import { TASK_TYPES, UNIPILE_TASK_TYPES } from '@/common/constants/taskTypes';
import { useMutation } from '@apollo/client';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import { getFullname } from '@/common/helpers/person';
import Alert from '@/components/Common/Alert/Alert';
import GenericButton from '@/components/Common/GenericButton';
import { SERVICE_FROM_SUBTYPE } from '@/containers/Profile/Contact/TimeLineItems/UnipileMessageItem/UnipileMessageItem';
import { GET_UNIPILE_RECONNECTION_URL } from '@/graphql/unipile';
import UnipileConnexionModal from '@/containers/Parameters/AutomationIntegration/UnipileAutomation/UnipileConnexionModal';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

interface UnipileAlertsProps {
  isConnectedLoading: boolean;
  isConnected: boolean;
  isInvitationPending: boolean;
  actionType: string;
  selectedSenderId?: string;
  selectedMessagingAccount: any;
  message: string;
  firstname: string;
  lastname: string;
  reconnectModalOpen: boolean;
  onReconnectModalOpen: (value: boolean) => void;
  onPollingRate: (value: number) => void;
  hasValidated: boolean;
}

const UnipileAlerts: React.FC<UnipileAlertsProps> = ({
  isConnectedLoading,
  isConnected,
  isInvitationPending,
  actionType,
  selectedSenderId,
  selectedMessagingAccount,
  message,
  firstname,
  lastname,
  reconnectModalOpen,
  onReconnectModalOpen,
  onPollingRate,
  hasValidated,
}) => {
  const { t } = useTranslation();
  const { shouldDisplayUnipileConnectedAlert } = useMergedConfigurationParams();

  const isMessagingAccountValid = useMemo(
    () => selectedMessagingAccount?.status === 'valid',
    [selectedMessagingAccount],
  );

  const [getUnipileReconnectionURL] = useMutation(GET_UNIPILE_RECONNECTION_URL);

  return (
    <>
      {!isConnectedLoading &&
        !isConnected &&
        !isInvitationPending &&
        isMessagingAccountValid &&
        _.includes(
          [TASK_TYPES.WHATSAPP_SEND_MESSAGE, TASK_TYPES.LINKEDIN_SEND_MESSAGE],
          actionType,
        ) &&
        selectedSenderId && (
          <Alert
            title={t('common.warning')}
            description={t(
              `settings.automations.unipile.contactNotConnected.${
                _.includes(
                  [
                    TASK_TYPES.LINKEDIN_SEND_MESSAGE,
                    TASK_TYPES.LINKEDIN_SEND_INMAIL,
                  ],
                  actionType,
                )
                  ? 'unipile-linkedin-message'
                  : 'unipile-whatsapp'
              }`,
              {
                contact: getFullname({
                  firstname,
                  lastname,
                }),
                recruiter: selectedMessagingAccount?.name,
              },
            )}
            level='warning'
          />
        )}
      {selectedSenderId &&
        isConnected &&
        shouldDisplayUnipileConnectedAlert &&
        isMessagingAccountValid &&
        actionType === TASK_TYPES.LINKEDIN_SEND_MESSAGE && (
          <Alert
            title={t('common.info')}
            description={t(
              `settings.automations.unipile.unipileWarnings.contact-connected`,
              {
                contact: getFullname({
                  firstname,
                  lastname,
                }),
                recruiter: selectedMessagingAccount?.name,
              },
            )}
            level='info'
          />
        )}
      {selectedSenderId &&
        !isConnectedLoading &&
        !isConnected &&
        !isInvitationPending &&
        isMessagingAccountValid &&
        [
          TASK_TYPES.LINKEDIN_SEND_MESSAGE,
          TASK_TYPES.LINKEDIN_SEND_REQUEST,
        ].includes(actionType) &&
        message?.length > 350 && (
          <Alert
            title={t('common.warning')}
            description={t(
              `settings.automations.unipile.unipileWarnings.too-many-characters`,
            )}
            level='warning'
          />
        )}
      {selectedSenderId &&
        !isConnectedLoading &&
        !isConnected &&
        isInvitationPending &&
        isMessagingAccountValid &&
        actionType === TASK_TYPES.LINKEDIN_SEND_MESSAGE && (
          <Alert
            title={t('common.warning')}
            description={t(
              'settings.automations.unipile.unipileWarnings.invitation-pending',
              {
                contact: getFullname({
                  firstname,
                  lastname,
                }),
              },
            )}
            level='warning'
          />
        )}
      {selectedSenderId &&
        !isConnectedLoading &&
        !isConnected &&
        isInvitationPending &&
        isMessagingAccountValid &&
        actionType === TASK_TYPES.LINKEDIN_SEND_REQUEST && (
          <Alert
            title={t('common.warning')}
            description={t(
              'settings.automations.unipile.unipileWarnings.invitation-pending-invite',
              {
                contact: getFullname({
                  firstname,
                  lastname,
                }),
              },
            )}
            level='warning'
          />
        )}
      {!isMessagingAccountValid &&
        _.includes(UNIPILE_TASK_TYPES, actionType) &&
        selectedSenderId &&
        actionType !== TASK_TYPES.WHATSAPP_SEND_MESSAGE && (
          <Alert
            title={t('common.warning')}
            description={t(
              'settings.automations.unipile.unipileWarnings.accountDisconnected',
              {
                accountType:
                  SERVICE_FROM_SUBTYPE[selectedMessagingAccount?.type || ''],
                recruiter: selectedMessagingAccount?.name,
              },
            )}
            level='warning'
            hasCta
          >
            <GenericButton
              onClick={() => {
                getUnipileReconnectionURL({
                  variables: {
                    accountId: selectedMessagingAccount?.serviceAccountId,
                  },
                }).then((response: any) => {
                  const { url, expectedCode: _code } =
                    response?.data?.getUnipileReconnectionLink || {};
                  window.open(url, '_blank');
                  onPollingRate(2000);
                });
                onReconnectModalOpen(true);
              }}
              size='small'
            >
              {t('settings.automations.unipile.reconnect')}
            </GenericButton>
          </Alert>
        )}
      {selectedSenderId &&
        isMessagingAccountValid &&
        !isConnectedLoading &&
        isConnected &&
        actionType === TASK_TYPES.LINKEDIN_SEND_REQUEST && (
          <Alert
            title={t('common.warning')}
            description={t(
              `settings.automations.unipile.unipileWarnings.alreadyConnected`,
              {
                contact: getFullname({
                  firstname,
                  lastname,
                }),
                recruiter: selectedMessagingAccount?.name,
              },
            )}
            level='warning'
          />
        )}
      <UnipileConnexionModal
        open={reconnectModalOpen}
        onClose={() => {
          onPollingRate(0);
          onReconnectModalOpen(false);
        }}
        onCancel={() => {
          onReconnectModalOpen(false);
          onPollingRate(0);
          onReconnectModalOpen(false);
        }}
        onConfirm={() => {
          onReconnectModalOpen(false);
          onReconnectModalOpen(false);
        }}
        hasValidated={hasValidated}
        hasErrors={false}
      />
    </>
  );
};

export default UnipileAlerts;
