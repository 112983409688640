import React, { useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import { NumberRecord, Stats } from '@/types/statistics/stats';
import { keyInStack } from '@/common/helpers/stats';
import { ClientDailyEvent } from '@/types/statistics/clientStatistics';
import { TimeSpan } from '../RevealAnalyticsHeader/RevealAnalyticsHeader';
import { STACK } from './CompletedTasksDataContainer';
import HorizontalStackedBarChart from '../HorizontalStackedBarChart';
import { aggregateTasksByUser } from './helpers';
import { filterEventsByDateRange } from '../../stats.helpers';

import styles from './TasksByUserDataContainer.module.less';

interface TasksByUserDataContainerProps {
  dateRange: TimeSpan;
  dailyEvents: ClientDailyEvent[];
  fetchDetails?: (authorEmail: string, taskType: string) => void;
}

const TasksByUserDataContainer: React.FC<TasksByUserDataContainerProps> = ({
  dailyEvents,
  dateRange,
  fetchDetails,
}) => {
  const { t } = useTranslation();
  const data = useMemo(() => {
    const eventsInRange = filterEventsByDateRange(dateRange, dailyEvents);

    const aggregatedTasksByUser = aggregateTasksByUser(eventsInRange);

    const formattedTasks: Stats[] = _.map(
      aggregatedTasksByUser,
      ({ fullname, values }, email) => {
        const filteredValues = _.reduce(
          values,
          (accumulator, count, taskType) =>
            keyInStack(STACK, taskType)
              ? { ...accumulator, [taskType]: count }
              : accumulator,
          {} as NumberRecord,
        );
        return {
          name: fullname,
          values: filteredValues,
          clickListeners: fetchDetails
            ? _.mapObject(filteredValues, (__, taskType) => () =>
                fetchDetails(email, taskType),
              )
            : undefined,
        };
      },
    );

    return formattedTasks;
  }, [dailyEvents, dateRange, fetchDetails]);

  return (
    <div className={styles.container}>
      {_.isEmpty(data) ? (
        <div className={styles.emptyState}>
          {t('analytics.noDataPlaceholder')}
        </div>
      ) : (
        <HorizontalStackedBarChart stack={STACK} allStats={data} />
      )}
    </div>
  );
};

export default TasksByUserDataContainer;
