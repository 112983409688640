import { TFunction } from 'i18next';

export type DropdownOption = {
  id?: string;
  value: string | null;
  text: string;
  icon?: string;
};

export default function getDropdownOptions(t: TFunction): DropdownOption[] {
  return [
    { value: null, text: t('CSVImport.selectField') },
    {
      value: 'firstname',
      text: t('CSVImport.dropdownOptions.firstname'),
      icon: 'user',
    },
    {
      value: 'lastname',
      text: t('CSVImport.dropdownOptions.lastname'),
      icon: 'user',
    },
    {
      value: 'fullname',
      text: t('CSVImport.dropdownOptions.fullname'),
      icon: 'user',
    },
    {
      value: 'email',
      text: t('CSVImport.dropdownOptions.email'),
      icon: 'email',
    },
    {
      value: 'phoneNumber',
      text: t('CSVImport.dropdownOptions.phonenumber'),
      icon: 'phone',
    },
    {
      value: 'linkedin',
      text: t('CSVImport.dropdownOptions.linkedin'),
      icon: 'linkedin',
    },
    {
      value: 'github',
      text: t('CSVImport.dropdownOptions.github'),
      icon: 'github',
    },
    {
      value: 'stackoverflow',
      text: t('CSVImport.dropdownOptions.stackoverflow'),
      icon: 'stack overflow',
    },
    {
      value: 'twitter',
      text: t('CSVImport.dropdownOptions.twitter'),
      icon: 'twitter',
    },
    {
      value: 'dribbble',
      text: t('CSVImport.dropdownOptions.dribbble'),
      icon: 'linkify',
    },
    {
      value: 'behance',
      text: t('CSVImport.dropdownOptions.behance'),
      icon: 'linkify',
    },
    {
      value: 'website',
      text: t('CSVImport.dropdownOptions.website'),
      icon: 'linkify',
    },
    {
      value: 'company',
      text: t('CSVImport.dropdownOptions.company'),
      icon: 'building',
    },
    {
      value: 'position',
      text: t('CSVImport.dropdownOptions.position'),
      icon: 'address card',
    },
    {
      value: 'headline',
      text: t('CSVImport.dropdownOptions.headline'),
      icon: 'address card',
    },
    {
      value: 'photoLink',
      text: t('CSVImport.dropdownOptions.photoLink'),
      icon: 'user circle',
    },
    {
      value: 'location',
      text: t('CSVImport.dropdownOptions.location'),
      icon: 'location arrow',
    },
  ].map(({ icon: _, ...values }) => values);
}
