import _ from 'underscore';

export const dateRangeOptions = ({ t }) => {
  const values = ['7days', '30days', '60days', '180days', '365days', 'week', 'month', 'quarter', 'year', 'allTime'];
  const formatValue = (value) => ({
    key: value,
    value,
    text: t(`analytics.dateRanges.${value}`),
  });
  return _.map(values, formatValue);
};

export const frequencyOptions = ({ t }) => {
  const values = ['day', 'week', 'month', 'quarter'];
  const formatValue = (value) => ({
    key: value,
    value,
    text: t(`analytics.frequency.${value}`),
  });
  return _.map(values, formatValue);
};
