import React from 'react';
import { Button } from 'semantic-ui-react';
import { getDisplayableName } from '../../../../common';

import DownloadButton from './DownloadButton';
import DeleteButton from './DeleteButton';

import './AttachmentPill.css';

const AttachmentPill = ({ attachment, attachmentIsImmutable, onSelectAttachment, profileId, t }) => {
  if (!attachment) {
    return null;
  }
  const { name, author, type, creationDate } = attachment;

  const uploadInfo = t('profile.resume.attachments.uploadInfo', {
    author: getDisplayableName({ author }),
    date: new Date(creationDate),
  });

  return (
    <div className='attachment-pill'>
      <span className='icon-container'>{getAttachmentIcon({ type })}</span>
      <span className='attachment-pill-inner'>
        <div className='tag-content caption-medium'>
          <span>{name}</span>
          {onSelectAttachment && (
            <Button onClick={() => onSelectAttachment({ id: attachment.id })}>
              <i className='ri-eye-line' />
            </Button>
          )}
          {!attachmentIsImmutable && (
            <>
              <DownloadButton icon id={attachment.id} profileId={profileId} />
              <DeleteButton icon id={attachment.id} profileId={profileId} />
            </>
          )}
        </div>
        <div className='tag-label'>{author && creationDate && uploadInfo}</div>
      </span>
    </div>
  );
};

const getAttachmentIcon = ({ type }) => {
  if (type === 'resume') {
    return <i className='ri-file-user-line' />;
  }
  if (type === 'cover-letter') {
    return <i className='ri-file-text-line' />;
  }
  return <i className='ri-file-line' />;
};

// onClick={() => onSelectAttachment({ id })}

export default AttachmentPill;
