import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { getMilestoneStageOptions } from '@/routes/WatchCollectViewV2/WatchCollectFollowup/FollowupKanban/components/FollowupKanbanCard';
import Thumbnail from '../../../../../components/Thumbnail';

const MilestoneTimelineItem = ({ profile, timelineItem }) => {
  const author = timelineItem.author || {};
  const { milestoneId } = timelineItem.action;
  const { t } = useTranslation();
  const milestoneOption = _.findWhere(getMilestoneStageOptions(t), {
    value: milestoneId,
  });

  return (
    <div className='timeline-card-mail-wrapper classic-item'>
      <div className='content'>
        <div className='header'>
          <Thumbnail
            link={author.photoLink}
            firstname={author.firstname}
            lastname={author.lastname}
          />
          <div className='author'>
            {timelineItem?.date && (
              <div className='date'>
                {timelineItem?.date &&
                  t('common.formattedDate', {
                    date: new Date(+timelineItem.date),
                  })}
              </div>
            )}
            <span>
              {`
              ${author.firstname} ${author.lastname} 
              ${t('profile.contact.timeline.moved')} 
              ${profile?.resumeData.firstname} 
              ${profile?.resumeData.lastname}
              ${t('profile.contact.timeline.inCategory')}
              ${milestoneOption?.text}
            `}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MilestoneTimelineItem;
