import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'underscore';
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import withDeleteSender from '../../../../../hocs/senders/withDeleteSender';

const DeleteSenderModal = ({ open, deleteSender, sender, closeModal, t }) => {
  if (!sender) return null;
  const onDelete = () => {
    deleteSender({ senderId: sender.id }).then(closeModal);
  };
  return (
    <ConfirmationModal
      content={t('settings.senders.deleteSender', {
        alias: sender.alias || sender.senderAddress,
        email: sender.senderAddress,
      })}
      header={t('settings.senders.deleteHeader')}
      onCancel={closeModal}
      onSubmit={onDelete}
      validationType='negative'
      open={open}
    />
  );
};

export default compose(
  withDeleteSender,
  withTranslation('translations'),
)(DeleteSenderModal);
