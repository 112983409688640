import React from 'react';
import * as Sentry from '@sentry/browser';
import Layout from './containers/Auth/Layout';

class ErrorBoundary extends React.Component {
  constructor() {
    super();

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // process the error
    console.error({ error });
    return { hasError: error };
  }

  componentDidCatch(error, info) {
    // The Missing profileId or task is necessary in useCandidateViewContext
    // But we dont want to send it to Sentry
    if (error.message !== 'Missing profileId or task') {
      Sentry.captureException(error);
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <Layout
          title='Something went wrong'
          subtitle='Please try to reload your page'
        />
      );
    }
    return children;
  }
}

export default ErrorBoundary;
