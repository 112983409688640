import gql from 'graphql-tag';

export interface ISearchPoolJobStat {
  id: string;
  stats?: {
    tasks: number;
    notContacted: number;
    interested: number;
    replied: number;
    hired: number;
    contacted: number;
    archivedFromNotContacted: number;
    archivedFromInterested: number;
    archivedFromReplied: number;
    archivedFromHired: number;
    archivedFromContacted: number;
  };
}

export interface SearchPoolJobStatsQueryResult {
  searchPool: {
    id: string;
    jobs?: ISearchPoolJobStat[];
  };
}
export interface SearchPoolJobStatsQueryVariables {
  searchPoolId: string[];
  filters?: {
    includeAll?: boolean;
    userEmail?: string;
    ATSOnly?: boolean;
    activeOnly?: boolean;
    departmentId?: string;
    sectionId?: string;
    subsectionId?: string;
    type?: string;
  };
}

// We could send a query with a missionIds filter
// that way we could automatically handle pagination
const SEARCH_POOL_JOBS_STATS = gql`
  query getSearchPoolJobsStats(
    $searchPoolId: ID!
    $filters: SearchPoolJobFiltersInput
  ) {
    searchPool(id: $searchPoolId) {
      id
      jobs(filters: $filters) {
        id
        stats {
          tasks
          notContacted
          interested
          replied
          hired
          contacted
          archivedFromNotContacted
          archivedFromInterested
          archivedFromReplied
          archivedFromHired
          archivedFromContacted
          applicants
          interestedSubSteps {
            subStepId
            count
          }
          atsMetadata {
            key
            value
          }
        }
      }
    }
  }
`;

export default SEARCH_POOL_JOBS_STATS;
