import React, { useState } from 'react';
import { compose } from 'underscore';
import { useTranslation, withTranslation } from 'react-i18next';
import { Redirect, Link } from 'react-router-dom';
import { Grid, Form, Checkbox, Icon } from 'semantic-ui-react';
import OAuthGmailSignin from './OAuthGmailSignin';
import OAuthOutlookSignin from './OAuthOutlookSignin';
import Separator from './Separator';
import Layout from './Layout';
import PasswordChecks from './PasswordChecks';

import withOAuthSignup from '../../hocs/users/withOAuthSignup';
import withTokenFromUrl from '../../hocs/users/withTokenFromUrl';
import withUserFromToken from '../../hocs/users/withUserFromToken';
import withClient from '../../hocs/clients/withClient';
import withSignup from '../../hocs/users/withSignup';
import withActionLogger from '../../hocs/withActionLogger';

import FloatingLabelInput from '../../components/FloatingLabelInput';
import { passwordIsStrong, sentryCaptureException } from '../../common';

import './GenericSigninForm.css';

const SignupFormContainer = ({
  client,
  loading,
  user,
  userLoading,
  t,
  onLogAction,
  clientId,
  onSignup,
  onSignupOAuth,
  token,
}) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [oAuthError, setOAuthError] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [alias, setAlias] = useState('');
  const [signature, setSignature] = useState('');
  const [cgvChecked, setCgvChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [oAuthEmail, setOAuthEmail] = useState('');
  const [password, setPassword] = useState('');
  const [type, setType] = useState('');
  const [idToken, setIdToken] = useState('');

  if (loading || userLoading) {
    return null;
  }

  if (!client) {
    return <Layout title={t('signup.unknownClientError')} />;
  }

  if (success) {
    return <Redirect to={`/client/${clientId}`} />;
  }

  if (!user) {
    return (
      <Layout title={t('signup.title')}>
        <div className='generic-signin-form'>
          <div className='form-details'>
            {t('signup.noTokenMessage', { clientTitle: client.title })}
          </div>
          <Link to='signin'>
            <SubmitButton>{t('signup.backToSignin')}</SubmitButton>
          </Link>
        </div>
      </Layout>
    );
  }

  const onTermsOfServiceClick = (e) => {
    e.stopPropagation();
    onLogAction({
      type: 'click-terms-of-services',
      info: {
        clientId,
        from: 'signup-form',
      },
    });
  };

  const onPrivacyClick = (e) => {
    e.stopPropagation();
    onLogAction({
      type: 'click-privacy',
      info: {
        clientId,
        from: 'signup-form',
      },
    });
  };

  if (idToken && type && oAuthEmail) {
    const onSubmitOAuth = async () => {
      if (!cgvChecked) {
        setError('cgvCheck');
        return;
      }
      setError('');
      setOAuthError('');
      try {
        await onSignupOAuth({
          idToken,
          type,
          firstname,
          lastname,
          alias,
          signature,
          clientId,
          signUpToken: token,
        });
        setSuccess(true);
      } catch (error) {
        sentryCaptureException({ error, tags: { feature: 'signup' } });
        setError('unknown');
      }
    };
    const onClickPrevious = () => {
      setIdToken('');
      setType('');
      setOAuthEmail('');
    };

    return (
      <Layout title={t('signup.title')}>
        <div className='generic-signin-form'>
          <div className='link-to-signin-container'>
            <AlreadyHaveAnAccountLink t={t} />
          </div>
          <UserDetailsForm
            firstname={firstname}
            setFirstname={setFirstname}
            lastname={lastname}
            setLastname={setLastname}
            email={oAuthEmail}
            t={t}
          />
          <UserCGV
            cgvChecked={cgvChecked}
            privacyChecked={privacyChecked}
            setCgvChecked={setCgvChecked}
            setPrivacyChecked={setPrivacyChecked}
            onTermsOfServiceClick={onTermsOfServiceClick}
            onPrivacyClick={onPrivacyClick}
            t={t}
          />

          <SubmitButton
            onClick={onSubmitOAuth}
            disabled={!firstname || !lastname || !cgvChecked || !privacyChecked}
          >
            {t('signup.form.submit')}
          </SubmitButton>

          {error && <ErrorMessage>{t(`signup.error.${error}`)}</ErrorMessage>}

          <div className='underlined-link'>
            <span onClick={onClickPrevious}>{t('signup.form.previous')}</span>
          </div>
        </div>
      </Layout>
    );
  }

  const onSubmitPassword = async () => {
    if (!cgvChecked) {
      return setError('cgvCheck');
    }
    if (!passwordIsStrong(password)) {
      return null;
    }
    setError('');
    try {
      await onSignup(token, {
        firstname,
        lastname,
        password,
        clientId,
      });
      setSuccess(true);
    } catch (error) {
      sentryCaptureException({ error, tags: { feature: 'signup' } });
      setError('unknown');
    }
    return undefined;
  };

  return (
    <Layout title={t('signup.title')}>
      <div className='generic-signin-form'>
        <div className='link-to-signin-container'>
          <AlreadyHaveAnAccountLink t={t} />
        </div>
        <OAuthButtons
          setFirstname={setFirstname}
          setLastname={setLastname}
          setEmail={setOAuthEmail}
          setAlias={setAlias}
          setSignature={setSignature}
          setIdToken={setIdToken}
          setType={setType}
          setError={setOAuthError}
          user={user}
          t={t}
        />
        {oAuthError && (
          <ErrorMessage>
            {t(`signup.error.${oAuthError}`, { email: user.email, oAuthEmail })}
          </ErrorMessage>
        )}
        <Grid.Row>
          <Grid.Column width={16}>
            <Separator className='signin-separator-dark'>
              {t('signin.or')}
            </Separator>
          </Grid.Column>
        </Grid.Row>
        <UserDetailsForm
          firstname={firstname}
          setFirstname={setFirstname}
          lastname={lastname}
          setLastname={setLastname}
          email={user?.email}
          password={password}
          setPassword={setPassword}
          t={t}
        />
        <UserCGV
          cgvChecked={cgvChecked}
          privacyChecked={privacyChecked}
          setCgvChecked={setCgvChecked}
          setPrivacyChecked={setPrivacyChecked}
          onTermsOfServiceClick={onTermsOfServiceClick}
          onPrivacyClick={onPrivacyClick}
          t={t}
        />
        <SubmitButton
          onClick={onSubmitPassword}
          disabled={
            !passwordIsStrong(password || '') ||
            !firstname ||
            !lastname ||
            !cgvChecked ||
            !privacyChecked
          }
        >
          {t('signup.form.submit')}
        </SubmitButton>
        {error && <ErrorMessage>{t(`signup.error.${error}`)}</ErrorMessage>}
      </div>
    </Layout>
  );
};

const UserDetailsForm = ({
  t,
  email,
  password,
  setPassword,
  firstname,
  setFirstname,
  lastname,
  setLastname,
}) => {
  const onChangeFirstname = (e) => {
    setFirstname(e.target.value);
  };
  const onChangeLastname = (e) => {
    setLastname(e.target.value);
  };
  return (
    <Form>
      <Form.Field className='firstname-field'>
        <FloatingLabelInput
          id='signup-firstname'
          onChange={onChangeFirstname}
          value={firstname}
          type='text'
          name='fname'
          autocomplete='given-name'
          label={t('signup.form.firstname')}
          minLength='2'
          pattern='.*\S+.*'
          required
        />
      </Form.Field>
      <Form.Field className='lastname-field'>
        <FloatingLabelInput
          id='signup-lastname'
          onChange={onChangeLastname}
          value={lastname}
          type='text'
          name='lname'
          autocomplete='family-name'
          label={t('signup.form.lastname')}
          minLength='2'
          pattern='.*\S+.*'
          required
        />
      </Form.Field>
      <Form.Field className='email-field'>
        <FloatingLabelInput
          value={email}
          type='email'
          name='email'
          label={t('signup.form.email')}
          autocomplete='username'
        />
      </Form.Field>
      {setPassword && (
        <>
          {/* <input hidden style={{display: 'none'}} type='email' id='username' autoComplete='username' /> */}
          <UserPassword password={password} setPassword={setPassword} t={t} />
        </>
      )}
    </Form>
  );
};

const UserPassword = ({ t, password, setPassword }) => {
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const onChange = (e) => {
    setPassword(e.target.value);
  };
  return (
    <Form.Field className='password-field'>
      <FloatingLabelInput
        id='signup-password'
        autocomplete='new-password'
        type='password'
        name='password'
        onFocus={() => setInputHasFocus(true)}
        onBlur={() => setInputHasFocus(false)}
        onChange={onChange}
        value={password}
        label={t('signup.form.password')}
      />
      <PasswordChecks password={password} inputHasFocus={inputHasFocus} />
    </Form.Field>
  );
};

const AlreadyHaveAnAccountLink = ({ t }) => {
  return (
    <div className='already-have-an-account-link'>
      <span>{t('signup.alreadyHaveAnAccount')}</span>
      <span>&nbsp;</span>
      <Link className='link-to-signin' to='signin'>
        {t('signup.signin')}
        <Icon name='chevron right' size='small' />
      </Link>
    </div>
  );
};

const OAuthButtons = ({
  setFirstname,
  setLastname,
  setAlias,
  setSignature,
  setEmail,
  setType,
  setIdToken,
  setError,
  user,
  t,
}) => {
  const onSignin = ({
    id_token: token,
    type,
    email,
    firstname,
    lastname,
    alias,
    signature,
  }) => {
    if (!token || !type || !email) {
      setError('login');
      return;
    }
    if (email !== user.email) {
      setEmail(email);
      setError('email_mismatch');
      return;
    }
    if (firstname) {
      setFirstname(firstname);
    }
    if (lastname) {
      setLastname(lastname);
    }
    if (alias) {
      setAlias(alias);
    }
    if (signature) {
      setSignature(signature);
    }
    setType(type);
    setEmail(email);
    setIdToken(token);
  };

  const onError = () => {
    setError('unknown');
  };

  return (
    <div className='signin-buttons'>
      <OAuthGmailSignin
        onSignin={onSignin}
        onError={onError}
        label={t('signup.signupWithGoogle')}
        mode='signup'
      />
      <OAuthOutlookSignin
        onSignin={onSignin}
        onError={onError}
        label={t('signup.signupWithMicrosoft')}
        mode='signup'
      />
    </div>
  );
};

const UserCGV = ({
  cgvChecked,
  privacyChecked,
  setCgvChecked,
  setPrivacyChecked,
  onTermsOfServiceClick,
  onPrivacyClick,
  t,
}) => {
  const { i18n } = useTranslation();
  const { resolvedLanguage } = i18n;
  const lng = resolvedLanguage.slice(0, 2);
  const termsLink =
    lng === 'fr'
      ? 'https://content.hiresweet.com/clients-hiresweet/hiresweet-marketplace-fr/hiresweet-marketplace-cgv'
      : 'https://content.hiresweet.com/clients-hiresweet/hiresweet-marketplace-en/hiresweet-marketplace-t-and-c';
  const privacyLink =
    lng === 'fr'
      ? 'https://content.hiresweet.com/clients-hiresweet/hiresweet-marketplace-fr/hiresweet-marketplace-politique-de-confidentialite'
      : 'https://content.hiresweet.com/clients-hiresweet/hiresweet-marketplace-en/hiresweet-marketplace-privacy';
  const onCGVChange = (e, { checked }) => {
    setCgvChecked(checked);
  };

  const onPrivacyChange = (e, { checked }) => {
    setPrivacyChecked(checked);
  };

  return (
    <div className='user-cgv'>
      <Checkbox
        checked={cgvChecked}
        onChange={onCGVChange}
        name='termsOfServiceAgreement'
        label={
          <label>
            <div className='cgv-first-line'>
              <span>{t('signup.cgv.firstLine')}&nbsp;</span>
              <a
                href={termsLink}
                target='_blank'
                className='cgv-modal-trigger'
                onClick={onTermsOfServiceClick}
                rel='noopener noreferrer'
              >
                {t('signup.cgv.secondLine')}
              </a>
            </div>
          </label>
        }
      />
      <Checkbox
        checked={privacyChecked}
        onChange={onPrivacyChange}
        name='privacyAgreement'
        label={
          <label>
            <div className='cgv-first-line'>
              <span>{t('signup.privacy.firstLine')}&nbsp;</span>
              <a
                href={privacyLink}
                target='_blank'
                className='cgv-modal-trigger'
                onClick={onPrivacyClick}
                rel='noopener noreferrer'
              >
                {t('signup.privacy.secondLine')}
              </a>
            </div>
          </label>
        }
      />
    </div>
  );
};

const ErrorMessage = ({ children }) => (
  <div className='warning-message'>{children}</div>
);

const SubmitButton = ({ children, onClick, disabled }) => (
  <Form.Field className='submit-field'>
    <button
      className='submit-button'
      type='submit'
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  </Form.Field>
);

export default compose(
  withTokenFromUrl,
  withUserFromToken,
  withClient,
  withSignup,
  withOAuthSignup,
  withActionLogger,
  withTranslation('translations'),
)(SignupFormContainer);
