import React, { ReactNode, FC, MouseEvent } from 'react';
import classNames from 'classnames';
import GenericTooltip from '@/components/Common/GenericTooltip';

interface OfferLabelProps {
  text?: ReactNode;
  content?: ReactNode;
  icon?: ReactNode;
  className?: string;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

const OfferLabel: FC<OfferLabelProps> = ({
  text,
  content,
  className,
  icon,
  onClick,
}) => {
  const wrappedOnClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (onClick) {
      onClick(e);
    }
  };

  const trigger = (
    <div
      className='offer-label-container'
      onClick={wrappedOnClick}
      role='button'
    >
      <div
        className={classNames('offer-label', className, content && 'hoverable')}
      >
        {icon && icon}
        {text && <span>{text}</span>}
      </div>
    </div>
  );

  if (!content) {
    return trigger;
  }

  return (
    <div className='offer-label-container' onClick={wrappedOnClick}>
      <GenericTooltip
        position='bottom center'
        mouseEnterDelay={500} // prevent popups from opening when we just want to explore the page
        trigger={trigger}
        content={<span>{content}</span>}
      />
    </div>
  );
};

export default OfferLabel;
