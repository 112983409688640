import React, { FC } from 'react';

import styles from './svg.module.less';

const Calendar: FC = () => (
  <svg
    className={styles.svgIcon}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_4298_20124)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 0C3.44772 0 3 0.447715 3 1V3V4C3 4.55228 3.44772 5 4 5C4.55228 5 5 4.55228 5 4V3V1C5 0.447715 4.55228 0 4 0ZM12 0C11.4477 0 11 0.447715 11 1V3V4C11 4.55228 11.4477 5 12 5C12.5523 5 13 4.55228 13 4V3V1C13 0.447715 12.5523 0 12 0ZM7.5 2C6.94772 2 6.5 2.44772 6.5 3V4C6.5 5.38071 5.38071 6.5 4 6.5C2.61929 6.5 1.5 5.38071 1.5 4C1.5 3.28983 0.737818 2.9057 0.387819 3.52363C0.140937 3.95951 0 4.4633 0 5V13C0 14.6569 1.34315 16 3 16H13C14.6569 16 16 14.6569 16 13V5C16 4.4633 15.8591 3.95951 15.6122 3.52363C15.2622 2.9057 14.5 3.28983 14.5 4C14.5 5.38071 13.3807 6.5 12 6.5C10.6193 6.5 9.5 5.38071 9.5 4V3C9.5 2.44772 9.05228 2 8.5 2H7.5ZM3 9C2.44772 9 2 9.44771 2 10V11C2 11.5523 2.44772 12 3 12H4C4.55228 12 5 11.5523 5 11V10C5 9.44772 4.55228 9 4 9H3ZM6.5 10C6.5 9.44771 6.94772 9 7.5 9H8.5C9.05229 9 9.5 9.44772 9.5 10V11C9.5 11.5523 9.05228 12 8.5 12H7.5C6.94772 12 6.5 11.5523 6.5 11V10ZM12 9C11.4477 9 11 9.44771 11 10V11C11 11.5523 11.4477 12 12 12H13C13.5523 12 14 11.5523 14 11V10C14 9.44772 13.5523 9 13 9H12Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_4298_20124'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default Calendar;
