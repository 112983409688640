import DropdownContainer from '@/components/Reveal/Dropdown/DropdownContainer/DropdownContainer';
import DropdownTrigger from '@/components/Reveal/Dropdown/DropdownTrigger/DropdownTrigger';
import React from 'react';
import _ from 'underscore';
import styles from './GenericDropdownTrigger.module.less';

interface GenericDropdownTriggerProps {
  currentValue: string;
  placeholder: string[];
  multiSelect: boolean;
  selectedItems: string[];
  totalItemCount: number;
  clearable: boolean;
  onClear: () => void;
}

const GenericDropdownTrigger: React.FC<GenericDropdownTriggerProps> = ({
  currentValue,
  placeholder,
  multiSelect,
  selectedItems = [],
  totalItemCount,
  clearable,
  onClear,
}) => {
  return (
    <div>
      <DropdownContainer>
        <DropdownTrigger clearable={clearable} onClear={onClear}>
          <div className={styles.triggerContent}>
            {currentValue && selectedItems.length < totalItemCount && (
              <div className={styles.triggerText}>{currentValue}</div>
            )}
            {multiSelect &&
              selectedItems.length > 1 &&
              selectedItems.length < totalItemCount && (
                <div className='pill-message blue small equal-padding'>
                  +{selectedItems.length - 1}
                </div>
              )}
            {multiSelect &&
              !_.isEmpty(selectedItems) &&
              selectedItems.length === totalItemCount && <>{placeholder}</>}
            {_.isEmpty(currentValue) && (
              <div className={styles.triggerPlaceholder}>{placeholder}</div>
            )}
          </div>
        </DropdownTrigger>
      </DropdownContainer>
    </div>
  );
};

export default GenericDropdownTrigger;
