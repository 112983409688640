import React from 'react';
import classNames from 'classnames';

import SettingsMenu from '../../components/menus/SettingsMenu';
import MainFooter from '../../components/MainFooter';
import { useNavApplicationMode } from '../TopBar/Header/NavSwitch.hook';

import './SettingsLayout.css';

const SettingsLayout = ({
  clientId,
  className,
  currentPage,
  size = 'small',
  children,
}) => {
  const { applicationMode } = useNavApplicationMode();

  return (
    <div className={classNames('settings-layout', className)}>
      <div className='settings-layout-menu'>
        {clientId && (
          <SettingsMenu clientId={clientId} currentPage={currentPage} />
        )}
      </div>
      <div
        className={classNames(
          'settings-layout-content',
          size === 'big' ? 'settings-layout-content-big' : '',
        )}
      >
        <div className='settings-layout-content-padding' />
        {children}
        {applicationMode === 'classic' && (
          <div className='settings-layout-content-footer'>
            <MainFooter clientId={clientId} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsLayout;
