import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import { t } from 'i18next';
import React from 'react';
import { Modal } from 'semantic-ui-react';
import styles from '../SuperPipelineSettings.module.less';

interface DeletePipelineModalProps {
  title: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  onSubmit: () => void;
}

const DeletePipelineModal: React.FC<DeletePipelineModalProps> = ({
  title,
  open,
  setOpen,
  onSubmit,
}) => {
  return (
    <GenericModal size='tiny' open={open} onClose={() => setOpen(false)}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <p className={styles.newSubstepNameLabel}>
          {t('superPipelineSettings.deletePipelineConfirmation')}
        </p>
      </Modal.Content>
      <Modal.Actions className={styles.actions}>
        <GenericButton
          size='big'
          primacy='secondary'
          onClick={() => setOpen(false)}
        >
          {t('common.cancel')}
        </GenericButton>
        <GenericButton size='big' primacy='primary' onClick={() => onSubmit()}>
          {t('common.delete')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default DeletePipelineModal;
