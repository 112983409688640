import gql from "graphql-tag";

export const GET_UNIPILE_CONNECTION_URL = gql`
  mutation getUnipileConnectionLink {
    getUnipileConnectionLink {
      url
      expectedCode
    }
  }
`;

export const GET_UNIPILE_RECONNECTION_URL = gql`
  mutation getUnipileReconnectionLink($accountId: String) {
    getUnipileReconnectionLink(accountId: $accountId) {
      url
      expectedCode
    }
  }
`;

export const DELETE_UNIPILE_CONNECTION = gql`
  mutation deleteUnipileConnection($id: String) {
    deleteUnipileConnectionLink(id: $id)
  }
`;

export const GET_MESSAGE_ACCOUNT_CONTACT_RELATION = gql`
  query getMessageAccountContactRelation($input: getMessageAccountContactRelationInput!) {
    user {
      id
      email
      getMessageAccountContactRelation(input: $input) {
        status
      }
    }
  }
`;