import React from 'react';
import { Loader } from 'semantic-ui-react';
import _ from 'underscore';

import EnrichedProfiles from './EnrichedProfiles';

const SearchResults = ({
  clientId,
  user,
  searches,
  searchResultsCategories,
  loadingSearchResultsCategories,
  selectedCategory,
  excludeHidden,
  excludeProfilesInJob,
  shownInterestOnly,
  sortBy,
  hiddenProfileIds,
  setHiddenProfileIds,
  profilesInJobIds,
  setProfilesInJobIds,
  cardMode,
  selectedProfileId,
  onSelectProfileId,
}) => {
  if (loadingSearchResultsCategories) {
    return (
      <div className='loading-placeholder'>
        <Loader active inline='centered' />
      </div>
    );
  }
  const currentCategory = _.findWhere(searchResultsCategories, {
    categoryId: selectedCategory,
  });
  const profileIds = _.pluck(currentCategory?.results, 'searchPoolProfileId');
  const searchResultItems = currentCategory?.results;

  return (
    <div className='search-results'>
      <EnrichedProfiles
        clientId={clientId}
        user={user}
        searchPoolId='watch'
        searches={searches}
        excludeHidden={excludeHidden}
        excludeProfilesInJob={excludeProfilesInJob}
        shownInterestOnly={shownInterestOnly}
        sortBy={sortBy}
        profileIds={profileIds}
        searchResultItems={searchResultItems}
        hiddenProfileIds={hiddenProfileIds}
        setHiddenProfileIds={setHiddenProfileIds}
        profilesInJobIds={profilesInJobIds}
        setProfilesInJobIds={setProfilesInJobIds}
        cardMode={cardMode}
        firstBatchSize={cardMode === 'large' ? 3 : 5}
        batchSize={5}
        selectedProfileId={selectedProfileId}
        onSelectProfileId={onSelectProfileId}
      />
    </div>
  );
};

export default SearchResults;
