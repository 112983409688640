import React from 'react';
import {
  HiresweetLibProvider,
  ProfileContainer,
  ProfileContractsHistory,
} from '@hiresweet/hiresweet-lib';

import useClientId from '@/hooks/router/useClientId';

const Playground16 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30 }}>
      <h1>P16 - Profile contract history</h1>
      <HiresweetLibProvider theme={theme} clientId={clientId}>
        <ProfileContainer profileId='jean-bombeur-45T0ZI'>
          <div
            style={{
              backgroundColor: 'white',
              width: 600,
              padding: 20,
              borderRadius: 4,
              boxShadow: '0px 3px 11px 0px #00000014',
            }}
          >
            <ProfileContractsHistory maxLength={1} />
          </div>
        </ProfileContainer>
      </HiresweetLibProvider>
    </div>
  );
};

export default Playground16;
