import React from 'react';
import classNames from 'classnames';
import TranslatableText from '../../../../components/TranslatableText';
import LabelDetails from '../../../../components/LabelDetails';

import './Pill.css';

const Pill = ({ className, icon, label, content, popupContent }) => (
  <div className={classNames('relevant-tag-pill', className)}>
    {icon && (
      <span className='icon-container'>{icon}</span>
    )}
    <span className='relevant-tag-pill-inner'>
      <span className='tag-content caption-medium'>
        <TranslatableText text={content} />
      </span>
      <span className='tag-label'>
        <TranslatableText text={label} />
        {popupContent && (
          <LabelDetails>
            <TranslatableText text={popupContent}/>
          </LabelDetails>
        )}
      </span>
    </span>
  </div>
);

export default Pill;
