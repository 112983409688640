import React, { PropsWithChildren, ReactNode } from 'react';

import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import DropdownControlsContext from '@/context/DropdownControlsContext';

import GenericButton, {
  GenericButtonColor,
  GenericButtonPrimacy,
  GenericButtonSize,
} from '../GenericButton';
import GenericDropdown, { GenericDropdownPosition } from '../GenericDropdown';

type Props = {
  title?: ReactNode;
  position?: GenericDropdownPosition;
  primacy?: GenericButtonPrimacy;
  size?: GenericButtonSize;
  color?: GenericButtonColor;
  disabled?: boolean;
  isIcon?: boolean;
  className?: string;
};

const ButtonDropdown = ({
  title,
  position,
  disabled,
  children,
  ...rest
}: PropsWithChildren<Props>) => (
  <GenericDropdown
    disabled={disabled}
    position={position}
    trigger={
      <DropdownControlsContext.Consumer>
        {({ toggleDropdown }) => (
          <GenericButton onClick={toggleDropdown} disabled={disabled} {...rest}>
            {title ?? null}
            <ArrowDown />
          </GenericButton>
        )}
      </DropdownControlsContext.Consumer>
    }
  >
    {children}
  </GenericDropdown>
);

export default ButtonDropdown;
