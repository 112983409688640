import classNames from 'classnames';
import _ from 'underscore';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import EmailEditor from 'react-email-editor';
import { Segment, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import LoadingComponent from '@/components/LoadingComponent';
import GenericButton from '@/components/Common/GenericButton';
import { useClientCampaignTemplates } from '../queries';

import CampaignLayout from '../CampaignLayout';
import CampaignsSubHeader from '../CampaignsSubHeader/CampaignsSubHeader';

import './NewCampaignPreview.css';

const NewCampaignPreview = ({ clientId }) => {
  const { t } = useTranslation();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [reloadEditor, setReloadEditor] = useState(false);
  const { clientCampaignTemplates, loading } = useClientCampaignTemplates({
    clientId,
    fetchPolicy: 'network-only',
  });

  const emailEditorRef = useRef(null);

  useEffect(() => {
    if (!loading) {
      setSelectedTemplate(clientCampaignTemplates?.[0]);
    }
  }, [loading, clientCampaignTemplates]);

  useEffect(() => {
    if (!selectedTemplate) {
      return;
    }
    setReloadEditor(true);
    setTimeout(() => {
      onLoad();
    }, 0);
    // eslint-disable-next-line
  }, [selectedTemplate]);

  if (loading || !selectedTemplate) {
    return <></>;
  }

  const onLoad = () => {
    if (!emailEditorRef?.current?.editor) {
      return;
    }
    emailEditorRef.current.editor.loadBlank({
      backgroundColor: '#e7e7e7',
    });
    emailEditorRef.current.editor.showPreview('desktop');
    setTimeout(() => {
      const templateJson = JSON.parse(selectedTemplate?.description);
      emailEditorRef.current.editor.loadDesign(templateJson);
    }, 500);
    setTimeout(() => {
      setReloadEditor(false);
    }, 550);
  };

  return (
    <CampaignLayout
      subHeader={
        <CampaignsSubHeader
          breadcrumbs={[
            {
              text: t('campaigns.breadcrumbs.campaigns'),
              link: `/client/${clientId}/reveal/campaigns`,
            },
            {
              text: t('campaigns.breadcrumbs.newCampaign'),
              link: `/client/${clientId}/reveal/campaigns/new-campaign-preview`,
            },
          ]}
        >
          <Link
            to={`/client/${clientId}/reveal/campaigns/new-campaign-create?campaignTemplateId=${selectedTemplate.id}`}
          >
            <GenericButton size='big'>
              {t('campaigns.buttons.startFromTemplate')}
            </GenericButton>
          </Link>
          <span className='span-delimiter'> {t('common.or')} </span>
          <Link to={`/client/${clientId}/reveal/campaigns/new-campaign-create`}>
            <GenericButton size='big'>
              {t('campaigns.buttons.startFromScratch')}
            </GenericButton>
          </Link>
        </CampaignsSubHeader>
      }
    >
      <div className='new-campaign-preview'>
        <div className='campaign-template-selector'>
          <h4 className='templates-list-section-title'>
            {t('campaigns.preview.choose')}
          </h4>
          <div className='campaign-templates-list'>
            {loading ? (
              <LoadingComponent />
            ) : (
              _.map(clientCampaignTemplates, (campaignTemplate) => (
                <Segment
                  key={campaignTemplate.id}
                  onClick={() => setSelectedTemplate(campaignTemplate)}
                  className={classNames(
                    'campaign-template',
                    selectedTemplate?.id === campaignTemplate.id &&
                      'selected-campaign-template',
                  )}
                >
                  <h4 className='campaign-template-title'>
                    {campaignTemplate.title}
                  </h4>
                  <p className='campaign-template-subject'>
                    {campaignTemplate.subject}
                  </p>
                  {/* <CampaignActionsIcons campaign={campaignTemplate} /> */}
                </Segment>
              ))
            )}
          </div>
        </div>
        <div className='new-campaign-preview-display'>
          <div className='preview-display-header'>
            <h2 className='header-2'>
              {t('campaigns.preview.header')} {selectedTemplate.title}
            </h2>
            <p>{t('campaigns.preview.subHeader')}.</p>
          </div>
          <div className='unlayer-editor-container'>
            <div className='editor-blocker editor-blocker-top' />
            <div className='editor-blocker editor-blocker-right' />
            <div className='editor-blocker editor-blocker-bottom' />
            <div className='editor-blocker editor-blocker-left' />
            {!_.isEmpty(selectedTemplate?.description) && (
              <EmailEditor
                ref={emailEditorRef}
                onLoad={onLoad}
                style={{ height: '100%', minWidth: '0px' }}
                options={{
                  displayMode: 'web',
                }}
              />
            )}
            {reloadEditor && (
              <div className='loader-container'>
                <Loader active />
              </div>
            )}
          </div>
          {/* <CampaignPreview
            clientId={clientId}
            campaign={selectedTemplate}
            alwaysExpanded
          /> */}
        </div>
      </div>
    </CampaignLayout>
  );
};

export default NewCampaignPreview;
