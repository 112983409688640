import React, { ReactNode } from 'react';
import classnames from 'classnames';
import styles from './AlertBox.module.less';

interface AlertBoxPros {
  title?: string;
  description?: string;
  children?: ReactNode;
  className?: any;
}

const AlertBox: React.FC<AlertBoxPros> = ({
  title,
  description,
  children,
  className,
}) => {
  return (
    <div className={classnames(styles.alertBox, className)}>
      {title && <p className={styles.title}>{title}</p>}
      {description && <p className={styles.description}>{description}</p>}
      {children && [children]}
    </div>
  );
};

export default AlertBox;
