import moment from 'moment';
import React from 'react';
import { Label } from 'semantic-ui-react';
import { ATSConnectedIcon } from '../../../../assets/icons';

import './WatchNewTag.css';

const WatchNewTag = ({ creationTimestamp }) => {
  // cf. definition in backend
  // we don't use thursdayCurrentWeek as it would be strange not to include next week's new too
  const mondayOneWeekBefore = moment()
    .startOf('isoWeek')
    // .subtract(1, 'weeks')
    .subtract(7, 'days');
  if (!creationTimestamp || creationTimestamp < mondayOneWeekBefore) {
    return null;
  }
  return (
    <Label className='watch-new-tag'>
      <ATSConnectedIcon />
      <span className='new-label-text'>NEW</span>
    </Label>
  );
};

export default WatchNewTag;
