import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import HoverItemsDropdown from '../../../components/HoverItemsDropdown';
import { HoverItemContent } from '../../../components/HoverItemsDropdown/HoverItemsDropdown';
import SequencePreview from './SequencePreview';

const AddToSequenceDropdown = ({
  className,
  clientId,
  selectedSequenceId,
  sequences,
  onChangeSequence,
  ...props
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <HoverItemsDropdown
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      className={className}
      selection
      search
      value={selectedSequenceId}
      options={getSequenceWithPreviewOptions({
        clientId,
        sequences,
        onChange: onChangeSequence,
        onClose,
      })}
      onChange={onChangeSequence}
      placeholder={t('reveal.sequenceSideBar.sequencePlaceHolder')}
      {...props}
    />
  );
};

export const getSequenceWithPreviewOptions = ({
  clientId,
  sequences,
  onChange,
  onClose,
  position = '',
}) => {
  return _.map(sequences, (sequence) => {
    const onClick = (e) => {
      e.stopPropagation();
      if (onChange) {
        onChange(e, { value: sequence?.id });
      }
      if (onClose) {
        onClose();
      }
    };
    return {
      key: sequence?.id,
      text: sequence?.title,
      originaltitle: sequence?.originaltitle,
      value: sequence?.id,
      className: 'hover-item',
      active: false,
      onClick,
      children: (
        <HoverItemContent
          key={sequence?.id}
          popupContentClassName='sequence-preview-popup-content'
          text={sequence?.title}
          onClick={onClick}
          position={position || null}
          popupContent={
            <SequencePreview clientId={clientId} sequence={sequence} />
          }
        />
      ),
    };
  });
};

export default AddToSequenceDropdown;
