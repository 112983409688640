import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const query = gql`
  query getClientAnalyticsOffers($clientId: ID!) {
    client(id: $clientId) {
      id
      offers(includeSuggestedOffers: false) {
        id
        title
        isHiresweetWatch
        isUnclassified
        isHold
        isArchived
        isWaitingForValidation
        isSuggestedForCreation
        criteriaEditionAllowed
      }
    }
  }
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      clientId: ownProps.clientId,
    },
  }),
  props: ({ data: { error, loading, client } }) => {
    return {
      offers: client && client.offers,
      loading,
      error,
    };
  },
});
