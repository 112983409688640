import _, { compose } from 'underscore';
import React from 'react';
import withOffer from '../../../hocs/offers/withOffer';

import withUserFromJWToken from '../../../hocs/users/withUserFromJWToken';
import withActionLogger from '../../../hocs/withActionLogger';
import withFollowJob from '../../../hocs/offers/withFollowJob';
import withUnfollowJob from '../../../hocs/offers/withUnfollowJob';

import OfferSettings from '../../../components/OfferSettings';
import OfferLabels from './components/OfferLabels';
import OfferPipe from './components/OfferPipe';
import { sentryCaptureException } from '../../../common';
import OwnerLabel from './OfferTable/OfferRow/OwnerLabel';
import OfferActiveLabel from './OfferTable/OfferRow/OfferActiveLabel';

class OfferCard extends React.Component {
  goToOffer = () => {
    window.scrollTo(0, 0);
    const { history, onLogAction, clientId, offer } = this.props;
    history.push(`/client/${clientId}/jobs/${offer.id}`);
    onLogAction({
      type: 'click-offer-link',
      info: {
        clientId,
        offerId: offer.id,
        from: 'offers',
      },
    });
  };

  goToStep = ({ stepId }) => {
    const { history, onLogAction, clientId, offer } = this.props;
    const redirectUrl = `/client/${clientId}/jobs/${offer.id}/profiles/step_${stepId}`;
    window.scrollTo(0, 0);
    onLogAction({
      type: 'click-offer-step',
      info: {
        clientId,
        offerId: offer.id,
        stepId,
        from: 'offers',
      },
    });
    return history.push(redirectUrl);
  };

  followJob = async (e) => {
    e.stopPropagation();
    const { followJob, offer } = this.props;
    try {
      await followJob({ id: offer.id });
    } catch (error) {
      sentryCaptureException({ error, tags: { feature: 'follow-job' } });
    }
  };

  unfollowJob = async (e) => {
    e.stopPropagation();
    const { unfollowJob, offer } = this.props;
    try {
      await unfollowJob({ id: offer.id });
    } catch (error) {
      sentryCaptureException({ error, tags: { feature: 'unfollow-job' } });
    }
  };

  render() {
    const { user, offer, clientId, offerLoading } = this.props;
    if (offerLoading) {
      return null;
    }

    const isFollowed =
      user?.email &&
      _.pluck(offer?.followers || [], 'email').indexOf(user?.email) >= 0;

    const pipeStepStats = _.filter(
      (offer || {}).pipeStepStats,
      ({ stepId }) => stepId !== 'pre-pending' && stepId !== 'skipped',
    );

    return (
      <div className='offer-row' onClick={() => this.goToOffer()}>
        <div>
          {!isFollowed ? (
            <i
              className='star-icon ri-star-line'
              onClick={this.followJob}
              role='button'
            />
          ) : (
            <i
              className='star-icon ri-star-fill'
              onClick={this.unfollowJob}
              role='button'
            />
          )}
        </div>
        <div className='title-and-settings'>
          <div className='offer-title-container'>
            <h3 className='go-to-offer'>{offer.title}</h3>
            <OfferActiveLabel offer={offer} />
          </div>
          <div className='job-labels-container'>
            <OwnerLabel offer={offer} />
            <OfferLabels offer={offer} clientId={clientId} />
          </div>

          <div className='offer-settings-container'>
            {!(offer || {}).isHiresweetWatch && (
              <OfferSettings offer={offer} clientId={clientId} from='offers' />
            )}
          </div>
        </div>

        <div className='pipe-container'>
          <OfferPipe pipeStepStats={pipeStepStats} goToStep={this.goToStep} />
        </div>
      </div>
    );
  }
}

export default compose(
  withActionLogger,
  withUserFromJWToken,
  withFollowJob,
  withUnfollowJob,
  // this allows Card to subscribe to individual Offer updates in cache (like owner updates with updateOffer)
  withOffer,
)(OfferCard);
