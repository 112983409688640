import React from 'react';
import './RevealCandidateViewActionsButtons.css';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import MessageTab from './MultiChannelActivity/tabs/MessageTab';

interface RevealCandidateViewNewMessageProps {
  clientId: string;
  profile: any;
  messageType?: string;
  metadata?: {
    key: string;
    value: string | boolean;
  }[];
  onClose?: () => void;
  profileName?: string;
  profilePhoneNumber?: string;
}

const RevealCandidateViewNewMessage: React.FC<RevealCandidateViewNewMessageProps> = ({
  clientId,
  profile,
  messageType,
  metadata,
  onClose,
  profileName,
  profilePhoneNumber,
}) => {
  const isPlugin = useIsPlugin();
  return (
    <div className={`new-activity-modal ${isPlugin ? 'plugin' : ''}`}>
      <MessageTab
        clientId={clientId}
        profile={profile}
        draftSequence={null}
        canShowDefaultTemplateReplies
        messageType={messageType}
        metadata={metadata}
        onClose={onClose}
        profileName={profileName}
        profilePhoneNumber={profilePhoneNumber}
      />
    </div>
  );
};

export default RevealCandidateViewNewMessage;
