import React, { useCallback, useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import AccordionFilter from '../components/AccordionFilter';
import { CustomCriteriaFilters } from '../CustomCriteriaFilters';
import ExperienceFields from './ExperienceFields';
import FreelanceFields from './FreelanceFields';
import {
  countAdvancedBackground,
  countCompanyStates,
  countExperience,
  countFreelanceStates,
  countIndustries,
  countJobs,
  countLocations,
  countManagementLevels,
  countSchoolStates,
  countSkills,
  getCriteriaForFilters,
  getCriteriaForSearch,
} from './helpers';
import CompanyFields from './CompanyFields';
import SchoolFields from './SchoolFields';
import IndustryFields from './IndustryFields';
import JobFields from './JobFields';
import LocationFields from './LocationFields';
import ManagementLevelFields from './ManagementLevelFields';
import SequenceFields from './SequenceFields';

import ProjectFilters from './ProjectFilters';
import SkillsDropdown from './SkillsDropdown';
import ImportFilters from '../Filters/ImportFilters';

import './OmegaCriteriaFilters.css';
import AtsFilters from '../AtsFilters';

const OmegaCriteriaFilters = ({
  criteria,
  onUpdateCriteria,
  clientId,
  projectId,
  searchParams,
  onUpdateSearchParams,
}) => {
  const { t } = useTranslation();
  const criteriaForFilters = useMemo(
    () => getCriteriaForFilters({ criteria }),
    [criteria],
  );

  const {
    experience,
    locations,
    industries,
    skills,
    jobPosition,
    advancedBackgroundDefinition,
    projectFilters,
    sequenceFilters,
    managementLevels,
    freelanceStates,
    companyStates,
    schoolStates,
    customFilters,
    importFilters,
  } = criteriaForFilters;

  const updateFilter = useCallback(
    (field) => {
      return (valueOrUpdateFunction) => {
        /* eslint-disable no-shadow */
        const {
          experience,
          locations,
          industries,
          skills,
          jobPosition,
          backgroundSolidity,
          schoolPrestige,
          startupnessImportance,
          advancedBackgroundDefinition,
          projectFilters,
          sequenceFilters,
          managementLevels,
          freelanceStates,
          companyStates,
          schoolStates,
          customFilters,
          importFilters,
        } = criteriaForFilters;
        /* eslint-enable no-shadow */

        let value;
        if (_.isFunction(valueOrUpdateFunction)) {
          value = valueOrUpdateFunction(criteriaForFilters[field]);
        } else {
          value = valueOrUpdateFunction;
        }
        const newCriteria = {
          experience,
          locations,
          industries,
          managementLevels,
          freelanceStates,
          companyStates,
          schoolStates,
          backgroundSolidity,
          schoolPrestige,
          startupnessImportance,
          skills,
          jobPosition,
          advancedBackgroundDefinition,
          projectFilters,
          sequenceFilters,
          customFilters,
          importFilters,
          [field]: value,
        };
        const criteriaForSearch = getCriteriaForSearch(newCriteria);
        onUpdateCriteria({ criteria: criteriaForSearch });
      };
    },
    [criteriaForFilters, onUpdateCriteria],
  );

  const updateSkills = useMemo(() => updateFilter('skills'), [updateFilter]);

  const updateLocations = useMemo(() => updateFilter('locations'), [
    updateFilter,
  ]);

  const updateIndustries = useMemo(() => updateFilter('industries'), [
    updateFilter,
  ]);

  const updateJobPosition = useMemo(() => updateFilter('jobPosition'), [
    updateFilter,
  ]);

  const updateManagementLevels = useMemo(
    () => updateFilter('managementLevels'),
    [updateFilter],
  );

  const updateFreelanceStates = useMemo(() => updateFilter('freelanceStates'), [
    updateFilter,
  ]);

  const updateCompanyStates = useMemo(() => updateFilter('companyStates'), [
    updateFilter,
  ]);

  const updateSchoolStates = useMemo(() => updateFilter('schoolStates'), [
    updateFilter,
  ]);

  const updateCustomFilters = useMemo(() => updateFilter('customFilters'), [
    updateFilter,
  ]);

  const updateProjectFilters = useMemo(() => updateFilter('projectFilters'), [
    updateFilter,
  ]);

  const updateSequenceFilters = useMemo(() => updateFilter('sequenceFilters'), [
    updateFilter,
  ]);

  const updateImportFilters = useMemo(() => updateFilter('importFilters'), [
    updateFilter,
  ]);

  const getCustomFilterLength = () => {
    if (
      customFilters?.find(({ id }) => id === 'hasAnyMissingSources') &&
      customFilters?.find(({ id }) => id === 'hasSources')
    ) {
      return customFilters?.length - 1 ?? 1;
    }
    return customFilters?.length ?? 0;
  };

  return (
    <div className='omega-criteria-filters'>
      <AccordionFilter
        title={<h4>{t('reveal.searchView.filters.job.label')}</h4>}
        count={countJobs({ jobPosition })}
      >
        <JobFields
          jobPosition={jobPosition}
          setJobPosition={updateJobPosition}
        />
      </AccordionFilter>

      <AccordionFilter
        title={<h4>{t('reveal.searchView.filters.skills.label')}</h4>}
        count={countSkills({ skills })}
      >
        <SkillsDropdown
          skills={skills}
          setSkills={updateSkills}
          name='main'
          label={t('reveal.searchView.filters.skills.main')}
        />
        <SkillsDropdown
          skills={skills}
          setSkills={updateSkills}
          name='important'
          label={t('reveal.searchView.filters.skills.important')}
        />
        <SkillsDropdown
          skills={skills}
          setSkills={updateSkills}
          name='bonus'
          label={t('reveal.searchView.filters.skills.bonus')}
        />
      </AccordionFilter>

      <AccordionFilter
        title={<h4>{t('reveal.searchView.filters.location.label')}</h4>}
        count={countLocations({ locations })}
      >
        <LocationFields locations={locations} setLocations={updateLocations} />
      </AccordionFilter>

      <AccordionFilter
        title={<h4>{t('reveal.searchView.filters.background.experience')}</h4>}
        count={countExperience({ experience })}
      >
        <ExperienceFields
          experience={experience}
          setExperience={updateFilter('experience')}
          key={experience}
        />
      </AccordionFilter>

      <AtsFilters
        clientId={clientId}
        projectId={projectId}
        searchParams={searchParams}
        onUpdateSearchParams={onUpdateSearchParams}
      />

      <AccordionFilter
        title={
          <>
            <span className='filter-icon'>
              <img
                alt='hiresweet-square'
                height='20px'
                src='/images/logos/hiresweet-logo-square.svg'
              />
            </span>
            <h4>{t('common.project')}</h4>
          </>
        }
        count={projectFilters?.length}
      >
        <ProjectFilters
          projectFilters={projectFilters}
          updateProjectFilters={updateProjectFilters}
        />
      </AccordionFilter>

      <AccordionFilter
        title={
          <>
            <span className='filter-icon'>
              <img
                alt='hiresweet-square'
                height='20px'
                src='/images/logos/hiresweet-logo-square.svg'
              />
            </span>
            <h4>{t('reveal.searchView.filters.sequence.label')}</h4>
          </>
        }
        count={sequenceFilters?.length}
      >
        <SequenceFields
          sequenceFilters={sequenceFilters}
          updateSequenceFilters={updateSequenceFilters}
          clientId={clientId}
        />
      </AccordionFilter>

      <AccordionFilter
        title={<h4>{t('reveal.searchView.filters.background.label')}</h4>}
        count={
          countIndustries({ industries }) +
          countManagementLevels({ managementLevels }) +
          countFreelanceStates({ freelanceStates }) +
          countAdvancedBackground({ advancedBackgroundDefinition }) +
          countCompanyStates({ companyStates }) +
          countSchoolStates({ schoolStates })
        }
      >
        <div className='sub-title'>
          {t('reveal.searchView.filters.background.industry')}
        </div>
        <IndustryFields
          industries={industries}
          setIndustries={updateIndustries}
        />

        <br />

        <div className='sub-title'>
          {t('reveal.searchView.filters.background.management')}
        </div>
        <ManagementLevelFields
          managementLevels={managementLevels}
          setManagementLevels={updateManagementLevels}
        />
        <br />

        <div className='sub-title'>
          {t('reveal.searchView.filters.background.freelance')}
        </div>
        <FreelanceFields
          freelanceStates={freelanceStates}
          setFreelanceStates={updateFreelanceStates}
        />
        <br />

        <div className='sub-title'>
          {t('reveal.searchView.filters.background.companies')}
        </div>
        <CompanyFields
          companyStates={companyStates ?? {}}
          setCompanyStates={updateCompanyStates}
        />

        <div className='sub-title'>
          {t('reveal.searchView.filters.background.schools')}
        </div>
        <SchoolFields
          schoolStates={schoolStates ?? {}}
          setSchoolStates={updateSchoolStates}
        />
      </AccordionFilter>

      <ImportFilters
        importFilters={importFilters}
        onChange={updateImportFilters}
      />

      <CustomCriteriaFilters
        clientId={clientId}
        defaultParams={customFilters}
        count={getCustomFilterLength()}
        onChange={updateCustomFilters}
      />
    </div>
  );
};

/* const BasicFilter = ({ title, count, onClick }) => {
  return (
    <div className='basic-filter' onClick={onClick}>
      <div className='basic-filter-title'>
        <span className='title'>{title}</span>
        {count > 0 && <div className='filter-count-label'>{count}</div>}
      </div>
    </div>
  );
}; */

export default OmegaCriteriaFilters;
