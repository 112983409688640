import { useContext } from 'react';
import EmailContext from '@/context/EmailContext';

interface EmailApi {
  currentEmailType: string | null;
  onTriggerSendMail: () => void;
  onTriggerOfflineRegister: (type: string, prompt?: string) => void;
  onTriggerChangeAccount: () => void;
  onTriggerSignIn: () => void;
  onTriggerSignOut: () => void;
  onSendError: () => void;
  getIdToken: () => void;
  getEmailAddressDetails: () => void;
  currentAddress: string;
  sendConfirmationModeActive: boolean;
  setSendConfirmationMode: () => void;
  isFirstSessionSend: boolean;
  setIsFirstSessionSend: () => void;
  hasOfflineGrant: Record<string, boolean>;
  connectionInProgress: boolean;
  onOpenProviderChoiceModal: () => void;
}

const useEmailApi = (): EmailApi => useContext(EmailContext);

export default useEmailApi;
