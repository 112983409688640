/* eslint-disable max-len */

import React from 'react';

import styles from './svg.module.less';

const Automations: React.FC = () => (
  <svg
    className={styles.svgIcon}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='1' y='2' width='6' height='1' rx='0.5' fill='currentColor' />
    <rect x='1' y='13' width='6' height='1' rx='0.5' fill='currentColor' />
    <rect x='1' y='10' width='4' height='1' rx='0.5' fill='currentColor' />
    <rect x='1' y='5' width='4' height='1' rx='0.5' fill='currentColor' />
    <path
      d='M5.81814 8.93002H8.00527V14.1614C8.00527 14.9315 8.93958 15.2948 9.43504 14.7136L14.7932 8.46501C15.2603 7.92008 14.8852 7.06998 14.1774 7.06998H11.9902V1.83865C11.9902 1.06848 11.0559 0.70519 10.5605 1.28645L5.20235 7.53499C4.74227 8.07992 5.11741 8.93002 5.81814 8.93002Z'
      fill='currentColor'
    />
  </svg>
);

export default Automations;
