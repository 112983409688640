import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'underscore';

import { ElseStatement } from '@/common/mergeTags/utils';
import ContextMenu from '@/components/Tables/ContextMenu/ContextMenu';

import ElseStatementValueEditor from './ElseStatementValueEditor';
import ElseStatementValueSimpleInput from './ElseStatementValueSimpleInput';
import { StatementInputMode, getStatementTextMode } from '../utils';

import styles from '../ConditionsChaining.module.less';

type ElseStatementSimplifiedFormProps = {
  elseStatement: ElseStatement;
  title?: string;
};

const ElseStatementSimplifiedForm: React.FC<ElseStatementSimplifiedFormProps> = ({
  elseStatement,
  title,
}) => {
  const { t } = useTranslation();
  const [inputMode, setInputMode] = useState<StatementInputMode>(
    getStatementTextMode(elseStatement.text),
  );

  const contextMenuAction = useMemo(() => {
    if (inputMode === 'basic-input') {
      return {
        label: t('editor.conditionsChainingVariable.switchToAdvancedEditor'),
        onClick: () => setInputMode('rich-editor'),
      };
    }
    return null;
  }, [inputMode, t]);

  return (
    <div
      className={classNames(
        styles.statementWrapper,
        styles.simplifiedStatementWrapper,
      )}
    >
      <div className={styles.ifStatementHeader}>
        <h4>{title || t('editor.conditionsChainingVariable.else')}</h4>
      </div>
      <div className={styles.simplifiedStatementFieldWrapper}>
        {inputMode === 'basic-input' ? (
          <ElseStatementValueSimpleInput elseStatement={elseStatement} />
        ) : (
          <ElseStatementValueEditor elseStatement={elseStatement} />
        )}
        {contextMenuAction && (
          <div className={styles.changeInputModeWrapper}>
            <ContextMenu
              popupClassName={styles.popup}
              popupContainerStyle={{ maxWidth: '300px' }}
              actions={[contextMenuAction]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ElseStatementSimplifiedForm;
