import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SlackIntegration from '../../graphql/fragments/SlackIntegration';

export const mutation = gql`
  mutation deleteClientSlackIntegration($integrationId: ID!) {
    deleteClientSlackIntegration(integrationId: $integrationId) {
      id
      slackIntegration {
        ...SlackIntegration
      }
    }
  }
  ${SlackIntegration}
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    deleteClientSlackIntegration: ({ integrationId }) =>
      mutate({
        variables: {
          integrationId,
        },
      }),
  }),
});
