/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import React, { useMemo, useState } from 'react';
import _ from 'underscore';

import { useTranslation } from 'react-i18next';
import { getRandomString } from '@/common';
import { getSweetEvaluator } from '@/SweetEvaluator';
import getExternalExpressionEvaluatorFromType from '@/common/mergeTags/externalEvaluators';
import { FieldCondition } from '@/common/mergeTags/utils';
import styles from './ConditionsChaining.module.less';
import { useConditionsChainingContext } from './useConditionsChainingContext';
import { getUpdatedPreviewContext, SimplifiedField } from './utils';
import useConditionsChainingCustomFields from './useConditionsChainingCustomFields';
import ConditionsChainingPreviewFormField from './ConditionsChainingPreviewFormField';
import ConditionsChainingPreviewOutput from './ConditionsChainingPreviewOutput';

const ConditionsChainingPreview: React.FC = () => {
  const { t } = useTranslation();

  const { conditionsChainingState, clientId } = useConditionsChainingContext();
  const {
    profileCustomFieldsMap,
    missionCustomFieldsMap,
  } = useConditionsChainingCustomFields({ clientId });

  const [previewContext, setPreviewContext] = useState<Record<string, any>>({
    customFields: {},
    missionCustomFields: {},
  });

  const uniqFields = useMemo(() => {
    const fields: Record<string, SimplifiedField> = {};

    const setFieldConditionInMap = ({
      condition,
    }: {
      condition: FieldCondition;
    }) => {
      if (condition.fieldCategory === 'native') {
        fields[condition.fieldId] = {
          fieldId: condition.fieldId,
          fieldCategory: condition.fieldCategory,
          fieldType: condition.fieldType,
        };
      }
      if (condition.fieldCategory === 'custom-field') {
        const customField = profileCustomFieldsMap[condition.customFieldId];
        fields[condition.customFieldId] = {
          fieldId: condition.customFieldId,
          fieldCategory: condition.fieldCategory,
          fieldType: customField?.type === 'enum' ? 'select' : 'string',
        };
      }
      if (condition.fieldCategory === 'linked-mission-custom-field') {
        const customField = missionCustomFieldsMap[condition.customFieldId];
        fields[condition.customFieldId] = {
          fieldId: condition.customFieldId,
          fieldCategory: condition.fieldCategory,
          fieldType: customField?.type === 'enum' ? 'select' : 'string',
        };
      }
    };

    _.each(conditionsChainingState.ifStatements, (ifStatement) => {
      const { condition } = ifStatement;
      if (condition.type === 'field-condition') {
        setFieldConditionInMap({ condition });
      } else {
        _.each(condition.conditions, (subCondition) => {
          setFieldConditionInMap({ condition: subCondition });
        });
      }
    });

    return fields;
  }, [conditionsChainingState, profileCustomFieldsMap, missionCustomFieldsMap]);

  const getFormFields = () => {
    const fields = [];
    for (const [key, field] of Object.entries(uniqFields)) {
      fields.push(
        <ConditionsChainingPreviewFormField
          key={key}
          field={field}
          clientId={clientId}
          context={previewContext}
          onChange={onFieldChange}
        />,
      );
    }
    return fields;
  };

  const onFieldChange = ({
    key,
    field,
    newValue,
  }: {
    key: string;
    field: SimplifiedField;
    newValue: string;
  }) => {
    setPreviewContext((previousState) =>
      getUpdatedPreviewContext({
        context: previousState,
        field,
        key,
        newValue,
        profileCustomFieldsMap,
        missionCustomFieldsMap,
      }),
    );
  };

  const output = useMemo(() => {
    if (_.isEmpty(uniqFields)) {
      return '';
    }
    const sweetEvaluator = getSweetEvaluator({
      getExternalExpressionEvaluatorFromType,
    });
    const instantiatedSnippets = sweetEvaluator.getUpdatedVariables({
      variables: [
        {
          id: getRandomString(12),
          name: '',
          ...conditionsChainingState,
        } as any,
      ],
      context: previewContext,
    });

    return instantiatedSnippets?.[0]?.state?.value || '';
  }, [conditionsChainingState, uniqFields, previewContext]);

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.previewTitle}>
        {t(`editor.conditionsChainingVariable.preview.title`)}
      </h4>
      <span className={styles.previewSubtitle}>
        {t(`editor.conditionsChainingVariable.preview.subtitle`)}
      </span>
      <div className={styles.statementWrapper}>
        <div className={styles.ifStatementHeader}>
          <h4>{t(`editor.conditionsChainingVariable.preview.context`)}</h4>
        </div>
        {getFormFields()}
      </div>
      <div className={styles.statementWrapper}>
        <div className={styles.ifStatementHeader}>
          <h4>{t(`editor.conditionsChainingVariable.preview.display`)}</h4>
        </div>
        <ConditionsChainingPreviewOutput output={output} />
      </div>
    </div>
  );
};

export default ConditionsChainingPreview;
