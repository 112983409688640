import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './svg.module.less';

const Car: FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    className={classNames(styles.svgIcon, className)}
    width='14'
    height='13'
    viewBox='0 0 14 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.6666 11.3333H2.33325V12C2.33325 12.1768 2.26301 12.3464 2.13799 12.4714C2.01297 12.5964 1.8434 12.6667 1.66659 12.6667H0.999919C0.823108 12.6667 0.653538 12.5964 0.528514 12.4714C0.40349 12.3464 0.333252 12.1768 0.333252 12V5.33334L1.98659 1.47467C2.08947 1.23467 2.26056 1.03016 2.47862 0.886525C2.69669 0.742887 2.95213 0.66644 3.21325 0.666672H10.7866C11.0475 0.666701 11.3026 0.74327 11.5204 0.886893C11.7382 1.03052 11.9091 1.23488 12.0119 1.47467L13.6666 5.33334V12C13.6666 12.1768 13.5963 12.3464 13.4713 12.4714C13.3463 12.5964 13.1767 12.6667 12.9999 12.6667H12.3333C12.1564 12.6667 11.9869 12.5964 11.8618 12.4714C11.7368 12.3464 11.6666 12.1768 11.6666 12V11.3333ZM12.3333 6.66667H1.66659V10H12.3333V6.66667ZM1.78392 5.33334H12.2159L10.7873 2.00001H3.21325L1.78459 5.33334H1.78392ZM3.33325 9.33334C3.06804 9.33334 2.81368 9.22798 2.62615 9.04045C2.43861 8.85291 2.33325 8.59856 2.33325 8.33334C2.33325 8.06812 2.43861 7.81377 2.62615 7.62623C2.81368 7.4387 3.06804 7.33334 3.33325 7.33334C3.59847 7.33334 3.85282 7.4387 4.04036 7.62623C4.2279 7.81377 4.33325 8.06812 4.33325 8.33334C4.33325 8.59856 4.2279 8.85291 4.04036 9.04045C3.85282 9.22798 3.59847 9.33334 3.33325 9.33334ZM10.6666 9.33334C10.4014 9.33334 10.147 9.22798 9.95948 9.04045C9.77194 8.85291 9.66659 8.59856 9.66659 8.33334C9.66659 8.06812 9.77194 7.81377 9.95948 7.62623C10.147 7.4387 10.4014 7.33334 10.6666 7.33334C10.9318 7.33334 11.1862 7.4387 11.3737 7.62623C11.5612 7.81377 11.6666 8.06812 11.6666 8.33334C11.6666 8.59856 11.5612 8.85291 11.3737 9.04045C11.1862 9.22798 10.9318 9.33334 10.6666 9.33334Z'
      fill='#A5ABC9'
    />
  </svg>
);

export default Car;
