import _ from 'underscore';
import React, {
  FC,
  HTMLAttributes,
  ReactNode,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { DropdownGroupTitle } from '@/components/Reveal/Sequences/SequenceDropdown/DropdownSequenceList';
import DropdownControlsContext from '@/context/DropdownControlsContext';

import GenericCheckbox from '../../GenericCheckbox';

import styles from './SelectItemList.module.less';

export type BasicItemType = {
  content: ReactNode;
  disabled?: boolean;
  depth?: number;
  searchableText: string;
  id: string;
};

export type TreeItemType = BasicItemType & {
  items?: TreeItemType[];
  selected?: boolean;
};

export type ItemGroupType = {
  groupId: string;
  groupLabel: string;
  sortBy: {
    key: string;
    label: string;
  }[];
  filterBy: {
    key: string;
    label: string;
  }[];
  groupBy: {
    key: string;
    label: string;
  }[];
  currentSortKey: string | null;
  currentFilterKey: string | null;
  currentGroupKey: string | null;
  items: BasicItemType[];
  filteredEmptyState?: ReactNode;
  tree?: TreeItemType;
};

interface ItemProps extends HTMLAttributes<HTMLDivElement> {
  className: string;
  disabled?: boolean;
  multiSelect?: boolean;
  selected?: boolean;
  depth?: number;
}

const Item: FC<ItemProps> = ({
  className,
  disabled = false,
  children,
  multiSelect,
  selected = false,
  ...rest
}) => {
  return (
    <div
      className={classNames(className, {
        [styles.itemTitle]: _.isString(children),
        [styles.itemDisabled]: disabled,
        [styles.selected]: multiSelect && selected,
      })}
      style={{
        ...(rest?.depth &&
          rest.depth > 0 && { paddingLeft: `${15 * rest.depth + 16}px` }),
      }}
      {...rest}
    >
      <span className={styles.itemContent}>
        {multiSelect && (
          <GenericCheckbox checked={selected} className={styles.itemCheckbox} />
        )}
        <span className={styles.itemName}>{children}</span>
      </span>
    </div>
  );
};

interface SelectGroupProps {
  group: ItemGroupType;
  onGroupFiltered?: (groupId: string, filterKey: string | null) => void;
  onGroupSorted?: (groupId: string, sortKey: string | null) => void;
  onGroupGrouped?: (groupId: string, groupKey: string | null) => void;
  onItemSelected: (item: BasicItemType) => void;
  multiSelect?: boolean;
  selectedItemIds?: string[];
  onSelectAll?: () => void;
  onUnselectAll?: () => void;
  searchValue?: string;
}

const Group: FC<SelectGroupProps> = ({
  group,
  onGroupSorted,
  onGroupFiltered,
  onGroupGrouped,
  onItemSelected,
  multiSelect,
  selectedItemIds,
  onSelectAll,
  onUnselectAll,
  searchValue = '',
}) => {
  const {
    items = [],
    groupId,
    groupLabel,
    sortBy,
    filterBy,
    groupBy,
    currentSortKey,
    currentFilterKey,
    currentGroupKey,
    filteredEmptyState,
    tree,
  } = group;

  const searchChildrenFromValue = useCallback(
    (elems: TreeItemType[], value: string, depth: number) => {
      const matches: TreeItemType[] = [];
      _.each(elems, (elem) => {
        if (
          elem.searchableText
            ?.toLowerCase()
            ?.indexOf(searchValue?.toLowerCase()) >= 0
        ) {
          matches.push({
            content: elem.content,
            searchableText: elem.searchableText,
            depth,
            id: elem.id,
          });
        } else {
          const childMatches = searchChildrenFromValue(
            elem.items || [],
            value,
            depth + 1,
          );
          if (!_.isEmpty(childMatches)) {
            matches.push({
              content: elem.content,
              searchableText: elem.searchableText,
              depth,
              id: elem.id,
            });
            _.each(childMatches, (match) => {
              matches.push(match);
            });
          }
        }
      });
      return matches;
    },
    [searchValue],
  );

  const { t } = useTranslation();
  const filteredItems = useMemo(
    () =>
      searchValue
        ? searchChildrenFromValue(tree?.items || items, searchValue, 0)
        : items,
    [searchValue, tree, searchChildrenFromValue, items],
  );

  const { closeDropdown } = useContext(DropdownControlsContext);

  return (
    <>
      <DropdownGroupTitle
        key={groupId}
        groupName={groupLabel}
        sortByOptions={sortBy}
        filterByOptions={filterBy}
        groupByOptions={groupBy}
        sortBy={currentSortKey}
        filterBy={currentFilterKey}
        groupBy={currentGroupKey}
        onSortBy={(sortKey) => {
          if (onGroupSorted) {
            onGroupSorted(groupId, sortKey);
          }
        }}
        onFilterBy={(filterKey) => {
          if (onGroupFiltered) {
            onGroupFiltered(groupId, filterKey);
          }
        }}
        onGroupBy={(groupKey) => {
          if (onGroupGrouped) {
            onGroupGrouped(groupId, groupKey);
          }
        }}
        coloredGroupBackground={!multiSelect}
      />
      {_.isEmpty(items) && filteredEmptyState}
      <div className={styles.itemsContainer}>
        {!_.isEmpty(items) &&
          _.map(filteredItems, (item) => {
            const { id, disabled, content, depth } = item;

            return (
              <Item
                key={id}
                className={styles.item}
                disabled={disabled}
                onClick={() => {
                  if (!disabled) {
                    if (!multiSelect) {
                      closeDropdown();
                    }
                    onItemSelected(item);
                  }
                }}
                onKeyPress={() => {
                  if (!disabled) {
                    if (!multiSelect) {
                      closeDropdown();
                    }
                    onItemSelected(item);
                  }
                }}
                role='button'
                multiSelect={multiSelect}
                selected={_.contains(selectedItemIds || [], item.id)}
                depth={depth}
              >
                {content}
              </Item>
            );
          })}
      </div>
      {!_.isEmpty(items) && multiSelect && (
        <div className={styles.selectors}>
          <span
            onClick={onSelectAll}
            className={
              items.length === (selectedItemIds || []).length
                ? styles.disabled
                : ''
            }
          >
            {t('profile.contact.filtering.selectAll')}
          </span>
          <span
            onClick={onUnselectAll}
            className={
              (selectedItemIds || []).length === 0 ? styles.disabled : ''
            }
          >
            {t('profile.contact.filtering.unselectAll')}
          </span>
        </div>
      )}
    </>
  );
};

export interface SelectItemListProps {
  items?: BasicItemType[];
  groups?: ItemGroupType[];
  onItemSelected: (item: BasicItemType, value?: boolean) => void;
  onGroupFiltered?: (groupId: string, filterKey: string | null) => void;
  onGroupSorted?: (groupId: string, sortKey: string | null) => void;
  onGroupGrouped?: (groupId: string, groupKey: string | null) => void;
  multiSelect?: boolean;
  selectedItemIds?: string[];
  onSelectAll?: () => void;
  onUnselectAll?: () => void;
  searchValue?: string;
}

const SelectItemList: React.FC<SelectItemListProps> = ({
  items = [],
  groups = [],
  onItemSelected,
  onGroupFiltered,
  onGroupSorted,
  onGroupGrouped,
  multiSelect,
  selectedItemIds,
  onSelectAll,
  onUnselectAll,
  searchValue = '',
}) => {
  const { closeDropdown } = useContext(DropdownControlsContext);

  return (
    <div
      className={classNames(
        styles.itemList,
        multiSelect ? styles.checkBoxItemList : null,
      )}
    >
      {/* Render all items without group first */}
      {_.map(items, (item) => {
        const { id, content, disabled } = item;

        return (
          <Item
            key={id}
            className={styles.item}
            disabled={disabled}
            onClick={() => {
              if (!disabled) {
                if (!multiSelect) {
                  closeDropdown();
                }
                onItemSelected(item);
              }
            }}
            onKeyPress={() => {
              if (!disabled) {
                if (!multiSelect) {
                  closeDropdown();
                }
                onItemSelected(item);
              }
            }}
            role='button'
            multiSelect={multiSelect}
            selected={_.contains(selectedItemIds || [], item.id)}
          >
            {content}
          </Item>
        );
      })}
      {/* And then all groups */}
      {_.map(groups, (group) => (
        <Group
          key={group.groupId}
          group={group}
          onItemSelected={onItemSelected}
          onGroupFiltered={onGroupFiltered}
          onGroupSorted={onGroupSorted}
          onGroupGrouped={onGroupGrouped}
          multiSelect={multiSelect}
          selectedItemIds={selectedItemIds}
          onSelectAll={onSelectAll}
          onUnselectAll={onUnselectAll}
          searchValue={searchValue}
        />
      ))}
    </div>
  );
};

export default SelectItemList;
