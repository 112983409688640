import { graphql } from '@apollo/client/react/hoc';
import { REGISTER_PROFILE_VIEW } from '@/graphql/searchPoolProfiles';

export default graphql(REGISTER_PROFILE_VIEW, {
  props: ({ mutate }) => ({
    registerView: ({ profileId }) => {
      return mutate({
        variables: {
          searchPoolId: 'watch',
          profileId,
          origin: {
            type: 'app',
            URL: window.location.href,
          },
        },
      });
    },
  }),
});
