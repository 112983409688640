import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';

import withActionLogger from '../../../../hocs/withActionLogger';
import Experience from './Experience';

import './Experiences.css';

class Experiences extends React.PureComponent {
  componentWillReceiveProps(nextProps) {
    if (nextProps.profileId !== this.props.profileId) {
      this.setState({ isOpened: false });
    }
  }

  componentWillMount() {
    this.setState((state) => ({ isOpened: state?.isOpened || false }));
  }

  handleToggleExperiences = () => {
    this.setState(({ isOpened }) => ({ isOpened: !isOpened }));
  };

  render() {
    const { isOpened } = this.state;
    const {
      experiences,
      t,
      profileId,
      onLogAction,
      hoverPopupsDisabled,
      applicationMode,
    } = this.props;
    const experienceGroups = makeGroups({ experiences });
    const displayToggle = (experienceGroups || []).length > 5;
    const displayExperience = (index) =>
      !displayToggle ||
      (displayToggle && !isOpened && index < 4) ||
      (displayToggle && isOpened);
    return (
      <div>
        {_.map(experienceGroups, (group, index) => {
          if (!displayExperience(index)) {
            return null;
          }
          return (
            <Experience
              applicationMode={applicationMode}
              key={index}
              groupedExperiences={group?.groupedExperiences}
              profileId={profileId}
              logAction={onLogAction}
              hoverPopupsDisabled={hoverPopupsDisabled}
            />
          );
        })}
        {displayToggle ? (
          isOpened === false ? (
            <div
              className='background-toggle down body-medium'
              onClick={() => this.handleToggleExperiences()}
            >
              {t('profile.contact.timeline.seeMore')}
            </div>
          ) : (
            <div
              className='background-toggle up body-medium'
              onClick={() => this.handleToggleExperiences()}
            >
              {t('profile.contact.timeline.seeLess')}
            </div>
          )
        ) : null}
      </div>
    );
  }
}

const makeGroups = ({ experiences }) => {
  return _.reduce(
    experiences,
    (groups, experience) => {
      let currentGroup = groups.pop();
      if (_.isEmpty(currentGroup)) {
        currentGroup = { groupedExperiences: [experience] };
        return [...(groups || []), currentGroup];
      }

      const lastExperience = _.last(currentGroup?.groupedExperiences);
      if (lastExperience?.companyName === experience?.companyName) {
        currentGroup.groupedExperiences.push(experience);
        return [...(groups || []), currentGroup];
      }

      const newGroup = { groupedExperiences: [experience] };
      return [...(groups || []), currentGroup, newGroup];
    },
    [],
  );
};

export default compose(
  withActionLogger,
  withTranslation('translations'),
)(Experiences);
