import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommentBody } from '@/containers/PluginProfile/components/Comment';
import classNames from 'classnames';
import TASK_ICONS from '../TaskIcons';
import AuthorAndDate from '../AuthorAndDate';

const TaskSnoozedItem = ({
  profile,
  item,
  unbiasedModeEnabled,
  setOverflowStatus,
  textAreaStyle,
  isBodyOverflown,
  showMoreClicked,
  onShowMoreClicked,
}) => {
  const { t } = useTranslation();
  const {
    action: { snoozeDate },
  } = item || {};
  const { firstname: profileFirstname, lastname: profileLastname } =
    profile?.resumeData || {};

  let profileName = `${profileFirstname || ''} ${profileLastname || ''}`.trim();
  profileName = unbiasedModeEnabled ? '???' : profileName;

  const { action } = item;

  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{ backgroundColor: TASK_ICONS.taskSnoozed?.color }}
              className='icon'
            >
              <i className={`${TASK_ICONS.taskSnoozed?.icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  `profile.contact.timeline.taskSnoozedItem.description`,
                  {
                    profileName,
                    snoozeDate: t('common.formattedDate', {
                      date: new Date(snoozeDate),
                    }),
                  },
                ),
              }}
            />
            <AuthorAndDate timelineItem={item} />
          </div>
        </div>
        {action.snoozeComment && (
          <div className='card-content comment'>
            <div className='row'>
              {action.snoozeReasonTitle && (
                <>
                  <div className='title'>{action.snoozeReasonTitle}</div>
                  <div className='spacer' />
                </>
              )}
            </div>
            <div
              ref={setOverflowStatus}
              className='body'
              style={{
                ...(textAreaStyle.height && {
                  height: textAreaStyle.height,
                }),
              }}
            >
              <div className='body-content'>
                {action.snoozeComment && (
                  <CommentBody content={action.snoozeComment} />
                )}
              </div>
              {isBodyOverflown && (
                <div
                  className={classNames('gradient-message', {
                    hidden: showMoreClicked,
                  })}
                />
              )}
            </div>
            <div className='spacer' />
            {isBodyOverflown && !showMoreClicked && (
              <div className='show-more' onClick={onShowMoreClicked}>
                {t('profile.contact.timeline.seeMore')}
              </div>
            )}
            {isBodyOverflown && showMoreClicked && (
              <div className='show-more' onClick={onShowMoreClicked}>
                {t('profile.contact.timeline.seeLess')}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskSnoozedItem;
