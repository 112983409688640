/* eslint-disable max-len */

import React, { FC } from 'react';

import classnames from 'classnames';
import styles from './svg.module.less';

const ArrowUp: FC = () => (
  <svg
    className={classnames(styles.svgIcon, styles.rotate180)}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.70713 10.2929C8.3166 10.6834 7.68344 10.6834 7.29292 10.2929L3.70713 6.70711C3.07716 6.07714 3.52333 5 4.41424 5H11.5858C12.4767 5 12.9229 6.07714 12.2929 6.70711L8.70713 10.2929Z'
      fill='currentColor'
    />
  </svg>
);

export default ArrowUp;
