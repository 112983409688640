import classNames from 'classnames';
import React, {
  ChangeEventHandler,
  forwardRef,
  useMemo,
  ForwardRefRenderFunction,
  ComponentProps,
} from 'react';

import styles from './GenericTextArea.module.less';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    'data-form-type'?: string;
  }
}

interface GenericTextAreaProps extends ComponentProps<'textarea'> {
  onValue?: (value: string) => void;
}

const ForwardedGenericTextArea: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  GenericTextAreaProps
> = (
  { onValue, className, onChange, 'data-form-type': dataFormType, ...props },
  ref,
) => {
  const handleChange = useMemo<
    ChangeEventHandler<HTMLTextAreaElement> | undefined
  >(
    () =>
      onChange ||
      (onValue !== undefined
        ? ({ target }) => onValue(target.value)
        : undefined),
    [onChange, onValue],
  );

  return (
    <textarea
      className={classNames(styles.textarea, className)}
      onChange={handleChange}
      data-form-type={dataFormType || 'other'}
      {...props}
      ref={ref}
    />
  );
};

const GenericTextArea = forwardRef(ForwardedGenericTextArea);

export default GenericTextArea;
