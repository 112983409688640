import {
  useQuery,
  QueryResult,
  useMutation,
  MutationTuple,
} from '@apollo/client';
import gql from 'graphql-tag';
import { GET_CLIENT_CRM_DESCRIPTOR } from '@/graphql/clients';
import { ClientCrmDescriptor as IClientCrmDescriptor } from '@/types/client';
import ClientCrmDescriptor from '@/graphql/fragments/ClientCrmDescriptor';

export type ResultClientCrmDescriptor = {
  client: {
    crmDescriptor: IClientCrmDescriptor;
  };
};

export function useClientCrmDescriptor(
  clientId: string,
): QueryResult<ResultClientCrmDescriptor, { clientId: string }> & {
  crmDescriptor: IClientCrmDescriptor | null;
} {
  const query = useQuery<ResultClientCrmDescriptor, { clientId: string }>(
    GET_CLIENT_CRM_DESCRIPTOR,
    { variables: { clientId } },
  );

  const { crmDescriptor } = query.data?.client || {};

  return {
    ...query,
    crmDescriptor: crmDescriptor ?? null,
  };
}

export default useClientCrmDescriptor;

const updateClientCrmDescriptor = gql`
  mutation updateClientCrmDescriptor($input: UpdateClientCrmDescriptorInput) {
    updateClientCrmDescriptor(input: $input) {
      id
      crmDescriptor {
        ...ClientCrmDescriptor
      }
    }
  }
  ${ClientCrmDescriptor}
`;

export interface ClientCrmDescriptorInput {
  name?: string;
  website?: string;
  logo?: {
    name: string;
    file: {
      content: string;
    };
  };
  linkedin?: string;
  crunchbase?: string;
  description?: string;
}

export function useClientCrmDescriptorMutation(): MutationTuple<
  {
    updateClientCrmDescriptor: {
      crmDescriptor: IClientCrmDescriptor;
    };
  },
  { input: ClientCrmDescriptorInput }
> {
  return useMutation(updateClientCrmDescriptor);
}
