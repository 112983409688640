import _ from 'underscore';

class BoardStateHandler {
  constructor({ boardState }) {
    const {
      contactedProfileIds,
      answeredProfileIds,
      inProcessProfileIds,
      hiredProfileIds,
      setContactedProfileIds,
      setAnsweredProfileIds,
      setInProcessProfileIds,
      setHiredProfileIds,
    } = boardState || {};

    this.stepToProfileIds = {
      contacted: contactedProfileIds,
      answered: answeredProfileIds,
      'in-process': inProcessProfileIds,
      hired: hiredProfileIds,
    };

    this.stepToSetter = {
      contacted: setContactedProfileIds,
      answered: setAnsweredProfileIds,
      'in-process': setInProcessProfileIds,
      hired: setHiredProfileIds,
    };
  }

  getProfileIds = (step) => {
    return this.stepToProfileIds[step];
  };

  addProfileToStep = ({ profileId, step, index }) => {
    const setter = this.stepToSetter[step];
    setter((oldIds) => {
      const newIds = [...oldIds];
      newIds.splice(index, 0, profileId);
      return newIds;
    });
  };

  removeProfileFromStep = ({ profileId, step }) => {
    const setter = this.stepToSetter[step];
    setter((oldIds) => _.without(oldIds, profileId));
  };

  changeProfileStep = ({ from, to, profileId, index }) => {
    this.removeProfileFromStep({ profileId, step: from });
    this.addProfileToStep({ profileId, step: to, index });
  };
}

export default BoardStateHandler;
