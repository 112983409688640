import { useTranslation } from 'react-i18next';
import React, { useState, useContext, useMemo } from 'react';
import { Modal } from 'semantic-ui-react';
import _ from 'underscore';

import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import ArrowRight from '@/components/Reveal/Icons/ArrowRight';
import GenericSelect from '@/components/Common/GenericSelect';
import MissionCategoryContext from '@/context/MissionCategoryContext';

import styles from './HiredNomenclatureModal.module.less';

interface HiredNomenclatureModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  initialValue: string;
  onSubmit: (value: string) => void;
}

const HiredNomenclatureModal: React.FC<HiredNomenclatureModalProps> = ({
  open,
  setOpen,
  initialValue,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const missionCategory = useContext(MissionCategoryContext);
  const [value, setValue] = useState(initialValue);
  const options = useMemo(
    () => [
      {
        value: 'hired',
        label: t('superPipelineSettings.nomenclature.options.hired', {
          count: 1,
        }),
      },
      {
        value: 'closed',
        label: t('superPipelineSettings.nomenclature.options.closed', {
          count: 1,
        }),
      },
      {
        value: 'engaged',
        label: t('superPipelineSettings.nomenclature.options.engaged', {
          count: 1,
        }),
      },
      {
        value: 'success',
        label: t('superPipelineSettings.nomenclature.options.success', {
          count: 1,
        }),
      },
      {
        value: 'signed',
        label: t('superPipelineSettings.nomenclature.options.signed', {
          count: 1,
        }),
      },
      {
        value: 'subscribed',
        label: t('superPipelineSettings.nomenclature.options.subscribed', {
          count: 1,
        }),
      },
      {
        value: 'registered',
        label: t('superPipelineSettings.nomenclature.options.registered', {
          count: 1,
        }),
      },
      {
        value: 'confirmed',
        label: t('superPipelineSettings.nomenclature.options.confirmed', {
          count: 1,
        }),
      },
      {
        value: 'validated',
        label: t('superPipelineSettings.nomenclature.options.validated', {
          count: 1,
        }),
      },
    ],
    [t],
  );

  const defaultOption = useMemo(
    () =>
      _.findWhere(options, {
        value: missionCategory === 'sales' ? 'signed' : 'hired',
      }),
    [options, missionCategory],
  );

  const sortedOptions = useMemo(() => _.sortBy(options, 'label'), [options]);

  return (
    <GenericModal size='tiny' open={open} onClose={() => setOpen(false)}>
      <Modal.Header>{t('superPipelineSettings.editHiredNaming')}</Modal.Header>
      <Modal.Content>
        <p className={styles.modalDescription}>
          {t('superPipelineSettings.editHiredDescription')}
        </p>
        <div className={styles.renameStepsModalContainer}>
          <div className={styles.header}>
            <div className={styles.sectionName}>
              {t('superPipelineSettings.nomenclature.modal.original')}
            </div>
            <div className={styles.sectionName}>
              {t('superPipelineSettings.nomenclature.modal.new')}
            </div>
          </div>
          <div className={styles.stepContainer}>
            <div className={styles.originalStep}>
              {defaultOption?.label ||
                t('superPipelineSettings.nomenclature.options.hired_one')}
            </div>
            <div className={styles.arrow}>
              <ArrowRight />
            </div>
            <div className={styles.newStepContainer}>
              <GenericSelect
                className={styles.scopeSelector}
                onChange={(e) => setValue(e?.value || '')}
                value={_.findWhere(sortedOptions, {
                  value,
                })}
                options={_.filter(
                  sortedOptions,
                  (option) => value !== option.value,
                )}
              />
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions className={styles.actions}>
        <GenericButton
          size='big'
          primacy='secondary'
          onClick={() => setOpen(false)}
        >
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          size='big'
          primacy='primary'
          onClick={() => onSubmit(value)}
          disabled={value === initialValue}
        >
          {t('common.save')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default HiredNomenclatureModal;
