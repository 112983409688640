import React from 'react';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import contextToProps from '@/hocs/contextToProps';
import { UPDATE_PROFILE_ACTIVITY } from '@/graphql/searchPoolProfile';

import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import { Profile } from '@/types/profile';
import LogActivityTimelineItem, {
  LogActivityTimeLineItemType,
  ActivityLogInput,
} from './LogActivityTimelineItem';

type LogActivityTimelineItemConnectedProps = {
  searchPoolId: string;
  timelineItem: LogActivityTimeLineItemType;
  applicationMode: string;
  onShowNotification: (notification: {
    message: string;
    level: 'error' | 'success';
    type?: string;
  }) => void;
  unbiasedModeEnabled: boolean;
  profile: SearchPoolProfile | Profile;
  clientId: string;
  t: TFunction;
  author: any;
};

export function LogActivityTimelineItemConnected(
  props: LogActivityTimelineItemConnectedProps,
) {
  const {
    clientId,
    searchPoolId,
    t,
    profile,
    timelineItem,
    author,
    onShowNotification: _osn,
    applicationMode,
    ...otherProps
  } = props;
  const [editMode, setEditMode] = React.useState(false);
  const [updateLogActivity] = useMutation(UPDATE_PROFILE_ACTIVITY);
  const { error, success } = useNotificationSystem();

  const onEdit = async (payload?: ActivityLogInput) => {
    try {
      await updateLogActivity({
        variables: {
          searchPoolId,
          profileId: profile.id,
          date: timelineItem.date,
          payload,
        },
      });
      const message = payload
        ? t('reveal.timeline.activityLog.notifications.updateSuccess')
        : t('reveal.timeline.activityLog.notifications.deletionSuccess');
      success(message);
      setEditMode(false);
    } catch (e) {
      const message = payload
        ? t('reveal.timeline.activityLog.notifications.updateError')
        : t('reveal.timeline.activityLog.notifications.deletionError');
      error(message);
      Sentry.captureException(e);
    }
  };

  // onRemove != onEdit (onRemove is called with MouseEvent)
  const onRemove = () => onEdit();
  return (
    <LogActivityTimelineItem
      {...otherProps}
      editMode={editMode}
      setEditMode={setEditMode}
      profile={profile}
      onEdit={onEdit}
      onRemove={onRemove}
      timelineItem={timelineItem}
      author={author}
      applicationMode={applicationMode}
      clientId={clientId}
    />
  );
}

export default compose(
  contextToProps,
  withTranslation('translations'),
)(LogActivityTimelineItemConnected);
