import React, { FC, useContext, useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import useSnoozeReasonsOverviewWithDateDetails from '@/graphql/hooks/clients/statistics/useSnoozeReasonsOverviewDateDetails';
import { useUserAllowedDepartments } from '@/graphql/hooks/users/useUserAllowedDepartments';
import useMissionsWithFoldering from '@/graphql/hooks/searchPoolJobs/useMissionsWithFoldering';

import StatisticsParametersContext from '../../../StatisticsParametersContext';
import GraphWrapper from '../../../components/GraphWrapper/GraphWrapper';
import DailyEventsDataContainer from '../../../components/DailyEventsDataContainer/DailyEventsDataContainer';

import { getDailyEventsFromDetails, buildStack } from './helpers';

import styles from './SnoozeReasonsByDate.module.less';

const SnoozeReasonsByDate: FC = () => {
  const { t } = useTranslation();
  const [{ missionIds, timeSpan, aggregatePeriod }] = useContext(
    StatisticsParametersContext,
  );
  const { subsections } = useUserAllowedDepartments();
  const { missionsWithFoldering } = useMissionsWithFoldering();

  const subsectionIdMap = useMemo(() => {
    const result = {} as Record<string, boolean>;
    _.each(subsections, ({ id }) => {
      result[id] = true;
    });
    return result;
  }, [subsections]);

  const availableMissionIds = useMemo(() => {
    const result = [] as string[];
    _.each(missionsWithFoldering, ({ id, foldering }) => {
      if (
        foldering?.subsection?.id &&
        subsectionIdMap[foldering.subsection.id]
      ) {
        result.push(id);
      }
    });
    return result;
  }, [missionsWithFoldering, subsectionIdMap]);

  const sentMissionIds = useMemo(
    () => (_.isEmpty(missionIds) ? availableMissionIds : missionIds),
    [missionIds, availableMissionIds],
  );

  const {
    snoozeReasonsOverviewWithDateDetails,
  } = useSnoozeReasonsOverviewWithDateDetails({
    missionsFilter: { in: sentMissionIds },
    startDate: timeSpan.startDate.format('YYYY-MM-DD'),
    endDate: timeSpan.endDate.format('YYYY-MM-DD'),
  });

  const dailyEvents = useMemo(
    () => getDailyEventsFromDetails(snoozeReasonsOverviewWithDateDetails),
    [snoozeReasonsOverviewWithDateDetails],
  );

  const stack = useMemo(
    () => buildStack(snoozeReasonsOverviewWithDateDetails),
    [snoozeReasonsOverviewWithDateDetails],
  );

  return (
    <div className={styles.card}>
      <GraphWrapper
        timeSpan={timeSpan}
        lockFrequency
        title={
          <h3 className={styles.title}>
            {t('reveal.reports.adventure.snoozeReasonsByDate.title')}
          </h3>
        }
        renderChildren={(_f, mode, type) => (
          <DailyEventsDataContainer
            dailyEvents={dailyEvents}
            frequency={aggregatePeriod}
            dateRange={timeSpan}
            mode={mode}
            type={type}
            stack={stack}
          />
        )}
      />
    </div>
  );
};

export default SnoozeReasonsByDate;
