import React, { useContext, useEffect, useState } from 'react';
import {
  Loader,
  ModalActions as Actions,
  ModalContent as Content,
  ModalHeader as Header,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import GenericButton from '@/components/Common/GenericButton';
import './TargetAccountModal.less';
import EmailContext from '@/context/EmailContext';

const TargetAccountModalStep1 = ({
  setModalOpen,
  toNextStep,
  addressToConnect,
  setHasTriedConnexion,
}) => {
  const { t } = useTranslation();
  const {
    connectionInProgress,
    offlineError,
    currentAddress,
    onTriggerOfflineRegister,
  } = useContext(EmailContext);

  const [completedRegistration, setCompletedRegistration] = useState(false);
  const [registrationFailed, setRegistrationFailed] = useState(false);

  useEffect(() => {
    if (!registrationFailed && offlineError) {
      setRegistrationFailed(true);
    }
  }, [offlineError, registrationFailed]);

  useEffect(() => {
    if (!completedRegistration) {
      return;
    }
    if (offlineError) {
      setCompletedRegistration(false);
      return;
    }
    if (currentAddress !== addressToConnect) {
      setRegistrationFailed(true);
      return;
    }
    toNextStep();
  }, [
    completedRegistration,
    offlineError,
    toNextStep,
    currentAddress,
    addressToConnect,
  ]);

  const onConnectGmail = async () => {
    try {
      await onTriggerOfflineRegister('gmail', 'select_account');
    } catch (e) {
      console.error(e);
    }
    setHasTriedConnexion(true);
    setCompletedRegistration(true);
  };

  const onConnectOutlook = async () => {
    try {
      await onTriggerOfflineRegister('outlook', 'select_account');
    } catch (e) {
      console.error(e);
    }
    setHasTriedConnexion(true);
    setCompletedRegistration(true);
  };

  return (
    <>
      <Header>{t('settings.targetAccount.modalStep1.header')}</Header>
      <Content>
        <div className='description'>
          {t('settings.targetAccount.modalStep1.description', {
            account: addressToConnect,
          })}
        </div>
        {connectionInProgress ? (
          <div className='signin-buttons'>
            <div className='email-signin-button loader-button'>
              <Loader inline active size='medium' className='on-dimmer' />
              <span>{t('profile.onboarding.connectionInProgress')}</span>
            </div>
          </div>
        ) : (
          <div className='signin-buttons'>
            <button
              type='button'
              className='email-signin-button'
              onClick={onConnectGmail}
              disabled={connectionInProgress}
              title={t('settings.targetAccount.modalStep1.connectGmail')}
            >
              <img alt='Gmail' src='/images/logos/gmail.svg' width='36px' />
              <span>{t('settings.targetAccount.modalStep1.connectGmail')}</span>
            </button>
            <button
              type='button'
              className='email-signin-button'
              onClick={onConnectOutlook}
              disabled={connectionInProgress}
              title={t('settings.targetAccount.modalStep1.connectOutlook')}
            >
              <img alt='Outlook' src='/images/logos/outlook.svg' width='36px' />
              <span>
                {t('settings.targetAccount.modalStep1.connectOutlook')}
              </span>
            </button>
            <div className='confidentiality-text'>
              {t('settings.targetAccount.modalStep1.confidentiality')}
            </div>
          </div>
        )}
      </Content>
      <Actions>
        <div className='modal-actions'>
          <GenericButton
            onClick={() => setModalOpen(false)}
            size='big'
            primacy='secondary'
          >
            {t('common.cancel')}
          </GenericButton>
          <GenericButton
            type='submit'
            onClick={() => toNextStep()}
            size='big'
            disabled={!currentAddress && currentAddress !== addressToConnect}
          >
            {t('settings.targetAccount.modalNext')}
          </GenericButton>
        </div>
      </Actions>
    </>
  );
};

export default TargetAccountModalStep1;
