import React from 'react';
import _ from 'underscore';
import useJobProject from '../../../../../graphql/hooks/searchPoolJobs/useJobProject';
import TodosContent from '../../../TodosView/TodosContent';

interface ImportedCandidatesTabProps {
  jobId: string;
  clientId: string;
}

const ImportedCandidatesTab: React.FC<ImportedCandidatesTabProps> = ({ jobId, clientId }) => {
  const { data: project, loading } = useJobProject('reveal', jobId, 'imported-profiles');
  if (loading || !project) {
    return null;
  }
  const tasks = _.flatten(
    _.map(project?.queues, (queue) => {
      return _.map(queue?.tasks, (task) => ({ ...task, projectId: project.id, projectTitle: project.title }));
    }),
  );
  return <TodosContent tasks={tasks} selectedTodos={[project]} clientId={clientId} loading={false} />;
};

export default ImportedCandidatesTab;
