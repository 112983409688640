import { CompanyStateType } from '@/containers/Parameters/CustomCompaniesStates/CompaniesStates';
import { TranslatableText } from '@/types/text';
import { gql, useMutation } from '@apollo/client';

const UPDATE_CUSTOM_CLIENT_COMPANY_STATE = gql`
  mutation UpdateClientCompanyState(
    $customCompanyDealStateId: ID!
    $companyState: UpdateClientCompanyStateInput
  ) {
    updateClientCompanyState(
      customCompanyDealStateId: $customCompanyDealStateId
      companyState: $companyState
    ) {
      id
      customCompanyDealStates {
        customCompanyDealStateId
        title {
          en
          fr
          default
        }
        type
      }
    }
  }
`;

interface UpdateClientCustomCompanyDealStateVariables {
  customCompanyDealStateId: string;
  companyState: CompanyStateType;
}

interface ClientCustomCompanyDealStateResults {
  client: {
    customCompanyDealStates: {
      customCompanyDealStateId: string;
      title: TranslatableText;
      type: string;
    }[];
  };
}

const useUpdateCustomClientCompanyState = () => {
  return useMutation<
    ClientCustomCompanyDealStateResults,
    UpdateClientCustomCompanyDealStateVariables
  >(UPDATE_CUSTOM_CLIENT_COMPANY_STATE, {
    // refetchQueries: ['getCustomCompanyDealStates'],
  });
};

export default useUpdateCustomClientCompanyState;
