import React, {
  FC,
  Dispatch,
  useLayoutEffect,
  SetStateAction,
  useContext,
  useState,
  useMemo,
} from 'react';
import _ from 'underscore';
import { Input } from 'semantic-ui-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CrossIcon } from '@/assets/icons';
import { invalidEmailFormat } from '@/common/validators';

import GenericDropdown from '@/components/Common/GenericDropdown';
import DropdownMenuPanel from '@/components/Common/DropdownMenuPanel';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import styles from './RevealCcBcc.module.less';

// TODO check if sender exists elsewhere
interface Sender {
  id: string;
  senderAddress: string;
}

interface RevealCcBccContextValue {
  ccShown: boolean;
  bccShown: boolean;
  soboShown: boolean;
  setCcShown: (value: boolean) => void;
  setBccShown: (value: boolean) => void;
  setSoboShown: (value: boolean) => void;
}

interface RevealCcBccContextProps {
  cc?: string[] | null;
  bcc?: string[] | null;
  sobo?: string | null;
}

// "parent" components should not need to handle if we display the inputs or not
// This leads to code duplication and harder refactors
const RevealCcBccContext = React.createContext<RevealCcBccContextValue | null>(
  null,
);

// We export a context since the controller buttons
// might not be "near" the actual inputs
export const RevealCcBccContextProvider: FC<RevealCcBccContextProps> = ({
  children,
  cc,
  bcc,
  sobo,
}) => {
  const [ccState, setCcShown] = useState(false);
  const [bccState, setBccShown] = useState(false);
  const [soboState, setSoboShown] = useState(false);

  // I'm adding these useMemos for "controllability"
  // since we have some async values changing the display.
  // This way we also centralize the logic that should always be the same:
  // if we have any value we display the inputs, if not it depends solely on the state
  const ccShown = useMemo(() => !_.isEmpty(cc) || ccState, [ccState, cc]);
  const bccShown = useMemo(() => !_.isEmpty(bcc) || bccState, [bccState, bcc]);
  const soboShown = useMemo(() => !_.isEmpty(sobo) || soboState, [
    sobo,
    soboState,
  ]);

  return (
    <RevealCcBccContext.Provider
      value={{
        ccShown,
        setCcShown,
        bccShown,
        setBccShown,
        soboShown,
        setSoboShown,
      }}
    >
      {children}
    </RevealCcBccContext.Provider>
  );
};

export const useRevealCcBccContext = (): RevealCcBccContextValue => {
  const context = useContext(RevealCcBccContext);
  if (!context) {
    throw new Error(
      'Did you forget to wrap your components in a RevealCcBccContextProvider?',
    );
  }

  return context;
};

export const RevealCcBccSelector: FC<{
  allowSobo?: boolean;
}> = ({ allowSobo = false }) => {
  const { t } = useTranslation();

  const {
    ccShown,
    bccShown,
    soboShown,
    setSoboShown,
    setCcShown,
    setBccShown,
  } = useRevealCcBccContext();

  return (
    <div className={styles.bcSelector}>
      {!ccShown && (
        <span
          role='button'
          onClick={() => setCcShown(true)}
          onKeyPress={() => setCcShown(true)}
        >
          Cc
        </span>
      )}
      {!bccShown && (
        <span
          role='button'
          onClick={() => setBccShown(true)}
          onKeyPress={() => setBccShown(true)}
        >
          Bcc
        </span>
      )}
      {allowSobo && !soboShown && (
        <span
          role='button'
          onClick={() => setSoboShown(true)}
          onKeyPress={() => setSoboShown(true)}
        >
          {t(`profile.contact.timeline.sobo`)}
        </span>
      )}
    </div>
  );
};

interface RevealCcBccInputsProps {
  onCc: Dispatch<SetStateAction<string[]>> | ((addresses: string[]) => void);
  cc: string[];
  onBcc: Dispatch<SetStateAction<string[]>> | ((addresses: string[]) => void);
  bcc: string[];
  // TODO: check what is sobo
  onSoboSelected: (sobo: any) => void;
  selectedSobo: Sender;
  soboSenders: Sender[];
  inputClassName?: string;
  isUnauthorizedSender?: boolean;
}

export const RevealCcBccInputs: React.FC<RevealCcBccInputsProps> = ({
  onCc,
  cc,
  onBcc,
  bcc,
  selectedSobo,
  soboSenders,
  onSoboSelected,
  inputClassName = '',
  isUnauthorizedSender = false,
}) => {
  const {
    ccShown,
    bccShown,
    soboShown,
    setCcShown,
    setBccShown,
    setSoboShown,
  } = useRevealCcBccContext();

  useLayoutEffect(() => {
    if (isUnauthorizedSender && !soboShown) {
      setSoboShown(true);
    }
  }, [isUnauthorizedSender, soboShown, setSoboShown]);

  return (
    <div>
      {ccShown && (
        <RevealCcBccInput
          setAddressesSelected={setCcShown as Dispatch<SetStateAction<boolean>>}
          setAddresses={onCc as Dispatch<SetStateAction<string[]>>}
          addresses={cc}
          type='cc'
          className={inputClassName}
        />
      )}
      {bccShown && (
        <RevealCcBccInput
          setAddressesSelected={
            setBccShown as Dispatch<SetStateAction<boolean>>
          }
          setAddresses={onBcc as Dispatch<SetStateAction<string[]>>}
          addresses={bcc}
          type='bcc'
          className={inputClassName}
        />
      )}
      {(soboShown || isUnauthorizedSender) && (
        <RevealCcBccSoboSelector
          sobo={selectedSobo}
          senders={soboSenders}
          isUnauthorizedSender={isUnauthorizedSender}
          className={inputClassName}
          onClose={() => setSoboShown(false)}
          onSoboSelected={onSoboSelected}
        />
      )}
    </div>
  );
};

interface RevealCcBccInputProps {
  setAddressesSelected: Dispatch<SetStateAction<boolean>>;
  setAddresses: Dispatch<SetStateAction<string[]>>;
  addresses: string[];
  type: string;
  className?: string;
}

const CloseInput: FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <span
      role='button'
      className={styles.inputCancel}
      onClick={onClick}
      onKeyPress={onClick}
    >
      <CrossIcon />
    </span>
  );
};

const RevealCcBccInput: React.FC<RevealCcBccInputProps> = ({
  setAddressesSelected,
  setAddresses,
  addresses,
  type,
  className = '',
}) => {
  const { t } = useTranslation();
  const [addressesError, setAddressesError] = useState(['']);
  const [newAddress, setNewAddresses] = useState('');
  const validateEmails = (
    ccEmails: string[],
    errorCallback: Dispatch<SetStateAction<string[]>>,
  ) => {
    const errors: string[] = [];
    _.each(ccEmails, (address) => {
      if (invalidEmailFormat({ email: address })) {
        errors.push(address);
      }
    });
    errorCallback(errors);
  };

  const onChangeAddresses = (e: React.FormEvent<HTMLInputElement>) => {
    if (e?.currentTarget?.value?.indexOf(' ') >= 0) {
      const trimmedMail = e.currentTarget.value.split(' ')[0].trim();
      setNewAddresses('');
      if (addresses.indexOf(trimmedMail) >= 0) {
        return;
      }
      const newAddressesArray = `${addresses.join(' ')} ${trimmedMail}`
        .trim()
        .split(' ');
      setAddresses(newAddressesArray);
      validateEmails(newAddressesArray, setAddressesError);
    } else {
      setNewAddresses(e?.currentTarget?.value);
    }
  };

  const onSubmitAddresses = async (e: React.FormEvent<HTMLInputElement>) => {
    if (!_.isEmpty(e?.currentTarget?.value)) {
      e.currentTarget.value += ' ';
    }
    onChangeAddresses(e);
  };

  const handleDeleteAddresses = (addressToDelete: string) => {
    setAddresses(_.filter(addresses, (address) => address !== addressToDelete));
  };

  const updateAddresses = (
    e: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLSpanElement>,
    oldAddress: string,
  ) => {
    const target = e?.target as HTMLElement;
    const updatedAddresses = [...addresses];
    const addressIndex = _.findIndex(
      addresses,
      (address) => address === oldAddress,
    );
    updatedAddresses[addressIndex] = target?.innerText;
    setAddresses(updatedAddresses);
    validateEmails(updatedAddresses, setAddressesError);
  };

  const handleSpaceAndReturnInAddressUpdate = (e: React.KeyboardEvent) => {
    const target = e?.target as HTMLElement;
    if (e?.key === ' ' || e?.key === 'Enter') {
      e.preventDefault();
    }
    if (e?.key === 'Enter') {
      target.blur();
    }
  };

  const handleAddressesCancel = () => {
    setAddresses([]);
    setAddressesSelected(false);
  };

  return (
    <div className={classNames(styles.inputContainer, className)}>
      <span className={styles.introWord}>
        {t(`profile.contact.timeline.${type}`)}{' '}
      </span>
      {_.map(addresses, (addressesTarget, index) => (
        <span
          key={index}
          className={classNames(
            styles.pill,
            `pill-message ${
              addressesError?.indexOf(addressesTarget) >= 0 ? 'red' : 'grey'
            }`,
          )}
        >
          <span
            role='textbox'
            contentEditable='true'
            suppressContentEditableWarning
            onKeyPress={handleSpaceAndReturnInAddressUpdate}
            onBlur={(e) => updateAddresses(e, addressesTarget)}
          >
            {addressesTarget}
          </span>

          <i
            role='button'
            className={classNames('ri-close-line', styles.deleteAddress)}
            onClick={() => handleDeleteAddresses(addressesTarget)}
            onKeyPress={() => handleDeleteAddresses(addressesTarget)}
          />
        </span>
      ))}
      {/* </span> */}
      <div className={styles.inputElement}>
        <Input
          className={styles.recipientInput}
          value={newAddress}
          onChange={onChangeAddresses}
          placeholder={t('profile.contact.timeline.emailInputPlaceholder')}
          onBlur={onSubmitAddresses}
          autoComplete='off'
          data-lpignore='true'
          input={{ 'data-form-type': 'other' }}
        />
        <CloseInput onClick={() => handleAddressesCancel()} />
      </div>
    </div>
  );
};

export const RevealCcBccSoboSelector: FC<{
  onClose: () => void;
  className?: string;
  sobo: Sender;
  senders: Sender[];
  onSoboSelected: (sobo: any | null) => void;
  isUnauthorizedSender?: boolean;
}> = ({
  onClose,
  className = '',
  sobo,
  senders,
  onSoboSelected,
  isUnauthorizedSender,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.inputContainer, className)}>
      <span className={styles.introWord}>
        {t(`profile.contact.timeline.sobo`)}
      </span>
      <div className={styles.soboSelector}>
        <GenericDropdown
          className={styles.soboDropdown}
          contentClassName={styles.dropdownContent}
          Trigger={({ onClick }) => {
            return isUnauthorizedSender ? (
              <span
                className={classNames(
                  styles.soboTrigger,
                  styles.unauthorizedSender,
                )}
              >
                {t('settings.senders.unauthorizedSender')}
              </span>
            ) : (
              <span
                className={classNames(styles.clickable, styles.soboTrigger)}
                role='button'
                onClick={onClick}
                onKeyPress={onClick}
              >
                {sobo?.senderAddress
                  ? sobo.senderAddress
                  : t('sequences.edit.selectSobo')}
                <ArrowDown />
              </span>
            );
          }}
        >
          <DropdownMenuPanel
            options={_.map(senders, (sender) => ({
              id: sender.id,
              label: (
                <span className={styles.clickable}>{sender.senderAddress}</span>
              ),
            }))}
            onSelect={(senderId) => {
              onSoboSelected(_.findWhere(senders, { id: senderId }));
            }}
          />
        </GenericDropdown>
      </div>
      <div className={styles.inputElement}>
        <CloseInput
          onClick={() => {
            onSoboSelected(null);
            onClose();
          }}
        />
      </div>
    </div>
  );
};
