import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import { ConnectForm } from '@/types/connectForm';
import { GET_CONNECT_FORMS, DELETE_CONNECT_FORM } from '@/graphql/connectForms';
import { GET_CONNECT_PAGES } from '../../../../../graphql/connectPages';

interface DeleteConnectFormModalProps {
  open: boolean;
  clientId: string;
  connectForm: ConnectForm | null | undefined;
  closeModal: () => void;
}
const DeleteConnectFormModal: React.FC<DeleteConnectFormModalProps> = ({
  open,
  clientId,
  connectForm,
  closeModal,
}) => {
  const { t } = useTranslation();

  const [deleteConnectForm] = useMutation(DELETE_CONNECT_FORM, {
    refetchQueries: [
      {
        query: GET_CONNECT_FORMS,
        variables: {
          clientId,
        },
      },
      {
        query: GET_CONNECT_PAGES,
        variables: {
          clientId,
        },
      },
    ],
  });

  if (!connectForm) return null;
  const onDelete = () => {
    deleteConnectForm({
      variables: { input: { id: connectForm.id } },
    }).then(closeModal);
  };
  return (
    <ConfirmationModal
      header={t('settings.connectSettings.connectForms.deletionModal.title')}
      onCancel={closeModal}
      onSubmit={onDelete}
      validationType='negative'
      open={open}
    />
  );
};

export default DeleteConnectFormModal;
