import _ from 'underscore';

import { Offer } from '@/types/offer';
import { ProfileWithAvatar } from '@/types/profile';

const emptyStateCategories = [
  'pendingMain',
  'pendingExtended',
  'othersPending',
  'allCandidates',
  'followUp',
] as const;

type EmptyStateCategory = typeof emptyStateCategories[number];

const getTopOffersFromCounts = (
  counts: Record<string, number>,
  limit = 4,
): string[] => {
  const offerIdAndCounts = _.map(counts, (count, offerId) => ({
    offerId,
    count,
  }));
  const filteredOfferIdAndCounts = _.filter(
    offerIdAndCounts,
    ({ count }) => count !== 0,
  );
  const sortedOfferIdAndCounts = _.sortBy(
    filteredOfferIdAndCounts,
    ({ count }) => -count,
  );
  const offerIds = _.map(sortedOfferIdAndCounts, ({ offerId }) => offerId);

  return _.first(offerIds, limit);
};

export const getEmptyStatesToDisplay = (
  counts: Record<EmptyStateCategory, number>,
  limit = 3,
): EmptyStateCategory[] => {
  const allEmptyStates: EmptyStateCategory[] = [];

  _.each(emptyStateCategories, (category) => {
    const count = counts[category];
    if (count !== 0) {
      allEmptyStates.push(category);
    }
  });

  return _.first(allEmptyStates, limit);
};

export const getPendingCountsAndTopOffers = (
  currentOfferId: string,
  allOffers: Offer[],
): {
  pendingMainCount: number;
  pendingExtendedCount: number;
  othersPendingCount: number;
  topOffers: string[];
} => {
  const result = {
    pendingMainCount: 0,
    pendingExtendedCount: 0,
    othersPendingCount: 0,
    topOffers: [] as string[],
  };

  const countsByOfferId: Record<string, number> = {};

  _.each(allOffers, ({ id, pipeStepStats = [], isArchived }) => {
    const pendingStats = _.findWhere(pipeStepStats, { stepId: 'pending' });
    if (id !== currentOfferId && !isArchived) {
      const pendingCount = pendingStats?.count || 0;
      result.othersPendingCount += pendingCount;
      countsByOfferId[id] = pendingCount;
    }
    if (id === currentOfferId) {
      const pendingMainStats = _.findWhere(
        pendingStats?.pipeSubStepStats || [],
        {
          subStepId: 'pending-main',
        },
      );
      result.pendingMainCount = pendingMainStats?.count || 0;
      const pendingExtendedStats = _.findWhere(
        pendingStats?.pipeSubStepStats || [],
        {
          subStepId: 'pending-extended',
        },
      );
      result.pendingExtendedCount = pendingExtendedStats?.count || 0;
    }
  });

  result.topOffers = getTopOffersFromCounts(countsByOfferId);

  return result;
};

export const getFirstProfilesAndRemainder = (
  profiles: ProfileWithAvatar[],
  limit = 4,
): { firstProfiles: ProfileWithAvatar[]; remainder: number } => {
  const sortedProfiles = _.sortBy(profiles, ({ resumeData }) => {
    const { photoLink } = resumeData;
    if (!!photoLink && !photoLink.includes('defaultAvatars')) {
      return 0;
    }
    return 1;
  });

  const firstProfiles = _.first(sortedProfiles, limit);

  return {
    firstProfiles,
    remainder: _.size(profiles) - _.size(firstProfiles),
  };
};
