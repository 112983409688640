import React, { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import useUnHoldOffer from '@/graphql/hooks/offers/useUnHoldOffer';
import useMiniOffer from '@/graphql/hooks/offers/useMiniOffer';
import useClientId from '@/hooks/router/useClientId';
import logAction from '@/common/logAction';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import { getFullname } from '@/common/helpers/person';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { sentryCaptureException } from '@/common';

import ConfirmationModal from '../ConfirmationModal';

import styles from './ReactivateOfferModal.module.less';

interface ReactivateOfferModalProps {
  open: boolean;
  onClose: () => void;
  offerId: string;
}

const ReactivateOfferModal: FC<ReactivateOfferModalProps> = ({
  offerId,
  onClose,
  open,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { user } = useCurrentUser();
  const { miniOffer } = useMiniOffer({ offerId });
  const [unHoldOffer] = useUnHoldOffer();
  const { success, error } = useNotificationSystem();

  const name = miniOffer?.title || t('offers.holdEmptyState.defaultName');

  const handleSubmit = async () => {
    logAction({
      type: 'reactivate-offer-sourcing',
      info: {
        clientId,
        jobOfferId: offerId,
        ...(user?.firstname &&
          user?.lastname && {
            author: getFullname(user),
          }),
      },
    }).catch((e) => sentryCaptureException({ error: e }));
    try {
      await unHoldOffer(offerId);
      success(t('offers.notifications.unHoldOffer.success'));
    } catch (e) {
      error(t('offers.notifications.unHoldOffer.error'));
      sentryCaptureException({ error: e });
    }
  };

  return (
    <ConfirmationModal
      open={open}
      header={t('offers.unHoldOfferModal.header', { name })}
      content={
        <Trans
          i18nKey='offers.unHoldOfferModal.subHeader'
          components={{
            br: <br />,
            a: (
              <Link
                to={`/client/${clientId}/jobs/${offerId}/criteria`}
                className={styles.link}
              />
            ),
          }}
          className={styles.content}
        />
      }
      submit={t('offers.unHoldOfferModal.confirm')}
      cancel={t('offers.unHoldOfferModal.cancel')}
      onCancel={() => onClose()}
      onSubmit={() => {
        handleSubmit();
        onClose();
      }}
      closeIcon={false}
    />
  );
};

export default ReactivateOfferModal;
