import React from 'react';
import SafeSrcDocIframe from './SafeSrcDocIframe';
import sanitizeSignature from '../../common/sanitizeSignature';

const Signature = ({ content }) => {
  return (
    <SafeSrcDocIframe
      scrolling='no'
      title='signature'
      srcDoc={
        `<style>@font-face {font-family: Graphik; src: url("/fonts/Graphik/Graphik-Regular.otf")} body{font-family: Graphik, sans-serif;color: rgba(0,0,0,.87); margin: 0;line-height: 1.4285em;font-size: 13px} p{margin: 0 0 1em}table: {border-spacing: 0;}</style>${ 
        sanitizeSignature(content)}`
      }
      style={{ border: 'none', width: '100%' }}
    />
  );
};

// React.memo makes the component Pure for perf improvements
export default React.memo(Signature);
