import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

const GET_SEARCH_POOL_MINI_RESULTS = gql`
  query getSearchPoolMiniResults(
    $searchPoolId: ID!
    $categories: [SearchCategoryInput]
    $searchesCriteria: [SearchCriteriaInput]
    $sortBy: String
    $excludeHidden: Boolean
    $excludeProfilesInJob: Boolean
  ) {
    searchPool(id: $searchPoolId) {
      id
      searchResults(
        searchesCriteria: $searchesCriteria
        categories: $categories
        excludeHidden: $excludeHidden
        excludeProfilesInJob: $excludeProfilesInJob
        sortBy: $sortBy
      ) {
        categories {
          categoryId
          results {
            searchPoolProfileId
          }
        }
      }
    }
  }
`;

type SortOrder = 'recency' | 'relevancy';

interface SearchPoolMiniResult {
  categoryId: string;
  results: {
    searchPoolProfileId: string;
  }[];
}

interface GetSearchPoolMiniResultsData {
  searchPool: {
    id: string;
    searchResults: {
      categories: SearchPoolMiniResult[];
    };
  };
}

interface GetSearchPoolMiniResultsVariables {
  searchPoolId: string;
  categories?: {
    categoryId?: string;
    withCount?: false;
    withResults: true;
  }[];
  searchesCriteria?: {
    jobPositionsOperator?: string;
    skillsOperator?: string;
    remoteOnly?: boolean;
    excludeHidden?: boolean;
    excludeProfilesInJob?: boolean;
  };
  sortBy?: SortOrder;
  excludeHidden?: boolean;
  excludeProfilesInJob?: boolean;
}

interface UseSearchPoolMiniResultsResult
  extends QueryResult<
    GetSearchPoolMiniResultsData,
    GetSearchPoolMiniResultsVariables
  > {
  searchPoolMiniResults: SearchPoolMiniResult[];
}

interface UseSearchPoolMiniResultsInput
  extends GetSearchPoolMiniResultsVariables {
  queryOptions?: QueryHookOptions<
    GetSearchPoolMiniResultsData,
    GetSearchPoolMiniResultsVariables
  >;
}

const useSearchPoolMiniResults = ({
  searchPoolId,
  searchesCriteria = {
    jobPositionsOperator: 'OR',
    skillsOperator: 'OR',
    remoteOnly: false,
    excludeHidden: true,
    excludeProfilesInJob: true,
  },
  categories = [{ categoryId: 'all', withCount: false, withResults: true }],
  sortBy = 'recency',
  excludeHidden = true,
  excludeProfilesInJob = true,
  queryOptions = {},
}: UseSearchPoolMiniResultsInput): UseSearchPoolMiniResultsResult => {
  const query = useQuery<
    GetSearchPoolMiniResultsData,
    GetSearchPoolMiniResultsVariables
  >(GET_SEARCH_POOL_MINI_RESULTS, {
    ...queryOptions,
    variables: {
      searchPoolId,
      searchesCriteria,
      categories,
      sortBy,
      excludeHidden,
      excludeProfilesInJob,
    },
  });

  const searchPoolMiniResults =
    query.data?.searchPool.searchResults.categories || [];

  return { ...query, searchPoolMiniResults };
};

export default useSearchPoolMiniResults;
