import gql from 'graphql-tag';
import {
  FetchResult,
  MutationResult,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';

import SequenceFragment from '@/graphql/fragments/Sequence';
import { Sequence } from '@/types/sequence';
import useClientId from '@/hooks/router/useClientId';
import { CLIENT_SEQUENCES } from '@/containers/Parameters/Sequences/queries';
import { ClientSequencesData } from './useClientSequences';

const RESTORE_SEQUENCE = gql`
  mutation restoreSequence($input: RestoreSequenceInput!) {
    sequence {
      restore(input: $input) {
        sequence {
          ...Sequence
        }
      }
    }
  }
  ${SequenceFragment}
`;

interface RestoreSequenceData {
  sequence: {
    restore: {
      sequence: Sequence;
    };
  };
}

interface RestoreSequenceVariables {
  input: {
    id: string;
  };
}

interface UseRestoreSequenceInput {
  mutationOptions?: MutationHookOptions<
    RestoreSequenceData,
    RestoreSequenceVariables
  >;
}

type UseRestoreSequenceResult = [
  (id: string) => Promise<FetchResult<RestoreSequenceData>>,
  MutationResult<RestoreSequenceData>,
];

const useRestoreSequence = ({
  mutationOptions = {},
}: UseRestoreSequenceInput = {}): UseRestoreSequenceResult => {
  const clientId = useClientId();
  const [mutation, result] = useMutation<
    RestoreSequenceData,
    RestoreSequenceVariables
  >(RESTORE_SEQUENCE, { ...mutationOptions });

  const queryToUpdate = {
    query: CLIENT_SEQUENCES,
    variables: { clientId, filters: { activeOnly: true } },
  };

  return [
    (id) =>
      mutation({
        variables: { input: { id } },
        update: (cache, { data }) => {
          if (!data) return;

          const oldData = cache.readQuery<ClientSequencesData>(queryToUpdate);

          if (!oldData) return;

          const newData = {
            client: {
              ...oldData.client,
              sequences: [
                ...(oldData?.client?.sequences || []),
                data.sequence.restore.sequence,
              ],
            },
          };

          cache.writeQuery({
            ...queryToUpdate,
            data: newData,
          });
        },
      }),
    result,
  ];
};

export default useRestoreSequence;
