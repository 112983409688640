import React from 'react';
import {
  Yellow10,
  Yellow40,
  SecondarySkyBlue10,
  SecondarySkyBlue40,
  PrimaryCobalt,
} from '@/less/colors';

const colorsFromColorString: Record<string, any> = {
  yellow: {
    backgroundColor: Yellow10,
    borderColor: Yellow40,
  },
  blue: {
    backgroundColor: SecondarySkyBlue10,
    borderColor: SecondarySkyBlue40,
  },
};

interface ColoredBoxProps {
  color: string;
  backgroundColor?: string;
  textColor?: string;
  padding?: string;
  rounded?: boolean;
  hasBorder?: boolean;
  margin?: string;
  borderColor?: string;
  children: React.ReactNode;
  radius?: string;
  key?: string | number;
  className?: string;
}

const ColoredBox: React.FC<ColoredBoxProps> = ({
  color,
  textColor,
  backgroundColor,
  radius = '0px',
  padding = '16px',
  margin = '8px 0px',
  rounded = false,
  hasBorder = true,
  borderColor,
  key,
  children,
  className,
}) => {
  return (
    <div
      key={key}
      className={className}
      style={{
        color: textColor || PrimaryCobalt,
        backgroundColor:
          backgroundColor || colorsFromColorString[color].backgroundColor,
        border: hasBorder
          ? `1px solid ${borderColor ||
              colorsFromColorString[color].borderColor}`
          : 'none',
        padding,
        borderRadius: rounded ? '50%' : radius,
        margin,
      }}
    >
      {children}
    </div>
  );
};

export default ColoredBox;
