import { useState, useCallback, useMemo } from 'react';
import { ShowMoreRowProps } from '@/components/Tables/ShowMore';

interface UseLocalShowMoreArgs<T> {
  items: T[];
  defaultDisplayLimit?: number;
  limitStep?: number;
  offset?: number;
}

type UseLocalShowMoreReturn<T> = [
  T[],
  ShowMoreRowProps,
  () => void,
  () => void,
  number,
];

export const useLocalShowMore = <T>({
  items,
  defaultDisplayLimit = 20,
  limitStep = 10,
  offset = 0,
}: UseLocalShowMoreArgs<T>): UseLocalShowMoreReturn<T> => {
  const [currentDisplayLimit, setCurrentDisplayLimit] = useState(
    defaultDisplayLimit,
  );

  // Adds a step to the view
  const showMore = useCallback(() => {
    setCurrentDisplayLimit((currentLimit) => currentLimit + limitStep);
  }, [limitStep]);

  // Resets to the default quantity view
  const reset = useCallback(() => {
    setCurrentDisplayLimit(defaultDisplayLimit);
  }, [defaultDisplayLimit]);

  const displayableItems = useMemo(() => {
    return items.slice(offset, currentDisplayLimit + offset);
  }, [currentDisplayLimit, items, offset]);

  const rowProps: ShowMoreRowProps = {
    items,
    limitStep,
    displayLimit: currentDisplayLimit,
    initialLimit: defaultDisplayLimit,
    onShowMore: showMore,
    onReset: reset,
  };

  return [displayableItems, rowProps, showMore, reset, currentDisplayLimit];
};

export default useLocalShowMore;
