import React from 'react';
import { Redirect } from 'react-router-dom';
import { compose } from 'underscore';
import SigninForm from '../containers/Auth/SigninForm';
import routerParamsToProps from '../hocs/routerParamsToProps';
import { decodeToken } from '../common';

class SigninView extends React.PureComponent {
  render() {
    const { clientId, location } = this.props;

    const { from } = location.state || {
      from: { pathname: `/client/${clientId}/dashboard` },
    };

    const token = localStorage.getItem('token');
    const decodedJWT = token ? decodeToken(token) : token;

    if (decodedJWT && decodedJWT.clientId === clientId) {
      return <Redirect to={`/client/${clientId}/dashboard`} />;
    }

    return <SigninForm clientId={clientId} redirectTo={from} />;
  }
}

export default compose(routerParamsToProps)(SigninView);
