import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

const mutation = gql`
  mutation signOAuthUser(
    $type: String!
    $token: String!
    $clientId: ID!
    $mfa: String
  ) {
    signOAuthUser(type: $type, token: $token, clientId: $clientId, mfa: $mfa) {
      id
      email
      shouldSetupMFA
      mfaUrl
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    signOAuthUser: ({ type, token, clientId, mfa }) => {
      return mutate({
        variables: { type, token, clientId, mfa },
        errorPolicy: 'all',
      });
    },
  }),
});
