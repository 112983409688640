import React from 'react';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import { PrimaryCobalt60 } from '@/less/colors';
import withUserNotificationsSettings from '../../../hocs/users/withUserNotificationsSettings';
import SettingsLayout from '../../SettingsLayout';
// import DiscoverNotifications from './DiscoverNotifications';
import JobOfferNotifications from './JobOfferNotifications';
// import ProductReleasesNotifications from './ProductReleasesNotifications';

const NotificationSettings = ({
  clientId,
  loadingUserSettings,
  client,
  user,
  t,
}) => {
  const offers = client?.offers;
  const email = user?.email;
  const permissions = useClientPermissions(clientId).data?.client?.permissions;
  const { applicationMode, updateApplicationMode } = useNavApplicationMode();

  if (applicationMode !== 'classic') updateApplicationMode('classic');
  // const discoverSetting = user?.emailNotificationsSettings?.discover;
  // const newFeatureSetting = user?.emailNotificationsSettings?.newFeatures;

  return (
    <SettingsLayout
      currentPage='notifications'
      clientId={clientId}
      className='settings-container'
    >
      <div className='settings notifications-page'>
        <div className='settings-header'>
          <h1>
            {t('notificationsSettings.header')}{' '}
            {permissions?.reveal &&
              permissions?.watchCollect &&
              '(Marketplace)'}
          </h1>
        </div>
        {permissions?.reveal && permissions?.watchCollect && (
          <>
            <p style={{ color: PrimaryCobalt60 }}>
              {t('notificationsSettings.subheader', { client: 'CRM' })}
              <Link to={`/client/${clientId}/crm-notifications`}>
                {' '}
                {t('integrations.clickHere')}
              </Link>
              .
            </p>
          </>
        )}
        <div className='section visible'>
          <div className='input-container'>
            <div className='input-label'>
              {t('notificationsSettings.jobAlerts.header')}
            </div>
            <div className='input-emphasis'>
              {t('notificationsSettings.jobAlerts.description')}
            </div>
            {!loadingUserSettings && (
              <JobOfferNotifications email={email} offers={offers} t={t} />
            )}
          </div>
          {/* <div className='input-container'>
            <div className='input-label'>{t('notificationsSettings.discover.header')}</div>
            <DiscoverNotifications active={discoverSetting} loading={loadingUserSettings} clientId={clientId} t={t} />
          </div>
          <div className='input-container'>
            <div className='input-label'>{t('notificationsSettings.newFeatures.header')}</div>
            <ProductReleasesNotifications
              active={newFeatureSetting}
              loading={loadingUserSettings}
              clientId={clientId}
              t={t}
            />
          </div> */}
        </div>
      </div>
    </SettingsLayout>
  );
};

export default compose(
  withUserNotificationsSettings,
  withTranslation('translations'),
)(NotificationSettings);
