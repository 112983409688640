import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import _, { compose } from 'underscore';
import Board from 'react-trello';
import { withTranslation } from 'react-i18next';
import Particles from 'react-tsparticles';
import logAction from '../../../../common/logAction';
import withChangeProfileStep from '../../../../hocs/profiles/withChangeProfileStep';
import withUserFromJWToken from '../../../../hocs/users/withUserFromJWToken';
import ProfileIdHandler from '../../../../common/ProfileIdHandler';
import SearchPoolProfileActions from '../../../WatchCollectAllCandidates/components/SearchPoolProfileActions';
import ReviewModal from '../../WatchCollectRecommendations/ReviewModal';
import FollowupKanbanCard from './components/FollowupKanbanCard';
import LaneHeaderComponent from './components/LaneHeaderComponent';
import BoardStateHandler from './BoardStateHandler';

import './FollowupKanban.css';

export const KANBAN_STEPS = ['contacted', 'answered', 'in-process', 'hired'];

const getStepAndInitialSortProfileIds = ({ profiles, step }) => {
  const stepProfilesIds = _.filter(
    profiles,
    ({ resumeData }) => resumeData?.step === step,
  );

  stepProfilesIds.sort(profileSortFunction);
  return _.pluck(stepProfilesIds, 'id');
};

const profileSortFunction = (profile1, profile2) => {
  if (profile1.isActive && !profile2.isActive) {
    return -1;
  }
  if (profile2.isActive && !profile1.isActive) {
    return 1;
  }
  if (
    profile1?.answer?.label === 'negative' &&
    profile2?.answer?.label !== 'negative'
  ) {
    return 1;
  }
  if (
    profile2?.answer?.label === 'negative' &&
    profile1?.answer?.label !== 'negative'
  ) {
    return -1;
  }
  return (
    (profile2.lastStepUpdateTimestamp ||
      profile2.creationTimestamp ||
      Infinity) -
    (profile1.lastStepUpdateTimestamp || profile1.creationTimestamp || Infinity)
  );
};

const getStepProfiles = ({
  step,
  boardStateHandler,
  profiles,
  shouldSortProfiles,
}) => {
  if (!step) {
    return [];
  }
  const stepProfileIds = boardStateHandler.getProfileIds(step);
  const currentStepProfiles = stepProfileIds.map((profileId) => {
    return profiles.find((profile) => profile.id === profileId);
  });

  if (shouldSortProfiles && shouldSortProfiles.current === false) {
    return currentStepProfiles;
  }
  currentStepProfiles.sort(profileSortFunction);
  return _.sortBy(
    currentStepProfiles,
    (profile) => !profile?.annotation?.globalFavorite?.value,
  );
};

const KANBAN_COLORS = {
  contacted: 'grey',
  answered: 'dark-blue',
  'in-process': 'light-blue',
  hired: 'green',
};

const FollowupKanban = ({
  clientId,
  unclassifiedJobId,
  profiles,
  setSkippedProfileIds,
  changeProfileStep,
  user,
  t,
  jobs,
}) => {
  const shouldSortProfiles = useRef(true);

  const [contactedProfileIds, setContactedProfileIds] = useState([]);
  const [answeredProfileIds, setAnsweredProfileIds] = useState([]);
  const [inProcessProfileIds, setInProcessProfileIds] = useState([]);
  const [hiredProfileIds, setHiredProfileIds] = useState([]);

  const [showConfettis, setShowConfettis] = useState(false);

  const { pathname, search } = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(search);
  const selectedProfileId = searchParams.get('profileId');

  const selectedProfile = _.findWhere(profiles, { id: selectedProfileId });
  const setSelectedProfileId = (profileId) => {
    if (!profileId) {
      history.push(pathname);
    } else {
      history.push(`${pathname}?profileId=${profileId}`);
    }
  };

  const openReviewModal = selectedProfile !== undefined;

  const [isBackdropVisible] = useState(false);

  useEffect(() => {
    refreshLanesProfiles();
    // eslint-disable-next-line
  }, [profiles.length, jobs]);

  const boardState = {
    contactedProfileIds,
    answeredProfileIds,
    inProcessProfileIds,
    hiredProfileIds,
    setContactedProfileIds,
    setAnsweredProfileIds,
    setInProcessProfileIds,
    setHiredProfileIds,
  };

  const refreshLanesProfiles = () => {
    setContactedProfileIds(
      getStepAndInitialSortProfileIds({ profiles, step: 'contacted' }),
    );
    setAnsweredProfileIds(
      getStepAndInitialSortProfileIds({ profiles, step: 'answered' }),
    );
    setInProcessProfileIds(
      getStepAndInitialSortProfileIds({ profiles, step: 'in-process' }),
    );
    setHiredProfileIds(
      getStepAndInitialSortProfileIds({ profiles, step: 'hired' }),
    );
  };

  const boardStateHandler = new BoardStateHandler({ boardState });

  const stepProfiles = getStepProfiles({
    step: getProfileStep(selectedProfile),
    boardStateHandler,
    profiles,
    shouldSortProfiles,
  });

  const profileIdHandler = ProfileIdHandler({
    profileIds: _.pluck(stepProfiles, 'id'),
    selectedProfileId,
    onSelectProfileId: setSelectedProfileId,
  });

  const components = {
    Card: FollowupKanbanCard,
    LaneHeader: LaneHeaderComponent,
  };

  /*   const onKanbanDropdownToggle = (state) => {
    setIsBackdropVisible(state);
  }; */

  const onChangeProfileStep = async ({ profileId, value }) => {
    const profileToUpdate = _.find(
      profiles,
      (profile) => profile?.id === profileId,
    );
    if (!profileToUpdate) {
      return;
    }
    profileToUpdate.resumeData.step = value;
    refreshLanesProfiles();
  };

  const onCardClick = async (cardId) => {
    setShowConfettis(false);
    setSelectedProfileId(cardId);

    logAction({
      type: 'followup-view-profile-card-clicked',
      profileId: cardId,
      info: {
        clientId,
        author: `${user?.firstname} ${user?.lastname}`,
        from: 'watch-collect-followup-view',
      },
    });
  };

  const onCardMoveAcrossLanes = async (fromLaneId, toLaneId, cardId, index) => {
    if (shouldSortProfiles.current) {
      shouldSortProfiles.current = false;
    }
    if (fromLaneId !== toLaneId && toLaneId === 'hired') {
      setShowConfettis(true);
    } else {
      setShowConfettis(false);
    }
    const profileId = cardId;
    try {
      const profile = _.findWhere(profiles, { id: profileId });

      boardStateHandler.changeProfileStep({
        profileId,
        from: fromLaneId,
        to: toLaneId,
        index,
      });

      if (fromLaneId !== toLaneId) {
        await changeProfileStep({
          id: profileId,
          step: toLaneId,
          fromStep: fromLaneId,
          offerId: profile?.jobOfferId,
        });

        logAction({
          type: 'followup-view-profile-changed-step',
          profileId,
          info: {
            clientId,
            jobOfferId: profile?.jobOfferId,
            fromStep: fromLaneId,
            toStep: toLaneId,
            author: `${user?.firstname} ${user?.lastname}`,
            from: 'watch-collect-followup-view',
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onCloseReviewModal = () => {
    setSelectedProfileId(null);
  };

  const goToNextProfileOrClose = () => {
    if (profileIdHandler.next) {
      // preloadNextProfiles is called inside onSelectProfileId
      profileIdHandler.onSelectProfileId(profileIdHandler.next);
    } else {
      onCloseReviewModal();
    }
  };

  const onSendFromFollowup = ({ profileId, jobOfferId }) => {
    logAction({
      type: 'followup-view-profile-sent',
      profileId,
      info: {
        clientId,
        jobOfferId,
        author: `${user?.firstname} ${user?.lastname}`,
        from: 'watch-collect-followup-review-modal',
      },
    });
    goToNextProfileOrClose();
  };

  const onSkipFromFollowupCard = ({ profileId, jobOfferId }) => {
    setSkippedProfileIds((oldIds) => _.union(oldIds, [profileId]));
    logAction({
      type: 'followup-view-profile-skipped',
      profileId,
      info: {
        clientId,
        jobOfferId,
        author: `${user?.firstname} ${user?.lastname}`,
        from: 'watch-collect-followup-review-modal',
      },
    });
  };

  const onSkipFromFollowupModal = ({ profileId, jobOfferId }) => {
    setSkippedProfileIds((oldIds) => _.union(oldIds, [profileId]));
    logAction({
      type: 'followup-view-profile-skipped',
      profileId,
      info: {
        clientId,
        jobOfferId,
        author: `${user?.firstname} ${user?.lastname}`,
        from: 'watch-collect-followup-review-modal',
      },
    });
    goToNextProfileOrClose();
  };

  const onAddToJobFromFollowup = ({
    profileId,
    searchPoolProfileId,
    jobOfferId,
  }) => {
    logAction({
      type: 'followup-view-profile-added-to-job',
      profileId,
      info: {
        clientId,
        searchPoolProfileId,
        jobOfferId,
        author: `${user?.firstname} ${user?.lastname}`,
        from: 'watch-collect-followup-review-modal',
      },
    });
    goToNextProfileOrClose();
  };

  const lanes = _.map(KANBAN_STEPS, (step) => {
    const lanesProfiles = getStepProfiles({
      step,
      boardStateHandler,
      profiles,
      shouldSortProfiles,
    });
    return {
      id: step,
      content: (
        <>
          <div className='lane-title'>
            <div className='lane-title-text'>
              {t(`watchCollect.followupView.kanban.pipe.${step}`)}
            </div>
            <div className='lane-title-count'>{lanesProfiles.length}</div>
            {/* <div className='lane-dropdown'>
              <KanbanColumnDropdown step={step} onKanbanDropdownToggle={onKanbanDropdownToggle} />
            </div> */}
          </div>
          <div className={`lane-divider ${KANBAN_COLORS[step]}`} />
        </>
      ),
      cards: getCards({
        step,
        profiles: lanesProfiles,
        onSkip: onSkipFromFollowupCard,
        jobOffers: jobs,
        clientId,
      }),
    };
  });

  const profileActions =
    unclassifiedJobId && selectedProfile?.jobOfferId === unclassifiedJobId ? (
      <SearchPoolProfileActions
        clientId={clientId}
        clientProfileId={selectedProfile?.id}
        clientProfile={selectedProfile}
        resumeData={selectedProfile?.resumeData}
        user={user}
        searches={[]}
        onMarkHidden={onSkipFromFollowupModal}
        onAddToJob={onAddToJobFromFollowup}
        isUnclassified
        t={t}
      />
    ) : null;

  return (
    <div className='followup-kanban'>
      {showConfettis && !openReviewModal && (
        <Particles
          id='tsparticles'
          options={{
            autoPlay: true,
            background: {
              color: {
                value: 'transparent',
              },
            },
            fpsLimit: 120,
            preset: 'confetti',
            particles: {
              number: {
                value: 0,
              },
              color: {
                value: ['#0073ff', '#00ff44', '#ff0000', '#fffb00'],
              },
              opacity: {
                value: 1,
                animation: {
                  enable: true,
                  minimumValue: 0,
                  speed: 2,
                  startValue: 'max',
                  destroy: 'min',
                },
              },
              size: {
                value: 7,
                random: {
                  enable: true,
                  minimumValue: 3,
                },
              },
              links: {
                enable: false,
              },
              life: {
                duration: {
                  sync: true,
                  value: 5,
                },
                count: 1,
              },
              move: {
                enable: true,
                gravity: {
                  enable: true,
                  acceleration: 20,
                },
                speed: 20,
                decay: 0.1,
                direction: 'none',
                random: false,
                straight: false,
                outModes: {
                  default: 'destroy',
                  top: 'none',
                },
              },
              roll: {
                darken: {
                  enable: true,
                  value: 25,
                },
                enable: true,
                speed: {
                  min: 5,
                  max: 15,
                },
              },
              wobble: {
                distance: 30,
                enable: true,
                speed: {
                  min: -7,
                  max: 7,
                },
              },
              shape: {
                type: ['circle', 'square', 'polygon'],
                options: {
                  polygon: [
                    {
                      sides: 5,
                    },
                    {
                      sides: 6,
                    },
                  ],
                },
              },
              rotate: {
                value: {
                  min: 0,
                  max: 360,
                },
                direction: 'random',
                animation: {
                  enable: true,
                  speed: 30,
                },
              },
              tilt: {
                direction: 'random',
                enable: true,
                value: {
                  min: 0,
                  max: 360,
                },
                animation: {
                  enable: true,
                  speed: 30,
                },
              },
            },
            interactivity: {
              detectsOn: 'window',
              events: {
                resize: true,
              },
            },
            detectRetina: true,
            emitters: {
              direction: 'none',
              life: {
                count: 10,
                duration: 0.1,
                delay: 0.1,
              },
              rate: {
                delay: 0.1,
                quantity: 100,
              },
              size: {
                width: 0,
                height: 0,
              },
            },
          }}
        />
      )}
      {isBackdropVisible && <div className='backdrop' />}
      <Board
        className='followup-kanban-board'
        data={{ lanes }}
        laneDraggable={false}
        components={components}
        onCardMoveAcrossLanes={onCardMoveAcrossLanes}
        onCardClick={onCardClick}
        t={t}
      />

      <ReviewModal
        open={openReviewModal}
        onClose={onCloseReviewModal}
        onSend={onSendFromFollowup}
        profileIdHandler={profileIdHandler}
        profileActions={profileActions}
        onChangeProfileStep={onChangeProfileStep}
      />
    </div>
  );
};

const getProfileStep = (profile) => {
  return profile?.resumeData?.step;
};

const getCards = ({ step, profiles, onSkip, jobOffers, clientId }) => {
  const cards = _.map(profiles, (profile) => {
    return {
      id: profile?.id, // used for react keys
      laneId: step,
      profile,
      onSkip,
      jobOffers,
      clientId,
    };
  });
  return cards;
};

/* const KanbanColumnDropdown = ({
  step,
  onValueChange,
  onKanbanDropdownToggle,
}) => {
  const optionsAndLabels = {
    contacted: {
      placeholder: 'Sort by relevancy',
      options: [
        {
          key: 'header-sort-by',
          children: () => (
            <Dropdown.Header key='header-sort' content='Sort by' />
          ),
          disabled: true,
        },
        {
          key: 'relevancy',
          value: 'relevancy',
          text: 'Relevancy',
          selected: true,
        },
        { key: 'nto', value: 'newest to oldest', text: 'Newest to oldest' },
        { key: 'otn', value: 'oldest to newest', text: 'Oldest to newest' },
      ],
      prefix: 'Sort by',
    },
    answered: {
      placeholder: 'All replies',
      options: [
        {
          key: 'header-show',
          children: () => <Dropdown.Header key='header-show' content='Show' />,
          disabled: true,
        },
        {
          key: 'all-replies',
          value: 'All replies',
          text: 'All replies',
          selected: true,
        },
        {
          key: 'recent-positive',
          value: 'Recent positive replies',
          text: 'Recent positive replies',
        },
        {
          key: 'positive',
          value: 'Positive replies',
          text: 'Positive replies',
        },
        {
          key: 'negative',
          value: 'Negative replies',
          text: 'Negative replies',
        },
        {
          key: 'header-sort-by',
          children: () => (
            <Dropdown.Header key='header-sort' content='Sort by' />
          ),
          disabled: true,
        },
        { key: 'relevancy', value: 'relevancy', text: 'Relevancy' },
        { key: 'nto', value: 'Newest to oldest', text: 'Newest to oldest' },
        { key: 'otn', value: 'oldest to newest', text: 'Oldest to newest' },
      ],
      prefix: '',
    },
    'in-process': {
      placeholder: 'All stages',
      options: [
        {
          key: 'header-show',
          children: () => <Dropdown.Header key='header-show' content='Show' />,
          disabled: true,
        },
        { key: 'all-stages', value: 'All stages', text: 'All stages' },
        {
          key: 'phone-screen',
          value: 'phone screen',
          text: 'Phone screen',
          selected: true,
        },
        {
          key: 'physical',
          value: 'physical interview',
          text: 'Physical interview',
        },
        { key: 'tech-test', value: 'technical test', text: 'Technical test' },
        {
          key: 'c-interview',
          value: 'c-level interview',
          text: 'C-level interview',
        },
        { key: 'refs', value: 'references', text: 'References' },
        { key: 'offer', value: 'offers', text: 'Offers' },
        {
          key: 'header-sort-by',
          children: () => (
            <Dropdown.Header key='header-sort' content='Sort by' />
          ),
          disabled: true,
        },
        { key: 'relevancy', value: 'relevancy', text: 'Relevancy' },
        { key: 'nto', value: 'Newest to oldest', text: 'Newest to oldest' },
        { key: 'otn', value: 'oldest to newest', text: 'Oldest to newest' },
      ],
      prefix: 'Sort by',
    },
    hired: {
      placeholder: 'Sort by relevancy',
      options: [
        {
          key: 'header-sort-by',
          children: () => (
            <Dropdown.Header key='header-sort' content='Sort by' />
          ),
          disabled: true,
        },
        {
          key: 'relevancy',
          value: 'relevancy',
          text: 'Relevancy',
          selected: true,
        },
        { key: 'nto', value: 'newest to oldest', text: 'Newest to oldest' },
        { key: 'otn', value: 'oldest to newest', text: 'Oldest to newest' },
      ],
      prefix: 'Sort by',
    },
  };

  const [value, setValue] = useState(optionsAndLabels[step].placeholder);

  const handleChange = (e, { value }) => {
    setValue(`${optionsAndLabels[step].prefix} ${value}`);
  };

  return (
    <Dropdown
      className='kanban-column-dropdown'
      placeholder={optionsAndLabels[step].placeholder}
      fluid
      selection
      compact
      floating
      text={value}
      onChange={handleChange}
      onOpen={() => onKanbanDropdownToggle(true)}
      onClose={() => onKanbanDropdownToggle(false)}
      // allowAdditions={true}
      // additionLabel='Sort by'
      options={optionsAndLabels[step].options}
    // renderLabel={optionsAndLabels[step].renderLabel}
    />
  );
}; */

export default compose(
  withTranslation('translations'),
  withUserFromJWToken,
  withChangeProfileStep,
)(FollowupKanban);
