import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import SearchPoolJobWorkflow from '@/graphql/fragments/SearchPoolJobWorkflow';

export const DeleteMissionWorkflow = gql`
  mutation deleteMissionWorkflow(
    $searchPoolId: ID!
    $input: deleteMissionWorkflowInput!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      deleteMissionWorkflow(input: $input) {
        job {
          id
          workflows {
            ...SearchPoolJobWorkflow
          }
        }
      }
    }
  }
  ${SearchPoolJobWorkflow}
`;

export default function useDeleteMissionWorkflow() {
  return useMutation(DeleteMissionWorkflow);
}
