import React from 'react';
import EmailContext from '../../context/EmailContext';

export default (WrappedComponent) => {
  return function WrapperComponent(props) {
    return (
      <EmailContext.Consumer>{(emailApi) => <WrappedComponent {...props} emailApi={emailApi} />}</EmailContext.Consumer>
    );
  };
};
