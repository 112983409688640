import React from 'react';
import _, { compose } from 'underscore';
import withClientStatistics from '../../../../../hocs/clients/withClientStatistics';
import SkipRatioGraph from './SkipRatioGraph';
import {
  addTimestamp,
  aggregateCohorts,
  dateRangeDates,
  filterEventsByDateRange,
  generateEmptyDailyCohorts,
} from '../../utils';

const SkipRatioDataContainer = ({
  statistics,
  statisticsLoading,
  dateRange,
  frequency,
  t,
}) => {
  let { dailyCohorts } = (statistics || {}).offers || {};
  if (_.isEmpty(dailyCohorts)) {
    dailyCohorts = generateEmptyDailyCohorts({ nDays: 9 });
  }

  const { startDate, endDate } = dateRangeDates({ dateRange });
  let skipRatioData = filterEventsByDateRange({
    events: dailyCohorts,
    startDate,
    endDate,
  });
  skipRatioData = aggregateCohorts({ events: skipRatioData, frequency });
  skipRatioData = addTimestamp({ events: skipRatioData });
  return (
    <SkipRatioGraph
      skipRatioData={skipRatioData}
      isLoading={statisticsLoading}
      frequency={frequency}
      t={t}
    />
  );
};

export default compose(withClientStatistics)(SkipRatioDataContainer);
