import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@hiresweet/hiresweet-lib';
import GenericFormLabel from '@/components/Common/GenericFormLabel';
import useFreeTrialConfiguration from '@/graphql/hooks/clients/useFreeTrialConfiguration';

import useUpgradeClientFreeTrial from './useUpgradeClientFreeTrial';

import styles from './FreeTrialEndDateForm.module.less';

function FreeTrialUpgradeForm() {
  const [upgradeClientFreeTrial] = useUpgradeClientFreeTrial();
  const { freeTrialConfiguration } = useFreeTrialConfiguration();
  const { t } = useTranslation();
  return (
    <div className={styles.section}>
      <GenericFormLabel className={styles.label}>
        {t('settings.freeTrial.upgrade')}
      </GenericFormLabel>
      <div className={styles.container}>
        <Button
          onClick={() => {
            upgradeClientFreeTrial();
          }}
          disabled={freeTrialConfiguration.upgraded}
        >
          {t('common.save')}
        </Button>
      </div>
    </div>
  );
}

export default FreeTrialUpgradeForm;
