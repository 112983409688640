import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './svg.module.less';

interface SendProps {
  className?: string;
}

const Send: FC<SendProps> = ({ className }) => (
  <svg
    className={classNames(styles.svgIcon, className)}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 13C2.44772 13 2 13.4477 2 14V19.7639C2 21.2507 3.56463 22.2177 4.89443 21.5528L20.4223 13.7889C21.8964 13.0518 21.8964 10.9482 20.4223 10.2111L4.89443 2.44722C3.56463 1.78232 2 2.74931 2 4.23607V10C2 10.5523 2.44772 11 3 11H8C8.55228 11 9 11.4477 9 12C9 12.5523 8.55228 13 8 13H3Z'
      fill='currentColor'
    />
  </svg>
);

export default Send;
