import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Placeholder } from 'semantic-ui-react';
import _ from 'underscore';
import GenericTooltip from '@/components/Common/GenericTooltip';
import useSearchPoolJobs from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobs';
import { ISearchPoolJob } from '@/graphql/searchPoolJobs';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

import { ProfileColumn } from '../../types';

import styles from './MissionsColumn.module.less';

const MissionsColumn: ProfileColumn = ({
  profile,
  profileLoading,
  className,
  style,
}) => {
  const params = useMergedConfigurationParams();
  const { data, loading: missionsLoading } = useSearchPoolJobs(
    'reveal',
    {},
    'cache-first',
  );

  const { missionsInfo = [] } = profile || {};

  const filteredMissionsInfo = useMemo(() => {
    if (params?.shouldHideArchivedMissionsInProfilesTables !== 'true') {
      return missionsInfo;
    }

    if (missionsLoading) {
      return [];
    }

    const missionsById = _.reduce(
      data?.searchPool.jobs || [],
      (agg, job) => {
        agg[job.id] = job;
        return agg;
      },
      {} as Record<string, ISearchPoolJob>,
    );

    return _.filter(missionsInfo, (missionInfo) => {
      const mission = missionsById[missionInfo.missionId];
      if (!mission) {
        return false;
      }

      return !mission.isArchived;
    });
  }, [params, missionsInfo, data, missionsLoading]);

  const popupMissions = _.map(
    filteredMissionsInfo?.slice(2),
    (missionInfo) => missionInfo.mission?.data?.title,
  );

  return (
    <div className={classNames(styles.missions, className)} style={style}>
      {profileLoading || missionsLoading ? (
        <Placeholder style={{ width: '100%' }}>
          <Placeholder.Line length='short' style={{ height: '0px' }} />
          <Placeholder.Line length='long' style={{ height: '10px' }} />
        </Placeholder>
      ) : (
        !_.isEmpty(filteredMissionsInfo) &&
        filteredMissionsInfo?.length &&
        _.map(filteredMissionsInfo.slice(0, 2), (missionInfo, index) => (
          <div key={missionInfo?.missionId} className={styles.missionLink}>
            <span className={styles.missionTitle}>
              {missionInfo.mission?.data?.title}
            </span>
            {index === 1 && !_.isEmpty(popupMissions) && (
              <GenericTooltip
                trigger={
                  <span className={styles.morePill}>
                    +{popupMissions.length}
                  </span>
                }
                content={popupMissions.join(', ')}
              />
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default MissionsColumn;
