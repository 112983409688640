import gql from 'graphql-tag';
import Author from './Author';
import ContactFlow from './ContactFlow';

export default gql`
  fragment Sequence on Sequence {
    id
    title
    description
    contactFlow {
      ...ContactFlow
    }
    author {
      ...Author
    }
    creationDate
    lastEditionDate
    isArchived
    folderId
    lastUseDate
    lastUseTimestamp
    lastUseByAuthor {
      authorEmail
      date
      timestamp
    }
  }
  ${ContactFlow}
  ${Author}
`;
