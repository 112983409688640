import React, { FC, useContext, useMemo, useState, useCallback } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';

import useClientMissionsDailyEvents from '@/graphql/hooks/clients/useClientMissionsDailyEvents';
import useClientMissionsDailyEventsDetails from '@/graphql/hooks/clients/useClientMissionsDailyEventsDetails';
import GenericModal from '@/components/Common/GenericModal';
import { useUserAllowedDepartments } from '@/graphql/hooks/users/useUserAllowedDepartments';
import useMissionsWithFoldering from '@/graphql/hooks/searchPoolJobs/useMissionsWithFoldering';

import StatisticsParametersContext from '../../../StatisticsParametersContext';
import DailyEventsDataContainer from '../../../components/DailyEventsDataContainer/DailyEventsDataContainer';
import GraphWrapper from '../../../components/GraphWrapper/GraphWrapper';
import { filterEventsByDateRange } from '../../../stats.helpers';
import { MissionRemovedDetail } from '../../../components/StatsProfileAndMissionRemovedTable/types';
import DetailsModalHeader from '../../../components/DetailsModalHeader';
import StatsProfileAndMissionRemovedTable from '../../../components/StatsProfileAndMissionRemovedTable';

import { formatDailyEvent, buildStack, coerceRemovalReason } from './helpers';

import styles from './MissionRemovals.module.less';

interface StatsFilters {
  startDay?: string;
  endDay?: string;
  removalReason?: string;
}

const MissionRemovals: FC = () => {
  const { i18n, t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [filters, setFilters] = useState<StatsFilters | undefined>(undefined);
  const [{ missionIds, timeSpan, aggregatePeriod }] = useContext(
    StatisticsParametersContext,
  );

  const { subsections } = useUserAllowedDepartments();
  const { missionsWithFoldering } = useMissionsWithFoldering();

  const subsectionIdMap = useMemo(() => {
    const result = {} as Record<string, boolean>;
    _.each(subsections, ({ id }) => {
      result[id] = true;
    });
    return result;
  }, [subsections]);

  const availableMissionIds = useMemo(() => {
    const result = [] as string[];
    _.each(missionsWithFoldering, ({ id, foldering }) => {
      if (
        foldering?.subsection?.id &&
        subsectionIdMap[foldering.subsection.id]
      ) {
        result.push(id);
      }
    });
    return result;
  }, [missionsWithFoldering, subsectionIdMap]);

  const sentMissionIds = useMemo(
    () => (_.isEmpty(missionIds) ? availableMissionIds : missionIds),
    [missionIds, availableMissionIds],
  );
  const { dailyEvents = [] } = useClientMissionsDailyEvents({
    missionIds: sentMissionIds,
  });

  const [
    fetchDetails,
    { dailyEvents: dailyEventDetails, loading: detailsLoading },
  ] = useClientMissionsDailyEventsDetails();

  const applyFilters = useCallback(
    (newFilters: StatsFilters) => {
      setModalOpen(true);
      setFilters(newFilters);
      fetchDetails({ missionIds: sentMissionIds });
    },
    [setModalOpen, setFilters, fetchDetails, sentMissionIds],
  );

  const missionRemovedDetails = useMemo(() => {
    const eventsInRange = filterEventsByDateRange(
      timeSpan,
      dailyEventDetails || [],
    );

    if (!filters) {
      return [];
    }

    const { startDay, endDay } = filters;

    const result: MissionRemovedDetail[] = [];

    _.each(eventsInRange, ({ day, details }) => {
      if ((startDay && day < startDay) || (endDay && day >= endDay)) {
        return;
      }
      _.each(details, (detail) => {
        if (detail.type !== 'nbRemoved') {
          return;
        }
        const { profileItems, missionId } = detail;
        const removalReason = coerceRemovalReason(detail.removalReason);
        if (filters.removalReason && removalReason !== filters.removalReason) {
          return;
        }
        _.each(profileItems, ({ profileId }) => {
          result.push({ profileId, missionId, day });
        });
      });
    });

    return result;
  }, [filters, dailyEventDetails, timeSpan]);

  const modalSubtitle = useMemo(() => {
    if (!filters?.startDay || !filters?.endDay) {
      return '';
    }
    const dateDisplayOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    const dayToLocaleString = (day: string): string =>
      new Date(day).toLocaleString(i18n.resolvedLanguage, dateDisplayOptions);

    return `${filters.removalReason} - ${t(
      'reveal.reports.missions.removed.detailsModal.subtitle',
      {
        startDate: dayToLocaleString(filters.startDay),
        endDate: dayToLocaleString(filters.endDay),
      },
    )}`;
  }, [filters, i18n, t]);

  const formattedDailyEvents = useMemo(
    () => _.map(dailyEvents, formatDailyEvent),
    [dailyEvents],
  );

  const stack = useMemo(() => buildStack(formattedDailyEvents), [
    formattedDailyEvents,
  ]);

  return (
    <div className={styles.card}>
      <GraphWrapper
        timeSpan={timeSpan}
        lockFrequency
        title={
          <h3 className={styles.title}>
            {t('reveal.reports.adventure.removedFromMission.title')}
          </h3>
        }
        renderChildren={(_f, mode, type) => (
          <DailyEventsDataContainer
            dailyEvents={formattedDailyEvents}
            frequency={aggregatePeriod}
            dateRange={timeSpan}
            mode={mode}
            type={type}
            stack={stack}
            fetchDetails={(startDay, endDay, removalReason) =>
              applyFilters({ startDay, endDay, removalReason })
            }
          />
        )}
      />
      {detailsLoading ? (
        <Dimmer active>
          <Loader size='large' />
        </Dimmer>
      ) : (
        <GenericModal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Content>
            <DetailsModalHeader>
              <h1>{t('reveal.reports.missions.removed.title')}</h1>
              {modalSubtitle && <h2>{modalSubtitle}</h2>}
            </DetailsModalHeader>
            <StatsProfileAndMissionRemovedTable
              missionRemovedDetails={missionRemovedDetails}
            />
          </Modal.Content>
        </GenericModal>
      )}
    </div>
  );
};

export default MissionRemovals;
