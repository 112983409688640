/* eslint-disable max-len */
import _ from 'underscore';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Segment } from 'semantic-ui-react';
import DetailsSection from './components/DetailsSections';
import ErrorBoundary from './components/ErrorBoundary';
import { formatAtsSectionsData, formatSectionsData } from './helpers';

import './MatchingDetailsPopup.css';

const MatchingDetailsPopup = ({ matchingDetails, atsExplanation, t }) => {
  const criteria = matchingDetails?.criteria;
  const sectionsData = formatSectionsData({ criteria });

  const atsSectionsData = formatAtsSectionsData({ atsExplanation, t });

  return (
    <Segment className='matching-details-popup' basic>
      {/* {_.isNumber(matchingDetails?.score) && ( */}
      {/* <div className='popup-header'> */}
      {/* <MatchingScoreLabel className='popup-score' score={matchingDetails?.score}/> */}
      {/* <span> match</span> */}
      {/* </div> */}
      {/* )} */}

      {(atsSectionsData?.recruiter
        || atsSectionsData?.coordinator
        || atsSectionsData?.creationDate
        || atsSectionsData?.lastActivityDate) && (
          <div className='section-container'>
            {atsSectionsData?.recruiter && <DetailsSection {...atsSectionsData.recruiter} />}
            {atsSectionsData?.coordinator && <DetailsSection {...atsSectionsData.coordinator} />}
            {atsSectionsData?.creationDate && <DetailsSection {...atsSectionsData.creationDate} />}
            {atsSectionsData?.lastActivityDate && <DetailsSection {...atsSectionsData.lastActivityDate} />}
          </div>
        )}

      {!_.isEmpty(atsSectionsData?.applications) && (
        <div className='section-container'>
          {_.map(atsSectionsData.applications, (applicationData) => (
            <DetailsSection {...applicationData} />
          ))}
        </div>
      )}

      {atsSectionsData?.tags && (
        <div className='section-container'>
          <DetailsSection {...atsSectionsData?.tags} />
        </div>
      )}

      {(sectionsData?.experience || sectionsData?.majorJobPosition || sectionsData?.minorJobPositions) && (
        <div className='section-container'>
          {sectionsData?.experience && <DetailsSection {...sectionsData?.experience} />}
          {sectionsData?.majorJobPosition && <DetailsSection {...sectionsData?.majorJobPosition} />}
          {sectionsData?.minorJobPositions && <DetailsSection {...sectionsData?.minorJobPositions} />}
        </div>
      )}

      {(sectionsData?.mainSkills || sectionsData?.importantSkills || sectionsData?.bonusSkills) && (
        <div className='section-container'>
          {sectionsData?.mainSkills && <DetailsSection {...sectionsData?.mainSkills} />}
          {sectionsData?.importantSkills && <DetailsSection {...sectionsData?.importantSkills} />}
          {sectionsData?.bonusSkills && <DetailsSection {...sectionsData?.bonusSkills} />}
        </div>
      )}

      {sectionsData?.locationsTarget && (
        <div className='section-container'>
          <DetailsSection {...sectionsData?.locationsTarget} />
        </div>
      )}

      {sectionsData?.industriesTarget && (
        <div className='section-container'>
          <DetailsSection {...sectionsData?.industriesTarget} />
        </div>
      )}

      {(sectionsData?.backgroundSolidity || sectionsData?.schoolPrestige || sectionsData?.startupnessImportance) && (
        <div className='section-container'>
          {sectionsData?.backgroundSolidity && <DetailsSection {...sectionsData?.backgroundSolidity} />}
          {sectionsData?.schoolPrestige && <DetailsSection {...sectionsData?.schoolPrestige} />}
          {sectionsData?.startupnessImportance && <DetailsSection {...sectionsData?.startupnessImportance} />}
        </div>
      )}
    </Segment>
  );
};

const Wrapper = (props) => {
  return (
    <ErrorBoundary>
      <MatchingDetailsPopup {...props} />
    </ErrorBoundary>
  );
};

export default withTranslation('translations')(Wrapper);
