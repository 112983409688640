import React, { FC, Fragment } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { StackItem } from '@/types/statistics/stackItem';
import { Stats } from '@/types/statistics/stats';
import { filterStack, getStackName } from '@/common/helpers/stats';
import { getPercentage, percentageToString } from '@/common/utils/number';
import { getRecordTotal } from '@/common/utils/record';
import LegendCategory from '../Legend/LegendCategory';
import PieChartSection from './PieChartSection';

import styles from './PieChart.module.less';

interface PieChartProps {
  stack: StackItem[];
  stats: Stats;
  exhaustive?: boolean;
}

const MINIMUM_RATIO = 0.006; // Roughly 2°/360°

const PieChart: FC<PieChartProps> = ({ stack, stats, exhaustive }) => {
  const { t } = useTranslation();

  const filteredStack = exhaustive ? stack : filterStack(stack, [stats]);

  const total = getRecordTotal(stats.values);

  // We bump up smaller non-zero values so that they still appear in the chart
  const compressedValues = _.mapObject(stats.values, (value) =>
    value ? Math.max(value, MINIMUM_RATIO * total) : 0,
  );

  const compressedTotal = getRecordTotal(compressedValues);

  const data = _.reduce(
    filteredStack,
    (accumulator, stackItem) => {
      const { datakey, color } = stackItem;
      if (!exhaustive && !stats.values[datakey]) {
        return accumulator;
      }
      const { normalizedValue: lastValue, offset: lastOffset } =
        _.last(accumulator) || {};
      const offset = (lastValue || 0) + (lastOffset || 0);
      return [
        ...accumulator,
        {
          datakey,
          name: getStackName(t, stackItem),
          value: stats.values[datakey],
          normalizedValue: compressedValues[datakey],
          color,
          offset,
        },
      ];
    },
    [] as {
      name: string;
      value: number;
      normalizedValue: number;
      datakey: string;
      color: string;
      offset: number;
    }[],
  );

  return (
    <div className={styles.container}>
      <div className={styles.chart}>
        {_.map(data, ({ datakey, color, normalizedValue, offset }) => (
          <PieChartSection
            key={datakey}
            color={color}
            value={normalizedValue / (compressedTotal || 1)}
            offset={offset / (compressedTotal || 1)}
          />
        ))}
      </div>
      <div className={styles.legend}>
        {_.map(data, ({ name, datakey, color, value }) => (
          <Fragment key={datakey}>
            <LegendCategory color={color}>{name}</LegendCategory>
            <div className={styles.legendValue}>
              <span className={styles.percentage}>
                {percentageToString(t)(getPercentage(value, total, 1))}
              </span>
              <span className={styles.number}>{value}</span>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default PieChart;
