import React, { useState, useRef, useEffect } from 'react';
import { Button, Input } from 'semantic-ui-react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import { SMART_VARIABLES } from '@/containers/Editor/VariableExamplesPreviewModal/SmartVariableWithPreviewModal';
import styles from '../SequenceDynamicVariables.module.less';

type Props = {
  snippet: SweetEvaluatorTypes.Variable;
  onEditSmartVariable: () => void;
  onSubmit: (updatedSnippet: SweetEvaluatorTypes.Variable) => void;
};

const FallbackSnippetPopup: React.FC<Props> = ({
  snippet,
  onSubmit,
  onEditSmartVariable,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(snippet?.fallbackValue?.text || '');
  const inputRef = useRef<Input | null>(null);

  const onSave = async (event: any) => {
    event.preventDefault();
    if (!snippet) {
      return;
    }
    onSubmit({ ...snippet, fallbackValue: { type: 'text', text: value } });
  };

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.focus();
  }, []);

  const shouldDisplayEditButton = _.includes(SMART_VARIABLES, snippet.type);

  return (
    <div>
      <form onSubmit={onSave} className={styles.sequenceFallbackPopup}>
        <div className={styles.title}>
          <img
            alt='arrow'
            className={styles.image}
            src='/images/icons/fallback.svg'
          />
          <span>Fallback</span>
        </div>

        <Input
          placeholder={t('reveal.mergeTags.fallbackPopup.placeholder')}
          className={styles.fallbackInput}
          value={value}
          onChange={(e, { value: v }) => setValue(v)}
          ref={inputRef}
        />
        <Button
          size='medium'
          className={classNames(styles.button, styles.submitButton)}
          type='submit'
        >
          {t('common.save')}
        </Button>
        {shouldDisplayEditButton && (
          <Button
            size='medium'
            className={classNames(styles.button, styles.editButton)}
            type='button'
            onClick={() => onEditSmartVariable()}
          >
            {t('common.edit')}
          </Button>
        )}
      </form>
    </div>
  );
};

export default FallbackSnippetPopup;
