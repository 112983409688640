import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import useClientId from '@/hooks/router/useClientId';

import ContactCategoryContext from '@/context/ContactTypeContext';
import {
  MergeTagsSelectorOption,
  generateOptions,
  CONDITIONS_CHAINING_VARIABLES,
  getContactCustomFieldOptions,
  getProjectCustomFieldOptions,
} from '../utils';
import MergeTagsVariablesOptions from './MergeTagsVariableOptions';
import useConditionsChainingCustomFields from '../../VariableExamplesPreviewModal/ConditionsChaining/useConditionsChainingCustomFields';
import { filterCustomFieldsForSimplifiedCondition } from './helpers';

import styles from '../MergeTagsSelector.module.less';

interface MergeTagsConditionVariablesOptionsProps {
  onSelectVariable: (variable: MergeTagsSelectorOption) => void;
  hasPermission: boolean;
}

const MergeTagsConditionVariablesOptions: FC<MergeTagsConditionVariablesOptionsProps> = ({
  onSelectVariable,
  hasPermission,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();

  const {
    profileCustomFields,
    missionCustomFields,
  } = useConditionsChainingCustomFields({ clientId });

  const contactCategory = useContext(ContactCategoryContext);

  const contactCategoryCustomFields = useMemo(
    () =>
      _.filter(
        profileCustomFields,
        (field) =>
          (contactCategory === 'human' && !field.contactCategory) ||
          field.contactCategory?.type === contactCategory,
      ),
    [contactCategory, profileCustomFields],
  );

  const filteredProfileCustomFields = useMemo(
    () => filterCustomFieldsForSimplifiedCondition(contactCategoryCustomFields),
    [contactCategoryCustomFields],
  );

  const filteredMissionCustomFields = useMemo(
    () => filterCustomFieldsForSimplifiedCondition(missionCustomFields),
    [missionCustomFields],
  );

  const generateTranslatedOptions = useMemo(() => generateOptions(t), [t]);

  const conditionsChainingVariableOptions = useMemo(
    () => generateTranslatedOptions(CONDITIONS_CHAINING_VARIABLES),
    [generateTranslatedOptions],
  );

  const contactCustomFieldOptions = useMemo(
    () => getContactCustomFieldOptions(t, filteredProfileCustomFields),
    [t, filteredProfileCustomFields],
  );

  const projectCustomFieldOptions = useMemo(
    () => getProjectCustomFieldOptions(t, filteredMissionCustomFields),
    [t, filteredMissionCustomFields],
  );

  const allCustomFieldOptions = useMemo(
    () => [...contactCustomFieldOptions, ...projectCustomFieldOptions],
    [contactCustomFieldOptions, projectCustomFieldOptions],
  );

  return (
    <>
      <MergeTagsVariablesOptions
        options={conditionsChainingVariableOptions}
        onSelectVariable={onSelectVariable}
        hasPermission={hasPermission}
      />
      {!_.isEmpty(allCustomFieldOptions) && (
        <>
          <hr className={styles.hr} />
          <span className={styles.sectionTitle}>
            {t('editor.mergeTagsSelector.ifElseCustomFields')}
          </span>
          <MergeTagsVariablesOptions
            options={allCustomFieldOptions}
            onSelectVariable={onSelectVariable}
            hasPermission={hasPermission}
          />
        </>
      )}
    </>
  );
};

export default MergeTagsConditionVariablesOptions;
