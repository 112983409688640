import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Checkbox, Dropdown, Form, Segment } from 'semantic-ui-react';
import _ from 'underscore';

import { getTranslatedText, getRandomString } from '@/common';
import {
  getActionType,
  isEmailAction,
  isLinkedinOrEmailAction,
  LINKEDIN_MESSAGE_SUBTYPES,
  TASK_TYPES,
  UNIPILE_TASK_TYPES,
} from '@/common/constants/taskTypes';
import GenericDropdown from '@/components/Common/GenericDropdown';
import MergeTagsAutomationEditor from '@/containers/Editor/MergeTagsAutomationEditor';
import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import {
  RevealCcBccSelector,
  RevealCcBccInputs,
  RevealCcBccContextProvider,
  RevealCcBccSoboSelector,
} from '@/components/Sequences/RevealCcBcc/RevealCcBcc';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import useUserSenders from '@/graphql/hooks/users/useUserSenders';
import DropdownMenuPanel from '@/components/Common/DropdownMenuPanel';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import Dots from '@/components/Common/Icons/Dots';
import MetaTaskForm from '@/revealComponents/ExplicitTasks/MetaTaskForm';
import { sanitizeTypename } from '@/common/utils/apollo';
import { CustomIcon } from '@/components/Common/GenericIconSelector/IconDropdown/IconDropdown';
import { CustomTextArea } from '@/containers/Parameters/OfferCriteria/CustomFormItems';
import useSharedMessagingAccounts from '@/graphql/hooks/clients/useSharedServiceAccount';
import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';
import Alert from '@/components/Common/Alert/Alert';

import GenerateSequence from './GenerateSequence';
import {
  hasMandatoryMessage,
  TYPES_WITH_SUBJECT,
  canHaveContent,
  TYPES_WITH_DEFAULT_DISPLAY_CONTENT,
  isMessageOpenByDefault,
  EMAIL_TYPE_OPTIONS,
  getCheckboxTradParams,
  TYPES_WITH_MESSAGE,
  TYPES_WITH_DESCRIPTION,
  TYPES_WITH_SIGNATURE,
  useClientTaskOptions,
} from './helpers';
import TriggerEditor, { DEFAULT_DELAY_TRIGGER } from './TriggerEditor';
import CreateMetaTaskFormModal from './MetaTaskForm/CreateMetaTaskFormModal';

import './FullContactFlowActionEditor.css';

// TODO: try reducing props and memoizing based on action ? If performance issues arise
const FullContactFlowActionEditor = ({
  clientId,
  index,
  firstEmailIndex,
  action,
  actions,
  signature,
  permissionsAndNudges,
  onUpdate,
  onDelete,
  onMoveUpward,
  onMoveDownward,
  templates,
  templateOptions,
  onQuitContactFlowEditor,
  formErrors,
  sequenceId,
  t,
  applyCcBccToNextMails,
}) => {
  // To be coherent in all cases, undefined at init
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [generateSequenceOpen, setGenerateSequenceOpen] = useState(false);
  // Show message or description editor
  const [showContent, setShowContent] = useState(
    (!_.isEmpty(action?.message?.body) && action?.message?.body !== '<br/>') ||
      isMessageOpenByDefault(action),
  );
  const [isOpen, setIsOpen] = useState(false);
  const [readyToDelete, setReadyToDelete] = useState(false);
  const [version, setVersion] = useState(1);
  const [snippets, setSnippets] = useState(action?.snippets || []);
  const [body, setBody] = useState(action?.message?.body || '');
  const [subject, setSubject] = useState(action?.message?.subject || '');
  const [showMetaTaskFormModal, setShowMetaTaskFormModal] = useState(false);
  const [unipileSenderShown, setUnipileSenderShown] = useState(
    !!action?.message?.senderId || !!action?.senderId || false,
  );
  const [accountTypeErrors, setAccountTypeErrors] = useState([]);
  const { user } = useCurrentUser();
  const { sharedMessagingAccounts } = useSharedMessagingAccounts();
  const { permissions } = useClientPermissions(clientId) || {};
  const messageTemplate = _.findWhere(templates, {
    id: action?.message?.templateId,
  });

  const { options: availableActionOptions, selectedOption } =
    useClientTaskOptions({
      currentAction: action,
      taskTypesToIgnore: [
        ...(!permissions.whatsappSequenceActions
          ? [TASK_TYPES.WHATSAPP_SEND_MESSAGE]
          : []),
      ],
    });

  const { data: userSendersData, loading: userSendersLoading } = useUserSenders(
    { granted: true, owned: true },
  );

  const cc = action?.message?.cc || [];
  const bcc = action?.message?.bcc || [];

  const linkedinMessagingAccounts = _.filter(
    sharedMessagingAccounts,
    (account) => account.type === 'unipile-linkedin',
  );

  const whatsappMessagingAccounts = _.filter(
    sharedMessagingAccounts,
    (account) => account.type === 'unipile-whatsapp',
  );

  const possibleSharedMessagingAccounts = useMemo(
    () =>
      permissions?.whatsappSequenceActions &&
      _.includes(UNIPILE_TASK_TYPES, action.type)
        ? action.type === TASK_TYPES.WHATSAPP_SEND_MESSAGE
          ? whatsappMessagingAccounts
          : linkedinMessagingAccounts
        : [],
    [
      action?.type,
      permissions,
      linkedinMessagingAccounts,
      whatsappMessagingAccounts,
    ],
  );

  const sender = useMemo(() => {
    if (action?.message?.sender) {
      return action?.message?.sender;
    }

    if (
      (action?.message?.senderId || action?.senderId) &&
      (userSendersData || possibleSharedMessagingAccounts)
    ) {
      return _.findWhere(
        [
          ...(userSendersData?.user?.senders || []),
          ...(possibleSharedMessagingAccounts || []),
        ],
        {
          id: action?.message?.senderId || action?.senderId,
        },
      );
    }

    return null;
  }, [userSendersData, action, possibleSharedMessagingAccounts]);

  const getSequenceSnippets = () => {
    return _.flatten(
      actions.map((sequenceAction) => {
        const { message, snippets: actionSnippets } = sequenceAction;
        return _.filter(actionSnippets || [], ({ id }) => {
          return message?.subject?.includes(id) || message?.body?.includes(id);
        });
      }),
    );
  };

  const sequenceSnippets = getSequenceSnippets();

  const areSameEmailsLists = (emailsList1, emailsList2) =>
    (emailsList1 || []).join(';') === (emailsList2 || []).join(';');

  const hasFollowingEmailActionsWithDifferentCcBcc = _.some(
    (actions || []).slice(index + 1),
    (nextAction) =>
      isEmailAction(nextAction) &&
      (!areSameEmailsLists(
        [...(nextAction.message?.cc || [])].sort(),
        [...cc].sort(),
      ) ||
        !areSameEmailsLists(
          [...(nextAction.message?.bcc || [])].sort(),
          [...bcc].sort(),
        )),
  );

  useEffect(() => {
    if (action?.message?.templateId && !subject && !body) {
      setSubject(messageTemplate?.subject);
      setBody(messageTemplate?.body);
    }
  }, [action, subject, body, messageTemplate]);

  useEffect(() => {
    if (
      !_.isEmpty(action?.message?.body) &&
      action?.message?.body !== '<br/>'
    ) {
      setShowContent(true);
    }
  }, [action]);

  /*
  useEffect(() => {
    if (
      action?.type === TASK_TYPES.LINKEDIN_SEND_MESSAGE &&
      !action?.message?.senderId &&
      action?.trigger?.type !== 'manual-trigger'
    ) {
      onUpdate({
        action: {
          ...action,
          actionId: action?.actionId,
          trigger: MANUAL_TRIGGER,
        },
      });
    }
  }, [action, index, onUpdate]);
  */

  useEffect(() => {
    if (
      (action?.message?.senderId || action?.senderId) &&
      _.includes(UNIPILE_TASK_TYPES, action?.type)
    ) {
      setUnipileSenderShown(true);
    }
  }, [action]);

  useEffect(() => {
    if (_.isEmpty(snippets) && !_.isEmpty(action.snippets)) {
      setSnippets(action.snippets);
      setVersion((previousVersion) => previousVersion + 1);
    }
    // eslint-disable-next-line
  }, [action]);

  const onUpdateActionTemplate = ({ templateId }) => {
    const template = _.findWhere(templates, { id: templateId });

    const {
      body: updatedBody,
      subject: updatedSubject,
      snippets: updatedSnippets,
    } = MergeTagsService.replaceAllIds({
      subject: template?.subject || '',
      body: template?.body || '',
      subId: 'sequence',
      snippets: template.snippets,
      t,
    });

    const newAction = {
      ...action,
      ...(action.message && {
        message: {
          ...action.message,
          body: updatedBody,
          subject: updatedSubject,
        },
      }),
      snippets: updatedSnippets,
    };
    setSubject(updatedSubject);
    setBody(updatedBody);
    setSnippets(updatedSnippets);
    onUpdate({ action: newAction });
    setSelectedTemplateId(templateId);
  };

  const getActionTypeAndSubtype = ({ value }) => {
    if (value === TASK_TYPES.LINKEDIN_RECRUITER_INMAIL) {
      return {
        type: TASK_TYPES.LINKEDIN_SEND_MESSAGE,
        subtype: LINKEDIN_MESSAGE_SUBTYPES.RECRUITER,
      };
    }
    if (value === TASK_TYPES.LINKEDIN_PREMIUM_INMAIL) {
      return {
        type: TASK_TYPES.LINKEDIN_SEND_MESSAGE,
        subtype: LINKEDIN_MESSAGE_SUBTYPES.PREMIUM,
      };
    }
    if (value === TASK_TYPES.LINKEDIN_SALESNAVIGATOR_INMAIL) {
      return {
        type: TASK_TYPES.LINKEDIN_SEND_MESSAGE,
        subtype: LINKEDIN_MESSAGE_SUBTYPES.SALESNAVIGATOR,
      };
    }
    if (value === TASK_TYPES.LINKEDIN_SEND_MESSAGE) {
      return {
        type: TASK_TYPES.LINKEDIN_SEND_MESSAGE,
        subtype: LINKEDIN_MESSAGE_SUBTYPES.CLASSIC,
      };
    }
    return {
      type: value,
      subtype: null,
    };
  };

  // NOTE: changes the action type but not the trigger
  const onChangeActionType = useCallback(
    ({ from }) => {
      return (e, { value: selectedIdOrType }) => {
        if (
          _.includes(
            [
              TASK_TYPES.LINKEDIN_SEND_MESSAGE,
              TASK_TYPES.LINKEDIN_CHECK_REQUEST,
              TASK_TYPES.LINKEDIN_SEND_REQUEST,
            ],
            action?.type,
          ) &&
          _.includes(['manual', 'automatic'], selectedIdOrType) &&
          from === 'secondary-dropdown'
        ) {
          const shouldPerformAutomatically = !!(
            selectedIdOrType === 'automatic'
          );
          const newAction = {
            ...action,
            shouldPerformAutomatically,
            ...(shouldPerformAutomatically &&
              action.trigger?.type === 'manual-trigger' && {
                trigger: DEFAULT_DELAY_TRIGGER,
              }),
          };
          onUpdate({ action: newAction });
          return;
        }

        const newSelectedOption = _.findWhere(availableActionOptions, {
          // Value can be ID if multiple actions of the same type
          value: selectedIdOrType,
        });

        const { type, subtype } = getActionTypeAndSubtype({
          value: newSelectedOption?.type,
        });

        const {
          customActionId,
          execution,
          format,
          text,
          optionIcon,
          completionButtonDisplay,
        } = newSelectedOption || {};
        const newAction = {
          actionId: action?.actionId,
          type,
          ...(subtype && { subtype }),
          ...(action?.trigger && { trigger: action?.trigger }),
          ...(customActionId && { customActionId }),
          ...(execution && { execution }),
          ...(format && { format }),
          ...(text && { title: { default: text } }),
          ...(optionIcon &&
            type === 'custom' && { icon: sanitizeTypename(optionIcon) }),
          ...(completionButtonDisplay && { completionButtonDisplay }),

          // NOTE: discard message && description (except for emails, see below)
          // ...(action?.description && _.contains(TYPES_WITH_DESCRIPTION, value) && { description: action?.description }),
        };

        // Keep message when switching email types
        if (
          action?.message &&
          ((isLinkedinOrEmailAction(action) &&
            isLinkedinOrEmailAction(newAction)) ||
            newAction.type === TASK_TYPES.WHATSAPP_SEND_MESSAGE)
        ) {
          newAction.message = action?.message;
        }

        // clear sender and shouldPerformAutomatically if change type
        if (!_.includes(['manual', 'automatic'], selectedIdOrType)) {
          if (selectedIdOrType !== action.type) {
            newAction.shouldPerformAutomatically = false;
            if (newAction.sender) {
              delete newAction.sender;
            }
            if (newAction.senderId) {
              delete newAction.senderId;
            }
            if (newAction.message?.senderId) {
              delete newAction.message.senderId;
            }
            if (newAction.message?.sender) {
              delete newAction.message.sender;
            }
          }
        }

        if (action?.description) {
          newAction.description = action?.description;
        }

        if (action?.snippets) {
          newAction.snippets = action?.snippets;
        }

        // When switching to an email action from main dropdown, set good type by default
        if (from === 'main-dropdown' && isEmailAction(newAction)) {
          if (index === 0) {
            newAction.type = TASK_TYPES.SEND_EMAIL_MANUAL;
          } else if (index <= firstEmailIndex) {
            newAction.type = TASK_TYPES.SEND_EMAIL_MANUAL;
          } else {
            newAction.type = TASK_TYPES.SEND_EMAIL;
          }
        }

        if (index > 0 && action?.trigger?.type === 'manual-trigger') {
          newAction.trigger = DEFAULT_DELAY_TRIGGER;
        }

        if (
          selectedIdOrType === 'manual' &&
          newAction.type === TASK_TYPES.SEND_EMAIL
        ) {
          newAction.type = TASK_TYPES.SEND_EMAIL_MANUAL;
        }

        if (
          selectedIdOrType === 'automatic' &&
          newAction.type === TASK_TYPES.SEND_EMAIL_MANUAL
        ) {
          newAction.type = TASK_TYPES.SEND_EMAIL;
        }

        setAccountTypeErrors([]);
        onUpdate({ action: newAction });
        setSubject(newAction.message?.subject || '');
        setBody(newAction.message?.body || '');
        setSnippets(newAction.snippets || []);
        setShowContent(
          TYPES_WITH_DEFAULT_DISPLAY_CONTENT.includes(newAction.type),
        );
      };
    },
    [action, availableActionOptions, firstEmailIndex, index, onUpdate],
  );

  const onChangeDescription = ({ value }) => {
    const newAction = {
      ...(action || {}),
      description: {
        default: value?.value,
      },
    };
    onUpdate({ action: newAction });
  };

  const onChangeShowContent = () => {
    setShowContent((prevState) => {
      if (prevState) {
        resetDescriptionAndMessage();
      }
      return !prevState;
    });
  };

  const resetDescriptionAndMessage = () => {
    const newAction = {
      ...(action || {}),
      description: undefined,
      message: {
        ...(action?.message || {}),
        body: '',
        subject: '',
      },
    };

    onUpdate({ action: newAction });
  };

  const firstEmailError = _.findWhere(formErrors, {
    error: 'first-email-must-be-manual',
  });
  const isFollowup =
    isEmailAction(action) && _.findIndex(actions, isEmailAction) !== index;

  const shouldDisplayContent = showContent || hasMandatoryMessage(action);

  const isFirstSoboInSequence = useMemo(() => {
    const seenSenderIds = {};

    let result = false;

    _.each(actions, ({ actionId, message }) => {
      if (result) return;
      if (!message?.senderId) return;

      if (!seenSenderIds[message.senderId] && actionId === action.actionId) {
        result = true;
      }

      seenSenderIds[message.senderId] = true;
    });

    return result;
  }, [actions, action]);

  const lastSoboSenderIdBefore = useMemo(() => {
    let hasSeenAction = false;
    let result = false;

    _.each(actions, ({ actionId, message }) => {
      if (hasSeenAction) {
        return;
      }

      if (actionId === action.actionId) {
        hasSeenAction = true;
      }

      if (message?.senderId) {
        result = message.senderId;
      }
    });

    return result;
  }, [actions, action]);

  const shouldDisplaySubject =
    // If action has a designated senderID, we must ask for a subject
    // but only if it has not been used before
    (!isFollowup || isFirstSoboInSequence) &&
    _.contains(TYPES_WITH_SUBJECT, getActionType(action));

  const displayContentToggle =
    !hasMandatoryMessage(action) && canHaveContent(action);

  const handleDelete = (event) => {
    event.stopPropagation();
    if (!readyToDelete) {
      return setReadyToDelete(true);
    }
    setIsOpen(false);
    return onDelete();
  };

  const handleClose = (event) => {
    if (!event) {
      setIsOpen(false);
      setReadyToDelete(false);
    }
  };

  const onChangeSubject = (newSubject) => {
    setSubject(newSubject);
    const newAction = {
      ...action,
      message: {
        ...action.message,
        subject: newSubject,
      },
    };
    onUpdate({ action: newAction });
  };

  const onChangeBody = (newBody) => {
    setBody(newBody);
    const newAction = {
      ...action,
      message: {
        ...action.message,
        body: newBody,
      },
    };
    onUpdate({ action: newAction });
  };

  const onSnippetAdded = ({ newSnippet, field, newContent }) => {
    if (!newSnippet) {
      return;
    }
    const newSnippets = [...snippets, newSnippet];
    const newAction = {
      ...action,
      snippets: newSnippets,
    };
    if (field === 'subject') {
      newAction.message.subject = newContent;
      setSubject(newContent);
    }
    if (field === 'body') {
      newAction.message.body = newContent;
      setBody(newContent);
    }
    setSnippets(newSnippets);
    onUpdate({ action: newAction });
    setVersion((prevState) => prevState + 1);
  };

  const onSnippetFragmentInserted = ({ newSnippets, field, newContent }) => {
    const updatedSnippets = [...snippets];
    const newAction = {
      ...action,
    };
    if (!_.isEmpty(newSnippets)) {
      updatedSnippets.push(...newSnippets);
      newAction.snippets = updatedSnippets;
    }
    if (field === 'subject') {
      newAction.message.subject = newContent;
      setSubject(newContent);
    }
    if (field === 'body') {
      newAction.message.body = newContent;
      setBody(newContent);
    }
    setSnippets(updatedSnippets);
    onUpdate({ action: newAction });

    setVersion((prevState) => prevState + 1);
  };

  const onSnippetUpdated = ({ updatedSnippet }) => {
    const updatedSnippets = _.map(snippets, (sn) => {
      if (sn.id === updatedSnippet.id) {
        return updatedSnippet;
      }
      return sn;
    });
    const newAction = {
      ...action,
      ...(action.message && {
        message: {
          ...action.message,
          body,
          subject,
        },
      }),
      snippets: updatedSnippets,
    };
    onUpdate({ action: newAction });
    setSnippets(updatedSnippets);
    setVersion((prevState) => prevState + 1);
  };

  const handleCcAndBccUpdate = useCallback(
    (newCcBcc = {}) => {
      const newAction = {
        ...action,
        message: {
          ...(action.message || null),
          ...newCcBcc,
        },
      };

      onUpdate({ action: newAction });
    },
    [onUpdate, action],
  );

  const handleSenderUpdate = useCallback(
    ({ senderId, sender }) => {
      const senderScopes = sender?.scopes || [];
      const currentAccountTypeErrors = [];
      if (
        action.subtype === TASK_TYPES.LINKEDIN_SALESNAVIGATOR_INMAIL &&
        !_.find(senderScopes, (scope) => scope.type === 'salesnavigator')
      ) {
        currentAccountTypeErrors.push('salesnavigator');
      }
      if (
        action.subtype === TASK_TYPES.LINKEDIN_RECRUITER_INMAIL &&
        !_.find(senderScopes, (scope) => scope.type === 'recruiter')
      ) {
        currentAccountTypeErrors.push('recruiter');
      }
      if (
        action.subtype === TASK_TYPES.LINKEDIN_PREMIUM_INMAIL &&
        !_.find(senderScopes, (scope) => scope.type === 'premium')
      ) {
        currentAccountTypeErrors.push('premium');
      }
      setAccountTypeErrors(currentAccountTypeErrors);
      const newAction = {
        ...action,
        ...(UNIPILE_TASK_TYPES.includes(action.type) &&
          !senderId &&
          !sender && {
            shouldPerformAutomatically: false,
          }),
        ...(action.type !== TASK_TYPES.LINKEDIN_CHECK_REQUEST && {
          message: {
            ...(action.message || null),
            senderId,
            sender,
          },
        }),
        ...(action.type === TASK_TYPES.LINKEDIN_CHECK_REQUEST && {
          senderId,
        }),
      };
      onUpdate({ action: newAction });
    },
    [onUpdate, action],
  );

  const handleSequenceGenerated = ({ newSubject, newBody, newSnippets }) => {
    const newAction = {
      ...action,
      message: {
        ...action.message,
        body: newBody,
        subject: newSubject,
      },
      snippets: newSnippets,
    };
    setSubject(newSubject);
    setBody(newBody);
    setSnippets(newSnippets);
    setVersion((previousVersion) => previousVersion + 1);
    onUpdate({ action: newAction });
  };

  const senderMatch = _.findWhere(userSendersData?.user?.senders, {
    id: action?.message?.senderId || action?.senderId,
  });
  const isUnauthorizedSender =
    (action?.message?.senderId || action?.senderId) && !senderMatch;

  const selectedMessagingAccount = useMemo(
    () =>
      _.findWhere(possibleSharedMessagingAccounts, {
        id: action?.message?.senderId || action?.senderId,
      }),
    [
      action?.message?.senderId,
      action?.senderId,
      possibleSharedMessagingAccounts,
    ],
  );

  const handleMetadataUpdate = (rule) => {
    const matchingRule = _.find(
      action.metadata,
      (metadata) => metadata.key === rule.key,
    );
    const newAction = {
      ...action,
      metadata: [
        ..._.filter(action.metadata, (metadata) => metadata.key !== rule.key),
        {
          key: rule.key,
          value: `${matchingRule?.value === 'false' || !matchingRule?.value}`,
        },
      ],
    };
    onUpdate({ action: newAction });
  };

  const metadataWarnings = useMemo(() => {
    const previousActions = actions.slice(0, index);
    const metadataFromPreviousActions = _.flatten(
      previousActions.map((previousAction) => {
        const previousTrueMetadata = _.filter(
          previousAction.metadata,
          (metadata) => metadata.value === 'true',
        );
        return previousTrueMetadata;
      }),
    );
    const warnings = [];
    _.uniq(
      _.each(metadataFromPreviousActions, (metadata) => {
        const expectedConditionValue = metadata.key.split(':')[0];
        const conditionAction = metadata.key.split(':')[1];
        if (
          [
            TASK_TYPES.LINKEDIN_RECRUITER_INMAIL,
            TASK_TYPES.LINKEDIN_PREMIUM_INMAIL,
            TASK_TYPES.LINKEDIN_SALESNAVIGATOR_INMAIL,
          ].includes(action.subtype) &&
          conditionAction === 'should-delete-next-linkedin-inmail'
        ) {
          warnings.push(
            t('sequences.metadataWarnings.stepWillBeDeleted', {
              condition:
                expectedConditionValue === 'false'
                  ? t('sequences.metadataWarnings.ifRefused')
                  : t('sequences.metadataWarnings.ifAccepted'),
            }),
          );
        } else if (
          action.subtype === LINKEDIN_MESSAGE_SUBTYPES.CLASSIC &&
          conditionAction === 'should-delete-next-linkedin-message'
        ) {
          warnings.push(
            t('sequences.metadataWarnings.stepWillBeDeleted', {
              condition:
                expectedConditionValue === 'false'
                  ? t('sequences.metadataWarnings.ifRefused')
                  : t('sequences.metadataWarnings.ifAccepted'),
            }),
          );
        }
      }),
    );
    return warnings;
  }, [actions, index, action, t]);

  // useEffect(() => {
  //   if (
  //     action?.actionId &&
  //     !sender &&
  //     permissions?.whatsappSequenceActions &&
  //     _.includes(UNIPILE_TASK_TYPES, action.type)
  //   ) {
  //     handleSenderUpdate({
  //       senderId: possibleSharedMessagingAccounts[0]?.id,
  //     });
  //   }
  // }, [
  //   sender,
  //   possibleSharedMessagingAccounts,
  //   permissions,
  //   handleSenderUpdate,
  //   action,
  // ]);

  return (
    <Segment
      className={classNames(
        'full-contact-flow-action-editor',
        !_.isEmpty(formErrors) && 'error',
      )}
    >
      {_.map(metadataWarnings, (warning) => (
        <Alert level='info' description={warning} key={warning} />
      ))}
      <div className='action-editor-header'>
        <div className='email-header'>
          <h3>
            {t('common.step')} {index + 1}
          </h3>

          <Dropdown
            className='task-type-dropdown hs-dropdown-menu-ellipsis hs-dropdown-text-with-image'
            selection
            value={selectedOption?.value}
            onChange={onChangeActionType({ from: 'main-dropdown' })}
            options={availableActionOptions.map((option) => ({
              ..._.omit(option, 'image'),
              text: (
                <div className='item-content'>
                  <div className='img-container'>
                    {option.optionIcon?.emojiText ? (
                      <span>{option.optionIcon?.emojiText}</span>
                    ) : (
                      <img
                        src={option.optionIcon.url || option.image.src}
                        alt=''
                      />
                    )}
                  </div>

                  {t(`sequences.tasks.${option.value}`, option.text)}
                </div>
              ),
            }))}
            // causes warning, but it works...
            text={
              <div className='text-with-image'>
                <CustomIcon small icon={selectedOption?.optionIcon} />
                <span>
                  {t(
                    `sequences.tasks.${selectedOption?.value}`,
                    selectedOption?.text,
                  )}
                </span>
              </div>
            }
          />
          {isEmailAction(action) && (
            <Dropdown
              className='email-type-dropdown'
              selection
              value={action?.type}
              onChange={onChangeActionType({
                from: 'secondary-dropdown',
              })}
              options={EMAIL_TYPE_OPTIONS(t)}
              error={!!firstEmailError}
            />
          )}
          {(_.includes(
            [
              TASK_TYPES.LINKEDIN_SEND_MESSAGE,
              TASK_TYPES.LINKEDIN_CHECK_REQUEST,
            ],
            action?.type,
          ) ||
            (action?.type === TASK_TYPES.LINKEDIN_SEND_REQUEST && index > 0)) &&
            (action?.message?.senderId || action?.senderId) && (
              <Dropdown
                className='email-type-dropdown'
                selection
                value={
                  action?.shouldPerformAutomatically ? 'automatic' : 'manual'
                }
                onChange={onChangeActionType({
                  from: 'secondary-dropdown',
                })}
                options={[
                  {
                    value: 'manual',
                    text: t('contactFlow.triggers.manually'),
                  },
                  {
                    value: 'automatic',
                    text: t('contactFlow.triggers.automatically'),
                  },
                ]}
                error={!!firstEmailError}
              />
            )}
        </div>

        <Dropdown
          className='sequences-actions-dropdown'
          onOpen={() => setIsOpen(true)}
          onClose={handleClose}
          open={isOpen}
          direction='left'
          text={t('sequences.edit.actions')}
        >
          <Dropdown.Menu>
            {index > 0 && (
              <Dropdown.Item className='dropdown-action' onClick={onMoveUpward}>
                <i className='ri-arrow-up-line' />
                {t('sequences.edit.moveUp')}
              </Dropdown.Item>
            )}
            {index !== actions.length - 1 && (
              <Dropdown.Item
                className='dropdown-action'
                onClick={onMoveDownward}
              >
                <i className='ri-arrow-down-line' />
                {t('sequences.edit.moveDown')}
              </Dropdown.Item>
            )}
            {(index > 0 || actions.length >= 2) && (
              <Dropdown.Item
                className={classNames(
                  'dropdown-action dropdown-action-delete',
                  readyToDelete && 'confirm-delete',
                )}
                onClick={handleDelete}
              >
                <i className='ri-delete-bin-line' />
                {!readyToDelete
                  ? t('sequences.edit.delete')
                  : t('sequences.edit.confirmDelete')}
              </Dropdown.Item>
            )}
            {permissions?.aiSequenceGeneration &&
              action.type !== TASK_TYPES.LINKEDIN_CHECK_REQUEST && (
                <Dropdown.Item
                  className='dropdown-action'
                  onClick={() => setGenerateSequenceOpen(true)}
                >
                  <svg
                    width='13'
                    height='13'
                    viewBox='0 0 24 24'
                    fill='currentColor'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M11.516 1.89224L3.39669 10.9709C2.50334 11.9699 3.21679 13.5489 4.56146 13.5489H8.88318C9.74386 13.5489 10.4416 14.2424 10.4416 15.0979V21.336C10.4416 22.4042 11.77 22.9061 12.484 22.1078L20.6033 13.0291C21.4967 12.0301 20.7832 10.4511 19.4385 10.4511H15.1168C14.2561 10.4511 13.5584 9.75759 13.5584 8.90213V2.66404C13.5584 1.59583 12.23 1.09386 11.516 1.89224Z'
                      fill='currentColor'
                    />
                  </svg>
                  {t('sequences.generation.generate')}
                </Dropdown.Item>
              )}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <RevealCcBccContextProvider cc={cc} bcc={bcc} sobo={sender}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <TriggerEditor
            permissionsAndNudges={permissionsAndNudges}
            action={action}
            onUpdate={onUpdate}
            displayExtraDescription
          />
          {permissions?.whatsappSequenceActions &&
            _.include(UNIPILE_TASK_TYPES, action.type) &&
            !_.isEmpty(possibleSharedMessagingAccounts) &&
            !unipileSenderShown && (
              <span
                className='unipile-sender-toggle'
                role='button'
                onClick={() => setUnipileSenderShown(true)}
                onKeyPress={() => setUnipileSenderShown(true)}
              >
                Sender
              </span>
            )}
          {isEmailAction(action) && (
            <RevealCcBccSelector
              allowSobo={
                !userSendersLoading &&
                !_.isEmpty(userSendersData?.user?.senders)
              }
            />
          )}
        </div>
        {_.includes(UNIPILE_TASK_TYPES, action.type) && unipileSenderShown && (
          <RevealCcBccSoboSelector
            onClose={() => setUnipileSenderShown(false)}
            sobo={{
              id: selectedMessagingAccount?.id,
              senderAddress: selectedMessagingAccount?.name,
            }}
            senders={[
              ...(selectedMessagingAccount
                ? [
                    {
                      id: null,
                      senderAddress: t('reveal.unipileSender.unselect'),
                    },
                  ]
                : []),
              ..._.map(possibleSharedMessagingAccounts, (account) => ({
                id: account.id,
                senderAddress: account.name,
              })),
            ]}
            onSoboSelected={(messagingAccount) =>
              handleSenderUpdate({
                senderId: messagingAccount?.id || null,
                sender: _.findWhere(possibleSharedMessagingAccounts, {
                  id: messagingAccount?.id,
                }),
              })
            }
          />
        )}
        {isEmailAction(action) && (
          <>
            <RevealCcBccInputs
              cc={cc}
              bcc={bcc}
              selectedSobo={sender}
              isUnauthorizedSender={isUnauthorizedSender}
              soboSenders={userSendersData?.user?.senders}
              onSoboSelected={(newSobo) =>
                handleCcAndBccUpdate({
                  senderId: newSobo?.id || null,
                  sender: _.findWhere(userSendersData?.user?.senders, {
                    id: newSobo?.id,
                  }),
                })
              }
              onCc={(newCc) => handleCcAndBccUpdate({ cc: newCc })}
              onBcc={(newBcc) => handleCcAndBccUpdate({ bcc: newBcc })}
            />
            {hasFollowingEmailActionsWithDifferentCcBcc &&
              (!_.isEmpty(cc) || !_.isEmpty(bcc)) && (
                <span
                  className='apply-to-next-mails'
                  onClick={() => applyCcBccToNextMails({ cc, bcc, index })}
                  onKeyPress={() => applyCcBccToNextMails({ cc, bcc, index })}
                  role='button'
                >
                  {t('reveal.candidatesView.timeline.applyToFollowingMails')}
                </span>
              )}
          </>
        )}
      </RevealCcBccContextProvider>
      {_.map(accountTypeErrors, (error) => (
        <Alert
          level='warning'
          description={t(`sequences.accountTypeErrors.${error}`)}
          key={error}
        />
      ))}
      {action?.format?.form && (
        <div className='meta-task-form-container'>
          <MetaTaskForm format={action.format} disabled />
        </div>
      )}
      <div className='action-editor-config' id={`contact-flow-action-${index}`}>
        <div className='action-editor-config-header'>
          {displayContentToggle && (
            <Checkbox
              checked={showContent}
              label={t(getCheckboxTradParams(action?.type))}
              onChange={onChangeShowContent}
              className='action-template-content-checkbox'
            />
          )}
          {action?.type === 'manual' && user?.isAdmin && (
            <div>
              <GenericDropdown
                className='sequence-subheader-more-actions'
                position='right'
                Trigger={({ onClick }) => (
                  <div onClick={onClick}>
                    <Dots />
                  </div>
                )}
              >
                <DropdownMenuPanel
                  options={[
                    {
                      id: 'show-meta-task-modal',
                      label: t('reveal.modals.metaTaskModal.trigger'),
                    },
                  ]}
                  onSelect={(id) => {
                    if (id === 'show-meta-task-modal') {
                      setShowMetaTaskFormModal(true);
                    }
                  }}
                />
              </GenericDropdown>
            </div>
          )}
        </div>
        {action?.type === TASK_TYPES.LINKEDIN_CHECK_REQUEST && (
          <div className='conditional-rules-container'>
            <Form.Field>
              <LabeledCheckbox
                checked={
                  _.findWhere(action.metadata, {
                    key: 'false:should-delete-next-linkedin-message',
                  })?.value === 'true'
                }
                label={t('sequences.removeLinkedinMessagesIfRefused')}
                onClick={() =>
                  handleMetadataUpdate({
                    key: 'false:should-delete-next-linkedin-message',
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <LabeledCheckbox
                checked={
                  _.findWhere(action.metadata, {
                    key: 'true:should-delete-next-linkedin-inmail',
                  })?.value === 'true'
                }
                label={t('sequences.removeLinkedinInmailMessagesIfAccepted')}
                onClick={() =>
                  handleMetadataUpdate({
                    key: 'true:should-delete-next-linkedin-inmail',
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <LabeledCheckbox
                checked={
                  _.findWhere(action.metadata, {
                    key: 'true:should-replace-next-inmail-subtypes-by-linkedin-message',
                  })?.value === 'true'
                }
                label={t('sequences.transformToLinkedinMessagesIfAccepted')}
                onClick={() =>
                  handleMetadataUpdate({
                    key: 'true:should-replace-next-inmail-subtypes-by-linkedin-message',
                  })
                }
              />
            </Form.Field>
          </div>
        )}
        {shouldDisplayContent && (
          <>
            {_.contains([...TYPES_WITH_MESSAGE, 'custom'], action?.type) && (
              <div className='action-template-editor'>
                <div className='template-selector' />
              </div>
            )}

            {_.contains(TYPES_WITH_DESCRIPTION, action?.type) && (
              <div className='description-container'>
                <CustomTextArea
                  key={action?.type} // TODO: hack to reload state on type change
                  label='description'
                  currentValue={getTranslatedText(
                    action?.description || action?.message?.body,
                  )}
                  onChange={onChangeDescription}
                  placeholderText='Description (optional)'
                  withCharacterCount
                />
              </div>
            )}
            <div className='action-editor-template'>
              {_.contains([...TYPES_WITH_MESSAGE, 'custom'], action?.type) && (
                <MergeTagsAutomationEditor
                  version={version}
                  key={action?.actionId + selectedTemplateId} // Reset editor when selected template changes
                  clientId={clientId}
                  fields={shouldDisplaySubject ? 'double' : 'simple'}
                  snippets={sequenceSnippets}
                  mergeTagsSubId='sequence'
                  onChange={onChangeBody}
                  onChangeSubject={onChangeSubject}
                  onSnippetAdded={onSnippetAdded}
                  onSnippetFragmentInserted={onSnippetFragmentInserted}
                  onSnippetUpdated={onSnippetUpdated}
                  defaultValue={body}
                  defaultValueSubject={subject}
                  placeholder={t('editor.placeholder.emailBody')}
                  placeholderSubject={t(
                    'editor.placeholder.emailSubjectOptional',
                  )}
                  action={action}
                  displayImageInsertion={isEmailAction(action)}
                  onNavigate={onQuitContactFlowEditor}
                  signature={
                    _.contains(TYPES_WITH_SIGNATURE, action?.type)
                      ? sender?.signature ||
                        (lastSoboSenderIdBefore &&
                          _.findWhere(userSendersData?.user?.senders || [], {
                            id: lastSoboSenderIdBefore,
                          })?.signature) ||
                        signature
                      : null
                  }
                  alwaysShowMenu
                  applyTemplateDropdown={
                    <Dropdown
                      className='template-selector-dropdown'
                      upward
                      item
                      scrolling
                      selectOnBlur={false}
                      placeholder={t('offers.defaultEmails.chooseTemplate')}
                      options={templateOptions}
                      value={selectedTemplateId}
                      onChange={(e, { value }) =>
                        onUpdateActionTemplate({ templateId: value })
                      }
                      text={t('editor.applyTemplate')}
                    />
                  }
                />
              )}
            </div>
          </>
        )}
      </div>
      {generateSequenceOpen && (
        <GenerateSequence
          clientId={clientId}
          action={action}
          actions={actions}
          sequenceId={sequenceId}
          onSubmit={handleSequenceGenerated}
          open={generateSequenceOpen}
          onChangeOpen={setGenerateSequenceOpen}
        />
      )}
      <CreateMetaTaskFormModal
        task={action}
        open={showMetaTaskFormModal}
        setOpen={setShowMetaTaskFormModal}
        metaTaskFormat={
          action?.format || {
            helpText: {
              default: t('reveal.modals.metaTaskModal.orderPlaceholder'),
            },
            form: {
              id: getRandomString(6),
              fields: [
                {
                  title: {
                    default: t('reveal.modals.metaTaskModal.formPlaceholder1'),
                  },
                  type: 'inline-text',
                  id: getRandomString(6),
                },
                {
                  id: getRandomString(6),
                  type: 'select',
                  title: {
                    default: t('reveal.modals.metaTaskModal.formPlaceholder2'),
                  },
                  options: [
                    {
                      id: getRandomString(6),
                      title: {
                        default: 'Option 1',
                      },
                    },
                    {
                      id: getRandomString(6),
                      title: {
                        default: 'Option 2',
                      },
                    },
                  ],
                },
              ],
            },
          }
        }
        onSave={(format) => {
          onUpdate({
            action: {
              ...action,
              format: {
                ...action.format,
                ...format,
                form: {
                  fields: sanitizeTypename(format.form?.fields),
                },
              },
            },
          });
        }}
      />
    </Segment>
  );
};

export default withTranslation('translations')(FullContactFlowActionEditor);
