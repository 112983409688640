import React, { FC, useMemo, useState, useCallback } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';

import useCampaignsDailyCohorts from '@/graphql/hooks/campaigns/useCampaignsDailyCohorts';
import useClientId from '@/hooks/router/useClientId';
import {
  isConvertibleCampaignEventType,
  statsCampaignEvents,
  StatsCampaignEventType,
} from '@/types/statistics/campaigns';
import useMiniCampaigns from '@/graphql/hooks/campaigns/useMiniCampaigns';
import useLocalShowMore from '@/hooks/ui/useLocalShowMore';
import { ShowMoreRow } from '@/components/Tables/ShowMore';
import { percentageToString } from '@/common/utils/number';
import useCampaignsDailyCohortsDetails from '@/graphql/hooks/campaigns/useCampaignsDailyCohortsDetails';
import GenericModal from '@/components/Common/GenericModal';
import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';
import InfoTooltip from '@/components/Common/InfoTooltip';
import EllipsisMenu from '@/components/EllipsisMenu';
import GenericFunnelRow from '../../../components/GenericFunnelRow';
import CampaignFunnelSummary from './CampaignFunnelSummary';
import { TimeSpan } from '../../../components/RevealAnalyticsHeader/RevealAnalyticsHeader';
import { filterEventsByDateRange } from '../../../stats.helpers';
import StatsProfileAndCampaignTable from '../../../components/StatsProfileAndCampaignTable';
import useFunnelDetails from './hooks/useFunnelDetails';
import {
  aggregateCampaignStats,
  getCampaignStats,
  getConversionRateNumbers,
} from './helpers';
import DetailsModalHeader from '../../../components/DetailsModalHeader';

import styles from './CampaignFunnels.module.less';

interface CampaignFunnelsProps {
  timeSpan: TimeSpan;
}

const CampaignFunnels: FC<CampaignFunnelsProps> = ({ timeSpan }) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { dailyCohorts = [] } = useCampaignsDailyCohorts({ clientId });
  const { miniCampaigns = [] } = useMiniCampaigns({ clientId });
  const [
    fetchDetails,
    { dailyCohorts: dailyCohortsDetails = [], loading: detailsLoading },
  ] = useCampaignsDailyCohortsDetails();
  const [modalOpen, setModalOpen] = useState(false);
  const [countArchived, setCountArchived] = useState(false);

  const dailyCohortsInDateRange = filterEventsByDateRange(
    timeSpan,
    dailyCohorts,
  );

  const dailyCohortsDetailsInDateRange = filterEventsByDateRange(
    timeSpan,
    dailyCohortsDetails,
  );

  const { filter, applyFilter, profileDetails } = useFunnelDetails(
    dailyCohortsDetailsInDateRange,
    ({ campaignIds }) => fetchDetails({ clientId, campaignIds }),
  );

  const globalClickListeners = useMemo(() => {
    if (!fetchDetails) {
      return undefined;
    }

    return _.reduce(
      statsCampaignEvents,
      (listeners, segment) => ({
        ...listeners,
        [segment]: () => {
          setModalOpen(true);
          applyFilter({ segment });
        },
      }),
      {} as Record<StatsCampaignEventType, () => void>,
    );
  }, [fetchDetails, applyFilter]);

  const campaignStats = useMemo(
    () => getCampaignStats(dailyCohortsInDateRange),
    [dailyCohortsInDateRange],
  );

  const countedMiniCampaigns = useMemo(
    () =>
      countArchived
        ? miniCampaigns
        : _.reject(miniCampaigns, ({ isArchived }) => isArchived),
    [countArchived, miniCampaigns],
  );

  const filteredMiniCampaigns = useMemo(
    () =>
      _.filter(
        countedMiniCampaigns,
        ({ id }) => campaignStats[id] !== undefined,
      ),
    [countedMiniCampaigns, campaignStats],
  );

  const countedCampaignStats = useMemo(() => {
    const result: typeof campaignStats = {};

    _.each(filteredMiniCampaigns, ({ id }) => {
      result[id] = campaignStats[id];
    });

    return result;
  }, [filteredMiniCampaigns, campaignStats]);

  const [displayableMiniCampaigns, showMoreRowProps] = useLocalShowMore({
    items: filteredMiniCampaigns,
    defaultDisplayLimit: 10,
  });

  const modalTitle = useMemo(() => {
    const titleParts = [] as string[];
    if (filter?.campaignId) {
      const campaignTitle = _.findWhere(miniCampaigns, {
        id: filter?.campaignId,
      })?.title;
      if (campaignTitle) {
        titleParts.push(campaignTitle);
      }
    }
    if (filter?.segment) {
      titleParts.push(t(`reveal.reports.marketing.state.${filter.segment}`));
    }
    return titleParts.join(' - ');
  }, [filter, miniCampaigns, t]);

  const handleExportData = useCallback(() => {
    const aggregated = aggregateCampaignStats(countedCampaignStats);
    const csvData = [
      [
        t('reveal.reports.marketing.campaign'),
        t('reveal.reports.marketing.state.nbEnrolled'),
        t('reveal.reports.marketing.state.nbContacted'),
        t('reveal.reports.marketing.state.nbOpened'),
        t('reveal.reports.marketing.state.nbClicked'),
        t('reveal.reports.marketing.state.nbUnsubscribe'),
      ],
      ['Total', ..._.map(statsCampaignEvents, (key) => aggregated[key])],
      [],
      [
        ..._.map(displayableMiniCampaigns, ({ id, title }) => {
          const counts = campaignStats[id];
          return [
            `"${title}"`,
            ..._.map(statsCampaignEvents, (key) => counts?.[key]),
          ];
        }),
      ],
    ];
    const csvContent = `${encodeURI('data:text/csv;charset=utf-8,')}${encodeURIComponent(
      csvData.map((row) => row.join(',')).join('\r\n'),
    )}`;
    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute(
      'download',
      `pipeline_statistics_by_compaign_${timeSpan.startDate.format(
        'YYYY-MM-DD',
      )}_${timeSpan.endDate.format('YYYY-MM-DD')}.csv`,
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [
    countedCampaignStats,
    campaignStats,
    displayableMiniCampaigns,
    t,
    timeSpan,
  ]);

  return (
    <div className={styles.campaignFunnels}>
      <div className={styles.top}>
        <h3>
          {t('reveal.reports.marketing.campaignFunnels')}
          <InfoTooltip rich hoverable position='right center'>
            <h1>
              {t('reveal.reports.tooltips.marketing.campaignFunnels.title')}
            </h1>
            <h2>
              {t('reveal.reports.tooltips.common.pipeline.passthroughRates')}
            </h2>
            <p>
              {t(
                'reveal.reports.tooltips.common.pipeline.passthroughRatesParagraph',
              )}
            </p>
            <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
            <p>
              {t(
                'reveal.reports.tooltips.marketing.campaignFunnels.timeSpanParagraph',
              )}
            </p>
            <p>
              {t(
                'reveal.reports.tooltips.marketing.campaignFunnels.detailsParagraph',
              )}
            </p>
          </InfoTooltip>
        </h3>
        <div className={styles.topRight}>
          <LabeledCheckbox
            checked={countArchived}
            onClick={() => setCountArchived(!countArchived)}
            label={t('campaigns.funnel.countArchived')}
          />
          <EllipsisMenu
            direction='left'
            items={[
              {
                key: 'export',
                title: t('reveal.missions.pipeline.exportAsCSV'),
                onClick: handleExportData,
              },
            ]}
          />
        </div>
      </div>
      <CampaignFunnelSummary
        data={aggregateCampaignStats(countedCampaignStats)}
        clickListeners={globalClickListeners}
      />
      <div className={styles.tableHeader}>
        <div className={styles.name}>
          {t('reveal.reports.marketing.campaign')}
        </div>
        {_.map(statsCampaignEvents, (key) => (
          <div
            className={classNames(styles.headerStage, {
              [styles.centered]: key === 'nbUnsubscribe',
              [styles.wider]: isConvertibleCampaignEventType(key),
            })}
            key={key}
          >
            {t(`reveal.reports.marketing.state.${key}`)}
          </div>
        ))}
      </div>
      {_.map(displayableMiniCampaigns, ({ id, title }) => {
        const counts = campaignStats[id];

        const conversionRates = getConversionRateNumbers(counts);

        const segments = _.map(statsCampaignEvents, (key) => ({
          key,
          name: t(`reveal.reports.marketing.state.${key}`),
          count: counts[key],
          conversionRate: isConvertibleCampaignEventType(key)
            ? percentageToString(t)(conversionRates[key])
            : undefined,
          clickListener: () => {
            setModalOpen(true);
            applyFilter({ campaignId: id, segment: key });
          },
          classNames: { global: styles[key], count: styles.count },
        }));

        const row = {
          title,
          link: `/client/${clientId}/reveal/campaigns/${id}`,
        };

        return <GenericFunnelRow key={id} segments={segments} row={row} />;
      })}
      <ShowMoreRow {...showMoreRowProps} />
      {detailsLoading ? (
        <Dimmer active>
          <Loader size='large' />
        </Dimmer>
      ) : (
        <GenericModal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Content>
            <DetailsModalHeader>
              <h1>{modalTitle}</h1>
            </DetailsModalHeader>
            <StatsProfileAndCampaignTable profileDetails={profileDetails} />
          </Modal.Content>
        </GenericModal>
      )}
    </div>
  );
};

export default CampaignFunnels;
