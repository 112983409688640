import gql from 'graphql-tag';

const UserNotificationsSettings = gql`
  fragment UserNotificationsSettings on User {
    id
    email
    emailNotificationsSettings {
      newFeatures
      discover
    }
  }
`;

export default UserNotificationsSettings;
