import React, { useEffect } from 'react';
import moment from 'moment';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import withClientStatistics from '../../../../../hocs/clients/withClientStatistics';
import CohortsGraph from './CohortsGraph';
import {
  aggregateCohorts,
  addTimestamp,
  sortByDay,
  fillEmptyValues,
  generateEmptyDailyCohorts,
} from '../../utils';

const CohortsDataContainer = ({
  statistics,
  statisticsLoading,
  frequency,
  setFrequency,
  t,
}) => {
  let { dailyCohorts } = (statistics || {}).offers || {};
  if (_.isEmpty(dailyCohorts)) {
    dailyCohorts = generateEmptyDailyCohorts({ nDays: 9 });
  }

  let cohortsData = aggregateCohorts({ events: dailyCohorts, frequency });
  cohortsData = sortByDay({ events: cohortsData });
  cohortsData = fillEmptyValues({ sortedEvents: cohortsData, frequency });
  cohortsData = addTimestamp({ events: cohortsData });

  useEffect(() => {
    // Compute  default value before computing first render
    if (frequency === null) {
      const fiveWeeksAgo = moment()
        .startOf('day')
        .subtract(5, 'weeks');
      if (!_.isEmpty(cohortsData) && cohortsData[0] <= fiveWeeksAgo) {
        setFrequency({ frequency: 'day' });
      } else {
        setFrequency({ frequency: 'week' });
      }
    }
  }, [frequency, cohortsData, setFrequency]);

  return (
    <CohortsGraph
      cohortsData={cohortsData}
      frequency={frequency}
      isLoading={statisticsLoading}
      t={t}
    />
  );
};

export default compose(
  withClientStatistics,
  withTranslation('translations'),
)(CohortsDataContainer);
