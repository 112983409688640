import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Segment, Checkbox, Button } from 'semantic-ui-react';

import { getTranslatedText } from '../../../common';
import routerParamsToProps from '../../../hocs/routerParamsToProps';
import { SEQUENCE_STATES } from '../../../common/constants/taskTypes';

import LaserFocusModal from './LaserFocusModal';
import TaskCard from './TaskCard';
import TaskRow from './TaskRow';

import './TodosContent.css';

const TodosContent = ({ todosTabId, tasks, selectedTodos, clientId, loading }) => {
  const [selectedTabId, setSelectedTabId] = useState(SEQUENCE_STATES.PENDING);
  const [viewMode, setViewMode] = useViewModeState('card');

  const tasksFilteredByState = _.filter(tasks, ({ state }) => state === selectedTabId);
  const tasksIds = _.pluck(tasksFilteredByState, 'id');

  const todosTitle = getTodosTitle({ todos: selectedTodos });

  return (
    <Segment className='todos-content'>
      <div className='todos-header-container'>
        <div
          className={`${selectedTabId === SEQUENCE_STATES.PENDING ? 'active' : ''} tab`}
          onClick={() => setSelectedTabId(SEQUENCE_STATES.PENDING)}
        >
          Pending tasks
        </div>
        {todosTabId !== 'applications' && (
          <div
            className={`${selectedTabId === SEQUENCE_STATES.IN_PROGRESS ? 'active' : ''} tab`}
            onClick={() => setSelectedTabId(SEQUENCE_STATES.IN_PROGRESS)}
          >
            In progress tasks
          </div>
        )}
        <div
          className={`${selectedTabId === SEQUENCE_STATES.COMPLETED ? 'active' : ''} tab`}
          onClick={() => setSelectedTabId(SEQUENCE_STATES.COMPLETED)}
        >
          Completed tasks
        </div>
        <div className='hs-flex-spacer' />
        <Button.Group className='view-mode-buttons'>
          <Button onClick={() => setViewMode('card')} active={viewMode === 'card'}>
            <i className='ri-layout-grid-fill' />
          </Button>
          <Button onClick={() => setViewMode('row')} active={viewMode === 'row'}>
            <i className='ri-menu-line' />
          </Button>
        </Button.Group>
      </div>

      <Tasks clientId={clientId} tasks={tasksFilteredByState} viewMode={viewMode} />
      {!loading && <LaserFocusModal title={todosTitle} clientId={clientId} tasksIds={tasksIds} tasks={tasks} />}
    </Segment>
  );
};

const Tasks = ({ clientId, tasks, viewMode }) => {
  if (_.isEmpty(tasks)) {
    return (
      <div className='task-cards'>
        <div className='task-cards-empty'>
          <img src='/images/placeholders/jobs.svg' alt='empty tasks placeholder' />
          <div className='no-tasks-text headline-4'>Your tasks will be displayed here</div>
        </div>
      </div>
    );
  }

  if (viewMode === 'row') {
    return (
      <div>
        <div className='task-row header'>
          <div className='cell header-cell checkbox'>
            <Checkbox disabled />
          </div>
          <div className='cell header-cell todo-title'>To-do</div>
          <div className='cell header-cell profile'>Associated to</div>
          <div className='cell header-cell owner'>Owner</div>
          <div className='cell header-cell status'>Status</div>
          <div className='cell header-cell date'>Due date</div>
        </div>
        {_.map(tasks, (task) => {
          return <TaskRow clientId={clientId} key={task?.id} task={task} />;
        })}
      </div>
    );
  }

  return (
    <div className='task-cards'>
      {_.map(tasks, (task) => {
        return <TaskCard key={task?.id} task={task} />;
      })}
    </div>
  );
};

const getTodosTitle = ({ todos }) => {
  if (_.isEmpty(todos)) {
    return null;
  }
  return _.map(todos, ({ title }) => getTranslatedText(title) || '').join(', ');
};

const useViewModeState = (defaultState) => {
  const [viewMode, setViewMode] = useState(localStorage.getItem('tasks-view-mode') || defaultState || 'card');
  const setStateInLocalStorage = (state) => {
    if (state !== viewMode) {
      setViewMode(state);
      localStorage.setItem('tasks-view-mode', state);
    }
  };
  return [viewMode, setStateInLocalStorage];
};

export default compose(withTranslation('translations'), withRouter, routerParamsToProps)(TodosContent);
