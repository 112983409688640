import _ from 'underscore';
import React from 'react';
import RecursiveSelector from '../components/RecursiveSelector';

const WorkableApplicationStatusesFilter = ({
  onUpdate,
  applicationStatuses,
  applicationStatusOptions,
}) => {
  const filter = getFilterFromApplicationStatuses({
    applicationStatuses,
  });
  return (
    <RecursiveSelector
      folderedOptions={applicationStatusOptions}
      filter={filter}
      onUpdateFilter={({ filter: newFilter }) => {
        onUpdate({
          applicationStatuses: getApplicationStatusesFromFilter({
            filter: newFilter,
          }),
        });
      }}
    />
  );
};

const getFilterFromApplicationStatuses = ({ applicationStatuses }) => {
  const filter = {};
  _.each(applicationStatuses, (applicationStatusItem) => {
    filter[applicationStatusItem.status] = {};
  });
  return filter;
};

const getApplicationStatusesFromFilter = ({ filter }) => {
  const applicationStatuses = [];
  _.each(filter, (subFilter, status) => {
    if (!subFilter) {
      return;
    }
    applicationStatuses.push({ status });
  });
  return applicationStatuses;
};

export default WorkableApplicationStatusesFilter;
