import React from 'react';
import classNames from 'classnames';
import _ from 'underscore';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ClientCustomAction } from '@/types/customAction';
import { ClientCustomActivity } from '@/graphql/hooks/clients/useClientCustomActivities';
import TranslatableText from '@/components/TranslatableText';
import GenericButton from '@/components/Common/GenericButton';
import Delete from '@/components/Reveal/Icons/Delete';
import { CustomIcon } from '@/components/Common/GenericIconSelector/IconDropdown/IconDropdown';

import { CustomIconDescriptor } from '@/components/Common/GenericIconSelector/types';
import styles from './CustomTasksTable.module.less';

interface Props {
  tasks: ClientCustomAction[] | ClientCustomActivity[] | undefined;
  type: 'tasks' | 'activities';
  onEdit: ({ taskId }: { taskId: string }) => void;
  onDelete: ({ taskId }: { taskId: string }) => void;
}

const CustomTasksTable: React.FC<Props> = ({
  tasks,
  type = 'tasks',
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();
  if (_.isEmpty(tasks)) {
    return null;
  }

  const translationKey =
    type === 'tasks' ? 'customTasksSettings' : 'customActivitySettings';

  return (
    <Table basic className={styles.table}>
      <Table.Header className={styles.header}>
        <Table.Row>
          <Table.HeaderCell className={styles.th}>
            <span>{t(`reveal.parameters.${translationKey}.table.name`)}</span>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.th}>
            <span>{t(`reveal.parameters.${translationKey}.table.type`)}</span>
          </Table.HeaderCell>
          <Table.HeaderCell collapsing />
          <Table.HeaderCell collapsing />
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {_.map(tasks || [], (task) => (
          <Table.Row key={task.id} className={styles.row}>
            <Table.Cell textAlign='left' className={styles.name}>
              <span className={styles.taskIcon}>
                <CustomIcon icon={task.icon as CustomIconDescriptor} />
              </span>
              <span>
                <TranslatableText text={task.title} />
              </span>
            </Table.Cell>
            <Table.Cell textAlign='left' className={styles.subtype}>
              {type === 'tasks' ? (
                <span>
                  {t(`reveal.parameters.customTasksSettings.type.${task.type}`)}
                </span>
              ) : (
                <span>
                  {t(`reveal.timeline.sharedNote.subtypes.${task.type}`)}
                </span>
              )}
            </Table.Cell>

            <Table.Cell>
              <GenericButton
                primacy='tertiary'
                onClick={() => onEdit({ taskId: task.id })}
              >
                <i className={classNames('ri-pencil-fill', styles.icon)} />
              </GenericButton>
            </Table.Cell>
            <Table.Cell>
              <GenericButton
                primacy='tertiary'
                onClick={() => onDelete({ taskId: task.id })}
              >
                <Delete />
              </GenericButton>
              {/* <i
                className={classNames('ri-delete-bin-line ri-lg', styles.icon)}
                onClick={() => onDelete({ taskId: task.id })}
              /> */}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default CustomTasksTable;
