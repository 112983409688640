import React from 'react';
import { Link } from 'react-router-dom';
import { sentryCaptureException } from '../../../common';
import logAction from '../../../common/logAction';

import './TopHeaderDropdown.css';

const logTopHeaderClick = ({
  name,
  clientId,
}: {
  name: string;
  clientId: string;
}): void => {
  try {
    logAction({
      type: `click-${name}`,
      info: {
        from: 'top-header',
        clientId,
      },
    });
  } catch (error) {
    sentryCaptureException({ error });
  }
};

interface TopHeaderLinkProps {
  /**
   * route to navigate to
   */
  to: string;
  /**
   * the pageId of this link
   */
  pageId: string;
  active: boolean;
  clientId: string;
}
const TopHeaderLink: React.FC<TopHeaderLinkProps> = ({
  to,
  children,
  pageId,
  active,
  clientId,
}) => {
  return (
    <Link
      className={`navigation-item-link${active ? ' active' : ''}`}
      to={to}
      onClick={() => logTopHeaderClick({ name: pageId, clientId })}
    >
      {children}
    </Link>
  );
};

export default TopHeaderLink;
