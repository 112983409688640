import React, { FC, useMemo, useState, useCallback, useEffect } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import classNames from 'classnames';

import useClientId from '@/hooks/router/useClientId';
import { useUserAllowedDepartments } from '@/graphql/hooks/users/useUserAllowedDepartments';
import useMissionsWithFolderingAndCustomFields from '@/graphql/hooks/searchPoolJobs/useMissionsWithFolderingAndCustomFields';
import TreeDropdown, {
  generateSelectedItemsFromTopLevelSelection,
} from '@/routes/RevealView/Overview/TreeDropdown';
import MissionDropdownEmptyState from '@/revealComponents/ProfileProjectsTab/NewProfileMissionsManagement/EmptyState/MissionDropdownEmptyState';
import GenericDropdownTrigger from '@/components/Common/GenericDropdownTrigger';
import { useClientMissionCustomFields } from '@/graphql/hooks/clients/useClientMissionCustomFields';
import { getTranslatedText } from '@/common/helpers/translatableText';
import { getShortLanguage } from '@/common/utils/i18n';
import { TreeItemType } from '@/components/Common/SelectItemDropdown/SelectItemList';

import styles from './MissionFolderingSelector.module.less';

type MissionFolderingSelectorProps = {
  onMissionIds: (missionIds: string[]) => void;
  displayMissionDropdown?: boolean;
};

const MissionFolderingSelector: FC<MissionFolderingSelectorProps> = ({
  onMissionIds,
  displayMissionDropdown,
}) => {
  const { t, i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const {
    missionsWithFolderingAndCustomFields,
  } = useMissionsWithFolderingAndCustomFields();
  const { departments } = useUserAllowedDepartments();
  const [departmentsTree, setDepartmentsTree] = useState<TreeItemType>();
  const [profilVivier, setProfilVivier] = useState<string | undefined>();
  const clientId = useClientId();
  const { missionCustomFields } = useClientMissionCustomFields(clientId);

  const flattenDepartments = useMemo(
    () =>
      _.flatten(
        _.map(departments, (department) => {
          return [
            {
              ...department,
              type: 'department',
            },
            ..._.flatten(
              _.map(department?.sections || [], (section) => {
                return [
                  {
                    ...section,
                    type: 'section',
                  },
                  ..._.map(section?.subsections || [], (subsection) => ({
                    ...subsection,
                    type: 'subsection',
                  })),
                ];
              }),
            ),
          ];
        }),
      ),
    [departments],
  );

  const singleSubsection = useMemo(() => {
    const subsections = _.where(flattenDepartments, { type: 'subsection' });
    return _.size(subsections) <= 1;
  }, [flattenDepartments]);

  useEffect(() => {
    if (_.isEmpty(departmentsTree?.items) && !_.isEmpty(departments)) {
      setDepartmentsTree({
        id: 'root',
        items: _.map(departments, (department) => ({
          id: department.id,
          items: _.map(department.sections || [], (section) => ({
            id: section.id,
            items: _.map(section.subsections || [], (subsection) => ({
              id: subsection.id,
              content: subsection.title,
              searchableText: subsection.title,
              items: [],
              selected: false,
            })),
            content: section.title,
            searchableText: section.title,
            selected: false,
          })),
          content: department.title,
          searchableText: department.title,
          selected: false,
        })),
        content: '',
        searchableText: '',
        selected: false,
      });
    }
  }, [departments, departmentsTree]);

  const selectedNodeIds = useMemo(() => {
    if (!departmentsTree) {
      return [];
    }
    return generateSelectedItemsFromTopLevelSelection(
      departmentsTree,
      false,
      [],
    );
  }, [departmentsTree]);

  const profilVivierOptions = useMemo(() => {
    const profilVivierDefinition = _.findWhere(missionCustomFields, {
      id: 'profil-vivier',
    });

    if (profilVivierDefinition?.type !== 'enum') {
      return undefined;
    }

    const unsortedOptions = _.map(
      profilVivierDefinition.options,
      ({ id, title }) => ({
        value: id,
        text: getTranslatedText(lang, title),
      }),
    );

    const sortedOptions = _.sortBy(unsortedOptions, (option) => option.text);

    return sortedOptions;
  }, [missionCustomFields, lang]);

  const handleProfilVivier = useCallback(
    (_e: unknown, { value }: DropdownProps) => {
      if (!value) {
        setProfilVivier(undefined);
        return;
      }
      if (typeof value !== 'string') {
        return;
      }
      setProfilVivier(value);
    },
    [],
  );

  useEffect(() => {
    const selectedSubsections = _.filter(
      selectedNodeIds,
      (node) =>
        _.findWhere(flattenDepartments, { id: node })?.type === 'subsection',
    );

    const selectedSubsectionSet = _.reduce(
      selectedSubsections,
      (acc, id) => ({ ...acc, [id]: true }),
      {} as Record<string, boolean>,
    );

    const missionIdsInScope = _.chain(missionsWithFolderingAndCustomFields)
      .filter(
        _.isEmpty(selectedSubsectionSet)
          ? () => true
          : ({ foldering }) =>
              foldering?.subsection?.id &&
              selectedSubsectionSet[foldering.subsection.id],
      )
      .filter(
        profilVivier === undefined
          ? () => true
          : ({ customFields }) => {
              const profilVivierCustomField = _.findWhere(customFields || [], {
                clientCustomFieldId: 'profil-vivier',
              });
              if (profilVivierCustomField?.type !== 'enum') {
                return false;
              }
              return _.contains(profilVivierCustomField.selected, profilVivier);
            },
      )
      .map(({ id }) => id)
      .value();

    onMissionIds(_.isEmpty(missionIdsInScope) ? ['NONE'] : missionIdsInScope);
  }, [
    departmentsTree,
    flattenDepartments,
    missionsWithFolderingAndCustomFields,
    profilVivier,
    onMissionIds,
    selectedNodeIds,
  ]);

  return (
    <div className={styles.container}>
      {!singleSubsection && departmentsTree && (
        <TreeDropdown
          selectedNodeIds={selectedNodeIds}
          totalItemCount={departments.length || 0}
          treeGroup={{
            groupId: 'first-group',
            groupLabel: t('organization_custom.all_department_uppercase'),
            sortBy: [],
            filterBy: [],
            groupBy: [],
            currentFilterKey: null,
            currentSortKey: null,
            currentGroupKey: null,
            tree: departmentsTree,
          }}
          emptyState={<MissionDropdownEmptyState clientId={clientId} />}
          onUpdateTree={setDepartmentsTree}
          Trigger={({ onClick }) => (
            <button
              type='button'
              onClick={onClick}
              className={styles.departmentsDropdownTrigger}
            >
              <GenericDropdownTrigger
                currentValue={
                  _.findWhere(flattenDepartments, { id: selectedNodeIds[0] })
                    ?.title || ''
                }
                placeholder={
                  _.size(flattenDepartments) === _.size(selectedNodeIds) ||
                  _.isEmpty(selectedNodeIds)
                    ? t('organization_custom.all_sections')
                    : t('missions.search.triggerPlaceholder')
                }
                clearable={false}
                onClear={() => ({})}
                multiSelect
                selectedItems={_.pluck(
                  _.filter(flattenDepartments, (department) =>
                    _.includes(selectedNodeIds, department.id),
                  ),
                  'id',
                )}
                totalItemCount={_.size(flattenDepartments)}
              />
            </button>
          )}
          searchPlaceholder={t('reveal.analyticsView.agencyPlaceholder')}
          onGroupFiltered={() => {}}
          onGroupSorted={() => {}}
          onGroupGrouped={() => {}}
        />
      )}
      {displayMissionDropdown && (
        <Dropdown
          selection
          search
          clearable
          options={profilVivierOptions}
          value={profilVivier}
          className={classNames('hiresweet-rounded', styles.missionDropdown)}
          placeholder={t('reveal.todosView.filters.allProfilVivier')}
          onChange={handleProfilVivier}
          selectOnBlur={false}
        />
      )}
    </div>
  );
};

export default MissionFolderingSelector;
