import React, {
  Dispatch,
  FC,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import useLocalStorage from '@/hooks/storage/useLocalStorage';
import GenericButton from '@/components/Common/GenericButton';
import { Action, UnipileMessageActionExecution } from '@/types/action';
import SlimSelect from '@/components/Common/SlimSelect';

import useSharedMessagingAccounts from '@/graphql/hooks/clients/useSharedServiceAccount';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_MESSAGE_ACCOUNT_CONTACT_RELATION,
  GET_UNIPILE_RECONNECTION_URL,
} from '@/graphql/unipile';
import { Task } from '@/types/task';
import classnames from 'classnames';
import Alert from '@/components/Common/Alert/Alert';
import { getFullname } from '@/common/helpers/person';
import { SERVICE_FROM_SUBTYPE } from '@/containers/Profile/Contact/TimeLineItems/UnipileMessageItem/UnipileMessageItem';
import useClientId from '@/hooks/router/useClientId';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import EmailActionItem from '../EmailActionItem';

import styles from './WhatsappMessageForm.module.less';
import MarkTaskAsCompletedButton from '../MarkTaskAsCompletedButton';
import ActionEmailEditor, { ActionMessage } from '../ActionEmailEditor';

type WhatsappMessageFormProps = {
  profile: any;
  onSubmit: (task: any) => void;
  onExecution: (input: UnipileMessageActionExecution) => Promise<void>;
  message: string;
  snippets: unknown;
  completeTaskLoading: boolean;
  task: Task;
  action: Action;
  onOpenReconnectModal?: (value: boolean) => void;
  messageState: ActionMessage;
  setMessageState: Dispatch<SetStateAction<ActionMessage>>;
  isInlineEdition: boolean;
  onUpdateAction: ({
    actionId,
    updatedAction,
  }: {
    actionId: string;
    updatedAction: any;
  }) => void;
  onIsInlineEdition: (isInline: boolean) => void;
};

type WhatsappSenderConfig = null | {
  senderId: string;
};

const WhatsappMessageForm: FC<WhatsappMessageFormProps> = ({
  profile,
  onSubmit,
  onExecution,
  message,
  snippets,
  completeTaskLoading,
  task,
  action,
  onOpenReconnectModal,
  messageState,
  setMessageState,
  isInlineEdition,
  onUpdateAction,
  onIsInlineEdition,
}) => {
  const { t } = useTranslation();
  const { isMinimized } = useMinimizedView();
  const clientId = useClientId();
  const [pollingRate, setPollingRate] = useState(0);
  const [getUnipileReconnectionURL] = useMutation(GET_UNIPILE_RECONNECTION_URL);
  const { sharedMessagingAccounts } = useSharedMessagingAccounts({
    queryOptions: {
      pollInterval: pollingRate,
    },
  });

  const ref = useRef(null);

  const whatsappMessagingAccounts = useMemo(() => {
    return _.filter(
      sharedMessagingAccounts,
      (account) => account.type === 'unipile-whatsapp',
    );
  }, [sharedMessagingAccounts]);

  const [senderConfig, setSenderConfig] = useLocalStorage<WhatsappSenderConfig>(
    'revealWhatsappSender',
    null,
  );

  const profilePhoneNumber = profile?.resumeData?.phoneNumber;

  const selectedWhatsappSender = useMemo(
    () =>
      senderConfig
        ? _.findWhere(whatsappMessagingAccounts, { id: senderConfig.senderId })
        : whatsappMessagingAccounts[0],
    [whatsappMessagingAccounts, senderConfig],
  );

  const { data: isConnectedData } = useQuery(
    GET_MESSAGE_ACCOUNT_CONTACT_RELATION,
    {
      variables: {
        input: {
          sharedMessagingSenderId: selectedWhatsappSender?.id,
          messagingType: 'unipile-whatsapp',
          profileId: profile.id,
        },
      },
      skip: _.isEmpty(selectedWhatsappSender?.id),
    },
  );

  const isConnected =
    isConnectedData?.user?.getMessageAccountContactRelation?.status ===
    'connected';

  const handleSubmit = async () => {
    if (!selectedWhatsappSender || !profilePhoneNumber) {
      return;
    }

    await onExecution({
      to: profilePhoneNumber,
      isConnected,
      chatId: '',
      senderId: selectedWhatsappSender.id,
    });

    onSubmit(task);
  };

  const isMessagingAccountValid = useMemo(
    () => selectedWhatsappSender?.status === 'valid',
    [selectedWhatsappSender],
  );

  const checkClassNames = (classList: string[]) => {
    const classNames = Array.from(classList);
    if (_.isEmpty(classNames)) {
      return false;
    }
    return classNames.some(
      (className) =>
        className === 'item' ||
        className === 'icon' ||
        className === 'menu' ||
        className === 'search' ||
        className.includes('search') ||
        className === 'editor-container' ||
        className.includes('SequenceDynamicVariables') ||
        className.includes('ConditionsChaining') ||
        /action-item.*/.test(className),
    );
  };

  const handleClick = React.useCallback(
    (event: MouseEvent) => {
      if (!ref || !ref.current) {
        return;
      }
      const { clientX, clientY } = event;
      const target = event.target as any;

      // TODO: Refacto this code and change the behavior to close the inline edition
      // The current solution is not easily extensible
      if (
        target &&
        (checkClassNames(target.classList) ||
          target.localName === 'textarea' ||
          target.localName === 'label')
      ) {
        return;
      }
      const { top, bottom, right, left } = ref.current.getBoundingClientRect();
      if (
        clientY < top ||
        clientY > bottom ||
        clientX > right ||
        clientX < left
      ) {
        onIsInlineEdition(false);
      }
    },
    [onIsInlineEdition, ref],
  );

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [handleClick]);

  if (!profilePhoneNumber) {
    return (
      <>
        {!isConnected && (
          <Alert
            title={t('common.warning')}
            description={t('settings.automations.unipile.emptyPhoneNumber', {
              contact: getFullname({
                firstname: profile.resumeData?.firstname,
                lastname: profile.resumeData?.lastname,
              }),
            })}
            level='info'
          />
        )}
        {message && (
          <div className={styles.message} ref={ref}>
            <EmailActionItem
              message={message}
              instantiatedSnippets={snippets}
              showSubject={false}
              showClipboardAction={false}
            />
          </div>
        )}
        <div
          className={classnames(
            styles.markAsCompletedButton,
            'mark-completed-button',
          )}
        >
          <MarkTaskAsCompletedButton task={task} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.field}>
        <span className={styles.label}>
          {t('reveal.candidatesView.timeline.textMessageTask.from')}
        </span>
        <SlimSelect
          value={selectedWhatsappSender?.id || ''}
          options={_.map(whatsappMessagingAccounts, ({ name, id }) => ({
            id,
            label: name,
          }))}
          onValue={(senderId) => setSenderConfig({ senderId })}
        />
      </div>
      <div className={styles.field}>
        <span className={styles.label}>
          {t('reveal.candidatesView.timeline.textMessageTask.to')}
        </span>
        <span className={styles.phoneNumber}>{profilePhoneNumber}</span>
      </div>
      {isMessagingAccountValid && !profilePhoneNumber && (
        <Alert
          title={t('common.warning')}
          description={t('settings.automations.unipile.emptyPhoneNumber', {
            contact: getFullname({
              firstname: profile.resumeData?.firstname,
              lastname: profile.resumeData?.lastname,
            }),
          })}
          level='info'
        />
      )}
      {selectedWhatsappSender && !isMessagingAccountValid && (
        <Alert
          title={t('common.warning')}
          description={t(
            `settings.automations.unipile.unipileWarnings.accountDisconnected`,
            {
              accountType: SERVICE_FROM_SUBTYPE['unipile-whatsapp'],
              recruiter: selectedWhatsappSender?.name,
            },
          )}
          level='info'
          hasCta
        >
          <GenericButton
            onClick={() => {
              getUnipileReconnectionURL({
                variables: {
                  accountId: selectedWhatsappSender?.serviceAccountId,
                },
              }).then((response) => {
                const { url, expectedCode: _code } =
                  response?.data?.getUnipileReconnectionLink || {};
                window.open(url, '_blank');
                setPollingRate(2000);
              });
              if (onOpenReconnectModal) {
                onOpenReconnectModal(true);
              }
            }}
            size='small'
          >
            {t('settings.automations.unipile.reconnect')}
          </GenericButton>
        </Alert>
      )}
      {message && (
        <div className={styles.message} ref={ref}>
          {isInlineEdition ? (
            <ActionEmailEditor
              messageState={messageState}
              setMessageState={setMessageState}
              action={action}
              clientId={clientId}
              onUpdateAction={({ updatedAction }: any) => {
                onUpdateAction({
                  actionId: action.actionId,
                  updatedAction: {
                    ...action,
                    ...updatedAction,
                    message: {
                      ...action?.message,
                      ...updatedAction?.message,
                    },
                  },
                });
              }}
              hideMenu
              isInlineEdition
            />
          ) : (
            <div
              className={classnames(
                styles.editableBody,
                `${isMinimized ? 'minimized' : ''}`,
              )}
              onClick={() => onIsInlineEdition(true)}
            >
              <EmailActionItem
                message={message}
                instantiatedSnippets={snippets}
                showSubject={false}
                showClipboardAction={false}
              />
            </div>
          )}
        </div>
      )}
      <GenericButton
        disabled={completeTaskLoading || !isMessagingAccountValid}
        className={classnames(
          styles.submit,
          completeTaskLoading || !isMessagingAccountValid
            ? styles.disabled
            : '',
        )}
        onClick={handleSubmit}
      >
        {t('reveal.candidatesView.timeline.whatsappMessageTask.send')}
      </GenericButton>
    </>
  );
};

export default WhatsappMessageForm;
