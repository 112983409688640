import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LockHistoryContextProvider } from '@/context/LockHistoryContext';
import CampaignsList from './CampaignsList';
import CreateCampaignPage from './CreateCampaignPage';
import EditCampaignPage from './EditCampaignPage';
import CampaignView from './CampaignView';
import NewCampaignPreview from './NewCampaignPreview';

interface CampaignsParams {
  clientId: string;
}

const Campaigns: FC = () => {
  const { params, path } = useRouteMatch<CampaignsParams>();
  const { clientId } = params;

  return (
    <Switch>
      <Route exact path={`${path}/new-campaign-preview`}>
        <NewCampaignPreview clientId={clientId} />
      </Route>
      <Route
        exact
        path={`${path}/new-campaign-create`}
        render={() => (
          <LockHistoryContextProvider>
            <CreateCampaignPage clientId={clientId} />
          </LockHistoryContextProvider>
        )}
      />
      <Route
        exact
        path={`${path}/:campaignId/edit`}
        render={({ match }) => (
          <LockHistoryContextProvider>
            <EditCampaignPage
              clientId={clientId}
              campaignId={match.params.campaignId}
            />
          </LockHistoryContextProvider>
        )}
      />
      <Route path={`${path}/:campaignId`} component={CampaignView} />
      <Route exact path={`${path}`}>
        <CampaignsList clientId={clientId} />
      </Route>
    </Switch>
  );
};

export default Campaigns;
