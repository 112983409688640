import React from 'react';

export default function useModal<T = unknown>(
  initialOpen = false,
): {
  isOpen: boolean;
  open: (options?: { params?: T }) => void;
  close: () => void;
  params?: T;
} {
  const [modal, setModal] = React.useState<{
    open: boolean;
    params?: T;
  }>({ open: initialOpen });
  const open = (options: { params?: T } = {}) =>
    setModal({ ...options, open: true });
  const close = () => setModal({ open: false });

  return {
    isOpen: Boolean(modal.open),
    params: modal.params,
    open,
    close,
  };
}
