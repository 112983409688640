import React from 'react';
import { Popup } from 'semantic-ui-react';


import { formatDate } from './utils';


export default function Days({
  values, size, space, padX, padY, colorFunction, onClick, tooltipFormatter,
}) {
  return (
    <g>
      {values.map((v, i) => {
        const s = size + space * 2;
        const x = padX + i * s + space;
        const y0 = padY + space;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <g key={`g-day-${i}`}>
            {v.map((d) => (
              <Popup
                key={d.date}
                size='tiny'
                position='top center'
                inverted
                content={tooltipFormatter(d)}
                trigger={(
                  <rect
                    className='cg-day'
                    x={x}
                    y={d.day * s + y0}
                    width={size}
                    height={size}
                    fill={colorFunction(d)}
                    data-count={d.count}
                    data-date={formatDate(d.date)}
                    onClick={() => onClick(d)}
                  />
                )}
              />

            ))}
          </g>
        );
      })}
    </g>
  );
}
