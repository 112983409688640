import _ from 'underscore';
import { encodeProfileId } from '.';

export type Metadata = {
  profileId: string,
  messageId: string,
  trackingDomain: string,
};
export type LinksTrackingResult = {
  body: string,
  // links: Array<string>
};
const replaceLinksForTracking = (body: string, metadata: Metadata): LinksTrackingResult => {
  const el = document.createElement('html');
  el.innerHTML = body;
  const linksEl = el.getElementsByTagName('a');
  _.each(linksEl, (anchor) => {
    anchor.href = transformURL(anchor.href, metadata);
  });
  return { body: el.innerHTML };
};

const transformURL = (url: string, { profileId, trackingDomain, messageId }: Metadata): string => {
  return `${trackingDomain}/link/${encodeURIComponent(btoa(url))}?uid=${encodeProfileId(profileId)}&mid=${messageId}`;
};

export const replaceCalendlyLinksForTracking = (body: string, metadata: Metadata): LinksTrackingResult => {
  const el = document.createElement('html');
  el.innerHTML = body;
  const linksEl = el.getElementsByTagName('a');
  _.each(linksEl, (anchor) => {
    anchor.href = transformCalendlyURL(anchor.href, metadata);
  });
  return { body: el.innerHTML }; 
}

const transformCalendlyURL = (url: string, { profileId }: Metadata): string => {
  if ((url || '').indexOf('https://calendly.com/') !== 0  || _.contains(url, 'salesforce_uuid=')) {
    return url
  }
  if (_.contains(url, '?')) {
    return `${url}&salesforce_uuid=${encodeProfileId(profileId)}`;
  }
  return `${url}?salesforce_uuid=${encodeProfileId(profileId)}`;
};

export default replaceLinksForTracking;
