import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import ContactFlow from '../../graphql/fragments/ContactFlow';

export const removeActions = gql`
  mutation removeActions(
    $jobOfferId: ID
    $contactFlowId: ID!
    $sequenceId: ID!
    $actionIds: [ID!]!
  ) {
    removeActions(
      jobOfferId: $jobOfferId
      contactFlowId: $contactFlowId
      sequenceId: $sequenceId
      actionIds: $actionIds
    ) {
      ...ContactFlow
    }
  }
  ${ContactFlow}
`;

export default graphql(removeActions, {
  props: ({ ownProps, mutate }) => ({
    removeActions: ({ contactFlowId, sequenceId, actionIds }) =>
      mutate({
        variables: {
          jobOfferId: ownProps.offerId,
          contactFlowId,
          sequenceId,
          actionIds,
        },
      }),
  }),
});
