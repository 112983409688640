import React, { useEffect } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useTranslation } from 'react-i18next';
import { Button, Popup } from 'semantic-ui-react';
import {
  fixBodyAndHeader,
  releaseBodyAndHeader,
} from '@/routes/WatchCollectAllCandidates/helpers';
import { CrossIcon, DownIcon, UpIcon } from '../../../assets/icons';

interface ProfileDimmerProps {
  onSelectProfileId: any;
  previousProfileId: any;
  nextProfileId: any;
  navDirectionState?: any;
  onClose: any;
}

const ProfileDimmer: React.FC<ProfileDimmerProps> = ({
  onSelectProfileId,
  previousProfileId,
  nextProfileId,
  navDirectionState,
  onClose,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    fixBodyAndHeader();
    return releaseBodyAndHeader;
  }, []);

  const goToPrevProfile = () => {
    if (navDirectionState) {
      navDirectionState.set('up');
    }
    if (previousProfileId && onSelectProfileId) {
      onSelectProfileId(previousProfileId);
    }
  };

  const goToNextProfile = () => {
    if (navDirectionState) {
      navDirectionState.set('down');
    }
    if (nextProfileId && onSelectProfileId) {
      onSelectProfileId(nextProfileId);
    }
  };

  const keyMap = {
    CLOSE_MODAL: 'esc',
    PREVIOUS_PROFILE: 'up',
    NEXT_PROFILE: 'down',
  };

  const handlers = {
    CLOSE_MODAL: () => {
      onClose();
    },
    PREVIOUS_PROFILE: (event: any) => {
      if (event) {
        // no scrolling with arrows
        event.preventDefault();
      }
      goToPrevProfile();
    },
    NEXT_PROFILE: (event: any) => {
      if (event) {
        // no scrolling with arrows
        event.preventDefault();
      }
      goToNextProfile();
    },
  };

  const CloseButton = (
    <Button
      className='dimmer-button back-button'
      circular
      icon={<CrossIcon />}
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
    />
  );

  const UpArrowButton = (
    <Button
      className='dimmer-button'
      disabled={!previousProfileId}
      circular
      icon={<UpIcon />}
      onClick={(e) => {
        e.stopPropagation();
        goToPrevProfile();
      }}
    />
  );

  const DownArrowButton = (
    <Button
      className='dimmer-button'
      disabled={!nextProfileId}
      circular
      icon={<DownIcon />}
      onClick={(e) => {
        e.stopPropagation();
        goToNextProfile();
      }}
    />
  );

  return (
    <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges>
      <div className='dimmer' onClick={onClose}>
        <div className='actions-container'>
          <Popup
            trigger={CloseButton}
            size='tiny'
            position='bottom center'
            basic
            inverted
            flowing
            popperModifiers={[
              {
                // Fix a positioning problem
                name: 'preventOverflow',
                options: {
                  boundariesElement: 'offsetParent',
                },
              }
            ]}
          >
            {t('keymap.escToQuit')}
          </Popup>

          {onSelectProfileId && (
            <>
              <Popup
                trigger={UpArrowButton}
                size='tiny'
                position='top center'
                basic
                inverted
                flowing
                popperModifiers={[
                  {
                    // Fix a positioning problem
                    name: 'preventOverflow',
                    options: {
                      boundariesElement: 'offsetParent',
                    },
                  }
                ]}
              >
                {t('keymap.upArrowNavigation')}
              </Popup>

              <Popup
                trigger={DownArrowButton}
                size='tiny'
                position='bottom center'
                basic
                inverted
                flowing
                popperModifiers={[
                  {
                    // Fix a positioning problem
                    name: 'preventOverflow',
                    options: {
                      boundariesElement: 'offsetParent',
                    },
                  }
                ]}
              >
                {t('keymap.downArrowNavigation')}
              </Popup>
            </>
          )}
        </div>
      </div>
    </GlobalHotKeys>
  );
};

export default ProfileDimmer;
