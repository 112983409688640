import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const mutation = gql`
  mutation addTrialClientAndOAuthUser(
    $clientName: String!
    $userInput: UserSignupOAuthInput!
    $mode: String
    $clientId: String
    $token: String
  ) {
    addTrialClientAndOAuthUser(
      clientName: $clientName
      userInput: $userInput
      mode: $mode
      clientId: $clientId
      token: $token
    ) {
      id
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    addTrialClientAndOAuthUser: async ({ clientName, userInput, mode, clientId, token }) => {
      const variables = { 
        clientName, 
        userInput, 
        mode, 
        ...clientId && { clientId }, 
        ...token && { token } 
      };
      // errorPolicy: 'all' prevents mutate from raising GraphQL errors
      // (we handle error objects manually in component)
      return mutate({ variables, errorPolicy: 'all' });
    },
  }),
});
