import _ from 'underscore';
import React, { PropsWithChildren, useContext } from 'react';
import {
  HiresweetLibProvider,
  ProjectOwner,
  ProjectSetItemContainer,
  ProjectTitle,
  ProjectsSetContainer,
  ProjectsSetContext,
  ProjectsSubsetRegister,
} from '@hiresweet/hiresweet-lib';
import useClientId from '@/hooks/router/useClientId';

const ProjectsTable = ({ children }: PropsWithChildren<object>) => {
  const { projectIds } = useContext(ProjectsSetContext);
  return (
    <ProjectsSubsetRegister projectIds={projectIds}>
      <table border={1}>{children}</table>
    </ProjectsSubsetRegister>
  );
};

const ProjectsTableHeader = ({ children }: PropsWithChildren<object>) => {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
};

const ProjectsTableHeaderCell = ({ children }: PropsWithChildren<object>) => {
  return <th>{children}</th>;
};

const ProjectsTableBody = ({ children }: PropsWithChildren<object>) => {
  return <tbody>{children}</tbody>;
};

const ProjectsTableRowMap = ({ children }: PropsWithChildren<object>) => {
  const { projectIds } = useContext(ProjectsSetContext);
  return (
    <>
      {(projectIds || []).map((projectId) => (
        <tr key={projectId}>
          <ProjectSetItemContainer projectId={projectId}>
            {children}
          </ProjectSetItemContainer>
        </tr>
      ))}
    </>
  );
};

const ProjectsTableCell = ({ children }: PropsWithChildren<object>) => {
  return <td>{children}</td>;
};

const Playground7 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30 }}>
      <h1>P7 - Projects - Full table</h1>
      <div>
        <HiresweetLibProvider theme={theme} clientId={clientId}>
          <ProjectsSetContainer filter={{}}>
            <ProjectsTable>
              <ProjectsTableHeader>
                <ProjectsTableHeaderCell>Title</ProjectsTableHeaderCell>
                <ProjectsTableHeaderCell>Owner</ProjectsTableHeaderCell>
              </ProjectsTableHeader>

              <ProjectsTableBody>
                <ProjectsTableRowMap>
                  <ProjectsTableCell>
                    <ProjectTitle />
                  </ProjectsTableCell>
                  <ProjectsTableCell>
                    <ProjectOwner />
                  </ProjectsTableCell>
                </ProjectsTableRowMap>
              </ProjectsTableBody>
            </ProjectsTable>
          </ProjectsSetContainer>
        </HiresweetLibProvider>
      </div>
    </div>
  );
};

export default Playground7;
