import _ from 'underscore';
import React, { useMemo } from 'react';
import classnames from 'classnames';

import useResizableItemList from '@/hooks/common/useResizableItemList';
import TranslatableText from '@/components/TranslatableText';
import { getTranslatedText } from '@/common';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

import HoverSearchPoolProfileCard from './HoverSearchpoolProfileCard';
import './NameAndHeadline.css';

const getProfileCustomField = ({ field, index, displayedItemsNumber }) => {
  if (getTranslatedText(field.title).indexOf('CDII') < 0) {
    return null;
  }

  const [selectedValue] = field.selected || [];

  if (selectedValue && selectedValue.indexOf('non') > -1) {
    return null;
  }

  return (
    <div
      key={field.id}
      className={classnames(
        'cardCustomField',
        index >= displayedItemsNumber ? 'customFieldHidden' : '',
      )}
    >
      <div className='customFieldTitle'>
        <TranslatableText text={field.title} />
      </div>
      <div className='customFieldValue'>{field.readableValue}</div>
    </div>
  );
};

const NameAndHeadline = ({
  clientId,
  searchPoolId,
  profile,
  customFields = [],
}) => {
  const { firstname, lastname, headline, email } = profile?.resumeData || {};
  const { linkedin } = profile?.resumeData?.sources || {};
  const headlineText = ((headline || {}).content || {}).text;
  const fullname =
    (firstname || '') + (firstname && lastname ? ' ' : '') + (lastname || '');

  const configurationParams = useMergedConfigurationParams();

  const { containerRef, listRef, displayedItemsNumber, finalWidth } =
    useResizableItemList < HTMLDivElement > 0;

  const displayedCustomFields = useMemo(() => {
    return _.compact(
      _.map(customFields, (field, index) => {
        // Sadly this must be a function, otherwise "null" is treated as a component
        return getProfileCustomField({
          field,
          index,
          displayedItemsNumber,
        });
      }),
    );
  }, [customFields, displayedItemsNumber]);

  return (
    <div className='name-and-headline'>
      <div className='full-name headline-5'>
        <div className='full-name-text' data-openreplay-masked>
          {configurationParams?.shouldHideProfilePreview !== 'true' ? (
            <HoverSearchPoolProfileCard
              profileId={profile?.id}
              searchPoolId={searchPoolId}
              clientId={clientId}
            >
              {fullname}{' '}
              {email && <i className='ri-mail-line ri-xs full-name-icon' />}
              {linkedin && (
                <i className='ri-linkedin-box-fill ri-xs full-name-icon' />
              )}
            </HoverSearchPoolProfileCard>
          ) : (
            <>
              {fullname}{' '}
              {email && <i className='ri-mail-line ri-xs full-name-icon' />}
              {linkedin && (
                <i className='ri-linkedin-box-fill ri-xs full-name-icon' />
              )}
            </>
          )}
        </div>
      </div>
      <div className='headline'>
        {!_.isEmpty(displayedCustomFields) && (
          <div ref={containerRef} className='cardCustomFieldsContainer'>
            <div
              ref={listRef}
              className='cardCustomFieldsList'
              style={{ width: finalWidth, minWidth: '32px' }}
            >
              {displayedCustomFields}
            </div>
            {customFields.length - displayedItemsNumber > 0 && (
              <div className='remainingCustomFields'>
                +{customFields.length - displayedItemsNumber}
              </div>
            )}
          </div>
        )}
        <span className='headline-text'>{headlineText}</span>
      </div>
    </div>
  );
};

export default NameAndHeadline;
