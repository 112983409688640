import { DAY_IN_MS } from '@/common/dates';
import { Task } from '@/types/task';
import { useMemo } from 'react';
import _ from 'underscore';

export function useTaskCanSnooze(task: Task) {
  const canSnooze = useMemo(() => {
    // We validate === false because old tasks don't have
    // this value, and must be snoozable
    if (task?.snoozeConfiguration?.isSnoozable === false) {
      return false;
    }

    if (!task?.snoozeConfiguration) {
      return true;
    }

    const maxSnoozeDateTimestamp = new Date(
      task.snoozeConfiguration.maxSnoozeDate,
    ).getTime();

    if (maxSnoozeDateTimestamp <= Date.now() + DAY_IN_MS) {
      return false;
    }

    // we still don't know if there is a isSnoozable field, so we null-coalesce to true
    return task.snoozeConfiguration.isSnoozable ?? true;
  }, [task]);

  // TODO: add function to validate snooze date
  return {
    canSnooze,
  };
}

export const canSnoozeTaskForGivenTime = (maxSnoozeDate: string, timeInDays: number) => {
  if (_.isEmpty(maxSnoozeDate)) {
    return true;
  }
  const timeInMs = timeInDays * DAY_IN_MS;
  return Date.now() + timeInMs < new Date(maxSnoozeDate).getTime()
}
