import { useState } from 'react';

const useCopyToClipboard = () => {
  const [copied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    const copyFrom = document.createElement('textarea');

    copyFrom.textContent = text;

    document.body.appendChild(copyFrom);

    copyFrom.select();

    document.execCommand('copy');

    copyFrom.blur();

    document.body.removeChild(copyFrom);
    return Promise.resolve();
  }

  const handleCopy = (text) => {
    copyTextToClipboard(text)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return {
    copied,
    handleCopy,
  };
};

export default useCopyToClipboard;
