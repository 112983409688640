import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import GenderBySource from './GenderBySource';
import GenderRepartition from './GenderRepartition';
import GenderRepartitionByProject from './GenderRepartitionByProject';
import PipelineStatisticsByGender from './PipelineStatisticsByGender';
import GenderVolumeProgression from './GenderVolumeProgression';
import { TimeSpan } from '../../components/RevealAnalyticsHeader/RevealAnalyticsHeader';
import AnalyticsSubTabs from '../../components/AnalyticsSubTabs';

import styles from './DiversityTab.module.less';

const DIVERSITY_SUB_TABS = ['projects', 'contacts'] as const;

type DiversitySubTab = typeof DIVERSITY_SUB_TABS[number];

interface DiversityTabProps {
  clientId: string;
  missionIds: string[];
  timeSpan: TimeSpan;
}

const DiversityTab: FC<DiversityTabProps> = ({
  clientId,
  missionIds,
  timeSpan,
}) => {
  const { t } = useTranslation();
  const [activeSubTab, setActiveSubTab] = useState<DiversitySubTab>('projects');

  const subTabOptions = _.map(DIVERSITY_SUB_TABS, (id) => ({
    id,
    name: t(`reveal.reports.diversity.subTabs.${id}`),
  }));

  return (
    <>
      <AnalyticsSubTabs
        options={subTabOptions}
        onSelect={setActiveSubTab}
        activeId={activeSubTab}
      />
      <div className={styles.diversityAnalytics}>
        {activeSubTab === 'projects' && (
          <>
            <PipelineStatisticsByGender
              clientId={clientId}
              missionIds={missionIds}
              timeSpan={timeSpan}
            />
            <GenderRepartitionByProject
              missionIds={missionIds}
              timeSpan={timeSpan}
            />
          </>
        )}
        {activeSubTab === 'contacts' && (
          <>
            <div className={styles.grid}>
              <GenderRepartition />
              <GenderBySource />
            </div>
            <GenderVolumeProgression timeSpan={timeSpan} />
          </>
        )}
      </div>
    </>
  );
};

export default DiversityTab;
