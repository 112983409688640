import React, { useEffect, useRef, useState } from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Checkbox, Modal, Divider } from 'semantic-ui-react';

import LocationAndRemoteForm from '@/components/LocationAndRemoteForm';
import {
  locationsAndRemoteFromRaw,
  locationsAndRemoteToRaw,
} from '@/common/helpers/locationAndRemote';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import logAction from '../../../common/logAction';
import skillOptions from '../../../common/options/skillOptions.json';
import schoolTypeOptions from '../../../common/options/schoolTypeOptions.json';
import jobPositionOptions from '../../../common/options/jobPositionOptions.json';
import rawLocationOptions from '../../../common/options/locationOptions.json';
import LabelDetails from '../../../components/LabelDetails';
import withUserFromJWToken from '../../../hocs/users/withUserFromJWToken';
import CriterionSelector from '../SearchTopBar/CriterionSelector';
import { ExperienceYearsCriterion } from '../SearchTopBar/ExperienceYearsCriterionPopup';
import { SalaryCriterion } from '../SearchTopBar/SalaryCriterionPopup';
import { OR_OPERATOR } from './OperatorToggle';
import WatchCollectFilter from './WatchCollectFilter';

import './WatchCollectFiltersModal.css';

const DEFAULT_FILTERS = {
  contracts: [],
  excludeHidden: true,
  includeProfilesInJob: 'pending-only',
  shownInterestOnly: false,
  experienceYearsMax: '',
  experienceYearsMin: '',
  jobPositions: [],
  jobPositionsOperator: 'OR',
  locations: [],
  schoolTypes: [],
  remoteOnly: false,
  responsibilities: [],
  salaryMax: '',
  salaryMin: '',
  selectedCategory: 'all',
  skills: [],
  skillsOperator: 'OR',
  sortBy: 'relevancy',
};

export const RESPONSIBILITIES = ['lead', 'director', 'normal'];
export const filterHasNoEffect = ({ responsibilities }) => {
  if (_.isEmpty(responsibilities)) {
    return true;
  }
  if (responsibilities.length !== RESPONSIBILITIES.length) {
    return false;
  }
  return _.isEmpty(_.difference(RESPONSIBILITIES, responsibilities));
};
const useSectionRefs = () => {
  return {
    jobPositions: useRef(),
    skills: useRef(),
    experienceYears: useRef(),
    responsibilities: useRef(),
    salary: useRef(),
    locationsAndRemote: useRef(),
    schoolTypes: useRef(),
    excludeProfiles: useRef(),
    selectedCategory: useRef(),
  };
};

const locationOptions = _.map(rawLocationOptions, ({ value, text: label }) => ({
  value,
  label,
}));

const WatchCollectFiltersModal = ({
  open,
  onClose,
  clientId,
  user,
  filtersState,
  scrollToSection,
  t,
}) => {
  const [selectedItems, setSelectedItems] = useState(filtersState?.values);
  const sectionRefs = useSectionRefs();

  const onReset = () => setSelectedItems(DEFAULT_FILTERS);

  useEffect(() => {
    if (scrollToSection) {
      const ref = sectionRefs[scrollToSection];
      if (!ref?.current) {
        return;
      }
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line
  }, []);

  const author = `${user?.firstname} ${user?.lastname}`;

  const setSelectedItemsByKey = (key) => {
    return (value) =>
      setSelectedItems((items) => {
        return {
          ...(items || {}),
          [key]: value,
        };
      });
  };

  const onToggleResponsibility = (level) => {
    return () =>
      setSelectedItems((items) => {
        const oldResponsibilities = items?.responsibilities || [];
        let newResponsibilities;
        if (_.contains(oldResponsibilities, level)) {
          newResponsibilities = _.without(oldResponsibilities, level);
        } else {
          newResponsibilities = [...oldResponsibilities, level];
        }
        return {
          ...(items || {}),
          responsibilities: newResponsibilities,
        };
      });
  };

  useEffect(() => {
    setSelectedItems(filtersState?.values);
    // eslint-disable-next-line
  }, [filtersState?.values]);

  const onConfirm = () => {
    filtersState.resetSearchInputs({ criteria: selectedItems });
    onClose();

    logAction({
      type: 'discover-filters-modal-apply-button',
      info: { clientId, author },
    });
  };

  const onLocationsAndRemote = (value) => {
    setSelectedItemsByKey('locationsAndRemote')(locationsAndRemoteToRaw(value));
  };

  const locationsAndRemote = locationsAndRemoteFromRaw(
    selectedItems?.locationsAndRemote,
  );

  return (
    <GenericModal
      className='watch-collect-filters-modal'
      open={open}
      onClose={onClose}
    >
      <Modal.Header>
        <div>
          {t('watchCollect.filters.header')}
          <LabelDetails text={t('watchCollect.filters.description')} />
        </div>
      </Modal.Header>
      <Modal.Content>
        <div ref={sectionRefs.jobPositions}>
          <WatchCollectFilter
            className='job-positions-filter'
            title={t('watchCollect.filters.jobPositions.title')}
            operator={selectedItems?.jobPositionsOperator}
            setOperator={setSelectedItemsByKey('jobPositionsOperator')}
            operatorHelp={
              selectedItems?.skillsOperator === OR_OPERATOR
                ? t('watchCollect.filters.operators.jobPositionsOrExample')
                : t('watchCollect.filters.operators.jobPositionsAndExample')
            }
          >
            <CriterionSelector
              selectedItems={selectedItems?.jobPositions}
              setSelectedItems={setSelectedItemsByKey('jobPositions')}
              options={jobPositionOptions}
              placeholder={t('watchCollect.filters.jobPositions.placeholder')}
            />
          </WatchCollectFilter>
        </div>

        <Divider />

        <div ref={sectionRefs.skills}>
          <WatchCollectFilter
            className='skills-filter'
            title={t('watchCollect.filters.skills.title')}
            operator={selectedItems?.skillsOperator}
            setOperator={setSelectedItemsByKey('skillsOperator')}
            operatorHelp={
              selectedItems?.skillsOperator === OR_OPERATOR
                ? t('watchCollect.filters.operators.skillsOrExample')
                : t('watchCollect.filters.operators.skillsAndExample')
            }
          >
            <CriterionSelector
              selectedItems={selectedItems?.skills}
              setSelectedItems={setSelectedItemsByKey('skills')}
              options={skillOptions}
              placeholder={t('watchCollect.filters.skills.placeholder')}
            />
          </WatchCollectFilter>
        </div>

        <Divider />

        <div ref={sectionRefs.experienceYears}>
          <WatchCollectFilter
            className='experience-filter half-size'
            title={t('watchCollect.filters.experienceYears.title')}
          >
            <ExperienceYearsCriterion
              experienceYearsMin={selectedItems?.experienceYearsMin}
              experienceYearsMax={selectedItems?.experienceYearsMax}
              setExperienceYearsMin={setSelectedItemsByKey(
                'experienceYearsMin',
              )}
              setExperienceYearsMax={setSelectedItemsByKey(
                'experienceYearsMax',
              )}
              t={t}
            />
          </WatchCollectFilter>
        </div>

        <Divider />

        <div ref={sectionRefs.responsibilities}>
          <WatchCollectFilter
            className='responsibilities-filter'
            title={t('watchCollect.filters.responsibilities.title')}
          >
            {_.map(RESPONSIBILITIES, (level) => {
              return (
                <div key={level}>
                  <Checkbox
                    checked={_.contains(selectedItems.responsibilities, level)}
                    label={t(
                      `watchCollect.filters.responsibilities.toggleLabels.${level}`,
                    )}
                    onChange={onToggleResponsibility(level)}
                  />
                </div>
              );
            })}
          </WatchCollectFilter>
        </div>

        <Divider />

        <div ref={sectionRefs.salary}>
          <WatchCollectFilter
            className='salary-filter half-size'
            title={t('watchCollect.filters.salary.title')}
          >
            <SalaryCriterion
              salaryMin={selectedItems?.salaryMin}
              salaryMax={selectedItems?.salaryMax}
              setSalaryMin={setSelectedItemsByKey('salaryMin')}
              setSalaryMax={setSelectedItemsByKey('salaryMax')}
              t={t}
            />
          </WatchCollectFilter>
        </div>

        <Divider />

        <div ref={sectionRefs.locationsAndRemote}>
          <WatchCollectFilter
            className='locations-filter'
            title={t('watchCollect.filters.locations.title')}
          >
            <LocationAndRemoteForm
              locationOptions={locationOptions}
              value={locationsAndRemote}
              onChange={onLocationsAndRemote}
            />
          </WatchCollectFilter>
        </div>

        <Divider />

        <div ref={sectionRefs.schoolTypes}>
          <WatchCollectFilter
            className='schooltype-filter'
            title={t('watchCollect.filters.schoolTypes.title')}
          >
            <CriterionSelector
              onlyCheckboxes
              selectedItems={selectedItems?.schoolTypes}
              setSelectedItems={setSelectedItemsByKey('schoolTypes')}
              options={_.map(schoolTypeOptions, (schoolTypeOption) => ({
                ...schoolTypeOption,
                text: t(
                  `watchCollect.filters.schoolTypes.options.${schoolTypeOption.value}`,
                ),
              }))}
              placeholder={t('watchCollect.filters.schoolTypes.placeholder')}
            />
          </WatchCollectFilter>
        </div>

        <Divider />

        <div ref={sectionRefs.excludeProfiles}>
          <WatchCollectFilter
            className='profiles-filter'
            title={t('watchCollect.filters.profiles.title')}
          >
            <div>
              <Checkbox
                label={t('watchCollect.filters.hiddenProfiles.label')}
                checked={!selectedItems.excludeHidden}
                onChange={() =>
                  setSelectedItemsByKey('excludeHidden')(
                    !selectedItems.excludeHidden,
                  )
                }
              />
            </div>
            <div>
              <Checkbox
                label={t('watchCollect.filters.shownInterest.label')}
                checked={selectedItems.shownInterestOnly}
                onChange={() =>
                  setSelectedItemsByKey('shownInterestOnly')(
                    !selectedItems.shownInterestOnly,
                  )
                }
              />
            </div>
          </WatchCollectFilter>
        </div>

        <div ref={sectionRefs.selectedCategory}>
          {user?.isAdmin && (
            <WatchCollectFilter
              className='admin-filter'
              title="Next week's new"
            >
              <label className='filters-toggle'>
                <div className='toggle-label'>
                  {' '}
                  Only show next week&apos;s new
                </div>
                <Checkbox
                  className='filters-toggle'
                  checked={selectedItems.selectedCategory === 'new-next-week'}
                  onChange={() => {
                    const newCategory =
                      selectedItems.selectedCategory === 'new-next-week'
                        ? 'all'
                        : 'new-next-week';
                    setSelectedItemsByKey('selectedCategory')(newCategory);
                  }}
                  toggle
                />
              </label>
            </WatchCollectFilter>
          )}
        </div>
      </Modal.Content>

      <Modal.Actions>
        <div className='confirmation-modal-actions'>
          <GenericButton primacy='secondary' size='big' onClick={onReset}>
            {t('watchCollect.filters.resetAll')}
          </GenericButton>
          <GenericButton size='big' onClick={onConfirm}>
            {t('watchCollect.filters.launch')}
          </GenericButton>
        </div>
      </Modal.Actions>
    </GenericModal>
  );
};

export default compose(
  withTranslation('translations'),
  withUserFromJWToken,
)(WatchCollectFiltersModal);
