import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SmallResumeData from '../../graphql/fragments/SmallResumeData';
import PipeStepStat from '../../graphql/fragments/PipeStepStat';

const refetchQuery = gql`
  query updateGetNextProfilesCache($offerId: ID!) {
    offer(id: $offerId) {
      id
      pipeStepStats {
        ...PipeStepStat
      }
    }
  }
  ${PipeStepStat}
`;

export const mutation = /* GraphQL */ gql`
  mutation getNextProfiles($id: ID!, $profilesAmount: Int!) {
    getNextProfiles(id: $id, profilesAmount: $profilesAmount) {
      id
      jobOfferId
      resumeData {
        ...SmallResumeData
      }
    }
  }
  ${SmallResumeData}
`;

export default graphql(mutation, {
  props: ({ ownProps, mutate }) => ({
    getNextProfiles: ({ offerId, profilesAmount }) =>
      mutate({
        variables: {
          id: offerId,
          profilesAmount,
        },
        refetchQueries: [
          {
            query: refetchQuery,
            variables: {
              offerId,
            },
          },
        ],
      }),
  }),
});
