import React, { CSSProperties, FC } from 'react';
import { Placeholder } from 'semantic-ui-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { PipelineState } from '@/components/PipelineSegmentation/pipelineSegmentation';
import useMiniMission from '@/graphql/hooks/searchPoolJob/useMiniMission';

import styles from './ArchiveActionColumn.module.less';

interface ArchiveActionColumnProps {
  missionId: string;
  fromStage: PipelineState;
  day: string;
  authorFullname: string;
  profileLoading?: boolean;
  className?: string;
  style?: CSSProperties;
}

const ArchiveActionColumn: FC<ArchiveActionColumnProps> = ({
  missionId,
  fromStage,
  day,
  authorFullname,
  profileLoading,
  className,
  style,
}) => {
  const { t, i18n } = useTranslation();

  const { loading, miniMission } = useMiniMission(missionId);

  const segmentName = t(`reveal.pipelineSegmentations.${fromStage}`);

  const actionDescription = t(
    `reveal.reports.archiveReasons.categoryBreakdown.detailsModal.archiveAction`,
    {
      fromStage: segmentName,
      fullname: authorFullname,
      date: new Date(day).toLocaleString(i18n.resolvedLanguage, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
    },
  );

  return (
    <div className={classNames(styles.action, className)} style={style}>
      {loading || profileLoading ? (
        <Placeholder>
          <Placeholder.Line length='short' />
        </Placeholder>
      ) : (
        <>
          <div className={styles.missionName}>
            {miniMission?.data?.title || '???'}
          </div>
          <div className={styles.description}>{actionDescription}</div>
        </>
      )}
    </div>
  );
};

export default ArchiveActionColumn;
