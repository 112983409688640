import React, { FC, useContext } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';

import useClientId from '@/hooks/router/useClientId';
import useClientActivity from '@/graphql/hooks/clients/useClientActivity';
import { LastProfileActionsContext } from '@/context/LastProfileActionsContext';
import useClientNotifications from '@/graphql/hooks/clients/useClientNotifications';
import LabelDetails from '@/components/LabelDetails';
import NotificationsTimeline from '@/routes/DashboardView/NotificationsDashboard/NotificationsTimeline';
import { ProfileAnswerNotification } from '@/types/notifications';

import styles from './NotificationsDashboard.module.less';
import Counter from '../Counter';
import EmptyNotificationsIllustration from './EmptyNotificationsIllustration';

const NotificationsDashboard: FC = () => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { events, loading: clientActivityLoading } = useClientActivity({
    clientId,
  });
  const { lastProfileActions, addProfileAction } = useContext(
    LastProfileActionsContext,
  );
  const {
    notifications,
    loading: notificationsLoading,
  } = useClientNotifications({ clientId });
  const unseenNotifications = (notifications || {}).notSeen || [];

  if (
    notificationsLoading ||
    clientActivityLoading ||
    !_.isArray(lastProfileActions)
  ) {
    return (
      <>
        <h2 className={styles.title}>{t('dashboard.notifications.header')}</h2>
        <Loader
          className={styles.loading}
          active
          inline='centered'
          size='large'
        />
      </>
    );
  }

  const hasPositiveAnswerEvent = !!_.findWhere(events, {
    subtype: 'positive-answer-detected',
  });

  const profileWithRecentActions = _.compact(
    _.pluck(lastProfileActions, 'profileId'),
  );
  const hasRecentActions = _.object(
    profileWithRecentActions,
    _.map(profileWithRecentActions, () => true),
  );

  const filteredNotifications = _.filter(
    unseenNotifications,
    (notification) => {
      const { id } = (notification as ProfileAnswerNotification).profile || {};
      if (id) {
        if (hasRecentActions[id]) {
          return false;
        }
      }
      return true;
    },
  );

  if (_.isEmpty(filteredNotifications)) {
    return (
      <>
        <h2 className={styles.title}>
          {t('dashboard.notifications.header')}
          <Counter count={filteredNotifications.length} />
          <LabelDetails text={t('dashboard.notifications.details')} />
        </h2>
        <div className={styles.emptyState}>
          <EmptyNotificationsIllustration className={styles.illustration} />
          <div className={styles.noItem}>
            {hasPositiveAnswerEvent
              ? t('dashboard.notifications.empty')
              : t('dashboard.notifications.empty-no-past-positive-answers')}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <h2 className={styles.title}>
        {t('dashboard.notifications.header')}
        <Counter count={filteredNotifications.length} />
        <LabelDetails text={t('dashboard.notifications.details')} />
      </h2>
      <NotificationsTimeline
        clientId={clientId}
        notifications={filteredNotifications}
        addProfileAction={addProfileAction}
      />
    </>
  );
};

export default NotificationsDashboard;
