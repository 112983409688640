import gql from 'graphql-tag';

export default gql`
  fragment Author on Author {
    firstname
    lastname
    photoLink
    email
  }
`;
