import { useMutation, type MutationHookOptions } from '@apollo/client';
import gql from 'graphql-tag';

const upgradeClientFreeTrial = gql`
  mutation upgradeClientFreeTrial {
    upgradeClientFreeTrial {
      id
      freeTrialConfiguration {
        upgraded
        upgradeDate
      }
    }
  }
`;

type Data = {
  client: {
    freeTrialConfiguration?: {
      upgraded?: boolean;
      upgradeDate?: string; // ISODate
    };
  };
};

type Variables = never;

type Input = {
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

function useUpgradeClientFreeTrial({ mutationOptions = {} }: Input = {}) {
  return useMutation<Data, Variables>(upgradeClientFreeTrial, mutationOptions);
}

export default useUpgradeClientFreeTrial;
