import React, { FC, useEffect, useMemo, useState } from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useSavedSearches from '@/graphql/hooks/savedSearches/useSavedSearches';
import GenericDropdown from '@/components/Common/GenericDropdown';
import useUpdateSavedSearch from '@/graphql/hooks/savedSearches/useUpdateSavedSearch';
import useDeleteSavedSearch from '@/graphql/hooks/savedSearches/useDeleteSavedSearch';
import { sanitizeTypename } from '@/common/utils/apollo';
import DropdownPanel from '@/components/Common/DropdownPanel';
import DropdownMenuItem from '@/components/Common/DropdownMenuItem';
import GenericButton from '@/components/Common/GenericButton';
import InfoTooltip from '@/components/Common/InfoTooltip';
import Delete from '../../Icons/Delete';
import CreateSavedSearchSidebar from '../CreateSavedSearchSidebar';
import ArrowDown from '../../Icons/ArrowDown';

import styles from './SavedSearchSelector.module.less';

interface SavedSearchSelectorProps {
  projectIds?: string[];
  currentCriteria?: { free?: string };
  overwriteCriteria: (criteria: { free?: string }) => void;
}

const defaultCriteria = { free: '{}' };

const SavedSearchSelector: FC<SavedSearchSelectorProps> = ({
  projectIds,
  currentCriteria = defaultCriteria,
  overwriteCriteria,
}) => {
  const { t } = useTranslation();
  const [selectedSearchId, setSelectedSearchId] = useState<string | undefined>(
    undefined,
  );
  const [showSaveSidebar, setShowSaveSidebar] = useState(false);

  const { savedSearches, loading } = useSavedSearches({ projectIds });

  const [updateSavedSearch] = useUpdateSavedSearch();
  const [deleteSavedSearch] = useDeleteSavedSearch();

  const selectedSearch = useMemo(
    () => _.findWhere(savedSearches, { id: selectedSearchId }),
    [savedSearches, selectedSearchId],
  );

  const hasBeenModified = useMemo(() => {
    const criteriaReference = selectedSearch?.criteria || defaultCriteria;
    return (
      JSON.stringify(sanitizeTypename(criteriaReference)) !==
      JSON.stringify(currentCriteria)
    );
  }, [selectedSearch, currentCriteria]);

  const handleUpdate = async () => {
    if (!selectedSearchId) return;
    await updateSavedSearch({
      id: selectedSearchId,
      criteria: currentCriteria,
    });
  };

  useEffect(() => {
    if (selectedSearch !== undefined) {
      overwriteCriteria(selectedSearch.criteria);
    }
  }, [selectedSearch, overwriteCriteria]);

  useEffect(() => {
    if (JSON.stringify(currentCriteria) === JSON.stringify(defaultCriteria)) {
      setSelectedSearchId(undefined);
    }
  }, [currentCriteria]);

  if (loading) {
    return (
      <div className={styles.container}>
        <div className={styles.title}>
          {t('reveal.searchView.savedSearches.title')}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {_.isEmpty(savedSearches) ? (
        <div>
          <span className={styles.title}>
            {t('reveal.searchView.savedSearches.title')}
          </span>
          <InfoTooltip position='right center'>
            {t('reveal.searchView.savedSearches.tooltip')}
          </InfoTooltip>
        </div>
      ) : (
        <GenericDropdown
          Trigger={({ onClick }) => (
            <button
              type='button'
              onClick={onClick}
              className={classNames(styles.dropdownTrigger)}
            >
              <span className={styles.title}>
                {selectedSearch?.title ||
                  t('reveal.searchView.savedSearches.title')}
              </span>
              <span className={styles.arrow}>
                <ArrowDown />
              </span>
            </button>
          )}
          className={styles.dropdown}
        >
          <DropdownPanel className={styles.menu}>
            {_.map(savedSearches, ({ id, title }) => (
              <div className={styles.menuItem} key={id}>
                <DropdownMenuItem
                  className={styles.menuItemSelect}
                  onClick={() => setSelectedSearchId(id)}
                  disabled={id === selectedSearchId}
                >
                  {title}
                </DropdownMenuItem>
                <GenericButton
                  className={styles.menuItemDelete}
                  isIcon
                  primacy='tertiary'
                  onClick={async () => {
                    await deleteSavedSearch({ id });
                  }}
                >
                  <Delete />
                </GenericButton>
              </div>
            ))}
          </DropdownPanel>
        </GenericDropdown>
      )}

      <div className={styles.actions}>
        {hasBeenModified && (
          <>
            <button
              type='button'
              className={styles.action}
              disabled={!hasBeenModified}
              onClick={() => setShowSaveSidebar(true)}
            >
              {t(
                `reveal.searchView.savedSearches.${
                  selectedSearch !== undefined ? 'saveNew' : 'save'
                }`,
              )}
            </button>
            {selectedSearch !== undefined && (
              <button
                type='button'
                className={styles.action}
                disabled={!hasBeenModified}
                onClick={handleUpdate}
              >
                {t('reveal.searchView.savedSearches.overwrite')}
              </button>
            )}
          </>
        )}
      </div>
      {showSaveSidebar && (
        <CreateSavedSearchSidebar
          criteria={currentCriteria}
          close={(id) => {
            if (id) {
              setSelectedSearchId(id);
            }
            setShowSaveSidebar(false);
          }}
        />
      )}
    </div>
  );
};

export default SavedSearchSelector;
