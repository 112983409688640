import { TFunction } from 'i18next';
import React from 'react';

import Contacts from '@/components/Reveal/Icons/Contacts';
import Kanban from '@/components/Reveal/Icons/Kanban';
import People from '@/components/Common/Icons/People';

import { useSearchPoolAllCandidatesCount } from '../../../graphql/hooks/searchPool/useSearchPoolCounts';

import Menu from './Menu/Menu';
import { MenuItem } from './Menu/MenuItem';

import styles from './NavClassic.module.less';

export interface NavClassicProps {
  clientId: string;
  permissions?: {
    reveal: boolean;
    watchCollect: boolean;
    watchCollectV2: boolean;
  };
  pageId: string;
  tabId: string;
  from: string;
  t: TFunction;
}

const NavClassic: React.FC<NavClassicProps> = ({
  clientId,
  pageId,
  from,
  tabId,
  permissions,
  t,
}) => {
  const { allCandidatesCount } = useSearchPoolAllCandidatesCount();

  let menuItems: MenuItem[] = [];

  menuItems.push({
    key: 'jobs',
    text: t('header.parameters.sourcing'),
    to: `/client/${clientId}/jobs`,
    pageId: 'jobs',
    active: pageId === 'jobs' || pageId === 'new-job',
    icon: <Contacts />,
  });
  menuItems.push({
    key: 'collect-all-candidates',
    text: t('header.parameters.collectAllCandidates', {
      count: allCandidatesCount,
      context: allCandidatesCount.toString(),
    }),
    pageId: 'collect-all-candidates',
    to: `/client/${clientId}/discover/candidates`,
    active: pageId === 'collect' && tabId === 'candidates',
    icon: <People />,
  });
  menuItems.push({
    key: 'discover-followup',
    text: t('header.parameters.offersFollowup'),
    pageId: 'discover-followup',
    to: `/client/${clientId}/discover/followup`,
    active: pageId === 'collect' && tabId === 'followup',
    icon: <Kanban />,
  });
  menuItems.push({
    key: 'templates',
    text: t('header.parameters.templates'),
    pageId: 'templates',
    to: `/client/${clientId}/templates`,
    active: pageId === 'templates',
  });
  menuItems.push({
    key: 'reports',
    text: t('header.parameters.analytics'),
    pageId: 'reports',
    to: `/client/${clientId}/reports`,
    active: pageId === 'reports' && from !== 'offer-parameters',
  });

  if (!permissions?.watchCollect && !permissions?.watchCollectV2) {
    menuItems = [
      {
        key: 'dashboard',
        text: t('header.parameters.dashboard'),
        pageId: 'dashboard',
        to: `/client/${clientId}/dashboard`,
        active: pageId === 'dashboard',
      },
      {
        key: 'jobs',
        text: t('header.parameters.offers'),
        pageId: 'jobs',
        to: `/client/${clientId}/jobs`,
        active: pageId === 'jobs',
      },
      {
        key: 'templates',
        text: t('header.parameters.templates'),
        pageId: 'templates',
        to: `/client/${clientId}/templates`,
        active: pageId === 'templates',
      },
      {
        key: 'reports',
        text: t('header.parameters.analytics'),
        pageId: 'reports',
        to: `/client/${clientId}/reports`,
        active: pageId === 'reports',
      },
    ];
  }

  return (
    <div className={styles.unifiedviewMenu}>
      <Menu clientId={clientId} items={menuItems} />
    </div>
  );
};

export default NavClassic;
