import classNames from 'classnames';
import React, {
  CSSProperties,
  FC,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
  useRef,
} from 'react';
import _ from 'underscore';

import { createPortal } from 'react-dom';
import DropdownControlsContext from '@/context/DropdownControlsContext';
import {
  AbsoluteDropdownPosition,
  AbsoluteDropdownYPosition,
  AbsoluteDropdownXPosition,
} from './types';
import { getYPosition, getXPosition, getTop, getLeft } from './helpers';

import styles from './AbsoluteDropdown.module.less';

export interface TriggerProps {
  onClick: () => void;
  disabled?: boolean;
}

export interface AbsoluteDropdownProps {
  position?: AbsoluteDropdownPosition;
  disabled?: boolean;
  /** @deprecated use lowercase trigger and DropdownControlsContext instead */
  Trigger?: FC<TriggerProps>;
  trigger?: ReactNode;
  onDisplayStatus?: (displayStatus: boolean) => void;
  className?: string;
  contentClassName?: string;
  style?: CSSProperties;
}

const AbsoluteDropdown: FC<AbsoluteDropdownProps> = ({
  position = 'bottom left',
  disabled,
  children,
  Trigger,
  trigger,
  className,
  onDisplayStatus,
  contentClassName,
  style,
}) => {
  const { whitelistElement: whitelistElementForParent } = useContext(
    DropdownControlsContext,
  );
  const [isContentDisplayed, setIsContentDisplayed] = useState(false);
  const [whitelistedOutsideElements, setWhitelistedOutsideElements] = useState(
    [] as HTMLElement[],
  );
  const [contentStyle, setContentStyle] = useState<{
    top: number;
    left: number;
  }>();

  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleDisplayed = useCallback(
    () => setIsContentDisplayed((value) => !value),
    [],
  );

  const whitelistElement = useMemo(
    () => (element: HTMLElement) => {
      setWhitelistedOutsideElements((current) => [...current, element]);
      whitelistElementForParent(element);
    },
    [whitelistElementForParent],
  );

  useEffect(() => {
    if (!isContentDisplayed) {
      setContentStyle(undefined);
      return;
    }
    const [preferredYPosition, preferredXPosition] = position.split(' ') as [
      AbsoluteDropdownYPosition,
      AbsoluteDropdownXPosition,
    ];
    const containerRect = containerRef.current?.getBoundingClientRect();
    const contentRect = contentRef.current?.getBoundingClientRect();

    const yPosition = getYPosition(
      preferredYPosition,
      containerRect,
      contentRect,
    );
    const xPosition = getXPosition(
      preferredXPosition,
      containerRect,
      contentRect,
    );

    const top = getTop(yPosition, containerRect, contentRect);
    const left = getLeft(xPosition, containerRect, contentRect);
    setContentStyle({ top, left });
  }, [containerRef, contentRef, position, isContentDisplayed]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      let shouldHide = true;
      if (
        containerRef.current &&
        containerRef.current.contains(e.target as Node)
      ) {
        shouldHide = false;
      }
      if (contentRef.current && contentRef.current.contains(e.target as Node)) {
        shouldHide = false;
      }
      _.each(whitelistedOutsideElements, (element) => {
        if (!shouldHide) return;
        if (element.contains(e.target as Node)) {
          shouldHide = false;
        }
      });
      if (shouldHide) setIsContentDisplayed(false);
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, whitelistedOutsideElements]);

  useEffect(() => {
    if (onDisplayStatus) {
      onDisplayStatus(isContentDisplayed);
    }
  }, [onDisplayStatus, isContentDisplayed]);

  const contextValue = useMemo(
    () => ({
      toggleDropdown: toggleDisplayed,
      closeDropdown: () => setIsContentDisplayed(false),
      whitelistElement,
    }),
    [toggleDisplayed, whitelistElement],
  );

  return (
    <DropdownControlsContext.Provider value={contextValue}>
      <div
        className={classNames(styles.dropdownContainer, className)}
        ref={containerRef}
      >
        {trigger ||
          (Trigger && (
            <Trigger disabled={disabled} onClick={toggleDisplayed} />
          ))}
        {createPortal(
          <div
            className={classNames(
              styles.dropdownContent,
              {
                [styles.open]: isContentDisplayed,
                [styles.invisible]: contentStyle === undefined,
              },
              contentClassName,
            )}
            style={{
              ...style,
              ...contentStyle,
            }}
            ref={contentRef}
          >
            {children}
          </div>,
          document.body,
        )}
      </div>
    </DropdownControlsContext.Provider>
  );
};

export default AbsoluteDropdown;
