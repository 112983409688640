import React, { ReactNode } from 'react';

import classNames from 'classnames';
import styles from './GenericSwitch.module.less';

type SwitchOption<T extends string | number> = {
  value: T;
  label: ReactNode;
};

type GenericSwitchProps<T extends string | number> = {
  options: SwitchOption<T>[];
  onValue: (value: T) => void;
  selectedValue: T;
  className?: string;
};

function GenericSwitch<T extends string | number>({
  options,
  onValue,
  selectedValue,
  className,
}: GenericSwitchProps<T>) {
  return (
    <div className={classNames(styles.switch, className)}>
      {options.map(({ value, label }) => (
        <button
          key={value}
          type='button'
          className={classNames(styles.option, {
            [styles.selected]: value === selectedValue,
          })}
          onClick={() => onValue(value)}
        >
          {label}
        </button>
      ))}
    </div>
  );
}

export default GenericSwitch;
