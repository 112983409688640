export const getTaskTypeDescription = (
  type: string,
  isCustom?: boolean,
): string => {
  switch (type) {
    case 'send-email-action':
    case 'send-extra-email':
      return 'sentEmail';
    case 'linkedin-send-request':
      return 'linkedinSendRequest';
    /*     case 'linkedin-check-request':
    return 'linkedinCheckRequestAccepted'; */
    case 'linkedin-send-message':
      return 'linkedinSendMessage';
    case 'send-text-message':
      return 'textMessage';
    case 'manual':
      return 'manual';
    case 'make-phone-call':
      return 'make-phone-call';
    case 'reveal-mission-update-profile-stage':
      return 'moveStage';
    case 'reveal-mission-profile-sourced':
      return 'profileSourced';
    case 'reveal-mission-created':
      return 'missionCreated';
    case 'reveal-mission-updated':
      return 'missionEdited';
    case 'reveal-mission-archived':
      return 'missionArchived';
    case 'reveal-mission-unarchived':
      return 'missionRestored';
    case 'reveal-mission-criteria-updated':
      return 'missionCriteriaUpated';
    case 'reveal-mission-profile-removed':
      return 'removeFromMission';
    case 'call':
      return isCustom ? 'custom-call' : 'call-with-profile';
    case 'meeting':
      return isCustom ? 'custom-meeting' : 'meeting';
    case 'comment':
      return isCustom ? 'custom-comment' : 'comment';
    case 'custom':
      return 'custom';
    default:
      return '';
  }
};

export const getTaskTypeIcon = (type: string): string => {
  switch (type) {
    case 'send-email-action':
    case 'send-extra-email':
      return 'ri-mail-fill';
    case 'linkedin-send-request':
    case 'linkedin-send-message':
    case 'linkedin-check-request':
      return 'ri-linkedin-box-fill';
    case 'send-text-message':
      return 'ri-message-fill';
    case 'reveal-mission-created':
    case 'reveal-mission-archived':
    case 'reveal-mission-unarchived':
    case 'reveal-mission-criteria-updated':
      return 'ri-briefcase-4-fill';
    case 'reveal-mission-updated':
      return 'ri-edit-line';
    case 'reveal-mission-update-profile-stage':
      return 'ri-user-shared-2-line';
    case 'reveal-mission-profile-sourced':
      return 'ri-user-add-line';
    case 'reveal-mission-profile-removed':
      return 'ri-user-unfollow-line';
    case 'make-phone-call':
      return 'ri-phone-fill';
    case 'manual':
      return 'ri-draft-fill';
    case 'call':
      return 'ri-phone-fill';
    case 'meeting':
      return 'custom-icon-manual-task';
    case 'comment':
      return 'ri-sticky-note-line';
    case 'custom':
      return 'custom';
    default:
      return 'ri-stack-fill';
  }
};
