import React, { DragEvent, useState } from 'react';
import { GenericSegmentationItem } from '..';
import styles from './GenericKanbanCard.module.less';

interface GenericKanbanCardProps {
  index: number;
  segmentItemIds: string[];
  item: GenericSegmentationItem;
  onDrag: (
    e: DragEvent<HTMLDivElement>,
    itemId: string,
    item: any,
    index: number,
  ) => void;
  transform: number;
  cardIsGrabbed: boolean;
  onSelectItem: ({
    item,
    segmentItems,
  }: {
    item: GenericSegmentationItem;
    segmentItems: string[];
  }) => void;
  onChangeCardIsGrabbed: (value: boolean) => void;
  children: any;
  draggable: boolean;
  disableProfilesInteraction?: boolean;
}

const GenericKanbanCard: React.FC<GenericKanbanCardProps> = ({
  index,
  segmentItemIds,
  item,
  onDrag,
  transform,
  cardIsGrabbed = false,
  onSelectItem,
  onChangeCardIsGrabbed,
  children,
  draggable,
  disableProfilesInteraction = false,
}) => {
  const [isDragged, setIsDragged] = useState(false);
  return (
    <div
      className={styles.kanbanCard}
      style={{
        marginTop: `${transform}px`,
        pointerEvents:
          (cardIsGrabbed && !isDragged) || disableProfilesInteraction
            ? 'none'
            : 'all',
        filter: disableProfilesInteraction ? 'grayscale(1)' : 'none',
      }}
      draggable={draggable}
      onDragStart={(e) => {
        setIsDragged(true);
        onDrag(e, item.id, item, index);
      }}
      onDragEnd={() => {
        onChangeCardIsGrabbed(false);
        setIsDragged(false);
      }}
      onClick={() => {
        onSelectItem({ item, segmentItems: segmentItemIds });
      }}
      onKeyPress={() => onSelectItem({ item, segmentItems: segmentItemIds })}
      role='link'
    >
      {children}
    </div>
  );
};

export default GenericKanbanCard;
