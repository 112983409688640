import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import useClientId from '@/hooks/router/useClientId';
import GenericButton from '@/components/Common/GenericButton';
import { UnipileMessageAction, TimelineItem } from '@/types/contactData';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
// import useSharedMessagingAccounts from '@/graphql/hooks/clients/useSharedServiceAccount';
import { PrimaryCobalt40 } from '@/less/colors';
import EmailTrackingPopup from '../EmailTrackingPopup';
import IntegrationsSynchronizationStatus from '../IntegrationsSynchronizationStatus';
import AuthorAndDate from '../AuthorAndDate';
import { TimeLineBody } from '../MessageItem';

const getBeautifiedPhoneNumber: any = ({ phoneNumber }: any) => {
  if (!phoneNumber) {
    return phoneNumber;
  }
  if (phoneNumber.indexOf('.') >= 0) {
    return phoneNumber;
  }
  const withoutSpace = phoneNumber.replace(/\s/g, '');

  if (withoutSpace.indexOf('+336') === 0) {
    return `+336 ${getBeautifiedPhoneNumber({
      phoneNumber: withoutSpace.slice(4),
    })}`;
  }
  if (withoutSpace.indexOf('+337') === 0) {
    return `+337 ${getBeautifiedPhoneNumber({
      phoneNumber: withoutSpace.slice(4),
    })}`;
  }

  if (/^[0-9]*$/.test(withoutSpace)) {
    if (withoutSpace.length === 8 || withoutSpace.length === 10) {
      let result = '';
      _.each(withoutSpace.split(''), (c) => {
        if (
          result.length > 1 &&
          result[result.length - 1] !== ' ' &&
          result[result.length - 2] !== ' '
        ) {
          result += ' ';
        }
        result += c;
      });
      return result;
    }
  }
  return phoneNumber;
};

interface UnipileMessageItemProps {
  item: TimelineItem<UnipileMessageAction>;
  unbiasedModeEnabled: boolean;
  profile: SearchPoolProfile;
  isBodyOverflown: boolean;
  showMoreClicked: boolean;
  onShowMoreClicked: () => void;
  setOverflowStatus: () => void;
  textAreaStyle: any;
  openMessageModal: (
    type?: string,
    metadata?: { key: string; value: string | boolean }[],
  ) => void;
}

export const SERVICE_FROM_SUBTYPE: Record<string, string> = {
  'unipile-linkedin': 'LinkedIn',
  'unipile-linkedin-message': 'LinkedIn',
  'unipile-linkedin-inmail': 'LinkedIn',
  'unipile-whatsapp': 'WhatsApp',
  'linkedin-send-message': 'LinkedIn',
  'whatsapp-send-message': 'WhatsApp',
};

export const ICON_FROM_SERVICE = {
  'unipile-whatsapp': {
    icon: 'ri-whatsapp-line',
    color: 'white',
    background: 'rgb(91, 180, 81)',
  },
  'whatsapp-send-message': {
    icon: 'ri-whatsapp-line',
    color: 'white',
    background: 'rgb(91, 180, 81)',
  },
  'unipile-linkedin-message': {
    icon: 'ri-linkedin-fill',
    color: 'white',
    background: '#4680D7',
  },
  'unipile-linkedin-inmail': {
    icon: 'ri-linkedin-fill',
    color: 'white',
    background: '#4680D7',
  },
  'unipile-linkedin': {
    icon: 'ri-linkedin-fill',
    color: 'white',
    background: '#4680D7',
  },
  'linkedin-send-message': {
    icon: 'ri-linkedin-fill',
    color: 'white',
    background: '#4680D7',
  },
  default: {
    icon: 'ri-chat-3',
    color: 'white',
    background: PrimaryCobalt40,
  },
};

const UnipileMessageItem: React.FC<UnipileMessageItemProps> = ({
  item,
  unbiasedModeEnabled,
  profile,
  isBodyOverflown,
  showMoreClicked,
  onShowMoreClicked,
  setOverflowStatus,
  textAreaStyle,
  openMessageModal,
  // blurEffectActive,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { action, author } = item || {};
  const { firstname, lastname, phoneNumber } = profile?.resumeData || {};
  // const unipileSendersData = useSharedMessagingAccounts();
  let profileName = `${firstname || ''} ${lastname || ''}`.trim();
  profileName = unbiasedModeEnabled ? '???' : profileName;
  const isLastMessage = getIsLastMessage(item, profile);

  const profilePhoneNumber =
    phoneNumber?.indexOf('06') === 0
      ? `${phoneNumber?.replace('06', '336')}`
      : phoneNumber;

  const actionRecipientNumber =
    action.to?.indexOf('06') === 0
      ? action.to?.replace('06', '336')
      : action.to;

  // const unipileSender = useMemo(() => {
  //   return _.findWhere(
  //     unipileSendersData?.data?.user?.sharedMessagingAccounts || [],
  //     {
  //       serviceAccountId: action?.unipileAccountId,
  //     },
  //   );
  // }, [unipileSendersData, action]);

  const isMessageToProfile =
    getBeautifiedPhoneNumber({ phoneNumber: actionRecipientNumber }) ===
      getBeautifiedPhoneNumber({ phoneNumber: profilePhoneNumber }) ||
    action.to === profileName ||
    action.to === 'profile' ||
    action.to === action.from;

  const translationKey = isMessageToProfile
    ? 'message-to-profile'
    : 'message-from-profile';

  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{
                backgroundColor: ICON_FROM_SERVICE[action.subtype].background,
              }}
              className='icon'
            >
              <i
                style={{
                  color: ICON_FROM_SERVICE[action.subtype].color,
                }}
                className={`${ICON_FROM_SERVICE[action.subtype].icon} ri-2x`}
              />
            </div>
          </div>
          <div className='author'>
            <div>
              <div
                style={{ display: 'inline-block' }}
                dangerouslySetInnerHTML={{
                  __html: t(
                    `profile.contact.timeline.unipile.${translationKey}.description`,
                    {
                      authorName: `${author?.firstname ||
                        ''} ${author?.lastname || ''}`,
                      profileName,
                      service: SERVICE_FROM_SUBTYPE[action.subtype],
                    },
                  ),
                }}
              />{' '}
            </div>
            <AuthorAndDate timelineItem={item}>
              <IntegrationsSynchronizationStatus
                timelineItem={item}
                clientId={clientId}
                profile={profile}
              />
            </AuthorAndDate>
          </div>
          <EmailTrackingPopup action={action} />
        </div>
        <div className='card-content'>
          <div
            ref={setOverflowStatus}
            className='body'
            style={{
              ...(textAreaStyle.height && {
                height: textAreaStyle.height,
              }),
            }}
          >
            <TimeLineBody content={action?.text} />
            {isBodyOverflown && (
              <div
                className={`gradient-message${
                  showMoreClicked ? ' hidden' : ''
                }`}
              />
            )}
          </div>
          <div className='spacer' />
          {isBodyOverflown && !showMoreClicked && (
            <div className='show-more' onClick={onShowMoreClicked}>
              {t('profile.contact.timeline.seeMore')}
            </div>
          )}
          {isBodyOverflown && showMoreClicked && (
            <div className='show-more' onClick={onShowMoreClicked}>
              {t('profile.contact.timeline.seeLess')}
            </div>
          )}
          {isLastMessage && !isMessageToProfile && openMessageModal && (
            <GenericButton
              onClick={() => {
                openMessageModal(
                  action.subtype === 'unipile-linkedin'
                    ? 'unipile-linkedin-message'
                    : action.subtype,
                  [
                    { key: 'unipileChatId', value: item.action.unipileChatId },
                    {
                      key: 'unipileAccountId',
                      value: item.action.unipileAccountId,
                    },
                    {
                      key: 'canSend',
                      value: true,
                    },
                  ],
                );
              }}
              primacy='secondary'
              size='small'
            >
              {t('profile.contact.timeline.reply')}
            </GenericButton>
          )}
        </div>
      </div>
    </div>
  );
};

const getIsLastMessage = (
  item: TimelineItem<UnipileMessageAction>,
  profile: SearchPoolProfile,
) => {
  const timeline = profile?.contactData?.timeline;
  if (_.isEmpty(timeline)) {
    return false;
  }

  const messageItems = _.filter(
    timeline || [],
    ({ action }) => action?.type === 'unipile-message',
  );
  if (
    _.any(messageItems, ({ date }) => {
      return +date > +item.date;
    })
  ) {
    return false;
  }
  return true;
};

export default UnipileMessageItem;
