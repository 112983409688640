import gql from 'graphql-tag';
import { MissionWithFolderingAndCustomFields } from '@/types/mission';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

const getMissionsWithFolderingAndCustomFields = gql`
  query getMissionsWithFolderingAndCustomFields {
    searchPool(id: "reveal") {
      id
      jobs {
        id
        isArchived
        data {
          title
        }
        foldering {
          department {
            id
          }
          section {
            id
          }
          subsection {
            id
          }
        }
        customFields {
          id
          type
          clientCustomFieldId
          ... on CustomFieldValueEnum {
            selected
          }
        }
      }
    }
  }
`;

type GetMissionsWithFolderingAndCustomFieldsData = {
  searchPool: {
    id: string;
    jobs: MissionWithFolderingAndCustomFields[];
  };
};

type UseMissionsWithFolderingAndCustomFieldsInput = {
  queryOptions?: QueryHookOptions<GetMissionsWithFolderingAndCustomFieldsData>;
};

interface UseMissionsWithFolderingAndCustomFieldsResult
  extends QueryResult<GetMissionsWithFolderingAndCustomFieldsData> {
  missionsWithFolderingAndCustomFields: MissionWithFolderingAndCustomFields[];
}

function useMissionsWithFolderingAndCustomFields({
  queryOptions = {},
}: UseMissionsWithFolderingAndCustomFieldsInput = {}): UseMissionsWithFolderingAndCustomFieldsResult {
  const query = useQuery<GetMissionsWithFolderingAndCustomFieldsData>(
    getMissionsWithFolderingAndCustomFields,
    queryOptions,
  );

  const missionsWithFolderingAndCustomFields =
    query.data?.searchPool.jobs || [];

  return { ...query, missionsWithFolderingAndCustomFields };
}

export default useMissionsWithFolderingAndCustomFields;
