import React from 'react';
import { Loader } from 'semantic-ui-react';
import Heatmap from '../Heatmap';
import { activityTooltip } from '../../utils';

import './ActivityGraph.css';

export const ActivityGraphStub = () => (
  <div className='activity-graph'>
    <div className='activity-graph-total'>
      <span className='activity-total-contacted-placeholder'>Loading...</span>
    </div>
    <div className='activity-graph-heatmap-container'>
      <div className='activity-graph-placeholder'>
        <Loader active inline='centered' size='large' />
      </div>
    </div>
  </div>
);

const ActivityGraph = ({ activityData, totalContacted = 0, options, isLoading, t }) => {
  if (isLoading) {
    return <ActivityGraphStub />;
  }

  return (
    <div className='activity-graph'>
      <div className='activity-graph-total'>
        <span className='activity-total-contacted'>
          {totalContacted || 0}
        </span>
        <span>
          &nbsp;
          {t('analytics.headers.activityGraph', { count: totalContacted })}
        </span>
      </div>
      <div className='activity-graph-heatmap-container'>
        <div className='activity-graph-heatmap'>
          <Heatmap data={activityData || []} tooltipFormatter={activityTooltip({ t })} {...options} />
        </div>
      </div>
    </div>
  );
};

export default ActivityGraph;
