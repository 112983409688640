import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'underscore';
import { Button, Modal } from 'semantic-ui-react';
import * as Sentry from '@sentry/browser';
import withRestoreProfile from '../../../../hocs/profiles/withRestoreProfile';
import { withLastProfileActionsContextConsumer } from '../../../../context/LastProfileActionsContext';

class RestoreModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = { open: false };
  }

  close = () => {
    this.setState({ open: false });
  };

  show = (evt) => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    this.setState({ open: true });
  };

  render() {
    const onSubmit = () => {
      const {
        clientId,
        profileId,
        offerId,
        restoreProfile,
        getNextProfileId,
        onChangeProfile,
        onChangeStep,
        stepId,
      } = this.props;
      restoreProfile({ id: profileId, offerId })
        .then(() => {
          const lastProfileAction = {
            clientId,
            jobOfferId: offerId,
            profileId,
            stepId,
            type: 'restore-profile',
          };
          try {
            this.props.addProfileAction(lastProfileAction);
          } catch (e) {
            console.error(e);
          }
          const nextProfileId = getNextProfileId();
          if (nextProfileId) {
            onChangeProfile(nextProfileId);
            this.close();
          } else {
            onChangeStep({ newStepId: stepId, forceRefresh: true });
            this.close();
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    };
    const { t } = this.props;
    const { open } = this.state;
    return (
      <div>
        <Modal
          className='restore-profile-modal'
          open={open}
          onClose={this.close}
          trigger={
            <Button
              basic
              secondary
              className='restore-modal-trigger open-skip-modal'
              onClick={this.show}
              type='button'
            >
              {t('profile.steps.restore.trigger')}
            </Button>
          }
          closeIcon
          size='tiny'
        >
          <Modal.Header>
            {t('profile.steps.restore.header')}
            <div className='subheader description'>
              {t('profile.steps.restore.subheader')}
            </div>
          </Modal.Header>

          <Modal.Actions className='align-center'>
            <Button primary size='big' type='submit' onClick={() => onSubmit()}>
              {t('profile.steps.restore.button')}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default compose(
  withRestoreProfile,
  withLastProfileActionsContextConsumer,
  withTranslation('translations'),
)(RestoreModal);
