import { useQuery, QueryHookOptions } from '@apollo/client';
import SEARCH_POOL_JOBS_STATS, {
  SearchPoolJobStatsQueryResult,
  SearchPoolJobStatsQueryVariables,
} from '../../searchPoolJobStats';

const useSearchPoolJobsStats = (
  searchPoolId: string[],
  filters?: SearchPoolJobStatsQueryVariables['filters'],
  queryOptions: QueryHookOptions<
    SearchPoolJobStatsQueryResult,
    SearchPoolJobStatsQueryVariables
  > = {},
) =>
  useQuery<SearchPoolJobStatsQueryResult, SearchPoolJobStatsQueryVariables>(
    SEARCH_POOL_JOBS_STATS,
    {
      variables: { searchPoolId, filters },
      ...queryOptions,
    },
  );

export default useSearchPoolJobsStats;
