import React from 'react';
import { withTranslation } from 'react-i18next';
import { List } from 'immutable';
import { Menu, Input, Popup, Icon, Button } from 'semantic-ui-react';

import { convertFromHTML, convertToHTML } from 'draft-convert';
import {
  ContentBlock,
  ContentState,
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  CompositeDecorator,
  SelectionState,
  genKey,
} from 'draft-js';

import { findPlaceholdersEntities, findLinkEntities, isURL } from './helpers';
import Placeholder from './Placeholder';
import Link from './Link';

import './editor.css';

const getFullUrl = (url) =>
  url.match(/^https?:\/\//i) ? url : `https://${url}`;

const NEWLINE_REGEX = /\n/g;
const MAX_LENGTH = 200;

class CustomEditor extends React.PureComponent {
  static defaultProps = {
    showSubmitButton: true,
  };

  constructor(props) {
    super(props);
    const initialEditorState = this.initEditor({ props, field: 'body' });
    const initialSubjectEditorState = this.initEditor({
      props,
      field: 'subject',
    });
    this.state = {
      needUpdate: !(props.profile || {}).resumeData,
      editorState: initialEditorState,
      editorStateSubject: initialSubjectEditorState,
      showURLInput: false,
      urlValue: '',
      activeField: 'body',
      hasFocus: false,
    };
  }

  onChange = (editorState, cb) => {
    this.setState({ editorState, activeField: 'body' }, () => {
      if (cb) cb();
      this.handleChange();
    });
  };

  getEntityKeyInSelection = (type) => {
    if (this.state.activeField === 'body') {
      const { editorState } = this.state;
      const selection = editorState.getSelection();
      const contentState = editorState.getCurrentContent();
      const startKey = selection.getStartKey();
      const startOffset = selection.getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const entityKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      if (!entityKey) return null;

      const entity = contentState.getEntity(entityKey);
      if (entity.getType() !== type) return null;
      return entityKey;
    }
    return null;
  };

  getInlineEditorState = (editorState) => {
    const blocks = editorState.getCurrentContent().getBlocksAsArray();

    // If we have more than one block, compress them
    if (blocks.length > 1) {
      editorState = this.condenseBlocks(editorState, blocks, {});
    } else {
      // We only have one content block
      let contentBlock = blocks[0];
      let text = contentBlock.getText();
      const characterList = contentBlock.getCharacterList();
      // let hasEntitiesToStrip = options.stripEntities && characterListhasEntities(characterList)

      if (NEWLINE_REGEX.test(text)) {
        // || hasEntitiesToStrip
        // Replace the text stripped of its newlines. Note that we replace
        // one '\n' with one ' ' so we don't need to modify the characterList
        text = this.replaceNewlines(text);

        // Create a new content block based on the old one
        contentBlock = new ContentBlock({
          key: genKey(),
          text,
          type: 'unstyled',
          characterList,
          depth: 0,
        });

        // Update the editor state with the compressed version
        // const selection = editorState.getSelection()
        const newContentState = ContentState.createFromBlockArray([
          contentBlock,
        ]);

        // Create the new state as an undoable action
        editorState = EditorState.push(
          editorState,
          newContentState,
          'insert-characters',
        );
      }
    }
    return editorState;
  };

  initEditor = ({ props, field }) => {
    const decorator = new CompositeDecorator([
      {
        strategy: findPlaceholdersEntities,
        component: Placeholder,
        props: {
          highlight: null,
        },
      },
      {
        strategy: findLinkEntities,
        component: Link,
      },
    ]);
    const subjectDefaultValue = props.defaultValueSubject
      ? props.defaultValueSubject
      : null;
    const bodyDefaultValue = props.defaultValue ? props.defaultValue : null;
    const value = field === 'subject' ? subjectDefaultValue : bodyDefaultValue;

    const contentState = value
      ? convertFromHTML({
          htmlToEntity: (nodeName, node, createEntity) => {
            if (nodeName === 'a') {
              return createEntity('LINK', 'MUTABLE', { url: node.href });
            }
            return null;
          },
          textToEntity: (text, createEntity) => {
            const result = [];
            text.replace(/\{{([^{}]*)}}/g, (match, key, offset) => {
              const entityKey = createEntity('PLACEHOLDER', 'IMMUTABLE', {
                key,
              });

              const getInstanciatedEntityText = (profile, key) => {
                const entityText = ((profile || {}).resumeData || {})[key];
                if (key === 'sourceName') {
                  if ((entityText || '').trim()) {
                    return `[${(entityText || '').trim()}] `;
                  }
                }
                return entityText || '';
              };
              const entityProfileContent = getInstanciatedEntityText(
                props.profile,
                key,
              );
              const entityText = entityProfileContent || key;
              result.push({
                entity: entityKey,
                offset,
                length: match.length,
                result: entityText,
              });
            });
            return result;
          },
        })(value)
      : null;

    return contentState
      ? EditorState.createWithContent(contentState, decorator)
      : EditorState.createEmpty(decorator);
  };

  componentWillReceiveProps(nextProps) {
    const needUpdate =
      this.props.version !== nextProps.version ||
      this.props.profileId !== nextProps.profileId ||
      this.state.needUpdate;
    //    || this.props.defaultValue !== nextProps.defaultValue
    //    || this.props.defaultValueSubject !== nextProps.defaultValueSubject;

    if (needUpdate) {
      this.setState({
        needUpdate: false,
        editorState: this.initEditor({ props: nextProps, field: 'body' }),
        editorStateSubject: this.initEditor({
          props: nextProps,
          field: 'subject',
        }),
      });
    }

    if (nextProps.focusId !== this.state.focusId) {
      this.setState({ focusId: nextProps.focusId }, () => {
        this.focus();
      });
    }

    if (
      nextProps.onChangeTriggerSubjectFocus !==
      this.props.onChangeTriggerSubjectFocus
    ) {
      this.focusSubject();
    }
  }

  condenseBlocks = (editorState, blocks) => {
    // , options
    blocks = blocks || editorState.getCurrentContent().getBlocksAsArray();
    let text = List();
    let characterList = List();

    // Gather all the text/characterList and concat them
    blocks.forEach((block) => {
      // Atomic blocks should be ignored (stripped)
      if (block.getType() !== 'atomic') {
        text = text.push(this.replaceNewlines(block.getText()));
        characterList = characterList.concat(block.getCharacterList());
      }
    });

    // Create a new content block
    const contentBlock = new ContentBlock({
      key: genKey(),
      text: text.join(''),
      type: 'unstyled',
      characterList,
      depth: 0,
    });

    // Update the editor state with the compressed version
    const newContentState = ContentState.createFromBlockArray([contentBlock]);
    // Create the new state as an undoable action
    editorState = EditorState.push(
      editorState,
      newContentState,
      'remove-range',
    );
    // Move the selection to the end
    return EditorState.moveFocusToEnd(editorState);
  };

  replaceNewlines = (str, replacement = ' ') =>
    str.replace(NEWLINE_REGEX, replacement);

  onChangeSubject = (editorState, cb) => {
    const editorStateInline = this.getInlineEditorState(editorState);

    this.setState({ editorStateSubject: editorStateInline }, () => {
      if (cb) cb();
      this.handleChangeSubject();
    });
  };

  handleChange = () => {
    const html = this.handleExportPlain({ field: 'body' });
    if (this.props.onChange) this.props.onChange(html);
  };

  handleChangeSubject = () => {
    const html = this.handleExportPlain({ field: 'subject' });
    if (this.props.onChangeSubject) this.props.onChangeSubject(html);
  };

  handleEditorRef = (editorRef) => {
    this.setState({ editorRef });
  };

  handleEditorRefSubject = (editorRef) => {
    this.setState({ editorRefSubject: editorRef });
  };

  handleUrlRef = (urlRef) => this.setState({ urlRef });

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState && command !== 'code') {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  handleBold = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.state.activeField === 'body') {
      this.onChange(
        RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'),
        this.focus,
      );
    }
  };

  handleItalic = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.state.activeField === 'body') {
      this.onChange(
        RichUtils.toggleInlineStyle(this.state.editorState, 'ITALIC'),
        this.focus,
      );
    }
  };

  handleUnderline = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.state.activeField === 'body') {
      this.onChange(
        RichUtils.toggleInlineStyle(this.state.editorState, 'UNDERLINE'),
        this.focus,
      );
    }
  };

  handleUL = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.state.activeField === 'body') {
      this.onChange(
        RichUtils.toggleBlockType(
          this.state.editorState,
          'unordered-list-item',
        ),
        this.focus,
      );
    }
  };

  handleOL = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.state.activeField === 'body') {
      this.onChange(
        RichUtils.toggleBlockType(this.state.editorState, 'ordered-list-item'),
        this.focus,
      );
    }
  };

  handleAddLink = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.state.activeField === 'body') {
      const { editorState } = this.state;
      const contentState = editorState.getCurrentContent();
      const linkEntityKey = this.getEntityKeyInSelection('LINK');
      let url = '';
      if (linkEntityKey) {
        url = contentState.getEntity(linkEntityKey).getData().url;
      } else {
        const selection = editorState.getSelection();
        const startKey = selection.getStartKey();
        const startOffset = selection.getStartOffset();
        const endOffset = selection.getEndOffset();
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
        const selectedText = blockWithLinkAtBeginning
          .getText()
          .slice(startOffset, endOffset);
        if (isURL(selectedText)) url = selectedText;
      }
      this.setState(
        {
          showURLInput: true,
          urlValue: url,
        },
        () => {
          setTimeout(() => this.state.urlRef && this.state.urlRef.focus(), 0);
        },
      );
    }
  };

  handleLinkKeyPress = (ev) =>
    ev.charCode === 13 || ev.key === 'Enter' ? this.insertLink() : null;

  handleLinkChange = (ev, { value }) => this.setState({ urlValue: value });

  insertLink = () => {
    if (this.state.activeField === 'body') {
      const { editorState, urlValue } = this.state;
      let entityKey = this.getEntityKeyInSelection('LINK');
      const contentState = editorState.getCurrentContent();

      let newEditorState = null;
      const newUrlValue = getFullUrl(urlValue);
      if (entityKey) {
        newEditorState = contentState.replaceEntityData(entityKey, {
          url: newUrlValue,
        });
        this.setState({ showURLInput: false, urlValue: '' });
        return;
      }
      const contentStateWithEntity = contentState.createEntity(
        'LINK',
        'MUTABLE',
        { url: newUrlValue },
      );
      entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      });

      this.setState(
        {
          editorState: RichUtils.toggleLink(
            newEditorState,
            newEditorState.getSelection(),
            entityKey,
          ),
          showURLInput: false,
          urlValue: '',
        },
        this.focus,
      );
    }
  };

  handleExportTemplate = ({ field }) => {
    if (field === 'body') {
      return convertToHTML({
        blockToHTML: (block) => {
          if (block.type === 'unstyled') {
            if (block.text === ' ' || block.text === '') {
              return <br />;
            }
            return <div />;
          }
          return null;
        },
        entityToHTML: (entity, originalText) => {
          if (entity.type === 'PLACEHOLDER') {
            return `{{${entity.data.key}}}`;
          }
          if (entity.type === 'LINK') {
            return `<a href="${entity.data.url}">${originalText}</a>`;
          }
          return originalText;
        },
      })(this.state.editorState.getCurrentContent());
    }
    let editorState = this.state.editorStateSubject;
    const contentState = this.state.editorStateSubject.getCurrentContent();
    contentState.getBlockMap().forEach((contentBlock) => {
      const blockKey = contentBlock.getKey();
      const entities = [];

      // Find entities keys in current block
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        if (entityKey === null) return false;
        if (contentState.getEntity(entityKey).getType() === 'PLACEHOLDER') {
          entities.push(entityKey);
        }
        return undefined;
      });

      // For each entity, find the position in the updated editor state
      entities.forEach((entityKey) => {
        const newContentBlock = editorState
          .getCurrentContent()
          .getBlockForKey(blockKey);
        newContentBlock.findEntityRanges(
          (character) => character.getEntity() === entityKey,
          (start, end) => {
            const selectionState = new SelectionState({
              anchorKey: blockKey,
              anchorOffset: start,
              focusKey: blockKey,
              focusOffset: end,
            });
            const entity = contentState.getEntity(entityKey);
            const textWithEntity = Modifier.replaceText(
              editorState.getCurrentContent(),
              selectionState,
              `{{${entity.data.key}}}`,
              null,
              entityKey,
            );
            editorState = EditorState.push(
              editorState,
              textWithEntity,
              'insert-characters',
            );
          },
        );
      });
    });

    // Export the update editor state
    return editorState.getCurrentContent().getPlainText();
  };

  handleExportPlain = ({ field }) => {
    if (field === 'body') {
      return convertToHTML({
        blockToHTML: (block) => {
          if (block.type === 'unstyled') {
            if (block.text === ' ' || block.text === '') {
              return <br />;
            }
            return <div />;
          }
          return null;
        },
        entityToHTML: (entity, originalText) => {
          if (entity.type === 'LINK') {
            return `<a href="${entity.data.url}">${originalText}</a>`;
          }
          return originalText;
        },
      })(this.state.editorState.getCurrentContent());
    }
    return this.state.editorStateSubject.getCurrentContent().getPlainText();
  };

  handleBeforeInput = () => {
    const currentContent = this.state.editorStateSubject.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;

    if (currentContentLength > MAX_LENGTH - 1) {
      return 'handled';
    }
    return undefined;
  };

  handlePastedText = (pastedText) => {
    const currentContent = this.state.editorStateSubject.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;

    if (currentContentLength + pastedText.length > MAX_LENGTH) {
      return 'handled';
    }
    return undefined;
  };

  focus = () => {
    this.state.editorRef.focus();
    this.setState({ showURLInput: false, urlValue: '', activeField: 'body' });
  };

  focusSubject = () => {
    this.state.editorRefSubject.focus();
    this.setState({
      showURLInput: false,
      urlValue: '',
      activeField: 'subject',
    });
  };

  onBlur = (e) => {
    const { currentTarget } = e;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this.setState({ hasFocus: false });
      }
    }, 0);
  };

  onFocus = (e) => {
    const { currentTarget } = e;
    setTimeout(() => {
      if (currentTarget.contains(document.activeElement)) {
        this.setState({ hasFocus: true });
      }
    }, 0);
  };

  renderSecondaryMenu({ handleSubmit, disableSubmit, t }) {
    const selection = this.state.editorState.getSelection();
    const currentStyle = this.state.editorState.getCurrentInlineStyle();
    const blockType = this.state.editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return (
      <Menu secondary style={{ margin: 0 }}>
        {this.state.showURLInput ? (
          <Menu.Item>
            <Input
              ref={this.handleUrlRef}
              value={this.state.urlValue}
              onChange={this.handleLinkChange}
              onKeyPress={this.handleLinkKeyPress}
              action={{
                color: 'green',
                icon: 'checkmark',
                onMouseDown: this.insertLink,
              }}
              iconPosition='left'
              icon='linkify'
              placeholder='https://...'
            />
          </Menu.Item>
        ) : (
          <Menu.Menu position='right'>
            <Menu.Item
              active={currentStyle.has('BOLD')}
              onMouseDown={this.handleBold}
              className='editor-toolbar-item'
            >
              <Popup
                trigger={<Icon name='bold' color='grey' size='small' />}
                content='Bold (Ctrl-B)'
                size='mini'
                inverted
                position='top center'
              />
            </Menu.Item>
            <Menu.Item
              active={currentStyle.has('ITALIC')}
              onMouseDown={this.handleItalic}
              className='editor-toolbar-item'
            >
              <Popup
                trigger={<Icon name='italic' color='grey' size='small' />}
                content='Italic (Ctrl-I)'
                size='mini'
                inverted
                position='top center'
              />
            </Menu.Item>
            <Menu.Item
              active={currentStyle.has('UNDERLINE')}
              onMouseDown={this.handleUnderline}
              className='editor-toolbar-item'
            >
              <Popup
                trigger={<Icon name='underline' color='grey' size='small' />}
                content='Underline (Ctrl-U)'
                size='mini'
                inverted
                position='top center'
              />
            </Menu.Item>
            <Menu.Item
              active={blockType === 'unordered-list-item'}
              onMouseDown={this.handleUL}
              className='editor-toolbar-item'
            >
              <Popup
                trigger={
                  <Icon name='unordered list' color='grey' size='small' />
                }
                content='Bulleted List'
                size='mini'
                inverted
                position='top center'
              />
            </Menu.Item>
            <Menu.Item
              active={blockType === 'ordered-list-item'}
              onMouseDown={this.handleOL}
              className='editor-toolbar-item'
            >
              <Popup
                trigger={<Icon name='ordered list' color='grey' size='small' />}
                content='Numbered List'
                size='mini'
                inverted
                position='top center'
              />
            </Menu.Item>
            <Menu.Item
              active={!!this.getEntityKeyInSelection('LINK')}
              onMouseDown={this.handleAddLink}
              className='editor-toolbar-item'
            >
              <Popup
                trigger={<Icon name='linkify' color='grey' size='small' />}
                content='Insert Link'
                size='mini'
                inverted
                position='top center'
              />
            </Menu.Item>
          </Menu.Menu>
        )}
        {this.props.showSubmitButton && (
          <Button
            onMouseDown={handleSubmit}
            className='hsButton save-comment-button'
            disabled={disableSubmit}
          >
            {t('profile.contact.timeline.save')}
          </Button>
        )}
      </Menu>
    );
  }

  render() {
    const { editorState, hasFocus } = this.state;
    const { handleSubmit, disableSubmit, t } = this.props;
    let className = 'RichEditor-editor';
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (
        contentState.getBlockMap() &&
        contentState.getBlockMap().first() &&
        contentState
          .getBlockMap()
          .first()
          .getType() !== 'unstyled'
      ) {
        className += ' RichEditor-hidePlaceholder';
      }
    }
    return (
      <div onBlur={this.onBlur} onFocus={this.onFocus}>
        <div className={`editor-container ${hasFocus ? 'focus' : ''}`}>
          {this.props.fields === 'double' ? (
            <div
              className={`email-subject ${className}`}
              onClick={this.focusSubject}
              style={{
                paddingBottom: 8,
                marginBottom: 0,
                borderBottom: '1px solid whitesmoke',
              }}
            >
              <Editor
                ref={this.handleEditorRefSubject}
                editorState={this.state.editorStateSubject}
                onChange={this.onChangeSubject}
                placeholder={this.props.placeholderSubject}
                handleBeforeInput={this.handleBeforeInput}
                handlePastedText={this.handlePastedText}
              />
            </div>
          ) : null}
          <div className='body-and-signature'>
            <div className={`main-body ${className}`} onClick={this.focus}>
              <Editor
                ref={this.handleEditorRef}
                editorState={this.state.editorState}
                handleKeyCommand={this.handleKeyCommand}
                onChange={this.onChange}
                placeholder={this.props.placeholder}
              />
            </div>
          </div>
          {hasFocus || this.props.alwaysShowMenu ? (
            this.renderSecondaryMenu({ handleSubmit, disableSubmit, t })
          ) : (
            <div className='empty-menu' />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('translations')(CustomEditor);
