import React, { useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import GenericButton from '@/components/Common/GenericButton';
import GenericTooltip from '@/components/Common/GenericTooltip';
import AddProfileToSequenceContextProvider from '@/context/AddProfileToSequenceContext';
import AddToProjectSidebar from './SideBars/AddToProjectSidebar';

const AddToProjectButton = ({ clientId, jobId, candidates, basicBtn }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const { t } = useTranslation();
  const Trigger = ({ disabled }) => (
    <GenericButton
      primacy={basicBtn ? 'secondary' : 'primary'}
      className='add-to-project-button bulk-action-button'
      onClick={() => setShowSidebar(true)}
      disabled={disabled}
    >
      {jobId
        ? t('reveal.searchView.header.addToMission')
        : t('reveal.searchView.header.addToAMission')}
    </GenericButton>
  );
  const disabled = _.isEmpty(candidates);
  return (
    <AddProfileToSequenceContextProvider>
      {disabled ? (
        <GenericTooltip
          trigger={
            <span>
              <Trigger disabled />
            </span>
          }
          content={t('reveal.missions.mission.actionButtonHelper')}
          position='bottom center'
        />
      ) : (
        <Trigger />
      )}

      {showSidebar && (
        <AddToProjectSidebar
          clientId={clientId}
          jobId={jobId}
          candidates={candidates}
          onClose={() => setShowSidebar(false)}
        />
      )}
    </AddProfileToSequenceContextProvider>
  );
};

export default AddToProjectButton;
