import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import TranslatableTextFragment from '@/graphql/fragments/TranslatableText';

import { HumanContactCategorySubtype } from './useHumanCategorySubtypes';

const mutation = gql`
  mutation enableTripartiteMode {
    enableClientMode(mode: "tripartite") {
      id
      permissions {
        canEditHumanContactSubtypes
        canHandleCompanyContacts
        salesProjects
        sharedNotes
      }
      humanContactCategorySubtypes {
        id
        title {
          ...TranslatableText
        }
        isDefault
      }
    }
  }
  ${TranslatableTextFragment}
`;

type Data = {
  enableClientMode: {
    id: string;
    permissions: {
      canEditHumanContactSubtypes: boolean;
      canHandleCompanyContacts: boolean;
      salesProjects: boolean;
      sharedNotes: boolean;
    };
    humanContactCategorySubtypes?: HumanContactCategorySubtype[];
  };
};

type Variables = Record<string, never>;

type Input = {
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

const useEnableTripartiteMode = ({ mutationOptions = {} }: Input = {}) =>
  useMutation<Data, Variables>(mutation, mutationOptions);

export default useEnableTripartiteMode;
