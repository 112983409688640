import gql from 'graphql-tag';
import { MutationTuple, useMutation } from '@apollo/client';
import SearchPoolProfileStateFragment from '@/graphql/fragments/SearchPoolProfileState';
import { SearchPoolProfileState } from '@/types/profile';

interface MarkGlobalFavoriteVariables {
  searchPoolId: string;
  profileId: string;
}

export const markGlobalFavorite = gql`
  mutation markGlobalFavorite($searchPoolId: ID!, $profileId: ID!) {
    markGlobalFavorite(searchPoolId: $searchPoolId, profileId: $profileId) {
      ...SearchPoolProfileState
    }
  }
  ${SearchPoolProfileStateFragment}
`;

export default (): MutationTuple<
  SearchPoolProfileState,
  MarkGlobalFavoriteVariables
> => useMutation(markGlobalFavorite);
