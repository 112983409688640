import React, { FC, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import EmptyState from '@/revealComponents/EmptyState';
import useMiniOffer from '@/graphql/hooks/offers/useMiniOffer';
import ReactivateOfferModal from '@/components/modals/ReactivateOfferModal';

import GenericButton from '@/components/Common/GenericButton';
import styles from './HoldEmptyState.module.less';

interface HoldEmptyStateProps {
  offerId: string;
}

const HoldEmptyState: FC<HoldEmptyStateProps> = ({ offerId }) => {
  const { t } = useTranslation();
  const { miniOffer, loading } = useMiniOffer({ offerId });
  const [modalOpen, setModalOpen] = useState(false);

  if (loading) {
    return (
      <div className={styles.emptyState}>
        <Loader inline='centered' />
      </div>
    );
  }

  const name = miniOffer?.title || t('offers.holdEmptyState.defaultName');

  return (
    <>
      <EmptyState
        className={styles.emptyState}
        title={t('offers.holdEmptyState.title', { name })}
        illustrationPath='/images/placeholders/projectsEmptyState.svg'
        innerContent={t('offers.holdEmptyState.innerContent')}
        cta={
          <GenericButton size='big' onClick={() => setModalOpen(true)}>
            {t('offers.holdEmptyState.cta')}
          </GenericButton>
        }
      />
      <ReactivateOfferModal
        offerId={offerId}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default HoldEmptyState;
