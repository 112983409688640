import _ from 'underscore';

import { SearchItem, SearchPreferences, SearchAcceptance } from './types';
import { getAcceptance } from './acceptance';
import { defaultPreferences } from './preferences';

export function search(
  text: string,
  items: SearchItem[],
  preferences: SearchPreferences = defaultPreferences,
): SearchItem[] {
  if (text === '') {
    return _.sortBy(items, ({ labels }) => labels[preferences.mainLanguage]);
  }

  const acceptanceMap = getAcceptanceMap(text, items, preferences);

  return _.chain(items)
    .filter(({ id }) => acceptanceMap[id].category !== 'rejected')
    .sortBy(({ id }) => -acceptanceMap[id].score)
    .value();
}

function getAcceptanceMap(
  text: string,
  items: SearchItem[],
  preferences: SearchPreferences,
): Record<string, SearchAcceptance> {
  const result = {} as Record<string, SearchAcceptance>;
  _.each(items, (item) => {
    result[item.id] = getAcceptance(text, item, preferences);
  });
  return result;
}
