import { useQuery } from '@apollo/client';
import {
  getClientSSOSettings,
  ClientSSOSettingsVariables,
  ClientSSOSettingsResult,
} from '../../../clients/clientSSOSettings';

const useClientSSOSettings = (clientId: string) =>
  useQuery<ClientSSOSettingsResult, ClientSSOSettingsVariables>(
    getClientSSOSettings,
    { variables: { clientId } },
  );

export default useClientSSOSettings;
