import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import CustomFieldsValues from '@/graphql/fragments/CustomFieldsValues';
import ProfileMissionInfoWithMission from '@/graphql/fragments/ProfileMissionInfoWithMission';
import ProfileSequenceInfoWithSequence from '@/graphql/fragments/ProfileSequenceInfoWithSequence';
import ContactFlow from './fragments/ContactFlow';
import MinimalTask from './fragments/MinimalTask';
import RevealResumeData from './fragments/RevealResumeData';
import Sources from './fragments/Sources';
import TimelineItem from './fragments/TimelineItem';
import ContactData from './fragments/ContactData';
import { ATSSourceData } from './fragments/SourceData';
import TranslatableText from './fragments/TranslatableText';

export const SEARCH_POOL_PROFILE = gql`
  query getSearchPoolProfile($searchPoolId: ID!, $profileId: ID!) {
    searchPool(id: $searchPoolId) {
      id
      profile(id: $profileId) {
        id
        email
        locked
        lastATSRefreshTimestamp
        resumeData {
          ...RevealResumeData
        }
        contactFlow {
          ...ContactFlow
        }
        contactData {
          timeline {
            ...TimelineItem
          }
        }
        currentSequenceInfo {
          sequenceId
          sequence {
            id
            title
          }
        }
        missionsInfo {
          ...ProfileMissionInfoWithMission
        }
      }
    }
  }
  ${ContactFlow}
  ${ProfileMissionInfoWithMission}
  ${RevealResumeData}
  ${TimelineItem}
`;

export const SEARCH_POOL_PROFILE_WITH_RESUME_DATA_ONLY = gql`
  query getSearchPoolProfileWithResumeDataOnly(
    $searchPoolId: ID!
    $profileId: ID!
  ) {
    searchPool(id: $searchPoolId) {
      id
      profile(id: $profileId) {
        id
        email
        resumeData {
          ...RevealResumeData
        }
      }
    }
  }
  ${RevealResumeData}
`;

export const APPLY_SEQUENCE_TO_PROFILE = gql`
  mutation applySequenceToProfile(
    $searchPoolId: ID!
    $input: ApplySequenceToProfileInput!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      applySequence(input: $input) {
        profile {
          id
          contactFlow {
            ...ContactFlow
          }
        }
      }
    }
  }
  ${ContactFlow}
`;

export const IMPORT_PROFILES_CSV = gql`
  mutation importProfilesCSV(
    $searchPoolId: ID!
    $input: ImportProfilesCSVInput
  ) {
    searchPool(id: $searchPoolId) {
      importProfilesCSV(input: $input) {
        insertedProfilesCount
        duplicateProfiles {
          id
          resumeData {
            firstname
            lastname
            email
            sources {
              ...Sources
            }
          }
        }
      }
    }
  }
  ${Sources}
`;

export const ADD_PROFILES_CUSTOM_FIELDS = gql`
  mutation AddProfilesCustomFields(
    $searchPoolId: ID!
    $input: AddProfilesCustomFieldsInput
  ) {
    searchPool(id: $searchPoolId) {
      addProfilesCustomFields(input: $input) {
        profiles {
          id
          resumeData {
            customFields {
              ...CustomFieldsValues
            }
          }
        }
      }
    }
  }
  ${CustomFieldsValues}
`;

export const LOG_PROFILE_ACTIVITY = gql`
  mutation logProfileActivity(
    $searchPoolId: ID!
    $missionId: ID
    $profileId: ID!
    $date: DateTime
    $payload: ActivityLogInput!
    $synchronize: Boolean
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      logProfileActivity(
        profileId: $profileId
        missionId: $missionId
        date: $date
        activityLog: $payload
        synchronize: $synchronize
      ) {
        id
        contactData {
          timeline {
            ...TimelineItem
          }
        }
        explicitLastInteraction {
          author {
            firstname
            lastname
            email
          }
          date
        }
        missionsInfo {
          ...ProfileMissionInfoWithMission
        }
        contactFlow {
          ...ContactFlow
        }
        currentSequenceInfo {
          ...ProfileSequenceInfoWithSequence
        }
      }
    }
  }
  ${TimelineItem}
  ${ProfileMissionInfoWithMission}
  ${ProfileSequenceInfoWithSequence}
  ${ContactFlow}
`;

export const UPDATE_PROFILE_ACTIVITY = gql`
  mutation updateProfileActivity(
    $searchPoolId: ID!
    $profileId: ID!
    $date: String!
    $payload: ActivityLogInput
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      updateProfileActivity(
        profileId: $profileId
        date: $date
        activityLog: $payload
      ) {
        id
        contactData {
          timeline {
            ...TimelineItem
          }
        }
      }
    }
  }
  ${TimelineItem}
`;

export const UPDATE_ACTION_FORM = gql`
  mutation updateActionForm(
    $searchPoolId: ID!
    $profileId: ID!
    $date: String!
    $type: String
    $form: CustomActionFormatFormInput
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      updateActionForm(
        profileId: $profileId
        date: $date
        form: $form
        type: $type
      ) {
        id
        contactData {
          timeline {
            ...TimelineItem
          }
        }
      }
    }
  }
  ${TimelineItem}
`;

export const SYNCHRONIZE_PROFILE_ACTIVITY = gql`
  mutation synchronizeProfileActivity(
    $searchPoolId: ID!
    $profileId: ID!
    $date: String!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      synchronizeProfileActivity(profileId: $profileId, date: $date) {
        id
        contactData {
          timeline {
            ...TimelineItem
          }
        }
      }
    }
  }
  ${TimelineItem}
`;

export const REMOVE_SYNCHRONIZED_PROFILE_ACTIVITY_FROM_ATS = gql`
  mutation removeSynchronizedProfileActivityFromATS(
    $searchPoolId: ID!
    $profileId: ID!
    $date: String!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      removeSynchronizedProfileActivityFromATS(
        profileId: $profileId
        date: $date
      ) {
        id
        contactData {
          timeline {
            ...TimelineItem
          }
        }
      }
    }
  }
  ${TimelineItem}
`;

export const ADD_PROFILE_COMMENT = gql`
  mutation addProfileComment(
    $searchPoolId: ID!
    $id: ID!
    $comment: String!
    $synchronize: Boolean
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      addProfileComment(id: $id, comment: $comment, synchronize: $synchronize) {
        id
        contactData {
          draftComment
          timeline {
            ...TimelineItem
          }
        }
      }
    }
  }
  ${TimelineItem}
`;

export const UPDATE_PROFILE_COMMENT = gql`
  mutation updateSearchPoolProfileComment(
    $searchPoolId: ID!
    $id: ID!
    $date: String!
    $comment: String!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      updateProfileComment(id: $id, date: $date, comment: $comment) {
        id
        contactData {
          draftComment
          timeline {
            ...TimelineItem
          }
        }
      }
    }
  }
  ${TimelineItem}
`;

export const REMOVE_TIMELINE_ITEM = gql`
  mutation removeTimelineItem(
    $searchPoolId: ID!
    $profileId: ID!
    $type: String!
    $date: String!
    $subtype: String
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      removeTimelineItem(
        profileId: $profileId
        type: $type
        date: $date
        subtype: $subtype
      ) {
        id
        contactData {
          draftComment
          timeline {
            ...TimelineItem
          }
        }
      }
    }
  }
  ${TimelineItem}
`;

export const withUpdateSearchPoolProfileComment = graphql(
  UPDATE_PROFILE_COMMENT,
  {
    props: ({ mutate }) => ({
      updateSearchPoolProfileComment: ({ searchPoolId, id, date, comment }) =>
        mutate({
          variables: {
            searchPoolId,
            id,
            date,
            comment,
          },
        }),
    }),
  },
);

export const UPDATE_RESUME_DATA_IN_SEARCH_POOL = gql`
  mutation updateResumeData(
    $searchPoolId: ID!
    $input: UpdateResumeDataProfileInput!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      updateResumeData(input: $input) {
        profile {
          id
          email
          resumeData {
            firstname
            lastname
            gender {
              value
              isLocked
            }
            email
            headline {
              content {
                text
              }
            }
            customFields {
              ...CustomFieldsValues
            }
            phoneNumber
            sources {
              ...Sources
            }
          }
          contactCategory {
            type
            subtypes {
              id
            }
          }
          linkedProfilesGroup {
            id
            linkedProfileReferences {
              profileId
              title {
                ...TranslatableText
              }
            }
          }
        }
      }
    }
  }
  ${Sources}
  ${CustomFieldsValues}
  ${TranslatableText}
`;

export const UPLOAD_TO_ATS_JOB = gql`
  mutation uploadProfileToATSJob(
    $searchPoolId: ID!
    $input: UploadProfileToATSJobInput!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      uploadProfileToATSJob(input: $input) {
        profile {
          id
          lastATSRefreshTimestamp
          resumeData {
            sourceData {
              ...ATSSourceData
            }
          }
          contactData {
            timeline {
              ...TimelineItem
            }
          }
        }
      }
    }
  }
  ${ATSSourceData}
  ${TimelineItem}
`;

export const REMOVE_PROFILE_IN_SEARCH_POOL = gql`
  mutation removeProfile($searchPoolId: ID!, $profileId: ID!) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      removeProfile(profileId: $profileId) {
        success
      }
    }
  }
`;

export const UPDATE_CONTACT_FLOW_ACTION = gql`
  mutation updateContactFlowAction(
    $searchPoolId: ID!
    $input: UpdateContactFlowActionInput
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      updateContactFlowAction(input: $input) {
        profile {
          id
          contactFlow {
            ...ContactFlow
          }
          currentSequenceInfo {
            ...ProfileSequenceInfoWithSequence
          }
          contactData {
            timeline {
              ...TimelineItem
            }
          }
        }
      }
    }
  }
  ${ContactFlow}
  ${TimelineItem}
  ${ProfileSequenceInfoWithSequence}
`;

export const ADD_CONTACT_FLOW_ACTION = gql`
  mutation addContactFlowAction(
    $searchPoolId: ID!
    $input: AddContactFlowActionInput
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      addContactFlowAction(input: $input) {
        profile {
          id
          contactFlow {
            ...ContactFlow
          }
        }
      }
    }
  }
  ${ContactFlow}
`;

export const SEND_SEARCH_POOL_EMAIL_ON_BEHALF_OF = gql`
  mutation sendSearchPoolEmailOnBehalfOf(
    $searchPoolId: ID!
    $input: SendSearchPoolEmailOnBehalfOfInput!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      sendSearchPoolEmailOnBehalfOf(input: $input) {
        id
        type
        threadId
        labelIds
      }
    }
  }
`;

export const SEND_SEARCH_POOL_EMAIL = gql`
  mutation sendSearchPoolEmail(
    $input: BackendSendSearchPoolEmailInput!
    $searchPoolId: ID!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      backendSendSearchPoolEmail(input: $input) {
        id
        type
        threadId
        labelIds
      }
    }
  }
`;

// TODO:
// Optimize this query by fetching in cache: call enrichedProfile instead of profile,
// with same args as the main profile query ?
export const SEARCH_POOL_PROFILE_CURRENT_TASK = gql`
  query getProfileCurrentTask($profileId: ID!) {
    searchPool(id: "reveal") {
      id
      profile(id: $profileId) {
        id
        currentSequenceInfo {
          sequenceId
          currentTask {
            ...MinimalTask
          }
        }
      }
    }
  }
  ${MinimalTask}
`;

export const MARK_AS_DO_NOT_CONTACT = gql`
  mutation MarkProfileAsDoNotContact(
    $searchPoolId: ID!
    $profileId: ID!
    $reason: MarkAsDoNotContactReasonInput
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      markProfileAsDoNotContact(profileId: $profileId, reason: $reason) {
        profile {
          id
          privacyState {
            markedAsDoNotContact
            reason {
              id
              title {
                ...TranslatableText
              }
            }
          }
          contactFlow {
            ...ContactFlow
          }
          contactData {
            ...ContactData
          }
          currentSequenceInfo {
            ...ProfileSequenceInfoWithSequence
          }
        }
      }
    }
  }
  ${ContactFlow}
  ${ContactData}
  ${ProfileSequenceInfoWithSequence}
  ${TranslatableText}
`;

export const UNMARK_AS_DO_NOT_CONTACT = gql`
  mutation UnmarkProfileAsDoNotContact($searchPoolId: ID!, $profileId: ID!) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      unmarkProfileAsDoNotContact(profileId: $profileId) {
        profile {
          id
          privacyState {
            markedAsDoNotContact
          }
          contactFlow {
            ...ContactFlow
          }
          contactData {
            ...ContactData
          }
          currentSequenceInfo {
            ...ProfileSequenceInfoWithSequence
          }
        }
      }
    }
  }
  ${ContactFlow}
  ${ContactData}
  ${ProfileSequenceInfoWithSequence}
`;

export const BULK_UPDATE_CURRENT_SEQUENCE_ACTION = gql`
  mutation BulkUpdateCurrentSequenceAction(
    $profileId: ID!
    $searchPoolId: ID!
    $input: [ContactFlowActionUpdateInput!]!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      bulkUpdateCurrentSequenceAction(profileId: $profileId, input: $input) {
        profile {
          id
          contactFlow {
            ...ContactFlow
          }
        }
      }
    }
  }
  ${ContactFlow}
`;

export const CLIENT_CUSTOM_PROFILE_ACTION = gql`
  mutation ClientCustomProfileAction(
    $input: CustomProfileActionInput!
    $searchPoolId: ID!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      clientCustomProfileAction(input: $input) {
        profile {
          id
          email
          locked
          lastATSRefreshTimestamp
          resumeData {
            ...RevealResumeData
          }
          contactFlow {
            ...ContactFlow
          }
          contactData {
            timeline {
              ...TimelineItem
            }
          }
          currentSequenceInfo {
            ...ProfileSequenceInfoWithSequence
          }
          missionsInfo {
            ...ProfileMissionInfoWithMission
          }
        }
      }
    }
  }
  ${ContactFlow}
  ${ProfileMissionInfoWithMission}
  ${RevealResumeData}
  ${TimelineItem}
  ${ProfileSequenceInfoWithSequence}
`;
