import React, { createContext, FC, RefObject, useRef } from 'react';

interface Tooltip {
  boundaryRef: RefObject<HTMLElement> | null;
}

export const TooltipContext = createContext<Tooltip>({
  boundaryRef: null,
});

const TooltipBoundary: FC = ({ children }) => {
  const boundaryRef = useRef<HTMLElement>(null);

  return (
    <TooltipContext.Provider value={{ boundaryRef }}>
      <span ref={boundaryRef}>{children}</span>
    </TooltipContext.Provider>
  );
};

export default TooltipBoundary;
