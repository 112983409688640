import React from 'react';

import logAction from '@/common/logAction';

export default function useQuickActions({
  clientId,
  mode,
}: {
  clientId: string;
  mode: 'reveal' | 'classic';
}) {
  const [newProfileOpen, setNewProfileOpen] = React.useState(false);
  const [newTemplateOpen, setNewTemplateOpen] = React.useState(false);
  const [newSequenceOpen, setNewSequenceOpen] = React.useState(false);
  const [newCollaboratorOpen, setNewCollaboratorOpen] = React.useState(false);
  const [newJobOpen, setNewJobOpen] = React.useState(false);

  const logQuickActionClick = (type: string) => {
    return logAction({
      type,
      info: { clientId, from: 'dashboard-quick-actions' },
    });
  };

  const openNewTemplate = () => {
    setNewTemplateOpen(true);
    logQuickActionClick('click-new-template');
  };
  const openNewCollaborator = () => {
    setNewCollaboratorOpen(true);
    logQuickActionClick('new-collaborator');
  };
  const openNewProfile = () => {
    setNewProfileOpen(true);
    logQuickActionClick('click-new-profile');
  };
  const openNewSequence = () => {
    setNewSequenceOpen(true);
    logQuickActionClick('click-new-sequence');
  };
  const openNewJob = () => {
    if (mode === 'reveal') {
      setNewJobOpen(true);
      logQuickActionClick('click-new-project');
    } else {
      logQuickActionClick('click-new-job');
    }
  };

  const quickActions = {
    openNewTemplate,
    openNewCollaborator,
    openNewProfile,
    openNewSequence,
    openNewJob,
  };
  return {
    modals: {
      newProfileOpen,
      newTemplateOpen,
      newSequenceOpen,
      newCollaboratorOpen,
      newJobOpen,
      setNewProfileOpen,
      setNewTemplateOpen,
      setNewSequenceOpen,
      setNewCollaboratorOpen,
      setNewJobOpen,
    },
    quickActions,
  };
}
