import React, {
  DragEvent,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Label } from 'semantic-ui-react';
import classNames from 'classnames';

import { PrimaryCobalt60 } from '@/less/colors';
import styles from './GenericKanbanColumn.module.less';
import GenericKanbanCard from './GenericKanbanCard';
import { GenericKanbanContext } from '../GenericKanbanContext';
import { GenericSegmentationViewContext } from '../../GenericSegmentationView/GenericSegmentationViewContext';
import { DEFAULT_MOVE_DATA } from '../../GenericSegmentationView/GenericSegmentationViewContextProvider';
import { getRandomColor } from '../../../WidgetPlayground/index';

export type GenericSegmentationItem = {
  id: string;
  [x: string]: any;
};

interface GenericKanbanColumnProps {
  segment: string;
  itemCount: number;
  items: GenericSegmentationItem[];
  color: string;
  dataLoading: boolean;
  segmentId: string;
  onAskMoreItems: (value: number) => void;
  renderItem: (
    item: GenericSegmentationItem,
    itemLoading: boolean,
  ) => ReactNode;
  disabled?: boolean;
  emptyState?: ReactNode;
  canDrop?: boolean;
}

const GenericKanbanColumn: React.FC<GenericKanbanColumnProps> = ({
  segment,
  itemCount,
  items,
  color,
  dataLoading,
  segmentId,
  onAskMoreItems,
  renderItem,
  disabled,
  emptyState,
  canDrop = true,
}) => {
  const { t } = useTranslation();
  const defaultItemsAmountToShow = 10;
  const seeMoreAmount = 25;
  const [cardToTransformId, setCardToTransformId] = useState('');
  const [isColumnHovered, setIsColumnHovered] = useState(false);
  const [showColumnAnchor, setShowColumnAnchor] = useState(false);
  const [, setPageNumber] = useState(0);
  const [itemsAmountToShow, setItemsAmountToShow] = useState(
    defaultItemsAmountToShow,
  );

  const {
    allowDrop: contextAllowDrop,
    dragContext,
    disableProfilesInteraction,
    disableDragAndDrop,
    onItemSelected,
    onDragContextChange,
    onChangeSegment,
  } = useContext(GenericSegmentationViewContext);

  const segmentColor = useMemo(() => {
    if (disabled) {
      return PrimaryCobalt60;
    }
    return color || getRandomColor();
  }, [color, disabled]);

  const { isCardGrabbed, isMouseDown, setIsCardGrabbed, setIsMouseDown } =
    useContext(GenericKanbanContext);

  const isColumnDisabled = useMemo(() => {
    if (disabled) {
      return true;
    }
    if (contextAllowDrop && dragContext && isMouseDown) {
      const canDropInCollumn = contextAllowDrop({
        ...dragContext,
        newSegment: segmentId,
      });
      if (!canDropInCollumn) {
        return true;
      }
    }
    return false;
  }, [contextAllowDrop, dragContext, segmentId, isMouseDown, disabled]);

  const pageItemIds = React.useMemo(() => {
    if (!items?.length) return [];
    return items.slice(0, itemsAmountToShow);
  }, [items, itemsAmountToShow]);

  const columnRef = useRef<HTMLDivElement>(null);

  const handleItemClick = useCallback(
    ({
      item,
      segmentItems,
    }: {
      item: GenericSegmentationItem;
      segmentItems: string[];
    }) => {
      if (onItemSelected) {
        onItemSelected({ item, segmentItems });
      }
    },
    [onItemSelected],
  );

  const drop = useCallback(
    (ev: any) => {
      ev.preventDefault();
      setIsColumnHovered(false);
      setIsCardGrabbed(false);
      if (isColumnDisabled || !canDrop) {
        return;
      }
      if (onDragContextChange) {
        onDragContextChange({
          ...dragContext,
          newSegment: segment,
          newColumnNode: ev.currentTarget,
        });
      }
    },
    [
      onDragContextChange,
      dragContext,
      setIsCardGrabbed,
      isColumnDisabled,
      segment,
      canDrop,
    ],
  );

  const allowDrop = useCallback(
    (ev: DragEvent<HTMLDivElement>) => {
      ev.preventDefault();
      if (isColumnDisabled || !canDrop) {
        // eslint-disable-next-line no-param-reassign
        ev.dataTransfer.dropEffect = 'none';
        return;
      }
      // eslint-disable-next-line no-param-reassign
      ev.dataTransfer.dropEffect = 'move';
      if (!columnRef.current) {
        return;
      }
      if (itemCount === 0) {
        setShowColumnAnchor(true);
      } else {
        setShowColumnAnchor(false);
      }
      const columnRect = columnRef.current.getBoundingClientRect();
      const yOffset = ev.pageY - columnRect.y;
      const columnCards = columnRef.current.children;
      _.each(columnCards, (columnCard) => {
        const cardRect = columnCard.getBoundingClientRect();
        const cardYOffset = cardRect.y - columnRect.y;
        if (
          yOffset > cardYOffset - cardRect.height / 2 &&
          yOffset < cardYOffset + cardRect.height / 2
        ) {
          setCardToTransformId(columnCard.id);
        }
      });
    },
    [isColumnDisabled, setCardToTransformId, columnRef, itemCount, canDrop],
  );

  const handleMove = useCallback(
    ({
      item,
      cardNode,
      prevColumnNode,
      index,
    }: {
      item: GenericSegmentationItem;
      cardNode: HTMLDivElement;
      prevColumnNode: HTMLElement | null;
      index: number;
    }) => {
      if (onDragContextChange) {
        onDragContextChange({
          item,
          index,
          prevSegment: segmentId,
          items: [item],
          cardNode,
          prevColumnNode: prevColumnNode || undefined,
        });
      }
    },
    [onDragContextChange, segmentId],
  );

  const drag = useCallback(
    (ev: DragEvent<HTMLDivElement>, itemId: string, index: number) => {
      const { parentElement } = ev.currentTarget;
      handleMove({
        item: _.findWhere(items, { id: itemId }) as GenericSegmentationItem,
        cardNode: ev.currentTarget,
        prevColumnNode: parentElement,
        index,
      });
      setIsCardGrabbed(true);
      ev.dataTransfer.setData('text', ev.currentTarget.id);
    },
    [setIsCardGrabbed, handleMove, items],
  );

  const handleDrop = useCallback(() => {
    if (onChangeSegment && dragContext) {
      setShowColumnAnchor(false);
      onChangeSegment({
        item: dragContext.item,
        cardNode: dragContext.cardNode,
        prevSegment: dragContext.prevSegment,
        newSegment: segmentId,
        items: dragContext.items,
        prevColumnNode: dragContext?.prevColumnNode,
        newColumnNode: dragContext?.newColumnNode,
        index: dragContext?.index ?? -1,
      });
    }
  }, [onChangeSegment, dragContext, segmentId]);

  const handleShowMore = () => {
    if (
      items?.length <= itemCount &&
      itemsAmountToShow >= items?.length - seeMoreAmount
    ) {
      // if it's the penultimate batch of show more in a single page,
      // increase the page number and fetch <offset>(page * 50) amount of profiles
      setPageNumber((oldValue) => {
        const newPage = oldValue + 1;
        onAskMoreItems(newPage * 50);
        return newPage;
      });
    }
    setItemsAmountToShow((oldValue) => oldValue + seeMoreAmount);
  };

  const shouldCardTransform = (item: GenericSegmentationItem) => {
    return (
      `kanbanCard_${item.id}-${item.categoryId}` === cardToTransformId &&
      isColumnHovered &&
      !isColumnDisabled
    );
  };

  return (
    <div
      className={classNames(styles.kanbanColumn, {
        [styles.disabled]: isColumnDisabled,
      })}
      key={segmentId}
    >
      <div
        className={styles.columnHeader}
        style={{ borderBottom: `2px solid ${segmentColor}` }}
      >
        <h4>{segment}</h4>
        <Label size='medium' className={styles.itemCounter}>
          {itemCount}
        </Label>
      </div>
      <div
        className={styles.columnContent}
        onDrop={(e) => {
          drop(e);
          handleDrop();
        }}
        onDragOver={(e) => {
          setIsColumnHovered(true);
          allowDrop(e);
        }}
        onDragEnter={() => {
          setIsColumnHovered(true);
        }}
        onDragLeave={() => {
          setIsColumnHovered(false);
          setShowColumnAnchor(false);
        }}
        onMouseDown={() => setIsMouseDown(true)}
        onMouseUp={() => setIsMouseDown(false)}
        ref={columnRef}
      >
        {!_.isEmpty(pageItemIds) ? (
          <div
            className={classNames(
              styles.kanbanColumnAnchor,
              showColumnAnchor ? styles.visible : null,
            )}
          />
        ) : (
          <div className={styles.emptyStateCard}>
            {emptyState || t('reveal.overview.columnEmptyState')}
          </div>
        )}
        {_.map(pageItemIds, (item, index) => (
          <div
            key={`${item.id}-${item.categoryId}`}
            id={`kanbanCard_${item.id}-${item.categoryId}`}
            style={{ position: 'relative' }}
          >
            <div
              className={classNames(
                styles.kanbanAnchor,
                shouldCardTransform(item) && styles.visible,
              )}
            />
            <GenericKanbanCard
              index={index}
              segmentItemIds={_.pluck(pageItemIds, 'id')}
              item={item || ''}
              draggable={!isColumnDisabled && !disableDragAndDrop}
              onDrag={drag}
              cardIsGrabbed={isCardGrabbed || false}
              onSelectItem={handleItemClick}
              onChangeCardIsGrabbed={(isGrabbed: boolean) => {
                if (onDragContextChange) {
                  onDragContextChange({
                    ...DEFAULT_MOVE_DATA,
                  });
                }
                setIsCardGrabbed(isGrabbed);
              }}
              transform={shouldCardTransform(item) ? 16 : 0}
              disableProfilesInteraction={disableProfilesInteraction || false}
            >
              {renderItem(item, dataLoading)}
            </GenericKanbanCard>
          </div>
        ))}
        {(items?.length > itemsAmountToShow || itemCount > items?.length) && (
          <p
            className={styles.showMore}
            onClick={() => handleShowMore()}
            onKeyPress={() => handleShowMore()}
          >
            {t('common.seeMore')}
            <span className='pill-message mini equal-padding blue'>
              {Math.min(
                seeMoreAmount,
                Math.max(
                  itemCount - items.length,
                  items.length - itemsAmountToShow,
                ),
              )}
            </span>
          </p>
        )}
        {itemCount > defaultItemsAmountToShow &&
          itemsAmountToShow >= itemCount && (
            <p
              className={styles.showMore}
              onClick={() => setItemsAmountToShow(defaultItemsAmountToShow)}
              onKeyPress={() => setItemsAmountToShow(defaultItemsAmountToShow)}
            >
              {t('common.seeLess')}
            </p>
          )}
      </div>
    </div>
  );
};

export default GenericKanbanColumn;
