import gql from 'graphql-tag';
import _ from 'underscore';
import { graphql } from '@apollo/client/react/hoc';
import SearchPoolProfileState from '../../graphql/fragments/SearchPoolProfileState';

export const query = gql`
  query getProfileSearchPoolState($id: ID!) {
    profile(id: $id) {
      id
      searchPoolState {
        ...SearchPoolProfileState
      }
    }
  }
  ${SearchPoolProfileState}
`;

export default graphql(query, {
  options: ({ clientProfileId }) => ({
    variables: {
      id: clientProfileId,
    },
  }),
  props: ({ data: { loading, profile, error } }) => ({
    loading,
    loadingSearchPoolState: loading,
    searchPoolState: profile && profile?.searchPoolState,
    errorSearchPoolState: error,
  }),
  skip: ({ searchPoolState }) => !_.isEmpty(searchPoolState),
});
