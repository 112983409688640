import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import GenericSelect from '@/components/Common/GenericSelect';
import baseSkillOptions from '@/common/options/skillOptions.json';
import revealSkillOptions from '@/common/options/revealSkillExtraOptions.json';

const skillOptions = [...baseSkillOptions, ...revealSkillOptions];

const cleanOptions = _.map(skillOptions, (opt) =>
  _.omit(opt, 'displayedByDefault'),
);

const SkillsDropdown = React.memo(({ skills, setSkills, name, label }) => {
  const { t, i18n } = useTranslation();

  const optionTextKey = (i18n.resolvedLanguage || '')
    .toLowerCase()
    .includes('fr')
    ? 'fr'
    : 'text';

  const onChange = (_e, { value }) => {
    setSkills({
      ...skills,
      [name]: skillsValueToCriteria(value),
    });
  };

  const value = skillsCriteriaToValue((skills || {})[name]);

  const formattedOptions = _.map(cleanOptions, (option) => ({
    value: option.value,
    label: option[optionTextKey] || option.text,
  }));

  return (
    <div className='skills-dropdown'>
      <div className='label'>{label}</div>
      <GenericSelect
        isMulti
        options={formattedOptions}
        value={_.map(value || [], (id) =>
          _.findWhere(formattedOptions, { value: id }),
        )}
        onChange={(newValues) =>
          onChange(null, { value: _.map(newValues, ({ value }) => value) })
        }
      />
    </div>
  );
});

const skillsValueToCriteria = (skills) => {
  return [{ skills: _.map(skills, (skill) => ({ id: skill })) }];
};

const skillsCriteriaToValue = (skillCriteria) => {
  return _.map(
    (skillCriteria || [])[0]?.skills,
    (skillObject) => skillObject.id,
  );
};

export default SkillsDropdown;
