import React, { FC } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import CustomField from '@/revealComponents/CustomField';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';

import styles from './MissionCustomFields.module.less';

interface MissionCustomFieldsProps {
  customFields: CustomFieldDefinition[];
  disabled?: boolean;
}

const MissionCustomFields: FC<MissionCustomFieldsProps> = ({
  customFields,
  disabled,
}) => {
  const { t } = useTranslation();
  if (!customFields?.length) {
    return null;
  }

  return (
    <div className={styles.customFieldsContainer}>
      <h3>{t('reveal.missions.mission.settingsTab.customFields')}</h3>
      {_.map(customFields, (customField) => (
        <CustomField
          key={customField.id}
          name={`customFields.${customField.id}`}
          definition={customField}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default MissionCustomFields;
