import classNames from 'classnames';
import React, {
  ChangeEventHandler,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useMemo,
  useState,
  ForwardRefRenderFunction,
} from 'react';

import styles from './GenericTextInput.module.less';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    'data-form-type'?: string;
  }
}

interface GenericTextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  onValue?: (value: string) => void;
  small?: boolean;
  icon?: ReactNode;
  fluid?: boolean;
}

const ForwardedGenericTextInput: ForwardRefRenderFunction<
  HTMLInputElement,
  GenericTextInputProps
> = (
  {
    small = false,
    onValue,
    icon,
    className,
    disabled,
    onChange,
    onFocus,
    onBlur,
    fluid,
    'data-form-type': dataFormType,
    ...props
  },
  ref,
) => {
  const [focus, setFocus] = useState(false);

  const handleChange = useMemo<
    ChangeEventHandler<HTMLInputElement> | undefined
  >(
    () =>
      onChange ||
      (onValue !== undefined
        ? ({ target }) => onValue(target.value)
        : undefined),
    [onChange, onValue],
  );

  const handleFocus = useCallback(
    (e) => {
      if (onFocus) {
        onFocus(e);
      }
      setFocus(true);
    },
    [onFocus, setFocus],
  );

  const handleBlur = useCallback(
    (e) => {
      if (onBlur) {
        onBlur(e);
      }
      setFocus(false);
    },
    [onBlur, setFocus],
  );

  return (
    <div
      className={classNames(
        styles.container,
        disabled && styles.disabled,
        focus && styles.focused,
        small && styles.small,
        fluid && styles.fluid,
        className,
      )}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      <input
        onChange={handleChange}
        className={styles.input}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        data-form-type={dataFormType || 'other'}
        {...props}
        ref={ref}
      />
    </div>
  );
};

const GenericTextInput = forwardRef(ForwardedGenericTextInput);

export default GenericTextInput;
