import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import _ from 'underscore';

import { SearchPoolMiniProfile } from '@/graphql/searchPoolMiniProfileById';
import ProfileRowSequence from '@/routes/RevealView/ProfileRow/ProfileRowSequence';

import styles from './ReapplySequenceModal.module.less';
import ModalComponent from './ModalComponent';

interface ErrorModalProps {
  onCancel: () => void;
  profileErrors: { profileId: string; error: string }[];
  profiles: SearchPoolMiniProfile[];
}

const ErrorModal: React.FC<ErrorModalProps> = ({
  onCancel,
  profileErrors,
  profiles,
}) => {
  const { t } = useTranslation('translations');
  const errors = _.compact(
    _.map(profileErrors, ({ profileId, error: profileErrorReason }) => {
      if (!profileId) {
        return null;
      }
      const profile = _.findWhere(profiles, { id: profileId });
      if (!profile) {
        return null;
      }
      return { profile, profileErrorReason };
    }),
  );
  const allErrors = profiles.length === profileErrors.length;

  return (
    <ModalComponent
      submitButton={
        <Button
          primary
          content={t('common.ok')}
          size='big'
          type='button'
          onClick={onCancel}
        />
      }
    >
      <Message error>
        {allErrors
          ? t('contactFlow.reapplySequence.allErrorsMessage')
          : t('contactFlow.reapplySequence.errorMessage')}
      </Message>
      <h4>{t('contactFlow.reapplySequence.errors')}</h4>
      <div className={classNames(styles.preview, styles.errorPreview)}>
        {_.map(errors, ({ profile, profileErrorReason }) => {
          return (
            <React.Fragment key={profile.id}>
              <div
                className={classNames(
                  styles.cell,
                  'name-and-headline-container',
                )}
              >
                <h5 className='full-name'>
                  {profile?.resumeData?.firstname || ''}{' '}
                  {profile?.resumeData?.lastname || ''}
                </h5>
              </div>
              <div
                className={classNames(styles.cell, styles.sequenceRowContainer)}
              >
                <ProfileRowSequence
                  currentSequenceInfo={profile?.currentSequenceInfo}
                  sequences={profile?.contactFlow?.sequences}
                  nextInteraction={null}
                  loading={false}
                  isStandalone={false}
                  mini
                />
              </div>
              <div className={classNames(styles.cell, styles.error)}>
                {t(
                  `contactFlow.reapplySequence.errorReasons.${profileErrorReason}`,
                  profileErrorReason,
                )}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </ModalComponent>
  );
};

export default ErrorModal;
