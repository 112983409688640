import gql from 'graphql-tag';
import { useMutation, MutationHookOptions } from '@apollo/client';
import ContactFlowTrigger from '@/graphql/fragments/ContactFlowTrigger';

interface ScheduleEmailInput {
  input: {
    actionId: string;
    sendDate: string;
    profileId: string;
    sender: string;
    trackOnEmailOpen: boolean;
    trackOnLinkClick: boolean;
    trackingMessageId: string;
  };
}

const SCHEDULE_EMAIL_MUTATION = gql`
  mutation scheduleEmail($input: ScheduleEmailInput!) {
    searchPoolProfile(searchPoolId: "reveal") {
      scheduleEmail(input: $input) {
        id
        contactFlow {
          id
          sequences {
            id
            actions {
              actionId
              plannedExecutionDate
              type
              trigger {
                ...ContactFlowTrigger
              }
            }
          }
        }
      }
    }
  }

  ${ContactFlowTrigger}
`;

export default function useScheduleEmail(
  options: MutationHookOptions<any, ScheduleEmailInput>,
) {
  const [mutate] = useMutation<any, ScheduleEmailInput>(
    SCHEDULE_EMAIL_MUTATION,
    {
      ...options,
    },
  );

  return mutate;
}
