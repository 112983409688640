import { UserAgentApplication } from 'msal';

export const MS_CLIENT_ID = process.env.REACT_APP_MS_CLIENT_ID;
export const REDIRECT_URI = `${window.location.origin  }/msal.html`; // function or string

export const msalConfig = {
  auth: {
    clientId: MS_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: REDIRECT_URI,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loadFrameTimeout: 8000, // default is 6000
    // logger: {
    //   info: (info)=>console.log('info: ', info),
    //   infoPii: (infoPii)=>console.log('infoPii: ', infoPii),
    //   verbose: (verbose)=>console.log('verbose: ', verbose),
    //   error: (error)=>console.log('error: ', error),
    //   warning: (warning)=>console.log('warning: ', warning),
    // },
  },
};


const authProvider = new UserAgentApplication(msalConfig);

export default authProvider;