import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import useClientId from '@/hooks/router/useClientId';
import { useMissionWorkflowsContext } from '../context/useMissionWorkflowsContext';

const ATS_ICONS = [
  { name: 'greenhouse', path: '/images/logos/greenhouse-round-logo.png' },
  { name: 'lever', path: '/images/logos/lever-round-logo.png' },
  { name: 'recruitee', path: '/images/logos/recruitee-round-logo.png' },
  { name: 'boondmanager', path: '/images/logos/boondmanager-square-logo.png' },
  {
    name: 'smartrecruiters',
    path: '/images/logos/smartrecruiters-round-logo.png',
  },
  { name: 'teamtailor', path: '/images/logos/teamtailor-square-logo.png' },
];

const ATSEmptyState = () => {
  const { t } = useTranslation();
  const { styles } = useMissionWorkflowsContext();
  const clientId = useClientId();
  return (
    <div className={styles.emptyStateWrapper}>
      <h2>{t('reveal.workflows.emptyStateTitle')}</h2>
      <span>{t('reveal.workflows.emptyStateSubtitle')}</span>
      <div className={styles.icons}>
        {ATS_ICONS.map((icon) => (
          <img src={icon.path} alt='ats logo' key={icon.name} width='56px' />
        ))}
      </div>
      <Link to={`/client/${clientId}/crm-integrations`}>
        <Button primary>{t('reveal.workflows.emptyStateConnect')}</Button>
      </Link>
    </div>
  );
};

export default ATSEmptyState;
