import React, { FC } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import AvatarColumn from '@/components/ProfileRow/columns/AvatarColumn';
import NameAndHeadlineColumn from '@/components/ProfileRow/columns/NameAndHeadlineColumn';
import HeaderRow from '@/components/ProfileRow/HeaderRow/HeaderRow';
import usePagination from '@/hooks/ui/usePagination';
import ProfileRow from '../ProfileRow';
import ActionColumn from './ActionColumn';
import { ActionDetail } from './types';

import styles from './StatsProfileAndActionTable.module.less';

interface StatsProfileAndActionTableProps {
  actionDetails: ActionDetail[];
}

const StatsProfileAndActionTable: FC<StatsProfileAndActionTableProps> = ({
  actionDetails,
}) => {
  const { t } = useTranslation();

  const { pageElements, numberOfPages, PageSelector } = usePagination(
    actionDetails,
  );

  return (
    <div className={styles.table}>
      <HeaderRow
        columns={[
          { id: 'avatar', title: '', className: styles.avatar },
          {
            id: 'contact',
            title: t('reveal.missions.mission.tableHeader.contact'),
            className: styles.nameAndHeadline,
          },
          {
            id: 'action',
            title: t('reveal.missions.mission.tableHeader.mission'),
            className: styles.action,
          },
        ]}
      />
      <div>
        {_.map(
          pageElements,
          ({ profileId, missionId, day, segment }, index) => (
            <ProfileRow
              key={`${missionId}_${profileId}_${day}_${index}`}
              profileId={profileId}
              columns={[
                {
                  id: 'avatar',
                  component: AvatarColumn,
                  className: styles.avatar,
                },
                {
                  id: 'contact',
                  component: NameAndHeadlineColumn,
                  className: styles.nameAndHeadline,
                },
                {
                  id: 'action',
                  component: ({ profileLoading }) => (
                    <ActionColumn
                      profileLoading={profileLoading}
                      segment={segment}
                      day={day}
                      missionId={missionId}
                      className={styles.action}
                    />
                  ),
                },
              ]}
            />
          ),
        )}
      </div>
      {numberOfPages > 1 && (
        <div className={styles.pagination}>
          <PageSelector />
        </div>
      )}
    </div>
  );
};

export default StatsProfileAndActionTable;
