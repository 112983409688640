import _ from 'underscore';
import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import sanitizeTimeLineItem from '@/common/sanitizeTimeLineItem';
import {
  LINKEDIN_MESSAGE_SUBTYPES,
  TASK_ICON_BY_TYPE,
} from '@/common/constants/taskTypes';
import UnsubscribedPill from '@/components/Reveal/Profile/privacy/UnsubscribedPill/UnsubscribedPill';
import MetaTaskForm from '@/revealComponents/ExplicitTasks/MetaTaskForm';
import { useMutation } from '@apollo/client';
import { UPDATE_ACTION_FORM } from '@/graphql/searchPoolProfile';
import { getTranslatedText, sentryCaptureException } from '@/common';
import { sanitizeTypename } from '@/common/utils/apollo';
import GenericButton from '@/components/Common/GenericButton';
import TaskIcon from '@/revealComponents/TaskIcon';
import TASK_ICONS from '../TaskIcons';
import IntegrationsSynchronizationStatus from '../IntegrationsSynchronizationStatus';
import { TimelineItemSynchronizationActions } from '../TimelineItemActions';
import EmailTrackingPopup from '../EmailTrackingPopup';
import AuthorAndDate from '../AuthorAndDate';

const CommentBody = React.memo(({ content }) => {
  if (content?.indexOf('\n') !== -1) {
    content = content.replaceAll('\n', '<br/>');
  }
  return (
    <div
      className='body'
      dangerouslySetInnerHTML={{ __html: sanitizeTimeLineItem(content) }}
    />
  );
});

const TaskCompletedItem = ({
  item,
  isBodyOverflown,
  showMoreClicked,
  onShowMoreClicked,
  setOverflowStatus,
  textAreaStyle,
  unbiasedModeEnabled,
  profile,
  clientId,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [metaTaskForm, setMetaTaskForm] = useState(item?.action?.form);
  const [updateActionForm] = useMutation(UPDATE_ACTION_FORM);
  const { t } = useTranslation();
  const { action, author } = item || {};
  const { firstname, lastname } = profile?.resumeData || {};
  let profileName = `${firstname || ''} ${lastname || ''}`.trim();
  profileName = unbiasedModeEnabled ? '???' : profileName;

  const onEdit = async () => {
    try {
      await updateActionForm(
        sanitizeTypename({
          variables: {
            searchPoolId: 'reveal',
            profileId: profile.id,
            date: item.date,
            type: item.action.type,
            form: metaTaskForm,
          },
        }),
      );
    } catch (e) {
      sentryCaptureException(e);
    }
  };

  useEffect(() => {
    setMetaTaskForm(item?.action?.form);
  }, [item]);

  const taskImage = useMemo(() => {
    if (!action.subtype) {
      return null;
    }
    const subtype =
      item?.action?.icon?.id ||
      item?.action?.messageSubtype ||
      item?.action?.subtype;
    if (subtype === LINKEDIN_MESSAGE_SUBTYPES.RECRUITER) {
      return TASK_ICON_BY_TYPE['linkedin-recruiter-inmail'];
    }
    if (subtype === LINKEDIN_MESSAGE_SUBTYPES.PREMIUM) {
      return TASK_ICON_BY_TYPE['linkedin-premium-inmail'];
    }
    if (subtype === LINKEDIN_MESSAGE_SUBTYPES.SALESNAVIGATOR) {
      return TASK_ICON_BY_TYPE['linkedin-salesnavigator-inmail'];
    }
    return null;
  }, [action.subtype, item]);

  if (!action?.subtype) return null;

  const subtype =
    item?.action?.icon?.id ||
    item?.action?.messageSubtype ||
    item?.action?.subtype;

  const iconColor = subtype
    ? TASK_ICONS[subtype]?.color
    : TASK_ICONS.taskCompleted.color;

  const icon = subtype
    ? TASK_ICONS[subtype]?.icon
    : TASK_ICONS.taskCompleted.icon;

  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div style={{ backgroundColor: iconColor }} className='icon'>
              {taskImage ? (
                <img src={`/images/icons/tasks/${taskImage}.svg`} alt='task' />
              ) : icon ? (
                <i className={`${icon}`} />
              ) : (
                <div className='custom-task-icon'>
                  <TaskIcon action={action} />
                </div>
              )}
            </div>
          </div>
          <div className='author'>
            <div>
              <div
                style={{ display: 'inline-block' }}
                dangerouslySetInnerHTML={{
                  __html:
                    action.subtype === 'custom' &&
                    !_.isEmpty(action.title?.default || action.title?.en)
                      ? getTranslatedText(action.title)
                      : t(
                          `profile.contact.timeline.${subtype}.${
                            action?.title || action?.subtype !== 'custom'
                              ? 'description'
                              : 'description_alt'
                          }`,
                          {
                            authorName: `${
                              author?.firstname || ''
                            } ${author?.lastname || ''}`,
                            profileName,
                            ...(action?.title && {
                              taskTitle: getTranslatedText(action.title),
                            }),
                          },
                        ),
                }}
              />{' '}
              {_.findWhere(item.action?.events || [], {
                type: 'click-unsubscribe-link',
              }) ? (
                <UnsubscribedPill showLabel />
              ) : (
                ''
              )}
            </div>
            <AuthorAndDate timelineItem={item}>
              <IntegrationsSynchronizationStatus
                timelineItem={item}
                clientId={clientId}
                profile={profile}
              />
            </AuthorAndDate>
          </div>
          {/* {item?.action?.form && (
            <TimelineItemActions
              profile={profile}
              clientId={clientId}
              onEdit={() => { setEditMode(true); onShowMoreClicked(); }}
              onRemove={() => { }}
              timelineItem={item}
            />
          )} */}
          <EmailTrackingPopup action={item.action} />
          <div className='timeline-item-actions'>
            <TimelineItemSynchronizationActions
              clientId={clientId}
              profile={profile}
              timelineItem={item}
            />
          </div>
        </div>
        {(action.messageSubject || action?.messageBody || metaTaskForm) && (
          <div
            className={classNames('card-content comment', {
              'edit-mode': editMode,
            })}
          >
            <div className='row'>
              {action.messageSubject && (
                <>
                  <div className='title'>{action.messageSubject}</div>
                  <div className='spacer' />
                </>
              )}
            </div>
            <div
              ref={setOverflowStatus}
              className='body'
              style={{
                ...(textAreaStyle.height && {
                  height: textAreaStyle.height,
                }),
              }}
            >
              <div className='body-content'>
                {metaTaskForm && (
                  <MetaTaskForm
                    format={{
                      helpText: action.helpText,
                      form: action.form,
                    }}
                    readMode
                    disabled={!editMode}
                    onUpdate={({ newForm }) => setMetaTaskForm(newForm)}
                  />
                )}
                {action.messageBody && (
                  <CommentBody content={action.messageBody} />
                )}
                {editMode && (
                  <GenericButton
                    primacy='primary'
                    onClick={() => {
                      setEditMode(false);
                      onEdit();
                    }}
                  >
                    {t('common.save')}
                  </GenericButton>
                )}
              </div>
              {isBodyOverflown && (
                <div
                  className={classNames('gradient-message', {
                    hidden: showMoreClicked,
                  })}
                />
              )}
            </div>
            <div className='spacer' />
            {isBodyOverflown && !showMoreClicked && (
              <div className='show-more' onClick={onShowMoreClicked}>
                {t('profile.contact.timeline.seeMore')}
              </div>
            )}
            {isBodyOverflown && showMoreClicked && (
              <div className='show-more' onClick={onShowMoreClicked}>
                {t('profile.contact.timeline.seeLess')}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskCompletedItem;
