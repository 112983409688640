import React, { ReactNode } from 'react';

import useUserMarketplaceProfileDisplayPreferences, {
  MarketplaceGlobalSettings,
} from '@/graphql/hooks/users/UserProfileDisplayPreferences';

import { BlurLine } from '../BlurLine';

interface UnbiasedFieldGenericProps<AllowedOptions> {
  name: keyof AllowedOptions;
  fallback?: ReactNode;
  children: ReactNode;
  enabled?: boolean;
}

interface UnbiasedModeFieldProps<AllowedOptions>
  extends UnbiasedFieldGenericProps<AllowedOptions> {
  loading: boolean;
  options: AllowedOptions;
}

export function UnbiasedModeField<AllowedOptions>({
  name,
  fallback = undefined,
  options,
  loading,
  children,
  enabled = true,
}: UnbiasedModeFieldProps<AllowedOptions>): JSX.Element {
  if (!enabled) {
    // If the flag is not enabled the feature is non-existing
    // so we don't even need to check the value
    return <>{children}</>;
  }
  const shouldHide = options[name];

  if (shouldHide || loading) {
    const renderedFallback = fallback === undefined ? <BlurLine /> : fallback;
    return <>{renderedFallback}</>;
  }

  return <>{children}</>;
}

export function MarketplaceUnbiasedModeField(
  props: UnbiasedFieldGenericProps<MarketplaceGlobalSettings>,
): JSX.Element {
  const {
    userMarketplaceProfileDisplayPreferences,
    loading,
  } = useUserMarketplaceProfileDisplayPreferences();

  return (
    <UnbiasedModeField<MarketplaceGlobalSettings>
      loading={loading}
      options={userMarketplaceProfileDisplayPreferences}
      {...props}
    />
  );
}
