import React, { useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import DropdownMenuPanel from '@/components/Common/DropdownMenuPanel';

import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import GenericDropdown from '../Common/GenericDropdown';

import { Segmentation } from './pipelineSegmentation';

import styles from './SegmentationDropdown.module.less';

interface SegmentationDropdownProps {
  segmentations: Segmentation[];
  segmentation: Segmentation;
  onSelectSegmentation: (segmentationId: string) => void;
  showKanban: boolean;
}

const SegmentationDropdown: React.FC<SegmentationDropdownProps> = ({
  segmentations,
  segmentation,
  onSelectSegmentation,
  showKanban,
}) => {
  const { t } = useTranslation();
  const configurationParams = useMergedConfigurationParams();

  const preFilteredSegmentations = showKanban
    ? segmentations
    : _.filter(
        segmentations,
        (segmentationItem) => !segmentationItem.needsShowKanbanFeatureFlag,
      );

  const dropdownOptions = useMemo(
    () =>
      _.map(preFilteredSegmentations, ({ id, translationKey }) => ({
        id,
        label: t(`reveal.pipelineSegmentations.pipelines.${translationKey}`),
        disabled: id === segmentation.id,
      })),
    [preFilteredSegmentations, t, segmentation.id],
  );

  const filteredDropdownOptions = useMemo(
    () =>
      _.filter(
        dropdownOptions,
        (option) =>
          option.label !== 'Pipeline' ||
          (option.label === 'Pipeline' &&
            configurationParams?.shouldHideProjectDefaultPipelineSegmentation !==
              'true'),
      ),
    [configurationParams, dropdownOptions],
  );

  return (
    <GenericDropdown
      Trigger={({ onClick }) => (
        <button
          type='button'
          onClick={onClick}
          className={styles.dropdownTrigger}
        >
          <span className={styles.title}>
            {t(
              `reveal.pipelineSegmentations.pipelines.${segmentation.translationKey}`,
            )}
          </span>
          <span className={styles.arrow}>
            <ArrowDown />
          </span>
        </button>
      )}
    >
      <DropdownMenuPanel
        options={filteredDropdownOptions}
        onSelect={(id) => onSelectSegmentation(id)}
      />
    </GenericDropdown>
  );
};

export default SegmentationDropdown;
