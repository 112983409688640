import React from 'react';

export const LastProfileActionsContext = React.createContext({
  lastProfileActions: [],
  addProfileAction: () => {},
});


export class LastProfileActionsProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      lastProfileActions: [],
      // eslint-disable-next-line react/no-unused-state
      addProfileAction: (lastProfileAction) =>
        this.setState((prevState) => ({
          lastProfileActions: prevState.lastProfileActions.concat([{ ...lastProfileAction, date: new Date() }]),
        })),
    };
  }

  render() {
    const { children } = this.props;
    return (
      <LastProfileActionsContext.Provider value={this.state}>{children}</LastProfileActionsContext.Provider>
    );
  }
}

export const withLastProfileActionsContextConsumer = (Component) => {
  const WrappedComponent = (props) => (
    <LastProfileActionsContext.Consumer>
      {(value) => <Component {...props} {...value} />}
    </LastProfileActionsContext.Consumer>
  );
  return WrappedComponent;
};
