import _ from 'underscore';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { Input, InputOnChangeData } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Profile } from '@/types/profile';
import { UPDATE_GLOBAL_COMMENT } from '@/graphql/profile';

import styles from './ProfileGlobalComment.module.less';

interface ProfileGlobalCommentProps {
  profile: Profile;
  readOnly: boolean;
}

const ProfileGlobalComment: FC<ProfileGlobalCommentProps> = ({
  profile,
  readOnly = false,
}) => {
  const { t } = useTranslation();
  const [inputSize, setInputSize] = useState(0);
  const [globalCommentText, setGlobalCommentText] = useState(
    profile?.annotation?.globalComment?.value || '',
  );
  const [isEditing, setIsEditing] = useState(false);
  const textLengthReference = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setInputSize((textLengthReference?.current?.offsetWidth || 0) + 0);
  }, [globalCommentText]);

  useEffect(() => {
    if (profile?.annotation?.globalComment?.value) {
      setGlobalCommentText(profile.annotation.globalComment.value);
    }
  }, [profile]);

  const [updateGlobalComment] = useMutation(UPDATE_GLOBAL_COMMENT);

  const onStickyNoteChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ) => {
    setGlobalCommentText(data.value.slice(0, 120));
  };

  const onUpdateGlobalComment = async () => {
    await updateGlobalComment({
      variables: { profileId: profile?.id, input: globalCommentText },
    });
    setIsEditing(false);
  };

  if (readOnly && _.isEmpty(profile?.annotation?.globalComment?.value)) {
    return <></>;
  }

  const handleStickyNoteKeyPress = ({ key }: { key: string }) => {
    if (key === 'Enter') {
      onUpdateGlobalComment();
      setIsEditing(false);
    }
  };

  return (
    <div className={classNames(styles.stickyNoteContainer)}>
      <div
        className={classNames(
          styles.stickyNote,
          readOnly && styles.readOnly,
          isEditing && styles.editing,
        )}
        onClick={() => !isEditing && setIsEditing(true)}
        role='button'
        tabIndex={0}
        onKeyPress={handleStickyNoteKeyPress}
      >
        <span
          style={{ position: 'absolute', visibility: 'hidden' }}
          ref={textLengthReference}
        >
          {globalCommentText.replaceAll(' ', '/')}
        </span>
        {!readOnly && (
          <i className={classNames(styles.editIcon, 'ri-pencil-fill')} />
        )}
        {isEditing && !readOnly ? (
          <>
            <Input
              autoFocus
              style={{
                width: `${inputSize}px`,
              }}
              className={classNames(styles.stickyNoteInput)}
              value={globalCommentText}
              onChange={onStickyNoteChange}
              onBlur={onUpdateGlobalComment}
            />
            <span className={classNames(styles.stickyNoteCounter)}>
              {globalCommentText?.length}/120
            </span>
          </>
        ) : (
          <>
            <span className={classNames(styles.stickyNoteText)}>
              {_.isEmpty(globalCommentText)
                ? t('profile.resume.globalComment.addStickyNote')
                : globalCommentText}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileGlobalComment;
