import React, { useState } from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import { Button } from 'semantic-ui-react';


import './ScaleRating.css';

const ScaleRating = ({ maxRating = 10, onRate, lowLegend, highLegend }) => {
  const [rating, setRating] = useState();
  const scale = _.map([...Array(maxRating).keys()], (index) => index + 1);

  const onSelectRating = ({ value }) => {
    let newRating = value;
    if (rating === value) {
      newRating = undefined;
    }
    setRating(newRating);
    onRate({ rating: newRating });
  };

  return (
    <div className='scale-rating'>
      <div className='scale' role='radiogroup'>
        {_.map(scale, (value) => {
          return (
            <Button
              className={classNames('scale-rating-button', rating === value && 'selected')}
              role='radio'
              onClick={() => onSelectRating({ value })}
              basic
            >
              {value}
            </Button>
          );
        })}
      </div>
      <div className='legend'>
        <div className='low-legend'>{lowLegend}</div>
        <div className='high-legend'>{highLegend}</div>
      </div>
    </div>
  );
};

export default ScaleRating;
