import gql from 'graphql-tag';

const MinimalTask = gql`
  fragment MinimalTask on Task {
    id
    type
    subtype
    state
    queueId
    sequenceId
    missionId
    origin
    dueDate
    referenceDueDate

    title {
      default
    }

    description {
      default
    }

    owner {
      email
      firstname
      lastname
    }

    message {
      templateId
      subject
      body
      sender {
        id
      }
    }

    synchronizeWithATS
  }
`;

export default MinimalTask;
