import React from 'react';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

import withAddClientIntegration from '../../../../hocs/integrations/withAddClientIntegration';
import OAuth2Provider from '../../../../context/oauth2';

class LeverCredentialsForm extends React.PureComponent {
  useOauth2Token = async ({ token }) => {
    const { addClientIntegration } = this.props;
    try {
      await addClientIntegration({
        type: 'lever',
        mode: 'oauth',
        token,
      });
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { t } = this.props;
    const oauth2Provider = OAuth2Provider({ type: 'lever' });
    const onClick =
      oauth2Provider &&
      oauth2Provider.authorizeWithOAuth({
        onAuthorizedCallback: this.useOauth2Token,
      });
    return (
      <div className='input-container'>
        <Button type='submit' primary onClick={onClick}>
          {t('integrations.connection.connect')}
        </Button>
      </div>
    );
  }
}

export default compose(
  withTranslation('translations'),
  withAddClientIntegration,
)(LeverCredentialsForm);
