import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import CareerPage from '../../graphql/fragments/CareerPage';

export const updateCareerPageMutation = gql`
  mutation updateCareerPage($input: UpdateCareerPageInput!) {
    updateCareerPage(input: $input) {
      careerPage {
        ...CareerPage
      }
    }
  }
  ${CareerPage}
`;

export default graphql(updateCareerPageMutation, {
  props: ({ mutate }) => ({
    updateCareerPage: ({ input }) => mutate({ variables: { input } }),
  }),
});
