/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { MergeTagsVariable, SNIPPET_TYPES } from '@/common/mergeTags/utils';

import useClientId from '@/hooks/router/useClientId';
import { ConditionsChainingProvider } from './ConditionsChaining/useConditionsChainingContext';
import ConditionsChainingModal from './ConditionsChaining/ConditionsChainingModal';
import DurationVariableModal from './DurationVariable/DurationVariableModal';
import KLastCompaniesModal from './KLastCompaniesVariable/KLastCompaniesModal';

export const SMART_VARIABLES = [
  SNIPPET_TYPES.CURRENT_EXPERIENCE_DURATION,
  SNIPPET_TYPES.DURATION_SINCE_GRADUATION,
  SNIPPET_TYPES.K_LAST_COMPANIES,
  SNIPPET_TYPES.CONDITIONS_CHAINING,
];

type Props = {
  open: boolean;
  exampleVariable: MergeTagsVariable;
  onClose: () => void;
  onSubmit: (exampleVariable: MergeTagsVariable) => void;
  isEditMode?: boolean;
};

const SmartVariableWithPreviewModal: React.FC<Props> = ({
  open,
  exampleVariable,
  onClose,
  onSubmit,
  isEditMode = false,
}) => {
  const clientId = useClientId();
  if (exampleVariable?.type === SNIPPET_TYPES.CONDITIONS_CHAINING) {
    return (
      <ConditionsChainingProvider
        clientId={clientId}
        initialValue={exampleVariable}
      >
        <ConditionsChainingModal
          open={open}
          exampleVariable={exampleVariable}
          onSubmit={onSubmit}
          onClose={onClose}
          isEditMode={isEditMode}
        />
      </ConditionsChainingProvider>
    );
  }
  if (
    exampleVariable?.type === SNIPPET_TYPES.CURRENT_EXPERIENCE_DURATION ||
    exampleVariable?.type === SNIPPET_TYPES.DURATION_SINCE_GRADUATION
  ) {
    return (
      <DurationVariableModal
        open={open}
        exampleVariable={exampleVariable}
        onSubmit={onSubmit}
        onClose={onClose}
        isEditMode={isEditMode}
      />
    );
  }
  if (exampleVariable?.type === SNIPPET_TYPES.K_LAST_COMPANIES) {
    return (
      <KLastCompaniesModal
        open={open}
        exampleVariable={exampleVariable}
        onSubmit={onSubmit}
        onClose={onClose}
        isEditMode={isEditMode}
      />
    );
  }
  return null;
};

export default SmartVariableWithPreviewModal;
