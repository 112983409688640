import React, { useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import {
  ConditionResult,
  IfStatement,
  MergeTagsVariable,
} from '@/common/mergeTags/utils';
import useClientId from '@/hooks/router/useClientId';
import MergeTagsAutomationEditor from '@/containers/Editor/MergeTagsAutomationEditor';
import { decodeHtml } from '@/common/utils/string';
import { useConditionsChainingContext } from '../useConditionsChainingContext';
import styles from '../ConditionsChaining.module.less';
import { trimLineBreaks } from './helpers';

const IfStatementValueEditor: React.FC<{
  ifStatement: IfStatement;
}> = ({ ifStatement }) => {
  const { t } = useTranslation();
  const [version, setVersion] = useState(0);
  const clientId = useClientId();
  const {
    getStateTreeAfterReplacement,
    conditionsChainingState,
    setConditionsChainingState,
  } = useConditionsChainingContext();

  const handleChange = _.debounce((html: string) => {
    const cleanHtml = html === '<br/>' || html === '\n' ? '' : decodeHtml(html);
    const trimmedHtml = trimLineBreaks(cleanHtml);
    const newState = getStateTreeAfterReplacement<ConditionResult>({
      node: conditionsChainingState,
      id: ifStatement.result.id,
      newValue: {
        ...ifStatement.result,
        text: trimmedHtml,
      },
    });
    setConditionsChainingState(newState);
  }, 50);

  const onSnippetAdded = _.debounce(
    ({
      newSnippet,
      newContent,
    }: {
      newSnippet: MergeTagsVariable;
      newContent: string;
    }) => {
      if (!newSnippet) {
        return;
      }
      const newState = getStateTreeAfterReplacement<IfStatement>({
        node: conditionsChainingState,
        id: ifStatement.id,
        newValue: {
          ...ifStatement,
          result: {
            ...ifStatement.result,
            text: newContent,
            snippets: [...(ifStatement.result.snippets || []), newSnippet],
          },
        },
      });
      setConditionsChainingState(newState);
    },
    50,
  );

  const onSnippetUpdated = _.debounce(
    ({ updatedSnippet }: { updatedSnippet: MergeTagsVariable }) => {
      const newState = getStateTreeAfterReplacement<IfStatement>({
        node: conditionsChainingState,
        id: ifStatement.id,
        newValue: {
          ...ifStatement,
          result: {
            ...ifStatement.result,
            snippets: _.map(ifStatement.result.snippets || [], (snippet) => {
              if (snippet.id === updatedSnippet.id) {
                return updatedSnippet;
              }
              return snippet;
            }),
          },
        },
      });
      setConditionsChainingState(newState);
      setVersion((prevState) => prevState + 1);
    },
    50,
  );

  return (
    <Form.Field className={styles.statement}>
      {conditionsChainingState.subtype !== 'switch-on-field' && (
        <label>{t('editor.conditionsChainingVariable.value')}</label>
      )}
      <MergeTagsAutomationEditor
        editorWrapperClassName='full-width-editor-wrapper'
        version={version}
        clientId={clientId}
        defaultValue={ifStatement.result.text}
        mergeTagsSubId='settings'
        snippets={ifStatement.result.snippets}
        onChange={handleChange}
        onSnippetAdded={onSnippetAdded}
        onSnippetUpdated={onSnippetUpdated}
        alwaysShowMenu
        fields='simple'
        withMinHeight={false}
        withConditionsChainingVariable={false}
        withClientFragmentSnippets={false}
        withBorderRadius
      />
    </Form.Field>
  );
};

export default IfStatementValueEditor;
