import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SlackIntegration from '../../graphql/fragments/SlackIntegration';

export const query = gql`
  query getClientSlackIntegration($id: ID!) {
    client(id: $id) {
      id
      slackIntegration {
        ...SlackIntegration
      }
    }
  }
  ${SlackIntegration}
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.clientId,
    },
  }),
  props: ({ data: { loading, client, error } }) => ({
    loading,
    loadingSlackIntegration: loading,
    slackIntegration: (client || {}).slackIntegration,
    error,
  }),
});
