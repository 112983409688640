import React, { useCallback, useMemo, useState } from 'react';
import _, { compose } from 'underscore';
import { Button, Modal } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

import withClientBilling from '../../../hocs/clients/withClientBilling';
import withAddOffer from '../../../hocs/offers/withAddOffer';
import withClientCriteriaOptions from '../../../hocs/clients/withClientCriteriaOptions';
import withAddMultipleSearchProfilesToJob from '../../../hocs/searchPool/withAddMultipleSearchProfilesToJob';
import contextToProps from '../../../hocs/contextToProps';

import {
  getOptions,
  getNewField,
  getLabelToValue,
  getGenericTitle,
  getOfferCriteria,
  getNewOfferCriteria,
  removeUnwantedFields,
  validateOfferInput,
} from '../../../containers/Parameters/OfferCriteria/helpers';
import PureCriteriaForm from '../../../containers/Parameters/OfferCriteria/PureCriteriaForm';

import './AddToNewJobMultipleModal.css';

const AddToNewJobMultipleModal = React.memo(
  ({
    clientId,
    profileIds,
    searches,
    open,
    onClose,
    onClick,
    onAddSelectionToNewJob,
    criteriaOptions,
    loadingCriteriaOptions,
    errorCriteriaOptions,
    addOffer,
    pricingPlanConfiguration,
    addMultipleSearchProfilesToJob,
    onShowNotification,
    t,
  }) => {
    // See ClientNewOffer/index.js for state design
    const [criteria, setCriteria] = useState(null);
    const [errorFields, setErrorFields] = useState();
    const [error, setError] = useState();

    const handleChangeCriteria = useCallback(
      ({ newCriteria }) => {
        const updatedCriteria = {
          ...criteria,
          ...newCriteria,
          ...{
            skills: {
              required:
                newCriteria?.skills?.required || criteria?.skills?.required,
              important:
                newCriteria?.skills?.important || criteria?.skills?.important,
              bonus: newCriteria?.skills?.bonus || criteria?.skills?.bonus,
            },
          },
        };

        setCriteria(updatedCriteria);
        setErrorFields((prevErrorFields) => {
          return prevErrorFields
            ? _.without(prevErrorFields, ...Object.keys(newCriteria))
            : null;
        });
      },
      [criteria, setCriteria, setErrorFields],
    );

    const labelToValue = useMemo(
      () => getLabelToValue(removeUnwantedFields(criteriaOptions)),
      [criteriaOptions],
    );

    const handleChange = useCallback(
      ({ label, value }) => {
        const realValue = label === 'jobPositions' ? [value] : value;
        const newField = getNewField({ label, value: realValue, labelToValue });
        const newCriteria = removeUnwantedFields(newField);
        handleChangeCriteria({ newCriteria });
      },
      [handleChangeCriteria, labelToValue],
    );

    // prevent unnecessary calculations for each MiniCard if closed
    if (!open) {
      return null;
    }

    if (
      loadingCriteriaOptions ||
      errorCriteriaOptions ||
      !criteriaOptions ||
      !searches
    ) {
      return (
        <Modal
          open={open}
          size='tiny'
          className='add-to-new-job-multiple-modal'
          onClose={onClose}
          onClick={onClick}
          closeIcon
        >
          <Modal.Header>
            {t('watchCollect.addToNewJobModal.title', { count: 1 })}
          </Modal.Header>
        </Modal>
      );
    }

    const cleanCriteriaOptions = removeUnwantedFields(criteriaOptions) || {};
    const options = getOptions(cleanCriteriaOptions);

    const handleSubmit = async () => {
      const simplifiedMode = !_.isEmpty(pricingPlanConfiguration);
      const title = getGenericTitle({ options, criteria });
      const {
        error: validationError,
        missingFieldsNames,
      } = validateOfferInput({ title, criteria, simplifiedMode, t });

      // ex: "afin de valider, remplissez le titre, le type d'offre et l'exp souhaitée"
      if (!_.isEmpty(missingFieldsNames)) {
        setErrorFields(missingFieldsNames);
      }

      if (validationError) {
        setError(validationError);
        setTimeout(() => setError(null), 5000);
        return;
      }

      // SUBMITTING
      // Create new Job
      let newOffer;
      try {
        const input = { title, criteria: criteria || {} };
        const { data } = await addOffer({ clientId, input });
        newOffer = data?.addOffer;

        onShowNotification({
          message: t('watchCollect.addToNewJobModal.jobCreated'),
          level: 'success',
        });
      } catch (e) {
        onShowNotification({
          message: t('watchCollect.addToNewJobModal.jobCreationFailed'),
          level: 'error',
        });
      }

      // Add profile to newly created Job
      try {
        const { data } = await addMultipleSearchProfilesToJob({
          ids: profileIds,
          jobOfferId: newOffer?.id,
        });
        const results = data?.addMultiplePoolProfilesToOffer;

        const errorsCount = results?.length - _.compact(results).length;
        if (errorsCount > 0) {
          onShowNotification({
            message: t('watchCollect.results.addSelectionToJobModal.warning', {
              count: errorsCount,
            }),
            level: 'error', // TODO: add warning level
          });
        } else {
          onShowNotification({
            message: t('watchCollect.addToNewJobModal.success', {
              count: profileIds?.length,
            }),
            level: 'success',
          });
        }
      } catch (e) {
        onShowNotification({
          message: t('watchCollect.addToNewJobModal.error', {
            count: profileIds?.length,
          }),
          level: 'error',
        });
      }

      if (onAddSelectionToNewJob) {
        onAddSelectionToNewJob();
      }
      onClose();
    };

    // must be non-empty on first render of PureCriteriaForm for them to work
    const initialValues = getOfferCriteria({
      searchCriteria: (searches || [])[0]?.criteria,
      options,
    });
    const initCriteria = getNewOfferCriteria({ initialValues, labelToValue });
    const onFormInit =
      initCriteria &&
      (() => handleChangeCriteria({ newCriteria: initCriteria }));

    return (
      <Modal
        open={open}
        className='add-to-new-job-multiple-modal'
        onClose={onClose}
        onClick={onClick}
        closeIcon
      >
        <Modal.Header>
          {t('watchCollect.addToNewJobModal.title', {
            count: profileIds?.length,
          })}
        </Modal.Header>
        <Modal.Content>
          <PureCriteriaForm
            t={t}
            options={options}
            handleChange={handleChange}
            onInit={onFormInit}
            modifyingNotification={() => {}}
            current={initialValues}
            errorFields={errorFields}
            simplifiedMode
            debounceTextFields
          />
          {error && (
            <div className='error-container'>
              <span>{error}</span>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <div className='align-left'>
            <Button className='dismiss' onClick={onClose}>
              {t('watchCollect.addToNewJobModal.cancel')}
            </Button>
          </div>
          <Button primary size='big' onClick={handleSubmit}>
            {t('watchCollect.addToNewJobModal.confirm')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  },
);

export default compose(
  contextToProps,
  withTranslation('translations'),
  withAddOffer,
  withAddMultipleSearchProfilesToJob,
  withClientBilling,
  withClientCriteriaOptions,
)(AddToNewJobMultipleModal);
