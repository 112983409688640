import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const query = gql`
  query getClientCalendlyIntegration($id: ID!) {
    client(id: $id) {
      id
      calendlyIntegration {
        active
        accounts {
          id
          slug
          email
          status
        }
      }
    }
  }
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.clientId,
    },
  }),
  props: ({ data: { loading, client, error } }) => ({
    loading,
    clientCalendlyIntegration: client?.calendlyIntegration,
    clientCalendlyIntegrationLoading: loading,
    error,
  }),
});
