import { useQuery } from '@apollo/client';
import {
  GET_CLIENT_TEMPLATES,
  GetClientTemplatesResult,
  GetClientTemplatesVariables,
} from '../../clientTemplates';

const useClientTemplates = (clientId: string) =>
  useQuery<GetClientTemplatesResult, GetClientTemplatesVariables>(
    GET_CLIENT_TEMPLATES,
    { variables: { clientId } },
  );

export default useClientTemplates;
