/* eslint-disable class-methods-use-this */

import _ from 'underscore';
import { SweetEvaluatorTypes } from '../../../../SweetEvaluator';

export default class ExpressionFirstCompanyEvaluator
  implements SweetEvaluatorTypes.EvaluatorInterface {
  evaluate({
    context,
  }: {
    context: SweetEvaluatorTypes.Context;
  }): { value: string | undefined } {
    const anyContext = context as any;
    const profileExperiences =
      anyContext.experiences || anyContext.data?.experiences || null;

    if (_.isEmpty(profileExperiences)) {
      // si empty on peut trouver dans la headline
      // const { headline } = anyContext;

      return { value: undefined };
    }

    return { value: profileExperiences[0]?.companyName || undefined };
  }
}
