import React, { useEffect, useState, useRef, useMemo } from 'react';
import _ from 'underscore';

import './ExperienceFields.css';
import GenericRangeSlider from '@/components/Common/GenericRangeSlider/GenericRangeSlider';
import { useTranslation } from 'react-i18next';

const XP_YEAR_MIN = 0;
const XP_YEAR_MAX = 20;

const formatLabel = (value, max, t) => {
  if (!value) {
    return value;
  }
  return value === max
    ? `${value}+ ${t('reveal.searchView.filters.background.sliderMax')}`
    : `${value}y`;
};

const ExperienceFields = ({ experience, setExperience }) => {
  const { t } = useTranslation();
  /**
   * We initialise the state with the experience props, and then handle this
   * internal state passing information only outwards and never listening to
   * changes from the top, with one exception below.
   */
  const [experienceState, setExperienceState] = useState(experience);

  const debouncedSetExperience = useMemo(
    () => _.debounce(setExperience, 600),
    [setExperience],
  );
  const isFirstRun = useRef(true);

  useEffect(() => {
    /**
     * This is the exception cited above.
     * This effect is triggered by the "clear" button on top of the search form,
     * because the component doesn't get remounted and needs to be reset.
     * For all other times, we assume that the experience we receive as props is
     * the same as the one we passed outwards using setExperience, eg. the same
     * as experienceState.
     */
    if (experience) {
      return;
    }
    setExperienceState(null);
  }, [experience]);

  useEffect(() => {
    if (!debouncedSetExperience) {
      return;
    }
    // Do not run this effect on the first render
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      debouncedSetExperience(experienceState);
    }
    // eslint-disable-next-line
  }, [experienceState]);

  const onChange = ([min, max]) => {
    // hack to fix min being assigned to -1; see https://github.com/davidchin/react-input-range/issues/158;
    const newMin = Math.max(min, XP_YEAR_MIN);

    const newExperience = {
      ...(experience || {}),
      target: {
        ...(experience?.target || {}),
        min: newMin === '' || newMin === XP_YEAR_MIN ? null : Number(newMin),
        max: max === '' || max === XP_YEAR_MAX ? null : Number(max),
      },
      okWith: {
        ...(experience?.okWith || {}),
        min:
          newMin === '' || newMin === XP_YEAR_MIN
            ? null
            : Math.max(newMin - 1, -2),
        max: max === '' || max === XP_YEAR_MAX ? null : Number(max) + 1,
      },
    };
    setExperienceState(newExperience);
  };

  return (
    <div className='experience-fields'>
      <GenericRangeSlider
        min={XP_YEAR_MIN}
        max={XP_YEAR_MAX}
        values={[experienceState?.target?.min || XP_YEAR_MIN, experienceState?.target?.max || XP_YEAR_MAX]}
        onChange={onChange}
        formatLabel={(value) => formatLabel(value, XP_YEAR_MAX, t)}
      />
    </div>
  );
};
export default ExperienceFields;
