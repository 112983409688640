import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import useClient from '@/graphql/hooks/clients/useClient';
import useClientId from '@/hooks/router/useClientId';
import GenericButton from '@/components/Common/GenericButton';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import GenericSelect from '@/components/Common/GenericSelect';

import styles from './AccountStateForm.module.less';
import useUpdateClientAccountState from './useUpdateClientAccountState';

function AccountStateForm() {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { data } = useClient(clientId);
  const [selectedStatus, setSelectedStatus] = React.useState('');
  const [updateClient] = useUpdateClientAccountState();

  const notifications = useNotificationSystem();

  const accountState = data?.client?.accountState;

  const accountStateOptions = [
    {
      value: 'active',
      label: t('settings.accountState.active'),
    },
    {
      value: 'deactivated',
      label: t('settings.accountState.deactivated'),
    },
  ];

  const handleSave = () => {
    const newAccountState = {
      status: selectedStatus,
      ...(selectedStatus === 'deactivated' && {
        deactivationReason: {
          type: 'comment',
          message: 'Manually deactivated',
        },
        deactivationDate: new Date().toISOString(),
      }),
    };
    updateClient(newAccountState)
      .then(() => {
        notifications.success(t('settings.accountState.saved'));
      })
      .catch(() => {
        notifications.error(t('settings.accountState.error'));
      });
  };

  return (
    <div className={styles.section}>
      <div className='settings settings-header'>
        <h1>{t('settings.accountState.title')}</h1>
      </div>
      <div className={styles.description}>
        <GenericSelect
          className={styles.selector}
          options={accountStateOptions}
          value={_.find(accountStateOptions, {
            value: selectedStatus || accountState?.status,
          })}
          onChange={(value) => setSelectedStatus(value?.value || '')}
        />
        <GenericButton onClick={() => handleSave()}>
          {t('common.save')}
        </GenericButton>
      </div>
    </div>
  );
}

export default AccountStateForm;
