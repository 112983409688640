import React, { useState } from 'react';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Button, Input, Modal, List } from 'semantic-ui-react';
import withCreateGreenhouseConnector from '../../hocs/clients/withCreateGreenhouseConnector';
import withStartConnectorSynchronization from '../../hocs/clients/withStartConnectorSynchronization';

import './RevealSetupModal.css';

const RevealSetupModal = ({
  revealProjectId,
  connectorId,
  createGreenhouseConnector,
  startConnectorSynchronization,
  skipFirstStep,
  open,
  onClose,
}) => {
  const [step, setStep] = useState(skipFirstStep ? 2 : 1);
  const [apiKey, setApiKey] = useState('');
  const [savingApiKey, setSavingApiKey] = useState(false);
  const [apiKeyError, setApiKeyError] = useState();
  const [
    startingConnectorSynchronization,
    setStartingConnectorSynchronization,
  ] = useState(false);

  const onChangeApiKey = (e, { value }) => {
    setApiKey(value);
    setApiKeyError(false);
  };

  const handleSaveApiKey = async () => {
    try {
      setSavingApiKey(true);
      const input = { apiKey };
      await createGreenhouseConnector({ input });
      setStep(2);
    } catch (e) {
      console.error(e);
      setApiKeyError(true);
    }
    setSavingApiKey(false);
  };

  const handleStartSynchronization = async () => {
    try {
      setStartingConnectorSynchronization(true);
      const input = { revealProjectId, connectorId };
      await startConnectorSynchronization({ input });
      onClose();
    } catch (e) {
      console.error(e);
    }
    setStartingConnectorSynchronization(false);
  };

  if (step === 2) {
    return (
      <Modal className='reveal-setup-modal' onClose={onClose} open={open}>
        <Modal.Header>Connect HireSweet to your Greenhouse (2/2)</Modal.Header>
        <Modal.Content>
          <div className='input-container'>
            <div className='input-label'>Connection setup was successful</div>
            <div className='input-description'>
              <div>
                You can now launch the synchronization between Greenhouse and
                HireSweet.
              </div>
              <div>This may take a few minutes.</div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div className='align-left'>
            <Button content='Cancel' onClick={onClose} className='dismiss' />
          </div>
          <Button
            onClick={handleStartSynchronization}
            disabled={startingConnectorSynchronization}
            loading={startingConnectorSynchronization}
            content='Launch synchronization'
            primary
            size='big'
          />
        </Modal.Actions>
      </Modal>
    );
  }

  return (
    <Modal className='reveal-setup-modal' onClose={onClose} open={open}>
      <Modal.Header>Connect HireSweet to your Greenhouse (1/2)</Modal.Header>
      <Modal.Content>
        <div className='input-container'>
          <h3>Instructions</h3>
          <List as='ol'>
            <List.Item as='li' value='-'>
              <div className='instruction'>
                Go to your&nbsp;
                <a
                  href='https://app.greenhouse.io/configure/dev_center/credentials'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  Greenhouse Dev Center
                </a>
                &nbsp;to generate a new Harvest API key.
              </div>
            </List.Item>

            <List.Item as='li' value='-'>
              <div className='instruction'>Select the API type: Harvest</div>
            </List.Item>
            <List.Item as='li' value='-'>
              <div className='instruction'>Select the partner: Hiresweet</div>
            </List.Item>
            <List.Item as='li' value='-'>
              <div className='instruction'>
                Enter a description: Hiresweet Reveal
              </div>
            </List.Item>
            <List.Item as='li' value='-'>
              <div className='instruction'>
                Select the permissions listed below
              </div>
              <div className='input-description'>
                These are the minimum requirements for our integration to work
                properly.
              </div>
            </List.Item>
          </List>
          <div className='permissions-container'>
            <List as='ol'>
              <List.Item as='li' value='-' className='category'>
                <div className='category-name'>Applications</div>
                <div className='routes'>
                  <div>Delete: Delete Application</div>
                  <div>Post: Advance Application</div>
                  <div>Post: Move Application</div>
                  <div>Post: Reject Application</div>
                </div>
              </List.Item>
              <List.Item as='li' value='-'>
                <div className='category-name'>Candidates</div>
                <div className='routes'>
                  <div>Get: Retrieve Candidate</div>
                  <div>Get: List Candidates</div>
                  <div>Post: Add Candidate</div>
                  <div>Post: Add Email</div>
                  <div>Post: Add Attachment</div>
                  <div>Post: Add Application</div>
                </div>
              </List.Item>
              <List.Item as='li' value='-'>
                <div className='category-name'>Tags</div>
                <div className='routes'>
                  <div>Get: List Candidate Tags</div>
                </div>
              </List.Item>
              <List.Item as='li' value='-'>
                <div className='category-name'>Users</div>
                <div className='routes'>
                  <div>Get: List Users</div>
                  <div>Get: List User Job Permissions</div>
                </div>
              </List.Item>
            </List>
            <List as='ol'>
              <List.Item as='li' value='-'>
                <div className='category-name'>Scorecards</div>
                <div className='routes'>
                  <div>Get: Retrieve Application&apos;s Scorecard</div>
                </div>
              </List.Item>
              <List.Item as='li' value='-'>
                <div className='category-name'>Activity Feed</div>
                <div className='routes'>
                  <div>Get: Retrieve Activity Feed</div>
                </div>
              </List.Item>
              <List.Item as='li' value='-'>
                <div className='category-name'>Jobs</div>
                <div className='routes'>
                  <div>Get: Retrieve Job</div>
                  <div>Get: List Jobs</div>
                </div>
              </List.Item>
              <List.Item as='li' value='-'>
                <div className='category-name'>Job Posts</div>
                <div className='routes'>
                  <div> Get: List Job Posts</div>
                  <div>Get: Retrieve Job Post for Job</div>
                  <div>Get: List Job Posts for Job</div>
                </div>
              </List.Item>
              <List.Item as='li' value='-'>
                <div className='category-name'>Job Stages</div>
                <div className='routes'>
                  <div>Get: List Job Stages for Job</div>
                </div>
              </List.Item>
              <List.Item as='li' value='-'>
                <div className='category-name'>Rejection Reasons</div>
                <div className='routes'>
                  <div>Get: List Rejection Reasons</div>
                </div>
              </List.Item>
              <List.Item as='li' value='-'>
                <div className='category-name'>User Roles</div>
                <div className='routes'>
                  <div>Get: List User Roles</div>
                </div>
              </List.Item>
            </List>
          </div>
          <List as='ol'>
            <List.Item as='li' value='-'>
              <div className='instruction'>
                Paste the newly created API key below.
              </div>
            </List.Item>
          </List>
          <div className='input-label'>Harvest API key</div>
          <Input
            placeholder='Paste API key'
            fluid
            value={apiKey}
            onChange={onChangeApiKey}
            error={apiKeyError}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className='align-left'>
          <Button content='Cancel' onClick={onClose} className='dismiss' />
        </div>
        <Button
          onClick={handleSaveApiKey}
          loading={savingApiKey}
          content='Save API key'
          disabled={!apiKey || savingApiKey}
          primary
          size='big'
        />
      </Modal.Actions>
    </Modal>
  );
};

export default compose(
  withTranslation('translations'),
  withCreateGreenhouseConnector,
  withStartConnectorSynchronization,
)(RevealSetupModal);
