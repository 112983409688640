import React, { ReactNode, useMemo, FC } from 'react';
import classNames from 'classnames';
import _ from 'underscore';

import ArrowDown from '@/components/Reveal/Icons/ArrowDown';

import GenericDropdown from '../GenericDropdown';
import DropdownMenuPanel from '../DropdownMenuPanel';

import styles from './SlimSelect.module.less';

type SelectOption = {
  id: string;
  label: ReactNode;
};

type SlimSelectProps = {
  options: SelectOption[];
  value: string;
  onValue: (id: string) => void;
  className?: string;
};

const SlimSelect: FC<SlimSelectProps> = ({
  options,
  value,
  onValue,
  className,
}) => {
  const selectedOption = useMemo(() => _.findWhere(options, { id: value }), [
    options,
    value,
  ]);

  return (
    <GenericDropdown
      Trigger={({ onClick }) => (
        <button
          type='button'
          onClick={onClick}
          className={classNames(styles.trigger, className)}
        >
          <span className={styles.label}>{selectedOption?.label || ''}</span>
          <ArrowDown className={styles.arrow} />
        </button>
      )}
    >
      <DropdownMenuPanel options={options} onSelect={onValue} />
    </GenericDropdown>
  );
};

export default SlimSelect;
