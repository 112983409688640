import React, { useState } from 'react';

import useClientId from '@/hooks/router/useClientId';

import { HiresweetLibProvider, SideNav } from '@hiresweet/hiresweet-lib';
import Dots from '@/components/Common/Icons/Dots';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import Company from '@/components/Common/Icons/Company';
import _ from 'underscore';

const tabs = [
  {
    id: 'tab-1',
    label: 'Tab 1',
    icon: <Dots />,
  },
  {
    id: 'tab-2',
    label: 'Tab 2',
    icon: <ArrowDown />,
  },
  {
    id: 'tab-3',
    label: 'Tab 3',
    icon: <Company />,
  },
];

const Playground4 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  const [selectedTab, setSelectedTab] = useState('');

  return (
    <div style={{ padding: 30 }}>
      <h1>P14 - SideNav</h1>
      <div style={{ display: 'flex', gap: '48px' }}>
        <HiresweetLibProvider theme={theme} clientId={clientId}>
          {/* Side nav */}
          <div style={{ width: '300px' }}>
            <SideNav
              tabs={tabs}
              selectedTab={selectedTab}
              onSelectTab={setSelectedTab}
            />
          </div>
        </HiresweetLibProvider>
        <h1>{_.findWhere(tabs, { id: selectedTab })?.label}</h1>
      </div>
    </div>
  );
};

export default Playground4;
