import React from 'react';
import { withRouter } from 'react-router-dom';
import _, { compose } from 'underscore';
import { Loader } from 'semantic-ui-react';
import withClientCareerPages from '../../../hocs/clients/withClientCareerPages';
import withOfferJobPostings from '../../../hocs/offers/withOfferJobPostings';
import withOfferPostingDefaults from '../../../hocs/offers/withOfferPostingDefaults';
import routerParamsToProps from '../../../hocs/routerParamsToProps';
import CareerPage from '../CareerPages/CareerPage';
import JobPosting from './JobPosting';
import JobPostingCreator from './JobPostingCreator';
import JobPostingApplicationFormSelector from './components/JobPostingApplicationFormSelector';
import JobPostingPublishActions from './components/JobPostingPublishActions';

import './JobPostingsManager.css';
import TwoColumnLayout from '../../../components/layout/TwoColumnLayout';

const JobPostingsManager = ({
  clientCareerPages,
  clientCareerPagesLoading,
  jobPostings,
  jobFoldering,
  jobPostingsLoading,
  offerId,
  clientId,
}) => {
  if (clientCareerPagesLoading || jobPostingsLoading) {
    return (
      <div className='job-postings-manager'>
        <Loader active />
      </div>
    );
  }

  // TODO: link to '/careerPage' ?
  if (_.isEmpty(clientCareerPages)) {
    return (
      <TwoColumnLayout
        leftColumn={
          <div className='job-postings-manager'>
            <h2 className='header'>Create a Career Page</h2>
            <CareerPage />
          </div>
        }
      />
    );
  }

  const enabledCareerPages = _.filter(clientCareerPages, { enabled: true });
  const careerPage = clientCareerPages[0];
  if (_.isEmpty(enabledCareerPages)) {
    return (
      <TwoColumnLayout
        leftColumn={
          <div className='job-postings-manager'>
            <h1>Career Page</h1>
            <CareerPage careerPage={careerPage} />
          </div>
        }
      />
    );
  }

  if (_.isEmpty(jobPostings)) {
    // Using first enabled careerPage as default
    return (
      <PostingDefaultDataLoader>
        {({ postingDefaults }) => (
          <JobPostingCreator
            postingDefaults={postingDefaults}
            careerPage={careerPage}
            jobOfferId={offerId}
            clientId={clientId}
          />
        )}
      </PostingDefaultDataLoader>
    );
  }

  const jobPosting = jobPostings[0] || {};
  if (jobPostings.length > 1) {
    console.warn('More than one jobPosting');
  }
  return (
    <TwoColumnLayout
      leftColumn={
        <div className='job-postings-manager'>
          <div>
            <JobPosting
              careerPageId={careerPage?.id}
              jobPosting={jobPosting}
              jobFoldering={jobFoldering}
            />
            <JobPostingApplicationFormSelector
              jobPostingId={jobPosting.id}
              applicationForm={jobPosting?.application?.form}
              clientId={clientId}
            />
          </div>
        </div>
      }
      rightColumn={<JobPostingPublishActions jobPosting={jobPosting} />}
    />
  );
};

const PostingDefaultDataLoader = compose(
  withRouter,
  routerParamsToProps,
  withOfferPostingDefaults,
)(({ children, loadingPostingDefaults, postingDefaults }) => {
  if (loadingPostingDefaults) {
    return null;
  }

  return children({ postingDefaults });
});

export default compose(
  withClientCareerPages,
  withOfferJobPostings,
)(JobPostingsManager);
