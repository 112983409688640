import _ from 'underscore';


const profilesToCSV = ({ profiles, t }) => {
  const header = [
    t('csvExport.headers.firstname'),
    t('csvExport.headers.lastname'),
    t('csvExport.headers.email'),
    // t('csvExport.headers.company'),
    t('csvExport.headers.step'),
    t('csvExport.headers.linkedin'),
    t('csvExport.headers.github'),
    t('csvExport.headers.stackoverflow'),
    t('csvExport.headers.twitter'),
    t('csvExport.headers.dribbble'),
    t('csvExport.headers.behance'),
    t('csvExport.headers.website'),
  ];

  const rowsAsArrays = _.map(profiles, (profile) => profileToKeyValues({ profile, t }));
  rowsAsArrays.unshift(header);

  // const csvContent = "data:text/csv;charset=utf-8," + rowsAsArrays.map(row => row.join(",")).join("\n");
  return rowsAsArrays;
};

const profileToKeyValues = ({ profile, t }) => {
  const { resumeData } = profile || {};
  const {
    firstname,
    lastname,
    sources,
    // experiences,
    step,
  } = resumeData || {};

  const email = profile?.email || resumeData?.email;

  const {
    linkedin,
    github,
    stackoverflow,
    twitter,
    dribbble,
    behance,
    website,
  } = sources || {};

  // const currentCompanyName = getCurrentCompanyName({ experiences });
  // const { lastSentDate, lastReplyDate } = getLastSentAndReplyDates({ contactFlow });

  return [
    firstname,
    lastname,
    email,
    // currentCompanyName || '/',
    t(`profile.steps.pipe.${step}`, ''),
    linkedin || '',
    github || '',
    stackoverflow || '',
    twitter || '',
    dribbble || '',
    behance || '',
    website || '',
    // lastSentDate: lastSentDate || '/',
    // lastReplyDate: lastReplyDate || '/',
  ]
};

// const getCurrentCompanyName = ({ experiences }) => {
//   if (_.isEmpty(experiences)) {
//     return null;
//   }
//   return experiences[0]?.companyName;
// };

// const getLastSentAndReplyDates = ({ contactFlow }) => {
//   let lastSentDate = null;
//   let lastReplyDate = null;
//
//   if (_.isEmpty(contactFlow)) {
//     return { lastSentDate, lastReplyDate };
//   }
// };

export default profilesToCSV;