/* eslint-disable class-methods-use-this */
import {
  EvaluatorInterface,
  Variable,
  ExternalVariableEvaluator,
  BasicVariable,
} from '../../types';

export default class ExpressionSingleVariableEvaluator
  implements EvaluatorInterface {
  evaluate({
    expression,
    externalVariableEvaluator,
  }: {
    expression: Variable;
    externalVariableEvaluator: ExternalVariableEvaluator;
  }): { value: string | undefined } {
    const expressionType = (expression as unknown) as BasicVariable;
    return externalVariableEvaluator({ variableId: expressionType.variableId });
  }
}
