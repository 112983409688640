import _ from 'underscore';
import { TranslatableText } from '@/types/text';
import { getTranslatedText } from '.';

const getJobWishes = ({
  hiresweetData,
}: {
  hiresweetData?: {
    wishes?: {
      jobs?: {
        target: TranslatableText[];
      };
    };
  };
}): string => {
  const jobWishes = _.map(hiresweetData?.wishes?.jobs?.target || [], (job) =>
    getTranslatedText(job),
  );
  return _.first(_.compact(jobWishes), 3)
    .join(', ')
    .trim();
};

export default getJobWishes;
