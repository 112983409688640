import { MutationTuple, useMutation } from '@apollo/client';
import { mutation as CREATE_CLIENT_INTEGRATION } from '@/hocs/integrations/withAddClientIntegration';

type ClientIntegrationVariables = {
  type: string;
  mode: string;
  accountId?: string;
  token?: string;
};

type ClientIntegrationsResponse = {
  id: string;
  integrations?: any[];
};

const useCreateClientIntegration = (): MutationTuple<
  ClientIntegrationsResponse,
  ClientIntegrationVariables
> => {
  return useMutation(CREATE_CLIENT_INTEGRATION);
};

export default useCreateClientIntegration;
