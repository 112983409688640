import React, { useMemo } from 'react';
import { getRandomString } from '@/common';
import {
  getMergeTagsKLastCompaniesValue,
  MergeTagsVariable,
  VariableExamplePreviewLocale,
} from '@/common/mergeTags/utils';
import ExamplesPreview from '../ExamplesPreview';

const getText = (locale: string, variable: MergeTagsVariable): string => {
  if (!variable) {
    return '';
  }
  if (locale === 'en') {
    return 'The skills you developped at {#k#} can be ...';
  }
  if (locale === 'fr') {
    return 'Les compétences que vous avez développées chez {#k#} seront...';
  }
  return '';
};

const fakeProfileExperiences = [
  {
    companyName: 'Acme',
    startDate: {
      raw: '2019-07-15',
      month: 6,
      year: 2019,
    },
  },
  {
    companyName: 'PiedPiper',
    startDate: {
      raw: '2014-06-15',
      month: 5,
      year: 2014,
    },
    endDate: {
      raw: '2014-07-15',
      month: 6,
      year: 2014,
    },
  },
  {
    companyName: 'Wonka Industries',
    startDate: {
      raw: '2016-05-15',
      month: 4,
      year: 2016,
    },
    endDate: {
      raw: '2016-06-15',
      month: 5,
      year: 2016,
    },
  },
  {
    companyName: 'Bubba Gump',
    title: {
      text: 'Prospection téléphonique',
    },
    endDate: {
      raw: '2017-05-15',
      month: 4,
      year: 2017,
    },
  },
];

type Props = {
  locale: VariableExamplePreviewLocale;
  variable: MergeTagsVariable;
  numberOfPreviousCompanies: number;
};

const KLastCompaniesVariableExamples: React.FC<Props> = ({
  locale,
  variable,
  numberOfPreviousCompanies,
}) => {
  const kLastCompaniesExamples = useMemo(() => {
    const text = getText(locale, variable);
    return [
      {
        id: getRandomString(10),
        nbLastCompanies: numberOfPreviousCompanies,
        text,
        locale,
        experiences: fakeProfileExperiences,
      },
      {
        id: getRandomString(10),
        nbLastCompanies: numberOfPreviousCompanies,
        text,
        locale,
        experiences: [
          fakeProfileExperiences[0],
          fakeProfileExperiences[1],
          fakeProfileExperiences[2],
        ],
      },
      {
        id: getRandomString(10),
        nbLastCompanies: numberOfPreviousCompanies,
        text,
        locale,
        experiences: [fakeProfileExperiences[0], fakeProfileExperiences[1]],
      },
      {
        id: getRandomString(10),
        nbLastCompanies: numberOfPreviousCompanies,
        text,
        locale,
        experiences: [fakeProfileExperiences[0]],
      },
      {
        id: getRandomString(10),
        nbLastCompanies: numberOfPreviousCompanies,
        text,
        locale,
        experiences: [],
      },
    ];
  }, [variable, locale, numberOfPreviousCompanies]);

  return (
    <ExamplesPreview
      examples={kLastCompaniesExamples}
      calculationFunction={getMergeTagsKLastCompaniesValue}
      examplePreviewTranslationKey='editor.kLastCompaniesVariables.examples.subtitle'
    />
  );
};

export default KLastCompaniesVariableExamples;
