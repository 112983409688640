import React from 'react';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import classNames from 'classnames';
import { sentryCaptureException } from '../../../common';
import logAction from '../../../common/logAction';
import { DropdownMenuItem, MenuItem } from './Menu/MenuItem';

import './TopHeaderDropdown.css';

const logTopHeaderClick = ({
  name,
  clientId,
}: {
  name: string;
  clientId: string;
}): void => {
  try {
    logAction({
      type: `click-${name}`,
      info: {
        from: 'top-header',
        clientId,
      },
    });
  } catch (error) {
    sentryCaptureException({ error });
  }
};
interface TopHeaderDropdownProps {
  items: MenuItem[];
  active?: boolean;
  clientId: string;
  icon?: DropdownProps['icon'];
}

const TopHeaderDropdown: React.FC<TopHeaderDropdownProps> = ({
  items,
  active,
  children,
  clientId,
  icon,
}) => {
  let className = 'navigation-item-link';
  if (active) {
    className += ' active';
  }

  const flattenedItems = _.flatten(
    _.map(items, (item) =>
      item.isDropdown ? (item as DropdownMenuItem).items : [item],
    ),
  );

  const hasIcons = _.some(flattenedItems, (item) => !!item.icon);

  return (
    <div className={className}>
      <Dropdown
        trigger={children}
        className={classNames(
          'navigation-item',
          hasIcons && 'navigation-item--reveal',
        )}
        icon={icon}
        floating
      >
        <Dropdown.Menu>
          {flattenedItems.map((item) => (
            <Dropdown.Item
              key={item?.key}
              active={item?.active}
              className={classNames(
                'top-header-dropdown-item',
                hasIcons && 'top-header-dropdown-item--reveal',
              )}
              as={Link}
              to={item.to}
              onClick={() => {
                logTopHeaderClick({ clientId, name: item.pageId });
              }}
            >
              {item.text}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default TopHeaderDropdown;
