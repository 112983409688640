import { FC, useCallback, useMemo, useState } from 'react';

import { PageSelector } from '@/components/Common/Pagination';

interface UsePaginationResult<ListType> {
  pageElements: ListType[];
  numberOfPages: number;
  currentPageIndex: number;
  setCurrentPageIndex: (index: number) => void;
  PageSelector: FC;
}

const usePagination = <ListType>(
  elements: ListType[],
  elementsPerPage = 10,
): UsePaginationResult<ListType> => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const numberOfPages = useMemo(
    () => Math.ceil(elements?.length / elementsPerPage),
    [elements, elementsPerPage],
  );
  const pageElements = useMemo(
    () =>
      elements.slice(
        currentPageIndex * elementsPerPage,
        (currentPageIndex + 1) * elementsPerPage,
      ),
    [elements, currentPageIndex, elementsPerPage],
  );

  const SimplifiedPageSelector = useCallback(
    () =>
      PageSelector({
        numberOfPages,
        currentPageIndex,
        onChange: setCurrentPageIndex,
      }),
    [numberOfPages, currentPageIndex, setCurrentPageIndex],
  );

  return {
    pageElements,
    numberOfPages,
    currentPageIndex,
    setCurrentPageIndex,
    PageSelector: SimplifiedPageSelector,
  };
};

export default usePagination;
