import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { RemotePolicy } from '@/types/remotePolicy';
import InfoTooltip from '../Common/InfoTooltip';

import styles from './RemotePolicyPill.module.less';

interface RemotePolicyPillProps {
  remotePolicy: RemotePolicy;
  selected: boolean;
  onClick?: () => void;
}

const RemotePolicyPill: FC<RemotePolicyPillProps> = ({
  remotePolicy,
  selected,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <button
      type='button'
      className={classNames(styles.pill, selected && styles.selected)}
      onClick={onClick}
      disabled={onClick === undefined}
    >
      <span className={styles.name}>
        {t(`criteria.remote.${remotePolicy}`)}
        <InfoTooltip>
          {t(`criteria.remoteTooltips.${remotePolicy}`)}
        </InfoTooltip>
      </span>
    </button>
  );
};

export default RemotePolicyPill;
