import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';

import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useCreateClientIntegration from '@/graphql/hooks/clients/integrations/useCreateClientIntegration';
import useCreateClientConnector from '@/graphql/hooks/clients/integrations/useCreateClientConnector';
import GenericButton from '@/components/Common/GenericButton';

interface TokenAndAccountIdCredentialsFormProps {
  type: string;
  reveal?: boolean;
}

const TokenAndAccountIdCredentialsForm: React.FC<TokenAndAccountIdCredentialsFormProps> = ({
  type,
  reveal,
}) => {
  const [token, setToken] = React.useState('');
  const [accountId, setAccountId] = React.useState('');
  const [inputError, setInputError] = React.useState(false);
  const notifications = useNotificationSystem();
  const { t } = useTranslation();
  const [addClientIntegration] = useCreateClientIntegration();
  const [addClientConnector] = useCreateClientConnector();

  const addClientIntegrationOrConnector = reveal
    ? (args: any) =>
        addClientConnector({
          ...args,
          variables: { input: { ...args.variables } },
        })
    : addClientIntegration;

  const handleSave = async () => {
    try {
      await addClientIntegrationOrConnector({
        variables: {
          type,
          mode: 'token',
          token,
          accountId,
        },
      });
      notifications.success(t('common.genericSuccess'));
    } catch (e) {
      console.error('e :', e);
      setInputError(true);
      notifications.error(t('common.genericError'));
    }
  };

  const onChangeToken = (e: React.FormEvent<HTMLInputElement>) => {
    setToken((e.currentTarget.value || '').trim());
    setInputError(false);
  };

  const onChangeAccountId = (e: React.FormEvent<HTMLInputElement>) => {
    setAccountId((e.currentTarget.value || '').trim());
    setInputError(false);
  };

  return (
    <div>
      <div className='input-container'>
        <div className='input-label'>
          {t('integrations.connection.subdomainHeader')}
        </div>
        <div className='input-element'>
          <Input
            placeholder={t('integrations.connection.subdomainPlaceholder')}
            value={accountId}
            onChange={onChangeAccountId}
            error={inputError}
          />
          <span>&nbsp;&nbsp;.{type}.com</span>
        </div>
      </div>
      <div className='input-container'>
        <div className='input-label'>
          {t('integrations.connection.tokensHeader')}
        </div>
        <div className='input-element'>
          <Input
            placeholder={t('integrations.connection.tokensPlaceholder')}
            value={token}
            onChange={onChangeToken}
            fluid
            error={inputError}
          />
        </div>
      </div>
      <div className='input-container'>
        <GenericButton
          type='submit'
          onClick={handleSave}
          disabled={_.isEmpty(token) || _.isEmpty(accountId)}
        >
          {t('integrations.connection.save')}
        </GenericButton>
      </div>
    </div>
  );
};

export default TokenAndAccountIdCredentialsForm;
