/* eslint-disable max-len */

import classNames from 'classnames';
import React, { FC } from 'react';

import globalStyles from './svg.module.less';
import styles from './Star.module.less';

interface StarProps {
  className: string;
}

const Star: FC<StarProps> = ({ className }) => (
  <svg
    className={globalStyles.svgIcon}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      className={classNames(styles.star, className)}
      d='M11.3172 3.5021C11.5837 2.91591 12.4163 2.91591 12.6828 3.5021L14.9487 8.48703C15.058 8.72761 15.2859 8.89289 15.5486 8.92209L20.4207 9.46375C21.0507 9.53378 21.3151 10.3051 20.8606 10.7469L17.2785 14.2293C17.1038 14.3991 17.0225 14.6431 17.0604 14.8837L17.9292 20.4042C18.0284 21.0347 17.3449 21.4931 16.7993 21.162L12.3891 18.4861C12.15 18.341 11.85 18.341 11.6109 18.4861L7.20074 21.162C6.65508 21.4931 5.97158 21.0347 6.0708 20.4042L6.93962 14.8837C6.97749 14.6431 6.89616 14.3991 6.72153 14.2293L3.13935 10.7469C2.6849 10.3051 2.94935 9.53378 3.57927 9.46375L8.45144 8.92209C8.71409 8.89289 8.94199 8.72761 9.05135 8.48703L11.3172 3.5021Z'
      strokeWidth='1.5'
    />
  </svg>
);

export default Star;
