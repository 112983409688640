import gql from 'graphql-tag';
import SearchCriteria from './SearchCriteria';

export default gql`
  fragment SavedSearch on SavedSearch {
    id
    searchPoolId
    name
    criteria {
      ...SearchCriteria
    }
    author {
      firstname
      lastname
    }
  }
  ${SearchCriteria}
`;
