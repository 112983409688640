import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import GenericTooltip from '../Common/GenericTooltip';

import styles from './DefaultTemplateFieldsNudge.module.less';

const DefaultTemplateFieldsNudge: FC = () => {
  const { t } = useTranslation();
  return (
    <GenericTooltip
      position='top center'
      trigger={
        <span className={styles.nudge}>
          <span className={styles.blinker} />
          <span className={styles.fill} />
        </span>
      }
    >
      {t('offers.sequences.unmodified')}
    </GenericTooltip>
  );
};

export default DefaultTemplateFieldsNudge;
