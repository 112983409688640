import React, { FC, MouseEvent } from 'react';
import { ProfileWithAvatar } from '@/types/profile';
import classNames from 'classnames';

import styles from './OfferProfileColumn.module.less';
import AvatarGroup from '../AvatarGroup';

interface OfferProfileColumnProps {
  count: number;
  profiles: ProfileWithAvatar[];
  remainder: number;
  onClick: (event: MouseEvent) => void;
}

const OfferProfileColumn: FC<OfferProfileColumnProps> = ({
  count,
  profiles,
  remainder,
  onClick,
}) => {
  return (
    <button
      type='button'
      className={classNames(styles.column, {
        [styles.notEmpty]: count > 0,
      })}
      onClick={onClick}
    >
      <span className={styles.count}>{count}</span>
      <AvatarGroup profiles={profiles} remainder={remainder} />
    </button>
  );
};

export default OfferProfileColumn;
