import _ from 'underscore';
import { useCallback, useMemo } from 'react';
import { getFullname } from '@/common/helpers/person';
import {
  GenericViewType,
  useUserSelectorPreferences,
} from '@/graphql/hooks/users/useUserDisplayPreferences';

export type SortKeys = 'author' | 'name' | 'creation-date' | 'last-use-by-me';
export type FilterKeys = 'created-by-me' | 'all-projects';
export type GroupKeys = 'departments' | 'author' | 'not-grouped';

export type Project = {
  id: string;
  data?: {
    title: string;
  };
  creationDate: string;
  lastUseByAuthor: {
    authorEmail: string;
    timestamp: number;
  }[];
  owner?: {
    firstname: string;
    lastname: string;
    email: string;
  };
  foldering?: {
    department: {
      id: string;
    };
    section: {
      id: string;
    };
    subsection: {
      id: string;
    };
  };
};

export type FilterFunction = (projects: Project[], context?: any) => Project[];
export type SortFunction = (projects: Project[], context?: any) => Project[];
export type SortCardinal = (projectA: Project, projectB: Project) => number;

export const PROJECT_FILTER_FUNCTIONS: Record<FilterKeys, FilterFunction> = {
  'created-by-me': (projects, context) => {
    const user = context?.currentUser?.user;
    if (!user?.email) {
      return projects;
    }

    return _.filter(projects, (project) => {
      if (!project.owner) {
        return false;
      }

      const {
        owner: { email },
      } = project;
      return email === user.email;
    });
  },
  'all-projects': (projects) => projects,
};

const authorName = (project: Project): string => {
  if (!project.owner) {
    return '';
  }

  return getFullname(project.owner);
};

export const PROJECT_SORT_CARDINALS: Record<Partial<SortKeys>, SortCardinal> = {
  author: (p1, p2) => {
    const p1AuthorName = authorName(p1);
    const p2AuthorName = authorName(p2);

    if (!p1AuthorName) {
      return -1;
    }

    if (!p2AuthorName) {
      return 1;
    }

    return authorName(p1).localeCompare(authorName(p2));
  },
  name: (p1, p2) => {
    if (!p1.data?.title) {
      return -1;
    }

    if (!p2.data?.title) {
      return 1;
    }

    return p1.data.title.localeCompare(p2.data.title);
  },
  'creation-date': (p1, p2) => {
    const p1CreationDate: Date = new Date(p1.creationDate);
    const p2CreationDate: Date = new Date(p2.creationDate);
    return p2CreationDate.getTime() - p1CreationDate.getTime();
  },
};

export const PROJECT_SORT_FUNCTIONS: Record<SortKeys, SortFunction> = {
  author: (projects) => {
    return projects.sort(PROJECT_SORT_CARDINALS.author);
  },
  name: (projects) => {
    return projects.sort(PROJECT_SORT_CARDINALS.name);
  },
  'creation-date': (projects) => {
    return projects.sort(PROJECT_SORT_CARDINALS['creation-date']);
  },
  'last-use-by-me': (projects, context) => {
    const { currentUser } = context || {};
    if (!currentUser || !currentUser.user || !currentUser.user.email) {
      return projects;
    }

    const {
      user: { email },
    }: {
      user: {
        email: string;
      };
    } = currentUser;

    return projects.sort((p1, p2) => {
      const lastUsedByMeP1 = _.findWhere(p1.lastUseByAuthor, {
        authorEmail: email,
      });

      const lastUsedByMeP2 = _.findWhere(p2.lastUseByAuthor, {
        authorEmail: email,
      });

      if (!lastUsedByMeP1 && !lastUsedByMeP2) {
        return 0;
      }

      if (lastUsedByMeP1 && !lastUsedByMeP2) {
        return -1;
      }

      if (lastUsedByMeP2 && !lastUsedByMeP1) {
        return 1;
      }

      if (lastUsedByMeP1 && lastUsedByMeP2) {
        return lastUsedByMeP2.timestamp - lastUsedByMeP1.timestamp;
      }

      return 0;
    });
  },
};

export const useProjectDropdownPreferences = ({
  selectorId = 'project-selector',
  defaultValues = {},
}: {
  selectorId?: string;
  defaultValues?: GenericViewType;
} = {}) => {
  const {
    userSelectorPreferences,
    updateUserSelectorPreferences,
    loading,
  } = useUserSelectorPreferences({
    selectorId,
    defaultValues: {
      selectorId,
      filteringOptions: {
        filters: [
          {
            key: 'all-projects',
            filter: {},
          },
        ],
      },
      sortingOptions: {
        sortBy: [
          {
            key: 'creation-date',
            order: 'descending',
          },
        ],
      },
      groupingOptions: {
        groupBy: [
          {
            key: 'not-grouped',
            group: 'not-grouped',
          },
          {
            key: 'departments',
            group: 'departments',
          },
        ],
      },
      selectedItemIds: [],
      ...defaultValues,
    },
  });

  const sortKey = useMemo<SortKeys>(() => {
    if (!userSelectorPreferences) {
      return 'creation-date';
    }

    const userSortKey = userSelectorPreferences.sortingOptions?.sortBy[0]
      ?.key as SortKeys;

    // Key is not supported, could be a migration
    if (userSortKey && !(userSortKey in PROJECT_SORT_FUNCTIONS)) {
      return 'creation-date' as SortKeys;
    }

    return userSortKey || 'creation-date';
  }, [userSelectorPreferences]);

  const filterKey = useMemo<FilterKeys>(() => {
    if (!userSelectorPreferences) {
      return 'all-projects';
    }

    const userFilterKey = userSelectorPreferences.filteringOptions?.filters[0]
      ?.key as FilterKeys;

    if (userFilterKey && !(userFilterKey in PROJECT_FILTER_FUNCTIONS)) {
      return 'all-projects';
    }

    return userFilterKey || 'all-projects';
  }, [userSelectorPreferences]);

  const groupKey = useMemo<GroupKeys>(() => {
    if (!userSelectorPreferences) {
      return 'not-grouped';
    }
    const userGroupKey = userSelectorPreferences.groupingOptions?.groupBy[0]
      ?.key as GroupKeys;

    return userGroupKey || 'not-grouped';
  }, [userSelectorPreferences]);

  const updateSortKey = useCallback(
    (key) => {
      updateUserSelectorPreferences({
        sortingOptions: {
          sortBy: [
            {
              key: key || 'creation-date',
              order: 'descending',
            },
          ],
        },
      });
    },
    [updateUserSelectorPreferences],
  );

  const updateFilterKey = useCallback(
    (key) => {
      updateUserSelectorPreferences({
        filteringOptions: {
          filters: [
            {
              key: key || 'all-projects',
              filter: {},
            },
          ],
        },
      });
    },
    [updateUserSelectorPreferences],
  );

  const updateGroupKey = useCallback(
    (key) => {
      updateUserSelectorPreferences({
        groupingOptions: {
          groupBy: [
            {
              key: key || 'not-grouped',
              group: key,
            },
          ],
        },
      });
    },
    [updateUserSelectorPreferences],
  );

  const updateSelectedItems = useCallback(
    (selectedItemIds) => {
      updateUserSelectorPreferences({
        ...userSelectorPreferences,
        selectedItemIds,
      });
    },
    [updateUserSelectorPreferences, userSelectorPreferences],
  );

  const selectedItemIds = useMemo(() => {
    return userSelectorPreferences?.selectedItemIds || [];
  }, [userSelectorPreferences]);

  return {
    filterKey,
    sortKey,
    groupKey,
    updateFilterKey,
    updateSortKey,
    updateGroupKey,
    selectedItemIds,
    updateSelectedItems,
    loading,
  };
};
