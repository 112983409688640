import React from 'react';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import CommentEditor from './Editor';

import './CommentDraft.css';

const createStateFromProfile = (profile: any): {
  initialComment: string;
  comment: string;
  version?: number;
} => {
  const contactData = profile ? profile.contactData : undefined;
  const comment =
    contactData && contactData.draftComment ? contactData.draftComment : '';
  return {
    initialComment: comment,
    comment,
  };
};

class CommentDraft extends React.PureComponent<
  {
    onDraftSave: (value: any) => void;
    profileId: number;
    t: TFunction;
  },
  {
    initialComment: string;
    comment: string;
    version?: number;
  }
> {
  currentProfileId: any;

  constructor(props: any) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = createStateFromProfile(props.profile);
    this.currentProfileId = props.profileId;
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.profileId !== this.currentProfileId) {
      this.currentProfileId = nextProps.profileId;
      const update = createStateFromProfile(nextProps.profile);
      this.setState(update);
    }
  }

  handleChange(comment: string) {
    this.setState({ comment });
  }

  handleSubmit = async () => {
    const { onDraftSave } = this.props;
    const { comment } = this.state;

    this.setState({
      initialComment: '',
      comment: '',
      version: Date.now(),
    });
    await onDraftSave(comment);
  };

  render() {
    const { t } = this.props;
    const { initialComment, version } = this.state;
    return (
      <div className='hsCommentDraft'>
        <CommentEditor
          defaultValue={initialComment}
          onChange={this.handleChange}
          placeholder={t('editor.placeholder.commentBody')}
          handleSubmit={this.handleSubmit}
          version={version} // resets editor when comment is submitted
          disableSubmit={false} // TODO
          alwaysShowMenu
        />
      </div>
    );
  }
}
export default withTranslation('translations')(CommentDraft);
