import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';

import GenericButton from '@/components/Common/GenericButton';
import withUserSenders from '../../../../hocs/users/withUserSenders';
import OwnedSendersTable from './OwnedSendersTable';
import GrantedSendersTable from './GrantedSendersTable';
import NewSenderModal from './NewSenderModal';
import EditSenderModal from './EditSenderModal';
import DeleteSenderModal from './DeleteSenderModal';
import { randomId } from './helpers';

import './SenderSettings.css';

const SenderSettings = ({ clientId, user, t }) => {
  const [addModalOpenId, setAddModalOpen] = useState(false);
  const [editModalOpenId, setEditModalOpen] = useState(false);
  const [deleteModalOpenId, setDeleteModalOpen] = useState(false);

  const { senders, email } = user || {};

  const ownedSenders = _.filter(senders, (sender) => {
    return sender?.owner?.email === email;
  });

  const grantedSenders = _.filter(senders, (sender) => {
    return (
      _.findWhere(sender?.users, { email }) && sender?.owner?.email !== email
    );
  });

  const onEditSender = ({ senderId }) => {
    setEditModalOpen(senderId);
  };

  const onDeleteSender = ({ senderId }) => {
    setDeleteModalOpen(senderId);
  };

  const senderToEdit =
    editModalOpenId && _.findWhere(ownedSenders, { id: editModalOpenId });

  const senderToDelete =
    deleteModalOpenId && _.findWhere(ownedSenders, { id: deleteModalOpenId });

  return (
    <div className='senders-section'>
      <div className='settings-header'>
        <h1>{t('header.accountDropdownMenu.sendersSettings')}</h1>
      </div>
      <h2>{t('settings.configuration')}</h2>
      <div className='section visible'>
        <div className='input-container'>
          <div className='input-label'>
            {t('settings.senders.sharedAccounts')}
          </div>
          <div className='input-description'>
            {t('settings.senders.details')}
          </div>

          <div className='senders-settings-given'>
            <OwnedSendersTable
              senders={ownedSenders}
              onEditSender={onEditSender}
              withEdit
              onDeleteSender={onDeleteSender}
            />
          </div>
          <GenericButton onClick={() => setAddModalOpen(randomId())}>
            {t('settings.senders.addButton')}
          </GenericButton>

          <NewSenderModal
            // key to create new modal each time
            key={addModalOpenId}
            clientId={clientId}
            ownerEmail={email}
            closeModal={() => setAddModalOpen(false)}
            open={!!addModalOpenId}
          />
          <EditSenderModal
            key={(senderToEdit || {}).id || 'sender-modal'}
            clientId={clientId}
            ownerEmail={email}
            sender={senderToEdit}
            closeModal={() => setEditModalOpen(false)}
            open={!!editModalOpenId}
          />
          <DeleteSenderModal
            sender={senderToDelete}
            clientId={clientId}
            closeModal={() => setDeleteModalOpen(false)}
            open={!!senderToDelete}
          />
        </div>
        {!_.isEmpty(grantedSenders) && (
          <div className='senders-settings-granted input-container'>
            <div className='input-label'>
              {t('settings.senders.accountsSharedWithYou')}
            </div>
            <div className='input-description'>
              {t('settings.senders.grantedDetails')}
            </div>

            <GrantedSendersTable senders={grantedSenders} />
          </div>
        )}
      </div>
    </div>
  );
};

export default compose(
  withTranslation('translations'),
  withUserSenders,
)(SenderSettings);
