import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { Department } from '../../graphql/fragments/Department';

export const addClientDepartment = gql`
  mutation addClientDepartment($department: ClientDepartmentInput!) {
    addClientDepartment(department: $department) {
      id
      departments {
        ...Department
      }
    }
  }
  ${Department}
`;

export default graphql(addClientDepartment, {
  props: ({ mutate }) => ({
    addClientDepartment: ({ department }) =>
      mutate({
        variables: {
          department,
        },
      }),
  }),
});
