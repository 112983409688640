import React, { FC, useMemo } from 'react';
import { Dropdown } from 'semantic-ui-react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import {
  SearchCriteria,
  IncludeProfilesInJob,
  includeProfilesInJobValues,
} from '@/types/search';

import styles from './CandidatesInJobDropdown.module.less';

interface CandidatesInJobDropdownProps {
  filtersState: {
    values: SearchCriteria;
    setIncludeProfilesInJob: (value: IncludeProfilesInJob) => void;
  };
}

const CandidatesInJobDropdown: FC<CandidatesInJobDropdownProps> = ({
  filtersState,
}) => {
  const { t } = useTranslation();
  const selectedValue =
    filtersState.values.includeProfilesInJob || 'pending-only';

  const optionsArray = useMemo(
    () =>
      _.map(includeProfilesInJobValues, (value) => ({
        text: t(`watchCollect.candidatesInJobDropdown.${value}`),
        value,
      })),
    [t],
  );

  return (
    <Dropdown
      selection
      value={selectedValue}
      options={optionsArray}
      onChange={(_e, { value }) =>
        filtersState.setIncludeProfilesInJob(value as IncludeProfilesInJob)
      }
      className={styles.dropdown}
    />
  );
};

export default CandidatesInJobDropdown;
