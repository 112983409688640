import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { literalTypeChecker } from '@/common/utils/types';
import { useMemo } from 'react';

const GET_INSTANT_FLOW_STATUS = gql`
  query getInstantFlowStatus($id: ID!) {
    offer(id: $id) {
      id
      instantFlowPipeDescriptor {
        uploadStatus
      }
    }
  }
`;

interface GetInstantFlowStatusData {
  offer: {
    __typename: 'Offer';
    id: string;
    instantFlowPipeDescriptor?: {
      __typename: 'InstantFlowPipeDescriptor';
      uploadStatus: string;
    };
  };
}

interface GetInstantFlowStatusVariables {
  id: string;
}

const instantFlowStatuses = ['pending', 'success', 'error'] as const;
const isInstantFlowStatus = literalTypeChecker(instantFlowStatuses);

export type InstantFlowStatus = typeof instantFlowStatuses[number];

interface UseInstantFlowStatusInput extends GetInstantFlowStatusVariables {
  queryOptions?: QueryHookOptions<
    GetInstantFlowStatusData,
    GetInstantFlowStatusVariables
  >;
}

interface UseInstantFlowStatusResult
  extends QueryResult<GetInstantFlowStatusData, GetInstantFlowStatusVariables> {
  instantFlowStatus?: InstantFlowStatus;
}

function useInstantFlowStatus({
  id,
  queryOptions = {},
}: UseInstantFlowStatusInput): UseInstantFlowStatusResult {
  const query = useQuery<
    GetInstantFlowStatusData,
    GetInstantFlowStatusVariables
  >(GET_INSTANT_FLOW_STATUS, { ...queryOptions, variables: { id } });

  const rawStatus = query.data?.offer.instantFlowPipeDescriptor?.uploadStatus;

  const instantFlowStatus = useMemo(() => coerceRawStatus(rawStatus), [
    rawStatus,
  ]);

  return {
    ...query,
    instantFlowStatus,
  };
}

function coerceRawStatus(rawStatus?: string): InstantFlowStatus | undefined {
  if (!rawStatus) {
    return undefined;
  }
  return isInstantFlowStatus(rawStatus) ? rawStatus : 'error';
}

export default useInstantFlowStatus;
