import React from 'react';
import _ from 'underscore';
import './Sources.css';

const SourceInnerBlock = ({ sourceId, t }) => {
  if (sourceId === 'website' || sourceId === 'githubIo' || sourceId === 'aboutMe') {
    return <span className={`source-link-inner ${sourceId}`}>{t('profile.resume.website')}</span>;
  }
  return (
    <img
      className={`source-link-inner ${sourceId}`}
      src={`/images/contact_logos/logolink_${sourceId}.svg`}
      alt={sourceId}
    />
  );
};

const cleanLink = (url) => (url.match(/^https?:\/\//i) ? url : `https://${url}`);

const Source = ({ source, onLogAction, type, from, t, profileId, searchPoolProfileId }) => {
  const { sourceId, url } = source;
  // fix some bug coming from the back where linkedin ID is None
  if (sourceId === 'linkedin' && _.isString(url) && url.includes('/in/None')) {
    return null;
  }
  return (
    <div className='source-link'>
      <a
        href={cleanLink(url)}
        target='_blank'
        rel='noreferrer noopener'
        onClick={() =>
          onLogAction({
            ...(profileId && { profileId }),
            ...(searchPoolProfileId && { searchPoolProfileId }),
            type: `click-${type || 'workplace'}-source`, // type: ('workplace' | 'profile')
            info: {
              sourceId,
              ...(from && { from }),
              ...(searchPoolProfileId && { searchPoolProfileId }),
            },
          })
        }
      >
        <SourceInnerBlock sourceId={sourceId} t={t} />
      </a>
    </div>
  );
};

export default Source;
