import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import ContactFlow from '../../graphql/fragments/ContactFlow';
import OfferRecruiterConfiguration from '../../graphql/fragments/OfferRecruiterConfiguration';

export const getOfferContactFlow = gql`
  query getOfferContactFlow($id: ID!) {
    offer(id: $id) {
      id
      title
      contactFlow {
        ...ContactFlow
      }
      recruiterConfigurations {
        ...OfferRecruiterConfiguration
      }
    }
  }
  ${ContactFlow}
  ${OfferRecruiterConfiguration}
`;

export default graphql(getOfferContactFlow, {
  options: ({ offerId }) => ({
    variables: { id: offerId },
  }),
  props: ({ data: { loading, error, offer } }) => ({
    loading,
    error,
    offer,
  }),
});
