import _ from 'underscore';
import { ATS_NAMES_BY_TYPE } from '@/common/reveal';
import { isEmailAction } from '@/common/constants/taskTypes';

const formatActionAsTimelineItem = (action) => {
  const date = new Date(action?.completion?.completionDate).valueOf();
  return {
    date: date ? `${date}` : null,
    author: null,
    action,
  };
};

export const getContactFlowEmails = ({ contactFlow }) => {
  const completedEmailItems = [];
  _.each(contactFlow?.sequences, ({ actions }) => {
    _.each(actions, (action) => {
      if (isEmailAction(action?.type) && action?.completion?.isCompleted) {
        completedEmailItems.push(formatActionAsTimelineItem(action));
      }
    });
  });
  return completedEmailItems;
};

export const getTimelineWithoutEmails = ({ timeline }) => {
  const nonEmailItems = [];
  _.each(timeline, (item) => {
    if (
      !isEmailAction(item?.action) &&
      !isEmailAction({ type: item?.action?.subtype })
    ) {
      nonEmailItems.push(item);
    }
  });
  return nonEmailItems;
};

export const getSynchronizedATSNames = ({ atsSynchronizations }) =>
  _.compact(
    _.map(
      atsSynchronizations || [],
      ({ connector }) => connector?.type && ATS_NAMES_BY_TYPE[connector?.type],
    ),
  ).join(', ');
