import { formatToYyyyMmDd } from '../dates';

const MILLISECONDS_IN_A_DAY = 86400000;

export const getMillisecondsFromDays = (days: number): number =>
  MILLISECONDS_IN_A_DAY * days;

export const differenceInDays = (endDate: Date, startDate: Date) =>
  Math.ceil((endDate.getTime() - startDate.getTime()) / MILLISECONDS_IN_A_DAY);

/**
 * Return true if a string represents today's date in YYYY-MM-DD format.
 * @param date the string to test
 */
export function isTodayYyyyMmDd(date?: string): boolean {
  const todayYyyyMmDd = new Date().toISOString().split('T')[0];
  return date === todayYyyyMmDd;
}

export function isoWithoutTimezone(
  referenceDate?: Date | string | number,
): string {
  const date = referenceDate ? new Date(referenceDate) : new Date();
  const year = date.getFullYear();
  const month = numberLeftPad(date.getMonth() + 1);
  const day = numberLeftPad(date.getDate());
  const hours = numberLeftPad(date.getHours());
  const minutes = numberLeftPad(date.getMinutes());
  const seconds = numberLeftPad(date.getSeconds());
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

const numberLeftPad = (number: number) => `00${number}`.slice(-2);

export type SweetDateInput = {
  raw: string;
  month: number;
  year: number;
};

export const getSweetDateInput = (date: Date): SweetDateInput => ({
  raw: formatToYyyyMmDd(date),
  month: date.getMonth(),
  year: date.getFullYear(),
});

export default {};
