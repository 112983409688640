import gql from 'graphql-tag';

export type SearchPoolProfileLastEvent = {
  id: string;
  lastEventTimestamp: string;
};

export type SearchPoolProfileLastEventResult = {
  searchPool: {
    id: string;
    profile: SearchPoolProfileLastEvent;
  };
};

export const SEARCH_POOL_PROFILE_LAST_EVENT = gql`
  query getSearchPoolProfileLastEvent($searchPoolId: ID!, $profileId: ID!) {
    searchPool(id: $searchPoolId) {
      id
      profile(id: $profileId) {
        id
        lastEventTimestamp
      }
    }
  }
`;

export default SEARCH_POOL_PROFILE_LAST_EVENT;
