import React, { useState, useEffect } from 'react';
import _ from 'underscore';
import { Loader } from 'semantic-ui-react';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import { useClientCurrentAtsId } from '@/graphql/hooks/clients/useClientRevealProjects';
import Playgrounds from '@/containers/Playgrounds';
import useUserFromJWToken from '../../graphql/hooks/users/useUserFromJWToken';

import RevealAnalytics from './RevealAnalytics';
import RevealSetupModal from './RevealSetupModal';
import SearchView from './SearchView';
import TasksView from './TasksView';
import PluginModal from './revealComponents/PluginModal';
import AutomationsView from './AutomationsView/AutomationsView';
import MergeTagsPlaygroundView from './MergeTagsPlaygroundView';
import CampaignsView from './CampaignsView/CampaignsView';
import WidgetPlayground from './WidgetPlayground';
import Overview from './Overview';
import ProjectsRouter from './ProjectsRouter';
import CompaniesView from './CompaniesView';

import './RevealProject.css';
import InboxView from './InboxView';

const RevealProject = ({
  clientId,
  revealProject,
  startPollingRevealProjects,
  stopPollingRevealProjects,
}) => {
  // useOpenReplay();
  const firstConnector = revealProject?.connectors?.[0];
  const { initializationStarted, initializationCompleted } =
    firstConnector?.synchronizationStatus || {};
  const [modalOpen, setModalOpen] = useState(!initializationStarted);
  const [pollingStarted, setPollingStarted] = useState(false);
  const { data: userData } = useUserFromJWToken();

  const atsId = useClientCurrentAtsId();

  const onCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (initializationStarted && !initializationCompleted && !pollingStarted) {
      startPollingRevealProjects(5 * 1000);
      setPollingStarted(true);
    }
    return () => {
      if (pollingStarted) {
        stopPollingRevealProjects();
      }
    };
    // eslint-disable-next-line
  }, [initializationStarted, initializationCompleted, pollingStarted]);

  // When user closes modal without initializing
  if (!modalOpen && !initializationStarted) {
    return <Redirect to={`/client/${clientId}/dashboard`} />;
  }

  // Open setup modal
  if (!initializationStarted) {
    return (
      <div className='reveal-view'>
        <div className='reveal-project'>
          <RevealSetupModal
            revealProjectId={revealProject?.id}
            connectorId={firstConnector?.id}
            open={modalOpen}
            onClose={onCloseModal}
            skipFirstStep={!!firstConnector}
          />
        </div>
      </div>
    );
  }

  // Setup in progress
  if (!initializationCompleted) {
    return (
      <div className='reveal-view'>
        <div className='reveal-project synchronizing'>
          <div className='synchronizing'>
            <h3 className='loader-text'>
              Synchronizing Greenhouse data...
              <br />
              This may take several hours.
            </h3>
            <Loader active inline='centered' size='large' />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Switch>
        <Route exact path='/client/:clientId/reveal/search'>
          <div className='reveal-view'>
            <SearchView clientId={clientId} revealProject={revealProject} />
          </div>
        </Route>
        <Route exact path='/client/:clientId/reveal/companies'>
          <CompaniesView currentTab='contacts' />
        </Route>

        <Route exact path='/client/:clientId/reveal/companies/overview'>
          <CompaniesView currentTab='overview' />
        </Route>

        <Route exact path='/client/:clientId/reveal/merge-tags/playground'>
          <MergeTagsPlaygroundView />
        </Route>

        <Route path='/client/:clientId/reveal/tasks'>
          {userData?.user?.email && (
            <TasksView
              clientId={clientId}
              revealProject={revealProject}
              userEmail={userData?.user?.email}
              isAdmin={userData?.user?.isAdmin}
            />
          )}
        </Route>

        <Route path='/client/:clientId/reveal/dashboard'>
          <Redirect to={`/client/${clientId}/reveal/projects`} />
        </Route>

        <Route path='/client/:clientId/reveal/analytics/:tab'>
          <RevealAnalytics />
        </Route>

        <Route path='/client/:clientId/reveal/analytics'>
          <Redirect
            to={`/client/${clientId}/reveal/analytics/${
              atsId === 'adventure' ? 'adventure1' : 'missions'
            }`}
          />
        </Route>

        <Route path='/client/:clientId/reveal/sequences'>
          <AutomationsView tab='sequences' />
        </Route>

        <Route path='/client/:clientId/reveal/templates'>
          <AutomationsView tab='templates' />
        </Route>

        <Route path='/client/:clientId/reveal/campaigns'>
          <AutomationsView tab='campaigns' />
        </Route>

        <Route path='/client/:clientId/reveal/pages'>
          <CampaignsView tab='pages' />
        </Route>

        <Route path='/client/:clientId/reveal/forms'>
          <CampaignsView tab='forms' />
        </Route>

        <Route path='/client/:clientId/reveal/playground/widget'>
          <WidgetPlayground />
        </Route>

        <Route path='/client/:clientId/reveal/playgrounds'>
          <Playgrounds />
        </Route>

        <Route path='/client/:clientId/reveal/inbox'>
          <InboxView />
        </Route>

        <Route path='/client/:clientId/reveal/overview'>
          <Overview />
        </Route>

        <Route path='/client/:clientId/reveal/projects'>
          <ProjectsRouter revealProject={revealProject} />
        </Route>

        <Route
          exact
          path='/client/:clientId/reveal/missions'
          render={({ match }) => (
            <Redirect
              to={generatePath(
                '/client/:clientId/reveal/projects',
                match.params,
              )}
            />
          )}
        />
        <Route
          exact
          path='/client/:clientId/reveal/missions/:missionCategory'
          render={({ match }) => (
            <Redirect
              to={generatePath(
                '/client/:clientId/reveal/projects/:missionCategory',
                match.params,
              )}
            />
          )}
        />
        <Route
          exact
          path='/client/:clientId/reveal/missions/:missionCategory/:projectId'
          render={({ match }) => (
            <Redirect
              to={generatePath(
                '/client/:clientId/reveal/projects/:missionCategory/:projectId',
                match.params,
              )}
            />
          )}
        />
        <Route
          exact
          path='/client/:clientId/reveal/missions/:missionCategory/:projectId/:tabId'
          render={({ match }) => (
            <Redirect
              to={generatePath(
                '/client/:clientId/reveal/projects/:missionCategory/:projectId/:tabId',
                match.params,
              )}
            />
          )}
        />

        <Route
          path='/client/:clientId/reveal/:tabId'
          // TODO: quick fix for old reveal URLs: redirect to /tasks by default
          render={({ match }) => (
            <Redirect
              to={generatePath('/client/:clientId/reveal/tasks', {
                ...match.params,
                tabId: 'tasks',
              })}
            />
          )}
        />
      </Switch>
      <PluginModal clientId={clientId} />
    </>
  );
};

export default RevealProject;
