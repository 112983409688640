import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import CohortsDataContainer from '../../Analytics/components/Cohorts/CohortsDataContainer';
import { frequencyOptions } from './helpers';

const Cohorts = ({ clientId, offerId, t }) => {
  const [frequency, setFrequency] = useState(null);

  return (
    <div className='analytics-cohorts section'>
      <h3>{t('analytics.headers.cohorts')}</h3>
      <div className='cohorts-body-container'>
        <div className='twin-selector-container'>
          <div className='twin-selector-left'>
            <div className='twin-selector-label'>{t('analytics.dropdownLabels.frequency')}</div>
            <div className='twin-selector-dropdown'>
              <Dropdown
                fluid
                selection
                onChange={(e, { value }) => setFrequency(value)}
                value={frequency}
                options={frequencyOptions({ t })}
              />
            </div>
          </div>
        </div>
        <div className='analytics-cohorts-container'>
          <CohortsDataContainer
            clientId={clientId}
            offerIds={[offerId]}
            frequency={frequency}
            setFrequency={({ frequency: newFrequency }) => setFrequency(newFrequency)}
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslation('translations')(Cohorts);
