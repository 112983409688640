import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import Snippet from '@/graphql/fragments/Snippet';
import MessageFragment from '../../graphql/fragments/Message';
import Author from '../../graphql/fragments/Author';

export const mutation = gql`
  mutation updateTemplate($templateId: ID!, $input: MailTemplateInput!) {
    updateTemplate(id: $templateId, input: $input) {
      id
      title
      body
      subject
      isArchived
      lastEditionDate
      recruiterConfigurations {
        id
        isDefaultReply
      }
      snippets {
        ...Snippet
      }
      author {
        ...Author
      }
    }
  }
  ${Author}
  ${Snippet}
`;

export default graphql(mutation, {
  props: ({ ownProps, mutate }) => ({
    updateTemplate: (template) =>
      mutate({
        variables: {
          templateId: ownProps.templateId,
          input: template,
        },
        update: (store, { data: { updateTemplate } }) => {
          store.writeFragment({
            data: {
              id: updateTemplate.id,
              subject: updateTemplate.subject,
              body: updateTemplate.body,
              snippets: updateTemplate.snippets,
              __typename: 'Message',
            },
            fragment: MessageFragment,
            fragmentName: 'MessageFragment',
            id: `Message:${ownProps.templateId}`,
          });
        },
      }),
  }),
});
