import React from 'react';
import { useTranslation } from 'react-i18next';

import Plus from '@/components/Reveal/Icons/Plus';
import GenericButton from '@/components/Common/GenericButton';

interface NewSequenceButtonProps {
  onOpen: (open: boolean) => void;
}

const NewSequenceButton: React.FC<NewSequenceButtonProps> = ({ onOpen }) => {
  const { t } = useTranslation();

  return (
    <GenericButton size='big' onClick={() => onOpen(true)}>
      <Plus />
      <span>{t('sequences.newSequence')}</span>
    </GenericButton>
  );
};

export default NewSequenceButton;
