import { isValidUrl, isValidUrlFromWebsite } from '@/common/utils/string';
import { ClientMarketplaceDescriptor } from '@/types/client';

export type CompanyDescriptor = Omit<
  ClientMarketplaceDescriptor,
  'description'
> & {
  description: string;
};

export type CompanyLogoUpload = {
  name: string;
  file: {
    content: string | ArrayBuffer;
  };
};

export const sourceTypes = ['linkedin', 'website', 'crunchbase'] as const;

export type SourceType = typeof sourceTypes[number];

export const sourceTypeUrlCheckers: Partial<Record<
  SourceType,
  (url: string) => boolean
>> = {
  website: isValidUrl,
  crunchbase: isValidUrl,
  linkedin: isValidUrlFromWebsite('linkedin.com'),
};
