import useClientId from '@/hooks/router/useClientId';
import {
  HiresweetLibProvider,
  UserSelector,
  ProjectSelector,
  ClientSelector,
} from '@hiresweet/hiresweet-lib';
import React from 'react';

const PlaygroundT8 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  const [selectedUser, setSelectedUser] = React.useState('');
  const [selectedClient, setSelectedClient] = React.useState('');

  return (
    <HiresweetLibProvider theme={theme} clientId={clientId}>
      <h1>P-T-8 - Selecteurs</h1>
      <div
        style={{
          marginTop: '24px',
          width: '500px',
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <UserSelector selected={selectedUser} onSelect={setSelectedUser} />
        <ProjectSelector selected={selectedUser} onSelect={setSelectedUser} />
        <ClientSelector
          linkedCompany={selectedClient}
          onSelect={(value) => setSelectedClient(value)}
        />
      </div>
    </HiresweetLibProvider>
  );
};

export default PlaygroundT8;
