import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import RevealConnector from '../../graphql/fragments/RevealConnector';

export const startConnectorSynchronizationMutation = gql`
  mutation startConnectorSynchronization(
    $input: StartConnectorSynchronizationInput
  ) {
    startConnectorSynchronization(input: $input) {
      connector {
        ...RevealConnector
      }
    }
  }
  ${RevealConnector}
`;

export default graphql(startConnectorSynchronizationMutation, {
  props: ({ mutate }) => ({
    startConnectorSynchronization: ({ input }) => {
      const variables = { input };
      return mutate({ variables });
    },
  }),
});
