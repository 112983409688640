import getLocalStorageItem from "@/common/utils/getLocalStorageItem";

const useIsPlugin = (): boolean => {
  const pluginMode = getLocalStorageItem('plugin-mode');
  if (pluginMode === 'on') {
    return true;
  }

  if (window.location.href.includes('chrome-extension')) {
    return true;
  }

  return false;
};

export default useIsPlugin;
