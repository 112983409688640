import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import {
  ProviderType,
  PROVIDER_NAMES_BY_TYPE,
  PROVIDER_SETTINGS_PAGE,
} from '@/common/reveal';
import { PrimaryCobalt } from '@/less/colors';
import GenericButton from '@/components/Common/GenericButton';

import { UserEnrichmentIntegration } from '@/graphql/hooks/users/useUserEnrichmentIntegrations';
import styles from './RevealProviders.module.less';
import BasicProviderCredentialsForm from './ProviderCredentialsForms/BasicProviderCredentialsForm';

interface RevealProviderProps {
  clientId: string;
  provider?: UserEnrichmentIntegration;
  type: ProviderType;
  mode: 'token';
}

const RevealProvider: React.FC<RevealProviderProps> = ({
  clientId,
  provider,
  type,
  mode,
}) => {
  const { t } = useTranslation();

  const providerActive =
    provider && !provider.useClientIntegration && provider.status === 'active';

  return (
    <>
      <div className='data-provider'>
        <div className='settings-header'>
          <Breadcrumb
            icon='right angle'
            size='massive'
            style={{
              color: PrimaryCobalt,
              fontWeight: 700,
              fontFamily: 'Gilroy',
              fontSize: '26px',
            }}
          >
            <Breadcrumb.Section
              as={Link}
              to={`/client/${clientId}/providers`}
              style={{
                color: PrimaryCobalt,
                fontFamily: 'Gilroy',
                marginBottom: '0px',
              }}
            >
              <h1>{t('providers.header')}</h1>
            </Breadcrumb.Section>
            <Breadcrumb.Divider
              icon={
                <i
                  className={classnames(
                    styles.breadcrumbDividerIcon,
                    'ri-arrow-right-s-line',
                  )}
                />
              }
            />
            <Breadcrumb.Section
              active
              style={{ fontFamilly: 'Gilroy', marginBottom: '0px' }}
            >
              <h1>{PROVIDER_NAMES_BY_TYPE[type]}</h1>
            </Breadcrumb.Section>
          </Breadcrumb>
        </div>
        <div className='section visible settings-section'>
          <div className={styles.providerHeader}>
            <div className={styles.providerHeaderText}>
              {provider
                ? t('providers.providerConnected')
                : t('providers.connectProvider')}
            </div>
          </div>
          {!providerActive ? (
            <>
              <BasicProviderCredentialsForm type={type} mode={mode} />
              <div className={styles.providerHelper}>
                <div>
                  <h4>{t('providers.helper.header')}</h4>
                  <p>{t(`providers.helper.${type}.description`)}</p>
                </div>
                {(type === 'kaspr' ||
                  type === 'rocketreach' ||
                  type === 'lusha') && (
                  <div className={styles.providerHelperInstructions}>
                    <a href={PROVIDER_SETTINGS_PAGE[type]}>
                      <GenericButton>
                        {t(`providers.helper.${type}.instructionsBtn`)}
                      </GenericButton>
                    </a>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className='input-container'>
                <div className='input-label'>
                  {t('providers.providerConnectedDescription')}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RevealProvider;
