import CustomActionExecutionFragment from '@/graphql/fragments/CustomActionExecutionFragment';
import { gql, useMutation } from '@apollo/client';

const UPDATE_CLIENT_CUSTOM_ACTION = gql`
  mutation UpdateClientCustomAction($clientId: ID!, $input: ClientCustomActionInput) {
    updateClientCustomAction(clientId: $clientId, input: $input) {
      customActions {
        id
        completionButtonDisplay {
          title {
            default
          }
        }
        execution {
          ...CustomActionExecutionFragment
        }
        icon {
          type
          url
        }
        title {
          default
        }
        type
      }
    }
  }
  ${CustomActionExecutionFragment}
`;

export function useUpdateClientCustomAction() {
  return useMutation(UPDATE_CLIENT_CUSTOM_ACTION);
}

const REMOVE_CLIENT_CUSTOM_ACTION = gql`
  mutation RemoveClientCustomAction($clientId: ID!, $actionId: ID!) {
    removeClientCustomAction(clientId: $clientId, actionId: $actionId) {
      customActions {
        id
      }
    }
  }
`;

export function useRemoveClientCustomAction() {
  return useMutation(REMOVE_CLIENT_CUSTOM_ACTION);
}
