import React, { useMemo } from 'react';
import _ from 'underscore';
import { StackItem } from '@/types/statistics/stackItem';
import { Stats } from '@/types/statistics/stats';
import {
  getMaxTotalCount,
  getTotalCount,
  filterStack,
  getTicks,
} from '@/common/helpers/stats';

import TooltipBoundary from '@/components/Common/TooltipBoundary';
import styles from './VerticalStackedBarChart.module.less';
import Legend from '../Legend';
import StackedBar from '../StackedBar';

interface VerticalStackedBarChartProps {
  stack: StackItem[];
  allStats: Stats[];
  sort?: boolean;
  isPercentage?: boolean;
  exhaustive?: boolean;
}

const VerticalStackedBarChart: React.FC<VerticalStackedBarChartProps> = ({
  stack,
  allStats,
  sort,
  isPercentage,
  exhaustive,
}) => {
  const maxCount = useMemo(
    () => (isPercentage ? 100 : getMaxTotalCount(allStats)),
    [allStats, isPercentage],
  );
  const filteredStack = useMemo(
    () => (exhaustive ? stack : filterStack(stack, allStats)),
    [stack, allStats, exhaustive],
  );
  const ticks = useMemo(
    () => (isPercentage ? [0, 25, 50, 75, 100] : getTicks(maxCount, 4)),
    [maxCount, isPercentage],
  );
  const sortedStats = useMemo(
    () =>
      !sort
        ? allStats
        : _.chain(allStats)
            .sortBy(getTotalCount)
            .reverse()
            .value(),
    [allStats, sort],
  );

  return (
    <>
      <Legend stack={filteredStack} />
      <div className={styles.graph}>
        <div className={styles.scale}>
          {_.map(ticks, (tick) => (
            <span className={styles.tick} key={tick}>
              {tick}
            </span>
          ))}
        </div>
        <div className={styles.dataContainer}>
          <TooltipBoundary>
            <div className={styles.scrollableData}>
              <div className={styles.data}>
                <div className={styles.rulers}>
                  {_.map(ticks, (tick) => (
                    <div className={styles.ruler} key={tick} />
                  ))}
                </div>
                <div className={styles.bars}>
                  {_.map(sortedStats, (stats, index) => (
                    <div className={styles.bar} key={index}>
                      <StackedBar
                        stack={filterStack(stack, [stats])}
                        stats={stats}
                        topBoundary={_.last(ticks) || maxCount}
                        isVertical
                        isPercentage={isPercentage}
                      />
                    </div>
                  ))}
                </div>
                <div className={styles.labels}>
                  {_.map(sortedStats, (stats, index) => (
                    <div className={styles.label} key={index}>
                      {stats.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </TooltipBoundary>
        </div>
      </div>
    </>
  );
};

export default VerticalStackedBarChart;
