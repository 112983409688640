import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import _ from 'underscore';

import SimpleDropdown from '@/components/SimpleDropdown';
import useLocalStorage from '@/hooks/storage/useLocalStorage';

import { LinkedinSenderConfig } from './SendUnipileLinkedinMessageButton';

interface UnipileSenderSelectorProps {
  selectedSenderId?: string;
  setSelectedSenderId?: Dispatch<SetStateAction<string>>;
  sharedMessagingAccounts: any[];
}

const UnipileSenderSelector: React.FC<UnipileSenderSelectorProps> = ({
  selectedSenderId,
  setSelectedSenderId,
  sharedMessagingAccounts,
}) => {
  const { t } = useTranslation();

  const [
    _unipileLinkedinSenderConfig,
    setUnipileLinkedinSenderConfig,
  ] = useLocalStorage<LinkedinSenderConfig>('revealLinkedinSender', null);

  const linkedinMessagingAccounts = useMemo(() => {
    return _.filter(
      sharedMessagingAccounts,
      (account) => account.type === 'unipile-linkedin',
    );
  }, [sharedMessagingAccounts]);

  return (
    <SimpleDropdown
      text={
        _.findWhere(sharedMessagingAccounts, { id: selectedSenderId })?.name ||
        t('reveal.sobo.assignSender')
      }
    >
      <Dropdown.Menu>
        {selectedSenderId && (
          <Dropdown.Item
            key='remove-unipile-sender'
            text={t('reveal.unipileSender.unselect')}
            onClick={() => {
              setUnipileLinkedinSenderConfig('');
              if (setSelectedSenderId) {
                setSelectedSenderId('');
              }
            }}
          />
        )}
        {_.map(linkedinMessagingAccounts, (linkedinMessagingAccount) => (
          <Dropdown.Item
            key={linkedinMessagingAccount.id}
            text={linkedinMessagingAccount.name}
            onClick={() => {
              setUnipileLinkedinSenderConfig(linkedinMessagingAccount.id);
              if (setSelectedSenderId) {
                setSelectedSenderId(linkedinMessagingAccount.id);
              }
            }}
          />
        ))}
      </Dropdown.Menu>
    </SimpleDropdown>
  );
};

export default UnipileSenderSelector;
