import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SearchResults from '../../graphql/fragments/SearchResults';

export const REVEAL_MAX_NB_RESULTS = 1000;

export const REVEAL_PROFILE_SEARCH_MAX_NB_RESULTS = 50;

export const GET_REVEAL_SEARCH_POOL_RESULTS = gql`
  query getRevealSearchPoolResults(
    $searchPoolId: ID!
    $searchesCriteria: [SearchCriteriaInput]
    $searchText: String
    $categories: [SearchCategoryInput]
    $sortBy: String
    $excludeHidden: Boolean
    $excludeProfilesInJob: Boolean
    $maxNbResults: Int
    $missionId: String
    $includeSkipped: Boolean
  ) {
    searchPool(id: $searchPoolId) {
      id
      searchResults(
        searchesCriteria: $searchesCriteria
        searchText: $searchText
        categories: $categories
        excludeHidden: $excludeHidden
        excludeProfilesInJob: $excludeProfilesInJob
        maxNbResults: $maxNbResults
        sortBy: $sortBy
        missionId: $missionId
        includeSkipped: $includeSkipped
      ) {
        ...SearchResults
      }
    }
  }
  ${SearchResults}
`;

export const REVEAL_CATEGORIES = {
  categoryId: 'all',
  withCount: true,
  withResults: true,
};

export default graphql(GET_REVEAL_SEARCH_POOL_RESULTS, {
  options: ({
    searchPoolId,
    searches,
    searchText,
    excludeHidden,
    excludeProfilesInJob,
    maxNbResults,
    sortBy,
    fetchPolicy,
    missionId,
    includeSkipped,
  }) => {
    return {
      variables: {
        searchPoolId,
        searchesCriteria: searches,
        searchText,
        categories: REVEAL_CATEGORIES,
        sortBy,
        excludeHidden,
        excludeProfilesInJob,
        maxNbResults,
        missionId,
        includeSkipped,
      },
      fetchPolicy,
    };
  },
  props: ({ data: { loading, error, searchPool } }) => ({
    loadingSearchResultsCategories: loading,
    errorSearchResults: error,
    searchResultsCategories: searchPool?.searchResults?.categories,
  }),
});

export const GET_CROSS_PROJECT_PROFILES_WITH_SEGMENTATION = gql`
  query getCrossProjectProfilesWithSegmentation(
    $searchPoolId: ID!
    $input: MiniProfilesWithSegmentationInput
  ) {
    searchPool(id: $searchPoolId) {
      id
      miniProfilesWithSegmentation(input: $input) {
        id
        isArchived
        missionId
        step
        missionsInfo {
          missionId
          mission {
            data {
              title
            }
          }
        }
      }
    }
  }
`;

export const GET_GENERIC_SEGMENTATION_PROFILES = gql`
  query getCrossMissionProfilesWithSegmentation(
    $searchPoolId: ID!
    $input: SegmentProfileResultsInput
  ) {
    searchPool(id: $searchPoolId) {
      id
      segmentProfileResults(input: $input) {
        segmentId
        profiles {
          id
          categoryId
        }
      }
    }
  }
`;

export const GET_GENERIC_SEGMENTATION_COUNTS = gql`
  query getCrossMissionProfilesCount(
    $searchPoolId: ID!
    $input: SegmentProfileResultsInput
  ) {
    searchPool(id: $searchPoolId) {
      id
      segmentProfileResults(input: $input) {
        segmentId
        count
      }
    }
  }
`;
