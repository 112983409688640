import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const mutation = gql`
  mutation unlockSearchPoolProfile(
    $searchPoolId: ID!
    $input: UnlockSearchPoolProfileInput
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      unlock(input: $input) {
        profile {
          id
          locked
          lockedSince
          contacted
          sent
        }
      }
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    unlockSearchPoolProfile: ({ searchPoolId, input }) =>
      mutate({
        variables: { searchPoolId, input },
      }),
  }),
});
