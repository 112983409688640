import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import Sequence from '../../../graphql/fragments/Sequence';
import SequenceTemplate from '../../../graphql/fragments/SequenceTemplate';

export const CLIENT_SEQUENCES = gql`
  query getClientSequences(
    $clientId: ID!
    $filters: ClientSequencesFiltersInput
  ) {
    client(id: $clientId) {
      id
      sequences(filters: $filters) {
        ...Sequence
      }
    }
  }
  ${Sequence}
`;

export const CLIENT_MINI_SEQUENCES = gql`
  query getClientMiniSequences(
    $clientId: ID!
    $filters: ClientSequencesFiltersInput
  ) {
    client(id: $clientId) {
      id
      sequences(filters: $filters) {
        id
        folderId
        isArchived
      }
    }
  }
`;

export const CLIENT_SEQUENCE_TEMPLATES = gql`
  query getClientSequenceTemplates($clientId: ID!) {
    client(id: $clientId) {
      id
      sequenceTemplates {
        defaultSequenceTemplates {
          ...SequenceTemplate
        }
      }
    }
  }
  ${SequenceTemplate}
`;

export const useClientSequenceTemplates = ({ clientId, ...options }) => {
  const { data, loading } = useQuery(CLIENT_SEQUENCE_TEMPLATES, {
    variables: { clientId },
    ...options,
  });
  return { loading, sequenceTemplates: data?.client?.sequenceTemplates };
};

export const CLIENT_SEQUENCE = gql`
  query getClientSequence($sequenceId: ID!) {
    sequence(id: $sequenceId) {
      ...Sequence
    }
  }
  ${Sequence}
`;

// TODO: refactor
// const CLIENT_SEQUENCE_TEMPLATE = gql`
//   query getClientSequenceTemplate($sequenceTemplateId: ID!) {
//     sequenceTemplate(id: $sequenceTemplateId) {
//       ...SequenceTemplate
//     }
//   }
//   ${SequenceTemplate}
// `;

export const CREATE_SEQUENCE = gql`
  mutation createSequence($input: CreateSequenceInput!) {
    sequence {
      create(input: $input) {
        sequence {
          ...Sequence
        }
      }
    }
  }
  ${Sequence}
`;

export const UPDATE_SEQUENCE = gql`
  mutation updateSequence($input: UpdateSequenceInput!) {
    sequence {
      update(input: $input) {
        sequence {
          ...Sequence
        }
      }
    }
  }
  ${Sequence}
`;

export const DUPLICATE_SEQUENCE = gql`
  mutation duplicateSequence($input: DuplicateSequenceInput!) {
    sequence {
      duplicate(input: $input) {
        sequence {
          ...Sequence
        }
      }
    }
  }
  ${Sequence}
`;
