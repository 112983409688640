import gql from 'graphql-tag';
import TranslatableText from './TranslatableText';
import Skill from './Skill';
import SweetDate from './SweetDate';
import TextWithEnrichments from './TextWithEnrichments';
import TextWithEnrichmentsAndStack from './TextWithEnrichmentsAndStack';
import TranslatableTextWithEnrichments from './TranslatableTextWithEnrichments';
import RelevantTag from './RelevantTag';
import HiresweetData from './HiresweetData';
import { ApplicationForm } from './Applications';
import Sources from './Sources';
import CandidateClientInterestStates from './CandidateClientInterestStates';

export default gql`
  fragment FullResumeData on ProfileResumeData {
    step
    subStep
    firstname
    lastname
    photoLink
    headline {
      ...TextWithEnrichmentsAndStack
    }
    location {
      name {
        ...TranslatableText
      }
    }
    prequalification {
      type
    }
    hidePersonnalData
    mainEducation {
      schoolName
      startDate {
        ...SweetDate
      }
      endDate {
        ...SweetDate
      }
    }
    email
    summary {
      ...TextWithEnrichmentsAndStack
    }
    sources {
      ...Sources
    }
    photoLink
    relevantActivities
    relevantTags {
      ...RelevantTag
    }
    phoneNumber
    application {
      questionsForm {
        ...ApplicationForm
      }
    }

    experiences {
      location {
        name {
          ...TranslatableText
        }
      }
      title {
        text
      }
      description {
        text
      }
      startDate {
        ...SweetDate
      }
      endDate {
        ...SweetDate
      }
      photoLink
      companyName
      companyWebsite
      stack {
        ...Skill
      }
      workplace {
        id
        data {
          name
          types {
            id
            name {
              ...TranslatableText
            }
          }
          foundedYear
          stack {
            name {
              default
              fr
              en
            }
            icon
            detailsURL
            ...Skill
          }
          mainLocation {
            name {
              ...TranslatableText
            }
          }
          sources {
            sourceId
            url
            name {
              ...TranslatableText
            }
          }
          staffCount {
            min
            max
          }
          industries {
            name {
              ...TranslatableText
            }
          }
          description {
            ...TranslatableTextWithEnrichments
          }
        }
      }
    }
    education {
      schoolName
      degree {
        text
      }
      startDate {
        ...SweetDate
      }
      endDate {
        ...SweetDate
      }
      photoLink
      schoolWebsite
      stack {
        ...Skill
      }
    }
    sourceData {
      github {
        relevantFacts {
          title {
            ...TranslatableText
          }
          description {
            ...TranslatableText
          }
        }
        repositories {
          name {
            ...TextWithEnrichments
          }
          fullname {
            ...TextWithEnrichments
          }
          description {
            ...TextWithEnrichments
          }
          fork
          createdAt {
            ...SweetDate
          }
          updatedAt {
            ...SweetDate
          }
          stargazersCount
          watchersCounts
          forksCount
          language
        }
      }
      stackoverflow {
        relevantFacts {
          title {
            ...TranslatableText
          }
          description {
            ...TranslatableText
          }
        }
      }
      linkedin {
        skills {
          label {
            ...TranslatableText
          }
          endorsementsCount
          relevance
        }
      }
      hiresweet {
        ...HiresweetData
        ...CandidateClientInterestStates
      }
    }
    sourceName
    smartSummary {
      arguments {
        type
        ... on GroupedKeyPointsArgument {
          groups {
            category
            keyPoints {
              category
              label {
                ...TranslatableText
              }
              content {
                ...TranslatableText
              }
              popupContent {
                ...TranslatableText
              }
            }
          }
        }
        ... on ReviewArgument {
          stack {
            ...Skill
          }
          title {
            ...TranslatableText
          }
          content {
            ...TranslatableText
          }
        }
      }
    }
  }
  ${TranslatableText}
  ${Skill}
  ${SweetDate}
  ${TextWithEnrichments}
  ${TextWithEnrichmentsAndStack}
  ${TranslatableTextWithEnrichments}
  ${RelevantTag}
  ${HiresweetData}
  ${CandidateClientInterestStates}
  ${ApplicationForm}
  ${Sources}
`;
