import useClientId from '@/hooks/router/useClientId';
import { HiresweetLibProvider, Stars } from '@hiresweet/hiresweet-lib';
import React from 'react';

const PlaygroundT6 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <HiresweetLibProvider theme={theme} clientId={clientId}>
      <h1>P-T-6 - Stars</h1>
      <Stars max={5} value={3} />
      <Stars max={10} value={7} />
    </HiresweetLibProvider>
  );
};

export default PlaygroundT6;
