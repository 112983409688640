import _ from 'underscore';
import React from 'react';
import {
  HiresweetLibProvider,
  ProjectOwner,
  ProjectTitle,
  ProjectsSetContainer,
  ProjectsTable,
  createSweetProjectComponent,
  useProjectData,
} from '@hiresweet/hiresweet-lib';
import useClientId from '@/hooks/router/useClientId';

const ProjectOwnerEmail = createSweetProjectComponent(() => {
  const { loading, data } = useProjectData(ProjectOwner);
  if (loading) {
    return '';
  }
  return data.email ?? '';
}, [ProjectOwner]);

const Playground9 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30 }}>
      <h1>P9 - Projects - Paginated table</h1>
      <div>
        <HiresweetLibProvider theme={theme} clientId={clientId}>
          <ProjectsSetContainer filter={{}}>
            <ProjectsTable pageSize={5}>
              <ProjectsTable.Header>
                <ProjectsTable.HeaderCell>Title</ProjectsTable.HeaderCell>
                <ProjectsTable.HeaderCell>Owner Name</ProjectsTable.HeaderCell>
                <ProjectsTable.HeaderCell>Owner Email</ProjectsTable.HeaderCell>
              </ProjectsTable.Header>

              <ProjectsTable.Body>
                <ProjectsTable.RowMap>
                  <ProjectsTable.Cell>
                    <ProjectTitle />
                  </ProjectsTable.Cell>
                  <ProjectsTable.Cell><ProjectOwner /></ProjectsTable.Cell>
                  <ProjectsTable.Cell>
                    <ProjectOwnerEmail />
                  </ProjectsTable.Cell>
                </ProjectsTable.RowMap>
              </ProjectsTable.Body>
            </ProjectsTable>
          </ProjectsSetContainer>
        </HiresweetLibProvider>
      </div>
    </div>
  );
};

export default Playground9;
