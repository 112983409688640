import React, { useEffect, useState } from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import { areEqualOrBothEmpty } from '../../../common/equality';
import logAction from '../../../common/logAction';
import { getSearchCriteria } from '../../../containers/Parameters/OfferCriteria/helpers';
import withDeleteSearch from '../../../hocs/clients/withDeleteSearch';
import withSavedSearches from '../../../hocs/clients/withSavedSearches';
import withClientActiveOffersSearchCriteria from '../../../hocs/offers/withClientActiveOffersSearchCriteria';
import withUserFromJWToken from '../../../hocs/users/withUserFromJWToken';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';

import './ApplySearchDropdown.css';

const ApplySearchDropdown = ({
  clientId,
  searchPoolId,
  appliedFilters,
  savedSearches,
  onApplySearch,
  deleteSearch,
  offersSearchCriteria,
  user,
  t,
}) => {
  const [selectedValue, setSelectedValue] = useState();
  const [searchToDeleteId, setSearchToDeleteId] = useState();
  const author = `${user?.firstname} ${user?.lastname}`;

  const savedSearchesOptions = _.map(savedSearches, (search) => {
    const onClickCross = (e) => {
      if (e?.stopPropagation) {
        e.stopPropagation();
      }
      setSearchToDeleteId(search?.id);
    };
    return {
      value: search?.id,
      className: 'saved-search-item',
      children: (
        <div className='item-text-and-cross'>
          <div className='item-text' title={search?.name}>
            {search?.name}
          </div>
          <i className='ri-close-line' onClick={onClickCross} role='button' />
        </div>
      ),
      type: 'saved-search',
      criteria: search?.criteria,
      name: search?.name, // camel-case in non-standard props throw react warning
    };
  });

  const jobSearchesOptions = _.map(offersSearchCriteria, (job) => {
    return {
      value: job?.id,
      text: job?.title,
      type: 'job-search',
      criteria: getSearchCriteria({ offerCriteria: job?.criteria }),
    };
  });

  let options = _.union(savedSearchesOptions, jobSearchesOptions);
  options = _.sortBy(options, ({ text }) => (text || '').toLowerCase());
  const selectedOption = _.findWhere(options, { value: selectedValue });

  useEffect(() => {
    const appliedCriteria = pickMainCriteria(appliedFilters);
    const selectedCriteria = pickMainCriteria(selectedOption?.criteria);
    // if (selectedOption?.type === 'job-search') {
    //   appliedCriteria = pickJobCriteria(appliedFilters);
    //   selectedCriteria = pickJobCriteria(selectedOption?.criteria);
    // } else {
    //   appliedCriteria = pickSearchCriteria(appliedFilters);
    //   selectedCriteria = pickSearchCriteria(selectedOption?.criteria);
    // }

    if (!areSearchCriteriaEqual(appliedCriteria, selectedCriteria)) {
      // console.log('not equal');
      setSelectedValue(null);
    }
    // eslint-disable-next-line
  }, [appliedFilters]);

  if (_.isEmpty(options)) {
    return null;
  }

  const onChange = (e, { value }) => {
    setSelectedValue(value);
    const nextOption = _.findWhere(options, { value });
    onApplySearch({ criteria: nextOption?.criteria });

    logAction({
      type: `discover-apply-${selectedOption?.type}`,
      info: { clientId, author },
    });
  };

  const onDeleteSearch = async () => {
    try {
      await deleteSearch({ searchPoolId, searchId: searchToDeleteId });
    } catch (e) {
      console.error(e);
    }

    logAction({
      type: 'discover-delete-saved-search',
      info: { clientId, author, searchId: searchToDeleteId },
    });

    setSearchToDeleteId(null);
  };

  return (
    <div className='apply-search-dropdown'>
      <Dropdown
        className='hs-dropdown-ellipsis hs-dropdown-menu-ellipsis'
        selection
        value={selectedValue}
        text={selectedOption?.text || selectedOption?.name}
        onChange={onChange}
        options={options}
        placeholder={t('watchCollect.applySearchDropdown.placeholder')}
      />
      <ConfirmationModal
        open={!!searchToDeleteId}
        header={t('watchCollect.deleteSearchModal.header')}
        content={t('watchCollect.deleteSearchModal.content')}
        submit={t('watchCollect.deleteSearchModal.submit')}
        onCancel={() => setSearchToDeleteId(false)}
        onSubmit={onDeleteSearch}
        size='tiny'
      />
    </div>
  );
};

const pickJobCriteria = (input) => {
  if (_.isEmpty(input)) {
    return null;
  }
  return _.pick(
    input,
    ...[
      'jobPositions',
      'locations',
      'schooTypes',
      'skills',
      'salaryMin',
      'salaryMax',
      'experienceYearsMin',
      'experienceYearsMax',
      'contracts',
    ],
  );
};

const pickMainCriteria = pickJobCriteria;

// const pickSearchCriteria = (input) => {
//   if (_.isEmpty(input)) {
//     return null;
//   }
//   return _.pick(input, ...[
//     'jobPositions',
//     'jobPositionsOperator',
//     'locations',
//     'schoolTypes,
//     'skills',
//     'skillsOperator',
//     'salaryMin',
//     'salaryMax',
//     'experienceYearsMin',
//     'experienceYearsMax',
//     'contracts',
//     'excludeHidden',
//     'excludeProfilesInJob',
//     'remoteOnly',
//   ]);
// };

const areSearchCriteriaEqual = (criteria1, criteria2) => {
  // both null or undefined
  if (criteria1 === criteria2) {
    return true;
  }

  // both empty
  if (_.isEmpty(criteria1) && _.isEmpty(criteria2)) {
    return true;
  }

  // at least one non-empty
  return (
    areEqualOrBothEmpty(criteria1?.locations, criteria2?.locations) &&
    areEqualOrBothEmpty(criteria1?.schoolTypes, criteria2?.schoolTypes) &&
    areEqualOrBothEmpty(criteria1?.skills, criteria2?.skills) &&
    areEqualOrBothEmpty(criteria1?.skillsOperator, criteria2?.skillsOperator) &&
    areEqualOrBothEmpty(criteria1?.jobPositions, criteria2?.jobPositions) &&
    areEqualOrBothEmpty(
      criteria1?.jobPositionsOperator,
      criteria2?.jobPositionsOperator,
    ) &&
    areEqualOrBothEmpty(criteria1?.salaryMin, criteria2?.salaryMin) &&
    areEqualOrBothEmpty(criteria1?.salaryMax, criteria2?.salaryMax) &&
    areEqualOrBothEmpty(criteria1?.contracts, criteria2?.contracts) &&
    areEqualOrBothEmpty(criteria1?.excludeHidden, criteria2?.excludeHidden) &&
    areEqualOrBothEmpty(
      criteria1?.excludeProfilesInJob,
      criteria2?.excludeProfilesInJob,
    ) &&
    areEqualOrBothEmpty(
      criteria1?.includeProfilesInJob,
      criteria2?.includeProfilesInJob,
    ) &&
    areEqualOrBothEmpty(criteria1?.remoteOnly, criteria2?.remoteOnly) &&
    areEqualOrBothEmpty(
      criteria1?.experienceYearsMax,
      criteria2?.experienceYearsMax,
    ) &&
    (areEqualOrBothEmpty(
      criteria1?.experienceYearsMin,
      criteria2?.experienceYearsMin,
    ) ||
      (!criteria1?.experienceYearsMin && !criteria2?.experienceYearsMin))
  );
};

export default compose(
  withTranslation('translations'),
  withUserFromJWToken,
  withSavedSearches,
  withClientActiveOffersSearchCriteria,
  withDeleteSearch,
)(ApplySearchDropdown);
