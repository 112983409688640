/* eslint-disable max-len */

import React from 'react';

import styles from './svg.module.less';

const Reports: React.FC = () => (
  <svg
    className={styles.svgIcon}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 1C2.79086 1 1 2.79086 1 5V11C1 13.2091 2.79086 15 5 15H11C13.2091 15 15 13.2091 15 11V5C15 2.79086 13.2091 1 11 1H5ZM5 8C4.44772 8 4 8.44772 4 9V11C4 11.5523 4.44772 12 5 12C5.55228 12 6 11.5523 6 11V9C6 8.44772 5.55228 8 5 8ZM10 7C10 6.44772 10.4477 6 11 6C11.5523 6 12 6.44772 12 7V11C12 11.5523 11.5523 12 11 12C10.4477 12 10 11.5523 10 11V7ZM8 4C7.44772 4 7 4.44772 7 5V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V5C9 4.44772 8.55228 4 8 4Z'
      fill='currentColor'
    />
  </svg>
);

export default Reports;
