const GREY = '#EEEEEE';
// const BLUE_0 = '';
// const BLUE_1 = '';
// const BLUE_2 = '';
const BLUE_3 = '#709DFF';
// const BLUE_4 = '';
const BLUE_5 = '#4864C9';
// const BLUE_6 = '';
const BLUE_7 = '#1F2E77';
// const BLUE_8 = '';
// const BLUE_9 = '#10173D';

// const PINK_0 = '';
// const PINK_1 = '';
// const PINK_2 = '';
const PINK_3 = '#FFE2E3';
const PINK_4 = '#FBD2D4';
const PINK_5 = '#F9C8CA';
const PINK_6 = '#F86E7F';
const PINK_7 = '#F86E7F';
// const PINK_8 = '';
// const PINK_9 = '';

const TEMPLATE_ICON = 'square';
const OFFER_ICON = 'square';
const SEND_ICON_0 = 'circle';
const SEND_ICON_1 = 'trophy';
const SEND_ICON_2 = 'gem';
const ANSWER_ICON_0 = 'circle';
const ANSWER_ICON_1 = 'trophy';
const ANSWER_ICON_2 = 'gem';

export const ICON_MAP = {
  'ith-send': {
    1: {
      name: SEND_ICON_0,
      color: GREY,
    },
    10: {
      name: SEND_ICON_0,
      color: BLUE_3,
    },
    25: {
      name: SEND_ICON_0,
      color: BLUE_5,
    },
    50: {
      name: SEND_ICON_0,
      color: BLUE_7,
    },
    100: {
      name: SEND_ICON_1,
      color: BLUE_3,
    },
    250: {
      name: SEND_ICON_1,
      color: BLUE_5,
    },
    500: {
      name: SEND_ICON_1,
      color: BLUE_7,
    },
    1000: {
      name: SEND_ICON_2,
      color: BLUE_3,
    },
    2500: {
      name: SEND_ICON_2,
      color: BLUE_5,
    },
    5000: {
      name: SEND_ICON_2,
      color: BLUE_7,
    },
    10000: {
      name: SEND_ICON_2,
      color: '',
    },
  },
  'ith-answer': {
    1: {
      name: ANSWER_ICON_0,
      color: GREY,
    },
    10: {
      name: ANSWER_ICON_0,
      color: PINK_3,
    },
    25: {
      name: ANSWER_ICON_0,
      color: PINK_5,
    },
    50: {
      name: ANSWER_ICON_0,
      color: PINK_7,
    },
    100: {
      name: ANSWER_ICON_1,
      color: PINK_3,
    },
    250: {
      name: ANSWER_ICON_1,
      color: PINK_5,
    },
    500: {
      name: ANSWER_ICON_1,
      color: PINK_7,
    },
    1000: {
      name: ANSWER_ICON_2,
      color: PINK_3,
    },
    2500: {
      name: ANSWER_ICON_2,
      color: PINK_5,
    },
    5000: {
      name: ANSWER_ICON_2,
      color: PINK_7,
    },
    10000: {
      name: ANSWER_ICON_2,
      color: '',
    },
  },
  'ith-template-created': {
    1: {
      name: TEMPLATE_ICON,
      color: GREY,
    },
    10: {
      name: TEMPLATE_ICON,
      color: BLUE_3,
    },
    25: {
      name: TEMPLATE_ICON,
      color: BLUE_5,
    },
    50: {
      name: TEMPLATE_ICON,
      color: BLUE_7,
    },
    100: {
      name: TEMPLATE_ICON,
      color: PINK_3,
    },
    250: {
      name: TEMPLATE_ICON,
      color: PINK_4,
    },
    500: {
      name: TEMPLATE_ICON,
      color: PINK_5,
    },
    1000: {
      name: TEMPLATE_ICON,
      color: PINK_6,
    },
    2500: {
      name: TEMPLATE_ICON,
      color: PINK_7,
    },
    5000: {
      name: TEMPLATE_ICON,
      color: '',
    },
    10000: {
      name: TEMPLATE_ICON,
      color: '',
    },
  },
  'ith-offer-created': {
    1: {
      name: OFFER_ICON,
      color: GREY,
    },
    10: {
      name: OFFER_ICON,
      color: BLUE_3,
    },
    25: {
      name: OFFER_ICON,
      color: BLUE_5,
    },
    50: {
      name: OFFER_ICON,
      color: BLUE_7,
    },
    100: {
      name: OFFER_ICON,
      color: PINK_3,
    },
    250: {
      name: OFFER_ICON,
      color: PINK_4,
    },
    500: {
      name: OFFER_ICON,
      color: PINK_5,
    },
    1000: {
      name: OFFER_ICON,
      color: PINK_6,
    },
    2500: {
      name: OFFER_ICON,
      color: PINK_7,
    },
    5000: {
      name: OFFER_ICON,
      color: '',
    },
    10000: {
      name: OFFER_ICON,
      color: '',
    },
  },
};
