import React, { useEffect, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import { CalibrationAndRecommendationIcon } from '@/assets/icons';
import Thumbnail from '../../../../../components/Thumbnail';
import { formatDate } from '../../../../../common/dates';
import TASK_ICONS from '../TaskIcons';
import AuthorAndDate from '../AuthorAndDate';

const ClassicSkippedFromMissionRecommendationsItem = ({
  item,
  unbiasedModeEnabled,
  profile,
}) => {
  const { t } = useTranslation();

  const [job, setJob] = useState(null);
  const { date, action, author } = item || {};
  const { firstname, lastname } = profile?.resumeData || {};
  let profileName = `${firstname || ''} ${lastname || ''}`.trim();
  profileName = unbiasedModeEnabled ? '???' : profileName;
  const recruiterName = `${author?.firstname || ''} ${author?.lastname ||
    ''}`.trim();
  const { jobOptions, loading } = useSearchPoolJobOptions('reveal');

  useEffect(() => {
    if (!loading) {
      setJob(
        _.findWhere(jobOptions, {
          id: action.missionId,
        }),
      );
    }
    // eslint-disable-next-line
  }, [loading]);

  return (
    <div
      className='timeline-card-mail-wrapper classic-item'
      style={{ bottom: 0 }}
    >
      <div className='content'>
        <div className='header'>
          <Thumbnail
            link={author?.photoLink}
            firstname={author?.firstname}
            lastname={author?.lastname}
            email={author?.email}
          />
          <div className='author'>
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  'profile.contact.timeline.skippedFromMissionRecommendations.description',
                  {
                    authorName: `${author?.firstname ||
                      ''} ${author?.lastname || ''}`,
                    profileName,
                    missionName: job?.data?.title,
                  },
                ),
              }}
            />
            <div className='date'>
              <b>{`${t('common.by')} ${recruiterName} `}</b>
              {`${date && formatDate(date, t)}`}
            </div>
          </div>
        </div>
        <div className='card-content comment'>
          <div className='body'>
            {t('profile.contact.timeline.reasons')}
            <ul>
              {_.map(
                _.compact(
                  getReasonTextsFromRawReason({
                    rawReason: action?.reason || '',
                    t,
                  }),
                ),
                (reason) => (
                  <li key={reason}>{reason}</li>
                ),
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const RevealSkippedFromMissionRecommendationsItem = ({
  item,
  unbiasedModeEnabled,
  profile,
}) => {
  const { t } = useTranslation();

  const [job, setJob] = useState(null);
  const { action, author } = item || {};
  const { firstname, lastname } = profile?.resumeData || {};
  let profileName = `${firstname || ''} ${lastname || ''}`.trim();
  profileName = unbiasedModeEnabled ? '???' : profileName;
  const { jobOptions, loading } = useSearchPoolJobOptions('reveal');

  useEffect(() => {
    if (!loading) {
      setJob(
        _.findWhere(jobOptions, {
          id: action?.missionId,
        }),
      );
    }
    // eslint-disable-next-line
  }, [loading]);

  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{
                backgroundColor:
                  TASK_ICONS['skipped-from-mission-recommendations'].color,
              }}
              className='icon'
            >
              <CalibrationAndRecommendationIcon />
            </div>
          </div>
          <div className='author'>
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  'profile.contact.timeline.skippedFromMissionRecommendations.description',
                  {
                    authorName: `${author?.firstname ||
                      ''} ${author?.lastname || ''}`,
                    profileName,
                    missionName: job?.data?.title,
                  },
                ),
              }}
            />
            <AuthorAndDate timelineItem={item} />
          </div>
        </div>
        <div className='card-content comment'>
          <div className='body'>
            {t('profile.contact.timeline.reasons')}
            <ul>
              {_.map(
                _.compact(
                  getReasonTextsFromRawReason({
                    rawReason: action?.reason || '',
                    t,
                  }),
                ),
                (reason) => (
                  <li key={reason}>{reason}</li>
                ),
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const SkippedFromMissionRecommendationsItem = ({
  profile,
  timelineItem,
  updateProfileComment,
  updateSearchPoolProfileComment,
  clientId,
  applicationMode,
}) => {
  const author = timelineItem?.author ? timelineItem?.author : {};

  if (applicationMode === 'classic') {
    return (
      <ClassicSkippedFromMissionRecommendationsItem
        profile={profile}
        item={timelineItem}
        author={author}
        updateProfileComment={updateProfileComment}
        updateSearchPoolProfileComment={updateSearchPoolProfileComment}
        clientId={clientId}
      />
    );
  }
  return (
    <RevealSkippedFromMissionRecommendationsItem
      profile={profile}
      item={timelineItem}
      author={author}
      updateProfileComment={updateProfileComment}
      updateSearchPoolProfileComment={updateSearchPoolProfileComment}
      clientId={clientId}
    />
  );
};

const getReasonTextsFromRawReason = ({ rawReason, t }) => {
  return _.compact(
    _.flatten(
      _.map((rawReason || '').split(';'), (subpart) =>
        _.map(subpart.split('#'), (part, index) =>
          index === 0 && part
            ? t(`profile.steps.newSkip.reasons.${part}`)
            : part,
        ),
      ),
    ),
  );
};

export default SkippedFromMissionRecommendationsItem;
