import React, { useMemo } from 'react';
import { isPhoneCallAction } from '@/common/constants/taskTypes';
import {
  ContactFlowSequenceActions,
  SearchPoolProfile,
} from '@/types/searchPoolProfile';
import { HelpTextPhoneCTA } from '@/revealComponents/ExplicitTasks/MetaTaskForm/HelpText';

interface UseHelpTextCTAArgs {
  action?: ContactFlowSequenceActions;
  profile?: SearchPoolProfile;
}

export function useHelpTextCTA({ action, profile }: UseHelpTextCTAArgs) {
  const actionHelpTextCTA = useMemo(() => {
    const isPhone =
      isPhoneCallAction(action?.type) || isPhoneCallAction(action?.countAs);
    if (isPhone && profile?.resumeData?.phoneNumber) {
      return (
        <HelpTextPhoneCTA phoneNumber={profile?.resumeData?.phoneNumber} />
      );
    }

    return null;
  }, [action, profile]);

  return actionHelpTextCTA;
}
