import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import CareerPageAndJobPostings from '../../graphql/fragments/CareerPageAndJobPostings';

export const getClientCareerPages = gql`
  query getClientCareerPages($id: ID!) {
    client(id: $id) {
      id
      careerPages {
        ...CareerPageAndJobPostings
      }
    }
  }
  ${CareerPageAndJobPostings}
`;

export default graphql(getClientCareerPages, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.clientId,
    },
  }),
  props: ({ data: { loading, client, error } }) => ({
    loading,
    clientCareerPages: client?.careerPages,
    clientCareerPagesLoading: loading,
    error,
  }),
});
