import React from 'react';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import GenericButton from '@/components/Common/GenericButton';
import withMarkHidden from '../../../hocs/searchPoolProfileState/withMarkHidden';

const SkipProfileButton = ({
  searchPoolId,
  searchPoolProfileId,
  onSkip,
  markHidden,
  t,
}) => {
  const handleHide = async (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    try {
      await markHidden({ searchPoolId, profileId: searchPoolProfileId });
      onSkip({ searchPoolProfileId });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <GenericButton primacy='secondary' color='red' onClick={handleHide}>
      <i className='ri-close-line' />
      {t('watchCollect.results.profileRow.skip')}
    </GenericButton>
  );
};

export default compose(
  withMarkHidden,
  withTranslation('translations'),
)(SkipProfileButton);
