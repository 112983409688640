interface PaginationBounds {
  pageMinIndex: number;
  pageMaxIndex: number;
}

export const getPaginationBounds = (
  currentPageIndex: number,
  nbPages: number,
  pageRay: number,
): PaginationBounds => {
  if (currentPageIndex - pageRay <= 0) {
    return {
      pageMinIndex: 0,
      pageMaxIndex: Math.min(2 * pageRay, nbPages - 1),
    };
  }
  if (currentPageIndex + pageRay >= nbPages - 1) {
    return {
      pageMinIndex: Math.max(0, nbPages - 1 - 2 * pageRay),
      pageMaxIndex: nbPages - 1,
    };
  }

  return {
    pageMinIndex: currentPageIndex - pageRay,
    pageMaxIndex: currentPageIndex + pageRay,
  };
};

export default {};
