import _, { compose } from 'underscore';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import { getTranslatedText } from '../../../common';
import {
  getFirstLineOnlyReview,
  getReview,
} from '../../../components/Profile/helpers';
import withRecommendedProfiles from '../../../hocs/clients/withRecommendedProfiles';
import CandidateMiniCard from './CandidateMiniCard';
import { NB_PROFILES_ON_CAROUSEL } from './constants';
import './RecommendedCandidatesDashboard.css';

const getCardContent = (jobOffer, profile) => {
  if (jobOffer && profile) {
    return {
      title: jobOffer.title,
      jobOfferId: jobOffer.id,
      profileId: profile.id,
      creationTimestamp: profile.creationTimestamp,
      firstname: profile.resumeData?.firstname,
      lastname: profile.resumeData?.lastname,
      mainExperience: _.first(profile.resumeData.experiences),
      educationName: profile.resumeData?.mainEducation?.schoolName,
      photoLink: profile.resumeData?.photoLink,
      headline: profile.resumeData?.headline?.content?.text,
      jobWishes: getJobWishes({
        hiresweetData: profile?.resumeData?.sourceData?.hiresweet,
      }),
      firstLineOnlyReview: getFirstLineOnlyReview({
        review: getReview({ profile }),
      }),
    };
  }
  return null;
};

const getJobWishes = ({ hiresweetData }) => {
  const jobWishes = _.map(hiresweetData?.wishes?.jobs?.target, (job) =>
    getTranslatedText(job),
  );
  return _.first(_.compact(jobWishes), 3)
    .join(', ')
    .trim();
};

const getCards = (jobs) => {
  let profiles = [];
  _.each(jobs, (job) => {
    profiles = [
      ...profiles,
      ..._.map(job?.recommendedProfilesFeed?.profiles, (profile) =>
        getCardContent(job, profile),
      ),
    ];
  });
  const sortedProfiles = _.sortBy(_.compact(profiles), {
    creationTimestamp: 1,
  });
  return sortedProfiles.slice(0, NB_PROFILES_ON_CAROUSEL);
};

const RecommendedCandidatesDashboard = ({
  clientId,
  t,
  jobsWithRecommendedProfiles,
  jobsWithRecommendedProfilesLoading,
}) => {
  if (jobsWithRecommendedProfilesLoading) {
    return (
      <RecommendedCandidatesDashboardWrapper clientId={clientId} t={t}>
        <div className='card-container placeholder'>
          <Loader active inline='centered' size='large' />
        </div>
      </RecommendedCandidatesDashboardWrapper>
    );
  }
  const cards = getCards(jobsWithRecommendedProfiles);

  if (_.isEmpty(cards)) {
    return null;
  }

  return (
    <RecommendedCandidatesDashboardWrapper clientId={clientId} t={t}>
      <div className='card-container'>
        {cards.map((cardContent) => (
          <CandidateMiniCard
            key={cardContent.profileId + cardContent.jobOfferId}
            clientId={clientId}
            {...cardContent}
          />
        ))}
        {/* <PlaceholderCard clientId={clientId} t={t} /> */}
      </div>
    </RecommendedCandidatesDashboardWrapper>
  );
};

const RecommendedCandidatesDashboardWrapper = ({ clientId, children, t }) => {
  return (
    <div className='dashboard-section recommended-profiles'>
      <div className='header'>
        <h1>
          {/* <Link to={`/client/${clientId}/discover/recommendations`}>{t('dashboard.recommendedCandidates.header')}</Link> */}
          {t('dashboard.recommendedCandidates.header')}
        </h1>
        {/* <div className='link'> */}
        {/* <Link to={`/client/${clientId}/discover/recommendations`}>{t('dashboard.recommendedCandidates.seeAll')}</Link> */}
        {/* </div> */}
      </div>
      {children}
    </div>
  );
};

export default compose(
  withTranslation('translations'),
  withRecommendedProfiles,
)(RecommendedCandidatesDashboard);
