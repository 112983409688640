import _ from 'underscore';

import {
  PrefixStatisticsNode,
  PrefixTaskType,
} from '@/types/statistics/clientStatistics';
import { getRecordTotal } from '@/common/utils/record';
import { sumNumberRecords } from '@/common/helpers/stats';
import { getEmptyPrefixStats } from '../helpers/kpis';

export const getStatsByPath = (
  stats: PrefixStatisticsNode,
): Record<string, Record<PrefixTaskType, number>> => {
  const result: Record<string, Record<PrefixTaskType, number>> = {};

  const addPathAndRecord = (
    { code, counts, children }: PrefixStatisticsNode,
    prefix?: string,
  ) => {
    const path = prefix ? `${prefix}_${code}` : code;
    result[path] = counts;
    _.each(children, (child) => {
      addPathAndRecord(child, path);
    });
  };

  addPathAndRecord(stats, undefined);

  return result;
};

interface StepStats {
  codes: string[];
  counts: Record<PrefixTaskType, number>;
}

export const getStatsByStepIndex = (
  stats: PrefixStatisticsNode,
): StepStats[] => {
  const resultMap: Record<
    number,
    { codeMap: Record<string, boolean>; counts: Record<PrefixTaskType, number> }
  > = {};

  const addStepStat = (
    { code, counts, children }: PrefixStatisticsNode,
    index: number,
  ) => {
    if (!resultMap[index]) {
      resultMap[index] = {
        codeMap: {},
        counts: getEmptyPrefixStats(),
      };
    }
    resultMap[index].codeMap[code] = true;
    resultMap[index].counts = sumNumberRecords(resultMap[index].counts, counts);
    _.each(children, (child) => {
      addStepStat(child, index + 1);
    });
  };

  addStepStat(stats, 0);

  return _.map(resultMap, ({ codeMap, counts }) => ({
    counts,
    codes: _.map(codeMap, (_v, code) => code),
  }));
};

export const isEmpty = ({ counts, children }: PrefixStatisticsNode): boolean =>
  getRecordTotal(counts) === 0 && _.all(children, isEmpty);

export const isLinear = ({ children }: PrefixStatisticsNode): boolean =>
  _.size(children) <= 1 && _.all(children, isLinear);

const taskTypeFromCode: Record<string, string> = {
  t: 'send-text-message',
  p: 'make-phone-call',
  man: 'manual',
  e: 'send-email-action',
  lcr: 'linkedin-check-request',
  li: 'linkedin-send-request',
  lri: 'linkedin-send-inmail',
  lm: 'linkedin-send-message',
  lsi: 'linkedin-salesnavigator-inmail',
};

export const getTaskTypesFromCodeArray = (codes: string[]): (string | null)[] =>
  _.map(codes, (code) => taskTypeFromCode[code] || null);

export const getTaskTypesFromCodeString = (codes: string): (string | null)[] =>
  getTaskTypesFromCodeArray(codes.split('_'));

export default {};
