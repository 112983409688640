import React, { Component } from 'react';
import _ from 'underscore';
import { Button, Popup } from 'semantic-ui-react';

class CustomRadio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.currentValue,
    };
  }

  handleChange = (option) => {
    const oldValue = this.state.value;
    const newValue = (oldValue || {}).value === option.value ? null : option;
    if (!this.props.noUnselect || newValue) {
      this.setState({ value: newValue });
      const { onChange, label } = this.props;
      onChange({ label, value: newValue });
    }
  };

  render() {
    const { options } = this.props;
    const { value } = this.state;
    return (
      <div className='custom-radio'>
        <div className='hs-button-group'>
          {_.map(options, (option, index) => (
            <Popup
              key={index}
              disabled={!option.tooltip}
              size='tiny'
              position='top right'
              inverted
              trigger={
                <Button
                  className={`custom-radio-button${
                    (value || {}).value === option.value ? ' selected' : ''
                  }`}
                  onClick={() => this.handleChange(option)}
                >
                  {option.label}
                </Button>
              }
            >
              {option.tooltip}
            </Popup>
          ))}
        </div>
      </div>
    );
  }
}

export default CustomRadio;
