import React, { useMemo } from 'react';
import { getRandomString } from '@/common';
import {
  DurationVariableFormat,
  getMergeTagsCalculatedDuration,
  MergeTagsVariable,
  SNIPPET_TYPES,
  VariableExamplePreviewLocale,
} from '@/common/mergeTags/utils';
import ExamplesPreview from '../ExamplesPreview';

const getDurationExampleText = (
  locale: string,
  variable: MergeTagsVariable,
): string => {
  if (!variable) {
    return '';
  }
  if (variable.type === SNIPPET_TYPES.CURRENT_EXPERIENCE_DURATION) {
    if (locale === 'en') {
      return 'I saw that you have been working for {#duration#} in your company';
    }
    if (locale === 'fr') {
      return "J'ai vu que vous travailliez depuis {#duration#} dans votre entreprise";
    }
  }
  if (variable.type === SNIPPET_TYPES.DURATION_SINCE_GRADUATION) {
    if (locale === 'en') {
      return 'I saw you graduated since {#duration#}';
    }
    if (locale === 'fr') {
      return "J'ai vu que vous êtes diplômé depuis {#duration#}";
    }
  }
  return '';
};

type Props = {
  locale: VariableExamplePreviewLocale;
  format: DurationVariableFormat;
  variable: MergeTagsVariable;
};

const DurationVariableExamples: React.FC<Props> = ({
  locale,
  format,
  variable,
}) => {
  const durationExamples = useMemo(() => {
    const text = getDurationExampleText(locale, variable);
    return [
      {
        id: getRandomString(10),
        startDate: '2022-01-10',
        endDate: '2022-08-10',
        text,
        format,
        locale,
      },
      {
        id: getRandomString(10),
        startDate: '2022-01-10',
        endDate: '2022-12-10',
        text,
        format,
        locale,
      },
      {
        id: getRandomString(10),
        startDate: '2022-01-10',
        endDate: '2023-08-10',
        text,
        format,
        locale,
      },
      {
        id: getRandomString(10),
        startDate: '2022-01-10',
        endDate: '2024-03-10',
        text,
        format,
        locale,
      },
    ];
  }, [variable, locale, format]);

  return (
    <ExamplesPreview
      examples={durationExamples}
      calculationFunction={getMergeTagsCalculatedDuration}
      examplePreviewTranslationKey='editor.durationVariable.examples.subtitle'
    />
  );
};

export default DurationVariableExamples;
