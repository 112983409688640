import _ from 'underscore';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { PropsWithChildren, useRef } from 'react';

import GenericTextInput from '../../GenericTextInput';
import GenericButton from '../../GenericButton';

import { CustomIconDescriptor } from '../types';

import styles from './IconDropdown.module.less';

// prettier-ignore
export const ALLOWED_EMOJIS = [
  '💼', '🤖', '👽', '👾', '👀', '👁', '👻', '🕸', '🐾', '💫', '⭐️', '🌟', '✨', '⚡️', '☄️', '💥', '🔥', '🌪', '🌈', '❄️', '🌊', '🏅', '🎖', '🏵', '🎨', '🎬', '🎯', '🎳', '🎮', '🎰', '🧩', '🚨', '🏎', '🚀', '🛸', '⚓️',
  '🚦', '🚥', '🌠', '🎇', '🎆', '⌚️', '📱', '💻', '⌨️', '🖥', '📞', '☎️', '🧭', '⏱', '⏲', '⏰', '🕰', '⌛️', '⏳', '🔌', '💡', '🔦', '🕯', '💎', '⚙️', '✉️', '📩', '📨', '📧', '🪧', '📪', '📥', '📊', '📈', '📉', '🗒',
  '🗓', '📆', '📅', '📖', '🔖', '🧷', '🔗', '📎', '🖇', '📐', '📏', '🧮', '📌', '📍', '🖊', '🖋', '✒️', '🖌', '🖍', '📝', '✏️', '🔍', '🔎', '✴️', '💢', '♨️', '🔅', '🌐', '❇️', '✳️', '⏏️', '▶️', '⏸', '⏯', '⏹', '⏺', '⏭',
  '⏮', '⏩', '⏪', '⏫', '⏬', '◀️', '🔼', '🔽', '➡️', '⬅️', '⬆️', '⬇️', '↗️', '↘️', '↙️', '↖️', '↪️', '↩️', '⤴️', '⤵️', '🔀', '🔁', '🔂', '🔄', '🔃', '🟥', '🟧', '🟨', '🟩', '🟦', '🟪', '⬛️', '⬜️', '🟫', '🏳️', '🏁',
] as const;

export const HIRESWEET_ICONS = [
  'call-task',
  'email-task',
  'linkedin-recruiter-task',
  'linkedin-salesnavigator-task',
  'linkedin-task',
  'manual-task',
  'sms-task',
];

export type IconEmoji = typeof ALLOWED_EMOJIS[number];
export type HireSweetIcon = typeof HIRESWEET_ICONS[number];

export const BasicIcon: React.FC<{
  emoji?: IconEmoji;
  src?: string;
  onClick?: () => void;
  small?: boolean;
}> = ({ emoji, src, onClick, small }) => {
  if (emoji) {
    return (
      <span
        onClick={onClick}
        className={classNames('task-icon', styles.taskIcon, {
          [styles.clickable]: Boolean(onClick),
          [styles.small]: small,
        })}
      >
        {emoji}
      </span>
    );
  }

  return (
    <img
      onClick={onClick}
      className={classNames('task-icon', styles.taskIcon, {
        [styles.clickable]: Boolean(onClick),
      })}
      src={src}
      alt='task icon'
    />
  );
};

export function CustomIcon({
  icon,
  onClick,
  small,
}: {
  icon: CustomIconDescriptor;
  onClick?: () => void;
  small?: boolean;
}) {
  if (!icon) {
    return null;
  }

  const { type, emojiText, url, id } = icon;

  if (type === 'url') {
    return <BasicIcon small={small} onClick={onClick} src={url} />;
  }

  if (type === 'emoji') {
    return (
      <BasicIcon
        small={small}
        onClick={onClick}
        emoji={emojiText as IconEmoji}
      />
    );
  }

  const builtInUrl = `/images/icons/tasks/${id}.svg`;
  return <BasicIcon small={small} onClick={onClick} src={builtInUrl} />;
}

const Category: React.FC<{ label: string }> = ({ label, children }) => {
  return (
    <div className={styles.category}>
      <div className={styles.categoryLabel}>{label}</div>
      {children}
    </div>
  );
};

const IconCategory: React.FC<{
  label: string;
  icons: CustomIconDescriptor[];
  onIconSelected: (icon: CustomIconDescriptor) => void;
}> = ({ label, icons, onIconSelected }) => {
  return (
    <Category label={label}>
      <div className={styles.iconList}>
        {_.map(icons, (icon) => {
          return (
            <CustomIcon
              key={icon.emojiText || icon.id || icon.url}
              onClick={() => onIconSelected(icon)}
              icon={icon}
            />
          );
        })}
      </div>
    </Category>
  );
};

const emojiIcons: CustomIconDescriptor[] = _.map(ALLOWED_EMOJIS, (emoji) => ({
  type: 'emoji' as CustomIconDescriptor['type'],
  emojiText: emoji,
}));

const hiresweetIcons: CustomIconDescriptor[] = _.map(
  HIRESWEET_ICONS,
  (hsIcon) => ({
    type: 'built-in' as CustomIconDescriptor['type'],
    id: hsIcon,
  }),
);

export function IconDropdown({
  onIconSelected,
}: PropsWithChildren<{
  onIconSelected: (icon: CustomIconDescriptor) => void;
}>) {
  const { t } = useTranslation();

  const urlInputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={styles.container}>
      <IconCategory
        label={t('reveal.iconDropdown.hiresweet')}
        onIconSelected={onIconSelected}
        icons={hiresweetIcons}
      />
      <IconCategory
        onIconSelected={onIconSelected}
        icons={emojiIcons}
        label={t('reveal.iconDropdown.emoji')}
      />
      <Category label={t('reveal.iconDropdown.url')}>
        <GenericTextInput
          small
          className={styles.urlInput}
          ref={urlInputRef}
          placeholder={t('reveal.iconDropdown.urlPlaceholder')}
        />
        <div className={styles.useUrlContainer}>
          <GenericButton
            onClick={() => {
              onIconSelected({
                type: 'url',
                url: urlInputRef.current?.value,
              });
            }}
          >
            {t('reveal.iconDropdown.useUrl')}
          </GenericButton>
        </div>
      </Category>
    </div>
  );
}
