import { useQuery, QueryHookOptions, ApolloError } from '@apollo/client';
import SEARCH_POOL_MINI_PROFILE, {
  SearchPoolMiniProfile,
  SearchPoolMiniProfileResult,
} from '@/graphql/searchPoolMiniProfileById';

interface UseSearchPoolMiniProfileByIdParams {
  searchPoolId: string;
  profileId: string;
}

const useSearchPoolMiniProfileById = (
  { searchPoolId, profileId }: UseSearchPoolMiniProfileByIdParams,
  options?: Omit<
    QueryHookOptions<
      SearchPoolMiniProfileResult,
      UseSearchPoolMiniProfileByIdParams
    >,
    'variables'
  >,
): {
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
  profile: SearchPoolMiniProfile | undefined;
} => {
  const { data, loading, error, refetch } = useQuery<
    SearchPoolMiniProfileResult,
    {
      searchPoolId: string;
      profileId: string;
    }
  >(SEARCH_POOL_MINI_PROFILE, {
    variables: {
      searchPoolId,
      profileId,
    },
    ...options,
  });
  return {
    loading,
    error,
    refetch,
    profile: data?.searchPool?.profile,
  };
};

export default useSearchPoolMiniProfileById;
