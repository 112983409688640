import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SmallResumeData from '../../graphql/fragments/SmallResumeData';
import PipeStepStat from '../../graphql/fragments/PipeStepStat';

export const updateOfferProfilesCache = gql`
  query updateOfferProfilesCache(
    $offerId: ID!
    $step: String!
    $search: String!
  ) {
    offer(id: $offerId) {
      id
      pipeStepStats {
        ...PipeStepStat
      }
      profiles(step: $step, search: $search) {
        id
        resumeData {
          ...SmallResumeData
        }
        powerHourFlags {
          powerHourId
          isFlagged
        }
        firstContactDate
        lastStepUpdateTimestamp
      }
    }
  }
  ${SmallResumeData}
  ${PipeStepStat}
`;

export const changeProfileOffer = gql`
  mutation changeProfileOffer($id: ID!, $newOfferId: ID!) {
    changeProfileOffer(id: $id, newOfferId: $newOfferId) {
      id
      lastStepUpdateTimestamp
      jobOfferId
      offer {
        id
      }
      contactData {
        draftComment
      }
    }
  }
`;

export default graphql(changeProfileOffer, {
  props: ({ ownProps, mutate }) => ({
    changeProfileOffer: async ({ profileId, newOfferId }) =>
      mutate({
        variables: { id: profileId, newOfferId },
        refetchQueries: [
          {
            query: updateOfferProfilesCache,
            variables: {
              // ownProps represents current state, which must be refreshed
              offerId: ownProps.offerId,
              step: ownProps.stepId,
              search: ownProps.searchText,
            },
            fetchPolicy: 'network-only',
          },
          {
            query: updateOfferProfilesCache,
            variables: {
              offerId: ownProps.offerId,
              step: ownProps.stepId,
              search: '',
            },
            fetchPolicy: 'network-only',
          },
          {
            query: updateOfferProfilesCache,
            variables: {
              offerId: ownProps.offerId,
              step: '',
              search: '',
            },
            fetchPolicy: 'network-only',
          },
          // also refresh destination offer
          {
            query: updateOfferProfilesCache,
            variables: {
              offerId: newOfferId,
              step: '',
              search: '',
            },
            fetchPolicy: 'network-only',
          },
          {
            query: updateOfferProfilesCache,
            variables: {
              offerId: newOfferId,
              step: '',
              // query will fire again only if searchText !== ''
              search: ownProps.searchText,
            },
            fetchPolicy: 'network-only',
          },
        ],
      }),
  }),
});
