import gql from 'graphql-tag';

import TranslatableTextFragment from '@/graphql/fragments/TranslatableText';
import { PipelineSubStepsDefinition } from '@/types/pipelineSubSteps';
import {
  MutationHookOptions,
  FetchResult,
  MutationResult,
  useMutation,
} from '@apollo/client';

const updateClientPipelineSubSteps = gql`
  mutation updateClientPipelineSubSteps($input: ClientPipelineSubStepsInput!) {
    updateClientPipelineSubSteps(input: $input) {
      id
      pipelineSubSteps {
        missionCategory {
          type
        }
        stepId
        subSteps {
          id
          title {
            ...TranslatableText
          }
        }
      }
    }
  }
  ${TranslatableTextFragment}
`;

type Data = {
  client: {
    id: string;
    pipelineSubSteps: PipelineSubStepsDefinition[];
  };
};

type Variables = {
  input: PipelineSubStepsDefinition;
};

type Input = {
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

type Result = [
  (input: Variables['input']) => Promise<FetchResult<Data>>,
  MutationResult<Data>,
];

function useUpdateClientPipelineSubSteps({
  mutationOptions = {},
}: Input = {}): Result {
  const [mutation, result] = useMutation<Data, Variables>(
    updateClientPipelineSubSteps,
    mutationOptions,
  );

  return [(input) => mutation({ variables: { input } }), result];
}

export default useUpdateClientPipelineSubSteps;
