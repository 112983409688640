import React, { Dispatch, SetStateAction } from 'react';
import _ from 'underscore';
import { Table } from 'semantic-ui-react';
import { LinkedInAccount } from '@/types/LinkedInAccount';

import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import styles from './LinkedinAccountsTable.module.less';
import LinkedinAccountsTableHeader from './LinkedinAccountsTableHeader';
import LinkedinAccountsTableBody from './LinkedinAccountsTableBody';

interface LinkedinAccountsTableProps {
  linkedinAccounts: LinkedInAccount[];
  withEdit: boolean;
  onEditLinkedinAccount: ({
    linkedinAccountId,
  }: {
    linkedinAccountId: string;
  }) => void;
  onDeleteLinkedinAccount: ({
    linkedinAccountId,
  }: {
    linkedinAccountId: string;
  }) => void;
  onShowConnectModal: Dispatch<SetStateAction<boolean>>;
  setAddressToConnect: Dispatch<SetStateAction<string>>;
}
const LinkedinAccountsTable: React.FC<LinkedinAccountsTableProps> = ({
  linkedinAccounts,
  withEdit,
  onEditLinkedinAccount,
  onDeleteLinkedinAccount,
  onShowConnectModal,
  setAddressToConnect,
}) => {
  const { user } = useCurrentUser();

  if (_.isEmpty(linkedinAccounts)) {
    return null;
  }

  return (
    <Table
      className={styles.linkedinAccountsSettingsTable}
      textAlign='center'
      basic
    >
      <LinkedinAccountsTableHeader withEdit={withEdit} />
      <LinkedinAccountsTableBody
        linkedinAccounts={linkedinAccounts}
        withEdit={withEdit}
        onEditLinkedinAccount={onEditLinkedinAccount}
        onDeleteLinkedinAccount={onDeleteLinkedinAccount}
        user={user}
        onShowConnectModal={onShowConnectModal}
        setAddressToConnect={setAddressToConnect}
      />
    </Table>
  );
};

export default LinkedinAccountsTable;
