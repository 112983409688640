import { gql } from '@apollo/client';

export const GET_USER_CONFIGURATION_PARAMS = gql`
  query getUserConfigurationParams {
    user {
      id
      email
      configurationParams {
        attributes {
          key
          value
        }
      }
    }
  }
`;
