import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import usePollInstantFlowStatus from '@/graphql/hooks/offers/usePollInstantFlowStatus';
import Spinner from '@/components/Common/Icons/Spinner';

import styles from './InstantFlowStatusGuard.module.less';

interface InstantFlowStatusGuardProps {
  offerId: string;
  onSuccess?: () => void;
  onError?: () => void;
  bypass?: boolean;
}

const InstantFlowStatusGuard: FC<InstantFlowStatusGuardProps> = ({
  offerId,
  onSuccess,
  onError,
  bypass,
  children,
}) => {
  const { t } = useTranslation();
  const instantFlowStatus = usePollInstantFlowStatus(offerId, {
    onSuccess: bypass ? undefined : onSuccess,
    onError: bypass ? undefined : onError,
  });

  if (bypass || instantFlowStatus !== 'pending') {
    return <>{children}</>;
  }

  return (
    <div className={styles.page}>
      <div className={styles.emptyState}>
        <div className={styles.loader}>
          <Spinner />
        </div>
        <div className={styles.message}>{t('offers.instantFlow.waiting')}</div>
      </div>
    </div>
  );
};

export default InstantFlowStatusGuard;
