import React, { useMemo } from 'react';
import _ from 'underscore';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import { MergeTagsVariable, SNIPPET_TYPES } from '@/common/mergeTags/utils';
import styles from './SequenceDynamicVariables.module.less';
import SequenceVariablePopup from './components/SequenceVariablePopup';

type Props = {
  clientId: string;
};

const VARIABLE_GROUPED_BY_NAME = [
  SNIPPET_TYPES.PLACEHOLDER,
  SNIPPET_TYPES.CUSTOM_FIELD,
];

const NON_DISPLAYED_VARIABLES = [SNIPPET_TYPES.SYNCED_FRAGMENT];

const SequenceDynamicVariables: React.FC<Props> = ({ clientId }) => {
  const { currentSequenceWithDeepActionsSnippets } = useCandidateViewContext();
  const { t } = useTranslation();
  const isPlugin = useIsPlugin();

  const sequenceDynamicVariables = useMemo(() => {
    const snippets: Record<string, MergeTagsVariable> = {};
    const snippetsByType: Record<string, MergeTagsVariable[]> = {};

    _.each(currentSequenceWithDeepActionsSnippets?.actions || [], (action) => {
      if (action.completion?.isCompleted) {
        return;
      }

      _.each(action.snippets, (snippet) => {
        const {
          id,
          name,
          type,
          state,
          fallbackValue,
        } = snippet as MergeTagsVariable;

        if (_.includes(NON_DISPLAYED_VARIABLES, type)) {
          return;
        }

        if (!snippetsByType[type]) {
          snippetsByType[type] = [];
        }

        if (_.includes(VARIABLE_GROUPED_BY_NAME, type)) {
          if (snippets[name]) {
            return;
          }
          snippets[name] = snippet;
          return;
        }

        const isSameFallbackOrStateAndName = _.find(
          snippetsByType[type],
          (targetSnippet) => {
            const isSameName = targetSnippet.name === name;
            if (!_.isEmpty(snippet.state?.value)) {
              return targetSnippet.state?.value === state?.value && isSameName;
            }
            return (
              targetSnippet.fallbackValue?.text === fallbackValue?.text &&
              isSameName
            );
          },
        );

        if (!isSameFallbackOrStateAndName) {
          snippets[id] = snippet;
        }

        snippetsByType[type].push(snippet);
      });
    });
    return {
      sequenceVariables: _.values(snippets) as SweetEvaluatorTypes.Variable[],
      snippetsByType,
    };
  }, [currentSequenceWithDeepActionsSnippets]);

  const { sequenceVariables, snippetsByType } = sequenceDynamicVariables;

  if (_.isEmpty(sequenceVariables)) {
    return <></>;
  }

  return (
    <div style={{ marginTop: isPlugin ? '25px' : '0px' }}>
      <h4>{t('reveal.mergeTags.sequenceVariables.title')}</h4>
      <div
        className={classNames(
          styles.dynamicVariablesWrapper,
          isPlugin ? styles.dynamicVariablesWrapperPlugin : '',
        )}
      >
        {_.map(sequenceVariables, (variable) => (
          <SequenceVariablePopup
            clientId={clientId}
            key={variable.id}
            variable={variable}
            sequenceSnippetsByType={snippetsByType}
          />
        ))}
      </div>
    </div>
  );
};

export default SequenceDynamicVariables;
