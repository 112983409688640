import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Tag from '@/components/Common/Tag';

const DefaultHeader: FC = () => {
  const { t } = useTranslation();

  return <Tag>{t('trial.onboarding.pill')}</Tag>;
};

export default DefaultHeader;
