import React from 'react';
import Senders from './Senders';
import SettingsLayout from '../../SettingsLayout';

const SendersSettings = ({ clientId }) => {
  return (
    <SettingsLayout
      currentPage='senders'
      clientId={clientId}
      className='settings-container'
    >
      <div className='settings'>
        <Senders clientId={clientId} withOwnedSenders withGrantedSenders />
      </div>
    </SettingsLayout>
  );
};

export default SendersSettings;
