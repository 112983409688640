import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SearchPoolProfileState from '../../graphql/fragments/SearchPoolProfileState';

export const markSeen = gql`
  mutation markSeen($searchPoolId: ID!, $profileId: ID!) {
    markSeen(searchPoolId: $searchPoolId, profileId: $profileId) {
      ...SearchPoolProfileState
    }
  }
  ${SearchPoolProfileState}
`;

export default graphql(markSeen, {
  props: ({ mutate }) => ({
    markSeen: ({ searchPoolId, profileId }) =>
      mutate({
        variables: { searchPoolId, profileId },
      }),
  }),
});
