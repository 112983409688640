/* eslint-disable class-methods-use-this */
import { EvaluatorInterface, BaseVariable, Variable } from '../../types';

export default class ExpressionSimpleEvaluator implements EvaluatorInterface {
  evaluate({
    expression,
  }: {
    expression: Variable;
  }): { value: string | undefined } {
    const expressionType = expression as BaseVariable;
    return {
      value: expressionType.state?.value,
    };
  }
}
