import React from 'react';
import { useTranslation } from 'react-i18next';
import Thumbnail from '../../../../../components/Thumbnail';
import { formatDate } from '../../../../../common/dates';
import TASK_ICONS from '../TaskIcons';
import AuthorAndDate from '../AuthorAndDate';

// TODO: use actual action?.jobOfferId for wording
const ClassicPluginImportItem = ({ item, unbiasedModeEnabled, profile }) => {
  const { t } = useTranslation();
  const { author, date, action } = item || {};
  const { firstname, lastname, photoLink, email } = author || {};
  const { firstname: profileFirstname, lastname: profileLastname } =
    profile?.resumeData || {};

  const recruiterName = `${firstname || ''} ${lastname || ''}`.trim();
  let profileName = `${profileFirstname || ''} ${profileLastname || ''}`.trim();
  profileName = unbiasedModeEnabled ? '???' : profileName;

  const sourceName = action?.sourceId ? t(`common.${action?.sourceId}`) : '';

  return (
    <div
      className='timeline-card-mail-wrapper classic-item'
      style={{ bottom: 0 }}
    >
      <div className='content'>
        <div className='header'>
          <Thumbnail
            link={photoLink}
            firstname={firstname}
            lastname={lastname}
            email={email}
          />
          <div className='author'>
            <div className='date'>{date && formatDate(date, t)}</div>
            <div
              dangerouslySetInnerHTML={{
                __html: sourceName
                  ? t('profile.contact.timeline.pluginImportItem.from', {
                      recruiterName,
                      profileName,
                      sourceName,
                    })
                  : t('profile.contact.timeline.pluginImportItem.description', {
                      recruiterName,
                      profileName,
                    }),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const RevealPluginImportItem = ({ item, unbiasedModeEnabled, profile }) => {
  const { t } = useTranslation();
  const { author, action } = item || {};
  const { firstname: profileFirstname, lastname: profileLastname } =
    profile?.resumeData || {};

  const recruiterName = `${author?.firstname || ''} ${author?.lastname ||
    ''}`.trim();
  let profileName = `${profileFirstname || ''} ${profileLastname || ''}`.trim();
  profileName = unbiasedModeEnabled ? '???' : profileName;
  const sourceName = action?.sourceId ? t(`common.${action?.sourceId}`) : '';

  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{ backgroundColor: TASK_ICONS['new-contact'].color }}
              className='icon'
            >
              <i className={`${TASK_ICONS['new-contact'].icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            <div
              dangerouslySetInnerHTML={{
                __html: sourceName
                  ? t('profile.contact.timeline.pluginImportItem.from', {
                      recruiterName,
                      profileName,
                      sourceName,
                    })
                  : t('profile.contact.timeline.pluginImportItem.description', {
                      recruiterName,
                      profileName,
                    }),
              }}
            />
            <AuthorAndDate timelineItem={item} />
          </div>
        </div>
      </div>
    </div>
  );
};

const PluginImportItem = ({
  profile,
  item,
  unbiasedModeEnabled,
  applicationMode,
}) => {
  if (applicationMode === 'classic') {
    return (
      <ClassicPluginImportItem
        profile={profile}
        unbiasedModeEnabled={unbiasedModeEnabled}
        item={item}
      />
    );
  }
  return (
    <RevealPluginImportItem
      profile={profile}
      item={item}
      unbiasedModeEnabled={unbiasedModeEnabled}
    />
  );
};

export default PluginImportItem;
