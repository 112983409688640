import React, { FC, useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import useTextingSenders from '@/graphql/hooks/clients/useTextingSenders';
import useLocalStorage from '@/hooks/storage/useLocalStorage';
import GenericButton from '@/components/Common/GenericButton';
import { TextMessageActionExecution } from '@/types/action';
import SlimSelect from '@/components/Common/SlimSelect';

import EmailActionItem from '../EmailActionItem';

import styles from './TextMessageForm.module.less';

type TextMessageFormProps = {
  profilePhoneNumber?: string;
  onSubmit: () => void;
  onExecution: (input: TextMessageActionExecution) => Promise<void>;
  message: string;
  snippets: unknown;
  completeTaskLoading: boolean;
};

type TextingSenderConfig = null | {
  senderId: string;
};

const TextMessageForm: FC<TextMessageFormProps> = ({
  profilePhoneNumber,
  onSubmit,
  onExecution,
  message,
  snippets,
  completeTaskLoading,
}) => {
  const { t } = useTranslation();
  const { textingSenders } = useTextingSenders();

  const [textingSenderConfig, setTextingSenderConfig] = useLocalStorage<
    TextingSenderConfig
  >('revealTextingSender', null);

  const selectedTextingSender = useMemo(
    () =>
      textingSenderConfig
        ? _.findWhere(textingSenders, { id: textingSenderConfig.senderId })
        : textingSenders[0],
    [textingSenders, textingSenderConfig],
  );

  const handleSubmit = async () => {
    if (!selectedTextingSender || !profilePhoneNumber) {
      return;
    }

    await onExecution({
      to: profilePhoneNumber,
      senderId: selectedTextingSender.id,
    });

    onSubmit();
  };

  if (!selectedTextingSender || !profilePhoneNumber) {
    return null;
  }

  return (
    <>
      <div className={styles.field}>
        <span className={styles.label}>
          {t('reveal.candidatesView.timeline.textMessageTask.from')}
        </span>
        <SlimSelect
          value={selectedTextingSender.id}
          options={_.map(textingSenders, ({ senderNumber, id }) => ({
            id,
            label: senderNumber,
          }))}
          onValue={(senderId) => setTextingSenderConfig({ senderId })}
        />
      </div>
      <div className={styles.field}>
        <span className={styles.label}>
          {t('reveal.candidatesView.timeline.textMessageTask.to')}
        </span>
        <span className={styles.phoneNumber}>{profilePhoneNumber}</span>
      </div>
      {message && (
        <div className={styles.message}>
          <EmailActionItem
            message={message}
            instantiatedSnippets={snippets}
            showSubject={false}
          />
        </div>
      )}
      <GenericButton
        disabled={completeTaskLoading}
        className={styles.submit}
        onClick={handleSubmit}
      >
        {t('reveal.candidatesView.timeline.textMessageTask.send')}
      </GenericButton>
    </>
  );
};

export default TextMessageForm;
