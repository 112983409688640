import React, { useRef } from 'react';
import classNames from 'classnames';
import useCopyToClipboard from '@/hooks/common/useCopyToClipboard';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import styles from './CopyToClipboardSection.module.less';

interface Props {
  textToCopy?: string;
  textToDisplay: string;
  displayText?: boolean;
  isActiveTarget?: boolean;
  onCopy?: () => void;
}

const CopyToClipboardSection: React.FC<Props> = ({
  textToCopy,
  textToDisplay,
  displayText,
  onCopy,
  isActiveTarget,
}) => {
  const ref = useRef(null);
  const isPlugin = useIsPlugin();

  const { copied, handleCopy } = useCopyToClipboard();
  const { isMinimized } = useMinimizedView();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleCopy(textToCopy || textToDisplay);
    if (onCopy) {
      onCopy();
    }
  };

  return (
    <div className={styles.copyToClipboardWrapper} ref={ref}>
      {displayText && (
        <span
          className={classNames(
            styles.textToCopy,
            isActiveTarget && isPlugin && 'clipboard-active-target-command',
            isMinimized ? styles.minimized : '',
          )}
          onClick={handleClick}
        >
          {textToDisplay}
        </span>
      )}

      {!displayText && (
        <div
          className={classNames(
            styles.copyToClipboard,
            isActiveTarget && isPlugin && 'clipboard-active-target-command',
            isMinimized ? styles.minimized : '',
          )}
          onClick={handleClick}
        >
          <i className={`ri-${!copied ? 'file-copy-fill' : 'check-line'}`} />
        </div>
      )}
    </div>
  );
};

export default CopyToClipboardSection;
