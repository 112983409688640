import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

const QuestionMark = (
  <Icon
    color='blue'
    name='question circle outline'
    style={{
      marginLeft: 5,
      cursor: 'pointer',
      opacity: 0.7,
    }}
  />
);

const InfoTooltip = ({ text }: { text: string }) => {
  return (
    <Popup
      size='tiny'
      position='top center'
      inverted
      content={text}
      trigger={QuestionMark}
    />
  );
};

export default InfoTooltip;
