import {
  FetchResult,
  gql,
  MutationHookOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import { SharingSettings, Scope, SavedSearch } from '@/types/savedSearch';
import RevealSavedSearchFragment from '@/graphql/fragments/RevealSavedSearch';
import { GET_SAVED_SEARCHES } from './useSavedSearches';

const CREATE_SAVED_SEARCH = gql`
  mutation createSavedSearch($input: CreateRevealSavedSearchInput!) {
    revealSavedSearch {
      create(input: $input) {
        revealSavedSearch {
          ...RevealSavedSearch
        }
      }
    }
  }
  ${RevealSavedSearchFragment}
`;

interface CreateSavedSearchData {
  revealSavedSearch: {
    create: {
      revealSavedSearch: SavedSearch;
    };
  };
}

interface CreateSavedSearchVariables {
  input: {
    title: string;
    sharingSettings: SharingSettings;
    scope: Scope;
    criteria: {
      free?: string;
    };
  };
}

interface UseCreateSavedSearchInput {
  mutationOptions?: MutationHookOptions<
    CreateSavedSearchData,
    CreateSavedSearchVariables
  >;
}

type UseCreateSavedSearchResult = [
  (
    input: CreateSavedSearchVariables['input'],
  ) => Promise<FetchResult<CreateSavedSearchData>>,
  MutationResult<CreateSavedSearchData>,
];

const useCreateSavedSearch = ({
  mutationOptions = {},
}: UseCreateSavedSearchInput = {}): UseCreateSavedSearchResult => {
  const [mutation, result] = useMutation<
    CreateSavedSearchData,
    CreateSavedSearchVariables
  >(CREATE_SAVED_SEARCH, {
    refetchQueries: [GET_SAVED_SEARCHES],
    ...mutationOptions,
  });

  return [(input) => mutation({ variables: { input } }), result];
};

export default useCreateSavedSearch;
