import React, { useContext, useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { SNIPPET_TYPES } from '@/common/mergeTags/utils';

import ContactCategoryContext from '@/context/ContactTypeContext';
import { MergeTagsSelectorOption } from '../utils';
import MergeTagsVariablesOptions from './MergeTagsVariableOptions';

import styles from '../MergeTagsSelector.module.less';

interface Props {
  clientId: string;
  onSelectVariable: (variable: MergeTagsSelectorOption) => void;
  hasPermission: boolean;
}

const MergeTagsCustomFieldsOptions: React.FC<Props> = ({
  clientId,
  onSelectVariable,
  hasPermission,
}) => {
  const { t } = useTranslation();
  const {
    profileCustomFields: clientCustomFields,
  } = useClientProfileCustomFields(clientId);

  const contactCategory = useContext(ContactCategoryContext);

  const customFields = useMemo(
    () =>
      _.filter(
        clientCustomFields,
        (field) =>
          (contactCategory === 'human' && !field.contactCategory) ||
          field.contactCategory?.type === contactCategory,
      ),
    [clientCustomFields, contactCategory],
  );

  const customFieldsOptions: MergeTagsSelectorOption[] = useMemo(() => {
    return _.map(customFields || [], (cf) => ({
      key: cf.id,
      label: cf.title.default || cf.id,
      type: SNIPPET_TYPES.CUSTOM_FIELD,
    }));
  }, [customFields]);

  if (_.isEmpty(customFieldsOptions)) {
    return null;
  }

  return (
    <>
      <hr className={styles.hr} />
      <span className={styles.sectionTitle}>
        {t('editor.mergeTagsSelector.contactCustomFields')}
      </span>
      <MergeTagsVariablesOptions
        options={customFieldsOptions}
        onSelectVariable={onSelectVariable}
        hasPermission={hasPermission}
      />
    </>
  );
};

export default MergeTagsCustomFieldsOptions;
