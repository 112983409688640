import React, { useEffect, useRef, useState } from 'react';
import { Dimmer, Input, Modal, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import GenericModal from '@/components/Common/GenericModal';

import CustomFileInput from '@/components/CustomFileInput';
import { UPLOAD_FILE } from '@/graphql/uploadFile';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import GenericButton from '@/components/Common/GenericButton';
import { isURL, toBase64 } from '../helpers';
import styles from './InsertImageModal.module.less';

const IMAGE_MAX_SIZE = 4_000_000;

const InsertImageModal = ({ open, onClose, handleImageInsertion }) => {
  const { t } = useTranslation();
  const isValidUrl = useRef(true);
  const isValidSize = useRef(true);
  const [imageUrl, setImageUrl] = useState('');
  const [error, setError] = useState(null);
  const notification = useNotificationSystem();

  const [uploadFile, { loading: uploadFileLoading }] = useMutation(UPLOAD_FILE);

  useEffect(() => {
    if (imageUrl) {
      setImageUrl('');
    }
    if (error) {
      setError(null);
    }
    // eslint-disable-next-line
  }, [open]);

  const handleSubmit = () => {
    let err;
    if (!isURL(imageUrl)) {
      isValidUrl.current = false;
      err = 'invalidUrl';
    } else {
      isValidUrl.current = true;
      err = '';
    }

    if (err) {
      setError(err);
      return;
    }

    handleImageInsertion(imageUrl);
    onClose();
  };

  const onDrop = async (files) => {
    const file = files?.[0];
    if (!file) {
      return;
    }

    if (file.size > IMAGE_MAX_SIZE) {
      isValidSize.current = false;
      setError('imageInvalidSize');
      return;
    }
    try {
      const base64 = await toBase64(file);
      const response = await uploadFile({
        variables: {
          fileContent: base64,
          filename: file.name,
        },
      });
      const uploadedFileUrl = response?.data?.uploadFile;
      if (!uploadedFileUrl) {
        notification.error(t('common.genericError'));
        return;
      }
      handleImageInsertion(uploadedFileUrl);
      onClose();
    } catch (err) {
      notification.error(t('common.genericError'));
    }
  };

  return (
    <GenericModal size='small' open={open} onClose={onClose}>
      <Modal.Header>
        {t('editor.insertImageModal.title')}
        <span className={styles.modalHeaderSubtitle}>
          {t('editor.insertImageModal.imageMaxSize')}
        </span>
      </Modal.Header>
      <Modal.Content>
        <Dimmer.Dimmable
          as={Segment}
          loading={uploadFileLoading}
          dimmed={uploadFileLoading}
        >
          <div className={styles.container}>
            <p className={styles.title}>
              {t('editor.insertImageModal.addUrl')}
            </p>
            {error === 'invalidUrl' && (
              <span className={styles.error}>
                {t('editor.insertImageModal.invalidUrl')}
              </span>
            )}
            <Input
              value={imageUrl}
              fluid
              onChange={(e, { value }) => {
                setImageUrl(value);
              }}
              error={error === 'invalidUrl'}
            />
          </div>
          <div className={styles.container}>
            <p className={classNames(styles.title, styles.centered)}>
              {t('common.or').toUpperCase()}
            </p>
          </div>
          <div className={styles.container}>
            {error === 'imageInvalidSize' && (
              <span className={styles.error}>
                {t('editor.insertImageModal.imageInvalidSize')}
              </span>
            )}
            <CustomFileInput
              onChange={onDrop}
              fileTypes='image/*'
              description={t('editor.insertImageModal.dragAndDrop')}
              buttonText={t('editor.insertImageModal.selectImage')}
              t={t}
            />
          </div>
        </Dimmer.Dimmable>
      </Modal.Content>
      <Modal.Actions>
        <GenericButton type='button' primacy='secondary' onClick={onClose}>
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          type='submit'
          primary
          onClick={handleSubmit}
          disabled={!imageUrl.length}
        >
          {t('common.save')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default InsertImageModal;
