import React, { useEffect, useState } from 'react';
import _ from 'underscore';
import { getRandomString } from '@/common';
import { MergeTagsVariable } from '@/common/mergeTags/utils';
import {
  getSweetEvaluator,
  SweetEvaluatorTypes,
} from '../../../../SweetEvaluator';
import { getExternalExpressionEvaluatorFromType } from '../externalEvaluators';

const DEFAULT_CONTEXT = {
  begin: 'Début ',
  middle: '-Milieu-',
  end: 'Fin',
  experiences: [
    {
      companyName: 'Entrepreneurs dans la Ville',
      title: {
        text: 'Service Civique',
      },
      startDate: {
        raw: '2019-09-15',
        month: 8,
        year: 2019,
      },
    },
    {
      companyName: 'Siam Shipping',
      title: {
        text: 'Assistante Marketing & Logistique',
      },
      startDate: {
        raw: '2017-08-15',
        month: 7,
        year: 2017,
      },
      endDate: {
        raw: '2017-12-15',
        month: 11,
        year: 2017,
      },
    },
    {
      companyName: 'emlyon business school',
      title: {
        text: 'Présidente du Bureau des Elèves',
      },
      startDate: {
        raw: '2018-01-15',
        month: 0,
        year: 2018,
      },
      endDate: {
        raw: '2018-12-15',
        month: 11,
        year: 2018,
      },
    },
    {
      companyName: 'RB',
      title: {
        text: 'Assistante Chef de Produit Harpic',
      },
      startDate: {
        raw: '2019-01-15',
        month: 0,
        year: 2019,
      },
      endDate: {
        raw: '2019-06-15',
        month: 5,
        year: 2019,
      },
    },
  ],
};

const DEFAULT_SNIPPETS: MergeTagsVariable[] = [
  {
    id: 'alpha',
    type: 'path',
    path: ['begin'],
    state: { value: 'Lancement', isLocked: true },
    name: 'Alpha',
  },
  {
    id: 'beta',
    name: 'Beta',
    type: 'concat',
    children: [
      { type: 'variable', name: 'test', variableId: 'alpha', id: 'child1' },
      { type: 'variable', name: 'test', variableId: 'delta', id: 'child2' },
    ],
  },
  {
    id: 'gamma',
    name: 'Gamma',
    type: 'concat',
    children: [
      { type: 'variable', name: 'test', variableId: 'beta', id: 'child1' },
      { type: 'path', name: 'test', path: ['end'], id: 'child2' },
    ],
  },
  {
    id: 'delta',
    name: 'Delta',
    type: 'path',
    path: ['middle'],
  },
  {
    id: 'fallback',
    name: 'Test Fallback',
    type: 'path',
    path: ['notExisting'],
    fallbackValue: { type: 'text', text: 'Fallback value' },
  },
  {
    id: 'lastXpCompany',
    name: 'Current Company',
    type: 'current-company',
    fallbackValue: { type: 'text', text: 'ton entreprise actuelle' },
  },
];

const DEFAULT_TEXT = `{{alpha}},   
{{beta}},   
{{gamma}},   
{{delta}}, 
Test Fallback Value => {{fallback}},
Test lastXpCompany => {{lastXpCompany}} 
Test firstXpCompany externalEvaluator => {{firstXpCompany}} 
  `;

interface ContextInterface {
  context: SweetEvaluatorTypes.Context;
  setContext: (newContext: SweetEvaluatorTypes.Context) => void;
  snippets: MergeTagsVariable[];
  addSnippet: () => void;
  updateSnippet: (snippet: MergeTagsVariable) => void;
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  displayedText: string;
  shouldEvaluateWhenContextChange: boolean;
  setShouldEvaluateWhenContextChange: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  evaluateSnippets: () => void;
}

const MergeTagsPlaygroundContext = React.createContext<ContextInterface | null>(
  null,
);

const MergeTagsPlaygroundProvider: React.FC = ({ children }) => {
  const [context, setContext] = useState<SweetEvaluatorTypes.Context>(
    DEFAULT_CONTEXT,
  );

  const [snippets, setSnippets] = useState<MergeTagsVariable[]>(
    DEFAULT_SNIPPETS,
  );

  const [text, setText] = useState(DEFAULT_TEXT);
  const [
    shouldEvaluateWhenContextChange,
    setShouldEvaluateWhenContextChange,
  ] = useState(false);

  const getUpdatedVariables = () => {
    const sweetEvaluator = getSweetEvaluator({
      getExternalExpressionEvaluatorFromType,
    });
    return sweetEvaluator.getUpdatedVariables({
      context,
      variables: snippets as SweetEvaluatorTypes.Variable[],
      // variableUpdates: [{ variableId: 'gamma', state: { value: 'Final' } }],
    });
  };

  useEffect(() => {
    if (shouldEvaluateWhenContextChange) {
      setSnippets(getUpdatedVariables());
    }
    // eslint-disable-next-line
  }, [context, shouldEvaluateWhenContextChange]);

  const getDisplayedText = (): string =>
    text.replace(/\{{([^{}]*)}}/g, (match, key) => {
      const snippet = _.findWhere(snippets, { id: key });
      return snippet?.state?.value || snippet?.fallbackValue?.text || 'XXX';
    });

  const displayedText = getDisplayedText();

  const addSnippet = () => {
    const newSnippet = {
      id: `v-${getRandomString(6)}`,
      type: 'path',
      path: ['firstname'],
    } as SweetEvaluatorTypes.Variable;
    setSnippets((prev) => [...prev, newSnippet]);
  };

  const updateSnippet = (snippetToUpdate: MergeTagsVariable) => {
    setSnippets((prev) =>
      _.map(prev, (snippet) =>
        snippet.id === snippetToUpdate.id ? snippetToUpdate : snippet,
      ),
    );
  };

  const evaluateSnippets = () => {
    setSnippets(getUpdatedVariables());
  };

  return (
    <MergeTagsPlaygroundContext.Provider
      value={{
        context,
        setContext,
        snippets,
        addSnippet,
        updateSnippet,
        text,
        setText,
        displayedText,
        shouldEvaluateWhenContextChange,
        setShouldEvaluateWhenContextChange,
        evaluateSnippets,
      }}
    >
      {children}
    </MergeTagsPlaygroundContext.Provider>
  );
};

function useMergeTagsPlayground() {
  const context = React.useContext(MergeTagsPlaygroundContext);
  if (!context) {
    throw new Error('Merge tags context must be used inside MergeTagsProvider');
  }
  return context;
}

export { MergeTagsPlaygroundProvider, useMergeTagsPlayground };
