import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import ClientDefaultCrmWorkflows from '@/graphql/fragments/ClientDefaultCrmWorkflows';

const DELETE_CLIENT_DEFAULT_WORKFLOW = gql`
  mutation DeleteClientDefaultWorkflow(
    $input: DeleteClientDefaultWorkflowInput
  ) {
    deleteClientDefaultWorkflow(input: $input) {
      id
      defaultCrmWorkflows {
        ...ClientDefaultCrmWorkflows
      }
    }
  }
  ${ClientDefaultCrmWorkflows}
`;

const useDeleteClientDefaultWorkflow = () => {
  return useMutation(DELETE_CLIENT_DEFAULT_WORKFLOW);
};

export default useDeleteClientDefaultWorkflow;
