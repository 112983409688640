import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';

const createSnippetInputFromMessage = ({
  snippets,
  message,
}: {
  snippets: SweetEvaluatorTypes.Variable[];
  message: { subject: string; body: string };
}) => {
  const subject = message.subject || '';
  const body = message.body || '';
  return MergeTagsService.createSnippetsInput({ snippets, subject, body });
};

export default createSnippetInputFromMessage;
