import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import { SearchResultItem } from '@/routes/RevealView/SearchView/types';
import { SearchCriteria } from '@/types/search';
import { GET_REVEAL_SEARCH_POOL_RESULTS } from '@/hocs/searchPool/withRevealSearchPoolResults';

type SearchResults = {
  categories: {
    categoryId: string;
    count: number;
    results: SearchResultItem[];
  }[];
};

type Data = {
  searchPool: {
    id: string;
    searchResults: SearchResults;
  };
};

type Variables = {
  searchPoolId: string;
  searchesCriteria?: SearchCriteria[];
  searchText?: string;
  categories?: {
    categoryId?: string;
    withCount?: boolean;
    withResults?: boolean;
  }[];
  sortBy?: string;
  excludeHidden?: boolean;
  excludeProfilesInJob?: boolean;
  maxNbResults?: number;
  missionId?: string;
  includeSkipped?: boolean;
};

type Input = Omit<Variables, 'searchPoolId'> & {
  queryOptions?: QueryHookOptions<Data, Variables>;
};

type Result = QueryResult<Data, Variables> & { searchResults: SearchResults };

function useRevealSearchPoolResults({
  queryOptions = {},
  ...variables
}: Input = {}): Result {
  const query = useQuery<Data, Variables>(GET_REVEAL_SEARCH_POOL_RESULTS, {
    ...queryOptions,
    variables: {
      ...variables,
      searchPoolId: 'reveal',
    },
  });

  const searchResults = query.data?.searchPool.searchResults || {
    categories: [],
  };

  return { ...query, searchResults };
}

export default useRevealSearchPoolResults;
