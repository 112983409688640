import React from 'react';
import _, { compose } from 'underscore';
import { withRouter } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import withClientAnalyticsOffers from '../../../../../hocs/clients/withClientAnalyticsOffers';
import PipelineDataContainer from './PipelineDataContainer';

import './Pipeline.css';
import { PipelineTableStub } from './PipelineTable';
import { PipeLineGraphStub } from './PipelineGraph';

class Pipeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offerGroup: props.offerId || 'all',
      dateRange: '180days',
    };
  }

  onChangeOfferGroup = (e, { value }) => {
    const { location, history } = this.props;
    this.setState({ offerGroup: value });
    history.replace({ ...location, search: '' }); // reset querystring
  };

  onChangeDateRange = (e, { value }) => {
    this.setState({ dateRange: value });
  };

  formatOfferGroups = () => {
    const { offers, t } = this.props;
    const filteredOffers = _.where(offers, { isUnclassified: false });
    const options = _.map(filteredOffers, (offer) => {
      return {
        key: offer.id,
        value: offer.id,
        text: offer.title,
      };
    });
    const all = {
      key: 'all',
      value: 'all',
      text: t('analytics.offerGroups.all'),
    };
    return [all].concat(options);
  };

  offerGroupIds = () => {
    const { offerGroup } = this.state;
    if (offerGroup === 'all') {
      // a query with empty offerIds is equivalent to requesting all offerIds
      return [];
    }
    return [offerGroup];
  };

  dateRangeOptions = () => {
    const { t } = this.props;
    const values = [
      '7days',
      '30days',
      '60days',
      '180days',
      '365days',
      'week',
      'month',
      'quarter',
      'year',
      'allTime',
    ];
    const formatValue = (value) => ({
      key: value,
      value,
      text: t(`analytics.dateRanges.${value}`),
    });
    return _.map(values, formatValue);
  };

  render() {
    const { clientId, t, fullStatisticsLoading } = this.props;
    const { offerGroup, dateRange } = this.state;
    return (
      <div className='section analytics-pipeline'>
        <div className='header'>
          <h3>{t('analytics.headers.pipeline')}</h3>
        </div>
        <br />
        <div className='pipeline-body-container'>
          <div className='twin-selector-container'>
            <div className='twin-selector-left'>
              <div className='twin-selector-label'>
                {t('analytics.dropdownLabels.offerGroup')}
              </div>
              <div className='twin-selector-dropdown'>
                <Dropdown
                  fluid
                  selection
                  onChange={this.onChangeOfferGroup}
                  value={offerGroup}
                  options={this.formatOfferGroups()}
                />
              </div>
            </div>
            <div className='twin-selector-right'>
              <div className='twin-selector-label'>
                {t('analytics.dropdownLabels.dateRange')}
              </div>
              <div className='twin-selector-dropdown'>
                <Dropdown
                  fluid
                  selection
                  onChange={this.onChangeDateRange}
                  value={dateRange}
                  options={this.dateRangeOptions()}
                />
              </div>
            </div>
          </div>
          <div className='analytics-pipeline-container'>
            {fullStatisticsLoading ? (
              <>
                <PipeLineGraphStub />
                <PipelineTableStub />
              </>
            ) : (
              <PipelineDataContainer
                clientId={clientId}
                offerIds={this.offerGroupIds()}
                dateRange={dateRange}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withTranslation('translations'),
  withClientAnalyticsOffers,
)(Pipeline);
