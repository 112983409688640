import { parsePhoneNumberFromString } from 'libphonenumber-js';
import _ from 'underscore';

// Taken from https://github.com/manishsaraan/email-validator
const EMAIL_REGEX =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

export const invalidEmailFormat = ({ email }) => {
  if (!email) {
    return true;
  }
  if (email.length > 256) {
    return true;
  }
  if (!EMAIL_REGEX.test(email)) {
    return true;
  }
  // Further checking of some things regex can't handle
  const [account, address] = email.split('@');
  if (account.length > 64) {
    return true;
  }
  const domainParts = address.split('.');

  if (domainParts.some((part) => part.length > 63)) {
    return true;
  }
  return false;
};

export const REGEXP_LINKEDIN = /^https?:\/\/(www.)?linkedin.com\/in\/[^/]*\/?/;

export const REGEXP_COMPANY_LINKEDIN =
  /^https?:\/\/www.linkedin.com\/company\/[^/]*\/?/;

export const isValidLinkedinProfileUrl = ({ linkedin }) => {
  if (!_.isString(linkedin)) {
    return false;
  }
  return !!REGEXP_LINKEDIN.test(linkedin);
};

export const REGEXP_WEBSITE = /^https?:\/\/[^/]*\/?/;

/**
 * LinkedIn Profile URLs on different apps
 */

export const isLinkedinProfileUrl = ({ url }) => {
  if (!url) {
    return false;
  }
  let finalUrl = url;
  if (!finalUrl.endsWith('/') && !url.includes('?')) {
    finalUrl += '/';
  }
  // matches https://www.linkedin.com/in/profile-name/
  // or https://www.linkedin.com/in/profile-name/?originalSubdomain=fr
  // eslint-disable-next-line no-useless-escape
  const regex =
    /^http[s]*:\/\/[www.]*linkedin\.com\/in\/[^/?]*((\/\w+)+|\/?)(?:\?[^/]*)?$/;

  return !!finalUrl.match(regex);
};

export const REGEXP_GITHUB = /^https?:\/\/github.com\/[^/]*\/?/;
export const isValidGithubProfileUrl = ({ github }) => {
  if (!_.isString(github)) {
    return false;
  }
  return !!REGEXP_GITHUB.test(github);
};

export const isValidBehanceProfileUrl = ({ behance }) => {
  if (!_.isString(behance)) {
    return false;
  }
  const regex = /^https?:\/\/behance.net\/[^/]*\/?$/;
  return !!regex.test(behance);
};

export const isValidTwitterProfileUrl = ({ twitter }) => {
  if (!_.isString(twitter)) {
    return false;
  }
  const regex = /^https?:\/\/twitter.com\/[^/]*\/?$/;
  return !!regex.test(twitter);
};

export const isValidDribbbleProfileUrl = ({ dribbble }) => {
  if (!_.isString(dribbble)) {
    return false;
  }
  const regex = /^https?:\/\/dribbble.com\/[^/]*\/?$/;
  return !!regex.test(dribbble);
};

export const REGEXP_STACKOVERFLOW =
  /^https?:\/\/stackoverflow.com\/users\/[^/]*\/?$/;
export const isValidStackoverflowProfileUrl = ({ stackoverflow }) => {
  if (!_.isString(stackoverflow)) {
    return false;
  }
  return !!REGEXP_STACKOVERFLOW.test(stackoverflow);
};

export const isValidPhoneNumber = ({ phoneNumber }) => {
  if (!phoneNumber || phoneNumber.trim() === '') {
    return true;
  }
  const phoneObject = parsePhoneNumberFromString(phoneNumber);
  if (phoneObject && phoneObject.isValid()) {
    return true;
  }
  if (isValidFrenchPhoneNumberString({ phoneNumber })) {
    return true;
  }
  return false;
};

export const isValidFrenchPhoneNumberString = ({ phoneNumber }) => {
  return (phoneNumber || '').match(
    /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
  );
};
