import React, { useState, useRef, useEffect } from 'react';
import {
  Input,
  Modal,
  ModalActions as Actions,
  ModalContent as Content,
  ModalHeader as Header,
  Form,
  Select,
  Loader,
} from 'semantic-ui-react';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import classNames from 'classnames';
import {
  CREATE_CONNECT_PAGE,
  UPDATE_CONNECT_PAGE,
  GET_CONNECT_PAGES,
  PAGE_URL_EXISTS,
} from '@/graphql/connectPages';
import { ConnectPage } from '@/types/connectPage';
import { GET_CONNECT_FORMS } from '@/graphql/connectForms';
import { ConnectForm } from '@/types/connectForm';
import GenericButton from '@/components/Common/GenericButton';
import { CheckMarkIcon } from '@/assets/icons';
import RichEditor from '@/components/RichEditor';
import { getTranslatedText } from '@/common';
import useClientCrmDescriptor from '@/graphql/hooks/clients/useClientCrmDescriptor';
import useCopyToClipboard from '@/hooks/common/useCopyToClipboard';
import ExternalLink from '@/components/Reveal/Icons/ExternalLink';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import ProjectDropdown from '@/components/Common/ProjectDropdown/ProjectDropdown';
import GenericTooltip from '@/components/Common/GenericTooltip';
import GenericToggle from '@/components/Common/GenericToggle';
import { sanitizeTypename } from '@/common/utils/apollo';
import useDebounceValue from '../../../../../hooks/common/useDebounceValue';
import ConnectPagePreview from '../ConnectPagePreview';
import styles from './ConnectPageEditorModal.module.less';

interface ConnectPageEditorModalProps {
  open: boolean;
  clientId: string;
  connectPage: ConnectPage | null;
  closeModal: () => void;
}

const ConnectPageEditorModal: React.FC<ConnectPageEditorModalProps> = ({
  closeModal,
  open,
  connectPage,
  clientId,
}) => {
  const { t } = useTranslation();
  const [urlCheckLoadingState, setUrlCheckLoadingState] = useState(false);
  const { data: connectFormsData } = useQuery(GET_CONNECT_FORMS, {
    variables: {
      clientId,
    },
  });

  const { crmDescriptor } = useClientCrmDescriptor(clientId);

  const PageDescription = getTranslatedText(
    crmDescriptor?.description?.sections?.[0]?.content || {
      default: '',
    },
  );

  const connectForms: ConnectForm[] = connectFormsData?.client?.connectForms;

  const connectFormsOption = _.map(connectForms, (connectForm) => ({
    key: connectForm?.id,
    value: connectForm?.id,
    text: connectForm?.title.default,
  }));

  const baseUrl = `https://join.hiresweet.com/${clientId}/`;
  const [formState, setFormState] = useState({
    ...(connectPage || {
      internalTitle: '',
      targetMissionId: '',
      title: {
        default: t(
          'settings.connectSettings.connectPages.editionModal.defaultTitle',
        ),
      },
      connectForm: {
        id: connectFormsOption?.[0]?.key,
      },
      published: false,
      url: baseUrl,
    }),
    description: getTranslatedText(
      connectPage?.description?.sections?.[0]?.content || {
        default: PageDescription,
      },
    ),
  });

  const urlExtension = formState?.url?.slice(
    formState?.url?.indexOf(baseUrl) + baseUrl.length,
  );

  const debouncedURL = useDebounceValue(formState?.url, 250);

  const { data: pageURLCheckData, loading: urlCheckLoading } = useQuery(
    PAGE_URL_EXISTS,
    {
      variables: {
        clientId,
        url: debouncedURL,
        pageId: connectPage?.id || '',
      },
      fetchPolicy: 'network-only',
    },
  );

  const isURLTaken = pageURLCheckData?.client?.isPageUrlAlreadyExisting;

  const { jobOptions: projects } = useSearchPoolJobOptions('reveal');

  useEffect(() => {
    if (!urlCheckLoading) {
      setUrlCheckLoadingState(false);
    }
  }, [urlCheckLoading]);

  const [createConnectPage] = useMutation(CREATE_CONNECT_PAGE, {
    refetchQueries: [
      {
        query: GET_CONNECT_PAGES,
        variables: {
          clientId,
        },
      },
    ],
  });

  const [updateConnectPage] = useMutation(UPDATE_CONNECT_PAGE, {
    refetchQueries: [
      {
        query: GET_CONNECT_PAGES,
        variables: {
          clientId,
        },
      },
    ],
  });

  const submitConnectPage = async () => {
    const {
      internalTitle,
      title,
      connectForm,
      published,
      url,
      description,
      targetMissionId,
    } = formState;
    if (connectPage?.id) {
      const form = sanitizeTypename(formState);
      await updateConnectPage({
        variables: {
          input: {
            id: connectPage.id,
            title: form.title,
            internalTitle,
            targetMissionId,
            connectForm: {
              connectFormId: form.connectForm.id,
            },
            published,
            url,
            description,
          },
        },
      });
    } else {
      await createConnectPage({
        variables: {
          input: {
            internalTitle,
            title,
            targetMissionId,
            connectForm: {
              connectFormId: connectForm.id,
            },
            published,
            url,
            description,
          },
        },
      });
    }
    closeModal();
  };

  const isSubmitDisabled =
    _.isEmpty(formState.internalTitle) ||
    _.isEmpty(formState.title.default) ||
    _.isEmpty(formState.connectForm?.id) ||
    _.isEmpty(urlExtension) ||
    isURLTaken;

  const urlInputRef = useRef<Input>(null);

  const { copied, handleCopy } = useCopyToClipboard();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleCopy(`${baseUrl}${urlExtension}`);
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      className='new-sender-modal'
      closeIcon
      closeOnDimmerClick
      size='large'
    >
      <Header>
        {connectPage?.id
          ? t('settings.connectSettings.connectPages.editionModal.updateTitle')
          : t('settings.connectSettings.connectPages.editionModal.createTitle')}
      </Header>
      <Content className={styles.connectPageWithPreview}>
        <Form className={styles.fieldsContainer}>
          <Form.Field>
            <div className={styles.fieldLabel}>
              {t(
                'settings.connectSettings.connectPages.editionModal.internalTitle',
              )}
            </div>
            <Input
              value={formState?.internalTitle || ''}
              placeholder={t(
                'settings.connectSettings.connectPages.editionModal.internalTitlePlaceholder',
              )}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  internalTitle: e.target.value,
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <div className={styles.fieldLabel}>
              {t('settings.connectSettings.connectPages.editionModal.title')}
            </div>
            <Input
              value={formState?.title?.default || ''}
              placeholder={t(
                'settings.connectSettings.connectPages.editionModal.titlePlaceholder',
              )}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  title: { default: e.target.value },
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <div className={styles.fieldLabel}>
              {t(
                'settings.connectSettings.connectPages.editionModal.description',
              )}
            </div>
            <RichEditor
              initialHtml={formState.description || ''}
              onChangeAsHtml={(html: string) =>
                setFormState({
                  ...formState,
                  description: html,
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <div className={styles.fieldLabel}>
              {t(
                'settings.connectSettings.connectPages.editionModal.attachForm',
              )}
            </div>
            <Select
              floating
              options={connectFormsOption}
              value={formState.connectForm?.id || connectPage?.connectForm?.id}
              placeholder={t(
                'settings.connectSettings.connectPages.editionModal.attachFormPlaceholder',
              )}
              onChange={(_e, data) => {
                setFormState({
                  ...formState,
                  connectForm: {
                    id: data.value as string,
                  },
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <div className={styles.fieldLabel}>
              {t(
                'settings.connectSettings.connectPages.editionModal.attachJob',
              )}
              <GenericTooltip
                basic
                inverted
                content={t(
                  'settings.connectSettings.connectPages.editionModal.jobTooltip',
                )}
                trigger={<i className='ri-question-line' />}
              />
            </div>
            <ProjectDropdown
              projects={projects}
              currentMission={_.findWhere(projects, {
                id: formState.targetMissionId,
              })}
              onProjectSelected={({ id }) => {
                setFormState({
                  ...formState,
                  targetMissionId: id,
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <div className={styles.fieldLabel}>
              {t('settings.connectSettings.connectPages.editionModal.url')}
            </div>
            <div
              className={styles.customInputContainer}
              onClick={() => urlInputRef?.current?.focus()}
              onKeyPress={() => urlInputRef?.current?.focus()}
              role='button'
            >
              <span className={styles.urlPrefix}>{baseUrl}</span>
              <Input
                ref={urlInputRef}
                className={styles.customInput}
                value={urlExtension || ''}
                placeholder={t(
                  'settings.connectSettings.connectPages.editionModal.urlPlaceholder',
                )}
                onChange={(e) => {
                  setUrlCheckLoadingState(true);
                  setFormState({
                    ...formState,
                    url: `${baseUrl}${e.target.value}`,
                  });
                }}
              />
              {!_.isEmpty(urlExtension) && (
                <>
                  {urlCheckLoadingState && (
                    <Loader inline active className={styles.urlCheckLoader} />
                  )}
                  {!urlCheckLoadingState && isURLTaken && (
                    <span
                      className={classNames(
                        styles.pillMessage,
                        'pill-message mini red',
                      )}
                    >
                      This URL is not available
                    </span>
                  )}
                  {!urlCheckLoadingState && !isURLTaken && (
                    <>
                      <span
                        className={classNames(
                          styles.pillMessage,
                          'pill-message mini green',
                        )}
                      >
                        <CheckMarkIcon className='' />
                      </span>
                      <span onClick={handleClick} className={styles.copyIcon}>
                        <i
                          className={`ri-${
                            !copied ? 'file-copy-fill' : 'check-line'
                          }`}
                        />
                      </span>
                      <span className={styles.externalLink}>
                        <a
                          href={`${baseUrl}${urlExtension}`}
                          className={styles.externalLink}
                          target='_blank'
                          rel='noreferrer noopener'
                        >
                          <ExternalLink />
                        </a>
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          </Form.Field>
          <Form.Field>
            <GenericToggle
              isChecked={formState.published}
              label={t(
                'settings.connectSettings.connectPages.editionModal.publish',
              )}
              name='published'
              onChange={({ value }: { value: boolean }) => {
                setFormState({
                  ...formState,
                  published: value,
                });
              }}
            />
          </Form.Field>
        </Form>
        <div className={styles.connectPagePreviewContainer}>
          <ConnectPagePreview connectPage={formState} clientId={clientId} />
        </div>
      </Content>
      <Actions className={styles.modalActions}>
        <div className='align-left'>
          <GenericButton primacy='secondary' onClick={closeModal}>
            {t('common.cancel')}
          </GenericButton>
        </div>
        <GenericButton
          disabled={isSubmitDisabled}
          primacy='primary'
          onClick={submitConnectPage}
        >
          {connectPage?.id
            ? t('settings.connectSettings.connectPages.editionModal.update')
            : t('settings.connectSettings.connectPages.editionModal.create')}
        </GenericButton>
      </Actions>
    </Modal>
  );
};

export default ConnectPageEditorModal;
