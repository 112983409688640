import React, { FC, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';

import useClientId from '@/hooks/router/useClientId';
import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import GenericButton from '@/components/Common/GenericButton';
import logAction from '@/common/logAction';
import useClient from '@/graphql/hooks/clients/useClient';
import { differenceInDays } from '@/common/utils/time';

import PaymentLink from '../PaymentLink';
import AskDemoModal from '../AskDemoModal';

import styles from './FreeTrialBanner.module.less';

const FreeTrialBanner: FC = () => {
  const { t } = useTranslation();
  const { applicationMode } = useNavApplicationMode();
  const clientId = useClientId();
  const [demoModalOpen, setDemoModalOpen] = useState(false);
  const { data } = useClient(clientId);

  if (applicationMode !== 'reveal') {
    return null;
  }

  const freeTrialUpgraded = data?.client.freeTrialConfiguration?.upgraded;

  if (freeTrialUpgraded) {
    return null;
  }

  const freeTrialEndDate =
    data?.client.freeTrialConfiguration?.endDate &&
    new Date(data.client.freeTrialConfiguration.endDate);

  if (!freeTrialEndDate) {
    return null;
  }

  const remainingDays = differenceInDays(freeTrialEndDate, new Date());

  const description =
    remainingDays < 0
      ? t('reveal.freeTrial.banner.expired')
      : t('reveal.freeTrial.banner.remainingDays', { count: remainingDays });

  return (
    <>
      <div className={styles.banner}>
        <div className={styles.remaining}>{description}</div>
        <div className={styles.ctas}>
          <PaymentLink />
          <GenericButton
            primacy='secondary'
            onClick={() => {
              logAction({ type: 'reveal-open-ask-demo-modal' });
              setDemoModalOpen(true);
            }}
          >
            {t('reveal.freeTrial.banner.askDemo')}
          </GenericButton>
        </div>
      </div>
      <AskDemoModal open={demoModalOpen} setOpen={setDemoModalOpen} />
    </>
  );
};

export default memo(FreeTrialBanner);
