import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useClientId from '@/hooks/router/useClientId';
import ButtonLink from '@/components/Common/GenericButton/ButtonLink';
import CardEmptyState from '../CardEmptyState/CardEmptyState';

interface AllCandidatesEmptyStateProps {
  count: number;
  newCount: number;
  isPrimary: boolean;
  offerId?: string;
}

const AllCandidatesEmptyState: FC<AllCandidatesEmptyStateProps> = ({
  count,
  isPrimary,
  newCount,
  offerId,
}) => {
  const clientId = useClientId();
  const { t } = useTranslation();

  return (
    <CardEmptyState
      illustrationPath='/images/placeholders/allCandidatesIllustration.svg'
      title={t('offers.profileEmptyStates.allCandidates.title')}
      cta={
        <ButtonLink
          to={
            offerId
              ? `/client/${clientId}/jobs/${offerId}/explore`
              : `/client/${clientId}/discover/candidates`
          }
          primacy={isPrimary ? 'primary' : 'secondary'}
        >
          {t('offers.profileEmptyStates.allCandidates.cta')}
        </ButtonLink>
      }
    >
      {t('offers.profileEmptyStates.allCandidates.innerContent', {
        count,
        newCount,
      })}
    </CardEmptyState>
  );
};

export default AllCandidatesEmptyState;
