import gql from 'graphql-tag';

export default gql`
  fragment CustomActionExecutionFragment on CustomActionExecution {
    type
    url
    phantomServiceId
    phantomAgentId
    phantomAgentType
    phantomAgentTitle
  }
`;
