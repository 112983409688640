import _ from 'underscore';
import React, { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Briefcase } from '@/assets/icons';

import { ATStype } from '@/common/reveal';
import { Contract } from '@/common/reveal/SourceData.type';

import styles from './ContractHistory.module.less';
import { getContractsForAts } from './helpers';

const NB_MS_IN_A_DAY = 24*3600*1000;

function LineIcon({ icon = <Briefcase /> }: { icon?: JSX.Element }) {
  return <div className={styles.lineIcon}>{icon}</div>;
}

function ContractDates({
  startDate,
  endDate,
}: {
  startDate?: string | null;
  endDate?: string | null;
}) {
  const { i18n, t } = useTranslation();
  const formattedDates = useMemo(() => {
    if (!startDate) {
      return null;
    }

    const formatter = new Intl.DateTimeFormat(i18n.resolvedLanguage, {
      dateStyle: 'short',
    });

    const formattedStartDate = formatter.format(new Date(startDate));
    const formattedEndDate = endDate
      ? formatter.format(new Date(endDate))
      : t('profile.resume.ATSData.inProgress');

    // const date1 = Date.parse(new Date(startDate).toDateString());
    // const date2 = Date.parse(new Date(endDate || '').toDateString());
    // const diffTime = Math.abs(date2 - date1);
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // const diffDaysCeil = diffDays || 1;

    return `${formattedStartDate} - ${formattedEndDate}`;
  }, [t, i18n, startDate, endDate]);

  return (formattedDates as unknown) as JSX.Element;
}

function ContractName({ contract }: { contract: Contract }) {
  const contractorName = contract.externalContractor?.name;
  const contractorNumber = contract.externalContractor?.sourceLegalId;

  const qualification = _.findWhere(contract.metadata, { key: 'qualification' })
    ?.value;

  return (
    <>
      {qualification && <span>{qualification} • </span>}
      &nbsp;
      {contractorName}
      &nbsp;
      {contractorNumber && (
        <span className={styles.contractNameLegalId}>{contractorNumber}</span>
      )}
    </>
  );
}

function ContractLine({
  contract,
}: {
  contract: Contract & { 
    addendumActualEndDate: string | null,
    smartEndDate: string | null,
  };
}) {

  return (
    <div className={styles.contractLine}>
      <LineIcon />
      <div className={styles.contractLineInfo}>
        <span>
          <ContractName contract={contract} />
        </span>
        {contract.sourceContractNumber && (
          <span>
            Nº {(contract.sourceContractNumber || '').replaceAll(' ', '')}
          </span>
        )}

        <span>
          <ContractDates
            startDate={contract.actualStartDate}
            endDate={
              contract.smartEndDate ||
              contract.actualEndDate ||
              contract.theoreticalEndDate ||
              contract.addendumActualEndDate ||
              contract.endDate
            }
          />
        </span>
      </div>
    </div>
  );
}

function SerieLine({
  title,
  contractorName,
  siret,
  contractNumber,
  startDate,
  endDate,
}: {
  title: string;
  contractorName: string;
  siret: string;
  contractNumber: string;
  startDate: string;
  endDate: string;
}) {

  return (
    <div className={styles.contractLine}>
      <LineIcon />
      <div className={styles.contractLineInfo}>
        <span>
          {title && <span>{title} • </span>}
            &nbsp;
            {contractorName}
            &nbsp;
            {siret && (
              <span className={styles.contractNameLegalId}>{siret}</span>
            )}
        </span>
        {contractNumber && (
          <span>
            Nº {(contractNumber || '').replaceAll(' ', '')}
          </span>
        )}
        <span>
          <ContractDates
            startDate={startDate}
            endDate={endDate}
          />
        </span>
      </div>
    </div>
  );
}


function ContractHistoryEmptyState() {
  const { t } = useTranslation();
  return (
    <div className={styles.emptyState}>
      {t('profile.resume.ATSData.noContracts')}
    </div>
  );
}

const getContractSmartEndDate = ({ contract } : any) : string | null => {

  if (!contract) {
    throw Error('no contract in getContractSmartEndDate');
  }

  if (contract.actualEndDate) {
    return contract.actualEndDate;
  }

  if (contract.theoreticalEndDate) {
    return contract.theoreticalEndDate;
  }

  let { endDate } = contract;
  // eslint-disable-next-line no-restricted-syntax
  for (const addendum of contract.addendums || []) {
    if (addendum.actualEndDate >= endDate) {
      endDate = addendum.actualEndDate; 
    }
  }

  return endDate;
}

export type AgencyType = 'city-agency' | 'is-agency';

export type Serie = {
  siret: string;
  startDate: string;
  endDate: string;
  contracts: Contract[];
}

type DayOffset = {
  type: 'calendar-days' | 'business-days',
  shift: number,
}


const BRIDGE_MAX_OFFET_BY_AGENCY_TYPE : Record<AgencyType, DayOffset> = {
  'city-agency': { type: 'business-days', shift: -4 },
  'is-agency': { type: 'calendar-days', shift: -30 },
}

export function getSeriesFromContracts({ agencyType, contracts } : { agencyType: AgencyType; contracts: Contract[] }) : Serie[] {

  const contractsBySIRET = _.groupBy(contracts, (contract) => (
    contract.externalContractor?.sourceId ?? contract.id
  ));

  const series = _.flatten(
    _.map(contractsBySIRET, contractsOfSameSiret => (
      getSeriesFromContractsOfSameSIRET({ agencyType, contracts: contractsOfSameSiret })
    ))
  );

  return _.sortBy(_.sortBy(series, 'startDate'), 'endDate').reverse();
}


const getDayAfterOffset = (date : string, nbDays: number) => {
  const d = new Date(new Date(date).toISOString().slice(0,10));
  return new Date(d.getTime() + nbDays*NB_MS_IN_A_DAY).toISOString().slice(0,10);
}

const getDayAfterBusinessOffset = (date: string, nbWorkingDays: number) => {
  if (nbWorkingDays === 0) {
    return new Date(date).toISOString().slice(0,10);
  }
  const unitShift = nbWorkingDays > 0 ? 1 : -1;
  let nbWorkingDaysToSee = nbWorkingDays > 0 ? nbWorkingDays : -nbWorkingDays; 
  let d = new Date(date);
  while (nbWorkingDaysToSee > 0) {
    d = new Date(d.getTime() + unitShift*NB_MS_IN_A_DAY);
    if (d.getDay() !== 6 && d.getDay() !== 0) {
      nbWorkingDaysToSee--;
    }
  }
  return d.toISOString().slice(0,10);
}

const wrapContractDate = (date: string) => new Date(
  new Date(date ?? '').getTime() + 3*3600*1000 
).toISOString().slice(0,10);


export const getSeriesFromContractsOfSameSIRET = ({ 
  agencyType, 
  contracts 
} : {
  agencyType: AgencyType;
  contracts: Contract[];
}) : Serie[] => {
  if (_.isEmpty(contracts)) {
    return []
  }

  const bridgeOffset = BRIDGE_MAX_OFFET_BY_AGENCY_TYPE[agencyType];

  type ContractInterval = {
    contract: Contract;
    fakeStartDate: string;
    startDate: string;
    endDate: string;
  }

  // fot type safety
  const fallbackDate = new Date().toISOString().slice(0,10);

  const contractIntervals : ContractInterval[] = _.sortBy(
    _.map(contracts, contract => ({
      contract,
      fakeStartDate: getDayAfterOffset(
        bridgeOffset.type === 'calendar-days' ? (
          getDayAfterOffset(wrapContractDate(contract.actualStartDate ?? fallbackDate), bridgeOffset.shift)
        ) : getDayAfterBusinessOffset(wrapContractDate(contract.actualStartDate ?? fallbackDate), bridgeOffset.shift), 
      -1),
      startDate: wrapContractDate(contract.actualStartDate ?? fallbackDate),
      endDate: wrapContractDate(getContractSmartEndDate({ contract }) ?? fallbackDate),
    })),
    'fakeStartDate'
  );

  if (!contractIntervals[0]) {
    return [];
  }
  const siret = contractIntervals[0].contract.externalContractor?.sourceId ?? '';

  // included end
  let currentSerie : Serie = {
    siret,
    startDate: contractIntervals[0].startDate,
    endDate: contractIntervals[0].endDate ?? fallbackDate, 
    contracts: [contractIntervals[0].contract],
  };
  const series : Serie[] = [currentSerie];

  // eslint-disable-next-line no-restricted-syntax
  for (const contractInterval of contractIntervals.slice(1)) {
    if (contractInterval.fakeStartDate <= currentSerie.endDate) {
      // merge in current serie
      currentSerie.contracts.push(contractInterval.contract);
      if (contractInterval.endDate > currentSerie.endDate) {
        currentSerie.endDate = contractInterval.endDate;
      }
    } else {
      // open new serie
      currentSerie = {
        siret,
        startDate: contractInterval.startDate,
        endDate: contractInterval.endDate, 
        contracts: [contractInterval.contract],
      };
      series.push(currentSerie)
    }
  }

  const getSortedContracts = (contractsToSort : Contract[]) => _.sortBy(
    _.sortBy(contractsToSort, 'actualStartDate'), 
    contract => getContractSmartEndDate({ contract })
  ).reverse();

  return _.map(series, (serie) => ({
    ...serie,
    contracts: getSortedContracts(serie.contracts)
  }));
}



export function ContractHistory({
  contracts,
  atsId,
}: PropsWithChildren<{
  contracts?: Contract[];
  atsId: ATStype;
}>) {
  const filteredContracts = useMemo(() => {
    return getContractsForAts(contracts || [], atsId);
  }, [atsId, contracts]);

  if (!filteredContracts?.length) {
    return <ContractHistoryEmptyState />;
  }

  return (
    <div>
      {_.map(filteredContracts, (contract) => {
        return (
          <ContractLine
            key={contract.id}
            contract={{
              ...contract.flattenedContract,
              // Adequat have a very specific handling of dates
              // and the addendum dates do not change the actual start date
              smartEndDate: getContractSmartEndDate({ contract }),
              endDate: contract.endDate,
              actualStartDate: contract.actualStartDate,
              theoreticalEndDate: contract.theoreticalEndDate,
              actualEndDate: contract.actualEndDate,
              addendumActualEndDate:
                contract.flattenedContract.actualEndDate ?? null,
            }}
          />
        );
      })}
    </div>
  );
}

export function AdventureContractsSeriesHistory({
  agencyType,
  contracts,
}: PropsWithChildren<{
  agencyType: AgencyType;
  contracts?: Contract[];
}>) {
  const series = useMemo(() => {
    return getSeriesFromContracts({ agencyType, contracts: contracts || [] });
  }, [agencyType, contracts]);

  if (!series?.length) {
    return <ContractHistoryEmptyState />;
  }

  return (
    <div>
      {_.map((series ?? []).slice(0,5), (serie, index) => {
        const lastContract = serie.contracts[0];
        const companyName = lastContract?.externalContractor?.name ?? '???';
        const contractNumber = lastContract?.sourceContractNumber ?? '';
        const qualification = _.findWhere(lastContract?.metadata ?? [], { key: 'qualification' })?.value;
    
        return (
          <div style={{ marginBottom : 10 }} key={`${index}_${serie.siret}_${serie.startDate}`}>
            <SerieLine 
              title={qualification ?? ''}
              contractorName={companyName}
              siret={serie.siret}
              contractNumber={contractNumber}
              startDate={serie.startDate}
              endDate={serie.endDate}
            />
          </div>
        );
      })}
    </div>
  );
}
