import _, { compose } from 'underscore';
import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { Form, Checkbox } from 'semantic-ui-react';

import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import contextToProps from '../../../../hocs/contextToProps';
import withCreateTemplate from '../../../../hocs/templates/withCreateTemplate';
import withOffer from '../../../../hocs/offers/withOffer';
import withUserSettings from '../../../../hocs/users/withUserSettings';

import TemplateModal from './TemplateModal.component';

import './Template.css';

const getMessagesFromOffer = (offer) => {
  const suggestions =
    offer && _.isArray(offer.defaultActionSuggestions)
      ? offer.defaultActionSuggestions
      : [];
  const targetSuggestion = _.find(
    suggestions,
    ({ rule }) => rule.id === 'firstContact',
  );
  return targetSuggestion &&
    targetSuggestion.action &&
    _.isArray(targetSuggestion.action.messages)
    ? targetSuggestion.action.messages
    : [];
};

export class NewTemplate_ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultTemplateChecked: true,
    };
  }

  onChangeOfferDefault = (ev, { checked }) => {
    this.setState({ defaultTemplateChecked: checked });
  };

  handleSubmit = async ({ title, subject, body, snippets }) => {
    const {
      t,
      sequenceId,
      messagePosition,
      createTemplate,
      onPostSubmit,
      onShowNotification,
    } = this.props;
    const { defaultTemplateChecked } = this.state;
    const isNewOfferDefault = defaultTemplateChecked;
    const snippetsInput = MergeTagsService.createSnippetsInput({
      subject,
      body,
      snippets,
    });
    const { data } = await createTemplate({
      title: title || t('templates.defaultTitle'),
      subject,
      body,
      isNewOfferDefault,
      messagePosition,
      ...(sequenceId && { sequenceId }),
      ...(snippetsInput && { snippets: snippetsInput }),
    });
    onShowNotification({
      message: t('templates.notifications.createdWithSuccess'),
      level: 'success',
    });
    this.setState({
      defaultTemplateChecked: true,
    });
    const createAndEditTemplateId =
      data && data.createTemplate && data.createTemplate.id
        ? data.createTemplate.id
        : null;
    if (onPostSubmit) {
      onPostSubmit({ templateId: createAndEditTemplateId });
    }
  };

  renderContent() {
    const { t, offer, messagePosition } = this.props;
    if (!offer) {
      return null;
    }
    const messages = getMessagesFromOffer(offer);
    const canSetDefaultIntro = messagePosition === 0;
    const canSetDefaultFollowup = messagePosition === 1 && messages.length >= 1;
    return (
      <div className='modal-options'>
        {canSetDefaultIntro ? (
          <Form.Field>
            <Checkbox
              label={t(
                'templates.createAndEditTemplate.useThisTemplateAsDefaultFirstContact',
              )}
              name='setDefaultTemplate'
              checked={this.state.defaultTemplateChecked}
              onChange={this.onChangeOfferDefault}
            />
          </Form.Field>
        ) : null}
        {canSetDefaultFollowup ? (
          <Form.Field>
            <Checkbox
              label={t(
                'templates.createAndEditTemplate.useThisTemplateAsDefaultFollowup',
              )}
              name='setDefaultTemplate'
              checked={this.state.defaultTemplateChecked}
              onChange={this.onChangeOfferDefault}
            />
          </Form.Field>
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <TemplateModal
        className={this.props.isFromOffer ? 'from-offer' : ''}
        open={this.props.open}
        onClose={this.props.onClose}
        onSubmit={this.handleSubmit}
        clientId={this.props.clientId}
      >
        {this.renderContent()}
      </TemplateModal>
    );
  }
}

export default compose(
  withUserSettings,
  withCreateTemplate,
  withOffer,
  contextToProps,
  withTranslation('translations'),
)(NewTemplate_);
