import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import TimeLineItem from '../../graphql/fragments/TimelineItem';

export const mutation = gql`
  mutation addProfileComment($id: ID!, $comment: String!) {
    addProfileComment(id: $id, comment: $comment) {
      id
      contactData {
        draftComment
        timeline {
          ...TimelineItem
        }
      }
    }
  }
  ${TimeLineItem}
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    addProfileComment: ({ id, comment }) =>
      mutate({
        variables: {
          id,
          comment,
        },
      }),
  }),
});
