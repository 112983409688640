import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Prompt } from 'react-router-dom';

interface LockHistory {
  lockHistory: (_: string) => void;
  unlockHistory: () => void;
}

const LockHistoryContext = createContext<LockHistory>({
  lockHistory: () => {
    /* Do nothing */
  },
  unlockHistory: () => {
    /* Do nothing */
  },
});

export const LockHistoryContextProvider: React.FC = ({ children }) => {
  const [isLocked, setIsLocked] = useState(false);
  const [message, setMessage] = useState('');

  const lockHistory = useCallback((newMessage: string) => {
    setIsLocked(true);
    setMessage(newMessage);
  }, []);

  const unlockHistory = useCallback(() => {
    setIsLocked(false);
    setMessage('');
  }, []);

  return (
    <>
      <LockHistoryContext.Provider value={{ lockHistory, unlockHistory }}>
        {children}
      </LockHistoryContext.Provider>
      <Prompt when={isLocked} message={message} />
    </>
  );
};

export const useLockHistory = (): LockHistory => {
  const context = useContext(LockHistoryContext);
  const { unlockHistory } = context;

  useEffect(() => {
    // Makes sure the prompt doesn't display eternally once the component is unmounted
    return unlockHistory;
  }, [unlockHistory]);

  return context;
};
