import React, { FC, useMemo, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import SettingsLayout from '@/containers/SettingsLayout';
import useClientCustomActivities, {
  ClientCustomActivity,
  GET_CLIENT_CUSTOM_ACTIVITIES,
  useRemoveClientCustomActivity,
  useUpdateClientCustomActivity,
} from '@/graphql/hooks/clients/useClientCustomActivities';
import GenericModal from '@/components/Common/GenericModal';
import { Modal } from 'semantic-ui-react';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';
import { ClientCustomAction } from '@/types/customAction';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { GraphQLError } from 'graphql';
import { subtypes } from '@/routes/RevealView/SearchView/CandidatesListPane/CandidateView/SharedNotes/subtypes';
import styles from '../CustomTasks/CustomTasksSettings.module.less';
import CustomTasksTable from '../CustomTasks/CustomTasksTable';
import CustomTaskForm from '../CustomTasks/CustomTasksForm';

interface CustomActivitiesSettingsProps {
  clientId: string;
}

const CustomActivitiesSettings: FC<CustomActivitiesSettingsProps> = ({
  clientId,
}) => {
  const { t } = useTranslation();
  const [creationModalOpen, setCreationModalOpen] = useState(false);
  const [editionModalOpen, setEditionModalOpen] = useState(false);
  const [deletionModalOpen, setDeletionModalOpen] = useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState('');
  const notifications = useNotificationSystem();
  // const toast = useNotificationSystem();

  const { clientCustomActivities } = useClientCustomActivities({ clientId });
  const [updateClientCustomActivity] = useUpdateClientCustomActivity();
  const [removeClientCustomActivity] = useRemoveClientCustomActivity();

  const handleEditActivity = ({ taskId }: { taskId: string }) => {
    setSelectedActivityId(taskId);
    setEditionModalOpen(true);
  };

  const handleUpdateActivity = ({
    data,
  }: {
    data: ClientCustomAction | ClientCustomActivity;
  }) => {
    updateClientCustomActivity({
      variables: {
        clientId,
        input: {
          ..._.omit(data, ['execution', 'completionButtonDisplay']),
          type: _.includes(subtypes, data.type) ? data.type : 'comment',
        },
      },
      refetchQueries: [GET_CLIENT_CUSTOM_ACTIVITIES],
      onError: (e) => {
        const error: GraphQLError & { data?: any } = e.graphQLErrors?.[0];
        if (error?.data?.details?.indexOf('name-already-exists') >= 0) {
          notifications.error(
            `${t(`reveal.parameters.customActivitySettings.errorPrefix`, {
              operation: creationModalOpen
                ? t(`reveal.parameters.customActivitySettings.creation`)
                : t(`reveal.parameters.customActivitySettings.update`),
            })} ${t(
              `reveal.parameters.customActivitySettings.${error.data.details}`,
              {
                name: data.title.default,
              },
            )}`,
          );
        }
      },
      onCompleted: () => {
        setEditionModalOpen(false);
        setCreationModalOpen(false);
      },
    });
  };

  const handleRemoveActivity = ({ actionId }: { actionId: string }) => {
    removeClientCustomActivity({
      variables: {
        clientId,
        activityId: actionId,
      },
      refetchQueries: [GET_CLIENT_CUSTOM_ACTIVITIES],
    });
    setDeletionModalOpen(false);
  };

  const currentActivity = useMemo(
    () => _.findWhere(clientCustomActivities, { id: selectedActivityId }),
    [selectedActivityId, clientCustomActivities],
  );

  return (
    <SettingsLayout
      currentPage='customActivities'
      clientId={clientId}
      className='settings-container'
    >
      <div className='settings'>
        <div className={styles.header}>
          <h1 className={styles.title}>
            {t(`reveal.parameters.customActivitySettings.title`)}
          </h1>
        </div>
        <h2>{t('settings.configuration')}</h2>
        <div className='section visible'>
          <div className={styles.description}>
            {t(`reveal.parameters.customActivitySettings.description`)}
          </div>
          <CustomTasksTable
            tasks={clientCustomActivities}
            type='activities'
            onEdit={handleEditActivity}
            onDelete={({ taskId }) => {
              setSelectedActivityId(taskId);
              setDeletionModalOpen(true);
            }}
          />
          <div className='input-container'>
            <GenericButton onClick={() => setCreationModalOpen(true)}>
              <Plus />
              {t(`reveal.parameters.customActivitySettings.create`)}
            </GenericButton>
          </div>
        </div>
      </div>
      <GenericModal
        open={editionModalOpen || creationModalOpen}
        className={styles.customActionFormModal}
      >
        <Modal.Header>
          {creationModalOpen
            ? t(`reveal.parameters.customActivitySettings.creationModal.title`)
            : t(`reveal.parameters.customActivitySettings.edit`)}
        </Modal.Header>
        <Modal.Content className={styles.modalContentContainer}>
          <CustomTaskForm
            onSubmit={handleUpdateActivity}
            type='activities'
            currentTask={editionModalOpen ? currentActivity : undefined}
            setOpen={() => {
              setEditionModalOpen(false);
              setCreationModalOpen(false);
            }}
            index={clientCustomActivities.length + 1}
          />
        </Modal.Content>
      </GenericModal>
      <ConfirmationModal
        header={t(`reveal.parameters.customActivitySettings.delete`)}
        submit={t('common.delete')}
        onSubmit={() => handleRemoveActivity({ actionId: selectedActivityId })}
        open={deletionModalOpen}
        onCancel={() => {
          setSelectedActivityId('');
          setDeletionModalOpen(false);
        }}
      />
    </SettingsLayout>
  );
};

export default CustomActivitiesSettings;
