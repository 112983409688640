import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';

import './index.css';

const ErrorModal = ({ open, header, content, onClose }) => {
  return (
    <Modal className='error-modal' open={open} onClose={onClose} size='mini' closeIcon>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <div className='message'>
          <span className='message-content'>{content}</span>
        </div>
      </Modal.Content>
      <Modal.Actions className='align-center'>
        <Button type='button' primary onClick={onClose} size='big'>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default withTranslation('translations')(ErrorModal);
