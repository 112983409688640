import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SearchPoolProfileState from '../../graphql/fragments/SearchPoolProfileState';
import { getOfferProfiles } from '../offers/withOfferProfiles';

export const addMultipleSearchProfilesToJobQuery = gql`
  mutation addMultiplePoolProfilesToOffer($ids: [ID!]!, $jobOfferId: ID!) {
    addMultiplePoolProfilesToOffer(ids: $ids, jobOfferId: $jobOfferId) {
      id
      searchPoolState {
        ...SearchPoolProfileState
      }
    }
  }
  ${SearchPoolProfileState}
`;

export default graphql(addMultipleSearchProfilesToJobQuery, {
  props: ({ mutate }) => {
    return {
      addMultipleSearchProfilesToJob: ({ ids, jobOfferId }) =>
        mutate({
          variables: { ids, jobOfferId },
          refetchQueries: [
            {
              query: getOfferProfiles,
              variables: {
                offerId: jobOfferId,
                step: '',
                search: '',
              },
            },
            {
              query: getOfferProfiles,
              variables: {
                offerId: jobOfferId,
                step: 'pending',
                search: '',
              },
            },
          ],
        }),
    };
  },
});
