import React, { useState, useMemo } from 'react';
import _ from 'underscore';

import { getSecureLink, getRandomDefaultAvatarLink } from '../common';
import { MarketplaceUnbiasedModeField } from './UnbiasedMode/UnbiasedModeField';

import './styles/Thumbnail.css';

const getInitial = (str?: string | null): string => {
  if (!_.isString(str)) {
    return '';
  }
  return str.charAt(0).toUpperCase();
};

const getCollaboratorInitials = (
  firstname?: string | null,
  lastname?: string | null,
): string => {
  return getInitial(firstname) + getInitial(lastname);
};

interface ThumbnailProps {
  link?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  unbiasedModeEnabled?: boolean;
}

const Thumbnail: React.FC<ThumbnailProps> = ({
  link,
  firstname,
  lastname,
  email,
  unbiasedModeEnabled = false,
}) => {
  const defaultLink = useMemo(
    () => getRandomDefaultAvatarLink(`${firstname}${lastname}`),
    [firstname, lastname],
  );
  const [src, setSrc] = useState(link ? getSecureLink(link) : defaultLink);
  return (
    <div className='user-thumbnail'>
      <div className='image initials'>
        <div className='initials-inner-text'>
          <MarketplaceUnbiasedModeField
            enabled={unbiasedModeEnabled}
            name='hideFullName'
            fallback={<i className='ri-user-3-line' />}
          >
            {getCollaboratorInitials(firstname, lastname) || getInitial(email)}
          </MarketplaceUnbiasedModeField>
        </div>
      </div>
      <MarketplaceUnbiasedModeField
        enabled={unbiasedModeEnabled}
        name='hidePicture'
        fallback={null}
      >
        <img
          className='image photo'
          alt={getCollaboratorInitials(firstname, lastname)}
          src={src}
          onError={() => setSrc(defaultLink)}
        />
      </MarketplaceUnbiasedModeField>
    </div>
  );
};

export default Thumbnail;
