import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import SearchResults from '../../graphql/fragments/SearchResults';

export const query = gql`
  query getSearchPoolResults(
    $searchPoolId: ID!
    $searchesCriteria: [SearchCriteriaInput]
    $categories: [SearchCategoryInput]
    $sortBy: String
    $excludeHidden: Boolean
    $includeProfilesInJob: String
    $shownInterestOnly: Boolean
  ) {
    searchPool(id: $searchPoolId) {
      id
      searchResults(
        searchesCriteria: $searchesCriteria
        categories: $categories
        excludeHidden: $excludeHidden
        includeProfilesInJob: $includeProfilesInJob
        shownInterestOnly: $shownInterestOnly
        sortBy: $sortBy
      ) {
        ...SearchResults
      }
    }
  }
  ${SearchResults}
`;

export default graphql(query, {
  options: ({
    searchPoolId,
    searches,
    selectedCategory,
    excludeHidden,
    includeProfilesInJob,
    shownInterestOnly,
    sortBy,
  }) => {
    const categories = [
      {
        categoryId: 'all',
        withCount: selectedCategory === 'all',
        withResults: selectedCategory === 'all',
      },
      // {
      //   categoryId: 'saved',
      //   withCount: true,
      //   withResults: selectedCategory === 'saved',
      // },
      {
        categoryId: 'new',
        withCount: true,
        withResults: false,
      },
      {
        categoryId: 'new-next-week',
        withCount: selectedCategory === 'new-next-week',
        withResults: selectedCategory === 'new-next-week',
      },
      // {
      //   categoryId: 'contacted',
      //   withCount: true,
      //   withResults: selectedCategory === 'contacted',
      // },
    ];

    return {
      variables: {
        searchPoolId,
        searchesCriteria: searches,
        categories,
        sortBy,
        excludeHidden,
        includeProfilesInJob,
        shownInterestOnly,
      },
      fetchPolicy: 'network-only',
    };
  },
  props: ({ data: { loading, error, searchPool } }) => ({
    loadingSearchResultsCategories: loading,
    errorSearchResults: error,
    searchResultsCategories: searchPool?.searchResults?.categories,
  }),
});
