import React, { useEffect, useRef } from 'react';
import _, { compose } from 'underscore';
import { withRouter } from 'react-router-dom';
import { Segment, Icon } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { REGISTER_PROFILE_VIEW } from '@/graphql/searchPoolProfiles';
import CandidateClientInterestStates from '@/containers/Profile/Resume/CandidateClientInterestStates/CandidateClientInterestStates';
import CandidatePowerHours from '@/containers/Profile/Resume/CandidatePowerHours/CandidatePowerHours';
import { sentryCaptureException } from '@/common';
import { withLastProfileActionsContextConsumer } from '@/context/LastProfileActionsContext';
import withOffer from '@/hocs/offers/withOffer';
import withProfile from '@/hocs/profiles/withProfile';
import withMarkSeen from '@/hocs/searchPoolProfileState/withMarkSeen';
import Background from '@/containers/Profile/Resume/Background';
import ProfileHeader from '@/containers/Profile/Resume/Card/ProfileHeader';
import SmartSummary from '@/containers/Profile/Resume/Card/SmartSummary';
import TimeLineItems from '@/containers/Profile/Contact/TimeLineItems';
import ProfileContactFlow from '@/containers/Profile/Contact/ProfileContactFlow';
import PendingActions from '@/containers/Profile/Contact/PendingActions';
import useClient from '@/graphql/hooks/clients/useClient';

import SearchPoolProfileActions from './components/SearchPoolProfileActions';
import ProfileJobState from './components/ProfileJobState';
import ProfilePreviewLoading from './components/ProfilePreviewLoading';
import TrialSidePanel from './TrialSidePanel';

import './ProfilePreview.css';

const ProfilePreview = ({
  clientId,
  profileId,
  profile,
  profileError,
  loadingProfile,
  lastProfileActions,
  user,
  onMarkHidden,
  onMarkUnhidden,
  onSend,
  searches,
  markSeen,
  setProfilesInJobIds,
  t,
}) => {
  const searchPoolState = profile?.searchPoolState;
  const { seen, searchPoolId, searchPoolProfileId } = searchPoolState || {};
  const wasSeen = useRef(false);
  const { data } = useClient(clientId);

  const [updateProfileViews] = useMutation(REGISTER_PROFILE_VIEW);

  useEffect(() => {
    if (!profile) {
      return;
    }
    if (searchPoolId && profileId) {
      updateProfileViews({
        variables: {
          searchPoolId,
          profileId,
          origin: {
            type: 'app',
            URL: window.location.href,
          },
        },
      });
    }
    // eslint-disable-next-line
  }, [searchPoolId, profileId]);

  useEffect(() => {
    try {
      if (
        searchPoolProfileId &&
        searchPoolId &&
        searchPoolState &&
        !seen &&
        !wasSeen.current
      ) {
        wasSeen.current = true;
        markSeen({ profileId: searchPoolProfileId, searchPoolId });
      }
    } catch (e) {
      sentryCaptureException(e);
    }
  }, [searchPoolProfileId, searchPoolId, searchPoolState, seen, markSeen]);

  const { resumeData, offer } = profile || {};
  const jobTitle = offer?.title;
  const isUnclassified = !offer || offer?.isUnclassified;

  if (loadingProfile) {
    return <ProfilePreviewLoading />;
  }
  if (profileError || !resumeData) {
    return (
      <div className='profile-preview'>
        <div className='resume-section-container'>
          <Segment className='resume-data-card-placeholder' />
        </div>
      </div>
    );
  }
  const lastProfileAction = _.last(lastProfileActions);

  return (
    <div className='profile-preview'>
      <div className='resume-section-container'>
        <Segment className='hiresweet-segment resume-data-card'>
          <div className='modal-profile-header'>
            <ProfileHeader
              profile={profile}
              profileId={profileId}
              firstname={resumeData?.firstname}
              lastname={resumeData?.lastname}
              resumeData={resumeData}
              clientId={clientId}
              watchCollectViewMode
              canApplyUnbiasedMode
            />
            <SearchPoolProfileActions
              clientId={clientId}
              clientProfileId={profileId}
              clientProfile={{ profileId: profile.id, jobOfferId: offer.id }}
              searchPoolState={profile?.searchPoolState}
              resumeData={profile?.resumeData}
              user={user}
              isUnclassified={isUnclassified}
              onMarkHidden={onMarkHidden}
              onMarkUnhidden={onMarkUnhidden}
              searches={searches}
              jobTitle={jobTitle}
              setProfilesInJobIds={setProfilesInJobIds}
              t={t}
            />
          </div>
          <CandidatePowerHours profile={profile} clientId={clientId} />
          <CandidateClientInterestStates
            profile={profile}
            clientId={clientId}
          />
          <SmartSummary
            isWatchProfile
            onLogAction={() => {}}
            profileId={profileId}
            argumentation={resumeData?.smartSummary?.arguments}
            skills={getSortedSkillsWishes({
              hiresweetData: resumeData?.sourceData?.hiresweet,
            })}
            neutralMode
            talentStrategist={profile?.talentStrategist}
          />
          <Background
            clientId={clientId}
            resumeData={resumeData}
            profileId={profileId}
          />
        </Segment>
      </div>

      {data?.client.hasLimitedAccessToProfiles ? (
        <div className='trial-side-panel'>
          <TrialSidePanel />
        </div>
      ) : (
        <div className='contact-section-container'>
          <div className='contact-section'>
            {profile && profile?.resumeData?.step !== 'pending' && (
              <div className='profile-job-state-container'>
                <ProfileJobState profile={profile} t={t} />
              </div>
            )}
            <Segment className='segment-custom-padding'>
              {profile?.resumeData?.step === 'contacted' &&
              lastProfileAction?.profileId === profile?.id &&
              lastProfileAction.type === 'send' ? (
                <div className='success'>
                  <h3>
                    {t(
                      'watchCollect.results.profileCard.contact.emailSuccessfullySent',
                    )}
                  </h3>
                  <div className='icon-container'>
                    <Icon name='paper plane' />
                  </div>
                </div>
              ) : (
                <ProfileContactFlowWithOffer
                  contactFlow={profile?.contactFlow}
                  profile={profile}
                  profileId={profile.id}
                  clientId={clientId}
                  offer={offer} // added by withOffer HOC
                  offerId={offer?.id}
                  stepId={profile?.resumeData?.step}
                  searchText=''
                  getNextProfileId={() => {}}
                  onChangeStep={() => {}}
                  onChangeProfile={() => {}}
                  onSend={onSend}
                  handleMarkProfileAsInMove={() => {}}
                  handleUnmarkProfileAsInMove={() => {}}
                  getProfilesInMove={() => {}}
                  isSearchResultProfile // TODO: hack to tell Send button not to fetch nextProfileId
                />
              )}
            </Segment>
            <PendingActions
              profile={profile}
              contactFlow={profile?.contactFlow}
              clientId={clientId}
            />
            <TimeLineItems
              clientId={clientId}
              profileId={profileId}
              isWatchProfile
            />
          </div>
        </div>
      )}
    </div>
  );
};

const getSortedSkillsWishes = ({ hiresweetData }) => {
  const { target, okWith } = hiresweetData?.wishes?.skills || {};
  // console.log('hiresweetData?.wishes?.skills', hiresweetData?.wishes?.skills);
  const mergedWishes = [...(target || []), ...(okWith || [])];
  return _.sortBy(mergedWishes, ({ relevance }) => -relevance);
};

// TODO: cleanup / explicit
const ProfileContactFlowWithOffer = withOffer(ProfileContactFlow);

export default compose(
  withMarkSeen,
  withProfile,
  withRouter,
  withLastProfileActionsContextConsumer,
  withTranslation('translations'),
)(ProfilePreview);
