/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import GenericButton from '@/components/Common/GenericButton';
import { getRandomString } from '@/common';
import styles from './ConditionsChaining.module.less';

import { useConditionsChainingContext } from './useConditionsChainingContext';
import IfStatementForm from './form-components/IfStatementForm';
import ConditionsChainingElseStatementValueEditor from './form-components/ElseStatementValueEditor';
import IfStatementSimplifiedForm from './form-components/IfStatementSimplifiedForm';
import ElseStatementSimplifiedForm from './form-components/ElseStatementSimplifiedForm';

const ConditionsChainingForm: React.FC = () => {
  const { t } = useTranslation();
  const {
    conditionsChainingState,
    setConditionsChainingState,
    hasIfStatements,
  } = useConditionsChainingContext();

  const addIfStatement = useCallback(() => {
    setConditionsChainingState((previousState) => ({
      ...previousState,
      ifStatements: [
        ...previousState.ifStatements,
        {
          id: getRandomString(12),
          condition: {
            id: getRandomString(12),
            type: 'field-condition',
            fieldCategory: 'native',
            fieldId: 'firstname',
            fieldType: 'string',
            accept: {
              id: getRandomString(12),
              type: 'is-known',
            },
          },
          result: {
            id: getRandomString(12),
            name: '',
            type: 'text-with-snippets',
            text: '',
            snippets: [],
          },
        },
      ],
    }));
  }, [setConditionsChainingState]);

  const fieldId = useMemo(() => conditionsChainingState.switchField?.fieldId, [
    conditionsChainingState,
  ]);

  const elseTitle = useMemo(
    () =>
      fieldId === 'gender'
        ? `${t('editor.conditionsChainingVariable.if')} ${t(
            'editor.conditionsChainingVariable.constructionOptions.gender',
          )} ${t(
            `editor.conditionsChainingVariable.acceptConditionOptions.is-unknown`,
          ).toLowerCase()}`
        : undefined,
    [t, fieldId],
  );

  return (
    <>
      <div className={styles.wrapper}>
        {hasIfStatements && (
          <>
            {conditionsChainingState.subtype === 'switch-on-field' ? (
              <>
                {_.map(conditionsChainingState.ifStatements, (ifStatement) => (
                  <IfStatementSimplifiedForm
                    ifStatement={ifStatement}
                    key={ifStatement.id}
                  />
                ))}
                <ElseStatementSimplifiedForm
                  elseStatement={conditionsChainingState.elseStatement}
                  title={elseTitle}
                />
              </>
            ) : (
              _.map(
                conditionsChainingState.ifStatements,
                (ifStatement, index) => (
                  <IfStatementForm
                    index={index}
                    ifStatement={ifStatement}
                    key={ifStatement.id}
                  />
                ),
              )
            )}
          </>
        )}
        {conditionsChainingState.subtype !== 'switch-on-field' && (
          <GenericButton className={styles.button} onClick={addIfStatement}>
            {hasIfStatements
              ? t('editor.conditionsChainingVariable.addElseIf')
              : t('editor.conditionsChainingVariable.addIf')}{' '}
          </GenericButton>
        )}
      </div>
      {conditionsChainingState.subtype !== 'switch-on-field' && (
        <div className={styles.wrapper}>
          <div className={styles.statementWrapper}>
            <h4>{t('editor.conditionsChainingVariable.else')}</h4>
            <ConditionsChainingElseStatementValueEditor
              elseStatement={conditionsChainingState.elseStatement}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ConditionsChainingForm;
