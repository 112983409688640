const SILO_TO_SUBDOMAIN_MAP = {
  1: 'app',
  2: 'app2',
  3: 'app3',
};

export const getGreenhouseCandidateURL = (candidateId: string | null) => {
  if (!candidateId) {
    return null;
  }
  const silo = getGreenhouseSilo(candidateId);
  const subdomain = SILO_TO_SUBDOMAIN_MAP[silo];
  return `https://${subdomain}.greenhouse.io/people/${candidateId}`;
};

export const getGreenhouseJobURL = (jobId: string | null) => {
  if (!jobId) {
    return null;
  }
  const silo = getGreenhouseSilo(jobId);
  const subdomain = SILO_TO_SUBDOMAIN_MAP[silo];
  return `https://${subdomain}.greenhouse.io/sdash/${jobId}`;
};

const getGreenhouseSilo = (candidateId: string): 1 | 2 | 3 => {
  if (candidateId.length <= 9) {
    return 1;
  }
  if (candidateId.endsWith('002')) {
    return 2;
  }
  if (candidateId.endsWith('003')) {
    return 3;
  }
  return 1;
};
