import React from 'react';
import { compose } from 'underscore';
import { withRouter, Redirect } from 'react-router-dom';
import withProfileFromSearchPoolProfile from '../../../hocs/profiles/withProfileFromSearchPoolProfile';
import ProfilePreviewLoading from '../components/ProfilePreviewLoading';

const ClientProfileLoader = ({
  loadingProfile,
  errorProfile,
  profileFromSearchPoolProfile,
  location,
}) => {
  if (loadingProfile) {
    return <ProfilePreviewLoading />;
  }
  if (errorProfile || !profileFromSearchPoolProfile) {
    // remove the queryString
    return <Redirect to={location.pathname} />;
  }
  const newQueryString = `?profileId=${profileFromSearchPoolProfile.id}`;
  return (
    <Redirect to={{ pathname: location.pathname, search: newQueryString }} />
  );
};

export default compose(
  withProfileFromSearchPoolProfile,
  withRouter,
)(ClientProfileLoader);
