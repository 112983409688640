import gql from 'graphql-tag';

export const GET_LINKEDIN_ACCOUNTS = gql`
  query getClientLinkedinAccounts($clientId: ID!) {
    client(id: $clientId) {
      id
      linkedinAccounts {
        id
        name
        type
        owner {
          email
          lastname
          firstname
        }
        notifiedEmail
        synchronizations {
          invitationAccepted {
            enabled
          }
          inMailReceived {
            enabled
          }
        }
        creationDate
        lastEditionDate
        state {
          type
          errorType
        }
      }
    }
  }
`;

export const CREATE_LINKEDIN_ACCOUNT = gql`
  mutation CreateLinkedinAccount($input: CreateLinkedinAccountInput!) {
    linkedinAccount {
      create(input: $input) {
        linkedinAccount {
          id
          name
          type
          notifiedEmail
          synchronizations {
            invitationAccepted {
              enabled
            }
            inMailReceived {
              enabled
            }
          }
        }
      }
    }
  }
`;

export const DELETE_LINKEDIN_ACCOUNT = gql`
  mutation deleteLinkedinAccount($input: DeleteLinkedinAccountInput!) {
    linkedinAccount {
      delete(input: $input) {
        id
      }
    }
  }
`;

export const UPDATE_LINKEDIN_ACCOUNT = gql`
  mutation updateLinkedinAccount($input: UpdateLinkedinAccountInput!) {
    linkedinAccount {
      update(input: $input) {
        name
        type
        notifiedEmail
        synchronizations {
          invitationAccepted {
            enabled
          }
          inMailReceived {
            enabled
          }
        }
      }
    }
  }
`;
