import _ from 'underscore';
import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalProps } from 'semantic-ui-react';
import ChevronLeft from '@/components/Reveal/Icons/ChevronLeft';
import GenericButton from '../GenericButton';
import GenericCheckbox from '../GenericCheckbox';
import GenericModal from '../GenericModal';
import { GenericSelectProps } from '../GenericSelect/GenericSelect';
import TimezoneSelect from '../TimezoneSelect';
import TimeSelector from '../TimeSelector/TimeSelector';
import { Timezone } from '../TimezoneSelect/timezones';


import styles from './TimeSelectorModal.module.less';

// can be a time ex: 14:56 or a ISOString for full dates
type DateOrTimeString = string;

type DefaultTimeOptionType = {
  label: string;
  time: DateOrTimeString;
  formatter?: (value: string) => string;
};

export type CustomTimeSelectionComponent = FC<{
  currentTime: string | null;
  onChange: (time: string) => void;
}>;

export interface TimeSelectorModalProps {
  defaultOptions?: DefaultTimeOptionType[];
  defaultTime?: string | null;
  defaultTimezone?: Timezone | null;
  title?: string | null;
  label?: string | null;
  allowCustom?: boolean;
  customSelectionComponent?: CustomTimeSelectionComponent;
  customSelectionValue?: CustomTimeSelectionComponent;
  onChange: (time: string | null, timezone: string) => void;
  submitLabel?: string;
}

interface SelectableItemProps {
  onChange: () => void;
  checked?: boolean;
  label: string;
}

interface DefaultTimeOptionProps {
  onSelected: (time: string) => void;
  label: string;
  time: DateOrTimeString;
  formatter?: DefaultTimeOptionType['formatter'];
}

interface TimezoneToggleProps {
  label?: string | null;
  timezone: Timezone;
  onChange: GenericSelectProps['onChange'];
}

const SelectableItem: FC<SelectableItemProps> = ({
  onChange,
  children,
  checked = false,
  label,
}) => {
  return (
    <div
      tabIndex={0}
      role='checkbox'
      aria-checked={checked}
      onClick={onChange}
      onKeyDown={onChange}
      className={classNames(styles.defaultTimeOption, {
        [styles.checked]: checked,
      })}
    >
      <div>
        <GenericCheckbox
          onClick={onChange}
          className={styles.circleCheckbox}
          checked={checked}
        />
      </div>
      {label && <span className={styles.label}>{label}</span>}
      {children}
    </div>
  );
};

const DefaultTimeOption: FC<Omit<SelectableItemProps, 'onChange'> &
  DefaultTimeOptionProps> = ({
  label,
  time,
  formatter = (v) => v,
  onSelected,
  ...rest
}) => {
  return (
    <SelectableItem label={label} onChange={() => onSelected(time)} {...rest}>
      <span className={styles.time}>{formatter(time)}</span>
    </SelectableItem>
  );
};

const CustomTimeSelector: FC<{
  currentTime: string | null;
  onChange: (time: string) => void;
}> = ({ currentTime, onChange }) => {
  return (
    <span className={styles.timeInput}>
      <TimeSelector time={currentTime} onChange={onChange} />
    </span>
  );
};

const TimezoneToggle: FC<TimezoneToggleProps> = ({
  timezone,
  onChange,
  label,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className={styles.headLabel}>
        {label && <span>{label}</span>}
        <span
          className={classNames(styles.timezoneToggle, {
            [styles.up]: open,
            [styles.down]: !open,
          })}
          onClick={() => setOpen((o) => !o)}
        >
          {open ? t('common.hide') : t('common.timezone.manage', { timezone })}
          <ChevronLeft />
        </span>
      </div>
      {open && (
        <TimezoneSelect
          defaultValue={{ value: timezone, label: timezone }}
          className={styles.timezoneSelect}
          onChange={onChange}
        />
      )}
    </div>
  );
};

const TimeSelectorModal: FC<TimeSelectorModalProps & ModalProps> = ({
  defaultOptions,
  defaultTime,
  defaultTimezone,
  title,
  label,
  onChange = () => {},
  onClose,
  allowCustom = true,
  customSelectionComponent = null,
  customSelectionValue = null,
  submitLabel,
  ...rest
}) => {
  // Get default value for customsSelectionValue only if needed
  if (!customSelectionValue && !customSelectionComponent) {
    // eslint-disable-next-line no-param-reassign
    customSelectionValue = CustomTimeSelector;
  }

  const { t } = useTranslation();
  const [customSelected, setCustomSelected] = useState(() => {
    if (!defaultTime) {
      return false;
    }

    const customOptionSelected = defaultOptions?.some(
      ({ time: dTime }) => dTime === defaultTime,
    );
    return !customOptionSelected;
  });

  const userTimezone = Intl.DateTimeFormat().resolvedOptions()
    .timeZone as Timezone;
  const [innerTime, setInnerTime] = useState(
    defaultTime || defaultOptions?.[0]?.time || null,
  );
  const [innerTimezone, setInnerTimezone] = useState<Timezone>(
    defaultTimezone || userTimezone,
  );

  const CustomSelectedComponent = customSelectionComponent;
  const CustomSelectedValue = customSelectionValue;

  return (
    <GenericModal size='tiny' onClose={onClose} {...rest}>
      <Modal.Header>{title}</Modal.Header>

      <Modal.Content>
        <TimezoneToggle
          label={label}
          timezone={innerTimezone}
          onChange={(selectedTimezone) => {
            if (!selectedTimezone?.value) {
              return;
            }
            setInnerTimezone(selectedTimezone.value as Timezone);
          }}
        />
        {defaultOptions?.length &&
          _.map(defaultOptions,
            ({ label: optionLabel, time: optionTime, formatter }, index) => (
              <DefaultTimeOption
                key={index}
                checked={innerTime === optionTime && !customSelected}
                onSelected={(selectedTime) => {
                  setCustomSelected(false);
                  setInnerTime(selectedTime);
                }}
                label={optionLabel}
                time={optionTime}
                formatter={formatter}
              />
            )
          )}
          
        {allowCustom && (
          <>
            <SelectableItem
              label={t('common.custom')}
              checked={customSelected}
              onChange={() => {
                setCustomSelected(true);
                if (!innerTime) {
                  // Small hack to make it work if the person clicks custom
                  // sees 12:00 and says "oh that's ok", and clicks accept
                  setInnerTime(defaultTime ?? null);
                }
              }}
            >
              {customSelected && CustomSelectedValue && (
                <CustomSelectedValue
                  currentTime={innerTime}
                  onChange={(time) => setInnerTime(time)}
                />
              )}
            </SelectableItem>
            {customSelected && CustomSelectedComponent && (
              <CustomSelectedComponent
                currentTime={innerTime}
                onChange={(time) => setInnerTime(time)}
              />
            )}
          </>
        )}
      </Modal.Content>

      <Modal.Actions>
        <GenericButton
          size='big'
          primacy='secondary'
          // @ts-ignore
          onClick={onClose || (() => {})}
        >
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          size='big'
          primacy='primary'
          onClick={() => {
            onChange(innerTime, innerTimezone);
          }}
        >
          {submitLabel ?? t('common.update')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default TimeSelectorModal;
