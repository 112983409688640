import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import Author from '@/graphql/fragments/Author';
import TranslatableText from '@/graphql/fragments/TranslatableText';
import { SearchPoolProfile as SearchPoolProfileType } from '@/types/searchPoolProfile';
import SearchPoolProfile from '@/graphql/fragments/SearchPoolProfile';

export const MERGE_PROFILES = gql`
  mutation MergeProfiles($searchPoolId: ID!, $input: MergeProfilesInput!) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      mergeProfiles(input: $input) {
        ...SearchPoolProfile
        recommendedMissions {
          missionId
          skipped
          recommendationDate
          arguments {
            type
            ... on ReviewArgument {
              content {
                default
                fr
                en
              }
            }
          }
        }
        missionsInfo {
          missionId
          mission {
            ... on RevealJob {
              missionCategory {
                type
              }
            }
          }
        }
        privacyState {
          markedAsDoNotContact
          reason {
            id
            title {
              ...TranslatableText
            }
          }
        }
        source {
          sourceId
          importDate
          type
          recruiter {
            ...Author
          }
          importName
          externalSourceId
          externalSourceName
        }
        linkedProfilesGroup {
          id
          linkedProfileReferences {
            profileId
            title {
              ...TranslatableText
            }
            metadata {
              key
              value
            }
          }
        }
        ... on RevealProfile {
          hiresweetDetails {
            smartHunt {
              batchId
              timestamp
              items {
                key
                value
              }
            }
            matchingOffers {
              batchId
              timestamp
              items {
                offerId
                title
                url
                criteria {
                  type
                  value
                }
                score {
                  key
                  value
                }
                activity {
                  key
                  value
                }
              }
            }
            modelScores {
              timestamp
              items {
                key
                value
              }
            }
            tags {
              name {
                default
              }
            }
            marketplaceHistory {
              timestamp
              items {
                key
                value
              }
            }
          }
          company {
            id
            name
          }
        }
      }
    }
  }
  ${SearchPoolProfile}
  ${Author}
  ${TranslatableText}
`;

type Data = {
  data: {
    searchPoolProfile: {
      mergeProfiles: {
        profile: SearchPoolProfileType;
      };
    };
  };
};

type Variables = {
  searchPoolId: string;
  input: {
    primaryProfileId: string;
    secondaryProfileId: string;
  };
};

const useMergeProfiles = () =>
  useMutation<Data, Variables>(MERGE_PROFILES, {
    refetchQueries: [
      'getSearchPoolJobProfiles',
      'getRevealSearchPoolResults',
      'getSearchPoolJobProfileResults',
      'getSearchPoolJobs',
    ],
  });

export default useMergeProfiles;
