import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import TalentStrategist from '@/graphql/fragments/TalentStrategist';
import TranslatableText from '../../graphql/fragments/TranslatableText';
import SweetDate from '../../graphql/fragments/SweetDate';
import TextWithEnrichmentsAndStack from '../../graphql/fragments/TextWithEnrichmentsAndStack';
import TranslatableTextWithEnrichments from '../../graphql/fragments/TranslatableTextWithEnrichments';
import Skill from '../../graphql/fragments/Skill';
import TextWithEnrichments from '../../graphql/fragments/TextWithEnrichments';
import RelevantTag from '../../graphql/fragments/RelevantTag';
import Attachment from '../../graphql/fragments/Attachment';
import { ApplicationForm } from '../../graphql/fragments/Applications';

export const GET_PROFILE_RESUME_WITHOUT_SKILLS = gql`
  query getProfileResumeWithoutSkills($id: ID!) {
    profile(id: $id) {
      id
      creationTimestamp
      lastStepUpdateTimestamp
      source {
        type
      }
      resumeData {
        step
        subStep
        hidePersonnalData
        firstname
        lastname
        headline {
          ...TextWithEnrichmentsAndStack
        }
        location {
          name {
            ...TranslatableText
          }
        }
        prequalification {
          type
        }
        mainEducation {
          schoolName
          startDate {
            ...SweetDate
          }
          endDate {
            ...SweetDate
          }
        }
        email
        summary {
          ...TextWithEnrichmentsAndStack
        }
        sources {
          linkedin
          github
          twitter
          website
          stackoverflow
          dribbble
          behance
        }
        photoLink
        relevantActivities
        relevantTags {
          ...RelevantTag
        }
        phoneNumber
        application {
          questionsForm {
            ...ApplicationForm
          }
        }

        experiences {
          location {
            name {
              ...TranslatableText
            }
          }
          title {
            text
          }
          description {
            text
          }
          startDate {
            ...SweetDate
          }
          endDate {
            ...SweetDate
          }
          photoLink
          companyName
          companyWebsite
          stack {
            ...Skill
          }
          workplace {
            id
            data {
              name
              types {
                id
                name {
                  ...TranslatableText
                }
              }
              foundedYear
              stack {
                name {
                  default
                  fr
                  en
                }
                icon
                detailsURL
                ...Skill
              }
              mainLocation {
                name {
                  ...TranslatableText
                }
              }
              sources {
                sourceId
                url
                name {
                  ...TranslatableText
                }
              }
              staffCount {
                min
                max
              }
              industries {
                name {
                  ...TranslatableText
                }
              }
              description {
                ...TranslatableTextWithEnrichments
              }
            }
          }
        }
        education {
          schoolName
          degree {
            text
          }
          startDate {
            ...SweetDate
          }
          endDate {
            ...SweetDate
          }
          photoLink
          schoolWebsite
          stack {
            ...Skill
          }
        }
        sourceData {
          hiresweet {
            clientInterestStates {
              clientId
              interestItems {
                id
                type
                state
                message {
                  text
                }
                creationDate
                lastEditionDate
                ... on CandidateInterestItemJobOffer {
                  jobOfferId
                }
                ... on CandidateInterestItemCompanyDepartment {
                  departmentId
                }
              }
            }
          }
          github {
            relevantFacts {
              title {
                ...TranslatableText
              }
              description {
                ...TranslatableText
              }
            }
            repositories {
              name {
                ...TextWithEnrichments
              }
              fullname {
                ...TextWithEnrichments
              }
              description {
                ...TextWithEnrichments
              }
              fork
              createdAt {
                ...SweetDate
              }
              updatedAt {
                ...SweetDate
              }
              stargazersCount
              watchersCounts
              forksCount
              language
            }
          }
          stackoverflow {
            relevantFacts {
              title {
                ...TranslatableText
              }
              description {
                ...TranslatableText
              }
            }
          }
          linkedin {
            skills {
              label {
                ...TranslatableText
              }
              endorsementsCount
              relevance
            }
          }
        }
        sourceName
        smartSummary {
          arguments {
            type
            ... on GroupedKeyPointsArgument {
              groups {
                category
                keyPoints {
                  category
                  label {
                    ...TranslatableText
                  }
                  content {
                    ...TranslatableText
                  }
                  popupContent {
                    ...TranslatableText
                  }
                }
              }
            }
            ... on ReviewArgument {
              stack {
                ...Skill
              }
              title {
                ...TranslatableText
              }
              content {
                ...TranslatableText
              }
            }
          }
        }
      }
      attachments {
        ...Attachment
      }
      processStep {
        milestone {
          id
        }
      }
      annotation {
        globalFavorite {
          value
          lastEditionDate
          author {
            firstname
            lastname
            email
          }
        }
        globalComment {
          value
          lastEditionDate
          author {
            firstname
            lastname
            email
          }
        }
      }
      views {
        timeline {
          author {
            firstname
            lastname
            email
            photoLink
          }
          date
          timestamp
          origin {
            type
            URL
          }
        }
        statsByRecruiter {
          author {
            firstname
            lastname
            email
            photoLink
          }
          firstViewTimestamp
          lastViewTimestamp
          count
        }
      }
      talentStrategist {
        ...TalentStrategist
      }
      powerHourFlags {
        powerHourId
        isFlagged
      }
      firstContactDate
    }
  }
  ${TalentStrategist}
  ${TranslatableText}
  ${Skill}
  ${SweetDate}
  ${TextWithEnrichments}
  ${TextWithEnrichmentsAndStack}
  ${TranslatableTextWithEnrichments}
  ${RelevantTag}
  ${Attachment}
  ${ApplicationForm}
`;

export default graphql(GET_PROFILE_RESUME_WITHOUT_SKILLS, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.profileId,
    },
  }),
  props: ({ data: { loading, profile, error } }) => ({
    loading,
    profileResumeLoading: loading,
    profile,
    error,
  }),
  skip: (ownProps) => !ownProps.profileId,
});
