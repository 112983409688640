import React, { Component } from 'react';
import _ from 'underscore';
import { withTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';
import CustomSelect from './CustomSelect';
import './SalaryRange.less';
import { DEBOUNCE_INPUT_MS } from '../PureCriteriaForm';

class CustomMoneyInput extends Component {
  constructor(props) {
    super(props);
    const { currentValue } = this.props;
    this.state = {
      value: currentValue,
    };
  }

  handleChange = (_ev, { name, value }) => {
    const { onChange } = this.props;
    const { value: oldValue } = this.state;
    this.setState({ value: value ? parseInt(value, 10) || oldValue : value });
    onChange({ name, value });
  };

  render() {
    const { placeholderText, name, showUnit = true } = this.props;
    const { value } = this.state;

    if (showUnit) {
      return (
        <Input
          name={name}
          className='money-input'
          label={showUnit ? { basic: true, content: 'K' } : null}
          labelPosition='right'
          type='text'
          value={value}
          placeholder={placeholderText || ''}
          onChange={this.handleChange}
          maxLength={showUnit ? 3 : 6}
        />
      );
    }

    return (
      <Input
        name={name}
        className='money-input-no-unit'
        type='text'
        value={value}
        placeholder={placeholderText || ''}
        onChange={this.handleChange}
        maxLength={showUnit ? 3 : 6}
      />
    );
  }
}

const currencyOptions = [
  { value: 'EUR', label: <span>Euros (€)</span> },
  { value: 'USD', label: <span>Dollars ($)</span> },
  { value: 'GBP', label: <span>Pounds (£)</span> },
];

const languageToCurrencyValue = (lng) => {
  return lng?.slice(0, 2) === 'fr' ? 'EUR' : 'USD';
};

class SalaryRange extends Component {
  constructor(props) {
    super(props);
    const { currentValue, showUnit = true } = this.props;
    this.state = {
      min: showUnit ? currentValue?.min / 1000 || 0 : currentValue?.min || 0,
      max: showUnit ? currentValue?.max / 1000 || 0 : currentValue?.max || 0,
      currency:
        currentValue?.currency ||
        languageToCurrencyValue(props.i18n.resolvedLanguage),
    };
  }

  handleChange = ({ name, value }) => {
    const { onChange } = this.props;
    let { min, max, currency } = this.state;
    if (name === 'min') {
      min = _.isNaN(parseInt(value, 10)) ? value : parseInt(value, 10);
      this.setState({ min });
    } else if (name === 'max') {
      max = _.isNaN(parseInt(value, 10)) ? value : parseInt(value, 10);
      this.setState({ max });
    } else {
      currency = value?.value;
      this.setState({ currency });
    }

    let error = null;
    if (
      (min && !_.isNumber(min)) ||
      (max && !_.isNumber(max)) ||
      min < 0 ||
      max < 0 ||
      (min > 0 && _.isNumber(max) && max < min)
    ) {
      error = true;
      this.setState({ error });
    } else {
      this.setState({ error: null });
    }

    onChange({
      label: 'salary',
      value: {
        value: {
          min:
            this.props.showUnit ?? true
              ? _.isNaN(1000 * min)
                ? min
                : 1000 * min
              : min,
          max:
            this.props.showUnit ?? true
              ? _.isNaN(1000 * max)
                ? max
                : 1000 * max
              : max,
          currency,
        },
      },
      error,
    });
  };

  render() {
    const {
      min: currentMin,
      max: currentMax,
      currency: currentCurrency,
      error,
    } = this.state;

    const { showUnit = true } = this.props;

    return (
      <div className='salary-section'>
        <CustomMoneyInput
          name='min'
          currentValue={currentMin}
          error={error}
          onChange={_.debounce(this.handleChange, DEBOUNCE_INPUT_MS)}
          placeholderText='min'
          showUnit={showUnit}
        />
        <div className='inline-separator'>-</div>
        <CustomMoneyInput
          name='max'
          currentValue={currentMax}
          error={error}
          onChange={_.debounce(this.handleChange, DEBOUNCE_INPUT_MS)}
          placeholderText='max'
          showUnit={showUnit}
        />
        <div className='currency'>
          <CustomSelect
            width='120px'
            label='currency'
            options={currencyOptions}
            value={_.findWhere(currencyOptions, { value: currentCurrency })}
            onChange={this.handleChange}
            placeholderText='Currency'
          />
        </div>
      </div>
    );
  }
}

// export default withI18n()(SalaryRange);
export default withTranslation('translations')(SalaryRange);
