import React, { useEffect, useState } from 'react';
import _ from 'underscore';

import { SNIPPET_TYPES } from '@/common/mergeTags/utils';
import AutomationConditionsChainingPlaceholder from './AutomationConditionsChainingPlaceholder';
import AutomationClassicPlaceholder from './AutomationClassicPlaceholder';
import SyncedFragmentPlaceholder from './SyncedFragmentPlaceholder';

const AutomationPlaceholder = (props) => {
  const {
    offsetKey,
    children,
    contentState,
    entityKey,
    snippets,
    setIsReadOnly,
    onUpdate,
    readOnly,
    onEditSmartVariable,
    clientSnippets,
    handleUnsyncFragment,
    clientCustomFields,
    clientMissionCustomFields,
  } = props;

  const [snippet, setSnippet] = useState(null);
  const [clientFragment, setClientFragment] = useState(null);

  useEffect(() => {
    if (!snippets) {
      return;
    }
    const entity = contentState.getEntity(entityKey);
    const key = entity?.data?.key;

    const clientSyncedFragment = _.find(clientSnippets, (clientSnippet) => {
      return (
        clientSnippet.id === key.split('_')[0] &&
        clientSnippet.type === SNIPPET_TYPES.FRAGMENT
      );
    });

    if (clientSyncedFragment) {
      setClientFragment(clientSyncedFragment);
      return;
    }

    const matchedSnippet = _.find(snippets, (sn) => {
      return sn?.id === key;
    });

    if (!matchedSnippet) {
      return;
    }
    setSnippet(matchedSnippet);
  }, [entityKey, snippets, contentState, clientSnippets]);

  if (snippet?.type === SNIPPET_TYPES.CONDITIONS_CHAINING) {
    return (
      <AutomationConditionsChainingPlaceholder
        offsetKey={offsetKey}
        readOnly={readOnly}
        setIsReadOnly={setIsReadOnly}
        onEditSmartVariable={() => onEditSmartVariable(snippet)}
      >
        {children}
      </AutomationConditionsChainingPlaceholder>
    );
  }

  if (clientFragment) {
    return (
      <SyncedFragmentPlaceholder
        offsetKey={offsetKey}
        readOnly={readOnly}
        setIsReadOnly={setIsReadOnly}
        handleUnsyncFragment={handleUnsyncFragment}
        clientFragment={clientFragment}
        snippets={snippets}
        clientSnippets={clientSnippets}
        clientCustomFields={clientCustomFields}
        clientMissionCustomFields={clientMissionCustomFields}
      >
        {children}
      </SyncedFragmentPlaceholder>
    );
  }

  return (
    <AutomationClassicPlaceholder
      variable={snippet}
      readOnly={readOnly}
      setIsReadOnly={setIsReadOnly}
      offsetKey={offsetKey}
      onUpdate={onUpdate}
      onEditSmartVariable={() => onEditSmartVariable(snippet)}
    >
      {children}
    </AutomationClassicPlaceholder>
  );
};

export default AutomationPlaceholder;
