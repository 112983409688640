import React, { FC } from 'react';
import { Popup } from 'semantic-ui-react';

import useIsPlugin from '@/hooks/common/useIsPlugin';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import { Sequence } from '@/types/sequence';
import ProfileRowSequence from '@/routes/RevealView/ProfileRow/ProfileRowSequence';
import SequencePreview from '@/revealComponents/SequencePreview';
import useClientId from '@/hooks/router/useClientId';

import styles from './SequenceDropdownItem.module.less';

interface SequenceDropdownItemProps {
  sequence: Sequence;
  shiftSidebar?: boolean;
}

const SequenceDropdownItem: FC<SequenceDropdownItemProps> = ({
  sequence,
  shiftSidebar,
}) => {
  const clientId = useClientId();
  const isPlugin = useIsPlugin();
  const { isMinimized } = useMinimizedView();

  return (
    <Popup
      className={`sequence-preview-popup${isPlugin ? '-plugin' : ''}`}
      basic
      on='hover'
      disabled={isMinimized}
      hoverable
      position={shiftSidebar ? 'left center' : 'right center'}
      trigger={
        <div className={styles.sequencePopupItem}>
          <span
            className={
              isPlugin ? styles.pluginSequenceTitle : styles.sequenceTitle
            }
          >
            {sequence?.title}
          </span>
          {!isPlugin && (
            <ProfileRowSequence
              currentSequenceInfo={sequence}
              sequences={sequence?.contactFlow?.sequences}
              nextInteraction={null}
              loading={false}
              isStandalone
              mini
              styles={styles.dropdownSequenceContainer}
            />
          )}
        </div>
      }
      content={<SequencePreview clientId={clientId} sequence={sequence} />}
      popperModifiers={[
        {
          // Fix a positioning problem
          name: 'preventOverflow',
          options: {
            boundariesElement: 'viewport',
          },
        },
      ]}
    />
  );
};

export default SequenceDropdownItem;
