import _ from 'underscore';
import { ClientMissionDailyEvent } from '@/types/statistics/clientStatistics';
import { DailyEvent } from '@/types/statistics/dailyEvent';

export const convertClientMissionDailyEvent = ({
  details,
  day,
}: ClientMissionDailyEvent): DailyEvent => ({
  day,
  values: {
    nbSourced: _.reduce(
      details,
      (sum, { type, count }) => sum + (type === 'nbSourced' ? count : 0),
      0,
    ),
  },
});

export default {};
