import { useEffect, useState } from 'react';
import _ from 'underscore';

function useCandidateSelection<
  TCandidate extends { id: string; isSelected?: boolean }
>() {
  const [allCandidates, setAllCandidates] = useState<TCandidate[]>([]);
  const [candidatesWithSelection, setCandidatesWithSelection] = useState<{
    [x: string]: TCandidate;
  }>({});

  /**
   * Create object containing profile id as key, and profile data + boolean isSelected as value
   * @param candidates
   * @param value
   * @returns
   */
  const createCandidateSelectionMap = (
    candidates: TCandidate[],
    value: boolean,
  ) => {
    return candidates.reduce<{ [x: string]: TCandidate }>(
      (accumulator, currentValue) => {
        accumulator[currentValue.id] = { ...currentValue, isSelected: value };
        return accumulator;
      },
      {},
    );
  };

  useEffect(() => {
    setCandidatesWithSelection(
      createCandidateSelectionMap(allCandidates, false),
    );
    // eslint-disable-next-line
  }, [allCandidates.length]);

  const getSelectedIds = (): string[] => {
    const result = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(candidatesWithSelection)) {
      if (value.isSelected) {
        result.push(key);
      }
    }
    return result;
  };

  const getSelected = (): TCandidate[] => {
    return _.filter(
      _.values(candidatesWithSelection),
      (value) => value?.isSelected || false,
    );
  };

  const isAllCandidatesSelected = _.every(
    _.values(candidatesWithSelection),
    (value) => !!value.isSelected,
  );

  /**
   * Check if profile is selected
   * @returns boolean
   */
  const isSelected = ({ profileId }: { profileId: string }): boolean => {
    return candidatesWithSelection[profileId]?.isSelected || false;
  };

  /**
   * Check if multiple profiles are selected
   * @returns boolean
   */
  const areSelected = ({ profilesIds }: { profilesIds: string[] }): boolean => {
    return _.every(profilesIds, (id) => isSelected({ profileId: id }));
  };

  const select = (candidate: TCandidate) => {
    setCandidatesWithSelection((previousValue) => ({
      ...previousValue,
      [candidate.id]: { ...previousValue[candidate.id], isSelected: true },
    }));
  };

  /**
   * Unselect profile
   * @param candidate
   */
  const unselect = (candidate: TCandidate) => {
    setCandidatesWithSelection((previousValue) => ({
      ...previousValue,
      [candidate.id]: { ...previousValue[candidate.id], isSelected: false },
    }));
  };

  const toggle = (candidate: TCandidate) => {
    if (!candidate) {
      return;
    }
    if (isSelected({ profileId: candidate.id })) {
      unselect(candidate);
    } else {
      select(candidate);
    }
  };

  const selectMultiple = (profile: TCandidate, candidates: TCandidate[]) => {
    candidates.forEach((candidate) => select(candidate));
  };

  const unselectMultiple = (candidates: TCandidate[]) => {
    candidates.forEach((candidate) => unselect(candidate));
  };

  const get = () => {
    return getSelected();
  };

  const getAll = () => {
    return allCandidates;
  };

  const reset = () => {
    setCandidatesWithSelection(
      createCandidateSelectionMap(allCandidates, false),
    );
  };

  const updateAllCandidates = ({
    candidates,
  }: {
    candidates: TCandidate[];
  }) => {
    return setAllCandidates(candidates);
  };

  const selectAll = (pageIndex: number, candidatePerPage: number) => {
    const idsToSelect = _.keys(candidatesWithSelection);

    const pageIds = idsToSelect.slice(
      pageIndex * candidatePerPage,
      (pageIndex + 1) * candidatePerPage,
    );

    pageIds.forEach((id) => select({ id } as TCandidate));
  };

  const selectAllAcrossAllPages = () => {
    return setCandidatesWithSelection(
      createCandidateSelectionMap(allCandidates, true),
    );
  };

  const onSelectAllInCurrentPageToggle = (
    checked: boolean,
    pageIndex: number,
    candidatePerPage: number,
    candidates: TCandidate[],
  ) => {
    if (checked) {
      selectAll(pageIndex, candidatePerPage);
    } else if (isAllCandidatesSelected) {
      reset();
    } else {
      unselectMultiple(candidates);
    }
  };

  const handleRangeSelect = ({ profileId }: { profileId: string }) => {
    const firstSelectedProfileIndex = _.findIndex(allCandidates, (profile) =>
      isSelected({ profileId: profile.id }),
    );
    if (firstSelectedProfileIndex === -1) {
      return;
    }
    const selectedProfileIndex = _.findIndex(
      allCandidates,
      (profile) => profile.id === profileId,
    );
    if (!selectedProfileIndex) {
      return;
    }
    for (let i = firstSelectedProfileIndex; i <= selectedProfileIndex; i++) {
      select(allCandidates[i]);
    }
  };

  return {
    select,
    unselect,
    toggle,
    onSelectAllInCurrentPageToggle,
    selectMultiple,
    get,
    isSelected,
    areSelected,
    reset,
    getSelectedIds,
    getAll,
    selectAll,
    updateAllCandidates,
    selectAllAcrossAllPages,
    isAllCandidatesSelected,
    handleRangeSelect,
  };
}

export type CandidateSelection = ReturnType<typeof useCandidateSelection>;

export default useCandidateSelection;
