import React, { FC } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import { Task } from '@/types/task';
import {
  getTaskType,
  TASK_ICON_BY_TYPE,
  TASK_TITLE_BY_TYPE,
  TASK_TYPES,
} from '@/common/constants/taskTypes';

import TaskTable from './TaskTable';

type TaskTypeTableProps = {
  tasks: Task[];
  onSelect: (id: string, selection?: Task[]) => void;
};

const displayedTaskTypes = _.without(
  _.keys(TASK_TITLE_BY_TYPE),
  TASK_TYPES.SEND_EMAIL_MANUAL,
) as (keyof typeof TASK_TITLE_BY_TYPE)[];

const TaskTypeTable: FC<TaskTypeTableProps> = ({ tasks, onSelect }) => {
  const { t } = useTranslation();

  const tasksWithoutCompletedTasks = _.filter(
    tasks,
    (task) => task?.state !== 'completed',
  );

  const tasksWithoutCompletedStateByType = getTasksGroupedByType(
    tasksWithoutCompletedTasks,
  );

  return (
    <>
      {_.map(displayedTaskTypes, (type) => {
        const tableTasks = tasksWithoutCompletedStateByType[type] || [];
        return (
          <TaskTable
            key={type}
            title={t(`sequences.tasks.${type || 'other'}`)}
            iconName={TASK_ICON_BY_TYPE[type]}
            tasks={tableTasks}
            onFocusTask={({ taskId }: { taskId: string }) => {
              onSelect(taskId, tableTasks);
            }}
          />
        );
      })}
    </>
  );
};

function getTasksGroupedByType(tasks: Task[]): Record<string, Task[]> {
  const tasksByType = _.groupBy(tasks, (task) => getTaskType(task));

  const otherTasks = _.union(
    tasksByType.manual,
    tasksByType['custom-task'],
    _.filter(tasks, ({ type }) => !type),
  );

  tasksByType['other-tasks'] = otherTasks;
  tasksByType['custom-task'] = otherTasks;
  tasksByType.manual = otherTasks;
  tasksByType[TASK_TYPES.SEND_EMAIL] = _.union(
    tasksByType[TASK_TYPES.SEND_EMAIL],
    tasksByType[TASK_TYPES.SEND_EMAIL_MANUAL],
  );
  tasksByType[TASK_TYPES.SEND_EMAIL_MANUAL] = _.union(
    tasksByType[TASK_TYPES.SEND_EMAIL],
    tasksByType[TASK_TYPES.SEND_EMAIL_MANUAL],
  );

  return tasksByType;
}

export default TaskTypeTable;
