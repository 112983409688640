import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import { EvaluationsSet } from '../../graphql/fragments/Evaluations';

export const deleteProfileEvaluationsSet = gql`
  mutation deleteProfileEvaluationsSet($id: ID!, $evaluationsSetId: ID!) {
    deleteProfileEvaluationsSet(id: $id, evaluationsSetId: $evaluationsSetId) {
      id
      evaluationsSets {
        ...EvaluationsSet
      }
    }
  }
  ${EvaluationsSet}
`;

export default graphql(deleteProfileEvaluationsSet, {
  props: ({ mutate }) => ({
    deleteProfileEvaluationsSet: ({ id, evaluationsSetId }) => {
      const variables = { id, evaluationsSetId };
      return mutate({ variables });
    },
  }),
});
