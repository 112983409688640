import React, { useContext } from 'react';
import _ from 'underscore';
import {
  Form,
  ModalActions as Actions,
  ModalContent as Content,
  ModalHeader as Header,
} from 'semantic-ui-react';

import GenericButton from '@/components/Common/GenericButton';
import { recruitersToOptions } from '../helpers';
import EmailContext from '../../../../../context/EmailContext';
import CheckboxList from '../../../../../components/CheckboxList';

import './SenderModalStep3.css';

const SenderModalStep3 = (props) => {
  const {
    ownerEmail,
    senderDetails,
    grantedRecruiters,
    setGrantedRecruiters,
    toPrevStep,
    toNextStep,
    users,
    t,
  } = props;

  const { currentAddress, currentEmailType } = useContext(EmailContext);

  const selectValue = (value) => {
    setGrantedRecruiters(_.uniq([...grantedRecruiters, value]));
  };

  const unSelectValue = (value) => {
    setGrantedRecruiters(
      _.reject(grantedRecruiters, (recruiter) => recruiter === value),
    );
  };

  const displayName =
    currentEmailType === 'outlook'
      ? outlookDisplayName(senderDetails)
      : gmailDisplayName(senderDetails);

  const shareWithOptions = recruitersToOptions({ users, ownerEmail });
  return (
    <>
      <Header>{t('settings.senders.modalStep3.header')}</Header>
      <Content>
        <div className='modal-main-description'>
          {t('settings.senders.modalStep3.description')}
        </div>

        <div className='input-container'>
          <div className='input-label'>
            {t('settings.senders.modalStep3.emailAccount')}
          </div>
          <div className='input-element'>{`${displayName} (${currentAddress})`}</div>
        </div>

        {!_.isEmpty(shareWithOptions) && (
          <div className='input-container'>
            <div className='input-label'>
              {t('settings.senders.modalStep3.sharedWith')}
            </div>
            <Form.Field className='users-checkbox-list'>
              <CheckboxList
                options={shareWithOptions}
                selectedValues={grantedRecruiters}
                selectValue={selectValue}
                unSelectValue={unSelectValue}
              />
            </Form.Field>
          </div>
        )}
      </Content>
      <Actions>
        <div className='modal-actions'>
          <GenericButton onClick={toPrevStep} primacy='secondary' size='big'>
            {t('settings.senders.modalPrevious')}
          </GenericButton>
          <GenericButton type='submit' onClick={toNextStep} size='big'>
            {t('settings.senders.modalSubmit')}
          </GenericButton>
        </div>
      </Actions>
    </>
  );
};

const outlookDisplayName = ({ firstname, lastname }) => {
  return `${firstname || ''} ${lastname || ''}`.trim();
};

const gmailDisplayName = ({ alias, firstname, lastname }) => {
  const fallbackName = `${firstname || ''} ${lastname || ''}`.trim();
  return (alias && alias.trim()) || fallbackName;
};

export default SenderModalStep3;
