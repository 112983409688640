import { TranslatableText } from './text';

export interface Mission {
  id: string;
  workflows: MissionWorkflow[];
}

export enum WORKFLOW_RULE_TYPES {
  REVEAL_MISSION_UPDATE_PROFILE_STAGE = 'reveal-mission-update-profile-stage',
  // REVEAL_ARCHIVE_IN_ATS = 'reveal-archive-in-ats',
  // ATS_UPDATE_PROFILE_STAGE = 'ats-update-profile-stage',
  // REVEAL_PROFILE_ENROLLED = 'reveal-profile-enrolled',
}
export enum WORKFLOW_INTERNAL_RULE_TYPES {
  REVEAL_MISSION_UPDATE_PROFILE_STAGE = 'reveal-mission-update-profile-stage',
  REVEAL_PROFILE_ENROLLED = 'reveal-profile-enrolled',
}

export enum WORKFLOW_EXTERNAL_RULE_TYPES {
  ATS_UPDATE_PROFILE_STAGE = 'ats-update-profile-stage',
}

export enum WORKFLOW_ACTION_COMMANDS {
  // MOVE_TO_MISSION_STAGE = 'move-to-mission-stage',
  MOVE_TO_ATS_STAGE = 'move-to-ats-stage',
}

export enum WORKFLOW_INTERNAL_ACTION_COMMANDS {
  MOVE_TO_MISSION_STAGE = 'move-to-mission-stage',
}

export enum WORKFLOW_EXTERNAL_ACTION_COMMANDS {
  MOVE_TO_ATS_STAGE = 'move-to-ats-stage',
}

export type MissionWorkflow = {
  id: string;
  isDraft?: boolean;
  rule: {
    type: WORKFLOW_RULE_TYPES;
    condition: {
      revealStageId?: string;
      atsStageId?: string;
    };
  };
  action: {
    type: 'external-service' | 'internal';
    command: WORKFLOW_ACTION_COMMANDS;
    service?: string;
    integrationId?: string;
    target: {
      atsStageId?: string;
      linkedATSJobId?: string;
      linkedATSUserId?: string;
      revealStageId?: string;
    };
  };
  warnings?: {
    message: TranslatableText;
  }[];
};
