import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { TranslatableText } from '@/types/text';
import { sanitizeTypename } from '@/common/utils/apollo';
import useClientId from '@/hooks/router/useClientId';
import TranslatableTextFragment from '@/graphql/fragments/TranslatableText';

export const getHumanContactCategorySubtypes = gql`
  query getHumanContactCategorySubtypes($clientId: ID!) {
    client(id: $clientId) {
      id
      humanContactCategorySubtypes {
        id
        title {
          ...TranslatableText
        }
        isDefault
      }
    }
  }
  ${TranslatableTextFragment}
`;

export type HumanContactCategorySubtype = {
  id: string;
  title: TranslatableText;
  isDefault?: boolean;
};

type Data = {
  client: {
    id: string;
    humanContactCategorySubtypes?: HumanContactCategorySubtype[];
  };
};

type Variables = {
  clientId: string;
};

type Input = {
  queryOptions?: QueryHookOptions<Data, Variables>;
};

function useHumanContactCategorySubtypes({ queryOptions = {} }: Input = {}) {
  const clientId = useClientId();
  const query = useQuery<Data, Variables>(getHumanContactCategorySubtypes, {
    ...queryOptions,
    variables: { clientId },
  });
  const humanContactCategorySubtypes = useMemo(
    () =>
      sanitizeTypename(query.data?.client.humanContactCategorySubtypes || []),
    [query.data],
  );
  return { ...query, humanContactCategorySubtypes };
}

export default useHumanContactCategorySubtypes;
