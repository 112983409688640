import React from 'react';
import { Icon, Popup, PopupProps } from 'semantic-ui-react';

import './LabelDetails.css';

const QuestionMarkIcon = <Icon className='question-mark-icon' name='question circle outline' size='small' />;

const LabelDetails: React.FC<PopupProps> = ({
  className,
  text,
  children,
  size = 'tiny',
  position = 'bottom center',
  basic,
  inverted,
}) => {
  return (
    <Popup
      className={className}
      trigger={QuestionMarkIcon}
      size={size}
      position={position}
      inverted={inverted}
      content={text}
      basic={basic}
    >
      {children}
    </Popup>
  );
};

export default LabelDetails;
