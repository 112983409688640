import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import UserSettings from '../../graphql/fragments/UserSettings';

export const mutation = gql`
  mutation updateUser($input: SettingsInput!, $clientId: ID!) {
    updateUser(input: $input, clientId: $clientId) {
      ...UserSettings
    }
  }
  ${UserSettings}
`;

export default graphql(mutation, {
  props: ({ ownProps, mutate }) => ({
    updateUser: (input) =>
      mutate({
        variables: {
          input,
          clientId: ownProps.clientId,
        },
      }),
  }),
});
