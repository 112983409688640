import React, { useState, useMemo, useCallback } from 'react';
import _, { compose } from 'underscore';
import { useMutation } from '@apollo/client';
import ReactDatePicker from 'react-datepicker';
import { Button, Form, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import GenericModal from '@/components/Common/GenericModal';
import GenericButton from '@/components/Common/GenericButton';
import { useClientMissionCustomFields } from '@/graphql/hooks/clients/useClientMissionCustomFields';
import {
  combineDataWithCustomFields,
  createCustomFieldsPayload,
} from '@/common/customFields';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import { ATS_WITHOUT_JOB, ATS_WITHOUT_NAME, ATS_WITHOUT_USER, ATS_WITH_OWNER, ATS_WITH_SUBSECTION } from '@/common/reveal';
import { useClientCurrentAtsId, useClientRevealConnector } from '@/graphql/hooks/clients/useClientRevealProjects';
import { getTranslatedText } from '@/common';
import { getDateValueAsDate } from '@/components/FormFields';
import { UPDATE_SEARCH_POOL_JOB } from '../../../../graphql/searchPoolJob';
import withClientUsers from '../../../../hocs/clients/withClientUsers';
import contextToProps from '../../../../hocs/contextToProps';
import { getUserOptions } from '../../optionsHelpers';

import './EditMissionModal.css';
import MissionDepartmentsForm from './InformationsTab/MissionDepartments';

const EditMissionModal = ({
  clientId,
  searchPoolId,
  job,
  onClose,
  users,
  onShowNotification,
}) => {
  const { t } = useTranslation();
  const configurationParams = useMergedConfigurationParams();
  const { formState, formOptions } = useEditMissionState({ job, users });
  const [editMission] = useMutation(UPDATE_SEARCH_POOL_JOB);
  const [selectedFoldering, setSelectedFoldering] = useState({
    departmentId: job?.foldering?.department?.id || '',
    sectionId: job?.foldering?.section?.id || '',
    subsectionId: job?.foldering?.subsection?.id || '',
  });

  const disableEdition = job.isArchived && configurationParams?.shouldDisableArchivedEdition === 'true';

  const atsId = useClientCurrentAtsId();
  const connector = useClientRevealConnector(clientId);

  const selectedUser = _.findWhere(users, { email: formState?.ownerEmail });

  const { missionCustomFields } = useClientMissionCustomFields(clientId);

  const customFieldsInitialValues = useMemo(() => {
    const customDetails = combineDataWithCustomFields(missionCustomFields, job);

    return _.object(
      customDetails.map((o) => [o.clientCustomFieldId, o.rawValue || null]),
    );
  }, [missionCustomFields, job]);

  const [filledCustomFields, setFilledCustomFields] = useState(
    customFieldsInitialValues || {},
  );

  const onCustomFieldChange = useCallback(
    async (customFieldId, value) => {
      setFilledCustomFields({
        ...filledCustomFields,
        [customFieldId]: value,
      });
    },
    [filledCustomFields],
  );

  const onSubmit = async () => {
    try {
      const variables = {
        searchPoolId,
        input: {
          jobId: job?.id,
          title: formState?.title,
          ...(selectedUser && {
            owner: {
              email: selectedUser?.email,
              firstname: selectedUser?.firstname,
              lastname: selectedUser?.lastname,
            },
          }),
          ...(filledCustomFields && {
            customFields: createCustomFieldsPayload(
              filledCustomFields,
              missionCustomFields,
            ),
          }),
          ...(selectedFoldering.departmentId && {
            departmentId: selectedFoldering.departmentId,
          }),
          ...(selectedFoldering.sectionId && {
            sectionId: selectedFoldering.sectionId,
          }),
          ...(selectedFoldering.subsectionId && {
            subsectionId: selectedFoldering.subsectionId,
          }),
        },
      };
      await editMission({ variables });

      onClose();

      onShowNotification({
        message: t('reveal.missions.mission.modals.editSuccess'),
        level: 'success',
      });
    } catch (e) {
      console.error(e);
      const error = e?.graphQLErrors?.[0]?.data?.details;
      onShowNotification({
        message: error || t('reveal.missions.mission.modals.editError'),
        level: 'error',
      });
    }
  };

  const usesName = !ATS_WITHOUT_NAME.includes(atsId);
  const atsWithoutJob =
    connector?.type && ATS_WITHOUT_JOB.includes(connector.type);

  return (
    <GenericModal
      open
      onClose={onClose}
      size='tiny'
      onClick={(event) => event.stopPropagation()}
    >
      {/* <Modal className='edit-mission-modal' open size='tiny'> */}
      <Modal.Header>
        {t('reveal.missions.mission.modals.editModalTitle')}
      </Modal.Header>

      <Modal.Content>
        <Form>
          {usesName && job?.__typename === 'RevealJob' && (
            <Form.Input
              disabled={disableEdition}
              label={t('reveal.missions.mission.modals.editModalTitleLabel')}
              value={formState?.title}
              onChange={(e, { value }) => formState.setTitle(value)}
              input={{ 'data-form-type': 'other' }}
            />
          )}

          <Form.Dropdown
            disabled={disableEdition}
            label={t('reveal.missions.mission.modals.editModalOwnerLabel')}
            selection
            search
            value={formState.ownerEmail}
            onChange={(e, { value }) => formState.setOwnerEmail(value)}
            options={formOptions.owners}
          />
          {_.map(missionCustomFields, (customField) => (
            <div className='custom-field-date-container' key={customField.id}>
              {customField.type === 'text' && (
                <Form.TextArea
                  disabled={configurationParams?.shouldDisableCustomFieldsEdition === 'true' || disableEdition}
                  label={getTranslatedText(customField.title)}
                  value={filledCustomFields[customField.id]}
                  onChange={(e, { value }) =>
                    onCustomFieldChange(customField.id, value)
                  }
                  input={{ 'data-form-type': 'other' }}
                  key={customField.id}
                />
              )}
              {customField.type === 'inline-text' && (
                <Form.Input
                  disabled={configurationParams?.shouldDisableCustomFieldsEdition === 'true' || disableEdition}
                  label={getTranslatedText(customField.title)}
                  value={filledCustomFields[customField.id]}
                  onChange={(e, { value }) =>
                    onCustomFieldChange(customField.id, value)
                  }
                  input={{ 'data-form-type': 'other' }}
                  key={customField.id}
                />
              )}
              {customField.type === 'enum' && (
                <Form.Dropdown
                  disabled={configurationParams?.shouldDisableCustomFieldsEdition === 'true' || disableEdition}
                  label={getTranslatedText(customField.title)}
                  selection
                  search
                  value={
                    !customField.isMultiselect
                      ? filledCustomFields[customField.id]?.[0]
                      : null
                  }
                  multiple={customField.isMultiple}
                  onChange={(e, { value }) =>
                    onCustomFieldChange(customField.id, [value])
                  }
                  options={customField.options?.map((option) => ({
                    key: option.id,
                    text: getTranslatedText(option.title),
                    value: option.id,
                  }))}
                  key={customField.id}
                />
              )}
              {customField.type === 'float' && (
                <Form.Input
                  disabled={configurationParams?.shouldDisableCustomFieldsEdition === 'true' || disableEdition}
                  type='number'
                  step='any'
                  label={getTranslatedText(customField.title)}
                  value={filledCustomFields[customField.id]}
                  onChange={(e, { value }) =>
                    onCustomFieldChange(customField.id, value)
                  }
                  input={{ 'data-form-type': 'other' }}
                  key={customField.id}
                />
              )}
              {customField.type === 'integer' && (
                <Form.Input
                  disabled={configurationParams?.shouldDisableCustomFieldsEdition === 'true' || disableEdition}
                  type='number'
                  step='any'
                  label={getTranslatedText(customField.title)}
                  value={filledCustomFields[customField.id]}
                  onChange={(e, { value }) =>
                    onCustomFieldChange(customField.id, value)
                  }
                  input={{ 'data-form-type': 'other' }}
                  key={customField.id}
                />
              )}
              {customField.type === 'day' && (
                <>
                  <label>{getTranslatedText(customField.title)}</label>
                  <ReactDatePicker
                    disabled={configurationParams?.shouldDisableCustomFieldsEdition === 'true' || disableEdition}
                    value={getDateValueAsDate(
                      filledCustomFields[customField.id],
                    )}
                    onChange={(e) => onCustomFieldChange(customField.id, e)}
                    selected={getDateValueAsDate(
                      filledCustomFields[customField.id],
                    )}
                    dateFormat='yyyy-MM-dd'
                  />
                </>
              )}
            </div>
          ))}
          <MissionDepartmentsForm
            foldering={selectedFoldering}
            onUpdateFoldering={setSelectedFoldering}
            disabled={configurationParams?.shouldDisableOrganisationEdition === 'true' || disableEdition}
          />
        </Form>
      </Modal.Content>

      <Modal.Actions>
        {/* <div className='align-left'>
          <Button className='dismiss' type='button' onClick={onClose}>
            {t('common.cancel')}
          </Button>
        </div>

        <Button
          size='big'
          primary
          // disabled={!_.isEmpty(displayedErrors)}
          type='button'
          onClick={onSubmit}
        // loading={creatingSequence || updatingSequence}
        >
          {t('common.save')}
        </Button> */}
        <GenericButton primacy='secondary' size='big' onClick={onClose}>
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          size='big'
          onClick={onSubmit}
        >
          {t('common.save')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

const useEditMissionState = ({ job, users }) => {
  const [title, setTitle] = useState(job?.data?.title);
  const [ownerEmail, setOwnerEmail] = useState(job?.owner?.email);

  const formState = {
    title,
    ownerEmail,
    setTitle,
    setOwnerEmail,
  };

  const formOptions = {
    owners: getUserOptions(users),
  };
  return {
    formState,
    formOptions,
  };
};

export default compose(contextToProps, withClientUsers)(EditMissionModal);
