import React, { FC } from 'react';
import timezones from './timezones';
import GenericSelect, { GenericSelectProps } from '../GenericSelect';

type Option = {
  label: string;
  value: string;
};

const timezoneOptions = timezones.map((timezone) => ({
  label: timezone,
  value: timezone,
}));

const TimezoneSelect: FC<Omit<GenericSelectProps<Option>, 'options'>> = ({
  ...props
}) => {
  return <GenericSelect options={timezoneOptions} {...props} />;
};

export default TimezoneSelect;
