import React, { FC, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  DisplayedTaskType,
  displayedTaskTypes,
  SequenceStatistic,
} from '@/types/statistics/clientStatistics';
import useClientId from '@/hooks/router/useClientId';
import AccordionButton from '@/components/Common/AccordionButton';
import { getKPIItems } from '../helpers/kpis';
import KPICell from './KPICell/KPICell';
import { isEmpty } from './helpers';
import { PrefixFilter } from '../helpers/filters';
import PrefixStatistics from './PrefixStatistics/PrefixStatistics';

import styles from './SequenceRow.module.less';

interface SequenceRowProps {
  stats: SequenceStatistic;
  fetchDetails?: (
    sequenceId: string,
    taskType: DisplayedTaskType,
    prefix?: PrefixFilter,
  ) => void;
}

const SequenceRow: FC<SequenceRowProps> = ({
  stats: { id, statistics, title, prefixStatistics },
  fetchDetails,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const [showPrefixDetails, setShowPrefixDetails] = useState(false);

  const clickListeners = fetchDetails
    ? _.reduce(
        displayedTaskTypes,
        (aggregator, taskType) => ({
          ...aggregator,
          [taskType]: () => fetchDetails(id, taskType),
        }),
        {} as Record<DisplayedTaskType, () => void>,
      )
    : undefined;

  const kpiItems = getKPIItems({
    stats: statistics,
    t,
    clickListeners,
  });

  return (
    <div className={styles.row}>
      <div className={styles.main}>
        <div className={styles.name}>
          {!isEmpty(prefixStatistics) && (
            <AccordionButton
              active={showPrefixDetails}
              className={styles.prefixToggle}
              onClick={() => setShowPrefixDetails(!showPrefixDetails)}
            />
          )}
          <Link
            className={styles.link}
            to={`/client/${clientId}/reveal/sequences/${id}`}
          >
            {title || '???'}
          </Link>
        </div>

        {_.map(kpiItems, (kpiItem) => (
          <KPICell key={kpiItem.id} kpiItem={kpiItem} />
        ))}
      </div>
      {showPrefixDetails && (
        <PrefixStatistics
          totalContacted={kpiItems[1].value}
          stats={prefixStatistics}
          fetchDetails={
            fetchDetails
              ? (taskType, prefix) => fetchDetails(id, taskType, prefix)
              : undefined
          }
        />
      )}
    </div>
  );
};

export default SequenceRow;
