import { gql, useMutation } from '@apollo/client';

const UPDATE_COMPANY_PIPELINE_STAGE = gql`
  mutation UpdateCompanyPipelineStage(
    $profileId: ID!
    $stageId: ID!
    $searchPoolId: ID!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      updateCompanyPipelineStage(profileId: $profileId, stageId: $stageId) {
        profile {
          ... on RevealProfile {
            id
            companyDealState {
              type
              customCompanyDealStateId
            }
          }
        }
      }
    }
  }
`;

export function useUpdateCompanyPipelineStage() {
  return useMutation(UPDATE_COMPANY_PIPELINE_STAGE);
}
