import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import sanitizeTimeLineItem from '@/common/sanitizeTimeLineItem';
import useClientId from '@/hooks/router/useClientId';
import { ATS_LOGOS_BY_TYPE } from '@/common/reveal';
import GenericButton from '@/components/Common/GenericButton';
import UnsubscribedPill from '@/components/Reveal/Profile/privacy/UnsubscribedPill/UnsubscribedPill';
import Thumbnail from '../../../../../components/Thumbnail';
import EmailTrackingPopup from '../EmailTrackingPopup';
import IntegrationsSynchronizationStatus from '../IntegrationsSynchronizationStatus';
import TASK_ICONS from '../TaskIcons';
import AuthorAndDate from '../AuthorAndDate';

export const TimeLineBody = React.memo(({ content }) => {
  return (
    <div
      className='body-content'
      dangerouslySetInnerHTML={{ __html: sanitizeTimeLineItem(content) }}
    />
  );
});

const ClassicMessageItem = ({
  item,
  unbiasedModeEnabled,
  profile,
  isBodyOverflown,
  textAreaStyle,
  blurEffectActive,
  // getCardElt,
  showMoreClicked,
  onShowMoreClicked,
  changeOverflowStatus,
  cardBodyTitle,
  cardBody,
}) => {
  const { t } = useTranslation();
  const { date, author } = item || {};
  // const { firstname, lastname } = profile?.resumeData || {};
  // let profileName = `${firstname || ''} ${lastname || ''}`.trim();
  // profileName = unbiasedModeEnabled ? '???' : profileName;
  // const recruiterName = `${author.firstname || ''} ${author.lastname || ''}`.trim();
  const translatedDateOrDuration = +date
    ? t('common.formattedDate', {
        date: new Date(+date),
      })
    : t('common.defaultDuration');

  return (
    <div className='timeline-card-mail-wrapper classic-item'>
      <div className='content'>
        <div className='header'>
          <Thumbnail
            link={author?.photoLink}
            firstname={item.author.firstname}
            lastname={item.author.lastname}
            email={item.author.email}
            unbiasedModeEnabled={false}
          />
          <div className='author'>
            <div className='date'>{translatedDateOrDuration}</div>
            {item.action.type === 'messageSequence' ? (
              <span>
                {item?.action?.from
                  ? `${t('profile.contact.timeline.from')} ${
                      item?.action?.from
                    } `
                  : ''}
                {`${t('profile.contact.timeline.to')} ${item.action.to ||
                  profile.email}`}
              </span>
            ) : item.action.from || item.action.to ? (
              <span>
                {item.action.from
                  ? `${t('profile.contact.timeline.from')} ${item.action.from} `
                  : ''}
                {item.action.to
                  ? `${t('profile.contact.timeline.to')} ${item.action.to}`
                  : ''}
              </span>
            ) : (
              <span>
                {item.author.firstname} {item.author.lastname}
              </span>
            )}
            {_.findWhere(item.action?.events || [], {
              type: 'click-unsubscribe-link',
            }) ? (
              <UnsubscribedPill showLabel />
            ) : (
              ''
            )}
          </div>
          <EmailTrackingPopup action={item.action} />
        </div>
        <div className='card-content message'>
          <div className='row'>
            {cardBodyTitle && <div className='title'>{cardBodyTitle}</div>}
            <div className='spacer' />
            {isBodyOverflown &&
              (!showMoreClicked ? (
                <div className='show-more' onClick={onShowMoreClicked}>
                  {t('profile.contact.timeline.seeMore')}
                </div>
              ) : (
                <div className='show-more' onClick={onShowMoreClicked}>
                  {t('profile.contact.timeline.seeLess')}
                </div>
              ))}
          </div>
          <div
            ref={changeOverflowStatus}
            className='body'
            style={{
              ...(textAreaStyle.height && { height: textAreaStyle.height }),
            }}
          >
            {blurEffectActive && <div className='blur-effect' />}
            <TimeLineBody content={cardBody} />
          </div>
        </div>
      </div>
    </div>
  );
};

const RevealMessageItem = ({
  item,
  unbiasedModeEnabled,
  profile,
  isBodyOverflown,
  showMoreClicked,
  onShowMoreClicked,
  setOverflowStatus,
  textAreaStyle,
  openMessageModal,
  // blurEffectActive,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { action, author } = item || {};
  const { firstname, lastname } = profile?.resumeData || {};
  let profileName = `${firstname || ''} ${lastname || ''}`.trim();
  profileName = unbiasedModeEnabled ? '???' : profileName;
  const atsType = item?.ats;
  const atsLogo = ATS_LOGOS_BY_TYPE[atsType];
  const isLastMessage = getIsLastMessage(item, profile);

  const getAllRecruiterSenderAddresses = () =>
    _.uniq(
      _.flatten(
        _.map(profile?.contactFlow?.sequences, (sequence) =>
          _.compact(
            _.map(
              sequence.actions,
              (currentAction) => currentAction.message?.sender?.senderAddress,
            ),
          ),
        ),
      ),
    );

  // Get possible author emails (handling cases like "send as" ...)
  // and filter only existing ones
  const isMessageToProfile = getAllRecruiterSenderAddresses().includes(
    action.from,
  );
  const translationKey = isMessageToProfile
    ? 'message-to-profile'
    : 'message-from-profile';

  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{ backgroundColor: TASK_ICONS.email?.color }}
              className='icon'
            >
              <i className={`${TASK_ICONS.email?.icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            <div>
              <div
                style={{ display: 'inline-block' }}
                dangerouslySetInnerHTML={{
                  __html: t(
                    `profile.contact.timeline.${translationKey}.description`,
                    {
                      authorName: `${author?.firstname ||
                        ''} ${author?.lastname || ''}`,
                      profileName,
                    },
                  ),
                }}
              />{' '}
              {_.findWhere(item.action?.events || [], {
                type: 'click-unsubscribe-link',
              }) ? (
                <UnsubscribedPill showLabel />
              ) : (
                ''
              )}
            </div>
            <AuthorAndDate timelineItem={item} atsLogo={atsLogo}>
              <IntegrationsSynchronizationStatus
                timelineItem={item}
                clientId={clientId}
                profile={profile}
              />
            </AuthorAndDate>
          </div>
          <EmailTrackingPopup action={action} />
        </div>
        <div className='card-content'>
          <div className='row'>
            <div className='title'>
              <span>
                {item.action.from
                  ? `${t('profile.contact.timeline.from')} ${item.action.from} `
                  : ''}
                {item.action.to
                  ? `${t('profile.contact.timeline.to')} ${item.action.to}`
                  : ''}
              </span>
            </div>

            {action?.message?.subject && (
              <div className='title mt'>{action.message.subject}</div>
            )}
          </div>
          <div
            ref={setOverflowStatus}
            className='body'
            style={{
              ...(textAreaStyle.height && {
                height: textAreaStyle.height,
              }),
            }}
          >
            <TimeLineBody content={action?.message?.body} />
            {isBodyOverflown && (
              <div
                className={`gradient-message${
                  showMoreClicked ? ' hidden' : ''
                }`}
              />
            )}
          </div>
          <div className='spacer' />
          {isBodyOverflown && !showMoreClicked && (
            <div className='show-more' onClick={onShowMoreClicked}>
              {t('profile.contact.timeline.seeMore')}
            </div>
          )}
          {isBodyOverflown && showMoreClicked && (
            <div className='show-more' onClick={onShowMoreClicked}>
              {t('profile.contact.timeline.seeLess')}
            </div>
          )}
          {isLastMessage && !isMessageToProfile && openMessageModal && (
            <GenericButton
              onClick={openMessageModal}
              primacy='secondary'
              size='small'
            >
              {t('profile.contact.timeline.reply')}
            </GenericButton>
          )}
        </div>
      </div>
    </div>
  );
};

const getIsLastMessage = (item, profile) => {
  const timeline = profile?.contactData?.timeline;
  if (_.isEmpty(timeline)) {
    return false;
  }

  const messageItems = _.filter(
    timeline,
    ({ action }) => action?.type === 'message',
  );
  if (
    _.any(messageItems, ({ date }) => {
      return +date > +item.date;
    })
  ) {
    return false;
  }
  return true;
};

const MessageItem = ({
  item,
  unbiasedModeEnabled,
  profile,
  isBodyOverflown,
  showMoreClicked,
  onShowMoreClicked,
  setOverflowStatus,
  textAreaStyle,
  blurEffectActive,
  cardBody,
  cardBodyTitle,
  applicationMode,
  openMessageModal,
}) => {
  if (applicationMode === 'classic') {
    return (
      <ClassicMessageItem
        item={item}
        profile={profile}
        unbiasedModeEnabled={unbiasedModeEnabled}
        isBodyOverflown={isBodyOverflown}
        showMoreClick={showMoreClicked}
        onShowMoreClicked={onShowMoreClicked}
        changeOverflowStatus={setOverflowStatus}
        textAreaStyle={textAreaStyle}
        blurEffectActive={blurEffectActive}
        cardBody={cardBody}
        cardBodyTitle={cardBodyTitle}
      />
    );
  }
  return (
    <RevealMessageItem
      item={item}
      profile={profile}
      unbiasedModeEnabled={unbiasedModeEnabled}
      isBodyOverflown={isBodyOverflown}
      showMoreClicked={showMoreClicked}
      onShowMoreClicked={onShowMoreClicked}
      setOverflowStatus={setOverflowStatus}
      textAreaStyle={textAreaStyle}
      blurEffectActive={blurEffectActive}
      openMessageModal={openMessageModal}
    />
  );
};

export default MessageItem;
