import gql from 'graphql-tag';

export const GET_CLIENT_DAILY_COHORTS = gql`
  query getClientDailyCohorts(
    $clientId: ID!
    $sequenceIds: [ID!]
    $userEmails: [ID!]
  ) {
    client(id: $clientId) {
      id
      statistics {
        sequences(sequenceIds: $sequenceIds, userEmails: $userEmails) {
          dailyCohorts {
            day
            details {
              sequenceId
              type
              count
            }
          }
        }
      }
    }
  }
`;

export const GET_CLIENT_DAILY_COHORTS_DETAILS = gql`
  query getClientDailyCohortsDetails(
    $clientId: ID!
    $sequenceIds: [ID!]
    $userEmails: [ID!]
  ) {
    client(id: $clientId) {
      id
      statistics {
        sequences(sequenceIds: $sequenceIds, userEmails: $userEmails) {
          dailyCohorts {
            day
            details {
              type
              sequenceId
              profileItems {
                profileId
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CLIENT_DAILY_EVENTS = gql`
  query getClientStatistics(
    $clientId: ID!
    $sequenceIds: [ID!]
    $userEmails: [ID!]
  ) {
    client(id: $clientId) {
      id
      statistics {
        sequences(sequenceIds: $sequenceIds, userEmails: $userEmails) {
          dailyEvents {
            day
            userStats {
              author {
                email
                firstname
                lastname
              }
              stats {
                type
                count
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CLIENT_DAILY_EVENTS_DETAILS = gql`
  query getClientStatisticsDetails(
    $clientId: ID!
    $sequenceIds: [ID!]
    $userEmails: [ID!]
  ) {
    client(id: $clientId) {
      id
      statistics {
        sequences(sequenceIds: $sequenceIds, userEmails: $userEmails) {
          dailyEvents {
            day
            userStats {
              author {
                email
                firstname
                lastname
              }
              stats {
                type
                profileItems {
                  profileId
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_AGGREGATED_CONTACTS = gql`
  query getAggregatedContacts($clientId: ID!, $categorizations: [String!]!) {
    client(id: $clientId) {
      id
      statistics {
        aggregatedContacts(categorizations: $categorizations) {
          categories {
            categorizationId
            categoryId
          }
          count
        }
      }
    }
  }
`;

export const GET_AGGREGATED_CONTACTS_DETAILS = gql`
  query getAggregatedContactsDetails(
    $clientId: ID!
    $categorizations: [String!]!
    $categoriesFilters: [CategoriesFilterInput]
  ) {
    client(id: $clientId) {
      id
      statistics {
        aggregatedContacts(
          categorizations: $categorizations
          categoriesFilters: $categoriesFilters
        ) {
          categories {
            categorizationId
            categoryId
          }
          profileItems {
            profileId
          }
        }
      }
    }
  }
`;

export const GET_CATEGORY_COHORTS = gql`
  query getCategoryCohorts(
    $clientId: ID!
    $categorization: CategorizationInput!
  ) {
    client(id: $clientId) {
      id
      statistics {
        categoryCohorts(categorization: $categorization) {
          day
          values {
            categoryId
            count
          }
        }
      }
    }
  }
`;
