import React, { FC, useState } from 'react';
import _ from 'underscore';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import parseHtml from 'html-react-parser';

import sanitizeTimeLineItem from '@/common/sanitizeTimeLineItem';
import Thumbnail from '@/components/Thumbnail';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import { Profile } from '@/types/profile';
import { TimelineItem } from '@/types/contactData';
import { Author } from '@/types/author';
import usePinAndUnpinSharedActivity from '@/graphql/hooks/searchPoolProfile/usePinAndUnpinSharedActivity';
import EditSharedNote from '@/routes/RevealView/SearchView/CandidatesListPane/CandidateView/SharedNotes/SharedNoteForm/EditSharedNote';
import useRemoveSharedActivity from '@/graphql/hooks/searchPoolProfile/useRemoveSharedActivity';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import GenericModal from '@/components/Common/GenericModal';
import { Modal } from 'semantic-ui-react';
import GenericButton from '@/components/Common/GenericButton';
import MetaTaskForm from '@/revealComponents/ExplicitTasks/MetaTaskForm';
import { MetaTaskFormat } from '@/revealComponents/ExplicitTasks/MetaTaskForm/types';
import { TranslatableText } from '@/types/text';
import { getTranslatedText } from '@/common';
import useClientCustomActivities from '@/graphql/hooks/clients/useClientCustomActivities';
import { getHtmlStringAsText } from '@/common/utils/htmlToText';
import EditComment from './Comment/EditComment.component';
import TASK_ICONS from './TaskIcons';
import TimelineItemActions from './TimelineItemActions';
import IntegrationsSynchronizationStatus from './IntegrationsSynchronizationStatus';
import AuthorAndDate from './AuthorAndDate';
import TargetsSummary from './TargetsSummary';

export type ActivityLogType =
  | 'call'
  | 'email-from-profile'
  | 'email-to-profile'
  | 'linkedin-message-from-profile'
  | 'linkedin-message-to-profile'
  | 'linkedin-invitation-to-profile'
  | 'linkedin-invitation-accepted-from-profile';

export type ActivityLogInput = {
  note: string;
  subtype: ActivityLogType;
};

export type LogActivityActionLabel = {
  id: string;
};

export interface LogActivityTimeLineItemType extends TimelineItem {
  action: {
    type: string;
    subtype: ActivityLogType;
    format: MetaTaskFormat;
    comment: { text: string };
    labels: [LogActivityActionLabel];
    customActivityId?: string;
    customActivityTitle?: TranslatableText;
  };
}

interface LogActivityTimelineItemProps {
  timelineItem: LogActivityTimeLineItemType;
  unbiasedModeEnabled: boolean;
  profile: SearchPoolProfile | Profile;
  onRemove: () => void;
  onEdit: (payload?: ActivityLogInput) => void;
  clientId: string;
  editMode: boolean;
  setEditMode: (editMode: boolean) => void;
  author: Author;
}

function useSanitizedContent(content?: string) {
  return React.useMemo(
    () => (!_.isEmpty(content) ? sanitizeTimeLineItem(content) : null),
    [content],
  );
}

function getProfileName(
  resumeData: { firstname?: string; lastname?: string } | undefined,
  { unbiasedModeEnabled }: { unbiasedModeEnabled: boolean },
): string {
  if (unbiasedModeEnabled) return '???';

  return `${resumeData?.firstname || ''} ${resumeData?.lastname || ''}`.trim();
}

const ClassicLogActivityTimelineItem: FC<LogActivityTimelineItemProps> = ({
  timelineItem,
  profile,
  unbiasedModeEnabled,
  onRemove,
  onEdit,
  clientId,
  editMode,
  setEditMode,
}) => {
  const { t } = useTranslation();
  const comment = timelineItem.action.comment?.text;
  const content = useSanitizedContent(comment);
  const contentTag = parseHtml(content || '');
  const {
    author = { photoLink: null, firstname: null, lastname: null, email: null },
  } = timelineItem;

  const recruiterName = `${author.firstname || ''} ${
    author.lastname || ''
  }`.trim();
  const profileName = getProfileName(profile?.resumeData, {
    unbiasedModeEnabled,
  });

  return (
    <div className='timeline-card-mail-wrapper classic-item'>
      <div className='content'>
        <div className='header'>
          <Thumbnail
            link={author.photoLink}
            firstname={author.firstname}
            lastname={author.lastname}
            email={author.email}
          />

          <div className='author'>
            {t(
              `reveal.timeline.activityLog.messages.${timelineItem.action.subtype}`,
              { recruiterName, profileName },
            )}
            <div className='date'>
              {+timelineItem.date
                ? t('common.formattedDate', {
                    date: new Date(+timelineItem.date),
                  })
                : t('common.defaultDuration')}
            </div>
          </div>
          <div className='buttons-container'>
            <button
              className='modify-button'
              type='button'
              onClick={() => setEditMode(true)}
            >
              {t('profile.contact.timeline.edit')}
            </button>
            <button className='trash-button' onClick={onRemove} type='button'>
              <img className='trash-svg' src='/images/icons/trash.svg' alt='' />
            </button>
          </div>
        </div>
        <div
          className={classnames('card-content comment', {
            'edit-mode': editMode,
          })}
        >
          {!editMode ? (
            !_.isEmpty(content) && <div className='body'>{contentTag}</div>
          ) : (
            <EditComment
              initialValue={comment}
              profile={profile}
              clientId={clientId}
              onSubmit={(note: string) =>
                onEdit({ subtype: timelineItem.action.subtype, note })
              }
              t={t}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const RevealLogActivityTimelineItem: FC<LogActivityTimelineItemProps> = ({
  timelineItem,
  profile,
  unbiasedModeEnabled,
  onRemove,
  onEdit,
  clientId,
  editMode,
  setEditMode,
}) => {
  const { t } = useTranslation();
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [removeSharedActivity] = useRemoveSharedActivity();
  const { clientCustomActivities } = useClientCustomActivities({ clientId });

  const comment = timelineItem.action.comment?.text;
  const content = useSanitizedContent(comment);
  const contentTag = parseHtml(content || '');

  const recruiterName = `${timelineItem?.author?.firstname || ''} ${
    timelineItem?.author?.lastname || ''
  }`.trim();
  const mainTarget = _.findWhere(timelineItem.targets || [], { isMain: true });
  const profileName =
    mainTarget?.name ||
    getProfileName(profile?.resumeData, {
      unbiasedModeEnabled,
    });

  const matchingCustomActivity = clientCustomActivities.find(
    (activity) => activity.id === timelineItem.action.customActivityId,
  );

  const answerLabel = timelineItem?.action?.labels?.[0]?.id;
  const subtype: string = timelineItem?.action?.subtype;
  const iconColor = subtype ? TASK_ICONS[subtype]?.color : TASK_ICONS.log.color;
  const icon = subtype ? TASK_ICONS[subtype]?.icon : TASK_ICONS.log.icon;
  const { format } = timelineItem?.action || {};

  const filteredTargets = _.filter(
    timelineItem.targets || [],
    ({ targetId }) => targetId !== profile.id,
  );

  const removeComment = () => {
    setWarningModalOpen(true);
  };

  const { pinSharedActivity, unpinSharedActivity } =
    usePinAndUnpinSharedActivity();
  const sharedActivityId = timelineItem.id;

  const confirmDeletion = () => {
    if (!_.isEmpty(timelineItem.targets) && timelineItem.id) {
      removeSharedActivity({
        variables: {
          searchPoolId: 'reveal',
          sharedActivityId: timelineItem.id,
        },
      }).then(() => {
        setWarningModalOpen(false);
        publishSubscriptionEvent('onProfileTimelineUpdate', { id: profile.id });
      });
    } else {
      onRemove();
    }
  };

  return (
    <div className='timeline-card-mail-wrapper'>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            {!matchingCustomActivity ? (
              <div style={{ backgroundColor: iconColor }} className='icon'>
                <i className={`${icon} ri-2x`} />
              </div>
            ) : matchingCustomActivity?.icon?.emojiText ? (
              <div className='icon'>
                <span className='custom-task-icon-text'>
                  {matchingCustomActivity?.icon?.emojiText}
                </span>
              </div>
            ) : (
              <div className='icon'>
                <img
                  className='custom-task-icon'
                  src={matchingCustomActivity?.icon?.url}
                  alt=''
                />
              </div>
            )}
          </div>
          <div className='author'>
            {!timelineItem.action.customActivityTitle ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: `${t(
                    `reveal.timeline.activityLog.messages.${timelineItem.action.subtype}`,
                    {
                      recruiterName,
                      profileName,
                    },
                  )} ${
                    answerLabel
                      ? t(
                          `reveal.timeline.activityLog.answerLabel.${answerLabel}`,
                        )
                      : ''
                  }`,
                }}
              />
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: `${t(
                    `reveal.timeline.activityLog.messages.custom-${timelineItem.action.subtype}`,
                    {
                      recruiterName,
                      profileName,
                      customActivityTitle: getTranslatedText(
                        timelineItem.action.customActivityTitle,
                      ),
                    },
                  )} ${
                    answerLabel
                      ? t(
                          `reveal.timeline.activityLog.answerLabel.${answerLabel}`,
                        )
                      : ''
                  }`,
                }}
              />
            )}

            {timelineItem.isPinned && (
              <i className='ri-pushpin-line' style={{ marginLeft: 4 }} />
            )}

            {!_.isEmpty(filteredTargets) && (
              <TargetsSummary targets={filteredTargets} />
            )}

            <AuthorAndDate timelineItem={timelineItem}>
              <IntegrationsSynchronizationStatus
                timelineItem={timelineItem}
                clientId={clientId}
                profile={profile}
              />
            </AuthorAndDate>
          </div>
          <div className='timeline-item-actions'>
            <TimelineItemActions
              profile={profile}
              clientId={clientId}
              onEdit={setEditMode}
              onRemove={removeComment}
              onPin={
                sharedActivityId
                  ? () => {
                      pinSharedActivity({
                        variables: {
                          input: {
                            profileId: profile.id,
                            sharedActivityId,
                          },
                        },
                      });
                    }
                  : undefined
              }
              onUnpin={
                sharedActivityId
                  ? () => {
                      unpinSharedActivity({
                        variables: {
                          input: {
                            profileId: profile.id,
                            sharedActivityId,
                          },
                        },
                      });
                    }
                  : undefined
              }
              timelineItem={timelineItem}
            />
          </div>
        </div>
        {!editMode ? (
          !(_.isEmpty(getHtmlStringAsText(content ?? '')?.trim()) && _.isEmpty(format)) && (
            <div
              className={classnames('card-content comment', {
                'edit-mode': editMode,
              })}
            >
              {format && (
                <MetaTaskForm
                  format={format}
                  readMode
                  disabled
                  onUpdate={({ newForm }) => {
                    return { form: newForm };
                  }}
                />
              )}
              <div className='body'>{contentTag}</div>
            </div>
          )
        ) : (
          <div
            className={classnames('card-content comment', {
              'edit-mode': editMode,
            })}
          >
            {!timelineItem?.id ? (
              <EditComment
                initialValue={comment || ''}
                profile={profile}
                clientId={clientId}
                onSubmit={(note: string) =>
                  onEdit({ subtype: timelineItem.action.subtype, note })
                }
                t={t}
              />
            ) : (
              <EditSharedNote
                profileId={profile.id}
                sharedActivityId={timelineItem.id}
                format={format}
                targets={timelineItem.targets || []}
                text={comment}
                subtype={timelineItem?.action?.subtype}
                customActivityId={timelineItem?.action?.customActivityId}
                date={new Date(+(timelineItem?.date || Date.now()))}
                onSubmit={() => setEditMode(false)}
              />
            )}
          </div>
        )}
      </div>
      <GenericModal size='tiny' open={warningModalOpen}>
        <Modal.Header>
          {t('profile.contact.timeline.comment.warningModalTitle')}
        </Modal.Header>
        <Modal.Content>
          {t('profile.contact.timeline.comment.warningModalContent')}
        </Modal.Content>
        <Modal.Actions>
          <GenericButton
            size='big'
            primacy='secondary'
            onClick={() => setWarningModalOpen(false)}
          >
            {t('common.cancel')}
          </GenericButton>
          <GenericButton size='big' primacy='primary' onClick={confirmDeletion}>
            {t('common.delete')}
          </GenericButton>
        </Modal.Actions>
      </GenericModal>
    </div>
  );
};

const LogActivityTimelineItem: FC<
  LogActivityTimelineItemProps & {
    applicationMode?: string;
  }
> = ({
  timelineItem,
  profile,
  unbiasedModeEnabled,
  onRemove,
  onEdit,
  clientId,
  editMode,
  setEditMode,
  author,
  applicationMode,
}) => {

  if (applicationMode === 'classic') {
    return (
      <ClassicLogActivityTimelineItem
        unbiasedModeEnabled={unbiasedModeEnabled}
        clientId={clientId}
        editMode={editMode}
        setEditMode={setEditMode}
        profile={profile}
        onEdit={onEdit}
        onRemove={onRemove}
        timelineItem={timelineItem}
        author={author}
      />
    );
  }
  return (
    <RevealLogActivityTimelineItem
      unbiasedModeEnabled={unbiasedModeEnabled}
      clientId={clientId}
      editMode={editMode}
      setEditMode={setEditMode}
      profile={profile}
      onEdit={onEdit}
      onRemove={onRemove}
      timelineItem={timelineItem}
      author={author}
    />
  );
};

export default LogActivityTimelineItem;
