import React from 'react';
import classNames from 'classnames';

import styles from './ActionButton.module.less';

const ActionButton = (
  props: React.ComponentProps<'button'> & { icon: string },
) => {
  const { className, icon, children } = props;
  return (
    <button
      type='button'
      {...props}
      className={classNames(styles.actionButton, className)}
    >
      {children}
      <i className={`ri-${icon}`} />
    </button>
  );
};

export default ActionButton;
