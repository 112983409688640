import gql from 'graphql-tag';
import SearchPoolJob from './fragments/SearchPoolJob';
import Criteria from './fragments/Criteria';
import CustomFieldsValues from './fragments/CustomFieldsValues';

const SEARCH_POOL_JOB = gql`
  query getSearchPoolJob(
    $searchPoolId: ID!
    $jobId: ID!
    $addedSince: String!
  ) {
    searchPool(id: $searchPoolId) {
      id
      job(id: $jobId) {
        ...SearchPoolJob
        criteria {
          ...Criteria
        }
        customFields {
          ...CustomFieldsValues
        }
        stats {
          applicants
          recommendations
          importedProfiles

          dailyAddedProfiles(addedSince: $addedSince) {
            day
            nbAdded
          }
        }
      }
    }
  }
  ${SearchPoolJob}
  ${Criteria}
  ${CustomFieldsValues}
`;

export const UPDATE_SEARCH_POOL_JOB = gql`
  mutation updateSearchPoolJob(
    $searchPoolId: ID!
    $input: UpdateSearchPoolJobInput!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      update(input: $input) {
        job {
          ...SearchPoolJob
          criteria {
            ...Criteria
          }
          customFields {
            ...CustomFieldsValues
          }
        }
      }
    }
  }
  ${SearchPoolJob}
  ${Criteria}
  ${CustomFieldsValues}
`;

export const ADD_JOB_DEFAULT_SEQUENCES = gql`
  mutation jobAddDefaultSequence(
    $jobId: ID!
    $sequenceId: ID!
    $isDefault: Boolean!
    $searchPoolId: ID!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      addAttachedSequence(
        jobId: $jobId
        sequenceId: $sequenceId
        isDefault: $isDefault
      ) {
        id
        attachedSequences {
          sequenceId
          isDefault
          manuallyAdded
        }
      }
    }
  }
`;

export const DISABLED_JOB_DEFAULT_SEQUENCES = gql`
  mutation jobDisableDefaultSequence(
    $jobId: ID!
    $sequenceId: ID!
    $searchPoolId: ID!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      disableAttachedSequence(jobId: $jobId, sequenceId: $sequenceId) {
        id
        attachedSequences {
          sequenceId
          isDefault
          manuallyAdded
        }
      }
    }
  }
`;

export const GET_JOB_DEFAULT_SEQUENCES = gql`
  query getJobDefaultSequences($searchPoolId: ID!, $jobId: ID!) {
    searchPool(id: $searchPoolId) {
      id
      job(id: $jobId) {
        id
        attachedSequences {
          sequenceId
          isDefault
          manuallyAdded
        }
      }
    }
  }
`;

export const ARCHIVE_SEARCH_POOL_JOB = gql`
  mutation archiveSearchPoolJob(
    $searchPoolId: ID!
    $input: ArchiveSearchPoolJobInput!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      archive(input: $input) {
        job {
          id
          isArchived
        }
      }
    }
  }
`;

export const UNARCHIVE_SEARCH_POOL_JOB = gql`
  mutation unarchiveSearchPoolJob(
    $searchPoolId: ID!
    $input: UnarchiveSearchPoolJobInput!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      unarchive(input: $input) {
        job {
          id
          isArchived
        }
      }
    }
  }
`;

export const UPDATE_SEARCH_POOL_JOB_CRITERIA = gql`
  mutation updateSearchPoolJobCriteria(
    $searchPoolId: ID!
    $input: UpdateSearchPoolJobCriteriaInput!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      updateCriteria(input: $input) {
        job {
          id
          criteria {
            ...Criteria
          }
          stats {
            relevantProfiles
          }
        }
      }
    }
  }
  ${Criteria}
`;

export const GET_SEARCH_POOL_JOB_RELEVANT_PROFILES_COUNT = gql`
  query getSearchPoolJobRelevantProfilesCount(
    $searchPoolId: ID!
    $jobId: ID!
    $criteria: CriteriaInput
  ) {
    searchPool(id: $searchPoolId) {
      id
      job(id: $jobId) {
        id
        stats {
          relevantProfiles(criteria: $criteria)
        }
      }
    }
  }
`;

export default SEARCH_POOL_JOB;
