import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { DELETE_TASK_FULL } from '@/graphql/tasks';
import ExplicitTaskEditor from '@/revealComponents/ExplicitTasks/ExplicitTaskEditor';
import ExplicitTaskDue from '@/revealComponents/ExplicitTasks/ExplicitTaskDue';
import ExplicitTask from '@/revealComponents/ExplicitTasks/ExplicitTask';
import './TaskLink.css';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';

const TaskLink = ({
  clientId,
  profileId,
  sequence,
  task,
  onSnooze = null,
  onDelete = null,
}) => {
  const [deleteTasks] = useMutation(DELETE_TASK_FULL);
  const notifications = useNotificationSystem();
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();
  const { dueDate } = task;
  const isTaskDue = !dueDate || dueDate <= new Date().toISOString();
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const { isMinimized, maximize } = useMinimizedView();

  const innerOnDelete = async (event) => {
    event.preventDefault();
    try {
      const variables = {
        input: {
          tasks: [
            {
              id: task.id,
              type: task.type,
              targetId: task.target?.id,
            },
          ],
        },
        explicitTasksFilter: { withDueLater: true },
      };
      await deleteTasks({ variables });
      notifications.success(
        t('reveal.candidatesView.timeline.explicitTasks.deleteSuccess'),
      );
      publishSubscriptionEvent('onExplicitTasksUpdated', { id: profileId });

      if (onDelete) {
        onDelete();
      }
    } catch (e) {
      notifications.success(
        t('reveal.candidatesView.timeline.explicitTasks.deleteError'),
      );
    }
  };

  const onEditTask = useCallback(() => {
    maximize();
    setEditMode(true);
  }, [maximize]);

  if (editMode) {
    return (
      <Segment className='task-link task-link-full'>
        <ExplicitTaskEditor
          task={task}
          clientId={clientId}
          profileId={profileId}
          onClose={() => setEditMode(false)}
        />
      </Segment>
    );
  }

  if (isTaskDue) {
    return (
      <ExplicitTaskDue
        task={task}
        onEdit={onEditTask}
        onDelete={innerOnDelete}
        onSnooze={onSnooze}
      />
    );
  }

  if (!isMinimized) {
    return (
      <ExplicitTask
        sequence={sequence}
        task={task}
        onEdit={onEditTask}
        onDelete={onDelete}
      />
    );
  }

  return null;
};

export default TaskLink;
