import React from 'react';
import {
  GET_GENERIC_SEGMENTATION_COUNTS,
  GET_GENERIC_SEGMENTATION_PROFILES,
} from '@/hocs/searchPool/withRevealSearchPoolResults';
import DataLoaderSegmentProfilesContextProvider from '../MissionProfilesGenericView/DataLoaderSegmentProfilesContext';

interface ProfileKanbanContainerProps {
  queryOptions: any;
}

const ProfileKanbanContainer: React.FC<ProfileKanbanContainerProps> = ({
  queryOptions,
  children,
}) => {
  return (
    <DataLoaderSegmentProfilesContextProvider
      query={GET_GENERIC_SEGMENTATION_PROFILES}
      queryCount={GET_GENERIC_SEGMENTATION_COUNTS}
      queryOptions={queryOptions}
    >
      {children}
    </DataLoaderSegmentProfilesContextProvider>
  );
};

export default ProfileKanbanContainer;
