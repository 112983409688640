import React from 'react';
import { useTranslation } from 'react-i18next';
import { ATS_LOGOS_BY_TYPE } from '@/common/reveal';
import { getTranslatedText } from '../../../../../common';
import Thumbnail from '../../../../../components/Thumbnail';
import { getActionTitle } from '../../../../../common/constants/taskTypes';
import { getTranslatedDateOrDuration } from '../../ProfileContactFlow/helpers';
import AuthorAndDate from '../AuthorAndDate';

// import './DefaultPendingAction.css';

const TASK_ICONS = {
  'send-email-action': {
    text: 'email-send-to',
    icon: 'ri-mail-line',
    color: '#B26FD2',
  },
  'send-email-manual': {
    text: 'email-send-to',
    icon: 'ri-mail-line',
    color: '#B26FD2',
  },
  manual: '',
  'linkedin-send-inmail': {
    text: 'linkedin-message-sent-to',
    icon: 'ri-linkedin-fill',
    color: '#4680D7',
  },
  'linkedin-send-message': {
    text: 'linkedin-message-sent-to',
    icon: 'ri-linkedin-fill',
    color: '#4680D7',
  },
  'linkedin-send-request': {
    text: 'linkedin-invite-sent',
    icon: 'ri-linkedin-fill',
    color: '#4680D7',
  },
  'linkedin-check-request': '',
  'send-text-message': '',
  'make-phone-call': {
    text: 'call-to',
    icon: 'ri-phone-fill',
    color: '#5A5270',
  },
};

const ClassicDefaultActionItem = ({ action, sequenceOwner }) => {
  const { t } = useTranslation();
  const { plannedExecutionDate, trigger, completion, owner } = action || {};
  const descriptionText = getTranslatedText(action?.description);

  let dateText;
  if (completion?.isCompleted) {
    let date;
    try {
      date = new Date(completion?.completionDate);
      dateText = `Completed on ${t('common.formattedDate', { date })}`;
    } catch (e) {
      dateText = 'Completed';
    }
  } else {
    dateText =
      trigger?.type === 'manual-trigger'
        ? 'Manual action'
        : getTranslatedDateOrDuration({ plannedExecutionDate, trigger, t });
  }

  const thumbnailUser = completion?.author || owner || sequenceOwner;
  return (
    <div className='default-pending-action timeline-card-mail-wrapper classic-item'>
      <div className='content'>
        <div className='header'>
          <Thumbnail
            firstname={thumbnailUser?.firstname}
            lastname={thumbnailUser?.lastname}
            email={thumbnailUser?.email}
          />
          <div className='author'>
            <div className='date'>{dateText}</div>
            <div>{getActionTitle({ action })}</div>
          </div>
        </div>
        {descriptionText && (
          <div className='card-content' style={{ padding: 16 }}>
            {descriptionText}
          </div>
        )}
      </div>
    </div>
  );
};

export const RevealDefaultActionItem = ({ action, profile, item }) => {
  const { t } = useTranslation();
  const atsType = item?.ats;
  const atsLogo = ATS_LOGOS_BY_TYPE[atsType];
  const descriptionText =
    getTranslatedText(action?.description) || action?.description;
  const getTaskTitle = (name, action) => {
    return (
      t(
        `reveal.timeline.activityLog.messages.${TASK_ICONS[action.type]?.text}`,
        { profileName: name },
      ) || getActionTitle({ action })
    );
  };
  const profileName = `${profile?.resumeData?.firstname || ''} ${profile
    ?.resumeData?.lastname || ''}`;
  return (
    <div className='default-pending-action timeline-card-mail-wrapper'>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{ backgroundColor: TASK_ICONS[action.type].color }}
              className='icon'
            >
              <i className={`${TASK_ICONS[action.type].icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            <div
              dangerouslySetInnerHTML={{
                __html: getTaskTitle(profileName, action),
              }}
            />
            <AuthorAndDate timelineItem={item} atsLogo={atsLogo} />
          </div>
        </div>
        {descriptionText && (
          <div className='card-content' style={{ padding: 16 }}>
            {descriptionText}
          </div>
        )}
      </div>
    </div>
  );
};

const DefaultActionItem = ({ action, profile, item, applicationMode }) => {
  const author = item.author ? item.author : {};
  const actionWithDate = {
    ...action,
    ...(item?.date && {
      plannedExecutionDate: new Date(+item.date).toISOString(),
    }),
  };
  if (applicationMode === 'classic') {
    return (
      <ClassicDefaultActionItem
        action={actionWithDate}
        profile={profile}
        author={author}
      />
    );
  }
  return (
    <RevealDefaultActionItem
      profile={profile}
      action={actionWithDate}
      item={item}
      author={author}
    />
  );
};

export default DefaultActionItem;
