import { useQuery } from '@apollo/client';
import REVEAL_CLIENT_ACTIVITY, {
  RevealClientActivityResults,
  RevealClientActivityVariables,
} from '../../revealClientActivity';

interface Arg {
  clientId: string;
  missionId?: string;
  onlyCurrentUser?: boolean;
}

const useRevealClientActivity = ({
  clientId,
  missionId,
  onlyCurrentUser,
}: Arg) => {
  return useQuery<RevealClientActivityResults, RevealClientActivityVariables>(
    REVEAL_CLIENT_ACTIVITY,
    {
      variables: {
        clientId,
        scope: 'reveal',
        missionId,
        onlyCurrentUser,
      },
      fetchPolicy: 'network-only',
    },
  );
};

export default useRevealClientActivity;
