import React, { Dispatch, SetStateAction } from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import ContextMenu from '@/components/Tables/ContextMenu/ContextMenu';
import { LinkedInAccount } from '@/types/LinkedInAccount';
import { User } from '@/types/user';
import LinkedinAccountStateTooltip from './LinkedinAccountStateTooltip';
import styles from './LinkedinAccountsTable.module.less';

// const LINKEDIN_ACCOUNT_TYPE_FROM_ID: Record<string, string> = {
//   'linkedin-recruiter': 'LinkedIn Recruiter',
//   'linkedin-classic': 'LinkedIn (Classic)',
// };

interface LinkedinAccountsTableRowProps {
  linkedinAccount: LinkedInAccount;
  withEdit: boolean;
  user: User;
  onEditLinkedinAccount: ({
    linkedinAccountId,
  }: {
    linkedinAccountId: string;
  }) => void;
  onDeleteLinkedinAccount: ({
    linkedinAccountId,
  }: {
    linkedinAccountId: string;
  }) => void;
  onShowConnectModal: Dispatch<SetStateAction<boolean>>;
  setAddressToConnect: Dispatch<SetStateAction<string>>;
}

export const LinkedinAccountsTableRow: React.FC<LinkedinAccountsTableRowProps> = ({
  linkedinAccount,
  withEdit,
  user,
  onEditLinkedinAccount,
  onDeleteLinkedinAccount,
  onShowConnectModal,
  setAddressToConnect,
}) => {
  const { t } = useTranslation();
  const { id, name, type, notifiedEmail, synchronizations, owner } =
    linkedinAccount || {};

  const onEdit = () => {
    onEditLinkedinAccount({ linkedinAccountId: id });
  };

  const onDelete = () => {
    onDeleteLinkedinAccount({ linkedinAccountId: id });
  };

  return (
    <Table.Row>
      <Table.Cell className={styles.tableCell}>{`${owner.firstname ||
        ''} ${owner.lastname || ''}`}</Table.Cell>
      <Table.Cell className={classnames(styles.tableCell, styles.noWrap)}>
        {notifiedEmail}
        <LinkedinAccountStateTooltip
          state={linkedinAccount.state}
          owner={owner}
          user={user}
          synchronizations={linkedinAccount.synchronizations}
          onShowConnectModal={onShowConnectModal}
          setAddressToConnect={() => setAddressToConnect(notifiedEmail)}
        />
      </Table.Cell>
      <Table.Cell className={styles.tableCell}>{name}</Table.Cell>
      <Table.Cell className={styles.tableCell}>
        {t(`settings.linkedinAccounts.${type}`)}
      </Table.Cell>
      <Table.Cell className={classnames(styles.tableCell, styles.flex)}>
        {synchronizations.inMailReceived.enabled && (
          <div>
            <span className={styles.synchronizationsStatus}>
              {t('settings.linkedinAccounts.acceptInMail')}
            </span>
          </div>
        )}
        {synchronizations.invitationAccepted.enabled && (
          <div>
            <span className={styles.synchronizationsStatus}>
              {t('settings.linkedinAccounts.acceptInvitations')}
            </span>
          </div>
        )}
      </Table.Cell>
      {withEdit && (
        <Table.Cell className={styles.actionTableCell} collapsing>
          <ContextMenu
            actions={[
              {
                icon: 'ri-edit-line',
                label: t('settings.senders.editButton'),
                onClick: () => onEdit(),
              },
              {
                icon: 'ri-delete-bin-line',
                label: t('settings.senders.deleteButton'),
                onClick: () => onDelete(),
              },
            ]}
          />
        </Table.Cell>
      )}
    </Table.Row>
  );
};

export default LinkedinAccountsTableRow;
