import React from 'react';
import _ from 'underscore';
import { Dropdown } from 'semantic-ui-react';
import { Ellipsis } from '../../assets/icons';

import './EllipsisMenu.css';

const EllipsisMenu = ({ items, direction = 'auto' }) => {
  const trigger = (
    <div className='ellipsis-menu'>
      <Ellipsis className='ellipsis-icon' />
    </div>
  );
  return (
    <Dropdown className='ellipsis-menu-dropdown' trigger={trigger} icon={null}>
      <Dropdown.Menu direction={direction}>
        {_.map(items, ({ onClick, title }, index) => (
          <Dropdown.Item key={`item-${index}-${title}`} onClick={onClick}>
            {title}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default EllipsisMenu;
