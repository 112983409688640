import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import PipeStepStat from '@/graphql/fragments/PipeStepStat';

export const mutation = gql`
  mutation archiveOffer($id: ID!, $removeFollowups: Boolean) {
    archiveOffer(id: $id, removeFollowups: $removeFollowups) {
      id
      isArchived
      isHold
      pipeStepStats {
        ...PipeStepStat
      }
      profiles(step: "pending") {
        id
      }
    }
  }
  ${PipeStepStat}
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    archiveOffer: ({ id, removeFollowups, onCompleted }) =>
      mutate({
        variables: { id, removeFollowups: !!removeFollowups },
        onCompleted,
      }),
  }),
});
