import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { GET_USER_SENDERS } from '@/graphql/users';
import Sender from '../../graphql/fragments/Sender';

export const mutation = gql`
  mutation deleteSender($senderId: ID!) {
    deleteSender(senderId: $senderId) {
      ...Sender
    }
  }
  ${Sender}
`;

export default graphql(mutation, {
  props: ({ mutate }) => {
    return {
      deleteSender: ({ senderId }) =>
        mutate({
          variables: { senderId },
          refetchQueries: [
            {
              query: GET_USER_SENDERS,
              variables: { owned: true, granted: true },
            },
          ],
        }),
    };
  },
});
