import gql from 'graphql-tag';
import SubSnippet from '@/graphql/fragments/SubSnippet';

export default gql`
  fragment Snippet on Snippet {
    id
    type
    name
    idInParentDynamicVariable

    ... on SnippetPath {
      state {
        value
        isLocked
      }
      fallbackValue {
        type
        text
      }
      path
    }
    ... on SnippetVariable {
      state {
        value
        isLocked
      }
      fallbackValue {
        type
        text
      }
      variableId
    }
    ... on SnippetCurrentCompany {
      state {
        value
        isLocked
      }
      fallbackValue {
        type
        text
      }
    }
    ... on SnippetLastCompany {
      state {
        value
        isLocked
      }
      fallbackValue {
        type
        text
      }
    }
    ... on SnippetCurrentJobTitle {
      state {
        value
        isLocked
      }
      fallbackValue {
        type
        text
      }
    }
    ... on SnippetCustomField {
      state {
        value
        isLocked
      }
      fallbackValue {
        type
        text
      }
      clientProfileCustomFieldId
    }
    ... on SnippetMissionCustomField {
      state {
        value
        isLocked
      }
      fallbackValue {
        type
        text
      }
      clientMissionCustomFieldId
    }
    ... on SnippetSyncedFragment {
      state {
        value
        isLocked
      }
      fallbackValue {
        type
        text
      }
      clientDynamicVariableId
    }
    ... on SnippetConcat {
      state {
        value
        isLocked
      }
      fallbackValue {
        type
        text
      }
      children {
        id
        type
        name
      }
    }
    ... on SnippetPlaceholder {
      state {
        value
        isLocked
      }
      fallbackValue {
        type
        text
      }
    }
    ... on SnippetFragment {
      text
      snippets {
        ...SubSnippet
      }
      author {
        firstname
        lastname
        email
      }
    }
    ... on SnippetSelect {
      options {
        id
        title {
          default
        }
      }
      isMultiselect
      state {
        value
        isLocked
      }
      fallbackValue {
        type
        text
      }
      author {
        firstname
        lastname
        email
      }
    }
    ... on SnippetDurationSinceGraduation {
      state {
        value
        isLocked
      }
      fallbackValue {
        type
        text
      }
      format
      locale
    }
    ... on SnippetCurrentExperienceDuration {
      state {
        value
        isLocked
      }
      fallbackValue {
        type
        text
      }
      format
      locale
    }
    ... on SnippetKLastCompanies {
      state {
        value
        isLocked
      }
      fallbackValue {
        type
        text
      }
      number
      locale
    }
    ... on SnippetConditionsChaining {
      state {
        value
        isLocked
      }
      fallbackValue {
        type
        text
      }
      subtype
      switchField {
        fieldId
      }
      ifStatements {
        id
        condition {
          id
          type
          fieldCategory
          fieldId
          customFieldId
          fieldType
          accept {
            id
            type
            targetText
            targetTexts
          }
          conditions {
            id
            type
            fieldCategory
            fieldId
            customFieldId
            fieldType
            accept {
              id
              type
              targetText
              targetTexts
            }
          }
        }
        result {
          id
          name
          type
          text
          snippets {
            ...SubSnippet
          }
        }
      }
      elseStatement {
        id
        name
        text
        type
        snippets {
          ...SubSnippet
        }
      }
    }
    ... on SnippetAIToken {
      text
      state {
        value
        isLocked
        options {
          value
        }
      }
      snippets {
        ...SubSnippet
      }
      author {
        firstname
        lastname
        email
      }
    }
  }
  ${SubSnippet}
`;
