import _ from 'underscore';
import { getTranslatedText } from '../../../../common';

const categories = [
  'jobPosition',
  'experience',
  'management',
  'location',
  'salary',
  'looking',
  'availability',
  'contract',
  'language',
];

const tagCategoryToIcon = {
  jobPosition: 'ri-briefcase-4-line',
  experience: 'ri-time-line',
  management: 'ri-user-2-line',
  location: 'ri-map-pin-line',
  salary: 'ri-wallet-line',
  looking: 'ri-search-line',
  availability: 'ri-calendar-check-line',
  contract: 'ri-article-line',
  language: 'ri-flag-line',
};

const defaultCategoryIcon = 'ri-focus-line';

export const tagIconFromCategory = (category) => tagCategoryToIcon[category] || defaultCategoryIcon;

// TODO: improve
const isSkillTag = (tag) => {
  const regex = new RegExp('.*echnolog.*');
  return !!regex.test(getTranslatedText(tag?.label));
};

export const getTags = ({ smartTags, neutralMode }) => {
  const tags = {
    unCategorized: [],
  };
  _.each(smartTags.groups, ({ category: type, keyPoints = [] }) => {
    // neutral tags are redundant with the other ones
    if (neutralMode && type !== 'neutral') {
      return;
    }
    if (!neutralMode && type === 'neutral') {
      return;
    }
    _.each(keyPoints, (relevantTag) => {
      if (isSkillTag(relevantTag)) {
        return;
      }
      const tag = { ...relevantTag, type, icon: tagIconFromCategory(relevantTag.category) };
      if (categories.includes(tag.category)) {
        tags[tag.category] = tag;
      } else {
        tags.unCategorized.push(tag);
      }
    });
  });
  return tags;
};
