import classNames from 'classnames';
import React from 'react';
import _, { compose } from 'underscore';
import { Dropdown } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { textEllipsis } from '../../../common';

import withActionLogger from '../../../hocs/withActionLogger';

import NewOfferButton from '../../../components/NewOfferButton';

import './OfferSelector.css';

const MAX_N_CHARACTERS = 30;

const OfferSelector = React.memo(
  ({ history, client, offerId, offers = [], t, targetPage, onLogAction }) => {
    const currentOffer = _.findWhere(offers, { id: offerId });
    const currentOfferTitle = currentOffer?.title || '';
    return (
      <div className='offer-selector'>
        {offerId && (
          <div className='selector-button with-client'>
            <div className='client-name-and-carret'>
              <GenericDropdown
                history={history}
                client={client}
                t={t}
                offerId={offerId}
                offers={_.reject(offers, (offer) => offer?.isUnclassified)}
                targetPage={targetPage}
                onLogAction={onLogAction}
                currentOfferTitle={currentOfferTitle}
              />
            </div>
          </div>
        )}
      </div>
    );
  },
);

const GenericDropdown = React.memo(
  ({
    history,
    client,
    offerId,
    offers = [],
    currentOfferTitle,
    t,
    targetPage,
    onLogAction,
  }) => {
    const clientId = (client || {}).id;
    const displayedOffers = _.filter(
      offers,
      ({ isArchived, isSuggestedForCreation, isUnClassified }) =>
        !(isArchived || isSuggestedForCreation || isUnClassified),
    );

    if (_.isEmpty(offers)) {
      return null;
    }

    const onClickAllOffers = () => {
      if (client) {
        history.push(`/client/${client.id}/jobs`);
        onLogAction({
          type: 'click-see-all-offers-link',
          info: {
            clientId,
            from: 'offer-selector',
          },
        });
      }
    };

    const onClickItem = ({ targetOffer }) => {
      if (client) {
        history.push(
          `/client/${client.id}/jobs/${targetOffer.id}/${targetPage ||
            'profiles'}`,
        );
        onLogAction({
          type: 'click-offer-link',
          info: {
            clientId,
            offerId: targetOffer.id,
            from: 'offer-selector',
          },
        });
      }
    };

    return (
      <div className='generic-dropdown'>
        <Dropdown
          className='hs-large-dropdown'
          trigger={
            <div className='headline-3 dropdown-trigger'>
              {currentOfferTitle}
            </div>
          }
          icon={<i className='ri-arrow-drop-down-fill' />}
        >
          <Dropdown.Menu className='offer-selector-menu'>
            {_.map(displayedOffers, (targetOffer, index) => (
              <Dropdown.Item
                key={index}
                className={classNames(
                  'large-item',
                  offerId && targetOffer.id === offerId && 'current',
                )}
                onClick={() => onClickItem({ targetOffer })}
              >
                <span>{textEllipsis(targetOffer.title, MAX_N_CHARACTERS)}</span>
              </Dropdown.Item>
            ))}
            <Dropdown.Divider />
            <Dropdown.Item className='large-item' onClick={onClickAllOffers}>
              {t('header.offerSelector.allOffers')}
            </Dropdown.Item>
            <Dropdown.Item className='large-item item-new-job'>
              <NewOfferButton clientId={clientId} from='offer-selector' />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  },
);

export default compose(
  withRouter,
  withActionLogger,
  withTranslation('translations'),
)(OfferSelector);
