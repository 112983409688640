import React, { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { TimelineItem, TextMessageAction } from '@/types/contactData';
import { getFullname } from '@/common/helpers/person';
import { Profile } from '@/types/profile';

import TASK_ICONS from '../TaskIcons';
import AuthorAndDate from '../AuthorAndDate';

import styles from './TextMessageItem.module.less';

type TextMessageItemProps = {
  profile: Profile;
  item: TimelineItem<TextMessageAction>;
};

const TextMessageItem: FC<TextMessageItemProps> = ({ item, profile }) => {
  const { t } = useTranslation();
  const { action } = item;

  const profileName = getFullname(profile.resumeData);

  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{ backgroundColor: TASK_ICONS['send-text-message'].color }}
              className='icon'
            >
              <i className={`${TASK_ICONS['send-text-message'].icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            <div>
              <div style={{ display: 'inline-block' }}>
                <Trans
                  i18nKey='profile.contact.timeline.text-message-from-profile.description'
                  values={{
                    profileName,
                  }}
                  components={{ b: <b /> }}
                />
              </div>
            </div>
            <AuthorAndDate timelineItem={item}  />
          </div>
        </div>
        <div className='card-content'>
          <div className='row'>
            <div className='title'>
              {action.from
                ? `${t('profile.contact.timeline.from')} ${action.from} `
                : ''}
              {action.to
                ? `${t('profile.contact.timeline.to')} ${action.to}`
                : ''}
            </div>
          </div>
          <div className='row'>
            <div className={styles.smsText}>{action.text}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextMessageItem;
