import React, { useState } from 'react';

import { Image, Popup } from 'semantic-ui-react';

import { useTranslation } from 'react-i18next';
import { getRandomDefaultAvatarLink, getSecureLink } from '@/common';
import ProfileSources from '@/containers/Profile/ProfileSources';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import DoNotContactPill from '@/components/Reveal/Profile/privacy/DoNotContactPill/DoNotContactPill';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import ProfileViews from '@/components/Profile/ProfileViews';
import { Sources } from '@/types/sources';
import { ProfileHeaderCustomFields } from '@/containers/Profile/Resume/Card/RevealProfileHeader/RevealProfileHeader';
import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import _ from 'underscore';
import styles from './PluginProfileHeader.module.less';

interface PluginProfileHeaderProps {
  toggleEditMode: (value: boolean) => void;
  clientId: string;
  watchProfile: any;
}

function hasValue(target: any) {
  // eslint-disable-next-line no-restricted-syntax
  for (const member in target) {
    if (target[member] !== null) return true;
  }
  return false;
}

const PluginProfileHeader: React.FC<PluginProfileHeaderProps> = ({
  toggleEditMode,
  clientId,
  watchProfile,
}) => {
  const { isMinimized, maximize } = useMinimizedView();
  const [numberCopiedToClipboard, setNumberCopiedToClipboard] = useState(false);
  const { profile } = useCandidateViewContext();

  const { profileCustomFields: customFields } = useClientProfileCustomFields(
    clientId,
  );

  const endProfile = profile || watchProfile;

  const resumeData = { ...(endProfile?.resumeData || {}) };
  const {
    firstname,
    lastname,
    photoLink,
    sources: profileSources,
    phoneNumber,
    email,
  } = resumeData;
  const { t } = useTranslation();

  const avatarImageUrl =
    photoLink || getRandomDefaultAvatarLink(firstname || `${lastname}`);

  // Done to avoid modifying apollo's cache directly (objects are frozen)
  const sources: Sources | null | undefined = profileSources
    ? { ...profileSources }
    : profileSources;
  if (sources) {
    sources.hiresweet = `${process.env.REACT_APP_SWEETAPP_FRONT_URL}/client/${clientId}/reveal/search?profileId=${endProfile.id}`;
  }

  const fullname =
    (firstname || '') + (firstname && lastname ? ' ' : '') + (lastname || '');

  const headlineStr =
    resumeData.headline?.content?.text ||
    resumeData?.headlineWithEnrichments?.content?.text;

  const copyNumberToClipBoard = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!phoneNumber) {
      return;
    }
    await navigator.clipboard.writeText(phoneNumber);
    setNumberCopiedToClipboard(true);
    setTimeout(() => {
      setNumberCopiedToClipboard(false);
    }, 2000);
  };

  const displaySources = email || phoneNumber || hasValue(sources);

  return (
    <div className={`${styles.root} ${isMinimized ? styles.minimized : ''}`}>
      <div className={styles.headlineContainer}>
        <div className={styles.headlineDatas}>
          <div className={styles.nameContainer}>
            <h3 className={styles.name}>{fullname}</h3>
            <ProfileViews profile={profile} />
            {!isMinimized ? (
              <>
                {endProfile?.privacyState?.markedAsDoNotContact && (
                  <Popup
                    trigger={
                      <div>
                        <DoNotContactPill showLabel={false} />
                      </div>
                    }
                    inverted
                    content={t(
                      'reveal.profileModal.privacy.doNotContact.label',
                    )}
                  />
                )}
                <div className={styles.fieldEditIconContainer}>
                  <div
                    onClick={() => {
                      toggleEditMode(true);
                    }}
                    className={styles.fieldEditIcon}
                  >
                    {t('common.edit')}
                  </div>
                </div>
              </>
            ) : (
              <button
                type='button'
                className={styles.maximizeButton}
                onClick={maximize}
                title='[Maximize]'
              >
                <i className='ri-fullscreen-fill' />
              </button>
            )}
          </div>
          {!isMinimized && (
            <>
              {headlineStr && <p className={styles.headline}>{headlineStr}</p>}
              {displaySources && (
                <div className={styles.emailAndPhone}>
                  {resumeData?.email && (
                    <span className={styles.email}>{resumeData.email}</span>
                  )}
                  <div className={styles.sourcesContainer}>
                    {resumeData?.phoneNumber && (
                      <Popup
                        trigger={
                          <i
                            className={`${styles.phoneIcon} ri-phone-fill`}
                            onClick={copyNumberToClipBoard}
                          />
                        }
                        content={
                          numberCopiedToClipboard ? (
                            <span id='number-popup'>Copied</span>
                          ) : (
                            <span id='number-popup'>
                              {getBeautifiedPhoneNumber({
                                phoneNumber: resumeData.phoneNumber,
                              })}
                            </span>
                          )
                        }
                        inverted
                        position='bottom center'
                        positionFixed
                        popperModifiers={[
                          {
                            // Fix a positioning problem
                            name: 'preventOverflow',
                            options: {
                              boundariesElement: 'offsetParent',
                            },
                          }
                        ]}
                        style={{ borderRadius: '4px' }}
                        on='hover'
                      />
                    )}
                    <ProfileSources
                      sources={sources}
                      onClickSource={() => {}}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {!isMinimized && (
          <div className={styles.headlinePicture}>
            <Image
              data-openreplay-masked
              src={getSecureLink(avatarImageUrl)}
              circular
              onError={(e: any) => {
                e.target.src = getRandomDefaultAvatarLink(
                  firstname || `${lastname}`,
                );
              }}
            />
          </div>
        )}
      </div>
      <ProfileHeaderCustomFields
        customFields={customFields}
        resumeData={resumeData}
      />
    </div>
  );
};

const getBeautifiedPhoneNumber: any = ({ phoneNumber }: any) => {
  if (!phoneNumber) {
    return null;
  }
  if (phoneNumber.indexOf('.') >= 0) {
    return phoneNumber;
  }
  const withoutSpace = phoneNumber.replace(/\s/g, '');

  if (withoutSpace.indexOf('+336') === 0) {
    return `+336 ${getBeautifiedPhoneNumber({
      phoneNumber: withoutSpace.slice(4),
    })}`;
  }
  if (withoutSpace.indexOf('+337') === 0) {
    return `+337 ${getBeautifiedPhoneNumber({
      phoneNumber: withoutSpace.slice(4),
    })}`;
  }

  if (/^[0-9]*$/.test(withoutSpace)) {
    if (withoutSpace.length === 8 || withoutSpace.length === 10) {
      let result = '';
      _.each(withoutSpace.split(''), (c) => {
        if (
          result.length > 1 &&
          result[result.length - 1] !== ' ' &&
          result[result.length - 2] !== ' '
        ) {
          result += ' ';
        }
        result += c;
      });
      return result;
    }
  }
  return phoneNumber;
};

export default PluginProfileHeader;
