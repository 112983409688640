import React, { FC } from 'react';
import _ from 'underscore';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';

import useClientId from '@/hooks/router/useClientId';
import useCategoryCohorts from '@/graphql/hooks/clients/useCategoryCohorts';
import { DailyEvent } from '@/types/statistics/dailyEvent';
import { NumberRecord } from '@/types/statistics/stats';
import { sumNumberRecords } from '@/common/helpers/stats';
import InfoTooltip from '@/components/Common/InfoTooltip';
import { isDefinedGender } from '@/common/helpers/gender';
import DailyEventsDataContainer from '../../../components/DailyEventsDataContainer/DailyEventsDataContainer';
import { TimeSpan } from '../../../components/RevealAnalyticsHeader/RevealAnalyticsHeader';
import GraphWrapper from '../../../components/GraphWrapper/GraphWrapper';
import { GenderRecord, GENDER_STACK } from '../helpers';

import styles from './GenderVolumeProgression.module.less';

interface GenderVolumeProgressionProps {
  timeSpan: TimeSpan;
}

const GenderVolumeProgression: FC<GenderVolumeProgressionProps> = ({
  timeSpan,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { loading, data } = useCategoryCohorts(clientId, 'gender');

  const { categoryCohorts } = data?.client.statistics || {};

  const { initialValues, dailyEvents } = _.reduce(
    categoryCohorts || [],
    (aggregator, { day, values }) => {
      const valueMap = {} as GenderRecord;

      _.each(values, ({ categoryId, count }) => {
        if (isDefinedGender(categoryId)) {
          valueMap[categoryId] = count;
        } else {
          valueMap.unknown = (valueMap.unknown || 0) + count;
        }
      });

      const date = moment(day);

      if (date < timeSpan.startDate) {
        return {
          initialValues: sumNumberRecords(aggregator.initialValues, valueMap),
          dailyEvents: aggregator.dailyEvents,
        };
      }

      return {
        initialValues: aggregator.initialValues,
        dailyEvents: [...aggregator.dailyEvents, { day, values: valueMap }],
      };
    },
    {
      initialValues: {} as NumberRecord,
      dailyEvents: [] as DailyEvent[],
    },
  );

  return (
    <div className={styles.section}>
      <div className={styles.graph}>
        <GraphWrapper
          timeSpan={timeSpan}
          lockChartType
          title={
            <h3 className={styles.title}>
              {t('reveal.reports.diversity.genderVolumeProgression')}
              <InfoTooltip rich hoverable position='right center'>
                <h1>
                  {t(
                    'reveal.reports.tooltips.diversity.genderProgression.title',
                  )}
                </h1>
                <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
                <p>{t('reveal.reports.tooltips.common.hiresweetCount')}</p>
                <h2>{t('reveal.reports.tooltips.common.usefulness')}</h2>
                <p>
                  {t(
                    'reveal.reports.tooltips.diversity.genderProgression.usefulnessParagraph',
                  )}
                </p>
                <h2>
                  {t('reveal.reports.tooltips.diversity.common.genderLabeling')}
                </h2>
                <p>
                  {t(
                    'reveal.reports.tooltips.diversity.common.genderLabelingParagraph',
                  )}
                </p>
              </InfoTooltip>
            </h3>
          }
          renderChildren={(frequency, mode) =>
            loading ? (
              <div className={styles.loader}>
                <Loader active inline='centered' size='large' />
              </div>
            ) : (
              <DailyEventsDataContainer
                frequency={frequency}
                stack={GENDER_STACK}
                type='area'
                mode={mode}
                dailyEvents={dailyEvents}
                dateRange={timeSpan}
                initialValues={initialValues}
                exhaustive
              />
            )
          }
        />
      </div>
    </div>
  );
};

export default GenderVolumeProgression;
