import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import classNames from 'classnames';
import useClientUsers from '@/graphql/hooks/clients/useClientUsers';
import { toString } from '@/common/utils/string';

import styles from './RevealAnalyticsHeader.module.less';

const MAX_RESULT_DISPLAY = 4;

interface Props {
  selectedUsers: string[] | undefined;
  onChangeUsers: (Users: string[]) => void;
  clientId: string;
}

const UsersDropdown: React.FC<Props> = ({
  selectedUsers,
  onChangeUsers,
  clientId,
}) => {
  const { t } = useTranslation();
  const { users, loading } = useClientUsers(clientId);

  if (loading) {
    return <></>;
  }

  const handleChangeUser = (
    ev: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
  ) => {
    if (Array.isArray(data.value)) {
      const values = data.value.map(toString);
      onChangeUsers(values);
    }
  };

  if (loading) return <></>;

  const renderLabel = (item: DropdownItemProps, index: number) => {
    if (index < MAX_RESULT_DISPLAY) {
      return {
        content: item.text,
      };
    }
    if (index === MAX_RESULT_DISPLAY) {
      return {
        content: (
          <div
            onClick={() =>
              onChangeUsers(selectedUsers?.slice(0, MAX_RESULT_DISPLAY) ?? [])
            }
          >
            {t('reveal.reports.header.countUsers', {
              nbr:
                (selectedUsers?.length ?? MAX_RESULT_DISPLAY) -
                MAX_RESULT_DISPLAY,
            })}
          </div>
        ),
      };
    }
    return null;
  };

  return (
    <Dropdown
      selection
      search
      multiple
      onChange={handleChangeUser}
      value={selectedUsers ?? []}
      options={[
        ...users?.map((user: any) => ({
          text: `${user.firstname} ${user.lastname}`,
          value: user.email,
          selected: selectedUsers?.includes(user.email),
        })),
      ]}
      className={classNames(styles.multiDropdown, 'hiresweet-rounded')}
      placeholder={t('reveal.reports.header.usersPlaceholder')}
      renderLabel={renderLabel}
    />
  );
};

export default UsersDropdown;
