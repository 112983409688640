import gql from 'graphql-tag';
import { useMutation, MutationResult } from '@apollo/client';
import ProfileMissionInfoWithMission from '@/graphql/fragments/ProfileMissionInfoWithMission';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import ContactFlow from '@/graphql/fragments/ContactFlow';
import ProfileSequenceInfoWithSequence from '@/graphql/fragments/ProfileSequenceInfoWithSequence';
import TimelineItem from '@/graphql/fragments/TimelineItem';

export const UpdateProfileMissionPipelineStage = gql`
  mutation updateProfilesPipelineStage(
    $searchPoolId: ID!
    $input: UpdateProfilesMissionPipelineStage!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      updateProfilesPipelineStage(input: $input) {
        id
        missionsInfo {
          ...ProfileMissionInfoWithMission
        }
        contactFlow {
          ...ContactFlow
        }
        currentSequenceInfo {
          ...ProfileSequenceInfoWithSequence
        }
        contactData {
          timeline {
            ...TimelineItem
          }
        }
      }
    }
  }
  ${ProfileMissionInfoWithMission}
  ${ContactFlow}
  ${ProfileSequenceInfoWithSequence}
  ${TimelineItem}
`;

type UpdateProfilesMissionPipelineStageResult = Record<string, never>;

type UpdateProfilesMissionPipelineStageVariables = {
  searchPoolId: string;
  input: {
    missionId: string;
    segmentationId: string;
    profileIds: string[];
    stage: string;
    labels?: string[];
    clientArchiveReasonId?: string;
    interestedStepId?: string;
  };
};

type CustomUpdateProfilesPipelineStage = (
  args: UpdateProfilesMissionPipelineStageVariables['input'],
) => any;

export default function useUpdateProfilesPipelineStage(): [
  CustomUpdateProfilesPipelineStage,
  MutationResult<UpdateProfilesMissionPipelineStageResult>,
] {
  const [update, mutationResult] = useMutation<
    UpdateProfilesMissionPipelineStageResult,
    UpdateProfilesMissionPipelineStageVariables
  >(UpdateProfileMissionPipelineStage, {
    refetchQueries: [
      'getSearchPoolJobProfileResults',
      'getSegmentCounts',
      'getCrossMissionProfilesWithSegmentation',
    ],
  });

  const publish = useDataUpdateSubscriptionPublish();

  const customUpdate: CustomUpdateProfilesPipelineStage = ({
    missionId,
    segmentationId,
    profileIds,
    stage,
    labels,
    clientArchiveReasonId,
    interestedStepId,
  }) => {
    try {
      const data = update({
        variables: {
          searchPoolId: 'reveal',
          input: {
            missionId,
            segmentationId,
            profileIds,
            stage,
            labels,
            clientArchiveReasonId,
            interestedStepId,
          },
        },
      });
      publish('onProfilesChangedMissionStage', { profileIds, missionId });
      return data;
    } catch (error) {
      return console.error(error);
    }
  };

  return [customUpdate, mutationResult];
}
