import gql from 'graphql-tag';
import { ATStype } from '@/common/reveal';

interface ISynchronizationStatus {
  initializationStarted: boolean;
  initializationCompleted: boolean;
  candidatesLastRefreshTimestamp: string;
}

export type SynchronizationSettingsKeys =
  | 'synchronizeNotes'
  | 'synchronizeCalls'
  | 'synchronizeEmails'
  | 'synchronizeLinkedinMessages'
  | 'synchronizeCompletedTasks'
  | 'synchronizeMissionActivities'
  | 'synchronizeSequenceActivities';

export type ISynchronisationSettings = Partial<
  Record<SynchronizationSettingsKeys, boolean | undefined>
>;

export interface IRevealConnector {
  id: string;
  type: ATStype;
  accountId?: string;
  creationDate: string;
  synchronizationStatus: ISynchronizationStatus;
  synchronizationSettings: ISynchronisationSettings;
}

export default gql`
  fragment RevealConnector on RevealConnector {
    id
    type
    accountId
    creationDate
    synchronizationStatus {
      initializationStarted
      initializationCompleted
      candidatesLastRefreshTimestamp
    }
    synchronizationSettings {
      synchronizeNotes
      synchronizeCalls
      synchronizeEmails
      synchronizeLinkedinMessages
      synchronizeCompletedTasks
      synchronizeMissionActivities
      synchronizeSequenceActivities
    }
  }
`;
