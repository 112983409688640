import {
  useClientProfileCustomFieldsMutation,
  CustomFieldDefinition,
  CustomFieldEnumOption,
} from '@/graphql/hooks/clients/useClientProfileCustomFields';
import useModal from '@/hooks/useModal';
import { generateId } from '@/components/CustomFields/CustomFieldForm';
import { DetectedNewEnumOptions } from './analysis.helpers';
import { ColumnMapping } from './helpers';

type ModalParams = {
  columnIndex: number;
  isNewColumn?: boolean;
};

const useModalParams = () => useModal<ModalParams>();
type ModalWithParams = Omit<ReturnType<typeof useModalParams>, 'open'> & {
  open: (options: { params: ModalParams }) => void;
};

export function useCustomFieldsEnumOptionsUpdateMutation({
  customFields,
}: {
  customFields: CustomFieldDefinition[];
}): (
  newEnumOptions: DetectedNewEnumOptions,
) => Promise<CustomFieldDefinition[]> {
  const [updateCustomFieldsMutate] = useClientProfileCustomFieldsMutation();

  const onUpdateCustomFieldsEnumOptions = async (
    newEnumOptions: DetectedNewEnumOptions,
  ) => {
    const newCustomFields = customFields.map((cf) => {
      if (cf.type !== 'enum') return cf;

      const newOptions = newEnumOptions.find(
        (ne) => ne.customField.id === cf.id,
      );
      if (!newOptions) return cf;

      const ids = cf.options.map((x) => x.id);
      const currentOptions = cf.options;
      const normalizedNewOptions: CustomFieldEnumOption[] = newOptions.newEnumOptions.map(
        (x) => ({
          id: generateId(x.title, { exclude: ids }),
          title: { default: x.title },
        }),
      );
      return {
        ...cf,
        options: [...currentOptions, ...normalizedNewOptions],
      };
    });

    const result = await updateCustomFieldsMutate({
      variables: { input: { fields: newCustomFields } },
    });

    // FIXME: why [] at the end? ensure data is defined!
    // FIXME: remove updateProfileCustomFieldsSettings?.profileCustomFields
    return (
      result.data?.updateProfileCustomFieldsSettings?.profileCustomFields || []
    );
  };

  return onUpdateCustomFieldsEnumOptions;
}

/**
 * Hook to create a custom field definition on the fly and to link it to a
 * given column.
 *
 * `modalCreateCustomField.open` must be called with `{ columnIndex }` argument
 * so we can link the field to the given column
 */
export default function useCSVCreateColumnCustomField({
  customFields,
  updateColumnMapping,
}: {
  customFields: CustomFieldDefinition[];
  updateColumnMapping: (index: number, mapping: ColumnMapping) => void;
}): {
  modalCreateCustomField: ModalWithParams;
  onSubmitNewCustomField: (customField: CustomFieldDefinition) => Promise<void>;
} {
  const [updateCustomFieldsMutate] = useClientProfileCustomFieldsMutation();

  const updateCustomFields = async (fields: CustomFieldDefinition[]) => {
    try {
      await updateCustomFieldsMutate({ variables: { input: { fields } } });
    } catch (e) {
      console.error(e);
    }
  };

  const modalCreateCustomField = useModal<ModalParams>(false);
  const onSubmitNewCustomField = async (
    field: CustomFieldDefinition & {
      defaultValue?: string | string[] | number;
    },
  ) => {
    const columnIndex = modalCreateCustomField.params?.columnIndex;
    if (columnIndex === undefined || columnIndex === null) {
      console.error('columnIndex is not defined');
      return;
    }
    const { defaultValue, ...newCustomField } = field;
    await updateCustomFields([...customFields, newCustomField]);
    updateColumnMapping(columnIndex, {
      type: 'custom-field',
      id: field.id,
      ...(defaultValue && { defaultValue }),
    });
    modalCreateCustomField.close();
  };

  return {
    modalCreateCustomField,
    onSubmitNewCustomField,
  };
}
