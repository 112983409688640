import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { withRouter } from 'react-router-dom';
import { Checkbox, Image, Form, Icon, Input } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import GenericButton from '@/components/Common/GenericButton';
import { getCareerPageInput } from './helpers';

import contextToProps from '../../../hocs/contextToProps';
import withClientDetails from '../../../hocs/clients/withClientDetails';
import withAddCareerPage from '../../../hocs/clients/withCreateCareerPage';
import withUpdateCareerPage from '../../../hocs/clients/withUpdateCareerPage';
import routerParamsToProps from '../../../hocs/routerParamsToProps';
import CustomFileInput from '../../../components/CustomFileInput';

import './CareerPage.css';
import CareerPageDescriptionModal from './CareerPageDescription/DescriptionModal';

const careerPageURL = 'https://join.hiresweet.com';

const CareerPage = ({
  careerPage,
  createCareerPage,
  updateCareerPage,
  client,
  onShowNotification,
  t,
}) => {
  const defaultCareerPage = {
    title: {
      default: client?.title || '',
    },
    enabled: true,
  };

  const [careerPageState, setCareerPageState] = useState(
    careerPage || defaultCareerPage,
  );
  const [loading, setLoading] = useState(false);
  const [editingLogo, setEditingLogo] = useState(false);

  const { title, organizationLogoURL, organizationWebsite, enabled } =
    careerPageState || {};

  const disableSubmit = !title || !organizationWebsite;

  const handleCreate = async () => {
    if (disableSubmit) {
      return;
    }
    // event.preventDefault(); // disables required checks ?
    try {
      setLoading(true);
      const input = getCareerPageInput({ careerPageState });
      await createCareerPage({ input });
      onShowNotification({
        message: t('settings.careerPage.creationSuccess'),
        level: 'success',
      });
    } catch (e) {
      console.warn(e);
      onShowNotification({
        message: t('settings.careerPage.creationError'),
        level: 'error',
      });
    }
    setLoading(false);
  };

  const handleUpdate = async () => {
    if (disableSubmit) {
      return;
    }
    setLoading(true);
    try {
      const input = {
        id: careerPage?.id,
        ...getCareerPageInput({ careerPageState }),
      };
      await updateCareerPage({ input });
      onShowNotification({
        message: t('settings.careerPage.updateSuccess'),
        level: 'success',
      });
    } catch (e) {
      console.warn(e);
      onShowNotification({
        message: t('settings.careerPage.updateError'),
        level: 'error',
      });
    }
    setLoading(false);
  };

  const onChangeLogo = (files) => {
    const file = (files || [])[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          setCareerPageState({
            ...careerPageState,
            logo: {
              name: file.name,
              file: {
                content: reader.result,
              },
            },
          });
        }
      };
      reader.readAsDataURL(file);
    }
    setEditingLogo(false);
  };

  return (
    <Form className='career-page'>
      {careerPage?.id && (
        <Form.Field className='input-container page-url'>
          <label htmlFor='career-page-url'>
            <a
              href={`${careerPageURL}/${careerPage?.id}/jobs`}
              target='_blank'
              rel='noreferrer noopener'
            >
              <span>{t('settings.careerPage.url')}</span>
              <i className='link-icon ri-external-link-line' />
            </a>
          </label>

          <div className='input-element'>
            <Input
              id='career-page-url'
              fluid
              value={`${careerPageURL}/${careerPage?.id}/jobs`}
              disabled
            />
          </div>
        </Form.Field>
      )}

      <div className='input-container'>
        <div className='input-element half-width'>
          <Form.Input
            fluid
            required
            label={t('settings.careerPage.title')}
            value={title?.default || ''}
            onChange={(e) =>
              setCareerPageState({
                ...careerPageState,
                title: {
                  ...careerPageState?.title, // TODO: language mix ?
                  default: e?.target?.value,
                },
              })
            }
          />
        </div>
      </div>

      <div className='inputs-row company-logo'>
        <div className='input-container half-width company-logo-upload'>
          {(careerPageState?.logo?.file?.content || organizationLogoURL) &&
          !editingLogo ? (
            <>
              <div
                className='company-logo-label input-label'
                onClick={() => setEditingLogo(true)}
              >
                {t('settings.careerPage.logo')}
                <Icon className='ri-edit-line' />
              </div>
              <div className='input-description'>
                {t('settings.careerPage.logoDetails')}
              </div>
              <div className='input-element'>
                <Image
                  className='company-logo-preview'
                  src={
                    careerPageState?.logo?.file?.content || organizationLogoURL
                  }
                />
              </div>
            </>
          ) : (
            <>
              <div
                className='company-logo-label input-label'
                onClick={() => setEditingLogo(false)}
              >
                {t('settings.careerPage.logo')}
                {editingLogo && <Icon className='ri-close-line' />}
              </div>
              <div className='input-description'>
                {t('settings.careerPage.logoDetails')}
              </div>
              <div className='input-element'>
                <CustomFileInput
                  description={t('settings.careerPage.uploadLogo')}
                  fileTypes='image/*'
                  onChange={onChangeLogo}
                  t={t}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div className='input-container'>
        <div className='input-element half-width'>
          <Form.Input
            fluid
            required
            label={t('settings.careerPage.organizationWebsite')}
            value={organizationWebsite || ''}
            placeholder={t(
              'settings.careerPage.organizationWebsitePlaceholder',
            )}
            onChange={(e) =>
              setCareerPageState({
                ...careerPageState,
                organizationWebsite: e?.target?.value,
              })
            }
            input={{ 'data-form-type': 'other' }}
          />
        </div>
      </div>

      <div className='input-container career-page-status'>
        <div className='input-label'>{t('settings.careerPage.status')}</div>
        <div className='input-description'>
          {t('settings.careerPage.statusDetails')}
        </div>
        <div className='input-element'>
          <Checkbox
            checked={!!enabled}
            label={t('settings.careerPage.publish')}
            onChange={(e, { checked }) =>
              setCareerPageState({ ...careerPageState, enabled: checked })
            }
          />
        </div>
      </div>
      <div className='buttons-container'>
        {!careerPage ? (
          <GenericButton disabled={loading} onClick={handleCreate}>
            {t('settings.careerPage.create')}
          </GenericButton>
        ) : (
          <GenericButton disabled={loading} onClick={handleUpdate}>
            {t('settings.careerPage.save')}
          </GenericButton>
        )}
      </div>
    </Form>
  );
};

export default compose(
  withRouter,
  routerParamsToProps,
  contextToProps,
  withClientDetails,
  withAddCareerPage,
  withUpdateCareerPage,
  withTranslation('translations'),
)(CareerPage);
