import React from 'react';
import { Segment } from 'semantic-ui-react';

const CandidatesWithoutLinkedinWarning = ({ candidatesWithLinkedin, candidatesWithoutLinkedin }) => {
  candidatesWithLinkedin = candidatesWithLinkedin || [];
  candidatesWithoutLinkedin = candidatesWithoutLinkedin || [];

  if (candidatesWithLinkedin.length === 0) {
    return (
      <Segment color='red'>
        None of the selected contacts have a LinkedIn profile URL, so you might not be able to complete the tasks.
      </Segment>
    );
  }

  if (candidatesWithoutLinkedin.length === 0) {
    return null;
  }
  const plural = candidatesWithoutLinkedin.length > 1;
  return (
    <Segment color='red'>
      {`${candidatesWithoutLinkedin.length} contact${plural ? 's' : ''} from your selection ${
        plural ? "aren't" : "isn't"
      } associated with any LinkedIn profile URL, so you might not be able to complete the tasks.`}
    </Segment>
  );
};

export default CandidatesWithoutLinkedinWarning;
