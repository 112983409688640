import React, { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import GenericModal from '@/components/Common/GenericModal';
import GenericButton from '@/components/Common/GenericButton';

import FreeTrialUpgradeModalContext from './FreeTrialUpgradeModalContext';

import styles from './FreeTrialUpgradeModalContextProvider.module.less';

const FreeTrialUpgradeModalContextProvider: FC = ({ children }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const value = useMemo(
    () => ({
      openFreeTrialUpgradeModal: () => setOpen(true),
    }),
    [],
  );

  return (
    <>
      <FreeTrialUpgradeModalContext.Provider value={value}>
        {children}
      </FreeTrialUpgradeModalContext.Provider>
      <GenericModal
        open={open}
        onClose={() => setOpen(false)}
        content={
          <div className={styles.modal}>
            <h1 className={styles.title}>
              {t('reveal.freeTrial.upgradedModal.title')}
            </h1>
            <p className={styles.paragraph}>
              {t('reveal.freeTrial.upgradedModal.description')}
            </p>
            <GenericButton
              size='big'
              className={styles.cta}
              onClick={() => setOpen(false)}
            >
              {t('reveal.freeTrial.upgradedModal.cta')}
            </GenericButton>
          </div>
        }
      />
    </>
  );
};

export default FreeTrialUpgradeModalContextProvider;
