/* eslint-disable max-len */

import React, { FC } from 'react';

interface EmptyNotificationsIllustrationProps {
  className?: string;
}

const EmptyNotificationsIllustration: FC<EmptyNotificationsIllustrationProps> = ({
  className,
}) => (
  <svg
    className={className}
    viewBox='0 0 84 84'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='84' height='84' rx='8' fill='#F1F6FF' />
    <rect x='40' width='44' height='84' fill='url(#paint0_linear_276_15206)' />
    <path
      d='M52.5188 60.7337L50.8329 50.7146C50.7665 50.3201 50.8202 49.9156 50.9824 49.5537L53.0008 45.097C53.317 44.3846 54.2959 43.7535 55.2497 43.9377C56.2708 44.1006 57.0944 45.0775 57.0499 46.0787L57.0797 49.3897C57.09 49.6921 57.2137 49.9451 57.4162 50.124C57.6154 50.2832 57.8818 50.36 58.1481 50.3152L62.2011 49.6333C62.9801 49.5022 63.7038 49.7049 64.1912 50.1908C64.6555 50.6602 64.8407 51.3389 64.712 52.0805L63.5289 59.8749C63.4289 61.1492 62.2652 62.3692 60.9438 62.5915L57.0979 63.2386C56.4372 63.3498 55.472 63.279 54.9766 62.9263L53.5501 62.1624C53.0055 61.8788 52.6216 61.3451 52.5188 60.7337Z'
      fill='#4864C9'
    />
    <path
      d='M47.3725 49.3176L46.3568 49.4885C44.8283 49.7457 44.3066 50.4419 44.5522 51.9014L46.2214 61.8219C46.467 63.2814 47.1878 63.7685 48.7163 63.5114L49.7321 63.3404C51.2606 63.0833 51.7823 62.387 51.5367 60.9276L49.8674 51.007C49.6219 49.5475 48.901 49.0604 47.3725 49.3176Z'
      fill='#4864C9'
    />
    <path
      d='M71 53.8028C71 62.5305 63.8366 69.6056 55 69.6056C52.8332 69.6056 50.767 69.1802 48.8824 68.4094L40.4118 71L42.0596 63.0986C40.1353 60.49 39 57.2774 39 53.8028C39 45.0752 46.1634 38 55 38C63.8366 38 71 45.0752 71 53.8028Z'
      stroke='#4864C9'
      strokeWidth='1.5'
      strokeLinejoin='round'
      fill='none'
    />
    <g filter='url(#filter0_d_276_15206)'>
      <path
        d='M7 26.6338C7 37.4773 15.9543 46.2676 27 46.2676C29.5828 46.2676 32.0512 45.787 34.3176 44.9119C34.5315 44.8293 34.7668 44.8177 34.9862 44.8844L43.6188 47.5086C44.3462 47.7297 45.0445 47.0905 44.8884 46.3465L43.2679 38.6233C43.2092 38.3433 43.2742 38.0524 43.4394 37.8189C45.6843 34.6452 47 30.7903 47 26.6338C47 15.7904 38.0457 7 27 7C15.9543 7 7 15.7904 7 26.6338Z'
        fill='white'
      />
    </g>
    <path
      d='M18 20H36'
      stroke='#4864C9'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18 25H27.47'
      stroke='#4864C9'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18 30H36'
      stroke='#4864C9'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18 35H27.47'
      stroke='#4864C9'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <defs>
      <filter
        id='filter0_d_276_15206'
        x='3'
        y='5'
        width='48'
        height='48.553'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='2' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.121569 0 0 0 0 0.180392 0 0 0 0 0.466667 0 0 0 0.06 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_276_15206'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_276_15206'
          result='shape'
        />
      </filter>
      <linearGradient
        id='paint0_linear_276_15206'
        x1='84'
        y1='35.5'
        x2='40.0042'
        y2='36.2468'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
    </defs>
  </svg>
);

export default EmptyNotificationsIllustration;
