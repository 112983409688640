import React, { useEffect, useRef, useState } from 'react';
import { CompositeDecorator, Editor, EditorState, RichUtils } from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import classNames from 'classnames';
import { findLinkEntities } from '../../containers/Editor/helpers';
import {
  fromHTMLOptions,
  mapKeyToEditorCommand,
  toHTMLOptions,
} from './helpers';
import Link from '../../containers/Editor/Link';
import RichEditorControls from './RichEditorControls';

import 'draft-js/dist/Draft.css';
import './RichEditor.css';

const draftDecorators = [
  {
    strategy: findLinkEntities,
    component: Link,
  },
];
const decorator = new CompositeDecorator(draftDecorators);

const styleMap = {
  BOLD: {
    fontWeight: 500,
  },
};

const CHANGE_DELAY = 500;

const RichEditor = ({ initialHtml, onChangeAsHtml, smallControls = false, placeholder = '', disabled = false }) => {
  const editorRef = useRef();

  const initialContentState =
    initialHtml && convertFromHTML(fromHTMLOptions)(initialHtml);
  const getInitialEditorState = () =>
    initialContentState
      ? EditorState.createWithContent(initialContentState, decorator)
      : EditorState.createEmpty(decorator);

  const [editorState, setEditorState] = useState(getInitialEditorState);
  const [changeLocked, setChangeLocked] = useState(true);

  /**
   * The Editor component throws a change event once mounted,
   * which messes with behavior which rely on knowing if a
   * change actually happened. This effect blocks change events
   * long enough for that first event to not pass through.
   */
  useEffect(() => {
    setTimeout(() => setChangeLocked(false), CHANGE_DELAY);
  }, []);

  const onChange = (state) => {
    if (changeLocked) {
      return;
    }
    setEditorState(state);
    if (onChangeAsHtml) {
      const currentContent = state.getCurrentContent();
      const html = convertToHTML(toHTMLOptions)(currentContent);
      onChangeAsHtml(html);
    }
  };

  const handleKeyCommand = ({ controls }) => {
    return (command, state) => {
      if (command === 'hyperlink') {
        controls.handleAddLink();
        return 'handled';
      }
      const newEditorState = RichUtils.handleKeyCommand(state, command);
      if (newEditorState && command !== 'code') {
        onChange(newEditorState);
        return 'handled';
      }
      return 'not-handled';
    };
  };

  return (
    <div className={classNames('rich-editor', smallControls ? 'small' : '')}>
      <RichEditorControls
        editorState={editorState}
        editorRef={editorRef}
        onChange={setEditorState}
        smallControls={smallControls}
        disabled={disabled}
      >
        {({ controls }) => (
          <Editor
            readOnly={disabled}
            placeholder={placeholder || ''}
            ref={editorRef}
            editorState={editorState}
            onChange={onChange}
            customStyleMap={styleMap}
            keyBindingFn={mapKeyToEditorCommand}
            handleKeyCommand={handleKeyCommand({ controls })}
          />
        )}
      </RichEditorControls>
    </div>
  );
};

export default RichEditor;
