import React from 'react';

interface ShowNotificationArguments {
  message: string;
  level: 'error' | 'success' | 'warning' | 'info';
  type?: 'networkStatus';
  autoDismiss?: number;
}

export interface NotificationsContextType {
  onShowNotification: (options: ShowNotificationArguments) => void;
  onClearNotifications: () => void;
  success: (message: string) => void;
  error: (message: string) => void;
}

const doNothing = () => {
  /* Do nothing. */
};

const NotificationsContext = React.createContext<NotificationsContextType>({
  onShowNotification: doNothing,
  onClearNotifications: doNothing,
  success: doNothing,
  error: doNothing,
});

export default NotificationsContext;
