import {
  useQuery,
  QueryResult,
  useMutation,
  MutationTuple,
} from '@apollo/client';
import gql from 'graphql-tag';
import { GET_CLIENT_MARKETPLACE_DESCRIPTOR } from '@/graphql/clients';
import { ClientMarketplaceDescriptor as IClientMarketplaceDescriptor } from '@/types/client';
import ClientMarketplaceDescriptor from '@/graphql/fragments/ClientMarketplaceDescriptor';

export type ResultClientMarketplaceDescriptor = {
  client: {
    marketplaceDescriptor: IClientMarketplaceDescriptor;
  };
};

export function useClientMarketplaceDescriptor(
  clientId: string,
): QueryResult<ResultClientMarketplaceDescriptor, { clientId: string }> & {
  marketplaceDescriptor: IClientMarketplaceDescriptor | null;
} {
  const query = useQuery<
    ResultClientMarketplaceDescriptor,
    { clientId: string }
  >(GET_CLIENT_MARKETPLACE_DESCRIPTOR, { variables: { clientId } });

  const { marketplaceDescriptor } = query.data?.client || {};

  return {
    ...query,
    marketplaceDescriptor: marketplaceDescriptor ?? null,
  };
}

export default useClientMarketplaceDescriptor;

const updateClientMarketplaceDescriptor = gql`
  mutation updateClientMarketplaceDescriptor(
    $input: UpdateClientMarketplaceDescriptorInput
  ) {
    updateClientMarketplaceDescriptor(input: $input) {
      id
      marketplaceDescriptor {
        ...ClientMarketplaceDescriptor
      }
    }
  }
  ${ClientMarketplaceDescriptor}
`;

export interface ClientMarketplaceDescriptorInput {
  name?: string;
  website?: string;
  logo?: {
    name: string;
    file: {
      content: string;
    };
  };
  linkedin?: string;
  crunchbase?: string;
  description?: string;
}

export function useClientMarketplaceDescriptorMutation(): MutationTuple<
  {
    updateClientMarketplaceDescriptor: {
      marketplaceDescriptor: IClientMarketplaceDescriptor;
    };
  },
  { input: ClientMarketplaceDescriptorInput }
> {
  return useMutation(updateClientMarketplaceDescriptor);
}
