import React, { FC } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import {
  DisplayedTaskType,
  SequenceStatistic,
} from '@/types/statistics/clientStatistics';
import { ShowMoreRow } from '@/components/Tables/ShowMore';
import useLocalShowMore from '@/hooks/ui/useLocalShowMore';
import SequenceRow from './SequenceRow';
import { PrefixFilter } from './helpers/filters';

import styles from './SequenceArray.module.less';

interface SequenceArrayProps {
  sequencesStats: SequenceStatistic[];
  fetchDetails?: (
    sequenceId: string,
    taskType: DisplayedTaskType,
    prefix?: PrefixFilter,
  ) => void;
}

const SequenceArray: FC<SequenceArrayProps> = ({
  sequencesStats,
  fetchDetails,
}) => {
  const { t } = useTranslation();

  const [displayableItems, showMoreRowProps] = useLocalShowMore({
    defaultDisplayLimit: 10,
    items: sequencesStats,
  });

  return (
    <div className={styles.array}>
      <div className={styles.header}>
        <div className={styles.cell}>
          {t('reveal.analyticsView.missions.name')}
        </div>
        <div className={styles.cell}>
          {t('reveal.analyticsView.sequences.enrolled')}
        </div>
        <div className={styles.cell}>
          {t('reveal.analyticsView.sequences.contacted')}
        </div>
        <div className={styles.cell}>
          {t('reveal.analyticsView.sequences.opened')}
        </div>
        <div className={styles.cell}>
          {t('reveal.analyticsView.sequences.clicked')}
        </div>
        <div className={styles.cell}>
          {t('reveal.analyticsView.sequences.answered')}
        </div>
        <div className={styles.cell}>
          {t('reveal.analyticsView.sequences.positiveAnswered')}
        </div>
      </div>

      <div className='sequence-array-body'>
        {_.map(displayableItems, (stats) => (
          <SequenceRow
            stats={stats}
            fetchDetails={fetchDetails}
            key={stats.id}
          />
        ))}
        <ShowMoreRow {...showMoreRowProps} />
      </div>
    </div>
  );
};

export default SequenceArray;
