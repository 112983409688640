import gql from 'graphql-tag';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import OfferSearchCriteria from '@/graphql/fragments/OfferSearchCriteria';
import { SearchCriteria } from '@/types/search';

const GET_OFFER_CRITERIA = gql`
  query getOfferCriteria($offerId: ID!) {
    offer(id: $offerId) {
      id
      title
      criteria {
        ...OfferSearchCriteria
      }
    }
  }
  ${OfferSearchCriteria}
`;

interface GetOfferCriteriaData {
  offer: {
    id: string;
    title: string;
    criteria: SearchCriteria;
  };
}

interface GetOfferCriteriaVariables {
  offerId: string;
}

interface UseOfferCriteriaResult
  extends QueryResult<GetOfferCriteriaData, GetOfferCriteriaVariables> {
  offerCriteria?: unknown;
}

interface UseOfferCriteriaInput extends GetOfferCriteriaVariables {
  queryOptions?: QueryHookOptions<
    GetOfferCriteriaData,
    GetOfferCriteriaVariables
  >;
}

const useOfferCriteria = ({
  offerId,
  queryOptions = {},
}: UseOfferCriteriaInput): UseOfferCriteriaResult => {
  const query = useQuery<GetOfferCriteriaData, GetOfferCriteriaVariables>(
    GET_OFFER_CRITERIA,
    {
      ...queryOptions,
      variables: {
        offerId,
      },
    },
  );

  const { criteria } = query.data?.offer || {};

  return { ...query, offerCriteria: criteria };
};

export default useOfferCriteria;
