import React from 'react';
import { Segment } from 'semantic-ui-react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import withActionLogger from '../../../../hocs/withActionLogger';
import withUserFromJWToken from '../../../../hocs/users/withUserFromJWToken';
import withClientOffers from '../../../../hocs/offers/withClientOffers';
import OffersSuggestions from '../../../../components/OffersSuggestions';

import './smartNoProfileRightPane.css';
import OtherOffersToSee from './OtherOffersToSee';
import CollaboratorInvitationForm from '../../../Parameters/Collaborators/CollaboratorInvitationForm';
import withAddCollaborator from '../../../../hocs/users/withAddCollaborator';

const OffersSuggestionsPanel = ({
  suggestedForCreationOffers,
  addUser,
  user,
  clientId,
  t,
}) => (
  <div className='smart-no-profile-right-pane'>
    <Segment>
      <h3>{t('offersSuggestions.title')}</h3>
      <p>{t('offersSuggestions.description')}</p>
      <OffersSuggestions
        suggestedOffers={suggestedForCreationOffers}
        user={user}
        clientId={clientId}
      />
    </Segment>
    <Segment className='collaborator-invitation'>
      <h3>{t('offersSuggestions.collaboratorInvitationTitle')}</h3>
      <p>{t('offersSuggestions.collaboratorInvitationDescription')}</p>
      <CollaboratorInvitationForm addUser={addUser} />
    </Segment>
  </div>
);

class SmartNoProfileRightPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldDisplayOffersSuggestions: false,
    };
  }

  componentDidMount() {
    const suggestedForCreationOffers = _.where(this.props.offers, {
      isSuggestedForCreation: true,
    });
    if (!_.isEmpty(suggestedForCreationOffers)) {
      this.setState({ shouldDisplayOffersSuggestions: true });
    }
  }

  render() {
    const { offers, offerId, addUser, t } = this.props;

    const suggestedForCreationOffers = _.where(offers, {
      isSuggestedForCreation: true,
    });
    if (this.state.shouldDisplayOffersSuggestions) {
      return (
        <OffersSuggestionsPanel
          suggestedForCreationOffers={suggestedForCreationOffers}
          user={this.props.user}
          clientId={this.props.clientId}
          addUser={addUser}
          t={t}
        />
      );
    }

    const targetOffers = _.compact(
      _.map(
        offers,
        ({
          id,
          title,
          pipeStepStats,
          isArchived,
          isSuggestedForCreation,
          isUnclassified,
        }) => {
          if (
            !id ||
            !title ||
            !pipeStepStats ||
            isArchived ||
            isSuggestedForCreation ||
            isUnclassified
          ) {
            return undefined;
          }
          if (id === offerId) {
            return undefined;
          }
          const nbPending = (
            _.findWhere(pipeStepStats, { stepId: 'pending' }) || { count: 0 }
          ).count;
          if (!nbPending) {
            return undefined;
          }
          return { id, title, nbPending };
        },
      ),
    );

    if (!_.isEmpty(targetOffers)) {
      return (
        <div className='smart-no-profile-right-pane'>
          <OtherOffersToSee {...this.props} targetOffers={targetOffers} />
        </div>
      );
    }

    return <div />;
  }
}

export default compose(
  withTranslation('translations'),
  withAddCollaborator,
  withActionLogger,
  withClientOffers,
  withUserFromJWToken,
)(SmartNoProfileRightPane);
