import React from 'react';

export default (WrappedComponent) => {
  return class withLogout extends React.Component {
    handleLogout = () =>
      Promise.resolve()
        .then(() => localStorage.removeItem('token'))
        .then(() => localStorage.removeItem('lastActionTimestamp'))
        .then(() => window.location.reload());

    render() {
      return <WrappedComponent onLogout={this.handleLogout} {...this.props} />;
    }
  };
};
