import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { SearchCriteria } from '@/types/search';

export type ExtraFilters = {
  archiveReasons?: {
    in?: string[];
    notIn?: string[];
  };
  searchCriteria?: SearchCriteria;
};

export type ResultSegmentCounts = {
  searchPool: {
    job: {
      segmentsCounts: { segmentId: string; count: number }[];
    };
  };
};

export type VariablesSegmentCounts = {
  missionId: string;
  segmentationId: string;
  segmentDefinitions: {
    id: string;
    type?: string;
  }[];
  extraFilters?: ExtraFilters;
};

export const segmentCountsQuery = gql`
  query getSegmentCounts(
    $searchPoolId: ID!
    $missionId: ID!
    $segmentationId: ID!
    $segmentDefinitions: [SegmentDefinitionInput!]!
    $extraFilters: ProfileResultsExtraFilters
  ) {
    searchPool(id: $searchPoolId) {
      id
      job(id: $missionId) {
        id
        segmentsCounts(
          segmentationId: $segmentationId
          segmentDefinitions: $segmentDefinitions
          extraFilters: $extraFilters
        ) {
          segmentId
          count
        }
      }
    }
  }
`;

export function useSegmentCounts(variables: VariablesSegmentCounts) {
  const query = useQuery<
    ResultSegmentCounts,
    VariablesSegmentCounts & { searchPoolId: string }
  >(segmentCountsQuery, {
    variables: { ...variables, searchPoolId: 'reveal' },
    fetchPolicy: 'network-only',
  });

  return {
    ...query,
    segmentCounts: query.data?.searchPool?.job?.segmentsCounts || [],
  };
}

export default useSegmentCounts;
