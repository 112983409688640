import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Profile from '../../graphql/fragments/Profile';

const withNextProfileFromSearchPoolProfile = gql`
  query withNextProfileFromSearchPoolProfile(
    $clientId: ID!
    $searchPoolId: ID!
    $searchPoolProfileId: ID!
    $criteria: SearchCriteriaInput
  ) {
    profileFromSearchPoolProfile(
      clientId: $clientId
      searchPoolId: $searchPoolId
      searchPoolProfileId: $searchPoolProfileId
      criteria: $criteria
    ) {
      ...Profile
    }
  }
  ${Profile}
`;

export default graphql(withNextProfileFromSearchPoolProfile, {
  options: ({ clientId, searchPoolId, nextProfileId, searches }) => {
    const criteria = (searches || [])[0]; // TODO
    return {
      variables: {
        clientId,
        searchPoolId,
        searchPoolProfileId: nextProfileId,
        criteria,
      },
    };
  },
  // skip loading next profile while previous one is still loading
  // skip query if no nextProfileId provided (e.g at the end of the list)
  skip: ({ nextProfileId, loadingProfile }) =>
    !!loadingProfile || !nextProfileId,
});
