import React from 'react';
import _ from 'underscore';
import ProfileDimmer from '@/components/Dimmer/ProfileDimmer';
import { CandidateViewProvider } from '@/context/CandidateView/useCandidateViewContext';
import CandidateView from '@/routes/RevealView/SearchView/CandidatesListPane/CandidateView';
import { Sequence } from '@/types/sequence';
import { usePrefetchProfiles } from '@/context/CandidateView/useEnrichedCandidateViewProfile';

type Props = {
  clientId: string;
  searchPoolId: string;
  jobId: string;
  open: boolean;
  onClose: any;
  profileIdHandler: any;
  skipProfile: any;
  confirmProfile: any;
  clientSequencesList: Sequence[];
  isApplicantProfileModal?: boolean;
};

const RecommendedProfileModal = ({
  clientId,
  searchPoolId,
  jobId,
  open,
  onClose,
  profileIdHandler,
  skipProfile,
  confirmProfile,
  clientSequencesList,
  isApplicantProfileModal = false,
}: Props) => {
  const {
    prev: previousProfileId,
    next: nextProfileId,
    profileIds,
  } = profileIdHandler;
  const previousProfileIds = React.useMemo(() => {
    if (!previousProfileId) return [];
    return [previousProfileId];
  }, [previousProfileId]);
  const nextProfileIds = React.useMemo(() => {
    if (!nextProfileId) return [];
    return [nextProfileId];
  }, [nextProfileId]);

  const firstProfilesToFetch = React.useMemo(() => {
    if (_.isEmpty(profileIds)) return [];
    return _.first(profileIds, 2) as string[];
  }, [profileIds]);

  usePrefetchProfiles(nextProfileIds, {}, 300);
  usePrefetchProfiles(previousProfileIds, {}, 600);
  usePrefetchProfiles(firstProfilesToFetch, {}, 1000);

  if (!open) {
    return null;
  }
  return (
    <div className='enriched reveal-profile-modal profile-preview-container'>
      <ProfileDimmer
        previousProfileId={profileIdHandler.prev}
        nextProfileId={profileIdHandler.next}
        onSelectProfileId={profileIdHandler.onSelectProfileId}
        onClose={onClose}
      />
      <CandidateViewProvider
        profileId={profileIdHandler.current}
        clientId={clientId}
      >
        <CandidateView
          searchPoolId={searchPoolId}
          clientId={clientId}
          withSimilarProfiles
          onClose={onClose}
          isRecommendedProfileModal={!isApplicantProfileModal}
          isApplicantProfileModal={isApplicantProfileModal}
          skipProfile={skipProfile}
          confirmProfile={confirmProfile}
          jobId={jobId}
          clientSequences={clientSequencesList}
        />
      </CandidateViewProvider>
    </div>
  );
};

export default RecommendedProfileModal;
