import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import Contacts from '@/components/Reveal/Icons/Contacts';
import Projects from '@/components/Reveal/Icons/Projects';
import Company from '@/components/Common/Icons/Company';
import useClientId from '@/hooks/router/useClientId';
import useMissionWithCategory from '@/graphql/hooks/searchPoolJob/useMissionWithCategory';

import styles from './TargetPill.module.less';

type Props = {
  targetId: string;
  name: string;
  type: string;
};

const TargetPill: FC<Props> = ({ targetId, name, type }) => {
  const clientId = useClientId();

  const { missionWithCategory } = useMissionWithCategory({
    missionId: targetId,
    queryOptions: {
      skip: type !== 'mission',
    },
  });

  const icon = useMemo(() => {
    if (type === 'company') {
      return <Company />;
    }
    if (type === 'profile') {
      return <Contacts />;
    }
    if (type === 'mission') {
      return <Projects />;
    }
    return null;
  }, [type]);

  const to = useMemo(() => {
    if (type === 'company') {
      return `/client/${clientId}/reveal/companies?profileId=${targetId}`;
    }
    if (type === 'profile') {
      return `/client/${clientId}/reveal/search?profileId=${targetId}`;
    }
    if (type === 'mission') {
      const missionCategoryType =
        missionWithCategory?.missionCategory?.type || 'recruiting';
      return `/client/${clientId}/reveal/projects/${missionCategoryType}/${targetId}`;
    }
    return undefined;
  }, [clientId, targetId, type, missionWithCategory]);

  return to ? (
    <Link to={to} className={styles.pill}>
      {icon}
      {name}
    </Link>
  ) : (
    <span className={styles.pill}>
      {icon}
      {name}
    </span>
  );
};

export default TargetPill;
