import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { ConditionResult, ElseStatement } from '@/common/mergeTags/utils';
import { useConditionsChainingContext } from '../useConditionsChainingContext';
import styles from '../ConditionsChaining.module.less';

const ElseStatementValueSimpleInput: React.FC<{
  elseStatement: ElseStatement;
}> = ({ elseStatement }) => {
  const {
    getStateTreeAfterReplacement,
    conditionsChainingState,
    setConditionsChainingState,
  } = useConditionsChainingContext();

  const handleChange = (newValue: string) => {
    const newState = getStateTreeAfterReplacement<ConditionResult>({
      node: conditionsChainingState,
      id: elseStatement.id,
      newValue: {
        ...elseStatement,
        text: newValue,
      },
    });
    setConditionsChainingState(newState);
  };

  return (
    <Form.Field className={styles.simplifiedStatementInput}>
      <Input
        value={elseStatement.text}
        onChange={(_e, { value }) => handleChange(value)}
        className={styles.input}
      />
    </Form.Field>
  );
};

export default ElseStatementValueSimpleInput;
