import React, { FC } from 'react';

import styles from './svg.module.less';

const Message: FC = () => (
  <svg
    className={styles.svgIcon}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 2C1.34315 2 0 3.34315 0 5V11C0 12.6569 1.34315 14 3 14H13C14.6569 14 16 12.6569 16 11V5C16 3.34315 14.6569 2 13 2H3ZM7.11612 8.05546L3.53033 4.46967C3.23744 4.17678 2.76256 4.17678 2.46967 4.46967C2.17678 4.76256 2.17678 5.23744 2.46967 5.53033L6.05546 9.11612C7.1294 10.1901 8.8706 10.1901 9.94454 9.11612L13.5303 5.53033C13.8232 5.23744 13.8232 4.76256 13.5303 4.46967C13.2374 4.17678 12.7626 4.17678 12.4697 4.46967L8.88388 8.05546C8.39573 8.54361 7.60427 8.54361 7.11612 8.05546Z'
      fill='currentColor'
    />
  </svg>
);

export default Message;
