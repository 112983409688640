import _ from 'underscore';

import { DailyEvent } from '@/types/statistics/dailyEvent';
import {
  ClientDailyCohort,
  ExclusiveTaskType,
  isExclusiveTaskType,
} from '@/types/statistics/clientStatistics';

export const convertDailyCohort = ({
  day,
  details,
}: ClientDailyCohort): DailyEvent => {
  const counts: Record<ExclusiveTaskType, number> = {
    nbAnswered: 0,
    nbContacted: 0,
    nbPositiveAnswered: 0,
  };
  _.each(details, ({ type, count }) => {
    if (isExclusiveTaskType(type)) {
      counts[type] += count;
    }
  });

  const { nbPositiveAnswered, nbAnswered, nbContacted } = counts;
  return {
    day,
    values: {
      nbPositiveAnswered: nbPositiveAnswered || 0,
      nbAnswered: (nbAnswered || 0) - (nbPositiveAnswered || 0),
      nbContacted: (nbContacted || 0) - (nbAnswered || 0),
    },
  };
};

export default {};
