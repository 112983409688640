import _ from 'underscore';

import { getFullname } from '@/common/helpers/person';
import { NumberRecord } from '@/types/statistics/stats';
import { ClientDailyEvent } from '@/types/statistics/clientStatistics';
import { DailyEvent } from '@/types/statistics/dailyEvent';

type AggregatedTasksByUser = Record<
  string,
  { fullname: string; values: NumberRecord }
>;

export const convertClientDailyEventByTaskType = ({
  day,
  userStats,
}: ClientDailyEvent): DailyEvent => {
  const result: DailyEvent = {
    day,
    values: {},
  };

  _.each(userStats, ({ stats }) => {
    _.each(stats, ({ count, type }) => {
      result.values[type] = (result.values[type] || 0) + count;
    });
  });

  return result;
};

export const convertClientDailyEventByUser = ({
  day,
  userStats,
}: ClientDailyEvent): DailyEvent => {
  const result: DailyEvent = {
    day,
    values: {},
  };

  _.each(userStats, ({ author, stats }) => {
    const { email } = author;
    _.each(stats, ({ count }) => {
      result.values[email] = (result.values[email] || 0) + count;
    });
  });

  return result;
};

export const aggregateTasksByUser = (
  events: ClientDailyEvent[],
): AggregatedTasksByUser => {
  const result: AggregatedTasksByUser = {};
  _.each(events, ({ userStats }) => {
    _.each(userStats, ({ author, stats }) => {
      const { email } = author;
      result[email] = result[email] || {
        fullname: getFullname(author),
        values: {} as NumberRecord,
      };
      _.each(stats, ({ type, count }) => {
        result[email].values[type] = (result[email].values[type] || 0) + count;
      });
    });
  });
  return result;
};

export default {};
