import React from 'react';
import { withTranslation } from 'react-i18next';
import GenericButton from '@/components/Common/GenericButton';

const ReviewProfileButton = ({ onReview, t }) => {
  return (
    <GenericButton primacy='secondary' onClick={onReview}>
      <i className='ri-check-line' />
      {t('watchCollect.recommendationsView.profileCard.review')}
    </GenericButton>
  );
};

export default withTranslation('translations')(ReviewProfileButton);
