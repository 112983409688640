import gql from 'graphql-tag';

export default gql`
  fragment MatchingDetails on MatchingDetails {
    score
    criteria {
      skills {
        ...SkillsMatching
      }
      jobPosition {
        ...JobPositionMatching
      }
      experience {
        ...ExperienceMatching
      }
      locations {
        ...LocationsMatching
      }
      industries {
        ...IndustriesMatching
      }
      backgroundSolidity {
        ...BackgroundSolidityMatching
      }
      schoolPrestige {
        ...SchoolPrestigeMatching
      }
      startupnessImportance {
        ...StartupnessImportanceMatching
      }
    }
  }

  fragment SkillsMatching on SkillsMatching {
    main {
      ...SkillsMatchingObject
    }
    important {
      ...SkillsMatchingObject
    }
    bonus {
      ...SkillsMatchingObject
    }
  }

  fragment SkillsMatchingObject on SkillsMatchingObject {
    skills {
      ...SkillMatchingItem
    }
  }

  fragment SkillMatchingItem on SkillMatchingItem {
    skillId
    score
  }

  fragment JobPositionMatching on JobPositionMatching {
    score
    major {
      ...JobPositionMatchingItem
    }
    minors {
      ...JobPositionMatchingItem
    }
  }

  fragment JobPositionMatchingItem on JobPositionMatchingItem {
    jobId
    score
  }

  fragment ExperienceMatching on ExperienceMatching {
    value
    target {
      ...ExperienceMatchingItem
    }
    okWith {
      ...ExperienceMatchingItem
    }
  }

  fragment ExperienceMatchingItem on ExperienceMatchingItem {
    validated
    min
    max
  }

  #fragment JobPositionExperienceMatching on JobPositionExperienceMatching {
  #  score
  #  major {
  #    ...JobPositionExperienceMatchingItem
  #  }
  #  minor {
  #    ...JobPositionExperienceMatchingItem
  #  }
  #}
  #
  #fragment JobPositionExperienceMatchingItem on JobPositionExperienceMatchingItem {
  #  jobId
  #  value
  #  score
  #}

  fragment LocationsMatching on LocationsMatching {
    target {
      ...LocationsMatchingItem
    }
    okWith {
      ...LocationsMatchingItem
    }
  }

  fragment LocationsMatchingItem on LocationsMatchingItem {
    # score # not available yet
    validated
    remoteWish
    locationId
  }

  fragment IndustriesMatching on IndustriesMatching {
    target {
      ...IndustriesMatchingItem
    }
  }

  fragment IndustriesMatchingItem on IndustriesMatchingItem {
    score
    industryId
  }

  fragment BackgroundSolidityMatching on BackgroundSolidityMatching {
    value
    target {
      ...BackgroundMatchingItem
    }
    okWith {
      ...BackgroundMatchingItem
    }
  }

  fragment BackgroundMatchingItem on BackgroundMatchingItem {
    min
    max
    validated
    score
  }

  fragment StartupnessImportanceMatching on StartupnessImportanceMatching {
    value
    score
    target {
      ...StartupnessMatchingItem
    }
  }

  fragment StartupnessMatchingItem on StartupnessMatchingItem {
    value
    validated
    score
  }

  # School prestige
  fragment SchoolPrestigeMatching on SchoolPrestigeMatching {
    score
    value
    target {
      ...SchoolPrestigeMatchingItem
    }
  }

  fragment SchoolPrestigeMatchingItem on SchoolPrestigeMatchingItem {
    prestigeId
    validated
    score
  }
`;
