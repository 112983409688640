import classNames from 'classnames';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import useIsPlugin from '@/hooks/common/useIsPlugin';

import GenericModal from '@/components/Common/GenericModal';
import GenericButton from '@/components/Common/GenericButton';
import styles from './ForceSendModal.module.less';

const ForceSendModal = ({
  onSubmit,
  onCancel,
  open,
  t,
  title,
  content,
  question,
}) => {
  const isPlugin = useIsPlugin();

  return (
    <GenericModal
      className={classNames(
        styles.stopConfirmationModal,
        isPlugin && styles.plugin,
      )}
      open={open}
      onClose={onCancel}
      closeIcon
      size='tiny'
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <div>
          {content}
          <br />
          {question}
        </div>
      </Modal.Content>
      <Modal.Actions className={classNames(isPlugin && styles.pluginActions)}>
        <GenericButton
          type='button'
          primacy='secondary'
          onClick={onCancel}
          size={isPlugin ? 'normal' : 'big'}
        >
          {t('profile.contact.drafts.confirmationModal.cancel')}
        </GenericButton>
        <GenericButton
          primacy='primary'
          size={isPlugin ? 'normal' : 'big'}
          type='button'
          onClick={onSubmit}
        >
          {t('profile.contact.drafts.confirmationModal.send')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default withTranslation('translations')(ForceSendModal);
