import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { SearchPoolMiniProfile } from '@/graphql/searchPoolMiniProfileById';
import { MiniSearchPoolProfile } from '@/graphql/fragments/SearchPoolProfile';

const GET_MINI_PROFILES = gql`
  query getMiniSearchPoolProfiles($ids: [ID!]!) {
    searchPool(id: "reveal") {
      id
      profiles(ids: $ids) {
        ...MiniSearchPoolProfile
      }
    }
  }
  ${MiniSearchPoolProfile}
`;

type Data = {
  searchPool: {
    id: string;
    profiles: (SearchPoolMiniProfile | null)[];
  };
};

type Variables = {
  ids: string[];
};

type Input = {
  profileIds: string[];
  queryOptions?: Omit<QueryHookOptions<Data, Variables>, 'variables'>;
};

const useSearchPoolProfiles = ({ profileIds, queryOptions = {} }: Input) => {
  const queryResults = useQuery<Data, Variables>(GET_MINI_PROFILES, {
    ...queryOptions,
    variables: {
      ids: profileIds,
    } as Variables,
  });

  return {
    ...queryResults,
    profiles: queryResults?.data?.searchPool?.profiles,
  };
};

export default useSearchPoolProfiles;
