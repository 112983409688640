import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

const useEnrichedProfileQueryParams = (location) => {
  const { sppId, spId, profileId } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const searchPoolProfileId = sppId ? atob(sppId) : null;
  const searchPoolId = spId ? atob(spId) : null;

  const searchPool =
    searchPoolProfileId && searchPoolId
      ? { searchPoolId, searchPoolProfileId }
      : null;

  return {
    profileId,
    searchPool,
  };
};

export const useEnrichedProfilesRouting = ({
  selectedProfileId,
  onSelectProfileId,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { profileId, searchPool } = useEnrichedProfileQueryParams(location);

  const showClientProfileModal =
    !selectedProfileId && (searchPool || profileId);

  const onCloseModal = () => {
    history.push(location.pathname); // remove queryString
    onSelectProfileId(null);
  };

  return {
    clientProfileId: profileId, // clientProfileModal
    ...searchPool, // clientProfileModal (searchPool mode)
    showClientProfileModal,
    selectedProfileId, // searchPoolProfileModal
    onSelectProfileId, // to open searchPoolProfileModal
    onCloseModal, // both modals
  };
};

export const getProfilePermaLink = (searchPoolId, searchPoolProfileId) => {
  if (!searchPoolId || !searchPoolProfileId) {
    return '';
  }

  const spId = encodeURIComponent(btoa(searchPoolId));
  const sppId = encodeURIComponent(btoa(searchPoolProfileId));

  const querystring = `?spId=${spId}&sppId=${sppId}`;

  return `${window.origin}/redirect/discover/candidates${querystring}`;
};
