import _ from 'underscore';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';

export function filterCustomFieldsForSimplifiedCondition(
  customFields: CustomFieldDefinition[],
): CustomFieldDefinition[] {
  return _.filter(customFields, (definition) => {
    if (definition.type !== 'enum') {
      return false;
    }
    return _.size(definition.options) <= 5;
  });
}
