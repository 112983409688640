import React from 'react';

import JobPostingsManager from './JobPostingsManager';

const JobPostings = ({ clientId, offerId }) => {
  return (
    <div className='offer-job-postings'>
      <JobPostingsManager clientId={clientId} offerId={offerId} />
    </div>
  );
};

export default JobPostings;
