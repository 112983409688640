import _ from 'underscore';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  ATStype,
  ATS_LOGOS_BY_TYPE,
  ATS_NAMES_BY_TYPE,
  BOONDMANAGER,
} from '@/common/reveal';

import { Application } from '@/common/reveal/SourceData.type';
import {
  SynchronizationCheckedIcon,
  SynchronizationCrossIcon,
} from '@/assets/icons';
import { getDateFromTimestamp } from '@/common/dates';
import { ATSSingleDataProps } from '../types';

import styles from './PluginATSData.module.less';
import GenericATSTitle from '../components/GenericAtsTitle';
import JobApplication, { formatDate } from '../components/JobApplication';
import { getApplicationStageAndStatusText } from '../ATSData.helpers';

interface ATSdataCardProps {
  atsId: ATStype;
  title?: ReactElement | string;
  description?: ReactElement | string;
  children: ReactElement | ReactNode;
}

// A simple component to show an ATS card with title & info
export const ATSDataCard: FC<ATSdataCardProps> = ({
  atsId,
  title,
  description,
  children,
}) => {
  const atsLogo = ATS_LOGOS_BY_TYPE[atsId];
  return (
    <div className={styles.atsDataCard}>
      <h3>
        {atsLogo && (
          <img
            className={styles.atsLogo}
            height='16px'
            alt='ats logo'
            src={atsLogo}
          />
        )}
        {title && <div className={styles.extraTitle}>{title}</div>}
      </h3>
      {description && <div className={styles.description}>{description}</div>}
      {children && <div className={styles.content}>{children}</div>}
    </div>
  );
};

interface InfoCategoryProps {
  icon?: ReactElement | null;
  title: string;
  description?: string | null;
  more?: ReactElement | string | null;
}

const InfoCategory: FC<InfoCategoryProps> = ({
  icon = null,
  title,
  description = null,
  more = null,
  children,
}) => {
  return (
    <div>
      <div className={styles.infoCategory}>
        {icon}
        <span className={styles.infoCategoryTitle}>{title}</span>
        {description && (
          <span className={styles.infoCategoryDescription}>{description}</span>
        )}
        {more}
      </div>
      {children && <div>{children}</div>}
    </div>
  );
};

interface LatestApplicationProps {
  applications: Application[];
  atsId: ATStype;
}

const LatestApplication: FC<LatestApplicationProps> = ({
  applications,
  atsId,
}) => {
  const { t } = useTranslation();

  const [latestApplication] = _.sortBy(applications, 'applicationDate');
  const currentStage = getApplicationStageAndStatusText(
    latestApplication,
    atsId,
  );

  if (atsId === BOONDMANAGER) {
    return <GenericATSTitle title={currentStage} />;
  }

  const hasJobs = hasAtLeastOneJob(applications);
  if (!hasJobs) {
    const atsName = ATS_NAMES_BY_TYPE[atsId];
    return (
      <>
        {atsName}
        <span className={styles.notInAJob}>
          {t('reveal.missions.mission.profiles.noAtsApplications')}
        </span>
      </>
    );
  }

  const [job] = latestApplication.jobs || [];
  if (!job) {
    return null;
  }

  return (
    <GenericATSTitle
      title={job.title}
      description={currentStage}
      tagLabel={
        applications.length - 1 > 0 ? `+${applications.length - 1}` : null
      }
    />
  );
};

interface PluginAtsDataTitleProps {
  applications: Application[];
  open: boolean;
  atsId: ATStype;
  onOpen: () => void;
  onClose: () => void;
}

const PluginAtsDataTitle: FC<PluginAtsDataTitleProps> = ({
  applications,
  open,
  atsId,
  onOpen,
  onClose,
}) => {
  return (
    <div className={styles.atsDataTitle}>
      <LatestApplication atsId={atsId} applications={applications} />
      <div className={styles.rightAligned}>
        {open ? (
          <i
            className={classNames(styles.chevron, 'ri-arrow-up-s-line ri-lg')}
            onClick={onClose}
            onKeyPress={onClose}
            role='button'
          />
        ) : (
          <i
            className={classNames(styles.chevron, 'ri-arrow-down-s-line ri-lg')}
            onClick={onOpen}
            onKeyPress={onOpen}
            role='button'
          />
        )}
      </div>
    </div>
  );
};

const hasAtLeastOneJob = (applications: Application[]) => {
  return _.some(applications, (application) =>
    Boolean(application?.jobs && application.jobs.length > 0),
  );
};

const PluginATSData: FC<ATSSingleDataProps> = ({
  atsId,
  atsData,
  filteredApplications,
  lastRefreshTimestamp,
  candidateURL,
}) => {
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);

  const hasJobs = hasAtLeastOneJob(filteredApplications);

  return (
    <ATSDataCard
      atsId={atsId}
      title={
        <PluginAtsDataTitle
          atsId={atsId}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          applications={filteredApplications}
        />
      }
    >
      {open && (
        <div className={styles.atsApplications}>
          <InfoCategory
            title={
              lastRefreshTimestamp ? t('common.lastSync') : t('common.notSync')
            }
            description={
              lastRefreshTimestamp
                ? t('common.relativeDate', {
                    date: getDateFromTimestamp(lastRefreshTimestamp),
                  })
                : ''
            }
            icon={
              lastRefreshTimestamp ? (
                <SynchronizationCheckedIcon className={styles.svgIcon} />
              ) : (
                <SynchronizationCrossIcon className={styles.svgIcon} />
              )
            }
            more={
              candidateURL ? (
                <a
                  href={candidateURL}
                  target='__blank'
                  rel='noopener noreferrer'
                  className={styles.candidateUrl}
                >
                  {t('common.open')} <i className='ri-share-box-line' />
                </a>
              ) : null
            }
          />
          <InfoCategory
            title={t('profile.resume.ATSData.creationDate')}
            description={formatDate(
              i18n.resolvedLanguage,
              atsData.creationDate,
            )}
            icon={<i className='ri-calendar-event-line ri-lg' />}
          />
          {// this allows us to prevent showing the category
          // if there are no linked jobs (exept for Boond where there are none)
          (hasJobs || atsId === BOONDMANAGER) && (
            <InfoCategory
              title={
                atsId === BOONDMANAGER
                  ? t('common.step')
                  : t('profile.resume.ATSData.job', {
                      count: filteredApplications.length,
                    })
              }
              icon={<i className='ri-briefcase-4-line ri-lg' />}
            >
              {_.map(filteredApplications, (application) => (
                <JobApplication
                  key={application.sourceApplicationId}
                  atsId={atsId}
                  application={application}
                />
              ))}
            </InfoCategory>
          )}
        </div>
      )}
    </ATSDataCard>
  );
};

export default PluginATSData;
