import _ from 'underscore';
import React from 'react';
import './ProfileTabsMenu.css';
import useMinimizedView from '@/hooks/ui/useMinimizedView';

interface ProfileTabsMenuProps {
  tabOptions: Array<{ id: string; text: string }>;
  activeTabId: string;
  setActiveTabId: React.Dispatch<React.SetStateAction<string>>;
}

const PluginProfileTabsMenu: React.FC<ProfileTabsMenuProps> = ({
  tabOptions,
  activeTabId,
  setActiveTabId,
}) => {
  const { isMinimized } = useMinimizedView();

  if (isMinimized) {
    return <></>;
  }

  return (
    <div className='profile-tabs-menu plugin'>
      {_.map(tabOptions, ({ id, text }, index) => (
        <div
          key={id}
          id={`profile-tabs-menu-${id}`}
          onClick={() => setActiveTabId(id)}
          onKeyPress={() => setActiveTabId(id)}
          role='menuitem'
          tabIndex={index}
          className={`tab${activeTabId === id ? ' active' : ''}`}
        >
          {text}
        </div>
      ))}
    </div>
  );
};

export default PluginProfileTabsMenu;
