/* eslint-disable no-restricted-syntax */
import _ from 'underscore';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TASK_TYPES } from '@/common/constants/taskTypes';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import useSharedMessagingAccounts from '@/graphql/hooks/clients/useSharedServiceAccount';
import { UnipileMessageActionExecution } from '@/types/action';
import GenericButton from '@/components/Common/GenericButton';
import useLocalStorage from '@/hooks/storage/useLocalStorage';
import { GET_MESSAGE_ACCOUNT_CONTACT_RELATION } from '@/graphql/unipile';
import { useQuery } from '@apollo/client';
import { Icon } from 'semantic-ui-react';
import styles from './SendUnipileLinkedinMessage.module.less';

interface SendUnipileLinkedinMessageButtonProps {
  task: any;
  onExecute?: (input: UnipileMessageActionExecution) => void;
  profileId?: string;
  senderId?: string;
  disabled?: boolean;
}

export type LinkedinSenderConfig = null | string;

const SendUnipileLinkedinMessageButton: React.FC<SendUnipileLinkedinMessageButtonProps> = ({
  task,
  profileId,
  onExecute,
  senderId,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { handleCompleteTask, completeTaskLoading } = useCandidateViewContext();
  const { sharedMessagingAccounts } = useSharedMessagingAccounts({
    skip: !_.includes(
      [TASK_TYPES.LINKEDIN_SEND_MESSAGE, TASK_TYPES.LINKEDIN_SEND_REQUEST],
      task.type,
    ),
  });
  const linkedinMessagingAccounts = useMemo(() => {
    return _.filter(
      sharedMessagingAccounts,
      (account) => account.type === 'unipile-linkedin',
    );
  }, [sharedMessagingAccounts]);

  const [senderConfig] = useLocalStorage<LinkedinSenderConfig>(
    'revealLinkedinSender',
    null,
  );

  const selectedLinkedinSender = useMemo(
    () =>
      senderId || senderConfig
        ? _.findWhere(linkedinMessagingAccounts, {
            id: senderId || senderConfig || '',
          })
        : null,
    [linkedinMessagingAccounts, senderConfig, senderId],
  );

  const { data: isConnectedData, loading: isConnectedLoading } = useQuery(
    GET_MESSAGE_ACCOUNT_CONTACT_RELATION,
    {
      variables: {
        input: {
          sharedMessagingSenderId: selectedLinkedinSender?.id,
          messagingType: 'unipile-linkedin',
          profileId,
        },
      },
      skip: _.isEmpty(selectedLinkedinSender?.id),
    },
  );

  const isConnected =
    isConnectedData?.user?.getMessageAccountContactRelation?.status ===
    'connected';

  const handleSubmit = async () => {
    if (!selectedLinkedinSender || !onExecute) {
      return;
    }

    await onExecute({
      isConnected,
      chatId: '',
      senderId: selectedLinkedinSender.id,
    });

    handleCompleteTask(task);
  };

  if (!selectedLinkedinSender) {
    return null;
  }

  return (
    <GenericButton
      className={styles.sendOnLinkedinButton}
      disabled={completeTaskLoading || isConnectedLoading || disabled}
      onClick={handleSubmit}
    >
      <span>
        <Icon alt='send' name='send' />
        {t(
          `reveal.candidatesView.timeline.${
            task.type === TASK_TYPES.LINKEDIN_SEND_REQUEST && isConnected
              ? 'linkedinInviteTask'
              : 'linkedinMessageTask'
          }.send`,
        )}
      </span>
    </GenericButton>
  );
};

export default SendUnipileLinkedinMessageButton;
