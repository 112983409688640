import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import ReactivateOfferModal from '@/components/modals/ReactivateOfferModal';
import GenericButton from '@/components/Common/GenericButton';

import styles from './OfferHoldColumn.module.less';

interface OfferHoldColumnProps {
  offerId: string;
  double?: boolean;
}

const OfferHoldColumn: FC<OfferHoldColumnProps> = ({ offerId, double }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div
      className={classNames(styles.column, {
        [styles.double]: double,
      })}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.explanation}>{t('offers.isHoldColumn')}</div>
      <div className={styles.cta}>
        <GenericButton
          primacy='secondary'
          color='grey'
          size='small'
          onClick={() => setModalOpen(true)}
        >
          {t('offers.unHoldThisOffer')}
        </GenericButton>
        <ReactivateOfferModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          offerId={offerId}
        />
      </div>
    </div>
  );
};

export default OfferHoldColumn;
