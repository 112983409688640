import React, { useEffect, useMemo, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  Form,
  Input,
  Modal,
  ModalActions as Actions,
  ModalContent as Content,
  ModalHeader as Header,
} from 'semantic-ui-react';
import { TextingSender } from '@/graphql/hooks/clients/useTextingSenders';
import useClientId from '@/hooks/router/useClientId';
import useClientUsers from '@/graphql/hooks/clients/useClientUsers';
import { recruitersToOptions } from '../../Senders/helpers';

interface EditTextingSenderModalProps {
  textingSender: TextingSender;
  open: boolean;
  closeModal: () => void;
  onSave: (textingSender: TextingSender) => void;
}

const EditTextingSenderModal: React.FC<EditTextingSenderModalProps> = ({
  textingSender,
  open,
  closeModal,
  onSave,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { users } = useClientUsers(clientId);
  const [textingSenderState, setTextingSenderState] = useState(textingSender);

  const flattenedUsers = useMemo(
    () => _.map(textingSenderState?.users || [], (user) => user?.email),
    [textingSenderState],
  );

  useEffect(() => {
    if (textingSender) {
      setTextingSenderState(textingSender);
    }
  }, [textingSender]);

  if (!textingSender || !textingSenderState) {
    return null;
  }

  const recruiterOptions = recruitersToOptions({
    users,
    ownerEmail: textingSender.owner?.email,
  });

  return (
    <Modal
      className='edit-sender-modal'
      open={open}
      onClose={closeModal}
      size='small'
      closeIcon
    >
      <Header>
        <div>{t('settings.texting.editModal.header')}</div>
        {textingSender?.senderNumber && (
          <div className='subheader'>{textingSender?.senderNumber}</div>
        )}
      </Header>
      <Content>
        <div className='modal-main-description'>
          {t('settings.texting.editModal.description')}
        </div>
        <Form className='edit-sender-details'>
          <Form.Field className='input-container'>
            <div className='input-label'>
              {t('settings.texting.editModal.phoneNumberAccount')}
            </div>
            <div className='input-element'>
              <Input
                type='text'
                style={{ opacity: 0.8 }}
                disabled
                value={textingSender?.senderNumber}
              />
            </div>
          </Form.Field>

          <div className='inputs-row'>
            <div className='input-container half-width'>
              <div className='input-label'>
                {t('settings.texting.editModal.sharedWith')}
              </div>
              <div className='input-element'>
                <Dropdown
                  multiple
                  selection
                  fluid
                  options={recruiterOptions}
                  value={flattenedUsers}
                  onChange={(e, { value }) =>
                    setTextingSenderState({
                      ...textingSenderState,
                      users: _.map(value as string[], (email) => ({ email })),
                    })
                  }
                  placeholder={t('settings.texting.editModal.usersPlaceholder')}
                />
              </div>
            </div>
            {/* Spacer */}
            <div className='input-container half-width' />
          </div>
        </Form>
      </Content>
      <Actions>
        <div className='align-left'>
          <Button className='dismiss' type='button' onClick={closeModal}>
            {t('settings.texting.editModal.cancel')}
          </Button>
        </div>
        <Button
          primary
          size='big'
          type='button'
          onClick={() => onSave(textingSenderState)}
          disabled={false}
        >
          {t('settings.texting.editModal.confirm')}
        </Button>
      </Actions>
    </Modal>
  );
};

export default EditTextingSenderModal;
