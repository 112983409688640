import { TranslatableText } from '@/types/text';
import { gql, useMutation } from '@apollo/client';

const ADD_CUSTOM_CLIENT_COMPANY_STATE = gql`
  mutation AddClientCompanyState($companyState: AddClientCompanyStateInput) {
    addClientCompanyState(companyState: $companyState) {
      id
      customCompanyDealStates {
        customCompanyDealStateId
        title {
          default
          fr
          en
        }
        type
      }
    }
  }
`;

interface AddClientCustomCompanyDealStateVariables {
  companyState: {
    title: TranslatableText;
    type: 'prospect' | 'client' | 'archived';
  };
}

interface ClientCustomCompanyDealStateResults {
  client: {
    customCompanyDealStates: {
      customCompanyDealStateId: string;
      title: {
        default: string;
        fr: string;
        en: string;
      };
      type: string;
    }[];
  };
}

const useAddCustomClientCompanyState = () => {
  return useMutation<
    ClientCustomCompanyDealStateResults,
    AddClientCustomCompanyDealStateVariables
  >(ADD_CUSTOM_CLIENT_COMPANY_STATE);
};

export default useAddCustomClientCompanyState;
