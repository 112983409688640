import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import Papaparse from 'papaparse';
import {
  Modal,
  Table,
  Button,
  Dropdown,
  Checkbox,
  List,
  Message,
} from 'semantic-ui-react';

import logAction from '../../../common/logAction';
import {
  invalidEmailFormat,
  isValidLinkedinProfileUrl,
  isValidGithubProfileUrl,
  isValidBehanceProfileUrl,
  isValidDribbbleProfileUrl,
  isValidStackoverflowProfileUrl,
  isValidTwitterProfileUrl,
} from '../../../common/validators';

import withImportProfilesFromCSV from '../../../hocs/profiles/withImportProfilesFromCSV';
import withClientBaseOffers from '../../../hocs/offers/withClientBaseOffers';
import CustomFileInput from '../../CustomFileInput';

import './ImportCSVModal.css';

const ImportCSVModal = ({
  clientId,
  jobOfferId,
  open,
  onClose,
  importProfilesCSV,
  t,
}) => {
  const [dataFromCSV, setDataFromCSV] = useState([]);
  const [firstLineIsHeader, setFirstLineIsHeader] = useState(false);
  const [columnsMapping, setColumnsMapping] = useState([]);
  const [profilesToUpload, setProfilesToUpload] = useState([]);
  const [profileErrors, setProfileErrors] = useState([]);
  const [importingProfiles, setImportingProfiles] = useState(false);
  const [importProfilesResults, setImportProfilesResults] = useState(null);

  const onCancel = () => {
    setDataFromCSV([]);
    setFirstLineIsHeader(false);
    setColumnsMapping([]);
    setProfilesToUpload([]);
    setProfileErrors([]);
    setImportProfilesResults(null);
    onClose();
  };

  const onDrop = (files) => {
    const file = (files || [])[0];
    if (file) {
      onChange(file);
    }
  };

  const onChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        // The file's text will be printed here
        Papaparse.parse(reader.result, {
          complete: (results, parsedFile) => {
            setDataFromCSV(results?.data);
            logAction({
              type: 'import-profiles-load-csv',
              info: {
                firstRow: (results?.data || [])[0],
              },
            });
            const {
              firstLineIsHeaderGuess,
              columnsMappingGuess,
            } = guessColumnsFromFirstLine(results.data[0]);
            if (!_.isEmpty(columnsMappingGuess)) {
              setColumnsMapping(columnsMappingGuess);
            }
            setFirstLineIsHeader(firstLineIsHeaderGuess);
          },
          skipEmptyLines: 'greedy',
        });
      };
      reader.readAsText(file);
      setProfileErrors(null);
      setProfilesToUpload(null);
      setImportProfilesResults(null);
    }
  };

  const onUpload = async () => {
    const dataToUpload = firstLineIsHeader
      ? dataFromCSV.slice(1)
      : dataFromCSV.slice(0);
    const { profiles, errors } = formatProfiles(dataToUpload, columnsMapping);
    setProfilesToUpload(profiles);
    setProfileErrors(errors);
    if (_.isEmpty(errors)) {
      try {
        setImportingProfiles(true);
        const { data } = await importProfilesCSV({
          jobOfferId,
          CSVInput: profiles,
        });
        const results = (data || {}).importProfilesCSV;
        setImportProfilesResults(results);
      } catch (e) {
        console.error('Import failed', e);
      }
      setImportingProfiles(false);
    }
  };

  const canTryUpload =
    _.contains(columnsMapping, 'email') &&
    _.contains(columnsMapping, 'firstname') &&
    jobOfferId;

  return (
    <Modal
      open={open}
      size='fullscreen'
      className='import-csv-modal'
      onClose={onCancel}
      closeIcon
    >
      <Modal.Header>{t('CSVImport.title')}</Modal.Header>
      <Modal.Content>
        {_.isEmpty(dataFromCSV) && (
          <CustomFileInput
            onChange={onDrop}
            fileTypes='.csv, application/vnd.ms-excel, text/csv'
            description={t('CSVImport.dragFile')}
            t={t}
          />
        )}
        {!_.isEmpty(dataFromCSV) && (
          <>
            <CSVPreview
              dataFromCSV={dataFromCSV}
              firstLineIsHeader={firstLineIsHeader}
              setFirstLineIsHeader={setFirstLineIsHeader}
              columnsMapping={columnsMapping}
              setColumnsMapping={setColumnsMapping}
              t={t}
            />
            {!_.isEmpty(profileErrors) && !importProfilesResults && (
              <div className='results'>
                {profilesToUpload.length > 0 && profileErrors.length > 0 && (
                  <div className='description positive'>
                    {t('CSVImport.results.profilesCanBeUploaded', {
                      count: profilesToUpload.length,
                    })}
                  </div>
                )}
                {profileErrors.length > 0 && (
                  <div className='description negative'>
                    {t('CSVImport.results.profilesCannotBeUploaded', {
                      count: profileErrors.length,
                    })}
                  </div>
                )}
                {!_.isEmpty(profileErrors) && (
                  <>
                    <div>
                      {t(
                        `CSVImport.${
                          profileErrors.length > 10
                            ? 'seeFirstErrors'
                            : 'seeErrors'
                        }`,
                        {
                          count: _.first(profileErrors, 10).length,
                        },
                      )}
                    </div>
                    <div>
                      <List bulleted>
                        {_.map(
                          _.first(profileErrors, 10),
                          ({ row, reasons }, rowIndex) => (
                            <List.Item key={`${rowIndex}-${row}`}>
                              {t('CSVImport.rowNumber', { number: row + 1 })}{' '}
                              {reasons.length > 1 ? (
                                <List>
                                  {_.map(reasons, (reason, reasonIndex) => (
                                    <List.Item key={reasonIndex}>
                                      {t(`CSVImport.errors.${reason?.text}`)}{' '}
                                      {reason.value && `(${reason.value})`}
                                    </List.Item>
                                  ))}
                                </List>
                              ) : (
                                <span>
                                  {t(`CSVImport.errors.${reasons[0]?.text}`)}{' '}
                                  {reasons[0]?.value &&
                                    `(${reasons[0]?.value})`}
                                </span>
                              )}
                            </List.Item>
                          ),
                        )}
                      </List>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}

        {importProfilesResults && (
          <Results clientId={clientId} results={importProfilesResults} t={t} />
        )}
      </Modal.Content>
      {!_.isEmpty(dataFromCSV) && !importProfilesResults && (
        <Modal.Actions>
          <div className='align-left'>
            <Button className='dismiss' onClick={onCancel}>
              {t('CSVImport.cancel')}
            </Button>
          </div>
          <Button
            primary
            size='big'
            onClick={onUpload}
            disabled={!canTryUpload || importingProfiles}
            loading={importingProfiles}
          >
            {t('CSVImport.upload')}
          </Button>
        </Modal.Actions>
      )}
      {importProfilesResults && (
        <Modal.Actions>
          <Button primary size='big' onClick={onCancel}>
            {t('CSVImport.close')}
          </Button>
        </Modal.Actions>
      )}
    </Modal>
  );
};

const CSVPreview = ({
  dataFromCSV,
  firstLineIsHeader,
  setFirstLineIsHeader,
  columnsMapping,
  setColumnsMapping,
  previewLength = 5,
  t,
}) => {
  const dropdownOptions = [
    { value: null, text: t('CSVImport.selectField') },
    { value: 'firstname', text: t('CSVImport.dropdownOptions.firstname') },
    { value: 'lastname', text: t('CSVImport.dropdownOptions.lastname') },
    { value: 'email', text: t('CSVImport.dropdownOptions.email') },
    { value: 'linkedin', text: t('CSVImport.dropdownOptions.linkedin') },
    { value: 'github', text: t('CSVImport.dropdownOptions.github') },
    {
      value: 'stackoverflow',
      text: t('CSVImport.dropdownOptions.stackoverflow'),
    },
    { value: 'twitter', text: t('CSVImport.dropdownOptions.twitter') },
    { value: 'dribbble', text: t('CSVImport.dropdownOptions.dribbble') },
    { value: 'behance', text: t('CSVImport.dropdownOptions.behance') },
    { value: 'website', text: t('CSVImport.dropdownOptions.website') },
  ];

  const onChangeColumnMapping = (index) => (event, { value }) => {
    setColumnsMapping((previousColumnsMapping) => {
      const rowLength = dataFromCSV[0].length;
      const newColumnsMapping =
        (previousColumnsMapping || []).length === rowLength
          ? [...previousColumnsMapping]
          : new Array(rowLength);
      newColumnsMapping[index] = value;
      return newColumnsMapping;
    });
  };

  const headerLine = firstLineIsHeader && dataFromCSV[0];
  const dataPreview = firstLineIsHeader
    ? dataFromCSV.slice(1, previewLength)
    : dataFromCSV.slice(0, previewLength);

  return (
    <div>
      <div className='input-container'>
        {(dataFromCSV || []).length > previewLength && (
          <div className='input-description'>
            {t('CSVImport.previewDescription', {
              totalCount: dataFromCSV.length,
              previewLength,
            })}
          </div>
        )}
        <div className='input-description'>
          {t('CSVImport.mappingInstructions')}
        </div>

        <div className='input-element'>
          <Checkbox
            checked={firstLineIsHeader}
            label={t('CSVImport.fistLineIsHeader')}
            onChange={() => {
              setFirstLineIsHeader((previousValue) => !previousValue);
            }}
          />
        </div>
      </div>
      <div style={{ overflowX: 'scroll' }}>
        <div className='dropdowns-container'>
          {_.map(dataPreview[0], (cell, index) => (
            <Dropdown
              key={`${columnsMapping[index]}-${index}`}
              className='column-dropdown'
              value={columnsMapping[index]}
              placeholder={t('CSVImport.selectField')}
              options={_.filter(
                dropdownOptions,
                ({ value }) =>
                  !value ||
                  !_.contains(columnsMapping, value) ||
                  value === columnsMapping[index],
              )}
              onChange={onChangeColumnMapping(index)}
              clearable
              fluid
              search
              selection
            />
          ))}
        </div>
        {!_.isEmpty(dataPreview) && (
          <Table>
            <Table.Body>
              {headerLine && (
                <Table.Row className='header-row'>
                  {_.map(headerLine, (cell, cellIndex) => (
                    <Table.Cell key={`header-${cellIndex}`}>
                      {`(${cell})` || ''}
                    </Table.Cell>
                  ))}
                </Table.Row>
              )}
              {_.compact(
                _.map(dataPreview, (row, index) => {
                  if (_.isEmpty(row)) {
                    return null;
                  }
                  return (
                    <Table.Row key={index}>
                      {_.map(row, (cell, cellIndex) => (
                        <Table.Cell key={`${index}-${cellIndex}`}>
                          {cell || ''}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  );
                }),
              )}
            </Table.Body>
          </Table>
        )}
      </div>
    </div>
  );
};

const Results = ({ clientId, results, t }) => {
  return (
    <>
      <Message className='results'>
        {results?.insertedProfilesCount === 0 &&
          (results?.mergedProfiles || []).length === 0 && (
            <div className='description positive'>
              {t('CSVImport.results.noNewProfiles')}
            </div>
          )}
        {results?.insertedProfilesCount > 0 && (
          <div className='description positive'>
            {t('CSVImport.results.profilesUploadedCount', {
              count: results?.insertedProfilesCount,
            })}
          </div>
        )}

        {(results?.mergedProfiles || []).length > 0 && (
          <div className='description positive'>
            {t('CSVImport.results.profilesMergedCount', {
              count: (results?.mergedProfiles || []).length,
            })}
          </div>
        )}
        {(results?.mergedProfiles || []).length > 0 && (
          <List bulleted>
            {_.map(results?.mergedProfiles, ({ id, resumeData }) => (
              <List.Item key={id}>
                {resumeData?.firstname} {resumeData?.lastname}
              </List.Item>
            ))}
          </List>
        )}
      </Message>

      {(results?.duplicateProfiles || []).length > 0 && (
        <Message className='results warning'>
          <div className='description'>
            {t('CSVImport.results.profilesDuplicatesCount', {
              count: (results?.duplicateProfiles || []).length,
            })}
          </div>
          <List bulleted>
            {_.map(results?.duplicateProfiles, (profile) => (
              <List.Item key={profile?.id}>
                {getProfileLink({ clientId, profile })}
              </List.Item>
            ))}
          </List>
        </Message>
      )}
    </>
  );
};

const getProfileLink = ({ clientId, profile, onClick }) => {
  const { id, jobOfferId } = profile || {};
  const { firstname, lastname, email } = profile?.resumeData || {};
  const fullName = `${firstname || ''} ${lastname || ''}`.trim();
  return (
    <div className='profile-link'>
      <a
        href={`/client/${clientId}/jobs/${jobOfferId}/profiles/${id}`}
        onClick={onClick}
        target='_blank'
        rel='noreferrer noopener'
      >
        {fullName}
      </a>
      {email && <div className='profile-link-email'>{email}</div>}
    </div>
  );
};

const formatProfiles = (dataFromCSV, columnsMapping) => {
  const errors = [];
  const validProfiles = _.compact(
    _.map(dataFromCSV, (row, index) => {
      const profile = {};
      for (
        let columnIndex = 0;
        columnIndex < columnsMapping.length;
        columnIndex += 1
      ) {
        const column = columnsMapping[columnIndex];
        if (column && row[columnIndex]) {
          profile[column] = row[columnIndex];
        }
      }
      const { isValid, reasons } = checkProfile(profile);
      if (!isValid) {
        errors.push({ row: index, reasons });
        return null;
      }
      return profile;
    }),
  );
  return { profiles: validProfiles, errors };
};

const checkProfile = (profile) => {
  let profileIsValid = true;
  const reasons = [];
  if (!profile.firstname) {
    profileIsValid = false;
    reasons.push({ text: 'missingFirstname' });
  }

  if (!profile.email) {
    profileIsValid = false;
    reasons.push({ text: 'missingEmail' });
  }
  if (profile.email && invalidEmailFormat({ email: profile.email })) {
    profileIsValid = false;
    reasons.push({ text: 'invalidEmail', value: profile.email });
  }

  if (
    profile.linkedin &&
    !isValidLinkedinProfileUrl({ linkedin: profile.linkedin })
  ) {
    profileIsValid = false;
    reasons.push({ text: 'invalidLinkedin', value: profile.linkedin });
  }

  if (profile.github && !isValidGithubProfileUrl({ github: profile.github })) {
    profileIsValid = false;
    reasons.push({ text: 'invalidGithub', value: profile.github });
  }

  if (
    profile.behance &&
    !isValidBehanceProfileUrl({ behance: profile.behance })
  ) {
    profileIsValid = false;
    reasons.push({ text: 'invalidBehance', value: profile.behance });
  }

  if (
    profile.dribbble &&
    !isValidDribbbleProfileUrl({ dribbble: profile.dribbble })
  ) {
    profileIsValid = false;
    reasons.push({ text: 'invalidDribbble', value: profile.dribbble });
  }

  if (
    profile.twitter &&
    !isValidTwitterProfileUrl({ twitter: profile.twitter })
  ) {
    profileIsValid = false;
    reasons.push({ text: 'invalidTwitter', value: profile.twitter });
  }

  if (
    profile.stackoverflow &&
    !isValidStackoverflowProfileUrl({ stackoverflow: profile.stackoverflow })
  ) {
    profileIsValid = false;
    reasons.push({
      text: 'invalidStackoverflow',
      value: profile.stackoverflow,
    });
  }

  return { isValid: profileIsValid, reasons };
};

export const guessColumnsFromFirstLine = (firstLine) => {
  const regexList = [
    { value: 'firstname', regex: /(first[- ]*name)|(pr(e|é)nom)/i },
    { value: 'lastname', regex: /(last[- ]*name)|(sur[- ]*name)/i },
    { value: 'email', regex: /e[- ]*mail/i },
    { value: 'linkedin', regex: /linkedin/i },
    { value: 'linkedin', regex: /^profile url/i },
    { value: 'github', regex: /github/i },
    { value: 'stackoverflow', regex: /stackoverflow/i },
    { value: 'behance', regex: /behance/i },
    { value: 'twitter', regex: /twitter/i },
    { value: 'dribbble', regex: /dribbble/i },
    { value: 'website', regex: /[web]*site/i },
  ];
  const columnsMappingGuess = [];

  for (let index = 0; index < firstLine.length; index += 1) {
    const cell = firstLine[index];
    const value = testAllRegex(
      cell,
      _.filter(
        regexList,
        (regex) => !_.contains(columnsMappingGuess, regex.value),
      ),
    );
    columnsMappingGuess.push(value);
  }

  if (
    _.filter(
      columnsMappingGuess,
      (value) =>
        value === 'firstname' || value === 'lastname' || value === 'email',
    ).length >= 2
  ) {
    return { firstLineIsHeaderGuess: true, columnsMappingGuess };
  }
  return {
    firstLineIsHeaderGuess: false,
    columnsMappingGuess: _.filter(
      columnsMappingGuess,
      (value) =>
        value !== 'firstname' || value !== 'lastname' || value !== 'email',
    ),
  };
};

const testAllRegex = (text, regexList) => {
  for (let index = 0; index < regexList.length; index += 1) {
    const regex = regexList[index];
    if (regex.regex.test(text)) {
      return regex.value;
    }
  }
  return null;
};

export default compose(
  withClientBaseOffers,
  withImportProfilesFromCSV,
  withTranslation('translations'),
)(ImportCSVModal);
