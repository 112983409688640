import React from 'react';
import classNames from 'classnames';

import { Segment } from 'semantic-ui-react';

import styles from './CandidatePage.module.less';

const CandidatePage = (
  props: Omit<React.ComponentProps<'div'>, 'color' | 'placeholder'>,
) => {
  const { className } = props;
  return (
    <Segment
      {...props}
      className={classNames('hiresweet-segment', className)}
    />
  );
};

CandidatePage.defaultProps = { className: '' };

export default CandidatePage;

CandidatePage.Header = ({ children }: { children: React.ReactNode }) => {
  return <div className='profile-header-container'>{children}</div>;
};

CandidatePage.Divider = ({
  withVerticalMargin,
}: {
  withVerticalMargin: boolean;
}) => {
  return (
    <div
      className={classNames(styles.divider, {
        [styles.dividerVerticalMargin]: withVerticalMargin,
      })}
    />
  );
};

CandidatePage.Content = ({ children }: { children: React.ReactNode }) => {
  return <div className='profile-tab-content'>{children}</div>;
};
