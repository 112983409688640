import React from 'react';
import _ from 'underscore';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { TextingSender } from '@/graphql/hooks/clients/useTextingSenders';
import styles from './GrantedTextingSendersTable.module.less';

interface GrantedTextingSendersTableProps {
  senders: TextingSender[];
}

const GrantedTextingSendersTable: React.FC<GrantedTextingSendersTableProps> = ({
  senders,
}) => {
  if (_.isEmpty(senders)) {
    return null;
  }
  return (
    <Table basic className={styles.table}>
      <TextingSendersHeader />
      <TextingSendersBody senders={senders} />
    </Table>
  );
};

export const TextingSendersHeader = () => {
  const { t } = useTranslation();
  return (
    <Table.Header className={styles.header}>
      <Table.Row className={styles.headerRow}>
        <Table.HeaderCell className={styles.th}>
          <span>{t('settings.texting.table.senderNumber')}</span>
        </Table.HeaderCell>
        <Table.HeaderCell className={styles.th}>
          <span>{t('settings.texting.table.owner')}</span>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export const TextingSendersBody: React.FC<GrantedTextingSendersTableProps> = ({
  senders,
}) => {
  return (
    <Table.Body>
      {_.map(senders, (sender) => (
        <TextingSendersRow key={sender.id} sender={sender} />
      ))}
    </Table.Body>
  );
};

interface TextingSendersRowProps {
  sender: TextingSender;
}

export const TextingSendersRow: React.FC<TextingSendersRowProps> = ({
  sender,
}) => {
  const { id, firstname, lastname, senderNumber } = sender || {};

  return (
    <Table.Row key={id} className={styles.row}>
      <Table.Cell textAlign='left' className={styles.name}>
        <span>{senderNumber}</span>
      </Table.Cell>
      <Table.Cell textAlign='left' className={styles.subtype}>
        {firstname || ''} {lastname || ''}
      </Table.Cell>
    </Table.Row>
  );
};

export default GrantedTextingSendersTable;
