import { useMutation } from '@apollo/client';
import { CREATE_SNIPPET } from '@/graphql/snippets';

const useCreateSnippet = () => {
  return useMutation<any, any>(CREATE_SNIPPET, {
    refetchQueries: ['GetClientSnippets', 'getAssistantDescriptors'],
  });
};

export default useCreateSnippet;
