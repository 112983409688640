import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './DoNotContactPill.module.less';

interface DoNotContactPillProps {
  showLabel?: boolean;
  text?: string;
}

const DoNotContactPill: React.FC<DoNotContactPillProps> = ({
  showLabel = true,
  text,
}) => {
  const { t } = useTranslation();

  return (
    <div className={style.pill}>
      <i className='ri-chat-off-line' />
      {showLabel &&
        (text || t('reveal.profileModal.privacy.doNotContact.label'))}
    </div>
  );
};

export default DoNotContactPill;
