import _ from 'underscore';
import React, { FC, useMemo } from 'react';

import GenericSelect from '../GenericSelect/GenericSelect';

import styles from './TimeSelector.module.less';

interface TimeSelectorProps {
  time?: string | null;
  onChange: (time: string) => void;
}

const buildLinearOptions = ({
  from = 0,
  to,
}: {
  from?: number;
  to: number;
}): { label: string; value: string }[] => {
  return _.map(_.range(from, to), (number) => ({
    label: `00${number}`.slice(-2),
    value: `00${number}`.slice(-2),
  }));
};

const TimeSelector: FC<TimeSelectorProps> = ({ time, onChange }) => {
  const [hours, minutes] = time?.split(':') || ['12', '00'];
  const hoursOptions = useMemo(
    () =>
      buildLinearOptions({
        from: 0,
        to: 24,
      }),
    [],
  );

  const minutesOptions = useMemo(
    () =>
      buildLinearOptions({
        from: 0,
        to: 60,
      }),
    [],
  );
  return (
    <div className={styles.timeSelectorDropdown}>
      <GenericSelect
        className={styles.dropdown}
        options={hoursOptions}
        onChange={(hour) => {
          if (!hour?.value) {
            return;
          }

          onChange(`${hour.value}:${minutes}`);
        }}
        value={{
          label: `00${hours}`.slice(-2),
          value: `00${hours}`.slice(-2),
        }}
      />
      :
      <GenericSelect
        className={styles.dropdown}
        options={minutesOptions}
        onChange={(minute) => {
          if (!minute?.value) {
            return;
          }

          onChange(`${hours}:${minute.value}`);
        }}
        value={{
          label: `00${minutes}`.slice(-2),
          value: `00${minutes}`.slice(-2),
        }}
      />
    </div>
  );
};

export default TimeSelector;
