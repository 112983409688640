import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _ from 'underscore';
import { Dropdown, Loader } from 'semantic-ui-react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import useLockCurrentSequenceVariable from '@/graphql/hooks/searchPoolProfile/useLockCurrentSequenceVariable';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import { MergeTagsVariable, SNIPPET_TYPES } from '@/common/mergeTags/utils';
import useClientProfileCustomFields, {
  CustomFieldDefinition,
} from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { getTranslatedText } from '@/common';
import {
  SEARCH_POOL_PROFILE_WITH_RESUME_DATA_ONLY,
  UPDATE_RESUME_DATA_IN_SEARCH_POOL,
} from '@/graphql/searchPoolProfile';
import {
  combineDataWithCustomFields,
  createCustomFieldsPayload,
} from '@/common/customFields';
import GenericToggle from '@/components/Common/GenericToggle';
import useUpdateContactFlowAction from '@/graphql/hooks/searchPoolProfile/useUpdateContactFlowAction';
import createActionInputFromAction from '@/common/contactFlow/createActionInputFromAction';
import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import { sanitizeTypename } from '@/common/utils/apollo';
import GenericButton from '@/components/Common/GenericButton';
import useGenerateAIVariable from '@/graphql/hooks/searchPoolProfile/useGenerateAIVariable';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';

import styles from '../SequenceDynamicVariables.module.less';

type Props = {
  clientId: string;
  mergeTagsVariable: MergeTagsVariable;
  afterSubmit?: () => void;
  sequenceSnippetsByType?: Record<string, MergeTagsVariable[]>;
};

const SequenceLockVariable: React.FC<Props> = ({
  mergeTagsVariable,
  afterSubmit,
  clientId,
  sequenceSnippetsByType,
}) => {
  const {
    profileId,
    refreshMergeTagEditor,
    profile,
    currentSequence,
  } = useCandidateViewContext();
  const {
    profileCustomFields: clientCustomFields,
  } = useClientProfileCustomFields(clientId);
  const { permissions } = useClientPermissions(clientId);

  const isPlugin = useIsPlugin();
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();
  const { t } = useTranslation();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useState(
    mergeTagsVariable.state?.value ||
      mergeTagsVariable.fallbackValue?.text ||
      '',
  );
  const [mode, setMode] = useState<'lock-variable' | 'update-resume-data'>(
    'update-resume-data',
  );

  const [updateContactFlowAction] = useUpdateContactFlowAction();
  const [lockCurrentSequenceVariable] = useLockCurrentSequenceVariable();
  const [
    generateAIVariable,
    { loading: aiGenerationLoading },
  ] = useGenerateAIVariable();
  const [updateResumeDataInSearchPool] = useMutation(
    UPDATE_RESUME_DATA_IN_SEARCH_POOL,
    {
      refetchQueries: [
        {
          query: SEARCH_POOL_PROFILE_WITH_RESUME_DATA_ONLY,
          variables: { searchPoolId: 'reveal', profileId },
        },
      ],
    },
  );

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.focus();
  }, []);

  const updateInputHeight = useCallback(() => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.style.height = '1px';
    inputRef.current.style.height = `${inputRef.current.scrollHeight + 2}px`;
  }, [inputRef]);

  const moveCursorToEndOfInput = useCallback(() => {
    if (!inputRef.current) {
      return;
    }
    const length = inputRef.current.value?.length || 0;
    inputRef.current.setSelectionRange(length, length);
  }, [inputRef]);

  const isVariableWithUpdateResumeDataOption = useMemo(() => {
    return (
      mergeTagsVariable.type === SNIPPET_TYPES.PATH &&
      _.contains(['firstname', 'lastname'], mergeTagsVariable.path[0])
    );
  }, [mergeTagsVariable]);

  const isAIVariable = useMemo(
    () =>
      mergeTagsVariable.type === SNIPPET_TYPES.AI_TOKEN ||
      (mergeTagsVariable.type === SNIPPET_TYPES.PLACEHOLDER &&
        (mergeTagsVariable.name === 'AI' ||
          mergeTagsVariable.name.startsWith('AI : ') ||
          mergeTagsVariable.name.startsWith('AI: '))),
    [mergeTagsVariable],
  );

  const handleGenerateAIVariable = useCallback(async () => {
    await generateAIVariable({
      profileId,
      variableId: mergeTagsVariable.id,
      variableValue: value,
    });
    if (afterSubmit) {
      afterSubmit();
    }
  }, [generateAIVariable, profileId, mergeTagsVariable.id, afterSubmit, value]);

  const clientCustomField: CustomFieldDefinition | null = useMemo(() => {
    if (mergeTagsVariable.type === SNIPPET_TYPES.CUSTOM_FIELD) {
      return (
        (_.findWhere(clientCustomFields || [], {
          id: mergeTagsVariable.clientProfileCustomFieldId,
        }) as CustomFieldDefinition) || null
      );
    }
    return null;
  }, [clientCustomFields, mergeTagsVariable]);

  const handleUpdateProfileCustomFields = useCallback(async () => {
    if (!clientCustomField || !clientCustomFields) {
      return;
    }
    const customDetails = combineDataWithCustomFields(
      clientCustomFields,
      profile?.resumeData || {},
    );
    const customFieldsInitialValues: any = _.object(
      customDetails.map((o) => [o.clientCustomFieldId, o.rawValue]),
    );
    if (clientCustomField.type === 'enum') {
      const updatedOptions: string[] = [];
      _.each(value.split(','), (option) => {
        const optionLabel = _.find(
          clientCustomField?.options || [],
          (cfOption) => getTranslatedText(cfOption.title) === option,
        );
        if (optionLabel) {
          updatedOptions.push(optionLabel.id);
        }
      });
      customFieldsInitialValues[clientCustomField.id] = updatedOptions;
    } else {
      customFieldsInitialValues[clientCustomField.id] = value;
    }

    const customFieldsPayload = createCustomFieldsPayload(
      customFieldsInitialValues,
      clientCustomFields,
    );
    await updateResumeDataInSearchPool({
      variables: {
        searchPoolId: 'reveal',
        input: {
          id: profileId,
          data: {
            customFields: customFieldsPayload,
          },
        },
      },
    });
  }, [
    clientCustomField,
    clientCustomFields,
    profileId,
    profile,
    updateResumeDataInSearchPool,
    value,
  ]);

  const handleUpdateProfileResumeData = useCallback(async () => {
    if (mergeTagsVariable.type !== SNIPPET_TYPES.PATH) {
      return;
    }
    const valueToUpdate = mergeTagsVariable.path[0];
    await updateResumeDataInSearchPool({
      variables: {
        searchPoolId: 'reveal',
        input: {
          id: profileId,
          data: {
            [valueToUpdate]: value,
          },
        },
      },
    });
  }, [mergeTagsVariable, profileId, updateResumeDataInSearchPool, value]);

  const handleCreateMergeTagFromVirtualVariable = useCallback(
    async ({ variable }: { variable: MergeTagsVariable }) => {
      if (!variable.isVirtual || !variable.actionId) {
        return;
      }
      const currentAction = _.findWhere(currentSequence.actions, {
        actionId: variable.actionId,
      });
      if (!currentAction) {
        return;
      }
      const actionSnippets = [
        ...currentAction.snippets,
        {
          ...variable,
          state: {
            value,
            isLocked: true,
          },
        },
      ];
      const actionInput = createActionInputFromAction({
        action: _.omit(
          { ...currentAction, snippets: actionSnippets },
          'indexInSequence',
          'emailIndex',
          'snoozeConfiguration',
        ),
      });
      const input = {
        id: profile.id,
        sequenceId: currentSequence.id,
        actionId: variable.actionId,
        actionInput,
        snippets: MergeTagsService.createSnippetsInput({
          subject: currentAction.message?.subject || '',
          body: currentAction.message?.body || '',
          snippets: actionSnippets,
        }),
      };
      await updateContactFlowAction({
        variables: {
          searchPoolId: 'reveal',
          input: sanitizeTypename(input),
        },
      });
    },
    [currentSequence, updateContactFlowAction, value, profile],
  );

  const handleLockCurrentSequenceVariable = useCallback(async () => {
    await lockCurrentSequenceVariable({
      variables: {
        searchPoolId: 'reveal',
        input: {
          profileId,
          dynamicVariable: {
            id: mergeTagsVariable.id,
            name: mergeTagsVariable.name || '',
            value
          },
        },
      },
    });
    refreshMergeTagEditor();
    publishSubscriptionEvent('onProfileLockCurrentSequenceVariable', {
      profileId,
    });
  }, [
    lockCurrentSequenceVariable,
    mergeTagsVariable,
    profileId,
    publishSubscriptionEvent,
    refreshMergeTagEditor,
    value,
  ]);

  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();
      if (!profileId || !value) {
        return;
      }
      if (afterSubmit) {
        afterSubmit();
      }

      if (mode === 'update-resume-data' && clientCustomField) {
        await handleUpdateProfileCustomFields();
        return;
      }

      if (
        mode === 'update-resume-data' &&
        isVariableWithUpdateResumeDataOption
      ) {
        await handleUpdateProfileResumeData();
        return;
      }

      // If we lock a virtual variable, we want to search for the other virtual variables
      // in the sequence of the same type, create them,
      // then lock other variables of the same type in the whole sequence

      // If we lock an existing variable in the contactFlow, we want to search for the virtual variables
      // in the sequence of the same type, create them then lock them

      if (mergeTagsVariable.isVirtual && mergeTagsVariable.actionId) {
        // Create in contactFlow the virtual variable
        await handleCreateMergeTagFromVirtualVariable({
          variable: mergeTagsVariable,
        });
      }

      const otherSnippetsOfSameType =
        sequenceSnippetsByType?.[mergeTagsVariable.type];

      const virtualSnippetsToCreate = _.filter(
        otherSnippetsOfSameType || [],
        (snippet: MergeTagsVariable) => {
          return (
            (snippet.isVirtual &&
              snippet.id.split('_')[0] ===
                mergeTagsVariable.id.split('_')[0]) ||
            false
          );
        },
      );

      // eslint-disable-next-line no-restricted-syntax
      for (const virtualSnippet of virtualSnippetsToCreate) {
        // eslint-disable-next-line no-await-in-loop
        await handleCreateMergeTagFromVirtualVariable({
          variable: virtualSnippet,
        });
      }

      if (virtualSnippetsToCreate.length) {
        setTimeout(async () => {
          await handleLockCurrentSequenceVariable();
        }, 200);
      } else {
        await handleLockCurrentSequenceVariable();
      }
    },
    [
      afterSubmit,
      clientCustomField,
      handleCreateMergeTagFromVirtualVariable,
      handleLockCurrentSequenceVariable,
      handleUpdateProfileCustomFields,
      handleUpdateProfileResumeData,
      isVariableWithUpdateResumeDataOption,
      mergeTagsVariable,
      mode,
      profileId,
      sequenceSnippetsByType,
      value,
    ],
  );

  const updateTextArea = useCallback(
    (e: any) => {
      if (e.keyCode === 13 && e.shiftKey === false) {
        e.preventDefault();
        handleSubmit(e);
      }
    },
    [handleSubmit],
  );

  const renderForm = useMemo(() => {
    if (clientCustomField?.type === 'enum') {
      return (
        <Dropdown
          fluid
          multiple={clientCustomField.isMultiselect}
          selection
          className={styles.input}
          value={
            Array.isArray(value)
              ? value?.split(',').map((v) => v.trim()) || []
              : value
          }
          onChange={(e, { value: dropdownValues }) => {
            if (Array.isArray(dropdownValues)) {
              setValue(dropdownValues.join(','));
            } else {
              setValue(dropdownValues as string);
            }
          }}
          options={_.map(clientCustomField.options, (option) => ({
            key: getTranslatedText(option.title),
            value: getTranslatedText(option.title),
            text: getTranslatedText(option.title),
          }))}
        />
      );
    }

    if (mergeTagsVariable.type === SNIPPET_TYPES.SELECT) {
      return (
        <Dropdown
          placeholder={mergeTagsVariable.name}
          fluid
          multiple
          selection
          className={styles.input}
          value={value?.split(',') || []}
          onChange={(e, { value: dropdownValues }) => {
            if (Array.isArray(dropdownValues)) {
              setValue(_.compact(dropdownValues).join(','));
            }
          }}
          options={_.map(mergeTagsVariable.options, (option) => ({
            key: option.id,
            value: option.id,
            text: getTranslatedText(option.title),
          }))}
        />
      );
    }

    if (
      mergeTagsVariable.type === SNIPPET_TYPES.AI_TOKEN && 
      !_.isEmpty(mergeTagsVariable.state?.options)
    ) {
     
      return (
        <div
          style={{
            padding: 5,
            maxHeight: '40vh',
            overflowY: 'scroll',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}
        >
          <AiOptionsSelector
            options={mergeTagsVariable?.state?.options ?? []}
            onSelectValue={(newValue) => {
              setValue(newValue);
              setTimeout(() => {
                updateInputHeight();
              }, 100);
            }}
          />
          <textarea
            ref={inputRef}
            rows={1}
            className={styles.input}
            value={value}
            onFocus={() => {
              updateInputHeight();
              moveCursorToEndOfInput();
            }}
            onKeyDown={updateTextArea}
            onChange={(e) => {
              setValue(e.target.value);
              updateInputHeight();
            }}
          />
        </div>
      );
    }

    return (
      <textarea
        ref={inputRef}
        rows={1}
        className={styles.input}
        value={value}
        onFocus={() => {
          updateInputHeight();
          moveCursorToEndOfInput();
        }}
        onKeyDown={updateTextArea}
        onChange={(e) => {
          setValue(e.target.value);
          updateInputHeight();
        }}
      />
    );
  }, [
    mergeTagsVariable,
    clientCustomField,
    value,
    updateInputHeight,
    updateTextArea,
    moveCursorToEndOfInput,
  ]);

  if (aiGenerationLoading) {
    return (
      <div className={styles.sequenceLockPopup}>
        <div className={styles.title}>{mergeTagsVariable.name}</div>
        <Loader active inline='centered' />
      </div>
    );
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className={classNames(
          styles.sequenceLockPopup,
          isPlugin ? styles.sequenceLockPopupPlugin : '',
        )}
      >
        <span className={styles.title}>{mergeTagsVariable.name}</span>

        {renderForm}

        <div
          className={classNames(
            styles.buttons,
            (clientCustomField || isVariableWithUpdateResumeDataOption) &&
              styles.buttonsSpaceBetween,
          )}
        >
          {(clientCustomField || isVariableWithUpdateResumeDataOption) && (
            <GenericToggle
                isChecked={mode === 'update-resume-data'}
                label={t('reveal.mergeTags.sequenceVariables.saveToProfile')}
                name='updateResumeData'
                onChange={() =>
                  setMode((prevState) =>
                    prevState === 'lock-variable'
                      ? 'update-resume-data'
                      : 'lock-variable',
                  )
                }
              />
          )}
          {isAIVariable && permissions?.aiTokenGeneration && (
            <GenericButton
              primacy='secondary'
              onClick={handleGenerateAIVariable}
            >
              {t('reveal.mergeTags.aiGeneration.generate')}
            </GenericButton>
          )}
          <GenericButton
            primacy='primary'
            disabled={!value || !value.length}
            type='submit'
          >
            {t('common.save')}
          </GenericButton>
        </div>
      </form>
    </div>
  );
};

const AiOptionsSelector = ({ 
  options, 
  onSelectValue 
} : {
  options: { value: string }[];
  onSelectValue: (_arg: string) => void;
}) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  return (
    <div >
      {_.map(options || [], (option, index) => (
        <div 
          key={index} 
            style={{
              backgroundColor: '#EDF0FA',
              border: `1px solid ${selectedIndex === index ?  '#1F2E77' : '#DAE0F4'}`, 
              borderRadius: 8,
              padding: 10,
              marginBottom: 8,
              color: '#1F2E77',
              cursor: 'pointer',
            }}
            onClick={() => {
              onSelectValue(option.value);
              setSelectedIndex(index);
            }}
          >
          {option.value}
        </div>
      ))}
    </div>
  )
}

export default SequenceLockVariable;
