import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Modal, Button, Message } from 'semantic-ui-react';

import withUploadProfileAttachment from '../../../hocs/profiles/withUploadProfileAttachment';
import CustomFileInput from '../../CustomFileInput';
import ConfirmationModal from '../ConfirmationModal';
import {
  ATTACHMENT_TYPE_COVER_LETTER,
  ATTACHMENT_TYPE_RESUME,
} from '../../../common/constants/attachments';

const UploadFileModal = ({
  profileId,
  attachments,
  open,
  onClose,
  uploadProfileAttachment,
  fileType,
  t,
}) => {
  const [fileToUpload, setFileToUpload] = useState(null);
  const [importError, setImportError] = useState(null);
  const [importingFile, setImportingFile] = useState(false);
  const [uploadWasSuccessful, setUploadWasSuccessful] = useState(false);
  const [ignoreFileTypeConflict, setIgnoreFileTypeConflict] = useState(false);

  const onCancel = () => {
    setFileToUpload(null);
    setImportError(null);
    setUploadWasSuccessful(false);
    setIgnoreFileTypeConflict(false);
    onClose();
  };

  const onDrop = (files) => {
    const file = (files || [])[0];
    if (file) {
      onChange(file);
    }
  };

  const onChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFileToUpload({
          ...(file.name && { name: file.name }),
          // ...(file.lastModifiedDate && { lastModifiedDate: file.lastModifiedDate }),
          ...(reader.result && { file: { content: reader.result } }),
        });
      };
      reader.readAsDataURL(file);
    }
    setImportError(null);
    setUploadWasSuccessful(false);
  };

  const onUpload = async () => {
    try {
      setImportingFile(true);
      await uploadProfileAttachment({
        attachment: {
          ...fileToUpload,
          type: fileType,
        },
        profileId,
      });
      setUploadWasSuccessful(true);
      setImportError(null);
    } catch (e) {
      console.error('Import failed', e);
      setImportError(e);
      setUploadWasSuccessful(false);
    }
    setIgnoreFileTypeConflict(false);
    setImportingFile(false);
  };

  const canTryUpload = !!fileToUpload?.file?.content;

  const fileTypeConflict =
    (fileType === ATTACHMENT_TYPE_RESUME &&
      _.findWhere(attachments, { type: ATTACHMENT_TYPE_RESUME })) ||
    (fileType === ATTACHMENT_TYPE_COVER_LETTER &&
      _.findWhere(attachments, { type: ATTACHMENT_TYPE_COVER_LETTER }));

  if (fileTypeConflict && !ignoreFileTypeConflict && !fileToUpload) {
    return (
      <ConfirmationModal
        open={open}
        header={t('fileUpload.fileTypeConflict.header')}
        content={t('fileUpload.fileTypeConflict.content')}
        submit={t('fileUpload.fileTypeConflict.overwrite')}
        onSubmit={() => setIgnoreFileTypeConflict(true)}
        onCancel={onCancel}
        t={t}
      />
    );
  }

  return (
    <Modal
      open={open}
      size='small'
      className='import-csv-modal'
      onClose={onCancel}
      closeIcon
    >
      <Modal.Header>{t('fileUpload.title')}</Modal.Header>
      <Modal.Content>
        {!fileToUpload && <CustomFileInput onChange={onDrop} t={t} />}
        {fileToUpload && !uploadWasSuccessful && (
          <>
            <div className='modal-main-description'>
              {t('fileUpload.willImportFile', { fileName: fileToUpload.name })}
            </div>
            {fileTypeConflict && (
              <div className='input-container'>
                <div className='input-error'>
                  {t('fileUpload.fileTypeConflict.content')}
                </div>
              </div>
            )}
          </>
        )}
        {uploadWasSuccessful && (
          <Message className='success'>{t('fileUpload.success')}</Message>
        )}
        {importError && (
          <Message className='error'>{t('fileUpload.error')}</Message>
        )}
      </Modal.Content>
      {canTryUpload && !uploadWasSuccessful ? (
        <Modal.Actions>
          <div className='align-left'>
            <Button className='dismiss' onClick={onCancel}>
              {t('fileUpload.cancel')}
            </Button>
          </div>
          <Button
            primary
            size='big'
            onClick={onUpload}
            disabled={!canTryUpload || importingFile}
            loading={importingFile}
          >
            {t('fileUpload.upload')}
          </Button>
        </Modal.Actions>
      ) : (
        <Modal.Actions>
          <Button primary size='big' onClick={onCancel}>
            {t('fileUpload.close')}
          </Button>
        </Modal.Actions>
      )}
    </Modal>
  );
};

export default compose(
  withUploadProfileAttachment,
  withTranslation('translations'),
)(UploadFileModal);
