import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Table, Icon } from 'semantic-ui-react';
import OfferMappingCreator from './OfferMappingCreator';
import { triggerTexts } from './WorkflowForm';

class JobOfferMapper extends React.PureComponent {
  handleDelete = (workflow) => {
    const { integration, deleteWorkflow } = this.props;
    const { type, clientId, jobOfferId } = (workflow || {}).owner;
    deleteWorkflow({
      clientId,
      integrationId: (integration || {}).id,
      workflowId: (workflow || {}).id,
      workflowOwner: {
        type,
        clientId,
        jobOfferId,
      },
    });
  };

  renderWorkflows = (workflows) => {
    const { t } = this.props;
    if (!workflows || workflows.length === 0) {
      return null;
    }
    return (
      <>
        <div className='offerMapper-workflows-header'>
          &nbsp;&nbsp;
          {t('integrations.yourWorkflows')}
        </div>
        <Table celled className='offerMapper-workflows-table'>
          <Table.Body>
            {workflows.map((w) => this.renderOfferMapping(w))}
          </Table.Body>
        </Table>
      </>
    );
  };

  renderOfferMapping = (workflow) => {
    const { needsRecruiterSelection, needsStageSelection, t } = this.props;
    return (
      <Table.Row key={workflow.id}>
        <Table.Cell className='offerMapping-light-text'>
          {t('integrations.configuration.from')}
        </Table.Cell>
        <Table.Cell>{workflow.owner.jobOfferTitle}</Table.Cell>
        <Table.Cell className='offerMapping-light-text'>
          {t('integrations.configuration.on')}
        </Table.Cell>
        <Table.Cell>{t(triggerTexts[workflow.rule.type])}</Table.Cell>
        <Table.Cell className='offerMapping-light-text'>
          {t('integrations.configuration.to')}
        </Table.Cell>
        <Table.Cell>{workflow.action.target.jobTitle}</Table.Cell>
        {needsStageSelection && (
          <>
            <Table.Cell className='offerMapping-light-text'>
              {t('integrations.configuration.in')}
            </Table.Cell>
            <Table.Cell>{workflow.action.target.stageName}</Table.Cell>
          </>
        )}
        {needsRecruiterSelection && (
          <>
            <Table.Cell className='offerMapping-light-text'>
              {t('integrations.configuration.as')}
            </Table.Cell>
            <Table.Cell>{workflow.action.target.recruiterName}</Table.Cell>
          </>
        )}
        <Table.Cell collapsing>
          <Icon
            className='offerMapping-light-text'
            name='trash alternate outline'
            style={{ cursor: 'pointer' }}
            onClick={() => this.handleDelete(workflow)}
          />
        </Table.Cell>
      </Table.Row>
    );
  };

  render() {
    const {
      clientId,
      integration,
      title,
      offers,
      addWorkflow,
      integrationOffers,
      recruiters,
      needsRecruiterSelection,
      needsStageSelection,
      t,
    } = this.props;

    const workflows = (integration || {}).workflows || [];

    if (!integration || integration.status !== 'active') {
      return (
        <div className='input-container'>
          <div className='input-description'>
            {t('integrations.configuration.notConnected')}
          </div>
        </div>
      );
    }

    const jobOfferWorkflows = _.filter(
      workflows,
      (w) => ((w || {}).owner || {}).type === 'offer',
    );

    return (
      <div>
        {
          <OfferMappingCreator
            title={title}
            clientId={clientId}
            integration={integration}
            offers={offers}
            integrationOffers={integrationOffers}
            recruiters={recruiters}
            addWorkflow={addWorkflow}
            needsRecruiterSelection={needsRecruiterSelection}
            needsStageSelection={needsStageSelection}
          />
        }
        {this.renderWorkflows(jobOfferWorkflows)}
      </div>
    );
  }
}

export default compose(withTranslation('translations'))(JobOfferMapper);
