import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { decodeToken } from '../common';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem('token');
  const decodedJWT = token ? decodeToken(token) : token;
  let user = null;
  if (decodedJWT && decodedJWT.email) {
    user = {
      email: decodedJWT.email,
      clientId: decodedJWT.clientId,
    };
  }
  Sentry.setUser(user);
  return (
    <Route
      {...rest}
      render={(props) =>
        decodedJWT && decodedJWT.clientId === props.match.params.clientId ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: props.match.params.clientId
                ? `/client/${props.match.params.clientId}/signin`
                : '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
