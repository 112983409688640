import React, {
  VFC,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'underscore';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import useProfilesWithLastMessageInfo from '@/graphql/hooks/searchPoolProfile/useProfilesWithLastMessageInfo';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import ProjectDropdown from '@/components/Common/ProjectDropdown/ProjectDropdown';
import GenericButton from '@/components/Common/GenericButton';
import Counter from '@/components/Common/Counter/Counter';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import DropdownControlsContext from '@/context/DropdownControlsContext';
import GenericTextInput from '@/components/Common/GenericTextInput';
import Search from '@/components/Reveal/Icons/Search';

import Conversation from './Conversation';
import ThreadItem from './ThreadItem';

import styles from './InboxView.module.less';

const InboxView: VFC = () => {
  const { t } = useTranslation();
  const [selectedProfileId, setSelectedProfileId] = useState<string>();
  const [projectIdSet, setProjectIdSet] = useState<Record<string, boolean>>({});
  const [profileName, setProfileName] = useState('');
  const { jobOptions } = useSearchPoolJobOptions('reveal', {
    activeOnly: true,
  });

  const selectAll = useCallback(() => {
    const newProjectIdSet: Record<string, boolean> = {};
    _.each(jobOptions, ({ id }) => {
      newProjectIdSet[id] = true;
    });
    setProjectIdSet(newProjectIdSet);
  }, [jobOptions]);

  const unselectAll = useCallback(() => setProjectIdSet({}), []);

  const toggleProjectId = useCallback(
    (id: string) =>
      setProjectIdSet((current) => ({
        ...current,
        [id]: !current[id],
      })),
    [],
  );

  const missionIds = useMemo(
    () => _.compact(_.map(projectIdSet, (value, id) => (value ? id : null))),
    [projectIdSet],
  );

  const [
    fetchProfiles,
    { profilesWithLastMessageInfo },
  ] = useProfilesWithLastMessageInfo({
    queryOptions: {
      fetchPolicy: 'network-only',
    },
  });

  const debouncedFetch = useMemo(() => _.debounce(fetchProfiles, 250), [
    fetchProfiles,
  ]);

  useEffect(() => {
    debouncedFetch({ missionIds, profileName: profileName || undefined });
  }, [debouncedFetch, missionIds, profileName]);

  useLayoutEffect(() => {
    setTimeout(() => {
      const chatHubstop = document.getElementById(
        'hubspot-messages-iframe-container',
      );
      if (!chatHubstop) {
        return;
      }
      chatHubstop.style.visibility = 'hidden';
      chatHubstop.style.pointerEvents = 'none';
    }, 1500);
  }, []);

  return (
    <div className={styles.inboxContainer}>
      <div className={styles.threadsContainer}>
        <div className={styles.threadsHeader}>
          <div className={styles.threadsTitleContainer}>
            <h3 className={styles.threadsTitle}>
              {t('reveal.inbox.threads.title')}
            </h3>
          </div>
          <GenericTextInput
            value={profileName}
            onValue={setProfileName}
            placeholder={t('reveal.inbox.threads.searchPlaceholder')}
            icon={<Search />}
          />
          <ProjectDropdown
            multiSelect
            projects={jobOptions}
            onProjectSelected={({ id }) => toggleProjectId(id)}
            selectedProjectIds={missionIds}
            trigger={<ProjectFilterTrigger selectedCount={missionIds.length} />}
            onSelectAll={selectAll}
            onUnselectAll={unselectAll}
          />
        </div>
        {_.map(profilesWithLastMessageInfo, (profile) => (
          <div
            key={profile.id}
            className={classnames(
              styles.thread,
              profile.id === selectedProfileId && styles.selected,
            )}
            onClick={() => setSelectedProfileId(profile.id)}
          >
            <ThreadItem profile={profile} />
          </div>
        ))}
      </div>
      <div className={styles.chatWindowContainer}>
        {selectedProfileId && <Conversation profileId={selectedProfileId} />}
      </div>
    </div>
  );
};

const ProjectFilterTrigger: VFC<{ selectedCount: number }> = ({
  selectedCount,
}) => {
  const { t } = useTranslation();
  const { toggleDropdown } = useContext(DropdownControlsContext);
  return (
    <GenericButton onClick={toggleDropdown} primacy='tertiary' size='small'>
      {selectedCount === 0 ? (
        t('reveal.inbox.threads.filters.allProjects')
      ) : (
        <>
          {t('reveal.inbox.threads.filters.projects')}
          <Counter value={selectedCount} />
        </>
      )}
      <ArrowDown />
    </GenericButton>
  );
};

export default InboxView;
