import classNames from 'classnames';
import React, { ReactElement } from 'react';
import _ from 'underscore';

import styles from './AnalyticsSubTabs.module.less';

interface AnalyticsSubTabsProps<OptionId extends string> {
  options: {
    id: OptionId;
    name: string;
  }[];
  activeId: OptionId;
  onSelect: (id: OptionId) => void;
}

const AnalyticsSubTabs = <OptionId extends string>({
  options,
  onSelect,
  activeId,
}: AnalyticsSubTabsProps<OptionId>): ReactElement => (
  <div className={styles.tabs}>
    {_.map(options, ({ id, name }) => (
      <button
        type='button'
        className={classNames(styles.tab, id === activeId && styles.active)}
        key={id}
        onClick={() => onSelect(id)}
      >
        {name}
      </button>
    ))}
  </div>
);

export default AnalyticsSubTabs;
