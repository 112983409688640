import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import { PrefixTaskType } from '@/types/statistics/clientStatistics';
import { TASK_ICON_BY_TYPE } from '@/common/constants/taskTypes';
import { getRecordTotal } from '@/common/utils/record';
import { getTaskTypesFromCodeArray } from '../../helpers';
import PrefixStatisticsRow from '../../PrefixStatisticsRow';

import styles from './StepRow.module.less';

interface StepRowProps {
  stepIndex: number;
  taskCodes: string[];
  stats: Record<PrefixTaskType, number>;
  totalContacted: number;
  fetchDetails?: (taskType: PrefixTaskType) => void;
}

const StepRow: FC<StepRowProps> = ({
  stepIndex,
  taskCodes,
  stats,
  totalContacted,
  fetchDetails,
}) => {
  const { t } = useTranslation();

  const taskTypes = getTaskTypesFromCodeArray(taskCodes);

  if (stepIndex === 0 && getRecordTotal(stats) === 0) {
    return null;
  }

  return (
    <PrefixStatisticsRow
      stats={stats}
      fetchDetails={fetchDetails}
      totalContacted={totalContacted}
    >
      <div className={styles.step}>
        <span className={styles.icons}>
          {_.map(
            taskTypes,
            (taskType, index) =>
              taskType && (
                <Fragment key={`${index}_${taskType}`}>
                  {index !== 0 && <span className={styles.separator} />}
                  <img
                    className={styles.icon}
                    src={`/images/icons/tasks/${
                      TASK_ICON_BY_TYPE[
                        taskType as keyof typeof TASK_ICON_BY_TYPE
                      ]
                    }.svg`}
                    alt={t(`sequences.tasks.${taskType}`)}
                    title={t(`sequences.tasks.${taskType}`)}
                  />
                </Fragment>
              ),
          )}
        </span>
        <span className={styles.name}>
          {t('reveal.reports.sequences.step', { step: stepIndex })}
        </span>
      </div>
    </PrefixStatisticsRow>
  );
};

export default StepRow;
