import React, { useEffect, useState } from 'react';
import {
  Input,
  Image,
  Select,
  ModalActions as Actions,
  ModalContent as Content,
  ModalHeader as Header,
  Form,
} from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  CREATE_LINKEDIN_ACCOUNT,
  UPDATE_LINKEDIN_ACCOUNT,
  GET_LINKEDIN_ACCOUNTS,
} from '@/graphql/linkedAccounts';

import { LinkedInAccount } from '@/types/LinkedInAccount';
import { validateEmail } from '@/containers/Auth/validators';
import InfoTooltip from '@/components/Common/InfoTooltip';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import GenericToggle from '@/components/Common/GenericToggle';

import styles from './LinkedinAccountEditorModal.module.less';

interface LinkedinAccountEditorModalProps {
  open: boolean;
  clientId: string;
  account: LinkedInAccount | null;
  closeModal: () => void;
  setAddressToConnect: (address: string) => void;
  setShouldShowConnectModalOnClose: (value: boolean) => void;
}

const LinkedinAccountEditorModal: React.FC<LinkedinAccountEditorModalProps> = ({
  closeModal,
  open,
  account,
  clientId,
  setAddressToConnect,
  setShouldShowConnectModalOnClose,
}) => {
  const { t, i18n } = useTranslation();
  const [formState, setFormState] = useState({
    name: '',
    type: 'linkedin-classic',
    notifiedEmail: '',
    synchronizations: {
      invitationAccepted: {
        enabled: false,
      },
      inMailReceived: {
        enabled: false,
      },
    },
  });

  useEffect(() => {
    if (!account) {
      return;
    }
    setFormState({
      name: account.name,
      type: account.type,
      notifiedEmail: account.notifiedEmail.toLowerCase(),
      synchronizations: {
        invitationAccepted: {
          enabled: account.synchronizations.invitationAccepted.enabled,
        },
        inMailReceived: {
          enabled: account.synchronizations.inMailReceived.enabled,
        },
      },
    });
  }, [account]);

  const [createLinkedinAccount] = useMutation(CREATE_LINKEDIN_ACCOUNT, {
    refetchQueries: [
      {
        query: GET_LINKEDIN_ACCOUNTS,
        variables: {
          clientId,
        },
      },
    ],
  });

  const [updateLinkedinAccount] = useMutation(UPDATE_LINKEDIN_ACCOUNT, {
    refetchQueries: [
      {
        query: GET_LINKEDIN_ACCOUNTS,
        variables: {
          clientId,
        },
      },
    ],
  });

  const submitLinkedinAccount = async () => {
    const { name, type, notifiedEmail, synchronizations } = formState;
    if (account?.id) {
      await updateLinkedinAccount({
        variables: {
          input: {
            id: account.id,
            name,
            type,
            notifiedEmail,
            synchronizations,
          },
        },
      });
    } else {
      await createLinkedinAccount({
        variables: {
          input: {
            name,
            type,
            notifiedEmail,
            synchronizations,
          },
        },
      });
    }
    closeModal();
  };

  const typeOptions = [
    {
      key: 'linkedin-classic',
      value: 'linkedin-classic',
      text: t('settings.linkedinAccounts.linkedin-classic'),
      selected: true,
    },
    {
      key: 'linkedin-recruiter',
      value: 'linkedin-recruiter',
      text: t('settings.linkedinAccounts.linkedin-recruiter'),
    },
  ];

  return (
    <GenericModal
      open={open}
      onClose={closeModal}
      className='new-sender-modal'
      size='small'
    >
      <Header>
        {account?.id
          ? t('settings.linkedinAccounts.updateTitle')
          : t('settings.linkedinAccounts.addTitle')}
      </Header>
      <Content>
        <Form>
          <Form.Field>
            <div className={styles.fieldLabel}>
              {t('settings.linkedinAccounts.name')}
            </div>
            <Input
              value={formState?.name || ''}
              placeholder={t('settings.linkedinAccounts.namePlaceholder')}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: e.target.value,
                })
              }
            />
          </Form.Field>

          <Form.Field>
            <div className={styles.fieldLabel}>
              {t('settings.linkedinAccounts.email')}
              <InfoTooltip rich hoverable position='right center'>
                {t('settings.linkedinAccounts.tooltips.email.firstPart')}&nbsp;
                <a
                  href='https://www.linkedin.com/mypreferences/d/manage-email-addresses'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  {t('settings.linkedinAccounts.tooltips.email.cta')}
                </a>
                {t('settings.linkedinAccounts.tooltips.email.secondPart')}
                &nbsp;
                <a
                  href='https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?access_type=offline&login_hint=116114719929182188139&openid.realm&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.compose%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20%20openid%20profile%20email&origin=https%3A%2F%2Fapp.hiresweet.com&response_type=code%20permission%20id_token&redirect_uri=storagerelay%3A%2F%2Fhttps%2Fapp.hiresweet.com%3Fid%3Dauth424983&ss_domain=https%3A%2F%2Fapp.hiresweet.com&prompt=consent&include_granted_scopes=true&client_id=439166704549-uc6i7s0gh7blevsl8126it20gu4cbe8g.apps.googleusercontent.com&gsiwebsdk=2&hd=hiresweet.com&as=S-66874957%3A1664955877975020&flowName=GeneralOAuthFlow'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  Gmail
                </a>
                &nbsp;
                <a
                  href='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=715174d8-525e-43c5-bb68-5d42bdedcf52&response_type=code&redirect_uri=https://app.hiresweet.com/msal.html&scope=user.read%20mail.readwrite%20mail.send%20openid%20profile%20offline_access&state=59fc9c663fbe3d4d96f05028&response_mode=query'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  Outlook
                </a>
              </InfoTooltip>
            </div>
            <Input
              value={formState?.notifiedEmail || ''}
              placeholder={t('settings.linkedinAccounts.emailPlaceholder')}
              onChange={(e) => {
                setAddressToConnect(e.target.value.toLowerCase());
                setShouldShowConnectModalOnClose(true);
                setFormState({
                  ...formState,
                  notifiedEmail: e.target.value.toLowerCase(),
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <div className={styles.fieldLabel}>
              {t('settings.linkedinAccounts.type')}
            </div>
            <Select
              value={formState?.type || ''}
              options={typeOptions}
              onChange={(_e, data) =>
                setFormState({
                  ...formState,
                  type: data.value as string,
                })
              }
            />
          </Form.Field>
          <div className={styles.fieldLabel}>
            {t('settings.linkedinAccounts.synchronizations')}
            <InfoTooltip rich hoverable position='right center'>
              {t('settings.linkedinAccounts.tooltips.synchronizations')}
              &nbsp;
              <a
                href='https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?access_type=offline&login_hint=116114719929182188139&openid.realm&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.compose%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20%20openid%20profile%20email&origin=https%3A%2F%2Fapp.hiresweet.com&response_type=code%20permission%20id_token&redirect_uri=storagerelay%3A%2F%2Fhttps%2Fapp.hiresweet.com%3Fid%3Dauth424983&ss_domain=https%3A%2F%2Fapp.hiresweet.com&prompt=consent&include_granted_scopes=true&client_id=439166704549-uc6i7s0gh7blevsl8126it20gu4cbe8g.apps.googleusercontent.com&gsiwebsdk=2&hd=hiresweet.com&as=S-66874957%3A1664955877975020&flowName=GeneralOAuthFlow'
                target='_blank'
                rel='noreferrer noopener'
              >
                Gmail
              </a>
              &nbsp;
              <a
                href='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=715174d8-525e-43c5-bb68-5d42bdedcf52&response_type=code&redirect_uri=https://app.hiresweet.com/msal.html&scope=user.read%20mail.readwrite%20mail.send%20openid%20profile%20offline_access&state=59fc9c663fbe3d4d96f05028&response_mode=query'
                target='_blank'
                rel='noreferrer noopener'
              >
                Outlook
              </a>
            </InfoTooltip>
          </div>
          <GenericToggle
            isChecked={
              formState?.synchronizations?.invitationAccepted?.enabled || false
            }
            label={t('settings.linkedinAccounts.acceptInvitations')}
            name='invitationAccepted'
            onChange={({ value }) =>
              setFormState({
                ...formState,
                synchronizations: {
                  ...formState.synchronizations,
                  invitationAccepted: {
                    enabled: value || false,
                  },
                },
              })
            }
          />
          {formState.synchronizations.invitationAccepted.enabled && (
            <div className={styles.infoBox}>
              <div>
                <h4 className={styles.infoBoxTitle}>
                  {t('settings.linkedinAccounts.acceptInvitations')}
                </h4>
                {t(
                  'settings.linkedinAccounts.infoBoxes.acceptInvitations.firstPart',
                )}
                <br />
                <br />
                {t(
                  'settings.linkedinAccounts.infoBoxes.acceptInvitations.secondPart',
                )}
                <br />
                <br />
                {t(
                  'settings.linkedinAccounts.infoBoxes.acceptInvitations.thirdPart',
                )}
                <a
                  href='https://www.linkedin.com/mypreferences/d/notification-subcategories/invitations-to-connect'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  {t(
                    'settings.linkedinAccounts.infoBoxes.acceptInvitations.cta',
                  )}
                </a>
                {t(
                  'settings.linkedinAccounts.infoBoxes.acceptInvitations.fourthPart',
                )}
                <br />
                <br />
                {t(
                  'settings.linkedinAccounts.infoBoxes.acceptInvitations.fifthPart',
                )}
              </div>
              <Image
                className={styles.infoBoxPicture}
                src={`/images/screenshots/linkedin-invitation-accepted-${i18n.resolvedLanguage}.png`}
              />
            </div>
          )}
          <GenericToggle
            isChecked={
              formState?.synchronizations?.inMailReceived?.enabled || false
            }
            label={t('settings.linkedinAccounts.acceptInMail')}
            name='inMailReceived'
            onChange={({ value }) =>
              setFormState({
                ...formState,
                synchronizations: {
                  ...formState.synchronizations,
                  inMailReceived: {
                    enabled: value || false,
                  },
                },
              })
            }
          />
        </Form>
        {formState.synchronizations.inMailReceived.enabled && (
          <div className={styles.infoBox}>
            <div>
              <h4 className={styles.infoBoxTitle}>
                {t('settings.linkedinAccounts.acceptInMail')}
              </h4>
              {t('settings.linkedinAccounts.infoBoxes.mailReveived.firstPart')}
              <br />
              <br />
              {t('settings.linkedinAccounts.infoBoxes.mailReveived.secondPart')}
              <br />
              <br />
              {t('settings.linkedinAccounts.infoBoxes.mailReveived.thirdPart')}
              <a
                href='https://www.linkedin.com/mypreferences/d/notification-subcategories/inmail-reminders'
                target='_blank'
                rel='noreferrer noopener'
              >
                {t('settings.linkedinAccounts.infoBoxes.mailReveived.cta')}
              </a>
              {t('settings.linkedinAccounts.infoBoxes.mailReveived.fourthPart')}
              <br />
              <br />
              {t('settings.linkedinAccounts.infoBoxes.mailReveived.fifthPart')}
            </div>
            <img
              className={styles.infoBoxPicture}
              src={`/images/screenshots/linkedin-inmail-received-${i18n.resolvedLanguage}.png`}
              alt='test'
            />
          </div>
        )}
      </Content>
      <Actions>
        <div className={styles.modalActions}>
          <GenericButton
            onClick={() => {
              setShouldShowConnectModalOnClose(false);
              closeModal();
            }}
            primacy='secondary'
            size='big'
          >
            {t('common.cancel')}
          </GenericButton>
          <GenericButton
            disabled={
              !formState.name ||
              !formState.notifiedEmail ||
              !!validateEmail({ email: formState.notifiedEmail, t })
            }
            size='big'
            onClick={submitLinkedinAccount}
          >
            {account?.id
              ? t('settings.linkedinAccounts.update')
              : t('settings.linkedinAccounts.add')}
          </GenericButton>
        </div>
      </Actions>
    </GenericModal>
  );
};

export default LinkedinAccountEditorModal;
