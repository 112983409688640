import gql from 'graphql-tag';

export default gql`
    fragment DailyEventsStatistics on DailyEventsStatistics {
        day
        nbAnswered
        nbPositiveAnswered
        nbSent
        nbSentWithEmailOpenTracking
        nbOpened
        # nbSentWithLinkClickTracking
        # nbClicked
        nbSkipped
        nbInProcess
        nbHired
    }
`;
