import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SmallIntegration from '../../graphql/fragments/SmallIntegration';
import getOfferWorkflows from './getOfferWorkflows';

export const mutation = gql`
  mutation deleteWelcomekitWorkflow(
    $clientId: ID!
    $integrationId: ID!
    $workflowId: ID!
    $workflowOwner: WorkflowOwnerInput!
  ) {
    deleteWelcomekitWorkflow(
      clientId: $clientId
      integrationId: $integrationId
      workflowId: $workflowId
      workflowOwner: $workflowOwner
    ) {
      ...SmallIntegration
    }
  }
  ${SmallIntegration}
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    deleteWelcomekitWorkflow: ({
      clientId,
      integrationId,
      workflowId,
      workflowOwner,
    }) =>
      mutate({
        variables: {
          clientId,
          integrationId,
          workflowId,
          workflowOwner,
        },
        refetchQueries: [
          {
            query: getOfferWorkflows,
            variables: {
              id: workflowOwner && workflowOwner.jobOfferId,
            },
          },
        ],
      }),
  }),
});
