import React from 'react';
import {
  ATStype,
  ATS_FILTERS_KEY_BY_TYPE,
  AtsFiltersKey,
} from '@/common/reveal';
import DateRangePicker from '../components/DateRangePicker';
import {
  getDateFromTimestamp,
  getTimestampFromDate,
  getCreationDynamicDescription,
  getLastActionDynamicDescription,
} from './utils';

type Filters = {
  [k in AtsFiltersKey]?: any;
};

type OnUpdateFunction = (filters: Filters) => void;

interface DateFilterProps {
  onUpdate: OnUpdateFunction;
  filters: any;
  atsType: ATStype;
}

export const CreationDateFilter: React.FC<DateFilterProps> = ({
  onUpdate,
  filters,
  atsType,
}) => {
  const handleUpdateMinCreationTimestamp = ({
    timestamp,
  }: {
    timestamp: number | null;
  }) => {
    onUpdate({
      [ATS_FILTERS_KEY_BY_TYPE[atsType]]: {
        ...filters,
        minCreationTimestamp: timestamp,
      },
    });
  };

  const handleUpdateMinCreationDelay = ({
    delay,
  }: {
    delay: number | null;
  }) => {
    onUpdate({
      [ATS_FILTERS_KEY_BY_TYPE[atsType]]: {
        ...filters,
        minCreationDelayInMs: delay,
      },
    });
  };

  const handleUpdateMaxCreationTimestamp = ({
    timestamp,
  }: {
    timestamp: number | null;
  }) => {
    onUpdate({
      [ATS_FILTERS_KEY_BY_TYPE[atsType]]: {
        ...filters,
        maxCreationTimestamp: timestamp,
      },
    });
  };

  const handleUpdateMaxCreationDelay = ({
    delay,
  }: {
    delay: number | null;
  }) => {
    onUpdate({
      [ATS_FILTERS_KEY_BY_TYPE[atsType]]: {
        ...filters,
        maxCreationDelayInMs: delay,
      },
    });
  };

  const handleResetMinCreationDate = () => {
    onUpdate({
      [ATS_FILTERS_KEY_BY_TYPE[atsType]]: {
        ...filters,
        minCreationDelayInMs: null,
        minCreationTimestamp: null,
      },
    });
  };

  const handleResetMaxCreationDate = () => {
    onUpdate({
      [ATS_FILTERS_KEY_BY_TYPE[atsType]]: {
        ...filters,
        maxCreationDelayInMs: null,
        maxCreationTimestamp: null,
      },
    });
  };

  const handleResetAllCreationDate = () => {
    onUpdate({
      [ATS_FILTERS_KEY_BY_TYPE[atsType]]: {
        ...filters,
        minCreationDelayInMs: null,
        minCreationTimestamp: null,
        maxCreationDelayInMs: null,
        maxCreationTimestamp: null,
      },
    });
  };

  return (
    <>
      <DateRangePicker
        minDate={getDateFromTimestamp(filters?.minCreationTimestamp)}
        maxDate={getDateFromTimestamp(filters?.maxCreationTimestamp)}
        onUpdateMinDate={({ date }: { date: Date | null }) =>
          handleUpdateMinCreationTimestamp({
            timestamp: getTimestampFromDate(date),
          })
        }
        onUpdateMaxDate={({ date }: { date: Date | null }) =>
          handleUpdateMaxCreationTimestamp({
            timestamp: getTimestampFromDate(date),
          })
        }
        minDelay={filters?.minCreationDelayInMs}
        maxDelay={filters?.maxCreationDelayInMs}
        onUpdateMinDelay={handleUpdateMinCreationDelay}
        onUpdateMaxDelay={handleUpdateMaxCreationDelay}
        dynamicDescription={getCreationDynamicDescription(
          filters?.minCreationDelayInMs,
          filters?.maxCreationDelayInMs,
        )}
        onResetMin={handleResetMinCreationDate}
        onResetMax={handleResetMaxCreationDate}
        onResetAll={handleResetAllCreationDate}
      />
    </>
  );
};

export const LastActionDateFilter: React.FC<DateFilterProps> = ({
  onUpdate,
  filters,
  atsType,
}) => {
  const handleUpdateMinLastActionTimestamp = ({
    timestamp,
  }: {
    timestamp: number | null;
  }) => {
    onUpdate({
      [ATS_FILTERS_KEY_BY_TYPE[atsType]]: {
        ...filters,
        minLastActionTimestamp: timestamp,
      },
    });
  };

  const handleUpdateMinLastActionDelay = ({
    delay,
  }: {
    delay: number | null;
  }) => {
    onUpdate({
      [ATS_FILTERS_KEY_BY_TYPE[atsType]]: {
        ...filters,
        minLastActionDelayInMs: delay,
      },
    });
  };

  const handleUpdateMaxLastActionTimestamp = ({
    timestamp,
  }: {
    timestamp: number | null;
  }) => {
    onUpdate({
      [ATS_FILTERS_KEY_BY_TYPE[atsType]]: {
        ...filters,
        maxLastActionTimestamp: timestamp,
      },
    });
  };

  const handleUpdateMaxLastActionDelay = ({
    delay,
  }: {
    delay: number | null;
  }) => {
    onUpdate({
      [ATS_FILTERS_KEY_BY_TYPE[atsType]]: {
        ...filters,
        maxLastActionDelayInMs: delay,
      },
    });
  };

  const handleResetMinLastAction = () => {
    onUpdate({
      [ATS_FILTERS_KEY_BY_TYPE[atsType]]: {
        ...filters,
        minLastActionDelayInMs: null,
        minLastActionTimestamp: null,
      },
    });
  };

  const handleResetMaxLastAction = () => {
    onUpdate({
      [ATS_FILTERS_KEY_BY_TYPE[atsType]]: {
        ...filters,
        maxLastActionDelayInMs: null,
        maxLastActionTimestamp: null,
      },
    });
  };

  const handleResetAllLastAction = () => {
    onUpdate({
      [ATS_FILTERS_KEY_BY_TYPE[atsType]]: {
        ...filters,
        minLastActionDelayInMs: null,
        minLastActionTimestamp: null,
        maxLastActionDelayInMs: null,
        maxLastActionTimestamp: null,
      },
    });
  };

  return (
    <DateRangePicker
      minDate={getDateFromTimestamp(filters?.minLastActionTimestamp)}
      maxDate={getDateFromTimestamp(filters?.maxLastActionTimestamp)}
      onUpdateMinDate={({ date }: { date: Date | null }) =>
        handleUpdateMinLastActionTimestamp({
          timestamp: getTimestampFromDate(date),
        })
      }
      onUpdateMaxDate={({ date }: { date: Date | null }) =>
        handleUpdateMaxLastActionTimestamp({
          timestamp: getTimestampFromDate(date),
        })
      }
      minDelay={filters?.minLastActionDelayInMs}
      maxDelay={filters?.maxLastActionDelayInMs}
      onUpdateMinDelay={handleUpdateMinLastActionDelay}
      onUpdateMaxDelay={handleUpdateMaxLastActionDelay}
      dynamicDescription={getLastActionDynamicDescription(
        filters?.minLastActionDelayInMs,
        filters?.maxLastActionDelayInMs,
      )}
      onResetMin={handleResetMinLastAction}
      onResetMax={handleResetMaxLastAction}
      onResetAll={handleResetAllLastAction}
    />
  );
};
