import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';

import withClientActivity from '../../../hocs/clients/withClientActivity';
import withNotifications from '../../../hocs/clients/withNotifications';
import { withLastProfileActionsContextConsumer } from '../../../context/LastProfileActionsContext';

import LabelDetails from '../../../components/LabelDetails';
import NotificationsTimeline from './NotificationsTimeline';

const NotificationsDashboard = ({
  clientId,
  notifications: notificationsData,
  notificationsLoading,
  clientActivity,
  clientActivityLoading,
  lastProfileActions,
  addProfileAction,
  t,
}) => {
  const notifications = (notificationsData || {}).notSeen || [];

  if (
    notificationsLoading ||
    clientActivityLoading ||
    !_.isArray(lastProfileActions)
  ) {
    return (
      <div className='notifications dashboard-section'>
        <div className='header'>
          <h1>{t('dashboard.notifications.header')}</h1>
        </div>
        <div className='notifications-table-placeholder'>
          <Loader active inline='centered' size='large' />
        </div>
      </div>
    );
  }

  const hasPositiveAnswerEvent = !!_.findWhere(clientActivity?.events, {
    subtype: 'positive-answer-detected',
  });

  const profileWithRecentActions = _.compact(
    _.pluck(lastProfileActions, 'profileId'),
  );
  const hasRecentActions = _.object(
    profileWithRecentActions,
    _.map(profileWithRecentActions, (x) => true),
  );

  const filteredNotifications = _.filter(notifications, (notification) => {
    if (notification?.profile?.id) {
      if (hasRecentActions[notification?.profile?.id]) {
        return false;
      }
    }
    return true;
  });

  if (_.isEmpty(filteredNotifications)) {
    return (
      <div className='notifications dashboard-section'>
        <div className='header'>
          <h1>
            {t('dashboard.notifications.header')}
            <span className='details'>
              <LabelDetails text={t('dashboard.notifications.details')} />
            </span>
          </h1>
        </div>
        <div className='no-items-container'>
          <img
            src='/images/placeholders/notifications.svg'
            height='155'
            alt='notifications-placeholder'
          />
          <div className='no-items'>
            {hasPositiveAnswerEvent
              ? t('dashboard.notifications.empty')
              : t('dashboard.notifications.empty-no-past-positive-answers')}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='notifications dashboard-section'>
      <div className='header'>
        <h1>
          {t('dashboard.notifications.header')} ({filteredNotifications.length})
          <LabelDetails text={t('dashboard.notifications.details')} />
        </h1>
      </div>
      <NotificationsTimeline
        clientId={clientId}
        notifications={filteredNotifications}
        addProfileAction={addProfileAction}
      />
    </div>
  );
};

export default compose(
  withClientActivity,
  withNotifications,
  withLastProfileActionsContextConsumer,
  withTranslation('translations'),
)(NotificationsDashboard);
