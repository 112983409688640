import * as Sentry from '@sentry/browser';
import { openPopup, sleep } from '../helpers';

const CRM_WELCOMEKIT_CLIENT_ID = process.env.REACT_APP_CRM_WELCOMEKIT_CLIENT_ID;
const REDIRECT_URI = `${window.location.origin}/welcomekit-callback-crm.html`;
const WELCOMEKIT_POPUP = { width: 400, height: 770 };

const BASE_URL = 'https://www.welcomekit.co/oauth/authorize?';
const scopes = [
  'me_r',
  'organizations_r',
  'departments_r',
  'offices_r',
  'candidates_rw',
  'documents_rw',
  'comments_w',
  'jobs_r',
  'emails_rw',
];
const params = [
  `response_type=code`,
  `client_id=${CRM_WELCOMEKIT_CLIENT_ID}`,
  `redirect_uri=${REDIRECT_URI}`,
  `scope=${scopes.join('+')}`,
];

const AUTHORIZATION_URL = BASE_URL + params.join('&');

let detectedAuthorizationCode = '';

const receiveAuthorizationCode = (event: any) => {
  const originCheck = /localhost:8070$|hiresweet\.com$/g;
  if (
    typeof event.data === 'string' &&
    event.data.startsWith('ats-authorization-code-') &&
    originCheck.test(event.origin)
  ) {
    const popupUrl = new URL(event.data.replace('ats-authorization-code-', ''));
    detectedAuthorizationCode = popupUrl.searchParams.get('code') || '';
  }
};

window.addEventListener('message', receiveAuthorizationCode);

const pollDetectAuthorizationCode = async (
  nbTrials: number,
  popup: Window | null,
): Promise<{ token: string } | undefined> => {
  if (nbTrials > 0) {
    if (detectedAuthorizationCode) {
      const result = { token: detectedAuthorizationCode };
      // reset code
      detectedAuthorizationCode = '';
      return result;
    }
    if (popup && popup.closed) {
      return undefined;
    }
    await sleep(250);
    return pollDetectAuthorizationCode(nbTrials - 1, popup);
  }
  return undefined;
};

/**
 * Constructor which returns a function that will execute an OAuth flow and run onAuthorizedCallback
 * when the authorization token is successfully retrieved.
 */
const authorizeWithOAuth = ({
  onAuthorizedCallback,
}: {
  onAuthorizedCallback: ({ token }: { token: string }) => Promise<void>;
}) => {
  /**
   * opens a popup to ask the user to allow an OAuth2 integration
   * @returns Promise <Object: {success: boolean, error: object}>
   */
  return async (): Promise<{ success: boolean; error?: Error | unknown }> => {
    try {
      // reset code
      detectedAuthorizationCode = '';
      // open child window that will use window.opener.postMessage
      // to send event back to current 'opener' window
      const offlineGrantPopup = openPopup(
        AUTHORIZATION_URL,
        '',
        WELCOMEKIT_POPUP.height,
        WELCOMEKIT_POPUP.width,
      );
      // start polling for validation (during 5 minutes max.)
      const pollResult = await pollDetectAuthorizationCode(
        (5 * 60 * 1000) / 250,
        offlineGrantPopup,
      );
      if (offlineGrantPopup) {
        // close pop-up
        offlineGrantPopup.close();
      }

      const { token } = pollResult || {};
      if (!token) {
        return {
          success: false,
          error: Error('user closed popup manually or popup timed out'),
        };
      }

      await onAuthorizedCallback({ token });

      return {
        success: true,
      };
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setTags({ feature: 'welcomekit-integration' });
        Sentry.captureException(e);
      });
      return {
        success: false,
        error: e,
      };
    }
  };
};

const welcomekit = {
  authorizeWithOAuth,
};

export default welcomekit;
