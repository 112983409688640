import React, { VFC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useUserFromJWToken from '@/graphql/hooks/users/useUserFromJWToken';
import useChangeProfileStep from '@/graphql/hooks/profiles/useChangeProfileStep';

import styles from './SubStepSwitcher.module.less';

type Props = {
  profileId: string;
  stepId: string;
  subStepId?: string;
};

const SubStepSwitcher: VFC<Props> = ({ profileId, stepId, subStepId }) => {
  const { t } = useTranslation();
  const { data: userData } = useUserFromJWToken();
  const [changeProfileStep] = useChangeProfileStep({
    mutationOptions: {
      onCompleted: () => {
        window.location.reload();
      },
    },
  });

  const switchSubStep = useMemo(
    () =>
      subStepId === 'pending-extended'
        ? () =>
            changeProfileStep({
              step: 'pending',
              subStep: 'pending-main',
              id: profileId,
            })
        : () =>
            changeProfileStep({
              step: 'pending',
              subStep: 'pending-extended',
              id: profileId,
            }),
    [subStepId, changeProfileStep, profileId],
  );

  const text = useMemo(
    () =>
      subStepId === 'pending-extended'
        ? t('profile.steps.pipe.move-to-pending-main')
        : t('profile.steps.pipe.move-to-pending-extended'),
    [subStepId, t],
  );

  if (!userData?.user?.isAdmin) {
    return null;
  }
  if (stepId !== 'pending') {
    return null;
  }

  return (
    <button type='button' onClick={switchSubStep} className={styles.button}>
      {text}
    </button>
  );
};

export default SubStepSwitcher;
