import _ from 'underscore';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { LEVER } from '@/common/reveal';
import useAtsFilterOptions from '../../../../graphql/hooks/clients/useClientAtsFiltersOptions';

import { UPLOAD_TO_ATS_JOB } from '../../../../graphql/searchPoolProfile';
import { getJobOptions, getStageOptions } from './AddToATSJobModal';

import contextToProps from '../../../../hocs/contextToProps';

import styles from './AddToATSJobModal.module.less';

const AddToLeverJobModal = ({
  clientId,
  searchPoolId,
  mainMissionId,
  profileId,
  onShowNotification,
  onClose,
}) => {
  const { formState, formOptions, onSubmit } = useAddToLeverJobModalFormState({
    clientId,
    searchPoolId,
    mainMissionId,
    profileId,
    onShowNotification,
    onClose,
  });
  return (
    <Modal
      className={classNames('add-to-lever-job-modal', styles.atsModal)}
      open
      onClose={onClose}
      size='tiny'
    >
      <Modal.Header>Add contact to Lever?</Modal.Header>
      <Modal.Content>
        <Form loading={formState.submitting}>
          <Form.Dropdown
            selection
            search
            label='Lever job'
            options={formOptions.jobs}
            value={formState.jobId}
            onChange={(e, { value }) => formState.setJobId(value)}
            loading={formState.jobsLoading}
          />

          <Form.Dropdown
            selection
            search
            label='Job stage'
            options={formOptions.stages}
            value={formState.stageId}
            onChange={(e, { value }) => formState.setStageId(value)}
          />
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <div className='align-left'>
          <Button
            content='Cancel'
            onClick={onClose}
            className='dismiss'
            disabled={formState.submitting}
          />
        </div>

        <Button
          primary
          content='Add'
          size='big'
          disabled={formState.disabled}
          type='button'
          onClick={onSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};

const useAddToLeverJobModalFormState = ({
  clientId,
  searchPoolId,
  mainMissionId,
  profileId,
  onShowNotification,
  onClose,
}) => {
  const [jobId, setJobId] = useState(mainMissionId);
  const [stageId, setStageId] = useState();
  const [submitting, setSubmitting] = useState();
  const { t } = useTranslation();

  const {
    data: atsFilterOptionsData,
    loading: jobsLoading,
  } = useAtsFilterOptions(clientId, { fetchPolicy: 'network-only' });
  const jobOptions = getJobOptions({ atsFilterOptionsData });
  const stageOptions = getStageOptions({
    atsFilterOptionsData,
    ats: LEVER,
  });
  const [uploadToATSJob] = useMutation(UPLOAD_TO_ATS_JOB);

  useEffect(() => {
    setStageId(null);
  }, [jobId]);

  useEffect(() => {
    if (!stageId && !_.isEmpty(stageOptions)) {
      setStageId(stageOptions[0]?.value);
    }
  }, [stageId, stageOptions]);

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      const variables = {
        searchPoolId,
        input: {
          sourceJobId: jobId,
          sourceStageId: stageId,
          profileId,
        },
      };

      await uploadToATSJob({ variables });

      onShowNotification({
        message: t('reveal.addToATS.success'),
        level: 'success',
      });

      onClose();
    } catch (e) {
      onShowNotification({
        message: t('reveal.addToATS.error'),
        level: 'error',
      });
    }
    setSubmitting(false);
  };

  const formState = {
    jobId,
    setJobId,
    jobsLoading,
    stageId,
    setStageId,
    disabled: !jobId || !stageId || submitting,
    submitting,
  };

  const formOptions = {
    jobs: jobOptions,
    stages: stageOptions,
  };

  return {
    formState,
    formOptions,
    onSubmit,
  };
};

export default contextToProps(AddToLeverJobModal);
