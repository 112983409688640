import React, {
  FC,
  useState,
  ReactNode,
  useCallback,
  SetStateAction,
} from 'react';
import { createPortal } from 'react-dom';
import _ from 'underscore';

import Pagination from '@/components/Pagination';

import Actions from './Actions';
import DefaultHeader from './DefaultHeader';

import styles from './OnboardingModal.module.less';

type OnboardingModalStep = {
  illustration: FC;
  content: FC<{ close: () => void }>;
};

type OnboardingModalProps = {
  steps: OnboardingModalStep[];
  onSubmit?: () => void | Promise<void>;
  close: () => void;
  header?: FC;
  submitCta?: ReactNode;
};

const OnboardingModal: FC<OnboardingModalProps> = ({
  steps,
  onSubmit,
  close,
  header: Header = DefaultHeader,
  submitCta,
}) => {
  const [step, innerSetStep] = useState(0);
  const nbSteps = steps.length;

  const setStep = useCallback(
    (action: SetStateAction<number>) => {
      innerSetStep((current) => {
        const newStep = typeof action === 'function' ? action(current) : action;

        if (newStep === -1) {
          close();
        }
        if (newStep === nbSteps) {
          if (onSubmit) {
            onSubmit();
          }
          close();
        }

        return newStep;
      });
    },
    [close, onSubmit, nbSteps],
  );

  const next = () => setStep((current) => current + 1);

  const previous = () => setStep((current) => current - 1);

  const { illustration: CurrentIllustration, content: CurrentContent } = steps[
    step
  ];

  return createPortal(
    <div className={styles.container} onClick={close}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div className={styles.layout}>
          <div className={styles.illustration}>
            <CurrentIllustration />
          </div>

          <div className={styles.right}>
            <div className={styles.content}>
              <div className={styles.header}>
                <Header />
              </div>
              <CurrentContent close={close} />
            </div>
            <div className={styles.footer}>
              <Pagination step={step} nbSteps={nbSteps} />
              <Actions
                step={step}
                nbSteps={nbSteps}
                close={close}
                next={next}
                previous={previous}
                submitCta={submitCta}
              />
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default OnboardingModal;
