import React, { VFC } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import TargetPill from './TargetPill';

import styles from './TargetsSummary.module.less';

type Props = {
  targets: {
    type: string;
    targetId: string;
    name: string;
  }[];
};

const TargetsSummary: VFC<Props> = ({ targets }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.summary}>
      <span className={styles.title}>
        {t('reveal.timeline.activityLog.sharedWith')}
      </span>
      {_.map(targets, ({ type, targetId, name }) => (
        <TargetPill
          key={`${type}_${targetId}`}
          type={type}
          targetId={targetId}
          name={name}
        />
      ))}
    </div>
  );
};

export default TargetsSummary;
