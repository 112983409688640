import _ from 'underscore';
import { useMemo, useState } from 'react';
import useUserSenders from '@/graphql/hooks/users/useUserSenders';
import useEmailApi from '@/hooks/common/useEmailApi';
import useLocalStorage from '@/hooks/storage/useLocalStorage';
import { textToId } from '@/common';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import { findLastSendEmailCompletedAction, findLastThread } from './utils';

export enum RevealSenderType {
  // front-end send via gmail or outlook
  Classic = 'classic',
  // send on behalf of
  Sobo = 'sobo',
}

const useRevealEmailAction = (
  profile?: SearchPoolProfile,
  {
    forceRevealSender,
    initialRevealSender,
  }: {
    forceRevealSender?: RevealSender | null;
    initialRevealSender?: RevealSender;
  } = {},
) => {
  const { data: userSendersData, loading: userSendersLoading } = useUserSenders(
    { granted: true, owned: true },
  );
  const emailApi = useEmailApi();

  const senders = userSendersData?.user?.senders;

  const [storedRevealSender, setRevealSender] =
    useRevealSenderStorage(initialRevealSender);

  const [currentSender, setCurrentSender] = useState(
    initialRevealSender || storedRevealSender,
  );

  const revealSender = useMemo(() => {
    return forceRevealSender || currentSender;
  }, [forceRevealSender, currentSender]);

  const [sendInTargetThread, setSendInTargetThread] = useState(true);

  const setClassicSender = () => {
    setRevealSender({ type: RevealSenderType.Classic });
    setCurrentSender({ type: RevealSenderType.Classic });
  };

  const setSoboSender = (senderId: string) => {
    setRevealSender({ type: RevealSenderType.Sobo, senderId });
    setCurrentSender({ type: RevealSenderType.Sobo, senderId });
  };

  const canChooseEmitter = !_.isEmpty(userSendersData?.user?.senders);
  const loading = emailApi.connectionInProgress || userSendersLoading;

  const assignedSender =
    revealSender.type === RevealSenderType.Sobo
      ? _.find(senders, { id: revealSender.senderId })
      : null;

  const emitter =
    revealSender.type === RevealSenderType.Classic
      ? emailApi.currentAddress
      : assignedSender?.senderAddress;

  const targetThread = findLastThread({
    threads: profile?.contactData?.threads || [],
  });

  const sendInTargetThreadPossible =
    targetThread?.mailAccountId === textToId(emitter);

  const lastEmailAction = findLastSendEmailCompletedAction({
    contactFlow: profile?.contactFlow,
  });

  const lastSubject = lastEmailAction?.message?.subject;
  const lastCc = lastEmailAction?.message?.cc;
  const lastBcc = lastEmailAction?.message?.bcc;

  return {
    canChooseEmitter,
    emitter: emitter || emailApi.currentAddress,
    revealSenderType: revealSender.type,
    setClassicSender,
    setSoboSender,
    loading,
    senders,
    emailApi,
    assignedSender,
    ...(targetThread && {
      targetThread: {
        ...targetThread,
        subject: lastSubject,
        cc: lastCc,
        bcc: lastBcc,
      },
    }),
    sendInTargetThreadPossible,
    sendInTargetThread,
    setSendInTargetThread,
    lastSubject,
  };
};

export type RevealSender =
  | {
      type: RevealSenderType.Sobo;
      senderId: string;
    }
  | {
      type: RevealSenderType.Classic;
    };

export const useRevealSenderStorage = (initialRevealSender?: RevealSender) =>
  useLocalStorage<RevealSender>(
    'revealSender',
    initialRevealSender || {
      type: RevealSenderType.Classic,
    },
  );

export default useRevealEmailAction;
