import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import { ConnectPage } from '@/types/connectPage';
import { GET_CONNECT_PAGES, DELETE_CONNECT_PAGE } from '@/graphql/connectPages';

interface DeleteConnectPageModalProps {
  open: boolean;
  clientId: string;
  connectPage: ConnectPage | null | undefined;
  closeModal: () => void;
}
const DeleteConnectPageModal: React.FC<DeleteConnectPageModalProps> = ({
  open,
  clientId,
  connectPage,
  closeModal,
}) => {
  const { t } = useTranslation();

  const [deleteConnectPage] = useMutation(DELETE_CONNECT_PAGE, {
    refetchQueries: [
      {
        query: GET_CONNECT_PAGES,
        variables: {
          clientId,
        },
      },
    ],
  });

  if (!connectPage) return null;
  const onDelete = () => {
    deleteConnectPage({
      variables: { input: { id: connectPage.id } },
    }).then(closeModal);
  };
  return (
    <ConfirmationModal
      header={t('settings.connectSettings.connectPages.deletionModal.title')}
      onCancel={closeModal}
      onSubmit={onDelete}
      validationType='negative'
      open={open}
    />
  );
};

export default DeleteConnectPageModal;
