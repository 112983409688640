import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import ContactFlow from '../../graphql/fragments/ContactFlow';

export const removeAction = gql`
  mutation removeAction(
    $jobOfferId: ID
    $contactFlowId: ID!
    $sequenceId: ID!
    $actionId: ID!
  ) {
    removeAction(
      jobOfferId: $jobOfferId
      contactFlowId: $contactFlowId
      sequenceId: $sequenceId
      actionId: $actionId
    ) {
      ...ContactFlow
    }
  }
  ${ContactFlow}
`;

export default graphql(removeAction, {
  props: ({ ownProps, mutate }) => ({
    removeAction: ({ contactFlowId, sequenceId, actionId }) =>
      mutate({
        variables: {
          jobOfferId: ownProps.offerId,
          contactFlowId,
          sequenceId,
          actionId,
        },
      }),
  }),
});
