import { ClientCustomActivity } from '@/graphql/hooks/clients/useClientCustomActivities';
import {
  ClientCustomAction,
  CustomActionExecution,
} from '@/types/customAction';

function vaildateExecution(execution: CustomActionExecution) {
  if (execution.type === 'http-call' && !execution.url) {
    return false;
  }
  if (
    execution.type === 'phantombuster' &&
    (!execution.phantomServiceId || !execution.phantomAgentId)
  ) {
    return false;
  }
  return true;
}

export function validateCustomAction(
  customAction?: ClientCustomAction | ClientCustomActivity,
  type?: 'tasks' | 'activities',
): boolean {
  if (!customAction) {
    return false;
  }
  if (type === 'activities') {
    return true;
  }
  return vaildateExecution((customAction as ClientCustomAction).execution);
}
