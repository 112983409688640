import React from 'react';
import { Loader } from 'semantic-ui-react';
import _ from 'underscore';
import NewProfileRow from '@/routes/RevealView/ProfileRow';

import './CampaignProfilesPage.css';

const CampaignProfilesPage = ({
  clientId,
  campaignId,
  candidateSelection,
  profiles,
  onClickRow,
  loading,
}) => {
  const selectOneProfileHandler = (profile) => (e) => {
    if (e?.stopPropagation) {
      e.stopPropagation();
    }
    if (candidateSelection.isSelected({ profileId: profile?.id })) {
      candidateSelection.unselect(profile);
      return;
    }
    candidateSelection.select(profile);
  };

  const handleRangeSelect = (profile) => {
    candidateSelection.handleRangeSelect({ profileId: profile.id });
  };

  return (
    <div className='campaign-profiles-page'>
      {loading ? (
        <Loading />
      ) : (
        _.map(profiles, (profile) => (
          <div className='campaign-profiles-page__row' key={profile.id}>
            <NewProfileRow
              clientId={clientId}
              searchPoolId='reveal'
              profileId={profile.id}
              campaignId={campaignId}
              onClick={() => onClickRow({ profileId: profile?.id })}
              isSelected={candidateSelection.isSelected({
                profileId: profile?.id,
              })}
              onToggleCheckbox={selectOneProfileHandler(profile)}
              onRangeSelect={handleRangeSelect}
              showMissions
              showSequence={false}
              showRelevance={false}
              showCampaignState
            />
          </div>
        ))
      )}
    </div>
  );
};

const Loading = () => (
  <div className='loading-placeholder'>
    <Loader active inline='centered' size='large' />
  </div>
);

export default CampaignProfilesPage;
