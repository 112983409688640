import React from 'react';
import { Redirect } from 'react-router-dom';
import { decodeToken } from '../common';
import SignupForm from '../containers/Auth/SignupForm';

class SignupView extends React.PureComponent {
  render() {
    const { match } = this.props;

    const token = localStorage.getItem('token');
    const decodedJWT = token ? decodeToken(token) : token;

    if (decodedJWT && decodedJWT.clientId === match.params.clientId) {
      return <Redirect to={`/client/${match.params.clientId}/dashboard`} />;
    }

    if (decodedJWT && decodedJWT.clientId !== match.params.clientId) {
      localStorage.removeItem('token');
    }

    return (
      <div>
        <SignupForm
          clientId={match.params.clientId}
          redirectTo={`/client/${match.params.clientId}/dashboard`}
        />
      </div>
    );
  }
}

export default SignupView;
