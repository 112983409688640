import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { Author } from '@/types/author';
import ProfileEnrichmentResultData from '@/graphql/fragments/ProfileEnrichmentResultData';

export const AskProfileEnrichmentMutation = gql`
  mutation AskProfileEnrichment(
    $profileId: ID!
    $searchPoolId: ID!
    $provider: String!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      askProfileEnrichment(profileId: $profileId, provider: $provider) {
        profile {
          id
          resumeData {
            sourceData {
              contactout {
                ...ProfileEnrichmentResultData
              }
              kaspr {
                ...ProfileEnrichmentResultData
              }
              rocketreach {
                ...ProfileEnrichmentResultData
              }
            }
          }
        }
      }
    }
  }
  ${ProfileEnrichmentResultData}
`;

type AskProfileEnrichmentVariables = {
  searchPoolId: string;
  profileId: string;
  provider: string;
};

type AskProfileEnrichmentResult = {
  data: {
    searchPoolProfile: {
      askProfileEnrichment: {
        profile: {
          id: string;
          resumeData: {
            sourceData: {
              contactout: {
                enrichmentResults: ProfileEnrichmentResults[];
              };
            };
          };
        };
      };
    };
  };
};

export type ProfileEnrichmentResults = {
  date: string;
  author: Author;
  phoneNumbers: { type: string; value: string }[];
  emails: { type: string; value: string }[];
};
export default function useAskProfileEnrichment() {
  return useMutation<AskProfileEnrichmentResult, AskProfileEnrichmentVariables>(
    AskProfileEnrichmentMutation,
    {
      refetchQueries: ['getUserEnrichmentIntegrations'],
    },
  );
}
