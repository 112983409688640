/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import {
  getLimitedText,
  stripHtmlFromTextAndLinesBreaks,
} from '@/common/utils/string';
import styles from './ConditionsChaining.module.less';
import { getStatementTextMode } from './utils';

type Props = {
  output: string;
};

const ConditionsChainingPreviewOutput: React.FC<Props> = ({ output }) => {
  const textMode = getStatementTextMode(output);
  const text =
    textMode === 'basic-input'
      ? stripHtmlFromTextAndLinesBreaks(output)
      : output;

  const textToDisplay = getLimitedText(text, 200);

  return (
    <div className={styles.previewOutput}>
      {output && (
        <>
          <i className='ri-double-quotes-l' />
          {textMode === 'rich-editor' ? (
            <span
              dangerouslySetInnerHTML={{
                __html: textToDisplay,
              }}
            />
          ) : (
            <span>{textToDisplay}</span>
          )}
        </>
      )}
    </div>
  );
};

export default ConditionsChainingPreviewOutput;
