import React from 'react';

const LaneHeaderComponent = ({ content }) => {
  return (
    <div className='lane-header-component'>
      {content}
    </div>
  );
};

export default LaneHeaderComponent;
