import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const mutation = gql`
  mutation saveCommentDraft($profileId: ID!, $content: String!) {
    setProfileCommentDraft(profileId: $profileId, input: $content) {
      id
      contactData {
        draftComment
      }
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    saveCommentDraft: ({ profileId, content }) =>
      mutate({
        variables: {
          profileId,
          content,
        },
      }),
  }),
});
