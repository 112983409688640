import React from 'react';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import {
  CustomFieldDefinition,
  CustomFieldDefinitionEnum,
  CustomFieldEnumOption,
} from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { getTranslatedText } from '@/common';
import {
  getReadableValue,
  getCustomFieldFakeValue,
  getFieldTypeIcon,
} from '@/common/customFields';
import styles from './ProfileCustomFieldsPreview.module.less';

export default function ProfileCustomFieldsPreview(props: {
  customFields: CustomFieldDefinition[];
  className?: string;
  activeFieldId?: string;
}): JSX.Element {
  const { t } = useTranslation('translations');
  const { customFields, className, activeFieldId } = props;
  const details = customFields.filter(
    (customField) => customField.display === 'in-details',
  );
  const top = customFields.filter(
    (customField) => customField.display === 'profile-top',
  );
  return (
    <div className={classnames(styles.container, className)}>
      <h2 className={styles.header}>
        {t('customFieldsSettings.preview.header')}
      </h2>
      <div className={styles.content}>
        <div className={styles.head}>
          <img
            src='/images/placeholders/profileDetailCustomFieldsHead.png'
            className={styles.placeholder}
            alt='Placeholder'
          />
          <CustomFieldsListDisplay
            customFields={top}
            activeFieldId={activeFieldId}
          />
        </div>
        <div className={styles.body}>
          <h3 className={styles.header2}>Details</h3>
          <CustomFieldsListDisplay
            customFields={details}
            activeFieldId={activeFieldId}
          />
        </div>
      </div>
    </div>
  );
}

function CustomFieldsListDisplay(props: {
  customFields: CustomFieldDefinition[];
  activeFieldId?: string;
}): JSX.Element {
  const { customFields, activeFieldId } = props;
  return (
    <div className={styles.grid}>
      {customFields.map((customField) => (
        <React.Fragment key={customField.id}>
          <div
            className={classnames(styles.label, {
              [styles.disabled]: activeFieldId !== customField.id,
            })}
          >
            <i className={getFieldTypeIcon(customField)} />
            {getTranslatedText(customField.title)}
          </div>
          <div
            className={classnames(styles.value, {
              [styles.disabled]: activeFieldId !== customField.id,
            })}
          >
            <CustomFieldFakeValue customField={customField} />
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

function getOptions(
  enumField: CustomFieldDefinitionEnum,
): CustomFieldEnumOption[] {
  if (enumField.isMultiselect) return enumField.options;
  if (enumField.options?.length > 0) return [enumField.options[0]];
  return [];
}

export function CustomFieldNumber({
  children,
  originalValue,
}: {
  children: React.ReactNode;
  originalValue?: string;
}): JSX.Element {
  return (
    <span>
      {children || '-'}
      {originalValue && String(children) !== originalValue ? (
        <span className={styles.muted} title={originalValue}>
          {' '}
          ({originalValue})
        </span>
      ) : null}
    </span>
  );
}

export function CustomFieldOption({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return <div className={styles.option}>{children}</div>;
}
function CustomFieldFakeValue(props: {
  customField: CustomFieldDefinition;
}): JSX.Element {
  const { customField } = props;
  if (customField.type === 'enum') {
    const options = getOptions(customField);
    return (
      <>
        {options.map((value) => (
          <CustomFieldOption key={value.id}>
            {getTranslatedText(value.title)}
          </CustomFieldOption>
        ))}
      </>
    );
  }
  const value = getReadableValue(
    getCustomFieldFakeValue(customField),
    customField,
  );

  if (Array.isArray(value)) {
    return <>{value.join(', ')}</>;
  }

  return <>{String(value || '')}</>;
}
