import gql from 'graphql-tag';
import _ from 'underscore';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import { Offer } from '@/types/offer';
import OfferFragment from '../../fragments/Offer';

const GET_OFFERS = gql`
  query getOffers($clientId: ID!) {
    client(id: $clientId) {
      id
      # Note: includeUnclassified is used below in props computation
      offers(includeSuggestedOffers: true, includeUnclassified: true) {
        ...Offer
      }
    }
  }
  ${OfferFragment}
`;

interface GetOffersData {
  client: {
    id: string;
    offers: Offer[];
  };
}

interface GetOffersVariables {
  clientId: string;
}

interface UseOffersInput extends GetOffersVariables {
  queryOptions?: QueryHookOptions<GetOffersData, GetOffersVariables>;
  includeUnclassified?: boolean;
}

interface UseOffersResult
  extends QueryResult<GetOffersData, GetOffersVariables> {
  offers: Offer[];
}

const sortWatchBeforeOthers = (clientOffers: Offer[]): Offer[] =>
  _.sortBy(clientOffers, ({ isHiresweetWatch }) => !isHiresweetWatch);

const useOffers = ({
  includeUnclassified = false,
  clientId,
  queryOptions = {},
}: UseOffersInput): UseOffersResult => {
  const query = useQuery<GetOffersData, GetOffersVariables>(GET_OFFERS, {
    ...queryOptions,
    variables: { clientId },
  });

  const unfilteredOffers = query.data?.client.offers || [];
  const filteredOffers = includeUnclassified
    ? unfilteredOffers
    : _.reject(unfilteredOffers, ({ isUnclassified }) => !!isUnclassified);
  const offers = sortWatchBeforeOthers(filteredOffers);

  return { ...query, offers };
};

export default useOffers;
