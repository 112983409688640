import _ from 'underscore';
import React from 'react';
import {
  HiresweetLibProvider,
  HiresweetProject,
} from '@hiresweet/hiresweet-lib';
import useClientId from '@/hooks/router/useClientId';

const Playground11 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30 }}>
      <h1>P11 - Slash HiresweetProject</h1>
      <div>
        <HiresweetLibProvider theme={theme} clientId={clientId}>
          <HiresweetProject projectId='ri7-demo-testimport2-UMODD2' />
        </HiresweetLibProvider>
      </div>
    </div>
  );
};

export default Playground11;
