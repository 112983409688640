import { useMutation } from '@apollo/client';
import {
  UPDATE_USER_SETTINGS,
  UpdateUserSettingsResult,
  UpdateUserSettingsVariables,
} from '../../users';

const useUpdateUserSettings = () =>
  useMutation<UpdateUserSettingsResult, UpdateUserSettingsVariables>(
    UPDATE_USER_SETTINGS,
  );

export default useUpdateUserSettings;
