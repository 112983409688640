import React, { Dispatch, SetStateAction, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { useMutation } from '@apollo/client';
import useClickOutside from '@/hooks/common/useClickOutside';
import { UPDATE_PROFILE_ANSWER } from '@/graphql/profile';
import styles from './KanbanCardChangeAnswer.module.less';

const ANSWER_OPTIONS = [
  {
    key: 'positive',
    label: 'toPositive',
  },
  {
    key: 'negative',
    label: 'toNegative',
  },
  /*  {
    key: 'in-process',
    label: 'toInProcess',
  }, */
];
interface Props {
  profileId: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const KanbanCardChangeAnswer: React.FC<Props> = ({ profileId, setOpen }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const [updateProfileAnswer] = useMutation(UPDATE_PROFILE_ANSWER);

  useClickOutside(ref, () => {
    if (!ref.current) return;
    setOpen(false);
  });

  const onChangeAnswer = async (selectedAnswer: string) => {
    await updateProfileAnswer({
      variables: {
        profileId,
        input: {
          label: selectedAnswer,
        },
      },
    });
    setOpen(false);
  };

  return (
    <div className={styles.answerMenuWrapper} ref={ref}>
      <h6 className={styles.title}>
        {t('watchCollect.followupView.kanban.card.answerMenu.title')}
      </h6>
      <div>
        {_.map(ANSWER_OPTIONS, (option) => (
          <div
            className={styles.option}
            key={option.key}
            onClick={() => onChangeAnswer(option.key)}
          >
            <i className='ri-arrow-right-circle-line ri-lg' />
            <span>
              {t(
                `watchCollect.followupView.kanban.card.answerMenu.${option.label}`,
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KanbanCardChangeAnswer;
