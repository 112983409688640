import useClientId from '@/hooks/router/useClientId';
import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const CLIENT_DOCUMENT_TYPES = gql`
  query getClientDocumentTypes($clientId: ID!) {
    client(id: $clientId) {
      id
      clientDocumentTypes {
        key
        value {
          default
          en
          fr
        }
      }
    }
  }
`;

export function useClientDocumentTypes({
  queryOptions = {},
}: { queryOptions?: QueryHookOptions } = {}) {
  const clientId = useClientId();
  const { data, ...rest } = useQuery(CLIENT_DOCUMENT_TYPES, {
    variables: {
      clientId,
    },
    ...queryOptions,
  });

  return {
    clientDocumentTypes: data?.client?.clientDocumentTypes,
    ...rest,
  };
}
