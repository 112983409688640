import React, { useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { useClientMissionCustomFields } from '@/graphql/hooks/clients/useClientMissionCustomFields';
import { SNIPPET_TYPES } from '@/common/mergeTags/utils';
import MergeTagsVariablesOptions from './MergeTagsVariableOptions';

import styles from '../MergeTagsSelector.module.less';
import {
  MergeTagsSelectorOption,
  PROJECT_VARIABLES,
  generateOptions,
} from '../utils';

interface Props {
  clientId: string;
  onSelectVariable: (variable: MergeTagsSelectorOption) => void;
  hasPermission: boolean;
}

const MergeTagsMissionOptions: React.FC<Props> = ({
  clientId,
  hasPermission,
  onSelectVariable,
}) => {
  const { missionCustomFields } = useClientMissionCustomFields(clientId);
  const { t } = useTranslation();

  const generateTranslatedOptions = useMemo(() => generateOptions(t), [t]);

  const projectVariablesOptions = useMemo(
    () => generateTranslatedOptions(PROJECT_VARIABLES),
    [generateTranslatedOptions],
  );

  const missionCustomFieldsOptions: MergeTagsSelectorOption[] = useMemo(() => {
    return _.map(missionCustomFields || [], (cf) => ({
      key: cf.id,
      label: cf.title.default || cf.id,
      type: SNIPPET_TYPES.MISSION_CUSTOM_FIELD,
    }));
  }, [missionCustomFields]);

  if (!hasPermission) {
    return null;
  }

  return (
    <>
      <MergeTagsVariablesOptions
        options={projectVariablesOptions}
        onSelectVariable={onSelectVariable}
        hasPermission={hasPermission}
      />
      {!_.isEmpty(missionCustomFieldsOptions) && (
        <>
          <hr className={styles.hr} />
          <span className={styles.sectionTitle}>
            {t('editor.mergeTagsSelector.missionCustomFields')}
          </span>
          <MergeTagsVariablesOptions
            options={missionCustomFieldsOptions}
            onSelectVariable={onSelectVariable}
            hasPermission={hasPermission}
          />
        </>
      )}
    </>
  );
};

export default MergeTagsMissionOptions;
