import gql from 'graphql-tag';
import _ from 'underscore';
import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { useMemo } from 'react';
import SearchPoolJobOption, {
  ISearchPoolJobOption,
} from '@/graphql/fragments/SearchPoolJobOption';
import { SearchPoolJobQueryVariables } from '../../searchPoolJobs';

interface SearchPoolJobOptionQueryResult {
  searchPool: {
    id: string;
    jobs?: ISearchPoolJobOption[];
  };
}

const useSearchPoolJobOptions = (
  searchPoolId: string,
  filters?: SearchPoolJobQueryVariables['filters'],
  fetchPolicy?: WatchQueryFetchPolicy | undefined,
): {
  jobOptions: ISearchPoolJobOption[];
  jobOptionsLoading: boolean;
  refetch: () => void;
} => {
  const { data, loading, refetch } = useQuery<
    SearchPoolJobOptionQueryResult,
    SearchPoolJobQueryVariables
  >(SEARCH_POOL_JOB_OPTIONS, {
    variables: { searchPoolId, filters },
    ...(fetchPolicy && { fetchPolicy }),
  });
  const displayableJobs = useMemo(
    () => _.filter(data?.searchPool?.jobs || [], ({ hide }) => !hide),
    [data],
  );
  return { jobOptions: displayableJobs, jobOptionsLoading: loading, refetch };
};

export const SEARCH_POOL_JOB_OPTIONS = gql`
  query getSearchPoolJobOptions(
    $searchPoolId: ID!
    $filters: SearchPoolJobFiltersInput
  ) {
    searchPool(id: $searchPoolId) {
      id
      jobs(filters: $filters) {
        ...SearchPoolJobOption
      }
    }
  }
  ${SearchPoolJobOption}
`;

export default useSearchPoolJobOptions;
