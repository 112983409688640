import _ from 'underscore';
import { getRandomString } from '@/common';

const useSequences = ({
  setSequences,
  formErrors,
  setErrorLevel,
  setCloseOnDimmerClick = null,
}) => {
  const handleInsertAction = ({ sequenceId, index, action }) => {
    setSequences((prevSequences) => {
      return _.map(prevSequences, (prevSequence) => {
        if (prevSequence.id !== sequenceId) {
          return prevSequence;
        }
        const newActions = [...prevSequence.actions];
        newActions.splice(index, 0, {
          ...action,
          actionId: `contact-flow-action-${getRandomString(6)}`,
        });
        return {
          ...prevSequence,
          actions: newActions,
        };
      });
    });

    if (setCloseOnDimmerClick) {
      setCloseOnDimmerClick(false);
    }
    if (_.isEmpty(formErrors)) {
      setErrorLevel('major');
    }
  };

  const handleUpdateAction = ({ sequenceId, actionId, action }) => {
    setSequences((prevSequences) => {
      return _.map(prevSequences, (prevSequence) => {
        if (prevSequence.id !== sequenceId) {
          return prevSequence;
        }
        const newActions = _.map(prevSequence.actions, (prevAction) => {
          if (prevAction.actionId !== actionId) {
            return prevAction;
          }
          return action;
        });
        return {
          ...prevSequence,
          actions: newActions,
        };
      });
    });

    if (setCloseOnDimmerClick) {
      setCloseOnDimmerClick(false);
    }
    if (_.isEmpty(formErrors)) {
      setErrorLevel('major');
    }
  };

  const handleRemoveAction = ({ sequenceId, actionId }) => {
    setSequences((prevSequences) => {
      return _.map(prevSequences, (prevSequence) => {
        if (prevSequence.id !== sequenceId) {
          return prevSequence;
        }
        const newActions = _.filter(
          prevSequence.actions,
          ({ actionId: prevActionId }) => prevActionId !== actionId,
        );
        return {
          ...prevSequence,
          actions: newActions,
        };
      });
    });

    if (setCloseOnDimmerClick) {
      setCloseOnDimmerClick(false);
    }

    if (_.isEmpty(formErrors)) {
      setErrorLevel('major');
    }
  };

  const handleRemoveActions = ({ sequenceId, actionIds }) => {
    setSequences((prevSequences) => {
      return _.map(prevSequences, (prevSequence) => {
        if (prevSequence.id !== sequenceId) {
          return prevSequence;
        }
        const newActions = _.filter(
          prevSequence.actions,
          ({ actionId }) => !_.contains(actionIds, actionId),
        );
        return {
          ...prevSequence,
          actions: newActions,
        };
      });
    });
    setCloseOnDimmerClick(false);

    if (_.isEmpty(formErrors)) {
      setErrorLevel('major');
    }
  };

  const handleMoveUpwardAction = ({ sequenceId, actionId }) => {
    setSequences((prevSequences) => {
      return _.map(prevSequences, (prevSequence) => {
        if (prevSequence.id !== sequenceId) {
          return prevSequence;
        }
        const { actions } = prevSequence;
        const index = _.findIndex(
          actions,
          (action) => action.actionId === actionId,
        );
        const newActions =
          index > 0
            ? [
                ...actions.slice(0, index - 1),
                actions[index],
                actions[index - 1],
                ...actions.slice(index + 1),
              ]
            : actions;
        return {
          ...prevSequence,
          actions: newActions,
        };
      });
    });
  };

  const handleMoveDownwardAction = ({ sequenceId, actionId }) => {
    setSequences((prevSequences) => {
      return _.map(prevSequences, (prevSequence) => {
        if (prevSequence.id !== sequenceId) {
          return prevSequence;
        }
        const { actions } = prevSequence;
        const index = _.findIndex(
          actions,
          (action) => action.actionId === actionId,
        );
        const newActions =
          index >= 0 && index + 1 < actions.length
            ? [
                ...actions.slice(0, index),
                actions[index + 1],
                actions[index],
                ...actions.slice(index + 2),
              ]
            : actions;
        return {
          ...prevSequence,
          actions: newActions,
        };
      });
    });
  };

  const updateAfterTemplateCreation = ({ action, sequenceId, actionIndex }) => {
    setSequences((prevSequences) => {
      return _.map(prevSequences, (prevSequence) => {
        if (prevSequence.id !== sequenceId) {
          return prevSequence;
        }
        const newAction = {
          actionId: `contact-flow-action-${getRandomString(6)}`,
          ...action,
        };
        const newActions = [...prevSequence.actions];
        newActions.splice(actionIndex, 1, newAction);
        return {
          ...prevSequence,
          actions: newActions,
        };
      });
    });

    if (setCloseOnDimmerClick) {
      setCloseOnDimmerClick(false);
    }

    if (_.isEmpty(formErrors)) {
      setErrorLevel('major');
    }
  };

  return {
    handleInsertAction,
    handleUpdateAction,
    handleRemoveAction,
    handleRemoveActions,
    handleMoveUpwardAction,
    handleMoveDownwardAction,
    updateAfterTemplateCreation,
  };
};

export default useSequences;
