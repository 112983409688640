import _ from 'underscore';

const checkContactFlowSnippets = ({ contactFlow }) => {
  const actionsWithMissingSnippets = [];
  _.each(contactFlow.sequences?.[0]?.actions, (action) => {
    const { message, snippets } = action;
    const { subject, body } = message || {};
    let hasError = false;
    const missingSnippets = [];
    if (subject) {
      subject.replace(/\{{([^{}]*)}}/g, (match, key) => {
        const isOldMergeTag = ['firstname', 'lastname', 'email'].includes(key);
        const matchingSnippet = (snippets || []).find(
          (snippet) => snippet.id === key,
        );
        if (!matchingSnippet && !isOldMergeTag) {
          hasError = true;
          missingSnippets.push(key);
        }
      });
    }
    if (body) {
      body.replace(/\{{([^{}]*)}}/g, (match, key) => {
        const matchingSnippet = (snippets || []).find(
          (snippet) => snippet.id === key,
        );
        const isOldMergeTag = ['firstname', 'lastname', 'email'].includes(key);
        if (!matchingSnippet && !isOldMergeTag) {
          hasError = true;
          missingSnippets.push(key);
        }
      });
    }
    if (hasError) {
      actionsWithMissingSnippets.push({ id: action.actionId, missingSnippets });
    }
  });
  return actionsWithMissingSnippets;
};

export default checkContactFlowSnippets;
