import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Dropdown, Icon, Button } from 'semantic-ui-react';
import { mutation } from '@/hocs/profiles/withChangeProfileStep';

import './JobAdmin.css';

type JobAdminProps = {
  profileData: any;
  profileUrl: string;
};

const JobAdmin: React.FC<JobAdminProps> = ({ profileData, profileUrl }) => {
  const { t } = useTranslation('translations');

  const { id, offer, resumeData } = profileData || {};
  const { step } = resumeData || {};
  const [stepState, setStepState] = useState(step);
  const [changeProfileStep, { data, error }] = useMutation(mutation);

  const onChangeProfileStep = async (_: any, { value }: any) => {
    await changeProfileStep({ variables: { id, step: value } });

    const { profile } = data || {};

    const { step: newStep } = profile?.resumeData || {};

    setStepState(newStep);
    return null;
  };

  return (
    <div className='job-admin'>
      <div className='segment'>
        <div className='row'>
          {offer?.title && (
            <div className='job-title'>
              <a href={profileUrl} target='__blank' rel='noreferrer noopener'>
                <div className='circle' />
                <div className='title'>{offer?.title}</div>
                <Icon name='external alternate' />
              </a>
            </div>
          )}
          <div className='step-selector'>
            <StepSelector
              step={stepState}
              onChange={onChangeProfileStep}
              error={error}
            />
          </div>
        </div>
        <div className='buttons-row'>
          <Button
            className='comment-button'
            onClick={() => {
              const overviewTab = document.getElementById(
                'hsSidebar-notes-block',
              );
              if (overviewTab) {
                overviewTab.scrollIntoView();
              }
            }}
          >
            <i className='pencil small icon' />
            <div className='write-comment-button'>
              {t('overview.writeComment')}
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

const StepSelector = ({ step, onChange, error }: any) => {
  const stepOptions = [
    { value: 'pending', text: 'Pending' },
    { value: 'contacted', text: 'Contacted' },
    { value: 'answered', text: 'Answered' },
    { value: 'in-process', text: 'In process' },
    { value: 'hired', text: 'Hired' },
    { value: 'skipped', text: 'Skipped' },
  ];
  // console.log(step);
  return (
    <Dropdown
      options={stepOptions}
      value={step}
      onChange={onChange}
      selection
      compact
      error={error}
    />
  );
};

export default JobAdmin;
