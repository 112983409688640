import gql from 'graphql-tag';
import { Author } from '@/types/author';

export const GET_ARCHIVE_REASONS = gql`
  query getClientArchiveReasons($clientId: ID!, $includeArchived: Boolean) {
    client(id: $clientId) {
      id
      archiveReasons(includeArchived: $includeArchived) {
        id
        title
        archiveTags
        category
        author {
          firstname
          email
          lastname
        }
        creationDate
        lastEditionDate
        linkedATSRejectionReasonId
        linkedATSRejectionReasonName
      }
    }
  }
`;

export const CREATE_ARCHIVE_REASON = gql`
  mutation CreateArchiveReason($input: CreateArchiveReasonInput!) {
    archiveReason {
      create(input: $input) {
        archiveReason {
          id
          title
          archiveTags
          category
          creationDate
          linkedATSRejectionReasonId
          linkedATSRejectionReasonName
        }
      }
    }
  }
`;

export const DELETE_ARCHIVE_REASON = gql`
  mutation deleteArchiveReason($input: DeleteArchiveReasonInput!) {
    archiveReason {
      delete(input: $input) {
        id
      }
    }
  }
`;

export const UPDATE_ARCHIVE_REASON = gql`
  mutation updateArchiveReason($input: UpdateArchiveReasonInput!) {
    archiveReason {
      update(input: $input) {
        id
        title
        archiveTags
        category
        linkedATSRejectionReasonId
        linkedATSRejectionReasonName
      }
    }
  }
`;

export type ArchiveReason = {
  id: string;
  title: string;
  author?: Author;
  creationDate?: Date;
  creationTimestamp?: number;
  lastEditionDate?: Date;
  category: string;
  archiveTags: string[];
  linkedATSRejectionReasonId: string;
  linkedATSRejectionReasonName: string;
};
