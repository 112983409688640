import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import { MiniCampaign } from '@/types/campaign';

export const GET_MINI_CAMPAIGNS = gql`
  query getMiniCampaigns($clientId: ID!, $filters: ClientCampaignsInput) {
    client(id: $clientId) {
      id
      campaigns(filters: $filters) {
        id
        isArchived
        title
        subject
      }
    }
  }
`;

interface GetMiniCampaignsData {
  client: {
    id: string;
    campaigns: MiniCampaign[];
  };
}

interface GetMiniCampaignsVariables {
  clientId: string;
  filters?: {
    activeOnly: boolean;
  };
}

interface UseMiniCampaignsResult
  extends QueryResult<GetMiniCampaignsData, GetMiniCampaignsVariables> {
  miniCampaigns?: MiniCampaign[];
}

interface UseMiniCampaignsInput extends GetMiniCampaignsVariables {
  queryOptions?: QueryHookOptions<
    GetMiniCampaignsData,
    GetMiniCampaignsVariables
  >;
}

const useMiniCampaigns = ({
  clientId,
  filters,
  queryOptions = {},
}: UseMiniCampaignsInput): UseMiniCampaignsResult => {
  const query = useQuery<GetMiniCampaignsData, GetMiniCampaignsVariables>(
    GET_MINI_CAMPAIGNS,
    {
      ...queryOptions,
      variables: {
        clientId,
        filters,
      },
    },
  );

  const { campaigns: miniCampaigns } = query.data?.client || {};
  return { ...query, miniCampaigns };
};

export default useMiniCampaigns;
