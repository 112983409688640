import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ResumeData } from '@/types/searchPoolProfile';
import getLinkedinURL from '@/common/reveal/linkedinURL';
import useUserFromJWToken from '@/graphql/hooks/users/useUserFromJWToken';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import { DAY_IN_MS } from '@/common/dates';
import TaskIcon from '../TaskIcon';

interface NextInteractionProps {
  author: any;
  timestampStr: string;
  action: any;
  resumeData?: ResumeData;
}

const NextInteraction: React.FC<NextInteractionProps> = ({
  author,
  timestampStr,
  action,
  resumeData,
}) => {
  const { data: userData } = useUserFromJWToken();
  const userPermissions = userData?.user?.permissions;
  const { t } = useTranslation();
  const dueDateDiff = parseInt(timestampStr, 10) - Date.now();

  const { url: linkedinURL } = getLinkedinURL({
    userPermissions,
    resumeData,
    actionType: action?.type,
    actionSubtype: action?.subtype,
  });

  const onLinkedinClick = (e: any) => {
    e.stopPropagation();
    window.postMessage(
      {
        type: 'set-task-state',
        data: {
          action,
          activeTaskListId: action?.type,
        },
      },
      '*',
    );
  };
  const configurationParams = useMergedConfigurationParams();

  const withoutHoursMode = configurationParams?.shouldDisplayNextInteractionWithoutHours === 'true';

  let dueDate = '';

  if (dueDateDiff < 604800000 && dueDateDiff >= DAY_IN_MS) {
    // between 1 and 7 days
    const nbDays = Math.round(dueDateDiff / DAY_IN_MS);
    dueDate = `${t('reveal.missions.mission.nextInteraction.in')} ${nbDays} ${t(
      'reveal.missions.mission.nextInteraction.day',
      {
        count: nbDays,
      },
    )}`;
  } else if (dueDateDiff > 604800000) {
    // more than a week
    const date = new Date(+timestampStr);
    dueDate = moment(date).format('LL');
  } else if (dueDateDiff < DAY_IN_MS && dueDateDiff >= 3600000) {
    const nbHours = Math.round(dueDateDiff / 3600000);
    dueDate = withoutHoursMode ? (
      `${t('reveal.missions.mission.nextInteraction.in')} 1 ${t(
        'reveal.missions.mission.nextInteraction.day',
        {
          count: 1,
        },
      )}`
    ) : `${t(
      'reveal.missions.mission.nextInteraction.in',
    )} ${nbHours} ${t('reveal.missions.mission.nextInteraction.hour', {
      count: nbHours,
    })}`;
  } else if (dueDateDiff < 3600000) {
    dueDate = t('reveal.missions.mission.nextInteraction.now');
  }
  return (
    <div className='next-interaction'>
      {(author?.email || (author?.firstname && author?.lastname)) && (
        <div className='email'>
          {author?.email || `${author?.firstname} ${author?.lastname}`}
        </div>
      )}
      <div className='tasks-action'>
        <TaskIcon action={action} />
        <h5>{t(`sequences.tasks.${action.type}`)}</h5>
        {(action.type === 'linkedin-send-message' ||
          action.type === 'linkedin-send-request') &&
          linkedinURL && (
            <a
              href={linkedinURL}
              target='_blank'
              rel='noreferrer noopener'
              aria-disabled={!linkedinURL}
              onClick={(e) => onLinkedinClick(e)}
            >
              <i className='ri-external-link-line ri-xl' />
            </a>
          )}
        {dueDate && (
          <div
            className={`${
              dueDateDiff > 0 ? 'pill-message green' : 'pill-message orange'
            } spaced`}
          >
            {dueDate}
          </div>
        )}
      </div>
    </div>
  );
};

export default NextInteraction;
