import React, { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GenericButton from '../GenericButton';
import GenericPopup from '../GenericPopup';

import styles from './GenericIconSelector.module.less';
import { CustomIcon, IconDropdown } from './IconDropdown/IconDropdown';
import { CustomIconDescriptor } from './types';

interface GenericIconSelectorProps {
  onIconSelected: (icon: CustomIconDescriptor) => void;
  icon: CustomIconDescriptor;
}

export default function GenericIconSelector({
  onIconSelected,
  icon,
}: PropsWithChildren<GenericIconSelectorProps>) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <CustomIcon icon={icon} />
      </div>
      <div>
        <GenericPopup
          basic
          on='click'
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          open={open}
          position='bottom right'
          trigger={
            <GenericButton primacy='secondary'>
              {t('reveal.iconDropdown.chooseIcon')}
            </GenericButton>
          }
        >
          <IconDropdown
            onIconSelected={(...args) => {
              onIconSelected(...args);
              setOpen(false);
            }}
          />
        </GenericPopup>
      </div>
    </div>
  );
}
