import React, { useState, useEffect } from 'react';
import { compose } from 'underscore';
import { useTranslation } from 'react-i18next';
// import { GET_USER_NOTIFICATIONS_SETTINGS } from '../../../hocs/users/withUserRevealNotificationsSettings';
import { Link } from 'react-router-dom';
import { Form, Radio, StrictCheckboxProps } from 'semantic-ui-react';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import { PrimaryCobalt60 } from '@/less/colors';
import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import './RevealNotificationsSettings.css';
import withUserSettings from '@/hocs/users/withUserSettings';
import withUpdateUserSettings from '@/hocs/users/withUpdateUserSettings';
import GenericButton from '@/components/Common/GenericButton';
import { IUserSettings } from '@/graphql/fragments/UserSettings';
import SettingsLayout from '../../SettingsLayout';

interface RevealNotificationSettingsProps {
  updateUser: (user: unknown) => Promise<void>;
  clientId: string;
  user: IUserSettings | undefined;
}

const RevealNotificationSettings: React.FC<RevealNotificationSettingsProps> = ({
  updateUser,
  clientId,
  user,
}) => {
  const { t } = useTranslation();
  const permissions = useClientPermissions(clientId).data?.client?.permissions;
  const { applicationMode, updateApplicationMode } = useNavApplicationMode();
  const [tasksNotifications, setTasksNotifications] = useState('twice-a-week');
  const [recommendationsNotifications, setRecommendationsNotifications] =
    useState('twice-a-week');
  const [reportsNotifications, setReportsNotifications] = useState('weekly');
  const [unipileNotifications, setUnipileNotifications] = useState(false);
  const [submitDisabled, disableSubmit] = useState(false);
  const [justSaved, setJustSaved] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user?.notifications?.crm?.pendingTasks?.type) {
      setTasksNotifications(user.notifications.crm.pendingTasks.type);
    }
    if (user?.notifications?.crm?.pendingRecommendations?.type) {
      setRecommendationsNotifications(
        user.notifications.crm.pendingRecommendations.type,
      );
    }
    if (user?.notifications?.crm?.weeklyStats?.type) {
      setReportsNotifications(user.notifications.crm.weeklyStats.type);
    }
    setUnipileNotifications(!!user?.notifications?.crm?.unipile);
  }, [user]);

  useEffect(() => {
    if (applicationMode !== 'reveal') updateApplicationMode('reveal');
  });

  const handleTasksNotificationsChange: StrictCheckboxProps['onChange'] = (
    _e,
    data,
  ) => {
    setTasksNotifications(data?.value as string);
  };

  const handleRecommendationsNotificationsChange: StrictCheckboxProps['onChange'] =
    (_e, data) => {
      setRecommendationsNotifications(data?.value as string);
    };

  const handleReportsNotificationsChange: StrictCheckboxProps['onChange'] = (
    _e,
    data,
  ) => {
    setReportsNotifications(data?.value as string);
  };

  const onSubmit = () => {
    const input = {
      notifications: {
        crm: {
          pendingTasks: {
            type: tasksNotifications,
          },
          pendingRecommendations: {
            type: recommendationsNotifications,
          },
          weeklyStats: {
            type: reportsNotifications,
          },
          unipile: unipileNotifications,
        },
      },
    };
    updateUser(input)
      .then(() => {
        disableSubmit(false);
        setJustSaved(true);
        setTimeout(() => setJustSaved(false), 1000);
      })
      .catch((e) => {
        disableSubmit(false);
        setError(e.message);
        setTimeout(() => setError(null), 3000);
      });
  };

  return (
    <Form className='settings' onSubmit={onSubmit}>
      <SettingsLayout
        currentPage='notifications'
        clientId={clientId}
        className='settings-container'
      >
        <div className='settings notifications-page'>
          <div className='settings-header'>
            <h1>
              {t('notificationsSettings.header')}{' '}
              {permissions?.reveal && permissions?.watchCollect && '(CRM)'}
            </h1>
            <div className='settings-submit'>
              <div>
                <GenericButton type='submit' disabled={submitDisabled}>
                  {t('settings.saveButton')}
                </GenericButton>
              </div>
              <div className='settings-submit-message'>
                {error ? <span className='error-message'>{error}</span> : null}
              </div>
              <div className='settings-submit-message'>
                {justSaved ? (
                  <span className='just-saved'>
                    {t('settings.notifications.savedWithSuccess')}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          {permissions?.reveal && permissions?.watchCollect && (
            <p style={{ color: PrimaryCobalt60 }}>
              {t('notificationsSettings.subheader', {
                client: 'Marketplace',
              })}
              <Link to={`/client/${clientId}/notifications`}>
                {' '}
                {t('integrations.clickHere')}
              </Link>
              .
            </p>
          )}
          <div className='section visible'>
            <div className='notification-section'>
              <h4>{t('notificationsSettings.reveal.tasks.title')}</h4>
              <p>{t('notificationsSettings.reveal.tasks.description')}</p>
              <Form.Field>
                <Radio
                  label={t('notificationsSettings.reveal.daily')}
                  name='daily-task'
                  value='daily'
                  checked={tasksNotifications === 'daily'}
                  onChange={handleTasksNotificationsChange}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={t('notificationsSettings.reveal.twiceAWeek')}
                  name='twice-week-task'
                  value='twice-a-week'
                  checked={tasksNotifications === 'twice-a-week'}
                  onChange={handleTasksNotificationsChange}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={t('notificationsSettings.reveal.weekly')}
                  name='week-task'
                  value='weekly'
                  checked={tasksNotifications === 'weekly'}
                  onChange={handleTasksNotificationsChange}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={t('notificationsSettings.reveal.never')}
                  name='never-task'
                  value='never'
                  checked={tasksNotifications === 'never'}
                  onChange={handleTasksNotificationsChange}
                />
              </Form.Field>
            </div>
            <div className='notification-section'>
              <h4>{t('notificationsSettings.reveal.recommendations.title')}</h4>
              <p>
                {t('notificationsSettings.reveal.recommendations.description')}
              </p>
              <Form.Field>
                <Radio
                  label={t('notificationsSettings.reveal.daily')}
                  name='daily-recommendation'
                  value='daily'
                  checked={recommendationsNotifications === 'daily'}
                  onChange={handleRecommendationsNotificationsChange}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={t('notificationsSettings.reveal.twiceAWeek')}
                  name='twice-week-recommendation'
                  value='twice-a-week'
                  checked={recommendationsNotifications === 'twice-a-week'}
                  onChange={handleRecommendationsNotificationsChange}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={t('notificationsSettings.reveal.weekly')}
                  name='week-recommendation'
                  value='weekly'
                  checked={recommendationsNotifications === 'weekly'}
                  onChange={handleRecommendationsNotificationsChange}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={t('notificationsSettings.reveal.never')}
                  name='never-recommendation'
                  value='never'
                  checked={recommendationsNotifications === 'never'}
                  onChange={handleRecommendationsNotificationsChange}
                />
              </Form.Field>
            </div>
            <div className='notification-section'>
              <h4>{t('notificationsSettings.reveal.reports.title')}</h4>
              <p>{t('notificationsSettings.reveal.reports.description')}</p>
              <Form.Field>
                <Radio
                  label={t('notificationsSettings.reveal.weekly')}
                  name='weekly-report'
                  value='weekly'
                  checked={reportsNotifications === 'weekly'}
                  onChange={handleReportsNotificationsChange}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={t('notificationsSettings.reveal.never')}
                  name='never-report'
                  value='never'
                  checked={reportsNotifications === 'never'}
                  onChange={handleReportsNotificationsChange}
                />
              </Form.Field>
            </div>
            <div className='notification-section'>
              <h4>{t('notificationsSettings.reveal.unipile.title')}</h4>
              <p>{t('notificationsSettings.reveal.unipile.description')}</p>
              <Form.Field>
                <Radio
                  label={t('notificationsSettings.reveal.yes')}
                  checked={unipileNotifications}
                  onChange={() => setUnipileNotifications(true)}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={t('notificationsSettings.reveal.no')}
                  checked={!unipileNotifications}
                  onChange={() => setUnipileNotifications(false)}
                />
              </Form.Field>
            </div>
          </div>
        </div>
      </SettingsLayout>
    </Form>
  );
};

export default compose(
  withUserSettings,
  withUpdateUserSettings,
)(RevealNotificationSettings);
