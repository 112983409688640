import React, { useState } from 'react';
import { Form, Segment } from 'semantic-ui-react';
import useSearchPoolJob from '@/graphql/hooks/searchPoolJobs/useSearchPoolJob';

import RichEditor from '@/components/RichEditor';
import { useTranslation } from 'react-i18next';
import { getTranslatedText } from '@/common';
import GenericButton from '@/components/Common/GenericButton';
import useUpdateJobPositionDescription from '@/graphql/hooks/searchPoolJob/useUpdatePositionDescription';
import { sanitizeTypename } from '@/common/utils/apollo';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';

interface PositionDescriptionProps {
  jobId: string;
}

const PositionDescription: React.FC<PositionDescriptionProps> = ({ jobId }) => {
  const { t } = useTranslation();
  const [lastEditedValue, setLastEditedValue] = useState('');
  const notifications = useNotificationSystem();

  const [updatePositionDescription] = useUpdateJobPositionDescription();

  const { data: jobData, loading: jobLoading } = useSearchPoolJob(
    'reveal',
    jobId,
  );

  if (jobLoading) {
    return null;
  }

  const description = getTranslatedText(
    jobData?.searchPool?.job?.positionDescription?.description?.sections?.[0]
      ?.content,
  );

  const onSave = () => {
    updatePositionDescription(
      sanitizeTypename({
        variables: {
          searchPoolId: 'reveal',
          input: {
            id: jobId,
            description: {
              multiSectionsJobPostingDescription: {
                type: 'multi-sections',
                sections: [
                  {
                    simpleJobPostingDescriptionSection: {
                      type: 'simple-section',
                      content: {
                        default: lastEditedValue || description,
                      },
                    },
                  },
                ],
              },
            },
          },
        },
      }),
    )
      .then(() => {
        notifications.success(
          t(
            'reveal.missions.mission.settingsTab.positionDescription.notifications.success',
          ),
        );
      })
      .catch((e) => {
        console.error(e);
        notifications.error(
          t(
            'reveal.missions.mission.settingsTab.positionDescription.notifications.error',
          ),
        );
      });
  };

  return (
    <Segment className='pure-criteria-form'>
      <div className='job-calibration-header'>
        <h2>
          {t('reveal.missions.mission.settingsTab.positionDescription.title')}
        </h2>
        <GenericButton onClick={() => onSave()}>
          {t('reveal.missions.mission.recommandations.calibration.saveButton')}
        </GenericButton>
      </div>
      <Form>
        <Form.Field>
          <label>
            {t(
              'reveal.missions.mission.settingsTab.positionDescription.subtitle',
            )}
          </label>
          <RichEditor
            initialHtml={description}
            onChangeAsHtml={(html: string) => setLastEditedValue(html)}
          />
        </Form.Field>
      </Form>
    </Segment>
  );
};

export default PositionDescription;
