import gql from 'graphql-tag';
import {
  MutationHookOptions,
  FetchResult,
  MutationResult,
  useMutation,
} from '@apollo/client';

const UNHOLD_OFFER = gql`
  mutation unHoldOffer($offerId: ID!) {
    unHoldOffer(id: $offerId) {
      id
      isHold
      instantFlowPipeDescriptor {
        uploadStatus
      }
    }
  }
`;

interface UnHoldOfferVariables {
  offerId: string;
}

interface UnHoldOfferData {
  unHoldOffer: {
    id: string;
    isHold: boolean;
    instantFlowPipeDescriptor?: {
      uploadStatus: string;
    };
  };
}

interface UseUnHoldOfferInput {
  mutationOptions?: MutationHookOptions<UnHoldOfferData, UnHoldOfferVariables>;
}

type UseUnHoldOfferResult = [
  (offerId: string) => Promise<FetchResult<UnHoldOfferData>>,
  MutationResult<UnHoldOfferData>,
];

const useUnHoldOffer = ({
  mutationOptions = {},
}: UseUnHoldOfferInput = {}): UseUnHoldOfferResult => {
  const [mutation, result] = useMutation<UnHoldOfferData, UnHoldOfferVariables>(
    UNHOLD_OFFER,
    {
      ...mutationOptions,
    },
  );

  return [(offerId) => mutation({ variables: { offerId } }), result];
};

export default useUnHoldOffer;
