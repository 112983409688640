import { UPDATE_CLIENT_PERMISSIONS } from '@/graphql/clients';
import { IClientPermissions } from '@/graphql/fragments/ClientPermissions';
import { useMutation } from '@apollo/client';

interface RevealClientPermissionsVariables {
  input: {
    permission: string;
    value: boolean;
  }
}

interface RevealClientPermissionsResults {
  client: {
    id: string;
    permissions: IClientPermissions;
  };
}

const useUpdateClientPermissions = () => {
  return useMutation<
    RevealClientPermissionsResults,
    RevealClientPermissionsVariables
  >(UPDATE_CLIENT_PERMISSIONS);
};

export default useUpdateClientPermissions;
