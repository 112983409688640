import _ from 'underscore';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnrichedField } from '../utils/enrichmentFallbacks';
import PluginBackgroundTimeline from './PluginBackgroundTimeline';
import styles from './PluginExperiences.module.less';

type PluginExperiencesProps = {
  experiences: {
    title: EnrichedField;
    startDate: any;
    endDate: any;
    workplace: any;
    companyName: string;
  }[];
  dense?: boolean;
};

const MAX_DISPLAY_COUNT = 3;

const PluginExperiences: React.FC<PluginExperiencesProps> = ({
  experiences,
  dense = false,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(!dense);
  const list = isOpen ? experiences : experiences?.slice(0, MAX_DISPLAY_COUNT);

  return (
    <div className={styles.root}>
      <div className={styles.title}>{t('profile.resume.experience')}</div>
      {_.map(list, (experience, index) => (
        <PluginBackgroundTimeline
          key={index}
          title={experience.title}
          startDate={experience.startDate}
          endDate={experience.endDate}
          workplace={
            experience.workplace?.data?.name ?? experience.companyName ?? ''
          }
        />
      ))}
      {experiences?.length > MAX_DISPLAY_COUNT + 1 && !isOpen && (
        <div
          className={styles.seeMore}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          See more
        </div>
      )}{' '}
    </div>
  );
};

PluginExperiences.defaultProps = {
  dense: false,
};

export default PluginExperiences;
