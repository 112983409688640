import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';

import {
  ClientDailyCohort,
  ExclusiveTaskType,
} from '@/types/statistics/clientStatistics';
import InfoTooltip from '@/components/Common/InfoTooltip';
import GraphWrapper from '../../components/GraphWrapper/GraphWrapper';
import {
  ChartType,
  Frequency,
  Mode,
} from '../../components/GraphWrapper/types';
import { TimeSpan } from '../../components/RevealAnalyticsHeader/RevealAnalyticsHeader';
import SequenceDataContainer from './SequencesDataContainer';

interface SequencesDailyCohortsStatisticsProps {
  timeSpan: TimeSpan;
  dailyCohorts: ClientDailyCohort[];
  cohortsLoading: boolean;
  fetchDetails: (
    startDay: string,
    endDay: string,
    taskType: ExclusiveTaskType,
  ) => void;
}

const SequencesDailyCohortsStatistics: FC<SequencesDailyCohortsStatisticsProps> = ({
  dailyCohorts,
  cohortsLoading,
  timeSpan,
  fetchDetails,
}) => {
  const { t } = useTranslation();

  return (
    <div className='sequences-cohorts-graph'>
      <GraphWrapper
        title={
          <h3 className='statistics-title'>
            {t('reveal.reports.sequences.cohortsTitle')}
            <InfoTooltip rich hoverable position='right center'>
              <h1>{t('reveal.reports.tooltips.sequences.cohorts.title')}</h1>
              <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
              <p>
                {t(
                  'reveal.reports.tooltips.sequences.cohorts.explanationParagraph',
                )}
              </p>
              <p>
                {t(
                  'reveal.reports.tooltips.sequences.cohorts.uniquenessParagraph',
                )}
              </p>
              <p>
                {t('reveal.reports.tooltips.sequences.cohorts.startParagraph')}
              </p>
              <p>
                {t(
                  'reveal.reports.tooltips.sequences.cohorts.multiSequenceParagraph',
                )}
              </p>
              <h2>{t('reveal.reports.tooltips.common.usefulness')}</h2>
              <p>
                {t(
                  'reveal.reports.tooltips.sequences.cohorts.usefulnessParagraph',
                )}
              </p>
              <p>
                {t(
                  'reveal.reports.tooltips.sequences.cohorts.exampleParagraph',
                )}
              </p>
              <h2>{t('reveal.reports.tooltips.common.surprised')}</h2>
              <p>
                {t(
                  'reveal.reports.tooltips.sequences.cohorts.surprisedParagraph',
                )}
              </p>
              <p>
                {t(
                  'reveal.reports.tooltips.sequences.cohorts.recencyParagraph',
                )}
              </p>
              <h2>{t('reveal.reports.tooltips.common.details')}</h2>
              <p>{t('reveal.reports.tooltips.common.barDetails')}</p>
            </InfoTooltip>
          </h3>
        }
        timeSpan={timeSpan}
        renderChildren={(frequency: Frequency, mode: Mode, type: ChartType) =>
          cohortsLoading ? (
            <Loader className='loader' active inline='centered' size='large' />
          ) : (
            <SequenceDataContainer
              dateRange={timeSpan}
              frequency={frequency}
              dailyCohorts={dailyCohorts}
              mode={mode}
              type={type}
              fetchDetails={fetchDetails}
            />
          )
        }
      />
    </div>
  );
};

export default SequencesDailyCohortsStatistics;
