import React, { PropsWithChildren, useContext } from 'react';
import useUserFromJWToken from '@/graphql/hooks/users/useUserFromJWToken';

interface AppContextInterface {
  clientId?: string | null;
  user?: {
    // TODO: complete with user type
    id: string;
    email: string;
  };
}

const AppContext = React.createContext<AppContextInterface | null>(null);

export function AppContextProvider({
  clientId,
  children,
}: PropsWithChildren<{
  clientId?: string | null;
}>) {
  const { data } = useUserFromJWToken();

  return (
    <AppContext.Provider value={{ clientId, user: data?.user }}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  const ctx = useContext(AppContext);
  if (!ctx) {
    throw new Error('Did you forget to wrap your app in <AppContextProvider>?');
  }

  return ctx;
}
