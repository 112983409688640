import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'underscore';

import MiniProfileSearchBar from '@/components/Reveal/Profile/MiniProfileSearchBar';
import { useMiniProfilesSearchContext } from '@/context/Profiles/useMiniProfilesSearch';
import RevealProfileMiniCard from '@/components/Reveal/Profile/RevealProfileMiniCard';
import EnrichedRevealProfileModal from '@/revealComponents/EnrichedRevealProfileModal';
import ProfileIdHandler from '@/common/ProfileIdHandler';
import { SearchIcon } from '../../../assets/icons';

import './GlobalSearchBar.css';

interface GlobalSearchProps {
  clientId: string;
  styles: any;
}

const GlobalSearchBar: React.FC<GlobalSearchProps> = ({ clientId, styles }) => {
  const [hideSelection, setHideSelection] = useState(true);
  const [selectedProfileId, setSelectedProfileId] = useState('');
  const location = useLocation();

  const currentUrl = `${location.pathname}${location.search}`;

  const profileIdHandler = ProfileIdHandler({
    profileIds: [],
    selectedProfileId,
    onSelectProfileId: (profileId: string) => setSelectedProfileId(profileId),
  });

  const {
    searchText,
    setSearchText,
    searchPoolProfiles,
  } = useMiniProfilesSearchContext();

  const handleClickOutside = () => {
    setTimeout(() => {
      setHideSelection(true);
      setSearchText('');
    }, 200);
  };

  const handleClickInside = () => {
    setHideSelection(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      // e.preventDefault();
      if ((e.metaKey || e.ctrlKey) && e.code === 'KeyK') {
        setHideSelection(false);
      }
    });
  });

  const onCloseProfileModal = () => {
    setSelectedProfileId('');
    window.history.replaceState(null, '', currentUrl);
  };

  if (selectedProfileId) {
    return (
      <EnrichedRevealProfileModal
        clientId={clientId}
        searches={{}}
        open={!!selectedProfileId}
        onClose={onCloseProfileModal}
        profileIdHandler={profileIdHandler}
        needNetworkCall
      />
    );
  }

  return (
    <div className={styles.iconNavigationItem}>
      <div className='global-search-icon-container' onClick={handleClickInside}>
        {hideSelection ? (
          <SearchIcon className='icon-navigation-item profiles-search-icon' />
        ) : null}
      </div>
      {!hideSelection && (
        <div className='global-search-bar-container'>
          <div className='global-search-bar'>
            <MiniProfileSearchBar onBlur={handleClickOutside} />
          </div>
          {!_.isEmpty(searchText) && !_.isEmpty(searchPoolProfiles) ? (
            <div className='mini-profile-search-results'>
              {_.map(searchPoolProfiles, (profile, index) => (
                <RevealProfileMiniCard
                  key={index}
                  profile={profile}
                  onClick={(_e: unknown) => {
                    setSearchText('');
                    window.history.replaceState(
                      null,
                      '',
                      `/client/${clientId}/reveal/search?profileId=${profile.id}`,
                    );
                    setSelectedProfileId(profile.id);
                    setHideSelection(true);
                  }}
                />
              ))}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default GlobalSearchBar;
