/* global gapi */
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useGoogleAuth2 } from '../../context/GoogleAuth2';
import { sentryCaptureException } from '../../common';
import OAuthSigninButton from './OAuthSigninButton';

const OAuthGmailSignin = ({
  label,
  onSignin,
  loading: externalLoading,
  onBeforeSignin,
  onError,
  mode = 'signin',
  t,
}) => {
  const [loading, setLoading] = useState(false);
  const auth2Promise = useGoogleAuth2();

  const onClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (typeof onBeforeSignin === 'function') {
        onBeforeSignin();
      }
      // TODO: use emailApi.onTriggerSignIn ?
      const auth2 = await auth2Promise;
      const googleUser = await auth2.signIn({ prompt: 'select_account' });
      const { id_token: token } = googleUser.getAuthResponse();
      const basicProfile = googleUser.getBasicProfile();
      const email = basicProfile?.getEmail();
      const firstname = basicProfile?.getGivenName();
      const lastname = basicProfile?.getFamilyName();

      let signature;
      let alias;

      if (mode === 'signup') {
        // emailApi.getEmailAddressDetails('gmail') does not work since
        // emailApi.onTriggerSignIn was not used above
        try {
          // https://developers.google.com/gmail/api/v1/reference/users/settings/sendAs/get
          const url = `https://www.googleapis.com/gmail/v1/users/me/settings/sendAs/${email}`;
          const response = await gapi.client.request(url);
          ({ signature, displayName: alias } = response?.result || {});
        } catch (error) {
          console.error('sendAs error', error);
        }
      }
      setLoading(false);
      return onSignin({
        id_token: token,
        type: 'gmail',
        firstname,
        lastname,
        email,
        alias,
        signature,
      });
    } catch (error) {
      setLoading(false);
      if (
        error?.error !== 'popup_closed_by_user' &&
        error?.error !== 'access_denied'
      ) {
        console.error(error);
        sentryCaptureException({ error });
        if (typeof onError === 'function') {
          onError({ error, provider: 'google' });
        }
      }
      return undefined;
    }
  };
  return (
    <OAuthSigninButton
      title={label || t('signin.signinWithGoogle')}
      text={label || t('signin.signinWithGoogle')}
      logoSrc='/images/logos/g-logo.png'
      disabled={loading || externalLoading}
      onClick={onClick}
    />
  );
};

export default withTranslation('translations')(OAuthGmailSignin);
