import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import ProfileTimeline from '../../graphql/fragments/ProfileTimeLine';

export const query = gql`
  query getProfileTimeline($profileId: ID!) {
    profile(id: $profileId) {
      ...ProfileTimeline
    }
  }
  ${ProfileTimeline}
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      profileId: ownProps.profileId,
    },
  }),
  props: ({ data: { loading, profile, error } }) => ({
    loading,
    profile,
    error,
  }),
  skip: (ownProps) => !ownProps.profileId,
});
