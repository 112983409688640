import React, { FC } from 'react';

interface SpeechBubbleProps {
  className?: string;
}

const SpeechBubble: FC<SpeechBubbleProps> = ({ className }) => (
  <svg
    className={className}
    width='.97em'
    height='1em'
    viewBox='0 0 292 302'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 301.252L57.3973 243.871H291.604V185.051H291.607V0H235.151H232.63H58.9766H57.2375H0V301.252ZM58.9766 59.0119V184.859H232.63V59.0119H58.9766Z'
      fill='currentColor'
    />
  </svg>
);

export default SpeechBubble;
