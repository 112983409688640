import React, { FC } from 'react';
import _ from 'underscore';
import { Trans, useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { Profile } from '@/types/profile';
import {
  CandidateInterestItem,
  CANDIDATE_INTEREST_ITEM_TYPE,
} from '@/common/reveal/SourceData.type';
import { useClientMarketplaceDescriptor } from '@/graphql/hooks/clients/useClientMarketplaceDescriptor';
import Interest from '@/components/Common/Icons/Interest';
import useClientId from '@/hooks/router/useClientId';

import styles from './CandidateClientInterestStates.module.less';

interface Props {
  profile: Profile;
  clientId: string;
}

const CandidateClientInterestStates: FC<Props> = ({ profile, clientId }) => {
  const clientInterestStates = _.findWhere(
    profile.resumeData.sourceData?.hiresweet?.clientInterestStates || [],
    { clientId },
  );

  const { marketplaceDescriptor } = useClientMarketplaceDescriptor(clientId);

  if (_.isEmpty(clientInterestStates)) {
    return <></>;
  }

  const companyGlobalInterestItem = _.findWhere(
    clientInterestStates?.interestItems || [],
    { type: CANDIDATE_INTEREST_ITEM_TYPE.COMPANY_GLOBAL },
  );

  if (!companyGlobalInterestItem || _.isEmpty(companyGlobalInterestItem)) {
    return null;
  }

  return (
    <CompanyGlobalInterestItem
      item={companyGlobalInterestItem}
      firstname={profile.resumeData.firstname}
      company={marketplaceDescriptor?.name}
    />
  );
};

interface CompanyGlobalInterestItemProps {
  item: CandidateInterestItem;
  firstname?: string;
  company?: string;
}

const CompanyGlobalInterestItem: FC<CompanyGlobalInterestItemProps> = ({
  item,
  firstname,
  company,
}) => {
  const clientId = useClientId();
  const { t } = useTranslation();
  const { creationDate, message } = item;
  const interestDate = new Date(creationDate);

  return (
    <div className={styles.wrapper}>
      <div className={styles.application}>
        <div className={styles.icon}>
          <Interest />
        </div>
        <div className='content'>
          <span className={styles.bold}>
            {t('profile.clientInterestStates.hasShownCompanyGlobalInterest', {
              firstname:
                firstname || t('profile.clientInterestStates.candidate'),
              company: company || t('profile.clientInterestStates.company'),
            })}
          </span>{' '}
          {t('profile.clientInterestStates.on', { date: interestDate })}
        </div>
      </div>
      <div className={styles.explanation}>
        <Trans
          i18nKey='profile.clientInterestStates.explanation'
          values={{
            company: company || t('profile.clientInterestStates.company'),
          }}
          components={{
            a: (
              <Link
                className={styles.link}
                to={`/client/${clientId}/company-settings`}
              />
            ),
          }}
        />
      </div>
      {message && !_.isEmpty(message?.text) && (
        <div className={styles.message}>{message.text}</div>
      )}
    </div>
  );
};

export default CandidateClientInterestStates;
