import React, { FC, useMemo } from 'react';
import _ from 'underscore';

import {
  ClientDailyCohort,
  ExclusiveTaskType,
  isExclusiveTaskType,
} from '@/types/statistics/clientStatistics';
import { TimeSpan } from '../../components/RevealAnalyticsHeader/RevealAnalyticsHeader';
import {
  ChartType,
  Frequency,
  Mode,
} from '../../components/GraphWrapper/types';
import * as colors from '../../../../../less/colors';
import { convertDailyCohort } from './helpers/cohorts';
import DailyEventsDataContainer from '../../components/DailyEventsDataContainer/DailyEventsDataContainer';

const STACK = [
  {
    datakey: 'nbContacted',
    color: colors.PrimaryCobalt80,
    i18nKey: 'reveal.reports.sequences.contactedNotReplied',
  },
  {
    datakey: 'nbAnswered',
    color: colors.SecondarySkyBlue,
    i18nKey: 'reveal.reports.sequences.repliedNotPositively',
  },
  {
    datakey: 'nbPositiveAnswered',
    color: colors.PositiveColor120,
    i18nKey: 'reveal.reports.sequences.repliedPositively',
  },
];

interface SequencesDataContainerProps {
  frequency: Frequency;
  dateRange: TimeSpan;
  type: ChartType;
  mode: Mode;
  dailyCohorts?: ClientDailyCohort[];
  fetchDetails?: (
    startDay: string,
    endDay: string,
    taskType: ExclusiveTaskType,
  ) => void;
}

const SequenceDataContainer: FC<SequencesDataContainerProps> = ({
  dailyCohorts,
  frequency,
  dateRange,
  mode,
  type,
  fetchDetails,
}) => {
  const dailyEvents = useMemo(
    () => _.map(dailyCohorts || [], convertDailyCohort),
    [dailyCohorts],
  );

  return (
    <DailyEventsDataContainer
      frequency={frequency}
      dateRange={dateRange}
      type={type}
      mode={mode}
      dailyEvents={dailyEvents}
      stack={STACK}
      fetchDetails={(startDay, endDay, taskType) => {
        if (fetchDetails && isExclusiveTaskType(taskType)) {
          fetchDetails(startDay, endDay, taskType);
        }
      }}
    />
  );
};

export default SequenceDataContainer;
