import _ from 'underscore';
import React from 'react';
import { Button, Modal, Input, Divider } from 'semantic-ui-react';
import { getRandomString } from '../../../../common';
import GradeButtonSelector from './GradeButtonSelector';
import EvaluationScorecardEditor from './EvaluationScorecardEditor';

import './EvaluationEditModal.css';

const EvaluationFieldEditor = ({ field, index, onUpdateField }) => {
  const onSelectGrade = ({ grade }) => {
    onUpdateField({
      newValue: {
        ...field,
        value: {
          ...field.value,
          grade,
        },
      },
    });
  };

  const onChange = (e, { value }) => {
    onUpdateField({
      newValue: {
        ...field,
        value: {
          ...field.value,
          comment: value,
        },
      },
    });
  };

  if (field.type === 'grade') {
    return (
      <div className='evaluation-edit-modal-field'>
        <h4>
          {index + 1}. {field.title}
        </h4>
        <GradeButtonSelector
          grade={(field.value || {}).grade}
          onSelectGrade={onSelectGrade}
        />
      </div>
    );
  }

  if (field.type === 'comment') {
    return (
      <div className='evaluation-edit-modal-field'>
        <h4>
          {index + 1}. {field.title}
        </h4>
        <Input
          placeholder='Your comment...'
          value={field.value?.comment}
          size='large'
          fluid
          onChange={onChange}
        />
      </div>
    );
  }

  if (field.type === 'grade-and-comment') {
    return (
      <div className='evaluation-edit-modal-field'>
        <h4>
          {index + 1}. {field.title}{' '}
        </h4>
        <GradeButtonSelector
          grade={(field.value || {}).grade}
          onSelectGrade={onSelectGrade}
        />
        <br />
        <br />
        <Input
          placeholder='Your comment...'
          value={field.value?.comment}
          size='large'
          fluid
          onChange={onChange}
        />
      </div>
    );
  }

  if (field.type === 'scorecard') {
    return (
      <EvaluationScorecardEditor
        index={index}
        scorecard={field}
        onUpdateField={onUpdateField}
      />
    );
  }
  return null;
};

class EvaluationEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    const getFormCopy = (form) => {
      return {
        ...form,
        fields: _.map(form.fields, (field) => ({
          ...field,
          id: getRandomString(10),
          ...(field.subfields && {
            subfields: _.map(field.subfields, (subfield) => ({
              ...subfield,
              id: getRandomString(10),
            })),
          }),
        })),
      };
    };

    this.state.evaluation = {
      ...this.props.initialValue,
      form: getFormCopy(this.props.initialValue.form || {}),
    };
  }

  handleUpdateFields = ({ newFields }) => {
    const { evaluation } = this.state;
    const currentForm = (evaluation || {}).form || {};
    this.setState({
      evaluation: {
        ...evaluation,
        form: {
          ...currentForm,
          fields: newFields,
        },
      },
    });
  };

  handleUpdateField = ({ fieldId, newValue }) => {
    const { evaluation } = this.state;
    const currentForm = (evaluation || {}).form || {};
    const newFields = _.map(currentForm.fields, (field) =>
      field.id !== fieldId
        ? field
        : {
            ...newValue,
            id: fieldId, // to be sure
          },
    );
    this.handleUpdateFields({ newFields });
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { evaluation } = this.state;
    onSubmit({ evaluation });
  };

  renderForm() {
    const { evaluation } = this.state;
    const { form } = evaluation || {};

    return (
      <div className='evaluation-edit-modal-fields-container'>
        {_.map(form.fields, (field, index) => (
          <div key={field.id}>
            {index > 0 ? <Divider /> : ''}
            <EvaluationFieldEditor
              field={field}
              index={index}
              onUpdateField={({ newValue }) => {
                this.handleUpdateField({ fieldId: field.id, newValue });
              }}
            />
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { onClose } = this.props;
    const { evaluation } = this.state;
    const { form } = evaluation;

    let disableSubmit = false;
    _.each(form.fields, (field) => {
      if (field.type === 'grade' || field.type === 'grade-and-comment') {
        if (field.value?.grade === undefined) {
          disableSubmit = true;
        }
      }
      _.each(field.subfields, (subfield) => {
        if (subfield.value?.grade === undefined) {
          disableSubmit = true;
        }
      });
    });

    return (
      <Modal
        className='evaluation-edit-modal'
        open
        onClose={onClose}
        closeIcon
        closeOnDimmerClick={false}
      >
        <Modal.Header>Evaluation</Modal.Header>
        <Modal.Content>{this.renderForm()}</Modal.Content>
        <Modal.Actions>
          <div className='align-left'>
            <Button className='dismiss' type='button' onClick={onClose}>
              Cancel
            </Button>
          </div>
          <Button
            primary
            type='button'
            size='big'
            onClick={this.handleSubmit}
            disabled={disableSubmit}
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default EvaluationEditModal;
