import React, { FC, Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useNotificationSystem from '@/hooks/common/useNotificationSystem';

import useAskReactivation from '@/graphql/hooks/clients/useAskReactivation';
import ConfirmationModal from '../../modals/ConfirmationModal';

interface AskReactivationModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onAsk: () => void;
}

const AskReactivationModal: FC<AskReactivationModalProps> = ({
  open,
  setOpen,
  onAsk,
}) => {
  const { t } = useTranslation();
  const [askReactivation, askReactivationResult] = useAskReactivation();
  const { success, error } = useNotificationSystem();

  useEffect(() => {
    if (askReactivationResult.error && error) {
      error(t('reveal.accountState.askReactivationModal.error'));
    }
    if (askReactivationResult.data && success) {
      success(t('reveal.accountState.askReactivationModal.success'));
    }
  }, [askReactivationResult, error, success, t]);

  return (
    <ConfirmationModal
      onCancel={() => setOpen(false)}
      onSubmit={() => {
        askReactivation();
        onAsk();
        setOpen(false);
      }}
      header={t('reveal.accountState.askReactivationModal.header')}
      open={open}
      content={t('reveal.accountState.askReactivationModal.description')}
      submit={t('reveal.accountState.askReactivationModal.submit')}
    />
  );
};

export default AskReactivationModal;
