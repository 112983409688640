import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Checkbox, Image } from 'semantic-ui-react';
import { getRandomDefaultAvatarLink, getSecureLink } from '../../../common';
import { isValidDate } from '../../../common/dates';
import { COMPLETE_TASK } from '../../../graphql/tasks';
import { getUserLabel } from '../optionsHelpers';
import StatusButton from '../revealComponents/Buttons/StatusButton';
import NameAndHeadline from '../revealComponents/RevealListRow/NameAndHeadline';

import './TaskRow.css';

export const TaskRowHeader = ({ checked, onToggleCheckbox }) => {
  const { t } = useTranslation();
  return (
    <div className='task-row header'>
      <div className='cell header-cell checkbox'>
        <Checkbox checked={checked} onChange={onToggleCheckbox} />
      </div>
      <div className='cell header-cell todo-title'>
        {t('reveal.tasks.taskTable.head.task')}
      </div>
      <div className='cell header-cell profile'>
        {t('reveal.tasks.taskTable.head.contact')}
      </div>
      <div className='cell header-cell owner'>
        {t('reveal.tasks.taskTable.head.owner')}
      </div>
      <div className='cell header-cell sequence'>
        {t('reveal.tasks.taskTable.head.sequence')}
      </div>
      <div className='cell header-cell status'>
        {t('reveal.tasks.taskTable.head.status')}
      </div>
      <div className='cell header-cell date'>
        {t('reveal.tasks.taskTable.head.date')}
      </div>
    </div>
  );
};

const TaskRow = ({ clientId, task, checked, onClickCheckbox, onClickTask }) => {
  const { t } = useTranslation();
  const [completeTask] = useMutation(COMPLETE_TASK);

  const {
    id,
    target,
    owner,
    projectId,
    queueId,
    sequenceId,
    missionId,
    state,
    type,
    dueDate,
  } = task || {};

  if (target?.__typename !== 'RevealProfile') {
    return null;
  }

  const { firstname, lastname, photoLink: avatarImageUrl } =
    target?.resumeData || {};

  const onMarkAsCompleted = async (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    try {
      await completeTask({
        variables: {
          input: {
            projectId,
            queueId,
            profileId: target?.id,
            sequenceId,
            missionId,
            task: {
              id,
              type,
            },
          },
        },
      });
    } catch (err) {
      console.error(err.message);
    }
  };

  const onSelect = (e) => {
    e.stopPropagation();
    onClickCheckbox();
  };

  let dueDateStr;
  if (dueDate) {
    try {
      const date = new Date(dueDate);
      dueDateStr = isValidDate(date)
        ? t('common.shortDateAndTime', { date })
        : '';
    } catch (e) {
      dueDateStr = '';
    }
  }

  return (
    <div
      className={`task-row ${checked ? 'selected' : ''}`}
      onClick={onClickTask}
    >
      <div className='cell checkbox' onClick={onSelect}>
        <Checkbox checked={checked} />
      </div>
      <div className='cell todo-title'>
        <span>{t(`sequences.tasks.${type || 'other'}`)}</span>
      </div>

      <div className='cell profile'>
        <Image
          src={
            avatarImageUrl
              ? getSecureLink(avatarImageUrl)
              : getRandomDefaultAvatarLink(firstname + lastname)
          }
          circular
          onError={(e) => {
            e.target.src = getRandomDefaultAvatarLink(firstname + lastname);
          }}
        />

        <NameAndHeadline
          clientId={clientId}
          searchPoolId='reveal'
          profile={target}
        />
      </div>

      <div className='cell owner'>
        <span>{getUserLabel(owner)}</span>
      </div>

      <div className='cell sequence'>
        {target?.currentSequenceInfo?.sequence?.title || '--'}
      </div>

      <div className='cell status'>
        <StatusButton
          state={state}
          taskType={type}
          onMarkAsCompleted={onMarkAsCompleted}
        />
      </div>

      <div className='cell date'>{dueDateStr}</div>
    </div>
  );
};

export default TaskRow;
