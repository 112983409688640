import _ from 'underscore';
import React, { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import {
  ArchivedState,
  PipelineState,
} from '@/components/PipelineSegmentation/pipelineSegmentation';
import { PipelineActionCountType } from '@/types/statistics/pipelineActions';
import usePipelineActionsStatistics from '@/graphql/hooks/clients/usePipelineActionsStatistics';
import useClientId from '@/hooks/router/useClientId';
import { getFullname } from '@/common/helpers/person';
import InfoTooltip from '@/components/Common/InfoTooltip';
import usePipelineActionsStatisticsDetails from '@/graphql/hooks/clients/usePipelineActionsStatisticsDetails';
import GenericModal from '@/components/Common/GenericModal';
import { TimeSpan } from '../../components/RevealAnalyticsHeader/RevealAnalyticsHeader';
import GraphWrapper from '../../components/GraphWrapper/GraphWrapper';
import {
  ChartType,
  Frequency,
  Mode,
} from '../../components/GraphWrapper/types';
import AuthorDataContainer from '../../components/AuthorDataContainer/AuthorDataContainer';
import {
  filterPipelineDailyEventsByProjects,
  getAuthorDailyEventsForCountType,
} from './helpers';
import { ActionDetail } from '../../components/StatsProfileAndActionTable/types';
import StatsProfileAndActionTable from '../../components/StatsProfileAndActionTable';
import { Segment } from '../../components/PipelineStatisticsByCategory/helpers/segments';
import DetailsModalHeader from '../../components/DetailsModalHeader';

import styles from './PipelineActionsStatistics.module.less';

interface PipelineActionsStatisticsProps {
  timeSpan: TimeSpan;
  missionIds: string[];
}

const segmentToCountType: Record<Segment, PipelineActionCountType> = {
  [PipelineState.PENDING]: 'nbMoveToPending',
  [PipelineState.IN_PROGRESS]: 'nbMoveToContacted',
  [PipelineState.REPLIED]: 'nbMoveToReplied',
  [PipelineState.INTERESTED]: 'nbMoveToInterested',
  [PipelineState.HIRED]: 'nbMoveToHired',
  [ArchivedState.ARCHIVED]: 'nbMoveToArchived',
};

// We don't display "moved to not contacted statistics"
const relevantStates = [
  PipelineState.IN_PROGRESS,
  PipelineState.INTERESTED,
  PipelineState.HIRED,
  ArchivedState.ARCHIVED,
] as const;

const paragraphListByPipelineState: Record<
  typeof relevantStates[number],
  readonly { title: string; paragraph: string }[]
> = {
  'in-progress': [
    {
      title: 'reveal.reports.tooltips.common.usefulness',
      paragraph:
        'reveal.reports.tooltips.missions.pipelineActions.in-progress.usefulnessParagraph',
    },
    {
      title: 'reveal.reports.tooltips.common.explanations',
      paragraph:
        'reveal.reports.tooltips.missions.pipelineActions.in-progress.multiMoveParagraph',
    },
    {
      title: 'reveal.reports.tooltips.common.details',
      paragraph: 'reveal.reports.tooltips.common.barDetails',
    },
  ],
  interested: [
    {
      title: 'reveal.reports.tooltips.common.recommendation',
      paragraph:
        'reveal.reports.tooltips.missions.pipelineActions.interested.recommendationParagraph',
    },
    {
      title: 'reveal.reports.tooltips.common.usefulness',
      paragraph:
        'reveal.reports.tooltips.missions.pipelineActions.interested.usefulnessParagraph',
    },
    {
      title: 'reveal.reports.tooltips.common.explanations',
      paragraph:
        'reveal.reports.tooltips.missions.pipelineActions.interested.multiMoveParagraph',
    },
    {
      title: 'reveal.reports.tooltips.common.details',
      paragraph: 'reveal.reports.tooltips.common.barDetails',
    },
  ],
  hired: [
    {
      title: 'reveal.reports.tooltips.common.surprised',
      paragraph:
        'reveal.reports.tooltips.missions.pipelineActions.hired.surprisedParagraph',
    },
    {
      title: 'reveal.reports.tooltips.common.explanations',
      paragraph:
        'reveal.reports.tooltips.missions.pipelineActions.hired.multiMoveParagraph',
    },
    {
      title: 'reveal.reports.tooltips.common.details',
      paragraph: 'reveal.reports.tooltips.common.barDetails',
    },
  ],
  archived: [
    {
      title: 'reveal.reports.tooltips.common.explanations',
      paragraph:
        'reveal.reports.tooltips.missions.pipelineActions.archived.multiMoveParagraph',
    },
    {
      title: 'reveal.reports.tooltips.common.details',
      paragraph: 'reveal.reports.tooltips.common.barDetails',
    },
  ],
};

interface StatsFilters {
  segment: Segment;
  authorEmail: string;
  startDay: string;
  endDay: string;
}

const PipelineActionsStatistics: FC<PipelineActionsStatisticsProps> = ({
  timeSpan,
  missionIds,
}) => {
  const { t, i18n } = useTranslation();
  const clientId = useClientId();
  const { loading, pipelineActionsByUser } = usePipelineActionsStatistics({
    clientId,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [filters, setFilters] = useState<StatsFilters | undefined>(undefined);

  const filteredPipelineActionsByUser = useMemo(
    () =>
      filterPipelineDailyEventsByProjects(
        missionIds,
        pipelineActionsByUser || [],
      ),
    [missionIds, pipelineActionsByUser],
  );

  const [
    fetchDetails,
    {
      pipelineActionsByUser: pipelineActionsByUserDetails,
      loading: detailsLoading,
    },
  ] = usePipelineActionsStatisticsDetails({
    queryOptions: { fetchPolicy: 'cache-first' },
  });

  const applyFilter = useCallback(
    (
      segment: Segment,
      startDay: string,
      endDay: string,
      authorEmail: string,
    ) => {
      setModalOpen(true);
      setFilters({ segment, startDay, endDay, authorEmail });
      fetchDetails({
        clientId,
        stagesFilter: { in: [segment] },
        authorEmailsFilter: { in: [authorEmail] },
        missionsFilter: !_.isEmpty(missionIds) ? { in: missionIds } : undefined,
        startDate: startDay,
        endDate: endDay,
      });
    },
    [setModalOpen, setFilters, missionIds, fetchDetails, clientId],
  );

  const actionDetails = useMemo(() => {
    if (!filters) {
      return [];
    }
    const { startDay, endDay, authorEmail, segment } = filters;
    const result: ActionDetail[] = [];

    _.each(pipelineActionsByUserDetails || [], ({ day, userStats }) => {
      if (day < startDay || day >= endDay) {
        return;
      }
      _.each(userStats, ({ author, stats }) => {
        if (author.email !== authorEmail) {
          return;
        }
        _.each(stats, ({ type, missionId, profileItems }) => {
          if (type !== segmentToCountType[segment]) {
            return;
          }
          if (!_.isEmpty(missionIds) && !_.contains(missionIds, missionId)) {
            return;
          }
          _.each(profileItems, ({ profileId }) => {
            result.push({ profileId, missionId, segment, day });
          });
        });
      });
    });

    return result;
  }, [filters, pipelineActionsByUserDetails, missionIds]);

  const authorFullnameMap = useMemo(() => {
    const result: Record<string, string> = {};

    _.each(filteredPipelineActionsByUser, ({ userStats }) => {
      _.each(userStats, ({ author }) => {
        const { email } = author;
        if (!result[email]) {
          result[email] = getFullname(author);
        }
      });
    });

    return result;
  }, [filteredPipelineActionsByUser]);

  const modalTitle = useMemo(() => {
    if (!filters?.segment || !filters?.authorEmail) {
      return '';
    }
    return t('reveal.reports.missions.moveTo.detailsModal.title', {
      segment: t(`reveal.pipelineSegmentations.${filters.segment}`),
      fullname: authorFullnameMap[filters.authorEmail],
    });
  }, [authorFullnameMap, filters, t]);

  const modalSubtitle = useMemo(() => {
    if (!filters?.startDay || !filters?.endDay) {
      return '';
    }
    const dateDisplayOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    const dayToLocaleString = (day: string): string =>
      new Date(day).toLocaleString(i18n.resolvedLanguage, dateDisplayOptions);

    return t('reveal.reports.missions.moveTo.detailsModal.subtitle', {
      startDate: dayToLocaleString(filters.startDay),
      endDate: dayToLocaleString(filters.endDay),
    });
  }, [filters, i18n, t]);

  return (
    <>
      {_.map(relevantStates, (pipelineState) => (
        <div key={pipelineState} className={styles.section}>
          <div className={styles.graph}>
            <GraphWrapper
              timeSpan={timeSpan}
              title={
                <h3 className={styles.title}>
                  {t(`reveal.reports.missions.moveTo.${pipelineState}`)}
                  <InfoTooltip rich hoverable position='right center'>
                    <h1>
                      {t(
                        `reveal.reports.tooltips.missions.pipelineActions.${pipelineState}.title`,
                      )}
                    </h1>
                    {_.map(
                      paragraphListByPipelineState[pipelineState],
                      ({ title, paragraph }) => (
                        <Fragment key={`${pipelineState}_${title}`}>
                          <h2>{t(title)}</h2>
                          <p>{t(paragraph)}</p>
                        </Fragment>
                      ),
                    )}
                  </InfoTooltip>
                </h3>
              }
              renderChildren={(
                frequency: Frequency,
                mode: Mode,
                type: ChartType,
              ) =>
                loading ? (
                  <div className={styles.loader}>
                    <Loader active inline='centered' size='large' />
                  </div>
                ) : (
                  <AuthorDataContainer
                    dateRange={timeSpan}
                    frequency={frequency}
                    authorDailyEvents={getAuthorDailyEventsForCountType(
                      segmentToCountType[pipelineState],
                      filteredPipelineActionsByUser,
                    )}
                    mode={mode}
                    type={type}
                    fetchDetails={(startDay, endDay, datakey) => {
                      applyFilter(pipelineState, startDay, endDay, datakey);
                    }}
                  />
                )
              }
            />
          </div>
        </div>
      ))}
      {detailsLoading ? (
        <Dimmer active>
          <Loader size='large' />
        </Dimmer>
      ) : (
        <GenericModal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Content>
            <DetailsModalHeader>
              {modalTitle && <h1>{modalTitle}</h1>}
              {modalSubtitle && <h2>{modalSubtitle}</h2>}
            </DetailsModalHeader>
            <StatsProfileAndActionTable actionDetails={actionDetails} />
          </Modal.Content>
        </GenericModal>
      )}
    </>
  );
};

export default PipelineActionsStatistics;
