import React, { useEffect, useMemo, useState } from 'react';
import _, { compose } from 'underscore';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { useTranslation } from 'react-i18next';
import { Divider } from 'semantic-ui-react';
import { ProfileContainer, ProfileDocuments } from '@hiresweet/hiresweet-lib';

import { REMOVE_PROFILE_IN_SEARCH_POOL } from '@/graphql/searchPoolProfile';
import contextToProps from '@/hocs/contextToProps';
import { combineDataWithCustomFields } from '@/common/customFields';
import { getATSDataItems } from '@/common/reveal/SourceData';
import { useClientProfileCustomFields } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import ProfileDetails, {
  ProfileApplications,
  ProfileAdditionalDetails,
} from '@/revealComponents/ProfileDetails';
import ProfileProjectsTab from '@/revealComponents/ProfileProjectsTab';
import ProfileSimilarProfiles, {
  MAX_SIMILAR_PROFILES,
} from '@/revealComponents/ProfileSimilarProfiles';
import ProfileTabsMenu from '@/revealComponents/ProfileTabsMenu';
import ATSData from '@/containers/Profile/Resume/Card/ATSData';
import { ATS_NAMES_BY_TYPE } from '@/common/reveal';
import ATSStatusBanner from '@/revealComponents/ATSStatusBanner';
import ProfileOverview from '@/revealComponents/ProfileOverview';
import EnrichmentProvider from '@/components/Reveal/Profile/contactout/enrichmentProvider';
import RevealCandidateViewTimeLineItems from '@/containers/Profile/Contact/TimeLineItems/RevealCandidateViewTimeLineItems';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import CompanyOverview from '@/components/CompanyOverview';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import { getAllAttachments } from '@/common/helpers/profile';
import { getFullname } from '@/common/helpers/person';
import { ATTACHMENT_TYPE_RESUME } from '@/common/constants/attachments';

import ModalDeletion from './ModalDeletion';
import CandidatePage from './CandidatePage';
import CandidateEditForm from './CandidateEditForm';
import ProfileTabContent from './ProfileTabContent';
import ConnectBox from './ConnectBox';
import ProfileHiresweetDetails from './HiresweetDetails';
import ProfileLinkedCompany, {
  EditProfileLinkedCompanyModal,
} from './ProfileLinkedCompany';
import CompanyEditForm from './CompanyEditForm';
import OverviewCard from './OverviewCard';

import styles from './ResumeDataCard.module.less';

const ResumeDataCard = ({
  searchPoolId,
  searchPoolProfile,
  profileId,
  clientId,
  withSimilarProfiles,
  onShowNotification,
  onCloseProfileModal,
  toggleEditMode,
  isEditing,
  openDeleteModal,
  setOpenDeleteModal,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('profile');
  const [linkedCompanyModalOpen, setLinkedCompanyModalOpen] = useState(false);
  const configurationParams = useMergedConfigurationParams();
  const { applicationMode } = useNavApplicationMode();
  const {
    resumeData,
    similarProfilesSearchString,
    creationTimestamp,
    missionsInfo,
    availability,
    contactCategory,
    company,
  } = searchPoolProfile || {};
  const isCompany = contactCategory?.type === 'company';
  const { permissions } = useClientPermissions(clientId);

  const [similarProfilesSearch, setSimilarProfilesSearch] = useState({
    free: similarProfilesSearchString,
  });

  const [deleteProfile] = useMutation(REMOVE_PROFILE_IN_SEARCH_POOL);

  const atsDataItems = getATSDataItems(resumeData?.sourceData);

  const onConfirmProfileDeletion = async () => {
    try {
      await deleteProfile({
        variables: { searchPoolId, profileId },
        refetchQueries: [
          'getSearchPoolJobProfiles',
          'getRevealSearchPoolResults',
          'getSearchPoolJobProfileResults',
          'getSearchPoolJobs',
        ],
      });
      onShowNotification({
        level: 'success',
        message: t('reveal.profileDeletion.success'),
      });
      setOpenDeleteModal(false);
      if (onCloseProfileModal) {
        onCloseProfileModal();
      }
    } catch (e) {
      onShowNotification({
        level: 'error',
        message: t('reveal.profileDeletion.error'),
      });
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    setSimilarProfilesSearch({ free: similarProfilesSearchString });
    // eslint-disable-next-line
  }, [searchPoolProfile?.id]);

  useEffect(() => {
    if (isCompany) {
      setActiveTab('company-overview');
    } else {
      setActiveTab(configurationParams?.defaultProfileActivePane || 'profile');
    }
  }, [profileId, configurationParams?.defaultProfileActivePane, isCompany]);

  const allAttachments = getAllAttachments(searchPoolProfile);

  const sourceData = resumeData?.sourceData || {};

  const nbMissions = (missionsInfo || []).length;
  const { profileCustomFields: customFields } =
    useClientProfileCustomFields(clientId);
  const customDetails = combineDataWithCustomFields(customFields, resumeData);
  const customFieldsInitialValues = _.object(
    customDetails.map((o) => [o.clientCustomFieldId, o.rawValue]),
  );

  const customFieldsForCategory = useMemo(() => {
    if (
      !contactCategory ||
      !contactCategory.type ||
      contactCategory?.type === 'human'
    ) {
      return _.filter(
        customFields,
        (field) =>
          !field.contactCategory ||
          !field.contactCategory.type ||
          field.contactCategory.type === 'human',
      );
    }
    return _.filter(
      customFields,
      (field) => field.contactCategory?.type === contactCategory.type,
    );
  }, [customFields, contactCategory]);

  if (isEditing) {
    return contactCategory?.type === 'human' ||
      !contactCategory ||
      !contactCategory.type ? (
      <CandidateEditForm
        customFields={customFieldsForCategory}
        setEditMode={toggleEditMode}
        profileId={profileId}
        initialValues={{
          firstname: resumeData?.firstname,
          lastname: resumeData?.lastname,
          headline: resumeData?.headline?.content?.text,
          email: resumeData?.email,
          email2: resumeData?.email2,
          email3: resumeData?.email3,
          phoneNumber: resumeData?.phoneNumber,
          phoneNumber2: resumeData?.phoneNumber2,
          phoneNumber3: resumeData?.phoneNumber3,
          linkedin: resumeData?.sources?.linkedin,
          github: resumeData?.sources?.github,
          stackoverflow: resumeData?.sources?.stackoverflow,
          customFields: customFieldsInitialValues,
          gender: [resumeData?.gender?.value],
          availability: {
            nextAvailabilityDate: availability?.nextAvailabilityDate,
          },
          contactCategory: contactCategory || { type: 'human', subtypes: [] },
        }}
        isGenderManuallySet={resumeData?.gender?.isLocked}
      />
    ) : (
      <CompanyEditForm
        customFields={customFieldsForCategory}
        setEditMode={toggleEditMode}
        profileId={profileId}
        initialValues={{
          name: resumeData?.name || resumeData?.firstname,
          email: resumeData?.email,
          email2: resumeData?.email2,
          email3: resumeData?.email3,
          phoneNumber: resumeData?.phoneNumber,
          phoneNumber2: resumeData?.phoneNumber2,
          phoneNumber3: resumeData?.phoneNumber3,
          companyLinkedin:
            resumeData?.sources?.companyLinkedin ||
            resumeData?.sources?.linkedin ||
            '',
          customFields: customFieldsInitialValues,
          contactCategory: contactCategory || { type: 'company', subtypes: [] },
          website: resumeData?.sources?.website,
        }}
      />
    );
  }

  const firstAtsDataItem = _.sortBy(atsDataItems, (x) =>
    x.type === 'generic' ? 1 : 0,
  )[0];

  const attachmentsForBackground =
    !!permissions && !permissions.profileDocumentsTab
      ? allAttachments
      : _.where(allAttachments, { type: ATTACHMENT_TYPE_RESUME }).slice(0, 1);

  return (
    <CandidatePage className='resume-data-card'>
      <ProfileTabsMenu
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isCompany={contactCategory?.type === 'company'}
        withSimilarProfiles={withSimilarProfiles}
        nbMissions={nbMissions}
        atsName={firstAtsDataItem?.asAtsType || firstAtsDataItem?.type}
        hasHiresweetDetails={
          !_.isEmpty(searchPoolProfile?.hiresweetDetails) &&
          clientId === 'quentin-qa-demo'
        }
      />
      <CandidatePage.Content>
        <ModalDeletion
          profileName={resumeData?.name ?? getFullname(resumeData)}
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          onConfirm={onConfirmProfileDeletion}
        />
        {activeTab === 'profile' && (
          <>
            {!_.isEmpty(sourceData.connect) && (
              <ConnectBox clientId={clientId} profile={searchPoolProfile} />
            )}
            <ProfileOverview profile={searchPoolProfile} />
            {sourceData?.type !== 'greenhouse' && (
              <ATSData profile={searchPoolProfile} clientId={clientId} />
            )}
            <ATSStatusBanner clientId={clientId} profile={searchPoolProfile} />
            <OverviewCard profile={searchPoolProfile} />
            {searchPoolProfile.resumeData?.sources?.linkedin && (
              <>
                <EnrichmentProvider
                  provider='contactout'
                  title='ContactOut'
                  icon='/images/logos/contactout.svg'
                  profile={searchPoolProfile}
                  clientId={clientId}
                />
                <EnrichmentProvider
                  provider='kaspr'
                  title='Kaspr'
                  icon='/images/logos/kaspr.png'
                  profile={searchPoolProfile}
                  clientId={clientId}
                />
                <EnrichmentProvider
                  provider='rocketreach'
                  title='RocketReach'
                  icon='/images/logos/rocketreach.svg'
                  profile={searchPoolProfile}
                  clientId={clientId}
                />
              </>
            )}
            {applicationMode === 'reveal' &&
              permissions?.canHandleCompanyContacts &&
              permissions?.canEditHumanContactSubtypes &&
              contactCategory?.type === 'human' &&
              _.some(contactCategory.subtypes, ({ id }) => id === 'sales') && (
                <>
                  <hr />
                  <ProfileLinkedCompany
                    company={company}
                    onEditLinkedCompany={setLinkedCompanyModalOpen}
                  />
                </>
              )}
            {applicationMode === 'reveal' && <hr />}
            <ProfileTabContent
              attachments={attachmentsForBackground}
              profileId={profileId}
              resumeData={resumeData}
              clientId={clientId}
              searchPoolId={searchPoolId}
              profile={searchPoolProfile}
              toggleEditMode={toggleEditMode}
              creationTimestamp={creationTimestamp}
            />
          </>
        )}
        {activeTab === 'ATS' && (
          <div className='ats-details'>
            {_.map(_.sortBy(atsDataItems, 'type'), (atsDataItem, index) => (
              <div key={index} className='generic-ats-details-section'>
                {atsDataItems.length > 1 && (
                  <div>
                    {index > 0 && <br />}
                    {index > 0 && <Divider />}
                    <h2>{getIntegrationName(atsDataItem)}</h2>
                    <br />
                  </div>
                )}
                <ProfileAdditionalDetails
                  clientId={clientId}
                  atsType={atsDataItem?.type}
                  atsData={atsDataItem}
                  profile={searchPoolProfile}
                />
                {/* Adventure renders its own applications */}
                {atsDataItem.type !== 'adventure' && (
                  <ProfileApplications atsData={atsDataItem} t={t} />
                )}
                <br />
                <br />
                <br />
              </div>
            ))}
          </div>
        )}
        {activeTab === 'details' && (
          <ProfileDetails
            customFields={customFields}
            profileId={profileId}
            isWatchProfile={false}
          />
        )}
        {activeTab === 'missions' && profileId && (
          <ProfileProjectsTab
            clientId={clientId}
            profileId={profileId}
            missionsInfo={missionsInfo}
            missions={_.pluck(missionsInfo, 'mission')}
          />
        )}
        {activeTab === 'similar' && (
          <ProfileSimilarProfiles
            searchPoolId={searchPoolId}
            profileId={profileId}
            searches={similarProfilesSearch}
            // +1 because often the current profile is included in results so we remove it later
            maxNbResults={MAX_SIMILAR_PROFILES + 1}
          />
        )}
        {activeTab === 'timeline' && (
          <>
            <br />
            <RevealCandidateViewTimeLineItems
              clientId={clientId}
              searchPoolId={searchPoolId}
              hideSourced
              loading={false}
              sequenceOwner={searchPoolProfile?.currentSequenceInfo?.author}
              openMessageModal={() => {}}
            />
          </>
        )}
        {activeTab === 'hs-data' && (
          <ProfileHiresweetDetails
            details={searchPoolProfile?.hiresweetDetails || {}}
          />
        )}
        {activeTab === 'company-overview' && (
          <CompanyOverview profileId={profileId} />
        )}
        {activeTab === 'documents' && (
          <div className='profile-documents'>
            {_.isEmpty(allAttachments) &&
            !!permissions &&
            !permissions.profileDocumentsTab ? (
              <div className='no-attachments'>
                <p>{t('reveal.profileModal.documentsView.emptyState')}</p>
              </div>
            ) : (
              <ProfileContainer profileId={profileId}>
                <ProfileDocuments
                  attachments={allAttachments}
                  showCreationButton={false}
                  modalClassName={styles.profileDocumentPreviewModal}
                />
              </ProfileContainer>
            )}
          </div>
        )}
        <EditProfileLinkedCompanyModal
          open={linkedCompanyModalOpen}
          profileId={profileId}
          linkedCompany={company}
          onClose={() => setLinkedCompanyModalOpen(false)}
        />
      </CandidatePage.Content>
    </CandidatePage>
  );
};

const getIntegrationName = (atsDataItem) => {
  const effectiveType = atsDataItem.asAtsType || atsDataItem.type;
  return ATS_NAMES_BY_TYPE[effectiveType] || atsDataItem.type;
};

export default compose(contextToProps)(ResumeDataCard);
