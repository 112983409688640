import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SearchPoolProfileState from '../../graphql/fragments/SearchPoolProfileState';

export const markHidden = gql`
  mutation markHidden($searchPoolId: ID!, $profileId: ID!) {
    markHidden(searchPoolId: $searchPoolId, profileId: $profileId) {
      ...SearchPoolProfileState
    }
  }
  ${SearchPoolProfileState}
`;

export default graphql(markHidden, {
  props: ({ mutate }) => ({
    markHidden: ({ searchPoolId, profileId }) =>
      mutate({
        variables: { searchPoolId, profileId },
      }),
  }),
});
