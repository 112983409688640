import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './svg.module.less';

interface HardHatProps {
  className?: string;
}

const HardHat: FC<HardHatProps> = ({ className }) => (
  <svg 
    className={classNames(styles.svgIcon, className)} 
    height="1.2em" 
    width="1.2em" 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 594.091 594.091"
  >
    <path
        stroke="black" 
        strokeWidth="4" 
      d="M594.091,449.218c0-28.092-3.916-55.873-11.639-82.57l-2.135-7.378h-21.596c-2.017-53.107-19.754-103.45-51.611-146.173 c-31.63-42.419-76.247-74.823-126.108-91.72l2.366-32.94l-8.076-2.226c-25.374-6.994-51.697-10.54-78.239-10.54 c-26.54,0-52.864,3.546-78.238,10.54l-8.077,2.226l2.366,32.94c-49.866,16.896-94.488,49.301-126.12,91.72 C55.125,255.818,37.387,306.161,35.37,359.27H13.773l-2.135,7.378C3.916,393.345,0,421.126,0,449.218v10.217h137.393l38.525,58.986 h242.256l38.525-58.986h137.393V449.218z M490.728,225.312c29.206,39.167,45.537,85.287,47.542,133.958H363.919l15.566-216.763 C423.416,158.515,462.626,187.624,490.728,225.312z M297.053,96.105c21.897,0,43.63,2.592,64.723,7.711L343.432,359.27h-92.756 L232.33,103.816C253.423,98.697,275.157,96.105,297.053,96.105z M103.365,225.312c28.105-37.688,67.32-66.797,111.257-82.807 l15.568,216.764H55.821C57.827,310.598,74.158,264.478,103.365,225.312z M407.113,497.986H186.978L161.8,459.435h270.491 L407.113,497.986z M20.62,439.001c0.732-20.109,3.623-39.985,8.626-59.296h535.599c5.004,19.311,7.894,39.187,8.625,59.296H20.62z" />
  </svg>
);


export default HardHat;

