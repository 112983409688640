import React, { FC, useMemo, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';

import {
  ArchivedState,
  PipelineState,
} from '@/components/PipelineSegmentation/pipelineSegmentation';
import { Category } from '@/types/statistics/category';
import { ShowMoreRow } from '@/components/Tables/ShowMore';
import useLocalShowMore from '@/hooks/ui/useLocalShowMore';
import GenericModal from '@/components/Common/GenericModal';
import PipelineFunnelSummary from './PipelineFunnelSummary';
import CategoryFunnelStatistics from './CategoryFunnelStatistics/CategoryFunnelStatistics';
import {
  DisplayType,
  PipelineCountDataPointForCategory,
  PipelineDetailDataPointForCategory,
  SegmentListeners,
} from './types';
import {
  getConversionRates,
  getCurrentCountsBySegment,
  getEverCountsBySegment,
} from './helpers/count';
import { segments, states } from './helpers/segments';
import { usePipelineCounts, usePipelineDetails } from './hooks';
import StatsProfileAndMissionTable from '../StatsProfileAndMissionTable';
import DetailsModalHeader from '../DetailsModalHeader';

import styles from './PipelineStatisticsByCategory.module.less';

interface PipelineStatisticsByCategoryProps {
  categories: Category[];
  data: PipelineCountDataPointForCategory[];
  displayType: DisplayType;
  categorization: string;
  detailsData?: PipelineDetailDataPointForCategory[];
  detailsLoading?: boolean;
  fetchDetails?: (filters: {
    categoryIds?: string[];
    stages?: PipelineState[];
    archivedStates?: ArchivedState[];
  }) => void;
}

const PipelineStatisticsByCategory: FC<PipelineStatisticsByCategoryProps> = ({
  categorization,
  categories,
  data,
  displayType,
  detailsData,
  detailsLoading,
  fetchDetails,
}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const {
    globalConversionRates,
    countsByCategory,
    summaryData,
  } = usePipelineCounts(data, displayType);

  const { filter, applyFilter, profileDetails } = usePipelineDetails(
    detailsData,
    displayType,
    fetchDetails,
  );

  const filteredCategories = _.filter(
    categories,
    ({ id }) => countsByCategory[id] !== undefined,
  );

  const globalClickListeners = useMemo(() => {
    if (!fetchDetails) {
      return undefined;
    }

    return _.reduce(
      segments,
      (listeners, segment) => ({
        ...listeners,
        [segment]: () => {
          setModalOpen(true);
          applyFilter({ segment });
        },
      }),
      {} as SegmentListeners,
    );
  }, [fetchDetails, applyFilter]);

  const clickListenersByCategory = useMemo(
    () =>
      _.reduce(
        _.keys(countsByCategory),
        (result, categoryId) => ({
          ...result,
          [categoryId]: !fetchDetails
            ? undefined
            : _.reduce(
                segments,
                (listeners, segment) => ({
                  ...listeners,
                  [segment]: () => {
                    setModalOpen(true);
                    applyFilter({
                      categoryId,
                      segment,
                    });
                  },
                }),
                {} as SegmentListeners,
              ),
        }),
        {} as Record<string, SegmentListeners | undefined>,
      ),
    [fetchDetails, applyFilter, countsByCategory],
  );

  const [displayableCategories, showMoreRowProps] = useLocalShowMore({
    items: filteredCategories,
    defaultDisplayLimit: 10,
  });

  const modalTitle = useMemo(() => {
    const titleParts = [] as string[];
    if (filter?.categoryId) {
      const categoryTitle = _.findWhere(categories, { id: filter?.categoryId })
        ?.title;
      if (categoryTitle) {
        titleParts.push(categoryTitle);
      }
    }
    if (filter?.segment) {
      titleParts.push(t(`reveal.pipelineSegmentations.${filter.segment}`));
    }
    return titleParts.join(' - ');
  }, [filter, categories, t]);

  return (
    <>
      <PipelineFunnelSummary
        data={summaryData}
        conversionRates={globalConversionRates}
        clickListeners={globalClickListeners}
      />
      <div className={styles.tableHeader}>
        <div className={styles.name}>{categorization}</div>
        {_.map(states, (stateId, index) => (
          <div
            className={`${styles.headerStage} ${
              index !== states.length - 1 ? styles.span2 : ''
            }`}
            key={stateId}
          >
            {t(`reveal.pipelineSegmentations.${stateId}`)}
          </div>
        ))}
        <div
          className={`${styles.headerStage} ${styles[ArchivedState.ARCHIVED]}`}
        >
          {t(`reveal.pipelineSegmentations.${ArchivedState.ARCHIVED}`)}
        </div>
      </div>
      {_.map(displayableCategories, ({ id: categoryId }) => {
        const counts = countsByCategory[categoryId];
        const everCountsBySegment = getEverCountsBySegment(counts);
        const conversionRates = getConversionRates(t)(everCountsBySegment);
        const datapoint =
          displayType === 'current'
            ? getCurrentCountsBySegment(counts)
            : everCountsBySegment;

        return (
          <CategoryFunnelStatistics
            conversionRates={conversionRates}
            key={categoryId}
            data={datapoint}
            category={_.find(categories, ({ id }) => id === categoryId)}
            clickListeners={clickListenersByCategory[categoryId]}
          />
        );
      })}
      <ShowMoreRow {...showMoreRowProps} />
      {detailsLoading ? (
        <Dimmer active>
          <Loader size='large' />
        </Dimmer>
      ) : (
        <GenericModal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Content>
            <DetailsModalHeader>
              <h1>{modalTitle}</h1>
            </DetailsModalHeader>
            <StatsProfileAndMissionTable profileDetails={profileDetails} />
          </Modal.Content>
        </GenericModal>
      )}
    </>
  );
};

export default PipelineStatisticsByCategory;
