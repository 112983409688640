import React, { useState, useEffect } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { CLIENT_SEQUENCES } from '@/containers/Parameters/Sequences/queries';
import TASK_ICONS from '../TaskIcons';
import IntegrationsSynchronizationStatus from '../IntegrationsSynchronizationStatus';
import { TimelineItemSynchronizationActions } from '../TimelineItemActions';
import AuthorAndDate from '../AuthorAndDate';

const EnrolledInSequenceItem = ({
  item,
  unbiasedModeEnabled,
  profile,
  clientId,
}) => {
  const { t } = useTranslation();

  const [sequence, setSequence] = useState('');
  const { action, author } = item || {};
  const { firstname, lastname } = profile?.resumeData || {};
  let profileName = `${firstname || ''} ${lastname || ''}`.trim();
  profileName = unbiasedModeEnabled ? '???' : profileName;
  const { loading, data } = useQuery(CLIENT_SEQUENCES, {
    variables: { clientId },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (!loading) {
      setSequence(
        _.findWhere(data?.client?.sequences, {
          id: action.sequenceId,
        }),
      );
    }
    // eslint-disable-next-line
  }, [loading]);

  if (loading) {
    return <></>;
  }
  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{
                backgroundColor: TASK_ICONS.enrolledInSequence.color,
              }}
              className='icon'
            >
              <i className={`${TASK_ICONS.enrolledInSequence.icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  `profile.contact.timeline.enrolledInSequence.description`,
                  {
                    authorName: `${author?.firstname ||
                      ''} ${author?.lastname || ''}`,
                    profileName,
                    sequenceName: sequence?.title,
                  },
                ),
              }}
            />
            <AuthorAndDate timelineItem={item}>
              <IntegrationsSynchronizationStatus
                timelineItem={item}
                clientId={clientId}
                profile={profile}
              />
            </AuthorAndDate>
          </div>
          <div className='timeline-item-actions'>
            <TimelineItemSynchronizationActions
              clientId={clientId}
              profile={profile}
              timelineItem={item}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnrolledInSequenceItem;
