import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'underscore';
import { Button, Modal } from 'semantic-ui-react';

import withActionLogger from '../../../../../../hocs/withActionLogger';
import withUnlockProfile from '../../../../../../hocs/profiles/withUnlockProfile';
import contextToProps from '../../../../../../hocs/contextToProps';
import withChangeProfileStep from '../../../../../../hocs/profiles/withChangeProfileStep';

import './UnlockProfileModal.css';

const LOCK_DURATION = 3000;

const UnlockProfileModal = ({
  clientId,
  stepId,
  profile,
  unlockProfile,
  changeProfileStep,
  onShowNotification,
  // TODO: refactor these props as Context or single method
  getNextProfileId,
  getProfilesInMove,
  onChangeProfile,
  onChangeStep,
  handleMarkProfileAsInMove,
  handleUnmarkProfileAsInMove,
  addProfileAction,

  t,
}) => {
  const lockExpired =
    parseFloat(profile?.lockedSince) + LOCK_DURATION < Date.now();
  const [open, setOpen] = useState(false);
  const [showTrigger, setShowTrigger] = useState(lockExpired);
  const onClose = () => setOpen(false);

  useEffect(() => {
    setTimeout(() => setShowTrigger(true), LOCK_DURATION);
  }, []);

  if (!showTrigger) {
    return null;
  }

  const trigger = (
    <div className='unlock-send-trigger' onClick={() => setOpen(true)}>
      {t('profile.contact.drafts.unlock')}
    </div>
  );

  const unlockWithoutChangingStep = async () => {
    try {
      await unlockProfile({ profileId: profile?.id });

      onShowNotification({
        message: t('profile.contact.drafts.unlockProfileModal.success'),
        level: 'success',
      });
    } catch (e) {
      onShowNotification({
        message: t('profile.contact.drafts.unlockProfileModal.error'),
        level: 'error',
      });
    }
  };

  const unlockAndMoveToContacted = async () => {
    const profileId = profile?.id;
    try {
      if (profile?.resumeData?.step === 'pending') {
        handleMarkProfileAsInMove({ profileId });

        const nextProfileId = getNextProfileId({
          forbiddenProfileIds: getProfilesInMove(),
        });

        if (nextProfileId) {
          await onChangeProfile(nextProfileId);
        }

        if (!nextProfileId) {
          const forbiddenProfileId = profileId;
          await onChangeStep({
            newStepId: stepId,
            forceRefresh: true,
            forbiddenProfileId,
            originalProfileId: profileId,
          });
        }

        await changeProfileStep({
          id: profileId,
          step: 'contacted',
          oldStep: 'pending',
          offerId: profile?.jobOfferId,
        });

        const lastProfileAction = {
          clientId,
          jobOfferId: profile?.jobOfferId,
          profileId,
          stepId,
          type: 'change-profile-step',
        };

        try {
          addProfileAction(lastProfileAction);
        } catch (e) {
          console.error(e);
        }

        handleUnmarkProfileAsInMove({ profileId });
      }

      await unlockProfile({ profileId });

      onShowNotification({
        message: t('profile.contact.drafts.unlockProfileModal.success'),
        level: 'success',
      });
    } catch (e) {
      onShowNotification({
        message: t('profile.contact.drafts.unlockProfileModal.error'),
        level: 'error',
      });
    }
  };

  return (
    <div>
      <Modal
        className='unlock-profile-modal'
        open={open}
        onClose={onClose}
        trigger={trigger}
        closeIcon
      >
        <Modal.Header>
          {t('profile.contact.drafts.unlockProfileModal.header')}
        </Modal.Header>
        <Modal.Content>
          <span className='modal-content'>
            {profile?.resumeData?.step === 'pending'
              ? t('profile.contact.drafts.unlockProfileModal.contentPending')
              : t('profile.contact.drafts.unlockProfileModal.contentOtherStep')}
          </span>
        </Modal.Content>
        <Modal.Actions className='modal-actions-buttons'>
          <div className='align-left'>
            <Button className='dismiss' type='submit' onClick={onClose}>
              {t('profile.contact.drafts.unlockProfileModal.cancel')}
            </Button>
          </div>
          {profile?.resumeData?.step === 'pending' ? (
            <>
              <Button
                secondary
                size='big'
                type='submit'
                onClick={unlockWithoutChangingStep}
              >
                {t(
                  'profile.contact.drafts.unlockProfileModal.confirmAndStayPending',
                )}
              </Button>
              <Button
                primary
                size='big'
                type='submit'
                onClick={unlockAndMoveToContacted}
              >
                {t(
                  'profile.contact.drafts.unlockProfileModal.confirmAndMoveToContacted',
                )}
              </Button>
            </>
          ) : (
            <Button
              secondary
              size='big'
              type='submit'
              onClick={unlockWithoutChangingStep}
            >
              {t('profile.contact.drafts.unlockProfileModal.confirmAnyway')}
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default compose(
  withActionLogger,
  withUnlockProfile,
  withChangeProfileStep,
  contextToProps,
  withTranslation('translations'),
)(UnlockProfileModal);
