import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import { EvaluationsSet } from '../../graphql/fragments/Evaluations';

export const addProfileEvaluation = gql`
  mutation addProfileEvaluation(
    $id: ID!
    $evaluationsSetId: ID!
    $evaluation: EvaluationInput
  ) {
    addProfileEvaluation(
      id: $id
      evaluationsSetId: $evaluationsSetId
      evaluation: $evaluation
    ) {
      id
      evaluationsSets {
        ...EvaluationsSet
      }
    }
  }
  ${EvaluationsSet}
`;

export default graphql(addProfileEvaluation, {
  props: ({ mutate }) => ({
    addProfileEvaluation: ({ id, evaluationsSetId, evaluation }) => {
      const variables = { id, evaluationsSetId, evaluation };
      return mutate({ variables });
    },
  }),
});
