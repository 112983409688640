import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { ConditionsChainingVariable } from '@/common/mergeTags/utils';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import { getInitialConditionsChainingState } from './utils';
import useConditionsChainingCustomFields from './useConditionsChainingCustomFields';

interface ContextInterface {
  clientId: string;
  conditionsChainingState: ConditionsChainingVariable;
  setConditionsChainingState: React.Dispatch<
    React.SetStateAction<ConditionsChainingVariable>
  >;
  isIdInStateTree: ({ node, id }: { node: any; id: string }) => boolean;
  hasIfStatements: boolean;
  getStateTreeAfterReplacement: <T>({
    node,
    id,
    newValue,
  }: {
    node: any;
    id: any;
    newValue: T;
  }) => any;
}

interface ProviderProps {
  initialValue?: SweetEvaluatorTypes.BaseVariable & ConditionsChainingVariable;
  clientId: string;
}

const ConditionsChainingContext = React.createContext<ContextInterface | null>(
  null,
);

const ConditionsChainingProvider: React.FC<ProviderProps> = ({
  initialValue,
  clientId,
  children,
}) => {
  const { t } = useTranslation();

  const {
    profileCustomFields,
    missionCustomFields,
  } = useConditionsChainingCustomFields({ clientId });

  const subtype = initialValue?.subtype || null;
  const switchFieldId = initialValue?.switchField?.fieldId;
  const initialConditionsChainingState = useMemo(() => {
    return getInitialConditionsChainingState({
      variable: initialValue,
      profileCustomFields,
      missionCustomFields,
      t,
    });
    // eslint-disable-next-line
  }, [switchFieldId, subtype, profileCustomFields, missionCustomFields, t]);

  const [conditionsChainingState, setConditionsChainingState] = useState<
    ConditionsChainingVariable
  >(initialConditionsChainingState);

  useEffect(() => {
    setConditionsChainingState(initialConditionsChainingState);
  }, [initialConditionsChainingState]);

  const hasIfStatements = !_.isEmpty(conditionsChainingState.ifStatements);

  const isIdInStateTree = useCallback(
    ({ node, id }: { node: any; id: string }): boolean => {
      if (node?.id === id) {
        return true;
      }
      if (_.isArray(node)) {
        return _.some(node, (subNode) =>
          isIdInStateTree({ node: subNode, id }),
        );
      }
      if (_.isObject(node)) {
        return _.some(_.values(node), (subNode) =>
          isIdInStateTree({ node: subNode, id }),
        );
      }
      return false;
    },
    [],
  );

  const getStateTreeAfterReplacement = useCallback(
    <T,>({
      node,
      id,
      newValue,
    }: {
      node: Record<string, unknown>;
      id: string;
      newValue: T;
    }): any => {
      if (!isIdInStateTree({ node, id })) {
        return node;
      }
      if (node?.id === id) {
        return newValue;
      }
      if (_.isArray(node)) {
        return _.map(node, (subNode) =>
          getStateTreeAfterReplacement<T>({
            node: subNode,
            id,
            newValue,
          }),
        );
      }
      if (_.isObject(node)) {
        return _.mapObject(node, (subNode) =>
          getStateTreeAfterReplacement<T>({
            node: subNode,
            id,
            newValue,
          }),
        );
      }
      return node;
    },
    [isIdInStateTree],
  );

  return (
    <ConditionsChainingContext.Provider
      value={{
        clientId,
        conditionsChainingState,
        setConditionsChainingState,
        isIdInStateTree,
        getStateTreeAfterReplacement,
        hasIfStatements,
      }}
    >
      {children}
    </ConditionsChainingContext.Provider>
  );
};

function useConditionsChainingContext() {
  const context = React.useContext(ConditionsChainingContext);
  if (!context) {
    throw new Error(
      'useConditionsChainingContext must be used within a ConditionsChainingProvider',
    );
  }
  return context;
}

export { ConditionsChainingProvider, useConditionsChainingContext };
