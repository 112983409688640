import moment from 'moment';
import _ from 'underscore';

export const formatDate = (creationTimestamp: number, t: any): string => {
  if (creationTimestamp) {
    if (+creationTimestamp) {
      return t('common.formattedDate', {
        date: new Date(+creationTimestamp),
      });
    }
    return t('common.defaultDuration');
  }
  return t('profile.contact.timeline.aMomentAgo');
};

export const sweetDateToMomentDate = (date: any) => {
  const { year, month } = date || {};
  const currentYear = new Date().getFullYear();
  const defaultEducationMonth = 6;
  if (!year || currentYear < year) {
    return null;
  }
  return moment([year, _.isNumber(month) ? month : defaultEducationMonth]);
};

export const isValidDate = (date: Date): boolean => {
  return date instanceof Date && !Number.isNaN(date);
};

export const getDateFromTimestamp = (timestamp?: string | number) => {
  if (!timestamp) {
    return null;
  }
  try {
    const date = new Date(+timestamp);
    if (!isValidDate(date)) {
      return null;
    }
    return date;
  } catch (e) {
    return null;
  }
};

export const formatToYyyyMmDd = (date: Date): string =>
  [
    date.getFullYear(),
    `0${date.getMonth() + 1}`.slice(-2),
    `0${date.getDate()}`.slice(-2),
  ].join('-');

export const formatToMonthDYyyy = (date: Date): string =>
  `${date.toLocaleString('default', {
    month: 'long',
  })} ${date.getDate()}, ${date.getFullYear()}`;


export const DAY_IN_MS = 24 * 60 * 60 * 1000;
export const WEEK_IN_MS = DAY_IN_MS * 7;
export const MONTH_IN_MS = DAY_IN_MS * 30;

export const dateFromDayAndWeekDiffFromStartDate = (startDate: string | Date | number, day: number, weekDiffInMS: number) => {
  if (typeof startDate === 'string') {
    const timestamp = new Date(startDate).getTime();
    return new Date(timestamp + (weekDiffInMS || 0) + +day * DAY_IN_MS);
  }
  if (typeof startDate === 'number') {
    return new Date(startDate + (weekDiffInMS || 0) + +day * DAY_IN_MS);
  }
  return new Date(startDate.getTime() + (weekDiffInMS || 0) + +day * DAY_IN_MS);
};

export const getWeekNumber = (date: Date) => {
  const startDate = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date.valueOf() - startDate.valueOf()) / DAY_IN_MS);

  return Math.ceil(days / 7);
};

export const dateDiffInDays = (date1: Date, date2: Date) => {
  if (!date1 || !date2) {
    return 0;
  }
  const timestamp1 = date1.getTime();
  const timestamp2 = date2.getTime();
  return Math.floor((timestamp1 - timestamp2) / DAY_IN_MS);
}