import { graphql } from '@apollo/client/react/hoc';
import { SEARCH_POOL_PROFILE_CURRENT_TASK } from '../../graphql/searchPoolProfile';

export default graphql(SEARCH_POOL_PROFILE_CURRENT_TASK, {
  props: ({ data: { loading, searchPool, error } }) => ({
    loading,
    error,
    currentTask: searchPool?.profile?.currentSequenceInfo?.currentTask,
  }),
});
