import React from 'react';
import _ from 'underscore';
import { getRoundedScore } from '../helpers';
import { MatchingLabel } from './MatchingLabels';

import './DetailsSection.css';

// TODO: 5-dots score component
const DetailsSection = ({ title, value, score, labels }) => {
  return (
    <div className='details-section'>
      <div className='section-header'>
        {title && <span className='title'>{title}</span>}
        {value && <span className='title'> • </span>}
        {value && <span className='value'>{value}</span>}
        {score && <span>{`${getRoundedScore({ score })}%`}</span>}
      </div>
      {!_.isEmpty(labels) && (
        <div className='labels'>
          {_.map(labels, ({ label, score }) => (
            <MatchingLabel key={label} label={label} score={score} />
          ))}
        </div>
      )}
    </div>
  );
};

export default DetailsSection;
