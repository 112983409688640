import React, { ReactNode } from 'react';
import GenericKanban from '../GenericKanban';
import { SegmentDefinition } from '../types';
import { DragContextType } from './GenericSegmentationViewContext';
import GenericSegmentationViewContextProvider from './GenericSegmentationViewContextProvider';
import { GenericSegmentationItem } from '../GenericKanban/GenericKanbanColumn/index';

interface GenericSegmentationViewProps {
  /**
   * Display type of the data. Either 'kanban' or 'table'.
   */
  displayType: 'kanban' | 'table';
  /**
   * `useState` hook value that corresponds to the ID of the selected item.
   *
   * Set by `onSelectedItemId`.
   *
   * Add `const [selectedItemId, setSelectedItemId] = useState<string>('');` to your component.
   */
  selectedItem?: GenericSegmentationItem;
  /**
   * `segmentItemsIds` is a `useState` hook value that corresponds to the list of items within the segment containing the `selectedItemId`.
   *
   * Set by `onSegmentItemIds`.
   *
   * Add `const [segmentItemIds, setSegmentItemIds] = useState<string[]>([]);` to your component.
   */
  segmentItemIds?: string[];
  /**
   * Array of SegmentDefinition with the following format :
   * ```
   * segmentId: string,
   * title: string,
   * color: string // segment header colored bar color
   * items: GenericSegmentationItem
   * ```
   */
  segmentDefinitions: SegmentDefinition[];
  /**
   * This function will be executed upon `segmentItem` selection with it's ID (`itemId`) as parameter.
   *
   * Setter for `selectedItemId`.
   *
   * Add `const [selectedItemId, setSelectedItemId] = useState<string>('');` to your component.
   */
  onItemSelected?: ({
    item,
    segmentItems,
  }: {
    item: GenericSegmentationItem;
    segmentItems: string[];
  }) => void;
  /**
   * Setter for `segmentItemIds`.
   *
   * Add `const [segmentItemIds, setSegmentItemIds] = useState<string[]>([]);` to your component.
   */
  onSegmentItemIds?: (itemIds: string[]) => void;
  /**
   * This callback will be called when a card is gragged or dropped. The `dragContext` will be passed as parameter. `dragContext` includes :
   * ```
   * itemId: string; // itemId to move
   * cardNode: HTMLElement;
   * index: number;
   * prevSegment: string;
   * newSegment: string;
   * items: GenericSegmentationItem[];
   * prevColumnNode: HTMLElement;
   * newColumnNode: HTMLElement;
   * revert: () => void;
   * ```
   */
  onChangeSegment?: (context: DragContextType) => void;
  /**
   * Function taking the item and its loading state returning a component that will be rendered inside the cards.
   */
  renderItem: (
    item: GenericSegmentationItem,
    itemLoading: boolean,
  ) => ReactNode;
  /**
   * `boolean` to indicate whether or not the items data are loading.
   */
  dataLoading?: boolean;
  /**
   * This callback will be executed when a card is dragged over a drop zone. The `dragContext` will be passed as parameter. `dragContext` includes :
   * ```
   * itemId: string; // itemId to move
   * cardNode: HTMLElement;
   * index: number;
   * prevSegment: string;
   * newSegment: string;
   * items: GenericSegmentationItem[]; // Items to move
   * prevColumnNode: HTMLElement;
   * newColumnNode: HTMLElement;
   * revert: () => void;
   * ```
   */
  allowDrop?: (context: DragContextType) => boolean;
  onChangeSegmentFilters?: ({
    segmentId,
    filters,
  }: {
    segmentId: string;
    filters: any;
  }) => void;
  disableDragAndDrop?: boolean;
  weekDiffInMS?: number;
  disableProfilesInteraction?: boolean;
}

const noOperation = () => {};

const GenericSegmentationView: React.FC<GenericSegmentationViewProps> = ({
  displayType,
  selectedItem,
  segmentItemIds,
  segmentDefinitions,
  onItemSelected,
  onSegmentItemIds,
  onChangeSegment,
  renderItem,
  dataLoading,
  allowDrop,
  onChangeSegmentFilters,
  disableDragAndDrop,
  weekDiffInMS = 0,
  disableProfilesInteraction = false,
}) => {
  return (
    <GenericSegmentationViewContextProvider
      selectedItemId={selectedItem?.id}
      segmentItemIds={segmentItemIds || []}
      onItemSelected={onItemSelected}
      onSegmentItemIds={onSegmentItemIds || noOperation}
      onChangeSegment={onChangeSegment || noOperation}
      allowDrop={allowDrop}
      onChangeSegmentFilters={onChangeSegmentFilters}
      disableDragAndDrop={disableDragAndDrop}
      weekDiffInMS={weekDiffInMS}
      disableProfilesInteraction={disableProfilesInteraction}
    >
      {displayType === 'kanban' && (
        <GenericKanban
          segmentDefinitions={segmentDefinitions}
          renderItem={renderItem}
          dataLoading={dataLoading || false}
        />
      )}
    </GenericSegmentationViewContextProvider>
  );
};

export default GenericSegmentationView;
