import moment from 'moment';
import React, { useMemo, FC } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { getTranslatedText } from '@/common';
import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import logAction from '@/common/logAction';
import { Experience as ExperienceType } from '@/types/profile';

import {
  getDateDiffInMs,
  getDurationInYearsAndMonths,
  getMomentDiffInMs,
  parseDate,
} from './date-utils';
import BackgroundItemCard from './BackgroundItemCard';
import BackgroundItemSubCard from './BackgroundItemSubCard';

type ExperienceProps = {
  groupedExperiences: ExperienceType[];
  profileId: string;
  hoverPopupsDisabled: boolean;
};

const Experience: FC<ExperienceProps> = ({
  groupedExperiences,
  profileId,
  hoverPopupsDisabled,
}) => {
  const { t } = useTranslation();
  const { applicationMode } = useNavApplicationMode();

  const logExpandFactory = useMemo(
    () => (experience: ExperienceType) => (expanded: boolean) => {
      if (expanded) {
        logAction({
          type: 'expand-profile-experience',
          profileId,
          info: {
            experience: experience?.title,
            company: experience?.companyName,
          },
        });
      }
    },
    [profileId],
  );

  const subCards = useMemo(
    () =>
      groupedExperiences.length > 1
        ? _.map(groupedExperiences, (experience, index) => {
            const title = (experience?.title || {}).text;
            const subtitle = getExperienceDuration(t, experience);
            return (
              <BackgroundItemSubCard
                key={index}
                title={title}
                subtitle={subtitle}
                content={experience?.description}
                logExpand={logExpandFactory(experience)}
              />
            );
          })
        : null,
    [groupedExperiences, t, logExpandFactory],
  );

  const firstExperience = groupedExperiences[0];
  if (!firstExperience) {
    return null;
  }

  let title;
  let subtitle;
  let content;
  if (groupedExperiences.length > 1) {
    title = null;
    subtitle = getTotalExperienceDuration(t, groupedExperiences);
    content = null;
  } else {
    title = (firstExperience.title || {}).text;
    subtitle = getExperienceDuration(t, firstExperience);
    content = firstExperience?.description;
  }

  const mergedStack = _.reduce(
    groupedExperiences,
    (acc, { stack }) => {
      return [...acc, ...(stack || [])];
    },
    [] as { id: string }[],
  );

  return (
    <BackgroundItemCard
      allowUnbiasedMode={false}
      icon={firstExperience.photoLink || '/images/icons/defaultCompany.svg'}
      title={title}
      applicationMode={applicationMode}
      titleSecondPart={{
        text: firstExperience?.companyName,
        link: firstExperience?.companyWebsite,
      }}
      subtitle={subtitle}
      profileId={profileId}
      content={content}
      workplace={firstExperience?.workplace}
      stack={mergedStack}
      logExpand={logExpandFactory(firstExperience)}
      hoverPopupsDisabled={hoverPopupsDisabled}
      resumeData={undefined}
    >
      {subCards}
    </BackgroundItemCard>
  );
};

const getTotalExperienceDuration = (
  t: TFunction,
  groupedExperiences: ExperienceType[],
) => {
  const orderedExperiences = _.sortBy(
    groupedExperiences,
    (exp) => parseDate(exp?.startDate) || moment(),
  );
  const firstExperience = orderedExperiences[0];
  const followingExperiences = orderedExperiences.slice(1);

  let intervalStart = parseDate(firstExperience?.startDate);
  let intervalEnd = parseDate(firstExperience?.endDate) || moment();
  let totalDurationInMs = 0;
  _.each(followingExperiences, (experience) => {
    const startDate = parseDate(experience?.startDate);
    const endDate = parseDate(experience?.endDate) || moment();
    if (!startDate || !endDate) {
      return;
    }
    // start a new interval and add previous interval duration
    if (startDate > intervalEnd) {
      totalDurationInMs += getMomentDiffInMs(intervalStart, intervalEnd);
      intervalStart = startDate;
      intervalEnd = endDate;
      return;
    }
    // or make interval longer
    intervalEnd = endDate > intervalEnd ? endDate : intervalEnd;
  });
  // add final interval duration
  totalDurationInMs += getMomentDiffInMs(intervalStart, intervalEnd);
  return getDurationInYearsAndMonths({ durationInMs: totalDurationInMs, t });
};

const getExperienceDuration = (t: TFunction, experience: ExperienceType) => {
  const startDate = experience.startDate && parseDate(experience.startDate);
  const endDate = experience.endDate && parseDate(experience.endDate);

  const capitalizeText = (str: string) => {
    if (_.isString(str) && str.length > 0) {
      return str.charAt(0).toUpperCase() + str.substr(1);
    }
    return str;
  };

  const formattedStartDate = capitalizeText(
    (startDate && startDate.format('MMM YYYY')) || '',
  );
  const formattedEndDate = capitalizeText(
    (endDate && endDate.format('MMM YYYY')) || t('profile.resume.presentShort'),
  );

  const startDateStr = experience?.startDate;
  const endDateStr = experience?.endDate || new Date().toISOString();
  const dateDiff = getDateDiffInMs(startDateStr, endDateStr);
  const duration =
    startDateStr &&
    endDateStr &&
    getDurationInYearsAndMonths({ durationInMs: dateDiff, t });
  const formattedDuration = duration ? `(${duration})` : '';

  const leftPart = formattedStartDate
    ? formattedStartDate +
      (formattedEndDate ? ` – ${formattedEndDate}` : '') +
      (formattedEndDate && formattedDuration ? `  ${formattedDuration}` : '')
    : '';
  const rightPart = getTranslatedText((experience.location || {}).name) || '';
  return leftPart + (leftPart && rightPart ? ' • ' : '') + rightPart;
};

export default Experience;
