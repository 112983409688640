import gql from 'graphql-tag';
import _ from 'underscore';
import { graphql } from '@apollo/client/react/hoc';
import Offer from '../../graphql/fragments/Offer';
import { query as ClientOffersQuery } from './withClientOffers';
import { query as ClientTemplatesAndAuthors } from '../templates/withClientTemplatesAndAuthors';
import { getClientBaseOffers } from './withClientBaseOffers';

export const mutation = gql`
  mutation addOffer($clientId: ID!, $input: OfferInput!) {
    addOffer(clientId: $clientId, input: $input) {
      ...Offer
      isInstantFlow
    }
  }
  ${Offer}
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    addOffer: ({ clientId, input }) => {
      const update = (store, { data }) => {
        const newOffer = data?.addOffer;
        if (!newOffer) {
          return;
        }

        // Update Base Offers query
        try {
          const baseOffersQueryData = store.readQuery({
            query: getClientBaseOffers,
            variables: { clientId },
          });
          const oldOffers = _.reject(
            baseOffersQueryData.client.offers,
            (offer) => offer?.id === newOffer?.id,
          );

          store.writeQuery({
            query: getClientBaseOffers,
            data: {
              ...baseOffersQueryData,
              client: {
                ...baseOffersQueryData.client,
                offers: [...oldOffers, newOffer],
              },
            },
          });
          // If query has not yet been made, store.readQuery fails
          // This means there is nothing in cache to update
        } catch (e) {
          console.error(e);
          console.error('skipping Base Offers cache update');
        }

        // Update full Offers query
        try {
          // warning: the cache may already contain newOffer if above update succeeded, so we check for unique ids
          const offersQueryData = store.readQuery({
            query: ClientOffersQuery,
            variables: { clientId },
          });
          const oldOffers = _.reject(
            offersQueryData.client.offers,
            (offer) => offer?.id === newOffer?.id,
          );

          store.writeQuery({
            query: ClientOffersQuery,
            data: {
              ...offersQueryData,
              client: {
                ...offersQueryData.client,
                offers: [...oldOffers, newOffer],
              },
            },
          });
          // If query has not yet been made, store.readQuery fails
          // This means there is nothing in cache to update
        } catch (e) {
          console.error(e);
          console.error('skipping Offers cache update');
        }
      };

      const refetchQueries = [
        {
          query: ClientTemplatesAndAuthors,
          variables: { clientId },
        },
      ];

      const variables = { clientId, input };
      return mutate({ variables, update, refetchQueries });
    },
  }),
});
