import React, { FC } from 'react';
import classNames from 'classnames';

import ArrowDown from '@/components/Reveal/Icons/ArrowDown';

import styles from './AccordionButton.module.less';

interface AccordionButtonProps {
  active?: boolean;
  onClick: () => void;
  className?: string;
}

const AccordionButton: FC<AccordionButtonProps> = ({
  active,
  onClick,
  className,
}) => (
  <button
    type='button'
    className={classNames(styles.icon, { [styles.active]: active }, className)}
    onClick={onClick}
  >
    <ArrowDown />
  </button>
);

export default AccordionButton;
