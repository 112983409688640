import { sentryCaptureException } from './index';

const logAction = async ({
  type,
  profileId,
  searchPoolProfileId,
  info,
}: {
  type: string;
  profileId?: string;
  searchPoolProfileId?: string;
  info?: { [key: string]: unknown };
}): Promise<void> => {
  const token = localStorage.getItem('token');
  return fetch(`${process.env.REACT_APP_SERVER_URL}/actions`, {
    headers: {
      'Content-Type': 'application/json',
      ...(token && { Authorization: token }),
    },
    method: 'POST',
    body: JSON.stringify({
      type,
      profileId,
      searchPoolProfileId,
      info,
    }),
  })
    .then(() => {
      localStorage.setItem('lastActionTimestamp', Date.now().toString());
    })
    .catch((error) => {
      sentryCaptureException({ error, tags: { feature: 'log-action' } });
    });
};

export default logAction;
