import { graphql } from '@apollo/client/react/hoc';
import _ from 'underscore';
import { GET_CLIENT_PROFILE_CUSTOM_FIELDS } from '@/graphql/clients';
import { sanitizeTypename } from '@/common/utils/apollo';

export default graphql(GET_CLIENT_PROFILE_CUSTOM_FIELDS, {
  options: (ownProps) => ({
    variables: {
      clientId: ownProps.clientId,
    },
  }),
  props: (query) => {
    const { profileCustomFields } = query?.data?.client || {};
    const fields = sanitizeTypename(profileCustomFields || []);
    return {
      clientCustomFields: fields,
    };
  },
});
