import React, { useCallback, useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import { MissionInterestedSubStep } from '@/graphql/hooks/clients/useClientMissionInterestedSubSteps';
import useClientId from '@/hooks/router/useClientId';
import { sanitizeTypename } from '@/common/utils/apollo';
import { getRandomString, getTranslatedText } from '@/common';
import Plus from '@/components/Reveal/Icons/Plus';
import GenericDropdown from '@/components/Common/GenericDropdown';
import DropdownPanel from '@/components/Common/DropdownPanel';
import Dots from '@/components/Common/Icons/Dots';
import DropdownMenuItem from '@/components/Common/DropdownMenuItem';
import Edit from '@/components/Reveal/Icons/Edit';
import Delete from '@/components/Reveal/Icons/Delete';
import useMissionInterestedSubPipelines from '@/graphql/hooks/clients/useMissionInterestedSubPipelines';
import MissionCategoryContext from '@/context/MissionCategoryContext';

import MissionSubStepSetting from './MissionSubStepSetting';
import MissionStepEditionModal from './MissionStepEditionModal';

import styles from './JobPipelineView.module.less';

interface JobPipelineEditionModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  clientSubSteps: MissionInterestedSubStep[];
  missionSubSteps: MissionInterestedSubStep[];
  onUpdateSteps: (newSteps: MissionInterestedSubStep[]) => void;
  onSaveSteps: () => void;
  segmentCounts: { segmentId: string; count: number }[];
}

const JobPipelineEditionModal: React.FC<JobPipelineEditionModalProps> = ({
  open,
  setOpen,
  clientSubSteps,
  missionSubSteps,
  onUpdateSteps,
  onSaveSteps,
  segmentCounts,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const missionCategoryType = useContext(MissionCategoryContext);
  const [editMode, setEditMode] = useState(false);
  const [newStepName, setNewStepName] = useState('');
  const [newStepIndex, setNewStepIndex] = useState(-1);
  const [newStepEditionModalOpen, setNewStepEditionModalOpen] = useState(false);
  const [selectedStepId, setSelectedStepId] = useState('');
  const [lastSavedState] = useState(missionSubSteps);
  const [tempStepsState, setTempStepsState] = useState(missionSubSteps);

  const [selectedPipelineId, setSelectedPipelineId] = useState('');

  const { missionInterestedSubPipelines } = useMissionInterestedSubPipelines();
  const pipelines = useMemo(
    () =>
      _.filter(
        missionInterestedSubPipelines,
        ({ missionCategory }) => missionCategory.type === missionCategoryType,
      ),
    [missionInterestedSubPipelines, missionCategoryType],
  );

  const pipelinesOptions = _.map(pipelines, (pipeline) => ({
    text: getTranslatedText(pipeline.title),
    value: pipeline.id,
  }));

  const handleChangePipeline = (pipelineId: string) => {
    setSelectedPipelineId(pipelineId);
    const pipeline = _.findWhere(pipelines, { id: pipelineId });
    if (!pipeline) {
      return;
    }
    onUpdateSteps(sanitizeTypename(pipeline.steps));
  };

  const handleStepToggle = useCallback(
    ({ key, value }: { key: string | number; value: boolean }) => {
      const missionOnlySubsteps = _.filter(
        clientSubSteps,
        (substep) => !_.isEmpty(substep.previousStepId),
      );
      if (value) {
        if (_.findWhere(missionOnlySubsteps, { id: key as string })) {
          const newSteps = _.map(missionSubSteps, (subStep) => {
            if (subStep.id === key) {
              return {
                ...subStep,
                isDisabled: !subStep.isDisabled,
              };
            }
            return subStep;
          });
          onUpdateSteps(sanitizeTypename(newSteps));
        } else {
          const idxToInsertAt = _.findIndex(
            clientSubSteps,
            (substep) => substep.id === key,
          );
          const newSteps = [
            ...missionSubSteps.slice(0, idxToInsertAt),
            _.findWhere(clientSubSteps, {
              id: key as string,
            }) as MissionInterestedSubStep,
            ...missionSubSteps.slice(idxToInsertAt),
          ];
          onUpdateSteps(sanitizeTypename(newSteps));
        }
      } else if (_.findWhere(missionOnlySubsteps, { id: key as string })) {
        const newSteps = _.map(missionSubSteps, (subStep) => {
          if (subStep.id === key) {
            return {
              ...subStep,
              isDisabled: value,
            };
          }
          return subStep;
        });
        onUpdateSteps(sanitizeTypename(newSteps));
      } else {
        onUpdateSteps(
          sanitizeTypename([
            ..._.filter(missionSubSteps, (subStep) => subStep.id !== key),
          ]),
        );
      }
    },
    [clientSubSteps, missionSubSteps, onUpdateSteps],
  );

  const handleAddSubstep = useCallback(() => {
    const newStep = {
      title: {
        default: newStepName,
      },
      id: getRandomString(6),
      previousStepId: clientSubSteps[newStepIndex - 1]?.id || null,
      isDisabled: false,
    };
    if (newStepIndex === 0) {
      onUpdateSteps(sanitizeTypename([newStep, ...missionSubSteps]));
    }
    onUpdateSteps(
      sanitizeTypename([
        ...missionSubSteps.slice(0, newStepIndex),
        newStep,
        ...missionSubSteps.slice(newStepIndex),
      ]),
    );
  }, [
    clientSubSteps,
    onUpdateSteps,
    missionSubSteps,
    newStepName,
    newStepIndex,
  ]);

  const handleRenameSubstep = useCallback(() => {
    const newSteps = _.map(missionSubSteps, (substep) => {
      if (substep.id === selectedStepId) {
        return {
          ...substep,
          title: {
            default: newStepName,
          },
        };
      }
      return substep;
    });
    onUpdateSteps(sanitizeTypename(newSteps));
  }, [onUpdateSteps, missionSubSteps, newStepName, selectedStepId]);

  const onDeleteSubstep = useCallback(
    (substepId) => {
      onUpdateSteps(
        _.filter(missionSubSteps, (substep) => substep.id !== substepId),
      );
    },
    [onUpdateSteps, missionSubSteps],
  );

  const onReset = useCallback(() => {
    onUpdateSteps(lastSavedState);
  }, [onUpdateSteps, lastSavedState]);

  if (!segmentCounts) {
    return null;
  }

  return (
    <GenericModal
      size='tiny'
      open={open}
      onClose={() => setOpen(false)}
      closeOnEscape
      closeOnDimmerClick
      className={styles.modal}
    >
      <Modal.Header>
        {t(`reveal.missions.mission.settingsTab.pipeline.modal.title`)}
      </Modal.Header>
      <Modal.Content className={styles.modal}>
        {!_.isEmpty(pipelinesOptions) && (
          <p className={styles.modalDescription}>
            <Dropdown
              className='template-selector-dropdown'
              upward
              item
              scrolling
              selectOnBlur={false}
              // disabled={editMode}
              options={pipelinesOptions}
              value={selectedPipelineId}
              onChange={(e, { value }) => handleChangePipeline(value as string)}
              text={t(
                'reveal.missions.mission.settingsTab.pipeline.modal.choosePipeline',
              )}
            />
          </p>
        )}
        {_.map(clientSubSteps, (subStep, index) => (
          <div key={subStep.id}>
            {editMode && (
              <div
                className={classnames(
                  styles.newSubstepContainer,
                  // _.contains(substepIdsWithVisibleSubstepAddition, subStep.id) &&
                  //   styles.newSubstepVisible,
                )}
                onClick={() => {
                  setNewStepIndex(index as number);
                  setNewStepEditionModalOpen(true);
                }}
              >
                <Plus />
                &nbsp;
                {t(
                  'reveal.missions.mission.settingsTab.pipeline.modal.addSubstep',
                )}
              </div>
            )}
            <div className={styles.subStepEditionRawContainer}>
              <MissionSubStepSetting
                isChecked={
                  !!_.findWhere(missionSubSteps, { id: subStep.id }) &&
                  !subStep.isDisabled
                }
                subStep={subStep}
                handleStepToggle={handleStepToggle}
                subStepCount={
                  _.findWhere(segmentCounts, { segmentId: subStep.id })
                    ?.count || 0
                }
              />
              {subStep.previousStepId && (
                <GenericDropdown
                  position='right'
                  Trigger={({ onClick }) => (
                    <GenericButton primacy='tertiary' onClick={onClick}>
                      <Dots />
                    </GenericButton>
                  )}
                >
                  <DropdownPanel className={styles.panel}>
                    <DropdownMenuItem
                      className={styles.panelOption}
                      key='interested-edit-substep'
                      onClick={() => {
                        setSelectedStepId(subStep.id);
                        setNewStepName(getTranslatedText(subStep.title));
                        setNewStepEditionModalOpen(true);
                      }}
                    >
                      <Edit className={styles.panelOptionIcon} />
                      <span>{t('common.edit')}</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className={styles.panelOption}
                      key='interested-delete-substep'
                      onClick={() => onDeleteSubstep(subStep.id)}
                    >
                      <Delete className={styles.panelOptionIcon} />
                      <span>{t('common.delete')}</span>
                    </DropdownMenuItem>
                  </DropdownPanel>
                </GenericDropdown>
              )}
            </div>
          </div>
        ))}
        {editMode && (
          <div
            // onMouseOver={() => onHoverSubstep(substeps[_.size(substeps) - 1].id)}
            // onMouseLeave={() => onHoverSubstep('')}
            onFocus={() => {}}
          >
            <div
              className={classnames(
                styles.newSubstepContainer,
                // hoveredSubstepIndex === substeps.length - 1 &&
                //   styles.newSubstepVisible,
              )}
              onClick={() => {
                setNewStepIndex(clientSubSteps.length);
                setNewStepEditionModalOpen(true);
              }}
            >
              <Plus />
              &nbsp;
              {t(
                'reveal.missions.mission.settingsTab.pipeline.modal.addSubstep',
              )}
            </div>
          </div>
        )}
        {editMode ? (
          <GenericButton
            className={styles.substepActions}
            primacy='secondary'
            onClick={() => {
              onUpdateSteps(tempStepsState);
              setEditMode(false);
            }}
          >
            {t('common.cancel')}
          </GenericButton>
        ) : (
          <GenericButton
            className={styles.substepActions}
            primacy='secondary'
            onClick={() => {
              setTempStepsState(missionSubSteps);
              setEditMode(true);
            }}
          >
            {t('superPipelineSettings.addSubstep')}
          </GenericButton>
        )}
        <div className={styles.infoBox}>
          <span>
            {t(`reveal.missions.mission.settingsTab.pipeline.modal.infoBox`)} (
            <Link to={`/client/${clientId}/pipeline`}>
              {t(
                `reveal.missions.mission.settingsTab.pipeline.modal.customPipelines`,
              )}
            </Link>
            ).
          </span>
        </div>
        <MissionStepEditionModal
          title={
            _.isEmpty(selectedStepId)
              ? t('superPipelineSettings.newSubstepModalTitle')
              : t('superPipelineSettings.editSubstepModalTitle')
          }
          open={newStepEditionModalOpen}
          setOpen={setNewStepEditionModalOpen}
          value={newStepName}
          setValue={setNewStepName}
          disabled={false}
          isEdition={!_.isEmpty(selectedStepId)}
          onSubmit={
            _.isEmpty(selectedStepId) ? handleAddSubstep : handleRenameSubstep
          }
        />
      </Modal.Content>
      <Modal.Actions className={styles.actions}>
        <GenericButton
          size='big'
          primacy='secondary'
          onClick={() => {
            onReset();
            setOpen(false);
          }}
        >
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          size='big'
          type='submit'
          form='custom-field-form'
          onClick={() => {
            onSaveSteps();
            setOpen(false);
          }}
        >
          {t(`common.save`)}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default JobPipelineEditionModal;
