import _, { compose } from 'underscore';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Segment, Image, Dropdown } from 'semantic-ui-react';

import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';
import { getRandomString } from '@/common';
import withClient from '@/hocs/clients/withClient';
import withAddClientEvaluationFormTemplate from '@/hocs/clients/withAddClientEvaluationFormTemplate';
import withUpdateClientEvaluationFormTemplate from '@/hocs/clients/withUpdateClientEvaluationFormTemplate';
import withDeleteClientEvaluationFormTemplate from '@/hocs/clients/withDeleteClientEvaluationFormTemplate';
import withMoveClientEvaluationFormTemplateUpward from '@/hocs/clients/withMoveClientEvaluationFormTemplateUpward';
import withMoveClientEvaluationFormTemplateDownward from '@/hocs/clients/withMoveClientEvaluationFormTemplateDownward';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import { sanitizeTypename } from '@/common/utils/apollo';

import SettingsLayout from '../../SettingsLayout';
import EvaluationFormEditModal from '../../Profile/EvaluationsSets/EvaluationFormEditModal';

import './EvaluationFormsSettings.css';

const EvaluationTemplate = ({
  evaluationTemplate,
  index,
  nbEvaluationTemplates,
  onAskEdit,
  onAskRemove,
  onMoveUpward,
  onMoveDownward,
  t,
}) => (
  <Segment className='evaluation-template'>
    <h3>{evaluationTemplate.title}</h3>

    <div className='settings-dropdown-container'>
      <Dropdown
        direction='left'
        icon={null}
        trigger={<Image src='/images/icons/figma/ellipsis.svg' />}
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => onAskEdit()}>
            {t('settings.evaluations.edit')}
          </Dropdown.Item>
          {index > 0 && (
            <Dropdown.Item onClick={() => onMoveUpward()}>
              {t('settings.evaluations.moveUpward')}
            </Dropdown.Item>
          )}
          {index + 1 < nbEvaluationTemplates && (
            <Dropdown.Item onClick={() => onMoveDownward()}>
              {t('settings.evaluations.moveDownward')}
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={() => onAskRemove()}>
            {t('settings.evaluations.delete')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  </Segment>
);

const cleanEvaluationTemplateInput = (input) => {
  return _.pick(sanitizeTypename(input), 'title', 'form');
};

class EvaluationFormsSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCloseModal = () => {
    this.setState({ modal: null });
  };

  handleAskAddEvaluationForm = () => {
    const { t } = this.props;
    this.setState({
      modal: {
        type: 'add-evaluation-form',
        initialValue: {
          form: {
            fields: [
              {
                id: getRandomString(10),
                type: 'grade',
                title: t('settings.evaluations.form.overallImpression'),
                coefficient: 1,
              },
            ],
          },
        },
      },
    });
  };

  handleAddEvaluationForm = ({ value }) => {
    const { addEvaluationFormTemplate } = this.props;

    addEvaluationFormTemplate({
      evaluationFormTemplate: cleanEvaluationTemplateInput(value),
    });

    this.handleCloseModal();
  };

  handleAskEditEvaluationForm = ({ evaluationFormTemplateId }) => {
    const { client } = this.props;
    const evaluationTemplates = client?.evaluationFormTemplates || [];
    const evaluationFormTemplate = _.findWhere(evaluationTemplates, {
      id: evaluationFormTemplateId,
    });

    if (evaluationFormTemplate) {
      this.setState({
        modal: {
          type: 'edit-evaluation-form',
          evaluationFormTemplateId,
          initialValue: evaluationFormTemplate,
        },
      });
    }
  };

  handleEditEvaluationForm = ({ evaluationFormTemplateId, newValue }) => {
    const { updateEvaluationFormTemplate } = this.props;

    updateEvaluationFormTemplate({
      evaluationFormTemplateId,
      evaluationFormTemplate: cleanEvaluationTemplateInput(newValue),
    });

    this.handleCloseModal();
  };

  handleAskDeleteEvaluationForm = ({ evaluationFormTemplateId }) => {
    this.setState({
      modal: {
        type: 'delete-evaluation-form',
        evaluationFormTemplateId,
      },
    });
  };

  handleDeleteEvaluationForm = ({ evaluationFormTemplateId }) => {
    const { deleteEvaluationFormTemplate } = this.props;
    deleteEvaluationFormTemplate({ evaluationFormTemplateId });
    this.handleCloseModal();
  };

  handleMoveEvaluationFormUpward = ({ evaluationFormTemplateId }) => {
    const { moveEvaluationFormTemplateUpward } = this.props;
    moveEvaluationFormTemplateUpward({ evaluationFormTemplateId });
    this.handleCloseModal();
  };

  handleMoveEvaluationFormDownward = ({ evaluationFormTemplateId }) => {
    const { moveEvaluationFormTemplateDownward } = this.props;
    moveEvaluationFormTemplateDownward({ evaluationFormTemplateId });
    this.handleCloseModal();
  };

  renderForm() {
    const { client, t } = this.props;

    const evaluationTemplates = client?.evaluationFormTemplates || [];

    return (
      <div className='evaluationTemplates-editor'>
        <div className='evaluationTemplates-list-container'>
          {_.map(evaluationTemplates, (evaluationTemplate, index) => (
            <div
              className='evaluationTemplate-container'
              key={evaluationTemplate.id}
            >
              <EvaluationTemplate
                index={index}
                nbEvaluationTemplates={evaluationTemplates.length}
                evaluationTemplate={evaluationTemplate}
                onAskEdit={() => {
                  this.handleAskEditEvaluationForm({
                    evaluationFormTemplateId: evaluationTemplate.id,
                  });
                }}
                onMoveUpward={() => {
                  this.handleMoveEvaluationFormUpward({
                    evaluationFormTemplateId: evaluationTemplate.id,
                  });
                }}
                onMoveDownward={() => {
                  this.handleMoveEvaluationFormDownward({
                    evaluationFormTemplateId: evaluationTemplate.id,
                  });
                }}
                onAskRemove={() => {
                  this.handleAskDeleteEvaluationForm({
                    evaluationFormTemplateId: evaluationTemplate.id,
                  });
                }}
                t={t}
              />
            </div>
          ))}
        </div>

        <GenericButton onClick={() => this.handleAskAddEvaluationForm()}>
          <Plus />
          {t('settings.evaluations.add')}
        </GenericButton>
      </div>
    );
  }

  render() {
    const { clientId, client, t } = this.props;
    const { modal } = this.state;

    return (
      <SettingsLayout
        currentPage='evaluationForms'
        clientId={clientId}
        className='settings-container'
      >
        <div className='settings evaluationForms-page'>
          <div className='settings-header'>
            <h1>{t('evaluationFormsSettings.header')}</h1>
          </div>
          <h2>{t('settings.evaluations.configuration')}</h2>
          <div className='section visible'>
            <div className='description'>
              {t('settings.evaluations.description')}
            </div>
            <div className='input-container'>{client && this.renderForm()}</div>
          </div>
        </div>

        {modal?.type === 'delete-evaluation-form' ? (
          <ConfirmationModal
            header={t('settings.evaluations.deleteConfirmationModal.title')}
            submit={t('settings.evaluations.deleteConfirmationModal.delete')}
            onSubmit={() => {
              this.handleDeleteEvaluationForm({
                evaluationFormTemplateId: modal.evaluationFormTemplateId,
              });
            }}
            open
            onCancel={() => this.handleCloseModal()}
          />
        ) : (
          ''
        )}

        {modal?.type === 'edit-evaluation-form' ? (
          <EvaluationFormEditModal
            mode='classic-edition'
            editMode='edit'
            initialValue={modal.initialValue}
            onClose={() => this.handleCloseModal()}
            onSubmit={({ newValue }) => {
              this.handleEditEvaluationForm({
                evaluationFormTemplateId: modal.evaluationFormTemplateId,
                newValue,
              });
            }}
            t={t}
          />
        ) : (
          ''
        )}

        {modal?.type === 'add-evaluation-form' ? (
          <EvaluationFormEditModal
            mode='classic-edition'
            editMode='create'
            initialValue={modal.initialValue}
            onClose={() => this.handleCloseModal()}
            onSubmit={({ newValue }) => {
              this.handleAddEvaluationForm({
                value: newValue,
              });
            }}
            t={t}
          />
        ) : (
          ''
        )}
      </SettingsLayout>
    );
  }
}

export default compose(
  withClient,
  withAddClientEvaluationFormTemplate,
  withUpdateClientEvaluationFormTemplate,
  withMoveClientEvaluationFormTemplateUpward,
  withMoveClientEvaluationFormTemplateDownward,
  withDeleteClientEvaluationFormTemplate,
  withTranslation('translations'),
)(EvaluationFormsSettings);
