import { useParams } from 'react-router-dom';
import { useContext } from 'react';

import FakeClientContext from '@/context/FakeClientContext';
import { useAppContext } from '@/context/AppContext/AppContext';

const useClientId = (): string => {
  const { clientId } = useParams<{ clientId: string }>();
  const { clientId: appClientId } = useAppContext();
  const fakeClient = useContext(FakeClientContext);
  return fakeClient.clientId ?? appClientId ?? clientId;
};

export default useClientId;
