import { useMemo, FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const GenericDateTimeFormat: FC<{
  date: Date;
  //   formatOptions?: Intl.DateTimeFormatOptions;
  // It seems the current implementation of the type does not allow timeStyle and dateStyle
  formatOptions?: any;
}> = ({
  date,
  formatOptions = {
    timeStyle: 'short',
    dateStyle: 'medium',
  },
}) => {
  const { i18n } = useTranslation();
  const formatter = useMemo(() => {
    return new Intl.DateTimeFormat(i18n.resolvedLanguage, formatOptions);
  }, [i18n, formatOptions]);

  // For some reason FC does not like String as return
  return (formatter.format(date) as unknown) as ReactElement;
};

export default GenericDateTimeFormat;
