import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import useClientId from '@/hooks/router/useClientId';
import { EnrichmentIntegrationQuota as EnrichmentQuotaFragment } from '@/graphql/fragments/EnrichmentIntegration';
import { EnrichmentIntegrationQuota } from '../users/useUserEnrichmentIntegrations';


const GET_CLIENT_ENRICHMENT_INTEGRATIONS = gql`
  query getClientEnrichmentIntegrations($clientId: ID!) {
    client(id: $clientId) {
      # This is is extremely important
      # Without it apollo cannot write to cache and triggers an error
      # So even if we get the response, "data" from useQuery is always undefined
      id
      clientEnrichmentIntegrations {
        id
        type
        status
        useSystemIntegration
        quota {
          ...EnrichmentIntegrationQuota
        }
      }
    }
  }

  ${EnrichmentQuotaFragment}
`;

export interface ClientEnrichmentIntegration {
  id: string;
  type: string;
  status: 'active';
  useSystemIntegration: boolean;
  quota?: EnrichmentIntegrationQuota;
}

interface ClientEnrichmentQueryResult {
  client: {
    clientEnrichmentIntegrations: ClientEnrichmentIntegration[];
  };
}

const useClientEnrichmentIntegrations = ({
  queryOptions = {},
}: {
  queryOptions?: QueryHookOptions;
} = {}): QueryResult & {
  enrichmentIntegrations: ClientEnrichmentIntegration[] | undefined;
} => {
  const clientId = useClientId();
  const { data, ...rest } = useQuery<ClientEnrichmentQueryResult>(
    GET_CLIENT_ENRICHMENT_INTEGRATIONS,
    {
      variables: {
        clientId,
      },
      ...queryOptions,
    },
  );

  const enrichmentIntegrations = data?.client?.clientEnrichmentIntegrations;
  return {
    data,
    enrichmentIntegrations,
    ...rest,
  };
};

export default useClientEnrichmentIntegrations;
