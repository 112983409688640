import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Button, Checkbox, Input, TextArea } from 'semantic-ui-react';
import SettingsLayout from '../../SettingsLayout';
import useSSOSettings from './useSSOSettings';

const getMetadataUrl = (clientId: string) => `${process.env.REACT_APP_SERVER_URL}/auth/saml/${clientId}/metadata.xml`;

interface SSOSettingsProps extends WithTranslation {
  clientId: string;
}

const SSOSettings: React.FC<SSOSettingsProps> = ({ clientId, t }) => {
  const {
    disabled,
    onChangeCertificate,
    certificate,
    onChangeSsoServiceUrl,
    ssoServiceURL,
    onChangeSSOActive,
    ssoActive,
    onChangeEntityId,
    entityId,
    canSubmit,
    onSubmit,
    submitLoading,
    error,
    justSaved,
  } = useSSOSettings(clientId);

  const metadataURL = getMetadataUrl(clientId);

  return (
    <SettingsLayout currentPage='sso' clientId={clientId} className='settings-container plugin-settings-container'>
      <Form className='settings sso-settings' onSubmit={onSubmit}>
        <div className='settings-header'>
          <h1>{t('settings.sso.header')}</h1>
          <div className='settings-submit'>
            <div>
              <Button type='submit' className='primary-cta' disabled={!canSubmit} loading={submitLoading}>
                {t('settings.saveButton')}
              </Button>
            </div>
            <div className='settings-submit-message'>
              {error ? <span className='error-message'>{error}</span> : null}
            </div>
            <div className='settings-submit-message'>
              {justSaved ? <span className='just-saved'>{t('settings.notifications.savedWithSuccess')}</span> : null}
            </div>
          </div>
        </div>
        <h2>{t('settings.sso.status')}</h2>
        <div className='section visible'>
          <div className='input-container'>
            <div className='input-label'>{t('settings.sso.activate')}</div>
            <div className='input-element'>
              <Checkbox
                checked={ssoActive}
                onChange={onChangeSSOActive}
                label={t('settings.sso.activateLabel')}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
        <h2>{t('settings.sso.identityProvider')}</h2>
        <div className='section visible'>
          <div className='input-container'>
            <div className='input-label'>{t('settings.sso.entityIdLabel')}</div>
            <div className='input-element'>
              <Input value={entityId} fluid onChange={onChangeEntityId} disabled={disabled || !ssoActive} />
            </div>
          </div>
          <div className='input-container'>
            <div className='input-label'>{t('settings.sso.ssoUrlLabel')}</div>
            <div className='input-element'>
              <Input value={ssoServiceURL} fluid onChange={onChangeSsoServiceUrl} disabled={disabled || !ssoActive} />
            </div>
          </div>
          <Form.Field
            onChange={onChangeCertificate}
            control={TextArea}
            label={t('settings.sso.certificateLabel')}
            value={certificate}
            disabled={disabled || !ssoActive}
            className='input-container'
          />
        </div>
        <h2>{t('settings.sso.serviceProvider')}</h2>
        <div className='section visible'>
          <div className='input-container'>
          <div className='description'>{t('settings.sso.serviceProviderDescription')}</div>
            <div className='input-label'>{t('settings.sso.serviceProviderMetadataUrl')}</div>
            <a href={metadataURL} target='_blank' rel='noopener noreferrer' >
              {metadataURL}
            </a>
          </div>
        </div>
      </Form>
    </SettingsLayout>
  );
};

export default withTranslation('translations')(SSOSettings);
