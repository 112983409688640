import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { getSearchCriteria } from '@/containers/Parameters/OfferCriteria/helpers';
import { formatForSearchQuery } from '@/routes/WatchCollectAllCandidates/helpers';
import { SearchCriteria } from '@/types/search';
import { useSessionSearch } from '@/context/SessionSearchContext';
import useOfferCriteria from '../offers/useOfferCriteria';

const SEARCH_POOL_ALL_CANDIDATES_COUNT = gql`
  query getSearchPoolAllCandidatesCount(
    $searchPoolCategories: [SearchCategoryInput!]!
    $searchesCriteria: [SearchCriteriaInput]
  ) {
    searchPool(id: "watch") {
      id
      searchResults(
        searchesCriteria: $searchesCriteria
        excludeHidden: true
        includeProfilesInJob: "pending-only"
        categories: $searchPoolCategories
        sortBy: "recency"
      ) {
        categories {
          categoryId
          count
        }
      }
    }
  }
`;

const searchesCriteria = [
  {
    jobPositionsOperator: 'OR',
    skillsOperator: 'OR',
    remoteOnly: false,
    excludeHidden: true,
    includeProfilesInJob: 'pending-only',
  },
] as const;

export const useSearchPoolAllCandidatesCount = (): {
  loading: boolean;
  allCandidatesCount: number;
} => {
  const { data, loading } = useQuery(SEARCH_POOL_ALL_CANDIDATES_COUNT, {
    variables: {
      searchPoolCategories: [
        {
          categoryId: 'all',
          withCount: true,
          withResults: false,
        },
      ],
      searchesCriteria,
    },
  });
  return {
    loading,
    allCandidatesCount:
      data?.searchPool?.searchResults?.categories?.[0]?.count || 0,
  };
};

export const useSearchPoolNewCandidatesCount = (): {
  loading: boolean;
  newCandidatesCount: number;
} => {
  const { data, loading } = useQuery(SEARCH_POOL_ALL_CANDIDATES_COUNT, {
    variables: {
      searchPoolCategories: [
        {
          categoryId: 'new',
          withCount: true,
          withResults: false,
        },
      ],
      searchesCriteria,
    },
  });
  return {
    loading,
    newCandidatesCount:
      data?.searchPool?.searchResults?.categories?.[0]?.count || 0,
  };
};

const useOfferQueryCriteria = (offerId: string): SearchCriteria[] => {
  const { offerCriteria } = useOfferCriteria({ offerId });

  const { sessionSearch } = useSessionSearch(searchesCriteria[0]);

  return useMemo(
    () =>
      (sessionSearch?.expirationTimestamp > Date.now()
        ? [formatForSearchQuery(sessionSearch?.criteria)]
        : [getSearchCriteria({ offerCriteria })]) || searchesCriteria,
    [sessionSearch, offerCriteria],
  );
};

export const useSearchPoolOfferCandidatesCount = (
  offerId: string,
): {
  loading: boolean;
  offerCandidatesCount: number;
} => {
  const queryCriteria = useOfferQueryCriteria(offerId);

  const { data, loading } = useQuery(SEARCH_POOL_ALL_CANDIDATES_COUNT, {
    variables: {
      searchPoolCategories: [
        {
          categoryId: 'all',
          withCount: true,
          withResults: false,
        },
      ],
      searchesCriteria: queryCriteria,
    },
  });

  return {
    loading,
    offerCandidatesCount:
      data?.searchPool?.searchResults?.categories?.[0]?.count || 0,
  };
};

export const useSearchPoolNewOfferCandidatesCount = (
  offerId: string,
): {
  loading: boolean;
  newOfferCandidatesCount: number;
} => {
  const queryCriteria = useOfferQueryCriteria(offerId);

  const { data, loading } = useQuery(SEARCH_POOL_ALL_CANDIDATES_COUNT, {
    variables: {
      searchPoolCategories: [
        {
          categoryId: 'new',
          withCount: true,
          withResults: false,
        },
      ],
      searchesCriteria: queryCriteria,
    },
  });

  return {
    loading,
    newOfferCandidatesCount:
      data?.searchPool?.searchResults?.categories?.[0]?.count || 0,
  };
};
