import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { Loader } from 'semantic-ui-react';
import { CSSObjectWithLabel } from 'react-select';

import GenericSelect from '@/components/Common/GenericSelect';
import EmailEditor from '@/containers/Editor';
import TargetsForm from '@/routes/RevealView/JobsView/JobView/InformationsTab/TargetsForm';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import useClientId from '@/hooks/router/useClientId';
import GenericButton from '@/components/Common/GenericButton';
import NowOrOtherDatePicker from '@/components/Common/NowOrOtherDatePicker';
import { DateValue } from '@/components/Common/NowOrOtherDatePicker/NowOrOtherDatePicker';
import useClientCustomActivities from '@/graphql/hooks/clients/useClientCustomActivities';
import { getTranslatedText } from '@/common';
import MetaTaskForm from '@/revealComponents/ExplicitTasks/MetaTaskForm';
import { MetaTaskFormat } from '@/revealComponents/ExplicitTasks/MetaTaskForm/types';
import useMissionId from '@/hooks/router/useMissionId';
import { SecondaryRed, SecondaryRed120, Yellow40 } from '@/less/colors';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

import CompanyContactsDropdown from '../CompanyContactsDropdown';
import { Subtype, coerceSubtype, subtypes } from '../subtypes';

import styles from './SharedNoteForm.module.less';

export type FormValue = {
  text: string;
  companyId?: string;
  format?: MetaTaskFormat;
  contactIds: string[];
  missionIds: string[];
  subtype: Subtype;
  customActivityId?: string;
  date: DateValue;
};

type Props = {
  profileId: string;
  initialValue: FormValue;
  onValue: (value: FormValue) => void;
  onSave: (value: FormValue) => void;
  isSaving?: boolean;
};

const SharedNoteForm = ({
  profileId,
  initialValue,
  onValue,
  onSave,
  isSaving,
}: Props) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const [value, setValue] = useState(initialValue);
  const { clientCustomActivities } = useClientCustomActivities({ clientId });
  const contextProjectId = useMissionId();
  const {
    shouldHideCompanySelectorInSharedNote,
    mandatoryProjectInSharedNote,
  } = useMergedConfigurationParams();

  const { jobOptions } = useSearchPoolJobOptions('reveal', {
    activeOnly: true,
  });

  const {
    companyId,
    contactIds,
    missionIds,
    subtype,
    text,
    format,
    customActivityId,
  } = value;

  const setAndEmitValue = useCallback(
    (newValue: FormValue) => {
      setValue(newValue);
      onValue(newValue);
    },
    [onValue],
  );

  const subtypeOptions = useMemo(() => {
    return [
      ..._.map(subtypes, (st) => ({
        value: st,
        label: t(`reveal.timeline.sharedNote.subtypes.${st}`),
      })),
      ..._.map(clientCustomActivities || [], (customActivity) => ({
        value: `${customActivity.type}_${customActivity.id}`,
        label: getTranslatedText(customActivity.title),
      })),
    ];
  }, [t, clientCustomActivities]);

  const missionOptionMap = useMemo(() => {
    const result = {} as Record<
      string,
      { label: string; value: string; isCurrentContext: boolean }
    >;
    _.each(jobOptions, ({ id, data }) => {
      result[id] = {
        value: id,
        label: data?.title || id,
        isCurrentContext: !contextProjectId || id === contextProjectId,
      };
    });
    return result;
  }, [jobOptions, contextProjectId]);

  const missionOptions = useMemo(
    () => _.values(missionOptionMap),
    [missionOptionMap],
  );

  const formErrors = useMemo(() => {
    const result: Record<string, string[]> = {};
    if (mandatoryProjectInSharedNote === 'true' && _.isEmpty(missionIds)) {
      result.projects ??= [];
      result.projects.push('mandatoryProjectInSharedNote');
    }
    return result;
  }, [mandatoryProjectInSharedNote, missionIds]);

  return (
    <>
      <div className={styles.header}>
        <h4>{t(`profile.actionButtons.shared-note`)}</h4>
      </div>
      <div className={styles.form}>
        <div className={styles.selectors}>
          <div className={styles.selector}>
            <div className={styles.label}>
              {t('reveal.timeline.sharedNote.subtype')}
            </div>
            <GenericSelect
              value={_.findWhere(subtypeOptions, {
                value: _.compact([subtype, customActivityId]).join('_'),
              })}
              options={subtypeOptions}
              onChange={(newValue) => {
                if (!newValue) {
                  return;
                }
                const [newSubtype, newCustomActivityId] =
                  newValue.value.split('_');
                setAndEmitValue({
                  ...value,
                  subtype: coerceSubtype(newSubtype),
                  ...(newCustomActivityId
                    ? {
                        customActivityId: newCustomActivityId,
                        format: _.findWhere(clientCustomActivities, {
                          id: newCustomActivityId,
                        })?.format,
                      }
                    : {
                        customActivityId: undefined,
                        format: undefined,
                      }),
                });
              }}
            />
          </div>
          <div className={styles.selector}>
            <div className={styles.label}>
              {t('organization_custom.project_uppercase', { count: 10 })}
            </div>
            <GenericSelect
              isMulti
              value={_.map(
                missionIds,
                (missionId) => missionOptionMap[missionId],
              )}
              options={missionOptions}
              onChange={(newValue) => {
                const newMissionIds = !newValue
                  ? []
                  : _.map(newValue, (item) => item?.value);
                setAndEmitValue({
                  ...value,
                  missionIds: _.compact(newMissionIds),
                });
              }}
              maxMenuHeight={200}
              styles={{
                multiValue: (base, { data }) => ({
                  ...base,
                  backgroundColor: data?.isCurrentContext
                    ? base.backgroundColor
                    : Yellow40,
                }),
                multiValueLabel: (base, { data }) => ({
                  ...base,
                  color: data?.isCurrentContext ? base.color : SecondaryRed120,
                }),
                multiValueRemove: (base, { data }) => ({
                  ...base,
                  color: data?.isCurrentContext ? base.color : SecondaryRed120,
                }),
                ...(!_.isEmpty(formErrors.projects) && {
                  control: (base: CSSObjectWithLabel) => ({
                    ...base,
                    borderColor: SecondaryRed,
                    ':hover': {
                      borderColor: SecondaryRed,
                    },
                  }),
                }),
              }}
            />
            {!_.isEmpty(formErrors.projects) &&
              formErrors.projects?.map((errorId) => (
                <div key={errorId} className={styles.error}>
                  {t(`reveal.timeline.sharedNote.error.${errorId}`)}
                </div>
              ))}
          </div>
          {shouldHideCompanySelectorInSharedNote !== 'true' && (
            <div className={styles.selector}>
              <TargetsForm
                label={t('reveal.timeline.sharedNote.companySelectorLabel')}
                linkedCompany={companyId}
                onLinkedCompany={(company) => {
                  setAndEmitValue({
                    ...value,
                    companyId: company,
                    contactIds: [],
                  });
                }}
                maxMenuHeight={200}
              />
            </div>
          )}
          {companyId && (
            <div className={styles.selector}>
              <CompanyContactsDropdown
                label={t('reveal.timeline.sharedNote.contactSelectorLabel')}
                company={companyId}
                onValue={(newValue) => {
                  setAndEmitValue({
                    ...value,
                    contactIds: newValue,
                  });
                }}
                value={contactIds}
              />
            </div>
          )}
        </div>
        {format && (
          <MetaTaskForm
            format={format}
            onUpdate={(newForm) =>
              setAndEmitValue({
                ...value,
                format: { helpText: newForm.helpText, form: newForm.newForm },
              })
            }
          />
        )}
        <div className={styles.editor}>
          <EmailEditor
            clientId={clientId}
            profileId={profileId}
            defaultValue={text}
            onChange={(newValue: string) => {
              setAndEmitValue({
                ...value,
                text: newValue,
              });
            }}
            mode='comment'
            placeholder={t('editor.placeholder.commentBody')}
            alwaysShowMenu
          />
        </div>
      </div>
      <div className={styles.footer}>
        <NowOrOtherDatePicker
          value={value.date}
          onChange={(date) => {
            setAndEmitValue({
              ...value,
              date,
            });
          }}
        />
        <GenericButton
          disabled={isSaving || !_.isEmpty(formErrors)}
          onClick={() => onSave(value)}
        >
          {isSaving ? <Loader /> : t('common.save')}
        </GenericButton>
      </div>
    </>
  );
};

export default SharedNoteForm;
