import React from 'react';

const Inbox = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2589_182)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3 1.5H13C13.8284 1.5 14.5 2.17157 14.5 3V6.40134C13.6195 5.89202 12.6269 6.22388 11.8124 6.71253L10.5725 7.45651C8.98907 8.40655 7.01093 8.40655 5.42752 7.45651L4.18756 6.71253C3.37314 6.22388 2.38046 5.89202 1.5 6.40134V3C1.5 2.17157 2.17157 1.5 3 1.5ZM0 8.99999V3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V8.99999V13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13V8.99999ZM5 12C5 12.5523 5.44772 13 6 13H10C10.5523 13 11 12.5523 11 12C11 11.4477 10.5523 11 10 11H6C5.44772 11 5 11.4477 5 12Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_2589_182'>
          <rect width='16' height='16' fill='currentColor' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Inbox;
