import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './svg.module.less';

const Location: FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    className={classNames(styles.svgIcon, className)}
    viewBox='0 0 12 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6 14.8187L1.75734 10.576C0.918228 9.73689 0.346791 8.66779 0.115286 7.5039C-0.11622 6.34002 0.00260456 5.13362 0.456732 4.03727C0.91086 2.94092 1.6799 2.00385 2.66659 1.34457C3.65328 0.685281 4.81332 0.333389 6 0.333389C7.18669 0.333389 8.34672 0.685281 9.33342 1.34457C10.3201 2.00385 11.0891 2.94092 11.5433 4.03727C11.9974 5.13362 12.1162 6.34002 11.8847 7.5039C11.6532 8.66779 11.0818 9.73689 10.2427 10.576L6 14.8187ZM9.3 9.63334C9.9526 8.98067 10.397 8.14915 10.577 7.24392C10.7571 6.33869 10.6646 5.4004 10.3114 4.54771C9.95817 3.69501 9.36003 2.96621 8.59261 2.45345C7.82519 1.94069 6.92296 1.66701 6 1.66701C5.07704 1.66701 4.17481 1.94069 3.40739 2.45345C2.63997 2.96621 2.04183 3.69501 1.68861 4.54771C1.33539 5.4004 1.24294 6.33869 1.42297 7.24392C1.603 8.14915 2.04741 8.98067 2.7 9.63334L6 12.9333L9.3 9.63334ZM6 7.66667C5.64638 7.66667 5.30724 7.52619 5.05719 7.27615C4.80715 7.0261 4.66667 6.68696 4.66667 6.33334C4.66667 5.97971 4.80715 5.64058 5.05719 5.39053C5.30724 5.14048 5.64638 5 6 5C6.35362 5 6.69276 5.14048 6.94281 5.39053C7.19286 5.64058 7.33334 5.97971 7.33334 6.33334C7.33334 6.68696 7.19286 7.0261 6.94281 7.27615C6.69276 7.52619 6.35362 7.66667 6 7.66667Z'
      fill='#A5ABC9'
    />
  </svg>
);

export default Location;
