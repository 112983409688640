import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './svg.module.less';

interface CrossProps {
  className?: string;
}

const Cross: FC<CrossProps> = ({ className }) => (
  <svg
    className={classNames(styles.svgIcon, className)}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5 5L11 11'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M11 5L5 11'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
);

export default Cross;
