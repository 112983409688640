import React from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { Button, Label } from 'semantic-ui-react';
import { getTranslatedText } from '../../../common';

import TranslatableText from '../../../components/TranslatableText';
import JobPostingDescription from './components/JobPostingDescription';

import './JobPostingDisplay.less';

const careerPageURL = 'https://join.hiresweet.com';

const JobPostingDisplay = ({
  careerPageId,
  jobPosting,
  jobFoldering,
  onClickEdit,
  t,
}) => {
  const { title, description } = jobPosting;
  return (
    <div className='job-posting-display'>
      <div className='display-header'>
        <div className='display-header-info'>
          <div className='header-and-status'>
            <a
              href={`${careerPageURL}/${careerPageId}/jobs/${jobPosting?.id}`}
              target='_blank'
              rel='noreferrer noopener'
            >
              <h2 className='header'>
                <TranslatableText text={title} />
              </h2>
              <i className='link-icon ri-external-link-line' />
            </a>
            <span
              className={classNames(
                'publication-status',
                jobPosting?.published ? 'published' : 'unpublished',
              )}
            >
              {jobPosting?.published ? (
                <i className='ri-checkbox-blank-circle-fill' />
              ) : (
                <i className='ri-checkbox-blank-circle-line' />
              )}
              {jobPosting?.published
                ? t('offers.jobPostings.display.published')
                : t('offers.jobPostings.display.notPublished')}
            </span>
          </div>

          <FolderingDisplay foldering={jobFoldering} />

          <LocationsDisplay locations={jobPosting?.locations} />
        </div>

        <div className='hs-flex-spacer' />

        <div className='display-header-buttons'>
          <Button primary onClick={onClickEdit}>
            {t('offers.jobPostings.display.edit')}
          </Button>
        </div>
      </div>

      <div className='job-description-container'>
        <JobPostingDescription description={description} />
      </div>
    </div>
  );
};

const FolderingDisplay = ({ foldering }) => {
  const { department, section } = foldering || {};
  return (
    <h5 className='foldering-display'>
      {department?.title && <span>{department?.title}</span>}
      {section?.title && <span> / {section?.title}</span>}
    </h5>
  );
};

const LocationsDisplay = ({ locations }) => {
  return (
    <div className='locations-display'>
      {_.map(locations, ({ label }, index) => (
        <Label key={index}>{getTranslatedText(label)}</Label>
      ))}
    </div>
  );
};

export default withTranslation('translations')(JobPostingDisplay);
