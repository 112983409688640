import gql from 'graphql-tag';
import MinimalTask from '@/graphql/fragments/MinimalTask';
import ProfileSequenceInfoWithSequence from '@/graphql/fragments/ProfileSequenceInfoWithSequence';
import ProfileMissionInfoWithMission from '@/graphql/fragments/ProfileMissionInfoWithMission';
import ContactFlow from '../../graphql/fragments/ContactFlow';
import TimelineItem from '../../graphql/fragments/TimelineItem';

export const lockSearchPoolProfileMutation = gql`
  mutation lockSearchPoolProfile(
    $searchPoolId: ID!
    $input: LockSearchPoolProfileInput!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      lock(input: $input) {
        profile {
          id
          locked
          lockedSince
          contacted
          sent
        }
      }
    }
  }
`;

export const unlockSearchPoolProfileMutation = gql`
  mutation unlockSearchPoolProfile(
    $searchPoolId: ID!
    $input: UnlockSearchPoolProfileInput!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      unlock(input: $input) {
        profile {
          id
          locked
          lockedSince
          contacted
          sent
        }
      }
    }
  }
`;

export const searchPoolProfileEmailSentMutation = gql`
  mutation searchPoolProfileEmailSent(
    $searchPoolId: ID!
    $input: SearchPoolProfileEmailSentInput!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      emailSent(input: $input) {
        profile {
          id
          locked
          lockedSince
          contacted
          sent
          contactData {
            defaultThreadId
            defaultSubject
            timeline {
              ...TimelineItem
            }
          }
          contactFlow {
            ...ContactFlow
          }
          currentSequenceInfo {
            ...ProfileSequenceInfoWithSequence
          }
          missionsInfo {
            ...ProfileMissionInfoWithMission
          }
        }
        task {
          id
          ...MinimalTask
        }
      }
    }
  }
  ${ContactFlow}
  ${TimelineItem}
  ${ProfileSequenceInfoWithSequence}
  ${ProfileMissionInfoWithMission}
  ${MinimalTask}
`;
