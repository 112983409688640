import { ATStype } from '@/common/reveal';
import { Contract } from '@/common/reveal/SourceData.type';

type PartialAtsFilters = {
  [k in ATStype]?: (contracts: Contract[]) => Contract[];
};

const contractSmartEndDate = (contract: Contract) => {
  return (
    contract.actualEndDate ||
    contract.theoreticalEndDate ||
    contract.flattenedContract.actualEndDate ||
    contract.endDate
  );
};

const NOOP_CONTRACT_FILTER = (contracts: Contract[]) => contracts;
const CONTRACT_FILTER_BY_ATS: PartialAtsFilters = {
  adventure: (contracts) => {
    const sortedContracts = [...contracts].sort((a, b) => {
      return (
        new Date(contractSmartEndDate(b)).getTime() -
        new Date(contractSmartEndDate(a)).getTime()
      );
    });

    // Adventure only uses last 5 contracts
    return sortedContracts
      .filter((c) => c.status?.state === 'validated')
  },
};

export function getContractsForAts(contracts: Contract[], atsId: ATStype) {
  const atsContractsFilter =
    CONTRACT_FILTER_BY_ATS[atsId] || NOOP_CONTRACT_FILTER;
  return atsContractsFilter(contracts);
}
