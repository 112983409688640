import gql from 'graphql-tag';


// All the needed fields for OfferLabels component
export default gql`
    fragment OfferStatuses on Offer {
      id
      isHold
      isArchived
      isAutopilot
      isWaitingForValidation
      isHiresweetWatch
    }
`;
