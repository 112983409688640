import React, { FC } from 'react';
import _ from 'underscore';

import MultilingualSelect from '@/components/Common/MultilingualSelect/MultilingualSelect';

import { jobOptions } from './helpers';

type JobSelectProps = {
  value: string;
  onValue: (value: string | undefined) => void;
};

const JobSelect: FC<JobSelectProps> = ({ value, onValue }) => (
  <MultilingualSelect options={jobOptions} value={value} onValue={onValue} />
);

export default JobSelect;
