import React, { useCallback, useEffect, useRef, useState } from 'react';
import useClientRect from './useClientRect';

interface DisplayedItemsInfo {
  displayedItemsNumber: number;
  finalWidth: number;
  remainingWidth: number;
}

const getDisplayedItemsInfo = (
  containerWidth: number,
  list: HTMLElement | null,
  offset = 0,
): DisplayedItemsInfo => {
  if (!list) {
    return {
      displayedItemsNumber: 0,
      finalWidth: 0,
      remainingWidth: offset,
    };
  }
  let widthAccumulator = 0;
  const { children } = list;

  for (let i = 0; i < children.length; i += 1) {
    const child = children[i];
    const childWidth = child.clientWidth || 0;
    const totalWidth = widthAccumulator + childWidth;

    if (totalWidth > containerWidth - offset) {
      return {
        displayedItemsNumber: i,
        finalWidth: widthAccumulator,
        remainingWidth: containerWidth - widthAccumulator,
      };
    }
    widthAccumulator = totalWidth;
  }

  return {
    displayedItemsNumber: children.length,
    finalWidth: widthAccumulator,
    remainingWidth: offset,
  };
};

interface ResizableItemListInfo<ListType extends HTMLElement> {
  containerRef: (node: HTMLElement | null) => void;
  listRef: React.RefObject<ListType>;
  displayedItemsNumber: number;
  finalWidth: number;
  remainingWidth: number;
  refresh: () => void;
}

/**
 * A hook that allows for a list of elements to display dynamically based on the space available in the container.
 * The hook uses refs to fetch the width of both the container and the children,
 * which means you must ensure that those elements (especially the children)
 * exist and take up the space they're supposed to (use visibility: hidden instead of display: none).
 *
 * @param offset Some extra width you may want to spare in your container (defaults to 0)
 * @returns {Object} containerRef a reference to plug to the container
 * @returns {Object} listRef a reference to plug to the list
 * @returns {number} displayedItemsNumber the number of children that can be visible in the container
 * @returns {number} finalWidth the space used by all the visible children
 * @returns {number} remainingWidth the space available in the container once extra children are hidden
 * @returns {function} refresh a function that can be called to force an update
 */
const useResizableItemList = <ListType extends HTMLElement>(
  offset = 0,
): ResizableItemListInfo<ListType> => {
  const { rect: containerRect, ref: containerRef } = useClientRect();
  const listRef = useRef<ListType>(null);

  const [displayedItemsNumber, setDisplayedItemsNumber] = useState(0);
  const [remainingWidth, setRemainingWidth] = useState(0);
  const [finalWidth, setFinalWidth] = useState(0);

  const updateDisplayedItemsInfo = useCallback(() => {
    const containerWidth = containerRect?.width || 0;
    const list = listRef.current;
    const {
      displayedItemsNumber: number,
      finalWidth: fWidth,
      remainingWidth: rWidth,
    } = getDisplayedItemsInfo(containerWidth, list, offset);
    setDisplayedItemsNumber(number);
    setRemainingWidth(rWidth);
    setFinalWidth(fWidth);
  }, [containerRect, offset]);

  useEffect(updateDisplayedItemsInfo, [
    containerRect,
    updateDisplayedItemsInfo,
  ]);

  const refresh = updateDisplayedItemsInfo;

  return {
    containerRef,
    listRef,
    displayedItemsNumber,
    remainingWidth,
    finalWidth,
    refresh,
  };
};

export default useResizableItemList;
