import gql from 'graphql-tag';
import {
  FetchResult,
  MutationHookOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import { getOfferProfiles } from '@/hocs/offers/withOfferProfiles';
import SearchPoolProfileState from '@/graphql/fragments/SearchPoolProfileState';

const ADD_PROFILES_TO_OFFER = gql`
  mutation addProfilesToOffer($ids: [ID!]!, $jobOfferId: ID!) {
    addMultiplePoolProfilesToOffer(ids: $ids, jobOfferId: $jobOfferId) {
      id
      searchPoolState {
        ...SearchPoolProfileState
      }
    }
  }
  ${SearchPoolProfileState}
`;

interface AddProfilesToOfferData {
  id: string;
}

interface AddProfilesToOfferVariables {
  ids: string[];
  jobOfferId: string;
}

interface UseAddProfilesToOfferInput {
  mutationOptions?: MutationHookOptions<
    AddProfilesToOfferData,
    AddProfilesToOfferVariables
  >;
}

type UseAddProfilesToOfferResult = [
  (
    ids: string[],
    jobOfferId: string,
  ) => Promise<FetchResult<AddProfilesToOfferData>>,
  MutationResult<AddProfilesToOfferData>,
];

const useAddProfilesToOffer = ({
  mutationOptions = {},
}: UseAddProfilesToOfferInput = {}): UseAddProfilesToOfferResult => {
  const [mutation, result] = useMutation<
    AddProfilesToOfferData,
    AddProfilesToOfferVariables
  >(ADD_PROFILES_TO_OFFER, {
    ...mutationOptions,
  });

  return [
    (ids, jobOfferId) =>
      mutation({
        variables: { ids, jobOfferId },
        refetchQueries: [
          {
            query: getOfferProfiles,
            variables: {
              offerId: jobOfferId,
              step: '',
              search: '',
            },
          },
          {
            query: getOfferProfiles,
            variables: {
              offerId: jobOfferId,
              step: 'pending',
              search: '',
            },
          },
        ],
      }),
    result,
  ];
};

export default useAddProfilesToOffer;
