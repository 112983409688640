import { RevealProject } from '@/common/reveal';
import getFilterOptions, { FilterOptions } from '../Filters/options';

const WORKABLE_APPLICATION_STATUS_OPTIONS = [
  {
    id: 'active',
    name: 'Active',
  },
  {
    id: 'hired',
    name: 'Hired',
  },
  {
    id: 'rejected',
    sourceId: 'rejected',
    name: 'Archived',
  },
];

const getWorkableOptions = ({
  projectId,
  projectsWithFilterOptions,
}: {
  projectId: string;
  projectsWithFilterOptions: RevealProject[];
}): FilterOptions =>
  getFilterOptions({
    connectorType: 'workable',
    projectId,
    projectsWithFilterOptions,
    getApplicationStatusOptions: () => WORKABLE_APPLICATION_STATUS_OPTIONS,
  });

export default getWorkableOptions;
