import React, { FC } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import AvatarColumn from '@/components/ProfileRow/columns/AvatarColumn';
import NameAndHeadlineColumn from '@/components/ProfileRow/columns/NameAndHeadlineColumn';
import HeaderRow from '@/components/ProfileRow/HeaderRow/HeaderRow';
import usePagination from '@/hooks/ui/usePagination';
import ProfileRow from '../ProfileRow';
import { ProfileCampaignDetail } from './types';
import CampaignColumn from './CampaignColumn';

import styles from './StatsProfileAndCampaignTable.module.less';

interface StatsProfileAndCampaignTableProps {
  profileDetails: ProfileCampaignDetail[];
}

const StatsProfileAndCampaignTable: FC<StatsProfileAndCampaignTableProps> = ({
  profileDetails,
}) => {
  const { t } = useTranslation();

  const { pageElements, numberOfPages, PageSelector } = usePagination(
    profileDetails,
  );

  return (
    <div className={styles.table}>
      <HeaderRow
        columns={[
          { id: 'avatar', title: '', className: styles.avatar },
          {
            id: 'contact',
            title: t('reveal.missions.mission.tableHeader.contact'),
            className: styles.nameAndHeadline,
          },
          {
            id: 'campaign',
            title: t('reveal.reports.marketing.campaign'),
            className: styles.campaign,
          },
        ]}
      />
      <div>
        {_.map(pageElements, ({ profileId, campaignId, day }, index) => (
          <ProfileRow
            key={`${campaignId}_${profileId}_${index}`}
            profileId={profileId}
            columns={[
              {
                id: 'avatar',
                component: AvatarColumn,
                className: styles.avatar,
              },
              {
                id: 'contact',
                component: NameAndHeadlineColumn,
                className: styles.nameAndHeadline,
              },
              {
                id: 'campaign',
                component: ({ profile, profileLoading }) => (
                  <CampaignColumn
                    day={day}
                    profile={profile}
                    profileLoading={profileLoading}
                    campaignId={campaignId}
                    className={styles.campaign}
                  />
                ),
              },
            ]}
          />
        ))}
      </div>
      {numberOfPages > 1 && (
        <div className={styles.pagination}>
          <PageSelector />
        </div>
      )}
    </div>
  );
};

export default StatsProfileAndCampaignTable;
