import { graphql } from '@apollo/client/react/hoc';
import { GET_CLIENT_SNIPPETS } from '@/graphql/snippets';

export default graphql(GET_CLIENT_SNIPPETS, {
  props: (query) => {
    const clientSnippets = query?.data?.snippets || [];
    return {
      clientSnippets,
    };
  },
});
