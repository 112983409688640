import { graphql } from '@apollo/client/react/hoc';
import { GET_USER_SENDERS } from '@/graphql/users';

export default graphql(GET_USER_SENDERS, {
  options: ({ withOwnedSenders, withGrantedSenders }) => ({
    variables: {
      ...(withGrantedSenders && { granted: withGrantedSenders }),
      ...(withOwnedSenders && { owned: withOwnedSenders }),
    },
  }),
  props: ({ data: { loading, user, error } }) => ({
    loading,
    user,
    error,
  }),
});
