/* eslint-disable max-len */
import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './svg.module.less';

interface WebsiteProps {
  className?: string;
}

const Website: FC<WebsiteProps> = ({ className }) => (
  <svg
    className={classNames(styles.svgIcon, className)}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill='none'
      d='M15 8C15 4.82898 12.8915 2.15043 10 1.28988M15 8C15 11.171 12.8915 13.8496 10 14.7101M15 8H1M1 8C1 4.82898 3.10851 2.15043 6 1.28988M1 8C1 11.171 3.10851 13.8496 6 14.7101M10 1.28988C7.10851 2.15043 5 4.82898 5 8C5 11.171 7.10851 13.8496 10 14.7101M10 1.28988C9.36629 1.10128 8.69497 1 8 1C7.30503 1 6.63371 1.10128 6 1.28988M6 1.28988C8.89149 2.15043 11 4.82898 11 8C11 11.171 8.89149 13.8496 6 14.7101M6 14.7101C6.63371 14.8987 7.30503 15 8 15C8.69497 15 9.36629 14.8987 10 14.7101'
      stroke='currentColor'
      strokeWidth='1.5'
    />
  </svg>
);

export default Website;
