import React from 'react';
import { Segment, Header, Divider } from 'semantic-ui-react';
import octicons from 'octicons';

import GITHUB_COLORS from './github-colors.json';

const RepositoryCard = ({
  name,
  fullname,
  description,
  language,
  stargazersCount,
  forksCount,
  onOpenRepository,
}) => {
  const langColor = (GITHUB_COLORS[language || 'JavaScript'] || {}).color;
  return (
    <Segment className='repository-card'>
      <Header className='repo-stats' floated='right' size='small'>
        {language && (
          <div className='language'>
            <div className='icon-container'>
              <div
                className='circle-icon'
                style={{ backgroundColor: langColor || 'grey' }}
              />
            </div>
            <div className='text-container'>
              <span className='text'>{language}</span>
            </div>
          </div>
        )}
        {stargazersCount !== undefined && stargazersCount > 0 && (
          <span className='stars'>
            <div className='icon-container'>
              <span
                className='icon star-icon'
                dangerouslySetInnerHTML={{ __html: octicons.star.toSVG() }}
              />
            </div>
            <div className='text-container'>
              <span className='text'>{stargazersCount}</span>
            </div>
          </span>
        )}

        {forksCount !== undefined && forksCount > 0 && (
          <span className='forks'>
            <div className='icon-container'>
              <span
                className='icon forks-icon'
                dangerouslySetInnerHTML={{
                  __html: octicons['repo-forked'].toSVG(),
                }}
              />
            </div>
            <div className='text-container'>
              <span className='text'>{forksCount}</span>
            </div>
          </span>
        )}
      </Header>
      <Header className='repo-title' floated='left' size='small'>
        <a
          className='repo-link'
          href={`https://github.com/${fullname}`}
          target='_blank'
          rel='noopener noreferrer'
          onClick={onOpenRepository}
        >
          {name}
        </a>
      </Header>

      <Divider clearing hidden fitted />

      <p className='description'>{description}</p>
    </Segment>
  );
};

export default RepositoryCard;
