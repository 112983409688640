import React, { useState } from 'react';
import _ from 'underscore';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';

import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import GenericModal from '@/components/Common/GenericModal';
import { useAddProfilesToCampaignMutation } from '@/graphql/AddProfilesToCampaignMutation';
import { CLIENT_MINI_CAMPAIGNS } from '@/containers/Parameters/Campaigns/queries';
import useUserSenders from '@/graphql/hooks/users/useUserSenders';
import { AddToCampaignForm } from '../../SearchView/CandidatesListPane/SideBars/AddToCampaignSidebar';

interface AddToCampaignModalProps {
  clientId: string;
  profileId: string;
  onClose: () => void;
}

const AddToCampaignModal: React.FC<AddToCampaignModalProps> = ({
  clientId,
  profileId,
  onClose,
}) => {
  const { t } = useTranslation();
  const notifications = useNotificationSystem();
  const [selectedCampaignId, setSelectedCampaignId] = useState('');
  const [campaignScheduleType, setCampaignScheduleType] = useState('');
  const [campaignScheduleDate, setCampaignScheduleDate] = useState(new Date());
  const [sender, setSender] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const variables = { clientId, filters: { activeOnly: true } };
  const clientCampaigns = useQuery(CLIENT_MINI_CAMPAIGNS, {
    variables,
  });
  const clientCampaignsList = _.filter(
    clientCampaigns?.data?.client?.campaigns,
    ({ isArchived }) => !isArchived,
  );

  const { data: userSendersData } = useUserSenders({
    granted: true,
    owned: true,
  });

  const senders = userSendersData?.user?.senders;

  const [addProfilesToCampaign] = useAddProfilesToCampaignMutation();

  const onSubmit = async () => {
    setSubmitting(true);
    try {
      const { data } = await addProfilesToCampaign({
        variables: {
          searchPoolId: 'reveal',
          input: {
            campaignId: selectedCampaignId,
            scheduleDate: campaignScheduleDate,
            profileIds: [profileId],
            assignedSender: {
              type: 'shared-sender',
              senderId: sender,
            },
          },
        },
      });

      const results = data?.searchPool?.addProfilesToCampaign?.results || [];
      const successes = _.filter(results, ({ success }) => success);
      const errors = _.filter(results, ({ success }) => !success);

      // TODO: add a 'warning' / orange level for future use (instead of showing 2 notifications ?)
      if (!_.isEmpty(errors)) {
        _.map(errors, (error) => {
          notifications.error(
            t(`reveal.campaignSideBar.errors.${error.error}`, {
              name: `${error.profile.resumeData.firstname} ${error.profile.resumeData.lastname}`,
            }),
          );
        });
      }

      if (!_.isEmpty(successes)) {
        // TODO: custom notif with link
        notifications.success(
          t('applyCampaignDropdown.success', {
            count: successes.length > 1 ? 2 : 1,
            nb: successes.length,
          }),
        );
        // TODO: GO TO LASER FOCUS (?)
      } else {
        // setSubmitting(false);
        // onClose();
      }

      setSubmitting(false);
      onClose();
    } catch (e) {
      notifications.error(t('reveal.recommendedProfiles.campaign.addError'));
      setSubmitting(false);
      onClose();
    }
  };

  return (
    <GenericModal open onClose={onClose} size='tiny'>
      <Modal.Header>
        {t('reveal.missions.mission.actions.addToCampaign')}
      </Modal.Header>
      <Modal.Content>
        <AddToCampaignForm
          clientCampaignsList={clientCampaignsList}
          onSelectedCampaignId={setSelectedCampaignId}
          campaignScheduleType={campaignScheduleType}
          onCampaignScheduleType={setCampaignScheduleType}
          campaignScheduleDate={campaignScheduleDate}
          onCampaignScheduleDate={setCampaignScheduleDate}
          profileAmount={1}
          senders={senders}
          onSelectSender={setSender}
        />
      </Modal.Content>

      <Modal.Actions>
        <div className='align-left'>
          <Button content='Cancel' onClick={onClose} className='dismiss' />
        </div>

        <Button
          primary
          content='Add'
          size='big'
          disabled={
            !selectedCampaignId ||
            !sender ||
            !campaignScheduleType ||
            submitting
          }
          type='button'
          onClick={onSubmit}
        />
      </Modal.Actions>
    </GenericModal>
  );
};

export default AddToCampaignModal;
