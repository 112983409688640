import {
  PrimaryCobalt20,
  PrimaryCobalt60,
  Purple,
  SecondarySkyBlue,
} from '@/less/colors';
import { Gender } from '@/types/gender';

export const GENDER_STACK = [
  {
    datakey: 'female',
    color: Purple,
    i18nKey: 'reveal.reports.diversity.genders.female',
  },
  {
    datakey: 'male',
    color: PrimaryCobalt60,
    i18nKey: 'reveal.reports.diversity.genders.male',
  },
  {
    datakey: 'other',
    color: SecondarySkyBlue,
    i18nKey: 'reveal.reports.diversity.genders.other',
  },
  {
    datakey: 'unknown',
    color: PrimaryCobalt20,
    i18nKey: 'reveal.reports.diversity.genders.unknown',
  },
];

export type GenderRecord = Record<Gender, number>;

export const getInitialGenderRecord = (): GenderRecord => ({
  female: 0,
  male: 0,
  other: 0,
  unknown: 0,
});

export const getInitialProfileItemsByGenderMap = (): Record<
  Gender,
  { profileId: string }[]
> => ({
  female: [],
  male: [],
  other: [],
  unknown: [],
});

export const mergeProfileItemsByGenderMaps = (
  profileItemsByGender1: Record<Gender, { profileId: string }[]>,
  profileItemsByGender2: Record<Gender, { profileId: string }[]>,
): Record<Gender, { profileId: string }[]> => ({
  female: [...profileItemsByGender1.female, ...profileItemsByGender2.female],
  male: [...profileItemsByGender1.male, ...profileItemsByGender2.male],
  other: [...profileItemsByGender1.other, ...profileItemsByGender2.other],
  unknown: [...profileItemsByGender1.unknown, ...profileItemsByGender2.unknown],
});
