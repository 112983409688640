import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import useAggregatedContacts from '@/graphql/hooks/clients/useAggregatedContacts';
import useClientId from '@/hooks/router/useClientId';
import { Gender, genderArray } from '@/types/gender';
import { Stats } from '@/types/statistics/stats';
import useAggregatedContactsDetails from '@/graphql/hooks/clients/useAggregatedContactsDetails';
import InfoTooltip from '@/components/Common/InfoTooltip';
import { isDefinedGender } from '@/common/helpers/gender';
import GenericModal from '@/components/Common/GenericModal';
import StatsProfileTable from '../../../components/StatsProfileTable';
import { GenderRecord, GENDER_STACK } from '../helpers';
import HorizontalParallelBarChart from '../../../components/HorizontalParallelBarChart';
import DetailsModalHeader from '../../../components/DetailsModalHeader';

import styles from './GenderBySource.module.less';

interface StatsFilter {
  gender: Gender;
  sourceType: string;
}

const GenderBySource: FC = () => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { loading, data: clientData } = useAggregatedContacts(clientId, [
    'gender',
    'source-type',
  ]);

  const { aggregatedContacts } = clientData?.client.statistics || {};

  const [
    fetchDetails,
    { loading: detailsLoading, aggregatedContacts: aggregatedContactsDetails },
  ] = useAggregatedContactsDetails();
  const [statsFilter, setStatsFilter] = useState<StatsFilter | undefined>(
    undefined,
  );
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => setModalOpen(detailsLoading || !!aggregatedContactsDetails), [
    aggregatedContactsDetails,
    detailsLoading,
  ]);

  const fetchWithFilter = useCallback(
    (newFilter: StatsFilter) => {
      if (!fetchDetails) {
        return;
      }
      setStatsFilter(newFilter);
      const { gender, sourceType } = newFilter;
      if (!sourceType || !gender) {
        return;
      }

      fetchDetails({
        clientId,
        categorizations: ['gender', 'source-type'],
        categoriesFilter: [
          {
            categorizationId: 'gender',
            categoryId: { in: [gender] },
          },
          {
            categorizationId: 'source-type',
            categoryId: { in: [sourceType] },
          },
        ],
      });
    },
    [fetchDetails, clientId],
  );

  const stats: Stats[] = useMemo(() => {
    if (!aggregatedContacts) {
      return [];
    }

    const statMap = {} as Record<string, GenderRecord>;

    _.each(aggregatedContacts, ({ categories, count }) => {
      const sourceTypeId = _.find(
        categories,
        ({ categorizationId }) => categorizationId === 'source-type',
      )?.categoryId;

      if (!sourceTypeId) {
        throw new Error('Unexpected undefined source type');
      }

      const coercedSourceTypeId = _.contains(
        ['reveal-csv-import', 'reveal-plugin-import', 'reveal-app-import'],
        sourceTypeId,
      )
        ? sourceTypeId
        : 'unknown';

      statMap[coercedSourceTypeId] = statMap[coercedSourceTypeId] || {};

      const genderId = _.find(
        categories,
        ({ categorizationId }) => categorizationId === 'gender',
      )?.categoryId;

      if (!genderId) {
        throw new Error('Unexpected undefined gender');
      }

      if (isDefinedGender(genderId)) {
        statMap[coercedSourceTypeId][genderId] = count;
      } else {
        statMap[coercedSourceTypeId].unknown =
          (statMap[coercedSourceTypeId].unknown || 0) + count;
      }
    });

    return _.map(statMap, (statRecord, sourceTypeId) => ({
      name: t(`reveal.reports.diversity.sourceTypes.${sourceTypeId}`),
      values: statRecord,
      clickListeners: _.reduce(
        genderArray,
        (listenerMap, gender) => ({
          ...listenerMap,
          [gender]: () => fetchWithFilter({ gender, sourceType: sourceTypeId }),
        }),
        {},
      ),
    }));
  }, [aggregatedContacts, t, fetchWithFilter]);

  const formattedDetailsData: Record<
    string,
    Record<Gender, { profileId: string }[]>
  > = useMemo(() => {
    const profileItemsMap = {} as Record<
      string,
      Record<Gender, { profileId: string }[]>
    >;

    _.each(aggregatedContactsDetails || [], ({ categories, profileItems }) => {
      const sourceTypeId = _.find(
        categories,
        ({ categorizationId }) => categorizationId === 'source-type',
      )?.categoryId;

      if (!sourceTypeId) {
        throw new Error('Unexpected undefined source type');
      }

      const coercedSourceTypeId = _.contains(
        ['reveal-csv-import', 'reveal-plugin-import', 'reveal-app-import'],
        sourceTypeId,
      )
        ? sourceTypeId
        : 'unknown';

      profileItemsMap[coercedSourceTypeId] =
        profileItemsMap[coercedSourceTypeId] || {};

      const genderId = _.find(
        categories,
        ({ categorizationId }) => categorizationId === 'gender',
      )?.categoryId;

      if (!genderId) {
        throw new Error('Unexpected undefined gender');
      }

      if (isDefinedGender(genderId)) {
        profileItemsMap[coercedSourceTypeId][genderId] = [...profileItems];
      } else {
        profileItemsMap[coercedSourceTypeId].unknown = [
          ...(profileItemsMap[coercedSourceTypeId].unknown || []),
          ...profileItems,
        ];
      }
    });

    return profileItemsMap;
  }, [aggregatedContactsDetails]);

  const profileItems = useMemo(() => {
    if (!statsFilter?.sourceType || !statsFilter?.gender) {
      return [];
    }
    const { gender, sourceType } = statsFilter;

    return formattedDetailsData[sourceType]?.[gender] || [];
  }, [formattedDetailsData, statsFilter]);

  const modalTitle = useMemo(() => {
    const titleParts = [] as string[];
    if (statsFilter?.sourceType) {
      titleParts.push(
        t(`reveal.reports.diversity.sourceTypes.${statsFilter.sourceType}`),
      );
    }
    if (statsFilter?.gender) {
      titleParts.push(
        t(`reveal.reports.diversity.genders.${statsFilter.gender}`),
      );
    }
    return titleParts.join(' - ');
  }, [statsFilter, t]);

  return (
    <>
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <h3>
            {t('reveal.reports.diversity.genderBySource')}
            <InfoTooltip rich hoverable position='right center'>
              <h1>
                {t('reveal.reports.tooltips.diversity.genderBySource.title')}
              </h1>
              <h2>{t('reveal.reports.tooltips.common.usefulness')}</h2>
              <p>
                {t(
                  'reveal.reports.tooltips.diversity.genderBySource.usefulnessParagraph',
                )}
              </p>
              <h2>{t('reveal.reports.tooltips.common.details')}</h2>
              <p>{t('reveal.reports.tooltips.common.barDetails')}</p>
              <h2>
                {t('reveal.reports.tooltips.diversity.common.genderLabeling')}
              </h2>
              <p>
                {t(
                  'reveal.reports.tooltips.diversity.common.genderLabelingParagraph',
                )}
              </p>
            </InfoTooltip>
          </h3>
        </div>
        <div className={styles.cardBody}>
          {loading ? (
            <div className={styles.loader}>
              <Loader active inline='centered' size='large' />
            </div>
          ) : (
            <HorizontalParallelBarChart
              stack={GENDER_STACK}
              allStats={stats}
              showPercentage
              exhaustive
            />
          )}
        </div>
      </div>
      {detailsLoading ? (
        <Dimmer active>
          <Loader size='large' />
        </Dimmer>
      ) : (
        <GenericModal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Content>
            <DetailsModalHeader>
              <h1>{modalTitle}</h1>
            </DetailsModalHeader>
            <StatsProfileTable profileItems={profileItems} />
          </Modal.Content>
        </GenericModal>
      )}
    </>
  );
};

export default GenderBySource;
