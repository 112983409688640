import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Loader, Button } from 'semantic-ui-react';
import GenericButton from '@/components/Common/GenericButton';
import SendEmailButton from './SendEmailButton';
import withOfferProfiles from '../../../../../hocs/offers/withOfferProfiles';

const MailButton = ({
  profileId,
  clientId,
  offerId,
  actionId,
  sequenceId,
  offerAssignedSender,
  stepId,
  getNextProfileId,
  isSearchResultProfile,
  onSend,
  onChangeStep,
  onChangeProfile,
  searchText,
  assignedSender,
  mailData,
  t,
  offerProfilesFirstnames,
  handleMarkProfileAsInMove,
  handleUnmarkProfileAsInMove,
  getProfilesInMove,
  emailApi,
}) => {
  const userCanSend =
    emailApi.currentAddress &&
    emailApi.hasOfflineGrant[emailApi.currentAddress] &&
    !emailApi.connectionInProgress;

  // NOTE: this is different from 'assignedSender'
  // which is defined in a profile for autopilot
  const userCanSendOnBehalfOf = !_.isEmpty(offerAssignedSender);
  const canSend = userCanSend || userCanSendOnBehalfOf;

  if (canSend) {
    const assignedSenderIsDefined =
      assignedSender && assignedSender.email && assignedSender.alias;
    return (
      <>
        <span className='email-title'>
          {t('profile.contact.drafts.contactCandidate')}
        </span>
        <SendEmailButton
          clientId={clientId}
          offerId={offerId}
          actionId={actionId}
          sequenceId={sequenceId}
          stepId={stepId}
          searchText={searchText}
          profileId={profileId}
          getNextProfileId={getNextProfileId}
          isSearchResultProfile={isSearchResultProfile}
          onSend={onSend}
          onChangeStep={onChangeStep}
          onChangeProfile={onChangeProfile}
          emailApi={emailApi}
          mailData={mailData}
          offerProfilesFirstnames={offerProfilesFirstnames}
          handleMarkProfileAsInMove={handleMarkProfileAsInMove}
          handleUnmarkProfileAsInMove={handleUnmarkProfileAsInMove}
          getProfilesInMove={getProfilesInMove}
          // TODO: unify assignedSender in a single prop
          assignedSender={assignedSenderIsDefined ? assignedSender : null}
          offerAssignedSender={offerAssignedSender}
        />
      </>
    );
  }

  if (emailApi.connectionInProgress) {
    return (
      <GenericButton primacy='primary' id='button-connection-in-progress'>
        <Loader inline active inverted size='mini' />
        <span>{t('profile.contact.drafts.connectionInProgress')}</span>
      </GenericButton>
    );
  }

  return (
    <>
      <GenericButton
        id='button-gmail-connexion'
        primacy='primary'
        onClick={() => emailApi.onTriggerOfflineRegister('gmail')}
      >
        <div>{t('profile.contact.drafts.gmailConnectShort')}</div>
      </GenericButton>
      <GenericButton
        id='button-outlook-connexion'
        primacy='primary'
        onClick={() =>
          emailApi.onTriggerOfflineRegister('outlook', 'select_account')
        }
      >
        <div>{t('profile.contact.drafts.outlookConnectShort')}</div>
      </GenericButton>
    </>
  );
};

export default compose(
  withOfferProfiles,
  withTranslation('translations'),
)(MailButton);
