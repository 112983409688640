import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Zap from '@/components/Common/Icons/Zap';
import GenericTooltip from '@/components/Common/GenericTooltip';

const ProfilePowerHourIcon: FC = () => {
  const { t } = useTranslation();
  return (
    <GenericTooltip trigger={<Zap />}>
      {t('profile.powerHour.tooltip')}
    </GenericTooltip>
  );
};

export default ProfilePowerHourIcon;
