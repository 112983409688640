import React, { ReactNode } from 'react';
import classnames from 'classnames';
import styles from './WarningBox.module.less';

interface WarningBoxPros {
  title?: string;
  description?: string;
  children?: ReactNode;
  className?: any;
}

const WarningBox: React.FC<WarningBoxPros> = ({
  title,
  description,
  children,
  className,
}) => {
  return (
    <div className={classnames(styles.warningBox, className)}>
      {title && <p className={styles.title}>{title}</p>}
      {description && <p className={styles.description}>{description}</p>}
      {children && [children]}
    </div>
  );
};

export default WarningBox;
