import React from 'react';
import { useMergeTagsPlayground } from '../context/MergeTagsPlaygroundProvider';
import styles from './styles.module.less';

const MergeTagsInstantiatedText: React.FC = () => {
  const { displayedText } = useMergeTagsPlayground();

  return (
    <div className={styles.textWrapper}>
      <div className={styles.instantiatedText}>
        <div>{displayedText}</div>
      </div>
    </div>
  );
};

export default MergeTagsInstantiatedText;
