import classNames from 'classnames';
import React, { ReactNode } from 'react';

import styles from './FakeGenericTextInput.module.less';

interface FakeGenericTextInputProps {
  big?: boolean;
  icon?: ReactNode;
  fluid?: boolean;
  className?: any;
  value?: string;
  disabled?: boolean;
}

const FakeGenericTextInput: React.FC<FakeGenericTextInputProps> = ({
  big = false,
  icon,
  className,
  fluid,
  value,
  disabled,
}) => {
  return (
    <div
      className={classNames(
        styles.container,
        disabled && styles.disabled,
        big && styles.big,
        fluid && styles.fluid,
        className,
      )}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      <div
        className={classNames(styles.input, disabled && styles.inputDisabled)}
      >
        {value}
      </div>
    </div>
  );
};

export default FakeGenericTextInput;
