import React, { FC, MouseEvent } from 'react';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { Profile } from '@/types/profile';
import {
  MARK_PROFILE_AS_GLOBAL_FAVORITE,
  UNMARK_PROFILE_AS_GLOBAL_FAVORITE,
} from '@/graphql/profile';

import useMarkGlobalFavorite from '@/graphql/hooks/searchPoolProfile/useMarkGlobalFavorite';
import useMarkNotGlobalFavorite from '@/graphql/hooks/searchPoolProfile/useMarkNotGlobalFavorite';
import { GET_PROFILE_RESUME } from '@/hocs/profiles/withProfileResume';
import styles from './ProfileFavoriteButton.module.less';

interface ProfileFavoriteButtonProps {
  profile: Profile;
}

const ProfileFavoriteButton: FC<ProfileFavoriteButtonProps> = ({ profile }) => {
  const isStarred =
    profile?.annotation?.globalFavorite?.value ||
    profile?.searchPoolState?.globalFavorite;

  const [markAsGlobalFavoriteMutation] = useMutation(
    MARK_PROFILE_AS_GLOBAL_FAVORITE,
    {
      refetchQueries: [
        {
          query: GET_PROFILE_RESUME,
          variables: {
            id: profile?.id,
          },
        },
      ],
    },
  );
  const [markGlobalFavoriteMutation] = useMarkGlobalFavorite();
  const [unmarkAsGlobalFavoriteMutation] = useMutation(
    UNMARK_PROFILE_AS_GLOBAL_FAVORITE,
  );
  const [markNotGlobalFavoriteMutation] = useMarkNotGlobalFavorite();

  const markAsGlobalFavorite = async (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    await markAsGlobalFavoriteMutation({
      variables: { profileId: profile?.id },
    });
    if (profile.searchPoolState) {
      await markGlobalFavoriteMutation({
        variables: {
          searchPoolId: profile.searchPoolState.searchPoolId || 'watch',
          profileId: profile.searchPoolState.searchPoolProfileId,
        },
      });
    }
  };

  const unmarkAsGlobalFavorite = async (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    await unmarkAsGlobalFavoriteMutation({
      variables: { profileId: profile?.id },
    });
    if (profile.searchPoolState) {
      await markNotGlobalFavoriteMutation({
        variables: {
          searchPoolId: profile.searchPoolState.searchPoolId || 'watch',
          profileId: profile.searchPoolState.searchPoolProfileId,
        },
      });
    }
  };

  return (
    <div
      className={classNames(styles.container, isStarred && styles.isStarred)}
    >
      {isStarred ? (
        <button
          type='button'
          className={classNames(
            'ri-star-fill',
            styles.favoriteButton,
            styles.marketAsFavorite,
          )}
          onClick={unmarkAsGlobalFavorite}
        />
      ) : (
        <button
          type='button'
          className={classNames(
            'ri-star-line',
            styles.favoriteButton,
            styles.notMarketAsFavorite,
          )}
          onClick={markAsGlobalFavorite}
        />
      )}
    </div>
  );
};

export default ProfileFavoriteButton;
