import React from 'react';
import { 
  HiresweetLibProvider, 
  HiresweetCreateProfileButton,
} from '@hiresweet/hiresweet-lib';

import useClientId from '@/hooks/router/useClientId';



const PlaygroundB8 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30, background: 'white' }}>
      <h1>B8 - Profile creation Button</h1>
      <HiresweetLibProvider
        theme={theme}
        clientId={clientId}
      >
        <h4>Creation Form</h4>
        <HiresweetCreateProfileButton />
          
      </HiresweetLibProvider>
    </div>
  );
};

export default PlaygroundB8;
