import React, { FC, useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import { Task } from '@/types/task';
import useMiniMissions from '@/graphql/hooks/searchPoolJobs/useMiniMissions';

import TaskTable from './TaskTable';

type TaskMissionIdTableProps = {
  tasks: Task[];
  onSelect: (id: string, selection?: Task[]) => void;
};

const TaskMissionIdTable: FC<TaskMissionIdTableProps> = ({
  tasks,
  onSelect,
}) => {
  const { t } = useTranslation();
  const { miniMissions } = useMiniMissions();

  const missionTitlesById = useMemo(() => {
    const result = {
      no_mission: t('reveal.tasks.no_mission'),
    } as Record<string, string>;

    _.each(miniMissions || [], ({ id, data }) => {
      result[id] = data.title;
    });

    return result;
  }, [miniMissions, t]);

  const uncompletedTasks = _.filter(
    tasks,
    (task) => task?.state !== 'completed',
  );

  const groupedTasks = getTasksGroupedByMissionId(uncompletedTasks);

  return (
    <>
      {_.map(groupedTasks, (tableTasks, missionId) => {
        return (
          <TaskTable
            key={missionId}
            title={missionTitlesById[missionId] || missionId}
            iconName=''
            tasks={tableTasks}
            onFocusTask={({ taskId }: { taskId: string }) => {
              onSelect(taskId, tableTasks);
            }}
          />
        );
      })}
    </>
  );
};

function getTasksGroupedByMissionId(tasks: Task[]): Record<string, Task[]> {
  const result = {} as Record<string, Task[]>;

  function addTask(missionId: string, task: Task) {
    if (!result[missionId]) {
      result[missionId] = [];
    }
    result[missionId].push(task);
  }

  _.each(tasks, (task) => {
    const missionsInfo = task.target?.missionsInfo || [];
    if (_.isEmpty(missionsInfo)) {
      addTask('no_mission', task);
      return;
    }
    _.each(missionsInfo, ({ missionId }) => {
      addTask(missionId, task);
    });
  });

  return result;
}

export default TaskMissionIdTable;
