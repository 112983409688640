import { useCallback, useMemo, useState } from 'react';
import _ from 'underscore';

import { ProfileCampaignDetail } from '@/routes/RevealView/RevealAnalytics/components/StatsProfileAndCampaignTable/types';
import {
  CampaignDailyCohortDetails,
  StatsCampaignEventType,
} from '@/types/statistics/campaigns';

interface Filter {
  campaignId?: string;
  segment?: StatsCampaignEventType;
}

interface FunnelDetails {
  filter: Filter | undefined;
  applyFilter: (filter: Filter) => void;
  profileDetails: ProfileCampaignDetail[];
}

const useFunnelDetails = (
  data: CampaignDailyCohortDetails[] = [],
  fetchDetails?: (filters: { campaignIds?: string[] }) => void,
): FunnelDetails => {
  const [filter, setFilter] = useState<Filter | undefined>(undefined);

  const applyFilter = useCallback(
    (newFilter: Filter) => {
      if (!fetchDetails) {
        return;
      }
      setFilter(newFilter);
      const { campaignId, segment } = newFilter;
      if (!segment) {
        return;
      }

      fetchDetails({
        campaignIds: campaignId ? [campaignId] : undefined,
      });
    },
    [fetchDetails],
  );

  const profileDetails = useMemo(() => {
    if (!filter?.segment) {
      return [];
    }

    const results = [] as ProfileCampaignDetail[];

    _.each(data, ({ details, day }) => {
      _.each(details, ({ campaignId, profileItems, type }) => {
        if (filter?.campaignId && campaignId !== filter.campaignId) {
          return;
        }
        if (filter.segment !== type) {
          return;
        }
        _.each(profileItems, ({ profileId }) => {
          results.push({ profileId, campaignId, day });
        });
      });
    });

    return results;
  }, [data, filter]);

  return {
    filter,
    applyFilter,
    profileDetails,
  };
};

export default useFunnelDetails;
