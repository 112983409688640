import React from 'react';
import FallbackAction from './FallbackAction';
import ManualAction from './ManualAction';

const GenericAction = (props) => {
  const { action } = props || {};
  // TODO: edge case handled in parent component for now
  // if (action?.type === 'send-email-action') {
  //   return (
  //     <EmailAction {...props}/>
  //   );
  // }

  if (action?.type === 'manual') {
    return (
      <ManualAction {...props}/>
    );
  }
  // console.error(`Unknown action type ${action?.type}`);
  return <FallbackAction {...props}/>;
};

export default GenericAction;
