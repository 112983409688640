import React from 'react';
import { Grid } from 'semantic-ui-react';

import styles from './MergeTagsPlaygroundView.module.less';
import MergeTagsContext from './components/MergeTagsContext';
import { MergeTagsPlaygroundProvider } from './context/MergeTagsPlaygroundProvider';
import MergeTagsText from './components/MergeTagsText';
import MergeTagsSnippets from './components/MergeTagsSnippets';
import MergeTagsInstantiatedText from './components/MergeTagsInstantiatedText';

const MergeTagsPlaygroundView: React.FC = () => {
  return (
    <MergeTagsPlaygroundProvider>
      <div className={styles.wrapper}>
        <h2>Merge Tags</h2>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <h4>Context</h4>
              <MergeTagsContext />
            </Grid.Column>
            <Grid.Column>
              <h4>Template and State</h4>
              <MergeTagsText />
              <MergeTagsSnippets />
            </Grid.Column>
            <Grid.Column>
              <h4>Display</h4>
              <MergeTagsInstantiatedText />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </MergeTagsPlaygroundProvider>
  );
};

export default MergeTagsPlaygroundView;
