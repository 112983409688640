import React, { FC } from 'react';
import _ from 'underscore';
import { Link } from 'react-router-dom';

import useOfferStepProfiles from '@/graphql/hooks/offers/useOfferStepProfiles';
import useClientId from '@/hooks/router/useClientId';
import Avatar from '@/components/Common/Avatar/Avatar';
import { getFirstProfilesAndRemainder } from '../../helpers';

import styles from './OfferPendingPreview.module.less';

interface OfferPendingPreviewProps {
  offerId: string;
}

const OfferPendingPreview: FC<OfferPendingPreviewProps> = ({ offerId }) => {
  const clientId = useClientId();
  const { data, stepProfiles } = useOfferStepProfiles({
    offerId,
    step: 'pending',
  });

  const { firstProfiles, remainder } = getFirstProfilesAndRemainder(
    stepProfiles,
  );

  const { title } = data?.offer || {};

  return (
    <Link className={styles.offer} to={`/client/${clientId}/jobs/${offerId}`}>
      <span className={styles.title}>{title}</span>
      <span className={styles.profiles}>
        <span className={styles.count}>{_.size(stepProfiles)}</span>
        <span className={styles.avatars}>
          {_.map(firstProfiles, ({ id, resumeData }) => (
            <Avatar key={id} className={styles.avatar} person={resumeData} />
          ))}
          {remainder !== 0 && (
            <span className={styles.avatar}>+{remainder}</span>
          )}
        </span>
      </span>
    </Link>
  );
};

export default OfferPendingPreview;
