import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouteMatch } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import withUserFromJWToken from '@/hocs/users/withUserFromJWToken';
import withClientUsers from '@/hocs/clients/withClientUsers';
import {
  getTaskType,
  getTaskTypeAndSubtype,
  TASK_TYPES,
} from '@/common/constants/taskTypes';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { UPDATE_PROFILE_TASK } from '@/graphql/searchPoolProfiles';
import { useClientRevealConnector } from '@/graphql/hooks/clients/useClientRevealProjects';
import RegisteredForm from '@/components/RegisteredForm/RegisteredForm';
import CreateUpdateTaskForm, {
  CreateUpdateTaskFormSchema,
  TASK_TYPE_OPTIONS,
} from '@/routes/RevealView/SearchView/CandidatesListPane/SideBars/CreateUpdateTaskForm';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import { formatTaskOrActionMessage } from '../ProfileContactFlow/helpers';

interface ExplicitTaskEditorProps {
  task: any;
  clientId: string;
  profileId: string;
  user: any;
  users: any;
  usersLoading: any;
  onClose: () => void;
}

const ExplicitTaskEditor: React.FC<ExplicitTaskEditorProps> = ({
  task,
  clientId,
  profileId,
  user,
  users,
  usersLoading,
  onClose,
}) => {
  const { t } = useTranslation();
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();
  const notifications = useNotificationSystem();
  const { params } = useRouteMatch<{ missionId: string }>();
  const [updateProfileTask] = useMutation(UPDATE_PROFILE_TASK);
  const { missionId } = params || {};
  const isDueLater = task?.dueDate > new Date().toISOString();
  const [messageState, setMessageState] = useState(task.message || {});
  const [submitting, setSubmitting] = useState(false);
  const defaultConnector = useClientRevealConnector(clientId);

  const onSubmit = async (data: any) => {
    setSubmitting(true);
    const {
      dueDate,
      selectedOwnerEmail,
      taskDescription,
      taskType,
      synchronize,
      isTimeSelected,
    } = data;

    const selectedTaskTypeOption = _.findWhere(TASK_TYPE_OPTIONS, {
      value: taskType,
    });
    const { type, subtype } = getTaskTypeAndSubtype({
      taskType: selectedTaskTypeOption?.type,
    });
    const selectedUser = _.findWhere(users, { email: selectedOwnerEmail });
    const owner = _.pick(selectedUser, ['firstname', 'lastname', 'email']);

    const date = dueDate || new Date();
    if (!isTimeSelected) {
      date.setHours(8, 0, 0);
    }
    const message = formatTaskOrActionMessage(messageState, taskType);

    const input = {
      profileId,
      id: task.id,
      task: {
        title: selectedTaskTypeOption?.text,
        type,
        ...(subtype && { subtype }),
        owner,
        dueDate: date,
        ...(missionId && { missionId }),
        ...(taskDescription && { description: { default: taskDescription } }),
        message: {
          subject: message.subject,
          body: message.body,
        },
        synchronizeWithATS: synchronize,
      },
    };

    const variables = {
      searchPoolId: 'reveal',
      input,
      explicitTasksFilter: { withDueLater: true },
    };

    const refetchQueries = missionId ? ['getTasksCount'] : undefined;

    try {
      await updateProfileTask({
        variables,
        refetchQueries,
      });
      notifications.success(
        t('reveal.candidatesView.timeline.explicitTasks.editSuccess'),
      );
      publishSubscriptionEvent('onExplicitTasksUpdated', { id: profileId });
      setSubmitting(false);
      if (onClose) {
        onClose();
      }
    } catch (e) {
      Sentry.captureException(e);
      notifications.success(
        t('reveal.candidatesView.timeline.explicitTasks.editError'),
      );
    }
  };

  const synchronizeDefaultValue =
    defaultConnector?.synchronizationSettings?.synchronizeCompletedTasks;

  const defaultValues = {
    selectedOwnerEmail: user?.email,
    taskType: getTaskType(task) || TASK_TYPES.MANUAL,
    dueWhen: task && isDueLater ? 'due-later' : 'due-now',
    dueDate: task?.dueDate ? new Date(task.dueDate) : new Date(),
    isTimeSelected: false,
    taskDescription: task?.description?.default || '',
    synchronize: task?.synchronizeWithATS ?? synchronizeDefaultValue,
  };

  return (
    <RegisteredForm
      id='edit-tasks-form'
      className='create-manual-tasks-form'
      onSubmit={onSubmit}
      resolver={yupResolver(CreateUpdateTaskFormSchema)}
      defaultValues={defaultValues}
    >
      <CreateUpdateTaskForm
        users={users}
        usersLoading={usersLoading}
        clientId={clientId}
        submitting={submitting}
        submitLabel={t('common.save')}
        messageState={messageState}
        setMessageState={setMessageState}
        compact
        withCancel
        onClose={onClose}
      />
    </RegisteredForm>
  );
};

export default compose(
  withUserFromJWToken,
  withClientUsers,
)(ExplicitTaskEditor);
