import React from 'react';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import { Image, Popup } from 'semantic-ui-react';
import { getSecureLink, getRandomDefaultAvatarLink } from '../common';

import './ProfileBubbles.css';

const MiniProfileBubbleRaw = ({ profile, clientId, offerId, logAction }) => {
  const { firstname, lastname, photoLink } = profile?.resumeData || {};
  const avatarLink = getRandomDefaultAvatarLink(firstname + lastname);
  return (
    <div className='mini-profile-bubble'>
      {clientId && offerId && profile?.id ? (
        <Link
          to={`/client/${clientId}/jobs/${offerId}/profiles/${profile.id}`}
          onClick={() => logAction && logAction({ type: 'click-mini-profile-avatar' })}
        >
          <Image
            circular
            src={getSecureLink(photoLink) || avatarLink}
            onError={(e) => { e.target.src = avatarLink; }}
          />
        </Link>
      ) : (
        <Image
          circular
          src={getSecureLink(photoLink) || avatarLink}
          onError={(e) => { e.target.src = avatarLink; }}
        />
      )}
    </div>
  );
};

export const MiniProfileBubble = ({ profile, clientId, offerId, logAction }) => {
  if (!profile?.resumeData?.firstname || !profile?.resumeData?.lastname) {
    return null;
  }
  return (
    <Popup
      basic
      trigger={(
        <div>
          <MiniProfileBubbleRaw profile={profile} clientId={clientId}
                                offerId={offerId} logAction={logAction}/>
        </div>
      )}
    >
      <div className='mini-profile-bubble-popup-content'>
        <div
          className='profile-name'>{`${profile?.resumeData?.firstname} ${profile?.resumeData?.lastname}`}</div>
        {profile?.resumeData?.headline?.content?.text && (
          <div
            className='profile-headline'>{profile?.resumeData?.headline?.content?.text}</div>
        )}
      </div>
    </Popup>
  );
};

const OtherProfiles = ({ nbLeft, offerId, clientId, logAction }) => {
  if (!nbLeft) {
    return '';
  }
  if (!offerId || !clientId) {
    return (
      <div className='counter'>
        <div className='counter-inner'>
          <center>+{nbLeft}</center>
        </div>
      </div>
    );
  }
  return (
    <Link
      to={`/client/${clientId}/jobs/${offerId}/profiles/`}
      onClick={() => logAction &&
        logAction({ type: 'click-other-profiles-avatar' })}
    >
      <div className='counter'>
        <div className='counter-inner'>
          <center>+{nbLeft}</center>
        </div>
      </div>
    </Link>
  );
};

export const MiniProfiles = ({ profiles, nbLeft, offerId, clientId, logAction }) => (
  <div className='mini-profiles-container'>
    {_.map(profiles, (p) => (
      <MiniProfileBubble key={p?.id} profile={p} offerId={offerId}
                         clientId={clientId} logAction={logAction}/>
    ))}
    <OtherProfiles nbLeft={nbLeft} offerId={offerId} clientId={clientId}
                   logAction={logAction}/>
  </div>
);
