import React, { FC } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import useAtsFilterOptions from '@/graphql/hooks/clients/useClientAtsFiltersOptions';
import GenericFormLabel from '@/components/Common/GenericFormLabel';

import AccordionFilter from '../components/AccordionFilter';
import ProfileInATSFilter from '../components/ProfileInATSFilter';
import { countAllFilters } from '../Filters/utils';
import AtsFilterTitle from '../AtsFilterTitle';
import {
  CreationDateFilter,
  LastActionDateFilter,
} from '../Filters/DateFilters';
import getFilterOptions from '../Filters/options';
import FilterDropdown from '../components/FilterDropdown';

import styles from './SuccessfactorsFilters.module.less';

type Filters = {
  profileInATS?: boolean;
  jobIds?: string[];
  recruiterIds?: string[];
};

type SuccessfactorsFiltersProps = {
  onUpdate: (filters: { successfactorsFilters?: Filters }) => void;
  filters?: Filters;
  projectId: string;
  clientId: string;
};

const SuccessfactorsFilters: FC<SuccessfactorsFiltersProps> = ({
  onUpdate,
  filters,
  projectId,
  clientId,
}) => {
  const { t } = useTranslation();

  const { data: atsFilterOptionsData } = useAtsFilterOptions(clientId, {});

  const projectsWithFilterOptions =
    atsFilterOptionsData?.client?.revealProjects;
  const revealProject = _.findWhere(projectsWithFilterOptions, {
    id: projectId,
  });
  const successfactorsConnector = _.findWhere(revealProject?.connectors, {
    type: 'successfactors',
  });

  if (!successfactorsConnector) {
    return null;
  }

  const handleFilterUpdate = (update: Partial<Filters>) => {
    onUpdate({
      successfactorsFilters: {
        ...filters,
        ...update,
      },
    });
  };

  const { jobOptions, userOptions } = getFilterOptions({
    connectorType: 'successfactors',
    projectId,
    projectsWithFilterOptions,
    getApplicationStatusOptions: () => [],
  });

  return (
    <AccordionFilter
      title={<AtsFilterTitle ats='successfactors' />}
      count={countAllFilters(filters)}
    >
      <div className={styles.section}>
        <GenericFormLabel>
          {t('reveal.searchView.filters.ats.contactExist')}
        </GenericFormLabel>
        <ProfileInATSFilter
          value={filters?.profileInATS}
          onUpdate={handleFilterUpdate}
        />
      </div>
      <div className={styles.section}>
        <GenericFormLabel>
          {t('reveal.searchView.filters.ats.creationDate')}
        </GenericFormLabel>
        <CreationDateFilter
          onUpdate={onUpdate}
          filters={filters}
          atsType='successfactors'
        />
      </div>
      <div className={styles.section}>
        <GenericFormLabel>
          {t('reveal.searchView.filters.ats.lastActionDate')}
        </GenericFormLabel>
        <LastActionDateFilter
          onUpdate={onUpdate}
          filters={filters}
          atsType='successfactors'
        />
      </div>
      <div className={styles.section}>
        <GenericFormLabel>
          {t('reveal.searchView.filters.ats.jobs')}
        </GenericFormLabel>
        <FilterDropdown
          options={jobOptions}
          onChange={(_e, { value }) =>
            handleFilterUpdate({ jobIds: value as string[] })
          }
          value={filters?.jobIds || []}
        />
      </div>
      <div className={styles.section}>
        <GenericFormLabel>
          {t('reveal.searchView.filters.ats.owners')}
        </GenericFormLabel>
        <FilterDropdown
          options={userOptions}
          onChange={(_e, { value }) =>
            handleFilterUpdate({ recruiterIds: value as string[] })
          }
          value={filters?.recruiterIds || []}
        />
      </div>
    </AccordionFilter>
  );
};

export default SuccessfactorsFilters;
