import TimelineItemFragment from '@/graphql/fragments/TimelineItem';
import { TimelineItem } from '@/types/contactData';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

export const getDraftSharedTimelineItems = gql`
  query getDraftSharedTimelineItems($searchPoolId: ID!, $profileId: ID!) {
    searchPool(id: $searchPoolId) {
      id
      profile(id: $profileId) {
        id
        contactData {
          draftSharedTimelineItems {
            ...TimelineItem
          }
        }
      }
    }
  }
  ${TimelineItemFragment}
`;

type Data = {
  searchPool: {
    id: string;
    profile?: {
      id: string;
      contactData?: {
        draftSharedTimelineItems?: TimelineItem[];
      };
    };
  };
};

type Variables = {
  searchPoolId: string;
  profileId: string;
};

type Input = {
  profileId: string;
  searchPoolId?: string;
  queryOptions?: QueryHookOptions<Data, Variables>;
};

type Result = QueryResult<Data, Variables> & {
  draftSharedTimelineItems: TimelineItem[];
};

function useDraftSharedTimelineItems({
  profileId,
  searchPoolId = 'reveal',
  queryOptions = {},
}: Input): Result {
  const query = useQuery<Data, Variables>(getDraftSharedTimelineItems, {
    ...queryOptions,
    variables: {
      profileId,
      searchPoolId,
    },
  });

  const draftSharedTimelineItems =
    query.data?.searchPool.profile?.contactData?.draftSharedTimelineItems || [];

  return { ...query, draftSharedTimelineItems };
}

export default useDraftSharedTimelineItems;
