/* eslint-disable no-restricted-syntax */
import { TFunction } from 'i18next';
import _ from 'underscore';
import {
  AcceptConditionType,
  ConditionsChainingVariable,
  ConditionResult,
  CONDITION_STRING_FIELDS,
  CONDITION_SELECT_FIELDS,
  IfStatement,
} from '@/common/mergeTags/utils';
import {
  CustomFieldDefinition,
  CustomFieldDefinitionEnum,
} from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { getRandomString, getTranslatedText } from '@/common';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import { definedGenders } from '@/common/helpers/gender';

type Option = {
  value: string;
  text: string;
};

export type SimplifiedField = {
  fieldCategory: string;
  fieldId: string;
  fieldType: string;
};

export const getStatementConstructionOptions = ({
  t,
  withConditionalOptions,
  profileCustomFields,
  missionCustomFields,
}: {
  t: TFunction;
  withConditionalOptions: boolean;
  profileCustomFields: CustomFieldDefinition[];
  missionCustomFields: CustomFieldDefinition[];
}): Option[] => {
  const options: Option[] = [];
  if (withConditionalOptions) {
    options.push({
      value: 'and',
      text: t('editor.conditionsChainingVariable.constructionOptions.and'),
    });
    options.push({
      value: 'or',
      text: t('editor.conditionsChainingVariable.constructionOptions.or'),
    });
  }
  options.push({
    value: 'firstname',
    text: t('editor.conditionsChainingVariable.constructionOptions.firstname'),
  });
  options.push({
    value: 'lastname',
    text: t('editor.conditionsChainingVariable.constructionOptions.lastname'),
  });
  options.push({
    value: 'gender',
    text: t('editor.conditionsChainingVariable.constructionOptions.gender'),
  });
  for (const cf of profileCustomFields) {
    options.push({
      value: `profile-custom-field/${
        cf.type === 'enum' ? 'select' : 'string'
      }/${cf.id}`,
      text: getTranslatedText(cf.title),
    });
  }
  for (const cf of missionCustomFields) {
    options.push({
      value: `mission-custom-field/${
        cf.type === 'enum' ? 'select' : 'string'
      }/${cf.id}`,
      text: getTranslatedText(cf.title),
    });
  }

  return options;
};

type AcceptOption = {
  value: AcceptConditionType;
  text: string;
};

export const getStringAcceptConditionOptions = ({
  t,
  fieldType,
}: {
  t: TFunction;
  fieldType: string;
}): AcceptOption[] => {
  const isKnown: AcceptOption = {
    value: 'is-known',
    text: t(
      'editor.conditionsChainingVariable.acceptConditionOptions.is-known',
    ),
  };
  const isUnknown: AcceptOption = {
    value: 'is-unknown',
    text: t(
      'editor.conditionsChainingVariable.acceptConditionOptions.is-unknown',
    ),
  };
  const stringEquals: AcceptOption = {
    value: 'string-equals',
    text: t(
      'editor.conditionsChainingVariable.acceptConditionOptions.string-equals',
    ),
  };
  const stringNotEquals: AcceptOption = {
    value: 'string-not-equals',
    text: t(
      'editor.conditionsChainingVariable.acceptConditionOptions.string-not-equals',
    ),
  };
  const stringContains: AcceptOption = {
    value: 'string-contains',
    text: t(
      'editor.conditionsChainingVariable.acceptConditionOptions.string-contains',
    ),
  };
  const stringNotContains: AcceptOption = {
    value: 'string-not-contains',
    text: t(
      'editor.conditionsChainingVariable.acceptConditionOptions.string-not-contains',
    ),
  };
  const stringIsAmong: AcceptOption = {
    value: 'string-is-among',
    text: t(
      'editor.conditionsChainingVariable.acceptConditionOptions.string-is-among',
    ),
  };

  if (fieldType === 'string') {
    return [
      isKnown,
      isUnknown,
      stringEquals,
      stringNotEquals,
      stringContains,
      stringNotContains,
    ];
  }
  if (fieldType === 'select') {
    return [stringEquals, stringNotEquals, stringIsAmong];
  }
  return [];
};

export const getSelectFieldOptions = ({
  fieldCategory,
  fieldId,
  profileCustomFields,
  missionCustomFields,
  t,
}: {
  fieldCategory: string;
  fieldId: string;
  profileCustomFields: CustomFieldDefinition[];
  missionCustomFields: CustomFieldDefinition[];
  t: TFunction;
}): { text: string; value: string }[] => {
  if (fieldCategory === 'native' && fieldId === 'gender') {
    return _.map(definedGenders, (genderKey) => ({
      text: t(`reveal.reports.diversity.genders.${genderKey}`),
      value: genderKey,
    }));
  }
  if (fieldCategory === 'custom-field') {
    const customField = profileCustomFields.find((cf) => cf.id === fieldId) as
      | CustomFieldDefinitionEnum
      | undefined;
    return _.map(customField?.options || [], (option) => ({
      text: getTranslatedText(option.title),
      value: option.id,
    }));
  }
  if (fieldCategory === 'linked-mission-custom-field') {
    const customField = missionCustomFields.find((cf) => cf.id === fieldId) as
      | CustomFieldDefinitionEnum
      | undefined;
    return _.map(customField?.options || [], (option) => ({
      text: getTranslatedText(option.title),
      value: option.id,
    }));
  }
  return [];
};

export const getConditionEmptyResult = (): ConditionResult => {
  return {
    id: getRandomString(12),
    name: '',
    type: 'text-with-snippets',
    text: '',
    snippets: [],
  };
};

const getDefaultConditionState = (): ConditionsChainingVariable => {
  return {
    type: 'conditions-chaining',
    ifStatements: [
      {
        id: getRandomString(12),
        condition: {
          id: getRandomString(12),
          type: 'field-condition',
          fieldCategory: 'native',
          fieldId: 'firstname',
          fieldType: 'string',
          accept: {
            id: getRandomString(12),
            type: 'is-known',
          },
        },
        result: getConditionEmptyResult(),
      },
    ],
    elseStatement: getConditionEmptyResult(),
  };
};

export const getInitialConditionsChainingState = ({
  variable,
  profileCustomFields,
  missionCustomFields,
  t,
}: {
  variable?: SweetEvaluatorTypes.BaseVariable & ConditionsChainingVariable;
  profileCustomFields?: CustomFieldDefinition[];
  missionCustomFields?: CustomFieldDefinition[];
  t: TFunction;
}): ConditionsChainingVariable => {
  // if no variable is passed, return default state
  if (!variable) {
    return getDefaultConditionState();
  }

  // if variable contains statements, just return statements
  if (variable?.ifStatements?.length) {
    return variable;
  }

  // if the statements are empty

  const isSimplifiedVariable = variable.subtype === 'switch-on-field';

  // if its not a simplified variable, return default state
  if (!isSimplifiedVariable) {
    return getDefaultConditionState();
  }

  // if its a simplified variable, calculate corresponding statements
  const switchFieldId = variable.switchField?.fieldId || '';

  const isProfileCustomFieldCondition = variable.id.startsWith(
    'profile-custom-field/',
  );
  const isMissionCustomFieldCondition = variable.id.startsWith(
    'mission-custom-field/',
  );

  const isNativeStringCondition = _.includes(
    CONDITION_STRING_FIELDS,
    switchFieldId,
  );
  if (isNativeStringCondition) {
    return {
      ...variable,
      type: 'conditions-chaining',
      subtype: 'switch-on-field',
      switchField: {
        fieldId: switchFieldId,
      },
      ifStatements: [
        {
          id: getRandomString(12),
          condition: {
            id: getRandomString(12),
            type: 'field-condition',
            fieldCategory: 'native',
            fieldId: switchFieldId,
            fieldType: 'string',
            accept: {
              id: getRandomString(12),
              type: 'is-known',
            },
          },
          result: getConditionEmptyResult(),
        },
        {
          id: getRandomString(12),
          condition: {
            id: getRandomString(12),
            type: 'field-condition',
            fieldCategory: 'native',
            fieldId: switchFieldId,
            fieldType: 'string',
            accept: {
              id: getRandomString(12),
              type: 'is-unknown',
            },
          },
          result: getConditionEmptyResult(),
        },
      ],
      elseStatement: getConditionEmptyResult(),
    };
  }

  const isNativeSelectCondition = _.includes(
    CONDITION_SELECT_FIELDS,
    switchFieldId,
  );
  if (isNativeSelectCondition) {
    const selectOptions = getSelectFieldOptions({
      fieldCategory: 'native',
      fieldId: switchFieldId,
      profileCustomFields: profileCustomFields || [],
      missionCustomFields: missionCustomFields || [],
      t,
    });

    const ifStatements = _.map(selectOptions, (option) => {
      return {
        id: getRandomString(12),
        condition: {
          id: getRandomString(12),
          type: 'field-condition',
          fieldCategory: 'native',
          fieldId: switchFieldId,
          fieldType: 'select',
          accept: {
            id: getRandomString(12),
            type: 'string-equals',
            targetText: option.value,
          },
        },
        result: getConditionEmptyResult(),
      };
    });

    return {
      ...variable,
      type: 'conditions-chaining',
      subtype: 'switch-on-field',
      switchField: {
        fieldId: switchFieldId,
      },
      ifStatements: ifStatements as IfStatement[],
      elseStatement: getConditionEmptyResult(),
    };
  }

  if (isProfileCustomFieldCondition || isMissionCustomFieldCondition) {
    const customField = _.findWhere(
      isProfileCustomFieldCondition
        ? profileCustomFields || []
        : missionCustomFields || [],
      {
        id: switchFieldId,
      },
    );
    const customFieldCategory = isProfileCustomFieldCondition
      ? 'custom-field'
      : 'linked-mission-custom-field';
    if (customField?.type === 'text' || customField?.type === 'inline-text') {
      return {
        ...variable,
        type: 'conditions-chaining',
        subtype: 'switch-on-field',
        switchField: {
          fieldId: switchFieldId,
        },
        ifStatements: [
          {
            id: getRandomString(12),
            condition: {
              id: getRandomString(12),
              type: 'field-condition',
              fieldCategory: customFieldCategory,
              customFieldId: switchFieldId,
              fieldType: 'string',
              accept: {
                id: getRandomString(12),
                type: 'is-known',
              },
            },
            result: getConditionEmptyResult(),
          },
          {
            id: getRandomString(12),
            condition: {
              id: getRandomString(12),
              type: 'field-condition',
              fieldCategory: customFieldCategory,
              customFieldId: switchFieldId,
              fieldType: 'string',
              accept: {
                id: getRandomString(12),
                type: 'is-unknown',
              },
            },
            result: getConditionEmptyResult(),
          },
        ],
        elseStatement: getConditionEmptyResult(),
      };
    }
    if (customField?.type === 'enum' && !customField.isMultiselect) {
      const selectOptions = getSelectFieldOptions({
        fieldCategory: customFieldCategory,
        fieldId: switchFieldId,
        profileCustomFields: profileCustomFields || [],
        missionCustomFields: missionCustomFields || [],
        t,
      });
      const ifStatements = _.map(selectOptions, (option) => {
        return {
          id: getRandomString(12),
          condition: {
            id: getRandomString(12),
            type: 'field-condition',
            fieldCategory: customFieldCategory,
            customFieldId: switchFieldId,
            fieldType: 'select',
            accept: {
              id: getRandomString(12),
              type: 'string-equals',
              targetText: option.value,
            },
          },
          result: getConditionEmptyResult(),
        };
      });

      return {
        ...variable,
        type: 'conditions-chaining',
        subtype: 'switch-on-field',
        switchField: {
          fieldId: switchFieldId,
        },
        ifStatements: ifStatements as IfStatement[],
        elseStatement: getConditionEmptyResult(),
      };
    }
  }

  // edge case
  return getDefaultConditionState();
};

export const getUpdatedPreviewContext = ({
  context,
  field,
  key,
  profileCustomFieldsMap,
  missionCustomFieldsMap,
  newValue,
}: {
  context: Record<string, any>;
  field: SimplifiedField;
  key: string;
  newValue: string;
  profileCustomFieldsMap: Record<string, any>;
  missionCustomFieldsMap: Record<string, any>;
}) => {
  if (field.fieldCategory === 'custom-field') {
    const customField = profileCustomFieldsMap[key];
    return {
      ...context,
      customFields: {
        ...context.customFields,
        [key]: {
          type: customField.type,
          value: newValue,
        },
      },
    };
  }
  if (field.fieldCategory === 'linked-mission-custom-field') {
    const customField = missionCustomFieldsMap[key];
    return {
      ...context,
      missionCustomFields: {
        ...context.missionCustomFields,
        [key]: {
          type: customField.type,
          value: newValue,
        },
      },
    };
  }
  return {
    ...context,
    [key]: newValue,
  };
};

export type StatementInputMode = 'basic-input' | 'rich-editor';

export const getStatementTextMode = (text: string): StatementInputMode =>
  isRichText(text) ? 'rich-editor' : 'basic-input';

const htmlTagRegex = /(<([^>]+)>)/gi;
const curlyBracesRegex = /{{|}}/g;

function isRichText(text: string): boolean {
  if (!text.length) {
    return false;
  }
  if (htmlTagRegex.test(text) || curlyBracesRegex.test(text)) {
    return true;
  }
  return false;
}
