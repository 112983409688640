/* eslint-disable max-len */
import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './svg.module.less';

interface ZapProps {
  className?: string;
}

const Zap: FC<ZapProps> = ({ className }) => (
  <svg
    className={classNames(styles.svgIcon, className)}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.67735 1.2615L2.26446 7.31396C1.66889 7.9799 2.14452 9.03262 3.04097 9.03262H5.92212C6.49591 9.03262 6.96106 9.49494 6.96106 10.0652V14.224C6.96106 14.9361 7.84664 15.2708 8.32265 14.7385L13.7355 8.68604C14.3311 8.0201 13.8555 6.96738 12.959 6.96738H10.0779C9.50409 6.96738 9.03894 6.50506 9.03894 5.93476V1.77602C9.03894 1.06389 8.15336 0.729239 7.67735 1.2615Z'
      fill='currentColor'
    />
  </svg>
);

export default Zap;
