import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ATStype, BOONDMANAGER } from '@/common/reveal';

interface BoondManagerApplicationsProps {
  atsId: ATStype;
  application?: {
    status?: {
      stage?: {
        sourceStageName?: string;
      };
    };
  };
}

const BoondManagerApplications: FC<BoondManagerApplicationsProps> = ({
  atsId,
  application,
}) => {
  const { t } = useTranslation();
  if (!application || atsId !== BOONDMANAGER) {
    return null;
  }

  return (
    <div className='row'>
      <div className='field-name'>
        <i className='ri-briefcase-4-line ri-lg' />
        <div className='tag-content-label'>
          {t('profile.resume.ATSData.currentStage')}
        </div>
      </div>
      <div className='jobs-column'>
        <div className='tag' style={{ marginBottom: '0px', marginTop: '3px' }}>
          <div className='tag-content'>
            <div className='tag-content-text'>
              {application?.status?.stage?.sourceStageName}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoondManagerApplications;
