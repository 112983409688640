import _ from 'underscore';

type DeepOmit<Dirty, Keys extends string> = Dirty extends (infer U)[]
  ? DeepOmit<U, Keys>[]
  : Dirty extends Record<string, unknown>
    ? { [K in Exclude<keyof Dirty, Keys>]: DeepOmit<Dirty[K], Keys> }
    : Dirty;

export const deepOmit =
  <K extends string>(keys: K[]) =>
  <T>(obj: T): DeepOmit<T, K> => {
    if (!obj) {
      return obj as DeepOmit<T, K>;
    }

    if (Array.isArray(obj)) {
      return _.map(obj, (item) => deepOmit(keys)(item)) as DeepOmit<T, K>;
    }

    if (typeof obj === 'object') {
      const newObject = _.omit(obj, keys);
      const sanitizedObject = _.mapObject(newObject, deepOmit(keys));
      return sanitizedObject as DeepOmit<T, K>;
    }

    return obj as DeepOmit<T, K>;
  };

export const literalTypeChecker =
  <Type>(typeArray: readonly Type[]) =>
  (element: unknown): element is Type =>
    _.contains(typeArray, element);

export const typesafeMapObject = <
  Key extends string | number | symbol,
  Input,
  Output,
>(
  record: Record<Key, Input>,
  transform: (value: Input, key: Key, rec: Record<Key, Input>) => Output,
): Record<Key, Output> => {
  const result = {} as Record<Key, Output>;

  _.each(_.keys(record) as Key[], (key) => {
    result[key] = transform(record[key], key, record);
  });

  return result;
};

export const typesafeMap = <RecordType extends Record<never, unknown>, Output>(
  record: RecordType,
  transform: (
    value: RecordType[keyof RecordType],
    key: keyof RecordType,
    rec: RecordType,
  ) => Output,
): Output[] => {
  const result = [] as Output[];

  _.each(_.keys(record) as (keyof RecordType)[], (key) => {
    result.push(transform(record[key], key, record));
  });

  return result;
};

export type Extends<A, B> = A extends B ? unknown : never;
export type Equals<A, B> = Extends<A, B> & Extends<B, A>;

export type Primitive =
  | null
  | undefined
  | string
  | number
  | boolean
  | symbol
  | bigint;
