import React, { FC, useMemo } from 'react';
import _ from 'underscore';

import useOfferStepProfiles from '@/graphql/hooks/offers/useOfferStepProfiles';
import Avatar from '@/components/Common/Avatar/Avatar';
import { getFirstProfilesAndRemainder } from '../helpers';

import styles from './PendingIllustration.module.less';

interface PendingIllustrationProps {
  offerId: string;
  subStep?: string;
}

const PendingIllustration: FC<PendingIllustrationProps> = ({
  offerId,
  subStep,
}) => {
  const { stepProfiles } = useOfferStepProfiles({
    offerId,
    step: 'pending',
  });

  const subStepProfiles = useMemo(
    () =>
      _.filter(
        stepProfiles,
        (profile) =>
          subStep === undefined || profile.resumeData.subStep === subStep,
      ),
    [stepProfiles, subStep],
  );

  const { firstProfiles, remainder } = useMemo(
    () => getFirstProfilesAndRemainder(subStepProfiles, 3),
    [subStepProfiles],
  );

  return (
    <div className={styles.illustration}>
      <div className={styles.avatars}>
        {_.map(firstProfiles, ({ id, resumeData }) => (
          <Avatar key={id} className={styles.avatar} person={resumeData} />
        ))}
        {remainder !== 0 && <span className={styles.avatar}>+{remainder}</span>}
      </div>
    </div>
  );
};

export default PendingIllustration;
