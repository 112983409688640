import gql from 'graphql-tag';

import Author from './Author';

const ApplicationFormField = gql`
  fragment ApplicationFormField on ApplicationFormField {
    id
    type
    title
    value {
      text
    }
  }
`;

export const ApplicationForm = gql`
  fragment ApplicationForm on ApplicationForm {
    fields {
      ...ApplicationFormField
    }
  }
  ${ApplicationFormField}
`;


export const ApplicationFormTemplate = gql`
  fragment ApplicationFormTemplate on ApplicationFormTemplate {
    id
    title
    author {
      ...Author
    }
    form {
      ...ApplicationForm
    }
  }
  ${ApplicationForm}
  ${Author}
`;
