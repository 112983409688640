import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import _ from 'underscore';
import { withTranslation } from 'react-i18next';
import TranslatableText from '../../../../components/TranslatableText';
import { getTags } from './SmartTags.helpers';

import './SmartTags.css';

const colorStyles = {
  neutral: { backgroundColor: '#E6F5F9', color: '#2EA4CC' },
  validated: { backgroundColor: '#E6F9EE', color: '#2ECC71' },
  warning: { backgroundColor: '#FBEAD0', color: '#F2994A' },
  bonus: { backgroundColor: '#E6F5F9', color: '#2EA4CC' },
};

const iconNames = {
  neutral: 'star-ticket',
  validated: 'green-check',
  warning: 'eye',
  bonus: 'star-ticket',
};

const iconStyle = {
  display: 'inline-block',
  lineHeight: '16px',
  marginRight: 10,
  width: 16,
  height: 16,
  flexShrink: 0,
  borderRadius: '50%',
};

export const CustomIcon = ({ style, children }) => (
  <span style={{ ...iconStyle, ...style }}>
    <center>{children}</center>
  </span>
);

const LineTag = ({
  neutralMode,
  label,
  content,
  popupContent,
  type,
  icon,
  t,
}) => {
  const ref = useRef(null);
  const [hasEllipsis, setHasEllipsis] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(false);
    setHasEllipsis(false);
  }, [content, label]);

  useEffect(() => {
    // detect ellipsis
    if (elementHasOverflow(ref?.current)) {
      setHasEllipsis(true);
    }
    // content is important for up-to-date ellipsis state

    // eslint-disable-next-line
  }, [content, hasEllipsis, expanded, ref?.current]);

  if (!label) {
    return null;
  }

  return (
    <div
      className={classNames(
        'smart-tag-line',
        expanded ? 'expanded' : 'reduced',
      )}
    >
      <div className='smart-tag-line-prefix'>
        <i className={`${icon}`} />
      </div>
      <TranslatableText text={label} />
      <span>&nbsp;·&nbsp;</span>

      <span
        className={classNames(
          'smart-tag-line-value',
          hasEllipsis && 'ellipsis',
        )}
        ref={ref}
        onClick={hasEllipsis ? () => setExpanded(!expanded) : null}
      >
        <TranslatableText text={content} />
      </span>
      {popupContent?.default && (
        <Popup
          inverted
          size='small'
          basic
          position='bottom center'
          hoverable
          trigger={
            <Icon
              className='question-mark-icon'
              name='question circle outline'
              size='small'
              style={{ marginTop: '5px' }}
            />
          }
        >
          <pre className='smart-tag-tooltip-content'>
            <TranslatableText text={popupContent} />
          </pre>
        </Popup>
      )}

      {!neutralMode && (
        <Popup
          trigger={
            <span className='smart-tag-line-suffix' style={colorStyles[type]}>
              <img
                src={`/images/icons/${iconNames[type]}.svg`}
                alt={`${type}tag`}
              />
            </span>
          }
          content={t(`profile.resume.smartTags.pill.${type}`, '')}
          position='right center'
        />
      )}
    </div>
  );
};

const elementHasOverflow = (element) => {
  return element?.offsetWidth < element?.scrollWidth;
};

const SmartTags = ({ smartTags, neutralMode, t }) => {
  const tags = getTags({ smartTags, neutralMode });

  return (
    <div className='smart-tags-container'>
      <LineTag neutralMode={neutralMode} t={t} {...tags.jobPosition} />
      <LineTag neutralMode={neutralMode} t={t} {...tags.experience} />
      <LineTag neutralMode={neutralMode} t={t} {...tags.management} />
      <LineTag neutralMode={neutralMode} t={t} {...tags.location} />
      <LineTag neutralMode={neutralMode} t={t} {...tags.salary} />
      <LineTag neutralMode={neutralMode} t={t} {...tags.looking} />
      <LineTag neutralMode={neutralMode} t={t} {...tags.availability} />
      <LineTag neutralMode={neutralMode} t={t} {...tags.contract} />
      <LineTag neutralMode={neutralMode} t={t} {...tags.language} />
      {_.map(tags.unCategorized, (tag, i) => (
        <LineTag neutralMode={neutralMode} t={t} key={i} {...tag} />
      ))}
    </div>
  );
};

export default withTranslation('translations')(SmartTags);
