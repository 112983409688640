import React, { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import ColoredDot from '@/common/ColoredDot';
import {
  LinkedAccountSynchronizations,
  LinkedinAccountState,
} from '@/types/LinkedInAccount';
import { Author } from '@/types/author';
import { User } from '@/types/user';
import styles from './LinkedinAccountStateTooltip.module.less';
import { getFullname } from '../../../../common/helpers/person';
import GenericTooltip from '../../../../components/Common/GenericTooltip/GenericTooltip';

interface LinkedinAccountStateTooltipProps {
  key?: string | number;
  className?: string;
  state: LinkedinAccountState;
  owner: Author;
  user: User;
  synchronizations: LinkedAccountSynchronizations;
  onShowConnectModal: Dispatch<SetStateAction<boolean>>;
  setAddressToConnect: () => void;
}

const LinkedinAccountStateTooltip: React.FC<LinkedinAccountStateTooltipProps> = ({
  key,
  className,
  state,
  owner,
  user,
  synchronizations,
  onShowConnectModal,
  setAddressToConnect,
}) => {
  if (!state) {
    return null;
  }

  if (
    !synchronizations?.inMailReceived?.enabled &&
    !synchronizations?.invitationAccepted?.enabled
  ) {
    return <LinkedinAccountNoSyncTooltip />;
  }

  switch (state.type) {
    case 'unknown':
      return (
        <LinkedinAccountRegularTooltip
          key={key}
          className={className}
          state={state}
        />
      );
    case 'validated':
      return (
        <LinkedinAccountRegularTooltip
          key={key}
          className={className}
          state={state}
        />
      );
    case 'error':
      switch (state.errorType) {
        case 'not-working':
          return (
            <LinkedinAccountErrorTooltip
              key={key}
              className={className}
              owner={owner}
              user={user}
              errorType={state.errorType}
              onShowConnectModal={() => {
                setAddressToConnect();
                onShowConnectModal(true);
              }}
            />
          );
        case 'not-connected':
          return (
            <LinkedinAccountErrorTooltip
              key={key}
              className={className}
              owner={owner}
              user={user}
              errorType={state.errorType}
              onShowConnectModal={() => {
                setAddressToConnect();
                onShowConnectModal(true);
              }}
            />
          );
        default:
          return (
            <LinkedinAccountRegularTooltip
              key={key}
              className={className}
              state={state}
            />
          );
      }
    default:
      return (
        <LinkedinAccountRegularTooltip
          key={key}
          className={className}
          state={state}
        />
      );
  }
};

interface LinkedinAccountRegularTooltipProps {
  key?: string | number;
  className?: string;
  state: LinkedinAccountState;
}

const LinkedinAccountRegularTooltip: React.FC<LinkedinAccountRegularTooltipProps> = ({
  key,
  state,
  className,
}) => {
  const { t } = useTranslation();

  if (!state) {
    return null;
  }

  return (
    <GenericTooltip
      key={key}
      content={t(`settings.linkedinAccounts.tooltips.state.${state.type}`)}
      inverted
      hoverable
      trigger={
        <div className={classNames(styles.dotContainer, className)}>
          <ColoredDot color={COLOR_FROM_TYPE[state?.type]} />
        </div>
      }
    />
  );
};

interface LinkedinAccountNoSyncTooltipProps {
  key?: string | number;
  className?: string;
}

const LinkedinAccountNoSyncTooltip: React.FC<LinkedinAccountNoSyncTooltipProps> = ({
  key,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <GenericTooltip
      key={key}
      content={t(`settings.linkedinAccounts.tooltips.state.not-sync`)}
      inverted
      hoverable
      trigger={
        <div className={classNames(styles.dotContainer, className)}>
          <ColoredDot color='grey' />
        </div>
      }
    />
  );
};

interface LinkedinAccountStateTooltipWithActionProps {
  key?: string | number;
  className?: string;
  owner: Author;
  user: User;
  errorType: string;
  onShowConnectModal: Dispatch<SetStateAction<boolean>>;
}

const COLOR_FROM_ERROR_TYPE: Record<string, string> = {
  'not-connected': 'yellow',
  'not-working': 'red',
};

const COLOR_FROM_TYPE: Record<string, string> = {
  validated: 'green',
  unknown: 'grey',
};

const LinkedinAccountErrorTooltip: React.FC<LinkedinAccountStateTooltipWithActionProps> = ({
  key,
  className,
  owner,
  user,
  errorType,
  onShowConnectModal,
}) => {
  const { t } = useTranslation();
  const isOwner = _.isEqual(getFullname(owner), getFullname(user));

  const onClickCta = () => {
    onShowConnectModal(true);
  };

  const color = COLOR_FROM_ERROR_TYPE[errorType];

  return (
    <GenericTooltip
      key={key}
      className={styles.popupContent}
      hoverable
      inverted
      content={
        <>
          {t(`settings.linkedinAccounts.tooltips.state.${errorType}`)}
          &nbsp;
          {isOwner ? (
            <span
              className={styles.link}
              onClick={() => onClickCta()}
              onKeyPress={() => onClickCta()}
              role='button'
            >
              {`${t(
                `settings.linkedinAccounts.tooltips.state.${errorType}-cta`,
              )}`}
            </span>
          ) : (
            ''
          )}
        </>
      }
      trigger={
        <div className={classNames(styles.dotContainer, className)}>
          <ColoredDot color={color} />
        </div>
      }
    />
  );
};

export default LinkedinAccountStateTooltip;
