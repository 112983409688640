import { useEffect, useRef, useState } from 'react';

const useHasOverflow = () => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  const getHeight = (ref) => {
    return ref?.current?.clientHeight || 0;
  };

  const [containerHeight, setContainerHeight] = useState(getHeight(containerRef));
  const [contentHeight, setContentHeight] = useState(getHeight(contentRef));

  useEffect(() => {
    setContainerHeight(getHeight(containerRef));
    setContentHeight(getHeight(contentRef));
  }, []);

  return {
    containerRef,
    contentRef,
    containerHasOverflow: contentHeight > containerHeight,
  };
};

export default useHasOverflow;
