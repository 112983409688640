import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'semantic-ui-react';
import { ATStype } from '@/common/reveal';
import { PrimaryCobalt, SecondarySkyBlue10 } from '@/less/colors';
import './Instructions.css';

interface IntegrationInstructionsProps {
  type: ATStype;
}

const IntegrationInstructions: React.FC<IntegrationInstructionsProps> = ({
  type,
}) => {
  const { i18n } = useTranslation();
  const styles = {
    integrationHelper: {
      backgroundColor: SecondarySkyBlue10,
      padding: '16px',
      borderRadius: '8px',
      justifyContent: 'space-between',
      color: PrimaryCobalt,
    },
  };
  return (
    <>
      {type === 'lever' && (
        <LeverInstructions lang={i18n.resolvedLanguage} styles={styles} />
      )}
      {type === 'greenhouse' && (
        <GreenhouseInstructions lang={i18n.resolvedLanguage} styles={styles} />
      )}
      {type === 'smartrecruiters' && (
        <SmartrecruitersInstructions
          lang={i18n.resolvedLanguage}
          styles={styles}
        />
      )}
      {type === 'teamtailor' && (
        <TeamtailorInstructions lang={i18n.resolvedLanguage} styles={styles} />
      )}
      {type === 'recruitee' && (
        <RecruiteeInstructions lang={i18n.resolvedLanguage} styles={styles} />
      )}
      {type === 'ashby' && (
        <AshbyInstructions lang={i18n.resolvedLanguage} styles={styles} />
      )}
    </>
  );
};

interface InstructionsProps {
  lang: string;
  styles: any;
}

const LeverInstructions: React.FC<InstructionsProps> = ({ lang, styles }) => {
  return (
    <div className='instructions'>
      {lang === 'fr' ? (
        <>
          <div style={styles.integrationHelper}>
            <p>
              Pour connecter votre Lever à HireSweet CRM, vous avez besoin d’un
              accès administrateur. Si ça n’est pas le cas, vous pouvez demander
              à un de vos collaborateurs ou votre supérieur.
            </p>
          </div>
          <h3>
            <span>1</span> Aller dans les <b>Settings</b> de Lever
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/lever-instructions-1.png'
              fluid
            />
          </div>
          <p>
            Vous pouvez cliquer directement sur{' '}
            <a
              href='https://hire.lever.co/settings/integrations?tab=api'
              target='_blank'
              rel='noopener noreferrer'
            >
              ce lien
            </a>{' '}
            pour arriver au bon endroit.
          </p>
          <br />
          <h3>
            <span>2</span> Cliquer sur <b>Generate New Key</b>
          </h3>
          <div className='image-container'>
            <Image
              src='/images/screenshots/integrations-instructions/lever-instructions-2.png'
              fluid
            />
          </div>
          <p>
            <b>NB :</b> Ne pas confondre avec la première partie qui sert
            uniquement à poster des offres (Posting API credentials).
          </p>
          <br />
          <h3>
            <span>3</span> Paramétrer la clé
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/lever-instructions-3.png'
              fluid
            />
          </div>
          <p>Remplir les informations suivantes :</p>
          <ol>
            <li>
              <b>Key name :</b> HireSweet
            </li>
            <li>
              <b>Logo (optionnel) :</b> vous pouvez récupérer notre logo{' '}
              <a
                href='https://www.google.com/search?q=hiresweet+logo&rlz=1C5CHFA_enFR747FR747&tbm=isch&source=iu&ictx=1&vet=1&fir=fPag6O7ZJJcXUM%252CR9KNCIs-kMXQIM%252C_%253B8X5zoUawsxJ2fM%252CDJMexqrdagHTiM%252C_%253BJnOlMf019jCJRM%252Ccur_DorHpKaihM%252C_%253BRwVGX3J4HoRspM%252C1Pi_eyKa2_iT4M%252C_%253BcNLGfRYU9tpXYM%252CoLB1pJITh8XBwM%252C_%253BhyW1dfag_tg7KM%252CZaUkvImGQumLdM%252C_%253BUFOvuRnA9ekSwM%252CND9WPo_5FY75dM%252C_%253BCqNAY_bH-hdHEM%252CoJf1OCrSvIvC9M%252C_%253BCZ1ubvsBJAK58M%252CJ1WLmPVKQRcZkM%252C_%253BEU-kjnoXYhWswM%252CexryXHoEWl6oHM%252C_&usg=AI4_-kTqOp0MoG7_l3ECeCw4FghmJPCKJw&sa=X&ved=2ahUKEwjKoe2U09H1AhUMqxoKHdM0A4YQ9QF6BAgUEAE&biw=1920&bih=969&dpr=1#imgrc=JnOlMf019jCJRM'
                target='_blank'
                rel='noopener noreferrer'
              >
                ici
              </a>
            </li>
          </ol>
          <p>
            Puis copier la clé avec le bouton <b>Copy key</b>.
          </p>
          <br />
          <h3>
            <span>4</span> Sélectionner les autorisations
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/lever-instructions-4.png'
              fluid
            />
          </div>
          <p>
            Nous avons besoin de toutes les autorisations pour pouvoir récupérer
            les candidats de votre Lever et proposer des workflows entre
            HireSweet CRM et Lever, vous pouvez donc cliquer sur{' '}
            <b>Select all</b>.
          </p>
          <br />
          <h3>
            <span>5</span> Cliquer sur <b>Done</b>
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/lever-instructions-5.png'
              fluid
            />
          </div>
          <p>
            Vous n’avez pas besoin de nous donner accès à la “Confidential data
            access”.
          </p>
          <p>
            Vous pouvez cliquer sur <b>Done</b>.
          </p>
          <br />
          <h3>
            <span>6</span> Copier-coller la clé sur HireSweet CRM
          </h3>
          <div className='image-container'>
            <Image
              src='/images/screenshots/integrations-instructions/lever-instructions-6.png'
              fluid
            />
          </div>
          <p>
            Après avoir collé la clé, cliquer sur <b>Enregistrer</b>.
          </p>
          <p>
            HireSweet CRM est désormais connecté à votre Lever, félicitations !
          </p>
          <br />
          <p>
            Si cela ne fonctionne pas, vous pouvez vous rapprocher de votre
            Account Manager via le chat ou par e-mail.
          </p>
        </>
      ) : (
        <>
          <div style={styles.integrationHelper}>
            <p>
              To connect Lever to HireSweet CRM, you need an admin access. If
              this is not the case, you can ask one of your collaborators or
              managers.
            </p>
          </div>
          <h3>
            <span>1</span> Go to <b>Settings</b> in Lever
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/lever-instructions-1.png'
              fluid
            />
          </div>
          <p>
            To access this page: click{' '}
            <a
              href='https://hire.lever.co/settings/integrations?tab=api'
              target='_blank'
              rel='noopener noreferrer'
            >
              here
            </a>
            .
          </p>
          <br />
          <h3>
            <span>2</span> Click <b>Generate New Key</b>
          </h3>
          <div className='image-container'>
            <Image
              src='/images/screenshots/integrations-instructions/lever-instructions-2.png'
              fluid
            />
          </div>
          <p>
            <b>NB:</b> Please note the credentials will be different from your
            &quot;Posting API credentials&quot;.
          </p>
          <br />
          <h3>
            <span>3</span> Set up the key
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/lever-instructions-3.png'
              fluid
            />
          </div>
          <p>Fill in the following fields:</p>
          <ol>
            <li>
              <b>Key name:</b> HireSweet
            </li>
            <li>
              <b>Logo (optional):</b> you can get our logo{' '}
              <a
                href='https://www.google.com/search?q=hiresweet+logo&rlz=1C5CHFA_enFR747FR747&tbm=isch&source=iu&ictx=1&vet=1&fir=fPag6O7ZJJcXUM%252CR9KNCIs-kMXQIM%252C_%253B8X5zoUawsxJ2fM%252CDJMexqrdagHTiM%252C_%253BJnOlMf019jCJRM%252Ccur_DorHpKaihM%252C_%253BRwVGX3J4HoRspM%252C1Pi_eyKa2_iT4M%252C_%253BcNLGfRYU9tpXYM%252CoLB1pJITh8XBwM%252C_%253BhyW1dfag_tg7KM%252CZaUkvImGQumLdM%252C_%253BUFOvuRnA9ekSwM%252CND9WPo_5FY75dM%252C_%253BCqNAY_bH-hdHEM%252CoJf1OCrSvIvC9M%252C_%253BCZ1ubvsBJAK58M%252CJ1WLmPVKQRcZkM%252C_%253BEU-kjnoXYhWswM%252CexryXHoEWl6oHM%252C_&usg=AI4_-kTqOp0MoG7_l3ECeCw4FghmJPCKJw&sa=X&ved=2ahUKEwjKoe2U09H1AhUMqxoKHdM0A4YQ9QF6BAgUEAE&biw=1920&bih=969&dpr=1#imgrc=JnOlMf019jCJRM'
                target='_blank'
                rel='noopener noreferrer'
              >
                here
              </a>
            </li>
          </ol>
          <p>
            Copy the key using the <b>Copy key</b> button.
          </p>
          <br />
          <h3>
            <span>4</span> Select permissions
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/lever-instructions-4.png'
              fluid
            />
          </div>
          <p>
            Click on <b>Select all</b> to preselect all permissions: these
            permissions will be used to synchronize your candidates and
            configure workflows between Lever and HireSweet CRM, you can uncheck
            those that do not correspond to your use.
          </p>
          <br />
          <h3>
            <span>5</span> Click <b>Done</b>
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/lever-instructions-5.png'
              fluid
            />
          </div>
          <p>
            You won&apos;t need to give us access to “Confidential data access”.
          </p>
          <p>
            Click <b>Done</b>.
          </p>
          <br />
          <h3>
            <span>6</span> Copy-paste the key on HireSweet CRM
          </h3>
          <div className='image-container'>
            <Image
              src='/images/screenshots/integrations-instructions/lever-instructions-6-en.png'
              fluid
            />
          </div>
          <p>
            Click <b>Save</b>.
          </p>
          <p>Congratulations, HireSweet CRM is now connected to your Lever!</p>
          <br />
          <p>
            If you experience any issues connecting Lever to HireSweet CRM,
            please contact your Account Manager. You can reach them via email or
            via chat.
          </p>
        </>
      )}
    </div>
  );
};

const GreenhouseInstructions: React.FC<InstructionsProps> = ({
  lang,
  styles,
}) => {
  return (
    <div className='instructions'>
      {lang === 'fr' ? (
        <>
          <div style={styles.integrationHelper}>
            <p>
              Pour connecter votre Greenhouse à HireSweet CRM, vous avez besoin
              d’un accès administrateur. Si ça n’est pas le cas, vous pouvez
              demander à un de vos collaborateurs ou votre supérieur.
            </p>
          </div>
          <h3>
            <span>1</span> Aller dans la partie <b>API Credential Management</b>{' '}
            de Greenhouse
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/greenhouse-instructions-1.png'
              fluid
            />
          </div>
          <p>
            Vous pouvez cliquer directement sur ce{' '}
            <a
              href='https://app3.greenhouse.io/configure/dev_center/credentials'
              target='_blank'
              rel='noopener noreferrer'
            >
              lien
            </a>{' '}
            pour arriver au bon endroit.
          </p>
          <p>
            Sinon, vous devez tout d’abord cliquer sur la partie{' '}
            <b>Configuration</b> puis aller dans le sous-menu <b>Dev Center</b>{' '}
            et enfin cliquer sur <b>API Credential Management</b>.
          </p>
          <br />
          <h3>
            <span>2</span> Cliquer sur <b>Create New API Key</b>
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/greenhouse-instructions-2.png'
              fluid
            />
          </div>
          <h3>
            <span>3</span> Paramétrer la clé
          </h3>
          <div className='image-container'>
            <Image
              src='/images/screenshots/integrations-instructions/greenhouse-instructions-3.png'
              fluid
            />
          </div>
          <p>Remplissez les informations suivantes :</p>
          <ol>
            <li>
              <b>API Type :</b> Harvest
            </li>
            <li>
              <b>Partner :</b> HireSweet
            </li>
            <li>
              <b>Description :</b> Intégration HireSweet
            </li>
          </ol>
          <p>
            Puis cliquez sur le bouton <b>Manage Permissions</b>.
          </p>
          <br />
          <h3>
            <span>4</span> Copier la clé d’API
          </h3>
          <div className='image-container'>
            <Image
              src='/images/screenshots/integrations-instructions/greenhouse-instructions-4.png'
              fluid
            />
          </div>
          <p>
            Cliquez sur le bouton <b>Copy</b> pour avoir la clé dans votre
            presse-papier. Vous pouvez la coller dans un bloc-notes ou sur
            HireSweet directement (voir étape 7). Vous pouvez ensuite cliquer
            sur <b>I have stored the API Key</b>.
          </p>
          <br />
          <h3>
            <span>5</span> Configurer les autorisations
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/greenhouse-instructions-5.png'
              fluid
            />
          </div>
          <p>
            Cliquer sur <b>Select All</b> pour pré-sélectionner toutes les
            autorisations : ces autorisations pemettront la synchronisation de
            vos candidats et la configuration d’automatisations entre Greenhouse
            et HireSweet CRM, vous pouvez décocher celles ne correspondant pas à
            vos usages.
          </p>
          <br />
          <h3>
            <span>6</span> Cliquer sur Save pour valider la clé
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/greenhouse-instructions-6.png'
              fluid
            />
          </div>
          <h3>
            <span>7</span> Copier-coller la clé sur HireSweet CRM
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/greenhouse-instructions-7-fr.png'
              fluid
            />
          </div>
          <p>
            Après avoir collé la clé, cliquer sur <b>Enregistrer</b>.
          </p>
          <p>
            HireSweet CRM est désormais connecté à votre Greenhouse,
            félicitations !
          </p>
          <br />
          <p>
            Si cela ne fonctionne pas, vous pouvez vous rapprocher de votre
            Account Manager via le chat ou par e-mail.
          </p>
        </>
      ) : (
        <>
          <div style={styles.integrationHelper}>
            <p>
              To connect Greenhouse to HireSweet CRM, you need an admin access.
              If this is not the case, please ask one of your collaborators or
              managers.
            </p>
          </div>
          <h3>
            <span>1</span> Go to <b>API Credential Management</b> in Greenhouse
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/greenhouse-instructions-1.png'
              fluid
            />
          </div>
          <p>
            To access this page: click{' '}
            <a
              href='https://app3.greenhouse.io/configure/dev_center/credentials'
              target='_blank'
              rel='noopener noreferrer'
            >
              here
            </a>
            .
          </p>
          <p>
            You can also click <b>Configuration</b>, then go to{' '}
            <b>Dev Center</b> and click <b>API Credential Management</b>.
          </p>
          <h3>
            <span>2</span> Click <b>Create New API Key</b>
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/greenhouse-instructions-2.png'
              fluid
            />
          </div>
          <br />
          <h3>
            <span>3</span> Set up the key
          </h3>
          <div className='image-container'>
            <Image
              src='/images/screenshots/integrations-instructions/greenhouse-instructions-3.png'
              fluid
            />
          </div>
          <p>Fill in the following fields:</p>
          <ol>
            <li>
              <b>API Type:</b> Harvest
            </li>
            <li>
              <b>Partner:</b> HireSweet
            </li>
            <li>
              <b>Description:</b> HireSweet Integration
            </li>
          </ol>
          <p>
            Click <b>Manage Permissions</b>.
          </p>
          <br />
          <h3>
            <span>4</span> Copy the API key
          </h3>
          <div className='image-container'>
            <Image
              src='/images/screenshots/integrations-instructions/greenhouse-instructions-4.png'
              fluid
            />
          </div>
          <p>
            Click <b>Copy</b> to get the API key. You can past it in your
            notepad or directly on HireSweet CRM (see step 7). Click{' '}
            <b>I have stored the API key</b>.
          </p>
          <br />
          <h3>
            <span>5</span> Select permissions
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/greenhouse-instructions-5.png'
              fluid
            />
          </div>
          <p>
            Click <b>Select all</b> to preselect all permissions: these
            permissions will be used to synchronize your candidates and
            configure workflows between Greenhouse and HireSweet CRM, you can
            uncheck those that do not correspond to your use.
          </p>
          <br />
          <h3>
            <span>6</span> Click <b>Save</b>
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/greenhouse-instructions-6.png'
              fluid
            />
          </div>
          <h3>
            <span>7</span> Copy-paste the key on HireSweet CRM
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/greenhouse-instructions-7-en.png'
              fluid
            />
          </div>
          <p>
            Then click on <b>Save</b>.
          </p>
          <p>
            Congratulations, HireSweet CRM is now connected to your Greenhouse!
          </p>
          <br />
          <p>
            If you experience any issues connecting Greenhouse to HireSweet CRM,
            please contact your Account Manager. You can reach them via email or
            via chat.
          </p>
        </>
      )}
    </div>
  );
};

const SmartrecruitersInstructions: React.FC<InstructionsProps> = ({
  lang,
  styles,
}) => {
  return (
    <div className='instructions'>
      {lang === 'fr' ? (
        <>
          <div style={styles.integrationHelper}>
            <p>
              Pour connecter votre SmartRecruiters à HireSweet CRM, vous avez
              besoin d’un accès administrateur. Si ça n’est pas le cas, vous
              pouvez demander à un de vos collaborateurs ou votre supérieur.
            </p>
          </div>
          <h3>
            <span>1</span> Aller dans la partie <b>Credentials</b> de
            SmartRecruiters
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/smartrecruiters-instructions-1.png'
              fluid
            />
          </div>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/smartrecruiters-instructions-1.2.png'
              fluid
            />
          </div>
          <p>
            Vous pouvez cliquer directement sur ce{' '}
            <a
              href='https://www.smartrecruiters.com/settings/administration/app-management/custom-applications'
              target='_blank'
              rel='noopener noreferrer'
            >
              lien
            </a>{' '}
            pour arriver au bon endroit.
          </p>
          <p>
            Sinon, vous devez tout d’abord cliquer sur votre photo de profil
            puis aller dans les <b>Settings</b>. Il faut ensuite aller dans le
            sous-menu <b>Administration</b>, cliquer sur{' '}
            <b>Apps & Integrations</b> et enfin choisir le tab{' '}
            <b>Credentials</b>.
          </p>
          <br />
          <h3>
            <span>2</span> Cliquer sur <b>New Credential</b> et sélectionner{' '}
            <b>API key</b>
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/smartrecruiters-instructions-2.png'
              fluid
            />
          </div>
          <p>
            Cliquez ensuite sur <b>Next</b>.
          </p>
          <h3>
            <span>3</span> Paramétrer la clé
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/smartrecruiters-instructions-3.png'
              fluid
            />
          </div>
          <p>Remplissez les informations suivantes :</p>
          <ol>
            <li>
              <b>Credentials name :</b> HireSweet
            </li>
            <li>
              <b>Avatar (optionnel) :</b> vous pouvez récupérer notre logo{' '}
              <a
                href='https://www.google.com/search?q=hiresweet+logo&rlz=1C5CHFA_enFR747FR747&tbm=isch&source=iu&ictx=1&vet=1&fir=fPag6O7ZJJcXUM%252CR9KNCIs-kMXQIM%252C_%253B8X5zoUawsxJ2fM%252CDJMexqrdagHTiM%252C_%253BJnOlMf019jCJRM%252Ccur_DorHpKaihM%252C_%253BRwVGX3J4HoRspM%252C1Pi_eyKa2_iT4M%252C_%253BcNLGfRYU9tpXYM%252CoLB1pJITh8XBwM%252C_%253BhyW1dfag_tg7KM%252CZaUkvImGQumLdM%252C_%253BUFOvuRnA9ekSwM%252CND9WPo_5FY75dM%252C_%253BCqNAY_bH-hdHEM%252CoJf1OCrSvIvC9M%252C_%253BCZ1ubvsBJAK58M%252CJ1WLmPVKQRcZkM%252C_%253BEU-kjnoXYhWswM%252CexryXHoEWl6oHM%252C_&usg=AI4_-kTqOp0MoG7_l3ECeCw4FghmJPCKJw&sa=X&ved=2ahUKEwjKoe2U09H1AhUMqxoKHdM0A4YQ9QF6BAgUEAE&biw=1920&bih=969&dpr=1#imgrc=JnOlMf019jCJRM'
                target='_blank'
                rel='noopener noreferrer'
              >
                ici
              </a>
            </li>
            <li>
              <b>Description (optionnel) :</b> HireSweet CRM integration
            </li>
          </ol>
          <p>
            Puis cliquez sur le bouton <b>Generate</b>.
          </p>
          <br />
          <h3>
            <span>4</span> Copier la clé d’API
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/smartrecruiters-instructions-4.png'
              fluid
            />
          </div>
          <p>
            Cliquez sur le bouton <b>Copy</b> pour avoir la clé dans votre
            presse-papier. Vous pouvez la coller dans un bloc-notes ou sur
            HireSweet directement (voir étape 5).
          </p>
          <br />
          <h3>
            <span>5</span> Copier-coller la clé sur HireSweet CRM
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/smartrecruiters-instructions-5-fr.png'
              fluid
            />
          </div>
          <p>
            Après avoir collé la clé, cliquer sur <b>Enregistrer</b>.
          </p>
          <p>
            HireSweet CRM est désormais connecté à votre SmartRecruiters,
            félicitations !
          </p>
          <br />
          <p>
            Si cela ne fonctionne pas, vous pouvez vous rapprocher de votre
            Account Manager via le chat ou par e-mail.
          </p>
        </>
      ) : (
        <>
          <div style={styles.integrationHelper}>
            <p>
              To connect SmartRecruiters to HireSweet CRM, you need an admin
              access. If this is not the case, please ask one of your
              collaborators or managers.
            </p>
          </div>
          <h3>
            <span>1</span> Go to <b>Credentials</b> in SmartRecruiters
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/smartrecruiters-instructions-1.png'
              fluid
            />
          </div>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/smartrecruiters-instructions-1.2.png'
              fluid
            />
          </div>
          <p>
            To access this page: click{' '}
            <a
              href='https://www.smartrecruiters.com/settings/administration/app-management/custom-applications'
              target='_blank'
              rel='noopener noreferrer'
            >
              here
            </a>
            .
          </p>
          <p>
            You can also click your profile picture and go to <b>Settings</b>.
            Then go to <b>Administration</b>, click <b>App & Integrations</b>{' '}
            and go to <b>Credentials</b>.
          </p>
          <h3>
            <span>2</span> Click <b>New Credential</b> and select <b>API key</b>
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/smartrecruiters-instructions-2.png'
              fluid
            />
          </div>
          <p>
            Click <b>Next</b>.
          </p>
          <br />
          <h3>
            <span>3</span> Set up the key
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/smartrecruiters-instructions-3.png'
              fluid
            />
          </div>
          <p>Fill in the following fields:</p>
          <ol>
            <li>
              <b>Credentials name:</b> HireSweet
            </li>
            <li>
              <b>Avatar (optional):</b> you can get our logo{' '}
              <a
                href='https://www.google.com/search?q=hiresweet+logo&rlz=1C5CHFA_enFR747FR747&tbm=isch&source=iu&ictx=1&vet=1&fir=fPag6O7ZJJcXUM%252CR9KNCIs-kMXQIM%252C_%253B8X5zoUawsxJ2fM%252CDJMexqrdagHTiM%252C_%253BJnOlMf019jCJRM%252Ccur_DorHpKaihM%252C_%253BRwVGX3J4HoRspM%252C1Pi_eyKa2_iT4M%252C_%253BcNLGfRYU9tpXYM%252CoLB1pJITh8XBwM%252C_%253BhyW1dfag_tg7KM%252CZaUkvImGQumLdM%252C_%253BUFOvuRnA9ekSwM%252CND9WPo_5FY75dM%252C_%253BCqNAY_bH-hdHEM%252CoJf1OCrSvIvC9M%252C_%253BCZ1ubvsBJAK58M%252CJ1WLmPVKQRcZkM%252C_%253BEU-kjnoXYhWswM%252CexryXHoEWl6oHM%252C_&usg=AI4_-kTqOp0MoG7_l3ECeCw4FghmJPCKJw&sa=X&ved=2ahUKEwjKoe2U09H1AhUMqxoKHdM0A4YQ9QF6BAgUEAE&biw=1920&bih=969&dpr=1#imgrc=JnOlMf019jCJRM'
                target='_blank'
                rel='noopener noreferrer'
              >
                here
              </a>
            </li>
            <li>
              <b>Description (optional):</b> HireSweet CRM integration
            </li>
          </ol>
          <p>
            Click <b>Generate</b>.
          </p>
          <br />
          <h3>
            <span>4</span> Copy the API key
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/smartrecruiters-instructions-4.png'
              fluid
            />
          </div>
          <p>
            Click the <b>Copy</b> logo to get the API key. You can past it in
            your notepad or directly on HireSweet CRM (see step 5).
          </p>
          <br />
          <h3>
            <span>5</span> Copy-paste the key on HireSweet CRM
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/smartrecruiters-instructions-5-en.png'
              fluid
            />
          </div>
          <p>
            Then click on <b>Save</b>.
          </p>
          <p>
            Congratulations, HireSweet CRM is now connected to your
            SmartRecruiters!
          </p>
          <br />
          <p>
            If you experience any issues connecting SmartRecruiters to HireSweet
            CRM, please contact your Account Manager. You can reach them via
            email or via chat.
          </p>
        </>
      )}
    </div>
  );
};

const TeamtailorInstructions: React.FC<InstructionsProps> = ({
  lang,
  styles,
}) => {
  return (
    <div className='instructions'>
      {lang === 'fr' ? (
        <>
          <div style={styles.integrationHelper}>
            <p>
              Pour connecter votre Teamtailor à HireSweet CRM, vous avez besoin
              d’un accès administrateur. Si ça n’est pas le cas, vous pouvez
              demander à un de vos collaborateurs ou votre supérieur.
            </p>
          </div>
          <h3>
            <span>1</span> Aller dans la partie <b>Clés API</b> de Teamtailor
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/teamtailor-instructions-1-fr.png'
              fluid
            />
          </div>
          <p>
            Vous pouvez cliquer directement sur ce{' '}
            <a
              href='https://app.teamtailor.com/companies/GxqhF0WAD3Y/settings/integrations/api_keys'
              target='_blank'
              rel='noopener noreferrer'
            >
              lien
            </a>{' '}
            pour arriver au bon endroit.
          </p>
          <p>
            Sinon, vous devez tout d’abord aller dans le menu déroulant sous le
            nom de votre compte, puis cliquer sur <b>Paramètres</b>. Vous devez
            ensuite descendre dans le sous-menu <b>Intégrations</b> et enfin
            cliquer sur <b>Clés API</b>.
          </p>
          <br />
          <h3>
            <span>2</span> Cliquer sur <b>Nouvelle clé API</b> et remplir les
            informations suivantes
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/teamtailor-instructions-2-fr.png'
              fluid
            />
          </div>
          <p>Remplissez les informations suivantes :</p>
          <ol>
            <li>
              <b>Nom :</b> HireSweet
            </li>
            <li>
              <b>Portée de l&apos;API :</b> Admin
            </li>
            <li>
              Cocher <b>Lire & Ecrire</b>
            </li>
          </ol>
          <p>
            Puis cliquez sur le bouton <b>Créer</b>.
          </p>
          <br />
          <h3>
            <span>3</span> Copier la clé d’API
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/teamtailor-instructions-3-fr.png'
              fluid
            />
          </div>
          <p>
            Cliquez sur le bouton <b>Copy</b> pour avoir la clé dans votre
            presse-papier. Vous pouvez la coller dans un bloc-notes ou sur
            HireSweet directement (voir étape 4).
          </p>
          <br />
          <h3>
            <span>4</span> Copier-coller la clé sur HireSweet CRM
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/teamtailor-instructions-4-fr.png'
              fluid
            />
          </div>
          <p>
            Après avoir collé la clé, cliquer sur <b>Enregistrer</b>.
          </p>
          <p>
            HireSweet CRM est désormais connecté à votre Teamtailor,
            félicitations !
          </p>
          <br />
          <p>
            Si cela ne fonctionne pas, vous pouvez vous rapprocher de votre
            Account Manager via le chat ou par e-mail.
          </p>
        </>
      ) : (
        <>
          <div style={styles.integrationHelper}>
            <p>
              To connect Teamtailor to HireSweet CRM, you need an admin access.
              If this is not the case, please ask one of your collaborators or
              managers.
            </p>
          </div>
          <h3>
            <span>1</span> Go to <b>API keys</b> in Teamtailor
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/teamtailor-instructions-1-en.png'
              fluid
            />
          </div>
          <p>
            To access this page: click{' '}
            <a
              href='https://app.teamtailor.com/companies/GxqhF0WAD3Y/settings/integrations/api_keys'
              target='_blank'
              rel='noopener noreferrer'
            >
              here
            </a>
            .
          </p>
          <p>
            You can also click <b>Settings</b>, then go to <b>Integrations</b>{' '}
            and click <b>API keys</b>.
          </p>
          <h3>
            <span>2</span> Click <b>New token</b> and fill in the fields
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/teamtailor-instructions-2-en.png'
              fluid
            />
          </div>
          <p>Fill in the following fields:</p>
          <ol>
            <li>
              <b>Name:</b> HireSweet
            </li>
            <li>
              <b>Scope:</b> Admin
            </li>
            <li>
              Check <b>Read & Write</b>
            </li>
          </ol>
          <p>
            Click <b>Create</b>.
          </p>
          <br />
          <h3>
            <span>3</span> Copy the API key
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/teamtailor-instructions-3-en.png'
              fluid
            />
          </div>
          <p>
            Click the <b>Copy</b> logo to get the API key. You can past it in
            your notepad or directly on HireSweet CRM (see step 4).
          </p>
          <br />
          <h3>
            <span>4</span> Copy-paste the key on HireSweet CRM
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/teamtailor-instructions-4-en.png'
              fluid
            />
          </div>
          <p>
            Then click on <b>Save</b>.
          </p>
          <p>
            Congratulations, HireSweet CRM is now connected to your Teamtailor!
          </p>
          <br />
          <p>
            If you experience any issues connecting Teamtailor to HireSweet CRM,
            please contact your Account Manager. You can reach them via email or
            via chat.
          </p>
        </>
      )}
    </div>
  );
};

const RecruiteeInstructions: React.FC<InstructionsProps> = ({
  lang,
  styles,
}) => {
  return (
    <div className='instructions'>
      {lang === 'fr' ? (
        <>
          <div style={styles.integrationHelper}>
            <p>
              Pour connecter votre Recruitee à HireSweet CRM, vous avez besoin
              d’un accès administrateur. Si ça n’est pas le cas, vous pouvez
              demander à un de vos collaborateurs ou votre supérieur.
            </p>
          </div>
          <h3>
            <span>1</span> Aller dans la partie <b>Clés API</b> de Recruitee
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/recruitee-instructions-1-fr.png'
              fluid
            />
          </div>
          <p>
            Vous pouvez cliquer directement sur ce{' '}
            <a
              href='https://app.recruitee.com/#/settings/api_tokens'
              target='_blank'
              rel='noopener noreferrer'
            >
              lien
            </a>{' '}
            pour arriver au bon endroit.
          </p>
          <p>
            Sinon, vous devez tout d’abord cliquer sur la partie{' '}
            <b>Paramètres</b> puis descendre dans le sous-menu{' '}
            <b>Applications et plugins</b> et enfin cliquer sur{' '}
            <b>Clés API personnelles</b>.
          </p>
          <br />
          <h3>
            <span>2</span> Cliquer sur <b>Nouvelle clé</b> et remplir les
            informations
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/recruitee-instructions-2-fr.png'
              fluid
            />
          </div>
          <p>Remplissez les informations suivantes :</p>
          <ol>
            <li>
              <b>Nom de la clé :</b> HireSweet
            </li>
          </ol>
          <p>
            Puis cliquez sur le bouton <b>Sauvegarder</b>.
          </p>
          <br />
          <h3>
            <span>3</span> Vérifier votre identité
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/recruitee-instructions-3-fr.png'
              fluid
            />
          </div>
          <p>
            Remplissez votre mot de passe ou utilisez un code de vérification
            qui arrivera dans votre boîte de réception électronique.
          </p>
          <h3>
            <span>4</span> Copier la clé d’API
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/recruitee-instructions-4-fr.png'
              fluid
            />
          </div>
          <p>
            Cliquez sur le bouton <b>Copy</b> pour avoir la clé dans votre
            presse-papier. Vous pouvez la coller dans un bloc-notes ou sur
            HireSweet directement (voir étape 5).
          </p>
          <br />
          <h3>
            <span>5</span> Copier-coller la clé sur HireSweet CRM
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/recruitee-instructions-5-fr.png'
              fluid
            />
          </div>
          <p>
            Le champs sous-domaine est visible sur la page{' '}
            <b>Clé API personnelles.</b>
          </p>
          <p>
            Après avoir collé la clé, cliquer sur <b>Enregistrer</b>.
          </p>
          <p>
            HireSweet CRM est désormais connecté à votre Recruitee,
            félicitations !
          </p>
          <br />
          <p>
            Si cela ne fonctionne pas, vous pouvez vous rapprocher de votre
            Account Manager via le chat ou par e-mail.
          </p>
        </>
      ) : (
        <>
          <div style={styles.integrationHelper}>
            <p>
              To connect Recruitee to HireSweet CRM, you need an admin access.
              If this is not the case, please ask one of your collaborators or
              managers.
            </p>
          </div>
          <h3>
            <span>1</span> Go to <b>Personal API tokens</b> in Recruitee
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/recruitee-instructions-1-en.png'
              fluid
            />
          </div>
          <p>
            To access this page: click{' '}
            <a
              href='https://app.recruitee.com/#/settings/api_tokens'
              target='_blank'
              rel='noopener noreferrer'
            >
              here
            </a>
            .
          </p>
          <p>
            You can also click <b>Settings</b>, then go to{' '}
            <b>Apps and plugins</b> and click <b>Personal API tokens</b>.
          </p>
          <br />
          <h3>
            <span>2</span> Click <b>New token</b> and select fill in the field
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/recruitee-instructions-2-en.png'
              fluid
            />
          </div>
          <p>Fill in the following fields:</p>
          <ol>
            <li>
              <b>Credentials name:</b> HireSweet
            </li>
          </ol>
          <p>
            Click <b>Save</b>.
          </p>
          <br />
          <h3>
            <span>3</span> Verify your identity
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/recruitee-instructions-3-en.png'
              fluid
            />
          </div>
          <p>Fill in your password or use verification code.</p>
          <h3>
            <span>4</span> Copy the API key
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/recruitee-instructions-4-en.png'
              fluid
            />
          </div>
          <p>
            Click <b>Copy</b> to get the API key. You can past it in your
            notepad or directly on HireSweet CRM (see step 5).
          </p>
          <br />
          <h3>
            <span>5</span> Copy-paste the key on HireSweet CRM
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/recruitee-instructions-5-en.png'
              fluid
            />
          </div>
          <p>You can find the subdomain on your Personal API tokens page.</p>
          <p>
            Paste the API key, click <b>Save</b>.
          </p>
          <p>
            Congratulations, HireSweet CRM is now connected to your Recruitee!
          </p>
          <br />
          <p>
            If you experience any issues connecting Recruitee to HireSweet CRM,
            please contact your Account Manager. You can reach them via email or
            via chat.
          </p>
        </>
      )}
    </div>
  );
};

const AshbyInstructions: React.FC<InstructionsProps> = ({ lang, styles }) => {
  return (
    <div className='instructions'>
      {lang === 'fr' ? (
        <>
          <div style={styles.integrationHelper}>
            <p>
              Pour connecter votre Ashby à HireSweet CRM, vous avez besoin d’un
              accès administrateur. Si ça n’est pas le cas, vous pouvez demander
              à un de vos collaborateurs ou votre supérieur.
            </p>
          </div>
          <h3>
            <span>1</span> Aller dans la partie <b>API Keys</b> de Ashby
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/ashby-instructions-1.png'
              fluid
            />
          </div>
          <p>
            Vous pouvez cliquer directement sur ce{' '}
            <a
              href='https://app.ashbyhq.com/admin/api/keys'
              target='_blank'
              rel='noopener noreferrer'
            >
              lien
            </a>{' '}
            pour arriver au bon endroit.
          </p>
          <p>
            Sinon, vous devez tout d’abord cliquer sur la partie <b>Admin</b>{' '}
            dans le header puis aller dans le sous-menu <b>Ashby API</b>. Il
            faut ensuite aller dans la section <b>API Keys</b>.
          </p>
          <br />
          <h3>
            <span>2</span> Cliquer sur <b>New</b> et paramétrer la clé
          </h3>
          <div className='image-container'>
            <Image
              src='/images/screenshots/integrations-instructions/ashby-instructions-2.png'
              fluid
            />
          </div>
          <p>Remplissez les informations suivantes :</p>
          <ol>
            <li>
              <b>Name:</b> HireSweet
            </li>
            <li>
              <b>Integration Partner (optionnel) :</b> HireSweet
            </li>
          </ol>
          <p>
            Puis cliquez sur le bouton <b>Create API Key</b>.
          </p>
          <br />
          <h3>
            <span>3</span> Copier la clé d’API
          </h3>
          <div className='image-container'>
            <Image
              src='/images/screenshots/integrations-instructions/ashby-instructions-3.png'
              fluid
            />
          </div>
          <p>
            Cliquez sur le logo <b>Copy</b> pour avoir la clé dans votre
            presse-papier. Vous pouvez la coller dans un bloc-notes ou sur
            directement (voir étape 4).
          </p>
          <br />
          <h3>
            <span>4</span> Copier-coller la clé sur HireSweet CRM
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/ashby-instructions-4-fr.png'
              fluid
            />
          </div>
          <p>
            Après avoir collé la clé, cliquer sur <b>Enregistrer</b>.
          </p>
          <p>
            HireSweet CRM est désormais connecté à votre Ashby, félicitations !
          </p>
          <br />
          <p>
            Si cela ne fonctionne pas, vous pouvez vous rapprocher de votre
            Account Manager via le chat ou par e-mail.
          </p>
        </>
      ) : (
        <>
          <div style={styles.integrationHelper}>
            <p>
              To connect Ashby to HireSweet CRM, you need an admin access. If
              this is not the case, please ask one of your collaborators or
              managers.
            </p>
          </div>
          <h3>
            <span>1</span> Go to <b>API Keys</b> in Ashby
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/ashby-instructions-1.png'
              fluid
            />
          </div>
          <p>
            To access this page: click{' '}
            <a
              href='https://app.ashbyhq.com/admin/api/keys'
              target='_blank'
              rel='noopener noreferrer'
            >
              here
            </a>
            .
          </p>
          <p>
            You can also click <b>Admin</b> in the header, then go to{' '}
            <b>Ashby API</b> and click <b>API Keys</b>.
          </p>
          <h3>
            <span>2</span> Click <b>New</b> and setup the key
          </h3>
          <div className='image-container'>
            <Image
              src='/images/screenshots/integrations-instructions/ashby-instructions-2.png'
              fluid
            />
          </div>
          <p>Fill in the following fields:</p>
          <ol>
            <li>
              <b>Name:</b> HireSweet
            </li>
            <li>
              <b>Integration Partner (optional):</b> HireSweet
            </li>
          </ol>
          <h3>
            <span>3</span> Copy the API key
          </h3>
          <div className='image-container'>
            <Image
              src='/images/screenshots/integrations-instructions/ashby-instructions-3.png'
              fluid
            />
          </div>
          <p>
            Click the <b>Copy</b> logo to get the API key. You can paste it in
            your notepad or directly on HireSweet CRM (see step 4).
          </p>
          <br />
          <h3>
            <span>4</span> Copy-paste the key on HireSweet CRM
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/integrations-instructions/ashby-instructions-4-en.png'
              fluid
            />
          </div>
          <p>
            Then click on <b>Save</b>.
          </p>
          <p>Congratulations, HireSweet CRM is now connected to your Ashby!</p>
          <br />
          <p>
            If you experience any issues connecting Ashby to HireSweet CRM,
            please contact your Account Manager. You can reach them via email or
            via chat.
          </p>
        </>
      )}
    </div>
  );
};

export default IntegrationInstructions;
