import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import {
  getActionTitle,
  getActionType,
  isEmailAction,
} from '@/common/constants/taskTypes';
import { getTranslatedText } from '../../../../common';
import ExpandableContainer from '../../../../components/ExpandableContainer';
import ReadOnlyTemplate from '../../../../containers/Parameters/Offers/components/ReadOnlyTemplate';
import TaskIcon from '../TaskIcon';
import { getTriggerText } from './getTriggerText';
import './ActionPreview.css';
import { TYPES_WITH_SUBJECT } from '../FullContactFlowEditor/FullContactFlowActionEditor/helpers';

const ActionPreview = ({
  clientId,
  action,
  actions,
  actionIndex,
  template,
  alwaysExpanded,
  userSenders,
}) => {
  const { t } = useTranslation('translations');
  const descriptionText = getTranslatedText(action?.description);
  const isFollowup =
    isEmailAction(action) &&
    _.findIndex(actions, isEmailAction) !== actionIndex;

  const messageBody = action?.message?.body || template?.body || '';
  const messageSubject = action?.message?.subject || template?.subject || '';
  const snippets = action.snippets || [];

  const sender = _.findWhere(userSenders, { id: action?.message?.senderId });
  const isUnauthorizedSender = action?.message?.senderId && !sender;

  const shouldDisplaySubject =
    !isFollowup &&
    _.contains(TYPES_WITH_SUBJECT, getActionType(action)) &&
    messageSubject.length;

  return (
    <div className='action-preview'>
      <div className='title'>
        <div className='position'>{actionIndex + 1}</div>

        <TaskIcon action={action} />
        {getActionTitle({ action })}
      </div>

      <div className='trigger'>
        <div className='line' />

        <div className='trigger-text'>
          {getTriggerText({ actionIndex, action, t })}
        </div>
      </div>

      {(sender?.senderAddress || sender?.name || isUnauthorizedSender) && (
        <div className='sender'>
          <div className='line' />
          <span className='intro-word'>
            {t('profile.contact.timeline.from')}
          </span>
          {isUnauthorizedSender ? (
            <span className='sender-error'>
              {t('settings.senders.unauthorizedSender')}
            </span>
          ) : (
            <span className='sender-address'>
              {sender?.senderAddress || sender?.name}
            </span>
          )}
        </div>
      )}

      {descriptionText && (
        <div className='description'>
          <div className='line' />

          <ExpandableContainer
            className='description-text'
            alwaysExpanded={alwaysExpanded}
            render={({ contentRef }) => (
              <div className='description-text-content' ref={contentRef}>
                {descriptionText}
              </div>
            )}
          />
        </div>
      )}

      {(messageBody || messageSubject) && (
        <div className='message'>
          <div className='line' />

          <ExpandableContainer
            className='message-template'
            alwaysExpanded={alwaysExpanded}
            render={({ contentRef }) => (
              <ReadOnlyTemplate
                contentRef={contentRef}
                clientId={clientId}
                snippets={snippets}
                subject={messageSubject}
                body={messageBody}
                shouldDisplaySubject={shouldDisplaySubject}
              />
            )}
          />
        </div>
      )}

      {!descriptionText && !template && <div className='line' />}
    </div>
  );
};

export default ActionPreview;
