import React from 'react';
import classNames from 'classnames';
import _ from 'underscore';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import GenericButton from '@/components/Common/GenericButton';
import Delete from '@/components/Reveal/Icons/Delete';
import { CustomIcon } from '@/components/Common/GenericIconSelector/IconDropdown/IconDropdown';

import styles from './AutomationIntegrationsTable.module.less';

interface AutomationIntegrationsTableProps {
  service: 'phantomBuster' | 'unipile';
  automations: any[] | undefined;
  onEdit: ({ automationId }: { automationId: string }) => void;
  onDelete: ({ automationId }: { automationId: string }) => void;
}

const AutomationIntegrationsTable: React.FC<AutomationIntegrationsTableProps> = ({
  service,
  automations,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();

  if (_.isEmpty(automations)) {
    return (
      <div className={styles.emptyState}>
        {t(`settings.automations.${service}.emptyState`)}
      </div>
    );
  }

  return (
    <Table basic className={styles.table}>
      <Table.Header className={styles.header}>
        <Table.Row>
          <Table.HeaderCell className={styles.th}>
            <span>{t('reveal.parameters.customTasksSettings.table.name')}</span>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.th}>
            <span>{t('reveal.parameters.customTasksSettings.table.type')}</span>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.th}>
            <span>{t(`settings.automations.${service}.table.author`)}</span>
          </Table.HeaderCell>
          <Table.HeaderCell collapsing />
          <Table.HeaderCell collapsing />
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {_.map(automations || [], (automation) => (
          <Table.Row key={automation.id} className={styles.row}>
            <Table.Cell textAlign='left' className={styles.name}>
              <span className={styles.automationIcon}>
                <CustomIcon icon={automation.icon} />
              </span>
              <span>{automation.title}</span>
            </Table.Cell>
            <Table.Cell textAlign='left' className={styles.subtype}>
              <span>
                {t(
                  `settings.automations.${service}.table.type.${automation.type}`,
                )}
              </span>
            </Table.Cell>
            <Table.Cell textAlign='left' className={styles.subtype}>
              <span>
                {automation.owner
                  ? automation.owner.email
                  : `${automation.author?.firstname} ${automation.author?.lastname}`}
              </span>
            </Table.Cell>

            <Table.Cell>
              <GenericButton
                primacy='tertiary'
                onClick={() => onEdit({ automationId: automation.id })}
              >
                <i className={classNames('ri-pencil-fill', styles.icon)} />
              </GenericButton>
            </Table.Cell>
            <Table.Cell>
              <GenericButton
                primacy='tertiary'
                onClick={() => onDelete({ automationId: automation.id })}
              >
                <Delete />
              </GenericButton>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default AutomationIntegrationsTable;
