import _ from 'underscore';

export type EnrichedField = {
  text?: string;
} | null;

export type EnrichedContentField = {
  content: EnrichedField;
} | null;

export type Location = { name: { default: string } };

export const fallbackForFieldWithEnrichments = ({
  field,
  fieldWithEnrichments,
  fallback,
}: {
  field: EnrichedContentField;
  fieldWithEnrichments: EnrichedContentField;
  fallback: string;
}): string =>
  fieldWithEnrichments?.content?.text ||
  field?.content?.text ||
  (_.isString(field) && field) ||
  fallback;

export const fallbackForEnrichedText = ({
  field,
  fallback,
}: {
  field: EnrichedField;
  fallback: string;
}): string => field?.text || fallback;

export const fallbackForLocation = ({
  location,
}: {
  location: Location;
}): string =>
  (_.isString(location) && location) || location?.name?.default || '';

