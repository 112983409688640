import _ from 'underscore';
import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import GenerateSequenceForm, {
  getPromptFromActionType,
  getSequenceTypeFromActionTypes,
} from '@/revealComponents/FullContactFlowEditor/FullContactFlowActionEditor/GenerateSequenceForm';

import './SequenceLayout.css';
import useClientId from '@/hooks/router/useClientId';
import useClientMarketplaceDescriptor from '@/graphql/hooks/clients/useClientMarketplaceDescriptor';
import { getRandomString, getTranslatedText } from '@/common';
import { getHtmlStringAsText } from '@/routes/RevealView/SearchView/utils';
import {
  TYPES_WITH_MESSAGE,
  TYPES_WITH_SUBJECT,
} from '@/revealComponents/FullContactFlowEditor/FullContactFlowActionEditor/helpers';
import { handleSnippetInGeneratedText } from '@/revealComponents/FullContactFlowEditor/FullContactFlowActionEditor/GenerateSequence';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import useGenerateMaybeDemoSequence from '@/graphql/hooks/sequences/useGenerateMaybeDemoSequence';

const SequenceLayout = ({
  withAiGenerationPanel,
  updateGeneratedActions,
  subHeader,
  children,
  onLoading,
  sequenceId,
  hasSidePanel = false,
  isStandalone,
  // 0 means no polling
  pollInterval = 0,
}) => {
  const clientId = useClientId();
  const { permissions } = useClientPermissions(clientId);
  const { t } = useTranslation();
  const [sessionId] = useState(getRandomString(5));
  const [loaderPlacement, setLoaderPlacement] = useState({
    top: 0,
    left: 0,
    width: 0,
  });
  const [formState, setFormState] = useState({
    basePrompts: [
      "Use 5 to 10 sentence to write an outreach email to a candidate I'd like to hire\nYou should mention the current candidate's company.\n\nCandidate's firstname: {{firstname}}\nCandidate's company: {{currentCompany}}",
    ],
    context: {
      language: 'english',
      tone: 'Casual',
      companyDescription: {
        description: '',
      },
      jobDescription: {
        description: '',
      },
      jobPosition:
        clientId === 'ai-seq-demo'
          ? t('sequences.generation.modal.recruiter')
          : '',
    },
    computePolicy: 'bulk',
    fullname: clientId === 'ai-seq-demo' ? 'Jane Doe' : undefined,
  });

  const localStorageJobDescription =
    localStorage.getItem(`jobDescription_${sequenceId}`) || '';

  useEffect(() => {
    setFormState((f) => ({
      ...f,
      context: {
        ...f.context,
        jobDescription: {
          description: localStorageJobDescription,
        },
      },
    }));
  }, [localStorageJobDescription]);

  const ref = useRef({});
  const [actions, setActions] = useState([]);
  const showPrompt = localStorage.getItem('showPrompt') || false;
  const [streamEnded, setStreamEnded] = useState(true);

  const [fetch, { loading }] = useGenerateMaybeDemoSequence({
    queryOptions: {
      pollInterval: streamEnded ? 0 : pollInterval,
      fetchPolicy: 'network-only',
      skip: true,
    },
    onData: (data) => {
      if (
        data?.generateSequence?.streamEnded ||
        data?.client?.generateSequence?.streamEnded
      ) {
        // Stop polling
        onLoading(false);
        setStreamEnded(true);
      }
    },
    onError: (error) => {
      console.error('Error while generating sequence');
      console.error(error);
      onLoading(false);
      setStreamEnded(true);
    },
    onGeneratedSequence: (generatedSequence) => {
      let maxActionWithContentIndex = -1;
      const newActions = _.map(generatedSequence?.actions, (action, index) => {
        const {
          outputWithSnippets: subject,
          snippetsInText: subjectSnippets,
        } = handleText(action.subject, t);
        const {
          outputWithSnippets: body,
          snippetsInText: bodySnippets,
        } = handleText(action.body, t);
        if (body) {
          maxActionWithContentIndex = index;
        }
        return {
          ...actions[index],
          actionId: `contact-flow-action-${getRandomString(6)}`,
          message: {
            subject,
            body,
          },
          snippets: [...subjectSnippets, ...bodySnippets],
        };
      });
      if (!pollInterval) {
        onLoading(false);
      }
      if (maxActionWithContentIndex > 0) {
        document.getElementById(`contact-flow-action-${maxActionWithContentIndex}`)?.scrollIntoView({ behavior: "smooth" });
      }
      updateGeneratedActions(newActions);
    },
  });

  const handleText = (text) => {
    const { snippetsInText, outputWithSnippets } = handleSnippetInGeneratedText(
      text,
      t,
    );
    return { snippetsInText, outputWithSnippets };
  };

  const { marketplaceDescriptor } = useClientMarketplaceDescriptor(clientId);

  const settingsCompanyDescription = getTranslatedText(
    marketplaceDescriptor?.description?.sections?.[0]?.content || {
      default: '',
    },
  );

  useEffect(() => {
    setFormState((f) => ({
      ...f,
      context: {
        ...f.context,
        companyDescription: {
          description: getHtmlStringAsText(settingsCompanyDescription),
        },
      },
    }));
  }, [settingsCompanyDescription]);

  useEffect(() => {
    let resizeObserver;
    if (!ref.current || !loading) {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
      return; // wait for the elementRef to be available
    }
    resizeObserver = new ResizeObserver(() => {
      if (!ref.current) {
        return;
      }
      const refRect = ref?.current?.getBoundingClientRect();
      setLoaderPlacement({
        left: refRect.left,
        top: refRect.top,
        width: refRect.width,
        height: refRect.height,
      });
    });
    resizeObserver.observe(ref.current);
  }, [loading]);

  const updateFormStateAndFetch = () => {
    if (!ref.current) {
      return;
    }
    setStreamEnded(false);
    const refRect = ref.current.getBoundingClientRect();
    setLoaderPlacement({
      left: refRect.left,
      top: refRect.top,
      width: refRect.width,
      height: refRect.height,
    });
    onLoading(true);
    updateGeneratedActions(actions);
    const actionTypes = _.pluck(actions, 'type');
    const firstEmailInActionsIndex = _.findIndex(actionTypes, (type) =>
      _.contains(TYPES_WITH_SUBJECT, type),
    );
    const actionPrompts = _.map(actions, (action, index) => {
      return getPromptFromActionType(
        action,
        formState.computePolicy,
        _.filter(actions.slice(0, index), (currentAction) =>
          _.contains(TYPES_WITH_MESSAGE, currentAction.type),
        )?.length > 0,
        index,
        index > 0 ? actions[index - 1] : null,
      );
    });
    let prompts = actionPrompts;
    if (formState.computePolicy === 'bulk') {
      prompts = `Write a sequence of ${getSequenceTypeFromActionTypes(
        actionTypes,
      )} for a candidate that I want to hire.\n`;
      prompts += _.map(actionPrompts, (prompt, index) => {
        if (index === firstEmailInActionsIndex) {
          return `${index + 1}) Subject Message ${index +
            1}: write a catchy subject\n${index + 2}) Message ${index +
            1}: ${prompt}`;
        }
        if (firstEmailInActionsIndex > -1 && index > firstEmailInActionsIndex) {
          return `${index + 2}) Message ${index + 1}: ${prompt}`;
        }
        return `${index + 1})  Message ${index + 1}: ${prompt}`;
      }).join('\n');
      prompts += '\n\n###\nOutput format\n';
      prompts += 'Tone of voice used:[insert]\n';
      prompts += 'Language used:[insert]\n';
      prompts = [
        prompts +
        _.map(actionPrompts, (prompt, index) => {
          if (index === 0) {
            return `Subject Message ${firstEmailInActionsIndex +
              1}:[insert]\nMessage 1:[insert]`;
          }
          return `Message ${index + 1}:[insert]`;
        }).join('\n'),
      ];
    }
    const newStreamId = isStandalone
      ? `${sessionId}_${getRandomString(12)}`
      : '';
    const { computePolicy, context, fullname = '' } = formState;
    const [firstname, ...rest] = fullname.split(' ');
    const lastname = rest.join(' ');
    const author = firstname || lastname ? { firstname, lastname } : undefined;
    setFormState({
      computePolicy,
      context,
      fullname,
      basePrompts: prompts,
      streamId: newStreamId,
    });
    const input = {
      computePolicy,
      context,
      author,
      basePrompts: prompts,
      streamId: newStreamId,
    };
    onLoading(true);
    fetch(input);
  };

  const saveJobDescriptionInLocalStorage = () => {
    if (sequenceId) {
      localStorage.setItem(
        `jobDescription_${sequenceId}`,
        formState.context.jobDescription.description,
      );
    }
  };

  if (!clientId) {
    return null;
  }

  const isSubmitDisabled = _.isEmpty(actions);

  const sidePanelActive =
    hasSidePanel && permissions.aiSequenceGenerationSidebar;

  return (
    <div className='sequence-layout'>
      <div className='sequence-layout_header'>{subHeader}</div>
      <div className='sequence-layout_frame'>
        {sidePanelActive && (
          <div
            className={`sequence-layout_side-panel ${withAiGenerationPanel ? 'active' : ''
              }`}
          >
            <div className='panel-content'>
              <GenerateSequenceForm
                clientId={clientId}
                formState={formState}
                setFormState={setFormState}
                showPrompt={showPrompt}
                multipleActions
                actions={actions}
                setActions={setActions}
              />
            </div>
            <div className='panel-action-footer'>
              <div className='panel-action-container'>
                <button
                  type='button'
                  className='panel-action'
                  disabled={loading || isSubmitDisabled}
                  onClick={() => {
                    saveJobDescriptionInLocalStorage();
                    updateFormStateAndFetch();
                  }}
                >
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M11.516 1.89224L3.39669 10.9709C2.50334 11.9699 3.21679 13.5489 4.56146 13.5489H8.88318C9.74386 13.5489 10.4416 14.2424 10.4416 15.0979V21.336C10.4416 22.4042 11.77 22.9061 12.484 22.1078L20.6033 13.0291C21.4967 12.0301 20.7832 10.4511 19.4385 10.4511H15.1168C14.2561 10.4511 13.5584 9.75759 13.5584 8.90213V2.66404C13.5584 1.59583 12.23 1.09386 11.516 1.89224Z'
                      fill='white'
                    />
                  </svg>

                  {t('sequences.generation.sidebar.generate')}
                </button>
              </div>
            </div>
          </div>
        )}
        <div
          className={classNames('sequence-layout_content', {
            'with-side-panel': sidePanelActive,
            right: withAiGenerationPanel,
          })}
          ref={ref}
        >
          <>
            {((!streamEnded && isStandalone) || loading) && (
              <div
                className='panel-loader'
                style={{
                  top: loaderPlacement.top,
                  left: loaderPlacement.left,
                  width: loaderPlacement.width,
                  height: loaderPlacement.height,
                }}
              >
                <Loader active className='loader' />
              </div>
            )}
            {children}
          </>
        </div>
      </div>
    </div>
  );
};

export default SequenceLayout;
