import _ from 'underscore';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import GenericCheckbox from '@/components/Common/GenericCheckbox';

import useUserTableViewPreferences from '@/graphql/hooks/users/useUserDisplayPreferences';
import useClientId from '@/hooks/router/useClientId';
import { useClientRevealConnector } from '@/graphql/hooks/clients/useClientRevealProjects';
import { ATStype, ATS_NAMES_BY_TYPE } from '@/common/reveal';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { getTranslatedText } from '@/common';
import SortingDropdown from '@/components/SortingDropdown';
import {
  ColumnDisplay,
  ColumnGroup,
  ColumnIds,
  DisplayedColumnMap,
} from '../JobsView/JobView/ProjectProfilesTab/columns/useProfilesTableColumns';
import TableColumnSelector from '../JobsView/TableColumnSelector';

import styles from './ProfileRowHeader.module.less';
import './ProfileRow.less';
import { CustomFieldsColumnsToExcludeFromCustomFieldsSection } from '.';
import { MISSION_PROFILES_TABLE_ID } from '../JobsView/ProfilesTable';

interface ListHeaderParams {
  tableId?: string;
  onToggleCheckbox?: (value: { checked: boolean }) => void;
  shouldHideCheckbox?: boolean;
  isChecked?: boolean;
  showWhitespace?: boolean;
  showProfileStep?: boolean;
  showMissions?: boolean;
  showEmail?: boolean;
  showPhoneNumber?: boolean;
  showRelevance?: boolean;
  showLastInteraction?: boolean;
  showNextInteraction?: boolean;
  showActiveSequence?: boolean;
  showAtsApplications?: boolean;
  showCampaignState?: boolean;
  showSequence?: boolean;
  columnGroups?: ColumnGroup[];
  displayedColumns?: Partial<DisplayedColumnMap>;
  onUpdateColumnDisplay?: (cols: ColumnDisplay) => void;
  displayColumn?: (id: ColumnIds) => boolean;
  customFields?: CustomFieldDefinition[];
  columnSorting?: boolean;
  showSubtype?: boolean;
  showCompanyDealState?: boolean;
  showComment?: boolean;
  showTitle?: boolean;
  showCompany?: boolean;
  showSource?: boolean;
  showHasBeenCandidate?: boolean;
  // showAdStatus?: boolean;
}

const ProfileRowHeader: FC<ListHeaderParams> = ({
  onToggleCheckbox,
  tableId = MISSION_PROFILES_TABLE_ID,
  isChecked,
  showWhitespace,
  showMissions,
  showRelevance,
  showLastInteraction,
  showNextInteraction,
  shouldHideCheckbox,
  showProfileStep = false,
  showEmail = false,
  showPhoneNumber = false,
  showActiveSequence = false,
  showAtsApplications = false,
  showCampaignState = false,
  showSequence = true,
  columnGroups = [],
  displayedColumns = {},
  onUpdateColumnDisplay,
  displayColumn = () => false,
  columnSorting = false,
  customFields,
  showSubtype = false,
  showCompanyDealState = false,
  showComment = false,
  showTitle = false,
  showCompany = false,
  showSource = false,
  showHasBeenCandidate = false,
  // showAdStatus = false,
}) => {
  const { t } = useTranslation();

  const {
    userTableViewPreferences: displayPreferences,
    updateUserTableViewPreferences,
  } = useUserTableViewPreferences({
    tableId,
    defaultValues: {
      tableId,
      sortingOptions: {
        sortBy: [],
      },
    },
  });

  const onUpdateSortBy = ({
    selectorId,
    order,
  }: {
    selectorId: string;
    order: 'ascending' | 'descending';
  }) => {
    const sortOptionIndex = _.findIndex(
      displayPreferences?.sortingOptions?.sortBy || [],
      (sortingOption) => sortingOption.key === selectorId,
    );
    const hasSameOrder =
      displayPreferences?.sortingOptions?.sortBy?.[sortOptionIndex]?.order ===
      order;

    if (hasSameOrder) {
      updateUserTableViewPreferences({
        tableId,
        sortingOptions: {
          sortBy: [],
        },
      });
      return;
    }

    updateUserTableViewPreferences({
      tableId,
      sortingOptions: {
        sortBy: [
          {
            key: selectorId,
            order,
          },
        ],
      },
    });
  };

  // const activeSortBy = useMemo(() => {
  //   return displayPreferences?.sortingOptions?.sortBy?.[0] || null;
  // }, [displayPreferences]);

  const clientId = useClientId();
  const connector = useClientRevealConnector(clientId);
  const atsId: ATStype | undefined = connector?.type;
  const atsName = atsId ? ATS_NAMES_BY_TYPE[atsId] : '';

  return (
    <div className='generic-profiles-list-header'>
      {onToggleCheckbox && (
        <div className='row-checkbox-container'>
          {!shouldHideCheckbox && (
            <GenericCheckbox
              checked={isChecked}
              big
              onClick={(e) => {
                e.stopPropagation();
                onToggleCheckbox({ checked: !isChecked });
              }}
            />
          )}
        </div>
      )}

      {showWhitespace && <div className='whitespace-container' />}

      {shouldHideCheckbox ? (
        <>
          <div className='name-and-headline-container'>
            <span className='header-text caption-2'>
              {columnSorting ? (
                <span className={styles.columnSortTrigger}>
                  <SortingDropdown
                    triggerText={t(
                      'reveal.missions.mission.tableHeader.contact',
                    )}
                    selectorId='name'
                    selectedSortingOption={
                      _.findWhere(
                        displayPreferences?.sortingOptions?.sortBy || [],
                        { key: 'name' },
                      )?.order as 'ascending' | 'descending'
                    }
                    onChangeSortingOption={({ selectorId, value }) =>
                      onUpdateSortBy({
                        selectorId,
                        order: value as 'ascending' | 'descending',
                      })
                    }
                  />
                </span>
              ) : (
                t('reveal.missions.mission.tableHeader.contact')
              )}
            </span>
          </div>
          <div className='avatar-container' />
        </>
      ) : (
        <>
          <div className='avatar-container' />
          <div className='name-and-headline-container'>
            <span className='header-text caption-2'>
              {columnSorting ? (
                <span className={styles.columnSortTrigger}>
                  <SortingDropdown
                    triggerText={t(
                      'reveal.missions.mission.tableHeader.contact',
                    )}
                    selectorId='name'
                    selectedSortingOption={
                      _.findWhere(
                        displayPreferences?.sortingOptions?.sortBy || [],
                        { key: 'name' },
                      )?.order as 'ascending' | 'descending'
                    }
                    onChangeSortingOption={({ selectorId, value }) =>
                      onUpdateSortBy({
                        selectorId,
                        order: value as 'ascending' | 'descending',
                      })
                    }
                  />
                </span>
              ) : (
                t('reveal.missions.mission.tableHeader.contact')
              )}
            </span>
          </div>
        </>
      )}

      {showProfileStep && (
        <div className='email-container'>
          <span className='header-text caption-2'>
            {t('reveal.missions.mission.tableHeader.step')}
          </span>
        </div>
      )}

      {showTitle && (
        <div className='title-container'>
          <span className='header-text caption-2'>
            {t('reveal.missions.mission.tableHeader.title')}
          </span>
        </div>
      )}

      {showCompany && (
        <div className='company-container'>
          <span className='header-text caption-2'>
            {t('reveal.missions.mission.tableHeader.company')}
          </span>
        </div>
      )}

      {showSource && (
        <div className='source-container'>
          <span className='header-text caption-2'>
            {t('reveal.missions.mission.tableHeader.source')}
          </span>
        </div>
      )}

      {showHasBeenCandidate && (
        <div className='has-been-candidate-container'>
          <span className='header-text caption-2'>
            {t('reveal.missions.mission.tableHeader.hasBeenCandidate')}
          </span>
        </div>
      )}

      {showEmail && (
        <div className='email-container'>
          <span className='header-text caption-2'>
            {t('reveal.missions.mission.tableHeader.email')}
          </span>
        </div>
      )}

      {showPhoneNumber && (
        <div className='phone-container'>
          <span className='header-text caption-2'>
            {t('reveal.missions.mission.tableHeader.phone')}
          </span>
        </div>
      )}

      {!_.isEmpty(
        CustomFieldsColumnsToExcludeFromCustomFieldsSection[clientId],
      ) &&
        _.map(
          CustomFieldsColumnsToExcludeFromCustomFieldsSection[clientId],
          (column) => {
            if (!displayColumn(column)) {
              return null;
            }
            return (
              <div className='missions-container'>
                <span className='header-text caption-2'>
                  {getTranslatedText(
                    _.findWhere(customFields || [], { id: column })?.title,
                  )}
                </span>
              </div>
            );
          },
        )}

      {showRelevance && (
        <div className='missions-container'>
          <span className='header-text caption-2'>
            {t('reveal.missions.mission.tableHeader.relevance')}
          </span>
        </div>
      )}

      {showMissions && (
        <div className='missions-container'>
          <span className='header-text caption-2'>
            {t('reveal.missions.mission.tableHeader.missions')}
          </span>
        </div>
      )}

      {showSubtype && (
        <div className='missions-container'>
          <span className='header-text caption-2'>
            {columnSorting ? (
              <span className={styles.columnSortTrigger}>
                <SortingDropdown
                  triggerText={t(
                    'reveal.missions.mission.tableHeader.category',
                  )}
                  selectorId='subtype'
                  selectedSortingOption={
                    _.findWhere(
                      displayPreferences?.sortingOptions?.sortBy || [],
                      { key: 'subtype' },
                    )?.order as 'ascending' | 'descending'
                  }
                  onChangeSortingOption={({ selectorId, value }) =>
                    onUpdateSortBy({
                      selectorId,
                      order: value as 'ascending' | 'descending',
                    })
                  }
                />
              </span>
            ) : (
              t('reveal.missions.mission.tableHeader.category')
            )}
          </span>
        </div>
      )}

      {showComment && (
        <div className='comment-container'>
          <span className='header-text caption-2'>
            {t('reveal.missions.mission.tableHeader.comment')}
          </span>
        </div>
      )}

      {showSequence &&
        (showActiveSequence ? (
          <div className='active-sequence-container'>
            <span className='header-text caption-2'>
              {t('reveal.missions.mission.tableHeader.activeSequence')}
            </span>
          </div>
        ) : (
          <div className='sequence-container'>
            <span className='header-text caption-2'>
              {t('reveal.missions.mission.tableHeader.sequence')}
            </span>
          </div>
        ))}

      {showCompanyDealState && (
        <div className='deal-state-container'>
          <span className='header-text caption-2'>
            {t('reveal.missions.mission.tableHeader.dealState')}
          </span>
        </div>
      )}

      {showCampaignState && (
        <div className='campaign-container'>
          <span className='header-text caption-2'>
            {t('reveal.missions.mission.tableHeader.campaignState')}
          </span>
        </div>
      )}

      {atsId !== 'adventure' && showLastInteraction && (
        <div className='last-interaction-container'>
          <span className='header-text caption-2'>
            {t('reveal.missions.mission.tableHeader.lastInteraction')}
          </span>
        </div>
      )}

      {_.map(customFields || [], (field) => {
        if (
          !displayColumn(field.id) ||
          _.includes(
            CustomFieldsColumnsToExcludeFromCustomFieldsSection[clientId],
            field.id,
          )
        ) {
          return null;
        }

        return (
          <div key={field.id} className={styles.customFieldHeader}>
            <span className='header-text caption-2'>
              {columnSorting ? (
                <span className={styles.columnSortTrigger}>
                  <SortingDropdown
                    fieldType={field.type}
                    triggerText={getTranslatedText(field.title)}
                    selectorId={`customField-${field.id}`}
                    selectedSortingOption={
                      _.findWhere(
                        displayPreferences?.sortingOptions?.sortBy || [],
                        {
                          key: `customField-${field.id}`,
                        },
                      )?.order as 'ascending' | 'descending'
                    }
                    onChangeSortingOption={({ selectorId, value }) =>
                      onUpdateSortBy({
                        selectorId,
                        order: value as 'ascending' | 'descending',
                      })
                    }
                  />
                </span>
              ) : (
                getTranslatedText(field.title)
              )}
            </span>
          </div>
        );
      })}

      {showNextInteraction && (
        <div className='next-interaction-container'>
          <span className='header-text caption-2'>
            {t('reveal.missions.mission.tableHeader.nextInteraction')}
          </span>
        </div>
      )}

      {showAtsApplications && (
        <div className='ats-stage-header'>
          <span className='header-text caption-2'>
            {t('reveal.missions.mission.tableHeader.atsApplications', {
              atsName,
            })}
          </span>
        </div>
      )}

      {atsId === 'adventure' && showLastInteraction && (
        <div className='last-interaction-container'>
          <span className='header-text caption-2'>
            {t('reveal.missions.mission.tableHeader.lastInteraction')}
          </span>
        </div>
      )}

      {Boolean(columnGroups?.length && displayedColumns) && (
        <div className={styles.columnSelectorHeader}>
          <TableColumnSelector
            onUpdateColumnsToDisplay={onUpdateColumnDisplay}
            currentColumnValues={Object.values(displayedColumns)}
            columnGroups={columnGroups}
          />
        </div>
      )}
    </div>
  );
};

export default ProfileRowHeader;
