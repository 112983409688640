import React, { FC, MouseEvent, useCallback, useMemo } from 'react';
import _ from 'underscore';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Offer } from '@/types/offer';
import useClientId from '@/hooks/router/useClientId';
import logAction from '@/common/logAction';
import Star from '@/components/Reveal/Icons/Star';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import { sentryCaptureException } from '@/common';
import useFollowJob from '@/graphql/hooks/offers/useFollowJob';
import useUnfollowJob from '@/graphql/hooks/offers/useUnfollowJob';
import OfferSettingsDropdown from '@/components/OfferSettingsDropdown';
import useOfferStepProfiles from '@/graphql/hooks/offers/useOfferStepProfiles';
import useOfferPipeStepStats from '@/graphql/hooks/offers/useOfferPipeStepStats';
import { getOptimizedSortedProfiles } from '@/containers/Profile/ProfileNavigation';

import OfferLabels from '../../components/OfferLabels';
import OfferActiveLabel from './OfferActiveLabel';
import OwnerLabel from './OwnerLabel';
import OfferPendingColumns from './OfferPendingColumns';
import { isOfferFavorite } from '../../helpers';

import styles from './OfferRow.module.less';

interface OfferRowProps {
  offer: Offer;
}

const OfferRow: FC<OfferRowProps> = ({ offer }) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { user } = useCurrentUser();
  const history = useHistory();
  const [followJob] = useFollowJob();
  const [unfollowJob] = useUnfollowJob();

  const { title, id } = offer;

  const { stepProfiles: profiles } = useOfferStepProfiles({
    offerId: id,
    step: 'pending',
  });

  const sortedProfiles = useMemo(
    () => getOptimizedSortedProfiles({ profiles, stepId: 'pending' }),
    [profiles],
  );

  const { pipeStepStats } = useOfferPipeStepStats({ offerId: id });

  const pipeStepCountsMap = useMemo(() => {
    const result = {} as Record<string, number>;

    _.each(pipeStepStats, ({ count = 0, stepId }) => {
      result[stepId] = count;
    });

    return result;
  }, [pipeStepStats]);

  const goToOffer = useCallback(() => {
    logAction({
      type: 'click-offer-link',
      info: {
        clientId,
        offerId: id,
        from: 'offers',
      },
    });
    history.push(`/client/${clientId}/jobs/${id}`);
  }, [history, clientId, id]);

  const goToStep = useCallback(
    (stepId: string) => {
      logAction({
        type: 'click-offer-step',
        info: {
          clientId,
          offerId: id,
          stepId,
          from: 'offers',
        },
      });
      history.push(`/client/${clientId}/jobs/${id}/profiles/step_${stepId}`);
    },
    [history, clientId, id],
  );

  const handleFollow = useCallback(async () => {
    try {
      await followJob(id);
    } catch (error) {
      sentryCaptureException({ error, tags: { feature: 'follow-job' } });
    }
  }, [followJob, id]);

  const handleUnfollow = useCallback(async () => {
    try {
      await unfollowJob(id);
    } catch (error) {
      sentryCaptureException({ error, tags: { feature: 'unfollow-job' } });
    }
  }, [unfollowJob, id]);

  const isFavorite = useMemo(() => isOfferFavorite(user, offer), [offer, user]);

  const handleStarClick = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation();
      await (isFavorite ? handleUnfollow : handleFollow)();
    },
    [isFavorite, handleUnfollow, handleFollow],
  );

  const handlerForStep = useCallback(
    (stepId: string) => async (event: MouseEvent) => {
      event.stopPropagation();
      goToStep(stepId);
    },
    [goToStep],
  );

  return (
    <button type='button' className={styles.offer} onClick={goToOffer}>
      <button
        type='button'
        className={classNames(styles.favoriteButton, {
          [styles.favorite]: isFavorite,
        })}
        onClick={handleStarClick}
      >
        <Star className={styles.star} />
      </button>
      <div className={styles.info}>
        <div className={styles.title}>{title}</div>
        <div className={styles.labels}>
          <OfferActiveLabel offer={offer} />
          <OwnerLabel offer={offer} />
          <OfferLabels offer={offer} clientId={clientId} />
        </div>
      </div>
      <OfferPendingColumns
        offerId={offer.id}
        pendingProfiles={sortedProfiles}
        isHold={offer.isHold}
        handlerForStep={handlerForStep}
      />
      <button
        type='button'
        className={classNames(styles.extraColumn, {
          [styles.notEmpty]: pipeStepCountsMap.answered > 0,
        })}
        onClick={handlerForStep('answered')}
      >
        <span className={styles.count}>{pipeStepCountsMap.answered || 0}</span>
      </button>
      {pipeStepCountsMap.hired > 0 ? (
        <div
          className={classNames(styles.extraColumn, {
            [styles.notEmpty]: pipeStepCountsMap['in-process'] > 0,
          })}
        >
          <button
            type='button'
            className={styles.count}
            onClick={handlerForStep('in-process')}
          >
            {pipeStepCountsMap['in-process'] || 0}
          </button>
          <button
            type='button'
            onClick={handlerForStep('hired')}
            className={styles.hired}
          >
            {t('offers.hired', { count: pipeStepCountsMap.hired })}
          </button>
        </div>
      ) : (
        <button
          type='button'
          className={classNames(styles.extraColumn, {
            [styles.notEmpty]: pipeStepCountsMap['in-process'] > 0,
          })}
          onClick={handlerForStep('in-process')}
        >
          <span className={styles.count}>
            {pipeStepCountsMap['in-process'] || 0}
          </span>
        </button>
      )}
      <button
        type='button'
        className={styles.menu}
        onClick={(e) => e.stopPropagation()}
      >
        <OfferSettingsDropdown
          offerId={offer.id}
          clientId={clientId}
          from='offers'
        />
      </button>
    </button>
  );
};

export default OfferRow;
