import _ from 'underscore';
import moment from 'moment';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { getTranslatedText } from '@/common';

export interface ICustomFieldValue {
  id: string;
  clientCustomFieldId: string;
}

export interface CustomFieldValueEnum extends ICustomFieldValue {
  type: 'enum';
  selected: string[];
}

interface CustomFieldValueFloat extends ICustomFieldValue {
  type: 'float';
  float: number;
}

interface CustomFieldValueInt extends ICustomFieldValue {
  type: 'integer';
  integer: number;
}

interface CustomFieldValueText extends ICustomFieldValue {
  type: 'text' | 'inline-text';
  text: string;
}

export interface CustomFieldValueDay extends ICustomFieldValue {
  type: 'day';
  date: string;
}

export type CustomFieldValue =
  | CustomFieldValueEnum
  | CustomFieldValueText
  | CustomFieldValueInt
  | CustomFieldValueDay
  | CustomFieldValueFloat;

type ResumeDataWithCustomFields = {
  customFields?: CustomFieldValue[];
};

export function getFieldTypeIcon(field: CustomFieldDefinition): string {
  if (field.type === 'enum') return 'ri-list-check';
  if (field.type === 'day') return 'ri-calendar-event-line';
  if (field.type === 'text') return 'ri-align-left';
  if (field.type === 'inline-text') return 'ri-align-left';
  if (field.type === 'integer') return 'ri-number-8';
  if (field.type === 'float') return 'ri-calculator-line';
  console.error('Unknown field type', field);
  return '';
}
export function getCustomFieldRawValue(value: CustomFieldValue) {
  if (value.type === 'integer') return value.integer;
  if (value.type === 'float') return value.float;
  if (value.type === 'text') return value.text;
  if (value.type === 'inline-text') return value.text;
  if (value.type === 'enum') return value.selected;
  if (value.type === 'day') return value.date;
  return undefined;
}

export function getCustomFieldFakeValue(
  customField: CustomFieldDefinition,
): number | string | string[] | undefined {
  if (customField.type === 'integer') return 123;
  if (customField.type === 'float') return 123.45;
  if (customField.type === 'text') return 'This is a text.';
  if (customField.type === 'inline-text') return 'This is a simple text';
  if (customField.type === 'enum') {
    return customField.isMultiselect
      ? customField.options.map((x) => x.id)
      : [customField.options[0].id];
  }
  if (customField.type === 'day') return moment().format('YYYY-MM-DD');
  return undefined;
}

export function getReadableValue(
  value: ReturnType<typeof getCustomFieldRawValue>,
  customField: CustomFieldDefinition,
) {
  if (customField.type === 'day') {
    return value ? moment(value).format('MMMM Do, YYYY') : null;
  }
  if (!Array.isArray(value)) return value;
  if (customField.type !== 'enum') return value;
  return value
    .map((id) => {
      const option = customField.options.find((op) => op.id === id);

      return option ? getTranslatedText(option.title) : id;
    })
    .join(', ');
}

export function combineDataWithCustomFields(
  customFields: CustomFieldDefinition[],
  resumeData?: ResumeDataWithCustomFields,
) {
  const values = resumeData?.customFields || ([] as CustomFieldValue[]);

  return customFields.map((customField) => {
    const value = values.find((v) => v.clientCustomFieldId === customField.id);
    const rawValue = value && getCustomFieldRawValue(value);
    const readableValue = rawValue && getReadableValue(rawValue, customField);
    return {
      clientCustomFieldId: customField.id,
      ...customField,
      ...value,
      rawValue,
      readableValue,
    };
  });
}

export type CustomFieldRawValue =
  | CustomFieldValueEnum['selected']
  | CustomFieldValueFloat['float']
  | CustomFieldValueInt['integer']
  | CustomFieldValueDay['date']
  | CustomFieldValueText['text'];

const getNumberOrNull = <T>(value: T): number | null => {
  const n = Number(value);
  return Number.isNaN(n) ? null : n;
};

export function createBaseCustomFieldValue(
  type: CustomFieldValue['type'],
  rawValue: CustomFieldRawValue,
) {
  if (type === 'text') return { type, text: rawValue };
  if (type === 'inline-text') return { type, text: rawValue };
  if (type === 'float') return { type, float: getNumberOrNull(rawValue) };
  if (type === 'integer') return { type, integer: getNumberOrNull(rawValue) };
  if (type === 'enum') return { type, selected: rawValue };
  if (type === 'day') {
    const rawDate = moment(rawValue);
    if (!rawDate.isValid()) return { type, date: null };
    return {
      type,
      date: rawDate
        .set({ hours: 12, minutes: 0, seconds: 0, milliseconds: 0 })
        .utcOffset(0)
        .set({ hours: 12 })
        .toISOString(),
    };
  }
  return undefined;
}

export function getCustomFieldJSONValue(
  type: CustomFieldValue['type'],
  rawValue: CustomFieldRawValue,
) {
  const value = createBaseCustomFieldValue(type, rawValue);
  return getCustomFieldRawValue(value as CustomFieldValue);
}

export function createCustomFieldsPayload(
  customFieldsData: Record<string, CustomFieldRawValue> | undefined,
  customFields: CustomFieldDefinition[],
) {
  return _.compact(
    _.map(customFieldsData || {}, (rawValue, clientCustomFieldId) => {
      const customField = customFields.find(
        (cf) => cf.id === clientCustomFieldId,
      );
      if (!customField) return null;
      return {
        ...createBaseCustomFieldValue(customField.type, rawValue),
        clientCustomFieldId,
      };
    }),
  );
}

export function getFieldTypeKey(field: CustomFieldDefinition) {
  if (field.type === 'enum') {
    return field.isMultiselect ? 'multiselect' : 'select';
  }
  return field.type;
}
