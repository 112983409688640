import React, { FC } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import AvatarColumn from '@/components/ProfileRow/columns/AvatarColumn';
import NameAndHeadlineColumn from '@/components/ProfileRow/columns/NameAndHeadlineColumn';
import HeaderRow from '@/components/ProfileRow/HeaderRow/HeaderRow';
import usePagination from '@/hooks/ui/usePagination';
import ProfileRow from '../ProfileRow';
import SequenceColumn from './SequenceColumn';
import { SequenceDetail } from './types';

import styles from './StatsProfileAndSequenceTable.module.less';

interface StatsProfileAndSequenceTableProps {
  sequenceDetails: SequenceDetail[];
}

const StatsProfileAndSequenceTable: FC<StatsProfileAndSequenceTableProps> = ({
  sequenceDetails,
}) => {
  const { t } = useTranslation();

  const { pageElements, numberOfPages, PageSelector } = usePagination(
    sequenceDetails,
  );

  return (
    <div className={styles.table}>
      <HeaderRow
        columns={[
          { id: 'avatar', title: '', className: styles.avatar },
          {
            id: 'contact',
            title: t('reveal.missions.mission.tableHeader.contact'),
            className: styles.nameAndHeadline,
          },
          {
            id: 'sequence',
            title: t('reveal.missions.mission.tableHeader.sequence'),
            className: styles.sequence,
          },
        ]}
      />
      <div>
        {_.map(pageElements, ({ profileId, sequenceId, day }, index) => (
          <ProfileRow
            key={`${sequenceId}_${day}_${profileId}_${index}`}
            profileId={profileId}
            columns={[
              {
                id: 'avatar',
                component: AvatarColumn,
                className: styles.avatar,
              },
              {
                id: 'contact',
                component: NameAndHeadlineColumn,
                className: styles.nameAndHeadline,
              },
              {
                id: 'sequence',
                component: ({ profileLoading }) => (
                  <SequenceColumn
                    profileLoading={profileLoading}
                    sequenceId={sequenceId}
                    className={styles.sequence}
                    day={day}
                  />
                ),
              },
            ]}
          />
        ))}
      </div>
      {numberOfPages > 1 && (
        <div className={styles.pagination}>
          <PageSelector />
        </div>
      )}
    </div>
  );
};

export default StatsProfileAndSequenceTable;
