import _, { compose } from 'underscore';
import React from 'react';
import { withTranslation } from 'react-i18next';

import routerParamsToProps from '../../../hocs/routerParamsToProps';
import contextToProps from '../../../hocs/contextToProps';
import withClientCollaborators from '../../../hocs/users/withClientCollaborators';
import withAddCollaborator from '../../../hocs/users/withAddCollaborator';
import withCancelInvite from '../../../hocs/users/withCancelInvite';
import withRemoveCollaborator from '../../../hocs/users/withRemoveCollaborator';
import withUserFromJWToken from '../../../hocs/users/withUserFromJWToken';

import PendingInviteCard from '../../../components/PendingInviteCard';
import ActiveCollaboratorsCard from '../../../components/ActiveCollaboratorsCard';
import SettingsLayout from '../../SettingsLayout';
import CollaboratorInvitationForm from './CollaboratorInvitationForm';

import './collaborators.css';

class ClientCollaborators extends React.PureComponent {
  render() {
    const {
      loading,
      error,
      collaborators,
      addUser,
      removeUser,
      user,
      clientId,
      t,
    } = this.props;

    if (loading) return <div />;
    if (error) return <p />;
    if (!collaborators) return <div />;

    const isAutopilotUser = (collaborator) => {
      const { email = '' } = collaborator || {};
      return email.startsWith('autopilot') && email.endsWith('@hiresweet.com');
    };
    const displayedUsers = _.reject(collaborators, isAutopilotUser);
    const pendingUsers = _.filter(
      displayedUsers,
      (c) => c.status === 'pending',
    );

    return (
      <SettingsLayout
        clientId={clientId}
        currentPage='users'
        className='settings-container'
      >
        <div className='settings collaborators-page'>
          <div className='settings-header'>
            <h1>{t('collaborators.header')}</h1>
          </div>

          <h2>{t('collaborators.inviteSection.header')}</h2>
          <div className='section'>
            <div className='input-container'>
              <div className='input-label'>
                {t('collaborators.inviteSection.send')}
              </div>
              <div className='input-element'>
                <CollaboratorInvitationForm addUser={addUser} />
              </div>
            </div>
          </div>

          {!_.isEmpty(pendingUsers) && (
            <>
              <h2>{t('collaborators.pendingSection.header')}</h2>
              <div className='section'>
                <div>
                  {_.map(pendingUsers, (c) => (
                    <PendingInviteCard
                      key={c.id}
                      invite={c}
                      onClick={() => removeUser(c.email)}
                    />
                  ))}
                </div>
              </div>
            </>
          )}

          <h2>{t('collaborators.activeSection.header')}</h2>
          <div className='section'>
            <div>
              {_.map(
                _.filter(displayedUsers, (c) => c.status === 'active'),
                (c) => (
                  <ActiveCollaboratorsCard
                    key={c.id}
                    user={user}
                    collaborator={c}
                    onClick={() => removeUser(c.email)}
                  />
                ),
              )}
            </div>
          </div>
        </div>
      </SettingsLayout>
    );
  }
}

export default compose(
  routerParamsToProps,
  withClientCollaborators,
  withAddCollaborator,
  withCancelInvite,
  withRemoveCollaborator,
  withUserFromJWToken,
  contextToProps,
  withTranslation('translations'),
)(ClientCollaborators);
