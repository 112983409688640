import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import { MergeTagsVariable, SNIPPET_TYPES } from '@/common/mergeTags/utils';
import {
  getLimitedText,
  stripHtmlFromTextAndLinesBreaks,
} from '@/common/utils/string';
import styles from '../SequenceDynamicVariables.module.less';
import useMergeTagsVariablesColors from '../hooks/useMergeTagsVariablesColors';

type SequenceVariableProps = {
  offsetKey: string;
  mergeTagsVariable: MergeTagsVariable;
  onClick: (mergeTagsVariable: MergeTagsVariable) => void;
};

const locales: Record<string, string> = {
  en: 'EN',
  fr: 'FR',
};

const SequenceVariable: React.FC<SequenceVariableProps> = ({
  offsetKey,
  mergeTagsVariable,
  onClick,
}) => {
  const { t } = useTranslation();
  const {
    backgroundColor,
    variableValueColor,
    variableNameColor,
  } = useMergeTagsVariablesColors(mergeTagsVariable);

  const isPlugin = useIsPlugin();

  const variableDisplayValue = useMemo(() => {
    return getLimitedText(
      stripHtmlFromTextAndLinesBreaks(
        MergeTagsService.getSnippetDisplayedValue({
          snippet: mergeTagsVariable,
        }),
      ),
      100,
    );
  }, [mergeTagsVariable]);

  return (
    <div
      onClick={() => onClick(mergeTagsVariable)}
      spellCheck={false}
      data-offset-key={offsetKey}
      aria-hidden='true'
      style={{
        backgroundColor,
        display: 'inline',
        padding: isPlugin ? '3px 5px' : '3px 7px',
        borderRadius: '1em',
        fontSize: isPlugin ? '11px' : '14px',
        fontWeight: 500,
        cursor: mergeTagsVariable ? 'pointer' : 'initial',
      }}
    >
      {(mergeTagsVariable.type === SNIPPET_TYPES.CURRENT_EXPERIENCE_DURATION ||
        mergeTagsVariable.type === SNIPPET_TYPES.DURATION_SINCE_GRADUATION) &&
        mergeTagsVariable?.format && (
          <span className={styles.variableInfo}>
            {t(`editor.durationVariable.format.${mergeTagsVariable.format}`)}
          </span>
        )}
      {(mergeTagsVariable.type === SNIPPET_TYPES.CURRENT_EXPERIENCE_DURATION ||
        mergeTagsVariable.type === SNIPPET_TYPES.DURATION_SINCE_GRADUATION) &&
        mergeTagsVariable?.locale && (
          <span className={styles.variableInfo}>
            {locales[mergeTagsVariable.locale]}
          </span>
        )}

      <span style={{ color: variableNameColor, marginRight: '5px' }}>
        {mergeTagsVariable?.fallbackValue?.text &&
          !mergeTagsVariable.state?.value && (
            <img
              alt='arrow'
              src='/images/icons/fallback-yellow.svg'
              style={{ width: '14px', marginRight: '10px' }}
            />
          )}
        {mergeTagsVariable.name}
      </span>

      <span style={{ color: variableValueColor }}>{variableDisplayValue}</span>
    </div>
  );
};

export default SequenceVariable;
