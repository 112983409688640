import React, { useState, useMemo, FC, useEffect } from 'react';
import classNames from 'classnames';
import _ from 'underscore';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import useMinimizedView from '@/hooks/ui/useMinimizedView';
import useUserFromJWToken from '@/graphql/hooks/users/useUserFromJWToken';
import { lowerCaseAndUnaccent } from '@/common';
import useClientSequences from '@/graphql/hooks/sequences/useClientSequences';
import { GET_JOB_DEFAULT_SEQUENCES } from '@/graphql/searchPoolJob';
import ProfileRowSequence from '@/routes/RevealView/ProfileRow/ProfileRowSequence';
import { ItemGroupType } from '@/components/Common/SelectItemDropdown/SelectItemList';
import SelectItemDropdown from '@/components/Common/SelectItemDropdown';
import { Sequence } from '@/types/sequence';
import {
  GenericDropdownProps,
  TriggerProps,
} from '@/components/Common/GenericDropdown/GenericDropdown';

import DropdownTrigger from '../../Dropdown/DropdownTrigger/DropdownTrigger';
import DropdownContainer from '../../Dropdown/DropdownContainer/DropdownContainer';

import SequenceDropdownEmptyState from './EmptyState/SequenceDropdownEmptyState';
import SequenceDropdownNoResultsState from './NoResultsState/SequenceDropdownNoResultsState';
import {
  useSequenceDropdownPreferences,
  SEQUENCE_FILTER_FUNCTIONS,
  SEQUENCE_SORT_FUNCTIONS,
  SortKey,
  FilterKey,
} from './sortFilter';
import SequenceDropdownItem from './SequenceDropdownItem';

import styles from './SequenceDropdown.module.less';

interface SequenceDropdownBaseProps {
  isPlugin?: boolean;
  defaultSequenceId?: string;
  secondarySequenceIds?: string[];
  currentSequence?: {
    sequenceId?: string | null;
  };
  onSequenceSelected: (id: string) => void;
  clientId: string;
  shiftSidebar?: boolean;
  autoFocus?: boolean;
}

interface SequenceDropdownProps extends SequenceDropdownBaseProps {
  clientSequencesList: Sequence[];
}

const SequenceDropdown: React.FC<SequenceDropdownProps &
  GenericDropdownProps> = ({
  clientSequencesList,
  defaultSequenceId,
  secondarySequenceIds,
  onSequenceSelected,
  clientId,
  Trigger,
  shiftSidebar,
  className,
  style,
  ...dropdownProps
}) => {
  const { t } = useTranslation();
  const { isMinimized } = useMinimizedView();
  const [searchQuery, setSearchQuery] = useState<string>('');

  const {
    sortKey,
    filterKey,
    updateSortKey,
    updateFilterKey,
  } = useSequenceDropdownPreferences();

  const { data: { user } = {} } = useUserFromJWToken();

  const searchedSequences = useMemo(() => {
    if (!searchQuery) {
      return clientSequencesList;
    }
    const unaccentedSearchString = lowerCaseAndUnaccent(searchQuery);
    return _.filter(clientSequencesList, ({ title }) => {
      const unaccentedTitle = lowerCaseAndUnaccent(title || '');
      return unaccentedTitle.includes(unaccentedSearchString);
    });
  }, [clientSequencesList, searchQuery]);

  const defaultSequence = _.findWhere(searchedSequences, {
    id: defaultSequenceId,
  });

  const defaultSequenceGroup = useMemo<ItemGroupType>(
    () => ({
      groupId: 'default-sequence',
      groupLabel: t('reveal.missions.mission.settingsTab.defaultSequence'),
      sortBy: [],
      filterBy: [],
      groupBy: [],
      currentSortKey: null,
      currentFilterKey: null,
      currentGroupKey: null,
      items: defaultSequence
        ? [
            {
              id: defaultSequence.id,
              content: (
                <SequenceDropdownItem
                  sequence={defaultSequence}
                  shiftSidebar={shiftSidebar}
                />
              ),
              searchableText: defaultSequence.title,
            },
          ]
        : [],
    }),
    [t, defaultSequence, shiftSidebar],
  );

  const secondarySequencesGroup = useMemo<ItemGroupType>(
    () => ({
      groupId: 'secondary-sequences',
      groupLabel: t('reveal.missions.mission.settingsTab.secondarySequences'),
      sortBy: [],
      filterBy: [],
      groupBy: [],
      currentSortKey: null,
      currentFilterKey: null,
      currentGroupKey: null,
      items: _.chain(searchedSequences)
        .filter(({ id }) => _.contains(secondarySequenceIds || [], id))
        .map((sequence) => ({
          id: sequence.id,
          content: (
            <SequenceDropdownItem
              sequence={sequence}
              shiftSidebar={shiftSidebar}
            />
          ),
          searchableText: sequence.title,
        }))
        .value(),
    }),
    [t, searchedSequences, secondarySequenceIds, shiftSidebar],
  );

  const remainingSequences = useMemo<Sequence[]>(() => {
    const allSequences = _.filter(
      searchedSequences,
      (sequence: { id: string }) =>
        !_.contains(secondarySequenceIds || [], sequence.id) &&
        sequence.id !== defaultSequenceId,
    );

    const filterSortContext = {
      clientId,
      user,
    };

    const filteredSequences = SEQUENCE_FILTER_FUNCTIONS[filterKey](
      allSequences,
      filterSortContext,
    );

    // Fltering first probably makes it so we have less sequences to sort
    const sortedAndFilteredSequences = SEQUENCE_SORT_FUNCTIONS[sortKey](
      filteredSequences,
      filterSortContext,
    );

    return sortedAndFilteredSequences;
  }, [
    searchedSequences,
    secondarySequenceIds,
    defaultSequenceId,
    clientId,
    user,
    filterKey,
    sortKey,
  ]);

  const remainingSequencesGroup = useMemo<ItemGroupType>(
    () => ({
      groupId: 'all-sequences',
      groupLabel:
        filterKey === 'created-by-me'
          ? t('profile.contact.filtering.createdByMe')
          : t('reveal.pipelineSegmentations.allSequences'),
      sortBy: [
        {
          key: 'name',
          label: t('common.sorting.alphabetically'),
        },
        {
          key: 'author',
          label: t('profile.contact.sorting.byAuthor'),
        },
        {
          key: 'creation-date',
          label: t('common.sorting.creationDate'),
        },
        {
          key: 'last-use-by-me',
          label: t('common.sorting.lastUseByMe'),
        },
      ],
      filterBy: [
        {
          key: 'all-sequences',
          label: t('profile.contact.filtering.allSequences'),
        },
        {
          key: 'created-by-me',
          label: t('profile.contact.filtering.createdByMe'),
        },
      ],
      groupBy: [],
      currentSortKey: sortKey,
      currentFilterKey: filterKey,
      currentGroupKey: null,
      items: _.map(remainingSequences, (sequence) => ({
        id: sequence.id,
        content: (
          <SequenceDropdownItem
            sequence={sequence}
            shiftSidebar={shiftSidebar}
          />
        ),
        searchableText: sequence.title,
      })),
    }),
    [t, remainingSequences, sortKey, filterKey, shiftSidebar],
  );

  const allGroups = useMemo<ItemGroupType[]>(() => {
    const result = [] as ItemGroupType[];

    if (!_.isEmpty(defaultSequenceGroup.items)) {
      result.push(defaultSequenceGroup);
    }
    if (!_.isEmpty(secondarySequencesGroup.items)) {
      result.push(secondarySequencesGroup);
    }
    result.push(remainingSequencesGroup);

    return result;
  }, [defaultSequenceGroup, secondarySequencesGroup, remainingSequencesGroup]);

  return (
    <SelectItemDropdown
      totalItemCount={clientSequencesList?.length || 0}
      groups={allGroups}
      onItemSelected={({ id }) => onSequenceSelected(id)}
      onSearchChanged={setSearchQuery}
      searchValue={searchQuery}
      searchPlaceholder={t('sequences.search.placeholder')}
      Trigger={Trigger}
      emptyState={<SequenceDropdownEmptyState clientId={clientId} />}
      emptyStateWithSearch={
        <SequenceDropdownNoResultsState
          search={searchQuery || ''}
          setSearchFilter={setSearchQuery}
        />
      }
      onGroupSorted={(_groupId, groupSortKey) =>
        updateSortKey((groupSortKey || 'name') as SortKey)
      }
      onGroupFiltered={(_groupId, groupFilterKey) =>
        updateFilterKey((groupFilterKey || 'all-projects') as FilterKey)
      }
      className={classNames(styles.dropdown, className)}
      contentClassName={classNames({
        mini: isMinimized,
      })}
      style={style}
      {...dropdownProps}
    />
  );
};

interface SequenceDropdownWithQueryProps extends SequenceDropdownBaseProps {
  clientSequencesList: Sequence[] | null | undefined;
  queryOptions?: QueryHookOptions;
  loading?: boolean;
  clearable?: boolean;
  placeholder?: string;
  onClear?: () => void;
}

const SequenceDropdownWithQuery: FC<SequenceDropdownWithQueryProps &
  Partial<GenericDropdownProps>> = ({
  clearable = false,
  clientId,
  queryOptions = {},
  clientSequencesList,
  currentSequence,
  loading = false,
  Trigger,
  placeholder,
  onClear,
  ...rest
}) => {
  const { loading: sequencesLoading, data } = useClientSequences({
    clientId,
    queryOptions: {
      skip: Boolean(clientSequencesList),
      fetchPolicy: 'cache-first',
      ...queryOptions,
    },
  });

  const { sequences = [] } = data?.client || {};

  const { sequenceId } = currentSequence || {};

  const selectedSequence = useMemo(
    () =>
      _.findWhere(clientSequencesList || sequences, {
        id: sequenceId,
      }),
    [clientSequencesList, sequences, sequenceId],
  );

  const defaultTrigger = useMemo(
    () => ({ onClick }: TriggerProps) => (
      <button type='button' onClick={onClick} className={styles.trigger}>
        <SequenceDropdownTrigger
          clearable={clearable}
          onClear={onClear}
          currentSequence={selectedSequence}
          placeholder={placeholder}
        />
      </button>
    ),
    [onClear, clearable, selectedSequence, placeholder],
  );

  if (loading || sequencesLoading) {
    // TODO: return loading state
    return null;
  }

  return (
    <SequenceDropdown
      clientId={clientId}
      clientSequencesList={clientSequencesList || sequences}
      Trigger={Trigger || defaultTrigger}
      {...rest}
    />
  );
};

interface SequenceDropdownForJobProps extends SequenceDropdownWithQueryProps {
  jobId: string;
  autoSelectDefaultSequence?: boolean;
  onClear?: () => void;
}

export const SequenceDropdownForJob: FC<SequenceDropdownForJobProps> = ({
  jobId,
  loading: _l,
  onSequenceSelected,
  currentSequence,
  defaultSequenceId,
  secondarySequenceIds,
  autoSelectDefaultSequence = false,
  onClear,
  clearable,
  ...rest
}) => {
  const { data: jobData, loading: loadingJobData } = useQuery(
    GET_JOB_DEFAULT_SEQUENCES,
    {
      variables: { searchPoolId: 'reveal', jobId },
      skip: !jobId || !!defaultSequenceId || !!secondarySequenceIds,
    },
  );

  const {
    defaultSequenceId: innerDefaultSequenceId,
    secondarySequenceIds: innerSecondarySequenceIds,
  } = useMemo(() => {
    if (!jobData) {
      return {};
    }

    const attachedSequences = jobData?.searchPool?.job?.attachedSequences;
    const defaultSequence = _.findWhere(attachedSequences, {
      isDefault: true,
    });
    const secondarySequences = _.filter(
      attachedSequences,
      ({ sequenceId }) => sequenceId !== defaultSequence?.sequenceId,
    );

    return {
      defaultSequenceId: defaultSequence?.sequenceId,
      secondarySequenceIds: secondarySequences.map(
        ({ sequenceId }) => sequenceId,
      ),
    };
  }, [jobData]);

  useEffect(() => {
    // Autoselect a sequence only if:
    // - we have a default sequence (provided by user or by query)
    // - user has opted for auto-select
    // - and we have no currently selected sequence (which means this only works the first time)
    if (
      autoSelectDefaultSequence &&
      (defaultSequenceId || innerDefaultSequenceId) &&
      !currentSequence?.sequenceId
    ) {
      onSequenceSelected(defaultSequenceId || innerDefaultSequenceId);
    }
  }, [
    defaultSequenceId,
    autoSelectDefaultSequence,
    innerDefaultSequenceId,
    onSequenceSelected,
    currentSequence,
  ]);

  return (
    <SequenceDropdownWithQuery
      clearable={clearable}
      onClear={onClear}
      currentSequence={currentSequence}
      onSequenceSelected={onSequenceSelected}
      defaultSequenceId={defaultSequenceId || innerDefaultSequenceId}
      secondarySequenceIds={secondarySequenceIds || innerSecondarySequenceIds}
      loading={loadingJobData}
      {...rest}
    />
  );
};

interface SequenceDropdownTriggerProps {
  currentSequence?: Sequence;
  clearable?: boolean;
  placeholder?: string;
  onClear?: () => void;
}

export const SequenceDropdownTrigger: FC<SequenceDropdownTriggerProps> = ({
  currentSequence,
  clearable = false,
  placeholder,
  onClear,
}) => {
  const { isMinimized } = useMinimizedView();
  return (
    <DropdownContainer>
      <DropdownTrigger clearable={clearable} onClear={onClear}>
        {!currentSequence && (
          <span className={styles.triggerPlaceholder}>{placeholder}</span>
        )}
        {currentSequence && (
          <div className={styles.defaultTrigger}>
            <span>{currentSequence.title}</span>
            {!isMinimized && (
              <ProfileRowSequence
                isStandalone
                sequences={currentSequence?.contactFlow.sequences}
                currentSequenceInfo={currentSequence}
                loading={false}
                nextInteraction={null}
                mini
              />
            )}
          </div>
        )}
      </DropdownTrigger>
    </DropdownContainer>
  );
};

export default SequenceDropdownWithQuery;
