import _ from 'underscore';
import { useCallback, useMemo } from 'react';

import { getFullname } from '@/common/helpers/person';
import { useUserSelectorPreferences } from '@/graphql/hooks/users/useUserDisplayPreferences';
import { Sequence } from '@/types/sequence';

export type SortKey = 'author' | 'name' | 'creation-date' | 'last-use-by-me';
export type FilterKey = 'created-by-me' | 'all-sequences';

interface Context {
  user?: { email: string };
}

export type FilterOrSortFunction = (
  sequences: Sequence[],
  context?: Context,
) => Sequence[];

export const SEQUENCE_FILTER_FUNCTIONS: Record<
  FilterKey,
  FilterOrSortFunction
> = {
  'all-sequences': (sequences) => {
    return sequences;
  },
  'created-by-me': (sequences, context) => {
    const user = context?.user;
    if (!user) {
      return sequences;
    }

    return _.filter(sequences, (sequence) => {
      if (!sequence.author) {
        // since there is no author we hide the sequence by default
        // Users will be able to find them in "All sequences"
        return false;
      }

      const {
        author: { email },
      } = sequence;
      return email === user.email;
    });
  },
};

export const SEQUENCE_SORT_FUNCTIONS: Record<SortKey, FilterOrSortFunction> = {
  author: (sequences) => {
    const authorName = (s: Sequence): string => {
      if (!s.author) {
        return '';
      }

      return getFullname(s.author);
    };

    return sequences.sort((s1, s2) => {
      const s1AuthorName = authorName(s1);
      const s2AuthorName = authorName(s2);

      if (!s1AuthorName) {
        return -1;
      }

      if (!s2AuthorName) {
        return 1;
      }

      return authorName(s1).localeCompare(authorName(s2));
    });
  },
  name: (sequences) => {
    return sequences.sort((s1, s2) => {
      if (!s1.title) {
        return -1;
      }

      if (!s2.title) {
        return 1;
      }

      return s1.title.localeCompare(s2.title);
    });
  },
  'creation-date': (sequences) => {
    return sequences.sort((s1, s2) => {
      const s1CreationDate: Date = new Date(s1.creationDate || '');
      const s2CreationDate: Date = new Date(s2.creationDate || '');
      return s2CreationDate.getTime() - s1CreationDate.getTime();
    });
  },
  'last-use-by-me': (sequences, context) => {
    const { user } = context || {};
    if (!user) {
      return sequences;
    }

    const { email } = user;

    if (!email) {
      return sequences;
    }

    return sequences.sort((s1, s2) => {
      const lastUsedByMeS1 = _.findWhere(s1.lastUseByAuthor || [], {
        authorEmail: email,
      });

      const lastUsedByMeS2 = _.findWhere(s2.lastUseByAuthor || [], {
        authorEmail: email,
      });

      if (lastUsedByMeS1 === undefined && lastUsedByMeS2 === undefined) {
        return 0;
      }

      if (lastUsedByMeS2 === undefined) {
        return -1;
      }

      if (lastUsedByMeS1 === undefined) {
        return 1;
      }

      return lastUsedByMeS2.timestamp - lastUsedByMeS1.timestamp;
    });
  },
};

interface UseSequenceDropdownPreferencesResult {
  filterKey: FilterKey;
  sortKey: SortKey;
  updateFilterKey: (key: FilterKey) => void;
  updateSortKey: (key: SortKey) => void;
}

export const useSequenceDropdownPreferences = (): UseSequenceDropdownPreferencesResult => {
  const {
    userSelectorPreferences,
    updateUserSelectorPreferences,
  } = useUserSelectorPreferences({
    selectorId: 'profile-modal-sequence-selector',
    defaultValues: {
      selectorId: 'profile-modal-sequence-selector',
      filteringOptions: {
        filters: [
          {
            key: 'all-sequences',
            filter: {},
          },
        ],
      },
      sortingOptions: {
        sortBy: [
          {
            key: 'creation-date',
            order: 'descending',
          },
        ],
      },
    },
  });

  const sortKey = useMemo<SortKey>(() => {
    if (!userSelectorPreferences) {
      return 'creation-date';
    }

    const userSortKey = userSelectorPreferences.sortingOptions?.sortBy[0]
      ?.key as SortKey;

    // Key is not supported, could be a migration
    if (userSortKey && !(userSortKey in SEQUENCE_SORT_FUNCTIONS)) {
      return 'creation-date';
    }

    return userSortKey || 'creation-date';
  }, [userSelectorPreferences]);

  const filterKey = useMemo<FilterKey>(() => {
    if (!userSelectorPreferences) {
      return 'all-sequences';
    }

    const userFilterKey = userSelectorPreferences.filteringOptions?.filters[0]
      ?.key as FilterKey;

    if (userFilterKey && !(userFilterKey in SEQUENCE_FILTER_FUNCTIONS)) {
      return 'all-sequences';
    }

    return userFilterKey || 'all-sequences';
  }, [userSelectorPreferences]);

  const updateSortKey = useCallback(
    (key) => {
      updateUserSelectorPreferences({
        sortingOptions: {
          sortBy: [
            {
              key: key || 'creation-date',
              order: 'descending',
            },
          ],
        },
      });
    },
    [updateUserSelectorPreferences],
  );

  const updateFilterKey = useCallback(
    (key) => {
      updateUserSelectorPreferences({
        filteringOptions: {
          filters: [
            {
              key: key || 'all-projects',
              filter: {},
            },
          ],
        },
      });
    },
    [updateUserSelectorPreferences],
  );

  return {
    filterKey,
    sortKey,
    updateFilterKey,
    updateSortKey,
  };
};
