import React from 'react';
import _, { compose } from 'underscore';

import createActionInputFromAction from '../../../../../common/contactFlow/createActionInputFromAction';
import getPermissionsAndNudgesAtIndex from '../../../../../common/contactFlow/getPermissionsAndNudgesAtIndex';
import withClientPermissions from '../../../../../hocs/clients/withClientPermissions';

import FollowupButton from '../Email/FollowupButton';
import EmailAction from '../Actions/EmailAction';
import withAddProfileContactFlowAction from '../../../../../hocs/profiles/withAddProfileContactFlowAction';
import withDeleteProfileContactFlowAction from '../../../../../hocs/profiles/withDeleteProfileContactFlowAction';
import withUpdateProfileContactFlowAction from '../../../../../hocs/profiles/withUpdateProfileContactFlowAction';
import withMarkProfileContactFlowActionAsCompleted from '../../../../../hocs/profiles/withMarkProfileContactFlowActionAsCompleted';

const DisplayedSequence = ({
  sequence,
  profileId,
  profile,
  t,
  addProfileContactFlowAction,
  deleteProfileContactFlowAction,
  updateProfileContactFlowAction,
  markProfileContactFlowActionAsCompleted,
  emailApi,
  user,
  offer,
  clientId,
  offerId,
  stepId,
  searchText,
  getNextProfileId,
  isSearchResultProfile,
  onSend,
  onChangeStep,
  onChangeProfile,
  handleMarkProfileAsInMove,
  handleUnmarkProfileAsInMove,
  getProfilesInMove,
  templates,
  permissions,
}) => {
  if (_.isEmpty(sequence)) {
    return null;
  }
  const maxNbFollowups = permissions?.maxNbFollowups ?? 1;
  const followupPosition = (sequence?.actions || []).length;

  const actionsToCome = _.compact(
    _.map((sequence || {}).actions, (action, index) => {
      if (action?.completion?.isCompleted) {
        return null;
      }
      return {
        ...action,
        indexInSequence: index,
      };
    }),
  );

  const addAction = ({ index, actionToInsert }) => {
    const actionInput = createActionInputFromAction({ action: actionToInsert });
    return addProfileContactFlowAction({
      id: profileId,
      sequenceId: sequence.id,
      contactFlowActionInput: actionInput,
      actionIndex: index,
    });
  };

  const onDeleteAction = ({ actionId }) => {
    return deleteProfileContactFlowAction({
      id: profileId,
      sequenceId: sequence.id,
      actionId,
    });
  };

  const onUpdateAction = ({ actionId, updatedAction }) => {
    const actionInput = createActionInputFromAction({
      action: _.omit(updatedAction, 'indexInSequence'),
    });
    return updateProfileContactFlowAction({
      id: profileId,
      sequenceId: sequence.id,
      actionId,
      contactFlowActionInput: actionInput,
    });
  };

  const onMarkActionAsCompleted = ({ actionId }) => {
    return markProfileContactFlowActionAsCompleted({
      id: profileId,
      sequenceId: sequence.id,
      actionId,
    });
  };

  if (sequence?.completion?.isCompleted) {
    return (
      <div className='completed-sequence-label'>
        {t('contactFlow.sequences.currentSequenceCompleted')}
      </div>
    );
  }

  const onAddFollowup = () => {
    const actionToInsert = {
      type: 'send-email-action',
      trigger: {
        type: 'delay-after-action',
        delay: { value: 5, unit: 'working-day' },
      },
      message: {
        subject: '',
        body: '',
      },
    };

    addAction({ index: (sequence?.actions || []).length, actionToInsert });
  };

  return (
    <div className='email-content'>
      {_.map(actionsToCome, (action, indexInActionsToCome) => {
        const permissionsAndNudges = getPermissionsAndNudgesAtIndex({
          actions: sequence.actions,
          index: action.indexInSequence,
          editType: 'update',
          t,
        });
        if (
          !_.contains(
            ['send-email-action', 'linkedin-send-request'],
            action.type,
          )
        ) {
          console.error(
            `HireSweet Classic - unknown action type ${action.type}`,
          );
          return null;
        }
        return (
          <div key={action.actionId}>
            {action.type === 'send-email-action' && (
              <EmailAction
                sequenceId={sequence?.id}
                action={action}
                indexInActionsToCome={indexInActionsToCome}
                profile={profile}
                onDeleteAction={() =>
                  onDeleteAction({ actionId: action.actionId })
                }
                onUpdateAction={({ updatedAction }) =>
                  onUpdateAction({ actionId: action.actionId, updatedAction })
                }
                onMarkActionAsCompleted={() =>
                  onMarkActionAsCompleted({ actionId: action.actionId })
                }
                emailApi={emailApi}
                permissionsAndNudges={permissionsAndNudges}
                t={t}
                user={user}
                offer={offer}
                clientId={clientId}
                offerId={offerId}
                stepId={stepId}
                searchText={searchText}
                getNextProfileId={getNextProfileId}
                isSearchResultProfile={isSearchResultProfile}
                onSend={onSend}
                onChangeStep={onChangeStep}
                onChangeProfile={onChangeProfile}
                handleMarkProfileAsInMove={handleMarkProfileAsInMove}
                handleUnmarkProfileAsInMove={handleUnmarkProfileAsInMove}
                getProfilesInMove={getProfilesInMove}
                templates={templates}
              />
            )}
          </div>
        );
      })}
      {maxNbFollowups >= followupPosition &&
        (stepId === 'contacted' || stepId === 'pending') && (
          <FollowupButton
            onAddFollowup={onAddFollowup}
            followupPosition={followupPosition}
          />
        )}
    </div>
  );
};

export default compose(
  withAddProfileContactFlowAction,
  withDeleteProfileContactFlowAction,
  withUpdateProfileContactFlowAction,
  withMarkProfileContactFlowActionAsCompleted,
  withClientPermissions,
)(DisplayedSequence);
