import React, { createContext, ReactNode, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import GenericModal from '@/components/Common/GenericModal';
import GenericButton from '@/components/Common/GenericButton';
import useUserSenders from '@/graphql/hooks/users/useUserSenders';
import useSharedMessagingAccounts from '@/graphql/hooks/clients/useSharedServiceAccount';

interface AddProfileToSequenceContextType {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  checkIfProfileCanBeAddedToSequence: (sequence: any) => boolean;
}

export const AddProfileToSequenceContext = createContext<
  AddProfileToSequenceContextType
>({
  modalOpen: false,
  setModalOpen: (_value: boolean) => ({}),
  checkIfProfileCanBeAddedToSequence: (_sequence: any) => true,
});

const AddProfileToSequenceContextProvider = ({ children }: {
  children?: ReactNode
}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const { sharedMessagingAccounts } = useSharedMessagingAccounts();
  const { data: userSendersData } = useUserSenders({
    granted: true,
    owned: true,
  });

  const checkIfProfileCanBeAddedToSequence = (sequence: any) => {
    const actions = _.flatten(
      _.pluck(sequence?.contactFlow?.sequences, 'actions'),
    );
    const senders = _.compact(_.pluck(
      _.map(actions, (action) => action.message),
      'senderId',
    ));

    const sendersDifference = _.compact(
      _.difference(
        senders,
        _.pluck(
          [...userSendersData.user.senders, ...sharedMessagingAccounts],
          'id',
        ),
      ),
    );

    return _.isEmpty(sendersDifference);
  };

  return (
    <AddProfileToSequenceContext.Provider
      value={{ modalOpen, setModalOpen, checkIfProfileCanBeAddedToSequence }}
    >
      {children}
      <GenericModal
        open={modalOpen}
        size='tiny'
        onClose={() => setModalOpen(false)}
      >
        <Modal.Header>
          {t('reveal.profileModal.warnings.noPermissionsForSenderTitle')}
        </Modal.Header>

        <Modal.Content>
          {t('reveal.profileModal.warnings.noPermissionsForSender')}
        </Modal.Content>

        <Modal.Actions>
          <GenericButton
            size='big'
            primacy='primary'
            onClick={() => setModalOpen(false)}
          >
            {t('common.ok')}
          </GenericButton>
        </Modal.Actions>
      </GenericModal>
    </AddProfileToSequenceContext.Provider>
  );
};

export default AddProfileToSequenceContextProvider;
