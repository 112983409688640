import React, { FC, useMemo } from 'react';
import { OptionTypeBase } from 'react-select';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import GenericSelect from '@/components/Common/GenericSelect';
import { archiveReasonCategories } from '@/types/statistics/pipelineActions';
import useArchiveReasons from '@/graphql/useArchiveReasons';
import useClientId from '@/hooks/router/useClientId';

import styles from './ArchiveReasonMultiSelect.module.less';

interface ArchiveReasonMultiSelectProps {
  defaultValues: string[];
  onValues: (value: string[]) => void;
}

const ArchiveReasonMultiSelect: FC<ArchiveReasonMultiSelectProps> = ({
  defaultValues,
  onValues,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { archiveReasons } = useArchiveReasons({ clientId });

  const optionsMap = useMemo(() => {
    const result = {} as Record<string, OptionTypeBase>;
    _.each(archiveReasonCategories, (value) => {
      result[value] = {
        value,
        label: t(`settings.archiveReasonsSettings.categories.${value}`),
      };
    });
    _.each(archiveReasons || [], ({ id, title }) => {
      const value = `#${id}`;
      result[value] = {
        value,
        label: title,
      };
    });
    return result;
  }, [t, archiveReasons]);

  const optionsArray = useMemo(() => _.values(optionsMap), [optionsMap]);

  const selectDefaultValues = useMemo(
    () => _.map(defaultValues, (id) => optionsMap[id]),
    [optionsMap, defaultValues],
  );

  return (
    <GenericSelect
      isMulti
      options={optionsArray}
      defaultValue={selectDefaultValues}
      placeholder={t('reveal.missions.mission.profiles.archiveReasonFilter')}
      onChange={(selectedValues) =>
        onValues(_.map(selectedValues || [], ({ value }) => value))
      }
      className={styles.select}
    />
  );
};

export default ArchiveReasonMultiSelect;
