import React from 'react';
import {
  ATStype,
  ATS_TIMELINE_LOGOS_BY_TYPE,
  ATS_LOGOS_BY_TYPE,
} from '@/common/reveal';
import { CommentBody } from '@/containers/PluginProfile/components/Comment';
import TASK_ICONS from '../TaskIcons';
import AuthorAndDate from '../AuthorAndDate';

interface GenericAtsTimelineItemProps {
  profile: any;
  timelineItem: any;
}

const GenericAtsTimelineItem: React.FC<GenericAtsTimelineItemProps> = ({
  profile,
  timelineItem,
}) => {
  if (
    timelineItem?.ats === 'greenhouse' ||
    timelineItem?.ats === 'lever' ||
    timelineItem?.ats === 'teamtailor' ||
    timelineItem?.ats === 'recruitee' ||
    timelineItem?.ats === 'ashby' ||
    timelineItem?.ats === 'pinpoint'
  ) {
    return <ATSTimelineItem profile={profile} timelineItem={timelineItem} />;
  }
  if (timelineItem?.ats === 'recruitcrm') {
    return (
      <RecruitcrmTimelineItem profile={profile} timelineItem={timelineItem} />
    );
  }

  const date = timelineItem.date
    ? +timelineItem.date
    : profile?.contactData?.creationTimestamp;
  const atsType: ATStype = timelineItem?.ats;
  const atsLogo = ATS_TIMELINE_LOGOS_BY_TYPE[atsType];
  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{
                backgroundColor: TASK_ICONS.genericATS.color,
              }}
              className='icon'
            >
              <i className={`${TASK_ICONS.genericATS.icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            {(timelineItem.action?.subject
              ? `${timelineItem.action?.subject}-`
              : '') + timelineItem.action?.body}
            <AuthorAndDate
              timelineItem={timelineItem}
              date={date}
              atsLogo={atsLogo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ATSTimelineItem: React.FC<GenericAtsTimelineItemProps> = ({
  profile,
  timelineItem,
}) => {
  const date = timelineItem.date
    ? +timelineItem.date
    : profile?.contactData?.creationTimestamp;
  const atsType: ATStype = timelineItem?.ats;
  const atsLogo = ATS_LOGOS_BY_TYPE[atsType];
  const taskIcon = getLogoFromATSAction(
    timelineItem?.ats,
    timelineItem?.action,
  );
  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{
                backgroundColor: TASK_ICONS[taskIcon]?.color,
              }}
              className='icon'
            >
              <i className={`${TASK_ICONS[taskIcon]?.icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            <div
              dangerouslySetInnerHTML={{
                __html: getTextFromATSAction(
                  profile.resumeData,
                  timelineItem?.action?.body,
                ),
              }}
            />
            <AuthorAndDate
              timelineItem={timelineItem}
              date={date}
              atsLogo={atsLogo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const RecruitcrmTimelineItem: React.FC<GenericAtsTimelineItemProps> = ({
  profile,
  timelineItem,
}) => {
  const date = timelineItem.date
    ? +timelineItem.date
    : profile?.contactData?.creationTimestamp;

  const atsType: ATStype = timelineItem?.ats;
  const atsLogo = ATS_LOGOS_BY_TYPE[atsType];
  const taskIcon = getLogoFromATSAction(
    timelineItem?.ats,
    timelineItem?.action,
  );

  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{
                backgroundColor: TASK_ICONS[taskIcon]?.color,
              }}
              className='icon'
            >
              <i className={`${TASK_ICONS[taskIcon]?.icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            <div
              dangerouslySetInnerHTML={{
                __html: getTextFromATSAction(
                  profile.resumeData,
                  timelineItem?.action?.subject,
                ),
              }}
            />
            <AuthorAndDate
              timelineItem={timelineItem}
              date={date}
              atsLogo={atsLogo}
            />
          </div>
        </div>
        {timelineItem.action.body && (
          <div className='card-content'>
            <CommentBody content={timelineItem.action.body} />
          </div>
        )}
      </div>
    </div>
  );
};

const LEVER_LOGO_MAPPING: Record<string, string> = {
  'was moved to stage': 'addedToMission',
  'was added to stage': 'addedToMission',
  'was added by': 'addedToMission',
  'was archived with reason': 'removedFromMission',
};

// const LEVER_WORDING_MAPPING: Record<string, string> = {
//   'was moved to stage':
//     'profile.contact.timeline.missionStageUpdated.descriptionNoMission',
//   'was added by':
//     'profile.contact.timeline.missionStageUpdated.descriptionNoMission',
//   'was archived with reason':
//     'profile.contact.timeline.missionStageUpdated.descriptionNoMission',
// };

const TEAMTAILOR_LOGO_MAPPING: Record<string, string> = {
  'Rejected by': 'removedFromMission',
  'was sourced by': 'sourced',
  'was moved': 'addedToMission',
  'Unrejected by': 'addedToMission',
};

const GREENHOUSE_LOGO_MAPPING: Record<string, string> = {
  'was converted to a prospect': 'ats-move',
  'Rejected from': 'removedFromMission',
  'altered this person': 'ats-changed',
  'was moved into': 'addedToMission',
};

const RECRUITEE_LOGO_MAPPING: Record<string, string> = {
  'added candidate': 'addedToMission',
  'added Tags to the candidate': 'ats-changed',
  'moved candidate': 'addedToMission',
  'added a task': 'addedToMission',
  'gave a Yes': 'evaluatedYes',
  'gave a No': 'evaluatedNo',
  'updated profile field': 'updated',
  'uploaded the file': 'uploaded',
  'added follower(s) to candidate': 'addedFollower',
  'requalified candidate': 'addedToMission',
  'disqualified candidate': 'removedFromMission',
  'scheduled a(n)': 'calendarEvent',
  'completed the task': 'taskCompleted',
  'applied for the job': 'applied',
};

const ATS_TYPE_LOGOS_MAP: Record<string, Record<string, string>> = {
  greenhouse: GREENHOUSE_LOGO_MAPPING,
  lever: LEVER_LOGO_MAPPING,
  teamtailor: TEAMTAILOR_LOGO_MAPPING,
  recruitee: RECRUITEE_LOGO_MAPPING,
  recruitcrm: RECRUITEE_LOGO_MAPPING,
  ashby: { 'was moved to': 'addedToMission' },
  pinpoint: { 'was moved to': 'addedToMission' },
};

// const ATS_TYPE_WORDING_MAP: Record<string, Record<string, string>> = {
//   lever: LEVER_WORDING_MAPPING,
// };

const getLogoFromATSAction = (
  ats: string,
  action: { subject: string; body: string },
) => {
  const text = `${action?.subject} ${action?.body}`.replaceAll('  ', ' ');
  const logoMap = ATS_TYPE_LOGOS_MAP[ats];
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(logoMap)) {
    if (text.indexOf(key) !== -1) {
      return logoMap[key];
    }
  }
  return 'genericATS';
};

const getTextFromATSAction = (
  resumeData: { firstname: string; lastname: string },
  text: string,
) => {
  const finalText = text
    .replace('moved to null', 'moved to a new stage')
    .replace(
      `${resumeData.firstname} ${resumeData?.lastname}`,
      `<b>${resumeData.firstname} ${resumeData?.lastname}</b>`,
    )
    .replace(` ${resumeData.firstname} `, ` <b>${resumeData.firstname}</b> `)
    .replace(` ${resumeData.lastname} `, ` <b>${resumeData.lastname}</b> `)
    .replace(' "', " <span class='ui label'>")
    .replace('" ', '</span> ')
    .replace('".', '</span>.')
    .replace('"', '</span>');
  return finalText;
  // const textMap = ATS_TYPE_WORDING_MAP[ats];
  // if (!textMap) {
  //   return 'TO BE DONE';
  // }

  // // eslint-disable-next-line no-restricted-syntax
  // for (const key of Object.keys(textMap)) {
  //   if (text.indexOf(key) !== -1) {
  //     return textMap[key];
  //   }
  // }
};

export default GenericAtsTimelineItem;
