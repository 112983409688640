import React from 'react';
import { useTranslation } from 'react-i18next';
import { MarketplaceUnbiasedModeField } from '@/components/UnbiasedMode/UnbiasedModeField';
import { BlurLine } from '@/components/UnbiasedMode/BlurLine';
import Thumbnail from '../../../../../components/Thumbnail';
import { formatDate } from '../../../../../common/dates';
import TASK_ICONS from '../TaskIcons';
import AuthorAndDate from '../AuthorAndDate';

const SeemsQualified = ({ firstname, unbiasedModeEnabled = false }) => {
  const { t } = useTranslation();
  return (
    <span>
      <MarketplaceUnbiasedModeField
        enabled={unbiasedModeEnabled}
        name='hideFullName'
        fallback={
          <BlurLine
            style={{
              display: 'inline-block',
              maxWidth: '70px',
              marginRight: '8px',
            }}
          />
        }
      >
        {firstname}{' '}
      </MarketplaceUnbiasedModeField>
      {t('profile.contact.timeline.seemsQualified')}
    </span>
  );
};

const ClassicSourcedTimelineItem = ({ profile }) => {
  const { t } = useTranslation();

  const unbiasedModeEnabled = profile?.resumeData.step === 'pending';

  return (
    <div
      className='timeline-card-mail-wrapper classic-item'
      style={{ bottom: 0 }}
    >
      <div className='content'>
        <div className='header'>
          <Thumbnail
            unbiasedModeEnabled={unbiasedModeEnabled}
            link={profile?.resumeData?.photoLink}
            firstname={profile?.resumeData?.firstname}
            lastname={profile?.resumeData?.lastname}
          />
          <div className='author'>
            <div className='date'>
              {profile?.contactData?.creationTimestamp &&
                formatDate(profile?.contactData?.creationTimestamp, t)}
            </div>
            <span>
              <SeemsQualified
                unbiasedModeEnabled={unbiasedModeEnabled}
                firstname={profile?.resumeData?.firstname}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const RevealSourcedTimelineItem = ({ profile, author }) => {
  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{ backgroundColor: TASK_ICONS.sourced.color }}
              className='icon'
            >
              <i className={`${TASK_ICONS.sourced.icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            <SeemsQualified firstname={profile?.resumeData?.firstname} />
            <AuthorAndDate
              author={author}
              date={profile?.contactData?.creationTimestamp}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const SourcedTimelineItem = ({ profile, applicationMode, author }) => {
  if (applicationMode === 'classic') {
    return <ClassicSourcedTimelineItem profile={profile} />;
  }
  return <RevealSourcedTimelineItem profile={profile} author={author} />;
};

export default SourcedTimelineItem;
