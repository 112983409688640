import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Criteria from '../../graphql/fragments/Criteria';

export const query = gql`
  query getPostingDefaults($id: ID!) {
    offer(id: $id) {
      id
      title
      criteria {
        ...Criteria
      }
      foldering {
        department {
          id
        }
        section {
          id
        }
      }
    }
  }
  ${Criteria}
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.offerId,
    },
  }),
  props: ({ data: { loading, error, offer } }) => ({
    loadingPostingDefaults: loading,
    errorPostingDefaults: error,
    postingDefaults: offer,
  }),
  skip: (ownProps) => !ownProps.offerId,
});
