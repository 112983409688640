import React from 'react';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { Popup } from 'semantic-ui-react';
import _ from 'underscore';
import { HeatMapMapping, HeatMapValue } from './typings';
import styles from './LinearHeatMap.module.less';

const dateFormat = 'MMM Do';

interface HeatMapItemProps {
  mapping: HeatMapMapping;
  value: HeatMapValue;
  labeli18nKey: string;
}
const HeatMapItem: React.FC<HeatMapItemProps> = ({
  mapping,
  value,
  labeli18nKey,
}) => {
  const shade =
    _.findLastIndex(mapping, (mappingValue) => value.value >= mappingValue) + 1;
  return (
    <Popup
      trigger={
        <div className={styles['heatmap-item']}>
          <div className={styles[`shade-${shade}`]} />
        </div>
      }
      position='bottom right'
      flowing
      basic
    >
      <Popup.Header className={styles['popup-header']}>
        {`${moment(value.dateRange.startDate).format(dateFormat)} - ${moment(
          value.dateRange.endDate,
        ).format(dateFormat)}`}
      </Popup.Header>
      <Popup.Content className={styles['popup-content']}>
        <Trans count={value.value} i18nKey={`translations:${labeli18nKey}`}>
          <span className={styles['popup-content-count']}>
            {({ count: value.value } as unknown) as React.ReactNode}
          </span>
          contacts
        </Trans>
      </Popup.Content>
    </Popup>
  );
};

export default HeatMapItem;
