/* eslint-disable max-len */

import React from 'react';

import styles from './svg.module.less';

const Contacts: React.FC = () => (
  <svg
    className={styles.svgIcon}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1 5C1 2.79086 2.79086 1 5 1H11C13.2091 1 15 2.79086 15 5V11C15 13.2091 13.2091 15 11 15H5C2.79086 15 1 13.2091 1 11V5ZM10 6C10 7.10457 9.10457 8 8 8C6.89543 8 6 7.10457 6 6C6 4.89543 6.89543 4 8 4C9.10457 4 10 4.89543 10 6ZM11.7688 10.7143C12.2425 11.2576 11.7758 12 11.055 12H4.94494C4.22418 12 3.75752 11.2576 4.23117 10.7143C4.30596 10.6285 4.38376 10.5452 4.46446 10.4645C4.92875 10.0002 5.47995 9.63188 6.08658 9.3806C6.69321 9.12933 7.34339 9 8 9C8.6566 9 9.30678 9.12933 9.91341 9.3806C10.52 9.63188 11.0712 10.0002 11.5355 10.4645C11.6162 10.5452 11.694 10.6285 11.7688 10.7143Z'
      fill='currentColor'
    />
  </svg>
);

export default Contacts;
