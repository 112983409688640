import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import useClientPermissions from '../../../graphql/hooks/clients/useClientPermissions';
import { useNavApplicationMode } from './NavSwitch.hook';

const pathsToSwitchToClassic = [
  // '/client/:clientId/dashboard',
  '/client/:clientId/offers/:offerId/profiles/:profileId',
  '/client/:clientId/jobs/:offerId/profiles/:profileId',
  '/client/:clientId/offers/:offerId/profiles',
  '/client/:clientId/jobs/:offerId/profiles',
  '/client/:clientId/jobs/:offerId',
  '/client/:clientId/jobs/:offerId/criteria',
  '/client/:clientId/jobs/:offerId/sequences',
  '/client/:clientId/jobs/:offerId/sequence',
  '/client/:clientId/jobs/:offerId/reports',
  '/client/:clientId/jobs/:offerId/jobPostings',
  '/client/:clientId/offers/:offerId',
  '/client/:clientId/discover',
  '/client/:clientId/discover/:tabId',
  '/client/:clientId/candidates',
  '/client/:clientId/offers/:offerId/:pageId',
  '/client/:clientId/jobs/:offerId/:pageId',
];

const pathsToSwitchToReveal = ['/client/:clientId/reveal', '/client/:clientId/reveal/:tabId'];

// Since the header is really high in the tree, we can't know if we should switch on ParameterView.
// So we use its pageId param to discriminate routes.
const parameterViewPath = '/client/:clientId/:pageId';
const pageIdsToSwitchToClassic = ['jobs'];
const pageIdsToSwitchToReveal = ['sequences'];

const shouldSwitch = (applicationMode, { path, params }) => {
  switch (applicationMode) {
    case 'classic':
      if (pathsToSwitchToReveal.includes(path)) {
        // console.log('switch to reveal', path);
        return true;
      }
      if (path === parameterViewPath && pageIdsToSwitchToReveal.includes(params.pageId)) {
        // console.log('switch to reveal', path);
        return true;
      }
      break;
    case 'reveal':
      if (pathsToSwitchToClassic.includes(path)) {
        // console.log('switch to classic', path);
        return true;
      }
      if (path === parameterViewPath && pageIdsToSwitchToClassic.includes(params.pageId)) {
        // console.log('switch to classic', path);
        return true;
      }
      break;
    default:
      break;
  }
  return false;
};

/**
 * Updates the applicationMode (classic or reveal) depending on the url we're at. Reveal URLs switch to reveal,
 * classic urls switch to classic. Common urls (settings) don't switch (default behaviour)
 */
const useApplicationSwitcher = ({ clientId }) => {
  const { path, params } = useRouteMatch();
  const {
    loading: userLoading,
    error: userError,
    applicationMode,
    onlyReveal,
    updateApplicationMode,
    updateLoading,
  } = useNavApplicationMode();
  const { loading: permissionsLoading, data: permissionsData, error: permissionsError } = useClientPermissions(
    clientId,
  );

  useEffect(() => {
    if (userLoading || userError || updateLoading || permissionsLoading || permissionsError) {
      return;
    }

    const { reveal } = permissionsData?.client?.permissions || {};
    const canSwitch = reveal && !onlyReveal;
    if (!canSwitch) {
      return;
    }

    if (shouldSwitch(applicationMode, { path, params })) {
      updateApplicationMode(applicationMode === 'classic' ? 'reveal' : 'classic');
    }
    // eslint-disable-next-line
  }, [
    path,
    userLoading,
    userError,
    applicationMode,
    onlyReveal,
    updateLoading,
    permissionsLoading,
    permissionsError,
    updateApplicationMode,
  ]);
};

// all is done via the hook so nothing to render
const ApplicationModeAutoSwitcher = ({ clientId }) => {
  useApplicationSwitcher({ clientId });
  return null;
};

export default ApplicationModeAutoSwitcher;
