import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import withClientAchievements from '../../../../../hocs/clients/withClientAchievements';
import AchievementRow from './AchievementRow';

import './Achievements.css';

const Achievements = ({
  clientId,
  mainEvents,
  mainEventsLoading,
  mainEventsError,
  t,
}) => {
  if (mainEventsError || mainEventsLoading) {
    return null;
  }

  const last10MainEvents = _.last(mainEvents, 10).reverse();

  if (_.isEmpty(last10MainEvents)) {
    return (
      <div className='section'>
        <div className='header'>
          <h3>{t('analytics.headers.achievements')}</h3>
        </div>
        <br />
        <div className='achievements-container'>
          <div className='achievements-descriptor'>
            {t('analytics.headers.achievementsDescriptorEmpty')}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='section'>
      <div className='header'>
        <h3>{t('analytics.headers.achievements')}</h3>
      </div>
      <br />
      <div className='achievements-container'>
        <div className='achievements-descriptor'>
          {t('analytics.headers.achievementsDescriptor')}
        </div>
        <Table className='achievements-table' basic='very'>
          <Table.Body>
            {_.map(last10MainEvents, (mainEvent, index) => (
              <AchievementRow
                key={index}
                clientId={clientId}
                mainEvent={mainEvent}
                t={t}
              />
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default compose(
  withTranslation('translations'),
  withClientAchievements,
)(Achievements);
