import React from 'react';
import _ from 'underscore';
import { Loader } from 'semantic-ui-react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useClientRevealProjects from '@/graphql/hooks/clients/useClientRevealProjects';
import { IRevealConnector } from '@/graphql/fragments/RevealConnector';
import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import { PrimaryCobalt60 } from '@/less/colors';

import {
  ashbyLogo,
  greenhouseLogo,
  leverLogo,
  recruiteeLogo,
  smartrecruitersLogo,
  teamtailorLogo,
  welcomekitLogo,
  workableLogo,
  boondmanagerLogo,
  recruitcrmLogo,
  successfactorsLogo,
  pinpointLogo,
} from '../Integrations/logos';
import IntegrationLink from '../Integrations/components/IntegrationLink';
import RevealIntegration from './RevealIntegration';
import SettingsLayout from '../../SettingsLayout';

interface ClientRevealIntegrationsProps {
  clientId: string;
}

const ClientRevealIntegrations: React.FC<ClientRevealIntegrationsProps> = ({
  clientId,
}) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { data, loading, error } = useClientRevealProjects(clientId);
  const revealProjects = data?.client?.revealProjects;
  const connectors = revealProjects?.[0].connectors || [];
  const { applicationMode, updateApplicationMode } = useNavApplicationMode();
  const permissions = useClientPermissions(clientId).data?.client?.permissions;

  if (applicationMode !== 'reveal') {
    updateApplicationMode('reveal');
  }

  if (loading || error) {
    return (
      <SettingsLayout
        currentPage='integrations'
        clientId={clientId}
        className='integrations-view settings-container'
      >
        <div className='menu-integrations settings'>
          <div className='settings-header'>
            <h1>{t('integrations.header')}</h1>
          </div>
          {permissions?.reveal && permissions?.watchCollect && (
            <p style={{ color: PrimaryCobalt60 }}>
              {t('integrations.subheader', { client: 'Marketplace' })}
              <Link to={`/client/${clientId}/integrations`}>
                {' '}
                {t('integrations.clickHere')}
              </Link>
              .
            </p>
          )}
          <div className='loader-container'>
            <Loader active inline='centered' size='large' />
          </div>
        </div>
      </SettingsLayout>
    );
  }

  const connectorFromType: Record<string, IRevealConnector> = _.indexBy(
    connectors,
    'type',
  );

  return (
    <SettingsLayout
      currentPage='crm-integrations'
      clientId={clientId}
      className='integrations-view settings-container'
    >
      <div className='menu-integrations settings'>
        <Switch>
          <Route exact path='/client/:clientId/crm-integrations'>
            <>
              <div className='settings-header'>
                <h1>
                  {t('integrations.header')}{' '}
                  {permissions?.reveal && permissions?.watchCollect && '(CRM)'}
                </h1>
              </div>
              {permissions?.reveal && permissions?.watchCollect && (
                <p style={{ color: PrimaryCobalt60 }}>
                  {t('integrations.subheader', { client: 'Marketplace' })}
                  <Link to={`/client/${clientId}/integrations`}>
                    {' '}
                    {t('integrations.clickHere')}
                  </Link>
                  .
                </p>
              )}
              <div className='integration-links-grid'>
                <IntegrationLink
                  title='Ashby'
                  logo={ashbyLogo}
                  integration={connectorFromType.ashby}
                  redirectLink={`${match.url}/ashby`}
                />
                <IntegrationLink
                  title='Greenhouse'
                  logo={greenhouseLogo}
                  integration={connectorFromType.greenhouse}
                  redirectLink={`${match.url}/greenhouse`}
                />
                <IntegrationLink
                  title='Lever'
                  logo={leverLogo}
                  integration={connectorFromType.lever}
                  redirectLink={`${match.url}/lever`}
                />
                <IntegrationLink
                  title='Recruitee'
                  logo={recruiteeLogo}
                  integration={connectorFromType.recruitee}
                  redirectLink={`${match.url}/recruitee`}
                />
                <IntegrationLink
                  title='SmartRecruiters'
                  logo={smartrecruitersLogo}
                  integration={connectorFromType.smartrecruiters}
                  redirectLink={`${match.url}/smartrecruiters`}
                />
                <IntegrationLink
                  title='Teamtailor'
                  logo={teamtailorLogo}
                  integration={connectorFromType.teamtailor}
                  redirectLink={`${match.url}/teamtailor`}
                />
                <IntegrationLink
                  title='Welcome Kit'
                  logo={welcomekitLogo}
                  integration={connectorFromType.welcomekit}
                  redirectLink={`${match.url}/welcomekit`}
                />
                <IntegrationLink
                  title='Workable'
                  logo={workableLogo}
                  integration={connectorFromType.workable}
                  redirectLink={`${match.url}/workable`}
                />
                <IntegrationLink
                  title='BoondManager'
                  logo={boondmanagerLogo}
                  integration={connectorFromType.boondmanager}
                  redirectLink={`${match.url}/boondmanager`}
                />
                <IntegrationLink
                  title='Recruit CRM'
                  logo={recruitcrmLogo}
                  integration={connectorFromType.recruitcrm}
                  redirectLink={`${match.url}/recruitcrm`}
                />
                <IntegrationLink
                  title='SAP SuccessFactors'
                  logo={successfactorsLogo}
                  integration={connectorFromType.successfactors}
                  redirectLink={`${match.url}/successfactors`}
                />
                <IntegrationLink
                  title='Pinpoint'
                  logo={pinpointLogo}
                  integration={connectorFromType.pinpoint}
                  redirectLink={`${match.url}/pinpoint`}
                />
              </div>
            </>
          </Route>

          <Route
            path={`${match.path}/ashby`}
            render={() => (
              <RevealIntegration
                clientId={clientId}
                connector={connectorFromType.ashby}
                type='ashby'
                mode='token'
              />
            )}
          />

          <Route
            path={`${match.path}/welcomekit`}
            render={() => (
              <RevealIntegration
                clientId={clientId}
                connector={connectorFromType.welcomekit}
                type='welcomekit'
                mode='oauth'
              />
            )}
          />

          <Route
            path={`${match.path}/greenhouse`}
            render={() => (
              <RevealIntegration
                clientId={clientId}
                connector={connectorFromType.greenhouse}
                type='greenhouse'
                mode='token'
              />
            )}
          />

          <Route
            path={`${match.path}/recruitee`}
            render={() => (
              <RevealIntegration
                clientId={clientId}
                connector={connectorFromType.recruitee}
                type='recruitee'
                mode='token'
              />
            )}
          />

          <Route
            path={`${match.path}/successfactors`}
            render={() => (
              <RevealIntegration
                clientId={clientId}
                connector={connectorFromType.successfactors}
                type='successfactors'
                mode='token'
              />
            )}
          />

          <Route
            path={`${match.path}/recruitcrm`}
            render={() => (
              <RevealIntegration
                clientId={clientId}
                connector={connectorFromType.recruitcrm}
                type='recruitcrm'
                mode='token'
              />
            )}
          />

          <Route
            path={`${match.path}/workable`}
            render={() => (
              <RevealIntegration
                clientId={clientId}
                connector={connectorFromType.workable}
                type='workable'
                mode='oauth'
              />
            )}
          />
          <Route
            path={`${match.path}/lever`}
            render={() => (
              <RevealIntegration
                clientId={clientId}
                connector={connectorFromType.lever}
                type='lever'
                mode='token'
              />
            )}
          />
          <Route
            path={`${match.path}/teamtailor`}
            render={() => (
              <RevealIntegration
                clientId={clientId}
                connector={connectorFromType.teamtailor}
                type='teamtailor'
                mode='token'
              />
            )}
          />
          <Route
            path={`${match.path}/smartrecruiters`}
            render={() => (
              <RevealIntegration
                clientId={clientId}
                connector={connectorFromType.smartrecruiters}
                type='smartrecruiters'
                mode='token'
              />
            )}
          />
          <Route
            path={`${match.path}/boondmanager`}
            render={() => (
              <RevealIntegration
                clientId={clientId}
                connector={connectorFromType.boondmanager}
                type='boondmanager'
                mode='token'
              />
            )}
          />
          <Route
            path={`${match.path}/pinpoint`}
            render={() => (
              <RevealIntegration
                clientId={clientId}
                connector={connectorFromType.pinpoint}
                type='pinpoint'
                mode='token'
              />
            )}
          />
        </Switch>
      </div>
    </SettingsLayout>
  );
};

export default ClientRevealIntegrations;
