import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const updateProfileEmail = gql`
  mutation updateProfileEmail($id: ID!, $email: String!) {
    updateProfileEmail(id: $id, email: $email) {
      id
      email
      resumeData {
        email
      }
    }
  }
`;

export default graphql(updateProfileEmail, {
  props: ({ mutate }) => ({
    updateProfileEmail: ({ id, email }) => {
      const variables = { id, email };
      return mutate({ variables });
    },
  }),
});
