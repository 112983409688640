import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import RemoteSelector from '@/components/RemoteSelector';
import { safelyRemoveItem } from '@/common/utils/array';
import { LocationAndRemote } from '@/types/locationAndRemote';
import { CustomSelect } from '../../containers/Parameters/OfferCriteria/CustomFormItems';
import { Option } from '../../containers/Parameters/OfferCriteria/GeneralCriteriaForm/types';
import {
  addWish,
  remotePoliciesAvailable,
  setFavorite,
  setRemoteWish,
  suggestedPolicy,
} from './helpers';
import { getLocationName } from '../../containers/Parameters/OfferCriteria/helpers';

import styles from './LocationAndRemoteForm.module.less';

interface LocationAndRemoteFormProps {
  locationOptions: Option[];
  value: LocationAndRemote[];
  onChange: (value: LocationAndRemote[]) => void;
}

const LocationAndRemoteForm: FC<LocationAndRemoteFormProps> = ({
  locationOptions,
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState<LocationAndRemote[]>(value);

  const handleValue = (newValue: LocationAndRemote[]) => {
    setCurrentValue(newValue);
    onChange(newValue);
  };

  const onLocation = ({ value: newValue }: { value: Option }) => {
    handleValue(addWish(currentValue, newValue.value));
  };

  return (
    <>
      <CustomSelect
        value={null}
        options={locationOptions}
        onChange={onLocation}
        showBlockTags
        placeholderText={t('criteria.locationsPlaceholder')}
      />
      {_.map(currentValue, ({ remotePolicy, locationId, favorite }, index) => {
        const policySuggestion = suggestedPolicy(currentValue, index);
        return (
          <div
            className={styles.remoteSelector}
            key={`${locationId}_${remotePolicy}`}
          >
            <RemoteSelector
              locationName={
                getLocationName(locationOptions, locationId) || '???'
              }
              remotePolicy={remotePolicy}
              availableRemotePolicies={remotePoliciesAvailable(
                currentValue,
                locationId,
              )}
              starred={favorite}
              onRemotePolicy={(policy) =>
                handleValue(setRemoteWish(currentValue, index, policy))
              }
              onRemove={() =>
                handleValue(safelyRemoveItem(currentValue, index))
              }
              onStar={() =>
                handleValue(setFavorite(currentValue, index, !favorite))
              }
              nudge={
                policySuggestion
                  ? {
                      prompt: t('criteria.remoteSuggestion', {
                        locationName:
                          getLocationName(locationOptions, locationId) || '???',
                        remotePolicy: t(`criteria.remote.${policySuggestion}`),
                      }),
                      onClick: () =>
                        handleValue(
                          addWish(currentValue, locationId, policySuggestion),
                        ),
                    }
                  : undefined
              }
            />
          </div>
        );
      })}
    </>
  );
};

export default LocationAndRemoteForm;
