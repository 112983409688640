import React, { FC } from 'react';

import styles from './svg.module.less';

const People: FC = () => {
  return (
    <svg
      className={styles.svgIcon}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4 6.23077C3.12272 6.23077 2.41154 5.50754 2.41154 4.61538C2.41154 3.72323 3.12272 3 4 3M2.07065 11C1.38164 11 0.801237 10.411 1.06491 9.76364C1.22457 9.37166 1.45858 9.01551 1.75358 8.7155C2.04858 8.4155 2.3988 8.17752 2.78425 8.01516C3.16969 7.8528 3.5828 7.76923 4 7.76923C4.4172 7.76923 4.83031 7.8528 5.21575 8.01516'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        fill='none'
      />
      <path
        d='M12.2158 6.23077C13.093 6.23077 13.8042 5.50754 13.8042 4.61538C13.8042 3.72323 13.093 3 12.2158 3M14.1451 11C14.8341 11 15.4145 10.411 15.1508 9.76364C14.9912 9.37166 14.7572 9.01551 14.4622 8.7155C14.1672 8.4155 13.817 8.17752 13.4315 8.01516C13.0461 7.8528 12.633 7.76923 12.2158 7.76923C11.7986 7.76923 11.3854 7.8528 11 8.01516'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        fill='none'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 7.53846C9.46213 7.53846 10.6474 6.29863 10.6474 4.76923C10.6474 3.23983 9.46213 2 8 2C6.53786 2 5.35256 3.23983 5.35256 4.76923C5.35256 6.29863 6.53786 7.53846 8 7.53846ZM12.8918 11.8805C13.3313 12.9903 12.3639 14 11.2156 14H8H4.78441C3.63606 14 2.66873 12.9903 3.10818 11.8805C3.37428 11.2086 3.76429 10.598 4.25597 10.0837C4.74764 9.56942 5.33134 9.16146 5.97374 8.88313C6.61615 8.60479 7.30467 8.46154 8 8.46154C8.69533 8.46154 9.38385 8.60479 10.0263 8.88313C10.6687 9.16146 11.2524 9.56942 11.744 10.0837C12.2357 10.598 12.6257 11.2086 12.8918 11.8805Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default People;
