import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

// will fetch directly from cache if already fetched
// through network via other Offer HOCs (e.g withOffer)
export const query = gql`
  query getOfferContactFlowVersionId($id: ID!) {
    offer(id: $id) {
      id
      contactFlow {
        id
        versionId
      }
    }
  }
`;

export default graphql(query, {
  options: ({ offerId }) => {
    return {
      variables: { id: offerId },
    };
  },
  props: ({ data: { offer } }) => {
    if (!offer) {
      return null;
    }
    // HACK: fallback is a fixed versionId, which means the contactFlow will not be
    // refetched on a job contactFlow update
    const versionId = offer.contactFlow?.versionId || '1';
    return { versionId };
  },
});
