import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { compose } from 'underscore';
import { GlobalHotKeys } from 'react-hotkeys';
import { Button, Modal, Form, Checkbox, Icon, Popup } from 'semantic-ui-react';

import classNames from 'classnames';
import GenericButton from '@/components/Common/GenericButton';
import GenericButtonGroup from '@/components/Common/GenericButtonGroup';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';

// Setting the style manually is necessary since GenericPopup uses the className itself
import buttonGroupStyles from '@/components/Common/GenericButtonGroup/GenericButtonGroup.module.less';
import GenericDropdown from '@/components/Common/GenericDropdown';
import DropdownMenuPanel from '@/components/Common/DropdownMenuPanel';
import { isoWithoutTimezone } from '@/common/utils/time';
import styles from './ConfirmationModal.module.less';
import ScheduleEmail from '../ScheduleEmail';
import { getOSFromPlatform } from './helpers';
import withStopConfirmationModal from '../../../../../../hocs/users/withStopConfirmationModal';
import withActionLogger from '../../../../../../hocs/withActionLogger';

const SESSION = 'session';
const FOREVER = 'forever';

const OS_CODE = getOSFromPlatform();
const MOD_KEY = OS_CODE === 'mac' ? '⌘' : 'Ctrl';

const SendButton = ({
  onTrigger,
  sendDisabled,
  assignedSender,
  disabledReason,
  logAction,
  allowSchedule,
}) => {
  const [showScheduleModal, setShowScheduleModal] = useState(false);

  const { t } = useTranslation();

  const buttonLabel = assignedSender
    ? t('profile.contact.drafts.confirmationModal.sendOnBehalfOf')
    : t('profile.contact.drafts.confirmationModal.trigger');

  const popupContent = useMemo(() => {
    if (sendDisabled) {
      if (disabledReason === 'invalid-email-format') {
        return t(
          'profile.contact.drafts.confirmationModal.triggerTooltip.disabled',
        );
      }
      if (disabledReason === 'missing-dynamic-variables') {
        return t(
          'profile.contact.drafts.confirmationModal.triggerTooltip.disabledMissingVariables',
        );
      }
      if (disabledReason === 'email-bounced') {
        return t(
          'profile.contact.drafts.confirmationModal.triggerTooltip.disabledEmailBounced',
        );
      }
    }
    return <SendButtonTooltip t={t} />;
  }, [sendDisabled, disabledReason, t]);

  const keyMap = {
    SEND: OS_CODE === 'mac' ? 'Command+Enter' : 'Control+Enter',
  };

  const handlers = useMemo(
    () => ({
      SEND: () => {
        if (sendDisabled) {
          return;
        }
        onTrigger({ sendWithHotKey: true });
        logAction({ type: 'send-with-hot-key' });
      },
    }),
    [onTrigger, logAction, sendDisabled],
  );

  return (
    <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
      <GenericButtonGroup>
        <Popup
          trigger={
            <div style={{ display: 'inline-block' }}>
              <GenericButton
                className={classNames(
                  {
                    'button-sobo': assignedSender && !sendDisabled,
                    [styles.mainButton]: allowSchedule,
                  },
                  buttonGroupStyles.buttonInGroup,
                )}
                onClick={onTrigger}
                disabled={sendDisabled}
              >
                <span>
                  <Icon alt='send' name='send' />
                  {buttonLabel}
                </span>
              </GenericButton>
            </div>
          }
          size='tiny'
          position='bottom center'
          inverted
          content={popupContent}
        />
        {allowSchedule && (
          <GenericDropdown
            contentClassName={styles.dropdownLeft}
            Trigger={({ onClick }) => {
              return (
                <GenericButton
                  onClick={onClick}
                  disabled={sendDisabled}
                  className={classNames(styles.scheduleButton, {
                    'button-sobo': assignedSender && !sendDisabled,
                  })}
                >
                  <ArrowDown />
                </GenericButton>
              );
            }}
          >
            <DropdownMenuPanel
              options={[
                {
                  id: 'schedule',
                  label: t(
                    'profile.contact.drafts.confirmationModal.scheduleEmail',
                  ),
                },
              ]}
              onSelect={(optionId) => {
                // just in case we add more options in the future
                if (optionId === 'schedule') {
                  setShowScheduleModal(true);
                }
              }}
            />
          </GenericDropdown>
        )}
      </GenericButtonGroup>

      {allowSchedule && (
        <ScheduleEmail
          open={showScheduleModal}
          onClose={() => setShowScheduleModal(false)}
          onSubmit={({ date, timezone }) => {
            setShowScheduleModal(false);
            const formattedDate = isoWithoutTimezone(date);
            onTrigger({ date: formattedDate, timezone });
          }}
        />
      )}
    </GlobalHotKeys>
  );
};

class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stopConfirmation: '',
    };
  }

  logAction = ({ type }) => {
    const { clientId, onLogAction } = this.props;
    onLogAction({
      type,
      info: {
        clientId,
        from: 'send-confirmation-modal',
      },
    });
  };

  handleSend = () => {
    const { stopConfirmation } = this.state;
    const {
      emailApi,
      stopConfirmationModal,
      onSubmit,
      clientId,
      openWithHotKey,
    } = this.props;

    if (stopConfirmation === SESSION || stopConfirmation === FOREVER) {
      emailApi.setSendConfirmationMode(false);
    }

    onSubmit(undefined, openWithHotKey).then(() => {
      if (stopConfirmation === FOREVER) {
        stopConfirmationModal({ clientId });
      }
    });
  };

  render() {
    const {
      open,
      openWithHotKey,
      onCancel,
      t,
      onTrigger,
      sendDisabled,
      assignedSender,
      disabledReason,
      allowSchedule,
    } = this.props;
    const { stopConfirmation } = this.state;
    return (
      <Modal
        className='stop-confirmation-modal'
        open={open}
        onClose={onCancel}
        trigger={
          <SendButton
            onTrigger={onTrigger}
            sendDisabled={sendDisabled}
            assignedSender={assignedSender}
            disabledReason={disabledReason}
            allowSchedule={allowSchedule}
            logAction={this.logAction}
          />
        }
        closeIcon
        size='small'
      >
        <Modal.Header>
          {t('profile.contact.drafts.confirmationModal.header')}
        </Modal.Header>
        <Modal.Content>
          <div className='input-container'>
            <div className='input-label'>
              {t('profile.contact.drafts.confirmationModal.removeWarning')}
            </div>
            <Form>
              <Form.Field className='input-element'>
                <Checkbox
                  name={SESSION}
                  checked={stopConfirmation === SESSION}
                  label={t(
                    'profile.contact.drafts.confirmationModal.sessionOnly',
                  )}
                  onChange={(event, { checked }) =>
                    this.setState({
                      stopConfirmation: checked ? SESSION : '',
                    })
                  }
                />
              </Form.Field>
              <Form.Field className='input-element'>
                <Checkbox
                  name={FOREVER}
                  checked={stopConfirmation === FOREVER}
                  label={t(
                    'profile.contact.drafts.confirmationModal.permanently',
                  )}
                  onChange={(event, { checked }) =>
                    this.setState({
                      stopConfirmation: checked ? FOREVER : '',
                    })
                  }
                />
              </Form.Field>
            </Form>
          </div>
          {!openWithHotKey && (
            <div className='hot-key-tip'>
              <span>
                {t(
                  'profile.contact.drafts.confirmationModal.proTip.youCanAlsoHit',
                )}
              </span>
              <span className='code-text'>{MOD_KEY}</span>
              <span> + </span>
              <span className='code-text'>{t('common.keyEnter')}</span>
              <span>
                {t('profile.contact.drafts.confirmationModal.proTip.toSend')}
              </span>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <div className='align-left'>
            <Button className='dismiss' type='submit' onClick={onCancel}>
              {t('profile.contact.drafts.confirmationModal.cancel')}
            </Button>
          </div>
          <Button primary size='big' type='submit' onClick={this.handleSend}>
            {t('profile.contact.drafts.confirmationModal.send')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const SendButtonTooltip = ({ t }) => {
  return (
    <div>
      <span>
        {t('profile.contact.drafts.confirmationModal.triggerTooltip.hit')}
      </span>
      <span className='code-text'>{MOD_KEY}</span>
      <span> + </span>
      <span className='code-text'>{t('common.keyEnter')}</span>
      <br />
      <span>
        {t('profile.contact.drafts.confirmationModal.triggerTooltip.toSend')}
      </span>
    </div>
  );
};

export default compose(
  withActionLogger,
  withStopConfirmationModal,
  withTranslation('translations'),
)(ConfirmationModal);
