import DropdownMenuItem from '@/components/Common/DropdownMenuItem';
import DropdownPanel from '@/components/Common/DropdownPanel';
import GenericButton from '@/components/Common/GenericButton';
import GenericDropdown from '@/components/Common/GenericDropdown';
import Dots from '@/components/Common/Icons/Dots';
import { Segment } from '@/components/PipelineSegmentation/pipelineSegmentation';
import Plus from '@/components/Reveal/Icons/Plus';
import { TranslatableText } from '@/types/text';
import { t } from 'i18next';
import React from 'react';
import _ from 'underscore';
import styles from '../SuperPipelineSettings.module.less';

export interface SegmentWithSubsteps extends Segment {
  substeps?: {
    id: string;
    title: TranslatableText;
  }[];
}

interface SuperPipelineSegmentRowProps {
  segment: SegmentWithSubsteps;
  index: number;
  onSelectedStep: (step: string) => void;
  onOpenStepNameEditionModal: (open: boolean) => void;
  onOpenNewSubstepModal: (open: boolean) => void;
  onNewSubstepIndex: (index: number) => void;
}

const SuperPipelineSegmentRow: React.FC<SuperPipelineSegmentRowProps> = ({
  segment,
  index,
  onSelectedStep,
  onOpenStepNameEditionModal,
  onOpenNewSubstepModal,
  onNewSubstepIndex,
}) => {
  return (
    <>
      <div className={styles.stepContainer} key={segment.id}>
        <div className={styles.stepNumber}>{index + 1}</div>
        <div className={styles.stepValue}>{segment.name}</div>
        {(segment.id === 'hired' ||
          (segment.id === 'interested' && _.isEmpty(segment.substeps))) && (
          <GenericDropdown
            position='right'
            Trigger={({ onClick }) => (
              <GenericButton
                primacy='tertiary'
                onClick={onClick}
                className={styles.actionButton}
              >
                <Dots />
              </GenericButton>
            )}
          >
            <DropdownPanel className={styles.panel}>
              {segment.id === 'interested' && (
                <DropdownMenuItem
                  className={styles.panelOption}
                  key='interested-add-substep'
                  onClick={() => {
                    onNewSubstepIndex(0);
                    onOpenNewSubstepModal(true);
                  }}
                >
                  <Plus />
                  <span>{t('superPipelineSettings.addSubstep')}</span>
                </DropdownMenuItem>
              )}
              {segment.id === 'hired' && (
                <DropdownMenuItem
                  className={styles.panelOption}
                  key='interested-add-substep'
                  onClick={() => {
                    onSelectedStep(segment.id);
                    onOpenStepNameEditionModal(true);
                  }}
                >
                  <i className='ri-pencil-fill ri-lg' />
                  <span>{t('common.edit')}</span>
                </DropdownMenuItem>
              )}
            </DropdownPanel>
          </GenericDropdown>
        )}
      </div>
    </>
  );
};

export default SuperPipelineSegmentRow;
