import React, { useState } from 'react';
import classNames from 'classnames';

import './FloatingLabelInput.css';

const FloatingLabelInput = ({
  label,
  value,
  onChange,
  onFocus: handleFocus,
  onBlur: handleBlur,
  type = 'text',
  name,
  className,
  id,
  ...props
}) => {
  const [inputActive, setInputActive] = useState(false);
  const onFocus = () => {
    setInputActive(true);
    if (handleFocus) {
      handleFocus();
    }
  };

  const onBlur = () => {
    if (value === '') {
      setInputActive(false);
    }
    if (handleBlur) {
      handleBlur();
    }
  };

  const setActiveAndOnChange = (e) => {
    if (onChange) {
      onChange(e);
      setInputActive(true);
    }
  };

  return (
    <div className={classNames('floating-label-input', className)}>
      <input
        className='label-input'
        type={type}
        name={name}
        value={value}
        onChange={setActiveAndOnChange}
        onFocus={onFocus}
        onBlur={onBlur}
        id={id}
        {...props}
      />
      <label
        htmlFor={id}
        className={classNames(
          'floating-label',
          value || inputActive ? 'floating' : '',
        )}
      >
        {label}
      </label>
    </div>
  );
};

export default FloatingLabelInput;
