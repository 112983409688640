import _ from 'underscore';
import React, { useState } from 'react';
import GenericButton from '@/components/Common/GenericButton';
import Playground1 from './PlaygroundViews/Playground1';
import Playground2 from './PlaygroundViews/Playground2';
import Playground3 from './PlaygroundViews/Playground3';
import Playground4 from './PlaygroundViews/Playground4';
import Playground5 from './PlaygroundViews/Playground5';
import Playground6 from './PlaygroundViews/Playground6';
import Playground7 from './PlaygroundViews/Playground7';
import Playground8 from './PlaygroundViews/Playground8';
import Playground9 from './PlaygroundViews/Playground9';
import Playground10 from './PlaygroundViews/Playground10';
import Playground11 from './PlaygroundViews/Playground11';
import Playground12 from './PlaygroundViews/Playground12';
import Playground13 from './PlaygroundViews/Playground13';
import Playground14 from './PlaygroundViews/Playground14';
import Playground15 from './PlaygroundViews/Playground15';
import Playground16 from './PlaygroundViews/Playground16';
import PlaygroundJ1 from './PlaygroundViews/PlaygroundJ1';
import PlaygroundJ2 from './PlaygroundViews/PlaygroundJ2';
import PlaygroundJ3 from './PlaygroundViews/PlaygroundJ3';
import PlaygroundJ4 from './PlaygroundViews/PlaygroundJ4';
import PlaygroundT1 from './PlaygroundViews/PlaygroundT1';
import PlaygroundT2 from './PlaygroundViews/PlaygroundT2';
import PlaygroundT3 from './PlaygroundViews/PlaygroundT3';
import PlaygroundB1 from './PlaygroundViews/PlaygroundB1';
import PlaygroundTScreens from './PlaygroundViews/PlaygroundTScreens';
import PlaygroundT4 from './PlaygroundViews/PlaygroundT4';
import PlaygroundProjectCustomField from './PlaygroundViews/PlaygroundProjectCustomField';
import PlaygroundProfileCustomField from './PlaygroundViews/PlaygroundProfileCustomField';
import PlaygroundT5 from './PlaygroundViews/PlaygroundT5';
import PlaygroundTasksTable1 from './PlaygroundViews/PlaygroundTasksTable1';
import PlaygroundTasksTable2 from './PlaygroundViews/PlaygroundTasksTable2';
import PlaygroundFragmentTypes from './PlaygroundViews/PlaygroundFragmentTypes';
import PlaygroundSlashTasks from './PlaygroundViews/PlaygroundSlashTasks';
import PlaygroundCommands from './PlaygroundViews/PlaygroundCommands';
import PlaygroundT6 from './PlaygroundViews/PlaygroundT6';
import PlaygroundB2 from './PlaygroundViews/PlaygroundB2';
import PlaygroundB3 from './PlaygroundViews/PlaygroundB3';
import PlaygroundB4 from './PlaygroundViews/PlaygroundB4';
import PlaygroundB5 from './PlaygroundViews/PlaygroundB5';
import PlaygroundT7 from './PlaygroundViews/PlaygroundT7';
import PlaygroundSweetCompoProps from './PlaygroundViews/PlaygroundSweetCompoProps';
import PlaygroundT8 from './PlaygroundViews/PlaygroundT8';
import PlaygroundB6 from './PlaygroundViews/PlaygroundB6';
import PlaygroundB7 from './PlaygroundViews/PlaygroundB7';
import PlaygroundB8 from './PlaygroundViews/PlaygroundB8';

const playgroundsCategories = [
  {
    id: 'widgets',
    title: 'Widgets',
    playgrounds: [
      { id: 'playground-4', title: 'Buttons and styles' },
      { id: 'playground-14', title: 'Side Nav' },
      { id: 'playground-3', title: 'Profile Header' },
      { id: 'playground-1', title: 'Profile tabs' },
      { id: 'playground-2', title: 'Project useData' },
      { id: 'playground-project-cf', title: 'Project Custom Field' },
      { id: 'playground-profile-cf', title: 'Profile Custom Field' },
      { id: 'playground-16', title: 'Profile contract history' },
      { id: 'playground-j-1', title: 'Side panel' },
      { id: 'playground-j-3', title: 'Panel with pagination' },
      { id: 'playground-j-4', title: 'Date picker' },
      { id: 'playground-t-1', title: 'Profile Experiences' },
      { id: 'playground-t-2', title: 'Project Applications Overview' },
      { id: 'playground-t-3', title: 'Form inputs' },
      { id: 'playground-t-4', title: 'Profile Footer' },
      { id: 'playground-b-5', title: 'Profile Form' },
      { id: 'playground-b-7', title: 'Profile Creation' },
      { id: 'playground-b-8', title: 'Profile Creation Button' },
      { id: 'playground-b-1', title: 'Project Form' },
      { id: 'playground-b-2', title: 'Project Form 2' },
      { id: 'playground-b-3', title: 'Project Creation' },
      { id: 'playground-b-4', title: 'Project Update' },
      { id: 'playground-t-5', title: 'Ball Chain' },
      { id: 'playground-t-6', title: 'Stars' },
      { id: 'playground-t-7', title: 'Pagination' },
      { id: 'playground-t-8', title: 'Selectors' },
    ],
  },
  {
    id: 'projects-tables',
    title: 'Projects Tables',
    playgrounds: [
      { id: 'playground-7', title: 'Full table' },
      { id: 'playground-5', title: 'Filter + Manual Pagination' },
      { id: 'playground-9', title: 'Paginated Table' },
    ],
  },
  {
    id: 'profiles-tables',
    title: 'Profiles Tables',
    playgrounds: [
      { id: 'playground-6', title: 'Manual Pagination' },
      { id: 'playground-10', title: 'Paginated Table' },
    ],
  },
  {
    id: 'tasks-tables',
    title: 'Tasks Tables',
    playgrounds: [
      { id: 'playground-tasks-table-1', title: 'Tasks table 1' },
      { id: 'playground-tasks-table-2', title: 'Tasks table 2' },
    ],
  },
  {
    id: 'views',
    title: 'Views',
    playgrounds: [{ id: 'playground-8', title: 'Posting / Command' }],
  },
  {
    id: 'slash-views',
    title: 'Slash Views',
    playgrounds: [
      { id: 'playground-11', title: 'Posting / Command' },
      { id: 'playground-12', title: 'Postings' },
      { id: 'playground-commands', title: 'Commands' },
      { id: 'playground-15', title: 'Project' },
      { id: 'playground-j-2', title: 'Profiles' },
      { id: 'playground-13', title: 'Profile' },
      { id: 'playground-slash-tasks', title: 'Tasks' },
      { id: 'playground-t-screens', title: 'Screens' },
    ],
  },
  {
    id: 'misc',
    title: 'Misc',
    playgrounds: [
      { id: 'playground-fragment-types', title: 'Fragment types' },
      { id: 'playground-sweetcompo-props', title: 'Sweetcompo props' },
      { id: 'playground-current-sequence-with-info', title: 'Current Sequence Actions' }
    ],
  },
];

const Playgrounds = () => {
  const [theme, setTheme] = useState<'hiresweet' | 'slash'>('slash');
  const [playgroundCategoryId, setPlaygroundCategoryId] =
    useState<string>('widgets');
  const [playgroundId, setPlaygroundId] = useState<string>(
    'playground-slash-tasks',
  );

  return (
    <div style={{ padding: 30 }}>
      <h1>Playgrounds</h1>
      <button
        type='button'
        style={{
          cursor: 'pointer',
          background: 'lightgrey',
          borderRadius: 4,
          padding: 10,
        }}
        onClick={() => {
          setTheme((currentTheme) =>
            currentTheme === 'hiresweet' ? 'slash' : 'hiresweet',
          );
        }}
      >
        {{ hiresweet: 'HireSweet', slash: 'Slash' }[theme] ?? ''}
      </button>
      <br />
      <br />
      <div>
        {_.map(playgroundsCategories, ({ id, title }) => (
          <GenericButton
            style={{ marginRight: 10 }}
            key={id}
            primacy='secondary'
            color='grey'
            onClick={() => {
              setPlaygroundId('');
              setPlaygroundCategoryId(id);
            }}
          >
            {title}
          </GenericButton>
        ))}
      </div>
      <br />
      <div>
        {_.map(
          _.findWhere(playgroundsCategories, { id: playgroundCategoryId })
            ?.playgrounds ?? [],
          ({ id, title }) => (
            <GenericButton
              style={{ marginRight: 10 }}
              key={`playground_${id}`}
              primacy='secondary'
              color='grey'
              onClick={() => setPlaygroundId(id)}
            >
              {title}
            </GenericButton>
          ),
        )}
      </div>
      {playgroundId === 'playground-1' ? <Playground1 theme={theme} /> : ''}
      {playgroundId === 'playground-2' ? <Playground2 theme={theme} /> : ''}
      {playgroundId === 'playground-3' ? <Playground3 theme={theme} /> : ''}
      {playgroundId === 'playground-4' ? <Playground4 theme={theme} /> : ''}
      {playgroundId === 'playground-5' ? <Playground5 theme={theme} /> : ''}
      {playgroundId === 'playground-6' ? <Playground6 theme={theme} /> : ''}
      {playgroundId === 'playground-7' ? <Playground7 theme={theme} /> : ''}
      {playgroundId === 'playground-8' ? <Playground8 theme={theme} /> : ''}
      {playgroundId === 'playground-9' ? <Playground9 theme={theme} /> : ''}
      {playgroundId === 'playground-10' ? <Playground10 theme={theme} /> : ''}
      {playgroundId === 'playground-11' ? <Playground11 theme={theme} /> : ''}
      {playgroundId === 'playground-12' ? <Playground12 theme={theme} /> : ''}
      {playgroundId === 'playground-commands' ? (
        <PlaygroundCommands theme={theme} />
      ) : (
        ''
      )}
      {playgroundId === 'playground-13' ? <Playground13 theme={theme} /> : ''}
      {playgroundId === 'playground-14' ? <Playground14 theme={theme} /> : ''}
      {playgroundId === 'playground-15' ? <Playground15 theme={theme} /> : ''}
      {playgroundId === 'playground-16' ? <Playground16 theme={theme} /> : ''}
      {playgroundId === 'playground-j-1' ? <PlaygroundJ1 theme={theme} /> : ''}
      {playgroundId === 'playground-j-2' ? <PlaygroundJ2 theme={theme} /> : ''}
      {playgroundId === 'playground-j-3' ? <PlaygroundJ3 theme={theme} /> : ''}
      {playgroundId === 'playground-j-4' ? <PlaygroundJ4 theme={theme} /> : ''}
      {playgroundId === 'playground-t-1' ? <PlaygroundT1 theme={theme} /> : ''}
      {playgroundId === 'playground-t-2' ? <PlaygroundT2 theme={theme} /> : ''}
      {playgroundId === 'playground-t-3' ? <PlaygroundT3 theme={theme} /> : ''}
      {playgroundId === 'playground-b-1' ? <PlaygroundB1 theme={theme} /> : ''}
      {playgroundId === 'playground-b-2' ? <PlaygroundB2 theme={theme} /> : ''}
      {playgroundId === 'playground-b-3' ? <PlaygroundB3 theme={theme} /> : ''}
      {playgroundId === 'playground-b-4' ? <PlaygroundB4 theme={theme} /> : ''}
      {playgroundId === 'playground-b-5' ? <PlaygroundB5 theme={theme} /> : ''}
      {playgroundId === 'playground-b-7' ? <PlaygroundB7 theme={theme} /> : ''}
      {playgroundId === 'playground-b-8' ? <PlaygroundB8 theme={theme} /> : ''}
      {playgroundId === 'playground-t-screens' ? <PlaygroundTScreens /> : ''}
      {playgroundId === 'playground-t-4' ? <PlaygroundT4 theme={theme} /> : ''}
      {playgroundId === 'playground-t-6' ? <PlaygroundT6 theme={theme} /> : ''}
      {playgroundId === 'playground-t-5' ? <PlaygroundT5 /> : ''}
      {playgroundId === 'playground-project-cf' ? (
        <PlaygroundProjectCustomField theme={theme} />
      ) : (
        ''
      )}
      {playgroundId === 'playground-profile-cf' ? (
        <PlaygroundProfileCustomField theme={theme} />
      ) : (
        ''
      )}
      {playgroundId === 'playground-tasks-table-1' ? (
        <PlaygroundTasksTable1 theme={theme} />
      ) : (
        ''
      )}
      {playgroundId === 'playground-tasks-table-2' ? (
        <PlaygroundTasksTable2 theme={theme} />
      ) : (
        ''
      )}
      {playgroundId === 'playground-fragment-types' ? (
        <PlaygroundFragmentTypes theme={theme} />
      ) : (
        ''
      )}
      {playgroundId === 'playground-slash-tasks' ? (
        <PlaygroundSlashTasks theme={theme} />
      ) : (
        ''
      )}
      {playgroundId === 'playground-t-7' && <PlaygroundT7 theme={theme} />}
      {playgroundId === 'playground-sweetcompo-props' && <PlaygroundSweetCompoProps theme={theme} />}
      {playgroundId === 'playground-t-8' && <PlaygroundT8 theme={theme} />}
      {playgroundId === 'playground-current-sequence-with-info' && <PlaygroundB6 theme={theme} />}
    </div>
  );
};

export default Playgrounds;
