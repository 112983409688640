import React from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import ContextMenu from '@/components/Tables/ContextMenu/ContextMenu';
import { ConnectForm } from '@/types/connectForm';
import { getFullname } from '@/common/helpers/person';

import styles from './ConnectFormsTable.module.less';

interface ConnectFormsTableRowProps {
  connectForm: ConnectForm;
  withEdit: boolean;
  onEditConnectForm: ({ connectFormId }: { connectFormId: string }) => void;
  onDeleteConnectForm: ({ connectFormId }: { connectFormId: string }) => void;
}

export const ConnectFormsTableRow: React.FC<ConnectFormsTableRowProps> = ({
  connectForm,
  withEdit,
  onEditConnectForm,
  onDeleteConnectForm,
}) => {
  const { t } = useTranslation();
  const { id, title, author } = connectForm || {};

  const onEdit = () => {
    onEditConnectForm({ connectFormId: id });
  };

  const onDelete = () => {
    onDeleteConnectForm({ connectFormId: id });
  };

  return (
    <Table.Row className={styles.tableRow}>
      <Table.Cell className={styles.tableCell}>{title.default}</Table.Cell>
      <Table.Cell>{getFullname(author)}</Table.Cell>
      {withEdit && (
        <Table.Cell className={styles.actionTableCell} collapsing>
          <ContextMenu
            actions={[
              {
                icon: 'ri-edit-line',
                label: t('settings.senders.editButton'),
                onClick: () => onEdit(),
              },
              {
                icon: 'ri-delete-bin-line',
                label: t('settings.senders.deleteButton'),
                onClick: () => onDelete(),
              },
            ]}
          />
        </Table.Cell>
      )}
    </Table.Row>
  );
};

export default ConnectFormsTableRow;
