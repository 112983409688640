import { useLazyQuery } from '@apollo/client';
import {
  GET_PROFILE_MATCHES,
  GetProfilesMatchesResult,
  GetProfilesMatchesVariables,
} from '../../searchPoolProfileMatches';

const useSearchPoolProfileMatches = (
  searchPoolId: string,
  input: { id?: string; resume?: string },
) => {
  return useLazyQuery<GetProfilesMatchesResult, GetProfilesMatchesVariables>(
    GET_PROFILE_MATCHES,
    {
      variables: { id: searchPoolId, input },
    },
  );
};

export default useSearchPoolProfileMatches;
