import React, { useMemo } from 'react';
import _ from 'underscore';

import { useTranslation } from 'react-i18next';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import sanitizeTimeLineItem from '@/common/sanitizeTimeLineItem';
import withClientTemplates from '@/hocs/templates/withClientTemplates';
import CopyToClipboardSection from '@/components/Common/CopyToClipboardSection/CopyToClipboardSection';
import { filterHtmlText } from '@/common/utils/string';
import { getHtmlStringAsText } from '@/common/utils/htmlToText';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import useClientId from '@/hooks/router/useClientId';
import { instantiateMessage } from '../../Actions/EmailAction';

import './EmailActionItem.css';

const EmailActionItem = ({
  message,
  showSubject,
  clipboardTargetIndex = -1,
  increaseClipboardTargetIndex = () => {},
  instantiatedSnippets = null,
  showClipboardAction = true,
}) => {
  const clientId = useClientId();
  return (
    <EmailPreview
      clientId={clientId}
      message={message}
      instantiatedSnippets={instantiatedSnippets}
      showSubject={showSubject}
      clipboardTargetIndex={clipboardTargetIndex}
      increaseClipboardTargetIndex={increaseClipboardTargetIndex}
      showClipboardAction={showClipboardAction}
    />
  );
};

const EmailPreview = withClientTemplates(
  ({
    message,
    templates,
    showSubject,
    clipboardTargetIndex,
    increaseClipboardTargetIndex,
    instantiatedSnippets,
    showClipboardAction = true,
  }) => {
    const { profile, mergeTagsProfileContext } = useCandidateViewContext();
    const { t } = useTranslation();
    if (!message) {
      return null;
    }
    const { body, templateId, subject } = message;

    let displayedSubject = subject;
    let displayedBody = body;

    if (!displayedBody && templateId) {
      if (!templates) {
        return null;
      }
      const context = profile?.resumeData || {};
      const instantiatedMessage = instantiateMessage({
        message,
        context,
        templates,
      });
      displayedBody = instantiatedMessage?.body;
    }

    if (!_.isEmpty(instantiatedSnippets)) {
      displayedSubject = MergeTagsService.getInstantiatedText({
        text: displayedSubject,
        instantiatedSnippets,
        context: mergeTagsProfileContext,
        t,
        withNoValueText: false,
      });
      displayedBody = MergeTagsService.getInstantiatedText({
        text: displayedBody,
        instantiatedSnippets,
        context: mergeTagsProfileContext,
        t,
        withNoValueText: false,
      });
    }

    const subjectInstantiated = MergeTagsService.getInstantiatedText({
      text: subject,
      instantiatedSnippets,
      context: mergeTagsProfileContext,
      t,
    });

    const bodyInstantiated = MergeTagsService.getInstantiatedText({
      text: body,
      instantiatedSnippets,
      context: mergeTagsProfileContext,
      t,
    });

    return (
      <EmailBody
        content={displayedBody}
        subject={displayedSubject || null}
        showSubject={showSubject}
        clipboardTargetIndex={clipboardTargetIndex}
        increaseClipboardTargetIndex={increaseClipboardTargetIndex}
        subjectInstantiated={subjectInstantiated}
        bodyInstantiated={bodyInstantiated}
        showClipboardAction={showClipboardAction}
      />
    );
  },
);

const EmailBody = React.memo(
  ({
    content,
    subject,
    showSubject,
    clipboardTargetIndex,
    increaseClipboardTargetIndex,
    subjectInstantiated = null,
    bodyInstantiated = null,
    showClipboardAction = true,
  }) => {
    const { isMinimized } = useMinimizedView();

    return (
      <div className='email-body-and-subject'>
        {subject && (
          <div className={`subject ${isMinimized ? 'minimized' : ''}`}>
            {showSubject && (
              <>
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitizeTimeLineItem(subject || ''),
                  }}
                />
                {showClipboardAction && (
                  <CopyToClipboardSection
                    textToDisplay={subject}
                    textToCopy={getHtmlStringAsText(
                      subjectInstantiated || subject,
                    )}
                    isActiveTarget={clipboardTargetIndex === 0}
                    onCopy={increaseClipboardTargetIndex}
                  />
                )}
              </>
            )}
          </div>
        )}
        <div className={`body ${isMinimized ? 'minimized' : ''}`}>
          {!isMinimized ? (
            <div
              className='content'
              dangerouslySetInnerHTML={{
                __html: sanitizeTimeLineItem(content || ''),
              }}
            />
          ) : (
            <div className='content'>{filterHtmlText(content || '')}</div>
          )}
          {showClipboardAction && (
            <CopyToClipboardSection
              textToDisplay={content || ''}
              textToCopy={bodyInstantiated}
              isActiveTarget={
                (!subject && clipboardTargetIndex === 0) ||
                (subject && clipboardTargetIndex === 1)
              }
              onCopy={increaseClipboardTargetIndex}
            />
          )}
        </div>
      </div>
    );
  },
);

export default EmailActionItem;
