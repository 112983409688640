import { useQuery } from '@apollo/client';
import { GET_USER_FROM_JWTOKEN } from '@/graphql/users';

export default function useCurrentUser() {
  const query = useQuery(GET_USER_FROM_JWTOKEN);

  return {
    ...query,
    user: query.data?.user,
  };
}
