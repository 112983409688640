import React, { useState } from 'react';
import GenericTextArea from '@/components/Common/GenericTextArea';
import classnames from 'classnames';
import _ from 'underscore';
import styles from '../MetaTaskForm.module.less';
import { MetaTaskFormFieldOnChange, MetaTaskFormatFormField } from '../types';

interface MetaTaskFormFieldFieldAnswerProps {
  field?: MetaTaskFormatFormField;
  onChange?: MetaTaskFormFieldOnChange;
  disabled?: boolean;
  readMode?: boolean;
}

const MetaTaskFormFieldTextAreaAnswer: React.FC<MetaTaskFormFieldFieldAnswerProps> = ({
  field,
  onChange,
  disabled = false,
  readMode = false,
}) => {
  const [currentValue, setCurrentValue] = useState(field?.value || '');
  const handleChange = ({ value }: { value: string }) => {
    if (onChange) {
      setCurrentValue(value);
      onChange({ fieldId: field?.id || '', value });
    }
  };

  if (readMode) {
    if (_.isEmpty(currentValue)) {
      return <span>-</span>;
    }
    return (
      <>
        {_.map(currentValue.split('\n'), (line, index) => (
          <span className={styles.textAreaAnswer}>
            {line}
            {index < currentValue.split('\n')?.length - 1 && <br />}
          </span>
        ))}
      </>
    );
  }

  return (
    <div className={styles.metaTaskAnswer}>
      <GenericTextArea
        className={classnames(styles.metaTaskInput, styles.metaTaskTextArea)}
        value={currentValue}
        onValue={(value) => handleChange({ value })}
        disabled={disabled}
      />
    </div>
  );
};

export default MetaTaskFormFieldTextAreaAnswer;
