import React, { useState, useCallback, useMemo } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import _ from 'underscore';

import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

import LaserFocusModal from '../TodosView/LaserFocusModal';
import TaskTypeTable from './TaskTypeTable';
import TaskMissionIdTable from './TaskMissionIdTable';
import TaskAgencyTable from './TaskAgencyTable';

import './FilteredTasks.css';

const FilteredTasks = ({ clientId, tasks, tasksLoading }) => {
  const location = useLocation();
  const history = useHistory();
  const [taskSelection, setTaskSelection] = useState();
  const matchTaskPage = useRouteMatch({
    path: '/client/:clientId/reveal/tasks',
  });
  const matchFocusPage = useRouteMatch({
    path: [
      '/client/:clientId/reveal/tasks/focus/:taskId',
      '/client/:clientId/reveal/projects/:missionCategory/:missionId/mission-tasks/focus/:taskId',
    ],
  });
  const { tasksViewTableGrouping } = useMergedConfigurationParams();

  const handleSelect = useCallback(
    (id, selection) => {
      setTaskSelection(selection);
      history.push(`${location.pathname}/focus/${id}`);
    },
    [history, location],
  );

  const table = useMemo(() => {
    if (!matchTaskPage) {
      return <TaskTypeTable tasks={tasks || []} onSelect={handleSelect} />;
    }
    switch (tasksViewTableGrouping) {
      case 'mission':
        return (
          <TaskMissionIdTable tasks={tasks || []} onSelect={handleSelect} />
        );
      case 'agency':
        return <TaskAgencyTable tasks={tasks || []} onSelect={handleSelect} />;
      default:
        return <TaskTypeTable tasks={tasks || []} onSelect={handleSelect} />;
    }
  }, [tasksViewTableGrouping, tasks, handleSelect, matchTaskPage]);

  if (tasksLoading) {
    return (
      <div className='reveal-tasks-container loading'>
        <Loader active inline='centered' size='large' />
      </div>
    );
  }

  return (
    <div className='filtered-tasks'>
      {table}

      {!!matchFocusPage && (
        <LaserFocusModal
          clientId={clientId}
          title='My tasks'
          tasksIds={_.pluck(taskSelection || tasks, 'id')}
          tasks={taskSelection || tasks}
        />
      )}
    </div>
  );
};

export default FilteredTasks;
