import React, { useCallback } from 'react';
import classnames from 'classnames';
import RangeSlider from 'react-range-slider-input';

import useClientRect from '@/hooks/common/useClientRect';

import 'react-range-slider-input/dist/style.css';

import styles from './GenericRangeSlider.module.less';

interface GenericRangeSliderProps {
  min: number;
  max: number;
  step?: number;
  values: [number, number];
  onChange: (values: [number, number]) => void;
  formatLabel?: (value: number) => string;
  labelClassName?: string;
  allowEqual?: boolean;
}

const GenericRangeSlider = ({
  min,
  max,
  step = 1,
  values,
  onChange,
  formatLabel = (value) => value.toString(),
  labelClassName,
  allowEqual,
}: GenericRangeSliderProps) => {
  const { ref: sliderRef, rect: sliderRect } = useClientRect();

  const stretch = max - min || 1;
  const lowerValueRatio = ((values[0] || min) - min) / stretch;
  const upperValueRatio = ((values[1] || max) - min) / stretch;

  const handleInput = useCallback(
    ([lower, upper]: [number, number]) => {
      if (lower === upper && !allowEqual) {
        return;
      }
      onChange([lower, upper]);
    },
    [onChange, allowEqual],
  );

  return (
    <div ref={sliderRef}>
      <div
        className={styles.labelsContainer}
        style={{
          ...(sliderRect && { width: sliderRect.width - 14 }),
        }}
      >
        <div
          className={classnames(labelClassName, styles.labelMin)}
          style={{
            left: `${lowerValueRatio * 100}%`,
          }}
        >
          {formatLabel(values[0] || min)}
        </div>
        <div
          className={classnames(labelClassName, styles.labelMax)}
          style={{
            left: `${upperValueRatio * 100}%`,
          }}
        >
          {formatLabel(values[1] || max)}
        </div>
      </div>
      <RangeSlider
        className={styles.experienceSlider}
        min={min}
        max={max}
        step={step}
        value={[values[0] || min, values[1] || max]}
        onInput={handleInput}
      />
    </div>
  );
};

export default GenericRangeSlider;
