import React, { useCallback, useState, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { getTranslatedText } from '@/common';
import { getDateValueAsDate } from '@/components/FormFields';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getShortLanguage } from '@/common/utils/i18n';
import styles from '../MetaTaskForm.module.less';
import { MetaTaskFormatFormField } from '../types';

interface MetaTaskFormFieldDateAnswerProps {
  field?: MetaTaskFormatFormField;
  onChange?: ({
    fieldId,
    value,
  }: {
    fieldId: string;
    value: string;
  }) => void | undefined;
  disabled?: boolean;
  readMode?: boolean;
}

const MetaTaskFormFieldDateAnswer: React.FC<MetaTaskFormFieldDateAnswerProps> = ({
  field,
  onChange,
  disabled,
  readMode,
}) => {
  const [currentValue, setCurrentValue] = useState(field?.value);
  const { i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);

  const dateFormat = useMemo(
    () => (lang === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'),
    [lang],
  );

  const handleChange = useCallback(
    (date: Date | null) => {
      const value = date?.toISOString() || '';
      if (onChange) {
        setCurrentValue(value);
        onChange({ fieldId: field?.id || '', value });
      }
    },
    [field, onChange],
  );

  if (readMode) {
    return (
      <span>
        {!field?.value
          ? '-'
          : new Date(
              new Date(field?.value).getTime() + 10 * 3600 * 1000,
            ).toLocaleDateString()}
      </span>
    );
  }

  return (
    <div className={styles.metaTaskAnswer}>
      <DatePicker
        id={field?.id}
        className={classnames(styles.metaTaskInput, styles.datePickerInput)}
        onChange={handleChange}
        name={getTranslatedText(field?.title || { default: '' })}
        selected={getDateValueAsDate(currentValue || field?.value || '')}
        locale={lang}
        dateFormat={dateFormat}
        disabled={disabled}
        disabledKeyboardNavigation
      />
    </div>
  );
};

export default MetaTaskFormFieldDateAnswer;
