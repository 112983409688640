import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import GenericButton from '@/components/Common/GenericButton';
import withAddJobPosting from '../../../hocs/offers/withAddJobPosting';
import { getCreateJobPostingInput, isInvalidJobPosting } from './helpers';
import JobPostingFormFields from './JobPostingFormFields';
import TwoColumnLayout from '../../../components/layout/TwoColumnLayout';
import { ApplicationFormSelector } from './components/JobPostingApplicationFormSelector';

import './JobPostingCreator.css';
import withClient from '../../../hocs/clients/withClient';

export const getDefaultJobPosting = ({ postingDefaults }) => {
  return {
    title: { default: postingDefaults?.title || '', fr: '', en: '' },
    description: {
      type: 'multi-sections',
      sections: [
        {
          type: 'simple-section',
          title: { default: '' },
          content: { default: '' },
          __typename: 'SimpleJobPostingDescriptionSection',
        },
      ],
      __typename: 'MultiSectionsJobPostingDescription',
    },
    locations: postingDefaults?.criteria?.locations || [],
    foldering: postingDefaults?.foldering,
    application: {
      form: {
        type: 'contact-and-questions',
        __typename: 'ContactAndQuestionsJobPostingApplicationForm',
        questionsForm: {
          type: 'template',
          templateId: null,
        },
      },
    },
    published: true, // we want this to be default value even if does not exist yet
  };
};

const JobPostingCreator = ({
  postingDefaults,
  careerPage,
  jobOfferId,
  createJobPosting,
  client,
  clientId,
  t,
}) => {
  const [jobPostingState, setJobPostingState] = useState(
    getDefaultJobPosting({ postingDefaults }),
  );
  const questionsFormOptions = [
    {
      value: null,
      text: t('offers.jobPostings.form.application.noApplicationForms'),
    },
    ..._.map(client.applicationFormTemplates, ({ id, title }) => ({
      value: id,
      text: title,
    })),
  ];
  const handleCreate = async () => {
    const input = getCreateJobPostingInput({
      jobPostingState,
      careerPageId: careerPage?.id,
      jobOfferId,
    });
    await createJobPosting({ input, clientId });
  };

  // console.log('jobPostingState', jobPostingState);
  const applicationFormState = jobPostingState?.application?.form;
  const setApplicationFormState = (applicationFormStateUpdateFunction) =>
    setJobPostingState({
      ...jobPostingState,
      application: {
        ...jobPostingState?.application,
        form: {
          ...applicationFormStateUpdateFunction(applicationFormState),
        },
      },
    });
  const currentApplicationFormOption = _.findWhere(questionsFormOptions, {
    value: applicationFormState?.questionsForm?.templateId,
  })?.text;

  const disableCreation = isInvalidJobPosting({ jobPosting: jobPostingState });
  return (
    <TwoColumnLayout
      leftColumn={
        <div className='job-postings-manager'>
          <div className='job-posting-creator section'>
            <JobPostingFormFields
              formState={jobPostingState}
              setFormState={setJobPostingState}
            />
          </div>
          <div className='section'>
            <div className='input-container'>
              <div className='input-label'>
                {t('offers.jobPostings.form.application.applicationForm')}
              </div>
              <div className='input-element half-width'>
                <ApplicationFormSelector
                  clientId={clientId}
                  setApplicationFormState={setApplicationFormState}
                  currentOption={currentApplicationFormOption}
                  questionsFormOptions={questionsFormOptions}
                  t={t}
                />
              </div>
            </div>
          </div>
        </div>
      }
      rightColumn={
        <div className='job-posting-actions'>
          <div className='section'>
            <h3>{t('offers.jobPostings.actions.hiresweetTips.title')}</h3>
            <div>{t('offers.jobPostings.actions.hiresweetTips.content')}</div>
          </div>
          <GenericButton
            size='big'
            className='button'
            onClick={handleCreate}
            disabled={disableCreation}
          >
            {t('offers.jobPostings.form.create')}
          </GenericButton>
        </div>
      }
    />
  );
};

export default compose(
  withTranslation('translations'),
  withClient,
  withAddJobPosting,
)(JobPostingCreator);
