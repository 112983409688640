import _ from 'underscore';
import classNames from 'classnames';
import React from 'react';
import { HeatMapMapping, HeatMapValue } from './typings';
import styles from './LinearHeatMap.module.less';
import HeatMapItem from './HeatMapItem';

interface LinearHeatMapProps {
  className?: string;
  values: HeatMapValue[];
  mapping: HeatMapMapping;
  labeli18nKey: string;
}
const LinearHeatMap: React.FC<LinearHeatMapProps> = ({
  className,
  values,
  mapping,
  labeli18nKey,
}) => {
  return (
    <div className={classNames(styles['linear-heatmap'], className)}>
      {_.map(values, (value, index) => (
        <HeatMapItem
          mapping={mapping}
          value={value}
          key={index}
          labeli18nKey={labeli18nKey}
        />
      ))}
    </div>
  );
};

export * from './typings';

export default LinearHeatMap;
