/* eslint-disable class-methods-use-this */
import { getValueFromPath } from '../../utils';
import {
  EvaluatorInterface,
  Context,
  BaseVariable,
  PathVariable,
  Variable,
} from '../../types';

export default class ExpressionPathEvaluator implements EvaluatorInterface {
  evaluate({
    context,
    expression,
  }: {
    context: Context;
    expression: Variable;
  }): { value: string | undefined } {
    const expressionType = expression as BaseVariable & PathVariable;
    return {
      value: getValueFromPath({
        object: context,
        path: expressionType.path,
      }),
    };
  }
}
