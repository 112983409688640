import React, { useMemo } from 'react';
import _ from 'underscore';

import { DataVizColors } from '@/less/colors';
import { getFullname } from '@/common/helpers/person';
import { ClientDailyEvent } from '@/types/statistics/clientStatistics';
import { ChartType, Frequency, Mode } from '../GraphWrapper/types';
import { TimeSpan } from '../RevealAnalyticsHeader/RevealAnalyticsHeader';
import { convertClientDailyEventByUser } from './helpers';
import DailyEventsDataContainer from '../DailyEventsDataContainer/DailyEventsDataContainer';

interface CompletedTasksByUserDataContainerProps {
  frequency: Frequency;
  dateRange: TimeSpan;
  type: ChartType;
  mode: Mode;
  clientDailyEvents: ClientDailyEvent[];
  fetchDetails?: (
    startDay: string,
    endDay: string,
    authorEmail: string,
  ) => void;
}

const CompletedTasksByUserDataContainer: React.FC<CompletedTasksByUserDataContainerProps> = ({
  clientDailyEvents,
  frequency,
  dateRange,
  mode,
  type,
  fetchDetails,
}) => {
  const dailyEvents = useMemo(
    () => _.map(clientDailyEvents, convertClientDailyEventByUser),
    [clientDailyEvents],
  );

  const userMap = useMemo(() => {
    const result: { [key: string]: string } = {};
    _.each(clientDailyEvents, ({ userStats }) => {
      _.each(userStats, ({ author }) => {
        result[author.email] = getFullname(author);
      });
    });
    return result;
  }, [clientDailyEvents]);

  const stack = useMemo(() => {
    return _.map(_.keys(userMap), (email, index) => ({
      datakey: email,
      color: DataVizColors[index % DataVizColors.length],
      displayedText: userMap[email],
    }));
  }, [userMap]);

  return (
    <DailyEventsDataContainer
      frequency={frequency}
      dateRange={dateRange}
      type={type}
      mode={mode}
      dailyEvents={dailyEvents}
      stack={stack}
      fetchDetails={fetchDetails}
    />
  );
};

export default CompletedTasksByUserDataContainer;
