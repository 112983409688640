import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { Department } from '../../graphql/fragments/Department';

export const moveClientDepartmentUpward = gql`
  mutation moveClientDepartmentUpward($departmentId: ID!) {
    moveClientDepartmentUpward(departmentId: $departmentId) {
      id
      departments {
        ...Department
      }
    }
  }
  ${Department}
`;

export default graphql(moveClientDepartmentUpward, {
  props: ({ mutate }) => ({
    moveClientDepartmentUpward: ({ departmentId }) =>
      mutate({
        variables: {
          departmentId,
        },
      }),
  }),
});
