import gql from 'graphql-tag';
import MailAccount from './fragments/MailAccount';
import UserPermissions from './fragments/UserPermissions';
import UserSettings, { IUserSettings } from './fragments/UserSettings';
import { UserDisplayPreferences } from './fragments/UserDisplayPreferences';
import Sender from './fragments/Sender';
import { UserClientPermissionsFragment } from './fragments/UserClientPermissions';

export interface GetUserFromJWTokenResult {
  user: User;
}

export interface Scope {
  type: string;
}

export interface ExplicitAllDepartmentScope extends Scope {
  type: 'all-departments';
}

export interface ExplicitDepartmentScope extends Scope {
  type: 'departments';
  departmentIds: string[];
}

export interface ExplicitSectionScope extends Scope {
  type: 'sections';
  sectionIds: string[];
}

export interface ExplicitSubsectionScope extends Scope {
  type: 'subsections';
  subsectionIds: string[];
}

export type DepartmentScopeSet =
  | ExplicitAllDepartmentScope
  | ExplicitDepartmentScope
  | ExplicitSectionScope
  | ExplicitSubsectionScope;

export interface User {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  hasSkippedOnboarding: boolean;
  firstLogin: boolean;
  firstLoginReveal: boolean;
  language?: string;
  isAdmin?: boolean;
  mailAccount: any;
  unbiasedModeEnabled?: boolean;
  onlyReveal?: boolean;
  applicationMode: string;
  permissions: any;
  onboarding: {
    completedDiscover?: boolean;
    completedRelease?: boolean;
  };
  newPricingValidated?: boolean;
  userCurrentClientSettings: {
    id: string;
    departmentScopes: DepartmentScopeSet[];
  };
}

export const GET_USER_FROM_JWTOKEN = gql`
  query getUserFromJWToken {
    user {
      id
      email
      firstname
      lastname
      hasSkippedOnboarding
      firstLogin
      firstLoginReveal
      language
      isAdmin
      mailAccount {
        ...MailAccount
      }
      unbiasedModeEnabled
      onlyReveal
      applicationMode
      permissions {
        ...UserPermissions
      }
      onboarding {
        completedDiscover
        completedRelease
      }
      newPricingValidated
      displayPreferences {
        ...UserDisplayPreferences
      }
      userCurrentClientSettings {
        id
        ...UserClientPermissionsFragment
      }
    }
  }
  ${MailAccount}
  ${UserPermissions}
  ${UserDisplayPreferences}
  ${UserClientPermissionsFragment}
`;

export interface UpdateUserSettingsResult {
  udpateUser: IUserSettings;
}
export interface UpdateUserSettingsVariables {
  clientId: string;
  input: {
    oldPassword?: string;
    newPassword?: string;
    language?: string;
    alias?: string;
    firstLogin?: boolean;
    firstLoginReveal?: boolean;
    signature?: string;
    showConfirmationModal?: boolean;
    unbiasedModeEnabled?: boolean;
    applicationMode?: string;
    newPricingValidated?: boolean;
  };
}
export const UPDATE_USER_SETTINGS = gql`
  mutation updateUser($input: SettingsInput!, $clientId: ID!) {
    updateUser(input: $input, clientId: $clientId) {
      ...UserSettings
    }
  }
  ${UserSettings}
`;

export interface UpdateUserApplicationResult {
  updateUser: {
    id: string;
    applicationMode: string;
    __typename: 'User';
  };
}
export interface UpdateUserApplicationVariables {
  clientId: string;
  input: {
    applicationMode: string;
  };
}
export const UPDATE_USER_APPLICATION_MODE = gql`
  mutation updateUser($input: SettingsInput!, $clientId: ID!) {
    updateUser(input: $input, clientId: $clientId) {
      id
      applicationMode
    }
  }
`;

export interface GetUserSendersVariables {
  granted: boolean;
  owned: boolean;
}

export const GET_USER_SENDERS = gql`
  query getUserSenders($granted: Boolean = true, $owned: Boolean = true) {
    user {
      id
      email
      senders(granted: $granted, owned: $owned) {
        ...Sender
      }
    }
  }
  ${Sender}
`;

export interface GetUserSettingsResult {
  user: IUserSettings;
}

export const GET_USER_SETTINGS = gql`
  query getUserSettings {
    user {
      ...UserSettings
    }
  }
  ${UserSettings}
`;

export const UPDATE_ATS_USER = gql`
  mutation updateATSUser($input: LinkedATSUserInput!) {
    updateLinkedATSUser(input: $input) {
      greenhouseUserId
      greenhouseUserName
      leverUserId
      leverUserName
      smartrecruitersUserId
      smartrecruitersUserName
      teamtailorUserId
      workableUserId
      teamtailorUserName
      workableUserName
      recruiteeUserId
      recruiteeUserName
      recruitcrmUserId
      recruitcrmUserName
      welcomekitUserId
      welcomekitUserName
      boondmanagerUserId
      boondmanagerUserName
      ashbyUserId
      ashbyUserName
      pinpointUserId
      pinpointUserName
    }
  }
`;
