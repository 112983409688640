import React from 'react';
import _, { compose } from 'underscore';
import { Table, Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { fullName } from '../helpers';

const OwnedSendersTable = ({
  senders,
  withEdit,
  onEditSender,
  onDeleteSender,
  t,
}) => {
  if (_.isEmpty(senders)) {
    return null;
  }
  return (
    <Table className='senders-settings-table' textAlign='center' basic>
      <SendersTableHeader withEdit={withEdit} t={t} />
      <SendersTableBody
        senders={senders}
        withEdit={withEdit}
        onEditSender={onEditSender}
        onDeleteSender={onDeleteSender}
        t={t}
      />
    </Table>
  );
};

export const SendersTableHeader = ({ withEdit, t }) => {
  return (
    <Table.Header className='senders-table-header'>
      <Table.Row>
        <Table.HeaderCell width={4}>
          {t('settings.senders.senderAddress')}
        </Table.HeaderCell>
        <Table.HeaderCell width={3}>
          {t('settings.senders.users')}
        </Table.HeaderCell>
        {withEdit && (
          <Table.HeaderCell width={2}>
            {t('settings.senders.actions')}
          </Table.HeaderCell>
        )}
      </Table.Row>
    </Table.Header>
  );
};

export const SendersTableBody = ({
  senders,
  withEdit,
  onEditSender,
  onDeleteSender,
  t,
}) => {
  return (
    <Table.Body>
      {_.map(senders, (sender) => (
        <SendersTableRow
          key={sender.id}
          sender={sender}
          withEdit={withEdit}
          onEditSender={onEditSender}
          onDeleteSender={onDeleteSender}
          t={t}
        />
      ))}
    </Table.Body>
  );
};

export const SendersTableRow = ({
  sender,
  withEdit,
  onEditSender,
  onDeleteSender,
  t,
}) => {
  const { id, senderAddress, users } = sender || {};

  const onEdit = () => {
    onEditSender({ senderId: id });
  };

  const onDelete = () => {
    onDeleteSender({ senderId: id });
  };

  return (
    <Table.Row>
      <Table.Cell>{senderAddress}</Table.Cell>
      <Table.Cell>
        {_.map(users, (user) => (
          <div className='shared-with-row' key={user?.email}>
            {fullName(user)}
          </div>
        ))}
      </Table.Cell>
      {withEdit && (
        <Table.Cell collapsing>
          <div className='edit-cell'>
            <Button className='tertiary' type='button' onClick={onEdit}>
              <i className='ri-edit-line' />
              {t('settings.senders.editButton')}
            </Button>
            <Button className='tertiary' type='button' onClick={onDelete}>
              <i className='ri-delete-bin-line' />
              {t('settings.senders.deleteButton')}
            </Button>
          </div>
        </Table.Cell>
      )}
    </Table.Row>
  );
};

export default compose(withTranslation('translations'))(OwnedSendersTable);
