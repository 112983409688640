import axios from 'axios';
import { useEffect, useState } from 'react';

export const getRelayState = (redirectTo: Location): string => {
  const { pathname = '/', hash = '', search = '' } = redirectTo || {};
  const redirection = encodeURIComponent(btoa(pathname + search + hash));
  return `redirectTo=${redirection}&host=${window.location.host}`;
};

export const decodeRelayState = (relayState = ''): string => {
  return atob(decodeURIComponent(relayState));
};

export const getSSOUrl = (clientId: string, redirectTo: Location): string =>
  `${process.env.REACT_APP_SERVER_URL}/auth/saml/${clientId}/login?${getRelayState(redirectTo)}`;

const getJWToken = async (exchangeToken: string, clientId: string) => {
  if (!exchangeToken || !clientId) {
    return;
  }
  const { headers } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/saml/${clientId}/exchange`, {
    tokenId: exchangeToken,
  });
  const jwt = headers.authorization;
  if (jwt) {
    localStorage.setItem('token', jwt);
  }
};

export interface UseExchangeTokenState {
  loading: boolean;
  success: boolean;
  error: boolean;
}

export const useExchangeToken = (exchangeToken: string, clientId: string): UseExchangeTokenState => {
  const [state, setState] = useState<UseExchangeTokenState>({ loading: true, success: false, error: false });
  useEffect(() => {
    getJWToken(exchangeToken, clientId)
      .then(() => {
        setState({ loading: false, success: true, error: false });
      })
      .catch(() => {
        setState({ loading: false, success: false, error: true });
      });
  }, [clientId, exchangeToken]);
  return state;
};
