import { literalTypeChecker } from '@/common/utils/types';

export const remotePolicies = [
  'mainly-on-site',
  'hybrid-remote',
  'near-full-remote',
  'full-remote',
] as const;

export type RemotePolicy = typeof remotePolicies[number];

export const isRemotePolicy = literalTypeChecker(remotePolicies);
