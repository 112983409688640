import React, { FC, useCallback, useMemo, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { SharingSettings } from '@/types/savedSearch';
import SidePanel from '@/components/SidePanel';
import GenericTextInput from '@/components/Common/GenericTextInput';
import GenericSelect from '@/components/Common/GenericSelect';
import { isSharingSettingsType } from '@/common/helpers/savedSearch';
import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';
import InfoTooltip from '@/components/Common/InfoTooltip';
import ProjectDropdown from '@/components/Common/ProjectDropdown/ProjectDropdown';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import GenericButton from '@/components/Common/GenericButton';
import useCreateSavedSearch from '@/graphql/hooks/savedSearches/useCreateSavedSearch';

import styles from './CreateSavedSearchSidebar.module.less';

interface CreateSavedSearchSidebarProps {
  criteria: { free?: string };
  close: (id?: string) => void;
  defaultSharingSettingsType?: SharingSettings['type'];
  lockSharingSettings?: boolean;
  lockProject?: boolean;
}

const CreateSavedSearchSidebar: FC<CreateSavedSearchSidebarProps> = ({
  criteria,
  close,
  defaultSharingSettingsType = 'only-author',
  lockSharingSettings = false,
  lockProject = false,
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const { missionId } = useParams<{ missionId?: string }>();
  const [sharingSettings, setSharingSettings] = useState<
    SharingSettings['type']
  >(defaultSharingSettingsType);
  const [assignToProject, setAssignToProject] = useState(!!missionId);
  const [assignedProjectId, setAssignedProjectId] = useState<
    string | undefined
  >(missionId);

  const {
    jobOptions: projects,
    jobOptionsLoading: projectsLoading,
  } = useSearchPoolJobOptions('reveal');

  const [createSavedSearch] = useCreateSavedSearch();

  const projectsMap = useMemo(() => _.indexBy(projects, 'id'), [projects]);

  const checkAssignToProject = useCallback(
    () =>
      setAssignToProject((current) => {
        const result = !current;
        if (!result) setAssignedProjectId(undefined);
        return result;
      }),
    [],
  );

  const assignedProject = assignedProjectId
    ? projectsMap[assignedProjectId]
    : undefined;

  const handleSave = async () => {
    const saveResult = await createSavedSearch({
      title,
      sharingSettings: { type: sharingSettings },
      scope: assignedProjectId
        ? { type: 'assigned-to-project', assignedProjectId }
        : { type: 'global' },
      criteria,
    });
    close(saveResult.data?.revealSavedSearch.create.revealSavedSearch.id);
  };

  const sharingSettingsLabelsMap: Record<
    SharingSettings['type'],
    string
  > = useMemo(
    () => ({
      'only-author': t(
        'reveal.searchView.savedSearches.savePanel.visibility.only-author',
      ),
      'all-users': t(
        'reveal.searchView.savedSearches.savePanel.visibility.all-users',
      ),
    }),
    [t],
  );

  const sharingSettingsOptions = useMemo(
    () =>
      _.map(sharingSettingsLabelsMap, (label, value) => ({
        label,
        value: value as SharingSettings['type'],
      })),
    [sharingSettingsLabelsMap],
  );

  return (
    <SidePanel
      title={t('reveal.searchView.savedSearches.savePanel.title')}
      onClose={close}
      footerContent={
        <GenericButton
          size='big'
          onClick={handleSave}
          disabled={
            title === '' || (assignToProject && assignedProjectId === undefined)
          }
        >
          {t('reveal.searchView.savedSearches.savePanel.save')}
        </GenericButton>
      }
    >
      <h3 className={styles.title}>
        {t('reveal.searchView.savedSearches.savePanel.name.title')}
      </h3>
      <GenericTextInput
        value={title}
        onValue={setTitle}
        className={styles.field}
      />
      {!lockSharingSettings && (
        <>
          <h3 className={styles.title}>
            {t('reveal.searchView.savedSearches.savePanel.visibility.title')}
          </h3>
          <GenericSelect
            options={sharingSettingsOptions}
            value={{
              label: sharingSettingsLabelsMap[sharingSettings],
              value: sharingSettings,
            }}
            onChange={(value) => {
              if (!value) return;
              if (isSharingSettingsType(value?.value)) {
                setSharingSettings(value.value);
              }
            }}
          />
        </>
      )}
      {!lockProject && (
        <>
          <h3 className={styles.title}>
            {t(
              'reveal.searchView.savedSearches.savePanel.assignToProject.title',
            )}
          </h3>
          <LabeledCheckbox
            checked={assignToProject}
            label={
              <>
                {t(
                  'reveal.searchView.savedSearches.savePanel.assignToProject.checkboxLabel',
                )}
                <InfoTooltip>
                  {t(
                    'reveal.searchView.savedSearches.savePanel.assignToProject.tooltip',
                  )}
                </InfoTooltip>
              </>
            }
            onClick={checkAssignToProject}
          />
          {assignToProject && !projectsLoading && (
            <ProjectDropdown
              projects={projects || []}
              currentMission={assignedProject}
              onProjectSelected={({ id }) => setAssignedProjectId(id)}
            />
          )}
        </>
      )}
    </SidePanel>
  );
};

export default CreateSavedSearchSidebar;
