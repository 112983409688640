import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import MinimalTask from '@/graphql/fragments/MinimalTask';
import ContactFlow from '../../graphql/fragments/ContactFlow';

export const mutation = gql`
  mutation deleteContactFlowAction(
    $searchPoolId: ID!
    $input: DeleteContactFlowActionInput
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      deleteContactFlowAction(input: $input) {
        profile {
          id
          contactFlow {
            ...ContactFlow
          }
          currentSequenceInfo {
            sequenceId
            currentTask {
              ...MinimalTask
            }
          }
        }
        task {
          id
          state
        }
      }
    }
  }
  ${MinimalTask}
  ${ContactFlow}
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    deleteContactFlowAction: ({ searchPoolId, input }) =>
      mutate({
        variables: { searchPoolId, input },
      }),
  }),
});
