import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { Form, Input, Modal } from 'semantic-ui-react';
import classNames from 'classnames';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import { ConditionsChainingVariable } from '@/common/mergeTags/utils';
import GenericModal from '@/components/Common/GenericModal';
import GenericButton from '@/components/Common/GenericButton';
import { useConditionsChainingContext } from './useConditionsChainingContext';

import styles from '../SmartVariableWithPreviewModal.module.less';
import ConditionsChainingForm from './ConditionsChainingForm';
import ConditionsChainingPreview from './ConditionsChainingPreview';
import { getInitialConditionsChainingState } from './utils';
import useConditionsChainingCustomFields from './useConditionsChainingCustomFields';

type Props = {
  open: boolean;
  exampleVariable: SweetEvaluatorTypes.BaseVariable &
    ConditionsChainingVariable;
  onClose: () => void;
  onSubmit: (
    exampleVariable: SweetEvaluatorTypes.BaseVariable &
      ConditionsChainingVariable,
  ) => void;
  isEditMode?: boolean;
};

const ConditionsChainingModal: React.FC<Props> = ({
  open,
  exampleVariable,
  onClose,
  onSubmit,
  isEditMode = false,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState(exampleVariable?.name || '');

  const {
    conditionsChainingState,
    setConditionsChainingState,
    clientId,
  } = useConditionsChainingContext();
  const {
    profileCustomFields,
    missionCustomFields,
  } = useConditionsChainingCustomFields({ clientId });

  const isSimplifiedVariable = exampleVariable.subtype === 'switch-on-field';

  const isProfileCustomFieldCondition = exampleVariable.id.startsWith(
    'profile-custom-field/',
  );
  const isMissionCustomFieldCondition = exampleVariable.id.startsWith(
    'mission-custom-field/',
  );

  useEffect(() => {
    const initialState = getInitialConditionsChainingState({
      variable: exampleVariable,
      profileCustomFields,
      missionCustomFields,
      t,
    });
    if (initialState) {
      setConditionsChainingState(initialState);
    }
  }, [
    exampleVariable,
    missionCustomFields,
    profileCustomFields,
    t,
    setConditionsChainingState,
  ]);

  const handleSubmit = () => {
    onSubmit({
      ...exampleVariable,
      id:
        isProfileCustomFieldCondition || isMissionCustomFieldCondition
          ? exampleVariable.id.split('/')[1]
          : exampleVariable.id,
      name,
      ifStatements: conditionsChainingState.ifStatements,
      ...(!_.isEmpty(conditionsChainingState.elseStatement) && {
        elseStatement: conditionsChainingState.elseStatement,
      }),
    });
    onClose();
  };

  useEffect(() => {
    if (exampleVariable?.name) {
      setName(exampleVariable.name);
    }
  }, [exampleVariable]);

  return (
    <GenericModal
      size={isSimplifiedVariable ? 'large' : 'fullscreen'}
      open={open}
      onClose={onClose}
    >
      <Modal.Header>{t('editor.variableExemplePreview.title')}</Modal.Header>
      <Modal.Content className={styles.content}>
        <Form className={styles.form}>
          <div className={styles.left}>
            <div className={styles.padding}>
              <Form.Field>
                <label>{t('editor.variableExemplePreview.name')}</label>
                <Input
                  value={name}
                  onChange={(e, { value }) => setName(value as string)}
                  className={styles.input}
                />
              </Form.Field>

              <ConditionsChainingForm />
            </div>
          </div>
          <div
            className={classNames(
              styles.right,
              isSimplifiedVariable && styles.rightBig,
            )}
          >
            <div className={styles.padding}>
              <ConditionsChainingPreview />
            </div>
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <GenericButton
          type='button'
          primacy='secondary'
          size='big'
          onClick={onClose}
        >
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          size='big'
          primacy='primary'
          type='submit'
          onClick={handleSubmit}
        >
          {isEditMode ? t('common.edit') : t('common.create')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default ConditionsChainingModal;
