import React from 'react';
import classNames from 'classnames';
import { Button } from 'semantic-ui-react';


import './ButtonToggle.css';

const ButtonToggle = ({ className, currentValue, options, setValue }) => {
  return (
    <Button.Group className={classNames(className, 'button-toggle')}>
      <Button
        className={classNames(currentValue === options[0]?.value && 'active')}
        onClick={() => setValue(options[0]?.value)}
      >
        {options[0]?.label}
      </Button>
      <Button
        className={classNames(currentValue === options[1]?.value && 'active')}
        onClick={() => setValue(options[1]?.value)}
      >
        {options[1]?.label}
      </Button>
    </Button.Group>
  );
};

export default ButtonToggle;
