import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const mutation = gql`
  mutation deleteEmailData($token: String!, $type: String!) {
    deleteEmailData(token: $token, type: $type)
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    deleteEmailData: ({ token, type }) =>
      mutate({
        variables: {
          token,
          type,
        },
      }),
  }),
});
