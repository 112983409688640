import gql from 'graphql-tag';

export default gql`
    fragment BaseOffer on Offer {
        id
        title
        isInstantFlow
        criteriaEditionAllowed
        isHiresweetWatch
        isUnclassified
        isWaitingForValidation
        isHold
        isArchived
        isSuggestedForCreation
    }
`;
