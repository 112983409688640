import useClientId from '@/hooks/router/useClientId';
import { QueryHookOptions, gql, useMutation, useQuery } from '@apollo/client';

const GET_CLIENT_DEPARTMENT_NOMENCLATURE = gql`
  query getClientDepartmentNomenclature($clientId: ID!) {
    client(id: $clientId) {
      id
      departmentNomenclature {
        department {
          id
          type
        }
        section {
          id
          type
        }
        subsection {
          id
          type
        }
      }
    }
  }
`;

export function useClientDepartmentNomenclature({ queryOptions = {} }: { queryOptions?: QueryHookOptions } = {}) {
  const clientId = useClientId();
  const { data, ...rest } = useQuery(GET_CLIENT_DEPARTMENT_NOMENCLATURE, {
    variables: {
      clientId,
    },
    ...queryOptions,
  });

  return {
    departmentNomenclature: data?.client?.departmentNomenclature,
    ...rest,
  }
}

const UPDATE_CLIENT_DEPARTMENT_NOMENCLATURE = gql`
  mutation updateClientDepartmentNomenclature($input: UpdateDepartmentNomenclatureInput!) {
    updateDepartmentNomenclature(input: $input) {
      id
      departmentNomenclature {
        department {
          id
          type
        }
        section {
          id
          type
        }
        subsection {
          id
          type
        }
      }
      id
    }
  }
`;

export function useUpdateClientDepartmentNomenclature() {
  return useMutation(UPDATE_CLIENT_DEPARTMENT_NOMENCLATURE);
}