import _ from 'underscore';
import { TFunction } from 'i18next';

import { DailyCohortDetails } from '@/graphql/hooks/clients/useClientDailyCohortsDetails';
import { SequenceDetail } from '@/routes/RevealView/RevealAnalytics/components/StatsProfileAndSequenceTable/types';
import {
  DisplayedTaskType,
  isSequenceTaskType,
} from '@/types/statistics/clientStatistics';
import { i18nKeysByTaskType } from '@/routes/RevealView/RevealAnalytics/tabs/SequencesTab/helpers/details';

export function getSequenceDetailsFromDailyCohorts(
  dailyCohortDetails: DailyCohortDetails[],
  taskType?: DisplayedTaskType,
): SequenceDetail[] {
  if (!taskType) {
    return [];
  }
  const result: SequenceDetail[] = [];
  _.each(dailyCohortDetails, ({ day, details }) => {
    _.each(details, ({ type, profileItems, sequenceId: detailSequenceId }) => {
      if (!isSequenceTaskType(type) || type !== taskType) {
        return;
      }
      _.each(profileItems, ({ profileId }) => {
        result.push({
          profileId,
          sequenceId: detailSequenceId,
          day,
        });
      });
    });
  });
  return result;
}

export const getModalTitle = (
  t: TFunction,
  taskType?: DisplayedTaskType,
): string | undefined =>
  taskType
    ? t(`reveal.analyticsView.sequences.${i18nKeysByTaskType[taskType]}`)
    : undefined;
