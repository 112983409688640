import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Dropdown, Form, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { getSelectFieldOptions, SimplifiedField } from './utils';
import styles from './ConditionsChaining.module.less';
import useConditionsChainingCustomFields from './useConditionsChainingCustomFields';

interface Props {
  field: SimplifiedField;
  clientId: string;
  context: Record<string, any>;
  onChange: ({
    key,
    field,
    newValue,
  }: {
    key: string;
    field: SimplifiedField;
    newValue: string;
  }) => void;
}

const ConditionsChainingPreviewFormField: React.FC<Props> = ({
  field,
  clientId,
  context,
  onChange,
}) => {
  const { t } = useTranslation();
  const {
    profileCustomFields,
    missionCustomFields,
    profileCustomFieldsMap,
    missionCustomFieldsMap,
  } = useConditionsChainingCustomFields({ clientId });

  const fieldLabel = useMemo(() => {
    if (field.fieldCategory === 'custom-field') {
      return profileCustomFieldsMap[field.fieldId]?.title ?? '';
    }
    if (field.fieldCategory === 'linked-mission-custom-field') {
      return missionCustomFieldsMap[field.fieldId]?.title ?? '';
    }
    return t(
      `editor.conditionsChainingVariable.constructionOptions.${field.fieldId}`,
    );
  }, [
    profileCustomFieldsMap,
    missionCustomFieldsMap,
    t,
    field.fieldCategory,
    field.fieldId,
  ]);

  const fieldValueFromContext = useMemo(() => {
    if (field.fieldCategory === 'custom-field') {
      return context.customFields?.[field.fieldId]?.value;
    }
    if (field.fieldCategory === 'linked-mission-custom-field') {
      return context.missionCustomFields?.[field.fieldId]?.value;
    }
    return _.isObject(context[field.fieldId])
      ? context[field.fieldId]?.value
      : context[field.fieldId];
  }, [context, field.fieldCategory, field.fieldId]);

  if (field.fieldType === 'string') {
    return (
      <Form.Field
        className={classNames(styles.statement, styles.columnStatement)}
      >
        <label>{fieldLabel}</label>
        <Input
          value={fieldValueFromContext}
          className={styles.input}
          onChange={(_event, { value: newValue }) =>
            onChange({ key: field.fieldId, field, newValue })
          }
        />
      </Form.Field>
    );
  }
  if (field.fieldType === 'select') {
    return (
      <Form.Field
        className={classNames(styles.statement, styles.columnStatement)}
      >
        <label>{fieldLabel}</label>
        <Dropdown
          className={styles.input}
          options={getSelectFieldOptions({
            fieldCategory: field.fieldCategory,
            fieldId: field.fieldId,
            profileCustomFields,
            missionCustomFields,
            t,
          })}
          search
          selection
          fluid
          value={fieldValueFromContext}
          onChange={(_event, { value }) =>
            onChange({ key: field.fieldId, field, newValue: value as string })
          }
        />
      </Form.Field>
    );
  }
  return null;
};

export default ConditionsChainingPreviewFormField;
