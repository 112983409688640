import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SearchPoolProfileState from '../../graphql/fragments/SearchPoolProfileState';
import { refetchQuery } from '../profiles/withRemoveProfile';
import { query as profileQuery } from '../profiles/withProfile';

export const removeFromJob = gql`
  mutation removeFromJob(
    $searchPoolId: ID!
    $profileId: ID!
    $clientProfileId: ID!
    $jobOfferId: ID!
  ) {
    removeFromJob(
      searchPoolId: $searchPoolId
      profileId: $profileId
      clientProfileId: $clientProfileId
      jobOfferId: $jobOfferId
    ) {
      ...SearchPoolProfileState
    }
  }
  ${SearchPoolProfileState}
`;

export default graphql(removeFromJob, {
  props: ({ mutate }) => ({
    removeFromJob: ({ searchPoolId, profileId, clientProfileId, jobOfferId }) =>
      mutate({
        variables: { searchPoolId, profileId, clientProfileId, jobOfferId },
        // TODO: fetch other steps ?
        refetchQueries: [
          {
            query: refetchQuery,
            variables: { offerId: jobOfferId, step: 'pending', search: '' },
            fetchPolicy: 'network-only',
          },
          {
            query: refetchQuery,
            variables: { offerId: jobOfferId, step: '', search: '' },
            fetchPolicy: 'network-only',
          },
          {
            query: profileQuery,
            variables: { id: clientProfileId },
            fetchPolicy: 'network-only',
          },
        ],
      }),
  }),
});
