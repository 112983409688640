import React, { useState } from 'react';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';

import { Button, Modal, Input } from 'semantic-ui-react';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import { isEmail } from '../../../common';
import contextToProps from '../../../hocs/contextToProps';
import withAddCollaborator from '../../../hocs/users/withAddCollaborator';

const AddUserModal = ({
  newCollaboratorOpen,
  setNewCollaboratorOpen,
  addUser,
  onShowNotification,
  t,
}) => {
  const [email, setEmail] = useState('');
  const [sendingInvite, setSendingInvite] = useState(false);
  const [invalidEmailAddressError, setInvalidEmailAddressError] = useState(
    false,
  );

  const sendInvite = () => {
    if (!isEmail(email)) {
      setInvalidEmailAddressError(true);
      return;
    }
    setSendingInvite(true);
    setInvalidEmailAddressError(false);
    addUser({ email })
      .then(() => {
        onShowNotification({
          message: t('collaborators.notifications.inviteSend'),
          level: 'success',
        });
        setSendingInvite(false);
        setEmail('');
        setNewCollaboratorOpen(false);
      })
      .catch(() => {
        onShowNotification({
          message: t('common.genericError'),
          level: 'error',
        });
        setSendingInvite(false);
        setEmail('');
      });
  };
  return (
    <GenericModal
      open={newCollaboratorOpen}
      onClose={() => setNewCollaboratorOpen(false)}
      size='tiny'
    >
      <Modal.Header>{t('dashboard.users.inviteUser')}</Modal.Header>
      <Modal.Content>
        <div className='input-container'>
          <div className='input-label'>
            {t('collaborators.pendingSection.email')}
          </div>

          <div className='input-element'>
            <Input
              type='email'
              fluid
              placeholder={t('collaborators.pendingSection.email')}
              name='email'
              value={email}
              onChange={(ev) => {
                setEmail(ev.target.value);
                setInvalidEmailAddressError(false);
              }}
            />
          </div>
          {invalidEmailAddressError && (
            <div className='input-error '>
              {t('dashboard.users.invalidEmailError')}
            </div>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions className='modal-actions-buttons'>
        <div className='flex-spaced-between'>
          <GenericButton
            primacy='secondary'
            size='big'
            onClick={() => setNewCollaboratorOpen(false)}
          >
            {t('common.cancel')}
          </GenericButton>
          <GenericButton
            onClick={sendingInvite ? () => {} : () => sendInvite()}
            size='big'
            disabled={sendingInvite}
          >
            {t('collaborators.pendingSection.submit')}
          </GenericButton>
        </div>
      </Modal.Actions>
    </GenericModal>
  );
};

export default compose(
  contextToProps,
  withTranslation('translations'),
  withAddCollaborator,
)(AddUserModal);
