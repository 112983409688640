import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Billing from '../../graphql/fragments/Billing';
import PricingPlanConfiguration from '../../graphql/fragments/PricingPlanConfiguration';

export const query = gql`
  query getClientBilling($id: ID!) {
    client(id: $id) {
      id
      billing {
        ...Billing
      }
      pricingPlanConfiguration {
        ...PricingPlanConfiguration
      }
    }
  }
  ${Billing}
  ${PricingPlanConfiguration}
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.clientId,
    },
  }),
  props: ({ data: { loading, client, error } }) => ({
    loading,
    clientBilling: client?.billing,
    pricingPlanConfiguration: client?.pricingPlanConfiguration,
    error,
  }),
});
