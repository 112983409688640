import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import {
  CustomRadio,
  CustomRange,
  CustomSelect,
  SalaryRange,
} from '../../CustomFormItems';
import { FormOptions, HandleChangeArguments } from '../types';
import { RevealFormValue } from './types';

interface RevealGeneralCriteriaFormProps {
  current: RevealFormValue;
  options: FormOptions;
  handleChange: <T extends keyof RevealFormValue>(
    args: HandleChangeArguments<T>,
  ) => void;
  errorFields: string[];
  modifyingNotification: (label: string) => ReactNode;
}

const RevealGeneralCriteriaForm: FC<RevealGeneralCriteriaFormProps> = ({
  current,
  options,
  handleChange,
  errorFields,
  modifyingNotification,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className='form-row'>
        <div className='form-box'>
          <div
            className={`form-field-label ${
              _.indexOf(errorFields, 'jobPositions') >= 0 ? ' is-error' : ''
            }`}
          >
            {t('criteria.jobPosition', { count: 0 })}
            {modifyingNotification('jobPositions')}
          </div>
          <div className='form-field-input'>
            <CustomSelect
              label='jobPositions'
              multi={false}
              value={current.jobPosition}
              options={(options || {}).jobPositions}
              onChange={handleChange}
              placeholderText={t('criteria.jobPositionsPlaceholder')}
            />
          </div>
        </div>
        <div className='form-box'>
          <div
            className={`form-field-label${
              _.indexOf(errorFields, 'locations') >= 0 ? ' is-error' : ''
            }`}
          >
            {t('criteria.location', { count: 0 })}
            {modifyingNotification('locations')}
          </div>
          <div className='form-field-input'>
            <CustomSelect
              label='locations'
              multi
              value={current.locations}
              options={(options || {}).locations}
              onChange={handleChange}
              showBlockTags
              placeholderText={t('criteria.locationsPlaceholder')}
            />
          </div>
        </div>
      </div>
      <div className='form-row'>
        <div className='form-box'>
          <div
            className={`form-field-label${
              _.indexOf(errorFields, 'experience') >= 0 ? ' is-error' : ''
            }`}
          >
            {t('criteria.experienceYears')}
            {modifyingNotification('experience')}
          </div>
          <div className='form-field-input'>
            <CustomRange
              label='experience'
              min={0}
              max={20}
              step={1}
              formatLabel={(value: number) =>
                value === 20 ? '20+' : `${value}`
              }
              currentValue={current.experienceYears}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className='form-box'>
          <div className='form-field-label'>
            {t('criteria.remote.label')}
            {modifyingNotification('remote')}
          </div>
          <div className='form-field-input form-field-input-custom-radio'>
            <CustomRadio
              label='remote'
              currentValue={current.remote}
              options={(options || {}).remote}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className='form-row'>
        <div className='form-box'>
          <div className='form-field-label'>
            {t('criteria.salary')}
            {modifyingNotification('salary')}
          </div>
          <div className='form-field-input'>
            <SalaryRange
              label='salary'
              currentValue={current.salary}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className='form-box' />
      </div>
    </>
  );
};

export default RevealGeneralCriteriaForm;
