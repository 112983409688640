import React from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import { Checkbox, Dropdown, Grid } from 'semantic-ui-react';

const CriterionSelector = ({
  selectedItems,
  setSelectedItems,
  options,
  placeholder,
  onlyCheckboxes,
}) => {


  const onChange = React.useCallback((e, { value }) => {
    setSelectedItems([...(selectedItems || []), value]);
    // eslint-disable-next-line
  }, [selectedItems]);


  const onCheckboxClick = React.useCallback(
    (e, { value, checked }) => {
      if (_.contains(selectedItems, value)) {
        if (!checked) {
          setSelectedItems(
            _.filter(selectedItems, (selectedItem) => selectedItem !== value),
          );
        }
      }
      if (checked) {
        setSelectedItems([...(selectedItems || []), value]);
      }
    },

    // eslint-disable-next-line
    [selectedItems],
  );

  const displayedItems = React.useMemo(() => {
    const allItems = _.map(options, (item) => ({
      ...item,
      selected: _.contains(selectedItems, item.value),
    }));

    return _.filter(
      allItems,
      ({ displayedByDefault, selected }) => displayedByDefault || selected,
    );
  }, [options, selectedItems]);

  const finalOptions = React.useMemo(
    () =>
      _.map(
        _.filter(options, ({ value }) => !_.contains(selectedItems, value)),
        ({ value, text }) => ({ value, text }),
      ),
    [options, selectedItems],
  );

  return (
    <div className='criterion-selector'>
      {!onlyCheckboxes && (
        <Dropdown
          placeholder={placeholder}
          fluid
          search
          selection
          value={null}
          selectOnBlur={false}
          selectOnNavigation={false}
          options={finalOptions}
          onChange={onChange}
        />
      )}
      <Grid container className='criterion-displayed-items'>
        {_.map(displayedItems, ({ selected, value, text }) => (
          <Grid.Column
            width={8}
            key={value}
            className={classNames('item', selected && 'selected')}
          >
            <Checkbox
              checked={selected}
              label={text}
              value={value}
              onClick={onCheckboxClick}
            />
          </Grid.Column>
        ))}
      </Grid>
    </div>
  );
};

export default CriterionSelector;
