import React from 'react';
import _, { compose } from 'underscore';
import { withRouter } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import withClientAnalyticsOffers from '../../../../../hocs/clients/withClientAnalyticsOffers';
import CohortsDataContainer from './CohortsDataContainer';

import './Cohorts.css';
import { CohortsGraphStub } from './CohortsGraph';

class Cohorts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offerGroup: props.offerId || 'all',
      frequency: null,
    };
  }

  onChangeOfferGroup = (e, { value }) => {
    const { location, history } = this.props;
    this.setState({ offerGroup: value });
    history.replace({ ...location, search: '' }); // reset querystring
    // this.setState({ frequency: null }); // recompute default value
  };

  onChangeFrequency = (e, { value }) => {
    this.setState({ frequency: value });
  };

  setFrequency = ({ frequency }) => {
    this.setState({ frequency });
  };

  formatOfferGroups = () => {
    const { offers, t } = this.props;
    const filteredOffers = _.where(offers, { isUnclassified: false });
    const options = _.map(filteredOffers, (offer) => {
      return {
        key: offer.id,
        value: offer.id,
        text: offer.title,
      };
    });
    const all = {
      key: 'all',
      value: 'all',
      text: t('analytics.offerGroups.all'),
    };
    return [all].concat(options);
  };

  offerGroupIds = () => {
    const { offerGroup } = this.state;
    if (offerGroup === 'all') {
      // a query with empty offerIds is equivalent to requesting all offerIds
      return [];
    }
    return [offerGroup];
  };

  frequencyOptions = () => {
    const { t } = this.props;
    const values = ['day', 'week', 'month', 'quarter'];
    const formatValue = (value) => ({
      key: value,
      value,
      text: t(`analytics.frequency.${value}`),
    });
    return _.map(values, formatValue);
  };

  render() {
    const { clientId, t, fullStatisticsLoading } = this.props;
    const { offerGroup, frequency } = this.state;
    return (
      <div className='section analytics-cohorts'>
        <div className='header'>
          <h3>{t('analytics.headers.cohorts')}</h3>
        </div>
        <br />
        <div className='cohorts-body-container'>
          <div className='twin-selector-container'>
            <div className='twin-selector-left'>
              <div className='twin-selector-label'>
                {t('analytics.dropdownLabels.offerGroup')}
              </div>
              <div className='twin-selector-dropdown'>
                <Dropdown
                  fluid
                  selection
                  onChange={this.onChangeOfferGroup}
                  value={offerGroup}
                  options={this.formatOfferGroups()}
                />
              </div>
            </div>
            <div className='twin-selector-right'>
              <div className='twin-selector-label'>
                {t('analytics.dropdownLabels.frequency')}
              </div>
              <div className='twin-selector-dropdown'>
                <Dropdown
                  fluid
                  selection
                  onChange={this.onChangeFrequency}
                  value={frequency}
                  options={this.frequencyOptions()}
                />
              </div>
            </div>
          </div>
          <div className='analytics-cohorts-container'>
            {fullStatisticsLoading ? (
              <CohortsGraphStub />
            ) : (
              <CohortsDataContainer
                clientId={clientId}
                offerIds={this.offerGroupIds()}
                frequency={frequency}
                setFrequency={this.setFrequency}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withTranslation('translations'),
  withClientAnalyticsOffers,
)(Cohorts);
