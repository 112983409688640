import React, { useEffect, useState } from 'react';
import { Popup } from 'semantic-ui-react';

import { useTranslation } from 'react-i18next';
import { isOneLineText } from '@/common/mergeTags/utils';
import styles from './Placeholder.module.less';
import { getInstantiatedEntityText } from '../helpers';
import FragmentPopupContent from './FragmentPopupContent';

const replaceMergeTagsIdsByName = ({
  text,
  snippets,
  clientSnippets,
  clientCustomFields,
  clientMissionCustomFields,
  t,
}) => {
  return text.replace(/\{{([^{}]*)}}/g, (match, key) => {
    const entityProfileContent = getInstantiatedEntityText({
      snippets,
      clientSnippets,
      clientCustomFields,
      missionCustomFields: clientMissionCustomFields,
      keyParam: key,
      t,
    });
    return `<span class='merge-tags-deep-snippet-value'>${entityProfileContent ||
      key}</span>`;
  });
};

const SyncedFragmentPlaceholder = ({
  offsetKey,
  readOnly,
  setIsReadOnly,
  children,
  handleUnsyncFragment,
  clientFragment,
  snippets,
  clientSnippets,
  clientCustomFields,
  clientMissionCustomFields,
}) => {
  const { t } = useTranslation();
  const [clientFragmentHTML, setClientFragmentHTML] = useState('');
  const [open, setIsOpen] = useState(false);

  useEffect(() => {
    if (!clientFragment?.text) {
      return;
    }
    const html = replaceMergeTagsIdsByName({
      text: clientFragment.text,
      snippets: clientFragment.snippets,
      clientSnippets,
      clientCustomFields,
      clientMissionCustomFields,
      t,
    });
    setClientFragmentHTML(
      isOneLineText({ text: html })
        ? `<span>${html}</span>`
        : `<div>${html}</div>`,
    );
  }, [
    clientFragment,
    snippets,
    clientSnippets,
    t,
    clientCustomFields,
    clientMissionCustomFields,
  ]);

  const handleOpen = (event) => {
    event.preventDefault();
    setIsReadOnly(true);
    setIsOpen(true);
  };

  const handleClose = (event) => {
    event.preventDefault();
    setIsReadOnly(false);
    setIsOpen(false);
  };

  const handleUnsyncFragmentClick = () => {
    setIsOpen(false);
    handleUnsyncFragment(clientFragment);
  };

  const getSpanDisplayed = () => {
    if (clientFragment) {
      return (
        <span
          spellCheck={false}
          data-offset-key={offsetKey}
          className={styles.fragmentPlaceholder}
          readOnly
          contentEditable={false}
          dangerouslySetInnerHTML={{
            __html: clientFragmentHTML,
          }}
        />
      );
    }
    return (
      <span
        spellCheck={false}
        data-offset-key={offsetKey}
        readOnly
        contentEditable={false}
        style={{
          backgroundColor: '#E2EBFF',
          padding: '3px 7px',
          borderRadius: '1em',
          color: '#1F2E77',
          fontWeight: '500',
          cursor: !readOnly ? 'pointer' : 'initial',
        }}
      >
        {children}
      </span>
    );
  };

  const span = getSpanDisplayed();

  if (!clientFragment) {
    return span;
  }

  return (
    <Popup
      open={open}
      className={clientFragment ? styles.fragmentPopupWrapper : ''}
      style={{ padding: '0px' }}
      content={
        <FragmentPopupContent
          clientFragment={clientFragment}
          onUnsyncFragment={handleUnsyncFragmentClick}
          t={t}
        />
      }
      on='click'
      trigger={span}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  );
};

export default SyncedFragmentPlaceholder;
