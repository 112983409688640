import React from 'react';
import { Button } from 'semantic-ui-react';
import _ from 'underscore';
import { getTranslatedText } from '../../common';

export const getUserLabel = (user: any) => {
  return (
    `${user?.firstname || ''} ${user?.lastname || ''}`.trim() ||
    user?.email ||
    ''
  );
};

export const getUserOptions = (users?: any) => {
  return _.chain(users)
    .map((user) => {
      const text = getUserLabel(user);
      if (!text) {
        return null;
      }
      return {
        text,
        value: user?.email,
      };
    })
    .compact()
    .sortBy('text')
    .value();
};

export const getProjectOptions = (projects?: any, selectedJobId?: string) => {
  const manualActiveProjects = _.filter(
    projects,
    ({ isArchived, isAutomated, jobId, type }) => {
      const excludedProjectTypes = [
        'recommended-profiles-for-job',
        'application-review',
        'imported-profiles',
      ];
      const projectCanBeInOptions =
        !isArchived && !isAutomated && !_.contains(excludedProjectTypes, type);
      if (jobId && selectedJobId) {
        return projectCanBeInOptions && jobId === selectedJobId;
      }
      if (!jobId && !selectedJobId) {
        return projectCanBeInOptions;
      }
      return false;
    },
  );
  return _.map(manualActiveProjects, ({ id, title }) => {
    return {
      text: getTranslatedText(title),
      value: id,
    };
  });
};

// TODO: sort by author and display author
export const getSequenceOptions = (sequences?: any) => {
  return _.map(sequences, ({ id, title }) => {
    return {
      text: title,
      value: id,
    };
  });
};

export const getLinkedinActionsOptions = () => {
  return [
    { value: 'linkedin-send-request', text: 'Send invitation request' },
    { value: 'linkedin-check-request', text: 'Check invitation status' },
    { value: 'linkedin-send-inmail', text: 'Send InMail' },
  ];
};

export const getTemplatesOptions = ({
  templates,
  onEditTemplate,
  onCreateNewTemplate,
}: any) => {
  const currentOptions = _.map(templates, (template) => {
    const { id, title } = template || {};
    return {
      content: (
        <div className='hs-item-content edit'>
          <span>{title}</span>
          <Button
            className='hs-container-button'
            onClick={() => onEditTemplate({ template })}
            type='button'
          >
            <i className='ri-pencil-line' />
          </Button>
        </div>
      ),
      text: title,
      value: id,
    };
  });

  const createTemplateOption = {
    className: 'add-item-content',
    content: (
      <span className='hs-item-content'>
        New template
        <i className='ri-add-line' />
      </span>
    ),
    value: 'new-template',
    text: 'New template',
    onClick: onCreateNewTemplate,
  };

  return [...currentOptions, createTemplateOption];
};

// Pass jobId if you want to force selection on specified id,
// If you have a jobId but want to allow another selection, don't pass jobId but use it as initial value of the state
export const getJobOptions = ({
  jobs,
  jobId,
}: {
  jobs?: any[];
  jobId?: string;
}) => {
  if (!jobs) {
    return [];
  }
  if (jobId) {
    const currentJob = _.findWhere(jobs, { id: jobId });
    return [
      {
        text: currentJob?.data?.title || jobId,
        value: jobId,
      },
    ];
  }
  return _.map(jobs, ({ id, data }) => ({
    text: data?.title,
    value: id,
  }));
};

export const getStageOptions = ({ job }: { job: any }) => {
  const stages = _.map(job?.stages, ({ id, name, position }) => {
    return {
      value: id,
      text: name,
      position,
    };
  });

  return _.sortBy(stages, ({ position }) => position);
};
