import React, { FC } from 'react';
import classNames from 'classnames';

import Help from '@/components/Reveal/Icons/Help';

import GenericTooltip from '../GenericTooltip';
import { GenericTooltipProps } from '../GenericTooltip/GenericTooltip';

import styles from './InfoTooltip.module.less';

type InfoTooltipProps = Omit<GenericTooltipProps, 'trigger'> & {
  iconClassName?: string;
};

const InfoTooltip: FC<InfoTooltipProps> = ({
  children,
  iconClassName,
  ...props
}) => (
  <GenericTooltip
    trigger={
      <span className={classNames(styles.icon, iconClassName)}>
        <Help />
      </span>
    }
    {...props}
  >
    <div className={styles.content}>{children}</div>
  </GenericTooltip>
);

export default InfoTooltip;
