import React, { FC, useMemo, useContext, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';

import useDailyCountReportStreamAggregate from '@/graphql/hooks/clients/useDailyCountReportStreamAggregate';
import { NumberRecord } from '@/types/statistics/stats';
import { useUserAllowedDepartments } from '@/graphql/hooks/users/useUserAllowedDepartments';
import useMissionsWithFoldering from '@/graphql/hooks/searchPoolJobs/useMissionsWithFoldering';

import StatisticsParametersContext from '../../../StatisticsParametersContext';
import GraphWrapper from '../../../components/GraphWrapper/GraphWrapper';
import DailyEventsDataContainer from '../../../components/DailyEventsDataContainer/DailyEventsDataContainer';
import { dailyCountReportStacks } from '../helpers';

import styles from './TasksProcessing.module.less';

const adventureTaskSubtypes = [
  ['start-1', 'start-2'],
  ['followup'],
  ['end-1', 'end-2'],
  ['payment-point'],
  ['intermission-followup'],
];

const reportStreamId = 'adequat-tasks-procesing-L3EKSV';
const stack = dailyCountReportStacks[reportStreamId];

const TasksProcessing: FC = () => {
  const { t } = useTranslation();
  const title = t('reveal.reports.adventure.taskProcessing.title');
  const [{ timeSpan, aggregatePeriod, missionIds }] = useContext(
    StatisticsParametersContext,
  );
  const [types, setTypes] = useState<string | undefined>();

  const taskSubtypeOptions = useMemo(
    () =>
      _.map(adventureTaskSubtypes, (subtypes) => ({
        text: t(`taskType.${subtypes[0]}`),
        value: subtypes.join(':'),
        key: subtypes.join(':'),
      })),
    [t],
  );

  const { subsections } = useUserAllowedDepartments();
  const { missionsWithFoldering } = useMissionsWithFoldering();

  const subsectionIdMap = useMemo(() => {
    const result = {} as Record<string, boolean>;
    _.each(subsections, ({ id }) => {
      result[id] = true;
    });
    return result;
  }, [subsections]);

  const availableMissionIds = useMemo(() => {
    const result = [] as string[];
    _.each(missionsWithFoldering, ({ id, foldering }) => {
      if (
        foldering?.subsection?.id &&
        subsectionIdMap[foldering.subsection.id]
      ) {
        result.push(id);
      }
    });
    return result;
  }, [missionsWithFoldering, subsectionIdMap]);

  const sentMissionIds = useMemo(
    () => (_.isEmpty(missionIds) ? availableMissionIds : missionIds),
    [missionIds, availableMissionIds],
  );

  const {
    dailyCountReportStreamAggregate,
  } = useDailyCountReportStreamAggregate({
    reportStreamId,
    startDate: timeSpan.startDate.format('YYYY-MM-DD'),
    endDate: timeSpan.endDate.format('YYYY-MM-DD'),
    aggregatePeriod,
    scope: [
      { key: 'missionId', value: { in: sentMissionIds } },
      ...(types ? [{ key: 'taskType', value: { in: types.split(':') } }] : []),
    ],
  });

  const dailyEvents = useMemo(
    () =>
      _.map(
        _.sortBy(dailyCountReportStreamAggregate, 'day'),
        ({ day, counts }) => {
          const values = {} as NumberRecord;
          _.each(counts, ({ value, key }) => {
            values[key] = value;
          });
          return {
            day,
            values,
          };
        },
      ),

    [dailyCountReportStreamAggregate],
  );

  return (
    <div className={styles.card}>
      <GraphWrapper
        lockChartType
        lockFrequency
        timeSpan={timeSpan}
        title={<h3 className={styles.title}>{title}</h3>}
        extraHeaderContent={
          <Dropdown
            className='dropdown hiresweet-rounded'
            selection
            clearable
            selectOnBlur={false}
            placeholder={t('reveal.reports.adventure.taskProcessing.taskType')}
            options={taskSubtypeOptions}
            onChange={(_e, data) =>
              setTypes(data.value ? `${data.value}` : undefined)
            }
            value={types}
          />
        }
        renderChildren={(_f, mode) => (
          <DailyEventsDataContainer
            frequency={aggregatePeriod}
            dateRange={timeSpan}
            stack={stack}
            mode={mode}
            type='bar'
            dailyEvents={dailyEvents}
          />
        )}
      />
    </div>
  );
};

export default TasksProcessing;
