import { TranslatableText } from '@/types/text';
import {
  adventureLogo,
  ashbyLogo,
  greenhouseLogo,
  leverLogo,
  recruiteeLogo,
  smartrecruitersLogo,
  zohoLogo,
  successfactorsLogo,
  teamtailorLogo,
  welcomekitLogo,
  workableLogo,
  pinpointLogo,
} from '@/containers/Parameters/Integrations/logos';

export const ADVENTURE = 'adventure';
export const ASHBY = 'ashby';
export const BOONDMANAGER = 'boondmanager';
export const BOONDMANAGER_SALES = 'boondmanager_sales';
export const GREENHOUSE = 'greenhouse';
export const LEVER = 'lever';
export const RECRUITEE = 'recruitee';
export const RECRUITCRM = 'recruitcrm';
export const SMARTRECRUITERS = 'smartrecruiters';
export const SUCCESSFACTORS = 'successfactors';
export const TEAMTAILOR = 'teamtailor';
export const WELCOMEKIT = 'welcomekit';
export const WORKABLE = 'workable';
export const ZOHO = 'zoho';
export const PINPOINT = 'pinpoint';
export const GENERIC_SOURCE_DATA = 'generic';

export const SUPPORTED_ATS_TYPES = [
  GENERIC_SOURCE_DATA,
  ADVENTURE,
  ASHBY,
  GREENHOUSE,
  LEVER,
  SMARTRECRUITERS,
  TEAMTAILOR,
  WORKABLE,
  RECRUITEE,
  RECRUITCRM,
  SUCCESSFACTORS,
  WELCOMEKIT,
  BOONDMANAGER,
  BOONDMANAGER_SALES,
  ZOHO,
  PINPOINT,
] as const;

export type ATStype = (typeof SUPPORTED_ATS_TYPES)[number];

export const ATS_NAMES_BY_TYPE: Record<ATStype, string> = {
  adventure: 'Adventure',
  ashby: 'Ashby',
  smartrecruiters: 'SmartRecruiters',
  successfactors: 'SAP SuccessFactors',
  greenhouse: 'Greenhouse',
  lever: 'Lever',
  teamtailor: 'Teamtailor',
  workable: 'Workable',
  recruitee: 'Recruitee',
  recruitcrm: 'Recruit CRM',
  welcomekit: 'Welcome Kit',
  boondmanager: 'BoondManager',
  generic: '',
  boondmanager_sales: 'BoondManager (Contacts CRM)',
  zoho: 'Zoho',
  pinpoint: 'Pinpoint',
};

export const ATS_FILTERS_KEY_BY_TYPE = {
  adventure: 'adventureFilters',
  ashby: 'ashbyFilters',
  teamtailor: 'teamtailorFilters',
  recruitee: 'recruiteeFilters',
  recruitcrm: 'recruitcrmFilters',
  greenhouse: 'greenhouseFilters',
  workable: 'workableFilters',
  lever: 'leverFilters',
  smartrecruiters: 'smartrecruitersFilters',
  successfactors: 'successfactorsFilters',
  welcomekit: 'welcomekitFilters',
  boondmanager: 'boondmanagerFilters',
  boondmanager_sales: 'boondmanagerFilters',
  zoho: 'zohoFilters',
  pinpoint: 'pinpointFilters',
  generic: 'genericFilters',
} as const satisfies Record<ATStype, string>;

export type AtsFiltersKey = (typeof ATS_FILTERS_KEY_BY_TYPE)[ATStype];

export const ATS_LOGOS_BY_TYPE: Record<ATStype, string> = {
  adventure: '/images/logos/adventure-square-logo.png',
  ashby: '/images/logos/ashby-square-logo.png',
  greenhouse: '/images/logos/greenhouse-square-logo.png',
  recruitee: '/images/logos/recruitee-square-logo.png',
  recruitcrm: '/images/logos/recruitcrm-logo.jpg',
  lever: '/images/logos/lever-square-logo.png',
  smartrecruiters: '/images/logos/smartrecruiters-square-logo.png',
  successfactors: '/images/logos/successfactors-logo.png',
  teamtailor: '/images/logos/teamtailor-square-logo.png',
  workable: '/images/logos/workable-square-logo.png',
  welcomekit: '/images/logos/welcomekit-square-logo.png',
  boondmanager_sales: '/images/logos/boondmanager-square-logo.png',
  boondmanager: '/images/logos/boondmanager-square-logo.png',
  zoho: '/images/logos/zoho-square-logo.png',
  pinpoint: '/images/logos/pinpoint-square-logo.jpg',
};

export const ATS_TIMELINE_LOGOS_BY_TYPE: Record<ATStype, string> = {
  adventure: '/images/logos/adventure-square-logo.png',
  ashby: '/images/logos/ashby-square-logo.png',
  greenhouse: '/images/logos/greenhouse-timeline-logo.png',
  recruitee: '/images/logos/recruitee-timeline-logo.png',
  recruitcrm: '/images/logos/recruitcrm-logo.jpg',
  lever: '/images/logos/lever-timeline-logo.png',
  smartrecruiters: '/images/logos/smartrecruiters-timeline-logo.png',
  successfactors: '/images/logos/successfactors-logo.png',
  teamtailor: '/images/logos/teamtailor-square-logo.png',
  workable: '/images/logos/workable-square-logo.png',
  welcomekit: '/images/logos/welcomekit-timeline-logo.png',
  boondmanager: '/images/logos/bondmanager-timeline-logo.png',
  zoho: '/images/logos/zoho-timeline-logo.png',
  pinpoint: '/images/logos/pinpoint-square-logo.jpg',
};

export const ATS_TITLE_AND_LOGO = {
  greenhouse: {
    title: 'Greenhouse',
    logo: greenhouseLogo,
  },
  recruitee: {
    title: 'Recruitee',
    logo: recruiteeLogo,
  },
  recruitcrm: {
    title: 'Recruit CRM',
    logo: recruiteeLogo,
  },
  workable: {
    title: 'Workable',
    logo: workableLogo,
  },
  lever: {
    title: 'Lever',
    logo: leverLogo,
  },
  smartrecruiters: {
    title: 'SmartRecruiters',
    logo: smartrecruitersLogo,
  },
  successfactors: {
    title: 'SAP SuccessFactors',
    logo: successfactorsLogo,
  },
  welcomekit: {
    title: 'Welcome Kit',
    logo: welcomekitLogo,
  },
  boondmanager: {
    title: 'BoondManager',
    logo: greenhouseLogo,
  },
  teamtailor: {
    title: 'Teamtailor',
    logo: teamtailorLogo,
  },
  ashby: {
    title: 'Ashby',
    logo: ashbyLogo,
  },
  zoho: {
    title: 'Zoho',
    logo: zohoLogo,
  },
  adventure: {
    title: 'Adventure',
    logo: adventureLogo,
  },
  pinpoint: {
    title: 'Pinpoint',
    logo: pinpointLogo,
  },
};

export const ATS_REQUIRING_EMAIL = [
  SMARTRECRUITERS,
  TEAMTAILOR,
  WELCOMEKIT,
  WORKABLE,
];

export const ATS_WITHOUT_USER = [ADVENTURE, WELCOMEKIT];

export const ATS_WITHOUT_JOB = [BOONDMANAGER, ADVENTURE];

export const ATS_WITHOUT_NAME = [ADVENTURE];
export const ATS_WITH_OWNER = [ADVENTURE];
export const ATS_WITH_SUBSECTION = [ADVENTURE];

export const ATS_WITH_JOB_SPECIFIC_STAGES = [
  RECRUITEE,
  TEAMTAILOR,
  WELCOMEKIT,
  GREENHOUSE,
  ASHBY,
];

export const CONNECTORS_WITH_SYNCHRONIZATION_SETTINGS = [
  GREENHOUSE,
  LEVER,
  SMARTRECRUITERS,
  TEAMTAILOR,
  ASHBY,
  WORKABLE,
  BOONDMANAGER,
  SUCCESSFACTORS,
  PINPOINT,
];

export const CONNECTORS_WITHOUT_WORKFLOWS: string[] = [
  /* BOONDMANAGER */
  // ADVENTURE,
  ZOHO,
];

export const CONNECTORS_WITHOUT_SYNCHRONIZATION_UPDATE_CAPABILITIES = [
  GREENHOUSE,
  ASHBY,
  WORKABLE,
  ZOHO,
  ADVENTURE,
];

export interface RevealProject {
  id: string;
  connectors?: RevealProjectConnector[];
}

type RevealProjectConnector = {
  id: string;
  type: ATStype;
  filterOptions?: ConnectorFilterOptions;
};

type ConnectorFilterOptions = {
  stageNames?: {
    id: string;
    name?: string;
  }[];
  rejectionReasons?: ConnectorRejectionReason[];
  users?: {
    id: string;
    firstname?: string;
    lastname?: string;
  }[];
  tags?: {
    id: string;
    name: TranslatableText;
  }[];
  jobs?: {
    id: string;
    name?: string;
    stageNames?: {
      id: string;
      name?: string;
    }[];
    locationName?: string;
    isArchived?: boolean;
  }[];
};

export type ConnectorRejectionReason = {
  id: string;
  name?: string;
  rejectionType?: RejectionType;
};

type RejectionType = {
  id: string;
  name?: string;
};

export const CONTACTOUT = 'contactout';
export const KASPR = 'kaspr';
export const ROCKETREACH = 'rocketreach';
export const LUSHA = 'lusha';
export const ZOOMINFO = 'zoominfo';

export const SUPPORTED_PROVIDER_TYPES = [
  CONTACTOUT,
  KASPR,
  ROCKETREACH,
  LUSHA,
  ZOOMINFO,
] as const;

export type ProviderType = (typeof SUPPORTED_PROVIDER_TYPES)[number];

export const PROVIDER_NAMES_BY_TYPE: Record<ProviderType, string> = {
  contactout: 'ContactOut',
  kaspr: 'Kaspr',
  rocketreach: 'RocketReach',
  lusha: 'Lusha',
  zoominfo: 'ZoomInfo',
};

export const PROVIDER_SETTINGS_PAGE = {
  kaspr: 'https://app.kaspr.io/settings',
  rocketreach: 'https://rocketreach.co/account?section=nav_gen_api',
  lusha: 'https://dashboard.lusha.com/enrich/api',
};
