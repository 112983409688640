import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Placeholder } from 'semantic-ui-react';

import useClientId from '@/hooks/router/useClientId';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

import { ProfileColumn } from '../../types';

import styles from './NameAndHeadlineColumn.module.less';

const NameAndHeadlineColumn: ProfileColumn = ({
  profile,
  profileLoading,
  className,
  style,
}) => {
  const clientId = useClientId();
  const { firstname, lastname, headline, email } = profile?.resumeData || {};
  const { linkedin } = profile?.resumeData?.sources || {};
  const headlineText = ((headline || {}).content || {}).text || '';
  const fullname =
    (firstname || '') + (firstname && lastname ? ' ' : '') + (lastname || '');

  const params = useMergedConfigurationParams();
  const shouldDisableDetailsRedirections =
    params.shouldDisableDetailsRedirections === 'true';

  return (
    <div
      className={classNames(styles.nameAndHeadline, className)}
      style={style}
    >
      {profileLoading || !profile?.id ? (
        <Placeholder>
          <Placeholder.Line length='short' style={{ height: '0px' }} />
          <Placeholder.Line length='long' style={{ height: '10px' }} />
        </Placeholder>
      ) : (
        <>
          <div className={styles.fullname} data-openreplay-masked>
            {shouldDisableDetailsRedirections ? (
              <span className={styles.fullnameText}>{fullname}</span>
            ) : (
              <Link
                target='_blank'
                to={`/client/${clientId}/reveal/search?profileId=${profile.id}`}
                className={classNames(styles.fullnameText, styles.clickable)}
              >
                {fullname}
              </Link>
            )}{' '}
            {email && (
              <i className={classNames(styles.icon, 'ri-mail-line', 'ri-xs')} />
            )}
            {linkedin && (
              <i
                className={classNames(
                  styles.icon,
                  'ri-linkedin-box-fill',
                  'ri-xs',
                )}
              />
            )}
          </div>
          <div className={styles.headline}>{headlineText}</div>
        </>
      )}
    </div>
  );
};

export default NameAndHeadlineColumn;
