export const getOSFromPlatform = () => {
  const platform = (window?.navigator?.platform || '').toLowerCase();
  const userAgent = (window?.navigator?.userAgent || '').toLowerCase();
  let os;
  if (platform.search('win') >= 0) {
    os = 'win';
  }
  if (platform.search('mac') >= 0) {
    os = 'mac';
  }
  if (userAgent.search('iphone') >= 0) {
    os = 'idevice';
  }
  if (platform.search('linux') >= 0) {
    os = 'linux';
  }
  return os;
};
