import React, { FC } from 'react';
import _ from 'underscore';

import {
  PrefixTaskType,
  PrefixStatisticsNode,
} from '@/types/statistics/clientStatistics';
import { getStatsByStepIndex } from '../helpers';
import { PrefixFilter } from '../../helpers/filters';
import StepRow from './StepRow';

interface StepStatisticsProps {
  stats: PrefixStatisticsNode;
  totalContacted: number;
  fetchDetails?: (taskType: PrefixTaskType, prefix: PrefixFilter) => void;
}

const StepStatistics: FC<StepStatisticsProps> = ({
  stats,
  totalContacted,
  fetchDetails,
}) => {
  const statsByStepIndex = getStatsByStepIndex(stats);

  return (
    <div>
      {_.map(statsByStepIndex, ({ codes, counts }, index) => (
        <StepRow
          key={index}
          taskCodes={codes}
          stats={counts}
          stepIndex={index}
          totalContacted={totalContacted}
          fetchDetails={
            fetchDetails
              ? (taskType) => fetchDetails(taskType, { type: 'step', index })
              : undefined
          }
        />
      ))}
    </div>
  );
};

export default StepStatistics;
