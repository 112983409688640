import gql from 'graphql-tag';
import ContactFlowDelay from './ContactFlowDelay';

const BaseContactFlowTrigger = gql`
  fragment BaseContactFlowTrigger on ContactFlowTrigger {
    type
    ... on ContactFlowTriggerManual {
      type
    }
    ... on ContactFlowTriggerDelayAfterAction {
      type
      delay {
        ...ContactFlowDelay
        targetTimeInDay {
          hoursAndMinutes
          timezone
        }
      }
    }
    ... on ContactFlowTriggerDelayAfterSequence {
      type
      delay {
        ...ContactFlowDelay
      }
    }
    ... on ContactFlowTriggerAfterDate {
      type
      date
    }
  }
  ${ContactFlowDelay}
`;

export default gql`
  fragment ContactFlowTrigger on ContactFlowTrigger {
    ... on ContactFlowTriggerAnd {
      conditions {
        ...BaseContactFlowTrigger
      }
    }
    ... on ContactFlowTriggerOr {
      conditions {
        ...BaseContactFlowTrigger
      }
    }
    ...BaseContactFlowTrigger
  }
  ${BaseContactFlowTrigger}
`;
