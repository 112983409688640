import gql from 'graphql-tag';
import PipeStepStat from './PipeStepStat';
import IntegrationWorkflow from './IntegrationWorkflow';
import OfferRecruiterConfiguration from './OfferRecruiterConfiguration';
import ContactFlow from './ContactFlow';
import TranslatableText from './TranslatableText';

export default gql`
  fragment Offer on Offer {
    id
    title
    foldering {
      department {
        id
      }
      section {
        id
      }
    }
    description
    criteriaEditionAllowed
    hasTemplate
    isHiresweetWatch
    isUnclassified
    isInstantFlow
    isWaitingForValidation
    isHold
    isArchived
    isAutopilot
    isSuggestedForCreation
    unlimitedFlowBatchSize
    pushFlowPolicy {
      nbPerDay
    }
    criteria {
      onlineLinks
    }
    pipeStepStats {
      ...PipeStepStat
    }
    workflows {
      ...IntegrationWorkflow
    }
    recruiterConfigurations {
      ...OfferRecruiterConfiguration
    }
    defaultActionSuggestions {
      action {
        type
        ... on MessageSequenceAction {
          messages {
            templateId
            delay {
              id
              name
            }
          }
        }
      }
      rule {
        id
      }
    }
    contactFlow {
      ...ContactFlow
    }
    owner {
      firstname
      lastname
      email
      isAdmin
    }
    hiringManager {
      email
    }
    followers {
      email
    }
    jobPostings {
      id
      title {
        ...TranslatableText
      }
      published
    }
  }
  ${TranslatableText}
  ${PipeStepStat}
  ${IntegrationWorkflow}
  ${OfferRecruiterConfiguration}
  ${ContactFlow}
`;
