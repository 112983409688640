import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import useClientId from '@/hooks/router/useClientId';

const getProjectNomenclatures = gql`
  query getProjectNomenclatures($clientId: ID!) {
    client(id: $clientId) {
      id
      projectNomenclatures {
        missionCategory {
          type
        }
        successStep {
          id
        }
      }
    }
  }
`;

type ProjectNomenclature = {
  missionCategory: { type: string };
  successStep?: { id: string };
};

type Data = {
  client: {
    id: string;
    projectNomenclatures?: ProjectNomenclature[];
  };
};

type Variables = {
  clientId: string;
};

type Input = {
  queryOptions?: QueryHookOptions<Data, Variables>;
};

type Result = QueryResult<Data, Variables> & {
  projectNomenclatures: ProjectNomenclature[];
};

function useProjectNomenclatures({ queryOptions = {} }: Input = {}): Result {
  const clientId = useClientId();
  const query = useQuery(getProjectNomenclatures, {
    ...queryOptions,
    variables: { clientId },
  });

  const projectNomenclatures = query.data?.client.projectNomenclatures || [];

  return { ...query, projectNomenclatures };
}

export default useProjectNomenclatures;
