import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

const mutation = gql`
  mutation signUpOAuthUser(
    $type: String!
    $idToken: String!
    $firstname: String!
    $lastname: String!
    $signature: String
    $alias: String
    $signUpToken: String!
    $clientId: ID!
  ) {
    signUpOAuthUser(
      type: $type
      idToken: $idToken
      firstname: $firstname
      lastname: $lastname
      signature: $signature
      alias: $alias
      signUpToken: $signUpToken
      clientId: $clientId
    ) {
      id
      email
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    onSignupOAuth: ({
      type,
      idToken,
      firstname,
      lastname,
      signUpToken,
      clientId,
      alias,
      signature,
    }) => {
      const variables = {
        type,
        idToken,
        firstname,
        lastname,
        signUpToken,
        clientId,
        ...(alias && { alias }),
        ...(signature && { signature }),
      };
      return mutate({ variables, errorPolicy: 'all' });
    },
  }),
});
