import React from 'react';
import { withTranslation } from 'react-i18next';
import { Image, Segment } from 'semantic-ui-react';
import _ from 'underscore';
import { getRandomDefaultAvatarLink, getSecureLink, getTranslatedText } from '../../../../common';

import { sweetDateToMomentDate } from '../../../../common/dates';
import { isFemaleName } from '../../../../common/firstnames';
import logAction from '../../../../common/logAction';
import { getSmartrecruitersCandidateURL } from '../../../../common/reveal/smartrecruitersURL';
import { getGreenhouseCandidateURL } from '../../../../common/reveal/greenhouseURL';
import { getLeverCandidateURL } from '../../../../common/reveal/leverURL';
import ResizableLabelList from '../../../../components/ResizableLabelList';
import ProfileSources from '../../../../containers/Profile/ProfileSources';
import useSelectedProfile from '../../../../hooks/router/useSelectedProfile';
import MatchLevelGauge from '../RevealListRow/MatchingDetails/MatchLevelGauge';

import './SimilarProfileCard.css';

const SimilarProfileCard = ({ profile, score }) => {
  const setSelectedProfileId = useSelectedProfile()[1];
  const { resumeData } = profile || {};
  const { firstname, lastname, headline, photoLink, mainEducation, sources, sourceData } = resumeData;
  const fullname = (firstname || '') + (firstname && lastname ? ' ' : '') + (lastname || '');
  const avatarImageUrl = photoLink || getRandomDefaultAvatarLink(firstname + lastname);

  const newSources = {
    ...sources
  };

  const smartrecruitersId = sourceData?.smartrecruiters?.sourceCandidateId;
  const smartrecruitersURL = getSmartrecruitersCandidateURL(smartrecruitersId);
  if (!_.isEmpty(newSources) && smartrecruitersURL) {
    newSources.smartrecruiters = smartrecruitersURL;
  }

  const greenhouseId = sourceData?.greenhouse?.sourceCandidateId;
  const greenhouseURL = getGreenhouseCandidateURL(greenhouseId);
  if (!_.isEmpty(newSources) && greenhouseURL) {
    newSources.greenhouse = greenhouseURL;
  }

  const leverId = sourceData?.lever?.sourceCandidateId;
  const leverURL = getLeverCandidateURL(leverId);
  if (!_.isEmpty(newSources) && leverURL) {
    newSources.lever = leverURL;
  }


  let labels = _.map(sourceData?.linkedin?.skills, ({ label, relevance }) => ({
    label: getTranslatedText(label),
    relevance,
  }));
  labels = _.sortBy(labels, ({ relevance }) => -relevance);

  const onClickSource = ({ e, sourceId }) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    logAction({
      searchPoolProfileId: profile?.id,
      type: 'click-profile-source',
      info: {
        sourceId,
        from: 'reveal-similar-profiles',
      },
    });
  };

  return (
    <Segment className='similar-profile-card' onClick={() => setSelectedProfileId(profile?.id)}>
      <div className='header'>
        <div className='avatar-container'>
          <Image
            src={getSecureLink(avatarImageUrl)}
            circular
            onError={(e) => {
              e.target.src = getRandomDefaultAvatarLink(firstname + lastname);
            }}
          />
        </div>

        <div className='name-and-headline-container'>
          <div className='full-name headline-5'>
            <div className='full-name-text'>
              {fullname}
            </div>

            <ProfileSources sources={newSources} onClickSource={onClickSource} />
          </div>
          <div className='headline'>{headline?.content?.text}</div>

          <MainEducation
            mainEducation={mainEducation}
            isFemale={isFemaleName(resumeData?.firstname)}
          />
        </div>

        <div className='similarity-score'>
          <MatchLevelGauge score={score} positive />
          {/* <span className='similarity-text caption-2'> */}
          {/* {t('reveal.similarProfiles.similarityScore', { percent: (score * 100).toFixed(0) })} */}
          {/* </span> */}
        </div>
      </div>

      {!_.isEmpty(labels) && (
        <div className='content'>
          <ResizableLabelList labelsWithRelevance={labels} condensedMode />
        </div>
      )}
    </Segment>
  );
};

const MainEducation = withTranslation('translations')(({ mainEducation, isFemale, t }) => {
  const { schoolName } = mainEducation || {};

  const endDate = sweetDateToMomentDate(mainEducation && mainEducation.endDate);

  const studiedAt = isFemale
    ? t('profile.resume.femaleStudiedAt')
    : t('profile.resume.studiedAt');

  const mainEducationText = schoolName ? `${studiedAt} ${schoolName} ${endDate ? `(${endDate.fromNow()})` : ''}` : '';
  return (
    <div className='main-education'>{mainEducationText}</div>
  );
});

export default withTranslation('translations')(SimilarProfileCard);
