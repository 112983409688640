import React from 'react';
import classNames from 'classnames';


import './IntegrationHeader.css';

const IntegrationHeader = ({ className, number, text }) => {
  return (
    <div className={classNames('integration-header', className)}>
      <div className='integration-header-number'>{number}</div>
      <div className='integration-header-text'>
        {text}
      </div>
    </div>
  );
};

export default IntegrationHeader;
