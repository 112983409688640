import React from 'react';

import { Modal, Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { compose } from 'underscore';

import withActionLogger from '../../hocs/withActionLogger';

class ErrorModal extends React.Component {
  handleRefreshAction = () => {
    const { onLogAction, profileId, offerId } = this.props;

    onLogAction({
      type: 'click-refresh-button-senderror-modal',
      profileId,
      info: {
        jobOfferId: offerId,
      },
    })
      .then(
        () => {
          window.location.reload(true);
        }, // set to true to force hard refresh ; might be deprecated but should work anyway
      )
      .catch(() => {
        window.location.reload(true); // reload even if the actionlogger failed
      });
  };

  handleSignOutAction = () => {
    const {
      onClose,
      onTriggerSignOut,
      onLogAction,
      profileId,
      offerId,
    } = this.props;
    onTriggerSignOut();
    onClose();
    try {
      onLogAction({
        type: 'click-reconnect-button-senderror-modal',
        profileId,
        info: {
          jobOfferId: offerId,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { t, open, onClose } = this.props;
    return (
      <Modal open={open} onClose={onClose} closeIcon size='tiny'>
        <Modal.Header>
          {t('profile.contact.drafts.errorModal.header')}
          <div className='subheader description'>
            {t('profile.contact.drafts.errorModal.message')}
          </div>
        </Modal.Header>

        <Modal.Actions className='align-center'>
          <Button onClick={this.handleSignOutAction} primary size='big'>
            {t('profile.contact.drafts.errorModal.actions.signout')}
          </Button>

          <Button onClick={this.handleRefreshAction} primary basic size='big'>
            {t('profile.contact.drafts.errorModal.actions.refresh')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default compose(
  withTranslation('translations'),
  withActionLogger,
)(ErrorModal);
