import _ from 'underscore';
import {
  WORKFLOW_EXTERNAL_ACTION_COMMANDS,
  WORKFLOW_INTERNAL_ACTION_COMMANDS,
} from '@/types/searchPoolJob';

export const sanitizeWorkflow = (worklow: any) => {
  const updatedWorkflow = JSON.parse(JSON.stringify({ ...worklow }));
  if (updatedWorkflow.__typename) {
    delete updatedWorkflow.__typename;
  }
  if (updatedWorkflow.action?.__typename) {
    delete updatedWorkflow.action.__typename;
  }
  if (updatedWorkflow.rule?.__typename) {
    delete updatedWorkflow.rule.__typename;
  }
  if (updatedWorkflow.rule?.condition?.__typename) {
    delete updatedWorkflow.rule?.condition?.__typename;
  }
  if (updatedWorkflow.action?.target?.__typename) {
    delete updatedWorkflow.action.target.__typename;
  }
  return updatedWorkflow;
};

export const getActionType = (command: string) => {
  if (_.contains(WORKFLOW_INTERNAL_ACTION_COMMANDS, command)) {
    return 'internal';
  }
  if (_.contains(WORKFLOW_EXTERNAL_ACTION_COMMANDS, command)) {
    return 'external-service';
  }
  return undefined;
};
