import useClientId from '@/hooks/router/useClientId';
import { HiresweetLibProvider, Pagination } from '@hiresweet/hiresweet-lib';
import React from 'react';

const PlaygroundT7 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  const [currentPage, setCurrentPage] = React.useState(3);
  return (
    <HiresweetLibProvider theme={theme} clientId={clientId}>
      <h1>P-T-7 - Pagination</h1>
      <Pagination
        currentPage={currentPage}
        totalPages={100}
        onPageChange={(index) => setCurrentPage(index)}
      />
    </HiresweetLibProvider>
  );
};

export default PlaygroundT7;
