import React, { useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import _ from 'underscore';
import { Button, Transition } from 'semantic-ui-react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  useRevealActivityContext,
  REVEAL_ACTIVITY_TABS,
} from '@/context/JobView/useRevealActivityProvider';
import { CrossIcon } from '@/assets/icons';
import RevealActivityTimeline from '@/components/RevealActivityTimeline/RevealActivityTimeline';
import useRevealClientActivity from '@/graphql/hooks/clients/useRevealClientActivity';
import { capitalizeFirstLetter } from '@/common/utils/string';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import useMissionSharedActivities from '@/graphql/hooks/searchPoolJob/useMissionSharedActivities';

import styles from './ActivityFeed.module.less';

const ActivityFeedModal: React.FC = () => {
  const { tabActive, isModalOpen } = useRevealActivityContext();
  return (
    <Transition visible={isModalOpen} animation='slide left' duration={500}>
      <div className={styles.activityFeedModal}>
        <CloseModal />
        <ActionsTabs />
        <div className={styles.activityFeedContent}>
          {tabActive === REVEAL_ACTIVITY_TABS.ALL_ACTIVITIES && (
            <ActivityTab onlyCurrentUser={false} />
          )}
          {tabActive === REVEAL_ACTIVITY_TABS.CURRENT_USER_ACTIVITIES && (
            <ActivityTab onlyCurrentUser />
          )}
          {tabActive === REVEAL_ACTIVITY_TABS.SHARED_ACTIVITIES && (
            <SharedActivityTab />
          )}
        </div>
      </div>
    </Transition>
  );
};

const CloseModal: React.FC = () => {
  const { closeModal } = useRevealActivityContext();
  return (
    <Button
      className={styles.closeModal}
      circular
      icon={<CrossIcon />}
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    />
  );
};

const ActionsTabs: React.FC = () => {
  const { t } = useTranslation();
  const { tabActive, setTabActive } = useRevealActivityContext();
  return (
    <div className={styles.actionsTabs}>
      <span
        className={
          tabActive === REVEAL_ACTIVITY_TABS.ALL_ACTIVITIES
            ? styles.tabActive
            : styles.tab
        }
        aria-hidden='true'
        onClick={() => setTabActive(REVEAL_ACTIVITY_TABS.ALL_ACTIVITIES)}
      >
        {t('reveal.dashboardView.activity.allActivity')}
      </span>
      <span
        className={
          tabActive === REVEAL_ACTIVITY_TABS.CURRENT_USER_ACTIVITIES
            ? styles.tabActive
            : styles.tab
        }
        aria-hidden='true'
        onClick={() =>
          setTabActive(REVEAL_ACTIVITY_TABS.CURRENT_USER_ACTIVITIES)
        }
      >
        {t('reveal.dashboardView.activity.myActivity')}
      </span>
      <span
        className={
          tabActive === REVEAL_ACTIVITY_TABS.SHARED_ACTIVITIES
            ? styles.tabActive
            : styles.tab
        }
        aria-hidden='true'
        onClick={() => setTabActive(REVEAL_ACTIVITY_TABS.SHARED_ACTIVITIES)}
      >
        {t('reveal.dashboardView.activity.sharedActivity')}
      </span>
    </div>
  );
};

const DEFAULT_EVENTS_LIMIT = 30;
const SEE_MORE_STEP = 20;

const ActivityTab = ({ onlyCurrentUser }: { onlyCurrentUser: boolean }) => {
  const { t } = useTranslation();
  const { clientId, missionId } = useRevealActivityContext();
  const configurationParams = useMergedConfigurationParams();
  const { data, loading } = useRevealClientActivity({
    clientId,
    missionId,
    onlyCurrentUser,
  });
  const [eventsCount, setEventsCount] = useState(DEFAULT_EVENTS_LIMIT);
  // TODO Remove filter to remove linkedin-check-request
  const originalEvents = (data?.client?.activity.events || []).filter(
    (event: any) => {
      return !(event.taskType && event.taskType === 'linkedin-check-request');
    },
  );
  const truncateEvents = (originalEvents || []).slice(0, eventsCount);
  const displayedEvents = useMemo(
    () => groupEventsByDate(truncateEvents || [], t),
    // eslint-disable-next-line
    [truncateEvents],
  );

  const showSeeMore =
    (truncateEvents?.length || 0) < (originalEvents?.length || 0);
  const seeMore = useCallback(() => {
    setEventsCount((oldCount) => oldCount + SEE_MORE_STEP);
  }, []);

  if (loading) {
    return null;
  }
  if (!displayedEvents?.length) {
    return <EmptyActivities />;
  }

  const filteredEvents = _.map(displayedEvents, (groupedItem) => ({
    ...groupedItem,
    events:
      configurationParams?.shouldHideProjectProfileStageUpdatedActivity ===
      'true'
        ? _.filter(
            groupedItem.events,
            (event) =>
              ![
                'reveal-mission-update-profile-stage',
                'reveal-task-completed',
              ].includes(event.subtype),
          )
        : groupedItem.events,
  }));

  return (
    <RevealActivityTimeline
      events={_.filter(filteredEvents, ({ events }) => !_.isEmpty(events))}
      showSeeMore={showSeeMore}
      seeMore={seeMore}
    />
  );
};

const EmptyActivities: React.FC = () => {
  const { t } = useTranslation();
  return (
    <span className={styles.emptyActivity}>
      {t('reveal.dashboardView.activity.empty')}
    </span>
  );
};

const SharedActivityTab = () => {
  const { t } = useTranslation();
  const { missionId } = useRevealActivityContext();
  const { sharedActivities, loading } = useMissionSharedActivities({
    missionId,
  });
  const [eventsCount, setEventsCount] = useState(DEFAULT_EVENTS_LIMIT);

  const originalEvents = useMemo(
    () =>
      _.map(sharedActivities, (event) => ({
        ...event,
        ...event.content,
        __typename: 'LoggedActivityContent',
      })).reverse(),
    [sharedActivities],
  );

  const truncateEvents = useMemo(
    () => (originalEvents || []).slice(0, eventsCount),
    [originalEvents, eventsCount],
  );
  const displayedEvents = useMemo(
    () => groupEventsByDate(truncateEvents || [], t),
    [truncateEvents, t],
  );

  const showSeeMore =
    (truncateEvents?.length || 0) < (originalEvents?.length || 0);
  const seeMore = useCallback(() => {
    setEventsCount((oldCount) => oldCount + SEE_MORE_STEP);
  }, []);

  if (loading) {
    return null;
  }
  if (!displayedEvents?.length) {
    return <EmptyActivities />;
  }

  return (
    <RevealActivityTimeline
      events={_.filter(displayedEvents, ({ events }) => !_.isEmpty(events))}
      showSeeMore={showSeeMore}
      seeMore={seeMore}
      missionId={missionId}
    />
  );
};

const groupEventsByDate = (events: any, t: TFunction) => {
  const groupedEvents = _.groupBy(events, (event) =>
    moment(event.date).format('YYYY-MM-DD'),
  );
  const dateKeys = _.keys(groupedEvents);
  const sortedEvents = dateKeys.map((key) => ({
    date: key,
    events: groupedEvents[key],
  }));
  const today = moment(new Date());
  const todayTranslation = t('common.today');
  const yesterday = moment(new Date()).subtract(1, 'days');
  const yesterdayTranslation = t('common.yesterday');

  const sortedWithDateTranslated: any = sortedEvents.map((event) => {
    let eventDict = event;
    if (today.isSame(moment(eventDict.date), 'day')) {
      eventDict = { date: todayTranslation, events: eventDict.events };
    } else if (yesterday.isSame(moment(event.date), 'day')) {
      eventDict = { date: yesterdayTranslation, events: eventDict.events };
    } else {
      const date = capitalizeFirstLetter(
        t('common.weekDayMonth', { date: new Date(event.date) }),
      );
      eventDict = { date, events: eventDict.events };
    }

    return eventDict;
  });
  return sortedWithDateTranslated;
};

export default ActivityFeedModal;
