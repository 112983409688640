import React from 'react';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import LinearHeatMap, {
  HeatMapMapping,
  HeatMapValue,
} from '@/components/LinearHeatMap';
import { RevealActivityProvider } from '@/context/JobView/useRevealActivityProvider';
import ActivityModalTrigger from "../JobView/ActivityFeed/ActivityModalTrigger";
import ActivityFeedModal from "../JobView/ActivityFeed/ActivityFeedModal";

import './JobContactKPIs.css';

interface JobContactKPIsProps {
  countToday: number;
  countWeek: number;
  countTrimester: number;
  heatMapValues: HeatMapValue[];
  clientId: string;
  jobId: string;
  t: TFunction;
}

const mapping: HeatMapMapping = [1, 3, 5, 10, 20];

const JobContactKPIs: React.FC<JobContactKPIsProps> = ({
  countToday,
  countWeek,
  countTrimester,
  heatMapValues,
  clientId,
  jobId,
  t,
}) => {
  return (
    <div className='job-contact-info'>
      <div className='job-contact-kpis'>
        {/* <div className='title'>{t('missions.kpis.title')}</div> */}
        <div className='job-contact-kpi'>
          <div className='number'>{countToday}</div>
          <div className='legend'>
            <pre>{t('missions.kpis.today')}</pre>
          </div>
        </div>
        <div className='job-contact-kpi'>
          <div className='number'>{countWeek}</div>
          <div className='legend'>
            <pre>{t('missions.kpis.thisWeek')}</pre>
          </div>
        </div>
        <div className='job-contact-kpi'>
          <div className='number'>{countTrimester}</div>
          <div className='legend'>
            <pre>{t('missions.kpis.thisSemester')}</pre>
          </div>
        </div>
      </div>
      <div className='job-linear-heat-map'>
        <LinearHeatMap
          mapping={mapping}
          values={heatMapValues}
          labeli18nKey='missions.kpis.contactsAdded'
        />
        <div className='job-contact-small-divider' />
        <div className='job-contact-all-activity-btn'>
          <RevealActivityProvider clientId={clientId} missionId={jobId}>
            <ActivityModalTrigger />
            <ActivityFeedModal />
          </RevealActivityProvider>
        </div>
      </div>
      
    </div>
  );
};

export default withTranslation('translations')(JobContactKPIs);
