import React, { useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';

import GenericButton from '@/components/Common/GenericButton';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import DropdownControlsContext from '@/context/DropdownControlsContext';
import GenericDropdown from '../../../components/Common/GenericDropdown/GenericDropdown';
import styles from './NewProfileSkipButton.module.less';
import GenericTitle from '../../../components/Common/Text/Titles/index';
import GenericText from '../../../components/Common/Text/index';

const skipReasonIcons: Record<string, string> = {
  'salary-mismatch': 'ri-money-euro-box-line',
  'unsuitable-skills': 'ri-focus-2-line',
  'not-enough-xp': 'ri-seedling-line',
  'insufficient-targeted-skills': 'ri-bookmark-line',
  'location-mismatch': 'ri-map-pin-2-line',
  'unsuitable-current-position': 'ri-suitcase-line',
  'already-contacted': 'ri-mail-open-line',
  'insufficient-education': 'ri-graduation-cap-line',
  'remote-policy': 'ri-home-7-line',
  'too-much-xp': 'ri-body-scan-line',
  'friend-company': 'ri-user-heart-line',
};

const newSkipReasons = Object.keys(skipReasonIcons);

interface NewProfileSkipButtonProps {
  skipProfileHelper: any;
}

const NewProfileSkipButton: React.FC<NewProfileSkipButtonProps> = ({
  skipProfileHelper,
}) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const [customReason, setCustomReason] = useState('');

  const handleReasonSelection = (reason: string) => {
    skipProfileHelper({
      skipReasonText: reason,
      onSkip: () => {},
    });
  };

  return (
    <div className='profile-skip-button'>
      <GenericDropdown
        contentClassName={styles.dropdownMenuContainer}
        position='right'
        Trigger={({ onClick }) => (
          <GenericButton
            size='normal'
            primacy='primary'
            color='red'
            onClick={onClick}
          >
            {t('profile.steps.newSkip.trigger')}
            <ArrowDown />
          </GenericButton>
        )}
      >
        <div className={styles.archiveReasonsMenuContainer}>
          <div className={styles.archiveReasonsMenu}>
            <GenericTitle as='h4' font='Gilroy'>
              {t('profile.steps.newSkip.trigger')}
            </GenericTitle>
            <GenericText
              text={t('profile.steps.newSkip.subheader')}
              color='cobalt60'
            />
            <div className={styles.archiveReasonsContainer}>
              {_.map(newSkipReasons.slice(0, 6), (reasonId) => (
                <ProfileSkipReason
                  key={reasonId}
                  reasonId={reasonId}
                  onClick={handleReasonSelection}
                />
              ))}
            </div>
            <span
              className={styles.showMoreBtn}
              onClick={() => setShowMore(!showMore)}
            >
              <i className='ri-arrow-down-s-line' />
              {showMore ? t('common.seeLess') : t('common.seeMore')}{' '}
              {t('profile.steps.newSkip.reason')}
            </span>
            {showMore && (
              <>
                <div className={styles.archiveReasonsContainer}>
                  {_.map(
                    newSkipReasons.slice(6, newSkipReasons.length),
                    (reasonId) => (
                      <ProfileSkipReason
                        reasonId={reasonId}
                        onClick={handleReasonSelection}
                      />
                    ),
                  )}
                </div>
                <GenericTitle
                  as='h5'
                  style={{
                    marginBottom: '2px',
                  }}
                >
                  {t('profile.steps.newSkip.otherReason')}
                </GenericTitle>
                <div className={styles.reasonInputContainer}>
                  <Input
                    fluid
                    placeholder={t('profile.steps.newSkip.tellUsWhy')}
                    className={styles.reasonInput}
                    value={customReason}
                    onChange={(e) => setCustomReason(e.target.value)}
                  />
                  <GenericButton
                    disabled={_.isEmpty(customReason)}
                    onClick={() => handleReasonSelection(`#${customReason}`)}
                  >
                    <i className='ri-check-line' />
                  </GenericButton>
                </div>
              </>
            )}
          </div>
          <div className={styles.archiveReasonsFooter}>
            <DropdownControlsContext.Consumer>
              {(value) => (
                <GenericButton
                  color='grey'
                  primacy='secondary'
                  onClick={() => value.closeDropdown()}
                >
                  {t('common.cancel')}
                </GenericButton>
              )}
            </DropdownControlsContext.Consumer>
            <GenericButton
              primacy='secondary'
              color='red'
              onClick={() => skipProfileHelper({ skipReasonText: null })}
            >
              {t('profile.steps.newSkip.skipWithoutReason')}
            </GenericButton>
          </div>
        </div>
      </GenericDropdown>

      {/* <SkipModal /> */}
    </div>
  );
};

interface ProfileSkipReasonProps {
  reasonId: string;
  onClick: (reasonId: string) => void;
}

const ProfileSkipReason: React.FC<ProfileSkipReasonProps> = ({
  reasonId,
  onClick,
}) => {
  const { t } = useTranslation();
  const reasonText = t(`profile.steps.newSkip.reasons.${reasonId}`);

  return (
    <div className={styles.skipReason} onClick={() => onClick(reasonId)}>
      <i className={skipReasonIcons[reasonId]} /> {reasonText}
    </div>
  );
};

export default NewProfileSkipButton;
