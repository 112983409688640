import _ from 'underscore';
import {
  ArchivedState,
  PipelineState,
} from '@/components/PipelineSegmentation/pipelineSegmentation';
import {
  DetailsByArchivedState,
  DetailsByPipelineState,
} from '@/types/statistics/pipeline';
import {
  PipelineDetailDataPointForCategory,
  SeparatedDetailsByPipelineState,
  SeparatedDetailsByPipelineStateByCategory,
} from '../types';
import { states } from './segments';

export const getInitialSeperatedDetailsByPipelineState = (): SeparatedDetailsByPipelineState => ({
  nonArchived: {
    [PipelineState.PENDING]: [],
    [PipelineState.IN_PROGRESS]: [],
    [PipelineState.REPLIED]: [],
    [PipelineState.INTERESTED]: [],
    [PipelineState.HIRED]: [],
  },
  archived: {
    [PipelineState.PENDING]: [],
    [PipelineState.IN_PROGRESS]: [],
    [PipelineState.REPLIED]: [],
    [PipelineState.INTERESTED]: [],
    [PipelineState.HIRED]: [],
  },
});

export const concatSeparatedDetailsByPipelineState = (
  a: SeparatedDetailsByPipelineState,
  b: SeparatedDetailsByPipelineState,
): SeparatedDetailsByPipelineState => ({
  nonArchived: {
    [PipelineState.PENDING]: [
      ...a.nonArchived[PipelineState.PENDING],
      ...b.nonArchived[PipelineState.PENDING],
    ],
    [PipelineState.IN_PROGRESS]: [
      ...a.nonArchived[PipelineState.IN_PROGRESS],
      ...b.nonArchived[PipelineState.IN_PROGRESS],
    ],
    [PipelineState.REPLIED]: [
      ...a.nonArchived[PipelineState.REPLIED],
      ...b.nonArchived[PipelineState.REPLIED],
    ],
    [PipelineState.INTERESTED]: [
      ...a.nonArchived[PipelineState.INTERESTED],
      ...b.nonArchived[PipelineState.INTERESTED],
    ],
    [PipelineState.HIRED]: [
      ...a.nonArchived[PipelineState.HIRED],
      ...b.nonArchived[PipelineState.HIRED],
    ],
  },
  archived: {
    [PipelineState.PENDING]: [
      ...a.archived[PipelineState.PENDING],
      ...b.archived[PipelineState.PENDING],
    ],
    [PipelineState.IN_PROGRESS]: [
      ...a.archived[PipelineState.IN_PROGRESS],
      ...b.archived[PipelineState.IN_PROGRESS],
    ],
    [PipelineState.REPLIED]: [
      ...a.archived[PipelineState.REPLIED],
      ...b.archived[PipelineState.REPLIED],
    ],
    [PipelineState.INTERESTED]: [
      ...a.archived[PipelineState.INTERESTED],
      ...b.archived[PipelineState.INTERESTED],
    ],
    [PipelineState.HIRED]: [
      ...a.archived[PipelineState.HIRED],
      ...b.archived[PipelineState.HIRED],
    ],
  },
});

export const formatPipelineDetailDataPoints = (
  datapoints: PipelineDetailDataPointForCategory[],
): SeparatedDetailsByPipelineStateByCategory =>
  _.reduce(
    datapoints,
    (result, { missionId, categoryId, data: datapoint }) => {
      const details = getInitialSeperatedDetailsByPipelineState();
      _.each(datapoint, ({ state, archived, profileItems }) => {
        if (archived) {
          details.archived[state] = [
            ...(details.archived[state] || []),
            ...profileItems,
          ];
        } else {
          details.nonArchived[state] = [
            ...(details.nonArchived[state] || []),
            ...profileItems,
          ];
        }
      });
      return {
        ...result,
        [`${missionId}__${categoryId}`]: details,
      };
    },
    {} as SeparatedDetailsByPipelineStateByCategory,
  );

export const getEverProfileItemsBySegment = (
  details: SeparatedDetailsByPipelineState,
): DetailsByPipelineState & DetailsByArchivedState => {
  const result: DetailsByPipelineState & DetailsByArchivedState = {
    [PipelineState.PENDING]: [],
    [PipelineState.IN_PROGRESS]: [],
    [PipelineState.REPLIED]: [],
    [PipelineState.INTERESTED]: [],
    [PipelineState.HIRED]: [],
    [ArchivedState.ARCHIVED]: [],
  };

  _.each(states, (segmentId, index) => {
    for (let i = index; i < states.length; i += 1) {
      result[segmentId] = [
        ...result[segmentId],
        ...details.nonArchived[states[i]],
        ...details.archived[states[i]],
      ];
    }
  });

  _.each(_.values(details.archived), (profileItems) => {
    result[ArchivedState.ARCHIVED] = [
      ...result[ArchivedState.ARCHIVED],
      ...profileItems,
    ];
  });

  return result;
};

export const getCurrentProfileItemsBySegment = (
  details: SeparatedDetailsByPipelineState,
): DetailsByPipelineState & DetailsByArchivedState => {
  return {
    ...details.nonArchived,
    [ArchivedState.ARCHIVED]: _.reduce(
      details.archived,
      (accumulator, profileItems) => [...accumulator, ...profileItems],
      [] as { profileId: string }[],
    ),
  };
};
