import gql from 'graphql-tag';

import TextWithEnrichments from './TextWithEnrichments';

export default gql`
    fragment TranslatableTextWithEnrichments on TranslatableTextWithEnrichments {
        default {
            ...TextWithEnrichments
        }
        fr {
            ...TextWithEnrichments
        }
        en {
            ...TextWithEnrichments
        }
    }
    ${TextWithEnrichments}
`;
