import {
  ATStype,
  GREENHOUSE,
  LEVER,
  RECRUITEE,
  SMARTRECRUITERS,
  WELCOMEKIT,
  WORKABLE,
} from '../reveal';

export const showNavIntegrationSwitch = (type: ATStype): boolean => {
  return [
    GREENHOUSE,
    LEVER,
    RECRUITEE,
    SMARTRECRUITERS,
    WELCOMEKIT,
    WORKABLE,
  ].includes(type);
};

export default showNavIntegrationSwitch;
