import gql from 'graphql-tag';

export default gql`
  fragment JobFoldering on JobFoldering {
    department {
      id
      title
    }
    section {
      id
      title
    }
  }
`;
