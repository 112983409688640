/* eslint-disable max-len */

import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './svg.module.less';

type CheckProps = {
  className?: string;
};

const Check: FC<CheckProps> = ({ className }) => (
  <svg
    className={classNames(styles.svgIcon, className)}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 6L7.70709 10.2929C7.31657 10.6834 6.6834 10.6834 6.29288 10.2929L4 8'
      stroke='currentColor'
      fill='none'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Check;
