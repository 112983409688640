import React from 'react';
import { Icon } from 'semantic-ui-react';
import { ICON_MAP } from './ICON_MAP';

const AchievementBadge = ({ subtype, count }) => {
  const { color, name } = (ICON_MAP[subtype] || {})[count] || {};
  return (
    <span>
      <Icon name={name} style={{color}} />
    </span>
  );
};

export default AchievementBadge;
