import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import usePowerHours from '@/graphql/hooks/powerHours/usePowerHours';
import { getSoonestPowerHour } from '@/common/helpers/powerHours';
import { TelescopeIcon } from '@/assets/icons';
import ButtonLink from '@/components/Common/GenericButton/ButtonLink';

import styles from './PowerHoursBanner.module.less';

const PowerHoursBanner: FC = () => {
  const { t } = useTranslation();
  const { powerHours } = usePowerHours();
  const sonnestPowerHour = getSoonestPowerHour(powerHours);

  if (!sonnestPowerHour) {
    return null;
  }

  return (
    <div className={styles.banner}>
      <span>
        <TelescopeIcon className='' />
        <span className={styles.message}>{sonnestPowerHour.message}</span>
      </span>

      <ButtonLink
        primacy='primary'
        to={{ pathname: sonnestPowerHour.link }}
        target='_blank'
      >
        {t('common.moreInformation')}
      </ButtonLink>
    </div>
  );
};

export default PowerHoursBanner;
