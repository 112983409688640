import _ from 'underscore';
import React from 'react';
import {
  HiresweetLibProvider,
  ProjectContainer,
  ProjectTitle,
  createProjectTextCustomField,
  createProjectFloatCustomField,
  createProjectIntegerCustomField,
  createProjectDayCustomField,
  createProjectExplicitEnumCustomField,
  
} from '@hiresweet/hiresweet-lib';
import useClientId from '@/hooks/router/useClientId';
import { useLocation } from 'react-router-dom';

const ProjectLocation = createProjectTextCustomField({ clientCustomFieldId: 'localisation'});
const ProjectRatio = createProjectFloatCustomField({ clientCustomFieldId: 'ratio'});
const ProjectImportance = createProjectIntegerCustomField({ clientCustomFieldId: 'importance'});
const ProjectDueDay = createProjectDayCustomField({ clientCustomFieldId: 'dueday' });
const ProjectTags = createProjectExplicitEnumCustomField({
  clientCustomFieldId: 'tags',
  titleFromId: { alpha: 'Alpha!', beta: 'Beta!', gamma: 'Gamma!' },
})

const PlaygroundProjectCustomField = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const projectId = searchParams.get('testProjectId') || "ri7-demo-testimport2-UMODD2";

  return (
    <div style={{ padding: 30 }}>
      <h1>Project Custom Field</h1>
      <div>
        <HiresweetLibProvider theme={theme} clientId={clientId}>
          <ProjectContainer projectId={projectId} >
            Title: <ProjectTitle /><br/>
            Location: <ProjectLocation /><br/>
            Ratio: <ProjectRatio /><br/>
            Importance: <ProjectImportance /><br/>
            DueDay: <ProjectDueDay /><br/>
            Tags: <ProjectTags /><br/>
          </ProjectContainer>
        </HiresweetLibProvider>
      </div>
    </div>
  );
};

export default PlaygroundProjectCustomField;
