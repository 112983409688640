import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const setPluginLinkedinPermission = gql`
  mutation setPluginLinkedinPermission($bool: Boolean!) {
    setPluginLinkedinPermission(bool: $bool) {
      id
      permissions {
        pluginLinkedin
      }
    }
  }
`;

export default graphql(setPluginLinkedinPermission, {
  props: ({ mutate }) => ({
    setPluginLinkedinPermission: ({ bool }) => mutate({ variables: { bool } }),
  }),
});
