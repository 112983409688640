import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import { useUpdateCompanyPipelineStage } from '@/graphql/hooks/searchPoolProfile/useUpdateCompanyPipelineStage';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import ButtonDropdownMenu from '@/components/Common/ButtonDropdownMenu';
import { getShortLanguage } from '@/common/utils/i18n';
import { getTranslatedText } from '@/common/helpers/translatableText';
import useClientCustomCompanyDealStates from '@/graphql/hooks/clients/useClientCustomCompanyDealStates';

interface CompanyStageEditionProps {
  company: SearchPoolProfile;
  onChangeStage?: (data: any) => void;
}

const CompanyStageEdition: React.FC<CompanyStageEditionProps> = ({
  company,
  onChangeStage,
}) => {
  const { t, i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const { customCompanyDealStates, loading } =
    useClientCustomCompanyDealStates();
  const [updateCompanyStage] = useUpdateCompanyPipelineStage();
  const stageId = company.companyDealState?.customCompanyDealStateId;

  const onChangeSegment = async (id: string) => {
    const { data } = await updateCompanyStage({
      variables: {
        searchPoolId: 'reveal',
        profileId: company.id,
        stageId: id,
      },
      refetchQueries: ['getCrossMissionProfilesWithSegmentation'],
    });
    if (onChangeStage) {
      onChangeStage({
        newData: data,
        newSegment: id,
        prevSegment: stageId,
      });
    }
  };

  const stageNameById = useMemo(() => {
    const result = {} as Record<string, string>;
    _.forEach(
      customCompanyDealStates,
      ({ customCompanyDealStateId, title }) => {
        result[customCompanyDealStateId] = getTranslatedText(lang, title);
      },
    );
    return result;
  }, [customCompanyDealStates, lang]);

  const dropdownOptions = useMemo(
    () =>
      _.map(stageNameById, (label, id) => ({
        id,
        label,
        disabled: id === stageId,
      })),
    [stageNameById, stageId],
  );

  const stageName = useMemo(() => {
    if (!stageId) {
      return t('common.unknown');
    }
    return stageNameById[stageId] || t('common.unknown');
  }, [t, stageId, stageNameById]);

  if (loading) {
    return null;
  }

  return (
    <ButtonDropdownMenu
      position='right'
      primacy='secondary'
      title={stageName}
      options={dropdownOptions}
      onSelect={onChangeSegment}
    />
  );
};

export default CompanyStageEdition;
