import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import ClientDefaultCrmWorkflows from '@/graphql/fragments/ClientDefaultCrmWorkflows';
import ClientPermissions from '../../graphql/fragments/ClientPermissions';
import { EvaluationFormTemplate } from '../../graphql/fragments/Evaluations';
import { ApplicationFormTemplate } from '../../graphql/fragments/Applications';
import { Department } from '../../graphql/fragments/Department';

export const query = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      id
      title
      photoLink
      language
      extraTermsOfServiceConditions
      hasLimitedAccessToProfiles
      permissions {
        ...ClientPermissions
      }
      settings {
        clickAndOpenTracking {
          trackOnEmailOpen
          trackOnLinkClick
        }
      }
      calendlyIntegration {
        active
      }
      customizations {
        mainMode
      }
      evaluationFormTemplates {
        ...EvaluationFormTemplate
      }
      applicationFormTemplates {
        ...ApplicationFormTemplate
      }
      departments {
        ...Department
      }
      defaultCrmWorkflows {
        ...ClientDefaultCrmWorkflows
      }
      freeTrialConfiguration {
        upgraded
        upgradeDate
        startDate
        endDate
      }
      accountState {
        status
      }
    }
  }
  ${ClientPermissions}
  ${EvaluationFormTemplate}
  ${ApplicationFormTemplate}
  ${Department}
  ${ClientDefaultCrmWorkflows}
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.clientId,
    },
  }),
  props: ({ data: { loading, client, error, refetch } }) => ({
    loading,
    loadingClient: loading,
    client,
    error,
    refetchClient: refetch,
  }),
});
