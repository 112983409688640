import _, { compose } from 'underscore';
import React from 'react';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Button } from 'semantic-ui-react';

import { isEmailAction, TASK_TYPES } from '@/common/constants/taskTypes';
import getPermissionsAndNudgesAtIndex from '@/common/contactFlow/getPermissionsAndNudgesAtIndex';
import {
  getMergeTagsBaseVariables,
  MERGE_TAGS_VARIABLES_BASE_IDS,
} from '@/common/mergeTags/utils';
import { getRandomString } from '@/common';
import GoToButton from '../../../../../containers/Parameters/OfferContactFlow/GoToButton';

import withOfferRecruiterConfiguration from '../../../../../hocs/offers/withOfferRecruiterConfiguration';
import withClientTemplates from '../../../../../hocs/templates/withClientTemplates';
import withUserSettings from '../../../../../hocs/users/withUserSettings';
import ContactFlowAction from './ContactFlowAction';
import ContactFlowPlaceholderSequence from './ContactFlowPlaceholderSequence';

import './ContactFlowSequenceEditor.css';

const FullContactFlowSequenceEditor = ({
  clientId,
  offerId,
  user,
  recruiterConfiguration,
  sequence,
  lastNonEmptySequence,
  onAddSequence,
  onUpdateSequenceTrigger,
  onInsertAction,
  onUpdateAction,
  onRemoveAction,
  onRemoveActions,
  onMoveUpward,
  onMoveDownward,
  templates,
  t,
  defaultTemplatesOnSeveralOffers,
  updateAfterTemplateCreation,
  onQuitContactFlowEditor,
  formErrors,
  applyCcBccToNextMails,
  isGenerationLoading,
}) => {
  if (sequence.isPlaceholder) {
    return (
      <ContactFlowPlaceholderSequence
        sequence={sequence}
        onAddSequence={onAddSequence}
      />
    );
  }
  const sequenceId = sequence.id;
  const { position, trigger: sequenceTrigger, actions = [] } = sequence || {};
  const firstEmailIndex = _.findIndex(actions, (action) =>
    isEmailAction(action),
  );
  const sequenceHasEmail = firstEmailIndex >= 0;

  const onUpdate = ({ action }) => {
    onUpdateAction({
      sequenceId,
      actionId: action.actionId,
      action,
    });
  };

  const lastSequenceId = (lastNonEmptySequence || {}).id;
  const startSequenceAfterDelay = () => {
    const defaultDelayAfterSequence = {
      type: 'delay-after-sequence',
      delay: {
        value: 30,
        unit: 'day',
      },
      sequenceId: lastSequenceId,
    };
    onUpdateSequenceTrigger({
      sequenceId: sequence.id,
      trigger: defaultDelayAfterSequence,
    });
  };

  if (sequence && !sequenceTrigger && position === 'custom') {
    return (
      <div className='sequence-trigger-selector'>
        <GoToButton disabled>Start Sequence after a response</GoToButton>
        <br />
        <GoToButton
          onClick={startSequenceAfterDelay}
          disabled={!lastSequenceId}
        >
          Start Sequence after a delay
        </GoToButton>
      </div>
    );
  }

  const deleteActionsAfterIndex = (index) => async () => {
    const actionsToDelete = actions.slice(index);
    const actionIds = _.pluck(actionsToDelete, 'actionId');
    return onRemoveActions({
      sequenceId,
      actionIds,
    });
  };

  const offerAssignedSender = recruiterConfiguration?.assignedSender;
  const userSignature = user && user.signature ? user.signature : null;
  const senderSignature = offerAssignedSender?.signature || null;
  const signature = !_.isEmpty(offerAssignedSender)
    ? senderSignature
    : userSignature;

  const templateOptions = _.map(
    _.where(templates, { isArchived: false }),
    ({ id, title }) => ({
      value: id,
      text: title,
    }),
  );

  const onClickAddAnAction = (index) => {
    const defaultManualTrigger = { type: 'manual-trigger' };
    const defaultDelayTrigger = {
      type: 'delay-after-action',
      delay: {
        value: 5,
        unit: 'working-day',
      },
    };
    const nudges = getPermissionsAndNudgesAtIndex({
      actions,
      index,
      editType: 'insert',
      t,
    });
    const { manualTrigger, nonManualTrigger } = nudges || {};
    const useTriggerWithDelay =
      nonManualTrigger.isAllowed &&
      (nonManualTrigger.isNudged || !manualTrigger.isAllowed);
    const trigger = useTriggerWithDelay
      ? defaultDelayTrigger
      : defaultManualTrigger;

    const { resolvedLanguage } = i18n;
    const lang = resolvedLanguage ? resolvedLanguage.split('-')[0] : null;

    const firstNameVariable = _.findWhere(getMergeTagsBaseVariables(t), {
      id: MERGE_TAGS_VARIABLES_BASE_IDS.FIRSTNAME,
    });
    const updatedFirstNameId = `${
      firstNameVariable.id
    }_sequence_${getRandomString(10)}`;
    const newAction = {
      type: sequenceHasEmail
        ? TASK_TYPES.SEND_EMAIL
        : TASK_TYPES.SEND_EMAIL_MANUAL,
      trigger,
      message: {
        body:
          lang === 'en'
            ? `<p>Hi {{${updatedFirstNameId}}}!</p><p></p>`
            : `<p>Bonjour {{${updatedFirstNameId}}} !</p><p></p>`,
      },
      snippets: [{ ...firstNameVariable, id: updatedFirstNameId }],
    };

    onInsertAction({
      sequenceId,
      index,
      action: newAction,
    });
  };

  return (
    <div className='full full-contact-flow-sequence-editor'>
      <div className='contact-flow-sequence'>
        {/* <div className='sequence-editor-header'>
            <div>
              <Icon name='trash alternate' onClick={() => onRemoveSequence({ sequenceId: sequence.id })} link />
            </div>
          </div>
          <ContactFlowSequenceTrigger sequence={sequence} /> */}

        <div className='sequence-actions'>
          {_.map(actions, (action, index) => {
            return (
              <div key={action.actionId} className='sequence-action'>
                {index > 0 && (
                  <VerticalLineWithAddButton
                    onClick={() => onClickAddAnAction(index)}
                  />
                )}

                <ContactFlowAction
                  offerId={offerId}
                  clientId={clientId}
                  sequence={sequence}
                  sequenceId={sequence.id}
                  actions={actions}
                  index={index}
                  firstEmailIndex={firstEmailIndex}
                  action={action}
                  signature={isGenerationLoading ? '' : signature}
                  templates={templates}
                  onUpdate={onUpdate}
                  onDelete={() =>
                    onRemoveAction({
                      sequenceId,
                      actionId: action.actionId,
                    })
                  }
                  onMoveUpward={() =>
                    onMoveUpward({
                      sequenceId,
                      actionId: action.actionId,
                    })
                  }
                  onMoveDownward={() =>
                    onMoveDownward({
                      sequenceId,
                      actionId: action.actionId,
                    })
                  }
                  onDeleteMultiple={deleteActionsAfterIndex(index)}
                  templateOptions={templateOptions}
                  onInsertAction={onInsertAction}
                  defaultTemplatesOnSeveralOffers={
                    defaultTemplatesOnSeveralOffers
                  }
                  updateAfterTemplateCreation={updateAfterTemplateCreation}
                  onQuitContactFlowEditor={onQuitContactFlowEditor}
                  formErrors={getActionErrors({
                    action,
                    formErrors,
                  })}
                  t={t}
                  applyCcBccToNextMails={applyCcBccToNextMails}
                />
              </div>
            );
          })}

          {!_.isEmpty(actions) && (
            <VerticalLineWithAddButton
              onClick={() => onClickAddAnAction(actions.length)}
            />
          )}
          {!_.isEmpty(actions) && (
            <div className='offer-2nd-followup-prompt'>
              <Button basic className='end-of-sequence'>
                <i className='ri-checkbox-circle-line' />
                {t('contactFlow.sequences.endOfSequence')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const getActionErrors = ({ action, formErrors }) => {
  return _.filter(formErrors, ({ actionId }) => actionId === action?.actionId);
};

const VerticalLineWithAddButton = ({ onClick }) => {
  return (
    <div className='vertical-line-separator'>
      <div className='line' />
      <i className='ri-add-line add-icon' onClick={onClick} />
    </div>
  );
};

export default compose(
  withClientTemplates,
  withUserSettings,
  withOfferRecruiterConfiguration,
  withTranslation('translations'),
)(FullContactFlowSequenceEditor);
