import React, { FC } from 'react';

import styles from './svg.module.less';

const AccountPin: FC = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={styles.svgIcon}
  >
    <g clipPath='url(#clip0_4099_760)'>
      <path
        d='M9.50404 14.496L8.00004 16L6.49604 14.496C3.54004 13.8133 1.33337 11.1626 1.33337 7.99998C1.33337 4.31998 4.32004 1.33331 8.00004 1.33331C11.68 1.33331 14.6667 4.31998 14.6667 7.99998C14.6667 11.1626 12.46 13.8133 9.50404 14.496ZM4.01537 10.2773C4.99404 11.7373 6.46337 12.6666 8.10671 12.6666C9.74937 12.6666 11.2194 11.738 12.1974 10.2773C11.0879 9.24038 9.62534 8.66452 8.10671 8.66665C6.58785 8.66435 5.12505 9.24022 4.01537 10.2773ZM8.00004 7.33331C8.53047 7.33331 9.03918 7.1226 9.41426 6.74753C9.78933 6.37245 10 5.86375 10 5.33331C10 4.80288 9.78933 4.29417 9.41426 3.9191C9.03918 3.54403 8.53047 3.33331 8.00004 3.33331C7.46961 3.33331 6.9609 3.54403 6.58583 3.9191C6.21075 4.29417 6.00004 4.80288 6.00004 5.33331C6.00004 5.86375 6.21075 6.37245 6.58583 6.74753C6.9609 7.1226 7.46961 7.33331 8.00004 7.33331Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_4099_760'>
        <rect width='16' height='16' fill='currentColor' />
      </clipPath>
    </defs>
  </svg>
);

export default AccountPin;
