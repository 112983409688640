import React, { FC, useContext } from 'react';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Sequence } from '@/types/sequence';
import ProfileRowSequence from '@/routes/RevealView/ProfileRow/ProfileRowSequence';
import {
  ATStype,
  ATS_LOGOS_BY_TYPE,
  ATS_NAMES_BY_TYPE,
  CONNECTORS_WITHOUT_WORKFLOWS,
} from '@/common/reveal';
import { PrimaryCobalt } from '@/less/colors';
import GenericTooltip from '@/components/Common/GenericTooltip';

import MissionCategoryContext from '@/context/MissionCategoryContext';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import styles from './title.module.less';
import { getTranslatedText } from '../../../../../../../common/index';

interface Project {
  id: string;
  data: {
    title?: string;
  };
  __typename:
    | 'RevealGreenhouseJob'
    | 'RevealLeverJob'
    | 'RevealSmartrecruitersJob';
  attachedSequences: any[];
  linkedATSJobId: string | number | null;
  workflows: any[];
  jobPostings?: any[];
}

interface AttachedSequence {
  sequenceId: string;
}

interface SequenceLinkProps {
  clientId: string;
  sequences: Sequence[];
  project: Project;
}

export type LinkedAts = ATStype | undefined | null;

interface ProjectRowTitleProps extends SequenceLinkProps {
  linkedAts: LinkedAts;
  shouldHideSequence?: boolean;
  shouldHideATS?: boolean;
}

interface ProjectIconProps {
  clientId: string;
  linkedAts: LinkedAts;
  project: Project;
}

// PRIMARY = isDefault
const ProjectIcon: FC<ProjectIconProps> = ({
  clientId,
  project,
  linkedAts,
}) => {
  const { t } = useTranslation();
  const missionCategory = useContext(MissionCategoryContext);

  if (!linkedAts) {
    return null;
  }

  if (_.includes(CONNECTORS_WITHOUT_WORKFLOWS, linkedAts)) {
    // since no workflows can be added there is no need
    // to display the logo or the link
    return null;
  }

  const atsName = ATS_NAMES_BY_TYPE[linkedAts];
  const logo = ATS_LOGOS_BY_TYPE[linkedAts];

  let logoComponent = (
    <Link
      to={`/client/${clientId}/reveal/projects/${missionCategory}/${project.id}/workflows`}
      className={styles.iconLink}
    >
      <img
        className={classNames('ats-logo', styles.atsLogo)}
        height='16px'
        alt={`${linkedAts} logo`}
        src={logo}
      />
    </Link>
  );

  if (!project.linkedATSJobId) {
    logoComponent = (
      <Link
        to={`/client/${clientId}/reveal/projects/${missionCategory}/${project.id}/workflows`}
        className={styles.iconLink}
      >
        <img
          className={classNames(
            'ats-logo',
            styles.atsLogo,
            styles.atsDisabledLogo,
          )}
          height='16px'
          alt={`${linkedAts} logo`}
          src={logo}
        />
      </Link>
    );

    return (
      <GenericTooltip
        rich
        trigger={
          <BadgeIcon offset='-3px' color='yellow'>
            {logoComponent}
          </BadgeIcon>
        }
      >
        <div className={styles.popupContainer}>
          <div className={styles.popupTitle}>
            <BadgeIcon borderColor={PrimaryCobalt} offset='-3px' color='yellow'>
              {logoComponent}
            </BadgeIcon>
            <span>
              {t('reveal.missions.sequences.notLinkedTo', {
                atsName,
              })}
            </span>
          </div>
        </div>
      </GenericTooltip>
    );
  }

  return (
    <GenericTooltip
      rich
      trigger={<BadgeIcon offset='-3px'>{logoComponent}</BadgeIcon>}
    >
      <div className={styles.popupContainer}>
        <div className={styles.popupTitle}>
          <BadgeIcon borderColor={PrimaryCobalt} offset='-3px'>
            {logoComponent}
          </BadgeIcon>
          <span>{t('reveal.missions.sequences.linkedTo', { atsName })}</span>
        </div>
        <div className={styles.popupInfoRow}>
          <span>{t('reveal.missions.sequences.workflow')}</span>
          <span>{project.workflows?.length || 0}</span>
        </div>
      </div>
    </GenericTooltip>
  );
};

interface BadgeIconProps {
  className?: string;
  color?: 'green' | 'yellow';
  borderColor?: string;
  offset?: string;
  offsetRight?: string;
  offsetTop?: string;
}

const BadgeIcon: FC<BadgeIconProps> = ({
  color = 'green',
  borderColor = 'white',
  className,
  children,
  offset = '-1px',
  offsetRight = null,
  offsetTop = null,
  ...props
}) => {
  const innerOffsetRight = offsetRight || offset;
  const innerOffsetTop = offsetTop || offset;
  return (
    <div className={styles.badgeIcon} {...props}>
      {!children && <i className={className} />}
      {children}
      <i
        style={{
          borderColor,
          top: innerOffsetTop,
          right: innerOffsetRight,
        }}
        className={classNames(styles.badge, styles[color])}
      />
    </div>
  );
};

const SequenceDetail: FC<{ sequence: Sequence | null }> = ({ sequence }) => {
  if (!sequence) {
    return null;
  }

  return (
    <div className={styles.sequenceListItem}>
      <span>{sequence.title || ''}</span>
      <ProfileRowSequence
        key={`profile-row-sequence-${sequence.id}`}
        currentSequenceInfo={sequence}
        sequences={sequence?.contactFlow.sequences}
        nextInteraction={null}
        // This will not work if our query is paginated
        loading={!sequence}
        isStandalone
        mini={false}
      />
    </div>
  );
};

const SequenceList: FC<{
  title: string;
  attachedSequences: AttachedSequence[];
  sequences: Sequence[];
}> = ({ title, attachedSequences, sequences }) => {
  if (!attachedSequences.length) {
    return null;
  }

  return (
    <div className={styles.sequenceList}>
      <span>{title}</span>
      {_.map(attachedSequences, (attachedSequence) => {
        const sequence = _.findWhere(sequences, {
          id: attachedSequence.sequenceId,
        });
        return (
          <SequenceDetail
            key={attachedSequence.sequenceId}
            sequence={sequence || null}
          />
        );
      })}
    </div>
  );
};

const SequenceLink: FC<SequenceLinkProps> = ({
  clientId,
  project,
  sequences,
}) => {
  const { t } = useTranslation();
  const missionCategory = useContext(MissionCategoryContext);

  const { attachedSequences } = project;
  const primary = _.findWhere(attachedSequences, { isDefault: true });
  const secondary = _.filter(
    attachedSequences,
    (sequence) => !sequence.isDefault,
  );

  if (!primary && !secondary.length) {
    return (
      <GenericTooltip
        rich
        trigger={
          <Link
            to={`/client/${clientId}/reveal/projects/${missionCategory}/${project.id}/sequences`}
            className={styles.iconLink}
            onClick={(e) => e.stopPropagation()}
          >
            <BadgeIcon
              className={classNames(
                'ri-stack-line',
                styles.titleIcon,
                styles.disabled,
              )}
              color='yellow'
            />
          </Link>
        }
      >
        {t('reveal.missions.sequences.noSequences')}
      </GenericTooltip>
    );
  }

  return (
    <GenericTooltip
      rich
      trigger={
        <Link
          className={styles.iconLink}
          to={`/client/${clientId}/reveal/projects/${missionCategory}/${project.id}/sequences`}
        >
          <BadgeIcon
            className={classNames('ri-stack-line', styles.titleIcon)}
            offsetRight='-2px'
            offsetTop='0px'
          />
        </Link>
      }
    >
      <div className={styles.popupContainer}>
        <div className={styles.popupTitle}>
          <BadgeIcon
            borderColor={PrimaryCobalt}
            className={classNames('ri-stack-line inverted', styles.titleIcon)}
          />
          <span>{t('reveal.missions.sequences.popupTitle')}</span>
          <span className={styles.titleCount}>{attachedSequences.length}</span>
        </div>
        <SequenceList
          title={t('reveal.missions.mission.settingsTab.defaultSequence')}
          attachedSequences={primary ? [primary] : []}
          sequences={sequences}
        />
        <SequenceList
          title={t('reveal.missions.mission.settingsTab.secondarySequences')}
          attachedSequences={secondary}
          sequences={sequences}
        />
      </div>
    </GenericTooltip>
  );
};

interface JobPostingLinkProps {
  clientId: string;
  project: Project;
}

const JobPostingLink: FC<JobPostingLinkProps> = ({ clientId, project }) => {
  const { t } = useTranslation();
  const jobPosting = project?.jobPostings?.[0];
  const missionCategory = useContext(MissionCategoryContext);
  const { permissions } = useClientPermissions(clientId);

  if (!permissions?.careerPage || missionCategory !== 'recruiting') {
    return null;
  }

  if (!jobPosting) {
    return (
      <GenericTooltip
        rich
        trigger={
          <Link
            to={`/client/${clientId}/reveal/projects/${missionCategory}/${project?.id}/job-posting`}
            className={styles.iconLink}
            onClick={(e) => e.stopPropagation()}
          >
            <BadgeIcon
              className={classNames(
                'ri-file-list-3-line',
                styles.titleIcon,
                styles.disabled,
              )}
              color='yellow'
            />
          </Link>
        }
      >
        {t('reveal.missions.mission.settingsTab.jobPosting.notConfigured')}
      </GenericTooltip>
    );
  }

  return (
    <GenericTooltip
      rich
      trigger={
        <Link
          className={styles.iconLink}
          to={`/client/${clientId}/reveal/projects/${missionCategory}/${project?.id}/applicants`}
          onClick={(e) => e.stopPropagation()}
        >
          <BadgeIcon
            className={classNames('ri-file-list-3-line', styles.titleIcon)}
            offsetRight='-2px'
            offsetTop='0px'
            color={jobPosting.published ? 'green' : 'yellow'}
          />
        </Link>
      }
    >
      <div className={styles.popupContainer}>
        {getTranslatedText(jobPosting.title)} -{' '}
        {jobPosting.published ? (
          <span className='pill-message green equal-padding small'>
            {t('reveal.missions.mission.settingsTab.jobPosting.published')}
          </span>
        ) : (
          <span className='pill-message grey equal-padding small'>
            {t('reveal.missions.mission.settingsTab.jobPosting.notPublished')}
          </span>
        )}
      </div>
    </GenericTooltip>
  );
};

const ProjectRowTitle: FC<ProjectRowTitleProps> = ({
  linkedAts,
  project,
  sequences,
  clientId,
  shouldHideSequence = false,
  shouldHideATS = false,
}) => {
  return (
    <div className={styles.titleContainer}>
      <span className={styles.titleHeader}>{project.data?.title}</span>
      {!shouldHideSequence && (
        <SequenceLink
          clientId={clientId}
          project={project}
          sequences={sequences}
        />
      )}
      {!shouldHideATS && (
        <ProjectIcon
          clientId={clientId}
          project={project}
          linkedAts={linkedAts}
        />
      )}
      <JobPostingLink clientId={clientId} project={project} />
    </div>
  );
};

export default ProjectRowTitle;
