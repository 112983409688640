import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, Redirect, useRouteMatch, generatePath } from 'react-router-dom';
import _ from 'underscore';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Icon,
  Popup,
  Segment,
  Loader,
  Modal,
  Input,
  Dropdown,
  Form,
} from 'semantic-ui-react';
import {
  GET_SEQUENCES_FOLDERS,
  DELETE_SEQUENCES_FOLDERS,
  RENAME_SEQUENCES_FOLDER,
} from '@/graphql/sequencesFolders';
import ProfileRowSequence from '@/routes/RevealView/ProfileRow/ProfileRowSequence';
import SequencePreview from '@/routes/RevealView/revealComponents/SequencePreview/index';
import './SequencesList.css';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import EmptyState from '@/revealComponents/EmptyState/EmptyState';
import { TableFolderIcon } from '@/assets/icons';
import GenericButton from '@/components/Common/GenericButton';
import ContactFlow from '@/graphql/fragments/ContactFlow';
import Author from '@/graphql/fragments/Author';
import useUserTableViewPreferences from '@/graphql/hooks/users/useUserDisplayPreferences';
import { lowerCaseAndUnaccent } from '@/common';
import { getFullname } from '@/common/helpers/person';
import NewSequenceTypeModal from '@/revealComponents/Modals/NewSequenceTypeModal';
import useArchiveSequence from '@/graphql/hooks/sequences/useArchiveSequence';
import useRestoreSequence from '@/graphql/hooks/sequences/useRestoreSequence';
import { UPDATE_SEQUENCE, DUPLICATE_SEQUENCE } from './queries';
import NewSequenceModal from './NewSequenceModal';

const getActionCount = (sequence) => {
  return sequence?.contactFlow?.sequences?.[0]?.actions?.length || 0;
};

const CLIENT_SEQUENCES = gql`
  query getClientSequences(
    $clientId: ID!
    $filters: ClientSequencesFiltersInput
  ) {
    client(id: $clientId) {
      id
      sequences(filters: $filters) {
        id
        title
        description
        contactFlow {
          ...ContactFlow
        }
        author {
          ...Author
        }
        creationDate
        lastEditionDate
        isArchived
        profilesCount
        folderId
        lastUseDate
        lastUseTimestamp
        lastUseByAuthor {
          authorEmail
          date
          timestamp
        }
      }
    }
  }
  ${ContactFlow}
  ${Author}
`;

export const sortText = (a, b, order) => {
  if (a < b) {
    return -1 * order;
  }

  if (a > b) {
    return 1 * order;
  }

  return 0;
};

const SEQUENCE_SORT_FUNCTIONS = {
  name: (sequences, order) => {
    return [...sequences].sort((s1, s2) => {
      const nameS1 = lowerCaseAndUnaccent(s1.title);
      const nameS2 = lowerCaseAndUnaccent(s2.title);
      return sortText(nameS1, nameS2, order);
    });
  },
  steps: (sequences, order) => {
    return [...sequences].sort((s1, s2) => {
      return (
        (s1.contactFlow.sequences[0].actions.length -
          s2.contactFlow.sequences[0].actions.length) *
        order
      );
    });
  },
  contacts: (sequences, order) => {
    return [...sequences].sort((s1, s2) => {
      return (s1.profilesCount - s2.profilesCount) * order;
    });
  },
  'creation-date': (sequences) => {
    return sequences;
  },
  author: (sequences, order) => {
    return [...sequences].sort((s1, s2) => {
      const a1Name = getFullname(s1.author);
      const a2Name = getFullname(s2.author);
      return sortText(a1Name, a2Name, order);
    });
  },
};

const SEQUENCE_FOLDER_SORT_FUNCTIONS = {
  // Small code duplication with sequences, but easier to read & modify
  name: (folders, order) => {
    return [...folders].sort((f1, f2) => {
      const nameF1 = lowerCaseAndUnaccent(f1.title);
      const nameF2 = lowerCaseAndUnaccent(f2.title);
      return sortText(nameF1, nameF2, order);
    });
  },
  steps: (folders) => {
    return SEQUENCE_FOLDER_SORT_FUNCTIONS.name(folders, 1);
    // return [...folders].sort((f1, f2) => {
    //   return (f1.stepSum - f2.stepSum) * order;
    // });
  },
  contacts: (folders, order) => {
    return [...folders].sort((f1, f2) => {
      return (f1.profilesCountSum - f2.profilesCountSum) * order;
    });
  },
  'creation-date': (folders) => {
    return folders;
  },
  author: (folders, order) => {
    return [...folders].sort((f1, f2) => {
      const a1Name = getFullname(f1.author);
      const a2Name = getFullname(f2.author);
      return sortText(a1Name, a2Name, order);
    });
  },
};

const SequencesList = ({ clientId }) => {
  const { t } = useTranslation();
  const { loading, error, data, refetch } = useQuery(CLIENT_SEQUENCES, {
    variables: { clientId },
    fetchPolicy: 'network-only',
  });
  const { data: sequencesFoldersData } = useQuery(GET_SEQUENCES_FOLDERS, {
    variables: { clientId },
    fetchPolicy: 'network-only',
  });
  const [displayArchived, setDisplayArchived] = useState(false);
  const sequences = data?.client?.sequences;
  const sequencesFolders = sequencesFoldersData?.client?.sequencesFolders;
  const activeSequences = _.where(sequences, { isArchived: false });
  const archivedSequences = _.where(sequences, { isArchived: true });
  const [sequenceTypeModalOpen, setSequenceTypeModalOpen] = useState(false);
  const [sequenceFolderToRename, setSequencesFolderToRename] = useState({});
  const [newFolderName, setNewFolderName] = useState('');

  const [
    renameSequencesFolder,
    { loading: renamingSequenceFolder },
  ] = useMutation(RENAME_SEQUENCES_FOLDER, {
    refetchQueries: [
      {
        query: GET_SEQUENCES_FOLDERS,
        variables: {
          clientId,
        },
      },
    ],
  });

  const onRenameFolder = async () => {
    const sequenceFolderInput = {
      id: sequenceFolderToRename?.id,
      title: newFolderName,
    };
    await renameSequencesFolder({
      variables: {
        input: sequenceFolderInput,
      },
    });
    setSequencesFolderToRename({});
  };

  useEffect(() => {
    setNewFolderName(sequenceFolderToRename?.title);
  }, [sequenceFolderToRename]);

  const onDuplicate = () => {
    refetch();
  };

  return (
    <div className='sequences-list'>
      {loading && <Loader active />}
      {!loading && !error && (
        <>
          {!(_.isEmpty(activeSequences) && _.isEmpty(sequencesFolders)) ? (
            <div className='sequences-section'>
              <SequencesTable
                sequences={activeSequences}
                clientId={clientId}
                sequencesFolders={sequencesFolders}
                onDuplicate={onDuplicate}
                setSequencesFolderToRename={setSequencesFolderToRename}
              />
            </div>
          ) : (
            <Segment>
              <EmptyState
                title={t('sequences.emptyState.title')}
                innerContent={t('sequences.emptyState.innerContent')}
                illustrationPath='/images/placeholders/sequencesEmptyState.svg'
                cta={
                  // <Link
                  //   to={generatePath(
                  //     `${path}/new-sequence-${_.isEmpty(defaultSequenceTemplates)
                  //       ? 'create'
                  //       : 'preview'
                  //     }`,
                  //     params,
                  //   )}
                  // >
                  <GenericButton
                    size='big'
                    onClick={() => setSequenceTypeModalOpen(true)}
                  >
                    {t('sequences.emptyState.cta')}
                  </GenericButton>
                  // </Link>
                }
              />
            </Segment>
          )}
          {!_.isEmpty(archivedSequences) && (
            <>
              <HideAndShowArchivedSequencesButton
                t={t}
                archivedSequences={archivedSequences}
                displayArchived={displayArchived}
                setDisplayArchived={setDisplayArchived}
              />
              {displayArchived && (
                <div className='sequences-section'>
                  <SequencesTable
                    sequences={archivedSequences}
                    clientId={clientId}
                    onDuplicate={onDuplicate}
                    useFolders={false}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
      <Modal
        open={!_.isEmpty(sequenceFolderToRename)}
        size='tiny'
        as={Form}
        onSubmit={onRenameFolder}
        onClick={(event) => event.stopPropagation()}
      >
        <Modal.Header>{t('sequences.renameFolderModal.title')}</Modal.Header>
        <Modal.Content>
          <div className='input-container'>
            <div className='input-label'>
              {t('sequences.renameFolderModal.name')}
            </div>
            <div className='input-element'>
              <Input
                fluid
                value={newFolderName}
                onChange={(_e, { value }) => setNewFolderName(value)}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div className='rename-folder-modal-actions'>
            <GenericButton
              primacy='secondary'
              onClick={() => setSequencesFolderToRename({})}
            >
              {t('sequences.renameFolderModal.cancel')}
            </GenericButton>
            <GenericButton type='submit' disabled={renamingSequenceFolder}>
              {t('sequences.renameFolderModal.rename')}
            </GenericButton>
          </div>
        </Modal.Actions>
      </Modal>
      <NewSequenceTypeModal
        open={sequenceTypeModalOpen}
        onClose={() => setSequenceTypeModalOpen(false)}
        clientId={clientId}
      />
    </div>
  );
};

const HideAndShowArchivedSequencesButton = ({
  archivedSequences,
  displayArchived,
  setDisplayArchived,
  t,
}) => {
  if (_.isEmpty(archivedSequences)) {
    return null;
  }

  if (displayArchived) {
    return (
      <div className='archived-sequences-link-box'>
        <button
          type='button'
          className='link-button'
          onClick={() => setDisplayArchived(false)}
        >
          {t('sequences.buttons.hideArchived')}
        </button>
      </div>
    );
  }

  return (
    <div className='archived-sequences-link-box'>
      <button
        type='button'
        className='link-button'
        onClick={() => setDisplayArchived(true)}
      >
        {t('sequences.buttons.showArchived')}
      </button>
    </div>
  );
};

const SequencesTable = ({
  sequences,
  clientId,
  onDuplicate,
  sequencesFolders,
  useFolders = true,
  setSequencesFolderToRename,
}) => {
  const { t } = useTranslation();
  const [openedSequenceId, setOpenedSequenceId] = useState(null);
  const [activeFolderId, setActiveFolderId] = useState(-1);
  const [activeSequenceRow, setActiveSequenceRow] = useState('');

  const tableId = 'automatisation-sequences';
  const {
    userTableViewPreferences,
    updateUserTableViewPreferences,
  } = useUserTableViewPreferences({
    tableId,
    defaultValues: {
      tableId,
      sortingOptions: {
        sortBy: [
          {
            key: 'name',
            order: 'ascending',
          },
        ],
      },
    },
  });

  const setSort = useCallback(
    ({ key, order }) => {
      updateUserTableViewPreferences({
        tableId,
        sortingOptions: {
          sortBy: [
            {
              key,
              order: order === 1 ? 'ascending' : 'descending',
            },
          ],
        },
      });
    },
    [updateUserTableViewPreferences],
  );

  // This component uses -1/1 internally, but we store ascending/descending
  const { sortKey, sortOrder } = useMemo(() => {
    const [{ key, order }] = userTableViewPreferences?.sortingOptions
      ?.sortBy || [{ key: 'name', order: 'ascending' }];

    return { sortKey: key, sortOrder: order === 'ascending' ? 1 : -1 };
  }, [userTableViewPreferences]);

  const handleToggleSort = useCallback(
    ({ key }) => {
      if (sortKey === key) {
        setSort({ key, order: -sortOrder });
      } else {
        setSort({ key, order: 1 });
      }
    },
    [setSort, sortKey, sortOrder],
  );

  const handleFolderClick = useCallback((id) => {
    setActiveFolderId((currentFolderId) => {
      if (currentFolderId === id) {
        return -1;
      }
      return id;
    });
  }, []);

  const getProfilePerFolder = (folderSequences) => {
    return _.reduce(
      folderSequences,
      (memo, sequence) => {
        return memo + (sequence?.profilesCount || 0);
      },
      0,
    );
  };

  const getFolderSequences = (allSequences, folderId) => {
    return _.filter(allSequences, (sequence) => sequence.folderId === folderId);
  };

  const [deleteSequencesFolder] = useMutation(DELETE_SEQUENCES_FOLDERS, {
    refetchQueries: [
      {
        query: GET_SEQUENCES_FOLDERS,
        variables: {
          clientId,
        },
      },
    ],
  });

  const onDeleteSequencesFolder = async (id) => {
    const deleteFolderInput = {
      id,
    };
    await deleteSequencesFolder({
      variables: {
        input: deleteFolderInput,
      },
    });
  };

  const sortSequences = useCallback(
    (sequencesToSort) => {
      const sortFunction = SEQUENCE_SORT_FUNCTIONS[sortKey] || ((seq) => seq);
      return sortFunction(sequencesToSort, sortOrder);
    },
    [sortKey, sortOrder],
  );

  const sortedSequences = useMemo(() => {
    return sortSequences(sequences);
  }, [sortSequences, sequences]);

  const folderLessSequences = useMemo(() => {
    if (!useFolders) {
      return sortedSequences;
    }
    return _.filter(sortedSequences, (sequence) => !sequence.folderId);
  }, [sortedSequences, useFolders]);

  const sortedFolders = useMemo(() => {
    const sortFunction =
      SEQUENCE_FOLDER_SORT_FUNCTIONS[sortKey] || ((seq) => seq);

    const foldersWithSums = _.map(sequencesFolders, (folder) => {
      const folderSequences = getFolderSequences(sortedSequences, folder.id);

      return {
        ...folder,
        stepSum: _.reduce(
          folderSequences,
          (sum, sequence) => {
            return sum + sequence.contactFlow.sequences[0].actions.length;
          },
          0,
        ),
        profilesCountSum: _.reduce(
          folderSequences,
          (sum, sequence) => {
            return sum + sequence.profilesCount;
          },
          0,
        ),
      };
    });
    return sortFunction(foldersWithSums, sortOrder);
  }, [sequencesFolders, sortKey, sortOrder, sortedSequences]);

  return (
    <>
      <Table basic className='sequences-table'>
        <Table.Header className='sequence-table-headers'>
          <Table.Row>
            <Table.HeaderCell>
              <TableHeader
                rowKey='name'
                name={t('sequences.table.name')}
                sortKey={sortKey}
                sortOrder={sortOrder}
                handleToggleSort={handleToggleSort}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <TableHeader
                rowKey='steps'
                name={t('sequences.table.steps')}
                sortKey={sortKey}
                sortOrder={sortOrder}
                handleToggleSort={handleToggleSort}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <TableHeader
                rowKey='contacts'
                name={t('sequences.table.candidatesCount')}
                sortKey={sortKey}
                sortOrder={sortOrder}
                handleToggleSort={handleToggleSort}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <TableHeader
                rowKey='author'
                name={t('sequences.table.author')}
                sortKey={sortKey}
                sortOrder={sortOrder}
                handleToggleSort={handleToggleSort}
              />
            </Table.HeaderCell>
            <Table.HeaderCell collapsing />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(sortedFolders, (folder) => {
            const folderSequences = getFolderSequences(
              sortedSequences,
              folder.id,
            );
            const isFolderEmpty = _.isEmpty(folderSequences);
            return (
              <Fragment key={folder.id}>
                <Table.Row
                  className='folder-row'
                  key='test-folder'
                  onClick={() => handleFolderClick(folder.id)}
                >
                  <Table.Cell colSpan={2} textAlign='left'>
                    <div className='folder-header'>
                      <TableFolderIcon />
                      <span className='folder-header-title'>
                        {folder.title}
                      </span>
                      {isFolderEmpty && (
                        <span className='folder-empty-title'>
                          {t('sequences.emptyFolder')}
                        </span>
                      )}
                    </div>
                  </Table.Cell>
                  <Table.Cell textAlign='left'>
                    <div>{getProfilePerFolder(folderSequences)}</div>
                  </Table.Cell>

                  <Table.Cell textAlign='left'>
                    <div>
                      {`${folder.author?.firstname} ${folder.author?.lastname}`}
                    </div>
                  </Table.Cell>

                  <Table.Cell>
                    <div
                      className='folder-actions'
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Dropdown
                        className='folder-actions-dropdown'
                        floating
                        direction='left'
                        icon={<i className='ri-more-fill ri-lg' />}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => setSequencesFolderToRename(folder)}
                            className='action-folder-title'
                            text={
                              <>
                                <i className='ri-pencil-line ri-lg' />
                                {t('sequences.folderActions.rename')}
                              </>
                            }
                          />
                          <Dropdown.Item
                            onClick={() =>
                              isFolderEmpty
                                ? onDeleteSequencesFolder(folder.id)
                                : {}
                            }
                            className='action-folder-title'
                            disabled={!isFolderEmpty}
                            style={{ padding: '0px' }}
                            text={
                              <>
                                <i className='ri-delete-bin-line ri-lg' />
                                {t('sequences.folderActions.delete')}
                              </>
                            }
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                      {!isFolderEmpty && (
                        <i
                          className={`${
                            activeFolderId === folder.id
                              ? 'ri-arrow-up-s-line'
                              : 'ri-arrow-down-s-line'
                          } ri-lg`}
                          onClick={() => handleFolderClick(folder.id)}
                        />
                      )}
                      {/* <i class="ri-add-fill ri-lg"></i> */}
                    </div>
                  </Table.Cell>
                </Table.Row>
                {_.map(folderSequences, (sequence) => (
                  <SequenceRow
                    key={sequence.id}
                    sequence={sequence}
                    folders={sequencesFolders}
                    currentFolder={folder}
                    activeFolderId={activeFolderId}
                    onDuplicate={onDuplicate}
                    clientId={clientId}
                    activeSequenceRow={activeSequenceRow}
                    setActiveSequenceRow={setActiveSequenceRow}
                  />
                ))}
              </Fragment>
            );
          })}
          {_.map(folderLessSequences, (sequence) => (
            <SequenceRow
              key={sequence.id}
              sequence={sequence}
              folders={sequencesFolders}
              onDuplicate={onDuplicate}
              clientId={clientId}
              activeSequenceRow={activeSequenceRow}
              setActiveSequenceRow={setActiveSequenceRow}
            />
          ))}
        </Table.Body>
      </Table>
      {openedSequenceId && (
        <NewSequenceModal
          clientId={clientId}
          open
          onClose={() => setOpenedSequenceId(null)}
          editionMode
          sequence={_.findWhere(sequences, { id: openedSequenceId })}
        />
      )}
    </>
  );
};

const SequenceRow = ({
  sequence,
  folders,
  currentFolder,
  activeSequenceRow,
  setActiveSequenceRow,
  activeFolderId,
  onDuplicate,
  clientId,
}) => {
  const { t } = useTranslation();
  const [redirectTo, setRedirectTo] = useState();
  const [archiveSequence] = useArchiveSequence();
  const [restoreSequence] = useRestoreSequence();
  const [duplicateSequence] = useMutation(DUPLICATE_SEQUENCE);
  const notification = useNotificationSystem();
  const { path, params } = useRouteMatch();

  const [updateSequence, { error: sequenceEditionError }] = useMutation(
    UPDATE_SEQUENCE,
  );

  const onMoveToFolder = async (folderId) => {
    const sequenceInput = {
      id: sequence.id,
      folderId,
    };

    await updateSequence({
      variables: { input: sequenceInput },
    });

    if (!sequenceEditionError) {
      setActiveSequenceRow('');
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} push />;
  }

  const foldersWithoutCurrentFolder = _.filter(
    folders,
    (folder) => folder.id !== currentFolder?.id,
  );

  const handleDuplicateSequence = async ({ id }) => {
    try {
      await duplicateSequence({ variables: { input: { id } } });
      notification.success(t('sequences.duplicate.success'));
      if (onDuplicate) {
        onDuplicate();
      }
    } catch (error) {
      notification.error(t('sequences.duplicate.error'));
    }
  };

  return (
    <Table.Row
      className={`sequence-row${
        sequence?.id === activeSequenceRow ? ' active' : ''
      }`}
      key={sequence.id}
      style={{
        display:
          !activeFolderId || sequence.folderId === activeFolderId
            ? 'table-row'
            : 'none',
      }}
      onClick={() =>
        setRedirectTo(
          `/client/${clientId}/reveal/sequences/${sequence?.id}/edit`,
        )
      }
    >
      <Table.Cell textAlign='left'>
        <div className={`sequence-header${currentFolder ? ' in-folder' : ''}`}>
          <i className='ri-stack-line sequence-icon' alt='template-grey' />
          <span className='sequence-header-title'>{sequence?.title}</span>
        </div>
      </Table.Cell>

      <Table.Cell textAlign='left'>
        <Popup
          basic
          position='right center'
          hoverable
          pinned
          trigger={
            <div style={{ width: '100%' }}>
              <ProfileRowSequence
                currentSequenceInfo={sequence}
                sequences={sequence?.contactFlow.sequences}
                nextInteraction={null}
                loading={false}
                isStandalone
                mini={false}
              />
            </div>
          }
          content={<SequencePreview clientId={clientId} sequence={sequence} />}
          style={{
            maxHeight: '500px',
            minWidth: '400px',
            overflowY: 'auto',
            marginLeft: `-${25 - getActionCount(sequence) * 3}%`,
          }}
        />
      </Table.Cell>

      <Table.Cell textAlign='left'>
        <div>{sequence?.profilesCount || 0}</div>
      </Table.Cell>

      <Table.Cell textAlign='left'>
        <div>{getSequenceAuthor(sequence?.author)}</div>
      </Table.Cell>

      <Table.Cell>
        <div
          className='sequence-actions'
          onClick={(event) => event.stopPropagation()}
        >
          <Popup
            content={
              <div className='sequence-more-actions'>
                <Link
                  className='more-actions-folder'
                  to={generatePath(`${path}/${sequence?.id}/edit`, {
                    ...params,
                  })}
                >
                  <i className='ri-pencil-line ri-lg' />
                  <span>{t('sequences.buttons.edit')}</span>
                </Link>
                <div
                  className='more-actions-folder'
                  onClick={() => handleDuplicateSequence(sequence)}
                >
                  <i className='ri-file-copy-2-line ri-lg' />
                  <span>{t('sequences.buttons.duplicate')}</span>
                </div>
                {!sequence.isArchived ? (
                  <div
                    className='more-actions-folder'
                    onClick={() => archiveSequence(sequence.id)}
                  >
                    <i className='ri-archive-line ri-lg' />
                    <span>{t('sequences.buttons.archive')}</span>
                  </div>
                ) : (
                  <div
                    className='more-actions-folder'
                    onClick={() => restoreSequence(sequence.id)}
                  >
                    <i className='ri-inbox-unarchive-line ri-lg' />
                    <span>{t('templates.buttons.restore')}</span>
                  </div>
                )}
                {sequence?.folderId && (
                  <div
                    className='more-actions-folder'
                    onClick={() => onMoveToFolder('root')}
                  >
                    <i className='ri-delete-bin-line ri-lg' />
                    <span className='action-folder-title'>
                      {t('sequences.folderActions.removeFromFolder')}
                    </span>
                  </div>
                )}
                {!_.isEmpty(foldersWithoutCurrentFolder) && (
                  <div className='more-actions-category'>
                    {t('sequences.folderActions.moveTo')}
                  </div>
                )}
                {_.map(foldersWithoutCurrentFolder, (folder) => (
                  <div
                    className='more-actions-folder'
                    onClick={() => onMoveToFolder(folder.id)}
                    key={folder.id}
                  >
                    <TableFolderIcon />
                    <span className='action-folder-title'>{folder.title}</span>
                  </div>
                ))}
              </div>
            }
            on='click'
            basic
            className='sequence-more-actions-popup'
            position='bottom right'
            offset={[0, -2]}
            onClose={() => setActiveSequenceRow('')}
            trigger={
              <i
                onClick={() => setActiveSequenceRow(sequence.id)}
                className='sequence-more-action-btn ri-more-fill ri-lg'
              />
            }
          />
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

const getSequenceAuthor = (author) => {
  if (!author) {
    return '';
  }

  return `${author.firstname || ''} ${author.lastname || ''}`;
};

const orderClass = (val) => (val > 0 ? ' sort increasing' : ' sort decreasing');
const orderIcon = (val) => (
  <div className='icon-container'>
    {val > 0 ? <Icon name='triangle up' /> : <Icon name='triangle down' />}
  </div>
);

const TableHeader = ({
  rowKey,
  name,
  sortKey,
  sortOrder,
  handleToggleSort,
}) => (
  <div
    className={`button-header${
      sortKey === rowKey ? orderClass(sortOrder) : ''
    }`}
    onClick={() => handleToggleSort({ key: rowKey })}
  >
    <span className='header-name'>{name}</span>{' '}
    {sortKey === rowKey ? orderIcon(sortOrder) : ''}
  </div>
);

export default SequencesList;
