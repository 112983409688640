import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { ApplicationFormTemplate } from '../../graphql/fragments/Applications';

export const addApplicationFormTemplate = gql`
  mutation addApplicationFormTemplate(
    $applicationFormTemplate: ApplicationFormTemplateInput!
  ) {
    addApplicationFormTemplate(
      applicationFormTemplate: $applicationFormTemplate
    ) {
      id
      applicationFormTemplates {
        ...ApplicationFormTemplate
      }
    }
  }
  ${ApplicationFormTemplate}
`;

export default graphql(addApplicationFormTemplate, {
  props: ({ mutate }) => ({
    addApplicationFormTemplate: ({ applicationFormTemplate }) =>
      mutate({
        variables: {
          applicationFormTemplate,
        },
      }),
  }),
});
