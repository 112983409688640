import { useQuery, QueryHookOptions } from '@apollo/client';
import { query as GET_CLIENT } from '../../../hocs/clients/withClient';

const useClient = (clientId: string, options?: QueryHookOptions) =>
  useQuery(GET_CLIENT, {
    ...options,
    variables: { id: clientId },
  });

export default useClient;
