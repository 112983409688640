import { PipelineState } from '@/components/PipelineSegmentation/pipelineSegmentation';
import { Author } from '../author';

export type PipelineActionCountType =
  | 'nbMoveToPending'
  | 'nbMoveToContacted'
  | 'nbMoveToReplied'
  | 'nbMoveToInterested'
  | 'nbMoveToHired'
  | 'nbMoveToArchived';

export const archiveReasonCategories = [
  'non-custom-reason',
  'no-reason',
  'not-contacted',
  'no-answer',
  'profile-not-interested',
  'profile-interested;they-rejected-us',
  'profile-interested;we-rejected-them',
  'profile-interested;other-archive-reason',
] as const;
export type ArchiveReasonCategory = typeof archiveReasonCategories[number];
export interface PipelineActionCount {
  type: PipelineActionCountType;
  missionId: string;
  count: number;
  archivedDetails?: {
    fromStage: PipelineState | 'null';
    customArchiveReasonId?: string;
    category: ArchiveReasonCategory;
  };
}

export interface PipelineActionDetails {
  type: PipelineActionCountType;
  missionId: string;
  profileItems: {
    profileId: string;
  }[];
  archivedDetails?: {
    fromStage: PipelineState | 'null';
    customArchiveReasonId?: string;
    category: ArchiveReasonCategory;
  };
}

export interface PipelineUserStatistics {
  author: Author;
  stats: PipelineActionCount[];
}

export interface PipelineUserStatisticsDetails {
  author: Author;
  stats: PipelineActionDetails[];
}

export interface PipelineDailyEvent {
  day: string;
  userStats: PipelineUserStatistics[];
}

export interface PipelineDailyEventDetails {
  day: string;
  userStats: PipelineUserStatisticsDetails[];
}
