import gql from 'graphql-tag';
import DailyEventsStatistics from './DailyEventsStatistics';

export const mainEventsFields = gql`
  fragment mainEventsFields on MainEvent {
    date
    type
    subtype
    count
    offer {
      id
      title
    }
  }
`;

export const OfferStatistics = gql`
  fragment OfferStatistics on OfferStatistics {
    dailyEvents {
      ...DailyEventsStatistics
    }
    dailyCohorts {
      ...DailyEventsStatistics
    }
    # offersActivity {
    #   jobOfferId
    #   offerCounts {
    #     ...DailyEventsStatistics
    #   }
    # }
    firstContactSequenceCohorts {
      index0 {
        ...DailyEventsStatistics
      }
      index1 {
        ...DailyEventsStatistics
      }
      index2 {
        ...DailyEventsStatistics
      }
      index3 {
        ...DailyEventsStatistics
      }
      index4 {
        ...DailyEventsStatistics
      }
    }
  }
  ${DailyEventsStatistics}
`;

export const FullStatistics = gql`
  fragment FullStatistics on Client {
    id
    statistics {
      mainEvents {
        ...mainEventsFields
      }
      offers(offerIds: $offerIds) {
        ...OfferStatistics
      }
    }
  }
  ${OfferStatistics}
  ${mainEventsFields}
`;
