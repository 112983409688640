import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SlackIntegration from '../../graphql/fragments/SlackIntegration';

export const mutation = gql`
  mutation editClientSlackIntegrationSettings(
    $integrationId: ID!
    $notificationsSettings: SlackNotificationsSettingsInput
  ) {
    editClientSlackIntegrationSettings(
      integrationId: $integrationId
      notificationsSettings: $notificationsSettings
    ) {
      id
      slackIntegration {
        ...SlackIntegration
      }
    }
  }
  ${SlackIntegration}
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    editClientSlackIntegrationSettings: ({
      notificationsSettings,
      integrationId,
    }) =>
      mutate({
        variables: {
          notificationsSettings,
          integrationId,
        },
      }),
  }),
});
