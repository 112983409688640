import _ from 'underscore';
import React from 'react';
import RecursiveSelector from '../components/RecursiveSelector';

type Filter = Record<string, Record<string, never>>;

type ApplicationStatus = { status: string };

interface WelcomekitApplicationStatusesFilterProps {
  onUpdate: any;
  applicationStatuses: ApplicationStatus[];
  applicationStatusOptions: { id: string; name: string }[];
}

const WelcomekitApplicationStatusesFilter: React.FC<WelcomekitApplicationStatusesFilterProps> = ({
  onUpdate,
  applicationStatuses,
  applicationStatusOptions,
}) => {
  const filter = getFilterFromApplicationStatuses({
    applicationStatuses,
  });
  return (
    <RecursiveSelector
      folderedOptions={applicationStatusOptions}
      filter={filter}
      level={0}
      onUpdateFilter={({ filter: newFilter }: { filter: Filter }) => {
        onUpdate({
          applicationStatuses: getApplicationStatusesFromFilter({
            filter: newFilter,
          }),
        });
      }}
    />
  );
};

const getFilterFromApplicationStatuses = ({
  applicationStatuses,
}: {
  applicationStatuses: ApplicationStatus[];
}) => {
  const filter: Filter = {};
  _.each(applicationStatuses, ({ status }) => {
    filter[status] = {};
  });
  return filter;
};

const getApplicationStatusesFromFilter = ({ filter }: { filter: Filter }) => {
  return _.compact(
    _.map(filter, (subFilter, status) => {
      if (!subFilter) {
        return null;
      }
      return { status };
    }),
  );
};

export default WelcomekitApplicationStatusesFilter;
