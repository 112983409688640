import gql from 'graphql-tag';

export default gql`
  fragment UserPermissions on UserPermissions {
    pluginGmail
    pluginCalendar
    pluginLinkedin
    crmTaskOnLinkedinRecruiter
    canEditRevealAtsConfiguration
    canEditCustomArchiveReasons
    canEnableAutoSend
  }
`;
