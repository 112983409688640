import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Criteria from '../../graphql/fragments/Criteria';

export const mutation = gql`
  mutation updateCriteria($id: ID!, $input: CriteriaInput!) {
    updateCriteria(id: $id, input: $input) {
      id
      title
      criteria {
        ...Criteria
      }
    }
  }
  ${Criteria}
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    updateCriteria: ({ id, input }) => {
      return mutate({
        variables: {
          id,
          input,
        },
      });
    },
  }),
});
