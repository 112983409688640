import { useQuery } from '@apollo/client';
import SEARCH_POOL_JOB from '../../searchPoolJob';

export const getSearchPoolJobVariables = (
  searchPoolId: string,
  jobId: string,
) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const lastWeek = new Date(today);
  lastWeek.setDate(today.getDate() - 7);

  const threeMonthsAgo = new Date(today);
  threeMonthsAgo.setMonth(today.getMonth() - 3);
  return {
    searchPoolId,
    jobId,
    addedSince: threeMonthsAgo.toISOString(),
  };
};

const useSearchPoolJob = (
  searchPoolId: string,
  jobId: string,
  pollInterval?: number,
) => {
  return useQuery(SEARCH_POOL_JOB, {
    variables: getSearchPoolJobVariables(searchPoolId, jobId),
    skip: !jobId,
    pollInterval,
  });
};

export default useSearchPoolJob;
