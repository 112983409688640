import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import ContactFlow from '../../graphql/fragments/ContactFlow';

export const insertAction = gql`
  mutation insertAction(
    $jobOfferId: ID
    $contactFlowId: ID!
    $sequenceId: ID!
    $actionIndex: Int!
    $actionInput: ContactFlowActionInput!
  ) {
    insertAction(
      jobOfferId: $jobOfferId
      contactFlowId: $contactFlowId
      sequenceId: $sequenceId
      actionIndex: $actionIndex
      actionInput: $actionInput
    ) {
      ...ContactFlow
    }
  }
  ${ContactFlow}
`;

export default graphql(insertAction, {
  props: ({ ownProps, mutate }) => ({
    insertAction: ({ contactFlowId, sequenceId, actionIndex, actionInput }) =>
      mutate({
        variables: {
          jobOfferId: ownProps.offerId,
          contactFlowId,
          sequenceId,
          actionIndex,
          actionInput,
        },
      }),
  }),
});
