import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { FullStatistics } from '../../graphql/fragments/Analytics';

export const query = gql`
  query getClientFullStatistics($clientId: ID!, $offerIds: [String!]) {
    client(id: $clientId) {
      ...FullStatistics
    }
  }
  ${FullStatistics}
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      clientId: ownProps.clientId,
      offerIds: [],
    },
  }),
  props: ({ data: { loading } }) => {
    return {
      fullStatisticsLoading: loading,
    };
  },
});
