import _ from 'underscore';
import { withTranslation } from 'react-i18next';
import React from 'react';
import { Grid, Table, Popup, Icon } from 'semantic-ui-react';
import { getDisplayableName, getRandomString } from '../../../../common';
import { formatDate } from '../../../../common/dates';


const getTruncated = (grade) => {
  return grade !== undefined ? Math.round(grade * 100) / 100 : undefined;
};

const getGradesAverage = ({ fields }) => {
  let sumGrades;
  let sumCoefs = 0;

  _.each(fields, ({ value, type, coefficient, subfields }) => {
    if (subfields) {
      let sumSubfieldCoefs = 0;
      _.each(subfields, ({ coefficient }) => {
        sumSubfieldCoefs += coefficient || 1;
      });
      const averageSubfieldsGrade = getGradesAverage({ fields: subfields });
      if (averageSubfieldsGrade !== undefined) {
        sumGrades = (sumGrades || 0) + sumSubfieldCoefs * averageSubfieldsGrade;
        sumCoefs += sumSubfieldCoefs;
      }
    } else if (value?.grade !== undefined && type !== 'comment') {
      sumGrades = (sumGrades || 0) + (coefficient || 1) * value.grade;
      sumCoefs += coefficient || 1;
    }
  });

  return sumGrades !== undefined && sumCoefs !== 0 ? sumGrades / sumCoefs : undefined;
};

const ScorecardDisplay = ({ scorecard }) => {
  const { subfields } = scorecard;
  const sumAllCoefs = _.reduce(subfields, (memo, { coefficient }) => memo + (coefficient || 0), 0);
  const averageGrade = getGradesAverage({ fields: subfields });

  return (
    <div className='evaluation-field'>
      <div>
        <h5>
          {scorecard.title} {false && sumAllCoefs /* disabled */ ? <span>(coef {sumAllCoefs})</span> : ''}
          {averageGrade !== undefined ? <span> - {getTruncated(averageGrade)}/5</span> : ''}
        </h5>
      </div>
      <div>
        <Table fluid basic='very' celled>
          <Table.Body>
            {_.map(subfields, ({ title, coefficient, value }, index) => (
              <Table.Row key={index}>
                <Table.Cell className='title-cell'>
                  {title}
                  {false && coefficient /* deactivated */ ? (
                    <span className='title-cell-coefficient'> x{coefficient}</span>
                  ) : (
                    ''
                  )}
                </Table.Cell>
                <Table.Cell className='grade-cell' textAlign='center'>
                  {getTruncated(value?.grade)}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

const EvaluationFieldDisplay = ({ field }) => {
  const displayComment = ({ field }) => {
    if (!_.isEmpty(field.values?.comments)) {
      return (
        <span>
          {_.map(field.values.comments, ({ comment, author }, index) => (
            <Popup
              content={(
                <span>
                  <span>{getDisplayableName({ author })}</span>
                  <br />
                  <span>{comment}</span>
                </span>
              )}
              trigger={<Icon style={{ color: '#4864C9', opacity: 0.7 }} name='comment' />}
            />
          ))}
        </span>
      );
    }
    if (!_.isEmpty(field.value?.comment)) {
      return <span>{field.value?.comment}</span>;
    }
    return <span className='no-comment'>No comment left</span>;
  };

  if (field.type === 'comment') {
    return (
      <div className='evaluation-field'>
        <div>
          <h5>{field.title}</h5>
        </div>
        <div className='field-comment'>{displayComment({ field })}</div>
      </div>
    );
  }

  if (field.type === 'grade-and-comment') {
    return (
      <div className='evaluation-field'>
        <div>
          <h5>
            {field.title} - {getTruncated(field.value?.grade)}/5
          </h5>
        </div>
        <div className='field-comment'>{displayComment({ field })}</div>
      </div>
    );
  }

  if (field.type === 'grade') {
    return (
      <div className='evaluation-field'>
        <div>
          <h5>
            {field.title} - {getTruncated(field.value?.grade)}/5
          </h5>
        </div>
      </div>
    );
  }

  if (field.type === 'scorecard') {
    return <ScorecardDisplay scorecard={field} />;
  }
  return <div />;
};

const getMergedEvaluation = ({ evaluations }) => {
  const result = {
    authors: [],
    form: {
      fields: [],
    },
  };

  const mergedForm = result.form;

  // inject data from all evaluations
  _.each(evaluations, (evaluation) => {
    if (evaluation.author) {
      result.authors.push(evaluation.author);
    }
    if (evaluation.date) {
      if (!result.date || evaluation.date > result.date) {
        result.date = evaluation.date;
      }
    }
    _.each(evaluation.form?.fields, (field, index) => {
      if (mergedForm.fields.length <= index) {
        mergedForm.fields.push({
          ..._.omit(field, 'value', 'subfields'),
          id: getRandomString(10),
          values: {
            grades: [],
            comments: [],
          },
        });
      }
      const mergedField = mergedForm.fields[index];
      if (mergedField.type !== field.type || mergedField.title !== field.title) {
        return;
      }

      if (field.value?.grade !== undefined) {
        mergedField.values.grades.push({
          author: evaluation.author,
          grade: field.value.grade,
        });
      }
      if (field.value?.comment) {
        mergedField.values.comments.push({
          author: evaluation.author,
          comment: field.value.comment,
        });
      }

      // subfields
      _.each(field.subfields, (subfield, subfieldIndex) => {
        if (!mergedField.subfields) {
          mergedField.subfields = [];
        }
        if (mergedField.subfields.length <= subfieldIndex) {
          mergedField.subfields.push({
            ..._.omit(subfield, 'value'),
            id: getRandomString(10),
            values: {
              grades: [],
              comments: [],
            },
          });
        }
        const mergedSubfield = mergedField.subfields[subfieldIndex];
        if (subfield.value?.grade !== undefined) {
          mergedSubfield.values.grades.push({
            author: evaluation.author,
            grade: subfield.value.grade,
          });
        }
      });
    });
  });

  const aggregateField = ({ field }) => {
    let sumGrades = 0;
    let nbGrades = 0;
    const { grades } = field.values;
    _.each(grades, ({ grade }) => {
      if (grade !== undefined) {
        sumGrades += grade;
        nbGrades += 1;
      }
    });

    if (nbGrades > 0) {
      field.value = {
        grade: sumGrades / nbGrades,
      };
    }
  };

  // aggregate on each field
  _.each(mergedForm.fields, (field) => {
    aggregateField({ field });
    _.each(field.subfields, (subfield) => {
      aggregateField({ field: subfield });
    });
  });

  return result;
};

const EvaluationDisplay = ({ evaluation: unitEvaluation, evaluations, onClose, t }) => {
  const evaluation = (evaluations || []).length >= 1 ? getMergedEvaluation({ evaluations }) : unitEvaluation;

  const gradesAverage = getGradesAverage({ fields: evaluation.form?.fields });

  return (
    <div className='evaluation-display'>
      <div className='evaluation-display-header'>
        <Grid style={{ margin: 0, padding: 0 }}>
          <Grid.Row style={{ margin: 0, padding: 0 }}>
            <Grid.Column width={12} style={{ margin: 0, padding: 0 }}>
              {evaluation.authors ? (
                <span className='evaluation-display-title'>
                  {t('profile.evaluations.feedbacks')} {_.map(evaluation.authors, (author) => getDisplayableName({ author })).join(', ')}
                </span>
              ) : (
                <span className='evaluation-display-title'>
                  {t('profile.evaluations.feedbackBy', {
                    author: getDisplayableName({ author: evaluation.author }),
                    date: formatDate(new Date(evaluation.date).getTime(), t),
                  })}
                </span>
              )}
            </Grid.Column>
            <Grid.Column width={4} textAlign='right' style={{ margin: 0, padding: 0 }}>
              <div className='close-button' onClick={onClose}>
                <img src='/images/icons/close2.png' alt='carret.png' />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div className='evaluation-display-body'>
        {gradesAverage !== undefined && (
          <center className='evaluation-display-average-container'>
            <span className='evaluation-display-average'>{t('profile.evaluations.average')} {getTruncated(gradesAverage)}/5</span>
          </center>
        )}
        {_.map((evaluation.form || {}).fields, (field, index) => (
          <EvaluationFieldDisplay key={index} field={field} />
        ))}
      </div>
    </div>
  );
};

export default withTranslation('translations')(EvaluationDisplay);
