const SOURCES = [
  'linkedin',
  'viadeo',
  'github',
  'stackoverflow',
  'coderWall',
  'codePen',
  'bitBucket',
  'reddit',
  'twitter',
  'angelList',
  'meetup',
  'googlePlus',
  'dribbble',
  'behance',
  'pinterest',
  'drupal',
  'joomla',
  'aboutMe',
  'medium',
  'quora',
  'speakerDeck',
  'githubIo',
  'smartrecruiters',
  'greenhouse',
  'lever',
  'teamtailor',
  'recruitee',
  'boondmanager',
  'website',
  'hiresweet',
];

export default SOURCES;
