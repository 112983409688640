import React from 'react';
import { Popup } from 'semantic-ui-react';

export default (props) => (
  <Popup
    trigger={
      <a
        spellCheck={false}
        data-offset-key={props.offsetKey}
        href={props.contentState.getEntity(props.entityKey).getData().url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    }
    content={props.contentState.getEntity(props.entityKey).getData().url}
  />
);
