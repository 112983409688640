import React from 'react';
import { useTranslation } from 'react-i18next';

const Step0: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className='step0'>
      <h1 className='step-title'>{t('reveal.onboardingModal.step0.title')}</h1>
      <h2 className='step-subtitle'>
        {t('reveal.onboardingModal.step0.subtitle')}
      </h2>
      <div className='list'>
        <ListItem index='1'>
          <span className='description'>
            {t('reveal.onboardingModal.step0.contacts.text')}
          </span>
          <GreenPill>
            {t('reveal.onboardingModal.step0.contacts.pill0')}
          </GreenPill>
          <GreenPill>
            {t('reveal.onboardingModal.step0.contacts.pill1')}
          </GreenPill>
          <GreenPill>
            {t('reveal.onboardingModal.step0.contacts.pill2')}
          </GreenPill>
        </ListItem>
        <ListItem index='2'>
          <span className='description'>
            {t('reveal.onboardingModal.step0.reach.text')}
          </span>
          <GreenPill>{t('reveal.onboardingModal.step0.reach.pill0')}</GreenPill>
          <GreenPill>{t('reveal.onboardingModal.step0.reach.pill1')}</GreenPill>
          <GreenPill>{t('reveal.onboardingModal.step0.reach.pill2')}</GreenPill>
        </ListItem>
        <ListItem index='3'>
          <span className='description'>
            {t('reveal.onboardingModal.step0.monitor.text')}
          </span>
          <GreenPill>
            {t('reveal.onboardingModal.step0.monitor.pill0')}
          </GreenPill>
          <GreenPill>
            {t('reveal.onboardingModal.step0.monitor.pill1')}
          </GreenPill>
        </ListItem>
      </div>
    </div>
  );
};

interface ListItemProps {
  index: string;
}
const ListItem: React.FC<ListItemProps> = ({ index, children }) => (
  <div className='list-item'>
    <div className='number'>{index}</div>
    <div className='content'>{children}</div>
  </div>
);

const GreenPill: React.FC = ({ children }) => (
  <div className='green-pill'>{children}</div>
);

export default Step0;
