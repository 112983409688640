import React, { useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import TooltipContainer from '@/components/Common/TooltipContainer';
import { StackItem } from '@/types/statistics/stackItem';
import { Stats } from '@/types/statistics/stats';
import {
  getDisplayedValue,
  getStackName,
  getTotalCount,
} from '@/common/helpers/stats';
import LegendCategory from '../Legend/LegendCategory';

import styles from './StackedBar.module.less';

interface StackedBarProps {
  stack: StackItem[];
  stats: Stats;
  topBoundary: number;
  isVertical?: boolean;
  isPercentage?: boolean;
  exhaustive?: boolean;
}

const StackedBar: React.FC<StackedBarProps> = ({
  stack,
  stats,
  topBoundary,
  isVertical,
  isPercentage,
  exhaustive,
}) => {
  const { t } = useTranslation();

  const filteredStack = useMemo(
    () => _.filter(stack, (stackItem) => stats.values[stackItem.datakey] !== 0),
    [stack, stats],
  );

  const tooltipStack = useMemo(() => {
    const baseStack = exhaustive ? stack : filteredStack;
    return isVertical ? [...baseStack].reverse() : baseStack;
  }, [filteredStack, isVertical, exhaustive, stack]);

  const tooltipContent = useMemo(
    () =>
      getTotalCount(stats) === 0 ? null : (
        <div className={styles.tooltip}>
          <div className={styles.tooltipHeader}>
            <span>{stats.name}</span>
            <span>{getTotalCount(stats)}</span>
          </div>
          <div>
            {_.map(tooltipStack, (stackItem) => (
              <div className={styles.tooltipItem} key={stackItem.datakey}>
                <LegendCategory color={stackItem.color}>
                  {getStackName(t, stackItem)}
                </LegendCategory>
                <span>
                  {getDisplayedValue(t, isPercentage)(stats, stackItem.datakey)}
                </span>
              </div>
            ))}
          </div>
        </div>
      ),
    [tooltipStack, stats, t, isPercentage],
  );

  return (
    <TooltipContainer tooltipContent={tooltipContent}>
      <div
        className={`${styles.bar} ${
          isVertical ? styles.vertical : styles.horizontal
        }`}
      >
        {_.map(filteredStack, ({ datakey, color }) => (
          <button
            type='button'
            key={datakey}
            className={classNames(
              styles.section,
              stats.clickListeners?.[datakey] && styles.clickable,
            )}
            style={{
              backgroundColor: color,
              flex: stats.values[datakey],
            }}
            onClick={stats.clickListeners?.[datakey]}
          />
        ))}
        <div
          className={styles.background}
          style={{
            flex: isPercentage ? 0 : topBoundary - getTotalCount(stats),
          }}
        >
          <span
            className={`${styles.totalCount} ${
              isPercentage ? styles.hidden : ''
            }`}
          >
            {Math.round(getTotalCount(stats)) || ''}
          </span>
        </div>
      </div>
    </TooltipContainer>
  );
};

export default StackedBar;
