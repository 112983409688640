import React, { useEffect, useMemo, useState } from 'react';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useCompanyTargetContacts from '@/graphql/hooks/searchPoolProfile/useCompanyTargetContacts';
import useClientId from '@/hooks/router/useClientId';
import { ProfileDetails } from '@/routes/RevealView/JobsView/JobView/ProjectProfilesTab/ProfileRow';
import useSearchPoolMiniProfileById from '@/graphql/hooks/profiles/useSearchPoolMiniProfileById';
import Plus from '@/components/Reveal/Icons/Plus';
import GenericTextInput from '@/components/Common/GenericTextInput';
import { getRandomDefaultAvatarLink, getSecureLink } from '@/common';
import {
  MiniProfilesSearchProvider,
  useMiniProfilesSearchContext,
} from '@/context/Profiles/useMiniProfilesSearch';

import GenericModal from '@/components/Common/GenericModal';
import { getFullname } from '@/common/helpers/person';
import { Image, Modal, Placeholder } from 'semantic-ui-react';
import GenericButton from '@/components/Common/GenericButton';
import useUpdateProfileLinkedCompany from '@/graphql/hooks/searchPoolProfile/useUpdateProfileLinkedCompany';
import classNames from 'classnames';
import { SearchPoolMiniProfile } from '@/graphql/searchPoolMiniProfileById';
import styles from './LinkedContacts.module.less';

type Props = {
  profileId: string;
};

const LinkedContacts = ({ profileId }: Props) => {
  const [selectedContactId, setSelectedContactId] = useState<string | null>(
    null,
  );
  const [linkContact, setLinkContact] = useState(false);
  const { t } = useTranslation();
  const { companyTargetContacts, loading } = useCompanyTargetContacts({
    profileId,
  });

  const [updateProfileLinkedCompany] = useUpdateProfileLinkedCompany();

  return (
    <>
      <h4 className={styles.title}>
        {t('reveal.profileModal.companyOverview.linkedContacts.title')}
      </h4>
      {_.isEmpty(companyTargetContacts) && !loading && (
        <div className={styles.emptyState}>
          {t('reveal.profileModal.companyOverview.linkedContacts.emptyState')}
        </div>
      )}
      {companyTargetContacts?.map(({ id }) => (
        <div key={id}>
          <CompanyLinkedContact profileId={id || ''} />
        </div>
      ))}
      {loading && (
        <div className={styles.placeholder}>
          <Placeholder>
            <Placeholder.Header image>
              <Placeholder.Line length='very short' />
              <Placeholder.Line length='medium' />
            </Placeholder.Header>
          </Placeholder>
        </div>
      )}
      {!linkContact && !loading && (
        <span
          className={styles.addContactButton}
          onClick={() => setLinkContact(true)}
        >
          <Plus />
          {t(
            'reveal.profileModal.companyOverview.linkedContacts.linkContact.title',
          )}
        </span>
      )}
      <GenericModal open={linkContact} onClose={() => setLinkContact(false)}>
        <Modal.Header>
          {t(
            'reveal.profileModal.companyOverview.linkedContacts.linkContact.title',
          )}
        </Modal.Header>
        <Modal.Content className={styles.contactSelectorContainer}>
          <div className={styles.description}>
            {t(
              'reveal.profileModal.companyOverview.linkedContacts.linkContact.description',
            )}
          </div>
          <MiniProfilesSearchProvider contactCategory='human'>
            <LinkContactInner
              onSelectedContact={setSelectedContactId}
              selectedContactId={selectedContactId}
              linkedContactIds={_.map(companyTargetContacts, 'id')}
            />
          </MiniProfilesSearchProvider>
        </Modal.Content>
        <Modal.Actions className={styles.actions}>
          <GenericButton
            size='big'
            primacy='secondary'
            onClick={() => {
              setSelectedContactId(null);
              setLinkContact(false);
            }}
          >
            {t('common.cancel')}
          </GenericButton>
          <GenericButton
            size='big'
            disabled={!selectedContactId}
            onClick={() => {
              updateProfileLinkedCompany({
                variables: {
                  searchPoolId: 'reveal',
                  profileId: selectedContactId || '',
                  input: {
                    id: profileId,
                  },
                },
              });
              setSelectedContactId(null);
              setLinkContact(false);
            }}
          >
            {t(`common.save`)}
          </GenericButton>
        </Modal.Actions>
      </GenericModal>
    </>
  );
};

interface CompanyLinkedContactProps {
  profileId: string;
}

const CompanyLinkedContact: React.FC<CompanyLinkedContactProps> = ({
  profileId,
}) => {
  const clientId = useClientId();
  const { profile, loading } = useSearchPoolMiniProfileById(
    {
      searchPoolId: 'reveal',
      profileId,
    },
    {
      fetchPolicy: 'network-only',
    },
  );

  if (loading || !profile) {
    return null;
  }

  return (
    <Link
      key={profileId}
      to={`/client/${clientId}/reveal/search?profileId=${profileId}`}
      className={styles.card}
    >
      <div className={styles.cardTitle}>
        <ProfileDetails
          clientId={clientId}
          searchPoolId='reveal'
          profile={profile}
        />
      </div>
    </Link>
  );
};

const LinkContactInner = ({
  linkedContactIds,
  onSelectedContact,
  selectedContactId,
}: {
  onSelectedContact: (profileId: string) => void;
  selectedContactId: string | null;
  linkedContactIds: string[];
}) => {
  const [localText, setLocalText] = useState('');
  const { t } = useTranslation();
  const { searchText, setSearchText, searchPoolProfiles } =
    useMiniProfilesSearchContext();

  const debounceSetSearchText = useMemo(
    () => _.debounce(setSearchText, 500),
    [setSearchText],
  );

  useEffect(() => {
    debounceSetSearchText(localText || '');
  }, [localText, debounceSetSearchText]);

  const filteredResults = useMemo(() => {
    return _.filter(searchPoolProfiles, (profile) => {
      return !_.contains(linkedContactIds, profile.id);
    });
  }, [searchPoolProfiles, linkedContactIds]);

  return (
    <>
      <div>
        <GenericTextInput
          className={styles.contactSearch}
          value={localText || ''}
          placeholder={t(
            'reveal.profileModal.companyOverview.linkedContacts.linkContact.searchContact',
          )}
          onValue={setLocalText}
        />
      </div>
      <div className={styles.contactsContainer}>
        {_.isEmpty(searchText) ? (
          <p className={styles.emptyState}>
            {t(
              'reveal.profileModal.companyOverview.linkedContacts.linkContact.emptyState',
            )}
          </p>
        ) : _.isEmpty(filteredResults) ? (
          <p className={styles.emptyState}>
            {t(
              'reveal.profileModal.companyOverview.linkedContacts.linkContact.noResult',
            )}
          </p>
        ) : (
          <>
            {_.map(filteredResults, (profile) => (
              <LinkedProfileRow
                profile={profile}
                key={profile.id}
                onProfileSelected={onSelectedContact}
                isActive={profile.id === selectedContactId}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

const LinkedProfileRow = ({
  profile,
  onProfileSelected,
  isActive,
}: {
  profile: SearchPoolMiniProfile;
  onProfileSelected: (profileId: string) => void;
  isActive: boolean;
}) => {
  const avatarLink = getRandomDefaultAvatarLink(
    (profile?.resumeData?.firstname || '') +
      (profile?.resumeData?.lastname || ''),
  );

  return (
    <div
      className={classNames(styles.contact, isActive ? styles.active : '')}
      key={profile.id}
      onClick={() => onProfileSelected(profile.id)}
    >
      <div className={styles.contactHeader}>
        <Image
          className={styles.contactAvatar}
          circular
          src={getSecureLink(profile?.resumeData?.photoLink || '')}
          onError={(e: any) => {
            e.target.src = avatarLink;
          }}
        />
        <div className={styles.contactName}>
          {getFullname({
            firstname: profile?.resumeData?.firstname,
            lastname: profile?.resumeData?.lastname,
          })}
        </div>
      </div>
    </div>
  );
};

export default LinkedContacts;
