import _ from 'underscore';

import baseSkillOptions from '@/common/options/skillOptions.json';
import revealSkillOptions from '@/common/options/revealSkillExtraOptions.json';

import { getTranslatedText } from '../../../common';
import jobPositionOptions from '../../../common/options/jobPositionOptions.json';
import locationOptions from '../../../common/options/revealLocationOptions.json';

const skillOptions = [...baseSkillOptions, ...revealSkillOptions];

export const getOptionText = ({ value, options }) => {
  return _.findWhere(options, { value })?.text || '';
};

const filterEmpty = (tags) => _.reject(tags, (tag) => !tag?.text);

export const getJobTags = ({ jobPosition }) => {
  const majorJobTag = {
    type: 'major',
    text: getOptionText({
      value: jobPosition?.major?.id,
      options: jobPositionOptions,
    }),
  };

  const minorJobTags = _.map(jobPosition?.minors, (job) => ({
    type: 'minor',
    text: getOptionText({ value: job?.id, options: jobPositionOptions }),
  }));

  return filterEmpty([majorJobTag, ...minorJobTags]);
};

export const getSkillsTags = ({ skills }) => {
  const getSkills = (level) => (skills?.[level] || [])[0]?.skills;
  const mainSkillTags = _.map(getSkills('main'), (skill) => ({
    text: getOptionText({ value: skill?.id, options: skillOptions }),
    type: 'main',
  }));

  const importantSkillTags = _.map(getSkills('important'), (skill) => ({
    text: getOptionText({ value: skill?.id, options: skillOptions }),
    type: 'important',
  }));

  const bonusSkillTags = _.map(getSkills('bonus'), (skill) => ({
    text: getOptionText({ value: skill?.id, options: skillOptions }),
    type: 'bonus',
  }));

  return filterEmpty([
    ...mainSkillTags,
    ...importantSkillTags,
    ...bonusSkillTags,
  ]);
};

export const getSkillsTagsFromCriteria = ({ skills }) => {
  const mainSkillTags = _.map(skills?.required, ({ label }) => ({
    text: getTranslatedText(label),
    type: 'main',
  }));

  const importantSkillTags = _.map(skills?.important, ({ label }) => ({
    text: getTranslatedText(label),
    type: 'important',
  }));

  const bonusSkillTags = _.map(skills?.bonus, ({ label }) => ({
    text: getTranslatedText(label),
    type: 'bonus',
  }));

  return filterEmpty([
    ...mainSkillTags,
    ...importantSkillTags,
    ...bonusSkillTags,
  ]);
};

export const getExperienceTags = ({ experience }) => {
  const { min, max } = experience?.target || {};
  if (!_.isNumber(min) && !_.isNumber(max)) {
    return null;
  }

  let targetTagText;
  if (!_.isNumber(min)) {
    targetTagText = `Experience < ${max}y`;
  } else if (!_.isNumber(max)) {
    targetTagText = `Experience > ${min}y`;
  } else {
    targetTagText = `Experience ${min} - ${max}y`;
  }
  return filterEmpty([{ text: targetTagText, type: 'experienceTarget' }]);
};

export const getLocationsTags = ({ locations }) => {
  const targetLocationsTags = _.map(locations?.target, (locationWish) => ({
    text: getOptionText({
      value: locationWish?.location?.id,
      options: locationOptions,
    }),
    type: 'locationTarget',
  }));
  return filterEmpty(targetLocationsTags);
};

export const getBackgroundTags = ({
  backgroundSolidity,
  schoolPrestige,
  startupnessImportance,
}) => {
  const backgroundSolidityTag = backgroundSolidity?.disable
    ? { text: 'Background solidity • OFF', type: 'solidity off' }
    : { text: 'Background solidity • ON', type: 'solidity on' };

  const schoolPrestigeTag = schoolPrestige?.disable
    ? { text: 'School prestige • OFF', type: 'prestige off' }
    : { text: 'School prestige • ON', type: 'prestige on' };

  const startupnessTagByImportance = {
    low: { text: 'Startupness • Low', type: 'minor' },
    medium: { text: 'Startupness • Medium', type: 'medium' },
    high: { text: 'Startupness • High', type: 'major' },
  };
  const startupnessTag = startupnessTagByImportance[startupnessImportance];
  if (!startupnessTag) {
    return null;
  }
  return filterEmpty([
    backgroundSolidityTag,
    schoolPrestigeTag,
    startupnessTag,
  ]);
};
