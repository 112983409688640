/* eslint-disable no-restricted-syntax */
import _ from 'underscore';
import { TFunction } from 'i18next';
import moment from 'moment';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';

export const SNIPPET_TYPES = {
  PATH: 'path',
  VARIABLE: 'variable',
  CONCAT: 'concat',
  CURRENT_COMPANY: 'current-company',
  CURRENT_JOB_TITLE: 'current-job-title',
  LAST_COMPANY: 'last-company',
  CUSTOM_FIELD: 'custom-field',
  MISSION_CUSTOM_FIELD: 'mission-custom-field',
  PLACEHOLDER: 'placeholder',
  FRAGMENT: 'fragment',
  SELECT: 'select',
  CURRENT_EXPERIENCE_DURATION: 'current-experience-duration',
  DURATION_SINCE_GRADUATION: 'duration-since-graduation',
  K_LAST_COMPANIES: 'k-last-companies',
  CONDITIONS_CHAINING: 'conditions-chaining',
  TEXT_WITH_SNIPPETS: 'text-with-snippets',
  SYNCED_FRAGMENT: 'synced-fragment',
  AI_TOKEN: 'ai-token',
} as const;

export const SNIPPET_INPUT_BY_TYPE: Record<string, string> = {
  [SNIPPET_TYPES.PATH]: 'snippetPath',
  [SNIPPET_TYPES.VARIABLE]: 'snippetVariable',
  [SNIPPET_TYPES.CONCAT]: 'snippetConcat',
  [SNIPPET_TYPES.CURRENT_COMPANY]: 'snippetCurrentCompany',
  [SNIPPET_TYPES.CURRENT_JOB_TITLE]: 'snippetCurrentJobTitle',
  [SNIPPET_TYPES.LAST_COMPANY]: 'snippetLastCompany',
  [SNIPPET_TYPES.CUSTOM_FIELD]: 'snippetCustomField',
  [SNIPPET_TYPES.MISSION_CUSTOM_FIELD]: 'snippetMissionCustomField',
  [SNIPPET_TYPES.PLACEHOLDER]: 'snippetPlaceholder',
  [SNIPPET_TYPES.FRAGMENT]: 'snippetFragment',
  [SNIPPET_TYPES.SELECT]: 'snippetSelect',
  [SNIPPET_TYPES.CURRENT_EXPERIENCE_DURATION]:
    'snippetCurrentExperienceDuration',
  [SNIPPET_TYPES.DURATION_SINCE_GRADUATION]: 'snippetDurationSinceGraduation',
  [SNIPPET_TYPES.K_LAST_COMPANIES]: 'snippetKLastCompanies',
  [SNIPPET_TYPES.CONDITIONS_CHAINING]: 'snippetConditionsChaining',
  [SNIPPET_TYPES.SYNCED_FRAGMENT]: 'snippetSyncedFragment',
  [SNIPPET_TYPES.AI_TOKEN]: 'snippetAIToken',
};

export enum DurationVariableFormat {
  SMART = 'smart',
  YEARS_AND_MONTHS = 'years-and-months',
  YEARS = 'years',
}

export type VariableExamplePreviewLocale = 'fr' | 'en';

export interface MergeTagsBaseExample {
  id: string;
  text: string;
}

export type CurrentCompanyVariable = {
  type: typeof SNIPPET_TYPES.CURRENT_COMPANY;
};
export type CurrentJobTitleVariable = {
  type: typeof SNIPPET_TYPES.CURRENT_JOB_TITLE;
};
export type LastCompanyVariable = {
  type: typeof SNIPPET_TYPES.LAST_COMPANY;
};
export type CustomFieldVariable = {
  type: typeof SNIPPET_TYPES.CUSTOM_FIELD;
  clientProfileCustomFieldId: string;
};
export type SyncedFragmentVariable = {
  type: typeof SNIPPET_TYPES.SYNCED_FRAGMENT;
  clientDynamicVariableId: string;
};
export type MissionCustomFieldVariable = {
  type: typeof SNIPPET_TYPES.MISSION_CUSTOM_FIELD;
  clientMissionCustomFieldId: string;
};
export type CurrentExperienceDurationVariable = {
  type: typeof SNIPPET_TYPES.CURRENT_EXPERIENCE_DURATION;
  format: DurationVariableFormat;
  locale: VariableExamplePreviewLocale;
};
export type DurationSinceGraduationVariable = {
  type: typeof SNIPPET_TYPES.DURATION_SINCE_GRADUATION;
  format: DurationVariableFormat;
  locale: VariableExamplePreviewLocale;
};
export type KLastCompaniesVariable = {
  type: typeof SNIPPET_TYPES.K_LAST_COMPANIES;
  number: number;
  locale: VariableExamplePreviewLocale;
};
export type TextWithSnippetsVariable = {
  type: typeof SNIPPET_TYPES.TEXT_WITH_SNIPPETS;
  text: string;
  snippets: MergeTagsVariable[];
};
export type AITokenVariable = {
  type: typeof SNIPPET_TYPES.AI_TOKEN;
};

// Conditions chaining types

type AcceptIsKnown = {
  id: string;
  type: 'is-known';
};
type AcceptIsUnknown = {
  id: string;
  type: 'is-unknown';
};
type AcceptStringEquals = {
  id: string;
  type: 'string-equals';
  targetText: string;
};
type AcceptStringContains = {
  id: string;
  type: 'string-contains';
  targetText: string;
};
type AcceptStringNotEquals = {
  id: string;
  type: 'string-not-equals';
  targetText: string;
};
type AcceptStringNotContains = {
  id: string;
  type: 'string-not-contains';
  targetText: string;
};
type AcceptStringIsAmong = {
  id: string;
  type: 'string-is-among';
  targetTexts: string[];
};

export type AcceptCondition =
  | AcceptIsKnown
  | AcceptIsUnknown
  | AcceptStringEquals
  | AcceptStringContains
  | AcceptStringNotEquals
  | AcceptStringNotContains
  | AcceptStringIsAmong;

export type AcceptConditionWithTargetText =
  | AcceptStringEquals
  | AcceptStringContains
  | AcceptStringNotEquals
  | AcceptStringNotContains;

export const ACCEPT_CONDITIONS_WITH_TARGET_TEXT = [
  'string-equals',
  'string-contains',
  'string-not-equals',
  'string-not-contains',
];

export type AcceptConditionWithTargetTexts = AcceptStringIsAmong;

export const ACCEPT_CONDITIONS_WITH_TARGET_TEXTS = ['string-is-among'];

export type ConditionNativeField =
  | 'firstname'
  | 'lastname'
  | 'gender'
  | 'and'
  | 'or';

export type ConditionStringFields = 'firstname' | 'lastname';
export type ConditionSelectFields = 'gender';
export type AcceptConditionType = AcceptCondition['type'];

export const CONDITION_STRING_FIELDS = ['firstname', 'lastname'] as const;
export const CONDITION_SELECT_FIELDS = ['gender'] as const;

export type StringFieldCondition = {
  id: string;
  type: 'field-condition';
  fieldCategory: 'native';
  fieldId: string;
  fieldType: 'string';
  accept: AcceptCondition;
};

export type SelectFieldCondition = {
  id: string;
  type: 'field-condition';
  fieldCategory: 'native';
  fieldId: string;
  fieldType: 'select';
  accept: AcceptCondition;
};

export type CustomFieldCondition = {
  id: string;
  type: 'field-condition';
  fieldCategory: 'custom-field';
  customFieldId: string;
  fieldType: string;
  accept: AcceptCondition;
};

export type LinkedMissionCustomFieldCondition = {
  id: string;
  type: 'field-condition';
  fieldCategory: 'linked-mission-custom-field';
  customFieldId: string;
  fieldType: string;
  accept: AcceptCondition;
};

export type FieldCondition =
  | StringFieldCondition
  | SelectFieldCondition
  | CustomFieldCondition
  | LinkedMissionCustomFieldCondition;

export type AndCondition = {
  id: string;
  type: 'and';
  conditions: FieldCondition[];
};
export type OrCondition = {
  id: string;
  type: 'or';
  conditions: FieldCondition[];
};

export type Condition = FieldCondition | AndCondition | OrCondition;

export type ConditionValue = {
  id: string;
  text: string;
};

export type ConditionResult = SweetEvaluatorTypes.BaseVariable &
  TextWithSnippetsVariable;

export type IfStatement = {
  id: string;
  condition: Condition;
  result: ConditionResult;
};

export type ElseStatement = ConditionResult;

export type ConditionsChainingVariable = {
  id?: string;
  type: 'conditions-chaining';
  subtype?: 'switch-on-field';
  switchField?: {
    fieldId: string;
  };
  ifStatements: IfStatement[];
  elseStatement: ElseStatement;
};

type ExternalVariable =
  | (SweetEvaluatorTypes.BaseVariable & CurrentCompanyVariable)
  | (SweetEvaluatorTypes.BaseVariable & CurrentJobTitleVariable)
  | (SweetEvaluatorTypes.BaseVariable & LastCompanyVariable)
  | (SweetEvaluatorTypes.BaseVariable & CustomFieldVariable)
  | (SweetEvaluatorTypes.BaseVariable & MissionCustomFieldVariable)
  | (SweetEvaluatorTypes.BaseVariable & CurrentExperienceDurationVariable)
  | (SweetEvaluatorTypes.BaseVariable & DurationSinceGraduationVariable)
  | (SweetEvaluatorTypes.BaseVariable & KLastCompaniesVariable)
  | (SweetEvaluatorTypes.BaseVariable & ConditionsChainingVariable)
  | (SweetEvaluatorTypes.BaseVariable & TextWithSnippetsVariable)
  | (SweetEvaluatorTypes.BaseVariable & SyncedFragmentVariable)
  | (SweetEvaluatorTypes.BaseVariable & AITokenVariable);

export type MergeTagsVariable = (
  | SweetEvaluatorTypes.Variable
  | ExternalVariable
) & {
  isVirtual?: boolean;
  actionId?: string;
  idInParentDynamicVariable?: string;
};

export const MERGE_TAGS_VARIABLES_BASE_IDS = {
  CURRENT_COMPANY: 'v-1243',
  CURRENT_JOB_TITLE: 'v-4567',
  LAST_COMPANY: 'v-9123',
  PLACEHOLDER: 'v-5678',
  FIRSTNAME: 'v-5173',
  LASTNAME: 'v-7890',
  EMAIL: 'v-5634',
  CURRENT_EXPERIENCE_DURATION: 'v-9862',
  DURATION_SINCE_GRADUATION: 'v-9982',
  MISSION_TITLE: 'v-SQ7YPY9F',
  MISSION_OWNER_FIRSTNAME: 'v-EDCVDCY0',
  MISSION_OWNER_LASTNAME: 'v-71WTUW5G',
  K_LAST_COMPANIES: 'v-TTDG37YJ',
  CONDITIONS_CHAINING: 'v-ZZC78QMR',
  TEXT_WITH_SNIPPETS: 'v-7XDNPYRG',
  AI_TOKEN: 'v-1029',
} as const;

export const getMergeTagsBaseVariables = (
  t: TFunction,
): MergeTagsVariable[] => [
  {
    id: MERGE_TAGS_VARIABLES_BASE_IDS.CURRENT_COMPANY,
    type: SNIPPET_TYPES.CURRENT_COMPANY,
    name: t('editor.currentCompany'),
  },
  {
    id: MERGE_TAGS_VARIABLES_BASE_IDS.CURRENT_JOB_TITLE,
    type: SNIPPET_TYPES.CURRENT_JOB_TITLE,
    name: t('editor.currentJobTitle'),
  },
  {
    id: MERGE_TAGS_VARIABLES_BASE_IDS.LAST_COMPANY,
    type: SNIPPET_TYPES.LAST_COMPANY,
    name: t('editor.lastCompany'),
  },
  {
    id: MERGE_TAGS_VARIABLES_BASE_IDS.PLACEHOLDER,
    type: SNIPPET_TYPES.PLACEHOLDER,
    name: t('editor.placeholderKey'),
  },
  {
    id: MERGE_TAGS_VARIABLES_BASE_IDS.FIRSTNAME,
    type: SNIPPET_TYPES.PATH,
    path: ['firstname'],
    name: t('editor.firstname'),
  },
  {
    id: MERGE_TAGS_VARIABLES_BASE_IDS.LASTNAME,
    type: SNIPPET_TYPES.PATH,
    path: ['lastname'],
    name: t('editor.lastname'),
  },
  {
    id: MERGE_TAGS_VARIABLES_BASE_IDS.EMAIL,
    type: SNIPPET_TYPES.PATH,
    path: ['email'],
    name: t('editor.email'),
  },
  {
    id: MERGE_TAGS_VARIABLES_BASE_IDS.CURRENT_EXPERIENCE_DURATION,
    type: SNIPPET_TYPES.CURRENT_EXPERIENCE_DURATION,
    format: DurationVariableFormat.SMART,
    name: t('editor.currentExperienceDuration'),
    locale: 'en',
  },
  {
    id: MERGE_TAGS_VARIABLES_BASE_IDS.DURATION_SINCE_GRADUATION,
    type: SNIPPET_TYPES.DURATION_SINCE_GRADUATION,
    format: DurationVariableFormat.SMART,
    name: t('editor.durationSinceGraduation'),
    locale: 'en',
  },
  {
    id: MERGE_TAGS_VARIABLES_BASE_IDS.MISSION_TITLE,
    type: SNIPPET_TYPES.PATH,
    name: t('editor.missionTitle'),
    path: ['linkedMission', 'title'],
  },
  {
    id: MERGE_TAGS_VARIABLES_BASE_IDS.MISSION_OWNER_FIRSTNAME,
    type: SNIPPET_TYPES.PATH,
    name: t('editor.missionOwnerFirstname'),
    path: ['linkedMission', 'owner', 'firstname'],
  },
  {
    id: MERGE_TAGS_VARIABLES_BASE_IDS.MISSION_OWNER_LASTNAME,
    type: SNIPPET_TYPES.PATH,
    name: t('editor.missionOwnerLastname'),
    path: ['linkedMission', 'owner', 'lastname'],
  },
  {
    id: MERGE_TAGS_VARIABLES_BASE_IDS.K_LAST_COMPANIES,
    type: SNIPPET_TYPES.K_LAST_COMPANIES,
    name: t('editor.kLastCompanies'),
    number: 3,
    locale: 'en',
  },
  {
    id: MERGE_TAGS_VARIABLES_BASE_IDS.CONDITIONS_CHAINING,
    type: SNIPPET_TYPES.CONDITIONS_CHAINING,
    name: t('editor.conditionsChaining'),
    ifStatements: [],
    elseStatement: {} as ConditionResult,
  },
];

export const getMergeTagsSimplifiedConditionVariable = ({
  id,
  fieldId,
  name,
}: {
  id: string;
  fieldId: string;
  name: string;
}): SweetEvaluatorTypes.BaseVariable & ConditionsChainingVariable => {
  return {
    id,
    type: SNIPPET_TYPES.CONDITIONS_CHAINING,
    name,
    subtype: 'switch-on-field',
    switchField: { fieldId },
    ifStatements: [],
    elseStatement: {} as ConditionResult,
  };
};

export const getMergeTagsSnippets = ({
  t,
  clientSnippets,
  customFields,
  missionCustomFields,
}: {
  t: TFunction;
  clientSnippets?: MergeTagsVariable[];
  customFields?: CustomFieldDefinition[];
  missionCustomFields?: CustomFieldDefinition[];
}): MergeTagsVariable[] => {
  const baseSnippets: MergeTagsVariable[] = getMergeTagsBaseVariables(t);
  if (!_.isEmpty(clientSnippets)) {
    _.each(clientSnippets || [], (snippet) => {
      baseSnippets.push({
        id: snippet.id,
        type: snippet.type,
        name: snippet.name,
      } as MergeTagsVariable);
    });
  }
  if (!_.isEmpty(customFields)) {
    _.each(customFields || [], (cf) => {
      baseSnippets.push({
        id: cf.id,
        type: SNIPPET_TYPES.CUSTOM_FIELD,
        name: cf.title.default || cf.id,
        clientProfileCustomFieldId: cf.id,
      } as MergeTagsVariable);
    });
  }
  if (!_.isEmpty(missionCustomFields)) {
    _.each(missionCustomFields || [], (cf) => {
      baseSnippets.push({
        id: cf.id,
        type: SNIPPET_TYPES.MISSION_CUSTOM_FIELD,
        name: cf.title.default || cf.id,
        clientMissionCustomFieldId: cf.id,
      } as MergeTagsVariable);
    });
  }

  return baseSnippets;
};

export const getOldMergeTagsKeys = (
  t: TFunction,
): { key: string; label: string }[] => [
  { key: 'firstname', label: t('editor.firstname') },
  { key: 'lastname', label: t('editor.lastname') },
  { key: 'email', label: t('editor.email') },
];

const getMergeTagsKeys = ({
  t,
  clientSnippets,
  customFields,
  missionCustomFields,
}: {
  t: TFunction;
  clientSnippets?: MergeTagsVariable[];
  customFields?: CustomFieldDefinition[];
  missionCustomFields?: CustomFieldDefinition[];
}) =>
  _.map(
    getMergeTagsSnippets({
      t,
      clientSnippets,
      customFields,
      missionCustomFields,
    }),
    (snippet) => ({
      key: snippet.id,
      label: snippet.name || snippet.id,
    }),
  );

export const getMergeTagLabel = ({
  key,
  t,
  clientSnippets,
  customFields,
  missionCustomFields,
}: {
  key: string;
  t: TFunction;
  clientSnippets?: MergeTagsVariable[];
  customFields?: CustomFieldDefinition[];
  missionCustomFields?: CustomFieldDefinition[];
}): string => {
  const mergeTag =
    _.findWhere(getOldMergeTagsKeys(t), { key }) ||
    _.findWhere(
      getMergeTagsKeys({
        t,
        customFields,
        clientSnippets,
        missionCustomFields,
      }),
      {
        key,
      },
    );
  return mergeTag?.label || '';
};

export const isOneLineText = ({ text }: { text: string }): boolean => {
  // Vérifier qu'il n'y a qu'une seule balise HTML englobant le texte
  const matchSingleTag = text.match(/^<(\w+)>.*<\/\1>$/);

  // Vérifier que le texte ne contient pas de sauts de ligne
  const matchNoLineBreaks =
    !text.match(/<br\/>/) && !text.match(/<br>/) && !text.match(/<br \/>/);

  return (matchSingleTag && matchNoLineBreaks) || false;
};

/**
 * This function is duplicated in the backend
 * If a modification is made here, it must be replicated in the backend
 */

interface DurationVariable {
  startDate: string;
  endDate: string;
  format: CurrentExperienceDurationVariable['format'];
  locale: VariableExamplePreviewLocale;
}

export const getMergeTagsCalculatedDuration = (
  input: DurationVariable,
): { calculatedValue: string; rawValue: unknown } => {
  const { startDate, endDate, format, locale } = input;
  const start = moment(startDate);
  const end = moment(endDate);

  const monthsText = {
    fr: 'mois',
    en: 'months',
  };
  const monthText = {
    fr: 'mois',
    en: 'month',
  };
  const yearsText = {
    fr: 'ans',
    en: 'years',
  };
  const yearText = {
    fr: 'an',
    en: 'year',
  };
  const andText = {
    fr: 'et',
    en: 'and',
  };
  const halfText = {
    fr: 'demi',
    en: 'a half',
  };

  const monthDiff = end.diff(start, 'months');
  const yearDiff = end.diff(start, 'years');

  const getMonthsText = (months: number) => {
    return months === 1 ? monthText[locale] : monthsText[locale];
  };
  const getYearsText = (years: number) => {
    return years === 1 ? yearText[locale] : yearsText[locale];
  };

  if (monthDiff === 0) {
    return {
      calculatedValue: `1 ${getMonthsText(1)}`,
      rawValue: 1,
    };
  }

  const smartFormatter = () => {
    if (monthDiff <= 10) {
      return `${monthDiff} ${getMonthsText(monthDiff)}`;
    }
    if (monthDiff <= 12) {
      return `1 ${getYearsText(1)}`;
    }
    const monthsModulo = monthDiff % 12;
    if (monthsModulo <= 3) {
      return `${yearDiff} ${getYearsText(yearDiff)}`;
    }
    if (monthsModulo <= 8) {
      return `${yearDiff} ${getYearsText(yearDiff)} ${andText[locale]} ${
        halfText[locale]
      }`;
    }
    return `${yearDiff + 1} ${getYearsText(yearDiff + 1)}`;
  };

  const yearFormatter = () => {
    if (monthDiff <= 10) {
      return `${monthDiff} ${getMonthsText(monthDiff)}`;
    }
    const displayedYears = Math.round(monthDiff / 12);
    return `${displayedYears} ${getYearsText(displayedYears)}`;
  };

  const yearsAndMonthsFormatter = () => {
    const monthsModulo = monthDiff % 12;
    if (yearDiff === 0) {
      if (monthsModulo === 0) {
        return `1 ${getMonthsText(1)}`;
      }
      return `${monthsModulo} ${getMonthsText(monthsModulo)}`;
    }
    return `${yearDiff} ${getYearsText(yearDiff)} ${
      monthsModulo > 0
        ? `${andText[locale]} ${monthsModulo} ${getMonthsText(monthsModulo)}`
        : ''
    }`.trim();
  };

  const formatters = {
    [DurationVariableFormat.SMART]: () => smartFormatter(),
    [DurationVariableFormat.YEARS]: () => yearFormatter(),
    [DurationVariableFormat.YEARS_AND_MONTHS]: () => yearsAndMonthsFormatter(),
  };

  if (!formatters[format]) {
    return {
      calculatedValue: '',
      rawValue: 0,
    };
  }

  return {
    calculatedValue: formatters[format](),
    rawValue: monthDiff,
  };
};

export const getMergeTagsKLastCompaniesValue = (input: {
  locale: string;
  nbLastCompanies: number;
  experiences: any;
}): { calculatedValue: string; rawValue: unknown } => {
  const { locale, nbLastCompanies, experiences } = input;
  if (_.isEmpty(experiences)) {
    return {
      calculatedValue: '',
      rawValue: '',
    };
  }

  const andText: any = {
    fr: 'et',
    en: 'and',
  };

  const allCompanyNames = _.map(
    experiences,
    (experience) => experience.companyName,
  );

  const companyNames = _.uniq(
    _.compact(_.map(experiences, (experience) => experience.companyName)),
  ).slice(0, nbLastCompanies);

  // eslint-disable-next-line no-nested-ternary
  const result = _.isEmpty(companyNames)
    ? undefined
    : companyNames.length === 1
    ? companyNames[0]
    : `${companyNames.slice(0, -1).join(', ')} ${andText[locale]} ${
        companyNames[companyNames.length - 1]
      }`;

  return {
    calculatedValue: result,
    rawValue: `${allCompanyNames.slice(0, -1).join(', ')} ${andText[locale]} ${
      allCompanyNames[allCompanyNames.length - 1]
    }`,
  };
};
