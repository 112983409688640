/* eslint-disable import/prefer-default-export */
import ExpressionSingleVariableEvaluator from './singleVariableEvaluator';
import ExpressionPathEvaluator from './pathEvaluator';
import ExpressionConcatEvaluator from './concatEvaluator';
import { EvaluatorInterface } from '../../types';
import ExpressionSimpleEvaluator from './simpleEvaluator';

const expressionEvaluatorFromType: Record<string, EvaluatorInterface> = {
  variable: new ExpressionSingleVariableEvaluator(),
  path: new ExpressionPathEvaluator(),
  concat: new ExpressionConcatEvaluator(),
  placeholder: new ExpressionSimpleEvaluator(),
  fragment: new ExpressionSimpleEvaluator(),
  select: new ExpressionSimpleEvaluator(),
  'ai-token': new ExpressionSimpleEvaluator(),
};

export const getExpressionEvaluatorFromType = ({
  type,
}: {
  type: string;
}): EvaluatorInterface => expressionEvaluatorFromType[type];
