import { TFunction } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Input, Radio } from 'semantic-ui-react';
import { compose } from 'underscore';

import { OPERATORS_OPTION } from './operatorsOptions';
import { StringOperator } from './types';

import './CustomCriteriaFilters.css';

const CustomCriteriaString = (props: {
  selectedOperator: StringOperator | undefined;
  onSelectedOperatorChange: (operator: StringOperator) => void;
  value: string;
  onValueChange: (value: string) => void;
  onClose: () => void;
  t: TFunction;
}) => {
  const {
    selectedOperator,
    onSelectedOperatorChange,
    value,
    onValueChange,
    onClose,
    t,
  } = props;

  return (
    <div className='property-details-wrapper'>
      {OPERATORS_OPTION.text.map((opt) => (
        <div className='radio-wrapper' key={opt.operator}>
          <div
            className='radio-row'
            onClick={() => {
              onSelectedOperatorChange(opt.operator);
            }}
          >
            <Radio
              className='radio'
              checked={selectedOperator === opt.operator}
            />
            <div className='label'>{t(opt.translation)}</div>
          </div>
          {opt.withInput && selectedOperator === opt.operator && (
            <Input
              className='property-details-selector'
              value={value}
              onChange={(ev) => {
                onValueChange(ev.target.value);
              }}
            />
          )}
        </div>
      ))}
      <div className='save-button' onClick={onClose}>
        {t('reveal.searchView.search.save')}
      </div>
    </div>
  );
};

export default compose(withTranslation('translations'))(CustomCriteriaString);
