import React, { FC, useCallback, useMemo, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import _ from 'underscore';
import { useUpdateCompanyPipelineStage } from '@/graphql/hooks/searchPoolProfile/useUpdateCompanyPipelineStage';
import EnrichedRevealProfileModal from '@/revealComponents/EnrichedRevealProfileModal';
import useClientId from '@/hooks/router/useClientId';
import ProfileIdHandler from '@/common/ProfileIdHandler';
import { useHistory, useLocation } from 'react-router-dom';
import useClientCustomCompanyDealStates from '@/graphql/hooks/clients/useClientCustomCompanyDealStates';
import { useTranslation } from 'react-i18next';
import { getShortLanguage } from '@/common/utils/i18n';
import { getTranslatedText } from '@/common/helpers/translatableText';
import { Segmentation } from '@/components/PipelineSegmentation/pipelineSegmentation';
import { GenericSegmentationItem } from '../../MissionProfilesGenericView/GenericKanban/GenericKanbanColumn';
import GenericSegmentationView from '../../MissionProfilesGenericView/GenericSegmentationView/GenericSegmentationView';
import { DragContextType } from '../../MissionProfilesGenericView/GenericSegmentationView/GenericSegmentationViewContext';
import { SegmentDefinition } from '../../MissionProfilesGenericView/types';
import { SEGMENT_COLORS } from '../../MissionProfilesGenericView';
import KanbanProfileCard from '../../MissionProfilesGenericView/GenericKanban/GenericKanbanColumn/GenericKanbanCard/KanbanProfileCard';
import ProfileKanbanContainer from '../../WidgetPlayground/ProfileKanbanContainer';

const companyBaseSegmentation = {
  id: 'D2KD9DW4',
  translationKey: 'companies',
  type: 'free-segmentation',
  segments: [],
  showDisplayTypeSelector: true,
} as const;

const CompaniesOverview: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const profileIdFromURL = queryParams.get('item');
  const [segmentItemIds, setSegmentItemIds] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<GenericSegmentationItem>({
    id: profileIdFromURL || '',
  });
  const { customCompanyDealStates } = useClientCustomCompanyDealStates();
  const [updateCompanyStage] = useUpdateCompanyPipelineStage();

  const clientId = useClientId();
  const client = useApolloClient();

  const segmentation: Segmentation = useMemo(
    () => ({
      ...companyBaseSegmentation,
      segments: _.isEmpty(customCompanyDealStates)
        ? []
        : [
            {
              id: 'unknown',
              name: t('common.unknown'),
            },
            ..._.map(
              customCompanyDealStates,
              ({ customCompanyDealStateId, title }) => ({
                id: customCompanyDealStateId,
                name: getTranslatedText(lang, title),
              }),
              [],
            ),
          ],
    }),
    [customCompanyDealStates, lang, t],
  );

  const profileIdHandler = ProfileIdHandler({
    profileIds: segmentItemIds,
    selectedProfileId: selectedItem.id,
    onSelectProfileId: (itemId: string) => {
      setSelectedItem({ id: itemId });
      const params = new URLSearchParams(location.search);
      params.set('item', itemId);
      const urlSearch = params.toString();
      history.replace({
        pathname: 'overview',
        search: urlSearch,
      });
    },
  });

  const defaultQueryOptions = useMemo(
    () => ({
      variables: {
        searchPoolId: 'reveal',
        input: {},
      },
      fetchPolicy: 'cache-first',
    }),
    [],
  );

  const segmentDefinitionWithItems: SegmentDefinition[] = useMemo(() => {
    if (!segmentation) return [];
    return _.map(segmentation.segments, ({ id, name }) => ({
      segmentId: id,
      ...(name && { title: name }),
      type: 'companies-overview',
      color: SEGMENT_COLORS[id],
      ...(id === 'unknown' && {
        canDrop: false,
      }),
    }));
  }, [segmentation]);

  const onChangeSegment = async (context: DragContextType) => {
    if (context.prevSegment === context.newSegment) {
      return;
    }
    const { data } = await updateCompanyStage({
      variables: {
        searchPoolId: 'reveal',
        profileId: context.item.id,
        stageId: context.newSegment,
      },
    });
    updateCacheAfterMutation({
      newData: data,
      prevSegment: context.prevSegment,
      newSegment: context.newSegment,
    });
  };

  const renderItem = useCallback(
    (item: GenericSegmentationItem, itemLoading: boolean) => {
      return (
        <KanbanProfileCard
          profileId={item.id}
          itemLoading={itemLoading}
          pageName='overview'
          missionTitle=''
        />
      );
    },
    [],
  );

  const updateCacheAfterMutation = useCallback(
    ({
      newData,
      prevSegment,
      newSegment,
    }: {
      newData: any;
      prevSegment: string;
      newSegment: string;
    }) => {
      const profileSegment =
        newData?.searchPoolProfile?.updateCompanyPipelineStage?.profile
          ?.companyDealState?.customCompanyDealStateId;
      if (prevSegment === newSegment && profileSegment === prevSegment) {
        return;
      }
      const { id } =
        newData?.searchPoolProfile?.updateCompanyPipelineStage?.profile || {};
      client.cache.modify({
        id: client.cache.identify({
          __typename: 'SearchPool',
          id: 'reveal',
        }),
        fields: {
          segmentProfileResults: (segmentProfileResults) => {
            const oldDataPrevSegment = _.findWhere(segmentProfileResults, {
              segmentId: prevSegment,
            });
            const profileRef = client.cache.identify({
              __typename: 'MiniProfileWithSegmentation',
              id,
              categoryId: prevSegment,
            });
            const profileToMove =
              _.find(
                oldDataPrevSegment.profiles,
                (profile) => profile.__ref === profileRef,
              ) ||
              _.find(
                oldDataPrevSegment?.profiles || [],
                (profile) => profile.__ref.indexOf(id) > -1,
              );
            const newSegmentProfileResults = _.map(
              segmentProfileResults,
              (segment) => {
                if (segment.segmentId === prevSegment) {
                  return {
                    ...segment,
                    profiles: _.filter(
                      segment.profiles,
                      (profile) => profile.__ref !== profileToMove?.__ref,
                    ),
                    count: segment.count - 1 >= 0 ? segment.count - 1 : 0,
                  };
                }

                if (segment.segmentId === newSegment) {
                  return {
                    ...segment,
                    profiles: [profileToMove, ...segment.profiles],
                    count: segment.count + 1,
                  };
                }

                return segment;
              },
            );
            return newSegmentProfileResults;
          },
        },
      });
    },
    [client.cache],
  );

  return (
    <div style={{ padding: '24px' }}>
      <ProfileKanbanContainer queryOptions={defaultQueryOptions}>
        <GenericSegmentationView
          displayType='kanban'
          selectedItem={selectedItem}
          onItemSelected={({ item, segmentItems }) => {
            setSelectedItem(item);
            setSegmentItemIds(segmentItems);
          }}
          segmentItemIds={segmentItemIds}
          onSegmentItemIds={(value) => {
            setSegmentItemIds(value);
          }}
          onChangeSegment={onChangeSegment}
          segmentDefinitions={segmentDefinitionWithItems}
          disableDragAndDrop={false}
          renderItem={renderItem}
          dataLoading={false}
        />
      </ProfileKanbanContainer>
      <EnrichedRevealProfileModal
        clientId={clientId}
        searches={undefined}
        open={!_.isEmpty(selectedItem?.id)}
        onClose={() => {
          const params = new URLSearchParams(location.search);
          params.delete('item');
          const searchUrl = params.toString();
          history.replace({
            pathname: 'overview',
            search: searchUrl,
          });
          setSelectedItem({ id: '' });
        }}
        profileIdHandler={profileIdHandler}
        onChangeStage={updateCacheAfterMutation}
      />
    </div>
  );
};

export default CompaniesOverview;
