/* eslint-disable global-require */

import React from 'react';
import ReactDOM from 'react-dom';

import './i18n';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'semantic-ui-less/semantic.less';
import 'remixicon/fonts/remixicon.css';
import '@hiresweet/hiresweet-lib/style.css';
import './main.css';
import '@/less/tables.css';

import { unregister } from './registerServiceWorker';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    ReactDOM.render(<NextApp />, document.getElementById('root'));
  });
}

unregister();
