import {
  RequisitionProjects,
  Tasks,
  ProfileTimelinePreview,
  HiresweetProfileActivePlacement,
} from '@hiresweet/hiresweet-lib';
import React from 'react';

const PlaygroundTScreens = () => {
  return (
    <div style={{ padding: '30px', background: 'white' }}>
      <ProfileTimelinePreview />
      <Tasks />
      <br />
      <RequisitionProjects />
      <br />
      <HiresweetProfileActivePlacement />
      <br />
      {/* <ProfileTimelinePreview /> */}
    </div>
  );
};

export default PlaygroundTScreens;
