import React, { useState, useEffect } from 'react';
import _, { compose } from 'underscore';
import {
  getOfferSenderAssignedByRecruiter,
  userCanUseSender,
} from '../../Email/sendEmailHelpers';
import EmailEditor from '../../../../../Editor';
import { instantiateText } from '../../../../../../common/index';
import EditTemplate from '../../../../../Parameters/Templates/modals/editTemplate';
import withOfferProfiles from '../../../../../../hocs/offers/withOfferProfiles';
import MailButton from '../../Email/MailButton';
import SenderAndRecipient from './SenderAndRecipient';
import TriggerEditor from './TriggerEditor';

import './TriggerEditor.css';

const EmailAction = ({
  sequenceId,
  action,
  indexInActionsToCome,
  onDeleteAction,
  t,
  onUpdateAction,
  permissionsAndNudges,
  emailApi,
  clientId,
  offerId,
  profile,
  getNextProfileId,
  isSearchResultProfile,
  onSend,
  onChangeStep,
  onChangeProfile,
  stepId,
  searchText,
  handleMarkProfileAsInMove,
  handleUnmarkProfileAsInMove,
  getProfilesInMove,
  user,
  offer,
  templates,
}) => {
  const defaultSubject = profile?.contactData?.defaultSubject;
  const [messageState, setMessageState] = useState({
    ...action?.message,
    ...(!action?.message?.subject &&
      defaultSubject && { subject: defaultSubject }),
  });
  const [version, setVersion] = useState(0);
  const [editTemplateMode, setEditTemplateMode] = useState({
    isActive: false,
    templateToEditId: null,
  });

  const [recipient, setRecipient] = useState(profile?.email || '');
  const [cc, setCc] = useState(
    profile?.talentStrategist?.email ? [profile.talentStrategist.email] : [],
  );
  const [bcc, setBcc] = useState([]);

  const context = profile?.resumeData || {};

  useEffect(() => {
    // On templates change
    if (!_.isEmpty(templates)) {
      setMessageState((previousMessageState) => {
        return instantiateMessage({
          message: previousMessageState,
          context,
          templates,
          defaultSubject,
        });
      });
      setVersion((previousVersion) => previousVersion + 1);
    }
    // eslint-disable-next-line
  }, [templates, context]);

  useEffect(() => {
    // IMPORTANT: prevents cursor from switching to beginning of text,
    // after selecting a template from the dropdown and clicking inside the editor
    if (!action?.message?.templateId) {
      return;
    }
    // On templateId change (e.g, when adding a Discover profile to a job with default templates and profile CF follows job CF)
    setMessageState(
      instantiateMessage({
        message: action?.message,
        context,
        templates,
        defaultSubject,
      }),
    );
    setVersion((previousVersion) => previousVersion + 1);

    // eslint-disable-next-line
  }, [action?.message?.templateId, context]);

  const onChangeBody = ({ updatedBody }) => {
    const updatedMessage = {
      ..._.omit(messageState, 'templateId'),
      body: updatedBody,
    };
    const updatedAction = {
      ...action,
      message: updatedMessage,
    };
    setMessageState(updatedMessage);
    onUpdateAction({ updatedAction });
  };

  const onChangeSubject = ({ updatedSubject }) => {
    const updatedMessage = {
      ..._.omit(messageState, 'templateId'),
      subject: updatedSubject,
    };
    const updatedAction = {
      ...action,
      message: updatedMessage,
    };
    setMessageState(updatedMessage);
    onUpdateAction({ updatedAction });
  };

  const onSelectTemplate = ({ templateId }) => {
    const updatedMessage = {
      ..._.omit(messageState, 'body', 'subject'),
      templateId,
    };
    const updatedAction = {
      ...action,
      message: updatedMessage,
    };

    const template = _.findWhere(templates, { id: templateId });
    if (!template) {
      console.error(`Template not found : ${templateId}`);
      return;
    }

    const { subject: templateSubject, body: templateBody } = template || {};
    setMessageState({
      ...updatedMessage,
      body: instantiateText({ context, text: templateBody }),
      subject:
        instantiateText({ context, text: templateSubject }) || defaultSubject,
    });
    setVersion(version + 1);
    onUpdateAction({ updatedAction });
  };

  const { trigger } = action;
  const { body, subject, sender } = messageState;

  const profileAssignedSender = (profile.contactData || {}).assignedSender;
  const offerAssignedSender = getOfferSenderAssignedByRecruiter({ offer });
  // check assigned sender was not revoked for this user
  const canUseOfferSender = userCanUseSender({
    userEmail: user?.email,
    sender: offerAssignedSender,
  });

  const from =
    sender?.senderAddress || // TODO Check if this is correct
    (canUseOfferSender && offerAssignedSender?.senderAddress) ||
    emailApi.currentAddress ||
    t('profile.contact.drafts.you');

  const userSignature = user && user.signature ? user.signature : null;
  const senderSignature = offerAssignedSender?.signature || null;
  const signature = !_.isEmpty(offerAssignedSender)
    ? senderSignature
    : userSignature;
  // const userCanSend = emailApi.currentAddress && emailApi.hasOfflineGrant[emailApi.currentAddress] && !emailApi.connectionInProgress;

  // NOTE: this is different from 'assignedSender'
  // which is defined in a profile for autopilot
  // const userCanSendOnBehalfOf = !_.isEmpty(offerAssignedSender);
  const displaySendButton =
    trigger?.type === 'manual-trigger' && indexInActionsToCome === 0;
  // const canSend = (userCanSend || userCanSendOnBehalfOf) && displaySendButton;

  return (
    <div className='email-content'>
      {displaySendButton && (
        <div className='email-header'>
          <MailButton
            clientId={clientId}
            offerId={offerId}
            sequenceId={sequenceId}
            actionId={action?.actionId}
            offerAssignedSender={canUseOfferSender && offerAssignedSender}
            stepId={stepId}
            searchText={searchText}
            profileId={profile?.id}
            getNextProfileId={getNextProfileId}
            isSearchResultProfile={isSearchResultProfile}
            onSend={onSend}
            onChangeStep={onChangeStep}
            onChangeProfile={onChangeProfile}
            assignedSender={profileAssignedSender}
            mailData={{
              subject: messageState?.subject,
              body: messageState?.body,
              signature: signature || '',
              dest: recipient,
              bccAddresses: bcc,
              ccAddresses: cc,
              firstname: profile?.resumeData?.firstname,
            }}
            handleMarkProfileAsInMove={handleMarkProfileAsInMove}
            handleUnmarkProfileAsInMove={handleUnmarkProfileAsInMove}
            getProfilesInMove={getProfilesInMove}
            emailApi={emailApi}
          />
        </div>
      )}
      <div className='email-body'>
        <div className='email-info'>
          {action?.indexInSequence === 0 ? (
            <SenderAndRecipient
              from={from}
              profile={profile}
              recipient={recipient}
              setRecipient={setRecipient}
              bcc={bcc}
              setBcc={setBcc}
              cc={cc}
              setCc={setCc}
              t={t}
            />
          ) : (
            <TriggerEditor
              action={action}
              onUpdateAction={onUpdateAction}
              onDeleteAction={onDeleteAction}
              permissionsAndNudges={permissionsAndNudges}
              deletionIsAllowed={permissionsAndNudges?.deletion?.isAllowed}
              t={t}
            />
          )}
        </div>
        <div className='email-body-editor'>
          <EmailEditor
            waitingForProfile
            clientId={clientId}
            offerId={offerId}
            profileId={profile.id}
            defaultValue={body}
            defaultValueSubject={subject}
            onChange={(updatedBody) => onChangeBody({ updatedBody })}
            onChangeSubject={(updatedSubject) =>
              onChangeSubject({ updatedSubject })
            }
            mode='draft'
            // TODO: indexInSequence works as long as there are only send-email-actions in sequence
            fields={action.indexInSequence === 0 ? 'double' : 'simple'}
            placeholder={
              action.indexInSequence === 0
                ? t('editor.placeholder.emailBody')
                : t('editor.placeholder.followupBody')
            }
            placeholderSubject={t('editor.placeholder.emailSubject')}
            signature={signature}
            version={version}
            // messagePosition={messagePosition}
            onSelectTemplate={({ templateId }) =>
              onSelectTemplate({ templateId })
            }
            editTemplate={
              messageState?.templateId
                ? () =>
                  setEditTemplateMode({
                    isActive: true,
                    templateToEditId: messageState?.templateId,
                  })
                : null
            }
            alwaysShowMenu
          // onChangeTriggerSubjectFocus
          />
        </div>
      </div>

      <EditTemplate
        clientId={clientId}
        template={_.findWhere(templates, {
          id: editTemplateMode.templateToEditId,
        })}
        templateId={editTemplateMode.templateToEditId}
        open={editTemplateMode.isActive}
        onClose={() => setEditTemplateMode({ isActive: false })}
        submitCallback={null}
      />
    </div>
  );
};

const instantiateMessage = ({
  message,
  context,
  templates,
  defaultSubject,
}) => {
  if (!message?.templateId) {
    return message;
  }
  const currentTemplate = _.findWhere(templates, { id: message?.templateId });
  if (!currentTemplate) {
    return message;
  }
  const { subject: templateSubject, body: templateBody } = currentTemplate;
  return {
    ...message,
    body: instantiateText({ context, text: templateBody }),
    subject:
      instantiateText({ context, text: templateSubject }) || defaultSubject,
  };
};

export default compose(withOfferProfiles)(EmailAction);
