import React from 'react';

export default (WrappedComponent) => {
  return class withSignup extends React.Component {
    handleSignup = (token, { firstname, lastname, password, clientId }) =>
      fetch(`${process.env.REACT_APP_SERVER_URL}/auth/signup`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          firstname,
          lastname,
          token,
          password,
          clientId,
        }),
      })
        .then((res) => {
          if (!res.ok) throw new Error(res.statusText);
          return res.headers.get('Authorization');
        })
        .then((jwtoken) => localStorage.setItem('token', jwtoken));

    render() {
      return <WrappedComponent onSignup={this.handleSignup} {...this.props} />;
    }
  };
};
