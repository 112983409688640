import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import NotificationsSetFragment from '@/graphql/fragments/NotificationSet';
import { NotificationSet } from '@/types/notifications';

const NOTIFICATIONS_POLL_INTERVAL = 5 * 60 * 1000; // every 5 minutes

const GET_NOTIFICATIONS = gql`
  query getNotifications($clientId: ID!) {
    client(id: $clientId) {
      id
      ...NotificationsSet
    }
  }
  ${NotificationsSetFragment}
`;

interface GetNotificationsData {
  client: {
    id: string;
    notifications: NotificationSet;
  };
}

interface GetNotificationsVariables {
  clientId: string;
}

interface UseClientNotificationsResult
  extends QueryResult<GetNotificationsData, GetNotificationsVariables> {
  notifications: NotificationSet;
}

interface UseClientNotificationsInput extends GetNotificationsVariables {
  queryOptions?: QueryHookOptions<
    GetNotificationsData,
    GetNotificationsVariables
  >;
}

const useClientNotifications = ({
  clientId,
  queryOptions = {},
}: UseClientNotificationsInput): UseClientNotificationsResult => {
  const lastActionTimestamp = localStorage.getItem('lastActionTimestamp');
  const shouldPollInterval =
    !lastActionTimestamp ||
    Date.now() - (+lastActionTimestamp || 0) < 3 * 60 * 60 * 1000;
  const query = useQuery<GetNotificationsData, GetNotificationsVariables>(
    GET_NOTIFICATIONS,
    {
      ...queryOptions,
      ...(shouldPollInterval
        ? {
            pollInterval: NOTIFICATIONS_POLL_INTERVAL,
          }
        : {}),
      variables: {
        clientId,
      },
    },
  );

  const {
    notifications = { countNotSeen: 0, countSeen: 0, notSeen: [], seen: [] },
  } = query.data?.client || {};

  return { ...query, notifications };
};

export default useClientNotifications;
