import React, { FC } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import AvatarColumn from '@/components/ProfileRow/columns/AvatarColumn';
import NameAndHeadlineColumn from '@/components/ProfileRow/columns/NameAndHeadlineColumn';
import HeaderRow from '@/components/ProfileRow/HeaderRow/HeaderRow';
import usePagination from '@/hooks/ui/usePagination';
import ProfileRow from '../ProfileRow';

import styles from './StatsProfileTable.module.less';

interface StatsProfileTableProps {
  profileItems: { profileId: string }[];
}

const StatsProfileTable: FC<StatsProfileTableProps> = ({ profileItems }) => {
  const { t } = useTranslation();

  const { pageElements, numberOfPages, PageSelector } = usePagination(
    profileItems,
  );

  return (
    <div className={styles.table}>
      <HeaderRow
        columns={[
          { id: 'avatar', title: '', className: styles.avatar },
          {
            id: 'contact',
            title: t('reveal.missions.mission.tableHeader.contact'),
            className: styles.nameAndHeadline,
          },
        ]}
      />
      <div>
        {_.map(pageElements, ({ profileId }, index) => (
          <ProfileRow
            key={`${profileId}_${index}`}
            profileId={profileId}
            columns={[
              {
                id: 'avatar',
                component: AvatarColumn,
                className: styles.avatar,
              },
              {
                id: 'contact',
                component: NameAndHeadlineColumn,
                className: styles.nameAndHeadline,
              },
            ]}
          />
        ))}
      </div>
      {numberOfPages > 1 && (
        <div className={styles.pagination}>
          <PageSelector />
        </div>
      )}
    </div>
  );
};

export default StatsProfileTable;
