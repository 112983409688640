import React from 'react';
import RelevantFacts from './RelevantFacts.component';
import './StackoverflowExtract.css';

const StackOverflowExtract = React.memo(({ relevantFacts }) => {
  return (
    <div className='stackoverflow-extract'>
      <h4>
        <img alt='stackoverflow-logo' src='/images/logos/logo-stackoverflow.svg'/>
        Stack Overflow
      </h4>
      <RelevantFacts facts={relevantFacts} />
    </div>
  );
});

export default StackOverflowExtract;
