import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { ProfileWithAvatar } from '@/types/profile';

const GET_SEARCH_PROFILES_WITH_AVATAR = gql`
  query getSearchProfilesWithAvatar(
    $searchPoolId: ID!
    $searchResultItemsInput: [SearchResultItemInput]
  ) {
    searchPool(id: $searchPoolId) {
      id
      searchEnrichedProfiles(searchResultItems: $searchResultItemsInput) {
        id
        email
        isActive
        resumeData {
          firstname
          lastname
          photoLink
        }
      }
    }
  }
`;

interface GetSearchProfilesWithAvatarData {
  searchPool: {
    id: string;
    searchEnrichedProfiles: ProfileWithAvatar[];
  };
}

interface GetSearchProfilesWithAvatarVariables {
  searchPoolId: string;
  searchResultItemsInput?: {
    searchPoolProfileId: string;
  }[];
}

interface UseSearchProfilesWithAvatarResult
  extends QueryResult<
    GetSearchProfilesWithAvatarData,
    GetSearchProfilesWithAvatarVariables
  > {
  searchProfilesWithAvatar: ProfileWithAvatar[];
}

interface UseSearchProfilesWithAvatarInput
  extends GetSearchProfilesWithAvatarVariables {
  queryOptions?: QueryHookOptions<
    GetSearchProfilesWithAvatarData,
    GetSearchProfilesWithAvatarVariables
  >;
}

const useSearchProfilesWithAvatar = ({
  searchPoolId,
  searchResultItemsInput,
  queryOptions = {},
}: UseSearchProfilesWithAvatarInput): UseSearchProfilesWithAvatarResult => {
  const query = useQuery<
    GetSearchProfilesWithAvatarData,
    GetSearchProfilesWithAvatarVariables
  >(GET_SEARCH_PROFILES_WITH_AVATAR, {
    ...queryOptions,
    variables: { searchPoolId, searchResultItemsInput },
  });

  const searchProfilesWithAvatar =
    query.data?.searchPool.searchEnrichedProfiles || [];

  return { ...query, searchProfilesWithAvatar };
};

export default useSearchProfilesWithAvatar;
