import React from 'react';
import { compose } from 'underscore';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import logAction from '../common/logAction';
import withClientPermissions from '../hocs/clients/withClientPermissions';

import './MainFooter.css';

const MainFooter = ({ clientId, permissions }) => {
  const { t, i18n } = useTranslation();
  const { applicationMode } = useNavApplicationMode();
  const { resolvedLanguage } = i18n;
  const lng = resolvedLanguage.slice(0, 2);
  const termsLink =
    lng === 'fr'
      ? 'https://content.hiresweet.com/clients-hiresweet/hiresweet-marketplace-fr/hiresweet-marketplace-cgv'
      : 'https://content.hiresweet.com/clients-hiresweet/hiresweet-marketplace-en/hiresweet-marketplace-t-and-c';
  const privacyLink =
    lng === 'fr'
      ? 'https://content.hiresweet.com/clients-hiresweet/hiresweet-marketplace-fr/hiresweet-marketplace-politique-de-confidentialite'
      : 'https://content.hiresweet.com/clients-hiresweet/hiresweet-marketplace-en/hiresweet-marketplace-privacy';
  return (
    <div className='sweetapp-footer'>
      <div className='footer-content'>
        <div className='footer-extra-sections'>
          <div>
            {/* <span>{t('footer.featureRequests')}</span> */}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://bit.ly/3McTMbN'
              onClick={() => logAction({ type: 'click-footer-help-center' })}
            >
              {t('footer.helpCenter')}
            </a>

            <img
              className='footer-bullet'
              alt='square-bullet'
              src='/images/icons/figma/square-bullet.svg'
            />

            <Link
              to={`/client/${clientId}/plugin`}
              className='dropdown-item'
              onClick={() =>
                logAction({ type: 'click-footer-chrome-extension' })
              }
            >
              <img
                className='chrome-logo'
                alt='chrome-grey-logo'
                src='/images/logos/chrome-grey-logo.svg'
              />
              {t('footer.chromeExtension')}
            </Link>
          </div>
        </div>

        <div className='footer-mentions'>
          {t('footer.hiresweetCopyright')}
          {applicationMode === 'classic' && (
            <>
              <img
                style={{ marginLeft: '8px' }}
                className='footer-bullet'
                alt='square-bullet'
                src='/images/icons/figma/square-bullet.svg'
              />
              <a href={termsLink} target='_blank' rel='noopener noreferrer'>
                {t('footer.TC')}
              </a>
              <img
                className='footer-bullet'
                alt='square-bullet'
                src='/images/icons/figma/square-bullet.svg'
              />
              <a href={privacyLink} target='_blank' rel='noopener noreferrer'>
                {t('footer.privacy')}
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default compose(withClientPermissions)(MainFooter);
