import _ from 'underscore';

import { FollowupProfile } from '@/types/profile';
import { getMillisecondsFromDays } from '../utils/time';

export const isProfileActive = (profile?: FollowupProfile): boolean => {
  if (!profile) {
    return false;
  }
  const {
    processStep,
    lastStepUpdateTimestamp,
    firstContactDate,
    resumeData,
  } = profile;
  const { step } = resumeData;
  const currentTimestamp = Date.now();

  const lastMilestoneUpdateTimestamp =
    processStep?.milestone?.lastUpdateTimestamp;
  const lastGlobalUpdateTimestamp =
    lastMilestoneUpdateTimestamp && lastStepUpdateTimestamp
      ? Math.max(lastMilestoneUpdateTimestamp, lastStepUpdateTimestamp)
      : lastMilestoneUpdateTimestamp || lastStepUpdateTimestamp || 0;

  const timeSinceLastGlobalUpdate =
    currentTimestamp - lastGlobalUpdateTimestamp;

  const timeSinceSentDate =
    currentTimestamp - new Date(firstContactDate).getTime();

  return (
    profile?.isActive ||
    step === 'hired' ||
    (step === 'in-process' &&
      (processStep?.milestone?.id === 'milestone-4' ||
        timeSinceLastGlobalUpdate < getMillisecondsFromDays(20))) ||
    (step === 'contacted' && timeSinceSentDate < getMillisecondsFromDays(12))
  );
};

export const getFollowupCount = (
  offerId: string,
  profilesToFollowup: FollowupProfile[],
): number =>
  _.size(
    _.filter(
      profilesToFollowup,
      (profile) => profile.jobOfferId === offerId && isProfileActive(profile),
    ),
  );
