import classNames from 'classnames';
import React from 'react';
import _ from 'underscore';
import { MergeTagsSelectorOption } from '../utils';
import styles from '../MergeTagsSelector.module.less';

type MergeTagsSubMenuProps = {
  options: MergeTagsSelectorOption[];
  onSelectVariable: (variable: MergeTagsSelectorOption) => void;
  hasPermission: boolean;
};

const MergeTagsVariablesOptions: React.FC<MergeTagsSubMenuProps> = ({
  options,
  onSelectVariable,
  hasPermission,
}) => {
  const cleanOptions = _.compact(
    _.map(options, (option) => {
      if (option.needAdvancedTemplating && !hasPermission) {
        return null;
      }
      return option;
    }),
  );

  return (
    <>
      {cleanOptions.map((option) => (
        <span
          key={option.key}
          className={classNames(styles.variable)}
          aria-hidden='true'
          onClick={() => onSelectVariable(option)}
        >
          {option.label}
        </span>
      ))}
    </>
  );
};

export default MergeTagsVariablesOptions;
