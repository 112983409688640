import classNames from 'classnames';
import React, { PropsWithChildren, useCallback } from 'react';
import _ from 'underscore';
import styles from './GenericTabMenu.module.less';

interface GenericTabMenuItem {
  id: string;
  label: string;
  disabled?: boolean;
}

interface GenericTabMenuItemProps extends GenericTabMenuItem {
  active?: boolean;
  onClick: (menuId: string) => void;
}

interface GenericTabMenuProps {
  menus: GenericTabMenuItem[];
  onMenuSelected: (menuId: string) => void;
  activeMenuId?: string;
  className?: string;
}

export function GenericTabMenuItem({
  id,
  label,
  disabled,
  active,
  onClick,
}: GenericTabMenuItemProps) {
  const onActivate = useCallback(() => {
    if (disabled) {
      return;
    }

    onClick(id);
  }, [onClick, id, disabled]);

  return (
    <div
      className={classNames(styles.tab, {
        [styles.activeTab]: active,
      })}
      role='menuitem'
      onClick={onActivate}
      onKeyPress={onActivate}
      tabIndex={0}
    >
      <h4>{label}</h4>
    </div>
  );
}

export default function GenericTabMenu({
  menus,
  activeMenuId,
  onMenuSelected,
  children,
  className,
}: PropsWithChildren<GenericTabMenuProps>) {
  return (
    <div className={classNames(styles.genericTabMenu, className)}>
      {_.map(menus, (menu) => {
        return (
          <GenericTabMenuItem
            key={menu.id}
            active={activeMenuId === menu.id}
            onClick={onMenuSelected}
            {...menu}
          />
        );
      })}
      {children}
    </div>
  );
}
