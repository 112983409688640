import _, { Dictionary } from 'underscore';
import React, { useMemo, useState } from 'react';
import SelectItemDropdown from '@/components/Common/SelectItemDropdown';
import { SelectItemDropdownType } from '@/components/Common/SelectItemDropdown/SelectItemDropdown';
import {
  BasicItemType,
  ItemGroupType,
  TreeItemType,
} from '@/components/Common/SelectItemDropdown/SelectItemList';
import MissionDropdownEmptyState from '@/revealComponents/ProfileProjectsTab/NewProfileMissionsManagement/EmptyState/MissionDropdownEmptyState';
import useClientId from '@/hooks/router/useClientId';

export const generateDropdownItemsFromTree = (
  tree: TreeItemType,
  depth = 0,
) => {
  const res: BasicItemType[] = [];
  _.each(tree.items || [], (node) => {
    res.push({
      id: node.id,
      content: node.searchableText,
      depth,
      searchableText: node.searchableText,
    });
    const childNodes = generateDropdownItemsFromTree(node, depth + 1);
    _.each(childNodes, (childNode) => {
      res.push(childNode);
    });
  });
  return res;
};

export const generateSelectedItemsFromTopLevelSelection = (
  tree: TreeItemType,
  isSelectionForced = false,
  selectedTopLevelNodes: string[],
) => {
  const selectedNodeIds: string[] = [];
  // eslint-disable-next-line
  for (const node of tree.items || []) {
    const childResults = generateSelectedItemsFromTopLevelSelection(
      node,
      node.selected || isSelectionForced,
      selectedTopLevelNodes,
    );
    // eslint-disable-next-line
    for (const childResult of childResults) {
      selectedNodeIds.push(childResult);
    }
    if (
      node.selected ||
      isSelectionForced ||
      (_.includes(selectedTopLevelNodes, node.id) &&
        node.items?.length === childResults.length)
    ) {
      selectedNodeIds.push(node.id);
    }
  }
  return selectedNodeIds;
};

export const generateSelectedItemsFromTree = (
  tree: TreeItemType,
  isSelectionForced = false,
  leafs: Dictionary<string | boolean> = {},
  topLevel = false,
) => {
  const selectedNodeIds: string[] = [];
  const selectedItemIds: string[] = [];
  // eslint-disable-next-line
  for (const node of tree.items || []) {
    if (node.selected || isSelectionForced) {
      if (leafs?.[node.id] && !topLevel) {
        selectedItemIds.push(node.id);
      }
      selectedNodeIds.push(node.id);
    }
    const {
      selectedNodeIds: childResults,
      selectedItemIds: childLeafIds,
    } = generateSelectedItemsFromTree(
      node,
      node.selected || isSelectionForced,
      leafs,
      topLevel,
    );
    // eslint-disable-next-line
    for (const childResult of childResults) {
      if (!topLevel || !node.selected) {
        selectedNodeIds.push(childResult);
      }
    }
    // eslint-disable-next-line
    for (const childLeaf of childLeafIds) {
      if (leafs?.[childLeaf] && (!topLevel || !node.selected)) {
        selectedItemIds.push(childLeaf);
      }
    }
  }
  return { selectedNodeIds, selectedItemIds };
};

export const getTreeAfterNodeSelection = (
  tree: TreeItemType,
  selectNodeId: string,
  isSelectionForced = false,
) => {
  const newTree = { ...tree };

  const newChildren = _.map(newTree.items || [], (item) => {
    if (item.id === selectNodeId || isSelectionForced) {
      const { newTree: newItem } = getTreeAfterNodeSelection(
        item,
        selectNodeId,
        true,
      );

      return {
        ...newItem,
        selected: true,
      };
    }

    const { newTree: childTree } = getTreeAfterNodeSelection(
      item,
      selectNodeId,
      isSelectionForced || item.selected,
    );
    return childTree;
  });

  if (newChildren.length && _.every(newChildren, (item) => !!item.selected)) {
    newTree.selected = true;
  }

  newTree.items = newChildren;
  return { newTree };
};

export const getTreeAfterNodeUnselection = (
  tree: TreeItemType,
  unselectNodeId: string,
  isSelectionForced = false,
) => {
  const newTree = { ...tree };
  let shouldApplyToParent = false;
  const newItems = _.map(newTree.items || [], (item) => {
    let shouldApplyToChildren = isSelectionForced;
    if (item.id === unselectNodeId || isSelectionForced) {
      newTree.selected = false;
      shouldApplyToChildren = true;
      shouldApplyToParent = true;
      const { newTree: newItem } = getTreeAfterNodeUnselection(
        item,
        unselectNodeId,
        shouldApplyToChildren,
      );
      return {
        ...newItem,
        selected: false,
      };
    }
    const {
      newTree: childTree,
      shouldApplyToParent: applyToParent,
    } = getTreeAfterNodeUnselection(
      item,
      unselectNodeId,
      shouldApplyToChildren,
    );
    if (applyToParent) {
      newTree.selected = false;
      shouldApplyToParent = true;
    }
    return childTree;
  });
  newTree.items = newItems;
  return { newTree, shouldApplyToParent };
};

export interface TreeGroupType extends Omit<ItemGroupType, 'items'> {
  tree: TreeItemType;
}

interface TreeDropdownProps
  extends Omit<
    SelectItemDropdownType,
    'onItemSelected' | 'onSelectAll' | 'onUnselectAll'
  > {
  treeGroup: TreeGroupType;
  onSelectAll?: () => void;
  onUnselectAll?: () => void;
  onUpdateTree: (newTree: TreeItemType) => void;
  onGroupFiltered: (groupId: string, key: string | null) => void;
  onGroupSorted: (groupId: string, key: string | null) => void;
  onGroupGrouped: (groupId: string, key: string | null) => void;
  selectedNodeIds: string[];
}

export const TreeDropdown: React.FC<TreeDropdownProps> = ({
  treeGroup,
  onSelectAll,
  onUnselectAll,
  onUpdateTree,
  onGroupFiltered,
  onGroupSorted,
  onGroupGrouped,
  selectedNodeIds,
  ...rest
}) => {
  const clientId = useClientId();
  const [searchValue, setSearchValue] = useState('');

  const groupFromTreeGroup = useMemo(() => {
    const itemsFromTreeGroup = generateDropdownItemsFromTree(treeGroup.tree);
    return {
      ...treeGroup,
      items: itemsFromTreeGroup,
      filteredEmptyState: (
        <MissionDropdownEmptyState isGroup clientId={clientId} />
      ),
    };
  }, [treeGroup, clientId]);

  return (
    <SelectItemDropdown
      groups={[groupFromTreeGroup]}
      multiSelect
      selectedItemIds={selectedNodeIds || []}
      searchValue={searchValue}
      onSearchChanged={setSearchValue}
      onItemSelected={(item, value) => {
        const newTree = value
          ? getTreeAfterNodeSelection(treeGroup.tree, item.id).newTree
          : getTreeAfterNodeUnselection(treeGroup.tree, item.id).newTree;
        onUpdateTree(newTree);
      }}
      onSelectAll={() => {
        const { newTree } = getTreeAfterNodeSelection(
          treeGroup.tree,
          treeGroup.tree.id,
          true,
        );
        onUpdateTree(newTree);
        onSelectAll?.();
      }}
      onUnselectAll={() => {
        const { newTree } = getTreeAfterNodeUnselection(
          treeGroup.tree,
          treeGroup.tree.id,
          true,
        );
        onUpdateTree(newTree);
        onUnselectAll?.();
      }}
      onGroupFiltered={onGroupFiltered}
      onGroupSorted={onGroupSorted}
      onGroupGrouped={onGroupGrouped}
      style={rest?.style}
      {...rest}
    />
  );
};

export default TreeDropdown;
