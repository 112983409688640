import React from 'react';
import _ from 'underscore';

import { useTranslation } from 'react-i18next';
import { ATS_LOGOS_BY_TYPE } from '@/common/reveal';
import withRevealFiltersOptions from '../../../../../hocs/searchPool/withRevealFiltersOptions';
import AccordionFilter from '../components/AccordionFilter';
import FilterDropdown from '../components/FilterDropdown';
import ProfileInATSFilter from '../components/ProfileInATSFilter';
import ApplicationStatusesFilter from './ApplicationStatusesFilter';
import getGreenhouseOptions from './getGreenhouseOptions';
import { countAllFilters } from '../Filters/utils';
import {
  CreationDateFilter,
  LastActionDateFilter,
} from '../Filters/DateFilters';

import './GreenhouseFilters.css';

const GreenhouseFilters = ({
  onUpdate,
  filters,
  projectId,
  projectsWithFilterOptions,
}) => {
  const { t } = useTranslation();
  const project = _.findWhere(projectsWithFilterOptions, { id: projectId });
  const hasGreenhouseConnector = !!_.findWhere(project?.connectors, {
    type: 'greenhouse',
  });

  if (!hasGreenhouseConnector) {
    return null;
  }

  const handleUpdateRecruiters = ({ recruiterIds }) => {
    onUpdate({
      greenhouseFilters: {
        ...filters,
        recruiterIds: _.isEmpty(recruiterIds) ? null : recruiterIds,
      },
    });
  };

  const handleUpdateCoordinators = ({ coordinatorIds }) => {
    onUpdate({
      greenhouseFilters: {
        ...filters,
        coordinatorIds: _.isEmpty(coordinatorIds) ? null : coordinatorIds,
      },
    });
  };

  const handleUpdateJobs = ({ jobIds }) => {
    onUpdate({
      greenhouseFilters: {
        ...filters,
        jobIds: _.isEmpty(jobIds) ? null : jobIds,
      },
    });
  };

  const handleUpdateStages = ({ stageIds }) => {
    onUpdate({
      greenhouseFilters: {
        ...filters,
        stageIds: _.isEmpty(stageIds) ? null : stageIds,
      },
    });
  };

  const handleUpdateApplicationStatuses = ({ applicationStatuses }) => {
    onUpdate({
      greenhouseFilters: {
        ...filters,
        applicationStatuses: _.isEmpty(applicationStatuses)
          ? null
          : applicationStatuses,
      },
    });
  };

  const handleUpdateTags = ({ tagIds }) => {
    onUpdate({
      greenhouseFilters: {
        ...filters,
        tagIds: _.isEmpty(tagIds) ? null : tagIds,
      },
    });
  };

  const handleUpdateProfileInATS = ({ profileInATS }) => {
    onUpdate({
      greenhouseFilters: {
        ...filters,
        profileInATS,
      },
    });
  };

  const {
    userOptions,
    stageOptions,
    applicationStatusOptions,
    tagOptions,
    jobOptions,
  } = getGreenhouseOptions({
    projectId,
    projectsWithFilterOptions,
  });

  const atsLogo = ATS_LOGOS_BY_TYPE.greenhouse;

  return (
    <div className='greenhouse-filters'>
      <AccordionFilter
        title={
          <>
            <span className='filter-icon'>
              <img height='20px' alt='ats logo' src={atsLogo} />
            </span>
            Greenhouse
          </>
        }
        count={countAllFilters(filters)}
      >
        <div className='sub-title'>
          {t('reveal.searchView.filters.ats.contactExist')}
        </div>
        <ProfileInATSFilter
          value={filters?.profileInATS}
          onUpdate={handleUpdateProfileInATS}
        />
        <br />
        <br />

        <div className='sub-title'>
          {t('reveal.searchView.filters.ats.recruiters')}
        </div>
        <FilterDropdown
          options={userOptions}
          onChange={(e, { value }) =>
            handleUpdateRecruiters({ recruiterIds: value })
          }
          value={filters?.recruiterIds || []}
        />

        <br />
        <div className='sub-title'>
          {t('reveal.searchView.filters.ats.coordinator')}
        </div>

        <FilterDropdown
          options={userOptions}
          onChange={(e, { value }) =>
            handleUpdateCoordinators({ coordinatorIds: value })
          }
          value={filters?.coordinatorIds || []}
        />

        <br />
        <div className='sub-title'>
          {t('reveal.searchView.filters.ats.creationDate')}
        </div>

        <CreationDateFilter
          onUpdate={onUpdate}
          filters={filters}
          atsType='greenhouse'
        />

        <br />
        <div className='sub-title'>
          {t('reveal.searchView.filters.ats.lastActionDate')}
        </div>

        <LastActionDateFilter
          onUpdate={onUpdate}
          filters={filters}
          atsType='greenhouse'
        />

        <br />
        <div className='sub-title'>
          {t('reveal.searchView.filters.ats.jobs')}
        </div>

        <FilterDropdown
          options={jobOptions}
          onChange={(e, { value }) => handleUpdateJobs({ jobIds: value })}
          value={filters?.jobIds || []}
        />

        <br />
        <div className='sub-title'>
          {t('reveal.searchView.filters.ats.stages')}
        </div>

        <FilterDropdown
          options={stageOptions}
          onChange={(e, { value }) => handleUpdateStages({ stageIds: value })}
          value={filters?.stageIds || []}
        />

        <br />
        <div className='sub-title'>
          {t('reveal.searchView.filters.ats.applicationStatus')}
        </div>

        <ApplicationStatusesFilter
          applicationStatuses={filters?.applicationStatuses}
          onUpdate={handleUpdateApplicationStatuses}
          applicationStatusOptions={applicationStatusOptions}
        />

        <br />
        <div className='sub-title'>Tags</div>
        <FilterDropdown
          options={tagOptions}
          onChange={(e, { value }) => handleUpdateTags({ tagIds: value })}
          value={filters?.tagIds || []}
        />
      </AccordionFilter>
    </div>
  );
};

export default withRevealFiltersOptions(GreenhouseFilters);
