import React from 'react';
import { CompositeDecorator, Editor, EditorState } from 'draft-js';
import { convertFromHTML } from 'draft-convert';
import { findLinkEntities } from '../../containers/Editor/helpers';
import { fromHTMLOptions } from './helpers';
import LinkEntity from './LinkEntity';

import 'draft-js/dist/Draft.css';

const draftDecorators = [{
  strategy: findLinkEntities,
  component: LinkEntity,
}];

const RichReader = ({ initialHtml }) => {
  const decorator = new CompositeDecorator(draftDecorators);
  const initialContentState = initialHtml && convertFromHTML(fromHTMLOptions)(initialHtml);
  const getInitialEditorState = () => (
    initialContentState
      ? EditorState.createWithContent(initialContentState, decorator)
      : EditorState.createEmpty(decorator)
  );
  const styleMap = {
    BOLD: {
      fontWeight: 500,
    },
  };
  return (
    <div className='rich-reader'>
      <Editor
        editorState={getInitialEditorState()}
        customStyleMap={styleMap}
        readOnly
      />
    </div>
  );
};

export default RichReader;
