import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import InfoTooltip from '@/components/InfoTooltip';
import PipelineGraph from './PipelineGraph';
import { formatNumber, formatPercentWithSymbol } from '../../utils';
import './PipelineTable.css';

export const PipelineTableStub = () => (
  <div className='pipeline-table-placeholder' />
);

const PipelineTable = ({ pipelineData, tableData, isLoading, t }) => {
  if (isLoading) {
    return <PipelineTableStub />;
  }
  const displayOpenRate = _.find(
    tableData,
    ({ nbSentWithEmailOpenTracking }) =>
      nbSentWithEmailOpenTracking && nbSentWithEmailOpenTracking > 0,
  );

  return (
    <Table className='pipeline-table' fixed textAlign='center' basic='very'>
      <PipelineTableHeader t={t} displayOpenRate={displayOpenRate} />
      <PipelineTableBody
        pipelineData={pipelineData}
        tableData={tableData}
        nanValue={0}
        displayOpenRate={displayOpenRate}
      />
    </Table>
  );
};

const PipelineTableHeader = ({ t, displayOpenRate }) => {
  return (
    <Table.Header className='pipeline-table-header'>
      <Table.Row>
        <Table.HeaderCell className='pipeline-table-header-cell' width={1} />
        <Table.HeaderCell className='pipeline-table-header-cell' width={2}>
          {t('analytics.labels.nbSent')}
          <div className='pipeline-metric-label-tooltip'>
            <InfoTooltip text={t('analytics.tooltips.nbSent')} />
          </div>
        </Table.HeaderCell>
        {displayOpenRate && (
          <Table.HeaderCell className='pipeline-table-header-cell' width={2}>
            {t('analytics.labels.rateOpened')}
            <div className='pipeline-metric-label-tooltip'>
              <InfoTooltip text={t('analytics.tooltips.rateOpened')} />
            </div>
          </Table.HeaderCell>
        )}
        {/* <Table.HeaderCell className='pipeline-table-header-cell' width={2}>
          {t('analytics.labels.rateClicked')}
        </Table.HeaderCell> */}
        <Table.HeaderCell className='pipeline-table-header-cell' width={2}>
          {t('analytics.labels.rateAnswered')}
          <div className='pipeline-metric-label-tooltip'>
            <InfoTooltip text={t('analytics.tooltips.rateAnswered')} />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell className='pipeline-table-header-cell' width={2}>
          {t('analytics.labels.ratePositiveAnswered')}
          <div className='pipeline-metric-label-tooltip'>
            <InfoTooltip text={t('analytics.tooltips.ratePositiveAnswered')} />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell className='pipeline-table-header-cell' width={2}>
          {t('analytics.labels.rateInProcess')}
          <div className='pipeline-metric-label-tooltip'>
            <InfoTooltip text={t('analytics.tooltips.rateInProcess')} />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell className='pipeline-table-header-cell' width={2}>
          {t('analytics.labels.nbHired')}
          <div className='pipeline-metric-label-tooltip'>
            <InfoTooltip text={t('analytics.tooltips.nbHired')} />
          </div>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

const PipelineTableBody = ({
  pipelineData,
  tableData,
  nanValue,
  displayOpenRate,
}) => {
  const emailRowKeys = Object.keys(tableData).sort();
  return (
    <Table.Body>
      <PipelineGraph pipelineData={pipelineData} />
      {_.isEmpty(tableData) && (
        <PipelineTableRow key='empty-row' nanValue={nanValue} />
      )}
      {_.map(emailRowKeys, (rowKey) => (
        <PipelineTableRow
          key={rowKey}
          rowData={tableData[rowKey]}
          nanValue={nanValue}
          displayOpenRate={displayOpenRate}
        />
      ))}
    </Table.Body>
  );
};

const PipelineTableRow = ({ rowData, nanValue = 0, displayOpenRate }) => {
  const {
    emailType,
    // nbSent = 0,
    nbReceivedAtLeastNEmails = 0,
    rateOpened = 0,
    // rateClicked = 0,
    rateAnswered = 0,
    ratePositiveAnswered = 0,
    rateInProcess = 0,
    nbHired = 0,
  } = rowData || {};

  return (
    <Table.Row>
      <Table.Cell className='pipeline-table-email-cell'>
        {emailType || ''}
      </Table.Cell>
      <Table.Cell className='sent'>
        {formatNumber(nbReceivedAtLeastNEmails, nanValue)}
      </Table.Cell>
      {displayOpenRate && (
        <Table.Cell className='opened'>
          {formatPercentWithSymbol(rateOpened, nanValue)}
        </Table.Cell>
      )}
      {/* <Table.Cell>{formatPercentWithSymbol(rateClicked, nanValue)}</Table.Cell> */}
      <Table.Cell className='answered'>
        {formatPercentWithSymbol(rateAnswered, nanValue)}
      </Table.Cell>
      <Table.Cell className='positive-answered'>
        {formatPercentWithSymbol(ratePositiveAnswered, nanValue)}
      </Table.Cell>
      <Table.Cell className='process'>
        {formatPercentWithSymbol(rateInProcess, nanValue)}
      </Table.Cell>
      <Table.Cell className='hired'>
        {formatNumber(nbHired, nanValue)}
      </Table.Cell>
    </Table.Row>
  );
};

export default compose(withTranslation('translations'))(PipelineTable);
