import { getTranslatedText } from '@/common';
import GenericButton from '@/components/Common/GenericButton';
import Delete from '@/components/Reveal/Icons/Delete';
import Edit from '@/components/Reveal/Icons/Edit';
import { ClientPipeline } from '@/graphql/clients';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import _ from 'underscore';
import styles from './ClientPipelinesTable.module.less';
import { DepartmentWithType } from '../CreateAndEditPipelineModal';

interface ClientPipelinesTableProps {
  pipelines: ClientPipeline[];
  setSelectedPipelineId: (id: string) => void;
  setDeleteNewPipelineModalOpen: (value: boolean) => void;
  setUpdateNewPipelineModalOpen: (value: boolean) => void;
  departments: DepartmentWithType[];
}

const ClientPipelinesTable: React.FC<ClientPipelinesTableProps> = ({
  pipelines,
  setSelectedPipelineId,
  setDeleteNewPipelineModalOpen,
  setUpdateNewPipelineModalOpen,
  departments,
}) => {
  const { t } = useTranslation();

  if (_.isEmpty(pipelines)) {
    return <></>;
  }
  return (
    <Table basic className={styles.table}>
      <Table.Header className={styles.header}>
        <Table.Row>
          <Table.HeaderCell className={styles.th}>
            <span>{t('superPipelineSettings.pipelinesTable.name')}</span>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.th}>
            <span>{t('superPipelineSettings.pipelinesTable.author')}</span>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.th}>
            <span>{t('superPipelineSettings.pipelinesTable.scope')}</span>
          </Table.HeaderCell>
          <Table.HeaderCell collapsing />
          <Table.HeaderCell collapsing />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(pipelines, ({ id, title, scopeDescriptor, author }) => (
          <Table.Row key={id} className={styles.row}>
            <Table.Cell textAlign='left' className={styles.name}>
              <span>{getTranslatedText(title)}</span>
            </Table.Cell>
            <Table.Cell textAlign='left' className={styles.name}>
              <span>
                {`${author?.firstname || ''} ${author?.lastname || ''}`}
              </span>
            </Table.Cell>
            <Table.Cell textAlign='left' className={styles.name}>
              <span>
                {_.findWhere(departments, {
                  id:
                    scopeDescriptor?.departmentId ||
                    scopeDescriptor?.sectionId ||
                    scopeDescriptor?.subsectionId,
                })?.title || ''}
              </span>
            </Table.Cell>
            <Table.Cell textAlign='left' className={styles.name}>
              <GenericButton
                primacy='tertiary'
                onClick={() => {
                  setSelectedPipelineId(id);
                  setUpdateNewPipelineModalOpen(true);
                }}
              >
                <Edit />
              </GenericButton>
            </Table.Cell>
            <Table.Cell textAlign='left' className={styles.name}>
              <GenericButton
                primacy='tertiary'
                onClick={() => {
                  setSelectedPipelineId(id);
                  setDeleteNewPipelineModalOpen(true);
                }}
              >
                <Delete />
              </GenericButton>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default ClientPipelinesTable;
