import React, { FC } from 'react';

import styles from './Spinner.module.less';
import svgStyles from './svg.module.less';

const Spinner: FC = () => (
  <svg
    className={svgStyles.svgIcon}
    viewBox='0 0 110 110'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='8.5'
      y='8.5'
      width='93'
      height='93'
      fill='none'
      rx='46.5'
      stroke='#F1F6FF'
      strokeWidth='17'
    />
    <path
      d='M101.425 55C106.161 55 110.068 51.1381 109.333 46.4597C108.66 42.176 107.48 37.9771 105.813 33.9524C103.049 27.2795 98.9981 21.2163 93.8909 16.1091C88.7837 11.0019 82.7205 6.95063 76.0476 4.18663C72.0229 2.51955 67.824 1.34044 63.5403 0.66711C58.8619 -0.0682658 55 3.83914 55 8.57497C55 13.3108 58.887 17.0534 63.5018 18.1171C65.5409 18.5871 67.5422 19.2265 69.4846 20.0311C74.0768 21.9332 78.2493 24.7213 81.764 28.236C85.2787 31.7507 88.0668 35.9232 89.9689 40.5154C90.7735 42.4578 91.4128 44.4591 91.8829 46.4982C92.9466 51.113 96.6892 55 101.425 55Z'
      fill='#4864C9'
      className={styles.bar}
    />
  </svg>
);

export default Spinner;
