import React, { useEffect, useState } from 'react';
import { Accordion, Icon, Segment } from 'semantic-ui-react';

import './AccordionFilter.css';

const AccordionFilter = ({ title, count, children }) => {
  const [expanded, setExpand] = useState(false);

  useEffect(() => {
    // Used to trigger a resize event to fix the boundaries in sliders within accordions
    // See : https://github.com/n3r4zzurr0/range-slider-input/issues/8
    window.dispatchEvent(new Event('resize'));
  }, [expanded]);

  return (
    <Segment as={Accordion} className='accordion-filter'>
      <Accordion.Title
        className='accordion-filter-title'
        active={expanded}
        index={0}
        onClick={() => setExpand(!expanded)}
      >
        <span className='title'>{title}</span>
        {count > 0 && <div className='ui label'>{count}</div>}
        <div className='hs-spacer' />
        <Icon className='dropdown' />
      </Accordion.Title>

      <Accordion.Content className='accordion-filter-content' active={expanded}>
        {children}
      </Accordion.Content>
    </Segment>
  );
};

export default AccordionFilter;
