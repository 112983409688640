import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import { ATStype, SUCCESSFACTORS } from '@/common/reveal';

type Props = {
  atsId: ATStype;
  phoneNumbers: { value: string }[];
};

const SuccessFactorsPhoneNumbers: VFC<Props> = ({ atsId, phoneNumbers }) => {
  const { t } = useTranslation();
  if (atsId !== SUCCESSFACTORS || _.isEmpty(phoneNumbers)) {
    return null;
  }

  return (
    <>
      {_.map(phoneNumbers, ({ value }) => (
        <div className='row' key={value}>
          <div className='field-name'>
            <i className='ri-phone-fill ri-lg' />
            <div className='tag-content-label'>
              {t('profile.resume.ATSData.phoneNumber')}
            </div>
          </div>
          <div className='jobs-column'>
            <div
              className='tag'
              style={{ marginBottom: '0px', marginTop: '3px' }}
            >
              <div className='tag-content'>
                <div className='tag-content-text'>{value}</div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SuccessFactorsPhoneNumbers;
