import { useState, useCallback } from 'react';
import useInterval from './useInterval';
import useTimeout from './useTimeout';

const POLLING_DELAY_MS = 1000;

const checkIfPluginInstalled = () => {
  return !!document.getElementById('hiresweet-plugin-installed');
};

const checkIfPluginSynchronized = ({ clientId }) => {
  const pluginClientId = localStorage.getItem('pluginClientId');
  return pluginClientId === clientId;
};

const usePluginStatuses = (clientId) => {
  const [pluginDownloaded, setPluginDownloaded] = useState(
    checkIfPluginInstalled,
  );
  const [pluginSynchronized, setPluginSynchronized] = useState(() =>
    checkIfPluginSynchronized({ clientId }),
  );

  const updateStatuses = useCallback(() => {
    setPluginDownloaded(checkIfPluginInstalled());
    setPluginSynchronized(checkIfPluginSynchronized({ clientId }));
  }, [clientId]);

  useTimeout(updateStatuses, 500);

  useInterval(updateStatuses, POLLING_DELAY_MS);

  return { pluginDownloaded, pluginSynchronized };
};

export default usePluginStatuses;
