import React from 'react';
import { Icon } from 'semantic-ui-react';

import './GoToButton.css';

const GoToButton = ({ children, onClick, disabled }) => {
  return (
    <div className={`go-to-button-container${  disabled ? ' disabled' : ''}`} onClick={onClick}>
      <div className='go-to-button-title'>
        {children}
      </div>
      <button type='button' className='go-to-button-arrow'>
        <Icon
          className='go-to-button-icon'
          name='chevron right'
          size='small'
        />
      </button>
    </div>
  );
};

export default GoToButton;
