import React, { useState, useMemo, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Segment, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import * as Sentry from '@sentry/browser';

import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import { ShowMoreRow } from '@/components/Tables/ShowMore';
import useLocalShowMore from '@/hooks/ui/useLocalShowMore';
import GenericButton from '@/components/Common/GenericButton';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useClientId from '@/hooks/router/useClientId';
import useScheduleEmail from '@/graphql/hooks/searchPoolProfile/useScheduleEmail';
import withSendRevealEmail from '@/hocs/email/withSendRevealEmail';
import { textToId } from '@/common';
import EmailContext from '@/context/EmailContext';
import { isoWithoutTimezone } from '@/common/utils/time';
import GenericTooltip from '@/components/Common/GenericTooltip';
import GenericModal from '@/components/Common/GenericModal';
import {
  RevealSenderType,
  useRevealSenderStorage,
} from '@/revealComponents/ProfileContactFlow/Actions/EmailAction/useRevealEmailAction';

import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import {
  BULK_COMPLETE_TASKS_FULL_PROFILE,
  DELETE_TASKS,
} from '../../../../graphql/tasks';
import NewProfileRow from '../../ProfileRow';
import ProfileRowHeader from '../../ProfileRow/ProfileRowHeader';
import { useProfilesTableColumnsWithCustomFields } from '../../JobsView/JobView/ProjectProfilesTab/columns/useProfilesTableColumns';

import './TaskTable.css';

export const TASK_PROFILES_TABLE_ID = 'task-profiles-table-view';

const TaskTable = ({
  title,
  iconName,
  tasks,
  onFocusTask,
  getEmailSenderAndTracking,
}) => {
  const clientId = useClientId();
  const { t } = useTranslation();
  const notif = useNotificationSystem();
  const [tasksSelection, setTasksSelection] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteTasks] = useMutation(DELETE_TASKS);
  const initialLimit = 10;
  const limitStep = 10;
  const firstTask = tasks[0];

  const { profileCustomFields } = useClientProfileCustomFields(clientId);

  const configurationParams = useMergedConfigurationParams();

  const [bulkCompleteTasks] = useMutation(BULK_COMPLETE_TASKS_FULL_PROFILE, {
    refetchQueries: ['getSearchPoolJobProfileResults'],
  });

  const scheduleEmail = useScheduleEmail({});

  const {
    columnGroups,
    displayedColumns,
    displayColumn,
    updateColumnsDisplay,
  } = useProfilesTableColumnsWithCustomFields({
    tableId: TASK_PROFILES_TABLE_ID,
    defaultColumns: [
      { id: 'task-profiles-view-subtype', hidden: false, available: true },
      { id: 'task-profiles-view-email', hidden: true, available: true },
      {
        id: 'task-profiles-view-last-interaction',
        hidden: false,
        available: true,
      },
    ],
  });

  const tasksMap = useMemo(() => {
    const result = {};
    _.each(tasks, (task) => {
      result[task.id] = task;
    });
    return result;
  }, [tasks]);

  const selectedTasksAreAllEmail = useMemo(() => {
    let result = true;
    _.each(tasksSelection, (taskId) => {
      if (!result) {
        return;
      }
      const task = tasksMap[taskId];
      if (task?.type !== 'send-email-manual') {
        result = false;
      }
    });
    return result;
  }, [tasksMap, tasksSelection]);

  const onClickStartTasks = () => {
    if (!_.isEmpty(tasks)) {
      onFocusTask({
        taskId: firstTask?.id,
        taskType: firstTask?.type,
        taskSubtype: firstTask?.subtype,
      });
    }
  };

  const { getEmailAddressDetails, currentEmailType } = useContext(EmailContext);

  const sendBulkEmails = async () => {
    const tasksToComplete = _.map(tasksSelection, (taskId) => tasksMap[taskId]);
    const scheduleEmailPromises = _.map(tasksToComplete, async (task) => {
      const profileId = task.target?.id;
      const {
        senderInfo,
        trackingInfo: { trackingMessageId, trackOnEmailOpen, trackOnLinkClick },
      } = await getEmailSenderAndTracking({ profileId });
      const {
        firstname,
        lastname,
        signature: emailApiSignature,
      } = getEmailAddressDetails();
      const { senderEmailAddress, alias, targetSignature } = senderInfo;
      const sender = {
        id: textToId(senderEmailAddress),
        firstname,
        lastname,
        alias,
        signature: targetSignature || emailApiSignature || '',
        senderAddress: senderEmailAddress,
        mailAccount: {
          idToken: textToId(senderEmailAddress),
          type: currentEmailType,
        },
      };

      await scheduleEmail({
        variables: {
          input: {
            sender,
            actionId: task.id.slice('task-for-'.length),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            sendDate: isoWithoutTimezone(),
            profileId,
            trackingMessageId,
            trackOnEmailOpen,
            trackOnLinkClick,
          },
        },
      });
    });

    await Promise.all(scheduleEmailPromises);
  };

  const onClickCompleteTasks = async () => {
    const tasksToComplete = _.map(tasksSelection, (taskId) => tasksMap[taskId]);
    try {
      await bulkCompleteTasks({
        variables: {
          input: _.map(tasksToComplete, (singleTask) => ({
            projectId: singleTask.projectId,
            queueId: singleTask.queueId,
            sequenceId: singleTask.sequenceId,
            missionId: singleTask.missionId,
            task: {
              id: singleTask.id,
              type: singleTask.type,
              ...(singleTask.subtype && { subtype: singleTask.subtype }),
            },
            profileId: singleTask.target?.id,
          })),
        },
      });
      setTasksSelection([]);
      notif.success(
        t('reveal.candidatesView.timeline.explicitTasks.editSuccess'),
      );
    } catch (e) {
      notif.error(t('reveal.candidatesView.timeline.explicitTasks.editError'));
    }
  };

  const [displayableItems, showMoreRowProps] = useLocalShowMore({
    items: tasks,
    limitStep,
    defaultDisplayLimit: initialLimit,
  });

  const [storedRevealSender] = useRevealSenderStorage();

  const disableBulkEmailButton = useMemo(() => {
    if (currentEmailType) {
      return false;
    }
    if (storedRevealSender.type === RevealSenderType.Sobo) {
      return false;
    }
    return _.reduce(
      tasksSelection,
      (acc, taskId) => {
        if (acc) {
          return true;
        }
        const task = tasksMap[taskId];
        return !task?.message?.sender?.id;
      },
      false,
    );
  }, [currentEmailType, storedRevealSender, tasksSelection, tasksMap]);

  if (_.isEmpty(tasks)) {
    return null;
  }

  const tasksCount = (tasks || []).length;
  const selectionCount = (tasksSelection || []).length;

  const isHeaderChecked =
    tasksCount > 0 && tasksCount === tasksSelection?.length;
  const onToggleHeaderCheckbox = () => {
    setTasksSelection((selection) => {
      return _.isEmpty(selection) ? _.pluck(tasks, 'id') : [];
    });
  };

  const onToggleTaskCheckbox = ({ taskId }) => {
    setTasksSelection((selection) => {
      return _.contains(selection, taskId)
        ? _.without(selection, taskId)
        : _.union(selection, [taskId]);
    });
  };

  const onDeleteTasks = async () => {
    try {
      const selectedTasks = _.filter(tasks, ({ id }) =>
        _.contains(tasksSelection, id),
      );
      const tasksInput = _.map(
        selectedTasks,
        ({ id, sequenceId, missionId, type, target }) => {
          return { id, sequenceId, missionId, type, targetId: target?.id };
        },
      );
      const variables = { input: { tasks: tasksInput } };
      await deleteTasks({ variables });
      setTasksSelection([]);
      setDeleteModalOpen(false);
    } catch (e) {
      Sentry(e);
    }
  };

  return (
    <Segment className='task-table'>
      <TaskTableHeader
        title={title}
        iconName={iconName}
        tasksCount={tasksCount}
        selectionCount={selectionCount}
        onClickStartTasks={onClickStartTasks}
        onClickDeleteTasks={() => setDeleteModalOpen(true)}
        onClickCompleteTasks={onClickCompleteTasks}
        shouldHideStartTasksButton={
          configurationParams?.shouldHideTaskBulkActions === 'true'
        }
        showBulkEmailButton={selectionCount !== 0 && selectedTasksAreAllEmail}
        disableBulkEmailButton={disableBulkEmailButton}
        onSendBulkEmails={sendBulkEmails}
      />
      <ProfileRowHeader
        tableId='tasks-view'
        onToggleCheckbox={onToggleHeaderCheckbox}
        isChecked={isHeaderChecked}
        showMissions
        showLastInteraction={displayColumn(
          'task-profiles-view-last-interaction',
        )}
        showEmail={displayColumn('task-profiles-view-email')}
        shouldHideCheckbox={
          configurationParams?.shouldHideTaskBulkActions === 'true'
        }
        showSequence={configurationParams?.shouldHideSequenceColumn !== 'true'}
        showSubtype={
          displayColumn('task-profiles-view-subtype') &&
          configurationParams?.shouldDisplayTaskSubtypeInTasksList === 'true'
        }
        showAdStatus={['adequat-test1-demo', 'adequat-production'].includes(
          clientId,
        )}
        columnGroups={columnGroups}
        displayedColumns={displayedColumns}
        displayColumn={displayColumn}
        onUpdateColumnDisplay={updateColumnsDisplay}
        customFields={profileCustomFields}
        columnSorting={
          configurationParams?.shouldDisplayTaskViewColumnSorting === 'true'
        }
      />
      {_.map(displayableItems, (task) => {
        return (
          <NewProfileRow
            key={task?.id}
            tableId='tasks-view'
            clientId={clientId}
            searchPoolId='reveal'
            profileId={task?.target?.id}
            onClick={(event) =>
              onFocusTask({
                event,
                taskId: task?.id,
                taskType: task?.type,
                taskSubtype: task?.subtype,
              })
            }
            isSelected={_.contains(tasksSelection, task?.id)}
            onToggleCheckbox={() => onToggleTaskCheckbox({ taskId: task?.id })}
            onRangeSelect={() => {}}
            showMissions
            showActiveSequence={
              configurationParams?.shouldHideSequenceColumn !== 'true'
            }
            showSequence={
              configurationParams?.shouldHideSequenceColumn !== 'true'
            }
            showEmail={displayColumn('task-profiles-view-email')}
            showLastInteraction={displayColumn(
              'task-profiles-view-last-interaction',
            )}
            shouldHideCheckbox={
              configurationParams?.shouldHideTaskBulkActions === 'true'
            }
            customFields={profileCustomFields}
            showSubtype={
              displayColumn('task-profiles-view-subtype') &&
              configurationParams?.shouldDisplayTaskSubtypeInTasksList ===
                'true'
            }
            showAdStatus={['adequat-test1-demo', 'adequat-production'].includes(
              clientId,
            )}
            subtype={task?.subtype}
            lateDateDayDiff={task.dateDiffInDays}
            displayColumn={displayColumn}
          />
        );
      })}

      <ShowMoreRow {...showMoreRowProps} />

      {deleteModalOpen && (
        <ConfirmationModal
          size='tiny'
          open
          header={t('reveal.tasks.deleteConfirmationModal.title', {
            count: selectionCount,
          })}
          content={t('reveal.tasks.deleteConfirmationModal.warning')}
          submit='Delete'
          cancel='Cancel'
          onSubmit={onDeleteTasks}
          onCancel={() => setDeleteModalOpen(false)}
          validationType='negative'
        />
      )}
    </Segment>
  );
};

const TaskTableHeader = ({
  title,
  iconName,
  tasksCount,
  selectionCount,
  onClickStartTasks,
  onClickDeleteTasks,
  onClickCompleteTasks,
  shouldHideStartTasksButton,
  showBulkEmailButton,
  disableBulkEmailButton,
  onSendBulkEmails,
}) => {
  const { t } = useTranslation();
  const [displayModal, setDisplayModal] = useState(false);
  return (
    <div className='task-table-header'>
      <h3 className='header-title'>
        {iconName && (
          <img src={`/images/icons/tasks/${iconName}.svg`} alt='task icon' />
        )}
        {title}
      </h3>

      <div className='header-buttons'>
        {!shouldHideStartTasksButton && selectionCount > 0 && (
          <>
            <GenericButton
              primacy='secondary'
              color='red'
              onClick={onClickDeleteTasks}
            >
              {t('reveal.tasks.deleteTasks', { count: selectionCount })}
            </GenericButton>
            {iconName !== 'email-task' && (
              <GenericButton primacy='secondary' onClick={onClickCompleteTasks}>
                {t('reveal.tasks.completeTasks', { count: selectionCount })}
              </GenericButton>
            )}
            {showBulkEmailButton &&
              (!disableBulkEmailButton ? (
                <GenericButton
                  primacy='secondary'
                  onClick={() => setDisplayModal(true)}
                >
                  {t('reveal.tasks.sendEmails', { count: selectionCount })}
                </GenericButton>
              ) : (
                <GenericTooltip
                  trigger={
                    <span>
                      <GenericButton primacy='secondary' disabled>
                        {t('reveal.tasks.sendEmails', {
                          count: selectionCount,
                        })}
                      </GenericButton>
                    </span>
                  }
                  content={t('reveal.tasks.disconnectedEmail')}
                  position='bottom'
                />
              ))}
          </>
        )}
        {!shouldHideStartTasksButton && (
          <GenericButton onClick={onClickStartTasks}>
            {t('reveal.tasks.startTasks', { count: tasksCount })}
          </GenericButton>
        )}
      </div>
      <GenericModal open={displayModal} onClose={() => setDisplayModal(false)}>
        <Modal.Header>
          {t('reveal.tasks.sendEmailsModal.title', { count: selectionCount })}
        </Modal.Header>
        <Modal.Content>
          {t('reveal.tasks.sendEmailsModal.paragraph', {
            count: selectionCount,
          })}
        </Modal.Content>
        <Modal.Actions className='bulk-emails-modal-actions'>
          <GenericButton
            primacy='secondary'
            size='big'
            onClick={() => setDisplayModal(false)}
          >
            {t('common.cancel')}
          </GenericButton>
          <GenericButton
            size='big'
            onClick={() => {
              setDisplayModal(false);
              onSendBulkEmails();
            }}
          >
            {t('reveal.tasks.sendEmailsModal.submit')}
          </GenericButton>
        </Modal.Actions>
      </GenericModal>
    </div>
  );
};

export default withSendRevealEmail(TaskTable);
