import gql from 'graphql-tag';
import Project from './fragments/Project';

export const GET_CLIENT_PROJECTS = gql`
  query getClientProjects($clientId: ID!, $filter: ClientProjectsFilter) {
    client(id: $clientId) {
      id
      projects(filter: $filter) {
        ...Project
      }
    }
  }
  ${Project}
`;

export const CREATE_AUTOMATED_PROJECT = gql`
  mutation createAutomatedProject($input: CreateAutomatedProjectInput!) {
    project {
      createAutomatedProject(input: $input) {
        project {
          id
        }
      }
    }
  }
`;

export const ARCHIVE_PROJECT = gql`
  mutation archiveProject($input: ArchiveProjectInput!) {
    project {
      archive(input: $input) {
        project {
          ...Project
        }
      }
    }
  }
  ${Project}
`;

export const EDIT_PROJECT = gql`
  mutation editProject($input: EditProjectInput!) {
    project {
      edit(input: $input) {
        project {
          ...Project
        }
      }
    }
  }
  ${Project}
`;

export const CREATE_MANUAL_TASKS = gql`
  mutation createManualTasks($input: CreateManualTasksInput!) {
    project {
      createManualTasks(input: $input) {
        tasks {
          id
        }
      }
    }
  }
`;

export const CREATE_LINKEDIN_TASKS = gql`
  mutation createLinkedinTasks($input: CreateLinkedinTasksInput!) {
    project {
      createLinkedinTasks(input: $input) {
        tasks {
          id
        }
      }
    }
  }
`;
