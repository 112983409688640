import gql from 'graphql-tag';

export default gql`
    fragment Sender on Sender {
        id
        clientId
        owner {
            email
            firstname
            lastname
        }
        users {
            email
            firstname
            lastname
        }
        firstname
        lastname
        signature
        alias
        senderAddress
        mailAccount {
            type
            address
        }
    }
`;
