import _, { compose } from 'underscore';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';

import sanitizeTimeLineItem from '@/common/sanitizeTimeLineItem';
import Thumbnail from '@/components/Thumbnail';
import { withUpdateSearchPoolProfileComment } from '@/graphql/searchPoolProfile';
import withUpdateProfileComment from '@/hocs/profiles/withUpdateProfileComment';
import { ATS_LOGOS_BY_TYPE } from '@/common/reveal';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import usePinAndUnpinSharedActivity from '@/graphql/hooks/searchPoolProfile/usePinAndUnpinSharedActivity';
import EditSharedNote from '@/routes/RevealView/SearchView/CandidatesListPane/CandidateView/SharedNotes/SharedNoteForm/EditSharedNote';

import useRemoveSharedActivity from '@/graphql/hooks/searchPoolProfile/useRemoveSharedActivity';
import TimelineItemActions from '../TimelineItemActions';
import TASK_ICONS from '../TaskIcons';
import EditComment from './EditComment.component';
import IntegrationsSynchronizationStatus from '../IntegrationsSynchronizationStatus';
import AuthorAndDate from '../AuthorAndDate';
import TargetsSummary from '../TargetsSummary';

import '../commonStyles/sanitize.css';

const CommentBody = React.memo(({ content }) => {
  return (
    <div
      className='body'
      dangerouslySetInnerHTML={{ __html: sanitizeTimeLineItem(content) }}
    />
  );
});

const ClassicCommentTimelineItem = ({
  profile,
  timelineItem,
  updateProfileComment,
  updateSearchPoolProfileComment,
  clientId,
  author,
  searchPoolId,
}) => {
  const { t } = useTranslation();
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();
  const [editMode, setEditMode] = useState(false);
  const updateComment = (newValue) => {
    if (!profile || !profile.id) {
      return;
    }

    if (!timelineItem.date) {
      return;
    }

    if (profile?.__typename === 'Profile' && updateProfileComment) {
      updateProfileComment({
        id: profile.id,
        date: `${timelineItem.date}`,
        comment: newValue,
      }).then(() => {
        setEditMode(false);
      });

      return;
    }

    if (
      profile?.__typename === 'RevealProfile' &&
      updateSearchPoolProfileComment
    ) {
      updateSearchPoolProfileComment({
        searchPoolId,
        id: profile.id,
        date: `${timelineItem.date}`,
        comment: newValue,
      }).then(() => {
        setEditMode(false);
        publishSubscriptionEvent('onProfileTimelineUpdate', { id: profile.id });
      });
    }
  };

  const removeComment = () => updateComment('');

  const startEditMode = () => {
    setEditMode(true);
  };

  const cardBody =
    timelineItem.action && timelineItem.action.comment
      ? timelineItem.action.comment.text
      : '';

  return (
    <div className='timeline-card-mail-wrapper classic-item'>
      {/* style={{ bottom: -40 }} */}
      <div className='content'>
        <div className='header'>
          <Thumbnail
            link={author.photoLink}
            firstname={author.firstname}
            lastname={author.lastname}
          />

          <div className='author'>
            <span>
              {author.firstname} {author.lastname}
            </span>
            <div className='date'>
              {+timelineItem.date
                ? t('common.formattedDate', {
                    date: new Date(+timelineItem.date),
                  })
                : t('common.defaultDuration')}
            </div>
          </div>

          <div className='buttons-container'>
            <button
              className='modify-button'
              type='button'
              onClick={() => startEditMode()}
            >
              {t('profile.contact.timeline.edit')}
            </button>
            <button
              className='trash-button'
              onClick={removeComment}
              type='button'
            >
              <img className='trash-svg' src='/images/icons/trash.svg' alt='' />
            </button>
          </div>
        </div>
        <div className={`card-content comment${editMode ? ' edit-mode' : ''}`}>
          {editMode ? (
            <EditComment
              clientId={clientId}
              profile={profile}
              initialValue={cardBody}
              onSubmit={updateComment}
              t={t}
            />
          ) : (
            <CommentBody content={cardBody} />
          )}
        </div>
      </div>
    </div>
  );
};

const RevealCommentTimelineItem = ({
  profile,
  timelineItem,
  updateProfileComment,
  updateSearchPoolProfileComment,
  clientId,
  searchPoolId,
}) => {
  const { t } = useTranslation();
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();
  const [editMode, setEditMode] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [removeSharedActivity] = useRemoveSharedActivity();

  const {
    pinSharedActivity,
    unpinSharedActivity,
  } = usePinAndUnpinSharedActivity();
  const sharedActivityId = timelineItem.id;

  const updateComment = (newValue) => {
    if (!profile || !profile.id) {
      console.error('profile error');
      return;
    }

    if (!timelineItem.date) {
      console.error('date error');
      return;
    }

    if (profile?.__typename === 'Profile' && updateProfileComment) {
      updateProfileComment({
        id: profile.id,
        date: `${timelineItem.date}`,
        comment: newValue,
      }).then(() => {
        setEditMode(false);
      });

      return;
    }

    if (
      profile?.__typename === 'RevealProfile' &&
      updateSearchPoolProfileComment
    ) {
      updateSearchPoolProfileComment({
        searchPoolId,
        id: profile.id,
        date: `${timelineItem.date}`,
        comment: newValue,
      }).then(() => {
        setEditMode(false);
        publishSubscriptionEvent('onProfileTimelineUpdate', { id: profile.id });
      });
    }
  };

  const removeComment = () => {
    setWarningModalOpen(true);
  };

  const confirmDeletion = () => {
    if (!_.isEmpty(timelineItem.targets)) {
      removeSharedActivity({
        variables: {
          searchPoolId: 'reveal',
          sharedActivityId: timelineItem.id,
        },
      }).then(() => {
        setWarningModalOpen(false);
        publishSubscriptionEvent('onProfileTimelineUpdate', { id: profile.id });
      });
    } else {
      updateComment('');
    }
  };

  const startEditMode = () => {
    setEditMode(true);
  };

  const { action } = timelineItem || {};
  const cardBody = action?.comment
    ? action.comment.text.replaceAll('\n', '<br/>')
    : '';

  const atsType = timelineItem?.ats;
  const atsLogo = ATS_LOGOS_BY_TYPE[atsType];

  const filteredTargets = _.filter(
    timelineItem?.targets || [],
    ({ targetId }) => targetId !== profile.id,
  );

  return (
    <div className='timeline-card-mail-wrapper'>
      {/* style={{ bottom: -40 }} */}
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{ backgroundColor: TASK_ICONS.comment.color }}
              className='icon'
            >
              <i className={`${TASK_ICONS.comment.icon} ri-2x`} />
            </div>
          </div>

          <div className='author'>
            <div>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('profile.contact.timeline.comment.description'),
                }}
              />
              {timelineItem.isPinned && (
                <i className='ri-pushpin-line' style={{ marginLeft: 4 }} />
              )}
            </div>
            {!_.isEmpty(filteredTargets) && (
              <TargetsSummary targets={filteredTargets} />
            )}
            <AuthorAndDate timelineItem={timelineItem} atsLogo={atsLogo}>
              <IntegrationsSynchronizationStatus
                timelineItem={timelineItem}
                clientId={clientId}
                profile={profile}
              />
            </AuthorAndDate>
          </div>
          {!timelineItem?.ats && (
            <div className='timeline-item-actions'>
              <TimelineItemActions
                onEdit={startEditMode}
                onRemove={removeComment}
                onPin={
                  sharedActivityId
                    ? () => {
                        pinSharedActivity({
                          variables: {
                            input: {
                              profileId: profile.id,
                              sharedActivityId,
                            },
                          },
                        });
                      }
                    : undefined
                }
                onUnpin={
                  sharedActivityId
                    ? () => {
                        unpinSharedActivity({
                          variables: {
                            input: {
                              profileId: profile.id,
                              sharedActivityId: timelineItem.id,
                            },
                          },
                        });
                      }
                    : undefined
                }
                profile={profile}
                clientId={clientId}
                timelineItem={timelineItem}
              />
            </div>
          )}
        </div>
        <div className={`card-content comment${editMode ? ' edit-mode' : ''}`}>
          {editMode ? (
            <EditComment
              clientId={clientId}
              profile={profile}
              initialValue={cardBody}
              onSubmit={updateComment}
              t={t}
            />
          ) : (
            <CommentBody content={cardBody} />
          )}
        </div>
      </div>
      <GenericModal size='tiny' open={warningModalOpen}>
        <Modal.Header>
          {t('profile.contact.timeline.comment.warningModalTitle')}
        </Modal.Header>
        <Modal.Content>
          {t('profile.contact.timeline.comment.warningModalContent')}
        </Modal.Content>
        <Modal.Actions>
          <GenericButton
            size='big'
            primacy='secondary'
            onClick={() => setWarningModalOpen(false)}
          >
            {t('common.cancel')}
          </GenericButton>
          <GenericButton size='big' primacy='primary' onClick={confirmDeletion}>
            {t('common.delete')}
          </GenericButton>
        </Modal.Actions>
      </GenericModal>
    </div>
  );
};

const CommentTimelineItem = ({
  profile,
  timelineItem,
  updateProfileComment,
  updateSearchPoolProfileComment,
  clientId,
  searchPoolId,
  applicationMode,
}) => {
  const author = timelineItem.author ? timelineItem.author : {};

  if (applicationMode === 'classic') {
    return (
      <ClassicCommentTimelineItem
        profile={profile}
        timelineItem={timelineItem}
        author={author}
        updateProfileComment={updateProfileComment}
        updateSearchPoolProfileComment={updateSearchPoolProfileComment}
        searchPoolId={searchPoolId}
        clientId={clientId}
      />
    );
  }
  return (
    <RevealCommentTimelineItem
      profile={profile}
      timelineItem={timelineItem}
      updateProfileComment={updateProfileComment}
      updateSearchPoolProfileComment={updateSearchPoolProfileComment}
      searchPoolId={searchPoolId}
      clientId={clientId}
    />
  );
};

export default compose(
  withUpdateProfileComment,
  withUpdateSearchPoolProfileComment,
)(CommentTimelineItem);
