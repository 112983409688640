import gql from 'graphql-tag';
import TranslatableText from './TranslatableText';

export default gql`
  fragment PricingPlanConfiguration on PricingPlanConfiguration {
    corePlanConfiguration {
      availablePlans {
        ...CorePlanOption
      }
      currentPlan {
        ...CorePlanOption
      }
    }
    applicantTrackingPlanConfiguration {
      availablePlans {
        ...ApplicantTrackingPlanOption
      }
      currentPlan {
        ...ApplicantTrackingPlanOption
      }
    }
    sourcingPlanConfiguration {
      availablePlans {
        ...SourcingPlanOption
      }
      currentPlan {
        ...SourcingPlanOption
      }
    }
  }
  
  fragment CorePlanOption on CorePlanOption {
    type
    title {
      ...TranslatableText
    }
    minNbSeats
    perSeatFee {
      value
      currency
    }
    minMonthlyFee {
      value
      currency
    }
    description {
      paragraphs {
        type
        ...on PlanTextParagraph {
          text {
            ...TranslatableText
          }
        }
        ...on PlanFeaturesGroupParagraph {
          title {
            ...TranslatableText
          }
          features {
            shortDescription {
              ...TranslatableText
            }
          }
        }
      }   
    }
  }

  fragment ApplicantTrackingPlanOption on ApplicantTrackingPlanOption {
    type
    title {
      ...TranslatableText
    }
    minNbSeats
    perSeatFee {
      value
      currency
    }
    minMonthlyFee {
      value
      currency
    }
    description {
      paragraphs {
        type
        ...on PlanTextParagraph {
          text {
            ...TranslatableText
          }
        }
        ...on PlanFeaturesGroupParagraph {
          title {
            ...TranslatableText
          }
          features {
            shortDescription {
              ...TranslatableText
            }
          }
        }
      }   
    }
  }
  
  fragment SourcingPlanOption on SourcingPlanOption {
    type
    title {
      ...TranslatableText
    }
  }

  ${TranslatableText}
`;
