import React, { useCallback, useState, useMemo, useContext } from 'react';
import _ from 'underscore';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';

import { Popup, Divider } from 'semantic-ui-react';
import SequencePreview from '@/revealComponents/SequencePreview';
import ProfileRowSequence from '@/routes/RevealView/ProfileRow/ProfileRowSequence';

import { Sequence } from '@/types/sequence';
import useMinimizedView from '@/hooks/ui/useMinimizedView';

import GenericPopup from '@/components/Common/GenericPopup';
import DropdownControlsContext from '@/context/DropdownControlsContext';
import styles from './DropdownSequenceList.module.less';

export type SortFilterOption = {
  key: string;
  label: string;
};

interface SequenceWithGroup extends Sequence {
  groupName?: string;
  sortByOptions?: SortFilterOption[];
  filterByOptions?: SortFilterOption[];
}

type OnSelectEvent = (key: string | null) => void;

interface SortByFilterByProps {
  onSortBy: OnSelectEvent;
  onFilterBy: OnSelectEvent;
  onGroupBy: OnSelectEvent;
  sortBy: string | null;
  filterBy: string | null;
  groupBy: string | null;
}

interface DropdownSequenceListProps extends SortByFilterByProps {
  sequenceList: SequenceWithGroup[];
  isPlugin: boolean;
  clientId: string;
  selectedSequenceId: string;
  onSequenceSelected: (sequence: Sequence) => void;
  shiftSidebar: boolean;
}

interface DropdownGroupTitleProps extends SortByFilterByProps {
  groupName: string;
  sortByOptions: SortFilterOption[];
  filterByOptions: SortFilterOption[];
  groupByOptions?: SortFilterOption[];
  coloredGroupBackground?: boolean;
}

interface DropdownFilterSortPopupProps extends SortByFilterByProps {
  sortByOptions: SortFilterOption[];
  filterByOptions: SortFilterOption[];
  groupByOptions?: SortFilterOption[];
  onSortBy: OnSelectEvent;
  onFilterBy: OnSelectEvent;
  onGroupBy: OnSelectEvent;
}

interface DropdownOptionListProps {
  title: string;
  options: SortFilterOption[];
  onSelect: OnSelectEvent;
  selectedItemKey: string | null;
}

const SelectedOptionIcon = ({
  selectedItemKey,
  option,
}: {
  selectedItemKey?: string | null;
  option: string;
}) => {
  if (!selectedItemKey || selectedItemKey !== option) {
    return null;
  }
  return <i className={classNames('ri-check-fill', styles.activeOption)} />;
};

const DropdownOptionList: React.FC<DropdownOptionListProps> = ({
  title,
  options,
  selectedItemKey,
  onSelect,
}) => {
  return (
    <div className={styles.dropdownOptionList}>
      <span>{title}</span>
      <ul>
        {_.map(options, ({ key, label }) => (
          <li
            key={key}
            className={classNames({
              [styles.active]: selectedItemKey === key,
            })}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onSelect(key === selectedItemKey ? null : key);
            }}
            onKeyPress={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onSelect(key === selectedItemKey ? null : key);
            }}
          >
            {label}
            <SelectedOptionIcon
              selectedItemKey={selectedItemKey}
              option={key}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

const DropdownFilterSortPopup: React.FC<DropdownFilterSortPopupProps> = ({
  sortByOptions,
  filterByOptions,
  groupByOptions,
  onSortBy,
  onFilterBy,
  onGroupBy,
  sortBy,
  filterBy,
  groupBy,
}) => {
  const { t } = useTranslation();

  const { whitelistElement } = useContext(DropdownControlsContext);

  const [open, setOpen] = useState(false);

  const handleSortSelection = useCallback(
    (key) => {
      setOpen(false);
      onSortBy(key);
    },
    [onSortBy],
  );

  const handleFilterSelection = useCallback(
    (key) => {
      setOpen(false);
      onFilterBy(key);
    },
    [onFilterBy],
  );

  const handleGroupSelection = useCallback(
    (key) => {
      setOpen(false);
      onGroupBy(key);
    },
    [onGroupBy],
  );

  const currentSortByTranslation = useMemo(() => {
    if (!sortBy) {
      return t('common.sorting.display');
    }
    const option = _.findWhere(sortByOptions, { key: sortBy });
    return option?.label || t('common.sorting.display');
  }, [sortByOptions, sortBy, t]);

  const handleContentRef = useMemo(
    () => (element: HTMLElement | null) => {
      if (element) whitelistElement(element);
    },
    [whitelistElement],
  );

  if (
    _.isEmpty(sortByOptions) &&
    _.isEmpty(filterByOptions) &&
    _.isEmpty(groupByOptions)
  ) {
    return null;
  }

  return (
    <GenericPopup
      basic
      on='click'
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      position='bottom center'
      trigger={
        <div
          className={classNames(styles.selectDropdown, {
            [styles.triggerActive]: open,
          })}
        >
          {currentSortByTranslation} <i className='dropdown icon' />
        </div>
      }
    >
      <div ref={handleContentRef}>
        {!_.isEmpty(sortByOptions) && (
          <DropdownOptionList
            title={t('common.sorting.sortBy')}
            options={sortByOptions}
            onSelect={handleSortSelection}
            selectedItemKey={sortBy}
          />
        )}
        {!_.isEmpty(filterByOptions) && (
          <>
            {!_.isEmpty(sortByOptions) && (
              <Divider style={{ margin: '8px 0px' }} />
            )}
            <DropdownOptionList
              title={t('common.filtering.filterBy')}
              options={filterByOptions}
              onSelect={handleFilterSelection}
              selectedItemKey={filterBy}
            />
          </>
        )}
        {!_.isEmpty(groupByOptions) && (
          <>
            {(!_.isEmpty(sortByOptions) || !_.isEmpty(filterByOptions)) && (
              <Divider style={{ margin: '8px 0px' }} />
            )}
            <DropdownOptionList
              title={t('common.grouping.groupBy')}
              options={groupByOptions || []}
              onSelect={handleGroupSelection}
              selectedItemKey={groupBy}
            />
          </>
        )}
      </div>
    </GenericPopup>
  );
};

export const DropdownGroupTitle: React.FC<DropdownGroupTitleProps> = ({
  groupName,
  sortByOptions,
  filterByOptions,
  groupByOptions,
  onSortBy,
  onFilterBy,
  onGroupBy,
  sortBy,
  filterBy,
  groupBy,
  coloredGroupBackground,
}) => {
  return (
    <div
      className={classNames(
        styles.group,
        coloredGroupBackground && [styles.coloredGroup],
      )}
    >
      <span>{groupName}</span>
      <DropdownFilterSortPopup
        sortByOptions={sortByOptions}
        filterByOptions={filterByOptions}
        groupByOptions={groupByOptions}
        sortBy={sortBy}
        filterBy={filterBy}
        groupBy={groupBy}
        onSortBy={onSortBy}
        onFilterBy={onFilterBy}
        onGroupBy={onGroupBy}
      />
    </div>
  );
};

const DropdownSequenceList: React.FC<DropdownSequenceListProps> = ({
  sequenceList,
  isPlugin,
  clientId,
  selectedSequenceId,
  onSequenceSelected,
  shiftSidebar,
  sortBy,
  filterBy,
  groupBy,
  onSortBy,
  onFilterBy,
  onGroupBy,
}) => {
  const { isMinimized } = useMinimizedView();
  return (
    <div className={styles.sequenceList}>
      {_.map(sequenceList, (sequence, index) =>
        sequence?.groupName ? (
          <DropdownGroupTitle
            key={sequence.groupName}
            groupName={sequence.groupName}
            sortBy={sortBy}
            filterBy={filterBy}
            groupBy={groupBy}
            onSortBy={onSortBy}
            onFilterBy={onFilterBy}
            onGroupBy={onGroupBy}
            filterByOptions={sequence.filterByOptions || []}
            sortByOptions={sequence.sortByOptions || []}
          />
        ) : (
          <Popup
            key={index}
            className={`sequence-preview-popup${isPlugin ? '-plugin' : ''}`}
            basic
            on='hover'
            disabled={isMinimized}
            hoverable
            position={shiftSidebar ? 'left center' : 'right center'}
            trigger={
              <div
                className={styles.sequencePopupItem}
                onClick={() => onSequenceSelected(sequence)}
              >
                <span
                  className={
                    isPlugin ? styles.pluginSequenceTitle : styles.sequenceTitle
                  }
                >
                  {sequence?.title}
                </span>
                {!isPlugin && (
                  <ProfileRowSequence
                    currentSequenceInfo={sequence}
                    sequences={sequence?.contactFlow?.sequences}
                    nextInteraction={null}
                    loading={false}
                    isStandalone
                    mini
                    styles={styles.dropdownSequenceContainer}
                  />
                )}
                {sequence?.id === selectedSequenceId && (
                  <i className='ri-check-fill' />
                )}
              </div>
            }
            content={
              <SequencePreview clientId={clientId} sequence={sequence} />
            }
            popperModifiers={[
              {
                // Fix a positioning problem
                name: 'preventOverflow',
                options: {
                  boundariesElement: 'viewport',
                },
              }
            ]}
          />
        ),
      )}
    </div>
  );
};

export default DropdownSequenceList;
