import _ from 'underscore';


export const totalProfiles = (pipeStepStats) => {
  if (!pipeStepStats || !_.isArray(pipeStepStats)) {
    return null;
  }
  return pipeStepStats.reduce((total, { count }) => {
    return total + count;
  }, 0);
};

export const totalSentProfiles = (pipeStepStats) => {
  if (!pipeStepStats || !_.isArray(pipeStepStats)) {
    return null;
  }
  return pipeStepStats.reduce((total, step) => {
    if (!step || !_.isObject(step)) {
      return 0;
    }
    let sentAtStep = step.count;
    if (step.stepId === 'pending' || step.subStepId === 'not-sent') {
      sentAtStep = 0;
    }
    if (step.stepId === 'skipped') {
      sentAtStep = totalSentProfiles(step.pipeSubStepStats);
    }
    return total + sentAtStep;
  }, 0);
};


export const sendRateFromPipeStepStats = (pipeStepStats) => {
  if (!pipeStepStats || !_.isArray(pipeStepStats)) {
    return null;
  }
  if (totalProfiles(pipeStepStats) === 0) {
    return 0;
  }
  return totalSentProfiles(pipeStepStats) / totalProfiles(pipeStepStats);
};
