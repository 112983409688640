import { createContext } from 'react';

export interface MinimizedView {
  isMinimized: boolean;
  minimize: () => void;
  maximize: () => void;
}

const MinimizedViewContext = createContext<MinimizedView>({
  isMinimized: false,
  minimize: () => {
    /* Do nothing */
  },
  maximize: () => {
    /* Do nothing */
  },
});

export default MinimizedViewContext;
