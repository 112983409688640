import * as Sentry from '@sentry/browser';
import classNames from 'classnames';
import _, { compose } from 'underscore';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Form, Message, Modal } from 'semantic-ui-react';

import {
  cleanString,
  detectSuspiciousEmailContent,
} from '@/common/detectSuspiciousEmailContent';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import GenericToggle from '@/components/Common/GenericToggle';
import contextToProps from '../../../../hocs/contextToProps';
import withUserSettings from '../../../../hocs/users/withUserSettings';
import MergeTagsAutomationEditor from '../../../Editor/MergeTagsAutomationEditor';
import withNavApplicationMode from '../../../TopBar/Header/withNavApplicationMode';
import ForceSubmitModal from './ForceSubmit';
import EmailEditor from '../../../Editor';

import './TemplateModal.css';

class TemplateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      success: false,
      errorTitle: false,
      errorMessage: null,
      errors: null,
      closeOnDimmerClick: true,
      version: 1,
    };
    const { template, user, t } = this.props;
    const tags = { firstname: '{{firstname}}' };
    const defaultBody = t('templates.createAndEditTemplate.defaultBody', tags);
    this.state = {
      ...this.state,
      title: (template && template.title) || '',
      subject: (template && template.subject) || '',
      body: (template && template.body) || defaultBody || '',
      initialSubject: (template && template.subject) || '',
      initialBody: (template && template.body) || defaultBody || '',
      snippets: (template && template.snippets) || [],
      isDefaultReply:
        (template &&
          _.findWhere(template.recruiterConfigurations, {
            recruiterEmail: user?.email,
          })?.isDefaultReply) ||
        false,
    };
  }

  showError = (message) => {
    this.setState({ errorMessage: message, errorTitle: true });
    setTimeout(() => this.setState({ errorMessage: null }), 3000);
  };

  onChangeBody = _.debounce((html) => {
    this.setState(
      {
        body: html,
      },
      () => {
        this.cleanUnusedSnippets();
      },
    );
  }, 400);

  onChangeSubject = _.debounce((newSubject) => {
    const { title, subject } = this.state;
    this.setState({ subject: newSubject }, () => this.cleanUnusedSnippets());
    if (!title || subject === title) {
      this.setState({ title: newSubject }, () => this.cleanUnusedSnippets());
    }
  }, 400);

  cleanUnusedSnippets = () => {
    const { subject, body, snippets } = this.state;
    const cleanSnippets = _.filter(snippets || [], ({ id }) => {
      return subject?.includes(id) || body?.includes(id);
    });
    this.setState({
      snippets: cleanSnippets,
    });
  };

  onSnippetAdded = _.debounce(({ newSnippet }) => {
    if (!newSnippet) {
      return;
    }
    const { snippets, version } = this.state;
    this.setState({
      snippets: [...snippets, newSnippet],
      version: version + 1,
    });
  }, 400);

  onSnippetUpdated = _.debounce(({ updatedSnippet }) => {
    const { snippets, version } = this.state;
    this.setState({
      snippets: _.map(snippets, (snippet) => {
        if (snippet.id === updatedSnippet.id) {
          return updatedSnippet;
        }
        return snippet;
      }),
      version: version + 1,
    });
  }, 400);

  onSnippetFragmentInserted = ({ newSnippets, field, newContent }) => {
    const { snippets, version } = this.state;
    this.setState({
      ...(!_.isEmpty(newSnippets) && {
        snippets: [...snippets, ...newSnippets],
      }),
      ...(field === 'subject' && {
        subject: newContent,
      }),
      ...(field === 'body' && {
        body: newContent,
      }),
      version: version + 1,
    });
  };

  onChange = (ev) => {
    this.setState({ [ev.currentTarget.name]: ev.currentTarget.value });
  };

  forbidCloseOnDimmerClick = () => {
    this.setState({ closeOnDimmerClick: false });
  };

  componentWillReceiveProps(nextProps) {
    const { open, t } = this.props;
    if (nextProps.open && !open) {
      const newTemplate = nextProps.template;
      const tags = { firstname: '{{firstname}}' };
      const defaultBody = t(
        'templates.createAndEditTemplate.defaultBody',
        tags,
      );
      this.setState({
        title: (newTemplate && newTemplate.title) || '',
        subject: (newTemplate && newTemplate.subject) || '',
        body: (newTemplate && newTemplate.body) || defaultBody || '',
        snippets: (newTemplate && newTemplate.snippets) || [],
        initialBody: (newTemplate && newTemplate.body) || defaultBody || '',
        initialSubject: (newTemplate && newTemplate.subject) || '',
        isDefaultReply: (newTemplate && newTemplate.isDefaultReply) || false,
        closeOnDimmerClick: true,
      });
    }
  }

  getTemplateErrors = () => {
    // const { t } = this.props;
    const { subject = '', body = '', title } = this.state || {};
    const errors = [];
    if (!_.isString(title) || title.replace(/ /g, '').trim() === '') {
      errors.push('noTitle');
    }

    const cleanBody = cleanString(body);
    const cleanSubject = cleanString(subject);

    // const tags = { firstname: '{{firstname}}' };
    // const cleanDefaultBody = cleanString(t('templates.createAndEditTemplate.defaultBody', tags));
    if (cleanBody === '') {
      // || cleanBody === cleanDefaultBody) {
      errors.push('noBody');
    }

    if (
      _.contains(detectSuspiciousEmailContent(cleanSubject), 'hellofirstname')
    ) {
      errors.push('ambiguousSubject');
    }
    if (_.contains(detectSuspiciousEmailContent(cleanBody), 'hellofirstname')) {
      errors.push('ambiguousBody');
    }
    return errors;
  };

  handleSubmit = async (force = false) => {
    const { title, subject, body, snippets, isDefaultReply } = this.state;
    const { t } = this.props;
    const errors = this.getTemplateErrors();

    if (errors.indexOf('noTitle') >= 0) {
      this.showError(t('templates.notifications.noTitle'));
      return;
    }
    if (errors.indexOf('noBody') >= 0) {
      this.showError(t('templates.notifications.noBody'));
      return;
    }
    if (
      (errors.indexOf('ambiguousSubject') >= 0 ||
        errors.indexOf('ambiguousBody') >= 0) &&
      force !== true
    ) {
      this.showWarningModal(errors);
      return;
    }

    this.setState({ loading: true, success: false });

    try {
      const { onSubmit, onClose } = this.props;
      await onSubmit({
        snippets,
        title,
        subject,
        body,
        isDefaultReply,
      });
      this.setState({
        loading: false,
        error: false,
        success: false,
        errorTitle: false,
        errorMessage: null,
      });
      onClose();
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      this.setState({ error: true, loading: false, success: false });
    }
  };

  showWarningModal = (errors) => {
    this.setState({ errors });
  };

  onClose = () => {
    const { onClose } = this.props;
    onClose();
    this.setState({
      loading: false,
      error: false,
      success: false,
      errorTitle: false,
      errorMessage: null,
    });
  };

  renderModalHeader() {
    const { t } = this.props;
    const { errorTitle, title } = this.state;
    const inputClass = errorTitle ? 'input-error' : '';
    return (
      <div className='modal-header-content'>
        <Form.Field className='title-field title' error={errorTitle} required>
          <input
            maxLength={80}
            placeholder={t(
              'templates.createAndEditTemplate.templateTitlePlaceholder',
            )}
            name='title'
            value={title}
            onChange={this.onChange}
            className={`inplace-input ${inputClass}`}
            tabIndex={-10}
          />
        </Form.Field>
      </div>
    );
  }

  renderForm() {
    const {
      t,
      user,
      onQuitContactFlowEditor,
      navApplicationMode,
      clientId,
      children,
    } = this.props;
    const isSubjectRequired = navApplicationMode?.applicationMode !== 'reveal';

    const {
      error,
      loading,
      success,
      version,
      body,
      subject,
      snippets,
      initialBody,
      initialSubject,
      errorMessage,
    } = this.state;
    const displayedSignature =
      user && user.signature
        ? user.signature
        : `<em>${t('templates.createAndEditTemplate.signature')}</em>`;

    return (
      <Form
        id='template-form'
        className='template-form form-mail'
        error={error}
        loading={loading}
        success={success}
        onSubmit={this.handleSubmit}
      >
        {navApplicationMode?.applicationMode === 'reveal' ? (
          <MergeTagsAutomationEditor
            version={version}
            clientId={clientId}
            defaultValue={body}
            defaultValueSubject={subject}
            mergeTagsSubId='template'
            snippets={snippets || []}
            onChange={this.onChangeBody}
            onChangeSubject={this.onChangeSubject}
            onSnippetAdded={this.onSnippetAdded}
            onSnippetUpdated={this.onSnippetUpdated}
            onSnippetFragmentInserted={this.onSnippetFragmentInserted}
            onNavigate={async () => {
              await this.handleSubmit();
              if (onQuitContactFlowEditor) {
                await onQuitContactFlowEditor();
              }
            }}
            alwaysShowMenu
            fields='double' // simple or double
            placeholder={t('editor.placeholder.emailBody')}
            placeholderSubject={
              isSubjectRequired
                ? t('editor.placeholder.emailSubject')
                : t('editor.placeholder.emailSubjectOptional')
            }
            signature={displayedSignature}
            // style={{ border: 'none' }}
          />
        ) : (
          <EmailEditor
            clientId={clientId}
            defaultValue={initialBody} // FIXME
            defaultValueSubject={initialSubject} // FIXME
            onChange={this.onChangeBody}
            onChangeSubject={this.onChangeSubject}
            onNavigate={async () => {
              await this.handleSubmit();
              if (onQuitContactFlowEditor) {
                await onQuitContactFlowEditor();
              }
            }}
            mode='template'
            alwaysShowMenu
            fields='double' // simple or double
            placeholder={t('editor.placeholder.emailBody')}
            placeholderSubject={
              isSubjectRequired
                ? t('editor.placeholder.emailSubject')
                : t('editor.placeholder.emailSubjectOptional')
            }
            signature={displayedSignature}
            // style={{ border: 'none' }}
          />
        )}

        {children}
        <Message
          error
          header={t('templates.notifications.unableToEditTemplate.header')}
          content={t('templates.notifications.unableToEditTemplate.content')}
        />
        {errorMessage ? (
          <span className='error-message'>{errorMessage}</span>
        ) : null}
      </Form>
    );
  }

  render() {
    const { open, onClose, className, navApplicationMode, t } = this.props;
    if (!open) {
      return null;
    }

    const { errors, title, subject, body, closeOnDimmerClick } = this.state;
    const isSubjectRequired = navApplicationMode?.applicationMode !== 'reveal';
    const disableSubmit = !title || !body || (isSubjectRequired && !subject);

    return (
      <GenericModal
        className={classNames('new-template-modal', className)}
        closeOnDimmerClick={closeOnDimmerClick}
        closeOnEscape={closeOnDimmerClick}
        onMouseDown={this.forbidCloseOnDimmerClick}
        open={open}
        onClose={onClose}
      >
        <Modal.Header>{this.renderModalHeader()}</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <div className='modal-main-content'>{this.renderForm()}</div>
          </Modal.Description>
          {navApplicationMode?.applicationMode !== 'classic' && (
            <GenericToggle
              className='add-to-default-replies-toggle'
              label={t('templates.createAndEditTemplate.addToDefaultReplies')}
              onChange={({ value }) =>
                this.setState((prevState) => ({
                  ...prevState,
                  isDefaultReply: value,
                }))
              }
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <div className='new-template-modal-actions'>
            <GenericButton
              primacy='secondary'
              size='big'
              onMouseDown={() => this.onClose()}
            >
              {t('templates.createAndEditTemplate.cancel')}
            </GenericButton>
            <GenericButton
              size='big'
              onClick={() => this.handleSubmit()}
              disabled={disableSubmit}
              tabIndex={-7}
            >
              {t('templates.createAndEditTemplate.submit')}
            </GenericButton>
          </div>
        </Modal.Actions>
        <ForceSubmitModal
          open={!!errors}
          errors={errors}
          onCancel={() => {
            this.setState({ errors: null });
          }}
          onForceSubmit={() => {
            this.setState({ errors: null });
            this.handleSubmit(true);
          }}
        />
      </GenericModal>
    );
  }
}

export default compose(
  withUserSettings,
  contextToProps,
  withNavApplicationMode,
  withTranslation('translations'),
)(TemplateModal);
