import _ from 'underscore';

import { TFunction } from 'i18next';
import {
  TASK_TYPES,
  TASK_TITLE_BY_TYPE,
  getTaskIconName,
  getActionType,
} from '@/common/constants/taskTypes';
import { useClientCustomActions } from '@/graphql/hooks/clients/useClientCustomActions';
import { sanitizeTypename } from '@/common/utils/apollo';
import { getTranslatedText } from '@/common';
import { useMemo } from 'react';
import { CustomIconDescriptor } from '@/components/Common/GenericIconSelector/types';

interface Action {
  message?: {
    templateId?: string;
  };
  description?: string;
  type: string;
}

export const getCheckboxTradParams = (type: string): string => {
  if (type === TASK_TYPES.LINKEDIN_SEND_REQUEST) {
    return 'sequences.sequenceEditor.addInviteContentMessage';
  }
  return TYPES_WITH_MESSAGE.includes(type)
    ? 'sequences.sequenceEditor.addContentMessage'
    : 'sequences.sequenceEditor.addContentNote';
};

export const isMessageOpenByDefault = (action: Action): boolean => {
  if (action?.message?.templateId) return true;
  if (action?.description) return true;
  return false;
};

export const SORTED_OPTION_TYPES = [
  TASK_TYPES.SEND_EMAIL,
  TASK_TYPES.SEND_EMAIL_MANUAL,
  TASK_TYPES.MAKE_PHONE_CALL,
  TASK_TYPES.MANUAL,
  TASK_TYPES.LINKEDIN_SEND_REQUEST,
  TASK_TYPES.LINKEDIN_SEND_MESSAGE,
  TASK_TYPES.LINKEDIN_PREMIUM_INMAIL,
  TASK_TYPES.LINKEDIN_RECRUITER_INMAIL,
  TASK_TYPES.LINKEDIN_SALESNAVIGATOR_INMAIL,
  TASK_TYPES.LINKEDIN_CHECK_REQUEST,
  TASK_TYPES.WHATSAPP_SEND_MESSAGE,
  TASK_TYPES.SEND_TEXT_MESSAGE,
];

export const TYPES_WITH_MESSAGE = [
  TASK_TYPES.SEND_EMAIL,
  TASK_TYPES.SEND_EMAIL_MANUAL,
  TASK_TYPES.LINKEDIN_SEND_MESSAGE,
  TASK_TYPES.LINKEDIN_PREMIUM_INMAIL,
  TASK_TYPES.LINKEDIN_RECRUITER_INMAIL,
  TASK_TYPES.LINKEDIN_SALESNAVIGATOR_INMAIL,
  TASK_TYPES.LINKEDIN_SEND_REQUEST,
  TASK_TYPES.SEND_TEXT_MESSAGE,
  TASK_TYPES.WHATSAPP_SEND_MESSAGE,
  TASK_TYPES.CUSTOM,
];

export const TYPES_WITH_SUBJECT = [
  TASK_TYPES.SEND_EMAIL,
  TASK_TYPES.SEND_EMAIL_MANUAL,
  TASK_TYPES.LINKEDIN_PREMIUM_INMAIL,
  TASK_TYPES.LINKEDIN_RECRUITER_INMAIL,
  TASK_TYPES.LINKEDIN_SALESNAVIGATOR_INMAIL,
];

export const TYPES_WITH_SIGNATURE = [
  TASK_TYPES.SEND_EMAIL,
  TASK_TYPES.SEND_EMAIL_MANUAL,
];

export const TYPES_WITH_DESCRIPTION = [
  TASK_TYPES.MANUAL,
  TASK_TYPES.SEND_TEXT_MESSAGE,
  TASK_TYPES.MAKE_PHONE_CALL,
];

export const TYPES_WITH_MANDATORY_CONTENT = [
  TASK_TYPES.SEND_EMAIL,
  TASK_TYPES.SEND_EMAIL_MANUAL,
  TASK_TYPES.LINKEDIN_SEND_MESSAGE,
  TASK_TYPES.LINKEDIN_PREMIUM_INMAIL,
  TASK_TYPES.LINKEDIN_RECRUITER_INMAIL,
  TASK_TYPES.LINKEDIN_SALESNAVIGATOR_INMAIL,
  TASK_TYPES.WHATSAPP_SEND_MESSAGE,
];

export const hasMandatoryMessage = (action: Action): boolean =>
  TYPES_WITH_MANDATORY_CONTENT.includes(action?.type);

export const canHaveContent = (action: Action): boolean =>
  [
    ...TYPES_WITH_MESSAGE,
    ...TYPES_WITH_DESCRIPTION,
    TASK_TYPES.CUSTOM,
  ].includes(action?.type);

export const TYPES_WITH_DEFAULT_DISPLAY_CONTENT = [
  TASK_TYPES.LINKEDIN_SEND_MESSAGE,
];

export const ACTION_TYPE_OPTIONS = _.map(SORTED_OPTION_TYPES, (type) => {
  const iconName = getTaskIconName({ type });
  return {
    ...(type === TASK_TYPES.SEND_EMAIL_MANUAL && { className: 'hs-hidden' }),
    value: type,
    type,
    text: TASK_TITLE_BY_TYPE[type as keyof typeof TASK_TITLE_BY_TYPE],
    optionIcon: {
      type: 'built-in',
      id: iconName,
    },
    image: {
      src: `/images/icons/tasks/${iconName}.svg`,
    },
  };
});

interface CustomActionOptions {
  value: string;
  type: string;
  text: string;
  image: {
    src: string;
  };
  className?: string;
  customActionId?: string;
  optionIcon: CustomIconDescriptor;
}

export function useClientTaskOptions({
  currentAction,
  taskTypesToIgnore,
}: {
  currentAction?: {
    type: string;
    icon?: {
      url?: string;
      src?: string;
      emojiText?: string;
      id?: string;
    };
    customActionId?: string;
  };
  taskTypesToIgnore?: string[];
} = {}) {
  const { clientCustomActions } = useClientCustomActions();

  const customActionOptions: CustomActionOptions[] = useMemo(
    () =>
      _.map(clientCustomActions, (customAction) => {
        const iconSrc =
          customAction.icon.type === 'built-in'
            ? `/images/icons/tasks/${customAction.icon.id}.svg`
            : customAction.icon.type === 'url'
              ? (customAction.icon.url as string)
              : '';

        const text = getTranslatedText(customAction.title);
        return {
          // client might have multiple custom tasks of the same type
          // so we use the ID to make them unique, but we'll need to
          // store them as "custom" at the end
          value: customAction.id,
          type: customAction.type,
          // TODO: should we replicate the action execution here?
          // if we add a custom action to a profile and then modify the action
          // we will execute the modified action on the profile
          customActionId: customAction.id,
          execution: sanitizeTypename(customAction.execution),
          completionButtonDisplay: customAction.completionButtonDisplay,
          text,
          format: sanitizeTypename(customAction.format),
          optionIcon: customAction.icon,
          image: {
            src: iconSrc,
          },
        };
      }),
    [clientCustomActions],
  );

  const options = useMemo(
    () => [
      ..._.filter(
        ACTION_TYPE_OPTIONS,
        ({ type }) => !_.contains(taskTypesToIgnore || [], type),
      ),
      ...customActionOptions,
    ],
    [customActionOptions, taskTypesToIgnore],
  );

  const actionType = getActionType(currentAction);
  const selectedOption = useMemo(() => {
    if (currentAction?.customActionId) {
      const match = _.findWhere(options, {
        customActionId: currentAction.customActionId,
      });
      if (match) {
        return match;
      }
    }

    const match = _.findWhere(options, {
      type: actionType,
    });

    return (
      match || {
        // Or we enrich optionIcon with either missing type or the fallback icon option (timeline)
        optionIcon: {
          ...currentAction?.icon,
          ...(currentAction?.icon?.url && {
            type: 'url',
          }),
          ...(currentAction?.icon?.emojiText && {
            type: 'emoji',
          }),
          ...(!currentAction?.icon?.url &&
            !currentAction?.icon?.emojiText && {
              url: '/images/logos/new-logo_without_text_blue.svg',
              type: 'url',
            }),
        },
      }
    );
  }, [actionType, currentAction, options]);

  return {
    options,
    selectedOption,
  };
}

export const EMAIL_TYPE_OPTIONS = (
  t: TFunction,
): { value: string; text: string }[] => [
  {
    value: TASK_TYPES.SEND_EMAIL_MANUAL,
    text: t('contactFlow.triggers.manually'),
  },
  {
    value: TASK_TYPES.SEND_EMAIL,
    text: t('contactFlow.triggers.automatically'),
  },
];
