import React from 'react';
import _ from 'underscore';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import useSequenceOptions from '@/graphql/hooks/sequences/useSequenceOptions';

enum SequenceFilterType {
  CURRENTLY_IN = 'currently-in',
  CURRENTLY_NOT_IN = 'currently-not-in',
  CURRENTLY_NOT_IN_ANY = 'currently-not-in-any',
}

interface SequenceFilter {
  filterType: SequenceFilterType;
  sequenceIds?: string[];
}

type SequenceOption = {
  value?: string;
  text?: string;
};

interface SequenceFieldsProps {
  clientId: string;
  sequenceFilters: Array<SequenceFilter>;
  updateSequenceFilters: any;
}

const SequenceFields = React.memo(
  ({
    clientId,
    sequenceFilters,
    updateSequenceFilters,
  }: SequenceFieldsProps) => {
    const { loading, data } = useSequenceOptions(clientId);
    const clientSequences = data?.client?.sequences || [];
    const { t } = useTranslation();

    const sequenceOptions: SequenceOption[] = _.map(clientSequences, (s) => ({
      value: s.id,
      text: s.title,
    }));

    const currentlyInFilter = _.findWhere(sequenceFilters, {
      filterType: SequenceFilterType.CURRENTLY_IN,
    });

    const currentlyNotInFilter = _.findWhere(sequenceFilters, {
      filterType: SequenceFilterType.CURRENTLY_NOT_IN,
    });
    const currentlyNotInAnyChecked = !!_.findWhere(sequenceFilters, {
      filterType: SequenceFilterType.CURRENTLY_NOT_IN_ANY,
    });

    const getCheckboxOnChangeHanler = (
      selectedFilterType: SequenceFilterType,
    ) => (e: React.SyntheticEvent, { checked }: { checked?: boolean }) => {
      updateSequenceFilters(
        updateCheckedFiltersHandler({
          checked,
          selectedFilterType,
        }),
      );
    };

    const getDropdownOnChangeHandler = (
      selectedFilterType: SequenceFilterType,
    ) => (e: React.SyntheticEvent, { value }: { value?: any }) => {
      updateSequenceFilters(
        updateSelectedSequenceHandler({
          value,
          selectedFilterType,
        }),
      );
    };

    return (
      <div className='section'>
        <div className='input-container'>
          <div className='input-label'>
            {t('reveal.searchView.filters.sequence.currentFollowing')}
          </div>
          <div className='input-element'>
            <Dropdown
              multiple
              selection
              fluid
              search
              loading={loading}
              options={sequenceOptions}
              value={currentlyInFilter?.sequenceIds || []}
              onChange={getDropdownOnChangeHandler(
                SequenceFilterType.CURRENTLY_IN,
              )}
              placeholder={t('reveal.searchView.filters.sequence.choose')}
            />
          </div>
        </div>
        <div className='input-container'>
          <div className='input-label'>
            {t('reveal.searchView.filters.sequence.currentNotFollowing')}
          </div>
          <Dropdown
            multiple
            selection
            fluid
            search
            loading={loading}
            options={sequenceOptions}
            value={currentlyNotInFilter?.sequenceIds || []}
            onChange={getDropdownOnChangeHandler(
              SequenceFilterType.CURRENTLY_NOT_IN,
            )}
            placeholder={t('reveal.searchView.filters.sequence.choose')}
          />
        </div>
        <div className='input-container'>
          <div className='input-label'>
            <Checkbox
              label={t('reveal.searchView.filters.sequence.currentAny')}
              checked={currentlyNotInAnyChecked}
              onChange={getCheckboxOnChangeHanler(
                SequenceFilterType.CURRENTLY_NOT_IN_ANY,
              )}
            />
          </div>
        </div>
      </div>
    );
  },
);

const updateCheckedFiltersHandler = ({
  checked,
  selectedFilterType,
}: {
  checked?: boolean;
  selectedFilterType: SequenceFilterType;
}) => (previousFilters: Array<SequenceFilter>) => {
  if (checked) {
    return [
      {
        filterType: selectedFilterType,
        ...(selectedFilterType !== SequenceFilterType.CURRENTLY_NOT_IN_ANY && {
          sequenceIds: [],
        }),
      },
    ];
  }
  return _.filter(
    previousFilters,
    ({ filterType }) => filterType !== selectedFilterType,
  );
};

const updateSelectedSequenceHandler = ({
  value,
  selectedFilterType,
}: {
  value: string[];
  selectedFilterType: SequenceFilterType;
}) => (previousFilters: Array<SequenceFilter>) => {
  return _.reject(
    [
      ..._.reject(
        previousFilters,
        (filter) => filter.filterType === selectedFilterType,
      ),
      {
        filterType: selectedFilterType,
        sequenceIds: value,
      },
    ],
    ({ sequenceIds }) => _.isEmpty(sequenceIds),
  );
};

export default SequenceFields;
