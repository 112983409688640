import React from 'react';
import { withTranslation } from 'react-i18next';

import { Modal, Button } from 'semantic-ui-react';

const ForceSubmit = ({ open, onCancel, onForceSubmit, t }) => {
  return (
    <Modal open={open} onClose={onCancel} closeIcon size='tiny'>
      <Modal.Header>{t('templates.createAndEditTemplate.forceSubmitModal.title')}</Modal.Header>
      <Modal.Content>{t('templates.createAndEditTemplate.forceSubmitModal.text')}</Modal.Content>
      <Modal.Actions>
        <div className='align-left'>
          <Button onClick={onCancel} className='dismiss'>
            {t('templates.createAndEditTemplate.forceSubmitModal.cancel')}
          </Button>
        </div>
        <Button onClick={onForceSubmit} secondary size='big'>
          {t('templates.createAndEditTemplate.forceSubmitModal.confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default withTranslation('translations')(ForceSubmit);
