import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';

import withClientPermissions from '../../hocs/clients/withClientPermissions';

import './DashboardQuickActions.css';

const DashboardQuickActions = ({
  clientId,
  permissions,
  t,
  mode,
  quickActions,
}) => {
  return (
    <div className='dashboard-quick-actions'>
      <NewJobButton
        mode={mode}
        clientId={clientId}
        onClick={quickActions.openNewJob}
        t={t}
      />
      <NewProfileButton
        mode={mode}
        permissions={permissions}
        onClick={quickActions.openNewProfile}
        t={t}
      />
      <NewSequenceButton
        mode={mode}
        onClick={quickActions.openNewSequence}
        t={t}
      />
      <NewTemplateButton onClick={quickActions.openNewTemplate} t={t} />
      <NewCollaboratorButton onClick={quickActions.openNewCollaborator} t={t} />
    </div>
  );
};

const NewJobButton = ({ clientId, mode, onClick, t }) => {
  if (mode === 'reveal') {
    return (
      <div
        className='quick-action'
        onClick={onClick}
        onKeyPress={onClick}
        role='button'
        tabIndex={0}
      >
        <i className='ri-add-line' />
        <span>{t('header.new.project')}</span>
      </div>
    );
  }
  return (
    <Link
      className='quick-action'
      onClick={onClick}
      to={`/client/${clientId}/new-job`}
    >
      <i className='ri-add-line' />
      <span>{t('header.new.job')}</span>
    </Link>
  );
};

const NewSequenceButton = ({ mode, onClick, t }) => {
  if (mode !== 'reveal') {
    return null;
  }
  return (
    <div
      className='quick-action'
      onClick={onClick}
      onKeyPress={onClick}
      role='button'
      tabIndex={0}
    >
      <i className='ri-stack-line' />
      <span>{t('header.new.sequence')}</span>
    </div>
  );
};

const NewProfileButton = ({ mode, permissions, onClick, t }) => {
  if (!permissions?.csvImportAndExport || mode === 'reveal') {
    return null;
  }
  return (
    <div
      className='quick-action'
      onClick={onClick}
      onKeyPress={onClick}
      role='button'
      tabIndex={0}
    >
      <i className='ri-user-received-2-line' />
      <span>{t('header.new.profile')}</span>
    </div>
  );
};

const NewTemplateButton = ({ onClick, t }) => {
  return (
    <div
      className='quick-action'
      onClick={onClick}
      onKeyPress={onClick}
      role='button'
      tabIndex={0}
    >
      <i className='ri-file-list-2-line' />
      <span>{t('header.new.template')}</span>
    </div>
  );
};

const NewCollaboratorButton = ({ onClick, t }) => {
  return (
    <div
      className='quick-action'
      onClick={onClick}
      onKeyPress={onClick}
      role='button'
      tabIndex={0}
    >
      <i className='ri-team-line' />
      <span>{t('header.new.user')}</span>
    </div>
  );
};

export default compose(
  withClientPermissions,
  withTranslation('translations'),
)(DashboardQuickActions);
