import { useMutation, useQuery } from '@apollo/client';
import classnames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader, Form, Image } from 'semantic-ui-react';
import _ from 'underscore';

import { getRandomDefaultAvatarLink, getSecureLink } from '@/common';
import GenericButton from '@/components/Common/GenericButton';
import GenericSelect from '@/components/Common/GenericSelect';
import GenericTextInput from '@/components/Common/GenericTextInput';
import LoadingComponent from '@/components/LoadingComponent';
import DoNotContactPill from '@/components/Reveal/Profile/privacy/DoNotContactPill/DoNotContactPill';
import {
  ICON_FROM_SERVICE,
  SERVICE_FROM_SUBTYPE,
} from '@/containers/Profile/Contact/TimeLineItems/UnipileMessageItem/UnipileMessageItem';
import { replacePhotoLink } from '@/containers/Profile/Resume/Card/RevealProfileHeader/RevealProfileHeader';
import ENRICHED_SEARCH_POOL_PROFILE from '@/graphql/enrichedSearchPoolProfile';
import useSharedMessagingAccounts from '@/graphql/hooks/clients/useSharedServiceAccount';
import SEARCH_POOL_PROFILE_LAST_EVENT from '@/graphql/useSearchPoolProfileLastEvent';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useProfileThreads from '@/graphql/hooks/searchPoolProfile/useProfileThreads';

import { sendMessageUsingMessagingServiceMutation } from '../SearchView/CandidatesListPane/CandidateView/MultiChannelActivity/UnipileNewMessage';

import styles from './InboxView.module.less';

interface ConversationProps {
  profileId: string;
}

const MESSAGE_TYPE_FROM_ACCOUNT_TYPE = {
  'unipile-linkedin': 'unipile-linkedin-message',
  'unipile-whatsapp': 'unipile-whatsapp',
};

const Conversation: React.FC<ConversationProps> = ({ profileId }) => {
  const { t } = useTranslation();
  const [explicitAccountId, setExplicitAccountId] = useState<string>('');
  const { sharedMessagingAccounts } = useSharedMessagingAccounts();
  const [messageType, setMessageType] = useState('');
  const [messageText, setMessageText] = useState('');
  const [sendMessageUsingMessagingService, { loading: _loading }] = useMutation(
    sendMessageUsingMessagingServiceMutation,
  );
  const [_isSending, setIsSending] = useState(false);
  const [lastSeenEventTimestamp, setLastSeenEventTimestamp] = useState(null);
  const { threads, refetch } = useProfileThreads({ profileId });

  const ref = useRef<HTMLDivElement>(null);

  const { error } = useNotificationSystem();

  const { loading: loadingProfile, data } = useQuery(
    ENRICHED_SEARCH_POOL_PROFILE,
    {
      variables: { searchPoolId: 'reveal', id: profileId },
      skip: _.isEmpty(profileId),
    },
  );
  const profile = data?.searchPool?.enrichedProfile;

  const { data: lastEventTimestampData } = useQuery(
    SEARCH_POOL_PROFILE_LAST_EVENT,
    {
      variables: {
        searchPoolId: 'reveal',
        profileId,
      },
      pollInterval: 4000,
    },
  );

  useEffect(() => {
    if (!lastEventTimestampData) {
      return;
    }
    const timestampStr =
      lastEventTimestampData.searchPool.profile.lastEventTimestamp;
    if (lastSeenEventTimestamp && lastSeenEventTimestamp !== timestampStr) {
      refetch();
    }
    setLastSeenEventTimestamp(timestampStr);
  }, [lastEventTimestampData, lastSeenEventTimestamp, refetch]);

  const handleSendMessage = useCallback(
    async (unipileChatId) => {
      if (!messageText) {
        return;
      }
      const res = await sendMessageUsingMessagingService({
        variables: {
          input: {
            messagingAccountType: 'unipile',
            messagingSenderId: explicitAccountId,
            messagingChatId: unipileChatId,
            messageType:
              messageType ||
              MESSAGE_TYPE_FROM_ACCOUNT_TYPE[sharedMessagingAccounts[0]?.type],
            profileId,
            body: messageText,
            subject: '',
            isConnected: true,
          },
          searchPoolId: 'reveal',
        },
      });
      if (
        res?.data?.searchPoolProfile?.sendMessageUsingMessagingService?.success
      ) {
        setMessageText('');
        setIsSending(false);
      } else {
        setIsSending(false);
        setMessageText('');
        error(t('reveal.inbox.errorSendMessage'));
      }
    },
    [
      explicitAccountId,
      sharedMessagingAccounts,
      messageText,
      sendMessageUsingMessagingService,
      messageType,
      profileId,
      error,
      t,
    ],
  );

  const sharedAccountsOptions = _.map(sharedMessagingAccounts, (account) => {
    const { type } = account;
    const icon = getIcon(type);
    return {
      value: account.id,
      label: (
        <div className={styles.dropdownOption}>
          <div
            style={{ backgroundColor: icon.background }}
            className={styles.dropdownIconContainer}
          >
            <i style={{ color: icon.color }} className={`${icon.icon}`} />
          </div>
          {account.name}
        </div>
      ),
    };
  });

  useEffect(() => {
    if (!_.isEmpty(sharedAccountsOptions) && !explicitAccountId) {
      setExplicitAccountId(sharedAccountsOptions[0].value);
    }
  }, [sharedAccountsOptions, explicitAccountId]);

  const account = useMemo(
    () =>
      explicitAccountId
        ? _.findWhere(sharedMessagingAccounts, {
            id: explicitAccountId,
          })
        : undefined,
    [explicitAccountId, sharedMessagingAccounts],
  );

  const messages = useMemo(
    () =>
      _.chain(threads)
        .filter(({ type }) => type in SERVICE_FROM_SUBTYPE)
        .map((thread) =>
          _.map(
            thread.messages || [],
            ({ id, text, from, timestamp, isFromRecruiter }) => ({
              id,
              text,
              isFromMe: isFromRecruiter,
              from,
              timestamp,
              type: thread.type,
            }),
          ),
        )
        .flatten()
        .filter(({ text }) => !/{{.*}}/.test(text))
        .sortBy('timestamp')
        .value(),
    [threads],
  );

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [messages]);

  if (_.isEmpty(profileId) || loadingProfile) {
    return (
      <div className={styles.conversationContainer}>
        <div className={styles.conversation}>
          <Dimmer active>
            <Loader />
          </Dimmer>
        </div>
      </div>
    );
  }

  const avatarImageUrl = profile?.resumeData?.photoLink
    ? replacePhotoLink(profile.resumeData.photoLink)
    : getRandomDefaultAvatarLink(
        `${profile?.resumeData?.firstname}${profile?.resumeData?.lastname}`,
      );

  return (
    <div className={styles.conversationContainer}>
      <div className={styles.conversationProfileHeader}>
        <div className={styles.avatarAndInfos}>
          <Image
            data-openreplay-masked
            className='card-company-image'
            src={getSecureLink(avatarImageUrl || '')}
            circular
            onError={(e: any) => {
              e.target.src = getRandomDefaultAvatarLink(
                `${profile?.resumeData?.firstname}${profile?.resumeData?.lastname}`,
              );
            }}
          />
          <div className={styles.infos}>
            <LoadingComponent
              as='div'
              loading={_.isEmpty(profile?.resumeData)}
              length={30}
              margin={0.6}
            >
              <div className='inline-middle'>
                <h2 className={styles.fullname}>
                  {profile?.resumeData?.firstname}{' '}
                  {profile?.resumeData?.lastname}
                </h2>
                <span className={styles.headline}>
                  {profile?.resumeData?.headline?.content?.text}
                </span>
              </div>
              {profile?.privacyState?.markedAsDoNotContact && (
                <DoNotContactPill />
              )}
            </LoadingComponent>
          </div>
        </div>
      </div>
      <div className={styles.conversation} ref={ref}>
        {_.map(messages, (message, index) => {
          const icon = getIcon(message.type);
          return (
            <div
              className={classnames(
                styles.messageContainer,
                message.isFromMe && styles.fromMe,
              )}
              key={message.id || index}
            >
              {!message.isFromMe && (
                <Image
                  className={styles.messageAvatar}
                  src={getSecureLink(avatarImageUrl || '')}
                  circular
                  onError={(e: any) => {
                    e.target.src = getRandomDefaultAvatarLink(
                      `${profile?.resumeData?.firstname}${profile?.resumeData?.lastname}`,
                    );
                  }}
                />
              )}
              <div className={styles.messageData}>
                <span className={styles.message}>{message.text}</span>
                <div className={styles.messageMetadata}>
                  <div
                    style={{ backgroundColor: icon.background }}
                    className={styles.messageIcon}
                  >
                    <i
                      style={{ color: icon.color }}
                      className={`${icon.icon} ri-sm`}
                    />
                  </div>
                  <span className={styles.from}>
                    {message.from === 'profile'
                      ? _.contains(message.type, 'whatsapp')
                        ? profile.resumeData?.phoneNumber
                        : `${profile.resumeData?.firstname} ${profile.resumeData?.lastname}`
                      : message.from}
                  </span>
                  <span className={styles.time}>
                    {t('common.shortDateAndTime', {
                      date: new Date(message.timestamp),
                    })}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.messageInputContainer}>
        <div className={styles.senderSelectorContainer}>
          {!_.isEmpty(sharedAccountsOptions) && (
            <GenericSelect
              defaultValue={sharedAccountsOptions?.[0]}
              options={sharedAccountsOptions}
              onChange={(value) => {
                setMessageType(MESSAGE_TYPE_FROM_ACCOUNT_TYPE[account?.type]);
                setExplicitAccountId(value.value as string);
              }}
              className={styles.trigger}
              menuPlacement='top'
            />
          )}
          <span className={styles.senderToRecipient}>
            {t('reveal.inbox.chat.from')}
            <span className={styles.sender}>
              {explicitAccountId
                ? _.findWhere(sharedMessagingAccounts, {
                    id: explicitAccountId,
                  })?.name || ''
                : ''}
            </span>
            {t('reveal.inbox.chat.to')}
            <span className={styles.recipient}>
              {account?.type === 'unipile-whatsapp'
                ? `${profile?.resumeData?.phoneNumber}`
                : `${profile?.resumeData?.firstname} ${profile?.resumeData?.lastname}`}
            </span>
          </span>
        </div>
        <Form className={styles.form}>
          <GenericTextInput
            className={styles.input}
            fluid
            value={messageText}
            placeholder={t('reveal.inbox.chat.messagePlaceholder')}
            onValue={(value) => setMessageText(value as string)}
            onKeyUp={(e) => {
              if (e.key === 'Enter' && e.shiftKey) {
                handleSendMessage('');
              }
            }}
            disabled={loadingProfile || _.isEmpty(sharedAccountsOptions)}
          />
          <GenericButton
            onClick={() => handleSendMessage('')}
            disabled={
              loadingProfile || _.isEmpty(sharedAccountsOptions) || !messageText
            }
          >
            {t('profile.contact.drafts.confirmationModal.send')}
          </GenericButton>
        </Form>
      </div>
    </div>
  );
};

const getIcon = (type: string) =>
  type in ICON_FROM_SERVICE
    ? ICON_FROM_SERVICE[type as keyof typeof ICON_FROM_SERVICE]
    : ICON_FROM_SERVICE.default;

export default Conversation;
