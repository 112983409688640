import classNames from 'classnames';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import {
  GenericButtonPrimacy,
  GenericButtonColor,
  GenericButtonSize,
} from './types';

import styles from './GenericButton.module.less';

interface ButtonLinkProps {
  primacy?: GenericButtonPrimacy;
  color?: GenericButtonColor;
  size?: GenericButtonSize;
  disabled?: boolean;
  isIcon?: boolean;
  className?: string;
}

const ForwardedButtonLink: ForwardRefRenderFunction<
  HTMLAnchorElement,
  ButtonLinkProps & LinkProps
> = (
  {
    primacy = 'primary',
    color = 'blue',
    size = 'normal',
    isIcon = false,
    className,
    children,
    disabled,
    style,
    ...props
  },
  ref,
) => (
  <Link
    className={classNames(
      styles.button,
      styles[primacy],
      styles[color],
      styles[size],
      { [styles.icon]: isIcon, [styles.disabled]: disabled },
      className,
    )}
    ref={ref}
    style={disabled ? { ...style, pointerEvents: 'none' } : style}
    {...props}
  >
    {children}
  </Link>
);

const ButtonLink = forwardRef(ForwardedButtonLink);

export default ButtonLink;
