import React, { useMemo, useState } from 'react';
import { Link, Redirect, useRouteMatch, generatePath } from 'react-router-dom';
import _ from 'underscore';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Icon,
  Popup,
  Segment,
  Loader,
  Dimmer,
  Modal,
} from 'semantic-ui-react';
import classNames from 'classnames';

import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import EmptyState from '@/revealComponents/EmptyState/EmptyState';
import GenericButton from '@/components/Common/GenericButton';
import useCampaignsDailyCohorts from '@/graphql/hooks/campaigns/useCampaignsDailyCohorts';
import {
  aggregateCampaignStats,
  getCampaignStats,
} from '@/routes/RevealView/RevealAnalytics/tabs/MarketingTab/CampaignFunnels/helpers';
import CampaignFunnelSummary from '@/routes/RevealView/RevealAnalytics/tabs/MarketingTab/CampaignFunnels/CampaignFunnelSummary';
import { getFullname } from '@/common/helpers/person';
import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';
import useCampaignsDailyCohortsDetails from '@/graphql/hooks/campaigns/useCampaignsDailyCohortsDetails';
import { statsCampaignEvents } from '@/types/statistics/campaigns';
import GenericModal from '@/components/Common/GenericModal';
import StatsProfileAndCampaignTable from '@/routes/RevealView/RevealAnalytics/components/StatsProfileAndCampaignTable';
import useFunnelDetails from '@/routes/RevealView/RevealAnalytics/tabs/MarketingTab/CampaignFunnels/hooks/useFunnelDetails';

import './CampaignsList.css';
import DetailsModalHeader from '@/routes/RevealView/RevealAnalytics/components/DetailsModalHeader';
import {
  ARCHIVE_CAMPAIGN,
  RESTORE_CAMPAIGN,
  DUPLICATE_CAMPAIGN,
  CLIENT_MINI_CAMPAIGNS,
} from './queries';
import NewCampaignModal from './NewCampaignModal';

export const getComparisonFunction = ({ sortKey }) => {
  if (sortKey === 'author') {
    return ({ author }) => getFullname(author).toLowerCase();
  }
  if (sortKey === 'title') {
    return ({ title }) => title.toLowerCase();
  }
  return '';
};

const CampaignsList = ({ clientId }) => {
  const { t } = useTranslation();
  const { loading, error, data, refetch } = useQuery(CLIENT_MINI_CAMPAIGNS, {
    variables: { clientId },
    fetchPolicy: 'network-only',
  });
  const { path, params } = useRouteMatch();
  const [displayArchived, setDisplayArchived] = useState(false);
  const campaigns = data?.client?.campaigns;
  const activeCampaigns = _.where(campaigns, { isArchived: false });
  const archivedCampaigns = _.where(campaigns, { isArchived: true });
  const [countArchivedInStats, setCountArchivedInStats] = useState(false);

  const { dailyCohorts } = useCampaignsDailyCohorts({ clientId });
  const campaignsAllStats = useMemo(() => getCampaignStats(dailyCohorts), [
    dailyCohorts,
  ]);
  const campaignOnlyActiveStats = useMemo(
    () =>
      _.reduce(
        activeCampaigns,
        (result, { id }) => ({ ...result, [id]: campaignsAllStats[id] }),
        {},
      ),
    [activeCampaigns, campaignsAllStats],
  );
  const campaignStats = useMemo(
    () =>
      aggregateCampaignStats(
        countArchivedInStats ? campaignsAllStats : campaignOnlyActiveStats,
      ),
    [campaignsAllStats, campaignOnlyActiveStats, countArchivedInStats],
  );

  const [
    fetchDetails,
    { dailyCohorts: dailyCohortsDetails = [], loading: detailsLoading },
  ] = useCampaignsDailyCohortsDetails();
  const [modalOpen, setModalOpen] = useState(false);

  const {
    filter,
    applyFilter,
    profileDetails,
  } = useFunnelDetails(dailyCohortsDetails, ({ campaignIds }) =>
    fetchDetails({ clientId, campaignIds }),
  );

  const globalClickListeners = useMemo(() => {
    if (!fetchDetails) {
      return undefined;
    }

    return _.reduce(
      statsCampaignEvents,
      (listeners, segment) => ({
        ...listeners,
        [segment]: () => {
          setModalOpen(true);
          applyFilter({ segment });
        },
      }),
      {},
    );
  }, [fetchDetails, applyFilter]);

  const modalTitle = useMemo(() => {
    const titleParts = [];
    if (filter?.segment) {
      titleParts.push(t(`reveal.reports.marketing.state.${filter.segment}`));
    }
    return titleParts.join(' - ');
  }, [filter, t]);

  const onDuplicate = () => {
    refetch();
  };

  return (
    <div className='campaigns-list'>
      {loading && <Loader active />}
      {!loading && !error && (
        <>
          {!_.isEmpty(activeCampaigns) ? (
            <div className='campaigns-section'>
              <div className='campaigns-header'>
                <h3>{t('reveal.reports.marketing.campaignFunnels')}</h3>
                <div className='campaigns-header-settings'>
                  <LabeledCheckbox
                    checked={countArchivedInStats}
                    onClick={() =>
                      setCountArchivedInStats(!countArchivedInStats)
                    }
                    label={t('campaigns.funnel.countArchived')}
                  />
                </div>
              </div>
              {campaignStats && (
                <CampaignFunnelSummary
                  data={campaignStats}
                  clickListeners={globalClickListeners}
                />
              )}
              <CampaignsTable
                campaigns={activeCampaigns}
                clientId={clientId}
                onDuplicate={onDuplicate}
              />
            </div>
          ) : (
            <Segment>
              <EmptyState
                title={t('campaigns.emptyState.title')}
                innerContent={t('campaigns.emptyState.innerContent')}
                illustrationPath='/images/placeholders/templatesEmptyState.svg'
                cta={
                  <Link
                    to={generatePath(`${path}/new-campaign-create`, params)}
                  >
                    <GenericButton size='big'>
                      {t('campaigns.emptyState.cta')}
                    </GenericButton>
                  </Link>
                }
              />
            </Segment>
          )}
          {!_.isEmpty(archivedCampaigns) && (
            <>
              <HideAndShowArchivedCampaignsButton
                t={t}
                archivedCampaigns={archivedCampaigns}
                displayArchived={displayArchived}
                setDisplayArchived={setDisplayArchived}
              />
              {displayArchived && (
                <div className='campaigns-section'>
                  <CampaignsTable
                    campaigns={archivedCampaigns}
                    clientId={clientId}
                    onDuplicate={onDuplicate}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
      {detailsLoading ? (
        <Dimmer active>
          <Loader size='large' />
        </Dimmer>
      ) : (
        <GenericModal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Content>
            <DetailsModalHeader>
              <h1>{modalTitle}</h1>
            </DetailsModalHeader>
            <StatsProfileAndCampaignTable profileDetails={profileDetails} />
          </Modal.Content>
        </GenericModal>
      )}
    </div>
  );
};

const HideAndShowArchivedCampaignsButton = ({
  archivedCampaigns,
  displayArchived,
  setDisplayArchived,
  t,
}) => {
  if (_.isEmpty(archivedCampaigns)) {
    return null;
  }

  if (displayArchived) {
    return (
      <div className='archived-campaigns-link-box'>
        <button
          type='button'
          className='link-button'
          onClick={() => setDisplayArchived(false)}
        >
          {t('campaigns.buttons.hideArchived')}
        </button>
      </div>
    );
  }

  return (
    <div className='archived-campaigns-link-box'>
      <button
        type='button'
        className='link-button'
        onClick={() => setDisplayArchived(true)}
      >
        {t('campaigns.buttons.showArchived')}
      </button>
    </div>
  );
};

const CampaignsTable = ({ campaigns, clientId, onDuplicate }) => {
  const { t } = useTranslation();
  const [sort, setSort] = useState({ key: 'none', order: 1 });
  const [openedCampaignId, setOpenedCampaignId] = useState(null);
  const [activeCampaignRow, setActiveCampaignRow] = useState('');

  const { key: sortKey, order: sortOrder } = sort;

  const handleToggleSort = ({ key }) => {
    if (sortKey === key) {
      setSort({ key, order: -sortOrder });
    } else {
      setSort({ key, order: 1 });
    }
  };

  const increasingSortedCampaigns = _.sortBy(
    campaigns,
    getComparisonFunction({ sortKey }),
  );
  const sortedCampaigns =
    sortOrder < 0
      ? increasingSortedCampaigns.reverse()
      : increasingSortedCampaigns;
  return (
    <>
      <Table basic className='campaigns-table'>
        <Table.Header className='campaign-table-headers'>
          <Table.Row>
            <Table.HeaderCell>
              <TableHeader
                rowKey='title'
                name={t('campaigns.table.name')}
                sortKey={sortKey}
                sortOrder={sortOrder}
                handleToggleSort={handleToggleSort}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <TableHeader
                rowKey='author'
                name={t('campaigns.table.author')}
                sortKey={sortKey}
                sortOrder={sortOrder}
                handleToggleSort={handleToggleSort}
              />
            </Table.HeaderCell>
            <Table.HeaderCell collapsing />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(sortedCampaigns, (campaign) => (
            <CampaignRow
              key={campaign.id}
              campaign={campaign}
              onDuplicate={onDuplicate}
              clientId={clientId}
              activeCampaignRow={activeCampaignRow}
              setActiveCampaignRow={setActiveCampaignRow}
            />
          ))}
        </Table.Body>
      </Table>
      {openedCampaignId && (
        <NewCampaignModal
          clientId={clientId}
          open
          onClose={() => setOpenedCampaignId(null)}
          editionMode
          campaign={_.findWhere(campaigns, { id: openedCampaignId })}
        />
      )}
    </>
  );
};

const CampaignRow = ({
  campaign,
  activeCampaignRow,
  setActiveCampaignRow,
  onDuplicate,
  clientId,
}) => {
  const { t } = useTranslation();
  const [redirectTo, setRedirectTo] = useState();
  const [archiveCampaign] = useMutation(ARCHIVE_CAMPAIGN);
  const [restoreCampaign] = useMutation(RESTORE_CAMPAIGN);
  const [duplicateCampaign] = useMutation(DUPLICATE_CAMPAIGN);
  const notification = useNotificationSystem();
  const { path, params } = useRouteMatch();

  if (redirectTo) {
    return <Redirect to={redirectTo} push />;
  }

  const handleArchiveCampaign = async ({ id }) => {
    await archiveCampaign({ variables: { input: { id } } });
  };
  const handleRestoreCampaign = async ({ id }) => {
    await restoreCampaign({ variables: { input: { id } } });
  };
  const handleDuplicateCampaign = async ({ id }) => {
    try {
      await duplicateCampaign({ variables: { input: { id } } });
      notification.success(t('campaigns.duplicate.success'));
      if (onDuplicate) {
        onDuplicate();
      }
    } catch (error) {
      notification.error(t('campaigns.duplicate.error'));
    }
  };

  return (
    <Table.Row
      className={classNames('campaign-row', {
        active: campaign?.id === activeCampaignRow,
      })}
      key={campaign.id}
      onClick={() =>
        setRedirectTo(`/client/${clientId}/reveal/campaigns/${campaign?.id}`)
      }
    >
      <Table.Cell textAlign='left'>
        <div className='campaign-header'>
          <i className='ri-stack-line campaign-icon' alt='template-grey' />
          <span className='campaign-header-title'>{campaign?.title}</span>
        </div>
      </Table.Cell>

      <Table.Cell textAlign='left'>
        <div>{getFullname(campaign?.author)}</div>
      </Table.Cell>

      <Table.Cell>
        <div
          className='campaign-actions'
          onClick={(event) => event.stopPropagation()}
        >
          <Popup
            content={
              <div className='campaign-more-actions'>
                <Link
                  className='more-actions-folder'
                  to={generatePath(`${path}/${campaign?.id}/edit`, {
                    ...params,
                  })}
                >
                  <i className='ri-pencil-line ri-lg' />
                  <span>{t('campaigns.buttons.edit')}</span>
                </Link>
                <div
                  className='more-actions-folder'
                  onClick={() => handleDuplicateCampaign(campaign)}
                >
                  <i className='ri-file-copy-2-line ri-lg' />
                  <span>{t('campaigns.buttons.duplicate')}</span>
                </div>
                {!campaign.isArchived ? (
                  <div
                    className='more-actions-folder'
                    onClick={() => handleArchiveCampaign(campaign)}
                  >
                    <i className='ri-archive-line ri-lg' />
                    <span>{t('campaigns.buttons.archive')}</span>
                  </div>
                ) : (
                  <div
                    className='more-actions-folder'
                    onClick={() => handleRestoreCampaign(campaign)}
                  >
                    <i className='ri-inbox-unarchive-line ri-lg' />
                    <span>{t('templates.buttons.restore')}</span>
                  </div>
                )}
              </div>
            }
            on='click'
            basic
            className='campaign-more-actions-popup'
            position='bottom right'
            offset={[0, -2]}
            onClose={() => setActiveCampaignRow('')}
            trigger={
              <i
                onClick={() => setActiveCampaignRow(campaign.id)}
                className='campaign-more-action-btn ri-more-fill ri-lg'
              />
            }
          />
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

const orderClass = (val) => (val > 0 ? 'increasing' : 'decreasing');
const OrderIcon = ({ sortOrder }) => (
  <div className='icon-container'>
    {sortOrder > 0 ? (
      <Icon name='triangle up' />
    ) : (
      <Icon name='triangle down' />
    )}
  </div>
);

export const TableHeader = ({
  rowKey,
  name,
  sortKey,
  sortOrder,
  handleToggleSort,
}) => (
  <div
    className={classNames('button-header', 'sort', {
      [orderClass(sortOrder)]: sortKey === rowKey,
    })}
    onClick={() => handleToggleSort({ key: rowKey })}
  >
    <span className='header-name'>{name}</span>{' '}
    {sortKey === rowKey ? <OrderIcon sortOrder={sortOrder} /> : ''}
  </div>
);

export default CampaignsList;
