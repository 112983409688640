import React, { useMemo, useState, useLayoutEffect } from 'react';
import { Button } from 'semantic-ui-react';
import _ from 'underscore';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';

import { REASONS_REMOVE_FROM_MISSION_OR_SEQUENCE } from '@/common';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useRemoveProfilesFromSequence from '@/graphql/hooks/searchPool/useRemoveProfilesFromSequence';
import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import styles from './RemoveFromSequenceModal.module.less';

const RemoveFromSequenceModal = ({
  sequenceId,
  candidates,
  onCancel,
  // this is to clear the selection of candidates, otherwise the
  // state has some stale data (the sequenceInfo in the profiles)
  onSuccess,
  isProfileView = false,
}) => {
  const { t } = useTranslation();
  const notification = useNotificationSystem();
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();
  const [removeProfilesFromSequence] = useRemoveProfilesFromSequence();
  const [selectedReason, setSelectedReason] = useState(
    'user-interruption__other',
  );

  const onRemoveFromSequence = async () => {
    try {
      const profileIds = _.pluck(candidates, 'id');
      const { data } = await removeProfilesFromSequence({
        profileIds,
        interruptionReason: selectedReason,
      });
      if (sequenceId) {
        publishSubscriptionEvent('onProfilesRemovedFromSequence', {
          profileIds,
          sequenceId,
        });
      }

      const results =
        data?.searchPool?.removeProfilesFromSequence?.results || [];

      const successes = _.filter(results, ({ success }) => success);
      const errors = _.filter(results, ({ success }) => !success);

      if (!_.isEmpty(errors)) {
        throw new Error('Backend error');
      }

      if (!_.isEmpty(successes)) {
        notification.success(
          t('removeFromSequence.success', {
            count: successes.length > 1 ? 2 : 1,
            nb: successes.length,
          }),
        );
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      notification.error(
        t('reveal.candidatesView.actionsBox.removeFromSequenceError'),
      );
    }

    onCancel();
  };

  // remove sequence directly without modal if sequence has pending state
  useLayoutEffect(() => {
    if (candidates.length !== 1) {
      return;
    }
    const profile = candidates[0];
    const currentSequence = _.findWhere(profile?.contactFlow?.sequences || [], {
      isCurrent: true,
    });
    if (!currentSequence?.actions[0]?.completion?.isCompleted) {
      onCancel();
      onRemoveFromSequence();
    }
    // eslint-disable-next-line
  }, [candidates, onCancel]);

  const removeSequenceInProfileViewContent = (
    <div>
      <span className='content-warning'>
        {t('reveal.candidatesView.actionsBox.removeFromSequenceWarning')}
      </span>

      <div className={styles.reasonsLabel}>
        <span className={styles.mainTitle}>
          {t('reveal.candidatesView.actionsBox.reasonsLabel')}
        </span>{' '}
        <span className={styles.subTitle}>{t('common.facultative')}</span>
      </div>

      <div className={styles.reasonsButtons}>
        {REASONS_REMOVE_FROM_MISSION_OR_SEQUENCE.map((reason) => (
          <Button
            key={reason}
            className={classNames(
              styles.button,
              selectedReason === reason && styles.buttonSelected,
            )}
            size='small'
            onClick={() => setSelectedReason(reason)}
          >
            {t(`reveal.candidatesView.actionsBox.sequenceReasons.${reason}`)}
          </Button>
        ))}
      </div>
    </div>
  );

  const removeSequenceGroupedProfiles = (
    <div>
      <span className='content-warning'>
        {t('reveal.candidatesView.actionsBox.removeFromSequenceWarning')}
      </span>
    </div>
  );

  const headerContent = useMemo(() => {
    if (candidates && candidates.length === 1 && candidates[0].resumeData) {
      return `${candidates[0].resumeData.firstname} ${candidates[0].resumeData.lastname}`;
    }
    return t('reveal.candidatesView.actionsBox.selection');
    // eslint-disable-next-line
  }, [candidates]);

  return (
    <ConfirmationModal
      open
      onCancel={onCancel}
      onSubmit={onRemoveFromSequence}
      header={
        sequenceId
          ? `${t('common.remove')} ${headerContent} ${t(
              'reveal.candidatesView.actionsBox.removeFromThisSequence',
            )}`
          : `${t('common.remove')} ${headerContent} ${t(
              'reveal.candidatesView.actionsBox.removeFromASequence',
            )}`
      }
      content={
        isProfileView
          ? removeSequenceInProfileViewContent
          : removeSequenceGroupedProfiles
      }
      submit={t('common.remove')}
      cancel={t('common.cancel')}
      size='tiny'
    />
  );
};

export default RemoveFromSequenceModal;
