import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Modal, Select } from 'semantic-ui-react';
import _ from 'underscore';
import { useMutation } from '@apollo/client';

import useAtsFilterOptions from '@/graphql/hooks/clients/useClientAtsFiltersOptions';
import { ATStype, ATS_NAMES_BY_TYPE } from '@/common/reveal';
import {
  ArchiveReason,
  GET_ARCHIVE_REASONS,
  CREATE_ARCHIVE_REASON,
  UPDATE_ARCHIVE_REASON,
} from '@/graphql/archiveReasons';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import GenericButton from '@/components/Common/GenericButton';
import InfoTooltip from '@/components/Common/InfoTooltip';
import GenericModal from '@/components/Common/GenericModal';

import styles from './CreateArchiveReasonsModal.module.less';

interface CreateArchiveReasonsModalProps {
  clientId: string;
  isOpen: boolean;
  setOpen: (status: boolean) => void;
  selectedReason: ArchiveReason | undefined;
}

const CreateArchiveReasonsModal: React.FC<CreateArchiveReasonsModalProps> = ({
  clientId,
  isOpen,
  setOpen,
  selectedReason,
}) => {
  const { t } = useTranslation();
  const notification = useNotificationSystem();
  const [reason, setReason] = useState<ArchiveReason | any>();
  const [createReason] = useMutation(CREATE_ARCHIVE_REASON, {
    refetchQueries: [
      {
        query: GET_ARCHIVE_REASONS,
        variables: { clientId },
      },
    ],
  });
  const [updateReason] = useMutation(UPDATE_ARCHIVE_REASON, {
    refetchQueries: [
      {
        query: GET_ARCHIVE_REASONS,
        variables: { clientId },
      },
    ],
  });
  const { data: atsFilterOptionsData } = useAtsFilterOptions(clientId);

  const ATSConnector =
    atsFilterOptionsData?.client?.revealProjects?.[0]?.connectors?.[0];
  const connectorAtsType: ATStype = ATSConnector?.type;
  const ATS = ATS_NAMES_BY_TYPE[connectorAtsType];
  const ATSLabels = ATSConnector?.filterOptions?.rejectionReasons;

  useEffect(() => {
    setReason(selectedReason);
  }, [selectedReason]);

  const handleReasonSubmit = () => {
    if (!selectedReason) {
      createReason({
        variables: {
          input: {
            title: reason?.title,
            archiveTags: reason?.archiveTags,
            category: reason?.category,
            linkedATSRejectionReasonId: reason?.linkedATSRejectionReasonId,
            linkedATSRejectionReasonName: reason?.linkedATSRejectionReasonName,
          },
        },
      });
      notification.success(
        t('settings.archiveReasonsSettings.archiveReasonCreated'),
      );
    } else {
      updateReason({
        variables: {
          input: {
            id: reason?.id,
            title: reason?.title,
            archiveTags: reason?.archiveTags,
            category: reason?.category,
            linkedATSRejectionReasonId: reason?.linkedATSRejectionReasonId,
            linkedATSRejectionReasonName: reason?.linkedATSRejectionReasonName,
          },
        },
      });
      notification.success(
        t('settings.archiveReasonsSettings.archiveReasonUpdated'),
      );
    }
    setOpen(false);
  };

  const handleReasonChange = (update: any) => {
    setReason({
      ...reason,
      ...update,
    });
  };

  const canSubmit = reason?.title && reason?.category;

  return (
    <GenericModal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={isOpen}
    >
      <Modal.Header>
        {t(
          `settings.archiveReasonsSettings.form.${
            selectedReason ? 'editTitle' : 'title'
          }`,
        )}
      </Modal.Header>
      <Modal.Content>
        <div className={styles.inputContainer}>
          <div className={styles.inputTitle}>
            {t('settings.archiveReasonsSettings.form.name')}
          </div>
          <div className={styles.input}>
            <Input
              fluid
              placeholder={t(
                'settings.archiveReasonsSettings.form.namePlaceholder',
              )}
              value={reason?.title || ''}
              onChange={(_e, data) => handleReasonChange({ title: data.value })}
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputTitle}>
            {t('settings.archiveReasonsSettings.form.category')}
          </div>
          {selectedReason && (
            <div className={styles.inputSubtitle}>
              {t('settings.archiveReasonsSettings.form.categoryDetails')}
            </div>
          )}
          <div className={styles.input}>
            <Select
              disabled={!!selectedReason}
              fluid
              placeholder={t(
                'settings.archiveReasonsSettings.form.categoryPlaceholder',
              )}
              value={reason?.category}
              selectOnBlur={false}
              onChange={(_e, data) =>
                handleReasonChange({ category: data.value })
              }
              options={_.map(
                [
                  'not-contacted',
                  'no-answer',
                  'profile-not-interested',
                  'profile-interested;they-rejected-us',
                  'profile-interested;we-rejected-them',
                  'profile-interested;other-archive-reason',
                ],
                (value) => ({
                  key: value,
                  value,
                  text: t(
                    `settings.archiveReasonsSettings.categories.${value}`,
                  ),
                }),
              )}
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputTitle}>
            {t('settings.archiveReasonsSettings.form.labels')}
            <InfoTooltip>
              {t('settings.archiveReasonsSettings.form.labelsDetails')}
            </InfoTooltip>
          </div>
          <div className={styles.input}>
            <Select
              fluid
              placeholder={t(
                'settings.archiveReasonsSettings.form.labelsPlaceholder',
              )}
              multiple
              selectOnBlur={false}
              value={reason?.archiveTags || []}
              onChange={(_e, data) =>
                handleReasonChange({ archiveTags: data.value })
              }
              options={_.map(
                [
                  'not-a-fit',
                  'not-responsive',
                  'keep-warm',
                  'offer-declined',
                  'location-mismatch',
                  'salary-mismatch',
                ],
                (value) => ({
                  key: value,
                  value,
                  text: t(
                    `settings.archiveReasonsSettings.archiveTags.${value}`,
                  ),
                }),
              )}
            />
          </div>
        </div>
        {ATS && (
          <div className={styles.inputContainer}>
            <div className={styles.inputTitle}>
              {t('settings.archiveReasonsSettings.form.atsReason', { ATS })}
              <InfoTooltip>
                {t('settings.archiveReasonsSettings.form.atsReasonDetails', {
                  ATS,
                })}
              </InfoTooltip>
            </div>
            <div className={styles.input}>
              <Select
                fluid
                placeholder={t(
                  'settings.archiveReasonsSettings.form.atsReasonPlaceholder',
                  { ATS },
                )}
                selectOnBlur={false}
                clearable
                value={reason?.linkedATSRejectionReasonId}
                onChange={(_e, data) => {
                  handleReasonChange({
                    linkedATSRejectionReasonName:
                      _.find(
                        data.options as any[],
                        (option) => option.key === data.value,
                      )?.text || null,
                    linkedATSRejectionReasonId: data.value || null,
                  });
                }}
                options={_.map(ATSLabels, (label) => {
                  return {
                    key: label.id,
                    value: label.id,
                    text: label.name,
                  };
                })}
              />
            </div>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <div className={styles.modalActions}>
          <GenericButton
            size='big'
            primacy='secondary'
            onClick={() => setOpen(false)}
          >
            {t('settings.archiveReasonsSettings.form.cancel')}
          </GenericButton>
          <GenericButton
            size='big'
            disabled={!canSubmit}
            onClick={() => handleReasonSubmit()}
          >
            {t(
              `settings.archiveReasonsSettings.form.${
                selectedReason ? 'edit' : 'create'
              }`,
            )}
          </GenericButton>
        </div>
      </Modal.Actions>
    </GenericModal>
  );
};

export default CreateArchiveReasonsModal;
