import React, { FC, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Offer } from '@/types/offer';
import { getFullname } from '@/common/helpers/person';
import useClientId from '@/hooks/router/useClientId';
import logAction from '@/common/logAction';
import ChangeOfferStateModalWrapper from '../../../components/ChangeOfferStateModalWrapper';

import styles from './OwnerLabel.module.less';

interface OwnerLabelProps {
  offer: Offer;
}

const OwnerLabel: FC<OwnerLabelProps> = ({ offer }) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const [modalType, setModalType] = useState<string>();
  const hasOwner = !!offer.owner?.email;

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (offer?.id) setModalType('organizeJob');
    logAction({ type: 'click-offer-tag-organizeJob' });
  };

  return (
    <>
      <button type='button' className={styles.label} onClick={handleClick}>
        {hasOwner
          ? t('offers.owner', { name: getFullname(offer.owner) })
          : t('offers.hasNoOwner')}
      </button>
      <button
        type='button'
        style={{ position: 'absolute' }}
        // This button prevents the offer page from opening when the user exits the modal.
        onClick={(e) => e.stopPropagation()}
      >
        <ChangeOfferStateModalWrapper
          offer={offer}
          clientId={clientId}
          modalType={modalType}
          onCloseModal={() => setModalType(undefined)}
        />
      </button>
    </>
  );
};

export default OwnerLabel;
