import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

const getProfilesWithLastMessageInfo = gql`
  query getProfilesWithLastMessageInfo(
    $searchPoolId: ID!
    $input: ProfilesByInboxInput
  ) {
    searchPool(id: $searchPoolId) {
      id
      profilesByInbox(input: $input) {
        id
        resumeData {
          firstname
          lastname
          headline {
            content {
              text
            }
          }
          photoLink
        }
        lastMessageInfo {
          timestamp
          type
        }
      }
    }
  }
`;

export type ProfileWithLastMessageInfo = {
  id: string;
  resumeData?: {
    firstname?: string;
    lastname?: string;
    headline?: {
      content: {
        text: string;
      };
    };
    photoLink?: string;
  };
  lastMessageInfo?: {
    timestamp?: number;
    type?: string;
  };
};

type Data = {
  searchPool?: {
    id: string;
    profilesByInbox?: ProfileWithLastMessageInfo[];
  };
};

type Variables = {
  searchPoolId: string;
  input?: {
    missionIds?: string[];
    profileName?: string;
  };
};

type Input = {
  searchPoolId?: string;
  queryOptions?: QueryHookOptions<Data, Variables>;
};

function useProfilesWithLastMessageInfo({
  searchPoolId = 'reveal',
  queryOptions = {},
}: Input = {}) {
  const [query, result] = useLazyQuery<Data, Variables>(
    getProfilesWithLastMessageInfo,
    queryOptions,
  );

  const fetch = useCallback(
    async (input: { missionIds?: string[]; profileName?: string }) => {
      const queryResult = await query({
        variables: {
          searchPoolId,
          input,
        },
      });
      return enrichResult(queryResult);
    },
    [query, searchPoolId],
  );

  return [fetch, enrichResult(result)] as const;
}

function enrichResult(result: QueryResult<Data, Variables>) {
  const profilesWithLastMessageInfo =
    result.data?.searchPool?.profilesByInbox || [];
  return { ...result, profilesWithLastMessageInfo };
}

export default useProfilesWithLastMessageInfo;
