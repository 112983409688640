import React, { useMemo } from 'react';
import _ from 'underscore';
import { DataVizColors, taskTypeColors } from '@/less/colors';
import { ClientDailyEvent } from '@/types/statistics/clientStatistics';
import { TimeSpan } from '../RevealAnalyticsHeader/RevealAnalyticsHeader';
import { ChartType, Frequency, Mode } from '../GraphWrapper/types';
import { convertClientDailyEventByTaskType } from './helpers';
import DailyEventsDataContainer from '../DailyEventsDataContainer/DailyEventsDataContainer';

export const STACK = [
  {
    datakey: 'nbSendEmail',
    color: taskTypeColors.email,
    i18nKey: 'reveal.analyticsView.tasks.chart.legend.nbSendEmail',
  },
  {
    datakey: 'nbMakePhoneCall',
    color: taskTypeColors.call,
    i18nKey: 'reveal.analyticsView.tasks.chart.legend.nbMakePhoneCall',
  },
  {
    datakey: 'nbManual',
    color: taskTypeColors.manual,
    i18nKey: 'reveal.analyticsView.tasks.chart.legend.nbManual',
  },
  {
    datakey: 'nbLinkedinSendInMail',
    color: DataVizColors[6],
    i18nKey: 'reveal.analyticsView.tasks.chart.legend.nbLinkedinSendInMail',
  },
  {
    datakey: 'nbLinkedinSendMessage',
    color: taskTypeColors.linkedInMessage,
    i18nKey: 'reveal.analyticsView.tasks.chart.legend.nbLinkedinSendMessage',
  },
  {
    datakey: 'nbLinkedinRecruiterSendInMail',
    color: taskTypeColors.linkedInRecruiterInMail,
    i18nKey:
      'reveal.analyticsView.tasks.chart.legend.nbLinkedinRecruiterSendInMail',
  },
  {
    datakey: 'nbLinkedinSalesNavigatorSendInMail',
    color: taskTypeColors.linkedInSalesNavigatorInMail,
    i18nKey:
      'reveal.analyticsView.tasks.chart.legend.nbLinkedinSalesNavigatorSendInMail',
  },
  {
    datakey: 'nbLinkedinSendRequest',
    color: taskTypeColors.linkedInInvitation,
    i18nKey: 'reveal.analyticsView.tasks.chart.legend.nbLinkedinSendRequest',
  },
  {
    datakey: 'nbLinkedinCheckRequest',
    color: taskTypeColors.linkedInInvitationChecked,
    i18nKey: 'reveal.analyticsView.tasks.chart.legend.nbLinkedinCheckRequest',
  },
  {
    datakey: 'nbAddEvaluation',
    color: DataVizColors[7],
    i18nKey: 'reveal.analyticsView.tasks.chart.legend.nbAddEvaluation',
  },
  {
    datakey: 'nbSendTextMessage',
    color: taskTypeColors.sms,
    i18nKey: 'reveal.analyticsView.tasks.chart.legend.nbSendTextMessage',
  },
  {
    datakey: 'nbReviewProfile',
    color: DataVizColors[8],
    i18nKey: 'reveal.analyticsView.tasks.chart.legend.nbReviewProfile',
  },
];

interface CompletedTasksDataContainerProps {
  frequency: Frequency;
  dateRange: TimeSpan;
  type: ChartType;
  mode: Mode;
  clientDailyEvents: ClientDailyEvent[];
  fetchDetails?: (startDay: string, endDay: string, taskType: string) => void;
}

const CompletedTasksDataContainer: React.FC<CompletedTasksDataContainerProps> = ({
  clientDailyEvents,
  frequency,
  dateRange,
  mode,
  type,
  fetchDetails,
}) => {
  const dailyEvents = useMemo(
    () => _.map(clientDailyEvents, convertClientDailyEventByTaskType),
    [clientDailyEvents],
  );

  return (
    <DailyEventsDataContainer
      frequency={frequency}
      dateRange={dateRange}
      type={type}
      mode={mode}
      dailyEvents={dailyEvents}
      stack={STACK}
      fetchDetails={fetchDetails}
    />
  );
};

export default CompletedTasksDataContainer;
