import _ from 'underscore';
import { getTranslatedText } from '../../common';
import { sweetDateToMomentDate } from '../../common/dates';

export const getProfileBlurb = ({ profile }) => {
  const review = getReview({ profile });
  return getTranslatedText(getFirstLineOnlyReview({ review }));
};

export const getReview = ({ profile }) => {
  if (!profile) {
    return null;
  }
  const smartSummary = profile?.resumeData?.smartSummary;
  return _.find(smartSummary?.arguments, { type: 'review' });
};

export const getFirstLineOnlyReview = ({ review }) => {
  if (!review || !review.content) {
    return null;
  }
  const firstLineOnlyReview = _.mapObject(review.content, (value) => {
    if (!value) {
      return null;
    }
    return (value.split('\n')[0] || '').trim();
  });
  if (!_.some(firstLineOnlyReview, (value) => !!value)) {
    return null;
  }
  return firstLineOnlyReview;
};

export const getHeadline = ({ profile }) => {
  return profile?.resumeData?.headline?.content?.text;
};

export const getExperienceDetail = ({ profile }) => {
  const mainExperience = _.first(profile?.resumeData?.experiences);
  return getDisplayedExperience(mainExperience)?.titleText;
};

export const getDisplayedExperience = (experience) => {
  if (!experience) {
    return null;
  }
  const { title, companyName /* , startDate, endDate */ } = experience;
  // const dateDiff = getDateDiffInMs(startDate, endDate || new Date().toISOString());
  // const duration = getDurationInYearsAndMonths({ durationInMs: dateDiff, t });
  return {
    titleText: _.compact([title?.text, companyName]).join(' • '),
    // duration,
  };
};

export const getMainEducation = ({ profile }) => {
  const { mainEducation, education } = profile?.resumeData;
  const educationToDisplay = mainEducation || (education && education[0]) || null;
  const schoolName = educationToDisplay?.schoolName || null;
  const educationEndDate = sweetDateToMomentDate(educationToDisplay?.endDate);

  if (!schoolName) {
    return '';
  }

  if (!educationEndDate) {
    return schoolName;
  }

  return `${schoolName} (${educationEndDate.fromNow()})`;
};
