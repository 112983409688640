import { RevealProject } from '@/common/reveal';
import getFilterOptions, { FilterOptions } from '../Filters/options';

const getApplicationStatusOptions = () => {
  return [];
};

const getRecruitcrmOptions = ({
  projectId,
  projectsWithFilterOptions,
}: {
  projectId: string;
  projectsWithFilterOptions: RevealProject[];
}): FilterOptions => {
  return getFilterOptions({
    connectorType: 'recruitcrm',
    projectId,
    projectsWithFilterOptions,
    getApplicationStatusOptions,
  });
};

export default getRecruitcrmOptions;
