import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Segment } from 'semantic-ui-react';
import _ from 'underscore';

import useInterestedSubPipeline from '@/graphql/hooks/searchPoolJob/useInterestedSubPipeline';
import useSegmentCounts from '@/graphql/hooks/searchPoolJob/useSegmentCounts';
import usePipelineSubSteps from '@/graphql/hooks/clients/usePipelineSubSteps';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import {
  MissionInterestedSubStep,
  useMissionInterestedSubStepsMutation,
} from '../../../../graphql/hooks/clients/useClientMissionInterestedSubSteps';
import useMissionSegmentations from '../JobView/ProjectProfilesTab/useMissionSegmentations';
import JobPipelineEditionModal from './JobPipelineEditionModal';
import JobPipelineTable from './JobPipelineTable';

import styles from './JobPipelineView.module.less';

interface JobPipelineViewProps {
  jobId: string;
}

const JobPipelineView: React.FC<JobPipelineViewProps> = ({ jobId }) => {
  const { t } = useTranslation();
  const missionCategoryType = useContext(MissionCategoryContext);

  const [steps, setSteps] = useState<MissionInterestedSubStep[]>([]);
  const [editionModalOpen, setEditionModalOpen] = useState(false);
  const { pipelineSubSteps } = usePipelineSubSteps();
  const subStepsDefinition = useMemo(
    () =>
      _.find(
        pipelineSubSteps,
        ({ missionCategory, stepId }) =>
          missionCategory.type === missionCategoryType &&
          stepId === 'interested',
      ),
    [pipelineSubSteps, missionCategoryType],
  );
  const clientMissionInterestedSubSteps = useMemo(
    () => subStepsDefinition?.subSteps || [],
    [subStepsDefinition],
  );
  const missionSegmentations = useMissionSegmentations(jobId, false, true);

  const { segmentCounts } = useSegmentCounts({
    missionId: jobId,
    segmentationId: 'D2KD9DW3',
    segmentDefinitions: missionSegmentations[0].segments.map((s) => ({
      id: s.id,
      type: s.type,
    })),
  });

  const { interestedSubPipeline } = useInterestedSubPipeline({
    missionId: jobId,
  });

  const missionInterestedSubSteps = useMemo(
    () =>
      _.map(interestedSubPipeline?.steps || [], (step) => ({
        ...step,
        title: {
          default: step.title,
        },
        ...(step.isDisabled && { isDisabled: true }),
      })),
    [interestedSubPipeline],
  );

  const [updatePipelineSteps] = useMissionInterestedSubStepsMutation();

  const onSaveSteps = useCallback(() => {
    updatePipelineSteps({
      variables: {
        input: {
          steps,
          missionId: jobId,
        },
        searchPoolId: 'reveal',
      },
    });
  }, [jobId, steps, updatePipelineSteps]);

  useEffect(() => {
    if (_.isEmpty(steps) && missionInterestedSubSteps) {
      setSteps(missionInterestedSubSteps);
    }
    // eslint-disable-next-line
  }, [missionInterestedSubSteps]);

  const clientStepsWithMissionSteps = useMemo(() => {
    let res = clientMissionInterestedSubSteps;
    for (let i = 0; i < steps.length; i++) {
      const idx = _.findIndex(
        res,
        (clientStep) => clientStep.id === steps[i].previousStepId,
      );
      if (idx !== -1) {
        res = [...res.slice(0, idx + 1), steps[i], ...res.slice(idx + 1)];
      } else if (steps[i].previousStepId === null) {
        res = [steps[i], ...res];
      }
    }
    return res;
  }, [steps, clientMissionInterestedSubSteps]);

  return (
    <div className='mission-interested-substeps-tab'>
      <Segment className='pure-criteria-form'>
        <h2>{t('reveal.missions.mission.settingsTab.pipeline.title')}</h2>
        <div className={styles.hint}>
          <span>{t(`reveal.missions.mission.settingsTab.pipeline.hint`)}</span>
        </div>

        <div className={styles.tableHeader}>
          <h3>
            {t('reveal.missions.mission.settingsTab.pipeline.table.steps')}
          </h3>
        </div>
        <JobPipelineTable
          jobId={jobId}
          onSaveSteps={onSaveSteps}
          onOpen={setEditionModalOpen}
        />
        {editionModalOpen && (
          <JobPipelineEditionModal
            open={editionModalOpen}
            setOpen={setEditionModalOpen}
            clientSubSteps={clientStepsWithMissionSteps}
            missionSubSteps={steps}
            onUpdateSteps={setSteps}
            onSaveSteps={onSaveSteps}
            segmentCounts={segmentCounts}
          />
        )}
      </Segment>
    </div>
  );
};

export default JobPipelineView;
