import { textToId } from '@/common';
import _ from 'underscore';
import listFrenchNames from '../../../../../common/frenchNames.json';

const lowerCaseAndUnaccent = (string) => {
  const lowerCaseString = _.isString(string) && (string || '').toLowerCase();
  const unaccentString =
    lowerCaseString &&
    lowerCaseString.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  return unaccentString;
};

const getWordsInArray = (string) => {
  if (!string || !_.isString(string)) {
    return [];
  }
  const stringWithoutPonctuation = string
    .replace(',', ' ')
    .replace('.', ' ')
    .replace(';', ' ')
    .replace('!', ' ')
    .replace('?', ' ')
    .replace(':', ' ');
  return stringWithoutPonctuation.split(' ');
};

export const getNamesInFirstLine = (body, firstname) => {
  const regexFirstLine = /<p>(.*?)<\/p>/i;
  const firstLineInTagRegex = _.isString(body) && regexFirstLine.exec(body);
  const firstLineInTag =
    firstLineInTagRegex &&
    firstLineInTagRegex.length > 1 &&
    firstLineInTagRegex[1];
  // not more than 30 chars, otherwise we can catch the recruiter name (ex: adotmob data engineer)
  const firstLine = firstLineInTag || (_.isString(body) && body.slice(0, 30));
  const normalizedFirstLine = lowerCaseAndUnaccent(firstLine);
  const normalizedFirstname = lowerCaseAndUnaccent(firstname);
  const firstlineWords = getWordsInArray(normalizedFirstLine);
  const namesInFirstLine = _.reduce(
    listFrenchNames,
    (memo, name) => {
      const normalizedName = lowerCaseAndUnaccent(name);
      const isNotProfileFirstName = normalizedName !== normalizedFirstname;
      // eslint-disable-next-line max-len
      const isNameInFirstLine =
        isNotProfileFirstName && _.indexOf(firstlineWords, normalizedName) > -1;
      const isNotSubName =
        isNameInFirstLine && normalizedFirstname.indexOf(normalizedName) === -1;
      return isNotSubName ? [...memo, name] : memo;
    },
    [],
  );
  return namesInFirstLine;
};

export const getOfferSenderAssignedByRecruiter = ({ offer }) => {
  const offerConfigs = offer?.recruiterConfigurations || [];
  return (offerConfigs[0] || {}).assignedSender;
};

export const userCanUseSender = ({ userEmail, sender }) => {
  const { owner, users, mailAccount } = sender || {};
  const userIsOwner = (owner || {}).email === userEmail;
  const userIsAllowed = _.findWhere(users, { email: userEmail });
  const mailAccountExists = !_.isEmpty(mailAccount);
  return mailAccountExists && (userIsOwner || userIsAllowed);
};

export const getThreadId = ({
  shouldUseThread,
  targetThread,
  currentAddress,
  profile,
}) => {
  if (!shouldUseThread) {
    return null;
  }
  if (targetThread?.id) {
    return targetThread?.id;
  }
  const threads = [...profile?.contactData?.threads] || [];
  threads.reverse(); // reverse the array so that findWhere finds the last thread pushed
  let currentAddressId = '';
  try {
    currentAddressId = textToId(currentAddress);
    const threadId = (
      _.find(threads, (thread) => {
        return (
          thread.mailAccountId === currentAddressId &&
          // prevent using threads if there are any bouncing emails on it
          // and if the email address changed we need to change the thread id anyway
          _.isEmpty(thread.bouncingEmails)
        );
      }) || {}
    ).id;
    return threadId || null;
  } catch (error) {
    console.error('Error parsing currentAddress', error);
  }
  return null;
};
