import React, { FC, useMemo } from 'react';
import _ from 'underscore';
import { ClientMissionDailyEvent } from '@/types/statistics/clientStatistics';
import { TimeSpan } from '../components/RevealAnalyticsHeader/RevealAnalyticsHeader';
import { ChartType, Frequency, Mode } from '../components/GraphWrapper/types';
import * as colors from '../../../../less/colors';
import DailyEventsDataContainer from '../components/DailyEventsDataContainer/DailyEventsDataContainer';
import { convertClientMissionDailyEvent } from './ContactsStatistics/helpers';

const STACK = [
  {
    datakey: 'nbSourced',
    color: colors.SecondaryBlue,
    i18nKey: 'reveal.analyticsView.missions.sourced',
    hideFromLegend: true,
  },
];

interface MissionsDataContainerProps {
  frequency: Frequency;
  dateRange: TimeSpan;
  type: ChartType;
  mode: Mode;
  missionDailyEvents: ClientMissionDailyEvent[];
  fetchDetails: (startDay: string, endDay: string) => void;
}

const MissionsDataContainer: FC<MissionsDataContainerProps> = ({
  missionDailyEvents,
  frequency,
  dateRange,
  mode,
  type,
  fetchDetails,
}) => {
  const dailyEvents = useMemo(
    () => _.map(missionDailyEvents, convertClientMissionDailyEvent),
    [missionDailyEvents],
  );

  return (
    <DailyEventsDataContainer
      dailyEvents={dailyEvents}
      frequency={frequency}
      dateRange={dateRange}
      mode={mode}
      type={type}
      stack={STACK}
      fetchDetails={fetchDetails}
    />
  );
};

export default MissionsDataContainer;
