import React, { FC } from 'react';
import classNames from 'classnames';

import Tag from '@/components/Common/Tag';

import styles from './GenericATSTitle.module.less';

interface GenericATSTitleProps {
  title?: string | null;
  description?: string | null;
  tagLabel?: string | null;
}

const GenericATSTitle: FC<GenericATSTitleProps> = ({
  title,
  description,
  tagLabel,
}) => {
  return (
    <div className={styles.applicationTitle}>
      {title && <span className={styles.applicationTitleParts}>{title}</span>}
      {description && (
        <span
          className={classNames(
            styles.applicationTitleStage,
            styles.applicationTitleParts,
          )}
        >
          {description}
        </span>
      )}
      {tagLabel && <Tag color='grey'>{tagLabel}</Tag>}
    </div>
  );
};

export default GenericATSTitle;
