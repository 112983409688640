import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { useMutation } from '@apollo/client';
import { query as ClientTemplatesQuery } from './withClientTemplatesAndOffers';

export const mutation = gql`
  mutation duplicateTemplate($id: ID!) {
    duplicateTemplate(id: $id) {
      id
    }
  }
`;

export const useDuplicateTemplate = (clientId) => {
  const [mutationFunction] = useMutation(mutation, {
    refetchQueries: [
      {
        query: ClientTemplatesQuery,
        variables: { clientId },
      },
    ],
  });
  return mutationFunction;
};

export default graphql(mutation, {
  props: ({ ownProps, mutate }) => ({
    duplicateTemplate: ({ id }) =>
      mutate({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: ClientTemplatesQuery,
            variables: { clientId: ownProps.clientId },
          },
        ],
      }),
  }),
});
