import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const mutation = gql`
  mutation unHoldOffer($id: ID!) {
    unHoldOffer(id: $id) {
      id
      isHold
      instantFlowPipeDescriptor {
        uploadStatus
      }
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    unHoldOffer: ({ id }) => mutate({ variables: { id } }),
  }),
});
