import React, { useCallback, useMemo, useState } from 'react';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import { getFullname } from '@/common/helpers/person';
import useClientDailyEvents from '@/graphql/hooks/clients/useClientDailyEvents';
import useClientDailyEventsDetails from '@/graphql/hooks/clients/useClientDailyEventsDetails';
import InfoTooltip from '@/components/Common/InfoTooltip';
import GenericModal from '@/components/Common/GenericModal';
import GraphWrapper from '../GraphWrapper/GraphWrapper';
import { ChartType, Frequency, Mode } from '../GraphWrapper/types';
import { TimeSpan } from '../RevealAnalyticsHeader/RevealAnalyticsHeader';
import CompletedTasksDataContainer, {
  STACK,
} from './CompletedTasksDataContainer';
import CompletedTasksByUserDataContainer from './CompletedTasksByUserDataContainer';
import TasksByUserDataContainer from './TasksByUserDataContainer';
import { TaskDetail } from '../StatsProfileAndTaskTable/types';
import StatsProfileAndTaskTable from '../StatsProfileAndTaskTable';
import { filterEventsByDateRange } from '../../stats.helpers';
import DetailsModalHeader from '../DetailsModalHeader';

import './CompletedTasks.css';

interface CompletedTasksProps {
  clientId: string;
  timeSpan: TimeSpan;
  selectedSequenceIds: string[];
  userEmails: string[];
}

interface StatsFilters {
  authorEmail?: string;
  startDay?: string;
  endDay?: string;
  taskType?: string;
}

const CompletedTasks: React.FC<CompletedTasksProps> = ({
  clientId,
  timeSpan,
  selectedSequenceIds,
  userEmails,
}) => {
  const { t, i18n } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [filters, setFilters] = useState<StatsFilters | undefined>(undefined);

  const { dailyEvents, loading } = useClientDailyEvents({
    clientId,
    sequenceIds: selectedSequenceIds,
    userEmails,
    queryOptions: { fetchPolicy: 'network-only' },
  });
  const [
    fetchDetails,
    { dailyEvents: dailyEventDetails, loading: detailsLoading },
  ] = useClientDailyEventsDetails();

  const applyFilters = useCallback(
    (newFilters: StatsFilters) => {
      setModalOpen(true);
      setFilters(newFilters);
      fetchDetails({
        clientId,
        ...(newFilters?.authorEmail
          ? { userEmails: [newFilters.authorEmail] }
          : {}),
      });
    },
    [setModalOpen, setFilters, fetchDetails, clientId],
  );

  const authorFullnameMap = useMemo(() => {
    const result: Record<string, string> = {};

    _.each(dailyEvents || [], ({ userStats }) => {
      _.each(userStats, ({ author }) => {
        const { email } = author;
        if (!result[email]) {
          result[email] = getFullname(author);
        }
      });
    });

    return result;
  }, [dailyEvents]);

  const taskDetails = useMemo(() => {
    const eventsInRange = filterEventsByDateRange(
      timeSpan,
      dailyEventDetails || [],
    );

    if (!filters) {
      return [];
    }
    const { startDay, endDay, authorEmail, taskType } = filters;

    const result: TaskDetail[] = [];

    _.each(eventsInRange, ({ day, userStats }) => {
      if ((startDay && day < startDay) || (endDay && day >= endDay)) {
        return;
      }
      _.each(userStats, ({ author, stats }) => {
        if (authorEmail && author.email !== authorEmail) {
          return;
        }
        _.each(stats, ({ type, profileItems }) => {
          if (taskType && type !== taskType) {
            return;
          }
          _.each(profileItems, ({ profileId }) => {
            result.push({ profileId, day, author, type });
          });
        });
      });
    });

    return result;
  }, [filters, dailyEventDetails, timeSpan]);

  const modalTitle = useMemo(() => {
    const titleParts = [] as string[];
    if (filters?.taskType) {
      titleParts.push(
        t(`reveal.analyticsView.tasks.chart.legend.${filters.taskType}`),
      );
    }
    if (filters?.authorEmail) {
      titleParts.push(authorFullnameMap[filters.authorEmail]);
    }
    return titleParts.join(' - ');
  }, [authorFullnameMap, filters, t]);

  const modalSubtitle = useMemo(() => {
    if (!filters?.startDay || !filters?.endDay) {
      return '';
    }
    const dateDisplayOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    const dayToLocaleString = (day: string): string =>
      new Date(day).toLocaleString(i18n.resolvedLanguage, dateDisplayOptions);

    return t('reveal.reports.missions.moveTo.detailsModal.subtitle', {
      startDate: dayToLocaleString(filters.startDay),
      endDate: dayToLocaleString(filters.endDay),
    });
  }, [filters, i18n, t]);

  const tooltipCommonFragment = useMemo(
    () => (
      <>
        <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
        <p>
          {t('reveal.reports.tooltips.tasks.common.countedTasksParagraph')}
          <ul>
            {_.map(STACK, ({ datakey, i18nKey }) => (
              <li key={datakey}>{t(i18nKey)}</li>
            ))}
          </ul>
        </p>
        <p>
          {t('reveal.reports.tooltips.tasks.common.uncountedTasks.paragraph')}
          <ul>
            <li>
              {t(
                'reveal.reports.tooltips.tasks.common.uncountedTasks.automaticEmail',
              )}
            </li>
            <li>
              {t(
                'reveal.reports.tooltips.tasks.common.uncountedTasks.loggedReply',
              )}
            </li>
            <li>
              {t(
                'reveal.reports.tooltips.tasks.common.uncountedTasks.loggedActivityOutside',
              )}
            </li>
            <li>
              {t(
                'reveal.reports.tooltips.tasks.common.uncountedTasks.loggedLinkedInOutside',
              )}
            </li>
          </ul>
        </p>
        <p>{t('reveal.reports.tooltips.tasks.common.timeParagraph')}</p>
        <h2>{t('reveal.reports.tooltips.common.details')}</h2>
        <p>{t('reveal.reports.tooltips.common.barDetails')}</p>
      </>
    ),
    [t],
  );

  return (
    <div className='tasks-tab'>
      <div className='tasks-cohorts-graph'>
        <div className='header'>
          <h3 className='title'>
            {t('reveal.reports.tasks.typeByUser')}
            <InfoTooltip rich hoverable position='right center'>
              <h1>{t('reveal.reports.tooltips.tasks.typeByUser.title')}</h1>
              {tooltipCommonFragment}
            </InfoTooltip>
          </h3>
        </div>
        {!loading && (
          <TasksByUserDataContainer
            dateRange={timeSpan}
            dailyEvents={dailyEvents ?? []}
            fetchDetails={(authorEmail, taskType) =>
              applyFilters({ authorEmail, taskType })
            }
          />
        )}
        {loading && (
          <Loader className='loader' active inline='centered' size='large' />
        )}
      </div>
      <div className='tasks-cohorts-graph'>
        <GraphWrapper
          timeSpan={timeSpan}
          title={
            <h3 className='title'>
              {t('reveal.reports.tasks.completedByUser')}
              <InfoTooltip rich hoverable position='right center'>
                <h1>
                  {t('reveal.reports.tooltips.tasks.completedByUser.title')}
                </h1>
                {tooltipCommonFragment}
              </InfoTooltip>
            </h3>
          }
          renderChildren={(frequency: Frequency, mode: Mode, type: ChartType) =>
            loading ? (
              <Loader
                className='loader'
                active
                inline='centered'
                size='large'
              />
            ) : (
              <CompletedTasksByUserDataContainer
                dateRange={timeSpan}
                frequency={frequency}
                clientDailyEvents={dailyEvents ?? []}
                mode={mode}
                type={type}
                fetchDetails={(startDay, endDay, authorEmail) =>
                  applyFilters({ startDay, endDay, authorEmail })
                }
              />
            )
          }
        />
      </div>
      <div className='tasks-cohorts-graph'>
        <GraphWrapper
          timeSpan={timeSpan}
          title={
            <h3 className='title'>
              {t('reveal.reports.tasks.completedByType')}
              <InfoTooltip rich hoverable position='right center'>
                <h1>
                  {t('reveal.reports.tooltips.tasks.completedByType.title')}
                </h1>
                {tooltipCommonFragment}
              </InfoTooltip>
            </h3>
          }
          renderChildren={(frequency: Frequency, mode: Mode, type: ChartType) =>
            loading ? (
              <Loader
                className='loader'
                active
                inline='centered'
                size='large'
              />
            ) : (
              <CompletedTasksDataContainer
                dateRange={timeSpan}
                frequency={frequency}
                clientDailyEvents={dailyEvents ?? []}
                mode={mode}
                type={type}
                fetchDetails={(startDay, endDay, taskType) =>
                  applyFilters({ startDay, endDay, taskType })
                }
              />
            )
          }
        />
      </div>
      {detailsLoading ? (
        <Dimmer active>
          <Loader size='large' />
        </Dimmer>
      ) : (
        <GenericModal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Content>
            <DetailsModalHeader>
              {modalTitle && <h1>{modalTitle}</h1>}
              {modalSubtitle && <h2>{modalSubtitle}</h2>}
            </DetailsModalHeader>
            <StatsProfileAndTaskTable taskDetails={taskDetails} />
          </Modal.Content>
        </GenericModal>
      )}
    </div>
  );
};

export default CompletedTasks;
