import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import * as Sentry from '@sentry/browser';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';
import _ from 'underscore';
import classnames from 'classnames';
import { getWeekNumber } from '@/common/dates';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import { useEffectOnce } from '@/hooks/common/useOnce';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import { useSearchPoolJobProfileResults } from '@/graphql/searchPoolJobProfiles';
import useUserTableViewPreferences from '@/graphql/hooks/users/useUserDisplayPreferences';
import useQueryParams from '@/hooks/router/useQueryParams';
import { SegmentationLine } from '@/components/PipelineSegmentation/SegmentationLine';
import useSegmentCounts, {
  ExtraFilters,
} from '@/graphql/hooks/searchPoolJob/useSegmentCounts';
import { useClientCurrentAtsId } from '@/graphql/hooks/clients/useClientRevealProjects';
import EnrichedRevealProfileModal from '@/revealComponents/EnrichedRevealProfileModal';
import useUpdateProfilesPipelineStage from '@/graphql/hooks/searchPoolJob/useUpdateProfilesMissionPipelineStage';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import MoveToStageAction from '@/components/PipelineSegmentation/MoveToStageAction';
import ImportCandidatesDropdown from '@/revealComponents/Buttons/ImportCandidatesDropdown';
import useDataUpdateSubscription from '@/graphql/dataUpdateSubscription/useDataUpdateSubscription';
import GenericTooltip from '@/components/Common/GenericTooltip';
import ButtonDropdown from '@/components/Common/ButtonDropdown';
import ProfileKanbanContainer from '@/routes/RevealView/WidgetPlayground/ProfileKanbanContainer';
import MissionProfilesGenericView from '@/routes/RevealView/MissionProfilesGenericView';
import { Segment } from '@/components/PipelineSegmentation/pipelineSegmentation';
import Segmentations from '@/components/PipelineSegmentation/data';
import ProfileIdHandler from '@/common/ProfileIdHandler';
import useProjectNomenclatures from '@/graphql/hooks/clients/useProjectNomenclatures';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import { getCriteriaFromSettings } from '@/context/ProfilesViewSettingsContext/helpers';
import ProfilesViewSettingsContext from '@/context/ProfilesViewSettingsContext';

import ProjectProfilesViewSettingsHeader from './ProjectProfilesViewSettingsHeader';
import ProfilesTable, { MISSION_PROFILES_TABLE_ID } from '../../ProfilesTable';
import ActionsDropdown from '../../../revealComponents/Buttons/ActionsDropdown';
import useCandidateSelection from '../../../SearchView/CandidatesListPane/useCandidateSelection';
import AddToSequenceButton from '../../../SearchView/CandidatesListPane/AddToSequenceButton';
import useMissionSegmentations, {
  ConfigurationParamDefaultSegmentationId,
  getSegmentationIdFromConfigurationParam,
} from './useMissionSegmentations';
import ArchiveReasonMultiSelect from './ArchiveReasonMultiSelect';

import './ProjectProfilesTab.css';

interface ProjectProfilesTabProps {
  jobId: string;
  clientId: string;
  shouldHideBulkActions?: boolean;
  disableProfilesInteraction?: boolean;
}

const ProjectProfilesTab: React.FC<ProjectProfilesTabProps> = ({
  jobId,
  clientId,
  shouldHideBulkActions,
  disableProfilesInteraction,
}) => {
  const { t } = useTranslation();
  const isPlugin = useIsPlugin();
  const candidateSelection = useCandidateSelection();
  const notifications = useNotificationSystem();
  const [profileIndex, setProfileIndex] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [displayType, setDisplayType] = useState('table');
  const [candidatePerPage, setCandidatePerPage] = useState(
    +localStorage.MissionPaginationProfilesPerPage
      ? +localStorage.MissionPaginationProfilesPerPage
      : 10,
  );
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [debouncedSearchEmail, setDebouncedSearchEmail] = useState('');
  const [debouncedSearchNumber, setDebouncedSearchNumber] = useState('');
  const [resetPageIndex, setResetPageIndex] = useState(false);
  const [weekDiffInMS, setWeekDiff] = useState(0);
  const [dynamicImportPolling, setDynamicImportPolling] = useState(0);
  const missionCategory = useContext(MissionCategoryContext);
  const atsId = useClientCurrentAtsId();

  const debouncedSetText = useMemo(
    () => _.debounce(setDebouncedSearchText, 1000),
    [setDebouncedSearchText],
  );

  const debouncedSetEmail = useMemo(
    () => _.debounce(setDebouncedSearchEmail, 1000),
    [setDebouncedSearchEmail],
  );

  const debouncedSetNumber = useMemo(
    () => _.debounce(setDebouncedSearchNumber, 1000),
    [setDebouncedSearchNumber],
  );

  const onSearchText = useCallback(
    (text) => {
      const trimmedText = text.trim();
      if (_.isEmpty(trimmedText)) {
        debouncedSetEmail('');
        debouncedSetNumber('');
        debouncedSetText('');
      } else if (trimmedText.search(/@[a-z]*/gi) > 0) {
        debouncedSetEmail(trimmedText);
        debouncedSetNumber('');
      } else if (
        trimmedText.search(/^[+]?[(]?\d{2,3}[)]?[-\s.]?\d{1,3}[-\s.]?/gim) >=
          0 &&
        trimmedText.replace(/^\+?33/, '0').replace(/\s/g, '').length >= 8
      ) {
        debouncedSetNumber(trimmedText);
        debouncedSetEmail('');
      } else {
        debouncedSetText(trimmedText);
      }
      setSearchText(text);
    },
    [debouncedSetEmail, debouncedSetNumber, debouncedSetText],
  );

  /**
   * Note for the future: if more display options like this one or displayType
   * are worked on, consider using a context.
   */
  const [condensedInterested, setCondensedInterested] = useState(false);

  const [archiveReasonsFilter, setArchiveReasonsFilter] = useState(
    [] as string[],
  );

  const configurationParameters = useMergedConfigurationParams();

  const missionSegmentations = useMissionSegmentations(
    jobId,
    condensedInterested,
  );
  const defaultSegmentation = missionSegmentations[0];
  const location = useQueryParams<{
    segmentationId?: string;
    segmentId?: string;
    selectedProfileId?: string;
    interestedStepId?: string;
  }>({
    defaults: {
      ...(configurationParameters.shouldHideProjectDefaultPipelineSegmentation !==
      'true'
        ? {
            segmentationId: defaultSegmentation.id,
            segmentId: defaultSegmentation.segments?.[0]?.id,
          }
        : {
            ...(configurationParameters.projectProfilesDefaultView === 'all'
              ? {
                  ...getSegmentationIdFromConfigurationParam(
                    configurationParameters?.projectProfilesDefaultView as ConfigurationParamDefaultSegmentationId,
                  ),
                }
              : {
                  segmentationId: missionSegmentations[1].id,
                  segmentId: missionSegmentations[1].segments?.[0]?.id,
                }),
          }),
    },
  });

  const { selectedProfileId } = location;
  const untranslatedSegmentation = useMemo(
    () =>
      missionSegmentations.find((x) => x.id === location.segmentationId) ||
      defaultSegmentation,
    [defaultSegmentation, missionSegmentations, location.segmentationId],
  );

  const { projectNomenclatures } = useProjectNomenclatures();

  const segmentation = useMemo(
    () =>
      untranslatedSegmentation.type !== 'pipeline'
        ? untranslatedSegmentation
        : {
            ...untranslatedSegmentation,
            segments: _.map(untranslatedSegmentation.segments, (segment) => {
              if ((segment.type || '').includes('hs-pipeline-interested')) {
                return segment;
              }
              if (segment.id === 'hired') {
                const recruitingSuccessStepId =
                  _.find(
                    projectNomenclatures,
                    ({ missionCategory: projectMissionCategory }) =>
                      projectMissionCategory.type === 'recruiting',
                  )?.successStep?.id || 'hired';
                const recruitingSuccessStepTitle = t(
                  `superPipelineSettings.nomenclature.options.${recruitingSuccessStepId}`,
                  {
                    count: 10,
                  },
                );

                const salesSuccessStepId =
                  _.find(
                    projectNomenclatures,
                    ({ missionCategory: projectMissionCategory }) =>
                      projectMissionCategory.type === 'sales',
                  )?.successStep?.id || 'signed';
                const salesSuccessStepTitle = t(
                  `superPipelineSettings.nomenclature.options.${salesSuccessStepId}`,
                  {
                    count: 10,
                  },
                );
                return {
                  ...segment,
                  name:
                    missionCategory === 'recruiting'
                      ? recruitingSuccessStepTitle
                      : salesSuccessStepTitle,
                };
              }
              return {
                ...segment,
                name: t(`reveal.pipelineSegmentations.${segment.id}`),
              };
            }),
          },
    [untranslatedSegmentation, t, projectNomenclatures, missionCategory],
  );

  const dateSegmentation = useMemo(() => {
    if (!segmentation || segmentation?.type !== 'hs-next-availability-date') {
      return segmentation;
    }

    // For some reason setting the current date as a whole day
    // makes the intervals stop working. i haven't really checked why
    // but i'm guessing it's a gte vs gt thing
    // Not sure what the comment above meant but it seems to be fixed, leaving it for now in case things go sideways.
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayMs = today.getTime();
    let referenceTimestamp = todayMs + weekDiffInMS;
    if (segmentation.subtype === 'date') {
      const referenceDate = new Date(referenceTimestamp);
      const referenceDay = referenceDate.getDay();
      const firstDay = parseInt(
        configurationParameters?.weeklyKanbanStartDay || '1',
        10,
      );
      referenceDate.setDate(referenceDate.getDate() - referenceDay + firstDay);
      referenceTimestamp = referenceDate.getTime();
    }

    const firstDate = parseInt(
      segmentation?.subtype === 'date'
        ? segmentation?.segments?.[0]?.nextAvailabilityFilter?.greaterThan || ''
        : _.find(
            segmentation?.segments,
            (segment) =>
              !!segment?.nextAvailabilityFilter?.lessThan &&
              !segment?.nextAvailabilityFilter?.greaterThan,
          )?.nextAvailabilityFilter?.lessThan || '',
      10,
    );

    // For whomever happens upon this code, here's how I understand it works
    // 1/ Initial data comes from src/components/PipelineSegmentation/data.ts
    //    where we have stored the different periods as dates (using the dates when the code was written)
    // 2/ this piece of code checks every segment and tries to match the difference with today's date
    // 3/ to do so, it performs some small addition/substraction to get the same differece relative to today's date
    // My proposal: timed segmentations should have a "periodStartOffset" and "periodEndOffset"
    // which would make this a lot clearer, and lessThan/greaterThan could be computed on the backend directly
    // while also taking into account timezones!
    return {
      ...segmentation,
      segments: _.map(segmentation?.segments, (segment: Segment) => {
        const segmentId =
          segmentation.subtype === 'date'
            ? `${segment.id}-week${getWeekNumber(new Date(referenceTimestamp))}`
            : segment.id;

        // "isUnknown" kind of segments
        if (
          !segment.nextAvailabilityFilter?.greaterThan &&
          !segment.nextAvailabilityFilter?.lessThan
        ) {
          return {
            ...segment,
            id: segmentId,
          };
        }

        /**
         * Realign limits relative to today
         */
        let lessThan: number | undefined;
        let greaterThan: number | undefined;

        if (segment.nextAvailabilityFilter.lessThan) {
          lessThan =
            referenceTimestamp +
            parseInt(segment.nextAvailabilityFilter.lessThan, 10) -
            firstDate;
        }
        if (segment.nextAvailabilityFilter.greaterThan) {
          greaterThan =
            referenceTimestamp +
            parseInt(segment.nextAvailabilityFilter.greaterThan, 10) -
            firstDate;
        }

        /**
         * In "period" mode, the first segment doesn't have a lower limit and
         * the last segment doesn't have an upper limit, we leave them as is
         */
        if (!lessThan || !greaterThan) {
          return {
            ...segment,
            id: segmentId,
            nextAvailabilityFilter: {
              lessThan: lessThan !== undefined ? `${lessThan}` : undefined,
              greaterThan:
                greaterThan !== undefined ? `${greaterThan}` : undefined,
            },
          };
        }

        // Disable all segments that are before today
        if (lessThan < todayMs) {
          return {
            ...segment,
            id: segmentId,
            nextAvailabilityFilter: {},
            isDisabled: true,
            emptyState: t(
              'reveal.pipelineSegmentations.availabilityPipeline.dateEmptyState',
            ),
          };
        }

        // Merge all previous days into today by removing today's lower limit
        if (greaterThan <= todayMs && lessThan >= todayMs) {
          return {
            ...segment,
            id: segmentId,
            nextAvailabilityFilter: {
              lessThan: `${lessThan}`,
            },
          };
        }

        // Remaining segments
        return {
          ...segment,
          id: segmentId,
          nextAvailabilityFilter: {
            lessThan: `${lessThan}`,
            greaterThan: `${greaterThan}`,
          },
        };
      }),
    };
  }, [
    segmentation,
    weekDiffInMS,
    configurationParameters?.weeklyKanbanStartDay,
    t,
  ]);

  const switchSegmentation = useCallback(
    (segmentationId, segmentId?: string) => {
      const newSegmentation = missionSegmentations.find(
        (x) => x.id === segmentationId,
      );
      if (!newSegmentation) return;
      location.setParams(
        {
          segmentationId,
          segmentId: segmentId || newSegmentation.segments[0].id,
        },
        { method: 'replace' },
      );
    },
    [missionSegmentations, location],
  );

  useEffectOnce(
    (done) => {
      // We can only redirect if we start in the default pipeline
      // otherwise user shared a specific link
      if (
        location.segmentationId !== 'D2KD9DW3' ||
        location.segmentId !== 'pending' ||
        !!location.segmentId
      ) {
        return;
      }

      const { segmentationId, segmentId } =
        getSegmentationIdFromConfigurationParam(
          configurationParameters?.projectProfilesDefaultView as ConfigurationParamDefaultSegmentationId,
        );

      if (
        location.segmentationId === segmentationId &&
        location.segmentId === segmentId
      ) {
        // No need to redirect if the "shared" link is already in the right location
        return;
      }

      switchSegmentation(segmentationId, segmentId);
      // Calling done ensures this function cannot run again
      done();
    },
    [
      switchSegmentation,
      location,
      configurationParameters?.projectProfilesDefaultView,
    ],
  );

  useEffectOnce(
    (done) => {
      if (
        segmentation?.showDisplayTypeSelector &&
        configurationParameters?.projectProfilesPreferedDisplayType === 'kanban'
      ) {
        setDisplayType('kanban');
        // Calling done ensures this function cannot run again
        done();
      }
    },
    [segmentation, configurationParameters?.projectProfilesDefaultView],
  );

  const setSelectedProfileId = (profileId?: string | null) => {
    location.setParams({ selectedProfileId: profileId ?? undefined });
  };

  useEffect(() => {
    if (!segmentation?.showDisplayTypeSelector) {
      setDisplayType((current) => segmentation?.displayType || current);
    }
  }, [segmentation]);

  const segment = useMemo(() => {
    const relevantSegmentation = _.findWhere(missionSegmentations, {
      id: location.segmentationId,
    });

    const result = _.findWhere(relevantSegmentation?.segments || [], {
      id: location.segmentId,
    });

    return result;
  }, [missionSegmentations, location]);

  const [state] = useContext(ProfilesViewSettingsContext);

  const extraFilters = useMemo(() => {
    const result = {} as ExtraFilters;
    const searchCriteria = getCriteriaFromSettings(state);

    if (segment?.id === 'archived') {
      result.archiveReasons = { in: archiveReasonsFilter };
    }

    if (searchCriteria.free) {
      result.searchCriteria = searchCriteria;
    }

    return _.isEmpty(result) ? undefined : result;
  }, [segment?.id, archiveReasonsFilter, state]);

  const { segmentCounts, refetch: refetchCounts } = useSegmentCounts({
    missionId: jobId,
    segmentationId: 'D2KD9DW3',
    segmentDefinitions: segmentation.segments.map((s) => ({
      id: s.id,
      type: s.type,
    })),
    extraFilters,
  });

  const {
    userTableViewPreferences: displayPreferences,
    loading: loadingDisplayPreferences,
  } = useUserTableViewPreferences({
    tableId: MISSION_PROFILES_TABLE_ID,
    defaultValues: {
      tableId: MISSION_PROFILES_TABLE_ID,
      sortingOptions: {
        sortBy: [],
      },
    },
  });

  const sortByOptions = useMemo(() => {
    if (
      _.isEmpty(displayPreferences?.sortingOptions?.sortBy) &&
      atsId === 'adventure'
    ) {
      return [
        {
          key: 'en-cdii',
          order: 'descending',
        },
        {
          key: 'adventure-status',
          order: 'ascending',
        },
        {
          key: 'adventure-contract',
          order: 'descending',
        },
      ];
    }
    return displayPreferences?.sortingOptions?.sortBy?.map(({ key, order }) => {
      if (key.includes('customField-')) {
        return {
          key: key.replace('customField-', ''),
          order,
        };
      }
      return { key, order };
    });
  }, [displayPreferences, atsId]);

  const {
    profileResults: segmentProfileResults,
    loading: segmentProfileResultsLoading,
    refetch: refetchSegmentProfileResults,
  } = useSearchPoolJobProfileResults({
    jobId,
    segmentationId: 'D2KD9DW3',
    // Small hack that accompanies the hack above
    step: location.segmentId || 'all',
    searchText: debouncedSearchText.trim(),
    searchEmail: debouncedSearchEmail,
    searchPhoneNumber: debouncedSearchNumber,
    type: segment?.type,
    extraFilters,
    sortBy: sortByOptions || [],
    queryOptions: {
      skip: loadingDisplayPreferences,
      pollInterval: dynamicImportPolling,
    },
  });

  useDataUpdateSubscription({
    type: 'onProfilesAddedToMission',
    // missionId == jobId
    match: { missionId: jobId },
    onData: () => {
      refetchCounts();
      refetchSegmentProfileResults();
    },
  });

  useDataUpdateSubscription({
    type: 'onProfilesRemovedFromMission',
    // missionId == jobId
    match: { missionId: jobId },
    onData: () => {
      refetchCounts();
      refetchSegmentProfileResults();
    },
  });

  useDataUpdateSubscription({
    type: 'onProfilesChangedMissionStage',
    match: { missionId: jobId },
    onData: () => {
      refetchCounts();
      refetchSegmentProfileResults();
    },
  });

  const segmentMiniProfiles = _.map(segmentProfileResults, (p) => ({
    ...p,
    id: p.profileId,
  }));

  useEffect(() => {
    const refetchProfilesData = async () => {
      try {
        await refetchCounts();
        // await refetchResults();
        // await refetchProfiles(); // Already depends on segmentId. Should depend on ids only ?
      } catch (e) {
        Sentry.captureException(e);
      }
    };
    refetchProfilesData();
  }, [refetchCounts, location]);

  /**
   * Refetch segment count after we add a candidate from the modal import candidate
   */
  useEffect(() => {
    const onProfileResultsChange = async () => {
      await refetchCounts();
    };
    onProfileResultsChange();
  }, [segmentMiniProfiles, refetchCounts]);

  const loading = segmentProfileResultsLoading;

  const isProfileInSegment = _.pluck(segmentMiniProfiles, 'id').includes(
    selectedProfileId,
  );

  const profileIdHandler = ProfileIdHandler({
    profileIds: _.pluck(segmentMiniProfiles, 'id'),
    selectedProfileId: isProfileInSegment
      ? selectedProfileId
      : segmentMiniProfiles?.[profileIndex || 0]?.id || '',
    onSelectProfileId: (profileId: string | null | undefined) => {
      setSelectedProfileId(profileId);
      setProfileIndex(
        _.pluck(segmentMiniProfiles, 'id').indexOf(profileId) - 1,
      );
    },
  });

  // const allCandidates = candidateSelection.getAll();
  const selection = candidateSelection.get();
  const currentPageMiniProfiles = segmentMiniProfiles?.slice(
    currentPageIndex * candidatePerPage,
    (currentPageIndex + 1) * candidatePerPage,
  );

  const [updateProfilesPipelineStage, { loading: pipelineUpdateLoading }] =
    useUpdateProfilesPipelineStage();

  useEffect(() => {
    candidateSelection.updateAllCandidates({ candidates: segmentMiniProfiles });
    candidateSelection.reset();
    if (segmentMiniProfiles.length <= candidatePerPage) {
      setResetPageIndex(true);
    }
    // eslint-disable-next-line
  }, [segmentProfileResults]);

  const selectOneProfileHandler = (profile: {
    id: string;
    isSelected?: boolean;
  }) => {
    candidateSelection.toggle(profile);
  };

  const handleRangeSelect = (profile: { id: string }) => {
    candidateSelection.handleRangeSelect({ profileId: profile.id });
  };

  const updateSelectionStage = async (
    stage: string,
    _prevStage?: string,
    labels?: string[],
    clientArchiveReasonId?: string,
    interestedStepId?: string,
  ) => {
    if (!location.segmentationId) return;
    try {
      await updateProfilesPipelineStage({
        missionId: jobId,
        segmentationId: location.segmentationId,
        profileIds: candidateSelection.getSelectedIds(),
        stage,
        labels,
        clientArchiveReasonId,
        interestedStepId,
      });
      refetchSegmentProfileResults();
      notifications.success(
        t('reveal.pipelineSegmentations.profilesStageUpdated'),
      );
    } catch (e) {
      notifications.error(t('Error occurred'));
      Sentry.captureException(e);
    }
  };

  const selectionIsEmpty = _.isEmpty(selection);

  const selectedProfilesCount = new Intl.NumberFormat(t('common.lang')).format(
    candidateSelection.get().length,
  );

  const isHeaderChecked = useMemo(() => {
    if (!selection.length) {
      return false;
    }
    const pageIds = _.pluck(currentPageMiniProfiles, 'id');
    return candidateSelection.areSelected({ profilesIds: pageIds });
    // eslint-disable-next-line
  }, [currentPageIndex, currentPageMiniProfiles.length, selection.length]);

  const onToggleCheckbox = ({ checked }: { checked: boolean }) =>
    candidateSelection.onSelectAllInCurrentPageToggle(
      checked,
      currentPageIndex,
      candidatePerPage,
      currentPageMiniProfiles,
    );

  return (
    <div
      className={classnames(
        'project-profiles-view',
        displayType === 'kanban' ? 'kanban-display' : '',
      )}
    >
      <ProjectProfilesViewSettingsHeader
        segmentations={missionSegmentations}
        segmentation={segmentation}
        onSelectSegmentation={switchSegmentation}
        displayType={displayType}
        onSelectDisplayType={(selectedDisplayType) =>
          setDisplayType(selectedDisplayType)
        }
        condensedInterested={condensedInterested}
        setCondensedInterested={setCondensedInterested}
        weekDiffInMS={weekDiffInMS}
        onWeekDiffChange={setWeekDiff}
        missionId={jobId}
        dynamicImportPolling={dynamicImportPolling}
        onDynamicImportPollingChange={setDynamicImportPolling}
      />
      <div
        className={classnames(
          'project-profiles-tab',
          displayType === 'kanban' ? 'kanban-display' : '',
        )}
      >
        {displayType === 'table' && (
          <>
            <SegmentationLine
              segmentation={segmentation}
              counts={segmentCounts}
              selectedSegmentId={location.segmentId}
              onSelectSegment={({ id }) => {
                location.setParams({ segmentId: id }, { method: 'replace' });
              }}
            />
            <div className='project-profiles-table'>
              <div className='button-container'>
                {!shouldHideBulkActions ? (
                  <>
                    <AddToSequenceButton
                      jobId={jobId}
                      clientId={clientId}
                      candidates={selection}
                    />
                    {segmentation?.type === 'pipeline' && (
                      <GenericTooltip
                        disabled={!selectionIsEmpty || pipelineUpdateLoading}
                        trigger={
                          <span>
                            <ButtonDropdown
                              primacy='secondary'
                              disabled={
                                selectionIsEmpty || pipelineUpdateLoading
                              }
                              title={t(
                                'reveal.pipelineSegmentations.moveToStage',
                              )}
                            >
                              <MoveToStageAction
                                missionId={jobId}
                                currentStage={location.segmentId}
                                isSubmitting={pipelineUpdateLoading}
                                stages={Segmentations[0].segments}
                                onMove={updateSelectionStage}
                                candidates={candidateSelection.get()}
                                setOpen={() => {
                                  /* Do nothing */
                                }}
                                absolutePosition='left'
                                clientId={clientId}
                              />
                            </ButtonDropdown>
                          </span>
                        }
                        content={t(
                          'reveal.pipelineSegmentations.makeSelection',
                        )}
                        position='bottom center'
                      />
                    )}

                    <ActionsDropdown
                      clientId={clientId}
                      jobId={jobId}
                      candidates={selection}
                      sequenceId={undefined}
                      onSuccess={() => refetchSegmentProfileResults()}
                    />

                    <ImportCandidatesDropdown
                      clientId={clientId}
                      jobId={jobId}
                      onSuccess={() => {
                        refetchSegmentProfileResults();
                        // setJobViewVersion((previousVersion) => previousVersion + 1);
                      }}
                    />

                    <div className='selected-profiles'>
                      {!_.isEmpty(candidateSelection?.get()) && (
                        <>
                          {selectedProfilesCount +
                            t('common.selectedProfilesCount', {
                              count: +selectedProfilesCount,
                            })}
                          {!candidateSelection.isAllCandidatesSelected ? (
                            <span
                              aria-hidden
                              className='select-all-profiles'
                              onClick={() =>
                                candidateSelection.selectAllAcrossAllPages()
                              }
                            >
                              {segmentProfileResults?.length >= 1000
                                ? t('reveal.selection.selectMax')
                                : t('reveal.selection.selectAll')}
                            </span>
                          ) : (
                            <span
                              aria-hidden
                              className='select-all-profiles'
                              onClick={() => candidateSelection.reset()}
                            >
                              {t('reveal.selection.unselectAll')}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <div />
                )}
                <div className='hs-flex-spacer' />

                {segment?.id === 'archived' && (
                  <ArchiveReasonMultiSelect
                    defaultValues={archiveReasonsFilter}
                    onValues={setArchiveReasonsFilter}
                  />
                )}

                <Input
                  value={searchText}
                  onChange={(_e, { value }: { value: string }) => {
                    onSearchText(value);
                  }}
                  className='mission-filter-by-text'
                  size='mini'
                  icon='search'
                  placeholder={t('header.search.searchProfiles')}
                />

                {/* <SequenceStatusFilter
              className='project-profiles-status-filter hs-default-dropdown'
              statusFilter={statusFilter}
              setStatusFilter={setStatusFilter}
              t={t}
            /> */}
              </div>
              <ProfilesTable
                tableId={MISSION_PROFILES_TABLE_ID}
                clientId={clientId}
                currentSegment={segment}
                onToggleHeaderCheckbox={onToggleCheckbox}
                isHeaderChecked={isHeaderChecked}
                loading={loading}
                miniProfiles={segmentMiniProfiles}
                setSelectedProfileId={setSelectedProfileId}
                setProfileIndex={setProfileIndex}
                candidateSelection={candidateSelection}
                selectOneProfileHandler={selectOneProfileHandler}
                handleRangeSelect={handleRangeSelect}
                resetPageIndex={resetPageIndex}
                currentPageIndex={currentPageIndex}
                setCurrentPageIndex={setCurrentPageIndex}
                setResetPageIndex={setResetPageIndex}
                version={debouncedSearchText}
                candidatePerPage={candidatePerPage}
                setCandidatePerPage={setCandidatePerPage}
                jobId={jobId}
                shouldHideCheckboxes={shouldHideBulkActions}
                shouldHideProjectSearch={
                  configurationParameters?.shouldHideProjectSearch === 'true'
                }
                disableProfilesInteraction={
                  configurationParameters?.shouldDisableProfileOpeningFromArchivedProject ===
                    'true' && disableProfilesInteraction
                }
                displaySortingOptions={
                  configurationParameters?.shouldDisplayProfileSortingInProject ===
                  'true'
                }
              />

              {/* Enriched profile query must end after non-enriched profiles have loaded
              so we get the full data in the modal */}
              {!loading && (
                <EnrichedRevealProfileModal
                  clientId={clientId}
                  open={!!selectedProfileId}
                  // profile={_.findWhere(cachedProfiles, { id: profileIdHandler.current })}
                  onClose={() => setSelectedProfileId(null)}
                  profileIdHandler={profileIdHandler}
                  searches={{}} // TODO: hacky but useful; add Mission criteria
                  profileId={selectedProfileId}
                  needNetworkCall={isPlugin}
                />
              )}
            </div>
          </>
        )}
        {displayType === 'kanban' && (
          <ProfileKanbanContainer
            queryOptions={{
              variables: {
                searchPoolId: 'reveal',
                input: {
                  extraFilters,
                  sortBy: sortByOptions || [],
                },
              },
              fetchPolicy: 'cache-first',
            }}
          >
            <MissionProfilesGenericView
              segmentation={dateSegmentation}
              jobId={jobId}
              disableDragAndDrop={dateSegmentation.disableDragAndDrop || false}
              disableProfilesInteraction={
                configurationParameters?.shouldDisableProfileOpeningFromArchivedProject ===
                  'true' && disableProfilesInteraction
              }
              weekDiffInMS={weekDiffInMS}
            />
          </ProfileKanbanContainer>
        )}
      </div>
    </div>
  );
};

export default ProjectProfilesTab;
