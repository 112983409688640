import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Profile from '../../graphql/fragments/Profile';

const withProfileFromSearchPoolProfileQuery = gql`
  query withProfileFromSearchPoolProfile(
    $clientId: ID!
    $searchPoolId: ID!
    $searchPoolProfileId: ID!
    $criteria: SearchCriteriaInput
  ) {
    profileFromSearchPoolProfile(
      clientId: $clientId
      searchPoolId: $searchPoolId
      searchPoolProfileId: $searchPoolProfileId
      criteria: $criteria
    ) {
      ...Profile
    }
  }
  ${Profile}
`;

export default graphql(withProfileFromSearchPoolProfileQuery, {
  options: ({ clientId, searchPoolId, searchPoolProfileId, searches }) => {
    const criteria = (searches || [])[0]; // TODO
    return {
      // NOTE: keep withNextProfileFromSearchPoolProfile variables in sync for cache optim to work
      variables: { clientId, searchPoolId, searchPoolProfileId, criteria },
    };
  },
  props: ({
    data: { loading, error, refetch, profileFromSearchPoolProfile },
  }) => {
    return {
      loadingProfile: loading,
      errorProfile: error,
      profileFromSearchPoolProfile,
      refetchProfileFromSearchPoolProfile: refetch,
    };
  },
});
