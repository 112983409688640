import classNames from 'classnames';
import React, { FC } from 'react';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';

import styles from './Toggle.module.less';

const Toggle: FC<CheckboxProps> = ({ className, ...props }) => {
  return (
    <Checkbox
      toggle
      className={classNames(className, styles.toggle)}
      {...props}
    />
  );
};

export default Toggle;
