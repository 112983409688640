import React from 'react';
import qs from 'qs';
import { useLocation, useRouteMatch } from 'react-router-dom';
import SSOReception from '../containers/Auth/SSO/SSOReception';

interface ClientMatch {
  clientId: string;
}
const SSOReceptionView: React.FC = () => {
  const { search } = useLocation();
  const { exchangetoken: exchangeToken, redirectto: redirectTo } = qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as Record<string, string>;
  const {
    params: { clientId },
  } = useRouteMatch<ClientMatch>();
  return <SSOReception clientId={clientId} exchangeToken={exchangeToken} redirectTo={redirectTo} />;
};

export default SSOReceptionView;
