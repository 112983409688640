import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import NewTemplate from '@/containers/Parameters/Templates/modals/NewTemplate';
import useClientId from '@/hooks/router/useClientId';
import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';

const NewTemplateButton: React.FC = () => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <GenericButton size='big' onClick={() => setModalOpen(true)}>
        <Plus />
        {t('templates.newTemplate')}
      </GenericButton>
      <NewTemplate
        clientId={clientId}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onPostSubmit={() => setModalOpen(false)}
      />
    </>
  );
};

export default NewTemplateButton;
