import React, { useState } from 'react';
import _ from 'underscore';
import { useQuery } from '@apollo/client';

import { GET_CONNECT_PAGES } from '@/graphql/connectPages';
import { ConnectPage } from '@/types/connectPage';
import styles from './ConnectPagesSettings.module.less';
import { useNavApplicationMode } from '../../../TopBar/Header/NavSwitch.hook';
import ConnectPagesTable from './ConnectPagesTable';
import ConnectPageEditorModal from './ConnectPageEditorModal';
import DeleteConnectPageModal from './DeleteConnectPageModal';
import ConnectQRCodeModal from './ConnectPageQRCodeModal';

interface ConnectPagesSettingsProps {
  clientId: string;
  setNewPageModalOpen: (value: boolean) => void;
  newPageModalOpen: boolean;
}

const ConnectPagesSettings: React.FC<ConnectPagesSettingsProps> = ({
  clientId,
  setNewPageModalOpen,
  newPageModalOpen,
}) => {
  const [editModalOpenId, setEditModalOpenId] = useState<string | null>(null);
  const [QRCodeModalPageId, setQRCodeModalPageId] = useState<string | null>(
    null,
  );
  const [deleteModalOpenId, setDeleteModalOpenId] = useState<string | null>(
    null,
  );
  const { applicationMode, updateApplicationMode } = useNavApplicationMode();

  const { data: connectPagesData } = useQuery(GET_CONNECT_PAGES, {
    variables: {
      clientId,
    },
  });
  if (applicationMode !== 'reveal') {
    updateApplicationMode('reveal');
  }

  const connectPages: ConnectPage[] = connectPagesData?.client?.connectPages;

  const onEditConnectPage = ({ connectPageId }: { connectPageId: string }) => {
    setEditModalOpenId(connectPageId);
  };

  const onDeleteConnectPage = ({
    connectPageId,
  }: {
    connectPageId: string;
  }) => {
    setDeleteModalOpenId(connectPageId);
  };

  const onShowQRCode = ({ connectPageId }: { connectPageId: string }) => {
    setQRCodeModalPageId(connectPageId);
  };

  const connectPageToEdit =
    (editModalOpenId && _.findWhere(connectPages, { id: editModalOpenId })) ||
    null;

  const connectPageToDisplayQRCodeFor =
    (QRCodeModalPageId &&
      _.findWhere(connectPages, { id: QRCodeModalPageId })) ||
    null;

  const connectPageToDelete = deleteModalOpenId
    ? _.findWhere(connectPages, { id: deleteModalOpenId })
    : null;

  return (
    <div className={styles.eventSection}>
      <ConnectPagesTable
        connectPages={connectPages}
        onEditConnectPage={onEditConnectPage}
        onShowQRCode={onShowQRCode}
        withEdit
        onDeleteConnectPage={onDeleteConnectPage}
      />
      {(newPageModalOpen || !!connectPageToEdit) && (
        <ConnectPageEditorModal
          open
          clientId={clientId}
          connectPage={connectPageToEdit}
          closeModal={() => {
            setEditModalOpenId(null);
            setNewPageModalOpen(false);
          }}
        />
      )}
      {QRCodeModalPageId && (
        <ConnectQRCodeModal
          open
          connectPage={connectPageToDisplayQRCodeFor}
          closeModal={() => {
            setQRCodeModalPageId(null);
          }}
          clientId={clientId}
        />
      )}
      <DeleteConnectPageModal
        connectPage={connectPageToDelete}
        clientId={clientId}
        closeModal={() => setDeleteModalOpenId(null)}
        open={!!connectPageToDelete}
      />
    </div>
  );
};

export default ConnectPagesSettings;
