import React from 'react';
import _ from 'underscore';
import FullContactFlowSequenceEditor from './FullContactFlowSequenceEditor';

import './FullContactFlowEditor.css';

class FullContactFlowEditor extends React.Component {
  render() {
    const {
      clientId,
      offerId,
      contactFlow,
      defaultTemplatesOnSeveralOffers,
      onAddSequence,
      onUpdateSequenceTrigger,
      onRemoveSequence,
      onInsertAction,
      onUpdateAction,
      onRemoveAction,
      onRemoveActions,
      onMoveUpward,
      onMoveDownward,
      updateAfterTemplateCreation,
      onQuitContactFlowEditor,
      formErrors,
      applyCcBccToNextMails,
      isGenerationLoading,
      t,
    } = this.props;
    const contactFlowSequences = (contactFlow || {}).sequences;

    // TODO: causing glitchy state initialization for nothing
    // const placeholderSequencePositions = ['first-contact', 'positive-answer', 'non-positive-answer', 'recontact'];
    // const placeholderTitleFromPosition = {
    //   'first-contact': 'First Contact',
    //   'positive-answer': 'Positive Answer',
    //   'non-positive-answer': 'Negative Answer',
    //   recontact: 'Recontact',
    // };
    //
    // const sequences = [
    //   ..._.map(
    //     placeholderSequencePositions,
    //     (position) => _.findWhere(contactFlowSequences, { position }) || {
    //       title: placeholderTitleFromPosition[position] || position,
    //       position,
    //       isPlaceholder: true,
    //     },
    //   ),
    //   ..._.filter(
    //     contactFlowSequences,
    //     (sequence) => !sequence.position || placeholderSequencePositions.indexOf(sequence.position) < 0,
    //   ),
    // ];

    const sequence = contactFlowSequences?.[0]; // TODO: multiple sequences
    const nonEmptySequences = _.filter(
      contactFlowSequences,
      (sequenceOrPlaceholder) => {
        return ((sequenceOrPlaceholder || {}).actions || []).length > 0;
      },
    );
    const lastNonEmptySequence =
      nonEmptySequences[nonEmptySequences.length - 1];

    if (!sequence) {
      return null;
    }

    return (
      <div className='contact-flow-editor'>
        <FullContactFlowSequenceEditor
          clientId={clientId}
          t={t}
          sequence={sequence}
          lastNonEmptySequence={lastNonEmptySequence}
          onAddSequence={onAddSequence}
          onUpdateSequenceTrigger={onUpdateSequenceTrigger}
          onRemoveSequence={onRemoveSequence}
          onInsertAction={onInsertAction}
          onUpdateAction={onUpdateAction}
          onRemoveAction={onRemoveAction}
          onRemoveActions={onRemoveActions}
          onMoveUpward={onMoveUpward}
          onMoveDownward={onMoveDownward}
          offerId={offerId}
          defaultTemplatesOnSeveralOffers={defaultTemplatesOnSeveralOffers}
          updateAfterTemplateCreation={updateAfterTemplateCreation}
          onQuitContactFlowEditor={onQuitContactFlowEditor}
          applyCcBccToNextMails={applyCcBccToNextMails}
          formErrors={_.filter(
            formErrors,
            ({ sequenceId }) => sequenceId === sequence?.id,
          )}
          isGenerationLoading={isGenerationLoading}
        />
      </div>
    );
  }
}

export default FullContactFlowEditor;
