import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Image } from 'semantic-ui-react';
import sanitizeHtml from 'sanitize-html';
import parseHtml from 'html-react-parser';

import useClientId from '@/hooks/router/useClientId';
import useClient from '@/graphql/hooks/clients/useClient';
import { getSecureLink, getRandomDefaultAvatarLink } from '@/common';
import { replaceWildcardsWithBoldHTMLText } from '@/common/utils/string';
import LabelDetails from '@/components/LabelDetails';
import SkillsLabels from '@/components/Profile/SkillsLabels';
import TranslatableText from '@/components/TranslatableText';
import { Stack } from '@/components/Stack';
import { BlurParagraph } from '@/components/UnbiasedMode/BlurLine';

import SmartTags from './SmartTags';

import './SmartSummary.css';

const SmartSummary = (props) => {
  const { t } = useTranslation();
  const {
    argumentation,
    skills,
    profileId,
    neutralMode,
    talentStrategist,
  } = props;
  if (_.isEmpty(argumentation)) {
    return null;
  }
  const review = _.find(argumentation, { type: 'review' }) || {};
  const smartTags = _.find(argumentation, { type: 'grouped-key-points' }) || {};

  return (
    <div className='smart-tags-and-summary'>
      <div className='summary-section'>
        <div className='summary-section-title'>
          {t('profile.resume.smartTags.wishes')}
          <LabelDetails text={t('profile.resume.smartTags.wishesHelp')} />
        </div>
        <SmartTags neutralMode={neutralMode} smartTags={smartTags} />
      </div>

      {!_.isEmpty(skills) && (
        <div className='summary-section'>
          <div className='profile-skills'>
            <div className='summary-section-title'>
              {t('profile.resume.smartTags.skills')}
              <LabelDetails text={t('profile.resume.smartTags.skillsHelp')} />
            </div>
            <SkillsLabels skills={skills} />
          </div>
        </div>
      )}

      <div className='summary-section'>
        <Review
          {...review}
          profileId={profileId}
          stack={review.stack}
          talentStrategist={talentStrategist}
          t={t}
        />
      </div>
    </div>
  );
};

const Review = ({ content, stack, title, profileId, talentStrategist, t }) => {
  const clientId = useClientId();
  const { data } = useClient(clientId);
  const { hasLimitedAccessToProfiles } = data?.client || {};
  const avatarFallback = getRandomDefaultAvatarLink();
  const { photoLink, firstname } = talentStrategist || {};

  if (!content?.default) {
    return null;
  }
  const contentWithBoldWords = replaceWildcardsWithBoldHTMLText({
    text: content,
  });

  const sanitizedContent = sanitizeHtml(contentWithBoldWords, {
    allowedTags: ['b'],
  });

  const contentTag = parseHtml(sanitizedContent);

  const normalizedTitle =
    title &&
    _.mapObject(title, (text) =>
      (text || '').replace(
        '(Hiresweet)',
        `${
          firstname ? `${t('common.by').toLowerCase()} ${firstname}` : ''
        } (HireSweet)`,
      ),
    );

  return (
    <div className='summary-tag-stack-container smart-summary'>
      <div className='stack-container'>
        <div className='summary-section-title'>
          {(normalizedTitle || {}).default ? (
            <TranslatableText text={normalizedTitle} />
          ) : (
            t('profile.resume.summary-watch')
          )}
          {talentStrategist && (
            <Image
              src={photoLink ? getSecureLink(photoLink) : avatarFallback}
              onError={(e) => {
                e.target.src = avatarFallback;
              }}
            />
          )}
        </div>
        {stack && (
          <div className='stack'>
            <Stack
              stack={stack}
              displaySoftSkills
              profileId={profileId}
              context='summary'
            />
          </div>
        )}
      </div>
      {hasLimitedAccessToProfiles ? (
        <BlurParagraph lines={8} />
      ) : (
        <div className='profile-summary'>{contentTag}</div>
      )}
    </div>
  );
};

export default SmartSummary;
