import _ from 'underscore';
import React from 'react';

import { Dropdown } from 'semantic-ui-react';

import InlineTimeSelector from '@/components/Common/InlineTimeSelector';

const TriggerDelayEditor = ({
  action,
  onUpdate,
  displayExtraDescription,
  t,
  disabled = false,
}) => {
  const delayValue = action?.trigger?.delay?.value;
  const delayUnit = action?.trigger?.delay?.unit;

  const delayValueOptionsEmptyArray = Array(30);
  const delayValueOptions = _.map(
    delayValueOptionsEmptyArray,
    (option, index) => {
      return { value: index + 1, text: `${index + 1}` };
    },
  );

  const delayUnitOptions = [
    {
      value: 'hour',
      text: t('contactFlow.triggers.units.hour', { count: delayValue }),
    },
    {
      value: 'working-day',
      text: t('contactFlow.triggers.units.workingDay', { count: delayValue }),
    },
    {
      value: 'day',
      text: t('contactFlow.triggers.units.day', { count: delayValue }),
    },
    {
      value: 'week',
      text: t('contactFlow.triggers.units.week', { count: delayValue }),
    },
  ];

  const handleChangeDelayValue = (e, { value }) => {
    onUpdate({
      action: {
        ...action,
        trigger: {
          type: 'delay-after-action',
          delay: {
            unit: action?.trigger?.delay?.unit || 'day',
            value,
          },
        },
      },
    });
  };

  const handleChangeDelayUnit = (e, { value: unit }) => {
    onUpdate({
      action: {
        ...action,
        trigger: {
          type: 'delay-after-action',
          delay: {
            unit,
            value: action?.trigger?.delay?.value || 7,
          },
        },
      },
    });
  };

  const handleTargetTimeChange = (time, timezone) => {
    onUpdate({
      action: {
        ...action,
        trigger: {
          type: 'delay-after-action',
          delay: {
            unit: action?.trigger?.delay?.unit || 'day',
            value: action?.trigger?.delay?.value || 7,
            targetTimeInDay: {
              timezone,
              hoursAndMinutes: time,
            },
          },
        },
      },
    });
  };

  const handleTargetTimeClear = () => {
    onUpdate({
      action: {
        ...action,
        trigger: {
          type: 'delay-after-action',
          delay: {
            unit: action?.trigger?.delay?.unit || 'day',
            value: action?.trigger?.delay?.value || 7,
          },
        },
      },
    });
  };

  return (
    <span className='trigger-delay-editor automatic-selector'>
      &nbsp;
      <Dropdown
        className='hs-inline-dropdown'
        value={delayValue}
        onChange={handleChangeDelayValue}
        options={delayValueOptions}
        scrolling
        disabled={disabled}
      />
      &nbsp;
      <Dropdown
        className='hs-inline-dropdown'
        value={delayUnit}
        onChange={handleChangeDelayUnit}
        options={delayUnitOptions}
        scrolling
        disabled={disabled}
      />
      <InlineTimeSelector
        onChange={handleTargetTimeChange}
        onClear={handleTargetTimeClear}
        title={t('contactFlow.triggers.delays.targetTime.scheduleTitle')}
        label={t('contactFlow.triggers.delays.targetTime.scheduleLabel')}
        time={action.trigger.delay?.targetTimeInDay?.hoursAndMinutes}
        timezone={action.trigger.delay?.targetTimeInDay?.timezone}
        defaultOptions={[
          {
            label: t(
              'contactFlow.triggers.delays.targetTime.defaultOptions.default',
            ),
            time: '09:00',
          },
          {
            label: t(
              'contactFlow.triggers.delays.targetTime.defaultOptions.lateMorning',
            ),
            time: '11:00',
          },
          {
            label: t(
              'contactFlow.triggers.delays.targetTime.defaultOptions.afterLunch',
            ),
            time: '14:00',
          },
          {
            label: t(
              'contactFlow.triggers.delays.targetTime.defaultOptions.endOfDay',
            ),
            time: '18:00',
          },
        ]}
      />
      {displayExtraDescription && (
        <>
          &nbsp;
          <span className='trigger-extra-description'>
            {t('contactFlow.triggers.ifNoAnswer')}
          </span>
        </>
      )}
    </span>
  );
};

export default TriggerDelayEditor;
