import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import SkipRatioDataContainer from '../../Analytics/components/SkipRatio/SkipRatioDataContainer';
import { dateRangeOptions, frequencyOptions } from './helpers';

const SkipRatio = ({ clientId, offerId, t }) => {
  const [dateRange, setDateRange] = useState('allTime');
  const [frequency, setFrequency] = useState('month');

  return (
    <div className='analytics-skip-ratio section'>
      <h3>{t('analytics.headers.skipRatio')}</h3>
      <br />
      <div className='skip-ratio-body-container'>
        <div className='selector-container'>
          <div className='selector-item'>
            <div className='selector-label'>{t('analytics.dropdownLabels.dateRange')}</div>
            <div className='selector-dropdown'>
              <Dropdown
                fluid
                selection
                onChange={(e, { value }) => setDateRange(value)}
                value={dateRange}
                options={dateRangeOptions({ t })}
              />
            </div>
          </div>
          <div className='selector-item'>
            <div className='selector-label'>{t('analytics.dropdownLabels.frequency')}</div>
            <div className='selector-dropdown'>
              <Dropdown
                fluid
                selection
                onChange={(e, { value }) => setFrequency(value)}
                value={frequency}
                options={frequencyOptions({ t })}
              />
            </div>
          </div>
        </div>
        <div className='analytics-skip-ratio-container'>
          <SkipRatioDataContainer
            clientId={clientId}
            offerIds={[offerId]}
            dateRange={dateRange}
            frequency={frequency}
            t={t}
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslation('translations')(SkipRatio);
