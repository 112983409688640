import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { PowerHour } from '@/types/powerHours';
import { TelescopeIcon } from '@/assets/icons';
import ButtonLink from '@/components/Common/GenericButton/ButtonLink';

interface PowerHoursLegacyBannerProps {
  powerHour: PowerHour;
  className?: string;
}

const PowerHoursLegacyBanner: FC<PowerHoursLegacyBannerProps> = ({
  powerHour,
  className,
}) => {
  const { t } = useTranslation();
  const { message, link } = powerHour;
  return (
    <div className={classNames('jobs-watch-collect-banner', className)}>
      <div className='banner-icon'>
        <TelescopeIcon className='' />
      </div>

      <div className='banner-content'>
        <div className='banner-main-text body-regular'>{message}</div>
      </div>

      <ButtonLink primacy='primary' to={{ pathname: link }} target='_blank'>
        {t('common.moreInformation')}
      </ButtonLink>
    </div>
  );
};

export default PowerHoursLegacyBanner;
