import {
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from '@apollo/client';
import { UPDATE_MISSION_INTERESTED_SUB_STEPS } from '@/graphql/clientMissionInterestedSubSteps';
import { TranslatableText } from '@/types/text';

export type MissionInterestedSubStep = {
  id: string;
  title: TranslatableText;
  previousStepId?: string | null;
  isDisabled?: boolean | null;
};

interface MissionInterestedSubStepsMutationVariables {
  input: { steps: MissionInterestedSubStep[]; missionId: string };
  searchPoolId: 'reveal';
}

export function useMissionInterestedSubStepsMutation(
  options: MutationHookOptions = {},
): MutationTuple<
  {
    updateMissionInterestedSubStepsSettings: {
      MissionInterestedSubSteps: MissionInterestedSubStep[];
    };
  },
  MissionInterestedSubStepsMutationVariables
> {
  return useMutation(UPDATE_MISSION_INTERESTED_SUB_STEPS, {
    ...options,
    variables: options.variables as MissionInterestedSubStepsMutationVariables,
    refetchQueries: ['GetInterestedSubPipeline'],
  });
}
