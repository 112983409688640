import React, { useContext } from 'react';
import { compose } from 'underscore';
import classNames from 'classnames';
import UnifiedViewContext from '@/containers/Parameters/Offers/UnifiedViewContext';
import OfferActiveLabel from '@/containers/Parameters/Offers/OfferTable/OfferRow/OfferActiveLabel';
import withClient from '../../../hocs/clients/withClient';
import withOffer from '../../../hocs/offers/withOffer';
import withClientBaseOffers from '../../../hocs/offers/withClientBaseOffers';
import withUserFromJWToken from '../../../hocs/users/withUserFromJWToken';

import StepNav from './StepNav';
import OfferSelector from './OfferSelector';
import OfferSettings from '../../../components/OfferSettings';
import OfferLabels from '../../Parameters/Offers/components/OfferLabels';

import './SubHeader.css';

const SubHeader = ({
  offerId,
  stepId,
  subStepId,
  pageId,
  searchText,
  onChangeStep,
  client,
  offer,
  user,
  offers,
  shouldSplitPending,
}) => {
  const { unifiedView } = useContext(UnifiedViewContext);
  if (!offer || !client || !user) {
    return <div className='app-sub-header' />;
  }
  return (
    <div className='app-sub-header'>
      <div className='selector-and-labels'>
        <OfferSelector
          client={client}
          offerId={offerId}
          offers={offers}
          targetPage={pageId}
        />
        <div className='labels-container'>
          <OfferActiveLabel offer={offer} />
          <OfferLabels offer={offer} clientId={client?.id} />
        </div>
      </div>
      <div
        className={classNames('app-sub-header-steps', {
          'app-sub-header-steps--unified': unifiedView,
        })}
      >
        <StepNav
          clientId={client?.id}
          offerId={offerId}
          searchText={searchText}
          stepId={searchText ? '#search' : stepId}
          subStepId={subStepId}
          onSelectStep={onChangeStep}
          shouldSplitPending={shouldSplitPending}
        />
      </div>
      {!offer?.isHiresweetWatch && !offer?.isUnclassified && (
        <div
          className={classNames('sub-header-settings', {
            'sub-header-settings--unified': unifiedView,
          })}
        >
          <OfferSettings
            offer={offer}
            clientId={client?.id}
            pageId={pageId}
            from='sub-header'
          />
        </div>
      )}
    </div>
  );
};

const MemoizedSubHeader = React.memo(SubHeader);

export default compose(
  withClient,
  withOffer,
  withClientBaseOffers,
  withUserFromJWToken,
)(MemoizedSubHeader);
