import React from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import SearchPoolJob from '../../fragments/SearchPoolJob';
import { CustomFieldInput } from '../clients/useClientProfileCustomFields';

const CREATE_JOB = gql`
  mutation createSearchPoolJob(
    $searchPoolId: ID!
    $input: CreateSearchPoolJobInput!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      create(input: $input) {
        job {
          ...SearchPoolJob
          stats {
            applicants
            recommendations
            importedProfiles
          }
        }
      }
    }
  }
  ${SearchPoolJob}
`;

type UseCreateSearchPoolJobOptions = {
  searchPoolId: string;
  input: {
    title: string;
    atsJobId?: string;
    atsUserId?: string;
    departmentId?: string;
    sectionId?: string;
    subsectionId?: string;
    customFields?: CustomFieldInput[];
  };
};

type UseCreateSearchPoolJobReturn = [
  (options: UseCreateSearchPoolJobOptions) => Promise<void>,
  { loading: boolean; data: any; error: any },
];

const useCreateSearchPoolJob = (): UseCreateSearchPoolJobReturn => {
  const [mutation, { loading, data, error }] = useMutation(CREATE_JOB);
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();

  const create = React.useCallback(
    async (options: UseCreateSearchPoolJobOptions) => {
      await mutation({
        variables: options,
        refetchQueries: ['getSearchPoolJobOptions'],
      });
      publishSubscriptionEvent('onMissionsListUpdated', {});
    },
    [mutation, publishSubscriptionEvent],
  );

  return [create, { loading, data, error }];
};

export default useCreateSearchPoolJob;
