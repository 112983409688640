import _ from 'underscore';

import {
  CampaignDailyCohort,
  ConvertibleCampaignEventType,
  isStatsCampaignEventType,
  StatsCampaignEventType,
} from '@/types/statistics/campaigns';
import { sumNumberRecords } from '@/common/helpers/stats';
import { getPercentage } from '@/common/utils/number';

const getEmptyCampaignStats = (): Record<StatsCampaignEventType, number> => ({
  nbEnrolled: 0,
  nbContacted: 0,
  nbOpened: 0,
  nbClicked: 0,
  nbUnsubscribe: 0,
});

export const getCampaignStats = (
  dailyCohorts?: CampaignDailyCohort[],
): Record<string, Record<StatsCampaignEventType, number>> => {
  const result: Record<string, Record<StatsCampaignEventType, number>> = {};

  _.each(dailyCohorts || [], ({ details }) => {
    _.each(details, ({ type, count, campaignId }) => {
      result[campaignId] = result[campaignId] || getEmptyCampaignStats();
      if (isStatsCampaignEventType(type)) {
        result[campaignId][type] += count;
      }
    });
  });

  return result;
};

export const getConversionRateNumbers = ({
  nbEnrolled,
  nbContacted,
  nbOpened,
  nbClicked,
}: Record<StatsCampaignEventType, number>): Record<
  ConvertibleCampaignEventType,
  number
> => ({
  nbEnrolled: getPercentage(nbContacted, nbEnrolled),
  nbContacted: getPercentage(nbOpened, nbContacted),
  nbOpened: getPercentage(nbClicked, nbOpened),
});

export const aggregateCampaignStats = (
  stats: Record<string, Record<StatsCampaignEventType, number>>,
): Record<StatsCampaignEventType, number> =>
  _.reduce(stats, sumNumberRecords, getEmptyCampaignStats());

export default {};
