import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Criteria from '../../graphql/fragments/Criteria';

export const getOfferCriteria = gql`
  query getOfferCriteria($id: ID!) {
    offer(id: $id) {
      id
      title
      isHold
      criteria {
        ...Criteria
      }
    }
  }
  ${Criteria}
`;

export default graphql(getOfferCriteria, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.offerId,
    },
  }),
  props: ({ data: { loading, error, offer } }) => ({
    loading,
    error,
    offer,
    loadingOfferCriteria: loading,
    errorOfferCriteria: error,
    offerCriteria: offer?.criteria,
  }),
  skip: (ownProps) => !ownProps.offerId,
});
