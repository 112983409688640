import React, { useState } from 'react';
import _ from 'underscore';
import { Form, Accordion, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import InfoTooltip from '@/components/Common/InfoTooltip';
import GenericSelect from '@/components/Common/GenericSelect';
import jobPositionOptions from '../../../../../common/options/revealJobPositionExtraOptions.json';
import { normalizeFilter } from '../../utils';
import JobSelect from './JobSelect';

const JobFields = ({ jobPosition, setJobPosition }) => {
  const majorValue = jobPosition?.major?.id;
  const minorValues = _.map(jobPosition?.minors, (item) => item?.id);
  const { t, i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(!_.isEmpty(minorValues));

  const handleClick = () => {
    setActiveIndex(!activeIndex);
  };

  const onChangeMajor = (_e, { value }) => {
    if (!value) {
      setJobPosition(_.omit(jobPosition, 'major'));
    } else {
      const newJobPosition = {
        ...jobPosition,
        major: { id: value },
      };
      setJobPosition(newJobPosition);
    }
  };

  const onChangeMinors = (_e, { value }) => {
    if (_.isEmpty(value)) {
      setJobPosition(_.omit(jobPosition, 'minors'));
    } else {
      const newJobPosition = {
        ...jobPosition,
        minors: _.map(value, (jobId) => ({ id: jobId })),
      };
      setJobPosition(newJobPosition);
    }
  };

  const optionTextKey = (i18n.resolvedLanguage || '')
    .toLowerCase()
    .includes('fr')
    ? 'fr'
    : 'text';

  const cleanOptions = _.sortBy(
    _.map(
      _.map(jobPositionOptions, (option) => ({
        ...option,
        en: option.text,
        text: option[optionTextKey] || option.text,
      })),
      (opt) => _.omit(opt, 'displayedByDefault'),
    ),
    'text',
  );

  return (
    <div className='job-fields section'>
      <JobSelect
        key={`jobSelect-${majorValue}`}
        value={majorValue}
        onValue={(value) => onChangeMajor(null, { value })}
      />
      <Accordion className='compact'>
        <Accordion.Title active={activeIndex} onClick={handleClick}>
          <Icon name='dropdown' />
          {t('reveal.searchView.filters.job.addMinor')}
          <InfoTooltip>
            {t('reveal.searchView.filters.job.minorPopup')}
          </InfoTooltip>
        </Accordion.Title>
        <Accordion.Content active={activeIndex}>
          <GenericSelect
            isMulti
            options={_.map(cleanOptions, ({ value, text }) => ({
              value,
              label: text,
            }))}
            value={_.map(minorValues, (id) => {
              const { value, text } = _.findWhere(cleanOptions, { value: id });
              return {
                value,
                label: text,
              };
            })}
            onChange={(newValues) =>
              onChangeMinors(null, {
                value: _.map(newValues, ({ value }) => value),
              })
            }
          />
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

export default React.memo(JobFields);
