import { Button } from 'semantic-ui-react';
import React from 'react';

import './GradeButtonSelector.css';

const GradeButtonSelector = ({ grade, onSelectGrade }) => {
  const getClassName = (targetGrade) => {
    return grade === targetGrade ? 'is-selected' : 'not-selected';
  };

  return (
    <Button.Group className='grade-button-selector' basic>
      <Button className={getClassName(0)} onClick={() => onSelectGrade({ grade: grade === 0 ? undefined : 0 })}>
        0
      </Button>
      <Button className={getClassName(1)} onClick={() => onSelectGrade({ grade: grade === 1 ? undefined : 1 })}>
        1
      </Button>
      <Button className={getClassName(2)} onClick={() => onSelectGrade({ grade: grade === 2 ? undefined : 2 })}>
        2
      </Button>
      <Button className={getClassName(3)} onClick={() => onSelectGrade({ grade: grade === 3 ? undefined : 3 })}>
        3
      </Button>
      <Button className={getClassName(4)} onClick={() => onSelectGrade({ grade: grade === 4 ? undefined : 4 })}>
        4
      </Button>
      <Button className={getClassName(5)} onClick={() => onSelectGrade({ grade: grade === 0 ? undefined : 5 })}>
        5
      </Button>
    </Button.Group>
  );
};

export default GradeButtonSelector;
