import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Integration from '../../graphql/fragments/Integration';

export const mutation = gql`
  mutation deleteClientIntegration($id: ID!) {
    deleteClientIntegration(id: $id) {
      id
      integrations {
        ...Integration
      }
    }
  }
  ${Integration}
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    deleteClientIntegration: ({ id }) =>
      mutate({
        variables: {
          id,
        },
      }),
  }),
});
