import React from 'react';
import _ from 'underscore';
import axios from 'axios';
import { Popup, Image, Loader } from 'semantic-ui-react';
import {
  getSecureLink,
  getTranslatedText,
  softSkills,
  getEnrichedText,
} from '../common';
import withActionLogger from '../hocs/withActionLogger';
import CollapsibleEnrichedText from './CollapsibleEnrichedText';

const mergeTechnosWithSameIcon = (stack) => {
  const mergedStackIcons = [];
  const mergedStack = [];
  _.forEach(stack, (techno) => {
    if (techno.icon && mergedStackIcons.indexOf(techno.icon) === -1) {
      mergedStack.push(techno);
      mergedStackIcons.push(techno.icon);
    } else if (techno.icon && mergedStackIcons.indexOf(techno.icon) > -1) {
      const sameStackIconIndex = mergedStackIcons.indexOf(techno.icon);
      mergedStack[
        sameStackIconIndex
      ].translatedName += `, ${techno.translatedName}`;
    } else {
      mergedStack.push(techno);
    }
  });
  return mergedStack;
};

export const getFormattedStack = (stack, displaySoftSkills) => {
  const formattedStack = _.map(stack, (techno) => {
    if (!techno) return null;
    const isSoftSkill =
      !techno.icon &&
      techno.name &&
      (_.pluck(softSkills, 'name').indexOf(techno.name.default) >= 0 ||
        _.pluck(softSkills, 'id').indexOf(techno.name.default) >= 0 ||
        _.pluck(softSkills, 'id').indexOf(techno.id) >= 0);
    if (displaySoftSkills && isSoftSkill) {
      const softSkill = _.find(
        softSkills,
        (skill) =>
          skill.id === techno.id ||
          skill.name === techno.name.default ||
          skill.id === techno.name.default,
      );
      return softSkill
        ? {
            translatedName: softSkill.name,
            icon: null,
            softSkill,
            detailsURL: techno.detailsURL,
          }
        : null;
    }
    if (techno.icon) {
      return {
        translatedName: getTranslatedText(techno.name),
        icon: techno.icon,
        detailsURL: techno.detailsURL,
      };
    }
    return null;
  });
  const filteredFormattedStack = _.filter(
    formattedStack,
    (techno) => (techno || {}).translatedName,
  );
  return mergeTechnosWithSameIcon(
    _.uniq(filteredFormattedStack, (techno) => (techno || {}).translatedName),
  ).slice(0, 5);
};

class TechnologyIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = { iconError: false };
  }

  loadDetailsCalled = false;

  loadDetails = async () => {
    if (this.loadDetailsCalled) {
      return;
    }
    this.loadDetailsCalled = true;

    const { detailsURL } = this.props;
    if (detailsURL) {
      try {
        const result = (
          await axios.get(detailsURL, {
            withCredentials: false,
          })
        ).data;
        this.setState({
          details: {
            ...result,
            description: getTranslatedText((result || {}).description) || ' ',
          },
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  render() {
    const {
      name,
      icon,
      detailsURL,
      softSkill,
      profileId,
      context,
    } = this.props;
    const { details } = this.state;
    if (this.state.iconError === true) {
      return null;
    }

    if (softSkill) {
      return (
        <Popup
          key={name}
          position='top right'
          size='mini'
          hoverable
          className='skill-popup'
          content={
            <PopupContent
              name={name}
              profileId={profileId}
              context={context}
              loadDetails={this.loadDetails}
            />
          }
          trigger={
            <div className='technology-icon'>
              <div
                className='initials-container'
                style={{ backgroundColor: softSkill.color }}
              >
                <div className='initials'>{softSkill.initials}</div>
              </div>
            </div>
          }
        />
      );
    }

    return (
      <Popup
        key={name}
        position='top right'
        size='mini'
        className='skill-popup'
        popperDependencies={[!!this.state.details]}
        content={
          <PopupContent
            name={name}
            profileId={profileId}
            context={context}
            hasDetails={!!detailsURL}
            details={details}
            loadDetails={this.loadDetails}
          />
        }
        trigger={
          <Image
            className='technology-icon'
            inline
            src={getSecureLink(icon)}
            onError={(e) => {
              this.setState({ iconError: true });
            }}
          />
        }
      />
    );
  }
}

class PopupContentRaw extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.loadDetails();
    this.setState({ openTimestamp: new Date().getTime() });
  }

  componentWillUnmount() {
    const duration = new Date().getTime() - this.state.openTimestamp;
    if (duration > 500) {
      this.props.onLogAction({
        type: `hover-technology-icon-in-${this.props.context}`,
        profileId: this.props.profileId,
        info: {
          duration,
          technologyName: this.props.name,
        },
      });
    }
  }

  logExpand = () => {
    this.props.onLogAction({
      type: `expand-technology-description-in-${this.props.context}`,
      profileId: this.props.profileId,
      info: {
        technologyName: this.props.name,
      },
    });
  };

  render() {
    const { name, details, hasDetails } = this.props;
    return (
      <div className={hasDetails ? 'has-details' : ''}>
        <div className='skill-name'>
          <span>{name}</span>
        </div>
        {hasDetails &&
          (details && details.description ? (
            <div className='skill-description'>
              <CollapsibleEnrichedText
                charCost={1}
                lineCost={90}
                targetMaxCost={500}
                toleranceFactor={0.3}
                enrichedText={getEnrichedText(details.description)}
                expandOnly
                onToggleCollapse={this.logExpand}
              />
            </div>
          ) : (
            <center>
              <Loader inline active />
            </center>
          ))}
      </div>
    );
  }
}

const PopupContent = withActionLogger(PopupContentRaw);

export const Stack = ({ stack, displaySoftSkills, profileId, context }) => {
  const displayedStack = getFormattedStack(stack, displaySoftSkills);
  return _.map(displayedStack, (technology) => (
    <TechnologyIcon
      key={(technology || {}).translatedName}
      name={(technology || {}).translatedName}
      icon={(technology || {}).icon}
      softSkill={(technology || {}).softSkill}
      detailsURL={(technology || {}).detailsURL}
      profileId={profileId}
      context={context}
    />
  ));
};
