import React, { createContext, Dispatch, SetStateAction, useState } from 'react';

interface IAdminApplicationModeContext {
  adminApplicationMode: 'classic' | 'reveal';
  setAdminApplicationMode: Dispatch<SetStateAction<'classic' | 'reveal'>>;
}

/**
 * A context only used to fill the gap left by the user settings for admins logged into a client
 * they are not a recruiter of.
 */
export const AdminApplicationModeContext = createContext<IAdminApplicationModeContext>({
  adminApplicationMode: 'classic',
  setAdminApplicationMode: () => {},
});

export const AdminApplicationModeContextProvider: React.FC = ({ children }) => {
  const [adminApplicationMode, setAdminApplicationMode] = useState<'classic' | 'reveal'>('classic');
  return (
    <AdminApplicationModeContext.Provider value={{ adminApplicationMode, setAdminApplicationMode }}>
      {children}
    </AdminApplicationModeContext.Provider>
  );
};
