import gql from 'graphql-tag';
import Sender from './Sender';

export default gql`
    fragment OfferRecruiterConfiguration on OfferRecruiterConfiguration {
        id
        recruiterEmail
        assignedSender {
            ...Sender
        }
    }
    ${Sender}
`;
