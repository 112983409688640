import React, { useState } from 'react';
import _ from 'underscore';
import { Dropdown, DropdownProps, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { KeywordsEmplacement, KeywordsOperator } from './types';
import {
  HAS_ANY_KEYWORDS,
  HAS_KEYWORDS,
  HAS_NONE_OF_KEYWORDS,
  KEYWORD_EMPLACEMENT_ANYWERE,
  KEYWORD_EMPLACEMENT_ALL_HEADLINE_EXPERIENCES,
  KEYWORD_EMPLACEMENT_HEADLINE,
} from './constants';

import './CustomCriteriaFilters.css';

const FILTERS: { value: KeywordsOperator; text: string }[] = [
  {
    value: HAS_ANY_KEYWORDS,
    text: 'reveal.searchView.search.keywords.anyOf',
  },
  {
    value: HAS_KEYWORDS,
    text: 'reveal.searchView.search.keywords.allOf',
  },
  {
    value: HAS_NONE_OF_KEYWORDS,
    text: 'reveal.searchView.search.keywords.noneOf',
  },
];

const KEYWORDS_EMPLACEMENTS = [
  {
    value: KEYWORD_EMPLACEMENT_ANYWERE,
    text: 'reveal.searchView.search.keywords.emplacements.anywhere',
  },
  {
    value: KEYWORD_EMPLACEMENT_HEADLINE,
    text: 'reveal.searchView.search.keywords.emplacements.headline',
  },
  {
    value: KEYWORD_EMPLACEMENT_ALL_HEADLINE_EXPERIENCES,
    text:
      'reveal.searchView.search.keywords.emplacements.allHeadlineExperiences',
  },
];

interface CustomerCriteriaKeywordsProps {
  onSelectedOperatorChange: (operator: KeywordsOperator) => void;
  selectedOperator: KeywordsOperator[];
  value: Partial<Record<KeywordsOperator, string[]>>;
  onValueChange: (
    value: Partial<Record<KeywordsOperator, string[]>>,
    operator?: KeywordsOperator[],
  ) => void;
  emplacement: KeywordsEmplacement | undefined;
  onEmplacementChange: (value: KeywordsEmplacement) => void;
  onClose: () => void;
}
const CustomCriteriaKeywords: React.FC<CustomerCriteriaKeywordsProps> = ({
  onSelectedOperatorChange,
  selectedOperator,
  value,
  onValueChange,
  emplacement,
  onEmplacementChange,
  onClose,
}) => {
  const { t } = useTranslation('translations');

  const onChange = (key: KeywordsOperator) => (
    ev: any,
    data: DropdownProps,
  ) => {
    const newValue = { ...value };
    const valueDropdown = data.value as string[];
    newValue[key] = valueDropdown;
    let newOperator = [...selectedOperator];

    // set or reset operator on selection
    if (!selectedOperator.includes(key)) {
      newOperator = [...selectedOperator, key];
    }

    if (valueDropdown?.length === 0 && selectedOperator.includes(key)) {
      newOperator = newOperator.filter((op) => op !== key);
    }

    onValueChange(newValue, newOperator);
  };

  return (
    <div className='property-details-wrapper'>
      {FILTERS.map(({ value: keywordsOperator, text }) => (
        <CustomKeywordsField
          onChange={onChange(keywordsOperator)}
          values={value[keywordsOperator]}
          text={t(text)}
          key={keywordsOperator}
          keywordsOperator={keywordsOperator}
          onSelectedOperatorChange={onSelectedOperatorChange}
        />
      ))}

      <hr />

      <div className='radio-wrapper'>
        <div className='radio-row'>
          <div className='label'>
            {t('reveal.searchView.search.keywords.location')}
          </div>
        </div>
        <Dropdown
          fluid
          onChange={(e: any, data: DropdownProps) =>
            onEmplacementChange(data.value as KeywordsEmplacement)
          }
          selection
          value={emplacement || KEYWORD_EMPLACEMENT_ANYWERE}
          options={_.map(KEYWORDS_EMPLACEMENTS, (emplacementOption) => ({
            value: emplacementOption.value,
            text: t(emplacementOption.text),
          }))}
        />
      </div>

      <Button primary className='save-button' onClick={onClose}>
        {t('reveal.searchView.search.save')}
      </Button>
    </div>
  );
};

interface CustomKeywordsFieldProps {
  onChange: (ev: any, data: DropdownProps) => void;
  text: string;
  values?: string[];
  keywordsOperator: KeywordsOperator;
  onSelectedOperatorChange: (operator: KeywordsOperator) => void;
}
const CustomKeywordsField: React.FC<CustomKeywordsFieldProps> = ({
  text,
  values,
  keywordsOperator,
  onChange,
  onSelectedOperatorChange,
}) => {
  const { t } = useTranslation('translations');
  const [newOptions, setNewOptions] = useState<
    { text: string; value: string }[]
  >(
    values?.map((opt) => ({
      text: opt,
      value: opt,
    })) ?? [],
  );

  const addItem = (ev: any, data: DropdownProps) => {
    const { value: optionText } = data;

    if (typeof optionText === 'string') {
      setNewOptions([
        ...newOptions,
        {
          text: optionText,
          value: optionText,
        },
      ]);
    }
  };

  return (
    <div className='radio-wrapper'>
      <div className='radio-row'>
        <div className='label'>{text}</div>
      </div>

      <Dropdown
        fluid
        multiple
        search
        selection
        onChange={onChange}
        value={values ?? []}
        options={newOptions}
        allowAdditions
        onAddItem={addItem}
        placeholder={t('reveal.searchView.search.keywords.placeholder')}
        noResultsMessage={t('reveal.searchView.search.keywords.writeKeyword')}
        className='noMargin'
        selectOnBlur={false}
        onClick={() => onSelectedOperatorChange(keywordsOperator)}
      />
    </div>
  );
};

export default CustomCriteriaKeywords;
