import React from 'react';
import { compose } from 'underscore';

import withProfileFromSearchPoolProfile from '../../../hocs/profiles/withProfileFromSearchPoolProfile';
import withNextProfileFromSearchPoolProfile from '../../../hocs/profiles/withNextProfileFromSearchPoolProfile';

import ProfilePreview from '../ProfilePreview';
import ProfilePreviewLoading from '../components/ProfilePreviewLoading';

const ProfilePreviewLoader = ({
  searchPoolId,
  searchPoolProfileId,
  profileFromSearchPoolProfile,
  loadingProfile,
  // errorProfile,
  clientId,
  user,
  onMarkHidden,
  onMarkUnhidden,
  setProfilesInJobIds,
  onSend,
  // onError,
  searches,
}) => {
  // const [redirectToNextProfile, setRedirectToNextProfile] = useState(false);
  //
  // useEffect(() => {
  //   if (redirectToNextProfile && onError) {
  //     setRedirectToNextProfile(false);
  //     onError();
  //   }
  // }, [redirectToNextProfile, onError]);

  if (loadingProfile) {
    return <ProfilePreviewLoading />;
  }

  // TODO: slightly dangerous because can cause chain reaction (error -> load next profile -> error)
  // if ((errorProfile || !profileFromSearchPoolProfile) && !redirectToNextProfile) {
  //   console.log('WARNING: Unable to load enriched profile with searchPoolProfileId', searchPoolProfileId);
  //   setRedirectToNextProfile(true);
  // }

  /**
   * If the full profile could not be fetched from the provided searchpool
   * profile ID, then maybe the provided ID was already the final one.
   * This happens when using the search bar for instance.
   */
  const profileId = profileFromSearchPoolProfile?.id || searchPoolProfileId;

  return (
    <ProfilePreview
      clientId={clientId}
      profileId={profileId}
      user={user}
      searchPoolId={searchPoolId}
      onMarkHidden={onMarkHidden}
      onMarkUnhidden={onMarkUnhidden}
      setProfilesInJobIds={setProfilesInJobIds}
      onSend={onSend}
      searches={searches}
      canApplyUnbiasedMode
    />
  );
};

export default compose(
  withProfileFromSearchPoolProfile,
  withNextProfileFromSearchPoolProfile,
)(ProfilePreviewLoader);
