import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import { Campaign } from '@/types/campaign';

import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useClientId from '@/hooks/router/useClientId';
import styles from './CampaignSettingsDropdown.module.less';
import {
  ARCHIVE_CAMPAIGN,
  DUPLICATE_CAMPAIGN,
  RESTORE_CAMPAIGN,
} from '../queries';

type Props = {
  campaign: Campaign;
};

const CampaignSettingsDropdown: React.FC<Props> = ({ campaign }) => {
  const [openModal, setOpenModal] = useState<string | null>(null);
  const [redirectTo, setRedirectTo] = useState<string | undefined>();
  const [archiveCampaign] = useMutation(ARCHIVE_CAMPAIGN);
  const [restoreCampaign] = useMutation(RESTORE_CAMPAIGN);
  const [duplicateCampaign] = useMutation(DUPLICATE_CAMPAIGN);
  const notification = useNotificationSystem();
  const { t } = useTranslation();
  const clientId = useClientId();

  const Icon = <i className={`ellipsis ri-more-fill ${styles.icon}`} />;

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  const closeModal = () => setOpenModal(null);

  const handleArchiveCampaign = async () => {
    await archiveCampaign({ variables: { input: { id: campaign.id } } });
    closeModal();
    notification.success(t('campaigns.archive.success'));
  };
  const handleRestoreCampaign = async () => {
    await restoreCampaign({ variables: { input: { id: campaign.id } } });
    closeModal();
    notification.success(t('campaigns.unarchive.success'));
  };
  const handleDuplicateCampaign = async () => {
    try {
      const result = await duplicateCampaign({
        variables: { input: { id: campaign.id } },
      });
      const newCampaignId = result.data.campaign.duplicate.campaign.id;
      setRedirectTo(`/client/${clientId}/reveal/campaigns/${newCampaignId}`);
      notification.success(t('campaigns.duplicate.success'));
    } catch (error) {
      notification.error(t('campaigns.duplicate.error'));
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <div className={styles.settingsDropdown}>
      <Dropdown className={styles.dropdown} direction='right' item icon={Icon}>
        <Dropdown.Menu style={{ marginTop: 3 }}>
          {campaign?.isArchived ? (
            <Dropdown.Item onClick={(): void => setOpenModal('unarchive')}>
              {t('campaigns.unarchive.label')}
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onClick={(): void => setOpenModal('archive')}>
              {t('campaigns.archive.label')}
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={() => handleDuplicateCampaign()}>
            {t('campaigns.duplicate.label')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {openModal === 'archive' && (
        <ConfirmationModal
          open
          size='tiny'
          header={t('campaigns.archive.warning')}
          onSubmit={() => handleArchiveCampaign()}
          onCancel={closeModal}
        />
      )}

      {openModal === 'unarchive' && (
        <ConfirmationModal
          open
          size='tiny'
          header={t('campaigns.unarchive.warning')}
          onSubmit={() => handleRestoreCampaign()}
          onCancel={closeModal}
        />
      )}
    </div>
  );
};

export default CampaignSettingsDropdown;
