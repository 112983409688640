import _ from 'underscore';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { Trans, useTranslation } from 'react-i18next';

import useUserEnrichmentIntegrations, {
  UserEnrichmentIntegration,
} from '@/graphql/hooks/users/useUserEnrichmentIntegrations';
import SidePanel from '@/components/SidePanel';
import GenericButton from '@/components/Common/GenericButton';
import SelectItemDropdown from '@/components/Common/SelectItemDropdown/SelectItemDropdown';
import DropdownContainer from '@/components/Reveal/Dropdown/DropdownContainer/DropdownContainer';
import DropdownTrigger from '@/components/Reveal/Dropdown/DropdownTrigger/DropdownTrigger';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import Alert from '@/components/Common/Alert/Alert';

import DropdownControlsContext from '@/context/DropdownControlsContext';
import useBulkEnrich, { EmailReplacementPolicy } from './useBulkEnrich';

import styles from './BulkEnrichSidebar.module.less';

interface BulkEnrichSidebarProps {
  onClose: () => void;
  candidates: any[];
}

const AUTO_SAVE_MAIL_STRATEGIES = [
  'never',
  'only-personal',
  'only-work',
  'prefer-personal',
  'prefer-work',
];

const getMinQuotaLeft = (inheritedIntegration?: UserEnrichmentIntegration) => {
  if (!inheritedIntegration) {
    return null;
  }

  const { totalCreditsCount = Infinity, usedCreditsCount = 0 } =
    inheritedIntegration?.quota?.currentPeriod || {};
  const ownQuotaleft = totalCreditsCount - usedCreditsCount;

  const {
    totalCreditsCount: clientTotal = 0,
    usedCreditsCount: clientUsed = 0,
  } = inheritedIntegration?.clientIntegration?.quota?.currentPeriod || {};
  const clientLeft = clientTotal - clientUsed;

  return Math.min(ownQuotaleft, clientLeft);
};

const BulkEnrichSidebar: FC<BulkEnrichSidebarProps> = ({
  candidates,
  onClose,
}) => {
  // This could become a select
  const ENRICHMENT_PROVIDER = 'contactout';

  const { t } = useTranslation();
  const toast = useNotificationSystem();
  const { enrichmentIntegrations } = useUserEnrichmentIntegrations();

  const [ownIntegration, inheritedIntegration] = useMemo(() => {
    const ownActiveIntegration = _.find(
      enrichmentIntegrations,
      ({ type, useClientIntegration, status }) => {
        return (
          type === ENRICHMENT_PROVIDER &&
          status === 'active' &&
          !useClientIntegration
        );
      },
    );

    let inheritedActiveIntegration = _.find(
      enrichmentIntegrations,
      ({ type, useClientIntegration, status }) => {
        return (
          type === ENRICHMENT_PROVIDER &&
          status === 'active' &&
          Boolean(useClientIntegration)
        );
      },
    );

    // If there is no quota left we can simulate that no inherited integration exists
    const remainingInInherited = getMinQuotaLeft(inheritedActiveIntegration);
    if (!remainingInInherited || remainingInInherited < 0) {
      inheritedActiveIntegration = undefined;
    }

    return [ownActiveIntegration, inheritedActiveIntegration];
  }, [ENRICHMENT_PROVIDER, enrichmentIntegrations]);

  const [onlyContactsWitoutEmail, setOnlyContactsWitoutEmail] = useState(false);
  const [autoSaveMailStrategy, setAutoSaveMailStrategy] =
    useState<EmailReplacementPolicy>('never');
  const [integrationId, setIntegrationId] = useState(
    inheritedIntegration?.id || ownIntegration?.id || null,
  );

  const remainingCredits = useMemo(
    () => getMinQuotaLeft(inheritedIntegration),
    [inheritedIntegration],
  );

  const [enrich] = useBulkEnrich({
    variables: {
      searchPoolId: 'reveal',
      profileIds: _.map(candidates, ({ id }) => id),
      provider: ENRICHMENT_PROVIDER,
      userIntegrationId: integrationId,
      primaryEmailAddressReplacementPolicy: autoSaveMailStrategy,
      shouldEnrichWhenAlreadyEmailAddress: !onlyContactsWitoutEmail,
    },
    onError: () => {
      toast.error(t(`reveal.bulkEnrichSidebar.results.error`));
      onClose();
    },
    onCompleted: (data) => {
      if (data.searchPoolProfile?.bulkAskProfileEnrichment?.inProgress) {
        toast.success(t(`reveal.bulkEnrichSidebar.results.successInProgress`));
      }

      toast.success(t(`reveal.bulkEnrichSidebar.results.success`));
      onClose();
    },
  });

  const autoSaveMailOptions = useMemo(() => {
    return _.map(AUTO_SAVE_MAIL_STRATEGIES, (strategy) => ({
      value: strategy,
      content: t(`reveal.bulkEnrichSidebar.autoSaveEmail.${strategy}`),
    }));
  }, [t]);

  const shouldDisplayIntegrationSelector =
    Boolean(ownIntegration) && Boolean(inheritedIntegration);

  const submit = useCallback(() => {
    enrich();
  }, [enrich]);

  return (
    <SidePanel
      className=''
      title={t('reveal.bulkEnrichSidebar.title', { count: candidates.length })}
      footerContent={
        <GenericButton
          size='big'
          disabled={!inheritedIntegration && !ownIntegration}
          onClick={submit}
        >
          {t('reveal.bulkEnrichSidebar.bulkEnrichButton', {
            count: candidates.length,
          })}
        </GenericButton>
      }
      onClose={onClose}
    >
      {ownIntegration && !inheritedIntegration ? (
        <Alert title={t('common.info')} level='info'>
          <Trans
            i18nKey='reveal.bulkEnrichSidebar.ownIntegrationInfo'
            values={{
              potentialCreditsUsed: candidates.length,
              integrationName: 'ContactOut',
            }}
            components={{
              br: <br />,
            }}
          />
        </Alert>
      ) : (
        remainingCredits !== null && (
          <>
            <Alert title={t('common.info')} level='info'>
              <Trans
                i18nKey='reveal.bulkEnrichSidebar.quotaInfo'
                values={{
                  remainingCredits,
                  potentialCreditsUsed: candidates.length,
                }}
                components={{
                  br: <br />,
                }}
              />
            </Alert>
            {remainingCredits < candidates.length && (
              <Alert level='warning' title={t('common.warning')}>
                {t('reveal.bulkEnrichSidebar.notEnoughQuotaWarning')}
              </Alert>
            )}
          </>
        )
      )}
      <Form>
        <Form.Field>
          <label>{t('reveal.bulkEnrichSidebar.contactsFilter')}</label>
          <SelectItemDropdown
            items={[
              {
                value: 'true',
                content: t(
                  'reveal.bulkEnrichSidebar.contacts.onlyWithoutEmail',
                ),
              },
              {
                value: 'false',
                content: t('reveal.bulkEnrichSidebar.contacts.allContacts'),
              },
            ]}
            emptyState={null}
            searchable={false}
            totalItemCount={2}
            onItemSelected={(item) =>
              setOnlyContactsWitoutEmail(item.value === 'true')
            }
            className={styles.dropdown}
            trigger={
              <DropdownControlsContext.Consumer>
                {({ toggleDropdown }) => (
                  <button
                    type='button'
                    onClick={toggleDropdown}
                    className={styles.trigger}
                  >
                    <DropdownContainer>
                      <DropdownTrigger>
                        {onlyContactsWitoutEmail
                          ? t(
                              'reveal.bulkEnrichSidebar.contacts.onlyWithoutEmail',
                            )
                          : t('reveal.bulkEnrichSidebar.contacts.allContacts')}
                      </DropdownTrigger>
                    </DropdownContainer>
                  </button>
                )}
              </DropdownControlsContext.Consumer>
            }
          />
        </Form.Field>

        <Form.Field>
          <label>{t('reveal.bulkEnrichSidebar.autoSaveMailOption')}</label>
          <SelectItemDropdown
            items={autoSaveMailOptions}
            emptyState={null}
            searchable={false}
            totalItemCount={2}
            onItemSelected={(item) =>
              setAutoSaveMailStrategy(item.value as EmailReplacementPolicy)
            }
            className={styles.dropdown}
            trigger={
              <DropdownControlsContext.Consumer>
                {({ toggleDropdown }) => (
                  <button
                    type='button'
                    onClick={toggleDropdown}
                    className={styles.trigger}
                  >
                    <DropdownContainer>
                      <DropdownTrigger>
                        {t(
                          `reveal.bulkEnrichSidebar.autoSaveEmail.${autoSaveMailStrategy}`,
                        )}
                      </DropdownTrigger>
                    </DropdownContainer>
                  </button>
                )}
              </DropdownControlsContext.Consumer>
            }
          />
        </Form.Field>

        {shouldDisplayIntegrationSelector && (
          <Form.Field>
            <label>{t('reveal.bulkEnrichSidebar.integrationOption')}</label>
            <SelectItemDropdown
              items={[
                {
                  value: inheritedIntegration?.id as string, // TODO: replace with inherited integration id
                  content: t(
                    'reveal.bulkEnrichSidebar.integrations.clientIntegration',
                  ),
                },
                {
                  value: ownIntegration?.id as string,
                  content: t(
                    'reveal.bulkEnrichSidebar.integrations.ownIntegration',
                  ),
                },
              ]}
              emptyState={null}
              searchable={false}
              totalItemCount={2}
              onItemSelected={(item) => setIntegrationId(item.value)}
              className={styles.dropdown}
              trigger={
                <DropdownControlsContext.Consumer>
                  {({ toggleDropdown }) => (
                    <button
                      type='button'
                      onClick={toggleDropdown}
                      className={styles.trigger}
                    >
                      <DropdownContainer>
                        <DropdownTrigger>
                          {integrationId === ownIntegration?.id
                            ? t(
                                'reveal.bulkEnrichSidebar.integrations.ownIntegration',
                              )
                            : t(
                                'reveal.bulkEnrichSidebar.integrations.clientIntegration',
                              )}
                        </DropdownTrigger>
                      </DropdownContainer>
                    </button>
                  )}
                </DropdownControlsContext.Consumer>
              }
            />
          </Form.Field>
        )}
      </Form>
    </SidePanel>
  );
};

export default BulkEnrichSidebar;
