import * as SweetEvaluatorTypes from './types';
import SweetEvaluator from './evaluator';

const getSweetEvaluator = ({
  getExternalExpressionEvaluatorFromType,
}: {
  getExternalExpressionEvaluatorFromType: SweetEvaluatorTypes.ExpressionEvaluatorFromType | null;
}): SweetEvaluator => {
  return new SweetEvaluator({ getExternalExpressionEvaluatorFromType });
};

export { SweetEvaluatorTypes, getSweetEvaluator };
