import _, { compose } from 'underscore';
import React, { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Segment } from 'semantic-ui-react';
import * as Sentry from '@sentry/browser';

import useClientId from '@/hooks/router/useClientId';

import { useClientTemplatesAndOffers } from '@/hocs/templates/withClientTemplatesAndOffers';
import useUserTableViewPreferences from '@/graphql/hooks/users/useUserDisplayPreferences';
import { lowerCaseAndUnaccent } from '@/common';
import { getFullname } from '@/common/helpers/person';
import { useDuplicateTemplate } from '../../../hocs/templates/withDuplicateTemplate';
import { useDeleteTemplate } from '../../../hocs/templates/withDeleteTemplate';
import { useRestoreTemplate } from '../../../hocs/templates/withRestoreTemplate';
import contextToProps from '../../../hocs/contextToProps';

import TemplatesTable from './TemplatesTable';

import TemplatesEmptyState from './TemplatesEmptyState';
import './TemplatesTable.css';

import { sortText } from '../Sequences/SequencesList';

const TEMPLATE_SORT_FUNCTIONS = {
  name: (templates, order) => {
    return [...templates].sort((t1, t2) => {
      const t1Name = lowerCaseAndUnaccent(t1.title);
      const t2Name = lowerCaseAndUnaccent(t2.title);
      return sortText(t1Name, t2Name, order);
    });
  },
  offers: (templates, order) => {
    return [...templates];
  },
  author: (templates, order) => {
    return [...templates].sort((t1, t2) => {
      const a1Name = getFullname(t1.author);
      const a2Name = getFullname(t2.author);
      return sortText(a1Name, a2Name, order);
    });
  },
  'last-edition-date': (templates, order) => {
    return [...templates].sort((t1, t2) => {
      const d1 = new Date(t1.lastEditionDate);
      const d2 = new Date(t2.lastEditionDate);
      return (d1 - d2) * order;
    });
  },
};

const ClientTemplates = ({ onShowNotification }) => {
  const clientId = useClientId();
  const { t } = useTranslation();
  const [displayArchivedTemplates, setDisplayArchivedTemplates] = useState(
    false,
  );

  const { templates, loading, refetch } = useClientTemplatesAndOffers(clientId);

  const duplicateTemplate = useDuplicateTemplate(clientId);
  const restoreTemplate = useRestoreTemplate(clientId);
  const deleteTemplate = useDeleteTemplate(clientId);

  const handleDeleteTemplate = (template) => {
    deleteTemplate({ variables: { id: template.id } })
      .then(() => {
        onShowNotification({
          message: t('templates.notifications.deletedWithSuccess'),
          level: 'success',
        });
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  };

  const handleAfterEditTemplate = () => refetch();

  const handleRestoreTemplate = (template) => {
    restoreTemplate({ variables: { id: template.id } })
      .then(() => {
        onShowNotification({
          message: t('templates.notifications.restoredWithSuccess'),
          level: 'success',
        });
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  };

  const handleDuplicateTemplate = (template) => {
    duplicateTemplate({ variables: { id: template.id } }).catch((e) => {
      Sentry.captureException(e);
    });
  };
  const tableId = 'automatisation-templates';
  const {
    userTableViewPreferences,
    updateUserTableViewPreferences,
  } = useUserTableViewPreferences({
    tableId,
    defaultValues: {
      tableId,
      sortingOptions: {
        sortBy: [
          {
            key: 'name',
            order: 'ascending',
          },
        ],
      },
    },
  });

  const setSort = useCallback(
    ({ key, order }) => {
      updateUserTableViewPreferences({
        tableId,
        sortingOptions: {
          sortBy: [
            {
              key,
              order: order === 1 ? 'ascending' : 'descending',
            },
          ],
        },
      });
    },
    [updateUserTableViewPreferences],
  );

  const { sortKey, sortOrder } = useMemo(() => {
    const [{ key, order }] = userTableViewPreferences?.sortingOptions
      ?.sortBy || [{ key: 'name', order: 'ascending' }];

    return { sortKey: key, sortOrder: order === 'ascending' ? 1 : -1 };
  }, [userTableViewPreferences]);

  const sortedTemplates = useMemo(() => {
    const sortFunction = TEMPLATE_SORT_FUNCTIONS[sortKey] || ((tpml) => tpml);
    return sortFunction(templates, sortOrder);
  }, [templates, sortKey, sortOrder]);

  const activeTemplates = _.filter(
    sortedTemplates,
    ({ isArchived }) => !isArchived,
  );
  const archivedTemplates = _.where(sortedTemplates, { isArchived: true });

  if (loading) {
    return null;
  }

  return (
    <>
      {!_.isEmpty(activeTemplates) ? (
        <div className='templates-section'>
          <TemplatesTable
            sortKey={sortKey}
            sortOrder={sortOrder}
            onSort={setSort}
            templates={activeTemplates}
            clientId={clientId}
            handleAfterEditTemplate={handleAfterEditTemplate}
            duplicateTemplate={handleDuplicateTemplate}
            handleDeleteTemplate={handleDeleteTemplate}
          />
        </div>
      ) : (
        <Segment>
          <TemplatesEmptyState onClick={() => {}} />
        </Segment>
      )}
      {!_.isEmpty(archivedTemplates) && (
        <>
          <div className='archived-templates-link-box'>
            <button
              type='button'
              className='link-button'
              onClick={() =>
                setDisplayArchivedTemplates(
                  (currentDisplayArchivedTemplates) =>
                    !currentDisplayArchivedTemplates,
                )
              }
            >
              {displayArchivedTemplates
                ? t('templates.buttons.hideArchivedTemplates')
                : t('templates.buttons.showArchivedTemplates')}
            </button>
          </div>
          {displayArchivedTemplates && (
            <div className='templates-section'>
              <TemplatesTable
                sortKey={sortKey}
                sortOrder={sortOrder}
                onSort={setSort}
                templates={archivedTemplates}
                clientId={clientId}
                restoreTemplate={handleRestoreTemplate}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default compose(contextToProps)(ClientTemplates);
