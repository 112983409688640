import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DynamicCandidateRecommendationBlurb = ({ 
  profileId, 
  recommendationBlurb 
}: { 
  profileId: string; 
  recommendationBlurb: string; 
}) => {

  if (recommendationBlurb === 'loading') {
    return <LoadingRecommendationBlurb profileId={profileId} />
  }

  return (
    <div>
      <ExplanationCard text={recommendationBlurb} />
    </div>
  )
}

const GET_PROFILE_RECOMMENDED_MISSIONS = gql`
query getProfileRecommendedMissions($searchPoolId: ID!, $profileId: ID!) {
  searchPool(id: $searchPoolId) {
    id
		profile(id:$profileId) {
			id
			recommendedMissions {
				missionId
				arguments {
					type
					...on ReviewArgument {
						content {
							default
						}
					}
				}
			}
		}
  }
}
`;

const LoadingRecommendationBlurb = ({ profileId } : { profileId: string }) => {

  const { t } = useTranslation();

  useQuery(GET_PROFILE_RECOMMENDED_MISSIONS, {
    variables: {
      searchPoolId: 'reveal',
      profileId,
    },
    pollInterval: 2000 
  });

  return (
    <ExplanationCard 
      text={t('reveal.recommendedProfiles.recommendationBlurbComputing')} 
    />
  )
}

const ExplanationCard = ({ text }: { text: string }) => {
  return (
    <div style={{ 
      marginTop: 10,
      marginBottom: 10,
      padding: 10, 
      border: '1px solid lightgrey',
      borderRadius: 5
    }}>
      {text}
    </div>
  )
}

export default DynamicCandidateRecommendationBlurb