import React, { ComponentProps, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import { Option } from '../Filters/options';

type FilterDropdownProps = {
  options: Option[];
  value: string[];
  onChange: ComponentProps<typeof Form.Dropdown>['onChange'];
};

const FilterDropdown: VFC<FilterDropdownProps> = ({
  options,
  onChange,
  value,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Dropdown
      fluid
      selection
      multiple
      search
      placeholder={t('common.select')}
      options={options}
      onChange={onChange}
      value={value}
      searchInput={{ 'data-form-type': 'other' }}
    />
  );
};

export default FilterDropdown;
