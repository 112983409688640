import React from 'react';
import styles from './Warning.module.less';

interface WarningProps {
  title: string;
  description: string;
}

const Warning: React.FC<WarningProps> = ({ title, description, children }) => (
  <div className={styles.warning}>
    <div>
      <span className={styles.title}>{title}</span>
      <span className={styles.description}>{description}</span>
    </div>
    {children}
  </div>
);

export default Warning;
