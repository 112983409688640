import React from 'react';
import withUserFromJWToken from '../hocs/users/withUserFromJWToken';

import './UserInitialThumbnail.css';

const UserInitialThumbnail = ({ clientId, user }) => {
  const { firstname, lastname } = user || {};
  const firstnameInitial = (firstname || '').charAt(0).toUpperCase();
  const lastnameInitial = (lastname || '').charAt(0).toUpperCase();
  const userInitials = `${firstnameInitial}${lastnameInitial}`.trim()
  || (clientId || '').slice(0, 2).toUpperCase()
  || '';

  return (
    <div className='user-initial-thumbnail'>
      {userInitials}
    </div>
  )
};

export default withUserFromJWToken(UserInitialThumbnail);