import React from 'react';
import { 
  HiresweetLibProvider, 
  ProfileContainer, 
  ProfileEditionForm, 
  ProfileEditionSubmitButton, 
  ProfileFirstname,
  ProfileFirstnameEditionInput, 
  ProfileLastname,
  ProfileLastnameEditionInput,
  ProfileHeadline,
  ProfileHeadlineEditionInput,
  ProfileEmail,
  ProfileEmailEditionInput,
  ProfilePhoneNumber,
  ProfilePhoneNumberEditionInput,
  ProfileLocation,
  ProfileLocationEditionInput,
  useProfileField
} from '@hiresweet/hiresweet-lib';

import useClientId from '@/hooks/router/useClientId';


const MyFirstNameInput = () => {
  const { loading, initialValue, onChange } = useProfileField(ProfileFirstnameEditionInput);

  if (loading) {
    return null;
  }

  return (
    <input defaultValue={initialValue} onChange={({ target }) => { onChange(target.value); } }/>
  )
}

const PlaygroundB5 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30, background: 'white' }}>
      <h1>B5 - Profile Forms</h1>
      <HiresweetLibProvider
        theme={theme}
        clientId={clientId}
      >
        <ProfileContainer profileId='julien-deoux-1BI1TI' >

          <h4>Display</h4>
          <ProfileFirstname /><br/>
          <ProfileLastname /><br/>
          <ProfileHeadline /><br/>
          <ProfileEmail /><br/>
          <ProfilePhoneNumber /><br/>
          <ProfileLocation /><br/>
          <br/>
          
          <h4>Form 1</h4>
          <ProfileEditionForm>
            <ProfileFirstnameEditionInput label='Firstname' />
            <br />
            <ProfileLastnameEditionInput label='Lastname' />
            <br />
            <ProfileHeadlineEditionInput label='Headline' />
            <br />
            <ProfileEmailEditionInput label='Email' />
            <br />
            <ProfilePhoneNumberEditionInput label='Phone' />
            <br />
            <ProfileLocationEditionInput label='Location' />
            <br />
            <ProfileEditionSubmitButton label='Sauvegarder 1' />
          </ProfileEditionForm>

          <h4>Form 2</h4>
          <ProfileEditionForm>
            <MyFirstNameInput />
            <br />
            <ProfileEditionSubmitButton label='Sauvegarder 2' />
          </ProfileEditionForm>

        </ProfileContainer>
    
      </HiresweetLibProvider>
    </div>
  );
};

export default PlaygroundB5;
