import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Application } from '@/common/reveal/SourceData.type';

import { ATStype, BOONDMANAGER } from '@/common/reveal';
import styles from './JobApplication.module.less';
import { getApplicationStageAndStatusText } from '../../ATSData.helpers';

export const formatDate = (lang: string, date?: string) => {
  if (!date) {
    return null;
  }

  const DateFormatter = new Intl.DateTimeFormat(lang, {
    // Our typescript version/types do not know that this exists
    // and block the app build
    // @ts-ignore
    dateStyle: 'long',
  });

  return DateFormatter.format(new Date(date));
};

interface GenericJobApplicationProps {
  title?: string;
  stage?: string | null;
  date?: string | null;
}

const GenericJobApplication: FC<GenericJobApplicationProps> = ({
  title,
  stage,
  date,
}) => {
  const { i18n } = useTranslation();
  return (
    <div className={styles.application}>
      {title && <span className={styles.jobTitle}>{title}</span>}
      <div className={styles.applicationInfo}>
        {stage && (
          <span className={styles.applicationDescription}>{stage}</span>
        )}
        {date && (
          <span className={styles.applicationDate}>
            {formatDate(i18n.resolvedLanguage, date)}
          </span>
        )}
      </div>
    </div>
  );
};

interface JobApplicationProps {
  application: Application;
  atsId: ATStype;
}

const JobApplication: FC<JobApplicationProps> = ({ atsId, application }) => {
  const currentStage = getApplicationStageAndStatusText(application, atsId);

  // Boondmanager has no "jobs"
  if (atsId === BOONDMANAGER) {
    return (
      <GenericJobApplication
        title={currentStage}
        date={application.lastActivityDate}
      />
    );
  }

  const [job] = application.jobs || [];
  if (!job) {
    return null;
  }

  return (
    <GenericJobApplication
      title={job.title}
      stage={currentStage}
      date={application.lastActivityDate}
    />
  );
};

export default JobApplication;
