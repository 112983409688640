import React from 'react';
import { useTranslation } from 'react-i18next';
import GenericButton from '@/components/Common/GenericButton';

import styles from './Actions.module.less';

interface ActionsProps {
  step: number;
  nbSteps: number;
  toNextStep: () => void;
  toPreviousStep: () => void;
  onClose: () => void;
}
const Actions: React.FC<ActionsProps> = ({
  step,
  nbSteps,
  toNextStep,
  toPreviousStep,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.actions}>
      {step > 0 && (
        <GenericButton primacy='secondary' size='big' onClick={toPreviousStep}>
          {t('common.previous')}
        </GenericButton>
      )}
      {step < nbSteps - 1 ? (
        <GenericButton size='big' onClick={toNextStep}>
          {t('common.next')}
        </GenericButton>
      ) : (
        <GenericButton size='big' onClick={onClose}>
          {t('reveal.onboardingModal.finish')}{' '}
        </GenericButton>
      )}
    </div>
  );
};

export default Actions;
