import _ from 'underscore';
import { DataVizColors } from '@/less/colors';
import { getFullname } from '@/common/helpers/person';
import { AuthorDailyEvent } from '@/types/statistics/authorStatistics';
import { DailyEvent } from '@/types/statistics/dailyEvent';
import { StackItem } from '@/types/statistics/stackItem';

export const convertAuthorDailyEvent = (
  event: AuthorDailyEvent,
): DailyEvent => ({
  day: event.day,
  values: _.mapObject(
    event.authorStats,
    (authorStatItem) => authorStatItem.count,
  ),
});

export const getFullnameMap = (
  events: AuthorDailyEvent[],
): Record<string, string> => {
  const result = {} as Record<string, string>;
  _.each(events, ({ authorStats }) => {
    _.each(authorStats, ({ author }) => {
      result[author.email] = getFullname(author);
    });
  });
  return result;
};

export const getAuthorStack = (
  fullnameMap: Record<string, string>,
): StackItem[] =>
  _.map(_.keys(fullnameMap), (email, index) => ({
    datakey: email,
    color: DataVizColors[index % DataVizColors.length],
    displayedText: fullnameMap[email],
  }));
