import React, { FC } from 'react';
import _ from 'underscore';

import useClientId from '@/hooks/router/useClientId';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';

import ProfilesViewFilterDropdown from './ProfilesViewFilterDropdown';
import ProfilesViewFilterPills from './ProfilesViewFilterPills';

const ProfilesViewFilterSettings: FC = () => {
  const clientId = useClientId();
  const { permissions } = useClientPermissions(clientId);
  if (!permissions?.canFilterProjectProfiles) {
    return null;
  }

  return (
    <>
      <ProfilesViewFilterPills />
      <ProfilesViewFilterDropdown />
    </>
  );
};

export default ProfilesViewFilterSettings;
