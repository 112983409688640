import { TFunction } from 'i18next';
import _ from 'underscore';

import { remotePolicies } from '@/types/remotePolicy';
import locationOptions from '@/common/options/locationOptions.json';
import { isEmptyRawLocationAndRemoteCriteria } from '@/common/helpers/locationAndRemote';
import { MAX_XP } from '@/containers/Parameters/OfferCriteria/helpers';
import { SearchCriteria, StorageSearchCriteria } from '@/types/search';
import { filterHasNoEffect } from './WatchCollectFiltersModal';

export const fixBodyAndHeader = (selector?: string): void => {
  const { scrollY } = window;
  document.body.style.position = 'fixed';
  document.body.style.minWidth = '100vw';
  document.body.style.top = `-${scrollY}px`;
  const header = document.querySelector<HTMLDivElement>(
    selector || '.parametersview.watch-collect .top-header',
  );
  if (header) {
    header.style.position = 'fixed';
    header.style.top = '0px';
  }
};

export const releaseBodyAndHeader = (selector?: string): void => {
  const scrollY = document.body.style.top;
  document.body.style.position = '';
  document.body.style.top = '';
  document.body.style.minWidth = '';
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
  const header = document.querySelector<HTMLDivElement>(
    selector || '.parametersview.watch-collect .top-header',
  );
  if (header) {
    header.style.position = '';
    header.style.top = '';
  }
};

export const getLocationsAndRemoteOptions = (
  t: TFunction,
): { value: string; text: string }[] => {
  const result: { value: string; text: string }[] = [];
  _.each(locationOptions, ({ value: locationId, text }) => {
    _.each(remotePolicies, (remotePolicy) => {
      result.push({
        value: `${locationId}__${remotePolicy}`,
        text: `${text} (${t(`criteria.remote.${remotePolicy}`)})`,
      });
    });
  });
  return result;
};

export const formatForSearchQuery = ({
  locationsAndRemote,
  schoolTypes,
  jobPositions,
  jobPositionsOperator,
  experienceYearsMin,
  experienceYearsMax,
  skills,
  skillsOperator,
  salaryMin,
  salaryMax,
  contracts,
  responsibilities,
  excludeHidden,
  excludeProfilesInJob,
  includeProfilesInJob,
  shownInterestOnly,
}: StorageSearchCriteria): SearchCriteria => ({
  ...(!isEmptyRawLocationAndRemoteCriteria(locationsAndRemote) && {
    locationsAndRemote,
  }),
  ...(!_.isEmpty(schoolTypes) && { schoolTypes }),
  ...(!_.isEmpty(jobPositions) && { jobPositions }),
  ...(!_.isEmpty(skills) && { skills }),
  ...(!_.isEmpty(contracts) && { contracts }),
  ...(!filterHasNoEffect({ responsibilities }) && { responsibilities }),
  ...(jobPositionsOperator && { jobPositionsOperator }),
  ...(skillsOperator && { skillsOperator }),
  ...(salaryMin && { salaryMin: parseInt(salaryMin, 10) }),
  ...(salaryMax && { salaryMax: parseInt(salaryMax, 10) }),
  ...(experienceYearsMin && {
    experienceYearsMin: parseInt(experienceYearsMin, 10),
  }),
  ...(experienceYearsMax &&
    parseInt(experienceYearsMax, 10) !== MAX_XP && {
      experienceYearsMax: parseInt(experienceYearsMax, 10),
    }),
  ...(_.isBoolean(excludeHidden) && { excludeHidden }),
  ...(_.isBoolean(excludeProfilesInJob) && { excludeProfilesInJob }),
  ...(_.isString(includeProfilesInJob) && { includeProfilesInJob }),
  ...(_.isBoolean(shownInterestOnly) && { shownInterestOnly }),
});
