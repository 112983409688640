import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import GenericModal from '@/components/Common/GenericModal';
import EmailContext from '@/context/EmailContext';
import { EMPTY_SENDER_DETAILS } from '../../Settings/Senders/NewSenderModal';
import TargetAccountModalStep1 from './Step1';
import TargetAccountModalStep2 from './Step2';
import WrongEmailModal from './WrongEmailModal';

import './TargetAccountModal.less';

interface TargetAccountModalProps {
  showConnectModal: boolean;
  setShowConnectModal: (value: boolean) => void;
  addressToConnect: string;
  refetch: () => void;
}

const STEPS = {
  ADD_ACCOUNT: 'connect-to-mail',
  ACCOUNT_DETAILS: 'account-details',
};

const TargetAccountModal: React.FC<TargetAccountModalProps> = ({
  showConnectModal,
  setShowConnectModal,
  addressToConnect,
  refetch,
}) => {
  const { currentAddress, onTriggerSignOut } = useContext(EmailContext);
  const [connectModalStep, setConnectModalStep] = useState(STEPS.ADD_ACCOUNT);
  const [senderDetails, setSenderDetails] = useState(EMPTY_SENDER_DETAILS);
  const [wrongEmailModalOpen, setWrongEmailModalOpen] = useState(false);
  const [hasTriedConnexion, setHasTriedConnexion] = useState(false);

  const submitSender = async () => {
    setShowConnectModal(false);
  };

  useEffect(() => {
    if (
      hasTriedConnexion &&
      currentAddress &&
      currentAddress !== addressToConnect
    ) {
      setWrongEmailModalOpen(true);
    }
  }, [currentAddress, addressToConnect, hasTriedConnexion]);

  return (
    <GenericModal
      open={showConnectModal}
      onClose={() => {
        refetch();
        setShowConnectModal(false);
      }}
      className={classNames('target-account-modal', 'add-account')}
      size='small'
    >
      {connectModalStep === STEPS.ADD_ACCOUNT && (
        <TargetAccountModalStep1
          setModalOpen={setShowConnectModal}
          toNextStep={() => setConnectModalStep(STEPS.ACCOUNT_DETAILS)}
          addressToConnect={addressToConnect}
          setHasTriedConnexion={setHasTriedConnexion}
        />
      )}
      {connectModalStep === STEPS.ACCOUNT_DETAILS && (
        <TargetAccountModalStep2
          senderDetails={senderDetails}
          setSenderDetails={setSenderDetails}
          toPrevStep={() => setConnectModalStep(STEPS.ADD_ACCOUNT)}
          toNextStep={submitSender}
        />
      )}
      <WrongEmailModal
        open={wrongEmailModalOpen}
        onClose={() => {
          setHasTriedConnexion(false);
          onTriggerSignOut();
        }}
        setOpen={setWrongEmailModalOpen}
        addressToConnect={addressToConnect}
        currentAddress={currentAddress}
      />
    </GenericModal>
  );
};

export default TargetAccountModal;
