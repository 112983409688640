import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SmallResumeData from '../../graphql/fragments/SmallResumeData';
import PipeStepStat from '../../graphql/fragments/PipeStepStat';
import { query as ClientTemplatesQuery } from './withClientTemplatesAndOffers';
import { query as ClientOffersQuery } from '../offers/withClientOffers';

export const ProfilesQuery = gql`
  query getCreateTemplateOfferProfiles(
    $offerId: ID!
    $step: String!
    $search: String!
  ) {
    offer(id: $offerId) {
      id
      pipeStepStats {
        ...PipeStepStat
      }
      profiles(step: $step, search: $search) {
        id
        contactData {
          defaultThreadId
          defaultSubject
          draftComment
        }
        resumeData {
          ...SmallResumeData
        }
        lastStepUpdateTimestamp
      }
    }
  }
  ${SmallResumeData}
  ${PipeStepStat}
`;

export const mutation = gql`
  mutation createTemplate(
    $title: String!
    $subject: String!
    $body: String!
    $snippets: [SnippetInput!]
    $clientId: ID!
    $offerId: ID
    $messagePosition: Int
    $sequenceId: ID
  ) {
    createTemplate(
      title: $title
      subject: $subject
      body: $body
      snippets: $snippets
      clientId: $clientId
      offerId: $offerId
      messagePosition: $messagePosition
      sequenceId: $sequenceId
    ) {
      id
    }
  }
`;

export default graphql(mutation, {
  props: ({ ownProps, mutate }) => ({
    createTemplate: ({
      title,
      subject,
      body,
      snippets,
      isNewOfferDefault,
      messagePosition,
      sequenceId,
    }) =>
      mutate({
        variables: {
          title,
          subject,
          body,
          offerId: isNewOfferDefault ? ownProps.offerId : null,
          messagePosition,
          clientId: ownProps.clientId,
          sequenceId,
          snippets,
        },
        awaitRefetchQueries: !!ownProps.awaitRefetchTemplates,
        refetchQueries: [
          {
            query: ClientTemplatesQuery,
            variables: { clientId: ownProps.clientId },
          },
          {
            query: ClientOffersQuery,
            variables: { clientId: ownProps.clientId },
          },
          {
            query: ProfilesQuery,
            variables: {
              offerId:
                isNewOfferDefault && ownProps.offerId
                  ? ownProps.offerId
                  : 'nada',
              search: '',
              step: 'pending',
            },
          },
        ],
      }),
  }),
});
