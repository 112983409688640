import DatePicker from 'react-datepicker';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Form, Placeholder, Dropdown } from 'semantic-ui-react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import './AddToCampaignSidebar.css';
import GenericButton from '@/components/Common/GenericButton';
import { useAddProfilesToCampaignMutation } from '@/graphql/AddProfilesToCampaignMutation';
import SidePanel from '@/components/SidePanel';
import { Campaign } from '@/types/campaign';
import ColoredBox from '@/common/ColoredBox';
import useUserSenders from '@/graphql/hooks/users/useUserSenders';
import useNotificationSystem from '../../../../../hooks/common/useNotificationSystem';
import { CLIENT_MINI_CAMPAIGNS } from '../../../../../containers/Parameters/Campaigns/queries';

interface AddToCampaignSidebarProps {
  clientId: string;
  candidates: any[];
  onClose: () => void;
}

const AddToCampaignSidebar: React.FC<AddToCampaignSidebarProps> = ({
  clientId,
  candidates,
  onClose,
}) => {
  const { t } = useTranslation();
  const [selectedCampaignId, setSelectedCampaignId] = useState('');
  const [campaignScheduleType, setCampaignScheduleType] = useState('');
  const [campaignScheduleDate, setCampaignScheduleDate] = useState(new Date());
  const [sender, setSender] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const variables = { clientId, filters: { activeOnly: true } };
  const clientCampaigns = useQuery(CLIENT_MINI_CAMPAIGNS, {
    variables,
  });
  const clientCampaignsList = _.filter(
    clientCampaigns?.data?.client?.campaigns,
    ({ isArchived }) => !isArchived,
  );

  const { data: userSendersData } = useUserSenders({
    granted: true,
    owned: true,
  });

  const senders = userSendersData?.user?.senders;

  const notifications = useNotificationSystem();

  const [addProfilesToCampaign] = useAddProfilesToCampaignMutation();

  const onSubmit = async () => {
    setSubmitting(true);
    try {
      const filteredCandidates = _.filter(
        candidates,
        (candidate) => !candidate.privacyState?.markedAsDoNotContact,
      );
      const profileIds = _.pluck(filteredCandidates, 'id');

      const { data } = await addProfilesToCampaign({
        variables: {
          searchPoolId: 'reveal',
          input: {
            campaignId: selectedCampaignId,
            scheduleDate: campaignScheduleDate,
            profileIds,
            assignedSender: {
              type: 'shared-sender',
              senderId: sender,
            },
          },
        },
      });

      const results = data?.searchPool?.addProfilesToCampaign?.results || [];
      const successes = _.filter(results, ({ success }) => success);
      const errors = _.filter(results, ({ success }) => !success);

      // TODO: add a 'warning' / orange level for future use (instead of showing 2 notifications ?)
      if (!_.isEmpty(errors)) {
        _.map(errors, (error) => {
          notifications.error(
            t(`reveal.campaignSideBar.errors.${error.error}`, {
              name: `${error.profile.resumeData.firstname} ${error.profile.resumeData.lastname}`,
            }),
          );
        });
      }

      if (!_.isEmpty(successes)) {
        // TODO: custom notif with link
        notifications.success(
          t('applyCampaignDropdown.success', {
            count: successes.length > 1 ? 2 : 1,
            nb: successes.length,
          }),
        );
        // TODO: GO TO LASER FOCUS (?)
      } else {
        // setSubmitting(false);
        // onClose();
      }

      setSubmitting(false);
      onClose();
    } catch (e) {
      notifications.error(t('reveal.recommendedProfiles.campaign.addError'));
      setSubmitting(false);
      onClose();
    }
  };

  const footerContent = (
    <GenericButton
      size='big'
      disabled={
        !selectedCampaignId || !sender || !campaignScheduleType || submitting
      }
      onClick={onSubmit}
    >
      {t('reveal.campaignSideBar.addToCampaignButton')}
    </GenericButton>
  );

  const handleClose = () => {
    if (!submitting) {
      onClose();
    }
  };

  return (
    <SidePanel
      className='add-to-campaign-sidebar'
      title={`${t('reveal.campaignSideBar.addToCampaign')} ${t(
        'reveal.campaignSideBar.candidate',
        {
          count: candidates?.length || 0,
        },
      )} ${t('reveal.campaignSideBar.toCampaign')}`}
      footerContent={footerContent}
      onClose={handleClose}
    >
      {!_.isEmpty(clientCampaignsList) ? (
        <AddToCampaignForm
          clientCampaignsList={clientCampaignsList}
          onSelectedCampaignId={setSelectedCampaignId}
          campaignScheduleType={campaignScheduleType}
          onCampaignScheduleType={setCampaignScheduleType}
          campaignScheduleDate={campaignScheduleDate}
          onCampaignScheduleDate={setCampaignScheduleDate}
          profileAmount={candidates?.length || 0}
          senders={senders}
          onSelectSender={setSender}
        />
      ) : (
        <>
          <Placeholder>
            <Placeholder.Header style={{ height: '14px', width: '100px' }}>
              <Placeholder.Line length='full' />
            </Placeholder.Header>
          </Placeholder>
          <Placeholder>
            <Placeholder.Header style={{ height: '38px' }}>
              <Placeholder.Line length='full' />
            </Placeholder.Header>
          </Placeholder>
        </>
      )}
    </SidePanel>
  );
};

interface AddToCampaignFormProps {
  onSelectedCampaignId: (id: string) => void;
  clientCampaignsList: Campaign[];
  campaignScheduleType: string;
  onCampaignScheduleType: (value: string) => void;
  campaignScheduleDate: Date;
  onCampaignScheduleDate: (value: Date) => void;
  profileAmount: number;
  senders: any[];
  onSelectSender: (value: string) => void;
}

export const AddToCampaignForm: React.FC<AddToCampaignFormProps> = ({
  onSelectedCampaignId,
  clientCampaignsList,
  campaignScheduleType,
  onCampaignScheduleType,
  campaignScheduleDate,
  onCampaignScheduleDate,
  profileAmount,
  senders,
  onSelectSender,
}) => {
  const { t } = useTranslation();
  const campaignOptions = _.map(clientCampaignsList, (campaign) => ({
    key: campaign.id,
    value: campaign.id,
    text: campaign.title,
  }));

  const senderOptions = _.map(senders, (sender) => ({
    key: sender.id,
    value: sender.id,
    text: `${sender.alias} (${sender.senderAddress})`,
  }));

  const scheduleOptions = [
    {
      key: 'schedule-now',
      value: 'schedule-now',
      text: t('reveal.campaignSideBar.scheduleNow'),
    },
    {
      key: 'schedule-later',
      value: 'schedule-later',
      text: t('reveal.campaignSideBar.scheduleLater'),
    },
    {
      key: 'not-scheduled',
      value: 'not-scheduled',
      text: t('reveal.campaignSideBar.notScheduled'),
    },
  ];
  return (
    <Form className='tasks-form'>
      <Form.Field>
        <label>{t('reveal.campaignSideBar.campaign')}</label>

        <div id='campaign-dropdown'>
          <Dropdown
            selection
            search
            fluid
            placeholder={t('reveal.campaignSideBar.campaignPlaceHolder')}
            options={campaignOptions}
            onChange={(e, data) => onSelectedCampaignId(data.value as string)}
          />
        </div>
      </Form.Field>
      <Form.Field>
        <label>{t('reveal.campaignSideBar.sender')}</label>

        <div id='sender-dropdown'>
          <Dropdown
            selection
            search
            fluid
            placeholder={t('reveal.campaignSideBar.senderPlaceHolder')}
            options={senderOptions}
            onChange={(e, data) => onSelectSender(data.value as string)}
          />
        </div>
      </Form.Field>
      <Form.Field>
        <label>{t('reveal.campaignSideBar.schedule')}</label>

        <div id='schedule-dropdown'>
          <Dropdown
            selection
            search
            fluid
            placeholder={t('reveal.campaignSideBar.schedulePlaceHolder')}
            options={scheduleOptions}
            onChange={(e, data) => onCampaignScheduleType(data.value as string)}
          />
        </div>
      </Form.Field>
      {campaignScheduleType === 'schedule-later' && (
        <Form.Field>
          <DatePicker
            className='campaign-date-picker'
            popperPlacement='top'
            showTimeSelect
            selected={campaignScheduleDate}
            onChange={onCampaignScheduleDate}
            dateFormat='MMMM d, yyyy'
          />
        </Form.Field>
      )}
      {campaignScheduleType && (
        <ColoredBox color='blue'>
          {t(`reveal.campaignSideBar.description.${campaignScheduleType}`, {
            count: profileAmount,
          })}
        </ColoredBox>
      )}
    </Form>
  );
};

export default AddToCampaignSidebar;
