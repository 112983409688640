import gql from 'graphql-tag';

export default gql`
  fragment ProfileEnrichmentResultData on ProfileEnrichmentResultData {
    enrichmentResults {
      date
      author {
        firstname
        lastname
        email
      }
      phoneNumbers {
        type
        value
      }
      emails {
        value
        type
      }
    }
  }
`;
