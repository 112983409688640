import React, { VFC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import GenericButton from '@/components/Common/GenericButton';
import useGetSearchFromText from '@/graphql/hooks/clients/useGetSearchFromText';
import GenericTextArea from '@/components/Common/GenericTextArea';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import useClientId from '@/hooks/router/useClientId';

import styles from './AICriteriaGenerator.module.less';

type Props = {
  overwriteCriteria: (criteria: { free?: string }) => void;
};

const AICriteriaGenerator: VFC<Props> = ({ overwriteCriteria }) => {
  const clientId = useClientId();
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const [getSearchFromText, { loading }] = useGetSearchFromText();
  const { permissions } = useClientPermissions(clientId);

  const handleClick = useCallback(async () => {
    const { search } = await getSearchFromText(text);
    if (search.criteria?.free) {
      overwriteCriteria({ free: search.criteria.free });
    }
  }, [getSearchFromText, overwriteCriteria, text]);

  if (!permissions?.aiSearchFromText) {
    return null;
  }

  return (
    <>
      <GenericTextArea
        className={styles.input}
        value={text}
        onValue={setText}
        placeholder={t('reveal.searchView.searchFromText.placeholder')}
      />
      <GenericButton onClick={handleClick} disabled={loading}>
        {t('reveal.searchView.searchFromText.submit')}
      </GenericButton>
    </>
  );
};

export default AICriteriaGenerator;
