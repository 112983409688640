import React from 'react';
import _ from 'underscore';
import { withTranslation } from 'react-i18next';
import { Loader, Table } from 'semantic-ui-react';
import { formatNumber, formatPercentWithSymbol } from '../../utils';

import './PipelineGraph.css';

export const PipeLineGraphStub = () => (
  <div className='pipeline-graph'>
    <Loader active inline='centered' size='large' />
  </div>
);

const PipelineGraph = ({ pipelineData, t }) => {
  const {
    nbAnswered,
    nbPositiveAnswered,
    nbSent,
    nbSentWithEmailOpenTracking,
    nbOpened,
    // nbSentWithLinkClickTracking,
    // nbClicked,
    nbInProcess,
    nbHired,
    rateAnswered,
    rateOpened,
    // rateClicked,
    ratePositiveAnswered,
    rateInProcess,
  } = pipelineData || {};

  return (
    <Table.Row className='pipeline-totals'>
      <Table.Cell />
      <Table.Cell>
        <PipelineMetric
          className='sent'
          metricTotal={nbSent}
        />
      </Table.Cell>
      {_.isNumber(nbSentWithEmailOpenTracking) && nbSentWithEmailOpenTracking > 0 && (
        <Table.Cell>
          <PipelineMetric
            className='opened'
            metricTotal={nbOpened}
            metricRate={rateOpened}
          />
        </Table.Cell>
      )}
      {/* {nbSentWithLinkClickTracking && (
        <PipelineMetric
          className='clicked'
          metricTotal={nbClicked}
          metricRate={rateClicked}
        />
      )} */}
      <Table.Cell>
        <PipelineMetric
          className='answered'
          metricTotal={nbAnswered}
          metricRate={rateAnswered}
        />
      </Table.Cell>
      <Table.Cell>
        <PipelineMetric
          className='positive-answered'
          metricTotal={nbPositiveAnswered}
          metricRate={ratePositiveAnswered}
        />
      </Table.Cell>
      <Table.Cell>
        <PipelineMetric
          className='process'
          metricTotal={nbInProcess}
          metricRate={rateInProcess}
        />
      </Table.Cell>
      <Table.Cell>
        <PipelineMetric
          className='hired'
          label={t('analytics.labels.nbHired')}
          metricTotal={nbHired}
        />
      </Table.Cell>
    </Table.Row>
  );
};

const PipelineMetric = ({ metricTotal, metricRate, className, nanValue = 0 }) => {
  const isPercentage = _.isNumber(metricRate);

  const mainMetric = isPercentage ? formatPercentWithSymbol(metricRate, nanValue) : formatNumber(metricTotal, nanValue);

  const secondaryMetric = isPercentage
    ? formatNumber(metricTotal, nanValue)
    : formatPercentWithSymbol(metricRate, nanValue);

  return (
    <div className='pipeline-metric'>
      <div className='pipeline-metric-value'>
        <span className={`pipeline-metric-main ${className || ''}`}>{mainMetric}</span>
        {isPercentage && <span className={`pipeline-metric-secondary ${className || ''}`}>({secondaryMetric})</span>}
      </div>
    </div>
  );
};

export default withTranslation('translations')(PipelineGraph);
