import React from 'react';
import { TFunction } from 'i18next';
import { Dropdown } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { compose } from 'underscore';
import useNavSwitch from './NavSwitch.hook';

import './NavSwitch.css';

interface TFunctionProps {
  t: TFunction;
}
const NavSwitchRevealLine: React.FC<TFunctionProps> = ({ t }) => (
  <span>
    <img
      className='logo'
      src='/images/logos/reveal-logo-color.svg'
      alt='hiresweet logo'
    />
    <span>{t('header.navSwitch.reveal')}</span>
  </span>
);

const NavSwitchRevealSubtext: React.FC<TFunctionProps> = ({ t }) => (
  <span>{t('header.navSwitch.revealSubtext')}</span>
);

const NavSwitchClassicLine: React.FC<TFunctionProps> = ({ t }) => (
  <span>
    <img
      className='logo'
      src='/images/logos/new-logo_without_text_blue.svg'
      alt='hiresweet logo'
    />
    <span>{t('header.navSwitch.classic')}</span>
  </span>
);

const NavSwitchClassicSubtext: React.FC<TFunctionProps> = ({ t }) => (
  <span>{t('header.navSwitch.classicSubtext')}</span>
);

interface NavSwitchTriggerProps extends TFunctionProps {
  applicationMode: string;
}
const NavSwitchTrigger: React.FC<NavSwitchTriggerProps> = ({
  applicationMode,
  t,
}) => {
  if (applicationMode === 'reveal') {
    return <NavSwitchRevealLine t={t} />;
  }
  return <NavSwitchClassicLine t={t} />;
};

interface NavSwitchProps extends TFunctionProps {
  clientId: string;
  history?: any; // from withRouter
}
const NavSwitch: React.FC<NavSwitchProps> = ({ clientId, t, history }) => {
  const {
    canShowSwitch,
    applicationMode,
    disabled,
    updateApplicationMode,
  } = useNavSwitch(clientId);
  if (!canShowSwitch) {
    return null;
  }

  const onClickReveal = () => {
    if (!disabled) {
      updateApplicationMode('reveal');
      history.push(`/client/${clientId}/reveal`);
    }
  };

  const onClickClassic = () => {
    if (!disabled) {
      updateApplicationMode('classic');
      history.push(`/client/${clientId}/dashboard`);
    }
  };

  return (
    <Dropdown
      className='nav-switch'
      icon='angle down'
      floating
      direction='left'
      trigger={<NavSwitchTrigger applicationMode={applicationMode} t={t} />}
      disabled={disabled}
    >
      <Dropdown.Menu>
        <Dropdown.Item onClick={onClickClassic}>
          <div className='maintext'>
            <NavSwitchClassicLine t={t} />
          </div>
          <div className='subtext'>
            <NavSwitchClassicSubtext t={t} />
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={onClickReveal}>
          <div className='maintext'>
            <NavSwitchRevealLine t={t} />
          </div>
          <div className='subtext'>
            <NavSwitchRevealSubtext t={t} />
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default compose(withRouter)(NavSwitch);
