import React, { FC, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import {
  locationsAndRemoteFromRaw,
  locationsAndRemoteToRaw,
} from '@/common/helpers/locationAndRemote';
import { sortLocationOptions } from '@/common/options/locationMarketplaceSort';
import LocationAndRemoteForm from '@/components/LocationAndRemoteForm';
import useClientId from '@/hooks/router/useClientId';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';

import { CustomRange, CustomSelect, SalaryRange } from '../../CustomFormItems';
import { FormOptions, HandleChangeArguments } from '../types';
import { MarketplaceFormValue } from './types';
import SalaryBenchmark from './SalaryBenchmark';
import { getCriteriaFromFormValue } from './helpers';

interface MarketplaceGeneralCriteriaFormProps {
  current: MarketplaceFormValue;
  options: FormOptions;
  handleChange: <T extends keyof MarketplaceFormValue>(
    args: HandleChangeArguments<T>,
  ) => void;
  errorFields: string[];
  modifyingNotification: (label: string) => ReactNode;
}

const MarketplaceGeneralCriteriaForm: FC<
  MarketplaceGeneralCriteriaFormProps
> = ({
  current,
  options,
  handleChange,
  errorFields,
  modifyingNotification,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { permissions } = useClientPermissions(clientId);

  const onLocation = useCallback(
    (value) =>
      handleChange({
        label: 'locationsAndRemote',
        value: locationsAndRemoteToRaw(value),
      }),
    [handleChange],
  );

  const locationsAndRemote = locationsAndRemoteFromRaw(
    current?.locationsAndRemote,
  );

  return (
    <>
      <div className='form-row'>
        <div className='form-box'>
          <div
            className={`form-field-label ${
              _.indexOf(errorFields, 'jobPositions') >= 0 ? ' is-error' : ''
            }`}
          >
            {t('criteria.jobPosition', { count: 0 })}
            {modifyingNotification('jobPositions')}
          </div>
          <div className='form-field-input'>
            <CustomSelect
              label='jobPositions'
              multi={false}
              value={current.jobPosition}
              options={(options || {}).jobPositions}
              onChange={handleChange}
              placeholderText={t('criteria.jobPositionsPlaceholder')}
            />
          </div>
        </div>
      </div>
      <div className='form-row'>
        <div className='form-box'>
          <div
            className={`form-field-label${
              _.indexOf(errorFields, 'locationsAndRemote') >= 0
                ? ' is-error'
                : ''
            }`}
          >
            {t('criteria.location', { count: 0 })}
          </div>
          <div className='form-field-input'>
            <LocationAndRemoteForm
              locationOptions={sortLocationOptions(
                (options || {}).locations || [],
              )}
              value={locationsAndRemote}
              onChange={onLocation}
            />
          </div>
        </div>
      </div>
      <div className='form-row'>
        <div className='form-box'>
          <div
            className={`form-field-label${
              _.indexOf(errorFields, 'experience') >= 0 ? ' is-error' : ''
            }`}
          >
            {t('criteria.experienceYears')}
            {modifyingNotification('experience')}
          </div>
          <div className='form-field-input'>
            <CustomRange
              label='experience'
              min={0}
              max={20}
              step={1}
              formatLabel={(value: number) =>
                value === 20 ? '20+' : `${value}`
              }
              currentValue={current.experienceYears}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      {permissions?.salaryBenchmark && (
        <SalaryBenchmark criteria={getCriteriaFromFormValue(current)} />
      )}
      <div className='form-row'>
        <div className='form-box'>
          <div className='form-field-label'>
            {t('criteria.salary')}
            {modifyingNotification('salary')}
          </div>
          <div className='form-field-input'>
            <SalaryRange
              label='salary'
              currentValue={current.salary}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketplaceGeneralCriteriaForm;
