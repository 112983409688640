import { useMutation } from '@apollo/client';
import { LOCK_CURRENT_SEQUENCE_VARIABLE } from '@/graphql/searchPoolProfiles';
import { ContactFlowSequence } from '@/types/searchPoolProfile';

interface Input {
  searchPoolId: string;
  input: {
    profileId: string;
    dynamicVariable: { id: string; name: string; value: string };
  };
}

interface NewSnippet {
  id: string;
  contactFlow: {
    id: string;
    sequences: ContactFlowSequence[];
  };
}

const useLockCurrentSequenceVariable = () => {
  return useMutation<NewSnippet, Input>(LOCK_CURRENT_SEQUENCE_VARIABLE);
};

export default useLockCurrentSequenceVariable;
