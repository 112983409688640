import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Image, Modal } from 'semantic-ui-react';
import useBrowserType from '@/hooks/common/useBrowserType';
import usePluginStatuses from '@/hooks/common/usePluginStatuses';
import useTimeout from '@/hooks/common/useTimeout';
import useUserFromJWToken from '@/graphql/hooks/users/useUserFromJWToken';
import logAction from '../../../../common/logAction';
import './PluginModal.css';

const PLUGIN_DISMISSED_STORAGE_KEY = 'pluginModalDismissedTimestamp';
const initPluginModalState = () => {
  const maybeTs = window.localStorage.getItem(PLUGIN_DISMISSED_STORAGE_KEY);
  if (maybeTs) {
    return maybeTs < Date.now() - 48 * 3600 * 1000;
  }
  return true;
};
const linkToStore = {
  chrome:
    'https://chrome.google.com/webstore/detail/hiresweet-extension/gncdeciaplnakdjjkcbmlbbbnlpochik',
  chromium:
    'https://chrome.google.com/webstore/detail/hiresweet-extension/gncdeciaplnakdjjkcbmlbbbnlpochik',
  firefox: 'https://addons.mozilla.org/en-US/firefox/addon/hiresweet/',
};
const canInstallExtension = (browserType) => !!linkToStore[browserType];

const browserLogo = {
  chrome: '/images/logos/chrome-logo.svg',
  chromium: '/images/logos/chrome-logo.svg',
  firefox: '/images/logos/firefox-logo.svg',
};

const usePluginModalState = (clientId) => {
  const { data: userData } = useUserFromJWToken();
  const firstLoginReveal = userData?.user?.firstLoginReveal;
  const [open, setOpen] = useState(initPluginModalState);
  const [hasDelayFinished, setHasDelayFinished] = useState(false);

  const endDelay = useCallback(() => {
    setHasDelayFinished(true);
  }, [setHasDelayFinished]);

  useTimeout(endDelay, 2000);
  const onClose = () => {
    setOpen(false);
    window.localStorage.setItem(PLUGIN_DISMISSED_STORAGE_KEY, Date.now());
  };
  const browserType = useBrowserType();
  const { pluginDownloaded } = usePluginStatuses(clientId);

  const showModal =
    hasDelayFinished &&
    open &&
    !pluginDownloaded &&
    !firstLoginReveal &&
    canInstallExtension(browserType);

  return [showModal, onClose];
};

const PluginModal = ({ clientId }) => {
  const { t } = useTranslation();
  const [open, onClose] = usePluginModalState(clientId);
  const browserType = useBrowserType();

  if (!open) {
    return null;
  }

  return (
    <Modal className='plugin-modal' open dimmer={{ id: 'plugin-modal-dimmer' }}>
      <div className='plugin-modal-wrapper'>
        <div className='plugin-modal-header'>
          <Image
            className='plugin-screenshot'
            src='/images/screenshots/pluginView.png'
            alt='plugin screenshot'
          />
          <div className='chrome-logo-wrapper'>
            <img
              className='chrome-logo'
              alt='browser-logo'
              src={browserLogo[browserType]}
            />
          </div>
        </div>
        <div className='content'>
          <div className='close-button'>
            <i className='ri-close-line' role='button' onClick={onClose} />
          </div>
          <h3>{t('reveal.pluginModal.title')}</h3>
          <p className='description'>{t('reveal.pluginModal.text')}</p>
          <Button
            id='plugin-modal-cta'
            primary
            href={linkToStore[browserType]}
            target='_blank'
            rel='noreferrer noopener'
            onClick={logGoToWebstore(browserType)}
            size='small'
          >
            {t('reveal.pluginModal.cta')}
          </Button>
          <Button size='small' onClick={onClose} basic primary>
            {t('reveal.pluginModal.dismiss')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const logGoToWebstore = (browserType) => () => {
  logAction({
    type: 'click-plugin-store-link',
    info: {
      browserType,
      from: 'linkedin-nudge-modal',
    },
  });
};

export default PluginModal;
