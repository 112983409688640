import React from 'react';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';
import { PlaneIcon } from '../../../assets/icons';

import './ProfileJobState.css';

const ProfileJobState = React.memo(({ profile, offerTitle, t }) => {
  if (!profile) {
    return null;
  }
  const {
    // creationTimestamp, // use for 'pending'
    lastStepUpdateTimestamp,
    resumeData,
    sent,
    answerDetected,
  } = profile || {};

  if (!resumeData?.step) {
    return null;
  }

  if (resumeData?.step === 'pending') {
    if (!offerTitle) {
      return null;
    }
    return (
      <div className='profile-job-state'>
        <span className='state-part'>{offerTitle}</span>
      </div>
    );
  }

  let state = resumeData?.step;
  if (resumeData?.step === 'disqualified' && sent) {
    state = 'contactedAndDisqualified';
  }
  if (resumeData?.step === 'disqualified' && answerDetected) {
    state = 'answeredAndDisqualified';
  }

  const statePart = t(`watchCollect.results.profileCard.header.step.${state}`, '');
  const lastUpdateMoment = moment(lastStepUpdateTimestamp, 'x');
  const dateStr = lastUpdateMoment.isValid() ? lastUpdateMoment.format('LL') : '';
  const datePart = dateStr ? `(${dateStr})` : '';

  if (!statePart) {
    return null;
  }

  let icon;
  switch (state) {
    case 'hired': {
      icon = <Icon className='job-state-icon handshake' name='handshake' size='small'/>;
      break;
    }
    case 'in-process': {
      icon = <PlaneIcon className='job-state-icon'/>;
      break;
    }
    case 'answered': {
      icon = <PlaneIcon className='job-state-icon'/>;
      break;
    }
    case 'answeredAndDisqualified': {
      icon = <PlaneIcon className='job-state-icon'/>;
      break;
    }
    case 'contacted': {
      icon = <PlaneIcon className='job-state-icon'/>;
      break;
    }
    case 'contactedAndDisqualified': {
      icon = <PlaneIcon className='job-state-icon'/>;
      break;
    }
    case 'skipped': {
      icon = <Icon className='job-state-icon minus' name='minus circle' size='small'/>;
      break;
    }
    case 'disqualified': {
      icon = <Icon className='job-state-icon minus' name='minus circle' size='small'/>;
      break;
    }
    default: {
      icon = null;
    }
  }

  return (
    <div className='profile-job-state'>
      <div className='icon-container'>{icon}</div>
      <span className='state-part'>{statePart}</span>
      <span className='date-part'>&nbsp;{datePart}</span>
    </div>
  );
});


export default ProfileJobState;
