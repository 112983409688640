import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useHideProfilesFromMissionSearch from '@/graphql/hooks/searchPoolJob/useHideProfilesFromMissionSearch';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import GenericButton from '@/components/Common/GenericButton';

interface HideFromSearchActionProps {
  projectId: string;
  profileId: string;
}

const HideFromSearchAction: FC<HideFromSearchActionProps> = ({
  profileId,
  projectId,
}) => {
  const { t } = useTranslation();
  const { success, error } = useNotificationSystem();
  const [hideFromSearch] = useHideProfilesFromMissionSearch();

  const handleClick = async () => {
    const { data } = await hideFromSearch({
      missionId: projectId,
      profileIds: [profileId],
    });

    const result = data?.searchPool.hideProfilesFromMissionSearch.results[0];

    if (!result) {
      return;
    }

    if (result.success) {
      success(t('reveal.searchView.hide.success'));
    } else {
      error(result.error);
    }
  };

  return (
    <GenericButton
      primacy='secondary'
      onClick={async (e) => {
        e.stopPropagation();
        await handleClick();
      }}
    >
      <i className='ri-thumb-down-line' />
      {t('reveal.searchView.hide.label')}
    </GenericButton>
  );
};

export default HideFromSearchAction;
