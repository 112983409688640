import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LockHistoryContextProvider } from '@/context/LockHistoryContext';
import SequencesList from './SequencesList';
import SequenceView from './SequenceView';
import NewSequencePreview from './NewSequencePreview';
import CreateSequencePage from './CreateSequencePage';
import EditSequencePage from './EditSequencePage';

interface SequencesParams {
  clientId: string;
}

const Sequences: FC = () => {
  const { params, path } = useRouteMatch<SequencesParams>();
  const { clientId } = params;

  return (
    <Switch>
      <Route exact path={`${path}/new-sequence-preview`}>
        <NewSequencePreview clientId={clientId} />
      </Route>
      <Route
        exact
        path={`${path}/new-sequence-create`}
        render={() => (
          <LockHistoryContextProvider>
            <CreateSequencePage clientId={clientId} />
          </LockHistoryContextProvider>
        )}
      />
      <Route
        exact
        path={`${path}/:sequenceId/edit`}
        render={({ match }) => (
          <LockHistoryContextProvider>
            <EditSequencePage
              clientId={clientId}
              sequenceId={match.params.sequenceId}
            />
          </LockHistoryContextProvider>
        )}
      />
      <Route path={`${path}/:sequenceId`} component={SequenceView} />
      <Route exact path={`${path}`}>
        <SequencesList clientId={clientId} />
      </Route>
    </Switch>
  );
};

export default Sequences;
