import { TFunction } from 'i18next';

export const getPercentage = (
  value: number,
  maximum: number,
  decimals = 0,
): number => {
  const divider = 10 ** decimals;
  return Math.round((100 * divider * (value || 0)) / (maximum || 1)) / divider;
};

export const percentageToString = (t: TFunction) => (value: number): string =>
  `${value}${t('reveal.reports.percentageSuffix')}`;
