import React from 'react';
import _ from 'underscore';
import ContactFlowSequenceEditor from './ContactFlowSequenceEditor';

import './ContactFlowEditor.css';

class ContactFlowEditor extends React.Component {
  render() {
    const {
      clientId,
      offerId,
      contactFlow,
      defaultTemplatesOnSeveralOffers,
      onAddSequence,
      onUpdateSequenceTrigger,
      onRemoveSequence,
      onInsertAction,
      onUpdateAction,
      onRemoveAction,
      onRemoveActions,
      updateAfterTemplateCreation,
      onQuitContactFlowEditor,
      t,
    } = this.props;
    const contactFlowSequences = (contactFlow || {}).sequences;
    const placeholderSequencePositions = ['first-contact', 'positive-answer', 'non-positive-answer', 'recontact'];

    const placeholderTitleFromPosition = {
      'first-contact': 'First Contact',
      'positive-answer': 'Positive Answer',
      'non-positive-answer': 'Negative Answer',
      recontact: 'Recontact',
    };

    const sequences = [
      ..._.map(
        placeholderSequencePositions,
        (position) => _.findWhere(contactFlowSequences, { position }) || {
          title: placeholderTitleFromPosition[position] || position,
          position,
          isPlaceholder: true,
        },
      ),
      ..._.filter(
        contactFlowSequences,
        (sequence) => !sequence.position || placeholderSequencePositions.indexOf(sequence.position) < 0,
      ),
    ];

    const sequence = sequences[0]; // TODO: multiple sequences
    const nonEmptySequences = _.filter(sequences, (sequenceOrPlaceholder) => {
      return ((sequenceOrPlaceholder || {}).actions || []).length > 0;
    });
    const lastNonEmptySequence = nonEmptySequences[nonEmptySequences.length - 1];
    return (
      <div className='contact-flow-editor'>
        <ContactFlowSequenceEditor
          clientId={clientId}
          t={t}
          sequence={sequence}
          lastNonEmptySequence={lastNonEmptySequence}
          onAddSequence={onAddSequence}
          onUpdateSequenceTrigger={onUpdateSequenceTrigger}
          onRemoveSequence={onRemoveSequence}
          onInsertAction={onInsertAction}
          onUpdateAction={onUpdateAction}
          onRemoveAction={onRemoveAction}
          onRemoveActions={onRemoveActions}
          offerId={offerId}
          defaultTemplatesOnSeveralOffers={defaultTemplatesOnSeveralOffers}
          updateAfterTemplateCreation={updateAfterTemplateCreation}
          onQuitContactFlowEditor={onQuitContactFlowEditor}
        />
      </div>
    );
  }
}

export default ContactFlowEditor;
