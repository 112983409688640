import React from 'react';
import _ from 'underscore';

import { Category } from '@/types/statistics/category';
import {
  CountsByArchivedState,
  CountsByPipelineState,
} from '@/types/statistics/pipeline';
import { Segment, segments } from '../helpers/segments';
import { SegmentListeners } from '../types';
import GenericFunnelRow from '../../GenericFunnelRow';

import styles from './CategoryFunnelStatistics.module.less';

interface CategoryFunnelStatisticsProps {
  data: CountsByPipelineState & CountsByArchivedState;
  category?: Category;
  conversionRates: Partial<Record<Segment, string>>;
  clickListeners?: SegmentListeners;
}

const CategoryFunnelStatistics: React.FC<CategoryFunnelStatisticsProps> = ({
  data,
  category,
  conversionRates,
  clickListeners,
}) => {
  const segmentDescriptions = _.map(segments, (key) => ({
    key,
    count: data[key],
    conversionRate: conversionRates?.[key],
    clickListener: clickListeners?.[key],
    classNames: { global: styles[key], count: styles.count },
  }));

  return <GenericFunnelRow row={category} segments={segmentDescriptions} />;
};

export default CategoryFunnelStatistics;
