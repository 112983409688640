/* globals gapi */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-throw-literal */
import React from 'react';
import _, { compose } from 'underscore';
import { withApollo } from '@apollo/client/react/hoc';
import withActionLogger from '../withActionLogger';
import detectBrowserAndOS from '../../common/detectBrowserAndOS';

import withEmailContextConsumer from './withEmailContextConsumer';
import {
  lockProfileMutation,
  sentProfileEmailMutation,
  backendSendMutation,
  sendOnBehalfOfMutation,
  unlockProfileMutation,
  updateCacheAfterSendEmail,
} from './withSendEmailMutations';
import {
  sentryCaptureException,
  textToId,
  getRandomString,
} from '../../common';
import getTrackingImage from '../../common/getTrackingImage';
import replaceLinksForTracking, {
  replaceCalendlyLinksForTracking,
} from '../../common/replaceLinksForTracking';

const TRACKING_DOMAIN = 'https://skj.shortylink.site';

function withSendEmail(WrappedComponent) {
  return class withSendEmailWrapper extends React.Component {
    handleSendEmail = ({
      subject,
      body: originalBody,
      signature,
      dest,
      bccAddresses = [],
      ccAddresses = [],
      defaultThreadId,
      profileId,
      jobOfferId,
      sequenceId,
      actionId,
      assignedSender,
      offerAssignedSender,
      client: hiresweetClient,
    }) => {
      let body = originalBody;
      const trackingMessageId = getRandomString(18);
      const trackOnEmailOpen =
        hiresweetClient?.settings?.clickAndOpenTracking?.trackOnEmailOpen;
      const trackOnLinkClick =
        hiresweetClient?.settings?.clickAndOpenTracking?.trackOnLinkClick;
      const trackCalendly = !!hiresweetClient?.calendlyIntegration?.active;
      const trackingDomain = TRACKING_DOMAIN; // todo : get from graphql
      let emailContainsTrackedCalendlyLinks = false;
      if (trackCalendly) {
        emailContainsTrackedCalendlyLinks =
          (body || '').indexOf('https://calendly.com/') !== -1;
        ({ body } = replaceCalendlyLinksForTracking(body, { profileId }));
      }

      if (trackOnLinkClick) {
        // replace all the links
        ({ body } = replaceLinksForTracking(body, {
          profileId,
          trackingDomain,
          messageId: trackingMessageId,
        }));
      }
      // add signature
      body = `<div>${body}</div><div>${signature}</div>`;

      if (trackOnEmailOpen) {
        // add pixel
        body += getTrackingImage(profileId, trackingMessageId, trackingDomain);
      }

      // wrap it up
      const fullBody = `<div>${body}</div>`;

      const { user, client, onLogAction, emailApi } = this.props;
      let emailForSend;
      // autopilot
      if (assignedSender && !defaultThreadId) {
        emailForSend = assignedSender.email;
        // sobo
      } else if (offerAssignedSender) {
        emailForSend = offerAssignedSender?.senderAddress;
      } else {
        emailForSend = emailApi.currentAddress;
      }
      return (
        Promise.resolve()
          // Lock the profile server side -> Already locked
          .then(() => {
            return client.mutate({
              mutation: lockProfileMutation,
              variables: {
                profileId,
                email: emailForSend,
              },
            });
          })

          // .then(() => Promise.reject('email not send... :('))
          // Send the email client side
          .then(async () => {
            // await new Promise((resolve) => {
            //   setTimeout(() => {
            //     resolve()
            //   }, 5000);
            // })

            // Autoshoot send
            if (assignedSender && !defaultThreadId) {
              const rawResult = await client.mutate({
                mutation: backendSendMutation,
                variables: {
                  profileId,
                  subject,
                  body: fullBody,
                },
              });

              if (!((rawResult || {}).data || {}).backendSendEmail) {
                console.error('error in backend send');
                throw Error('invalid gmailResult');
              }
              const emailResult = rawResult.data.backendSendEmail;

              const type = emailResult.type || 'gmail';

              if (type === 'gmail') {
                const gmailResult = emailResult;
                return {
                  senderAddress: assignedSender.email,
                  gmailResult: {
                    ...gmailResult,
                    result: gmailResult,
                  },
                  threadData: {
                    body: fullBody,
                    from: assignedSender.email,
                    to: dest,
                    subject,
                    ...((gmailResult || {}).threadId && {
                      threadId: gmailResult.threadId,
                    }),
                  },
                };
              } // type === 'outlook'
              return {
                senderAddress: assignedSender.email,
                threadData: {
                  body: fullBody,
                  from: assignedSender.email,
                  to: dest,
                  subject,
                  ...((emailResult || {}).threadId && {
                    threadId: emailResult.threadId,
                  }),
                  type,
                },
              };
            }

            // SOBO send
            if (offerAssignedSender) {
              const { id, senderAddress, alias } = offerAssignedSender;
              if (!senderAddress || !id) {
                throw new Error('Invalid offerAssignedSender');
              }

              const rawResult = await client.mutate({
                mutation: sendOnBehalfOfMutation,
                variables: {
                  profileId,
                  subject,
                  body: fullBody,
                  senderId: id,
                  bcc: bccAddresses,
                  cc: ccAddresses,
                },
              });

              const emailResult = rawResult?.data?.sendEmailOnBehalfOf;
              if (!emailResult) {
                console.error('Error in SOBO send');
                throw new Error('Error in SOBO send');
              }

              const type = emailResult.type || 'gmail';
              let sentResult = {
                senderAddress,
                threadData: {
                  body: fullBody,
                  from: senderAddress,
                  to: dest,
                  bcc: bccAddresses,
                  cc: ccAddresses,
                  subject,
                  ...((emailResult || {}).threadId && {
                    threadId: emailResult.threadId,
                  }),
                  alias, // sender alias
                  type,
                },
                sender: offerAssignedSender,
              };

              if (type === 'gmail') {
                const gmailResult = { ...emailResult, result: emailResult };
                sentResult = { ...sentResult, gmailResult };
              }

              return sentResult;
            }

            // Regular send
            return emailApi.onTriggerSendMail({
              subject,
              body: fullBody,
              dest,
              bccAddresses,
              ccAddresses,
              alias: user ? user.alias : undefined,
              defaultThreadId,
            });
          })
          // .then(() => Promise.reject('email not send... :('))
          // Update the profile server side
          .then((sentResult) => {
            const { error } = (sentResult || {}).gmailResult || {};
            if (error || (sentResult || {}).success === false) {
              // log the send error with all the data available
              let errorMessage;
              if (((sentResult || {}).gmailResult || {}).error) {
                errorMessage = `${sentResult.gmailResult.error.code} ${sentResult.gmailResult.error.message}`;
              } else {
                errorMessage = (sentResult.error || {}).message;
              }
              try {
                onLogAction({
                  type: 'sendError-client',
                  profileId,
                  info: {
                    userSetup: detectBrowserAndOS(),
                    dest,
                    defaultThreadId,
                    jobOfferId,
                    error: errorMessage,
                    sentResult,
                    recruiterEmail: user?.email,
                    currentAdress: emailApi.currentAddress, // TODO: typo, legacy ?
                    currentAddress: emailApi.currentAddress,
                    senderAddress: emailForSend,
                    googleToken: gapi
                      ? _.omit(
                          gapi.auth2
                            .getAuthInstance()
                            .currentUser.get()
                            .getAuthResponse(),
                          'access_token',
                          'id_token',
                        )
                      : null, //eslint-disable-line
                  },
                });
              } catch (e) {
                console.error(e); // nothing more to do when the logger does not work
              }
              return client
                .mutate({
                  mutation: unlockProfileMutation,
                  variables: {
                    profileId,
                    error: errorMessage,
                  },
                })
                .then(() => sentResult);
            }
            if (sentResult.threadData) {
              if (emailContainsTrackedCalendlyLinks) {
                onLogAction({
                  type: 'calendly-send-link',
                  profileId,
                });
              }
              const sender = formatSenderInput({ sender: sentResult?.sender });
              return client
                .mutate({
                  mutation: sentProfileEmailMutation,
                  variables: {
                    profileId,
                    signature,
                    alias: user ? user.alias : undefined,
                    trackingMessageId,
                    trackOnEmailOpen,
                    trackOnLinkClick,
                    sequenceId,
                    actionId,
                    ...sentResult.threadData,
                    ...(sender && { sender }),
                  },
                })
                .then(() => sentResult);
            }
            console.error('ERROR (unhandled)');
            console.error('RES: ', sentResult);
            return {};
          })
          // Finally, return the gmail result to be able to handle errors coming from it.
          .then((sentResult) => {
            const { error } = (sentResult || {}).gmailResult || {};
            if (!_.isEmpty(error) || (sentResult || {}).success === false) {
              if (
                (((sentResult || {}).gmailResult || {}).error || {}).code ===
                  404 ||
                (((sentResult || {}).gmailResult || {}).error || {}).code ===
                  400
              ) {
                try {
                  // remove the previous sendErrors
                  onLogAction({
                    type: 'remove-sendError',
                    profileId,
                  });
                } catch (e) {
                  sentryCaptureException({
                    error: e,
                    tags: { feature: 'mail' },
                  });
                }
                throw Error('400_404_gmail_error');
              } else {
                const errorMessage =
                  sentResult?.error?.message ||
                  sentResult?.gmailResult?.error?.message ||
                  'send_error';
                throw Error(errorMessage);
              }
            }
            return sentResult;
          })
          // Catch the error and unlock the profile if no email was send
          .catch(async (e) => {
            sentryCaptureException({ error: e, tags: { feature: 'mail' } });
            await client.mutate({
              mutation: unlockProfileMutation,
              variables: {
                profileId,
                error: e.message,
              },
            });
            emailApi.onSendError({ profileId, offerId: jobOfferId });
            throw e;
          })
      );
    };

    handleRefreshProfile = ({ profileId, stepId }) => {
      const { client } = this.props;
      return Promise.resolve()
        .then(() => {
          return client.query({
            query: updateCacheAfterSendEmail,
            variables: {
              profileId,
              step: stepId,
            },
            fetchPolicy: 'network-only',
          });
        })
        .then(() => {
          return 'ok';
        })
        .catch(async (e) => {
          console.error(e);
        });
    };

    render() {
      return (
        <WrappedComponent
          sendEmail={this.handleSendEmail}
          refreshProfile={this.handleRefreshProfile}
          {...this.props}
        />
      );
    }
  };
}

const formatSenderInput = ({ sender }) => {
  if (_.isEmpty(sender)) {
    return null;
  }
  const senderKeys = [
    'id',
    'clientId',
    'alias',
    'firstname',
    'lastname',
    'signature',
    'senderAddress',
  ];
  const senderInput = _.pick(sender, ...senderKeys);

  // convert resolved mailAccount back to DB format
  const { address, type } = sender?.mailAccount || {};
  if (address && type) {
    senderInput.mailAccount = { idToken: textToId(address), type };
  }
  return senderInput;
};

export default compose(
  withActionLogger,
  withApollo,
  withEmailContextConsumer,
  withSendEmail,
);
