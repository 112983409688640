import * as Sentry from '@sentry/browser';
import { openPopup, sleep } from '../helpers';

const LEVER_CLIENT_ID = process.env.REACT_APP_LEVER_CLIENT_ID;
const REDIRECT_URI = `${window.location.origin}/lever-callback.html`;
const LEVER_POPUP = { width: 440, height: 440 };

const BASE_URL = 'https://auth.lever.co/authorize?';
const scopes = [
  'users:read:admin',
  'stages:read:admin',
  'postings:read:admin',
  'opportunities:write:admin',
  'candidates:write:admin',
  'notes:read:admin',
  'notes:write:admin',
  'offline_access',
];
const params = [
  `client_id=${LEVER_CLIENT_ID}`,
  `redirect_uri=${REDIRECT_URI}`,
  'response_type=code',
  'audience=https://api.lever.co/v1/',
  `scope=${scopes.join('%20')}`,
  'prompt=consent',
  'state=',
];

const AUTHORIZATION_URL = BASE_URL + params.join('&');

let detectedAuthorizationCode = '';
let state = '';

const receiveAuthorizationCode = (event) => {
  const originCheck = /localhost:8070$|\.hiresweet\.com$/g;
  if (
    typeof event.data === 'string' &&
    event.data.startsWith('ats-authorization-code-') &&
    originCheck.test(event.origin)
  ) {
    const popupUrl = new URL(event.data.replace('ats-authorization-code-', ''));
    // console.log('detectedState=', popupUrl.searchParams.get('state'));
    // console.log('EqualityCheck=', popupUrl.searchParams.get('state') === state);
    detectedAuthorizationCode = popupUrl.searchParams.get('code');
  }
};

window.addEventListener('message', receiveAuthorizationCode);

const pollDetectAuthorizationCode = async (nbTrials, popup) => {
  if (nbTrials > 0) {
    if (detectedAuthorizationCode) {
      const result = { token: detectedAuthorizationCode };
      // reset code
      detectedAuthorizationCode = '';
      return result;
    }
    if (popup && popup.closed) {
      return undefined;
    }
    await sleep(250);
    return pollDetectAuthorizationCode(nbTrials - 1, popup);
  }
  return undefined;
};

/**
 * Constructor which returns a function that will execute an OAuth flow and run onAuthorizedCallback
 * when the authorization token is successfully retrieved.
 */
const authorizeWithOAuth = ({ onAuthorizedCallback }) => {
  /**
   * opens a popup to ask the user to allow an OAuth2 integration
   * @returns Promise <Object: {success: boolean, error: object}>
   */
  return async () => {
    try {
      // reset code
      detectedAuthorizationCode = '';
      state = '';
      const randomValues = new Uint32Array(1);
      window.crypto.getRandomValues(randomValues);
      // open child window that will use window.opener.postMessage
      // to send event back to current 'opener' window
      [state] = randomValues;
      const authUrl = AUTHORIZATION_URL + state;
      const offlineGrantPopup = openPopup(
        authUrl,
        '',
        LEVER_POPUP.height,
        LEVER_POPUP.width,
      );
      // start polling for validation (during 5 minutes max.)
      const pollResult = await pollDetectAuthorizationCode(
        (5 * 60 * 1000) / 250,
        offlineGrantPopup,
      );
      // close pop-up
      offlineGrantPopup.close();

      const { token } = pollResult || {};
      if (!token) {
        return {
          success: false,
          error: Error('user closed popup manually or popup timed out'),
        };
      }

      await onAuthorizedCallback({ token });

      return {
        success: true,
      };
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setTags({ feature: 'lever-integration' });
        Sentry.captureException(e);
      });
      return {
        success: false,
        error: e,
      };
    }
  };
};

const lever = {
  authorizeWithOAuth,
};

export default lever;
