import { QueryResult, useLazyQuery, QueryHookOptions } from '@apollo/client';
import gql from 'graphql-tag';
import useClientId from '@/hooks/router/useClientId';
import {
  GeneratedSequence,
  SequenceGenerationInput,
} from '@/types/generatedSequence';

const GENERATE_SEQUENCE = gql`
  query generateSequence($clientId: ID!, $input: SequenceGenerationInput) {
    client(id: $clientId) {
      id
      generateSequence(input: $input) {
        streamId
        streamEnded
        sequence {
          actions {
            body
            subject
          }
        }
      }
    }
  }
`;

export interface GenerateSequenceVariables {
  clientId: string;
  input: SequenceGenerationInput;
}

export interface GenerateSequenceData {
  client: {
    id: string;
    generateSequence: {
      sequence: GeneratedSequence;
    };
  };
}

interface UseGenerateSequenceInput {
  queryOptions?: QueryHookOptions<
    GenerateSequenceData,
    GenerateSequenceVariables
  >;
}

type UseGenerateSequenceResult = [
  (input: SequenceGenerationInput) => void,
  QueryResult<GenerateSequenceData, GenerateSequenceVariables> & {
    generatedSequence?: GeneratedSequence;
  },
];

const useGenerateSequence = ({
  queryOptions,
}: UseGenerateSequenceInput): UseGenerateSequenceResult => {
  const clientId = useClientId();
  const [fetch, result] = useLazyQuery<
    GenerateSequenceData,
    GenerateSequenceVariables
  >(GENERATE_SEQUENCE, {
    // use network-only as a default, if
    // defined in options it will be overridden
    fetchPolicy: 'network-only',
    ...queryOptions,
  });

  const generatedSequence = result?.data?.client.generateSequence.sequence;

  return [
    (input) => fetch({ variables: { clientId, input } }),
    {
      ...result,
      generatedSequence,
    },
  ];
};

export default useGenerateSequence;
