import React from 'react';
import { getSortedSkillsWishes } from '../../../../../components/Profile/SkillsLabels';
import Background from '../../../../../containers/Profile/Resume/Background';
import SmartSummary from '../../../../../containers/Profile/Resume/Card/SmartSummary';

const ProfileTabContent = ({
  attachments,
  profileId,
  resumeData,
  clientId,
  toggleEditMode,
  creationTimestamp,
}) => {
  return (
    <>
      <SmartSummary
        isWatchProfile
        onLogAction={() => {}}
        profileId={profileId}
        argumentation={resumeData?.smartSummary?.arguments}
        // TODO: greenhouse skills instead ?
        skills={getSortedSkillsWishes({
          hiresweetData: resumeData?.sourceData?.hiresweet,
        })}
        neutralMode
      />
      <Background
        resumeData={resumeData}
        attachments={attachments}
        profileId={profileId}
        clientId={clientId}
        toggleEditMode={toggleEditMode}
        isRevealProfile // TODO: make conditional
        creationTimestamp={creationTimestamp}
      />
    </>
  );
};

export default ProfileTabContent;
