import jwtDecode from 'jwt-decode';
import _ from 'underscore';
import * as Sentry from '@sentry/browser';
import i18n from 'i18next';
import { TranslatableText } from '@/types/text';

export const decodeToken = (token: string) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken;
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
};

export const disqualifyReasons: Array<{ name: string }> = [
  { name: 'technical-skills' },
  { name: 'salary' },
  { name: 'fit' },
  { name: 'dispo' },
];

export const skipReasons: Array<{ name: string }> = [
  { name: 'already-contacted' },
  { name: 'friend-company' },
  { name: 'xp' },
  { name: 'formation' },
  { name: 'bad-skills' },
];

export const newDisqualifyReasons: Array<{ name: string }> = [
  { name: 'technical-level' },
  { name: 'human-fit' },
  { name: 'remuneration-expectations' },
  { name: 'availability' },
  { name: 'currently-not-looking' },
  { name: 'not-interested' },
  { name: 'inappropriate-technical-stack' },
  { name: 'remuneration-too-low' },
  { name: 'responsibilities-too-low' },
  { name: 'responsibilities-too-high' },
  { name: 'location-mismatch' },
  { name: 'contract' },
];

export const newSkipReasons: Array<{ name: string }> = [
  { name: 'not-enough-xp' },
  { name: 'too-much-xp' },
  { name: 'unsuitable-skills' },
  { name: 'insufficient-targeted-skills' },
  { name: 'unsuitable-current-position' },
  { name: 'insufficient-education' },
  { name: 'too-prestigious-education' },
  { name: 'already-contacted' },
  { name: 'already-interviewed' },
  { name: 'friend-company' },
  { name: 'location-mismatch' },
];

export const softSkills = [
  {
    id: 'product-design',
    name: 'Product Design',
    initials: 'PD',
    color: '#87d500',
  },
  { id: 'user-experience', name: 'UX', initials: 'UX', color: '#87d500' },
  { id: 'user-interface', name: 'UI', initials: 'UI', color: '#87d500' },
  {
    id: 'product-management	',
    name: 'Product Management',
    initials: 'PM',
    color: '#87d500',
  },

  {
    id: 'agile-methodology',
    name: 'Agile Methodology',
    initials: 'AM',
    color: '#d50087',
  },
  { id: 'scrum', name: 'Scrum', initials: 'SM', color: '#d50087' },

  {
    id: 'business-development',
    name: 'Business Development',
    initials: 'BD',
    color: '#9fdd32',
  },
  {
    id: 'growth-hacking',
    name: 'Growth Hacking',
    initials: 'GH',
    color: '#9fdd32',
  },
  {
    id: 'customer-success',
    name: 'Customer Success',
    initials: 'CS',
    color: '#9fdd32',
  },
  {
    id: 'search-engine-optimization',
    name: 'SEO',
    initials: 'SE',
    color: '#9fdd32',
  },
  {
    id: 'search-engine-marketing',
    name: 'SEM',
    initials: 'SE',
    color: '#9fdd32',
  },

  {
    id: 'data-science',
    name: 'Data Science',
    initials: 'DS',
    color: '#0087d5',
  },
  {
    id: 'machine-learning',
    name: 'Machine Learning',
    initials: 'ML',
    color: '#0087d5',
  },
  {
    id: 'deep-learning',
    name: 'Deep Learning',
    initials: 'DL',
    color: '#0087d5',
  },
  {
    id: 'data-engineering',
    name: 'Data Engineering',
    initials: 'DE',
    color: '#0087d5',
  },
  {
    id: 'business-intelligence',
    name: 'Business Intelligence',
    initials: 'BI',
    color: '#0087d5',
  },
  {
    id: 'data-analysis',
    name: 'Data Analysis',
    initials: 'DA',
    color: '#0087d5',
  },

  {
    id: 'site-reliability-engineering',
    name: 'Site Reliability Engineering',
    initials: 'SR',
    color: '#1993d9',
  },
  { id: 'devops', name: 'DevOps', initials: 'DO', color: '#1993d9' },
  {
    id: 'system-administration',
    name: 'System Administration',
    initials: 'SA',
    color: '#1993d9',
  },

  { id: '3d', name: '3D', initials: '3D', color: '#4cabe1' },
  { id: 'game-design', name: 'Game Design', initials: 'GD', color: '#4cabe1' },
  {
    id: 'game-programming',
    name: 'Game Programming',
    initials: 'GP',
    color: '#4cabe1',
  },
  { id: 'video-games', name: 'Video Games', initials: 'VG', color: '#4cabe1' },

  { id: 'ecommerce', name: 'Ecommerce', initials: 'EC', color: '#93d919' },
  {
    id: 'project-management',
    name: 'Project Management',
    initials: 'PM',
    color: '#93d919',
  },
  { id: 'operations', name: 'Operations', initials: 'OP', color: '#93d919' },

  { id: 'quality-assurance', name: 'QA', initials: 'QA', color: '#329fdd' },
  {
    id: 'test-driven-development',
    name: 'TDD',
    initials: 'TD',
    color: '#329fdd',
  },
  {
    id: 'hardware-architecture',
    name: 'Hardware Architecture',
    initials: 'HA',
    color: '#329fdd',
  },
  {
    id: 'solutions-engineer',
    name: 'Solutions Engineer',
    initials: 'SE',
    color: '#329fdd',
  },
];

export const getSecureLink = (url: string) =>
  !url || !_.isString(url) ? undefined : url.replace('http://', 'https://');

const getOppositeLetterInAlphabet = (letter: string) => {
  if (!letter || !_.isString(letter)) return letter;
  const zMinAsciiNumber = 'z'.charCodeAt(0);
  const zMajAsciiNumber = 'Z'.charCodeAt(0);
  const aMinAsciiNumber = 'a'.charCodeAt(0);
  const aMajAsciiNumber = 'A'.charCodeAt(0);
  const letterAsciiMunber = letter.charCodeAt(0);
  const oppositeLetterAsciiNumber =
    letterAsciiMunber >= aMinAsciiNumber && letterAsciiMunber <= zMinAsciiNumber
      ? zMinAsciiNumber - (letterAsciiMunber - aMinAsciiNumber)
      : letterAsciiMunber >= aMajAsciiNumber &&
        letterAsciiMunber <= zMajAsciiNumber
      ? zMajAsciiNumber - (letterAsciiMunber - aMajAsciiNumber)
      : letterAsciiMunber;
  return String.fromCharCode(oppositeLetterAsciiNumber);
};

export const encodeProfileId = (profileId: string) =>
  _.reduce(
    profileId,
    (memo, letter) => memo + getOppositeLetterInAlphabet(letter),
    '',
  );

export const getTranslatedText = (
  translatableText?: TranslatableText,
): string => {
  if (!translatableText) {
    return '';
  }
  const { resolvedLanguage } = i18n;
  const lang = resolvedLanguage ? resolvedLanguage.split('-')[0] : null;
  if (lang !== 'fr' && lang !== 'en') {
    return translatableText.default || '';
  }
  return (lang && translatableText[lang]) || translatableText.default || '';
};

export const getTranslatedTextWithEnrichments = (translatable: any) => {
  if (!_.isObject(translatable)) {
    return '';
  }
  const { resolvedLanguage } = i18n;
  const lang = resolvedLanguage ? resolvedLanguage.split('-')[0] : null;
  const text = lang && (translatable[lang] || {}).text;
  return text || (translatable.default || {}).text || '';
};

export const passwordIsLongEnough = (password: string): boolean =>
  password.length >= 8;

export const passwordHasBothUpperAndLowerCaseCharacters = (
  password: string,
): boolean =>
  password.toUpperCase() !== password && password.toLowerCase() !== password;

export const passwordHasDigits = (password: string): boolean =>
  /\d/.test(password);

export const passwordIsStrong = (password: string) => {
  if (
    passwordIsLongEnough(password) &&
    passwordHasBothUpperAndLowerCaseCharacters(password) &&
    passwordHasDigits(password)
  ) {
    return true;
  }
  return false;
};

export const textEllipsis = (text: string, nb: number) => {
  if (text && text.length > nb - 3) {
    return `${text.slice(0, nb - 3)}...`;
  }
  return text;
};

const getTextWithoutHTMLEntities = (text: string) => {
  const step1 = text.replace(/&#(\d+);/g, (match, dec) => {
    return String.fromCharCode(dec);
  });
  const step2 = step1.replace(/&quot;/g, '"');
  const step3 = step2.replace(/&amp;/g, '&');
  const step4 = step3.replace(/&nbsp;/g, ' ');
  return step4;
};

export const getEnrichedText = (string: any) => {
  // FIXME any
  if (string && string.type && _.contains(['raw', 'paragraph'], string.type)) {
    return string;
  }
  if (!string || !_.isString(string) || String(string).trim() === '') {
    return { type: null, text: null, children: null };
  }
  if (_.indexOf(string, '\n') > -1) {
    const paragraphs = getTextWithoutHTMLEntities(string).split('\n');
    return {
      type: 'paragraph',
      text: null,
      children: _.map(paragraphs, (p) => ({
        type: 'raw',
        text: p,
        children: null,
      })),
    };
  }
  // HACK cut if string too long ?
  return {
    type: 'raw',
    text: getTextWithoutHTMLEntities(string),
    children: null,
  };
};

// must sync with the common in the backend or might break threads
export const lowerCaseAndUnaccent = (string: string) => {
  if (!_.isString(string)) {
    return '';
  }
  const lowerCaseString = string.toLowerCase();
  const unaccentString =
    lowerCaseString &&
    lowerCaseString.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  return unaccentString;
};

export const textToId = (baseText: string) => {
  const text = lowerCaseAndUnaccent(baseText);
  let result = '';
  _.each(text.split(''), (c) => {
    result +=
      c === ' '
        ? '-'
        : (c >= 'a' && c <= 'z') || (c >= '0' && c <= '9')
        ? c
        : '_';
  });
  return result;
};

export const getRandomDefaultAvatarLink = (profileId: string) => {
  const randomFromString = (str: string, nbValues: number) =>
    _.reduce(
      str,
      (memo, y) => (977 * y.charCodeAt(0) + 641 * memo) % nbValues,
      0,
    ) / nbValues;
  let randomNumber;
  if (!profileId) {
    randomNumber = Math.random();
  } else {
    randomNumber = randomFromString(profileId, 4);
  }

  if (randomNumber < 0.25) {
    return '/images/defaultAvatars/default-avatar-1.svg';
  }
  if (randomNumber < 0.5) {
    return '/images/defaultAvatars/default-avatar-2.svg';
  }
  if (randomNumber < 0.75) {
    return '/images/defaultAvatars/default-avatar-3.svg';
  }
  return '/images/defaultAvatars/default-avatar-4.svg';
};

export const getRandomString = (size: number): string => {
  const possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let text = '';
  for (let i = 0; i < size; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const sentryCaptureException = ({
  error,
  tags,
  extra,
}: {
  error: any;
  tags?: { [key: string]: string };
  extra?: { [key: string]: any };
}) => {
  Sentry.withScope((scope) => {
    if (tags) {
      scope.setTags(tags);
    }
    if (extra) {
      scope.setExtras(extra);
    }
    Sentry.captureException(error);
  });
};

export const sentryCaptureMessage = ({ message }: { message: string }) => {
  Sentry.captureMessage(message);
};

export const instantiateText = ({
  context,
  text,
}: {
  context: any;
  text: string;
}) =>
  text.replace(/\{{([^{}]*)}}/g, (match, key) =>
    context[key] ? context[key] : '',
  );

export const areSameDate = (d1: Date, d2: Date): boolean =>
  d1.getFullYear() === d2.getFullYear() &&
  d1.getMonth() === d2.getMonth() &&
  d1.getDate() === d2.getDate();

export const isEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/; // TODO Improve regex
  return re.test(email);
};

interface Author {
  email: string;
  firstname: string;
  lastname: string;
  photoLink: string;
}

export const getDisplayableName = ({ author }: { author: Author }) => {
  if (!author) {
    return 'Someone';
  }
  if (author.firstname && author.lastname) {
    return `${author.firstname} ${author.lastname}`;
  }
  if (author.firstname) {
    return author.firstname;
  }
  if (author.lastname) {
    return author.lastname;
  }
  if (author.email) {
    return author.email.split('@')[0];
  }
  return '';
};

export const recursivelyRemoveEmptyKeyValues = (arg: any): any => {
  if (_.isArray(arg)) {
    return _.map(arg, recursivelyRemoveEmptyKeyValues);
  }
  if (_.isObject(arg)) {
    return _.mapObject(
      removeEmptyKeyValues(arg),
      recursivelyRemoveEmptyKeyValues,
    );
  }
  return arg;
};

export const sanitizeURLInput = (url: string): string => {
  if (url.indexOf('https://') === 0) {
    return url;
  }
  if (url.indexOf('http://') === 0) {
    return url.replace('http://', 'https://');
  }
  return `https://${url}`;
};

export const defaultBoolean = (value: any, defaultValue: boolean): boolean => {
  return _.isBoolean(value) ? value : defaultValue;
};

export const removeEmptyKeyValues = (obj: Record<string, any>) => {
  const res: any = {};
  _.each(obj || {}, (value, key) => {
    if (
      typeof value !== 'undefined' &&
      !_.isNull(value) &&
      !(_.isObject(value) && _.isEmpty(value))
    ) {
      res[key] = value;
    }
  });
  return res;
};

export const capitalizeText = (str: string | undefined): string | undefined => {
  if (!_.isString(str) || str.length <= 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.substr(1);
};

export const setsAreEqual = (setA: Set<any>, setB: Set<any>) => {
  if (setA.size !== setB.size) {
    return false;
  }
  return _.every(Array.from(setA.values()), (item) => setB.has(item));
};

export const ARCHIVED_ENGAGEMENT = [
  'not-contacted',
  'no-answer',
  'profile-not-interested',
  'profile-interested',
];
export const ARCHIVED_REASONS = [
  'they-rejected-us',
  'we-rejected-them',
  'other-archive-reason',
];
export const REPLIED_ANSWERS = ['negative', 'neutral', 'positive'];

export const REASONS_REMOVE_FROM_MISSION_OR_SEQUENCE = [
  'user-interruption__positive-answer',
  'user-interruption__medium-answer',
  'user-interruption__negative-answer',
  'user-interruption__no-answer',
  'user-interruption__other',
] as const;

export type InterruptionReason = typeof REASONS_REMOVE_FROM_MISSION_OR_SEQUENCE[number];
