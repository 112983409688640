import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const query = gql`
  query hasEnabledSharedDiscussion($token: String!, $type: String!) {
    hasEnabledSharedDiscussion(token: $token, type: $type)
  }
`;

export default graphql(query, {
  options: ({ token, type }) => ({
    variables: {
      token,
      type,
    },
    fetchPolicy: 'no-cache',
  }),
  name: 'hasEnabledSharedDiscussion',
  skip: ({ token, type }) => !token || !type,
});
