import React from 'react';
import { getDefaultKeyBinding, KeyBindingUtil } from 'draft-js';

export const blockToHTML = (block) => {
  if (block.type !== 'unstyled') {
    return null;
  }
  if (block.text === ' ' || block.text === '') {
    return <br/>;
  }
  return <div/>;
};

export const entityToHTML = (entity, originalText) => {
  if (entity.type === 'LINK') {
    return `<a href="${entity.data.url}">${originalText}</a>`;
  }
  return originalText;
};

export const htmlToEntity = (nodeName, node, createEntity) => {
  if (nodeName === 'a') {
    return createEntity('LINK', 'MUTABLE', { url: node.href });
  }
  return null;
};

export const toHTMLOptions = {
  blockToHTML,
  entityToHTML,
};

export const fromHTMLOptions = {
  htmlToEntity,
};

export const mapKeyToEditorCommand = (e) => {
  if (e.keyCode === 75 && KeyBindingUtil.hasCommandModifier(e)) {
    // cmd + K on mac or ctrl + K on win / linux
    return 'hyperlink';
  }
  return getDefaultKeyBinding(e);
};
