import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';

import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useCreateClientIntegration from '@/graphql/hooks/clients/integrations/useCreateClientIntegration';
import useCreateClientConnector from '@/graphql/hooks/clients/integrations/useCreateClientConnector';
import GenericButton from '@/components/Common/GenericButton';
import OAuth2Provider from '../../../../context/oauth2';

interface BasicCredentialsFormProps {
  mode: 'oauth' | 'token';
  type: string;
  reveal?: boolean;
}

const BasicCredentialsForm: React.FC<BasicCredentialsFormProps> = ({
  mode,
  type,
  reveal,
}) => {
  const [token, setToken] = React.useState('');
  const [inputError, setInputError] = React.useState(false);
  const [accountId, setAccountId] = React.useState('');
  const { t } = useTranslation();
  const notifications = useNotificationSystem();
  const [addClientIntegration] = useCreateClientIntegration();
  const [addClientConnector] = useCreateClientConnector();

  const onChangeAccountId = (e: React.FormEvent<HTMLInputElement>) => {
    setAccountId((e.currentTarget.value || '').trim());
  };

  const addClientIntegrationOrConnector = reveal
    ? (args: any) =>
        addClientConnector({
          ...args,
          variables: { input: { ...args.variables } },
        })
    : addClientIntegration;

  const onChangeToken = (e: React.FormEvent<HTMLInputElement>) => {
    setToken((e.currentTarget.value || '').trim());
    setInputError(false);
  };

  const handleSave = async () => {
    try {
      await addClientIntegrationOrConnector({
        variables: {
          type,
          mode,
          token,
          ...(accountId && { accountId }),
        },
      });
      notifications.success(t('common.genericSuccess'));
    } catch (e) {
      setInputError(true);
      notifications.error(t('common.genericError'));
    }
  };

  const useOauth2Token = async ({ token: oauthCode }: { token: string }) => {
    try {
      await addClientIntegrationOrConnector({
        variables: {
          type,
          mode: 'oauth',
          token: oauthCode,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  if (mode === 'token') {
    return (
      <>
        {type === 'workable' && (
          <div className='input-container'>
            <div className='input-label'>
              {t('integrations.connection.subdomainHeader')}
            </div>
            <div className='input-element'>
              <Input
                placeholder={t('integrations.connection.subdomainPlaceholder')}
                value={accountId}
                onChange={onChangeAccountId}
              />
              <span>&nbsp;&nbsp;.workable.com</span>
            </div>
          </div>
        )}
        <div className='input-container'>
          <div className='input-label'>
            {t('integrations.connection.tokensHeader')}
          </div>
          <div className='settings-row'>
            <Input
              placeholder={t('integrations.connection.tokensPlaceholder')}
              value={token}
              onChange={onChangeToken}
              error={inputError}
              fluid
            />
            <GenericButton onClick={handleSave} disabled={_.isEmpty(token)}>
              {t('integrations.connection.save')}
            </GenericButton>
          </div>
        </div>
      </>
    );
  }
  if (mode === 'oauth') {
    const oauth2Provider = OAuth2Provider({
      type,
      reveal,
    });
    const onClick = oauth2Provider
      ? oauth2Provider.authorizeWithOAuth({
          onAuthorizedCallback: useOauth2Token,
        })
      : () => null;
    return (
      <div className='input-container'>
        <GenericButton onClick={onClick}>
          {t('integrations.connection.connect')}
        </GenericButton>
      </div>
    );
  }
  return null;
};

export default BasicCredentialsForm;
