import React from 'react';
import { Redirect } from 'react-router-dom';
import { compose } from 'underscore';
import routerParamsToProps from '../hocs/routerParamsToProps';
import { decodeToken } from '../common';

import ForgottenPassword from '../containers/Auth/ForgottenPasswordForm';

class ForgottenPasswordView extends React.PureComponent {
  render() {
    const { clientId } = this.props;

    const token = localStorage.getItem('token');
    const decodedJWT = token ? decodeToken(token) : token;

    if (decodedJWT && decodedJWT.clientId === clientId) {
      return <Redirect to={`/client/${clientId}/dashboard`} />;
    }

    return (
      <div>
        <ForgottenPassword clientId={clientId} />
      </div>
    );
  }
}
export default compose(routerParamsToProps)(ForgottenPasswordView);
