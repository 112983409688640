import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import { LinkedInAccount } from '@/types/LinkedInAccount';
import {
  GET_LINKEDIN_ACCOUNTS,
  DELETE_LINKEDIN_ACCOUNT,
} from '@/graphql/linkedAccounts';

interface DeleteLinkedinAccountModalProps {
  open: boolean;
  clientId: string;
  linkedinAccount: LinkedInAccount | null | undefined;
  closeModal: () => void;
}
const DeleteLinkedinAccountModal: React.FC<DeleteLinkedinAccountModalProps> = ({
  open,
  clientId,
  linkedinAccount,
  closeModal,
}) => {
  const { t } = useTranslation();

  const [deleteLinkedinAccount] = useMutation(DELETE_LINKEDIN_ACCOUNT, {
    refetchQueries: [
      {
        query: GET_LINKEDIN_ACCOUNTS,
        variables: {
          clientId,
        },
      },
    ],
  });

  if (!linkedinAccount) return null;
  const onDelete = () => {
    deleteLinkedinAccount({
      variables: { input: { id: linkedinAccount.id } },
    }).then(closeModal);
  };
  return (
    <ConfirmationModal
      header={t('settings.linkedinAccounts.deletionModal.title')}
      onCancel={closeModal}
      onSubmit={onDelete}
      validationType='negative'
      open={open}
    />
  );
};

export default DeleteLinkedinAccountModal;
