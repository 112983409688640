import classNames from 'classnames';
import React from 'react';
import _, { compose } from 'underscore';
import { Grid, Input, Image } from 'semantic-ui-react';
import { withApollo } from '@apollo/client/react/hoc';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import { SearchIcon } from '../../../assets/icons';
import {
  getSecureLink,
  textEllipsis,
  getRandomDefaultAvatarLink,
} from '../../../common';
import withClientBaseOffers from '../../../hocs/offers/withClientBaseOffers';
import { query as clientProfilesQuery } from '../../../hocs/profiles/withClientProfiles';
import { query as clientProfilesByNumberQuery } from '../../../hocs/profiles/withClientProfilesByNumber';
import withActionLogger from '../../../hocs/withActionLogger';

import './ClientProfilesSearchBar.css';

const ProfileMiniCard = ({
  clientId,
  profile,
  selected,
  titleFromId,
  onClick,
}) => {
  const resumeData = (profile || {}).resumeData || {};
  const jobOfferId = (profile || {}).jobOfferId || '';
  const jobOfferIsUnclassified = profile?.offer?.isUnclassified;
  const id = (profile || {}).id || '';
  let link = '';
  if (jobOfferIsUnclassified) {
    link =
      clientId && jobOfferId && id
        ? `/client/${clientId}/discover/candidates?profileId=${id}`
        : '';
  } else {
    link =
      clientId && jobOfferId && id
        ? `/client/${clientId}/jobs/${jobOfferId}/profiles/${id}`
        : '';
  }

  const { firstname, lastname, headline, photoLink } = resumeData;
  const headlineText = ((headline || {}).content || {}).text;

  const fullname =
    (firstname || '') + (firstname && lastname ? ' ' : '') + (lastname || '');
  const wrappedFullname = textEllipsis(
    fullname,
    jobOfferIsUnclassified ? 30 : 15,
  );

  const offerTitle = titleFromId[jobOfferId] || '';
  const wrappedOfferTitle = textEllipsis(
    offerTitle,
    30 - wrappedFullname.length,
  );
  const avatarLink = getRandomDefaultAvatarLink(firstname + lastname);
  return (
    <Link
      to={link}
      as='div'
      className='filtered-profile-card-container'
      onClick={onClick}
    >
      <div
        className={`filtered-profile-card-subcontainer${selected ? ' selected' : ''
          }`}
      >
        <div className='filtered-profile-card'>
          <Grid style={{ padding: 0, margin: 0 }}>
            <Grid.Row style={{ padding: 0, margin: 0 }}>
              <Grid.Column width={3}>
                <div className='profile-bubble'>
                  <Image
                    circular
                    src={getSecureLink(photoLink) || avatarLink}
                    onError={(e) => {
                      e.target.src = avatarLink;
                    }}
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={13}>
                <div className='content'>
                  <div className='name'>
                    {wrappedFullname}
                    {!jobOfferIsUnclassified && (
                      <>
                        <div
                          style={{
                            display: 'inline-block',
                            paddingLeft: 5,
                            paddingRight: 5,
                            opacity: 0.3,
                          }}
                        >
                          <span style={{ fontSize: 12 }}> ▸ </span>
                        </div>
                        {wrappedOfferTitle}
                      </>
                    )}
                  </div>
                  <div className='description'>
                    {textEllipsis(headlineText, 38)}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    </Link>
  );
};

const FilteredProfiles = ({
  profiles,
  selected,
  clientId,
  titleFromId,
  onClose,
  t,
}) => {
  if (_.isEmpty(profiles)) {
    return (
      <div className='filtered-profiles'>
        <div className='no-filtered-profiles'>
          <center>
            <span>{t('header.search.noResults')}</span>
          </center>
        </div>
      </div>
    );
  }
  return (
    <div className='filtered-profiles with-profiles'>
      {_.map(profiles, (profile, index) => (
        <ProfileMiniCard
          key={index}
          clientId={clientId}
          profile={profile}
          titleFromId={titleFromId}
          selected={
            selected && selected.id && selected.id === (profile || {}).id
          }
          onClick={onClose}
        />
      ))}
    </div>
  );
};

const withProfilesLoader = (WrappedComponent) =>
  withApollo(
    // WARNING: Overwrites client prop
    (props) => {
      const getClientProfiles = async ({ search }) => {
        if (!search) {
          return [];
        }
        const param = {
          query: clientProfilesQuery,
          variables: {
            clientId: props.clientId,
            search,
          },
          fetchPolicy: 'no-cache'
        };
        const clientProfiles = (
          ((await props.client.query(param)).data || {}).client || {}
        ).profiles;
        return clientProfiles;
      };
      return (
        <WrappedComponent {...props} getClientProfiles={getClientProfiles} />
      );
    },
  );

const withProfilesLoaderByNumber = (WrappedComponent) =>
  withApollo(
    // WARNING: Overwrites client prop
    (props) => {
      const getClientProfilesByNumber = async ({ searchNumber }) => {
        if (!searchNumber) {
          return [];
        }
        const param = {
          query: clientProfilesByNumberQuery,
          variables: {
            clientId: props.clientId,
            searchNumber,
          },
          fetchPolicy: 'no-cache'
        };
        const clientProfiles = (
          ((await props.client.query(param)).data || {}).client || {}
        ).profilesByNumber;
        return clientProfiles;
      };
      return (
        <WrappedComponent {...props} getClientProfilesByNumber={getClientProfilesByNumber} />
      );
    },
  );
class ClientProfilesSearchbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideSelection: true,
    };
  }

  handleKeyDown = (e) => {
    const { clientId, history } = this.props;
    const { filteredProfiles, selected } = this.state;

    if (_.isEmpty(filteredProfiles)) {
      return;
    }
    const index =
      selected && selected.id
        ? _.findIndex(filteredProfiles, { id: selected.id })
        : -1;

    if (e.keyCode === 38) {
      // previous
      const targetIndex = index - 1;
      if (targetIndex >= 0 && targetIndex < filteredProfiles.length) {
        this.setState({
          selected: {
            id: filteredProfiles[targetIndex].id,
            jobOfferId: filteredProfiles[targetIndex].jobOfferId,
          },
        });
      } else {
        this.setState({
          selected: null,
        });
      }
    } else if (e.keyCode === 40) {
      // next
      const targetIndex = index + 1;
      if (targetIndex < filteredProfiles.length) {
        this.setState({
          selected: {
            id: filteredProfiles[targetIndex].id,
            jobOfferId: filteredProfiles[targetIndex].jobOfferId,
          },
        });
      }
    } else if (e.keyCode === 13) {
      // select
      if (
        history &&
        clientId &&
        selected &&
        selected.id &&
        selected.jobOfferId
      ) {
        const link = `/client/${clientId}/jobs/${selected.jobOfferId}/profiles/${selected.id}`;
        history.push(link);
      }
    }
  };

  handleUpdateResult = _.throttle(async ({ search }) => {
    let profiles = [];
    const trimmedText = search.trim();
    if (
      trimmedText.search(/^[+]?[(]?\d{2,3}[)]?[-\s.]?\d{1,3}[-\s.]?/i) >= 0
    ) {
      if (trimmedText.replace(/^\+?33/, '0').replace(/\s/g, '').length < 8) {
        return;
      }
      const queryResult = await this.props.getClientProfilesByNumber({ searchNumber: trimmedText });
      profiles = (queryResult || []).slice(0, 6);
    }
    else {
      const queryResult = await this.props.getClientProfiles({ search: trimmedText });
      profiles = (queryResult || []).slice(0, 6);
    }
    const { offers } = this.props;
    if (!_.isEmpty(offers)) {
      const profilesWithOffer = _.map(profiles, (profile) => ({
        ...profile,
        offer: _.findWhere(offers, { id: profile.jobOfferId }),
      }));
      this.setState({
        filteredProfiles: profilesWithOffer,
        selected: null,
      });
    } else {
      this.setState({
        filteredProfiles: profiles,
        selected: null,
      });
    }
  }, 300);

  handleChangeSearch = async (e, { value }) => {
    this.handleUpdateResult({ search: value });
    this.setState({
      searchText: value || null,
      selected: null,
    });
  };

  // TODO: keep; Called when clicking outside of the component (see react-onclickoutside)
  handleClickOutside = (evt) => {
    // console.log('click-outside');
    this.setState({ hideSelection: true });
  };

  handleClickInside = (evt) => {
    // console.log('click-inside');
    const { onLogAction, clientId } = this.props;
    const { hideSelection } = this.state;
    if (hideSelection !== false) {
      onLogAction({
        type: 'click-profiles-search-bar',
        info: {
          clientId,
          from: 'top-header',
        },
      });
    }
    this.setState({ hideSelection: false }, () => {
      this.profileSearchInput.focus();
    });
  };

  render() {
    const { t, clientId, offers } = this.props;
    const {
      searchText,
      hideSelection,
      filteredProfiles,
      selected,
    } = this.state;

    const clientOffers = offers || [];
    const titleFromId = {};
    _.each(clientOffers, ({ id, title }) => {
      if (id && title) {
        titleFromId[id] = title;
      }
    });

    return (
      <div className='client-profiles-search-bar-wrapper'>
        {/* {hideSelection && ( */}
        <div onClick={this.handleClickInside} style={{ cursor: 'pointer' }}>
          <SearchIcon className='profiles-search-icon' />
        </div>
        {/* )} */}
        <div
          className={classNames(
            'client-profiles-search-bar',
            hideSelection && 'hidden',
          )}
          onKeyDown={this.handleKeyDown}
        >
          <Input
            ref={(input) => {
              this.profileSearchInput = input;
            }}
            fluid
            icon={
              <i className='icon custom-dropdown-icon'>
                <SearchIcon className='profiles-search-icon' />
              </i>
            }
            // icon='search'
            iconPosition='left'
            className={_.isEmpty(searchText) ? '' : 'not-empty'}
            placeholder={t('header.search.searchProfiles')}
            onChange={this.handleChangeSearch}
          />
          {searchText && !hideSelection && (
            <FilteredProfiles
              t={t}
              titleFromId={titleFromId}
              clientId={clientId}
              profiles={filteredProfiles || {}}
              selected={selected}
              onClose={() => this.setState({ hideSelection: true })}
            />
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  withActionLogger,
  withRouter,
  withClientBaseOffers,
  withTranslation('translations'),
  withProfilesLoader,
  withProfilesLoaderByNumber,
  onClickOutside,
)(ClientProfilesSearchbar);
