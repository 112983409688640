import gql from 'graphql-tag';
import { MutationTuple, useMutation } from '@apollo/client';

import { UserEnrichmentIntegration } from '@/graphql/fragments/EnrichmentIntegration';

type UserProviderVariables = {
  input: {
    type: string;
    status: string;
    apiKey: string;
  };
};

type MutationPayload = {
  client: ClientResponse;
};

interface ClientResponse {
  id: string;
}

const CREATE_REVEAL_PROVIDER = gql`
  mutation createUserDataProvider($input: DataProviderInput) {
    addDataProvider(input: $input) {
      id
      clients {
        id
        enrichmentIntegrations {
          ...UserEnrichmentIntegration
        }
      }
    }
  }
  ${UserEnrichmentIntegration}
`;

const useCreateClientProvider = (): MutationTuple<
  MutationPayload,
  UserProviderVariables
> => {
  return useMutation(CREATE_REVEAL_PROVIDER);
};

export default useCreateClientProvider;
