import _ from 'underscore';
import React, {
  useMemo,
  useCallback,
  // useState,
  useContext,
  useEffect,
} from 'react';
import {
  DocumentNode,
  LazyQueryHookOptions,
  OperationVariables,
  useApolloClient,
  useLazyQuery,
  WatchQueryOptions,
} from '@apollo/client';

interface DataLoaderSegmentProfilesContextProviderProps {
  query: DocumentNode;
  queryCount: DocumentNode;
  queryOptions: LazyQueryHookOptions;
  children: React.ReactNode;
}

interface BundleQueryOptionsArg {
  offset?: number;
  queryOptions?: Partial<LazyQueryHookOptions>;
}

export type BundleAndQueryArgs = [any, BundleQueryOptionsArg];

interface ContextInterface {
  bundleAndQuery: (...args: BundleAndQueryArgs) => void;
  data: any;
  dataCount: any;
  updateQuery: <TVars = OperationVariables>(
    mapFn: (
      previousQueryResult: any,
      options: Pick<WatchQueryOptions<TVars, any>, 'variables'>,
    ) => any,
  ) => void;
  fetchCount: () => void;
}

export const DataLoaderSegmentProfilesContext =
  React.createContext<ContextInterface | null>(null);
const DataLoaderSegmentProfilesContextProvider: React.FC<
  DataLoaderSegmentProfilesContextProviderProps
> = ({ query, queryCount, queryOptions, children }) => {
  const apolloClient = useApolloClient();

  // const [inputVariables, setInputVariables] = useState(queryOptions);

  // usefull if top level query options change
  // useEffect(() => {
  //   setInputVariables(queryOptions);
  // }, [queryOptions]);

  const [fetch, { data, updateQuery, fetchMore }] = useLazyQuery(
    query,
    queryOptions,
  );

  const [fetchCount, { data: dataCount, fetchMore: fetchMoreCount }] =
    useLazyQuery(queryCount, queryOptions);

  const fetchCountProxy = useCallback(
    (args) => {
      const firstExtraArgs = args[0]?.[1] || {};
      const { offset, queryOptions: queryOptionsOverride = {} } =
        firstExtraArgs;

      const newFetchData: LazyQueryHookOptions = {
        ...queryOptions,
        ...queryOptionsOverride,
        variables: {
          ...queryOptions.variables,
          input: {
            getCount: true,
            ...queryOptions?.variables?.input,
            segments: _.map(args, (arg) => ({
              segmentId: arg[0].segmentId,
              missionsFilter: arg[0].missionsFilter,
              type: arg[0].type,
              nextAvailabilityFilter: arg[0].nextAvailabilityFilter,
            })),
          },
        },
      };
      if (offset > 0) {
        fetchMoreCount(newFetchData);
      } else {
        fetchCount(newFetchData);
      }
    },
    [queryOptions, fetchCount, fetchMoreCount],
  );

  const fetchProxy = useCallback(
    (args) => {
      // args[0] is the first list of arguments in our stack, which looks like fetch(segmentDef, { offset });
      // args[0][1] is thus { offset }
      const firstExtraArgs = args[0]?.[1] || {};
      const { offset, queryOptions: queryOptionsOverride = {} } =
        firstExtraArgs;

      const newFetchData: LazyQueryHookOptions = {
        ...queryOptions,
        ...queryOptionsOverride,
        variables: {
          ...queryOptions.variables,
          input: {
            ...queryOptions?.variables?.input,
            segments: _.map(args, (arg) => ({
              segmentId: arg[0].segmentId,
              missionsFilter: arg[0].missionsFilter,
              type: arg[0].type,
              nextAvailabilityFilter: arg[0].nextAvailabilityFilter,
            })),
            ...(offset > 0 && { offset }),
          },
        },
      };

      if (offset > 0) {
        fetchMore(newFetchData);
      } else {
        fetch(newFetchData);
      }
    },
    [queryOptions, fetch, fetchMore],
  );

  const bundleAndQuery = useMemo(() => {
    let lastArgs: any[] = [];
    let currentTimeout: NodeJS.Timeout;
    const internalBundleAndQuery = (...args: BundleAndQueryArgs) => {
      // if we are in the 200ms time period
      // we need to cancel previous timeout to prevent
      // the query
      clearTimeout(currentTimeout);
      lastArgs.push(args);
      currentTimeout = setTimeout(() => {
        fetchProxy(lastArgs);
        fetchCountProxy(lastArgs);
        // We need to empty the args to prepare the function for the next batch
        lastArgs = [];
      }, 200);
    };

    return internalBundleAndQuery;
  }, [fetchProxy, fetchCountProxy]);

  useEffect(() => {
    // This useEffect handles a very specific case for cache. Since we
    // are using pagination we have to handle the cache merge function manually,
    // and we asked apollo not to use inputs as a cache key.
    // This is because inputs can be bundled and vary a lot (if we add offset it breaks the cache).
    // Moreover, in the "crossproject/changed mission" use-case we would love for
    // the cache key to be composed of the missionIds and nothing else, essentially
    // giving us the ability to cache per-project-list, but this might be a case among many...
    // tomorrow we might need to cache by missionIds + recruiter etc...
    // The solution for now is to ask the context to empty the cache if it is unmounted, effectively
    // allowing the next network answer to fill it up again (without merging because of pagination).

    // However, this also means that for now we cannot have 2 different bundled queries for the same resolver
    // at the same time (imagine kanban 1 on top, kanban 2 below), since they're going to
    // share their cache. Because the filters are "column only" it is difficult to configure
    // apollo to ask it to use them as cache keys and we might need to construct the key programatically. However this
    // comes back to the problem above, where we don't really know _what_ we want to use as a cache key.
    // The ideal scenario would be for us to provide the cache key as a prop of the context, allowing it to reuse it
    // infinitely and have many contexts for the same provider available at the same time
    const { cache } = apolloClient;

    return () => {
      const searchPoolCacheId = cache.identify({
        __typename: 'SearchPool',
        id: 'reveal',
      });

      cache.modify({
        id: searchPoolCacheId,
        fields: {
          segmentProfileResults: () => {
            return [];
          },
        },
      });
    };
  }, [apolloClient]);

  const context = useMemo(
    () => ({ bundleAndQuery, data, dataCount, updateQuery, fetchCount }),
    [bundleAndQuery, data, dataCount, updateQuery, fetchCount],
  );

  return (
    <DataLoaderSegmentProfilesContext.Provider value={context}>
      {children}
    </DataLoaderSegmentProfilesContext.Provider>
  );
};

export const useDataLoaderSegmentProfilesContext = () => {
  const context = useContext(DataLoaderSegmentProfilesContext);
  if (!context) {
    throw new Error(
      'No context found, did you forget to wrap in DataLoaderSegmentProfilesContextProvider?',
    );
  }

  return context;
};

export default DataLoaderSegmentProfilesContextProvider;
