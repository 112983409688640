import React from 'react';
import { useMutation } from '@apollo/client';
import { IMPORT_PROFILES_CSV } from '@/graphql/searchPoolProfile';
import { formatProfiles, User } from './helpers';

type Profiles = ReturnType<typeof formatProfiles>['profiles'];

type CSVImportUploadPayload = {
  profiles: Profiles;
  importName: string;
  recruiter: User;
  missionId: string;
};
type CSVUploader = {
  reset: () => void;
  upload: (payload: CSVImportUploadPayload) => Promise<void>;
  isUploading: boolean;
  importedProfiles: unknown;
};

/**
 * Upload rows as profiles
 */
export default function useCSVProfilesUploader(): CSVUploader {
  const [importingProfiles, setImportingProfiles] = React.useState(false);
  const [importProfilesResults, setImportProfilesResults] = React.useState(
    null,
  );

  const [importProfilesCSV] = useMutation(IMPORT_PROFILES_CSV);

  const upload = async (payload: CSVImportUploadPayload) => {
    try {
      setImportingProfiles(true);
      const variables = {
        searchPoolId: 'reveal',
        input: {
          ...payload,
        },
      };
      const { data } = (await importProfilesCSV({ variables })) || {};
      const results = data?.searchPool?.importProfilesCSV;
      setImportProfilesResults(results);
    } catch (e) {
      console.error('Import failed', e);
    }
    setImportingProfiles(false);
  };

  const reset = () => {
    setImportingProfiles(false);
    setImportProfilesResults(null);
  };

  return {
    upload,
    reset,
    isUploading: importingProfiles,
    importedProfiles: importProfilesResults,
  };
}
