import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SavedSearch from '../../graphql/fragments/SavedSearch';

export const deleteSearch = gql`
  mutation deleteSearch($searchPoolId: ID!, $searchId: ID!) {
    deleteSearch(searchId: $searchId) {
      id
      savedSearches(searchPoolId: $searchPoolId) {
        ...SavedSearch
      }
    }
  }
  ${SavedSearch}
`;

export default graphql(deleteSearch, {
  props: ({ mutate }) => ({
    deleteSearch: ({ searchId }) => {
      const variables = { searchId };
      return mutate({ variables });
    },
  }),
});
