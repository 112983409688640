import gql from 'graphql-tag';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';

import { TrackedLinksDailyStatistics } from '@/types/statistics/trackedLinks';
import useClientId from '@/hooks/router/useClientId';
import { isTodayYyyyMmDd } from '@/common/utils/time';
import { GenericStatsFilterInput } from '../common';

export const GET_TRACKED_LINKS_DAILY_EVENTS = gql`
  query getTrackedLinksDailyEvents(
    $clientId: ID!
    $startDate: Date
    $endDate: Date
    $sequencesFilter: GenericStatsFilterInput
    $urlsFilter: GenericStatsFilterInput
  ) {
    client(id: $clientId) {
      id
      statistics {
        trackedLinks(
          startDate: $startDate
          endDate: $endDate
          sequencesFilter: $sequencesFilter
          urlsFilter: $urlsFilter
        ) {
          dailyEvents {
            day
            linkStats {
              url
              sequenceId
              missionId
              eventType
              count
            }
          }
        }
      }
    }
  }
`;

interface GetTrackedLinksDailyEventsData {
  client: {
    id: string;
    statistics: {
      trackedLinks: {
        dailyEvents: TrackedLinksDailyStatistics[];
      };
    };
  };
}

export interface GetTrackedLinksDailyEventsVariables {
  clientId: string;
  startDate?: string;
  endDate?: string;
  sequencesFilter?: GenericStatsFilterInput;
  urlsFilter?: GenericStatsFilterInput;
}

interface UseTrackedLinksDailyStatisticsResults
  extends QueryResult<
    GetTrackedLinksDailyEventsData,
    GetTrackedLinksDailyEventsVariables
  > {
  trackedLinksDailyStatistics: TrackedLinksDailyStatistics[];
}

interface UseTrackedLinksDailyStatisticsInput
  extends Omit<GetTrackedLinksDailyEventsVariables, 'clientId'> {
  queryOptions?: QueryHookOptions<
    GetTrackedLinksDailyEventsData,
    GetTrackedLinksDailyEventsVariables
  >;
}

const useTrackedLinksDailyStatistics = ({
  startDate,
  endDate,
  sequencesFilter,
  urlsFilter,
  queryOptions = {},
}: UseTrackedLinksDailyStatisticsInput = {}): UseTrackedLinksDailyStatisticsResults => {
  const clientId = useClientId();
  const query = useQuery<
    GetTrackedLinksDailyEventsData,
    GetTrackedLinksDailyEventsVariables
  >(GET_TRACKED_LINKS_DAILY_EVENTS, {
    ...queryOptions,
    variables: {
      clientId,
      startDate,
      endDate: isTodayYyyyMmDd(endDate) ? undefined : endDate,
      sequencesFilter,
      urlsFilter,
    },
  });

  const trackedLinksDailyStatistics =
    query.data?.client.statistics.trackedLinks.dailyEvents || [];
  return { ...query, trackedLinksDailyStatistics };
};

export default useTrackedLinksDailyStatistics;
