import React from 'react';
import _, { compose } from 'underscore';

import { withTranslation } from 'react-i18next';
import withClientTemplates from '../../../../../hocs/templates/withClientTemplates';
import withUserSettings from '../../../../../hocs/users/withUserSettings';
import withOfferRecruiterConfiguration from '../../../../../hocs/offers/withOfferRecruiterConfiguration';

import getPermissionsAndNudgesAtIndex from '../../../../../common/contactFlow/getPermissionsAndNudgesAtIndex';

import ContactFlowActionEditor from '../ContactFlowActionEditor';
import AddContactFlowActionButton from '../ContactFlowActionAddButon';
import ContactFlowPlaceholderSequence from './ContactFlowPlaceholderSequence';
import GoToButton from '../../GoToButton';

import './ContactFlowSequenceEditor.css';
import withClientPermissions from '../../../../../hocs/clients/withClientPermissions';

class ContactFlowSequenceEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { newFollowupOpen: false };
  }

  handleInsertAction = (args) => {
    const { onInsertAction } = this.props;
    onInsertAction(args);
    this.setState({ newFollowupOpen: false });
  };

  render() {
    const {
      clientId,
      offerId,
      user,
      recruiterConfiguration,
      permissions,
      sequence,
      lastNonEmptySequence,
      onAddSequence,
      onUpdateSequenceTrigger,
      onUpdateAction,
      onRemoveAction,
      onRemoveActions,
      templates,
      t,
      defaultTemplatesOnSeveralOffers,
      updateAfterTemplateCreation,
      onQuitContactFlowEditor,
    } = this.props;

    const { newFollowupOpen } = this.state;

    if (sequence.isPlaceholder) {
      return (
        <ContactFlowPlaceholderSequence
          sequence={sequence}
          onAddSequence={onAddSequence}
        />
      );
    }
    const sequenceId = sequence.id;

    const onUpdate = ({ action }) =>
      onUpdateAction({ sequenceId, actionId: action.actionId, action });

    const lastSequenceId = (lastNonEmptySequence || {}).id;
    const startSequenceAfterDelay = () => {
      const defaultDelayAfterSequence = {
        type: 'delay-after-sequence',
        delay: {
          value: 30,
          unit: 'day',
        },
        sequenceId: lastSequenceId,
      };
      onUpdateSequenceTrigger({
        sequenceId: sequence.id,
        trigger: defaultDelayAfterSequence,
      });
    };

    const { position, trigger, actions = [] } = sequence || {};
    if (sequence && !trigger && position === 'custom') {
      return (
        <div className='sequence-trigger-selector'>
          <GoToButton disabled>Start Sequence after a response</GoToButton>
          <br />
          <GoToButton
            onClick={startSequenceAfterDelay}
            disabled={!lastSequenceId}
          >
            Start Sequence after a delay
          </GoToButton>
        </div>
      );
    }

    const deleteActionsAfterIndex = (index) => async () => {
      const actionsToDelete = actions.slice(index);
      const actionIds = _.pluck(actionsToDelete, 'actionId');
      return onRemoveActions({ sequenceId, actionIds });
    };

    const maxNbFollowups = permissions?.maxNbFollowups ?? 1;
    const currentFollowupCount = actions.length - 1;
    const showAddFollowupPrompt =
      !newFollowupOpen &&
      maxNbFollowups > 1 &&
      currentFollowupCount < maxNbFollowups &&
      currentFollowupCount > 0;

    const offerAssignedSender = recruiterConfiguration?.assignedSender;
    const userSignature = user && user.signature ? user.signature : null;
    const senderSignature = offerAssignedSender?.signature || null;
    const signature = !_.isEmpty(offerAssignedSender)
      ? senderSignature
      : userSignature;

    const templateOptions = _.map(
      _.where(templates, { isArchived: false }),
      ({ id, title }) => ({
        value: id,
        text: title,
      }),
    );

    return (
      <div className='contact-flow-sequence-editor'>
        <div className='contact-flow-sequence'>
          {/* <div className='sequence-editor-header'>
            <div>
              <Icon name='trash alternate' onClick={() => onRemoveSequence({ sequenceId: sequence.id })} link />
            </div>
          </div>
          <ContactFlowSequenceTrigger sequence={sequence} /> */}
          <div className='sequence-actions'>
            <div className='sequence-action'>
              <ContactFlowAction
                offerId={offerId}
                clientId={clientId}
                sequenceId={sequence.id}
                actions={actions}
                index={0}
                action={actions[0]}
                signature={signature}
                templates={templates}
                onUpdate={onUpdate}
                onDelete={() =>
                  onRemoveAction({
                    sequenceId,
                    actionId: (actions[0] || {}).actionId,
                  })
                }
                onDeleteMultiple={deleteActionsAfterIndex(0)}
                templateOptions={templateOptions}
                onInsertAction={this.handleInsertAction}
                defaultTemplatesOnSeveralOffers={
                  defaultTemplatesOnSeveralOffers
                }
                updateAfterTemplateCreation={updateAfterTemplateCreation}
                onQuitContactFlowEditor={onQuitContactFlowEditor}
                t={t}
              />
            </div>
            {(actions || []).length > 0 && (
              <>
                <VerticalLine />
                <div className='sequence-action'>
                  <ContactFlowAction
                    offerId={offerId}
                    clientId={clientId}
                    sequenceId={sequence.id}
                    actions={actions}
                    index={1}
                    action={actions[1]}
                    signature={signature}
                    templates={templates}
                    onUpdate={onUpdate}
                    onDelete={() =>
                      onRemoveAction({
                        sequenceId,
                        actionId: (actions[1] || {}).actionId,
                      })
                    }
                    onDeleteMultiple={deleteActionsAfterIndex(1)}
                    templateOptions={templateOptions}
                    onInsertAction={this.handleInsertAction}
                    defaultTemplatesOnSeveralOffers={
                      defaultTemplatesOnSeveralOffers
                    }
                    updateAfterTemplateCreation={updateAfterTemplateCreation}
                    onQuitContactFlowEditor={onQuitContactFlowEditor}
                    t={t}
                  />
                </div>
              </>
            )}
            {_.map(actions.slice(2), (action, index) => (
              <div
                key={action.id || action.actionId}
                className='sequence-action'
              >
                <VerticalLine />
                <ContactFlowAction
                  offerId={offerId}
                  clientId={clientId}
                  sequenceId={sequence.id}
                  actions={actions}
                  index={index + 2}
                  action={action}
                  signature={signature}
                  templates={templates}
                  onUpdate={onUpdate}
                  onDelete={() =>
                    onRemoveAction({ sequenceId, actionId: action.actionId })
                  }
                  onDeleteMultiple={deleteActionsAfterIndex(index + 2)}
                  templateOptions={templateOptions}
                  onInsertAction={this.handleInsertAction}
                  defaultTemplatesOnSeveralOffers={
                    defaultTemplatesOnSeveralOffers
                  }
                  updateAfterTemplateCreation={updateAfterTemplateCreation}
                  onQuitContactFlowEditor={onQuitContactFlowEditor}
                  t={t}
                />
              </div>
            ))}
            {showAddFollowupPrompt && (
              <>
                <VerticalLine />
                <div className='offer-2nd-followup-prompt'>
                  <span
                    onClick={() => this.setState({ newFollowupOpen: true })}
                  >
                    {t('offers.defaultEmails.addFollowup')}
                  </span>
                </div>
              </>
            )}
            {newFollowupOpen && (
              <>
                <VerticalLine />
                <div className='sequence-action'>
                  <ContactFlowAction
                    offerId={offerId}
                    clientId={clientId}
                    sequenceId={sequence.id}
                    actions={actions}
                    index={actions.length}
                    signature={signature}
                    templates={templates}
                    onUpdate={onUpdate}
                    templateOptions={templateOptions}
                    onInsertAction={this.handleInsertAction}
                    removeNewFollowupCard={() =>
                      this.setState({ newFollowupOpen: false })
                    }
                    defaultTemplatesOnSeveralOffers={
                      defaultTemplatesOnSeveralOffers
                    }
                    updateAfterTemplateCreation={updateAfterTemplateCreation}
                    onQuitContactFlowEditor={onQuitContactFlowEditor}
                    t={t}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const VerticalLine = () => (
  <div className='vertical-line-separator'>
    <div className='line' />
  </div>
);

const ContactFlowAction = ({
  offerId,
  sequenceId,
  onInsertAction,
  action,
  actions,
  removeNewFollowupCard,
  index,
  t,
  updateAfterTemplateCreation,
  ...rest
}) => {
  if (!action) {
    return (
      <AddContactFlowActionButton
        actions={actions}
        index={index}
        offerId={offerId}
        sequenceId={sequenceId}
        onInsertAction={({ newAction }) =>
          onInsertAction({
            sequenceId,
            index,
            action: newAction,
          })
        }
        permissionsAndNudges={getPermissionsAndNudgesAtIndex({
          actions,
          index,
          editType: 'insert',
          t,
        })}
        removeNewFollowupCard={removeNewFollowupCard}
        updateAfterTemplateCreation={updateAfterTemplateCreation}
        {...rest}
      />
    );
  }
  return (
    <ContactFlowActionEditor
      action={action}
      actions={actions}
      index={index}
      permissionsAndNudges={getPermissionsAndNudgesAtIndex({
        actions,
        index,
        editType: 'update',
        t,
      })}
      {...rest}
    />
  );
};

export default compose(
  withClientTemplates,
  withClientPermissions,
  withUserSettings,
  withOfferRecruiterConfiguration,
  withTranslation('translations'),
)(ContactFlowSequenceEditor);
