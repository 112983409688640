import gql from 'graphql-tag';
import { useCallback } from 'react';
import { QueryHookOptions, QueryResult, useLazyQuery } from '@apollo/client';

import useClientId from '@/hooks/router/useClientId';

const getSearchFromText = gql`
  query getSearchFromText($clientId: ID!, $text: String!) {
    client(id: $clientId) {
      getSearchFromText(text: $text) {
        criteria {
          free
        }
      }
    }
  }
`;

type Data = {
  client: {
    getSearchFromText: {
      criteria: {
        free: string | null;
      } | null;
    } | null;
  };
};

type Variables = {
  clientId: string;
  text: string;
};

type Input = {
  queryOptions?: QueryHookOptions<Data, Variables>;
};

function useGetSearchFromText({ queryOptions = {} }: Input = {}) {
  const clientId = useClientId();
  const [query, result] = useLazyQuery<Data, Variables>(getSearchFromText, {
    ...queryOptions,
  });
  const fetch = useCallback(
    async (text: string) => {
      const queryResult = await query({ variables: { clientId, text } });
      return enrichResult(queryResult);
    },
    [query, clientId],
  );
  return [fetch, enrichResult(result)] as const;
}

function enrichResult(result: QueryResult<Data, Variables>) {
  const search = result.data?.client.getSearchFromText || { criteria: null };
  return { ...result, search };
}

export default useGetSearchFromText;
