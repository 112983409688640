import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import {
  getSecureLink,
  textEllipsis,
  getRandomDefaultAvatarLink,
} from '../../../common';

interface RevealProfileMiniCardProps {
  profile: any;
  onClick: any;
}

const RevealProfileMiniCard: React.FC<RevealProfileMiniCardProps> = ({
  profile,
  onClick,
}) => {
  const { resumeData } = profile;
  const avatarLink = getRandomDefaultAvatarLink(
    resumeData.firstname + resumeData.lastname,
  );
  // const wrappedFullname = textEllipsis(`${resumeData.firstname} ${resumeData.lastname}`, 30);
  const fullname =
    (resumeData?.firstname || '') +
    (resumeData?.lastname
      ? (resumeData.firstname ? ' ' : '') + resumeData.lastname
      : '');
  const wrappedFullname = textEllipsis(fullname, 30);
  return (
    <div className='reveal-profile-mini-card' onClick={onClick}>
      <div className='reveal-profile-mini-card-subcontainer'>
        <Grid style={{ padding: 0, margin: 0 }}>
          <Grid.Row style={{ padding: 0, margin: 0 }}>
            <Grid.Column width={3}>
              <div className='profile-bubble'>
                <Image
                  circular
                  src={getSecureLink(resumeData.photoLink) || avatarLink}
                  onError={(e: any) => {
                    e.target.src = avatarLink;
                  }}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={13}>
              <div className='content'>
                <div className='name'>{wrappedFullname}</div>
              </div>
              {resumeData?.headline?.content?.text && (
                <div className='headline'>
                  {resumeData?.headline?.content?.text}
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default RevealProfileMiniCard;
