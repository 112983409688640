import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useLazyQuery } from '@apollo/client';
import { CampaignDailyCohortDetails } from '@/types/statistics/campaigns';

const GET_CAMPAIGNS_DAILY_COHORTS_DETAILS = gql`
  query getCampaignsDailyCohorts($clientId: ID!, $campaignIds: [ID!]) {
    client(id: $clientId) {
      id
      statistics {
        campaigns(campaignIds: $campaignIds) {
          dailyCohorts {
            day
            details {
              campaignId
              type
              profileItems {
                profileId
              }
            }
          }
        }
      }
    }
  }
`;

interface GetCampaignsDailyCohortsDetailsData {
  client: {
    id: string;
    statistics: {
      campaigns: {
        dailyCohorts: CampaignDailyCohortDetails[];
      };
    };
  };
}

interface GetCampaignsDailyCohortsDetailsVariables {
  clientId: string;
  campaignIds?: string[];
}

interface UseCampaignsDailyCohortsDetailsInput {
  queryOptions?: QueryHookOptions<
    GetCampaignsDailyCohortsDetailsData,
    GetCampaignsDailyCohortsDetailsVariables
  >;
}

type UseCampaignsDailyCohortsDetailsResult = [
  (variables: GetCampaignsDailyCohortsDetailsVariables) => void,
  QueryResult<
    GetCampaignsDailyCohortsDetailsData,
    GetCampaignsDailyCohortsDetailsVariables
  > & {
    dailyCohorts?: CampaignDailyCohortDetails[];
  },
];

const useCampaignsDailyCohortsDetails = ({
  queryOptions = {},
}: UseCampaignsDailyCohortsDetailsInput = {}): UseCampaignsDailyCohortsDetailsResult => {
  const [fetchDetails, query] = useLazyQuery<
    GetCampaignsDailyCohortsDetailsData,
    GetCampaignsDailyCohortsDetailsVariables
  >(GET_CAMPAIGNS_DAILY_COHORTS_DETAILS, queryOptions);

  return [
    (variables) => fetchDetails({ variables }),
    {
      ...query,
      dailyCohorts: query.data?.client.statistics.campaigns.dailyCohorts,
    },
  ];
};

export default useCampaignsDailyCohortsDetails;
