import React from 'react';
import _ from 'underscore';

import { useTranslation } from 'react-i18next';
import useAtsFilterOptions from '@/graphql/hooks/clients/useClientAtsFiltersOptions';

import { ATS_LOGOS_BY_TYPE } from '@/common/reveal';
import AccordionFilter from '../components/AccordionFilter';
import FilterDropdown from '../components/FilterDropdown';
import ProfileInATSFilter from '../components/ProfileInATSFilter';
import getRecruitcrmOptions from './getRecruitcrmOptions';
import {
  CreationDateFilter,
  LastActionDateFilter,
} from '../Filters/DateFilters';
import { countAllFilters } from '../Filters/utils';

// HACK
import '../GreenhouseFilters/GreenhouseFilters.css';

type OnUpdateFunction = (filters: any) => void;

interface RecruitcrmFiltersProps {
  onUpdate: OnUpdateFunction;
  filters: any;
  projectId: string;
  clientId: string;
}
const RecruitcrmFilters: React.FC<RecruitcrmFiltersProps> = ({
  onUpdate,
  filters,
  projectId,
  clientId,
}) => {
  const { t } = useTranslation();
  const { data: atsFilterOptionsData } = useAtsFilterOptions(clientId, {});
  const projectsWithFilterOptions =
    atsFilterOptionsData?.client?.revealProjects;
  const project = _.findWhere(projectsWithFilterOptions, { id: projectId });
  const hasRecruitcrmConnector = !!_.findWhere(project?.connectors, {
    type: 'recruitcrm',
  });

  if (!hasRecruitcrmConnector) {
    return null;
  }

  const handleUpdateJobs = ({ jobIds }: { jobIds?: string[] }) => {
    onUpdate({
      recruitcrmFilters: {
        ...filters,
        jobIds: _.isEmpty(jobIds) ? null : jobIds,
      },
    });
  };

  const handleUpdateStages = ({ stageIds }: { stageIds?: string[] }) => {
    onUpdate({
      recruitcrmFilters: {
        ...filters,
        stageIds: _.isEmpty(stageIds) ? null : stageIds,
      },
    });
  };

  const handleUpdateProfileInATS = ({
    profileInATS,
  }: {
    profileInATS?: {
      yes?: boolean;
      no?: boolean;
    };
  }) => {
    onUpdate({
      recruitcrmFilters: {
        ...filters,
        profileInATS,
      },
    });
  };

  const { stageOptions, jobOptions } = getRecruitcrmOptions({
    projectId,
    projectsWithFilterOptions,
  });

  const atsLogo = ATS_LOGOS_BY_TYPE.recruitcrm;

  return (
    <div className='greenhouse-filters'>
      <AccordionFilter
        title={
          <>
            <span className='filter-icon'>
              <img height='20px' alt='ats logo' src={atsLogo} />
            </span>
            Recruit CRM
          </>
        }
        count={countAllFilters(filters)}
      >
        <div className='sub-title'>
          {' '}
          {t('reveal.searchView.filters.ats.contactExist')}
        </div>
        <ProfileInATSFilter
          value={filters?.profileInATS}
          onUpdate={handleUpdateProfileInATS}
        />
        <br />
        <br />

        <div className='sub-title'>
          {t('reveal.searchView.filters.ats.creationDate')}
        </div>
        <CreationDateFilter
          onUpdate={onUpdate}
          filters={filters}
          atsType='recruitcrm'
        />

        <br />
        <div className='sub-title'>
          {t('reveal.searchView.filters.ats.lastActionDate')}
        </div>
        <LastActionDateFilter
          onUpdate={onUpdate}
          filters={filters}
          atsType='recruitcrm'
        />
        <br />

        <div className='sub-title'>
          {t('reveal.searchView.filters.ats.jobs')}
        </div>

        <FilterDropdown
          options={jobOptions}
          onChange={(
            e: React.SyntheticEvent,
            { value }: { value?: string[] },
          ) => handleUpdateJobs({ jobIds: value })}
          value={filters?.jobIds || []}
        />
        <br />

        <div className='sub-title'>
          {t('reveal.searchView.filters.ats.stages')}
        </div>

        <FilterDropdown
          options={stageOptions}
          onChange={(
            e: React.SyntheticEvent,
            { value }: { value?: string[] },
          ) => handleUpdateStages({ stageIds: value })}
          value={filters?.stageIds || []}
        />
        <br />
      </AccordionFilter>
    </div>
  );
};

export default RecruitcrmFilters;
