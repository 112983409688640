import React from 'react';
import _ from 'underscore';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Segmentations } from '@/components/PipelineSegmentation/data';
import {
  WORKFLOW_ACTION_COMMANDS,
  WORKFLOW_RULE_TYPES,
} from '@/types/searchPoolJob';
import {
  useMissionWorkflowsContext,
  WORKFLOWS_CONTEXT_MODE,
} from '../context/useMissionWorkflowsContext';

interface Props {
  [x: string]: any;
}

export const TriggerRuleDropdown: React.FC<Props> = ({ ...props }) => {
  const { t } = useTranslation();
  const ruleOptions = Object.values(WORKFLOW_RULE_TYPES).map((rule) => ({
    key: rule,
    text: t(`reveal.workflows.ruleTypes.${rule}`, { ats: props.atsName }),
    value: rule,
  }));
  const { styles } = useMissionWorkflowsContext();
  return (
    <Dropdown
      className={styles.dropdown}
      compact
      selection
      selectOnBlur={false}
      options={ruleOptions}
      {...props}
    />
  );
};

export const ActionCommandDropdown: React.FC<Props> = ({ ats, ...props }) => {
  const { t } = useTranslation();
  const actionCommandsOption = Object.values(WORKFLOW_ACTION_COMMANDS).map(
    (action) => ({
      key: action,
      text: t(`reveal.workflows.actionCommands.${action}`, { ats }),
      value: action,
    }),
  );
  const { styles } = useMissionWorkflowsContext();
  return (
    <Dropdown
      className={styles.dropdown}
      compact
      selection
      selectOnBlur={false}
      options={actionCommandsOption}
      {...props}
    />
  );
};

export const MoveToRevealStageDropdown: React.FC<Props> = ({ ...props }) => {
  const { styles, mode } = useMissionWorkflowsContext();
  const { t } = useTranslation();
  return (
    <Dropdown
      className={styles.dropdown}
      search
      compact
      fluid={mode === WORKFLOWS_CONTEXT_MODE.MISSION_WORKFLOWS}
      selection
      selectOnBlur={false}
      options={_.map(Segmentations[0].segments, (segment) => ({
        key: segment.id,
        text: t(`reveal.pipelineSegmentations.${segment.id}`),
        value: segment.id,
      }))}
      placeholder={t('reveal.workflows.chooseStage')}
      {...props}
    />
  );
};
export const MoveToAtsStageDropdown: React.FC<Props> = ({ ...props }) => {
  const { styles, atsStageOptions } = useMissionWorkflowsContext();
  const { t } = useTranslation();
  return (
    <Dropdown
      search
      className={classNames(styles.dropdown, styles.flexGrow)}
      compact
      selection
      selectOnBlur={false}
      {...props}
      options={atsStageOptions}
      placeholder={t('reveal.workflows.chooseStage')}
    />
  );
};
