import React from 'react';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Button, Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { PrimaryCobalt } from '@/less/colors';

import JobOfferMapper from '../components/JobOfferMapper';
import IntegrationHeader from '../components/IntegrationHeader';

import withAddLeverWorkflow from '../../../../hocs/integrations/withAddLeverWorkflow';
import withDeleteLeverWorkflow from '../../../../hocs/integrations/withDeleteLeverWorkflow';
import withDeleteClientIntegration from '../../../../hocs/integrations/withDeleteClientIntegration';
import LeverCredentialsForm from '../CredentialsForms/LeverCredentialsForm';
// import IntegrationATSSwitch from '@/components/IntegrationATSSwitch';

class LeverIntegration extends React.PureComponent {
  handleRevoke = () => {
    const { deleteClientIntegration, integration } = this.props;
    if ((integration || {}).id) {
      deleteClientIntegration({ id: integration.id });
    }
  };

  render() {
    const {
      clientId,
      integration,
      offers,
      addLeverWorkflow,
      deleteLeverWorkflow,
      permissions,
      t,
    } = this.props;
    const { status } = integration || {};
    return (
      <div className='lever-integration'>
        <div className='settings-header'>
          <Breadcrumb
            icon='right angle'
            size='massive'
            style={{
              color: PrimaryCobalt,
              fontWeight: 700,
              fontFamily: 'Gilroy',
              fontSize: '26px',
            }}
          >
            <Breadcrumb.Section
              as={Link}
              to={`/client/${clientId}/integrations`}
              style={{
                color: PrimaryCobalt,
                fontFamily: 'Gilroy',
                marginBottom: '0px',
              }}
            >
              <h1>
                {t('integrations.header')}{' '}
                {permissions?.reveal &&
                  permissions?.watchCollect &&
                  '(Marketplace)'}
              </h1>
            </Breadcrumb.Section>
            <Breadcrumb.Divider
              icon={
                <i
                  style={{ fontSize: '16px', margin: '0px 10px' }}
                  className='ri-arrow-right-s-line'
                />
              }
            />
            <Breadcrumb.Section
              active
              style={{ fontFamilly: 'Gilroy', marginBottom: '0px' }}
            >
              <h1>Lever</h1>
            </Breadcrumb.Section>
          </Breadcrumb>
        </div>
        {permissions?.reveal && permissions?.watchCollect && (
          <>
            <p>
              {t('integrations.subheader', { client: 'CRM' })}
              <Link to={`/client/${clientId}/crm-integrations/lever`}>
                {' '}
                {t('integrations.clickHere')}
              </Link>
              .
            </p>
          </>
        )}
        <div className='section visible'>
          <IntegrationHeader
            number={1}
            text={
              status === 'active'
                ? t('integrations.ATSConnected')
                : t('integrations.connectATS')
            }
          />
          {status !== 'active' && <LeverCredentialsForm />}
          {status === 'active' && (
            <div className='input-container'>
              <Button secondary onClick={this.handleRevoke}>
                {t('integrations.revokeButton')}
              </Button>
            </div>
          )}
          <IntegrationHeader number={2} text={t('integrations.configureATS')} />
          <JobOfferMapper
            title='Lever'
            clientId={clientId}
            offers={offers}
            integration={integration}
            integrationOffers={(integration || {}).jobs}
            addWorkflow={addLeverWorkflow}
            recruiters={(integration || {}).users}
            deleteWorkflow={deleteLeverWorkflow}
            needsRecruiterSelection
            needsStageSelection
          />
        </div>
        {/* <IntegrationATSSwitch
          clientId={clientId}
          clientType="classic"
          connector={status === "active"}
          type={"lever"}
        /> */}
      </div>
    );
  }
}

export default compose(
  withTranslation('translations'),
  withAddLeverWorkflow,
  withDeleteLeverWorkflow,
  withDeleteClientIntegration,
)(LeverIntegration);
