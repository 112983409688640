import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import { useRemoveProfilesFromMission } from '@/graphql/searchPoolProfiles';
import contextToProps from '@/hocs/contextToProps';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import { getJobOptions } from '../../optionsHelpers';

const RemoveFromMissionModal = ({
  missionId,
  candidates,
  onSuccess,
  onCancel,
  onShowNotification,
}) => {
  const { t } = useTranslation();
  const [removeProfilesFromMission] = useRemoveProfilesFromMission();
  const [selectedMissionId, setSelectedMissionId] = useState();
  const {
    jobOptions: jobs,
    jobOptionsLoading: jobsLoading,
  } = useSearchPoolJobOptions('reveal', {
    activeOnly: true,
  });

  const profileIds = _.pluck(candidates, 'id');

  const jobOptions = getJobOptions({ jobs, jobId: missionId });

  const onRemoveFromMission = async () => {
    try {
      const { data } = await removeProfilesFromMission({
        variables: {
          searchPoolId: 'reveal',
          input: {
            missionId: missionId || selectedMissionId,
            profileIds,
          },
        },
      });
      const results =
        data?.searchPool?.removeProfilesFromMission?.results || [];
      const successes = _.filter(results, ({ success }) => success);
      const errors = _.filter(results, ({ success }) => !success);
      if (!_.isEmpty(errors)) {
        throw new Error('Backend error');
      }

      if (!_.isEmpty(successes)) {
        onShowNotification({
          message: t(
            'reveal.missions.mission.actions.removeFromMissionSuccess',
            { count: successes.length },
          ),
          level: 'success',
        });
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      onShowNotification({
        message: t('reveal.candidatesView.actionsBox.removeFromMissionError'),
        level: 'error',
      });
    }

    onCancel();
  };

  return (
    <ConfirmationModal
      open
      onCancel={onCancel}
      onSubmit={onRemoveFromMission}
      header={t('reveal.candidatesView.actionsBox.removeFromMission')}
      content={
        !missionId && (
          <Dropdown
            fluid
            search
            selection
            options={jobOptions}
            loading={jobsLoading}
            value={selectedMissionId}
            onChange={(e, { value }) => setSelectedMissionId(value)}
            placeholder={t(
              'reveal.candidatesView.actionsBox.selectMissionToRemoveFrom',
            )}
          />
        )
      }
      submit={t('common.removeAlt')}
      cancel={t('common.cancel')}
      size='tiny'
      submitDisabled={!missionId && !selectedMissionId}
    />
  );
};

export default contextToProps(RemoveFromMissionModal);
