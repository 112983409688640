import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import _ from 'underscore';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useAddProfilesToOffer from '@/graphql/hooks/offers/useAddProfilesToOffer';
import ButtonAndDropdown from '@/components/Common/ButtonAndDropdown';
import Plus from '@/components/Reveal/Icons/Plus';
import GenericButton from '@/components/Common/GenericButton';

interface AddProfilesToJobButtonProps {
  profileIds: string[];
  openModal: () => void;
  onAddProfiles: () => void;
}

const AddProfilesToJobButton: FC<AddProfilesToJobButtonProps> = ({
  profileIds,
  openModal,
  onAddProfiles,
}) => {
  const { t } = useTranslation();
  const { offerId } = useParams<{ offerId: string }>();
  const { success, error } = useNotificationSystem();
  const [addProfilesToOffer] = useAddProfilesToOffer();

  const handleAddToJob = async () => {
    onAddProfiles();
    try {
      await addProfilesToOffer(profileIds, offerId);
      success(
        t('watchCollect.results.addSelectionToJobModal.success', { count: 1 }),
      );
      if (onAddProfiles) {
        onAddProfiles();
      }
    } catch (e) {
      error(
        t('watchCollect.results.addSelectionToJobModal.error', { count: 1 }),
      );
    }
  };

  return offerId ? (
    <ButtonAndDropdown
      title={
        <>
          <Plus />
          {t('watchCollect.results.profileCard.header.addToJobModal.thisJob')}
        </>
      }
      options={[
        {
          id: 'openModal',
          label: t(
            'watchCollect.results.profileCard.header.addToJobModal.otherJob',
          ),
        },
      ]}
      onClick={handleAddToJob}
      onSelect={(id) => {
        if (id === 'openModal') {
          openModal();
        }
      }}
      position='right'
      className='add-to-job-button'
    />
  ) : (
    <GenericButton onClick={openModal} disabled={_.isEmpty(profileIds)}>
      <i className='ri-add-line' />
      {t('watchCollect.results.profileCard.header.addToJobModal.anyJob')}
    </GenericButton>
  );
};

export default AddProfilesToJobButton;
