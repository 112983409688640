import _ from 'underscore';
import React from 'react';
import Papaparse from 'papaparse';

import logAction from '@/common/logAction';
import { sentryCaptureException } from '@/common';

import { CSVLines, filterCSVData } from './helpers';

export type CSVLoader = {
  rows: CSVLines;
  reset: () => void;
  loadCSVFile: (file: File) => void;
};

/**
 * Load and parse a CSV file
 */
export default function useCSVLoader(): CSVLoader {
  const [rows, setRows] = React.useState<CSVLines>([]);
  // FIXME: excludedRows is not used
  /*   const [_excludedRows, setExcludedRows] = React.useState<CSVLines | null>(
    null,
  ); */

  const reset = () => {
    setRows([]);
    // setExcludedRows([]);
  };

  const loadCSVFile = (file: File) => {
    const reader = new FileReader();

    reader.onload = () => {
      // The file's text will be printed here
      let data = reader.result as string;
      if (!data) return;
      if (data.indexOf('"First Name,""') === 0) {
        const rows = data.split('\r\n');
        const mergedRows: string[] = [];
        let shouldMergeWithNext = false;
        _.each(rows, (row) => {
          if (shouldMergeWithNext) {
            mergedRows[mergedRows.length - 1] += row;
          } else {
            mergedRows.push(row);
          }
          shouldMergeWithNext = !(row.length >= 3 && row.slice(-3) === '"""');
        });
        const fixedRows = _.map(mergedRows, (row) => {
          const trimmed = row
            .trim()
            .slice(1)
            .slice(0, -1);
          const i = trimmed.indexOf(',""');
          return `""${trimmed.slice(0, i)}"",""${trimmed.slice(i + 3)}`.replace(
            /""/g,
            '"',
          );
        });
        data = fixedRows.join('\n');
      }

      const trimRegex = /^\s+|\s+$/g;
      const result = data.replace(trimRegex, '');
      const config = {
        complete: (results) => {
          const {
            filteredResults,
            // excludedResults: excluded
          } = filterCSVData(results?.data);
          setRows(filteredResults);
          // setExcludedRows(excluded);
          logAction({
            type: 'import-reveal-profiles-load-csv',
            info: {
              firstRow: (filteredResults || [])[0],
              app: 'reveal',
            },
          });
        },
        error: (err: Error) => {
          try {
            sentryCaptureException({
              error: err,
              tags: { feature: 'import-reveal-profiles-load-csv' },
            });
          } catch (e) {
            console.error('Error while sending error to Sentry', e, err);
          }
        },
        skipEmptyLines: 'greedy',
      } as Papaparse.ParseConfig;
      Papaparse.parse(result, config);
    };
    reader.readAsText(file);
  };
  return {
    rows,
    reset,
    loadCSVFile,
  };
}
