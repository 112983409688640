import React, { useMemo } from 'react';
import _ from 'underscore';
import { Button, Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ATS_WITH_JOB_SPECIFIC_STAGES, BOONDMANAGER } from '@/common/reveal';
import useClientId from '@/hooks/router/useClientId';
import useClient from '@/graphql/hooks/clients/useClient';
import { lowerCaseAndUnaccent } from '@/common';
import {
  INTEGRATION_WITHOUT_JOBS,
  INTEGRATION_WITHOUT_LINKED_USERS,
  useMissionWorkflowsContext,
} from '../context/useMissionWorkflowsContext';

const ATSConnector: React.FC = () => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const {
    connector,
    styles,
    atsJobOptions,
    atsUsersOptions,
    integrationTitle,
    linkedATSJobId,
    linkedATSUserId,
    onLinkedJobChange,
    onLinkedUserChange,
  } = useMissionWorkflowsContext();

  const { data: clientData } = useClient(clientId);

  const onJobChange = (event: any, data: any) => {
    onLinkedJobChange(data.value);
  };
  const onUserChange = (event: any, data: any) => {
    onLinkedUserChange(data.value);
  };

  const integrationHasJobs = !_.contains(
    INTEGRATION_WITHOUT_JOBS,
    connector?.type,
  );

  const integrationHasLinkedUser = !_.contains(
    INTEGRATION_WITHOUT_LINKED_USERS,
    connector?.type,
  );

  const shouldDisplayBanner = useMemo(() => {
    if (
      [...ATS_WITH_JOB_SPECIFIC_STAGES, BOONDMANAGER].includes(connector?.type)
    ) {
      return false;
    }
    if (!_.isEmpty(clientData?.client?.defaultCrmWorkflows)) {
      return false;
    }
    return true;
  }, [clientData, connector]);

  return (
    <>
      {shouldDisplayBanner && (
        <div className={styles.banner}>
          <div>
            <h2 className={styles.bannerTitle}>
              {t('reveal.workflows.missionWorkflows.banner.title')}
            </h2>
            <p className={styles.bannerSubtitle}>
              {t('reveal.workflows.missionWorkflows.banner.subtitle')}
            </p>
          </div>
          <div>
            <Link to={`/client/${clientId}/crm-integrations/${connector.type}`}>
              <Button primary className={styles.bannerButton}>
                {t('reveal.workflows.missionWorkflows.banner.button')}
              </Button>
            </Link>
          </div>
        </div>
      )}

      <span className={styles.title}>
        {t('reveal.workflows.missionWorkflows.rules')}
      </span>
      <span className={styles.description}>
        {!integrationHasJobs &&
          t('reveal.workflows.linkWithoutJob', {
            integrationTitle,
          })}
        {!integrationHasLinkedUser &&
          t('reveal.workflows.linkWithoutOwner', {
            integrationTitle,
          })}
        {integrationHasJobs &&
          integrationHasLinkedUser &&
          t('reveal.workflows.link', {
            integrationTitle,
          })}
      </span>
      <div className={styles.atsJobAndOwner}>
        {integrationHasJobs && (
          <Dropdown
            className={styles.dropdown}
            placeholder={t('reveal.workflows.selectJob')}
            style={{
              width: integrationHasLinkedUser ? '100%' : '50%',
            }}
            fluid
            search={(options, query) => {
              return _.filter(
                options,
                (option) =>
                  lowerCaseAndUnaccent(option.text as string).indexOf(
                    lowerCaseAndUnaccent(query),
                  ) >= 0,
              );
            }}
            selection
            options={atsJobOptions}
            onChange={onJobChange}
            defaultValue={linkedATSJobId ?? ''}
            clearable
            selectOnBlur={false}
          />
        )}

        {integrationHasLinkedUser && (
          <Dropdown
            className={styles.dropdown}
            style={{
              width: integrationHasJobs ? '100%' : '50%',
            }}
            placeholder={t('reveal.workflows.selectOwner')}
            fluid
            search={(options, query) => {
              return _.filter(
                options,
                (option) =>
                  lowerCaseAndUnaccent(option.text as string).indexOf(
                    lowerCaseAndUnaccent(query),
                  ) >= 0,
              );
            }}
            selection
            options={atsUsersOptions}
            onChange={onUserChange}
            defaultValue={linkedATSUserId ?? ''}
            clearable
            selectOnBlur={false}
          />
        )}
      </div>
    </>
  );
};

export default ATSConnector;
