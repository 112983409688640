import React from 'react';
import { useFormContext } from 'react-hook-form';
import { getTranslatedText } from '@/common';
import { CustomTextArea } from '@/containers/Parameters/OfferCriteria/CustomFormItems';


const RegisteredCustomTextArea = ({ name, ...props }) => {
  const {
    register,
    setValue,
    watch,
  } = useFormContext();


  const value = watch(name);
  const currentValue = typeof value === 'string'
    ? value
    : getTranslatedText(value);

  return (
    <CustomTextArea
      {...register(name)}
      {...props}
      currentValue={currentValue}
      onChange={({ value }) => setValue(name, value?.value)}
    />
  );
};

export default RegisteredCustomTextArea;
