import React from 'react';
import { Table } from 'semantic-ui-react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './LinkedinAccountsTable.module.less';

interface LinkedinAccountsTableHeaderProps {
  withEdit: boolean;
}

const LinkedinAccountsTableHeader: React.FC<LinkedinAccountsTableHeaderProps> = ({
  withEdit,
}) => {
  const { t } = useTranslation();
  return (
    <Table.Header className={styles.linkedinAccountsTableHeader}>
      <Table.Row>
        <Table.HeaderCell
          className={styles.linkedinAccountsTableHeaderItem}
          width={4}
        >
          {t('settings.linkedinAccounts.owner')}
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.linkedinAccountsTableHeaderItem}
          width={4}
        >
          {t('settings.linkedinAccounts.email')}
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.linkedinAccountsTableHeaderItem}
          width={3}
        >
          {t('settings.linkedinAccounts.name')}
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.linkedinAccountsTableHeaderItem}
          width={4}
        >
          {t('settings.linkedinAccounts.type')}
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.linkedinAccountsTableHeaderItem}
          width={3}
        >
          {t('settings.linkedinAccounts.synchronizations')}
        </Table.HeaderCell>
        {withEdit && (
          <Table.HeaderCell
            className={classnames(
              styles.linkedinAccountsTableHeaderItem,
              styles.actionTableHeaderItem,
            )}
            width={2}
          />
        )}
      </Table.Row>
    </Table.Header>
  );
};

export default LinkedinAccountsTableHeader;
