import { useState, useEffect } from 'react';
import { StrictInputProps, StrictTextAreaProps } from 'semantic-ui-react';
import useClientSSOSettings from '../../../graphql/hooks/clients/singleSignOn/useClientSSOSettings';
import useUpdateClientSSOSettings from '../../../graphql/hooks/clients/singleSignOn/useUpdateClientSSOSettings';
import useTimeout from '../../../hooks/common/useTimeout';

const JUST_SAVED_TIMEOUT_MS = 5000;

const defaultIdentityProvider = {
  certificates: [''],
  ssoServiceURL: '',
  entityId: '',
};

const useSSOSettings = (clientId: string) => {
  const { data, loading } = useClientSSOSettings(clientId);
  const [
    updateSSOSettings,
    { loading: submitLoading, error },
  ] = useUpdateClientSSOSettings();

  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [ssoActive, setSsoActive] = useState<boolean>(false);
  const [certificate, setCertificate] = useState<string>('');
  const [entityId, setEntityId] = useState<string>('');
  const [ssoServiceURL, setSsoServiceUrl] = useState<string>('');

  const [justSaved, setJustSaved] = useState(false);

  useTimeout(
    () => {
      setJustSaved(false);
    },
    justSaved ? JUST_SAVED_TIMEOUT_MS : null,
  );

  useEffect(() => {
    if (!loading && data) {
      // init form state
      const { isActive, identityProvider } = data.client.singleSignOn ?? {
        isActive: false,
      };

      const {
        certificates: initialCertificates,
        entityId: initialEntityId,
        ssoServiceURL: initialSsoServiceURL,
      } = identityProvider || defaultIdentityProvider;

      setSsoActive(isActive);
      setCertificate(initialCertificates[0]);
      setSsoServiceUrl(initialSsoServiceURL);
      setEntityId(initialEntityId);
    }
  }, [data, loading]);

  const makeFormDirty = () => {
    if (!formIsDirty) {
      setFormIsDirty(true);
    }
  };

  const makeFormClean = () => {
    if (formIsDirty) {
      setFormIsDirty(false);
    }
  };

  const onChangeSSOActive = () => {
    setSsoActive(!ssoActive);
    makeFormDirty();
  };

  const onChangeEntityId: StrictInputProps['onChange'] = (e, { value }) => {
    setEntityId(value);
    makeFormDirty();
  };

  const onChangeSsoServiceUrl: StrictInputProps['onChange'] = (
    e,
    { value },
  ) => {
    setSsoServiceUrl(value);
    makeFormDirty();
  };

  const onChangeCertificate: StrictTextAreaProps['onChange'] = (
    e,
    { value },
  ) => {
    setCertificate(value?.toString() || '');
    makeFormDirty();
  };

  const onSubmit = async () => {
    const input = ssoActive
      ? {
        isActive: true,
        identityProvider: {
          certificates: [certificate],
          ssoServiceURL,
          entityId,
        },
      }
      : { isActive: false };

    await updateSSOSettings({
      variables: {
        input,
      },
    });
    makeFormClean();
  };

  const inputIsValid =
    !ssoActive || (ssoActive && certificate && entityId && ssoServiceURL);
  const canSubmit = formIsDirty && inputIsValid;

  return {
    disabled: loading || submitLoading,
    onChangeCertificate,
    certificate,
    onChangeSsoServiceUrl,
    ssoServiceURL,
    onChangeSSOActive,
    ssoActive,
    onChangeEntityId,
    entityId,
    canSubmit,
    onSubmit,
    submitLoading,
    justSaved,
    error: error?.message,
  };
};

export default useSSOSettings;
