import { QueryHookOptions, QueryResult, useLazyQuery } from '@apollo/client';
import { GET_AGGREGATED_CONTACTS_DETAILS } from '@/graphql/statistics';
import { CategorizationType } from '@/types/statistics/category';
import { GenericStatsFilterInput } from './common';

interface AggregatedContactsForCategories {
  categories: {
    categorizationId: CategorizationType;
    categoryId: string;
  }[];
  profileItems: {
    profileId: string;
  }[];
}

interface GetAggregatedContactsDetailsData {
  client: {
    id: string;
    statistics: {
      aggregatedContacts: AggregatedContactsForCategories[];
    };
  };
}

interface GetAggregatedContactsDetailsVariables {
  clientId: string;
  categorizations: CategorizationType[];
  categoriesFilter?: {
    categorizationId: CategorizationType;
    categoryId: GenericStatsFilterInput;
  }[];
}

interface UseAggregatedContactsDetailsInput {
  queryOptions?: QueryHookOptions<
    GetAggregatedContactsDetailsData,
    GetAggregatedContactsDetailsVariables
  >;
}

type UseAggregatedContactsDetailsResult = [
  (variables: GetAggregatedContactsDetailsVariables) => void,
  QueryResult<
    GetAggregatedContactsDetailsData,
    GetAggregatedContactsDetailsVariables
  > & {
    aggregatedContacts: AggregatedContactsForCategories[] | undefined;
  },
];

const useAggregatedContactsDetails = ({
  queryOptions = {},
}: UseAggregatedContactsDetailsInput = {}): UseAggregatedContactsDetailsResult => {
  const [fetchDetails, query] = useLazyQuery<
    GetAggregatedContactsDetailsData,
    GetAggregatedContactsDetailsVariables
  >(GET_AGGREGATED_CONTACTS_DETAILS, {
    ...(queryOptions || {}),
  });

  return [
    (variables) => fetchDetails({ variables }),
    {
      ...query,
      aggregatedContacts: query?.data?.client?.statistics?.aggregatedContacts,
    },
  ];
};

export default useAggregatedContactsDetails;
