import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { compose } from 'underscore';

import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import Thumbnail from '@/components/Thumbnail';
import sanitizeTimeLineItem from '@/common/sanitizeTimeLineItem';
import { withUpdateSearchPoolProfileComment } from '../../../../graphql/searchPoolProfile';
import EditComment from './EditComment';

import './Comment.css';

type CommentProps = {
  profile: any;
  timelineItem: any;
  commentHandlers: {
    delete: (value: unknown) => void;
    update: (value: unknown) => void;
    add: (value: unknown) => void;
  };
  updateSearchPoolProfileComment: (value: object) => void;
};

const Comment: React.FC<CommentProps> = ({
  profile,
  timelineItem,
  commentHandlers,
  updateSearchPoolProfileComment,
}) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const commentText = timelineItem?.action?.comment?.text || '';
  const { date, author } = timelineItem || {};
  const { firstname, lastname } = author || {};
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleDeleteClick = ({ date }: any) => {
    commentHandlers.delete({ date });
  };

  const updateComment = async (newComment: string) => {
    if (!profile || !date || !newComment) {
      return;
    }

    if (profile?.__typename === 'Profile') {
      await commentHandlers.update({
        id: profile.id,
        date, // keep same date as before
        comment: newComment,
      });
    }

    if (profile.__typename === 'RevealProfile') {
      await updateSearchPoolProfileComment({
        variables: {
          searchPoolId: 'reveal',
          id: profile.id,
          comment: newComment,
          date, // keep same date as before
        },
      });
      publishSubscriptionEvent('onProfileTimelineUpdate', { id: profile.id });
    }

    setEditMode(false);
  };

  return (
    <div className='timeline-card-mail-wrapper'>
      <div className='content'>
        <div className='header'>
          <Thumbnail
            link={author?.photoLink}
            firstname={author?.firstname}
            lastname={author?.lastname}
            email=''
          />

          <div className='author'>
            <div className='date'>
              {date && t('common.formattedDate', { date: new Date(+date) })}
            </div>
            <div className='author-name'>{`${firstname} ${lastname}`}</div>
          </div>

          <div className='actions-container'>
            {!editMode && (
              <div className='comment-actions'>
                <Icon
                  className='hsSidebarLink hsTimelineComment-pencil'
                  name='pencil'
                  size='small'
                  onClick={handleEditClick}
                />
                <Icon
                  className='hsSidebarLink hsTimelineComment-trash'
                  name='trash alternate outline'
                  size='small'
                  onClick={() => handleDeleteClick({ date })}
                />
              </div>
            )}
          </div>
        </div>
        <div className='card-content'>
          {editMode ? (
            <EditComment initialValue={commentText} onSubmit={updateComment} />
          ) : (
            <CommentBody content={commentText} />
          )}
        </div>
      </div>
    </div>
  );
};

export const CommentBody = React.memo(({ content }: { content: any }) => {
  return (
    <div
      className='body'
      dangerouslySetInnerHTML={{ __html: sanitizeTimeLineItem(content) }}
    />
  );
});

export default compose(withUpdateSearchPoolProfileComment)(Comment);
