import moment from 'moment';
import { reduce } from 'underscore';
import { propertyFilterHasError } from './propertyFilterHasError';
import { PropertiesType, PropertyFilter } from './types';

export const stateToJSONParams = (
  filters: PropertyFilter[],
  i18n: { resolvedLanguage: string },
) => {
  const { resolvedLanguage } = i18n;
  const lang = resolvedLanguage ? resolvedLanguage.split('-')[0] : undefined;
  moment.locale(lang);

  return reduce(
    filters,
    (filterRequests: any[], filter) => {
      if (propertyFilterHasError(filter)) return filterRequests;

      const value = getValue(filter);
      if (!filter.operator) return filterRequests;

      if (filter.type === 'contact') {
        const request = filter.operator.map((op) => ({
          id:
            op === 'any-filled'
              ? 'hasAnySources'
              : op === 'all-filled'
              ? 'hasSources'
              : op === 'any-missing'
              ? 'hasAnyMissingSources'
              : 'hasNoneOfSources',
          params: {
            sourceTypes: filter?.value?.[op] ?? [],
          },
        }));
        return filterRequests.concat(request);
      }

      if (filter.type === 'diversity') {
        const request = filter.operator.map((op) => ({
          id:
            op === 'firstname_is_likely'
              ? 'firstnameIsLikely'
              : 'hasNoneOfSources',
          params: {
            value: filter?.value?.[op] ?? [],
          },
        }));
        return filterRequests.concat(request);
      }

      if (filter.type === 'keywords') {
        const request = filter.operator.map((operatorItem) => ({
          id: operatorItem,
          params: {
            keywords: filter?.value?.[operatorItem] ?? [],
            emplacements: filter?.emplacements || [],
          },
        }));
        return filterRequests.concat(request);
      }

      const customParams: {
        type: PropertiesType;
        policy: 'equal' | 'isNull' | 'contains' | 'intersect' | 'exists';
        value?: string | number | string[];
        min?: string | number | string[];
        max?: string | number | string[];
      } = {
        type: filter.type,
        policy: 'equal',
        value: undefined,
      };

      switch (filter.operator) {
        case 'relative_is':
        case 'is':
        case 'is_not':
          customParams.policy = 'equal';
          customParams.value = value;
          break;
        case 'start_with':
          customParams.policy = 'contains';
          customParams.value = `^${value}`;
          break;
        case 'end_with':
          customParams.policy = 'contains';
          customParams.value = `${value}$`;
          break;
        case 'include':
        case 'not_include':
          customParams.policy = 'contains';
          customParams.value = value;
          break;
        case 'not_has_value':
        case 'has_value':
          customParams.policy = 'exists';
          customParams.value = value;
          break;
        case 'relative_greater':
        case 'greater':
          customParams.min = value;
          break;
        case 'relative_lesser':
        case 'lesser':
          customParams.max = value;
          break;
        case 'is_in':
        case 'is_not_in':
          customParams.policy = 'intersect';
          customParams.value = value;
          break;
        default:
          break;
      }

      const filterRequest = filter.operator.includes('not')
        ? {
            id: '#not',
            params: {
              son: {
                id: 'customField',
                params: {
                  clientFilterKey: filter.id,
                  ...customParams,
                },
              },
            },
          }
        : {
            id: 'customField',
            params: {
              clientFilterKey: filter.id,
              ...customParams,
            },
          };

      filterRequests.push(filterRequest);
      return filterRequests;
    },
    [],
  );
};

const getValue = (filter: PropertyFilter) => {
  switch (filter.type) {
    case 'enum':
    case 'text':
    case 'inline-text':
      return filter.value;
    case 'integer':
    case 'float':
      return Number.parseFloat(filter.value ?? '');
    case 'day':
      if (filter.value) {
        const date = moment(filter.value);
        return date.format();
      }
      return undefined;
    default:
      return undefined;
  }
};

export default stateToJSONParams;
