import gql from 'graphql-tag';
import Task from './Task';

export default gql`
  fragment Project on Project {
    id
    jobId
    type
    isArchived
    isAutomated
    title {
      default
    }
    owner {
      email
      firstname
      lastname
    }
    queues {
      id
      tasks {
        ...Task
      }
    }
  }
  ${Task}
`;
