import React from 'react';

import useClientId from '@/hooks/router/useClientId';

import {
  HiresweetLibProvider,
  InfoBlock,
  InfoBlockItem,
  ProfileContainer,
  useProfileData,
  createSweetProfileComponent,
  ProfileFirstname,
  ProfileLastname,
  ProfileCustomFieldDate,
  ProfileTabs,
  ProfileTabContainer,
  ProfileLocation,
  ProfileGenericSourceCandidateId,
  ProfileShareableLink,
} from '@hiresweet/hiresweet-lib';

/* const Delayed = () => {
  const [enabled, setEnable] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setEnable(true);
    }, 1000);
  }, []);

  if (!enabled) {
    return <span>Waiting</span>;
  }
  return null; // <ProfileFullname />;
}; */

const SlashSourceId = createSweetProfileComponent(() => {
  const { loading, data: slashProfileId } = useProfileData(ProfileGenericSourceCandidateId);

  if (loading) {
    return null;
  }

  return `${slashProfileId}`.toUpperCase();

}, [ProfileGenericSourceCandidateId]);


const ProfileInitials = createSweetProfileComponent(() => {
  const { loading: loadingFirstname, data: firstname } =
    useProfileData(ProfileFirstname);
  const { loading: loadingLastname, data: lastname } =
    useProfileData(ProfileLastname);

  return loadingFirstname || loadingLastname
    ? 'Loading'
    : `${(firstname || '').slice(0, 1)}. ${(lastname || '').slice(0, 1)}`;
}, [ProfileFirstname, ProfileLastname]);

const Playground1 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30 }}>
      <h1>P1 - Profile tabs</h1>
      <HiresweetLibProvider theme={theme} clientId={clientId}>
        <ProfileContainer profileId="julien-deoux-1BI1TI">
          <table>
            <tbody>
              <tr>
                <td>ShareableLink:</td>{' '}
                <td>
                  <ProfileShareableLink />
                </td>
              </tr>
              <tr>
                <td>Firstname:</td>{' '}
                <td>
                  <ProfileFirstname />
                </td>
              </tr>
              <tr>
                <td>Lastname:</td> <td />
              </tr>
              <tr>
                <td>Initials:</td>{' '}
                <td>
                  <ProfileInitials />
                </td>
              </tr>
              <tr>
                <td>Date call</td>
                <td>
                  <ProfileCustomFieldDate clientCustomFieldId='datecall' />
                </td>
              </tr>
               <tr>
                <td>Slash Id</td>
                <td>
                  <SlashSourceId />
                </td>
              </tr>
            </tbody>
            <br />
            <br />

            <div>
              <InfoBlock title='Trop cool'>
                <InfoBlockItem label='Firstname'>
                  <ProfileFirstname />
                </InfoBlockItem>
                <InfoBlockItem label='Lastname'>
                  <span>OK</span>
                </InfoBlockItem>
              </InfoBlock>
            </div>

            <ProfileTabs defaultTabId='info'>
              <ProfileTabContainer tabId='info' tabLabel='Info'>
                <h1>Tab Info</h1>
              </ProfileTabContainer>
              <ProfileTabContainer tabId='info2' tabLabel='Info2' prefetch>
                <h1>Tab Info2</h1>
                Location: <ProfileLocation />
              </ProfileTabContainer>
              <ProfileTabContainer tabId='more' tabLabel='More' >
                <h1>Tab More</h1>
                <ProfileFirstname />
              </ProfileTabContainer>
              <ProfileTabContainer
                tabId='matching'
                tabLabel='Matching'
                prefetch
              >
                <h1>Tab Matching</h1>
                <ProfileLastname />
              </ProfileTabContainer>
            </ProfileTabs>
          </table>
        </ProfileContainer>
      </HiresweetLibProvider>
    </div>
  );
};

export default Playground1;
