import React, { FC } from 'react';
import classNames from 'classnames';

import { PrefixTaskType } from '@/types/statistics/clientStatistics';
import RateCellContent from '../KPICell/RateCellContent/RateCellContent';

import styles from './PrefixStatisticsRow.module.less';

interface PrefixStatisticsRowProps {
  stats: Record<PrefixTaskType, number>;
  totalContacted: number;
  fetchDetails?: (taskType: PrefixTaskType) => void;
}

const PrefixStatisticsRow: FC<PrefixStatisticsRowProps> = ({
  stats,
  totalContacted,
  fetchDetails,
  children,
}) => (
  <div className={styles.row}>
    <div className={styles.descriptor}>{children}</div>
    <span className={styles.column} />
    <button
      type='button'
      onClick={fetchDetails ? () => fetchDetails('nbContacted') : undefined}
      disabled={fetchDetails === undefined}
      className={classNames(styles.column, styles.button)}
    >
      {stats.nbContacted || '-'}
    </button>
    <span className={styles.column} />
    <span className={styles.column} />
    <button
      type='button'
      onClick={fetchDetails ? () => fetchDetails('nbAnswered') : undefined}
      disabled={fetchDetails === undefined}
      className={classNames(styles.column, styles.button)}
    >
      <RateCellContent
        value={stats.nbAnswered}
        referenceValue={totalContacted}
        rate={stats.nbAnswered / (totalContacted || 1)}
      />
    </button>
    <button
      type='button'
      onClick={
        fetchDetails ? () => fetchDetails('nbPositiveAnswered') : undefined
      }
      disabled={fetchDetails === undefined}
      className={classNames(styles.column, styles.button)}
    >
      <RateCellContent
        value={stats.nbPositiveAnswered}
        referenceValue={totalContacted}
        rate={stats.nbPositiveAnswered / (totalContacted || 1)}
      />
    </button>
  </div>
);

export default PrefixStatisticsRow;
