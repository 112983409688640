import React from 'react';
import {
  HiresweetLibProvider,
  ProfileHeader,
  ProfileContainer,
} from '@hiresweet/hiresweet-lib';

import useClientId from '@/hooks/router/useClientId';

const Playground3 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30 }}>
      <h1>P3 - Profile Header</h1>
      <HiresweetLibProvider theme={theme} clientId={clientId}>
        <ProfileContainer profileId='jean-bombeur-45T0ZI'>
          <ProfileHeader />
        </ProfileContainer>
      </HiresweetLibProvider>
    </div>
  );
};

export default Playground3;
