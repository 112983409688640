import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './svg.module.less';

const Tools: FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    className={classNames(styles.svgIcon, className)}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.55327 0.180668C1.99796 0.0219904 2.48033 0.0019537 2.93665 0.123204C3.39297 0.244455 3.80176 0.501288 4.10904 0.859773C4.41631 1.21826 4.6076 1.66153 4.65763 2.13102C4.70767 2.60052 4.61409 3.07414 4.38927 3.48933L11.5286 10.6293L10.5859 11.572L3.44594 4.432C3.03065 4.65594 2.55727 4.74879 2.08816 4.69834C1.61905 4.64788 1.17624 4.45647 0.818078 4.14934C0.459916 3.84221 0.203214 3.43377 0.0818003 2.97785C-0.0396137 2.52193 -0.0200418 2.03991 0.137937 1.59533L1.62927 3.08667C1.72152 3.18218 1.83186 3.25836 1.95387 3.31077C2.07587 3.36318 2.20709 3.39076 2.33987 3.39192C2.47265 3.39307 2.60433 3.36777 2.72722 3.31749C2.85012 3.26721 2.96177 3.19296 3.05567 3.09906C3.14956 3.00517 3.22381 2.89352 3.27409 2.77062C3.32437 2.64773 3.34967 2.51605 3.34852 2.38327C3.34737 2.25049 3.31978 2.11927 3.26737 1.99726C3.21496 1.87526 3.13878 1.76491 3.04327 1.67267L1.5526 0.180001L1.55327 0.180668ZM8.4646 1.43667L10.5859 0.258001L11.5286 1.20067L10.3499 3.322L9.17127 3.558L7.75794 4.972L6.8146 4.02933L8.2286 2.61533L8.4646 1.43667ZM3.98594 6.858L4.9286 7.80067L1.39327 11.336C1.27307 11.4565 1.11131 11.5265 0.941157 11.5316C0.771003 11.5367 0.605346 11.4765 0.478147 11.3634C0.350949 11.2503 0.271846 11.0928 0.257056 10.9232C0.242266 10.7536 0.292909 10.5848 0.398604 10.4513L0.450604 10.3933L3.98594 6.858Z'
      fill='#A5ABC9'
    />
  </svg>
);

export default Tools;
