import {
  FetchResult,
  MutationHookOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback } from 'react';

import { Author } from '@/types/author';

const unmarkProfileForCuratedAutoSend = gql`
  mutation unmarkProfileForCuratedAutoSend($profileId: ID!) {
    unmarkProfileForCuratedAutoSend(profileId: $profileId) {
      id
      annotation {
        curatedAutoSend {
          value
          lastEditionDate
          author {
            firstname
            lastname
            email
          }
        }
      }
    }
  }
`;

type Data = {
  id: string;
  annotation: {
    curatedAutoSend: {
      value: boolean;
      lastEditionDate: string;
      author: Author;
    };
  };
};

type Variables = {
  profileId: string;
};

type Input = {
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

type Result = [
  (profileId: string) => Promise<FetchResult<Data>>,
  MutationResult<Data>,
];

function useUnmarkProfileForCuratedAutoSend({
  mutationOptions = {},
}: Input = {}): Result {
  const [mutation, result] = useMutation<Data, Variables>(
    unmarkProfileForCuratedAutoSend,
    mutationOptions,
  );

  const update = useCallback(
    (profileId: string) => mutation({ variables: { profileId } }),
    [mutation],
  );

  return [update, result];
}

export default useUnmarkProfileForCuratedAutoSend;
