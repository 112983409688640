import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';

class CustomInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.currentValue,
    };
  }

  handleChange = (ev) => {
    const { onChange, label } = this.props;
    const {value} = ev.target;
    this.setState({ value });
    onChange({ label, value: { value } });
  };

  render() {
    const { placeholderText } = this.props;
    const { value } = this.state;
    return (
      <Input
        className='custom-input'
        type='text'
        value={value}
        placeholder={placeholderText || ''}
        onChange={this.handleChange}
      />
    );
  }
}

export default CustomInput;
