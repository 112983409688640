import React, { useCallback, useState } from 'react';
import logAction from '@/common/logAction';
import MinimizedViewContext from '../../context/MinimizedView/MinimizedViewContext';

interface PluginMinimizedViewProviderProps {
  clientId: string;
}

const PluginMinimizedViewProvider: React.FC<PluginMinimizedViewProviderProps> = ({
  clientId,
  children,
}) => {
  const [isMinimized, setIsMinimized] = useState(false);

  const minimize = useCallback(() => {
    chrome.storage.local.set({ minimizedMode: true });
    setIsMinimized(true);
    logAction({
      type: 'reveal-plugin-minimize-view',
      info: {
        clientId,
      },
    });
  }, [setIsMinimized, clientId]);
  const maximize = useCallback(() => {
    chrome.storage.local.set({ minimizedMode: false });
    setIsMinimized(false);
    logAction({
      type: 'reveal-plugin-maximize-view',
      info: {
        clientId,
      },
    });
  }, [setIsMinimized, clientId]);

  return (
    <MinimizedViewContext.Provider value={{ isMinimized, minimize, maximize }}>
      {children}
    </MinimizedViewContext.Provider>
  );
};

export default PluginMinimizedViewProvider;
