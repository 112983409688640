import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const setPluginCalendarPermission = gql`
  mutation setPluginCalendarPermission($bool: Boolean!) {
    setPluginCalendarPermission(bool: $bool) {
      id
      permissions {
        pluginCalendar
      }
    }
  }
`;

export default graphql(setPluginCalendarPermission, {
  props: ({ mutate }) => ({
    setPluginCalendarPermission: ({ bool }) => mutate({ variables: { bool } }),
  }),
});
