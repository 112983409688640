import _ from 'underscore';
import {
  PipelineActionCountType,
  PipelineDailyEvent,
} from '@/types/statistics/pipelineActions';
import {
  AuthorDailyEvent,
  AuthorStatistics,
} from '@/types/statistics/authorStatistics';

export const filterPipelineDailyEventsByProjects = (
  missionIds: string[],
  events: PipelineDailyEvent[],
): PipelineDailyEvent[] =>
  _.isEmpty(missionIds)
    ? events
    : _.map(events, (event) => ({
        day: event.day,
        userStats: _.map(event.userStats, (userStatItem) => ({
          author: userStatItem.author,
          stats: _.filter(userStatItem.stats, (statItem) =>
            _.contains(missionIds, statItem.missionId),
          ),
        })),
      }));

export const getAuthorDailyEventsForCountType = (
  type: PipelineActionCountType,
  events: PipelineDailyEvent[],
): AuthorDailyEvent[] => {
  const result = [] as AuthorDailyEvent[];

  _.each(events, (pipelineEvent) => {
    const { day, userStats } = pipelineEvent;

    const authorStats = {} as Record<string, AuthorStatistics>;

    _.each(userStats, (userStatItem) => {
      const { author, stats } = userStatItem;

      _.each(stats, (statItem) => {
        const { type: itemType, count } = statItem;
        if (type === itemType) {
          authorStats[author.email] = authorStats[author.email] || {
            author,
            count: 0,
          };
          authorStats[author.email].count += count;
        }
      });
    });

    if (!_.isEmpty(authorStats)) {
      result.push({
        day,
        authorStats,
      });
    }
  });

  return result;
};
