import gql from 'graphql-tag';
import TimelineItem from './TimelineItem';

const ProfileTimeline = gql`
  fragment ProfileTimeline on Profile {
    id
    email
    resumeData {
      step
      firstname
      lastname
      photoLink
    }
    contactData {
      creationTimestamp
      defaultThreadId
      timeline {
        ...TimelineItem
      }
    }
  }
  ${TimelineItem}
`;

export default ProfileTimeline;
