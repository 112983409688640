import gql from 'graphql-tag';
import TranslatableText from './TranslatableText';

export default gql`
  fragment GreenhouseTag on GreenhouseTag {
    sourceTagId
    name {
      ...TranslatableText
    }
  }
  ${TranslatableText}
`;
