import gql from 'graphql-tag';

export default gql`
  fragment UserMarketplaceGlobalProfileDisplayPreferencesFragment on UserMarketplaceProfileDisplayPreferences {
    hideFullName
    hidePicture
    hideEducationSchoolNames
    hideEducationDates
    hideEducationDescriptions
  }
`;
