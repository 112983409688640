/* eslint-disable max-len */

import React, { FC } from 'react';

const Star: FC = () => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.54482 2.0014C7.72245 1.61061 8.27755 1.61061 8.45518 2.0014L10.0699 5.55386C10.1428 5.71424 10.2948 5.82443 10.4699 5.84389L13.9472 6.23048C14.3671 6.27716 14.5434 6.7914 14.2404 7.08593L11.6856 9.56952C11.5692 9.6827 11.515 9.84537 11.5403 10.0058L12.1588 13.9361C12.225 14.3565 11.7693 14.6621 11.4055 14.4413L8 12.375L4.59448 14.4413C4.23071 14.6621 3.77504 14.3565 3.84119 13.9361L4.45975 10.0058C4.48499 9.84537 4.43077 9.6827 4.31435 9.56952L1.75957 7.08593C1.4566 6.7914 1.6329 6.27716 2.05284 6.23048L5.53013 5.84389C5.70523 5.82443 5.85716 5.71424 5.93007 5.55386L7.54482 2.0014Z'
      stroke='currentColor'
      fill='white'
      strokeWidth='1.5'
    />
  </svg>
);

export default Star;
