import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import { Form } from 'semantic-ui-react';

import withClient from '../../hocs/clients/withClient';
import contextToProps from '../../hocs/contextToProps';
import withSendForgottenPasswordToken from '../../hocs/users/withSendForgottenPasswordToken';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import Layout from './Layout';

import './GenericSigninForm.css';

class ForgottenPasswordForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      email: '',
    };
  }

  onSubmit = () => {
    this.setState({ loading: true, error: false });
    return Promise.resolve()
      .then(() =>
        this.props.sendForgottenPasswordToken({
          email: this.state.email ? this.state.email.toLowerCase() : null,
        }),
      )
      .then(() => {
        this.props.onShowNotification({
          message: this.props.t('forgottenPassword.notification'),
          level: 'success',
        });
        this.setState({ loading: false, error: false });
      })
      .catch((e) => {
        Sentry.captureException(e);
        this.setState({ loading: false, error: true });
      });
  };

  onChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <Layout
        title={t('forgottenPassword.header')}
        subtitle={t('forgottenPassword.subheader')}
        // details={t('forgottenPassword.details')}
      >
        <Form
          className='generic-signin-form'
          error={this.state.error}
          loading={this.state.loading}
          onSubmit={this.onSubmit}
        >
          <FloatingLabelInput
            label={t('forgottenPassword.form.email')}
            name='email'
            value={this.state.email}
            onChange={this.onChange}
          />
          <Form.Field className='submit-field'>
            <button className='submit-button' type='submit'>
              {t('forgottenPassword.form.submit')}
            </button>
          </Form.Field>
          <div className='underlined-link'>
            <Link to='signin'>
              <span>{t('forgottenPassword.previousPage')}</span>
            </Link>
          </div>
        </Form>
      </Layout>
    );
  }
}

export default compose(
  contextToProps,
  withClient,
  withSendForgottenPasswordToken,
  withTranslation('translations'),
)(ForgottenPasswordForm);
