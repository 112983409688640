import _ from 'underscore';
import React, { Component } from 'react';
import { getOptimizedSortedProfiles } from '@/containers/Profile/ProfileNavigation';

/**
 * WARNING: the component wrapped with this HOC must be passed mandatory props
 */
const withNextProfileId = (WrappedComponent) => {
  class ComposedComponent extends Component {
    getNextProfileId = (options = {}) => {
      const { forbiddenProfileIds } = options;
      const {
        profiles,
        error,
        profileId,
        isSearchResultProfile,
        stepId,
        subStepId,
        shouldSplitPending,
      } = this.props;

      if (error) {
        return null;
      }
      if (isSearchResultProfile) {
        return null;
      }

      /*
      console.log('ask nextProfileId', {
        profiles,
        error,
        profileId,
        isSearchResultProfile,
        stepId,        
      });
      console.log('with options', options);
      */

      const forbiddenProfilesMap = _.object(
        _.map(forbiddenProfileIds, (id) => [id, true]),
      );
      // console.log('forbiddenProfilesMap', forbiddenProfilesMap);
      const profilesList = _.isArray(profiles) ? profiles : [];
      const profilesInCurrentStep = getOptimizedSortedProfiles({
        stepId,
        profiles: _.filter(
          profilesList,
          (profile) =>
            profile?.resumeData?.step === stepId &&
            (!shouldSplitPending ||
              stepId !== 'pending' ||
              !_.contains(['pending-main', 'pending-extended'], subStepId) ||
              profile?.resumeData?.subStep === subStepId) &&
            !forbiddenProfilesMap[(profile || {}).id],
        ),
      });
      // console.log('profilesInCurrentStep', profilesInCurrentStep);
      const index = _.findIndex(profilesInCurrentStep, { id: profileId });
      const previousProfile =
        index >= 0 && index - 1 >= 0
          ? profilesInCurrentStep[index - 1].id
          : null;
      const nextProfileId =
        index >= 0 && index + 1 < profilesInCurrentStep.length
          ? profilesInCurrentStep[index + 1].id
          : previousProfile;

      // console.log('returns ' + nextProfileId);

      return nextProfileId;
    };

    render() {
      const { error } = this.props;

      if (error) {
        return null;
      }
      return (
        <WrappedComponent
          {...this.props}
          getNextProfileId={this.getNextProfileId}
        />
      );
    }
  }
  return ComposedComponent;
};

export default withNextProfileId;
