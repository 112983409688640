import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@/assets/icons';
import styles from './SequenceDropdownEmptyState.module.less';

interface SequenceDropdownEmptyStateProps {
  clientId: string;
}

const SequenceDropdownEmptyState: React.FC<SequenceDropdownEmptyStateProps> = ({
  clientId,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <img
        src='/images/placeholders/sequencesEmptyState.svg'
        alt='Empty sequences'
      />
      <div className={styles.descriptionContainer}>
        <p className={styles.title}>{t('sequences.emptyState.title')}</p>
        <p className={styles.description}>
          {t('sequences.emptyState.innerContent')}
        </p>
        <Link
          className={styles.ctaContainer}
          to={`/client/${clientId}/reveal/sequences/new-sequence-create`}
          target='_blank'
          rel='noopener noreferrer'
        >
          <PlusIcon />
          {t('sequences.emptyState.cta')}
        </Link>
      </div>
    </div>
  );
};

export default SequenceDropdownEmptyState;
