import gql from 'graphql-tag';
import {
  FetchResult,
  MutationResult,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';
import _ from 'underscore';

import SequenceFragment from '@/graphql/fragments/Sequence';
import { Sequence } from '@/types/sequence';
import { CLIENT_SEQUENCES } from '@/containers/Parameters/Sequences/queries';
import useClientId from '@/hooks/router/useClientId';
import { ClientSequencesData } from './useClientSequences';

const ARCHIVE_SEQUENCE = gql`
  mutation archiveSequence($input: ArchiveSequenceInput!) {
    sequence {
      archive(input: $input) {
        sequence {
          ...Sequence
        }
      }
    }
  }
  ${SequenceFragment}
`;

interface ArchiveSequenceData {
  sequence: {
    archive: {
      sequence: Sequence;
    };
  };
}

interface ArchiveSequenceVariables {
  input: {
    id: string;
  };
}

interface UseArchiveSequenceInput {
  mutationOptions?: MutationHookOptions<
    ArchiveSequenceData,
    ArchiveSequenceVariables
  >;
}

type UseArchiveSequenceResult = [
  (id: string) => Promise<FetchResult<ArchiveSequenceData>>,
  MutationResult<ArchiveSequenceData>,
];

const useArchiveSequence = ({
  mutationOptions = {},
}: UseArchiveSequenceInput = {}): UseArchiveSequenceResult => {
  const clientId = useClientId();
  const [mutation, result] = useMutation<
    ArchiveSequenceData,
    ArchiveSequenceVariables
  >(ARCHIVE_SEQUENCE, { ...mutationOptions });

  const queryToUpdate = {
    query: CLIENT_SEQUENCES,
    variables: { clientId, filters: { activeOnly: true } },
  };

  return [
    (id) =>
      mutation({
        variables: { input: { id } },
        update: (cache, { data }) => {
          if (!data) return;

          const oldData = cache.readQuery<ClientSequencesData>(queryToUpdate);

          if (!oldData) return;

          const newData = {
            client: {
              ...oldData.client,
              sequences: _.reject(
                oldData?.client?.sequences || [],
                (sequence) => sequence.id === id,
              ),
            },
          };

          cache.writeQuery({
            ...queryToUpdate,
            data: newData,
          });
        },
      }),
    result,
  ];
};

export default useArchiveSequence;
