import React, { FC } from 'react';

const ExternalLink: FC = () => (
  <svg
    width='.94em'
    height='1em'
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6 2.5H4C2.89543 2.5 2 3.39543 2 4.5V12.5C2 13.6046 2.89543 14.5 4 14.5H12C13.1046 14.5 14 13.6046 14 12.5V10.5M7 9.5L14 2.5M14 2.5V6.5M14 2.5H10'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='transparent'
    />
  </svg>
);

export default ExternalLink;
