import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useUpdateUserSettings from '@/graphql/hooks/users/useUpdateUserSettings';
import useClientId from '@/hooks/router/useClientId';

import OnboardingModal from '../modals/OnboardingModal';
import MarketplaceBulletsStep from '../modals/OnboardingModal/steps/MarketplaceBulletsStep';
import MailStep from '../modals/OnboardingModal/steps/MailStep';
import StartStep from '../modals/OnboardingModal/steps/StartStep';

const MarketplaceOnboardingModal: FC = () => {
  const clientId = useClientId();
  const { t } = useTranslation();

  const [updateUserSettings] = useUpdateUserSettings();

  return (
    <OnboardingModal
      steps={[MarketplaceBulletsStep, MailStep, StartStep]}
      close={() => {
        updateUserSettings({
          variables: { clientId, input: { firstLogin: false } },
        });
      }}
      submitCta={t('onboarding.footer.submit')}
    />
  );
};

export default MarketplaceOnboardingModal;
