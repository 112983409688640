import React from 'react';
import { Loader } from 'semantic-ui-react';
import _, { compose } from 'underscore';
import { Switch, Route, withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { PrimaryCobalt60 } from '@/less/colors';
import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import {
  greenhouseLogo,
  recruiteeLogo,
  workableLogo,
  leverLogo,
  ashbyLogo,
  teamtailorLogo,
  smartrecruitersLogo,
  welcomekitLogo,
} from './logos';
import IntegrationLink from './components/IntegrationLink';
import SmartrecruitersIntegration from './SmartrecruitersIntegration';
import GreenhouseIntegration from './GreenhouseIntegration';
import AshbyIntegration from './AshbyIntegration';
import RecruiteeIntegration from './RecruiteeIntegration';
import TeamtailorIntegration from './TeamtailorIntegration';
import WelcomekitIntegration from './WelcomekitIntegration';
import WorkableIntegration from './WorkableIntegration';
import LeverIntegration from './LeverIntegration';
import SettingsLayout from '../../SettingsLayout';
import withClientOffers from '../../../hocs/offers/withClientOffers';
import withClientIntegrations from '../../../hocs/integrations/withClientIntegrations';

import './Integrations.css';

function ClientIntegrations(props) {
  const {
    t,
    clientId,
    integrations,
    offers,
    integrationsLoading,
    integrationsError,
    match,
  } = props;
  const { applicationMode, updateApplicationMode } = useNavApplicationMode();
  const permissions = useClientPermissions(clientId).data?.client?.permissions;
  if (applicationMode !== 'classic') updateApplicationMode('classic');

  if (integrationsLoading || integrationsError || !integrations) {
    return (
      <SettingsLayout
        currentPage='integrations'
        clientId={clientId}
        className='integrations-view settings-container'
      >
        <div className='menu-integrations settings'>
          <div className='settings-header'>
            <h1>{t('integrations.header')}</h1>
          </div>
          <div className='loader-container'>
            <Loader active inline='center' size='large' />
          </div>
        </div>
      </SettingsLayout>
    );
  }

  const integrationFromType = {};
  _.each(integrations, (integration) => {
    integrationFromType[integration.type] = integration;
  });
  return (
    <SettingsLayout
      currentPage='integrations'
      clientId={clientId}
      className='integrations-view settings-container'
    >
      <div className='menu-integrations settings'>
        <Switch>
          <Route exact path='/client/:clientId/integrations'>
            <>
              <div className='settings-header'>
                <h1>
                  {t('integrations.header')}{' '}
                  {permissions?.reveal &&
                    permissions?.watchCollect &&
                    '(Marketplace)'}
                </h1>
              </div>
              {permissions?.reveal && permissions?.watchCollect && (
                <>
                  <p style={{ color: PrimaryCobalt60 }}>
                    {t('integrations.subheader', { client: 'CRM' })}
                    <Link to={`/client/${clientId}/crm-integrations`}>
                      {' '}
                      {t('integrations.clickHere')}
                    </Link>
                    .
                  </p>
                </>
              )}
              <div className='integration-links-grid'>
                <IntegrationLink
                  title='Greenhouse'
                  logo={greenhouseLogo}
                  integration={integrationFromType.greenhouse}
                  redirectLink={`${match.url}/greenhouse`}
                />
                <br />
                <IntegrationLink
                  clientId={clientId}
                  title='Lever'
                  logo={leverLogo}
                  integration={integrationFromType.lever}
                  redirectLink={`${match.url}/lever`}
                />
                <br />
                <IntegrationLink
                  title='Recruitee'
                  logo={recruiteeLogo}
                  integration={integrationFromType.recruitee}
                  redirectLink={`${match.url}/recruitee`}
                />
                <br />
                <IntegrationLink
                  clientId={clientId}
                  title='SmartRecruiters'
                  logo={smartrecruitersLogo}
                  integration={integrationFromType.smartrecruiters}
                  redirectLink={`${match.url}/smartrecruiters`}
                  disabled
                />
                <br />
                <IntegrationLink
                  clientId={clientId}
                  title='Welcome Kit'
                  logo={welcomekitLogo}
                  integration={integrationFromType.welcomekit}
                  redirectLink={`${match.url}/welcomekit`}
                  disabled
                />
                <br />
                <IntegrationLink
                  clientId={clientId}
                  title='Workable'
                  logo={workableLogo}
                  integration={integrationFromType.workable}
                  redirectLink={`${match.url}/workable`}
                />
                <IntegrationLink
                  title='Ashby'
                  logo={ashbyLogo}
                  integration={integrationFromType.ashby}
                  redirectLink={`${match.url}/ashby`}
                />
                <br />
                <IntegrationLink
                  title='Teamtailor'
                  logo={teamtailorLogo}
                  integration={integrationFromType.teamtailor}
                  redirectLink={`${match.url}/teamtailor`}
                />
              </div>

            </>
          </Route>
          <Route
            path={`${match.path}/greenhouse`}
            render={(routeProps) => (
              <GreenhouseIntegration
                {...routeProps}
                clientId={clientId}
                integration={integrationFromType.greenhouse}
                offers={offers}
                permissions={permissions}
              />
            )}
          />
          <Route
            path={`${match.path}/recruitee`}
            render={(routeProps) => (
              <RecruiteeIntegration
                {...routeProps}
                clientId={clientId}
                integration={integrationFromType.recruitee}
                offers={offers}
                permissions={permissions}
              />
            )}
          />
          <Route
            path={`${match.path}/workable`}
            render={(routeProps) => (
              <WorkableIntegration
                {...routeProps}
                clientId={clientId}
                integration={integrationFromType.workable}
                offers={offers}
                permissions={permissions}
              />
            )}
          />
          <Route
            path={`${match.path}/lever`}
            render={(routeProps) => (
              <LeverIntegration
                {...routeProps}
                clientId={clientId}
                integration={integrationFromType.lever}
                offers={offers}
                permissions={permissions}
              />
            )}
          />
          <Route
            path={`${match.path}/smartrecruiters`}
            render={(routeProps) => (
              <SmartrecruitersIntegration
                {...routeProps}
                clientId={clientId}
                integration={integrationFromType.smartrecruiters}
                offers={offers}
                permissions={permissions}
              />
            )}
          />
          <Route
            path={`${match.path}/welcomekit`}
            render={(routeProps) => (
              <WelcomekitIntegration
                {...routeProps}
                clientId={clientId}
                integration={integrationFromType.welcomekit}
                offers={offers}
                permissions={permissions}
              />
            )}
          />
          <Route
            path={`${match.path}/ashby`}
            render={(routeProps) => (
              <AshbyIntegration
                {...routeProps}
                clientId={clientId}
                integration={integrationFromType.ashby}
                offers={offers}
                permissions={permissions}
              />
            )}
          />
          <Route
            path={`${match.path}/teamtailor`}
            render={(routeProps) => (
              <TeamtailorIntegration
                {...routeProps}
                clientId={clientId}
                integration={integrationFromType.teamtailor}
                offers={offers}
                permissions={permissions}
              />
            )}
          />
        </Switch>
      </div>
    </SettingsLayout>
  );
}

export default compose(
  withTranslation('translations'),
  withClientIntegrations,
  withClientOffers,
  withRouter,
)(ClientIntegrations);
