import React, { FC } from 'react';
import _ from 'underscore';
import { Switch, Route, Redirect, generatePath } from 'react-router-dom';

import MissionCategoryContext from '@/context/MissionCategoryContext';
import useUserFromJWToken from '@/graphql/hooks/users/useUserFromJWToken';
import { IRevealProject } from '@/graphql/fragments/RevealProject';

import JobsView from './JobsView';
import JobView from './JobsView/JobView';
import { TabId } from './revealComponents/ProjectTypesTabs';

type ProjectsRouterProps = {
  revealProject: IRevealProject;
};

const ProjectsRouter: FC<ProjectsRouterProps> = ({ revealProject }) => {
  const { data: userData } = useUserFromJWToken();

  return (
    <Switch>
      <Route
        exact
        path='/client/:clientId/reveal/projects'
        render={({ match }) => (
          <Redirect
            to={generatePath(
              '/client/:clientId/reveal/projects/:missionCategory',
              {
                ...match.params,
                missionCategory: 'recruiting',
              },
            )}
          />
        )}
      />
      <Route
        exact
        path='/client/:clientId/reveal/projects/:missionCategory'
        render={({ match }) => (
          <MissionCategoryContext.Provider value={match.params.missionCategory}>
            <JobsView
              searchPoolId='reveal'
              revealProject={revealProject}
              user={userData}
            />
          </MissionCategoryContext.Provider>
        )}
      />
      <Route
        path='/client/:clientId/reveal/projects/:missionCategory/:missionId/:tabId'
        render={({ match }) => (
          <MissionCategoryContext.Provider value={match.params.missionCategory}>
            <JobView
              clientId={match.params.clientId}
              searchPoolId='reveal'
              activeTab={match.params.tabId}
              jobId={match.params.missionId}
              revealProject={revealProject}
            />
          </MissionCategoryContext.Provider>
        )}
      />
      <Route
        path='/client/:clientId/reveal/projects/:missionCategory/:missionId'
        render={({ match }) => (
          <Redirect
            to={generatePath(
              '/client/:clientId/reveal/projects/:missionCategory/:missionId/:tabId',
              {
                ...match.params,
                tabId: TabId.PROFILES,
              },
            )}
          />
        )}
      />
    </Switch>
  );
};

export default ProjectsRouter;
