import React, { useState } from 'react';
import _ from 'underscore';
import { useQuery } from '@apollo/client';

import { GET_CONNECT_FORMS } from '@/graphql/connectForms';
import { ConnectForm } from '@/types/connectForm';
import styles from './ConnectFormsSettings.module.less';
import { useNavApplicationMode } from '../../../TopBar/Header/NavSwitch.hook';
import ConnectFormsTable from './ConnectFormsTable';
import ConnectFormEditorModal from './ConnectFormEditorModal';
import DeleteConnectFormModal from './DeleteConnectFormModal';

interface ConnectFormsSettingsProps {
  clientId: string;
  newFormModalOpen: boolean;
  setNewFormModalOpen: (value: boolean) => void;
}

const ConnectFormsSettings: React.FC<ConnectFormsSettingsProps> = ({
  clientId,
  newFormModalOpen,
  setNewFormModalOpen,
}) => {
  const [editModalOpenId, setEditModalOpenId] = useState<string | null>(null);
  const [deleteModalOpenId, setDeleteModalOpenId] = useState<string | null>(
    null,
  );
  const { applicationMode, updateApplicationMode } = useNavApplicationMode();

  const { data: connectFormsData } = useQuery(GET_CONNECT_FORMS, {
    variables: {
      clientId,
    },
  });

  if (applicationMode !== 'reveal') {
    updateApplicationMode('reveal');
  }

  const connectForms: ConnectForm[] = connectFormsData?.client?.connectForms;

  const onEditConnectForm = ({ connectFormId }: { connectFormId: string }) => {
    setEditModalOpenId(connectFormId);
  };

  const onDeleteConnectForm = ({
    connectFormId,
  }: {
    connectFormId: string;
  }) => {
    setDeleteModalOpenId(connectFormId);
  };

  const connectFormToEdit =
    (editModalOpenId && _.findWhere(connectForms, { id: editModalOpenId })) ||
    null;

  const connectFormToDelete = deleteModalOpenId
    ? _.findWhere(connectForms, { id: deleteModalOpenId })
    : null;

  return (
    <div className={styles.formSection}>
      <div className={styles.connectFormSettingsGiven}>
        <ConnectFormsTable
          connectForms={connectForms}
          onEditConnectForm={onEditConnectForm}
          withEdit
          onDeleteConnectForm={onDeleteConnectForm}
        />
      </div>

      {(newFormModalOpen || !!connectFormToEdit) && (
        <ConnectFormEditorModal
          open
          clientId={clientId}
          connectForm={connectFormToEdit}
          closeModal={() => {
            setEditModalOpenId(null);
            setNewFormModalOpen(false);
          }}
        />
      )}
      <DeleteConnectFormModal
        connectForm={connectFormToDelete}
        clientId={clientId}
        closeModal={() => setDeleteModalOpenId(null)}
        open={!!connectFormToDelete}
      />
    </div>
  );
};

export default ConnectFormsSettings;
