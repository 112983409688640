/* eslint-disable max-len */

import React, { FC } from 'react';

import styles from './svg.module.less';

const Help: FC = () => (
  <svg
    className={styles.svgIcon}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.99998 14.6666C4.31798 14.6666 1.33331 11.6819 1.33331 7.99992C1.33331 4.31792 4.31798 1.33325 7.99998 1.33325C11.682 1.33325 14.6666 4.31792 14.6666 7.99992C14.6666 11.6819 11.682 14.6666 7.99998 14.6666ZM7.99998 13.3333C9.41447 13.3333 10.771 12.7713 11.7712 11.7712C12.7714 10.771 13.3333 9.41441 13.3333 7.99992C13.3333 6.58543 12.7714 5.22888 11.7712 4.22868C10.771 3.22849 9.41447 2.66659 7.99998 2.66659C6.58549 2.66659 5.22894 3.22849 4.22874 4.22868C3.22855 5.22888 2.66665 6.58543 2.66665 7.99992C2.66665 9.41441 3.22855 10.771 4.22874 11.7712C5.22894 12.7713 6.58549 13.3333 7.99998 13.3333ZM7.33331 9.99992H8.66665V11.3333H7.33331V9.99992ZM8.66665 8.90325V9.33325H7.33331V8.33325C7.33331 8.15644 7.40355 7.98687 7.52858 7.86185C7.6536 7.73682 7.82317 7.66659 7.99998 7.66659C8.18937 7.66657 8.37485 7.61278 8.53486 7.51147C8.69487 7.41016 8.82282 7.2655 8.90383 7.09431C8.98483 6.92312 9.01556 6.73245 8.99243 6.54448C8.96931 6.35651 8.89328 6.17898 8.7732 6.03253C8.65312 5.88608 8.49392 5.77674 8.31413 5.71723C8.13433 5.65773 7.94134 5.6505 7.7576 5.69639C7.57386 5.74228 7.40692 5.83941 7.27622 5.97646C7.14552 6.11352 7.05643 6.28487 7.01931 6.47059L5.71131 6.20859C5.7924 5.80331 5.97965 5.42683 6.25393 5.11764C6.5282 4.80845 6.87966 4.57764 7.27237 4.4488C7.66509 4.31996 8.08497 4.29771 8.4891 4.38433C8.89323 4.47095 9.26711 4.66333 9.57253 4.9418C9.87794 5.22028 10.1039 5.57486 10.2274 5.96929C10.3509 6.36373 10.3674 6.78388 10.2752 7.18679C10.1831 7.5897 9.98562 7.96092 9.70299 8.26249C9.42037 8.56407 9.06274 8.78519 8.66665 8.90325Z'
      fill='currentColor'
    />
  </svg>
);

export default Help;
