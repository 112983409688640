import React from 'react';
import { useTranslation } from 'react-i18next';

import useFreeTrialConfiguration from '@/graphql/hooks/clients/useFreeTrialConfiguration';

import FreeTrialEndDateForm from './FreeTrialEndDateForm';
import FreeTrialUpgradeForm from './FreeTrialUpgradeForm';

import styles from './FreeTrialForm.module.less';

function FreeTrialForm() {
  const { t } = useTranslation();
  const { freeTrialConfiguration } = useFreeTrialConfiguration();
  return (
    <div className={styles.section}>
      <div className='settings settings-header'>
        <h1>{t('settings.freeTrial.title')}</h1>
      </div>
      {freeTrialConfiguration.upgraded && (
        <div className={styles.freeTrialMessage}>
          {t('settings.freeTrial.upgraded')}
        </div>
      )}
      {freeTrialConfiguration.endDate ? (
        <>
          <FreeTrialEndDateForm
            initialValue={new Date(freeTrialConfiguration.endDate)}
          />
          <FreeTrialUpgradeForm />
        </>
      ) : (
        <div className={styles.freeTrialMessage}>
          {t('settings.freeTrial.noConfig')}
        </div>
      )}
    </div>
  );
}

export default FreeTrialForm;
