import React, { useMemo } from 'react';
import _ from 'underscore';

import { getFullname } from '@/common/helpers/person';
import GenericSelect from '@/components/Common/GenericSelect';
import useCompanyTargetContacts from '@/graphql/hooks/searchPoolProfile/useCompanyTargetContacts';

import styles from './CompanyContactsDropdown.module.less';

interface CompanyContactsDropdownProps {
  label?: string;
  company: string;
  onValue: (v: string[]) => void;
  value: string[];
}

const CompanyContactsDropdown: React.FC<CompanyContactsDropdownProps> = ({
  label,
  company,
  onValue,
  value,
}) => {
  const { companyTargetContacts } = useCompanyTargetContacts({
    profileId: company,
  });

  const contactOptionsById = useMemo(() => {
    const result = {} as Record<string, { label: string; value: string }>;
    _.each(companyTargetContacts, ({ id, resumeData }) => {
      result[id] = {
        label: getFullname(resumeData),
        value: id,
      };
    });
    return result;
  }, [companyTargetContacts]);

  if (_.isEmpty(companyTargetContacts)) {
    return null;
  }

  const contactOptions = _.values(contactOptionsById);
  const selectedContacts = _.compact(
    _.map(value, (id) => contactOptionsById[id]),
  );

  return (
    <>
      {label && <div className={styles.label}>{label}</div>}
      <GenericSelect
        isMulti
        options={contactOptions}
        value={selectedContacts}
        onChange={(newValue) => {
          if (!newValue) {
            onValue([]);
          } else {
            onValue(_.pluck(newValue, 'value'));
          }
        }}
        maxMenuHeight={200}
      />
    </>
  );
};

export default CompanyContactsDropdown;
