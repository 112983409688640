import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useLockHistory } from '@/context/LockHistoryContext';
import GenericButton from '@/components/Common/GenericButton';

const CancelButton: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { unlockHistory } = useLockHistory();

  const handleClick = () => {
    unlockHistory();
    history.goBack();
  };

  return (
    <GenericButton size='big' primacy='secondary' onClick={handleClick}>
      {t('common.cancel')}
    </GenericButton>
  );
};

export default CancelButton;
