import _ from 'underscore';
import { useCallback, useMemo } from 'react';
import {
  gql,
  MutationHookOptions,
  QueryHookOptions,
  useMutation,
  useQuery,
} from '@apollo/client';

import useClientId from '@/hooks/router/useClientId';

import { GET_CLIENT_CONFIGURATION_PARAMS } from '../ClientConfigurationParams';
import { GET_USER_CONFIGURATION_PARAMS } from '../UserConfigurationParams';

const keyValueParamToObject = (param?: { key: string; value: string }[]) => {
  if (!param) {
    return null;
  }

  const result = {} as Record<string, string>;

  _.each(param, ({ key, value }) => {
    result[key] = value;
  });

  return result;
};

export const useUserConfigurationParams = (
  options: Omit<QueryHookOptions, 'variables'> = {},
) => {
  const { data, ...result } = useQuery(GET_USER_CONFIGURATION_PARAMS, {
    variables: {},
    ...options,
  });

  const configurationParams = useMemo(() => {
    return keyValueParamToObject(data?.user?.configurationParams?.attributes);
  }, [data]);

  return {
    data,
    configurationParams,
    ...result,
  };
};

export const useClientConfigurationParams = (
  options: Omit<QueryHookOptions, 'variables'> = {},
) => {
  const clientId = useClientId();
  const { data, ...result } = useQuery(GET_CLIENT_CONFIGURATION_PARAMS, {
    ...options,
    variables: {
      clientId,
    },
  });

  const configurationParams = useMemo(() => {
    return keyValueParamToObject(data?.client?.configurationParams?.attributes);
  }, [data]);

  return {
    data,
    configurationParams,
    ...result,
  };
};

export const useMergedConfigurationParams = ({
  overrides = {},
}: {
  overrides?: Record<string, unknown>;
} = {}) => {
  const clientQuery = useClientConfigurationParams();
  const userQuery = useUserConfigurationParams();

  const mergedParams = useMemo(() => {
    const clientConfig =
      clientQuery.data?.client?.configurationParams?.attributes || {};
    const userConfig =
      userQuery.data?.user?.configurationParams?.attributes || {};

    const clientConfigObject = _.reduce(
      clientConfig,
      (agg, { key, value }) => {
        // eslint-disable-next-line
        agg[key] = value;
        return agg;
      },
      {} as Record<string, unknown>,
    );

    const userConfigObject = _.reduce(
      userConfig,
      (agg, { key, value }) => {
        // eslint-disable-next-line
        agg[key] = value;
        return agg;
      },
      {} as Record<string, unknown>,
    );

    // The order here is important
    // client data is used only if user does not override
    // and if the app itself has no overrides
    return {
      ...clientConfigObject,
      ...userConfigObject,
      ...overrides,
    };
  }, [clientQuery.data, userQuery.data, overrides]);

  return mergedParams;
};

const UPDATE_USER_CONFIGURATION_PARAMS = gql`
  mutation updateUserConfigurationParams(
    $input: UpdateConfigurationParamInput!
  ) {
    updateUserConfigurationParam(input: $input) {
      id
      configurationParams {
        attributes {
          key
          value
        }
      }
    }
  }
`;

const UPDATE_CLIENT_CONFIGURATION_PARAMS = gql`
  mutation updateClientConfigurationParams(
    $input: UpdateConfigurationParamInput!
  ) {
    updateClientConfigurationParam(input: $input) {
      id
      configurationParams {
        attributes {
          key
          value
        }
      }
    }
  }
`;

const DELETE_USER_CONFIGURATION_PARAMS = gql`
  mutation deleteUserConfigurationParams(
    $input: DeleteConfigurationParamInput!
  ) {
    deleteUserConfigurationParam(input: $input) {
      id
      configurationParams {
        attributes {
          key
          value
        }
      }
    }
  }
`;

const DELETE_CLIENT_CONFIGURATION_PARAMS = gql`
  mutation deleteClientConfigurationParams(
    $input: DeleteConfigurationParamInput!
  ) {
    deleteClientConfigurationParam(input: $input) {
      id
      configurationParams {
        attributes {
          key
          value
        }
      }
    }
  }
`;

export const useUpdateUserConfigurationParam = ({
  queryOptions = {},
}: {
  queryOptions: Omit<MutationHookOptions, 'variables'>;
} = {}) => {
  const [updateMutation] = useMutation(UPDATE_USER_CONFIGURATION_PARAMS, {
    ...queryOptions,
  });

  const [deleteMutation] = useMutation(DELETE_USER_CONFIGURATION_PARAMS, {
    ...queryOptions,
  });

  const updateOrDelete = useCallback(
    ({ key, value }: { key: string; value: string }) => {
      if (value === null || value === undefined) {
        return deleteMutation({
          variables: {
            input: {
              key,
            },
          },
        });
      }

      return updateMutation({
        variables: {
          input: {
            key,
            value,
          },
        },
      });
    },
    [deleteMutation, updateMutation],
  );

  return updateOrDelete;
};

export const useUpdateClientConfigurationParam = ({
  queryOptions = {},
}: {
  queryOptions: Omit<MutationHookOptions, 'variables'>;
} = {}) => {
  const [updateMutation] = useMutation(UPDATE_CLIENT_CONFIGURATION_PARAMS, {
    ...queryOptions,
  });

  const [deleteMutation] = useMutation(DELETE_CLIENT_CONFIGURATION_PARAMS, {
    ...queryOptions,
  });

  const updateOrDelete = useCallback(
    ({ key, value }: { key: string; value: string }) => {
      if (value === null || value === undefined) {
        return deleteMutation({
          variables: {
            input: {
              key,
            },
          },
        });
      }

      return updateMutation({
        variables: {
          input: {
            key,
            value,
          },
        },
      });
    },
    [deleteMutation, updateMutation],
  );

  return updateOrDelete;
};
