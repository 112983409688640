import { createContext } from 'react';
import { GenericSegmentationItem } from '../GenericKanban/GenericKanbanColumn';

export type DragContextType = {
  item: GenericSegmentationItem;
  cardNode: HTMLElement;
  index: number;
  prevSegment: string;
  newSegment: string;
  items: GenericSegmentationItem[];
  prevColumnNode: HTMLElement;
  newColumnNode: HTMLElement;
};

export type GenericSegmentationViewContextProps = {
  selectedItemId: string;
  segmentItemIds: string[];
  onItemSelected?: ({
    item,
    segmentItems,
  }: {
    item: GenericSegmentationItem;
    segmentItems: string[];
  }) => void;
  onSegmentItemIds: (itemIds: string[]) => void;
  onChangeSegment: (context: DragContextType) => void;
  dragContext: DragContextType;
  onDragContextChange: (context: Partial<DragContextType>) => void;
  segmentItems: GenericSegmentationItem[];
  allowDrop?: (context: DragContextType) => boolean;
  onChangeSegmentFilters?: ({
    segmentId,
    filters,
  }: {
    segmentId: string;
    filters: any;
  }) => void;
  disableDragAndDrop?: boolean;
  weekDiffInMS?: number;
  disableProfilesInteraction?: boolean;
};

export const GenericSegmentationViewContext = createContext<
  Partial<GenericSegmentationViewContextProps>
>({});
