import React from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';
import AttachmentPill from './AttachmentPill';

const Attachments = (props) => {
  const { attachments, attachmentsAreImmutable, profileId, onSelectAttachment, t } = props;
  if (_.isEmpty(attachments)) {
    return null;
  }
  return (
    <div className='attachments-container'>
      {_.map(attachments, (attachment) => (
        <AttachmentPill
          key={attachment.id}
          attachment={attachment}
          attachmentIsImmutable={attachmentsAreImmutable}
          profileId={profileId}
          onSelectAttachment={onSelectAttachment}
          t={t}
        />
      ))}
    </div>
  );
};

export default withTranslation('translations')(Attachments);
