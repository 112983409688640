import React from 'react';

export const contactOutLogo = (
  <img alt='contactout logo' src='/images/logos/contactout.svg' />
);

export const kasprLogo = <img alt='kaspr logo' src='/images/logos/kaspr.png' />;

export const rocketReachLogo = (
  <img alt='rocketreach logo' src='/images/logos/rocketreach.svg' />
);

export const zoomInfoLogo = (
  <img alt='zoominfo logo' src='/images/logos/zoominfo.png' />
);

export const lushaLogo = <img alt='lusha logo' src='/images/logos/lusha.png' />;
