/* eslint-disable max-len */

import React, { FC } from 'react';

import styles from './svg.module.less';

const ChevronLeft: FC = () => (
  <svg
    className={styles.svgIcon}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10 12L6.25548 8.56567C5.91484 8.25325 5.91484 7.74672 6.25548 7.4343L10 3.99998'
      stroke='currentColor'
      fill='none'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default ChevronLeft;
