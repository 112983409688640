import _ from 'underscore';
import React, { useRef, useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import styles from './styles.module.less';

type Props = {
  content: Record<string, unknown>;
  onChange: (value: Record<string, unknown>) => void;
};

const MergeTagsJsonContent: React.FC<Props> = ({ content, onChange }) => {
  const ref = useRef<HTMLPreElement | null>(null);
  const [isInvalid, setIsInvalid] = useState(false);
  const [contentRaw, setContentRaw] = useState(
    JSON.stringify(content, null, 4),
  );

  useEffect(() => {
    setContentRaw(JSON.stringify(content, null, 4));
  }, [content]);

  const handleInputChange = () => {
    if (!ref.current) {
      return;
    }
    try {
      const parsed = JSON.parse(ref.current.innerText);
      if (isInvalid) {
        setIsInvalid(false);
      }
      onChange(parsed);
      setContentRaw(JSON.stringify(parsed, null, 4));
    } catch (error) {
      setIsInvalid(true);
    }
  };

  // eslint-disable-next-line
  const onInput = useMemo(() => _.debounce(handleInputChange, 700), [
    isInvalid,
  ]);

  return (
    <div
      className={classNames(styles.json, isInvalid ? styles.jsonInvalid : '')}
    >
      <pre
        ref={ref}
        className={styles.pre}
        onInput={onInput}
        contentEditable
        id='json'
      >
        {contentRaw}
      </pre>
    </div>
  );
};

export default MergeTagsJsonContent;
