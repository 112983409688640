import { useMutation } from '@apollo/client';
import { DELETE_SNIPPET } from '@/graphql/snippets';

const useDeleteSnippet = () => {
  return useMutation<any, any>(DELETE_SNIPPET, {
    refetchQueries: ['GetClientSnippets'],
  });
};

export default useDeleteSnippet;
