import React, { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import _ from 'underscore';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { SearchPoolMiniProfile } from '@/graphql/searchPoolMiniProfileById';
import { Sequence } from '@/types/sequence';
import { getTranslatedText } from '@/common';
import { getActionTitle } from '@/common/constants/taskTypes';
import {
  ReapplySequenceToProfilesResults,
  ReapplySequenceToProfilesVariables,
  REAPPLY_SEQUENCE_TO_PROFILES,
} from '@/graphql/searchPoolProfiles';

import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import GenericButton from '@/components/Common/GenericButton';
import styles from './ReapplySequenceModal.module.less';
import UpdatePreview from './UpdatePreview';
import ModalComponent from './ModalComponent';

import { ReapplySequenceTerms } from './utils';

interface ReapplySequenceModalContentProps {
  afterSequenceEdition?: boolean;
  toggleMode?: boolean;
  isToggleEnabled?: boolean;
  onSwitchToggle?: () => void;
  title?: string;
  description?: React.ReactElement;
  profiles: SearchPoolMiniProfile[];
  sequence: Sequence;
  reapplySequenceTerms: ReapplySequenceTerms;
  onError: () => void;
  onSuccess: () => void;
  clientId: string;
  setProfileErrors: (
    arg:
      | {
          error: string;
          profileId: string;
        }[]
      | undefined,
  ) => void;
  cancelButton?: React.ReactElement;
}

const ReapplySequenceModalContent: React.FC<ReapplySequenceModalContentProps> = ({
  afterSequenceEdition,
  toggleMode,
  isToggleEnabled,
  onSwitchToggle,
  title,
  description,
  profiles,
  sequence,
  reapplySequenceTerms,
  onError,
  onSuccess,
  clientId,
  setProfileErrors,
  cancelButton,
}) => {
  const { t } = useTranslation('translations');
  const [reapplySequenceToProfiles] = useMutation<
    ReapplySequenceToProfilesResults,
    ReapplySequenceToProfilesVariables
  >(REAPPLY_SEQUENCE_TO_PROFILES);
  const notification = useNotificationSystem();
  const {
    possible,
    lastCompletedActionIndex,
    shortestProfileSequenceLength,
    suffixApplicableAtIndex,
    minimumNumberOfActionsToKeep,
  } = reapplySequenceTerms;
  const [
    userDefinedNumberOfProfileActionsToKeep,
    setNumberOfProfileActionsToKeep,
  ] = useState<number | undefined>(shortestProfileSequenceLength);
  const [userDefinedSequenceSliceIndex, setSequenceSliceIndex] = useState<
    number
  >(0);
  const profileIds = _.pluck(profiles, 'id');
  useEffect(() => {
    if (lastCompletedActionIndex) {
      const defaultValue = lastCompletedActionIndex + 1;
      if (userDefinedNumberOfProfileActionsToKeep !== defaultValue) {
        setNumberOfProfileActionsToKeep(defaultValue);
      }
      if (
        defaultValue <
          (sequence?.contactFlow?.sequences?.[0]?.actions || []).length &&
        userDefinedSequenceSliceIndex !== defaultValue
      ) {
        setSequenceSliceIndex(defaultValue);
      }
    }
    // eslint-disable-next-line
  }, [sequence, lastCompletedActionIndex]);

  if (
    !possible ||
    !_.isNumber(lastCompletedActionIndex) ||
    !_.isNumber(shortestProfileSequenceLength) ||
    !_.isNumber(userDefinedNumberOfProfileActionsToKeep)
  ) {
    return null;
  }

  const handleReapplySequence = async ({
    numberOfProfileActionsToKeep,
    sequenceSuffixIndex,
  }: {
    numberOfProfileActionsToKeep: number;
    sequenceSuffixIndex: number;
  }) => {
    try {
      if (toggleMode && !isToggleEnabled) {
        onSuccess();
        return;
      }
      const { data } = await reapplySequenceToProfiles({
        variables: {
          searchPoolId: 'reveal',
          input: {
            profileIds,
            sequenceId: sequence.id,
            numberOfProfileActionsToKeep,
            sequenceSuffixIndex,
          },
        },
      });
      const results = data?.searchPool?.reapplySequenceToProfiles?.results;
      if (!results) {
        throw Error();
      }
      if (_.all(results, ({ success }) => !!success)) {
        notification.success(t('common.genericSuccess'));
        onSuccess();
        return;
      }
      const errors = _.filter(results, ({ success }) => !success);
      setProfileErrors(
        _.compact(
          _.map(errors, ({ error, profileId }) => {
            if (!error || !profileId) {
              return null;
            }
            return {
              error,
              profileId,
            };
          }),
        ),
      );
    } catch (e) {
      notification.error(t('common.genericError'));
      onError();
    }
  };

  const sequenceActionOptions = _.map(
    sequence?.contactFlow?.sequences?.[0]?.actions || [],
    (action, index) => {
      const actionDescription = getTranslatedText(
        action?.description || { default: '' },
      );
      return {
        text: `${index + 1}. ${getActionTitle({
          action,
        })} ${actionDescription ? `(${actionDescription})` : ''}`,
        value: index,
      };
    },
  );

  const descriptionComponent = description || null;

  if (minimumNumberOfActionsToKeep === 0) {
    // Sequence not started => Apply full sequence
    return (
      <ModalComponent
        title={title}
        cancelButton={cancelButton}
        submitButton={
          <GenericButton
            primacy='primary'
            size='big'
            type='submit'
            onClick={() =>
              handleReapplySequence({
                numberOfProfileActionsToKeep: 0,
                sequenceSuffixIndex: 0,
              })
            }
          >
            {afterSequenceEdition
              ? t('common.confirm')
              : t('contactFlow.reapplySequence.reapplySequence')}
          </GenericButton>
        }
      >
        {descriptionComponent}
        <UpdatePreview
          toggleMode={!!afterSequenceEdition}
          onSwitchToggle={onSwitchToggle}
          isEnabled={isToggleEnabled}
          titleText={`${t(
            'contactFlow.reapplySequence.shortReapplySequenceIsPossibleFromIndex',
          )} ${sequenceActionOptions[0]?.text}
            ${
              !descriptionComponent
                ? ` (${t('contactFlow.reapplySequence.preview')})`
                : ''
            }
            `}
          profiles={profiles}
          sequence={sequence}
          numberOfProfileActionsToKeep={0}
          sequenceSuffixIndex={0}
          clientId={clientId}
        />
      </ModalComponent>
    );
  }

  if (suffixApplicableAtIndex) {
    return (
      <ModalComponent
        title={title}
        cancelButton={cancelButton}
        submitButton={
          <GenericButton
            primacy='primary'
            type='submit'
            size='big'
            onClick={() =>
              handleReapplySequence({
                numberOfProfileActionsToKeep: suffixApplicableAtIndex,
                sequenceSuffixIndex: suffixApplicableAtIndex,
              })
            }
          >
            {afterSequenceEdition
              ? t('common.confirm')
              : t('contactFlow.reapplySequence.reapplySequence')}
          </GenericButton>
        }
      >
        {descriptionComponent}
        <UpdatePreview
          onSwitchToggle={onSwitchToggle}
          toggleMode={!!afterSequenceEdition}
          isEnabled={isToggleEnabled}
          titleText={`${t(
            'contactFlow.reapplySequence.shortReapplySequenceIsPossibleFromIndex',
          )} ${
            _.findWhere(sequenceActionOptions, {
              value: suffixApplicableAtIndex,
            })?.text
          } ${
            !descriptionComponent
              ? ` (${t('contactFlow.reapplySequence.preview')})`
              : ''
          }`}
          profiles={profiles}
          sequence={sequence}
          numberOfProfileActionsToKeep={suffixApplicableAtIndex}
          sequenceSuffixIndex={suffixApplicableAtIndex}
          clientId={clientId}
        />
      </ModalComponent>
    );
  }

  return (
    <ModalComponent
      title={title}
      cancelButton={cancelButton}
      submitButton={
        <GenericButton
          primacy='primary'
          type='submit'
          size='big'
          onClick={() =>
            handleReapplySequence({
              numberOfProfileActionsToKeep: userDefinedNumberOfProfileActionsToKeep,
              sequenceSuffixIndex: userDefinedSequenceSliceIndex,
            })
          }
        >
          {afterSequenceEdition
            ? t('common.confirm')
            : t('contactFlow.reapplySequence.reapplySequence')}
        </GenericButton>
      }
    >
      <div className={styles.mainDescriptionForComplexeCase}>
        {t('contactFlow.reapplySequence.mainDescription', {
          count: profiles.length,
        })}
      </div>
      <div className={styles.inputContainerComplexCase}>
        <div className={styles.inputLabel}>
          {t('contactFlow.reapplySequence.numberOfActionsToKeep', {
            count: profiles.length,
          })}
        </div>
        <div className={styles.inputDescription}>
          {t('contactFlow.reapplySequence.minimumNumberOfActionsToKeep', {
            count: lastCompletedActionIndex + 1,
          })}
        </div>
        <div className='input-element'>
          <Dropdown
            style={{ borderRadius: 8 }}
            fluid
            placeholder={t(
              'contactFlow.reapplySequence.numberOfActionsToKeep',
              {
                count: profiles.length,
              },
            )}
            value={userDefinedNumberOfProfileActionsToKeep}
            onChange={(e, { value }) =>
              setNumberOfProfileActionsToKeep(value as number)
            }
            options={_.map(
              _.range(
                lastCompletedActionIndex + 1,
                shortestProfileSequenceLength + 1,
              ),
              (i) => ({
                text: i,
                value: i,
              }),
            )}
            selection
          />
        </div>
      </div>
      <div className={styles.inputContainerComplexCase}>
        <div className={styles.inputLabel}>
          {t('contactFlow.reapplySequence.firstActionToAppend')}
        </div>
        <div className='input-element'>
          <Dropdown
            style={{ borderRadius: 8 }}
            fluid
            placeholder={t('contactFlow.reapplySequence.firstActionToAppend')}
            value={userDefinedSequenceSliceIndex}
            selection
            onChange={(e, { value }) => setSequenceSliceIndex(value as number)}
            options={sequenceActionOptions}
          />
        </div>
      </div>
      <UpdatePreview
        onSwitchToggle={onSwitchToggle}
        toggleMode={!!afterSequenceEdition}
        isEnabled={isToggleEnabled}
        profiles={profiles}
        sequence={sequence}
        numberOfProfileActionsToKeep={userDefinedNumberOfProfileActionsToKeep}
        sequenceSuffixIndex={userDefinedSequenceSliceIndex}
        clientId={clientId}
      />
    </ModalComponent>
  );
};

export default ReapplySequenceModalContent;
