import React, { FC, Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAskDemo from '@/graphql/hooks/clients/useAskDemo';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';

import ConfirmationModal from '../../modals/ConfirmationModal';

interface AskDemoModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const AskDemoModal: FC<AskDemoModalProps> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const [askDemo, askDemoResult] = useAskDemo();
  const { success, error } = useNotificationSystem();

  useEffect(() => {
    if (askDemoResult.error && error) {
      error(t('reveal.freeTrial.askDemoModal.error'));
    }
    if (askDemoResult.data && success) {
      success(t('reveal.freeTrial.askDemoModal.success'));
    }
  }, [askDemoResult, error, success, t]);

  return (
    <ConfirmationModal
      onCancel={() => setOpen(false)}
      onSubmit={() => {
        askDemo();
        setOpen(false);
      }}
      header={t('reveal.freeTrial.askDemoModal.header')}
      open={open}
      content={t('reveal.freeTrial.askDemoModal.description')}
      submit={t('reveal.freeTrial.askDemoModal.submit')}
    />
  );
};

export default AskDemoModal;
