import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';

import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useCreateClientProvider from '@/graphql/hooks/clients/providers/useCreateClientProvider';
import GenericButton from '@/components/Common/GenericButton';

interface BasicProviderCredentialsFormProps {
  mode: 'oauth' | 'token';
  type: string;
}

const BasicProviderCredentialsForm: React.FC<BasicProviderCredentialsFormProps> = ({
  mode,
  type,
}) => {
  const [token, setToken] = React.useState('');
  const [inputError, setInputError] = React.useState(false);
  const { t } = useTranslation();
  const notifications = useNotificationSystem();
  const [addClientProvider] = useCreateClientProvider();

  const onChangeToken = (e: React.FormEvent<HTMLInputElement>) => {
    setToken((e.currentTarget.value || '').trim());
    setInputError(false);
  };

  const handleSave = async () => {
    try {
      addClientProvider({
        variables: {
          input: {
            type,
            status: 'active',
            apiKey: token,
          },
        },
      });
      notifications.success(t('common.genericSuccess'));
    } catch (e) {
      setInputError(true);
      notifications.error(t('common.genericError'));
    }
  };

  if (mode === 'token') {
    return (
      <div className='input-container'>
        <div className='input-label'>
          {t('integrations.connection.tokensHeader')}
        </div>
        <div className='settings-row'>
          <Input
            placeholder={t('integrations.connection.tokensPlaceholder')}
            value={token}
            onChange={onChangeToken}
            error={inputError}
            fluid
          />
          <GenericButton onClick={handleSave} disabled={_.isEmpty(token)}>
            {t('integrations.connection.save')}
          </GenericButton>
        </div>
      </div>
    );
  }
  return null;
};

export default BasicProviderCredentialsForm;
