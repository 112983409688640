import {
  AtsData,
  ConnectEmail,
  ConnectPhoneNumber,
} from '@/common/reveal/SourceData.type';
import { TranslatableText } from '@/types/text';
import gql from 'graphql-tag';
import Attachment from './Attachment';
import TextWithEnrichmentsAndStack from './TextWithEnrichmentsAndStack';
import TranslatableTextType from './TranslatableText';

export interface AdventureQualification {
  id: string;
  isPrimary: boolean;
  name: TranslatableText;
}

export interface AdventureATSData extends AtsData {
  type: 'adventure';
  sourceCandidateId: string;
  firstname: string;
  lastname: string;

  emails: ConnectEmail[];
  phoneNumbers: ConnectPhoneNumber[];

  lastUpdateDate: string;
  lastActivityDate: string;

  availabilityInfoSlots: {
    lastInfoDate: string;
    type: 'available' | 'not-available';
    startDate: string;
    endDate: string | null;
  }[];

  headline?: {
    content: {
      text: string;
    };
  };

  currentCompany?: {
    name?: string;
  };

  addresses: {
    type: string;
    value: string;
  }[];

  transportationTypes: {
    type: string;
    name: TranslatableText;
  }[];

  sectors: {
    type: string;
    name: TranslatableText;
  }[];

  qualifications: AdventureQualification[];

  certifications: {
    id: string;
    name: TranslatableText;
  }[];

  skills?: {
    id: string;
    name: TranslatableText;
  }[];

  tags?: {
    sourceTagId: string;
    name: TranslatableText;
  }[];
}

export const FullATSContract = gql`
  fragment FullATSContract on ATSContract {
    id
    sourceContractId
    sourceContractNumber
    creationDate

    actualStartDate
    actualEndDate
    endDate
    theoreticalEndDate
    leewayEndDates

    metadata {
      key
      value
    }

    externalContractor {
      sourceId
      name
      sourceLegalId
    }

    status {
      state
      history {
        oldState
        newState
        transitionDate
        addendumId
        comments {
          author {
            firstname
            lastname
            email
          }
          comment
          date
        }
      }
    }

    addendums {
      addendumId

      actualStartDate
      actualEndDate
      theoreticalEndDate
      leewayEndDates

      metadata {
        key
        value
      }
    }
  }
`;

export const ATSContract = gql`
  fragment ATSContract on ATSContract {
    ...FullATSContract

    flattenedContract {
      ...FullATSContract
    }
  }
  ${FullATSContract}
`;

export default gql`
  fragment AdventureData on AdventureData {
    type
    sourceCandidateId
    firstname
    lastname

    creationDate
    lastUpdateDate
    lastActivityDate

    availabilityInfoSlots {
      type
      lastInfoDate
      startDate
      endDate
    }

    headline {
      ...TextWithEnrichmentsAndStack
    }

    currentCompany {
      name
    }

    emails {
      type
      value
    }

    phoneNumbers {
      type
      value
    }

    addresses {
      type
      value
    }

    attachments {
      ...Attachment
    }

    applications {
      sourceApplicationId
      applicationDate
      lastActivityDate
      jobs {
        sourceJobId
        title
      }
      status {
        sourceStatusId
        stage {
          sourceStageId
          sourceStageName
        }
        rejection {
          category {
            type
            name
          }
          reason {
            sourceRejectionReasonId
            sourceRejectionReasonName
          }
          lastStage {
            sourceStageId
            sourceStageName
          }
        }
      }
    }

    contracts {
      ...ATSContract
    }

    transportationTypes {
      type
      name {
        ...TranslatableText
      }
    }

    sectors {
      id
      name {
        ...TranslatableText
      }
    }

    qualifications {
      id
      isPrimary
      name {
        ...TranslatableText
      }
    }

    certifications {
      id
      name {
        ...TranslatableText
      }
    }
    tags {
      sourceTagId
      name {
        ...TranslatableText
      }
    }
  }
  ${Attachment}
  ${ATSContract}
  ${TextWithEnrichmentsAndStack}
  ${TranslatableTextType}
`;
