/* eslint-disable max-len */

import React, { FC } from 'react';

import styles from './svg.module.less';

const Interest: FC = () => (
  <svg
    className={styles.svgIcon}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 2.784C8.808 1.704 10.104 1 11.552 1C14.008 1 16 3 16 5.472C16 6.424 15.848 7.304 15.584 8.12C14.32 12.12 10.424 14.512 8.496 15.168C8.224 15.264 7.776 15.264 7.504 15.168C5.576 14.512 1.68 12.12 0.416 8.12C0.152 7.304 0 6.424 0 5.472C0 3 1.992 1 4.448 1C5.896 1 7.192 1.704 8 2.784ZM11.6817 7.23163C12.0858 6.85513 12.1081 6.22236 11.7316 5.8183C11.3551 5.41423 10.7224 5.39188 10.3183 5.76838L7.25436 8.62326L5.70127 7.09549C5.30755 6.70819 4.67441 6.7134 4.2871 7.10712C3.8998 7.50084 3.90501 8.13399 4.29873 8.52129L6.01839 10.2129C6.03262 10.2269 6.04728 10.2405 6.06233 10.2536C6.73187 10.8376 7.76811 10.8376 8.43765 10.2536C8.4459 10.2464 8.45402 10.2391 8.46202 10.2316L11.6817 7.23163Z'
      fill='currentColor'
    />
  </svg>
);

export default Interest;
