import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {
  AcceptConditionWithTargetText,
  AcceptConditionWithTargetTexts,
  ACCEPT_CONDITIONS_WITH_TARGET_TEXTS,
  FieldCondition,
} from '@/common/mergeTags/utils';
import { useConditionsChainingContext } from '../useConditionsChainingContext';
import styles from '../ConditionsChaining.module.less';
import { getSelectFieldOptions } from '../utils';
import useConditionsChainingCustomFields from '../useConditionsChainingCustomFields';

type NodeType = AcceptConditionWithTargetTexts | AcceptConditionWithTargetText;

const SelectTarget: React.FC<{
  nodeId: string;
  node: NodeType;
  condition: FieldCondition;
}> = ({ nodeId, node, condition }) => {
  const { t } = useTranslation();
  const {
    getStateTreeAfterReplacement,
    conditionsChainingState,
    setConditionsChainingState,
    clientId,
  } = useConditionsChainingContext();

  const {
    profileCustomFields,
    missionCustomFields,
  } = useConditionsChainingCustomFields({ clientId });

  function isMultiple(
    targetNode: NodeType,
  ): targetNode is AcceptConditionWithTargetTexts {
    return ACCEPT_CONDITIONS_WITH_TARGET_TEXTS.includes(node.type);
  }

  const handleChange = ({ newValue }: { newValue: string | string[] }) => {
    const newState = getStateTreeAfterReplacement<NodeType>({
      node: conditionsChainingState,
      id: nodeId,
      newValue: {
        ...node,
        ...(isMultiple(node)
          ? { targetTexts: newValue as string[] }
          : { targetText: newValue as string }),
      },
    });
    setConditionsChainingState(newState);
  };

  return (
    <Dropdown
      className={styles.input}
      options={getSelectFieldOptions({
        fieldCategory: condition.fieldCategory,
        fieldId:
          condition.fieldCategory === 'native'
            ? condition.fieldId
            : condition.customFieldId,
        profileCustomFields,
        missionCustomFields,
        t,
      })}
      search
      selection
      fluid
      multiple={isMultiple(node)}
      value={isMultiple(node) ? node.targetTexts : node.targetText}
      onChange={(event, { value }) =>
        handleChange({ newValue: value as string | string[] })
      }
    />
  );
};

export default SelectTarget;
