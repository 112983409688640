import { graphql } from '@apollo/client/react/hoc';
import { GET_CLIENT_PERMISSIONS } from '../../graphql/clients';

// NB: It seems easier to get them through withClient directly

export default graphql(GET_CLIENT_PERMISSIONS, {
  options: (ownProps) => ({
    variables: {
      clientId: ownProps.clientId,
    },
  }),
  props: ({ data: { loading, client, error } }) => ({
    loading,
    permissionsLoading: loading,
    permissions: (client || {}).permissions || {},
    error,
  }),
});
