import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './OkToContactPill.module.less';

const OkToContactPill: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={style.pill}>
      <i className='ri-chat-check-line' />
      {t('reveal.profileModal.privacy.okToContact.label')}
    </div>
  );
};

export default OkToContactPill;
