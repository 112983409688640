import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CountsByArchivedState,
  CountsByPipelineState,
} from '@/types/statistics/pipeline';
import { PipelineState } from '@/components/PipelineSegmentation/pipelineSegmentation';
import {
  flattenSeparatedCountsByPipelineState,
  formatPipelineCountDataPoints,
  getConversionRates,
  getCurrentCountsBySegment,
  getEverCountsBySegment,
} from '../helpers/count';
import {
  DisplayType,
  PipelineCountDataPointForCategory,
  SeparatedCountsByPipelineStateByCategory,
} from '../types';

export interface PipelineCounts {
  countsByCategory: SeparatedCountsByPipelineStateByCategory;
  globalConversionRates: Partial<Record<PipelineState, string>>;
  summaryData: CountsByPipelineState & CountsByArchivedState;
}

const usePipelineCounts = (
  data: PipelineCountDataPointForCategory[],
  displayType: DisplayType,
): PipelineCounts => {
  const { t } = useTranslation();

  const countsByCategory = useMemo(() => formatPipelineCountDataPoints(data), [
    data,
  ]);

  const globalFunnelData = useMemo(
    () => flattenSeparatedCountsByPipelineState(countsByCategory),
    [countsByCategory],
  );

  const everGlobalFunnelData = useMemo(
    () => getEverCountsBySegment(globalFunnelData),
    [globalFunnelData],
  );

  const globalConversionRates = useMemo(
    () => getConversionRates(t)(everGlobalFunnelData),
    [everGlobalFunnelData, t],
  );

  const currentGlobalFunnelData = useMemo(
    () => getCurrentCountsBySegment(globalFunnelData),
    [globalFunnelData],
  );

  const summaryData =
    displayType === 'current' ? currentGlobalFunnelData : everGlobalFunnelData;

  return {
    countsByCategory,
    globalConversionRates,
    summaryData,
  };
};

export default usePipelineCounts;
