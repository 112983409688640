import _ from 'underscore';
import React, { PropsWithChildren, useContext, useState } from 'react';
import {
  HiresweetLibProvider,
  ProfileFirstname,
  ProfileLastname,
  ProfilesSetContainer,
  ProfilesSetContext,
  ProfileSetItemContainer,
  ProfilesSubsetRegister,
} from '@hiresweet/hiresweet-lib';
import useClientId from '@/hooks/router/useClientId';

const PaginatedProfiles = ({ children }: PropsWithChildren<object>) => {
  const { profileIds } = useContext(ProfilesSetContext);
  const [iPage, setIPage] = useState(0);

  const chunks = _.chunk(profileIds, 5);

  if (_.isEmpty(chunks)) {
    return null;
  }

  return (
    <div>
      <h3>Paginated</h3>
      <button
        type='submit'
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setIPage((currentIPage) =>
            currentIPage < chunks.length - 1 ? currentIPage + 1 : 0,
          );
        }}
      >
        {iPage + 1} / {chunks.length}
      </button>
      <br />
      <ProfilesSubsetRegister profileIds={chunks[iPage] ?? []}>
        {chunks[iPage].map((profileId) => (
          <div key={profileId}>
            <ProfileSetItemContainer profileId={profileId}>
              <span>{profileId}</span> {children}
            </ProfileSetItemContainer>
          </div>
        ))}
      </ProfilesSubsetRegister>
    </div>
  );
};

const Playground5 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30 }}>
      <h1>P6 - Profiles - Manual Pagination</h1>
      <div>
        <HiresweetLibProvider theme={theme} clientId={clientId}>
          <ProfilesSetContainer
            filter={{
              type: 'project-step',
              projectId: 'ri7-demo-testimport2-UMODD2',
              step: 'all',
            }}
          >
            <PaginatedProfiles>
              <ProfileFirstname /> --- <ProfileLastname />
            </PaginatedProfiles>
          </ProfilesSetContainer>
        </HiresweetLibProvider>
      </div>
    </div>
  );
};

export default Playground5;
