import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';
import _ from 'underscore';

import useClientId from '@/hooks/router/useClientId';
import usePipelineStatisticsByCategory from '@/graphql/hooks/clients/usePipelineStatisticsByCategory';
import { Stats } from '@/types/statistics/stats';
import { Gender } from '@/types/gender';
import usePipelineStatisticsByCategoryDetails from '@/graphql/hooks/clients/usePipelineStatisticsByCategoryDetails';
import useMiniMissions from '@/graphql/hooks/searchPoolJobs/useMiniMissions';
import InfoTooltip from '@/components/Common/InfoTooltip';
import { sumNumberRecords } from '@/common/helpers/stats';
import { typesafeMapObject } from '@/common/utils/types';
import GenericModal from '@/components/Common/GenericModal';
import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';
import StatsProfileAndMissionTable from '../../../components/StatsProfileAndMissionTable';
import {
  GenderRecord,
  GENDER_STACK,
  getInitialGenderRecord,
  getInitialProfileItemsByGenderMap,
  mergeProfileItemsByGenderMaps,
} from '../helpers';
import HorizontalStackedBarChart from '../../../components/HorizontalStackedBarChart';
import { TimeSpan } from '../../../components/RevealAnalyticsHeader/RevealAnalyticsHeader';
import DetailsModalHeader from '../../../components/DetailsModalHeader';

import styles from './GenderRepartitionByProject.module.less';

interface StatsFilter {
  gender?: Gender;
  missionId?: string;
  archivedProjects?: boolean;
}

interface GenderRepartitionByProjectProps {
  timeSpan: TimeSpan;
  missionIds: string[];
}

const GenderRepartitionByProject: FC<GenderRepartitionByProjectProps> = ({
  timeSpan,
  missionIds,
}) => {
  const [showArchivedProjects, setShowArchivedProjects] = useState(false);
  const { t } = useTranslation();
  const clientId = useClientId();
  const { loading: statsLoading, volumeByPipelineSplitByCategory } =
    usePipelineStatisticsByCategory({
      clientId,
      categorization: { type: 'gender' },
      startDate: timeSpan.startDate.format('YYYY-MM-DD'),
      endDate: timeSpan.endDate.format('YYYY-MM-DD'),
    });
  const { miniMissions, loading: miniMissionsLoading } = useMiniMissions();
  const [
    fetchDetails,
    {
      volumeByPipelineSplitByCategory: detailsVolumeByPipelineSplitByCategory,
      loading: detailsLoading,
    },
  ] = usePipelineStatisticsByCategoryDetails();
  const [statsFilter, setStatsFilter] = useState<StatsFilter | undefined>(
    undefined,
  );
  const [modalOpen, setModalOpen] = useState(false);

  const fetchWithFilter = useCallback(
    (newFilter: StatsFilter) => {
      if (!fetchDetails) {
        return;
      }
      setStatsFilter(newFilter);
      const { gender, missionId } = newFilter;
      if (!missionId || !gender) {
        return;
      }

      fetchDetails({
        clientId,
        categorization: { type: 'gender' },
        categoriesFilter: { in: [gender] },
        missionsFilter: { in: [missionId] },
        startDate: timeSpan.startDate.format('YYYY-MM-DD'),
        endDate: timeSpan.endDate.format('YYYY-MM-DD'),
      });
    },
    [fetchDetails, clientId, timeSpan],
  );

  const formattedData: Stats[] = _.chain(volumeByPipelineSplitByCategory || [])
    .filter(({ missionId }) => {
      const miniMission = _.findWhere(miniMissions || [], { id: missionId });
      return (
        (!showArchivedProjects ? !miniMission?.isArchived : true) &&
        (_.isEmpty(missionIds) || _.contains(missionIds, missionId))
      );
    })
    .reduce(
      (accumulator, { missionId, categoryId, data }) => ({
        ...accumulator,
        [missionId]: sumNumberRecords(
          accumulator[missionId] || getInitialGenderRecord(),
          {
            ...getInitialGenderRecord(),
            [categoryId]: _.reduce(data, (sum, { count }) => sum + count, 0),
          },
        ),
      }),
      {} as Record<string, GenderRecord>,
    )
    .map((values, missionId) => {
      const miniMission = _.findWhere(miniMissions || [], { id: missionId });
      return {
        name: miniMission?.data?.title || '???',
        values,
        clickListeners: typesafeMapObject(
          values,
          (_count, categoryId) => () => {
            setModalOpen(true);
            fetchWithFilter({ gender: categoryId, missionId });
          },
        ),
        link: `/client/${clientId}/reveal/projects/recruiting/${missionId}`,
      };
    })
    .value();

  const formattedDetailsData: Record<
    string,
    Record<Gender, { profileId: string }[]>
  > = _.chain(detailsVolumeByPipelineSplitByCategory || [])
    .filter(
      ({ missionId }) =>
        _.isEmpty(missionIds) || _.contains(missionIds, missionId),
    )
    .reduce(
      (accumulator, { missionId, categoryId, data }) => ({
        ...accumulator,
        [missionId]: mergeProfileItemsByGenderMaps(
          accumulator[missionId] || getInitialProfileItemsByGenderMap(),
          {
            ...getInitialProfileItemsByGenderMap(),
            [categoryId]: _.reduce(
              data,
              (aggregatedProfileItems, { profileItems }) => [
                ...aggregatedProfileItems,
                ...profileItems,
              ],
              [] as { profileId: string }[],
            ),
          },
        ),
      }),
      {} as Record<string, Record<Gender, { profileId: string }[]>>,
    )
    .value();

  const profileDetails = useMemo(() => {
    if (!statsFilter?.missionId || !statsFilter?.gender) {
      return [];
    }
    const { gender, missionId } = statsFilter;

    return _.map(
      formattedDetailsData[missionId]?.[gender] || [],
      ({ profileId }) => ({
        profileId,
        missionId,
      }),
    );
  }, [formattedDetailsData, statsFilter]);

  const modalTitle = useMemo(() => {
    const titleParts = [] as string[];
    if (statsFilter?.missionId) {
      const missionTitle = _.findWhere(miniMissions || [], {
        id: statsFilter.missionId,
      })?.data?.title;
      if (missionTitle) {
        titleParts.push(missionTitle);
      }
    }
    if (statsFilter?.gender) {
      titleParts.push(
        t(`reveal.reports.diversity.genders.${statsFilter.gender}`),
      );
    }
    return titleParts.join(' - ');
  }, [miniMissions, statsFilter, t]);

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <h3>
          {t('reveal.reports.diversity.genderRepartitionByProject')}
          <InfoTooltip rich hoverable position='right center'>
            <h1>
              {t('reveal.reports.tooltips.diversity.genderByProject.title')}
            </h1>
            <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
            <p>{t('reveal.reports.tooltips.common.hiresweetCount')}</p>
            <h2>{t('reveal.reports.tooltips.common.usefulness')}</h2>
            <p>
              {t(
                'reveal.reports.tooltips.diversity.genderByProject.usefulnessParagraph',
              )}
            </p>
            <h2>{t('reveal.reports.tooltips.common.details')}</h2>
            <p>{t('reveal.reports.tooltips.common.barDetails')}</p>
            <h2>
              {t('reveal.reports.tooltips.diversity.common.genderLabeling')}
            </h2>
            <p>
              {t(
                'reveal.reports.tooltips.diversity.common.genderLabelingParagraph',
              )}
            </p>
          </InfoTooltip>
        </h3>
        <LabeledCheckbox
          checked={showArchivedProjects}
          onClick={() => setShowArchivedProjects((prev) => !prev)}
          label={t('reveal.missions.pipeline.countArchived')}
        />
      </div>
      {statsLoading || miniMissionsLoading ? (
        <div className={styles.loader}>
          <Loader active inline='centered' size='large' />
        </div>
      ) : (
        <div className={styles.graph}>
          <HorizontalStackedBarChart
            stack={GENDER_STACK}
            allStats={formattedData}
            isPercentage
            exhaustive
          />
        </div>
      )}
      {detailsLoading ? (
        <Dimmer active>
          <Loader size='large' />
        </Dimmer>
      ) : (
        modalOpen && (
          <GenericModal open={modalOpen} onClose={() => setModalOpen(false)}>
            <Modal.Content>
              <DetailsModalHeader>
                <h1>{modalTitle}</h1>
              </DetailsModalHeader>
              <StatsProfileAndMissionTable profileDetails={profileDetails} />
            </Modal.Content>
          </GenericModal>
        )
      )}
    </div>
  );
};

export default GenderRepartitionByProject;
