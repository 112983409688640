import _ from 'underscore';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import React from 'react';
import { Button } from 'semantic-ui-react';
import classNames from 'classnames';

import {
  ContactOperator,
  DiversityOperator,
  KeywordsEmplacement,
  KeywordsOperator,
  PropertyFilter,
} from './types';
import {
  HAS_ANY_KEYWORDS,
  HAS_KEYWORDS,
  HAS_NONE_OF_KEYWORDS,
  KEYWORD_EMPLACEMENT_ANYWERE,
  KEYWORD_EMPLACEMENT_HEADLINE,
  KEYWORD_EMPLACEMENT_ALL_HEADLINE_EXPERIENCES,
} from './constants';
import { OPERATORS_OPTION } from './operatorsOptions';
import { propertyFilterHasError } from './propertyFilterHasError';

import './CustomCriteriaFilters.css';

const CustomCriteriaFilterButton = (props: {
  filter: PropertyFilter;
  onRemoveFilter: () => void;
  t: TFunction;
}) => {
  const { filter, onRemoveFilter, t } = props;
  return (
    <Button
      className={classNames('filter-button', {
        hasError: propertyFilterHasError(filter),
      })}
    >
      <span className='bold'>{filter.name}</span>
      {filter.type !== 'contact' &&
        filter.type !== 'diversity' &&
        filter.type !== 'keywords' && (
          <>
            {` ${getTraductionKey(filter, t).toLowerCase()} `}
            <span className='bold'>{getTextFromValue(t, filter)}</span>
          </>
        )}
      {filter.type === 'contact' && (
        <>
          {' '}
          {filter.operator?.map((op, index) => (
            <React.Fragment key={op}>
              {` ${t(CONTACT_TRAD_KEY[op]).toLowerCase()} `}
              <span className='bold'>
                {filter.value?.[op]
                  ?.map((value) => t(CONTACT_TRAD_VALUE_KEY?.[value] ?? ''))
                  .join(', ')}
              </span>
              {index < (filter.operator?.length ?? 1) - 1 &&
                ` ${t('reveal.searchView.search.and')} `}
            </React.Fragment>
          ))}
        </>
      )}
      {filter.type === 'diversity' && (
        <>
          {' '}
          {filter.operator?.map((op, index) => (
            <React.Fragment key={op}>
              {` ${t(DIVERSITY_TRAD_KEY[op]).toLowerCase()} `}
              <span className='bold'>
                {_.map(_.values(filter.value), (value) => (
                  <>
                    {t(DIVERSITY_TRAD_VALUE_KEY?.[value] ?? '')} {}
                  </>
                ))}
              </span>
              {index < (filter.operator?.length ?? 1) - 1 &&
                ` ${t('reveal.searchView.search.and')} `}
            </React.Fragment>
          ))}
        </>
      )}
      {filter.type === 'keywords' && (
        <>
          {' '}
          {filter.operator?.map((op, index) => (
            <React.Fragment key={op}>
              {` ${t(KEYWORD_TRAD_KEY[op])} `}
              <span className='bold'>
                {filter?.value?.[op] && filter.value[op]?.join(', ')}{' '}
                {!_.isEmpty(filter.emplacements) && (
                  <>
                    {' '}
                    (
                    {getEmplacementTranslation(
                      filter.emplacements?.[0] as KeywordsEmplacement,
                      t,
                    )}
                    )
                  </>
                )}
              </span>
              {index < (filter.operator?.length ?? 1) - 1 &&
                ` ${t('reveal.searchView.search.and')} `}
            </React.Fragment>
          ))}
        </>
      )}
      <div className='cross' onClick={onRemoveFilter}>
        <i className='ri-close-line' />
      </div>
    </Button>
  );
};

const getTraductionKey = (filter: PropertyFilter, t: TFunction): string => {
  const { type, operator } = filter;
  const operatorsOptions: {
    operator: string;
    translation: string;
  }[] = OPERATORS_OPTION[type];

  const operatorOption = (operatorsOptions || []).find(
    (op) => op.operator === operator,
  );

  return t(operatorOption?.translation ?? '');
};

const getEmplacementTranslation = (
  emplacement: KeywordsEmplacement,
  t: TFunction,
): string => {
  const translations: Record<string, string> = {
    [KEYWORD_EMPLACEMENT_ANYWERE]: t(
      'reveal.searchView.search.keywords.emplacements.anywhere',
    ),
    [KEYWORD_EMPLACEMENT_HEADLINE]: t(
      'reveal.searchView.search.keywords.emplacements.headline',
    ),
    [KEYWORD_EMPLACEMENT_ALL_HEADLINE_EXPERIENCES]: t(
      'reveal.searchView.search.keywords.emplacements.allHeadlineExperiences',
    ),
  };
  return translations[emplacement];
};

const getTextFromValue = (t: TFunction, filter: PropertyFilter) => {
  const value = filter?.value;
  if (!_.isEmpty(filter?.options) && Array.isArray(value)) {
    const options = _.map(value, (val) => {
      return _.find(filter.options, (option) => {
        return option.id === val;
      })?.title?.default;
    });
    return options.join(', ');
  }
  if (Array.isArray(value)) return value.join(', ');
  if (
    filter.type === 'day' &&
    typeof value === 'string' &&
    !Number.isNaN(Date.parse(value))
  ) {
    const date = new Date(value);
    return t('common.simpleDate', {
      date,
    });
  }
  return value;
};

const CONTACT_TRAD_KEY: Record<ContactOperator, string> = {
  'any-filled': 'reveal.searchView.search.anyFilled',
  'all-filled': 'reveal.searchView.search.allFilled',
  'any-missing': 'reveal.searchView.search.anyMissing',
  'all-missing': 'reveal.searchView.search.allMissing',
};

const DIVERSITY_TRAD_KEY: Record<DiversityOperator, string> = {
  firstname_is_likely: 'reveal.searchView.search.firstnameIsLikely',
};

const KEYWORD_TRAD_KEY: Record<KeywordsOperator, string> = {
  [HAS_ANY_KEYWORDS]: 'reveal.searchView.search.keywords.descriptions.anyOf',
  [HAS_KEYWORDS]: 'reveal.searchView.search.keywords.descriptions.allOf',
  [HAS_NONE_OF_KEYWORDS]:
    'reveal.searchView.search.keywords.descriptions.noneOf',
};

const CONTACT_TRAD_VALUE_KEY: Record<string, string> = {
  email: 'reveal.searchView.search.email',
  phoneNumber: 'reveal.searchView.search.phoneNumber',
  linkedin: 'reveal.searchView.search.linkedin',
  github: 'reveal.searchView.search.github',
  stackoverflow: 'reveal.searchView.search.stackoverflow',
};

const DIVERSITY_TRAD_VALUE_KEY: Record<string, string> = {
  male: 'reveal.searchView.search.male',
  female: 'reveal.searchView.search.female',
};

export default _.compose(withTranslation('translations'))(
  CustomCriteriaFilterButton,
);
