import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';

import './SearchBar.css';

export default withTranslation('translations')(
  class SearchBar extends Component {
    static defaultProps = {
      full: false,
      searchText: '',
    };

    constructor(props) {
      super(props);
      this.state = {
        searchText: props.searchText || '',
      };
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.searchText !== this.state.searchText) {
        this.setState({ searchText: nextProps.searchText });
      }
    }

    handleChange = (event) => {
      this.setState({ searchText: event.currentTarget.value });
      if (this.props.onChange) {
        this.props.onChange(event.currentTarget.value);
      }
    };

    render() {
      const { t, full } = this.props;
      const variantClass = full ? 'full' : 'reduced';
      return (
        <div className={`search-bar ${variantClass}`}>
          <Input
            icon='search'
            iconPosition='left'
            placeholder={t('profile.profileNavigator.searchCandidates')}
            value={this.state.searchText} // value
            onChange={this.handleChange}
          />
        </div>
      );
    }
  },
);
