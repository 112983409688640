import React from 'react';
import classNames from 'classnames';
import { Dropdown, Popup } from 'semantic-ui-react';

import './HoverItemsDropdown.css';

const HoverItemsDropdown = ({
  className,
  options,
  value,
  open,
  onOpen,
  onClose,
  onChange,
  ...props
}) => {
  return (
    <Dropdown
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      className={classNames('hover-items-dropdown', className)}
      selection
      options={options}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

export const HoverItemContent = ({
  triggerClassName,
  popupContentClassName,
  text,
  position,
  popupContent,
  onClick,
}) => {
  return (
    <Popup
      basic
      position={position || 'left center'}
      hoverable
      pinned
      trigger={
        <div
          className={classNames(triggerClassName, 'hover-item-popup-trigger')}
          onClick={onClick}
        >
          {text}
        </div>
      }
      content={
        <div
          className={classNames(
            popupContentClassName,
            'hover-item-popup-content',
          )}
        >
          {popupContent}
        </div>
      }
      // See fix for correct positioning
      // https://github.com/Semantic-Org/Semantic-UI-React/issues/3725#issuecomment-516766920
      popperModifiers={[
        {
          // Fix a positioning problem
          name: 'preventOverflow',
          options: {
            boundariesElement: 'window',
          },
        }
      ]}
    />
  );
};

export default HoverItemsDropdown;
