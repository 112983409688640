import _ from 'underscore';
import React from 'react';
import RecursiveSelector from '../components/RecursiveSelector';

const PinpointApplicationStatusesFilter = ({
  onUpdate,
  applicationStatuses,
  applicationStatusOptions,
}) => {
  const filter = getFilterFromApplicationStatuses({
    applicationStatuses,
    applicationStatusOptions,
  });
  return (
    <RecursiveSelector
      folderedOptions={applicationStatusOptions}
      filter={filter}
      onUpdateFilter={({ filter: newFilter }) => {
        onUpdate({
          applicationStatuses: getApplicationStatusesFromFilter({
            filter: newFilter,
            applicationStatusOptions,
          }),
        });
      }}
    />
  );
};

const getFilterFromApplicationStatuses = ({
  applicationStatuses,
  applicationStatusOptions,
}) => {
  const filter = {};

  _.each(applicationStatuses, (applicationStatusItem) => {
    if (applicationStatusItem.status !== 'rejected') {
      filter[applicationStatusItem.status] = {};
    }

    if (applicationStatusItem.status === 'rejected') {
      const rejectionKey = getRejectionKey(
        applicationStatusItem.rejectionReasonTypeId,
        applicationStatusOptions,
      );
      if (rejectionKey) {
        filter[rejectionKey] = {};
        _.each(
          applicationStatusItem.rejectionReasonIds,
          (rejectionReasonId) => {
            filter[rejectionKey][`${rejectionReasonId}`] = {};
          },
        );
      }
    }
  });
  return filter;
};

const getApplicationStatusesFromFilter = ({
  filter,
  applicationStatusOptions,
}) => {
  const applicationStatuses = [];
  _.each(filter, (subFilter, status) => {
    if (!subFilter) {
      return;
    }
    if (status.indexOf('rejection') !== 0) {
      applicationStatuses.push({ status });
    } else {
      const rejectionReasonTypeId = getRejectionReasonTypeId(
        status,
        applicationStatusOptions,
      );
      if (rejectionReasonTypeId !== null) {
        const rejectionReasonIds = [];
        _.each(subFilter, (value, key) => {
          if (value) {
            try {
              rejectionReasonIds.push(key);
            } catch (e) {
              console.error('invalid key ', key);
            }
          }
        });
        applicationStatuses.push({
          status: 'rejected',
          rejectionReasonTypeId,
          ...(!_.isEmpty(rejectionReasonIds) && { rejectionReasonIds }),
        });
      }
    }
  });
  return applicationStatuses;
};

const getRejectionReasonTypeId = (status, applicationStatusOptions) => {
  return (
    _.findWhere(applicationStatusOptions, { id: status })?.sourceId || null
  );
};

const getRejectionKey = (rejectionReasonTypeId, applicationStatusOptions) => {
  return (
    _.findWhere(applicationStatusOptions, {
      sourceId: rejectionReasonTypeId,
    }) || {}
  ).id;
};

export default PinpointApplicationStatusesFilter;
