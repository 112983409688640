import { DraftInlineStyle } from 'draft-js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  Menu,
  Popup,
  Dropdown,
  Input,
  InputOnChangeData,
} from 'semantic-ui-react';
import {
  FONT_SIZE_BIG,
  FONT_SIZE_HUGE,
  FONT_SIZE_NORMAL,
  FONT_SIZE_SMALL,
  STYLE_MAP,
} from './constants';

interface EditorToolsMenuProps {
  showURLInput: boolean;
  urlValue: string;
  handleUrlRef: any;
  handleLinkChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ) => void;
  handleLinkKeyPress: (e: React.SyntheticEvent) => void | null;
  currentStyle: DraftInlineStyle;
  insertLink: () => void;
  inlineStyleHandler: (
    style: string,
  ) => React.EventHandler<React.SyntheticEvent>;
  currentBlockType: string;
  blockTypeHandler: (
    blockType: string,
  ) => React.EventHandler<React.SyntheticEvent>;
  getEntityKeyInSelection: (type: string) => string | null;
  handleAddLink: React.EventHandler<React.SyntheticEvent>;
  handleClickImage?: React.EventHandler<React.SyntheticEvent>;
  displayImageInsertion?: boolean;
}
const EditorToolsMenu: React.FC<EditorToolsMenuProps> = ({
  showURLInput,
  urlValue,
  handleUrlRef,
  handleLinkChange,
  handleLinkKeyPress,
  currentStyle,
  insertLink,
  inlineStyleHandler,
  currentBlockType,
  blockTypeHandler,
  getEntityKeyInSelection,
  handleAddLink,
  handleClickImage,
  displayImageInsertion,
}) => {
  const { t } = useTranslation();
  if (showURLInput) {
    return (
      <Menu.Item>
        <Input
          ref={handleUrlRef}
          value={urlValue}
          onChange={handleLinkChange}
          onKeyPress={handleLinkKeyPress}
          action={{
            className: 'url-input-action',
            icon: 'checkmark',
            onMouseDown: insertLink,
          }}
          iconPosition='left'
          icon='linkify'
          placeholder='https://...'
        />
      </Menu.Item>
    );
  }
  return (
    <Menu.Menu>
      <Menu.Item
        active={currentStyle.has('BOLD')}
        onMouseDown={inlineStyleHandler('BOLD')}
        className='editor-toolbar-item'
      >
        <Popup
          trigger={<Icon name='bold' color='grey' size='small' />}
          content={`${t('editor.toolsMenu.bold')} (Ctrl-B)`}
          size='mini'
          inverted
          position='top center'
        />
      </Menu.Item>
      <Menu.Item
        active={currentStyle.has('ITALIC')}
        onMouseDown={inlineStyleHandler('ITALIC')}
        className='editor-toolbar-item'
      >
        <Popup
          trigger={<Icon name='italic' color='grey' size='small' />}
          content={`${t('editor.toolsMenu.italic')} (Ctrl-I)`}
          size='mini'
          inverted
          position='top center'
        />
      </Menu.Item>
      <Menu.Item
        active={currentStyle.has('UNDERLINE')}
        onMouseDown={inlineStyleHandler('UNDERLINE')}
        className='editor-toolbar-item'
      >
        <Popup
          trigger={<Icon name='underline' color='grey' size='small' />}
          content={`${t('editor.toolsMenu.underline')} (Ctrl-U)`}
          size='mini'
          inverted
          position='top center'
        />
      </Menu.Item>
      <Menu.Item
        active={
          currentStyle.has(FONT_SIZE_SMALL) ||
          currentStyle.has(FONT_SIZE_NORMAL) ||
          currentStyle.has(FONT_SIZE_BIG) ||
          currentStyle.has(FONT_SIZE_HUGE)
        }
        className='editor-toolbar-item'
      >
        <Dropdown
          trigger={<Icon name='text height' color='grey' size='small' />}
          onMouseDown={(e) => {
            // Keep editor text in focus
            e.stopPropagation();
            e.preventDefault();
          }}
          size='mini'
          icon
        >
          <Dropdown.Menu>
            <Dropdown.Item
              style={STYLE_MAP[FONT_SIZE_SMALL]}
              onMouseDown={inlineStyleHandler(FONT_SIZE_SMALL)}
              active={currentStyle.has(FONT_SIZE_SMALL)}
            >
              {t('editor.fontSize.small')}
            </Dropdown.Item>
            <Dropdown.Item onMouseDown={inlineStyleHandler(FONT_SIZE_NORMAL)}>
              {t('editor.fontSize.normal')}
            </Dropdown.Item>
            <Dropdown.Item
              style={STYLE_MAP[FONT_SIZE_BIG]}
              onMouseDown={inlineStyleHandler(FONT_SIZE_BIG)}
              active={currentStyle.has(FONT_SIZE_BIG)}
            >
              {t('editor.fontSize.big')}
            </Dropdown.Item>
            <Dropdown.Item
              style={STYLE_MAP[FONT_SIZE_HUGE]}
              onMouseDown={inlineStyleHandler(FONT_SIZE_HUGE)}
              active={currentStyle.has(FONT_SIZE_HUGE)}
            >
              {t('editor.fontSize.huge')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
      <Menu.Item
        active={currentBlockType === 'unordered-list-item'}
        onMouseDown={blockTypeHandler('unordered-list-item')}
        className='editor-toolbar-item'
      >
        <Popup
          trigger={<Icon name='unordered list' color='grey' size='small' />}
          content={t('editor.toolsMenu.bulletedList')}
          size='mini'
          inverted
          position='top center'
        />
      </Menu.Item>
      <Menu.Item
        active={currentBlockType === 'ordered-list-item'}
        onMouseDown={blockTypeHandler('ordered-list-item')}
        className='editor-toolbar-item'
      >
        <Popup
          trigger={<Icon name='ordered list' color='grey' size='small' />}
          content={t('editor.toolsMenu.numberedList')}
          size='mini'
          inverted
          position='top center'
        />
      </Menu.Item>
      <Menu.Item
        active={!!getEntityKeyInSelection('LINK')}
        onMouseDown={handleAddLink}
        className='editor-toolbar-item'
      >
        <Popup
          trigger={<Icon name='linkify' color='grey' size='small' />}
          content={`${t('editor.toolsMenu.link')} (Ctrl-K)`}
          size='mini'
          inverted
          position='top center'
        />
      </Menu.Item>
      {displayImageInsertion && handleClickImage && (
        <Menu.Item onClick={handleClickImage} className='editor-toolbar-item'>
          <Popup
            trigger={<Icon name='image' color='grey' size='small' />}
            content={t('editor.toolsMenu.image')}
            size='mini'
            inverted
            position='top center'
          />
        </Menu.Item>
      )}
    </Menu.Menu>
  );
};

export default EditorToolsMenu;
