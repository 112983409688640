import getLinkedinURL from '@/common/reveal/linkedinURL';
import GenericButton from '@/components/Common/GenericButton';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import useUserFromJWToken from '@/graphql/hooks/users/useUserFromJWToken';
import { SecondaryBlue } from '@/less/colors';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CompleteOnLinkedInLinkProps {
  onClick: () => void;
  action: any;
  isButton?: boolean;
}

const CompleteOnLinkedInLink: React.FC<CompleteOnLinkedInLinkProps> = ({
  onClick,
  action,
  isButton = false,
}) => {
  const { t } = useTranslation();
  const { profile } = useCandidateViewContext();
  const { data: userData } = useUserFromJWToken();
  const [isInTheRightPage, setIsInTheRightPage] = useState(false);
  const userPermissions = userData?.user?.permissions;

  const { url: linkedinURL, type: linkedinType } = getLinkedinURL({
    resumeData: profile?.resumeData,
    actionType: action?.type,
    actionSubtype: action?.subtype,
    userPermissions,
  });

  const completeOnLinkedinText = useMemo(() => {
    if (linkedinType === 'linkedin-recruiter') {
      return t('common.completeOnLinkedinRecruiter');
    }
    if (linkedinType === 'linkedin-salesnavigator') {
      return t('common.completeOnLinkedinSalesNavigator');
    }
    return t('common.completeOnLinkedin');
  }, [linkedinType, t]);

  if (isInTheRightPage || !linkedinURL) {
    return null;
  }

  if (window.chrome) {
    // eslint-disable-next-line no-unused-expressions
    chrome?.tabs?.query({ active: true, lastFocusedWindow: true }, (tabs) => {
      const url = (tabs?.[0]?.url ?? '').replace('www.', '');
      setIsInTheRightPage(url === linkedinURL);
    });

    // eslint-disable-next-line no-unused-expressions
    chrome?.tabs?.query({ active: true, lastFocusedWindow: true }, (tabs) => {
      const url = (tabs?.[0]?.url ?? '').replace('www.', '');
      setIsInTheRightPage(url === linkedinURL);
    });
  } else {
    console.error('Cannot do that if not on chrome');
  }

  if (isButton) {
    return (
      <a
        href={linkedinURL}
        target='_blank'
        rel='noreferrer noopener'
        aria-disabled={!linkedinURL}
        onClick={onClick}
      >
        <GenericButton>
          {completeOnLinkedinText}
          <i className='ri-external-link-line' />
        </GenericButton>
      </a>
    );
  }

  return (
    <a
      href={linkedinURL}
      target='_blank'
      className='complete-on-linkedin-link'
      rel='noreferrer noopener'
      aria-disabled={!linkedinURL}
      onClick={onClick}
      style={{ marginRight: '8px', color: SecondaryBlue }}
    >
      {completeOnLinkedinText}
      <span>
        <i
          style={{ marginLeft: '6px' }}
          className='ri-external-link-line ri-lg'
        />
      </span>
    </a>
  );
};

export default CompleteOnLinkedInLink;
