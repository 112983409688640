import _ from 'underscore';
import React from 'react';
import { Container } from 'semantic-ui-react';

import RelevantFacts from './RelevantFacts.component';
import RepositoryCard from './RepositoryCard.component';

import './GithubExtract.css';

class GithubExtract extends React.PureComponent {
  logOpenRepository(fullname) {
    if (this.props.logAction) {
      this.props.logAction({
        type: 'open-github-repository',
        profileId: this.props.profileId,
        info: { fullname },
      });
    }
  }

  render() {
    const { relevantFacts, repositories } = this.props;
    return (
      <div className='github-extract'>
        <h4>
          <img alt='github-logo' src='/images/logos/logo-github.svg'/>
          GitHub
        </h4>
        <Container fluid>
          {_.map(
            repositories,
            (
              {
                name,
                fullname,
                description,
                language,
                stargazersCount,
                forksCount,
              },
              index,
            ) => (
              <RepositoryCard
                key={index}
                name={(name || {}).text}
                fullname={(fullname || {}).text}
                description={(description || {}).text}
                language={language}
                stargazersCount={stargazersCount}
                forksCount={forksCount}
                onOpenRepository={() => this.logOpenRepository((fullname || {}).text)}
              />
            ),
          )}
          {relevantFacts && <RelevantFacts facts={relevantFacts} />}
        </Container>
      </div>
    );
  }
}

export default GithubExtract;
