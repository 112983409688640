import React from 'react';
import { useTranslation } from 'react-i18next';

const LastInteraction = ({ lastInteraction }) => {
  const { t } = useTranslation();
  const { author, date: timestampStr } = lastInteraction || {};
  const date = timestampStr ? new Date(+timestampStr) : null;
  const formattedDate = date ? t('common.shortDate', { date }) : null;
  /* if (!author?.email && !formattedDate) {
    return null;
  } */
  return (
    <div className='last-interaction'>
      {(author?.email || (author?.firstname && author?.lastname)) && (
        <div className='email'>
          {author?.email || `${author?.firstname} ${author?.lastname}`}
        </div>
      )}
      {formattedDate && <div className='date'>{formattedDate}</div>}
    </div>
  );
};

export default LastInteraction;
