import React from 'react';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import ContactFlow from '../../graphql/fragments/ContactFlow';
import withOfferContactFlowVersionId from '../offers/withOfferContactFlowVersionId';

export const getProfileContactFlowByVersion = gql`
  query getProfileContacflowByVersion($id: ID!, $versionId: String!) {
    profile(id: $id) {
      id
      contactFlow(jobContactFlowVersion: $versionId) {
        ...ContactFlow
      }
    }
  }
  ${ContactFlow}
`;

const withProfileContactFlowByVersion = graphql(
  getProfileContactFlowByVersion,
  {
    options: ({ profileId, versionId }) => ({
      variables: {
        id: profileId,
        versionId,
      },
    }),
    props: ({ data: { profile, loading } }) => ({
      profileContactFlow: profile && profile.contactFlow,
      loadingProfileContactFlow: loading,
    }),
  },
);

/**
 * Use this HOC to receive a 'profileContactFlow' prop which is synced when
 * corresponding job offer's contact flow gets updated
 * Requires profileId as input prop
 */
const withProfileContactFlow = (WrappedComponent) => {
  const ComponentWithProfileContactFlow = withProfileContactFlowByVersion(
    WrappedComponent,
  );
  const VersionIdChecker = ({ profileId, versionId, ...props }) => {
    // avoid making invalid query if mutation variables are missing
    if (!versionId || !profileId) {
      // still pass props (one of them could be truthy)
      return (
        <WrappedComponent
          profileId={profileId}
          versionId={versionId}
          {...props}
        />
      );
    }
    return (
      <ComponentWithProfileContactFlow
        profileId={profileId}
        versionId={versionId}
        {...props}
      />
    );
  };
  return withOfferContactFlowVersionId(VersionIdChecker);
};

export default withProfileContactFlow;
