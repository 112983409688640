import React, { ReactNode, useCallback, useState } from 'react';
import { GenericSegmentationItem } from '../GenericKanban/GenericKanbanColumn';
import {
  DragContextType,
  GenericSegmentationViewContext,
} from './GenericSegmentationViewContext';

interface GenericSegmentationViewContextProviderProps {
  children: ReactNode;
  selectedItemId?: string;
  segmentItemIds: string[];
  onItemSelected?: ({
    item,
    segmentItems,
  }: {
    item: GenericSegmentationItem;
    segmentItems: string[];
  }) => void;
  onSegmentItemIds: (itemIds: string[]) => void;
  onChangeSegment: (context: DragContextType) => void;
  allowDrop?: (context: DragContextType) => boolean;
  onChangeSegmentFilters?: ({
    segmentId,
    filters,
  }: {
    segmentId: string;
    filters: any;
  }) => void;
  disableDragAndDrop?: boolean;
  weekDiffInMS?: number;
  disableProfilesInteraction?: boolean;
}

export const DEFAULT_MOVE_DATA = {
  itemId: '',
  item: { id: '' },
  prevSegment: '',
  newSegment: '',
  items: [],
  cardNode: undefined,
  prevColumnNode: undefined,
  newColumnNode: undefined,
  index: -1,
};

const GenericSegmentationViewContextProvider: React.FC<GenericSegmentationViewContextProviderProps> = ({
  children,
  selectedItemId,
  segmentItemIds,
  onItemSelected,
  onSegmentItemIds,
  onChangeSegment,
  allowDrop,
  onChangeSegmentFilters,
  disableDragAndDrop,
  weekDiffInMS = 0,
  disableProfilesInteraction = false,
}) => {
  const [currentMoveData, setCurrentMoveData] = useState<
    Partial<DragContextType>
  >();
  const onDragContextChange = useCallback(
    ({
      item,
      cardNode,
      prevSegment,
      newSegment,
      items,
      prevColumnNode,
      newColumnNode,
      index,
    }: Partial<DragContextType>) => {
      setCurrentMoveData({
        item: item || { id: '' },
        prevSegment: prevSegment || '',
        newSegment: newSegment || '',
        items: items || [],
        cardNode: cardNode || undefined,
        prevColumnNode: prevColumnNode || undefined,
        newColumnNode: newColumnNode || undefined,
        index: index || 0,
      });
    },
    [],
  );

  return (
    <GenericSegmentationViewContext.Provider
      value={{
        selectedItemId,
        segmentItemIds,
        onItemSelected,
        onSegmentItemIds,
        onChangeSegment,
        onDragContextChange,
        dragContext: currentMoveData as DragContextType,
        allowDrop,
        onChangeSegmentFilters,
        disableDragAndDrop,
        weekDiffInMS,
        disableProfilesInteraction,
      }}
    >
      {children}
    </GenericSegmentationViewContext.Provider>
  );
};

export default GenericSegmentationViewContextProvider;
