/* eslint-disable no-nested-ternary */
import _ from 'underscore';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getTranslatedText } from '@/common';
import { TranslatableText } from '@/types/text';
import InfoTooltip from '@/components/Common/InfoTooltip';

import classNames from 'classnames';
import styles from './HelpText.module.less';

type HelpTextCTA = null | string | JSX.Element;

export function HelpTextPhoneCTA({ phoneNumber }: { phoneNumber: string }) {
  const formattedNumber = useMemo(() => {
    if (/^\+/.test(phoneNumber)) {
      // we have intl code
      return phoneNumber;
    }

    // We mostly stored phone numbers without formatting
    // which means they are probably french
    const parts = phoneNumber.match(/(\d){2}/g);
    if (!parts) {
      return phoneNumber;
    }

    return parts.join(' ');
  }, [phoneNumber]);

  return (
    <a href={`tel:${phoneNumber}`} className={styles.phoneNumberCTA}>
      <i className='ri-phone-fill' />
      <span>{formattedNumber}</span>
    </a>
  );
}

function HelpTextCTA({
  separator = false,
  cta,
}: {
  separator?: boolean;
  cta?: HelpTextCTA;
}) {
  if (!cta) {
    return null;
  }

  return (
    <div>
      {separator && <hr className={styles.hr} />}
      <div>{cta}</div>
    </div>
  );
}

export function HelpText({
  text,
  cta,
}: {
  text?: TranslatableText;
  cta?: HelpTextCTA;
}) {
  const { t } = useTranslation();

  if ((!text || !getTranslatedText(text)) && !cta) {
    return null;
  }

  const JSON_PREFIX = 'json:';
  const translatedText = text ? getTranslatedText(text) : '';

  let parsedSmartDescriptor = null;
  try {
    if (translatedText.indexOf(JSON_PREFIX) === 0) {
      parsedSmartDescriptor = JSON.parse(
        translatedText.slice(JSON_PREFIX.length),
      );
    }
  } catch (e) {
    console.error('Error while parsing task instruction');
  }

  const parts = !translatedText
    ? []
    : translatedText.indexOf(JSON_PREFIX) !== 0
    ? [
        {
          type: 'block',
          items: [
            {
              type: 'header',
              content: t('common.instructions'),
            },
            translatedText,
          ],
        },
      ]
    : parsedSmartDescriptor
    ? _.isArray(parsedSmartDescriptor)
      ? parsedSmartDescriptor
      : [parsedSmartDescriptor]
    : [];

  if (!parts.length) {
    return (
      <div className={classNames(styles.helpText)}>
        <HelpTextCTA separator={Boolean(text)} cta={cta} />
      </div>
    );
  }

  return (
    <div>
      {_.map(parts, (part, index) => (
        <div key={index}>
          <div
            className={classNames(
              styles.helpText,
              index > 0 && styles.helpTextIsNotFirst,
            )}
          >
            <SmartInstructionsTree smartDescription={part} />
          </div>
        </div>
      ))}
      {cta && (
        <div className={classNames(styles.helpText)}>
          <HelpTextCTA separator={Boolean(text)} cta={cta} />
        </div>
      )}
    </div>
  );
}

const SmartInstructionsTree = ({
  smartDescription,
}: {
  smartDescription: any;
}) => {
  if (_.isArray(smartDescription)) {
    return (
      <span>
        {_.map(smartDescription, (subDescription, index) => (
          <SmartInstructionsTree
            key={index}
            smartDescription={subDescription}
          />
        ))}
      </span>
    );
  }
  if (_.isObject(smartDescription)) {
    if (smartDescription.type === 'header') {
      return (
        <div className={styles.instructionsTitle}>
          <SmartInstructionsTree smartDescription={smartDescription.content} />
        </div>
      );
    }
    if (smartDescription.type === 'title') {
      return (
        <h4>
          <SmartInstructionsTree smartDescription={smartDescription.content} />
        </h4>
      );
    }
    if (smartDescription.type === 'subtitle') {
      return (
        <h5>
          <SmartInstructionsTree smartDescription={smartDescription.content} />
        </h5>
      );
    }
    if (smartDescription.type === 'tooltip') {
      return (
        <InfoTooltip style={{ cursor: 'pointer' }}>
          <SmartInstructionsTree smartDescription={smartDescription.content} />
        </InfoTooltip>
      );
    }
    if (smartDescription.type === 'block') {
      return (
        <div>
          {_.map(smartDescription.items, (item, index) => (
            <SmartInstructionsTree key={index} smartDescription={item} />
          ))}
        </div>
      );
    }
    if (smartDescription.type === 'accordion') {
      return (
        <div>
          <details>
            <summary style={{ display: 'list-item', cursor: 'pointer' }}>
              {smartDescription.summary}
            </summary>
            <SmartInstructionsTree
              smartDescription={smartDescription.details}
            />
          </details>
        </div>
      );
    }
    if (smartDescription.type === 'list') {
      return (
        <ul>
          {_.map(smartDescription.items, (item, index) => (
            <li key={index}>
              <SmartInstructionsTree smartDescription={item} />
            </li>
          ))}
        </ul>
      );
    }
    return null;
  }
  if (_.isString(smartDescription)) {
    const lines = smartDescription.split('\n');
    return (
      <span>
        {_.map(lines, (line, index) => (
          <span key={index}>
            {index > 0 ? <br /> : ''}
            {line}
          </span>
        ))}
      </span>
    );
  }
  return null;
};
