import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import ContactFlow from '../../graphql/fragments/ContactFlow';

export const createContactFlow = gql`
  mutation createContactFlow(
    $jobOfferId: ID!
    $contactFlowInput: ContactFlowInput!
  ) {
    createContactFlow(id: $jobOfferId, contactFlowInput: $contactFlowInput) {
      id
      contactFlow {
        ...ContactFlow
      }
    }
  }
  ${ContactFlow}
`;

export default graphql(createContactFlow, {
  props: ({ ownProps, mutate }) => ({
    createContactFlow: ({ contactFlowInput }) =>
      mutate({
        variables: {
          jobOfferId: ownProps.offerId,
          contactFlowInput,
        },
      }),
  }),
});
