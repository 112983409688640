import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import _ from 'underscore';

import useDebounceValue from '@/hooks/common/useDebounceValue';
import {
  GET_REVEAL_SEARCH_POOL_RESULTS,
  REVEAL_MAX_NB_RESULTS,
  REVEAL_CATEGORIES,
} from '@/hocs/searchPool/withRevealSearchPoolResults';

import { usePrefetchProfiles } from '@/context/CandidateView/useEnrichedCandidateViewProfile';
import { findRelevantCriteria } from '@/context/CandidateView/useCandidateViewContext';
import useDataUpdateSubscription from '@/graphql/dataUpdateSubscription/useDataUpdateSubscription';
import CandidatesSearchResults from './CandidatesSearchResults';
import ProjectSearchParamsContext from '../../JobsView/JobView/MoreContactsTab/ProjectSearchParamsContext';

import './CandidatesListPane.css';

const MAX_NB_PROFILES_TO_PREFETCH = 2;

const CandidatesListPane = ({
  jobId,
  searchPoolId,
  searches,
  searchText,
  setResultCount,
  hasFilters,
  showActiveSequence = false,
  shouldHideBulkActions = false,
}) => {
  // NOTE: frequent CandidatesSearchResults un-mounts are ugly
  // if (loadingSearchResultsCategories) {
  //   return (
  //     <div className='candidates-list-pane loading'>
  //       <Loader active inline size='large'/>
  //     </div>
  //   );
  // }

  // Use value debouncer to avoid multiple search to be thrown
  // at the same time
  const debouncedSearchText = useDebounceValue(searchText, 250);

  const [projectSearchParams] = useContext(ProjectSearchParamsContext) || [];
  const { includeSkipped } = projectSearchParams || {};

  const { loading, data, refetch: refetchSearchResults } = useQuery(
    GET_REVEAL_SEARCH_POOL_RESULTS,
    {
      variables: {
        searchPoolId,
        searchesCriteria: searches,
        searchText: debouncedSearchText.trim(),
        categories: REVEAL_CATEGORIES,
        maxNbResults: REVEAL_MAX_NB_RESULTS,
        missionId: jobId,
        includeSkipped,
      },
      fetchPolicy: 'network-only',
    },
  );

  useDataUpdateSubscription({
    type: 'onProfilesAdded',
    onData: () => {
      refetchSearchResults();
    },
  });

  const searchResultsCategories = data?.searchPool?.searchResults?.categories;

  const category = _.findWhere(searchResultsCategories, { categoryId: 'all' });

  const { results: searchResultItems = [], count = 0 } = category || {};

  // eslint-disable-next-line
  React.useEffect(() => setResultCount(count), [count]);

  const profilesToPrefetch = React.useMemo(() => {
    if (!searchResultItems?.length) return [];
    return _.first(
      _.pluck(searchResultItems, 'searchPoolProfileId'),
      MAX_NB_PROFILES_TO_PREFETCH,
    );
  }, [searchResultItems]);

  const relevantCriteria = findRelevantCriteria(searches);
  usePrefetchProfiles(profilesToPrefetch, { relevantCriteria });

  return (
    <div className='candidates-list-pane'>
      <CandidatesSearchResults
        jobId={jobId}
        searchPoolId={searchPoolId}
        searches={searches}
        loadingResults={loading}
        searchResultItems={searchResultItems}
        refetchSearchResults={refetchSearchResults}
        hasFilters={hasFilters}
        showActiveSequence={showActiveSequence}
        shouldHideBulkActions={shouldHideBulkActions}
        contactCategory='human'
      />
    </div>
  );
};

export default CandidatesListPane;
