import gql from 'graphql-tag';
import _ from 'underscore';
import { graphql } from '@apollo/client/react/hoc';
import TranslatableText from '@/graphql/fragments/TranslatableText';
import SweetDate from '@/graphql/fragments/SweetDate';
import CandidateClientInterestStates from '@/graphql/fragments/CandidateClientInterestStates';

export const query = gql`
  query getSearchEnrichedProfiles(
    $searchPoolId: ID!
    $searchResultItemsInput: [SearchResultItemInput]
  ) {
    searchPool(id: $searchPoolId) {
      id
      searchEnrichedProfiles(searchResultItems: $searchResultItemsInput) {
        id
        creationTimestamp
        resumeData {
          firstname
          lastname
          photoLink
          sources {
            linkedin
            github
            twitter
            website
            stackoverflow
            dribbble
            behance
          }
          mainEducation {
            schoolName
            endDate {
              ...SweetDate
            }
          }
          experiences {
            title {
              text
            }
            companyName
            startDate {
              ...SweetDate
            }
            endDate {
              ...SweetDate
            }
          }
          sourceData {
            hiresweet {
              wishes {
                locations {
                  remoteWish
                  target {
                    location {
                      ...TranslatableText
                    }
                    remoteWish
                  }
                  okWith {
                    location {
                      ...TranslatableText
                    }
                    remoteWish
                  }
                }
                jobs {
                  target {
                    ...TranslatableText
                  }
                }
                salary {
                  target
                  okWith
                }
                skills {
                  target {
                    skillId
                    relevance
                  }
                  okWith {
                    skillId
                    relevance
                  }
                }
              }
              ...CandidateClientInterestStates
            }
          }
          smartSummary {
            arguments {
              type
              ... on ReviewArgument {
                content {
                  ...TranslatableText
                }
              }
            }
          }
        }
        searchPoolState {
          id
          searchPoolId
          searchPoolProfileId
          hidden
          saved
          seen
          new
          blacklisted
          contacted
          globalFavorite
          clientProfiles {
            profileId
            jobOfferId
            jobTitle
            isUnclassified
          }
        }
        annotation {
          globalFavorite {
            value
          }
        }
        powerHourFlags {
          powerHourId
          isFlagged
        }
        firstContactDate
      }
    }
  }
  ${TranslatableText}
  ${CandidateClientInterestStates}
  ${SweetDate}
`;

// these are only default
// values depending on cardMode are set via props (in SearchResults.js)
const PROFILE_BATCH_SIZE_FIRST = 3;
const PROFILE_BATCH_SIZE = 5;

const findRelevantCriteria = ({ scores, searches }) => {
  let maxScore = (scores || [])[0] || null;
  let maxIndex = 0;
  for (let index = 0; index < scores.length; index += 1) {
    const score = scores[index];
    if (score > maxScore) {
      maxScore = score;
      maxIndex = index;
    }
  }
  return (searches || [])[maxIndex] || null;
};

const getSearchResultItemsInput = ({
  searchResultItems,
  searches,
  firstBatchSize,
  batchSize,
  profilesCount,
}) => {
  const searchResultItemsBatch = profilesCount
    ? (searchResultItems || []).slice(
        profilesCount,
        profilesCount + (batchSize || PROFILE_BATCH_SIZE),
      )
    : (searchResultItems || []).slice(
        0,
        firstBatchSize || PROFILE_BATCH_SIZE_FIRST,
      );
  const searchResultItemsInput = _.map(
    searchResultItemsBatch,
    ({ searchPoolProfileId, scores }) => {
      return {
        searchPoolProfileId,
        criteria: findRelevantCriteria({ scores, searches }),
      };
    },
  );
  return searchResultItemsInput;
};

export default graphql(query, {
  options: ({
    searchPoolId,
    searchResultItems,
    searches,
    firstBatchSize,
    batchSize,
  }) => {
    return {
      variables: {
        searchPoolId,
        searchResultItemsInput: getSearchResultItemsInput({
          searchResultItems,
          searches,
          firstBatchSize,
          batchSize,
        }),
      },
    };
  },
  props: ({ data: { loading, error, searchPool, fetchMore } }) => ({
    loadingEnrichedProfiles: loading,
    errorEnrichedProfiles: error,
    enrichedProfiles: searchPool && searchPool.searchEnrichedProfiles,
    getNextProfiles: async ({ searchResultItems, searches, profilesCount }) => {
      const updateQuery = (previousData, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousData;
        }

        const previousSearchEnrichedProfiles =
          previousData?.searchPool?.searchEnrichedProfiles || [];
        const previousSearchEnrichedProfileIds = _.pluck(
          previousSearchEnrichedProfiles,
          'id',
        );
        const newSearchEnrichedProfiles =
          fetchMoreResult?.searchPool?.searchEnrichedProfiles || [];
        // Avoid duplicates
        const newSearchEnrichedProfilesToAdd = _.filter(
          newSearchEnrichedProfiles,
          ({ id }) => !_.contains(previousSearchEnrichedProfileIds, id),
        );
        return {
          ...previousData,
          searchPool: {
            ...previousData?.searchPool,
            searchEnrichedProfiles: [
              ...previousSearchEnrichedProfiles,
              ...newSearchEnrichedProfilesToAdd,
            ],
          },
        };
      };
      return fetchMore({
        variables: {
          searchResultItemsInput: getSearchResultItemsInput({
            searchResultItems,
            searches,
            profilesCount,
          }),
        },
        updateQuery,
      });
    },
  }),
});
