import classNames from 'classnames';
import React, {
  ButtonHTMLAttributes,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';

import {
  GenericButtonColor,
  GenericButtonSize,
  GenericButtonPrimacy,
} from './types';

import styles from './GenericButton.module.less';

interface GenericButtonProps {
  primacy?: GenericButtonPrimacy;
  color?: GenericButtonColor;
  size?: GenericButtonSize;
  isIcon?: boolean;
  className?: string;
}

const ForwardedGenericButton: ForwardRefRenderFunction<
  HTMLButtonElement,
  GenericButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = (
  {
    primacy = 'primary',
    color = 'blue',
    size = 'normal',
    type = 'button',
    isIcon = false,
    className,
    children,
    disabled,
    style,
    ...props
  },
  ref,
) => (
  <button
    className={classNames(
      styles.button,
      styles[primacy],
      styles[color],
      styles[size],
      isIcon && styles.icon,
      className,
    )}
    type={type === 'submit' ? 'submit' : 'button'}
    ref={ref}
    disabled={disabled}
    style={disabled ? { ...style, pointerEvents: 'none' } : style}
    {...props}
  >
    {children}
  </button>
);

const GenericButton = forwardRef(ForwardedGenericButton);

export default GenericButton;
