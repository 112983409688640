import _ from 'underscore';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface ClientRect {
  rect: DOMRect | null;
  ref: (node: HTMLElement | null) => void;
}

const useClientRect = (): ClientRect => {
  const [rect, setRect] = useState<DOMRect | null>(null);
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(
        _.debounce((entries: ResizeObserverEntry[]) => {
          requestAnimationFrame(() => {
            _.each(entries, (entry) => setRect(entry.contentRect));
          });
        }, 100),
      ),
    [],
  );

  const ref = useCallback(
    (node: HTMLElement | null) => {
      if (node) {
        setRect(node.getBoundingClientRect());
        resizeObserver.observe(node);
      }
    },
    [resizeObserver],
  );

  useEffect(
    () => () => {
      resizeObserver.disconnect();
    },
    [resizeObserver],
  );

  return { rect, ref };
};

export default useClientRect;
