import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import PipelineDataContainer from '../../Analytics/components/Pipeline/PipelineDataContainer';
import { dateRangeOptions } from './helpers';

const Pipeline = ({ offerId, clientId, t }) => {
  const [dateRange, setDateRange] = useState('180days');

  return (
    <div className='analytics-pipeline section'>
      <h3>{t('analytics.headers.pipeline')}</h3>
      <div className='pipeline-body-container'>
        <div className='twin-selector-container'>
          <div className='twin-selector-left'>
            <div className='twin-selector-label'>{t('analytics.dropdownLabels.dateRange')}</div>
            <div className='twin-selector-dropdown'>
              <Dropdown
                fluid
                selection
                onChange={(e, { value }) => setDateRange(value)}
                value={dateRange}
                options={dateRangeOptions({ t })}
              />
            </div>
          </div>
        </div>
        <div className='analytics-pipeline-container'>
          <PipelineDataContainer clientId={clientId} offerIds={[offerId]} dateRange={dateRange} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation('translations')(Pipeline);
