import gql from 'graphql-tag';
import { FetchResult, MutationResult, useMutation } from '@apollo/client';

export const INSTANTIATE_AI_MERGE_TAGS = gql`
  mutation InstantiateAiMergeTags($input: IntantiateAiMergeTagsInput!, $searchPoolId: ID!) {
    searchPool(id: $searchPoolId) {
      instantiateAiMergeTags(input: $input) {
        errors
      }
    }
  }
`;

interface InstantiateAiMergeTagsInput {
  profileIds: string[];
}

type InstantiateAiMergeTagsHook = [
  (options: {
    variables: {
      searchPoolId: string;
      input: InstantiateAiMergeTagsInput;
    };
  }) => Promise<FetchResult<any>>,
  MutationResult<any>,
];

function useInstantiateAiMergeTags(): InstantiateAiMergeTagsHook {
  const [mutation, mutationResult] = useMutation(INSTANTIATE_AI_MERGE_TAGS);
  return [mutation, mutationResult]; // as const
}

export default useInstantiateAiMergeTags;