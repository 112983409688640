import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './LogoCircle.module.less';

interface LogoCircleProps {
  src: string;
  alt: string;
  className?: string;
}

const LogoCircle: FC<LogoCircleProps> = ({ className, src, alt }) => (
  <div className={classNames(styles.logoCircle, className)}>
    <img className={styles.logo} src={src} alt={alt} />
  </div>
);

export default LogoCircle;
