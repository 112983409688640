import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import GenericTextInput from '@/components/Common/GenericTextInput';
import { t } from 'i18next';
import React from 'react';
import { Modal } from 'semantic-ui-react';
import styles from './JobPipelineView.module.less';

interface MissionStepEditionModal {
  title: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  value: string;
  setValue: (value: string) => void;
  disabled: boolean;
  onSubmit: () => void;
  isEdition?: boolean;
}

const MissionStepEditionModal: React.FC<MissionStepEditionModal> = ({
  title,
  open,
  setOpen,
  value,
  setValue,
  disabled,
  onSubmit,
  isEdition = false,
}) => {
  return (
    <GenericModal size='mini' open={open} onClose={() => setOpen(false)}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <p className={styles.newSubstepNameLabel}>
          {t('superPipelineSettings.substepName')}
        </p>
        <GenericTextInput
          small
          fluid
          value={value}
          onValue={setValue}
          autoFocus
        />
      </Modal.Content>
      <Modal.Actions className={styles.actions}>
        <GenericButton
          size='big'
          primacy='secondary'
          onClick={() => setOpen(false)}
        >
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          size='big'
          primacy='primary'
          onClick={() => {
            onSubmit();
            setOpen(false);
          }}
          disabled={disabled}
        >
          {isEdition ? t('common.edit') : t('common.create')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default MissionStepEditionModal;
