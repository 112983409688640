import _ from 'underscore';
import { ConnectorRejectionReason, RevealProject } from '@/common/reveal';
import getFilterOptions, { FilterOptions } from '../Filters/options';

const getApplicationStatusOptions = (
  rejectionReasons?: ConnectorRejectionReason[],
) => {
  const weRejectedThemId = _.first(
    _.filter(
      rejectionReasons || [],
      ({ rejectionType }) => rejectionType?.name === 'We rejected them',
    ),
  )?.rejectionType?.id;

  const theyRejectedUsId = _.first(
    _.filter(
      rejectionReasons || [],
      ({ rejectionType }) => rejectionType?.name === 'They rejected us',
    ),
  )?.rejectionType?.id;

  const noneSpecifiedId = _.first(
    _.filter(
      rejectionReasons || [],
      ({ rejectionType }) => rejectionType?.name === 'None specified',
    ),
  )?.rejectionType?.id;

  const applicationStatusOptions = [
    {
      id: 'active',
      name: 'Active',
    },
    {
      id: 'hired',
      name: 'Hired',
    },
    {
      id: 'rejection__we-rejected-them',
      sourceId: weRejectedThemId,
      name: 'We rejected them',
      subOptions: _.map(
        _.filter(
          rejectionReasons || [],
          ({ rejectionType }) => rejectionType?.name === 'We rejected them',
        ),
        // TODO comparison is made on name instead of id
        ({ id, name }) => ({ id, name }),
      ),
    },
    {
      id: 'rejection__they-rejected-us',
      sourceId: theyRejectedUsId,
      name: 'They rejected us',
      subOptions: _.map(
        _.filter(
          rejectionReasons || [],
          ({ rejectionType }) => rejectionType?.name === 'They rejected us',
        ),
        // TODO comparison is made on name instead of id
        ({ id, name }) => ({ id, name }),
      ),
    },
    {
      id: 'rejection__none-specified',
      sourceId: noneSpecifiedId,
      name: 'Rejection - None specified',
      subOptions: _.map(
        _.filter(
          rejectionReasons || [],
          ({ rejectionType }) => rejectionType?.name === 'None specified',
        ),
        // TODO comparison is made on name instead of id
        ({ id, name }) => ({ id, name }),
      ),
    },
  ];
  return applicationStatusOptions;
};

/**
 * @param {string} projectId - Greenhouse project id
 * @param projectsWithFilterOptions value from graphql query getGreenhouseFiltersOptions
 */
const getGreenhouseOptions = ({
  projectId,
  projectsWithFilterOptions,
}: {
  projectId: string;
  projectsWithFilterOptions: RevealProject[];
}): FilterOptions => {
  return getFilterOptions({
    connectorType: 'greenhouse',
    projectId,
    projectsWithFilterOptions,
    getApplicationStatusOptions,
  });
};

export default getGreenhouseOptions;
