import React, { useState, useEffect } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { CLIENT_MINI_CAMPAIGNS } from '@/containers/Parameters/Campaigns/queries';
import EnrolledInCampaignIcon from '@/components/Reveal/Icons/EnrolledInCampaign';
import TASK_ICONS from '../TaskIcons';
import IntegrationsSynchronizationStatus from '../IntegrationsSynchronizationStatus';
import { TimelineItemSynchronizationActions } from '../TimelineItemActions';
import AuthorAndDate from '../AuthorAndDate';

const EnrolledInCampaignItem = ({
  item,
  unbiasedModeEnabled,
  profile,
  clientId,
}) => {
  const { t } = useTranslation();

  const [campaign, setCampaign] = useState(null);
  const { author, action } = item || {};
  const { firstname, lastname } = profile?.resumeData || {};
  let profileName = `${firstname || ''} ${lastname || ''}`.trim();
  profileName = unbiasedModeEnabled ? '???' : profileName;
  const { loading, data } = useQuery(CLIENT_MINI_CAMPAIGNS, {
    variables: { clientId },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!loading) {
      setCampaign(
        _.findWhere(data?.client?.campaigns, {
          id: action.campaignId,
        }),
      );
    }
    // eslint-disable-next-line
  }, [loading]);

  if (loading || !campaign) {
    return <></>;
  }
  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{
                backgroundColor: TASK_ICONS.enrolledInCampaign?.color || 'grey',
              }}
              className='icon'
            >
              <EnrolledInCampaignIcon />
            </div>
          </div>
          <div className='author'>
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  `profile.contact.timeline.enrolledInCampaign.description`,
                  {
                    authorName: `${author?.firstname ||
                      ''} ${author?.lastname || ''}`,
                    profileName,
                    campaignName: campaign.title,
                  },
                ),
              }}
            />
            <AuthorAndDate timelineItem={item}>
              <IntegrationsSynchronizationStatus
                timelineItem={item}
                clientId={clientId}
                profile={profile}
              />
            </AuthorAndDate>
          </div>
          <div className='timeline-item-actions'>
            <TimelineItemSynchronizationActions
              clientId={clientId}
              profile={profile}
              timelineItem={item}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnrolledInCampaignItem;
