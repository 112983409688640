import { createContext } from 'react';

interface UnifiedView {
  unifiedView: boolean;
}

const UnifiedViewContext = createContext<UnifiedView>({
  unifiedView: false,
});

export default UnifiedViewContext;
