import React, { FC } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import AvatarColumn from '@/components/ProfileRow/columns/AvatarColumn';
import NameAndHeadlineColumn from '@/components/ProfileRow/columns/NameAndHeadlineColumn';
import HeaderRow from '@/components/ProfileRow/HeaderRow/HeaderRow';
import usePagination from '@/hooks/ui/usePagination';
import ProfileRow from '../ProfileRow';
import ClickColumn from './ClickColumn';
import { ClickDetail } from './types';

import styles from './StatsProfileAndClickTable.module.less';

interface StatsProfileAndClickTableProps {
  profileItems: ClickDetail[];
}

const StatsProfileAndClickTable: FC<StatsProfileAndClickTableProps> = ({
  profileItems,
}) => {
  const { t } = useTranslation();

  const { pageElements, numberOfPages, PageSelector } = usePagination(
    profileItems,
  );

  return (
    <div className={styles.table}>
      <HeaderRow
        columns={[
          { id: 'avatar', title: '', className: styles.avatar },
          {
            id: 'contact',
            title: t('reveal.missions.mission.tableHeader.contact'),
            className: styles.nameAndHeadline,
          },
          {
            id: 'clickDay',
            title: t('reveal.reports.sequences.linkClicks.detailsModal.click'),
            className: styles.clickDay,
          },
        ]}
      />
      <div>
        {_.map(pageElements, ({ profileId, day, sequenceId }, index) => (
          <ProfileRow
            key={`${profileId}_${index}`}
            profileId={profileId}
            columns={[
              {
                id: 'avatar',
                component: AvatarColumn,
                className: styles.avatar,
              },
              {
                id: 'contact',
                component: NameAndHeadlineColumn,
                className: styles.nameAndHeadline,
              },
              {
                id: 'clickDay',
                component: ({ profileLoading }) => (
                  <ClickColumn
                    profileLoading={profileLoading}
                    day={day}
                    sequenceId={sequenceId}
                    className={styles.clickDay}
                  />
                ),
              },
            ]}
          />
        ))}
      </div>
      {numberOfPages > 1 && (
        <div className={styles.pagination}>
          <PageSelector />
        </div>
      )}
    </div>
  );
};

export default StatsProfileAndClickTable;
