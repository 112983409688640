import * as Sentry from '@sentry/browser';
import _, { compose } from 'underscore';
import React, { useRef, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Form } from 'semantic-ui-react';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import InputControl from '@/components/InputControl';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import { REGEXP_WEBSITE, REGEXP_COMPANY_LINKEDIN } from '@/common/validators';
import { UPDATE_RESUME_DATA_IN_SEARCH_POOL } from '@/graphql/searchPoolProfile';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { createCustomFieldsPayload } from '@/common/customFields';
import contextToProps, { ContextToProps } from '@/hocs/contextToProps';
import CustomField from '@/revealComponents/CustomField';
import GenericButton from '@/components/Common/GenericButton';

import './CandidateEditForm.css';

const ProfileSchema = yup.object().shape({
  name: yup.string(),
  email: yup.string().email(),
  email2: yup.string().email(),
  email3: yup.string().email(),
  phoneNumber: yup.string(),
  phoneNumber2: yup.string(),
  phoneNumber3: yup.string(),
  companyLinkedin: yup
    .string()
    .trim()
    .matches(REGEXP_COMPANY_LINKEDIN, { excludeEmptyString: true }),
  website: yup
    .string()
    .trim()
    .matches(REGEXP_WEBSITE, { excludeEmptyString: true }),
});

type ProfileData = {
  name: string;
  email: string;
  email2: string;
  email3: string;
  phoneNumber: string;
  phoneNumber2: string;
  phoneNumber3: string;
  companyLinkedin: string;
  customFields: Record<string, string | number>;
  contactCategory: {
    type: 'human' | 'company';
    subtypes: { id: string }[];
  };
  website: string;
};

export type CompanyEditFormProps = ContextToProps & {
  setEditMode: (editMode: boolean) => void;
  initialValues: Partial<ProfileData>;
  customFields?: CustomFieldDefinition[];
  profileId: string;
  t: TFunction;
  isGenderManuallySet: boolean;
};

const CompanyEditForm = ({
  setEditMode,
  initialValues,
  customFields = [],
  profileId,
  t,
  onShowNotification,
}: CompanyEditFormProps) => {
  const [updateResumeDataInSearchPool] = useMutation(
    UPDATE_RESUME_DATA_IN_SEARCH_POOL,
  );

  const getDefaultValues = () => ({
    name: initialValues.name || '',
    email: initialValues.email || '',
    email2: initialValues.email2 || '',
    email3: initialValues.email3 || '',
    phoneNumber: initialValues.phoneNumber || '',
    phoneNumber2: initialValues.phoneNumber2 || '',
    phoneNumber3: initialValues.phoneNumber3 || '',
    companyLinkedin: initialValues.companyLinkedin || '',
    customFields: initialValues.customFields || {},
    website: initialValues.website || '',
  });
  const methods = useForm<ProfileData>({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(ProfileSchema),
  });

  const {
    handleSubmit,
    formState: { dirtyFields },
  } = methods;

  const updatedFields = useRef<Record<string, boolean>>({});

  useEffect(() => {
    if (!updatedFields.current) {
      return;
    }
    _.each(_.keys(dirtyFields), (field) => {
      if (!updatedFields.current[field]) {
        updatedFields.current[field] = true;
      }
    });
  });

  useEffect(() => {
    methods.reset(getDefaultValues());
    // eslint-disable-next-line
  }, [profileId]);

  const publish = useDataUpdateSubscriptionPublish();

  const onSubmit = async (payload: ProfileData) => {
    const finalPayload: { [x: string]: any } = {};
    const anyPayload = payload as any;
    _.each(_.keys(payload), (key: string) => {
      if (updatedFields.current[key]) {
        finalPayload[key] = anyPayload[key];
      }
    });

    const {
      companyLinkedin,
      website,
      customFields: customFieldsData,
      ...rest
    } = finalPayload;

    try {
      let customFieldsPayload;
      if (customFieldsData) {
        customFieldsPayload = createCustomFieldsPayload(
          finalPayload.customFields,
          customFields,
        );
      }

      await updateResumeDataInSearchPool({
        variables: {
          searchPoolId: 'reveal',
          input: {
            id: profileId,
            data: {
              ...rest,
              ...(customFields && {
                customFields: customFieldsPayload,
              }),
              sources: {
                companyLinkedin: companyLinkedin || '',
                website: website || '',
              },
            },
          },
        },
      });

      publish('onProfileResumeDataUpdate', { id: profileId });
      setEditMode(false);
      onShowNotification({
        level: 'success',
        message: t('reveal.profileEdition.success'),
      });
    } catch (e) {
      console.error({ e });
      onShowNotification({
        level: 'error',
        message: t('reveal.profileEdition.error'),
      });
      Sentry.captureException(e);
    }
  };

  return (
    <FormProvider {...methods}>
      <Form className='candidate-edit-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='candidate-edit-header'>
          <h2 className='title'>{t('reveal.profileEdition.title')}</h2>
          <div className='actions'>
            <GenericButton
              primacy='secondary'
              onClick={() => setEditMode(false)}
            >
              {t('reveal.profileEdition.cancel')}
            </GenericButton>
            <GenericButton type='submit'>
              {t('reveal.profileEdition.save')}
            </GenericButton>
          </div>
        </div>
        <div className='candidate-edit-content'>
          <div className='candidate-edit-standard-fields'>
            <InputControl
              label={t('reveal.profileEdition.name')}
              errorMessage={t('reveal.profileEdition.errors.name')}
              name='name'
              horizontal
            />
            <InputControl
              label={t('reveal.profileEdition.email')}
              errorMessage={t('reveal.profileEdition.errors.email')}
              name='email'
              horizontal
            />

            <InputControl
              label={t('reveal.profileEdition.email2')}
              errorMessage={t('reveal.profileEdition.errors.email2')}
              name='email2'
              horizontal
              legend={t('reveal.profileEdition.useAsMainEmail')}
              clickableLegend
              onLegendClicked={() => {
                const tmp = methods.getValues().email;
                methods.setValue('email', methods.getValues().email2);
                methods.setValue('email2', tmp);
              }}
            />

            <InputControl
              label={t('reveal.profileEdition.email3')}
              errorMessage={t('reveal.profileEdition.errors.email3')}
              name='email3'
              horizontal
              legend={t('reveal.profileEdition.useAsMainEmail')}
              clickableLegend
              onLegendClicked={() => {
                const tmp = methods.getValues().email;
                methods.setValue('email', methods.getValues().email2);
                methods.setValue('email3', tmp);
              }}
            />
            <InputControl
              label={t('reveal.profileEdition.phoneNumber')}
              errorMessage={t('reveal.profileEdition.errors.phoneNumber')}
              name='phoneNumber'
              horizontal
            />
            <InputControl
              label={t('reveal.profileEdition.phoneNumber2')}
              errorMessage={t('reveal.profileEdition.errors.phoneNumber2')}
              name='phoneNumber2'
              horizontal
              legend={t('reveal.profileEdition.useAsMainPhone')}
              clickableLegend
              onLegendClicked={() => {
                const tmp = methods.getValues().phoneNumber;
                methods.setValue(
                  'phoneNumber',
                  methods.getValues().phoneNumber2,
                );
                methods.setValue('phoneNumber2', tmp);
              }}
            />
            <InputControl
              label={t('reveal.profileEdition.phoneNumber3')}
              errorMessage={t('reveal.profileEdition.errors.phoneNumber3')}
              name='phoneNumber3'
              horizontal
              legend={t('reveal.profileEdition.useAsMainPhone')}
              clickableLegend
              onLegendClicked={() => {
                const tmp = methods.getValues().phoneNumber;
                methods.setValue(
                  'phoneNumber',
                  methods.getValues().phoneNumber3,
                );
                methods.setValue('phoneNumber3', tmp);
              }}
            />
            <InputControl
              label={t('reveal.profileEdition.companyLinkedin')}
              errorMessage={t('reveal.profileEdition.errors.companyLinkedin')}
              name='companyLinkedin'
              horizontal
            />
            <InputControl
              label={t('reveal.profileEdition.website')}
              errorMessage={t('reveal.profileEdition.errors.website')}
              name='website'
              horizontal
            />
          </div>
          <div className='candidate-edit-custom-fields'>
            {(customFields || []).map((customField) => (
              <CustomField
                key={customField.id}
                name={`customFields.${customField.id}`}
                definition={customField}
              />
            ))}
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};

export default compose(
  contextToProps,
  withTranslation('translations'),
)(CompanyEditForm);
