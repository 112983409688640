import React, { useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { GET_LINKEDIN_ACCOUNTS } from '@/graphql/linkedAccounts';
import GenericButton from '@/components/Common/GenericButton';
import SettingsLayout from '@/containers/SettingsLayout';
import LinkedinAccountsTable from './LinkedinAccountsTable/index';
import { LinkedInAccount } from '../../../types/LinkedInAccount';
import DeleteLinkedinAccountModal from './DeleteLinkedinAccountModal';
import LinkedinAccountEditorModal from './LinkedinAccountEditorModal/index';
import { useNavApplicationMode } from '../../TopBar/Header/NavSwitch.hook';
import TargetAccountModal from './TargetAccountModal';

import styles from './LinkedAccountsSettings.module.less';
import '../Settings/Senders/NewSenderModal/NewSenderModal.css';

interface LinkedAccountsSettingsProps {
  clientId: string;
}

const LinkedAccountsSettings: React.FC<LinkedAccountsSettingsProps> = ({
  clientId,
}) => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpenId, setEditModalOpenId] = useState<string | null>(null);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [
    shouldShowConnectModalOnClose,
    setShouldShowConnectModalOnClose,
  ] = useState(false);
  const [deleteModalOpenId, setDeleteModalOpenId] = useState<string | null>(
    null,
  );
  const [addressToConnect, setAddressToConnect] = useState('');

  const { t } = useTranslation();
  const { applicationMode, updateApplicationMode } = useNavApplicationMode();

  const { data: linkedInAccountsData, refetch } = useQuery(
    GET_LINKEDIN_ACCOUNTS,
    {
      variables: {
        clientId,
      },
    },
  );

  if (applicationMode !== 'reveal') {
    updateApplicationMode('reveal');
  }

  const linkedinAccounts: LinkedInAccount[] =
    linkedInAccountsData?.client?.linkedinAccounts;

  const onEditLinkedinAccount = ({
    linkedinAccountId,
  }: {
    linkedinAccountId: string;
  }) => {
    setEditModalOpenId(linkedinAccountId);
  };

  const onDeleteLinkedinAccount = ({
    linkedinAccountId,
  }: {
    linkedinAccountId: string;
  }) => {
    setDeleteModalOpenId(linkedinAccountId);
  };

  const linkedinAccountToEdit =
    (editModalOpenId &&
      _.findWhere(linkedinAccounts, { id: editModalOpenId })) ||
    null;

  const linkedinAccountToDelete = deleteModalOpenId
    ? _.findWhere(linkedinAccounts, { id: deleteModalOpenId })
    : null;

  return (
    <SettingsLayout
      currentPage='linkedin'
      clientId={clientId}
      className={styles.settingsContainer}
    >
      <div className={styles.linkedAccountsSection}>
        <div className={styles.settingsHeader}>
          <h1>{t('settings.linkedinAccounts.title')}</h1>
        </div>
        <h2>{t('settings.configuration')}</h2>
        <div className={classNames(styles.section, styles.visible)}>
          <div className={styles.inputContainer}>
            <div className={styles.inputLabel}>
              {t('settings.linkedinAccounts.subtitle')}
            </div>
            <div className={styles.inputDescription}>
              {t('settings.linkedinAccounts.description')}
            </div>

            <div className={styles.linkedinAccountSettingsGiven}>
              <LinkedinAccountsTable
                linkedinAccounts={linkedinAccounts}
                onEditLinkedinAccount={onEditLinkedinAccount}
                withEdit
                onDeleteLinkedinAccount={onDeleteLinkedinAccount}
                onShowConnectModal={setShowConnectModal}
                setAddressToConnect={setAddressToConnect}
              />
            </div>
            <GenericButton onClick={() => setAddModalOpen(true)}>
              {t('settings.linkedinAccounts.newAccount')}
            </GenericButton>
            {(addModalOpen || !!linkedinAccountToEdit) && (
              <LinkedinAccountEditorModal
                open
                clientId={clientId}
                account={linkedinAccountToEdit}
                setAddressToConnect={setAddressToConnect}
                setShouldShowConnectModalOnClose={
                  setShouldShowConnectModalOnClose
                }
                closeModal={() => {
                  setEditModalOpenId(null);
                  setAddModalOpen(false);
                  if (shouldShowConnectModalOnClose) {
                    setShowConnectModal(true);
                    setShouldShowConnectModalOnClose(false);
                  }
                  setTimeout(() => {
                    refetch();
                  }, 7000);
                }}
              />
            )}
            <DeleteLinkedinAccountModal
              linkedinAccount={linkedinAccountToDelete}
              clientId={clientId}
              closeModal={() => setDeleteModalOpenId(null)}
              open={!!linkedinAccountToDelete}
            />
            {showConnectModal && (
              <TargetAccountModal
                showConnectModal={showConnectModal}
                setShowConnectModal={setShowConnectModal}
                addressToConnect={addressToConnect}
                refetch={refetch}
              />
            )}
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
};

export default LinkedAccountsSettings;
