import gql from 'graphql-tag';

import Enrichment from './Enrichment';

export default gql`
    fragment TextWithEnrichments on TextWithEnrichments {
        text
        enrichments {
            ...Enrichment
        }
    }
  ${Enrichment}
`;
