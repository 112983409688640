import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import JobPosting from '../../graphql/fragments/JobPosting';

export const deleteJobPostingMutation = gql`
  mutation deleteJobPosting($input: DeleteJobPostingInput!) {
    deleteJobPosting(input: $input) {
      jobOffer {
        id
        jobPostings {
          ...JobPosting
        }
      }
    }
  }
  ${JobPosting}
`;

export default graphql(deleteJobPostingMutation, {
  props: ({ mutate }) => ({
    deleteJobPosting: ({ input }) => mutate({ variables: { input } }),
  }),
});
