import { MetaTaskFormatFormField } from '@/revealComponents/ExplicitTasks/MetaTaskForm/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'semantic-ui-react';
import Dots from '@/components/Common/Icons/Dots';
import DropdownPanel from '@/components/Common/DropdownPanel';
import DropdownMenuItem from '@/components/Common/DropdownMenuItem';
import _ from 'underscore';
import GenericDropdown from '@/components/Common/GenericDropdown';
import ArrowUp from '@/components/Reveal/Icons/ArrowUp';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import DropdownControlsContext from '@/context/DropdownControlsContext';
import Delete from '@/components/Reveal/Icons/Delete';
import styles from './CreateMetaTaskForm.module.less';

export interface MetaTaskFieldFormProps {
  field: MetaTaskFormatFormField;
  onUpdateField: ({
    fieldId,
    newField,
  }: {
    fieldId: string;
    newField: MetaTaskFormatFormField;
  }) => void;
  onRemoveField?: ({ fieldId }: { fieldId: string }) => void;
  onMoveDownward?: ({ fieldId }: { fieldId: string }) => void;
  onMoveUpward?: ({ fieldId }: { fieldId: string }) => void;
  index: number;
  isLast?: boolean;
}

const MetaTaskInlineTextFieldForm: React.FC<MetaTaskFieldFormProps> = ({
  field,
  onUpdateField,
  onRemoveField,
  onMoveDownward,
  onMoveUpward,
  index,
  isLast,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Field className={styles.modalFormField}>
      <div className={styles.formFieldContainer}>
        <div className={styles.fieldTitle}>
          <div className={styles.fieldTitleInfo}>
            {t('reveal.modals.metaTaskModal.field')} {index}
            <span className='pill-message blue mini mini small-radius'>
              {t(`reveal.modals.metaTaskModal.${field.type === 'inline-text' ? 'inline-text' : 'multi-line-text'}`)}
            </span>
          </div>
          <div className={styles.fieldTitleActions}>
            <GenericDropdown
              position='right'
              trigger={
                <DropdownControlsContext.Consumer>
                  {({ toggleDropdown }) => (
                    <div style={{cursor: 'pointer'}} onClick={toggleDropdown}>
                      <Dots />
                    </div>
                  )}
                </DropdownControlsContext.Consumer>
              }
            >
              <DropdownPanel className='dropdown-panel'>
                {!isLast ? (
                  <DropdownMenuItem
                    onClick={() => onMoveDownward &&
                      onMoveDownward({
                        fieldId: field.id,
                      })
                    }
                  >
                    <span className='dropdown-menu-item-icon'>
                      <ArrowDown />
                    </span>
                    {t('settings.departments.moveDownward')}
                  </DropdownMenuItem>
                ) : (
                  ''
                )}
                {index > 1 ? (
                  <DropdownMenuItem
                    onClick={() => onMoveUpward &&
                      onMoveUpward({
                        fieldId: field.id,
                      })
                    }
                  >
                    <span className='dropdown-menu-item-icon'>
                      <ArrowUp />
                    </span>
                    {t('settings.departments.moveUpward')}
                  </DropdownMenuItem>
                ) : (
                  ''
                )}
                <DropdownMenuItem
                  onClick={() => onRemoveField &&
                    onRemoveField({
                      fieldId: field.id,
                    })
                  }
                >
                  <span className='dropdown-menu-item-icon'>
                    <Delete />
                  </span>
                  {t('settings.departments.delete')}
                </DropdownMenuItem>
              </DropdownPanel>
            </GenericDropdown>
          </div>
        </div>
        <div className={styles.inlineTextFieldQuestionContainer}>
          <Input
            size='small'
            className={styles.modalFormInput}
            value={field?.title?.default || ''}
            placeholder={
              field?.title?.default ||
              t('reveal.modals.metaTaskModal.formPlaceholder1')
            }
            onChange={(e) =>
              onUpdateField({
                fieldId: field.id,
                newField: {
                  ...field,
                  title: { default: e.target.value },
                },
              })
            }
          />
        </div>
      </div>
    </Form.Field>
  );
};

export default MetaTaskInlineTextFieldForm;
