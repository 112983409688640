import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import useClientId from '@/hooks/router/useClientId';
import { SnoozeReasonStatsWithDateDetails } from '@/types/statistics/snoozeReasonsOverview';

import { GenericStatsFilterInput } from '../common';

const getSnoozeReasonsOverviewWithDateDetails = gql`
  query getSnoozeReasonsOverviewWithDateDetails(
    $clientId: ID!
    $missionsFilter: GenericStatsFilterInput
    $authorEmailsFilter: GenericStatsFilterInput
    $startDate: Date
    $endDate: Date
  ) {
    client(id: $clientId) {
      id
      statistics {
        snoozeReasonsOverview(
          missionsFilter: $missionsFilter
          authorEmailsFilter: $authorEmailsFilter
          startDate: $startDate
          endDate: $endDate
        ) {
          snoozeReasonId
          snoozeReasonTitle
          details {
            date
          }
        }
      }
    }
  }
`;

type GetSnoozeReasonsOverviewWithDateDetailsData = {
  client: {
    id: string;
    statistics: {
      snoozeReasonsOverview: SnoozeReasonStatsWithDateDetails[];
    };
  };
};

type GetSnoozeReasonsOverviewWithDateDetailsVariables = {
  clientId: string;
  missionsFilter?: GenericStatsFilterInput;
  authorEmailsFilter?: GenericStatsFilterInput;
  startDate?: string; // YYYY-MM-DD
  endDate?: string; // YYYY-MM-DD
};

type UseSnoozeReasonsOverviewWithDateDetailsInput = {
  missionsFilter?: GenericStatsFilterInput;
  authorEmailsFilter?: GenericStatsFilterInput;
  startDate?: string;
  endDate?: string;
  queryOptions?: QueryHookOptions<
    GetSnoozeReasonsOverviewWithDateDetailsData,
    GetSnoozeReasonsOverviewWithDateDetailsVariables
  >;
};

interface UseSnoozeReasonsOverviewWithDateDetailsResult
  extends QueryResult<
    GetSnoozeReasonsOverviewWithDateDetailsData,
    GetSnoozeReasonsOverviewWithDateDetailsVariables
  > {
  snoozeReasonsOverviewWithDateDetails: SnoozeReasonStatsWithDateDetails[];
}

function useSnoozeReasonsOverviewWithDateDetails({
  missionsFilter,
  authorEmailsFilter,
  startDate,
  endDate,
  queryOptions = {},
}: UseSnoozeReasonsOverviewWithDateDetailsInput = {}): UseSnoozeReasonsOverviewWithDateDetailsResult {
  const clientId = useClientId();
  const query = useQuery<
    GetSnoozeReasonsOverviewWithDateDetailsData,
    GetSnoozeReasonsOverviewWithDateDetailsVariables
  >(getSnoozeReasonsOverviewWithDateDetails, {
    ...queryOptions,
    variables: {
      clientId,
      missionsFilter,
      authorEmailsFilter,
      startDate,
      endDate,
    },
  });

  const snoozeReasonsOverviewWithDateDetails =
    query.data?.client.statistics.snoozeReasonsOverview || [];

  return { ...query, snoozeReasonsOverviewWithDateDetails };
}

export default useSnoozeReasonsOverviewWithDateDetails;
