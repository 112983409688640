import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { CampaignDailyCohort } from '@/types/statistics/campaigns';

const GET_CAMPAIGNS_DAILY_COHORTS = gql`
  query getCampaignsDailyCohorts($clientId: ID!, $campaignIds: [ID!]) {
    client(id: $clientId) {
      id
      statistics {
        campaigns(campaignIds: $campaignIds) {
          dailyCohorts {
            day
            details {
              campaignId
              type
              count
            }
          }
        }
      }
    }
  }
`;

interface GetCampaignsDailyCohortsData {
  client: {
    id: string;
    statistics: {
      campaigns: {
        dailyCohorts: CampaignDailyCohort[];
      };
    };
  };
}

interface GetCampaignsDailyCohortsVariables {
  clientId: string;
  campaignIds?: string[];
}

interface UseCampaignsDailyCohortsResult
  extends QueryResult<
    GetCampaignsDailyCohortsData,
    GetCampaignsDailyCohortsVariables
  > {
  dailyCohorts?: CampaignDailyCohort[];
}

interface UseCampaignsDailyCohortsInput
  extends GetCampaignsDailyCohortsVariables {
  queryOptions?: QueryHookOptions<
    GetCampaignsDailyCohortsData,
    GetCampaignsDailyCohortsVariables
  >;
}

const useCampaignsDailyCohorts = ({
  clientId,
  campaignIds,
  queryOptions = {},
}: UseCampaignsDailyCohortsInput): UseCampaignsDailyCohortsResult => {
  const query = useQuery<
    GetCampaignsDailyCohortsData,
    GetCampaignsDailyCohortsVariables
  >(GET_CAMPAIGNS_DAILY_COHORTS, {
    ...queryOptions,
    variables: {
      clientId,
      campaignIds,
    },
  });

  const { dailyCohorts } = query.data?.client.statistics.campaigns || {};
  return { ...query, dailyCohorts };
};

export default useCampaignsDailyCohorts;
