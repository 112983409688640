import React from 'react';
import _ from 'underscore';
import i18next from 'i18next';
import { Table } from 'semantic-ui-react';
import { ColumnDisplay } from '@/graphql/hooks/users/useUserDisplayPreferences';
import { ISearchPoolJob } from '@/graphql/searchPoolJobs';
import SortingDropdown from '@/components/SortingDropdown';

import { ProjectStats } from '../../components/Projects/Row/ProjectRow/types';
import { ATSDefinitionGetter, ColumnRenderer } from '../types';

import styles from './columns.module.less';

export type AdventureColumnIds = 'temps-in-job' | 'occupation-rate';

interface AdventureColumn extends ColumnDisplay {
  id: AdventureColumnIds;
}

export const COLUMNS: AdventureColumn[] = [
  {
    id: 'temps-in-job',
    hidden: false,
    targetOrderIndex: 1,
    available: true,
    name: 'Ressources en poste',
  },
  {
    id: 'occupation-rate',
    hidden: false,
    targetOrderIndex: 2,
    available: true,
    name: "% d'occupation",
  },
];

export function getColumnDefinitions() {
  return COLUMNS;
}

export const getValueRenderers: (arg: {
  job: ISearchPoolJob;
  stats: ProjectStats;
}) => Record<AdventureColumnIds, ColumnRenderer> = ({ job, stats }) => {
  return {
    'temps-in-job': () => {
      const stat = _.findWhere(stats?.atsMetadata || [], { key: 'tempsInJob' });
      return <Table.Cell key='temps-in-job'>{stat?.value || '-'}</Table.Cell>;
    },
    'occupation-rate': () => {
      const formatter = new Intl.NumberFormat(i18next.resolvedLanguage, {
        style: 'percent',
        maximumFractionDigits: 0,
      });
      const stat = _.findWhere(stats?.atsMetadata || [], { key: 'tempsInJob' });
      const tempsInJob = stat?.value ? Number.parseInt(stat.value, 10) : 0;
      if (!tempsInJob || !job) {
        return <Table.Cell key='occupation-rate'>0%</Table.Cell>;
      }

      return (
        <Table.Cell key='occupation-rate'>
          {formatter.format(tempsInJob / job.profilesCount)}
        </Table.Cell>
      );
    },
  };
};

export const getHeaderRenderers: (arg: {
  job: ISearchPoolJob;
  stats: ProjectStats;
}) => Record<AdventureColumnIds, ColumnRenderer> = () => {
  return {
    'temps-in-job': (
      _column,
      { columnSorting, sortBy, onUpdateSortBy } = {},
    ) => {
      return (
        <Table.HeaderCell key='temps-in-job'>
          {columnSorting ? (
            <span className={styles.columnSortTrigger}>
              <SortingDropdown
                sortType='numerical'
                triggerText='Ressources en poste'
                selectorId='temps-in-job'
                selectedSortingOption={
                  _.findWhere(sortBy || [], {
                    key: 'temps-in-job',
                  })?.order as 'ascending' | 'descending'
                }
                onChangeSortingOption={({ selectorId, value }) =>
                  onUpdateSortBy({
                    selectorId,
                    order: value as 'ascending' | 'descending',
                  })
                }
              />
            </span>
          ) : (
            'Ressources en poste'
          )}
        </Table.HeaderCell>
      );
    },
    'occupation-rate': (
      _column,
      { columnSorting, sortBy, onUpdateSortBy } = {},
    ) => {
      return (
        <Table.HeaderCell key='occupation-rate'>
          {columnSorting ? (
            <span className={styles.columnSortTrigger}>
              <SortingDropdown
                sortType='numerical'
                triggerText='% Occupation'
                selectorId='percent-occupied'
                selectedSortingOption={
                  _.findWhere(sortBy || [], {
                    key: 'percent-occupied',
                  })?.order as 'ascending' | 'descending'
                }
                onChangeSortingOption={({ selectorId, value }) =>
                  onUpdateSortBy({
                    selectorId,
                    order: value as 'ascending' | 'descending',
                  })
                }
              />
            </span>
          ) : (
            '% Occupation'
          )}
        </Table.HeaderCell>
      );
    },
  };
};

export const AdventureColumns: ATSDefinitionGetter = {
  getColumnDefinitions,
  getValueRenderers,
  getHeaderRenderers,
};
