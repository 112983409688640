import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import GenericModal from '@/components/Common/GenericModal';

interface ModalComponentProps {
  title?: string;
  submitButton?: React.ReactElement;
  cancelButton?: React.ReactElement;
}

const ModalComponent: React.FC<ModalComponentProps> = ({
  title,
  children,
  submitButton,
  cancelButton,
}) => {
  const { t } = useTranslation('translations');

  return (
    <GenericModal open size='small'>
      <Modal.Header>
        {title || t('contactFlow.reapplySequence.reapplySequence')}
      </Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions>
        {cancelButton}
        {submitButton || null}
      </Modal.Actions>
    </GenericModal>
  );
};

export default ModalComponent;
