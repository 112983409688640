import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

const ProfileInATSFilter = ({ onUpdate, value }) => {
  const { t } = useTranslation();
  const { yes, no } = value || {};

  const onChangeYes = () => {
    onUpdate({
      profileInATS: {
        ...value,
        yes: !yes ? true : null,
        no: null,
      },
    });
  };
  const onChangeNo = () => {
    onUpdate({
      profileInATS: {
        ...value,
        no: !no ? true : null,
        yes: null,
      },
    });
  };
  return (
    <Button.Group className='hs-button-group'>
      <Button onClick={onChangeYes} className={yes && !no ? 'selected' : ''}>
        {t('common.yes')}
      </Button>
      <Button onClick={onChangeNo} className={no && !yes ? 'selected' : ''}>
        {t('common.no')}
      </Button>
    </Button.Group>
  );
};

export default ProfileInATSFilter;
