import React, { FC } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useClientId from '@/hooks/router/useClientId';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';

type NewProjectButtonProps = {
  onClick: (buttonName: string) => void;
};

const NewProjectButton: FC<NewProjectButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { permissions } = useClientPermissions(clientId);

  const { salesProjects } = permissions || {};

  if (!salesProjects) {
    return (
      <Dropdown.Item
        as={Link}
        to={`/client/${clientId}/reveal/projects/recruiting?newJobModal=true`}
        onClick={() => {
          onClick('new-project');
        }}
      >
        <i className='menu-icon ri-briefcase-4-fill' />
        <span>{t('header.new.project')}</span>
      </Dropdown.Item>
    );
  }

  return (
    <>
      <Dropdown.Item
        as={Link}
        to={`/client/${clientId}/reveal/projects/recruiting?newJobModal=true`}
        onClick={() => {
          onClick('new-hiring-project');
        }}
      >
        <i className='menu-icon ri-briefcase-4-fill' />
        <span>{t('header.new.hiringProject')}</span>
      </Dropdown.Item>
      <Dropdown.Item
        as={Link}
        to={`/client/${clientId}/reveal/projects/sales?newJobModal=true`}
        onClick={() => {
          onClick('new-sales-project');
        }}
      >
        <i className='menu-icon ri-briefcase-4-fill' />
        <span>{t('header.new.salesProject')}</span>
      </Dropdown.Item>
    </>
  );
};

export default NewProjectButton;
