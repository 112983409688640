import { useQuery, QueryHookOptions } from '@apollo/client';
import GET_CLIENT_ATS_FILTERS_OPTIONS from '../../clientAtsFilterOptions';

const useAtsFilterOptions = (clientId: string, options?: QueryHookOptions) => {
  return useQuery(GET_CLIENT_ATS_FILTERS_OPTIONS, {
    ...options,
    variables: { clientId },
  });
};

export default useAtsFilterOptions;
