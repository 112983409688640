import _ from 'underscore';

import { Offer } from '@/types/offer';
import { User } from '@/types/user';
import { removeAccents } from '@/common/utils/string';

export const isOfferAttachedToUser = ({
  offer,
  user,
}: {
  offer?: Offer;
  user?: User;
}): boolean =>
  !!user?.email &&
  (_.pluck(offer?.followers || [], 'email').indexOf(user?.email) >= 0 ||
    offer?.owner?.email === user.email ||
    offer?.owner?.email === user.email);

export const sortOffersForUserView = (user: User, offers: Offer[]): Offer[] =>
  _.chain(offers)
    .sortBy(({ title }) => removeAccents(title.toLowerCase()))
    .sortBy((offer) => (isOfferFavorite(user, offer) ? 0 : 1))
    .sortBy(({ isHold }) => (isHold ? 1 : 0))
    .value();

export const isOfferFavorite = (user: User, offer: Offer): boolean =>
  _.some(offer?.followers || [], ({ email }) => email === user?.email);
