import gql from 'graphql-tag';

export default gql`
  fragment GreenhouseApplicationStatus on GreenhouseStatus {
    sourceStatusId
    stage {
      sourceStageId
      sourceStageName
    }
    rejection {
      category {
        type
        name
        sourceRejectionCategoryId
        sourceRejectionCategoryName
      }
      reason {
        sourceRejectionReasonId
        sourceRejectionReasonName
      }
      lastStage {
        sourceStageId
        sourceStageName
      }
    }
  }
`;
