import React, { useCallback, useContext, useMemo } from 'react';
import _, { compose } from 'underscore';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

import UnifiedViewContext from '@/containers/Parameters/Offers/UnifiedViewContext';
import useProfilesToFollowup from '@/graphql/hooks/profiles/useProfilesToFollowup';
import { getFollowupCount } from '@/common/helpers/followup';
import useClientTemplates from '@/graphql/hooks/templates/useClientTemplates';
import { hasTemplateDefaultFields } from '@/common/helpers/template';

import { getJobTemplatesId } from '../common/jobs';
import withUserFromJWToken from '../hocs/users/withUserFromJWToken';
import withActionLogger from '../hocs/withActionLogger';
import withClient from '../hocs/clients/withClient';
import OfferSettingsDropdown from './OfferSettingsDropdown';
import { SettingsIcon, StackIcon, BarChartIcon } from '../assets/icons';
import { getSequenceButtonText } from './helpers';
import Kanban from './Reveal/Icons/Kanban';
import DefaultTemplateFieldsNudge from './DefaultTemplateFieldsNudge';

import './OfferSettings.css';
import ButtonLink from './Common/GenericButton/ButtonLink';

const OfferSettings = ({
  offer,
  clientId,
  client,
  from,
  pageId,
  onLogAction,
  t,
}) => {
  const { id } = offer || {};
  const { unifiedView } = useContext(UnifiedViewContext);
  const {
    profilesToFollowup,
    loading: followupLoading,
  } = useProfilesToFollowup({ clientId });

  const followupCount = useMemo(
    () => getFollowupCount(id, profilesToFollowup),
    [profilesToFollowup, id],
  );

  const logAction = useCallback(
    ({ type }) => {
      onLogAction({
        type,
        info: {
          clientId,
          jobOfferId: id,
          from: `${from}-offer-settings-buttons`,
        },
      });
    },
    [onLogAction, clientId, id, from],
  );

  const onToggleCriteria = useCallback(
    (e) => {
      e.stopPropagation();
      logAction({ type: 'click-offer-criteria' });
    },
    [logAction],
  );

  const onToggleSequence = useCallback(
    (e) => {
      e.stopPropagation();
      logAction({ type: 'click-offer-sequences' });
    },
    [logAction],
  );

  const onToggleAnalytics = useCallback(
    (e) => {
      e.stopPropagation();
      logAction({ type: 'click-offer-analytics' });
    },
    [logAction],
  );

  const { data: templatesData } = useClientTemplates(clientId);

  const templateMapById = useMemo(() => {
    const result = {};

    _.each(templatesData?.client.templates || [], (template) => {
      result[template.id] = template;
    });

    return result;
  }, [templatesData]);

  if (!offer || offer?.isHiresweetWatch || offer?.isUnclassified) {
    return null;
  }

  const jobTemplatesId = getJobTemplatesId(offer);
  const jobHasNoTemplatesLinked = _.isEmpty(jobTemplatesId);
  const jobHasNoFollowupsLinked = jobTemplatesId.length < 2;

  const jobHasTemplateDefaultFields = _.reduce(
    jobTemplatesId,
    (acc, templateId) => {
      if (acc) {
        return true;
      }
      const template = templateMapById[templateId];
      return hasTemplateDefaultFields(template?.body || '');
    },
    false,
  );

  const pendingProfilesUrl = `/client/${clientId}/jobs/${id}`;
  return (
    <div className='offer-settings'>
      {unifiedView && (
        <div className='offer-settings-item'>
          <ButtonLink
            primacy='secondary'
            color='grey'
            to={`/client/${clientId}/discover/followup/${id}`}
          >
            <span className='offer-settings-icon'>
              <Kanban />
            </span>
            <span>
              {t('header.parameters.offersFollowup')}
              {!followupLoading && ` (${followupCount})`}
            </span>
          </ButtonLink>
        </div>
      )}

      {(offer || {}).criteriaEditionAllowed && (
        <div className='offer-settings-item'>
          <ButtonLink
            primacy='secondary'
            color={pageId === 'criteria' ? 'blue' : 'grey'}
            to={
              pageId === 'criteria'
                ? pendingProfilesUrl
                : `/client/${clientId}/jobs/${id}/criteria`
            }
            onClick={onToggleCriteria}
          >
            <SettingsIcon className='offer-settings-icon' />
            <span>{t('header.parameters.criteria')}</span>
          </ButtonLink>
        </div>
      )}

      <div className='offer-settings-item'>
        <ButtonLink
          primacy='secondary'
          color={
            pageId === 'sequence'
              ? 'blue'
              : jobHasNoTemplatesLinked || jobHasNoFollowupsLinked
              ? 'red'
              : 'grey'
          }
          to={
            pageId === 'sequence'
              ? pendingProfilesUrl
              : `/client/${clientId}/jobs/${id}/sequence`
          }
          negative={jobHasNoTemplatesLinked || jobHasNoFollowupsLinked}
          onClick={onToggleSequence}
        >
          <StackIcon className='offer-settings-icon' />
          <span>
            {getSequenceButtonText(
              jobHasNoTemplatesLinked,
              jobHasNoFollowupsLinked,
              t,
            )}
          </span>
        </ButtonLink>
        {jobHasTemplateDefaultFields && <DefaultTemplateFieldsNudge />}
      </div>

      {((client || {}).permissions || {}).analytics && (
        <div className='offer-settings-item'>
          <ButtonLink
            primacy='secondary'
            color={pageId === 'reports' ? 'blue' : 'grey'}
            to={
              pageId === 'reports'
                ? pendingProfilesUrl
                : `/client/${clientId}/jobs/${id}/reports`
            }
            onClick={onToggleAnalytics}
          >
            <BarChartIcon className='offer-settings-icon' />
            <span>{t('header.parameters.analytics')}</span>
          </ButtonLink>
        </div>
      )}
      <div
        className='offer-settings-item'
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <OfferSettingsDropdown clientId={clientId} offerId={id} from={from} />
      </div>
    </div>
  );
};

export default compose(
  withUserFromJWToken,
  withActionLogger,
  withClient,
  withTranslation('translations'),
)(OfferSettings);
