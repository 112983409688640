import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import GenericTextInput from '@/components/Common/GenericTextInput';
import { t } from 'i18next';
import React from 'react';
import { Modal } from 'semantic-ui-react';
import styles from '../SuperPipelineSettings.module.less';

interface StepAndSubstepEditionModal {
  title: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  value: string;
  setValue: (value: string) => void;
  disabled: boolean;
  onSubmit: () => void;
  isSubstep?: boolean;
  isEdition?: boolean;
}

const StepAndSubstepEditionModal: React.FC<StepAndSubstepEditionModal> = ({
  title,
  open,
  setOpen,
  value,
  setValue,
  disabled,
  onSubmit,
  isSubstep = false,
  isEdition = false,
}) => {
  return (
    <GenericModal size='mini' open={open} onClose={() => setOpen(false)}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <p className={styles.newSubstepNameLabel}>
          {isSubstep
            ? t('superPipelineSettings.substepName')
            : t('superPipelineSettings.stepName')}
        </p>
        <GenericTextInput fluid value={value} onValue={setValue} autoFocus />
      </Modal.Content>
      <Modal.Actions className={styles.actions}>
        <GenericButton
          size='big'
          primacy='secondary'
          onClick={() => setOpen(false)}
        >
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          size='big'
          primacy='primary'
          onClick={() => onSubmit()}
          disabled={disabled}
        >
          {isEdition ? t('common.edit') : t('common.create')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default StepAndSubstepEditionModal;
