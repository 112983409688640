import gql from 'graphql-tag';
import Workflow from './Workflow';

export default gql`
  fragment Integration on IIntegration {
    id
    type
    status
    workflows {
      ...Workflow
    }
    ... on GreenhouseIntegration {
      jobs {
        id
        name
      }
      users {
        id
        name
      }
    }
    ... on AshbyIntegration {
      jobs {
        id
        name
      }
      users {
        id
        name
      }
    }
    ... on PinpointIntegration {
      jobs {
        id
        name
      }
      users {
        id
        name
      }
    }
    ... on RecruiteeIntegration {
      jobs {
        id
        name
      }
    }
    ... on TeamtailorIntegration {
      jobs {
        id
        name
      }
    }
    ... on WorkableIntegration {
      jobs {
        id
        name
      }
      users {
        id
        name
      }
    }
    ... on LeverIntegration {
      jobs {
        id
        name
      }
      users {
        id
        name
      }
    }
    ... on WelcomekitIntegration {
      jobs {
        id
        name
      }
    }
    ... on SmartrecruitersIntegration {
      jobs {
        id
        name
      }
    }
  }
  ${Workflow}
`;
