import _ from 'underscore';

const DAY_MS = 24 * 3600 * 1000;
const DAYS_PER_MONTH = 30.5;
const DAYS_PER_WEEK = 7;
export const MONTH = 'month';
export const WEEK = 'week';
export const DAY = 'day';

export const msToDelay = (ms) => {
  if (typeof ms !== 'number' || _.isNaN(ms)) {
    return null;
  }
  const nbDays = ms / DAY_MS;
  if (nbDays % DAYS_PER_MONTH === 0) {
    return {
      type: MONTH,
      count: nbDays / DAYS_PER_MONTH,
    };
  }
  if (nbDays % DAYS_PER_WEEK === 0) {
    return {
      type: WEEK,
      count: nbDays / DAYS_PER_WEEK,
    };
  }
  return {
    type: DAY,
    count: Math.round(nbDays),
  };
};

export const msToDelayWithType = (ms, type) => {
  if (typeof ms !== 'number' || _.isNaN(ms)) {
    return null;
  }

  const nbDays = ms / DAY_MS;
  switch (type) {
    case (DAY):
      return Math.round(nbDays);
    case (WEEK):
      return Math.round(nbDays / DAYS_PER_WEEK);
    case (MONTH):
      return Math.round(nbDays / DAYS_PER_MONTH);
    default:
      return Math.round(nbDays);
  }
};

export const delayToMs = (delay) => {
  switch (delay.type) {
    case MONTH:
      return delay.count * 30.5 * DAY_MS;
    case 'week':
      return delay.count * 7 * DAY_MS;
    case 'day':
    default:
      return delay.count * DAY_MS;
  }
};
