import { graphql } from '@apollo/client/react/hoc';
import { ADD_CONTACT_FLOW_ACTION } from '@/graphql/searchPoolProfile';

export default graphql(ADD_CONTACT_FLOW_ACTION, {
  props: ({ mutate }) => ({
    addContactFlowAction: ({ searchPoolId, input }) =>
      mutate({
        variables: { searchPoolId, input },
      }),
  }),
});
