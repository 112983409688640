import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown, Form, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import _ from 'underscore';
import '../../OverrideStyles.css';
import {
  CustomFieldCondition,
  getMergeTagsSimplifiedConditionVariable,
  MergeTagsVariable,
  MERGE_TAGS_VARIABLES_BASE_IDS,
  SNIPPET_TYPES,
} from '@/common/mergeTags/utils';
import { getRandomString, getTranslatedText } from '@/common';
import GenericButton from '@/components/Common/GenericButton';
import { ConditionsChainingProvider } from '@/containers/Editor/VariableExamplesPreviewModal/ConditionsChaining/useConditionsChainingContext';
import useClientId from '@/hooks/router/useClientId';
import useConditionsChainingCustomFields from '@/containers/Editor/VariableExamplesPreviewModal/ConditionsChaining/useConditionsChainingCustomFields';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import { getInitialConditionsChainingState } from '@/containers/Editor/VariableExamplesPreviewModal/ConditionsChaining/utils';
import { getConditionsChainingVariableOptions } from '@/containers/Editor/MergeTagsSelector/utils';
import GenericToggle from '@/components/Common/GenericToggle';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import SnippetConditionsChaining from './SnippetConditionsChaining';
import SnippetSelectOptions from './SnippetSelectOptions';
import SnippetFragment from './SnippetFragment';
import styles from '../../SnippetsSettings.module.less';

type Props = {
  onSubmit: (data: MergeTagsVariable) => void;
  setOpen: (open: boolean) => void;
  defaultValues?: MergeTagsVariable;
  setIsFullScreenModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const aiTokenOptionSections: Record<string, any> = [
  {
    id: 'profile-information',
    title: { default: "Candidate's Background" },
    options: [
      {
        id: 'shouldUseSummary',
        title: { default: 'Use summary' },
      },
      {
        id: 'shouldUseLastExperience',
        title: { default: 'Use last experience' },
      },
      {
        id: 'shouldUsePastExperiences',
        title: { default: 'Use all past experiences' },
      },
      { id: 'shouldUseEducation', title: { default: 'Use education' } },
      { id: 'shouldUseLocation', title: { default: 'Use location' } },
      {
        id: 'shouldFocusOnLastExperience',
        title: { default: 'Focus on last experience' },
      },
      { id: 'shouldFocusOnSkills', title: { default: 'Focus on skills' } },
      {
        id: 'shouldFocusOnInterests',
        title: { default: 'Focus on interests' },
      },
    ],
  },
  {
    id: 'past-interaction',
    title: { default: 'Past interaction' },
    useExperimentalAiToken: true,
    options: [
      {
        id: 'shouldUsePastMessageIfAny',
        title: { default: 'Use existence of past messages if any' },
      },
      {
        id: 'shouldUsePastMessageContentIfAny',
        title: { default: 'Use content of past messages if any' },
      },
      {
        id: 'shouldUsePastApplicationIfAny',
        title: { default: 'Use existence of past applications if any' },
      },
      {
        id: 'shouldUsePastApplicationContentIfAny',
        title: { default: 'Use content of past applications if any' },
      },
      {
        id: 'shouldUsePastATSArchiveReasons',
        title: { default: 'Use past ATS archive reasons' },
      },
    ],
  },
  {
    id: 'project',
    title: { default: 'Project' },
    useExperimentalAiToken: true,
    options: [
      {
        id: 'shouldUseProjectCustomFields',
        title: { default: 'Use project custom fields' },
      },
      {
        id: 'shouldUseProjectCriterias',
        title: { default: 'Use project criteria' },
      },
      {
        id: 'shouldUseProjectPosting',
        title: { default: 'Use project posting' },
      },
      {
        id: 'shouldUseProjectATSLinkedJobPosting',
        title: { default: "Use ATS linked job's posting" },
      },
    ],
  },
  {
    id: 'company',
    title: { default: 'Company' },
    options: [
      {
        id: 'shouldUseCompanyGlobalDescription',
        title: { default: 'Use global description of the company' },
      },
      {
        id: 'shouldUseCompanyCulture',
        title: { default: "Focus on company's culture" },
      },
      {
        id: 'shouldUseCompanyPerks',
        title: { default: "Focus on company's perks" },
      },
    ],
  },
  {
    id: 'recruiter',
    title: { default: 'Recruiter / Hiring Manager' },
    options: [
      {
        id: 'shouldUseSequenceOwnerInformations',
        title: { default: 'Use information about the sequence owner' },
        useExperimentalAiToken: true,
      },
      {
        id: 'shouldUseHiringManagerInformations',
        title: { default: 'Use information about the Hiring Manager' },
        useExperimentalAiToken: true,
      },
      {
        id: 'shouldFocusOnCommonIntereset',
        title: { default: 'Focus on common interest' },
      },
      {
        id: 'shouldFocusOnCommonPastExperience',
        title: { default: 'Focus on common past experience' },
      },
    ],
  },
];

const getInitialValues = (variable?: MergeTagsVariable): MergeTagsVariable => {
  if (!variable) {
    return {
      id: '',
      name: '',
      type: SNIPPET_TYPES.FRAGMENT,
      text: '',
      snippets: [],
    };
  }
  if (variable.type === SNIPPET_TYPES.SELECT) {
    return {
      id: variable.id || '',
      name: variable.name || '',
      type: SNIPPET_TYPES.SELECT,
      isMultiselect: variable.isMultiselect || true,
      options: _.isEmpty(variable.options)
        ? ([
            { id: getRandomString(5), title: { default: '' } },
          ] as SweetEvaluatorTypes.SelectOption[])
        : variable.options,
      selected: !_.isEmpty(variable.selected) ? variable.selected : [],
    };
  }
  if (variable.type === SNIPPET_TYPES.FRAGMENT) {
    return {
      id: variable.id || '',
      name: variable.name || '',
      type: SNIPPET_TYPES.FRAGMENT,
      text: variable.text || '',
      snippets: !_.isEmpty(variable.snippets) ? variable.snippets : [],
    };
  }
  if (variable.type === SNIPPET_TYPES.CONDITIONS_CHAINING) {
    return variable;
  }
  if (variable.type === SNIPPET_TYPES.AI_TOKEN) {
    const mergedAiTokenOptions = _.flatten([
      ..._.map(
        _.filter(
          aiTokenOptionSections,
          (section) => !section.useExperimentalAiToken,
        ),
        (section) =>
          _.filter(section.options, (option) => !option.useExperimentalAiToken),
      ),
      ..._.map([...insertionOptions, ...intentionOptions], (option) => ({
        id: option.key,
        title: { default: option.text },
      })),
    ]);
    return {
      ...variable,
      options: _.map(variable.options || [], (option) =>
        _.findWhere(mergedAiTokenOptions, { id: option }),
      ),
    };
  }
  return variable;
};

const insertionOptions = [
  {
    key: 'within-sentence',
    text: 'Within sentence',
    value: 'within-sentence',
  },
  {
    key: 'inline-sentence',
    text: 'Inline sentence',
    value: 'inline-sentence',
  },
  {
    key: 'multi-sentences',
    text: 'One or more sentences',
    value: 'multi-sentences',
  },
  {
    key: 'bullet-points',
    text: 'Bullet points',
    value: 'bullet-points',
  },
];

const intentionOptions = [
  {
    key: 'general',
    text: 'General',
    value: 'general',
  },
  {
    key: 'ice-breaker',
    text: 'Ice breaker',
    value: 'ice-breaker',
  },
  {
    key: 'adequation-with-company',
    text: 'Adequation with the company',
    value: 'adequation-with-company',
  },
  {
    key: 'adequation-with-job',
    text: 'Adequation with the job',
    value: 'adequation-with-job',
  },
];

const SnippetSettingsForm: React.FC<Props> = ({
  onSubmit,
  setOpen,
  defaultValues,
  setIsFullScreenModal,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();

  const { permissions } = useClientPermissions(clientId);

  const [formValues, setFormValues] = useState<MergeTagsVariable>(
    getInitialValues(defaultValues),
  );
  const [selectedOption, setSelectedOption] = useState<string>(
    defaultValues?.type || SNIPPET_TYPES.FRAGMENT,
  );

  const {
    missionCustomFields,
    profileCustomFields,
  } = useConditionsChainingCustomFields({ clientId });

  useEffect(() => {
    if (formValues.type !== SNIPPET_TYPES.CONDITIONS_CHAINING) {
      return;
    }
    /* 
    If variable is of type conditions-chaining, we need to find the corresponding option.
    The conditionsChainingVariablesOptions variable does not expose a option with a type conditions-chaining
    So we need to match the conditions-chaining variable with his corresponding option 
    */

    // If the variable does not contain a subtype and a switchField, its an classic conditions-chaining variable
    if (!formValues.subtype || !formValues.switchField) {
      setSelectedOption(MERGE_TAGS_VARIABLES_BASE_IDS.CONDITIONS_CHAINING);
      return;
    }
    // If its a simplified variable, we need to find the corresponding option
    // If its a native field
    if (formValues.switchField.fieldId === 'firstname') {
      setSelectedOption('conditions-chaining-simplified-firstname');
      return;
    }
    if (formValues.switchField.fieldId === 'gender') {
      setSelectedOption('conditions-chaining-simplified-gender');
      return;
    }
    // if custom field
    // Check in the first statement the customFieldId and fieldCategory
    const { customFieldId, fieldCategory } = formValues.ifStatements[0]
      ?.condition as CustomFieldCondition;
    setSelectedOption(
      `${
        fieldCategory === 'custom-field'
          ? 'profile-custom-field'
          : 'mission-custom-field'
      }/${customFieldId}`,
    );
  }, [formValues]);

  const conditionsChainingVariablesOptions = useMemo(() => {
    return getConditionsChainingVariableOptions(
      t,
      profileCustomFields,
      missionCustomFields,
    );
  }, [profileCustomFields, missionCustomFields, t]);

  const snippetTypesOptions = useMemo(() => {
    const options: any = [
      {
        key: 'native-fragment',
        text: t('reveal.parameters.snippetSettings.type.fragment'),
        value: SNIPPET_TYPES.FRAGMENT,
        description: t(
          'reveal.parameters.snippetSettings.form.simpleDescription',
        ),
      },
      {
        key: 'native-select',
        text: t('reveal.parameters.snippetSettings.type.select'),
        value: SNIPPET_TYPES.SELECT,
        description: t(
          'reveal.parameters.snippetSettings.form.multipleValuesDescription',
        ),
      },
      ...(permissions?.aiTokenGeneration
        ? [
            {
              key: 'native-ai-token',
              text: t('reveal.parameters.snippetSettings.type.ai-token'),
              value: SNIPPET_TYPES.AI_TOKEN,
              description: t('reveal.parameters.snippetSettings.form.ai-token'),
            },
          ]
        : []),
    ];

    _.each(conditionsChainingVariablesOptions, (variable) => {
      options.push({
        key: variable.key,
        text: variable.label,
        value: variable.key,
      });
    });

    return options;
  }, [t, conditionsChainingVariablesOptions, permissions]);

  const setOptions = (options: SweetEvaluatorTypes.SelectOption[]) => {
    setFormValues((prevState) => ({ ...prevState, options }));
  };

  const isDisabled = useMemo(() => {
    if (_.isEmpty(formValues.name)) {
      return true;
    }
    if (
      formValues.type === SNIPPET_TYPES.FRAGMENT &&
      _.isEmpty(formValues.text)
    ) {
      return true;
    }
    if (
      formValues.type === SNIPPET_TYPES.SELECT &&
      _.some(formValues.options || [], (option) =>
        _.isEmpty(option.title?.default),
      )
    ) {
      return true;
    }
    return false;
  }, [formValues]);

  const handleChangeSnippetType = ({ value }: { value: string }) => {
    setSelectedOption(value);
    if (value === SNIPPET_TYPES.SELECT) {
      const values = getInitialValues({
        ...(formValues as SweetEvaluatorTypes.BaseVariable &
          SweetEvaluatorTypes.SelectVariable),
        type: SNIPPET_TYPES.SELECT,
      });
      setFormValues(values);
      setIsFullScreenModal(false);
      return;
    }
    if (value === SNIPPET_TYPES.FRAGMENT) {
      const values = getInitialValues({
        ...(formValues as SweetEvaluatorTypes.BaseVariable &
          SweetEvaluatorTypes.FragmentVariable),
        type: SNIPPET_TYPES.FRAGMENT,
      });
      setFormValues(values);
      setIsFullScreenModal(false);
      return;
    }
    if (value === SNIPPET_TYPES.AI_TOKEN) {
      const values = getInitialValues({
        ...(formValues as SweetEvaluatorTypes.BaseVariable &
          SweetEvaluatorTypes.AITokenVariable),
        type: SNIPPET_TYPES.AI_TOKEN,
      });
      const insertionKeys = _.pluck(insertionOptions, 'key');
      const intentionKeys = _.pluck(intentionOptions, 'key');
      const options = _.pluck(values.options, 'id');
      const presentInsertion = !_.isEmpty(
        _.intersection(insertionKeys, options),
      );
      const presentIntention = !_.isEmpty(
        _.intersection(intentionKeys, options),
      );
      setFormValues({
        ...values,
        options: [
          ...(values.options || []),
          ...(!presentInsertion
            ? [
                {
                  id: 'within-sentence',
                  title: { default: 'Within sequence' },
                },
              ]
            : []),
          ...(!presentIntention
            ? [{ id: 'general', title: { default: 'General' } }]
            : []),
        ],
      });
      setIsFullScreenModal(false);
      return;
    }
    const selectedConditionOption = _.findWhere(
      conditionsChainingVariablesOptions,
      {
        key: value,
      },
    );
    if (!selectedConditionOption) {
      return;
    }
    setIsFullScreenModal(true);
    if (
      selectedConditionOption.simplifiedConditionVariable &&
      selectedConditionOption.simplifiedConditionVariable.key
    ) {
      const simplifiedConditionVariable = getMergeTagsSimplifiedConditionVariable(
        {
          id: selectedConditionOption.key,
          fieldId: selectedConditionOption.simplifiedConditionVariable.key,
          name: selectedConditionOption.label,
        },
      );
      const simplifiedConditionVariableState = getInitialConditionsChainingState(
        {
          variable: simplifiedConditionVariable,
          profileCustomFields,
          missionCustomFields,
          t,
        },
      );
      setFormValues((prevState) => ({
        id: prevState.id || '',
        name: prevState.name || '',
        ...simplifiedConditionVariableState,
      }));
    } else {
      setFormValues((prevState) => ({
        id: prevState.id || '',
        name: prevState.name || '',
        ...getInitialConditionsChainingState({
          profileCustomFields,
          missionCustomFields,
          t,
        }),
      }));
    }
  };

  const handleSubmit = () => {
    onSubmit(formValues);
  };

  const onAiTokenOptionChange = useCallback(
    ({ key, title, value }) => {
      const currentOptions = formValues.options;
      if (!value) {
        const optionIndex = _.findIndex(
          currentOptions,
          (option) => option.id === key,
        );
        setFormValues({
          ...formValues,
          options: _.uniq([
            ...currentOptions.slice(0, optionIndex),
            ...currentOptions.slice(optionIndex + 1),
          ]),
        });
      } else {
        const insertion = _.findWhere(insertionOptions, { value });
        const intention = _.findWhere(intentionOptions, { value });
        if (insertion || intention) {
          const insertionIntersection = _.intersection(
            _.pluck(currentOptions, 'id'),
            _.pluck(insertionOptions, 'key'),
          );
          const intentionIntersection = _.intersection(
            _.pluck(currentOptions, 'id'),
            _.pluck(intentionOptions, 'key'),
          );
          const filteredOptions = _.filter(currentOptions, (option) => {
            if (insertion) {
              return !_.includes(insertionIntersection, option.id);
            }
            return !_.includes(intentionIntersection, option.id);
          });
          setFormValues({
            ...formValues,
            options: _.uniq([
              ...filteredOptions,
              { id: value, title: { default: title } },
            ]),
          });
        } else {
          setFormValues({
            ...formValues,
            options: _.uniq([...(currentOptions || []), { id: key, title }]),
          });
        }
      }
    },
    [formValues],
  );

  const hasDefaultValues = !!defaultValues?.id;

  return (
    <div className={styles.modalForm}>
      <Form.Field className={styles.modalFormField}>
        <label htmlFor='name'>
          {t('reveal.parameters.snippetSettings.name')}
        </label>
        <Input
          id='name'
          fluid
          name='name'
          className={styles.modalFormInput}
          value={formValues.name}
          onChange={(e, { value }) =>
            setFormValues((prevState) => ({ ...prevState, name: value }))
          }
          input={{ 'data-form-type': 'other' }}
        />
      </Form.Field>
      <Form.Field className={styles.modalFormField}>
        <label htmlFor='type'>
          {t('reveal.parameters.snippetSettings.typeLabel')}
        </label>
        <Dropdown
          id='type'
          fluid
          selection
          className={styles.modalFormInput}
          defaultValue={formValues.type}
          onChange={(e, { value }) => {
            handleChangeSnippetType({ value: value as string });
          }}
          options={snippetTypesOptions}
          value={selectedOption}
        />
      </Form.Field>

      {(formValues.type === SNIPPET_TYPES.FRAGMENT ||
        formValues.type === SNIPPET_TYPES.AI_TOKEN) && (
        <SnippetFragment
          formValues={formValues}
          setFormValues={setFormValues}
        />
      )}
      {formValues.type === SNIPPET_TYPES.AI_TOKEN && (
        <>
          {permissions?.aiTokenExperimental && (
            <>
              <Form.Field className={styles.modalFormField}>
                <label htmlFor='type'>Insertion</label>
                <Dropdown
                  id='insertion'
                  fluid
                  selection
                  className={styles.modalFormInput}
                  value={
                    _.findWhere(insertionOptions, {
                      key: _.intersection(
                        _.pluck(insertionOptions, 'key'),
                        _.pluck(formValues.options, 'id'),
                      )?.[0],
                    })?.value
                  }
                  onChange={(_e, { value, options }) =>
                    onAiTokenOptionChange({
                      key: 'insertion',
                      title: _.findWhere(options || [], { value })?.text,
                      value,
                    })
                  }
                  options={insertionOptions}
                />
              </Form.Field>
              <Form.Field className={styles.modalFormField}>
                <label htmlFor='type'>Intention</label>
                <Dropdown
                  id='intention'
                  fluid
                  selection
                  className={styles.modalFormInput}
                  value={
                    _.findWhere(intentionOptions, {
                      key: _.intersection(
                        _.pluck(intentionOptions, 'key'),
                        _.pluck(formValues.options, 'id'),
                      )?.[0],
                    })?.value
                  }
                  onChange={(_e, { value, options }) =>
                    onAiTokenOptionChange({
                      key: 'intention',
                      title: _.findWhere(options || [], { value })?.text,
                      value,
                    })
                  }
                  options={intentionOptions}
                />
              </Form.Field>
            </>
          )}
          <div className={styles.aiTokenOptionGroups}>
            {_.map(
              _.filter(
                aiTokenOptionSections,
                (section) => !section.useExperimentalAiToken,
              ),
              (section) => (
                <div className={styles.aiTokenSection} key={section.id}>
                  <label className={styles.aiTokenOptionGroupName}>
                    {getTranslatedText(section.title)}
                  </label>
                  {_.map(
                    _.filter(
                      section.options,
                      (option) => !option.useExperimentalAiToken,
                    ),
                    (option) => (
                      <GenericToggle
                        key={option.id}
                        name={getTranslatedText(option.title)}
                        label={getTranslatedText(option.title)}
                        id={option.id}
                        onChange={({ key, value }) =>
                          onAiTokenOptionChange({
                            key,
                            title: option.title,
                            value,
                          })
                        }
                        isChecked={_.findWhere(formValues.options || [], {
                          id: option.id,
                        })}
                      />
                    ),
                  )}
                </div>
              ),
            )}
          </div>
        </>
      )}
      {formValues.type === SNIPPET_TYPES.SELECT && (
        <SnippetSelectOptions
          options={formValues.options}
          setOptions={setOptions}
        />
      )}
      {formValues.type === SNIPPET_TYPES.CONDITIONS_CHAINING && (
        <ConditionsChainingProvider
          clientId={clientId}
          initialValue={formValues}
        >
          <SnippetConditionsChaining setFormValues={setFormValues} />
        </ConditionsChainingProvider>
      )}

      <div className={styles.modalActions}>
        <GenericButton
          size='big'
          primacy='secondary'
          onClick={() => setOpen(false)}
        >
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          size='big'
          onClick={() => handleSubmit()}
          disabled={isDisabled}
        >
          {t(
            hasDefaultValues
              ? 'common.save'
              : 'reveal.parameters.snippetSettings.creationModal.create',
          )}
        </GenericButton>
      </div>
    </div>
  );
};

export default SnippetSettingsForm;
