import _ from 'underscore';
import {
  LocationAndRemote,
  RawLocationAndRemoteCriteria,
} from '@/types/locationAndRemote';

export const isEmptyRawLocationAndRemoteCriteria = ({
  target = [],
  okWith = [],
}: RawLocationAndRemoteCriteria = {}): boolean =>
  _.isEmpty(target) && _.isEmpty(okWith);

export const locationsAndRemoteFromRaw = (
  raw?: RawLocationAndRemoteCriteria,
): LocationAndRemote[] => {
  const { target = [], okWith = [] } = raw || {};
  const targetLocationsAndRemote = _.map(
    target,
    ({ location, remoteWish }) => ({
      locationId: location.id,
      remotePolicy: remoteWish,
      favorite: true,
    }),
  );
  const okWithLocationsAndRemote = _.map(
    okWith,
    ({ location, remoteWish }) => ({
      locationId: location.id,
      remotePolicy: remoteWish,
      favorite: false,
    }),
  );
  return [...targetLocationsAndRemote, ...okWithLocationsAndRemote];
};

export const locationsAndRemoteToRaw = (
  value: LocationAndRemote[],
): RawLocationAndRemoteCriteria => {
  const result: RawLocationAndRemoteCriteria = {};
  _.each(value, ({ locationId, remotePolicy, favorite }) => {
    const item = {
      remoteWish: remotePolicy,
      location: {
        id: locationId,
      },
    };
    if (favorite) {
      result.target = result.target || [];
      result.target.push(item);
    } else {
      result.okWith = result.okWith || [];
      result.okWith.push(item);
    }
  });
  return result;
};

export default {};
