import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import withProfileTimeLine from '../../../../hocs/profiles/withProfileTimeLine';
import { getContactFlowEmails, getTimelineWithoutEmails } from './utils';
import TimeLineItem from './TimeLineItem';
import LoadingComponent from '../../../../components/LoadingComponent';

class TimeLineItems extends React.PureComponent {
  render() {
    const {
      loading,
      profile,
      clientId,
      profileId,
      isWatchProfile,
      error,
      t,
      searchPoolId,
      hideSourced,
      dense = false,
    } = this.props;

    if (!profile) {
      return null;
    }
    if (!profileId || loading || error) {
      return (
        <div style={{ marginLeft: 10, marginTop: 30 }}>
          <LoadingComponent loading length={30} as='div' rows={1} margin={2} />
          <div className='timeline-card-mail-wrapper' style={{ padding: 10 }}>
            <LoadingComponent
              loading
              length={30}
              as='div'
              rows={2}
              margin={1}
              randomCoef={4}
            />
          </div>
        </div>
      );
    }

    const isImportOrApplicationAction = (action) => {
      return _.contains(
        [
          'plugin-import',
          'app-manual-import',
          'app-csv-import',
          'reveal-plugin-import',
          'reveal-csv-import',
          'career-page-application',
        ],
        action?.type,
      );
    };
    const contactFlowEmails = getContactFlowEmails({
      contactFlow: profile?.contactFlow,
    });
    const timelineWithoutEmails = getTimelineWithoutEmails({
      timeline: profile?.contactData?.timeline,
    });
    const timeline = _.union(timelineWithoutEmails, contactFlowEmails);

    let timelineItemsList = timeline;

    if (
      !isWatchProfile &&
      !_.find(timeline, (item) => isImportOrApplicationAction(item?.action))
    ) {
      timelineItemsList = [...timeline, { action: { type: 'sourced' } }];
    }
    timelineItemsList = _.reject(
      timelineItemsList,
      (item) =>
        item?.action?.type === 'messageSequence' ||
        (hideSourced && item?.action?.type === 'sourced'),
    );
    timelineItemsList = _.sortBy(timelineItemsList, (item) => +item?.date * -1);

    if (_.isEmpty(timelineItemsList)) {
      return (
        <div className='timeline'>
          <h3 className='section-info'>
            {t('reveal.candidatesView.timeline.noPastActivity')}
          </h3>
        </div>
      );
    }

    if (!profileId) return null;

    return (
      <div
        className={classNames('timeline', {
          dense,
        })}
      >
        {!dense && (
          <h3 className='section-title'>
            {t('profile.contact.timeline.past')}
          </h3>
        )}
        {_.map(timelineItemsList, (timelineItem, i) => (
          <TimeLineItem
            key={`${i}_${profileId}_timeline_item${timelineItem.date}_${
              (timelineItem.action || {}).type
            }`}
            clientId={clientId}
            searchPoolId={searchPoolId}
            timelineItem={timelineItem}
            profile={profile}
          />
        ))}
      </div>
    );
  }
}

export default compose(
  withProfileTimeLine,
  withTranslation('translations'),
)(TimeLineItems);
