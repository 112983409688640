import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

const query = gql`
  query getClientSingleSignOnActive($id: ID!) {
    client(id: $id) {
      id
      singleSignOn {
        isActive
      }
    }
  }
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.clientId,
    },
  }),
  props: ({ data: { loading, client, error } }) => ({
    loading,
    loadingClientSingleSignOnActive: loading,
    client,
    error,
  }),
});
