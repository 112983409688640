import GenericButton from '@/components/Common/GenericButton';
import {
  MetaTaskFormat,
  MetaTaskFormatFormField,
} from '@/revealComponents/ExplicitTasks/MetaTaskForm/types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import GenericToggle from '@/components/Common/GenericToggle';
import { PlusIcon } from '@/assets/icons';
import { getRandomString, getTranslatedText } from '@/common';
import GenericDropdown from '@/components/Common/GenericDropdown';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import DropdownPanel from '@/components/Common/DropdownPanel';
import useClientProfileCustomFields, {
  CustomFieldDefinitionEnum,
} from '@/graphql/hooks/clients/useClientProfileCustomFields';
import useClientId from '@/hooks/router/useClientId';
import styles from './CreateMetaTaskForm.module.less';
import MetaTaskInlineTextFieldForm from './MetaTaskInlineTextFieldForm';
import MetaTaskOrderFieldForm from './MetaTaskOrderFieldForm';
import MetaTaskSelectFieldForm from './MetaTaskSelectFieldForm';
import MetaTaskCustomFieldForm from './MetaTaskCustomFieldForm';
import MetaTaskHelpTextFieldForm from './MetaTaskHelpTextFieldForm';
import MetaTaskDateFieldForm from './MetaTaskDateFieldForm';

interface CreateMetaTaskFormProps {
  metaTaskFormat?: MetaTaskFormat;
  onUpdate: (form: MetaTaskFormat) => void;
}

const CreateMetaTaskForm: React.FC<CreateMetaTaskFormProps> = ({
  metaTaskFormat,
  onUpdate,
}) => {
  const [showOrder, setShowOrder] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const clientId = useClientId();
  const { profileCustomFields } = useClientProfileCustomFields(clientId);

  const { t } = useTranslation();

  useEffect(() => {
    if (!_.isEmpty(metaTaskFormat?.helpText?.default)) {
      setShowOrder(true);
    }
    if (!_.isEmpty(metaTaskFormat?.form?.fields)) {
      setShowForm(true);
    }
  }, [metaTaskFormat]);

  const contactsCustomFields = useMemo(
    () =>
      _.filter(
        profileCustomFields,
        (field) =>
          !field.contactCategory ||
          !field.contactCategory.type ||
          field.contactCategory.type === 'human',
      ),
    [profileCustomFields],
  );

  const unusedCustomFields = useMemo(() => {
    const usedCustomFields = _.pluck(metaTaskFormat?.form?.fields || [], 'id');
    return _.filter(
      contactsCustomFields,
      (field) => !_.contains(usedCustomFields, field.id),
    );
  }, [metaTaskFormat, contactsCustomFields]);

  if (!metaTaskFormat) {
    return null;
  }

  const handleFieldUpdate = ({
    fieldId,
    newField,
  }: {
    fieldId?: string;
    newField: MetaTaskFormatFormField;
  }) => {
    const newFields = _.map(
      metaTaskFormat.form?.fields,
      (field: MetaTaskFormatFormField) => {
        if (field.id === fieldId) {
          return {
            ...field,
            ...newField,
          };
        }
        return field;
      },
    );
    onUpdate({
      ...metaTaskFormat,
      form: {
        ...metaTaskFormat?.form,
        fields: newFields,
      },
    });
  };

  const handleFieldRemoval = ({ fieldId }: { fieldId: string }) => {
    onUpdate({
      ...metaTaskFormat,
      form: {
        ...metaTaskFormat?.form,
        fields: _.filter(
          metaTaskFormat.form.fields,
          (field) => field.id !== fieldId,
        ),
      },
    });
  };

  const handleMoveFieldUpward = ({ fieldId }: { fieldId: string }) => {
    const fieldIndex = _.findIndex(
      metaTaskFormat.form.fields,
      (field) => field.id === fieldId,
    );
    if (fieldIndex === 0) {
      return;
    }
    const newFields = [...metaTaskFormat.form.fields];
    [newFields[fieldIndex - 1], newFields[fieldIndex]] = [
      newFields[fieldIndex],
      newFields[fieldIndex - 1],
    ];
    onUpdate({
      ...metaTaskFormat,
      form: {
        ...metaTaskFormat?.form,
        fields: newFields,
      },
    });
  };

  const handleMoveFieldDownward = ({ fieldId }: { fieldId: string }) => {
    const fieldIndex = _.findIndex(
      metaTaskFormat.form.fields,
      (field) => field.id === fieldId,
    );
    if (fieldIndex === metaTaskFormat.form.fields.length - 1) {
      return;
    }
    const newFields = [...metaTaskFormat.form.fields];
    [newFields[fieldIndex], newFields[fieldIndex + 1]] = [
      newFields[fieldIndex + 1],
      newFields[fieldIndex],
    ];
    onUpdate({
      ...metaTaskFormat,
      form: {
        ...metaTaskFormat?.form,
        fields: newFields,
      },
    });
  };

  const handleOrderUpdate = ({ order }: { order: string }) => {
    onUpdate({
      ...metaTaskFormat,
      helpText: { default: order },
    });
  };

  const handleShowOrder = (value: boolean) => {
    if (!value) {
      onUpdate({
        ...metaTaskFormat,
        helpText: { default: '' },
      });
    }
    setShowOrder(value);
  };

  return (
    <div className={styles.fieldsContainer}>
      <div className={styles.fieldSectionContainer}>
        <MetaTaskOrderFieldForm
          showOrder={showOrder}
          onShowOrder={handleShowOrder}
          order={showOrder ? metaTaskFormat?.helpText?.default || '' : ''}
          onUpdateOrder={handleOrderUpdate}
        />
        <div className={styles.fieldContainer}>
          <div className={styles.fieldHeader}>
            <GenericToggle
              className={styles.toggle}
              isChecked={showForm}
              name='showForm'
              onChange={({ value }) => {
                if (!value) {
                  onUpdate({
                    ...metaTaskFormat,
                    form: {
                      ...metaTaskFormat?.form,
                      fields: [],
                    },
                  });
                } else {
                  onUpdate({
                    ...metaTaskFormat,
                    form: {
                      ...metaTaskFormat?.form,
                      fields: [
                        {
                          title: {
                            default: t(
                              'reveal.modals.metaTaskModal.formPlaceholder1',
                            ),
                          },
                          type: 'inline-text',
                          id: getRandomString(6),
                        },
                        {
                          id: getRandomString(6),
                          type: 'select',
                          title: {
                            default: t(
                              'reveal.modals.metaTaskModal.formPlaceholder2',
                            ),
                          },
                          options: [
                            {
                              id: getRandomString(6),
                              title: {
                                default: 'Option 1',
                              },
                            },
                            {
                              id: getRandomString(6),
                              title: {
                                default: 'Option 2',
                              },
                            },
                          ],
                        },
                      ],
                    },
                  });
                }
                setShowForm(value);
              }}
            />
            <span>{t('reveal.modals.metaTaskModal.addForm')}</span>
          </div>
          {showForm && (
            <>
              {_.map(metaTaskFormat.form.fields, (field, index) => (
                <div key={field.id}>
                  {field.type === 'inline-text' && (
                    <MetaTaskInlineTextFieldForm
                      field={field}
                      onUpdateField={handleFieldUpdate}
                      onRemoveField={handleFieldRemoval}
                      onMoveDownward={handleMoveFieldDownward}
                      onMoveUpward={handleMoveFieldUpward}
                      index={index + 1}
                      isLast={index + 1 === metaTaskFormat.form.fields.length}
                    />
                  )}
                  {field.type === 'text-area' && (
                    <MetaTaskInlineTextFieldForm
                      field={field}
                      onUpdateField={handleFieldUpdate}
                      onRemoveField={handleFieldRemoval}
                      onMoveDownward={handleMoveFieldDownward}
                      onMoveUpward={handleMoveFieldUpward}
                      index={index + 1}
                      isLast={index + 1 === metaTaskFormat.form.fields.length}
                    />
                  )}
                  {field.type === 'select' && (
                    <MetaTaskSelectFieldForm
                      field={field}
                      onUpdateField={handleFieldUpdate}
                      onRemoveField={handleFieldRemoval}
                      onMoveDownward={handleMoveFieldDownward}
                      onMoveUpward={handleMoveFieldUpward}
                      index={index + 1}
                      isLast={index + 1 === metaTaskFormat.form.fields.length}
                    />
                  )}
                  {field.type === 'custom-field' &&
                    !_.isEmpty(contactsCustomFields) && (
                      <MetaTaskCustomFieldForm
                        field={field}
                        onUpdateField={handleFieldUpdate}
                        onRemoveField={handleFieldRemoval}
                        onMoveDownward={handleMoveFieldDownward}
                        onMoveUpward={handleMoveFieldUpward}
                        index={index + 1}
                        isLast={index + 1 === metaTaskFormat.form.fields.length}
                        customFields={[
                          _.findWhere(contactsCustomFields, {
                            id: field.id,
                          }) as CustomFieldDefinitionEnum,
                          ...unusedCustomFields,
                        ]}
                      />
                    )}
                  {field.type === 'help-text' && (
                    <MetaTaskHelpTextFieldForm
                      field={field}
                      onUpdateField={handleFieldUpdate}
                      onRemoveField={handleFieldRemoval}
                      onMoveDownward={handleMoveFieldDownward}
                      onMoveUpward={handleMoveFieldUpward}
                      index={index + 1}
                      isLast={index + 1 === metaTaskFormat.form.fields.length}
                    />
                  )}
                  {field.type === 'day' && (
                    <MetaTaskDateFieldForm
                      field={field}
                      onUpdateField={handleFieldUpdate}
                      onRemoveField={handleFieldRemoval}
                      onMoveDownward={handleMoveFieldDownward}
                      onMoveUpward={handleMoveFieldUpward}
                      index={index + 1}
                      isLast={index + 1 === metaTaskFormat.form.fields.length}
                    />
                  )}
                </div>
              ))}
              <GenericDropdown
                upward
                Trigger={({ onClick }) => (
                  <GenericButton primacy='secondary' onClick={onClick}>
                    <PlusIcon />
                    {t('reveal.modals.metaTaskModal.addField')}
                    <ArrowDown />
                  </GenericButton>
                )}
              >
                <DropdownPanel className={styles.panel}>
                  <div
                    className={styles.newFieldDropdownOption}
                    onClick={() =>
                      onUpdate({
                        ...metaTaskFormat,
                        form: {
                          ...metaTaskFormat?.form,
                          fields: [
                            ...metaTaskFormat.form.fields,
                            {
                              title: {
                                default: '',
                              },
                              type: 'select',
                              options: [
                                {
                                  id: getRandomString(6),
                                  title: {
                                    default: 'Option 1',
                                  },
                                },
                                {
                                  id: getRandomString(6),
                                  title: {
                                    default: 'Option 2',
                                  },
                                },
                              ],
                              isMultiselect: false,
                              id: getRandomString(6),
                            },
                          ],
                        },
                      })
                    }
                  >
                    {t('reveal.modals.metaTaskModal.addSelectField')}
                  </div>
                  <div
                    className={styles.newFieldDropdownOption}
                    onClick={() =>
                      onUpdate({
                        ...metaTaskFormat,
                        form: {
                          ...metaTaskFormat?.form,
                          fields: [
                            ...metaTaskFormat.form.fields,
                            {
                              title: {
                                default: '',
                              },
                              type: 'select',
                              options: [
                                {
                                  id: getRandomString(6),
                                  title: {
                                    default: 'Option 1',
                                  },
                                },
                                {
                                  id: getRandomString(6),
                                  title: {
                                    default: 'Option 2',
                                  },
                                },
                              ],
                              isMultiselect: true,
                              id: getRandomString(6),
                            },
                          ],
                        },
                      })
                    }
                  >
                    {t('reveal.modals.metaTaskModal.addMultiSelectField')}
                  </div>
                  <div
                    className={styles.newFieldDropdownOption}
                    onClick={() =>
                      onUpdate({
                        ...metaTaskFormat,
                        form: {
                          ...metaTaskFormat?.form,
                          fields: [
                            ...metaTaskFormat.form.fields,
                            {
                              title: {
                                default: '',
                              },
                              type: 'inline-text',
                              id: getRandomString(6),
                            },
                          ],
                        },
                      })
                    }
                  >
                    {t('reveal.modals.metaTaskModal.addInlineTextField')}
                  </div>
                  <div
                    className={styles.newFieldDropdownOption}
                    onClick={() =>
                      onUpdate({
                        ...metaTaskFormat,
                        form: {
                          ...metaTaskFormat?.form,
                          fields: [
                            ...metaTaskFormat.form.fields,
                            {
                              title: {
                                default: '',
                              },
                              type: 'text-area',
                              id: getRandomString(6),
                            },
                          ],
                        },
                      })
                    }
                  >
                    {t('reveal.modals.metaTaskModal.addTextAreaField')}
                  </div>
                  <div
                    className={styles.newFieldDropdownOption}
                    onClick={() =>
                      onUpdate({
                        ...metaTaskFormat,
                        form: {
                          ...metaTaskFormat?.form,
                          fields: [
                            ...metaTaskFormat.form.fields,
                            {
                              title: {
                                default: '',
                              },
                              type: 'help-text',
                              id: getRandomString(6),
                            },
                          ],
                        },
                      })
                    }
                  >
                    {t('reveal.modals.metaTaskModal.addHelpTextField')}
                  </div>
                  {!_.isEmpty(unusedCustomFields) && (
                    <div
                      className={styles.newFieldDropdownOption}
                      onClick={() =>
                        onUpdate({
                          ...metaTaskFormat,
                          form: {
                            ...metaTaskFormat?.form,
                            fields: [
                              ...metaTaskFormat.form.fields,
                              {
                                title: {
                                  default: getTranslatedText(
                                    unusedCustomFields?.[0]?.title,
                                  ),
                                },
                                type: 'custom-field',
                                subtype: unusedCustomFields?.[0]?.type,
                                id: unusedCustomFields?.[0]?.id,
                                ...(unusedCustomFields?.[0]?.type ===
                                  'enum' && {
                                  options: _.map(
                                    unusedCustomFields[0].options,
                                    (option) => ({
                                      id: option.id,
                                      title: option.title,
                                    }),
                                  ),
                                  isMultiselect:
                                    unusedCustomFields[0].isMultiselect,
                                }),
                              },
                            ],
                          },
                        })
                      }
                    >
                      {t('reveal.modals.metaTaskModal.addCustomField')}
                    </div>
                  )}
                  <div
                    className={styles.newFieldDropdownOption}
                    onClick={() =>
                      onUpdate({
                        ...metaTaskFormat,
                        form: {
                          ...metaTaskFormat?.form,
                          fields: [
                            ...metaTaskFormat.form.fields,
                            {
                              title: {
                                default: '',
                              },
                              type: 'day',
                              subtype: 'day',
                              id: getRandomString(6),
                            },
                          ],
                        },
                      })
                    }
                  >
                    {t('reveal.modals.metaTaskModal.addDateField')}
                  </div>
                </DropdownPanel>
              </GenericDropdown>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateMetaTaskForm;
