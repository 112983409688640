import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Integration from '../../graphql/fragments/Integration';

export const mutation = gql`
  mutation addClientIntegration(
    $type: String!
    $mode: String!
    $accountId: String
    $token: String
  ) {
    addClientIntegration(
      type: $type
      mode: $mode
      accountId: $accountId
      token: $token
    ) {
      id
      integrations {
        ...Integration
      }
    }
  }
  ${Integration}
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    addClientIntegration: ({ type, mode, accountId, token }) =>
      mutate({
        variables: {
          type,
          mode,
          accountId,
          token,
        },
      }),
  }),
});
