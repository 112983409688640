import React, { FC } from 'react';
import classNames from 'classnames';

import Message from '@/components/Common/Icons/Message';
import { Profile } from '@/types/profile';
import useMarkProfileForCuratedAutoSend from '@/graphql/hooks/profiles/useMarkProfileForCuratedAutoSend';

import useUnmarkProfileForCuratedAutoSend from '@/graphql/hooks/profiles/useUnmarkProfileForCuratedAutoSend';
import styles from './CuratedAutoSendButton.module.less';

type CuratedAutoSendButtonProps = {
  profile: Profile;
};

const CuratedAutoSendButton: FC<CuratedAutoSendButtonProps> = ({ profile }) => {
  const isMarked = !!profile.annotation?.curatedAutoSend?.value;
  const [markProfileForCuratedAutoSend] = useMarkProfileForCuratedAutoSend();
  const [
    unmarkProfileForCuratedAutoSend,
  ] = useUnmarkProfileForCuratedAutoSend();

  return (
    <button
      type='button'
      className={classNames(styles.button, { [styles.active]: isMarked })}
      onClick={() =>
        isMarked
          ? unmarkProfileForCuratedAutoSend(profile.id)
          : markProfileForCuratedAutoSend(profile.id)
      }
    >
      <Message />
    </button>
  );
};

export default CuratedAutoSendButton;
