import gql from 'graphql-tag';

import TranslatableText from './TranslatableText';

export default gql`
  fragment Skill on Technology {
    id
    name {
      ...TranslatableText
    }
    icon
    detailsURL
  }
  ${TranslatableText}
`;
