import gql from 'graphql-tag';
import React, { FC, ReactNode, useCallback, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import useClientId from '@/hooks/router/useClientId';

import Toggle from '@/components/Common/Toggle/Toggle';
import { ClientEnrichmentIntegration } from '@/graphql/hooks/clients/useClientEnrichmentIntegrations';
import { UserEnrichmentIntegration } from '@/graphql/hooks/users/useUserEnrichmentIntegrations';
import {
  useDisableSystemEnrichmentIntegration,
  useEnableSystemEnrichmentIntegration,
} from './hooks';

import styles from './PeriodQuotaSettings.module.less';
import QuotaByUser from '../QuotaByUser/QuotaByUser';

interface ClientEnrichmentCreditsResult {
  client: {
    enrichmentMonthlyAmount: number;
  };
}

const CLIENT_MONTHLY_ENRICHMENT_CREDITS = gql`
  query getClientMonthlyEnrichmentCredits($clientId: ID!, $provider: String!) {
    client(id: $clientId) {
      id
      enrichmentMonthlyAmount(provider: $provider)
    }
  }
`;

interface PeriodQuotaSettingsProps {
  clientIntegration: ClientEnrichmentIntegration;
  userIntegration?: UserEnrichmentIntegration;
  providerTitle: string;
  providerLogo: ReactNode;
  providerType: string;
}

const PeriodQuotaSettings: FC<PeriodQuotaSettingsProps> = ({
  clientIntegration,
  userIntegration,
  providerTitle,
  providerLogo,
  providerType,
}) => {
  const { t } = useTranslation();

  const clientId = useClientId();
  const periodType = useMemo(() => {
    return (
      clientIntegration?.quota?.periodPolicy?.type ||
      // for now we only support monthly, so it's a good default
      'monthly'
    );
  }, [clientIntegration]);

  const nextUpdateDate = useMemo(() => {
    // TODO: add next period date to resolver and get from backend
    const { currentPeriod = null } = clientIntegration?.quota || {};
    if (!currentPeriod) {
      return null;
    }
    const endDate = new Date(currentPeriod.endDate);
    return endDate;
  }, [clientIntegration]);

  const [enableSystemCredits] = useEnableSystemEnrichmentIntegration(
    {
      clientIntegrationId: clientIntegration?.id,
      userIntegrationId: userIntegration?.id,
      hasAcceptedTerms: false,
      type: providerType,
    },
    {
      refetchQueries: [
        'getUserEnrichmentIntegrations',
        'getClientEnrichmentIntegrations',
      ],
    },
  );

  const [disableSystemCredits] = useDisableSystemEnrichmentIntegration({
    userIntegrationId: userIntegration?.id,
  });

  const toggleQuota = useCallback(
    (e, { checked }) => {
      if (checked) {
        enableSystemCredits();
        return;
      }

      if (disableSystemCredits) {
        disableSystemCredits();
      }
    },
    [enableSystemCredits, disableSystemCredits],
  );

  const currentQuota = clientIntegration?.quota?.currentPeriod || null;

  const { data: monthlyCreditsData } = useQuery<ClientEnrichmentCreditsResult>(
    CLIENT_MONTHLY_ENRICHMENT_CREDITS,
    {
      variables: {
        clientId,
        provider: 'contactout',
      },
    },
  );

  const monthlyCredits = useMemo(() => {
    return monthlyCreditsData?.client.enrichmentMonthlyAmount;
  }, [monthlyCreditsData]);

  // Handle while no data and handle if === 0
  if (!monthlyCredits) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.settings}>
        <div className={styles.illustration}>
          <div className={styles.logo}>{providerLogo}</div>
          <div className={styles.count}>{monthlyCredits}</div>
        </div>
        <div className={styles.main}>
          <div>
            <div className={styles.title}>
              <Toggle
                checked={clientIntegration?.status === 'active'}
                onChange={toggleQuota}
              />
              {t('providers.periodQuota.title', {
                provider: providerTitle,
                count: monthlyCredits,
                period: t(`providers.periodQuota.period.${periodType}`),
              })}
            </div>
            <div className={styles.description}>
              {t(`providers.periodQuota.description.${periodType}`)}
            </div>
          </div>
          {currentQuota && (
            <div className={styles.aside}>
              <span className={styles.usage}>
                {t(`providers.periodQuota.usage.${periodType}`, {
                  total: currentQuota.totalCreditsCount,
                  used: currentQuota.usedCreditsCount,
                  globalTotal:
                    clientIntegration.quota?.currentPeriod.totalCreditsCount,
                  globalUsed:
                    clientIntegration.quota?.currentPeriod.usedCreditsCount,
                })}
              </span>
              {nextUpdateDate && (
                <span className={styles.update}>
                  {t('providers.periodQuota.update', { date: nextUpdateDate })}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      {clientIntegration && <QuotaByUser provider={providerType} />}
    </div>
  );
};

export default PeriodQuotaSettings;
