/* eslint-disable react/no-unused-state */
import React from 'react';
import _, { compose } from 'underscore';
import * as Sentry from '@sentry/browser';
import { withTranslation } from 'react-i18next';
import { Button, Form, TextArea, Segment } from 'semantic-ui-react';

import ScaleRating from './ScaleRating';
import {
  sendRateFromPipeStepStats,
  totalSentProfiles,
} from '../../../../common/pipeStepStatsFunctions';
import withUserFromJWToken from '../../../../hocs/users/withUserFromJWToken';
import withActionLogger from '../../../../hocs/withActionLogger';
import withGetNextProfiles from '../../../../hocs/profiles/withGetNextProfiles';
import contextToProps from '../../../../hocs/contextToProps';
import withUpdatePushFlowPolicy from '../../../../hocs/offers/withUpdatePushFlowPolicy';
import withEnableAutopilot from '../../../../hocs/offers/withEnableAutopilot';
import withClient from '../../../../hocs/clients/withClient';

import OfferSettingPane from './OfferSettingPane';
import PodcastLink from './PodcastLink';

import './smartNoProfileLeftPane.css';

const numberOfPodcastEpisodes = 9;

class SmartNoProfileLeftPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ratePreviousSelection: false,
      hasDismissedAutopilotPane: false,
      hasDismissedPushFlowPane: false,
    };
  }

  handleRate = ({ rating }) => {
    this.setState({ rating });
  };

  handleEditComment = (e, { value }) => {
    this.setState({
      reason: value,
    });
  };

  handleSubmit = () => {
    if (!this.props.hasSubmittedSelectionFeedback) {
      this.props.onSubmitSelectionFeedback({
        offerId: this.props.offerId,
        rating: this.state.rating,
        reason: this.state.reason,
      });
    }
  };

  handleGetNextProfiles = async () => {
    const {
      getNextProfiles,
      offerId,
      unlimitedFlowBatchSize,
      onChangeProfile,
      onShowNotification,
      onLogAction,
      t,
    } = this.props;
    try {
      onLogAction({
        type: 'click-unlimited-flow',
        info: {
          jobOfferId: offerId,
        },
      });
    } catch (_error) {
      // Do nothing
    }
    try {
      const { data } = await getNextProfiles({
        offerId,
        profilesAmount: unlimitedFlowBatchSize,
      });
      if (
        data &&
        _.isArray(data.getNextProfiles) &&
        data.getNextProfiles.length >= 1 &&
        data.getNextProfiles[0] &&
        data.getNextProfiles[0].id
      ) {
        await onChangeProfile(data.getNextProfiles[0].id);
      } else {
        onShowNotification({
          message: t('smartLeftPane.getNextProfilesError'),
          level: 'error',
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      onShowNotification({
        message: t('smartLeftPane.getNextProfilesError'),
        level: 'error',
      });
    }
  };

  ratePreviousSelection = () => {
    this.setState({
      ratePreviousSelection: true,
    });
  };

  renderPushFlowPane = () => {
    const { t, updatePushFlowPolicy } = this.props;
    const acceptPushFlowPane = () => {
      this.onOfferAction({
        actionType: 'update-offer-push-flow',
        action: updatePushFlowPolicy,
        translateKey: 'updatePushFlowPolicy',
      });
    };
    const dismissPushFlowPane = () => {
      this.setState({ hasDismissedPushFlowPane: true });
      this.onOfferAction({
        actionType: 'dismiss-flow-augmentation-invitation-empty-pane',
      });
    };
    return (
      <OfferSettingPane
        paneName='pushFlowPane'
        onAccept={acceptPushFlowPane}
        onDismiss={dismissPushFlowPane}
        t={t}
      />
    );
  };

  renderAutopilotPane = () => {
    const { t, enableAutopilot } = this.props;
    const acceptAutopilotPane = () => {
      this.onOfferAction({
        actionType: 'enable-offer-autopilot',
        action: enableAutopilot,
        translateKey: 'enableAutopilot',
      });
    };

    const dismissAutopilotPane = () => {
      this.setState({ hasDismissedAutopilotPane: true });
      this.onOfferAction({
        actionType: 'dismiss-autopilot-invitation-empty-pane',
      });
    };
    return (
      <OfferSettingPane
        paneName='autopilotPane'
        onAccept={acceptAutopilotPane}
        onDismiss={dismissAutopilotPane}
        t={t}
      />
    );
  };

  renderArticlesPane = ({ afterFeedback, crmMode }) => {
    const { client, offerId, user, t } = this.props;
    const watchCollectPermission = client?.permissions?.watchCollect;
    const episodes = _.chain(numberOfPodcastEpisodes)
      .range()
      .map((index) => ({
        url: t(`podcastLinks.${index}.url`),
        title: t(`podcastLinks.${index}.title`),
        author: t(`podcastLinks.${index}.author`),
      }))
      .value();

    return (
      <div className='smart-no-profile-left-pane'>
        <div className='articles-pane'>
          {crmMode ? (
            <h2 className='articles-pane-title'>
              {t('smartLeftPane.noMorePendingProfiles')}
            </h2>
          ) : (
            <h2 className='articles-pane-title'>
              {afterFeedback
                ? t('smartLeftPane.thanks')
                : !watchCollectPermission
                ? t('smartLeftPane.waitingForNewProfiles')
                : t('smartLeftPane.waitingForNewWatchProfiles')}
            </h2>
          )}
          <div className='sub-header'>{t('smartLeftPane.tipsIntro')}</div>
          <div className='articles-container'>
            {_.map(episodes, ({ url, title, author }) => (
              <PodcastLink
                key={url}
                url={url}
                title={title}
                author={author}
                offerId={offerId}
                user={user}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  renderRatingForm = () => {
    const { t } = this.props;
    const { rating } = this.state;
    return (
      <Segment className='smart-no-profile-left-pane rating-form'>
        <h2>{t('smartLeftPane.dailySelection')}</h2>
        <div className='rating-container'>
          <ScaleRating
            maxRating={10}
            onRate={this.handleRate}
            lowLegend={t('smartLeftPane.lowRating')}
            highLegend={t('smartLeftPane.highRating')}
          />
        </div>
        <Form className='comment-container'>
          {rating && (
            <>
              <h5>{t('smartLeftPane.improveSearch')}</h5>
              <TextArea
                placeholder={t('smartLeftPane.feedbackPlaceholder')}
                rows={6}
                onChange={this.handleEditComment}
              />
            </>
          )}
        </Form>
        <Button
          className='rating-button'
          primary
          type='button'
          disabled={!rating}
          onClick={this.handleSubmit}
        >
          <span>{t('smartLeftPane.rateSelection')}</span>
        </Button>
      </Segment>
    );
  };

  renderUnlimitedFlowChoice = ({ afterFeedback }) => {
    const { t, unlimitedFlowBatchSize, hasTreatedPendingProfiles } = this.props;
    return (
      <div className='smart-no-profile-left-pane'>
        <div className='unlimited-flow-container'>
          {!afterFeedback ? (
            <div>
              <center>
                <h3>{t('smartLeftPane.fastForwardSearch')}</h3>
              </center>
              <p>{t('smartLeftPane.unlimitedFlowDescription')}</p>
              <center>
                <div className='actions-container'>
                  <Button onClick={this.handleGetNextProfiles}>
                    <span>
                      {t('smartLeftPane.displayMoreProfiles', {
                        count: unlimitedFlowBatchSize,
                      })}
                    </span>
                  </Button>
                </div>
                {hasTreatedPendingProfiles && (
                  <div className='rate-previous-selection'>
                    <span onClick={this.ratePreviousSelection}>
                      {t('smartLeftPane.ratePreviousSelection')}
                    </span>
                  </div>
                )}
              </center>
            </div>
          ) : (
            <center>
              <h3>{t('smartLeftPane.thanks')}</h3>
              <p>{t('smartLeftPane.unlimitedFlowAfterFeedbackDescription')}</p>
              <div className='actions-container'>
                <Button onClick={this.handleGetNextProfiles}>
                  <span>
                    {t('smartLeftPane.displayMoreProfiles', {
                      count: unlimitedFlowBatchSize,
                    })}
                  </span>
                </Button>
              </div>
            </center>
          )}
        </div>
      </div>
    );
  };

  onOfferAction = ({ actionType, action, translateKey }) => {
    const {
      clientId,
      offer,
      onLogAction,
      user,
      onShowNotification,
      t,
    } = this.props;
    if (offer.id) {
      try {
        onLogAction({
          type: actionType,
          info: {
            clientId,
            jobOfferId: offer.id,
            author: `${user.firstname} ${user.lastname}`,
          },
        });
      } catch (e) {
        // ignore
      }
      if (action) {
        action({ id: offer.id }).then(
          () =>
            onShowNotification({
              message: t(`offers.notifications.${translateKey}.success`),
              level: 'success',
            }),
          () =>
            onShowNotification({
              message: t(`offers.notifications.${translateKey}.error`),
              level: 'error',
            }),
        );
      }
    }
  };

  render() {
    const {
      offer,
      nbPrePending,
      unlimitedFlowBatchSize,
      hasTreatedPendingProfiles,
      hasSubmittedSelectionFeedback,
      client,
    } = this.props;

    const {
      ratePreviousSelection,
      hasDismissedAutopilotPane,
      rating,
    } = this.state; // hasDismissedPushFlowPane

    const crmMode = client?.customizations?.mainMode === 'crm';
    const canApplyunlimitedFlow =
      _.isNumber(unlimitedFlowBatchSize) &&
      unlimitedFlowBatchSize > 0 &&
      nbPrePending >= unlimitedFlowBatchSize &&
      !crmMode;

    const findSmartTip = (type) =>
      _.findWhere((offer || {}).smartTips, { type });

    const sendMilestoneGoal =
      (findSmartTip('sends-milestone') || {})
        .estimatedNbSendsByPositiveAnswer || 25;
    const hasReachedSendGoal =
      totalSentProfiles((offer || {}).pipeStepStats) > sendMilestoneGoal;

    // const shouldAskForPushFlowPolicy = ((offer || {}).pushFlowPolicy || {}).nbPerDay === 5
    //   && !(offer || {}).isHiresweetWatch
    //   && !(offer || {}).isUnclassified
    //   && !!findSmartTip('flow-augmentation-invitation')
    //   && hasReachedSendGoal
    //   && sendRateFromPipeStepStats((offer || {}).pipeStepStats) > 0.5
    //   && !hasDismissedPushFlowPane
    //   && rating
    //   && rating >= 6
    //   && !crmMode;

    const shouldAskForPushFlowPolicy = false;

    const shouldAskForAutopilot =
      !(offer || {}).isAutopilot &&
      !(offer || {}).isHiresweetWatch &&
      !(offer || {}).isUnclassified &&
      !!findSmartTip('autopilot-invitation') &&
      hasReachedSendGoal &&
      sendRateFromPipeStepStats((offer || {}).pipeStepStats) > 0.65 &&
      !hasDismissedAutopilotPane &&
      rating &&
      rating >= 7 &&
      !crmMode;

    if (!offer) {
      // blank pane while loading offer - avoids pane switching
      return null;
    }

    if (!ratePreviousSelection && canApplyunlimitedFlow) {
      return this.renderUnlimitedFlowChoice({ afterFeedback: false });
    }
    if (hasSubmittedSelectionFeedback && canApplyunlimitedFlow) {
      return this.renderUnlimitedFlowChoice({ afterFeedback: true });
    }
    if (hasSubmittedSelectionFeedback && shouldAskForPushFlowPolicy) {
      return this.renderPushFlowPane();
    }
    if (hasSubmittedSelectionFeedback && shouldAskForAutopilot) {
      return this.renderAutopilotPane();
    }
    if (hasSubmittedSelectionFeedback) {
      return this.renderArticlesPane({ afterFeedback: true, crmMode });
    }
    if ((hasTreatedPendingProfiles || ratePreviousSelection) && !crmMode) {
      return this.renderRatingForm();
    }

    return this.renderArticlesPane({ afterFeedback: false, crmMode });
  }
}

export default compose(
  withTranslation('translations'),
  contextToProps,
  withClient,
  withGetNextProfiles,
  withActionLogger,
  withUserFromJWToken,
  withEnableAutopilot,
  withUpdatePushFlowPolicy,
)(SmartNoProfileLeftPane);
