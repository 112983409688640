import gql from 'graphql-tag';
import classNames from 'classnames';
import React, { FC, useState } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import useClientId from '@/hooks/router/useClientId';

import {
  GenericTable,
  GenericTableRow,
} from '@/components/Common/GenericTable';
import { UserEnrichmentIntegration as UserIntegrationFragment } from '@/graphql/fragments/EnrichmentIntegration';
import { UserForEnrichmentIntegration } from './types';

import QuotaUserList from './components/QuotaUserList/QuotaUserList';

import styles from './QuotaByUser.module.less';

const GET_CLIENT_USERS = gql`
  query getUsersForEnrichmentIntegration($clientId: ID!) {
    client(id: $clientId) {
      id
      users {
        id
        email
        firstname
        lastname
        userCurrentClientSettings {
          id
          enrichmentIntegrations {
            ...UserEnrichmentIntegration
          }
        }
      }
    }
  }

  ${UserIntegrationFragment}
`;

interface UsersQueryResult {
  client: {
    users: UserForEnrichmentIntegration[];
  };
}

interface QuotaByUserProps {
  provider: string;
}

const QuotaByUser: FC<QuotaByUserProps> = ({ provider }) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { data } = useQuery<UsersQueryResult>(GET_CLIENT_USERS, {
    variables: { clientId },
    onError(e) {
      console.error(e);
    },
  });

  const users = data?.client.users;

  const [showUserList, setShowUserList] = useState(false);

  if (!users) {
    return null;
  }

  return (
    <div>
      <span
        className={styles.title}
        onClick={() => setShowUserList((show) => !show)}
      >
        {t('providers.quotaByUser.title')}
        <i
          className={classNames('icon angle', {
            down: !showUserList,
            up: showUserList,
          })}
        />
      </span>
      {showUserList && (
        <GenericTable>
          <thead>
            <GenericTableRow>
              <th>{t('reveal.parameters.customTasksSettings.table.name')}</th>
              <th>{t('providers.quotaByUser.enable')}</th>
              <th>{t('providers.quotaByUser.monthlyQuota')}</th>
              <th>{t('providers.quotaByUser.thisMonth')}</th>
            </GenericTableRow>
          </thead>
          <QuotaUserList provider={provider} users={users} />
        </GenericTable>
      )}
    </div>
  );
};

export default QuotaByUser;
