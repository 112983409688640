import React, { useState } from 'react';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Redirect, Link } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import * as Sentry from '@sentry/browser';

import withClient from '../../hocs/clients/withClient';
import withTokenFromUrl from '../../hocs/users/withTokenFromUrl';
import withActionLogger from '../../hocs/withActionLogger';
import withUserFromToken from '../../hocs/users/withUserFromToken';
import withResetPassword from '../../hocs/users/withResetPassword';
import { passwordIsStrong } from '../../common';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import PasswordChecks from './PasswordChecks';

import Layout from './Layout';

import './GenericSigninForm.css';

const ResetPasswordForm = ({
  clientId,
  client,
  onResetPassword,
  token,
  user,
  userLoading,
  t,
}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordIsWeak, setPasswordIsWeak] = useState(false);
  const [passwordInputHasFocus, setPasswordInputHasFocus] = useState(false);

  const onSubmit = () => {
    if (!passwordIsStrong(password)) {
      setLoading(false);
      setError(true);
      setSuccess(false);
      setPasswordIsWeak(true);
      setPasswordError(false);
      setTimeout(() => setPasswordIsWeak(false), 8000);
    } else if (password !== confirmPassword) {
      setLoading(false);
      setError(true);
      setSuccess(false);
      setPasswordError(true);
    } else {
      const userSubmitData = { password, clientId };
      setLoading(true);
      setError(false);
      return Promise.resolve()
        .then(() => onResetPassword(token, userSubmitData))
        .then(() => {
          setLoading(false);
          setError(false);
          setSuccess(true);
          setPasswordError(false);
        })
        .catch((e) => {
          Sentry.captureException(e);
          setLoading(false);
          setError(true);
        });
    }
    return null;
  };

  if (success) {
    return <Redirect to={`/client/${clientId}/dashboard`} />;
  }
  if (userLoading) {
    return <Layout />;
  }
  if (!client) {
    return <Layout title={t('resetPassword.unknownClientError')} />;
  }
  if (!user) {
    return (
      <Layout title={t('resetPassword.title')}>
        <div className='generic-signin-form'>
          <div className='form-details'>
            {t('resetPassword.noTokenMessage', { clientTitle: client.title })}
          </div>
          <Form.Field className='submit-field'>
            <Link to='signin'>
              <button className='submit-button' type='button'>
                {t('resetPassword.signin')}
              </button>
            </Link>
          </Form.Field>
        </div>
      </Layout>
    );
  }

  return (
    <Layout title={t('resetPassword.title')}>
      <Form
        className='generic-signin-form'
        error={error}
        loading={loading}
        onSubmit={onSubmit}
      >
        <Form.Field className='email-field'>
          <FloatingLabelInput
            label={t('resetPassword.form.account')}
            value={user ? user.email : ''}
            disabled
          />
        </Form.Field>
        <Form.Field className='password-field'>
          <FloatingLabelInput
            type='password'
            onFocus={() => setPasswordInputHasFocus(true)}
            onBlur={() => setPasswordInputHasFocus(false)}
            label={t('resetPassword.form.password')}
            value={password}
            onChange={(e) => setPassword(e?.target?.value)}
          />
          <PasswordChecks
            password={password}
            inputHasFocus={passwordInputHasFocus}
          />
        </Form.Field>
        <Form.Field className='password-field'>
          <FloatingLabelInput
            className={passwordError ? 'input-error' : ''}
            type='password'
            label={t('resetPassword.form.passwordConfirmation')}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e?.target?.value)}
            maxLength={100}
          />
        </Form.Field>
        {passwordIsWeak && (
          <div className='warning-message'>
            <br />
            <span className='error'>
              {t('resetPassword.form.passwordIsWeak')}
            </span>
          </div>
        )}
        <Form.Field className='submit-field'>
          <button className='submit-button' type='submit'>
            {t('resetPassword.form.submit')}
          </button>
        </Form.Field>
      </Form>
    </Layout>
  );
};

export default compose(
  withTokenFromUrl,
  withUserFromToken,
  withClient,
  withResetPassword,
  withActionLogger,
  withTranslation('translations'),
)(ResetPasswordForm);
