import React, { CSSProperties, FC } from 'react';
import { Placeholder } from 'semantic-ui-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useMiniSequence from '@/graphql/hooks/sequences/useMiniSequence';

import styles from './ClickColumn.module.less';

interface ClickColumnProps {
  day: string;
  sequenceId: string;
  profileLoading?: boolean;
  className?: string;
  style?: CSSProperties;
}

const ClickColumn: FC<ClickColumnProps> = ({
  day,
  sequenceId,
  profileLoading,
  className,
  style,
}) => {
  const { t, i18n } = useTranslation();

  const { loading: sequenceLoading, miniSequence } = useMiniSequence({
    sequenceId,
  });

  const clickDescription = t(
    'reveal.reports.sequences.linkClicks.detailsModal.clicked',
    {
      date: new Date(day).toLocaleString(i18n.resolvedLanguage, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
    },
  );

  return (
    <div className={classNames(styles.click, className)} style={style}>
      {sequenceLoading || profileLoading ? (
        <Placeholder>
          <Placeholder.Line length='short' />
        </Placeholder>
      ) : (
        <>
          <div className={styles.description}>{clickDescription}</div>
          <div className={styles.sequenceName}>
            {miniSequence?.title || '???'}
          </div>
        </>
      )}
    </div>
  );
};

export default ClickColumn;
