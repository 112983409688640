import _ from 'underscore';

import {
  ConditionsChainingVariable,
  MergeTagsVariable,
  isOneLineText,
  SNIPPET_INPUT_BY_TYPE,
  SNIPPET_TYPES,
} from '@/common/mergeTags/utils';
import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';

const createSnippetInput = ({ variable }: { variable: MergeTagsVariable }) => {
  const { id, name, type } = variable;
  const snippetKey = SNIPPET_INPUT_BY_TYPE[type];
  if (type === SNIPPET_TYPES.FRAGMENT || type === SNIPPET_TYPES.AI_TOKEN) {
    const fragmentVariable = variable as SweetEvaluatorTypes.BaseVariable &
      SweetEvaluatorTypes.FragmentVariable;
    const { text, snippets, options } = fragmentVariable;
    const cleanSnippets = _.filter(snippets || [], (snippet) => {
      return text.includes(snippet.id);
    });

    let snippetText;
    if (isOneLineText({ text })) {
      snippetText = text
        .replace('<div>', '<span>')
        .replace('</div>', '</span>');
    } else {
      snippetText = text;
    }
    return {
      [snippetKey]: {
        ...(id && { id }),
        name,
        type,
        text: snippetText,
        snippets: MergeTagsService.createSnippetsInput({
          snippets: cleanSnippets,
          subject: '',
          body: fragmentVariable.text,
        }),
        ...(options && { options }),
      },
    };
  }
  if (type === SNIPPET_TYPES.SELECT) {
    const selectVariable = variable as SweetEvaluatorTypes.BaseVariable &
      SweetEvaluatorTypes.SelectVariable;
    return {
      [snippetKey]: {
        ...(id && { id }),
        name,
        type,
        isMultiselect: true,
        options: _.map(selectVariable.options || [], (option) => ({
          ..._.omit(option, '__typename'),
          title: _.omit(option.title, '__typename'),
        })),
      },
    };
  }
  if (type === SNIPPET_TYPES.CONDITIONS_CHAINING) {
    return MergeTagsService.createConditionsChainingSnippetsInput(
      variable as SweetEvaluatorTypes.BaseVariable & ConditionsChainingVariable,
    );
  }
  return null;
};

export default createSnippetInput;
