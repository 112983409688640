import React from 'react';
import _ from 'underscore';
import { Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withOfferPendingProfiles } from '../../../../hocs/offers/withOfferProfiles';
import { MiniProfiles } from '../../../../components/ProfileBubbles';

const OfferMiniCard = withOfferPendingProfiles(
  ({ t, onLogAction, user, clientId, offerId, title, nbPending, profiles }) => {
    const miniProfiles = (profiles || []).slice(0, 3);
    const nbLeft = Math.max(nbPending - miniProfiles.length, 0);

    const onClick = () => {
      onLogAction({
        type: 'click-offer-link-from-empty-profile-view',
        info: {
          clientId,
          jobOfferId: offerId,
          author: `${user.firstname} ${user.lastname}`,
          url: `/client/${clientId}/jobs/${offerId}`,
        },
      });
    };

    return (
      <div className='offer-mini-card-container'>
        <Link to={`/client/${clientId}/jobs/${offerId}`} onClick={onClick}>
          <div className='offer-mini-card'>
            <div className='offer-title'>{title}</div>
            {!_.isEmpty(miniProfiles) && (
              <MiniProfiles profiles={miniProfiles} nbLeft={nbLeft} />
            )}
          </div>
        </Link>
      </div>
    );
  },
);

export default OfferMiniCard;
