import { ResumeData } from '@/types/searchPoolProfile';
import { TaskType } from '@/types/task';
import { UserPermissions } from '@/types/user';
import { LINKEDIN_MESSAGE_SUBTYPES, TASK_TYPES } from '../constants/taskTypes';

const getLinkedinURL = ({
  resumeData,
  actionType,
  actionSubtype,
  userPermissions,
}: {
  resumeData?: ResumeData;
  actionType?: TaskType;
  actionSubtype: string;
  userPermissions?: UserPermissions;
}): {
  type?: 'linkedin-recruiter' | 'linkedin-salesnavigator' | 'linkedin';
  url?: string;
} => {
  if (!resumeData) {
    return {};
  }

  const linkedinRecruiterProfileId =
    resumeData?.sourceData?.linkedinRecruiter?.id;
  const linkedinSalesNavigatorProfileId =
    resumeData?.sourceData?.linkedinSalesNavigator?.id;
  const linkedinCommonProfileId = resumeData?.sourceData?.linkedinCommon?.id;

  if (actionType === TASK_TYPES.LINKEDIN_SEND_MESSAGE) {
    if (
      actionSubtype === LINKEDIN_MESSAGE_SUBTYPES.CLASSIC &&
      resumeData?.sources?.linkedin
    ) {
      return {
        type: 'linkedin',
        url: `${resumeData?.sources?.linkedin}/`,
      };
    }

    if (
      actionSubtype === LINKEDIN_MESSAGE_SUBTYPES.RECRUITER &&
      (linkedinRecruiterProfileId || linkedinCommonProfileId)
    ) {
      return {
        type: 'linkedin-recruiter',
        url: `https://linkedin.com/talent/profile/${linkedinRecruiterProfileId ||
          linkedinCommonProfileId}?rightRail=composer`,
      };
    }

    if (
      actionSubtype === LINKEDIN_MESSAGE_SUBTYPES.SALESNAVIGATOR &&
      (linkedinSalesNavigatorProfileId || linkedinCommonProfileId)
    ) {
      return {
        type: 'linkedin-salesnavigator',
        url: `https://www.linkedin.com/sales/lead/${linkedinSalesNavigatorProfileId ||
          linkedinCommonProfileId},name/`,
      };
    }

    // if subtype is not defined
    if (
      (linkedinRecruiterProfileId || linkedinCommonProfileId) &&
      userPermissions?.crmTaskOnLinkedinRecruiter
    ) {
      return {
        type: 'linkedin-recruiter',
        url: `https://linkedin.com/talent/profile/${linkedinRecruiterProfileId ||
          linkedinCommonProfileId}?rightRail=composer`,
      };
    }
  }

  if (resumeData?.sources?.linkedin) {
    return {
      type: 'linkedin',
      url: `${resumeData?.sources?.linkedin}/`,
    };
  }
  return {};
};

export default getLinkedinURL;
