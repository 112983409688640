import React from 'react';
import _ from 'underscore';
import * as Sentry from '@sentry/browser';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  ADD_PROFILE_COMMENT,
  LOG_PROFILE_ACTIVITY,
} from '@/graphql/searchPoolProfile';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import { ArchiveReason, GET_ARCHIVE_REASONS } from '@/graphql/archiveReasons';
import { getProfileMissionId, getSubtype, getStageName } from './helpers';
import BaseLogActivityForm, { ActivityLog } from './LogActivityForm';

type LogActivityFormProps = {
  searchPoolId: string;
  clientId: string;
  dense?: boolean;
  handleClose?: () => void;
  onSubmitSuccess?: () => void;
};

const LogActivityForm: React.FC<LogActivityFormProps> = ({
  searchPoolId,
  clientId,
  dense,
  handleClose,
  onSubmitSuccess,
}) => {
  const { t } = useTranslation();
  const { profile } = useCandidateViewContext();
  const notification = useNotificationSystem();
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();

  // LogActivityForm uses `react-hook-form` that has the capacity to reset forms.
  // Though LogActivityForm uses input components that are unfit with `react-hook-form`
  // because they are not uncontrolled.
  // That's a bummer: as fallback we just re-render the Form component using the `key` prop.
  const [formVersion, setFormVersion] = React.useState(0);
  const resetForm = React.useCallback(() => setFormVersion((v) => v + 1), [
    setFormVersion,
  ]);

  const [logActivity, { loading: logProfileMutationLoading }] = useMutation(
    LOG_PROFILE_ACTIVITY,
  );
  const [
    addProfileComment,
    { loading: addProfileCommentMutationLoading },
  ] = useMutation(ADD_PROFILE_COMMENT);
  const loading = logProfileMutationLoading || addProfileCommentMutationLoading;

  const { data: archiveReasonsData } = useQuery(GET_ARCHIVE_REASONS, {
    variables: { clientId },
    fetchPolicy: 'network-only',
  });

  const clientArchiveReasons: ArchiveReason[] =
    archiveReasonsData?.client?.archiveReasons;

  const onSubmitActivityLog = async (payload: ActivityLog) => {
    try {
      if (payload.channel === 'note') {
        await addProfileComment({
          variables: {
            searchPoolId: 'reveal',
            id: profile.id,
            comment: payload.note,
            synchronize: payload.synchronize,
          },
        });
      } else {
        const {
          answerLabel,
          date,
          shouldInterruptSequence,
          shouldMoveToStageArchived,
          archivedEngagement,
          synchronize,
        } = payload;
        const subtype = getSubtype(payload);
        const labels: string[] = [];
        if (answerLabel) {
          if (![
            "call", "meeting","texting-message-to-profile","texting-message-from-profile",
            "vocal-message-to-profile","vocal-message-from-profile","inspection-tour",
            "call-to-profile","call-from-profile"
          ].includes(subtype)) {
            labels.push(answerLabel);
          }
        }
        if (shouldMoveToStageArchived && archivedEngagement) {
          labels.push(archivedEngagement);
        }
        const missionId = getProfileMissionId(profile);
        const clientArchiveReason = _.find(
          clientArchiveReasons,
          ({ id }) => id === archivedEngagement,
        );
        await logActivity({
          variables: {
            searchPoolId,
            profileId: profile.id,
            ...(missionId && { missionId }),
            date: date === 'now' ? null : date,
            payload: {
              subtype,
              note: payload.note,
              shouldInterruptSequence,
              moveToStageName: getStageName(payload),
              ...(!_.isEmpty(labels) && {
                answerLabels: labels,
              }),
              ...(clientArchiveReason?.id && {
                clientArchiveReasonId: clientArchiveReason?.id,
              }),
            },
            synchronize,
          },
        });
      }
      resetForm();
      publishSubscriptionEvent('onProfileTimelineUpdate', { id: profile.id });
      notification.success(
        t('reveal.timeline.activityLog.notifications.createSuccess'),
      );
      // eslint-disable-next-line no-unused-expressions
      onSubmitSuccess && onSubmitSuccess();
    } catch (e) {
      notification.error(
        t('reveal.timeline.activityLog.notifications.createError'),
      );
      Sentry.captureException(e);
    }
  };

  return (
    <BaseLogActivityForm
      key={formVersion}
      clientId={clientId}
      onSubmit={onSubmitActivityLog}
      handleClose={handleClose}
      submitLoading={loading}
      dense={dense}
      clientArchiveReasons={clientArchiveReasons}
    />
  );
};

LogActivityForm.defaultProps = {
  handleClose: () => {
    return false;
  },
  onSubmitSuccess: () => {
    return false;
  },
  dense: false,
};

export default LogActivityForm;
