import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import EmptyState from '@/revealComponents/EmptyState/EmptyState';
import GenericButton from '@/components/Common/GenericButton';

interface TemplatesEmptyStateProps {
  onClick: () => void;
}

const TemplatesEmptyState: React.FC<TemplatesEmptyStateProps> = ({
  onClick,
}) => {
  const { applicationMode } = useNavApplicationMode();
  const { t } = useTranslation();

  return (
    <EmptyState
      title={t('reveal.templates.emptyState.title')}
      innerContent={t(
        applicationMode === 'reveal'
          ? 'reveal.templates.emptyState.innerContent'
          : 'dashboard.templates.noTemplates',
      )}
      illustrationPath='/images/placeholders/templatesEmptyState.svg'
      cta={
        <GenericButton size='big' onClick={onClick}>
          {t('reveal.templates.emptyState.cta')}
        </GenericButton>
      }
    />
  );
};

export default TemplatesEmptyState;
