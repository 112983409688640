/* eslint-disable class-methods-use-this */
import _ from 'underscore';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import {
  CurrentExperienceDurationVariable,
  getMergeTagsCalculatedDuration,
} from '@/common/mergeTags/utils';
import { sweetDateToMomentDate } from '@/common/dates';

export default class ExpressionCurrentExperienceDurationEvaluator
  implements SweetEvaluatorTypes.EvaluatorInterface {
  evaluate({
    context,
    expression,
  }: {
    context: SweetEvaluatorTypes.Context;
    expression: SweetEvaluatorTypes.Variable;
  }): { value: string | undefined } {
    const variable = (expression as unknown) as SweetEvaluatorTypes.BaseVariable &
      CurrentExperienceDurationVariable;

    const anyContext = context as any;
    const profileExperiences =
      anyContext.experiences || anyContext.data?.experiences || null;

    if (_.isEmpty(profileExperiences)) {
      return { value: undefined };
    }

    const currentExperience = _.find(
      profileExperiences,
      ({ endDate }) => !endDate,
    );

    if (
      _.isEmpty(currentExperience) ||
      _.isEmpty(currentExperience?.startDate)
    ) {
      return { value: undefined };
    }

    const { format, locale } = variable;

    const startDate = sweetDateToMomentDate(currentExperience?.startDate);

    if (!startDate) {
      return { value: undefined };
    }

    return {
      value:
        getMergeTagsCalculatedDuration({
          startDate: startDate.toISOString(),
          endDate: new Date().toISOString(),
          format,
          locale,
        })?.calculatedValue || undefined,
    };
  }
}
