import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import { StackItem } from '@/types/statistics/stackItem';
import { NumberRecord, Stats } from '@/types/statistics/stats';
import {
  getDisplayedValue,
  getStackName,
  getTotalCount,
} from '@/common/helpers/stats';
import TooltipContainer from '@/components/Common/TooltipContainer';
import { getPercentage } from '@/common/utils/number';
import LegendCategory from '../Legend/LegendCategory';
import Area from './Area';

import styles from './StackedArea.module.less';

interface StackedAreaProps {
  stack: StackItem[];
  stats: Stats;
  previousValues?: NumberRecord;
  topBoundary: number;
  isPercentage?: boolean;
}

const StackedArea: FC<StackedAreaProps> = ({
  stack,
  stats,
  previousValues,
  topBoundary,
  isPercentage,
}) => {
  const { t } = useTranslation();

  const filteredStack = useMemo(
    () =>
      _.filter(
        stack,
        (stackItem) =>
          stats.values[stackItem.datakey] !== 0 ||
          (previousValues?.[stackItem.datakey] || 0) !== 0,
      ),
    [stack, stats, previousValues],
  );

  const tooltipContent = useMemo(
    () =>
      getTotalCount(stats) === 0 ? null : (
        <div className={styles.tooltip}>
          <div className={styles.tooltipHeader}>
            <span>{stats.name}</span>
            <span>{getTotalCount(stats)}</span>
          </div>
          <div>
            {_.map([...filteredStack].reverse(), (stackItem) => (
              <div className={styles.tooltipItem} key={stackItem.datakey}>
                <LegendCategory color={stackItem.color}>
                  {getStackName(t, stackItem)}
                </LegendCategory>
                <span>
                  {getDisplayedValue(t, isPercentage)(stats, stackItem.datakey)}
                </span>
              </div>
            ))}
          </div>
        </div>
      ),
    [filteredStack, stats, t, isPercentage],
  );

  const data = _.reduce(
    filteredStack,
    (accumulator, stackItem) => {
      const { datakey, color } = stackItem;
      if (!stats.values[datakey] && !previousValues?.[datakey]) {
        return accumulator;
      }
      const {
        previousValue: lastPreviousValue,
        value: lastValue,
        offset: lastOffset,
        previousOffset: lastPreviousOffset,
      } = _.last(accumulator) || {};
      const offset = (lastValue || 0) + (lastOffset || 0);
      const previousOffset =
        (lastPreviousValue || 0) + (lastPreviousOffset || 0);
      return [
        ...accumulator,
        {
          datakey,
          name: getStackName(t, stackItem),
          value: stats.values[datakey],
          previousValue: previousValues?.[datakey] || 0,
          color,
          offset,
          previousOffset,
        },
      ];
    },
    [] as {
      name: string;
      value: number;
      previousValue: number;
      datakey: string;
      color: string;
      offset: number;
      previousOffset: number;
    }[],
  );

  const leftTop = isPercentage
    ? (_.last(data)?.previousOffset || 1) + (_.last(data)?.previousValue || 1)
    : topBoundary;

  const rightTop = isPercentage
    ? (_.last(data)?.offset || 1) + (_.last(data)?.value || 1)
    : topBoundary;

  return (
    <TooltipContainer tooltipContent={tooltipContent}>
      <div className={styles.stackedArea}>
        {_.map(
          data,
          ({
            datakey,
            color,
            value,
            previousValue,
            offset,
            previousOffset,
          }) => (
            <Area
              key={datakey}
              color={color}
              leftOffset={getPercentage(previousOffset, leftTop, 2)}
              leftValue={getPercentage(previousValue, leftTop, 2)}
              rightOffset={getPercentage(offset, rightTop, 2)}
              rightValue={getPercentage(value, rightTop, 2)}
            />
          ),
        )}
      </div>
    </TooltipContainer>
  );
};

export default StackedArea;
