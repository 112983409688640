import React from 'react';
import NavClassic, { NavClassicProps } from './NavClassic';
import NavReveal, { NavRevealProps } from './NavReveal';
import { useNavApplicationMode } from './NavSwitch.hook';

interface NavConditionalProps extends NavClassicProps, NavRevealProps {}
const NavConditional: React.FC<NavConditionalProps> = (props) => {
  const { clientId } = props;
  const { applicationMode, loading, error } = useNavApplicationMode();

  if (loading || error || !clientId) {
    return null;
  }

  switch (applicationMode) {
    case 'reveal':
      return <NavReveal {...props} />;
    case 'classic':
    default:
      return <NavClassic {...props} />;
  }
};

export default NavConditional;
