/* eslint-disable import/prefer-default-export */
import React from 'react';
import { OperationVariables, useApolloClient } from '@apollo/client';
import { DocumentNode } from 'graphql';

export function useLazyQueryPromisified<
  TData = any,
  TVariables = OperationVariables
>(query: DocumentNode) {
  const client = useApolloClient();
  return React.useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query,
        variables,
      }),
    // eslint-disable-next-line
    [client],
  );
}
