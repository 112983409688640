import {
  FetchResult,
  gql,
  MutationHookOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

const DELETE_SAVED_SEARCH = gql`
  mutation deleteSavedSearch($input: DeleteRevealSavedSearchInput!) {
    revealSavedSearch {
      delete(input: $input) {
        id
      }
    }
  }
`;

interface DeleteSavedSearchData {
  revealSavedSearch: {
    delete: {
      id: string;
    };
  };
}

interface DeleteSavedSearchVariables {
  input: {
    id: string;
  };
}

interface UseDeleteSavedSearchInput {
  mutationOptions?: MutationHookOptions<
    DeleteSavedSearchData,
    DeleteSavedSearchVariables
  >;
}

type UseDeleteSavedSearchResult = [
  (
    input: DeleteSavedSearchVariables['input'],
  ) => Promise<FetchResult<DeleteSavedSearchData>>,
  MutationResult<DeleteSavedSearchData>,
];

const useDeleteSavedSearch = ({
  mutationOptions = {},
}: UseDeleteSavedSearchInput = {}): UseDeleteSavedSearchResult => {
  const [mutation, result] = useMutation<
    DeleteSavedSearchData,
    DeleteSavedSearchVariables
  >(DELETE_SAVED_SEARCH, {
    ...mutationOptions,
  });

  return [
    (input) =>
      mutation({
        variables: { input },
        update: (cache, { data }) => {
          if (data?.revealSavedSearch.delete.id) {
            cache.evict({
              id: `RevealSavedSearch:${data.revealSavedSearch.delete.id}`,
            });
          }
        },
      }),
    result,
  ];
};

export default useDeleteSavedSearch;
