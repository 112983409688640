import React, { useMemo, useState } from 'react';
import { Dropdown, TextArea } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import { TFunction } from 'i18next';
import InfoTooltip from '@/components/Common/InfoTooltip';

import { Action } from '@/types/action';
import {
  DELAY_AFTER_ACTION,
  MANUAL_TRIGGER,
  TASK_TYPES,
} from '@/common/constants/taskTypes';
import ProfileRowSequence from '@/routes/RevealView/ProfileRow/ProfileRowSequence';
import GenericTextInput from '@/components/Common/GenericTextInput';
import GenericSelect from '@/components/Common/GenericSelect';
import { TYPES_WITH_DESCRIPTION, TYPES_WITH_MESSAGE } from './helpers';
import styles from './GenerateSequence.module.less';

export type SequenceGenerationFormState = {
  basePrompts: string[];
  context: {
    language: string;
    tone: string;
    companyDescription: {
      description: string;
    };
    jobDescription: {
      description: string;
    };
    jobPosition: string;
  };
  computePolicy: string;
  streamId?: string;
  fullname?: string;
};

interface GenerateSequenceFormProps {
  clientId: string;
  formState: SequenceGenerationFormState;
  setFormState: (state: SequenceGenerationFormState) => void;
  showPrompt: boolean;
  multipleActions?: boolean;
  setActions?: (actions: any[]) => void;
  inModal?: boolean;
}

const getSuggestedSequences = ({ t }: { t: TFunction }) => {
  return [
    {
      title: t('sequences.generation.modal.structure.sequence-1'),
      id: 'sequence-1',
      actions: [
        {
          type: TASK_TYPES.SEND_EMAIL_MANUAL,
          actionId: 'sequence-1-action-1',
          trigger: {
            type: MANUAL_TRIGGER,
          },
        },
        {
          type: TASK_TYPES.SEND_EMAIL,
          actionId: 'sequence-1-action-2',
          trigger: {
            type: DELAY_AFTER_ACTION,
            delay: {
              unit: 'day',
              value: 7,
            },
          },
        },
        {
          type: TASK_TYPES.SEND_EMAIL,
          actionId: 'sequence-1-action-3',
          trigger: {
            type: DELAY_AFTER_ACTION,
            delay: {
              unit: 'day',
              value: 7,
            },
          },
        },
      ],
    },
    {
      title: t('sequences.generation.modal.structure.sequence-2'),
      id: 'sequence-2',
      actions: [
        {
          type: TASK_TYPES.LINKEDIN_SEND_REQUEST,
          actionId: 'sequence-2-action-1',
          trigger: {
            type: MANUAL_TRIGGER,
          },
        },
        {
          type: TASK_TYPES.LINKEDIN_SEND_MESSAGE,
          actionId: 'sequence-2-action-2',
          trigger: {
            type: DELAY_AFTER_ACTION,
            delay: {
              unit: 'working-day',
              value: 3,
            },
          },
        },
        {
          type: TASK_TYPES.LINKEDIN_SEND_MESSAGE,
          actionId: 'sequence-2-action-3',
          trigger: {
            type: DELAY_AFTER_ACTION,
            delay: {
              unit: 'working-day',
              value: 7,
            },
          },
        },
        {
          type: TASK_TYPES.LINKEDIN_RECRUITER_INMAIL,
          actionId: 'sequence-2-action-4',
          trigger: {
            type: DELAY_AFTER_ACTION,
            delay: {
              unit: 'working-day',
              value: 7,
            },
          },
        },
      ],
    },
    // {
    //   title: t('sequences.generation.modal.structure.sequence-3'),
    //   id: 'sequence-3',
    //   actions: [
    //     {
    //       type: TASK_TYPES.SEND_TEXT_MESSAGE,
    //       actionId: 'sequence-3-action-1',
    //       trigger: {
    //         type: MANUAL_TRIGGER,
    //       },
    //     },
    //     {
    //       type: TASK_TYPES.SEND_TEXT_MESSAGE,
    //       actionId: 'sequence-3-action-2',
    //       trigger: {
    //         type: DELAY_AFTER_ACTION,
    //         delay: {
    //           unit: 'day',
    //           value: 7,
    //         },
    //       },
    //     },
    //     {
    //       type: TASK_TYPES.MAKE_PHONE_CALL,
    //       actionId: 'sequence-3-action-3',
    //       trigger: {
    //         type: DELAY_AFTER_ACTION,
    //         delay: {
    //           unit: 'day',
    //           value: 7,
    //         },
    //       },
    //     },
    //   ],
    // },
    {
      title: t('sequences.generation.modal.structure.sequence-4'),
      id: 'sequence-4',
      actions: [
        {
          type: TASK_TYPES.LINKEDIN_SEND_MESSAGE,
          actionId: 'sequence-4-action-1',
          trigger: {
            type: MANUAL_TRIGGER,
          },
        },
        {
          type: TASK_TYPES.SEND_EMAIL_MANUAL,
          actionId: 'sequence-4-action-2',
          trigger: {
            type: DELAY_AFTER_ACTION,
            delay: {
              unit: 'day',
              value: 2,
            },
          },
        },
        {
          type: TASK_TYPES.SEND_EMAIL,
          actionId: 'sequence-4-action-3',
          trigger: {
            type: DELAY_AFTER_ACTION,
            delay: {
              unit: 'day',
              value: 5,
            },
          },
        },
        {
          type: TASK_TYPES.LINKEDIN_SEND_MESSAGE,
          actionId: 'sequence-4-action-4',
          trigger: {
            type: DELAY_AFTER_ACTION,
            delay: {
              unit: 'day',
              value: 3,
            },
          },
        },
        // {
        //   type: TASK_TYPES.SEND_TEXT_MESSAGE,
        //   actionId: 'sequence-4-action-5',
        //   trigger: {
        //     type: DELAY_AFTER_ACTION,
        //     delay: {
        //       unit: 'day',
        //       value: 7,
        //     },
        //   },
        // },
        // {
        //   type: TASK_TYPES.MAKE_PHONE_CALL,
        //   actionId: 'sequence-4-action-6',
        //   trigger: {
        //     type: DELAY_AFTER_ACTION,
        //     delay: {
        //       unit: 'day',
        //       value: 7,
        //     },
        //   },
        // },
      ],
    },
  ];
};

export const STRING_ITEM_TO_GENERATE_FROM_TYPE: Record<string, string> = {
  [TASK_TYPES.SEND_EMAIL]: 'outreach email',
  [TASK_TYPES.SEND_EMAIL_MANUAL]: 'outreach email',
  [TASK_TYPES.LINKEDIN_SEND_MESSAGE]: 'outreach LinkedIn message',
  [TASK_TYPES.LINKEDIN_RECRUITER_INMAIL]: 'outreach LinkedIn message',
  [TASK_TYPES.LINKEDIN_PREMIUM_INMAIL]: 'outreach LinkedIn message',
  [TASK_TYPES.LINKEDIN_SALESNAVIGATOR_INMAIL]: 'outreach LinkedIn message',
  [TASK_TYPES.LINKEDIN_SEND_REQUEST]:
    'short note added to a LinkedIn invitation requesting the candidate to accept your network connection',
  [TASK_TYPES.SEND_TEXT_MESSAGE]: 'outreach text message',
  [TASK_TYPES.WHATSAPP_SEND_MESSAGE]: 'WhatsApp message',
};

export const getSequenceTypeFromActionTypes = (types: string[]): string => {
  const uniqueTypes = _.uniq(
    _.map(types, (type) =>
      type === TASK_TYPES.LINKEDIN_SEND_REQUEST
        ? 'outreach LinkedIn messages'
        : `${STRING_ITEM_TO_GENERATE_FROM_TYPE[type]}s`,
    ),
  );
  if (uniqueTypes.length > 1) {
    return uniqueTypes.join(' and ');
  }
  return `${uniqueTypes?.[0]}`;
};

export const getBulkPromptFromActionType = (
  action: any,
  hasPreviousMailAction: boolean,
  previousAction: Action,
  index: number,
) => {
  if (
    (!_.contains(TYPES_WITH_MESSAGE, action.type) &&
      !_.contains(TYPES_WITH_DESCRIPTION, action.type)) ||
    action.type === TASK_TYPES.MAKE_PHONE_CALL
  ) {
    return '';
  }

  let prompt = '';
  const stringItem = STRING_ITEM_TO_GENERATE_FROM_TYPE[action.type] || '';
  const isLinkedinMessageAfterLinkedinInvitation =
    previousAction?.type === TASK_TYPES.LINKEDIN_SEND_REQUEST &&
    action.type === TASK_TYPES.LINKEDIN_SEND_MESSAGE;
  if (isLinkedinMessageAfterLinkedinInvitation) {
    prompt =
      'a LinkedIn message to be sent once the LinkedIn invitation was accepted by the candidate.';
  } else if (hasPreviousMailAction) {
    prompt = `a follow-up ${stringItem}`;
  } else {
    prompt = `${
      stringItem.indexOf('outreach') === -1 ? 'a' : 'an'
    } ${stringItem}`;
  }
  if (hasPreviousMailAction) {
    if (!isLinkedinMessageAfterLinkedinInvitation) {
      if (action.trigger) {
        const delay =
          action.trigger.delay.unit === 'working-day'
            ? 'a few days'
            : `${action.trigger.delay.value} ${action.trigger.delay.unit}`;
        prompt += ` sent ${delay} after the message ${index}`;
      }
      prompt += ' if you had no answer to';
      for (let i = 1; i <= index; i += 1) {
        if (i > 1) {
          if (i === index) {
            prompt += ' and';
          } else {
            prompt += ',';
          }
        }
        prompt += ` the message ${i}`;
        if (i === index) {
          prompt += '.';
        }
      }
    }
    prompt += ` Make sure the message ${index + 1} is different than`;
    for (let i = 1; i <= index; i += 1) {
      if (i > 1) {
        if (i === index) {
          prompt += ' and';
        } else {
          prompt += ',';
        }
      }
      prompt += ` the message ${i}`;
      if (i === index) {
        prompt += '.';
      }
    }
  }
  return prompt;
};

const getSequentialPromptFromActionType = (
  action: Action,
  hasPreviousMailAction: boolean,
) => {
  if (
    (!_.contains(TYPES_WITH_MESSAGE, action.type) &&
      !_.contains(TYPES_WITH_DESCRIPTION, action.type)) ||
    action.type === TASK_TYPES.MAKE_PHONE_CALL
  ) {
    return '';
  }
  const stringItem = STRING_ITEM_TO_GENERATE_FROM_TYPE[action.type] || '';
  if (hasPreviousMailAction) {
    return `Write a follow-up ${stringItem} to a candidate.`;
  }
  return `Write ${
    stringItem.indexOf('outreach') === -1 ? 'a' : 'an'
  } ${stringItem} to a candidate as company.`;
};

export const getPromptFromActionType = (
  action: Action,
  computePolicy: string,
  hasPreviousMailAction: boolean,
  index: number,
  previousAction: Action,
) => {
  if (computePolicy === 'bulk') {
    return getBulkPromptFromActionType(
      action,
      hasPreviousMailAction,
      previousAction,
      index,
    );
  }
  return getSequentialPromptFromActionType(action, hasPreviousMailAction);
};

const GenerateSequenceForm: React.FC<GenerateSequenceFormProps> = ({
  clientId,
  formState,
  setFormState,
  showPrompt,
  multipleActions = false,
  setActions,
  inModal = false,
}) => {
  const { t } = useTranslation();
  const [selectedSequence, setSelectedSequence] = useState('sequence-1');
  const { language, tone, companyDescription, jobDescription } =
    formState?.context || {};

  const onChangeLanguage = (newLanguage: string) => {
    setFormState({
      ...formState,
      context: {
        ...formState.context,
        language: newLanguage,
      },
    });
  };

  const onChangeTone = (newTone: string) => {
    setFormState({
      ...formState,
      context: {
        ...formState.context,
        tone: newTone,
      },
    });
  };

  const onChangePrompt = (newPrompt: string) => {
    setFormState({
      ...formState,
      basePrompts: [newPrompt],
    });
  };

  const onChangeCompanyDescription = (description: string) => {
    setFormState({
      ...formState,
      context: {
        ...formState.context,
        companyDescription: {
          description,
        },
      },
    });
  };

  const onChangeJobDescription = (description: string) => {
    setFormState({
      ...formState,
      context: {
        ...formState.context,
        jobDescription: {
          description,
        },
      },
    });
  };

  const onChangeFullname = (fullname: string) => {
    setFormState({
      ...formState,
      fullname,
    });
  };

  const onChangeJobPosition = (position: string) => {
    setFormState({
      ...formState,
      context: {
        ...formState.context,
        jobPosition: position,
      },
    });
  };

  const sequenceTemplateOptions = _.map(
    getSuggestedSequences({ t }),
    (template) => {
      return {
        key: template.id,
        value: template.id,
        text: (
          <ProfileRowSequence
            inlineTitle
            currentSequenceInfo={{
              contactFlow: {
                sequences: [template],
                id: selectedSequence,
              },
              sequence: template,
              title: template.title,
            }}
            sequences={[template]}
            nextInteraction=''
            loading={false}
            mini
            hideTitle={false}
            showStateIcons={false}
          />
        ),
      };
    },
  );

  useMemo(() => {
    const currentlySelectedSequence = _.findWhere(
      getSuggestedSequences({ t }),
      {
        id: selectedSequence,
      },
    );
    if (currentlySelectedSequence && setActions) {
      setActions(currentlySelectedSequence?.actions);
    }
    // eslint-disable-next-line
  }, [selectedSequence]);

  const onActionChange = (value: string) => {
    setSelectedSequence(value);
  };

  const setComputePolicy = (value: string) => {
    setFormState({
      ...formState,
      computePolicy: value,
    });
  };

  const languageOptionMap = useMemo(
    () =>
      ({
        french: {
          value: 'french',
          label: t('langs.french'),
        },
        english: {
          value: 'english',
          label: t('langs.english'),
        },
        spanish: {
          value: 'spanish',
          label: t('langs.spanish'),
        },
        dutch: {
          value: 'dutch',
          label: t('langs.dutch'),
        },
        italian: {
          value: 'italian',
          label: t('langs.italian'),
        },
        portuguese: {
          value: 'portuguese',
          label: t('langs.portuguese'),
        },
      }) as Record<string, { label: string; value: string }>,
    [t],
  );

  const languageOptions = useMemo(
    () => _.values(languageOptionMap),
    [languageOptionMap],
  );

  return (
    <div className={styles.formContainer}>
      {clientId === 'ai-seq-demo' && (
        <>
          <div className={styles.formGroup}>
            <h4 className={styles.formTitle}>
              {t('sequences.generation.modal.fullname')}
            </h4>
            <GenericTextInput
              onValue={onChangeFullname}
              value={formState.fullname}
              className={styles.formInput}
              placeholder={t('sequences.generation.modal.fullnamePlaceholder')}
            />
          </div>
          <div className={styles.formGroup}>
            <h4 className={styles.formTitle}>
              {t('sequences.generation.modal.jobPosition')}
            </h4>
            <GenericTextInput
              value={formState.context.jobPosition}
              className={styles.formInput}
              onValue={onChangeJobPosition}
            />
          </div>
        </>
      )}
      <div className={styles.formGroup}>
        <h4 className={styles.formTitle}>
          {t('sequences.generation.modal.language')}
        </h4>
        <GenericSelect
          className={styles.languageDropdown}
          onChange={(value) => onChangeLanguage(value?.value || 'english')}
          value={languageOptionMap[language]}
          placeholder={t('sequences.generation.modal.languagePlaceholder')}
          options={languageOptions}
        />
      </div>
      <div className={styles.toneContainer}>
        <h4 className={styles.formTitle}>
          {t('sequences.generation.modal.toneOfVoice')}
          <InfoTooltip position='right center'>
            {t('sequences.generation.modal.toneTooltip')}
          </InfoTooltip>
        </h4>
        <GenericTextInput
          onValue={onChangeTone}
          value={tone}
          className={styles.formInput}
        />
        {/* <div className={styles.tones}>
          <div
            onClick={() => onChangeTone('funny')}
            className={classnames(
              styles.tone,
              tone === 'funny' ? styles.selected : '',
            )}
          >
            {t('sequences.generation.modal.tone.funny')}
          </div>
          <div
            onClick={() => onChangeTone('casual')}
            className={classnames(
              styles.tone,
              tone === 'casual' ? styles.selected : '',
            )}
          >
            {t('sequences.generation.modal.tone.casual')}
          </div>
          <div
            onClick={() => onChangeTone('formal')}
            className={classnames(
              styles.tone,
              tone === 'formal' ? styles.selected : '',
            )}
          >
            {t('sequences.generation.modal.tone.formal')}
          </div>
        </div> */}
      </div>
      {multipleActions && (
        <div className={styles.sequenceStructureContainer}>
          <h4 className={styles.formTitle}>Structure</h4>
          <Dropdown
            placeholder={t('sequences.generation.modal.structurePlaceholder')}
            options={sequenceTemplateOptions}
            selection
            selectOnBlur={false}
            fluid
            defaultValue='sequence-1'
            className={styles.sequenceDropdown}
            onChange={(e, { value }) => onActionChange(value as string)}
          />
        </div>
      )}
      {showPrompt && (
        <>
          <div className={styles.textAreaContainer}>
            <h4 className={styles.formTitle}>Generation prompt</h4>
            <TextArea
              className={styles.textArea}
              rows={2}
              value={formState.basePrompts.join('\n\n')}
              onChange={(e) => onChangePrompt(e.target.value)}
              placeholder='Write a few lines outreach email to a candidate.'
            />
          </div>
          {!inModal && (
            <div className={styles.computePolicyContainer}>
              <h4 className={styles.formTitle}>Compute policy</h4>
              <Dropdown
                placeholder='Choose a compute policy'
                options={[
                  {
                    key: 'sequential',
                    value: 'sequential',
                    text: 'Sequential',
                  },
                  {
                    key: 'parallel',
                    value: 'parallel',
                    text: 'Parallel',
                  },
                  {
                    key: 'bulk',
                    value: 'bulk',
                    text: 'Bulk',
                  },
                ]}
                selection
                search
                fluid
                value={formState.computePolicy}
                className={styles.languageDropdown}
                onChange={(e, { value }) => setComputePolicy(value as string)}
              />
            </div>
          )}
        </>
      )}
      <div className={styles.textAreaContainer}>
        <h4 className={styles.formTitle}>
          {t('sequences.generation.modal.companyDescription')}
          {clientId !== 'ai-seq-demo' && (
            <InfoTooltip rich hoverable>
              {t('sequences.generation.modal.companyTooltip')}
              &nbsp;
              <Link
                to={`/client/${clientId}/company-settings`}
                rel='noopener'
                target='_blank'
              >
                {t('sequences.generation.modal.companyTooltipLink')}
              </Link>
            </InfoTooltip>
          )}
        </h4>
        <TextArea
          className={styles.textArea}
          rows={8}
          value={companyDescription.description}
          onChange={(e) => onChangeCompanyDescription(e.target.value)}
          placeholder={t(
            'sequences.generation.modal.companyDescriptionPlaceholder',
          )}
        />
      </div>
      <div className={styles.textAreaContainer}>
        <h4 className={styles.formTitle}>
          {t('sequences.generation.modal.jobDescription')}
        </h4>
        <TextArea
          className={styles.textArea}
          rows={8}
          value={jobDescription.description}
          onChange={(e) => onChangeJobDescription(e.target.value)}
          placeholder={t(
            'sequences.generation.modal.jobDescriptionPlaceholder',
          )}
        />
      </div>
    </div>
  );
};

export default GenerateSequenceForm;
