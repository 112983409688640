import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const query = gql`
  query getClientActivity($clientId: ID!) {
    client(id: $clientId) {
      id
      activity {
        events {
          type
          subtype
          date
          ... on ProfilesActivityEvent {
            profiles {
              id
              resumeData {
                firstname
                lastname
                headline {
                  content {
                    text
                  }
                }
                photoLink
              }
            }
            offer {
              id
              title
              isUnclassified
            }
            totalProfilesCount
          }
          ... on SingleProfileActivityEvent {
            profile {
              id
              resumeData {
                firstname
                lastname
                headline {
                  content {
                    text
                  }
                }
                photoLink
              }
            }
            offer {
              id
              title
              isUnclassified
            }
          }
          ... on UserActivityEvent {
            user {
              id
              email
              firstname
              lastname
            }
          }
          ... on OfferActivityEvent {
            offer {
              id
              title
              isUnclassified
            }
          }
          # ... on OfferPushFlowPolicyActivityEvent {
          #   offer {
          #     id
          #     title
          #   }
          #   pushFlowPolicy {
          #     nbPerDay
          #   }
          # }
        }
      }
    }
  }
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      clientId: ownProps.clientId,
    },
  }),
  props: ({ data: { error, loading, client } }) => {
    return {
      clientActivity: client && client.activity,
      loading,
      clientActivityLoading: loading,
      error,
    };
  },
});
