import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './svg.module.less';

const CheckmarkOutline: FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    className={classNames(styles.svgIcon, className)}
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.99992 13.6667C3.31792 13.6667 0.333252 10.682 0.333252 7.00001C0.333252 3.31801 3.31792 0.333344 6.99992 0.333344C10.6819 0.333344 13.6666 3.31801 13.6666 7.00001C13.6666 10.682 10.6819 13.6667 6.99992 13.6667ZM6.99992 12.3333C8.41441 12.3333 9.77096 11.7714 10.7712 10.7712C11.7713 9.77105 12.3333 8.4145 12.3333 7.00001C12.3333 5.58552 11.7713 4.22897 10.7712 3.22877C9.77096 2.22858 8.41441 1.66668 6.99992 1.66668C5.58543 1.66668 4.22888 2.22858 3.22868 3.22877C2.22849 4.22897 1.66659 5.58552 1.66659 7.00001C1.66659 8.4145 2.22849 9.77105 3.22868 10.7712C4.22888 11.7714 5.58543 12.3333 6.99992 12.3333ZM6.33525 9.66668L3.50659 6.83801L4.44925 5.89534L6.33525 7.78134L10.1059 4.01001L11.0493 4.95268L6.33525 9.66668Z'
      fill='#A5ABC9'
    />
  </svg>
);

export default CheckmarkOutline;
