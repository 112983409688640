import gql from 'graphql-tag';
import { ResumeData } from '@/types/searchPoolProfile';

import { PipelineState } from '@/components/PipelineSegmentation/pipelineSegmentation';
import { CustomFieldValue } from '@/common/customFields';
import { Author as AuthorType } from '@/types/author';
import { MiniSearchPoolProfile } from './fragments/SearchPoolProfile';
import CustomFieldsValues from './fragments/CustomFieldsValues';
import Author from './fragments/Author';
import { ContactCategory } from '../context/ContactTypeContext';

export type Action = {
  owner?: string;
  plannedExecutionDate?: string;
  type?: string;
  actionId?: string;
  completion?: { isCompleted: boolean; completionDate?: string };
};

export type SearchPoolMiniProfile = {
  id: string;
  // TODO: complete me
  resumeData?: ResumeData;
  currentSequenceInfo?: {
    lastActionDate?: string;
    author: AuthorType;
    sequenceId?: string;
  };
  name?: string;
  contactCategory?: ContactCategory;
  missionsInfo: {
    missionId: string;
    mission: {
      data?: { title?: string };
    };
    data: {
      archived: boolean;
      comment: string;
      segmentationStates: {
        segmentationId: string;
        state: PipelineState;
        interestedStepId?: string;
      }[];
      customFields?: CustomFieldValue[];
    };
  }[];
  campaignEnrollments?: {
    campaignId: string;
    state: {
      status: string;
      hold: boolean;
      archived: boolean;
      nextActionDate: string;
      completionTimestamp: string;
    };
  }[];
  contactFlow?: {
    sequences?: {
      isCurrent: boolean;
      completion?: { isCompleted: boolean };
      trigger?: { type?: string };
      actions: Action[];
    }[];
  };
  privacyState?: {
    markedAsDoNotContact: boolean;
  };
  explicitLastInteraction?: {
    author: AuthorType;
    date: string;
  };
  companyDealState?: {
    type: string;
    customCompanyDealStateId: string;
  };
  applicationMissions?: {
    missionId: string;
  }[];
  source?: {
    sourceId: string;
    importDate: string;
    type: string;
    recruiter: AuthorType;
    importName: string;
  };
};

export type SearchPoolMiniProfileResult = {
  searchPool: {
    id: string;
    profile: SearchPoolMiniProfile;
  };
};

export const SEARCH_POOL_MINI_PROFILE = gql`
  query getSearchPoolMiniProfile($searchPoolId: ID!, $profileId: ID!) {
    searchPool(id: $searchPoolId) {
      id
      profile(id: $profileId) {
        ...MiniSearchPoolProfile
        missionsInfo {
          missionId
          mission {
            id
            data {
              title
              sourceJobId
            }
          }
          data {
            archived
            comment
            segmentationStates {
              segmentationId
              state
              interestedStepId
            }
            customFields {
              ...CustomFieldsValues
            }
          }
        }
        campaignEnrollments {
          id
          campaignId
          state {
            status
            hold
            archived
            nextActionDate
            completionTimestamp
          }
        }
        explicitLastInteraction {
          author {
            firstname
            lastname
            email
          }
          date
        }
        companyDealState {
          type
          customCompanyDealStateId
        }
        applicationMissions {
          missionId
        }
        source {
          sourceId
          importDate
          type
          recruiter {
            ...Author
          }
          importName
          externalSourceId
          externalSourceName
        }
      }
    }
  }
  ${Author}
  ${MiniSearchPoolProfile}
  ${CustomFieldsValues}
`;

export default SEARCH_POOL_MINI_PROFILE;
