// Micka's colors

export const PrimaryCobalt120 = '#0b1a63';
export const PrimaryCobalt = '#1F2E77';
export const PrimaryCobalt80 = '#4C5892';
export const PrimaryCobalt60 = '#7982AD';
export const PrimaryCobalt40 = '#A5ABC9';
export const PrimaryCobalt20 = '#D2D5E4';
export const PrimaryCobalt10 = '#E9EBF2';
export const PrimaryCobalt05 = '#F3F4F8';

export const PrimaryCobaltOp06 = '#1f2e770f';
export const PrimaryCobaltOp15 = '#1f2e7726';

export const PrimaryPink = '#F9C8CA';

export const SecondaryRed = '#F66F81';
export const SecondaryRed40 = '#FCC5CC';
export const SecondaryRed60 = '#FBA8B2';
export const SecondaryRed80 = '#F98B99';
export const SecondaryRed120 = '#E45A6B';

export const SecondaryBlue = '#4864C9';
export const SecondaryBlue120 = '#3450B5';
export const SecondaryBlue100 = '#4864C9';
export const SecondaryBlue80 = '#6D83D4';
export const SecondaryBlue60 = '#91A2DF';
export const SecondaryBlue40 = '#B6C1E9';
export const SecondaryBlue20 = '#DAE0F4';
export const SecondaryBlue10 = '#EDF0FA';
export const SecondaryBlue05 = '#F6F7FC';

export const SecondarySkyBlue = '#709DFF';
export const SecondarySkyBlue120 = '#5C89EB';
export const SecondarySkyBlue60 = '#A9C4FF';
export const SecondarySkyBlue05 = '#F8FAFF';
export const SecondarySkyBlue10 = '#F1F6FF';
export const SecondarySkyBlue20 = '#E2EBFF';
export const SecondarySkyBlue40 = '#c6d8ff';

export const PositiveColor = '#28CA42';
export const PositiveColor120 = '#14B62E';
export const PositiveColor80 = '#3CDE56';
export const PositiveColor10 = '#28CA421A';

export const NegativeColor = '#FF6059';
export const NegativeColor120 = '#EB4C45';
export const NegativeColor80 = '#FF746D';
export const NegativeColor10 = '#FF60591A';

export const Purple = '#b26fd2';
export const Purple40 = '#e0c5ed';

export const Yellow = '#ffbd2e';
export const Yellow40 = '#ffe5ab';
export const Yellow100 = '#FFBD2E';
export const Yellow10 = '#FFF2D5';

export const Orange = '#f99d47';
export const Cyan = '#49ccd4';
export const Violet = '#854bff';
export const AppleGreen = '#bed888';

// Legacy data viz palette
export const DataViz1 = '#1f2e77'; // same as @PrimaryCobalt
export const DataViz2 = '#7b3182';
export const DataViz3 = '#be3679';
export const DataViz4 = '#ed5461';
export const DataViz5 = '#ff8545';
export const DataViz6 = '#eba91a'; // same as @WarningColor

// Data viz palette
export const DataVizColors = [
  PrimaryCobalt40,
  PrimaryCobalt,
  PrimaryPink,
  SecondarySkyBlue,
  SecondaryBlue,
  Purple,
  Yellow,
  SecondaryRed80,
  PositiveColor,
  NegativeColor,
  Purple40,
  SecondarySkyBlue40,
  PrimaryCobalt120,
  PrimaryCobalt40,
  SecondaryBlue60,
  Yellow40,
  Orange,
  Cyan,
  Violet,
  AppleGreen,
];

export const taskTypeColors = {
  email: Purple,
  call: PrimaryCobalt80,
  manual: '#6555c6',
  linkedInMessage: SecondarySkyBlue,
  linkedInRecruiterInMail: '#70ffd6',
  linkedInSalesNavigatorInMail: '#70e3ff',
  linkedInInvitation: SecondaryBlue,
  linkedInInvitationChecked: SecondarySkyBlue60,
  sms: SecondaryRed120,
};
