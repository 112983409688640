import _ from 'underscore';
import React, { Component } from 'react';
import { Grid, Segment, Divider, Input, Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { getDisplayableName } from '../../../../common';
import EvaluationDisplay from './EvaluationDisplay';
import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import EvaluationEditModal from './EvaluationEditModal';

class EvaluationsSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editTitleMode: false,
    };
  }

  handleSelectEvaluation = ({ evaluation }) => {
    this.setState({
      selectedEvaluationId: evaluation.id,
      aggregationMode: null,
    });
  };

  handleSelectAggregationMode = () => {
    const { aggregationMode } = this.state;
    this.setState({
      aggregationMode: !aggregationMode,
      selectedEvaluationId: null,
    });
  };

  handleClose = () => {
    this.setState({ selectedEvaluationId: null, aggregationMode: null });
  };

  openDeleteEvaluationConfirmationModal = ({
    evaluationsSetId,
    evaluationId,
  }) => {
    this.setState({
      deleteEvaluationConfirmationModal: { evaluationsSetId, evaluationId },
      selectedEvaluationId: null,
      aggregationMode: null,
    });
  };

  onSubmitDeleteEvaluationConfirmationModal = async () => {
    const { deleteProfileEvaluation } = this.props;
    const { deleteEvaluationConfirmationModal } = this.state || {};
    const { evaluationsSetId, evaluationId } =
      deleteEvaluationConfirmationModal || {};
    this.setState({ deleteEvaluationConfirmationModal: null });
    await deleteProfileEvaluation({ evaluationsSetId, evaluationId });
  };

  openDeleteEvaluationsSetConfirmationModal = () => {
    this.setState({ deleteEvaluationsSetConfirmationModal: true });
  };

  onSubmitDeleteEvaluationsSetConfirmationModal = async () => {
    const { onRemove } = this.props;
    this.setState({
      deleteEvaluationsSetConfirmationModal: null,
      selectedEvaluationId: null,
      aggregationMode: null,
    });
    await onRemove();
  };

  onChangeTitleInput = (event, { value }) =>
    this.setState({ evaluationsSetTitle: value });

  onChangeTitle = async () => {
    const { onUpdate } = this.props;
    const { evaluationsSetTitle } = this.state;
    this.setState({ evaluationsSetTitle: null, editTitleMode: false });
    await onUpdate({ title: evaluationsSetTitle });
  };

  onCancelChangeTitle = async () => {
    this.setState({ evaluationsSetTitle: null, editTitleMode: false });
  };

  handleUpdateEvaluation = async ({
    evaluationsSetId,
    evaluationId,
    evaluation,
  }) => {
    const { updateEvaluation } = this.props;
    await updateEvaluation({
      evaluationsSetId,
      evaluationId,
      evaluation,
    });
    this.setState({ updateEvaluationModal: null });
  };

  render() {
    const { evaluationsSet, onAskAddFeedback, t } = this.props;
    const {
      selectedEvaluationId,
      aggregationMode,
      deleteEvaluationConfirmationModal,
      deleteEvaluationsSetConfirmationModal,
      editTitleMode,
      evaluationsSetTitle,
      updateEvaluationModal,
    } = this.state;

    const { evaluations } = evaluationsSet;

    return (
      <div className='evaluations-set-container'>
        <Segment>
          <div className='evaluations-set-header'>
            <Grid style={{ margin: 0, padding: 0 }}>
              <Grid.Row style={{ margin: 0, padding: 0 }}>
                <Grid.Column width={12} style={{ margin: 0, padding: 0 }}>
                  <div className='evaluations-set-title'>
                    {editTitleMode ? (
                      <div className='row'>
                        <Input
                          placeholder='New title'
                          size='small'
                          value={evaluationsSetTitle}
                          onChange={this.onChangeTitleInput}
                        />
                        <Button
                          primary
                          basic
                          size='small'
                          onClick={this.onCancelChangeTitle}
                        >
                          {t('common.cancel')}
                        </Button>
                        <Button
                          primary
                          size='small'
                          onClick={this.onChangeTitle}
                        >
                          {t('common.save')}
                        </Button>
                      </div>
                    ) : (
                      <h4>{evaluationsSet.title}</h4>
                    )}
                  </div>
                </Grid.Column>
                <Grid.Column
                  width={editTitleMode ? 12 : 4}
                  textAlign='right'
                  style={{ margin: 0, padding: 0 }}
                >
                  {!editTitleMode && (
                    <div className='buttons-container'>
                      <button
                        className='modify-button'
                        type='button'
                        onClick={() =>
                          this.setState({
                            editTitleMode: true,
                            evaluationsSetTitle: evaluationsSet.title,
                          })
                        }
                      >
                        {t('common.edit')}
                      </button>
                      <button
                        className='trash-button'
                        onClick={this.openDeleteEvaluationsSetConfirmationModal}
                        type='button'
                      >
                        <img
                          className='trash-svg'
                          src='/images/icons/trash.svg'
                          alt=''
                        />
                      </button>
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <div className='evaluations-list'>
            <ul>
              {_.map(evaluations, (evaluation) => {
                // console.log('evaluation', evaluation);
                const author = getDisplayableName({
                  author: evaluation?.author,
                });
                const date = t('common.shortDate', {
                  date: new Date(evaluation?.date),
                });
                return (
                  <li key={evaluation.id}>
                    <div className='evaluation-item caption-2'>
                      {t('profile.evaluations.author', { author, date })}
                      <div className='controls'>
                        <span
                          className='see-evaluation'
                          onClick={() =>
                            this.handleSelectEvaluation({ evaluation })
                          }
                        >
                          {t('profile.evaluations.see')}
                        </span>
                        {'  '}
                        <span
                          className='see-evaluation'
                          onClick={() =>
                            this.setState({
                              updateEvaluationModal: {
                                evaluation,
                                evaluationId: evaluation.id,
                                evaluationsSetId: evaluationsSet.id,
                              },
                            })
                          }
                        >
                          {t('profile.evaluations.edit')}
                        </span>
                        <button
                          className='trash-button'
                          onClick={() =>
                            this.openDeleteEvaluationConfirmationModal({
                              evaluationsSetId: evaluationsSet.id,
                              evaluationId: evaluation.id,
                            })
                          }
                          type='button'
                        >
                          <img
                            className='trash-svg'
                            src='/images/icons/trash.svg'
                            alt=''
                          />
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })}
              <li>
                <span className='add-evaluation' onClick={onAskAddFeedback}>
                  {t('profile.evaluations.addFeedback')}
                </span>
              </li>
            </ul>
          </div>
          {(evaluations || []).length >= 1 && (
            <center>
              <div
                className='see-aggregated'
                onClick={this.handleSelectAggregationMode}
              >
                <span>
                  {aggregationMode
                    ? t('profile.evaluations.hideAggregatedResults')
                    : t('profile.evaluations.showAggregatedResults')}
                </span>
              </div>
            </center>
          )}
          {selectedEvaluationId && (
            <div>
              <Divider />
              <EvaluationDisplay
                evaluation={_.findWhere(evaluations, {
                  id: selectedEvaluationId,
                })}
                onClose={this.handleClose}
              />
            </div>
          )}
          {aggregationMode && !_.isEmpty(evaluations) && (
            <div>
              <Divider />
              <EvaluationDisplay
                evaluations={evaluations}
                onClose={this.handleClose}
              />
            </div>
          )}
        </Segment>
        <ConfirmationModal
          header={t('profile.evaluations.deleteEvaluation')}
          submit={t('common.delete')}
          onSubmit={this.onSubmitDeleteEvaluationConfirmationModal}
          open={!!deleteEvaluationConfirmationModal}
          onCancel={() =>
            this.setState({ deleteEvaluationConfirmationModal: null })
          }
        />
        <ConfirmationModal
          header={t('profile.evaluations.deleteSetOfEvaluations')}
          submit={t('common.delete')}
          onSubmit={this.onSubmitDeleteEvaluationsSetConfirmationModal}
          open={!!deleteEvaluationsSetConfirmationModal}
          onCancel={() =>
            this.setState({ deleteEvaluationsSetConfirmationModal: null })
          }
        />
        {updateEvaluationModal && (
          <EvaluationEditModal
            initialValue={updateEvaluationModal.evaluation}
            onClose={() => this.setState({ updateEvaluationModal: null })}
            onSubmit={({ evaluation }) =>
              this.handleUpdateEvaluation({
                evaluationsSetId: updateEvaluationModal.evaluationsSetId,
                evaluationId: updateEvaluationModal.evaluationId,
                evaluation,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default withTranslation('translations')(EvaluationsSet);
