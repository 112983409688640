import React, { useState } from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import { Button, Checkbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {
  ARCHIVED_ENGAGEMENT,
  ARCHIVED_REASONS,
  sentryCaptureException,
} from '@/common';
import { useRemoveProfilesFromMission } from '@/graphql/searchPoolProfiles';
import useUpdateProfilesPipelineStage from '@/graphql/hooks/searchPoolJob/useUpdateProfilesMissionPipelineStage';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import MoveToStageAction from '@/components/PipelineSegmentation/MoveToStageAction';
import { Segmentations } from '@/components/PipelineSegmentation/data';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import ConfirmationModal from '@/components/modals/ConfirmationModal';

import styles from './NewMissionItem.module.less';

interface NewMissionItemProps {
  searchPoolId: string;
  missionId: string;
  segmentationId: string;
  stageId: string;
  stageName: string;
  profileId: string;
  missionsInfoData: any;
  clientId: string;
  onChangeStage?: (data: any) => void;
}
const NewMissionItem: React.FC<NewMissionItemProps> = ({
  searchPoolId,
  missionId,
  segmentationId,
  stageId,
  stageName,
  profileId,
  missionsInfoData,
  clientId,
  onChangeStage,
}) => {
  const { t } = useTranslation();
  const { error, success } = useNotificationSystem();
  const { profile } = useCandidateViewContext();
  const [missionPipelineOpen, setMissionPipelineOpen] = useState(false);
  const [removeFromMissionModalOpen, setRemoveFromMissionModalOpen] = useState(
    false,
  );
  const [selectedEngagement, setSelectedEngagement] = useState('not-contacted');
  const [selectedReason, setSelectedReason] = useState('');
  const [shouldInterruptSequence, setShouldInterruptSequence] = useState(true);

  const [updatePipeline, { loading }] = useUpdateProfilesPipelineStage();
  const [
    removeProfilesFromMission,
    // @ts-ignore no properly typed because types wrongly guessed by TS from a JS file
  ] = useRemoveProfilesFromMission();

  const innerOnChangeStage = async (
    newStageId: string,
    prevStageId: string,
    labels?: string[],
    clientArchiveReasonId?: string,
    interestedStepId?: string,
  ) => {
    try {
      const { data } = await updatePipeline({
        missionId,
        stage: newStageId,
        profileIds: [profileId],
        segmentationId,
        labels,
        clientArchiveReasonId,
        interestedStepId,
      });
      success(t('reveal.candidatesView.actionsBox.updateStageSuccess'));
      setMissionPipelineOpen(false);
      if (onChangeStage) {
        onChangeStage({
          newData: data,
          prevSegment: prevStageId,
          jobId: missionId,
        });
      }
    } catch (e) {
      sentryCaptureException({ error: e });
      console.info(e);
      error(t('reveal.candidatesView.actionsBox.updateStageError'));
    }
  };

  const onRemoveFromMission = async () => {
    try {
      // @ts-ignore no properly typed because types wrongly guessed by TS from a JS file
      await removeProfilesFromMission({
        variables: {
          searchPoolId,
          input: {
            missionId,
            profileIds: [profileId],
            shouldInterruptSequence,
            answerLabels: _.compact([selectedEngagement, selectedReason]),
            ...(shouldInterruptSequence && {
              sequenceId: profile?.currentSequenceInfo?.sequenceId,
            }),
          },
        },
      });
      success(
        t('reveal.candidatesView.actionsBox.removeFromMissionSuccess', {
          count: 1,
        }),
      );
    } catch (e) {
      sentryCaptureException({ error: e });
      error(t('reveal.candidatesView.actionsBox.removeFromMissionError'));
    }
  };

  const handleOpenRemoveFromMission = (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    event.stopPropagation();
    if (
      !missionsInfoData ||
      !missionsInfoData.segmentationStates ||
      missionsInfoData.segmentationStates[0].state === 'pending'
    ) {
      setRemoveFromMissionModalOpen(false);
      onRemoveFromMission();
      return;
    }
    setRemoveFromMissionModalOpen(true);
  };

  const displayInterruptSequence =
    profile?.currentSequenceInfo?.sequenceId &&
    profile?.currentSequenceInfo?.state !== 'completed';

  return (
    <div className='mission-item'>
      <div
        className='mission-pipeline-dropdown'
        onClick={() => setMissionPipelineOpen((prev) => !prev)}
      >
        <span className={styles.segmentName}>{stageName}</span>
        <div>
          <i aria-hidden='true' className='dropdown icon' />
          <i
            aria-hidden='true'
            className='ri-close-fill ri-lg'
            onClick={handleOpenRemoveFromMission}
          />
        </div>
      </div>
      {missionPipelineOpen && (
        <MoveToStageAction
          missionId={missionId}
          currentStage={stageId}
          stages={Segmentations[0].segments}
          onMove={innerOnChangeStage}
          isSubmitting={loading}
          candidates={[profile]}
          setOpen={setMissionPipelineOpen}
          absolutePosition='right'
          clientId={clientId}
        />
      )}

      <button
        type='button'
        id='command-move-to-not-contacted'
        style={{ position: 'absolute', visibility: 'hidden' }}
        onClick={() => innerOnChangeStage('pending', stageId)}
      />
      <button
        type='button'
        id='command-move-to-contacted'
        style={{ position: 'absolute', visibility: 'hidden' }}
        onClick={() => innerOnChangeStage('in-progress', stageId)}
      />
      <button
        type='button'
        id='command-move-to-replied'
        style={{ position: 'absolute', visibility: 'hidden' }}
        onClick={() => innerOnChangeStage('replied', stageId)}
      />
      <button
        type='button'
        id='command-move-to-interested'
        style={{ position: 'absolute', visibility: 'hidden' }}
        onClick={() => innerOnChangeStage('interested', stageId)}
      />
      <button
        type='button'
        id='command-move-to-hired'
        style={{ position: 'absolute', visibility: 'hidden' }}
        onClick={() => innerOnChangeStage('hired', stageId)}
      />
      <button
        type='button'
        id='command-move-to-archived-not-contacted'
        style={{ position: 'absolute', visibility: 'hidden' }}
        onClick={() =>
          innerOnChangeStage('archived', stageId, ['not-contacted'])
        }
      />
      <button
        type='button'
        id='command-move-to-archived-no-reply'
        style={{ position: 'absolute', visibility: 'hidden' }}
        onClick={() => innerOnChangeStage('archived', stageId, ['no-answer'])}
      />
      <button
        type='button'
        id='command-move-to-archived-contact-no-interested'
        style={{ position: 'absolute', visibility: 'hidden' }}
        onClick={() =>
          innerOnChangeStage('archived', stageId, ['profile-not-interested'])
        }
      />
      <button
        type='button'
        id='command-move-to-archived-contact-interested'
        style={{ position: 'absolute', visibility: 'hidden' }}
        onClick={() =>
          innerOnChangeStage('archived', stageId, ['profile-interested'])
        }
      />

      {removeFromMissionModalOpen && (
        <ConfirmationModal
          open
          onCancel={() => setRemoveFromMissionModalOpen(false)}
          onSubmit={onRemoveFromMission}
          header={`${t(
            'common.remove',
          )} ${`${profile.resumeData?.firstname} ${profile.resumeData?.lastname}`} ${t(
            'reveal.candidatesView.actionsBox.fromMission',
          )}`}
          content={
            <div>
              <div className={styles.reasonsLabel}>
                <span className={styles.mainTitle}>
                  {t('reveal.candidatesView.actionsBox.reasonsLabel')}
                </span>{' '}
                <span className={styles.subTitle}>
                  {t('common.facultative')}
                </span>
              </div>

              <div className={styles.reasonsButtons}>
                {ARCHIVED_ENGAGEMENT.map((reason) => (
                  <Button
                    key={reason}
                    className={classNames(
                      styles.button,
                      selectedEngagement === reason && styles.buttonSelected,
                    )}
                    size='small'
                    onClick={() => setSelectedEngagement(reason)}
                  >
                    {t(
                      `reveal.candidatesView.actionsBox.missionsReasons.${reason}`,
                    )}
                  </Button>
                ))}
              </div>

              {selectedEngagement === 'profile-interested' && (
                <>
                  <div className={styles.reasonsLabel}>
                    <span className={styles.mainTitle}>
                      {t('common.details')}
                    </span>{' '}
                  </div>
                  <div className={styles.reasonsButtons}>
                    {ARCHIVED_REASONS.map((reason) => (
                      <Button
                        key={reason}
                        className={classNames(
                          styles.button,
                          selectedReason === reason && styles.buttonSelected,
                        )}
                        size='small'
                        onClick={() => setSelectedReason(reason)}
                      >
                        {t(
                          `reveal.candidatesView.actionsBox.missionsReasons.${reason}`,
                        )}
                      </Button>
                    ))}
                  </div>
                </>
              )}

              {displayInterruptSequence && (
                <div className={styles.interruptSequence}>
                  <Checkbox
                    label={
                      <label>
                        <span className={styles.checkboxTitle}>
                          {profile?.currentSequenceInfo?.sequence.title}{' '}
                        </span>
                        <span className={styles.checkboxSubtitle}>
                          {t(
                            'reveal.candidatesView.actionsBox.missionsReasons.willBeStopped',
                          )}
                        </span>
                      </label>
                    }
                    defaultChecked={shouldInterruptSequence}
                    onChange={(event, data) => {
                      if (data?.checked) {
                        setShouldInterruptSequence(data.checked);
                      }
                    }}
                  />
                </div>
              )}
            </div>
          }
          submit={t('common.remove')}
          cancel={t('common.cancel')}
          size='tiny'
        />
      )}
    </div>
  );
};

export default NewMissionItem;
