import React, { useEffect, useRef, useState } from 'react';
import _ from 'underscore';
import { withTranslation } from 'react-i18next';
import { Label, Popup } from 'semantic-ui-react';


import './ResizableLabelList.css';

const LABEL_STYLE = {
  fontSize: '13px',
  fontWeight: 400,
};

const ResizableLabelList = ({ labelsWithRelevance = [], condensedMode = false, t }) => {
  const ref = useRef(null);

  const getContainerWidth = () => {
    return ref?.current?.clientWidth - 1 || 0;
  };

  const [containerWidth, setContainerWidth] = useState(getContainerWidth());
  const updateContainerWidth = () => setContainerWidth(getContainerWidth());

  const [maxDisplayedLabels, setMaxDisplayedLabels] = useState(0);

  let displayedLabels = labelsWithRelevance;
  let extraLabels = [];
  if (condensedMode) {
    displayedLabels = labelsWithRelevance.slice(0, maxDisplayedLabels);
    extraLabels = labelsWithRelevance.slice(maxDisplayedLabels);
  }

  useEffect(() => {
    updateContainerWidth();
    window.addEventListener('resize', updateContainerWidth);
    return () => window.removeEventListener('resize', updateContainerWidth);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (containerWidth > 0) {
      const newMaxDisplayedLabels = getMaxDisplayedLabels({ labels: labelsWithRelevance, containerWidth });
      setMaxDisplayedLabels(newMaxDisplayedLabels);
    }
    // eslint-disable-next-line
  }, [containerWidth]);

  const extraLabelsCount = extraLabels.length;
  // console.log('displayedLabels', displayedLabels);
  // console.log('extraLabels', extraLabels);

  return (
    <div className='resizable-label-list' ref={ref}>
      {_.map(displayedLabels, (skill, index) => {
        return (
          <Label
            key={`${index}-${skill?.skillId}`}
            className={getLabelTagClassName({ index, relevance: skill?.relevance })}
          >
            <span>{skill?.label}</span>
          </Label>
        );
      })}
      {extraLabelsCount > 0 && (
        <Popup
          hoverable
          className='resizable-label-list-popup'
          trigger={(
            <Label className='skill-tag normal'>
              <span>
                {t('watchCollect.results.profileCard.content.plusOtherSkills', { count: extraLabelsCount })}
              </span>
            </Label>
          )}
          position='bottom center'
        >
          <ResizableLabelList labelsWithRelevance={extraLabels} />
        </Popup>
      )}
    </div>
  );
};

export const getLabelTagClassName = ({ relevance }) => {
  if (relevance >= 10) {
    return 'skill-tag important';
  }
  if (relevance >= 6) {
    return 'skill-tag medium';
  }
  return 'skill-tag normal';
};


const getMaxDisplayedLabels = ({ labels, containerWidth }) => {
  // console.log('skills', skills);
  const EXTRA_LABEL_WIDTH = 100;
  const PADDING = 12;
  const MARGIN = 4;
  const CONTAINER_NEGATIVE_MARGIN = -8;
  const availableWidth = containerWidth - EXTRA_LABEL_WIDTH - CONTAINER_NEGATIVE_MARGIN;

  const measureLabel = (label) => measureText({ text: label, style: LABEL_STYLE })?.width + 2 * PADDING + 2 * MARGIN;

  let maxDisplayedLabels = 0;
  let totalLabelsWidth = 0;
  let currentLabelIndex = 0;
  while (currentLabelIndex < labels.length) {
    const currentLabel = labels[currentLabelIndex];
    if (totalLabelsWidth + measureLabel(currentLabel?.label) > availableWidth) {
      break;
    }
    totalLabelsWidth += measureLabel(currentLabel?.label);
    // console.log('totalLabelsWidth', totalLabelsWidth, 'at index', currentLabelIndex);
    currentLabelIndex += 1;
    maxDisplayedLabels += 1;
  }

  // if last skill fits instead of "+1 other", leave it
  if (currentLabelIndex === labels.length - 1) {
    const lastLabel = labels[currentLabelIndex];
    const lastLabelWidth = measureLabel(lastLabel?.label);
    // console.log('lastLabelWidth', lastLabelWidth, 'totalLabelsWidth', totalLabelsWidth, 'containerWidth', containerWidth);
    if (totalLabelsWidth + lastLabelWidth < containerWidth - CONTAINER_NEGATIVE_MARGIN) {
      maxDisplayedLabels += 1;
      currentLabelIndex += 1;
      totalLabelsWidth += lastLabelWidth;
    }
  }

  // console.log('finalLabelsWidth', totalLabelsWidth);
  // console.log('maxDisplayedLabels', maxDisplayedLabels);
  return maxDisplayedLabels;
};

const measureText = ({ text, style }) => {
  // console.log('measureText', text, style);
  const tempElement = document.createElement('div');
  tempElement.className = 'measure-text-temp';

  // IMPORTANT: "hides" div from view
  tempElement.style.left = -1000;
  tempElement.style.top = -1000;
  tempElement.style.position = 'absolute';

  tempElement.innerHTML = text;
  tempElement.style.fontSize = style?.fontSize || '14px';
  tempElement.style.fontFamily = style?.fontFamily || 'Graphik';
  tempElement.style.fontWeight = style?.fontWeight || 400;


  document.body.appendChild(tempElement);

  const textMeasures = {
    width: tempElement.clientWidth,
    height: tempElement.clientHeight,
  };

  document.body.removeChild(tempElement);
  // console.log('textMeasures', text, textMeasures?.width);
  return textMeasures;
};


export default withTranslation('translations')(ResizableLabelList);
