/* eslint-disable no-underscore-dangle */
import _ from 'underscore';
import { sanitizeTypename } from '@/common/utils/apollo';
import { sanitizeURLInput } from '../../../common';

export const getCareerPageInput = ({ careerPageState }) => {
  const input = _.pick(
    careerPageState,
    'title',
    'organizationWebsite',
    'enabled',
    'logo',
    'theme',
    'description',
    'layout',
  );
  const sanitizedInput = {
    ...input,
    ...(input.organizationWebsite && {
      organizationWebsite: sanitizeURLInput(input.organizationWebsite),
    }),
  };
  return sanitizeTypename(sanitizedInput);
};

export default getCareerPageInput;
