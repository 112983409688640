import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SearchPoolJob from '@/graphql/fragments/SearchPoolJob';
import JobPosting from '../../graphql/fragments/JobPosting';
import { getClientCareerPages } from '../clients/withClientCareerPages';

export const createJobPostingMutation = gql`
  mutation createJobPosting($input: CreateJobPostingInput!) {
    createJobPosting(input: $input) {
      jobOffer {
        id
        jobPostings {
          ...JobPosting
        }
      }
    }
  }
  ${JobPosting}
`;

export const createRevealJobPostingMutation = gql`
  mutation createRevealJobPosting($searchPoolId: ID!, $input: CreateJobPostingInput!) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      createRevealJobPosting(input: $input) {
        jobPosting {
          ...JobPosting
        }
        job {
          ...SearchPoolJob
        }
      }
    }
  }
  ${JobPosting}
  ${SearchPoolJob}
`;

export default graphql(createJobPostingMutation, {
  props: ({ mutate }) => ({
    createJobPosting: ({ input, clientId }) =>
      mutate({
        variables: { input },
        refetchQueries: [
          {
            query: getClientCareerPages,
            variables: {
              id: clientId,
            },
          },
        ],
      }),
  }),
});
