import React from 'react';
import useAppPluginPubSub from '@/common/appPluginMessaging/useAppPluginPubSub';
import {
  DataUpdateNotificationParams,
  DataUpdateNotificationMethodName,
} from './constants';
import { SubscriptionsData } from './types';

export type DataUpdateSubscriptionPublishFn<
  TSubscriptionType extends keyof SubscriptionsData
> = (
  type: TSubscriptionType,
  data: SubscriptionsData[TSubscriptionType],
) => void;

/**
 * Returns a function that can be used to publish event on data subscriptions.
 *
 * This will leverage app/plugin communication PubSub.
 */
function useDataUpdateSubscriptionPublish<
  TSubscriptionType extends keyof SubscriptionsData
>(): DataUpdateSubscriptionPublishFn<TSubscriptionType> {
  const pubSub = useAppPluginPubSub();

  return React.useCallback<DataUpdateSubscriptionPublishFn<TSubscriptionType>>(
    (type, data) => {
      if (!pubSub) {
        return;
      }
      pubSub.notify<DataUpdateNotificationParams>(
        DataUpdateNotificationMethodName,
        {
          type,
          data,
        },
      );
    },
    [pubSub],
  );
}

export default useDataUpdateSubscriptionPublish;
