import gql from 'graphql-tag';
import { MissionWithFoldering } from '@/types/mission';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

const getMissionsWithFoldering = gql`
  query getMissionsWithFoldering {
    searchPool(id: "reveal") {
      id
      jobs {
        id
        isArchived
        data {
          title
        }
        foldering {
          department {
            id
          }
          section {
            id
          }
          subsection {
            id
          }
        }
      }
    }
  }
`;

type GetMissionsWithFolderingData = {
  searchPool: {
    id: string;
    jobs: MissionWithFoldering[];
  };
};

type UseMissionsWithFolderingInput = {
  queryOptions?: QueryHookOptions<GetMissionsWithFolderingData>;
};

interface UseMissionsWithFolderingResult
  extends QueryResult<GetMissionsWithFolderingData> {
  missionsWithFoldering: MissionWithFoldering[];
}

function useMissionsWithFoldering({
  queryOptions = {},
}: UseMissionsWithFolderingInput = {}): UseMissionsWithFolderingResult {
  const query = useQuery<GetMissionsWithFolderingData>(
    getMissionsWithFoldering,
    queryOptions,
  );

  const missionsWithFoldering = query.data?.searchPool.jobs || [];

  return { ...query, missionsWithFoldering };
}

export default useMissionsWithFoldering;
