import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import styles from './GenericTable.module.less';

export function GenericTable({
  children,
  className,
  ...rest
}: PropsWithChildren<{ className?: string }>) {
  return (
    <table className={classNames(className, styles.table)} {...rest}>
      {children}
    </table>
  );
}

export function GenericTableRow({
  children,
  className,
  ...rest
}: PropsWithChildren<{ className?: string }>) {
  return (
    <tr className={classNames(className, styles.row)} {...rest}>
      {children}
    </tr>
  );
}

export function TwoLineCell({
  line1,
  line2,
}: PropsWithChildren<{
  line1: string;
  line2?: string;
}>) {
  return (
    <div className={styles.twoLineCell}>
      <span>{line1}</span>
      {line2 && <span>{line2}</span>}
    </div>
  );
}
