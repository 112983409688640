import React, { useCallback, useMemo, useState, useContext } from 'react';
import axios from 'axios';
import _ from 'underscore';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import i18n from 'i18next';

import SEARCH_POOL_MINI_PROFILE from '@/graphql/searchPoolMiniProfileById';
import {
  useRemoveProfilesFromCampaign,
  useSetCampaignProfilesToHold,
  useSetCampaignProfilesToScheduled,
} from '@/graphql/AddProfilesToCampaignMutation';
import { useClientRevealConnector } from '@/graphql/hooks/clients/useClientRevealProjects';
import getCandidateAtsURL from '@/common/reveal/candidateAtsURL';
import { SUPPORTED_ATS_TYPES } from '@/common/reveal';
import { useLazyQueryPromisified } from '@/common/graphql';
import ButtonDropdownMenu from '@/components/Common/ButtonDropdownMenu';
import GenericTooltip from '@/components/Common/GenericTooltip';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';

import './ActionsDropdown.css';
import useUserEnrichmentIntegrations from '@/graphql/hooks/users/useUserEnrichmentIntegrations';
import AddProfileToSequenceContextProvider from '@/context/AddProfileToSequenceContext';
import ProfileTableParamsContext from '@/context/ProfileTableParamsContext';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import useInstantiateAiMergeTags from '@/graphql/instantiateAiMergeTags';
import useGenerateWordDocumentFromProfiles from '@/graphql/hooks/searchPool/useGenerateWordDocumentFromProfiles';
import downloadPDF from '@/common/downloadPDF';
import BulkEnrichSidebar from '../../SearchView/CandidatesListPane/SideBars/BulkEnrichSidebar/BulkEnrichSidebar';
import AddToCampaignSidebar from '../../SearchView/CandidatesListPane/SideBars/AddToCampaignSidebar';
import AddToSequenceSidebar from '../../SearchView/CandidatesListPane/SideBars/AddToSequenceSidebar';
import ReapplySequenceModal from '../Modals/ReapplySequenceModal';
import RemoveFromSequenceModal from '../Modals/RemoveFromSequenceModal';
import RemoveFromMissionModal from '../Modals/RemoveFromMissionModal';
import CreateManualTasksSidebar from '../../SearchView/CandidatesListPane/SideBars/CreateManualTasksSidebar';
import ExportProfileCSVModal from './ExportProfileCSVModal';
import AddToProjectSidebar from '../../SearchView/CandidatesListPane/SideBars/AddToProjectSidebar';

const BULK_ENRICH_MAX_CONTACTS = 50;

const isHumanskillsClient = ({ clientId }) => {
  if ((clientId ?? '').includes('aravati')) {
    return true;
  }
  if ((clientId ?? '').includes('lareleve')) {
    return true;
  }
  if ((clientId ?? '').includes('humanskills')) {
    return true;
  }
  if (clientId === 'acme') {
    return true;
  }
  return false;
};

const ActionsDropdown = ({
  clientId,
  jobId,
  sequenceId,
  candidates,
  onSuccess,
  campaignId = '',
}) => {
  const { t } = useTranslation();
  const params = useMergedConfigurationParams();
  const connector = useClientRevealConnector(clientId);
  const [openRemoveFromMissionModal, setOpenRemoveFromMissionModal] =
    useState(false);
  const [openRemoveFromSequenceModal, setOpenRemoveFromSequenceModal] =
    useState(false);
  const [openReapplySequenceModal, setOpenReapplySequenceModal] =
    useState(false);
  const [openCreateTasks, setOpenCreateTasks] = useState(false);
  const [openAddToSequence, setOpenAddToSequence] = useState(false);
  const [openAddToCampaign, setOpenAddToCampaign] = useState(false);
  const [openBulkEnrichModal, setOpenBulkEnrichModal] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [openAddToProject, setOpenAddToProject] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const clientPermissionsResult = useClientPermissions(clientId);
  const { permissions: clientPermissions } =
    clientPermissionsResult?.data?.client || {};

  const [generateDocument] = useGenerateWordDocumentFromProfiles();

  const [removeProfilesFromCampaign] =
    useRemoveProfilesFromCampaign(campaignId);

  const [setProfilesToHold] = useSetCampaignProfilesToHold(campaignId);
  const [setProfilesToScheduled] =
    useSetCampaignProfilesToScheduled(campaignId);

  const [instantiateAiMergeTags] = useInstantiateAiMergeTags();

  const { enrichmentIntegrations } = useUserEnrichmentIntegrations();
  const hasSupportedBulkIntegration = useMemo(() => {
    return Boolean(
      _.find(enrichmentIntegrations, ({ type, status }) => {
        return ['contactout'].includes(type) && status === 'active';
      }),
    );
  }, [enrichmentIntegrations]);

  const showProgressReport = useMemo(() => {
    return (
      jobId && (isHumanskillsClient({ clientId }) || clientId === 'ae-search')
    );
  }, [clientId, jobId]);

  // Use a custom function to use lazy query as a promise and wait the response
  // Better than useQuery with refetch option, because useQuery with refetch bypass the cache
  const getMiniProfile = useLazyQueryPromisified(SEARCH_POOL_MINI_PROFILE);

  const bulkActionsOptions = useMemo(() => {
    if (sequenceId) {
      return [
        ...(params?.shouldHideCSVExport !== 'true'
          ? [
              {
                id: 'export-to-csv',
                label: t('reveal.missions.mission.actions.exportCSV'),
              },
            ]
          : []),
        ...(showProgressReport
          ? [
              {
                id: 'generate-document',
                label: 'Progress report',
              },
            ]
          : []),
        {
          id: 'open-in-linkedin',
          label: t('reveal.missions.mission.actions.openInLinkedin'),
          disabled: candidates.length > 10,
        },
        {
          id: 'open-in-ats',
          label: t('reveal.missions.mission.actions.openInATS'),
          disabled: candidates.length > 10,
        },
        {
          id: 'remove-from-sequence',
          label: t('reveal.missions.mission.actions.removeFromSequence'),
        },
        {
          id: 'reapply-sequence',
          label: t('reveal.missions.mission.actions.reapplySequence'),
        },
        ...(clientPermissions?.canBulkInstantiateAiMergeTags
          ? [
              {
                id: 'compute-ai-merge-tags',
                label: t('reveal.missions.mission.actions.computeAIMergeTags'),
              },
            ]
          : []),
        ...(hasSupportedBulkIntegration &&
        candidates.length <= BULK_ENRICH_MAX_CONTACTS
          ? [
              {
                id: 'bulk-enrich',
                label: t('reveal.missions.mission.actions.bulkEnrich'),
              },
            ]
          : []),
      ];
    }
    return [
      {
        id: 'add-to-project',
        label: t('reveal.searchView.header.addToAMission'),
      },
      {
        id: 'add-to-sequence',
        label: t('reveal.missions.mission.actions.addToSequence'),
      },
      ...(clientPermissions?.crmMarketing && !campaignId
        ? [
            {
              id: 'add-to-campaign',
              label: t('reveal.missions.mission.actions.addToCampaign'),
            },
          ]
        : []),
      {
        id: 'create-tasks',
        label: t('reveal.missions.mission.actions.createTasks'),
      },
      ...(params?.shouldHideCSVExport !== 'true'
        ? [
            {
              id: 'export-to-csv',
              label: t('reveal.missions.mission.actions.exportCSV'),
            },
          ]
        : []),
      ...(showProgressReport
        ? [
            {
              id: 'generate-document',
              label: 'Progress report',
            },
          ]
        : []),
      {
        id: 'open-in-linkedin',
        label: t('reveal.missions.mission.actions.openInLinkedin'),
        disabled: candidates.length > 10,
      },
      {
        id: 'open-in-ats',
        label: t('reveal.missions.mission.actions.openInATS'),
        disabled: candidates.length > 10,
      },
      {
        id: 'remove-from-mission',
        label: t('reveal.missions.mission.actions.removeFromMission'),
      },
      {
        id: 'remove-from-sequence',
        label: t('reveal.missions.mission.actions.removeFromSequence'),
      },
      ...(hasSupportedBulkIntegration &&
      candidates.length <= BULK_ENRICH_MAX_CONTACTS
        ? [
            {
              id: 'bulk-enrich',
              label: t('reveal.missions.mission.actions.bulkEnrich'),
            },
          ]
        : []),
      ...(_.any(
        candidates,
        ({ sequenceId: candidateSequenceId }) => !!candidateSequenceId,
      )
        ? [
            {
              id: 'reapply-sequence',
              label: t('reveal.missions.mission.actions.reapplySequence'),
              disabled: _.any(
                candidates,
                ({ sequenceId: candidateSequenceId }) => !candidateSequenceId,
              ),
            },
          ]
        : []),
      ...(clientPermissions?.crmMarketing && campaignId
        ? [
            {
              id: 'hold-in-campaign',
              label: t('reveal.missions.mission.actions.holdInCampaign'),
            },
            {
              id: 'set-to-scheduled-in-campaign',
              label: t('reveal.missions.mission.actions.scheduleInCampaign'),
            },
            {
              id: 'remove-from-campaign',
              label: t('reveal.missions.mission.actions.removeFromCampaign'),
            },
          ]
        : []),
      ...(clientPermissions?.canBulkInstantiateAiMergeTags
        ? [
            {
              id: 'compute-ai-merge-tags',
              label: t('reveal.missions.mission.actions.computeAIMergeTags'),
            },
          ]
        : []),
    ];
  }, [
    showProgressReport,
    sequenceId,
    candidates,
    t,
    campaignId,
    clientPermissions,
    hasSupportedBulkIntegration,
    params,
  ]);

  const { disabledActions } = useContext(ProfileTableParamsContext);
  const filteredBulkActionOptions = useMemo(
    () => _.filter(bulkActionsOptions, ({ id }) => !disabledActions[id]),
    [bulkActionsOptions, disabledActions],
  );

  const openPopup = useCallback(({ url }) => {
    if (!url) {
      return;
    }
    const popup = window.open(url, '_blank');
    if (!popup) {
      setShowModal(true);
      return;
    }

    popup.onload = () => {
      if (popup.innerHeight === 0) {
        setShowModal(true);
      }
    };
  }, []);

  const openInLinkedin = useCallback(
    async ({ id }) => {
      // Use the cache and does not create an additional query
      const miniProfileResponse = await getMiniProfile({
        searchPoolId: 'reveal',
        profileId: id,
      });
      const miniProfile = miniProfileResponse?.data?.searchPool?.profile;
      if (!miniProfile) {
        return;
      }
      const url = miniProfile.resumeData?.sources?.linkedin;
      openPopup({ url });
    },
    [openPopup, getMiniProfile],
  );

  const openInATS = useCallback(
    async ({ id }) => {
      try {
        // Use the cache and does not create an additional query
        const miniProfileResponse = await getMiniProfile({
          searchPoolId: 'reveal',
          profileId: id,
        });
        const sourceData =
          miniProfileResponse?.data?.searchPool?.profile?.resumeData
            ?.sourceData;
        if (!sourceData) {
          return;
        }
        const firstAtsItem = _.find(
          SUPPORTED_ATS_TYPES,
          (atsType) => sourceData[atsType],
        );
        const candidateURL = getCandidateAtsURL({
          atsDataItem: sourceData[firstAtsItem],
          accountId: connector?.accountId,
        });
        if (!candidateURL) {
          return;
        }
        openPopup({ url: candidateURL });
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    [getMiniProfile, openPopup, connector],
  );

  const handleExportToCSV = useCallback(
    async ({ profiles, fields }) => {
      const token = localStorage.getItem('token');
      if (!token) {
        return;
      }
      try {
        const profilesIds = _.pluck(profiles, 'id');
        const { resolvedLanguage } = i18n;
        const lang = resolvedLanguage ? resolvedLanguage.split('-')[0] : null;
        const response = await axios.request({
          url: `${process.env.REACT_APP_SERVER_URL}/csv/export-reveal-profiles`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          responseType: 'blob',
          data: {
            clientId,
            profilesIds,
            ...(jobId ? { jobId } : {}),
            fields,
            lang,
          },
        });
        const filename = `HireSweet contacts - ${new Date().toLocaleDateString()}.csv`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        setTimeout(() => URL.revokeObjectURL(link.href), 0);
        link.click();
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [clientId, jobId],
  );

  const onActionSelected = useCallback(
    (selectedAction) => {
      if (selectedAction === 'open-in-linkedin') {
        // eslint-disable-next-line no-restricted-syntax
        for (const candidate of candidates) {
          // eslint-disable-next-line no-await-in-loop
          openInLinkedin(candidate);
        }
      }

      if (selectedAction === 'open-in-ats') {
        // eslint-disable-next-line no-restricted-syntax
        for (const candidate of candidates) {
          // eslint-disable-next-line no-await-in-loop
          openInATS(candidate);
        }
      }

      if (selectedAction === 'export-to-csv') {
        setExportModalOpen(true);
      }

      if (selectedAction === 'generate-document') {
        const profileIds = _.pluck(candidates, 'id');
        if (!generateDocument) {
          return;
        }
        generateDocument({
          variables: {
            profileIds,
            searchPoolId: 'reveal',
            jobId,
            templateId:
              clientId === 'ae-search' ? 'ae-search-bullet-points' : '',
          },
        }).then(({ data }) => {
          const content =
            data?.searchPool?.generateProfileListDocument?.file?.content;

          downloadPDF({
            filename: 'progress-report.docx',
            objectURL: content,
          });
          // console.log({ content });
        });
        // .then(({ data }) => {
        //   if (!data?.searchPool?.generateProfileListDocument?.file?.content) {
        //     return;
        //   }
        //   const { content } = data.searchPool.generateProfileListDocument.file;
        //   const [contentPreffix, contentData] = content.split(';base64,');
        //   const url = window.URL.createObjectURL(new Blob([contentData]));
        //   const link = document.createElement('a');
        //   link.href = url;
        //   link.target = '_blank';
        //   link.download = data.searchPool.generateProfileListDocument.name;
        //   link.click();
        // });
      }

      if (selectedAction === 'add-to-project') {
        setOpenAddToProject(true);
      }

      if (selectedAction === 'add-to-sequence') {
        setOpenAddToSequence(true);
      }

      if (selectedAction === 'add-to-campaign') {
        setOpenAddToCampaign(true);
      }

      if (selectedAction === 'create-tasks') {
        setOpenCreateTasks(true);
      }

      if (selectedAction === 'remove-from-mission') {
        setOpenRemoveFromMissionModal(true);
      }

      if (selectedAction === 'remove-from-sequence') {
        setOpenRemoveFromSequenceModal(true);
      }

      if (selectedAction === 'compute-ai-merge-tags') {
        instantiateAiMergeTags({
          variables: {
            searchPoolId: 'reveal',
            input: {
              profileIds: _.pluck(candidates, 'id'),
            },
          },
        });
      }

      if (selectedAction === 'bulk-enrich') {
        setOpenBulkEnrichModal(true);
      }

      if (selectedAction === 'remove-from-campaign') {
        if (!_.isEmpty(campaignId)) {
          removeProfilesFromCampaign({
            variables: {
              searchPoolId: 'reveal',
              input: {
                campaignId,
                profileIds: _.pluck(candidates, 'id'),
              },
            },
          });
        }
      }

      if (selectedAction === 'hold-in-campaign') {
        if (!_.isEmpty(campaignId)) {
          setProfilesToHold({
            variables: {
              searchPoolId: 'reveal',
              input: {
                campaignId,
                profileIds: _.pluck(candidates, 'id'),
              },
            },
          });
        }
      }

      if (selectedAction === 'set-to-scheduled-in-campaign') {
        if (!_.isEmpty(campaignId)) {
          setProfilesToScheduled({
            variables: {
              searchPoolId: 'reveal',
              input: {
                campaignId,
                profileIds: _.pluck(candidates, 'id'),
              },
            },
          });
        }
      }

      // if (selectedAction === 'create-linkedin-tasks') {
      //   setOpenCreateLinkedinTasks(true);
      // }

      if (selectedAction === 'reapply-sequence') {
        setOpenReapplySequenceModal(true);
      }
    },
    [
      clientId,
      candidates,
      openInLinkedin,
      openInATS,
      generateDocument,
      jobId,
      instantiateAiMergeTags,
      campaignId,
      removeProfilesFromCampaign,
      setProfilesToHold,
      setProfilesToScheduled,
    ],
  );

  const Trigger = () =>
    _.isEmpty(candidates) ? (
      <GenericTooltip
        trigger={
          <span>
            <ButtonDropdownMenu
              title={t('reveal.missions.mission.actionButton')}
              options={filteredBulkActionOptions}
              onSelect={onActionSelected}
              primacy='secondary'
              position='left'
              disabled
            />
          </span>
        }
        content={t('reveal.missions.mission.actionButtonHelper')}
        position='bottom center'
      />
    ) : (
      <ButtonDropdownMenu
        title={t('reveal.missions.mission.actionButton')}
        options={filteredBulkActionOptions}
        onSelect={onActionSelected}
        primacy='secondary'
        position='left'
      />
    );

  return (
    <AddProfileToSequenceContextProvider>
      <Trigger />

      {openAddToProject && (
        <AddToProjectSidebar
          clientId={clientId}
          candidates={candidates}
          onClose={() => setOpenAddToProject(false)}
        />
      )}

      {openAddToSequence && (
        <AddToSequenceSidebar
          clientId={clientId}
          jobId={jobId}
          candidates={candidates}
          onClose={() => setOpenAddToSequence(false)}
        />
      )}

      {openAddToCampaign && (
        <AddToCampaignSidebar
          clientId={clientId}
          candidates={candidates}
          onClose={() => setOpenAddToCampaign(false)}
        />
      )}

      {openCreateTasks && (
        <CreateManualTasksSidebar
          clientId={clientId}
          jobId={jobId}
          candidates={candidates}
          onClose={() => setOpenCreateTasks(false)}
        />
      )}

      {openRemoveFromMissionModal && (
        <RemoveFromMissionModal
          clientId={clientId}
          missionId={jobId}
          candidates={candidates}
          onSuccess={onSuccess}
          onCancel={() => setOpenRemoveFromMissionModal(false)}
        />
      )}

      {openRemoveFromSequenceModal && (
        <RemoveFromSequenceModal
          sequenceId={sequenceId}
          candidates={candidates}
          onSuccess={onSuccess}
          onCancel={() => setOpenRemoveFromSequenceModal(false)}
        />
      )}

      {openReapplySequenceModal && (
        <ReapplySequenceModal
          candidates={candidates}
          onCancel={() => setOpenReapplySequenceModal(false)}
          clientId={clientId}
        />
      )}

      {openBulkEnrichModal && (
        <BulkEnrichSidebar
          candidates={candidates}
          onClose={() => setOpenBulkEnrichModal(false)}
        />
      )}

      <Modal
        open={showModal}
        closeIcon
        onClose={() => setShowModal(false)}
        size='tiny'
      >
        <Modal.Header>Are your pop-ups disabled?</Modal.Header>
        <Modal.Content>
          Some tabs you were trying to open have been blocked by your browser.
          <br />
          You can allow our website to open pop-ups in your browser settings.
          <br />
          <br />
          e.g in Chrome:
          <img
            className='enable-popups-screenshot'
            src='/images/screenshots/enable-popups-chrome.png'
            alt='enable pop-ups in chrome'
          />
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => setShowModal(false)}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
      <ExportProfileCSVModal
        jobId={jobId}
        open={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
        onExport={(fields) =>
          handleExportToCSV({ profiles: candidates, fields })
        }
      />
    </AddProfileToSequenceContextProvider>
  );
};

export default ActionsDropdown;
