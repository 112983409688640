import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { compose } from 'underscore';
import classNames from 'classnames';

import AutosizeInput from 'react-18-input-autosize';
import { Popup } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

import { SecondaryBlue } from '@/less/colors';
import onLogAction from '../../../../../../common/logAction';
import { invalidEmailFormat } from '../../../../../../common/validators';
import withUpdateProfileEmail from '../../../../../../hocs/profiles/withUpdateProfileEmail';
import './SenderAndRecipient.css';

const SenderAndRecipient = ({
  from,
  profile,
  recipient,
  setRecipient,
  setCc,
  updateProfileEmail,
  t,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(false);
  const [recipientInputRef, setRecipientInputFocus] = useFocus();
  const [ccSelected, setCcSelected] = useState(true);

  const talentStrategistEmail = profile?.talentStrategist?.email;

  const onCopyText = () =>
    onLogAction({
      profileId: profile.id,
      type: 'copy-email',
      info: {
        from,
        to: profile?.email,
      },
    });

  useEffect(() => {
    if (editMode) {
      setRecipientInputFocus();
    }
  }, [editMode, setRecipientInputFocus]);

  const onEdit = () => {
    setEditMode(true);
  };

  const onSubmit = async () => {
    if (!recipient) {
      setEditMode(false);
      return;
    }

    if (invalidEmailFormat({ email: recipient })) {
      setError(true);
      setEditMode(false);
      return;
    }

    try {
      await updateProfileEmail({ id: profile?.id, email: recipient });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }

    setEditMode(false);
  };

  const onChangeRecipient = (e) => {
    setRecipient(e?.target?.value || '');
    setError(false);
  };

  const onSetCc = () => {
    const newState = !ccSelected;
    setCcSelected(newState);
    setCc(newState ? [talentStrategistEmail] : []);
    try {
      onLogAction({
        profileId: profile.id,
        type: newState ? 'restore-cc' : 'remove-cc',
        info: {
          from,
          to: profile.data?.email || profile.email,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const recipientIsEditable =
    !profile?.sent && (!profile?.email || profile?.isImported);

  return (
    <div>
      <div onCopy={onCopyText} className='sender-and-recipient'>
        {from && (
          <span className='sender'>
            <span className='intro-word'>
              {t('profile.contact.timeline.from')}
            </span>
            <span>{from}</span>
          </span>
        )}
        <span className='recipient'>
          <span className='intro-word'>{t('profile.contact.timeline.to')}</span>
          {recipientIsEditable ? (
            <span onClick={onEdit}>
              <AutosizeInput
                ref={recipientInputRef}
                className={classNames('recipient-input', error && 'error')}
                value={recipient}
                onChange={onChangeRecipient}
                placeholder={t(
                  'profile.contact.timeline.emailInputPlaceholder',
                )}
                disabled={!editMode}
                onBlur={onSubmit}
              />
              {!editMode && <i className='recipient-icon ri-edit-line' />}
            </span>
          ) : (
            <span>{profile?.resumeData?.email}</span>
          )}
          {talentStrategistEmail && (
            <Popup
              style={{
                fontFamily: 'Graphik',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '16px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: SecondaryBlue,
              }}
              content={talentStrategistEmail}
              trigger={
                <span
                  className={`pill-message ${ccSelected ? 'blue' : 'grey'}`}
                  onClick={() => onSetCc()}
                >
                  Cc {ccSelected && '+1'}
                </span>
              }
            />
          )}
        </span>
      </div>
    </div>
  );
};

const useFocus = () => {
  const ref = useRef(null);
  const setFocus = () => {
    ref.current?.focus();
  };
  return [ref, setFocus];
};

export default compose(
  withTranslation('translations'),
  withUpdateProfileEmail,
)(SenderAndRecipient);
