import React, { FC } from 'react';

import styles from './svg.module.less';

const Phone: FC = () => (
  <svg
    className={styles.svgIcon}
    width='16'
    height='8'
    viewBox='0 0 16 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.4475 4.63988C10.3686 4.22541 9.20838 4 8 4C6.79162 4 5.63137 4.22541 4.55249 4.63988C4.45968 4.67553 4.41328 4.69335 4.36205 4.72409C4.17999 4.83334 4.0364 5.05495 4.0025 5.27903C3.99296 5.34209 3.99296 5.40496 3.99296 5.5307C3.99296 5.91524 3.99296 6.10752 3.96204 6.26704C3.84544 6.86865 3.41573 7.33864 2.86569 7.46618C2.71983 7.5 2.54404 7.5 2.19245 7.5H1.99648C1.46203 7.5 1.1948 7.5 0.978725 7.42243C0.548989 7.26817 0.211958 6.89954 0.0709184 6.42952C1.04431e-06 6.19318 -2.15374e-08 5.90091 0 5.31635V5.14296C2.15831e-08 4.5604 1.03669e-06 4.26913 0.0793404 3.95989C0.167378 3.61675 0.398241 3.18675 0.629335 2.93549C0.837596 2.70906 1.01979 2.5979 1.38417 2.37558C3.34238 1.1808 5.5985 0.5 8 0.5C10.4015 0.5 12.6576 1.1808 14.6158 2.37558C14.9802 2.5979 15.1624 2.70906 15.3707 2.93549C15.6018 3.18675 15.8326 3.61675 15.9207 3.95989C16 4.26913 16 4.5604 16 5.14296V5.31635C16 5.90091 16 6.19318 15.9291 6.42952C15.788 6.89954 15.451 7.26817 15.0213 7.42243C14.8052 7.5 14.538 7.5 14.0035 7.5H13.8075C13.456 7.5 13.2802 7.5 13.1343 7.46618C12.5843 7.33864 12.1546 6.86865 12.038 6.26704C12.007 6.10752 12.007 5.91524 12.007 5.5307C12.007 5.40496 12.007 5.34209 11.9975 5.27903C11.9636 5.05495 11.82 4.83334 11.6379 4.7241C11.5867 4.69336 11.5403 4.67553 11.4475 4.63988Z'
      fill='#1F2E77'
    />
  </svg>
);

export default Phone;
