import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SmallResumeData from '../../graphql/fragments/SmallResumeData';
import PipeStepStat from '../../graphql/fragments/PipeStepStat';
import Author from '../../graphql/fragments/Author';

export const refetchQuery = gql`
  query updateProfileStepCache(
    $offerId: ID!
    $step: String!
    $search: String!
  ) {
    offer(id: $offerId) {
      id
      pipeStepStats {
        ...PipeStepStat
      }
      profiles(step: $step, search: $search) {
        id
        resumeData {
          ...SmallResumeData
        }
        lastStepUpdateTimestamp
      }
    }
  }
  ${SmallResumeData}
  ${PipeStepStat}
`;

export const mutation = gql`
  mutation changeProfileStep($id: ID!, $step: String!) {
    changeProfileStep(id: $id, step: $step) {
      id
      resumeData {
        step
        subStep
      }
      contactData {
        timeline {
          author {
            ...Author
          }
          date
          action {
            ... on StepAction {
              step {
                stepId
                details
              }
            }
          }
        }
      }
      lastStepUpdateTimestamp
    }
  }
  ${Author}
`;

export default graphql(mutation, {
  props: ({ ownProps, mutate }) => ({
    changeProfileStep: ({ id, fromStep, step, offerId }) =>
      mutate({
        variables: { id, step },
        refetchQueries: [
          {
            query: refetchQuery,
            variables: {
              offerId,
              // 'from' step is either specified in original query, or as a component prop
              step: ownProps?.stepId || fromStep,
              search: ownProps?.searchText ? ownProps?.searchText : '',
            },
          },
          {
            query: refetchQuery,
            variables: {
              offerId,
              step: ownProps?.stepId || fromStep,
              search: '',
            },
          },
        ],
      }),
  }),
});
