import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'semantic-ui-react';

import styles from './Placeholder.module.less';

const AutomationConditionsChainingPlaceholder: React.FC<{
  offsetKey: number;
  readOnly: boolean;
  setIsReadOnly: (isReadOnly: boolean) => void;
  children: React.ReactNode;
  onEditSmartVariable: () => void;
}> = ({
  offsetKey,
  readOnly,
  setIsReadOnly,
  children,
  onEditSmartVariable,
}) => {
  const { t } = useTranslation();
  const [open, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsReadOnly(true);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsReadOnly(false);
    setIsOpen(false);
  };

  const handleClick = () => {
    onEditSmartVariable();
    setIsOpen(false);
    setIsReadOnly(false);
  };

  const span = (
    <span
      spellCheck={false}
      data-offset-key={offsetKey}
      className={styles.placeholder}
    >
      {children}
    </span>
  );

  if (readOnly) {
    return span;
  }

  return (
    <Popup
      open={open}
      style={{ padding: '0px' }}
      position='top center'
      content={
        <div className={styles.popup} onClick={handleClick} aria-hidden>
          <div className={styles.title}>{t('common.edit')}</div>
        </div>
      }
      on='click'
      trigger={span}
      onOpen={(event) => {
        event.preventDefault();
        handleOpen();
      }}
      onClose={(event) => {
        event.preventDefault();
        handleClose();
      }}
    />
  );
};

export default AutomationConditionsChainingPlaceholder;
