import moment from 'moment';
import _ from 'underscore';

export const nDaysAgo = (n) => {
  // include beginning of the day (midnight onwards)
  return moment()
    .subtract(n, 'days')
    .startOf('day');
};

export const sameDayNMonthsAgo = (n) => {
  return moment()
    .subtract(n, 'months')
    .startOf('day');
};

export const filterEventsByDateRange = ({ events, startDate, endDate }) => {
  if (startDate === '-Inf' && endDate === '+Inf') {
    return events;
  }
  return _.filter(events, (event) => {
    if (!event) {
      return false;
    }
    const eventDate = moment(event.day);
    return startDate <= eventDate && eventDate <= endDate;
  });
};

export const dateRangeDates = ({ dateRange }) => {
  let endDate = moment();
  let startDate;
  switch (dateRange) {
    case '7days': {
      startDate = nDaysAgo(6);
      break;
    }
    case '30days': {
      startDate = nDaysAgo(29);
      break;
    }
    case '60days': {
      startDate = nDaysAgo(59);
      break;
    }
    case '180days': {
      startDate = nDaysAgo(179);
      break;
    }
    case '365days': {
      startDate = nDaysAgo(364);
      break;
    }
    case 'week': {
      startDate = moment()
        .startOf('isoWeek')
        .startOf('day');
      break;
    }
    case 'month': {
      startDate = moment().startOf('month');
      break;
    }
    case 'quarter': {
      startDate = moment().startOf('quarter');
      break;
    }
    case 'year': {
      startDate = moment().startOf('year');
      break;
    }
    case 'allTime': {
      startDate = '-Inf';
      endDate = '+Inf';
      break;
    }
    default:
      startDate = '-Inf';
      endDate = '+Inf';
      break;
  }
  return { startDate, endDate };
};

/**
 * Example output: Single 'Event Stats' object, without a date
 *  {
 *    nbAnswered: X,
 *    nbPositiveAnswered: Y,
 *    nbSent: Z
 *    nbAnswered: W,
 *    nbSent: U,
 *    nbInProcess: V,
 *  }
 */
export const aggregateEvents = ({ events }) => {
  return _.reduce(
    events,
    (totals, event) => {
      return {
        nbAnswered: (totals.nbAnswered || 0) + (event.nbAnswered || 0),
        nbPositiveAnswered:
          (totals.nbPositiveAnswered || 0) + (event.nbPositiveAnswered || 0),
        nbSent: (totals.nbSent || 0) + (event.nbSent || 0),
        nbSentWithEmailOpenTracking:
          (totals.nbSentWithEmailOpenTracking || 0) +
          (event.nbSentWithEmailOpenTracking || 0),
        nbOpened: (totals.nbOpened || 0) + (event.nbOpened || 0),
        nbSkipped: (totals.nbSkipped || 0) + (event.nbSkipped || 0),
        nbHired: (totals.nbHired || 0) + (event.nbHired || 0),
        nbInProcess: (totals.nbInProcess || 0) + (event.nbInProcess || 0),
      };
    },
    {},
  );
};

export const safeDivide = (a, b) => {
  if (!b) {
    return null;
  }
  return (a || 0) / b;
};

export const addRates = ({ events, totalSent }) => {
  return _.map(events, (event) => {
    if (_.isEmpty(event)) {
      return {};
    }
    const {
      nbAnswered,
      nbPositiveAnswered,
      nbSent,
      nbSentWithEmailOpenTracking,
      // nbSentWithLinkClickTracking,
      nbInProcess,
      nbOpened,
      // nbClicked,
    } = event;
    const totalNbSent = totalSent || nbSent;
    return {
      ...event,
      rateAnswered: safeDivide(nbAnswered, totalNbSent),
      rateOpened: safeDivide(nbOpened, nbSentWithEmailOpenTracking),
      // rateClicked: safeDivide(nbClicked, nbSentWithLinkClickTracking),
      ratePositiveAnswered: safeDivide(nbPositiveAnswered, totalNbSent),
      rateInProcess: safeDivide(nbInProcess, totalNbSent),
    };
  });
};

const startOfPeriod = ({ frequency, dayMoment }) => {
  switch (frequency) {
    case 'day': {
      return dayMoment.startOf('day');
    }
    case 'week': {
      return dayMoment.startOf('isoWeek');
    }
    case 'month': {
      return dayMoment.startOf('month');
    }
    case 'quarter': {
      return dayMoment.startOf('quarter');
    }
    case 'year': {
      return dayMoment.startOf('year');
    }
    default:
      throw new Error(`Invalid frequency: ${frequency}`);
  }
};

/**
 * Example output (frequency = 'week'):
 *  {
 *    '2020-01-06': [{ nbSent: 42, nHired: 3, ...}, ...],
 *    '2020-01-13': [{ nbSent: 42, nHired: 3, ...}, ...],
 *    ...
 *  }
 */
export const groupBy = ({ events, frequency }) => {
  const groupedEvents = {};
  _.each(events, (event) => {
    const dayMoment = moment(event.day);
    const groupId = startOfPeriod({ frequency, dayMoment }).format(
      'YYYY-MM-DD',
    );
    groupedEvents[groupId] = groupedEvents[groupId] || [];
    groupedEvents[groupId].push(event);
  });
  return groupedEvents;
};

export const aggregateCohorts = ({ events, frequency }) => {
  if (!frequency) {
    return [];
  }
  if (frequency === 'day') {
    return _.map(events, (event) => ({ ...event, frequency }));
  }
  // group events by frequency in dict
  const groupedEvents = groupBy({ events, frequency });
  // compute aggregations for each key in dict
  const groupAggregations = _.mapObject(groupedEvents, (eventsGroup) =>
    aggregateEvents({ events: eventsGroup }),
  );
  // flatten groupAggregations from dict to array
  return _.map(Object.keys(groupAggregations), (groupId) => {
    return {
      ...groupAggregations[groupId],
      day: groupId,
      frequency,
    };
  });
};

export const addTimestamp = ({ events }) => {
  return _.map(events, (event) => ({
    ...event,
    timestamp: moment(event.day).valueOf(),
  }));
};

export const sortByDay = ({ events }) => {
  return _.sortBy(events, 'day');
};

export const fillEmptyValues = ({ sortedEvents, frequency }) => {
  if (_.isEmpty(sortedEvents)) {
    return [];
  }

  const eventsByDay = {};
  _.each(sortedEvents, (event) => {
    eventsByDay[event.day] = event;
  });

  const newSortedEvents = [];
  let currentDate = startOfPeriod({
    frequency,
    dayMoment: moment(sortedEvents[0].day),
  });
  while (currentDate <= moment()) {
    const day = currentDate.format('YYYY-MM-DD');
    const emptyEvent = {
      day,
      frequency,
      nbSent: 0,
      nbAnswered: 0,
      nbPositiveAnswered: 0,
      nbInProcess: 0,
      nbHired: 0,
      nbSkipped: 0,
    };
    const event = eventsByDay[day] || emptyEvent;
    newSortedEvents.push(event);
    currentDate = currentDate.add(1, frequency);
  }
  return newSortedEvents;
};

export const formatPercent = (number) => {
  return parseFloat((number * 100).toFixed(1));
};

export const formatPercentWithSymbol = (number, nanValue) => {
  if (!_.isNumber(number)) {
    return nanValue === undefined ? '' : nanValue; // NOTE: nanValue || '' does not work with nanValue = 0;
  }
  return `${formatPercent(number)}%`;
};

export const formatNumber = (number, nanValue) => {
  const defaultValue = _.isUndefined(nanValue) ? '' : nanValue;
  return _.isNumber(number) ? number : defaultValue;
};

export const formatFloat = (float, digits, nanValue) => {
  const defaultValue = _.isUndefined(nanValue) ? '' : nanValue;
  return _.isNumber(float) ? parseFloat(float.toFixed(digits)) : defaultValue;
};

export const activityTooltip = ({ t }) => {
  return (dataPoint) => {
    const { date } = dataPoint;
    let activityCount;
    if (!_.isNumber(dataPoint.count)) {
      activityCount = '';
    } else if (dataPoint.count === 1) {
      activityCount = `1 ${t('analytics.tooltips.activity', { count: 1 })}`;
    } else {
      activityCount = `${dataPoint.count} ${t('analytics.tooltips.activity', {
        count: 0,
      })}`;
    }
    const dateText = `${t('analytics.dateFormats.day', { date })}`;
    return activityCount ? activityCount + dateText : dateText;
  };
};

export const formatActivityData = ({ events }) => {
  return _.map(events, (event) => {
    return {
      date: event.day,
      count: event.nbSent,
    };
  });
};

export const ticksByFrequency = ({ startDate, frequency }) => {
  if (frequency === 'day') {
    return null; // will let recharts use default values;
  }
  const endTs = Date.now();

  const period = frequency === 'week' ? 'isoWeek' : 'month';
  const startMoment = moment(startDate)
    .startOf(period)
    .startOf('day');
  const endMoment = moment(endTs).startOf('day');

  let currentMoment = startMoment;
  const ticks = [];

  while (currentMoment < endMoment) {
    ticks.push(currentMoment.valueOf());
    currentMoment = currentMoment.add(1, frequency);
  }
  return ticks;
};

export const dateTickFormatter = (frequency, t) => {
  return (timestamp) => {
    if (!timestamp) {
      return '';
    }
    const date = new Date(timestamp);
    if (frequency === 'quarter') {
      return t('analytics.dateFormats.quarter', { date });
    }
    if (frequency === 'month') {
      return t('analytics.dateFormats.month', { date });
    }
    if (frequency === 'week') {
      return t('analytics.dateFormats.week', { date });
    }
    return t('analytics.dateFormats.day', { date });
  };
};

export const scoreFromStats = ({ stats, regularity }) => {
  const { nbSent, nbAnswered } = stats || {};
  const score = ((nbSent || 0) + 4 * (nbAnswered || 0)) * regularity ** 2;

  if (score > 0 && score < 1) {
    return 1;
  }

  return formatFloat(score, 0);
};

/**
 * Scores the regularity based on the number of working days that were active
 * in the past 30 days (heuristic of 5/7 working days)
 *
 * Score is in {1 , 5}
 */
export const regularityFromEvents = ({ events }) => {
  const addOnePerActiveDay = (activeDaysTemp, event) => {
    return activeDaysTemp + (event.nbSent > 0 ? 1 : 0);
  };
  const activeDays = _.reduce(events, addOnePerActiveDay, 0);
  const regularityScore = ((activeDays * (5 / 7)) / 30) * 5;
  return regularityScore > 5 ? 5 : regularityScore;
};

export const generateEmptyDailyCohorts = ({ nDays }) => {
  const emptyData = [];
  let timestamp = Date.now();
  for (let i = 0; i < nDays; i += 1) {
    timestamp -= 3600 * 24 * 1000;
    emptyData.push({
      day: moment(timestamp).format('YYYY-MM-DD'),
      frequency: 'day',
      nbAnswered: 0,
      nbHired: 0,
      nbInProcess: 0,
      nbPositiveAnswered: 0,
      nbSent: 0,
      nbSkipped: 0,
    });
  }
  return emptyData;
};
