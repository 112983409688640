import React, { useEffect, useMemo, useState } from 'react';
import TextArea from 'react-textarea-autosize';
import _ from 'underscore';
import CharacterCount from '@/components/CharacterCount';

import './CustomTextArea.css';

export const DEBOUNCE_INPUT_MS = 400;

const CustomTextArea = ({
  currentValue,
  label,
  placeholderText,
  onChange,
  withCharacterCount = false,
}) => {
  const [value, setValue] = useState(currentValue);
  // console.log('VALUE', { currentValue, value });

  const onChangeMemoized = useMemo(() => {
    return _.debounce(onChange, DEBOUNCE_INPUT_MS);

    // eslint-disable-next-line
  }, []);

  const handleChange = (ev) => {
    // ev.persist(); // for debugging

    const newValue = ev.target.value;
    // console.log('EVENTS', { ev, newValue, value });

    setValue(newValue);
    onChangeMemoized({ label, value: { value: newValue } });
  };

  useEffect(() => {
    // FIXME quick fix to reset form, with the debounce function, the state is reset to an old state
    if (!currentValue?.length) {
      setValue('');
    }
  }, [currentValue]);

  if (withCharacterCount) {
    return (
      <div className='flex-column'>
        <TextArea
          className='custom-textarea'
          value={value}
          placeholder={placeholderText || ''}
          onChange={handleChange}
        />
        <CharacterCount text={value} endPlacement />
      </div>
    );
  }

  return (
    <>
      <TextArea
        className='custom-textarea'
        value={value}
        placeholder={placeholderText || ''}
        onChange={handleChange}
      />
    </>
  );
};

export default CustomTextArea;
