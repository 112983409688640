/* eslint-disable max-len */

import React, { FC } from 'react';

const NudgeArrow: FC = () => (
  <svg
    style={{ marginRight: 15 }}
    width='61'
    height='23'
    viewBox='0 0 61 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.14844 1C6.07031 16.8259 17.225 24.0872 36.0312 21.4806C59.5391 18.2223 48.9375 2.3964 45.25 7.05107C41.5625 11.7057 49.3984 18.2223 60 14.4985M5.14844 1L1 7.51654M5.14844 1L11.1406 6.58561'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </svg>
);

export default NudgeArrow;
