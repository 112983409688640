import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import useClientId from '@/hooks/router/useClientId';
import useClient from '@/graphql/hooks/clients/useClient';
import { remotePolicies } from '@/types/remotePolicy';

import { getTranslatedText } from '../../common';
import {
  getDateDiffInMs,
  getDurationInYearsAndMonths,
} from '../../containers/Profile/Resume/Background/date-utils';
import TranslatableText from '../TranslatableText';
import { getFirstLineOnlyReview } from './helpers';
import SkillsLabels, { getSortedSkillsWishes } from './SkillsLabels';
import { MarketplaceUnbiasedModeField } from '../UnbiasedMode/UnbiasedModeField';
import { BlurLine, BlurParagraph } from '../UnbiasedMode/BlurLine';

import './ProfileCardContent.css';

const ProfileCardContent = ({
  profile,
  condensedMode = false,
  canApplyUnbiasedMode,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { data } = useClient(clientId);
  const { hasLimitedAccessToProfiles } = data?.client || {};
  const { resumeData } = profile || {};
  const { mainEducation, experiences, sourceData, smartSummary } =
    resumeData || {};

  const hiresweetData = sourceData?.hiresweet;

  const experienceToDisplay = _.first(experiences);
  const displayedExperience = getDisplayedExperience(
    experienceToDisplay,
    t,
    hasLimitedAccessToProfiles,
  );

  const schoolName = mainEducation?.schoolName || null;
  const educationEndDate = sweetDateToMomentDate(mainEducation?.endDate);
  const displayedEducation = schoolName && {
    schoolName,
    timeFromNow: educationEndDate && educationEndDate.fromNow(),
  };

  const sortedSkillsWishes = getSortedSkillsWishes({ hiresweetData });
  const locationWishes = getLocationWishes({ hiresweetData, t });
  const salaryWish = getSalaryWish({ hiresweetData });
  const review = _.find(smartSummary?.arguments, { type: 'review' }) || null;
  const firstLineOnlyReview = getFirstLineOnlyReview({ review });

  return (
    <div className='profile-card-content'>
      {firstLineOnlyReview && (
        <div className='summary'>
          {hasLimitedAccessToProfiles ? (
            <BlurParagraph lines={1} />
          ) : (
            <>
              &laquo;
              <TranslatableText text={firstLineOnlyReview} />
              &raquo;
            </>
          )}
        </div>
      )}
      {condensedMode ? (
        <ExperienceAndEducation
          canApplyUnbiasedMode={canApplyUnbiasedMode}
          displayedEducation={displayedEducation}
          displayedExperience={displayedExperience}
        />
      ) : (
        <div className='row'>
          <ExperienceAndEducation
            canApplyUnbiasedMode={canApplyUnbiasedMode}
            displayedEducation={displayedEducation}
            displayedExperience={displayedExperience}
          />
        </div>
      )}
      <div className='row' />
      <div className='row'>
        <div className='body-item'>
          {locationWishes && (
            <>
              <i className='ri-map-pin-line' />
              {locationWishes}
            </>
          )}
        </div>
        {salaryWish && (
          <div className='body-item'>
            <i className='ri-money-dollar-circle-line' />
            {t('watchCollect.results.profileCard.content.salaryExpectations', {
              amount: salaryWish,
            })}
          </div>
        )}
      </div>
      <div className='skills'>
        <SkillsLabels
          skills={sortedSkillsWishes}
          condensedMode={condensedMode}
        />
      </div>
    </div>
  );
};
const ExperienceAndEducation = ({
  displayedExperience,
  displayedEducation,
  canApplyUnbiasedMode = false,
}) => {
  const clientId = useClientId();
  const { data } = useClient(clientId);
  const { hasLimitedAccessToProfiles } = data?.client || {};
  const DefaultBlurLine = (
    // to center and to make it visible
    <BlurLine style={{ minWidth: '100px', display: 'inline-block' }} />
  );
  return (
    <>
      {displayedExperience && (
        <div className='body-item'>
          <i className='ri-briefcase-4-line' />
          <div>
            <span>{displayedExperience.titleText}</span>
            {displayedExperience.duration && (
              <span className='time-span'>
                {' '}
                ({displayedExperience.duration})
              </span>
            )}
          </div>
        </div>
      )}
      {displayedEducation && (
        <div className='body-item'>
          <i className='ri-award-line' />
          {hasLimitedAccessToProfiles ? (
            <BlurLine style={{ width: 250, alignSelf: 'center' }} />
          ) : (
            <div>
              <MarketplaceUnbiasedModeField
                name='hideEducationSchoolNames'
                enabled={canApplyUnbiasedMode}
                fallback={DefaultBlurLine}
              >
                <span>{displayedEducation.schoolName}</span>
                <MarketplaceUnbiasedModeField
                  name='hideEducationDates'
                  enabled={canApplyUnbiasedMode}
                  fallback={DefaultBlurLine}
                >
                  {displayedEducation.timeFromNow && (
                    <span className='time-span'>
                      {' '}
                      ({displayedEducation.timeFromNow})
                    </span>
                  )}
                </MarketplaceUnbiasedModeField>
              </MarketplaceUnbiasedModeField>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const getLocationWishes = ({ hiresweetData, t }) => {
  const locationsWishes = hiresweetData?.wishes?.locations;

  const allLocationWishes = [
    ...(locationsWishes?.target || []),
    ...(locationsWishes?.okWith || []),
  ];

  if (_.isEmpty(allLocationWishes)) {
    return null;
  }

  const locationWishesByRemoteWish = _.groupBy(allLocationWishes, 'remoteWish');

  const locationWishesAsText = _.map(remotePolicies, (remotePolicy) => {
    const locations = locationWishesByRemoteWish[remotePolicy];
    if (!locations) {
      return null;
    }
    const locationsText = _.map(locations, ({ location }) =>
      getTranslatedText(location),
    ).join(', ');
    return t(
      `watchCollect.results.profileCard.content.locationsAndRemote.${remotePolicy}`,
      { locations: locationsText },
    );
  });

  return _.compact(locationWishesAsText).join(' ; ');
};

// TODO: NOTE - we display minimum base salary not target
const getSalaryWish = ({ hiresweetData }) =>
  hiresweetData?.wishes?.salary?.okWith ||
  hiresweetData?.wishes?.salary?.target;

const getDisplayedExperience = (experience, t, hasLimitedAccessToProfiles) => {
  if (!experience) {
    return null;
  }
  const { title, companyName, startDate, endDate, workplace } = experience;
  const dateDiff = getDateDiffInMs(
    startDate,
    endDate || new Date().toISOString(),
  );

  const company = hasLimitedAccessToProfiles
    ? getTranslatedText(workplace?.data?.types?.[0]?.name) ||
      t('trial.placeholders.company')
    : companyName;

  const duration = getDurationInYearsAndMonths({ durationInMs: dateDiff, t });
  return {
    titleText: _.compact([title?.text, company]).join(' • '),
    duration,
  };
};

const sweetDateToMomentDate = (date) => {
  const { year, month } = date || {};
  const currentYear = new Date().getFullYear();
  const defaultEducationMonth = 6;
  if (!year || currentYear < year) {
    return null;
  }
  return moment([year, _.isNumber(month) ? month : defaultEducationMonth]);
};

export default React.memo(ProfileCardContent);
