import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
import '../ConfirmationModal/confirmationModal.css';

const ForceSendModal = ({
  onSubmit,
  onCancel,
  open,
  t,
  title,
  content,
  question,
}) => {
  return (
    <div>
      <Modal
        className='stop-confirmation-modal'
        open={open}
        onClose={onCancel}
        closeIcon
        size='tiny'
      >
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          <div>
            {content}
            <br />
            {question}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div className='align-left'>
            <Button className='dismiss' type='button' onClick={onCancel}>
              {t('profile.contact.drafts.confirmationModal.cancel')}
            </Button>
          </div>
          <Button primary size='big' type='button' onClick={onSubmit}>
            {t('profile.contact.drafts.confirmationModal.send')}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default withTranslation('translations')(ForceSendModal);
