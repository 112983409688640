import React, { FC } from 'react';

import styles from './Counter.module.less';

interface CounterProps {
  count: number;
}

const Counter: FC<CounterProps> = ({ count }) => (
  <span className={styles.pill}>{count}</span>
);

export default Counter;
