import React, { FC, useMemo } from 'react';
import { Dropdown, Placeholder } from 'semantic-ui-react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// import useClientUsers from '@/graphql/hooks/clients/useClientUsers';
import _ from 'underscore';
import useFilteredClientUsers from '@/graphql/hooks/clients/useFilteredClientUsers';
import { getUserOptions } from '../../../routes/RevealView/optionsHelpers';

interface UserFilterProps {
  selectedUser?: string | null | undefined;
  onChange: (e?: any, data?: any) => void;
  className: string;
  sortOptions?: boolean;
  // jobsUsers: string[];
  shouldLimitOnScopedMissionOwners?: boolean;
}

interface UserOptions {
  text: string;
  value: string;
}

const UserFilter: FC<UserFilterProps> = ({
  className = '',
  selectedUser,
  onChange,
  sortOptions,
  // jobsUsers = [],
  shouldLimitOnScopedMissionOwners = false,
}) => {
  const { t } = useTranslation();
  const { clientId } = useParams<{ clientId: string }>();
  const { data: usersData, loading } = useFilteredClientUsers(
    clientId,
    shouldLimitOnScopedMissionOwners ? (
      [ { "key": "scoped-mission-owners", "descriptor": ""}]
    ) : [],
    {
      fetchPolicy: 'cache-first',
    }
  );

  const options: UserOptions[] = useMemo(() => {
    if (!usersData?.client?.users) {
      return [];
    }

    return sortOptions
      ? _.sortBy(getUserOptions(usersData.client.users), 'text')
      : getUserOptions(usersData.client.users);
  }, [usersData, sortOptions]);

  const filteredUsers = options;/* useMemo(() => {
    if (_.isEmpty(jobsUsers)) {
      return options;
    }
    return _.filter(options, (option) => _.includes(jobsUsers, option.value));
  }, [jobsUsers, options]); */

  if (loading) {
    return (
      <Placeholder className={className}>
        <Placeholder.Header image />
        <Placeholder.Line />
      </Placeholder>
    );
  }

  return (
    <Dropdown
      className={className}
      selection
      search
      value={selectedUser || 'all'}
      onChange={onChange}
      options={[
        { value: 'all', text: t('reveal.todosView.filters.allUsers') },
        ...filteredUsers,
      ]}
    />
  );
};

export default UserFilter;
