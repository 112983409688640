import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { ArchiveReason, GET_ARCHIVE_REASONS } from './archiveReasons';

interface GetArchiveReasonsData {
  client: {
    id: string;
    archiveReasons: ArchiveReason[];
  };
}

interface GetArchiveReasonsVariables {
  clientId: string;
  includeArchived?: boolean;
}

interface UseArchiveReasonsInput extends GetArchiveReasonsVariables {
  queryOptions?: QueryHookOptions<
    GetArchiveReasonsData,
    GetArchiveReasonsVariables
  >;
}

interface UseArchiveReasonsResult
  extends QueryResult<GetArchiveReasonsData, GetArchiveReasonsVariables> {
  archiveReasons?: ArchiveReason[];
}

const useArchiveReasons = ({
  clientId,
  includeArchived,
  queryOptions = {},
}: UseArchiveReasonsInput): UseArchiveReasonsResult => {
  const query = useQuery<GetArchiveReasonsData, GetArchiveReasonsVariables>(
    GET_ARCHIVE_REASONS,
    {
      ...queryOptions,
      variables: { clientId, includeArchived },
    },
  );

  const { archiveReasons } = query.data?.client || {};
  return { ...query, archiveReasons };
};

export default useArchiveReasons;
