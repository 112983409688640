import React from 'react';
import _ from 'underscore';

import { getTranslatedText } from '../../../../common';


import './RelevantFacts.css';

const RelevantFacts = ({ facts }) => {
  const factsGroups = _.groupBy(facts, (__, i) => Math.floor(i / 2));

  return (
    <div className="relevant-facts">
      {_.map(factsGroups, (group, index) => (
        <div className='relevant-fact-group' key={index}>
          {group.length >= 1 && (
            <RelevantFact
              label={group[0].title}
              content={group[0].description}
              isNotFirstRow={+index > 0}
            />
          )}
          {group.length >= 2 && (
            <RelevantFact
              label={group[1].title}
              content={group[1].description}
              isNotFirstRow={+index > 0}
              isNotFirstColumn
            />
          )}
        </div>
      ))}
    </div>
  );
};

const RelevantFact = ({ label, content }) => {
  return (
    <div className='relevant-fact'>
      <div className='fact-label'>{getTranslatedText(label)}</div>
      <div className='fact-value'>{getTranslatedText(content)}</div>
    </div>
  );
};

RelevantFact.defaultProps = {
  isNotFirstColumn: false,
};

export default RelevantFacts;
