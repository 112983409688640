import _ from 'underscore';
import React, { PropsWithChildren, useContext, useState } from 'react';
import {
  HiresweetLibProvider,
  ProfileSetItemContainer,
  ProfileFirstname,
  ProfileLastname,
  ProfilesSetContainer,
  ProfilesSetContext,
  ProfilesSubsetRegister,
  ProjectContainer,
  ProjectTitle,
  ProjectOwner,
} from '@hiresweet/hiresweet-lib';
import useClientId from '@/hooks/router/useClientId';

const ProfilesTable = ({ children }: PropsWithChildren<object>) => {
  const { profileIds } = useContext(ProfilesSetContext);
  return (
    <ProfilesSubsetRegister profileIds={profileIds}>
      <table border={1}>{children}</table>
    </ProfilesSubsetRegister>
  );
};

const ProfilesTableHeader = ({ children }: PropsWithChildren<object>) => {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
};

const ProfilesTableHeaderCell = ({ children }: PropsWithChildren<object>) => {
  return <th>{children}</th>;
};

const ProfilesTableBody = ({ children }: PropsWithChildren<object>) => {
  return <tbody>{children}</tbody>;
};

const ProfilesTableRowMap = ({ children }: PropsWithChildren<object>) => {
  const { profileIds } = useContext(ProfilesSetContext);
  return (
    <>
      {(profileIds || []).map((profileId) => (
        <tr key={profileId}>
          <ProfileSetItemContainer profileId={profileId}>
            {children}
          </ProfileSetItemContainer>
        </tr>
      ))}
    </>
  );
};

const ProfilesTableCell = ({ children }: PropsWithChildren<object>) => {
  return <td>{children}</td>;
};

const Playground8 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();

  const projectId = 'ri7-demo-test-hellowork-NEIIVG';

  const [mode, setMode] = useState<'applicant' | 'step'>('step');

  const filterForApplicants = {
    type: 'project-applicants',
    projectId,
  };
  const filterForStepProfiles = {
    type: 'project-step',
    projectId: 'ri7-demo-test-hellowork-NEIIVG',
    step: 'all',
  };

  const filter =
    mode === 'applicant' ? filterForApplicants : filterForStepProfiles;

  return (
    <div style={{ padding: 30 }}>
      <h1>P8 - Posting / Command</h1>
      <div>
        <HiresweetLibProvider theme={theme} clientId={clientId}>
          <ProjectContainer projectId={projectId}>
            <strong>
              <ProjectTitle />
            </strong>
            <br />
            <em>
              <ProjectOwner />
            </em>
            <br />
          </ProjectContainer>

          {(['applicant', 'step'] as ('applicant' | 'step')[]).map(
            (modeOption) => (
              <button
                key={modeOption}
                type='button'
                style={{
                  cursor: 'pointer',
                  background: modeOption === mode ? 'lightgrey' : 'whitesmoke',
                  border: modeOption === mode ? '1px solid grey' : '0px',
                  borderRadius: 2,
                  padding: 8,
                  marginRight: 10,
                }}
                onClick={() => {
                  setMode(modeOption);
                }}
              >
                {{ applicant: 'Applicants', step: 'Follow-up' }[modeOption] ??
                  ''}
              </button>
            ),
          )}
          <br />
          <br />

          <ProfilesSetContainer filter={filter}>
            <ProfilesTable>
              <ProfilesTableHeader>
                <ProfilesTableHeaderCell>Firstname</ProfilesTableHeaderCell>
                <ProfilesTableHeaderCell>Lastname</ProfilesTableHeaderCell>
              </ProfilesTableHeader>

              <ProfilesTableBody>
                <ProfilesTableRowMap>
                  <ProfilesTableCell>
                    <ProfileFirstname />
                  </ProfilesTableCell>
                  <ProfilesTableCell>
                    <ProfileLastname />
                  </ProfilesTableCell>
                </ProfilesTableRowMap>
              </ProfilesTableBody>
            </ProfilesTable>
          </ProfilesSetContainer>
        </HiresweetLibProvider>
      </div>
    </div>
  );
};

export default Playground8;
