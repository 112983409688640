import * as Sentry from '@sentry/browser';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import _ from 'underscore';

import { useTranslation } from 'react-i18next';
import { CREATE_MANUAL_TASKS } from '@/graphql/projects';
import { TASK_TYPES } from '@/common/constants/taskTypes';
import { ACTION_TYPE_OPTIONS } from '@/revealComponents/FullContactFlowEditor/FullContactFlowActionEditor/helpers';
import useClientTemplates from '@/graphql/hooks/templates/useClientTemplates';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import {
  getTemplatesOptions,
  getUserOptions,
} from '../../../../optionsHelpers';

const TASK_TYPE_OPTIONS = _.reject(
  ACTION_TYPE_OPTIONS,
  ({ type }) => type === TASK_TYPES.SEND_EMAIL,
);

const useCreateManualTasksFormState = ({
  clientId,
  jobId,
  user,
  users,
  usersLoading,
  candidates,
  onClose = null,
}) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [createManualTasks] = useMutation(CREATE_MANUAL_TASKS);
  const { permissions } = useClientPermissions(clientId) || {};
  const [taskTypeOptions, setTaskTypeOptions] = useState(TASK_TYPE_OPTIONS);
  const [selectedOwnerEmail, setSelectedOwnerEmail] = useState(user?.email);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [taskType, setTaskType] = useState(TASK_TYPES.MANUAL);
  const [taskDescription, setTaskDescription] = useState('');
  const [newTemplateOpen, setNewTemplateOpen] = useState(false);
  const [templateToEdit, setTemplateToEdit] = useState();
  const [dueWhen, setDueWhen] = useState('due-now');
  const [dueDate, setDueDate] = useState(new Date());

  const clientTemplates = useClientTemplates(clientId);
  const cachedTemplates = clientTemplates?.data?.client?.templates;
  const templates = _.filter(cachedTemplates, ({ isArchived }) => !isArchived);

  const notifications = useNotificationSystem();

  const onAddItem = (e, { value }) => {
    setTaskTypeOptions((options) => [
      {
        text: value,
        value,
        type: 'custom-task',
      },
      ...options,
    ]);
  };

  const onSubmit = async () => {
    try {
      setSubmitting(true);

      const profileIds = _.pluck(candidates, 'id');
      const selectedTaskTypeOption = _.findWhere(taskTypeOptions, {
        value: taskType,
      });
      const selectedUser = _.findWhere(users, { email: selectedOwnerEmail });
      const owner = _.pick(selectedUser, ['firstname', 'lastname', 'email']);

      const input = {
        profileIds,
        title: selectedTaskTypeOption?.text,
        type: selectedTaskTypeOption?.type,
        owner,
        dueDate: dueDate.toISOString(),
        ...(jobId && { missionId: jobId }),
        ...(taskDescription && { description: { default: taskDescription } }),
        ...(selectedTemplateId && {
          message: {
            templateId: selectedTemplateId,
          },
        }),
      };

      const refetchQueries = jobId ? ['getTasksCount'] : undefined;

      await createManualTasks({ variables: { input }, refetchQueries });

      notifications.success(t('reveal.candidatesView.tasksCreated'));
      reset();
    } catch (e) {
      Sentry.captureException(e);
      notifications.error(
        t('reveal.candidatesView.timeline.explicitTasks.createError'),
      );
    }

    setSubmitting(false);
    if (onClose) {
      onClose();
    }
  };

  const reset = () => {
    setSubmitting(false);
    setTaskTypeOptions(TASK_TYPE_OPTIONS);
    setSelectedOwnerEmail(user?.email);
    setSelectedTemplateId(null);
    setTaskType(TASK_TYPES.MANUAL);
    setTaskDescription('');
    setNewTemplateOpen(false);
    setTemplateToEdit(null);
    setDueWhen('due-now');
    setDueDate(new Date());
  };

  const setDueDateWithTime = (value) => {
    const dateWithFixedTime = value;
    dateWithFixedTime.setHours(8, 0, 0);
    // console.log('setDueDateWrapper', typeof dateWithFixedTime, dateWithFixedTime);
    setDueDate(dateWithFixedTime);
  };

  const formState = {
    taskType,
    setTaskType,
    taskDescription,
    setTaskDescription,
    selectedOwnerEmail,
    setSelectedOwnerEmail,
    dueDate,
    setDueDate: setDueDateWithTime,
    dueWhen,
    setDueWhen,
    selectedTemplateId,
    setSelectedTemplateId,
    newTemplateOpen,
    setNewTemplateOpen,
    templateToEdit,
    setTemplateToEdit,
    onAddItem,
    onSubmit,
    submitting,
    disabled: false, // entire form can be disabled if candidate selection is invalid
    submissionDisabled:
      !taskType ||
      submitting ||
      !selectedOwnerEmail ||
      (taskType === TASK_TYPES.LINKEDIN_SEND_MESSAGE && !selectedTemplateId),
    reset,
  };

  const formOptions = {
    taskTypes: _.filter(
      TASK_TYPE_OPTIONS,
      (actionOption) => permissions.whatsappSequenceActions || permissions.unipile || !actionOption.value.includes('whatsapp')
    ),
    users: getUserOptions(users),
    templates: getTemplatesOptions({
      templates,
      onEditTemplate: ({ template }) => setTemplateToEdit(template),
      onCreateNewTemplate: () => setNewTemplateOpen(true),
    }),
    loading: usersLoading || clientTemplates?.loading,
  };

  // NOTE: make a state too, so they can be updated (e.g when click 'Add project' or 'New template')
  const [formOptionsState, setFormOptionsState] = useState(formOptions);

  useEffect(() => {
    if (!formOptions.loading) {
      setFormOptionsState(formOptions);
    }

    // eslint-disable-next-line
  }, [formOptions?.loading]);

  useEffect(() => {
    setFormOptionsState(formOptions);
    // using templates causes infinite loop because the array changes on every _.filter call

    // eslint-disable-next-line
  }, [cachedTemplates]);

  return {
    formState,
    formOptions: {
      ...formOptionsState,
      setFormOptions: setFormOptionsState,
    },
  };
};

export default useCreateManualTasksFormState;
