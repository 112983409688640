export const HAS_ANY_KEYWORDS = 'hasAnyKeywords';
export const HAS_KEYWORDS = 'hasKeywords';
export const HAS_NONE_OF_KEYWORDS = 'hasNoneOfKeywords';

export const KEYWORD_EMPLACEMENT_ANYWERE = 'anywhere';
export const KEYWORD_EMPLACEMENT_HEADLINE = 'headline';
export const KEYWORD_EMPLACEMENT_ALL_HEADLINE_EXPERIENCES =
  'all_headline_experiences';

export const KEYWORDS_PARAM_IDS = [
  HAS_ANY_KEYWORDS,
  HAS_KEYWORDS,
  HAS_NONE_OF_KEYWORDS,
] as const;

export const KEYWORDS_EMPLACEMENT_IDS = [
  KEYWORD_EMPLACEMENT_ANYWERE,
  KEYWORD_EMPLACEMENT_HEADLINE,
  KEYWORD_EMPLACEMENT_ALL_HEADLINE_EXPERIENCES,
] as const;
