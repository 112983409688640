import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useTranslation } from 'react-i18next';
import { Button, Popup } from 'semantic-ui-react';
import { CrossIcon, DownIcon, UpIcon } from '@/assets/icons';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';

const TaskDimmer: React.FC = () => {
  const { t } = useTranslation();
  const {
    tasksCount,
    currentTaskIndex,
    previousTaskId,
    nextTaskId,
    goToNextTask,
    goToPreviousTask,
    onClose,
  } = useCandidateViewContext();

  const keyMap = {
    CLOSE_MODAL: 'esc',
    PREVIOUS_TASK: 'up',
    NEXT_TASK: 'down',
  };

  const handlers = {
    CLOSE_MODAL: () => {
      onClose();
    },
    PREVIOUS_TASK: (event: any) => {
      if (event) {
        // no scrolling with arrows
        event.preventDefault();
      }
      goToPreviousTask();
    },
    NEXT_TASK: (event: any) => {
      if (event) {
        // no scrolling with arrows
        event.preventDefault();
      }
      goToNextTask();
    },
  };

  const CloseButton = (
    <Button
      className='dimmer-button back-button'
      circular
      icon={<CrossIcon className='' />}
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
    />
  );

  const UpArrowButton = (
    <Button
      className='dimmer-button task-dimmer-button'
      disabled={!previousTaskId}
      circular
      icon={<UpIcon />}
      onClick={(e) => {
        e.stopPropagation();
        goToPreviousTask();
      }}
    />
  );

  const DownArrowButton = (
    <Button
      className='dimmer-button task-dimmer-button'
      disabled={!nextTaskId}
      circular
      icon={<DownIcon />}
      onClick={(e) => {
        e.stopPropagation();
        goToNextTask();
      }}
    />
  );

  const handleClick = (event: any) => {
    if (!event?.target) return;
    // if we want to close the dimmer
    if (
      event.target.classList?.contains('task-dimmer') ||
      event.target.classList?.contains('actions-container')
    ) {
      onClose();
    }
  };

  return (
    <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges>
      <div className='dimmer task-dimmer' onClick={handleClick}>
        <div className='actions-container'>
          <Popup
            trigger={CloseButton}
            size='tiny'
            position='bottom center'
            basic
            inverted
            flowing
            popperModifiers={[
              {
                // Fix a positioning problem
                name: 'preventOverflow',
                options: {
                  boundariesElement: 'offsetParent',
                },
              }
            ]}
          >
            {t('keymap.escToQuit')}
          </Popup>

          <div className='tasks-actions-buttons'>
            <Popup
              trigger={UpArrowButton}
              size='tiny'
              position='top center'
              basic
              inverted
              flowing
              popperModifiers={[
                {
                  // Fix a positioning problem
                  name: 'preventOverflow',
                  options: {
                    boundariesElement: 'offsetParent',
                  },
                }
              ]}
            >
              {t('keymap.upArrowNavigation')}
            </Popup>

            <span className='tasks-actions-title'>
              {' '}
              {t('reveal.tasks.taskAction.task')}{' '}
            </span>

            <div className='tasks-actions-count'>
              <span className='current-index'>{currentTaskIndex + 1}</span>
              <span className='total-count'>{tasksCount}</span>
            </div>

            <Popup
              trigger={DownArrowButton}
              size='tiny'
              position='bottom center'
              basic
              inverted
              flowing
              popperModifiers={[
                {
                  // Fix a positioning problem
                  name: 'preventOverflow',
                  options: {
                    boundariesElement: 'offsetParent',
                  },
                }
              ]}
            >
              {t('keymap.downArrowNavigation')}
            </Popup>
          </div>
        </div>
      </div>
    </GlobalHotKeys>
  );
};

export default TaskDimmer;
