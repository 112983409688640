import _ from 'underscore';
import useClientId from '@/hooks/router/useClientId';
import { HiresweetLibProvider, HiresweetTasks } from '@hiresweet/hiresweet-lib';
import React from 'react';



const PlaygroundSlashTasks = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <HiresweetLibProvider theme={theme} clientId={clientId}>
      <h1>Tâches (Slash)</h1>
      <HiresweetTasks />
    </HiresweetLibProvider>
  );
};


export default PlaygroundSlashTasks;
