import React, { FC, useMemo } from 'react';
import { Loader } from 'semantic-ui-react';
import _ from 'underscore';

import useClientId from '@/hooks/router/useClientId';
import useOffers from '@/graphql/hooks/offers/useOffers';
import useProfilesToFollowup from '@/graphql/hooks/profiles/useProfilesToFollowup';
import {
  useSearchPoolNewOfferCandidatesCount,
  useSearchPoolOfferCandidatesCount,
} from '@/graphql/hooks/searchPool/useSearchPoolCounts';
import { getFollowupCount } from '@/common/helpers/followup';
import {
  getEmptyStatesToDisplay,
  getPendingCountsAndTopOffers,
} from './helpers';
import FollowupEmptyState from './FollowupEmptyState';
import PendingMainEmptyState from './PendingMainEmptyState';
import PendingExtendedEmptyState from './PendingExtendedEmptyState';
import OthersPendingEmptyState from './OthersPendingEmptyState';
import AllCandidatesEmptyState from './AllCandidatesEmptyState';

import styles from './ProfileEmptyState.module.less';

interface ProfileEmptyStateProps {
  offerId: string;
  onChangeStep: (next: { newStepId: string; newSubStepId: string }) => void;
}

const ProfileEmptyState: FC<ProfileEmptyStateProps> = ({
  offerId,
  onChangeStep,
}) => {
  const clientId = useClientId();
  const { offers, loading: offersLoading } = useOffers({ clientId });
  const {
    profilesToFollowup,
    loading: followupLoading,
  } = useProfilesToFollowup({ clientId });
  const {
    offerCandidatesCount,
    loading: offerCandidatesCountLoading,
  } = useSearchPoolOfferCandidatesCount(offerId);
  const {
    newOfferCandidatesCount,
    loading: newOfferCandidatesCountLoading,
  } = useSearchPoolNewOfferCandidatesCount(offerId);

  const followUpCount = useMemo(
    () => getFollowupCount(offerId, profilesToFollowup),
    [profilesToFollowup, offerId],
  );

  const {
    pendingExtendedCount,
    pendingMainCount,
    othersPendingCount,
    topOffers,
  } = useMemo(() => getPendingCountsAndTopOffers(offerId, offers), [
    offerId,
    offers,
  ]);

  const displayedEmptyStates = useMemo(
    () =>
      getEmptyStatesToDisplay({
        pendingMain: pendingMainCount,
        pendingExtended: pendingExtendedCount,
        othersPending: othersPendingCount,
        allCandidates: offerCandidatesCount,
        followUp: followUpCount,
      }),
    [
      pendingMainCount,
      pendingExtendedCount,
      othersPendingCount,
      offerCandidatesCount,
      followUpCount,
    ],
  );

  if (
    offersLoading ||
    followupLoading ||
    offerCandidatesCountLoading ||
    newOfferCandidatesCountLoading
  ) {
    return <Loader inline='centered' active />;
  }

  return (
    <div className={styles.emptyStates}>
      {_.contains(displayedEmptyStates, 'pendingMain') && (
        <div className={styles.emptyState}>
          <PendingMainEmptyState
            offerId={offerId}
            count={pendingMainCount}
            isPrimary={_.indexOf(displayedEmptyStates, 'pendingMain') === 0}
            onChangeStep={onChangeStep}
          />
        </div>
      )}
      {_.contains(displayedEmptyStates, 'pendingExtended') && (
        <div className={styles.emptyState}>
          <PendingExtendedEmptyState
            offerId={offerId}
            count={pendingExtendedCount}
            isPrimary={_.indexOf(displayedEmptyStates, 'pendingExtended') === 0}
            onChangeStep={onChangeStep}
          />
        </div>
      )}
      {_.contains(displayedEmptyStates, 'othersPending') && (
        <div className={styles.emptyState}>
          <OthersPendingEmptyState
            isPrimary={_.indexOf(displayedEmptyStates, 'othersPending') === 0}
            offerIds={topOffers}
          />
        </div>
      )}
      {_.contains(displayedEmptyStates, 'allCandidates') && (
        <div className={styles.emptyState}>
          <AllCandidatesEmptyState
            count={offerCandidatesCount}
            newCount={newOfferCandidatesCount}
            isPrimary={_.indexOf(displayedEmptyStates, 'allCandidates') === 0}
            offerId={offerId}
          />
        </div>
      )}
      {_.contains(displayedEmptyStates, 'followUp') && (
        <div className={styles.emptyState}>
          <FollowupEmptyState
            count={followUpCount}
            isPrimary={_.indexOf(displayedEmptyStates, 'followUp') === 0}
            offerId={offerId}
          />
        </div>
      )}
    </div>
  );
};

export default ProfileEmptyState;
