import React, { useState } from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { CandidateViewProvider } from '@/context/CandidateView/useCandidateViewContext';
import RevealCandidateViewTimeLineItems from '@/containers/Profile/Contact/TimeLineItems/RevealCandidateViewTimeLineItems';
import RevealCandidateViewNewActivity from '@/routes/RevealView/SearchView/CandidatesListPane/CandidateView/RevealCandidateViewNewActivity';
import RevealCandidateViewNewMessage from '@/routes/RevealView/SearchView/CandidatesListPane/CandidateView/RevealCandidateViewNewMessage';
import RevealCandidateViewTasks from '@/routes/RevealView/SearchView/CandidatesListPane/CandidateView/RevealCandidateViewTasks';
import styles from './PluginProfileActivity.module.less';

interface PluginProfileActivityProps {
  clientId: string;
  searchPoolId: string;
  profile: any;
}

type ActivityType = 'new-activity' | 'new-message' | 'tasks' | '';

const PluginProfileActivity: React.FC<PluginProfileActivityProps> = ({
  clientId,
  profile,
  searchPoolId,
}) => {
  const { t } = useTranslation();
  const [selectedActivity, setSelectedActivity] = useState<ActivityType>('');

  return (
    <CandidateViewProvider profileId={profile.id} clientId={clientId}>
      {searchPoolId === 'reveal' && (
        <>
          <div className={styles.activitiesContainer}>
            <div className={styles.buttons}>
              <Button
                onClick={() => setSelectedActivity('new-activity')}
                className={classNames(
                  styles.activityButton,
                  selectedActivity === 'new-activity' && styles.active,
                )}
              >
                <i className='ri-add-box-line' />
                {t('profile.actionButtons.logActivity')}
              </Button>
              <Button
                onClick={() => setSelectedActivity('new-message')}
                className={classNames(
                  styles.activityButton,
                  selectedActivity === 'new-message' && styles.active,
                )}
              >
                <i className='ri-at-line' />
                {t('profile.actionButtons.message')}
              </Button>
              <Button
                onClick={() => setSelectedActivity('tasks')}
                className={classNames(
                  styles.activityButton,
                  selectedActivity === 'tasks' && styles.active,
                )}
              >
                <i className='ri-todo-line' />
                {t('profile.actionButtons.task')}
              </Button>
            </div>
          </div>
        </>
      )}

      {!_.isEmpty(selectedActivity) && <div className={styles.darkOverlay} />}

      {!_.isEmpty(selectedActivity) && (
        <div className={styles.modalWrapper}>
          <div className={styles.exitActivityModal}>
            <Button onClick={() => setSelectedActivity('')}>
              <i className='ri-close-fill ri-xl' />
            </Button>
          </div>

          {selectedActivity === 'new-activity' && (
            <RevealCandidateViewNewActivity
              clientId={clientId}
              setIsOpen={setSelectedActivity}
            />
          )}

          {selectedActivity === 'new-message' && (
            <RevealCandidateViewNewMessage
              clientId={clientId}
              profile={profile}
            />
          )}

          {selectedActivity === 'tasks' && (
            <RevealCandidateViewTasks
              clientId={clientId}
              setIsOpen={setSelectedActivity}
            />
          )}
        </div>
      )}

      <div className={styles.root}>
        <RevealCandidateViewTimeLineItems
          clientId={clientId}
          searchPoolId={searchPoolId}
          hideSourced
          loading={!profile}
          sequenceOwner={profile?.currentSequenceInfo?.author}
          watchProfile={profile}
        />
      </div>
    </CandidateViewProvider>
  );
};

export default PluginProfileActivity;
