/* eslint-disable no-restricted-syntax */
import _ from 'underscore';
import React from 'react';
import { Button } from 'semantic-ui-react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { TASK_TYPES } from '@/common/constants/taskTypes';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import { sanitizeTypename } from '@/common/utils/apollo';
import { getTranslatedText } from '@/common';
import { UnipileMessageActionExecution } from '@/types/action';

interface MarkTaskAsCompletedButtonProps {
  task: any;
  onExecute: (input: UnipileMessageActionExecution) => void;
}

const getCompletedButtonText = (taskType: string, t: TFunction): string => {
  switch (taskType) {
    case TASK_TYPES.LINKEDIN_SEND_MESSAGE:
      return t('task.messageSent');
    case TASK_TYPES.LINKEDIN_SEND_REQUEST:
      return t('task.inviteSent');
    case TASK_TYPES.SEND_TEXT_MESSAGE:
      return t('task.smsSent');
    case TASK_TYPES.MAKE_PHONE_CALL:
      return t('task.phoneCall');
    default:
      return t('task.taskDone');
  }
};

const MarkTaskAsCompletedButton: React.FC<MarkTaskAsCompletedButtonProps> = ({
  children,
  task,
  onExecute,
}) => {
  const { isMinimized } = useMinimizedView();
  const { t } = useTranslation();
  const { handleCompleteTask, completeTaskLoading } = useCandidateViewContext();

  const flags: any = {};

  for (const field of task?.form?.fields || []) {
    for (const eventRule of field.onEvents || []) {
      if (
        eventRule.rule?.type === 'on-selected-value' &&
        eventRule.effect?.type === 'set-form-flag-from-selected-value'
      ) {
        const value =
          field.value ||
          _.findWhere(field.options, { selected: true })?.id ||
          null;
        if (eventRule.effect.key) {
          flags[eventRule.effect.key] = value;
        }
      }
    }
  }

  const isDisabledFieldId: any = {};
  for (const field of task?.form?.fields || []) {
    if (field.condition?.type === 'form-flag') {
      const flagValue = flags[field.condition.key];
      if ((field.condition?.targetValues || []).includes(flagValue)) {
        if (field.condition.effect?.type === 'remove' && field.id) {
          isDisabledFieldId[field.id] = true;
        }
      }
    }
  }

  const requiredFields = _.filter(
    task?.form?.fields,
    (field) => field.required || field.id === 'profile-answered', // "impl iforga" for ad
  );

  const missingRequiredFields = _.filter(
    requiredFields,
    (requiredField) =>
      !isDisabledFieldId[requiredField.id] &&
      !requiredField.value &&
      !_.some(requiredField.options, ({ selected }) => !!selected),
  );

  return (
    <Button
      className='pending-action-button'
      id='mark-task-as-completed'
      positive
      disabled={!_.isEmpty(missingRequiredFields) || completeTaskLoading}
      onClick={async () => {
        if (
          onExecute &&
          _.includes(
            [
              TASK_TYPES.LINKEDIN_SEND_MESSAGE,
              TASK_TYPES.LINKEDIN_SEND_REQUEST,
            ],
            task.type,
          )
        ) {
          await onExecute({ isConnected: false, chatId: '', senderId: '' });
        }
        handleCompleteTask(sanitizeTypename(task));
      }}
    >
      <i className='ri-check-line' />
      {!isMinimized &&
        (children ||
          getTranslatedText(task.completionButtonDisplay?.title) ||
          getCompletedButtonText(task.type, t))}
    </Button>
  );
};

export default MarkTaskAsCompletedButton;
