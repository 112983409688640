import { graphql } from '@apollo/client/react/hoc';
import { GET_USER_SETTINGS } from '@/graphql/users';

export default graphql(GET_USER_SETTINGS, {
  options: () => ({
    variables: {},
  }),
  props: ({ data: { loading, user, error } }) => ({
    loading,
    loadingUserSettings: loading,
    user,
    error,
  }),
});
