import React from 'react';
import _ from 'underscore';
import useJobProject from '../../../../../graphql/hooks/searchPoolJobs/useJobProject';
import { TabId } from '../../../revealComponents/ProjectTypesTabs';
import TodosContent from '../../../TodosView/TodosContent';

interface ApplicationReviewsTabProps {
  jobId: string;
  clientId: string;
}

const ApplicationReviewsTab: React.FC<ApplicationReviewsTabProps> = ({ jobId, clientId }) => {
  const { data: project, loading } = useJobProject('reveal', jobId, 'applications');
  if (loading || !project) {
    return null;
  }
  const tasks = _.flatten(
    _.map(project?.queues, (queue) => {
      return _.map(queue?.tasks, (task) => ({ ...task, projectId: project.id, projectTitle: project.title }));
    }),
  );
  return <TodosContent todosTabId={TabId.APPLICATIONS} tasks={tasks} selectedTodos={[project]} clientId={clientId} loading={false} />;
};

export default ApplicationReviewsTab;
