/* eslint-disable max-len */

import React from 'react';

import styles from './svg.module.less';

const Projects: React.FC = () => (
  <svg
    className={styles.svgIcon}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 2H7C6.44772 2 6 2.44772 6 3V4H10V3C10 2.44772 9.55228 2 9 2ZM5 3V4H4.31496C2.83909 4 1.63151 5.05963 1.36808 6.43208C1.33737 6.5921 1.40289 6.75355 1.52934 6.85632C2.61335 7.73731 3.99788 8.40722 5.58756 8.74857C5.82503 8.79956 6 9.00609 6 9.24897V10C6 10.5523 6.44772 11 7 11H9C9.55228 11 10 10.5523 10 10V9.24897C10 9.00609 10.175 8.79956 10.4124 8.74857C12.0021 8.40723 13.3866 7.73731 14.4707 6.85633C14.5971 6.75356 14.6626 6.59211 14.6319 6.43209C14.3685 5.05963 13.1609 4 11.685 4H11V3C11 1.89543 10.1046 1 9 1H7C5.89543 1 5 1.89543 5 3Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.02254 10.0229C4.9958 9.79524 4.84381 9.59571 4.62349 9.5325C3.79062 9.29353 3.00889 8.96866 2.29499 8.57125C1.92407 8.36477 1.44179 8.64638 1.47693 9.06944L1.8199 13.199C1.92214 14.2214 2.78247 15 3.80997 15H12.19C13.2175 15 14.0779 14.2214 14.1801 13.199L14.5231 9.06945C14.5582 8.64638 14.0759 8.36477 13.705 8.57125C12.9911 8.96867 12.2094 9.29353 11.3765 9.5325C11.1562 9.59572 11.0042 9.79524 10.9775 10.0229C10.8537 11.0766 10.2173 12 9 12H7C5.78273 12 5.14631 11.0766 5.02254 10.0229Z'
      fill='currentColor'
    />
  </svg>
);

export default Projects;
