import React from 'react';
import { TFunction } from 'i18next';
import { useRouteMatch } from 'react-router-dom';

import Tasks from '@/components/Reveal/Icons/Tasks';
import Projects from '@/components/Reveal/Icons/Projects';
import Contacts from '@/components/Reveal/Icons/Contacts';
import Automations from '@/components/Reveal/Icons/Automations';
import Reports from '@/components/Reveal/Icons/Reports';
import Counter from '@/components/Common/Counter/Counter';
import { useGetTasksCount } from '@/graphql/tasks';
import useUserFromJWToken from '@/graphql/hooks/users/useUserFromJWToken';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import Marketing from '@/components/Reveal/Icons/Marketing';
import { isAutomationTab } from '@/routes/RevealView/AutomationsView/types';
import { isMarketingTab } from '@/routes/RevealView/CampaignsView/types';
import BoardIcon from '@/components/Reveal/Icons/BoardIcon';
import { MissionCategoryType } from '@/types/missionCategory';
import Deal from '@/components/Reveal/Icons/Deal';
import Company from '@/components/Common/Icons/Company';
import Inbox from '@/components/Reveal/Icons/Inbox';

import { MenuItem } from './Menu/MenuItem';
import Menu from './Menu';

import styles from './NavReveal.module.less';

export interface NavRevealProps {
  clientId: string;
  pageId: string;
  tabId: string;
  t: TFunction;
}

const NavReveal: React.FC<NavRevealProps> = ({
  clientId,
  pageId,
  t,
  tabId,
}) => {
  const { data: userData } = useUserFromJWToken();
  const matchProjectPage = useRouteMatch<{
    missionCategory: MissionCategoryType;
  }>({
    path: '/client/:clientId/reveal/projects/:missionCategory',
  });
  const missionCategory = matchProjectPage?.params.missionCategory;
  const taskCount = useGetTasksCount({
    clientId,
    filters: {
      ownerEmails: userData?.user?.email ? [userData.user.email] : null,
    },
  });

  const clientPermissionsResult = useClientPermissions(clientId);
  const permissions = clientPermissionsResult?.data?.client?.permissions;
  if (!clientId) {
    return null;
  }
  const kanbanOverview = permissions?.crmOverview;
  const { canHandleCompanyContacts, salesProjects, inbox } = permissions || {};

  const menuItems: MenuItem[] = [
    {
      key: 'projects',
      text: salesProjects
        ? t('header.parameters.hiringProjects')
        : t('header.parameters.projects'),
      pageId: 'reveal-jobs',
      to: `/client/${clientId}/reveal/projects/recruiting`,
      active:
        pageId === 'reveal' &&
        tabId === 'projects' &&
        missionCategory === 'recruiting',
      icon: <Projects />,
    },
    ...(salesProjects
      ? [
          {
            key: 'salesProjects',
            text: t('header.parameters.salesProjects'),
            pageId: 'reveal-sales-projects',
            to: `/client/${clientId}/reveal/projects/sales`,
            active:
              pageId === 'reveal' &&
              tabId === 'projects' &&
              missionCategory === 'sales',
            icon: <Deal />,
          },
        ]
      : []),
    ...(kanbanOverview
      ? [
          {
            key: 'overview',
            text: <>{t('header.parameters.overview')}</>,
            pageId: 'overview',
            to: `/client/${clientId}/reveal/overview`,
            active: pageId === 'reveal' && tabId === 'overview',
            icon: <BoardIcon />,
          },
        ]
      : []),
    {
      key: 'todos',
      text: (
        <>
          <span className={styles.title}>{t('header.parameters.todos')}</span>
          <Counter
            value={taskCount || 0}
            active={pageId === 'reveal' && tabId === 'tasks'}
          />
        </>
      ),
      to: `/client/${clientId}/reveal/tasks`,
      pageId: 'reveal-todos',
      active: pageId === 'reveal' && tabId === 'tasks',
      icon: <Tasks />,
    },
    ...(inbox
      ? [
          {
            key: 'inbox',
            text: (
              <>
                <span className={styles.title}>
                  {t('header.parameters.inbox')}
                </span>
              </>
            ),
            to: `/client/${clientId}/reveal/inbox`,
            pageId: 'reveal-inbox',
            active: pageId === 'reveal' && tabId === 'inbox',
            icon: <Inbox />,
          },
        ]
      : []),
    {
      key: 'contacts',
      text: t('header.parameters.contacts'),
      pageId: 'reveal-search',
      to: `/client/${clientId}/reveal/search`,
      active: pageId === 'reveal' && tabId === 'search',
      icon: <Contacts />,
    },
    ...(canHandleCompanyContacts
      ? [
          {
            key: 'companies',
            text: t('header.parameters.companies'),
            pageId: 'reveal-companies',
            to: `/client/${clientId}/reveal/companies`,
            active: pageId === 'reveal' && tabId === 'companies',
            icon: <Company />,
          },
        ]
      : []),
    {
      key: 'automations',
      text: t('header.parameters.automations', { count: 2 }),
      pageId: 'automations',
      to: `/client/${clientId}/reveal/sequences`,
      active: pageId === 'reveal' && isAutomationTab(tabId),
      icon: <Automations />,
    },
  ];

  if (permissions?.crmPages) {
    menuItems.push({
      key: 'campaigns',
      text: t('header.parameters.marketing', { count: 2 }),
      pageId: 'campaigns',
      to: `/client/${clientId}/reveal/pages`,
      active: pageId === 'reveal' && isMarketingTab(tabId),
      icon: <Marketing />,
    });
  }

  menuItems.push({
    key: 'analytics',
    text: t('header.parameters.analytics'),
    pageId: 'analytics',
    to: `/client/${clientId}/reveal/analytics`,
    active: pageId === 'reveal' && tabId === 'analytics',
    icon: <Reports />,
  });

  return <Menu clientId={clientId} items={menuItems} />;
};

export default NavReveal;
