import { isFemaleName } from '../../common/firstnames';

export const getOfferActivityDescription = ({ activity, t }) => {
  switch (activity?.subtype) {
    case 'new-offer':
      return t('dashboard.activity.offerCreated');
    case 'edit_criteria':
      return t('dashboard.activity.criteriaUpdated');
    case 'enable-offer-autopilot':
      return t('dashboard.activity.autopilotEnabled');
    case 'disable-offer-autopilot':
      return t('dashboard.activity.autopilotDisabled');
    case 'archive-offer':
      return t('dashboard.activity.offerArchived');
    case 'unarchive-offer':
      return t('dashboard.activity.offerRestored');
    case 'reactivate-offer-sourcing':
      return t('dashboard.activity.sourcingEnabled');
    case 'stop-offer-sourcing':
      return t('dashboard.activity.sourcingPaused');
    case 'update-offer-push-flow':
      return t('dashboard.activity.pushFlowIncreased', {
        count: activity?.pushFlowPolicy?.nbPerDay || 10,
      });
    default:
      return '';
  }
};

export const getOfferActivityIconClass = ({ activity }) => {
  switch (activity?.subtype) {
    case 'new-offer':
      return 'ri-add-circle-line'; // or 'ri-file-add-line'; ?
    case 'edit_criteria':
      return 'ri-equalizer-line';
    case 'enable-offer-autopilot':
      return 'ri-send-plane-fill';
    case 'disable-offer-autopilot':
      return 'ri-send-plane-line';
    case 'archive-offer':
      return 'ri-stop-circle-line';
    case 'unarchive-offer':
      return 'ri-play-circle-line';
    case 'stop-offer-sourcing':
      return 'ri-stop-circle-line';
    case 'reactivate-offer-sourcing':
      return 'ri-play-circle-line';
    case 'update-offer-push-flow':
      return 'ri-settings-5-line';
    default:
      return 'ri-settings-5-line';
  }
};

export const getProfilesActivityDescription = ({ activity, t }) => {
  switch (activity?.subtype) {
    case 'send':
      return t('dashboard.activity.profilesContacted', {
        count: activity?.totalProfilesCount,
      });
    default:
      return '';
  }
};

export const getProfileActivityDescription = ({ activity, t }) => {
  const profile = activity?.profile;
  const isFemale =
    profile?.resumeData?.firstname &&
    isFemaleName(profile?.resumeData?.firstname);
  switch (activity?.subtype) {
    case 'send':
      return t('dashboard.activity.profileContacted', {
        context: isFemale ? 'female' : '',
      });
    case 'positive-answer-detected':
      return t('dashboard.activity.positiveAnswer');
    case 'move-to-process':
      return t('dashboard.activity.profileMovedToProcess', {
        context: isFemale ? 'female' : '',
      });
    case 'move-to-hired':
      return t('dashboard.activity.profileHired', {
        context: isFemale ? 'female' : '',
      });
    default:
      return '';
  }
};

export const getProfileActivityIconClass = ({ activity }) => {
  switch (activity?.subtype) {
    case 'send':
      return 'ri-send-plane-fill';
    case 'positive-answer-detected':
      return 'ri-mail-check-line';
    case 'move-to-process':
      return 'ri-user-shared-2-line';
    case 'move-to-hired':
      return 'ri-star-smile-line';
    default:
      return 'ri-user-smile-line';
  }
};

export const getTasksActivityDescription = ({ activity, t }) => {
  const { totalTasksCount = 1, subtype, taskType } = activity;
  const description = t(
    `reveal.dashboardView.activity.${subtype}.${taskType}`,
    { count: totalTasksCount, fallback: '' },
  );
  const defaultDescription = t(
    `reveal.dashboardView.activity.${subtype}.default`,
    { count: totalTasksCount, fallback: '' },
  );
  return description || defaultDescription;
};

export const getTasksActivityIconClass = ({ activity }) => {
  const { subtype, taskType } = activity;
  const icons = {
    'reveal-task-created': {
      'send-email': 'ri-mail-line',
      'linkedin-send-inmail': 'ri-mail-line',
      'linkedin-check-request': 'ri-linkedin-box-line',
      'linkedin-send-request': 'ri-linkedin-box-line',
      recommendation: 'ri-star-line',
      'application-review': 'ri-user-search-line',
      manual: 'ri-todo-line',
    },
    'reveal-task-completed': {
      'send-email': 'ri-mail-send-line',
      'linkedin-send-inmail': 'ri-mail-send-line',
      'linkedin-check-request': 'ri-linkedin-box-line',
      'linkedin-send-request': 'ri-linkedin-box-line',
      recommendation: 'ri-star-line',
      'application-review': 'ri-user-follow-line',
      manual: 'ri-check-line',
    },
  };
  return icons[subtype]?.[taskType] ?? 'ri-todo-line';
};

export const getProjectActivityDescription = ({ activity, t }) => {
  const { subtype } = activity;

  switch (subtype) {
    case 'reveal-project-created':
      return t('reveal.dashboardView.activity.reveal-project-created');
    case 'reveal-project-archived':
      return t('reveal.dashboardView.activity.reveal-project-archived');
    case 'reveal-project-edited':
      return t('reveal.dashboardView.activity.reveal-project-edited');
    default:
      return '';
  }
};

export const getProjectActivityIconClass = ({ activity }) => {
  const { subtype } = activity;
  if (subtype === 'reveal-project-created') {
    return 'ri-add-box-line';
  }
  if (subtype === 'reveal-project-archived') {
    return 'ri-checkbox-line';
  }
  if (subtype === 'reveal-project-edited') {
    return 'ri-settings-4-line';
  }
  return `TODO ${subtype}`;
};

export const getProjectTypeFromActivity = ({ activity }) => {
  const { taskType } = activity;
  switch (taskType) {
    case 'application-review':
      return 'applications';
    case 'recommendation':
      return 'recommendations';

    default:
      return 'tasks';
  }
};

export const getDisplayedDate = ({ dateString, t }) => {
  const todaysDate = new Date().toDateString();
  if (dateString === todaysDate) {
    return t('dashboard.activity.today');
  }
  const yesterdaysDate = new Date(Date.now() - 24 * 3600 * 1000).toDateString();
  if (dateString === yesterdaysDate) {
    return t('dashboard.activity.yesterday');
  }
  const date = new Date(dateString);
  if (date.getFullYear() !== new Date().getFullYear()) {
    return t('dashboard.activity.dayMonthYear', { date });
  }
  return t('dashboard.activity.dayAndMonth', { date });
};
