import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const mutation = gql`
  mutation updateClientTrackingSettings(
    $trackingSettingsInput: TrackingSettingsInput!
  ) {
    updateClientTrackingSettings(
      trackingSettingsInput: $trackingSettingsInput
    ) {
      id
      settings {
        clickAndOpenTracking {
          trackOnEmailOpen
          trackOnLinkClick
        }
      }
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    updateClientTrackingSettings: ({ trackingSettingsInput }) =>
      mutate({
        variables: {
          trackingSettingsInput,
        },
      }),
  }),
});
