import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import Counter from '@/components/Common/Counter/Counter';
import GenericTextInput from '@/components/Common/GenericTextInput';
import Search from '@/components/Reveal/Icons/Search';
import useDebounceValue from '@/hooks/common/useDebounceValue';
import ProfileTableParamsContext from '@/context/ProfileTableParamsContext';
import useRevealSearchPoolResults from '@/graphql/hooks/searchPool/useRevealSearchPoolResults';
import ProfilesViewSettingsContextProvider from '@/context/ProfilesViewSettingsContext/ProfilesViewSettingsContextProvider';
import {
  getCriteriaFromSettings,
  ProfilesViewSettings,
} from '@/context/ProfilesViewSettingsContext/helpers';
import ProfilesViewSettingsContext from '@/context/ProfilesViewSettingsContext';
import CompanyDealStateFilter from '@/components/ProfilesViewSettings/CompanyDealStateFilter';

import CandidatesSearchResults from '../../SearchView/CandidatesListPane/CandidatesSearchResults';
import AccordionFilter from '../../SearchView/SearchPane/components/AccordionFilter';
import CompaniesCustomFieldFilters from './CompaniesCustomFieldFilters';

import styles from './CompaniesContactsView.module.less';

const searchVariablesBase = {
  categories: [
    {
      categoryId: 'all',
      withCount: true,
      withResults: true,
    },
  ],
  queryOptions: {
    fetchPolicy: 'network-only' as const,
  },
};

const profileTableParams = {
  disabledActions: {
    'add-to-project': true,
    'import-candidates': true,
    'open-in-linkedin': true,
    'open-in-ats': true,
    'remove-from-sequence': true,
    'reapply-sequence': true,
    'add-to-sequence': true,
    'add-to-campaign': true,
    'create-tasks': true,
    'remove-from-mission': true,
    'bulk-enrich': true,
    'hold-in-campaign': true,
    'set-to-scheduled-in-campaign': true,
    'remove-from-campaign': true,
  },
};

const Inner = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [profilesViewSettings] = useContext(ProfilesViewSettingsContext);

  const searchesCriteria = useMemo(
    () => [getCriteriaFromSettings(profilesViewSettings)],
    [profilesViewSettings],
  );

  // Use value debouncer to avoid multiple search to be thrown
  // at the same time
  const debouncedSearchText = useDebounceValue(searchText, 250);

  const searchVariables = useMemo(
    () => ({
      ...searchVariablesBase,
      searchesCriteria,
      searchText: debouncedSearchText.trim(),
    }),
    [debouncedSearchText, searchesCriteria],
  );

  const {
    searchResults,
    refetch: refetchSearchResults,
    loading,
  } = useRevealSearchPoolResults(searchVariables);
  const category = _.findWhere(searchResults.categories, { categoryId: 'all' });
  const { results: searchResultItems = [], count: resultCount = 0 } =
    category || {};

  return (
    <>
      <div className={styles.sidebar}>
        <h3 className={styles.title}>
          {t('common.search')}
          <Counter value={resultCount} active={resultCount > 0} />
        </h3>
        <GenericTextInput
          value={searchText}
          onValue={setSearchText}
          placeholder={t('header.search.searchCompanies')}
          icon={<Search />}
          className={styles.searchInput}
        />
        <div className={styles.filters}>
          <AccordionFilter
            title={t('reveal.searchView.filters.dealState.label')}
            count={profilesViewSettings.companyDealState ? 1 : 0}
          >
            <CompanyDealStateFilter />
          </AccordionFilter>
          <CompaniesCustomFieldFilters />
        </div>
      </div>
      <div className={styles.main}>
        <ProfileTableParamsContext.Provider value={profileTableParams}>
          <CandidatesSearchResults
            searchPoolId='reveal'
            tableId='search-companies-table-view'
            searches={searchVariables.searchesCriteria}
            loadingResults={loading}
            searchResultItems={searchResultItems}
            refetchSearchResults={refetchSearchResults}
            contactCategory='company'
          />
        </ProfileTableParamsContext.Provider>
      </div>
    </>
  );
};

const CompaniesContactsView = () => {
  return (
    <ProfilesViewSettingsContextProvider
      initialSettings={initialProfilesViewSettings}
    >
      <Inner />
    </ProfilesViewSettingsContextProvider>
  );
};

const initialProfilesViewSettings: ProfilesViewSettings = {
  contactCategory: { type: 'company' },
  atsTagFilters: {},
};

export default CompaniesContactsView;
