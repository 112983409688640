import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { MiniMission } from '@/types/mission';
import { SearchPoolJobQueryVariables } from '@/graphql/searchPoolJobs';

const getMiniMissions = gql`
  query getMiniMissions(
    $searchPoolId: ID!
    $filters: SearchPoolJobFiltersInput
  ) {
    searchPool(id: $searchPoolId) {
      id
      jobs(filters: $filters) {
        id
        isArchived
        data {
          title
        }
      }
    }
  }
`;

type Data = {
  searchPool: {
    id: string;
    jobs: MiniMission[];
  };
};

type Variables = {
  searchPoolId: string;
  filters: SearchPoolJobQueryVariables['filters'];
};

type Result = QueryResult<Data, Variables> & {
  miniMissions: MiniMission[];
};

type UseMiniMissionsInput = {
  searchPoolId?: string;
  filters?: Variables['filters'];
  queryOptions?: QueryHookOptions<Data, Variables>;
};

function useMiniMissions({
  searchPoolId = 'reveal',
  filters,
  queryOptions = {},
}: UseMiniMissionsInput = {}): Result {
  const query = useQuery<Data, Variables>(getMiniMissions, {
    variables: {
      searchPoolId,
      filters,
    },
    ...queryOptions,
  });

  const miniMissions = query.data?.searchPool?.jobs || [];

  return { ...query, miniMissions };
}

export default useMiniMissions;
