import {
  MutationHookOptions,
  FetchResult,
  MutationResult,
  useMutation,
  gql,
} from '@apollo/client';

import ContactFlow from '@/graphql/fragments/ContactFlow';
import ProfileSequenceInfoWithSequence from '@/graphql/fragments/ProfileSequenceInfoWithSequence';
import ProfileMissionInfoWithMission from '@/graphql/fragments/ProfileMissionInfoWithMission';
import TimelineItem from '@/graphql/fragments/TimelineItem';
import { Profile } from '@/types/profile';
import { InterruptionReason } from '@/common';

const REMOVE_PROFILES_FROM_SEQUENCE = gql`
  mutation removeProfilesFromSequence(
    $searchPoolId: ID!
    $input: RemoveProfilesFromSequenceInput!
  ) {
    searchPool(id: $searchPoolId) {
      removeProfilesFromSequence(input: $input) {
        results {
          profileId
          success
          error
          profile {
            id
            currentSequenceInfo {
              ...ProfileSequenceInfoWithSequence
            }
            contactFlow {
              ...ContactFlow
            }
            missionsInfo {
              ...ProfileMissionInfoWithMission
            }
            contactData {
              timeline {
                ...TimelineItem
              }
            }
          }
        }
      }
    }
  }
  ${ContactFlow}
  ${ProfileSequenceInfoWithSequence}
  ${ProfileMissionInfoWithMission}
  ${TimelineItem}
`;

type RemoveProfilesFromSequenceData = {
  searchPool: {
    id: string;
    removeProfilesFromSequence: {
      results: {
        profileId: string;
        success: boolean;
        error?: string;
        profile?: Profile;
      }[];
    };
  };
};

type RemoveProfilesFromSequenceVariables = {
  searchPoolId: string;
  input: {
    profileIds: string[];
    sequenceId?: string;
    interruptionReason?: InterruptionReason;
  };
};

type UseRemoveProfilesFromSequenceInput = {
  mutationOptions?: MutationHookOptions<
    RemoveProfilesFromSequenceData,
    RemoveProfilesFromSequenceVariables
  >;
  searchPoolId?: string;
};

type UseRemoveProfilesFromSequenceResult = [
  (
    input: RemoveProfilesFromSequenceVariables['input'],
  ) => Promise<FetchResult<RemoveProfilesFromSequenceData>>,
  MutationResult<RemoveProfilesFromSequenceData>,
];

const useRemoveProfilesFromSequence = ({
  mutationOptions = {},
  searchPoolId = 'reveal',
}: UseRemoveProfilesFromSequenceInput = {}): UseRemoveProfilesFromSequenceResult => {
  const [mutation, result] = useMutation<
    RemoveProfilesFromSequenceData,
    RemoveProfilesFromSequenceVariables
  >(REMOVE_PROFILES_FROM_SEQUENCE, {
    refetchQueries: ['getSearchPoolJob', 'getSearchPoolJobProfileResults'],
    ...mutationOptions,
  });

  return [(input) => mutation({ variables: { searchPoolId, input } }), result];
};

export default useRemoveProfilesFromSequence;
