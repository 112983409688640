import gql from 'graphql-tag';
import TranslatableText from '@/graphql/fragments/TranslatableText';

const GET_CLIENT_ATS_FILTERS_OPTIONS = gql`
  query getRevealFiltersOptions($clientId: ID!) {
    client(id: $clientId) {
      id
      revealProjects {
        id
        connectors {
          id
          type
          filterOptions {
            stageNames {
              id
              name
            }
            rejectionReasons {
              id
              name
              rejectionType {
                id
                name
              }
            }
            users {
              id
              firstname
              lastname
            }
            tags {
              id
              name {
                ...TranslatableText
              }
            }
            jobs {
              id
              name
              stageNames {
                id
                name
              }
              locationName
              isArchived
            }
          }
        }
      }
      users {
        firstname
        lastname
        email
      }
      bulkImports {
        id
        name
      }
    }
  }
  ${TranslatableText}
`;

export default GET_CLIENT_ATS_FILTERS_OPTIONS;
