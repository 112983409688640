/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useState } from 'react';
import { Dropdown, Form, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import i18n from '@/i18n';

import {
  CurrentExperienceDurationVariable,
  DurationSinceGraduationVariable,
  DurationVariableFormat,
  VariableExamplePreviewLocale,
} from '@/common/mergeTags/utils';
import GenericModal from '@/components/Common/GenericModal';

import GenericButton from '@/components/Common/GenericButton';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import styles from '../SmartVariableWithPreviewModal.module.less';
import DurationVariableExamples from './DurationVariableExamples';

const formatOptions = Object.values(DurationVariableFormat);

type DurationVariable =
  | (SweetEvaluatorTypes.BaseVariable & DurationSinceGraduationVariable)
  | (SweetEvaluatorTypes.BaseVariable & CurrentExperienceDurationVariable);

type Props = {
  open: boolean;
  exampleVariable: DurationVariable;
  onClose: () => void;
  onSubmit: (exampleVariable: DurationVariable) => void;
  isEditMode?: boolean;
};

const DurationVariableModal: React.FC<Props> = ({
  open,
  exampleVariable,
  onClose,
  onSubmit,
  isEditMode = false,
}) => {
  const { t } = useTranslation();

  const [locale, setLocale] = useState<VariableExamplePreviewLocale>(
    (i18n.resolvedLanguage as VariableExamplePreviewLocale) || 'en',
  );

  const [format, setFormat] = useState<
    CurrentExperienceDurationVariable['format']
  >(exampleVariable.format || DurationVariableFormat.SMART);

  const localeOptions = useMemo(() => {
    return [
      {
        key: 'en',
        text: t('editor.variableExemplePreview.locales.en'),
        value: 'en',
      },
      {
        key: 'fr',
        text: t('editor.variableExemplePreview.locales.fr'),
        value: 'fr',
      },
    ];
  }, [t]);

  const handleSubmit = () => {
    onSubmit({
      ...exampleVariable,
      locale,
      format,
    });
    onClose();
  };

  return (
    <GenericModal size='large' open={open} onClose={onClose}>
      <Modal.Header>{t('editor.variableExemplePreview.title')}</Modal.Header>
      <Modal.Content className={styles.content}>
        <Form className={styles.form}>
          <div className={styles.left}>
            <div className={styles.padding}>
              <Form.Field>
                <label>{t('editor.variableExemplePreview.language')}</label>
                <Dropdown
                  value={locale}
                  onChange={(e, { value }) =>
                    setLocale(value as VariableExamplePreviewLocale)
                  }
                  className={styles.input}
                  fluid
                  selection
                  options={localeOptions}
                />
              </Form.Field>
              <Form.Field className={styles.marginTop}>
                <label>Format</label>
                <div className={styles.formatOptions}>
                  {_.map(formatOptions, (option) => (
                    <button
                      type='button'
                      key={option}
                      className={classNames(
                        styles.customButton,
                        option === format && styles.buttonActive,
                      )}
                      onClick={() => setFormat(option)}
                    >
                      {t(`editor.durationVariable.format.${option}`)}
                    </button>
                  ))}
                </div>
              </Form.Field>
              <div className={styles.infoAlert}>
                {t(`editor.durationVariable.formatInfo.${format}`)}
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.padding}>
              <div className={styles.examples}>
                <h3 className={styles.examplesTitle}>
                  {t('editor.variableExemplePreview.preview')}
                </h3>
                <DurationVariableExamples
                  format={format}
                  locale={locale}
                  variable={exampleVariable}
                />
              </div>
            </div>
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <GenericButton
          type='button'
          primacy='secondary'
          size='big'
          onClick={onClose}
        >
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          size='big'
          primacy='primary'
          type='submit'
          onClick={handleSubmit}
        >
          {isEditMode ? t('common.edit') : t('common.create')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default DurationVariableModal;
