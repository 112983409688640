import { BaseQueryOptions, useQuery } from '@apollo/client';

import { GET_CLIENT_DAILY_EVENTS } from '@/graphql/statistics';
import { ClientDailyEvent } from '@/types/statistics/clientStatistics';

interface GetClientDailyEventsData {
  client: {
    id: string;
    statistics: {
      sequences: {
        dailyEvents: ClientDailyEvent[];
      };
    };
  };
}

interface GetClientDailyEventsVariables {
  clientId: string;
  sequenceIds?: string[];
  userEmails?: string[];
}

interface UseClientDailyEventsResult {
  dailyEvents?: ClientDailyEvent[];
  loading: boolean;
}

interface UseClientDailyEventsInput extends GetClientDailyEventsVariables {
  queryOptions?: BaseQueryOptions<GetClientDailyEventsVariables>;
}

const useClientDailyEvents = ({
  clientId,
  sequenceIds,
  userEmails,
  queryOptions = {},
}: UseClientDailyEventsInput): UseClientDailyEventsResult => {
  const { data, loading } = useQuery<
    GetClientDailyEventsData,
    GetClientDailyEventsVariables
  >(GET_CLIENT_DAILY_EVENTS, {
    ...queryOptions,
    variables: { clientId, sequenceIds, userEmails },
  });

  const { dailyEvents } = data?.client?.statistics?.sequences || {};
  return { dailyEvents, loading };
};

export default useClientDailyEvents;
