/* eslint-disable max-len */

import React, { FC } from 'react';

interface NewJobIllustrationProps {
  className?: string;
}

const NewJobIllustration: FC<NewJobIllustrationProps> = ({ className }) => (
  <svg
    className={className}
    viewBox='0 0 84 84'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='84' height='84' rx='8' fill='#F1F6FF' />
    <path
      d='M77 8L8 77'
      stroke='#4864C9'
      strokeLinecap='round'
      strokeDasharray='3 5'
    />
    <path
      d='M42.25 4.75V80.75'
      stroke='#4864C9'
      strokeLinecap='round'
      strokeDasharray='3 5'
    />
    <path
      d='M80.25 42.75L4.25001 42.75'
      stroke='#4864C9'
      strokeLinecap='round'
      strokeDasharray='3 5'
    />
    <path
      d='M76.9509 58.2369L7.54907 27.2631'
      stroke='#4864C9'
      strokeLinecap='round'
      strokeDasharray='3 5'
    />
    <path
      d='M76.4949 26.2792L8.00512 59.2208'
      stroke='#4864C9'
      strokeLinecap='round'
      strokeDasharray='3 5'
    />
    <path
      d='M56.7525 7.62625L27.7475 77.8737'
      stroke='#4864C9'
      strokeLinecap='round'
      strokeDasharray='3 5'
    />
    <path
      d='M27.569 7.70051L56.9311 77.7995'
      stroke='#4864C9'
      strokeLinecap='round'
      strokeDasharray='3 5'
    />
    <path
      d='M8 8L77 77'
      stroke='#4864C9'
      strokeLinecap='round'
      strokeDasharray='3 5'
    />
    <circle cx='42' cy='42' r='27' fill='#F1F6FF' />
    <rect x='40' width='44' height='84' fill='url(#paint0_linear_9_2443)' />
    <g filter='url(#filter0_d_9_2443)'>
      <rect x='22' y='22' width='40' height='40' rx='20' fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M43.5 33H40.5C39.6716 33 39 33.6716 39 34.5V36H45V34.5C45 33.6716 44.3285 33 43.5 33ZM37.5 34.5V36H36.4725C34.2587 36 32.4473 37.5894 32.0522 39.6481C32.0061 39.8882 32.1044 40.1303 32.2941 40.2845C33.9201 41.606 35.9969 42.6108 38.3814 43.1228C38.7376 43.1993 39 43.5091 39 43.8735V45C39 45.8284 39.6716 46.5 40.5 46.5H43.5C44.3285 46.5 45 45.8284 45 45V43.8735C45 43.5091 45.2625 43.1993 45.6187 43.1229C48.0032 42.6108 50.08 41.606 51.706 40.2845C51.8957 40.1303 51.994 39.8882 51.9479 39.6481C51.5528 37.5895 49.7414 36 47.5276 36H46.5V34.5C46.5 32.8431 45.1569 31.5 43.5 31.5H40.5C38.8432 31.5 37.5 32.8431 37.5 34.5Z'
        fill='#4864C9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.5338 45.0343C37.4937 44.6929 37.2658 44.3936 36.9353 44.2987C35.686 43.9403 34.5134 43.453 33.4425 42.8569C32.8861 42.5472 32.1627 42.9696 32.2154 43.6042L32.7299 49.7985C32.8833 51.3321 34.1738 52.5 35.715 52.5H48.2851C49.8263 52.5 51.1168 51.3321 51.2702 49.7985L51.7846 43.6042C51.8374 42.9696 51.1139 42.5472 50.5576 42.8569C49.4867 43.453 48.3141 43.9403 47.0648 44.2987C46.7343 44.3936 46.5064 44.6929 46.4662 45.0343C46.2806 46.615 45.3259 48 43.5 48H40.5C38.6741 48 37.7195 46.615 37.5338 45.0343Z'
        fill='#4864C9'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_9_2443'
        x='18'
        y='20'
        width='48'
        height='48'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='2' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.121569 0 0 0 0 0.180392 0 0 0 0 0.466667 0 0 0 0.06 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_9_2443'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_9_2443'
          result='shape'
        />
      </filter>
      <linearGradient
        id='paint0_linear_9_2443'
        x1='84'
        y1='35.5'
        x2='40.0042'
        y2='36.2468'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
    </defs>
  </svg>
);

export default NewJobIllustration;
