import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import {
  Container,
  Dropdown,
  Input,
  Loader,
  TextArea,
} from 'semantic-ui-react';
import { useMutation } from '@apollo/client';

import { getRandomString } from '@/common';
import { isEmailAction, TASK_TYPES } from '@/common/constants/taskTypes';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import useSequences from '@/hooks/common/useSequences';
import './SequencePage.css';
import contextToProps from '@/hocs/contextToProps';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { useLockHistory } from '@/context/LockHistoryContext';
import GenericButton from '@/components/Common/GenericButton';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import checkContactFlowSnippets from '@/common/contactFlow/checkContactFlowSnippets';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import { sanitizeTypename } from '@/common/utils/apollo';
import SequenceErrorMessages from '../SequenceErrorMessages';
import CancelButton from '../SequencesSubHeader/CancelButton';
import validateContactFlow from '../validateContactFlow';
import getContactFlowInput from '../../../../common/contactFlow/getContactFlowInput';
import {
  CLIENT_SEQUENCES,
  CREATE_SEQUENCE,
  useClientSequenceTemplates,
} from '../queries';
import FullContactFlowEditor from '../../../../routes/RevealView/revealComponents/FullContactFlowEditor';
import SequencesSubHeader from '../SequencesSubHeader/SequencesSubHeader';
import SequenceLayout from '../SequenceLayout';
import validateSequenceSendersAndSubjects from '../utils/validation';

const DEFAULT_SEQUENCES = [
  {
    id: `contactflow-sequence-${getRandomString(6)}`,
    position: 'first-contact',
    actions: [
      {
        actionId: `contact-flow-action-${getRandomString(6)}`,
        type: TASK_TYPES.SEND_EMAIL_MANUAL,
        trigger: { type: 'manual-trigger' },
      },
    ],
  },
];

const SequenceHeaderMoreActions = ({ actions }) => {
  if (!actions?.length) {
    return null;
  }

  return (
    <Dropdown
      className='sequence-subheader-more-actions'
      icon={<i className='ri-more-fill ri-xl' />}
      basic
      floating
      direction='left'
    >
      <Dropdown.Menu>
        {actions.map((action) => (
          <Dropdown.Item
            onClick={action.onClick}
            text={action.label}
            key={action.id}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const CreateSequencePage = ({ clientId }) => {
  const history = useHistory();
  const notification = useNotificationSystem();
  const { t } = useTranslation();
  const { permissions } = useClientPermissions(clientId);
  const { user } = useCurrentUser();

  const [generationLoading, setGenerationLoading] = useState(false);
  const [showAIGenerationPanel, setShowAIGenerationPanel] = useState(false);
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();
  const URLQueryParams = new URLSearchParams(useLocation().search);
  const sequenceTemplateId = URLQueryParams.get('sequenceTemplateId');
  const showAIGenerationPanelParam = URLQueryParams.get('withAIGeneration');

  const { lockHistory, unlockHistory } = useLockHistory();

  const { loading, sequenceTemplates } = useClientSequenceTemplates({
    clientId,
  });
  const sequenceTemplate = _.findWhere(
    sequenceTemplates?.defaultSequenceTemplates,
    { id: sequenceTemplateId },
  );

  useEffect(() => {
    if (showAIGenerationPanelParam) {
      setSequenceTitle(
        sequenceTemplate?.title ||
          `${new Date().toLocaleDateString()} ${t('sequences.aiSequence')} ${t(
            'common.by',
          ).toLowerCase()} ${user.firstname}`,
      );
    }
    setShowAIGenerationPanel(showAIGenerationPanelParam);
    // eslint-disable-next-line
  }, [showAIGenerationPanelParam]);

  const updateAfterMutation = (
    cache,
    { data: { sequence: sequenceMutationData } },
  ) => {
    const queries = [
      { query: CLIENT_SEQUENCES, variables: { clientId } },
      {
        query: CLIENT_SEQUENCES,
        variables: { clientId, filters: { activeOnly: true } },
      },
    ];

    _.each(queries, (query) => {
      const data = cache.readQuery(query);

      if (!data) {
        // it is possible that we arrive at the sequences page
        // without having requested sequences first
        return;
      }

      const newData = {
        client: {
          ...data.client,
          sequences: [
            ...(data?.client?.sequences || []),
            sequenceMutationData?.create?.sequence,
          ],
        },
      };
      cache.writeQuery({
        ...query,
        data: newData,
      });
    });
  };

  // MUTATION update sequence
  const [
    createSequence,
    { data: createSequenceData, loading: creatingSequence },
  ] = useMutation(CREATE_SEQUENCE, {
    update: updateAfterMutation,
  });

  // FORM PROPS
  const [sequenceTitle, setSequenceTitle] = useState(
    t('sequences.titlePlaceholder'),
  );
  const [sequenceDescription, setSequenceDescription] = useState();

  const [sequences, setSequences] = useState(DEFAULT_SEQUENCES);
  const contactFlow = { sequences };

  useEffect(() => {
    if (!_.isEmpty(sequenceTemplate)) {
      setSequenceTitle(
        sequenceTemplate?.title || t('sequences.titlePlaceholder'),
      );
      setSequenceDescription(sequenceTemplate?.description);
      setSequences(
        sequenceTemplate?.contactFlow?.sequences || DEFAULT_SEQUENCES,
      );
    }
    // eslint-disable-next-line
  }, [sequenceTemplate]);

  // FORM ERRORS
  const [errorLevel, setErrorLevel] = useState('major');
  const formErrors = validateContactFlow({ contactFlow });
  const displayedErrors =
    errorLevel === 'major'
      ? _.filter(formErrors, ({ level }) => level === 'major')
      : formErrors;

  const {
    handleInsertAction,
    handleUpdateAction,
    handleRemoveAction,
    handleMoveUpwardAction,
    handleMoveDownwardAction,
    updateAfterTemplateCreation,
  } = useSequences({
    setSequences: (sequencesMutation) => {
      lockHistory(t('sequences.sequenceEditor.lockHistory'));
      setSequences(sequencesMutation);
    },
    formErrors,
    setErrorLevel,
    clientId,
  });

  // SUBMIT
  const onSubmit = async () => {
    if (!_.isEmpty(formErrors)) {
      setErrorLevel('minor');
      return;
    }

    // Validate that actions with new sender have a subject
    // otherwise throw an error.
    // TODO: add this to validateContactflow function
    validateSequenceSendersAndSubjects(contactFlow.sequences[0]);

    const missingSnippets = checkContactFlowSnippets({ contactFlow });
    if (!_.isEmpty(missingSnippets)) {
      notification.error(
        t('sequences.sequenceMissingSnippets', {
          count: missingSnippets.length,
        }),
      );
      throw new Error('invalid snippet in sequence');
    }

    const contactFlowInput = getContactFlowInput({
      contactFlow,
    });

    await createSequence({
      variables: {
        input: {
          title: sequenceTitle,
          description: sequenceDescription,
          contactFlow: sanitizeTypename(contactFlowInput),
        },
      },
    });

    publishSubscriptionEvent('onSequencesListUpdated', {});
  };

  useEffect(() => {
    if (createSequenceData) {
      const newSequenceId = createSequenceData.sequence.create.sequence.id;
      history.push(`/client/${clientId}/reveal/sequences/${newSequenceId}`);
    }

    // eslint-disable-next-line
  }, [createSequenceData]);

  const breadcrumbs = [
    {
      text: t('sequences.breadcrumbs.sequences'),
      link: `/client/${clientId}/reveal/sequences`,
    },
    {
      text: t('sequences.breadcrumbs.newSequence'),
      link: `/client/${clientId}/reveal/sequences/new-sequence-preview`,
    },
    {
      text: t('sequences.breadcrumbs.createSequence'),
      link: `/client/${clientId}/reveal/sequences/new-sequence-create`,
    },
  ];

  const onValidate = {
    text: t('sequences.buttons.create'),
    clickHandler: async () => {
      unlockHistory();
      try {
        await onSubmit();
        notification.success(t('sequences.new.sequenceCreated'));
      } catch (e) {
        notification.error(t('sequences.new.sequenceCreateError'));
        console.error(e);
      }
    },
  };

  const applyCcBccToNextMails = ({ cc, bcc, index }) => {
    const sequence = _.first(contactFlow.sequences);
    if (!sequence) {
      return;
    }
    const newActions = _.map(sequence.actions || [], (action, actionIndex) => {
      if (!isEmailAction(action) || actionIndex <= index) {
        return action;
      }
      return {
        ...action,
        message: {
          ...action.message,
          ...{
            cc,
            bcc,
          },
        },
      };
    });
    const newSequence = [
      {
        ...sequence,
        actions: newActions,
      },
    ];
    setSequences(newSequence);
  };

  const updateGeneratedActions = (newActions) => {
    const sequence = _.first(contactFlow.sequences);
    if (!sequence) {
      return;
    }
    const newSequence = [
      {
        ...sequence,
        actions: newActions,
      },
    ];
    setSequences(newSequence);
  };

  const extraActions = useMemo(() => {
    const actions = [];
    if (permissions.aiSequenceGenerationSidebar) {
      actions.push({
        id: 'generate-ai-sequence',
        onClick: () => setShowAIGenerationPanel((show) => !show),
        label: showAIGenerationPanel
          ? t('sequences.generation.subHeader.hideAIPanel')
          : t('sequences.generation.subHeader.showAIPanel'),
      });
    }

    return actions;
  }, [permissions, showAIGenerationPanel, t]);

  return (
    <SequenceLayout
      withAiGenerationPanel={showAIGenerationPanel}
      updateGeneratedActions={updateGeneratedActions}
      hasSidePanel
      pollInterval={500}
      isStandalone
      subHeader={
        <SequencesSubHeader
          breadcrumbs={breadcrumbs}
          errorMessage={<SequenceErrorMessages errors={displayedErrors} />}
        >
          <SequenceHeaderMoreActions actions={extraActions} />
          <CancelButton />
          <GenericButton
            size='big'
            disabled={
              !_.isEmpty(displayedErrors) ||
              creatingSequence ||
              generationLoading
            }
            onClick={onValidate.clickHandler}
          >
            {onValidate.text}
          </GenericButton>
        </SequencesSubHeader>
      }
      onLoading={setGenerationLoading}
    >
      {loading ? (
        <Loader active />
      ) : (
        <Container className='new-sequence-edit'>
          <h3 className='input-container'>
            <Input
              className='sequence-title-input'
              fluid
              placeholder={t('sequences.title')}
              value={sequenceTitle}
              onChange={(ev) => setSequenceTitle(ev.target.value)}
            />

            {(clientId === 'sequences-reveal' ||
              clientId === 'sequences-reveal-fr') && (
              <TextArea
                className='sequence-description-input'
                placeholder='Description (optional)'
                value={sequenceDescription}
                onChange={(e, { value }) => setSequenceDescription(value)}
                rows={6}
              />
            )}
          </h3>

          <FullContactFlowEditor
            clientId={clientId}
            contactFlow={contactFlow}
            t={t}
            onInsertAction={handleInsertAction}
            onUpdateAction={handleUpdateAction}
            onRemoveAction={handleRemoveAction}
            onMoveUpward={handleMoveUpwardAction}
            onMoveDownward={handleMoveDownwardAction}
            onQuitContactFlowEditor={onSubmit}
            updateAfterTemplateCreation={updateAfterTemplateCreation}
            formErrors={displayedErrors}
            applyCcBccToNextMails={applyCcBccToNextMails}
            isGenerationLoading={generationLoading}
          />
        </Container>
      )}
    </SequenceLayout>
  );
};

export default _.compose(contextToProps)(CreateSequencePage);
