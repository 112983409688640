import _ from 'underscore';

// TODO: KEEP TRANSLATIONS UPDATED (search for 'send-email-action')
// TODO: maintain up-to-date with dropdown options (at creation time)

import { getTranslatedText } from '..';

const PENDING = 'pending';
const IN_PROGRESS = 'in-progress';
const COMPLETED = 'completed';

export const SEQUENCE_STATES = {
  PENDING,
  IN_PROGRESS,
  COMPLETED,
};

export const SEND_EMAIL = 'send-email-action'; // deprecated: 'send-email'
const SEND_EMAIL_MANUAL = 'send-email-manual';
const CUSTOM = 'custom';
const MANUAL = 'manual';
const LINKEDIN_SEND_INMAIL = 'linkedin-send-inmail';
const LINKEDIN_SEND_MESSAGE = 'linkedin-send-message';
const LINKEDIN_RECRUITER_INMAIL = 'linkedin-recruiter-inmail';
const LINKEDIN_PREMIUM_INMAIL = 'linkedin-premium-inmail';
const LINKEDIN_SALESNAVIGATOR_INMAIL = 'linkedin-salesnavigator-inmail';
const LINKEDIN_SEND_REQUEST = 'linkedin-send-request';
const LINKEDIN_CHECK_REQUEST = 'linkedin-check-request';
const ADD_EVALUATION = 'add-evaluation';
const SEND_TEXT_MESSAGE = 'send-text-message';
const REVIEW_PROFILE = 'review-profile';
const MAKE_PHONE_CALL = 'make-phone-call';
const WHATSAPP_SEND_MESSAGE = 'whatsapp-send-message';

const EMAIL_TYPES = [SEND_EMAIL, SEND_EMAIL_MANUAL];

// NOTE: 'send-email' is deprecated
export const isEmailAction = (action) =>
  _.contains(EMAIL_TYPES, action?.type) || action?.type === 'send-email';

export const isLinkedinTaskType = (type) =>
  (type || '').startsWith('linkedin-');

export const isSMSAction = (type) => type === SEND_TEXT_MESSAGE;
export const isPhoneCallAction = (type) => type === MAKE_PHONE_CALL;

export const isLinkedinOrEmailAction = (action) => {
  return isEmailAction(action) || isLinkedinTaskType(action?.type);
};

export const isManualEmailTask = (type) => type === SEND_EMAIL_MANUAL;

export const TASK_TYPES = {
  MANUAL,
  LINKEDIN_SEND_INMAIL,
  LINKEDIN_RECRUITER_INMAIL,
  LINKEDIN_PREMIUM_INMAIL,
  LINKEDIN_SALESNAVIGATOR_INMAIL,
  LINKEDIN_SEND_MESSAGE,
  LINKEDIN_SEND_REQUEST,
  LINKEDIN_CHECK_REQUEST,
  SEND_EMAIL,
  SEND_EMAIL_MANUAL,
  ADD_EVALUATION,
  SEND_TEXT_MESSAGE,
  REVIEW_PROFILE,
  MAKE_PHONE_CALL,
  CUSTOM,
  WHATSAPP_SEND_MESSAGE,
};

export const UNIPILE_TASK_TYPES = [
  TASK_TYPES.LINKEDIN_SEND_MESSAGE,
  TASK_TYPES.LINKEDIN_SEND_REQUEST,
  TASK_TYPES.WHATSAPP_SEND_MESSAGE,
  TASK_TYPES.LINKEDIN_CHECK_REQUEST,
];

export const LINKEDIN_MESSAGE_SUBTYPES = {
  CLASSIC: 'linkedin-classic-message',
  RECRUITER: 'linkedin-recruiter-inmail',
  PREMIUM: 'linkedin-premium-inmail',
  SALESNAVIGATOR: 'linkedin-salesnavigator-inmail',
};

export const TASK_TITLE_BY_TYPE = {
  [SEND_EMAIL]: 'Send email',
  [SEND_EMAIL_MANUAL]: 'Send email',
  [MAKE_PHONE_CALL]: 'Call',
  [SEND_TEXT_MESSAGE]: 'Send text message',
  [LINKEDIN_SEND_REQUEST]: 'Send LinkedIn invitation',
  [LINKEDIN_SEND_MESSAGE]: 'Send LinkedIn message',
  [LINKEDIN_RECRUITER_INMAIL]: 'Send LinkedIn Recruiter InMail',
  [LINKEDIN_PREMIUM_INMAIL]: 'Send LinkedIn Business Premium InMail',
  [LINKEDIN_SALESNAVIGATOR_INMAIL]: 'Send LinkedIn Sales Navigator InMail',
  [LINKEDIN_CHECK_REQUEST]: 'Check LinkedIn invitation status',
  [LINKEDIN_SEND_INMAIL]: 'Send InMail',
  [REVIEW_PROFILE]: 'Review profile',
  [ADD_EVALUATION]: 'Add evaluation',
  [CUSTOM]: 'Custom task',
  [MANUAL]: 'Manual task',
  [WHATSAPP_SEND_MESSAGE]: 'Send Whatsapp message',
  [undefined]: 'Other task',
};

export const getTaskType = (task) => {
  if (
    task?.type === TASK_TYPES.LINKEDIN_SEND_MESSAGE &&
    task?.subtype === LINKEDIN_MESSAGE_SUBTYPES.RECRUITER
  ) {
    return TASK_TYPES.LINKEDIN_RECRUITER_INMAIL;
  }
  if (
    task?.type === TASK_TYPES.LINKEDIN_SEND_MESSAGE &&
    task?.subtype === LINKEDIN_MESSAGE_SUBTYPES.SALESNAVIGATOR
  ) {
    return TASK_TYPES.LINKEDIN_SALESNAVIGATOR_INMAIL;
  }
  if (
    task?.type === TASK_TYPES.LINKEDIN_SEND_MESSAGE &&
    task?.subtype === LINKEDIN_MESSAGE_SUBTYPES.PREMIUM
  ) {
    return TASK_TYPES.LINKEDIN_PREMIUM_INMAIL;
  }
  return task?.type;
};

export const getTaskTypeAndSubtype = ({ taskType }) => {
  if (taskType === TASK_TYPES.LINKEDIN_RECRUITER_INMAIL) {
    return {
      type: TASK_TYPES.LINKEDIN_SEND_MESSAGE,
      subtype: TASK_TYPES.LINKEDIN_RECRUITER_INMAIL,
    };
  }
  if (taskType === TASK_TYPES.LINKEDIN_PREMIUM_INMAIL) {
    return {
      type: TASK_TYPES.LINKEDIN_SEND_MESSAGE,
      subtype: TASK_TYPES.LINKEDIN_PREMIUM_INMAIL,
    };
  }
  if (taskType === TASK_TYPES.LINKEDIN_SALESNAVIGATOR_INMAIL) {
    return {
      type: TASK_TYPES.LINKEDIN_SEND_MESSAGE,
      subtype: TASK_TYPES.LINKEDIN_SALESNAVIGATOR_INMAIL,
    };
  }
  return {
    type: taskType,
    subtype: null,
  };
};

export const getTaskTitle = ({ task }) => {
  return (
    getTranslatedText(task?.title) ||
    TASK_TITLE_BY_TYPE[getTaskType(task)] ||
    TASK_TITLE_BY_TYPE[undefined]
  );
};

export const getActionType = (action) => {
  if (
    action?.type === TASK_TYPES.LINKEDIN_SEND_MESSAGE &&
    action?.subtype === LINKEDIN_MESSAGE_SUBTYPES.RECRUITER
  ) {
    return TASK_TYPES.LINKEDIN_RECRUITER_INMAIL;
  }
  if (
    action?.type === TASK_TYPES.LINKEDIN_SEND_MESSAGE &&
    action?.subtype === LINKEDIN_MESSAGE_SUBTYPES.PREMIUM
  ) {
    return TASK_TYPES.LINKEDIN_PREMIUM_INMAIL;
  }
  if (
    action?.type === TASK_TYPES.LINKEDIN_SEND_MESSAGE &&
    action?.subtype === LINKEDIN_MESSAGE_SUBTYPES.SALESNAVIGATOR
  ) {
    return TASK_TYPES.LINKEDIN_SALESNAVIGATOR_INMAIL;
  }
  return action?.type;
};

export const getActionTitle = ({ action }) => {
  const actionType = getActionType(action);
  return (
    getTranslatedText(action?.title || actionType) ||
    TASK_TITLE_BY_TYPE[actionType] ||
    TASK_TITLE_BY_TYPE[undefined]
  );
};

// icons are located in /public/images/icons/tasks
export const TASK_ICON_BY_TYPE = {
  [SEND_EMAIL]: 'email-task',
  [SEND_EMAIL_MANUAL]: 'email-task',
  [MAKE_PHONE_CALL]: 'call-task',
  [SEND_TEXT_MESSAGE]: 'sms-task',
  [LINKEDIN_SEND_REQUEST]: 'linkedin-task',
  [LINKEDIN_SEND_MESSAGE]: 'linkedin-task',
  [LINKEDIN_RECRUITER_INMAIL]: 'linkedin-recruiter-task',
  [LINKEDIN_PREMIUM_INMAIL]: 'linkedin-task',
  [LINKEDIN_SALESNAVIGATOR_INMAIL]: 'linkedin-salesnavigator-task',
  [LINKEDIN_CHECK_REQUEST]: 'linkedin-task',
  [LINKEDIN_SEND_INMAIL]: 'linkedin-task',
  [REVIEW_PROFILE]: 'manual-task',
  [ADD_EVALUATION]: 'manual-task',
  [MANUAL]: 'manual-task',
  [CUSTOM]: 'manual-task',
  [WHATSAPP_SEND_MESSAGE]: 'whatsapp-message',
  [undefined]: 'manual-task',
  [null]: 'manual-task',
};

export const getTaskIconName = (action) => {
  const type = getActionType(action);
  return TASK_ICON_BY_TYPE[type] || TASK_ICON_BY_TYPE[undefined];
};

export const ACTION_KEY_BY_TYPE = {
  [SEND_EMAIL]: 'sendEmailAction',
  [SEND_EMAIL_MANUAL]: 'sendEmailManualAction',
  [MAKE_PHONE_CALL]: 'makePhoneCallAction',
  [SEND_TEXT_MESSAGE]: 'sendTextMessageAction',
  [WHATSAPP_SEND_MESSAGE]: 'sendWhatsappMessageAction',
  [LINKEDIN_SEND_REQUEST]: 'linkedinSendRequestAction',
  [LINKEDIN_SEND_MESSAGE]: 'linkedinSendMessageAction',
  [LINKEDIN_RECRUITER_INMAIL]: 'linkedinSendMessageAction',
  [LINKEDIN_PREMIUM_INMAIL]: 'linkedinSendMessageAction',
  [LINKEDIN_SALESNAVIGATOR_INMAIL]: 'linkedinSendMessageAction',
  [LINKEDIN_CHECK_REQUEST]: 'linkedinCheckRequestAction',
  [LINKEDIN_SEND_INMAIL]: 'linkedinSendInMailAction',
  [REVIEW_PROFILE]: 'reviewProfileAction',
  [ADD_EVALUATION]: 'addEvaluationAction',
  [MANUAL]: 'manualAction',
  [CUSTOM]: 'customAction',
};

// accepted fields for different actions
// type & trigger are required
export const ACTION_FIELDS_BY_TYPE = {
  [SEND_EMAIL]: { type: true, trigger: true, message: true, subject: true },
  [SEND_EMAIL_MANUAL]: {
    type: true,
    trigger: true,
    message: true,
    subject: true,
  },
  [MAKE_PHONE_CALL]: { type: true, trigger: true, description: true },
  [SEND_TEXT_MESSAGE]: {
    type: true,
    trigger: true,
    description: true,
    message: true,
  },
  [WHATSAPP_SEND_MESSAGE]: {
    type: true,
    trigger: true,
    description: false,
    message: true,
  },
  [LINKEDIN_SEND_REQUEST]: { type: true, trigger: true, message: true },
  [LINKEDIN_SEND_MESSAGE]: { type: true, trigger: true, message: true },
  [LINKEDIN_RECRUITER_INMAIL]: {
    type: true,
    trigger: true,
    message: true,
    subject: true,
  },
  [LINKEDIN_PREMIUM_INMAIL]: {
    type: true,
    trigger: true,
    message: true,
    subject: true,
  },
  [LINKEDIN_SALESNAVIGATOR_INMAIL]: {
    type: true,
    trigger: true,
    message: true,
    subject: true,
  },
  [LINKEDIN_CHECK_REQUEST]: { type: true, trigger: true },
  [LINKEDIN_SEND_INMAIL]: {
    type: true,
    trigger: true,
    message: true,
    subject: true,
  },
  [REVIEW_PROFILE]: { type: true, trigger: true },
  [ADD_EVALUATION]: { type: true, trigger: true },
  [MANUAL]: { type: true, trigger: true, description: true, title: true },
  [CUSTOM]: { type: true, trigger: true, customActionId: true, message: true },
};

export const getActionFieldsForType = (type) => {
  if (type?.includes('custom-task')) {
    return ACTION_FIELDS_BY_TYPE.custom;
  }
  const result =
    ACTION_FIELDS_BY_TYPE[type] ||
    ACTION_FIELDS_BY_TYPE[ACTION_KEY_BY_TYPE[type]];

  if (!result) {
    throw new Error(`Missing action fields for task type ${type}`);
  }
  return result;
};

export const MANUAL_TRIGGER = 'manual-trigger';
export const DELAY_AFTER_ACTION = 'delay-after-action';
const AFTER_DATE = 'after-date';
export const OR_CONDITION_TYPE = '$or';
export const AND_CONDITION_TYPE = '$and';
export const LINKEDIN_INVITATION_ACCEPTED = 'linkedin-invitation-accepted';

export const TRIGGER_KEY_BY_TYPE = {
  [MANUAL_TRIGGER]: 'triggerManual',
  [DELAY_AFTER_ACTION]: 'triggerDelayAfterAction',
  [AFTER_DATE]: 'triggerAfterDate',
  [OR_CONDITION_TYPE]: 'triggerOr',
  [AND_CONDITION_TYPE]: 'triggerAnd',
  [LINKEDIN_INVITATION_ACCEPTED]: 'triggerSpecificCondition',
};

const NO_MARK_AS_COMPLETED_BUTTON_TYPES = [
  LINKEDIN_SEND_INMAIL,
  LINKEDIN_SEND_REQUEST,
  LINKEDIN_SEND_MESSAGE,
  LINKEDIN_RECRUITER_INMAIL,
  LINKEDIN_PREMIUM_INMAIL,
  LINKEDIN_SALESNAVIGATOR_INMAIL,
  LINKEDIN_CHECK_REQUEST,
  SEND_EMAIL,
  SEND_EMAIL_MANUAL,
];

export const hasMarkAsCompletedButton = (task) => {
  return !_.contains(NO_MARK_AS_COMPLETED_BUTTON_TYPES, task?.type);
};

const TYPES_WITH_HTML_MESSAGE = [
  TASK_TYPES.SEND_EMAIL,
  TASK_TYPES.SEND_EMAIL_MANUAL,
  TASK_TYPES.CUSTOM,
];
export const canHaveHtmlMessage = (action) =>
  _.contains(TYPES_WITH_HTML_MESSAGE, action?.type);

export const hasSkipButton = () => {
  return true;
};

export const INTERACTION_TYPES = [
  TASK_TYPES.LINKEDIN_SEND_INMAIL,
  TASK_TYPES.LINKEDIN_SEND_MESSAGE,
  TASK_TYPES.LINKEDIN_RECRUITER_INMAIL,
  TASK_TYPES.LINKEDIN_PREMIUM_INMAIL,
  TASK_TYPES.LINKEDIN_SALESNAVIGATOR_INMAIL,
  TASK_TYPES.LINKEDIN_SEND_REQUEST,
  TASK_TYPES.MAKE_PHONE_CALL,
  TASK_TYPES.SEND_EMAIL,
  TASK_TYPES.SEND_EMAIL_MANUAL,
  TASK_TYPES.SEND_TEXT_MESSAGE,
  TASK_TYPES.WHATSAPP_SEND_MESSAGE,
  'call',
  'email-from-profile',
  'email-to-profile',
  'linkedin-message-from-profile',
  'linkedin-message-to-profile',
  'linkedin-invitation-accepted-from-profile',
];
