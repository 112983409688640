import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import _ from 'underscore';
import {
  ConditionsChainingVariable,
  FieldCondition,
} from '@/common/mergeTags/utils';
import { getSweetEvaluator, SweetEvaluatorTypes } from '@/SweetEvaluator';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import GenericButton from '@/components/Common/GenericButton';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import ConditionsChainingPreviewFormField from '@/containers/Editor/VariableExamplesPreviewModal/ConditionsChaining/ConditionsChainingPreviewFormField';
import {
  getUpdatedPreviewContext,
  SimplifiedField,
} from '@/containers/Editor/VariableExamplesPreviewModal/ConditionsChaining/utils';
import useConditionsChainingCustomFields from '@/containers/Editor/VariableExamplesPreviewModal/ConditionsChaining/useConditionsChainingCustomFields';
import getExternalExpressionEvaluatorFromType from '@/common/mergeTags/externalEvaluators';
import {
  SEARCH_POOL_PROFILE_WITH_RESUME_DATA_ONLY,
  UPDATE_RESUME_DATA_IN_SEARCH_POOL,
} from '@/graphql/searchPoolProfile';
import {
  combineDataWithCustomFields,
  createCustomFieldsPayload,
} from '@/common/customFields';
import ConditionsChainingPreviewOutput from '@/containers/Editor/VariableExamplesPreviewModal/ConditionsChaining/ConditionsChainingPreviewOutput';
import styles from '../SequenceDynamicVariables.module.less';

interface Props {
  clientId: string;
  variable: SweetEvaluatorTypes.BaseVariable & ConditionsChainingVariable;
  afterSubmit?: () => void;
}

const SequenceSimplifiedConditionsChainingVariable: React.FC<Props> = ({
  clientId,
  variable,
  afterSubmit,
}) => {
  const isPlugin = useIsPlugin();
  const { t } = useTranslation();
  const {
    mergeTagsProfileContext,
    profileId,
    profile,
  } = useCandidateViewContext();
  const {
    profileCustomFieldsMap,
    missionCustomFieldsMap,
    profileCustomFields,
  } = useConditionsChainingCustomFields({ clientId });
  const [context, setContext] = useState<Record<string, any>>({});

  const [updateResumeDataInSearchPool] = useMutation(
    UPDATE_RESUME_DATA_IN_SEARCH_POOL,
    {
      refetchQueries: [
        {
          query: SEARCH_POOL_PROFILE_WITH_RESUME_DATA_ONLY,
          variables: { searchPoolId: 'reveal', profileId },
        },
      ],
    },
  );

  useEffect(() => {
    setContext(mergeTagsProfileContext);
  }, [mergeTagsProfileContext]);

  const fieldCondition = useMemo(() => {
    const firstStatement = variable.ifStatements[0];
    return firstStatement.condition as FieldCondition;
  }, [variable]);

  const simplifiedField = useMemo(() => {
    return {
      fieldCategory: fieldCondition.fieldCategory,
      fieldId:
        fieldCondition.fieldCategory === 'native'
          ? fieldCondition.fieldId
          : fieldCondition.customFieldId,
      fieldType: fieldCondition.fieldType,
    };
  }, [fieldCondition]);

  const handleChange = ({
    key,
    field,
    newValue,
  }: {
    key: string;
    field: SimplifiedField;
    newValue: string;
  }) => {
    setContext((previousState) =>
      getUpdatedPreviewContext({
        context: previousState,
        key,
        field,
        newValue,
        profileCustomFieldsMap,
        missionCustomFieldsMap,
      }),
    );
  };

  const formContent = useMemo(() => {
    return (
      <ConditionsChainingPreviewFormField
        clientId={clientId}
        context={context}
        field={simplifiedField}
        onChange={handleChange}
      />
    );
    // eslint-disable-next-line
  }, [context, clientId, fieldCondition]);

  const output = useMemo(() => {
    const sweetEvaluator = getSweetEvaluator({
      getExternalExpressionEvaluatorFromType,
    });
    const instantiatedSnippets = sweetEvaluator.getUpdatedVariables({
      variables: [(variable as unknown) as SweetEvaluatorTypes.Variable],
      context,
    });
    return instantiatedSnippets?.[0]?.state?.value || '';
  }, [context, variable]);

  const getFieldValueFromContext = () => {
    if (simplifiedField.fieldCategory === 'custom-field') {
      return context.customFields?.[simplifiedField.fieldId]?.value;
    }
    if (simplifiedField.fieldCategory === 'linked-mission-custom-field') {
      return context.missionCustomFields?.[simplifiedField.fieldId]?.value;
    }
    return _.isObject(context[simplifiedField.fieldId])
      ? context[simplifiedField.fieldId]?.value
      : context[simplifiedField.fieldId];
  };

  const handleSubmit = async () => {
    const updatedValue = getFieldValueFromContext();

    const dataPayload: Record<string, unknown> = {};
    if (simplifiedField.fieldCategory === 'native') {
      dataPayload[simplifiedField.fieldId] = updatedValue;
    }
    if (simplifiedField.fieldCategory === 'custom-field') {
      const customDetails = combineDataWithCustomFields(
        profileCustomFields,
        profile?.resumeData || {},
      );
      const customFieldsInitialValues: any = _.object(
        customDetails.map((o) => [o.clientCustomFieldId, o.rawValue]),
      );
      customFieldsInitialValues[simplifiedField.fieldId] = updatedValue;
      const customFieldsPayload = createCustomFieldsPayload(
        customFieldsInitialValues,
        profileCustomFields,
      );
      dataPayload.customFields = customFieldsPayload;
    }

    if (_.isEmpty(dataPayload)) {
      return;
    }

    await updateResumeDataInSearchPool({
      variables: {
        searchPoolId: 'reveal',
        input: {
          id: profileId,
          data: dataPayload,
        },
      },
    });
    if (afterSubmit) {
      afterSubmit();
    }
  };

  return (
    <div>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
        className={classNames(
          styles.sequenceLockPopup,
          isPlugin ? styles.sequenceLockPopupPlugin : '',
        )}
      >
        <span className={styles.title}>{variable.name}</span>

        {formContent}

        <div className={styles.preview}>
          <span className={styles.title}>
            {t(`editor.conditionsChainingVariable.preview.display`)}
          </span>
          <ConditionsChainingPreviewOutput
            output={output || t('reveal.mergeTags.noValue')}
          />
        </div>

        <div className={styles.buttons}>
          <GenericButton
            className={classNames(styles.button, styles.submitButton)}
            type='submit'
          >
            {t('common.save')}
          </GenericButton>
        </div>
      </form>
    </div>
  );
};

export default SequenceSimplifiedConditionsChainingVariable;
