import React, { useState } from 'react';
import { captureException } from '@sentry/browser';
import { withTranslation, Trans } from 'react-i18next';
import ContentEditable from 'react-contenteditable';
import _, { compose } from 'underscore';
import { Form, Checkbox, Input } from 'semantic-ui-react';

import GenericButton from '@/components/Common/GenericButton';
import withUpdateClientTrackingSettings from '../../../hocs/clients/withUpdateClientTrackingSettings';
import withUpdateUserSettings from '../../../hocs/users/withUpdateUserSettings';
import withUserSettings from '../../../hocs/users/withUserSettings';
import withClient from '../../../hocs/clients/withClient';

import SettingsLayout from '../../SettingsLayout';
import AdvancedMessagingModal from './AdvancedMessagingModal';
import GmailAliasPreview from './Senders/GmailAliasPreview';

const EmailSettingsForm = ({
  user,
  updateUser,
  client,
  updateClientTrackingSettings,
  t,
}) => {
  const { alias, signature, showConfirmationModal, isAdmin } = user || {};
  const { trackOnEmailOpen, trackOnLinkClick } =
    client?.settings?.clickAndOpenTracking || {};
  const [input, setInput] = useState({
    alias,
    signature,
    showConfirmationModal,
  });
  const [trackingSettingsInput, setTrackingSettingsInput] = useState({
    trackOnEmailOpen: !!trackOnEmailOpen,
    trackOnLinkClick: !!trackOnLinkClick,
  });
  const [submitDisabled, disableSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [justSaved, setJustSaved] = useState(false);

  const onChangeAlias = (ev, { value }) => {
    setInput((previousInput) => ({
      ...previousInput,
      alias: value,
    }));
  };

  const onChangeCheckbox = (ev, { name, checked }) => {
    if (name === 'trackOnEmailOpen' || name === 'trackOnLinkClick') {
      setTrackingSettingsInput((previousInput) => ({
        ...previousInput,
        [name]: checked,
      }));
    } else {
      setInput((previousInput) => ({
        ...previousInput,
        [name]: checked,
      }));
    }
  };

  const onChangeSignature = (ev) => {
    setInput((previousInput) => ({
      ...previousInput,
      signature: ev.target.value,
    }));
  };

  const onSubmit = () => {
    disableSubmit(true);
    updateUser(input)
      .then(() => {
        updateClientTrackingSettings({ trackingSettingsInput });
      })
      .then(() => {
        disableSubmit(false);
        setJustSaved(true);
        setTimeout(() => setJustSaved(false), 1000);
      })
      .catch((e) => {
        captureException(e);
        disableSubmit(false);
        setError(e.message);
        setTimeout(() => setError(null), 3000);
      });
  };

  return (
    <Form className='settings' onSubmit={onSubmit}>
      <div className='settings-header'>
        <h1>{t('settings.emailingSection.header')}</h1>
        <div className='settings-submit'>
          <div>
            <GenericButton type='submit' disabled={submitDisabled}>
              {t('settings.saveButton')}
            </GenericButton>
          </div>
          <div className='settings-submit-message'>
            {error ? <span className='error-message'>{error}</span> : null}
          </div>
          <div className='settings-submit-message'>
            {justSaved ? (
              <span className='just-saved'>
                {t('settings.notifications.savedWithSuccess')}
              </span>
            ) : null}
          </div>
        </div>
      </div>
      <h2>{t('settings.emailingSection.aliasAndSignature')}</h2>
      <div className='section visible'>
        <div className='inputs-row'>
          <div className='input-container half-width'>
            <div className='input-label'>
              <Trans i18nKey='translations:settings.emailingSection.sendAs'>
                Envoyer les emails en tant que
                <span className='no-emphasis'>(uniquement pour Gmail)</span> :
              </Trans>
            </div>
            <div className='input-element'>
              <Form.Field className='alias-field'>
                <Input
                  type='text'
                  name='alias'
                  value={input.alias || ''}
                  onChange={onChangeAlias}
                  placeholder={t('settings.emailingSection.sendAsPlaceholder')}
                />
              </Form.Field>
            </div>
          </div>
          <div className='input-container half-width'>
            <div className='input-label preview-label'>
              {t('settings.senders.editModal.aliasPreview')}
            </div>
            <GmailAliasPreview alias={input.alias} />
          </div>
        </div>

        <div className='input-container'>
          <div className='input-label'>
            {t('settings.emailingSection.signature')}
          </div>
          <div className='input-element'>
            <Form.Field className='signature-field'>
              <ContentEditable
                className='content-edition-field'
                html={input.signature || ''} // innerHTML of the editable div
                disabled={false} // use true to disable edition
                onChange={onChangeSignature} // handle innerHTML change
                placeholder={t('settings.emailingSection.signaturePlaceholder')}
                name='signature'
              />
            </Form.Field>
          </div>
        </div>
      </div>
      {(isAdmin || client?.permissions?.reveal) && (
        <>
          <h2>{t('settings.emailTracking.header')}</h2>
          <div className='section visible'>
            <div className='input-container'>
              <div className='input-label'>
                {t('settings.emailTracking.emailTracking')}
              </div>
              <div className='input-element'>
                <Checkbox
                  name='trackOnEmailOpen'
                  onChange={onChangeCheckbox}
                  checked={trackingSettingsInput.trackOnEmailOpen}
                  label={t('settings.emailTracking.trackOnEmailOpen')}
                />
              </div>
              <div className='input-description'>
                {t('settings.emailTracking.trackOnEmailOpenTooltip')}
              </div>
            </div>
            <div className='input-container'>
              <div className='input-label'>
                {t('settings.emailTracking.clickTracking')}
              </div>
              <div className='input-element'>
                <Checkbox
                  name='trackOnLinkClick'
                  onChange={onChangeCheckbox}
                  checked={trackingSettingsInput.trackOnLinkClick}
                  label={t('settings.emailTracking.trackOnLinkClick')}
                />
              </div>
              <div className='input-description'>
                {t('settings.emailTracking.trackOnLinkClickTooltip')}
              </div>
            </div>
          </div>
        </>
      )}

      <h2>{t('settings.additionalOptions.header')}</h2>
      <div className='section visible'>
        <div className='input-container'>
          <div className='input-label'>
            {t('settings.advancedMessaging.header')}
          </div>
          <div className='input-element'>
            <Checkbox
              name='showConfirmationModal'
              onChange={onChangeCheckbox}
              checked={
                _.isBoolean(input.showConfirmationModal)
                  ? input.showConfirmationModal
                  : true
              }
              label={t('settings.advancedMessaging.showConfirmationModal')}
            />
          </div>
        </div>
        <div className='input-container'>
          <AdvancedMessagingModal>
            {(onActivate) => (
              <div className='messaging-modal-opener' onClick={onActivate}>
                {t('settings.advancedMessaging.openOptions')}
              </div>
            )}
          </AdvancedMessagingModal>
        </div>
      </div>
    </Form>
  );
};

const EmailSettings = ({
  clientId,
  user,
  client,
  loadingUserSettings,
  loadingClient,
  updateClientTrackingSettings,
  updateUser,
  t,
}) => {
  return (
    <SettingsLayout
      currentPage='email'
      clientId={clientId}
      className='settings-container'
    >
      {user && !loadingUserSettings && !loadingClient && (
        <EmailSettingsForm
          user={user}
          updateUser={updateUser}
          client={client}
          updateClientTrackingSettings={updateClientTrackingSettings}
          t={t}
        />
      )}
    </SettingsLayout>
  );
};

export default compose(
  withUserSettings,
  withUpdateUserSettings,
  withClient,
  withUpdateClientTrackingSettings,
  withTranslation('translations'),
)(EmailSettings);
