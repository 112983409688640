import React, { useMemo, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

import './JobPostings.css';
import { Department } from '@/types/client';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import {
  JobPostingsTable,
  getExistingAndPossibleJobPostings,
  getSortingFunction,
} from './JobPostings';

interface RevealJobPostingProps {
  clientId: string;
  careerPage: any;
  clientDepartments: Department[];
}

export const JobsWithJobPosting = gql`
  query JobsWithJobPosting(
    $searchPoolId: ID!
    $input: JobsWithJobPostingsInput
  ) {
    searchPool(id: $searchPoolId) {
      jobsWithJobPosting(input: $input) {
        id
        owner {
          firstname
          lastname
          email
        }
        jobPostings {
          id
          published
          url
          title {
            default
            fr
            en
          }
          description {
            ... on MultiSectionsJobPostingDescription {
              type
              sections {
                type
                ... on SimpleJobPostingDescriptionSection {
                  type
                  title {
                    default
                    fr
                    en
                  }
                  content {
                    default
                    fr
                    en
                  }
                }
                ... on VideoJobPostingDescriptionSection {
                  type
                  url
                }
                ... on ImageJobPostingDescriptionSection {
                  type
                  name
                  file {
                    url
                  }
                }
              }
            }
            type
          }
          locations {
            identifier
            label {
              default
              fr
              en
            }
          }
          owner {
            firstname
            email
            lastname
          }
          foldering {
            department {
              id
              title
              sections {
                id
                title
                subsections {
                  id
                  title
                }
              }
            }
            section {
              id
              title
            }
          }
          application {
            form {
              type
              ... on ContactAndQuestionsJobPostingApplicationForm {
                type
                questionsForm {
                  type
                  templateId
                  form {
                    fields {
                      type
                      id
                      title
                      value {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
        data {
          title
        }
      }
    }
  }
`;

const RevealJobPostings: React.FC<RevealJobPostingProps> = ({
  clientId,
  careerPage,
  clientDepartments,
}) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(JobsWithJobPosting, {
    variables: {
      input: {
        jobPostingIds: _.pluck(careerPage.jobPostings, 'id'),
      },
      searchPoolId: 'reveal',
    },
  });

  const jobPostings = useMemo(
    () =>
      loading
        ? []
        : getExistingAndPossibleJobPostings({
            clientId,
            jobs: data?.searchPool?.jobsWithJobPosting || [],
            careerPage,
            clientDepartments,
            applicationMode: 'reveal',
          }),
    [data, careerPage, clientDepartments, clientId, loading],
  );

  const sortingFunction = getSortingFunction(clientDepartments);
  const publishedJobPostings = _.filter(
    jobPostings,
    ({ published }) => published,
  ).sort(sortingFunction);
  const unpublishedJobPostings = _.filter(
    jobPostings,
    ({ published }) => !published,
  ).sort(sortingFunction);
  const [showNotPublishedPostings, setShowNotPublishedPostings] = useState(
    _.isEmpty(publishedJobPostings),
  );

  return (
    <>
      <h2>{t('settings.careerPage.jobPostings.jobPostings')}</h2>
      <div className='job-postings section visible'>
        <JobPostingsTable
          jobPostings={publishedJobPostings}
          clientDepartments={clientDepartments}
          t={t}
          unpublished={undefined}
        />
        {!_.isEmpty(unpublishedJobPostings) && (
          <div className='button-container'>
            <Button
              className='link-button'
              onClick={() =>
                setShowNotPublishedPostings(!showNotPublishedPostings)
              }
            >
              {showNotPublishedPostings
                ? t('settings.careerPage.jobPostings.hideNotPublishedPostings')
                : t('settings.careerPage.jobPostings.showNotPublishedPostings')}
            </Button>
          </div>
        )}
        {showNotPublishedPostings && (
          <JobPostingsTable
            jobPostings={unpublishedJobPostings}
            clientDepartments={clientDepartments}
            t={t}
            unpublished
          />
        )}
      </div>
    </>
  );
};

export default RevealJobPostings;
