import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const query = gql`
  query getClientUsers($clientId: ID!) {
    client(id: $clientId) {
      id
      users {
        id
        email
        firstname
        lastname
        photoLink
        status
        date
      }
    }
  }
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      clientId: ownProps.clientId,
    },
  }),
  props: ({ data: { loading, client, error } }) => ({
    loading,
    collaboratorsLoading: loading,
    collaborators: client && client.users ? client.users : [],
    error,
  }),
});
