import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import GenericButton from '@/components/Common/GenericButton';

import PendingIllustration from '../PendingIllustration';
import CardEmptyState from '../CardEmptyState';

interface PendingExtendedEmptyStateProps {
  offerId: string;
  count: number;
  isPrimary: boolean;
  onChangeStep: (next: { newStepId: string; newSubStepId: string }) => void;
}

const PendingExtendedEmptyState: FC<PendingExtendedEmptyStateProps> = ({
  count,
  isPrimary,
  offerId,
  onChangeStep,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PendingIllustration offerId={offerId} subStep='pending-extended' />
      <CardEmptyState
        title={t('offers.profileEmptyStates.pendingExtended.title', { count })}
        cta={
          <GenericButton
            primacy={isPrimary ? 'primary' : 'secondary'}
            onClick={() =>
              onChangeStep({
                newStepId: 'pending',
                newSubStepId: 'pending-extended',
              })
            }
          >
            {t('offers.profileEmptyStates.pendingExtended.cta')}
          </GenericButton>
        }
      >
        {t('offers.profileEmptyStates.pendingExtended.innerContent', {
          count,
        })}
      </CardEmptyState>
    </>
  );
};

export default PendingExtendedEmptyState;
