import React, { useCallback, useState, useMemo, FC } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Segment, Header, Divider, Image } from 'semantic-ui-react';
import { useMutation, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/browser';

import './JobSequencesTab.css';
import { Link } from 'react-router-dom';
import {
  ADD_JOB_DEFAULT_SEQUENCES,
  GET_JOB_DEFAULT_SEQUENCES,
  DISABLED_JOB_DEFAULT_SEQUENCES,
} from '@/graphql/searchPoolJob';
import contextToProps from '@/hocs/contextToProps';
import { CLIENT_SEQUENCES } from '@/containers/Parameters/Sequences/queries';
import { getSequenceWithPreviewOptions } from '@/revealComponents/AddToSequenceDropdown';
import SequencePreview from '@/revealComponents/SequencePreview';
import ProfileRowSequence from '@/routes/RevealView/ProfileRow/ProfileRowSequence';
import { getRandomDefaultAvatarLink } from '@/common';
import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';
import { Sequence } from '@/types/sequence';
import SequenceDropdownWithQuery from '@/components/Reveal/Sequences/SequenceDropdown/SequenceDropdown';
import DropdownContainer from '@/components/Reveal/Dropdown/DropdownContainer/DropdownContainer';
import DropdownTrigger from '@/components/Reveal/Dropdown/DropdownTrigger/DropdownTrigger';

import styles from './JobSequencesTab.module.less';

interface JobSequencesTabParams {
  clientId: string;
  searchPoolId: string;
  jobId: string;
  onShowNotification: (props: { message: string; level: string }) => void;
}

interface SequenceSelectorProps {
  clientId: string;
  sequences?: Sequence[];
  defaultSequence?: {
    sequenceId: string;
  };
  clearable: boolean;
  onRemoveDefaultSequence: () => void;
  onChange: (id: string) => void;
  placeholder: string;
}

const SequenceSelector: FC<SequenceSelectorProps> = ({
  clientId,
  sequences,
  defaultSequence,
  onChange,
  onRemoveDefaultSequence,
  clearable,
  placeholder,
}) => {
  const { t } = useTranslation();

  if (!sequences || _.isEmpty(sequences)) {
    return (
      <div className='no-sequences-content'>
        <span>{t('reveal.sequenceSideBar.noSequence')}</span>
        <br />
        <span>{t('reveal.sequenceSideBar.goTo')}</span>
        <Link to={`/client/${clientId}/reveal/sequences`}>
          {t('reveal.sequenceSideBar.sequencePage')}
        </Link>
        <span>{t('reveal.sequenceSideBar.toCreateOne')}</span>
      </div>
    );
  }

  return (
    <>
      <SequenceDropdownWithQuery
        clearable={clearable}
        clientId={clientId}
        clientSequencesList={sequences}
        placeholder={placeholder}
        currentSequence={{
          sequenceId: defaultSequence?.sequenceId,
        }}
        onSequenceSelected={onChange}
      />
      {defaultSequence && (
        <div
          onClick={onRemoveDefaultSequence}
          className='close-default-container'
        >
          <i className='ri-close-fill ri-lg close-default-sequence' />
        </div>
      )}
    </>
  );
};

const JobSequencesTab: React.FC<JobSequencesTabParams> = ({
  clientId,
  searchPoolId,
  jobId,
  onShowNotification,
}) => {
  const { t } = useTranslation();
  const [editSequence] = useMutation(ADD_JOB_DEFAULT_SEQUENCES);
  const [disableSequence] = useMutation(DISABLED_JOB_DEFAULT_SEQUENCES);
  // const [sequences, setSequences] = useState<JobSequenceData[]>([]);
  const [selectedSequenceId] = useState();
  const { data: jobData } = useQuery(GET_JOB_DEFAULT_SEQUENCES, {
    variables: { searchPoolId: 'reveal', jobId },
  });
  const attachedSequences = jobData?.searchPool?.job?.attachedSequences;
  const defaultSequence = _.findWhere(attachedSequences, { isDefault: true });
  const secondarySequences = _.filter(
    attachedSequences,
    ({ sequenceId }) => sequenceId !== defaultSequence?.sequenceId,
  );
  const { loading, data: allClientSequences } = useQuery(CLIENT_SEQUENCES, {
    variables: { clientId, filters: { activeOnly: true } },
  });

  const onSave = useCallback(
    async ({ variables, request }: { variables: any; request: any }) => {
      try {
        await request({ variables });
        onShowNotification({
          message: t(
            'reveal.missions.mission.settingsTab.sequencesSuccessfullyEdited',
          ),
          level: 'success',
        });
      } catch (e) {
        Sentry.captureException(e);

        onShowNotification({
          message: t('reveal.missions.mission.settingsTab.sequencesEditError'),
          level: 'error',
        });
      }
    },
    [onShowNotification, t],
  );

  const handleDefaultSequenceChange = useCallback(
    (id) => {
      const { sequenceId, state } = {
        sequenceId: id || defaultSequence.sequenceId,
        state: !!id,
      };
      onSave({
        variables: {
          jobId,
          sequenceId,
          isDefault: state,
          searchPoolId,
        },
        request: editSequence,
      });
    },
    [jobId, searchPoolId, defaultSequence, onSave, editSequence],
  );

  const getSequenceWithTitle = useCallback((sequence: any) => {
    const newSequence = { ...sequence };
    if (!newSequence.hasPreview) {
      newSequence.title = (
        <>
          <span>{newSequence.title || ''}</span>
          <div className='sequence-preview'>
            <ProfileRowSequence
              currentSequenceInfo={newSequence}
              sequences={newSequence.contactFlow.sequences}
              nextInteraction={null}
              loading={false}
              isStandalone
              mini
            />
          </div>
        </>
      );
      newSequence.hasPreview = true;
    }

    return newSequence;
  }, []);

  const sequences = useMemo(() => {
    if (loading) {
      return [];
    }
    const optionsWithSequencePreview = _.map(
      allClientSequences?.client?.sequences,
      (sequence) => getSequenceWithTitle(sequence),
    );
    const sequencesOptions = getSequenceWithPreviewOptions({
      clientId,
      sequences: optionsWithSequencePreview,
      onChange: handleDefaultSequenceChange,
      position: 'right center',
      onClose: () => {},
    });
    return sequencesOptions;
    // setSequences(sequencesOptions);
  }, [
    getSequenceWithTitle,
    handleDefaultSequenceChange,
    allClientSequences,
    loading,
    clientId,
  ]);

  const getSequenceTitle = ({ sequenceId }: { sequenceId: string }) => {
    const sequence = _.findWhere(allClientSequences?.client?.sequences, {
      id: sequenceId,
    });
    if (!sequence) {
      return null;
    }

    return getSequenceWithTitle(sequence).title;
  };

  const handleSecondarySequenceChange = (id: string) => {
    onSave({
      variables: {
        jobId,
        sequenceId: id,
        isDefault: false,
        searchPoolId,
      },
      request: editSequence,
    });
  };

  const remainingOptions = _.filter(
    allClientSequences?.client?.sequences,
    (sequence) => {
      return (
        sequence.id !== defaultSequence?.sequenceId &&
        !_.some(secondarySequences, { sequenceId: sequence.id })
      );
    },
  );

  function getRemainingSequences(): Record<string, any>[] {
    const remainingOptionsWithTitle: any[] = _.map(
      remainingOptions,
      (sequence) => getSequenceWithTitle(sequence),
    );

    return getSequenceWithPreviewOptions({
      clientId,
      sequences: _.map(remainingOptionsWithTitle, (sequence) => ({
        ...sequence,
      })),
      onChange: handleSecondarySequenceChange,
      position: 'right center',
      onClose: () => {},
    });
  }

  const handleDisableSequence = (sequenceId: string) => {
    onSave({
      variables: {
        jobId,
        sequenceId,
        searchPoolId,
      },
      request: disableSequence,
    });
  };

  const onRemoveDefaultSequence = () => {
    handleDefaultSequenceChange(null);
  };
  const remainingSequences = getRemainingSequences();
  const selectedSequence = _.findWhere(sequences, { key: selectedSequenceId });
  const defaultSequenceData: any = _.find(sequences, (sequence: any) => {
    return sequence?.key === defaultSequence?.sequenceId;
  });
  const secondarySequencesData = _.map(secondarySequences, (sequence) => {
    return _.find(sequences, (sequenceData: any) => {
      return sequenceData?.key === sequence.sequenceId;
    });
  });
  return (
    <div className='sequence-tab'>
      <Segment className='pure-criteria-form'>
        <h2>{t('reveal.missions.mission.settingsTab.sequences')}</h2>
        <div className='info-box'>
          <p>{t('reveal.missions.mission.settingsTab.infoBox.description')}</p>
          <div className='info-box-buttons'>
            <Link
              to={`/client/${clientId}/reveal/sequences/new-sequence-preview`}
            >
              <GenericButton primacy='secondary'>
                <Plus />
                {t('reveal.missions.mission.settingsTab.infoBox.newSequence')}
              </GenericButton>
            </Link>
            <Link to={`/client/${clientId}/reveal/sequences`}>
              <GenericButton>
                {t('reveal.missions.mission.settingsTab.infoBox.toSequence')}
              </GenericButton>
            </Link>
          </div>
        </div>
        <div className='sequences-container'>
          <div className='mission-sequences'>
            <h3>{t('reveal.missions.mission.settingsTab.defaultSequence')}</h3>
            <p>
              {t('reveal.missions.mission.settingsTab.defaultSequenceSubtitle')}
            </p>

            <SequenceSelector
              clientId={clientId}
              clearable={Boolean(defaultSequence)}
              sequences={allClientSequences?.client?.sequences}
              defaultSequence={defaultSequence}
              onChange={handleDefaultSequenceChange}
              onRemoveDefaultSequence={onRemoveDefaultSequence}
              placeholder={t(
                'reveal.missions.mission.settingsTab.newDefaultSequence',
              )}
            />

            {(!_.isEmpty(remainingSequences) ||
              !_.isEmpty(secondarySequences)) && (
              <>
                <h3>
                  {t('reveal.missions.mission.settingsTab.secondarySequences')}
                </h3>
                <p>
                  {t(
                    'reveal.missions.mission.settingsTab.secondarySequencesSubtitle',
                  )}
                </p>
              </>
            )}
            {_.map(secondarySequences, (secondarySequence) => (
              <div className={styles.secondarySequence}>
                <DropdownContainer key={secondarySequence.sequenceId}>
                  <DropdownTrigger
                    className={styles.fakeTrigger}
                    clearable
                    onClear={() =>
                      handleDisableSequence(secondarySequence.sequenceId)
                    }
                  >
                    <div className={styles.secondarySequenceTrigger}>
                      {getSequenceTitle({
                        sequenceId: secondarySequence.sequenceId,
                      })}
                    </div>
                  </DropdownTrigger>
                </DropdownContainer>
              </div>
            ))}
            {!_.isEmpty(remainingSequences) && (
              <>
                <SequenceSelector
                  clientId={clientId}
                  clearable={false}
                  sequences={remainingOptions}
                  onChange={handleSecondarySequenceChange}
                  onRemoveDefaultSequence={onRemoveDefaultSequence}
                  placeholder={t(
                    'reveal.missions.mission.settingsTab.newSecondarySequence',
                  )}
                />
              </>
            )}
          </div>
          <div className='result-preview'>
            <div className='preview-container'>
              <div className='preview'>
                <div className='preview-header'>
                  <div className='picture-and-headline'>
                    <Image src={getRandomDefaultAvatarLink('')} circular />
                    <div className='headline-container'>
                      <div className='item header-item' />
                      <div className='item' />
                      <div className='item' />
                    </div>
                  </div>
                  <div className='description'>
                    <div className='item' />
                    <div className='item' />
                    <div className='item' />
                    <div className='item' />
                  </div>
                </div>
                <Divider />
                <div className='preview-body'>
                  <div className='sequence-dropdown-preview-container'>
                    <div className='fake-input'>
                      <p className='fake-input-value'>
                        {defaultSequenceData?.text?.props?.children?.[0]?.props
                          ?.children ||
                          t(
                            'reveal.missions.mission.settingsTab.illustration.defaultEmpty',
                          )}
                      </p>
                      <span>
                        <i className='ri-arrow-drop-down-fill ri-2x' />
                      </span>
                    </div>
                    <div className='fake-dropdown-menu'>
                      <div className='default-preview'>
                        <Header size='tiny'>
                          {t(
                            'reveal.missions.mission.settingsTab.illustration.defaultPreview',
                          )}
                        </Header>
                        {defaultSequenceData ? (
                          <div className='default-sequence-preview'>
                            {defaultSequenceData?.text}
                          </div>
                        ) : (
                          <div className='default-sequence-preview'>
                            {t(
                              'reveal.missions.mission.settingsTab.illustration.defaultEmpty',
                            )}
                          </div>
                        )}
                      </div>
                      <Divider />
                      <div className='default-preview'>
                        <Header size='tiny'>
                          {t(
                            'reveal.missions.mission.settingsTab.illustration.secondaryPreview',
                          )}
                        </Header>
                        {!_.isEmpty(secondarySequencesData) ? (
                          _.map(
                            secondarySequencesData,
                            (sequence: any, index) => (
                              <div
                                key={index}
                                className='secondary-sequence-preview'
                              >
                                {sequence?.text}
                              </div>
                            ),
                          )
                        ) : (
                          <div className='secondary-sequence-preview'>
                            {t(
                              'reveal.missions.mission.settingsTab.illustration.secondaryEmpty',
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Segment>

      {selectedSequence && (
        <Segment className='sequence-preview-container'>
          <SequencePreview
            clientId={clientId}
            sequence={selectedSequence}
            alwaysExpanded
          />
        </Segment>
      )}
    </div>
  );
};

export default _.compose(contextToProps)(JobSequencesTab);
