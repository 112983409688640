import React /* , { useState } */ from 'react';
import {
  HiresweetLibProvider,
  ProjectEditionForm,
  ProjectEditionSubmitButton,
  ProjectTitleEditionInput,
  createProjectTextCustomFieldEditionInput,
  createProjectIntegerCustomFieldEditionInput,
  createProjectFloatCustomFieldEditionInput,
  createProjectExplicitEnumCustomFieldEditionInput,
  createProjectDayCustomFieldEditionInput,
  /*
  createProjectExplicitEnumCustomFieldEditionInput,  ,
  */
} from '@hiresweet/hiresweet-lib';

import useClientId from '@/hooks/router/useClientId';

const ProjectLocalisationEditor = createProjectTextCustomFieldEditionInput({
  label: 'Localisation',
  clientCustomFieldId: 'localisation',
  isInlineText: true,
});

const ProjectImportanceEditor = createProjectIntegerCustomFieldEditionInput({
  label: 'Importance',
  clientCustomFieldId: 'importance',
});

const ProjectRatioEditor = createProjectFloatCustomFieldEditionInput({
  label: 'Ratio',
  clientCustomFieldId: 'ratio',
});

const qualificationTitleFromId = {
  a: 'A!',
  b: 'B!',
  c: 'C!',
};

const ProjectQualificationEditor =
  createProjectExplicitEnumCustomFieldEditionInput({
    label: 'Qualification',
    clientCustomFieldId: 'qualification',
    titleFromId: qualificationTitleFromId,
    srot: true,
  });

const ProjectDueDayEditor = createProjectDayCustomFieldEditionInput({
  label: 'Due Date',
  clientCustomFieldId: 'dueday',
});

const PlaygroundB3 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30, background: 'white' }}>
      <h1>B2 - Project Forms 2</h1>
      <HiresweetLibProvider theme={theme} clientId={clientId}>
        <h4>Creation Form</h4>
        <ProjectEditionForm mode='creation'>
          <ProjectTitleEditionInput label='Titre' />
          <br />
          <ProjectLocalisationEditor />
          <br />
          <ProjectImportanceEditor />
          <br />
          <ProjectRatioEditor />
          <br />
          <ProjectQualificationEditor />
          <br />
          <ProjectDueDayEditor />
          <br />
          <ProjectEditionSubmitButton label='Créer le projet' />
        </ProjectEditionForm>
      </HiresweetLibProvider>
    </div>
  );
};

export default PlaygroundB3;
