import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import OfferRecipients from '../../graphql/fragments/OfferRecipients';

export const mutation = gql`
  mutation setJobRecipient($offerId: ID!, $isRecipient: Boolean!) {
    setJobRecipient(id: $offerId, isRecipient: $isRecipient) {
      ...OfferRecipients
    }
  }
  ${OfferRecipients}
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    setJobRecipient: ({ offerId, isRecipient }) => {
      return mutate({
        variables: {
          offerId,
          isRecipient,
        },
      });
    },
  }),
});
