import React, { useState } from 'react';
import {
  HiresweetLibProvider,
  ProjectContainer,
  ProjectEditionForm,
  ProjectEditionSubmitButton,
  ProjectTitleEditionInput,
  ProjectTitle,
  useProjectField,
  createProjectTextCustomField,
  createProjectTextCustomFieldEditionInput,
  createProjectExplicitEnumCustomFieldEditionInput,
  createProjectExplicitEnumCustomField,
  createProjectIntegerCustomFieldEditionInput,
  createProjectFloatCustomFieldEditionInput,
  createProjectIntegerCustomField,
  createProjectFloatCustomField,
  createProjectDayCustomField,
  createProjectDayCustomFieldEditionInput,
} from '@hiresweet/hiresweet-lib';

import useClientId from '@/hooks/router/useClientId';

const MyFormInner = ({
  initialValue,
  onChange,
}: {
  initialValue?: string;
  onChange: (x: string) => void;
}) => {
  const [state, setState] = useState(initialValue);

  return (
    <input
      value={state}
      onChange={({ target }) => {
        setState(target.value);
        onChange(target.value);
      }}
    />
  );
};

const MyForm = () => {
  const { loading, initialValue, onChange } = useProjectField(
    ProjectTitleEditionInput,
  );
  if (loading) {
    return null;
  }
  return <MyFormInner initialValue={initialValue} onChange={onChange} />;
};

const ProjectLocalisation = createProjectTextCustomField({
  clientCustomFieldId: 'localisation',
});

const LocalisationEditor = createProjectTextCustomFieldEditionInput({
  label: 'Localisation',
  clientCustomFieldId: 'localisation',
  isInlineText: true,
});

const LocalisationEditor2 = () => {
  const { loading, initialValue, onChange } =
    useProjectField(LocalisationEditor);

  if (loading) {
    return null;
  }

  return (
    <input
      defaultValue={initialValue}
      onChange={({ target }) => {
        onChange(target.value);
      }}
    />
  );
};

const qualificationTitleFromId = {
  a: 'A!',
  b: 'B!',
  c: 'C!',
};

const ProjectQualification = createProjectExplicitEnumCustomField({
  clientCustomFieldId: 'qualification',
  titleFromId: qualificationTitleFromId,
});

const ProjectQualificationEditor =
  createProjectExplicitEnumCustomFieldEditionInput({
    label: 'Qualification',
    clientCustomFieldId: 'qualification',
    titleFromId: qualificationTitleFromId,
    sort: true,
  });

const ProjectImportance = createProjectIntegerCustomField({
  clientCustomFieldId: 'importance',
});

const ProjectImportanceEditor = createProjectIntegerCustomFieldEditionInput({
  label: 'Importance',
  clientCustomFieldId: 'importance',
});

const ProjectRatio = createProjectFloatCustomField({
  clientCustomFieldId: 'ratio',
});

const ProjectRatioEditor = createProjectFloatCustomFieldEditionInput({
  label: 'Ratio',
  clientCustomFieldId: 'ratio',
});

const ProjectDueDay = createProjectDayCustomField({
  clientCustomFieldId: 'dueday',
});

const ProjectDueDayEditor = createProjectDayCustomFieldEditionInput({
  label: 'Due Date',
  clientCustomFieldId: 'dueday',
});

const PlaygroundB2 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30, background: 'white' }}>
      <h1>B2 - Project Forms 2</h1>
      <HiresweetLibProvider theme={theme} clientId={clientId}>
        <ProjectContainer projectId='ri7-demo-testimport2-UMODD2'>
          <h4>Display</h4>
          <ProjectTitle />
          <br />
          <ProjectLocalisation />
          <br />
          <ProjectQualification />
          <br />
          <ProjectImportance />
          <br />
          <ProjectRatio /> <br />
          <ProjectDueDay />
          <br />
          <h4>Form 1</h4>
          <ProjectEditionForm>
            <ProjectTitleEditionInput label='Titre2' />
            <br />
            <LocalisationEditor />
            <br />
            <ProjectQualificationEditor />
            <br />
            <ProjectImportanceEditor />
            <br />
            <ProjectRatioEditor />
            <br />
            <ProjectDueDayEditor />
            <br />
            <ProjectEditionSubmitButton label='Sauvegarder 1' />
          </ProjectEditionForm>
          <br />
          <br />
          <br />
          <h4>Form 2</h4>
          <ProjectEditionForm>
            <MyForm />
            <br />
            <LocalisationEditor2 />
            <br />
            <ProjectEditionSubmitButton label='Sauvegarder 2' />
          </ProjectEditionForm>
        </ProjectContainer>
      </HiresweetLibProvider>
    </div>
  );
};

export default PlaygroundB2;
