import React from 'react';
import { Button } from 'semantic-ui-react';

export default function OfferSettingPane(props) {
  const {
    onAccept,
    onDismiss,
    paneName,
    t,
  } = props;
  return (
    <div className='smart-no-profile-left-pane'>
      <div className='unlimited-flow-container'>
        <center>
          <h3>{t(`smartLeftPane.${paneName}Title`)}</h3>
        </center>
        <p>{t(`smartLeftPane.${paneName}Details`)}</p>
        <center>
          <div className='actions-container'>
            <Button onClick={onAccept}>
              <span>{t(`smartLeftPane.${paneName}Button`)}</span>
            </Button>
          </div>
          <div className='ignore-container'>
            <span className='ignore-text' onClick={onDismiss}>
              {t(`smartLeftPane.${paneName}Ignore`)}
            </span>
          </div>
        </center>
      </div>
    </div>
  );
}
