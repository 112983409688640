import gql from 'graphql-tag';
import { MutationTuple, useMutation } from '@apollo/client';
import { SearchPoolProfileState } from '@/types/profile';

interface EditPhotoLinkVariables {
  searchPoolId: string;
  profileId: string;
  photoLink?: string;
}

export const EditPhotoLink = gql`
  mutation UpdateProfilePhotoLink(
    $profileId: ID!
    $photoLink: String!
    $searchPoolId: ID!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      updateProfilePhotoLink(profileId: $profileId, photoLink: $photoLink) {
        profile {
          id
          resumeData {
            photoLink
          }
        }
      }
    }
  }
`;

export default (): MutationTuple<
  SearchPoolProfileState,
  EditPhotoLinkVariables
> => useMutation(EditPhotoLink);
