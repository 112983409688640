import * as Sentry from '@sentry/browser';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { useAddProfilesToMissionAndSequenceMutation } from '@/graphql/searchPoolProfiles';

import { CLIENT_SEQUENCES } from '../../../../../../containers/Parameters/Sequences/queries';
import useSearchPoolJobOptions from '../../../../../../graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';

const useAddToProjectFormState = ({
  clientId,
  jobId,
  candidates,
  onClose,
  onShowNotification,
}) => {
  const { t } = useTranslation();
  const [selectedJobId, setSelectedJobId] = useState(jobId || null);
  const [selectedSequenceId, setSelectedSequenceId] = useState();
  const [addToSequence, setAddToSequence] = useState(!_.isEmpty(jobId));
  const [submitting, setSubmitting] = useState(false);

  const {
    jobOptions: jobs,
    jobOptionsLoading: jobsLoading,
  } = useSearchPoolJobOptions('reveal', { activeOnly: true });

  // const jobOptions = _.map(jobs, ({ id, data }) => ({
  //   text: data?.title,
  //   value: id,
  // }));

  const clientSequences = useQuery(CLIENT_SEQUENCES, {
    variables: { clientId },
  });
  const clientSequencesList = clientSequences?.data?.client?.sequences;
  // const sequenceOptions = getSequenceOptions(clientSequencesList);

  const selectedSequence = _.findWhere(clientSequencesList, {
    id: selectedSequenceId,
  });

  useEffect(() => {
    if (!jobsLoading && !selectedJobId && jobs?.length === 1) {
      setSelectedJobId(jobs[0].id);
    }

    // eslint-disable-next-line
  }, [jobsLoading]);

  const [
    addProfilesToMissionAndSequence,
  ] = useAddProfilesToMissionAndSequenceMutation();

  const onClear = () => {
    setAddToSequence(false);
    setSelectedJobId('');
  };

  const onClearSequence = () => {
    setSelectedSequenceId('');
  };

  const onChangeJob = ({ id }) => {
    setAddToSequence(!_.isEmpty(id));
    setSelectedJobId(id);
  };

  const onChangeSequence = (e, { value }) => {
    setSelectedSequenceId(value);
  };

  const onSubmit = async () => {
    setSubmitting(true);
    try {
      const filteredCandidates = _.filter(
        candidates,
        (candidate) => !candidate.privacyState?.markedAsDoNotContact,
      );
      const profileIds = _.pluck(filteredCandidates, 'id');

      const { data } = await addProfilesToMissionAndSequence({
        variables: {
          searchPoolId: 'reveal',
          input: {
            missionId: selectedJobId,
            ...(addToSequence &&
              selectedSequenceId && { sequenceId: selectedSequenceId }),
            profileIds,
          },
        },
        ...(addToSequence && {
          sequence: _.findWhere(clientSequencesList, {
            id: selectedSequenceId,
          }),
        }),
      });

      if (!data) {
        // Case where the modal was displayed
        return;
      }

      const results =
        data?.searchPool?.addProfilesToMissionAndSequence?.results || [];
      const successes = _.filter(results, ({ success }) => success);
      const errors = _.filter(results, ({ success }) => !success);

      // TODO: add a 'warning' / orange level for future use (instead of showing 2 notifications ?)
      if (!_.isEmpty(errors)) {
        onShowNotification({
          message: t('reveal.candidatesView.actionsBox.addToMissionError', {
            count: errors.length > 1 ? 2 : 1,
            nb: errors.length,
          }),
          level: 'error',
        });
      }

      if (!_.isEmpty(successes)) {
        // TODO: custom notif with link
        onShowNotification({
          message: t('reveal.missions.mission.actions.addedToMissionSuccess', {
            count: successes.length,
          }),
          level: 'success',
        });
        // TODO: GO TO LASER FOCUS (?)
      } else {
        // setSubmitting(false);
        // onClose();
      }

      setSubmitting(false);
      onClose();
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      onShowNotification({
        message: t('reveal.missions.mission.actions.addToMissionError'),
        level: 'error',
      });
      setSubmitting(false);
      onClose();
    }
  };

  const loading = jobsLoading || clientSequences?.loading;
  const formState = {
    selectedJobId,
    setSelectedJobId,
    selectedSequenceId,
    setSelectedSequenceId,
    selectedSequence,
    addToSequence,
    toggleAddToSequence: () => setAddToSequence((checked) => !checked),
    loading,
    submitting,
    submissionDisabled: !selectedJobId || submitting || loading,
    onChangeJob,
    onChangeSequence,
    onSubmit,
    onClear,
    onClearSequence,
  };

  const formOptions = {
    jobs,
    // sequences: sequenceOptions,
    clientSequencesList,
  };

  return {
    formState,
    formOptions,
  };
};

export default useAddToProjectFormState;
