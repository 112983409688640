import React from 'react';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import logAction from '../../../common/logAction';
import withUserFromJWToken from '../../../hocs/users/withUserFromJWToken';

import './SortByDropdown.css';

export const RELEVANCY_ORDER = 'relevancy';
export const RECENCY_ORDER = 'recency';

const SortByDropdown = ({ clientId, sortBy, setSortBy, user, t }) => {
  const options = [
    {
      value: RELEVANCY_ORDER,
      text: t('watchCollect.sortByDropdown.relevancy'),
    },
    { value: RECENCY_ORDER, text: t('watchCollect.sortByDropdown.recency') },
  ];

  const onChange = (e, { value }) => {
    setSortBy(value);

    const author = `${user?.firstname} ${user?.lastname}`;
    logAction({
      type: `discover-sort-by-${value}`,
      info: { clientId, author },
    });
  };
  return (
    <div className='sort-by-dropdown'>
      <div className='sort-by-text'>
        {t('watchCollect.sortByDropdown.label')}
      </div>
      <Dropdown
        selection
        value={sortBy}
        onChange={onChange}
        options={options}
      />
    </div>
  );
};

export default compose(
  withUserFromJWToken,
  withTranslation('translations'),
)(SortByDropdown);
