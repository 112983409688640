import React, { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Placeholder } from 'semantic-ui-react';
import classNames from 'classnames';

import { Author } from '@/types/author';
import { getFullname } from '@/common/helpers/person';

import styles from './TaskColumn.module.less';

interface TaskColumnProps {
  type: string;
  day: string;
  author: Author;
  profileLoading?: boolean;
  className?: string;
  style?: CSSProperties;
}

const TaskColumn: FC<TaskColumnProps> = ({
  type,
  day,
  author,
  className,
  profileLoading,
  style,
}) => {
  const { t, i18n } = useTranslation();

  const description = `${getFullname(author)} - ${new Date(day).toLocaleString(
    i18n.resolvedLanguage,
    {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
  )}`;

  return (
    <div className={classNames(styles.task, className)} style={style}>
      {profileLoading ? (
        <Placeholder>
          <Placeholder.Line length='short' />
        </Placeholder>
      ) : (
        <>
          <div className={styles.type}>
            {t(`reveal.analyticsView.tasks.chart.legend.${type}`)}
          </div>
          <div className={styles.description}>{description}</div>
        </>
      )}
    </div>
  );
};

export default TaskColumn;
