import _ from 'underscore';
import React, { useState, useEffect } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import GenericRangeSlider from '@/components/Common/GenericRangeSlider';
import logAction from '../../../common/logAction';

import styles from './MarkeplaceRange.module.less';

export const XP_YEAR_MAX = 20;

// LEGACY
const ExperienceYearsCriterionPopup = ({
  experienceYearsMin,
  experienceYearsMax,
  setExperienceYearsMin,
  setExperienceYearsMax,
  resetSelectedSearch,
  t,
  clientId,
  user,
}) => {
  const [experienceYearsMinState, setExperienceYearsMinState] = useState(
    experienceYearsMin || 0,
  );
  const [experienceYearsMaxState, setExperienceYearsMaxState] = useState(
    experienceYearsMax || XP_YEAR_MAX,
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setExperienceYearsMinState(experienceYearsMin);
    setExperienceYearsMaxState(experienceYearsMax);
  }, [experienceYearsMin, experienceYearsMax]);

  const apply = () => {
    if (experienceYearsMin !== experienceYearsMinState) {
      setExperienceYearsMin(experienceYearsMinState);
      resetSelectedSearch();
    }
    if (experienceYearsMax !== experienceYearsMaxState) {
      setExperienceYearsMax(experienceYearsMaxState);
      resetSelectedSearch();
    }
    setOpen(false);
    logAction({
      type: 'discover-change-filter-experience-year',
      info: {
        clientId,
        author: `${user?.firstname} ${user?.lastname}`,
      },
    });
  };

  const reset = () => {
    setExperienceYearsMinState('');
    setExperienceYearsMaxState('');
  };

  const isActive =
    _.isNumber(experienceYearsMin) || _.isNumber(experienceYearsMax);

  return (
    <Popup
      open={open}
      trigger={
        <div
          className={classNames('criterion-button', isActive && 'active')}
          onClick={() => setOpen(!open)}
        >
          {isActive
            ? t('watchCollect.filters.experienceYears.label', {
                minimum: experienceYearsMin || 0,
                maximum: experienceYearsMax || XP_YEAR_MAX,
              })
            : t('watchCollect.filters.experienceYears.defaultLabel')}
        </div>
      }
      onClose={() => {
        apply();
        setOpen(false);
      }}
      on='click'
      basic
      position='bottom center'
      className='criterion-popup'
    >
      <ExperienceYearsCriterion
        experienceYearsMin={experienceYearsMinState || 0}
        experienceYearsMax={experienceYearsMaxState || XP_YEAR_MAX}
        setExperienceYearsMin={setExperienceYearsMinState}
        setExperienceYearsMax={setExperienceYearsMaxState}
        t={t}
      />
      <div className='actions-container'>
        <Button className='reset-button' onClick={reset}>
          {t('watchCollect.filters.reset')}
        </Button>
        <Button className='primary-cta' onClick={apply}>
          {t('watchCollect.filters.apply')}
        </Button>
      </div>
    </Popup>
  );
};

export const ExperienceYearsCriterion = ({
  experienceYearsMin,
  experienceYearsMax,
  setExperienceYearsMin,
  setExperienceYearsMax,
  t,
}) => {
  const onChange = ([minValue, maxValue]) => {
    setExperienceYearsMin(minValue === 0 ? '' : minValue);
    setExperienceYearsMax(maxValue === XP_YEAR_MAX ? '' : maxValue);
  };

  return (
    <div className='experience-years-criterion'>
      <div className='input-range-container'>
        <GenericRangeSlider
          min={0}
          max={XP_YEAR_MAX}
          step={1}
          values={[experienceYearsMin || 0, experienceYearsMax || XP_YEAR_MAX]}
          onChange={onChange}
          labelClassName={styles.marketplaceRangeLabel}
        />
      </div>
      <div className='min-max-labels'>
        <div>{t('watchCollect.filters.experienceYears.minimum')}</div>
        <div>{t('watchCollect.filters.experienceYears.maximum')}</div>
      </div>
    </div>
  );
};

export default withTranslation('translations')(ExperienceYearsCriterionPopup);
