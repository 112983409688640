import React from 'react';
import { withTranslation } from 'react-i18next';
import _, { compose } from 'underscore';
import * as Sentry from '@sentry/browser';

import ButtonDropdownMenu from '@/components/Common/ButtonDropdownMenu';
import withOfferPipe from '../../../hocs/offers/withOfferPipe';
import withChangeProfileStep from '../../../hocs/profiles/withChangeProfileStep';
import { withLastProfileActionsContextConsumer } from '../../../context/LastProfileActionsContext';
import DisqualifyModal from '../modals/DisqualifyModal';

class ProfileStepButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChangeStep = this.handleChangeStep.bind(this);
    this.state = {
      disqualifiedModalOpen: false,
    };
  }

  handleChangeStep = async ({ step }) => {
    const {
      clientId,
      profileId,
      offerId,
      getNextProfileId,
      getProfilesInMove,
      onChangeProfile,
      onChangeStep,
      stepId,
      changeProfileStep,
      handleMarkProfileAsInMove,
      handleUnmarkProfileAsInMove,
      addProfileAction,
    } = this.props;
    if (step === 'disqualified') {
      this.setState({ disqualifiedModalOpen: true });
    } else {
      handleMarkProfileAsInMove({ profileId });
      const nextProfileId = getNextProfileId({
        forbiddenProfileIds: getProfilesInMove(),
      });

      if (nextProfileId) {
        await onChangeProfile(nextProfileId);
      }

      if (!nextProfileId) {
        const forbiddenProfileId = profileId;
        await onChangeStep({
          newStepId: stepId,
          forceRefresh: true,
          forbiddenProfileId,
          originalProfileId: profileId,
        });
      }

      changeProfileStep({
        id: profileId,
        step,
        oldStep: step,
        offerId,
      })
        .then(() => {
          const lastProfileAction = {
            clientId,
            jobOfferId: offerId,
            profileId,
            stepId,
            type: 'change-profile-step',
          };
          try {
            addProfileAction(lastProfileAction);
          } catch (e) {
            console.error(e);
          }
          handleUnmarkProfileAsInMove({ profileId });
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    }
  };

  disqualifiedModalClose = () => {
    this.setState({ disqualifiedModalOpen: false });
  };

  render() {
    const { loading, error, pipe, step, t } = this.props;
    if (loading) return <div />;
    if (error) return <div />;
    if (!pipe) return <div />;

    const cleanedPipe = _.filter(pipe, (step) => step.stepId !== 'pre-pending');
    const stepList = _.map(cleanedPipe, ({ stepId }) => ({
      text: t(`profile.steps.${stepId}`, stepId),
      value: stepId,
    }));
    const disqualifiedOption = {
      text: t('profile.steps.disqualified'),
      value: 'disqualified',
    };

    const stepOptionsWithoutPendingAndSkipped = _.filter(
      stepList,
      ({ value }) => value !== 'pending' && value !== 'skipped',
    );

    const stepOptions =
      _.findIndex(
        stepOptionsWithoutPendingAndSkipped,
        (o) => o.value === 'disqualified',
      ) < 0 && this.props.stepId !== 'pending'
        ? [...stepOptionsWithoutPendingAndSkipped, disqualifiedOption]
        : stepOptionsWithoutPendingAndSkipped;

    const currentStepOption = _.findWhere(stepList, { value: step });
    const stepTitle =
      currentStepOption && currentStepOption.text
        ? currentStepOption.text
        : t('profile.steps.state');
    return (
      <div className='profile-step-selector'>
        <ButtonDropdownMenu
          title={stepTitle}
          options={_.map(stepOptions, ({ text, value }) => ({
            label: text,
            id: value,
            disabled: value === step,
          }))}
          onSelect={(id) => this.handleChangeStep({ step: id })}
          position='right'
          primacy='secondary'
        />
        <DisqualifyModal
          offerId={this.props.offerId}
          profileId={this.props.profileId}
          getNextProfileId={this.props.getNextProfileId}
          getProfilesInMove={this.props.getProfilesInMove}
          onChangeProfile={this.props.onChangeProfile}
          handleMarkProfileAsInMove={this.props.handleMarkProfileAsInMove}
          handleUnmarkProfileAsInMove={this.props.handleUnmarkProfileAsInMove}
          onChangeStep={this.props.onChangeStep}
          stepId={this.props.stepId}
          searchText={this.props.searchText}
          open={this.state.disqualifiedModalOpen}
          onClose={this.disqualifiedModalClose}
        />
      </div>
    );
  }
}

export default compose(
  withChangeProfileStep,
  withOfferPipe,
  withLastProfileActionsContextConsumer,
  withTranslation('translations'),
)(ProfileStepButton);
