import React, { FC } from 'react';

import ChevronLeft from '@/components/Reveal/Icons/ChevronLeft';

import styles from './FilterTitle.module.less';

type FilterTitleProps = {
  onPrevious: () => void;
};

const FilterTitle: FC<FilterTitleProps> = ({ children, onPrevious }) => (
  <button type='button' className={styles.title} onClick={onPrevious}>
    <ChevronLeft />
    <span>{children}</span>
  </button>
);

export default FilterTitle;
