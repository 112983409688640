import _ from 'underscore';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';
import gql from 'graphql-tag';
import React from 'react';
import { User } from './useClientUsers';

interface FilterInput {
  key: string;
  descriptor: string;
}

const withoutFilterQuery = gql`
  query getClientNoFilterUsers($id: ID!) {
    client(id: $id) {
      id
      users {
        id
        firstname
        lastname
        email
        status
      }
    }
  }
`;

const query = gql`
  query getClientFilteredUsers($id: ID!, $filters: [UsersFilterInput!]) {
    client(id: $id) {
      id
      users(filters: $filters) {
        id
        firstname
        lastname
        email
        status
      }
    }
  }
`;

type UsersResult = { client: { users: User[] } };

const useFilteredClientUsers = (
  clientId: string,
  filters: FilterInput[],
  options: QueryHookOptions = {},
): QueryResult<UsersResult> & { users: User[] } => {
  const result = useQuery<UsersResult>(
    _.isEmpty(filters) ? withoutFilterQuery : query, 
  {
    variables: { 
      id: clientId, 
      ...!_.isEmpty(filters) && { filters }, 
    },
    // use network-only as a default, if
    // defined in options it will be overridden
    fetchPolicy: 'network-only',
    ...options,
  });

  const users = React.useMemo(() => {
    return result?.data?.client?.users || [];
    // eslint-disable-next-line
  }, [result?.data]);

  return {
    ...result,
    users,
  };
};

export default useFilteredClientUsers;
