import React, { useState } from 'react';
import { Popup } from 'semantic-ui-react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import FallbackSnippetPopup from '@/revealComponents/ProfileContactFlow/Sequences/SequenceDynamicVariables/components/FallbackSnippetPopup';
import styles from './Placeholder.module.less';

const locales = {
  en: 'EN',
  fr: 'FR',
};

const AutomationClassicPlaceholder = ({
  variable,
  children,
  readOnly,
  setIsReadOnly,
  offsetKey,
  onUpdate,
  onEditSmartVariable,
}) => {
  const { t } = useTranslation();
  const [open, setIsOpen] = useState(false);

  const handleOpen = (event) => {
    event.preventDefault();
    setIsReadOnly(true);
    setIsOpen(true);
  };

  const handleClose = (event) => {
    event.preventDefault();
    setIsReadOnly(false);
    setIsOpen(false);
  };

  const handleSubmit = (updatedSnippet) => {
    onUpdate({ updatedSnippet });
    setIsOpen(false);
    setIsReadOnly(false);
  };

  const handleEditSmartVariable = () => {
    onEditSmartVariable();
    setIsOpen(false);
    setIsReadOnly(false);
  };

  const span = (
    <span
      readOnly
      contentEditable={false}
      spellCheck={false}
      data-offset-key={offsetKey}
      className={styles.placeholder}
      style={{
        cursor: !readOnly ? 'pointer' : 'initial',
      }}
    >
      {variable?.fallbackValue?.text && (
        <img
          alt='arrow'
          src='/images/icons/fallback.svg'
          style={{ width: '14px' }}
        />
      )}
      {variable?.format && (
        <span className={styles.snippetInfo}>
          {t(`editor.durationVariable.format.${variable.format}`)}
        </span>
      )}
      {variable?.locale && (
        <span className={classNames(styles.snippetInfo, styles.margin)}>
          {locales[variable.locale]}
        </span>
      )}
      {children}
    </span>
  );

  if (!variable || readOnly) {
    return span;
  }

  return (
    <Popup
      open={open}
      style={{ padding: '0px' }}
      content={
        <FallbackSnippetPopup
          snippet={variable}
          onSubmit={handleSubmit}
          onEditSmartVariable={handleEditSmartVariable}
        />
      }
      on='click'
      trigger={span}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  );
};

export default AutomationClassicPlaceholder;
