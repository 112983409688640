import React from 'react';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Checkbox, Form } from 'semantic-ui-react';

import ReactivateOfferModal from '@/components/modals/ReactivateOfferModal';
import withArchiveOffer from '../../../../hocs/offers/withArchiveOffer';
import withUnArchiveOffer from '../../../../hocs/offers/withUnArchiveOffer';
import withHoldOffer from '../../../../hocs/offers/withHoldOffer';
import withUnHoldOffer from '../../../../hocs/offers/withUnHoldOffer';
import withHideOffer from '../../../../hocs/offers/withHideOffer';
import withUserFromJWToken from '../../../../hocs/users/withUserFromJWToken';
import withActionLogger from '../../../../hocs/withActionLogger';
import withEnableAutopilot from '../../../../hocs/offers/withEnableAutopilot';
import withDisableAutopilot from '../../../../hocs/offers/withDisableAutopilot';
import withUpdatePushFlowPolicy from '../../../../hocs/offers/withUpdatePushFlowPolicy';
import contextToProps from '../../../../hocs/contextToProps';
import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import ConfigureSenderModal from './ConfigureSenderModal';
import OfferIntegrationWorkflowsModal from '../../../../components/modals/OfferIntegrationWorkflowsModal';
import ImportCSVModal from '../../../../components/modals/ImportCSVModal';
import EditJobTitleModal from './EditJobTitleModal';
import OrganizeJobModal from './OrganizeJobModal';
import NewProfileModal from '../../../Profile/modals/newProfileModal';

class OfferStateModalWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      removeFollowups: false,
      reactivateSourcing: true,
    };
  }

  onOfferAction = ({ actionType, action, translateKey }) => {
    const {
      clientId,
      offer,
      onLogAction,
      user,
      onCloseModal,
      onShowNotification,
      t,
    } = this.props;
    if (offer.id) {
      try {
        onLogAction({
          type: actionType,
          info: {
            clientId,
            jobOfferId: offer.id,
            ...(user?.firstname &&
              user?.lastname && {
                author: `${user?.firstname} ${user?.lastname}`,
              }),
          },
        });
      } catch (e) {
        console.error(e);
      }
      action({ id: offer.id }).then(
        () =>
          onShowNotification({
            message: t(`offers.notifications.${translateKey}.success`),
            level: 'success',
          }),
        () =>
          onShowNotification({
            message: t(`offers.notifications.${translateKey}.error`),
            level: 'error',
          }),
      );
    }
    onCloseModal();
  };

  onArchive = () => {
    const { archiveOffer } = this.props;
    this.onOfferAction({
      actionType: 'archive-offer',
      action: ({ id }) => {
        return archiveOffer({
          id,
          removeFollowups: this.state.removeFollowups,
          onCompleted: () => window.location.reload(),
        });
      },
      translateKey: 'archiveOffer',
    });
  };

  onUnArchive = () => {
    const { unArchiveOffer } = this.props;
    this.onOfferAction({
      actionType: 'unarchive-offer',
      action: unArchiveOffer,
      translateKey: 'unArchiveOffer',
    });
  };

  onHold = () => {
    const { holdOffer } = this.props;
    const { removeFollowups } = this.state;
    this.onOfferAction({
      actionType: 'stop-offer-sourcing',
      action: ({ id }) => {
        return holdOffer({
          id,
          removeFollowups,
          onCompleted: () => window.location.reload(),
        });
      },
      translateKey: 'holdOffer',
    });
  };

  onUnHold = () => {
    const { unHoldOffer } = this.props;
    this.onOfferAction({
      actionType: 'reactivate-offer-sourcing',
      action: unHoldOffer,
      translateKey: 'unHoldOffer',
    });
  };

  onUpdatePushFlowPolicy = () => {
    const { updatePushFlowPolicy } = this.props;
    this.onOfferAction({
      actionType: 'update-offer-push-flow',
      action: updatePushFlowPolicy,
      translateKey: 'updatePushFlowPolicy',
    });
  };

  onEnableAutopilot = () => {
    const { enableAutopilot } = this.props;
    this.onOfferAction({
      actionType: 'enable-offer-autopilot',
      action: enableAutopilot,
      translateKey: 'enableAutopilot',
    });
  };

  onDisableAutopilot = () => {
    const { disableAutopilot } = this.props;
    this.onOfferAction({
      actionType: 'disable-offer-autopilot',
      action: disableAutopilot,
      translateKey: 'disableAutopilot',
    });
  };

  onHide = () => {
    const { hideOffer } = this.props;
    this.onOfferAction({
      actionType: 'hide-offer',
      action: hideOffer,
      translateKey: 'hideOffer',
    });
  };

  render() {
    const {
      offer,
      modalType,
      modalSubtype,
      onCloseModal,
      clientId,
      t,
    } = this.props;

    let header;
    let content;
    let onSubmit;
    const onCancel = onCloseModal;
    let submit;
    let cancel;
    let validationType = 'positive';
    switch (modalType) {
      case 'hide':
        header = t('offers.deleteOfferModal.header');
        content = t('offers.deleteOfferModal.subHeader');
        submit = t('offers.deleteOfferModal.confirm');
        cancel = t('offers.deleteOfferModal.cancel');
        onSubmit = this.onHide;
        validationType = 'negative';
        break;
      case 'archive':
        header = t('offers.archiveOfferModal.header');
        content = (
          <div>
            <div style={{ marginBottom: 10 }}>
              {(offer || {}).isHold
                ? t('offers.archiveOfferModal.subHeaderIfHold')
                : t('offers.archiveOfferModal.subHeaderIfNotHold')}
            </div>
            <div>
              <Checkbox
                label={t('offers.removeFollowups')}
                checked={this.state.removeFollowups}
                onChange={(e, { checked }) =>
                  this.setState({ removeFollowups: checked })
                }
              />
            </div>
          </div>
        );
        onSubmit = this.onArchive;
        submit = t('offers.archiveOfferModal.confirm');
        cancel = t('offers.archiveOfferModal.cancel');
        validationType = 'negative';
        break;
      case 'unarchive':
        header = t('offers.unArchiveOfferModal.header');
        content = (
          <div>
            <div style={{ marginBottom: 10 }}>
              {t('offers.unArchiveOfferModal.subHeader')}
            </div>
            <div>
              <Checkbox
                label={t('offers.unArchiveOfferModal.reactivateSourcing')}
                checked={this.state.reactivateSourcing}
                onChange={(e, { checked }) =>
                  this.setState({ reactivateSourcing: checked })
                }
              />
            </div>
          </div>
        );
        cancel = t('offers.unArchiveOfferModal.cancel');
        submit = t('offers.unArchiveOfferModal.unArchive');
        onSubmit = () => {
          this.onUnArchive();
          if (this.state.reactivateSourcing) {
            this.onUnHold();
          }
        };
        break;
      case 'hold':
        header = t('offers.holdOfferModal.header');
        content = (
          <div>
            <div style={{ marginBottom: 10 }}>
              {t('offers.holdOfferModal.subHeader')}
            </div>
            <Form.Field>
              <Checkbox
                label={t('offers.removeFollowups')}
                checked={this.state.removeFollowups}
                onChange={(e, { checked }) =>
                  this.setState({ removeFollowups: checked })
                }
              />
            </Form.Field>
          </div>
        );
        onSubmit = this.onHold;
        submit = t('offers.holdOfferModal.confirm');
        cancel = t('offers.holdOfferModal.cancel');
        validationType = 'negative';
        break;
      case 'unhold':
        header = t('offers.unHoldOfferModal.header');
        content = t('offers.unHoldOfferModal.subHeader');
        onSubmit = this.onUnHold;
        submit = t('offers.unHoldOfferModal.confirm');
        cancel = t('offers.unHoldOfferModal.cancel');
        return (
          <ReactivateOfferModal
            open
            offerId={offer.id}
            onClose={onCloseModal}
          />
        );
      case 'enableAutopilot':
        header = t('offers.enableAutopilotModal.header');
        content = t('offers.enableAutopilotModal.subHeader');
        onSubmit = this.onEnableAutopilot;
        submit = t('offers.enableAutopilotModal.confirm');
        cancel = t('offers.enableAutopilotModal.cancel');
        break;
      case 'disableAutopilot':
        header = t('offers.disableAutopilotModal.header');
        content = t('offers.disableAutopilotModal.subHeader');
        submit = t('offers.disableAutopilotModal.confirm');
        cancel = t('offers.disableAutopilotModal.cancel');
        onSubmit = this.onDisableAutopilot;
        validationType = 'negative';
        break;
      case 'pushFlowPolicyAugmentation':
        header = t('offers.pushFlowPolicyModal.header');
        content = t('offers.pushFlowPolicyModal.subHeader');
        submit = t('offers.pushFlowPolicyModal.confirm');
        cancel = t('offers.pushFlowPolicyModal.cancel');
        onSubmit = this.onUpdatePushFlowPolicy;
        break;
      case 'configureSender':
        return (
          <ConfigureSenderModal
            open
            offerId={offer.id}
            onClose={onCloseModal}
          />
        );
      case 'editTitle':
        return <EditJobTitleModal offer={offer} onClose={onCloseModal} />;
      case 'organizeJob':
        return (
          <OrganizeJobModal
            clientId={clientId}
            offer={offer}
            onClose={onCloseModal}
          />
        );
      case 'newProfile':
        return (
          <NewProfileModal clientId={clientId} open onClose={onCloseModal} />
        );
      case 'newProfilesFromCSV':
        return (
          <ImportCSVModal
            clientId={clientId}
            jobOfferId={offer.id}
            open
            onClose={onCloseModal}
          />
        );

      case 'integrationWorkflow':
        return (
          <OfferIntegrationWorkflowsModal
            open
            clientId={clientId}
            offerId={offer.id}
            onClose={onCloseModal}
            selectedIntegrationType={modalSubtype}
          />
        );
      default:
        // No modal pops-up
        return null;
    }
    return (
      <ConfirmationModal
        open
        onCancel={onCancel}
        cancel={cancel}
        onSubmit={onSubmit}
        submit={submit}
        header={header}
        content={content}
        validationType={validationType}
      />
    );
  }
}

export default compose(
  withUserFromJWToken,
  withActionLogger,
  withArchiveOffer,
  withUnArchiveOffer,
  withHoldOffer,
  withUnHoldOffer,
  withUpdatePushFlowPolicy,
  withEnableAutopilot,
  withDisableAutopilot,
  withHideOffer,
  contextToProps,
  withTranslation('translations'),
)(OfferStateModalWrapper);
