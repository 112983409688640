import React from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';

import baseSkillOptions from '@/common/options/skillOptions.json';
import revealSkillOptions from '@/common/options/revealSkillExtraOptions.json';

import industryOptions from '../../../../../common/options/industryOptions.json';
import locationOptions from '../../../../../common/options/revealLocationOptions.json';
import jobPositionOptions from '../../../../../common/options/jobPositionOptions.json';
import HoverPopup from '../HoverPopup';
import { MatchingLabel } from './components/MatchingLabels';
import {
  getBackgroundSolidityLabels,
  getLabelsFromItems,
  getSchoolPrestigeLabels,
  getStartupnessLabels,
  getDateLabel,
  getUserLabel,
  getTagsLabels,
  getApplicationLabels,
} from './helpers';
import MatchingDetailsPopup from './MatchingDetailsPopup';

import './MatchingDetails.css';

const skillOptions = [...baseSkillOptions, ...revealSkillOptions];

const MatchingDetails = ({ matchingDetails, atsExplanation, t }) => {
  // if (_.isEmpty(matchingDetails?.criteria)) {
  //   return (
  //     <div className='matching-details'>
  //       <MatchingScoreLabel className='main-score' score={score} />
  //     </div>
  //   );
  // }
  const { criteria } = matchingDetails || {};

  const allLabels = _.union(
    getSkillMatchingLabels({ skills: criteria?.skills }),
    getJobPositionMatchingLabels({ jobPosition: criteria?.jobPosition }),
    getExperienceLabels({ experience: criteria?.experience }),
    getLocationsLabels({ locations: criteria?.locations }),
    getIndustriesLabels({ industries: criteria?.industries }),
    getBackgroundSolidityLabels({
      backgroundSolidity: criteria?.backgroundSolidity,
    }),
    getSchoolPrestigeLabels({ schoolPrestige: criteria?.schoolPrestige }),
    getStartupnessLabels({
      startupnessImportance: criteria?.startupnessImportance,
    }),
    _.compact([
      getUserLabel({ user: atsExplanation?.recruiter }),
      getUserLabel({ user: atsExplanation?.coordinator }),
      getDateLabel({
        prefix: 'Created on ',
        dateStr: atsExplanation?.creationDate,
        t,
      }),
      getDateLabel({
        prefix: 'Last action on ',
        dateStr: atsExplanation?.lastActivityDate,
        t,
      }),
    ]),
    getTagsLabels({ tags: atsExplanation?.tags }),
    getApplicationsLabels({ applications: atsExplanation?.applications }),
  );

  const topLabels = _.sortBy(allLabels, ({ score }) => -score); // .slice(0, 4);

  const PopupContent =
    !_.isEmpty(matchingDetails) || !_.isEmpty(atsExplanation) ? (
      <MatchingDetailsPopup
        matchingDetails={matchingDetails}
        atsExplanation={atsExplanation}
      />
    ) : null;

  return (
    <HoverPopup content={PopupContent} delay={350}>
      <div className='matching-details'>
        {/* <MatchingScoreLabel className='main-score' score={matchingDetails?.score} /> */}

        <div className='matching-labels-container'>
          {_.map(topLabels, ({ label, score }) => (
            <MatchingLabel key={label} label={label} score={score} neutral />
          ))}
        </div>
      </div>
    </HoverPopup>
  );
};

const getSkillMatchingLabels = ({ skills }) => {
  const allSkills = _.compact([
    ...getSkills(skills?.main),
    ...getSkills(skills?.important),
    ...getSkills(skills?.bonus),
  ]);
  const topMatchingSkills = _.sortBy(allSkills, ({ score }) => -score); // .slice(0, 3);
  return getLabelsFromItems({
    items: topMatchingSkills,
    options: skillOptions,
    key: 'skillId',
  });
};

const getSkills = (category) => (category || [])[0]?.skills || [];

const getJobPositionMatchingLabels = ({ jobPosition }) => {
  const allJobs = _.compact([
    jobPosition?.major,
    ...(jobPosition?.minors || []),
  ]);
  const topMatchingJobs = _.sortBy(allJobs, ({ score }) => -score); // .slice(0, 3);
  return getLabelsFromItems({
    items: topMatchingJobs,
    options: jobPositionOptions,
    key: 'jobId',
  });
};

const getExperienceLabels = ({ experience }) => {
  if (!experience?.target?.validated && !experience?.okWith?.validated) {
    return [];
  }
  const nbYearsString = getNbYearsStr({ value: experience?.value });
  if (experience?.target?.validated) {
    return [
      {
        label: `Experience ${nbYearsString}`,
        score: 0.9,
      },
    ];
  }
  return [
    {
      label: `Experience ${nbYearsString}`,
      score: 0.6,
    },
  ];
};

const getLocationsLabels = ({ locations }) => {
  return getLabelsFromItems({
    items: locations?.target,
    options: locationOptions,
    key: 'locationId',
  });
};

const getIndustriesLabels = ({ industries }) => {
  return getLabelsFromItems({
    items: industries?.target,
    options: industryOptions,
    key: 'industryId',
  });
};

const getNbYearsStr = ({ value }) => {
  if (!_.isNumber(value)) {
    return null;
  }
  if (value < 1) {
    return '<1y';
  }
  if (Math.floor(value) === 1) {
    return '1y';
  }
  return `${Math.floor(value)}y`;
};

const getApplicationsLabels = ({ applications }) => {
  return _.compact(_.flatten(_.map(applications, getApplicationLabels)));
};

export default withTranslation('translations')(MatchingDetails);
