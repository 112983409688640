import React, { createRef } from 'react';
import NotificationSystem from 'react-notification-system';
import NotificationsContext from '@/context/NotificationSystem';

const withNotifications = (WrappedComponent) => {
  class ComposedComponent extends React.Component {
    constructor(props) {
      super(props);
      this.notificationSystem = createRef();
      // this.notificationSystemNetwork = null
      this.addNotification = this.addNotification.bind(this);
      this.clearNotifications = this.clearNotifications.bind(this);
      this.success = this.success.bind(this);
      this.error = this.error.bind(this);
    }

    addNotification({ message, level, type, autoDismiss }) {
      if (type === 'networkStatus') {
        // this.notificationSystemNetwork.addNotification({
        //   message:'Vous êtes actuellement hors ligne',
        //   level: 'error',
        //   position: 'tc',
        //   autoDismiss: 0
        // });
        this.notificationSystem.current?.addNotification({
          message: 'Vous êtes actuellement hors ligne',
          level: 'error',
          position: this.props.shiftSidebar ? 'bl' : 'br',
          autoDismiss: 0,
        });
      } else {
        this.notificationSystem.current?.addNotification({
          message,
          level,
          position: this.props.shiftSidebar ? 'bl' : 'br',
          ...(typeof autoDismiss === 'number' && { autoDismiss }),
        });
      }
    }

    success(message) {
      this.addNotification({ message, level: 'success' });
    }

    error(message) {
      this.addNotification({ message, level: 'error' });
    }

    clearNotifications() {
      // this.notificationSystemNetwork.clearNotifications();
      if (this.notificationSystem.current) {
        this.notificationSystem.current.clearNotifications();
      }
    }

    render() {
      return (
        <>
          <NotificationsContext.Provider
            value={{
              onClearNotifications: this.clearNotifications,
              onShowNotification: this.addNotification,
              success: this.success,
              error: this.error,
            }}
          >
            <WrappedComponent
              {...this.props}
              addNotification={this.addNotification}
              onShowNotification={this.addNotification}
              onClearNotifications={this.clearNotifications}
            />
          </NotificationsContext.Provider>
          <NotificationSystem ref={this.notificationSystem} style={style} />
        </>
      );
    }
  }
  return ComposedComponent;
};

const style = {
  Containers: {
    DefaultStyle: {
      padding: '0 15px 15px 15px',
      width: '320px',
    },
  },
  // tc: {
  //   top: '0px',
  //   bottom: 'auto',
  //   margin: '0 auto',
  //   left: '12.5%',
  // },
  // bl: {
  //   top: 'auto',
  //   bottom: '0px',
  //   left: '0px',
  //   right: 'auto'
  // },
  NotificationItem: {
    // Override the notification item
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      border: 'none',
      color: '#ffffff',
      padding: '15px',
    },

    success: {
      // Applied only to the success notification item
      backgroundColor: '#4864C9',
      WebkitBoxShadow: 'none',
      MozBoxShadow: 'none',
      boxShadow: 'none',
    },
    error: {
      // Applied only to the success notification item
      backgroundColor: '#F86E7F',
      WebkitBoxShadow: 'none',
      MozBoxShadow: 'none',
      boxShadow: 'none',
    },
  },
  Dismiss: {
    DefaultStyle: {
      backgroundColor: 'none',
      fontWeight: 'lighter',
      top: '16px',
      right: '10px',
    },
    success: {
      fontSize: '25px',
    },
    error: {
      fontSize: '0px',
    },
  },
};

export default withNotifications;
