import gql from 'graphql-tag';
import Workflow from '../../graphql/fragments/Workflow';


const getOfferWorkflows = gql`
    query getOfferWorkflows($id: ID!) {
        offer(id: $id) {
            id
            workflows {
                ...Workflow
            }
        }
    }
    ${Workflow}
`;

export default getOfferWorkflows;
