import React from 'react';
import { useTranslation } from 'react-i18next';
import useUserMarketplaceProfileDisplayPreferences, {
  MarketplaceGlobalSettings,
  useUpdateUserMarketplaceGobalProfileDisplayPreferences,
} from '@/graphql/hooks/users/UserProfileDisplayPreferences';
import GenericToggle from '@/components/Common/GenericToggle';
import DisplayPreferencesStyles from '../DisplayPreferences.module.less';
import ComplianceStyles from '../../RevealCompliance/RevealCompliance.module.less';

const MarketplaceDisplayPreferences: React.FC = () => {
  const { t } = useTranslation();
  const {
    userMarketplaceProfileDisplayPreferences,
  } = useUserMarketplaceProfileDisplayPreferences();

  const [
    updateUserDisplayPreferences,
  ] = useUpdateUserMarketplaceGobalProfileDisplayPreferences({});

  const {
    // We need to set default values in case this is the
    // first time the user visits this page
    hidePicture = false,
    hideFullName = false,
    hideEducationSchoolNames = false,
    hideEducationDates = false,
    hideEducationDescriptions = false,
  }: MarketplaceGlobalSettings = userMarketplaceProfileDisplayPreferences || {};

  const handleUserDisplayPreferenceChange = ({
    key,
    value,
  }: {
    key: keyof MarketplaceGlobalSettings;
    value: boolean;
  }) => {
    const {
      hidePicture: existingHidePicture = false,
      hideFullName: existingHideFullName = false,
      hideEducationSchoolNames: existingHideEducationSchoolNames = false,
      hideEducationDates: existingHideEducationDates = false,
      hideEducationDescriptions: existingHideEducationDescriptions = false,
    } = userMarketplaceProfileDisplayPreferences;

    // The server sometimes sends back null values, to prevent graphql errors
    // we set them to false by default
    const newPreferences: MarketplaceGlobalSettings = {
      hidePicture: existingHidePicture || false,
      hideFullName: existingHideFullName || false,
      hideEducationSchoolNames: existingHideEducationSchoolNames || false,
      hideEducationDates: existingHideEducationDates || false,
      hideEducationDescriptions: existingHideEducationDescriptions || false,
      // And set the changed value
      [key]: value,
    };
    updateUserDisplayPreferences(newPreferences);
  };

  return (
    <div className={`${ComplianceStyles.privacySection} settings`}>
      <div className='settings-header'>
        <h1>{t('settings.privacySettings.title')}</h1>
      </div>
      <h2>{t('settings.privacySettings.subtitle')}</h2>
      <div className={ComplianceStyles.background}>
        <p className={ComplianceStyles.paragraph}>
          {t('settings.privacySettings.description')}
        </p>
        <div className={DisplayPreferencesStyles.content}>
          <div className={DisplayPreferencesStyles.leftPan}>
            <p className={DisplayPreferencesStyles.panTitle}>
              {t('settings.privacySettings.leftPanTitle')}
            </p>
            <p className={DisplayPreferencesStyles.subTitle}>
              {t('settings.privacySettings.personalInformation')}
            </p>
            <GenericToggle<MarketplaceGlobalSettings>
              isChecked={hidePicture}
              name='hidePicture'
              label={t('settings.privacySettings.profilePicture')}
              onChange={handleUserDisplayPreferenceChange}
            />
            <GenericToggle<MarketplaceGlobalSettings>
              isChecked={hideFullName}
              name='hideFullName'
              label={t('settings.privacySettings.firstnameAndLastname')}
              onChange={handleUserDisplayPreferenceChange}
            />
            {/* Wait for backend to be ready */}
            {/* <p className={DisplayPreferencesStyles.subTitle}>
              {t('settings.privacySettings.marketplace.hiresweetInformation')}
            </p>
            <GenericToggle
              isChecked={false}
              name='hideSummary'
              label={t('settings.privacySettings.marketplace.resume')}
              onChange={handleUserDisplayPreferenceChange}
            /> */}
            <p className={DisplayPreferencesStyles.subTitle}>
              {t('settings.privacySettings.educationInformation')}
            </p>
            <GenericToggle<MarketplaceGlobalSettings>
              isChecked={hideEducationSchoolNames}
              name='hideEducationSchoolNames'
              label={t('settings.privacySettings.educationNames')}
              onChange={handleUserDisplayPreferenceChange}
            />
            <GenericToggle<MarketplaceGlobalSettings>
              isChecked={hideEducationDates}
              name='hideEducationDates'
              label={t('settings.privacySettings.educationDates')}
              onChange={handleUserDisplayPreferenceChange}
            />
            <GenericToggle<MarketplaceGlobalSettings>
              isChecked={hideEducationDescriptions}
              name='hideEducationDescriptions'
              label={t('settings.privacySettings.educationDescriptions')}
              onChange={handleUserDisplayPreferenceChange}
            />
          </div>
          <div className={DisplayPreferencesStyles.rightPan} />
        </div>
      </div>
    </div>
  );
};

export default MarketplaceDisplayPreferences;
