/* eslint-disable import/prefer-default-export */
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';

/**
 * Get valid custom fields for import column mapping
 */
export function getCustomFieldsForColumnMapping(
  customFields?: CustomFieldDefinition[],
): CustomFieldDefinition[] {
  const VALID: CustomFieldDefinition['type'][] = [
    'inline-text',
    'text',
    'enum',
    'float',
    'integer',
    'day',
  ];
  return (customFields || []).filter(({ type }) => VALID.includes(type));
}
