import { QueryHookOptions, QueryResult, useLazyQuery } from '@apollo/client';

import { GET_CLIENT_DAILY_EVENTS_DETAILS } from '@/graphql/statistics';
import { Author } from '@/types/author';

interface DailyEventDetails {
  day: string;
  userStats: {
    author: Author;
    stats: {
      type: string;
      profileItems: {
        profileId: string;
      }[];
    }[];
  }[];
}

interface GetClientDailyEventDetailsData {
  client: {
    id: string;
    statistics: {
      sequences: {
        dailyEvents: DailyEventDetails[];
      };
    };
  };
}

interface GetClientDailyEventDetailsVariables {
  clientId: string;
  sequenceIds?: string[];
  userEmails?: string[];
}

interface UseClientSequencesDailyEventDetailsInput {
  queryOptions?: QueryHookOptions<
    GetClientDailyEventDetailsData,
    GetClientDailyEventDetailsVariables
  >;
}

type UsePipelineStatisticsByCategoryDetailsResult = [
  (variables: GetClientDailyEventDetailsVariables) => void,
  QueryResult<
    GetClientDailyEventDetailsData,
    GetClientDailyEventDetailsVariables
  > & {
    dailyEvents?: DailyEventDetails[];
  },
];

const useClientDailyEventsDetails = (
  input?: UseClientSequencesDailyEventDetailsInput,
): UsePipelineStatisticsByCategoryDetailsResult => {
  const { queryOptions } = input || {};

  const [fetchDetails, query] = useLazyQuery<
    GetClientDailyEventDetailsData,
    GetClientDailyEventDetailsVariables
  >(GET_CLIENT_DAILY_EVENTS_DETAILS, {
    ...(queryOptions || {}),
  });

  return [
    (variables) => fetchDetails({ variables }),
    {
      ...query,
      dailyEvents: query?.data?.client?.statistics?.sequences?.dailyEvents,
    },
  ];
};

export default useClientDailyEventsDetails;
