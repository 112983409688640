import React from 'react';
import EmailAPI from '../../context/EmailAPI';

export default (WrappedComponent) => {
  return function WrapperComponent(props) {
    const { user, clientId, refetchUser } = props;
    return (
      <EmailAPI user={user} clientId={clientId} refetchUser={refetchUser}>
        <WrappedComponent {...props} />
      </EmailAPI>
    );
  }
};

