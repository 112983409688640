import {
  HAS_ANY_KEYWORDS,
  HAS_KEYWORDS,
  HAS_NONE_OF_KEYWORDS,
} from './constants';
import {
  ContactOperator,
  DateOperator,
  DiversityOperator,
  EnumOperator,
  KeywordsOperator,
  NumberOperator,
  StringOperator,
} from './types';

const ENUM_OPERATOR_OPTIONS: {
  operator: EnumOperator;
  translation: string;
  withInput?: boolean;
}[] = [
  {
    operator: 'is_in',
    translation: 'reveal.searchView.search.isIn',
    withInput: true,
  },
  {
    operator: 'is_not_in',
    translation: 'reveal.searchView.search.isNotIn',
    withInput: true,
  },
  {
    operator: 'not_has_value',
    translation: 'reveal.searchView.search.unknown',
    withInput: false,
  },
  {
    operator: 'has_value',
    translation: 'reveal.searchView.search.known',
    withInput: false,
  },
];

const DATE_OPERATOR_OPTIONS: {
  operator: DateOperator;
  translation: string;
  withInput?: boolean;
}[] = [
  {
    operator: 'relative_greater',
    translation: 'reveal.searchView.search.dateRelativeMore',
    withInput: true,
  },
  {
    operator: 'relative_lesser',
    translation: 'reveal.searchView.search.dateRelativeLess',
    withInput: true,
  },
  {
    operator: 'greater',
    translation: 'reveal.searchView.search.dateAbsoluteAfter',
    withInput: true,
  },
  {
    operator: 'lesser',
    translation: 'reveal.searchView.search.dateAbsoluteBefore',
    withInput: true,
  },
  {
    operator: 'has_value',
    translation: 'reveal.searchView.search.known',
    withInput: false,
  },
  {
    operator: 'not_has_value',
    translation: 'reveal.searchView.search.unknown',
    withInput: false,
  },
];

const NUMBER_OPERATOR_OPTIONS: {
  operator: NumberOperator;
  translation: string;
  withInput?: boolean;
}[] = [
  {
    operator: 'greater',
    translation: 'reveal.searchView.search.greater',
    withInput: true,
  },
  {
    operator: 'lesser',
    translation: 'reveal.searchView.search.lesser',
    withInput: true,
  },
  {
    operator: 'is',
    translation: 'reveal.searchView.search.is',
    withInput: true,
  },
  {
    operator: 'is_not',
    translation: 'reveal.searchView.search.isNot',
    withInput: true,
  },
  {
    operator: 'not_has_value',
    translation: 'reveal.searchView.search.unknown',
    withInput: false,
  },
  {
    operator: 'has_value',
    translation: 'reveal.searchView.search.known',
    withInput: false,
  },
];

const STRING_OPERATOR_OPTIONS: {
  operator: StringOperator;
  translation: string;
  withInput?: boolean;
}[] = [
  {
    operator: 'is',
    translation: 'reveal.searchView.search.is',
    withInput: true,
  },
  {
    operator: 'is_not',
    translation: 'reveal.searchView.search.isNot',
    withInput: true,
  },
  {
    operator: 'start_with',
    translation: 'reveal.searchView.search.startWith',
    withInput: true,
  },
  {
    operator: 'end_with',
    translation: 'reveal.searchView.search.endWith',
    withInput: true,
  },
  {
    operator: 'include',
    translation: 'reveal.searchView.search.contain',
    withInput: true,
  },
  {
    operator: 'not_include',
    translation: 'reveal.searchView.search.doesNotContain',
    withInput: true,
  },
  {
    operator: 'not_has_value',
    translation: 'reveal.searchView.search.unknown',
    withInput: false,
  },
  {
    operator: 'has_value',
    translation: 'reveal.searchView.search.known',
    withInput: false,
  },
];

const CONTACT_OPERATOR_OPTIONS: {
  operator: ContactOperator;
  translation: string;
}[] = [
  {
    operator: 'any-filled',
    translation: 'reveal.searchView.search.anyFilled',
  },
  {
    operator: 'all-filled',
    translation: 'reveal.searchView.search.allFilled',
  },
  {
    operator: 'any-missing',
    translation: 'reveal.searchView.search.anyMissing',
  },
  {
    operator: 'all-missing',
    translation: 'reveal.searchView.search.allMissing',
  },
];

const DIVERSITY_OPERATOR_OPTIONS: {
  operator: DiversityOperator;
  translation: string;
}[] = [
  {
    operator: 'firstname_is_likely',
    translation: 'reveal.searchView.search.firstnameIsLikely',
  },
];

const KEYWORDS_OPERATOR_OPTIONS: {
  operator: KeywordsOperator;
  translation: string;
  withInput?: boolean;
}[] = [
  {
    operator: HAS_ANY_KEYWORDS,
    translation: 'reveal.searchView.search.keywords.anyOf',
    withInput: true,
  },
  {
    operator: HAS_KEYWORDS,
    translation: 'reveal.searchView.search.keywords.allOf',
    withInput: true,
  },
  {
    operator: HAS_NONE_OF_KEYWORDS,
    translation: 'reveal.searchView.search.keywords.noneOf',
    withInput: true,
  },
];

export const OPERATORS_OPTION = {
  'inline-text': STRING_OPERATOR_OPTIONS,
  text: STRING_OPERATOR_OPTIONS,
  float: NUMBER_OPERATOR_OPTIONS,
  integer: NUMBER_OPERATOR_OPTIONS,
  day: DATE_OPERATOR_OPTIONS,
  enum: ENUM_OPERATOR_OPTIONS,
  contact: CONTACT_OPERATOR_OPTIONS,
  diversity: DIVERSITY_OPERATOR_OPTIONS,
  keywords: KEYWORDS_OPERATOR_OPTIONS,
};

export default OPERATORS_OPTION;
