import gql from 'graphql-tag';
import TimelineItem from './TimelineItem';

export default gql`
  fragment ContactData on ProfileContactData {
    creationTimestamp
    defaultThreadId
    defaultSubject
    timeline {
      ...TimelineItem
    }
    threads {
      id
      type
      mailAccountId
      mailAccountType
      lastMessageTimestamp
      bouncingEmails
    }
    draftComment
    assignedSender {
      email
      alias
    }
  }
  ${TimelineItem}
`;
