import _ from 'underscore';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AtsData } from '@/common/reveal/SourceData.type';
import { LEVER } from '@/common/reveal';

interface LeverReviewsSummaryProps {
  isHired: boolean;
  atsId: string;
  data?: AtsData;
}

const LeverReviewsSummary: FC<LeverReviewsSummaryProps> = ({
  data,
  atsId,
  isHired,
}) => {
  const { t } = useTranslation();

  if (atsId !== LEVER) {
    return null;
  }

  if (isHired) {
    return null;
  }

  const notes = _.filter(
    data?.activityFeed || [],
    ({ type }) => type === 'note',
  );

  const re = new RegExp(
    /^(?:([1-4]) \/ 4\s+\((?:Strong )?(?:No )?Hire\))$/,
    'gm',
  );

  const reviews = _.compact(
    _.map(notes, ({ body, user, creationDate, subject, sourceActivityId }) => {
      if (!body || !subject || !user) {
        return null;
      }
      const match = re.exec(body);
      if (!match || _.isEmpty(match)) {
        return null;
      }
      return {
        sourceActivityId,
        review: match[1],
        user,
        creationDate,
        subject,
      };
    }),
  );

  if (_.isEmpty(reviews)) {
    return null;
  }

  return (
    <div className='row'>
      <div className='field-name'>
        <i className='ri-briefcase-4-line ri-lg' />
        <div className='tag-content-label'>Feedback</div>
      </div>
      <div className='jobs-column'>
        {_.map(
          reviews,
          ({ sourceActivityId, review, user, creationDate, subject }) => {
            const userFullname = `${user?.firstname || ''} ${user?.lastname ||
              ''}`.trim();
            return (
              <div
                className='tag'
                style={{ marginBottom: '0px', marginTop: '3px' }}
                key={sourceActivityId}
              >
                <div className='tag-content'>
                  <div className='tag-content-text'>
                    {review} / 4 - {subject}
                  </div>
                  <span className='tag-content-status'>{userFullname}</span>
                  {creationDate && (
                    <span className='tag-content-date'>
                      {t('common.simpleDate', { date: new Date(creationDate) })}
                    </span>
                  )}
                </div>
              </div>
            );
          },
        )}
      </div>
    </div>
  );
};

export default LeverReviewsSummary;
