import React, { useMemo, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import _ from 'underscore';
import SettingsLayout from '@/containers/SettingsLayout';
import useCreateSnippet from '@/graphql/hooks/snippets/useCreateSnippet';
import useClientSnippets from '@/graphql/hooks/snippets/useClientSnippets';
import SnippetsSettingsTable from '@/containers/Parameters/SnippetsSettings/components/table/SnippetsSettingsTable';
import useDeleteSnippet from '@/graphql/hooks/snippets/useDeleteSnippet';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import useEditSnippet from '@/graphql/hooks/snippets/useEditSnippet';
import { MergeTagsVariable, SNIPPET_TYPES } from '@/common/mergeTags/utils';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import Plus from '@/components/Reveal/Icons/Plus';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { useClientAssistantDescriptors } from '@/common/mergeTags/useAssistantDescriptors';
import createSnippetInput from './utils/createSnippetInput';
import SnippetSettingsForm from './components/form/SnippetSettingsForm';

import styles from './SnippetsSettings.module.less';

type Props = {
  clientId: string;
};

const SnippetsSettings: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();
  const [addSnippetModalOpen, setAddSnippetModalOpen] = useState(false);
  const [isFullScreenModal, setIsFullScreenModal] = useState(false);
  const [editionMode, setEditionMode] = useState<{
    isOpen: boolean;
    snippet: MergeTagsVariable | null;
  }>({
    isOpen: false,
    snippet: null,
  });
  const [deletionMode, setDeletionMode] = useState<{
    isOpen: boolean;
    snippetId: string | null;
  }>({
    isOpen: false,
    snippetId: null,
  });

  const notification = useNotificationSystem();
  const [createSnippetMutation] = useCreateSnippet();
  const [editSnippetMutation] = useEditSnippet();
  const [deleteSnippetMutation] = useDeleteSnippet();
  const { data: clientSnippets } = useClientSnippets();
  const { assistantDescriptors } = useClientAssistantDescriptors();

  const snippetsWithAssistantDescriptors = useMemo(() => {
    return _.map(clientSnippets?.snippets || [], (snippet) => ({
      ...snippet,
      ...(snippet.type === SNIPPET_TYPES.AI_TOKEN && {
        text:
          _.findWhere(assistantDescriptors, { id: snippet.text })?.text || '',
        options:
          _.findWhere(assistantDescriptors, { id: snippet.text })?.options ||
          [],
      }),
    }));
  }, [assistantDescriptors, clientSnippets?.snippets]);

  const addSnippet = async (variable: MergeTagsVariable) => {
    if (!variable.name) {
      return;
    }
    try {
      const snippetsInput = createSnippetInput({
        variable: _.omit(variable, 'id') as MergeTagsVariable,
      });
      await createSnippetMutation({
        variables: {
          input: {
            snippet: {
              ...snippetsInput,
            },
          },
        },
      });
      setAddSnippetModalOpen(false);

      notification.success(t('common.genericSuccess'));
    } catch (error) {
      console.error(error);
      notification.success(t('common.genericError'));
    }
  };

  const editSnippet = async (variable: MergeTagsVariable) => {
    try {
      const snippetsInput = createSnippetInput({ variable });
      await editSnippetMutation({
        variables: {
          input: {
            snippet: {
              ...snippetsInput,
            },
          },
        },
      });
      setEditionMode({
        isOpen: false,
        snippet: null,
      });
      notification.success(t('common.genericSuccess'));
    } catch (error) {
      console.error(error);
      notification.success(t('common.genericError'));
    }
  };

  const deleteSnippet = async () => {
    try {
      await deleteSnippetMutation({
        variables: {
          snippetId: deletionMode.snippetId,
        },
      });
      setDeletionMode({ isOpen: false, snippetId: null });
      notification.success(t('common.genericSuccess'));
    } catch (error) {
      console.error(error);
      notification.success(t('common.genericError'));
    }
  };

  const openEditMode = (snippet: MergeTagsVariable) => {
    setEditionMode({
      isOpen: true,
      snippet,
    });
  };

  const openDeleteMode = (snippetId: string) => {
    setDeletionMode({
      isOpen: true,
      snippetId,
    });
  };

  return (
    <SettingsLayout
      currentPage='snippets'
      clientId={clientId}
      className='settings-container'
      size='big'
    >
      <div className='settings'>
        <div className={styles.header}>
          <h1 className={styles.title}>
            {t('reveal.parameters.snippetSettings.title')}
          </h1>
        </div>
        <h2>{t('settings.configuration')}</h2>
        <div className='section visible'>
          <div className={styles.description}>
            {t('reveal.parameters.snippetSettings.description')}
          </div>
          <SnippetsSettingsTable
            snippets={snippetsWithAssistantDescriptors}
            onEdit={openEditMode}
            onDelete={openDeleteMode}
          />
          <div className='input-container'>
            <GenericButton onClick={() => setAddSnippetModalOpen(true)}>
              <Plus />
              {t('reveal.parameters.snippetSettings.create')}
            </GenericButton>
          </div>
        </div>
      </div>
      <GenericModal
        open={addSnippetModalOpen}
        onOpen={() => setAddSnippetModalOpen(true)}
        size={isFullScreenModal ? 'fullscreen' : 'large'}
      >
        <Modal.Header>
          {t('reveal.parameters.snippetSettings.creationModal.title')}
        </Modal.Header>
        <Modal.Content image>
          <SnippetSettingsForm
            onSubmit={addSnippet}
            setOpen={setAddSnippetModalOpen}
            setIsFullScreenModal={setIsFullScreenModal}
          />
        </Modal.Content>
      </GenericModal>
      <GenericModal
        open={editionMode.isOpen}
        onOpen={() =>
          setEditionMode((prevState) => ({ ...prevState, isOpen: false }))
        }
        size={
          editionMode.snippet?.type === SNIPPET_TYPES.CONDITIONS_CHAINING
            ? 'fullscreen'
            : 'small'
        }
      >
        <Modal.Header>
          {t('reveal.parameters.snippetSettings.edit')}
        </Modal.Header>
        <Modal.Content image>
          <SnippetSettingsForm
            onSubmit={editSnippet}
            setIsFullScreenModal={setIsFullScreenModal}
            setOpen={(value) =>
              setEditionMode((prevState) => ({
                ...prevState,
                isOpen: value,
              }))
            }
            defaultValues={editionMode.snippet || undefined}
          />
        </Modal.Content>
      </GenericModal>
      <ConfirmationModal
        header={t('reveal.parameters.snippetSettings.delete')}
        submit={t('common.delete')}
        onSubmit={deleteSnippet}
        open={deletionMode.isOpen}
        onCancel={() =>
          setDeletionMode((prevState) => ({ ...prevState, isOpen: false }))
        }
      />
    </SettingsLayout>
  );
};

export default SnippetsSettings;
