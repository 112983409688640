import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

const updateProfileMissionComment = gql`
  mutation UpdateProfileMissionStateComment(
    $input: UpdateProfileMissionStateCommentInput!
    $searchPoolId: ID!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      updateProfileMissionStateComment(input: $input) {
        profile {
          id
          missionsInfo {
            missionId
            insertionDate
            data {
              archived
              comment
              segmentationStates {
                segmentationId
                state
                interestedStepId
              }
            }
          }
        }
      }
    }
  }
`;

const useUpdateProfileMissionComment = () => {
  return useMutation(updateProfileMissionComment);
};

export default useUpdateProfileMissionComment;
