import _ from 'underscore';
import {
  ATStype,
  ConnectorRejectionReason,
  RevealProject,
} from '@/common/reveal';
import { getTranslatedText, getRandomString } from '@/common';

export type Option = { value: string; text: string };

type ApplicationStatusOption = {
  id: string;
  name: string;
  sourceId?: string;
  subOptions?: { id: string; name?: string }[];
};

export type FilterOptions = {
  userOptions: Option[];
  tagOptions: Option[];
  applicationStatusOptions: ApplicationStatusOption[];
  stageOptions: Option[];
  jobOptions: Option[];
};

const getFilterOptions = ({
  connectorType,
  projectId,
  projectsWithFilterOptions,
  getApplicationStatusOptions,
}: {
  connectorType: ATStype;
  projectId: string;
  projectsWithFilterOptions: RevealProject[];
  getApplicationStatusOptions: (
    rejectionReasons?: ConnectorRejectionReason[],
  ) => ApplicationStatusOption[];
}): FilterOptions => {
  const project = _.findWhere(projectsWithFilterOptions, { id: projectId });
  const connector = _.findWhere(project?.connectors || [], {
    type: connectorType,
  });
  const options = connector?.filterOptions;

  const userOptions = _.map(
    getUserOptions(options?.users || []),
    ({ id, firstname, lastname }) => ({
      value: id,
      text: `${firstname || ''} ${lastname || ''}`,
    }),
  );

  const archivedTranslated = getTranslatedText({
    default: 'Archived',
    fr: 'Archivé',
  });
  const jobOptions = _.map(options?.jobs || [], ({ id, name, isArchived }) => ({
    value: id,
    text: isArchived ? `${name || ''} (${archivedTranslated})` : name || '',
  }));

  const stageOptions = _.map(
    _.sortBy(options?.stageNames || [], 'name'),
    ({ id, name }) => ({
      value: id,
      text: name || '',
    }),
  );

  const tagOptions = _.map(options?.tags || [], ({ id, name }) => ({
    value: id,
    key: `tag_${getRandomString(10)}`,
    text: getTranslatedText(name),
  }));
  const sortedTagOptions = _.sortBy(tagOptions, ({ text }) =>
    (text || '').toLowerCase(),
  );

  return {
    userOptions,
    tagOptions: sortedTagOptions,
    applicationStatusOptions: getApplicationStatusOptions(
      options?.rejectionReasons,
    ),
    stageOptions,
    jobOptions,
  };
};

const getUserOptions = (
  users: { id: string; firstname?: string; lastname?: string }[],
) => {
  if (_.isEmpty(users)) {
    return [];
  }
  const filteredUsers = _.filter(
    users,
    ({ firstname, lastname }) => !!(firstname || lastname),
  );
  return filteredUsers.sort((a, b) => {
    const aText = `${a.firstname ? a.firstname : ''}${
      a.lastname ? a.lastname : ''
    }`;
    const bText = `${b.firstname ? b.firstname : ''}${
      b.lastname ? b.lastname : ''
    }`;
    return aText.localeCompare(bText);
  });
};

export default getFilterOptions;
