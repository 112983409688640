import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import useClientId from '@/hooks/router/useClientId';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';
import NewSequenceButton from './NewSequenceButton';
import NewTemplateButton from './NewTemplateButton';
import NewSequencesFolderButton from './NewSequencesFolderButton';
import NewSequenceTypeModal from '../../revealComponents/Modals/NewSequenceTypeModal';
import { AutomationTab } from '../types';

import styles from './SubHeader.module.less';

interface SubHeaderProps {
  tab: AutomationTab;
}

const SubHeader: React.FC<SubHeaderProps> = ({ tab }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [sequenceTypeModalOpen, setSequenceTypeModalOpen] = useState(false);
  const clientId = useClientId();

  const { permissions } = useClientPermissions(clientId);

  return (
    <div className={styles.subHeader}>
      <ul className={styles.navigation}>
        <li className={styles.tab}>
          <Link
            to={`/client/${clientId}/reveal/sequences`}
            className={`${styles.tabButton} ${tab === 'sequences' &&
              styles.active}`}
          >
            {t('header.parameters.sequences', { count: 2 })}
          </Link>
        </li>
        <li className={styles.tab}>
          <Link
            to={`/client/${clientId}/reveal/templates`}
            className={`${styles.tabButton} ${tab === 'templates' &&
              styles.active}`}
          >
            {t('header.parameters.templates')}
          </Link>
        </li>
        {permissions?.crmMarketing && (
          <li className={styles.tab}>
            <Link
              to={`/client/${clientId}/reveal/campaigns`}
              className={`${styles.tabButton} ${tab === 'campaigns' &&
                styles.active}`}
            >
              {t('header.parameters.campaigns', { count: 2 })}
            </Link>
          </li>
        )}
      </ul>
      <div className={styles.sequencesButtons}>
        {tab === 'sequences' && (
          <>
            <NewSequencesFolderButton />
            <NewSequenceButton
              onOpen={() => {
                if (permissions?.aiSequenceGenerationSidebar) {
                  setSequenceTypeModalOpen(true);
                  return;
                }

                history.push(
                  `/client/${clientId}/reveal/sequences/new-sequence-preview`,
                );
              }}
            />
          </>
        )}
        {tab === 'templates' && <NewTemplateButton />}
        {tab === 'campaigns' && (
          <Link
            to={`/client/${clientId}/reveal/campaigns/new-campaign-preview`}
          >
            <GenericButton size='big' primacy='primary'>
              <Plus />
              {t('campaigns.newCampaign')}
            </GenericButton>
          </Link>
        )}
      </div>
      <NewSequenceTypeModal
        open={sequenceTypeModalOpen}
        onClose={() => setSequenceTypeModalOpen(false)}
        clientId={clientId}
      />
    </div>
  );
};

export default SubHeader;
