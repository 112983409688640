import useClientId from '@/hooks/router/useClientId';
import useClientPermissions from './useClientPermissions';
import useUserFromJWToken from '../users/useUserFromJWToken';

const useCustomArchiveReasonsPermission = (): boolean => {
  const clientId = useClientId();
  const permissions = useClientPermissions(clientId).data?.client?.permissions;
  const { data: userData } = useUserFromJWToken();

  const userPermission =
    userData?.user?.permissions?.canEditCustomArchiveReasons;
  return userPermission || !!permissions?.allUsersCanEditCustomArchiveReasons;
};

export default useCustomArchiveReasonsPermission;
