import React from 'react';
import _ from 'underscore';
import './ProfileViews.css';

interface ProfileViewsProps {
  profile: any;
}

const ProfileViews: React.FC<ProfileViewsProps> = ({ profile }) => {
  const views = profile?.views?.timeline
    ? [...profile.views.timeline]?.sort(
        (a: any, b: any) => b.timestamp - a.timestamp,
      )
    : [];
  if (_.isEmpty(views)) {
    return <></>;
  }

  return <></>;

  /*
  return (
    <Popup
      basic
      position='bottom center'
      hoverable
      className='profileViews-popup'
      pinned
      disabled={!openable}
      on='click'
      trigger={
        <span className={`pill-message blue ${isPlugin && 'plugin'}`}>
          <i className='ri-eye-line ri-lg' />
          {t('common.profileViews', { count: views.length })}
        </span>
      }
      content={
        <>
          <div className='view-header-row'>
            <div className='title'>
              {t('common.profileViews', { count: views.length })}
            </div>
          </div>
          {_.map(views, (view) => (
            <div className='view-row' key={view.timestamp}>
              <Image
                className='view-author-image'
                src={
                  view.author.photoLink
                    ? getSecureLink(view.author.photoLink)
                    : getRandomDefaultAvatarLink(
                        view.author.firstname + view.author.lastname,
                      )
                }
                onError={(e: any) => {
                  e.target.src = getRandomDefaultAvatarLink(
                    view.author.firstname + view.author.lastname,
                  );
                }}
              />
              <div className='name'>{view.author.firstname}</div>
              <div className='date'>
                {t('common.formattedDate', { date: new Date(view.date) })}
              </div>
            </div>
          ))}
        </>
      }
      style={{
        maxHeight: '300px',
        minWidth: '300px',
        overflowY: 'auto',
        marginLeft: `-0%`,
      }}
    />
  );
  */
};

export default ProfileViews;
