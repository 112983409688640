import _ from 'underscore';
import useClientId from '@/hooks/router/useClientId';
import {
  HiresweetLibProvider,
  TasksSetContainer,
  TasksTable,
  TaskTargetFirstname,
  TaskTargetLastname,
  TaskTargetEmail,
  TaskTargetHeadline,
  TaskTargetSequenceTitle,
  TaskCategory,
  TaskTargetPhoneNumber,
  TaskTargetLastInteraction,
} from '@hiresweet/hiresweet-lib';
import React from 'react';





const PlaygroundT4 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <HiresweetLibProvider theme={theme} clientId={clientId}>
      <h1>P-T-4 - Tasks table</h1>
      <TasksSetContainer filter={{}}>
        <TasksTable pageSize={10}>
          <TasksTable.Header className=''>
            <TasksTable.HeaderCell className=''>Contact</TasksTable.HeaderCell>
            <TasksTable.HeaderCell className=''>E-mail</TasksTable.HeaderCell>
            <TasksTable.HeaderCell className=''>Téléphone</TasksTable.HeaderCell>
            <TasksTable.HeaderCell className=''>Séquence</TasksTable.HeaderCell>
            <TasksTable.HeaderCell className=''>Type de tâche</TasksTable.HeaderCell>
            <TasksTable.HeaderCell className=''>Dernière interaction</TasksTable.HeaderCell>
          </TasksTable.Header>
          <TasksTable.Body>
            <TasksTable.RowMap>
              
              <TasksTable.Cell className=''>
                <TaskTargetFirstname /> <TaskTargetLastname /><br/>
                <TaskTargetHeadline />
              </TasksTable.Cell>

              <TasksTable.Cell className=''><TaskTargetEmail /></TasksTable.Cell>

              <TasksTable.Cell className=''><TaskTargetPhoneNumber /></TasksTable.Cell>
              
              <TasksTable.Cell className=''><TaskTargetSequenceTitle /></TasksTable.Cell>

              <TasksTable.Cell className=''><TaskCategory /></TasksTable.Cell>

              <TasksTable.Cell className=''><TaskTargetLastInteraction /></TasksTable.Cell>
            </TasksTable.RowMap>
          </TasksTable.Body>
        </TasksTable>
      </TasksSetContainer>
    </HiresweetLibProvider>
  );
};


export default PlaygroundT4;
