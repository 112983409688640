import gql from 'graphql-tag';

export const GET_SEQUENCES_FOLDERS = gql`
  query getClientSequencesFolders($clientId: ID!) {
    client(id: $clientId) {
      id
      sequencesFolders {
        id
        title
        author {
          firstname
          lastname
        }
        creationDate
        lastEditionDate
      }
    }
  }
`;

export const CREATE_SEQUENCES_FOLDERS = gql`
  mutation CreateSequencesFolder($input: CreateSequencesFolderInput!) {
    sequencesFolder {
      create(input: $input) {
        sequencesFolder {
          title
        }
      }
    }
  }
`;

export const DELETE_SEQUENCES_FOLDERS = gql`
  mutation deleteSequencesFolder($input: DeleteSequencesFolderInput!) {
    sequencesFolder {
      delete(input: $input) {
        id
      }
    }
  }
`;

export const RENAME_SEQUENCES_FOLDER = gql`
  mutation renamteSequencesFolder($input: RenameSequencesFolderInput!) {
    sequencesFolder {
      rename(input: $input) {
        id
        title
      }
    }
  }
`;
