import React, { FC, useContext, useMemo } from 'react';
import _ from 'underscore';

import useClientMissionsDailyEvents from '@/graphql/hooks/clients/useClientMissionsDailyEvents';
import { SecondaryBlue } from '@/less/colors';
import { useUserAllowedDepartments } from '@/graphql/hooks/users/useUserAllowedDepartments';
import useMissionsWithFoldering from '@/graphql/hooks/searchPoolJobs/useMissionsWithFoldering';

import StatisticsParametersContext from '../../../StatisticsParametersContext';
import { coerceRemovalReason } from '../MissionRemovals/helpers';
import HorizontalStackedBarChart from '../../../components/HorizontalStackedBarChart';

const MissionRemovalReasons: FC = () => {
  const [{ missionIds, timeSpan }] = useContext(StatisticsParametersContext);
  const { subsections } = useUserAllowedDepartments();
  const { missionsWithFoldering } = useMissionsWithFoldering();

  const subsectionIdMap = useMemo(() => {
    const result = {} as Record<string, boolean>;
    _.each(subsections, ({ id }) => {
      result[id] = true;
    });
    return result;
  }, [subsections]);

  const availableMissionIds = useMemo(() => {
    const result = [] as string[];
    _.each(missionsWithFoldering, ({ id, foldering }) => {
      if (
        foldering?.subsection?.id &&
        subsectionIdMap[foldering.subsection.id]
      ) {
        result.push(id);
      }
    });
    return result;
  }, [missionsWithFoldering, subsectionIdMap]);

  const sentMissionIds = useMemo(
    () => (_.isEmpty(missionIds) ? availableMissionIds : missionIds),
    [missionIds, availableMissionIds],
  );
  const { dailyEvents = [] } = useClientMissionsDailyEvents({
    missionIds: sentMissionIds,
  });

  const startDay = useMemo(() => timeSpan.startDate.format('YYYY-MM-DD'), [
    timeSpan,
  ]);
  const endDay = useMemo(() => timeSpan.endDate.format('YYYY-MM-DD'), [
    timeSpan,
  ]);

  const countsByRemovalReason = useMemo(() => {
    const result = {} as Record<string, number>;

    _.each(dailyEvents, ({ day, details }) => {
      if (day < startDay || day > endDay) {
        return;
      }
      _.each(details, (detail) => {
        if (detail.type !== 'nbRemoved') {
          return;
        }
        const removalReason = coerceRemovalReason(detail.removalReason);
        result[removalReason] = (result[removalReason] || 0) + detail.count;
      });
    });

    return result;
  }, [dailyEvents, startDay, endDay]);

  const allStats = useMemo(
    () =>
      _.map(countsByRemovalReason, (count, name) => {
        return {
          name,
          values: { [name]: count },
        };
      }),
    [countsByRemovalReason],
  );

  const stack = useMemo(
    () =>
      _.map(_.keys(countsByRemovalReason), (removalReason) => ({
        datakey: removalReason,
        color: SecondaryBlue,
        hideFromLegend: true,
        displayedText: removalReason,
      })),
    [countsByRemovalReason],
  );

  return <HorizontalStackedBarChart stack={stack} allStats={allStats} />;
};

export default MissionRemovalReasons;
