/* eslint-disable no-nested-ternary */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Dropdown, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {
  TASK_TYPES,
  UNIPILE_TASK_TYPES,
  getTaskType,
  isEmailAction,
  isLinkedinTaskType,
} from '@/common/constants/taskTypes';
import SimpleDropdown from '@/components/SimpleDropdown';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import { useClientTaskOptions } from '@/revealComponents/FullContactFlowEditor/FullContactFlowActionEditor/helpers';
import TaskIcon from '@/revealComponents/TaskIcon';
import ContextMenu from '@/components/Tables/ContextMenu/ContextMenu';
import { getTranslatedText } from '@/common';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import { useTaskCanSnooze } from '@/hooks/common/useTaskCanSnooze';
import { UnipileMessageActionExecution } from '@/types/action';
import _ from 'underscore';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import useClientId from '@/hooks/router/useClientId';
import { SharedMessagingAccount } from '@/graphql/hooks/clients/useSharedServiceAccount';
import { GET_MESSAGE_ACCOUNT_CONTACT_RELATION } from '@/graphql/unipile';
import { useQuery } from '@apollo/client';
import SendUnipileLinkedinMessageButton from './SendUnipileLinkedinMessageButton';
import CompleteOnLinkedInLink from './CompleteOnLinkedInLink';
import UnipileSenderSelector from './UnipileSenderSelector';
import UnipileAlerts from './UnipileAlerts';

interface ActionItemHeaderProps {
  isCurrent?: boolean;
  action: any;
  task?: any;
  onRemove?: () => void;
  onEdit?: () => void;
  withIcon?: boolean;
  displayContextMenu?: boolean;
  selectedUnipileSenderId?: string;
  setSelectedUnipileSenderId?: Dispatch<SetStateAction<string>>;
  onSnoozeFromContextMenu?: () => void;
  onRemoveFromContextMenu?: () => void;
  onEditFromContextMenu?: () => void;
  onExecute?: (input: UnipileMessageActionExecution) => void;
  messagingAccount?: SharedMessagingAccount;
  sharedMessagingAccounts?: SharedMessagingAccount[];
  reconnectModalOpen?: boolean;
  onReconnectModalOpen?: (open: boolean) => void;
  onUnipilePollingRate?: (rate: number) => void;
}

const ActionItemHeader: React.FC<ActionItemHeaderProps> = ({
  isCurrent,
  action,
  task,
  onEdit,
  onRemove,
  withIcon,
  children,
  displayContextMenu = false,
  onSnoozeFromContextMenu,
  onRemoveFromContextMenu,
  onEditFromContextMenu,
  onExecute,
  messagingAccount,
  selectedUnipileSenderId,
  setSelectedUnipileSenderId,
  sharedMessagingAccounts,
  reconnectModalOpen,
  onReconnectModalOpen,
  onUnipilePollingRate,
}) => {
  const { t } = useTranslation();
  const isPlugin = useIsPlugin();
  const { isMinimized } = useMinimizedView();
  const { profile } = useCandidateViewContext();
  const { sequenceId, missionId } = useParams<{
    sequenceId: string | undefined;
    missionId: string | undefined;
  }>();
  const clientId = useClientId();

  const [hasValidated, setHasValidated] = useState(false);

  const configurationParameters = useMergedConfigurationParams();
  const { permissions } = useClientPermissions(clientId);

  const { data: isConnectedData, loading: isConnectedLoading } = useQuery(
    GET_MESSAGE_ACCOUNT_CONTACT_RELATION,
    {
      variables: {
        input: {
          sharedMessagingSenderId: selectedUnipileSenderId,
          messagingType: 'unipile-linkedin',
          profileId: profile.id,
        },
      },
      skip:
        !_.includes(UNIPILE_TASK_TYPES, action?.type) ||
        _.isEmpty(selectedUnipileSenderId),
    },
  );

  const isConnected =
    isConnectedData?.user?.getMessageAccountContactRelation?.status ===
    'connected';
  const isInvitationPending =
    isConnectedData?.user?.getMessageAccountContactRelation?.status ===
    'pending';

  useEffect(() => {
    if (!messagingAccount || !hasValidated) {
      return;
    }

    if (messagingAccount.status === 'valid') {
      setHasValidated(true);
      if (onUnipilePollingRate) {
        onUnipilePollingRate(0);
      }
    }
  }, [
    hasValidated,
    messagingAccount,
    sharedMessagingAccounts,
    onUnipilePollingRate,
  ]);

  useEffect(() => {
    if (
      !selectedUnipileSenderId &&
      action?.type === TASK_TYPES.WHATSAPP_SEND_MESSAGE
    ) {
      const whatsappAccounts = _.filter(
        sharedMessagingAccounts || [],
        (account) => account.type === 'unipile-whatsapp',
      );
      if (whatsappAccounts?.length === 1 && setSelectedUnipileSenderId) {
        setSelectedUnipileSenderId(whatsappAccounts[0]?.id);
      }
    }
  }, [
    selectedUnipileSenderId,
    sharedMessagingAccounts,
    action,
    setSelectedUnipileSenderId,
  ]);

  const { selectedOption } = useClientTaskOptions({
    currentAction: action,
  });

  const onLinkedinClick = () => {
    // window.browser.tabs.getCurrent();
    window.postMessage(
      {
        type: 'set-task-state',
        data: {
          task,
          activeTaskListId: missionId || sequenceId || getTaskType(task),
        },
      },
      '*',
    );
  };

  const { canSnooze } = useTaskCanSnooze(task);

  const canDisplayContextMenu = useMemo(() => {
    if (!displayContextMenu) {
      return false;
    }

    const { shouldHideDeleteTask, shouldHideEditTask } =
      configurationParameters;
    if (
      shouldHideDeleteTask === 'true' &&
      shouldHideEditTask === 'true' &&
      (!canSnooze || action?.snoozeConfiguration?.isSnoozable === false)
    ) {
      return false;
    }

    return true;
  }, [
    displayContextMenu,
    canSnooze,
    configurationParameters,
    action?.snoozeConfiguration?.isSnoozable,
  ]);

  const isSendBlocked = useMemo(() => {
    if (
      ![
        TASK_TYPES.LINKEDIN_SEND_MESSAGE,
        TASK_TYPES.LINKEDIN_SEND_REQUEST,
      ].includes(action?.type)
    ) {
      return false;
    }
    if (
      [
        'linkedin-recruiter-inmail',
        'linkedin-premium-inmail',
        'linkedin-salesnavigator-inmail',
      ].includes(action?.subtype)
    ) {
      return false;
    }
    return (
      isInvitationPending ||
      (!isConnectedLoading &&
        !isConnected &&
        action?.message?.body?.length > 350)
    );
  }, [action, isConnected, isConnectedLoading, isInvitationPending]);

  const title = getTranslatedText(action.title);

  return (
    <div className='action-item-content-header-container'>
      <div className='action-item-content-header'>
        {withIcon && <TaskIcon action={action} />}
        <h5 className={isMinimized ? 'minimized' : ''}>
          {title ||
            t(`sequences.tasks.${selectedOption?.value}`, selectedOption?.text)}
        </h5>
        {action.nbManualSnoozes > 0 && task && (
          <Popup
            content={t('reveal.candidatesView.nbTaskSnoozed', {
              count: action.nbManualSnoozes,
            })}
            size='mini'
            position='top center'
            inverted
            trigger={
              <span className='snooze-count-text'>
                <i className='ri-rest-time-line' />
                {action.nbManualSnoozes}
              </span>
            }
          />
        )}
        {(configurationParameters?.shouldHideDeleteTask !== 'true' ||
          configurationParameters?.shouldHideEditTask !== 'true') &&
        (onRemove || onEdit) ? (
          <SimpleDropdown
            text={t('reveal.candidatesView.timeline.edit')}
            direction='left'
          >
            <Dropdown.Menu>
              {configurationParameters?.shouldHideEditTask !== 'true' &&
                onEdit && (
                  <Dropdown.Item
                    onClick={() => onEdit()}
                    text={t('reveal.candidatesView.timeline.edit')}
                  />
                )}
              {configurationParameters?.shouldHideDeleteTask !== 'true' &&
                onRemove && (
                  <Dropdown.Item
                    text={t('reveal.candidatesView.timeline.remove')}
                    onClick={() => onRemove()}
                  />
                )}
            </Dropdown.Menu>
          </SimpleDropdown>
        ) : !selectedUnipileSenderId ||
          !permissions?.whatsappSequenceActions ? (
          isLinkedinTaskType(task?.type) &&
          !isPlugin && (
            <CompleteOnLinkedInLink
              onClick={onLinkedinClick}
              action={action}
              isButton
            />
          )
        ) : (
          permissions?.whatsappSequenceActions &&
          _.includes(UNIPILE_TASK_TYPES, task?.type) &&
          selectedUnipileSenderId && (
            <SendUnipileLinkedinMessageButton
              task={task}
              onExecute={onExecute}
              profileId={profile.id}
              senderId={selectedUnipileSenderId}
              disabled={isSendBlocked}
            />
          )
        )}
        {children && children}
        {canDisplayContextMenu && (
          <ContextMenu
            actions={[
              ...(canSnooze &&
              action?.snoozeConfiguration?.isSnoozable !== false
                ? [
                    {
                      icon: 'ri-rest-time-line',
                      key: 'snooze',
                      label: t('reveal.candidatesView.timeline.snooze.label'),
                      onClick: onSnoozeFromContextMenu,
                      disabled: !onSnoozeFromContextMenu,
                    },
                  ]
                : []),
              ...(configurationParameters?.shouldHideEditTask !== 'true'
                ? [
                    {
                      icon: 'ri-pencil-line',
                      key: 'edit',
                      label: t('common.edit'),
                      onClick: onEditFromContextMenu,
                      disabled: !onEditFromContextMenu,
                    },
                  ]
                : []),
              ...(configurationParameters?.shouldHideDeleteTask !== 'true'
                ? [
                    {
                      icon: 'ri-close-fill',
                      key: 'remove',
                      label: t('common.delete'),
                      onClick: onRemoveFromContextMenu,
                      disabled: !onRemoveFromContextMenu,
                    },
                  ]
                : []),
            ]}
          />
        )}
      </div>
      {!isPlugin &&
        permissions?.whatsappSequenceActions &&
        ((_.includes(UNIPILE_TASK_TYPES, task?.type) &&
          selectedUnipileSenderId) ||
          isEmailAction(task)) && (
          <CompleteOnLinkedInLink onClick={onLinkedinClick} action={action} />
        )}
      {permissions?.unipile &&
        isCurrent &&
        _.includes(UNIPILE_TASK_TYPES, action?.type) &&
        action?.type !== TASK_TYPES.WHATSAPP_SEND_MESSAGE && (
          <div>
            <UnipileSenderSelector
              selectedSenderId={selectedUnipileSenderId}
              setSelectedSenderId={setSelectedUnipileSenderId}
              sharedMessagingAccounts={sharedMessagingAccounts || []}
            />
            <UnipileAlerts
              isConnectedLoading={isConnectedLoading}
              isConnected={isConnected}
              isInvitationPending={isInvitationPending}
              actionType={
                (action?.subtype === 'linkedin-recruiter-inmail'
                  ? 'linkedin-send-inmail'
                  : action?.subtype === 'linkedin-salesnavigator-inmail'
                    ? 'linkedin-salesnavigator-inmail'
                    : action?.subtype === 'linkedin-premium-inmail'
                      ? 'linkedin-premium-inmail'
                      : action?.type) || ''
              }
              selectedSenderId={selectedUnipileSenderId}
              message={action?.message?.body || ''}
              firstname={profile?.resumeData?.firstname || ''}
              lastname={profile?.resumeData?.lastname || ''}
              selectedMessagingAccount={messagingAccount}
              reconnectModalOpen={reconnectModalOpen || false}
              onReconnectModalOpen={onReconnectModalOpen || (() => {})}
              onPollingRate={onUnipilePollingRate || (() => {})}
              hasValidated={hasValidated}
            />
          </div>
        )}
    </div>
  );
};

export default ActionItemHeader;
