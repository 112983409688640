import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { EvaluationFormTemplate } from '../../graphql/fragments/Evaluations';

export const moveEvaluationFormTemplateDownward = gql`
  mutation moveEvaluationFormTemplateDownward($evaluationFormTemplateId: ID!) {
    moveEvaluationFormTemplateDownward(
      evaluationFormTemplateId: $evaluationFormTemplateId
    ) {
      id
      evaluationFormTemplates {
        ...EvaluationFormTemplate
      }
    }
  }
  ${EvaluationFormTemplate}
`;

export default graphql(moveEvaluationFormTemplateDownward, {
  props: ({ mutate }) => ({
    moveEvaluationFormTemplateDownward: ({ evaluationFormTemplateId }) =>
      mutate({
        variables: {
          evaluationFormTemplateId,
        },
      }),
  }),
});
