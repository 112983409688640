import React, { FC, useMemo, useState, useCallback } from 'react';
import { Loader, Dimmer, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import InfoTooltip from '@/components/Common/InfoTooltip';
import useTrackedLinksDailyStatistics from '@/graphql/hooks/clients/statistics/useTrackedLinksDailyStatistics';
import useTrackedLinksDailyStatisticsDetails from '@/graphql/hooks/clients/statistics/useTrackedLinksDailyStatisticsDetails';
import GenericModal from '@/components/Common/GenericModal';
import useLocalShowMore from '@/hooks/ui/useLocalShowMore';
import { ShowMoreRow } from '@/components/Tables/ShowMore';
import EmptyState from '@/revealComponents/EmptyState';
import { getStatsForLinks } from './helpers';
import { TimeSpan } from '../../../components/RevealAnalyticsHeader/RevealAnalyticsHeader';
import StatsProfileAndClickTable from '../../../components/StatsProfileAndClickTable';
import { ClickDetail } from '../../../components/StatsProfileAndClickTable/types';
import LinkPreview from './LinkPreview/LinkPreview';
import DetailsModalHeader from '../../../components/DetailsModalHeader/DetailsModalHeader';

import styles from './TrackedLinks.module.less';

interface TrackedLinksProps {
  timeSpan: TimeSpan;
  sequenceIds: string[];
}

const TrackedLinks: FC<TrackedLinksProps> = ({ timeSpan, sequenceIds }) => {
  const { t, i18n } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    trackedLinksDailyStatistics,
    loading,
  } = useTrackedLinksDailyStatistics({
    startDate: timeSpan.startDate.format('YYYY-MM-DD'),
    endDate: timeSpan.endDate.format('YYYY-MM-DD'),
    ...(!_.isEmpty(sequenceIds) && { sequencesFilter: { in: sequenceIds } }),
  });
  const [selectedUrl, setSelectedUrl] = useState<string>();

  const [
    fetchDetails,
    { trackedLinksDailyStatisticsDetails, loading: detailsLoading },
  ] = useTrackedLinksDailyStatisticsDetails();

  const profileDetails = useMemo(() => {
    const result = [] as ClickDetail[];
    _.each(trackedLinksDailyStatisticsDetails, ({ day, linkStats }) => {
      _.each(linkStats, ({ eventType, url, profileItems, sequenceId }) => {
        if (eventType !== 'click') return;
        if (selectedUrl !== url) return;

        _.each(profileItems, ({ profileId }) => {
          result.push({
            profileId,
            day,
            sequenceId,
          });
        });
      });
    });
    return result;
  }, [trackedLinksDailyStatisticsDetails, selectedUrl]);

  const fetchDetailsForUrl = useCallback(
    (url: string) => {
      setModalOpen(true);
      setSelectedUrl(url);
      fetchDetails({
        startDate: timeSpan.startDate.format('YYYY-MM-DD'),
        endDate: timeSpan.endDate.format('YYYY-MM-DD'),
        urlsFilter: { in: [url] },
        ...(!_.isEmpty(sequenceIds) && {
          sequencesFilter: { in: sequenceIds },
        }),
      });
    },
    [fetchDetails, timeSpan, sequenceIds],
  );

  const statsForLinks = useMemo(
    () => getStatsForLinks(trackedLinksDailyStatistics),
    [trackedLinksDailyStatistics],
  );

  const sortedStatsForLinks = useMemo(() => {
    const increasingSorted = _.sortBy(statsForLinks, 'lastDay');
    return [...increasingSorted].reverse();
  }, [statsForLinks]);

  const [displayableItems, showMoreRowProps] = useLocalShowMore({
    defaultDisplayLimit: 10,
    items: sortedStatsForLinks,
  });

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <h3>
          {t('reveal.reports.sequences.linkClicks.title')}
          <InfoTooltip rich hoverable position='right center'>
            <h1>{t('reveal.reports.sequences.linkClicks.tooltip.title')}</h1>
            <p>{t('reveal.reports.sequences.linkClicks.tooltip.content')}</p>
          </InfoTooltip>
        </h3>
      </div>
      <div className={styles.cardBody}>
        {loading ? (
          <div className={styles.loader}>
            <Loader active inline='centered' size='large' />
          </div>
        ) : (
          <>
            {_.isEmpty(displayableItems) ? (
              <EmptyState
                title={t(
                  'reveal.reports.sequences.linkClicks.emptyState.title',
                )}
                innerContent={t(
                  'reveal.reports.sequences.linkClicks.emptyState.innerContent',
                )}
              />
            ) : (
              <div className={styles.table}>
                <div className={classNames(styles.row, styles.header)}>
                  <div className={styles.cell}>
                    {t('reveal.reports.sequences.linkClicks.url')}
                  </div>
                  <div className={styles.cell}>
                    {t('reveal.reports.sequences.linkClicks.lastDay')}
                  </div>
                  <div className={styles.cell}>
                    {t('reveal.reports.sequences.linkClicks.count')}
                  </div>
                </div>
                {_.map(displayableItems, ({ url, count, lastDay }) => (
                  <div key={url} className={styles.row}>
                    <span className={classNames(styles.cell, styles.link)}>
                      <LinkPreview
                        url={url}
                        classNames={{
                          url: styles.linkUrl,
                          title: styles.linkTitle,
                        }}
                      />
                    </span>
                    <button
                      type='button'
                      className={styles.cell}
                      onClick={() => fetchDetailsForUrl(url)}
                    >
                      {new Date(lastDay).toLocaleString(i18n.resolvedLanguage, {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </button>
                    <button
                      type='button'
                      className={styles.cell}
                      onClick={() => fetchDetailsForUrl(url)}
                    >
                      {count}
                    </button>
                  </div>
                ))}
                <ShowMoreRow {...showMoreRowProps} />
              </div>
            )}
          </>
        )}
      </div>

      {detailsLoading ? (
        <Dimmer active>
          <Loader size='large' />
        </Dimmer>
      ) : (
        <GenericModal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Content>
            <DetailsModalHeader>
              <h1>
                {t('reveal.reports.sequences.linkClicks.detailsModal.title')}
              </h1>
              {selectedUrl && (
                <h2 className={styles.linkSubtitle}>{selectedUrl}</h2>
              )}
            </DetailsModalHeader>
            <StatsProfileAndClickTable profileItems={profileDetails} />
          </Modal.Content>
        </GenericModal>
      )}
    </div>
  );
};

export default TrackedLinks;
