import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const setPluginGmailPermission = gql`
  mutation setPluginGmailPermission($bool: Boolean!) {
    setPluginGmailPermission(bool: $bool) {
      id
      permissions {
        pluginGmail
      }
    }
  }
`;

export default graphql(setPluginGmailPermission, {
  props: ({ mutate }) => ({
    setPluginGmailPermission: ({ bool }) => mutate({ variables: { bool } }),
  }),
});
