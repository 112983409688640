import React from 'react';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import MailAccountConnect from '../../MailAccountConnect';

const ProviderChoiceModal = ({ open, onClose, onTriggerOfflineRegister }) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      closeIcon
      onClose={onClose}
      dimmer='blurring'
      size='tiny'
      closeOnDimmerClick={false}
    >
      <Modal.Header>{t('changeEmailAccountModal.header')}</Modal.Header>
      <Modal.Content>
        <div className='mail-account-connect-modal'>
          <MailAccountConnect
            onClick={() => {
              onTriggerOfflineRegister('gmail');
              onClose();
            }}
            imgSrc='/images/logos/gmail.svg'
            imgAlt='gmail'
            text={t('changeEmailAccountModal.gmailSwitch')}
          />
          <MailAccountConnect
            onClick={() => {
              onTriggerOfflineRegister('outlook', 'select_account');
              onClose();
            }}
            imgSrc='/images/logos/outlook.svg'
            imgAlt='outlook'
            text={t('changeEmailAccountModal.outlookSwitch')}
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ProviderChoiceModal;
