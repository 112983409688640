import React from 'react';
// import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import './RevealCandidateViewActionsButtons.css';
import LogActivityForm from '@/components/Reveal/LogActivity/LogActivityForm.connected';
import useIsPlugin from '@/hooks/common/useIsPlugin';

interface RevealCandidateViewNewActivityProps {
  clientId: string;
  setIsOpen: any;
}

const RevealCandidateViewNewActivity: React.FC<RevealCandidateViewNewActivityProps> = ({
  clientId,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const isPlugin = useIsPlugin();
  return (
    <div className={`new-activity-modal ${isPlugin ? 'plugin' : ''}`}>
      <h4>{t('profile.actionButtons.logAnActivity')}</h4>
      <LogActivityForm
        searchPoolId='reveal'
        clientId={clientId}
        onSubmitSuccess={() => setIsOpen(false)}
      />
    </div>
  );
};

export default RevealCandidateViewNewActivity;
