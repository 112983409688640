import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Dropdown, Segment } from 'semantic-ui-react';

import NewTemplate from '../../../Templates/modals/NewTemplate';

import './ContactFlowActionAddButton.css';

const AddContactFlowActionButton = ({
  clientId,
  offerId,
  sequenceId,
  templateOptions,
  onInsertAction,
  index,
  disabled,
  removeNewFollowupCard,
  permissionsAndNudges,
  updateAfterTemplateCreation,
  t,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const onClose = () => {
    setModalOpen(false);
    if (removeNewFollowupCard) {
      removeNewFollowupCard();
    }
  };
  if (disabled) {
    return (
      <Segment className='default-email-card'>
        <div className='card-header'>{`${t('offers.defaultEmails.emailHeader')} ${index + 1}`}</div>
        <div className='offer-card-email without-default disabled'>
          <span className='action-button action-button-text'>{t('offers.defaultEmails.createTemplate')}</span>
          &nbsp;
          <span className='or'>{t('offers.defaultEmails.or')}</span>
          &nbsp;
          <span className='action-button'>{t('offers.defaultEmails.chooseTemplate')}</span>
        </div>
      </Segment>
    );
  }

  const { manualTrigger, nonManualTrigger } = permissionsAndNudges;

  const useTriggerWithDelay = nonManualTrigger.isAllowed && (nonManualTrigger.isNudged || !manualTrigger.isAllowed);

  const trigger = useTriggerWithDelay
    ? {
      type: 'delay-after-action',
      delay: {
        value: 5,
        unit: 'working-day',
      },
    }
    : {
      type: 'manual-trigger',
    };

  return (
    <Segment className='default-email-card'>
      <div className='card-header'>
        <h3 className='card-header-title'>{`${t('offers.defaultEmails.emailHeader')} ${index + 1}`}</h3>

        {index > 1 && (
          <Button className='hs-tertiary-button detach-button' onClick={removeNewFollowupCard}>
            <i className='ri-link-unlink-m' />
            {t('offers.defaultEmails.detach')}
          </Button>
        )}
      </div>

      <div className='offer-card-email without-default'>
        <span className='action-button action-button-text' onClick={() => setModalOpen(true)}>
          {t('offers.defaultEmails.createTemplate')}
        </span>
        &nbsp;
        <span className='or'>{t('offers.defaultEmails.or')}</span>
        &nbsp;
        <Dropdown
          className='hs-dropdown-ellipsis action-button'
          basic
          scrolling
          // upward // causes dropdown to go under headers on first e-mail
          placeholder={t('offers.defaultEmails.chooseTemplate')}
          options={[{ value: null, text: t('offers.defaultEmails.chooseTemplate') }, ...templateOptions]}
          onChange={(e, { value }) => {
            if (value) {
              onInsertAction({
                newAction: {
                  type: 'send-email-action',
                  trigger,
                  message: { templateId: value },
                },
              });
            }
          }}
        />
        <NewTemplate
          offerId={offerId}
          messagePosition={index}
          clientId={clientId}
          sequenceId={sequenceId}
          open={modalOpen}
          onClose={onClose}
          onPostSubmit={
            updateAfterTemplateCreation
              ? ({ templateId }) => updateAfterTemplateCreation({
                action: {
                  type: 'send-email-action',
                  trigger,
                  message: { templateId },
                },
                sequenceId,
                actionIndex: index,
              })
              : null
          }
        />
      </div>
    </Segment>
  );
};

export default withTranslation('translations')(AddContactFlowActionButton);
