import React, { ReactNode, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import useClientId from '@/hooks/router/useClientId';
import useClient from '@/graphql/hooks/clients/useClient';
import GenericModal from '@/components/Common/GenericModal';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import logAction from '@/common/logAction';
import GenericButton from '@/components/Common/GenericButton';

import styles from './AccountStateGuard.module.less';
import AskReactivationModal from '../AskReactivationModal';

type Props = {
  children: ReactNode;
};

const AccountStateGuard = ({ children }: Props) => {
  const [reactivationModalOpen, setReactivationModalOpen] = useState(false);
  const [hasAsked, setHasAsked] = useState(false);
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const clientId = useClientId();
  const { data, loading } = useClient(clientId);

  if (loading) {
    return (
      <div className='reveal-view loading'>
        <Loader active inline='centered' size='large' />
      </div>
    );
  }

  if (
    user?.isAdmin ||
    !data?.client?.accountState?.status ||
    data.client.accountState.status === 'active'
  ) {
    return children;
  }

  return (
    <>
      <GenericModal className={styles.modal} open>
        <h1 className={styles.title}>
          {t('reveal.accountState.deactivated.title')}
        </h1>
        <p className={styles.paragraph}>
          {t('reveal.accountState.deactivated.description')}
        </p>
        {hasAsked ? (
          <div className={styles.confirmation}>
            {t('reveal.accountState.deactivated.askReactivationSubmitted')}
          </div>
        ) : (
          <GenericButton
            primacy='secondary'
            size='big'
            className={styles.cta}
            onClick={() => {
              logAction({ type: 'reveal-open-ask-reactivation-modal' });
              setReactivationModalOpen(true);
            }}
          >
            {t('reveal.accountState.deactivated.askReactivation')}
          </GenericButton>
        )}
      </GenericModal>
      <AskReactivationModal
        open={reactivationModalOpen}
        setOpen={setReactivationModalOpen}
        onAsk={() => setHasAsked(true)}
      />
    </>
  );
};

export default AccountStateGuard;
