import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import ClientDefaultCrmWorkflows from '@/graphql/fragments/ClientDefaultCrmWorkflows';

const UPDATE_CLIENT_DEFAULT_WORKFLOW = gql`
  mutation UpdateClientDefaultWorkflow(
    $input: UpdateClientDefaultWorkflowInput
  ) {
    updateClientDefaultWorkflow(input: $input) {
      id
      defaultCrmWorkflows {
        ...ClientDefaultCrmWorkflows
      }
    }
  }
  ${ClientDefaultCrmWorkflows}
`;

const useUpdateClientDefaultWorkflow = () => {
  return useMutation(UPDATE_CLIENT_DEFAULT_WORKFLOW);
};

export default useUpdateClientDefaultWorkflow;
