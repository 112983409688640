import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import NotificationsSetFragment from '../../graphql/fragments/NotificationSet';

const NOTIFICATIONS_POLL_INTERVAL = 5 * 60 * 1000; // every 5 minutes
const lastActionTimestamp = localStorage.getItem('lastActionTimestamp');

export const query = gql`
  query getNotifications($clientId: ID!) {
    client(id: $clientId) {
      id
      ...NotificationsSet
    }
  }
  ${NotificationsSetFragment}
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      clientId: ownProps.clientId,
    },
    ...((!lastActionTimestamp ||
      Date.now() - lastActionTimestamp < 3 * 60 * 60 * 1000) && {
      pollInterval: NOTIFICATIONS_POLL_INTERVAL,
    }),
  }),
  props: ({ data: { loading, client, error } }) => {
    return {
      loading,
      notificationsLoading: loading,
      notifications: (client && client.notifications) || [],
      clientId: client && client.id,
      error,
    };
  },
});
