import React, { useMemo } from 'react';
import { CustomFieldValue, CustomFieldValueDay } from '@/common/customFields';
import { useTranslation } from 'react-i18next';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import _ from 'underscore';
import { getTranslatedText } from '@/common';

export const getValueFromCustomField = ({
  customField,
  customFields,
  i18n,
}: {
  customField?: CustomFieldValue;
  customFields?: CustomFieldDefinition[];
  i18n: any;
}) => {
  if (!customField) {
    return null;
  }

  const noopFactory = (property: string) => {
    return (customFieldValue: CustomFieldValue) => {
      return (
        customFieldValue[property as keyof typeof customFieldValue] || null
      );
    };
  };

  const clientCustomField = _.findWhere(customFields || [], {
    id: customField.clientCustomFieldId,
  });

  const formatters: Record<
    CustomFieldValue['type'],
    (customField: CustomFieldValue) => string | null
  > = {
    day: (customFieldValue) => {
      if (!(customFieldValue as CustomFieldValueDay).date) {
        return '-';
      }

      const dateFormatter = new Intl.DateTimeFormat(i18n.resolvedLanguage, {
        dateStyle: 'long',
      });

      return dateFormatter.format(
        new Date((customFieldValue as CustomFieldValueDay).date),
      );
    },
    'inline-text': noopFactory('text'),
    text: noopFactory('text'),
    integer: noopFactory('integer'),
    float: noopFactory('float'),
    enum: (customFieldValue) => {
      if (!clientCustomField) {
        return customFieldValue?.selected?.join(', ') || '-';
      }
      const values = _.filter(clientCustomField.options, ({ id }) =>
        customFieldValue.selected?.includes(id),
      );
      const names = _.map(values, (value) => getTranslatedText(value.title));
      return names?.join(', ') || '-';
    },
  };

  const formatter = formatters[customField.type];
  return formatter(customField);
};

export const useFormattedCustomField = ({
  customField,
  customFields,
}: {
  customField?: CustomFieldValue;
  customFields?: CustomFieldDefinition[];
}) => {
  const { i18n } = useTranslation();

  const value = useMemo(() => {
    return getValueFromCustomField({ customField, customFields, i18n });
  }, [customField, customFields, i18n]);

  return value;
};

export function InlineCustomField({
  customField,
  customFields,
}: {
  customField?: CustomFieldValue;
  customFields?: CustomFieldDefinition[];
}) {
  const value = useFormattedCustomField({ customField, customFields });
  return <span>{value || '-'}</span>;
}
