import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const query = gql`
  query getUserFromToken($token: String!, $clientId: ID!) {
    user(token: $token, clientId: $clientId) {
      id
      email
      isAdmin
    }
  }
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      token: ownProps.token,
      clientId: ownProps.clientId,
    },
  }),
  props: ({ data: { loading, user, error } }) => ({
    userLoading: loading,
    user,
    userError: error,
  }),
  skip: (ownProps) => !ownProps.token,
});
