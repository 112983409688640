import _ from 'underscore';
import React from 'react';
import {
  Button,
  Modal,
  Grid,
  Dropdown,
  Input,
  Divider,
  Image,
  Checkbox,
} from 'semantic-ui-react';

import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import { getRandomString, textToId } from '../../../common';

import './EvaluationFormEditModal.css';

/*
   The component EvaluationFormEditModal can be used with two modes :

   1) mode='classic-edition'
   This mode is the one triggered from the settings menu, and can be used both for
   creation and edition. In this mode, we ask to edit a title in top of the form

   2) mode='online-creation'
   This mode is the one triggered from the profile evaluation view, where it is
   optional to save the evaluation form created.
   It is asked in a second modal if we want to save the created form or not, asking
   a title if the user wants to save it

 */

const GradeButtons = () => (
  <Button.Group className='grade-button-selector' basic>
    <Button>0</Button>
    <Button>1</Button>
    <Button>2</Button>
    <Button>3</Button>
    <Button>4</Button>
    <Button>5</Button>
  </Button.Group>
);

class FieldEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'preview',
    };
  }

  handleChangeMode = ({ mode }) => {
    this.setState({ mode });
  };

  renderSettings() {
    const {
      index,
      nbFields,
      onMoveFieldUpward,
      onMoveFieldDownward,
      onDuplicateField,
      onRemoveField,
      t,
    } = this.props;
    const { mode } = this.state;

    return (
      <div className='evaluation-form-editor-field-settings'>
        <div className='field-settings-edit-preview-container'>
          {mode === 'preview' ? (
            <span onClick={() => this.handleChangeMode({ mode: 'edit' })}>
              {t('settings.evaluations.form.edit')}
            </span>
          ) : (
            <span onClick={() => this.handleChangeMode({ mode: 'preview' })}>
              {t('settings.evaluations.form.backToPreview')}
            </span>
          )}
        </div>
        <div className='field-settings-dropdown-container'>
          <Dropdown
            direction='left'
            trigger={<Image src='/images/icons/figma/ellipsis.svg' />}
            icon={null}
          >
            <Dropdown.Menu>
              {index > 0 && (
                <Dropdown.Item onClick={() => onMoveFieldUpward()}>
                  {t('settings.evaluations.moveUpward')}
                </Dropdown.Item>
              )}
              {index < nbFields - 1 && (
                <Dropdown.Item onClick={() => onMoveFieldDownward()}>
                  {t('settings.evaluations.moveDownward')}
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={() => onDuplicateField()}>
                {t('settings.evaluations.duplicate')}
              </Dropdown.Item>
              {nbFields > 1 && (
                <Dropdown.Item onClick={() => onRemoveField()}>
                  {t('settings.evaluations.delete')}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  }

  renderPreview() {
    const { field, index, t } = this.props;
    if (field.type === 'grade') {
      return (
        <div className='evaluation-form-editor-field'>
          <div className='evaluation-form-editor-field-header'>
            <h4>
              {index + 1}. {field.title}
            </h4>
            {this.renderSettings({ fieldId: field.id })}
          </div>
          <div className='grade-field-grade-selector'>
            <GradeButtons />
          </div>
        </div>
      );
    }
    if (field.type === 'comment') {
      return (
        <div className='evaluation-form-editor-field'>
          <div className='evaluation-form-editor-field-header'>
            <h4>
              {index + 1}. {field.title}
            </h4>
            {this.renderSettings({ fieldId: field.id })}
          </div>
          <div>
            <Input
              value={t('settings.evaluations.form.commentPlaceholder')}
              disabled
              fluid
              size='large'
            />
          </div>
        </div>
      );
    }

    if (field.type === 'grade-and-comment') {
      return (
        <div className='evaluation-form-editor-field'>
          <div className='evaluation-form-editor-field-header'>
            <h4>
              {index + 1}. {field.title}{' '}
            </h4>
            {this.renderSettings({ fieldId: field.id })}
          </div>
          <div className='grade-field-grade-selector'>
            <GradeButtons />
          </div>
          <Input
            value={t('settings.evaluations.form.commentPlaceholder')}
            disabled
            fluid
            size='large'
          />
        </div>
      );
    }

    if (field.type === 'scorecard') {
      return (
        <div className='evaluation-form-editor-field'>
          <div className='evaluation-form-editor-field-header'>
            <h4>
              {index + 1}. {field.title}
            </h4>
            {this.renderSettings({ fieldId: field.id })}
          </div>
          <Grid style={{ margin: 0, padding: 0 }}>
            {_.map(field.subfields, (subfield, subfieldIndex) => (
              <Grid.Row key={subfieldIndex} style={{ margin: 0, padding: 0 }}>
                <Grid.Column
                  width={8}
                  verticalAlign='middle'
                  style={{ margin: 0, padding: 0 }}
                >
                  <span>{subfield.title}</span>
                </Grid.Column>
                <Grid.Column
                  width={8}
                  verticalAlign='middle'
                  textAlign='right'
                  style={{ margin: 0, padding: 0 }}
                >
                  <GradeButtons />
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        </div>
      );
    }
    return null;
  }

  renderEdition() {
    const { field, index, onUpdateField, t } = this.props;

    return (
      <div className='evaluation-form-editor-field'>
        <div className='evaluation-form-editor-field-header'>
          <h4>
            {index + 1}. {t('settings.evaluations.form.editingThisQuestion')}
          </h4>

          {this.renderSettings({ fieldId: field.id })}
        </div>
        <div>
          <div className='evaluation-form-editor-field-edition'>
            <div className='field-edition-element'>
              <span className='field-edition-element-text'>
                {t('settings.evaluations.form.fieldTitle')}
              </span>
              <Input
                className='field-edition-input'
                placeholder={t('settings.evaluations.form.fieldTitle')}
                value={field.title}
                onChange={(e, { value }) => {
                  const newValue = {
                    ...field,
                    title: value,
                  };
                  onUpdateField({ newValue });
                }}
                fluid
              />
            </div>
            <div className='field-edition-element'>
              <span className='field-edition-element-text'>
                {t('settings.evaluations.form.fieldType')}
              </span>
              <Dropdown
                className='field-edition-dropdown'
                fluid
                selection
                options={[
                  {
                    value: 'comment',
                    text: t('settings.evaluations.form.fieldOptions.comment'),
                  },
                  {
                    value: 'grade',
                    text: t('settings.evaluations.form.fieldOptions.grade'),
                  },
                  {
                    value: 'grade-and-comment',
                    text: t(
                      'settings.evaluations.form.fieldOptions.gradeAndComment',
                    ),
                  },
                  {
                    value: 'scorecard',
                    text: t('settings.evaluations.form.fieldOptions.scorecard'),
                  },
                ]}
                value={field.type}
                onChange={(e, { value }) => {
                  const newValue = {
                    id: field.id,
                    title: field.title,
                    type: value,
                    ...((value === 'grade' ||
                      value === 'grade-and-comment') && {
                      coefficient: 1,
                    }),
                    ...(value === 'scorecard' && {
                      subfields: [
                        {
                          id: getRandomString(10),
                          type: 'grade',
                          title: '',
                          coefficient: 1,
                        },
                      ],
                    }),
                  };
                  onUpdateField({ newValue });
                }}
              />
            </div>
            {field.type === 'scorecard' ? (
              <div className='scorecard-subfields-editor'>
                {_.map(field.subfields, (subfield, subfieldIndex) => (
                  <div
                    key={subfield.id}
                    className='scorecard-subfield-edition-element'
                  >
                    <span className='subfield-edition-element-text'>
                      {t('settings.evaluations.form.score')} {subfieldIndex + 1}
                    </span>
                    <Input
                      className='subfield-edition-input'
                      placeholder={t('settings.evaluations.form.fieldTitle')}
                      value={subfield.title}
                      onChange={(e, { value }) => {
                        const newValue = {
                          ...field,
                          subfields: _.map(
                            field.subfields,
                            (iteratedSubfield) =>
                              iteratedSubfield.id === subfield.id
                                ? {
                                    ...subfield,
                                    title: value,
                                  }
                                : iteratedSubfield,
                          ),
                        };
                        onUpdateField({ newValue });
                      }}
                      fluid
                    />
                    <button
                      className='trash-button'
                      onClick={() => {
                        const newValue = {
                          ...field,
                          subfields: _.filter(
                            field.subfields,
                            (iteratedSubfield) =>
                              iteratedSubfield.id !== subfield.id,
                          ),
                        };
                        onUpdateField({ newValue });
                      }}
                      type='button'
                    >
                      <img
                        className='trash-svg'
                        src='/images/icons/trash.svg'
                        alt=''
                      />
                    </button>
                  </div>
                ))}
                <div className='add-subfield-container'>
                  <span
                    onClick={() => {
                      const newValue = {
                        ...field,
                        subfields: [
                          ...field.subfields,
                          {
                            id: getRandomString(10),
                            type: 'grade',
                            title: '',
                            coefficient: 1,
                          },
                        ],
                      };
                      onUpdateField({ newValue });
                    }}
                  >
                    {t('settings.evaluations.form.addScoreField')}
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { mode } = this.state;

    if (mode === 'preview') {
      return this.renderPreview();
    }
    return this.renderEdition();
  }
}

const getFieldCopy = ({ field, withCopyTitleExtention, t }) => {
  return {
    ...field,
    id: getRandomString(10),
    title:
      field.title +
      (withCopyTitleExtention
        ? ` - ${t ? t('settings.evaluations.form.fieldCopy') : 'Copy'}`
        : ''),
    ...(field.subfields && {
      subfields: _.map(field.subfields, (subfield) => ({
        ...subfield,
        id: getRandomString(10),
      })),
    }),
  };
};

class EvaluationFormEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    const addIdsToForm = (form) => ({
      ...form,
      fields: _.map(form.fields, (field) =>
        getFieldCopy({ field, t: props.t }),
      ),
    });

    this.state.initialValue = props.initialValue;

    this.state.form = addIdsToForm({
      fields: [],
      ...props.initialValue?.form,
    });

    if (props.mode === 'classic-edition') {
      this.state.title =
        props.initialValue?.title ||
        (props.t && props.t('settings.evaluations.form.newForm')) ||
        'New form';
    }
  }

  handleSubmit = () => {
    const { mode } = this.props;
    if (mode === 'online-creation') {
      this.setState({
        showSaveInTemplatesModal: true,
      });
    } else {
      const { onSubmit } = this.props;
      const { title, form, initialValue } = this.state;

      const getIdFromStr = (str) => `${textToId(str)}-${getRandomString(6)}`;

      onSubmit({
        newValue: {
          ...initialValue,
          title,
          form,
          id: initialValue?.id || getIdFromStr(title),
        },
      });
    }
  };

  handleBackToEdition = () => {
    this.setState({
      showSaveInTemplatesModal: null,
    });
  };

  handleFinalizeSubmit = ({ withSave, title }) => {
    const { onSubmit } = this.props;
    const { form } = this.state;
    onSubmit({ evaluationForm: form, saveAsTemplate: !!withSave, title });
  };

  handleUpdateFormTitle = ({ newValue }) => {
    this.setState({ title: newValue });
  };

  handleUpdateFields = ({ newFields }) => {
    const { form: currentForm } = this.state;
    this.setState({
      form: {
        ...currentForm,
        fields: newFields,
      },
    });
  };

  handleMoveFieldUpward = ({ fieldId }) => {
    const { form: currentForm } = this.state;
    const index = _.findIndex(currentForm.fields, { id: fieldId });

    if (index <= 0) {
      return;
    }

    const newFields = [
      ...currentForm.fields.slice(0, index - 1),
      currentForm.fields[index],
      currentForm.fields[index - 1],
      ...currentForm.fields.slice(index + 1),
    ];

    this.handleUpdateFields({ newFields });
  };

  handleMoveFieldDownward = ({ fieldId }) => {
    const { form: currentForm } = this.state;
    const index = _.findIndex(currentForm.fields, { id: fieldId });

    if (index < 0 || index === currentForm.fields.length - 1) {
      return;
    }

    const newFields = [
      ...currentForm.fields.slice(0, index),
      currentForm.fields[index + 1],
      currentForm.fields[index],
      ...currentForm.fields.slice(index + 2),
    ];

    this.handleUpdateFields({ newFields });
  };

  handleDuplicateField = ({ fieldId }) => {
    const { t } = this.props;
    const { form: currentForm } = this.state;
    const index = _.findIndex(currentForm.fields, { id: fieldId });

    if (index < 0) {
      return;
    }

    const field = currentForm.fields[index];

    const newFields = [
      ...currentForm.fields.slice(0, index),
      field,
      getFieldCopy({ field, withCopyTitleExtention: true, t }),
      ...currentForm.fields.slice(index + 1),
    ];

    this.handleUpdateFields({ newFields });
  };

  handleRemoveField = ({ fieldId }) => {
    const { form: currentForm } = this.state;
    const newFields = _.filter(currentForm.fields, ({ id }) => id !== fieldId);
    this.handleUpdateFields({ newFields });
  };

  handleUpdateField = ({ fieldId, newValue }) => {
    const { form: currentForm } = this.state;
    const newFields = _.map(currentForm.fields, (field) =>
      field.id !== fieldId
        ? field
        : {
            ...newValue,
            id: fieldId, // to be sure
          },
    );
    this.handleUpdateFields({ newFields });
  };

  handleAddField = ({ index }) => {
    const { t } = this.props;
    const { form: currentForm } = this.state;
    const newFields = [
      ...currentForm.fields.slice(0, index),
      {
        id: getRandomString(10),
        type: 'comment',
        title: t('settings.evaluations.form.defaultTitle'),
      },
      ...currentForm.fields.slice(index),
    ];
    this.handleUpdateFields({ newFields });
  };

  renderFormEditor() {
    const { mode, t } = this.props;
    const { form, title } = this.state;

    return (
      <div className='evaluation-form-editor-fields-container'>
        {mode === 'classic-edition' ? (
          <div className='input-container'>
            <div className='input-label'>
              {t('settings.evaluations.form.title')}
            </div>
            <div className='input-element'>
              <Input
                fluid
                className='field-edition-input'
                placeholder={t('settings.evaluations.form.title')}
                value={title}
                onChange={(e, { value }) => {
                  this.handleUpdateFormTitle({ newValue: value });
                }}
              />
            </div>
          </div>
        ) : (
          ''
        )}

        {_.map(form.fields, (field, index) => (
          <div key={field.id}>
            {index > 0 ? <Divider /> : ''}
            <FieldEditor
              field={field}
              index={index}
              nbFields={form.fields.length}
              onUpdateField={({ newValue }) => {
                this.handleUpdateField({ fieldId: field.id, newValue });
              }}
              onMoveFieldUpward={() =>
                this.handleMoveFieldUpward({ fieldId: field.id })
              }
              onMoveFieldDownward={() =>
                this.handleMoveFieldDownward({ fieldId: field.id })
              }
              onDuplicateField={() =>
                this.handleDuplicateField({ fieldId: field.id })
              }
              onRemoveField={() =>
                this.handleRemoveField({ fieldId: field.id })
              }
              t={t}
            />
          </div>
        ))}
        <div className='add-field-container'>
          <span
            onClick={() => this.handleAddField({ index: form.fields.length })}
          >
            {t('settings.evaluations.form.addField')}
          </span>
        </div>
      </div>
    );
  }

  renderSaveInTemplatesModal() {
    const { t } = this.props;
    const { saveThisForm, formToSaveTitle } = this.state;
    return (
      <GenericModal
        className='save-evaluation-form-template-modal'
        open
        onClose={() => this.setState({ showSaveInTemplatesModal: false })}
        size='small'
      >
        <Modal.Header>
          {t('settings.evaluations.saveTemplateModal.header')}
        </Modal.Header>
        <Modal.Content>
          <div className='input-container'>
            <div className='input-label'>
              {t('settings.evaluations.saveTemplateModal.saveToReuseLater')}
            </div>
            <div className='input-element'>
              <Checkbox
                label={t('settings.evaluations.saveTemplateModal.saveForm')}
                onChange={() => this.setState({ saveThisForm: !saveThisForm })}
                checked={saveThisForm}
              />
            </div>
          </div>
          {saveThisForm && (
            <div className='inputs-row'>
              <div className='input-container half-width'>
                <div className='input-label'>
                  {t('settings.evaluations.saveTemplateModal.title')}
                </div>
                <div className='input-element'>
                  <Input
                    placeholder={t(
                      'settings.evaluations.saveTemplateModal.title',
                    )}
                    fluid
                    value={formToSaveTitle || ''}
                    onChange={(e, { value }) => {
                      this.setState({ formToSaveTitle: value });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <div className='evaluation-form-edit-modal-actions'>
            <GenericButton
              primacy='secondary'
              onClick={() => {
                this.handleBackToEdition();
              }}
            >
              {t('settings.evaluations.saveTemplateModal.previous')}
            </GenericButton>
            <GenericButton
              onClick={() => {
                this.handleFinalizeSubmit({
                  withSave: saveThisForm,
                  title:
                    formToSaveTitle ||
                    t('settings.evaluations.form.defaultFormTitle'),
                });
              }}
            >
              {t('settings.evaluations.saveTemplateModal.submit')}
            </GenericButton>
          </div>
        </Modal.Actions>
      </GenericModal>
    );
  }

  render() {
    const { onClose, mode, t, editMode = 'edit' } = this.props;
    const { showSaveInTemplatesModal } = this.state;

    const disableSubmit = false;

    if (mode === 'online-creation' && showSaveInTemplatesModal) {
      return this.renderSaveInTemplatesModal();
    }

    return (
      <GenericModal
        className='evaluation-form-edit-modal'
        open
        onClose={onClose}
        size='small'
      >
        <Modal.Header>
          {t(`settings.evaluations.${editMode}Modal.header`)}
        </Modal.Header>
        <Modal.Content>{this.renderFormEditor()}</Modal.Content>
        <Modal.Actions>
          <div className='evaluation-form-edit-modal-actions'>
            <GenericButton size='big' primacy='secondary' onClick={onClose}>
              {t('common.cancel')}
            </GenericButton>
            <GenericButton
              size='big'
              onClick={this.handleSubmit}
              disabled={disableSubmit}
            >
              {mode === 'online-creation' ? (
                <span>{t('settings.evaluations.editModal.continue')}</span>
              ) : (
                <span>{t(`common.${editMode}`)}</span>
              )}
            </GenericButton>
          </div>
        </Modal.Actions>
      </GenericModal>
    );
  }
}

export default EvaluationFormEditModal;
