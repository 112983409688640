import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import contextToProps from '../../hocs/contextToProps';
import withCreateSuggestedOffer from '../../hocs/offers/withCreateSuggestedOffer';
import OfferSuggestion from './OfferSuggestion';
import withActionLogger from '../../hocs/withActionLogger';

class OffersSuggestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offers: _.map(this.props.suggestedOffers, ({ id, title, criteria }) => ({
        id,
        title,
        hasBeenCreated: false,
        seekingCandidatesOnly: false,
        ...(criteria && criteria.onlineLinks && { link: criteria.onlineLinks }),
      })),
    };
  }

  componentDidMount() {
    const { onLogAction, clientId, user } = this.props;
    onLogAction({
      type: 'saw-suggested-offers',
      info: {
        clientId,
        author: `${user.firstname} ${user.lastname}`,
      },
    });
  }

  handleSelectOffer = async ({ offerId }) => {
    const { createSuggestedOffer, onShowNotification, t } = this.props;
    try {
      const selectedOffer = _.findWhere(this.state.offers, { id: offerId });
      await createSuggestedOffer({
        id: offerId,
        seekingCandidatesOnly: selectedOffer.seekingCandidatesOnly,
      });
      onShowNotification({
        message: t('offersSuggestions.createdSuccessfully'),
        level: 'success',
      });
      this.setState(({ offers }) => ({
        offers: _.map(offers, (offer) =>
          offer.id === offerId ? { ...offer, hasBeenCreated: true } : offer,
        ),
      }));
    } catch (e) {
      console.error(e);
    }
  };

  handleSelectSeekingCandidatesOnly = async ({ offerId, event, data }) => {
    try {
      this.setState(({ offers }) => ({
        offers: _.map(offers, (offer) =>
          offer.id === offerId
            ? { ...offer, seekingCandidatesOnly: data.checked }
            : offer,
        ),
      }));
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { offers } = this.state;
    return (
      <>
        {_.map(offers, (offer, index) => (
          <OfferSuggestion
            key={index}
            offer={offer}
            handleSelectOffer={this.handleSelectOffer}
            handleSelectSeekingCandidatesOnly={
              this.handleSelectSeekingCandidatesOnly
            }
            t={this.props.t}
          />
        ))}
        {_.any(
          offers,
          ({ hasBeenCreated }) => hasBeenCreated === false,
        ) ? null : (
          <p>
            {this.props.t('offersSuggestions.congratulations', {
              count: offers.length > 1 ? 0 : 1,
            })}
          </p>
        )}
      </>
    );
  }
}

export default compose(
  withCreateSuggestedOffer,
  contextToProps,
  withTranslation('translations'),
  withActionLogger,
)(OffersSuggestions);
