import _ from 'underscore';
import {
  SearchPoolMiniProfile,
  Action,
} from '@/graphql/searchPoolMiniProfileById';
import { Sequence } from '@/types/sequence';
import { CurrentSequenceInfo } from '@/types/searchPoolProfile';
import { ContactFlow } from '@/types/contactFlow';
import { recursivelyRemoveEmptyKeyValues } from '@/common';

export type ReapplySequenceTerms =
  | {
      possible: false;
      reason: string;
      lastCompletedActionIndex?: undefined;
      shortestProfileSequenceLength?: undefined;
      minimumNumberOfActionsToKeep?: undefined;
      suffixApplicableAtIndex?: undefined;
    }
  | {
      possible: true;
      reason?: undefined;
      lastCompletedActionIndex: number;
      shortestProfileSequenceLength: number;
      minimumNumberOfActionsToKeep: number;
      suffixApplicableAtIndex: number;
    };

export const getReapplySequenceTerms = ({
  sequence,
  profiles,
}: {
  sequence?: Sequence;
  profiles?: SearchPoolMiniProfile[];
}): ReapplySequenceTerms => {
  if (!sequence) {
    return {
      possible: false,
      reason: 'missing-sequence',
    };
  }
  if (!profiles || _.isEmpty(profiles)) {
    return {
      possible: false,
      reason: 'missing-profiles',
    };
  }
  const profileCurrentSequences = _.map(profiles, (profile) => {
    const currentSequence = _.findWhere(profile?.contactFlow?.sequences || [], {
      isCurrent: true,
    });
    return { id: profile.id, sequence: currentSequence };
  });
  if (
    _.any(
      profileCurrentSequences,
      (profileCurrentSequence) => !profileCurrentSequence?.sequence,
    )
  ) {
    return {
      possible: false,
      reason: 'one-has-no-current-sequence',
    };
  }

  if (
    _.any(
      profiles || [],
      (profile) => profile?.currentSequenceInfo?.sequenceId !== sequence?.id,
    )
  ) {
    return {
      possible: false,
      reason: 'not-all-same-sequence',
    };
  }

  if (
    _.any(
      profileCurrentSequences,
      (profileCurrentSequence) =>
        !!profileCurrentSequence?.sequence?.completion?.isCompleted,
    )
  ) {
    return {
      possible: false,
      reason: 'one-sequence-is-completed',
    };
  }
  const profilesActions = _.map(profiles, (profile) => {
    const currentSequence = _.findWhere(profile?.contactFlow?.sequences || [], {
      isCurrent: true,
    });
    return currentSequence?.actions;
  });

  const lastCompletedActionIndexes = _.map(
    profilesActions,
    (profileActions) => {
      const firstNotCompletedActionIndex = _.findIndex(
        profileActions || [],
        (action) => !action?.completion?.isCompleted,
      );
      if (firstNotCompletedActionIndex === -1) {
        return (profileActions || []).length - 1;
      }
      return firstNotCompletedActionIndex - 1;
    },
  );

  const lastCompletedActionIndex = Math.max(...lastCompletedActionIndexes);

  const profileSequencesLengths = _.map(
    profilesActions,
    (profileActions) => (profileActions || []).length,
  );

  const shortestProfileSequenceLength = Math.min(...profileSequencesLengths);
  if (lastCompletedActionIndex >= shortestProfileSequenceLength) {
    return {
      possible: false,
      reason: 'profiles-are-not-compatible',
    };
  }

  const shortestPrefixLength = findShortestPrefixLength({
    profilesActions,
    sequence,
  });

  const minimumNumberOfActionsToKeep = lastCompletedActionIndex + 1;
  const suffixApplicableAtIndex = findSuffixApplicableAtIndex({
    minimumNumberOfActionsToKeep,
    shortestPrefixLength,
  });

  return {
    possible: true,
    lastCompletedActionIndex,
    shortestProfileSequenceLength,
    minimumNumberOfActionsToKeep,
    suffixApplicableAtIndex,
  };
};

const findSuffixApplicableAtIndex = ({
  minimumNumberOfActionsToKeep,
  shortestPrefixLength,
}: {
  minimumNumberOfActionsToKeep: number;
  shortestPrefixLength: number;
}) => {
  if (shortestPrefixLength > 0) {
    if (shortestPrefixLength < minimumNumberOfActionsToKeep) {
      return 0;
    }
    if (minimumNumberOfActionsToKeep <= shortestPrefixLength) {
      return minimumNumberOfActionsToKeep;
    }
  }
  return 0;
};

const findShortestPrefixLength = ({
  profilesActions,
  sequence,
}: {
  profilesActions: (Action[] | undefined)[];
  sequence: Sequence;
}): number => {
  const sequenceActions: Action[] =
    sequence?.contactFlow?.sequences?.[0]?.actions || [];
  const profileSequencesPrefixLength = _.map(
    profilesActions,
    (profileActions) =>
      getProfileSequencePrefixLength({ profileActions, sequenceActions }),
  );
  return Math.min(...profileSequencesPrefixLength);
};

export const getProfileSequencePrefixLength = ({
  profileActions,
  sequenceActions,
}: {
  profileActions?: Action[];
  sequenceActions: Action[];
}): number => {
  if (!profileActions) {
    return 0;
  }
  const firstActionWithDifferentTypeIndex = _.findIndex(
    profileActions,
    (action, index) => action?.type !== sequenceActions[index]?.type,
  );
  if (firstActionWithDifferentTypeIndex === -1) {
    return profileActions.length;
  }
  return firstActionWithDifferentTypeIndex;
};

export const shouldDisplayReapplySequence = ({
  currentSequenceInfo,
}: {
  currentSequenceInfo: CurrentSequenceInfo;
}): boolean => {
  if (currentSequenceInfo?.completion?.isCompleted) {
    return false;
  }
  const lastSequenceEditionDate =
    currentSequenceInfo?.sequence?.lastEditionDate;
  const reapplicationDate = currentSequenceInfo?.reapplicationDate;
  const insertionDate = currentSequenceInfo?.insertionDate;
  if (!lastSequenceEditionDate) {
    return false;
  }
  if (reapplicationDate) {
    if (lastSequenceEditionDate > reapplicationDate) {
      return true;
    }
    return false;
  }
  if (insertionDate) {
    if (lastSequenceEditionDate > insertionDate) {
      return true;
    }
    return false;
  }

  return false;
};

export const findLastModifiedActionIndex = ({
  newContactFlow,
  oldContactFlow,
}: {
  newContactFlow: ContactFlow;
  oldContactFlow: ContactFlow;
}): number | undefined => {
  const newSequenceActions = newContactFlow?.sequences?.[0]?.actions;
  const oldSequenceActions = oldContactFlow?.sequences?.[0]?.actions;
  if (!newSequenceActions || !oldSequenceActions) {
    return undefined;
  }

  if (newSequenceActions.length !== oldSequenceActions.length) {
    return newSequenceActions.length - 1;
  }

  return _.findLastIndex(newSequenceActions, (newAction, index) => {
    const oldAction = oldSequenceActions[index];
    if (
      !_.isEqual(
        recursivelyRemoveEmptyKeyValues(oldAction),
        recursivelyRemoveEmptyKeyValues(newAction),
      )
    ) {
      return true;
    }
    return false;
  });
};
