import { TFunction } from 'i18next';
import _ from 'underscore';
import {
  MERGE_TAGS_VARIABLES_BASE_IDS,
  SNIPPET_TYPES,
} from '@/common/mergeTags/utils';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { getTranslatedText } from '@/common';

export type MergeTagsSelectorOption = {
  key: string;
  label: string;
  type: string;
  isClientSnippet?: boolean;
  needAdvancedTemplating?: boolean;
  simplifiedConditionVariable?: {
    key: string;
    isProfileCustomField?: boolean;
    isMissionCustomField?: boolean;
  };
};

type MergeTagsSelectorRawOption = Omit<MergeTagsSelectorOption, 'label'> & {
  i18nKey: string;
};

export const generateOptions = (t: TFunction) => (
  rawOptions: MergeTagsSelectorRawOption[],
): MergeTagsSelectorOption[] =>
  _.map(rawOptions, ({ i18nKey, ...rest }) => ({ ...rest, label: t(i18nKey) }));

export const CONTACT_VARIABLES: MergeTagsSelectorRawOption[] = [
  {
    key: MERGE_TAGS_VARIABLES_BASE_IDS.FIRSTNAME,
    i18nKey: 'editor.firstname',
    type: SNIPPET_TYPES.PATH,
  },
  {
    key: MERGE_TAGS_VARIABLES_BASE_IDS.CURRENT_COMPANY,
    i18nKey: 'editor.currentCompany',
    type: SNIPPET_TYPES.CURRENT_COMPANY,
  },
  {
    key: MERGE_TAGS_VARIABLES_BASE_IDS.CURRENT_JOB_TITLE,
    i18nKey: 'editor.currentJobTitle',
    needAdvancedTemplating: true,
    type: SNIPPET_TYPES.CURRENT_JOB_TITLE,
  },
  {
    key: MERGE_TAGS_VARIABLES_BASE_IDS.CURRENT_EXPERIENCE_DURATION,
    i18nKey: 'editor.currentExperienceDuration',
    needAdvancedTemplating: true,
    type: SNIPPET_TYPES.CURRENT_EXPERIENCE_DURATION,
  },
];

export const MORE_CONTACT_VARIABLES: MergeTagsSelectorRawOption[] = [
  {
    key: MERGE_TAGS_VARIABLES_BASE_IDS.LASTNAME,
    i18nKey: 'editor.lastname',
    type: SNIPPET_TYPES.PATH,
  },
  {
    key: MERGE_TAGS_VARIABLES_BASE_IDS.EMAIL,
    i18nKey: 'editor.email',
    type: SNIPPET_TYPES.PATH,
  },
  {
    key: MERGE_TAGS_VARIABLES_BASE_IDS.DURATION_SINCE_GRADUATION,
    i18nKey: 'editor.durationSinceGraduation',
    needAdvancedTemplating: true,
    type: SNIPPET_TYPES.DURATION_SINCE_GRADUATION,
  },
  {
    key: MERGE_TAGS_VARIABLES_BASE_IDS.K_LAST_COMPANIES,
    i18nKey: 'editor.kLastCompanies',
    needAdvancedTemplating: true,
    type: SNIPPET_TYPES.K_LAST_COMPANIES,
  },
];

export const PROJECT_VARIABLES: MergeTagsSelectorRawOption[] = [
  {
    key: MERGE_TAGS_VARIABLES_BASE_IDS.MISSION_TITLE,
    i18nKey: 'editor.missionTitle',
    needAdvancedTemplating: true,
    type: SNIPPET_TYPES.PATH,
  },
  {
    key: MERGE_TAGS_VARIABLES_BASE_IDS.MISSION_OWNER_FIRSTNAME,
    i18nKey: 'editor.missionOwnerFirstname',
    needAdvancedTemplating: true,
    type: SNIPPET_TYPES.PATH,
  },
  {
    key: MERGE_TAGS_VARIABLES_BASE_IDS.MISSION_OWNER_LASTNAME,
    i18nKey: 'editor.missionOwnerLastname',
    needAdvancedTemplating: true,
    type: SNIPPET_TYPES.PATH,
  },
];

export const CONDITIONS_CHAINING_VARIABLES: MergeTagsSelectorRawOption[] = [
  {
    key: 'conditions-chaining-simplified-firstname',
    i18nKey: 'editor.conditionsChainingVariable.selectorOptions.firstname',
    needAdvancedTemplating: true,
    type: SNIPPET_TYPES.CONDITIONS_CHAINING,
    simplifiedConditionVariable: {
      key: 'firstname',
    },
  },
  {
    key: 'conditions-chaining-simplified-gender',
    i18nKey: 'editor.conditionsChainingVariable.selectorOptions.gender',
    needAdvancedTemplating: true,
    type: SNIPPET_TYPES.CONDITIONS_CHAINING,
    simplifiedConditionVariable: {
      key: 'gender',
    },
  },
  {
    key: MERGE_TAGS_VARIABLES_BASE_IDS.CONDITIONS_CHAINING,
    i18nKey: 'editor.conditionsChainingVariable.selectorOptions.advanced',
    needAdvancedTemplating: true,
    type: SNIPPET_TYPES.CONDITIONS_CHAINING,
  },
];

export const getContactCustomFieldOptions = (
  t: TFunction,
  contactCustomFields: CustomFieldDefinition[],
): MergeTagsSelectorOption[] =>
  _.map(contactCustomFields, (cf) => ({
    key: `profile-custom-field/conditions-chaining-${cf.id}`,
    label: `${t('editor.conditionsChainingVariable.title')} ${getTranslatedText(
      cf.title,
    )}`,
    needAdvancedTemplating: true,
    type: SNIPPET_TYPES.CONDITIONS_CHAINING,
    simplifiedConditionVariable: {
      key: cf.id,
      isProfileCustomField: true,
    },
  }));

export const getProjectCustomFieldOptions = (
  t: TFunction,
  projectCustomFields: CustomFieldDefinition[],
): MergeTagsSelectorOption[] =>
  _.map(projectCustomFields, (cf) => ({
    key: `mission-custom-field/conditions-chaining-${cf.id}`,
    label: `${t('editor.conditionsChainingVariable.title')} ${getTranslatedText(
      cf.title,
    )}`,
    needAdvancedTemplating: true,
    type: SNIPPET_TYPES.CONDITIONS_CHAINING,
    simplifiedConditionVariable: {
      key: cf.id,
      isMissionCustomField: true,
    },
  }));

export const getConditionsChainingVariableOptions = (
  t: TFunction,
  contactCustomFields: CustomFieldDefinition[],
  projectCustomFields: CustomFieldDefinition[],
): MergeTagsSelectorOption[] => [
  ...generateOptions(t)(CONDITIONS_CHAINING_VARIABLES),
  ...getContactCustomFieldOptions(t, contactCustomFields),
  ...getProjectCustomFieldOptions(t, projectCustomFields),
];
