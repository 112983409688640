import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import GenericButton from '@/components/Common/GenericButton';

import PendingIllustration from '../PendingIllustration';
import CardEmptyState from '../CardEmptyState';

interface PendingMainEmptyStateProps {
  offerId: string;
  count: number;
  isPrimary: boolean;
  onChangeStep: (next: { newStepId: string; newSubStepId: string }) => void;
}

const PendingMainEmptyState: FC<PendingMainEmptyStateProps> = ({
  offerId,
  count,
  isPrimary,
  onChangeStep,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PendingIllustration offerId={offerId} subStep='pending-main' />
      <CardEmptyState
        title={t('offers.profileEmptyStates.pendingMain.title', { count })}
        cta={
          <GenericButton
            primacy={isPrimary ? 'primary' : 'secondary'}
            onClick={() =>
              onChangeStep({
                newStepId: 'pending',
                newSubStepId: 'pending-main',
              })
            }
          >
            {t('offers.profileEmptyStates.pendingMain.cta')}
          </GenericButton>
        }
      >
        {t('offers.profileEmptyStates.pendingMain.innerContent', {
          count,
        })}
      </CardEmptyState>
    </>
  );
};

export default PendingMainEmptyState;
