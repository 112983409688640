import _, { compose, debounce } from 'underscore';
import React, { useCallback, useMemo } from 'react';
import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import createActionInputFromAction from '../../../../../common/contactFlow/createActionInputFromAction';
import getPermissionsAndNudgesAtIndex from '../../../../../common/contactFlow/getPermissionsAndNudgesAtIndex';

import withAddContactFlowAction from '../../../../../hocs/searchPoolProfile/withAddContactFlowAction';
import withDeleteContactFlowAction from '../../../../../hocs/searchPoolProfile/withDeleteContactFlowAction';
import withUpdateContactFlowAction from '../../../../../hocs/searchPoolProfile/withUpdateContactFlowAction';
import {
  isEmailAction,
  TASK_TYPES,
} from '../../../../../common/constants/taskTypes';
import EmailAction from '../Actions/EmailAction';
import GenericAction from '../Actions/GenericAction';
import FollowupButton from '../Email/FollowupButton';

import './DisplayedSequence.css';

const DisplayedSequence = ({
  sequence,
  profileId,
  searchPoolId,
  profile,
  t,
  addContactFlowAction,
  deleteContactFlowAction,
  updateContactFlowAction,
  emailApi,
  user,
  clientId,
  onSend,
  templates,
  task,
  firstEmailOnly,
  canShowDefaultTemplateReplies,
  messageType,
}) => {
  const onUpdateAction = useCallback(
    ({ actionId, updatedAction }) => {
      const actionInput = createActionInputFromAction({
        action: _.omit(updatedAction, 'indexInSequence', 'emailIndex'),
      });
      const input = {
        id: profileId,
        sequenceId: sequence.id,
        actionId,
        actionInput,
        ...(!_.isEmpty(updatedAction.snippets) && {
          snippets: MergeTagsService.createSnippetsInput({
            subject: updatedAction.message.subject,
            body: updatedAction.message.body,
            snippets: updatedAction.snippets,
          }),
        }),
      };
      return updateContactFlowAction({ searchPoolId: 'reveal', input });
    },
    [profileId, sequence, updateContactFlowAction],
  );

  const onUpdateActionMemoized = useMemo(() => debounce(onUpdateAction, 1000), [
    onUpdateAction,
  ]);

  if (_.isEmpty(sequence)) {
    return null;
  }
  const followupPosition = (sequence?.actions || []).length;

  let emailIndex = -1;
  const actionsToCome = _.compact(
    _.map((sequence || {}).actions, (action, index) => {
      if (action?.completion?.isCompleted) {
        return null;
      }
      if (isEmailAction(action)) {
        emailIndex += 1;
      }
      return {
        ...action,
        indexInSequence: index,
        ...(isEmailAction(action) && { emailIndex }),
      };
    }),
  );

  const addAction = ({ index, actionToInsert }) => {
    const actionInput = createActionInputFromAction({ action: actionToInsert });
    const input = {
      id: profileId,
      sequenceId: sequence.id,
      actionInput,
      actionIndex: index,
    };
    return addContactFlowAction({ searchPoolId: 'reveal', input });
  };

  const onDeleteAction = ({ actionId }) => {
    const input = {
      id: profileId,
      sequenceId: sequence.id,
      actionId,
      ...(task && { task: _.pick(task, 'id', 'projectId', 'queueId') }),
    };
    return deleteContactFlowAction({ searchPoolId: 'reveal', input });
  };

  if (sequence?.completion?.isCompleted) {
    return (
      <div className='completed-sequence-label'>
        {t('contactFlow.sequences.currentSequenceCompleted')}
      </div>
    );
  }

  const onAddFollowup = () => {
    const actionToInsert = {
      type: TASK_TYPES.SEND_EMAIL,
      trigger: {
        type: 'delay-after-action',
        delay: { value: 5, unit: 'working-day' },
      },
      message: {
        subject: '',
        body: '',
      },
    };

    addAction({ index: (sequence?.actions || []).length, actionToInsert });
  };

  let hasReachedNonEmailAction = false;
  const contiguousEmailActions = [];
  const otherActions = [];

  _.each(actionsToCome, (action, index) => {
    if (hasReachedNonEmailAction) {
      otherActions.push(action);
      return;
    }
    if (!isEmailAction(action)) {
      hasReachedNonEmailAction = true;
      otherActions.push(action);
      return;
    }
    if (index === 0 || !firstEmailOnly) {
      contiguousEmailActions.push(action);
    } else {
      otherActions.push(action);
    }
  });

  return (
    <div className='email-content displayed-sequence'>
      {_.map(contiguousEmailActions, (action, indexInActionsToCome) => {
        const permissionsAndNudges = getPermissionsAndNudgesAtIndex({
          actions: sequence.actions,
          index: action.indexInSequence,
          editType: 'update',
          t,
        });
        return isEmailAction(action) ? (
          <EmailAction
            key={action.actionId}
            sequenceId={sequence?.id}
            action={action}
            indexInActionsToCome={indexInActionsToCome}
            profile={profile}
            searchPoolId={searchPoolId}
            onDeleteAction={() => onDeleteAction({ actionId: action.actionId })}
            onUpdateAction={({ updatedAction }) =>
              onUpdateActionMemoized({
                actionId: action.actionId,
                updatedAction,
              })
            }
            emailApi={emailApi}
            permissionsAndNudges={permissionsAndNudges}
            t={t}
            user={user}
            clientId={clientId}
            onSend={onSend}
            templates={templates}
            task={task}
            canShowDefaultTemplateReplies={canShowDefaultTemplateReplies}
          />
        ) : (
          <GenericAction key={action?.actionId} action={action} />
        );
      })}
      {/* HACK */}
      {/* {_.isEmpty(otherActions) && !firstEmailOnly && (
        <FollowupButton
          onAddFollowup={onAddFollowup}
          followupPosition={followupPosition}
        />
      )} */}
    </div>
  );
};

export default compose(
  withAddContactFlowAction,
  withDeleteContactFlowAction,
  withUpdateContactFlowAction,
)(DisplayedSequence);
