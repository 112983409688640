import React, { FC, useMemo, useState, useContext } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import SettingsLayout from '@/containers/SettingsLayout';
import * as Sentry from '@sentry/browser';
import Segmentations from '@/components/PipelineSegmentation/data';
import GenericButton from '@/components/Common/GenericButton';
import { useMutation } from '@apollo/client';
import { PlusIcon } from '@/assets/icons';
import useClient from '@/graphql/hooks/clients/useClient';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { useUpdateClientProjectNomenclature } from '@/graphql/hooks/clients/useClientProjectNomenclature';
import InfoBox from '@/components/Common/InfoBox';
import useClientId from '@/hooks/router/useClientId';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import useProjectNomenclatures from '@/graphql/hooks/clients/useProjectNomenclatures';
import usePipelineSubSteps from '@/graphql/hooks/clients/usePipelineSubSteps';
import useUpdateClientPipelineSubSteps from '@/graphql/hooks/clients/useUpdateClientPipelineSubSteps';
import useMissionInterestedSubPipelines from '@/graphql/hooks/clients/useMissionInterestedSubPipelines';
import SuperPipelineSegmentTable from './ClientSubsteps/SuperPipelineSegmentTable';
import StepAndSubstepEditionModal from './ClientSubsteps/StepAndSubstepEditionModal';

import { getRandomString, getTranslatedText } from '../../../common/index';
import ClientPipelinesTable from './ClientPipelines/ClientPipelinesTable';
import {
  ClientPipeline,
  CREATE_CLIENT_MISSION_INTERESTED_SUB_PIPELINE,
  DELETE_CLIENT_MISSION_INTERESTED_SUB_PIPELINE,
  UPDATE_CLIENT_MISSION_INTERESTED_SUB_PIPELINE,
} from '../../../graphql/clients';
import CreateAndEditPipelineModal from './ClientPipelines/CreateAndEditPipelineModal';
import DeletePipelineModal from './ClientPipelines/DeletePipelineModal';
import { sanitizeTypename } from '../../../common/utils/apollo';
import HiredNomenclatureModal from './ClientSubsteps/HiredNomenclatureModal';

import styles from './SuperPipelineSettings.module.less';

const segmentation = Segmentations[0];

const SuperPipelineSettings: FC = () => {
  const clientId = useClientId();
  const missionCategoryType = useContext(MissionCategoryContext);
  const { t } = useTranslation();
  const { data: clientData } = useClient(clientId);
  const { client } = clientData;
  const notification = useNotificationSystem();
  const [selectedSubstepId, setSelectedSubstepId] = useState('');
  const [newSubstepModalOpen, setNewSubstepModalOpen] = useState(false);
  const [hoveredSubStepId, setHoveredSubstepId] = useState('');
  const [newSubstepIndex, setNewSubstepIndex] = useState(-1);
  const [
    substepNameEditionModalOpen,
    setSubstepNameEditionModalOpen,
  ] = useState(false);
  const [stepNameEditionModalOpen, setStepNameEditionModalOpen] = useState(
    false,
  );
  const [stepName, setStepName] = useState('');
  const { pipelineSubSteps } = usePipelineSubSteps();
  const subStepsDefinition = useMemo(
    () =>
      _.find(
        pipelineSubSteps,
        ({ missionCategory, stepId }) =>
          missionCategory.type === missionCategoryType &&
          stepId === 'interested',
      ),
    [pipelineSubSteps, missionCategoryType],
  );
  const missionInterestedSubSteps = useMemo(
    () => subStepsDefinition?.subSteps || [],
    [subStepsDefinition],
  );
  const [newPipelineModalOpen, setNewPipelineModalOpen] = useState(false);
  const [updatePipelineModalOpen, setUpdatePipelineModalOpen] = useState(false);
  const [deletePipelineModalOpen, setDeleteNewPipelineModalOpen] = useState(
    false,
  );
  const [selectedPipelineId, setSelectedPipelineId] = useState('');
  const [substepEditMode, setSubstepEditMode] = useState(false);

  const [updateProjectNomenclature] = useUpdateClientProjectNomenclature();
  const { projectNomenclatures } = useProjectNomenclatures();

  const projectNomenclature = useMemo(
    () =>
      _.find(
        projectNomenclatures,
        ({ missionCategory }) => missionCategory.type === missionCategoryType,
      ),
    [projectNomenclatures, missionCategoryType],
  );

  const defaultSuccessStepId = useMemo(
    () => (missionCategoryType === 'sales' ? 'signed' : 'hired'),
    [missionCategoryType],
  );

  const successStepId = useMemo(
    () => projectNomenclature?.successStep?.id || defaultSuccessStepId,
    [projectNomenclature?.successStep?.id, defaultSuccessStepId],
  );

  const { missionInterestedSubPipelines } = useMissionInterestedSubPipelines();

  // flatten departments with sub-departments and sub-sub-departments to create options for select input
  const flattenDepartments = _.flatten(
    _.map(client?.departments, (department) => {
      return [
        {
          ...department,
          type: 'department',
        },
        ..._.flatten(
          _.map(department?.sections || [], (section) => {
            return [
              {
                ...section,
                type: 'section',
              },
              ..._.map(section?.subsections || [], (subsection) => ({
                ...subsection,
                type: 'subsection',
              })),
            ];
          }),
        ),
      ];
    }),
  );

  const pipelines = useMemo(
    () =>
      _.filter(
        missionInterestedSubPipelines,
        ({ missionCategory }) => missionCategory.type === missionCategoryType,
      ),
    [missionInterestedSubPipelines, missionCategoryType],
  );

  const [createPipeline] = useMutation(
    CREATE_CLIENT_MISSION_INTERESTED_SUB_PIPELINE,
  );
  const [updatePipeline] = useMutation(
    UPDATE_CLIENT_MISSION_INTERESTED_SUB_PIPELINE,
  );
  const [deletePipeline] = useMutation(
    DELETE_CLIENT_MISSION_INTERESTED_SUB_PIPELINE,
  );

  const segmentsWithSubsteps = useMemo(
    () =>
      _.map(segmentation.segments, (segment) => ({
        ...segment,
        ...(segment.id === 'interested' && {
          substeps: missionInterestedSubSteps,
        }),
        name: t(`reveal.pipelineSegmentations.${segment.id}`),
        ...(segment.id === 'hired' && {
          name: t(
            `superPipelineSettings.nomenclature.options.${successStepId}`,
            { count: 10 },
          ),
        }),
      })),
    [missionInterestedSubSteps, t, successStepId],
  );

  const [updateClientPipelineSubSteps] = useUpdateClientPipelineSubSteps();

  const handleAddSubstep = () => {
    const newSubstep = {
      id: getRandomString(6),
      title: {
        default: stepName,
      },
    };
    const cleanSubsteps = sanitizeTypename(missionInterestedSubSteps);
    const subSteps = [
      ...cleanSubsteps.slice(0, newSubstepIndex),
      newSubstep,
      ...cleanSubsteps.slice(newSubstepIndex),
    ];
    updateClientPipelineSubSteps({
      missionCategory: { type: missionCategoryType },
      stepId: 'interested',
      subSteps,
    });
    setNewSubstepModalOpen(false);
    setStepName('');
    setSubstepEditMode(false);
  };

  const handleDeleteSubstep = ({ substepId }: { substepId: string }) => {
    const subSteps = sanitizeTypename(
      _.filter(
        missionInterestedSubSteps,
        (substep) => substep.id !== substepId,
      ),
    );
    updateClientPipelineSubSteps({
      missionCategory: { type: missionCategoryType },
      stepId: 'interested',
      subSteps,
    });
  };

  const handleSaveNewSubstepName = () => {
    const subSteps = sanitizeTypename(
      _.map(missionInterestedSubSteps, (substep) => ({
        ...substep,
        ...(substep.id === selectedSubstepId && {
          title: {
            default: stepName,
          },
        }),
      })),
    );
    updateClientPipelineSubSteps({
      missionCategory: { type: missionCategoryType },
      stepId: 'interested',
      subSteps,
    });
    setSubstepNameEditionModalOpen(false);
    setStepName('');
  };

  const isNewStepNameAlreadyUsed =
    !!_.find(
      missionInterestedSubSteps,
      (substep) => stepName === getTranslatedText(substep.title),
    ) || false;

  const hoveredSubstepIndex = hoveredSubStepId
    ? _.findIndex(
        missionInterestedSubSteps,
        (substep) => substep.id === hoveredSubStepId,
      )
    : -1;
  const substepIdsWithVisibleSubstepAddition =
    hoveredSubstepIndex >= 0
      ? _.pluck(
          missionInterestedSubSteps.slice(
            hoveredSubstepIndex,
            hoveredSubstepIndex + 2,
          ),
          'id',
        )
      : [];

  const handleCreatePipeline = async (pipeline: Partial<ClientPipeline>) => {
    createPipeline({
      variables: {
        clientId,
        input: sanitizeTypename(pipeline),
      },
      onError: (e) => {
        const error = e.graphQLErrors?.[0];
        notification.error(
          `${t(`superPipelineSettings.errorPrefix`, {
            operation: t('superPipelineSettings.creation'),
          })} ${t(
            `superPipelineSettings.${error.message}`,
            error.message.indexOf('existing-name') > 0
              ? {
                  name: pipeline.title?.default,
                }
              : {},
          )}`,
        );
        Sentry.captureException(error);
      },
    });
    setNewPipelineModalOpen(false);
  };

  const handleDeletePipeline = () => {
    deletePipeline({
      variables: {
        clientId,
        input: {
          id: selectedPipelineId,
        },
      },
    });
    setDeleteNewPipelineModalOpen(false);
  };

  const handleUpdatePipeline = (pipeline: Partial<ClientPipeline>) => {
    updatePipeline({
      variables: {
        clientId,
        input: sanitizeTypename(pipeline),
      },
      onError: (e) => {
        const error = e.graphQLErrors?.[0];
        notification.error(
          `${t(`superPipelineSettings.errorPrefix`, {
            operation: t('superPipelineSettings.update'),
          })} ${t(
            `superPipelineSettings.${error.message}`,
            error.message.indexOf('existing-name') > 0
              ? {
                  name: pipeline.title?.default,
                }
              : {},
          )}`,
        );
        Sentry.captureException(error);
      },
    });
    setUpdatePipelineModalOpen(false);
  };

  const handleEditHiredName = (id: string) => {
    updateProjectNomenclature({
      variables: {
        input: {
          successStep: {
            id,
          },
          missionCategory: {
            type: missionCategoryType,
          },
        },
      },
    });
    setStepNameEditionModalOpen(false);
  };

  const usedDepartments = _.compact([
    ..._.pluck(
      _.map(pipelines, (pipeline) => pipeline.scopeDescriptor),
      'departmentId',
    ),
    ..._.pluck(
      _.map(pipelines, (pipeline) => pipeline.scopeDescriptor),
      'sectionId',
    ),
    ..._.pluck(
      _.map(pipelines, (pipeline) => pipeline.scopeDescriptor),
      'subsectionId',
    ),
  ]);

  return (
    <SettingsLayout
      currentPage='pipeline'
      clientId={clientId}
      className='settings-container'
    >
      <div className='settings'>
        <div className='settings-header'>
          <h1>{t('superPipelineSettings.header')}</h1>
        </div>
        <h2 className={styles.stepsTitle}>
          {t('superPipelineSettings.defaultPipeline')}
        </h2>
        <div className={styles.superPipelineSettingsContainer}>
          <div className={styles.description}>
            {t('superPipelineSettings.description')}
          </div>
          <div className={styles.infoBox}>
            <p className={styles.infoBoxTitle}>
              {t('superPipelineSettings.infoBox.title')}
            </p>
            <p className={styles.infoBoxDescription}>
              {t('superPipelineSettings.infoBox.description')}
            </p>
          </div>
          <SuperPipelineSegmentTable
            segmentsWithSubsteps={segmentsWithSubsteps}
            onHoverSubstep={setHoveredSubstepId}
            substepIdsWithVisibleSubstepAddition={
              substepIdsWithVisibleSubstepAddition
            }
            onOpenNewSubstepModal={setNewSubstepModalOpen}
            onNewSubstepIndex={setNewSubstepIndex}
            onDeleteSubstep={handleDeleteSubstep}
            onSelectedStep={setSelectedSubstepId}
            hoveredSubstepIndex={hoveredSubstepIndex}
            onOpenSubstepNameEditionModal={setSubstepNameEditionModalOpen}
            onOpenStepNameEditionModal={setStepNameEditionModalOpen}
            onChangeSubstepName={setStepName}
            substepEditMode={substepEditMode}
            onSubstepEditMode={setSubstepEditMode}
          />
          <StepAndSubstepEditionModal
            title={t('superPipelineSettings.newSubstepModalTitle')}
            open={newSubstepModalOpen}
            setOpen={setNewSubstepModalOpen}
            value={stepName}
            setValue={setStepName}
            disabled={!stepName.length || isNewStepNameAlreadyUsed}
            onSubmit={handleAddSubstep}
            isSubstep
          />
          <StepAndSubstepEditionModal
            title={t('superPipelineSettings.editSubstepModalTitle')}
            open={substepNameEditionModalOpen}
            setOpen={setSubstepNameEditionModalOpen}
            value={stepName}
            setValue={setStepName}
            disabled={!stepName.length || isNewStepNameAlreadyUsed}
            onSubmit={handleSaveNewSubstepName}
            isSubstep
            isEdition
          />
          <HiredNomenclatureModal
            open={stepNameEditionModalOpen}
            setOpen={setStepNameEditionModalOpen}
            initialValue={successStepId}
            onSubmit={handleEditHiredName}
          />
        </div>
      </div>
      <div className='settings'>
        <h2 className={styles.stepsTitle}>
          {t('superPipelineSettings.scopedPipelines')}
        </h2>
        <div className={styles.superPipelineSettingsContainer}>
          <InfoBox
            className={styles.scopedPipelineInfoBox}
            title={t('superPipelineSettings.scopedPipelinesInfoBox.title')}
            description={t(
              'superPipelineSettings.scopedPipelinesInfoBox.description',
            )}
          />
          <ClientPipelinesTable
            pipelines={pipelines}
            setSelectedPipelineId={setSelectedPipelineId}
            setDeleteNewPipelineModalOpen={setDeleteNewPipelineModalOpen}
            setUpdateNewPipelineModalOpen={setUpdatePipelineModalOpen}
            departments={flattenDepartments}
          />
          <CreateAndEditPipelineModal
            title={t('superPipelineSettings.newPipeline')}
            open={newPipelineModalOpen}
            setOpen={setNewPipelineModalOpen}
            clientSubsteps={missionInterestedSubSteps}
            pipeline={{
              steps: missionInterestedSubSteps,
              missionCategory: { type: missionCategoryType },
            }}
            onCheckName={(name: string, id: string) =>
              !!_.find(
                pipelines,
                (pipeline) =>
                  name === pipeline.title.default && id !== pipeline.id,
              )
            }
            updatePipeline={handleCreatePipeline}
            departments={flattenDepartments || []}
            usedDepartments={usedDepartments}
          />
          <CreateAndEditPipelineModal
            title={t('superPipelineSettings.editPipelineModalTitle')}
            open={updatePipelineModalOpen}
            setOpen={setUpdatePipelineModalOpen}
            isEdition
            clientSubsteps={missionInterestedSubSteps}
            pipeline={_.findWhere(pipelines, { id: selectedPipelineId })}
            onCheckName={(name: string, id: string) =>
              !!_.find(
                pipelines,
                (pipeline) =>
                  name === pipeline.title.default && id !== pipeline.id,
              )
            }
            updatePipeline={handleUpdatePipeline}
            departments={flattenDepartments || []}
            usedDepartments={usedDepartments}
          />
          <DeletePipelineModal
            title={t('superPipelineSettings.deletePipelineModalTitle')}
            open={deletePipelineModalOpen}
            setOpen={setDeleteNewPipelineModalOpen}
            onSubmit={handleDeletePipeline}
          />
          <GenericButton
            primacy='primary'
            onClick={() => setNewPipelineModalOpen(true)}
          >
            <PlusIcon />
            {t('superPipelineSettings.newPipeline')}
          </GenericButton>
        </div>
      </div>
    </SettingsLayout>
  );
};

export default SuperPipelineSettings;
