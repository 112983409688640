import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import GenericButton from '@/components/Common/GenericButton';
import { getATSName } from '@/common/reveal/utils';
import { TrashIcon } from '@/assets/icons';
import { ATStype } from '@/common/reveal';

import useUserSettings from '@/graphql/hooks/users/useUserSettings';
import { GET_USER_SETTINGS, UPDATE_ATS_USER } from '@/graphql/users';
import styles from './RevealIntegrationLinkedUser.module.less';
import LinkedUserSelectionModal from './LinkedUserSelectionModal';

interface RevealIntegrationLinkedUserProps {
  type: ATStype;
  clientId: string;
}

const RevealIntegrationLinkedUser: React.FC<RevealIntegrationLinkedUserProps> = ({
  type,
  clientId,
}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const { data: userData } = useUserSettings();
  const user = userData?.user as Record<string, any>;
  const linkedUserName = user?.[`${type}UserName`] || '';
  const linkedUserId = user?.[`${type}UserId`] || '';

  const [updateATSUser] = useMutation(UPDATE_ATS_USER);

  const handleRemoveATSUser = () => {
    updateATSUser({
      variables: {
        input: {
          atsType: type,
          userName: '',
          userId: '',
        },
      },
      refetchQueries: [
        {
          query: GET_USER_SETTINGS,
          variables: {
            searchPoolId: 'reveal',
          },
        },
      ],
    });
  };

  return (
    <div className='section visible'>
      <div className='input-container'>
        <div className={styles.title}>
          {t('settings.linkedATSUser.title', { ATS: getATSName(type) })}
        </div>
        <div className='input-description'>
          {t('settings.linkedATSUser.subtitle', { ATS: getATSName(type) })}
        </div>
        <div className={styles.linkedATSUserContainer}>
          {linkedUserName ? (
            <>
              <div className={styles.linkedUser}>
                {t('settings.linkedATSUser.linked', {
                  username: linkedUserName,
                })}
              </div>
              <GenericButton
                primacy='secondary'
                onClick={() => setModalOpen(true)}
              >
                {t('common.edit')}
              </GenericButton>
              <div
                className={styles.removeLinkedUser}
                onClick={() => handleRemoveATSUser()}
              >
                <TrashIcon className='remove-ats-user' />
              </div>
            </>
          ) : (
            <>
              <div className={styles.linkedUser}>
                {t('settings.linkedATSUser.notLinked', {
                  ATS: getATSName(type),
                })}
              </div>
              <GenericButton
                primacy='secondary'
                onClick={() => setModalOpen(true)}
              >
                {t('common.edit')}
              </GenericButton>
            </>
          )}
        </div>
      </div>
      <LinkedUserSelectionModal
        open={modalOpen}
        atsType={type}
        setOpen={setModalOpen}
        clientId={clientId}
        atsUserId={linkedUserId}
      />
    </div>
  );
};

export default RevealIntegrationLinkedUser;
