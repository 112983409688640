import React, { FC, useMemo } from 'react';
import _ from 'underscore';
import { StackItem } from '@/types/statistics/stackItem';
import { NumberRecord, Stats } from '@/types/statistics/stats';
import {
  filterStack,
  getMaxTotalCount,
  getTicks,
} from '@/common/helpers/stats';
import TooltipBoundary from '@/components/Common/TooltipBoundary';
import Legend from '../Legend';
import StackedArea from './StackedArea';

import styles from './StackedAreaChart.module.less';

const MINIMUM_RATIO = 0.007; // Roughly 2px/280px

interface StackedAreaChartProps {
  stack: StackItem[];
  allStats: Stats[];
  initialValues?: NumberRecord;
  isPercentage?: boolean;
  exhaustive?: boolean;
}

const StackedAreaChart: FC<StackedAreaChartProps> = ({
  stack,
  allStats,
  initialValues,
  isPercentage,
  exhaustive,
}) => {
  const maxCount = useMemo(
    () => (isPercentage ? 100 : getMaxTotalCount(allStats)),
    [allStats, isPercentage],
  );
  const filteredStack = exhaustive ? stack : filterStack(stack, allStats);
  const ticks = useMemo(
    () => (isPercentage ? [0, 25, 50, 75, 100] : getTicks(maxCount, 4)),
    [maxCount, isPercentage],
  );

  // We bump up smaller non-zero values so that they still appear in the chart
  const compressedStats = _.map(allStats, ({ name, link, values }) => ({
    name,
    link,
    values: _.mapObject(values, (value) =>
      value ? Math.max(value, MINIMUM_RATIO * maxCount) : 0,
    ),
  }));

  const compressedMaxCount = getMaxTotalCount(compressedStats);

  return (
    <>
      <Legend stack={filteredStack} />
      <div className={styles.graph}>
        <div className={styles.scale}>
          {_.map(ticks, (tick) => (
            <span className={styles.tick} key={tick}>
              {tick}
            </span>
          ))}
        </div>
        <div className={styles.dataContainer}>
          <TooltipBoundary>
            <div className={styles.scrollableData}>
              <div className={styles.data}>
                <div className={styles.rulers}>
                  {_.map(ticks, (tick) => (
                    <div className={styles.ruler} key={tick} />
                  ))}
                </div>
                <div className={styles.areas}>
                  {_.map(allStats, (stats, index) => (
                    <div className={styles.section} key={index}>
                      <StackedArea
                        stack={stack}
                        stats={stats}
                        previousValues={
                          index === 0
                            ? initialValues
                            : allStats[index - 1]?.values
                        }
                        topBoundary={compressedMaxCount}
                        isPercentage={isPercentage}
                      />
                    </div>
                  ))}
                </div>
                <div className={styles.labels}>
                  {_.map(allStats, (stats, index) => (
                    <div className={styles.label} key={index}>
                      {stats.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </TooltipBoundary>
        </div>
      </div>
    </>
  );
};

export default StackedAreaChart;
