import React from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import { Checkbox } from 'semantic-ui-react';


const CheckboxList = (props) => {
  const { className, options, selectValue, unSelectValue, selectedValues, radio } = props;

  const onChange = ({ value }) => {
    if (!isChecked(value, selectedValues)) {
      selectValue(value);
    } else {
      unSelectValue(value);
    }
  };

  return (
    <div className={classNames('checkbox-list', className)}>
      {_.map(options, (option, index) => (
        <CheckboxListItem
          first={index === 0}
          key={option?.value}
          onChange={() => onChange(option)}
          checked={isChecked(option.value, selectedValues)}
          label={option?.text}
          radio={radio}
        />
      ))}
    </div>
  );
};

const isChecked = (value, selectedValues) => {
  return _.contains(selectedValues, value);
};

export const CheckboxListItem = ({ first, onChange, checked, label, radio }) => {
  return (
    <Checkbox
      className={classNames('checkbox-list-item', first && 'checkbox-list-item-first', radio ? 'radio' : 'multi')}
      onChange={onChange}
      checked={checked}
      label={label}
      radio={radio}
    />
  );
};

export default CheckboxList;
