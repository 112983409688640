import gql from 'graphql-tag';
import { MiniOffer } from '@/types/offer';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

const GET_MINI_OFFER = gql`
  query getMiniOffer($offerId: ID!) {
    offer(id: $offerId) {
      id
      title
      isArchived
    }
  }
`;

interface GetMiniOfferVariables {
  offerId: string;
}

interface GetMiniOfferData {
  offer: MiniOffer;
}

interface UseMiniOfferInput extends GetMiniOfferVariables {
  queryOptions?: QueryHookOptions<GetMiniOfferData, GetMiniOfferVariables>;
}

interface UseMiniOfferResult
  extends QueryResult<GetMiniOfferData, GetMiniOfferVariables> {
  miniOffer: MiniOffer | undefined;
}

function useMiniOffer({
  offerId,
  queryOptions = {},
}: UseMiniOfferInput): UseMiniOfferResult {
  const query = useQuery<GetMiniOfferData, GetMiniOfferVariables>(
    GET_MINI_OFFER,
    { ...queryOptions, variables: { offerId } },
  );

  return {
    ...query,
    miniOffer: query.data?.offer,
  };
}

export default useMiniOffer;
