import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import ContactFlow from '../../graphql/fragments/ContactFlow';

export const mutation = gql`
  mutation updateContactFlowAction(
    $searchPoolId: ID!
    $input: UpdateContactFlowActionInput
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      updateContactFlowAction(input: $input) {
        profile {
          id
          contactFlow {
            ...ContactFlow
          }
        }
      }
    }
  }
  ${ContactFlow}
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    updateContactFlowAction: ({ searchPoolId, input }) =>
      mutate({
        variables: { searchPoolId, input },
      }),
  }),
});
