import { literalTypeChecker } from '@/common/utils/types';
import { Author } from '../author';

export interface ClientDailyEvent {
  day: string;
  userStats: {
    author: Author;
    stats: {
      type: string;
      count: number;
    }[];
  }[];
}

const sequenceTaskTypes = [
  'nbEnrolled',
  'nbContacted',
  'nbSentWithEmailOpenTracking',
  'nbSentWithLinkClickTracking',
  'nbOpened',
  'nbClicked',
  'nbAnswered',
  'nbPositiveAnswered',
] as const;

export type SequenceTaskType = typeof sequenceTaskTypes[number];

export const isSequenceTaskType = literalTypeChecker(sequenceTaskTypes);

export const displayedTaskTypes = [
  'nbEnrolled',
  'nbContacted',
  'nbOpened',
  'nbClicked',
  'nbAnswered',
  'nbPositiveAnswered',
] as const;

export type DisplayedTaskType = typeof displayedTaskTypes[number];

export const exclusiveTaskTypes = [
  'nbContacted',
  'nbAnswered',
  'nbPositiveAnswered',
] as const;

export type ExclusiveTaskType = typeof exclusiveTaskTypes[number];

export const isExclusiveTaskType = literalTypeChecker(exclusiveTaskTypes);

export const prefixTaskTypes = [
  'nbContacted',
  'nbAnswered',
  'nbPositiveAnswered',
] as const;

export type PrefixTaskType = typeof prefixTaskTypes[number];

export const isPrefixTaskType = literalTypeChecker(prefixTaskTypes);

export interface ClientDailyCohort {
  day: string;
  details: {
    sequenceId: string;
    type: string;
    count: number;
  }[];
}

export interface PrefixStatisticsNode {
  code: string;
  counts: Record<PrefixTaskType, number>;
  children: PrefixStatisticsNode[];
}

export type SequenceStatistic = {
  id: string;
  title: string;
  statistics: Record<SequenceTaskType, number>;
  prefixStatistics: PrefixStatisticsNode;
};

const clientMissionActionTypes = ['nbSourced', 'nbRemoved'] as const;

export type ClientMissionActionType = typeof clientMissionActionTypes[number];

export const isClientMissionActionType = literalTypeChecker(
  clientMissionActionTypes,
);

export interface ClientMissionDailyEvent {
  day: string;
  details: DailyEventDetail[];
}

type DailyEventDetail =
  | {
      type: 'nbSourced';
      missionId: string;
      count: number;
    }
  | {
      type: 'nbRemoved';
      missionId: string;
      count: number;
      removalReason?: string;
    };
