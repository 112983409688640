import React from 'react';
import { Form, Button } from 'semantic-ui-react';

import EmailEditor from '../../../../Editor';

export default class EditComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialComment: props.initialValue,
      comment: props.initialValue,
    };
  }

  handleSubmit = () => {
    this.props.onSubmit(this.state.comment);
  };

  handleChange = (comment) => {
    this.setState({ comment });
  };

  render() {
    const { initialComment } = this.state;
    const { profile, t } = this.props;
    if (!profile) {
      return null;
    }

    return (
      <div className='comment-content'>
        <Form>
          <EmailEditor
            clientId={this.props.clientId}
            profileId={this.props.profile.id}
            defaultValue={initialComment}
            onChange={this.handleChange}
            mode='comment'
            placeholder={t('editor.placeholder.commentBody')}
            alwaysShowMenu
          />
          <Button
            onMouseDown={this.handleSubmit}
            className='primary-cta save-comment-button'
          >
            {t('profile.contact.timeline.save')}
          </Button>
        </Form>
      </div>
    );
  }
}
