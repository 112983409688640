import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { AuthResponse } from 'msal';
import _ from 'underscore';
import authProvider, {
  msalConfig,
  REDIRECT_URI,
  MS_CLIENT_ID,
} from '../msAuthProvider';
import { getHeaders, openPopup, sleep } from '../helpers';
import { MS_GRAPH_ENDPOINTS, MS_SCOPES } from './constants';

interface OutlookResponse {
  status: string;
  code?: string;
  state?: string;
}

let detectedAuthorizationCode: string | null = '';
let detectedAuthorizationState: string | null = '';
const receiveAuthorizationCode = (event: MessageEvent) => {
  const originCheck = /localhost:8070$|hiresweet\.com$/g;
  if (
    typeof event.data === 'string' &&
    event.data.slice(0, 22) === 'ms-authorization-code-' &&
    originCheck.test(event.origin)
  ) {
    const popupUrl = new URL(event.data.slice(22));
    detectedAuthorizationCode = popupUrl.searchParams.get('code');
    detectedAuthorizationState = popupUrl.searchParams.get('state');
  }
};

window.addEventListener('message', receiveAuthorizationCode);

const getCurrentUser = async (args: { loginHint?: string }) => {
  const { loginHint } = args || {};
  let tokens: AuthResponse | Record<string, never> = {};
  try {
    tokens = await authProvider.acquireTokenSilent({
      scopes: MS_SCOPES,
      authority: msalConfig.auth.authority,
      loginHint,
    });
  } catch (error) {
    if (_.isObject(error) && error.name === 'InteractionRequiredAuthError') {
      tokens = await authProvider
        .acquireTokenPopup({
          scopes: MS_SCOPES,
          authority: msalConfig.auth.authority,
          loginHint,
        })
        .catch(() => ({}));
    } else return null;
  }
  const { accessToken } = tokens;
  if (!accessToken) return null;
  const options = getHeaders(accessToken);
  const { data } = await axios.get(MS_GRAPH_ENDPOINTS.ME, options);
  return data;
};

const loginPopup = async () => {
  return authProvider.loginPopup({ scopes: MS_SCOPES });
};

const pollDetectAuthorizationCode = async (
  timeout: number,
  pollInterval: number,
  popup: Window | null,
): Promise<OutlookResponse> => {
  if (timeout > 0) {
    if (detectedAuthorizationCode && detectedAuthorizationState) {
      const result = {
        code: detectedAuthorizationCode,
        state: detectedAuthorizationState,
        status: 'ok',
      };
      detectedAuthorizationCode = ''; // reset the auth code
      detectedAuthorizationState = '';
      return result;
    }
    if (popup && popup.closed) {
      return { status: 'popup_closed_by_user' };
    }
    await sleep(250);
    return pollDetectAuthorizationCode(
      timeout - pollInterval,
      pollInterval,
      popup,
    );
  }
  return { status: 'popup_timeout' };
};

interface RegisterOfflineArgs {
  user: { id: string };
  prompt: string;
}
/**
 * opens a popup to ask the user to register offline
 * @param prompt string. For options see https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-auth-code-flow#request-an-authorization-code
 * @returns Promise<string | undefined}>
 */
const registerOffline = async ({
  user,
  prompt,
}: RegisterOfflineArgs): Promise<string | undefined> => {
  detectedAuthorizationCode = '';
  const url = `${msalConfig.auth.authority}/oauth2/v2.0/authorize?`;
  const params = [
    `client_id=${MS_CLIENT_ID}`,
    'response_type=code',
    `redirect_uri=${REDIRECT_URI}`,
    `scope=${MS_SCOPES.join('%20')}`,
    `state=${user.id}`, // mettre quelque chose de spécifique à l'utilisateur pour pouvoir l'utiliser comme id après
    'response_mode=query',
  ];
  if (prompt) {
    params.push(`prompt=${prompt}`);
  }
  // open auth grant popup // in the popup use window.opener.postmessage
  let outlookResponse: OutlookResponse | Record<string, never> = {};
  try {
    const offlineGrantPopup = openPopup(url + params.join('&'), '', 600, 443);
    // start polling for validation
    outlookResponse = await pollDetectAuthorizationCode(
      5 * 60 * 1000,
      250,
      offlineGrantPopup,
    ); // poll every 250ms for 5 minutes
    if (offlineGrantPopup) {
      offlineGrantPopup.close();
    }
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTags({ feature: 'outlook' });
      Sentry.captureException(error);
    });
    throw error;
  }
  if (outlookResponse.status !== 'ok') {
    throw new Error(outlookResponse.status);
  }
  if (user.id !== outlookResponse.state) {
    throw new Error(
      `Outlook register: invalid state. Expected "${user.id}", got "${outlookResponse.state}"`,
    );
  }
  return outlookResponse.code;
};

const testHasLoggedOut = async (
  nbTrialsRemaining: number,
  popup: Window | null,
): Promise<{ success: boolean; error?: string }> => {
  let token;
  if (nbTrialsRemaining > 0) {
    if (popup && popup.closed) {
      return { success: false, error: 'popup closed' };
    }
    try {
      token = await authProvider.acquireTokenSilent({
        scopes: MS_SCOPES,
        authority: msalConfig.auth.authority,
      });
    } catch {
      token = null;
    }
    if (!token) {
      return { success: true };
    }
    await sleep(1000);
    return testHasLoggedOut(nbTrialsRemaining - 1, popup);
  }
  return { success: false, error: 'timeout' };
};

const signOut = async () => {
  const url = `${msalConfig.auth.authority}/oauth2/v2.0/logout`;
  const popup = openPopup(url, '', 440, 610);
  const hasLoggedOut = await testHasLoggedOut(50, popup);
  if ((hasLoggedOut || {}).success && popup && !popup.closed) {
    popup.close();
  }
  // @ts-ignore
  authProvider.account = null;
  // @ts-ignore
  authProvider.clearCache();
  return hasLoggedOut;
};

const formatOutlookEmail = ({
  dest,
  bccAddresses,
  ccAddresses,
  body,
  subject,
}: {
  dest: string;
  bccAddresses: string[];
  ccAddresses: string[];
  body: string;
  subject: string;
}) => {
  const message = {
    subject,
    toRecipients: [
      {
        emailAddress: {
          address: dest,
        },
      },
    ],
    ...(!_.isEmpty(bccAddresses) && {
      bccRecipients: _.map(bccAddresses, (bccAddress) => ({
        emailAddress: {
          address: bccAddress,
        },
      })),
    }),
    ...(!_.isEmpty(ccAddresses) && {
      ccRecipients: _.map(ccAddresses, (ccAddress) => ({
        emailAddress: {
          address: ccAddress,
        },
      })),
    }),
    body: {
      // eslint-disable-next-line no-irregular-whitespace
      content: `${body}​`, // do not remove the empty string, do not modify it. it contains an invisible utf-8
      // character (u+200b) to force the outlook API to encode the message in UTF-8 to avoid the
      // gmail client that clips messages
      contentType: 'html',
    },
  };
  return message;
};

const acquireTokenRequiresInteraction = (
  errorMessage: string | null | undefined,
) => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }
  return (
    errorMessage.indexOf('consent_required') > -1 ||
    errorMessage.indexOf('interaction_required') > -1 ||
    errorMessage.indexOf('login_required') > -1
  );
};

const sendMail = async (
  from: string,
  dest: string,
  body: string,
  subject: string,
  bccAddresses: string[],
  ccAddresses: string[],
): Promise<{ success: boolean; error?: any; threadData?: any }> => {
  try {
    let tokens: AuthResponse | Record<string, never> = {};
    try {
      tokens = await authProvider.acquireTokenSilent({
        scopes: MS_SCOPES,
        authority: msalConfig.auth.authority,
      });
    } catch (error) {
      if (
        _.isObject(error) &&
        acquireTokenRequiresInteraction(error.errorCode)
      ) {
        tokens = await authProvider.acquireTokenPopup({
          scopes: MS_SCOPES,
          authority: msalConfig.auth.authority,
        });
      }
    }

    const { accessToken } = tokens;
    if (!accessToken) {
      throw Error(`Outlook Send Error (no access token)`);
    }

    const email = formatOutlookEmail({
      dest,
      bccAddresses,
      ccAddresses,
      body,
      subject,
    });
    const options = getHeaders(accessToken);
    const draftResult = await axios.post(
      MS_GRAPH_ENDPOINTS.MAIL,
      email,
      options,
    );
    if (
      !draftResult ||
      draftResult.status !== 201 ||
      !draftResult.data ||
      !draftResult.data.id
    ) {
      throw Error(
        `Outlook Send Error (creating a draft) - status ${draftResult.status}`,
      );
    }
    const messageId = draftResult.data.id;
    const sendResult = await axios.post(
      `${MS_GRAPH_ENDPOINTS.MAIL}/${messageId}/send`,
      null,
      options,
    );
    if (sendResult.status === 202) {
      return {
        success: true,
        threadData: {
          from,
          to: dest,
          cc: ccAddresses,
          bcc: bccAddresses,
          body: draftResult.data.body.content, // The content is wrapped with <html> tag. Is it consistent ?
          subject: draftResult.data.subject,
          threadId: draftResult.data.conversationId,
          type: 'outlook',
        },
      };
    }
    throw Error(`Outlook Send Error - status ${(sendResult || {}).status}`);
  } catch (error) {
    return { success: false, error };
  }
};

const outlookApi = {
  authProvider,
  signOut,
  getCurrentUser,
  loginPopup,
  registerOffline,
  sendMail,
};

export default outlookApi;
