import React from 'react';
import OfferPipeline from './components/OfferPipeline';
import OfferCohorts from './components/OfferCohorts';
import OfferSkipRatio from './components/OfferSkipRatio';

import './OfferAnalytics.css';

const OfferAnalytics = ({ clientId, offerId }) => {
  return (
    <div className='analytics-view'>
      <div className='analytics offer-analytics'>
        <OfferPipeline clientId={clientId} offerId={offerId} />
        <OfferCohorts clientId={clientId} offerId={offerId} />
        <OfferSkipRatio clientId={clientId} offerId={offerId} />
      </div>
    </div>
  );
};

export default OfferAnalytics;
