import React from 'react';
import _ from 'underscore';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import { getMergeTagLabel } from '@/common/mergeTags/utils';
import {
  FONT_SIZE_STYLES,
  REVERSE_FONT_SIZE_STYLE_MAP,
  STYLE_MAP,
} from './constants';

export const findPlaceholdersEntities = (
  contentBlock,
  callback,
  contentState,
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    if (entityKey === null) {
      return false;
    }
    // To look for certain types of entities,
    // or entities with a certain mutability,
    // you may need to get the entity from contentState.
    return contentState.getEntity(entityKey).getType() === 'PLACEHOLDER';
  }, callback);
};

export const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
};

export const isURL = (str) => {
  const regex = /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:,%_+.~#?&//=]*)$/;
  return regex.test(str);
};

export const draftJSContentToHTML = convertToHTML({
  styleToHTML: (style) => {
    if (FONT_SIZE_STYLES.includes(style)) {
      return <span style={STYLE_MAP[style]} />;
    }
    return null;
  },
  blockToHTML: (block) => {
    if (block.type === 'unstyled') {
      if (block.text === ' ' || block.text === '') {
        return <br />;
      }
      return <div />;
    }
    return null;
  },
  entityToHTML: (entity, originalText) => {
    if (entity.type === 'IMAGE') {
      return `<img src="${entity.data.src}" width="${entity.data.width}%" align="${entity.data.alignment}" />`;
    }
    if (entity.type === 'PLACEHOLDER') {
      return `{{${entity.data.key}}}`;
    }
    if (entity.type === 'LINK') {
      return `<a href="${entity.data.url}">${originalText}</a>`;
    }
    return originalText;
  },
});

const getSnippetByKey = (keyToFind, snippets) => {
  return _.find(snippets, (sn) => sn?.id === keyToFind);
};

export const getInstantiatedEntityText = ({
  snippets,
  clientSnippets,
  clientCustomFields,
  clientMissionCustomFields,
  keyParam,
  t,
  evaluatedMode,
}) => {
  const matchedSnippet = getSnippetByKey(keyParam, snippets || []);
  if (matchedSnippet) {
    if (evaluatedMode) {
      return (
        matchedSnippet.state?.value ||
        matchedSnippet.fallbackValue?.text ||
        'No value'
      );
    }
    return matchedSnippet.name;
  }
  const snippetsFromText = MergeTagsService.getSnippetsFromText({
    text: `{{${keyParam}}}`,
    t,
    customFields: clientCustomFields,
    missionCustomFields: clientMissionCustomFields,
    clientSnippets,
  });
  if (!_.isEmpty(snippetsFromText)) {
    return snippetsFromText[0].name;
  }
  return getMergeTagLabel({
    key: keyParam,
    t,
    customFields: clientCustomFields,
    missionCustomFields: clientMissionCustomFields,
    clientSnippets,
  });
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getHTMLToDraftJSContentConverter = ({
  snippets,
  clientSnippets,
  clientCustomFields,
  clientMissionCustomFields,
  t,
  evaluatedMode,
}) =>
  convertFromHTML({
    htmlToStyle: (nodeName, node, currentStyle) => {
      if (nodeName === 'span' && node?.style?.fontSize) {
        if (_.has(REVERSE_FONT_SIZE_STYLE_MAP, node.style.fontSize)) {
          return currentStyle.add(
            REVERSE_FONT_SIZE_STYLE_MAP[node.style.fontSize],
          );
        }
      }
      return currentStyle;
    },
    htmlToEntity: (nodeName, node, createEntity) => {
      if (nodeName === 'a') {
        return createEntity('LINK', 'MUTABLE', { url: node.href });
      }
      if (nodeName === 'img') {
        return createEntity('IMAGE', 'MUTABLE', {
          src: node.src,
          width: node.width,
          alignment: node.align,
        });
      }
      return null;
    },
    textToEntity: (text, createEntity) => {
      const result = [];
      text.replace(/\{{([^{}]*)}}/g, (match, key, offset) => {
        const entityKey = createEntity('PLACEHOLDER', 'IMMUTABLE', {
          key,
        });

        const entityProfileContent = getInstantiatedEntityText({
          snippets,
          clientSnippets,
          clientCustomFields,
          missionCustomFields: clientMissionCustomFields,
          keyParam: key,
          t,
          evaluatedMode,
        });
        const entityText = entityProfileContent || key;

        result.push({
          entity: entityKey,
          offset,
          length: match.length,
          result: entityText,
        });
      });
      return result;
    },
    htmlToBlock: (nodeName) => {
      if (nodeName === 'img') {
        return {
          type: 'atomic',
        };
      }
      return undefined;
    },
  });
