import React, { FC, useMemo } from 'react';
import { Loader } from 'semantic-ui-react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import useClientExternalAutomationServices from '@/graphql/hooks/clients/useClientExternalAutomationServices';
import GenericSelect from '@/components/Common/GenericSelect';
import GenericFormLabel from '@/components/Common/GenericFormLabel';

import styles from './PhantomBusterTaskConfig.module.less';

interface PhantomAgentInfo {
  phantomAgentId: string;
  phantomAgentType?: string;
  phantomAgentTitle?: string;
}

interface PhantomBusterTaskConfigProps {
  phantomServiceId?: string;
  phantomAgentId?: string;
  onPhantomServiceId: (phantomServiceId?: string) => void;
  onPhantomAgentInfo: (info?: PhantomAgentInfo) => void;
}

const PhantomBusterTaskConfig: FC<PhantomBusterTaskConfigProps> = ({
  phantomServiceId,
  phantomAgentId,
  onPhantomServiceId,
  onPhantomAgentInfo,
}) => {
  const {
    externalAutomationServices,
    loading,
  } = useClientExternalAutomationServices();
  const { t } = useTranslation();

  const serviceOptions = useMemo(
    () =>
      _.map(externalAutomationServices, ({ id, title }) => ({
        value: id,
        label: title || id,
      })),
    [externalAutomationServices],
  );

  const selectedService = useMemo(
    () => _.findWhere(externalAutomationServices, { id: phantomServiceId }),
    [externalAutomationServices, phantomServiceId],
  );

  const selectedServiceOption = useMemo(
    () => _.findWhere(serviceOptions, { value: phantomServiceId }),
    [serviceOptions, phantomServiceId],
  );

  const automationOptions = useMemo(
    () =>
      _.map(selectedService?.automations || [], ({ id, title }) => ({
        value: id,
        label: title,
      })),
    [selectedService],
  );

  const selectedAutomationOption = useMemo(
    () => _.findWhere(automationOptions, { value: phantomAgentId }),
    [automationOptions, phantomAgentId],
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className={styles.field}>
        <GenericFormLabel>
          {t(
            'reveal.parameters.customTasksSettings.phantombuster.phantomService',
          )}
        </GenericFormLabel>
        <GenericSelect
          options={serviceOptions}
          value={selectedServiceOption}
          onChange={(value) => {
            if (!value) {
              return;
            }

            onPhantomServiceId(value.value);
          }}
        />
      </div>
      <div className={styles.field}>
        <GenericFormLabel>
          {t(
            'reveal.parameters.customTasksSettings.phantombuster.phantomAgent',
          )}
        </GenericFormLabel>
        <GenericSelect
          options={automationOptions}
          value={selectedAutomationOption}
          onChange={(value) => {
            if (!value) {
              return;
            }

            const automation = _.findWhere(selectedService?.automations || [], {
              id: value.value,
            });

            if (!automation) {
              return;
            }

            onPhantomAgentInfo({
              phantomAgentId: automation.id,
              phantomAgentTitle: automation.title,
              phantomAgentType: automation.type,
            });
          }}
        />
      </div>
    </div>
  );
};

export default PhantomBusterTaskConfig;
