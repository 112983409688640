import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Button, Input, Select } from 'semantic-ui-react';
import _ from 'underscore';
import './DateRangePicker.css';
import {
  DAY,
  delayToMs,
  MONTH,
  msToDelay,
  msToDelayWithType,
  WEEK,
} from './dateRangeUtils';

const DateRangePicker = ({
  minDate,
  maxDate,
  onUpdateMinDate,
  onUpdateMaxDate,
  minDelay,
  maxDelay,
  onUpdateMinDelay,
  onUpdateMaxDelay,
  dynamicDescription,
  onResetMin,
  onResetMax,
  onResetAll,
}) => {
  const { t } = useTranslation();
  const [activeMode, setActiveMode] = useState(
    minDate || maxDate ? 'fixed' : 'dynamic',
  );

  useEffect(() => {
    if (minDate || maxDate) {
      setActiveMode('fixed');
    }
  }, [minDate, maxDate]);

  const onTabChange = (newActiveMode) => {
    if (newActiveMode !== activeMode) {
      onResetAll();
      setActiveMode(newActiveMode);
    }
  };

  return (
    <div className='tab-container'>
      <div className='tab-selectors'>
        <Button
          className='tab-button'
          basic
          active={activeMode === 'dynamic'}
          onClick={() => onTabChange('dynamic')}
        >
          {t('reveal.searchView.filters.ats.dynamic')}
        </Button>
        <Button
          className='tab-button'
          basic
          active={activeMode === 'fixed'}
          onClick={() => onTabChange('fixed')}
        >
          {t('reveal.searchView.filters.ats.fixed')}
        </Button>
      </div>
      <div className='datepicker-content'>
        {activeMode === 'dynamic' && (
          <DynamicDateRangePicker
            minDelay={minDelay}
            maxDelay={maxDelay}
            onUpdateMax={onUpdateMaxDelay}
            onUpdateMin={onUpdateMinDelay}
            onResetMin={onResetMin}
            onResetMax={onResetMax}
            dynamicDescription={dynamicDescription}
          />
        )}
        {activeMode === 'fixed' && (
          <FixedDateRangePicker
            minDate={minDate}
            maxDate={maxDate}
            onUpdateMax={onUpdateMaxDate}
            onUpdateMin={onUpdateMinDate}
          />
        )}
      </div>
    </div>
  );
};

const DynamicDateRangePicker = ({
  minDelay,
  maxDelay,
  onUpdateMin,
  onUpdateMax,
  dynamicDescription,
  onResetMin,
  onResetMax,
}) => {
  const { t } = useTranslation();
  return (
    <div className='dynamic-date-picker'>
      <div className='date-picker-label'>
        {t('reveal.searchView.filters.ats.lessThan')}
      </div>
      <DelayInput
        delayInMs={minDelay}
        onUpdate={onUpdateMin}
        onReset={onResetMin}
        placeholder='N'
      />

      <div className='date-picker-label'>
        {t('reveal.searchView.filters.ats.moreThan')}
      </div>
      <DelayInput
        delayInMs={maxDelay}
        onUpdate={onUpdateMax}
        onReset={onResetMax}
        placeholder='N'
      />

      <div className='date-picker-description'>{dynamicDescription}</div>
    </div>
  );
};

const delayTypeOptions = (count, t) => {
  return [
    {
      key: DAY,
      value: DAY,
      text: t('reveal.searchView.filters.ats.daysAgo', {
        count: count === '1' ? 1 : 2,
      }),
    },
    {
      key: WEEK,
      value: WEEK,
      text: t('reveal.searchView.filters.ats.weeksAgo', {
        count: count === '1' ? 1 : 2,
      }),
    },
    {
      key: MONTH,
      value: MONTH,
      text: t('reveal.searchView.filters.ats.monthsAgo', {
        count: count === '1' ? 1 : 2,
      }),
    },
  ];
};

const getFormattedDelay = ({ delayInMs }) => {
  const delay = msToDelay(delayInMs);
  const delayType = delay?.type || MONTH;
  const delayCount = delay?.count >= 1 ? delay?.count.toString() : '';
  return { delayType, delayCount };
};

export const DelayInput = ({ placeholder, delayInMs, onUpdate, onReset }) => {
  delayInMs = delayInMs || 0;
  const [delayInMsState, setDelayInMsState] = useState(delayInMs);
  const { delayType, delayCount } = getFormattedDelay({ delayInMs });
  const [type, setType] = useState(delayType);
  const [count, setCount] = useState(delayCount);
  const { t } = useTranslation('translations');

  // console.log(placeholder, { delayInMs, type, count });

  const setState = ({ delayInMs }) => {
    const delayCount = msToDelayWithType(delayInMs, type);

    // console.log(placeholder, { delayInMs, delay, delayType, delayCount });
    setCount(delayCount);
  };

  useEffect(() => {
    if (delayInMs !== delayInMsState) {
      // console.log('UPDATE', placeholder, { delayInMs, delayInMsState });
      setDelayInMsState(delayInMs);
      setState({ delayInMs });
    }

    // eslint-disable-next-line
  }, [delayInMs, setState]);

  // useEffect(() => {
  //   setType(delayType);
  //   setCount(delayCount);
  // }, [delayType, delayCount]);

  const onChangeDelayCount = (e, { value }) => {
    // remove everything that's not digits
    // TODO: || 0 prevents NaN below but creates unexpected behaviour when inputting non-numeric values
    const cleanValue = value.replace(/\D*/, '') || 0;
    setCount(cleanValue);

    const valueNumber = parseInt(cleanValue, 10);
    // console.log({ cleanValue, valueNumber });
    if (!_.isNaN(valueNumber) && valueNumber >= 0 && _.isString(type)) {
      const newDelayInMs = delayToMs({ type, count: valueNumber });
      onUpdate({ delay: newDelayInMs });
    }
  };

  const onChangeType = (e, { value }) => {
    setType(value);

    if (!_.isNaN(count) && count >= 0) {
      const newDelayInMs = delayToMs({ type: value, count });
      onUpdate({ delay: newDelayInMs });
    }
  };

  const onClickReset = () => {
    onReset();
    setCount('');
  };

  return (
    <div className='delay-input'>
      <Input
        className='delay-count-input'
        type='number'
        placeholder={placeholder || 'N'}
        value={count}
        min='1'
        onChange={onChangeDelayCount}
      />
      <Select
        className='delay-type-input'
        options={delayTypeOptions(count, t)}
        value={type}
        onChange={onChangeType}
      />
      {onReset && (
        <Button
          className='delay-reset-button'
          size='small'
          basic
          onClick={onClickReset}
        >
          <i className='ri-close-line' />
        </Button>
      )}
    </div>
  );
};

const FixedDateRangePicker = ({
  minDate,
  maxDate,
  onUpdateMin,
  onUpdateMax,
}) => {
  const { t } = useTranslation();
  return (
    <div className='date-range-picker'>
      <div className='date-picker-field-container'>
        <div className='date-picker-label'>{t('common.after')}:</div>
        <div className='date-picker-container'>
          <DatePicker
            selected={minDate}
            onChange={(date) => onUpdateMin({ date })}
            isClearable
            placeholderText={t('reveal.searchView.filters.ats.pickDate')}
            dateFormat='dd/MM/yyyy' // TODO: US date format
          />
        </div>
      </div>
      <div className='date-picker-field-container'>
        <div className='date-picker-label'>{t('common.before')}:</div>
        <div className='date-picker-container'>
          <DatePicker
            selected={maxDate}
            onChange={(date) => onUpdateMax({ date })}
            isClearable
            placeholderText={t('reveal.searchView.filters.ats.pickDate')}
            dateFormat='dd/MM/yyyy'
          />
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
