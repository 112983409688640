import React, {
  FC,
  HTMLAttributes,
  RefObject,
  useCallback,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Popup, PopupProps } from 'semantic-ui-react';

import _ from 'underscore';
import styles from './GenericPopup.module.less';

// This is a hack to allow SemanticUI to transfer html refs to us
// otherwise semantic ui transfers a Popup react element which
// has no reference to the underlying HTML element

type DivWithForwardableRefProps = HTMLAttributes<HTMLDivElement> & {
  forwardedRef: RefObject<HTMLDivElement>;
};

// We fake a div which can forward its ref
const DivWithForwardableRef: FC<DivWithForwardableRefProps> = ({
  forwardedRef,
  ...props
}) => <div ref={forwardedRef} {...props} />;

type GenericPopupProps = PopupProps & {
  checkInnerPopups?: boolean;
};

const GenericPopup: React.FC<GenericPopupProps> = ({
  className,
  checkInnerPopups = false,
  open,
  onOpen,
  onClose,
  ...props
}) => {
  const popupRef = useRef<HTMLDivElement>(null);

  const [innerOpen, setInnerOpen] = useState<boolean>(open ?? false);

  const handleOpen = useCallback(
    (e, data) => {
      // user event, linter does not understand onOpen?.(e)
      // so we have to if-it
      if (onOpen) {
        onOpen(e, data);
      }
      setInnerOpen(true);
    },
    [onOpen],
  );

  // This custom handle close function allows us to
  // check if another popup has been clicked and prevent
  // closing of this popup.
  // Please note that this is not foolproof, since it is possible
  // that our popups have _nothing to do with each other_.
  // So use the checkInnerPopups prop when necessary.
  // It could be possible to add a htmlFor or name to our popups
  // to fake group them and improve this technique
  const handleClose = useCallback(
    (e, data) => {
      // user event

      if (!checkInnerPopups) {
        setInnerOpen(false);
        if (onClose) {
          onClose(e, data);
        }
        return;
      }

      if (!popupRef?.current) {
        setInnerOpen(false);
        if (onClose) {
          onClose(e, data);
        }
        return;
      }

      const otherPopups = Array.from(document.querySelectorAll('.ui.popup'));

      let shouldClose = true;

      _.each(otherPopups, (otherPopup) => {
        if (otherPopup.contains(e.target as HTMLElement)) {
          // we found another popup doing somehting, do not close this one
          shouldClose = false;
        }
      });

      if (shouldClose) {
        setInnerOpen(false);
        if (onClose) {
          onClose(e, data);
        }
      }
    },
    [checkInnerPopups, onClose],
  );

  return (
    <Popup
      as={DivWithForwardableRef}
      forwardedRef={popupRef}
      // if user passed an open state it takes precedence over
      // our computed innerOpen state
      open={open ?? innerOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      className={classNames(className, styles.genericPopup)}
      {...props}
    />
  );
};

export default GenericPopup;
