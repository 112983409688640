/* global gapi */
/* eslint-disable react/no-unused-state */
import React from 'react';
import * as Sentry from '@sentry/browser';
import { compose } from 'underscore';
import withActionLogger from '../hocs/withActionLogger';
import withTestOfflineGrant from '../hocs/email/withTestOfflineGrant';
import withRegisterOfflineGrant from '../hocs/email/withRegisterOfflineGrant';
import { sentryCaptureException } from '../common';
import { withGoogleAuth2 } from './GoogleAuth2';
import EmailContext, { emptyEmailContext } from './EmailContext';
import outlookApi from './outlookAPI';
import gmailApi from './gmailAPI';
import SendErrorModal from './SendErrorModal';
import WarningModal from '../components/modals/WarningModal';
import ProviderChoiceModal from '../components/modals/WarningModal/ProviderChoiceModal';

class EmailAPI extends React.Component {
  constructor(props) {
    super(props);
    this.state = emptyEmailContext;
  }

  async componentDidMount() {
    const { auth2Promise } = this.props;
    let auth2;
    try {
      auth2 = await auth2Promise;
    } catch (e) {
      console.error(e);
      Sentry.withScope((scope) => {
        scope.setTags({ feature: 'email-api', type: 'googleAuthInitError' });
        Sentry.captureException(e);
      });
      this.setState({ googleAuthInitError: e });
      return;
    }

    const getIdToken = (options = {}) => {
      let { type } = options;
      if (!type) {
        type = this.state.currentEmailType;
      }
      if (type === 'gmail') {
        return auth2.currentUser.get().getAuthResponse().id_token;
      }
      if (type === 'outlook') {
        return outlookApi.authProvider.cacheStorage.getItem('msal.idtoken'); // HACK
      }
      return null;
    };

    const getEmailAddressDetails = async (type) => {
      if (type === 'outlook') {
        const outlookUser = await outlookApi.getCurrentUser();
        const { givenName: firstname, surname: lastname } = outlookUser;
        return {
          firstname,
          lastname,
        };
      }

      if (type === 'gmail') {
        const basicProfile = auth2?.currentUser?.get()?.getBasicProfile();
        const firstname = basicProfile?.getGivenName();
        const lastname = basicProfile?.getFamilyName();
        const email = basicProfile?.getEmail();

        let sendAsDetails;

        try {
          // https://developers.google.com/gmail/api/v1/reference/users/settings/sendAs/get
          const url = `https://www.googleapis.com/gmail/v1/users/me/settings/sendAs/${email}`;
          const response = await gapi.client.request(url);
          sendAsDetails = response?.result;
        } catch (e) {
          console.error('sendAs error', e);
        }

        return {
          firstname,
          lastname,
          signature: sendAsDetails?.signature,
          alias: sendAsDetails?.displayName,
        };
      }

      return null;
    };

    const updateSigninStatus = async ({ type, loginHint }) => {
      // console.log('Update Signin Status ', type);
      let currentUser;
      if (type === 'outlook') {
        currentUser = await outlookApi.getCurrentUser({ loginHint });
      } else if (type === 'gmail' && auth2.isSignedIn.get()) {
        currentUser = auth2.currentUser.get();
      }

      if (currentUser) {
        // console.log('Current User : ', currentUser);
        let currentAddress;
        if (type === 'outlook') {
          currentAddress = currentUser.mail || currentUser.userPrincipalName;
        } else if (type === 'gmail') {
          currentAddress = currentUser.getBasicProfile().getEmail();
        }
        const token = getIdToken({ type });
        // this should happen rarely
        // but if there is no token, then no point in testing hasGrantOffline
        if (!token) {
          this.setState({ connectionInProgress: false });
          return false;
        }

        // test offline grant
        try {
          const hasOffline = await this.props.testOfflineGrant({ token, type });
          const { hasOfflineGrant } = this.state;
          this.setState({
            hasOfflineGrant: {
              ...hasOfflineGrant,
              ...{ [currentAddress]: hasOffline },
            },
          });
          if (hasOffline) {
            this.setState({
              currentAddress,
              currentEmailType: type,
              connectionInProgress: false,
            });
            return true;
          }
          this.setState({ connectionInProgress: false });
          return false;
        } catch (reason) {
          // console.log(reason);
          this.setState({ connectionInProgress: false });
          return false;
        }
      } else {
        this.setState({
          currentAddress: null,
          currentEmailType: null,
          connectionInProgress: false,
        });
        return false;
      }
    };

    // initialization : try to use the previously used type, fallback to the other
    const { user } = this.props;
    const preferredMailType = ((user || {}).mailAccount || {}).type || 'gmail';
    const secondaryMailType =
      preferredMailType === 'gmail' ? 'outlook' : 'gmail';
    this.setState({ connectionInProgress: true });
    const hasGrantOffline = await updateSigninStatus({
      type: preferredMailType,
    });
    if (!hasGrantOffline) {
      updateSigninStatus({ type: secondaryMailType });
    }

    // GMAIL LISTENERS
    // Listen for sign-in state changes.
    // auth2.isSignedIn.listen(() => {
    // console.log('LISTEN ISSIGNEDIN')
    // updateSigninStatus({ type: 'gmail' })});
    // Listen for current user changes
    // disabled because duplicate with isSignedIn.listen
    auth2.currentUser.listen(() => {
      updateSigninStatus({ type: 'gmail' });
    });

    // API functions

    const onTriggerSignIn = async (type) => {
      const { user: propsUser, clientId, onLogAction } = this.props;
      this.setState({ connectionInProgress: true });
      try {
        onLogAction({
          type: `${type}-signin`,
          info: {
            author: propsUser
              ? `${propsUser.firstname} ${propsUser.lastname}`
              : undefined,
            clientId,
          },
        });
      } catch (error) {
        // console.log(error);
      }
      try {
        if (type === 'outlook') {
          await outlookApi.loginPopup();
          return await updateSigninStatus({ type: 'outlook' });
        }
        if (type === 'gmail') {
          await auth2.signIn();
          return await updateSigninStatus({ type: 'gmail' });
        }
      } catch (error) {
        this.setState({ connectionInProgress: false });
        Sentry.captureException(error);
      }
      return undefined;
    };

    const onTriggerOfflineRegister = async (type, prompt) => {
      const { user: propsUser, clientId, onLogAction } = this.props;
      this.setState({ connectionInProgress: true });
      try {
        onLogAction({
          type: `${type}-register-offline`,
          info: {
            author: propsUser
              ? `${propsUser.firstname} ${propsUser.lastname}`
              : undefined,
            clientId,
          },
        });
      } catch (error) {
        Sentry.captureException(error);
      }
      let authorizationCode;
      try {
        if (type === 'outlook') {
          authorizationCode = await outlookApi.registerOffline({
            user: propsUser,
            prompt,
          });
        } else if (type === 'gmail') {
          authorizationCode = await gmailApi.registerOffline({ auth2, prompt });
        }
      } catch (error) {
        if (error.message !== 'popup_closed_by_user' && 'access_denied') {
          sentryCaptureException({ error, tags: { feature: type } });
        }
        try {
          onLogAction({
            type: `${type}-register-offline-failed`,
            info: {
              author: propsUser
                ? `${propsUser.firstname} ${propsUser.lastname}`
                : undefined,
              clientId,
              error: error?.message || JSON.stringify(error),
            },
          });
        } catch (e) {
          Sentry.captureException(e);
        }
        this.setState({ offlineError: true, connectionInProgress: false });
        return null;
      }

      if (!authorizationCode) {
        try {
          onLogAction({
            type: `${type}-register-offline-failed`,
            info: {
              author: propsUser
                ? `${propsUser.firstname} ${propsUser.lastname}`
                : undefined,
              clientId,
              error: `authorization code is "${authorizationCode}"`,
            },
          });
        } catch (error) {
          Sentry.captureException(error);
        }
        this.setState({ offlineError: true, connectionInProgress: false });
        return null;
      }

      // send auth code to the backend
      try {
        const { data: authResult } = await this.props.registerOfflineGrant(
          type,
          authorizationCode,
        );
        if (!authResult?.registerEmailAccount) {
          try {
            onLogAction({
              type: `${type}-register-offline-failed`,
              info: {
                author: propsUser
                  ? `${propsUser.firstname} ${propsUser.lastname}`
                  : undefined,
                clientId,
                error: 'registerOfflineGrant result is false or undefined',
              },
            });
          } catch (error) {
            Sentry.captureException(error);
          }
          this.setState({ offlineError: true, connectionInProgress: false });
          return null;
        }
      } catch (error) {
        sentryCaptureException({ error, tags: { feature: type } });
        this.setState({ offlineError: true, connectionInProgress: false });
        return null;
      }
      if (type === 'outlook') {
        outlookApi.authProvider.clearCache();
        outlookApi.authProvider.account = null;
        // refetch user to get new loginHint
        const newUserData = await this.props.refetchUser();
        const { loginHint } =
          (((newUserData || {}).data || {}).user || {}).mailAccount || {};
        return updateSigninStatus({ type: 'outlook', loginHint });
      }
      if (type === 'gmail') {
        return updateSigninStatus({ type: 'gmail' });
      }
      return undefined;
    };

    const onTriggerSignOut = () => {
      const { currentEmailType } = this.state;
      // log action
      this.setState({
        currentAddress: null,
        currentEmailType: null,
      });
      if (currentEmailType === 'outlook') {
        return outlookApi.signOut();
      }
      if (currentEmailType === 'gmail') {
        return auth2.signOut();
      }
      return undefined;
    };

    const onTriggerSendMail = ({
      subject,
      body,
      dest,
      bccAddresses,
      ccAddresses,
      alias,
      defaultThreadId,
    }) => {
      const { user: propsUser, clientId, onLogAction } = this.props;
      const { currentEmailType } = this.state;

      try {
        onLogAction({
          type: `${currentEmailType}-send-email`,
          info: {
            author: propsUser
              ? `${propsUser.firstname} ${propsUser.lastname}`
              : undefined,
            clientId,
          },
        });
      } catch (error) {
        // console.log(error);
      }
      try {
        if (this.state.currentEmailType === 'outlook') {
          return outlookApi.sendMail(
            this.state.currentAddress,
            dest,
            body,
            subject,
            bccAddresses,
            ccAddresses,
          );
        }
        if (this.state.currentEmailType === 'gmail') {
          const mail = { subject, body, dest, defaultThreadId, alias };
          return gmailApi.sendEmail({
            gapi,
            auth2,
            mail,
            currentGmailAddress: this.state.currentAddress,
            bccAddresses,
            ccAddresses,
          });
        }
      } catch (error) {
        return { success: false, error };
      }
      return undefined;
    };

    const onTriggerChangeAccount = async (type) => {
      if (this.state.currentEmailType === 'outlook' || !type) {
        const signoutResult = await onTriggerSignOut();
        if (signoutResult.success) {
          this.setState({
            providerChoiceModalOpen: true,
          });
        }
        return undefined;
      }
      await onTriggerSignOut();
      return onTriggerOfflineRegister(type);
    };
    const onSendError = ({ profileId, offerId }) => {
      this.setState({
        sendErrorModal: {
          profileId,
          offerId,
        },
      });
    };

    this.setState({
      onTriggerSendMail,
      onTriggerOfflineRegister,
      onTriggerChangeAccount,
      onTriggerSignIn,
      onTriggerSignOut,
      onSendError,
      getIdToken,
      getEmailAddressDetails,
      setSendConfirmationMode: (newValue) => {
        this.setState({ sendConfirmationModeActive: !!newValue });
      },
      setIsFirstSessionSend: (bool) => {
        this.setState({ isFirstSessionSend: !!bool });
      },
      onOpenProviderChoiceModal: this.onOpenProviderChoiceModal,
    });
  }

  onOpenProviderChoiceModal = () => {
    this.setState({ providerChoiceModalOpen: true });
  };

  onCloseProviderChoiceModal = () => {
    this.setState({ providerChoiceModalOpen: false });
  };

  onCloseSignInErrorModal = () => {
    this.setState({ offlineError: false });
  };

  onCloseSendErrorModal = () => {
    this.setState({
      sendErrorModal: null,
    });
  };

  onCloseGoogleAuthInitErrorModal = () => {
    this.setState({
      googleAuthInitError: null,
    });
  };

  render() {
    const { children } = this.props;
    const {
      offlineError,
      googleAuthInitError,
      providerChoiceModalOpen,
      onTriggerOfflineRegister,
    } = this.state;
    return (
      <>
        <EmailContext.Provider value={this.state}>
          {children}
        </EmailContext.Provider>
        {this.state.sendErrorModal && (
          <SendErrorModal
            profile={this.state.sendErrorModal.profile}
            offerId={this.state.sendErrorModal.offerId}
            open
            onTriggerSignOut={this.state.onTriggerSignOut}
            onClose={this.onCloseSendErrorModal}
          />
        )}
        <WarningModal
          open={!!googleAuthInitError}
          translationId='emailApi.googleAuthInitError'
          onCancel={this.onCloseGoogleAuthInitErrorModal}
        />
        <WarningModal
          open={!!offlineError}
          translationId='profile.notifications.errorRegisterOffline'
          onCancel={this.onCloseSignInErrorModal}
        />
        <ProviderChoiceModal
          open={providerChoiceModalOpen}
          onTriggerOfflineRegister={onTriggerOfflineRegister}
          onClose={this.onCloseProviderChoiceModal}
        />
      </>
    );
  }
}

export default compose(
  withGoogleAuth2,
  withActionLogger,
  withTestOfflineGrant,
  withRegisterOfflineGrant,
)(EmailAPI);
