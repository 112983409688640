import gql from 'graphql-tag';
import TranslatableText from './fragments/TranslatableText';

export interface RevealClientActivityVariables {
  clientId: string;
  scope?: 'classic' | 'reveal';
  missionId?: string;
  onlyCurrentUser?: boolean;
}

export interface RevealClientActivityResults {
  client: {
    id: string;
    activity: {
      events: Array<any>;
    };
  };
}

const REVEAL_CLIENT_ACTIVITY = gql`
  query getClientActivity($clientId: ID!, $scope: String, $missionId: String, $onlyCurrentUser: Boolean) {
    client(id: $clientId) {
      id
      activity(scope: $scope, missionId: $missionId, onlyCurrentUser: $onlyCurrentUser) {
        events {
          type
          subtype
          date
          ... on SingleTaskActivityEvent {
            taskType
            task {
              id
              type
              title {
                ...TranslatableText
              }
              target {
                ... on RevealProfile {
                  id
                  resumeData {
                    firstname
                    lastname
                  }
                }
              }
            }
            author {
              firstname
              lastname
            }
          }
          ... on TasksActivityEvent {
            taskType
            tasks {
              id
              type
            }
            author {
              firstname
              lastname
            }
            totalTasksCount
          }
          ... on RevealProfileActivityEvent {
            newStage
            author {
              firstname
              lastname
            }
            searchPoolProfileId
            searchPoolProfileData {
              firstname
              lastname
            }
          }
          ... on RevealMultipleProfilesActivityEvent {
            newStage
            author {
              firstname
              lastname
            }
            searchPoolProfiles 
            totalsearchPoolProfilesCount 
          }
          ... on RevealMissionActivityEvent {
            author {
              firstname
              lastname
            }
          }
        }
        
      }
    }
  }
  ${TranslatableText}
`;

export default REVEAL_CLIENT_ACTIVITY;
