import React from 'react';
import _ from 'underscore';
import { withTranslation } from 'react-i18next';

import { Stack } from '../../../../components/Stack';
import Summary from './Summary';

const SummaryAndStack = ({ t, summary, profileId, onLogAction }) => {
  const { content = {}, stack = [] } = summary || {};
  const shouldDisplaySummary =
    _.isObject(content) &&
    (!_.isEmpty(content.text) || !_.isEmpty(content.children));
  const shouldDisplayStack = !_.isEmpty(stack) && shouldDisplaySummary;
  if (!shouldDisplaySummary) {
    return null;
  }
  return (
    <div className='summary-tag-stack-container'>
      <div className='stack-container'>
        {`${t('profile.resume.summary')} (LinkedIn)`}
        <div className='stack'>
          {shouldDisplayStack && (
            <Stack
              stack={stack}
              displaySoftSkills
              profileId={profileId}
              context='summary'
            />
          )}
        </div>
      </div>
      <Summary
        content={content}
        logAction={onLogAction}
        profileId={profileId}
      />
    </div>
  );
};

export default withTranslation('translations')(SummaryAndStack);
