import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { useQuery } from '@apollo/client';
import Author from '../../graphql/fragments/Author';
import BaseMailTemplate from '../../graphql/fragments/BaseMailTemplate';

export const query = gql`
  query getClientTemplates($clientId: ID!) {
    client(id: $clientId) {
      id
      templates {
        ...BaseMailTemplate
        author {
          ...Author
        }
        offers {
          id
          title
          isUnclassified
        }
        recruiterConfigurations {
          id
          recruiterEmail
          isDefaultReply
        }
      }
    }
  }
  ${Author}
  ${BaseMailTemplate}
`;

export const useClientTemplatesAndOffers = (clientId, queryOptions = {}) => {
  const { data, loading, refetch } = useQuery(query, {
    ...queryOptions,
    variables: {
      clientId,
    },
  });

  const { client, error } = data || {};

  return {
    loading,
    refetch,
    templatesAndOffersLoading: loading,
    templates: client && client.templates ? client.templates : [],
    error,
  };
};

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      clientId: ownProps.clientId,
    },
  }),
  props: ({ data: { loading, client, error } }) => ({
    loading,
    templatesAndOffersLoading: loading,
    templates: client && client.templates ? client.templates : [],
    error,
  }),
});
