import _, { compose } from 'underscore';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

import { hasTemplateDefaultFields } from '@/common/helpers/template';
import WarningModal from '../../../../../components/modals/WarningModal';
import contextToProps from '../../../../../hocs/contextToProps';
import withUserSettings from '../../../../../hocs/users/withUserSettings';
import withSendEmail from '../../../../../hocs/email/withSendEmail';
import withProfileDrafts from '../../../../../hocs/profiles/withProfileDrafts';
import { withLastProfileActionsContextConsumer } from '../../../../../context/LastProfileActionsContext';

import ConfirmationModal from './ConfirmationModal';
import ForceSendModal from './ForceSendModal';
import UnlockProfileModal from './UnlockProfileModal';

import { textToId, sentryCaptureException } from '../../../../../common';
import withActionLogger from '../../../../../hocs/withActionLogger';
import { getNamesInFirstLine } from './sendEmailHelpers';
import { getOSFromPlatform } from './ConfirmationModal/helpers';
import {
  cleanString,
  detectSuspiciousEmailContent,
} from '../../../../../common/detectSuspiciousEmailContent';
import withClient from '../../../../../hocs/clients/withClient';
import { invalidEmailFormat } from '../../../../../common/validators';

const BUTTON_LOCK_DURATION_IN_MS = 5000;
const WRONG_NAME_IN_EMAIL = 'wrong_name_in_email';
const SUSPICIOUS_CONTENT = 'suspicious_content';
const HAS_XX = 'has_xx';
const HAS_DEFAULT_TEMPLATE_FIELDS = 'has_default_template_fields';
const SHORT_BODY_WARNING = 'short-body-email-warning';
const SHORT_SUBJECT_WARNING = 'short-subject-email-warning';
const OS_CODE = getOSFromPlatform();
const MOD_KEY = OS_CODE === 'mac' ? '⌘' : 'Ctrl';

const performSendEmail = async ({
  handleMarkProfileAsInMove,
  handleUnmarkProfileAsInMove,
  getProfilesInMove,

  sendEmail,
  onShowNotification,
  addProfileAction,
  refreshProfile,
  onChangeStep,
  t,

  clientId,
  jobOfferId,
  profileId,
  getNextProfileId,
  stepId,

  sequenceId,
  actionId,

  assignedSender,
  offerAssignedSender,
  mailData,
  client,
  displayHotKeyTip,
}) => {
  if (stepId === 'pending') {
    handleMarkProfileAsInMove({ profileId });
  }

  try {
    await sendEmail({
      ...mailData,
      profileId,
      jobOfferId,
      sequenceId,
      actionId,
      assignedSender,
      offerAssignedSender,
      client,
    });
  } catch (e) {
    console.error(e);
    sentryCaptureException({ error: e, tags: { feature: 'mail' } });
    onShowNotification({
      message: t('profile.notifications.emailSentError'),
      level: 'error',
    });

    handleUnmarkProfileAsInMove({ profileId });
    refreshProfile({ profileId, stepId });
    return;
  }

  if (stepId === 'pending') {
    const nextProfileId = getNextProfileId({
      forbiddenProfileIds: getProfilesInMove(),
    });
    if (!nextProfileId) {
      const forbiddenProfileId = profileId;
      onChangeStep({
        newStepId: stepId,
        forceRefresh: true,
        forbiddenProfileId,
        originalProfileId: profileId,
      });
    }
  }

  refreshProfile({ profileId, stepId }).then(() => {
    handleUnmarkProfileAsInMove({ profileId });
  });

  const message = displayHotKeyTip ? (
    <>
      <span>{t('profile.notifications.emailSentWithSuccess')}</span>
      <br />
      <span>
        {t('profile.notifications.emailSentWithSuccessTip.youCanAlsoHit')}
      </span>
      <span className='code-text'>{MOD_KEY}</span>
      <span> + </span>
      <span className='code-text'>{t('common.keyEnter')}</span>
      <span>{t('profile.notifications.emailSentWithSuccessTip.toSend')}</span>
    </>
  ) : (
    t('profile.notifications.emailSentWithSuccess')
  );

  if (onShowNotification) {
    onShowNotification({
      message,
      level: 'success',
    });
  }

  try {
    const lastProfileAction = {
      clientId,
      jobOfferId,
      profileId,
      stepId,
      type: 'send',
    };
    addProfileAction(lastProfileAction);
  } catch (e) {
    console.error(e);
  }
};

class SendEmailButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openWithHotKey: null,
    };
  }

  lockProfileUntil = {};

  handleOpenModal = ({ sendWithHotKey }) => {
    this.setState({ open: true, openWithHotKey: sendWithHotKey });
  };

  handleCancel = () => {
    this.setState({ open: false, openWithHotKey: null });
  };

  handleOpenForceSendModal = ({ title, content, question }) => {
    this.setState({
      forceSendModal: {
        title,
        content,
        question,
      },
    });
  };

  handleCancelForceSendModal = () => {
    this.setState({ forceSendModal: null });
  };

  raiseWarningModal = (warningMessage) => {
    this.setState({ warningMessage });
  };

  closeWarningModal = () => {
    this.setState({ warningMessage: null });
  };

  handleForceSendSubmit = () => {
    this.handleSubmit(true);
  };

  checkEmail = ({ subject, body, firstname, offerProfilesFirstnames }) => {
    if (!_.isString(body) || body?.length < 10) {
      throw Error(SHORT_BODY_WARNING);
    }
    if (!_.isString(subject) || subject?.length < 8) {
      throw Error(SHORT_SUBJECT_WARNING);
    }

    const namesInFirstLine = getNamesInFirstLine(
      body,
      firstname,
      offerProfilesFirstnames,
    );
    if (namesInFirstLine.length > 0) {
      throw Error(WRONG_NAME_IN_EMAIL);
    }
    const suspiciousContent = detectSuspiciousEmailContent(cleanString(body));
    if (_.contains(suspiciousContent, 'hellofirstname')) {
      throw Error(SUSPICIOUS_CONTENT);
    }
    const hasXX = /XX/.test(body);
    if (hasXX) {
      throw Error(HAS_XX);
    }
    if (hasTemplateDefaultFields(body)) {
      throw Error(HAS_DEFAULT_TEMPLATE_FIELDS);
    }
  };

  handleSubmit = async (force, addThreadId = true, sendWithHotKey = false) => {
    const {
      clientId,
      client,
      stepId,
      sequenceId,
      actionId,
      profile,
      getNextProfileId,
      onSend,
      onChangeProfile,
      onChangeStep,
      offerId,
      offerProfilesFirstnames,
      handleMarkProfileAsInMove,
      handleUnmarkProfileAsInMove,
      getProfilesInMove,
      emailApi,
      assignedSender,
      offerAssignedSender,
      t,
      sendEmail,
      onShowNotification,
      addProfileAction,
      refreshProfile,
    } = this.props;

    if (!profile || !profile.id) {
      return;
    }

    const timestamp = Date.now();
    if (
      this.lockProfileUntil[profile.id] &&
      this.lockProfileUntil[profile.id] > timestamp &&
      !force
    ) {
      return;
    }
    this.lockProfileUntil[profile.id] = timestamp + BUTTON_LOCK_DURATION_IN_MS;

    this.setState({ open: false, openWithHotKey: null, forceSendModal: null });

    // decide if we put a threadId with the email, and which threadId we should append.
    const { mailData } = this.props;
    if (addThreadId) {
      const threads = ((profile || {}).contactData || {}).threads || [];
      threads.reverse(); // reverse the array so that findWhere finds the last thread pushed
      let currentAddressId = '';
      try {
        currentAddressId = textToId(emailApi.currentAddress);
      } catch (error) {
        sentryCaptureException({ error, tags: { feature: 'mail' } });
      }
      let threadId = null;
      if (currentAddressId) {
        threadId = (
          _.findWhere(threads, { mailAccountId: currentAddressId }) || {}
        ).id;
      }
      if (threadId) {
        mailData.defaultThreadId = threadId;
      }
    }

    // Check integrity in email (especially the profile name)
    if (!force) {
      try {
        this.checkEmail({
          subject: mailData.subject,
          body: mailData.body,
          firstname: mailData.firstname,
          offerProfilesFirstnames,
        });
      } catch (e) {
        if (
          !_.contains(
            [
              WRONG_NAME_IN_EMAIL,
              SUSPICIOUS_CONTENT,
              HAS_XX,
              SHORT_BODY_WARNING,
              SHORT_SUBJECT_WARNING,
              HAS_DEFAULT_TEMPLATE_FIELDS,
            ],
            e?.message,
          )
        ) {
          sentryCaptureException({ error: e, tags: { feature: 'mail' } });
        }
        if (e.message === WRONG_NAME_IN_EMAIL && !force) {
          this.handleOpenForceSendModal({
            title: t('profile.contact.drafts.wrongNameModal.title'),
            content: t('profile.contact.drafts.wrongNameModal.content'),
            question: t('profile.contact.drafts.wrongNameModal.question'),
          });
          return;
        }
        if (e.message === SUSPICIOUS_CONTENT) {
          this.handleOpenForceSendModal({
            title: t('profile.contact.drafts.suspiciousContent.title'),
            content: t('profile.contact.drafts.suspiciousContent.content'),
            question: t('profile.contact.drafts.suspiciousContent.question'),
          });
          return;
        }
        if (e.message === HAS_DEFAULT_TEMPLATE_FIELDS) {
          this.handleOpenForceSendModal({
            title: t('profile.contact.drafts.suspiciousTemplate.title'),
            content: t('profile.contact.drafts.suspiciousTemplate.content'),
            question: t('profile.contact.drafts.suspiciousTemplate.question'),
          });
          return;
        }
        if (e.message === HAS_XX) {
          this.handleOpenForceSendModal({
            title: t('profile.contact.drafts.hasXX.title'),
            content: t('profile.contact.drafts.hasXX.content'),
            question: t('profile.contact.drafts.hasXX.question'),
          });
          return;
        }
        if (e.message === SHORT_BODY_WARNING) {
          this.handleOpenForceSendModal({
            title: t('profile.contact.drafts.shortBodyWarning.title'),
            content: t('profile.contact.drafts.shortBodyWarning.content'),
            question: t('profile.contact.drafts.shortBodyWarning.question'),
          });
          return;
        }
        if (e.message === SHORT_SUBJECT_WARNING) {
          this.handleOpenForceSendModal({
            title: t('profile.contact.drafts.shortSubjectWarning.title'),
            content: t('profile.contact.drafts.shortSubjectWarning.content'),
            question: t('profile.contact.drafts.shortSubjectWarning.question'),
          });
          return;
        }
        return;
      }
    }

    const nextProfileId = getNextProfileId({
      forbiddenProfileIds: getProfilesInMove(),
    });
    if (nextProfileId) {
      onChangeProfile(nextProfileId);
    }

    const displayHotKeyTip = emailApi?.isFirstSessionSend && !sendWithHotKey;
    // console.log('sendWithHotKey', sendWithHotKey);
    // console.log('isFirstSessionSend', emailApi?.isFirstSessionSend);
    // console.log('displayHotKeyTip', displayHotKeyTip);

    performSendEmail({
      sendEmail,
      onShowNotification,
      addProfileAction,
      refreshProfile,
      handleMarkProfileAsInMove,
      handleUnmarkProfileAsInMove,
      getProfilesInMove,
      onChangeStep,
      t,

      mailData,
      clientId,
      client,
      jobOfferId: offerId,
      profileId: profile.id,
      getNextProfileId,
      sequenceId,
      actionId,
      stepId,
      assignedSender,
      offerAssignedSender,

      displayHotKeyTip,
    });

    this.setState({
      open: false,
      forceSendModal: null,
    });

    emailApi.setIsFirstSessionSend(false);

    if (onSend) {
      onSend({ profileId: profile?.id, jobOfferId: offerId });
    }
  };

  handleAskSend = async ({ sendWithHotKey }) => {
    // TODO: refacto subject test with other email checks
    const {
      mailData,
      // emailApi,
      t,
      // user
    } = this.props;
    if (
      (!mailData ||
        !_.isString(mailData.body) ||
        mailData?.body?.length < 10) &&
      (mailData?.body?.length || 0) <= 7
    ) {
      this.raiseWarningModal(t('profile.contact.drafts.bodyEmptyError'));
    } else if (
      (!mailData ||
        !_.isString(mailData.subject) ||
        mailData?.subject?.length < 8) &&
      (mailData?.subject?.length || 0) <= 0
    ) {
      this.raiseWarningModal(t('profile.contact.drafts.subjectEmptyError'));
    } else if (
      // disabled feature
      false
      // emailApi?.sendConfirmationModeActive &&
      // user?.showConfirmationModal
    ) {
      this.handleOpenModal({ sendWithHotKey });
    } else {
      this.handleSubmit(undefined, undefined, sendWithHotKey);
    }
  };

  handleTrySend = async ({ sendWithHotKey }) => {
    this.handleAskSend({ sendWithHotKey });
  };

  render() {
    const {
      profile,
      clientId,
      assignedSender,
      offerAssignedSender,
      emailApi,
      mailData,
      // TODO: refactor these props as Context or single method
      getNextProfileId,
      getProfilesInMove,
      onChangeProfile,
      onChangeStep,
      handleMarkProfileAsInMove,
      handleUnmarkProfileAsInMove,
      addProfileAction,

      t,
    } = this.props;
    // const sourceName = ((profile || {}).resumeData || {}).sourceName;
    if (!profile) return <div />;
    if (!this.state.open && profile.locked) {
      return (
        <div className='send-button-with-lock'>
          <Button
            className='sending primary-cta'
            disabled
            icon='lock'
            content={t('profile.contact.drafts.sending')}
          />
          <UnlockProfileModal
            clientId={clientId}
            stepId={profile?.resumeData?.step}
            profileId={profile?.id}
            profile={profile}
            // TODO: refactor these props as Context or single method
            getNextProfileId={getNextProfileId}
            onChangeProfile={onChangeProfile}
            onChangeStep={onChangeStep}
            getProfilesInMove={getProfilesInMove}
            addProfileAction={addProfileAction}
            handleMarkProfileAsInMove={handleMarkProfileAsInMove}
            handleUnmarkProfileAsInMove={handleUnmarkProfileAsInMove}
          />
        </div>
      );
    }

    return (
      <div style={{ display: 'inline-block' }}>
        <WarningModal
          open={!!this.state.warningMessage}
          message={this.state.warningMessage}
          onCancel={this.closeWarningModal}
        />
        <ConfirmationModal
          sendDisabled={invalidEmailFormat({ email: mailData?.dest })}
          open={this.state.open}
          openWithHotKey={this.state.openWithHotKey}
          onTrigger={this.handleTrySend}
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          emailApi={emailApi}
          assignedSender={assignedSender}
          offerAssignedSender={offerAssignedSender}
          clientId={clientId}
        />
        <ForceSendModal
          open={!!this.state.forceSendModal}
          onCancel={this.handleCancelForceSendModal}
          onSubmit={this.handleForceSendSubmit}
          title={(this.state.forceSendModal || {}).title || ''}
          content={(this.state.forceSendModal || {}).content || ''}
          question={(this.state.forceSendModal || {}).question || ''}
        />
      </div>
    );
  }
}

export default compose(
  withUserSettings,
  withSendEmail,
  withActionLogger,
  withProfileDrafts,
  contextToProps,
  withLastProfileActionsContextConsumer,
  withClient,
  withTranslation('translations'),
)(SendEmailButton);
