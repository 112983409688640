import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import _ from 'underscore';
import { StackItem } from '@/types/statistics/stackItem';
import { Stats } from '@/types/statistics/stats';
import { filterStack, getStackName } from '@/common/helpers/stats';
import { dateTickFormatter, tooltipValueFormatter } from '../../utils';
import { Frequency, Mode } from '../GraphWrapper/types';
import Legend from '../Legend';

interface LineGraphProps {
  mode: Mode;
  stack: StackItem[];
  ticks: number[];
  fullData: any[];
  frequency: Frequency;
  stackWithData: StackItem[];
  allStats: Stats[];
}

const LineGraph: FC<LineGraphProps> = ({
  mode,
  stack,
  ticks,
  fullData,
  frequency,
  stackWithData,
  allStats,
}) => {
  const { t } = useTranslation();

  const filteredStack = useMemo(() => filterStack(stack, allStats), [
    stack,
    allStats,
  ]);

  const totalCountData = useMemo(
    () =>
      _.map(fullData, (dataItem) =>
        _.reduce(
          dataItem,
          (total, value, key) => {
            if (key === 'timestamp' || !(typeof value === 'number')) {
              return total;
            }
            return total + value;
          },
          0,
        ),
      ),
    [fullData],
  );

  const percentageData = useMemo(
    () =>
      _.map(fullData, (dataItem, index) =>
        _.mapObject(dataItem, (value, key) => {
          if (key === 'timestamp' || !(typeof value === 'number')) {
            return value;
          }
          return Math.round((100 * value) / totalCountData[index]) || 0;
        }),
      ),
    [fullData, totalCountData],
  );

  const MAX_BAR_SIZE = 100;
  const barSize = 5 + 1500 / (ticks.length || MAX_BAR_SIZE); // heuristic
  const AXIS_PADDING = barSize / 2 + 5;
  const Y_AXIS_WIDTH = 45;

  // NOTE: order of Axes / Bars is important for "z-index" of components
  return (
    <div>
      <Legend stack={filteredStack} />
      <ResponsiveContainer width='100%' height={300}>
        <LineChart
          data={mode === 'percentage' ? percentageData : fullData}
          // margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey='timestamp'
            type='number'
            ticks={ticks}
            tickCount={ticks?.length}
            tick={{ fontSize: '12px' }}
            // Mandatory to size the absicsa to fit the values
            domain={[(dataMin: any) => dataMin, (dataMax: any) => dataMax]}
            tickFormatter={dateTickFormatter(frequency, t)}
            // leave some room for first an last bars
            padding={{ left: AXIS_PADDING, right: AXIS_PADDING }}
          />
          <Tooltip
            labelFormatter={dateTickFormatter(frequency, t)}
            formatter={tooltipValueFormatter(mode, t)}
            separator=''
          />
          {stackWithData.map((item) => (
            <Line
              type='monotone'
              key={`line-${item.datakey}`}
              dataKey={item.datakey}
              stroke={item.color}
              name={getStackName(t, item)}
              opacity={0.9}
              animationDuration={300}
              activeDot={{ r: 8 }}
            />
          ))}
          <YAxis
            tickLine={false}
            axisLine={false}
            width={Y_AXIS_WIDTH}
            type='number'
            allowDecimals={false}
            allowDataOverflow
            domain={mode === 'percentage' ? [0, 100] : undefined}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineGraph;
