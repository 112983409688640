import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { StackItem } from '@/types/statistics/stackItem';
import { getStackName } from '@/common/helpers/stats';
import LegendCategory from './LegendCategory';

import styles from './Legend.module.less';

interface LegendProps {
  stack: StackItem[];
}

const Legend: React.FC<LegendProps> = ({ stack }) => {
  const { t } = useTranslation();

  const displayedStack = _.reject(stack, { hideFromLegend: true });

  if (_.isEmpty(displayedStack)) {
    return null;
  }

  return (
    <div className={styles.legend}>
      {_.map(displayedStack, (stackItem) => (
        <LegendCategory
          key={stackItem.datakey}
          color={stackItem.color}
          condensed
        >
          {getStackName(t, stackItem)}
        </LegendCategory>
      ))}
    </div>
  );
};

export default Legend;
