import useClientId from '@/hooks/router/useClientId';
import { 
  ProjectTitle, 
  ProjectContainer,
  HiresweetLibProvider,
  ProfileContainer,
  ProfileFirstname,
} from '@hiresweet/hiresweet-lib';
import React from 'react';

const PlaygroundFragmentTypes = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <HiresweetLibProvider theme={theme} clientId={clientId}>
      
      <h1>Cross fragments</h1>
      <br/>
      
      <ProjectContainer projectId='ri7-demo-testimport2-UMODD2'>
        <h2><ProjectTitle /></h2>

        <ProfileContainer profileId='jean-bombeur-45T0ZI'>
          <h2><ProfileFirstname /></h2>
        </ProfileContainer>

      </ProjectContainer>
      <br/>

    
    </HiresweetLibProvider>

  );
};

export default PlaygroundFragmentTypes;
