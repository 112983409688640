import classNames from 'classnames';
import React, { FC, MouseEvent } from 'react';

import Check from '@/components/Common/Icons/Check';

import styles from './GenericCheckbox.module.less';

interface GenericCheckboxProps {
  checked?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  big?: boolean;
  className?: string;
}

const GenericCheckbox: FC<GenericCheckboxProps> = ({
  checked,
  onClick,
  big,
  className = '',
}) =>
  onClick ? (
    <button
      type='button'
      className={classNames(
        className,
        styles.checkbox,
        checked && styles.checked,
        big && styles.big,
      )}
      onClick={onClick}
    >
      {checked && <Check />}
    </button>
  ) : (
    <div
      className={classNames(
        className,
        styles.checkbox,
        checked && styles.checked,
        big && styles.big,
      )}
    >
      {checked && <Check />}
    </div>
  );

export default GenericCheckbox;
