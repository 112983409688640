import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'underscore';
import { useQuery } from '@apollo/client';
import { Button, Loader, Modal, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { removeEmptyKeyValues } from '../../../../../common';
import withRevealFiltersOptions from '../../../../../hocs/searchPool/withRevealFiltersOptions';
import {
  GET_REVEAL_SEARCH_POOL_RESULTS,
  REVEAL_CATEGORIES,
  REVEAL_MAX_NB_RESULTS,
} from '../../../../../hocs/searchPool/withRevealSearchPoolResults';
import {
  getCriteriaForFilters,
  getCriteriaForSearch,
  getExtraCriteriaFromAdvancedBackgroundDefinition,
  validateAdvancedBackgroundDefinition,
} from '../OmegaCriteriaFilters/helpers';
import ExperienceFields from '../OmegaCriteriaFilters/ExperienceFields';
import IndustryFields from '../OmegaCriteriaFilters/IndustryFields';
import JobFields from '../OmegaCriteriaFilters/JobFields';
import LocationFields from '../OmegaCriteriaFilters/LocationFields';
import SkillsDropdown from '../OmegaCriteriaFilters/SkillsDropdown';
import GreenhouseSectionContent from './GreenhouseSectionContent';

import './SearchModal.css';
import ProjectFilters from '../OmegaCriteriaFilters/ProjectFilters';
import SequenceFields from '../OmegaCriteriaFilters/SequenceFields';
import { CustomCriteriaFilters } from '../CustomCriteriaFilters';
import ManagementLevelFields from '../OmegaCriteriaFilters/ManagementLevelFields';
import FreelanceFields from '../OmegaCriteriaFilters/FreelanceFields';
import CompanyFields from '../OmegaCriteriaFilters/CompanyFields';
import SchoolFields from '../OmegaCriteriaFilters/SchoolFields';

const SearchModal = ({
  clientId,
  projectId,
  projectsWithFilterOptions,
  searchParams,
  onUpdateSearchParams,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  // TODO: REFACTO + OPTIM with useReducer
  const [advBackgroundState] = useState();
  const [searchParamsState, setSearchParamsState] = useState(searchParams);
  // const [advancedErrors, setAdvancedErrors] = useState();

  const project = _.findWhere(projectsWithFilterOptions, { id: projectId });
  const hasGreenhouseConnector = !!_.findWhere(project?.connectors, {
    type: 'greenhouse',
  });

  const { criteria, greenhouseFilters } = searchParamsState || {};
  const criteriaForFilters = useMemo(
    () => getCriteriaForFilters({ criteria }),
    [criteria],
  );
  const {
    experience,
    locations,
    industries,
    skills,
    jobPosition,
    projectFilters,
    sequenceFilters,
    customFilters,
    managementLevels,
    freelanceStates,
    companyStates,
    schoolStates,
  } = criteriaForFilters;

  const getCustomFilterLength = () => {
    if (
      customFilters?.find(({ id }) => id === 'hasAnyMissingSources') &&
      customFilters?.find(({ id }) => id === 'hasSources')
    ) {
      return customFilters?.length - 1 ?? 1;
    }
    return customFilters?.length ?? 0;
  };

  const fullSearchParamsState = getFullSearchParamsState({
    searchParamsState,
    advBackgroundState,
  });

  const searches = [{ free: JSON.stringify({ ...fullSearchParamsState }) }];
  const variables = {
    searchPoolId: 'reveal',
    searchesCriteria: searches,
    searchText: '',
    categories: REVEAL_CATEGORIES,
    maxNbResults: REVEAL_MAX_NB_RESULTS,
  };
  // TODO: network-only ?
  const searchResults = useQuery(GET_REVEAL_SEARCH_POOL_RESULTS, { variables });
  const searchCount =
    (searchResults?.data?.searchPool?.searchResults?.categories || [])[0]
      ?.count || 0;

  useEffect(() => {
    setSearchParamsState(searchParams);
  }, [searchParams]);

  // eslint-disable-next-line no-shadow
  const onUpdateGreenhouseFilters = ({ greenhouseFilters }) => {
    setSearchParamsState((oldState) => ({
      ...(oldState || {}),
      greenhouseFilters: removeEmptyKeyValues(greenhouseFilters),
    }));
  };

  // eslint-disable-next-line no-shadow
  const onUpdateCriteria = useCallback(({ criteria }) => {
    setSearchParamsState((oldState) => ({
      ...(oldState || {}),
      ...(criteria && { criteria }),
    }));
  }, []);

  const updateFilter = useCallback(
    (field) => {
      return (value) => {
        /* eslint-disable no-shadow */
        const {
          experience,
          locations,
          skills,
          jobPosition,
          backgroundSolidity,
          schoolPrestige,
          startupnessImportance,
          projectFilters,
          sequenceFilters,
        } = criteriaForFilters;
        /* eslint-enable no-shadow */

        const newCriteria = {
          experience,
          locations,
          backgroundSolidity,
          schoolPrestige,
          startupnessImportance,
          skills,
          jobPosition,
          projectFilters,
          sequenceFilters,
          [field]: value,
        };
        const criteriaForSearch = getCriteriaForSearch(newCriteria);
        onUpdateCriteria({ criteria: criteriaForSearch });
      };
    },
    [onUpdateCriteria, criteriaForFilters],
  );

  const onSubmitSearch = () => {
    const errors = validateAdvancedBackgroundDefinition({
      advancedBackgroundDefinition: advBackgroundState,
    });
    if (!_.isEmpty(errors)) {
      // setAdvancedErrors(errors);
      return;
    }

    const fullSearchParams = getFullSearchParamsState({
      searchParamsState,
      advBackgroundState,
    });
    onUpdateSearchParams({ newSearchParams: fullSearchParams });
    onClose();
  };

  const updateSkills = useMemo(() => updateFilter('skills'), [updateFilter]);

  const updateLocations = useMemo(() => updateFilter('locations'), [
    updateFilter,
  ]);

  const updateIndustries = useMemo(() => updateFilter('industries'), [
    updateFilter,
  ]);

  const updateJobPosition = useMemo(() => updateFilter('jobPosition'), [
    updateFilter,
  ]);

  const updateManagementLevels = useMemo(
    () => updateFilter('managementLevels'),
    [updateFilter],
  );

  const updateFreelanceStates = useMemo(() => updateFilter('freelanceStates'), [
    updateFilter,
  ]);

  const updateCompanyStates = useMemo(() => updateFilter('companyStates'), [
    updateFilter,
  ]);

  const updateSchoolStates = useMemo(() => updateFilter('schoolStates'), [
    updateFilter,
  ]);

  const updateCustomFilters = useMemo(() => updateFilter('customFilters'), [
    updateFilter,
  ]);

  const updateProjectFilters = (updateFunction) => {
    setSearchParamsState((previousState) => ({
      ...previousState,
      criteria: {
        ...previousState?.criteria,
        projectFilters: updateFunction(
          previousState?.criteria?.projectFilters || [],
        ),
      },
    }));
  };
  const updateSequenceFilters = (updateFunction) => {
    setSearchParamsState((previousState) => ({
      ...previousState,
      criteria: {
        ...previousState?.criteria,
        sequenceFilters: updateFunction(
          previousState?.criteria?.sequenceFilters || [],
        ),
      },
    }));
  };

  // TODO 2: experience magic filter toggle
  return (
    <Modal
      className='reveal-search-modal'
      open={open}
      onClose={onClose}
      size='fullscreen'
      closeIcon
    >
      <Modal.Header>
        <span className='title h2'>
          {t('reveal.searchView.filters.modal.allFilters')}
        </span>
        <div className='hs-spacer' />
        <span className='count h2'>
          {searchResults?.loading ? (
            <Loader active inline className='on-dimmer' size='small' />
          ) : (
            <span>
              {searchCount} {t('reveal.searchView.filters.modal.candidates')}
              {searchCount === 1 ? '' : 's'}
            </span>
          )}
        </span>
        <Button
          primary
          className='submit-button headline-3'
          onClick={onSubmitSearch}
        >
          {t('common.search')}
        </Button>
      </Modal.Header>

      <Modal.Content>
        <div className='column-1'>
          <FiltersSection title={t('reveal.searchView.filters.job.label')}>
            <JobFields
              jobPosition={jobPosition}
              setJobPosition={updateJobPosition}
            />
          </FiltersSection>

          <FiltersSection title={t('reveal.searchView.filters.skills.label')}>
            <SkillsDropdown
              skills={skills}
              setSkills={updateSkills}
              name='main'
              label={t('reveal.searchView.filters.skills.main')}
            />
            <SkillsDropdown
              skills={skills}
              setSkills={updateSkills}
              name='important'
              label={t('reveal.searchView.filters.skills.important')}
            />
            <SkillsDropdown
              skills={skills}
              setSkills={updateSkills}
              name='bonus'
              label={t('reveal.searchView.filters.skills.bonus')}
            />
          </FiltersSection>

          <FiltersSection title={t('reveal.searchView.search.moreFilters')}>
            <CustomCriteriaFilters
              clientId={clientId}
              defaultParams={customFilters}
              count={getCustomFilterLength()}
              onChange={updateCustomFilters}
              withoutAccordion
            />
          </FiltersSection>
        </div>

        <div className='column-2'>
          <FiltersSection title={t('reveal.searchView.filters.location.label')}>
            <LocationFields
              locations={locations}
              setLocations={updateLocations}
            />
          </FiltersSection>

          <FiltersSection
            title={t('reveal.searchView.filters.background.label')}
          >
            <div className='label'>
              {t('reveal.searchView.filters.background.experience')}
            </div>
            <ExperienceFields
              experience={experience}
              setExperience={updateFilter('experience')}
            />

            <br />
            <div className='label'>
              {t('reveal.searchView.filters.background.industry')}
            </div>
            <IndustryFields
              industries={industries}
              setIndustries={updateIndustries}
            />
            <br />

            <div className='label'>
              {t('reveal.searchView.filters.background.management')}
            </div>
            <ManagementLevelFields
              managementLevels={managementLevels}
              setManagementLevels={updateManagementLevels}
            />
            <br />

            <div className='label'>
              {t('reveal.searchView.filters.background.freelance')}
            </div>
            <FreelanceFields
              freelanceStates={freelanceStates}
              setFreelanceStates={updateFreelanceStates}
            />
            <br />

            <div className='label'>
              {t('reveal.searchView.filters.background.companies')}
            </div>
            <CompanyFields
              companyStates={companyStates ?? {}}
              setCompanyStates={updateCompanyStates}
            />

            <div className='label'>
              {t('reveal.searchView.filters.background.schools')}
            </div>
            <SchoolFields
              schoolStates={schoolStates ?? {}}
              setSchoolStates={updateSchoolStates}
            />
          </FiltersSection>

          {hasGreenhouseConnector && (
            <>
              <FiltersSection title='Projects'>
                <ProjectFilters
                  projectFilters={projectFilters}
                  updateProjectFilters={updateProjectFilters}
                />
              </FiltersSection>
              <FiltersSection
                title={`${t('reveal.searchView.filters.sequence.label')}s`}
              >
                <SequenceFields
                  sequenceFilters={sequenceFilters}
                  updateSequenceFilters={updateSequenceFilters}
                  clientId={clientId}
                />
              </FiltersSection>
            </>
          )}
        </div>

        <div className='column-3'>
          {hasGreenhouseConnector ? (
            <FiltersSection title='ATS (Greenhouse)'>
              <GreenhouseSectionContent
                projectId={projectId}
                projectsWithFilterOptions={projectsWithFilterOptions}
                clientId={clientId}
                filters={greenhouseFilters}
                onUpdate={onUpdateGreenhouseFilters}
              />
            </FiltersSection>
          ) : (
            <>
              <FiltersSection title='Projects'>
                <ProjectFilters
                  projectFilters={projectFilters}
                  updateProjectFilters={updateProjectFilters}
                />
              </FiltersSection>
              <FiltersSection title='Sequences'>
                <SequenceFields
                  sequenceFilters={sequenceFilters}
                  updateSequenceFilters={updateSequenceFilters}
                  clientId={clientId}
                />
              </FiltersSection>
            </>
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};

const getFullSearchParamsState = ({
  searchParamsState,
  advBackgroundState,
}) => {
  const errors = validateAdvancedBackgroundDefinition({
    advancedBackgroundDefinition: advBackgroundState,
  });
  return removeEmptyKeyValues({
    ...(searchParamsState || {}),
    criteria: {
      ...(searchParamsState?.criteria || {}),
      ...(!_.isEmpty(advBackgroundState) &&
        _.isEmpty(errors) &&
        getExtraCriteriaFromAdvancedBackgroundDefinition({
          advancedBackgroundDefinition: advBackgroundState,
        })),
    },
  });
};

const FiltersSection = ({ title, children }) => {
  return (
    <Segment className='reveal-filters-section'>
      <h2 className='title'>{title}</h2>
      <div className='section-content'>{children}</div>
    </Segment>
  );
};

export default withRevealFiltersOptions(SearchModal);
