import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'underscore';
import { Segment } from 'semantic-ui-react';
import { toTriggerInput } from './helpers';

import routerParamsToProps from '../../../hocs/routerParamsToProps';
import contextToProps from '../../../hocs/contextToProps';
import withOfferContactFlow from '../../../hocs/offers/withOfferContactFlow';
import withInsertAction from '../../../hocs/contactFlows/withInsertAction';
import withUpdateAction from '../../../hocs/contactFlows/withUpdateAction';
import withDeleteAction from '../../../hocs/contactFlows/withDeleteAction';
import withDeleteActions from '../../../hocs/contactFlows/withDeleteActions';
import withAddSequence from '../../../hocs/contactFlows/withAddSequence';
import withUpdateSequenceTrigger from '../../../hocs/contactFlows/withUpdateSequenceTrigger';
import withDeleteSequence from '../../../hocs/contactFlows/withDeleteSequence';
import withCreateContactFlow from '../../../hocs/contactFlows/withCreateContactFlow';
import createActionInputFromAction from '../../../common/contactFlow/createActionInputFromAction';
import OfferStateModalWrapper from '../Offers/components/ChangeOfferStateModalWrapper';
import OfferRecruiterConfiguration from '../Offers/components/OfferRecruiterConfiguration';
import ConfigureSenderModal from '../Offers/components/ConfigureSenderModal';
import withUserFromJWToken from '../../../hocs/users/withUserFromJWToken';
import withOfferParams from '../../../hocs/offers/withOfferParams';
import TwoColumnLayout from '../../../components/layout/TwoColumnLayout';

import ContactFlowEditor from './ContactFlowEditor';
import CuratedAutoSendControls from './CuratedAutoSendControls';

import './OfferContactFlow.css';

class OfferContactFlow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalType: null,
    };
  }

  handleInsertAction = ({ sequenceId, index, action }) => {
    const { offer, insertAction } = this.props;
    const contactFlow = (offer || {}).contactFlow || {};
    if (!contactFlow.id) {
      throw Error('invalid contact flow');
    }

    insertAction({
      contactFlowId: contactFlow.id,
      sequenceId,
      actionIndex: index,
      actionInput: createActionInputFromAction({ action }),
    });
  };

  handleUpdateAction = ({ sequenceId, actionId, action }) => {
    const { offer, updateAction } = this.props;
    const contactFlow = (offer || {}).contactFlow || {};
    if (!contactFlow.id) {
      throw Error('invalid contact flow');
    }

    updateAction({
      contactFlowId: contactFlow.id,
      sequenceId,
      actionId,
      actionInput: createActionInputFromAction({ action }),
    });
  };

  handleRemoveAction = ({ sequenceId, actionId }) => {
    const { offer, removeAction } = this.props;
    const contactFlow = (offer || {}).contactFlow || {};
    if (!contactFlow.id) {
      throw Error('invalid contact flow');
    }
    removeAction({
      contactFlowId: contactFlow.id,
      sequenceId,
      actionId,
    });
  };

  handleRemoveActions = ({ sequenceId, actionIds }) => {
    const { offer, removeActions } = this.props;
    const contactFlow = (offer || {}).contactFlow || {};
    if (!contactFlow.id) {
      throw Error('invalid contact flow');
    }
    removeActions({
      contactFlowId: contactFlow.id,
      sequenceId,
      actionIds,
    });
  };

  handleAddSequence = async ({ sequence }) => {
    const { offer, addSequence, createContactFlow } = this.props;
    const { title, position } = sequence;

    // TODO: decide how to do clean contactFlow initialisation
    let contactFlowId;
    try {
      if (!offer?.contactFlow) {
        const contactFlowInput = {};
        const { data } = await createContactFlow({ contactFlowInput });
        contactFlowId = data?.createContactFlow?.contactFlow?.id;
      } else {
        contactFlowId = offer?.contactFlow?.id;
      }

      if (!contactFlowId) {
        throw new Error('Missing contactFlowId');
      }

      addSequence({
        jobOfferId: offer.id,
        contactFlowId,
        sequenceInput: {
          title,
          position,
          actions: [],
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleUpdateSequenceTrigger = ({ sequenceId, trigger }) => {
    const { offer, updateSequenceTrigger } = this.props;
    const contactFlow = (offer || {}).contactFlow || {};
    updateSequenceTrigger({
      jobOfferId: offer.id,
      contactFlowId: contactFlow.id,
      sequenceId,
      triggerInput: toTriggerInput({ trigger }),
    });
  };

  handleRemoveSequence = ({ sequenceId }) => {
    const { offer, deleteSequence } = this.props;
    const contactFlow = (offer || {}).contactFlow || {};
    if (!contactFlow.id) {
      throw Error('invalid contact flow');
    }

    deleteSequence({
      jobOfferId: offer.id,
      contactFlowId: contactFlow.id,
      sequenceId,
    });
  };

  render() {
    const {
      loading,
      error,
      offer,
      clientId,
      defaultTemplatesOnSeveralOffers,
      user,
      t,
    } = this.props;
    const { modalOpen, modalType } = this.state;
    if (loading || !offer) {
      return <div />;
    }
    if (error) {
      return <p />;
    }

    const contactFlow = offer.contactFlow || {};
    const recruiterConfiguration = (offer?.recruiterConfigurations || [])[0];
    const { assignedSender } = recruiterConfiguration || {};

    const setModalType = (type) => {
      this.setState({ modalType: type });
    };

    return (
      <div className='offer-contact-flow'>
        <TwoColumnLayout
          leftColumn={
            <ContactFlowEditor
              clientId={clientId}
              t={t}
              offerId={offer.id}
              onAddSequence={this.handleAddSequence}
              onUpdateSequenceTrigger={this.handleUpdateSequenceTrigger}
              onRemoveSequence={this.handleRemoveSequence}
              onInsertAction={this.handleInsertAction}
              onUpdateAction={this.handleUpdateAction}
              onRemoveAction={this.handleRemoveAction}
              onRemoveActions={this.handleRemoveActions}
              contactFlow={contactFlow}
              defaultTemplatesOnSeveralOffers={defaultTemplatesOnSeveralOffers}
            />
          }
          rightColumn={
            <div>
              {!assignedSender && (
                <Segment
                  className='quick-action'
                  onClick={() => this.setState({ modalOpen: true })}
                  role='button'
                >
                  <i className='ri-group-line' />
                  {t('sequences.configureSender')}
                </Segment>
              )}
              {assignedSender && (
                <OfferRecruiterConfiguration
                  assignedSender={assignedSender}
                  user={user}
                  setModalOpen={() => {
                    this.setState({ modalOpen: true });
                  }}
                />
              )}
              {offer?.id && (
                <AutopilotToggle
                  offerId={offer?.id}
                  setModalType={setModalType}
                  t={t}
                />
              )}
              {offer?.id &&
                user?.isAdmin &&
                user?.permissions?.canEnableAutoSend && (
                  <Segment>
                    <CuratedAutoSendControls offerId={offer.id} />
                  </Segment>
                )}
              <Segment className='hiresweet-tips'>
                <h3>{t('sequences.hiresweetTip.title')}</h3>
                <div className='tips-content'>
                  {t('sequences.hiresweetTip.content')}
                </div>
              </Segment>
              <OfferStateModalWrapper
                offer={offer}
                clientId={clientId}
                modalType={modalType}
                onCloseModal={() => this.setState({ modalType: null })}
              />
              <ConfigureSenderModal
                open={modalOpen}
                offerId={offer?.id}
                onClose={() => this.setState({ modalOpen: false })}
              />
            </div>
          }
        />
      </div>
    );
  }
}

const AutopilotToggle = withOfferParams(({ offer, setModalType, t }) => {
  if (offer?.isAutopilot) {
    return (
      <Segment
        className='quick-action'
        onClick={() => setModalType('disableAutopilot')}
        role='button'
      >
        <i className='ri-send-plane-line' />
        {t('offers.disableAutopilot')}
      </Segment>
    );
  }
  return (
    <Segment
      className='quick-action'
      onClick={() => setModalType('enableAutopilot')}
      role='button'
    >
      <i className='ri-send-plane-fill' />
      {t('offers.enableAutopilot')}
    </Segment>
  );
});

export default compose(
  routerParamsToProps,
  contextToProps,
  withTranslation('translations'),
  withUserFromJWToken,
  withOfferContactFlow,
  withCreateContactFlow,
  withDeleteAction,
  withDeleteActions,
  withInsertAction,
  withUpdateAction,
  withAddSequence,
  withUpdateSequenceTrigger,
  withDeleteSequence,
)(OfferContactFlow);
