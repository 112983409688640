import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextingSender } from '@/graphql/hooks/clients/useTextingSenders';
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';

interface DeleteTextingSenderModalProps {
  open: boolean;
  textingSender: TextingSender;
  closeModal: () => void;
  onDelete: () => void;
}

const DeleteTextingSenderModal: React.FC<DeleteTextingSenderModalProps> = ({
  open,
  textingSender,
  closeModal,
  onDelete,
}) => {
  const { t } = useTranslation();
  if (!textingSender) return null;
  const handleDelete = () => {
    closeModal();
    onDelete();
  };
  return (
    <ConfirmationModal
      content={t('settings.numbers.deletenumber', {
        alias: textingSender.senderNumber,
        email: textingSender.senderNumber,
      })}
      header={t('settings.numbers.deleteHeader')}
      onCancel={closeModal}
      onSubmit={handleDelete}
      validationType='negative'
      open={open}
    />
  );
};

export default DeleteTextingSenderModal;
