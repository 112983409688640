import _ from 'underscore';

import { TranslatableText as TranslatableTextType } from '@/types/text';
import { getTranslatedText } from '..';

export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const filterHtmlText = (text: string): string => {
  const element = document.createElement('div');
  element.innerHTML = text;
  return element.textContent || element.innerText;
};

export const stripHtmlFromTextAndLinesBreaks = (text: string): string => {
  if (!text) {
    return '';
  }
  return text
    .replaceAll('<br />', '\n')
    .replaceAll('<br/>', '\n')
    .replace(/<[^>]*>?/gm, '');
};

const INFINITY = 1 / 0;

/**
 * Lodash's "toString" method directly taken from the source :
 * https://github.com/lodash/lodash/blob/master/toString.js
 *
 * @param {*} value The value to convert.
 * @returns {string} Returns the converted string.
 */
export const toString = (value: unknown): string => {
  if (value === null) {
    return '';
  }
  // Exit early for strings to avoid a performance hit in some environments.
  if (typeof value === 'string') {
    return value;
  }
  if (Array.isArray(value)) {
    // Recursively convert values (susceptible to call stack limits).
    return `${value.map((other) => (other == null ? other : toString(other)))}`;
  }
  if (_.isSymbol(value)) {
    return value.toString();
  }
  const result = `${value}`;
  if (typeof value !== 'number' || typeof value !== 'bigint') {
    return result;
  }
  return result === '0' && 1 / value === -INFINITY ? '-0' : result;
};

export const replaceWildcardsWithBoldHTMLText = ({
  text,
}: {
  text: TranslatableTextType;
}): string | null => {
  let contentWithBoldWords = getTranslatedText(text);
  const wordBetweenWildcardsRegex = /\*\*[^*]+\*\*/g;
  let currentBoldWord: RegExpExecArray | null;
  do {
    currentBoldWord = wordBetweenWildcardsRegex.exec(contentWithBoldWords);
    if (currentBoldWord) {
      const stringToBold = currentBoldWord[0];
      contentWithBoldWords = contentWithBoldWords.replace(
        stringToBold,
        `<b>${stringToBold.substr(2, stringToBold.length - 4)}</b>`,
      );
    }
  } while (currentBoldWord);
  if (!contentWithBoldWords) {
    return null;
  }
  return contentWithBoldWords;
};

const getWebsiteRegex = (domain: string | undefined): RegExp =>
  new RegExp(`^(https://)?(www.)?${domain}/.+$`);

export const isValidUrlFromWebsite = (domain: string | undefined) => (
  url: string,
): boolean => getWebsiteRegex(domain).test(url);

const urlRegex = /^(https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export const isValidUrl = (url: string): boolean => urlRegex.test(url);

export const removeAccents = (source: string): string =>
  source.normalize('NFD').replace(/\p{Diacritic}/gu, '');

export const caseInsensitiveIncludes = (reference = '', test = ''): boolean =>
  removeAccents((reference || '').toLowerCase()).includes(
    removeAccents((test || '').toLowerCase()),
  );

export const getLimitedText = (text: string, limit: number) => {
  if (!text) {
    return '';
  }
  if (text.length > limit) {
    return `${text.slice(0, limit)}...`;
  }
  return text;
};

export const decodeHtml = (text: string): string => {
  if (!text) {
    return '';
  }
  return text
    .replace(/&#(\d+);/g, (_match, dec) => {
      return String.fromCharCode(dec);
    })
    .replace(/&quot;/g, '"')
    .replace(/&amp;/g, '&')
    .replace(/&nbsp;/g, ' ')
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/&#x27;/g, "'");
};
