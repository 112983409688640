import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import useClientId from '@/hooks/router/useClientId';
import ButtonLink from '@/components/Common/GenericButton/ButtonLink';

import OfferPendingPreview from './OfferPendingPreview';
import CardEmptyState from '../CardEmptyState';

interface OthersPendingEmptyStateProps {
  isPrimary: boolean;
  offerIds: string[];
}

const OthersPendingEmptyState: FC<OthersPendingEmptyStateProps> = ({
  isPrimary,
  offerIds,
}) => {
  const clientId = useClientId();
  const { t } = useTranslation();

  return (
    <CardEmptyState
      title={t('offers.profileEmptyStates.othersPending.title')}
      cta={
        <ButtonLink
          to={`/client/${clientId}/jobs`}
          primacy={isPrimary ? 'primary' : 'secondary'}
        >
          {t('offers.profileEmptyStates.othersPending.cta')}
        </ButtonLink>
      }
    >
      <div>
        {_.map(offerIds, (offerId) => (
          <OfferPendingPreview key={offerId} offerId={offerId} />
        ))}
      </div>
    </CardEmptyState>
  );
};

export default OthersPendingEmptyState;
