import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

export const removeSharedActivity = gql`
  mutation removeSharedActivity($searchPoolId: ID!, $sharedActivityId: ID!) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      removeSharedActivity(sharedActivityId: $sharedActivityId) {
        errors
      }
    }
  }
`;

type Data = {
  data: {
    searchPoolProfile: {
      removeSharedActivity: {
        errors: string[];
      };
    };
  };
};

type Variables = {
  searchPoolId: string;
  sharedActivityId: string;
};

const useRemoveSharedActivity = () =>
  useMutation<Data, Variables>(removeSharedActivity, {
    refetchQueries: [
      'getEnrichedSearchPoolProfile',
      'getMissionSharedActivities',
    ],
  });

export default useRemoveSharedActivity;
