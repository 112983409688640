import { Application } from './SourceData.type';

export const getWelcomekitCandidateURL = ({
  candidateId,
  accountId,
  applications,
}: {
  candidateId: string | null;
  accountId?: string;
  applications?: Application[];
}): string | null => {
  const jobId = applications?.[0]?.jobs?.[0]?.sourceJobId;
  if (!candidateId || !accountId || !jobId) {
    return null;
  }

  return `https://www.welcomekit.co/dashboard/o/${accountId}/jobs/${jobId}/card/${candidateId}`;
};

export default getWelcomekitCandidateURL;
