import React, { FC, useContext } from 'react';
import _ from 'underscore';
import { Placeholder } from 'semantic-ui-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import useMiniMission from '@/graphql/hooks/searchPoolJob/useMiniMission';
import { ProfileColumnProps } from '@/components/ProfileRow/types';
import { PipelineState } from '@/components/PipelineSegmentation/pipelineSegmentation';
import useClientId from '@/hooks/router/useClientId';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import MissionCategoryContext from '@/context/MissionCategoryContext';

import styles from './MissionColumn.module.less';

interface MissionColumnProps extends ProfileColumnProps {
  missionId: string;
}

const MissionColumn: FC<MissionColumnProps> = ({
  profile,
  profileLoading,
  missionId,
  className,
  style,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const missionCategory = useContext(MissionCategoryContext);

  const { loading, miniMission } = useMiniMission(missionId);

  const missionInfo = _.findWhere(profile?.missionsInfo || [], { missionId });

  const pipelineState =
    missionInfo?.data?.segmentationStates?.[0]?.state || PipelineState.PENDING;

  const pipelineStateName = t(`reveal.pipelineSegmentations.${pipelineState}`, {
    count: 1,
  });

  const segmentName = missionInfo?.data?.archived
    ? t('reveal.pipelineSegmentations.archivedFrom', {
        pipelineState: pipelineStateName,
      })
    : pipelineStateName;

  const title = miniMission?.data?.title || '???';

  const params = useMergedConfigurationParams();
  const shouldDisableDetailsRedirections =
    params.shouldDisableDetailsRedirections === 'true';

  return (
    <div className={classNames(styles.mission, className)} style={style}>
      {loading || profileLoading ? (
        <Placeholder>
          <Placeholder.Line length='short' />
        </Placeholder>
      ) : (
        <>
          {shouldDisableDetailsRedirections ? (
            <span className={styles.missionName}>{title}</span>
          ) : (
            <Link
              className={classNames(styles.missionName, styles.clickable)}
              to={`/client/${clientId}/reveal/projects/${missionCategory}/${missionId}/profiles?segmentId=${
                missionInfo?.data?.archived ? 'archived' : pipelineState
              }`}
            >
              {title}
            </Link>
          )}
          <div className={styles.segment}>{segmentName}</div>
        </>
      )}
    </div>
  );
};

export default MissionColumn;
