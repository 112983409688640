import React, { FC, ReactNode } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import {
  isConvertibleCampaignEventType,
  statsCampaignEvents,
  StatsCampaignEventType,
} from '@/types/statistics/campaigns';
import GenericFunnelSummary from '@/routes/RevealView/RevealAnalytics/components/GenericFunnelSummary';
import { percentageToString } from '@/common/utils/number';
import { getConversionRateNumbers } from '../helpers';

import styles from './CampaignFunnelSummary.module.less';

interface CampaignFunnelSummaryProps {
  data: Record<StatsCampaignEventType, number>;
  clickListeners?: Partial<Record<StatsCampaignEventType, () => void>>;
}

const CampaignFunnelSummary: FC<CampaignFunnelSummaryProps> = ({
  data,
  clickListeners,
}) => {
  const { t } = useTranslation();
  const conversionRates = getConversionRateNumbers(data);

  const tooltips: Record<StatsCampaignEventType, ReactNode> = {
    nbEnrolled: (
      <>
        <h1>
          {t(
            'reveal.reports.tooltips.marketing.campaignFunnels.categories.nbEnrolled.title',
          )}
        </h1>
        <h2>{t('reveal.reports.tooltips.common.surprised')}</h2>
        <p>
          {t(
            'reveal.reports.tooltips.marketing.campaignFunnels.categories.nbEnrolled.contactedParagraph',
          )}
        </p>
      </>
    ),
    nbContacted: (
      <>
        <h1>
          {t(
            'reveal.reports.tooltips.marketing.campaignFunnels.categories.nbContacted.title',
          )}
        </h1>
        <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
        <p>
          {t(
            'reveal.reports.tooltips.marketing.campaignFunnels.categories.nbContacted.definitionParagraph',
          )}
        </p>
      </>
    ),
    nbOpened: (
      <>
        <h1>
          {t(
            'reveal.reports.tooltips.marketing.campaignFunnels.categories.nbOpened.title',
          )}
        </h1>
        <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
        <p>
          {t(
            'reveal.reports.tooltips.marketing.campaignFunnels.categories.nbOpened.multiOpenParagraph',
          )}
        </p>
        <p>
          {t(
            'reveal.reports.tooltips.marketing.campaignFunnels.categories.nbOpened.trackingParagraph',
          )}
        </p>
      </>
    ),
    nbClicked: (
      <>
        <h1>
          {t(
            'reveal.reports.tooltips.marketing.campaignFunnels.categories.nbClicked.title',
          )}
        </h1>
        <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
        <p>
          {t(
            'reveal.reports.tooltips.marketing.campaignFunnels.categories.nbClicked.multiClickParagraph',
          )}
        </p>
        <h2>{t('reveal.reports.tooltips.common.surprised')}</h2>
        <p>
          {t(
            'reveal.reports.tooltips.marketing.campaignFunnels.categories.nbClicked.blockersParagraph',
          )}
        </p>
        <p>
          {t(
            'reveal.reports.tooltips.marketing.campaignFunnels.categories.nbClicked.trackingParagraph',
          )}
        </p>
      </>
    ),
    nbUnsubscribe: (
      <h1>
        {t(
          'reveal.reports.tooltips.marketing.campaignFunnels.categories.nbUnsubscribe.title',
        )}
      </h1>
    ),
  };

  const segmentDescriptions = _.map(statsCampaignEvents, (key) => ({
    key,
    name: t(`reveal.reports.marketing.state.${key}`),
    count: data[key],
    conversionRate: isConvertibleCampaignEventType(key)
      ? percentageToString(t)(conversionRates[key])
      : undefined,
    clickListener: clickListeners?.[key],
    tooltip: tooltips[key],
    classNames: { global: styles[key] },
  }));
  return <GenericFunnelSummary segments={segmentDescriptions} />;
};

export default CampaignFunnelSummary;
