import _ from 'underscore';
import React from 'react';
import { TFunction } from 'i18next';

export const getApplicationStageAndStatusText = (application: any) => {
  const { status, isProspect } = application || {};
  const { sourceStatusId, rejection, stage } = status || {};
  const rejectionReason = rejection?.reason?.sourceRejectionReasonName;
  const stageName = stage?.sourceStageName;
  return getApplicationStatusText({
    isProspect,
    sourceStatusId,
    rejectionReason,
    stageName,
  });
};

export const getApplicationStatusText = ({
  isProspect,
  sourceStatusId,
  rejectionReason,
  stageName,
}: any): string => {
  const status = (sourceStatusId || '').charAt(0).toUpperCase()
    + (sourceStatusId || '').substring(1);
  if (sourceStatusId === 'hired') {
    return `${status}`;
  }
  if (isProspect) {
    return `${status} (Prospect)`;
  }
  return `${status}, ${
    sourceStatusId === 'rejected' ? rejectionReason : stageName
  }`;
};

interface GreenhouseTimelineProps {
  activityFeed: Array<any>;
  t: TFunction;
}
export const GreenhouseTimeline: React.FC<GreenhouseTimelineProps> = ({
  activityFeed,
  t,
}) => {
  if (_.isEmpty(activityFeed)) {
    return null;
  }

  return (
    <>
      <h3>Timeline</h3>
      <div className='greenhouse-timeline'>
        {_.map(activityFeed, ({ creationDate, subject, body }, index) => {
          return (
            <div className='timeline-item' key={index}>
              <div className='subject'>{subject || body}</div>
              {subject && <div className='body'>{body}</div>}
              {creationDate && (
                <div className='date'>
                  {t('common.simpleDate', { date: new Date(creationDate) })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};
