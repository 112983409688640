import React, { useContext, useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';

import {
  Form,
  Input,
  ModalActions as Actions,
  ModalContent as Content,
  ModalHeader as Header,
} from 'semantic-ui-react';

import GenericButton from '@/components/Common/GenericButton';
import GmailAliasPreview from '../GmailAliasPreview';
import EmailContext from '../../../../../context/EmailContext';
import { validNewSenderFormData } from '../helpers';

import './SenderModalStep2.css';

const SenderModalStep2 = ({
  senderDetails,
  setSenderDetails,
  toPrevStep,
  toNextStep,
  t,
}) => {
  const [loadedPrefill, setLoadedPrefill] = useState(false);
  const {
    connectionInProgress,
    currentAddress,
    currentEmailType,
    getEmailAddressDetails,
  } = useContext(EmailContext);

  useEffect(() => {
    const prefillAccountDetails = async () => {
      const prefillValues = await getEmailAddressDetails(currentEmailType);
      const { firstname, lastname, alias, signature } = prefillValues || {};
      const fullName = `${firstname} ${lastname}`.trim();
      // Outlook user does not have chance to edit the alias
      // default alias is set in backend if not provided
      const defaultAlias = currentEmailType === 'gmail' ? fullName : '';
      const prefilledSenderDetails = {
        firstname: firstname || '',
        lastname: lastname || '',
        alias: alias || defaultAlias,
        signature: signature || '',
      };
      setSenderDetails(prefilledSenderDetails);
      setLoadedPrefill(true);
    };

    prefillAccountDetails();
    // run once on mount and when current address changes
    // eslint-disable-next-line
  }, [currentAddress, currentEmailType]);

  const { alias, signature, firstname, lastname } = senderDetails;

  const onChange = (e, { name, value }) =>
    setSenderDetails({ ...senderDetails, [name]: value });
  const onChangeSignature = (e) => {
    onChange(null, { name: 'signature', value: e.target.value });
  };

  const confirmationDisabled = !validNewSenderFormData({
    alias,
    signature,
    firstname,
    lastname,
    senderAddress: currentAddress,
    emailType: currentEmailType,
  });

  const loading = connectionInProgress || !loadedPrefill;

  return (
    <>
      <Header>{t('settings.senders.modalStep2.header')}</Header>
      <Content className='sender-modal-form'>
        <div className='inputs-row'>
          <Form.Field className='email-field input-container half-width'>
            <div className='input-label'>
              {t('settings.senders.modalStep2.emailAccount')}
            </div>
            <div className='input-element'>
              <Input
                type='text'
                style={{ opacity: 0.8 }}
                disabled
                loading={loading}
                value={currentAddress}
                fluid
              />
            </div>
          </Form.Field>
          {/* Spacer
          <div className='input-container half-width' /> */}
        </div>
        <div className='inputs-row'>
          <Form.Field className='text-field input-container half-width'>
            <div className='input-label'>
              {t('settings.senders.modalStep2.firstName')}
            </div>
            <div className='input-element'>
              <Input
                type='text'
                name='firstname'
                value={firstname}
                onChange={onChange}
                loading={loading}
                fluid
              />
            </div>
          </Form.Field>

          <Form.Field className='text-field input-container half-width'>
            <div className='input-label'>
              {t('settings.senders.modalStep2.lastName')}
            </div>
            <div className='input-element'>
              <Input
                type='text'
                name='lastname'
                value={lastname}
                onChange={onChange}
                loading={loading}
                fluid
              />
            </div>
          </Form.Field>
        </div>

        <Form.Field className='signature-field input-container'>
          <div className='input-label'>
            {t('settings.senders.modalStep2.signature')}
          </div>
          <div className='input-element'>
            <div className='signature-content'>
              <ContentEditable
                name='signature'
                className='content-edition-field'
                html={signature}
                onChange={onChangeSignature}
              />
            </div>
          </div>
        </Form.Field>

        {currentEmailType === 'gmail' && (
          <div className='alias-field-container inputs-row'>
            <Form.Field className='alias-field-input input-container half-width'>
              <div className='input-label'>
                {t('settings.senders.modalStep2.alias')}
              </div>
              <div className='input-element'>
                <Input
                  type='text'
                  name='alias'
                  value={alias}
                  onChange={onChange}
                  autoFocus
                  loading={loading}
                  fluid
                />
              </div>
            </Form.Field>
            <Form.Field className='alias-field-preview input-container half-width'>
              <div className='input-label preview-label'>
                {t('settings.senders.modalStep2.aliasPreview')}
              </div>
              <div className='input-element'>
                <GmailAliasPreview alias={alias} />
              </div>
            </Form.Field>
          </div>
        )}
      </Content>
      <Actions>
        <div className='modal-actions'>
          <GenericButton onClick={toPrevStep} primacy='secondary' size='big'>
            {t('settings.senders.modalPrevious')}
          </GenericButton>
          <GenericButton
            type='submit'
            onClick={toNextStep}
            size='big'
            disabled={confirmationDisabled}
          >
            {t('settings.senders.modalNext')}
          </GenericButton>
        </div>
      </Actions>
    </>
  );
};

export default SenderModalStep2;
