import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import useClientId from '@/hooks/router/useClientId';
import Author from '@/graphql/fragments/Author';
import { ExternalAutomationService } from '@/types/externalAutomation';

const getClientExternalAutomationServices = gql`
  query getClientExternalAutomationServices($clientId: ID!) {
    client(id: $clientId) {
      id
      externalAutomationServices {
        id
        type
        title
        author {
          ...Author
        }
        automations {
          id
          title
          ... on PhantomBusterAutomation {
            type
            description
          }
        }
      }
    }
  }
  ${Author}
`;

export const CREATE_CLIENT_EXTERNAL_AUTOMATION_SERVICES = gql`
  mutation CreateClientAutomationService($input: CreateExternalAutomationServiceInput!) {
    createClientAutomationService(input: $input) {
      id
      externalAutomationServices {
        author {
          email
          firstname
          photoLink
          lastname
        }
        creationDate
        id
        title
        type
      }
    }
  }
`;

export const UPDATE_CLIENT_EXTERNAL_AUTOMATION_SERVICES = gql`
  mutation UpdateClientAutomationService($input: UpdateExternalAutomationServiceInput!) {
    updateClientAutomationService(input: $input) {
      id
      externalAutomationServices {
        author {
          email
          firstname
          lastname
          photoLink
        }
        creationDate
        id
        title
        type
      }
    }
  }
`;

export const DELETE_CLIENT_EXTERNAL_AUTOMATION_SERVICES = gql`
  mutation Mutation($input: DeleteExternalAutomationServiceInput!) {
    deleteClientAutomationService(input: $input) {
      id
      externalAutomationServices {
        author {
          firstname
          email
          lastname
          photoLink
        }
        creationDate
        id
        title
        type
      }
    }
  }
`;

type GetClientExternalAutomationServicesData = {
  client: {
    id: string;
    externalAutomationServices?: ExternalAutomationService[];
  };
};

type GetClientExternalAutomationServicesVariables = {
  clientId: string;
};

type UseClientExternalAutomationServicesInput = {
  queryOptions?: QueryHookOptions<
    GetClientExternalAutomationServicesData,
    GetClientExternalAutomationServicesVariables
  >;
};

interface UseClientExternalAutomationServicesResult
  extends QueryResult<
    GetClientExternalAutomationServicesData,
    GetClientExternalAutomationServicesVariables
  > {
  externalAutomationServices: ExternalAutomationService[];
}

function useClientExternalAutomationServices({
  queryOptions = {},
}: UseClientExternalAutomationServicesInput = {}): UseClientExternalAutomationServicesResult {
  const clientId = useClientId();
  const query = useQuery<
    GetClientExternalAutomationServicesData,
    GetClientExternalAutomationServicesVariables
  >(getClientExternalAutomationServices, {
    ...queryOptions,
    variables: { clientId },
  });

  const { externalAutomationServices = [] } = query.data?.client || {};

  return { ...query, externalAutomationServices };
}

export default useClientExternalAutomationServices;
