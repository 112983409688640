import React from 'react';
import { useTranslation } from 'react-i18next';
import Thumbnail from '../../../../../components/Thumbnail';
import { formatDate } from '../../../../../common/dates';
import TASK_ICONS from '../TaskIcons';
import AuthorAndDate from '../AuthorAndDate';

// TODO: use actual action?.jobOfferId for wording
const ClassicManualImportItem = ({ item, unbiasedModeEnabled, profile }) => {
  const { t } = useTranslation();
  const { author, date } = item || {};
  const { firstname, lastname, photoLink, email } = author || {};
  const { firstname: profileFirstname, lastname: profileLastname } =
    profile?.resumeData || {};

  const recruiterName = `${firstname || ''} ${lastname || ''}`.trim();
  let profileName = `${profileFirstname || ''} ${profileLastname || ''}`.trim();
  profileName = unbiasedModeEnabled ? '???' : profileName;

  return (
    <div
      className='timeline-card-mail-wrapper classic-item'
      style={{ bottom: 0 }}
    >
      <div className='content'>
        <div className='header'>
          <Thumbnail
            link={photoLink}
            firstname={firstname}
            lastname={lastname}
            email={email}
          />
          <div className='author'>
            <div className='date'>{date && formatDate(date, t)}</div>
            {t('profile.contact.timeline.manualImportItem.description', {
              recruiterName,
              profileName,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const RevealManualImportItem = ({ item, unbiasedModeEnabled, profile }) => {
  const { author } = item || {};
  const { firstname: profileFirstname, lastname: profileLastname } =
    profile?.resumeData || {};
  const { t } = useTranslation();

  const recruiterName = `${author.firstname || ''} ${author.lastname ||
    ''}`.trim();
  let profileName = `${profileFirstname || ''} ${profileLastname || ''}`.trim();
  profileName = unbiasedModeEnabled ? '???' : profileName;

  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{ backgroundColor: TASK_ICONS['new-contact'].color }}
              className='icon'
            >
              <i className={`${TASK_ICONS['new-contact'].icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  'profile.contact.timeline.manualImportItem.description',
                  { recruiterName, profileName },
                ),
              }}
            />
            <AuthorAndDate timelineItem={item}  />
          </div>
        </div>
      </div>
    </div>
  );
};

const ManualImportItem = ({
  item,
  unbiasedModeEnabled,
  profile,
  applicationMode,
}) => {
  if (applicationMode === 'classic') {
    return (
      <ClassicManualImportItem
        profile={profile}
        item={item}
        unbiasedModeEnabled={unbiasedModeEnabled}
      />
    );
  }
  return (
    <RevealManualImportItem
      profile={profile}
      item={item}
      unbiasedModeEnabled={unbiasedModeEnabled}
    />
  );
};

export default ManualImportItem;
