import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './EditComment.css';

import CommentEditor from './Editor';

type EditCommentProps = {
  initialValue: string;
  onSubmit: (value: string) => void;
};

const EditComment: React.FC<EditCommentProps> = ({
  initialValue,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState(initialValue);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const handleSubmit = () => {
    setDisableSubmit(true);
    onSubmit(comment);
  };

  const handleChange = (value: string) => {
    setComment(value);
    setDisableSubmit(false);
  };

  return (
    <CommentEditor
      defaultValue={initialValue}
      onChange={handleChange}
      placeholder={t('editor.placeholder.commentBody')}
      handleSubmit={handleSubmit}
      disableSubmit={disableSubmit}
    />
  );
};

export default EditComment;
