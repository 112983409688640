import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LegalEntity } from '@/types/client';
import RegisteredForm from '@/components/RegisteredForm/RegisteredForm';
import RegisteredInput from '@/components/RegisteredForm/RegisteredInput';
import GenericButton from '@/components/Common/GenericButton';
import styles from '../RevealCompliance.module.less';

interface ComplianceLegalEntityProps {
  entity: LegalEntity;
  onEntityChange: (data: LegalEntity) => void;
  entityName: string;
}

const ComplianceLegalEntity: React.FC<ComplianceLegalEntityProps> = ({
  entity,
  onEntityChange,
  entityName,
}) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);

  const handleChange = (data: LegalEntity) => {
    onEntityChange(data);
    setEditMode(false);
  };

  return (
    <div className={styles.privacySection}>
      <h2 className={styles.title}>
        {t(`settings.complianceSettings.${entityName}.title`)}
      </h2>
      <div className={styles.background}>
        <p className={styles.paragraph}>
          {t(`settings.complianceSettings.${entityName}.paragraph`)}
        </p>

        {!editMode && (
          <>
            <div className={styles.content}>
              <div>
                {t('settings.complianceSettings.legalEntity.name')}{' '}
                <span>{entity.fullname || ''}</span>
              </div>
              <div>
                {t('settings.complianceSettings.legalEntity.address')}{' '}
                <span>{entity.address || ''}</span>
              </div>
              <div>
                {t('settings.complianceSettings.legalEntity.phone')}{' '}
                <span>{entity.phoneNumber || ''}</span>
              </div>
              <div>
                {t('settings.complianceSettings.legalEntity.email')}{' '}
                <span>{entity.email || ''}</span>
              </div>
            </div>
            <div className={styles.actions}>
              <GenericButton
                primacy='secondary'
                onClick={() => setEditMode(!editMode)}
              >
                {t('settings.complianceSettings.editButton')}
              </GenericButton>
            </div>
          </>
        )}

        {editMode && (
          <RegisteredForm onSubmit={handleChange} defaultValues={entity}>
            <div className={styles.content}>
              <div>
                <span className={styles.field}>
                  {t('settings.complianceSettings.legalEntity.name')}
                </span>
                <RegisteredInput
                  name='fullname'
                  className={styles.input}
                  placeholder={t(
                    'settings.complianceSettings.legalEntity.name',
                  )}
                />
              </div>
              <div>
                <span className={styles.field}>
                  {t('settings.complianceSettings.legalEntity.address')}
                </span>
                <RegisteredInput
                  name='address'
                  className={styles.input}
                  placeholder={t(
                    'settings.complianceSettings.legalEntity.address',
                  )}
                />
              </div>
              <div>
                <span className={styles.field}>
                  {t('settings.complianceSettings.legalEntity.phone')}
                </span>
                <RegisteredInput
                  name='phoneNumber'
                  className={styles.input}
                  placeholder={t(
                    'settings.complianceSettings.legalEntity.phone',
                  )}
                />
              </div>
              <div>
                <span className={styles.field}>
                  {t('settings.complianceSettings.legalEntity.email')}
                </span>
                <RegisteredInput
                  name='email'
                  className={styles.input}
                  placeholder={t(
                    'settings.complianceSettings.legalEntity.email',
                  )}
                />
              </div>
            </div>
            <div className={styles.actions}>
              <GenericButton
                primacy='secondary'
                onClick={() => setEditMode(!editMode)}
              >
                {t('common.cancel')}
              </GenericButton>
              <GenericButton type='submit'>
                {t('settings.saveButton')}
              </GenericButton>
            </div>
          </RegisteredForm>
        )}
      </div>
    </div>
  );
};

export default ComplianceLegalEntity;
