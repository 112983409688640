import React from 'react';
import {
  Yellow,
  SecondarySkyBlue10,
  NegativeColor,
  PositiveColor,
  PrimaryCobalt60,
} from '@/less/colors';

const colorsFromColorString: Record<string, any> = {
  yellow: {
    backgroundColor: Yellow,
  },
  blue: {
    backgroundColor: SecondarySkyBlue10,
  },
  red: {
    backgroundColor: NegativeColor,
  },
  green: {
    backgroundColor: PositiveColor,
  },
  grey: {
    backgroundColor: PrimaryCobalt60,
  },
};

interface ColoredDotProps {
  color: string;
  size?: string;
  padding?: string;
  rounded?: boolean;
  hasBorder?: boolean;
  margin?: string;
  borderColor?: string;
  radius?: string;
  key?: string | number;
  className?: string;
}

const ColoredDot: React.FC<ColoredDotProps> = ({
  color,
  size = '12px',
  radius = '0px',
  padding = '0px',
  margin = '0px 8px',
  rounded = true,
  hasBorder = false,
  borderColor,
  key,
  className,
}) => {
  return (
    <div
      key={key}
      className={className}
      style={{
        width: size,
        height: size,
        display: 'inline-block',
        backgroundColor: colorsFromColorString[color].backgroundColor || color,
        border: hasBorder
          ? `1px solid ${borderColor ||
          colorsFromColorString[color].borderColor}`
          : 'none',
        padding,
        borderRadius: rounded ? '50%' : radius,
        margin,
      }}
    />
  );
};

export default ColoredDot;
