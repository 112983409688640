import React, { FC } from 'react';
import { Placeholder } from 'semantic-ui-react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ProfileColumnProps } from '@/components/ProfileRow/types';
import useClientId from '@/hooks/router/useClientId';
import useMiniCampaign from '@/graphql/hooks/campaigns/useMiniCampaign';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

import styles from './CampaignColumn.module.less';

interface CampaignColumnProps extends ProfileColumnProps {
  campaignId: string;
  day: string;
}

const CampaignColumn: FC<CampaignColumnProps> = ({
  profileLoading,
  campaignId,
  day,
  className,
  style,
}) => {
  const { t, i18n } = useTranslation();
  const clientId = useClientId();

  const { loading, miniCampaign } = useMiniCampaign({ campaignId });

  const event = t(`reveal.reports.marketing.detailsModal.state.nbEnrolled`, {
    date: new Date(day).toLocaleString(i18n.resolvedLanguage, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }),
  });

  const params = useMergedConfigurationParams();
  const shouldDisableDetailsRedirections =
    params.shouldDisableDetailsRedirections === 'true';

  const title = miniCampaign?.title || '???';

  return (
    <div className={classNames(styles.campaign, className)} style={style}>
      {loading || profileLoading ? (
        <Placeholder>
          <Placeholder.Line length='short' />
        </Placeholder>
      ) : (
        <>
          {shouldDisableDetailsRedirections ? (
            <span className={styles.name}>{title}</span>
          ) : (
            <Link
              className={classNames(styles.name, styles.clickable)}
              to={`/client/${clientId}/reveal/campaigns/${campaignId}`}
            >
              {title}
            </Link>
          )}
          <div className={styles.event}>{event}</div>
        </>
      )}
    </div>
  );
};

export default CampaignColumn;
