import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'semantic-ui-react';

import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useAtsFilterOptions from '@/graphql/hooks/clients/useClientAtsFiltersOptions';
import GenericModal from '@/components/Common/GenericModal';
import { UPLOAD_TO_ATS_JOB } from '../../../../graphql/searchPoolProfile';

import styles from './AddToATSJobModal.module.less';

// import './AddToGreenhouseJobModal.css';

const AddToBoondmanagerModal = ({
  clientId,
  searchPoolId,
  profileId,
  mainMissionId,
  onClose,
}) => {
  const { t } = useTranslation();
  const notifications = useNotificationSystem();
  const [stageId, setStageId] = useState();
  const [submitting, setSubmitting] = useState();

  const { data: atsFilterOptionsData } = useAtsFilterOptions(clientId, {
    fetchPolicy: 'network-only',
  });
  const filtersOptions =
    ((atsFilterOptionsData?.client?.revealProjects || {})[0]?.connectors ||
      {})[0]?.filterOptions || {};
  // const job = _.findWhere(jobOptions, { id: jobId });
  const stageOptions = _.map(filtersOptions?.stageNames, ({ id, name }) => ({
    text: name,
    value: id,
  }));

  const [uploadToATSJob, { loading: uploading }] = useMutation(
    UPLOAD_TO_ATS_JOB,
  );

  useEffect(() => {
    if (!stageId && !_.isEmpty(stageOptions)) {
      setStageId(stageOptions[0]?.value);
    }
  }, [stageId, stageOptions]);

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      const variables = {
        searchPoolId,
        input: {
          sourceJobId: mainMissionId || '',
          sourceStageId: stageId,
          profileId,
        },
      };

      await uploadToATSJob({ variables });

      notifications.success(t('reveal.addToATS.success'));

      onClose();
    } catch (e) {
      notifications.error(t('reveal.addToATS.error'));
    }
    setSubmitting(false);
  };

  return (
    <GenericModal
      className={styles.atsModal}
      open
      onClose={onClose}
      size='tiny'
    >
      <Modal.Header>
        {t('reveal.addToATS.addContact', { ats: 'BoondManager' })}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Dropdown
            selection
            search
            label='Job stage'
            options={stageOptions}
            value={stageId}
            onChange={(e, { value }) => setStageId(value)}
          />
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <div className='align-left'>
          <Button content='Cancel' onClick={onClose} className='dismiss' />
        </div>

        <Button
          primary
          content='Add'
          size='big'
          disabled={!stageId || submitting}
          loading={uploading}
          type='button'
          onClick={onSubmit}
        />
      </Modal.Actions>
    </GenericModal>
  );
};

export default AddToBoondmanagerModal;
