import React, { useMemo, useState } from 'react';
import _, { compose } from 'underscore';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  ACTION_FIELDS_BY_TYPE,
  isEmailAction,
} from '@/common/constants/taskTypes';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import useUpdateContactFlowAction from '@/graphql/hooks/searchPoolProfile/useUpdateContactFlowAction';
import createActionInputFromAction from '@/common/contactFlow/createActionInputFromAction';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import LoadingComponent from '@/components/LoadingComponent';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import { BULK_UPDATE_CURRENT_SEQUENCE_ACTION } from '@/graphql/searchPoolProfile';
import { getTranslatedText } from '@/common';
import { sanitizeTypename } from '@/common/utils/apollo';
import { debouncePromise } from '@/common/utils/async';
import withDeleteContactFlowAction from '../../../../../../hocs/searchPoolProfile/withDeleteContactFlowAction';
import { canDeleteAction, isTaskForAction } from '../../helpers';
import ActionItem from './ActionItem';
import ActionItemEditor from './ActionItemEditor';
import ActionWithTaskItem, { filterCcAndBcc } from './ActionWithTaskItem';
import EmailActionItem from './EmailActionItem';
import { getTaskTextToCopy } from './utils';
import ActionInlineEdition from './ActionInlineEdition';

const RevealPendingAction = ({
  action,
  sequenceId,
  clientId,
  searchPoolId,
  actions,
  deleteContactFlowAction,
  task,
  index,
  isLast,
  displayAdvanceTask,
  onResize,
  nextEmailActionIds,
  setHeightToAddToContainer,
  onSnoozeAction,
  onDeleteAction,
}) => {
  const { profile, currentTask, loadingProfile, goToNextTask } =
    useCandidateViewContext();
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();
  const [updateContactFlowAction] = useUpdateContactFlowAction();
  const notification = useNotificationSystem();
  const { t } = useTranslation();
  const { maximize } = useMinimizedView();
  const [bulkUpdateCurrentSequenceAction] = useMutation(
    BULK_UPDATE_CURRENT_SEQUENCE_ACTION,
  );

  const [editMode, setEditMode] = useState(false);
  const [isFolded, setIsFolded] = useState(true);
  const [isInlineEdition, setIsInlineEdition] = useState(false);
  const defaultSubject = profile?.contactData?.defaultSubject;
  const [messageState, setMessageState] = useState({
    body: '',
    subject: '',
    cc: [],
    bcc: [],
    snippets: [],
  });

  React.useEffect(() => {
    if (!action) {
      return;
    }
    setMessageState({
      body:
        action?.message?.body ||
        getTranslatedText(action.description) ||
        getTaskTextToCopy(task) ||
        '',
      subject: action?.message?.subject || defaultSubject || '',
      snippets: action?.snippets || [],
    });
  }, [action, task, defaultSubject]);

  const actionId = action?.actionId;
  const canDeleteContactFlowAction = useMemo(
    () =>
      canDeleteAction({
        actions,
        actionId,
      }),
    [actions, actionId],
  );

  const onUpdateActionMemoized = useMemo(
    () =>
      debouncePromise(async ({ actionId, updatedAction }) => {
        const actionInput = createActionInputFromAction({
          action: _.omit(
            updatedAction,
            'indexInSequence',
            'emailIndex',
            'snoozeConfiguration',
          ),
        });

        const input = {
          id: profile.id,
          sequenceId,
          actionId,
          actionInput,
          ...(!_.isEmpty(updatedAction.message?.snippets) && {
            snippets: MergeTagsService.createSnippetsInput({
              subject: updatedAction.message?.subject || '',
              body: updatedAction.message?.body || '',
              snippets: updatedAction.message.snippets || [],
            }),
          }),
        };

        const execResult = await updateContactFlowAction({
          variables: {
            searchPoolId: 'reveal',
            input: sanitizeTypename(input),
          },
        });
        publishSubscriptionEvent('onTaskUpdated', {
          profileId: profile.id,
          sequenceId,
          taskId: task?.id,
        });
        return execResult;
      }, 400),
    [
      profile,
      publishSubscriptionEvent,
      sequenceId,
      task,
      updateContactFlowAction,
    ],
  );

  if (!action) {
    return null;
  }

  const { message, completion } = action;
  if (completion?.isCompleted) {
    return null;
  }

  const taskId = isTaskForAction({ task, action }) ? task.id : null;

  const wouldBeFirstEmail =
    _.findIndex(actions, (act) => isEmailAction(act)) >= index;
  const isFirstEmail = isEmailAction(action) && wouldBeFirstEmail;

  const showSubject = !isEmailAction(action) || isFirstEmail;

  const onDeleteContactFlowAction = async () => {
    if (!profile.id) {
      return;
    }
    const input = {
      id: profile.id,
      sequenceId,
      actionId: action?.actionId,
      ...(task && { task: _.pick(task, 'id', 'projectId', 'queueId') }),
    };

    // TODO: should be onActionUpdated ?
    publishSubscriptionEvent('onTaskUpdated', {
      profileId: profile.id,
      sequenceId,
      taskId, // TODO: check this is correct and useful ?
    });

    await deleteContactFlowAction({
      searchPoolId: 'reveal',
      input,
    });

    if (onDeleteAction && taskId) {
      onDeleteAction(taskId);
    }
  };

  const onRemoveAction = () => {
    if (!canDeleteContactFlowAction) {
      notification.error(
        t('reveal.candidatesView.timeline.unableToDeleteBecauseFollowup'),
      );
      return;
    }
    onDeleteContactFlowAction();
  };

  const onEditAction = () => {
    maximize();
    setEditMode(true);
    if (onResize) {
      onResize();
    }
  };

  const onChangeToNow = async () => {
    try {
      const newAction = {
        ...action,
        trigger: { type: 'manual-trigger' },
      };
      const contactFlowActionInput = createActionInputFromAction({
        action: omitNullFields(
          _.omit(newAction, 'nbManualSnoozes', 'snoozeConfiguration'),
        ),
      });
      const input = sanitizeTypename({
        id: profile.id,
        sequenceId,
        actionId: action.actionId,
        actionInput: contactFlowActionInput,
        ...(!_.isEmpty(action.snippets) && {
          snippets: MergeTagsService.createSnippetsInput({
            subject: action.message?.subject || '',
            body: action.message?.body || '',
            snippets: action.snippets,
          }),
        }),
      });
      await updateContactFlowAction({
        variables: { searchPoolId: 'reveal', input: sanitizeTypename(input) },
      });
      notification.success(t('reveal.candidatesView.timeline.editSuccess'));
    } catch (e) {
      console.error(e);
      notification.error(t('reveal.candidatesView.timeline.editError'));
    }
  };

  const onSnooze = async ({
    snoozeDate,
    snoozeReasonId,
    snoozeReasonTitle,
    snoozeDuration,
    snoozeComment,
  }) => {
    try {
      const newAction = {
        ...action,
        trigger: {
          type: 'after-date',
          date: snoozeDate.toISOString(),
        },
      };

      const contactFlowActionInput = createActionInputFromAction({
        action: _.omit(newAction, 'nbManualSnoozes'),
      });
      const input = {
        id: profile.id,
        sequenceId,
        actionId: action.actionId,
        actionInput: contactFlowActionInput,
        isSnoozeAction: true,
        snoozeDuration,
        ...(!_.isEmpty(snoozeReasonId) && {
          snoozeReasonId,
          snoozeReasonTitle,
        }),
        ...(snoozeComment && { snoozeComment }),
        ...(!_.isEmpty(action.snippets) && {
          snippets: MergeTagsService.createSnippetsInput({
            subject: action.message?.subject || '',
            body: action.message?.body || '',
            snippets: action.snippets,
          }),
        }),
      };
      await updateContactFlowAction({
        variables: { searchPoolId: 'reveal', input: sanitizeTypename(input) },
      });
      notification.success(t('reveal.candidatesView.timeline.editSuccess'));

      if (onSnoozeAction && taskId) {
        onSnoozeAction(taskId);
      }

      if (currentTask?.id && currentTask?.id === task?.id) {
        setTimeout(() => goToNextTask(), 500);
      }
    } catch (e) {
      console.error(e);
      notification.error(t('reveal.candidatesView.timeline.editError'));
    }
  };

  const handleQuitInlineEdition = () => {
    setIsInlineEdition(false);
    setIsFolded(true);
  };

  if (loadingProfile) {
    return <LoadingComponent as='div' loading length={50} margin={0.6} />;
  }

  const onBulkApplyCcBccToActions = ({ actionIds, cc, bcc }) => {
    if (!profile.id) {
      return;
    }
    const updatedActions = _.map(actionIds, (nextEmailActionId) => ({
      actionId: nextEmailActionId,
      updateFields: {
        cc: filterCcAndBcc(cc),
        bcc: filterCcAndBcc(bcc),
      },
    }));

    bulkUpdateCurrentSequenceAction({
      variables: {
        searchPoolId: 'reveal',
        profileId: profile.id,
        input: updatedActions,
      },
    });
  };

  if (editMode) {
    return (
      <ActionItemEditor
        profile={profile}
        isLast={isLast}
        action={action}
        onClose={() => setEditMode(false)}
        canChangeType={canDeleteContactFlowAction}
        index={index}
        actions={actions}
        sequenceId={sequenceId}
        profileId={profile.id}
        clientId={clientId}
        nextEmailActionIds={nextEmailActionIds}
        onBulkApplyCcBccToActions={onBulkApplyCcBccToActions}
      />
    );
  }

  if (taskId && action.trigger?.type !== 'delay-after-action') {
    return (
      <ActionWithTaskItem
        task={task}
        action={action}
        actions={actions}
        profile={profile}
        index={index}
        isFirstEmail={showSubject}
        isLast={isLast}
        clientId={clientId}
        searchPoolId={searchPoolId}
        sequenceId={sequenceId}
        onUpdate={onUpdateActionMemoized}
        onEdit={onEditAction}
        onRemove={onRemoveAction}
        onSnooze={onSnooze}
        nextEmailActionIds={nextEmailActionIds}
        onBulkApplyCcBccToActions={onBulkApplyCcBccToActions}
        isInlineEdition={isInlineEdition}
        setIsInlineEdition={setIsInlineEdition}
        messageState={messageState}
        setMessageState={setMessageState}
      />
    );
  }

  return (
    <ActionItem
      profile={profile}
      action={action}
      isLast={isLast}
      clientId={clientId}
      onEdit={onEditAction}
      onRemove={onRemoveAction}
      onChangeToNow={onChangeToNow}
      displayAdvanceTask={displayAdvanceTask}
      isEditable
      isInlineEdition={isInlineEdition}
      setIsInlineEdition={setIsInlineEdition}
      isFolded={isFolded}
      setIsFolded={setIsFolded}
      setHeightToAddToContainer={setHeightToAddToContainer}
    >
      {isInlineEdition ? (
        <ActionInlineEdition
          action={action}
          messageState={messageState}
          setMessageState={setMessageState}
          isFirstEmail={isFirstEmail}
          clientId={clientId}
          onUpdate={onUpdateActionMemoized}
          onQuitInlineEdition={handleQuitInlineEdition}
        />
      ) : (
        ACTION_FIELDS_BY_TYPE[action.type]?.message && (
          <EmailActionItem
            message={message}
            showSubject={showSubject}
            instantiatedSnippets={action.snippets}
          />
        )
      )}
    </ActionItem>
  );
};

const omitNullFields = (dict) => {
  return _.object(
    _.compact(
      _.map(dict, (value, key) =>
        value !== undefined && value !== null ? [key, value] : null,
      ),
    ),
  );
};

export default compose(withDeleteContactFlowAction)(RevealPendingAction);
