import React from 'react';
import _, { compose } from 'underscore';
import { Checkbox, Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

import slackOAuth2Provider from '../../../context/oauth2/slack';

import withClientSlackIntegration from '../../../hocs/clients/withClientSlackIntegration';
import withAddClientSlackIntegration from '../../../hocs/clients/withAddClientSlackIntegration';
import withEditClientSlackIntegrationSettings from '../../../hocs/clients/withEditClientSlackIntegrationSettings';
import withDeleteClientSlackIntegration from '../../../hocs/clients/withDeleteClientSlackIntegration';

import SettingsLayout from '../../SettingsLayout';

import './SlackIntegrationSettings.css';

const SlackIntegration = ({
  addClientSlackIntegration,
  editClientSlackIntegrationSettings,
  deleteClientSlackIntegration,
  loadingSlackIntegration,
  slackIntegration,
  clientId,
  t,
}) => {
  return (
    <SettingsLayout
      currentPage='slackIntegration'
      clientId={clientId}
      className='settings-container'
    >
      <div className='settings slack-integration-page'>
        <div className='settings-header'>
          <h1>{t('slackIntegration.header')}</h1>
        </div>
        <SlackIntegrationSettings
          addClientSlackIntegration={addClientSlackIntegration}
          editClientSlackIntegrationSettings={
            editClientSlackIntegrationSettings
          }
          deleteClientSlackIntegration={deleteClientSlackIntegration}
          loadingSlackIntegration={loadingSlackIntegration}
          slackIntegration={slackIntegration}
          t={t}
        />
      </div>
    </SettingsLayout>
  );
};

const SlackIntegrationSettings = ({
  addClientSlackIntegration,
  editClientSlackIntegrationSettings,
  deleteClientSlackIntegration,
  loadingSlackIntegration,
  slackIntegration,
  t,
}) => {
  if (loadingSlackIntegration) {
    return null;
  }

  const useOauth2Token = async ({ token }) => {
    try {
      await addClientSlackIntegration({ token });
    } catch (e) {
      console.error(e);
    }
  };

  const onClickAddSlack = slackOAuth2Provider.authorizeWithOAuth({
    onAuthorizedCallback: useOauth2Token,
  });

  const integrationId = slackIntegration?.id;

  const onDeleteSlackIntegration = () =>
    deleteClientSlackIntegration({ integrationId });
  return (
    <>
      <h2>{t('settings.configuration')}</h2>
      <div className='section visible'>
        <div className='input-container'>
          <div className='input-label'>
            {t('slackIntegration.authorization.header')}
          </div>
          {!slackIntegration && (
            <>
              <div className='input-description'>
                {t('slackIntegration.authorization.activateDescription')}
              </div>
              <Button onClick={onClickAddSlack} className='slack-button'>
                <img
                  className='provider-logo'
                  src='/images/logos/slack.svg'
                  alt='connect Slack'
                />
                <span className='button-text'>
                  {t('slackIntegration.authorization.activate')}
                  <span className='strong'>Slack</span>
                </span>
              </Button>
            </>
          )}

          {slackIntegration && (
            <>
              <div className='input-description'>
                <div>
                  {t('slackIntegration.authorization.connectionDescription1')}
                  <span className='strong'>{slackIntegration?.team?.name}</span>
                  {t('slackIntegration.authorization.connectionDescription2')}
                </div>
                <div>
                  {t('slackIntegration.authorization.notificationsDescription')}{' '}
                  <span className='strong'>
                    {slackIntegration?.channel?.name}
                  </span>
                  .
                </div>
              </div>
              <Button
                className='primary-cta negative'
                onClick={onDeleteSlackIntegration}
              >
                {t('slackIntegration.authorization.disconnect')}
              </Button>
            </>
          )}
        </div>
      </div>
      {slackIntegration && (
        <SlackIntegrationNotificationsSettings
          integrationId={integrationId}
          notificationsSettings={slackIntegration?.notificationsSettings}
          editClientSlackIntegrationSettings={
            editClientSlackIntegrationSettings
          }
          t={t}
        />
      )}
    </>
  );
};

const SlackIntegrationNotificationsSettings = ({
  integrationId,
  notificationsSettings,
  editClientSlackIntegrationSettings,
  t,
}) => {
  const {
    // watchCollectNotification,
    newCandidatesNotification,
    positiveAnswersNotification,
    statisticsNotification,
  } = notificationsSettings || {};

  const onChangeCheckbox = async (ev, { name, checked }) => {
    try {
      await editClientSlackIntegrationSettings({
        integrationId,
        notificationsSettings: {
          ..._.mapObject(
            _.omit(notificationsSettings, '__typename'),
            (setting) => _.omit(setting, '__typename'),
          ),
          [name]: {
            active: checked,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  // TODO: needs refacto
  // we substituted daily notif with discover notif in the backend
  return (
    <>
      <h2>{t('slackIntegration.notificationsSettings.header')}</h2>
      <div className='section visible'>
        {/* <div className='input-container'> */}
        {/* <div className='input-label'>{t('slackIntegration.notificationsSettings.watchCollectNotification')}</div> */}
        {/* <div className='input-element'> */}
        {/* <Checkbox */}
        {/* name='watchCollectNotification' */}
        {/* onChange={onChangeCheckbox} */}
        {/* checked={watchCollectNotification?.active} */}
        {/* label={{ */}
        {/* children: ( */}
        {/* <span>{t('slackIntegration.notificationsSettings.watchCollectNotificationDescription')}</span> */}
        {/* ), */}
        {/* }} */}
        {/* /> */}
        {/* </div> */}
        {/* </div> */}
        <div className='input-container'>
          <div className='input-label'>
            {t(
              'slackIntegration.notificationsSettings.newCandidatesNotification',
            )}
          </div>
          <div className='input-element'>
            <Checkbox
              name='newCandidatesNotification'
              onChange={onChangeCheckbox}
              checked={newCandidatesNotification?.active}
              label={{
                children: (
                  <span>
                    {t(
                      'slackIntegration.notificationsSettings.newCandidatesNotificationDescription',
                    )}
                  </span>
                ),
              }}
            />
          </div>
        </div>
        <div className='input-container'>
          <div className='input-label'>
            {t(
              'slackIntegration.notificationsSettings.positiveAnswersNotification',
            )}
          </div>
          <div className='input-element'>
            <Checkbox
              name='positiveAnswersNotification'
              onChange={onChangeCheckbox}
              checked={positiveAnswersNotification?.active}
              label={{
                children: (
                  <span>
                    {t(
                      'slackIntegration.notificationsSettings.positiveAnswersNotificationDescription',
                    )}
                  </span>
                ),
              }}
            />
          </div>
        </div>
        <div className='input-container'>
          <div className='input-label'>
            {t('slackIntegration.notificationsSettings.statisticsNotification')}
          </div>
          <div className='input-element'>
            <Checkbox
              name='statisticsNotification'
              onChange={onChangeCheckbox}
              checked={statisticsNotification?.active}
              label={{
                children: (
                  <span>
                    {t(
                      'slackIntegration.notificationsSettings.statisticsNotificationDescription',
                    )}
                  </span>
                ),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default compose(
  withClientSlackIntegration,
  withAddClientSlackIntegration,
  withEditClientSlackIntegrationSettings,
  withDeleteClientSlackIntegration,
  withTranslation('translations'),
)(SlackIntegration);
