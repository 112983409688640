import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'semantic-ui-react';
import _ from 'underscore';

import { Task } from '@/types/task';
import TaskIcon from '@/revealComponents/TaskIcon';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import './RevealPendingActions.css';
import Alert from '@/components/Common/Alert/Alert';
import { getFullname } from '@/common/helpers/person';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import {
  isEmailAction,
  LINKEDIN_MESSAGE_SUBTYPES,
  TASK_TYPES,
} from '@/common/constants/taskTypes';
import Warning from '@/components/Common/Warning/Warning';
import GenericButton from '@/components/Common/GenericButton';
import { shouldDisplayReapplySequence } from '@/revealComponents/Modals/ReapplySequenceModal/utils';
import {
  ContactFlowSequence,
  CurrentSequenceInfo,
} from '@/types/searchPoolProfile';
import ReapplySequenceModal from '@/revealComponents/Modals/ReapplySequenceModal';
import { Author } from '@/types/author';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import SequenceDynamicVariables from '../SequenceDynamicVariables';
import RevealPendingAction from './RevealPendingAction';

const isLinkedinSalesNavigatorProfileUrl = ({
  url,
}: {
  url: string;
}): boolean => {
  if (!url) {
    return false;
  }
  // matches https://www.linkedin.com/sales/people/...
  const regex = /^http[s]*:\/\/[www.]*linkedin\.com\/sales\/people\/.*$/;

  // matches https://www.linkedin.com/sales/lead/...
  const regex2 = /^http[s]*:\/\/[www.]*linkedin\.com\/sales\/lead\/.*$/;
  return !!url.match(regex) || !!url.match(regex2);
};

interface RevealCandidateViewPendingActionsProps {
  clientId: string;
  searchPoolId: string;
  profileId: string;
  task: any;
  currentSequenceInfo?: CurrentSequenceInfo;
  skipFirst: boolean;
  pluginCurrentUrl?: string;
  onSnoozeAction?: (id: string) => void;
  onDeleteAction?: (id: string) => void;
  shouldHideFinalSeparator?: boolean;
}

const RevealCandidateViewPendingActions: React.FC<
  RevealCandidateViewPendingActionsProps
> = ({
  clientId,
  searchPoolId,
  profileId,
  task,
  currentSequenceInfo,
  skipFirst,
  pluginCurrentUrl,
  onSnoozeAction,
  onDeleteAction,
  shouldHideFinalSeparator,
}) => {
  const { t } = useTranslation();
  const [displayReapplySequenceModal, setDisplayReapplySequenceModal] =
    useState(false);
  const { currentSequence } = useCandidateViewContext();
  const isPlugin = useIsPlugin();
  const { isMinimized } = useMinimizedView();
  const sequenceOwner = currentSequenceInfo?.author;
  const displayedActionsLimit = +(useMergedConfigurationParams()
    .displayedActionsLimit as string);

  let pendingActions = _.filter(
    currentSequence?.actions,
    (action) => !action?.completion?.isCompleted,
  );

  const onlyFuture = !_.isEmpty(pendingActions?.[0]?.plannedExecutionDate);

  if (skipFirst) {
    pendingActions = pendingActions.slice(1);
  }

  if (_.isEmpty(pendingActions)) {
    return null;
  }

  const nSkippedActions =
    (currentSequence?.actions || []).length - (pendingActions || []).length;

  const nPendingActions = pendingActions?.length || 0;
  const maxNDisplayedActions = onlyFuture
    ? displayedActionsLimit
    : 1 + displayedActionsLimit;
  const nDisplayedActions = displayedActionsLimit
    ? Math.min(nPendingActions, maxNDisplayedActions)
    : nPendingActions;

  return (
    <>
      <SequenceDynamicVariables clientId={clientId} />
      {!isMinimized && (
        <h4 className='next-activities-title'>
          {t('profile.contact.timeline.toCome')}
          <span className='ui label'>{nDisplayedActions}</span>
        </h4>
      )}
      {!isMinimized &&
        currentSequenceInfo &&
        shouldDisplayReapplySequence({ currentSequenceInfo }) && (
          <Alert
            title={t('common.warning')}
            description={t('contactFlow.reapplySequence.sequenceWasModified')}
            level='info'
          >
            <GenericButton
              primacy='secondary'
              size='small'
              onClick={() => setDisplayReapplySequenceModal(true)}
            >
              {t('contactFlow.reapplySequence.reapplySequence')}
            </GenericButton>
          </Alert>
        )}
      {displayReapplySequenceModal && (
        <ReapplySequenceModal
          candidates={[
            {
              id: profileId,
              profileId,
              sequenceId: currentSequenceInfo?.sequenceId,
            },
          ]}
          onCancel={() => setDisplayReapplySequenceModal(false)}
          clientId={clientId}
        />
      )}
      {!onlyFuture ? (
        <PendingActionsWithCurrentTask
          pendingActions={pendingActions}
          nSkippedActions={nSkippedActions}
          currentSequence={currentSequence}
          clientId={clientId}
          task={task}
          sequenceOwner={sequenceOwner}
          searchPoolId={searchPoolId}
          isPlugin={isPlugin}
          pluginCurrentUrl={pluginCurrentUrl}
          onSnoozeAction={onSnoozeAction}
          onDeleteAction={onDeleteAction}
        />
      ) : (
        <PendingActionsFuture
          pendingActions={pendingActions}
          nSkippedActions={nSkippedActions}
          currentSequence={currentSequence}
          clientId={clientId}
          task={task}
          sequenceOwner={sequenceOwner}
          searchPoolId={searchPoolId}
        />
      )}
      {!isMinimized && !shouldHideFinalSeparator && (
        <Divider className='action-and-timeline-divider' />
      )}
    </>
  );
};

export const EmptyPendingActions: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className='reveal-pending-actions'>
      <h3 className='section-info'>
        {t('reveal.candidatesView.timeline.noUpcomingActions')}
      </h3>
    </div>
  );
};

interface PendingActionsWithCurrentTaskProps {
  pendingActions: any;
  nSkippedActions: number;
  currentSequence: ContactFlowSequence;
  clientId: string;
  task: Task;
  sequenceOwner?: Author;
  searchPoolId: string;
  isPlugin: boolean;
  pluginCurrentUrl?: string;
  onSnoozeAction?: (id: string) => void;
  onDeleteAction?: (id: string) => void;
}

const PendingActionsWithCurrentTask: React.FC<
  PendingActionsWithCurrentTaskProps
> = ({
  pendingActions,
  nSkippedActions,
  currentSequence,
  clientId,
  task,
  sequenceOwner,
  searchPoolId,
  isPlugin,
  pluginCurrentUrl,
  onSnoozeAction,
  onDeleteAction,
}) => {
  const { t } = useTranslation();
  const { isMinimized } = useMinimizedView();
  const [moreActionsExpanded, setMoreActionsExpanded] = useState(false);
  const [heightToAddToContainer, setHeightToAddToContainer] = useState(0);
  const [maxHeightExpanded, setMaxHeightExpanded] = useState('70px');
  const { profile } = useCandidateViewContext();
  const { displayedActionsLimit } = useMergedConfigurationParams();

  const ref = useRef<HTMLDivElement | null>(null);

  const { user } = useCurrentUser();
  const isOtherSequenceOwner =
    user?.email && sequenceOwner?.email && user.email !== sequenceOwner.email;

  useEffect(() => {
    if (!ref?.current || !moreActionsExpanded) {
      return;
    }
    const { height } = ref.current.getBoundingClientRect();
    setMaxHeightExpanded(`${(height + heightToAddToContainer || 0) + 120}px`);
  }, [heightToAddToContainer, moreActionsExpanded]);

  const handleExpandClick = () => {
    if (!ref?.current) {
      return;
    }
    if (maxHeightExpanded === '70px') {
      const { height } = ref.current.getBoundingClientRect();
      setMaxHeightExpanded(`${(height || 0) + 120}px`);
    } else {
      setMaxHeightExpanded('70px');
    }
    setMoreActionsExpanded((previousValue) => !previousValue);
  };

  const onResize = () => {
    setMaxHeightExpanded(`min-content`);
  };

  const shouldDisplayInMailRecruiterWarning = useMemo(() => {
    const action = pendingActions?.[0];
    if (!action || !profile?.resumeData?.sourceData) {
      return false;
    }
    if (
      action.type !== TASK_TYPES.LINKEDIN_SEND_MESSAGE ||
      action.subtype !== LINKEDIN_MESSAGE_SUBTYPES.RECRUITER
    ) {
      return false;
    }
    const { linkedinCommon, linkedinRecruiter } = profile.resumeData.sourceData;
    return !linkedinCommon?.id && !linkedinRecruiter?.id;
  }, [profile, pendingActions]);

  const shouldDisplayInMailSalesNavigatorWarning = useMemo(() => {
    const action = pendingActions?.[0];
    if (
      !action ||
      !profile?.resumeData?.sourceData ||
      isLinkedinSalesNavigatorProfileUrl({ url: pluginCurrentUrl || '' })
    ) {
      return false;
    }
    if (
      action.type !== TASK_TYPES.LINKEDIN_SEND_MESSAGE ||
      action.subtype !== LINKEDIN_MESSAGE_SUBTYPES.SALESNAVIGATOR
    ) {
      return false;
    }
    const { linkedinSalesNavigator, linkedinCommon } =
      profile.resumeData.sourceData;
    return !linkedinSalesNavigator?.id && !linkedinCommon?.id;
  }, [profile, pendingActions, pluginCurrentUrl]);

  const displayedPendingActions = displayedActionsLimit
    ? (pendingActions || []).slice(1, 1 + +(displayedActionsLimit as string))
    : (pendingActions || []).slice(1);

  return (
    <>
      {isOtherSequenceOwner && (
        <Alert
          title={t('common.warning')}
          description={t('reveal.profileModal.warnings.otherSequenceOwner', {
            fullname: getFullname(sequenceOwner),
          })}
        />
      )}

      {isPlugin && shouldDisplayInMailRecruiterWarning && (
        <Warning
          title={t('common.warning')}
          description={t(
            'reveal.profileModal.warnings.unknowLinkedinRecruiterUrl',
          )}
        />
      )}

      {isPlugin && shouldDisplayInMailSalesNavigatorWarning && (
        <Warning
          title={t('common.warning')}
          description={t(
            'reveal.profileModal.warnings.unknowLinkedinSalesNavigatorUrl',
          )}
        />
      )}

      <div
        className={`reveal-pending-actions ${isMinimized ? 'minimized' : ''}`}
      >
        <RevealPendingAction
          key={pendingActions?.[0]?.actionId}
          index={nSkippedActions + 0}
          action={pendingActions?.[0]}
          sequenceId={currentSequence?.id}
          searchPoolId={searchPoolId}
          clientId={clientId}
          actions={currentSequence?.actions || []}
          task={task}
          displayAdvanceTask={
            pendingActions?.[0]?.trigger?.type !== 'manual-trigger' &&
            pendingActions?.[0]?.type !== 'send-email-action'
          }
          nextEmailActionIds={_.pluck(
            _.filter((pendingActions || []).slice(1), isEmailAction),
            'actionId',
          )}
          onSnoozeAction={onSnoozeAction}
          onDeleteAction={onDeleteAction}
        />
        {pendingActions?.length > 1 && !isMinimized && (
          <div className='more-pending-actions-container'>
            <div
              style={{ maxHeight: maxHeightExpanded || '70px' }}
              className={`more-pending-actions${
                moreActionsExpanded ? ' expanded' : ''
              }`}
            >
              <div className='preview'>
                <div className='preview-content'>
                  <span className='divider' />
                  <div className='icons-container'>
                    {_.map(
                      [...displayedPendingActions].reverse(),
                      (action, index) => {
                        return (
                          <TaskIcon
                            action={action}
                            key={`${index}-${action.actionId}`}
                          />
                        );
                      },
                    )}
                  </div>
                  <p>
                    {!isPlugin
                      ? t('profile.contact.timeline.upcomingTasks', {
                          count: displayedPendingActions.length || 0,
                        })
                      : `+${displayedPendingActions.length}`}
                  </p>
                </div>
                <div className='preview-maximize' onClick={handleExpandClick}>
                  <p>
                    {moreActionsExpanded
                      ? t('profile.contact.timeline.retract')
                      : t('profile.contact.timeline.expand')}
                  </p>
                  <i className='ri-arrow-drop-down-line ri-xl expand-icon' />
                </div>
              </div>
              <Divider />
              <div ref={ref} className='all-actions'>
                {_.map(displayedPendingActions, (action, index) => {
                  return (
                    <>
                      <RevealPendingAction
                        key={action.actionId || index}
                        index={nSkippedActions + (index + 1)}
                        action={action}
                        sequenceId={currentSequence?.id}
                        searchPoolId={searchPoolId}
                        clientId={clientId}
                        actions={currentSequence?.actions || []}
                        task={task}
                        displayAdvanceTask={
                          (!(
                            action?.snoozeConfiguration ||
                            task?.snoozeConfiguration
                          ) ||
                            (
                              action?.snoozeConfiguration ||
                              task?.snoozeConfiguration
                            )?.isAdvanceable) &&
                          pendingActions?.[0]?.completion &&
                          index === 0 &&
                          action.actionId === pendingActions[0].actionId &&
                          action.trigger?.type !== 'manual-trigger' &&
                          action.type !== 'send-email-action'
                        }
                        onResize={onResize}
                        sequenceOwner={sequenceOwner}
                        nextEmailActionIds={_.pluck(
                          _.filter(
                            displayedPendingActions.slice(index + 1),
                            isEmailAction,
                          ),
                          'actionId',
                        )}
                        setHeightToAddToContainer={setHeightToAddToContainer}
                      />
                      {index < pendingActions.slice(1).length - 1 && (
                        <hr className='upcoming-actions-separator' />
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

interface PendingActionsFutureProps {
  pendingActions: any;
  nSkippedActions: number;
  currentSequence: ContactFlowSequence;
  clientId: string;
  task: Task;
  sequenceOwner?: Author;
  searchPoolId: string;
}

const PendingActionsFuture: React.FC<PendingActionsFutureProps> = ({
  pendingActions,
  nSkippedActions,
  currentSequence,
  clientId,
  task,
  sequenceOwner,
  searchPoolId,
}) => {
  const { t } = useTranslation();
  const { isMinimized } = useMinimizedView();
  const { displayedActionsLimit } = useMergedConfigurationParams();
  const displayedPendingActions = displayedActionsLimit
    ? (pendingActions || []).slice(0, +(displayedActionsLimit as string))
    : pendingActions || [];
  return (
    <div className={`reveal-pending-actions ${isMinimized ? 'minimized' : ''}`}>
      {!_.isEmpty(displayedPendingActions) && (
        <div className='more-pending-actions-container'>
          <div
            className={`more-pending-actions only-future ${
              isMinimized ? 'minimized' : ''
            }`}
          >
            <div className={`preview ${isMinimized ? 'minimized' : ''}`}>
              <div
                className={`preview-content ${isMinimized ? 'minimized' : ''}`}
              >
                <div className='icons-container'>
                  {_.map(
                    [...displayedPendingActions].reverse(),
                    (action, index) => {
                      return <TaskIcon key={index} action={action} />;
                    },
                  )}
                </div>
                <p>
                  {t('profile.contact.timeline.upcomingTasksOnlyFuture', {
                    count: displayedPendingActions.length,
                  })}
                </p>
              </div>
            </div>
            {!isMinimized && (
              <>
                <Divider />
                <div id='all-actions-container' className='all-actions'>
                  {_.map(displayedPendingActions, (action, index) => {
                    return (
                      <RevealPendingAction
                        key={action.actionId || index}
                        index={nSkippedActions + index}
                        action={action}
                        sequenceId={currentSequence?.id}
                        searchPoolId={searchPoolId}
                        clientId={clientId}
                        actions={currentSequence?.actions || []}
                        task={task}
                        displayAdvanceTask={
                          (!(
                            action?.snoozeConfiguration ||
                            task?.snoozeConfiguration
                          ) ||
                            (
                              action?.snoozeConfiguration ||
                              task?.snoozeConfiguration
                            )?.isAdvanceable) &&
                          index === 0 &&
                          action.trigger?.type !== 'manual-trigger' &&
                          action.type !== 'send-email-action'
                        }
                        sequenceOwner={sequenceOwner}
                        nextEmailActionIds={_.pluck(
                          _.filter(
                            displayedPendingActions.slice(index + 1),
                            isEmailAction,
                          ),
                          'actionId',
                        )}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RevealCandidateViewPendingActions;
