import React, { FC, useState, useReducer } from 'react';
import { useRouteMatch } from 'react-router-dom';

import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import useClientId from '@/hooks/router/useClientId';
import { useClientCurrentAtsId } from '@/graphql/hooks/clients/useClientRevealProjects';

import CompletedTasks from './components/CompletedTasks';
import { RevealAnalyticsHeader } from './components/RevealAnalyticsHeader';
import SequencesTab from './tabs/SequencesTab';
import RevealAnalyticsMissions from './RevealAnalyticsMissions';
import DiversityTab from './tabs/DiversityTab';
import MarketingTab from './tabs/MarketingTab';
import StatisticsParametersContext, {
  statisticsParametersReducer,
  defaultStatisticsParameters,
} from './StatisticsParametersContext';
import AdventureDashboard2 from './tabs/AdventureDashboard2';
import AdventureDashboard1 from './tabs/AdventureDashboard1';

import './index.css';

const RevealAnalytics: FC = () => {
  const clientId = useClientId();
  const { data } = useClientPermissions(clientId);
  const { diversityAnalytics, crmMarketing } = data?.client.permissions || {};
  const [selectedSequence, setSelectedSequence] = useState<string[]>([]);
  const [userEmails, setUserEmails] = useState<string[]>([]);
  const [statisticsParameters, dispatch] = useReducer(
    statisticsParametersReducer,
    defaultStatisticsParameters,
  );

  const { timeSpan, missionIds } = statisticsParameters;

  const { params } = useRouteMatch<{ tab: string }>();

  const atsId = useClientCurrentAtsId();

  return (
    <div className='reveal-analytics'>
      <StatisticsParametersContext.Provider
        value={[statisticsParameters, dispatch]}
      >
        <RevealAnalyticsHeader
          clientId={clientId}
          selectedSequence={selectedSequence}
          setSelectedSequence={setSelectedSequence}
          userEmails={userEmails}
          onChangeUsers={(users) => {
            setUserEmails(users);
          }}
        />
        <div className='reveal-analytics-content'>
          {params.tab === 'missions' && (
            <RevealAnalyticsMissions
              timeSpan={timeSpan}
              missionIds={missionIds}
            />
          )}
          {params.tab === 'sequences' && (
            <SequencesTab
              clientId={clientId}
              timeSpan={timeSpan}
              selectedSequenceIds={selectedSequence}
            />
          )}
          {params.tab === 'tasks' && (
            <CompletedTasks
              clientId={clientId}
              timeSpan={timeSpan}
              selectedSequenceIds={selectedSequence}
              userEmails={userEmails}
            />
          )}
          {params.tab === 'diversity' && diversityAnalytics && (
            <DiversityTab
              clientId={clientId}
              missionIds={missionIds}
              timeSpan={timeSpan}
            />
          )}
          {params.tab === 'marketing' && crmMarketing && (
            <MarketingTab timeSpan={timeSpan} />
          )}
          {params.tab === 'adventure1' && atsId === 'adventure' && (
            <AdventureDashboard1 />
          )}
          {params.tab === 'adventure2' && atsId === 'adventure' && (
            <AdventureDashboard2 />
          )}
        </div>
      </StatisticsParametersContext.Provider>
    </div>
  );
};

export default RevealAnalytics;
