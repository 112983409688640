import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import withClientAnalyticsOffers from '../../../../../hocs/clients/withClientAnalyticsOffers';
import ActivityDataContainer from './ActivityDataContainer';
import { ActivityGraphStub } from './ActivityGraph';

import './Activity.css';

const Activity = ({ clientId, offers, t, fullStatisticsLoading }) => {
  const filteredOffer = _.where(offers, { isUnclassified: false });
  return (
    <div className='section analytics-activity'>
      <div className='header'>
        <h3>{t('analytics.headers.activity')}</h3>
      </div>

      <br />
      <div className='activity-body-container'>
        <div className='analytics-activity-container'>
          {fullStatisticsLoading ? (
            <ActivityGraphStub />
          ) : (
            <ActivityDataContainer clientId={clientId} offers={filteredOffer} />
          )}
        </div>
      </div>
    </div>
  );
};

export default compose(
  withTranslation('translations'),
  withClientAnalyticsOffers,
)(Activity);
