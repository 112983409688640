import React from 'react';
import { useTranslation } from 'react-i18next';
import _, { compose } from 'underscore';

import './Notes.css';

import withAddProfileComment from '@/hocs/profiles/withAddProfileComment';
import withUpdateProfileComment from '@/hocs/profiles/withUpdateProfileComment';

import Comment from './Comment';
import CommentDraft from './Comment/CommentDraft';

type NoteProps = {
  profile: any;
  addProfileComment: (value: any) => Promise<any>;
  updateProfileComment: (value: any) => Promise<any>;
};

const getSortedComments = (timeline: any) => {
  const timelineComments = _.filter(timeline, (item) => {
    return item?.action?.type === 'comment';
  });
  return _.sortBy(timelineComments, '-date');
};

const Notes: React.FC<NoteProps> = ({
  profile,
  addProfileComment,
  updateProfileComment,
}) => {
  const { t } = useTranslation();

  const commentHandlers = {
    add: async (comment: string) => {
      const result = await addProfileComment({ id: profile.id, comment });
      const { status, data } = result || {};
      if (status !== 200 || _.isEmpty(data)) {
        console.error(' - - - - - commentAddHandler FAILED - - - - - ');
      }
      // setTimeline(data?.addProfileComment?.contactData?.timeline);
    },
    update: async ({ id, date, comment }: any) => {
      const result = await updateProfileComment({ id, date, comment });
      const { status, data } = result || {};
      if (status !== 200 || _.isEmpty(data)) {
        console.error(' - - - - - commentUpdateHandler FAILED - - - - - ');
      }
    },
    delete: async ({ date }: any) => {
      await updateProfileComment({
        id: profile.id,
        date,
        // this has the effect of removing the comment from the db
        comment: '',
      });
    },
  };

  return (
    <div className='hsNotes'>
      <h2>{t('profile.contact.timeline.comments.header')}</h2>
      <div className='hsNotes-container'>
        <CommentDraft
          profileId={profile?.id ?? 0}
          onDraftSave={commentHandlers.add}
        />
        {_.map(
          getSortedComments(profile?.contactData?.timeline ?? []),
          (comment) => (
            <Comment
              key={`comment-${comment.date}`}
              profile={profile}
              timelineItem={comment}
              commentHandlers={commentHandlers}
            />
          ),
        )}
      </div>
    </div>
  );
};

export default compose(withUpdateProfileComment, withAddProfileComment)(Notes);
