import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const mutation = gql`
  mutation followJob($id: ID!) {
    followJob(id: $id) {
      id
      owner {
        email
      }
      hiringManager {
        email
      }
      followers {
        email
      }
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    followJob: ({ id }) => mutate({ variables: { id } }),
  }),
});
