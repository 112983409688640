import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Segment, Image, Icon } from 'semantic-ui-react';
import classNames from 'classnames';

import getJobWishes from '@/common/candidates';
import ProfileFavoriteButton from '@/containers/Profile/Resume/Card/ProfileFavoriteButton';
import { MarketplaceUnbiasedModeField } from '@/components/UnbiasedMode/UnbiasedModeField';
import { BlurLine } from '@/components/UnbiasedMode/BlurLine';
import Interest from '@/components/Common/Icons/Interest';
import { CANDIDATE_INTEREST_ITEM_TYPE } from '@/common/reveal/SourceData.type';
import ProfilePowerHourIcon from '@/containers/Profile/Resume/Card/ProfilePowerHourIcon';
import ProfilePowerHourFlag from '@/containers/Profile/Resume/PowerHourState';
import withClient from '@/hocs/clients/withClient';
import { getCurrentClientProfile } from '@/common/helpers/profile';

import logAction from '../../../common/logAction';
import SaveProfileInfoToClipboard from '../../../containers/hotkeys/SaveProfileInfoToClipboard';
import withUserFromJWToken from '../../../hocs/users/withUserFromJWToken';
import withClientBaseOffers from '../../../hocs/offers/withClientBaseOffers';

import { getSecureLink, getRandomDefaultAvatarLink } from '../../../common';
import Source from '../../../components/Sources';
import ProfileCardContent from '../../../components/Profile/ProfileCardContent';
import WatchNewTag from '../../WatchCollectViewV2/WatchCollectRecommendations/JobProfileRecommendations/WatchNewTag';
import ProfileJobState from '../components/ProfileJobState';
// import ProfileActions from '../components/ProfileActions';
import SearchPoolProfileActions from '../components/SearchPoolProfileActions';
import { getProfilePermaLink } from './EnrichedProfiles.hooks';
import SOURCES from '../../../common/constants/sources';
import ProfileViews from '../../../components/Profile/ProfileViews';

import './MiniCard.css';
import '../WatchProfileHeader.css';

class MiniCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      linkCopiedToClipboard: false,
    };
  }

  renderSources = () => {
    const { t, profile } = this.props;
    const { sources } = profile?.resumeData || {};
    return _.map(SOURCES, (sourceId) => {
      return sources && sources[sourceId] ? (
        <Source
          key={sourceId}
          source={{ sourceId, url: sources[sourceId] }}
          onLogAction={logAction}
          type='profile'
          from='watch-collect'
          searchPoolProfileId={profile?.id}
          t={t}
        />
      ) : null;
    });
  };

  onSelectProfile = () => {
    const { profile, onSelectProfileId } = this.props;
    const { id: profileId } = profile || {};
    onSelectProfileId(profileId);
  };

  onShareProfile = async (e) => {
    e.stopPropagation();
    const { profile } = this.props;
    const { searchPoolId, searchPoolProfileId } =
      profile?.searchPoolState || {};
    if (searchPoolId && searchPoolProfileId) {
      const permalink = getProfilePermaLink(searchPoolId, searchPoolProfileId);
      await navigator.clipboard.writeText(permalink);
      this.setState({ linkCopiedToClipboard: true });
    }
  };

  render() {
    const {
      searches,
      client,
      clientId,
      profile,
      user,
      onMarkHidden,
      onMarkUnhidden,
      setProfilesInJobIds,
      onClick,
      t,
    } = this.props;
    const { linkCopiedToClipboard } = this.state;
    const { resumeData, searchPoolState, creationTimestamp } = profile || {};
    const { seen, clientProfiles, searchPoolId, searchPoolProfileId } =
      searchPoolState || {};
    const { photoLink, sourceData, firstname, lastname, headline } =
      resumeData || {};
    const hiresweetData = sourceData?.hiresweet;
    const jobWishes = getJobWishes({ hiresweetData });

    const clientInterestStates = _.findWhere(
      hiresweetData?.clientInterestStates || [],
      { clientId },
    );
    const hasCompanyGlobalInterestItem = _.findWhere(
      clientInterestStates?.interestItems || [],
      {
        type: CANDIDATE_INTEREST_ITEM_TYPE.COMPANY_GLOBAL,
      },
    );

    const avatarImageUrl =
      photoLink || getRandomDefaultAvatarLink(firstname + lastname);

    const clientProfile = getCurrentClientProfile(clientProfiles);
    const jobTitle = clientProfile?.jobTitle;
    const jobOfferId = clientProfile?.jobOfferId;
    const clientProfileId = clientProfile?.profileId;
    const isUnclassified = clientProfile?.isUnclassified ?? true;

    let hiddenUntilStr;
    if (user?.isAdmin && profile?.minVisibilityTimestamp > Date.now()) {
      const hiddenUntilMoment = moment(profile?.minVisibilityTimestamp, 'x');
      hiddenUntilStr = hiddenUntilMoment.isValid()
        ? hiddenUntilMoment.format('LL')
        : '';
    }

    const isAdmin = user?.isAdmin;

    return (
      <>
        <Segment className='mini-card resume-data-card' onClick={onClick}>
          <div className='watch-profile-header'>
            <div className={classNames('avatar-container', seen && 'seen')}>
              <MarketplaceUnbiasedModeField
                name='hidePicture'
                fallback={
                  <Image
                    src={getRandomDefaultAvatarLink(firstname + lastname)}
                    circular
                  />
                }
              >
                <Image
                  src={getSecureLink(avatarImageUrl)}
                  circular
                  onError={(e) => {
                    e.target.src = getRandomDefaultAvatarLink(
                      firstname + lastname,
                    );
                  }}
                />
              </MarketplaceUnbiasedModeField>
              <div className='header-annotations'>
                <ProfileFavoriteButton profile={profile} />
              </div>
              {hasCompanyGlobalInterestItem && (
                <div className='header-show-interest'>
                  <Interest />
                </div>
              )}
              <ProfilePowerHourFlag profile={profile}>
                <div className='miniCardPowerHoursIcon'>
                  <ProfilePowerHourIcon />
                </div>
              </ProfilePowerHourFlag>
            </div>

            <div className={classNames('profile-data-extract', seen && 'seen')}>
              <div className='first-line'>
                <span className='fullname'>
                  {client?.hasLimitedAccessToProfiles ? (
                    <BlurLine style={{ display: 'inline-block', width: 100 }} />
                  ) : (
                    <MarketplaceUnbiasedModeField
                      name='hideFullName'
                      fallback={<BlurLine style={{ minWidth: '100px' }} />}
                    >
                      {firstname} {lastname}
                    </MarketplaceUnbiasedModeField>
                  )}
                  <ProfileViews
                    profile={profile?.searchPoolState?.profiles?.[0]}
                    openable={false}
                  />
                  {isAdmin && searchPoolId && searchPoolProfileId && (
                    <i
                      className={`ri-external-link-line${
                        linkCopiedToClipboard ? ' copied' : ''
                      }`}
                      onClick={this.onShareProfile}
                    />
                  )}
                  <SaveProfileInfoToClipboard
                    profile={profile}
                    offerId={jobOfferId}
                    asIcon
                  />
                </span>
                <span className='sources-container'>
                  {this.renderSources()}
                </span>
                <WatchNewTag creationTimestamp={creationTimestamp} />
              </div>
              <div>
                {!_.isEmpty(jobWishes) && (
                  <span className='headline'>
                    <Icon name='search' />
                    {jobWishes}
                  </span>
                )}
                {_.isEmpty(jobWishes) && headline?.content?.text && (
                  <span className='headline'>{headline?.content?.text}</span>
                )}
              </div>
            </div>

            <div className='profile-state'>
              <SearchPoolProfileActions
                searchPoolState={searchPoolState}
                clientId={clientId}
                clientProfileId={clientProfileId} // can be undefined
                clientProfile={clientProfile} // can be undefined
                resumeData={profile?.resumeData}
                user={user}
                isUnclassified={isUnclassified}
                onMarkHidden={onMarkHidden}
                onMarkUnhidden={onMarkUnhidden}
                searches={searches}
                jobTitle={jobTitle}
                setProfilesInJobIds={setProfilesInJobIds}
                t={t}
              />

              <div className='profile-state-container'>
                {hiddenUntilStr && (
                  <div className='profile-hidden-state'>
                    <span className='hidden-state-part'>
                      Hidden until&nbsp;
                    </span>
                    <span className='date-part'>{hiddenUntilStr}</span>
                    &nbsp;
                  </div>
                )}
                <ProfileJobState profile={clientProfile} t={t} />
              </div>
            </div>
          </div>
          <ProfileCardContent profile={profile} canApplyUnbiasedMode />
        </Segment>
      </>
    );
  }
}

export default compose(
  withUserFromJWToken,
  withClientBaseOffers,
  withTranslation('translations'),
  withClient,
)(MiniCard);
