/* eslint-disable class-methods-use-this */
import _ from 'underscore';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import {
  DurationSinceGraduationVariable,
  getMergeTagsCalculatedDuration,
} from '@/common/mergeTags/utils';
import { sweetDateToMomentDate } from '@/common/dates';

export default class ExpressionDurationSinceGraduationEvaluator
  implements SweetEvaluatorTypes.EvaluatorInterface {
  evaluate({
    context,
    expression,
  }: {
    context: SweetEvaluatorTypes.Context;
    expression: SweetEvaluatorTypes.Variable;
  }): { value: string | undefined } {
    const variable = (expression as unknown) as SweetEvaluatorTypes.BaseVariable &
      DurationSinceGraduationVariable;

    const anyContext = context as any;
    const profileEducations =
      anyContext.education || anyContext.data?.education || null;

    if (_.isEmpty(profileEducations)) {
      return { value: undefined };
    }

    const mainEducation =
      _.findWhere(profileEducations, { isMain: true }) ||
      profileEducations?.[0] ||
      null;

    if (_.isEmpty(mainEducation) || _.isEmpty(mainEducation?.endDate)) {
      return { value: undefined };
    }

    const startDate = sweetDateToMomentDate(mainEducation?.endDate);

    if (!startDate) {
      return { value: undefined };
    }

    const { format, locale } = variable;

    return {
      value:
        getMergeTagsCalculatedDuration({
          startDate: startDate.toISOString(),
          endDate: new Date().toISOString(),
          format,
          locale,
        })?.calculatedValue || undefined,
    };
  }
}
