import React, { FC } from 'react';
import _ from 'underscore';

import {
  PrefixTaskType,
  PrefixStatisticsNode,
} from '@/types/statistics/clientStatistics';
import PathRow from './PathRow';
import { getStatsByPath } from '../helpers';
import { PrefixFilter } from '../../helpers/filters';

interface PathStatisticsProps {
  stats: PrefixStatisticsNode;
  totalContacted: number;
  fetchDetails?: (taskType: PrefixTaskType, prefix: PrefixFilter) => void;
  isLinear: boolean;
}

const PathStatistics: FC<PathStatisticsProps> = ({
  stats,
  totalContacted,
  fetchDetails,
  isLinear,
}) => {
  const statsByPath = getStatsByPath(stats);

  return (
    <div>
      {_.map(statsByPath, (s, path) => (
        <PathRow
          key={path}
          path={path}
          stats={s}
          totalContacted={totalContacted}
          fetchDetails={
            fetchDetails
              ? (taskType) => fetchDetails(taskType, { type: 'topology', path })
              : undefined
          }
          isLinear={isLinear}
        />
      ))}
    </div>
  );
};

export default PathStatistics;
