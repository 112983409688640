import React, { useContext, useState } from 'react';
import _, { compose } from 'underscore';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import GenericModal from '@/components/Common/GenericModal';
import withClientUsers from '../../../../../hocs/clients/withClientUsers';
import withAddSender from '../../../../../hocs/senders/withAddSender';
import EmailContext from '../../../../../context/EmailContext';
import SenderModalStep1 from './SenderModalStep1';
import SenderModalStep2 from './SenderModalStep2';
import SenderModalStep3 from './SenderModalStep3';

import './NewSenderModal.css';

export const STEPS = {
  ADD_ACCOUNT: 'add-account',
  CONFIGURE_SETTINGS: 'configure-settings',
  CONFIGURE_USERS: 'configure-users',
};

export const EMPTY_SENDER_DETAILS = {
  alias: '',
  signature: '',
  firstname: '',
  lastname: '',
};

const NewSenderModal = ({
  ownerEmail,
  closeModal,
  users,
  open,
  addSender,
  clientId,
  t,
}) => {
  const [step, setStep] = useState(STEPS.ADD_ACCOUNT);
  const [senderDetails, setSenderDetails] = useState(EMPTY_SENDER_DETAILS);
  const [grantedRecruiters, setGrantedRecruiters] = useState([]);

  const activeUsers = _.reject(users, { status: 'pending' });

  const { currentEmailType, getIdToken } = useContext(EmailContext);

  const submitSender = async () => {
    const { signature, alias, firstname, lastname } = senderDetails;
    const idToken = getIdToken();
    const usersInput = _.map(grantedRecruiters, (recruiterEmail) => ({
      email: recruiterEmail,
    }));
    await addSender({
      clientId,
      signature,
      alias,
      firstname,
      lastname,
      users: usersInput,
      mailAccount: {
        idToken,
        type: currentEmailType,
      },
    });
    closeModal();
  };

  let content = null;
  switch (step) {
    case STEPS.ADD_ACCOUNT: {
      content = (
        <SenderModalStep1
          toNextStep={() => setStep(STEPS.CONFIGURE_SETTINGS)}
          closeModal={closeModal}
          t={t}
        />
      );
      break;
    }
    case STEPS.CONFIGURE_SETTINGS: {
      content = (
        <SenderModalStep2
          senderDetails={senderDetails}
          setSenderDetails={setSenderDetails}
          toPrevStep={() => setStep(STEPS.ADD_ACCOUNT)}
          toNextStep={() => setStep(STEPS.CONFIGURE_USERS)}
          t={t}
        />
      );
      break;
    }
    case STEPS.CONFIGURE_USERS: {
      content = (
        <SenderModalStep3
          ownerEmail={ownerEmail}
          users={activeUsers}
          senderDetails={senderDetails}
          grantedRecruiters={grantedRecruiters}
          setGrantedRecruiters={setGrantedRecruiters}
          toPrevStep={() => setStep(STEPS.CONFIGURE_SETTINGS)}
          toNextStep={submitSender}
          t={t}
        />
      );
      break;
    }
    default: {
      return null;
    }
  }
  return (
    <GenericModal
      open={open}
      onClose={closeModal}
      className={classNames('new-sender-modal', step)}
      size='small'
    >
      {content}
    </GenericModal>
  );
};

export default compose(
  withTranslation('translations'),
  withClientUsers,
  withAddSender,
)(NewSenderModal);
