import React, { FC, useState } from 'react';

import ProjectSearchParamsContext from './ProjectSearchParamsContext';

const ProjectSearchParamsContextProvider: FC = ({ children }) => {
  const state = useState({
    includeSkipped: false,
  });

  return (
    <ProjectSearchParamsContext.Provider value={state}>
      {children}
    </ProjectSearchParamsContext.Provider>
  );
};

export default ProjectSearchParamsContextProvider;
