import React from 'react';
import _ from 'underscore';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import GenericCheckbox from '@/components/Common/GenericCheckbox';
import { getJobOptions } from '../../../optionsHelpers';

import './ProjectFilters.css';

enum ProjectFilterType {
  CURRENTLY_IN = 'currently-in',
  CURRENTLY_NOT_IN = 'currently-not-in',
  CURRENTLY_NOT_IN_ANY = 'currently-not-in-any',
  HAS_BEEN_IN = 'has-been-in',
  HAS_NEVER_BEEN_IN_ANY = 'has-never-been-in-any',
}

const filterTypesWithProjectsSelection = [
  ProjectFilterType.CURRENTLY_IN,
  ProjectFilterType.CURRENTLY_NOT_IN,
  ProjectFilterType.HAS_BEEN_IN,
];

const filterTypesWithoutProjectsSelection = [
  ProjectFilterType.CURRENTLY_NOT_IN_ANY,
  ProjectFilterType.HAS_NEVER_BEEN_IN_ANY,
];

interface ProjectFilter {
  filterType: ProjectFilterType;
  projectIds?: string[];
}

type ProjectOption = {
  value?: string;
  text?: string;
};

interface ProjectFiltersProps {
  projectFilters: Array<ProjectFilter>;
  updateProjectFilters: any;
}
const ProjectFilters: React.FC<ProjectFiltersProps> = ({
  projectFilters,
  updateProjectFilters,
}) => {
  const { jobOptions: jobs } = useSearchPoolJobOptions('reveal');
  const jobOptions = getJobOptions({ jobs });
  return (
    <div className='project-filters'>
      <ProjectFiltersPopupContent
        projectFilters={projectFilters}
        updateProjectFilters={updateProjectFilters}
        projectOptions={jobOptions}
      />
    </div>
  );
};

interface ProjectFiltersPopupContentProps {
  projectFilters: Array<ProjectFilter>;
  updateProjectFilters: any;
  projectOptions: Array<ProjectOption>;
}
const ProjectFiltersPopupContent: React.FC<ProjectFiltersPopupContentProps> = ({
  projectFilters,
  updateProjectFilters,
  projectOptions,
}) => {
  const { t } = useTranslation();
  const currentlyInFilter = _.findWhere(projectFilters, {
    filterType: ProjectFilterType.CURRENTLY_IN,
  });
  const currentlyNotInFilter = _.findWhere(projectFilters, {
    filterType: ProjectFilterType.CURRENTLY_NOT_IN,
  });
  const hasBeenInFilter = _.findWhere(projectFilters, {
    filterType: ProjectFilterType.HAS_BEEN_IN,
  });
  const currentlyNotInAnyChecked = !!_.findWhere(projectFilters, {
    filterType: ProjectFilterType.CURRENTLY_NOT_IN_ANY,
  });
  const hasNeverBeenInChecked = !!_.findWhere(projectFilters, {
    filterType: ProjectFilterType.HAS_NEVER_BEEN_IN_ANY,
  });

  const onChangeCurrentlyNotInAny = () => {
    updateProjectFilters(
      updateCheckedFiltersHandler({
        checked: !currentlyNotInAnyChecked,
        selectedFilterType: ProjectFilterType.CURRENTLY_NOT_IN_ANY,
      }),
    );
  };
  const onChangeHasNeverBeenInAny = () => {
    updateProjectFilters(
      updateCheckedFiltersHandler({
        checked: !hasNeverBeenInChecked,
        selectedFilterType: ProjectFilterType.HAS_NEVER_BEEN_IN_ANY,
      }),
    );
  };

  const onChangeCurrentlyInProjects = (
    e: React.SyntheticEvent,
    { value }: { value?: any },
  ) => {
    updateProjectFilters(
      updateSelectedProjectHandler({
        value,
        selectedFilterType: ProjectFilterType.CURRENTLY_IN,
      }),
    );
  };
  const onChangeCurrentlyNotInProjects = (
    e: React.SyntheticEvent,
    { value }: { value?: any },
  ) => {
    updateProjectFilters(
      updateSelectedProjectHandler({
        value,
        selectedFilterType: ProjectFilterType.CURRENTLY_NOT_IN,
      }),
    );
  };
  const onChangeHasBeenInProjects = (
    e: React.SyntheticEvent,
    { value }: { value?: any },
  ) => {
    updateProjectFilters(
      updateSelectedProjectHandler({
        value,
        selectedFilterType: ProjectFilterType.HAS_BEEN_IN,
      }),
    );
  };

  return (
    <div>
      <div className='input-container'>
        <div className='input-label'>
          {t('reveal.searchView.filters.mission.currentFollowing')}
        </div>

        <div className='input-element'>
          <Dropdown
            multiple
            selection
            fluid
            search
            options={projectOptions}
            value={currentlyInFilter?.projectIds || []}
            onChange={onChangeCurrentlyInProjects}
            placeholder={t('reveal.searchView.filters.mission.choose')}
          />
        </div>
      </div>
      <div className='input-container'>
        <div className='input-label'>
          {t('reveal.searchView.filters.mission.currentNotFollowing')}
        </div>
        <Dropdown
          multiple
          selection
          fluid
          labeled
          search
          options={projectOptions}
          value={currentlyNotInFilter?.projectIds || []}
          onChange={onChangeCurrentlyNotInProjects}
          placeholder={t('reveal.searchView.filters.mission.choose')}
        />
      </div>
      <div className='input-container'>
        <div className='input-label'>
          {t('reveal.searchView.filters.mission.hasBeen')}
        </div>
        <Dropdown
          multiple
          selection
          fluid
          search
          options={projectOptions}
          value={hasBeenInFilter?.projectIds || []}
          onChange={onChangeHasBeenInProjects}
          placeholder={t('reveal.searchView.filters.mission.choose')}
        />
      </div>
      <button
        type='button'
        className='project-filters-checkbox'
        onClick={onChangeCurrentlyNotInAny}
      >
        <GenericCheckbox checked={currentlyNotInAnyChecked} />
        <div className='project-filters-label'>
          {t('reveal.searchView.filters.mission.currentAny')}
        </div>
      </button>
      <button
        type='button'
        className='project-filters-checkbox'
        onClick={onChangeHasNeverBeenInAny}
      >
        <GenericCheckbox checked={hasNeverBeenInChecked} />
        <div className='project-filters-label'>
          {t('reveal.searchView.filters.mission.neverBeen')}
        </div>
      </button>
    </div>
  );
};

const updateCheckedFiltersHandler = ({
  checked,
  selectedFilterType,
}: {
  checked?: boolean;
  selectedFilterType: ProjectFilterType;
}) => (previousFilters: Array<ProjectFilter>) => {
  if (checked) {
    const filterAlreadySelected = _.findWhere(previousFilters, {
      filterType: selectedFilterType,
    });
    if (filterAlreadySelected) {
      return getStillRelevantPreviousFilters({
        previousFilters,
        selectedFilterType,
      });
    }
    return [
      ...getStillRelevantPreviousFilters({
        previousFilters,
        selectedFilterType,
      }),
      {
        filterType: selectedFilterType,
        ...(selectedFilterType !== ProjectFilterType.CURRENTLY_NOT_IN_ANY &&
          selectedFilterType !== ProjectFilterType.HAS_NEVER_BEEN_IN_ANY && {
            projectIds: [],
          }),
      },
    ];
  }
  return _.filter(
    previousFilters,
    ({ filterType }) => filterType !== selectedFilterType,
  );
};

const getStillRelevantPreviousFilters = ({
  previousFilters,
  selectedFilterType,
}: {
  previousFilters: Array<ProjectFilter>;
  selectedFilterType: ProjectFilterType;
}) => {
  if (
    selectedFilterType === ProjectFilterType.CURRENTLY_NOT_IN_ANY ||
    selectedFilterType === ProjectFilterType.HAS_NEVER_BEEN_IN_ANY
  ) {
    return [];
  }

  if (_.contains(filterTypesWithProjectsSelection, selectedFilterType)) {
    return _.filter(
      previousFilters,
      ({ filterType }) =>
        !_.contains(filterTypesWithoutProjectsSelection, filterType),
    );
  }

  return previousFilters;
};

const updateSelectedProjectHandler = ({
  value,
  selectedFilterType,
}: {
  value: string[];
  selectedFilterType: ProjectFilterType;
}) => (previousFilters: Array<ProjectFilter>) => {
  return _.reject(
    [
      ..._.reject(
        previousFilters,
        (filter) => filter.filterType === selectedFilterType,
      ),
      {
        filterType: selectedFilterType,
        projectIds: value,
      },
    ],
    ({ projectIds }) => _.isEmpty(projectIds),
  );
};

export default ProjectFilters;
