import { graphql } from '@apollo/client/react/hoc';
import { GET_CLIENT_TEMPLATES } from '@/graphql/clientTemplates';
import { UPDATE_DEFAULT_TEMPLATE_REPLY } from '@/graphql/defaultReplyTemplate';

export default graphql(UPDATE_DEFAULT_TEMPLATE_REPLY, {
  props: ({ ownProps, mutate }) => ({
    updateTemplateRecruiterConfiguration: ({ templateId, isDefaultReply }) =>
      mutate({
        variables: {
          id: templateId,
          isDefaultReply,
        },
        refetchQueries: [
          {
            query: GET_CLIENT_TEMPLATES,
            variables: { clientId: ownProps.clientId },
          },
        ],
      }),
  }),
});
