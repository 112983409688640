import qs from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Dropdown, Segment } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';

import { getFullname } from '@/common/helpers/person';
import useUserTableViewPreferences from '@/graphql/hooks/users/useUserDisplayPreferences';
import { getDiffInBusinessDays } from '@/common/utils/days';
import { useClientCurrentAtsId } from '@/graphql/hooks/clients/useClientRevealProjects';
import { useUserAllowedDepartments } from '@/graphql/hooks/users/useUserAllowedDepartments';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import useClientUsers from '@/graphql/hooks/clients/useClientUsers';
import EmptyState from '@/revealComponents/EmptyState/EmptyState';
import Counter from '@/components/Common/Counter/Counter';
import useAtsFilterOptions from '@/graphql/hooks/clients/useClientAtsFiltersOptions';
import { getTranslatedText } from '@/common/helpers/translatableText';
import { getShortLanguage } from '@/common/utils/i18n';
import { useClientMissionCustomFields } from '@/graphql/hooks/clients/useClientMissionCustomFields';

import { getValueFromCustomField } from '@/components/CustomFields/InlineCustomField';
import { useGetClientTasks } from '../../../graphql/tasks';
import routerParamsToProps from '../../../hocs/routerParamsToProps';
import useSearchPoolJobOptions from '../../../graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import FilteredTasks from './FilteredTasks';
import { CLIENT_SEQUENCES } from '../../../containers/Parameters/Sequences/queries';
import { getUserOptions } from '../optionsHelpers';

import './TasksView.css';
import { useTaskViewFilterDisplayPreferences } from './hooks';

const TASK_SUBTYPES_BY_ATS = {
  adventure: [
    {
      id: ['start-1', 'start-2'],
      translationKey: 'taskType.start-1',
    },
    {
      id: ['followup'],
      translationKey: 'taskType.followup',
    },
    {
      id: ['end-1', 'end-2'],
      translationKey: 'taskType.end-1',
    },
    {
      id: ['payment-point'],
      translationKey: 'taskType.payment-point',
    },
    {
      id: ['intermission-followup'],
      translationKey: 'taskType.intermission-followup',
    },
  ],
};

const COLUMN_SORT_MAP = {
  name: (tasks, order, _params) => {
    const sortedTasks = _.sortBy(tasks, (task) =>
      getFullname(task?.target?.resumeData).toLowerCase(),
    );
    return order === 'ascending' ? sortedTasks : sortedTasks.reverse();
  },
  customField: (tasks, order, params) => {
    // this has not been tested with every custom field type

    const sortedTasks = _.sortBy(tasks, (task) => {
      const missionCustomField = _.findWhere(
        task.target?.resumeData?.customFields || [],
        {
          clientCustomFieldId: params.customFieldId,
        },
      );
      const value = getValueFromCustomField({
        customField: missionCustomField,
      });
      if (typeof value === 'number') {
        return value || 0;
      }
      return value === '-' ? '' : value || '';
    });
    return order === 'ascending' ? sortedTasks : sortedTasks.reverse();
  },
  subtype: (tasks, order, params) => {
    const sortedTasks = _.sortBy(tasks, (task) =>
      params?.t(`taskType.${task?.subtype}`),
    );
    return order === 'ascending' ? sortedTasks : sortedTasks.reverse();
  },
};

const TasksView = ({ clientId, userEmail }) => {
  const { t, i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const configurationParams = useMergedConfigurationParams();
  const location = useLocation();
  const searchParams =
    qs.parse(location?.search, { ignoreQueryPrefix: true }) || {};
  const missionIdFromMissionPath =
    location.pathname.split('/projects/')?.[1]?.split('/')?.[1] || undefined;
  const { data: usersData, loading } = useClientUsers(clientId);

  const [users, setUsers] = useState([]);
  const atsId = useClientCurrentAtsId();
  const { data: filterOptionsData } = useAtsFilterOptions(clientId);
  const { missionCustomFields } = useClientMissionCustomFields(clientId);

  const {
    filters,
    loading: loadingDisplayPrefs,
    updateEqualityFilters,
  } = useTaskViewFilterDisplayPreferences();

  const { userTableViewPreferences } = useUserTableViewPreferences({
    tableId: 'tasks-view',
    defaultValues: {
      tableId: 'tasks-view',
      filteringOptions: {
        filters: [],
      },
    },
  });

  const defaultUserEmailFilter =
    configurationParams?.shouldHideTasksListTaskOwnerFilter !== 'true'
      ? userEmail
      : 'all';

  const defaultMissionIdFilter =
    searchParams?.missionId || missionIdFromMissionPath;

  const {
    selectedDepartmentId = null,
    selectedSubtypeIds = null,
    selectedStatus = 'all',
    selectedCdiiOption = 'all',
    selectedProfilVivier = 'all',
    selectedMissionOwner = 'all',
    selectedSequenceId = null,
    selectedUserEmail = defaultUserEmailFilter,
    selectedMissionId = defaultMissionIdFilter,
  } = useMemo(() => {
    if (loadingDisplayPrefs) {
      return {};
    }

    const {
      departmentIdFilter = [],
      subtypeFilter = null,
      adventureStatusFilter = 'all',
      adventureCDIIFilter = 'all',
      adventureProfilVivierFilter = 'all',
      missionOwnerFilter = 'all',
      sequenceIdFilter = null,
      taskOwner = defaultUserEmailFilter,
      missionIdFilter = defaultMissionIdFilter,
    } = filters || {};

    return {
      selectedDepartmentId:
        _.size(departmentIdFilter) <= 1
          ? departmentIdFilter?.[0] ?? null
          : departmentIdFilter,
      selectedSubtypeIds: subtypeFilter,
      selectedStatus: adventureStatusFilter,
      selectedCdiiOption: adventureCDIIFilter,
      selectedProfilVivier: adventureProfilVivierFilter,
      selectedMissionOwner: missionOwnerFilter,
      selectedSequenceId: sequenceIdFilter,
      selectedUserEmail: taskOwner,
      selectedMissionId: missionIdFilter,
    };
  }, [
    filters,
    loadingDisplayPrefs,
    defaultUserEmailFilter,
    defaultMissionIdFilter,
  ]);

  const statusOptions = useMemo(() => {
    const connector = _.findWhere(
      filterOptionsData?.client.revealProjects[0].connectors || [],
      { type: 'adventure' },
    );

    if (!connector) {
      return null;
    }

    const tags = _.filter(connector.filterOptions?.tags || [], ({ id }) =>
      id.startsWith('status'),
    );

    return [
      {
        value: 'all',
        text: t('reveal.todosView.filters.allStatuses'),
      },
      ..._.map(tags, ({ id, name }) => ({
        value: id,
        text: getTranslatedText(lang, name),
      })),
    ];
  }, [filterOptionsData, lang, t]);

  const cdiiOptions = useMemo(() => {
    const connector = _.findWhere(
      filterOptionsData?.client.revealProjects[0].connectors || [],
      { type: 'adventure' },
    );

    if (!connector) {
      return null;
    }

    const tags = _.filter(connector.filterOptions?.tags || [], ({ id }) =>
      id.startsWith('cdii'),
    );

    return [
      {
        value: 'all',
        text: t('reveal.todosView.filters.allCdiiOptions'),
      },
      ..._.map(tags, ({ id }) => ({
        value: id,
        text: id === 'cdii__yes' ? 'En CDII' : 'Pas en CDII',
      })),
    ];
  }, [filterOptionsData, t]);

  const profilVivierOptions = useMemo(() => {
    const profilVivierDefinition = _.findWhere(missionCustomFields, {
      id: 'profil-vivier',
    });

    if (profilVivierDefinition?.type !== 'enum') {
      return null;
    }

    const unsortedOptions = _.map(
      profilVivierDefinition.options,
      ({ id, title }) => ({
        value: id,
        text: getTranslatedText(lang, title),
      }),
    );

    const sortedOptions = _.sortBy(unsortedOptions, (option) => option.text);

    return [
      {
        value: 'all',
        text: t('reveal.todosView.filters.allProfilVivier'),
      },
      ...sortedOptions,
    ];
  }, [missionCustomFields, lang, t]);

  const {
    loading: departmentsLoading,
    departments,
  } = useUserAllowedDepartments();

  const subtypeOptions = useMemo(
    () =>
      _.map(TASK_SUBTYPES_BY_ATS[atsId] || [], (option) => ({
        value: option.id,
        text: t(option.translationKey),
      })),
    [atsId, t],
  );

  const departmentOptions = useMemo(() => {
    if (departmentsLoading) {
      return [];
    }

    if (atsId === 'adventure') {
      const options = [];
      _.forEach(departments, (department) => {
        _.forEach(department.sections, (section) => {
          _.forEach(section.subsections, (subsection) => {
            options.push({
              value: subsection.id,
              text: subsection.title,
            });
          });
        });
      });

      return options;
    }

    return _.flatten(
      _.map(departments || [], (department) => [
        {
          value: department.id,
          text: department.title,
        },
        ..._.map(department.sections, (section) => [
          {
            value: section.id,
            text: section.title,
          },
          ..._.map(section.subsections, (subsection) => ({
            value: subsection.id,
            text: subsection.title,
          })),
        ]),
      ]),
    );
  }, [departments, atsId, departmentsLoading]);

  useEffect(() => {
    if (!loading) {
      setUsers(usersData?.client?.users);
    }
  }, [usersData, loading]);

  useEffect(() => {
    if (
      !loading &&
      !loadingDisplayPrefs &&
      !_.isEmpty(users) &&
      !_.findWhere(users, { email: selectedUserEmail })
    ) {
      updateEqualityFilters({ 'task-owner': null });
    }
  }, [
    users,
    selectedUserEmail,
    loading,
    loadingDisplayPrefs,
    updateEqualityFilters,
  ]);

  const {
    jobOptions: jobOptionsData,
    jobOptionsLoading: searchPoolJobsLoading,
  } = useSearchPoolJobOptions('reveal', { activeOnly: true });

  const jobsList = _.filter(jobOptionsData, ({ hide }) => !hide);
  const sortedJobs = _.sortBy(jobsList, ({ __typename }) =>
    ['RevealGreenhouseJob', 'RevealLeverJob'].indexOf(__typename) >= 0 ? 1 : 0,
  );

  const jobOptions = _.map(sortedJobs, ({ id, data }) => ({
    value: id,
    text: data?.title || 'undefined',
  }));

  const { loading: sequencesLoading, data } = useQuery(CLIENT_SEQUENCES, {
    variables: { clientId },
    fetchPolicy: 'network-only',
  });
  const sequences = data?.client?.sequences;
  const activeSequences = _.where(sequences, { isArchived: false });
  const sequencesOptions = _.map(activeSequences, ({ id, title }) => ({
    value: id,
    text: title,
  }));

  const userOptions = getUserOptions(users);

  const isKnownOwnerEmail = {};
  const jobOwnerOptions = [];
  _.each(sortedJobs, ({ owner }) => {
    if (owner?.email && !isKnownOwnerEmail[owner.email]) {
      jobOwnerOptions.push({
        text:
          `${owner.firstname || ''} ${owner.lastname || ''}`.trim() ||
          owner.email,
        value: owner.email,
      });
      isKnownOwnerEmail[owner.email] = true;
    }
  });

  const missionIds = selectedMissionId ? [selectedMissionId] : null;
  const sequenceIds = selectedSequenceId ? [selectedSequenceId] : null;
  const ownerEmails = selectedUserEmail ? [selectedUserEmail] : null;

  let departmentIds = null;
  if (selectedDepartmentId) {
    if (_.isArray(selectedDepartmentId)) {
      departmentIds = selectedDepartmentId;
    } else {
      departmentIds = [selectedDepartmentId];
    }
  }

  const filteringSet = {
    missionIds: defaultMissionIdFilter ? [defaultMissionIdFilter] : missionIds,
    sequenceIds,
    ownerEmails,
    departmentIds,
    subtypeIds: selectedSubtypeIds,
    ...((selectedStatus !== 'all' ||
      selectedCdiiOption !== 'all' ||
      selectedProfilVivier !== 'all' ||
      selectedMissionOwner !== 'all') && {
      extraFilters: {
        ...((selectedProfilVivier !== 'all' ||
          selectedMissionOwner !== 'all') && {
          missionFilters: {
            ...(selectedProfilVivier !== 'all' && {
              customFields: [
                {
                  id: 'profil-vivier',
                  values: [selectedProfilVivier],
                },
              ],
            }),
            ...(selectedMissionOwner !== 'all' && {
              owners: [
                {
                  email: selectedMissionOwner,
                },
              ],
            }),
          },
        }),
        ...((selectedStatus !== 'all' || selectedCdiiOption !== 'all') && {
          searchCriteria: {
            free: JSON.stringify({
              adventureFilters: {
                tagIds: [
                  ...(selectedStatus !== 'all' ? [selectedStatus] : []),
                  ...(selectedCdiiOption !== 'all' ? [selectedCdiiOption] : []),
                ],
              },
            }),
          },
        }),
      },
    }),
  };

  const shouldBlockIfNoMainFilter =
    configurationParams?.shouldBlockIfNoMainFilterInTasksView === 'true';

  const hasNoMainFilter = !_.some(
    filteringSet,
    (val, key) =>
      (key === 'departmentIds' && (val || []).length <= 8) ||
      (key === 'extraFilters' && !_.isEmpty(val?.missionFilters?.owners)),
  );

  const shouldBlockBecauseNoMainFilter =
    shouldBlockIfNoMainFilter && hasNoMainFilter;

  const { loading: tasksLoading, data: tasksData } = useGetClientTasks({
    clientId,
    filters: filteringSet,
    queryOptions: {
      skip:
        departmentsLoading ||
        loadingDisplayPrefs ||
        !configurationParams ||
        shouldBlockBecauseNoMainFilter,
    },
  });

  const tasks = useMemo(
    () => [
      ...(tasksData?.client?.explicitTasks || []),
      ...(tasksData?.client?.contactFlowTasks || []),
    ],
    [tasksData],
  );
  const filteredTasks = useMemo(
    () =>
      _.filter(
        tasks,
        (task) => !_.isEmpty(task?.target) && task?.state !== 'deleted',
      ),
    [tasks],
  );

  const currentDay = new Date().toISOString().slice(0, 10);

  const tasksWithDateDiff = useMemo(
    () =>
      _.map(filteredTasks, (task) => {
        const diffInDays =
          task?.referenceDueDate && task?.referenceDueDate < currentDay
            ? getDiffInBusinessDays(task.referenceDueDate, currentDay)
            : 0;
        return {
          ...task,
          dateDiffInDays: diffInDays,
        };
      }),
    [currentDay, filteredTasks],
  );

  const tasksSortedByDueDate = useMemo(
    () =>
      _.sortBy(
        tasksWithDateDiff,
        (a) => -a.dateDiffInDays + numberFromId(a.id || ''),
      ),
    [tasksWithDateDiff],
  );

  const sortedTasks = useMemo(() => {
    const sortByOptions = userTableViewPreferences?.sortingOptions?.sortBy?.reverse();
    let sortedByDueDateTasks = [...tasksSortedByDueDate];
    _.each(sortByOptions, (sortBy) => {
      const columnSortParams = {
        t,
      };
      if (sortBy.key.indexOf('customField-') !== -1) {
        const fieldId = sortBy.key.split('customField-')[1];
        columnSortParams.customFieldId = fieldId;
        sortedByDueDateTasks = COLUMN_SORT_MAP.customField
          ? COLUMN_SORT_MAP.customField(
              sortedByDueDateTasks,
              sortBy.order,
              columnSortParams,
            )
          : sortedByDueDateTasks;
      } else if (sortBy.key.indexOf('substep-') !== -1) {
        const fieldId = sortBy.key.split('customField-')[1];
        columnSortParams.customFieldId = fieldId;
        columnSortParams.substepId = fieldId;
        sortedByDueDateTasks = COLUMN_SORT_MAP.substep
          ? COLUMN_SORT_MAP.substep(
              sortedByDueDateTasks,
              sortBy.order,
              columnSortParams,
            )
          : sortedByDueDateTasks;
      } else {
        sortedByDueDateTasks = COLUMN_SORT_MAP[sortBy.key]
          ? COLUMN_SORT_MAP[sortBy.key](
              sortedByDueDateTasks,
              sortBy.order,
              columnSortParams,
            )
          : sortedByDueDateTasks;
      }
    });
    return sortedByDueDateTasks;
  }, [tasksSortedByDueDate, userTableViewPreferences, t]);

  return (
    <div className='reveal-tasks'>
      <div className='reveal-tasks-header'>
        <div className='header-actions'>
          {statusOptions && (
            <Dropdown
              selection
              search
              clearable={selectedStatus && selectedStatus !== 'all'}
              className='hiresweet-rounded small'
              options={statusOptions}
              value={selectedStatus || 'all'}
              disabled={loadingDisplayPrefs || tasksLoading}
              onChange={(_e, { value }) =>
                updateEqualityFilters({ 'adventure-status': value })
              }
            />
          )}
          {cdiiOptions && (
            <Dropdown
              selection
              search
              clearable={selectedCdiiOption && selectedCdiiOption !== 'all'}
              className='hiresweet-rounded small'
              options={cdiiOptions}
              value={selectedCdiiOption || 'all'}
              disabled={loadingDisplayPrefs || tasksLoading}
              onChange={(_e, { value }) =>
                updateEqualityFilters({ 'adventure-cdii': value })
              }
            />
          )}
          {profilVivierOptions && (
            <Dropdown
              selection
              search
              clearable={selectedProfilVivier && selectedProfilVivier !== 'all'}
              className='hiresweet-rounded small'
              options={profilVivierOptions}
              value={selectedProfilVivier || 'all'}
              disabled={loadingDisplayPrefs || tasksLoading}
              onChange={(_e, { value }) =>
                updateEqualityFilters({ 'adventure-profil-vivier': value })
              }
            />
          )}
          {configurationParams?.shouldDisplayTasksListProjectOwner ===
            'true' && (
            <Dropdown
              selection
              search
              clearable={selectedMissionOwner && selectedMissionOwner !== 'all'}
              value={selectedMissionOwner || 'all'}
              disabled={loadingDisplayPrefs || tasksLoading}
              onChange={(_e, { value }) => {
                updateEqualityFilters({ 'mission-owner': value });
              }}
              options={[
                {
                  value: 'all',
                  text: t('reveal.todosView.filters.allMissionOwners'),
                },

                ..._.sortBy(jobOwnerOptions, 'text'),
              ]}
              className='hiresweet-rounded small'
            />
          )}
          {configurationParams?.shouldHideTasksListProjectFilter !== 'true' &&
            !missionIdFromMissionPath && (
              <Dropdown
                selection
                search
                clearable={selectedMissionId && selectedMissionId !== 'all'}
                value={selectedMissionId || 'all'}
                loading={searchPoolJobsLoading}
                disabled={loadingDisplayPrefs || tasksLoading}
                onChange={(_e, { value }) => {
                  updateEqualityFilters({
                    'mission-id': value === 'all' ? null : value,
                  });
                }}
                options={[
                  {
                    value: 'all',
                    text: t('reveal.todosView.filters.allMissions'),
                  },
                  ...jobOptions,
                ]}
                className='hiresweet-rounded small'
              />
            )}
          {configurationParams?.shouldHideSequenceFilter !== 'true' && (
            <Dropdown
              selection
              search
              clearable={selectedSequenceId && selectedSequenceId !== 'all'}
              value={selectedSequenceId || 'all'}
              loading={sequencesLoading}
              disabled={loadingDisplayPrefs || tasksLoading}
              onChange={(e, { value }) => {
                updateEqualityFilters({
                  'sequence-id': value === 'all' ? null : value,
                });
              }}
              options={[
                {
                  value: 'all',
                  text: t('reveal.todosView.filters.allSequences'),
                },
                ...sequencesOptions,
              ]}
              className='hiresweet-rounded small'
            />
          )}
          {(departmentOptions || []).length > 1 && (
            <Dropdown
              selection
              search
              clearable={selectedDepartmentId && selectedDepartmentId !== 'all'}
              value={
                _.isArray(selectedDepartmentId)
                  ? 'custom'
                  : selectedDepartmentId || 'all'
              }
              disabled={loadingDisplayPrefs || tasksLoading}
              onChange={(_e, { value }) => {
                updateEqualityFilters({
                  'department-id': value === 'all' ? null : [value],
                });
              }}
              options={[
                {
                  value: 'all',
                  text: t('reveal.todosView.filters.allDepartments'),
                },
                ...(_.isArray(selectedDepartmentId)
                  ? [
                      {
                        value: 'custom',
                        text: t('reveal.todosView.filters.someSubsections', {
                          count: _.size(selectedDepartmentId),
                        }),
                      },
                    ]
                  : []),
                ..._.sortBy(departmentOptions, 'text'),
              ]}
              className='hiresweet-rounded small'
            />
          )}
          {configurationParams?.shouldDisplayTaskSubtypeFilterInTasksList &&
            !_.isEmpty(subtypeOptions) && (
              <Dropdown
                selection
                search
                clearable={selectedSubtypeIds && selectedSubtypeIds !== 'all'}
                value={selectedSubtypeIds || 'all'}
                disabled={loadingDisplayPrefs || tasksLoading}
                onChange={(e, { value }) => {
                  updateEqualityFilters({
                    subtype: value === 'all' ? null : value,
                  });
                }}
                options={[
                  {
                    value: 'all',
                    text: t('reveal.todosView.filters.allSubtypes'),
                  },
                  ...subtypeOptions,
                ]}
                className='hiresweet-rounded small'
              />
            )}
          {configurationParams?.shouldHideTasksListTaskOwnerFilter !==
            'true' && (
            <Dropdown
              selection
              search
              value={
                !_.isEmpty(userOptions) ? selectedUserEmail || 'all' : 'all'
              }
              disabled={loadingDisplayPrefs || tasksLoading}
              onChange={(e, { value }) => {
                updateEqualityFilters({
                  'task-owner': value === 'all' ? null : value,
                });
              }}
              options={[
                { value: 'all', text: t('reveal.todosView.filters.allUsers') },
                ...userOptions,
              ]}
              className='hiresweet-rounded small'
            />
          )}
        </div>
        {!tasksLoading ? (
          <div className='tasks-title'>
            <span className='headline-2'>{t('reveal.tasks.title')}</span>
            <Counter value={tasks?.length || 0} active />
          </div>
        ) : null}
      </div>
      <div className='reveal-tasks-content'>
        {tasksLoading || !_.isEmpty(tasks) ? (
          <FilteredTasks
            clientId={clientId}
            tasks={sortedTasks}
            tasksLoading={tasksLoading}
          />
        ) : (
          <Segment>
            <EmptyState
              title={
                shouldBlockBecauseNoMainFilter
                  ? t('reveal.tasks.emptyState.askForFilter')
                  : t('reveal.tasks.emptyState.title')
              }
              innerContent={
                shouldBlockBecauseNoMainFilter ? (
                  <div>
                    <br />
                    <br />
                  </div>
                ) : (
                  t('reveal.tasks.emptyState.innerContent')
                )
              }
              illustrationPath='/images/placeholders/tasksEmptyState.svg'
            />
          </Segment>
        )}
      </div>
    </div>
  );
};

function numberFromId(id) {
  let hash = 0;
  _.each([...id], (c) => {
    hash += c.charCodeAt(0);
  });
  return +`0.${hash}`.slice(0, 15);
}

export default routerParamsToProps(TasksView);
