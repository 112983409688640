import React from 'react';
import _, { compose } from 'underscore';
import { Trans, withTranslation } from 'react-i18next';
import { Form, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import qs from 'qs';
import withGlobalSignin from '../../hocs/users/withGlobalSignin';
import withOAuthGlobalSignin from '../../hocs/users/withOAuthGlobalSignin';

import { sentryCaptureException } from '../../common';
import withActionLogger from '../../hocs/withActionLogger';
import FloatingLabelInput from '../../components/FloatingLabelInput';

import TeamChoice from './TeamChoice';
import Separator from './Separator';
import Layout from './Layout';
import OAuthGmailSignin from './OAuthGmailSignin';
import OAuthOutlookSignin from './OAuthOutlookSignin';

import './GenericSigninForm.css';
import './GlobalSigninForm.css';

class GlobalSigninForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      email: '',
      password: '',
      oAuthError: null,
    };
  }

  onSubmit = () => {
    this.setState({ loading: true, error: false });
    const payload = {
      email: this.state.email ? this.state.email.toLowerCase() : null,
      password: this.state.password,
    };
    return this.props
      .onGlobalPartialSignin(payload)
      .then(this.handleUserClients)
      .catch((e) => {
        Sentry.captureException(e);
        this.setState({ loading: false, error: true });
      });
  };

  onOAuthSignin = async ({ id_token: token, type }) => {
    const { globalSignOAuthUser } = this.props;
    try {
      const { data } = await globalSignOAuthUser({ token, type });
      const clients = data?.globalSignOAuthUser?.clients;
      this.handleUserClients(clients);
    } catch (error) {
      console.error(error);
      sentryCaptureException({ error });
    }
  };

  handleUserClients = (clients) => {
    if (_.isArray(clients) && clients.length === 1) {
      this.onChooseClient(clients[0].id);
    } else {
      this.setState({ loading: false, error: false, receivedclients: clients });
    }
  };

  onChooseClient = (clientId) => {
    const { onGlobalClientSignin, location, history } = this.props;
    const queryString =
      (_.isString(location.search) && location.search.replace('?', '')) || '';
    const queryObject = qs.parse(queryString);
    const redirectionPath = decodeURIComponent(queryObject?.redirect || '');
    let redirectUrl = `/client/${clientId}/dashboard`;
    if (redirectionPath) {
      redirectUrl = `/client/${clientId}${redirectionPath}`;
    }
    return onGlobalClientSignin({ clientId })
      .then(() => {
        history.push(redirectUrl);
      })
      .catch((e) => {
        Sentry.captureException(e);
        this.setState({ loading: false, error: true });
      });
  };

  onChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  onBeforeOAuthSignin = () => {
    this.setState({ oAuthError: null });
  };

  onOAuthError = ({ provider }) => {
    this.setState({ oAuthError: provider });
  };

  onClickForgottenPassword = () => {
    const { onLogAction, clientId } = this.props;
    onLogAction({
      type: 'click-forgotten-password',
      info: {
        clientId,
        from: 'global-signin-form',
      },
    });
  };

  render() {
    const { t, clients } = this.props;
    const {
      error,
      loading,
      email,
      password,
      receivedclients,
      oAuthError,
    } = this.state;

    if (clients && _.isArray(clients) && clients.length > 1) {
      return (
        <TeamChoice
          clients={clients}
          t={t}
          onChooseClient={this.onChooseClient}
        />
      );
    }

    if (
      receivedclients &&
      _.isArray(receivedclients) &&
      receivedclients.length > 1
    ) {
      return (
        <TeamChoice
          clients={receivedclients}
          t={t}
          onChooseClient={this.onChooseClient}
        />
      );
    }

    return (
      <Layout
        title={t('globalSignin.header')}
        // details={t('globalSignin.details')}
      >
        <div className='generic-signin-form'>
          <div className='signin-buttons'>
            <OAuthGmailSignin
              onSignin={this.onOAuthSignin}
              onError={this.onOAuthError}
              onBeforeSignin={this.onBeforeOAuthSignin}
            />
            <OAuthOutlookSignin
              onSignin={this.onOAuthSignin}
              onError={this.onOAuthError}
              onBeforeSignin={this.onBeforeOAuthSignin}
            />
          </div>
          {oAuthError && (
            <span className='signup-error'>
              {t(`signin.error.${oAuthError}`, '')}
            </span>
          )}
          <Separator className='signin-separator-dark'>
            {t('signin.or')}
          </Separator>
          <Form
            style={{ width: '100%' }}
            error={error}
            warning={error}
            loading={loading}
            onSubmit={this.onSubmit}
          >
            <Form.Field className='email-field'>
              <FloatingLabelInput
                label={t('globalSignin.form.email')}
                name='email'
                value={email}
                onChange={this.onChange}
              />
            </Form.Field>
            <Form.Field className='password-field'>
              <FloatingLabelInput
                type='password'
                label={t('globalSignin.form.password')}
                name='password'
                value={password}
                onChange={this.onChange}
              />
            </Form.Field>
            <Message
              error
              header={t('globalSignin.form.error.title')}
              content={t('globalSignin.form.error.message')}
            />
            <Message
              warning
              header={t('globalSignin.form.warning.title')}
              content={
                <p>
                  <Trans i18nKey='translations:globalSignin.form.warning.message'>
                    &nbsp;<a href='https://candidates.hiresweet.com/'>&nbsp;</a>
                    &nbsp;
                  </Trans>
                </p>
              }
            />
            <Form.Field className='submit-field'>
              <button className='submit-button' type='submit'>
                {t('globalSignin.form.submit')}
              </button>
            </Form.Field>
            <div className='underlined-link'>
              <Link
                to='forgotten-password'
                onClick={this.onClickForgottenPassword}
              >
                <span>{t('globalSignin.forgottenPassword')}</span>
              </Link>
            </div>

            <div className='underlined-link candidate-link'>
              <a href='https://candidates.hiresweet.com/'>
                <span>{t('globalSignin.candidateLandingLink')}</span>
              </a>
            </div>
          </Form>
        </div>
      </Layout>
    );
  }
}

export default compose(
  withOAuthGlobalSignin,
  withGlobalSignin,
  withActionLogger,
  withTranslation('translations'),
)(GlobalSigninForm);
