import _ from 'underscore';
import { ConnectorRejectionReason, RevealProject } from '@/common/reveal';
import getFilterOptions, { FilterOptions } from '../Filters/options';

const REJECTED_BY_COMPANY = 'rejected-by-company';
const REJECTED_BY_CANDIDATE = 'rejected-by-candidate';

const getApplicationStatusOptions = (
  rejectionReasons?: ConnectorRejectionReason[],
) => {
  return [
    {
      id: 'active',
      name: 'Active',
    },
    {
      id: 'hired',
      name: 'Hired',
    },
    {
      id: 'rejection__we-rejected-them',
      sourceId: REJECTED_BY_COMPANY,
      name: 'We rejected them',
      subOptions: _.map(
        _.filter(
          rejectionReasons || [],
          ({ rejectionType }) => rejectionType?.id === REJECTED_BY_COMPANY,
        ),
        ({ id, name }) => ({ id, name }),
      ),
    },
    {
      id: 'rejection__they-rejected-us',
      sourceId: REJECTED_BY_CANDIDATE,
      name: 'They rejected us',
      subOptions: _.map(
        _.filter(
          rejectionReasons || [],
          ({ rejectionType }) => rejectionType?.id === REJECTED_BY_CANDIDATE,
        ),
        ({ id, name }) => ({ id, name }),
      ),
    },
  ];
};

const getBoondmanagerOptions = ({
  projectId,
  projectsWithFilterOptions,
}: {
  projectId: string;
  projectsWithFilterOptions: RevealProject[];
}): FilterOptions => {
  return getFilterOptions({
    connectorType: 'boondmanager',
    projectId,
    projectsWithFilterOptions,
    getApplicationStatusOptions,
  });
};

export default getBoondmanagerOptions;
