import React from 'react';
import _ from 'underscore';

import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import ProfileOverview from '@/revealComponents/ProfileOverview';
import AttachmentsSection from '@/containers/Profile/Resume/Attachments/AttachmentsSection';
import { getAllAttachments } from '@/common/helpers/profile';
import OverviewCard from '@/routes/RevealView/SearchView/CandidatesListPane/CandidateView/OverviewCard';
import useUserProfileViewDisplayPreferences from '@/routes/RevealView/SearchView/CandidatesListPane/CandidateView/OverviewCard/useUserProfileViewDisplayPreferences';
import ATSData from '@/containers/Profile/Resume/Card/ATSData';
import useClientId from '@/hooks/router/useClientId';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';

import LinkedProjects from './LinkedProjects';
import LinkedContacts from './LinkedContacts';

type Props = {
  profileId: string;
};

function CompanyOverview({ profileId }: Props) {
  const clientId = useClientId();
  const { permissions } = useClientPermissions(clientId);
  const { profile } = useCandidateViewContext();
  const attachments = getAllAttachments(profile);
  const { profileViews } = useUserProfileViewDisplayPreferences();
  const profileViewPreferences = _.findWhere(profileViews, {
    profileViewId: 'default-company-view',
  });

  return (
    <>
      <ProfileOverview profile={profile} />
      <hr />
      <ATSData profile={profile} clientId={clientId} />
      <OverviewCard profile={profile} />
      {profileViewPreferences?.overviewCard && <hr />}
      <LinkedProjects profileId={profileId} />
      <hr style={{ marginTop: '18px' }} />
      <LinkedContacts profileId={profileId} />
      <hr style={{ marginTop: '18px' }} />
      {!!permissions && !permissions.profileDocumentsTab && (
        <AttachmentsSection attachments={attachments} profileId={profileId} />
      )}
    </>
  );
}

export default CompanyOverview;
