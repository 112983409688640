import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import OfferRecruiterConfiguration from '../../graphql/fragments/OfferRecruiterConfiguration';

export const addRecruiterConfiguration = gql`
  mutation addRecruiterConfiguration(
    $id: ID!
    $configInput: RecruiterConfigurationInput!
  ) {
    addRecruiterConfiguration(id: $id, configInput: $configInput) {
      id
      recruiterConfigurations {
        ...OfferRecruiterConfiguration
      }
    }
  }
  ${OfferRecruiterConfiguration}
`;

export default graphql(addRecruiterConfiguration, {
  props: ({ mutate }) => ({
    addRecruiterConfiguration: ({ id, configInput }) => {
      return mutate({ variables: { id, configInput } });
    },
  }),
});
