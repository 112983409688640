import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { Image } from 'semantic-ui-react';

import ConfirmationModal from './modals/ConfirmationModal';
import withActionLogger from '../hocs/withActionLogger';
import withClientPermissions from '../hocs/clients/withClientPermissions';
import withClientOffers from '../hocs/offers/withClientOffers';
import GenericButton from './Common/GenericButton';

const NewOfferButton = ({
  onLogAction,
  clientId,
  // permissions,
  // offers,
  loading,
  permissionsLoading,
  clientOffersLoading,
  from,
  t,
}) => {
  const [freeTrialModalOpen, setFreeTrialModalOpen] = useState(false);
  const [redirectionLink, setRedirectionLink] = useState(null);
  if (redirectionLink) {
    return <Redirect to={redirectionLink} />;
  }
  if (loading || permissionsLoading || clientOffersLoading) {
    return null;
  }
  // const { maxActiveOffersNumber } = permissions || {};

  // const numberOfActiveOffers = _.where(offers, { isSuggestedForCreation: false }).length;
  // const canCreateMoreOffers = !_.isNumber(maxActiveOffersNumber) || numberOfActiveOffers < maxActiveOffersNumber;
  const canCreateMoreOffers = true;

  const tryLogAction = ({ type, fromId }) => {
    try {
      onLogAction({
        type,
        info: {
          clientId,
          from: fromId,
        },
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const onSubmitModal = () => {
    tryLogAction({ type: 'click-billing', fromId: 'max-offers-modal' });
    setRedirectionLink(`/client/${clientId}/billing`);
  };

  const onClickNewOffer = () => {
    tryLogAction({ type: 'click-new-offer-link', fromId: from });
    setRedirectionLink(`/client/${clientId}/new-job`);
  };

  const onClickMaxOffers = () => {
    tryLogAction({ type: 'max-offers-modal-view', fromId: from });
    setFreeTrialModalOpen(true);
  };

  return (
    <div className='new-offer-button'>
      {canCreateMoreOffers ? (
        <GenericButton onClick={onClickNewOffer}>
          <Image src='/images/icons/figma/new-cross-white.svg' />
          {t('offers.createOffer')}
        </GenericButton>
      ) : (
        <GenericButton onClick={onClickMaxOffers} disabled>
          <Image src='/images/icons/figma/new-cross-white.svg' />
          {t('offers.createOffer')}
        </GenericButton>
      )}
      <ConfirmationModal
        header={t('billing.upgradeAccount')}
        content={t('billing.maxOffersNumberForCurrentPlan')}
        submit={t('billing.upgrade')}
        onSubmit={onSubmitModal}
        open={freeTrialModalOpen}
        onCancel={() => setFreeTrialModalOpen(false)}
        t={t}
      />
    </div>
  );
};

export default compose(
  withClientOffers,
  withClientPermissions,
  withActionLogger,
  withTranslation('translations'),
)(NewOfferButton);
