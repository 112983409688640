import _ from 'underscore';
import htmlToText from 'html-to-text';
import { removeEmptyKeyValues } from '@/common';

export const getHtmlStringAsText = (htmlString) => {
  if (!htmlString) {
    return '';
  }
  const result = htmlToText
    .fromString((htmlString || '').replaceAll('</div>', '</div><br/>'), {
      ignoreHref: true,
      ignoreImage: true,
      unorderedListItemPrefix: '• ',
      wordwrap: null,
    })
    .trim();

  return result;
};

const CURRENTLY_NOT_IN_ANY = 'currently-not-in-any';
const HAS_NEVER_BEEN_IN_ANY = 'has-never-been-in-any';

export const normalizeFilter = (filterOptions, searchQuery) => {
  const normalizedInput = (searchQuery || '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  return _.filter(filterOptions, (option) => {
    const normalizedOption = (option.text || '')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
    return normalizedOption.indexOf(normalizedInput) >= 0;
  });
};

export const cleanSearchParams = (searchParams) => {
  const cleanSequenceFilters = _.filter(
    searchParams?.criteria?.sequenceFilters,
    ({ filterType, sequenceIds }) =>
      filterType === CURRENTLY_NOT_IN_ANY || !_.isEmpty(sequenceIds),
  );
  const cleanProjectFilters = _.filter(
    searchParams?.criteria?.projectFilters,
    ({ filterType, projectIds }) =>
      filterType === CURRENTLY_NOT_IN_ANY ||
      filterType === HAS_NEVER_BEEN_IN_ANY ||
      !_.isEmpty(projectIds),
  );
  const cleanCriteria = removeEmptyKeyValues({
    ..._.omit(searchParams?.criteria, 'sequenceFilters', 'projectFilters'),
    ...(!_.isEmpty(cleanSequenceFilters) && {
      sequenceFilters: cleanSequenceFilters,
    }),
    ...(!_.isEmpty(cleanProjectFilters) && {
      projectFilters: cleanProjectFilters,
    }),
  });
  const cleanParams = removeEmptyKeyValues({
    ..._.omit(searchParams, 'criteria'),
    ...(!_.isEmpty(cleanCriteria) && {
      criteria: cleanCriteria,
    }),
  });
  return JSON.stringify(cleanParams);
};

export const getModifiableParams = (searchParams) => ({
  ...searchParams,
  criteria: removeEmptyKeyValues({
    ...searchParams.criteria,
    categoryFilters: undefined,
  }),
});
