import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SavedSearch from '../../graphql/fragments/SavedSearch';

export const saveSearch = gql`
  mutation saveSearch(
    $searchPoolId: ID!
    $criteria: SearchCriteriaInput!
    $name: String!
  ) {
    saveSearch(searchPoolId: $searchPoolId, name: $name, criteria: $criteria) {
      id
      savedSearches(searchPoolId: $searchPoolId) {
        ...SavedSearch
      }
    }
  }
  ${SavedSearch}
`;

export default graphql(saveSearch, {
  props: ({ mutate }) => ({
    saveSearch: ({ searchPoolId, name, search }) => {
      const variables = { searchPoolId, name, criteria: search?.criteria };
      return mutate({ variables });
    },
  }),
});
