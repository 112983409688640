import gql from 'graphql-tag';

export const UserDisplayTableViewPreferences = gql`
  fragment UserDisplayTableViewPreferences on UserDisplayTableViewPreferences {
    tableId
    filteringOptions {
      filters {
        key
        filter {
          in
          eq
        }
      }
    }
    sortingOptions {
      sortBy {
        key
        order
      }
    }
    columnsDisplay {
      columns {
        id
        hidden
        targetOrderIndex
        available
      }
    }
    extraParameters {
      key
      value
    }
  }
`;

export const UserDisplaySelectorPreferences = gql`
  fragment UserDisplaySelectorPreferences on UserDisplaySelectorPreferences {
    selectorId
    filteringOptions {
      filters {
        key
        filter {
          in
          eq
        }
      }
    }
    sortingOptions {
      sortBy {
        key
        order
      }
    }
    groupingOptions {
      groupBy {
        key
        group
      }
    }
    columnsDisplay {
      columns {
        id
        hidden
        targetOrderIndex
        available
      }
    }
    selectedItemIds
  }
`;

export const UserDisplayPreferences = gql`
  fragment UserDisplayPreferences on UserDisplayPreferences {
    tableViews {
      ...UserDisplayTableViewPreferences
    }
    selectors {
      ...UserDisplaySelectorPreferences
    }
  }

  ${UserDisplayTableViewPreferences}

  ${UserDisplaySelectorPreferences}
`;
