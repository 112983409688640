import gql from 'graphql-tag';

export interface IdentityProvider {
  certificates: string[];
  entityId: string;
  ssoServiceURL: string;
}

export interface SSOSettings {
  isActive: boolean;
  identityProvider?: IdentityProvider;
}

interface ISSOSettingsFragment {
  id: string;
  singleSignOn: SSOSettings;
}
const SSOSettingsFragment = gql`
  fragment SSOSettingsFragment on Client {
    id
    singleSignOn {
      isActive
      identityProvider {
        certificates
        entityId
        ssoServiceURL
      }
    }
  }
`;

export interface UpdateClientSSOSettingsVariables {
  input: SSOSettings;
}

export interface UpdateClientSSOSettingsResult {
  updateClientSSOSettings: ISSOSettingsFragment;
}

export const updateClientSSOSettings = gql`
  mutation updateClientSSOSettings($input: SSOSettingsInput!) {
    updateClientSSOSettings(input: $input) {
      client {
        ...SSOSettingsFragment
      }
    }
  }
  ${SSOSettingsFragment}
`;

export interface ClientSSOSettingsResult {
  client: ISSOSettingsFragment;
}
export interface ClientSSOSettingsVariables {
  clientId: string;
}
export const getClientSSOSettings = gql`
  query getClientSSOSettings($clientId: ID!) {
    client(id: $clientId) {
      ...SSOSettingsFragment
    }
  }
  ${SSOSettingsFragment}
`;
