import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import JobFoldering from '../../graphql/fragments/JobFoldering';
import JobPosting from '../../graphql/fragments/JobPosting';

export const query = gql`
  query getOfferJobPostings($id: ID!) {
    offer(id: $id) {
      id
      foldering {
        ...JobFoldering
      }
      jobPostings {
        ...JobPosting
      }
    }
  }
  ${JobFoldering}
  ${JobPosting}
`;

export const revealJobWithJobPostings = gql`
  query jobWithJobPostings($searchPoolId: ID!, $jobId: ID!) {
    searchPool(id: $searchPoolId) {
      id
      job(id: $jobId) {
        id
        foldering {
          department {
            id
          }
          section {
            id
          }
          subsection {
            id
          }
        }
        jobPostings {
          ...JobPosting
        }
      }
    }
  }
  ${JobPosting}
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.offerId,
    },
  }),
  props: ({ data: { loading, offer, error } }) => ({
    loading,
    jobPostings: offer?.jobPostings,
    jobFoldering: offer?.foldering,
    jobPostingsLoading: loading,
    error,
  }),
});
