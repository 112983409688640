import React from 'react';

import useClientId from '@/hooks/router/useClientId';

import {
  HiresweetLibProvider,
  ProjectContainer,
  ProjectOwner,
  ProjectTitle,
  useProjectData,
} from '@hiresweet/hiresweet-lib';

const Alpha = () => {
  const { loading, data } = useProjectData(ProjectTitle);
  if (loading) {
    return 'LOOOOAAAADDDDING';
  }
  return `Title from useProjectData: ${data}`;
};

const Beta = () => {
  const { loading, data } = useProjectData(ProjectOwner);
  if (loading) {
    return 'LOOOOAAAADDDDING';
  }
  return `Title from useProjectData: ${data?.email}`;
};

const Playground1 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30 }}>
      <h1>P2 - Project useData</h1>
      <HiresweetLibProvider theme={theme} clientId={clientId}>
        <ProjectContainer projectId='ri7-demo-testimport2-UMODD2'>
          <div>
            <h1>
              <ProjectTitle />
            </h1>
          </div>
        </ProjectContainer>
        <br />
        <br />
        <br />
        <ProjectContainer projectId='ri7-demo-julien-_-guinea-pigs-UOP3FK'>
          <Alpha />
          <br />
          <Beta />
        </ProjectContainer>
      </HiresweetLibProvider>
    </div>
  );
};

export default Playground1;
