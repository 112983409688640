import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'underscore';
import { Form, Message } from 'semantic-ui-react';
import { QRCode } from 'react-qrcode-logo';
import * as Sentry from '@sentry/browser';

import withSignin from '@/hocs/users/withSignin';
import withActionLogger from '@/hocs/withActionLogger';
import withOAuthSignin from '@/hocs/users/withOAuthSignin';
import withClientSingleSignOnActive from '@/hocs/clients/withClientSingleSignOnActive';

import FloatingLabelInput from '../../components/FloatingLabelInput';
import OAuthGmailSignin from './OAuthGmailSignin';
import OAuthOutlookSignin from './OAuthOutlookSignin';
import Separator from './Separator';
import Layout from './Layout';
import SSOLink from './SSO/SSOLink';

import './GenericSigninForm.css';

class SigninForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      oAuthError: null,
      email: '',
      password: '',
      mfa: '',
    };
  }

  onSubmit = () => {
    const { email, password, id_token: token, type, mfa } = this.state;
    if (token && type) {
      return this.onOAuthSignin({ id_token: token, type, mfa });
    }
    const { onSignin } = this.props;
    this.setState({ loading: true, error: false });
    const payload = {
      email: email ? email.toLowerCase() : null,
      password,
      mfaTOTP: mfa,
    };
    return onSignin(payload)
      .then((res = {}) => {
        if (res.mfa) {
          this.setState({ loading: false, error: false, needMfa: true });
        } else if (res.mfaUrl) {
          this.setState({
            loading: false,
            error: false,
            needMfaSetup: true,
            needMfa: true,
            mfaUrl: res.mfaUrl,
          });
        } else {
          this.setState({ loading: false, error: false, success: true });
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
        this.setState({ loading: false, error: true });
      });
  };

  onChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  onOAuthSignin = async ({ id_token: token, type, mfa }) => {
    const { signOAuthUser, clientId } = this.props;
    try {
      const { data } = await signOAuthUser({ token, type, clientId, mfa });
      const { signOAuthUser: user } = data || {};
      if (user.shouldSetupMFA) {
        this.setState({
          loading: false,
          error: false,
          needMfa: true,
          id_token: token,
          type,
        });
        return;
      }
      if (user.mfaUrl) {
        this.setState({
          loading: false,
          error: false,
          needMfaSetup: true,
          needMfa: true,
          mfaUrl: user.mfaUrl,
          id_token: token,
          type,
        });
        return;
      }
      this.setState({ success: true });
    } catch (error) {
      this.setState({ error: true });
    }
  };

  onBeforeOAuthSignin = () => {
    this.setState({ oAuthError: null });
  };

  onOAuthError = ({ provider }) => {
    this.setState({ oAuthError: provider });
  };

  onClickForgottenPassword = () => {
    const { onLogAction, clientId } = this.props;
    onLogAction({
      type: 'click-forgotten-password',
      info: {
        clientId,
        from: 'signin-form',
      },
    });
  };

  render() {
    const { t, clientId, redirectTo, client } = this.props;
    const clientSingleSignOnIsActive = client?.singleSignOn?.isActive;
    const {
      success,
      email,
      error,
      loading,
      oAuthError,
      password,
      needMfaSetup,
      mfa,
      needMfa,
      mfaUrl,
    } = this.state;

    if (success) {
      return <Redirect to={redirectTo} />;
    }

    return (
      <Layout title={t('signin.title')}>
        <div className='generic-signin-form'>
          <div className='signin-buttons'>
            <OAuthGmailSignin
              onSignin={this.onOAuthSignin}
              onError={this.onOAuthError}
              onBeforeSignin={this.onBeforeOAuthSignin}
            />
            <OAuthOutlookSignin
              onSignin={this.onOAuthSignin}
              onError={this.onOAuthError}
              onBeforeSignin={this.onBeforeOAuthSignin}
            />
          </div>
          {oAuthError && (
            <div className='signup-error'>
              {t(`signin.error.${oAuthError}`, '')}
            </div>
          )}
          <Separator className='signin-separator-dark'>
            {t('signin.or')}
          </Separator>
          <Form
            error={error}
            loading={loading}
            onSubmit={this.onSubmit}
            style={{ width: '100%' }}
          >
            {!needMfa && !needMfaSetup && (
              <>
                <Form.Field className='email-field'>
                  <FloatingLabelInput
                    id='email'
                    label={t('signin.form.email')}
                    value={email}
                    type='email'
                    name='email'
                    onChange={this.onChange}
                  />
                </Form.Field>
                <Form.Field className='password-field'>
                  <FloatingLabelInput
                    id='password'
                    label={t('signin.form.password')}
                    type='password'
                    name='password'
                    value={password}
                    onChange={this.onChange}
                  />
                </Form.Field>
              </>
            )}
            {needMfaSetup && (
              <div className='qr-code-container'>
                <QRCode value={mfaUrl} size={250} quietZone={24} />
              </div>
            )}
            {needMfa && (
              <Form.Field className='mfa-field'>
                <input
                  type='number'
                  placeholder='012345'
                  name='mfa'
                  value={mfa}
                  onChange={this.onChange}
                />
              </Form.Field>
            )}
            <Message
              className='error-message'
              error
              header={t('signin.form.error.title')}
              content={t('signin.form.error.message')}
            />
            <Form.Field className='submit-field'>
              <button className='submit-button' type='submit'>
                {t('signin.form.submit')}
              </button>
            </Form.Field>
            <div className='underlined-link'>
              <Link
                to='forgotten-password'
                onClick={this.onClickForgottenPassword}
              >
                <span>{t('signin.forgottenPassword')}</span>
              </Link>
            </div>
          </Form>
          {clientSingleSignOnIsActive && (
            <>
              <Separator className='signin-separator-dark'>
                {t('signin.or')}
              </Separator>
              <div className='underlined-link'>
                <SSOLink clientId={clientId} redirectTo={redirectTo}>
                  <span>{t('signin.sso')}</span>
                </SSOLink>
              </div>
            </>
          )}
        </div>
      </Layout>
    );
  }
}

export default compose(
  withOAuthSignin,
  withSignin,
  withActionLogger,
  withClientSingleSignOnActive,
  withTranslation('translations'),
)(SigninForm);
