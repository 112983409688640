import gql from 'graphql-tag';
import {
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from '@apollo/client';

const BULK_ENRICH_MUTATION = gql`
  mutation bulkEnrichProfiles(
    $searchPoolId: ID!
    $profileIds: [String!]!
    $provider: String!
    $userIntegrationId: String
    $primaryEmailAddressReplacementPolicy: String
    $shouldEnrichWhenAlreadyEmailAddress: Boolean
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      bulkAskProfileEnrichment(
        profileIds: $profileIds
        provider: $provider
        userIntegrationId: $userIntegrationId
        primaryEmailAddressReplacementPolicy: $primaryEmailAddressReplacementPolicy
        shouldEnrichWhenAlreadyEmailAddress: $shouldEnrichWhenAlreadyEmailAddress
      ) {
        inProgress
        profiles {
          id
          email
          resumeData {
            sourceData {
              contactout {
                enrichmentResults {
                  date
                  emails {
                    type
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface BulkEnrichResult {
  searchPoolProfile: {
    bulkAskProfileEnrichment: {
      inProgress: boolean;
      profiles: {
        id: string;
        email: string;
      }[];
    };
  };
}

export type EmailReplacementPolicy =
  | 'never'
  | 'only-personal'
  | 'only-work'
  | 'prefer-personal'
  | 'prefer-work';

interface BulkEnrichVariables {
  searchPoolId: string;
  profileIds: string[];
  provider: string;
  userIntegrationId?: string | null;
  primaryEmailAddressReplacementPolicy: EmailReplacementPolicy;
  shouldEnrichWhenAlreadyEmailAddress: boolean;
}

const useBulkEnrich = (
  options: MutationHookOptions<BulkEnrichResult, BulkEnrichVariables>,
): MutationTuple<BulkEnrichResult, BulkEnrichVariables> => {
  return useMutation<BulkEnrichResult, BulkEnrichVariables>(
    BULK_ENRICH_MUTATION,
    {
      refetchQueries: ['getUserEnrichmentIntegrations'],
      ...options,
    },
  );
};

export default useBulkEnrich;
