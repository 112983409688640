import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './svg.module.less';

const Tag: FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    className={classNames(styles.svgIcon, className)}
    width='15'
    height='15'
    viewBox='0 0 15 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.26673 0.399994L13.8661 1.34333L14.8087 7.94333L8.68073 14.0713C8.55571 14.1963 8.38617 14.2665 8.2094 14.2665C8.03262 14.2665 7.86308 14.1963 7.73806 14.0713L1.13806 7.47133C1.01308 7.34631 0.942871 7.17677 0.942871 6.99999C0.942871 6.82322 1.01308 6.65368 1.13806 6.52866L7.26673 0.399994ZM7.73806 1.81466L2.55206 6.99999L8.2094 12.6567L13.3947 7.47133L12.6881 2.52133L7.73806 1.81466ZM9.15139 6.05733C8.9013 5.80714 8.76083 5.46785 8.76089 5.11409C8.76092 4.93893 8.79545 4.76549 8.86251 4.60367C8.92957 4.44185 9.02785 4.29483 9.15173 4.17099C9.27561 4.04716 9.42267 3.94893 9.58451 3.88193C9.74635 3.81493 9.9198 3.78046 10.095 3.78049C10.4487 3.78055 10.788 3.92114 11.0381 4.17133C11.2882 4.42151 11.4286 4.76081 11.4286 5.11456C11.4285 5.46832 11.2879 5.80756 11.0377 6.05766C10.7875 6.30776 10.4482 6.44823 10.0945 6.44817C9.74074 6.4481 9.40149 6.30752 9.15139 6.05733Z'
      fill='#A5ABC9'
    />
  </svg>
);

export default Tag;
