import React, { Fragment, useContext, useMemo } from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  PipelineState,
  Segment,
  Segmentation,
} from '@/components/PipelineSegmentation/pipelineSegmentation';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import usePipelineSubSteps from '@/graphql/hooks/clients/usePipelineSubSteps';

import styles from './SegmentationLine.module.less';

type SegmentationLineProps = {
  segmentation: Segmentation;
  counts?: { segmentId: string; count: number }[] | null;
  selectedSegmentId?: string;
  onSelectSegment?: (segment: Segment) => void;
};
export function SegmentationLine(props: SegmentationLineProps) {
  const { segmentation, counts, selectedSegmentId, onSelectSegment } = props;
  const { segments } = segmentation;
  const { t } = useTranslation();
  const missionCategoryType = useContext(MissionCategoryContext);
  const { pipelineSubSteps } = usePipelineSubSteps();
  const subStepsDefinition = useMemo(
    () =>
      _.find(
        pipelineSubSteps,
        ({ missionCategory, stepId }) =>
          missionCategory.type === missionCategoryType &&
          stepId === 'interested',
      ),
    [pipelineSubSteps, missionCategoryType],
  );
  const missionInterestedSubSteps = useMemo(
    () => subStepsDefinition?.subSteps || [],
    [subStepsDefinition],
  );

  const interstedSegments = _.filter(
    segments,
    (segment) => (segment.type || '').indexOf('interested') >= 0,
  );
  const interestedSubstepIds = _.pluck(
    interstedSegments || missionInterestedSubSteps,
    'id',
  );
  return (
    <nav className={styles.segmentationLine}>
      {segments.map((segment, index) => (
        <Fragment key={segment.id}>
          {_.contains(interestedSubstepIds, segments[index]?.id) &&
            !_.contains(interestedSubstepIds, segments[index - 1]?.id) && (
              <div className={styles.startSeparator}>
                {t(`reveal.pipelineSegmentations.${PipelineState.INTERESTED}`)}
              </div>
            )}
          <SegmentationLineItem
            key={segment.id}
            count={
              counts?.find((c) => c.segmentId === segment.id)?.count ?? null
            }
            selected={segment.id === selectedSegmentId}
            onClick={onSelectSegment && (() => onSelectSegment(segment))}
          >
            {segment.name || ''}
          </SegmentationLineItem>
          {_.contains(interestedSubstepIds, segments[index]?.id) &&
            !_.contains(interestedSubstepIds, segments[index + 1]?.id) && (
              <div className={styles.endSeparator} />
            )}
        </Fragment>
      ))}
    </nav>
  );
}

function SegmentationLineItem(props: {
  count?: number | null;
  children: string;
  selected?: boolean;
  onClick?: () => void;
}) {
  const { children, count, selected, onClick } = props;
  return (
    <div
      className={classNames(styles.segmentationLineItem, {
        [styles.segmentationLineItemSelected]: selected,
      })}
      role={onClick && 'button'}
      onClick={onClick}
      tabIndex={0}
      onKeyPress={(e: React.KeyboardEvent) => {
        if (onClick && [' ', 'Space', 'Enter'].includes(e.key)) {
          onClick();
          e.preventDefault();
        }
      }}
    >
      <span className={styles.itemName}>{children}</span>
      {_.isNumber(count) && (
        <>
          <span className={styles.itemDivider}>•</span>
          <span className={styles.itemCount}>{count}</span>
        </>
      )}
    </div>
  );
}
