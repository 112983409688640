import _ from 'underscore';
import React, { PropsWithChildren, useContext, useState } from 'react';
import {
  HiresweetLibProvider,
  ProjectTitle,
  ProjectsSetContainer,
  ProjectsSetContext,
  ProjectSetItemContainer,
  ProjectOwner,
  ProjectsSubsetRegister,
} from '@hiresweet/hiresweet-lib';
import useClientId from '@/hooks/router/useClientId';

const PaginatedProjects = ({ children }: PropsWithChildren<object>) => {
  const { projectIds } = useContext(ProjectsSetContext);
  const [iPage, setIPage] = useState(0);

  const chunks = _.chunk(projectIds, 5);

  if (_.isEmpty(chunks)) {
    return null;
  }

  return (
    <div>
      <h3>Paginated</h3>
      <button
        type='submit'
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setIPage((currentIPage) =>
            currentIPage < chunks.length - 1 ? currentIPage + 1 : 0,
          );
        }}
      >
        {iPage + 1} / {chunks.length}
      </button>
      <br />
      <ProjectsSubsetRegister projectIds={chunks[iPage] ?? []}>
        {(chunks[iPage] || []).map((projectId) => (
          <div key={projectId}>
            <ProjectSetItemContainer projectId={projectId}>
              {children}
            </ProjectSetItemContainer>
          </div>
        ))}
      </ProjectsSubsetRegister>
    </div>
  );
};

const Playground5 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();

  const [ownerEmail, setOwnerEmail] = useState('ismael@hiresweet.com');

  return (
    <div style={{ padding: 30 }}>
      <h1>P5 - Projects - Filter and Manual Pagination</h1>
      <div>
        <button
          type='button'
          onClick={() => setOwnerEmail('paul@hiresweet.com')}
        >
          Select Paul
        </button>
        <button
          type='button'
          onClick={() => setOwnerEmail('ismael@hiresweet.com')}
        >
          Select Ismael
        </button>
        <HiresweetLibProvider theme={theme} clientId={clientId}>
          <ProjectsSetContainer filter={{ ownerEmail }}>
            <PaginatedProjects>
              <ProjectTitle /> - <ProjectOwner />
            </PaginatedProjects>
          </ProjectsSetContainer>
        </HiresweetLibProvider>
      </div>
    </div>
  );
};

export default Playground5;
