import gql from 'graphql-tag';
import { withApollo } from '@apollo/client/react/hoc';
import React from 'react';

const query = gql`
  query hasGrantOffline($token: String!, $type: String!) {
    hasGrantOffline(token: $token, type: $type)
  }
`;

export default (WrappedComponent) =>
  withApollo(({ client, ...rest }) => {
    const childProps = {
      testOfflineGrant: async ({ token, type }) => {
        const param = {
          query,
          variables: { token, type },
          fetchPolicy: 'network-only',
        };
        const { data } = await client.query(param);
        return !!(data && data.hasGrantOffline);
      },
    };
    return <WrappedComponent {...rest} {...childProps} />;
  });
