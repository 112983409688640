import { Client } from '@/types/client';

export function isInExpiredFreeTrial(client?: Client): boolean {
  const { freeTrialConfiguration } = client || {};

  if (
    !freeTrialConfiguration ||
    freeTrialConfiguration.upgraded ||
    !freeTrialConfiguration?.endDate
  ) {
    return false;
  }

  const freeTrialEndDate = new Date(freeTrialConfiguration.endDate);

  return freeTrialEndDate && new Date() > freeTrialEndDate;
}
