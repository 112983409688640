import _ from 'underscore';
import { isLinkedInInviteAction } from '@/common/contactFlow/getPermissionsAndNudgesAtIndex';
import {
  AND_CONDITION_TYPE,
  isEmailAction,
  LINKEDIN_INVITATION_ACCEPTED,
  OR_CONDITION_TYPE,
  TASK_TYPES,
  UNIPILE_TASK_TYPES,
} from '../../../common/constants/taskTypes';

// returns an array of errors (empty if validation is OK)
const validateContactFlow = ({ contactFlow }) => {
  return _.flatten(
    _.map(contactFlow?.sequences, (sequence) =>
      validateSequence(sequence, false),
    ),
  );
};

export const validateSequence = (sequence, allowDelayOnFirstAction) => {
  // const firstEmailIndex = _.findIndex(sequence?.actions, isEmailAction);
  return _.compact(
    _.map(sequence?.actions, (action, index) => {
      const firstActionOfTypeIndex = _.findIndex(
        sequence?.actions,
        (sequenceAction) =>
          (sequenceAction.type === action.type ||
            (sequenceAction.type.indexOf('send-email') >= 0 &&
              action.type.indexOf('send-email') >= 0)) &&
          _.includes(
            [
              TASK_TYPES.LINKEDIN_SEND_MESSAGE,
              TASK_TYPES.SEND_EMAIL,
              TASK_TYPES.SEND_EMAIL_MANUAL,
            ],
            sequenceAction.type,
          ),
      );
      return validateAction({
        sequenceId: sequence?.id,
        action,
        index,
        firstActionOfTypeIndex,
        sequenceActions: sequence?.actions,
        allowDelayOnFirstAction,
      });
    }),
  );
};

// NOTE: order of errors matter, only the first warning will be displayed
const validateAction = ({
  sequenceId,
  action,
  index,
  firstActionOfTypeIndex,
  sequenceActions,
  allowDelayOnFirstAction,
}) => {
  const errors = [];

  if (index === 0 && action?.shouldPerformAutomatically) {
    errors.push({
      error: 'first-action-cannot-be-automatic',
      type: action?.type,
      sequenceId,
      actionId: action?.actionId,
      level: 'major',
    });
  }

  if (
    action?.trigger?.type === 'manual-trigger' &&
    action?.shouldPerformAutomatically &&
    UNIPILE_TASK_TYPES.includes(action?.type)
  ) {
    errors.push({
      error: 'manual-action-cannot-be-automatic',
      type: action?.type,
      sequenceId,
      actionId: action?.actionId,
      level: 'major',
      index,
    });
  }

  // check that the first action is created without a delay
  if (
    index === 0 &&
    !!action?.trigger?.delay &&
    !allowDelayOnFirstAction &&
    action.type !== TASK_TYPES.CUSTOM
  ) {
    errors.push({
      error: 'first-action-must-be-created-immediately',
      type: action?.type,
      sequenceId,
      actionId: action?.actionId,
      index,
      firstActionOfTypeIndex,
      level: 'major',
    });
  }

  if (
    index === firstActionOfTypeIndex &&
    isEmailAction(action) &&
    action?.type !== TASK_TYPES.SEND_EMAIL_MANUAL
  ) {
    errors.push({
      error: 'first-email-must-be-manual',
      type: action?.type,
      sequenceId,
      actionId: action?.actionId,
      index,
      firstActionOfTypeIndex,
      level: 'major',
    });
  }

  if (isEmailAction(action) && !action?.message) {
    errors.push({
      error: 'missing-message',
      type: action?.type,
      sequenceId,
      actionId: action?.actionId,
      index,
      firstActionOfTypeIndex,
      level: 'minor',
    });
  }

  if (hasLinkedinInvitationAcceptedTrigger(action)) {
    const invitationActionIndex = _.findIndex(
      sequenceActions,
      isLinkedInInviteAction,
    );
    if (invitationActionIndex === -1 || invitationActionIndex >= index) {
      errors.push({
        error: 'missing-linkedin-invitation-before-invitation-accepted-trigger',
        type: action?.type,
        sequenceId,
        actionId: action?.actionId,
        index,
        firstActionOfTypeIndex,
        level: 'major',
      });
    }
  }

  return errors;
};

const hasLinkedinInvitationAcceptedTrigger = (action) => {
  const { trigger } = action;
  if (!trigger) {
    return false;
  }
  const triggerType = trigger.type;
  if (
    triggerType !== OR_CONDITION_TYPE &&
    triggerType !== AND_CONDITION_TYPE &&
    triggerType !== LINKEDIN_INVITATION_ACCEPTED
  ) {
    return false;
  }
  if (triggerType === LINKEDIN_INVITATION_ACCEPTED) {
    return true;
  }
  const triggerConditions = trigger.conditions;

  const oneConditionHasLinkedinInvitationAcceptedTrigger = _.contains(
    _.map(triggerConditions, (condition) =>
      hasLinkedinInvitationAcceptedTrigger({ ...action, trigger: condition }),
    ),
    true,
  );
  return oneConditionHasLinkedinInvitationAcceptedTrigger;
};

export default validateContactFlow;
