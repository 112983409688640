import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
  ReactNode,
} from 'react';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import useClientMissionsDailyEvents from '@/graphql/hooks/clients/useClientMissionsDailyEvents';
import useClientMissionsDailyEventsDetails from '@/graphql/hooks/clients/useClientMissionsDailyEventsDetails';
import InfoTooltip from '@/components/Common/InfoTooltip';
import GenericModal from '@/components/Common/GenericModal';
import { useUserAllowedDepartments } from '@/graphql/hooks/users/useUserAllowedDepartments';
import useMissionsWithFoldering from '@/graphql/hooks/searchPoolJobs/useMissionsWithFoldering';

import GraphWrapper from '../../components/GraphWrapper/GraphWrapper';
import {
  ChartType,
  Frequency,
  Mode,
} from '../../components/GraphWrapper/types';
import MissionsDataContainer from '../MissionsDataContainer';
import { filterEventsByDateRange } from '../../stats.helpers';
import StatsProfileAndMissionSourcedTable from '../../components/StatsProfileAndMissionSourcedTable';
import { MissionSourcedDetail } from '../../components/StatsProfileAndMissionSourcedTable/types';
import DetailsModalHeader from '../../components/DetailsModalHeader';
import StatisticsParametersContext from '../../StatisticsParametersContext';

import styles from './ContactsStatistics.module.less';

interface StatsFilters {
  startDay?: string;
  endDay?: string;
}

interface ContactsStatisticsProps {
  title?: ReactNode;
  useAggregatePeriod?: boolean;
}

const ContactsStatistics: React.FC<ContactsStatisticsProps> = ({
  title,
  useAggregatePeriod,
}) => {
  const [{ missionIds, timeSpan, aggregatePeriod }] = useContext(
    StatisticsParametersContext,
  );
  const { t, i18n } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [filters, setFilters] = useState<StatsFilters | undefined>(undefined);
  const { subsections } = useUserAllowedDepartments();
  const { missionsWithFoldering } = useMissionsWithFoldering();

  const subsectionIdMap = useMemo(() => {
    const result = {} as Record<string, boolean>;
    _.each(subsections, ({ id }) => {
      result[id] = true;
    });
    return result;
  }, [subsections]);

  const availableMissionIds = useMemo(() => {
    const result = [] as string[];
    _.each(missionsWithFoldering, ({ id, foldering }) => {
      if (
        foldering?.subsection?.id &&
        subsectionIdMap[foldering.subsection.id]
      ) {
        result.push(id);
      }
    });
    return result;
  }, [missionsWithFoldering, subsectionIdMap]);

  const sentMissionIds = useMemo(
    () => (_.isEmpty(missionIds) ? availableMissionIds : missionIds),
    [missionIds, availableMissionIds],
  );

  const {
    dailyEvents = [],
    loading: loadingStats,
  } = useClientMissionsDailyEvents({
    missionIds: sentMissionIds,
    queryOptions: { fetchPolicy: 'network-only' },
  });

  const [
    fetchDetails,
    { dailyEvents: dailyEventDetails, loading: detailsLoading },
  ] = useClientMissionsDailyEventsDetails();

  const applyFilters = useCallback(
    (newFilters: StatsFilters) => {
      setModalOpen(true);
      setFilters(newFilters);
      fetchDetails({ missionIds: sentMissionIds });
    },
    [setModalOpen, setFilters, fetchDetails, sentMissionIds],
  );

  const missionSourcedDetails = useMemo(() => {
    const eventsInRange = filterEventsByDateRange(
      timeSpan,
      dailyEventDetails || [],
    );

    if (!filters) {
      return [];
    }

    const { startDay, endDay } = filters;

    const result: MissionSourcedDetail[] = [];

    _.each(eventsInRange, ({ day, details }) => {
      if ((startDay && day < startDay) || (endDay && day >= endDay)) {
        return;
      }
      _.each(details, ({ type, missionId, profileItems }) => {
        if (type !== 'nbSourced') {
          return;
        }
        _.each(profileItems, ({ profileId }) => {
          result.push({ profileId, missionId, day });
        });
      });
    });

    return result;
  }, [filters, dailyEventDetails, timeSpan]);

  const modalSubtitle = useMemo(() => {
    if (!filters?.startDay || !filters?.endDay) {
      return '';
    }
    const dateDisplayOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    const dayToLocaleString = (day: string): string =>
      new Date(day).toLocaleString(i18n.resolvedLanguage, dateDisplayOptions);

    return t('reveal.reports.missions.sourced.detailsModal.subtitle', {
      startDate: dayToLocaleString(filters.startDay),
      endDate: dayToLocaleString(filters.endDay),
    });
  }, [filters, i18n, t]);

  const defaultTitle = useMemo(
    () => (
      <>
        {t('reveal.reports.missions.sourced.title')}
        <InfoTooltip rich hoverable position='right center'>
          <h1>{t('reveal.reports.tooltips.missions.sourced.title')}</h1>
          <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
          <p>
            {t(
              'reveal.reports.tooltips.missions.sourced.multiMissionParagraph',
            )}
          </p>
          <h2>{t('reveal.reports.tooltips.common.details')}</h2>
          <p>{t('reveal.reports.tooltips.common.barDetails')}</p>
        </InfoTooltip>
      </>
    ),
    [t],
  );

  return (
    <div className={styles.section}>
      <div className={styles.revealAnalyticsGraph}>
        <GraphWrapper
          timeSpan={timeSpan}
          lockFrequency={useAggregatePeriod}
          lockMode
          title={<h3 className={styles.title}>{title || defaultTitle}</h3>}
          renderChildren={(
            frequency: Frequency,
            _mode: Mode,
            type: ChartType,
          ) =>
            loadingStats ? (
              <div className={styles.loader}>
                <Loader active inline='centered' size='large' />
              </div>
            ) : (
              <MissionsDataContainer
                dateRange={timeSpan}
                frequency={useAggregatePeriod ? aggregatePeriod : frequency}
                missionDailyEvents={dailyEvents}
                mode='number'
                type={type}
                fetchDetails={(startDay, endDay) =>
                  applyFilters({ startDay, endDay })
                }
              />
            )
          }
        />
      </div>
      {detailsLoading ? (
        <Dimmer active>
          <Loader size='large' />
        </Dimmer>
      ) : (
        <GenericModal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Content>
            <DetailsModalHeader>
              <h1>{t('reveal.reports.missions.sourced.title')}</h1>
              {modalSubtitle && <h2>{modalSubtitle}</h2>}
            </DetailsModalHeader>
            <StatsProfileAndMissionSourcedTable
              missionSourcedDetails={missionSourcedDetails}
            />
          </Modal.Content>
        </GenericModal>
      )}
    </div>
  );
};

export default ContactsStatistics;
