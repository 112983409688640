import React from 'react';
import _ from 'underscore';
import { Checkbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ContactFlowSequenceActions } from '@/types/searchPoolProfile';
import {
  LINKEDIN_INVITATION_ACCEPTED,
  OR_CONDITION_TYPE,
} from '@/common/constants/taskTypes';
import { capitalizeFirstLetter } from '@/common/utils/string';
import { useQuery } from '@apollo/client';
import { GET_LINKEDIN_ACCOUNTS } from '@/graphql/linkedAccounts';
import InfoTooltip from '../Common/InfoTooltip';

interface LinkedinInvitationAcceptedConditionProps {
  checked: boolean;
  clientId: string;
  onUpdate: ({ action }: { action: ContactFlowSequenceActions }) => void;
  action: ContactFlowSequenceActions;
  disabled?: boolean;
}

const LinkedinInvitationAcceptedCondition: React.FC<LinkedinInvitationAcceptedConditionProps> = ({
  checked,
  clientId,
  onUpdate,
  action,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const { data: linkedInAccountsData } = useQuery(
    GET_LINKEDIN_ACCOUNTS,
    {
      variables: {
        clientId,
      },
    },
  );

  const nbLinkedinAccounts = linkedInAccountsData?.client?.linkedinAccounts?.length ?? 0; 

  const deleteTrigger = () => onUpdate({ action: _.omit(action, 'trigger') });
  const addOrTriggerAndCondition = () =>
    onUpdate({
      action: {
        ...action,
        trigger: {
          type: OR_CONDITION_TYPE,
          conditions: [action.trigger, { type: LINKEDIN_INVITATION_ACCEPTED }],
        },
      },
    });

  if (nbLinkedinAccounts === 0) {
    return '';
  }

  return (
    <div>
      <Checkbox
        checked={checked}
        label={`${capitalizeFirstLetter(t('common.OR'))} ${t(
          'contactFlow.triggers.linkedinInvitationAccepted',
        )}`}
        onChange={checked ? deleteTrigger : addOrTriggerAndCondition}
        disabled={disabled}
        className='trigger-checkbox'
      />
      {action?.type?.includes('linkedin-') && (
        <InfoTooltip rich hoverable position='right center'>
          {t('contactFlow.triggers.tooltips.triggerAutomatically.firstPart')}
          &nbsp;
          <a
            href={`https://app.hiresweet.com/client/${clientId}/linkedin`}
            target='_blank'
            rel='noreferrer noopener'
          >
            {t('contactFlow.triggers.tooltips.triggerAutomatically.cta')}
          </a>
          {t('contactFlow.triggers.tooltips.triggerAutomatically.secondPart')}
          &nbsp;
          <a
            href='https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?access_type=offline&login_hint=116114719929182188139&openid.realm&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.compose%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20%20openid%20profile%20email&origin=https%3A%2F%2Fapp.hiresweet.com&response_type=code%20permission%20id_token&redirect_uri=storagerelay%3A%2F%2Fhttps%2Fapp.hiresweet.com%3Fid%3Dauth424983&ss_domain=https%3A%2F%2Fapp.hiresweet.com&prompt=consent&include_granted_scopes=true&client_id=439166704549-uc6i7s0gh7blevsl8126it20gu4cbe8g.apps.googleusercontent.com&gsiwebsdk=2&hd=hiresweet.com&as=S-66874957%3A1664955877975020&flowName=GeneralOAuthFlow'
            target='_blank'
            rel='noreferrer noopener'
          >
            Gmail
          </a>
          &nbsp;
          <a
            href='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=715174d8-525e-43c5-bb68-5d42bdedcf52&response_type=code&redirect_uri=https://app.hiresweet.com/msal.html&scope=user.read%20mail.readwrite%20mail.send%20openid%20profile%20offline_access&state=59fc9c663fbe3d4d96f05028&response_mode=query'
            target='_blank'
            rel='noreferrer noopener'
          >
            Outlook
          </a>
        </InfoTooltip>
      )}
    </div>
  );
};

export default LinkedinInvitationAcceptedCondition;
