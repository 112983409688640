/* eslint-disable class-methods-use-this */
import _ from 'underscore';
import {
  Context,
  EvaluatorInterface,
  BaseVariable,
  ConcatVariable,
  Variable,
  ExpressionEvaluatorFromType,
  ExternalVariableEvaluator,
} from '../../types';

export default class ExpressionConcatEvaluator implements EvaluatorInterface {
  evaluate({
    context,
    expression,
    getExpressionEvaluatorFromType,
    externalVariableEvaluator,
  }: {
    context: Context;
    expression: Variable;
    getExpressionEvaluatorFromType: ExpressionEvaluatorFromType;
    externalVariableEvaluator: ExternalVariableEvaluator;
  }): { value: string | undefined } {
    const expressionType = expression as BaseVariable & ConcatVariable;
    const parts = _.map(expressionType?.children, (subExpression) =>
      getExpressionEvaluatorFromType({ type: subExpression.type }).evaluate({
        context,
        expression: subExpression,
        getExpressionEvaluatorFromType,
        externalVariableEvaluator,
      }),
    );
    return { value: _.pluck(parts, 'value').join('') };
  }
}
