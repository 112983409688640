import gql from 'graphql-tag';
import Author from './fragments/Author';
import ProfileEnrichmentResultData from './fragments/ProfileEnrichmentResultData';
import SearchPoolProfile, {
  SearchPoolProfileMissions,
  SearchPoolProfileWithoutResumeData,
} from './fragments/SearchPoolProfile';
import TranslatableText from './fragments/TranslatableText';
import Task from './fragments/Task';

const ENRICHED_SEARCH_POOL_PROFILE = gql`
  query getEnrichedSearchPoolProfile(
    $searchPoolId: ID!
    $id: ID!
    $criteria: SearchCriteriaInput
    $explicitTasksFilter: ExplicitTasksFilter
  ) {
    searchPool(id: $searchPoolId) {
      id
      enrichedProfile(id: $id, criteria: $criteria) {
        ...SearchPoolProfile
        explicitTasks(filter: $explicitTasksFilter) {
          ...Task
        }
        recommendedMissions {
          missionId
          skipped
          recommendationDate
          arguments {
            type
            ... on ReviewArgument {
              content {
                default
                fr
                en
              }
            }
          }
        }
        missionsInfo {
          missionId
          mission {
            ... on RevealJob {
              missionCategory {
                type
              }
            }
          }
        }
        privacyState {
          markedAsDoNotContact
          reason {
            id
            title {
              ...TranslatableText
            }
          }
        }
        source {
          sourceId
          importDate
          type
          recruiter {
            ...Author
          }
          importName
          externalSourceId
          externalSourceName
        }
        contactCategory {
          type
          subtypes {
            id
          }
        }
        linkedProfilesGroup {
          id
          linkedProfileReferences {
            profileId
            title {
              ...TranslatableText
            }
            metadata {
              key
              value
            }
          }
        }
        ... on RevealProfile {
          hiresweetDetails {
            smartHunt {
              batchId
              timestamp
              items {
                key
                value
              }
            }
            matchingOffers {
              batchId
              timestamp
              items {
                offerId
                title
                url
                criteria {
                  type
                  value
                }
                score {
                  key
                  value
                }
                activity {
                  key
                  value
                }
              }
            }
            modelScores {
              timestamp
              items {
                key
                value
              }
            }
            tags {
              name {
                default
              }
            }
            marketplaceHistory {
              timestamp
              items {
                key
                value
              }
            }
          }
          company {
            id
            name
          }
        }
      }
    }
  }
  ${SearchPoolProfile}
  ${Author}
  ${Task}
  ${TranslatableText}
`;

const ENRICHED_SEARCH_POOL_PROFILE_WITHOUT_RESUME_DATA = gql`
  query getEnrichedSearchPoolProfileWihoutResumeData(
    $searchPoolId: ID!
    $id: ID!
    $criteria: SearchCriteriaInput
    $explicitTasksFilter: ExplicitTasksFilter
  ) {
    searchPool(id: $searchPoolId) {
      id
      enrichedProfile(id: $id, criteria: $criteria) {
        ...SearchPoolProfile
        explicitTasks(filter: $explicitTasksFilter) {
          ...Task
        }
      }
    }
  }
  ${SearchPoolProfileWithoutResumeData}
  ${Task}
`;

const ENRICHED_SEARCH_POOL_PROFILE_MISSIONS = gql`
  query getEnrichedSearchPoolProfileMissions(
    $searchPoolId: ID!
    $id: ID!
    $criteria: SearchCriteriaInput
  ) {
    searchPool(id: $searchPoolId) {
      id
      enrichedProfile(id: $id, criteria: $criteria) {
        ...SearchPoolProfile
      }
    }
  }
  ${SearchPoolProfileMissions}
`;

const ENRICHED_SEARCH_POOL_PROFILE_ENRICHMENT_RESULTS = gql`
  query getEnrichedSearchPoolProfileEnrichementResults(
    $searchPoolId: ID!
    $id: ID!
    $criteria: SearchCriteriaInput
  ) {
    searchPool(id: $searchPoolId) {
      id
      enrichedProfile(id: $id, criteria: $criteria) {
        id
        resumeData {
          sourceData {
            contactout {
              ...ProfileEnrichmentResultData
            }
          }
        }
      }
    }
  }
  ${ProfileEnrichmentResultData}
`;

const ENRICHED_SEARCH_POOL_PROFILE_SOURCES = gql`
  query getEnrichedSearchPoolProfileEnrichementResults(
    $searchPoolId: ID!
    $id: ID!
    $criteria: SearchCriteriaInput
  ) {
    searchPool(id: $searchPoolId) {
      id
      enrichedProfile(id: $id, criteria: $criteria) {
        id
        resumeData {
          firstname
          lastname
          headline {
            content {
              text
            }
          }
          email
          sources {
            linkedin
            github
            stackoverflow
            salesNavigator
          }
        }
      }
    }
  }
`;

export {
  ENRICHED_SEARCH_POOL_PROFILE_WITHOUT_RESUME_DATA,
  ENRICHED_SEARCH_POOL_PROFILE_ENRICHMENT_RESULTS,
  ENRICHED_SEARCH_POOL_PROFILE_SOURCES,
  ENRICHED_SEARCH_POOL_PROFILE_MISSIONS,
};

export default ENRICHED_SEARCH_POOL_PROFILE;
