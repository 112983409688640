import { QueryResult, useQuery } from '@apollo/client';
import { IClientPermissions } from '@/graphql/fragments/ClientPermissions';
import {
  GET_CLIENT_PERMISSIONS,
  GetClientPermissionsResult,
  GetClientPermissionsVariables,
} from '../../clients';

const useClientPermissions = (
  clientId: string,
): QueryResult<GetClientPermissionsResult, GetClientPermissionsVariables> & {
  permissions?: IClientPermissions;
} => {
  const { data, ...rest } = useQuery<
    GetClientPermissionsResult,
    GetClientPermissionsVariables
  >(GET_CLIENT_PERMISSIONS, {
    variables: { clientId },
  });

  if (clientId === 'ai-seq-demo') {
    return {
      data,
      ...rest,
      permissions: {
        watchCollect: false,
        watchCollectV2: false,
        skipWithoutReason: false,
        skipWithoutModal: false,
        canEditUserDescription: false,
        integrations: false,
        secondFollowup: false,
        analytics: false,
        maxActiveOffersNumber: 0,
        maxNbFollowups: 0,
        termsOfService: false,
        csvImportAndExport: false,
        profileEvaluation: false,
        canEvaluatePendingProfiles: false,
        careerPage: false,
        reveal: false,
        revealAnalytics: false,
        hideEndorsements: false,
        crmMarketing: false,
        crmPages: false,
        advancedTemplating: false,
        allUsersCanEditRevealAtsConfiguration: false,
        allUsersCanEditCustomArchiveReasons: false,
        diversityAnalytics: false,
        dataProviderIntegrations: false,
        marketplaceSplitPending: false,
        marketplaceUnifiedView: false,
        aiSequenceGeneration: false,
        aiSequenceGenerationSidebar: true,
        nextAvailability: false,
        customPipelines: false,
        canEditAutomationIntegrations: false,
        canEditCustomTasks: false,
        canFilterProjectProfiles: false,
        salaryBenchmark: false,
        hardFilters: false,
        unipile: false,
        salesProjects: false,
        whatsappSequenceActions: false,
        aiTokenGeneration: false,
        aiTokenExperimental: false,
        aiRecoBlurb: false,
        projectDynamicImports: false,
        positionDescription: false,
        canScheduleAtBulkEnrollment: false,
        canBulkInstantiateAiMergeTags: false,
      },
    };
  }

  const permissions = data?.client?.permissions;
  return {
    data,
    permissions,
    ...rest,
  };
};

export default useClientPermissions;
