import _ from 'underscore';
import { ConnectorRejectionReason, RevealProject } from '@/common/reveal';
import getFilterOptions, { FilterOptions } from '../Filters/options';

const getApplicationStatusOptions = (
  rejectionReasons?: ConnectorRejectionReason[],
) => {
  return [
    {
      id: 'active',
      name: 'Active',
    },
    {
      id: 'hired',
      name: 'Hired',
    },
    {
      id: 'rejected',
      sourceId: 'rejected',
      name: 'Archived',
      subOptions: _.map(rejectionReasons || [], (item) => ({
        id: item.id,
        name: item.name,
      })),
    },
  ];
};

const getLeverOptions = ({
  projectId,
  projectsWithFilterOptions,
}: {
  projectId: string;
  projectsWithFilterOptions: RevealProject[];
}): FilterOptions => {
  return getFilterOptions({
    connectorType: 'lever',
    projectId,
    projectsWithFilterOptions,
    getApplicationStatusOptions,
  });
};

export default getLeverOptions;
