import gql from 'graphql-tag';

const SEARCH_POOL_MINI_PROFILE_BY_TEXT_RESULTS = gql`
  query getMiniProfileFromText(
    $searchPoolId: ID!
    $input: SearchProfilesByTextInput
  ) {
    searchPool(id: $searchPoolId) {
      id
      searchProfilesByText(input: $input) {
        resumeData {
          firstname
          lastname
          headline {
            content {
              text
            }
          }
          photoLink
        }
        id
        missionsInfo {
          mission {
            id
            data {
              title
            }
          }
        }
      }
    }
  }
`;

export default SEARCH_POOL_MINI_PROFILE_BY_TEXT_RESULTS;
