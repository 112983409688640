import { useHistory } from 'react-router-dom';
import qs from 'qs';

type SetParamsFnOptions = { method: 'push' | 'replace' };
type SetParamsFn<Params> = (
  params: Partial<Params>,
  options?: SetParamsFnOptions,
) => void;
type LocationParamsBag<Params> = Params & {
  /**
   * Change existing params without removing existing ones
   *
   * @param options.method Either 'push' or 'replace'
   */
  setParams: SetParamsFn<Params>;
  putParams: SetParamsFn<Params>;
};

/**
 * Helpers to change specific query params
 */
export default function useQueryParams<Params>({
  defaults,
}: { defaults?: Partial<Params> } = {}): LocationParamsBag<Params> {
  const history = useHistory();
  const { location } = history;
  const currentParams = qs.parse(location.search.substring(1));

  const setParams = (params: Partial<Params>, options?: SetParamsFnOptions) => {
    const { method = 'push' } = options || {};
    const newParams = qs.stringify({ ...currentParams, ...params });

    history[method](`${location.pathname}?${newParams}`);
  };

  const putParams = (params: Partial<Params>, options?: SetParamsFnOptions) => {
    const { method = 'push' } = options || {};
    const newParams = qs.stringify({ ...params });

    history[method](`${location.pathname}?${newParams}`);
  };
  return {
    ...defaults,
    ...((currentParams as unknown) as Params),
    setParams,
    putParams
  };
}
