import _ from 'underscore';
import createActionInputFromAction from './createActionInputFromAction';

const getContactFlowInput = ({ contactFlow, removePosition = false }) => {
  return {
    ..._.omit(contactFlow, 'id', 'versionId', '__typename', 'linkedMissionId'),
    sequences: _.map(contactFlow.sequences, (contactFlowSequence) => ({
      ..._.omit(
        contactFlowSequence,
        'id',
        'completion',
        'isCurrent',
        'isDraft',
        'name',
        '__typename',
        'linkedMissionId',
        removePosition ? 'position' : undefined,
      ),
      actions: _.map(contactFlowSequence.actions, (action) =>
        createActionInputFromAction({ action }),
      ),
    })),
  };
};

export default getContactFlowInput;
