import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Table, Loader } from 'semantic-ui-react';
import withClientCollaborators from '../../hocs/users/withClientCollaborators';
import withAddCollaborator from '../../hocs/users/withAddCollaborator';
import withUserFromJWToken from '../../hocs/users/withUserFromJWToken';
import withActionLogger from '../../hocs/withActionLogger';

const CollaboratorRow = ({ firstname, lastname, email, status }) => {
  return (
    <Table.Row>
      <Table.Cell>
        <span>{`${firstname || ''} ${lastname || ''}`}</span>
      </Table.Cell>
      <Table.Cell>
        <span>{email}</span>
      </Table.Cell>
      <Table.Cell>
        <span>{status}</span>
      </Table.Cell>
    </Table.Row>
  );
};

const EmptyUsersDashboard = ({ clientId, t }) => (
  <div className='users dashboard-section'>
    <div className='header'>
      <h1>
        <Link to={`/client/${clientId}/users`}>
          {t('collaborators.header')}
        </Link>
      </h1>
    </div>
    <div className='no-items-container'>
      <img
        src='/images/placeholders/users.svg'
        height='155'
        alt='users-placeholder'
      />
      <div className='no-items'>{t('dashboard.users.noUsers')}</div>
    </div>
  </div>
);

const UsersDashboard = ({
  clientId,
  collaborators,
  collaboratorsLoading,
  userFromJWTokenLoading,
  addUser,
  t,
  user,
  onLogAction,
}) => {
  const [seeAll, setSeeAll] = useState(false);

  const logAction = ({ type }) => {
    onLogAction({
      type,
      info: {
        clientId,
        from: 'users-dashboard',
      },
    });
  };

  if (collaboratorsLoading || userFromJWTokenLoading) {
    return (
      <div className='users dashboard-section'>
        <div className='header'>
          <h1>
            <Link to={`/client/${clientId}/users`}>
              {t('collaborators.header')}
            </Link>
          </h1>
        </div>
        <div className='users-table-placeholder'>
          <Loader active inline='centered' size='large' />
        </div>
      </div>
    );
  }

  const N_DEFAULT_DISPLAYED_USERS = 10;

  const isAutopilotUser = (collaborator) => {
    const { email = '' } = collaborator || {};
    return email.startsWith('autopilot') && email.endsWith('@hiresweet.com');
  };

  const regularUsers = _.reject(collaborators, isAutopilotUser);
  const filteredUsers = _.filter(regularUsers, ({ id }) => id !== user.id);
  const sortedUsers = _.sortBy(
    filteredUsers,
    ({ firstname, lastname }) => (firstname || '') + (lastname || ''),
  );
  const displayedUsers = _.first(
    sortedUsers,
    seeAll ? sortedUsers.length : N_DEFAULT_DISPLAYED_USERS,
  );

  if (_.isEmpty(displayedUsers)) {
    return <EmptyUsersDashboard clientId={clientId} t={t} />;
  }
  return (
    <div className='users dashboard-section'>
      <div className='header'>
        <h1>
          <Link to={`/client/${clientId}/users`}>
            {t('collaborators.header')}
          </Link>
        </h1>
      </div>
      <Table basic style={{ border: 'none' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <span>{t('dashboard.users.name').toUpperCase()}</span>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <span>{t('dashboard.users.email').toUpperCase()}</span>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <span>{t('dashboard.users.status').toUpperCase()}</span>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(displayedUsers, (collaborator, index) => (
            <CollaboratorRow
              key={collaborator.id}
              firstname={collaborator.firstname || t('dashboard.users.user')}
              lastname={collaborator.lastname || index + 1}
              email={collaborator.email}
              status={getStatus(collaborator, t)}
            />
          ))}
        </Table.Body>
      </Table>
      {sortedUsers.length > N_DEFAULT_DISPLAYED_USERS && !seeAll && (
        <div
          className='see-all-link-container'
          onClick={() => {
            setSeeAll(true);
            logAction({ type: 'click-see-all-collaborators-link' });
          }}
        >
          {t('dashboard.users.seeAll')}
        </div>
      )}
    </div>
  );
};

const getStatus = (user, t) => {
  const { status = '' } = user || {};
  if (status === 'pending') {
    return t('dashboard.users.pending');
  }
  if (status === 'active') {
    return t('dashboard.users.active');
  }
  return '';
};

export default compose(
  withUserFromJWToken,
  withClientCollaborators,
  withAddCollaborator,
  withActionLogger,
  withTranslation('translations'),
)(UsersDashboard);
