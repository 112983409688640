export const getLeverApplicationStatusText = ({
  sourceStatusId,
  stageName,
  lastStageName,
  rejectionReason,
}: {
  sourceStatusId?: string;
  stageName?: string;
  lastStageName?: string;
  rejectionReason?: string;
}): string => {
  if (sourceStatusId !== 'rejected') {
    return (
      stageName ||
      lastStageName ||
      (sourceStatusId === 'hired' ? 'Hired' : '') ||
      '??'
    );
  }
  return `Archived${rejectionReason ? ` - ${rejectionReason}` : ''} ${
    lastStageName ? ` (${lastStageName})` : ''
  }`;
};

export const getGenericApplicationStatusText = ({
  sourceStatusId,
  stageName,
  lastStageName,
  rejectionReason,
}: {
  sourceStatusId?: string;
  stageName?: string;
  lastStageName?: string;
  rejectionReason?: string;
}): string => {
  if (sourceStatusId !== 'rejected') {
    return (
      stageName ||
      lastStageName ||
      (sourceStatusId === 'hired' ? 'Hired' : '') ||
      '??'
    );
  }
  return `Archived${rejectionReason ? ` - ${rejectionReason}` : ''} ${
    lastStageName ? ` (${lastStageName})` : ''
  }`;
};

export const getSmartrecruitersApplicationStatusText = ({
  sourceStatusId,
  stageName,
  rejectionReason,
}: {
  sourceStatusId?: string;
  stageName?: string;
  rejectionReason?: string;
}): string => {
  const status =
    stageName ||
    ((sourceStatusId || '').length < 30
      ? (sourceStatusId || '').charAt(0).toUpperCase() +
        (sourceStatusId || '').substring(1)
      : null) ||
    '??';
  if (sourceStatusId === 'rejected' && rejectionReason) {
    return `${status} (${rejectionReason})`;
  }
  return status;
};

export const getSuccessFactorsApplicationStatusText = ({
  sourceStatusId,
  stageName,
  rejectionReason,
}: {
  sourceStatusId?: string;
  stageName?: string;
  rejectionReason?: string;
}): string => {
  if (sourceStatusId !== 'rejected') {
    return (
      stageName ||
      (sourceStatusId === 'hired' ? 'Hired' : '') ||
      '??'
    );
  }
  return `Archived${rejectionReason ? ` - ${rejectionReason}` : ''}`;
};