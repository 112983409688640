import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { Container, Input } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import EmailEditor from 'react-email-editor';
import { useHistory, useLocation } from 'react-router-dom';

import contextToProps from '@/hocs/contextToProps';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { useLockHistory } from '@/context/LockHistoryContext';
import GenericButton from '@/components/Common/GenericButton';
import CampaignLayout from '../CampaignLayout';
import CampaignsSubHeader from '../CampaignsSubHeader/CampaignsSubHeader';
import {
  CLIENT_CAMPAIGNS,
  CREATE_CAMPAIGN,
  useClientCampaignTemplates,
} from '../queries';
import CancelButton from '../CampaignsSubHeader/CancelButton';

import './CampaignPage.css';

const CreateCampaignPage = ({ clientId }) => {
  const history = useHistory();
  const notification = useNotificationSystem();
  const { t } = useTranslation();
  // FORM PROPS
  const [campaignTitle, setCampaignTitle] = useState(
    t('campaigns.titlePlaceholder'),
  );
  const [campaignDescription, setCampaignDescription] = useState();
  const [campaignSubject, setCampaignSubject] = useState('');

  const URLQueryParams = new URLSearchParams(useLocation().search);
  const campaignTemplateId = URLQueryParams.get('campaignTemplateId');

  const emailEditorRef = useRef(null);

  const { clientCampaignTemplates } = useClientCampaignTemplates({
    clientId,
  });
  const campaignTemplate = _.findWhere(clientCampaignTemplates, {
    id: campaignTemplateId,
  });

  const { unlockHistory } = useLockHistory();

  const updateAfterMutation = (
    cache,
    { data: { campaign: campaignMutationData } },
  ) => {
    const data = cache.readQuery({
      query: CLIENT_CAMPAIGNS,
      variables: { clientId },
    });
    const newData = {
      client: {
        ...data.client,
        campaigns: [
          ...(data?.client?.campaigns || []),
          campaignMutationData?.create?.campaign,
        ],
      },
    };
    cache.writeQuery({
      query: CLIENT_CAMPAIGNS,
      data: newData,
    });
  };

  useEffect(() => {
    if (!_.isEmpty(campaignTemplate)) {
      setCampaignTitle(
        campaignTemplate?.title || t('campaigns.titlePlaceholder'),
      );
      setCampaignSubject(campaignTemplate?.subject);
      setCampaignDescription(campaignTemplate?.description);
    }
    // eslint-disable-next-line
  }, [campaignTemplate]);

  // MUTATION update campaign
  const [createCampaign, { data: createCampaignData }] = useMutation(
    CREATE_CAMPAIGN,
    {
      update: updateAfterMutation,
    },
  );

  useEffect(() => {
    if (createCampaignData) {
      const newCampaignid = createCampaignData.campaign.create.campaign.id;
      history.push(`/client/${clientId}/reveal/campaigns/${newCampaignid}`);
    }
    // eslint-disable-next-line
  }, [createCampaignData]);

  // SUBMIT
  const onSubmit = async ({ design, html }) => {
    await createCampaign({
      variables: {
        input: {
          title: campaignTitle,
          subject: campaignSubject,
          description: JSON.stringify(design),
          descriptionHTML: html,
        },
      },
    });
  };

  const breadcrumbs = [
    {
      text: t('campaigns.breadcrumbs.campaigns'),
      link: `/client/${clientId}/reveal/campaigns`,
    },
    {
      text: t('campaigns.breadcrumbs.createCampaign'),
      link: `/client/${clientId}/reveal/campaigns/new-campaign-create`,
    },
  ];

  const onValidate = {
    text: t('campaigns.buttons.create'),
    clickHandler: async ({ design, html }) => {
      unlockHistory();
      try {
        await onSubmit({ design, html });
        notification.success(t('campaigns.new.campaignCreated'));
      } catch (e) {
        notification.error(t('campaigns.new.campaignCreateError'));
        console.error(e);
      }
    },
  };

  const exportHtml = async () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      onValidate.clickHandler({ design, html });
    });
  };

  useEffect(() => {
    if (!campaignDescription || !emailEditorRef?.current?.editor) {
      return;
    }
    setTimeout(() => {
      const templateJson = JSON.parse(campaignDescription);
      emailEditorRef.current.editor.loadDesign(templateJson);
    }, 500);
  }, [campaignDescription]);

  return (
    <CampaignLayout
      subHeader={
        <CampaignsSubHeader breadcrumbs={breadcrumbs}>
          <CancelButton />
          <GenericButton
            size='big'
            disabled={_.isEmpty(campaignTitle) || _.isEmpty(campaignSubject)}
            onClick={() => exportHtml()}
          >
            {onValidate.text}
          </GenericButton>
        </CampaignsSubHeader>
      }
    >
      <Container className='new-campaign-edit'>
        <h3 className='input-container'>
          <Input
            className='campaign-title-input'
            fluid
            placeholder={t('campaigns.title')}
            value={campaignTitle}
            onChange={(ev) => setCampaignTitle(ev.target.value)}
          />
        </h3>
        <div>
          <Input
            className='campaign-subject-input'
            fluid
            placeholder={t('campaigns.subjectPlaceholder')}
            value={campaignSubject || ''}
            onChange={(ev) => setCampaignSubject(ev.target.value)}
          />
        </div>
        <div className='editor-container'>
          <EmailEditor
            ref={emailEditorRef}
            style={{ height: '100%' }}
            options={{ displayMode: 'email' }}
          />
        </div>
      </Container>
    </CampaignLayout>
  );
};

export default _.compose(contextToProps)(CreateCampaignPage);
