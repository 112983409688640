import { TFunction } from 'i18next';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'underscore';

import { ContactOperator } from './types';

import './CustomCriteriaFilters.css';

const CriteriaContact = (props: {
  onSelectedOperatorChange: (operator: ContactOperator) => void;
  selectedOperator: ContactOperator[];
  value: Record<ContactOperator, string[]>;
  onValueChange: (
    value: Record<ContactOperator, string[]>,
    operator?: ContactOperator[],
  ) => void;
  onClose: () => void;
  t: TFunction;
}) => {
  const {
    onSelectedOperatorChange,
    selectedOperator,
    value,
    onValueChange,
    onClose,
    t,
  } = props;

  const onChange = (key: ContactOperator) => (ev: any, data: DropdownProps) => {
    const newValue = { ...value };
    const valueDropdown = data.value as string[];
    newValue[key] = valueDropdown;
    let newOperator = [...selectedOperator];

    // set or reset operator on selectino
    if (!selectedOperator.includes(key)) {
      newOperator = [...selectedOperator, key];
    }

    if (valueDropdown?.length === 0 && selectedOperator.includes(key)) {
      newOperator = newOperator.filter((op) => op !== key);
    }

    onValueChange(newValue, newOperator);
  };

  return (
    <div className='property-details-wrapper'>
      {/* Hold for simplicity of the form
      <div className='radio-wrapper'>
        <div className='radio-row' onClick={() => { onSelectedOperatorChange('all-filled'); }}>
          <Checkbox
            className='checkbox'
            checked={selectedOperator.includes('all-filled')}
          />
          <div className='label'>{t('reveal.searchView.search.allFilled')}</div>
        </div>
        <Dropdown
          selection
          multiple
          options={getOptions(t)}
          placeholder={t('reveal.searchView.search.contactPlaceholder')}
          onChange={onChange('all-filled')}
          value={value['all-filled'] ?? []}
          className='dropdown-contact'
          upward
        />
      </div>
      */}
      <div className='radio-wrapper'>
        <div className='radio-row'>
          <div className='label'>{t('reveal.searchView.search.anyFilled')}</div>
        </div>
        <Dropdown
          selection
          multiple
          options={getOptions(t)}
          placeholder={t('reveal.searchView.search.contactPlaceholder')}
          onChange={onChange('any-filled')}
          value={value['any-filled'] ?? []}
          className='dropdown-contact'
          upward
          onClick={() => {
            onSelectedOperatorChange('any-filled');
          }}
        />
      </div>
      {/*
      <div className='radio-wrapper'>
        <div className='radio-row' onClick={() => { onSelectedOperatorChange('any-missing'); }}>
          <Checkbox
            className='checkbox'
            checked={selectedOperator.includes('any-missing')}
          />
          <div className='label'>{t('reveal.searchView.search.anyMissing')}</div>
        </div>
        <Dropdown
          selection
          multiple
          options={getOptions(t)}
          placeholder={t('reveal.searchView.search.contactPlaceholder')}
          onChange={onChange('any-missing')}
          value={value['any-missing'] ?? []}
          className='dropdown-contact'
          upward
        />
      </div>
      */}
      <div className='radio-wrapper'>
        <div className='radio-row'>
          <div className='label'>
            {t('reveal.searchView.search.allMissing')}
          </div>
        </div>
        <Dropdown
          selection
          multiple
          options={getOptions(t)}
          placeholder={t('reveal.searchView.search.contactPlaceholder')}
          onChange={onChange('all-missing')}
          value={value['all-missing'] ?? []}
          className='dropdown-contact'
          upward
          onClick={() => onSelectedOperatorChange('all-missing')}
        />
      </div>
      <div className='save-button' onClick={onClose}>
        {t('reveal.searchView.search.save')}
      </div>
    </div>
  );
};

const getOptions = (t: TFunction) => [
  {
    text: t('reveal.searchView.search.email'),
    value: 'email',
  },
  {
    text: t('reveal.searchView.search.phoneNumber'),
    value: 'phoneNumber',
  },
  {
    text: t('reveal.searchView.search.linkedin'),
    value: 'linkedin',
  },
  {
    text: t('reveal.searchView.search.github'),
    value: 'github',
  },
  {
    text: t('reveal.searchView.search.stackoverflow'),
    value: 'stackoverflow',
  },
];

export default compose(withTranslation('translations'))(CriteriaContact);
