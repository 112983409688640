import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PlusIcon } from '@/assets/icons';
import MissionCategoryContext from '@/context/MissionCategoryContext';

import styles from './MissionDropdownEmptyState.module.less';

interface MissionDropdownEmptyStateProps {
  clientId: string;
  isGroup?: boolean;
}

const EMTPY_STATE_TRANSLATIONS = {
  all: {
    title: 'reveal.missions.emptyState.title',
    content: 'reveal.missions.emptyState.innerContent',
  },
  group: {
    title: 'reveal.missions.emptyStateGroup.title',
    content: 'reveal.missions.emptyStateGroup.innerContent',
  },
};

const MissionDropdownEmptyState: React.FC<MissionDropdownEmptyStateProps> = ({
  clientId,
  isGroup = false,
}) => {
  const { t } = useTranslation();
  const translations = isGroup
    ? EMTPY_STATE_TRANSLATIONS.group
    : EMTPY_STATE_TRANSLATIONS.all;
  const missionCategory = useContext(MissionCategoryContext);
  return (
    <div className={styles.container}>
      <img
        src='/images/placeholders/projectsEmptyState.svg'
        alt='Empty missions'
      />
      <div className={styles.descriptionContainer}>
        <p className={styles.title}>{t(translations.title)}</p>
        <p className={styles.description}>{t(translations.content)}</p>
        {!isGroup && (
          <Link
            className={styles.ctaContainer}
            to={`/client/${clientId}/reveal/projects/${missionCategory}?newJobModal=true`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <PlusIcon />
            {t('reveal.missions.emptyState.cta')}
          </Link>
        )}
      </div>
    </div>
  );
};

export default MissionDropdownEmptyState;
