import React, { FC } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import { StackItem } from '@/types/statistics/stackItem';
import { NumberRecord, Stats } from '@/types/statistics/stats';
import { keyInStack, sumNumberRecords } from '@/common/helpers/stats';
import { ticksByFrequency } from '../../utils';
import { ChartType, Frequency, Mode } from '../GraphWrapper/types';
import { TimeSpan } from '../RevealAnalyticsHeader/RevealAnalyticsHeader';
import VerticalStackedBarChart from '../VerticalStackedBarChart';
import LineGraph from '../LineGraph/LineGraph';
import StackedAreaChart from '../StackedAreaChart';
import getDayRange from './getDayRange';
import GraphEmptyState from '../GraphEmptyState';

import './RevealAnalyticsGraph.css';

interface RevealAnalyticsGraphProps {
  data: any[];
  frequency: Frequency;
  timeSpan: TimeSpan;
  mode: Mode;
  stack: StackItem[];
  emptyValue: NumberRecord;
  type: ChartType;
  initialValues?: NumberRecord;
  fetchDetails?: (startDay: string, endDay: string, datakey: string) => void;
  exhaustive?: boolean;
}

const RevealAnalyticsGraph: FC<RevealAnalyticsGraphProps> = ({
  data,
  timeSpan,
  frequency,
  mode,
  stack,
  emptyValue,
  type,
  initialValues,
  fetchDetails,
  exhaustive,
}) => {
  const { t } = useTranslation();

  if (_.isEmpty(data)) {
    return <GraphEmptyState />;
  }

  const ticks = ticksByFrequency({
    firstDataDate: (data[0] || {}).timestamp,
    startDate: timeSpan.startDate,
    endDate: timeSpan.endDate,
    frequency,
  });

  const dataIndexedByTimestamp = _.indexBy(data, 'timestamp');

  // fill empty ticks
  const fullData = ticks
    ? _.map(ticks, (tick, index) => {
        if (dataIndexedByTimestamp[tick]) {
          return {
            ...emptyValue,
            ...dataIndexedByTimestamp[tick],
            timestamp: dataIndexedByTimestamp[tick].timestamp ?? '',
            index,
          };
        }
        return {
          frequency,
          timestamp: tick,
          index,
          ...emptyValue,
        };
      })
    : data;

  const keysWithData = stack
    .map((s) => s.datakey)
    .filter((key) => fullData.some((tick: any) => tick[key] > 0));
  const stackWithData = stack.filter((s) => keysWithData.includes(s.datakey));

  const formattedStats: Stats[] = _.map(fullData, (item) => ({
    name: t('analytics.dateFormats.day', { date: new Date(item.timestamp) }),
    values: _.reduce(
      item,
      (values, value, key) =>
        keyInStack(stack, key) ? { ...values, [key]: value } : values,
      {},
    ),
    clickListeners: _.reduce(
      item,
      (values, _value, key) => {
        const [startDay, endDay] = getDayRange(item.timestamp, frequency);
        return keyInStack(stack, key)
          ? {
              ...values,
              [key]: fetchDetails
                ? () => fetchDetails(startDay, endDay, key)
                : undefined,
            }
          : values;
      },
      {},
    ),
  }));

  if (type === 'area') {
    const aggregatedStats: Stats[] = _.reduce(
      formattedStats,
      (aggregator, { name, values }) => {
        const lastValues = _.last(aggregator)?.values || initialValues || {};
        return [
          ...aggregator,
          { name, values: sumNumberRecords(lastValues, values) },
        ];
      },
      [] as Stats[],
    );

    return (
      <StackedAreaChart
        stack={stack}
        allStats={aggregatedStats}
        initialValues={initialValues}
        isPercentage={mode === 'percentage'}
        exhaustive={exhaustive}
      />
    );
  }

  if (type === 'bar') {
    return (
      <VerticalStackedBarChart
        stack={stack}
        allStats={formattedStats}
        isPercentage={mode === 'percentage'}
        exhaustive={exhaustive}
      />
    );
  }

  if (type === 'line') {
    return (
      <LineGraph
        mode={mode}
        stack={stack}
        ticks={ticks}
        stackWithData={stackWithData}
        frequency={frequency}
        fullData={fullData}
        allStats={formattedStats}
      />
    );
  }

  return <></>;
};

export default RevealAnalyticsGraph;
