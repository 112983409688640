import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const mutation = gql`
  mutation cancelInvite($clientId: ID!, $email: String!) {
    cancelInvite(clientId: $clientId, email: $email) {
      id
    }
  }
`;

export default graphql(mutation, {
  props: ({ ownProps, mutate }) => ({
    cancelInvite: ({ email }) =>
      mutate({
        variables: {
          clientId: ownProps.clientId,
          email,
        },
      }),
  }),
});
