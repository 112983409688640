import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'underscore';
import { FieldCondition, IfStatement } from '@/common/mergeTags/utils';

import { CustomFieldDefinitionEnum } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import ContextMenu from '@/components/Tables/ContextMenu/ContextMenu';
import styles from '../ConditionsChaining.module.less';
import IfStatementValueEditor from './IfStatementValueEditor';
import useConditionsChainingCustomFields from '../useConditionsChainingCustomFields';
import { useConditionsChainingContext } from '../useConditionsChainingContext';
import IfStatementValueSimpleInput from './IfStatementValueSimpleInput';
import { getStatementTextMode, StatementInputMode } from '../utils';

const IfStatementSimplifiedForm: React.FC<{
  ifStatement: IfStatement;
}> = ({ ifStatement }) => {
  const { t } = useTranslation();
  const { clientId } = useConditionsChainingContext();
  const {
    profileCustomFields,
    missionCustomFields,
    profileCustomFieldsMap,
    missionCustomFieldsMap,
  } = useConditionsChainingCustomFields({ clientId });
  const [inputMode, setInputMode] = useState<StatementInputMode>(
    getStatementTextMode(ifStatement.result.text),
  );

  const condition = ifStatement.condition as FieldCondition;

  const fieldLabel = useMemo(() => {
    if (condition.fieldCategory === 'native') {
      return t(
        `editor.conditionsChainingVariable.constructionOptions.${condition.fieldId}`,
      );
    }
    if (condition.fieldCategory === 'custom-field') {
      return profileCustomFieldsMap[condition.customFieldId].title;
    }
    if (condition.fieldCategory === 'linked-mission-custom-field') {
      return missionCustomFieldsMap[condition.customFieldId].title;
    }
    return undefined;
  }, [condition, profileCustomFieldsMap, missionCustomFieldsMap, t]);

  const selectOption = useMemo(() => {
    if (
      condition.fieldType !== 'select' ||
      condition.accept.type !== 'string-equals'
    ) {
      return undefined;
    }
    if (
      condition.fieldCategory === 'native' &&
      condition.fieldId === 'gender'
    ) {
      return t(
        `reveal.reports.diversity.genders.${condition.accept.targetText}`,
      );
    }
    if (condition.fieldCategory === 'custom-field') {
      const customField = _.findWhere(profileCustomFields, {
        id: condition.customFieldId,
      }) as CustomFieldDefinitionEnum | undefined;
      return _.findWhere(customField?.options || [], {
        id: condition.accept.targetText,
      })?.title?.default;
    }
    if (condition.fieldCategory === 'linked-mission-custom-field') {
      const customField = _.findWhere(missionCustomFields, {
        id: condition.customFieldId,
      }) as CustomFieldDefinitionEnum | undefined;
      return _.findWhere(customField?.options || [], {
        id: condition.accept.targetText,
      })?.title?.default;
    }
    return undefined;
  }, [condition, t, missionCustomFields, profileCustomFields]);

  const contextMenuAction = useMemo(() => {
    if (inputMode === 'basic-input') {
      return {
        label: t('editor.conditionsChainingVariable.switchToAdvancedEditor'),
        onClick: () => setInputMode('rich-editor'),
      };
    }
    return null;
  }, [inputMode, t]);

  return (
    <div
      className={classNames(
        styles.statementWrapper,
        styles.simplifiedStatementWrapper,
      )}
    >
      <div className={styles.ifStatementHeader}>
        <h4>
          {t('editor.conditionsChainingVariable.if')} {fieldLabel}{' '}
          {t(
            `editor.conditionsChainingVariable.acceptConditionOptions.${condition.accept.type}`,
          ).toLowerCase()}{' '}
          {selectOption && <>{selectOption}</>}
        </h4>
      </div>
      <div className={styles.simplifiedStatementFieldWrapper}>
        {inputMode === 'basic-input' ? (
          <IfStatementValueSimpleInput ifStatement={ifStatement} />
        ) : (
          <IfStatementValueEditor ifStatement={ifStatement} />
        )}
        {contextMenuAction && (
          <div className={styles.changeInputModeWrapper}>
            <ContextMenu
              popupClassName={styles.popup}
              popupContainerStyle={{ maxWidth: '300px' }}
              actions={[contextMenuAction]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default IfStatementSimplifiedForm;
