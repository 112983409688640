import {
  FetchResult,
  MutationHookOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback } from 'react';

import { CuratedAutoSendConfig } from '@/types/curatedAutoSend';

const updateCuratedAutoSend = gql`
  mutation updateCuratedAutoSend(
    $offerId: ID!
    $input: CuratedAutoSendConfigInput!
  ) {
    updateCuratedAutoSend(id: $offerId, input: $input) {
      id
      curatedAutoSend {
        isEnabled
        senderEmail
      }
    }
  }
`;

type Data = {
  offer: {
    id: string;
    curatedAutoSend?: CuratedAutoSendConfig;
  };
};

type Variables = {
  offerId: string;
  input: CuratedAutoSendConfig;
};

type Input = {
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

type Result = [
  (offerId: string, input: CuratedAutoSendConfig) => Promise<FetchResult<Data>>,
  MutationResult<Data>,
];

function useUpdateCuratedAutoSend({
  mutationOptions = {},
}: Input = {}): Result {
  const [mutation, result] = useMutation<Data, Variables>(
    updateCuratedAutoSend,
    mutationOptions,
  );

  const update = useCallback(
    (offerId: string, input: CuratedAutoSendConfig) =>
      mutation({ variables: { offerId, input } }),
    [mutation],
  );

  return [update, result];
}

export default useUpdateCuratedAutoSend;
