import React from 'react';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import withClientStatistics from '../../../../../hocs/clients/withClientStatistics';
import ActivityGraph from './ActivityGraph';

import {
  aggregateEvents,
  filterEventsByDateRange,
  formatActivityData,
  // nDaysAgo,
  sameDayNMonthsAgo,
  // scoreFromStats,
  // regularityFromEvents,
} from '../../utils';

const ActivityDataContainer = ({
  clientId,
  offers,
  statistics,
  statisticsLoading,
  t,
}) => {
  const { dailyEvents /* , offersActivity */ } =
    (statistics || {}).offers || {};

  // Heatmap data and options
  const activityData = formatActivityData({ events: dailyEvents });

  const startDate = sameDayNMonthsAgo(12).toDate();
  const endDate = new Date();
  const options = { startDate, endDate };

  // Total contacted
  const filteredEvents = filterEventsByDateRange({
    events: dailyEvents,
    startDate,
    endDate,
  });
  const totalContacted = aggregateEvents({ events: filteredEvents }).nbSent;

  /*
  // Map job id to title
  const jobTitlesById = _.reduce(offers, (titlesById, offer) => {
    titlesById[offer.id] = offer.title;
    return titlesById;
  }, {});


  // Stats by job id (for ranking)
  let rankingData = _.map(offersActivity, ({ jobOfferId, offerCounts }) => {
    const events = filterEventsByDateRange({
      events: offerCounts,
      startDate: nDaysAgo(30).toDate(),
      endDate,
    });

    // regularity score
    const regularity = regularityFromEvents({ events });

    // ranking score
    const offerStats = aggregateEvents({ events });
    const score = scoreFromStats({ stats: offerStats, regularity });

    if (score === 0) {
      return null;
    }

    return {
      jobOfferId,
      jobTitle: jobTitlesById[jobOfferId],
      score,
      regularity,
      ...offerStats,
    };
  });

  rankingData = _.compact(rankingData);
  */
  return (
    <>
      <ActivityGraph
        activityData={activityData}
        totalContacted={totalContacted}
        options={options}
        isLoading={statisticsLoading}
        t={t}
      />
      {/*
      <ActivityRanking
        clientId={clientId}
        rankingData={rankingData}
        isLoading={statisticsLoading}
        t={t}
      />
      */}
    </>
  );
};

export default compose(
  withClientStatistics,
  withTranslation('translations'),
)(ActivityDataContainer);
