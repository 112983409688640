import React from 'react';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';

import ConfirmationModal from '@/components/modals/ConfirmationModal';
import contextToProps from '../../hocs/contextToProps';
import withRemoveFromJob from '../../hocs/searchPoolProfileState/withRemoveFromJob';

const RemoveFromJobModal = React.memo(
  ({
    searchPoolId,
    profileId,
    clientProfileId,
    jobOfferId,
    open,
    onClose,
    onShowNotification,
    removeFromJob,
    t,
  }) => {
    const handleRemoveFromJob = async () => {
      try {
        await removeFromJob({
          searchPoolId,
          profileId,
          clientProfileId,
          jobOfferId,
        });
        onShowNotification({
          message: t('watchCollect.removeFromJobModal.success'),
          level: 'success',
        });
      } catch (e) {
        onShowNotification({
          message: t('watchCollect.removeFromJobModal.error'),
          level: 'error',
        });
      }
      onClose();
    };
    return (
      <ConfirmationModal
        open={open}
        header={t('watchCollect.removeFromJobModal.title')}
        onSubmit={handleRemoveFromJob}
        onCancel={onClose}
        submit={t('watchCollect.removeFromJobModal.confirm')}
        validationType='negative'
      />
    );
  },
);

export default compose(
  withRemoveFromJob,
  contextToProps,
  withTranslation('translations'),
)(RemoveFromJobModal);
