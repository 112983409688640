import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SmallResumeData from '../../graphql/fragments/SmallResumeData';
import PipeStepStat from '../../graphql/fragments/PipeStepStat';
import Author from '../../graphql/fragments/Author';

export const refetchQuery = gql`
  query updateRestoreProfileCache(
    $offerId: ID!
    $step: String!
    $search: String!
  ) {
    offer(id: $offerId) {
      id
      pipeStepStats {
        ...PipeStepStat
      }
      profiles(step: $step, search: $search) {
        id
        resumeData {
          ...SmallResumeData
        }
        lastStepUpdateTimestamp
      }
    }
  }
  ${SmallResumeData}
  ${PipeStepStat}
`;

export const mutation = gql`
  mutation restoreProfile($id: ID!) {
    restoreProfile(id: $id) {
      id
      resumeData {
        step
        subStep
      }
      contactData {
        timeline {
          author {
            ...Author
          }
          date
          action {
            type
          }
        }
      }
      lastStepUpdateTimestamp
    }
  }
  ${Author}
`;

export default graphql(mutation, {
  props: ({ ownProps, mutate }) => ({
    restoreProfile: ({ id, offerId }) =>
      mutate({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: refetchQuery,
            variables: {
              offerId,
              step: ownProps.stepId,
              search: ownProps.searchText,
            },
          },
          {
            query: refetchQuery,
            variables: { offerId, step: ownProps.stepId, search: '' },
          },
        ],
      }),
  }),
});
