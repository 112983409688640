import _ from 'underscore';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnrichedField } from '../utils/enrichmentFallbacks';
import PluginBackgroundTimeline from './PluginBackgroundTimeline';
import styles from './PluginEducations.module.less';

type PluginEducationsProps = {
  educations: {
    degree: EnrichedField;
    startDate: any;
    endDate: any;
    schoolName: any;
  }[];
  dense?: boolean;
};

const MAX_DISPLAY_COUNT = 3;

const PluginEducations: React.FC<PluginEducationsProps> = ({
  educations,
  dense = false,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(!dense);
  const list = isOpen
    ? educations
    : educations?.slice(0, MAX_DISPLAY_COUNT) || [];

  return (
    <div className={styles.root}>
      <div className={styles.title}>{t('profile.resume.education')}</div>
      {_.map(list, (education, index) => (
        <PluginBackgroundTimeline
          key={index}
          title={education.degree}
          startDate={education.startDate}
          endDate={education.endDate}
          workplace={education.schoolName}
        />
      ))}
      {educations?.length > MAX_DISPLAY_COUNT + 1 && !isOpen && (
        <div
          className={styles.seeMore}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          See more
        </div>
      )}
    </div>
  );
};

PluginEducations.defaultProps = {
  dense: false,
};

export default PluginEducations;
