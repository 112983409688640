import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';

import ProjectSearchParamsContext from '../../JobsView/JobView/MoreContactsTab/ProjectSearchParamsContext';

const IncludeSkippedCheckbox: FC = () => {
  const { t } = useTranslation();
  const projectSearchParamsTuple = useContext(ProjectSearchParamsContext);

  if (!projectSearchParamsTuple) {
    return null;
  }

  const [params, setParams] = projectSearchParamsTuple;

  const { includeSkipped } = params;

  return (
    <LabeledCheckbox
      checked={includeSkipped}
      label={t('reveal.searchView.header.includeSkipped')}
      onClick={() => setParams({ includeSkipped: !includeSkipped })}
    />
  );
};

export default IncludeSkippedCheckbox;
