import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Author from '../../graphql/fragments/Author';
import BaseMailTemplate from '../../graphql/fragments/BaseMailTemplate';

export const query = gql`
  query getClientTemplates($clientId: ID!) {
    client(id: $clientId) {
      id
      templates {
        ...BaseMailTemplate
        author {
          ...Author
        }
      }
    }
  }
  ${Author}
  ${BaseMailTemplate}
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      clientId: ownProps.clientId,
    },
  }),
  props: ({ data: { loading, client, error } }) => ({
    loading,
    clientTemplatesAndAuthorsLoading: loading,
    templates: client && client.templates ? client.templates : [],
    error,
  }),
});
