import { useCallback, useEffect, useMemo, useState } from 'react';

interface ClientRect {
  rect: DOMRect | null;
  ref: (node: HTMLElement | null) => void;
}

const useEagerClientRect = (): ClientRect => {
  const [rect, setRect] = useState<DOMRect | null>(null);
  const [node, setNode] = useState<HTMLElement | null>(null);

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(() => {
        requestAnimationFrame(() => {
          setRect(node?.getBoundingClientRect() || null);
        });
      }),
    [node],
  );

  const ref = useCallback(
    (newNode: HTMLElement | null) => {
      if (newNode) {
        setNode(newNode);
        resizeObserver.observe(newNode);
      }
    },
    [resizeObserver],
  );

  useEffect(() => () => resizeObserver.disconnect(), [resizeObserver]);

  return { rect, ref };
};

export default useEagerClientRect;
