import _, { compose } from 'underscore';
import React from 'react';
import { withTranslation } from 'react-i18next';

import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import contextToProps from '../../../../hocs/contextToProps';
import withUserSettings from '../../../../hocs/users/withUserSettings';
import withUpdateTemplate from '../../../../hocs/templates/withUpdateTemplate';
import TemplateModal from './TemplateModal.component';

import '../Templates.css';

export class EditTemplate_ extends React.Component {
  handleSubmit = async ({ title, subject, body, snippets, isDefaultReply }) => {
    const {
      t,
      updateTemplate,
      onShowNotification,
      submitCallback,
    } = this.props;
    const snippetsInput = MergeTagsService.createSnippetsInput({
      subject,
      body,
      snippets,
    });
    await updateTemplate({
      title,
      subject,
      body,
      snippets: snippetsInput,
      isDefaultReply,
    });
    onShowNotification({
      message: t('templates.notifications.editedWithSuccess'),
      level: 'success',
    });
    if (submitCallback) {
      await submitCallback();
    }
  };

  render() {
    const {
      templateId,
      defaultTemplatesOnSeveralOffers,
      open,
      onClose,
      template,
      clientId,
      onQuitContactFlowEditor,
      t,
    } = this.props;
    const templates = defaultTemplatesOnSeveralOffers;
    const isOnMultipleTemplates =
      templates && templateId && _.indexOf(templates, templateId) > -1;
    return (
      <TemplateModal
        open={open}
        onClose={onClose}
        template={template}
        onSubmit={this.handleSubmit}
        clientId={clientId}
        onQuitContactFlowEditor={onQuitContactFlowEditor}
      >
        {isOnMultipleTemplates && (
          <p>{t('templates.createAndEditTemplate.editInfo')}</p>
        )}
      </TemplateModal>
    );
  }
}

export default compose(
  withUserSettings,
  withUpdateTemplate,
  contextToProps,
  withTranslation('translations'),
)(EditTemplate_);
