import React, { useState, useRef } from 'react';
import {
  Modal,
  ModalActions as Actions,
  ModalContent as Content,
  ModalHeader as Header,
  Image,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { QRCode } from 'react-qrcode-logo';
import html2canvas from 'html2canvas';
import { jsPDF as JsPDF } from 'jspdf';
import { ConnectPage } from '@/types/connectPage';
import useCopyToClipboard from '@/hooks/common/useCopyToClipboard';
import GenericButton from '@/components/Common/GenericButton';
import { useClientCrmDescriptor } from '@/graphql/hooks/clients/useClientCrmDescriptor';
import styles from './ConnectPageQRCodeModal.module.less';

interface ConnectQRCodeModalProps {
  open: boolean;
  connectPage: ConnectPage | null;
  closeModal: () => void;
  clientId: string;
}

const ConnectQRCodeModal: React.FC<ConnectQRCodeModalProps> = ({
  closeModal,
  open,
  connectPage,
  clientId,
}) => {
  const { t } = useTranslation();
  const [copiedPopupOpen, setCopiedPopupOpen] = useState(false);
  const { handleCopy } = useCopyToClipboard();

  const { crmDescriptor } = useClientCrmDescriptor(clientId);

  const htmlToPdfRef = useRef<HTMLDivElement>(null);

  const handleClick = (url: string) => {
    if (!url) {
      return;
    }
    handleCopy(url);
    setCopiedPopupOpen(true);
    setTimeout(() => {
      setCopiedPopupOpen(false);
    }, 1000);
  };

  const savePDF = () => {
    if (!htmlToPdfRef.current) {
      return;
    }
    const refRect = htmlToPdfRef.current.getBoundingClientRect();
    html2canvas(htmlToPdfRef.current).then((canvas) => {
      const img = canvas.toDataURL('image/jpeg');
      const pdf = new JsPDF();
      pdf.addImage(
        img,
        'JPEG',
        3,
        10,
        refRect.width / 3.25,
        refRect.height / 3.25,
      );
      pdf.save(`${connectPage?.internalTitle}.pdf`);
    });
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      className='new-sender-modal'
      closeIcon
      closeOnDimmerClick
      size='small'
    >
      <Header>
        {t('settings.connectSettings.connectPages.QRCodeModal.title', {
          page: connectPage?.internalTitle,
        })}
      </Header>
      <Content className={styles.content}>
        <div ref={htmlToPdfRef}>
          <div className={styles.QRCodeModalHeader}>
            <div className={styles.gradient}>{connectPage?.title?.default}</div>
          </div>
          <div className={styles.QRCodeContainer}>
            <QRCode
              value={connectPage?.url}
              logoImage={crmDescriptor?.logoURL || ''}
              logoHeight={crmDescriptor?.logoURL ? 40 : 0}
              logoWidth={crmDescriptor?.logoURL ? 40 : 0}
              removeQrCodeBehindLogo
              ecLevel='H'
              quietZone={24}
            />
          </div>
          <div className={styles.footer}>
            <Image
              src='/images/logos/logo-hiresweet.svg'
              width={24}
              height={24}
            />
            <span>
              {t(
                'settings.connectSettings.connectPages.QRCodeModal.poweredByHiresweet',
              )}
            </span>
            <a href='https://www.hiresweet.com/'>www.hiresweet.com</a>
          </div>
        </div>
      </Content>
      <Actions className={styles.modalActions}>
        <div className='align-left'>
          <GenericButton primacy='secondary' onClick={closeModal}>
            {t('common.cancel')}
          </GenericButton>
        </div>
        <GenericButton
          primacy='secondary'
          onClick={() => handleClick(connectPage?.url || '')}
        >
          {copiedPopupOpen
            ? t('settings.connectSettings.connectPages.QRCodeModal.linkCopied')
            : t('settings.connectSettings.connectPages.QRCodeModal.copyLink')}
        </GenericButton>

        <GenericButton primacy='primary' onClick={() => savePDF()}>
          {t('settings.connectSettings.connectPages.QRCodeModal.downloadPrint')}
        </GenericButton>
      </Actions>
    </Modal>
  );
};

export default ConnectQRCodeModal;
