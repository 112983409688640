/* eslint-disable max-len */

import React from 'react';

const Tasks: React.FC = () => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1 5C1 2.79086 2.79086 1 5 1H11C13.2091 1 15 2.79086 15 5V11C15 13.2091 13.2091 15 11 15H5C2.79086 15 1 13.2091 1 11V5ZM6.85355 4.14645C7.04882 4.34171 7.04882 4.65829 6.85355 4.85355L4.85355 6.85355C4.65829 7.04882 4.34171 7.04882 4.14645 6.85355L3.14645 5.85355C2.95118 5.65829 2.95118 5.34171 3.14645 5.14645C3.34171 4.95118 3.65829 4.95118 3.85355 5.14645L4.5 5.79289L6.14645 4.14645C6.34171 3.95118 6.65829 3.95118 6.85355 4.14645ZM6.85355 9.35355C7.04882 9.15829 7.04882 8.84171 6.85355 8.64645C6.65829 8.45118 6.34171 8.45118 6.14645 8.64645L4.5 10.2929L3.85355 9.64645C3.65829 9.45118 3.34171 9.45118 3.14645 9.64645C2.95118 9.84171 2.95118 10.1583 3.14645 10.3536L4.14645 11.3536C4.34171 11.5488 4.65829 11.5488 4.85355 11.3536L6.85355 9.35355ZM8 5.5C8 5.22386 8.22386 5 8.5 5H12.5C12.7761 5 13 5.22386 13 5.5C13 5.77614 12.7761 6 12.5 6H8.5C8.22386 6 8 5.77614 8 5.5ZM8.5 9.5C8.22386 9.5 8 9.72386 8 10C8 10.2761 8.22386 10.5 8.5 10.5H12.5C12.7761 10.5 13 10.2761 13 10C13 9.72386 12.7761 9.5 12.5 9.5H8.5Z'
      fill='currentColor'
    />
  </svg>
);

export default Tasks;
