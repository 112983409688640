import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { Container, Input } from 'semantic-ui-react';
import { useQuery, useMutation } from '@apollo/client';

import EmailEditor from 'react-email-editor';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { useLockHistory } from '@/context/LockHistoryContext';
import GenericButton from '@/components/Common/GenericButton';
import CampaignLayout from '../CampaignLayout';
import CampaignsSubHeader from '../CampaignsSubHeader/CampaignsSubHeader';
import { CLIENT_CAMPAIGN, UPDATE_CAMPAIGN } from '../queries';

import CancelButton from '../CampaignsSubHeader/CancelButton';

const EditCampaignPage = ({ clientId, campaignId }) => {
  const history = useHistory();
  const notification = useNotificationSystem();
  const { t } = useTranslation();
  // QUERY get client campaign by Id
  const { data } = useQuery(CLIENT_CAMPAIGN, {
    variables: {
      clientId,
      campaignId,
    },
  });
  const campaign = data?.campaign;

  const emailEditorRef = useRef(null);

  const { unlockHistory } = useLockHistory();

  // MUTATION update campaign
  const [updateCampaign, { loading: updatingCampaign }] = useMutation(
    UPDATE_CAMPAIGN,
  );

  // FORM PROPS
  const [campaignTitle, setCampaignTitle] = useState(
    campaign?.title || t('campaigns.titlePlaceholder'),
  );
  const [campaignSubject, setCampaignSubject] = useState(
    campaign?.subject || '',
  );

  useEffect(() => {
    setCampaignTitle(campaign?.title);
    setCampaignSubject(campaign?.subject);
  }, [campaign]);

  // SUBMIT
  const onSubmit = async ({ design, html }) => {
    const campaignInput = {
      id: campaignId,
      title: campaignTitle,
      subject: campaignSubject,
      description: JSON.stringify(design),
      descriptionHTML: html,
    };

    await updateCampaign({
      variables: { input: campaignInput },
    });
  };

  const clickHandler = async ({ design, html }) => {
    unlockHistory();
    try {
      await onSubmit({ design, html });
      notification.success(t('campaigns.edit.campaignEdited'));
      history.push(`/client/${clientId}/reveal/campaigns/${campaignId}`);
    } catch (e) {
      notification.error(t('campaigns.edit.campaignEditError'));
      console.error(e);
    }
  };

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((editor) => {
      const { design, html } = editor;
      clickHandler({ design, html });
    });
  };

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    setTimeout(() => {
      const templateJson = JSON.parse(campaign.description);
      emailEditorRef.current.editor.loadDesign(templateJson);
    }, 500);
  };

  return (
    <CampaignLayout
      subHeader={
        <CampaignsSubHeader
          breadcrumbs={[
            {
              text: t('campaigns.breadcrumbs.campaigns'),
              link: `/client/${clientId}/reveal/campaigns`,
            },
            {
              text: campaignTitle,
              link: `/client/${clientId}/reveal/campaigns/${campaignId}`,
            },
            {
              text: t('campaigns.breadcrumbs.editCampaign'),
              link: `/client/${clientId}/reveal/campaigns/${campaignId}/edit`,
            },
          ]}
        >
          <CancelButton />
          <GenericButton
            size='big'
            disabled={updatingCampaign}
            onClick={exportHtml}
          >
            {t('campaigns.buttons.save')}
          </GenericButton>
        </CampaignsSubHeader>
      }
    >
      <Container className='new-campaign-edit'>
        <h3 className='input-container'>
          <Input
            className='campaign-title-input'
            fluid
            placeholder={t('campaigns.title')}
            value={campaignTitle || ''}
            onChange={(ev) => setCampaignTitle(ev.target.value)}
          />
        </h3>
        <div>
          <Input
            className='campaign-subject-input'
            fluid
            placeholder={t('campaigns.subjectPlaceholder')}
            value={campaignSubject || ''}
            onChange={(ev) => setCampaignSubject(ev.target.value)}
          />
        </div>
        <div className='editor-container'>
          {!_.isEmpty(campaign?.description) && (
            <EmailEditor
              ref={emailEditorRef}
              onLoad={onLoad}
              style={{ height: '100%' }}
            />
          )}
        </div>
      </Container>
    </CampaignLayout>
  );
};

export default EditCampaignPage;
