import _ from 'underscore';

import { Primitive } from './types';

export const getRecordTotal = (
  record: Record<string | number | symbol, number>,
): number => _.reduce(record, (sum, value) => sum + value, 0);

export function deepIndex<Obj, Path extends DeepPath<Obj>>(
  obj: Obj,
  path: Path,
): DeepIndex<Obj, Path> | undefined {
  if (!obj) {
    return undefined;
  }
  if (typeof obj !== 'object') {
    throw new Error(
      `Error while indexing, ${JSON.stringify(obj)} is not an object`,
    );
  }
  const [head, ...tail] = path.split('.');
  if (!head) {
    throw new Error(`Malformed path ${path}`);
  }
  if (!(head in obj)) {
    return undefined;
  }
  const next = obj[head as keyof Obj];
  if (tail.length === 0) {
    return next as DeepIndex<Obj, Path>;
  }
  return deepIndex(next, tail.join('.') as DeepPath<typeof next>) as DeepIndex<
    Obj,
    Path
  >;
}

export type DeepPathWithType<Obj, T> = PathsWithType<
  Obj,
  T
>[keyof PathsWithType<Obj, T>];

type PathsWithType<Obj, T> = {
  [K in DeepPath<Obj> as DeepIndex<Obj, K> extends T ? K : never]: K;
};

type DeepIndex<Obj, Path extends DeepPath<Obj>> = Path extends keyof Obj
  ? Obj[Path]
  : Path extends `${infer K}.${infer Tail}`
    ? K extends keyof Obj
      ? Tail extends DeepPath<Obj[K]>
        ? DeepIndex<Obj[K], Tail>
        : never
      : never
    : never;

type DeepPath<Obj> = Obj extends Primitive
  ? never
  : Paths<Obj>[keyof Paths<Obj>];

type Paths<Obj> = {
  [K in keyof Obj]-?: K extends string ? K | `${K}.${DeepPath<Obj[K]>}` : never;
};
