import React, { VFC } from 'react';

import { ATS_LOGOS_BY_TYPE, ATS_NAMES_BY_TYPE, ATStype } from '@/common/reveal';

import styles from './AtsFilterTitle.module.less';

type AtsFilterTitleProps = {
  ats: ATStype;
};

const AtsFilterTitle: VFC<AtsFilterTitleProps> = ({ ats }) => (
  <>
    <img className={styles.logo} alt='ats logo' src={ATS_LOGOS_BY_TYPE[ats]} />
    {ATS_NAMES_BY_TYPE[ats]}
  </>
);

export default AtsFilterTitle;
