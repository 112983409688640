import gql from 'graphql-tag';

export const GET_DEFAULT_TEMPLATE_REPLIES = gql`
  query recruiterConfigurations($clientId: ID!) {
    client(id: $clientId) {
      templates {
        title
        recruiterConfigurations {
          recruiterEmail
          id
          isDefaultReply
        }
        id
      }
    }
  }
`;

export const UPDATE_DEFAULT_TEMPLATE_REPLY = gql`
  mutation updateTemplateRecruiterConfiguration(
    $id: ID!
    $isDefaultReply: Boolean
  ) {
    updateTemplateRecruiterConfiguration(
      id: $id
      isDefaultReply: $isDefaultReply
    ) {
      id
      recruiterConfigurations {
        recruiterEmail
        id
        isDefaultReply
      }
    }
  }
`;
