import { OfferCriteria } from '@/types/offerCriteria';
import { sanitizeTypename } from '@/common/utils/apollo';

import { MarketplaceFormValue } from './types';

export function getCriteriaFromFormValue({
  experienceYears,
  jobPosition,
  locationsAndRemote,
}: MarketplaceFormValue): OfferCriteria {
  return {
    ...(jobPosition && {
      jobPositions: {
        required: [
          {
            identifier: jobPosition.value,
            label: { default: jobPosition.label },
          },
        ],
      },
    }),
    experience: {
      years: {
        min: experienceYears?.min,
        max: experienceYears?.max,
      },
    },
    locationsAndRemote: sanitizeTypename(locationsAndRemote),
  };
}
