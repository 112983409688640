import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'underscore';
import { Button, Modal } from 'semantic-ui-react';
import * as Sentry from '@sentry/browser';
import withRemoveProfile from '../../../../hocs/profiles/withRemoveProfile';

class ProfileRemovalModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = { open: false };
  }

  close = () => {
    this.setState({ open: false });
  };

  show = (evt) => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    this.setState({ open: true });
  };

  render() {
    const onSubmit = () => {
      const {
        profileId,
        offerId,
        removeProfile,
        getNextProfileId,
        onChangeProfile,
        onChangeStep,
        stepId,
      } = this.props;
      removeProfile({ id: profileId, offerId })
        .then(() => {
          const nextProfileId = getNextProfileId();
          if (nextProfileId) {
            onChangeProfile(nextProfileId);
            this.close();
          } else {
            onChangeStep({ newStepId: stepId, forceRefresh: true });
            this.close();
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    };
    const { t } = this.props;
    const { open } = this.state;
    return (
      <div>
        <Modal
          open={open}
          onClose={this.close}
          trigger={
            <div className='remove-trigger'>
              <button
                className='remove-button'
                type='button'
                onClick={this.show}
              >
                {t('profile.steps.remove.trigger')}
              </button>
            </div>
          }
          closeIcon
          size='small'
        >
          <Modal.Header>
            {t('profile.steps.remove.header')}
            <div className='subheader description'>
              {t('profile.steps.remove.subheader')}
            </div>
          </Modal.Header>

          <Modal.Actions className='align-center'>
            <Button
              className='dismiss'
              type='submit'
              onClick={() => this.close()}
            >
              {t('common.cancel')}
            </Button>

            <Button
              secondary
              size='big'
              type='submit'
              onClick={() => onSubmit()}
            >
              {t('profile.steps.remove.button')}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default compose(
  withRemoveProfile,
  withTranslation('translations'),
)(ProfileRemovalModal);
