import { createContext } from 'react';

type ProfileTableParams = {
  disabledActions: Record<string, boolean>;
};

const ProfileTableParamsContext = createContext<ProfileTableParams>({
  disabledActions: {},
});

export default ProfileTableParamsContext;
