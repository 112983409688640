import React from 'react';
import classNames from 'classnames';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import styles from './SimpleDropdown.module.less';

const SimpleDropdown: React.FC<DropdownProps> = ({ className, ...props }) => {
  return (
    <Dropdown
      {...props}
      className={classNames(styles.simpleDropdown, className)}
    />
  );
};

export default SimpleDropdown;
