/* eslint-disable max-len */

import React, { FC } from 'react';

import styles from './svg.module.less';

const ChevronRight: FC = () => (
  <svg
    className={styles.svgIcon}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6 12L9.74452 8.56567C10.0852 8.25325 10.0852 7.74672 9.74452 7.4343L5.99997 3.99998'
      stroke='currentColor'
      fill='none'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default ChevronRight;
