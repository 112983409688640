import React from 'react';
import { Redirect } from 'react-router-dom';
import { decodeRelayState, useExchangeToken } from './utils';

// todo
const SSOError = () => {
  return null;
};

interface SSOReceptionProps {
  clientId: string;
  exchangeToken: string;
  /**
   * encoded redirection
   */
  redirectTo: string;
}

const SSOReception: React.FC<SSOReceptionProps> = ({ clientId, redirectTo, exchangeToken }) => {
  const { loading, success, error } = useExchangeToken(exchangeToken, clientId);
  if (loading) {
    return null;
  }
  if (success) {
    return <Redirect to={decodeRelayState(redirectTo)} />;
  }
  if (error) {
    return <SSOError />;
  }
  return null;
};

export default SSOReception;
