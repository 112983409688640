import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Tab } from 'semantic-ui-react';
import Comment from './Comment';
import LoadingComponent from '../../../../components/LoadingComponent';
import DisplayedSequence from './Sequences/DisplayedSequence';

import withUserSettings from '../../../../hocs/users/withUserSettings';
import withEmailContextConsumer from '../../../../hocs/email/withEmailContextConsumer';
import withClientTemplates from '../../../../hocs/templates/withClientTemplates';

import './ProfileContactFlow.css';

const ProfileContactFlow = ({
  contactFlow,
  profile,
  profileId,
  emailApi,
  user,
  offer,
  clientId,
  offerId,
  stepId,
  searchText,
  getNextProfileId,
  isSearchResultProfile,
  onChangeStep,
  onChangeProfile,
  onSend,
  handleMarkProfileAsInMove,
  handleUnmarkProfileAsInMove,
  getProfilesInMove,
  templates,
  loading,
  error,
  t,
}) => {
  const [commentFocusId, setCommentFocusId] = useState(0);

  const currentSequence = _.findWhere(contactFlow?.sequences, {
    isCurrent: true,
  });
  let displayedSequence = currentSequence;
  const currentSequenceFirstAction = (currentSequence?.actions || [])[0];
  if (currentSequenceFirstAction?.completion?.isCompleted) {
    displayedSequence = _.findWhere(contactFlow?.sequences, { isDraft: true });
  }
  const panes = [
    {
      menuItem: t('profile.contact.drafts.email'),
      render: () => (
        <Tab.Pane as='div' style={{ padding: '0 20px 10px' }}>
          <div>
            <div>
              <DisplayedSequence
                clientId={clientId}
                sequence={displayedSequence}
                profileId={profileId}
                profile={profile}
                emailApi={emailApi}
                user={user}
                offer={offer}
                offerId={offerId}
                stepId={stepId}
                searchText={searchText}
                getNextProfileId={getNextProfileId}
                isSearchResultProfile={isSearchResultProfile}
                onChangeStep={onChangeStep}
                onChangeProfile={onChangeProfile}
                onSend={onSend}
                handleMarkProfileAsInMove={handleMarkProfileAsInMove}
                handleUnmarkProfileAsInMove={handleUnmarkProfileAsInMove}
                getProfilesInMove={getProfilesInMove}
                templates={templates}
                t={t}
              />
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: t('profile.contact.drafts.comment'),
      render: () => (
        <Tab.Pane as='div' style={{ padding: '0 20px 10px' }}>
          <Comment
            clientId={clientId}
            profileId={profileId}
            focusId={commentFocusId}
          />
        </Tab.Pane>
      ),
    },
  ];

  if (loading || !profileId || !user || error) {
    return (
      <div style={{ padding: 20 }}>
        <LoadingComponent loading length={10} as='div' rows={1} margin={0.5} />
        <div style={{ padding: 20, border: '2px solid #bebebe' }}>
          <LoadingComponent
            loading
            length={30}
            as='div'
            rows={1}
            randomCoef={7}
          />
          <LoadingComponent
            loading
            length={40}
            as='div'
            rows={3}
            margin={1}
            randomCoef={7}
          />
        </div>
      </div>
    );
  }

  const onTabChange = (event, target) => {
    if (target.activeIndex === 1) {
      setTimeout(() => setCommentFocusId(Math.random()), 100);
    }
  };

  return (
    <Tab
      onTabChange={onTabChange}
      className='timeline-default-card-wrapper classic-item'
      menu={{ secondary: true, pointing: true }}
      panes={panes}
    />
  );
};

export default compose(
  withTranslation('translations'),
  withUserSettings,
  withClientTemplates,
  withEmailContextConsumer,
)(ProfileContactFlow);
