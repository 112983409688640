import React from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import { Image, Icon, Checkbox } from 'semantic-ui-react';

import getJobWishes from '@/common/candidates';
import { MarketplaceUnbiasedModeField } from '@/components/UnbiasedMode/UnbiasedModeField';
import Interest from '@/components/Common/Icons/Interest';
import { CANDIDATE_INTEREST_ITEM_TYPE } from '@/common/reveal/SourceData.type';
import ProfilePowerHourIcon from '@/containers/Profile/Resume/Card/ProfilePowerHourIcon';
import ProfilePowerHourFlag from '@/containers/Profile/Resume/PowerHourState';
import useClient from '@/graphql/hooks/clients/useClient';
import { BlurLine } from '@/components/UnbiasedMode/BlurLine';

import { withLastProfileActionsContextConsumer } from '../../../../context/LastProfileActionsContext';
import { getSecureLink, getRandomDefaultAvatarLink } from '../../../../common';
import SkillsLabels, {
  getSortedSkillsWishes,
} from '../../../../components/Profile/SkillsLabels';
import WatchNewTag from './WatchNewTag';

import './ProfileRow.css';

const ProfileRow = ({
  profile,
  isSelectedInBatch,
  onCheck,
  onClick,
  reviewButton,
  skipButton,
  clientId,
}) => {
  const { data } = useClient(clientId);
  const { hasLimitedAccessToProfiles } = data?.client || {};
  if (!profile || !profile.resumeData) {
    return null;
  }

  const { creationTimestamp, resumeData } = profile;
  const { firstname, lastname, photoLink, sourceData } = resumeData;
  const hiresweetData = sourceData?.hiresweet;
  const jobWishes = getJobWishes({ hiresweetData });
  const sortedSkillsWishes = getSortedSkillsWishes({ hiresweetData });
  const avatarImageUrl =
    photoLink || getRandomDefaultAvatarLink(firstname + lastname);

  const clientInterestStates = _.findWhere(
    hiresweetData?.clientInterestStates || [],
    { clientId },
  );
  const hasCompanyGlobalInterestItem = _.findWhere(
    clientInterestStates?.interestItems || [],
    {
      type: CANDIDATE_INTEREST_ITEM_TYPE.COMPANY_GLOBAL,
    },
  );

  return (
    <div
      className={classNames('profile-row', isSelectedInBatch && 'selected')}
      onClick={onClick}
    >
      <div className='row-checkbox-container' onClick={onCheck}>
        <Checkbox checked={!!isSelectedInBatch} />
      </div>
      <div className='avatar-container'>
        <MarketplaceUnbiasedModeField
          name='hidePicture'
          fallback={
            <Image
              src={getRandomDefaultAvatarLink(firstname + lastname)}
              circular
            />
          }
        >
          <Image
            src={getSecureLink(avatarImageUrl)}
            circular
            onError={(e) => {
              e.target.src = getRandomDefaultAvatarLink(firstname + lastname);
            }}
          />
        </MarketplaceUnbiasedModeField>
        {hasCompanyGlobalInterestItem && (
          <div className='avatar-show-interest'>
            <Interest />
          </div>
        )}
        <ProfilePowerHourFlag profile={profile}>
          <div className='profileRowPowerHoursIcon'>
            <ProfilePowerHourIcon profile={profile} />
          </div>
        </ProfilePowerHourFlag>
      </div>

      <div className='middle-container'>
        <div className='full-name headline-5'>
          <div className='full-name-text'>
            {hasLimitedAccessToProfiles ? (
              <BlurLine />
            ) : (
              <MarketplaceUnbiasedModeField name='hideFullName'>
                {firstname} {lastname}
              </MarketplaceUnbiasedModeField>
            )}{' '}
          </div>
          <WatchNewTag creationTimestamp={creationTimestamp} />
        </div>

        <div className='job-wishes'>
          {jobWishes && (
            <div className='headline'>
              <Icon name='search' />
              {jobWishes}
            </div>
          )}
        </div>

        <div className='skills'>
          <SkillsLabels skills={sortedSkillsWishes} condensedMode />
        </div>
      </div>

      <div className='buttons-container'>
        {reviewButton}
        {skipButton}
      </div>
    </div>
  );
};

export default _.compose(withLastProfileActionsContextConsumer)(ProfileRow);
