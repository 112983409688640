import React, { FC } from 'react';
import { Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useAccountStrategist from '@/graphql/hooks/clients/useAccountStrategist';
import { getFullname } from '@/common/helpers/person';
import ButtonLink from '@/components/Common/GenericButton/ButtonLink';
import Avatar from '@/components/Common/Avatar';
import Calendar from '@/components/Common/Icons/Calendar';
import Message from '@/components/Common/Icons/Message';

import styles from './AccountStrategistContact.module.less';

const AccountStrategistContact: FC = () => {
  const { t } = useTranslation();
  const { accountStrategist, loading } = useAccountStrategist();

  if (loading) {
    return <Loader />;
  }

  if (!accountStrategist) {
    return null;
  }

  const fullname = getFullname(accountStrategist);
  const { email, phoneNumber, calendlyUrl } = accountStrategist;

  return (
    <>
      <h2 className={styles.header}>{t('offers.accountStrategist.title')}</h2>
      <div className={styles.contactCard}>
        <Avatar person={accountStrategist} className={styles.avatar} />
        <div className={styles.contactInfo}>
          <div>
            <span className={styles.name}>{fullname}</span>
            <span className={styles.title}>
              {t('offers.accountStrategist.accountStrategist')}
            </span>
          </div>
          {email && (
            <Link
              to={{ pathname: `mailto:${email}` }}
              className={styles.email}
              target='_blank'
            >
              {email}
            </Link>
          )}
          {phoneNumber && (
            <Link
              to={{ pathname: `tel:${phoneNumber}` }}
              className={styles.phoneNumber}
              target='_blank'
            >
              {phoneNumber}
            </Link>
          )}
        </div>
        <div className={styles.buttons}>
          {calendlyUrl && (
            <ButtonLink
              to={{ pathname: calendlyUrl }}
              isIcon
              primacy='secondary'
              target='_blank'
            >
              <Calendar />
            </ButtonLink>
          )}
          {email && (
            <ButtonLink
              to={{ pathname: `mailto:${email}` }}
              isIcon
              target='_blank'
            >
              <Message />
            </ButtonLink>
          )}
        </div>
      </div>
    </>
  );
};

export default AccountStrategistContact;
