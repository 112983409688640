import { TFunction } from 'i18next';
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { compose } from 'underscore';

import GenericCheckbox from '@/components/Common/GenericCheckbox';

import './SchoolFields.css';

export type SchoolState = Record<SchoolsKey, string[]>;
type SchoolsKey = 'currentlyIn' | 'in' | 'currentlyNotIn' | 'neverIn';

const SchoolFields = (props: {
  schoolStates: SchoolState;
  setSchoolStates: (value: SchoolState) => void;
  t: TFunction;
}) => {
  const { schoolStates, setSchoolStates, t } = props;

  const handleFilterUpdate = (key: SchoolsKey) => (value: string[]) => {
    setSchoolStates({
      ...schoolStates,
      [key]: value,
    });
  };
  return (
    <div className='schools section'>
      {FILTERS.map(({ value, text }) => (
        <SchoolsField
          onFilterUpdate={handleFilterUpdate(value)}
          values={schoolStates[value]}
          text={t(text)}
          key={value}
          t={t}
        />
      ))}
    </div>
  );
};

const SchoolsField = (props: {
  onFilterUpdate: (value: string[]) => void;
  text: string;
  values: string[];
  t: TFunction;
}) => {
  const { text, values, onFilterUpdate, t } = props;

  const [newOptions, setNewOptions] = useState<
    { text: string; value: string }[]
  >(
    values?.map((opt) => ({
      text: opt,
      value: opt,
    })) ?? [],
  );
  const [isSelected, setIsSelected] = useState(values?.length > 0 ?? []);

  useEffect(() => {
    if (!values?.length) {
      setIsSelected(false);
    }
    // eslint-disable-next-line
  }, [values?.length]);

  const onChange = (_e: unknown, data: DropdownProps) => {
    onFilterUpdate(data.value as string[]);
  };

  const addItem = (_e: unknown, data: DropdownProps) => {
    const { value: optionText } = data;

    setIsSelected(true);

    if (typeof optionText === 'string') {
      setNewOptions([
        ...newOptions,
        {
          text: optionText,
          value: optionText,
        },
      ]);
    }
  };

  const handleSelect = () => {
    if (isSelected) {
      setIsSelected(false);
      onFilterUpdate([]);
      return;
    }

    setIsSelected(true);
  };

  return (
    <div className='field'>
      <button type='button' className='radio-row' onClick={handleSelect}>
        <GenericCheckbox checked={isSelected} />
        <div className='radio-label'>{text}</div>
      </button>
      {isSelected && (
        <Dropdown
          multiple
          search
          selection
          onChange={onChange}
          value={values}
          options={newOptions}
          allowAdditions
          onAddItem={addItem}
          placeholder={t('reveal.searchView.search.schools.schoolPlaceholder')}
        />
      )}
    </div>
  );
};

const FILTERS: { value: SchoolsKey; text: string }[] = [
  {
    value: 'currentlyIn',
    text: 'reveal.searchView.search.schools.currentlyIn',
  },
  {
    value: 'in',
    text: 'reveal.searchView.search.schools.in',
  },
  {
    value: 'currentlyNotIn',
    text: 'reveal.searchView.search.schools.currentlyNotIn',
  },
  {
    value: 'neverIn',
    text: 'reveal.searchView.search.schools.neverIn',
  },
];

export default compose(withTranslation('translations'))(SchoolFields);
