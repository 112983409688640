import React, { FC, PropsWithChildren } from 'react';

import SequenceDropdown from '@/components/Reveal/Sequences/SequenceDropdown';
import useIsPlugin from '@/hooks/common/useIsPlugin';

import { Sequence } from '@/types/sequence';
import SequenceDropdownControlsContext from '../context/SequenceDropdownControlsContext';

interface SequenceDropdownContainerProps {
  sequences: Sequence[];
  onClickAdd: (id: string) => void;
  clientId: string;
  shiftSidebar?: boolean;
  defaultSequenceId?: string;
  secondarySequenceIds?: string[];
  className?: string;
}

const SequenceDropdownContainer: FC<PropsWithChildren<SequenceDropdownContainerProps>> = ({
  sequences,
  onClickAdd,
  clientId,
  shiftSidebar,
  defaultSequenceId,
  secondarySequenceIds,
  className,
  children,
}) => {
  const isPlugin = useIsPlugin();

  return (
    <SequenceDropdown
      position='right'
      isPlugin={isPlugin}
      clientSequencesList={sequences}
      defaultSequenceId={defaultSequenceId}
      secondarySequenceIds={secondarySequenceIds}
      onSequenceSelected={onClickAdd}
      clientId={clientId}
      Trigger={({ onClick }) => (
        <SequenceDropdownControlsContext.Provider
          value={{ toggleSequenceDropdown: onClick }}
        >
          {children}
        </SequenceDropdownControlsContext.Provider>
      )}
      shiftSidebar={shiftSidebar}
      className={className}
    />
  );
};

export default SequenceDropdownContainer;
