import { gql } from '@apollo/client';

export const GET_CLIENT_CONFIGURATION_PARAMS = gql`
  query getClientConfigurationParams($clientId: ID!) {
    client(id: $clientId) {
      id
      configurationParams {
        attributes {
          key
          value
        }
      }
    }
  }
`;
