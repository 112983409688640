import gql from 'graphql-tag';
import {
  FetchResult,
  MutationHookOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import { getOfferProfiles } from '@/hocs/offers/withOfferProfiles';
import SearchPoolProfileState from '@/graphql/fragments/SearchPoolProfileState';

const ADD_PROFILE_TO_OFFER = gql`
  mutation addProfileToOffer($id: ID!, $jobOfferId: ID!) {
    addSearchPoolProfileToJobOffer(id: $id, jobOfferId: $jobOfferId) {
      id
      searchPoolState {
        ...SearchPoolProfileState
      }
    }
  }
  ${SearchPoolProfileState}
`;

interface AddProfileToOfferData {
  id: string;
}

interface AddProfileToOfferVariables {
  id: string;
  jobOfferId: string;
}

interface UseAddProfileToOfferInput {
  mutationOptions?: MutationHookOptions<
    AddProfileToOfferData,
    AddProfileToOfferVariables
  >;
}

type UseAddProfileToOfferResult = [
  (
    id: string,
    jobOfferId: string,
  ) => Promise<FetchResult<AddProfileToOfferData>>,
  MutationResult<AddProfileToOfferData>,
];

const useAddProfileToOffer = ({
  mutationOptions = {},
}: UseAddProfileToOfferInput = {}): UseAddProfileToOfferResult => {
  const [mutation, result] = useMutation<
    AddProfileToOfferData,
    AddProfileToOfferVariables
  >(ADD_PROFILE_TO_OFFER, {
    ...mutationOptions,
  });

  return [
    (id, jobOfferId) =>
      mutation({
        variables: { id, jobOfferId },
        refetchQueries: [
          {
            query: getOfferProfiles,
            variables: {
              offerId: jobOfferId,
              step: '',
              search: '',
            },
          },
          {
            query: getOfferProfiles,
            variables: {
              offerId: jobOfferId,
              step: 'pending',
              search: '',
            },
          },
        ],
      }),
    result,
  ];
};

export default useAddProfileToOffer;
