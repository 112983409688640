import { gql, QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import { SavedSearch } from '@/types/savedSearch';
import useClientId from '@/hooks/router/useClientId';
import RevealSavedSearchFragment from '@/graphql/fragments/RevealSavedSearch';

export const GET_SAVED_SEARCHES = gql`
  query getSavedSearches($clientId: ID!, $projectIds: [ID!]) {
    client(id: $clientId) {
      id
      revealSavedSearches(projectIds: $projectIds) {
        ...RevealSavedSearch
      }
    }
  }
  ${RevealSavedSearchFragment}
`;

interface GetSavedSearchesVariables {
  clientId: string;
  projectIds?: string[];
}

interface GetSavedSearchesData {
  client: {
    id: string;
    revealSavedSearches: SavedSearch[];
  };
}

interface UseSavedSearchesInput {
  projectIds?: string[];
  queryOptions?: QueryHookOptions<
    GetSavedSearchesData,
    GetSavedSearchesVariables
  >;
}

interface UseSavedSearchesResult
  extends QueryResult<GetSavedSearchesData, GetSavedSearchesVariables> {
  savedSearches: SavedSearch[];
}

const useSavedSearches = ({
  projectIds,
  queryOptions = {},
}: UseSavedSearchesInput = {}): UseSavedSearchesResult => {
  const clientId = useClientId();
  const query = useQuery<GetSavedSearchesData, GetSavedSearchesVariables>(
    GET_SAVED_SEARCHES,
    {
      ...queryOptions,
      variables: {
        clientId,
        projectIds,
      },
    },
  );

  const savedSearches = query.data?.client.revealSavedSearches || [];

  return { ...query, savedSearches };
};

export default useSavedSearches;
