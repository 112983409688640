import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';
import styles from './SubHeader.module.less';
import { MarketingTab } from '../types';

interface SubHeaderProps {
  tab: MarketingTab;
  clientId: string;
  setNewFormModalOpen: (value: boolean) => void;
  setNewPageModalOpen: (value: boolean) => void;
}

const SubHeader: React.FC<SubHeaderProps> = ({
  tab,
  clientId,
  setNewFormModalOpen,
  setNewPageModalOpen,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.subHeader}>
      <ul className={styles.navigation}>
        <li className={styles.tab}>
          <Link
            to={`/client/${clientId}/reveal/pages`}
            className={`${styles.tabButton} ${tab === 'pages' &&
              styles.active}`}
          >
            {t('header.parameters.pages')}
          </Link>
        </li>
        <li className={styles.tab}>
          <Link
            to={`/client/${clientId}/reveal/forms`}
            className={`${styles.tabButton} ${tab === 'forms' &&
              styles.active}`}
          >
            {t('header.parameters.forms')}
          </Link>
        </li>
      </ul>
      <div className={styles.campaignsButtons}>
        {tab === 'pages' && (
          <GenericButton size='big' onClick={() => setNewPageModalOpen(true)}>
            <Plus />
            {t('settings.connectSettings.connectPages.newPage')}
          </GenericButton>
        )}
        {tab === 'forms' && (
          <GenericButton size='big' onClick={() => setNewFormModalOpen(true)}>
            <Plus />
            {t('settings.connectSettings.connectForms.newForm')}
          </GenericButton>
        )}
      </div>
    </div>
  );
};

export default SubHeader;
