import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const mutation = gql`
  mutation markProfileAsShared($id: ID!) {
    markProfileAsShared(id: $id) {
      id
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    markProfileAsShared({ profileId }) {
      mutate({
        variables: { id: profileId },
      });
    },
  }),
});
