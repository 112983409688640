import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Dropdown } from 'semantic-ui-react';


const RegisteredDropdown = ({ name, ...props }) => {
  const { register, watch, setValue } = useFormContext();

  const watchedValue = watch(name);

  return (
    <Dropdown
      {...register(name)}
      {...props}
      defaultValue={watchedValue}
      onChange={(e, { value }) => setValue(name, value)}
    />
  );
};

export default RegisteredDropdown;
