import InfoBox from '@/components/Common/InfoBox';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useClientId from '@/hooks/router/useClientId';
import { Link } from 'react-router-dom';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import { Modal } from 'semantic-ui-react';
import TargetsForm from '@/routes/RevealView/JobsView/JobView/InformationsTab/TargetsForm';
import useUpdateProfileLinkedCompany from '@/graphql/hooks/searchPoolProfile/useUpdateProfileLinkedCompany';
import Plus from '@/components/Reveal/Icons/Plus';
import styles from './ProfileLinkedCompany.module.less';

interface ProfileLinkedCompanyProps {
  company: {
    id: string;
    name: string;
  };
  onEditLinkedCompany: (value: boolean) => void;
}

const ProfileLinkedCompany: React.FC<ProfileLinkedCompanyProps> = ({
  company,
  onEditLinkedCompany,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();

  if (!company) {
    return (
      <InfoBox className={styles.linkedCompanyBox}>
        <span>{t('profile.resume.noCompanySet')}</span>
        <GenericButton
          onClick={() => onEditLinkedCompany(true)}
          className={styles.companyButton}
          primacy='secondary'
          size='small'
        >
          <Plus />
          {t('profile.resume.linkCompany')}
        </GenericButton>
      </InfoBox>
    );
  }

  return (
    <InfoBox className={styles.linkedCompanyBox}>
      <span>
        {t('profile.resume.company')}
        <Link
          className={styles.companyName}
          to={`/client/${clientId}/reveal/companies?profileId=${company.id}`}
        >
          {company.name}
        </Link>
      </span>
      <GenericButton
        onClick={() => onEditLinkedCompany(true)}
        className={styles.companyButton}
        primacy='secondary'
        size='small'
      >
        {t('common.edit')}
      </GenericButton>
    </InfoBox>
  );
};

interface ProfileLinkedCompanyModalProps {
  open: boolean;
  profileId: string;
  linkedCompany: {
    name: string;
    id: string;
  };
  onClose: () => void;
}

export const EditProfileLinkedCompanyModal: React.FC<ProfileLinkedCompanyModalProps> = ({
  open,
  profileId,
  linkedCompany,
  onClose,
}) => {
  const { t } = useTranslation();
  const [newLinkedCompany, setNewLinkedCompany] = useState<string | undefined>(
    linkedCompany?.id,
  );
  const [updateProfileLinkedCompany] = useUpdateProfileLinkedCompany();

  return (
    <GenericModal
      size='tiny'
      open={open}
      onClose={onClose}
      closeOnEscape
      closeOnDimmerClick
    >
      <Modal.Header>{t('profile.resume.companyModal.title')}</Modal.Header>
      <Modal.Content className={styles.modal}>
        <TargetsForm
          linkedCompany={newLinkedCompany || undefined}
          onLinkedCompany={(value) => setNewLinkedCompany(value)}
        />
      </Modal.Content>
      <Modal.Actions className={styles.actions}>
        <GenericButton size='big' primacy='secondary' onClick={onClose}>
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          size='big'
          onClick={() => {
            updateProfileLinkedCompany({
              variables: {
                searchPoolId: 'reveal',
                profileId,
                input: {
                  id: newLinkedCompany,
                },
              },
            });
            onClose();
          }}
        >
          {t('common.save')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default ProfileLinkedCompany;
