import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ContactsStatistics from '../../RevealAnalyticsMissions/ContactsStatistics';
import { dailyCountReportStacks } from '../AdventureDashboard2/helpers';
import DailyCountStream from '../AdventureDashboard2/DailyCountStream';

import MissionRemovals from './MissionRemovals';
import MissionRemovalReasons from './MissionRemovalReasons';

import styles from './AdventureDashboard.module.less';

const AdventureDashboard1: FC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ContactsStatistics
        title={t('reveal.reports.adventure.addedToMission.title')}
        useAggregatePeriod
      />
      <MissionRemovals />
      <div className={styles.card}>
        <div className={styles.header}>
          <h3 className={styles.title}>
            {t('reveal.reports.adventure.removalReasons.title')}
          </h3>
        </div>
        <div className={styles.content}>
          <MissionRemovalReasons />
        </div>
      </div>
      <DailyCountStream
        reportStreamId='adequat-status-H6YRKS'
        stack={dailyCountReportStacks['adequat-status-H6YRKS']}
        title={t('reveal.reports.adventure.availability.title')}
      />
      <DailyCountStream
        reportStreamId='adequat-anteriority-R2ALUB'
        stack={dailyCountReportStacks['adequat-anteriority-R2ALUB']}
        title={t('reveal.reports.adventure.anteriority.title')}
      />
    </div>
  );
};

export default AdventureDashboard1;
