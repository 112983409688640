import React from 'react';
import _, { compose } from 'underscore';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import GenericButton from '@/components/Common/GenericButton';
import withClientPermissions from '../../hocs/clients/withClientPermissions';
import withRecommendedProfilesCount from '../../hocs/clients/withRecommendedProfilesCount';

import './WatchCollectBanner.css';

const WatchCollectBanner = ({
  clientId,
  permissions,
  jobsWithRecommendedProfilesCount,
  jobsWithRecommendedProfilesCountLoading,
  t,
}) => {
  if (
    (!permissions?.watchCollect && !permissions?.watchCollectV2) ||
    jobsWithRecommendedProfilesCountLoading
  ) {
    return null;
  }

  const totalCount = _.reduce(
    jobsWithRecommendedProfilesCount,
    (total, job) => {
      return total + (job?.recommendedProfilesFeed?.total || 0);
    },
    0,
  );

  if (!totalCount) {
    return null;
  }

  return (
    <Link to={`/client/${clientId}/jobs`}>
      <div className='watch-collect-banner'>
        <div className='banner-icon'>
          <i className='ri-magic-fill' />
        </div>

        <div className='banner-content'>
          <div className='banner-main-text body-semibold'>
            {t('dashboard.watchCollectBanner.text', { count: totalCount })}
          </div>
          <div className='banner-sub-text caption-2'>
            {t('dashboard.watchCollectBanner.subtext', { count: totalCount })}
          </div>
        </div>

        <GenericButton>
          {t('dashboard.watchCollectBanner.button', {
            count: totalCount,
          })}
        </GenericButton>
      </div>
    </Link>
  );
};

export default compose(
  withClientPermissions,
  withTranslation('translations'),
  withRecommendedProfilesCount,
)(WatchCollectBanner);
