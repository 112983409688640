import { lowerCaseAndUnaccent } from '@/common';
import { useClientDepartments } from '@/hocs/clients/withClientDepartments';
import useClientId from '@/hooks/router/useClientId';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import _ from 'underscore';

import styles from './MissionDepartments.module.less';

interface MissionDepartmentsFormProps {
  foldering: {
    departmentId: string;
    sectionId: string;
    subsectionId: string;
  };
  onUpdateFoldering: (foldering: {
    departmentId: string;
    sectionId: string;
    subsectionId: string;
  }) => void;
  disabled?: boolean;
}

const MissionDepartmentsForm: React.FC<MissionDepartmentsFormProps> = ({
  foldering,
  onUpdateFoldering,
  disabled,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const departments = useClientDepartments(clientId);

  const selectedDepartment = _.findWhere(departments, {
    id: foldering.departmentId,
  });

  const selectedSubDepartment = _.findWhere(
    selectedDepartment?.sections || [],
    {
      id: foldering.sectionId,
    },
  );

  const departmentOptions = _.map(departments, (department) => ({
    text: department.title,
    value: department.id,
  }));

  const subDepartmentOptions = _.map(
    selectedDepartment?.sections || [],
    (section) => ({
      text: section.title,
      value: section.id,
    }),
  );

  const sectionOptions = _.map(
    selectedSubDepartment?.subsections || [],
    (section) => ({
      text: section.title,
      value: section.id,
    }),
  );

  const hasDepartments = !_.isEmpty(departments);
  const hasSections =
    !_.isEmpty(foldering.departmentId) && !_.isEmpty(subDepartmentOptions);
  const hasSubsections =
    !_.isEmpty(foldering.sectionId) && !_.isEmpty(sectionOptions);

  if (!hasDepartments && !hasSections && !hasSubsections) {
    return null;
  }

  return (
    <div className='mission-departments-container'>
      <h3>{t('reveal.missions.mission.settingsTab.departments')}</h3>
      {hasDepartments && (
        <div className={styles.inputContainer}>
          <div className={styles.label}>
            {t('reveal.missions.newMissionModal.department')}
          </div>
          <div className='input-element'>
            <Dropdown
              disabled={disabled}
              placeholder={t(
                'reveal.missions.newMissionModal.departmentPlaceholder',
              )}
              fluid
              value={foldering.departmentId}
              onChange={(e, { value }) =>
                onUpdateFoldering({
                  ...foldering,
                  departmentId: value as string,
                })
              }
              search={(options, query) => {
                return _.filter(
                  options,
                  (option) =>
                    lowerCaseAndUnaccent(option.text as string).indexOf(
                      lowerCaseAndUnaccent(query),
                    ) >= 0,
                );
              }}
              selection
              options={departmentOptions}
              clearable
              selectOnBlur={false}
            />
          </div>
        </div>
      )}
      {hasSections && (
        <div className={styles.inputContainer}>
          <div className={styles.label}>
            {t('reveal.missions.newMissionModal.section')}
          </div>
          <div className='input-element'>
            <Dropdown
              disabled={disabled}
              placeholder={t(
                'reveal.missions.newMissionModal.sectionPlaceholder',
              )}
              fluid
              value={foldering.sectionId}
              onChange={(e, { value }) =>
                onUpdateFoldering({
                  ...foldering,
                  sectionId: value as string,
                })
              }
              search={(options, query) => {
                return _.filter(
                  options,
                  (option) =>
                    lowerCaseAndUnaccent(option.text as string).indexOf(
                      lowerCaseAndUnaccent(query),
                    ) >= 0,
                );
              }}
              selection
              options={subDepartmentOptions}
              clearable
              selectOnBlur={false}
            />
          </div>
        </div>
      )}
      {hasSubsections && (
        <div className={styles.inputContainer}>
          <div className={styles.label}>
            {t('reveal.missions.newMissionModal.subsection')}
          </div>
          <div className='input-element'>
            <Dropdown
              disabled={disabled}
              placeholder={t(
                'reveal.missions.newMissionModal.subsectionPlaceholder',
              )}
              fluid
              value={foldering.subsectionId}
              onChange={(e, { value }) =>
                onUpdateFoldering({
                  ...foldering,
                  subsectionId: value as string,
                })
              }
              search={(options, query) => {
                return _.filter(
                  options,
                  (option) =>
                    lowerCaseAndUnaccent(option.text as string).indexOf(
                      lowerCaseAndUnaccent(query),
                    ) >= 0,
                );
              }}
              selection
              options={sectionOptions}
              clearable
              selectOnBlur={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MissionDepartmentsForm;
