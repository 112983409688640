import {
  FetchResult,
  gql,
  MutationHookOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';
import _ from 'underscore';

const HIDE_PROFILES_FROM_MISSION_SEARCH = gql`
  mutation hideProfilesFromMissionSearch(
    $searchPoolId: ID!
    $input: HideProfilesFromMissionSearchInput!
  ) {
    searchPool(id: $searchPoolId) {
      hideProfilesFromMissionSearch(input: $input) {
        results {
          profileId
          success
          error
        }
      }
    }
  }
`;

interface HideProfilesFromMissionSearchInput {
  missionId: string;
  profileIds: string[];
}

interface HideProfilesFromMissionSearchVariables {
  searchPoolId: string;
  input: HideProfilesFromMissionSearchInput;
}

interface HideProfileSuccess {
  success: true;
  profileId: string;
}

interface HideProfileFailure {
  success: false;
  profileId: string;
  error: string;
}

interface HideProfilesFromMissionSearchData {
  searchPool: {
    hideProfilesFromMissionSearch: {
      results: (HideProfileSuccess | HideProfileFailure)[];
    };
  };
}

interface UseHideProfilesFromMissionSearchInput {
  searchPoolId?: string;
  mutationOptions?: MutationHookOptions<
    HideProfilesFromMissionSearchData,
    HideProfilesFromMissionSearchVariables
  >;
}

type UseHideProfilesFromMissionSearchResult = [
  (
    input: HideProfilesFromMissionSearchInput,
  ) => Promise<FetchResult<HideProfilesFromMissionSearchData>>,
  MutationResult<HideProfilesFromMissionSearchData>,
];

const useHideProfilesFromMissionSearch = ({
  searchPoolId = 'reveal',
  mutationOptions = {},
}: UseHideProfilesFromMissionSearchInput = {}): UseHideProfilesFromMissionSearchResult => {
  const [mutation, result] = useMutation<
    HideProfilesFromMissionSearchData,
    HideProfilesFromMissionSearchVariables
  >(HIDE_PROFILES_FROM_MISSION_SEARCH, {
    ...mutationOptions,
  });

  return [
    (input) =>
      mutation({
        variables: { searchPoolId, input },
        update: (cache, { data }) => {
          _.each(
            data?.searchPool.hideProfilesFromMissionSearch.results || [],
            ({ success, profileId }) => {
              if (success) {
                cache.evict({
                  id: `SearchResultItem:{"searchPoolProfileId":"${profileId}"}`,
                });
                cache.modify({
                  id: 'SearchResultsCategory:{"categoryId":"all"}',
                  fields: (value, { fieldName }) =>
                    fieldName === 'count' && typeof value === 'number'
                      ? Math.max(value - 1, 0)
                      : value,
                });
              }
            },
          );
        },
      }),
    result,
  ];
};

export default useHideProfilesFromMissionSearch;
