import gql from 'graphql-tag';

export default gql`
  fragment IntegrationWorkflow on Workflow {
    id
    action {
      ... on GreenhouseUpload {
        service
      }
      ... on AshbyUpload {
        service
      }
      ... on PinpointUpload {
        service
      }
      ... on RecruiteeUpload {
        service
      }
      ... on TeamtailorUpload {
        service
      }
      ... on HubspotUpload {
        service
      }
      ... on SmartrecruitersUpload {
        service
      }
      ... on WelcomekitUpload {
        service
      }
      ... on WorkableUpload {
        service
      }
      ... on LeverUpload {
        service
      }
    }
  }
`;
