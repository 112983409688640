import React from 'react';

export default function DayTitles({ styles, size, space, padX, padY }) {
  const s = size + space * 2;
  const half = s / 2;
  const days = [
    {
      v: 'M',
      y: padY + s * 1 + half,
    },
    {
      v: 'W',
      y: padY + s * 3 + half,
    },
    {
      v: 'F',
      y: padY + s * 5 + half,
    },
  ];
  return (
    <g>
      {days.map((d) => (
        <text key={d.v} x={padX / 2} y={d.y} style={styles.text2}>
          {d.v}
        </text>
      ))}
    </g>
  );
}
