import React from 'react';
import _ from 'underscore';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const GenericTagFilter = ({ category, options, onChange, value }) => {
  const { t } = useTranslation();
  const categoryOptions = _.filter(options, (option) => {
    return option.value.startsWith(`${category}__`);
  });
  return (
    <Form.Dropdown
      fluid
      selection
      multiple
      search
      placeholder={t('common.select')}
      options={categoryOptions}
      onChange={onChange}
      value={value}
    />
  );
};

export default GenericTagFilter;
