import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

export const UpdateJobPositionDescription = gql`
  mutation UpdatePositionDescription(
    $input: UpdatePositionDescriptionInput!
    $searchPoolId: ID!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      updatePositionDescription(input: $input) {
        id
        positionDescription {
          description {
            type
            ... on MultiSectionsJobPostingDescription {
              type
              sections {
                type
                ... on SimpleJobPostingDescriptionSection {
                  type
                  content {
                    default
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type UpdateJobPositionDescriptionResult = Record<string, never>;

type UpdateJobPositionDescriptionVariables = {
  searchPoolId: string;
  input: {
    id: string;
    description: {
      multiSectionsJobPostingDescription: {
        type: string;
        sections: {
          simpleJobPostingDescriptionSection: {
            type: string;
            content: {
              default: string;
            };
          };
        }[];
      };
    };
  };
};

export default function useUpdateJobPositionDescription() {
  return useMutation<
    UpdateJobPositionDescriptionResult,
    UpdateJobPositionDescriptionVariables
  >(UpdateJobPositionDescription);
}
