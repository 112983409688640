import _ from 'underscore';
import React, { FC, useCallback, useMemo } from 'react';

import { getFullname } from '@/common/helpers/person';
import Toggle from '@/components/Common/Toggle/Toggle';
import GenericTextInput from '@/components/Common/GenericTextInput';
import { GenericTableRow, TwoLineCell } from '@/components/Common/GenericTable';
import { UserForEnrichmentIntegration } from '../../types';
import {
  useEnableSystemEnrichmentIntegration,
  useDisableSystemEnrichmentIntegration,
} from '../../../PeriodQuotaSettings/hooks';

import styles from './QuotaUserList.module.less';

interface UserQuotaItemProps {
  user: UserForEnrichmentIntegration;
  provider: string;
}

const UserQuotaItem: FC<UserQuotaItemProps> = ({ provider, user }) => {
  const userInheritedIntegration = useMemo(() => {
    const integration = _.findWhere(
      user?.userCurrentClientSettings?.enrichmentIntegrations || [],
      {
        type: provider,
        useClientIntegration: true,
      },
    );
    return integration;
  }, [user, provider]);

  const clientIntegration = useMemo(
    () => userInheritedIntegration?.clientIntegration,
    [userInheritedIntegration],
  );

  const defaultEnableVariables = useMemo(() => {
    return {
      type: provider,
      hasAcceptedTerms: false,
      userEmail: user.email,
      userIntegrationId: userInheritedIntegration?.id,
      clientIntegrationId: clientIntegration?.id,
    };
  }, [provider, user, userInheritedIntegration, clientIntegration]);

  const [enableOrEditUserIntegration] = useEnableSystemEnrichmentIntegration(
    defaultEnableVariables,
  );

  const [disableUserIntegration] = useDisableSystemEnrichmentIntegration({
    userIntegrationId: userInheritedIntegration?.id,
    userEmail: user.email,
  });

  const updateOrCreateUserIntegration = useCallback(
    ({ quota }: { quota?: number } = {}) => {
      const extraMutationParams = quota
        ? {
            monthlyQuota: quota,
          }
        : {};

      enableOrEditUserIntegration({
        variables: {
          input: {
            ...defaultEnableVariables,
            ...extraMutationParams,
          },
        },
      });
    },
    [enableOrEditUserIntegration, defaultEnableVariables],
  );

  const onQuotaChanged = useMemo(
    () =>
      _.debounce((value: string) => {
        if (!value) {
          return;
        }
        updateOrCreateUserIntegration({
          quota: +value,
        });
      }, 250),
    [updateOrCreateUserIntegration],
  );

  const onActivationChanged = useCallback(
    (event, { checked }) => {
      if (!checked && disableUserIntegration) {
        disableUserIntegration();
        return;
      }

      updateOrCreateUserIntegration();
    },
    [updateOrCreateUserIntegration, disableUserIntegration],
  );

  const userIntegrationIsActive = userInheritedIntegration?.status === 'active';
  const currentPeriod = userInheritedIntegration?.quota?.currentPeriod;
  const policy = userInheritedIntegration?.quota?.periodPolicy;
  return (
    <GenericTableRow>
      <td>
        <TwoLineCell line1={getFullname(user)} line2={user.email} />
      </td>
      <td>
        <Toggle
          checked={userIntegrationIsActive}
          onChange={onActivationChanged}
        />
      </td>
      <td>
        <GenericTextInput
          small
          type='number'
          defaultValue={policy?.totalCreditsCount || 0}
          onValue={onQuotaChanged}
        />
      </td>
      <td>
        <span>
          {policy?.totalCreditsCount ? (
            <span>
              {currentPeriod?.usedCreditsCount} /{' '}
              {currentPeriod?.totalCreditsCount}
            </span>
          ) : (
            '-'
          )}
        </span>
      </td>
    </GenericTableRow>
  );
};

interface QuotaUserListProps {
  users: UserForEnrichmentIntegration[];
  provider: string;
}

const QuotaUserList: FC<QuotaUserListProps> = ({ users, provider }) => {
  if (!users) {
    return null;
  }

  return (
    <tbody className={styles.userList}>
      {users.map((user) => (
        <UserQuotaItem key={user.email} provider={provider} user={user} />
      ))}
    </tbody>
  );
};

export default QuotaUserList;
