import { TFunction } from 'i18next';
import _ from 'underscore';

const months = [
  'Jan.',
  'Feb.',
  'Mar.',
  'Apr.',
  'May',
  'Jun.',
  'Jul.',
  'Aug.',
  'Sep.',
  'Oct.',
  'Nov.',
  'Dec.',
];

export const formatDate = (date: any, defaultValue?: any) => {
  if (!date) {
    return defaultValue || '';
  }
  const { raw, month, year } = date;
  if (!_.isNumber(month) && !year) {
    return raw || defaultValue || '';
  }
  if (!_.isNumber(month)) {
    return year;
  }
  return `${months[month]} ${year}`;
};

export const formatDates = ({
  startDate,
  endDate,
}: {
  startDate: any;
  endDate: any;
}): {
  start: any;
  end: any;
} | null => {
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);
  if (formattedStartDate === '' && formattedEndDate === '') {
    return null;
  }
  const start = formattedStartDate;
  let end;
  if (formattedStartDate === '') {
    end = formattedEndDate;
  } else {
    end = formattedEndDate || 'Today';
  }
  return { start, end };
};

export const formatTimestampToDate = (timestamp: any, t: TFunction): string => {
  if (timestamp) {
    if (+timestamp) {
      return t('common.formattedDate', {
        date: new Date(+timestamp),
      });
    }
    return t('common.defaultDuration');
  }
  return t('profile.contact.timeline.aMomentAgo');
};
