import React, { useEffect, useState, useRef, useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import {
  SNIPPET_TYPES,
  MergeTagsVariable,
  MERGE_TAGS_VARIABLES_BASE_IDS,
} from '@/common/mergeTags/utils';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';

import MergeTagsCustomFieldsOptions from './components/MergeTagsCustomFieldsOptions';
import MergeTagsMissionOptions from './components/MergeTagsMissionOptions';
import MergeTagsVariablesOptions from './components/MergeTagsVariableOptions';
import MergeTagsCustomVariablesOptions from './components/MergeTagsCustomVariablesOptions';
import {
  MergeTagsSelectorOption,
  generateOptions,
  CONTACT_VARIABLES,
  MORE_CONTACT_VARIABLES,
} from './utils';
import MergeTagsConditionVariablesOptions from './components/MergeTagsConditionVariablesOptions';

import styles from './MergeTagsSelector.module.less';

type Props = {
  clientId: string;
  currentSequenceVariables: MergeTagsVariable[];
  onClose: () => void;
  onVariableSelected: (variable: MergeTagsSelectorOption) => void;
  withConditionsChainingVariable: boolean;
  withClientFragmentSnippets?: boolean;
};

type SubMenuState =
  | 'all'
  | 'contact-variables'
  | 'placeholders'
  | 'custom-variables'
  | 'mission-variables'
  | 'conditions-chaining-variables';

const MergeTagsSelector: React.FC<Props> = ({
  clientId,
  currentSequenceVariables,
  onClose,
  onVariableSelected,
  withConditionsChainingVariable = true,
  withClientFragmentSnippets = true,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { t } = useTranslation();
  const permissions = useClientPermissions(clientId).data?.client?.permissions;

  const [subMenuSelected, setSubMenuSelected] = useState<SubMenuState>('all');

  const hasPermission = !!permissions?.advancedMergeTags;

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  const currentSequenceVariablesOptions: MergeTagsSelectorOption[] = useMemo(() => {
    const variables = _.uniq(
      _.filter(
        currentSequenceVariables,
        (variable) =>
          variable.type === SNIPPET_TYPES.PLACEHOLDER ||
          variable.type === SNIPPET_TYPES.CONDITIONS_CHAINING,
      ),
      (placeholder) => placeholder.name,
    );
    return _.map(variables, (variable) => ({
      key: variable.id,
      label: variable.name,
      type: variable.type,
    }));
  }, [currentSequenceVariables]);

  const handleSelectVariable = (variable: MergeTagsSelectorOption) => {
    onVariableSelected(variable);
    onClose();
  };

  const generateTranslatedOptions = useMemo(() => generateOptions(t), [t]);

  const contactVariablesOptions = useMemo(
    () => generateTranslatedOptions(CONTACT_VARIABLES),
    [generateTranslatedOptions],
  );

  const moreContactVariablesOptions = useMemo(
    () => generateTranslatedOptions(MORE_CONTACT_VARIABLES),
    [generateTranslatedOptions],
  );

  return (
    <div
      ref={ref}
      className={classNames(
        styles.wrapper,
        location.pathname.includes('templates') && styles.wrapperTemplate,
      )}
      onBlur={(event) => {
        if ((event.relatedTarget as HTMLButtonElement)?.type !== 'button') {
          onClose();
        }
      }}
      tabIndex={0}
    >
      {subMenuSelected === 'all' && (
        <>
          <span className={styles.sectionTitle}>
            {t('editor.mergeTagsSelector.contactVariables')}
          </span>
          <MergeTagsVariablesOptions
            options={contactVariablesOptions}
            onSelectVariable={handleSelectVariable}
            hasPermission={hasPermission}
          />
          <span
            className={styles.subSectionTitle}
            aria-hidden='true'
            onClick={() => setSubMenuSelected('contact-variables')}
          >
            {t('editor.mergeTagsSelector.moreContactVariables')}
            <Icon className={styles.icon} size='small' name='chevron right' />
          </span>

          <hr className={styles.hr} />

          {withConditionsChainingVariable && (
            <span
              className={styles.subSectionTitle}
              aria-hidden='true'
              onClick={() =>
                setSubMenuSelected('conditions-chaining-variables')
              }
            >
              {t('editor.mergeTagsSelector.conditionVariables')}
              <Icon className={styles.icon} size='small' name='chevron right' />
            </span>
          )}

          {hasPermission && (
            <span
              className={styles.subSectionTitle}
              aria-hidden='true'
              onClick={() => setSubMenuSelected('mission-variables')}
            >
              {t('editor.mergeTagsSelector.missionVariables')}
              <Icon className={styles.icon} size='small' name='chevron right' />
            </span>
          )}

          <span
            className={styles.subSectionTitle}
            aria-hidden='true'
            onClick={() => setSubMenuSelected('custom-variables')}
          >
            {t('editor.mergeTagsSelector.myCustomVariables')}
            <Icon className={styles.icon} size='small' name='chevron right' />
          </span>

          {!_.isEmpty(currentSequenceVariablesOptions) && (
            <>
              <hr className={styles.hr} />
              <span className={styles.sectionTitle}>
                {t('editor.mergeTagsSelector.mySequenceVariables')}
              </span>
              <MergeTagsVariablesOptions
                options={currentSequenceVariablesOptions}
                onSelectVariable={({ key, label, type }) =>
                  handleSelectVariable({
                    key,
                    label,
                    type,
                    isClientSnippet: true,
                  })
                }
                hasPermission={hasPermission}
              />
            </>
          )}

          <hr className={styles.hr} />
          <span
            className={styles.subSectionTitle}
            aria-hidden='true'
            onClick={() =>
              handleSelectVariable({
                key: MERGE_TAGS_VARIABLES_BASE_IDS.PLACEHOLDER,
                label: t('editor.placeholderKey'),
                type: SNIPPET_TYPES.PLACEHOLDER,
              })
            }
          >
            <span className={styles.textWithIcon}>
              <i className='ri-add-line' />
              {t('editor.mergeTagsSelector.addPlaceholder')}
            </span>
          </span>
        </>
      )}
      {subMenuSelected !== 'all' && (
        <>
          <span
            aria-hidden='true'
            className={classNames(styles.sectionTitle, styles.pointer)}
            onClick={() => setSubMenuSelected('all')}
          >
            <Icon className={styles.icon} size='small' name='chevron left' />
            {t('editor.mergeTagsSelector.allVariables')}
          </span>
          <hr className={styles.hr} />
        </>
      )}
      {subMenuSelected === 'contact-variables' && (
        <>
          <MergeTagsVariablesOptions
            options={moreContactVariablesOptions}
            onSelectVariable={handleSelectVariable}
            hasPermission={hasPermission}
          />
          <MergeTagsCustomFieldsOptions
            clientId={clientId}
            onSelectVariable={handleSelectVariable}
            hasPermission={hasPermission}
          />
        </>
      )}
      {subMenuSelected === 'custom-variables' && (
        <MergeTagsCustomVariablesOptions
          onSelectVariable={handleSelectVariable}
          hasPermission={hasPermission}
          clientId={clientId}
          withClientFragmentSnippets={withClientFragmentSnippets}
          withConditionsChainingVariable={withConditionsChainingVariable}
        />
      )}
      {subMenuSelected === 'mission-variables' && (
        <MergeTagsMissionOptions
          onSelectVariable={handleSelectVariable}
          hasPermission={hasPermission}
          clientId={clientId}
        />
      )}
      {subMenuSelected === 'conditions-chaining-variables' && (
        <MergeTagsConditionVariablesOptions
          onSelectVariable={handleSelectVariable}
          hasPermission={hasPermission}
        />
      )}
    </div>
  );
};

export default MergeTagsSelector;
