import classNames from 'classnames';
import React from 'react';

import styles from './Counter.module.less';

interface CounterProps {
  value: number;
  active?: boolean;
  className?: string;
}

const Counter: React.FC<CounterProps> = ({ value, active, className }) => (
  <span
    className={classNames(
      styles.counter,
      { [styles.active]: active },
      className,
    )}
  >
    {value}
  </span>
);

export default Counter;
