import React, { useState } from 'react';
import {
  GET_GENERIC_SEGMENTATION_COUNTS,
  GET_GENERIC_SEGMENTATION_PROFILES,
} from '@/hocs/searchPool/withRevealSearchPoolResults';
import GenericButton from '@/components/Common/GenericButton';
import { GenericSegmentationItem } from '../MissionProfilesGenericView/GenericKanban/GenericKanbanColumn';
import GenericSegmentationView from '../MissionProfilesGenericView/GenericSegmentationView/GenericSegmentationView';
import KanbanProfileCard from '../MissionProfilesGenericView/GenericKanban/GenericKanbanColumn/GenericKanbanCard/KanbanProfileCard/index';
import DataLoaderSegmentProfilesContextProvider from '../MissionProfilesGenericView/DataLoaderSegmentProfilesContext';

export const getRandomColor = () => {
  return `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')}`;
};

const WidgetPlayground: React.FC = () => {
  const [tab, setTab] = useState<'cross-project' | 'ats' | 'new-kanban'>(
    'new-kanban',
  );

  // return
  // return <ATSProfilesKanban />;
  return (
    <>
      <div
        style={{
          padding: '12px',
        }}
      >
        <GenericButton primacy='secondary' onClick={() => setTab('new-kanban')}>
          New kanban
        </GenericButton>
      </div>

      {tab === 'new-kanban' && <NewKanban />}
    </>
  );
};

const NewKanban = () => {
  const segmentDefinitions = [
    {
      // @see: https://www.notion.so/hiresweet/GenericComponent-Kanban-76c25dbeab4145a494e30b32f7d2e9cc
      segmentId: 'pending',
      title: 'Not contacted',
      items: [],
      color: `rgb(${getRandomColor()})`,
      filters: {
        includeArchivedProjects: false,
        jobIds: [],
      },
      // ...
    },
    {
      // @see: https://www.notion.so/hiresweet/GenericComponent-Kanban-76c25dbeab4145a494e30b32f7d2e9cc
      segmentId: 'in-progress',
      title: 'Contacted',
      items: [],
      color: `rgb(${getRandomColor()})`,
      filters: {
        jobIds: [],
      },
      // ...
    },
    {
      // @see: https://www.notion.so/hiresweet/GenericComponent-Kanban-76c25dbeab4145a494e30b32f7d2e9cc
      segmentId: 'replied',
      title: 'Replied',
      items: [],
      color: `rgb(${getRandomColor()})`,
      filters: {
        jobIds: [],
      },
      // ...
    },
    {
      segmentId: 'interested',
      title: 'Interested',
      items: [],
      color: `rgb(${getRandomColor()})`,
      filters: {
        includeArchivedProjects: false,
        jobIds: [],
      },
    },
    {
      segmentId: 'hired',
      title: 'Hired',
      items: [],
      color: `rgb(${getRandomColor()})`,
      filters: {
        includeArchivedProjects: false,
        jobIds: [],
      },
    },
    {
      segmentId: 'archived',
      title: 'Archived',
      items: [],
      color: `rgb(${getRandomColor()})`,
      filters: {
        includeArchivedProjects: false,
        jobIds: [],
      },
    },
  ];

  const renderItem = (item: GenericSegmentationItem, itemLoading: boolean) => (
    <KanbanProfileCard profileId={item.id} itemLoading={itemLoading} />
  );

  return (
    <DataLoaderSegmentProfilesContextProvider
      query={GET_GENERIC_SEGMENTATION_PROFILES}
      queryCount={GET_GENERIC_SEGMENTATION_COUNTS}
      queryOptions={{
        variables: {
          searchPoolId: 'reveal',
          input: {},
        },
        fetchPolicy: 'network-only',
      }}
    >
      <GenericSegmentationView
        segmentDefinitions={segmentDefinitions}
        displayType='kanban'
        renderItem={renderItem}
      />
    </DataLoaderSegmentProfilesContextProvider>
  );
};

export default WidgetPlayground;
