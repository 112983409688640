import { useContext } from 'react';
import _ from 'underscore';
import { useRouteMatch } from 'react-router-dom';
import useClientPermissions from '../../../graphql/hooks/clients/useClientPermissions';
import useUpdateUserApplicationMode from '../../../graphql/hooks/users/useUpdateUserApplicationMode';
import useUserFromJWToken from '../../../graphql/hooks/users/useUserFromJWToken';
import { AdminApplicationModeContext } from './AdminApplicationModeContext';

// also used inside NavConditional and ApplicationModeAutoSwitcher
export const useNavApplicationMode = () => {
  const { params } = useRouteMatch<{ clientId: string }>();
  const { clientId } = params;
  const { loading, data: userData, error } = useUserFromJWToken();
  const [
    updateApplicationMode,
    { loading: updateLoading },
  ] = useUpdateUserApplicationMode(clientId, userData?.user?.id);

  const onlyReveal = userData?.user?.onlyReveal;
  const lastApplicationMode = userData?.user?.applicationMode;
  const applicationMode = onlyReveal
    ? 'reveal'
    : lastApplicationMode || 'classic';

  // we need to fix the graphql based applicationMode for admins because it's set into user.clients and
  // admins don't have it so the updates won't work.
  const isAdmin = userData?.user?.isAdmin;

  const { adminApplicationMode, setAdminApplicationMode } = useContext(
    AdminApplicationModeContext,
  );

  const applicationModeFixed = isAdmin ? adminApplicationMode : applicationMode;
  const updateApplicationModeFixed = isAdmin
    ? setAdminApplicationMode
    : updateApplicationMode;

  return {
    onlyReveal,
    applicationMode: applicationModeFixed,
    loading,
    error,
    updateLoading,
    updateApplicationMode: updateApplicationModeFixed,
  };
};

const useNavSwitch = (clientId: string) => {
  const {
    loading: userLoading,
    error: userError,
    applicationMode,
    onlyReveal,
    updateApplicationMode,
    updateLoading,
  } = useNavApplicationMode();
  const {
    loading: permissionsLoading,
    data: permissionsData,
    error: permissionsError,
  } = useClientPermissions(clientId);
  if (
    userLoading ||
    permissionsLoading ||
    !_.isEmpty(userError) ||
    !_.isEmpty(permissionsError)
  ) {
    return {
      canShowSwitch: false,
      applicationMode: 'classic',
      updateApplicationMode,
      disabled: true,
    };
  }
  const { reveal } = permissionsData?.client?.permissions || {};

  const canShowSwitch = reveal && !onlyReveal;

  return {
    canShowSwitch,
    applicationMode,
    updateApplicationMode,
    disabled: updateLoading,
  };
};
export default useNavSwitch;
