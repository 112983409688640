import _, { isEmpty } from 'underscore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useClientProfileCustomFields, {
  ResultProfileCustomFields,
} from '@/graphql/hooks/clients/useClientProfileCustomFields';
import NewCustomCriteriaFilter from './NewCustomCriteriaFilter';
import CustomCriteriaFilterButton from './CustomCriteriaFilterButton';
import CustomCriteriaFiltersTemplate from './CustomCriteriaFilterTemplate';
import AccordionFilter from '../components/AccordionFilter';
import { APIFilter, PropertyFilter } from './types';
import { stateToJSONParams } from './stateToJsonParams';
import { jsonParamsToState } from './jsonParamsToState';

import './CustomCriteriaFilters.css';

const getRandomKey = () => {
  return `${Math.random()}`.slice(2, 12);
};

type Props = {
  clientId: string;
  count: number;
  defaultParams: APIFilter[];
  onChange: (value: any) => void;
  withoutAccordion: boolean | null;
};

const CustomCriteriaFilters: React.FC<Props> = ({
  clientId,
  count,
  defaultParams,
  onChange,
  withoutAccordion,
}) => {
  const [filters, setFilters] = useState<PropertyFilter[]>([]);
  const { t, i18n } = useTranslation();

  const initFilterState = (data?: ResultProfileCustomFields) => {
    const filtersFromState = jsonParamsToState(
      defaultParams,
      data?.client?.profileCustomFields ?? [],
      t,
    );
    setFilters(
      _.map(filtersFromState, (filter) => ({ key: getRandomKey(), ...filter })),
    );
  };

  const { profileCustomFields } = useClientProfileCustomFields(
    clientId,
    initFilterState,
  );

  useEffect(() => {
    if (isEmpty(defaultParams)) {
      setFilters([]);
    } else {
      const filtersFromState = jsonParamsToState(
        defaultParams,
        profileCustomFields,
        t,
      );
      setFilters(
        _.map(filtersFromState, (filter) => ({
          key: getRandomKey(),
          ...filter,
        })),
      );
    }
  }, [defaultParams, t, profileCustomFields]);

  const handleNewCriteria = (filterToAdd: PropertyFilter) => {
    const newFilters = [...filters, { key: getRandomKey(), ...filterToAdd }];
    setFilters(newFilters);
    bubbleUpFilter(newFilters);
  };

  const handleEditFilter = (index: number) => (
    editedFilter: PropertyFilter | null,
  ) => {
    if (!filters[index] || !editedFilter) {
      return;
    }

    setFilters([
      ...filters.slice(0, index),
      editedFilter,
      ...filters.slice(index + 1),
    ]);
  };

  const handleEditDiversityFilter = (editedFilter: PropertyFilter | null) => {
    const diversityFilter = _.findWhere(filters, { id: 'diversity' });
    if (!diversityFilter || !editedFilter) {
      return;
    }
    setFilters(
      _.map(filters, (filter) => {
        if (filter.id === 'diversity') {
          return editedFilter;
        }
        return filter;
      }),
    );
  };

  const removeFilter = (key?: string) => () => {
    const newFilters = _.reject(filters, (filter) => filter.key === key);
    setFilters(newFilters);
    bubbleUpFilter(newFilters);
  };

  const bubbleUpFilter = (newFilters: PropertyFilter[]) => {
    const JSONParams = stateToJSONParams(newFilters, i18n);
    onChange(JSONParams);
  };

  const requestFilter = () => {
    bubbleUpFilter(filters);
  };

  const content = (
    <>
      <div className='subtitle'>
        {t('reveal.searchView.search.pastActivity.label')}
      </div>
      <div className='filter-list'>
        {_.filter(
          filters,
          ({ id }) =>
            id === 'last-email-interaction' ||
            id === 'last-ats-interaction' ||
            id === 'last-interaction',
        ).map((filter, index) => (
          <CustomCriteriaFiltersTemplate
            key={`${filter.key}`}
            setSelectedPropertyFilter={handleEditFilter(index)}
            selectedPropertyFilter={filter}
            triggerButton={
              // The div wrapper is essential here otherwise semantic doesn't recognize the onclick event
              <div>
                <CustomCriteriaFilterButton
                  filter={filter}
                  onRemoveFilter={removeFilter(filter.key)}
                />
              </div>
            }
            onClose={requestFilter}
            clientId={clientId}
          />
        ))}
      </div>
      <NewCustomCriteriaFilter
        onSave={handleNewCriteria}
        clientId={clientId}
        mode='pastActivity'
      />

      <br />

      <div className='subtitle'>
        {t('reveal.searchView.search.filterContactInformation')}
      </div>
      <div className='filter-list'>
        {_.where(filters, { id: 'contact' }).map((filter, index) => (
          <CustomCriteriaFiltersTemplate
            key={`${filter.key}`}
            setSelectedPropertyFilter={handleEditFilter(index)}
            selectedPropertyFilter={filter}
            triggerButton={
              // The div wrapper is essential here otherwise semantic doesn't recognize the onclick event
              <div>
                <CustomCriteriaFilterButton
                  filter={filter}
                  onRemoveFilter={removeFilter(filter.key)}
                />
              </div>
            }
            onClose={requestFilter}
            clientId={clientId}
          />
        ))}
      </div>
      <NewCustomCriteriaFilter
        onSave={handleNewCriteria}
        clientId={clientId}
        mode='contactInformation'
      />

      <br />

      <div className='subtitle'>
        {' '}
        {t('reveal.searchView.search.filterDiversity')}
      </div>
      <div className='filter-list'>
        {_.where(filters, { id: 'diversity' }).map((filter) => (
          <CustomCriteriaFiltersTemplate
            key={`${filter.key}`}
            setSelectedPropertyFilter={handleEditDiversityFilter}
            selectedPropertyFilter={filter}
            triggerButton={
              <div>
                <CustomCriteriaFilterButton
                  filter={filter}
                  onRemoveFilter={removeFilter(filter.key)}
                />
              </div>
            }
            onClose={requestFilter}
            clientId={clientId}
          />
        ))}
      </div>
      {_.isEmpty(_.filter(filters, ({ id }) => id === 'diversity')) && (
        <NewCustomCriteriaFilter
          onSave={handleNewCriteria}
          clientId={clientId}
          mode='diversityInformation'
        />
      )}

      <br />

      {profileCustomFields.length > 0 && (
        <>
          <div className='subtitle'>
            {t('reveal.searchView.search.filterCustomProperties')}
          </div>
          <div className='filter-list'>
            {_.reject(
              filters,
              ({ id }) =>
                id === 'contact' ||
                id === 'diversity' ||
                id === 'keywords' ||
                id === 'last-email-interaction' ||
                id === 'last-ats-interaction' ||
                id === 'last-interaction',
            ).map((filter, index) => (
              <CustomCriteriaFiltersTemplate
                key={`${filter.key}`}
                setSelectedPropertyFilter={handleEditFilter(index)}
                selectedPropertyFilter={filter}
                triggerButton={
                  // The div wrapper is essential here otherwise semantic doesn't recognize the onclick event
                  <div>
                    <CustomCriteriaFilterButton
                      filter={filter}
                      onRemoveFilter={removeFilter(filter.key)}
                    />
                  </div>
                }
                onClose={requestFilter}
                clientId={clientId}
              />
            ))}
          </div>
          <NewCustomCriteriaFilter
            onSave={handleNewCriteria}
            clientId={clientId}
            mode='customFields'
          />
          <br />
        </>
      )}

      <div className='subtitle'>
        {t('reveal.searchView.search.keywords.label')}
      </div>
      <div className='filter-list'>
        {_.filter(filters, ({ id }) => id === 'keywords').map(
          (filter, index) => (
            <CustomCriteriaFiltersTemplate
              key={`${filter.key}`}
              setSelectedPropertyFilter={handleEditFilter(index)}
              selectedPropertyFilter={filter}
              triggerButton={
                // The div wrapper is essential here otherwise semantic doesn't recognize the onclick event
                <div>
                  <CustomCriteriaFilterButton
                    filter={filter}
                    onRemoveFilter={removeFilter(filter.key)}
                  />
                </div>
              }
              onClose={requestFilter}
              clientId={clientId}
            />
          ),
        )}
      </div>
      <NewCustomCriteriaFilter
        onSave={handleNewCriteria}
        clientId={clientId}
        mode='keywords'
      />

      <br />
    </>
  );

  return (
    <div className='custom-criteria-filters'>
      {withoutAccordion ? (
        content
      ) : (
        <AccordionFilter
          title={t('reveal.searchView.search.moreFilters')}
          count={count}
        >
          {content}
        </AccordionFilter>
      )}
    </div>
  );
};

export default CustomCriteriaFilters;
