import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import _ from 'underscore';

import GenericButton from '@/components/Common/GenericButton';
import useOfferPipeStepStats from '@/graphql/hooks/offers/useOfferPipeStepStats';

import RecommendationsIllustration from './RecommendationsIllustration';

import style from './InstantFlowSuccessMessage.module.less';

type InstantFlowSuccessMessageProps = {
  offerId: string;
  onClose: () => void;
};

const InstantFlowSuccessMessage: FC<InstantFlowSuccessMessageProps> = ({
  offerId,
  onClose,
}) => {
  const { t } = useTranslation();
  const { pipeStepStats } = useOfferPipeStepStats({ offerId });

  const { main, extended } = useMemo(() => {
    const result = {
      main: 0,
      extended: 0,
    };
    _.each(pipeStepStats, ({ stepId, pipeSubStepStats }) => {
      if (stepId !== 'pending') {
        return;
      }
      _.each(pipeSubStepStats || [], ({ count, subStepId }) => {
        if (subStepId === 'pending-main') {
          result.main = count;
        }
        if (subStepId === 'pending-extended') {
          result.extended = count;
        }
      });
    });
    return result;
  }, [pipeStepStats]);

  return (
    <div className={style.container}>
      <RecommendationsIllustration />
      <div className={style.title}>
        {t('offers.instantFlow.successModal.title', { count: main + extended })}
      </div>
      <div className={style.bullet}>
        <div className={classNames(style.count, style.main)}>{main}</div>
        <div className={style.paragraph}>
          <Trans
            i18nKey='offers.instantFlow.successModal.main'
            values={{ count: main }}
            components={{ b: <strong /> }}
          />
        </div>
      </div>
      <div className={style.bullet}>
        <div className={classNames(style.count, style.extended)}>
          {extended}
        </div>
        <div className={style.paragraph}>
          <Trans
            i18nKey='offers.instantFlow.successModal.extended'
            values={{ count: extended }}
            components={{ b: <strong /> }}
          />
        </div>
      </div>
      <div className={style.cta}>
        {t('offers.instantFlow.successModal.cta', { count: main + extended })}
      </div>
      <GenericButton className={style.button} size='big' onClick={onClose}>
        {t('offers.instantFlow.successModal.button')}
      </GenericButton>
    </div>
  );
};

export default InstantFlowSuccessMessage;
