import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import usePagination from '@/hooks/ui/usePagination';
import HeaderRow from '@/components/ProfileRow/HeaderRow/HeaderRow';
import AvatarColumn from '@/components/ProfileRow/columns/AvatarColumn';
import NameAndHeadlineColumn from '@/components/ProfileRow/columns/NameAndHeadlineColumn';
import ProfileRow from '../ProfileRow';
import { ArchiveDetail } from './types';
import ArchiveActionColumn from './ArchiveActionColumn';

import styles from './StatsProfileAndArchiveActionTable.module.less';

interface StatsProfileAndArchiveActionTableProps {
  archiveDetails: ArchiveDetail[];
}

const StatsProfileAndArchiveActionTable: FC<StatsProfileAndArchiveActionTableProps> = ({
  archiveDetails,
}) => {
  const { t } = useTranslation();

  const { pageElements, numberOfPages, PageSelector } = usePagination(
    archiveDetails,
  );

  return (
    <div className={styles.table}>
      <HeaderRow
        columns={[
          { id: 'avatar', title: '', className: styles.avatar },
          {
            id: 'contact',
            title: t('reveal.missions.mission.tableHeader.contact'),
            className: styles.nameAndHeadline,
          },
          {
            id: 'archiveAction',
            title: t('reveal.missions.mission.tableHeader.mission'),
            className: styles.archiveAction,
          },
        ]}
      />
      <div>
        {_.map(
          pageElements,
          ({ profileId, missionId, day, fromStage, authorFullname }, index) => (
            <ProfileRow
              key={`${missionId}_${profileId}_${day}_${index}`}
              profileId={profileId}
              columns={[
                {
                  id: 'avatar',
                  component: AvatarColumn,
                  className: styles.avatar,
                },
                {
                  id: 'contact',
                  component: NameAndHeadlineColumn,
                  className: styles.nameAndHeadline,
                },
                {
                  id: 'archiveAction',
                  component: ({ profileLoading }) => (
                    <ArchiveActionColumn
                      profileLoading={profileLoading}
                      authorFullname={authorFullname}
                      fromStage={fromStage}
                      day={day}
                      missionId={missionId}
                      className={styles.archiveAction}
                    />
                  ),
                },
              ]}
            />
          ),
        )}
      </div>
      {numberOfPages > 1 && (
        <div className={styles.pagination}>
          <PageSelector />
        </div>
      )}
    </div>
  );
};

export default StatsProfileAndArchiveActionTable;
