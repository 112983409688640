import React, { FC } from 'react';

import { TimeSpan } from '../../components/RevealAnalyticsHeader/RevealAnalyticsHeader';
import CampaignFunnels from './CampaignFunnels';

interface MarketingTabProps {
  timeSpan: TimeSpan;
}

const MarketingTab: FC<MarketingTabProps> = ({ timeSpan }) => (
  <CampaignFunnels timeSpan={timeSpan} />
);

export default MarketingTab;
