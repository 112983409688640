type PromiseResolver<T> = (value?: T | PromiseLike<T>) => void;

export function debounce<Args extends unknown[], Result>(
  fn: (...args: Args) => Result,
  delay: number,
): (...args: Args) => Promise<Result> {
  let timeout: NodeJS.Timeout | undefined;
  let pendingResolvers: PromiseResolver<Result>[] = [];

  return (...args: Args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      const result = fn(...args);
      pendingResolvers.forEach((resolver) => resolver(result));
      pendingResolvers = [];
    }, delay);
    return new Promise((resolve) => pendingResolvers.push(resolve));
  };
}

export function debouncePromise<Args extends unknown[], Result>(
  fn: (...args: Args) => Promise<Result>,
  delay: number,
): (...args: Args) => Promise<Result> {
  const debouncedFn = debounce(fn, delay);
  return (...args: Args) =>
    new Promise((resolve) =>
      debouncedFn(...args).then((result) => resolve(result)),
    );
}
