import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import onLogAction from '../../common/logAction';
import TranslatableText from '../TranslatableText';
import './ActivityTimeline.css';
import {
  getOfferActivityDescription,
  getOfferActivityIconClass,
  getProfileActivityDescription,
  getProfileActivityIconClass,
  getProfilesActivityDescription,
  getProjectActivityDescription,
  getProjectActivityIconClass,
  getTasksActivityDescription,
  getTasksActivityIconClass,
  getProjectTypeFromActivity,
} from './helpers';

const DEFAULT_EVENTS_LIMIT = 4;
const SEE_MORE_STEP = 8;

const ActivityTimeline = ({ clientId, activityEvents: activityEventsParams, user }) => {
  const { t } = useTranslation();
  const activityEvents = activityEventsParams || [];
  const [eventsCount, setEventsCount] = useState(DEFAULT_EVENTS_LIMIT);
  const displayedEvents = activityEvents.slice(0, eventsCount);
  const showSeeMore = displayedEvents.length < activityEvents.length;

  const seeMore = () => {
    setEventsCount((oldCount) => oldCount + SEE_MORE_STEP);
  };

  const logAction = ({ type }) => {
    return onLogAction({
      type,
      info: {
        clientId,
        from: 'activity-dashboard',
      },
    });
  };
  return (
    <div className='activity-timeline'>
      {_.map(displayedEvents, (activity, index) => (
        <ActivityTimelineItem
          key={index}
          clientId={clientId}
          activity={activity}
          user={user}
          logAction={logAction}
        />
      ))}
      {showSeeMore && <ExpandReduceItem seeMore={seeMore} t={t} />}
    </div>
  );
};

const ExpandReduceItem = ({ seeMore, t }) => {
  return (
    <div className='activity-timeline-item profiles-activity'>
      <div className='activity-icon-container'>
        <div className='activity-icon'>
          <i className='ri-add-line' />
        </div>
      </div>
      <div className='activity-content'>
        <span className='activity-description see-more-toggle body-medium' onClick={seeMore}>
          {t('dashboard.activity.seeMore')}
        </span>
      </div>
    </div>
  );
};

const ActivityTimelineItem = ({ clientId, activity, user, logAction }) => {
  switch (activity.__typename) {
    case 'ProfilesActivityEvent':
      return <ProfilesActivity clientId={clientId} activity={activity} logAction={logAction} />;
    case 'SingleProfileActivityEvent':
      return <ProfileActivity clientId={clientId} activity={activity} logAction={logAction} />;
    case 'OfferActivityEvent':
    case 'OfferPushFlowPolicyActivityEvent':
      return <OfferActivity clientId={clientId} activity={activity} logAction={logAction} />;
    case 'UserActivityEvent':
      return <UserActivity clientId={clientId} activity={activity} logAction={logAction} currentUser={user} />;
    case 'TasksActivityEvent':
      return <TasksActivity clientId={clientId} activity={activity} logAction={logAction} />;
    case 'SingleTaskActivityEvent':
      return <SingleTaskActivity clientId={clientId} activity={activity} logAction={logAction} />;
    case 'ProjectActivityEvent':
      return <ProjectActivity clientId={clientId} activity={activity} logAction={logAction} />;
    default:
      return null;
  }
};

const ProfilesActivity = ({ clientId, activity, logAction }) => {
  const { t } = useTranslation();
  // TODO: we filter out positive answers which are displayed in the NotificationsDashboard
  if (activity?.subtype === 'positive-answer-detected') {
    return null;
  }
  const activityDescription = getProfilesActivityDescription({ activity, t });
  return (
    <GenericActivity
      className='profiles-activity'
      iconClassName='ri-send-plane-fill'
      description={activityDescription}
      date={activity?.date}
    >
      <ActivityOfferLink clientId={clientId} offer={activity?.offer} logAction={logAction} />
    </GenericActivity>
  );
};

const ProfileActivity = ({ clientId, activity, logAction }) => {
  const { t } = useTranslation();
  const activityDescription = getProfileActivityDescription({
    activity,
    t,
  });
  const activityIconClass = getProfileActivityIconClass({ activity });
  return (
    <GenericActivity
      className='profile-activity'
      iconClassName={activityIconClass}
      description={(
        <>
          <ProfileName
            profile={activity?.profile}
            clientId={clientId}
            offerId={activity?.offer?.id}
            logAction={logAction}
          />
          {activityDescription}
        </>
      )}
      date={activity?.date}
    >
      <ActivityOfferLink clientId={clientId} offer={activity?.offer} logAction={logAction} />
    </GenericActivity>
  );
};

const TasksActivity = ({ clientId, logAction, activity }) => {
  const { t } = useTranslation();
  const activityIconClass = getTasksActivityIconClass({ activity });
  const activityDescription = getTasksActivityDescription({ activity, t });
  const projectType = getProjectTypeFromActivity({ activity });
  return (
    <GenericActivity
      className='tasks-activity'
      iconClassName={activityIconClass}
      description={activityDescription}
      date={activity?.date}
    >
      <ActivityProjectLink
        clientId={clientId}
        logAction={logAction}
        project={activity.project}
        projectType={projectType}
      />
    </GenericActivity>
  );
};

const SingleTaskActivity = ({ clientId, logAction, activity }) => {
  const { t } = useTranslation();
  const activityIconClass = getTasksActivityIconClass({ activity });
  const activityDescription = getTasksActivityDescription({ activity, t });
  const projectType = getProjectTypeFromActivity({ activity });

  return (
    <GenericActivity
      className='tasks-activity'
      iconClassName={activityIconClass}
      description={activityDescription}
      date={activity?.date}
    >
      <ActivityTaskLink clientId={clientId} logAction={logAction} task={activity.task} projectType={projectType} />
    </GenericActivity>
  );
};

const ProjectActivity = ({ clientId, logAction, activity }) => {
  const { t } = useTranslation();
  const activityIconClass = getProjectActivityIconClass({ activity });
  const activityDescription = getProjectActivityDescription({ activity, t });
  const projectType = getProjectTypeFromActivity({ activity });
  return (
    <GenericActivity
      className='tasks-activity'
      iconClassName={activityIconClass}
      description={activityDescription}
      date={activity?.date}
    >
      <ActivityProjectLink
        clientId={clientId}
        logAction={logAction}
        project={activity.project}
        projectType={projectType}
      />
    </GenericActivity>
  );
};

const OfferActivity = ({ clientId, activity, logAction }) => {
  const { t } = useTranslation();
  const activityDescription = getOfferActivityDescription({ activity, t });
  const activityIconClass = getOfferActivityIconClass({ activity });
  return (
    <GenericActivity
      className='offer-activity'
      iconClassName={activityIconClass}
      description={activityDescription}
      date={activity?.date}
    >
      <ActivityOfferLink clientId={clientId} offer={activity?.offer} logAction={logAction} />
    </GenericActivity>
  );
};

const UserActivity = ({ clientId, activity, logAction, currentUser }) => {
  const { t } = useTranslation();
  if (activity?.subtype !== 'collaborator_signup') {
    return null;
  }

  const activityDescription = currentUser.email === activity?.user.email
    ? t('dashboard.activity.ownAccountCreation')
    : t('dashboard.activity.accountCreation');

  return (
    <GenericActivity
      className='user-activity'
      iconClassName='ri-team-line'
      description={activityDescription}
      date={activity?.date}
    >
      {clientId && (
        <div className='activity-details'>
          <Link to={`/client/${clientId}/users`} onClick={() => logAction({ type: 'click-collaborators-link' })}>
            {`${activity?.user?.firstname} ${activity?.user?.lastname}`}
          </Link>
        </div>
      )}
    </GenericActivity>
  );
};

const ProfileName = ({ profile, clientId, offerId, logAction }) => {
  const profileName = `${profile?.resumeData?.firstname} ${profile?.resumeData?.lastname} `;
  return (
    <span className='profile-avatar-and-name'>
      <Link
        to={`/client/${clientId}/jobs/${offerId}/profiles/${profile.id}`}
        onClick={() => logAction({ type: 'click-profile-link' })}
      >
        {profileName}
      </Link>
    </span>
  );
};

const ActivityOfferLink = ({ clientId, offer, logAction }) => {
  if (!clientId || !offer?.id || offer?.isUnclassified) {
    return null;
  }
  return (
    <div className='activity-details'>
      <Link to={`/client/${clientId}/jobs/${offer.id}`} onClick={() => logAction({ type: 'click-offer-link' })}>
        {offer?.title}
      </Link>
    </div>
  );
};

const ActivityTaskLink = ({ clientId, task, projectType, logAction }) => {
  if (!clientId || !task?.id) {
    return null;
  }
  const { id, title } = task; // TODO
  return (
    <div className='activity-details'>
      <Link
        to={`/client/${clientId}/reveal/todos/${projectType}/focus/${id}`}
        onClick={() => logAction({ type: 'click-task-link' })}
      >
        <TranslatableText text={title} />
      </Link>
    </div>
  );
};

const ActivityProjectLink = ({ clientId, project, projectType, logAction }) => {
  if (!clientId || !project?.id || !projectType) {
    return null;
  }
  const { id, title } = project;
  return (
    <div className='activity-details'>
      <Link
        to={`/client/${clientId}/reveal/todos/${projectType}?todoProjectId%5B0%5D=${id}`}
        onClick={() => logAction({ type: 'click-project-link' })}
      >
        <TranslatableText text={title} />
      </Link>
    </div>
  );
};

const GenericActivity = ({ className, iconClassName, description, date, children }) => {
  const { t } = useTranslation();
  return (
    <div className={`activity-timeline-item${className ? ` ${className}` : ''}`}>
      <div className='activity-icon-container'>
        <div className='activity-icon'>
          <i className={iconClassName} />
        </div>
      </div>
      <div className='activity-content'>
        <div className='activity-description'>{description}</div>
        {children}
        {date && <div className='activity-date'>{t('dashboard.activity.time', { date: new Date(date) })}</div>}
      </div>
    </div>
  );
};

export default ActivityTimeline;
