import React, { FC, useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import useClientId from '@/hooks/router/useClientId';
import { GET_JOB_DEFAULT_SEQUENCES } from '@/graphql/searchPoolJob';
import { CLIENT_SEQUENCES } from '@/containers/Parameters/Sequences/queries';
import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';
import SequenceDropdown from '@/components/Reveal/Sequences/SequenceDropdown';
import GenericButton from '@/components/Common/GenericButton';
import DropdownPanel from '@/components/Common/DropdownPanel';
import DropdownControlsContext from '@/context/DropdownControlsContext';

import styles from './AddToSequencePanel.module.less';

interface AddToSequencePanelProps {
  projectId: string;
  onSubmit: (sequenceId: string | null) => void;
}

const AddToSequencePanel: FC<AddToSequencePanelProps> = ({
  projectId,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { closeDropdown } = useContext(DropdownControlsContext);
  const [checked, setChecked] = useState(true);
  const [selectedSequenceId, setSelectedSequenceId] = useState<string | null>(
    null,
  );
  const { data: jobData } = useQuery(GET_JOB_DEFAULT_SEQUENCES, {
    variables: { searchPoolId: 'reveal', jobId: projectId },
    skip: !projectId,
  });
  const attachedSequences = jobData?.searchPool?.job?.attachedSequences;
  const defaultSequenceId = _.findWhere(attachedSequences, { isDefault: true })
    ?.sequenceId;
  const secondarySequenceIds = _.pluck(
    _.filter(
      attachedSequences,
      ({ sequenceId }) => sequenceId !== defaultSequenceId,
    ),
    'sequenceId',
  );
  const { data } = useQuery(CLIENT_SEQUENCES, {
    variables: { clientId },
  });

  const clientSequencesList = _.filter(
    data?.client?.sequences,
    ({ isArchived }) => !isArchived,
  );

  return (
    <DropdownPanel className={styles.panel}>
      <LabeledCheckbox
        checked={checked}
        label={t('reveal.recommendedProfiles.sequence.add')}
        onClick={() => setChecked((prev) => !prev)}
      />
      <div className={styles.inputs}>
        {checked && (
          <SequenceDropdown
            clientSequencesList={clientSequencesList}
            defaultSequenceId={defaultSequenceId}
            currentSequence={{
              sequenceId: selectedSequenceId || defaultSequenceId,
            }}
            secondarySequenceIds={secondarySequenceIds}
            onSequenceSelected={setSelectedSequenceId}
            clientId={clientId}
          />
        )}

        <GenericButton
          disabled={checked && !(selectedSequenceId || defaultSequenceId)}
          onClick={() => {
            closeDropdown();
            onSubmit(selectedSequenceId || defaultSequenceId);
          }}
        >
          {t('reveal.recommendedProfiles.confirm.trigger')}
        </GenericButton>
      </div>
    </DropdownPanel>
  );
};

export default AddToSequencePanel;
