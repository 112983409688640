import _ from 'underscore';
import React, { FC, useState, useCallback } from 'react';
import { Popup } from 'semantic-ui-react';
import classNames from 'classnames';

import styles from './ContextMenu.module.less';

type Action = {
  key?: string;
  icon?: string | HTMLElement;
  label: string;
  onClick?: () => void;
  disabled?: boolean;
};

interface ContextMenuActionProps {
  action: Action;
  onClick: () => any;
}

interface ContextMenuProps {
  actions: Action[];
  popupContainerStyle?: Record<any, any>;
  popupClassName?: string;
}

const ContextMenuAction: FC<ContextMenuActionProps> = ({ onClick, action }) => {
  const { icon, disabled = false, onClick: actionClick, label } = action;
  const innerOnClick = useCallback(() => {
    if (disabled) {
      return;
    }

    onClick();
    actionClick?.();
  }, [onClick, actionClick, disabled]);

  // At some point we are going to need to send custom elements
  // so this is here to prepare that day
  if (React.isValidElement(action)) {
    return action;
  }

  return (
    <div
      className={classNames(styles.contextMenuAction, {
        [styles.disabled]: disabled || !actionClick,
      })}
      onClick={innerOnClick}
    >
      {icon && _.isString(icon) && <i className={`${icon} ri-lg`} />}
      {icon && React.isValidElement(icon) && icon}
      <span>{label}</span>
    </div>
  );
};

const ContextMenu: FC<ContextMenuProps> = ({
  actions,
  popupContainerStyle,
  popupClassName,
}) => {
  // Handle the open state manually to prevent overlapping modals & popups
  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen((currentStatus) => !currentStatus);
  }, []);

  return (
    <div
      className={styles.contextBtnContainer}
      onClick={(event) => event.stopPropagation()}
      onKeyPress={(event) => event.stopPropagation()}
      role='button'
    >
      <Popup
        basic
        open={open}
        hoverable
        onClose={() => setOpen(false)}
        position='bottom right'
        className={classNames(
          styles.contextMenuPopup,
          popupClassName && popupClassName,
        )}
        trigger={
          <i
            onClick={toggleOpen}
            className={classNames(styles.contextBtn, 'ri-more-fill ri-lg')}
            onKeyPress={toggleOpen}
            role='button'
          />
        }
      >
        <div
          className={styles.contextMenuContainer}
          style={popupContainerStyle}
        >
          {_.map(actions, (action, index) => (
            <ContextMenuAction
              key={action.key || index}
              onClick={() => setOpen(false)}
              action={action}
            />
          ))}
        </div>
      </Popup>
    </div>
  );
};

export default ContextMenu;
