import React from 'react';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { compose } from 'underscore';
import { Template } from '@/types/template';
import Templates from '@/containers/Parameters/Templates';
import useClientId from '@/hooks/router/useClientId';
import withClientTemplatesAndOffers from '@/hocs/templates/withClientTemplatesAndOffers';
import routerParamsToProps from '@/hocs/routerParamsToProps';
import NewTemplateButton from './RevealView/AutomationsView/SubHeader/NewTemplateButton';

interface TemplatesViewProps {
  loading: boolean;
  templates: Template[];
  error: boolean;
}

const TemplatesView: React.FC<TemplatesViewProps> = ({
  loading,
  templates,
  error,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();

  if (error) return <p />;
  if (!templates) return <div />;

  if (loading) {
    return (
      <div className='menu-templates'>
        <div className='header'>
          <h1>{t('templates.header')}</h1>
        </div>
        <div className='templates-list-placeholder'>
          <Loader active inline='centered' size='large' />
        </div>
      </div>
    );
  }
  return (
    <div className='menu-templates'>
      <div className='templates-header'>
        <h2 className='templates-header-title'>{t('templates.header')}</h2>
        <div className='new-template-container'>
          <NewTemplateButton />
        </div>
      </div>
      <Templates clientId={clientId} />
    </div>
  );
};

export default compose(
  routerParamsToProps,
  withClientTemplatesAndOffers,
)(TemplatesView);
