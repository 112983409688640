import { TranslatableText } from '@/types/text';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import { DeepPathWithType } from '@/common/utils/record';

export type ProfileOverviewCardItem = {
  label: TranslatableText;
  content: ProfileOverviewCardItemContent;
};

export type ProfileOverviewCardItemContent =
  | ProfileOverviewCardCustomFieldItemContent
  | ProfileOverviewCardBuiltinStringItemContent
  | ProfileOverviewCardBuiltinDateItemContent
  | ProfileOverviewCardCompositeItemContent;

export type ProfileOverviewCardCustomFieldItemContent = {
  type: 'customField';
  customFieldType:
    | 'enum'
    | 'inline-text'
    | 'text'
    | 'float'
    | 'integer'
    | 'day';
  customFieldId: string;
};

type ProfileOverviewCardBuiltinStringItemContent = {
  type: 'builtin-string';
  path: DeepPathWithType<SearchPoolProfile, string>;
};

type ProfileOverviewCardBuiltinDateItemContent = {
  type: 'builtin-date';
  path: DeepPathWithType<SearchPoolProfile, Date | string | number>;
};

type ProfileOverviewCardCompositeItemContent = {
  type: 'composite';
  elements: ProfileOverviewCardItemContent[];
};

export function getItemId(content: ProfileOverviewCardItemContent): string {
  switch (content.type) {
    case 'customField': {
      return `customField_${content.customFieldId}`;
    }
    case 'builtin-string': {
      return `builtin_${content.path}`;
    }
    case 'builtin-date': {
      return `builtin_${content.path}`;
    }
    case 'composite': {
      return `composite_${content.elements.map(getItemId).join(';')}`;
    }
    default: {
      const { type } = content;
      throw new Error(
        `No unique ID can be generated for item of type ${type}.`,
      );
    }
  }
}
