import gql from 'graphql-tag';
import Author from './Author';
import ContactFlow from './ContactFlow';

export default gql`
  fragment SequenceTemplate on SequenceTemplate {
    id
    title
    description
    contactFlow {
      ...ContactFlow
    }
    author {
      ...Author
    }
    creationDate
    lastEditionDate
    # isArchived
  }
  ${ContactFlow}
  ${Author}
`;
