import React, { FC, useMemo, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

// import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import SettingsLayout from '@/containers/SettingsLayout';
import {
  useClientCustomActions,
  GET_CLIENT_CUSTOM_ACTIONS,
} from '@/graphql/hooks/clients/useClientCustomActions';
import GenericModal from '@/components/Common/GenericModal';
import { Modal } from 'semantic-ui-react';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';
import { ClientCustomAction } from '@/types/customAction';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { GraphQLError } from 'graphql';
import styles from './CustomTasksSettings.module.less';
import CustomTasksTable from './CustomTasksTable';
import CustomTaskForm from './CustomTasksForm';
import {
  useRemoveClientCustomAction,
  useUpdateClientCustomAction,
  // useRemoveClientCustomAction,
} from '../../../graphql/hooks/clients/useUpdateClientCustomAction';

interface CustomTasksSettingsProps {
  clientId: string;
}

const CustomTasksSettings: FC<CustomTasksSettingsProps> = ({ clientId }) => {
  const { t } = useTranslation();
  const [creationModalOpen, setCreationModalOpen] = useState(false);
  const [editionModalOpen, setEditionModalOpen] = useState(false);
  const [deletionModalOpen, setDeletionModalOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState('');
  const notifications = useNotificationSystem();
  // const toast = useNotificationSystem();

  const { clientCustomActions } = useClientCustomActions();
  const [updateClientCustomAction] = useUpdateClientCustomAction();
  const [removeClientCustomAction] = useRemoveClientCustomAction();

  const handleEditTask = ({ taskId }: { taskId: string }) => {
    setSelectedTaskId(taskId);
    setEditionModalOpen(true);
  };

  const handleUpdateTask = ({ data }: { data: ClientCustomAction }) => {
    updateClientCustomAction({
      variables: {
        clientId,
        input: {
          ...data,
          ...(data.execution?.type && { execution: data.execution }),
        },
      },
      refetchQueries: [GET_CLIENT_CUSTOM_ACTIONS],
      onError: (e) => {
        const error: GraphQLError & { data?: any } = e.graphQLErrors?.[0];
        if (error?.data?.details?.indexOf('name-already-exists') >= 0) {
          notifications.error(
            `${t(`reveal.parameters.customTasksSettings.errorPrefix`, {
              operation: creationModalOpen
                ? t('reveal.parameters.customTasksSettings.creation')
                : t('reveal.parameters.customTasksSettings.update'),
            })} ${t(
              `reveal.parameters.customTasksSettings.${error.data.details}`,
              {
                name: data.title.default,
              },
            )}`,
          );
        }
      },
      onCompleted: () => {
        setEditionModalOpen(false);
        setCreationModalOpen(false);
      },
    });
  };

  const handleRemoveTask = ({ actionId }: { actionId: string }) => {
    removeClientCustomAction({
      variables: {
        clientId,
        actionId,
      },
      refetchQueries: [GET_CLIENT_CUSTOM_ACTIONS],
    });
    setDeletionModalOpen(false);
  };

  const currentTask = useMemo(
    () => _.findWhere(clientCustomActions, { id: selectedTaskId }),
    [selectedTaskId, clientCustomActions],
  );

  return (
    <SettingsLayout
      currentPage='customTasks'
      clientId={clientId}
      className='settings-container'
    >
      <div className='settings'>
        <div className={styles.header}>
          <h1 className={styles.title}>
            {t('reveal.parameters.customTasksSettings.title')}
          </h1>
        </div>
        <h2>{t('settings.configuration')}</h2>
        <div className='section visible'>
          <div className={styles.description}>
            {t('reveal.parameters.customTasksSettings.description')}
          </div>
          <CustomTasksTable
            type='tasks'
            tasks={clientCustomActions}
            onEdit={handleEditTask}
            onDelete={({ taskId }) => {
              setSelectedTaskId(taskId);
              setDeletionModalOpen(true);
            }}
          />
          <div className='input-container'>
            <GenericButton onClick={() => setCreationModalOpen(true)}>
              <Plus />
              {t('reveal.parameters.customTasksSettings.create')}
            </GenericButton>
          </div>
        </div>
      </div>
      <GenericModal
        open={editionModalOpen || creationModalOpen}
        className={styles.customActionFormModal}
      >
        <Modal.Header>
          {creationModalOpen
            ? t('reveal.parameters.customTasksSettings.creationModal.title')
            : t('reveal.parameters.customTasksSettings.edit')}
        </Modal.Header>
        <Modal.Content className={styles.modalContentContainer}>
          <CustomTaskForm
            type='tasks'
            onSubmit={handleUpdateTask}
            currentTask={editionModalOpen ? currentTask : undefined}
            setOpen={() => {
              setEditionModalOpen(false);
              setCreationModalOpen(false);
            }}
            index={clientCustomActions.length + 1}
          />
        </Modal.Content>
      </GenericModal>
      <ConfirmationModal
        header={t('reveal.parameters.customTasksSettings.delete')}
        submit={t('common.delete')}
        onSubmit={() => handleRemoveTask({ actionId: selectedTaskId })}
        open={deletionModalOpen}
        onCancel={() => {
          setSelectedTaskId('');
          setDeletionModalOpen(false);
        }}
      />
    </SettingsLayout>
  );
};

export default CustomTasksSettings;
