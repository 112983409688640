import React, { FC, ReactNode, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import GenericCheckbox from '../Common/GenericCheckbox';

import styles from './RegisteredCheckbox.module.less';

interface RegisteredCheckboxProps {
  name: string;
  defaultValue?: boolean;
  label: ReactNode;
}

const RegisteredCheckbox: FC<RegisteredCheckboxProps> = ({
  name,
  defaultValue,
  label,
}) => {
  const { register, setValue, watch } = useFormContext();
  const value = watch(name, defaultValue);

  useEffect(() => {
    register(name);
  }, [register, name]);

  return (
    <button
      type='button'
      onClick={() => setValue(name, !value)}
      className={styles.container}
    >
      <GenericCheckbox checked={value} />
      <span className={styles.label}>{label}</span>
    </button>
  );
};

export default RegisteredCheckbox;
