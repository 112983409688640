import _ from 'underscore';
import { TFunction } from 'i18next';

import {
  DisplayedTaskType,
  ExclusiveTaskType,
  isExclusiveTaskType,
  isPrefixTaskType,
  isSequenceTaskType,
} from '@/types/statistics/clientStatistics';
import { DailyCohortDetails } from '@/graphql/hooks/clients/useClientDailyCohortsDetails';
import { MiniSequence } from '@/types/sequence';
import { SequenceDetail } from '../../../components/StatsProfileAndSequenceTable/types';
import { filterEventsByDateRange } from '../../../stats.helpers';
import { caseOf, StatsFilters } from './filters';
import { TimeSpan } from '../../../components/RevealAnalyticsHeader/RevealAnalyticsHeader';

const getExclusiveSequenceDetailsFromDailyCohorts = (
  dailyCohortDetails: DailyCohortDetails[],
  miniSequences: MiniSequence[],
  filters: StatsFilters,
): SequenceDetail[] => {
  const { startDay, endDay, taskType, sequenceId } = filters;
  const { value } = taskType;

  if (!isExclusiveTaskType(value)) {
    return [];
  }

  const exlcusiveDetailsMap: Record<ExclusiveTaskType, SequenceDetail[]> = {
    nbAnswered: [],
    nbContacted: [],
    nbPositiveAnswered: [],
  };

  _.each(dailyCohortDetails, ({ day, details }) => {
    if ((startDay && day < startDay) || (endDay && day >= endDay)) {
      return;
    }
    _.each(details, ({ type, profileItems, sequenceId: detailSequenceId }) => {
      if (sequenceId && sequenceId !== detailSequenceId) {
        return;
      }
      if (!_.some(miniSequences, ({ id }) => id === detailSequenceId)) {
        return;
      }
      if (!isExclusiveTaskType(type)) {
        return;
      }
      _.each(profileItems, ({ profileId }) => {
        exlcusiveDetailsMap[type].push({
          profileId,
          sequenceId: detailSequenceId,
          day,
        });
      });
    });
  });

  if (value === 'nbContacted') {
    return _.filter(
      exlcusiveDetailsMap.nbContacted,
      ({ profileId, sequenceId: detailSequenceId }) =>
        !_.some(
          exlcusiveDetailsMap.nbAnswered,
          (profileItem) =>
            profileItem.profileId === profileId &&
            profileItem.sequenceId === detailSequenceId,
        ),
    );
  }

  if (value === 'nbAnswered') {
    return _.filter(
      exlcusiveDetailsMap.nbAnswered,
      ({ profileId, sequenceId: detailSequenceId }) =>
        !_.some(
          exlcusiveDetailsMap.nbPositiveAnswered,
          (profileItem) =>
            profileItem.profileId === profileId &&
            profileItem.sequenceId === detailSequenceId,
        ),
    );
  }

  return exlcusiveDetailsMap[value];
};

export const getSequenceDetailsFromDailyCohorts = (
  timeSpan: TimeSpan,
  dailyCohortDetails: DailyCohortDetails[] = [],
  miniSequences?: MiniSequence[],
  filters?: StatsFilters,
): SequenceDetail[] => {
  if (!filters || !miniSequences) {
    return [];
  }
  const { startDay, endDay, taskType, sequenceId, prefix } = filters;

  if (!taskType) {
    return [];
  }

  const { exclusive, value } = taskType;

  const cohortsInRange = filterEventsByDateRange(timeSpan, dailyCohortDetails);

  if (exclusive) {
    return getExclusiveSequenceDetailsFromDailyCohorts(
      cohortsInRange,
      miniSequences,
      filters,
    );
  }

  const result: SequenceDetail[] = [];

  _.each(dailyCohortDetails, ({ day, details }) => {
    if ((startDay && day < startDay) || (endDay && day >= endDay)) {
      return;
    }
    _.each(details, ({ type, profileItems, sequenceId: detailSequenceId }) => {
      if (sequenceId && sequenceId !== detailSequenceId) {
        return;
      }
      if (!_.some(miniSequences, ({ id }) => id === detailSequenceId)) {
        return;
      }
      if (!prefix) {
        if (!isSequenceTaskType(type) || type !== value) {
          return;
        }
      } else {
        const [realType, ...prefixElements] = type.split('_');
        if (
          !isPrefixTaskType(realType) ||
          realType !== value ||
          (prefix.type === 'topology' &&
            prefix.path !== `/_${prefixElements.join('_')}`) ||
          (prefix.type === 'step' && _.size(prefixElements) !== prefix.index)
        ) {
          return;
        }
      }
      _.each(profileItems, ({ profileId }) => {
        result.push({
          profileId,
          sequenceId: detailSequenceId,
          day,
        });
      });
    });
  });

  return result;
};

export const i18nKeysByTaskType: Record<DisplayedTaskType, string> = {
  nbAnswered: 'answered',
  nbEnrolled: 'enrolled',
  nbContacted: 'contacted',
  nbOpened: 'opened',
  nbClicked: 'clicked',
  nbPositiveAnswered: 'positiveAnswered',
};

const i18nKeysByExclusiveTaskType: Record<ExclusiveTaskType, string> = {
  nbContacted: 'contactedNotReplied',
  nbAnswered: 'repliedNotPositively',
  nbPositiveAnswered: 'repliedPositively',
};

export const getModalTitle = (
  t: TFunction,
  filters?: StatsFilters,
): string | undefined =>
  filters?.taskType
    ? caseOf(filters.taskType, {
        inclusive: (value) =>
          t(`reveal.analyticsView.sequences.${i18nKeysByTaskType[value]}`),
        exclusive: (value) =>
          t(`reveal.reports.sequences.${i18nKeysByExclusiveTaskType[value]}`),
      })
    : undefined;

export const getModalSubtitle = (
  t: TFunction,
  language: string,
  filters?: StatsFilters,
  miniSequences?: MiniSequence[],
): string | undefined => {
  if (filters?.sequenceId) {
    const sequence = _.findWhere(miniSequences || [], {
      id: filters.sequenceId,
    });
    return sequence?.title;
  }

  if (!filters?.startDay || !filters?.endDay) {
    return undefined;
  }
  const dateDisplayOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const dayToLocaleString = (day: string): string =>
    new Date(day).toLocaleString(language, dateDisplayOptions);

  return t('reveal.reports.sequences.dailyCohorts.detailsModal.subtitle', {
    startDate: dayToLocaleString(filters.startDay),
    endDate: dayToLocaleString(filters.endDay),
  });
};
