import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  OverallRecommendation,
  ScorecardQuestions,
  ScorecardSummary,
} from '@/revealComponents/ProfileTimeline';
import { ATStype, ATS_LOGOS_BY_TYPE } from '@/common/reveal';
import TASK_ICONS from '../TaskIcons';
import AuthorAndDate from '../AuthorAndDate';

import './ScoreCardTimelineItem.css';

interface ScoreCardTimelineItemProps {
  timelineItem: any;
}

const ScoreCardTimelineItem: React.FC<ScoreCardTimelineItemProps> = ({
  timelineItem,
}) => {
  const { t } = useTranslation();

  const atsType: ATStype = timelineItem?.ats;
  const atsLogo = ATS_LOGOS_BY_TYPE[atsType];

  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{
                backgroundColor: TASK_ICONS.messageUnavailable.color,
              }}
              className='icon'
            >
              <i className={`${TASK_ICONS.messageUnavailable.icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            Scorecard
            <AuthorAndDate timelineItem={timelineItem} atsLogo={atsLogo} />
          </div>
        </div>
        <div className='card-content scorecard-activity'>
          <OverallRecommendation
            overallRecommendation={timelineItem.action?.overallRecommendation}
            t={t}
          />
          <ScorecardQuestions questions={timelineItem.action?.questions} />
          <ScorecardSummary attributes={timelineItem.action?.attributes} />
        </div>
      </div>
    </div>
  );
};

export default ScoreCardTimelineItem;
