import classNames from 'classnames';
import React, { FC } from 'react';
import { Popup, PopupProps } from 'semantic-ui-react';

import styles from './GenericTooltip.module.less';

export type GenericTooltipProps = { rich?: boolean } & Omit<
  PopupProps,
  'basic'
>;

const GenericTooltip: FC<GenericTooltipProps> = ({
  children,
  rich,
  className,
  ...props
}) => (
  <Popup
    basic
    className={classNames(styles.tooltip, className, {
      [styles.rich]: rich,
    })}
    {...props}
  >
    {children}
  </Popup>
);

export default GenericTooltip;
