import React from 'react';

import styles from './Funnel.module.less';

const Funnel: React.FC = ({ children }) => (
  <span className={styles.container}>
    <span className={styles.funnel}>
      {children}
      <div className={styles.arrowTop} />
      <div className={styles.arrowBottom} />
    </span>
  </span>
);

export default Funnel;
