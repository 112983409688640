import React, { Dispatch, SetStateAction } from 'react';
import { Modal } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ArchivedStageContent } from '@/components/PipelineSegmentation/MoveToStageAction';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import GenericModal from '@/components/Common/GenericModal';
import { ArchiveReason, GET_ARCHIVE_REASONS } from '@/graphql/archiveReasons';
import GenericButton from '@/components/Common/GenericButton';

interface ArchiveReasonsModalProps {
  clientId: string;
  profiles: SearchPoolProfile[];
  onClose: () => void;
  archivedEngagement: string;
  setArchivedEngagement: Dispatch<SetStateAction<string>>;
  archivedReason: string;
  setArchivedReason: Dispatch<SetStateAction<string>>;
  clientArchiveReasonId: string;
  setClientArchiveReasonId: Dispatch<SetStateAction<string>>;
  confirm: () => void;
  onCancel: () => void;
}

const ArchiveReasonsModal: React.FC<ArchiveReasonsModalProps> = ({
  clientId,
  profiles,
  onClose,
  archivedEngagement,
  setArchivedEngagement,
  archivedReason,
  setArchivedReason,
  clientArchiveReasonId,
  setClientArchiveReasonId,
  confirm,
  onCancel,
}) => {
  const { t } = useTranslation();

  const { data: archiveReasonsData } = useQuery(GET_ARCHIVE_REASONS, {
    variables: { clientId },
    fetchPolicy: 'network-only',
  });

  const clientArchiveReasons: ArchiveReason[] =
    archiveReasonsData?.client?.archiveReasons;

  const handleCancel = () => {
    onCancel();
    onClose();
  };

  const handleConfirm = () => {
    confirm();
    onClose();
  };

  return (
    <GenericModal
      open
      onClose={() => onClose()}
      closeOnEscape
      closeOnDimmerClick={false}
      size='mini'
    >
      <Modal.Header>{t('reveal.modals.archiveReasons.title')}</Modal.Header>
      <Modal.Content>
        <ArchivedStageContent
          archivedEngagement={archivedEngagement}
          setArchivedEngagement={setArchivedEngagement}
          archivedReason={archivedReason}
          setArchivedReason={setArchivedReason}
          candidatesLength={profiles.length}
          clientArchiveReasonId={clientArchiveReasonId}
          setClientArchiveReasonId={setClientArchiveReasonId}
          clientArchiveReasons={clientArchiveReasons}
        />
      </Modal.Content>
      <Modal.Actions>
        <GenericButton primacy='secondary' onClick={handleCancel}>
          {t('common.cancel')}
        </GenericButton>
        <GenericButton primacy='primary' onClick={handleConfirm}>
          {t('common.confirm')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default ArchiveReasonsModal;
