import GenericTextInput from '@/components/Common/GenericTextInput';
import React, { useState } from 'react';
import styles from '../MetaTaskForm.module.less';
import { MetaTaskFormFieldOnChange, MetaTaskFormatFormField } from '../types';

interface MetaTaskFormFieldFieldAnswerProps {
  field?: MetaTaskFormatFormField;
  onChange?: MetaTaskFormFieldOnChange;
  disabled?: boolean;
  readMode?: boolean;
}

const MetaTaskFormFieldInputAnswer: React.FC<MetaTaskFormFieldFieldAnswerProps> = ({
  field,
  onChange,
  disabled = false,
  readMode = false,
}) => {
  const [currentValue, setCurrentValue] = useState(field?.value || '');
  const handleChange = ({ value }: { value: string }) => {
    if (onChange) {
      setCurrentValue(value);
      onChange({ fieldId: field?.id || '', value });
    }
  };

  if (readMode) {
    return (
      <span>{currentValue || '-'}</span>
    )
  }

  return (
    <div className={styles.metaTaskAnswer}>
      <GenericTextInput
        type={
          field?.type === 'inline-text' || field?.subtype === 'inline-text'
            ? 'text'
            : 'number'
        }
        step={field?.subtype === 'float' ? 0.1 : undefined}
        small
        className={styles.metaTaskInput}
        value={currentValue}
        onChange={(e) => handleChange({ value: e.target.value })}
        disabled={disabled}
      />
    </div>
  );
};

export default MetaTaskFormFieldInputAnswer;
