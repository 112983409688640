import React from 'react';
import _ from 'underscore';
import { List, Checkbox } from 'semantic-ui-react';
import ColoredBox from '@/common/ColoredBox';
// import { LinkedInLogo } from '../../../../../assets/icons';

import './Results.css';
import GenericButton from '@/components/Common/GenericButton';

/*
  TODO improvements:
  - warn if CSV contains duplicates in itself before upload (i.e 2 lines are the same or same email or same URL)
  - better identify duplicates in lines, highlight whether duplicate is because of email or linkedin or both
  - make duplicates count more robust
 */
const Results = ({
  clientId,
  results,
  t,
  onSubmit,
  jobId,
  customFieldsToSave,
}) => {
  const { insertedProfilesCount } = results || {};
  const mergedProfiles = results?.mergedProfiles || [];
  const duplicateProfiles = results?.duplicateProfiles || [];
  const mergedProfilesCount = mergedProfiles.length;
  const duplicateProfilesCount = duplicateProfiles.length;
  const noNewlyImportedProfiles =
    insertedProfilesCount === 0 && mergedProfilesCount === 0;

  const [selectedActions, setSelectedActions] = React.useState({
    addDuplicates: !!jobId,
    saveCustomFields: !_.isEmpty(customFieldsToSave),
  });
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

  const handleSubmitActions = () => {
    setIsButtonDisabled(true);
    onSubmit({
      addDuplicates: selectedActions.addDuplicates,
      saveCustomFields: selectedActions.saveCustomFields,
    });
  };

  return (
    <>
      <ColoredBox
        className='results'
        color='blue'
        radius='8px'
        margin='12px 0px'
      >
        {noNewlyImportedProfiles && (
          <div className='description positive'>
            {t('CSVImport.results.noNewProfiles')}
          </div>
        )}
        {insertedProfilesCount > 0 && (
          <div className='description positive'>
            {t('CSVImport.results.profilesUploadedCount', {
              count: insertedProfilesCount,
            })}
          </div>
        )}

        {mergedProfilesCount > 0 && (
          <div className='description positive'>
            {t('CSVImport.results.profilesMergedCount', {
              count: mergedProfilesCount,
            })}
          </div>
        )}
        {mergedProfilesCount > 0 && (
          <List bulleted>
            {_.map(mergedProfiles, ({ id, resumeData }) => (
              <List.Item key={id}>
                {resumeData?.firstname} {resumeData?.lastname}
              </List.Item>
            ))}
          </List>
        )}
      </ColoredBox>

      {duplicateProfilesCount > 0 && (
        <ColoredBox
          className='results warning'
          color='yellow'
          radius='8px'
          margin='12px 0px 0px'
        >
          <div className='results-wrapper'>
            <div className='description'>
              {t('CSVImport.results.profilesDuplicatesCount', {
                count: duplicateProfilesCount,
              })}
            </div>
            {(jobId || !_.isEmpty(customFieldsToSave)) && (
              <div className='results-actions'>
                <div className='checkboxs'>
                  {jobId && (
                    <Checkbox
                      label={t(
                        'CSVImport.results.addProfilesDuplicatesToProject',
                      )}
                      checked={selectedActions.addDuplicates}
                      onChange={(e, { checked }) =>
                        setSelectedActions((previousValues) => ({
                          ...previousValues,
                          addDuplicates: checked,
                        }))
                      }
                    />
                  )}
                  {!_.isEmpty(customFieldsToSave) && (
                    <Checkbox
                      label={t('CSVImport.results.saveProfileCustomFields')}
                      checked={selectedActions.saveCustomFields}
                      onChange={(e, { checked }) =>
                        setSelectedActions((previousValues) => ({
                          ...previousValues,
                          saveCustomFields: checked,
                        }))
                      }
                    />
                  )}
                </div>
                <GenericButton
                  onClick={() => handleSubmitActions()}
                  disabled={
                    (!selectedActions.addDuplicates &&
                      !selectedActions.saveCustomFields) ||
                    isButtonDisabled
                  }
                >
                  {t('common.confirm')}
                </GenericButton>
              </div>
            )}
          </div>
          <List bulleted>
            {_.map(duplicateProfiles, (profile) => (
              <List.Item key={profile?.id}>
                {getProfileLink({ clientId, profile })}
              </List.Item>
            ))}
          </List>
        </ColoredBox>
      )}
    </>
  );
};

const getProfileLink = ({ clientId, profile, onClick }) => {
  const { id } = profile || {};
  const { firstname, lastname, email, sources } = profile?.resumeData || {};
  const fullName = `${firstname || ''} ${lastname || ''}`.trim();
  return (
    <div className='profile-link'>
      <span>
        <a
          href={`/client/${clientId}/reveal/search?profileId=${id}`}
          onClick={onClick}
          target='_blank'
          rel='noreferrer noopener'
        >
          {fullName}
        </a>
        {/* {sources?.linkedin && ( */}
        {/* <a href={cleanLink(sources?.linkedin)} target='_blank' rel='noreferrer noopener'> */}
        {/* <LinkedInLogo className='linkedin-logo' /> */}
        {/* </a> */}
        {/* )} */}
      </span>
      {email && <div className='profile-link-item'>{email}</div>}
      {sources?.linkedin && (
        <div className='profile-link-item'>{sources?.linkedin}</div>
      )}
    </div>
  );
};

// const cleanLink = (url) => (url.match(/^https?:\/\//i) ? url : `https://${url}`);

export default Results;
