import gql from 'graphql-tag';
import ContactFlowTrigger from './ContactFlowTrigger';
import ContactFlowAction from './ContactFlowAction';

export default gql`
  fragment ContactFlowSequence on ContactFlowSequence {
    id
    name
    title
    isCurrent
    isDraft
    position
    trigger {
      ...ContactFlowTrigger
    }
    actions {
      ...ContactFlowAction
    }
    completion {
      isCompleted
      completionDate
      reasons {
        type
      }
    }
    linkedMissionId
  }
  ${ContactFlowTrigger}
  ${ContactFlowAction}
`;
