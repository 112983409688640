import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import ContactFlow from '../../graphql/fragments/ContactFlow';

export const mutation = gql`
  mutation markProfileContactFlowActionAsCompleted(
    $id: ID!
    $sequenceId: ID!
    $actionId: ID!
  ) {
    markProfileContactFlowActionAsCompleted(
      id: $id
      actionId: $actionId
      sequenceId: $sequenceId
    ) {
      id
      contactFlow {
        ...ContactFlow
      }
    }
  }
  ${ContactFlow}
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    markProfileContactFlowActionAsCompleted: ({ id, sequenceId, actionId }) =>
      mutate({
        variables: {
          id,
          sequenceId,
          actionId,
        },
      }),
  }),
});
