import React from 'react';
import _ from 'underscore';
import classNames from 'classnames';

import './CriteriaTags.css';

export const CriteriaTags = ({ header, tags }) => {
  if (_.isEmpty(tags)) {
    return null;
  }
  return (
    <div className='criteria-tags'>
      {header && <h5>{header}</h5>}
      <div className='tags-container'>
        {_.map(tags, (tag, index) => (
          <CriteriaTag key={`${tag?.text}-${index}`} tag={tag} />
        ))}
      </div>
    </div>
  );
};

export const CriteriaTag = ({ tag }) => {
  const { type, text } = tag || {};
  if (!text) {
    return null;
  }
  return <div className={classNames('criteria-tag', type)}>{text}</div>;
};
