import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './HideOrShowArchived.module.less';

interface HideOrShowArchivedProps {
  showArchived: boolean;
  setShowArchived: (value: boolean) => void;
}

const HideOrShowArchived: FC<HideOrShowArchivedProps> = ({
  showArchived,
  setShowArchived,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <button
        type='button'
        className={styles.button}
        onClick={() => setShowArchived(!showArchived)}
      >
        {t(`offers.${showArchived ? 'hide' : 'show'}ArchivedOffers`)}
      </button>
    </div>
  );
};

export default HideOrShowArchived;
