import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const mutation = gql`
  mutation unArchiveOffer($id: ID!) {
    unArchiveOffer(id: $id) {
      id
      isArchived
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    unArchiveOffer: ({ id }) => mutate({ variables: { id } }),
  }),
});
