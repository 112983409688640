import React, { useCallback, useMemo, useState } from 'react';
import _ from 'underscore';
import { useQuery } from '@apollo/client';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import useDataUpdateSubscription from '@/graphql/dataUpdateSubscription/useDataUpdateSubscription';
import GenericButton from '@/components/Common/GenericButton';
import ReapplySequenceStepByStepModal from '@/revealComponents/Modals/ReapplySequenceModal/ReapplySequenceStepByStepModal';
import useClientDailyCohortsDetails from '@/graphql/hooks/clients/useClientDailyCohortsDetails';
import GenericModal from '@/components/Common/GenericModal';
import DetailsModalHeader from '@/routes/RevealView/RevealAnalytics/components/DetailsModalHeader';
import StatsProfileAndSequenceTable from '@/routes/RevealView/RevealAnalytics/components/StatsProfileAndSequenceTable';
import { displayedTaskTypes } from '@/types/statistics/clientStatistics';

import { GET_SEQUENCE_WITH_MINI_PROFILES } from '../../../../graphql/sequences';
import FunnelKPI from '../../../../routes/RevealView/RevealAnalytics/components/FunnelKPI/FunnelKPI';
import { getKPIItems } from '../../../../routes/RevealView/RevealAnalytics/tabs/SequencesTab/helpers/kpis';

import SequenceProfilesList from './SequenceProfilesList';
import SequenceSettingsDropdown from './SequenceSettingsDropdown';
import { getModalTitle, getSequenceDetailsFromDailyCohorts } from './helpers';

import './SequenceView.css';

const SequenceView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId, sequenceId } = useParams();
  const location = useLocation();
  const { search, pathname } = location;
  const [modalOpen, setModalOpen] = useState(false);
  const [taskType, setTaskType] = useState(undefined);
  const queryParams = new URLSearchParams(search);
  const shouldReapplySequence = !!queryParams.get('reapplySequence');
  const lastModifiedActionIndex =
    queryParams.get('lastModifiedActionIndex') &&
    parseInt(queryParams.get('lastModifiedActionIndex'), 10);
  const variables = { sequenceId };
  const { data, loading, refetch, error } = useQuery(
    GET_SEQUENCE_WITH_MINI_PROFILES,
    {
      variables,
      fetchPolicy: 'network-only',
    },
  );

  useDataUpdateSubscription({
    type: 'onProfilesRemovedFromSequence',
    match: { sequenceId },
    onData: () => refetch(),
  });
  useDataUpdateSubscription({
    type: 'onProfileAddedToSequence',
    match: { sequenceId },
    onData: () => refetch(),
  });
  const { sequence } = data || {};

  const [
    fetchDetails,
    { dailyCohorts: dailyCohortDetails, loading: detailsLoading },
  ] = useClientDailyCohortsDetails();

  const applyFilter = useCallback(
    (newTaskType) => {
      setModalOpen(true);
      setTaskType(newTaskType);
      fetchDetails({ clientId, sequenceIds: [sequenceId] });
    },
    [fetchDetails, clientId, sequenceId],
  );

  const sequenceDetails = useMemo(
    () =>
      getSequenceDetailsFromDailyCohorts(dailyCohortDetails || [], taskType),
    [dailyCohortDetails, taskType],
  );

  const clickListeners = useMemo(
    () =>
      _.reduce(
        displayedTaskTypes,
        (aggregator, value) => ({
          ...aggregator,
          [value]: () => applyFilter(value),
        }),
        {},
      ),
    [applyFilter],
  );

  const modalTitle = useMemo(() => getModalTitle(t, taskType), [t, taskType]);

  if (!sequence && error) {
    document.location.href = `/client/${clientId}/reveal/sequences`;
  }

  if (loading) {
    return (
      <div className='sequence-view'>
        <Loader active size='large' />
      </div>
    );
  }

  return (
    <div className='sequence-view'>
      <div className='sequences-header'>
        <div className='sequence-view-breadcrumb'>
          <Link
            className='sequence-view-breadcrumb-title'
            to={`/client/${clientId}/reveal/sequences`}
          >
            {t('sequences.header')}
          </Link>
          <div className='sequence-view-breadcrumb-divider'>
            <i className='ri-arrow-right-s-line' />
          </div>
          <div className='sequence-view-breadcrumb-title collapsible'>
            {sequence?.title}
          </div>
          <SequenceSettingsDropdown sequence={sequence} />
        </div>
        <Link to={`${pathname}/edit`}>
          <GenericButton size='big'>
            {t('sequences.editSequence')}
          </GenericButton>
        </Link>
      </div>

      <div className='sequences-content'>
        <FunnelKPI
          kpiItems={getKPIItems({
            stats: sequence?.statistics,
            t,
            clickListeners,
          })}
        />

        <SequenceProfilesList
          clientId={clientId}
          sequenceId={sequenceId}
          searchPoolId='reveal'
          profilesWithCurrentSequenceId={sequence?.profiles}
          loading={loading}
        />
      </div>
      {shouldReapplySequence && (
        <ReapplySequenceStepByStepModal
          sequenceId={sequenceId}
          candidates={sequence?.profiles}
          clientId={clientId}
          lastModifiedActionIndex={lastModifiedActionIndex}
          onCancel={() => {
            // Removes the queryParams ('reapplySequence' in particular)
            history.replace(pathname);
          }}
        />
      )}
      {detailsLoading ? (
        <Dimmer active>
          <Loader size='large' />
        </Dimmer>
      ) : (
        <GenericModal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Content>
            <DetailsModalHeader>
              {modalTitle && <h1>{modalTitle}</h1>}
            </DetailsModalHeader>
            <StatsProfileAndSequenceTable sequenceDetails={sequenceDetails} />
          </Modal.Content>
        </GenericModal>
      )}
    </div>
  );
};

export default SequenceView;
