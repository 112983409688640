import { TFunction } from 'i18next';
import React, { useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Popup, Input } from 'semantic-ui-react';
import { compose } from 'underscore';
import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { getTranslatedText } from '@/common';
import CriteriaDiversity from '@/routes/RevealView/SearchView/SearchPane/CustomCriteriaFilters/CriteriaDiversity';
import CustomCriteriaDate from './CustomCriteriaDate';
import CustomCriteriaEnum from './CustomCriteriaEnum';
import CustomCriteriaNumber from './CustomCriteriaNumber';
import CustomCriteriaString from './CustomCriteriaString';
import {
  ContactOperator,
  DateOperator,
  DiversityOperator,
  EnumOperator,
  KeywordsEmplacement,
  KeywordsOperator,
  NumberOperator,
  PropertyFilter,
  StringOperator,
} from './types';

import './CustomCriteriaFilters.css';
import CriteriaContact from './CriteriaContact';
import CustomCriteriaKeywords from './CustomCriteriaKeywords';

const MIN_PROPERTIES_TO_DISPLAY_SEARCH = 5;

const CustomCriteriaFiltersTemplate = (props: {
  mode?: string;
  clientId: string;
  selectedPropertyFilter: PropertyFilter | null;
  triggerButton: React.ReactNode;
  onClose: () => void;
  setSelectedPropertyFilter: (filter: PropertyFilter | null) => void;
  t: TFunction;
}) => {
  const {
    mode,
    clientId,
    selectedPropertyFilter,
    triggerButton,
    setSelectedPropertyFilter,
    onClose,
    t,
  } = props;
  const { profileCustomFields: customFields } = useClientProfileCustomFields(
    clientId,
  );

  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  const closePopup = () => {
    onClose();
    setIsOpen(false);
  };

  const contactProperties: PropertyFilter[] = [
    {
      id: 'contact',
      type: 'contact',
      name: t('reveal.searchView.search.contactInformation'),
    },
  ];

  const diversityProperties: PropertyFilter[] = [
    {
      id: 'diversity',
      type: 'diversity',
      name: t('reveal.searchView.search.diversityInformation'),
    },
  ];

  const pastActivityProperties: PropertyFilter[] = [
    {
      id: 'last-interaction',
      type: 'day',
      name: t('reveal.searchView.search.pastActivity.last-interaction'),
    },
    {
      id: 'last-email-interaction',
      type: 'day',
      name: t('reveal.searchView.search.pastActivity.last-email-interaction'),
    },
    {
      id: 'last-ats-interaction',
      type: 'day',
      name: t('reveal.searchView.search.pastActivity.last-ats-interaction'),
    },
  ];

  const keywordsProperties: PropertyFilter[] = [
    {
      id: 'keywords',
      type: 'keywords',
      name: t('reveal.searchView.search.keywords.descriptions.label'),
    },
  ];

  const contactsCustomFields = useMemo(
    () =>
      (customFields || []).filter(
        (field) =>
          !field.contactCategory ||
          !field.contactCategory.type ||
          field.contactCategory.type === 'human',
      ),
    [customFields],
  );

  const customFieldsProperties: PropertyFilter[] = contactsCustomFields.map(
    (field) => {
      if (field.type === 'enum') {
        return {
          id: field.id,
          type: field.type,
          name: getTranslatedText(field.title),
          options: field.options,
        };
      }

      return {
        id: field.id,
        type: field.type,
        name: getTranslatedText(field.title),
      };
    },
  );

  const getPropertiesFromMode = (inputMode: string | undefined) => {
    if (inputMode === 'contactInformation') {
      return contactProperties;
    }
    if (inputMode === 'diversityInformation') {
      return diversityProperties;
    }
    if (inputMode === 'pastActivity') {
      return pastActivityProperties;
    }
    if (inputMode === 'keywords') {
      return keywordsProperties;
    }
    return customFieldsProperties;
  };

  const properties: PropertyFilter[] = getPropertiesFromMode(mode);
  const handleSelectedPropertyType = (
    selectedProperty: PropertyFilter,
  ) => () => {
    setSelectedPropertyFilter(selectedProperty);
  };

  const handleSelectedStringOperator = (operator: StringOperator) => {
    if (selectedPropertyFilter) {
      setSelectedPropertyFilter({
        id: selectedPropertyFilter.id,
        name: selectedPropertyFilter.name,
        type: selectedPropertyFilter.type === 'text' ? 'text' : 'inline-text',
        operator,
      });
    }
  };

  const handleSelectedDateOperator = (operator: DateOperator) => {
    if (selectedPropertyFilter) {
      setSelectedPropertyFilter({
        id: selectedPropertyFilter.id,
        name: selectedPropertyFilter.name,
        type: 'day',
        operator,
      });
    }
  };

  const handleSelectedNumberOperator = (operator: NumberOperator) => {
    if (selectedPropertyFilter) {
      setSelectedPropertyFilter({
        id: selectedPropertyFilter.id,
        name: selectedPropertyFilter.name,
        type: selectedPropertyFilter.type === 'float' ? 'float' : 'integer',
        operator,
      });
    }
  };

  const handleSelectEnumValueOperator = (operator: EnumOperator) => {
    if (selectedPropertyFilter && selectedPropertyFilter.type === 'enum') {
      setSelectedPropertyFilter({
        id: selectedPropertyFilter.id,
        name: selectedPropertyFilter.name,
        type: 'enum',
        operator,
        options: selectedPropertyFilter.options,
      });
    }
  };

  const handleSelectContactValueOperator = (newOperator: ContactOperator) => {
    if (selectedPropertyFilter && selectedPropertyFilter.type === 'contact') {
      const operator = selectedPropertyFilter.operator?.includes(newOperator)
        ? selectedPropertyFilter.operator?.filter((op) => op !== newOperator)
        : [newOperator, ...(selectedPropertyFilter?.operator ?? [])];

      setSelectedPropertyFilter({
        ...selectedPropertyFilter,
        id: selectedPropertyFilter.id,
        name: selectedPropertyFilter.name,
        type: 'contact',
        operator,
      });
    }
  };

  const handleSelectDiversityValueOperator = (
    newOperator: DiversityOperator,
  ) => {
    if (selectedPropertyFilter && selectedPropertyFilter.type === 'diversity') {
      const operator = selectedPropertyFilter.operator?.includes(newOperator)
        ? selectedPropertyFilter.operator?.filter((op) => op !== newOperator)
        : [newOperator, ...(selectedPropertyFilter?.operator ?? [])];

      setSelectedPropertyFilter({
        ...selectedPropertyFilter,
        id: selectedPropertyFilter.id,
        name: selectedPropertyFilter.name,
        type: 'diversity',
        operator,
      });
    }
  };

  const handleSelectKeywordsValueOperator = (newOperator: KeywordsOperator) => {
    if (selectedPropertyFilter && selectedPropertyFilter.type === 'keywords') {
      const operator = selectedPropertyFilter.operator?.includes(newOperator)
        ? selectedPropertyFilter.operator?.filter((op) => op !== newOperator)
        : [newOperator, ...(selectedPropertyFilter?.operator ?? [])];

      setSelectedPropertyFilter({
        ...selectedPropertyFilter,
        id: selectedPropertyFilter.id,
        name: selectedPropertyFilter.name,
        type: 'keywords',
        operator,
      });
    }
  };

  const handleValueChange = (newValue: string) => {
    if (
      selectedPropertyFilter &&
      selectedPropertyFilter.type !== 'enum' &&
      selectedPropertyFilter.type !== 'contact' &&
      selectedPropertyFilter.type !== 'diversity' &&
      selectedPropertyFilter.type !== 'keywords'
    ) {
      setSelectedPropertyFilter({
        ...selectedPropertyFilter,
        value: newValue,
      });
    }
  };

  const handleEnumValueChange = (newValue: string[]) => {
    if (selectedPropertyFilter && selectedPropertyFilter.type === 'enum') {
      setSelectedPropertyFilter({
        ...selectedPropertyFilter,
        value: newValue,
      });
    }
  };

  const handleKeywordsValueChange = (
    value: Partial<Record<KeywordsOperator, string[]>>,
    operator?: KeywordsOperator[],
  ) => {
    if (selectedPropertyFilter && selectedPropertyFilter.type === 'keywords') {
      const newFilter = operator
        ? {
            ...selectedPropertyFilter,
            operator,
            value,
          }
        : {
            ...selectedPropertyFilter,
            value,
          };

      setSelectedPropertyFilter(newFilter);
    }
  };

  const handleKeywordsEmplacementChange = (value: KeywordsEmplacement) => {
    if (selectedPropertyFilter && selectedPropertyFilter.type === 'keywords') {
      const newFilter = {
        ...selectedPropertyFilter,
        emplacements: [value],
      };

      setSelectedPropertyFilter(newFilter);
    }
  };

  const handleContactValueChange = (
    value: Record<ContactOperator, string[]>,
    operator?: ContactOperator[],
  ) => {
    if (selectedPropertyFilter && selectedPropertyFilter.type === 'contact') {
      const newFilter = operator
        ? {
            ...selectedPropertyFilter,
            operator,
            value,
          }
        : {
            ...selectedPropertyFilter,
            value,
          };

      setSelectedPropertyFilter(newFilter);
    }
  };

  const handleDiversityValueChange = (
    value: Record<DiversityOperator, string[]>,
    operator?: DiversityOperator[],
  ) => {
    if (selectedPropertyFilter && selectedPropertyFilter.type === 'diversity') {
      const newFilter = operator
        ? {
            ...selectedPropertyFilter,
            operator,
            value,
          }
        : {
            ...selectedPropertyFilter,
            value,
          };
      setSelectedPropertyFilter(newFilter);
    }
  };

  return (
    <Popup
      className='custom-criteria-pop-in'
      content={
        <>
          {!selectedPropertyFilter &&
            (mode === 'customFields' || mode === 'pastActivity') && (
              <>
                {MIN_PROPERTIES_TO_DISPLAY_SEARCH < properties.length && (
                  <Input
                    className='search'
                    value={search}
                    onChange={(ev) => {
                      setSearch(ev.target.value);
                    }}
                    placeholder={t('reveal.searchView.search.searchProperty')}
                  />
                )}
                {properties
                  .filter((p) => compareString(p.name, search))
                  .map((property) => (
                    <div
                      className='dropdown-row'
                      key={property.id}
                      onClick={handleSelectedPropertyType(property)}
                    >
                      {mode === 'pastActivity'
                        ? t(
                            `reveal.searchView.search.pastActivity.${property.id}`,
                          )
                        : property.name || '???'}
                    </div>
                  ))}
              </>
            )}
          {selectedPropertyFilter && (
            <>
              {selectedPropertyFilter.type === 'inline-text' && (
                <CustomCriteriaString
                  onClose={closePopup}
                  onSelectedOperatorChange={handleSelectedStringOperator}
                  selectedOperator={selectedPropertyFilter.operator}
                  value={selectedPropertyFilter.value ?? ''}
                  onValueChange={handleValueChange}
                />
              )}
              {selectedPropertyFilter.type === 'text' && (
                <CustomCriteriaString
                  onClose={closePopup}
                  onSelectedOperatorChange={handleSelectedStringOperator}
                  selectedOperator={selectedPropertyFilter.operator}
                  value={selectedPropertyFilter.value ?? ''}
                  onValueChange={handleValueChange}
                />
              )}
              {selectedPropertyFilter.type === 'day' && (
                <CustomCriteriaDate
                  onClose={closePopup}
                  onSelectedOperatorChange={handleSelectedDateOperator}
                  selectedOperator={selectedPropertyFilter.operator}
                  value={selectedPropertyFilter.value ?? ''}
                  onValueChange={handleValueChange}
                />
              )}
              {selectedPropertyFilter.type === 'float' && (
                <CustomCriteriaNumber
                  onClose={closePopup}
                  onSelectedOperatorChange={handleSelectedNumberOperator}
                  selectedOperator={selectedPropertyFilter.operator}
                  value={selectedPropertyFilter.value ?? ''}
                  onValueChange={handleValueChange}
                />
              )}
              {selectedPropertyFilter.type === 'integer' && (
                <CustomCriteriaNumber
                  onClose={closePopup}
                  onSelectedOperatorChange={handleSelectedNumberOperator}
                  selectedOperator={selectedPropertyFilter.operator}
                  value={selectedPropertyFilter.value ?? ''}
                  onValueChange={handleValueChange}
                />
              )}
              {selectedPropertyFilter.type === 'enum' && (
                <CustomCriteriaEnum
                  onClose={closePopup}
                  onSelectedOperatorChange={handleSelectEnumValueOperator}
                  selectedOperator={selectedPropertyFilter.operator}
                  value={selectedPropertyFilter.value ?? []}
                  onValueChange={handleEnumValueChange}
                  options={selectedPropertyFilter.options}
                />
              )}
              {selectedPropertyFilter.type === 'keywords' && (
                <CustomCriteriaKeywords
                  onClose={closePopup}
                  onSelectedOperatorChange={handleSelectKeywordsValueOperator}
                  value={selectedPropertyFilter.value ?? {}}
                  selectedOperator={selectedPropertyFilter.operator || []}
                  onValueChange={handleKeywordsValueChange}
                  emplacement={
                    selectedPropertyFilter.emplacements?.[0] as
                      | KeywordsEmplacement
                      | undefined
                  }
                  onEmplacementChange={handleKeywordsEmplacementChange}
                />
              )}
            </>
          )}
          {selectedPropertyFilter?.type === 'contact' && (
            <CriteriaContact
              onClose={closePopup}
              onSelectedOperatorChange={handleSelectContactValueOperator}
              value={selectedPropertyFilter.value ?? {}}
              selectedOperator={selectedPropertyFilter.operator || []}
              onValueChange={handleContactValueChange}
            />
          )}
          {selectedPropertyFilter?.type === 'diversity' && (
            <CriteriaDiversity
              onClose={closePopup}
              onSelectedOperatorChange={handleSelectDiversityValueOperator}
              value={selectedPropertyFilter.value ?? {}}
              selectedOperator={selectedPropertyFilter.operator || []}
              onValueChange={handleDiversityValueChange}
            />
          )}
        </>
      }
      trigger={
        <div
          onClick={() => {
            if (mode === 'contactInformation') {
              handleSelectedPropertyType(contactProperties[0])();
            }
            if (mode === 'diversityInformation') {
              handleSelectedPropertyType(diversityProperties[0])();
            }
            if (mode === 'keywords') {
              handleSelectedPropertyType(keywordsProperties[0])();
            }
          }}
        >
          {triggerButton}
        </div>
      }
      popperDependencies={[
        selectedPropertyFilter?.type,
        selectedPropertyFilter?.operator,
        search,
      ]}
      on='click'
      position='bottom left'
      onClose={closePopup}
      basic
      open={isOpen}
      onOpen={() => setIsOpen(true)}
    />
  );
};

const compareString = (a: string, b: string) =>
  a.toLocaleLowerCase().includes(b.toLocaleLowerCase());

export default compose(withTranslation('translations'))(
  CustomCriteriaFiltersTemplate,
);
