import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Dropdown, Menu } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { sentryCaptureException } from '@/common';

import { useClientRevealConnector } from '@/graphql/hooks/clients/useClientRevealProjects';
import {
  REMOVE_SYNCHRONIZED_PROFILE_ACTIVITY_FROM_ATS,
  SYNCHRONIZE_PROFILE_ACTIVITY,
} from '@/graphql/searchPoolProfile';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import { Profile } from '@/types/profile';
import {
  ATS_NAMES_BY_TYPE,
  CONNECTORS_WITHOUT_SYNCHRONIZATION_UPDATE_CAPABILITIES,
} from '@/common/reveal';
import { TimelineItem } from '@/types/contactData';
import { connectorCanBeSynchronized } from '@/common/reveal/utils';
import {
  SynchronizationCheckedIcon,
  SynchronizationCrossIcon,
} from '@/assets/icons';
import { getSynchronizedATSNames } from './utils';
import './TimelineItemActions.less';

// import './DefaultPendingAction.css';

interface TimelineItemActionsProps {
  clientId: string;
  profile: SearchPoolProfile | Profile;
  onEdit?: (args: any) => void | Promise<void>;
  onRemove: (args: any) => void | Promise<void>;
  onPin?: () => void;
  onUnpin?: () => void;
  timelineItem: any;
  withAtsSynchronization?: boolean;
}

const TimelineItemActions: React.FC<TimelineItemActionsProps> = ({
  clientId,
  profile,
  onRemove,
  onEdit,
  timelineItem,
  withAtsSynchronization = true,
  onPin,
  onUnpin,
}) => {
  const { t } = useTranslation();

  if (timelineItem.isExternal) {
    return null;
  }

  const { isPinned } = timelineItem;

  return (
    <Dropdown
      className='timeline-item-action-dropdown'
      text=''
      icon='ri-more-fill'
      direction='left'
    >
      <Dropdown.Menu>
        <Dropdown.Item
          icon={<i className='ri-delete-bin-line' />}
          text={t('profile.contact.timeline.itemActions.remove')}
          onClick={onRemove}
        />
        {onEdit && (
          <Dropdown.Item
            icon={<i className='ri-edit-line' />}
            text={t('profile.contact.timeline.itemActions.edit')}
            onClick={onEdit}
          />
        )}
        {onPin && !isPinned && (
          <Dropdown.Item
            icon={<i className='ri-pushpin-line' />}
            text={t('profile.contact.timeline.itemActions.pin')}
            onClick={onPin}
          />
        )}
        {onUnpin && isPinned && (
          <Dropdown.Item
            icon={<i className='ri-unpin-line' />}
            text={t('profile.contact.timeline.itemActions.unpin')}
            onClick={onUnpin}
          />
        )}
        {withAtsSynchronization && (
          <>
            <Dropdown.Divider />
            <SynchronizationActions
              clientId={clientId}
              profile={profile}
              timelineItem={timelineItem}
            />
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

interface TimelineItemSynchronizationActionsProps {
  clientId: string;
  profile: SearchPoolProfile | Profile;
  timelineItem: TimelineItem;
}

export const TimelineItemSynchronizationActions: React.FC<TimelineItemSynchronizationActionsProps> = ({
  clientId,
  profile,
  timelineItem,
}) => {
  if (
    !useSynchronizationActionsShouldBeDisplayed({
      clientId,
      profile,
      timelineItem,
    })
  ) {
    return null;
  }

  return (
    <Dropdown text='' icon='ri-more-fill' direction='left'>
      <Dropdown.Menu>
        <SynchronizationActions
          clientId={clientId}
          profile={profile}
          timelineItem={timelineItem}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

interface SynchronizationActionsProps {
  clientId: string;
  profile: SearchPoolProfile | Profile;
  timelineItem: TimelineItem;
}

const useSynchronizationActionsShouldBeDisplayed = ({
  clientId,
  timelineItem,
  profile,
}: SynchronizationActionsProps) => {
  const defaultConnector = useClientRevealConnector(clientId);
  const { atsSynchronizations } = timelineItem;
  const synchronized = !_.isEmpty(atsSynchronizations);

  if (!defaultConnector) {
    return false;
  }

  if (!connectorCanBeSynchronized(defaultConnector?.type)) {
    return false;
  }

  const profileSynchronized = !!profile?.resumeData?.sourceData?.[
    defaultConnector?.type
  ]?.sourceCandidateId;
  if (!profileSynchronized) {
    return false;
  }

  if (
    synchronized &&
    _.contains(
      CONNECTORS_WITHOUT_SYNCHRONIZATION_UPDATE_CAPABILITIES,
      defaultConnector.type,
    )
  ) {
    return false;
  }
  return true;
};

const SynchronizationActions: React.FC<SynchronizationActionsProps> = ({
  clientId,
  profile,
  timelineItem,
}) => {
  const { t } = useTranslation();
  const { error, success } = useNotificationSystem();
  const defaultConnector = useClientRevealConnector(clientId);
  const [synchronizeProfileActivity] = useMutation(
    SYNCHRONIZE_PROFILE_ACTIVITY,
  );
  const [removeSynchronizedProfileActivityFromATS] = useMutation(
    REMOVE_SYNCHRONIZED_PROFILE_ACTIVITY_FROM_ATS,
  );
  const { atsSynchronizations } = timelineItem;
  const synchronized = !_.isEmpty(atsSynchronizations);
  const synchronizedAtsNames = getSynchronizedATSNames({ atsSynchronizations });

  if (
    !useSynchronizationActionsShouldBeDisplayed({
      clientId,
      profile,
      timelineItem,
    })
  ) {
    return null;
  }

  if (!defaultConnector) {
    return null;
  }

  const tryDecorator = (mutation: (args?: any) => any) => async () => {
    try {
      await mutation({
        variables: {
          searchPoolId: 'reveal',
          profileId: profile.id,
          date: timelineItem.date,
        },
      });
      success(t('common.genericSuccess'));
    } catch (e) {
      error(t('common.genericError'));
      sentryCaptureException({
        error: e,
        tags: { feature: 'ATSSynchronization' },
      });
    }
  };

  if (synchronized) {
    return (
      <>
        <Menu.Header className='ats-status'>
          {synchronizedAtsNames} -{' '}
          {t('profile.contact.timeline.actions.synchronized')}
        </Menu.Header>
        <Dropdown.Item
          icon={<SynchronizationCrossIcon className='icon' />}
          text={t('profile.contact.timeline.actions.removeFromATS')}
          onClick={tryDecorator(removeSynchronizedProfileActivityFromATS)}
        />
      </>
    );
  }

  const atsName = ATS_NAMES_BY_TYPE[defaultConnector.type];
  return (
    <>
      <Menu.Header className='ats-status'>
        {atsName} - {t('profile.contact.timeline.actions.notSynchronized')}
      </Menu.Header>
      <Dropdown.Item
        icon={<SynchronizationCheckedIcon className='icon' />}
        text={t('profile.contact.timeline.actions.synchronize')}
        onClick={tryDecorator(synchronizeProfileActivity)}
      />
    </>
  );
};

export default TimelineItemActions;
