import React from 'react';
import _ from 'underscore';
import { Loader, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { MissionWorkflow } from '@/types/searchPoolJob';
import { ATS_WITH_JOB_SPECIFIC_STAGES } from '@/common/reveal';
import GenericButton from '@/components/Common/GenericButton';
import useAtsConfigurationPermission from '@/graphql/hooks/clients/useAtsConfigurationPermission';
import GenericTooltip from '@/components/Common/GenericTooltip';
import Workflow from './components/Workflow';
import {
  useMissionWorkflowsContext,
  WORKFLOWS_CONTEXT_MODE,
} from './context/useMissionWorkflowsContext';
import ATSConnector from './components/ATSConnector';
import ATSEmptyState from './components/ATSEmptyState';

const MissionWorkflows: React.FC = () => {
  const {
    styles,
    loading,
    integrationTitle,
    connector,
    workflows,
    addStep,
    mode,
  } = useMissionWorkflowsContext();
  const { t } = useTranslation();

  const canEditAtsConfiguration = useAtsConfigurationPermission();

  if (loading) {
    return <Loader inline='centered' active />;
  }

  if (
    ATS_WITH_JOB_SPECIFIC_STAGES.includes(connector?.type) &&
    mode === WORKFLOWS_CONTEXT_MODE.CLIENT_DEFAULT_WORKFLOWS
  ) {
    return <></>;
  }

  const i18nWorkflowKey =
    mode === WORKFLOWS_CONTEXT_MODE.MISSION_WORKFLOWS
      ? 'missionWorkflows'
      : 'clientDefaultWorkflows';

  return (
    <Segment className='pure-criteria-form'>
      <div className={styles.workflowsSection}>
        <div className={styles.header}>
          <h2>{t(`reveal.workflows.${i18nWorkflowKey}.title`)}</h2>
        </div>

        {!connector || connector.type === 'custom' ? (
          <div className={styles.connector}>
            <ATSEmptyState />
          </div>
        ) : (
          <>
            <div className={styles.hint}>
              <i className='ri-lightbulb-flash-line' />
              <span>
                {t(`reveal.workflows.${i18nWorkflowKey}.hint`, {
                  integrationTitle: integrationTitle ?? 'your ats',
                })}
              </span>
            </div>

            {mode === WORKFLOWS_CONTEXT_MODE.MISSION_WORKFLOWS && (
              <>
                <div className={styles.connector}>
                  <ATSConnector />
                </div>

                <hr className={styles.divider} />
              </>
            )}

            <div className={styles.section}>
              <span className={styles.title}>
                {t(`reveal.workflows.${i18nWorkflowKey}.workflows`)}
                {workflows.length > 0 && (
                  <span className={styles.count}>{workflows.length}</span>
                )}
              </span>
              <WorkflowsList workflows={workflows} styles={styles} />
            </div>

            <div className={styles.footer}>
              {canEditAtsConfiguration ? (
                <GenericButton
                  size='big'
                  primacy='secondary'
                  onClick={() => addStep()}
                >
                  {t('reveal.workflows.addStep')}
                </GenericButton>
              ) : (
                <GenericTooltip
                  trigger={
                    <GenericButton size='big' primacy='secondary' disabled>
                      {t('reveal.workflows.addStep')}
                    </GenericButton>
                  }
                >
                  {t('common.permissions.disabled')}
                </GenericTooltip>
              )}
            </div>
          </>
        )}
      </div>
    </Segment>
  );
};

const WorkflowsList: React.FC<{
  workflows: MissionWorkflow[];
  styles: any;
}> = ({ workflows, styles }) => {
  return (
    <div className={styles.workflowsList}>
      {_.map(workflows, (workflow: MissionWorkflow) => (
        <Workflow key={workflow.id} workflow={workflow} />
      ))}
    </div>
  );
};

export default MissionWorkflows;
