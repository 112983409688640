import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal, Input } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { CLIENT_CAMPAIGNS, CREATE_CAMPAIGN, UPDATE_CAMPAIGN } from './queries';

import './NewCampaignModal.css';

const NewCampaignModal = ({
  clientId,
  open,
  onClose,
  t,
  campaign,
  editionMode,
}) => {
  const [
    createCampaign,
    { loading: creatingCampaign, error: campaignCreationError },
  ] = useMutation(CREATE_CAMPAIGN, {
    update: (cache, { data: { campaign: campaignMutationData } }) => {
      const data = cache.readQuery({
        query: CLIENT_CAMPAIGNS,
        variables: { clientId },
      });
      const newData = {
        client: {
          ...data.client,
          campaigns: [
            ...(data?.client?.campaigns || []),
            campaignMutationData?.create?.campaign,
          ],
        },
      };
      cache.writeQuery({
        query: CLIENT_CAMPAIGNS,
        data: newData,
      });
    },
  });

  const [
    updateCampaign,
    { loading: updatingCampaign, error: campaignEditionError },
  ] = useMutation(UPDATE_CAMPAIGN);
  const [campaignTitle, setCampaignTitle] = useState(
    campaign?.title || t('campaigns.titlePlaceholder'),
  );

  const [closeOnDimmerClick, setCloseOnDimmerClick] = useState(true);

  const onSubmit = async () => {
    if (editionMode) {
      await updateCampaign({
        variables: {
          input: {
            id: campaign.id,
            title: campaignTitle,
          },
        },
      });
    } else {
      await createCampaign({
        variables: {
          input: { title: campaignTitle },
        },
      });
    }
    if (!campaignCreationError && !campaignEditionError) {
      setCloseOnDimmerClick(true);
      onClose();
    }
  };

  return (
    <Modal
      className='new-campaign-modal'
      open={open}
      onClose={onClose}
      closeOnDimmerClick={closeOnDimmerClick}
      closeOnEscape={closeOnDimmerClick}
    >
      <Modal.Header>
        {editionMode
          ? t('campaigns.updateCampaignHeader')
          : t('campaigns.createCampaignHeader')}
      </Modal.Header>
      <Modal.Content scrolling>
        <h3 className='input-container'>
          <Input
            className='campaign-title-input'
            fluid
            placeholder={t('campaigns.title')}
            value={campaignTitle}
            onChange={(ev) => {
              setCampaignTitle(ev.target.value);
              setCloseOnDimmerClick(false);
            }}
          />
        </h3>
      </Modal.Content>
      <Modal.Actions>
        <div className='align-left'>
          <Button className='dismiss' type='button' onClick={onClose}>
            {t('common.cancel')}
          </Button>
        </div>

        <div className='submit-action'>
          <Button
            size='big'
            primary-
            type='button'
            onClick={onSubmit}
            loading={creatingCampaign || updatingCampaign}
          >
            {editionMode
              ? t('campaigns.buttons.save')
              : t('campaigns.buttons.create')}
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default withTranslation('translations')(NewCampaignModal);
