import gql from 'graphql-tag';

export const CREATE_DYNAMIC_IMPORTS = gql`
  mutation CreateDynamicImport(
    $input: CreateDynamicImportInput!
    $searchPoolId: ID!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      createDynamicImport(input: $input) {
        id
        dynamicImports {
          id
          descriptor {
            unipileSharedAccountId
            sourceJobId
          }
          creationDate
          type
          synchronizationSettings {
            type
          }
        }
      }
    }
  }
`;

export const UPDATE_DYNAMIC_IMPORTS = gql`
  mutation UpdateDynamicImport(
    $input: UpdateDynamicImportInput!
    $searchPoolId: ID!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      updateDynamicImport(input: $input) {
        id
        dynamicImports {
          id
          descriptor {
            unipileSharedAccountId
            sourceJobId
          }
          creationDate
          type
          synchronizationSettings {
            type
          }
        }
      }
    }
  }
`;

export const DELETE_DYNAMIC_IMPORTS = gql`
  mutation DeleteDynamicImport(
    $input: DeleteDynamicImportInput!
    $searchPoolId: ID!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      deleteDynamicImport(input: $input) {
        id
        dynamicImports {
          id
          descriptor {
            unipileSharedAccountId
            sourceJobId
          }
          creationDate
          type
          synchronizationSettings {
            type
          }
        }
      }
    }
  }
`;

export const TRIGGER_DYNAMIC_IMPORT = gql`
  mutation TriggerDynamicImport(
    $input: TriggerDynamicImport!
    $searchPoolId: ID!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      triggerDynamicImport(input: $input) {
        id
      }
    }
  }
`;
