import React from 'react';
import { Modal, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import InputControl from '@/components/InputControl';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { isCustomFieldEnum } from '@/components/CustomFields/CustomFieldForm';
import { SelectControl, DatePickerControl } from '@/components/FormFields';
import { getTranslatedText } from '@/common';
import { getCustomFieldJSONValue } from '@/common/customFields';
import GenericButton from '@/components/Common/GenericButton';

type ModalColumnDefaultValueProps = {
  open: boolean;
  closeModal: () => void;
  onSubmit: (defaultValue: string | string[]) => void;
  customFieldDefinition?: CustomFieldDefinition | null;
};
export const FormID = 'modal-csv-column-default-value';

type ColumnDefaultValueFormData = {
  defaultValue: string;
};
export default function ModalColumnDefaultValue(
  props: ModalColumnDefaultValueProps,
): JSX.Element {
  const { open, closeModal, onSubmit, customFieldDefinition } = props;
  const { t } = useTranslation();
  const form = useForm<ColumnDefaultValueFormData>({
    defaultValues: { defaultValue: '' },
  });

  const type = customFieldDefinition?.type || 'inline-text';
  const submit = (payload: ColumnDefaultValueFormData) => {
    const value = getCustomFieldJSONValue(type, payload.defaultValue);
    onSubmit(value ? String(value) : '');
    closeModal();
  };

  const enumOptions =
    customFieldDefinition?.type === 'enum' ? customFieldDefinition.options : [];
  const isMultiple =
    customFieldDefinition?.type === 'enum' &&
    customFieldDefinition?.isMultiselect;
  return (
    <Modal
      className='reveal-modal'
      open={open}
      onClose={closeModal}
      closeIcon
      centered
      style={{
        justifyContent: 'center',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
    >
      <Modal.Header>{t('CSVImport.addColumn')}</Modal.Header>
      <Modal.Content>
        <FormProvider {...form}>
          <Form
            onSubmit={form.handleSubmit(submit)}
            id={FormID}
            data-testid={FormID}
          >
            {isCustomFieldEnum(type) && (
              <SelectControl
                name='defaultValue'
                label={t('CSVImport.defaultValue')}
                options={enumOptions.map((option) => ({
                  label: getTranslatedText(option.title),
                  value: option.id,
                }))}
                multiple={isMultiple}
                placeholder={t('customFieldsSettings.defaultValuePlaceholder')}
                errorMessage={t('customFieldsSettings.defaultValueError')}
              />
            )}
            {!isCustomFieldEnum(type) && type !== 'day' && (
              <InputControl
                label={t('CSVImport.defaultValue')}
                name='defaultValue'
                type='text'
                placeholder={t('customFieldsSettings.defaultValuePlaceholder')}
                errorMessage={t('customFieldsSettings.defaultValueError')}
              />
            )}
            {type === 'day' && (
              <DatePickerControl
                label={t('CSVImport.defaultValue')}
                name='defaultValue'
                errorMessage={t('customFieldsSettings.defaultValueError')}
              />
            )}
          </Form>
        </FormProvider>
      </Modal.Content>
      <Modal.Actions style={{ gap: '8px' }}>
        <GenericButton primacy='secondary' onClick={closeModal}>
          {t('customFieldsSettings.cancel')}
        </GenericButton>
        <GenericButton primacy='primary' type='submit' form={FormID}>
          {t('customFieldsSettings.save')}
        </GenericButton>
      </Modal.Actions>
    </Modal>
  );
}
