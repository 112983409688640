import React from 'react';
import { Popup } from 'semantic-ui-react';

export default (props) => (
  <Popup
    hoverable
    trigger={
      <a
        spellCheck={false}
        data-offset-key={props.offsetKey}
        href={props.contentState.getEntity(props.entityKey).getData().url}
        target='_blank'
        rel='noopener noreferrer'>
        {props.children}
      </a>
    }>
    <Popup.Content>
      <a target='_blank' rel='noopener noreferrer' href={props.contentState.getEntity(props.entityKey).getData().url}>
        {props.contentState.getEntity(props.entityKey).getData().url}
      </a>
    </Popup.Content>
  </Popup>
);
