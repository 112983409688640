import gql from 'graphql-tag';

export default gql`
  fragment Workflow on Workflow {
    id
    owner {
      type
      clientId
      jobOfferId
      jobOfferTitle
    }
    action {
      type
      ... on GreenhouseUpload {
        command
        integrationId
        service
        target {
          jobId
          jobTitle
          recruiterId
          recruiterName
          stageStep
          stageName
        }
      }
      ... on AshbyUpload {
        command
        integrationId
        service
        target {
          jobId
          jobTitle
          recruiterId
          recruiterName
          stageStep
          stageName
        }
      }
      ... on PinpointUpload {
        command
        integrationId
        service
        target {
          jobId
          jobTitle
          stageStep
          stageName
        }
      }
      ... on RecruiteeUpload {
        command
        integrationId
        service
        target {
          jobId
          jobTitle
          stageStep
          stageName
        }
      }
      ... on TeamtailorUpload {
        command
        integrationId
        service
        target {
          jobId
          jobTitle
          stageStep
          stageName
        }
      }
      ... on WorkableUpload {
        command
        integrationId
        service
        target {
          jobId
          jobTitle
          recruiterId
          recruiterName
          stageStep
          stageName
        }
      }
      ... on WelcomekitUpload {
        command
        integrationId
        service
        target {
          jobId
          jobTitle
          stageStep
          stageName
        }
      }

      ... on SmartrecruitersUpload {
        command
        integrationId
        service
        target {
          jobId
          jobTitle
          stageStep
          stageName
        }
      }
      ... on LeverUpload {
        command
        integrationId
        service
        target {
          jobId
          jobTitle
          recruiterId
          recruiterName
          stageStep
          stageName
        }
      }
    }
    rule {
      type
    }
  }
`;
