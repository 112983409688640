import React from 'react';

export default (WrappedComponent) => {
  return class withResetPassword extends React.Component {
    handleResetPassword = (token, { password, clientId }) =>
      fetch(`${process.env.REACT_APP_SERVER_URL}/auth/reset-password`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          token,
          password,
          clientId,
        }),
      })
        .then((res) => {
          if (!res.ok) throw new Error(res.statusText);
          return res.headers.get('Authorization');
        })
        .then((jwtoken) => {
          localStorage.setItem('token', jwtoken);
        });

    render() {
      return (
        <WrappedComponent
          onResetPassword={this.handleResetPassword}
          {...this.props}
        />
      );
    }
  };
};
