import React from 'react';
import GenericButton from '@/components/Common/GenericButton';

import styles from './ActionBox.module.less';

type ActionBoxProps = {
  title: string;
  buttonText: string;
  onClick: () => void;
  description?: string;
  buttonIcon?: string;
};

export default function ActionBox(props: ActionBoxProps): JSX.Element {
  const { title, buttonText, description, onClick, buttonIcon } = props;
  return (
    <div className={styles.actionBox}>
      <div className='action-box-content'>
        <div className={styles.title}>{title}</div>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      <GenericButton primacy='secondary' onClick={onClick}>
        {buttonIcon && <i className={buttonIcon} />}
        {buttonText}
      </GenericButton>
    </div>
  );
}
