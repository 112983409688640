import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

import './StatusButton.css';

const StatusButton = ({ state, taskType, onMarkAsCompleted }) => {
  const { t } = useTranslation();
  if (!state) {
    return '--';
  }

  if (taskType === 'application-review' && state !== 'completed') {
    return null;
  }

  if (state === 'completed') {
    return (
      <Button className='task-status-button completed' type='button'>
        <i className='ri-check-line' />
        <span className='body-medium'>{t('reveal.todosView.states.completed')}</span>
      </Button>
    );
  }

  if (state === 'deleted') {
    return (
      <Button className='task-status-button deleted' type='button'>
        <i className='ri-close-line' />
        <span className='body-medium'>{t('reveal.todosView.states.deleted')}</span>
      </Button>
    );
  }

  if (state === 'in-progress') {
    return (
      <Button
        className={classNames('task-status-button', state)}
        type='button'
        onClick={(e) => {
          e.stopPropagation();
          // onMarkAsCompleted();
        }}
      >
        {false ? (/* deactivated */
          <>
            <i className='ri-check-line' />
            <span className='body-medium'>Interrupt this task?</span>
          </>
        ) : (
          <>
            <i className='ri-loader-3-line' />
            <span className='body-medium'>{t(`reveal.todosView.states.${state}`)}</span>
          </>
        )}
      </Button>
    );
  }

  if (state !== 'completed') {
    return (
      <Button
        className={classNames('task-status-button', state)}
        type='button'
        onClick={(e) => {
          e.stopPropagation();
          // onMarkAsCompleted();
        }}
      >
        {false ? (
          <>
            <i className='ri-check-line' />
            <span className='body-medium'>Mark as completed?</span>
          </>
        ) : (
          <>
            <i className='ri-loader-3-line' />
            <span className='body-medium'>{t(`reveal.todosView.states.${state}`)}</span>
          </>
        )}
      </Button>
    );
  }

  return null;
};

export default StatusButton;
