import gql from 'graphql-tag';

export const getUserClientMailAccount = gql`
  query getUserClientMailAccount {
    user {
      id
      email
      hasAllowedGrantOffline
      alias
      signature
      mailAccount {
        type
        address
      }
    }
  }
`;
