import _ from 'underscore';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import classNames from 'classnames';

import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import { ISearchPoolJobOption } from '@/graphql/fragments/SearchPoolJobOption';
import { toString } from '@/common/utils/string';

import styles from './RevealAnalyticsHeader.module.less';

const MAX_RESULT_DISPLAY = 4;

const MissionsDropdown = (props: {
  selectedMissions: string[] | undefined;
  onChangeMissions: (missions: string[]) => void;
}) => {
  const { t } = useTranslation();
  const { selectedMissions, onChangeMissions } = props;
  const { jobOptions, jobOptionsLoading } = useSearchPoolJobOptions('reveal', {
    activeOnly: true,
  });

  const handleChangeMission = (
    ev: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
  ) => {
    if (Array.isArray(data.value)) {
      const values = data.value.map(toString);
      onChangeMissions(values);
    }
  };

  if (jobOptionsLoading) return <></>;

  const renderLabel = (item: DropdownItemProps, index: number) => {
    if (index < MAX_RESULT_DISPLAY) {
      return {
        content: item.text,
      };
    }
    if (index === MAX_RESULT_DISPLAY) {
      return {
        content: (
          <div
            onClick={() =>
              onChangeMissions(
                selectedMissions?.slice(0, MAX_RESULT_DISPLAY) ?? [],
              )
            }
          >
            {t('reveal.reports.header.countMissions', {
              nbr:
                (selectedMissions?.length ?? MAX_RESULT_DISPLAY) -
                MAX_RESULT_DISPLAY,
            })}
          </div>
        ),
      };
    }
    return null;
  };

  const dropdownOptions = _.map(jobOptions, (job: ISearchPoolJobOption) => ({
    text: job?.data?.title,
    value: job.id,
    selected: selectedMissions?.includes(job.id),
  }));

  return (
    <Dropdown
      selection
      search
      multiple
      onChange={handleChangeMission}
      value={selectedMissions ?? []}
      options={dropdownOptions}
      className={classNames(
        styles.multiDropdown,
        styles.missionsDropdown,
        'hiresweet-rounded',
      )}
      placeholder={t('reveal.reports.header.missionsPlaceholder')}
      renderLabel={renderLabel}
    />
  );
};

export default MissionsDropdown;
