import React from 'react';
import { withTranslation } from 'react-i18next';

import { Popup } from 'semantic-ui-react';
import './WatchLabel.css';

export const OfferWatchLabel = withTranslation('translations')(({ t }) => (
  <div className='offer-label-container'>
    <Popup
      trigger={
        <div>
          <div className='offer-label watch-label'>{t('hiresweetWatch.prequalifiedProfiles')}</div>
        </div>
      }
      size='tiny'
      position='bottom center'
      content={
        <span>{t('hiresweetWatch.description')}</span>
      }
    />
  </div>
));

export const ProfileWatchLabel = withTranslation('translations')(({ t }) => (
  <div className='profile-watch-label-container'>
    <div className='profile-watch-label-subcontainer'>
      <Popup
        trigger={
          <div>
            <div className='watch-label'>{t('hiresweetWatch.prequalifiedProfile')}</div>
          </div>
        }
        size='tiny'
        position='bottom center'
        content={
          <span>{t('hiresweetWatch.description')}</span>
        }
      />
    </div>
  </div>
));



