import React from 'react';
import _ from 'underscore';
import classNames from 'classnames';

import './RecursiveSelector.css';

const RecursiveSelector = ({ folderedOptions, filter, onUpdateFilter, level }) => {
  const recursionLevel = level || 0;

  const handleToggle = ({ id }) => {
    if (filter[id]) {
      onUpdateFilter({
        filter: {
          ...filter,
          [id]: null,
        },
      });
    } else {
      onUpdateFilter({
        filter: {
          ...filter,
          [id]: {},
        },
      });
    }
  };

  const handleUpdateSubfilterFunc = (id) => (arg) => {
    onUpdateFilter({
      filter: {
        ...filter,
        [id]: arg.filter,
      },
    });
  };

  return (
    <div className={classNames('recursive-selector', `depth_${recursionLevel}`)}>
      {_.map(folderedOptions, ({ id, name, subOptions }) => (
        <div key={id} className={classNames('recursive-selector-container', `depth_${recursionLevel}`)}>
          <div className={classNames('selector-title', filter[id] && 'selected')} onClick={() => handleToggle({ id })}>
            {name}
          </div>

          {!_.isEmpty(subOptions) && filter[id] && (
            <RecursiveSelector
              folderedOptions={subOptions}
              filter={filter[id]}
              onUpdateFilter={handleUpdateSubfilterFunc(id)}
              level={recursionLevel + 1}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default RecursiveSelector;
