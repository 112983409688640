import React from 'react';

export default (WrappedComponent) =>
  class withSignin extends React.Component {
    handleSignin = async ({ email, password, mfaTOTP }) => {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/auth/signin`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            email: email ? email.toLowerCase() : null,
            password,
            clientId: this.props.clientId,
            mfaTOTP: mfaTOTP || null,
          }),
        },
      );

      if (res.status === 400) {
        // the server tells us to use mfa
        const json = await res.json();
        if (!json.mfaUrl) {
          return { mfa: true };
        }
        return { mfaUrl: json.mfaUrl };
      }

      if (!res.ok) {
        console.error(res.statusText);
        throw new Error(res.statusText);
      }
      localStorage.setItem('token', res.headers.get('Authorization'));
      return undefined;
    };

    render() {
      return <WrappedComponent onSignin={this.handleSignin} {...this.props} />;
    }
  };
