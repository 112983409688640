import gql from 'graphql-tag';
import Author from './fragments/Author';
import Campaign from './fragments/Campaign';

export const GET_CAMPAIGN = gql`
  query getCampaign($campaignId: ID!) {
    campaign(id: $campaignId) {
      ...Campaign
    }
  }
  ${Author}
  ${Campaign}
`;

export const GET_CAMPAIGN_WITH_MINI_PROFILES = gql`
  query getCampaignWithMiniProfiles($campaignId: ID!) {
    campaign(id: $campaignId) {
      id
      title
      profilesCount
      profiles {
        id
        campaignEnrollments {
          id
          campaignId
          state {
            archived
            hold
            status
            nextActionDate
            completionTimestamp
          }
        }
        resumeData {
          firstname
          lastname
        }
      }
    }
  }
`;

export interface GetCampaignListResults {
  client: {
    id: string;
    campaigns: Array<{
      id: string;
      title: string | null;
      isArchived: string | null;
    }> | null;
  };
}

export interface GetCampaignListVariables {
  clientId: string;
  activeOnly?: boolean;
}

export const GET_CAMPAIGN_LIST = gql`
  query getCampaignList($clientId: ID!, $activeOnly: Boolean) {
    client(id: $clientId) {
      id
      campaigns(filters: { activeOnly: $activeOnly }) {
        id
        title
        isArchived
        profilesCount
      }
    }
  }
`;
