import gql from 'graphql-tag';
import { type QueryHookOptions, useQuery } from '@apollo/client';

import type { ProfileOverviewCardItem } from '@/common/helpers/profileOverviewCard';
import type { TranslatableText } from '@/types/text';

const getUserProfileViewDisplayPreferences = gql`
  query getUserProfileViewDisplayPreferences {
    user {
      id
      email
      displayPreferences {
        profileViews {
          profileViewId
          overviewCard {
            title {
              default
              fr
              en
            }
            items {
              label {
                default
                fr
                en
              }
              content {
                ... on ProfileOverviewCardCustomFieldItemContent {
                  customFieldId
                  customFieldType
                  type
                }
                ... on ProfileOverviewCardBuiltinStringItemContent {
                  type
                  path
                }
                ... on ProfileOverviewCardBuiltinDateItemContent {
                  type
                  path
                }
                ... on ProfileOverviewCardCompositeItemContent {
                  type
                  elements {
                    ... on ProfileOverviewCardCustomFieldItemContent {
                      customFieldId
                      customFieldType
                      type
                    }
                    ... on ProfileOverviewCardBuiltinStringItemContent {
                      type
                      path
                    }
                    ... on ProfileOverviewCardBuiltinDateItemContent {
                      type
                      path
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type Data = {
  user?: {
    id: string;
    displayPreferences?: {
      profileViews?: {
        profileViewId: string;
        overviewCard?: {
          title: TranslatableText;
          items: ProfileOverviewCardItem[];
        };
      }[];
    };
  };
};

type Variables = Record<string, never>;

type Input = {
  queryOptions?: QueryHookOptions<Data, Variables>;
};

function useUserProfileViewDisplayPreferences({
  queryOptions = {},
}: Input = {}) {
  const query = useQuery<Data, Variables>(
    getUserProfileViewDisplayPreferences,
    queryOptions,
  );
  const profileViews = query.data?.user?.displayPreferences?.profileViews ?? [];
  return { ...query, profileViews };
}

export default useUserProfileViewDisplayPreferences;
