import React, { useState } from 'react';
import ProfileDimmer from '@/components/Dimmer/ProfileDimmer';
import ProfilePreviewLoader from './ProfilePreviewLoader';

const SearchPoolProfileModal = ({
  clientId,
  selectedProfileId,
  searchPoolId,
  profileIds,
  onSelectProfileId,
  onClose,
  user,
  onMarkHidden,
  onMarkUnhidden,
  setProfilesInJobIds,
  searches,
}) => {
  const [navDirection, setNavDirection] = useState('down');
  const currentProfileIndex = (profileIds || []).indexOf(selectedProfileId);
  const previousProfileId =
    currentProfileIndex > 0 && profileIds[currentProfileIndex - 1];
  const nextProfileId =
    currentProfileIndex !== -1 && profileIds[currentProfileIndex + 1];
  // console.log({ selectedProfileId, nextProfileId, previousProfileId });

  const goToNextProfileOrClose = () => {
    if (navDirection === 'down' && nextProfileId) {
      onSelectProfileId(nextProfileId);
      return;
    }
    if (navDirection === 'up' && previousProfileId) {
      onSelectProfileId(previousProfileId);
      return;
    }
    onClose();
  };

  return (
    <div className='overflow-modal'>
      <div className='profile-preview-container marketplace-profile'>
        <ProfileDimmer
          onSelectProfileId={onSelectProfileId}
          nextProfileId={nextProfileId}
          previousProfileId={previousProfileId}
          navDirectionState={{ get: navDirection, set: setNavDirection }}
          onClose={onClose}
        />
        <ProfilePreviewLoader
          clientId={clientId}
          searchPoolProfileId={selectedProfileId}
          nextProfileId={
            navDirection === 'down' ? nextProfileId : previousProfileId
          }
          searchPoolId={searchPoolId}
          user={user}
          onMarkHidden={onMarkHidden}
          onMarkUnhidden={onMarkUnhidden}
          setProfilesInJobIds={setProfilesInJobIds}
          searches={searches}
          onError={goToNextProfileOrClose}
          onSend={goToNextProfileOrClose}
          canApplyUnbiasedMode
        />
      </div>
    </div>
  );
};

export default SearchPoolProfileModal;
