import gql from 'graphql-tag';

export default /* GraphQL */ gql`
    fragment Billing on Billing {
        type
        stripeCustomerId
        email
        address
        paymentMethod {
            id
            type
            expMonth
            expYear
            last4Digits
        }
    }
`;
