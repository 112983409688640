import React, { FC } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import useClientId from '@/hooks/router/useClientId';

import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import NewCompanyButton from './NewCompanyButton';

import styles from './CompaniesView.module.less';
import CompaniesContactsView from './CompaniesContactsView/CompaniesContactsView';
import CompaniesOverview from './CompaniesOverview/CompaniesOverview';

interface CompaniesViewProps {
  currentTab: 'contacts' | 'overview';
}

const CompaniesView: FC<CompaniesViewProps> = ({ currentTab }) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { permissions } = useClientPermissions(clientId);

  return (
    <div className={styles.viewport}>
      <div className={styles.header}>
        <div className={styles.headerTabs}>
          <Link to={`/client/${clientId}/reveal/companies`}>
            <h2
              className={classnames(
                styles.title,
                currentTab === 'contacts' ? styles.selected : '',
              )}
            >
              {t('header.parameters.companies')}
            </h2>
          </Link>
          {permissions?.companiesOverview && (
            <Link to={`/client/${clientId}/reveal/companies/overview`}>
              <h2
                className={classnames(
                  styles.title,
                  currentTab === 'overview' ? styles.selected : '',
                )}
              >
                Overview
              </h2>
            </Link>
          )}
        </div>
        {currentTab === 'contacts' && <NewCompanyButton />}
      </div>
      {currentTab === 'contacts' && <CompaniesContactsView />}
      {currentTab === 'overview' && permissions?.companiesOverview && (
        <CompaniesOverview />
      )}
    </div>
  );
};

export default CompaniesView;
