import gql from 'graphql-tag';

export default gql`
  fragment ClientDefaultCrmWorkflows on MissionWorkflow {
    id
    rule {
      type
      condition {
        revealStageId
      }
    }
    action {
      integrationId
      service
      type
      command
      target {
        atsStageId
        linkedATSJobId
        linkedATSUserId
        revealStageId
      }
    }
  }
`;
