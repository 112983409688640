import React from 'react';
import { withTranslation } from 'react-i18next';
import { Checkbox } from 'semantic-ui-react';

import './FollowupButton.css';

const FollowupButton = ({ onAddFollowup, followupPosition, t }) => (
  <div className='followup-button' onClick={onAddFollowup} role='button'>
    <div className='followup-button-header'>
      <Checkbox checked={false} />
      <i className='ri-ghost-smile-line' />
      <span>
        {t('profile.contact.drafts.addFollowup')}
      </span>
    </div>
    {
      followupPosition <= 2 && (
        <div className='followup-button-explanation'>
          {t('profile.contact.drafts.addFollowupExplanations')}
        </div>
      )
    }
  </div>
);

export default withTranslation('translations')(FollowupButton);
