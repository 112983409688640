import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import ExpressionCurrentCompanyEvaluator from '@/common/mergeTags/externalEvaluators/currentCompanyEvaluator';
import ExpressionLastCompanyEvaluator from '@/common/mergeTags/externalEvaluators/lastCompanyEvaluator';
import ExpressionCustomFieldEvaluator from '@/common/mergeTags/externalEvaluators/customFieldsEvaluator';
import ExpressionCurrentJobTitleEvaluator from './currentJobTitleEvaluator';
import { SNIPPET_TYPES } from '../utils';
import ExpressionCurrentExperienceDurationEvaluator from './currentExperienceDurationEvaluator';
import ExpressionDurationSinceGraduationEvaluator from './durationSinceGraduationEvaluator';
import ExpressionMissionCustomFieldEvaluator from './missionCustomFieldsEvaluator';
import ExpressionKLastCompaniesEvaluator from './kLastCompaniesEvaluator';
import ExpressionConditionsChainingEvaluator from './conditionsChainingEvaluator';
import ExpressionTextWithSnippetsEvaluator from './textWithSnippetsEvaluator';
import ExpressionSyncedFragmentEvaluator from './syncedFragmentEvaluator';

const externalEvaluatorFromType: Record<
  string,
  SweetEvaluatorTypes.EvaluatorInterface
> = {
  [SNIPPET_TYPES.CURRENT_COMPANY]: new ExpressionCurrentCompanyEvaluator(),
  [SNIPPET_TYPES.CURRENT_JOB_TITLE]: new ExpressionCurrentJobTitleEvaluator(),
  [SNIPPET_TYPES.CUSTOM_FIELD]: new ExpressionCustomFieldEvaluator(),
  [SNIPPET_TYPES.MISSION_CUSTOM_FIELD]: new ExpressionMissionCustomFieldEvaluator(),
  [SNIPPET_TYPES.LAST_COMPANY]: new ExpressionLastCompanyEvaluator(),
  [SNIPPET_TYPES.CURRENT_EXPERIENCE_DURATION]: new ExpressionCurrentExperienceDurationEvaluator(),
  [SNIPPET_TYPES.DURATION_SINCE_GRADUATION]: new ExpressionDurationSinceGraduationEvaluator(),
  [SNIPPET_TYPES.K_LAST_COMPANIES]: new ExpressionKLastCompaniesEvaluator(),
  [SNIPPET_TYPES.CONDITIONS_CHAINING]: new ExpressionConditionsChainingEvaluator(),
  [SNIPPET_TYPES.TEXT_WITH_SNIPPETS]: new ExpressionTextWithSnippetsEvaluator(),
  [SNIPPET_TYPES.SYNCED_FRAGMENT]: new ExpressionSyncedFragmentEvaluator(),
};
const getExternalExpressionEvaluatorFromType = ({
  type,
}: {
  type: string;
}): SweetEvaluatorTypes.EvaluatorInterface => externalEvaluatorFromType[type];

export default getExternalExpressionEvaluatorFromType;
