import hubspot from './hubspot';
import smartrecruiters from './smartrecruiters';
import welcomekit from './welcomekit';
import welcomekitCRM from './welcomekitCRM';
import workable from './workable';
import lever from './lever';

const OAuth2Provider = ({
  type,
  reveal,
}: {
  type: string;
  reveal?: boolean;
}): {
  authorizeWithOAuth: ({
    onAuthorizedCallback,
  }: {
    onAuthorizedCallback: any;
  }) => () => Promise<{
    success: boolean;
    error?: Error | unknown;
  }>;
} => {
  switch (type) {
    case 'hubspot': {
      return hubspot;
    }
    case 'smartrecruiters': {
      return smartrecruiters;
    }
    case 'welcomekit': {
      if (reveal) {
        return welcomekitCRM;
      }
      return welcomekit;
    }
    case 'workable': {
      return workable;
    }
    case 'lever': {
      return lever;
    }
    default:
      throw new Error('Not implemented integration type');
  }
};

export default OAuth2Provider;
