import _ from 'underscore';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import {
  ATStype,
  ATS_NAMES_BY_TYPE,
  CONNECTORS_WITH_SYNCHRONIZATION_SETTINGS,
} from './index';

export const connectorCanBeSynchronized = (type?: ATStype): boolean => {
  if (!type) {
    return false;
  }
  return _.contains(CONNECTORS_WITH_SYNCHRONIZATION_SETTINGS, type);
};

export const profileIsInATS = (
  profile: SearchPoolProfile,
  type: ATStype,
): boolean =>
  type && !!profile?.resumeData?.sourceData?.[type]?.sourceCandidateId;

export const getATSName = (type?: ATStype): string | undefined =>
  type && ATS_NAMES_BY_TYPE[type];
