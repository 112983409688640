import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import styles from '../MetaTaskForm.module.less';
import {
  MetaTaskFormFieldOnSelect,
  MetaTaskFormatFormField,
  MetaTaskFormatFormFieldOption,
} from '../types';

export interface MetaTaskFormFieldSelectAnswerProps {
  field: MetaTaskFormatFormField;
  option?: MetaTaskFormatFormFieldOption;
  onSelect?: MetaTaskFormFieldOnSelect;
  disabled?: boolean;
  readMode?: boolean;
}

const MetaTaskFormFieldSelectableAnswer: React.FC<MetaTaskFormFieldSelectAnswerProps> = ({
  option,
  field,
  disabled = false,
  readMode,
  onSelect,
}) => {
  const [isSelected, setIsSelected] = useState(option?.selected);

  useEffect(() => {
    setIsSelected(option?.selected);
  }, [option]);

  const handleSelect = () => {
    if (onSelect && !disabled) {
      setIsSelected(!option?.selected);
      onSelect({ fieldId: field?.id || '', answerId: option?.id || '' });
    }
  };

  if (readMode) {
    return <span>{option?.title?.default || ''}</span>
  }

  return (
    <div
      className={classnames(
        styles.metaTaskSelectAnswer,
        isSelected ? styles.selected : null,
      )}
      onClick={handleSelect}
    >
      {option?.title?.default}
    </div>
  );
};

export default MetaTaskFormFieldSelectableAnswer;
