import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Input } from 'semantic-ui-react';
import _ from 'underscore';

import RevealApplicantsPane from '@/routes/RevealView/SearchView/ApplicantsPane/ApplicantsPane';
import useSavedSearchParams from '@/graphql/hooks/searchPool/useSavedSearchParams';
import { TabId } from '@/revealComponents/ProjectTypesTabs';
import CandidatesListPane from '@/routes/RevealView/SearchView/CandidatesListPane';
import RevealSearchPane from '@/routes/RevealView/SearchView/SearchPane';
import { cleanSearchParams } from '@/routes/RevealView/SearchView/utils';
import {
  CalibrationAndRecommendationIcon,
  ContactSearchIcon,
} from '@/assets/icons';
import RevealRecommendationPane from '@/routes/RevealView/SearchView/RecommendationPane/RecommendationPane';
import SavedSearchSelector from '@/components/Reveal/SavedSearch/SavedSearchSelector';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import MissionCategoryContext from '@/context/MissionCategoryContext';

import ProjectSearchParamsContextProvider from './ProjectSearchParamsContextProvider';

import '../AllSettingsTab/allSettingsTab.css';
import {
  ApplicantsTabWrapper,
  RecommendedTabWrapper,
} from '../ProjectProfilesTab/RecommendedOrApplicantsTabWrapper';

interface MoreContactsTabParams {
  clientId: string;
  revealProject: any;
  mode: 'general' | 'job' | undefined;
  jobId: string;
  stats: any;
  activeTab: string;
}

const MoreContactsTab: React.FC<MoreContactsTabParams> = ({
  clientId,
  revealProject,
  mode,
  jobId,
  stats,
  activeTab,
}) => {
  const { t } = useTranslation();
  const {
    savedSearchParams,
    loadingSavedSearchParams,
    setSavedSearchParams,
  } = useSavedSearchParams({ mode, jobId });
  const [searchParams, setSearchParams] = useState(savedSearchParams || {});
  const [searchText, setSearchText] = useState('');
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const hasLoadedSavedSearchParams = useRef(false);
  const [resultCount, setResultCount] = useState(0);
  const configurationParams = useMergedConfigurationParams();
  const handleUpdateSearchParams = useCallback(
    ({ newSearchParams }: { newSearchParams: { free?: string } }) => {
      setSearchParams(newSearchParams);
      setSavedSearchParams(newSearchParams);
    },
    [setSavedSearchParams],
  );

  const hasFilters = useMemo(() => !_.isEmpty(searchParams?.criteria), [
    searchParams,
  ]);

  useEffect(() => {
    if (!loadingSavedSearchParams && !hasLoadedSavedSearchParams.current) {
      hasLoadedSavedSearchParams.current = true;
      setSearchParams(savedSearchParams);
    }

    // eslint-disable-next-line
  }, [savedSearchParams]);

  // NOTE: normalize searchParams with removeEmptyKeyValues
  // (an empty key can cause duplicate Query for the same result,
  // because the variables wouldn't exactly match in another component)
  const searches = [{ free: cleanSearchParams(searchParams) }];

  const overwriteCriteria = useCallback(
    ({ free }) =>
      handleUpdateSearchParams({
        newSearchParams: free ? JSON.parse(free) : {},
      }),
    [handleUpdateSearchParams],
  );

  const missionCategoryType = useContext(MissionCategoryContext);

  if (loadingSavedSearchParams) {
    return null;
  }

  return (
    <ProjectSearchParamsContextProvider>
      <div className='more-contacts-view'>
        <div className='menu'>
          {activeTab !== TabId.APPLICANTS ? (
            <div className='ui list'>
              {missionCategoryType === 'recruiting' && (
                <div className='item'>
                  <Button
                    className={`default settingLink${
                      activeTab === TabId.RECOMMENDATIONS ? ' active' : ''
                    }`}
                    as={Link}
                    to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${jobId}/recommendations`}
                  >
                    <CalibrationAndRecommendationIcon className='menu-icon' />
                    {t('reveal.missions.mission.recommendation', {
                      count: stats?.recommendations,
                    })}
                    {stats?.recommendations > 0 && (
                      <span className='ui label'>{stats.recommendations}</span>
                    )}
                  </Button>
                </div>
              )}
              <div className='item'>
                <Button
                  className={`default settingLink${
                    activeTab === TabId.SEARCH ? ' active' : ''
                  }`}
                  as={Link}
                  to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${jobId}/search`}
                >
                  <ContactSearchIcon className='menu-icon' />
                  {t('reveal.missions.mission.contacts')}
                  {resultCount > 0 && (
                    <span className='ui label'>{resultCount}</span>
                  )}
                </Button>
              </div>
            </div>
          ) : (
            <div className='ui list'>
              <div className='item'>
                <Button
                  className='default settingLink active'
                  as={Link}
                  to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${jobId}/applicants`}
                >
                  <CalibrationAndRecommendationIcon className='menu-icon' />
                  {t('reveal.missions.mission.applicants', {
                    count: stats?.applications,
                  })}
                  {stats?.applications > 0 && (
                    <span className='ui label'>{stats.applications}</span>
                  )}
                </Button>
              </div>
            </div>
          )}
          {activeTab === TabId.SEARCH && (
            <>
              <Input
                value={searchText}
                fluid
                onChange={(e, { value }) => {
                  setSearchText(value);
                }}
                className='contact-filter-by-text'
                icon='search'
                placeholder={t('header.search.searchProfiles')}
              />
              <SavedSearchSelector
                projectIds={[jobId]}
                currentCriteria={searches?.[0]}
                overwriteCriteria={overwriteCriteria}
              />
              <RevealSearchPane
                clientId={clientId}
                projectId={revealProject?.id}
                searchParams={searchParams}
                onUpdateSearchParams={handleUpdateSearchParams}
                searchModalOpen={searchModalOpen}
                setSearchModalOpen={setSearchModalOpen}
              />
            </>
          )}
          {activeTab === TabId.RECOMMENDATIONS && (
            <RevealRecommendationPane
              searchPoolId='reveal'
              clientId={clientId}
              jobId={jobId}
            />
          )}
          {activeTab === TabId.APPLICANTS && (
            <RevealApplicantsPane clientId={clientId} jobId={jobId} />
          )}
        </div>
        <div className='contentTab'>
          {activeTab === TabId.RECOMMENDATIONS && (
            <RecommendedTabWrapper
              jobId={jobId}
              clientId={clientId}
              searchPoolId='reveal'
            />
          )}
          {activeTab === TabId.APPLICANTS && (
            <ApplicantsTabWrapper
              jobId={jobId}
              clientId={clientId}
              searchPoolId='reveal'
            />
          )}
          {activeTab === TabId.SEARCH && (
            <CandidatesListPane
              clientId={clientId}
              projectId={revealProject?.id}
              jobId={jobId}
              searchPoolId='reveal'
              searches={searches}
              searchText={searchText}
              setResultCount={setResultCount}
              hasFilters={hasFilters}
              showActiveSequence
              shouldHideBulkActions={
                configurationParams?.shouldHideProjectProfilesBulkActions ===
                'true'
              }
            />
          )}
        </div>
      </div>
    </ProjectSearchParamsContextProvider>
  );
};

export default MoreContactsTab;
