import React from 'react';
import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import RevealDisplayPreferences from './RevealDisplayPreferences/RevealDisplayPreferences';
import MarketplaceDisplayPreferences from './MarketplaceDisplayPreferences';

const DisplayPreferences: React.FC = () => {
  const { applicationMode } = useNavApplicationMode();

  return applicationMode === 'reveal' ? (
    <RevealDisplayPreferences />
  ) : (
    <MarketplaceDisplayPreferences />
  );
};

export default DisplayPreferences;
