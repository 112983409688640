import React, { FC, useCallback, useMemo, useState } from 'react';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import { TimeSpan } from '@/routes/RevealView/RevealAnalytics/components/RevealAnalyticsHeader/RevealAnalyticsHeader';
import SequenceArray from '@/routes/RevealView/RevealAnalytics/tabs/SequencesTab/SequenceArray';
import FunnelKPI from '@/routes/RevealView/RevealAnalytics/components/FunnelKPI/FunnelKPI';
import EllipsisMenu from '@/components/EllipsisMenu';
import useClientDailyCohorts from '@/graphql/hooks/clients/useClientDailyCohorts';
import useMiniSequences from '@/graphql/hooks/sequences/useMiniSequences';
import InfoTooltip from '@/components/Common/InfoTooltip';
import useClientDailyCohortsDetails from '@/graphql/hooks/clients/useClientDailyCohortsDetails';
import GenericModal from '@/components/Common/GenericModal';
import SequencesDailyCohortsStatistics from './SequencesDailyCohortsStatistics';
import StatsProfileAndSequenceTable from '../../components/StatsProfileAndSequenceTable';
import { StatsFilters } from './helpers/filters';
import {
  getModalSubtitle,
  getModalTitle,
  getSequenceDetailsFromDailyCohorts,
} from './helpers/details';
import { getKpisToDisplay, getSequencesStats } from './helpers/kpis';

import './SequencesTab.css';
import TrackedLinks from './TrackedLinks';
import DetailsModalHeader from '../../components/DetailsModalHeader/DetailsModalHeader';

interface SequencesTabProps {
  clientId: string;
  timeSpan: TimeSpan;
  selectedSequenceIds: string[];
}

const SequencesTab: FC<SequencesTabProps> = ({
  clientId,
  timeSpan,
  selectedSequenceIds,
}) => {
  const { t, i18n } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [filters, setFilters] = useState<StatsFilters | undefined>(undefined);

  const { dailyCohorts, loading: dailyCohortsLoading } = useClientDailyCohorts({
    clientId,
    sequenceIds: selectedSequenceIds,
    queryOptions: { fetchPolicy: 'cache-first' },
  });

  const { miniSequences, loading: miniSequencesLoading } = useMiniSequences({
    clientId,
    filters: { activeOnly: true, sequenceIds: selectedSequenceIds },
  });

  const foundSequenceIdsMap = useMemo(() => {
    const result = {} as Record<string, boolean>;
    _.each(miniSequences || [], ({ id }) => {
      result[id] = true;
    });
    return result;
  }, [miniSequences]);

  const filteredDailyCohorts = useMemo(
    () =>
      _.map(dailyCohorts || [], ({ day, details }) => ({
        day,
        details: _.filter(
          details,
          ({ sequenceId }) => foundSequenceIdsMap[sequenceId],
        ),
      })),
    [foundSequenceIdsMap, dailyCohorts],
  );

  const [
    fetchDetails,
    { dailyCohorts: dailyCohortDetails, loading: detailsLoading },
  ] = useClientDailyCohortsDetails();

  const filteredDailyCohortDetails = useMemo(
    () =>
      _.map(dailyCohortDetails || [], ({ day, details }) => ({
        day,
        details: _.filter(
          details,
          ({ sequenceId }) => foundSequenceIdsMap[sequenceId],
        ),
      })),
    [foundSequenceIdsMap, dailyCohortDetails],
  );

  const applyFilters = useCallback(
    (newFilters: StatsFilters) => {
      setModalOpen(true);
      setFilters(newFilters);
      fetchDetails({
        clientId,
        sequenceIds: newFilters.sequenceId
          ? [newFilters.sequenceId]
          : selectedSequenceIds,
      });
    },
    [setModalOpen, setFilters, fetchDetails, clientId, selectedSequenceIds],
  );

  const sequenceDetails = useMemo(
    () =>
      getSequenceDetailsFromDailyCohorts(
        timeSpan,
        filteredDailyCohortDetails,
        miniSequences,
        filters,
      ),
    [timeSpan, filteredDailyCohortDetails, miniSequences, filters],
  );

  const sequencesStats = useMemo(
    () => getSequencesStats(timeSpan, filteredDailyCohorts, miniSequences),
    [filteredDailyCohorts, miniSequences, timeSpan],
  );

  const kpisToDisplay = useMemo(
    () =>
      getKpisToDisplay(t, sequencesStats, (value) =>
        applyFilters({ taskType: { exclusive: false, value } }),
      ),
    [t, sequencesStats, applyFilters],
  );

  const modalTitle = useMemo(() => getModalTitle(t, filters), [t, filters]);
  const modalSubtitle = useMemo(
    () => getModalSubtitle(t, i18n.resolvedLanguage, filters, miniSequences),
    [t, i18n, filters, miniSequences],
  );

  const handleExportData = useCallback(() => {
    const data = kpisToDisplay.map((kpiItem) => ({
      title: kpiItem.text,
      value: kpiItem.value,
    }));
    const kpisInSequencesStats = _.map(sequencesStats, (sequence) => [
      `"${sequence.title}"`,
      sequence.statistics.nbEnrolled,
      sequence.statistics.nbContacted,
      sequence.statistics.nbOpened,
      sequence.statistics.nbClicked,
      sequence.statistics.nbAnswered,
      sequence.statistics.nbPositiveAnswered,
    ]);
    const csvData = [
      [
        t('reveal.missions.mission.tableHeader.sequence'),
        ...data.map((item) => item.title),
      ],
      ['Total', ...data.map((item) => item.value)],
      [],
      ...kpisInSequencesStats,
    ];
    const csvContent = `${encodeURI('data:text/csv;charset=utf-8,')}${encodeURIComponent(
      csvData.map((row) => row.join(',')).join('\r\n'),
    )}`;
    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute(
      'download',
      `pipeline_statistics_by_sequence_${timeSpan.startDate.format(
        'YYYY-MM-DD',
      )}_${timeSpan.endDate.format('YYYY-MM-DD')}.csv`,
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [kpisToDisplay, sequencesStats, t, timeSpan]);

  return (
    <div className='sequences-tab'>
      <div className='sequences-cohorts-graph'>
        <div className='sequences-graph-header'>
          <h3 className='sequences-graph-title'>
            {t('reveal.reports.sequences.kpisTitle')}
            <InfoTooltip rich hoverable position='right center'>
              <h1>{t('reveal.reports.tooltips.sequences.kpis.title')}</h1>
              <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
              <p>
                {t('reveal.reports.tooltips.sequences.kpis.timeSpanParagraph')}
              </p>
              <p>
                {t(
                  'reveal.reports.tooltips.sequences.kpis.categoriesParagraph',
                )}
              </p>
            </InfoTooltip>
          </h3>
          <EllipsisMenu
            direction='left'
            items={[
              {
                key: 'export',
                title: t('reveal.missions.pipeline.exportAsCSV'),
                onClick: () => handleExportData(),
              },
            ]}
          />
        </div>
        {dailyCohortsLoading || miniSequencesLoading ? (
          <Loader className='loader' active inline='centered' size='large' />
        ) : (
          <>
            <FunnelKPI kpiItems={kpisToDisplay} />
            <SequenceArray
              sequencesStats={sequencesStats}
              fetchDetails={(sequenceId, taskType, prefix) =>
                applyFilters({
                  sequenceId,
                  taskType: { exclusive: false, value: taskType },
                  prefix,
                })
              }
            />
          </>
        )}
      </div>
      <SequencesDailyCohortsStatistics
        timeSpan={timeSpan}
        dailyCohorts={filteredDailyCohorts}
        cohortsLoading={dailyCohortsLoading}
        fetchDetails={(startDay, endDay, taskType) =>
          applyFilters({
            startDay,
            endDay,
            taskType: { exclusive: true, value: taskType },
          })
        }
      />
      <TrackedLinks timeSpan={timeSpan} sequenceIds={selectedSequenceIds} />
      {detailsLoading ? (
        <Dimmer active>
          <Loader size='large' />
        </Dimmer>
      ) : (
        <GenericModal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Content>
            <DetailsModalHeader>
              {modalTitle && <h1>{modalTitle}</h1>}
              {modalSubtitle && <h2>{modalSubtitle}</h2>}
            </DetailsModalHeader>
            <StatsProfileAndSequenceTable sequenceDetails={sequenceDetails} />
          </Modal.Content>
        </GenericModal>
      )}
    </div>
  );
};

export default SequencesTab;
