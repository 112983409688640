import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Attachment from '@/graphql/fragments/Attachment';
import downloadPDF from '@/common/downloadPDF';
import { DownloadIcon } from '@/assets/icons';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useClientId from '@/hooks/router/useClientId';
import useClient from '@/graphql/hooks/clients/useClient';

const SEARCH_POOL_PROFILE_RESUME = gql`
  query getSearchPoolProfileResumePdf($searchPoolId: ID!, $profileId: ID!) {
    searchPool(id: $searchPoolId) {
      id
      enrichedProfile(id: $profileId) {
        id
        resumePdf {
          ...Attachment
          file {
            content
          }
        }
      }
    }
  }
  ${Attachment}
`;

const PROFILE_RESUME = gql`
  query getProfileResumePdf($profileId: ID!) {
    profile(id: $profileId) {
      id
      resumePdf {
        ...Attachment
        file {
          content
        }
      }
    }
  }
  ${Attachment}
`;

const useProfileResume = (profileId: string, searchPoolId?: string) => {
  const { refetch: refetchSeachPoolProfileResume } = useQuery(
    SEARCH_POOL_PROFILE_RESUME,
    {
      variables: { searchPoolId, profileId },
      skip: true,
      fetchPolicy: 'network-only',
    },
  );
  const { refetch: refetchProfileResume } = useQuery(PROFILE_RESUME, {
    variables: { profileId },
    skip: true,
    fetchPolicy: 'network-only',
  });
  if (searchPoolId) {
    return {
      fetchResumePdf: async () => {
        const { data } = await refetchSeachPoolProfileResume();
        const resumePdf = data?.searchPool?.enrichedProfile?.resumePdf;
        if (!resumePdf) {
          throw Error('No resumePdf fetched');
        }
        return resumePdf;
      },
    };
  }
  return {
    fetchResumePdf: async () => {
      const { data } = await refetchProfileResume();
      const resumePdf = data?.profile?.resumePdf;
      if (!resumePdf) {
        throw Error('No resumePdf fetched');
      }
      return resumePdf;
    },
  };
};

interface DownloadResumeButtonProps {
  profileId: string;
  searchPoolId: string;
}

const DownloadResumeButton: React.FC<DownloadResumeButtonProps> = ({
  profileId,
  searchPoolId,
}) => {
  const notification = useNotificationSystem();
  const { t } = useTranslation('translations');
  const { fetchResumePdf } = useProfileResume(profileId, searchPoolId);
  const clientId = useClientId();
  const { data } = useClient(clientId);

  const { hasLimitedAccessToProfiles } = data?.client || {};

  if (hasLimitedAccessToProfiles) {
    return null;
  }

  const onError = (e?: Error) => {
    notification.onClearNotifications();
    notification.error(t('profile.resume.attachments.loadingError'));
    return { data: null, loading: false, error: e };
  };

  const onCompleted = (resumePdf: any) => {
    if (resumePdf?.file?.content) {
      downloadPDF({
        filename: resumePdf?.name,
        objectURL: resumePdf?.file?.content,
      });
    }
  };

  const downloadResume = async () => {
    notification.onShowNotification({
      message: t('profile.resume.attachments.loading'),
      level: 'success',
      autoDismiss: 0,
    });
    try {
      const resumePdf = await fetchResumePdf();
      notification.onClearNotifications();
      onCompleted(resumePdf);
    } catch (e) {
      onError(e instanceof Error ? e : undefined);
    }
  };

  return (
    <span
      className='download-icon-container'
      onClick={downloadResume}
      role='button'
      tabIndex={0}
      onKeyDown={(event) => event.key === 'Enter' && downloadResume()}
    >
      <DownloadIcon className='download-icon' />
    </span>
  );
};

export default DownloadResumeButton;
