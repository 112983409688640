import React, { useCallback } from 'react';
import _, { compose } from 'underscore';
import { Trans, withTranslation } from 'react-i18next';
import { Loader, Button, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import withProfileResume from '../../../hocs/profiles/withProfileResume';
import withNextProfileId from '../../../hocs/profiles/withNextProfileId';
import withOfferParams from '../../../hocs/offers/withOfferParams';
import withClient from '../../../hocs/clients/withClient';

import { ChromeStoreLink } from '../../../components/Links';
import PrivacyBox from './PrivacyBox';
import RelevantActivities from './RelevantActivities';
import Card from './Card';
import Background from './Background';
import SmartNoProfileLeftPane from './SmartNoProfileLeftPane';
import ProfileRemovalModal from '../modals/removeModal';
import MoveModal from '../modals/moveModal';

import usePluginStatuses from '../../../hooks/common/usePluginStatuses';
import { useSearchPoolAllCandidatesCount } from '../../../graphql/hooks/searchPool/useSearchPoolCounts';

import './Resume.css';
import SubStepSwitcher from './SubStepSwitcher';

const ChromeExtentionDownloadSection = ({ clientId, t }) => {
  const { pluginSynchronized } = usePluginStatuses(clientId);
  if (!pluginSynchronized) {
    return (
      <div>
        <br />
        <Grid
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          <Grid.Row
            columns={2}
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            <Grid.Column
              textAlign='right'
              verticalAlign='middle'
              style={{ padding: 0 }}
            >
              {t('profile.noProfiles.chromeExtensionPrefix')}
            </Grid.Column>
            <Grid.Column
              verticalAlign='middle'
              style={{
                padding: 0,
                paddingLeft: 5,
              }}
            >
              <ChromeStoreLink>
                <Button className='primary-cta'>
                  {t('profile.noProfiles.chromeExtensionDownload')}
                </Button>
              </ChromeStoreLink>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
  return <div />;
};

const Resume = ({
  shouldDisplaySmartNoProfilePane,
  hasTreatedPendingProfiles,
  hasSubmittedSelectionFeedback,
  onSubmitSelectionFeedback,
  profile,
  profileResumeLoading,
  loading,
  error,
  offer,
  profileId,
  offerId,
  getNextProfileId,
  getProfilesInMove,
  stepId,
  subStepId,
  searchText,
  isSearchResultProfile,
  onChangeProfile,
  onChangeStep,
  handleMarkProfileAsInMove,
  handleUnmarkProfileAsInMove,
  clientId,
  client,
  nbPrePending,
  profiles,
  offerProfilesLoading,
  findingOneProfile,
  refetchOfferProfiles,
  t,
}) => {
  const { allCandidatesCount } = useSearchPoolAllCandidatesCount();

  const goToNextProfile = useCallback(
    async (fallback) => {
      const nextProfileId = getNextProfileId();
      if (nextProfileId) {
        await onChangeProfile(nextProfileId);
        return;
      }
      if (fallback) {
        await fallback();
      }
    },
    [getNextProfileId, onChangeProfile],
  );

  const refreshStep = useCallback(async () => {
    // same step as before: useful ?
    await onChangeStep({ newStepId: stepId, forceRefresh: true });
  }, [onChangeStep, stepId]);

  if (shouldDisplaySmartNoProfilePane) {
    return (
      <SmartNoProfileLeftPane
        clientId={clientId}
        offer={offer}
        offerId={offerId}
        profiles={profiles}
        hasTreatedPendingProfiles={hasTreatedPendingProfiles}
        hasSubmittedSelectionFeedback={hasSubmittedSelectionFeedback}
        onSubmitSelectionFeedback={onSubmitSelectionFeedback}
        nbPrePending={nbPrePending}
        unlimitedFlowBatchSize={(offer || {}).unlimitedFlowBatchSize}
        onChangeProfile={onChangeProfile}
      />
    );
  }

  const hidePersonnalData =
    profile && profile.resumeData && profile.resumeData.hidePersonnalData;
  const attachments = !hidePersonnalData ? profile?.attachments : [];
  const resumeData = !hidePersonnalData ? profile?.resumeData : {};
  const relevantActivities = !hidePersonnalData
    ? profile?.resumeData?.relevantActivities
    : null;
  const profileCreationTimestamp = profile && profile.creationTimestamp;
  if (error) {
    return <div>{/* <h1>This is an error !!!!!!!!!!! {error}</h1> */}</div>;
  }

  if (
    offerProfilesLoading ||
    findingOneProfile ||
    profileResumeLoading ||
    !client?.permissions
  ) {
    return (
      <div className='no-items-container'>
        <Loader inline='centered' active size='large' />
      </div>
    );
  }

  const { watchCollect } = client?.permissions || {};

  const crmMode = client?.customizations?.mainMode === 'crm';
  if (!profile) {
    if (crmMode && stepId === 'pending') {
      return (
        <div className='import-items-container'>
          <div className='import-items'>
            <h3>{t('profile.noProfiles.importManually')}</h3>
            <ChromeExtentionDownloadSection clientId={clientId} t={t} />
            <br />
            <br />
            <div className='player-wrapper'>
              <iframe
                title='tutorial-video'
                src='https://www.loom.com/embed/13d75ac910c142f685a80689580d96c0'
                frameBorder='0'
                webkitallowfullscreen
                mozallowfullscreen
                allowFullScreen
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    if (watchCollect) {
      return (
        <div className='no-resume-container'>
          <img
            src='/images/icons/custom/illustration-profiles.svg'
            alt='profiles-placeholder'
          />
          <div className='no-items watch'>
            <Trans
              i18nKey={
                _.isEmpty(profiles)
                  ? 'translations:profile.noProfiles.watchFirstDescription'
                  : 'translations:profile.noProfiles.watchDescription'
              }
              values={{ allCandidatesCount }}
            >
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://calendar.google.com/calendar/u/0/r/eventedit?text=HireSweet+Discover&details=Regarder+ma+s%C3%A9lection+HireSweet+de+la+semaine+!+%0Ahttps://app.hiresweet.com/&location=https://app.hiresweet.com/&dates=20210104T081500Z/20210104T083000Z&recur=RRULE:FREQ%3DWEEKLY'
              >
                {' '}
              </a>
            </Trans>
          </div>
          <Button
            primary
            as={Link}
            to={`/client/${clientId}/discover/candidates`}
          >
            {t('profile.noProfiles.allCandidates')}
          </Button>
        </div>
      );
    }
    return (
      <div className='no-resume-container'>
        <img
          src='/images/icons/custom/illustration-profiles.svg'
          alt='profiles-placeholder'
        />
        <div className='no-items'>{t(`profile.noProfiles.${stepId}`)}</div>
      </div>
    );
  }

  return (
    <div className='profile-resume-container' key={profileId}>
      <PrivacyBox profile={profile} />
      <Card
        loading={loading}
        resumeData={resumeData}
        attachments={attachments}
        offer={offer}
        offerId={offerId}
        profileId={profileId}
        profile={profile}
        isSearchResultProfile={isSearchResultProfile}
        getNextProfileId={getNextProfileId}
        getProfilesInMove={getProfilesInMove}
        onChangeProfile={onChangeProfile}
        onChangeStep={onChangeStep}
        handleMarkProfileAsInMove={handleMarkProfileAsInMove}
        handleUnmarkProfileAsInMove={handleUnmarkProfileAsInMove}
        stepId={stepId}
        subStepId={subStepId}
        searchText={searchText}
        clientId={clientId}
        profileCreationTimestamp={profileCreationTimestamp}
        firstname={(profile.resumeData || {}).firstname}
        lastname={(profile.resumeData || {}).lastname}
        source={profile.source}
        hidePersonnalData={hidePersonnalData}
      />
      {relevantActivities ? (
        <RelevantActivities relevantActivities={relevantActivities} />
      ) : null}
      <Background
        clientId={clientId}
        resumeData={resumeData}
        attachments={attachments}
        profileId={profileId}
      />
      {stepId === 'pending' && (
        <MoveModal
          clientId={clientId}
          offerId={offerId}
          stepId={stepId}
          searchText={searchText}
          profileId={profileId}
          goToNextProfile={async () => {
            await goToNextProfile(refreshStep);
          }}
        />
      )}
      <SubStepSwitcher
        profileId={profileId}
        stepId={stepId}
        subStepId={subStepId}
      />
      <ProfileRemovalModal
        profileId={profileId}
        getNextProfileId={getNextProfileId}
        onChangeProfile={onChangeProfile}
        onChangeStep={onChangeStep}
        stepId={stepId}
        offerId={offerId}
        searchText={searchText}
      />
    </div>
  );
};

export default compose(
  withNextProfileId,
  withClient,
  withProfileResume,
  withOfferParams,
  withTranslation('translations'),
)(Resume);
