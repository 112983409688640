import React from 'react';
import CreateMetaTaskForm from '@/revealComponents/FullContactFlowEditor/FullContactFlowActionEditor/MetaTaskForm/CreateMetaTaskForm';
import { ClientCustomAction } from '@/types/customAction';
import { ClientCustomActivity } from '@/graphql/hooks/clients/useClientCustomActivities';

interface CustomTaskFormFormConfigProps {
  taskForm: ClientCustomAction | ClientCustomActivity;
  onUpdate: (
    newValues: Partial<ClientCustomAction | ClientCustomActivity>,
  ) => void;
}

export function CustomTaskFormFormConfig({
  taskForm,
  onUpdate,
}: CustomTaskFormFormConfigProps) {
  return (
    <CreateMetaTaskForm
      metaTaskFormat={taskForm.format}
      onUpdate={(format) => {
        return onUpdate({
          format,
        });
      }}
    />
  );
}
