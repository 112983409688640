import React, { useState } from 'react';

import TriggerEditor from '@/revealComponents/FullContactFlowEditor/FullContactFlowActionEditor/TriggerEditor';
import GenericCheckbox from '@/components/Common/GenericCheckbox';
import { getTranslatedDateOrDuration } from '../../helpers';

const EmailActionTriggerEditor = ({
  action,
  permissionsAndNudges,
  onUpdateAction,
  onDeleteAction,
  deletionIsAllowed,
  t,
}) => {
  const [triggerEditionMode, setTriggerEditionMode] = useState(false);
  const [triggerState, setTriggerState] = useState(action?.trigger);

  const { trigger, plannedExecutionDate, type } = action;
  const translatedDateOrDuration = getTranslatedDateOrDuration({
    plannedExecutionDate,
    trigger,
    type,
    t,
  });

  // TODO: uncomment for reveal ?
  // if (trigger?.type === 'manual-trigger') {
  //   return null;
  // }

  return (
    <div className='trigger-editor'>
      <div className='trigger-editor-toggle'>
        <div className='trigger-editor-header'>
          <GenericCheckbox
            checked
            onClick={deletionIsAllowed ? onDeleteAction : undefined}
          />
          <i className='ri-ghost-smile-line' />
          {translatedDateOrDuration}
        </div>
        {triggerEditionMode ? (
          <>
            <i
              className='icon-button ri-close-line'
              onClick={() => {
                setTriggerState(action.trigger);
                setTriggerEditionMode(false);
              }}
            />
            <i
              className='icon-button ri-check-line'
              onClick={() => {
                onUpdateAction({
                  updatedAction: { ...action, trigger: triggerState },
                });
                setTriggerEditionMode(false);
              }}
            />
          </>
        ) : (
          <i
            className='icon-button ri-edit-line'
            onClick={() => setTriggerEditionMode(true)}
          />
        )}
      </div>
      {triggerEditionMode && (
        <div className='trigger-editor-container'>
          <TriggerEditor
            action={{ ...action, trigger: triggerState }}
            onUpdate={({ action: updatedAction }) =>
              setTriggerState(updatedAction.trigger)
            }
            permissionsAndNudges={permissionsAndNudges}
          />
        </div>
      )}
    </div>
  );
};

export default EmailActionTriggerEditor;
