/* eslint-disable max-len */

import React from 'react';

import styles from './svg.module.less';

const Search: React.FC = () => (
  <svg
    className={styles.svgIcon}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.0206 11.0779L14.876 13.9326L13.9326 14.8759L11.078 12.0206C10.0158 12.8721 8.69465 13.3352 7.33331 13.3333C4.02131 13.3333 1.33331 10.6453 1.33331 7.33325C1.33331 4.02125 4.02131 1.33325 7.33331 1.33325C10.6453 1.33325 13.3333 4.02125 13.3333 7.33325C13.3353 8.69459 12.8721 10.0157 12.0206 11.0779ZM10.6833 10.5833C11.5294 9.71318 12.0019 8.54687 12 7.33325C12 4.75459 9.91131 2.66659 7.33331 2.66659C4.75465 2.66659 2.66665 4.75459 2.66665 7.33325C2.66665 9.91125 4.75465 11.9999 7.33331 11.9999C8.54693 12.0018 9.71324 11.5293 10.5833 10.6833L10.6833 10.5833V10.5833Z'
      fill='currentColor'
    />
  </svg>
);

export default Search;
