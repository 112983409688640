import _, { compose } from 'underscore';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Button } from 'semantic-ui-react';

import withProfileDrafts from '../../../../../hocs/profiles/withProfileDrafts';
import withSaveCommentDraft from '../../../../../hocs/profiles/withSaveCommentDraft';
import withAddProfileComment from '../../../../../hocs/profiles/withAddProfileComment';
import EmailEditor from '../../../../Editor';

import '../../drafts.css';

const createStateFromProfile = (profile) => {
  const contactData = profile ? profile.contactData : undefined;
  const comment =
    contactData && contactData.draftComment ? contactData.draftComment : '';
  return {
    initialComment: comment,
    comment,
  };
};

class Comment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = createStateFromProfile(props.profile);
    this.currentProfileId = props.profileId;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.profileId !== this.currentProfileId) {
      this.currentProfileId = nextProps.profileId;
      const update = createStateFromProfile(nextProps.profile);
      this.setState(update);
    }
  }

  handleChange(comment) {
    this.setState({ comment }, () => this.saveDraftComment());
  }

  handleSubmit = () => {
    const { profileId } = this.props;
    this.props.addProfileComment({
      id: profileId,
      comment: this.state.comment,
    });
    this.setState({ initialComment: '', comment: '', version: Date.now() });
  };

  saveDraftComment = _.throttle(() => {
    const content = this.state.comment;
    this.props.saveCommentDraft({
      profileId: this.props.profileId,
      content,
    });
  }, 300);

  render() {
    const { profileId, clientId, t } = this.props;
    const { initialComment, comment, version } = this.state;
    const commentNotEmpty =
      comment &&
      comment.trim() !== '' &&
      comment !== '<br/>' &&
      comment !== '<br />';
    return (
      <div className='comment-content'>
        <Form>
          <EmailEditor
            clientId={clientId}
            profileId={profileId}
            defaultValue={initialComment}
            version={version}
            onChange={this.handleChange}
            mode='comment'
            placeholder={t('editor.placeholder.commentBody')}
            focusId={this.props.focusId}
            alwaysShowMenu
          />
          <Button
            className='primary-cta save-comment-button'
            type='submit'
            onMouseDown={commentNotEmpty ? this.handleSubmit : null}
            disabled={!commentNotEmpty}
          >
            {t('profile.contact.drafts.save')}
          </Button>
        </Form>
      </div>
    );
  }
}

export default compose(
  withProfileDrafts,
  withSaveCommentDraft,
  withAddProfileComment,
  withTranslation('translations'),
)(Comment);
