import React, { useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import GenericButton from '@/components/Common/GenericButton';
import EmptyState from '@/revealComponents/EmptyState';
import useClientSnippets from '@/graphql/hooks/snippets/useClientSnippets';
import { SNIPPET_TYPES } from '@/common/mergeTags/utils';
import MergeTagsVariablesOptions from './MergeTagsVariableOptions';
import { MergeTagsSelectorOption } from '../utils';
import styles from '../MergeTagsSelector.module.less';

interface Props {
  clientId: string;
  onSelectVariable: (variable: MergeTagsSelectorOption) => void;
  hasPermission: boolean;
  withClientFragmentSnippets: boolean;
  withConditionsChainingVariable: boolean;
}

const MergeTagsCustomVariablesOptions: React.FC<Props> = ({
  clientId,
  onSelectVariable,
  hasPermission,
  withClientFragmentSnippets = true,
  withConditionsChainingVariable = true,
}) => {
  const { t } = useTranslation();
  const { data: clientSnippets } = useClientSnippets();

  const clientSnippetsOptions: MergeTagsSelectorOption[] = useMemo(() => {
    return _.compact(
      _.map(clientSnippets?.snippets || [], (snippet) => {
        if (
          snippet.type === SNIPPET_TYPES.FRAGMENT &&
          !withClientFragmentSnippets
        ) {
          return null;
        }
        if (
          snippet.type === SNIPPET_TYPES.CONDITIONS_CHAINING &&
          !withConditionsChainingVariable
        ) {
          return null;
        }
        return {
          key: snippet.id,
          label: snippet.name,
          type: snippet.type,
        };
      }),
    );
  }, [
    clientSnippets,
    withClientFragmentSnippets,
    withConditionsChainingVariable,
  ]);

  return (
    <>
      {_.isEmpty(clientSnippetsOptions) ? (
        <EmptyState
          size='small'
          className={styles.emptyState}
          title={t('editor.mergeTagsSelector.customVariablesEmptyState.title')}
          innerContent={t(
            'editor.mergeTagsSelector.customVariablesEmptyState.innerContent',
          )}
          illustrationPath='/images/placeholders/templatesEmptyState.svg'
          cta={
            <a
              href={`/client/${clientId}/snippets`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <GenericButton size='small'>
                {t('editor.mergeTagsSelector.customVariablesEmptyState.cta')}
              </GenericButton>
            </a>
          }
        />
      ) : (
        <MergeTagsVariablesOptions
          options={clientSnippetsOptions}
          onSelectVariable={({ key, label, type }) =>
            onSelectVariable({
              key,
              label,
              type,
              isClientSnippet: true,
            })
          }
          hasPermission={hasPermission}
        />
      )}
    </>
  );
};

export default MergeTagsCustomVariablesOptions;
