import { useEffect, useState } from 'react';

export function useEffectOnce(
  callback: (done: () => void) => void | (() => void),
  deps: unknown[] = [],
) {
  const [ranOnce, setRanOnce] = useState(false);

  useEffect(() => {
    if (ranOnce) {
      return undefined;
    }

    const done = () => setRanOnce(true);
    return callback(done);
    // we don't need callback in deps since it is declared "as-is" in user-land code
    // eslint-disable-next-line
  }, [ranOnce, ...deps]);
}
