import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

import usePowerHours from '@/graphql/hooks/powerHours/usePowerHours';
import { getSoonestPowerHour } from '@/common/helpers/powerHours';
import PowerHoursLegacyBanner from '@/containers/Parameters/Offers/components/PowerHoursLegacyBanner';

import SaveSearchModal from '../SavedSearches/SaveSearchModal';
import SearchFilters, { areFiltersEmpty } from '../SearchFilters';
import WatchCollectFiltersModal from '../WatchCollectFiltersModal';
import ApplySearchDropdown from './ApplySearchDropdown';
import SearchResults from './SearchResults';
import withSearchPoolResults from '../../../hocs/searchPool/withSearchPoolResults';
import SortByDropdown from './SortByDropdown';
import CardModeToggle from '../../WatchCollectViewV2/CardModeToggle';
import CandidatesInJobDropdown from './CandidatesInJobDropdown';

import './WatchCollectContainer.css';

const WatchCollectContainer = ({
  searches,
  selectedCategory,
  clientId,
  user,
  selectedSearchIds,
  search,
  filtersState,
  onClickResetAll,
  onApplySearch,
  excludeHidden,
  shownInterestOnly,
  setSortBy,
  sortBy,
  loadingSearchResultsCategories,
  searchResultsCategories,
  cardMode,
  setCardMode,
  t,
}) => {
  const [hiddenProfileIds, setHiddenProfileIds] = useState([]);
  const [profilesInJobIds, setProfilesInJobIds] = useState([]);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [filtersModalScrollTo, setFiltersModalScrollTo] = useState();

  const history = useHistory();
  const { pathname, search: querySearch } = useLocation();
  const queryObject = qs.parse(querySearch.replace('?', ''));
  const { profileId, shownInterestOnly: shownInterestOnlyParam } = queryObject;
  const selectedProfileId = profileId;

  const { powerHours } = usePowerHours();
  const soonestPowerHour = getSoonestPowerHour(powerHours);

  const onSelectProfileId = (newProfileId) => {
    const newSearch = qs.stringify({
      ...queryObject,
      profileId: newProfileId || undefined,
    });
    history.push(newSearch ? `${pathname}?${newSearch}` : pathname);
  };

  const removeShownInterestOnlyParam = () => {
    if (!shownInterestOnlyParam) {
      return;
    }
    const newSearch = qs.stringify({
      ...queryObject,
      shownInterestOnly: undefined,
    });
    history.push(newSearch ? `${pathname}?${newSearch}` : pathname);
  };

  const currentCategory = _.findWhere(searchResultsCategories, {
    categoryId: selectedCategory,
  });
  const profileIds = _.pluck(currentCategory?.results, 'searchPoolProfileId');
  const filteredIds = _.difference(
    profileIds,
    hiddenProfileIds,
    profilesInJobIds,
  );
  const profileCount = excludeHidden ? filteredIds.length : profileIds.length;
  const newCount = _.findWhere(searchResultsCategories, { categoryId: 'new' })
    ?.count;

  return (
    <div className='watch-collect-container'>
      <div className='profiles-container'>
        <div className='top-menu'>
          <div className='title'>
            <span className='emphasis'>
              <span className='profiles-count'>{profileCount}</span>
              <span className='candidates'>
                {t('watchCollect.results.description.candidate', {
                  count: profileCount,
                })}
              </span>
              {profileCount > 0 && (
                <span>
                  {t('watchCollect.results.description.including')}
                  <span className='profiles-count'>{newCount}</span>
                  {t('watchCollect.results.description.new', {
                    count: newCount,
                  })}
                </span>
              )}
            </span>
          </div>
          <div className='actions-container'>
            {filtersModalOpen && (
              <WatchCollectFiltersModal
                clientId={clientId}
                filtersState={filtersState}
                open={filtersModalOpen}
                scrollToSection={filtersModalScrollTo}
                onOpen={() => setFiltersModalOpen(true)}
                onClose={() => setFiltersModalOpen(false)}
              />
            )}

            <ApplySearchDropdown
              clientId={clientId}
              searchPoolId='watch'
              appliedFilters={filtersState?.values}
              onApplySearch={(...args) => {
                removeShownInterestOnlyParam();
                onApplySearch(...args);
              }}
            />

            <SortByDropdown
              clientId={clientId}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
            <CardModeToggle cardMode={cardMode} setCardMode={setCardMode} />
          </div>
        </div>

        <div className='top-menu'>
          <div className='left'>
            <Button
              className='watch-collect-filters-trigger default-button'
              type='button'
              onClick={() => {
                removeShownInterestOnlyParam();
                setFiltersModalOpen(true);
              }}
            >
              <i className='ri-equalizer-line' />
              <span>{t('watchCollect.filters.trigger')}</span>
            </Button>
          </div>
          <CandidatesInJobDropdown filtersState={filtersState} />
        </div>

        <div className='search-filters-container'>
          <SearchFilters
            filtersState={filtersState}
            onClickLabel={(filterType) => {
              removeShownInterestOnlyParam();
              setFiltersModalOpen(true);
              setFiltersModalScrollTo(filterType);
            }}
            onClickResetAll={onClickResetAll}
          />
          <div className='search-actions'>
            {!areFiltersEmpty({ filters: filtersState?.values }) && (
              <SaveSearchModal
                searchPoolId='watch'
                clientId={clientId}
                user={user}
                search={search}
                selectedSearchIds={selectedSearchIds}
              />
            )}
          </div>
        </div>

        {soonestPowerHour && (
          <PowerHoursLegacyBanner
            powerHour={soonestPowerHour}
            className='watch-collect-power-hours'
          />
        )}

        <SearchResults
          clientId={clientId}
          user={user}
          searchPoolId='watch'
          searches={searches}
          selectedCategory={selectedCategory}
          excludeHidden={excludeHidden}
          excludeProfilesInJob={
            filtersState.values.includeProfilesInJob === 'none'
          }
          shownInterestOnly={shownInterestOnly}
          sortBy={sortBy}
          loadingSearchResultsCategories={loadingSearchResultsCategories}
          searchResultsCategories={searchResultsCategories}
          hiddenProfileIds={hiddenProfileIds}
          setHiddenProfileIds={setHiddenProfileIds}
          profilesInJobIds={profilesInJobIds}
          setProfilesInJobIds={setProfilesInJobIds}
          cardMode={cardMode}
          selectedProfileId={selectedProfileId}
          onSelectProfileId={onSelectProfileId}
        />
      </div>
    </div>
  );
};

export default compose(
  withSearchPoolResults,
  withTranslation('translations'),
)(WatchCollectContainer);
