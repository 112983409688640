import { getTranslatedText } from '@/common';
import GenericToggle from '@/components/Common/GenericToggle';
import GenericTooltip from '@/components/Common/GenericTooltip';
import { MissionInterestedSubStep } from '@/graphql/hooks/clients/useClientMissionInterestedSubSteps';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FakeGenericTextInput from '@/components/Common/FakeGenericTextInput';
import styles from './JobPipelineView.module.less';

interface MissionSubStepSettingProps {
  isChecked: boolean;
  subStep: MissionInterestedSubStep;
  handleStepToggle: ({
    key,
    value,
  }: {
    key: string | number;
    value: boolean;
  }) => void;
  subStepCount: number;
}

const MissionSubStepSetting: React.FC<MissionSubStepSettingProps> = ({
  isChecked,
  subStep,
  handleStepToggle,
  subStepCount,
}) => {
  const { t } = useTranslation();
  if (subStepCount > 0) {
    return (
      <>
        <GenericTooltip
          trigger={
            <div>
              <GenericToggle
                disabled
                isChecked={isChecked}
                label=''
                name={subStep.id}
                onChange={handleStepToggle}
                className={styles.subStepEditionToggle}
              />
            </div>
          }
        >
          <span>{t('superPipelineSettings.notEmpty')}</span>
        </GenericTooltip>
        <FakeGenericTextInput
          value={getTranslatedText(subStep.title)}
          className={styles.subStepEditionInput}
          disabled={!isChecked}
          fluid
        />
      </>
    );
  }

  return (
    <>
      <GenericToggle
        isChecked={isChecked}
        label=''
        name={subStep.id}
        onChange={handleStepToggle}
        className={styles.subStepEditionToggle}
      />
      <FakeGenericTextInput
        value={getTranslatedText(subStep.title)}
        className={styles.subStepEditionInput}
        disabled={!isChecked}
        fluid
      />
    </>
  );
};

export default MissionSubStepSetting;
