import React, { useState } from 'react';
import _ from 'underscore';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/browser';

import { yupResolver } from '@hookform/resolvers/yup';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import withUserFromJWToken from '@/hocs/users/withUserFromJWToken';
import withClientUsers from '@/hocs/clients/withClientUsers';
import RegisteredForm from '@/components/RegisteredForm/RegisteredForm';
import CreateUpdateTaskForm, {
  CreateUpdateTaskFormSchema,
  TASK_TYPE_OPTIONS,
} from '@/routes/RevealView/SearchView/CandidatesListPane/SideBars/CreateUpdateTaskForm';
import {
  getTaskTypeAndSubtype,
  TASK_TYPES,
} from '@/common/constants/taskTypes';
import { CREATE_PROFILE_TASK } from '@/graphql/searchPoolProfiles';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import { useClientRevealConnector } from '@/graphql/hooks/clients/useClientRevealProjects';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import { formatTaskOrActionMessage } from './ProfileContactFlow/helpers';
import styles from './TaskTab.module.less';

function TaskTab({
  clientId,
  user,
  users,
  usersLoading,
  setIsOpen,
}: {
  clientId: string;
  user: any;
  users: unknown[];
  usersLoading: boolean;
  setIsOpen: any;
}) {
  const { t } = useTranslation();
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();
  const notifications = useNotificationSystem();
  const { profileId } = useCandidateViewContext();
  const { params } = useRouteMatch<{ missionId: string }>();
  const { missionId } = params || {};
  const [createProfileTask] = useMutation(CREATE_PROFILE_TASK);
  const [submitting, setSubmitting] = useState(false);
  const [messageState, setMessageState] = useState({ subject: '', body: '' });
  const defaultConnector = useClientRevealConnector(clientId);

  const onSubmit = async (data: any) => {
    setSubmitting(true);
    const {
      dueDate,
      selectedOwnerEmail,
      taskDescription,
      taskType,
      synchronize,
      isTimeSelected,
    } = data;

    const selectedTaskTypeOption = _.findWhere(TASK_TYPE_OPTIONS, {
      value: taskType,
    });
    const { type, subtype } = getTaskTypeAndSubtype({
      taskType: selectedTaskTypeOption?.type,
    });
    const selectedUser = _.findWhere(users, { email: selectedOwnerEmail });
    const owner = _.pick(selectedUser, ['firstname', 'lastname', 'email']);

    const date = dueDate || new Date();
    if (!isTimeSelected) {
      date.setHours(8, 0, 0);
    }

    const message = formatTaskOrActionMessage(messageState, taskType);

    const input = {
      profileId,
      task: {
        title: selectedTaskTypeOption?.text,
        type,
        ...(subtype && { subtype }),
        owner,
        dueDate: date,
        ...(missionId && { missionId }),
        ...(taskDescription && { description: { default: taskDescription } }),
        message,
        synchronizeWithATS: synchronize,
      },
    };

    const variables = {
      searchPoolId: 'reveal',
      input,
      explicitTasksFilter: { withDueLater: true },
    };

    const refetchQueries = missionId ? ['getTasksCount'] : undefined;

    try {
      await createProfileTask({
        variables,
        refetchQueries,
      });
      notifications.success(
        t('reveal.candidatesView.timeline.explicitTasks.createSuccess'),
      );
      publishSubscriptionEvent('onExplicitTasksUpdated', { id: profileId });
    } catch (e) {
      Sentry.captureException(e);
      notifications.error(
        t('reveal.candidatesView.timeline.explicitTasks.createError'),
      );
    }
    setSubmitting(false);

    if (setIsOpen) {
      setIsOpen(false);
    }
  };

  const defaultValues = {
    selectedOwnerEmail: user?.email,
    taskType: TASK_TYPES.MANUAL,
    dueWhen: 'due-now',
    dueDate: new Date(),
    isTimeSelected: false,
    taskDescription: '',
    synchronize:
      defaultConnector?.synchronizationSettings?.synchronizeCompletedTasks,
  };

  return (
    <div className={styles.wrapper}>
      <RegisteredForm
        id='create-tasks-form'
        className='create-manual-tasks-form'
        resolver={yupResolver(CreateUpdateTaskFormSchema)}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
      >
        <CreateUpdateTaskForm
          users={users}
          usersLoading={usersLoading}
          clientId={clientId}
          submitting={submitting}
          submitLabel={t('reveal.missions.mission.actions.createTask')}
          messageState={messageState}
          setMessageState={setMessageState}
          compact
        />
      </RegisteredForm>
    </div>
  );
}

export default _.compose(withUserFromJWToken, withClientUsers)(TaskTab);
