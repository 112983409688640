import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { useQuery } from '@apollo/client';
import { Department } from '../../graphql/fragments/Department';

export const query = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      id
      departments {
        ...Department
      }
    }
  }
  ${Department}
`;

export const useClientDepartments = (clientId) => {
  const { data } = useQuery(query, {
    fetchPolicy: 'network-only',
    variables: {
      id: clientId,
    },
  });
  return data?.client?.departments || [];
};

export const useClientDepartmentsQuery = (clientId) => {
  const { data, loading } = useQuery(query, {
    fetchPolicy: 'network-only',
    variables: {
      id: clientId,
    },
  });
  return { loading, data: data?.client?.departments };
};

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.clientId,
    },
  }),
  props: ({ data: { loading, client, error } }) => ({
    errorClientDepartments: error,
    loadingClientDepartments: loading,
    clientDepartments: client?.departments,
  }),
});
