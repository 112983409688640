import gql from 'graphql-tag';

const ProfileProjectState = gql`
    fragment ProfileProjectState on ProfileProjectState {
        projectId
        state
    }
`;

export default ProfileProjectState;
