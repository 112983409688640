import gql from 'graphql-tag';
import { BaseQueryOptions, useQuery } from '@apollo/client';

import { ClientMissionDailyEvent } from '@/types/statistics/clientStatistics';
import useClientId from '@/hooks/router/useClientId';

const GET_CLIENT_MISSIONS_DAILY_EVENTS = gql`
  query getClientMissionsStatistics($clientId: ID!, $missionIds: [ID!]) {
    client(id: $clientId) {
      id
      statistics {
        missions(missionIds: $missionIds) {
          dailyEvents {
            day
            details {
              missionId
              type
              count
              removalReason
            }
          }
        }
      }
    }
  }
`;

interface GetClientMissionsDailyEventsData {
  client: {
    id: string;
    statistics: {
      missions: {
        dailyEvents: ClientMissionDailyEvent[];
      };
    };
  };
}

interface GetClientMissionsDailyEventsVariables {
  clientId: string;
  missionIds?: string[];
}

interface UseClientMissionsDailyEventsResult {
  dailyEvents?: ClientMissionDailyEvent[];
  loading: boolean;
}

interface UseClientDailyCohortInput {
  missionIds?: string[];
  queryOptions?: BaseQueryOptions<GetClientMissionsDailyEventsVariables>;
}

const useClientMissionsDailyEvents = ({
  missionIds,
  queryOptions = {},
}: UseClientDailyCohortInput): UseClientMissionsDailyEventsResult => {
  const clientId = useClientId();
  const { data, loading } = useQuery<
    GetClientMissionsDailyEventsData,
    GetClientMissionsDailyEventsVariables
  >(GET_CLIENT_MISSIONS_DAILY_EVENTS, {
    ...queryOptions,
    variables: { clientId, missionIds },
  });

  const { dailyEvents } = data?.client?.statistics?.missions || {};
  return { dailyEvents, loading };
};

export default useClientMissionsDailyEvents;
