import React, { useContext, FC } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import useMinimizedView from '@/hooks/ui/useMinimizedView';
import MissionDropdownControlsContext from '../context/MissionDropdownControlsContext';

interface AddToMissionProps {
  missionsInfo: unknown[];
}

const AddToMission: FC<AddToMissionProps> = ({ missionsInfo }) => {
  const { t } = useTranslation();
  const { isMinimized } = useMinimizedView();
  const { toggleMissionDropdown } = useContext(MissionDropdownControlsContext);

  return (
    <div className='new-mission-container'>
      <button
        type='button'
        onClick={toggleMissionDropdown || undefined}
        className='new-mission-icon'
        disabled={toggleMissionDropdown === null}
      >
        {!isMinimized && _.isEmpty(missionsInfo) && (
          <span>{t('profile.missionsAndSequences.addToMission')}</span>
        )}
        <i className='ri-add-circle-line ri-lg' />
      </button>
    </div>
  );
};

export default AddToMission;
