import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const getProfileThreads = gql`
  query getProfileThreads($searchPoolId: ID!, $profileId: ID!) {
    searchPool(id: $searchPoolId) {
      id
      enrichedProfile(id: $profileId) {
        id
        contactData {
          threads {
            type
            id
            lastMessageTimestamp
            messages {
              text
              isFromRecruiter
              to
              from
              timestamp
              id
            }
          }
        }
      }
    }
  }
`;

type Data = {
  searchPool?: {
    id: string;
    enrichedProfile?: {
      id: string;
      contactData?: {
        threads?: {
          id: string;
          type: string;
          messages?: {
            text: string;
            isFromRecruiter: boolean;
            to: string;
            from: string;
            id: string;
            timestamp: number;
          }[];
        }[];
      };
    };
  };
};

type Variables = {
  searchPoolId: string;
  profileId: string;
};

type Input = {
  profileId: string;
  searchPoolId?: string;
  queryOptions?: QueryHookOptions<Data, Variables>;
};

function useProfileThreads({
  profileId,
  searchPoolId = 'reveal',
  queryOptions = {},
}: Input) {
  const query = useQuery<Data, Variables>(getProfileThreads, {
    ...queryOptions,
    variables: { searchPoolId, profileId },
  });
  const threads =
    query.data?.searchPool?.enrichedProfile?.contactData?.threads || [];
  return { ...query, threads };
}

export default useProfileThreads;
