import React, { useEffect, useRef, useState } from 'react';
import _ from 'underscore';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';

// TODO: migrate to module.less
import './NewProfileMissionsManagement.css';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import createActionInputFromAction from '@/common/contactFlow/createActionInputFromAction';
import useUpdateContactFlowAction from '@/graphql/hooks/searchPoolProfile/useUpdateContactFlowAction';
import { LOG_PROFILE_ACTIVITY } from '@/graphql/searchPoolProfile';
import { getProfileMissionId } from '@/components/Reveal/LogActivity/helpers';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import AddProfileToSequenceContextProvider from '@/context/AddProfileToSequenceContext';
import { sanitizeTypename } from '@/common/utils/apollo';
import ProjectsProfileManagement from './components/ProjectsProfileManagement';
import SequenceProfileManagement from './components/SequenceProfileManagement';

interface NewProfileMissionsManagementProps {
  missions: any;
  missionsInfo: any;
  clientId: string;
  messagingClient?: any;
  shiftSidebar?: boolean;
  onChangeStage?: (data: any) => void;
  onSequenceEdited?: () => void;
}

const getNote = ({ subject, body }: { subject: string; body: string }) => {
  if (!subject && !body) {
    return null;
  }
  if (subject && body) {
    return `<div>${subject}</div><br/><div>${body}</div>`;
  }
  if (body) {
    return `<div>${body}</div>`;
  }
  return null;
};

const NewProfileMissionsManagement: React.FC<NewProfileMissionsManagementProps> = ({
  clientId,
  missions,
  missionsInfo,
  messagingClient = null,
  shiftSidebar = false,
  onChangeStage,
  onSequenceEdited,
}) => {
  const { profile, profileId } = useCandidateViewContext();
  const isPlugin = useIsPlugin();
  const { isMinimized } = useMinimizedView();
  const notification = useNotificationSystem();
  const { t } = useTranslation();

  const handleLinkedinRecruiterSubmitting = useRef(false);
  const [updateContactFlowAction] = useUpdateContactFlowAction();
  const [logActivity] = useMutation(LOG_PROFILE_ACTIVITY);

  const [requestData, setRequestData] = useState({
    body: '',
    subject: '',
    type: '',
  });
  const [shouldLogActivity, setShouldLogActivity] = useState(false);
  const [shouldUpdateContactFlow, setShouldUpdateContactFlow] = useState(false);
  const [missionToDisplay, setMissionToDisplay] = useState<any>({});

  const handleLogLinkedinActivity = async (data: {
    subject: string;
    body: string;
  }) => {
    const missionId = getProfileMissionId(profile);
    const note = getNote({ subject: data.subject, body: data.body });
    await logActivity({
      variables: {
        searchPoolId: 'reveal',
        profileId: profile.id,
        ...(missionId && { missionId }),
        date: null,
        payload: {
          subtype:
            requestData.type === 'linkedin-send-request'
              ? 'linkedin-invitation-to-profile'
              : 'linkedin-message-to-profile',
          ...(note && { note }),
          shouldInterruptSequence: false,
        },
      },
    });
    notification.success(
      t('reveal.timeline.activityLog.notifications.createSuccess'),
    );
    setRequestData({ body: '', subject: '', type: '' });
    setShouldLogActivity(false);
  };

  const handleUpdateContactFlowAction = async (data: {
    subject: string;
    body: string;
  }) => {
    const activeSequence = _.findWhere(profile?.contactFlow?.sequences || [], {
      isCurrent: true,
    });
    if (!activeSequence) {
      return;
    }
    const pendingActions = _.filter(
      activeSequence?.actions,
      (action) => !action?.completion?.isCompleted,
    );
    const activeAction = pendingActions[0];
    const updatedAction = {
      ...activeAction,
      message: {
        ...activeAction.message,
        body: data.body || '',
        subject: data.subject || '',
      },
    };
    const contactFlowActionInput = createActionInputFromAction({
      action: _.omit(updatedAction, 'nbManualSnoozes', 'snoozeConfiguration'),
    });
    const input = {
      id: profileId,
      sequenceId: activeSequence.id,
      actionId: updatedAction.actionId,
      actionInput: contactFlowActionInput,
    };
    await updateContactFlowAction({
      variables: { searchPoolId: 'reveal', input: sanitizeTypename(input) },
    });
    const markAsCompletedElement: HTMLElement | null = document.querySelector(
      '#mark-task-as-completed',
    );
    if (markAsCompletedElement) {
      markAsCompletedElement.click();
    }
    notification.success(
      t('reveal.candidatesView.timeline.explicitTasks.editSuccess'),
    );
    setRequestData({ body: '', subject: '', type: '' });
    setShouldUpdateContactFlow(false);
  };

  useEffect(() => {
    const handleEffect = async () => {
      if (shouldLogActivity) {
        await handleLogLinkedinActivity(requestData);
      }

      if (shouldUpdateContactFlow) {
        await handleUpdateContactFlowAction(requestData);
      }
    };

    handleEffect();
    // eslint-disable-next-line
  }, [shouldLogActivity, shouldUpdateContactFlow]);

  useEffect(() => {
    if (handleLinkedinRecruiterSubmitting.current || !requestData.type) {
      return;
    }
    handleLinkedinRecruiterSubmitting.current = true;
    const activeSequence = _.findWhere(profile?.contactFlow?.sequences || [], {
      isCurrent: true,
    });
    if (activeSequence) {
      const pendingActions = _.filter(
        activeSequence?.actions,
        (action) => !action?.completion?.isCompleted,
      );
      const activeAction = pendingActions[0];
      if (
        !activeAction ||
        activeAction.type !== requestData.type ||
        !_.isEmpty(activeAction.plannedExecutionDate)
      ) {
        setShouldLogActivity(true);
      } else {
        setShouldUpdateContactFlow(true);
      }
      handleLinkedinRecruiterSubmitting.current = false;
    }
    // eslint-disable-next-line
  }, [requestData, handleLinkedinRecruiterSubmitting]);

  const messageListener = (request: any) => {
    if (request?.type === 'linkedinRecruiterMessageSend') {
      const { subject, body } = request.data;
      setRequestData({ subject, body, type: 'linkedin-send-message' });
    }
    if (request?.type === 'linkedinInvitationSend') {
      const { subject, body } = request.data;
      setRequestData({ subject, body, type: 'linkedin-send-request' });
    }
  };

  /**
   * Listener for message from Background scripts
   * Move all cases into useEffects hooks, because profile in listener not refresh properly
   */
  useEffect(() => {
    if (!messagingClient || !profile) {
      return undefined;
    }
    messagingClient.addListener(messageListener);
    return () => {
      messagingClient.removeListener(messageListener);
    };
  }, [messagingClient, profile]);

  return (
    <AddProfileToSequenceContextProvider>
      <div
        className={`missions-and-sequences-management-container${
          isPlugin ? ' plugin' : ''
        } ${isMinimized ? 'minimized' : ''}`}
      >
        <ProjectsProfileManagement
          missionsInfo={missionsInfo}
          missions={missions}
          clientId={clientId}
          missionToDisplay={missionToDisplay}
          setMissionToDisplay={setMissionToDisplay}
          shiftSidebar={shiftSidebar}
          onChangeStage={onChangeStage}
        />
        <SequenceProfileManagement
          clientId={clientId}
          missionId={missionToDisplay?.mission?.id}
          shiftSidebar={shiftSidebar}
          onSequenceEdited={onSequenceEdited}
        />
      </div>
    </AddProfileToSequenceContextProvider>
  );
};

export default NewProfileMissionsManagement;
