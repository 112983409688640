import gql from 'graphql-tag';
import ContactFlowSequence from './ContactFlowSequence';

export default gql`
  fragment ContactFlow on ContactFlow {
    id
    versionId
    sequences {
      ...ContactFlowSequence
    }
  }
  ${ContactFlowSequence}
`;
