import React, { FC } from 'react';

type AsteriskProps = {
  className?: string;
};

const Asterisk: FC<AsteriskProps> = ({ className }) => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 104 104'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <rect x='43.334' width='17.3333' height='104' fill='currentColor' />
    <rect
      x='9.10059'
      y='21.3589'
      width='17.3333'
      height='104'
      transform='rotate(-45 9.10059 21.3589)'
      fill='currentColor'
    />
    <rect
      width='17.3333'
      height='104'
      transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 94.8994 21.3589)'
      fill='currentColor'
    />
    <rect
      y='60.6665'
      width='17.3333'
      height='104'
      transform='rotate(-90 0 60.6665)'
      fill='currentColor'
    />
  </svg>
);

export default Asterisk;
