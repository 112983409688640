import React from 'react';
import { Checkbox, Icon, Popup } from 'semantic-ui-react';
import './OfferSuggestion.css';

const OfferSuggestion = ({
  offer,
  handleSelectOffer,
  handleSelectSeekingCandidatesOnly,
  t,
}) => {
  if (!offer || !offer.title || !offer.id) {
    return null;
  }
  const sourcePhotoLink =
    offer.link &&
    offer.link.indexOf('welcometothejungle.com') > -1 &&
    '/images/logos/wttj-square-logo.png';
  return (
    <div
      className={`offer-suggestion${offer.hasBeenCreated ? ' disabled' : ''}`}
    >
      {sourcePhotoLink && (
        <img src={sourcePhotoLink} className='source-icon' alt='' />
      )}
      <div className='card-content'>
        <div className='title'>
          {offer.link ? (
            <a href={offer.link} target='_blank' rel='noreferrer noopener'>
              {offer.title}
            </a>
          ) : (
            offer.title
          )}
        </div>
        <div>
          <Checkbox
            label={t('offersSuggestions.watchOnly')}
            checked={offer.seekingCandidatesOnly}
            onClick={(event, data) =>
              handleSelectSeekingCandidatesOnly({
                offerId: offer.id,
                event,
                data,
              })
            }
          />
          <Popup
            trigger={
              <Icon
                name='question circle outline'
                size='small'
                style={{
                  color: '#4864C9',
                  marginLeft: 3,
                  cursor: 'pointer',
                }}
              />
            }
            size='tiny'
            position='bottom center'
            inverted
            content={<span>{t('offersSuggestions.watchOnlyDescription')}</span>}
          />
        </div>
      </div>
      {offer.hasBeenCreated ? (
        <span className='success'>
          <Icon name='check' />
        </span>
      ) : (
        <button
          type='button'
          className='button'
          onClick={() => handleSelectOffer({ offerId: offer.id })}
        >
          {t('offersSuggestions.submit')}
        </button>
      )}
    </div>
  );
};
export default OfferSuggestion;
