import gql from 'graphql-tag';
import TranslatableText from './TranslatableText';
import TranslatableTextWithEnrichments from './TranslatableTextWithEnrichments';

export default gql`
  fragment Workplace on Workplace {
    id
    data {
      name
      types {
        id
        name {
          ...TranslatableText
        }
      }
      foundedYear
      stack {
        name {
          default
          fr
          en
        }
        icon
        detailsURL
        ...Skill
      }
      mainLocation {
        name {
          ...TranslatableText
        }
      }
      sources {
        sourceId
        url
        name {
          ...TranslatableText
        }
      }
      staffCount {
        min
        max
      }
      industries {
        name {
          ...TranslatableText
        }
      }
      description {
        ...TranslatableTextWithEnrichments
      }
    }
  }
  ${TranslatableText}
  ${TranslatableTextWithEnrichments}
`;
