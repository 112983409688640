import React, { useContext, useState } from 'react';
import _, { compose } from 'underscore';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import UnifiedViewContext from '@/containers/Parameters/Offers/UnifiedViewContext';
import onLogAction from '../../../common/logAction';
import { MiniProfileBubble } from '../../../components/ProfileBubbles';

import '@/components/ActivityTimeline/ActivityTimeline.css';
import './NotificationsTimeline.css';

const DEFAULT_EVENTS_LIMIT = 4;

const NotificationsTimeline = ({
  clientId,
  notifications,
  addProfileAction,
  t,
}) => {
  const [seeAll, setSeeAll] = useState(false);
  const displayedNotifications = seeAll
    ? notifications
    : (notifications || []).slice(0, DEFAULT_EVENTS_LIMIT);
  const showExpandReduce = notifications.length > DEFAULT_EVENTS_LIMIT;

  const logAction = ({ type }) => {
    const info = { clientId, from: 'notifications-dashboard' };
    return onLogAction({ type, info });
  };

  const onDismiss = ({ profileId }) => {
    if (!profileId) {
      console.warn('Missing argument profileId');
    }
    const type = 'dismiss-profile-notifications';
    addProfileAction({ type, clientId, profileId });
    const info = { clientId, profileId, from: 'notifications-dashboard' };
    onLogAction({ type, info });
  };

  return (
    <div className='notifications-timeline activity-timeline'>
      {_.map(displayedNotifications, (notification, index) => (
        <NotificationsTimelineItem
          key={index}
          clientId={clientId}
          notification={notification}
          logAction={logAction}
          onDismiss={onDismiss}
          t={t}
        />
      ))}
      {showExpandReduce && (
        <ExpandReduceItem seeAll={seeAll} setSeeAll={setSeeAll} t={t} />
      )}
    </div>
  );
};

const ExpandReduceItem = ({ seeAll, setSeeAll, t }) => {
  return (
    <div className='activity-timeline-item profiles-activity'>
      <div className='activity-icon-container'>
        <div className='activity-icon'>
          <i className={seeAll ? 'ri-subtract-line' : 'ri-add-line'} />
        </div>
      </div>
      <div className='activity-content'>
        <span
          className='activity-description see-more-toggle body-medium'
          onClick={() => setSeeAll((seeAll) => !seeAll)}
        >
          {seeAll
            ? t('dashboard.activity.seeLess')
            : t('dashboard.activity.seeMore')}
        </span>
      </div>
    </div>
  );
};

const NotificationsTimelineItem = ({
  clientId,
  notification,
  logAction,
  onDismiss,
}) => {
  const { unifiedView } = useContext(UnifiedViewContext);
  const { profile, offer } = notification || {};
  const profileName = `${profile?.resumeData?.firstname || ''} ${profile
    ?.resumeData?.lastname || ''}`.trim();
  return (
    <div className='notifications-timeline-item positive-answer'>
      <div className='notification-icon-container'>
        <MiniProfileBubble
          profile={profile}
          offerId={offer?.id}
          clientId={clientId}
          logAction={logAction}
        />
      </div>
      <div className='notification-content'>
        <span className='notification-description'>
          <Link
            className='profile-name'
            to={
              unifiedView
                ? `/client/${clientId}/discover/followup/${offer.id}`
                : `/client/${clientId}/jobs/${offer?.id}/profiles/${profile?.id}`
            }
            onClick={() => logAction({ type: 'click-profile-link' })}
          >
            {profileName}
          </Link>
        </span>

        {notification?.offer?.id &&
        notification.offer.id.indexOf('-candidates') < 0 ? (
          <OfferLink
            clientId={clientId}
            offer={notification?.offer}
            logAction={logAction}
          />
        ) : (
          ''
        )}

        {notification?.date && parseInt(notification?.date, 10) && (
          <span className='notification-date'>
            {moment(parseInt(notification?.date, 10)).fromNow()}
          </span>
        )}
      </div>
      <div
        className='notification-action'
        onClick={() => onDismiss({ profileId: profile?.id })}
      >
        <i className='ri-close-line' />
      </div>
    </div>
  );
};

const OfferLink = ({ clientId, offer, logAction }) => {
  if (!clientId || !offer?.id || offer?.isUnclassified) {
    return null;
  }
  return (
    <div className='notification-details'>
      <Link
        to={`/client/${clientId}/jobs/${offer.id}`}
        onClick={() => logAction({ type: 'click-offer-link' })}
      >
        {offer?.title}
      </Link>
    </div>
  );
};

export default compose(withTranslation('translations'))(NotificationsTimeline);
