/* eslint-disable import/prefer-default-export */
import _ from 'underscore';

export function getValueFromPath({
  object,
  path,
}: {
  object: any;
  path: string[];
}): string | undefined {
  if (_.isEmpty(path)) {
    return object;
  }
  if (object?.[path[0]] !== undefined) {
    return getValueFromPath({
      object: object[path[0]],
      path: path.slice(1),
    });
  }
  return undefined;
}
