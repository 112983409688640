import React, { FC, useContext, useMemo } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import { AggregatePeriod } from '@/types/statistics/reportStream';

import StatisticsParametersContext from '../../../StatisticsParametersContext';

const availableAggregatePeriods: AggregatePeriod[] = [
  'day',
  'week',
  'month',
  'quarter',
  'year',
];

const AggregatePeriodSelector: FC = () => {
  const { t } = useTranslation();
  const [{ aggregatePeriod }, dispatch] = useContext(
    StatisticsParametersContext,
  );

  const options = useMemo(
    () =>
      _.map(availableAggregatePeriods, (value) => ({
        value,
        text: t(`analytics.frequency.${value}`),
      })),
    [t],
  );

  return (
    <Dropdown
      selection
      search
      className='hiresweet-rounded'
      options={options}
      value={aggregatePeriod}
      onChange={(_e, { value }) =>
        dispatch({
          type: 'setAggregatePeriod',
          value: value as AggregatePeriod,
        })
      }
      selectOnBlur={false}
    />
  );
};

export default AggregatePeriodSelector;
