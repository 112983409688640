import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import GenericCheckbox from '@/components/Common/GenericCheckbox';

import './CompanyFields.css';

export type CompanyState = Record<CompanyKey, string[]>;
type CompanyKey = 'currentlyIn' | 'in' | 'currentlyNotIn' | 'neverIn';

const FILTERS: { value: CompanyKey; text: string }[] = [
  {
    value: 'currentlyIn',
    text: 'reveal.searchView.search.companies.currentlyIn',
  },
  {
    value: 'in',
    text: 'reveal.searchView.search.companies.in',
  },
  {
    value: 'currentlyNotIn',
    text: 'reveal.searchView.search.companies.currentlyNotIn',
  },
  {
    value: 'neverIn',
    text: 'reveal.searchView.search.companies.neverIn',
  },
];

interface CompanyFieldsProps {
  companyStates: CompanyState;
  setCompanyStates: (value: CompanyState) => void;
}

const CompanyFields: FC<CompanyFieldsProps> = ({
  companyStates,
  setCompanyStates,
}) => {
  const { t } = useTranslation();
  const handleFilterUpdate = (key: CompanyKey) => (value: string[]) => {
    setCompanyStates({
      ...companyStates,
      [key]: value,
    });
  };
  return (
    <div className='companies section'>
      {FILTERS.map(({ value, text }) => (
        <CompanyField
          onFilterUpdate={handleFilterUpdate(value)}
          values={companyStates[value]}
          text={t(text)}
          key={value}
        />
      ))}
    </div>
  );
};

interface CompanyFieldProps {
  onFilterUpdate: (value: string[]) => void;
  text: string;
  values: string[];
}

const CompanyField: FC<CompanyFieldProps> = ({
  text,
  values,
  onFilterUpdate,
}) => {
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState(values?.length > 0 ?? []);
  const [newOptions, setNewOptions] = useState<
    { text: string; value: string }[]
  >(
    values?.map((opt) => ({
      text: opt,
      value: opt,
    })) ?? [],
  );

  useEffect(() => {
    if (!values?.length) {
      setIsSelected(false);
    }
    // eslint-disable-next-line
  }, [values?.length]);

  const onChange = (_e: unknown, data: DropdownProps) => {
    onFilterUpdate(data.value as string[]);
  };

  const addItem = (_e: unknown, data: DropdownProps) => {
    const { value: optionText } = data;

    setIsSelected(true);

    if (typeof optionText === 'string') {
      setNewOptions([
        ...newOptions,
        {
          text: optionText,
          value: optionText,
        },
      ]);
    }
  };

  const handleSelect = () => {
    if (isSelected) {
      setIsSelected(false);
      onFilterUpdate([]);
      return;
    }

    setIsSelected(true);
  };

  return (
    <div className='field'>
      <button type='button' className='radio-row' onClick={handleSelect}>
        <GenericCheckbox checked={isSelected} />
        <div className='radio-label'>{text}</div>
      </button>
      {isSelected && (
        <Dropdown
          multiple
          search
          selection
          onChange={onChange}
          value={values}
          options={newOptions}
          allowAdditions
          onAddItem={addItem}
          placeholder={t(
            'reveal.searchView.search.companies.companyPlaceholder',
          )}
        />
      )}
    </div>
  );
};

export default CompanyFields;
