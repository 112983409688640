import gql from 'graphql-tag';
import { MutationTuple, useMutation } from '@apollo/client';
import RevealProject, {
  IRevealProject,
} from '@/graphql/fragments/RevealProject';
import { ISynchronisationSettings } from '@/graphql/fragments/RevealConnector';

type ConnectorSynchronizationSettings = {
  input: {
    type: string;
    id: string;
    settings: ISynchronisationSettings;
  };
};

type MutationPayload = {
  revealProject: {
    updateConnectorSynchronizationSettings: {
      client: ClientResponse;
    };
  };
};

interface ClientResponse {
  id: string;
  revealProjects?: IRevealProject[];
}

const UPDATE_CONNECTOR_SYNCHRONIZATION_SETTINGS = gql`
  mutation updateConnectorSynchronizationSettings(
    $input: UpdateConnectorSynchronizationSettingsInput!
  ) {
    revealProject {
      updateConnectorSynchronizationSettings(input: $input) {
        client {
          id
          revealProjects {
            ...RevealProject
          }
        }
      }
    }
  }
  ${RevealProject}
`;

const useUpdateConnectorSynchronizationSettings = (): MutationTuple<
  MutationPayload,
  ConnectorSynchronizationSettings
> => {
  return useMutation(UPDATE_CONNECTOR_SYNCHRONIZATION_SETTINGS);
};

export default useUpdateConnectorSynchronizationSettings;
