import classNames from 'classnames';
import React from 'react';
import _ from 'underscore';
import { getClassName, getRoundedScore } from '../helpers';

import './MatchingLabels.css';

export const MatchingScoreLabel = ({ className, score }) => {
  if (!_.isNumber(score)) {
    return <span className={classNames('matching-score-label', 'not-available', className)}>--</span>;
  }
  const roundedScore = getRoundedScore({ score });
  return (
    <span className={classNames('matching-score-label', className)}>
      <i className='score-icon ri-focus-2-line' />
      <span className='score'>{`${roundedScore}%`}</span>
    </span>
  );
};

export const MatchingLabel = ({ label, score, neutral }) => {
  return (
    <span className={classNames('matching-label', neutral ? 'neutral' : getClassName(score))}>
      <span>{label}</span>
      {/* {_.isNumber(score) && <span> • {getRoundedScore({ score })}%</span>} */}
    </span>
  );
};
