import _ from 'underscore';
import React, { useMemo, useRef } from 'react';
import styles from './styles.module.less';
import { useMergeTagsPlayground } from '../context/MergeTagsPlaygroundProvider';

const MergeTagsText: React.FC = () => {
  const { text, setText } = useMergeTagsPlayground();
  const ref = useRef<HTMLTextAreaElement | null>(null);

  const handleInputChange = () => {
    if (!ref.current) {
      return;
    }
    setText(ref.current.value);
  };

  // eslint-disable-next-line
  const onInput = useMemo(() => _.debounce(handleInputChange, 700), []);

  return (
    <div className={styles.textWrapper}>
      <div className={styles.text}>
        <textarea ref={ref} onInput={onInput} contentEditable>
          {text}
        </textarea>
      </div>
    </div>
  );
};

export default MergeTagsText;
