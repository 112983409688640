import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

const mutation = gql`
  mutation globalSignOAuthUser($type: String!, $token: String!) {
    globalSignOAuthUser(type: $type, token: $token) {
      id
      email
      clients {
        id
        title
      }
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    globalSignOAuthUser: ({ type, token }) => {
      return mutate({ variables: { type, token }, errorPolicy: 'all' });
    },
  }),
});
