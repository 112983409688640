import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';

import NewCandidateModal from '../../SearchView/CandidatesListPane/ImportCandidates/NewCandidateModal';

const NewCompanyButton: FC = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <GenericButton size='big' onClick={() => setModalOpen(true)}>
        <Plus />
        {t('header.new.company')}
      </GenericButton>
      <NewCandidateModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        contactCategory='company'
      />
    </>
  );
};

export default NewCompanyButton;
