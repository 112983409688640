import React, { useState, useEffect, useMemo, useRef } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { Dropdown } from 'semantic-ui-react';
import MergeTagsContactFlowEditor from '@/containers/Editor/MergeTagsContactFlowEditor';
import { getSweetEvaluator } from '@/SweetEvaluator';
import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import getExternalExpressionEvaluatorFromType from '@/common/mergeTags/externalEvaluators';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import { getUserClientMailAccount } from '@/hocs/email/sendEmailQuery';
import {
  GET_DEFAULT_TEMPLATE_REPLIES,
  UPDATE_DEFAULT_TEMPLATE_REPLY,
} from '@/graphql/defaultReplyTemplate';
import useMergeTagsProfileContext from '@/common/mergeTags/useMergeTagsProfileContext';
import SimpleDropdown from '@/components/SimpleDropdown';
import useRevealEmailAction, { RevealSenderType } from './useRevealEmailAction';
import TriggerEditor from './TriggerEditor';
import SenderAndRecipient from './SenderAndRecipient';
import MailButton from '../../Email/MailButton';
import EditTemplate from '../../../../../../containers/Parameters/Templates/modals/editTemplate';
import { instantiateText } from '../../../../../../common';

const EmailAction = ({
  sequenceId,
  action,
  indexInActionsToCome,
  onDeleteAction,
  onUpdateAction,
  permissionsAndNudges,
  emailApi,
  clientId,
  profile,
  searchPoolId,
  onSend,
  user,
  templates,
  task,
  canShowDefaultTemplateReplies,
}) => {
  const needInitialRefresh = useRef(true);
  const { t } = useTranslation();
  const {
    emitter,
    canChooseEmitter,
    assignedSender,
    setClassicSender,
    setSoboSender,
    senders,
    revealSenderType,
    targetThread,
    sendInTargetThreadPossible,
    sendInTargetThread,
    setSendInTargetThread,
  } = useRevealEmailAction(profile);
  const defaultSubject = profile?.contactData?.defaultSubject; // FIXME
  const targetThreadSubject =
    sendInTargetThread && sendInTargetThreadPossible
      ? targetThread?.subject
      : undefined;
  const targetThreadCc = targetThread?.cc;
  const targetThreadBcc = targetThread?.bcc;
  const actionMessageSubject = action?.message?.subject;
  const initialSubject = getSubject({
    targetThreadSubject,
    actionMessageSubject,
    defaultSubject,
  });
  const [messageState, setMessageState] = useState({
    ...action?.message,
    ...(initialSubject && { subject: initialSubject }),
    snippets: action.snippets || [],
  });

  const isPlugin = useIsPlugin();

  const [cc, setCc] = useState([]);
  const [bcc, setBcc] = useState([]);

  const [version, setVersion] = useState(0);
  const [editTemplateMode, setEditTemplateMode] = useState({
    isActive: false,
    templateToEditId: null,
  });
  const [
    suggestedTemplateForDefaultReply,
    setSuggestedTemplateForDefaultReply,
  ] = useState(null);

  const mergeTagsProfileContext = useMergeTagsProfileContext({
    clientId,
    profile,
  });
  const { profileCustomFields: customFields } = useClientProfileCustomFields(
    clientId,
  );

  const { data: defaultTemplatesData } = useQuery(
    GET_DEFAULT_TEMPLATE_REPLIES,
    {
      variables: {
        clientId,
      },
    },
  );

  const templateWithRecruiterConfigurations = _.filter(
    defaultTemplatesData?.client?.templates,
    (template) => {
      const userConfiguration =
        user?.email &&
        _.findWhere(template.recruiterConfigurations, {
          recruiterEmail: user?.email,
        });
      return userConfiguration?.isDefaultReply;
    },
  );

  const [addToDefaultTemplateReplies] = useMutation(
    UPDATE_DEFAULT_TEMPLATE_REPLY,
  );

  const { data: pluginUserData } = useQuery(getUserClientMailAccount, {});

  const preInstantiateMessageState = (sequenceAction) => {
    const updatedAction = MergeTagsService.preInstantiateContactFlowAction({
      evaluatorContext: mergeTagsProfileContext,
      action: sequenceAction,
    });
    setMessageState((prev) => ({
      ...prev,
      snippets: updatedAction.snippets,
    }));
    setVersion((previousVersion) => previousVersion + 1);
  };

  const instantiatedMessageState = useMemo(() => {
    return MergeTagsService.instantiateMessage({
      evaluatorContext: mergeTagsProfileContext,
      snippets: messageState.snippets,
      subject: messageState.subject,
      body: messageState.body,
      t,
    });
    // eslint-disable-next-line
  }, [messageState, customFields, profile]);

  useEffect(() => {
    if (!action) {
      return;
    }
    if (needInitialRefresh.current) {
      preInstantiateMessageState(action);
      needInitialRefresh.current = false;
    }
    // eslint-disable-next-line
  }, [action]);

  const shouldUseTargetThread =
    sendInTargetThread && sendInTargetThreadPossible && targetThreadSubject;
  useEffect(() => {
    if (shouldUseTargetThread) {
      if (targetThreadSubject) {
        setMessageState((prev) => ({
          ..._.omit(prev, 'templateId'),
          subject: getReplySubject(targetThreadSubject),
        }));
      }
      if (targetThreadCc && !_.isEmpty(targetThreadCc)) {
        setCc(targetThreadCc);
      }
      if (targetThreadBcc && !_.isEmpty(targetThreadBcc)) {
        setBcc(targetThreadBcc);
      }
    } else {
      setMessageState((prev) => ({
        ...prev,
        subject: action?.message?.subject || defaultSubject,
      }));
    }
    setVersion(version + 1);
    // eslint-disable-next-line
  }, [
    shouldUseTargetThread,
    targetThreadSubject,
    targetThreadCc,
    targetThreadBcc,
  ]);

  const onChangeBody = _.debounce((updatedBody) => {
    const updatedMessage = {
      ..._.omit(messageState, 'templateId'),
      body: updatedBody,
    };
    setMessageState(updatedMessage);
    if (onUpdateAction) {
      const updatedAction = {
        ...action,
        message: updatedMessage,
      };
      onUpdateAction({ updatedAction });
    }
  }, 400);

  const onChangeSubject = _.debounce((updatedSubject) => {
    const updatedMessage = {
      ..._.omit(messageState, 'templateId'),
      subject: updatedSubject,
    };
    setMessageState(updatedMessage);
    if (onUpdateAction) {
      const updatedAction = {
        ...action,
        message: updatedMessage,
      };
      onUpdateAction({ updatedAction });
    }
  }, 400);

  const onSnippetAdded = _.debounce(({ newSnippet }) => {
    if (!newSnippet) {
      return;
    }
    const sweetEvaluator = getSweetEvaluator({
      getExternalExpressionEvaluatorFromType,
    });
    const instantiatedSnippets = sweetEvaluator.getUpdatedVariables({
      variables: [...(messageState.snippets || []), newSnippet],
      context: mergeTagsProfileContext,
    });
    setMessageState((prevState) => ({
      ...prevState,
      snippets: instantiatedSnippets,
    }));
  }, 400);

  const onSnippetUpdated = _.debounce(({ updatedSnippet }) => {
    const newSnippets = _.map(messageState.snippets || [], (snippet) => {
      if (snippet.id === updatedSnippet.id) {
        return updatedSnippet;
      }
      return snippet;
    });
    setMessageState((prevState) => ({
      ...prevState,
      snippets: newSnippets,
    }));
    const updatedAction = {
      ...action,
      snippets: newSnippets,
    };
    onUpdateAction({ updatedAction });
    setVersion((prevState) => prevState + 1);
  }, 400);

  const onSnippetFragmentInserted = ({ newSnippets, field, newContent }) => {
    const updatedSnippets = [...(messageState.snippets || [])];
    let updatedSubject = messageState.subject;
    let updatedBody = messageState.body;

    if (!_.isEmpty(newSnippets)) {
      updatedSnippets.push(...newSnippets);
    }
    if (field === 'subject') {
      updatedSubject = newContent;
    }
    if (field === 'body') {
      updatedBody = newContent;
    }
    setMessageState((prevState) => ({
      ...prevState,
      snippets: updatedSnippets,
      subject: updatedSubject,
      body: updatedBody,
    }));
    setVersion((prevState) => prevState + 1);
  };

  const onSelectTemplate = ({ templateId, isDefaultReplyTemplate }) => {
    const template = _.findWhere(templates, { id: templateId });
    if (!template) {
      console.error(`Template not found : ${templateId}`);
      return;
    }

    const isTemplateAlreadyDefault = _.findWhere(
      templateWithRecruiterConfigurations,
      { id: templateId },
    );

    const { subject: templateSubject, body: templateBody, snippets } = template;

    const sweetEvaluator = getSweetEvaluator({
      getExternalExpressionEvaluatorFromType,
    });

    const {
      subject: contactFlowSubject,
      body: contactFlowBody,
      snippets: contactFlowSnippets,
    } = MergeTagsService.replaceAllIds({
      subject: templateSubject || '',
      body: templateBody || '',
      subId: 'contactFlow',
      snippets,
      t,
    });
    const instantiatedSnippets = sweetEvaluator.getUpdatedVariables({
      variables: contactFlowSnippets,
      context: mergeTagsProfileContext,
    });

    const updatedMessage = {
      ...messageState,
      body: contactFlowBody,
      subject: contactFlowSubject,
      templateId,
    };

    const subjectFromTemplate =
      MergeTagsService.getSnippetIdOrContextValueFromText({
        context: mergeTagsProfileContext,
        text: contactFlowSubject || '',
        instantiatedSnippets,
      }) || '';
    const updatedMessageState = {
      ...updatedMessage,
      body: MergeTagsService.getSnippetIdOrContextValueFromText({
        context: mergeTagsProfileContext,
        text: contactFlowBody || '',
        instantiatedSnippets,
      }),
      subject:
        shouldUseTargetThread && targetThreadSubject
          ? getReplySubject(targetThreadSubject)
          : subjectFromTemplate,
      snippets: instantiatedSnippets,
    };
    setMessageState(updatedMessageState);
    setVersion(version + 1);
    onUpdateAction({
      updatedAction: {
        ...action,
        message: {
          body: updatedMessageState.body,
          subject: updatedMessageState.subject,
        },
        snippets: instantiatedSnippets,
      },
    });
    if (
      shouldUseTargetThread &&
      !isDefaultReplyTemplate &&
      !isTemplateAlreadyDefault
    ) {
      setSuggestedTemplateForDefaultReply(template);
    } else if (suggestedTemplateForDefaultReply) {
      setSuggestedTemplateForDefaultReply(null);
    }
  };

  const handleAddTemplateToDefault = () => {
    addToDefaultTemplateReplies({
      variables: {
        id: suggestedTemplateForDefaultReply.id,
        isDefaultReply: true,
      },
    });
    setSuggestedTemplateForDefaultReply(null);
  };

  const { trigger } = action;
  const { body, subject, snippets } = messageState;

  const userSignature = user && user.signature ? user.signature : null;
  const senderSignature =
    assignedSender && assignedSender.signature
      ? assignedSender.signature
      : null;
  const signature =
    revealSenderType === RevealSenderType.Classic
      ? userSignature
      : senderSignature;

  const displaySendButton =
    trigger?.type === 'manual-trigger' && indexInActionsToCome === 0;

  const userCanSend =
    (emailApi.currentAddress &&
      emailApi.hasOfflineGrant[emailApi.currentAddress] &&
      !emailApi.connectionInProgress) ||
    assignedSender;

  return (
    <div className='email-content'>
      {displaySendButton && (
        <div className='email-header'>
          <h4 style={{ margin: 0 }}>{t('profile.actionButtons.newMessage')}</h4>
          <MailButton
            clientId={clientId}
            sequenceId={sequenceId}
            actionId={action?.actionId}
            profileId={profile?.id}
            profile={profile}
            searchPoolId={searchPoolId}
            onSend={onSend}
            mailData={{
              subject: instantiatedMessageState?.instantiatedSubject,
              body: instantiatedMessageState?.instantiatedBody,
              signature: signature || '',
              dest: profile?.email,
              bccAddresses: bcc,
              ccAddresses: cc,
              firstname: profile?.resumeData?.firstname,
            }}
            emailApi={emailApi}
            task={task}
            assignedSender={assignedSender}
            setClassicSender={setClassicSender}
            targetThread={targetThread}
            shouldUseThread={sendInTargetThread && sendInTargetThreadPossible}
            allowSchedule={false}
          />
        </div>
      )}
      {canChooseEmitter && !userCanSend && (
        <div>
          <SimpleDropdown text={t('reveal.sobo.assignSender')}>
            <Dropdown.Menu>
              {_.map(senders, (sender) => (
                <Dropdown.Item
                  key={sender.id}
                  text={sender.senderAddress}
                  onClick={() => setSoboSender(sender.id)}
                />
              ))}
            </Dropdown.Menu>
          </SimpleDropdown>
        </div>
      )}
      <div className='email-body'>
        <div className='email-info'>
          {action?.emailIndex === 0 ? (
            <SenderAndRecipient
              clientId={clientId}
              revealSenderType={revealSenderType}
              from={emitter}
              canChooseEmitter={canChooseEmitter}
              senders={senders}
              profile={profile}
              t={t}
              setClassicSender={setClassicSender}
              setSoboSender={setSoboSender}
              bcc={bcc}
              setBcc={setBcc}
              cc={cc}
              setCc={setCc}
              sendInTargetThread={sendInTargetThread}
              setSendInTargetThread={setSendInTargetThread}
              sendInTargetThreadPossible={sendInTargetThreadPossible}
              targetThread={targetThread}
              pluginUserData={pluginUserData}
              templateWithRecruiterConfigurations={
                templateWithRecruiterConfigurations
              }
              onSelectTemplate={({ templateId }) =>
                onSelectTemplate({ templateId, isDefaultReplyTemplate: true })
              }
              canShowDefaultTemplateReplies={canShowDefaultTemplateReplies}
              suggestedTemplateForDefaultReply={
                suggestedTemplateForDefaultReply
              }
              handleAddTemplateToDefault={handleAddTemplateToDefault}
            />
          ) : (
            <TriggerEditor
              action={action}
              onUpdateAction={onUpdateAction}
              onDeleteAction={onDeleteAction}
              permissionsAndNudges={permissionsAndNudges}
              deletionIsAllowed={permissionsAndNudges?.deletion?.isAllowed}
              t={t}
            />
          )}
        </div>

        <div className='email-body-editor'>
          <MergeTagsContactFlowEditor
            version={version}
            clientId={clientId}
            fields={action?.emailIndex === 0 ? 'double' : 'simple'}
            snippets={snippets}
            mergeTagsSubId='contactFlow'
            onChange={onChangeBody}
            onChangeSubject={onChangeSubject}
            onSnippetAdded={onSnippetAdded}
            onSnippetUpdated={onSnippetUpdated}
            onSnippetFragmentInserted={onSnippetFragmentInserted}
            defaultValue={body}
            defaultValueSubject={subject}
            alwaysShowMenu
            profileId={profile.id}
            placeholder={
              action?.emailIndex === 0
                ? t('editor.placeholder.emailBody')
                : t('editor.placeholder.followupBody')
            }
            placeholderSubject={t('editor.placeholder.emailSubject')}
            onSelectTemplate={onSelectTemplate}
            isPlugin={isPlugin}
            signature={signature}
            editTemplate={null} // No template edition
            displayImageInsertion
          />
        </div>
      </div>

      <EditTemplate
        clientId={clientId}
        template={_.findWhere(templates, {
          id: editTemplateMode.templateToEditId,
        })}
        templateId={editTemplateMode.templateToEditId}
        open={editTemplateMode.isActive}
        onClose={() => setEditTemplateMode({ isActive: false })}
        submitCallback={null}
      />
    </div>
  );
};

export const instantiateMessage = ({
  message,
  context,
  templates,
  defaultSubject,
}) => {
  if (!message?.templateId) {
    return message;
  }
  const currentTemplate = _.findWhere(templates, { id: message?.templateId });
  if (!currentTemplate) {
    return message;
  }
  const { subject: templateSubject, body: templateBody } = currentTemplate;
  return {
    ...message,
    body: instantiateText({ context, text: templateBody }),
    subject:
      instantiateText({ context, text: templateSubject }) || defaultSubject,
  };
};

const getSubject = ({
  targetThreadSubject,
  actionMessageSubject,
  defaultSubject,
}) => {
  if (targetThreadSubject) {
    return targetThreadSubject;
  }
  if (actionMessageSubject) {
    return actionMessageSubject;
  }
  return defaultSubject || '';
};

const getReplySubject = (previousSubject) =>
  `Re: ${(previousSubject || '').replace(/^Re: /, '')}`;

export default EmailAction;
