import React, { useState } from 'react';
import _ from 'underscore';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from '@/containers/Parameters/SnippetsSettings/SnippetsSettings.module.less';
import MergeTagsAutomationEditor from '@/containers/Editor/MergeTagsAutomationEditor';
import useClientId from '@/hooks/router/useClientId';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';

type Props = {
  formValues: SweetEvaluatorTypes.BaseVariable &
    (
      | SweetEvaluatorTypes.FragmentVariable
      | SweetEvaluatorTypes.AITokenVariable
    );
  setFormValues: (
    values: SweetEvaluatorTypes.BaseVariable &
      (
        | SweetEvaluatorTypes.FragmentVariable
        | SweetEvaluatorTypes.AITokenVariable
      ),
  ) => void;
};

const SnippetFragment: React.FC<Props> = ({ formValues, setFormValues }) => {
  const [version, setVersion] = useState(0);
  const clientId = useClientId();
  const { t } = useTranslation();

  const onChange = _.debounce((html: string) => {
    const newValues = { ...formValues, text: html };
    setFormValues(newValues);
  }, 400);

  const onSnippetAdded = _.debounce(
    ({
      newSnippet,
      newContent,
    }: {
      newSnippet: SweetEvaluatorTypes.Variable;
      newContent: string;
    }) => {
      if (!newSnippet) {
        return;
      }
      const newValues = {
        ...formValues,
        text: newContent,
        snippets: [...(formValues.snippets || []), newSnippet],
      };
      setFormValues(newValues);
    },
    400,
  );

  const onSnippetUpdated = _.debounce(
    ({ updatedSnippet }: { updatedSnippet: SweetEvaluatorTypes.Variable }) => {
      const newValues = {
        ...formValues,
        snippets: _.map(formValues.snippets || [], (snippet) => {
          if (snippet.id === updatedSnippet.id) {
            return updatedSnippet;
          }
          return snippet;
        }),
      };
      setFormValues(newValues);
      setVersion((prevState) => prevState + 1);
    },
    400,
  );

  return (
    <Form.Field className={styles.modalFormField}>
      <label>
        {formValues.type === 'fragment'
          ? t('reveal.parameters.snippetSettings.content')
          : 'Instructions'}
      </label>
      <MergeTagsAutomationEditor
        version={version}
        clientId={clientId}
        defaultValue={formValues.text}
        mergeTagsSubId='settings'
        snippets={formValues.snippets}
        onChange={onChange}
        onSnippetAdded={onSnippetAdded}
        onSnippetUpdated={onSnippetUpdated}
        alwaysShowMenu
        fields='simple'
      />
    </Form.Field>
  );
};

export default SnippetFragment;
