import { formatToYyyyMmDd } from '@/common/dates';
import { Frequency } from '../GraphWrapper/types';

const getDayRange = (
  timestamp: string,
  frequency: Frequency,
): [string, string] => {
  const startDate = new Date(timestamp);
  const endDate = new Date(startDate);
  if (frequency === 'day') {
    endDate.setDate(endDate.getDate() + 1);
  }
  if (frequency === 'week') {
    endDate.setDate(endDate.getDate() + 7);
  }
  if (frequency === 'month') {
    endDate.setMonth(endDate.getMonth() + 1);
  }
  if (frequency === 'quarter') {
    endDate.setMonth(endDate.getMonth() + 3);
  }

  return [formatToYyyyMmDd(startDate), formatToYyyyMmDd(endDate)];
};

export default getDayRange;
