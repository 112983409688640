import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './GraphEmptyState.module.less';

const GraphEmptyState: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.emptyState}>{t('analytics.noDataPlaceholder')}</div>
  );
};

export default GraphEmptyState;
