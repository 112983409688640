import React, { VFC, useCallback, useMemo, useState } from 'react';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useCompanyTargetJobs from '@/graphql/hooks/searchPoolProfile/useCompanyTargetJobs';
import useClientId from '@/hooks/router/useClientId';

import { getTranslatedText } from '@/common';
import HoverPopup from '@/revealComponents/RevealListRow/HoverPopup';
import classnames from 'classnames';
import Plus from '@/components/Reveal/Icons/Plus';
import GenericButton from '@/components/Common/GenericButton';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import ProjectDropdown from '@/components/Common/ProjectDropdown/ProjectDropdown';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { UPDATE_SEARCH_POOL_JOB } from '@/graphql/searchPoolJob';
import { useMutation } from '@apollo/client';
import useSearchPoolJob from '@/graphql/hooks/searchPoolJobs/useSearchPoolJob';
import styles from './LinkedProjects.module.less';

type LinkedProjectsProps = {
  profileId: string;
};

const LinkedProjects: React.FC<LinkedProjectsProps> = ({ profileId }) => {
  const { t } = useTranslation();
  const notification = useNotificationSystem();
  const [linkedProjectId, setLinkedProjectId] = useState<string | null>(null);
  const [linkProject, setLinkProject] = useState(false);
  const [editMission] = useMutation(UPDATE_SEARCH_POOL_JOB);
  const { companyTargetJobs } = useCompanyTargetJobs({
    profileId,
  });
  const { jobOptions: projects } = useSearchPoolJobOptions('reveal', {
    missionCategory: { type: 'recruiting' },
  });

  const sortedCompanyTargetJobs = [...companyTargetJobs].sort((a, b) => {
    if (b.isArchived && !a.isArchived) {
      return -1;
    }
    if (a.isArchived && !b.isArchived) {
      return 1;
    }
    return a.data.title.localeCompare(b.data.title);
  });

  const linkedProject = useMemo(
    () => _.findWhere(projects, { id: linkedProjectId || '' }),
    [linkedProjectId, projects],
  );

  const selectableProjects = useMemo(() => {
    return _.filter(projects, (project) => {
      return !_.findWhere(sortedCompanyTargetJobs, { id: project.id });
    });
  }, [projects, sortedCompanyTargetJobs]);

  const onSave = useCallback(async () => {
    try {
      const variables = {
        searchPoolId: 'reveal',
        input: {
          jobId: linkedProjectId,
          title: linkedProject?.data?.title,
          targets: {
            companies: [{ id: profileId }],
          },
        },
      };
      await editMission({
        variables,
        refetchQueries: ['getCompanyTargetJobs'],
      });

      notification.success(t('reveal.missions.mission.modals.editSuccess'));
    } catch (e) {
      console.error(e);

      notification.error(t('reveal.missions.mission.modals.editError'));
    }
  }, [t, profileId, editMission, linkedProject, linkedProjectId, notification]);

  return (
    <>
      <h4 className={styles.title}>
        {t('reveal.profileModal.companyOverview.linkedProjects.title')}
      </h4>
      {_.isEmpty(sortedCompanyTargetJobs) && (
        <div className={styles.emptyState}>
          {t('reveal.profileModal.companyOverview.linkedProjects.emptyState')}
        </div>
      )}
      {sortedCompanyTargetJobs.map(({ id, data, isArchived }) => (
        <div key={id} style={{ width: '100%' }}>
          <CompanyLinkedProject
            jobId={id}
            jobData={data}
            archived={!!isArchived}
          />
        </div>
      ))}
      {!linkProject && (
        <span
          className={styles.addProjectButton}
          onClick={() => setLinkProject(true)}
        >
          <Plus />
          {t('reveal.profileModal.companyOverview.linkedProjects.linkProject')}
        </span>
      )}
      {linkProject && (
        <div className={styles.linkProjectContainer}>
          <ProjectDropdown
            initialOpen
            projects={selectableProjects || []}
            currentMission={linkedProject}
            onProjectSelected={({ id }) => setLinkedProjectId(id)}
            style={{ minWidth: '100%' }}
          />
          <GenericButton
            primacy='secondary'
            onClick={() => setLinkProject(false)}
            className={styles.linkButton}
          >
            {t('common.cancel')}
          </GenericButton>
          <GenericButton
            primacy='primary'
            className={styles.linkButton}
            onClick={() => {
              onSave();
              setLinkProject(false);
            }}
          >
            {t('common.save')}
          </GenericButton>
        </div>
      )}
    </>
  );
};

interface CompanyLinkedProjectProps {
  jobId: string;
  jobData: {
    title: string;
  };
  archived: boolean;
}

const CompanyLinkedProject: VFC<CompanyLinkedProjectProps> = ({
  jobId,
  jobData,
  archived,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();

  const { data: job } = useSearchPoolJob('reveal', jobId);

  const description = getTranslatedText(
    job?.searchPool?.job?.positionDescription?.description?.sections?.[0]
      ?.content,
  );

  return (
    <Link
      key={jobId}
      to={`/client/${clientId}/reveal/projects/recruiting/${jobId}`}
      target='_blank'
      className={styles.card}
    >
      {description ? (
        <div className={classnames(styles.cardTitle)}>
          <HoverPopup
            useMaxWidth
            content={
              <div className={styles.jobPopupContainer}>
                <h2 className={styles.jobPopupTitle}>{jobData.title}</h2>
                <div
                  className={styles.jobPopupDescription}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            }
          >
            {jobData.title || jobId}
            {archived && (
              <span className={styles.archived}>
                &nbsp;- {t('offers.defaultEmails.archived')}
              </span>
            )}
          </HoverPopup>
        </div>
      ) : (
        <div className={classnames(styles.cardTitle)}>
          {jobData.title || jobId}
          {archived && (
            <span className={styles.archived}>
              &nbsp;- {t('offers.defaultEmails.archived')}
            </span>
          )}
        </div>
      )}
    </Link>
  );
};

export default LinkedProjects;
