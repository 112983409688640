import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import './CampaignsSubHeader.css';

type LinkProps = {
  link: string;
  text: string;
};

interface SubHeaderProps {
  breadcrumbs: LinkProps[];
  errorMessage: any;
}

const CampaignsSubHeader: React.FC<SubHeaderProps> = ({
  breadcrumbs = [],
  errorMessage,
  children,
}) => {
  return (
    <div className='campaign-sub-header'>
      <div className='campaign-sub-header_breadcrumb'>
        {breadcrumbs.map((breadcrumb, i) => (
          <BreadcrumbSection
            key={breadcrumb?.text}
            breadcrumb={breadcrumb}
            i={i}
          />
        ))}
      </div>
      {errorMessage && (
        <div className='campaign-sub-header_error-message-container'>
          {errorMessage}
        </div>
      )}
      <div className='campaign-sub-header_actions-container'>{children}</div>
    </div>
  );
};

const BreadcrumbSection: React.FC<{
  breadcrumb: LinkProps;
  i: number;
}> = ({ breadcrumb, i }) => {
  return (
    <>
      {i > 0 && (
        <div className='campaign-sub-header_breadcrumb-divider'>
          <i className='ri-arrow-right-s-line' />
        </div>
      )}
      <Link
        className={classNames(
          'campaign-sub-header_breadcrumb-section',
          i === 1 && 'collapsible',
        )}
        to={breadcrumb.link}
      >
        {breadcrumb.text}
      </Link>
    </>
  );
};

export default CampaignsSubHeader;
