import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Divider, Dropdown } from 'semantic-ui-react';

import './DepartmentSelector.css';

/**
 * const value = 'sectionId | departmentId'
 *
 * const onChange = ({
 *   type, // 'section' | 'department'
 *   id,
 *   departmentId // if type === 'section'
 * }) => { doStuff(); };
 */
const DepartmentSelector = ({
  value: currentValue,
  onChange,
  departments,
  withOtherOption,
  withAllOption,
  placeholder,
}) => {
  const { t } = useTranslation();
  const options = getDepartmentAndSectionOptions({
    departments,
    withOtherOption,
    withAllOption,
    t,
  });
  const withSections = _.some(options, ({ type }) => type === 'section');
  const dropdownText = getOptionText({ value: currentValue, options });
  return (
    <div className='department-selector'>
      <Dropdown
        className='selection hs-dropdown-ellipsis'
        fluid
        closeOnChange
        text={dropdownText}
        placeholder={placeholder}
      >
        <Dropdown.Menu>
          {_.map(options, ({ value, text, type, departmentId }, index) => (
            <React.Fragment key={value}>
              {type === 'department' && index > 0 && <Divider key={index} />}
              <Dropdown.Item
                key={value}
                onClick={() => onChange({ type, id: value, departmentId })}
                className={classNames(
                  `option-${type}`,
                  withSections && 'with-sections',
                )}
              >
                {text}
              </Dropdown.Item>
            </React.Fragment>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const getDepartmentAndSectionOptions = ({
  departments,
  withAllOption,
  withOtherOption,
  t,
}) => {
  const sortedDepartmentsAndSections = _.flatten(
    _.map(departments, ({ id, title, sections }) => {
      const sectionsOptions = _.map(sections, (section) => ({
        text: section?.title,
        value: section?.id,
        type: 'section',
        departmentId: id,
      }));
      return [
        { text: title, value: id, type: 'department' },
        ...sectionsOptions,
      ];
    }),
  );
  return [
    ...(withAllOption
      ? [
          {
            text: t('offers.departments.options.all'),
            value: 'all',
            type: 'department',
          },
        ]
      : []),
    ...sortedDepartmentsAndSections,
    ...(withOtherOption
      ? [
          {
            text: t('offers.departments.options.others'),
            value: 'other',
            type: 'department',
          },
        ]
      : []),
  ];
};

const getOptionText = ({ value, options }) => {
  return _.findWhere(options, { value })?.text;
};

export default DepartmentSelector;
