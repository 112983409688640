import React from 'react';
import _ from 'underscore';
import { Trans } from 'react-i18next';
import useClientId from '@/hooks/router/useClientId';
import { TimelineItem } from '@/types/contactData';
import { getFullname } from '@/common/helpers/person';
import { Profile } from '@/types/profile';
import useMiniCampaigns from '@/graphql/hooks/campaigns/useMiniCampaigns';
import { MiniCampaign } from '@/types/campaign';
import UnsubscribedPill from '@/components/Reveal/Profile/privacy/UnsubscribedPill/UnsubscribedPill';
import TASK_ICONS from '../TaskIcons';
import IntegrationsSynchronizationStatus from '../IntegrationsSynchronizationStatus';
import EmailTrackingPopup from '../EmailTrackingPopup';
import AuthorAndDate from '../AuthorAndDate';

const ClassicCampaignMessageItem = () => {
  return null;
};

interface RevealCampaignMessageItemProps {
  item: TimelineItem;
  unbiasedModeEnabled: boolean;
  profile: Profile;
}

const RevealCampaignMessageItem: React.FC<RevealCampaignMessageItemProps> = ({
  item,
  unbiasedModeEnabled,
  profile,
}) => {
  const clientId = useClientId();
  const { action, author } = item || {};
  let profileName = getFullname(profile?.resumeData);
  profileName = unbiasedModeEnabled ? '???' : profileName;
  const recruiterName = getFullname(author);

  const { data } = useMiniCampaigns({ clientId });

  const campaigns: MiniCampaign[] = data?.client?.campaigns || [];
  const campaignName = _.findWhere(campaigns, { id: item?.action?.campaignId })
    ?.title;

  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{ backgroundColor: TASK_ICONS.email?.color }}
              className='icon'
            >
              <i className={`${TASK_ICONS.email?.icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            <div>
              <Trans
                i18nKey='profile.contact.timeline.campaign-message.description'
                components={{ b: <b /> }}
                values={{
                  authorName: recruiterName,
                  profileName,
                  campaignName,
                }}
              />
              {_.findWhere(item?.action?.events || [], {
                type: 'click-unsubscribe-link',
              }) ? (
                <UnsubscribedPill showLabel />
              ) : (
                ''
              )}
            </div>
            <AuthorAndDate timelineItem={item}>
              <IntegrationsSynchronizationStatus
                timelineItem={item}
                clientId={clientId}
                profile={profile}
              />
            </AuthorAndDate>
          </div>
          <EmailTrackingPopup action={action} />
        </div>
      </div>
    </div>
  );
};

interface CampaignMessageItemProps {
  item: any;
  unbiasedModeEnabled: boolean;
  profile: any;
  applicationMode: string;
}
const CampaignMessageItem: React.FC<CampaignMessageItemProps> = ({
  item,
  unbiasedModeEnabled,
  profile,
  applicationMode,
}) => {
  if (applicationMode === 'classic') {
    return <ClassicCampaignMessageItem />;
  }
  return (
    <RevealCampaignMessageItem
      item={item}
      profile={profile}
      unbiasedModeEnabled={unbiasedModeEnabled}
    />
  );
};

export default CampaignMessageItem;
