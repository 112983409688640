import React, { useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Form } from 'semantic-ui-react';
import { getRandomString } from '@/common';
import {
  AcceptConditionWithTargetText,
  AcceptConditionWithTargetTexts,
  ACCEPT_CONDITIONS_WITH_TARGET_TEXT,
  AndCondition,
  Condition,
  FieldCondition,
  IfStatement,
  OrCondition,
} from '@/common/mergeTags/utils';
import GenericButton from '@/components/Common/GenericButton';
import { useConditionsChainingContext } from '../useConditionsChainingContext';
import styles from '../ConditionsChaining.module.less';
import ConditionsChainingDropdownStatement from './DropdownStatement';
import ConditionsChainingDropdownAccept from './DropdownAccept';
import IfStatementValueEditor from './IfStatementValueEditor';
import InputTarget from './InputTarget';
import SelectTarget from './SelectTarget';

const IfStatementForm: React.FC<{
  ifStatement: IfStatement;
  index: number;
}> = ({ ifStatement, index }) => {
  const { t } = useTranslation();
  const {
    getStateTreeAfterReplacement,
    conditionsChainingState,
    setConditionsChainingState,
  } = useConditionsChainingContext();

  const addConditionSubRow = () => {
    const condition = ifStatement.condition as AndCondition | OrCondition;
    const newState = getStateTreeAfterReplacement<Condition>({
      node: conditionsChainingState,
      id: condition.id,
      newValue: {
        id: condition.id,
        type: condition.type,
        conditions: [
          ...condition.conditions,
          {
            id: getRandomString(12),
            type: 'field-condition',
            fieldCategory: 'native',
            fieldId: 'firstname',
            fieldType: 'string',
            accept: {
              id: getRandomString(12),
              type: 'is-known',
            },
          },
        ],
      },
    });
    setConditionsChainingState(newState);
  };

  const isStatementClearable = conditionsChainingState.ifStatements.length > 1;

  const isSubConditionClearable =
    (ifStatement.condition.type === 'and' ||
      ifStatement.condition.type === 'or') &&
    ifStatement.condition.conditions.length > 1;

  const clearStatement = () => {
    const newState = {
      ...conditionsChainingState,
      ifStatements: _.filter(
        conditionsChainingState.ifStatements,
        (statement) => statement.id !== ifStatement.id,
      ),
    };
    setConditionsChainingState(newState);
  };

  const clearSubCondition = ({
    subConditionId,
  }: {
    subConditionId: string;
  }) => {
    const newState = {
      ...conditionsChainingState,
      ifStatements: _.map(conditionsChainingState.ifStatements, (statement) => {
        if (statement.id !== ifStatement.id) {
          return statement;
        }
        const ifOrCondition = statement.condition as AndCondition | OrCondition;
        return {
          ...statement,
          condition: {
            ...ifOrCondition,
            conditions: _.filter(
              ifOrCondition.conditions,
              (subCondition) => subCondition.id !== subConditionId,
            ),
          },
        };
      }),
    };
    setConditionsChainingState(newState);
  };

  return (
    <div className={styles.statementWrapper}>
      <div className={styles.ifStatementHeader}>
        <h4>
          {index === 0
            ? t('editor.conditionsChainingVariable.if')
            : t('editor.conditionsChainingVariable.elseIf')}
        </h4>
        {isStatementClearable && (
          <i
            aria-hidden='true'
            className={classNames('ri-close-fill ri-lg', styles.clearIcon)}
            onClick={clearStatement}
          />
        )}
      </div>

      <>
        {ifStatement.condition.type === 'and' ||
        ifStatement.condition.type === 'or' ? (
          <>
            <Form.Field className={styles.statement}>
              <label className={styles.label}>
                {t('editor.conditionsChainingVariable.condition')}
              </label>
              <ConditionsChainingDropdownStatement
                value={ifStatement.condition.type}
                nodeId={ifStatement.condition.id}
                withConditionalOptions
              />
            </Form.Field>
            <div className={styles.andConditions}>
              {_.map(ifStatement.condition.conditions, (condition) => (
                <Form.Field
                  key={condition.id}
                  className={classNames(styles.statement, styles.subConditions)}
                >
                  <IfStatementFieldConditionInputs
                    condition={condition}
                    withConditionalOptions={false}
                  />

                  {isSubConditionClearable && (
                    <i
                      aria-hidden='true'
                      className={classNames(
                        'ri-close-fill ri-lg',
                        styles.clearIcon,
                      )}
                      onClick={() =>
                        clearSubCondition({ subConditionId: condition.id })
                      }
                    />
                  )}
                </Form.Field>
              ))}
              <GenericButton
                className={styles.button}
                onClick={addConditionSubRow}
              >
                {t('editor.conditionsChainingVariable.addRow')}
              </GenericButton>
            </div>
          </>
        ) : (
          <Form.Field className={styles.statement}>
            <label className={styles.label}>
              {t('editor.conditionsChainingVariable.condition')}
            </label>

            <IfStatementFieldConditionInputs
              condition={ifStatement.condition}
              withConditionalOptions
            />
          </Form.Field>
        )}
        <IfStatementValueEditor ifStatement={ifStatement} />
      </>
    </div>
  );
};

const IfStatementFieldConditionInputs: React.FC<{
  condition: FieldCondition;
  withConditionalOptions: boolean;
}> = ({ condition, withConditionalOptions }) => {
  // profile and mission custom fields can have the same id
  // so we add the word "profile" or "mission" at the beginning of the value
  // the value will be split after in the DropdownStatement component
  const statementValue = useMemo(() => {
    if (condition.fieldCategory === 'native') {
      return condition.fieldId;
    }
    if (condition.fieldCategory === 'custom-field') {
      return `profile-custom-field/${condition.fieldType}/${condition.customFieldId}`;
    }
    if (condition.fieldCategory === 'linked-mission-custom-field') {
      return `mission-custom-field/${condition.fieldType}/${condition.customFieldId}`;
    }
    return '';
  }, [condition]);

  return (
    <>
      <ConditionsChainingDropdownStatement
        value={statementValue}
        nodeId={condition.id}
        withConditionalOptions={withConditionalOptions}
      />
      <ConditionsChainingDropdownAccept
        value={condition.accept.type}
        nodeId={condition.accept.id}
        fieldType={condition.fieldType}
      />
      {condition.fieldType === 'string' &&
        ACCEPT_CONDITIONS_WITH_TARGET_TEXT.includes(condition.accept.type) && (
          <InputTarget
            value={
              (condition.accept as AcceptConditionWithTargetText).targetText
            }
            nodeId={condition.accept.id}
            node={condition.accept as AcceptConditionWithTargetText}
          />
        )}
      {condition.fieldType === 'select' && (
        <SelectTarget
          nodeId={condition.accept.id}
          node={condition.accept as AcceptConditionWithTargetTexts}
          condition={condition}
        />
      )}
    </>
  );
};

export default IfStatementForm;
