import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useEmailApi from '@/hooks/common/useEmailApi';
import Check from '@/components/Common/Icons/Check';

import styles from './Content.module.less';

const Content: React.FC = () => {
  const { t } = useTranslation();

  const { onTriggerOfflineRegister, currentEmailType } = useEmailApi();

  return (
    <>
      <h1>{t('onboarding.mail.title')}</h1>
      <p>{t('onboarding.mail.emailText')}</p>
      <button
        type='button'
        className={styles.connector}
        onClick={() => {
          onTriggerOfflineRegister('gmail');
        }}
        disabled={currentEmailType === 'gmail'}
      >
        <div className={styles.logoContainer}>
          <img
            className={styles.logo}
            src='/images/logos/gmail.svg'
            alt='gmail'
          />
        </div>
        <span
          className={classNames(styles.cta, {
            [styles.connected]: currentEmailType === 'gmail',
          })}
        >
          {t(
            `changeEmailAccountModal.${
              currentEmailType === 'gmail' ? 'gmailConnected' : 'gmailSwitch'
            }`,
          )}
        </span>
        {currentEmailType === 'gmail' && (
          <span className={styles.check}>
            <Check />
          </span>
        )}
      </button>
      <button
        type='button'
        className={styles.connector}
        onClick={() => {
          onTriggerOfflineRegister('outlook', 'select_account');
        }}
        disabled={currentEmailType === 'outlook'}
      >
        <div className={styles.logoContainer}>
          <img
            className={styles.logo}
            src='/images/logos/outlook.svg'
            alt='outlook'
          />
        </div>
        <span
          className={classNames(styles.cta, {
            [styles.connected]: currentEmailType === 'outlook',
          })}
        >
          {t(
            `changeEmailAccountModal.${
              currentEmailType === 'outlook'
                ? 'outlookConnected'
                : 'outlookSwitch'
            }`,
          )}
        </span>
        {currentEmailType === 'outlook' && (
          <span className={styles.check}>
            <Check />
          </span>
        )}
      </button>
    </>
  );
};

export default Content;
