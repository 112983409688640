import React from 'react';
import _ from 'underscore';
import useClientTemplates from '@/graphql/hooks/templates/useClientTemplates';
import useUserSenders from '@/graphql/hooks/users/useUserSenders';
import useSharedMessagingAccounts from '@/graphql/hooks/clients/useSharedServiceAccount';
import ActionPreview from './ActionPreview';

const SequencePreview = ({ clientId, sequence, alwaysExpanded = false }) => {
  const clientTemplates = useClientTemplates(clientId);
  const templates = clientTemplates?.data?.client?.templates;

  const contactFlowSequence = (sequence?.contactFlow?.sequences || [])[0];

  const { data: userSendersData } = useUserSenders({
    granted: true,
    owned: true,
  });

  const { sharedMessagingAccounts } = useSharedMessagingAccounts();

  if (
    !sequence ||
    !contactFlowSequence ||
    _.isEmpty(contactFlowSequence?.actions)
  ) {
    return null;
  }

  return (
    <div className='sequence-preview'>
      {_.map(contactFlowSequence?.actions || [], (action, index) => (
        <ActionPreview
          key={action?.actionId}
          actions={contactFlowSequence?.actions}
          actionIndex={index}
          clientId={clientId}
          action={action}
          template={getActionTemplate({ action, templates })}
          alwaysExpanded={alwaysExpanded}
          userSenders={
            [
              ...(userSendersData?.user?.senders || []),
              ...(sharedMessagingAccounts || []),
            ] || []
          }
        />
      ))}
    </div>
  );
};

const getActionTemplate = ({ action, templates }) => {
  return _.findWhere(templates, { id: action?.message?.templateId });
};

export default SequencePreview;
