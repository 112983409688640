/* eslint-disable no-restricted-syntax */
import _ from 'underscore';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import {
  ConditionsChainingVariable,
  FieldCondition,
  Condition,
  AndCondition,
  OrCondition,
  AcceptCondition,
} from '../utils';

const CONDITION_VALIDATORS: Record<
  string,
  ({
    accept,
    contextField,
  }: {
    accept: AcceptCondition;
    contextField: string;
  }) => boolean
> = {
  'is-known': ({ contextField }) => !!contextField,
  'is-unknown': ({ contextField }) => !contextField,
  'string-equals': ({ accept, contextField }) => {
    return (
      contextField !== undefined &&
      accept.type === 'string-equals' &&
      contextField.toLowerCase() === accept.targetText.toLowerCase()
    );
  },
  'string-not-equals': ({ accept, contextField }) => {
    return (
      contextField !== undefined &&
      accept.type === 'string-not-equals' &&
      contextField.toLowerCase() !== accept.targetText.toLowerCase()
    );
  },
  'string-contains': ({ accept, contextField }) => {
    return (
      contextField !== undefined &&
      accept.type === 'string-contains' &&
      contextField?.toLowerCase().includes(accept.targetText.toLowerCase())
    );
  },
  'string-not-contains': ({ accept, contextField }) => {
    return (
      contextField !== undefined &&
      accept.type === 'string-not-contains' &&
      !contextField?.toLowerCase().includes(accept.targetText.toLowerCase())
    );
  },
  'string-is-among': ({ accept, contextField }) => {
    return (
      contextField !== undefined &&
      accept.type === 'string-is-among' &&
      !!accept.targetTexts.find(
        (text) => text.toLowerCase() === contextField.toLowerCase(),
      )
    );
  },
};

export default class ExpressionConditionsChainingEvaluator
  implements SweetEvaluatorTypes.EvaluatorInterface {
  evaluate({
    context: fullContext,
    expression,
    getExpressionEvaluatorFromType,
    externalVariableEvaluator,
  }: {
    context: SweetEvaluatorTypes.Context;
    expression: SweetEvaluatorTypes.Variable;
    getExpressionEvaluatorFromType: SweetEvaluatorTypes.ExpressionEvaluatorFromType;
    externalVariableEvaluator: SweetEvaluatorTypes.ExternalVariableEvaluator;
  }): { value: string | undefined; evaluatedStatementId?: string } {
    const variable = (expression as unknown) as SweetEvaluatorTypes.BaseVariable &
      ConditionsChainingVariable;

    const { ifStatements, elseStatement } = variable;

    const getContextField = ({
      context,
      fieldId,
    }: {
      context: any;
      fieldId: string;
    }) => {
      let contextValue = '';
      const contextField = context[fieldId];
      if (!contextField) {
        return undefined;
      }
      if (_.isObject(contextField)) {
        contextValue = contextField.text || contextField.value || '';
      } else {
        contextValue = contextField;
      }
      return contextValue;
    };

    const isValidated = ({
      context,
      accept,
      fieldId,
    }: {
      context: any;
      accept: AcceptCondition;
      fieldId: string;
    }) => {
      const contextField = getContextField({ context, fieldId });

      if (!CONDITION_VALIDATORS[accept.type]) {
        return false;
      }
      return CONDITION_VALIDATORS[accept.type]({
        accept,
        contextField: contextField as string,
      });
    };

    const isFieldConditionAccepted = ({
      condition,
    }: {
      condition: FieldCondition;
    }): boolean => {
      if (condition.fieldCategory === 'native') {
        return isValidated({
          context: fullContext,
          accept: condition.accept,
          fieldId: condition.fieldId,
        });
      }
      if (condition.fieldCategory === 'custom-field') {
        return isValidated({
          context: fullContext.customFields || {},
          accept: condition.accept,
          fieldId: condition.customFieldId,
        });
      }
      if (condition.fieldCategory === 'linked-mission-custom-field') {
        return isValidated({
          context: fullContext.missionCustomFields || {},
          accept: condition.accept,
          fieldId: condition.customFieldId,
        });
      }

      return false;
    };

    const isStatementConditionAccepted = ({
      condition,
    }: {
      condition: Condition;
    }) => {
      const { type } = condition;
      if (type === 'field-condition') {
        return isFieldConditionAccepted({
          condition: condition as FieldCondition,
        });
      }
      if (type === 'and') {
        return _.all((condition as AndCondition).conditions, (andCondition) =>
          isFieldConditionAccepted({ condition: andCondition }),
        );
      }
      if (type === 'or') {
        return _.some((condition as OrCondition).conditions, (orCondition) =>
          isFieldConditionAccepted({ condition: orCondition }),
        );
      }
      return false;
    };

    for (const statement of ifStatements) {
      const isAccepted = isStatementConditionAccepted({
        condition: statement.condition,
      });
      if (isAccepted) {
        const subExpression = statement.result as any;
        const evaluatedResult = getExpressionEvaluatorFromType({
          type: subExpression.type,
        }).evaluate({
          context: fullContext,
          expression: {
            ...subExpression,
            text: subExpression.text || '',
          },
          getExpressionEvaluatorFromType,
          externalVariableEvaluator,
        });
        return {
          value: evaluatedResult.value,
          evaluatedStatementId: statement.id,
        };
      }
    }

    if (elseStatement) {
      return {
        value:
          getExpressionEvaluatorFromType({
            type: elseStatement.type,
          }).evaluate({
            context: fullContext,
            expression: {
              ...(elseStatement as any),
            },
            getExpressionEvaluatorFromType,
            externalVariableEvaluator,
          })?.value || undefined,
      };
    }

    return { value: undefined };
  }
}
