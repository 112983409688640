import React from 'react';
import { Button, Checkbox } from 'semantic-ui-react';

import { MergeTagsVariable } from '@/common/mergeTags/utils';
import { useMergeTagsPlayground } from '../context/MergeTagsPlaygroundProvider';
import { SweetEvaluatorTypes } from '../../../../SweetEvaluator';
import MergeTagsJsonContent from './MergeTagsJsonContent';
import styles from './styles.module.less';

const MergeTagsSnippets: React.FC = () => {
  const {
    snippets,
    addSnippet,
    shouldEvaluateWhenContextChange,
    setShouldEvaluateWhenContextChange,
    evaluateSnippets,
  } = useMergeTagsPlayground();
  return (
    <div className={styles.snippetsWrapper}>
      <h4>Snippets</h4>
      <div className={styles.actions}>
        <Button primary size='tiny' onClick={() => evaluateSnippets()}>
          Evaluate
        </Button>

        <Checkbox
          toggle
          label='Auto re-evaluate when context change'
          defaultChecked={shouldEvaluateWhenContextChange}
          onChange={(e, data) =>
            setShouldEvaluateWhenContextChange(data.checked || false)
          }
        />
      </div>
      {snippets.map((snippet) => (
        <Snippet snippet={snippet} key={snippet.id} />
      ))}
      <Button
        style={{ marginTop: '20px' }}
        size='tiny'
        color='black'
        onClick={() => addSnippet()}
      >
        Add Snippet
      </Button>
    </div>
  );
};

type SnippetProps = {
  snippet: MergeTagsVariable;
};
const Snippet: React.FC<SnippetProps> = ({ snippet }) => {
  const { updateSnippet } = useMergeTagsPlayground();
  return (
    <MergeTagsJsonContent
      content={snippet}
      onChange={(updatedSnippet) =>
        updateSnippet(updatedSnippet as SweetEvaluatorTypes.Variable)
      }
    />
  );
};

export default MergeTagsSnippets;
