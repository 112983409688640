import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { OfferWatchLabel } from '@/components/WatchLabel';
import { ATSConnectedIcon, AutopilotIcon } from '@/assets/icons';

import withActionLogger from '@/hocs/withActionLogger';
import './OfferLabels.css';
import withEnableAutopilot from '@/hocs/offers/withEnableAutopilot';
import contextToProps from '@/hocs/contextToProps';
import withUserFromJWToken from '@/hocs/users/withUserFromJWToken';

import OfferStateModalWrapper from './ChangeOfferStateModalWrapper';
import OfferLabel from './OfferLabel';

const OfferLabels = ({
  clientId,
  offer,
  hideDisabledAutopilot = true,
  onLogAction,
  t,
  onShowNotification,
  enableAutopilot,
  user,
}) => {
  const [modalType, setModalType] = useState(null);
  const logAction = ({ type }) => {
    onLogAction({
      type,
      info: {
        clientId,
        jobOfferId: offer.id,
        from: 'offers-view',
        ...(user?.firstname &&
          user?.lastname && { author: `${user?.firstname} ${user?.lastname}` }),
      },
    });
  };

  const onEnableAutopilot = async () => {
    try {
      await enableAutopilot({ id: offer.id });
      logAction({
        actionType: 'enable-offer-autopilot',
      });
      onShowNotification({
        message: t('offers.notifications.enableAutopilot.success'),
        level: 'success',
      });
    } catch (e) {
      onShowNotification({
        message: t('offers.notifications.enableAutopilot.error'),
        level: 'error',
      });
    }
  };

  const onSetModalType = ({ type, subtype }) => {
    if (offer?.id) {
      setModalType({ type, subtype });
    }
    const typeToKey = {
      disableAutopilot: 'disable-autopilot',
      unhold: 'unhold',
      integrationWorkflow: 'integration-workflow',
    };
    const key = typeToKey[type] || type;
    logAction({ type: `click-offer-tag-${key}` });
  };

  const { unlimitedFlowBatchSize } = offer || {};
  const hasUnlimitedFlow = unlimitedFlowBatchSize > 0;
  const jobPostingIsPublished = !!_.findWhere(offer?.jobPostings, {
    published: true,
  });
  return (
    <>
      {jobPostingIsPublished && (
        <OfferLabel
          className='job-active'
          text={t('offers.isPublished')}
          icon={<i className='ri-walk-line job-active-icon' />}
        />
      )}
      {offer.isHiresweetWatch && <OfferWatchLabel />}
      {hasUnlimitedFlow && (
        <OfferLabel
          className='unlimited-flow'
          text={t('offers.fastForwardSearch')}
          icon={<AutopilotIcon />}
          content={t('offers.fastForwardSearchDescription')}
        />
      )}
      {!offer.isAutopilot && !hideDisabledAutopilot && (
        <OfferLabel
          className='hold'
          icon={<AutopilotIcon />}
          content={t('offers.isAutopilotDetails')}
          text={t('offers.isNoAutopilot')}
          onClick={onEnableAutopilot}
        />
      )}
      {offer.isAutopilot && (
        <OfferLabel
          text={t('offers.isAutopilot')}
          content={t('offers.isAutopilotDetails')}
          icon={<AutopilotIcon />}
          onClick={() => onSetModalType({ type: 'disableAutopilot' })}
        />
      )}
      {renderOfferWorkflowsLabels({ offer, onSetModalType, t })}

      <div onClick={(e) => e.stopPropagation()}>
        <OfferStateModalWrapper
          offer={offer}
          clientId={clientId}
          modalType={modalType?.type}
          modalSubtype={modalType?.subtype}
          onCloseModal={() => setModalType(null)}
        />
      </div>
    </>
  );
};

const renderOfferWorkflowsLabels = ({ offer, onSetModalType, t }) => {
  const existingLabelsByType = new Set([]);
  return _.map((offer || {}).workflows, (workflow) => {
    const integrationType = ((workflow || {}).action || {}).service || '';
    if (integrationType === '' || existingLabelsByType.has(integrationType)) {
      return null;
    }
    existingLabelsByType.add(integrationType);
    return (
      <OfferLabel
        className='job-workflow-label'
        key={workflow.id}
        text={t(`integrations.shortTypes.${integrationType}`)}
        content={t(`offers.integrationDetails.${integrationType}`)}
        icon={<ATSConnectedIcon />}
        onClick={() =>
          onSetModalType({
            type: 'integrationWorkflow',
            subtype: integrationType,
          })
        }
      />
    );
  });
};

export default compose(
  withTranslation('translations'),
  contextToProps,
  withUserFromJWToken,
  withActionLogger,
  withEnableAutopilot,
)(OfferLabels);
