import React, { useEffect, useState, useMemo, useCallback } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ProfileRowHeader from '@/routes/RevealView/ProfileRow/ProfileRowHeader';
import useSelectedProfile from '@/hooks/router/useSelectedProfile';
import EmptyState from '@/revealComponents/EmptyState/EmptyState';
import GenericButton from '@/components/Common/GenericButton';
import {
  PageSelector,
  PartitioningSelector,
} from '@/components/Common/Pagination';
import { Dropdown } from 'semantic-ui-react';
import ProfileIdHandler from '../../../../common/ProfileIdHandler';
import { REVEAL_MAX_NB_RESULTS } from '../../../../hocs/searchPool/withRevealSearchPoolResults';
import ActionsDropdown from '../../../../routes/RevealView/revealComponents/Buttons/ActionsDropdown';
import EnrichedRevealProfileModal from '../../../../routes/RevealView/revealComponents/EnrichedRevealProfileModal';
import useCandidateSelection from '../../../../routes/RevealView/SearchView/CandidatesListPane/useCandidateSelection';
import CampaignProfilesPage from './CampaignProfilesPage';

import './CampaignProfilesList.css';

const candidatePerPageOptions = [10, 15, 20, 30, 50];

const CampaignProfilesList = ({
  clientId,
  campaignId,
  searchPoolId,
  profilesWithCurrentCampaignId,
  loading,
}) => {
  const { t } = useTranslation();
  const [statusFilter, setStatusFilter] = useState('all');

  const getCampaignEnrollmentIndex = useCallback(({ campaignEnrollments }) => {
    const profileCampaignEnrollmentIds = _.pluck(
      campaignEnrollments,
      'campaignId',
    );
    return _.indexOf(profileCampaignEnrollmentIds, campaignId);
  }, [campaignId]);

  // exclude profiles that may have been remove from campaign since query loaded
  const isInCampaign = useCallback(({ campaignEnrollments }) => {
    return getCampaignEnrollmentIndex({ campaignEnrollments }) >= 0;
  }, [getCampaignEnrollmentIndex]);

  const profilesInCampaign = useMemo(() => _.filter(
    profilesWithCurrentCampaignId,
    isInCampaign,
  ), [profilesWithCurrentCampaignId, isInCampaign]);

  const filteredProfilesId = useMemo(() => _.filter(
    profilesInCampaign,
    (profile) => {
      if (statusFilter === 'all') {
        return true;
      }
      const campaignEnrollmentIndex = getCampaignEnrollmentIndex({ campaignEnrollments: profile.campaignEnrollments });
      const campaignEnrollment = profile.campaignEnrollments[campaignEnrollmentIndex];
      return statusFilter === campaignEnrollment.state.status;
    }
  ), [profilesInCampaign, getCampaignEnrollmentIndex, statusFilter]);
  const [candidatePerPage, setCandidatePerPage] = useState(
    +localStorage.CampaignPaginationProfilesPerPage
      ? +localStorage.CampaignPaginationProfilesPerPage
      : 10,
  );
  const [currentPage, setCurrentPage] = useState(0);
  const pageProfiles = (filteredProfilesId || []).slice(
    currentPage * candidatePerPage,
    (currentPage + 1) * candidatePerPage,
  );
  const totalPages = Math.min(
    Math.ceil((filteredProfilesId?.length || 0) / candidatePerPage),
    REVEAL_MAX_NB_RESULTS / candidatePerPage,
  );

  const [selectedProfileId, setSelectedProfileId] = useSelectedProfile();
  const candidateSelection = useCandidateSelection();
  const allCandidates = candidateSelection.getAll();
  const selection = candidateSelection.get();

  const isHeaderChecked = useMemo(() => {
    if (_.isEmpty(selection) || _.isEmpty(allCandidates)) {
      return false;
    }
    const pageIds = _.pluck(pageProfiles, 'id');
    return candidateSelection.areSelected({ profilesIds: pageIds });
    // eslint-disable-next-line
  }, [
    currentPage,
    pageProfiles.length,
    selection.length,
    allCandidates.length,
  ]);

  const onToggleCheckbox = ({ checked }) =>
    candidateSelection.onSelectAllInCurrentPageToggle(
      checked,
      currentPage,
      candidatePerPage,
      pageProfiles,
    );

  const handleCandidatePerPageChange = (value) => {
    setCandidatePerPage(value);
    localStorage.setItem('CampaignPaginationProfilesPerPage', `${value}`);
  };

  useEffect(() => {
    candidateSelection.updateAllCandidates({ candidates: filteredProfilesId });
    candidateSelection.reset();
    setCurrentPage(0);
    // eslint-disable-next-line
  }, [_.pluck(filteredProfilesId, 'id').join(';')]);

  const profileIdHandler = ProfileIdHandler({
    profileIds: _.pluck(filteredProfilesId, 'id'),
    selectedProfileId,
    onSelectProfileId: (profileId) => setSelectedProfileId(profileId),
  });

  return (
    <div className='campaign-profiles-list'>
      <div className='button-container'>
        <ActionsDropdown
          clientId={clientId}
          campaignId={campaignId}
          candidates={selection}
          t={t}
        />
        <div className='align-right'>
          <Dropdown
            selection
            search
            value={statusFilter}
            onChange={(e, { value }) => setStatusFilter(value)}
            options={[
              {
                key: 'all',
                value: 'all',
                text: t('campaigns.table.filters.allStatus')
              },
              {
                key: 'scheduled',
                value: 'scheduled',
                text: t('campaigns.table.filters.scheduled')
              },
              {
                key: 'completed',
                value: 'completed',
                text: t('campaigns.table.filters.completed')
              },
              {
                key: 'pending',
                value: 'pending',
                text: t('campaigns.table.filters.pending')
              },
            ]}
            className='hiresweet-rounded'
          />
        </div>
      </div>

      <ProfileRowHeader
        onToggleCheckbox={onToggleCheckbox}
        isChecked={isHeaderChecked}
        showMissions
        showSequence={false}
        showCampaignState
      />

      {!loading && _.isEmpty(profilesWithCurrentCampaignId) ? (
        <EmptyState
          title={t('campaigns.table.emptyState.title')}
          innerContent={t('campaigns.table.emptyState.innerContent')}
          illustrationPath='/images/placeholders/contactsEmptyState.svg'
          cta={
            <Link to={`/client/${clientId}/reveal/search`}>
              <GenericButton size='big'>
                {t('campaigns.table.emptyState.cta')}
              </GenericButton>
            </Link>
          }
        />
      ) : (
        <>
          <CampaignProfilesPage
            clientId={clientId}
            campaignId={campaignId}
            searchPoolId={searchPoolId}
            candidateSelection={candidateSelection}
            profiles={pageProfiles}
            loading={loading}
            onClickRow={({ profileId }) => setSelectedProfileId(profileId)}
          />

          <div className='table-footer'>
            <PartitioningSelector
              options={candidatePerPageOptions}
              value={candidatePerPage}
              onChange={(value) => handleCandidatePerPageChange(value)}
            />
            {totalPages > 1 && (
              <PageSelector
                currentPageIndex={currentPage}
                numberOfPages={totalPages}
                onChange={setCurrentPage}
              />
            )}
          </div>
        </>
      )}

      {/* Enriched profile query must end after non-enriched profiles have
      loaded so we get the full data in the modal */}
      {!loading && (
        <EnrichedRevealProfileModal
          clientId={clientId}
          searchPoolId={searchPoolId}
          open={!!selectedProfileId}
          onClose={() => setSelectedProfileId(null)}
          profileIdHandler={profileIdHandler}
          searches={{}} // TODO: hacky but useful
        />
      )}
    </div>
  );
};

export default CampaignProfilesList;
