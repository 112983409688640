import { TFunction } from 'i18next';

export const getSequenceButtonText = (
  jobHasNoTemplatesLinked: boolean,
  jobHasNoFollowupsLinked: boolean,
  t: TFunction,
): string => {
  if (!jobHasNoTemplatesLinked && !jobHasNoFollowupsLinked) {
    return t('header.parameters.sequences', { count: 1 });
  }
  if (jobHasNoTemplatesLinked) {
    return t('offers.sequences.noTemplatesLinked');
  }
  return t('offers.sequences.noFollowupsLinked');
};

export default {};
