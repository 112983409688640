import gql from 'graphql-tag';
import ProfileMissionInfo from './ProfileMissionInfo';

export default gql`
  fragment ProfileMissionInfoWithMission on ProfileMissionInfo {
    mission {
      id
      data {
        title
      }
    }
    ...ProfileMissionInfo
  }
  ${ProfileMissionInfo}
`;
