import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './LegendCategory.module.less';

interface LegendCategoryProps {
  color: string;
  condensed?: boolean;
}

const LegendCategory: FC<LegendCategoryProps> = ({
  color,
  condensed,
  children,
}) => (
  <span className={classNames(styles.category, condensed && styles.condensed)}>
    <i className={styles.box} style={{ backgroundColor: color }} />
    <span className={styles.label}>{children}</span>
  </span>
);

export default LegendCategory;
