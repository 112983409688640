import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import styles from './Alert.module.less';

interface Props {
  title: string;
  description?: string;
  level?: 'error' | 'warning' | 'info';
  hasCta?: boolean;
}

const Alert = ({
  title,
  description,
  level = 'error',
  hasCta = false,
  children,
}: PropsWithChildren<Props>) => (
  <div
    className={classNames(
      styles.alert,
      styles[level],
      hasCta ? styles.flex : '',
    )}
  >
    <div>
      <span className={styles.title}>{title}</span>
      {description && <span className={styles.description}>{description}</span>}
    </div>
    {children}
  </div>
);

export default Alert;
