import gql from 'graphql-tag';
import { MutationTuple, useMutation } from '@apollo/client';
import RevealProject, {
  IRevealProject,
} from '@/graphql/fragments/RevealProject';

type ClientIntegrationVariables = {
  input: {
    type: string;
    mode: string;
    accountId?: string;
    token?: string;
  };
};

type MutationPayload = {
  client: ClientResponse;
};

interface ClientResponse {
  id: string;
  revealProjects?: IRevealProject[];
}

const CREATE_REVEAL_CONNECTOR = gql`
  mutation createRevealConnector($input: CreateRevealConnectorInput!) {
    createRevealConnector(input: $input) {
      client {
        id
        revealProjects {
          ...RevealProject
        }
      }
    }
  }
  ${RevealProject}
`;

const useCreateClientIntegration = (): MutationTuple<
  MutationPayload,
  ClientIntegrationVariables
> => {
  return useMutation(CREATE_REVEAL_CONNECTOR);
};

export default useCreateClientIntegration;
