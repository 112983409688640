import React from 'react';
import {
  MetaTaskFormatFormField,
  MetaTaskFormatFormFieldOption,
} from '../types';
import MetaTaskFormFieldInputAnswer from './MetaTaskFormFieldInputAnswer';
import MetaTaskFormFieldSelectableAnswer from './MetaTaskFormFieldSelectableAnswer';
import MetaTaskFormFieldDateAnswer from './MetaTaskFormFieldDateAnswer';
import MetaTaskFormFieldTextAreaAnswer from './MetaTaskFormFieldTextAreaAnswer';

export interface MetaTaskFormFieldAnswerProps {
  field?: MetaTaskFormatFormField;
  option?: MetaTaskFormatFormFieldOption;
  onSelect?: ({
    fieldId,
    answerId,
  }: {
    fieldId: string;
    answerId: string;
  }) => void | undefined;
  onChange?: ({
    fieldId,
    value,
  }: {
    fieldId: string;
    value: string;
  }) => void | undefined;
  disabled?: boolean;
  readMode?: boolean;
}

const MetaTaskFormFieldAnswer: React.FC<MetaTaskFormFieldAnswerProps> = ({
  field,
  option,
  onChange,
  onSelect,
  disabled,
  readMode,
}) => {
  if (!field) {
    return null;
  }

  if (
    field.type === 'select' ||
    field.type === 'checkbox' ||
    field.type === 'enum' ||
    field?.subtype === 'enum'
  ) {
    return (
      <MetaTaskFormFieldSelectableAnswer
        field={field}
        option={option}
        onSelect={onSelect}
        disabled={disabled}
        readMode={readMode}
      />
    );
  }
  if (
    field?.type === 'inline-text' ||
    field?.subtype === 'inline-text' ||
    field?.subtype === 'integer' ||
    field?.subtype === 'float'
  ) {
    return (
      <MetaTaskFormFieldInputAnswer
        field={field}
        onChange={onChange}
        disabled={disabled}
        readMode={readMode}
      />
    );
  }
  if (field?.type === 'text-area') {
    return (
      <MetaTaskFormFieldTextAreaAnswer
        field={field}
        onChange={onChange}
        disabled={disabled}
        readMode={readMode}
      />
    );
  }

  if (field?.subtype === 'day') {
    return (
      <MetaTaskFormFieldDateAnswer
        field={field}
        onChange={onChange}
        disabled={disabled}
        readMode={readMode}
      />
    );
  }

  return null;
};

export default MetaTaskFormFieldAnswer;
