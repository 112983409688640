import React, { useState, useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Segment, Loader } from 'semantic-ui-react';
import { useMutation, useLazyQuery } from '@apollo/client';
import GenericButton from '@/components/Common/GenericButton';
import GeneralCriteriaForm from '@/containers/Parameters/OfferCriteria/GeneralCriteriaForm';
import useSearchPoolJob from '../../../../graphql/hooks/searchPoolJobs/useSearchPoolJob';
import useClientCriteriaOptions from '../../../../graphql/hooks/clients/useClientCriteriaOptions';
import jobPositionOptions from '../../../../common/options/revealJobPositionExtraOptions.json';

import {
  SkillsCriteriaForm,
  OtherInformationCriteriaForm,
} from '../../../../containers/Parameters/OfferCriteria/PureCriteriaForm';
import {
  getInitialValues,
  getLabelToValue,
  getNewField,
  getOptions,
  removeUnwantedFields,
} from '../../../../containers/Parameters/OfferCriteria/helpers';
import {
  UPDATE_SEARCH_POOL_JOB_CRITERIA,
  GET_SEARCH_POOL_JOB_RELEVANT_PROFILES_COUNT,
} from '../../../../graphql/searchPoolJob';

import './Calibration.css';

const JobCalibrationView = ({ clientId, searchPoolId, jobId }) => {
  const { t, i18n } = useTranslation();
  const { data: jobData, loading: jobLoading } = useSearchPoolJob(
    searchPoolId,
    jobId,
  );

  const [
    getRelevantProfilesCountQuery,
    // { data: relevantProfilesData, loading: relevantProfilesCountLoading },
  ] = useLazyQuery(GET_SEARCH_POOL_JOB_RELEVANT_PROFILES_COUNT);

  const {
    data: criteriaOptionsData,
    loading: criteriaOptionsLoading,
  } = useClientCriteriaOptions(clientId);

  const job = jobData?.searchPool?.job;

  /*   const lazyRelevantProfilesCount =
    relevantProfilesData?.searchPool?.job?.stats?.relevantProfiles;
  const relevantProfilesCount = _.isNumber(lazyRelevantProfilesCount)
    ? lazyRelevantProfilesCount
    : job?.stats?.relevantProfiles; */

  const getRelevantProfilesCount = useMemo(
    () => _.debounce(getRelevantProfilesCountQuery, 1000),
    [getRelevantProfilesCountQuery],
  );

  if (criteriaOptionsLoading || jobLoading) {
    return (
      <div className='job-calibration-view'>
        <Loader active size='large' />
      </div>
    );
  }

  const optionTextKey = (i18n.resolvedLanguage || '')
    .toLowerCase()
    .includes('fr')
    ? 'fr'
    : 'text';

  const cleanJobPositionsOptions = _.sortBy(
    _.map(
      _.map(jobPositionOptions, (option) => ({
        ...option,
        en: option.text,
        text: option[optionTextKey] || option.text,
      })),
      (opt) => _.omit(opt, 'displayedByDefault'),
    ),
    'text',
  );

  const cleanClientCriteriaOptions = {
    ...criteriaOptionsData?.client?.criteriaOptions,
    jobPositions: _.map(cleanJobPositionsOptions, (option) => ({
      identifier: option.value,
      label: { default: option.text },
    })),
  };

  const clientCriteriaOptions = getOptions(cleanClientCriteriaOptions);
  const labelToValue = getLabelToValue(cleanClientCriteriaOptions);

  return (
    <div className='job-calibration-view'>
      <CriteriaForm
        jobId={job?.id}
        jobCriteria={job?.criteria}
        getRelevantProfilesCount={getRelevantProfilesCount}
        criteriaOptions={clientCriteriaOptions}
        labelToValue={labelToValue}
        t={t}
      />
    </div>
  );
};

const CriteriaForm = ({
  jobId,
  jobCriteria,
  getRelevantProfilesCount,
  criteriaOptions,
  labelToValue,
  t,
}) => {
  const [updateCriteria, { loading: updatingCriteria }] = useMutation(
    UPDATE_SEARCH_POOL_JOB_CRITERIA,
  );
  const [criteriaState, setCriteriaState] = useState(jobCriteria);

  const handleChange = async ({ label, value }) => {
    const realValue = label === 'jobPositions' ? [value] : value;
    const newField = getNewField({
      label,
      value: realValue,
      labelToValue,
      currentCriteria: criteriaState,
    });
    if (newField) {
      const newCriteria = removeUnwantedFields({
        ...criteriaState,
        ...newField,
      });
      setCriteriaState(newCriteria);
      getRelevantProfilesCount({
        variables: {
          searchPoolId: 'reveal',
          jobId,
          criteria: formatCriteriaBeforeSave(newCriteria),
        },
      });
    }
  };

  return (
    <>
      <CriteriaSections
        jobCriteria={criteriaState}
        criteriaOptions={criteriaOptions}
        handleChange={handleChange}
        updatingCriteria={updatingCriteria}
        jobId={jobId}
        updateCriteria={updateCriteria}
        t={t}
      />
    </>
  );
};

const CriteriaSections = ({
  jobCriteria,
  criteriaOptions,
  updatingCriteria,
  handleChange,
  jobId,
  updateCriteria,
  t,
}) => {
  const currentCriteria = {
    ...getInitialValues({ offer: { criteria: jobCriteria } }),
    ...(!_.isEmpty(jobCriteria?.collaboratorsInCharge) && {
      collaboratorsInCharge: _.compact(
        _.map(jobCriteria?.collaboratorsInCharge, (collaborator) => {
          if (_.isString(collaborator)) {
            return _.findWhere(criteriaOptions?.collaboratorsInCharge, {
              value: collaborator,
            });
          }
          const { email } = collaborator;
          return _.findWhere(criteriaOptions?.collaboratorsInCharge, {
            value: email,
          });
        }),
      ),
    }),
  };

  const onSave = () => {
    const criteria = formatCriteriaBeforeSave(jobCriteria);
    updateCriteria({
      variables: {
        searchPoolId: 'reveal',
        input: {
          id: jobId,
          criteria,
        },
      },
    });
  };

  return (
    <>
      <Segment className='pure-criteria-form'>
        <div className='job-calibration-header'>
          <h2>{t('reveal.missions.mission.settingsTab.calibration')}</h2>
          <GenericButton onClick={onSave} disabled={updatingCriteria}>
            {t(
              'reveal.missions.mission.recommandations.calibration.saveButton',
            )}
          </GenericButton>
        </div>
        <h3>
          {t('reveal.missions.mission.recommandations.calibration.generalTab')}
        </h3>
        <GeneralCriteriaForm
          t={t}
          options={criteriaOptions}
          handleChange={handleChange}
          current={currentCriteria}
          modifyingNotification={() => {}}
          errorFields={null}
          simplifiedMode
          debounceTextFields
        />
        <div className='ui divider settings-divider' />
        <h3>
          {t('reveal.missions.mission.recommandations.calibration.skillTab')}
        </h3>
        <SkillsCriteriaForm
          t={t}
          options={criteriaOptions}
          handleChange={handleChange}
          current={currentCriteria}
          modifyingNotification={() => {}}
          errorFields={null}
          simplifiedMode
          debounceTextFields
        />
        <div className='ui divider settings-divider' />
        <h3>
          {t(
            'reveal.missions.mission.recommandations.calibration.otherInfoTab',
          )}
        </h3>
        <OtherInformationCriteriaForm
          t={t}
          options={criteriaOptions}
          handleChange={handleChange}
          current={currentCriteria}
          modifyingNotification={() => {}}
          errorFields={null}
          simplifiedMode
          debounceTextFields
        />
      </Segment>
    </>
  );
};

const formatCriteriaBeforeSave = (criteria) => {
  const cleanCriteria = removeUnwantedFields(criteria);
  return {
    ...cleanCriteria,
    ...(cleanCriteria?.collaboratorsInCharge && {
      collaboratorsInCharge: _.map(
        cleanCriteria?.collaboratorsInCharge,
        (collaborator) => {
          if (_.isString(collaborator)) {
            return collaborator;
          }
          return collaborator?.email;
        },
      ),
    }),
  };
};

export default JobCalibrationView;
