import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';

import { Dropdown } from 'semantic-ui-react';

import UnifiedViewContext from '@/containers/Parameters/Offers/UnifiedViewContext';
import { getJobTemplatesId } from '@/common/jobs';
import { exportToCSV, profilesToCSV } from '../common/csv';

import OfferStateModalWrapper from '../containers/Parameters/Offers/components/ChangeOfferStateModalWrapper';
import withClientIntegrations from '../hocs/integrations/withClientIntegrations';
import { getOfferProfiles } from '../hocs/offers/withOfferProfiles';
import withUserFromJWToken from '../hocs/users/withUserFromJWToken';
import withActionLogger from '../hocs/withActionLogger';
import withOffer from '../hocs/offers/withOffer';
import withClient from '../hocs/clients/withClient';
import contextToProps from '../hocs/contextToProps';
import withApolloClientSafe from '../hocs/withApolloClientSafe';
import { getSequenceButtonText } from './helpers';
import Dots from './Common/Icons/Dots';
import GenericButton from './Common/GenericButton';

import './OfferSettingsDropdown.css';

const OfferSettingsDropdown = ({
  clientId,
  client,
  apolloClient,
  offer,
  offerLoading,
  onLogAction,
  from,
  integrations,
  integrationsLoading,
  onShowNotification,
  t,
}) => {
  const [modalType, setModalType] = useState(null);

  const { unifiedView } = useContext(UnifiedViewContext);
  const history = useHistory();

  const {
    isWaitingForValidation,
    isArchived,
    isAutopilot,
    isHold,
    workflows: offerWorkflows,
  } = offer || {};
  const clientCanAddExportAndImportProfiles =
    client?.permissions?.csvImportAndExport;
  const clientCanManagePostings = client?.permissions?.careerPage;
  if (offerLoading) {
    return null;
  }

  const jobTemplatesId = getJobTemplatesId(offer);
  const jobHasNoTemplatesLinked = _.isEmpty(jobTemplatesId);
  const jobHasNoFollowupsLinked = jobTemplatesId.length < 2;

  const shouldDisplayCreateWorkflowButton =
    !integrationsLoading &&
    _.isEmpty(offerWorkflows) &&
    !_.isEmpty(_.filter(integrations, ({ type }) => type !== 'slack'));

  const shouldDisplayEditWorkflowButton = !_.isEmpty(offerWorkflows);

  const logAction = ({ type }) => {
    onLogAction({
      type,
      info: {
        clientId,
        jobOfferId: offer.id,
        from: `${from}-offer-settings-buttons`,
      },
    });
  };

  const handleCriteriaClick = (e) => {
    e.stopPropagation();
    logAction({ type: 'click-offer-criteria' });
    history.push(`/client/${clientId}/jobs/${offer.id}/criteria`);
  };

  const handleSequenceClick = (e) => {
    e.stopPropagation();
    if (jobHasNoTemplatesLinked || jobHasNoFollowupsLinked) {
      return;
    }
    logAction({ type: 'click-offer-sequences' });
    history.push(`/client/${clientId}/jobs/${offer.id}/sequence`);
  };

  const handleAnalyticsClick = (e) => {
    e.stopPropagation();
    logAction({ type: 'click-offer-analytics' });
    history.push(`/client/${clientId}/jobs/${offer.id}/reports`);
  };

  const onSetModalType = (type) => {
    if (offer?.id) {
      setModalType(type);
    }
    logAction({ type: 'click-offer-settings-dropdown' });
  };

  const Icon = (
    <GenericButton primacy='tertiary' isIcon>
      <Dots />
    </GenericButton>
  );

  const exportJobProfilesToCSV = async () => {
    const variables = {
      offerId: offer?.id,
      step: '',
      search: '',
    };

    try {
      const { data } = await apolloClient.query({
        query: getOfferProfiles,
        variables,
      });
      const profiles = data?.offer?.profiles;

      const csvData = profilesToCSV({ profiles, t });

      const nbCandidates = csvData.length - 1;
      const clientName = client?.title || '';
      const jobName = offer?.title || '';

      const filename = `${_.compact([
        clientName,
        'HireSweet',
        jobName,
        `${nbCandidates} candidates`,
      ]).join(' - ')}.csv`;
      exportToCSV({ filename, rows: csvData });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      onShowNotification({
        message: t('newProfileModal.error'),
        level: 'error',
      });
    }
  };

  return (
    <div className='offer-settings-dropdown'>
      <Dropdown
        className='hs-contextual-menu'
        direction='left'
        item
        icon={Icon}
      >
        <Dropdown.Menu style={{ marginTop: 3 }}>
          {unifiedView && (
            <>
              <Dropdown.Item onClick={handleCriteriaClick}>
                {t('header.parameters.criteria')}
              </Dropdown.Item>

              <Dropdown.Item onClick={handleSequenceClick}>
                {getSequenceButtonText(
                  jobHasNoTemplatesLinked,
                  jobHasNoFollowupsLinked,
                  t,
                )}
              </Dropdown.Item>

              <Dropdown.Item onClick={handleAnalyticsClick}>
                {t('header.parameters.analytics')}
              </Dropdown.Item>
            </>
          )}
          <Dropdown.Item onClick={() => onSetModalType('editTitle')}>
            {t('offers.editTitleModal.trigger')}
          </Dropdown.Item>

          <Dropdown.Item onClick={() => onSetModalType('organizeJob')}>
            {t('offers.organizeModal.trigger')}
          </Dropdown.Item>

          {clientCanManagePostings && (
            <Dropdown.Item
              as={Link}
              to={`/client/${clientId}/jobs/${offer?.id}/jobPostings`}
            >
              {t('offers.managePosting')}
            </Dropdown.Item>
          )}

          {clientCanAddExportAndImportProfiles && (
            <>
              <Dropdown.Item onClick={() => onSetModalType('newProfile')}>
                {t('newProfileModal.trigger')}
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => onSetModalType('newProfilesFromCSV')}
              >
                {t('CSVImport.importProfiles')}
              </Dropdown.Item>

              <Dropdown.Item onClick={exportJobProfilesToCSV}>
                {t('csvExport.trigger')}
              </Dropdown.Item>
            </>
          )}

          <Dropdown.Item onClick={() => onSetModalType('configureSender')}>
            {t('offers.configureSender')}
          </Dropdown.Item>

          {!isArchived && !isAutopilot && (
            <Dropdown.Item onClick={() => onSetModalType('enableAutopilot')}>
              {t('offers.enableAutopilot')}
            </Dropdown.Item>
          )}

          {!isArchived && isAutopilot && (
            <Dropdown.Item onClick={() => onSetModalType('disableAutopilot')}>
              {t('offers.disableAutopilot')}
            </Dropdown.Item>
          )}

          {!isWaitingForValidation && !isHold && (
            <Dropdown.Item onClick={() => onSetModalType('hold')}>
              {t('offers.holdThisOffer')}
            </Dropdown.Item>
          )}

          {!isWaitingForValidation && !isArchived && isHold && (
            <Dropdown.Item onClick={() => onSetModalType('unhold')}>
              {t('offers.unHoldThisOffer')}
            </Dropdown.Item>
          )}

          {!isWaitingForValidation && !isArchived && (
            <Dropdown.Item onClick={() => onSetModalType('archive')}>
              {t('offers.archiveThisOffer')}
            </Dropdown.Item>
          )}

          {!isWaitingForValidation && isArchived && (
            <Dropdown.Item onClick={() => onSetModalType('unarchive')}>
              {t('offers.unArchiveThisOffer')}
            </Dropdown.Item>
          )}

          {isWaitingForValidation && (
            <Dropdown.Item onClick={() => onSetModalType('hide')}>
              {t('offers.hideThisOffer')}
            </Dropdown.Item>
          )}

          {shouldDisplayCreateWorkflowButton && (
            <Dropdown.Item
              onClick={() => onSetModalType('integrationWorkflow')}
            >
              {t('offers.integrationWorkflows.createWorkflow')}
            </Dropdown.Item>
          )}

          {shouldDisplayEditWorkflowButton && (
            <Dropdown.Item
              onClick={() => onSetModalType('integrationWorkflow')}
            >
              {t('offers.integrationWorkflows.editWorkflow')}
            </Dropdown.Item>
          )}

          {/* {!isWaitingForValidation && !isArchived && pushFlowPolicy?.nbPerDay === 5 && ( */}
          {/* <Dropdown.Item onClick={() => onSetModalType('pushFlowPolicyAugmentation')}> */}
          {/* {t('offers.pushFlowPolicyAugmentation')} */}
          {/* </Dropdown.Item> */}
          {/* )} */}
        </Dropdown.Menu>
      </Dropdown>
      <OfferStateModalWrapper
        offer={offer}
        clientId={clientId}
        modalType={modalType}
        onCloseModal={() => setModalType(null)}
      />
    </div>
  );
};

export default compose(
  withClient,
  withApolloClientSafe,
  withOffer,
  withUserFromJWToken,
  withActionLogger,
  contextToProps,
  withClientIntegrations,
  withTranslation('translations'),
)(OfferSettingsDropdown);
