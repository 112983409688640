import { createContext, Dispatch, Reducer } from 'react';

import { AggregatePeriod } from '@/types/statistics/reportStream';

import { TimeSpan } from './components/RevealAnalyticsHeader/RevealAnalyticsHeader';
import { initialDateRange } from './stats.helpers';

type StatisticsParameters = {
  timeSpan: TimeSpan;
  missionIds: string[];
  aggregatePeriod: AggregatePeriod;
};

type StatisticsParametersAction =
  | {
      type: 'setTimeSpan';
      value: TimeSpan;
    }
  | {
      type: 'setMissionIds';
      value: string[];
    }
  | {
      type: 'setAggregatePeriod';
      value: AggregatePeriod;
    };

type StatisticsParametersContext = [
  StatisticsParameters,
  Dispatch<StatisticsParametersAction>,
];

export const defaultStatisticsParameters: StatisticsParameters = {
  timeSpan: initialDateRange,
  missionIds: [],
  aggregatePeriod: 'week',
};

export const statisticsParametersReducer: Reducer<
  StatisticsParameters,
  StatisticsParametersAction
> = (state, action) => {
  switch (action.type) {
    case 'setTimeSpan':
      return { ...state, timeSpan: action.value };
    case 'setMissionIds':
      return { ...state, missionIds: action.value };
    case 'setAggregatePeriod':
      return { ...state, aggregatePeriod: action.value };
    default:
      return state;
  }
};

const StatisticsParametersContext = createContext<StatisticsParametersContext>([
  defaultStatisticsParameters,
  () => {},
]);

export default StatisticsParametersContext;
