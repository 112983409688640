import gql from 'graphql-tag';
import TranslatableText from './TranslatableText';

export default gql`
  fragment HiresweetData on HiresweetData {
    wishes {
      locations {
        remoteWish
        target {
          location {
            ...TranslatableText
          }
          remoteWish
        }
      }
      jobs {
        target {
          ...TranslatableText
        }
      }
      contracts {
        target {
          ...TranslatableText
        }
      }
      salary {
        target
        okWith
      }
      skills {
        target {
          skillId
          relevance
        }
        okWith {
          skillId
          relevance
        }
      }
    }
    availability {
      targetDate
      transitionDelayInMonths
    }
    seekingLevel {
      ...TranslatableText
    }
    languages {
      name {
        ...TranslatableText
      }
      level
    }
  }
  ${TranslatableText}
`;
