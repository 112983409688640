import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Dropdown, Loader, Modal, Table } from 'semantic-ui-react';
import _ from 'underscore';
import { useMutation, useQuery } from '@apollo/client';

import SettingsLayout from '@/containers/SettingsLayout';
import {
  ArchiveReason,
  GET_ARCHIVE_REASONS,
  DELETE_ARCHIVE_REASON,
} from '@/graphql/archiveReasons';
import useAtsFilterOptions from '@/graphql/hooks/clients/useClientAtsFiltersOptions';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import Plus from '@/components/Reveal/Icons/Plus';

import useCustomArchiveReasonsPermission from '@/graphql/hooks/clients/useCustomArchiveReasonsConfigurationPermission';
import GenericPopup from '@/components/Common/GenericPopup';
import styles from './ArchiveReasons.module.less';
import CreateArchiveReasonsModal from './CreateArchiveReasonModal';

interface ArchiveReasonsProps {
  clientId: string;
}

const ArchiveReasons: React.FC<ArchiveReasonsProps> = ({ clientId }) => {
  const { t } = useTranslation();
  const notification = useNotificationSystem();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState<ArchiveReason>();
  const [deleteReason] = useMutation(DELETE_ARCHIVE_REASON, {
    refetchQueries: [
      {
        query: GET_ARCHIVE_REASONS,
        variables: { clientId },
      },
    ],
  });

  const { data: archiveReasonsData, loading } = useQuery(GET_ARCHIVE_REASONS, {
    variables: { clientId },
    fetchPolicy: 'network-only',
  });

  const canEditCustomArchiveReasons = useCustomArchiveReasonsPermission();

  // on purpose: to prefetch it before opening modals
  useAtsFilterOptions(clientId);

  const reasons: ArchiveReason[] =
    archiveReasonsData?.client?.archiveReasons || [];

  const handleEditReason = (reason: ArchiveReason) => {
    setSelectedReason(reason);
    setCreateModalOpen(true);
  };

  const handleDeleteReason = (id: string) => {
    deleteReason({
      variables: {
        input: {
          id,
        },
      },
    });
    notification.success(
      t('settings.archiveReasonsSettings.archiveReasonDeleted'),
    );
  };

  const getcreateNewArchiveReasonsBtn = () => {
    if (loading) {
      return <></>;
    }

    const btn = (
      <div className='input-container'>
        <GenericButton
          disabled={!canEditCustomArchiveReasons}
          onClick={() => {
            setSelectedReason(undefined);
            setCreateModalOpen(true);
          }}
        >
          <Plus />
          {t('settings.archiveReasonsSettings.actions.createNew')}
        </GenericButton>
      </div>
    );

    if (!canEditCustomArchiveReasons) {
      return (
        <GenericPopup
          trigger={<div>{btn}</div>}
          content={t('common.permissions.disabled')}
          inverted
          on='hover'
        />
      );
    }

    return btn;
  };

  return (
    <div className='settings'>
      <SettingsLayout
        currentPage='archive-reasons'
        clientId={clientId}
        className='settings-container'
      >
        <div className='settings archive-reasons-page'>
          <h1 className={styles.mainTitle}>
            {t('settings.archiveReasonsSettings.header')}
          </h1>
          <h2>{t('settings.configuration')}</h2>
          <div className='section visible'>
            <p className='description'>
              {t('settings.archiveReasonsSettings.description')}
            </p>
            <div className='reasons-table'>
              {loading ? (
                <Container textAlign='center'>
                  <br />
                  <Loader inline active />
                  <br />
                </Container>
              ) : (
                !_.isEmpty(reasons) && (
                  <Table className={styles.reasonsTable} singleLine>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          {t(
                            'settings.archiveReasonsSettings.reasonsTable.reason',
                          )}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t(
                            'settings.archiveReasonsSettings.reasonsTable.category',
                          )}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t(
                            'settings.archiveReasonsSettings.reasonsTable.tags',
                          )}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t(
                            'settings.archiveReasonsSettings.reasonsTable.atsReason',
                          )}
                        </Table.HeaderCell>
                        <Table.HeaderCell className={styles.actionColumn} />
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {_.map(reasons, (reason) => (
                        <Table.Row key={reason.id}>
                          <Table.Cell className={styles.reasonName}>
                            {reason.title}
                          </Table.Cell>
                          <Table.Cell className={styles.reasonCategory}>
                            {t(
                              `settings.archiveReasonsSettings.categories.${reason.category}`,
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <div className={styles.reasonTags}>
                              {_.map(
                                reason.archiveTags,
                                (archiveTag, index) => (
                                  <div className={styles.tag} key={index}>
                                    {t(
                                      `settings.archiveReasonsSettings.archiveTags.${archiveTag}`,
                                    )}
                                  </div>
                                ),
                              )}
                            </div>
                          </Table.Cell>
                          <Table.Cell className={styles.reasonCategory}>
                            {reason.linkedATSRejectionReasonName}
                          </Table.Cell>
                          {canEditCustomArchiveReasons && (
                            <Table.Cell className={styles.reasonActions}>
                              <Dropdown
                                floating
                                trigger={<i className='ri-more-fill' />}
                                direction='left'
                                icon={null}
                              >
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    key='item-edit'
                                    onClick={() => handleEditReason(reason)}
                                  >
                                    {t(
                                      'settings.archiveReasonsSettings.actions.edit',
                                    )}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    key='item-delete'
                                    onClick={() => {
                                      setSelectedReason(reason);
                                      setDeleteModalOpen(true);
                                    }}
                                  >
                                    {t(
                                      'settings.archiveReasonsSettings.actions.delete',
                                    )}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Table.Cell>
                          )}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )
              )}
            </div>
            {getcreateNewArchiveReasonsBtn()}
          </div>
          {createModalOpen && (
            <CreateArchiveReasonsModal
              isOpen={createModalOpen}
              setOpen={setCreateModalOpen}
              selectedReason={selectedReason}
              clientId={clientId}
            />
          )}
          {deleteModalOpen && (
            <GenericModal
              open={deleteModalOpen}
              onClose={() => setDeleteModalOpen(false)}
              closeOnEscape
              closeOnDimmerClick
              size='small'
            >
              <Modal.Header>
                {t('settings.archiveReasonsSettings.deletionModal.title')}
              </Modal.Header>
              <Modal.Actions>
                <div className={styles.modalActions}>
                  <GenericButton
                    primacy='secondary'
                    size='big'
                    onClick={() => setDeleteModalOpen(false)}
                  >
                    {t('common.cancel')}
                  </GenericButton>
                  <GenericButton
                    onClick={() => {
                      handleDeleteReason(selectedReason?.id || '');
                      setDeleteModalOpen(false);
                    }}
                    size='big'
                  >
                    {t('common.save')}
                  </GenericButton>
                </div>
              </Modal.Actions>
            </GenericModal>
          )}
        </div>
      </SettingsLayout>
    </div>
  );
};

export default ArchiveReasons;
