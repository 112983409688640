import React, { FC } from 'react';

import { IAppPluginPubSub } from './AppPluginPubSub';

const AppPluginBridgePubSub = React.createContext<IAppPluginPubSub | null>(
  null,
);

export type AppPluginPubSubProviderProps = {
  pubSub: IAppPluginPubSub | null;
};

export const AppPluginPubSubProvider: FC<AppPluginPubSubProviderProps> = ({
  pubSub,
  children,
}) => {
  return (
    <AppPluginBridgePubSub.Provider value={pubSub}>
      {children}
    </AppPluginBridgePubSub.Provider>
  );
};

/**
 * Returns a PubSub to communicate with the plugin.
 *
 * If the connection with the plugin has not been established,
 * it will return `null`.
 */
function useAppPluginPubSub(): IAppPluginPubSub | null {
  return React.useContext(AppPluginBridgePubSub);
}

export default useAppPluginPubSub;
