import GenericToggle from '@/components/Common/GenericToggle';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'semantic-ui-react';
import styles from './CreateMetaTaskForm.module.less';

interface MetaTaskOrderFieldFormProps {
  showOrder: boolean;
  onShowOrder: (value: boolean) => void;
  order: string;
  onUpdateOrder: ({ order }: { order: string }) => void;
}

const MetaTaskOrderFieldForm: React.FC<MetaTaskOrderFieldFormProps> = ({
  showOrder,
  onShowOrder,
  order,
  onUpdateOrder,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Field>
      <div className={styles.fieldContainer}>
        <div className={styles.fieldHeader}>
          <GenericToggle
            className={styles.toggle}
            isChecked={showOrder || false}
            name='showOrder'
            onChange={({ value }) => {
              if (!value) {
                onUpdateOrder({ order: '' });
              } else {
                onUpdateOrder({
                  order: t('reveal.modals.metaTaskModal.orderPlaceholder'),
                });
              }
              onShowOrder(value);
            }}
          />
          <span>{t('reveal.modals.metaTaskModal.addOrder')}</span>
        </div>
        {showOrder && (
          <Input
            size='small'
            className={classnames(styles.modalFormInput, styles.orderInput)}
            value={order || ''}
            placeholder={
              order || t('reveal.modals.metaTaskModal.orderPlaceholder')
            }
            onChange={(e) =>
              onUpdateOrder({
                order: e.target.value,
              })
            }
          />
        )}
      </div>
    </Form.Field>
  );
};

export default MetaTaskOrderFieldForm;
