import _ from 'underscore';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Offer from '../../graphql/fragments/Offer';
import ClientPermissions from '../../graphql/fragments/ClientPermissions';

export const query = gql`
  query getClientOffers($clientId: ID!) {
    client(id: $clientId) {
      id
      title
      photoLink
      language
      extraTermsOfServiceConditions
      permissions {
        ...ClientPermissions
      }
      # Note: includeUnclassified is used below in props computation
      offers(includeSuggestedOffers: true, includeUnclassified: true) {
        ...Offer
      }
    }
  }
  ${Offer}
  ${ClientPermissions}
`;

const sortWatchBeforeOthers = (clientOffers) =>
  _.sortBy(clientOffers, ({ isHiresweetWatch }) => !isHiresweetWatch);

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      clientId: ownProps.clientId,
    },
  }),
  props: ({ ownProps, data: { loading, client, error } }) => {
    // filtering is done in frontend rather than backend, because changing includeUnclassified arg means fetching again
    const offers = ownProps?.includeUnclassified
      ? client?.offers
      : _.reject(client?.offers, ({ isUnclassified }) => isUnclassified);

    return {
      loading,
      clientOffersLoading: loading,
      offers: sortWatchBeforeOthers(offers),
      error,
    };
  },
});
