import React, { useMemo, useState } from 'react';
import { MergeTagsVariable, SNIPPET_TYPES } from '@/common/mergeTags/utils';
import GenericPopup from '@/components/Common/GenericPopup';
import SequenceVariable from './SequenceVariable';
import SequenceLockVariable from './SequenceLockVariable';
import SequenceSimplifiedConditionsChainingVariable from './SequenceSimplifiedConditionsChainingVariable';

interface Props {
  clientId: string;
  variable: MergeTagsVariable;
  withPopupContentOnly?: boolean;
  afterSubmit?: () => void;
  sequenceSnippetsByType?: Record<string, MergeTagsVariable[]>;
}

const SequenceVariablePopup: React.FC<Props> = ({
  clientId,
  variable,
  withPopupContentOnly,
  afterSubmit,
  sequenceSnippetsByType,
}) => {
  const [selectedVariable, setSelectedVariable] = useState<MergeTagsVariable>(
    variable,
  );
  const [open, setIsOpen] = useState(false);

  const content = useMemo(() => {
    if (
      selectedVariable.type === SNIPPET_TYPES.CONDITIONS_CHAINING &&
      selectedVariable.subtype === 'switch-on-field'
    ) {
      return (
        <SequenceSimplifiedConditionsChainingVariable
          clientId={clientId}
          variable={selectedVariable}
          afterSubmit={afterSubmit || (() => setIsOpen(false))}
        />
      );
    }
    return (
      <SequenceLockVariable
        mergeTagsVariable={selectedVariable}
        clientId={clientId}
        afterSubmit={afterSubmit || (() => setIsOpen(false))}
        sequenceSnippetsByType={sequenceSnippetsByType}
      />
    );
    // eslint-disable-next-line
  }, [selectedVariable]);

  if (withPopupContentOnly) {
    return content;
  }

  return (
    <GenericPopup
      style={{ padding: '0px' }}
      open={open}
      content={content}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      on='click'
      position='bottom center'
      trigger={
        <SequenceVariable
          onClick={(selectedSequenceVariable) =>
            setSelectedVariable(selectedSequenceVariable)
          }
          key={variable.id}
          mergeTagsVariable={variable}
          offsetKey='0'
        />
      }
    />
  );
};

export default SequenceVariablePopup;
