import _ from 'underscore';
import React, { Dispatch, SetStateAction } from 'react';
import moment from 'moment';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import {
  CSVLines,
  CSVLine,
  ColumnsMapping,
  guessColumnsFromFirstLine,
  ColumnMapping,
  getDefaultValue,
} from './helpers';
import {
  getFloatValue,
  getIntegerValue,
  getDateFormat,
  getDateValue,
} from './analysis.helpers';

export type ParsedCSVLines = (string | number | null | string[])[][];
export type CSVImportMapper = {
  reset: () => void;
  headers: CSVLine | null;
  lines: CSVLines;
  parsedRows: ParsedCSVLines;
  columnsMapping: ColumnsMapping;
  firstLineIsHeader: boolean;
  setFirstLineIsHeader: Dispatch<SetStateAction<boolean>>;
  updateColumnMapping: (index: number, mapping: ColumnMapping | null) => void;
};

/**
 * Detect default CSV options and mapping
 *
 * CSV options includes first line as header detection and column mapping to
 * profile fields.
 */
export default function useCSVImportMapper(
  rows: CSVLines,
  { customFields }: { customFields: CustomFieldDefinition[] },
): CSVImportMapper {
  // Import Options
  const [firstLineIsHeader, setFirstLineIsHeader] = React.useState(false);
  const [columnsMapping, setColumnsMapping] = React.useState<ColumnsMapping>(
    [],
  );

  const reset = () => {
    setFirstLineIsHeader(false);
    setColumnsMapping([]);
  };

  const { headers, lines } = React.useMemo(() => {
    return {
      headers: firstLineIsHeader ? rows[0] : null,
      lines: firstLineIsHeader ? rows.slice(1) : rows.slice(0),
    };
  }, [firstLineIsHeader, rows]);

  React.useEffect(() => {
    if (!rows.length) return;

    const {
      firstLineIsHeaderGuess,
      columnsMappingGuess,
    } = guessColumnsFromFirstLine(rows[0]);
    if (!_.isEmpty(columnsMappingGuess)) {
      setColumnsMapping(columnsMappingGuess);
    }
    setFirstLineIsHeader(firstLineIsHeaderGuess);
  }, [rows]);

  // Column Mapping
  const updateColumnMapping = (
    index: number,
    mapping: ColumnMapping | null,
  ) => {
    setColumnsMapping(
      (previousColumnsMapping: ColumnsMapping): ColumnsMapping => {
        const previousMapping = previousColumnsMapping || [];
        const before = previousMapping.slice(0, index);
        const after = previousMapping.slice(index + 1);
        const rowLength = rows.length ? rows[0].length : 0;
        const newMapping =
          index >= rowLength && mapping === null
            ? [...before, ...after]
            : [...before, mapping, ...after];

        return newMapping;
      },
    );
  };

  const parsedRows: ParsedCSVLines = React.useMemo(() => {
    return lines.map((line) => {
      return columnsMapping.map((mapping, index) => {
        if (!mapping) return (index < line.length && line[index]) || null;
        if (mapping.type === 'classic-field') {
          return (index < line.length && line[index]) || null;
        }
        const customFieldId = mapping.id;
        const customField = _.findWhere(customFields, { id: customFieldId });
        if (!customField) return null;
        const originalValue = line[index];
        const { defaultValue } = mapping;
        const dv = getDefaultValue(defaultValue);
        if (customField.type === 'enum') {
          return originalValue || dv || null;
        }
        if (customField.type === 'float') {
          return getFloatValue(originalValue) || dv || null;
        }
        if (customField.type === 'integer') {
          return getIntegerValue(originalValue) || dv || null;
        }
        if (customField.type === 'day') {
          const column = rows.map((row) => row[index]);
          // FIXME: performance
          const dateFormat = getDateFormat(column);
          return moment(getDateValue(originalValue, dateFormat)).format(
            'MMMM DO, YYYY',
          );
        }
        return originalValue || dv || null;
      });
    });
    // eslint-disable-next-line
  }, [lines, columnsMapping, customFields]);

  return {
    reset,
    headers,
    lines,
    parsedRows,
    columnsMapping,
    firstLineIsHeader,
    setFirstLineIsHeader,
    updateColumnMapping,
  };
}
