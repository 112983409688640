import gql from 'graphql-tag';

export default gql`
  fragment SearchCriteria on SearchCriteria {
    locations
    locationsAndRemote {
      okWith {
        location {
          id
        }
        remoteWish
      }
      target {
        location {
          id
        }
        remoteWish
      }
    }
    jobPositions
    jobPositionsOperator
    skills
    skillsOperator
    salaryMin
    salaryMax
    experienceYearsMin
    experienceYearsMax
    contracts
    schoolTypes
    excludeHidden
    excludeProfilesInJob
    remoteOnly # should have been called "excludeRemoteOnly"
    responsibilities
  }
`;
