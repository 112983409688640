import { useLocation, useRouteMatch } from 'react-router-dom';

const useSequenceId = () => {
  const { pathname } = useLocation();
  const matchSequencePage = useRouteMatch({
    path: '/client/:clientId/reveal/sequences/:sequenceId',
    exact: true,
  });
  if (!matchSequencePage) {
    return null;
  }
  return pathname.split('/')[4];
};

export default useSequenceId;
