import { EditorState, RichUtils } from 'draft-js';
import React, { useEffect, useRef, useState } from 'react';
import { Icon, Input, Menu, Popup } from 'semantic-ui-react';
import classNames from 'classnames';
import { isURL } from '../../containers/Editor/helpers';

const RichEditorControls = ({ children, editorState, editorRef, onChange, smallControls, disabled = false }) => {
  const linkInputRef = useRef(null);
  const [showUrlInput, setShowUrlInput] = useState(false);
  const [urlValue, setUrlValue] = useState('');

  const focusRef = (ref) => {
    try {
      ref.current.focus();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!showUrlInput) {
      // TODO: does not work very well... link is still selected when URL input is closed
      // so typing will erase link block we just created
      focusRef(editorRef);
    } else {
      focusRef(linkInputRef);
    }

    // eslint-disable-next-line
  }, [linkInputRef, showUrlInput]);

  const currentInlineStyle = editorState.getCurrentInlineStyle();
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startOffset = selection.getStartOffset();
  const endOffset = selection.getEndOffset();

  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const inlineStyleHandler = (style) => (e) => {
    e.preventDefault(); // necessary to keep focus in Editor
    onChange(RichUtils.toggleInlineStyle(editorState, style));
  };

  const blockTypeHandler = (blockType) => (e) => {
    e.preventDefault();
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const getBlockWithLinkAtBeginning = () => {
    const startKey = selection.getStartKey();
    return contentState.getBlockForKey(startKey);
  };

  const getEntityKeyInSelection = (type) => {
    const blockWithLinkAtBeginning = getBlockWithLinkAtBeginning();
    const entityKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
    if (!entityKey) {
      return null;
    }
    const entity = contentState.getEntity(entityKey);
    if (entity.getType() !== type) {
      return null;
    }
    return entityKey;
  };

  const handleAddLink = (e) => {
    e?.stopPropagation(); // eslint-disable-line no-unused-expressions
    e?.preventDefault(); // eslint-disable-line no-unused-expressions

    const linkEntityKey = getEntityKeyInSelection('LINK');
    let url = '';
    if (linkEntityKey) {
      url = contentState.getEntity(linkEntityKey).getData().url;
    } else {
      const blockWithLinkAtBeginning = getBlockWithLinkAtBeginning();
      const selectedText = blockWithLinkAtBeginning
        .getText()
        .slice(startOffset, endOffset);
      url = isURL(selectedText) ? selectedText : '';
    }
    setUrlValue(url);
    setShowUrlInput(true);
  };

  const handleLinkChange = (e, { value }) => {
    setUrlValue(value);
  };

  const insertLink = () => {
    if (getEntityKeyInSelection('LINK')) {
      setShowUrlInput(false);
      setUrlValue('');
      return;
    }
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: getFullUrl(urlValue) },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    const currentSelection = newEditorState.getSelection();
    const selectionWithoutFocus = currentSelection.set('hasFocus', false);

    onChange(
      RichUtils.toggleLink(newEditorState, selectionWithoutFocus, entityKey),
    );
    setShowUrlInput(false);
    setUrlValue('');
  };

  const handleLinkKeyPress = (e) => {
    if (e?.charCode === 13 || e?.key === 'Enter') {
      insertLink();
    }
  };

  // TODO: hack for hover styling
  const onClickPlaceholder = () => { };

  const controls = disabled ? {} : {
    handleAddLink,
  };

  return (
    <div className={classNames('rich-editor-controls', smallControls ? 'small' : '', disabled ? 'disabled' : '')}>
      <div className='editor-container'>
        {/* Children render props pattern to pass children methods to parent */}
        {children({ controls })}
      </div>
      <Menu className='controls-container'>
        <Menu.Item
          active={currentInlineStyle.has('BOLD')}
          onMouseDown={inlineStyleHandler('BOLD')}
          onClick={onClickPlaceholder} // TODO: hack for hover styling
          className='control-item'
        >
          <PopupIcon name='bold' content='Bold (Ctrl-B)' />
        </Menu.Item>

        <Menu.Item
          active={currentInlineStyle.has('ITALIC')}
          onMouseDown={inlineStyleHandler('ITALIC')}
          onClick={onClickPlaceholder}
          className='control-item'
        >
          <PopupIcon name='italic' content='Italic (Ctrl-I)' />
        </Menu.Item>

        <Menu.Item
          active={currentInlineStyle.has('UNDERLINE')}
          onMouseDown={inlineStyleHandler('UNDERLINE')}
          onClick={onClickPlaceholder}
          className='control-item'
        >
          <PopupIcon name='underline' content='Underline (Ctrl-U)' />
        </Menu.Item>

        <Menu.Item
          active={blockType === 'unordered-list-item'}
          onMouseDown={blockTypeHandler('unordered-list-item')}
          onClick={onClickPlaceholder}
          className='control-item'
        >
          <PopupIcon name='unordered list' content='Bulleted List' />
        </Menu.Item>

        <Menu.Item
          active={blockType === 'ordered-list-item'}
          onMouseDown={blockTypeHandler('ordered-list-item')}
          onClick={onClickPlaceholder}
          className='control-item'
        >
          <PopupIcon name='ordered list' content='Numbered List' />
        </Menu.Item>

        {showUrlInput ? (
          <Menu.Item>
            <Input
              ref={linkInputRef}
              value={urlValue}
              onChange={handleLinkChange}
              onKeyPress={handleLinkKeyPress}
              iconPosition='left'
              icon='linkify'
              placeholder='https://site.com'
              action={{
                className: 'url-input-action',
                icon: <i className='ri-check-line' />,
                onMouseDown: insertLink,
              }}
            />
          </Menu.Item>
        ) : (
          <Menu.Item
            active={!!getEntityKeyInSelection('LINK')}
            onMouseDown={handleAddLink}
            onClick={onClickPlaceholder}
            className='editor-toolbar-item'
          >
            <PopupIcon name='linkify' content='Insert Link (Ctrl-K)' />
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};

const PopupIcon = ({ name, content }) => {
  return (
    <Popup
      trigger={
        <Icon className='control-icon' name={name} size='small' />
      }
      content={content}
      size='mini'
      inverted
      position='top center'
    />
  );
};

const getFullUrl = (url) =>
  url.match(/^https?:\/\//i) ? url : `https://${url}`;

export default RichEditorControls;
