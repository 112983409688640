import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';
import withNotifications from '@/hocs/clients/withNotifications';
import { sanitizeTypename } from '@/common/utils/apollo';
import SettingsLayout from '../../SettingsLayout';
import DepartmentEditModal from './DepartmentEditModal';
import withAddClientDepartment from '../../../hocs/clients/withAddClientDepartment';
import withUpdateClientDepartment from '../../../hocs/clients/withUpdateClientDepartment';
import withClient from '../../../hocs/clients/withClient';
import withMoveClientDepartmentUpward from '../../../hocs/clients/withMoveClientDepartmentUpward';
import withMoveClientDepartmentDownward from '../../../hocs/clients/withMoveClientDepartmentDownward';
import withDeleteClientDepartment from '../../../hocs/clients/withDeleteClientDepartment';

import './DepartmentsSettings.css';
import DepartmentTable from './DepartmentTable';
import DepartmentEditNaming from './DepartmentEditNaming';

class DepartmentsSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: null,
    };
  }

  handleCloseModal = () => {
    this.setState({ modal: null });
  };

  handleEditDepartment = async ({ departmentId, newValue }) => {
    const { updateClientDepartment } = this.props;
    await updateClientDepartment(
      sanitizeTypename({
        departmentId,
        department: {
          title: newValue.title,
          ...(newValue.sections && {
            sections: _.map(newValue.sections, (section) =>
              _.omit(section, '__typename'),
            ),
          }),
        },
      }),
    );
    this.setState({ modal: null });
  };

  handleAskEditDepartment = ({ departmentId }) => {
    const { client } = this.props || {};
    const { departments } = client || {};
    const department = _.findWhere(departments, { id: departmentId });
    this.setState({
      modal: {
        type: 'edit-department',
        departmentId,
        initialValue: department,
      },
    });
  };

  handleAskAddDepartment = () => {
    this.setState({
      modal: {
        type: 'add-department',
      },
    });
  };

  handleAddDepartment = async ({ department }) => {
    const { addClientDepartment } = this.props;
    await addClientDepartment({
      department: {
        title: department.title,
        ...(department.sections && { sections: department.sections }),
      },
    });

    this.setState({ modal: null });
  };

  handleRemoveDepartment = async ({ departmentId }) => {
    const { deleteClientDepartment, notifications } = this.props;
    const queryOptions = {
      onError: (error) => notifications.error(error.message),
    };
    await deleteClientDepartment({ departmentId, queryOptions });
  };

  handleMoveDepartmentUpward = async ({ departmentId }) => {
    const { client, moveClientDepartmentUpward } = this.props || {};
    const { departments } = client || {};
    const index = _.findIndex(departments, { id: departmentId });
    if (index < 1) {
      return;
    }
    await moveClientDepartmentUpward({ departmentId });
  };

  handleMoveDepartmentDownward = async ({ departmentId }) => {
    const { client, moveClientDepartmentDownward } = this.props || {};
    const { departments } = client || {};
    const index = _.findIndex(departments, { id: departmentId });
    if (index < 0 || index + 1 >= departments.length) {
      return;
    }
    await moveClientDepartmentDownward({ departmentId });
  };

  render() {
    const { client, clientId, t } = this.props;
    const { modal } = this.state;
    const { departments } = client || [];
    return (
      <SettingsLayout
        clientId={clientId}
        currentPage='departments'
        className='settings-container'
      >
        <div className='settings departments-page'>
          <div className='settings-header'>
            <h1>{t('departments.header')}</h1>
          </div>
          <h2>{t('settings.configuration')}</h2>
          <div className='section visible'>
            <div className='description'>
              {t('settings.departments.description')}
            </div>
            <div className='departments-form'>
              <DepartmentTable
                departments={departments}
                onEdit={this.handleAskEditDepartment}
                onRemove={this.handleRemoveDepartment}
                onMoveDownward={this.handleMoveDepartmentDownward}
                onMoveUpward={this.handleMoveDepartmentUpward}
              />
              <div className='add-department-container'>
                <GenericButton onClick={() => this.handleAskAddDepartment()}>
                  <Plus />
                  {t('settings.departments.add')}
                </GenericButton>
              </div>
            </div>
            <DepartmentEditNaming />
          </div>
        </div>

        {modal?.type === 'edit-department' ? (
          <DepartmentEditModal
            mode='edit'
            initialValue={modal.initialValue}
            onClose={this.handleCloseModal}
            onSubmit={({ department }) => {
              this.handleEditDepartment({
                departmentId: modal?.departmentId,
                newValue: department,
              });
            }}
            t={t}
          />
        ) : (
          ''
        )}
        {modal?.type === 'add-department' ? (
          <DepartmentEditModal
            mode='create'
            onClose={this.handleCloseModal}
            onSubmit={({ department }) => {
              this.handleAddDepartment({ department });
            }}
            t={t}
          />
        ) : (
          ''
        )}
      </SettingsLayout>
    );
  }
}

export default compose(
  withClient,
  withNotifications,
  withAddClientDepartment,
  withUpdateClientDepartment,
  withMoveClientDepartmentUpward,
  withMoveClientDepartmentDownward,
  withDeleteClientDepartment,
  withTranslation('translations'),
)(DepartmentsSettings);
