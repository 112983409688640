import React, { useMemo } from 'react';
import MissionDropdownEmptyState from '@/revealComponents/ProfileProjectsTab/NewProfileMissionsManagement/EmptyState/MissionDropdownEmptyState';
import useClientId from '@/hooks/router/useClientId';
import { useTranslation } from 'react-i18next';
import { GenericDropdownProps } from '@/components/Common/GenericDropdown/GenericDropdown';
import _ from 'underscore';
import { ProjectDropdownTrigger } from '@/components/Common/ProjectDropdown/ProjectDropdown';
import styles from '@/components/Common/ProjectDropdown/ProjectDropdown.module.less';
import TreeDropdown from './TreeDropdown';
import { useProjectTreeDropdownContext } from './ProjectTreeDropdownContext';

const ProjectTreeDropdown: React.FC<Partial<GenericDropdownProps>> = ({
  ...rest
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const {
    selectedGroupKey,
    selectedFilterKey,
    selectedSortKey,
    onFilterChange,
    onGroupChange,
    onSortChange,
    selectedNodeIds = [],
    items = [],
    tree,
    onUpdateTree,
    selectedItemIds,
    totalItemCount,
  } = useProjectTreeDropdownContext();

  const SORT_BY_ITEMS = useMemo(
    () => [
      {
        key: 'name',
        label: t('common.sorting.alphabetically'),
      },
      {
        key: 'author',
        label: t('profile.contact.sorting.byOwner'),
      },
      {
        key: 'creation-date',
        label: t('common.sorting.creationDate'),
      },
      {
        key: 'last-use-by-me',
        label: t('common.sorting.lastUseByMe'),
      },
    ],
    [t],
  );

  const FILTER_BY_ITEMS = useMemo(
    () => [
      {
        key: 'all-projects',
        label: t('profile.contact.filtering.allProjects'),
      },
      {
        key: 'created-by-me',
        label: t('profile.contact.filtering.projectsCreatedByMe'),
      },
    ],
    [t],
  );

  const placeholder = useMemo(
    () =>
      items.length <= (selectedItemIds || []).length
        ? t('profile.contact.filtering.allProjects')
        : t('profile.contact.filtering.chooseProject'),
    [items, selectedItemIds, t],
  );

  return (
    <TreeDropdown
      selectedNodeIds={selectedNodeIds}
      totalItemCount={totalItemCount}
      treeGroup={{
        groupId: 'first-group',
        groupLabel: t(`reveal.overview.groupNames.${selectedFilterKey}`),
        sortBy: SORT_BY_ITEMS,
        filterBy: FILTER_BY_ITEMS,
        groupBy: [
          {
            key: 'departments',
            label: t('reveal.missions.columnSelectors.departments'),
          },
          {
            key: 'author',
            label: t('profile.contact.sorting.byOwner'),
          },
          {
            key: 'not-grouped',
            label: t('common.grouping.noGroup'),
          },
        ],
        currentFilterKey: selectedFilterKey,
        currentSortKey: selectedSortKey,
        currentGroupKey: selectedGroupKey,
        tree,
      }}
      emptyState={<MissionDropdownEmptyState clientId={clientId} />}
      onUpdateTree={(newTree) => {
        if (onUpdateTree) {
          onUpdateTree(newTree);
        }
      }}
      Trigger={({ onClick }) => (
        <button type='button' onClick={onClick} className={styles.trigger}>
          <ProjectDropdownTrigger
            currentMission={_.findWhere(items, { id: selectedItemIds?.[0] })}
            placeholder={placeholder}
            clearable={false}
            onClear={() => ({})}
            multiSelect
            selectedItems={selectedItemIds}
            totalItemCount={(items || []).length || 0}
          />
        </button>
      )}
      style={{ ...rest?.style }}
      searchPlaceholder={t('missions.search.placeholder')}
      onGroupFiltered={(_groupId, key) => onFilterChange(key || '')}
      onGroupSorted={(_groupId, key) => onSortChange(key || '')}
      onGroupGrouped={(_groupId, key) => {
        if (key) {
          onGroupChange(key);
        }
      }}
      className={styles.dropdown}
    />
  );
};

export default ProjectTreeDropdown;
