import gql from 'graphql-tag';

export default gql`
  fragment MessageFragment on Message {
    id: templateId
    contactFlowActionId
    body
    subject
  }
`;
