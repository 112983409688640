import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import OfferRecruiterConfiguration from '../../graphql/fragments/OfferRecruiterConfiguration';

export const getRecruiterConfiguration = gql`
  query getOfferRecruiterConfiguration($id: ID!) {
    offer(id: $id) {
      id
      recruiterConfigurations {
        ...OfferRecruiterConfiguration
      }
    }
  }
  ${OfferRecruiterConfiguration}
`;

export default graphql(getRecruiterConfiguration, {
  options: ({ offerId }) => ({
    variables: { id: offerId },
  }),
  props: ({ data: { loading, error, offer } }) => {
    // filtered by current user in back-end
    const recruiterConfigurations = offer?.recruiterConfigurations || [];
    return {
      loading,
      error,
      recruiterConfiguration: recruiterConfigurations[0],
    };
  },
});
