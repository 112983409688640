import gql from 'graphql-tag';
import TranslatableText from './TranslatableText';
import Skill from './Skill';
import SweetDate from './SweetDate';
import TextWithEnrichments from './TextWithEnrichments';
import TextWithEnrichmentsAndStack from './TextWithEnrichmentsAndStack';
import TranslatableTextWithEnrichments from './TranslatableTextWithEnrichments';
import RelevantTag from './RelevantTag';
import { ApplicationForm } from './Applications';
import Sources from './Sources';
import Workplace from './Workplace';
import CustomFieldsValues from './CustomFieldsValues';
import SourceData from './SourceData';

export default gql`
  fragment RevealResumeData on ProfileResumeData {
    photoLink
    firstname
    lastname
    email
    email2
    email3
    phoneNumber
    phoneNumber2
    phoneNumber3
    hidePersonnalData
    gender {
      value
      isLocked
    }
    customFields {
      ...CustomFieldsValues
    }
    headline {
      ...TextWithEnrichmentsAndStack
    }
    location {
      name {
        ...TranslatableText
      }
    }
    prequalification {
      type
    }
    summary {
      ...TextWithEnrichmentsAndStack
    }
    sources {
      ...Sources
    }
    relevantActivities
    relevantTags {
      ...RelevantTag
    }
    application {
      questionsForm {
        ...ApplicationForm
      }
    }
    experiences {
      location {
        name {
          ...TranslatableText
        }
      }
      title {
        text
      }
      description {
        text
      }
      startDate {
        ...SweetDate
      }
      endDate {
        ...SweetDate
      }
      photoLink
      companyName
      companyWebsite
      stack {
        ...Skill
      }
      workplace {
        ...Workplace
      }
    }
    education {
      schoolName
      degree {
        text
      }
      startDate {
        ...SweetDate
      }
      endDate {
        ...SweetDate
      }
      photoLink
      schoolWebsite
      stack {
        ...Skill
      }
    }
    mainEducation {
      schoolName
      startDate {
        ...SweetDate
      }
      endDate {
        ...SweetDate
      }
    }
    sourceData {
      ...SourceData
    }
    sourceName
    smartSummary {
      arguments {
        type
        ... on GroupedKeyPointsArgument {
          groups {
            category
            keyPoints {
              label {
                ...TranslatableText
              }
              content {
                ...TranslatableText
              }
              popupContent {
                ...TranslatableText
              }
            }
          }
        }
        ... on ReviewArgument {
          stack {
            ...Skill
          }
          title {
            ...TranslatableText
          }
          content {
            ...TranslatableText
          }
        }
      }
    }
    enrichmentInfo {
      lastEnrichmentDate
    }
  }
  ${TranslatableText}
  ${Skill}
  ${SweetDate}
  ${TextWithEnrichments}
  ${TextWithEnrichmentsAndStack}
  ${TranslatableTextWithEnrichments}
  ${RelevantTag}
  ${ApplicationForm}
  ${Sources}
  ${Workplace}
  ${CustomFieldsValues}
  ${SourceData}
`;
