import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Offer } from '@/types/offer';
import { JobSuspendedIcon, JobInReviewIcon } from '@/assets/icons';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import useClientId from '@/hooks/router/useClientId';
import logAction from '@/common/logAction';
import ChangeOfferStateModalWrapper from '../../../components/ChangeOfferStateModalWrapper';
import OfferLabel from '../../../components/OfferLabel';

interface OfferActiveLabelProps {
  offer: Offer;
}

const OfferActiveLabel: FC<OfferActiveLabelProps> = ({ offer }) => {
  const clientId = useClientId();
  const { t } = useTranslation();
  const [modalType, setModalType] = useState<string>();

  const { watchCollect, watchCollectV2 } =
    useClientPermissions(clientId).data?.client?.permissions || {};
  const watchCollectPermission = watchCollect || watchCollectV2 || false;

  const offerIsActive =
    !offer?.isWaitingForValidation && !offer?.isArchived && !offer?.isHold;

  const onSetModalType = (type: string) => {
    if (offer?.id) setModalType(type);
    logAction({ type: `click-offer-tag-${type}` });
  };

  return (
    <button
      type='button'
      onClick={(e) => e.stopPropagation()}
      style={{ margin: 0, padding: 0 }}
    >
      {offer.isWaitingForValidation && (
        <OfferLabel
          className='waiting-for-validation'
          text={t('offers.waitingForValidation')}
          icon={<JobInReviewIcon className='job-suspended-icon' />}
          content={t('offers.waitingForValidationDetails')}
        />
      )}
      {!offer.isArchived && offer.isHold && (
        <OfferLabel
          className='hold'
          content={t('offers.isHoldDetails')}
          icon={<JobSuspendedIcon className='job-suspended-icon' />}
          onClick={() => onSetModalType('unhold')}
        />
      )}
      {offerIsActive && (
        <OfferLabel
          className='job-active'
          icon={<i className='ri-checkbox-blank-circle-fill job-active-icon' />}
          content={watchCollectPermission && t('offers.upcomingNextBatch')}
          onClick={() => onSetModalType('hold')}
        />
      )}
      <ChangeOfferStateModalWrapper
        offer={offer}
        clientId={clientId}
        modalType={modalType}
        onCloseModal={() => setModalType(undefined)}
      />
    </button>
  );
};

export default OfferActiveLabel;
