import React from 'react';
import classNames from 'classnames';
import { Icon } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { passwordIsLongEnough, passwordHasBothUpperAndLowerCaseCharacters, passwordHasDigits } from '../../common';

const PasswordChecks = ({ password, inputHasFocus, t }) => {
  return (
    <div className={classNames('password-checks', (password || inputHasFocus) && 'active')}>
      {(password || inputHasFocus) && (
        <>
          <div className={passwordIsLongEnough(password) ? 'valid' : 'invalid'}>
            {passwordIsLongEnough(password) ? (
              <Icon name='check circle outline' />
            ) : (
              <Icon name='times circle outline' />
            )}
            {t('signup.passwordShouldBeLong')}
          </div>
          <div className={passwordHasBothUpperAndLowerCaseCharacters(password) ? 'valid' : 'invalid'}>
            {passwordHasBothUpperAndLowerCaseCharacters(password) ? (
              <Icon name='check circle outline' />
            ) : (
              <Icon name='times circle outline' />
            )}
            {t('signup.passwordShouldIncludeUpperAndLowerCase')}
          </div>
          <div className={passwordHasDigits(password) ? 'valid' : 'invalid'}>
            {passwordHasDigits(password) ? <Icon name='check circle outline' /> : <Icon name='times circle outline' />}
            {t('signup.passwordShouldIncludeDigits')}
          </div>
        </>
      )}
    </div>
  );
};

export default withTranslation('translations')(PasswordChecks);
