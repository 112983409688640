import { gql, useMutation } from '@apollo/client';

const UPDATE_CLIENT_PROJECT_NOMENCLATURE = gql`
  mutation updateClientProjectNomenclature(
    $input: UpdateProjectNomenclatureInput!
  ) {
    updateProjectNomenclature(input: $input) {
      id
      projectNomenclatures {
        missionCategory {
          type
        }
        successStep {
          id
        }
      }
    }
  }
`;

export function useUpdateClientProjectNomenclature() {
  return useMutation(UPDATE_CLIENT_PROJECT_NOMENCLATURE);
}
