import _ from 'underscore';
import React from 'react';
import {
  HiresweetLibProvider,
  ProfileFirstname,
  ProfileLastname,
  ProfilesSetContainer,
  ProfilesTable,
} from '@hiresweet/hiresweet-lib';
import useClientId from '@/hooks/router/useClientId';

const Playground10 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30 }}>
      <h1>P10 - Profiles - Paginated Table</h1>
      <div>
        <HiresweetLibProvider theme={theme} clientId={clientId}>
          <ProfilesSetContainer
            filter={{
              type: 'project-step',
              projectId: 'ri7-demo-testimport2-UMODD2',
              step: 'all',
            }}
          >
            <ProfilesTable pageSize={5}>
              <ProfilesTable.Header>
                <ProfilesTable.HeaderCell>Firstname</ProfilesTable.HeaderCell>
                <ProfilesTable.HeaderCell>Lastname</ProfilesTable.HeaderCell>
              </ProfilesTable.Header>

              <ProfilesTable.Body>
                <ProfilesTable.RowMap>
                  <ProfilesTable.Cell>
                    <ProfileFirstname />
                  </ProfilesTable.Cell>
                  <ProfilesTable.Cell>
                    <ProfileLastname />
                  </ProfilesTable.Cell>
                </ProfilesTable.RowMap>
              </ProfilesTable.Body>
            </ProfilesTable>
          </ProfilesSetContainer>
        </HiresweetLibProvider>
      </div>
    </div>
  );
};

export default Playground10;
