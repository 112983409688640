import React, {
  ForwardRefRenderFunction,
  ComponentProps,
  forwardRef,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';

import ButtonLink from '@/components/Common/GenericButton/ButtonLink';
import useClientId from '@/hooks/router/useClientId';
import usePollFreeTrialConfiguration from '@/graphql/hooks/clients/usePollFreeTrialConfiguration';

import FreeTrialUpgradeModalContext from '../FreeTrialUpgradeModalContextProvider/FreeTrialUpgradeModalContext';

type PaymentLinkProps = Partial<ComponentProps<typeof ButtonLink>>;

const ForwardedPaymentLink: ForwardRefRenderFunction<
  HTMLAnchorElement,
  PaymentLinkProps
> = ({ to, onClick, ...props }, ref) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const {
    startPolling,
    freeTrialConfiguration,
  } = usePollFreeTrialConfiguration();
  const { openFreeTrialUpgradeModal } = useContext(
    FreeTrialUpgradeModalContext,
  );

  useEffect(() => {
    if (freeTrialConfiguration.upgraded && openFreeTrialUpgradeModal) {
      openFreeTrialUpgradeModal();
    }
  }, [freeTrialConfiguration, openFreeTrialUpgradeModal]);

  return (
    <ButtonLink
      to={
        to || {      
          pathname: `https://buy.stripe.com/6oE9Cy3hjccE5ZCdRA?client_reference_id=${clientId}`,
        }
      }
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
        startPolling();
      }}
      target='_blank'
      ref={ref}
      {...props}
    >
      {t('reveal.freeTrial.banner.upgrade')}
    </ButtonLink>
  );
};

const PaymentLink = forwardRef(ForwardedPaymentLink);

export default PaymentLink;
