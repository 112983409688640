import {
  ExclusiveTaskType,
  DisplayedTaskType,
} from '@/types/statistics/clientStatistics';

export type TaskTypeFilter =
  | { exclusive: false; value: DisplayedTaskType }
  | { exclusive: true; value: ExclusiveTaskType };

interface TaskTypeFilterCases<T> {
  inclusive: (taskType: DisplayedTaskType) => T;
  exclusive: (taskType: ExclusiveTaskType) => T;
}

export const caseOf = <T>(
  { exclusive, value }: TaskTypeFilter,
  cases: TaskTypeFilterCases<T>,
): T =>
  exclusive
    ? cases.exclusive(value as ExclusiveTaskType)
    : cases.inclusive(value as DisplayedTaskType);

export type PrefixFilter =
  | {
      type: 'topology';
      path: string;
    }
  | {
      type: 'step';
      index: number;
    };

export interface StatsFilters {
  taskType: TaskTypeFilter;
  sequenceId?: string;
  prefix?: PrefixFilter;
  startDay?: string;
  endDay?: string;
}
