import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import GenericCheckbox from '@/components/Common/GenericCheckbox';

import './FollowupButton.css';

interface FollowupButtonProps {
  onAddFollowup: () => void;
  followupPosition: number;
}

const FollowupButton: FC<FollowupButtonProps> = ({
  onAddFollowup,
  followupPosition,
}) => {
  const { t } = useTranslation();
  return (
    <button type='button' className='followup-button' onClick={onAddFollowup}>
      <div className='followup-button-header'>
        <GenericCheckbox />
        <i className='ri-ghost-smile-line' />
        <span>{t('profile.contact.drafts.addFollowup')}</span>
      </div>
      {followupPosition <= 2 && (
        <div className='followup-button-explanation'>
          {t('profile.contact.drafts.addFollowupExplanations')}
        </div>
      )}
    </button>
  );
};

export default FollowupButton;
