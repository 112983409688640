import gql from 'graphql-tag';
import Attachment from './Attachment';
import GreenhouseApplicationStatus from './GreenhouseApplicationStatus';
import GreenhouseTag from './GreenhouseTag';
import SweetDate from './SweetDate';
import TextWithEnrichmentsAndStack from './TextWithEnrichmentsAndStack';
import TranslatableText from './TranslatableText';
import Scorecard from './Scorecard';

export default gql`
  fragment GreenhouseData on GreenhouseData {
    type
    sourceCandidateId
    firstname
    lastname
    headline {
      ...TextWithEnrichmentsAndStack
    }
    currentCompany {
      name
    }
    creationDate
    lastUpdateDate
    lastActivityDate
    emails {
      type
      value
    }
    phoneNumbers {
      type
      value
    }
    addresses {
      type
      value
    }
    tags {
      ...GreenhouseTag
    }
    attachments {
      ...Attachment
      file {
        url
      }
    }
    recruiter {
      sourceUserId
      firstname
      lastname
    }
    coordinator {
      sourceUserId
      firstname
      lastname
    }
    education {
      degree {
        text
      }
      startDate {
        ...SweetDate
      }
      endDate {
        ...SweetDate
      }
    }
    experiences {
      title {
        text
      }
      startDate {
        ...SweetDate
      }
      endDate {
        ...SweetDate
      }
      companyName
    }
    applications {
      sourceApplicationId
      isProspect
      applicationDate
      lastActivityDate
      rejectionDate
      location {
        name {
          ...TranslatableText
        }
      }
      sourcingChannel {
        sourceSourcingChannelId
        name
      }
      creditedTo {
        sourceUserId
        firstname
        lastname
      }
      jobs {
        sourceJobId
        title
      }
      status {
        ...GreenhouseApplicationStatus
      }
      questionsForm {
        fields {
          type
          title
          value
        }
      }
      scorecards {
        ...Scorecard
      }
    }
    activityFeed {
      sourceActivityId
      type
      creationDate
      subject
      body
      to
      from
      user {
        sourceUserId
        firstname
        lastname
      }
      isPrivate
      visibility
    }
  }
  ${Attachment}
  ${SweetDate}
  ${Scorecard}
  ${TextWithEnrichmentsAndStack}
  ${TranslatableText}
  ${GreenhouseApplicationStatus}
  ${GreenhouseTag}
`;
