import React from 'react';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import contextToProps from '../../../../hocs/contextToProps';
import withPublishJobPosting from '../../../../hocs/jobPostings/withPublishJobPosting';
import withUnPublishJobPosting from '../../../../hocs/jobPostings/withUnPublishJobPosting';

import './JobPostingActions.less';

const JobPostingPublishActions = ({
  jobPosting,
  publishJobPosting,
  unPublishJobPosting,
  onShowNotification,
  t,
}) => {
  const handlePublish = async () => {
    try {
      await publishJobPosting({ input: { id: jobPosting.id } });
      onShowNotification({
        message: t('offers.jobPostings.actions.publishSuccess'),
        level: 'success',
      });
    } catch (e) {
      onShowNotification({
        message: t('offers.jobPostings.actions.publishError'),
        level: 'error',
      });
    }
  };
  const handleUnPublish = async () => {
    try {
      await unPublishJobPosting({ input: { id: jobPosting.id } });
      onShowNotification({
        message: t('offers.jobPostings.actions.hideSuccess'),
        level: 'success',
      });
    } catch (e) {
      onShowNotification({
        message: t('offers.jobPostings.actions.hideError'),
        level: 'error',
      });
    }
  };
  return (
    <div className='job-posting-actions'>
      <div className='section'>
        <h3>{t('offers.jobPostings.actions.hiresweetTips.title')}</h3>
        <div>{t('offers.jobPostings.actions.hiresweetTips.content')}</div>
      </div>
      {jobPosting.published ? (
        <Button secondary onClick={handleUnPublish}>
          {t('offers.jobPostings.actions.hide')}
        </Button>
      ) : (
        <Button primary onClick={handlePublish}>
          {t('offers.jobPostings.actions.publish')}
        </Button>
      )}
    </div>
  );
};

export default compose(
  withPublishJobPosting,
  withUnPublishJobPosting,
  contextToProps,
  withTranslation('translations'),
)(JobPostingPublishActions);
