import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import { PrefixTaskType } from '@/types/statistics/clientStatistics';
import { TASK_ICON_BY_TYPE } from '@/common/constants/taskTypes';
import { getRecordTotal } from '@/common/utils/record';
import ChevronRight from '@/components/Reveal/Icons/ChevronRight';
import { getTaskTypesFromCodeString } from '../../helpers';
import PrefixStatisticsRow from '../../PrefixStatisticsRow';

import styles from './PathRow.module.less';

interface PathRowProps {
  path: string;
  stats: Record<PrefixTaskType, number>;
  totalContacted: number;
  fetchDetails?: (taskType: PrefixTaskType) => void;
  isLinear: boolean;
}

const PathRow: FC<PathRowProps> = ({
  path,
  stats,
  totalContacted,
  fetchDetails,
  isLinear,
}) => {
  const { t } = useTranslation();

  const taskTypes = getTaskTypesFromCodeString(path);
  const lastType = _.last(taskTypes);
  const stepIndex = _.size(taskTypes) - 1;

  if (!lastType && getRecordTotal(stats) === 0) {
    return null;
  }

  return (
    <PrefixStatisticsRow
      stats={stats}
      fetchDetails={fetchDetails}
      totalContacted={totalContacted}
    >
      <div className={styles.path}>
        {!lastType ? (
          <span className={styles.name}>{t(`sequences.tasks.other`)}</span>
        ) : (
          <>
            {isLinear && <span className={styles.index}>{stepIndex}</span>}
            <span className={styles.icons}>
              {isLinear ? (
                <img
                  className={styles.icon}
                  src={`/images/icons/tasks/${
                    TASK_ICON_BY_TYPE[
                      lastType as keyof typeof TASK_ICON_BY_TYPE
                    ]
                  }.svg`}
                  alt={t(`sequences.tasks.${lastType}`)}
                  title={t(`sequences.tasks.${lastType}`)}
                />
              ) : (
                _.map(_.rest(taskTypes), (taskType, index) => (
                  <Fragment key={`${index}_${taskType}`}>
                    {index !== 0 && (
                      <span className={styles.separator}>
                        <ChevronRight />
                      </span>
                    )}
                    <img
                      className={styles.icon}
                      src={`/images/icons/tasks/${
                        TASK_ICON_BY_TYPE[
                          taskType as keyof typeof TASK_ICON_BY_TYPE
                        ]
                      }.svg`}
                      alt={t(`sequences.tasks.${taskType}`)}
                      title={t(`sequences.tasks.${taskType}`)}
                    />
                  </Fragment>
                ))
              )}
            </span>
            <span className={styles.name}>
              {t(`sequences.tasks.${lastType}`)}
            </span>
          </>
        )}
      </div>
    </PrefixStatisticsRow>
  );
};

export default PathRow;
