import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

interface SequenceError {
  error: string;
  type: string;
  sequenceId: string;
  actionId: string;
  index: number;
  firstEmailIndex?: number;
  level: 'major' | 'minor';
}

interface SequenceErrorMessageProps {
  errors: SequenceError[];
}
const SequenceErrorMessages: React.FC<SequenceErrorMessageProps> = ({
  errors,
}) => {
  const { t } = useTranslation();
  if (_.isEmpty(errors)) {
    return null;
  }
  const firstEmailError = _.findWhere(errors, {
    error: 'first-email-must-be-manual',
  });
  const firstActionAutomaticError = _.findWhere(errors, {
    error: 'first-action-cannot-be-automatic',
  });
  const automaticManualActionError = _.findWhere(errors, {
    error: 'manual-action-cannot-be-automatic',
  });
  const firstActionCreatedImmediatelyError = _.findWhere(errors, {
    error: 'first-action-must-be-created-immediately',
  });
  const missingMessageError = _.findWhere(errors, {
    error: 'missing-message',
  });
  const missingLinkedinInvitationBeforeTriggerError = _.findWhere(errors, {
    error: 'missing-linkedin-invitation-before-invitation-accepted-trigger',
  });

  return (
    <div className='form-errors'>
      {firstEmailError && (
        <div className='details'>
          {t('sequences.errors.firstEmailMustBeManual', {
            position: firstEmailError?.index + 1,
          })}
        </div>
      )}

      {firstActionAutomaticError && (
        <div className='details'>
          {t('sequences.errors.firstActionCannotBeAutomatic')}
        </div>
      )}

      {automaticManualActionError && (
        <div className='details'>
          {t('sequences.errors.manualActionCannotBeAutomatic', {
            position: (automaticManualActionError?.index || 0) + 1,
          })}
        </div>
      )}

      {firstActionCreatedImmediatelyError && (
        <div className='details'>
          {t('sequences.errors.firstActionMustBeCreatedImmediatly', {
            position: firstActionCreatedImmediatelyError?.index + 1,
          })}
        </div>
      )}

      {!firstEmailError && missingMessageError && (
        <div className='details'>{t('sequences.errors.missingMessage')}</div>
      )}

      {missingLinkedinInvitationBeforeTriggerError && (
        <div className='details'>
          {t('sequences.errors.missingLinkedinInvitationBeforeTriggerError')}
        </div>
      )}
    </div>
  );
};

export default SequenceErrorMessages;
