const OfferRecipients = /* GraphQL */ `
  fragment OfferRecipients on Offer {
    id
    title
    isRecipient
    followers {
      email
    }
  }
`;

export default OfferRecipients;
