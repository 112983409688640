/* eslint-disable import/prefer-default-export */
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import ExpressionFirstCompanyEvaluator from './firstCompanyEvaluator';

const expressionEvaluatorFromType: Record<
  string,
  SweetEvaluatorTypes.EvaluatorInterface
> = {
  'first-company': new ExpressionFirstCompanyEvaluator(),
};

export const getExternalExpressionEvaluatorFromType = ({
  type,
}: {
  type: string;
}): SweetEvaluatorTypes.EvaluatorInterface => expressionEvaluatorFromType[type];
