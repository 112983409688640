import gql from 'graphql-tag';
import SearchPoolProfileState from './SearchPoolProfileState';
import FullResumeData from './FullResumeData';
import ContactData from './ContactData';
import ContactFlow from './ContactFlow';
import { EvaluationsSet } from './Evaluations';
import Attachment from './Attachment';

export default gql`
  fragment Profile on Profile {
    id
    creationTimestamp
    lastStepUpdateTimestamp
    email
    locked
    lockedSince
    contacted
    sent
    answerDetected
    isImported
    jobOfferId
    offer {
      id
      title
      isUnclassified
    }
    source {
      type
    }
    resumeData {
      ...FullResumeData
    }
    contactData {
      ...ContactData
    }
    contactFlow {
      ...ContactFlow
    }
    searchPoolState {
      ...SearchPoolProfileState
    }
    evaluationsSets {
      ...EvaluationsSet
    }
    attachments {
      ...Attachment
    }
    processStep {
      milestone {
        id
      }
    }
    annotation {
      globalFavorite {
        value
      }
      globalComment {
        value
      }
    }
    views {
      timeline {
        author {
          firstname
          lastname
          email
          photoLink
        }
        date
        timestamp
        origin {
          type
          URL
        }
      }
      statsByRecruiter {
        author {
          firstname
          lastname
          email
          photoLink
        }
        firstViewTimestamp
        lastViewTimestamp
        count
      }
    }
    talentStrategist {
      photoLink
      firstname
      email
    }
    powerHourFlags {
      powerHourId
      isFlagged
    }
    firstContactDate
  }
  ${ContactData}
  ${ContactFlow}
  ${FullResumeData}
  ${SearchPoolProfileState}
  ${EvaluationsSet}
  ${Attachment}
`;
