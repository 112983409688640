import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import styles from './DropdownContainer.module.less';

interface Props {
  className?: string;
  mini?: boolean;
}

const DropdownContainer = ({
  className,
  children,
  mini = false,
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={classNames(className, styles.dropdownContainer, {
        [styles.mini]: mini,
      })}
    >
      {children}
    </div>
  );
};

export default DropdownContainer;
