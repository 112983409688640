import React, { FC } from 'react';

import styles from './svg.module.less';

const Deal: FC = () => {
  return (
    <svg
      className={styles.svgIcon}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.41422 10.4142L5.58579 13.5858C6.36684 14.3668 7.63317 14.3668 8.41422 13.5858L13.9142 8.08579C14.2893 7.71071 14.5 7.20201 14.5 6.67157V2.5C14.5 1.94772 14.0523 1.5 13.5 1.5H9.32843C8.798 1.5 8.28929 1.71071 7.91422 2.08579L2.41422 7.58579C1.63317 8.36683 1.63317 9.63317 2.41422 10.4142ZM11.5 6C12.3284 6 13 5.32843 13 4.5C13 3.67157 12.3284 3 11.5 3C10.6716 3 10 3.67157 10 4.5C10 5.32843 10.6716 6 11.5 6Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Deal;
