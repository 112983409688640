import { useEffect, useState } from 'react';
import useUserFromJWToken from '@/graphql/hooks/users/useUserFromJWToken';
import useUpdateUserSettings from '@/graphql/hooks/users/useUpdateUserSettings';
import useClientId from '@/hooks/router/useClientId';
import { nbSteps } from './constants';

export const useOnboardingModalController = () => {
  const { data } = useUserFromJWToken();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (data?.user.firstLoginReveal) {
      setOpen(true);
    }
  }, [data]);

  const [step, setStep] = useState(0);
  const toNextStep = () => {
    if (step < nbSteps - 1) {
      setStep((prevStep) => prevStep + 1);
    }
  };
  const toPrevStep = () => {
    if (step > 0) {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const onClose = () => {
    setStep(0);
    setOpen(false);
  };

  const onOpen = () => {
    setStep(0);
    setOpen(true);
  };

  return {
    open,
    step,
    toNextStep,
    toPrevStep,
    onClose,
    onOpen,
  };
};

/**
 * will call the mutation on mount to update the settings
 */
export const useSetFinishedOnboardingOnUnmount = (): void => {
  const { data } = useUserFromJWToken();
  const clientId = useClientId();
  const [update, { called }] = useUpdateUserSettings();
  const firstLoginReveal = data?.user?.firstLoginReveal;
  useEffect(() => {
    if (firstLoginReveal === true && !called) {
      return () => {
        update({ variables: { clientId, input: { firstLoginReveal: false } } });
      };
    }
    return () => null;
  }, [update, firstLoginReveal, called, clientId]);
};
