import _ from 'underscore';
import { useCallback, useMemo } from 'react';
import gql from 'graphql-tag';
import {
  QueryResult,
  useMutation,
  useQuery,
  FetchResult,
  QueryHookOptions,
  MutationHookOptions,
  MutationFunctionOptions,
} from '@apollo/client';

import { UserDisplayPreferences } from '@/graphql/fragments/UserDisplayPreferences';
import { sanitizeTypename } from '@/common/utils/apollo';

const GET_USER_DISPLAY_PREFERENCES = gql`
  query getUserDisplayPreferences {
    user {
      id
      email
      displayPreferences {
        ...UserDisplayPreferences
      }
    }
  }

  ${UserDisplayPreferences}
`;

const UPDATE_TABLE_VIEW_MUTATION = gql`
  mutation updateTableViewPreferences($input: UserDisplayTableViewsInput!) {
    updateTableViewPreferences(input: $input) {
      id
      displayPreferences {
        ...UserDisplayPreferences
      }
    }
  }
  ${UserDisplayPreferences}
`;

type ObjectLiteral = Record<string, unknown>;

export type ColumnDisplay = {
  id: string;
  hidden: boolean;
  targetOrderIndex: number | null;
  available: boolean;
  name?: string;
};

export type GenericViewType = {
  tableId?: string;
  selectorId?: string;
  filteringOptions?: {
    filters: {
      key: string;
      filter: ObjectLiteral;
    }[];
  };
  sortingOptions?: {
    sortBy: {
      key: string;
      order: 'ascending' | 'descending';
    }[];
  };
  groupingOptions?: {
    groupBy: {
      key: string;
      group: 'departments' | 'not-grouped' | 'author';
    }[];
  };
  columnsDisplay?: {
    columns: ColumnDisplay[];
  };
  selectedItemIds?: string[];
  extraParameters?: {
    key: string;
    value: string;
  }[];
};

type UserDisplayPreferencesType = {
  tableViews: GenericViewType[];
  selectors: GenericViewType[];
};

type DisplayPreferencesQuery = {
  user: {
    id: string;
    displayPreferences: {
      tableViews: GenericViewType[];
    };
  };
};

type UpdateExtraParameterFunction<ExtraParameterType> = <
  Key extends keyof ExtraParameterType,
>(
  key: Key,
  value: ExtraParameterType[Key],
) => void;

type UseUserTableViewPreferencesReturnType<ExtraParameterType> = QueryResult & {
  updateUserTableViewPreferences: (
    variables?: GenericViewType,
    options?: ObjectLiteral,
  ) => Promise<FetchResult<GenericViewType>>;
  userTableViewPreferences: GenericViewType | null;
  extraParameters: ExtraParameterType | null;
  updateExtraParameter: UpdateExtraParameterFunction<ExtraParameterType>;
};

type QueryOptionsArgument = {
  queryOptions?: QueryHookOptions;
  mutationOptions?: MutationHookOptions;
};

export function useUserDisplayPreferences({
  queryOptions = {},
}: QueryOptionsArgument = {}): QueryResult & {
  userId?: string;
  userDisplayPreferences?: UserDisplayPreferencesType;
} {
  const query = useQuery<DisplayPreferencesQuery>(
    GET_USER_DISPLAY_PREFERENCES,
    {
      fetchPolicy: 'network-only',
      ...queryOptions,
    },
  );

  const sanitizedUserPreferences = useMemo(
    () => sanitizeTypename(query.data?.user?.displayPreferences),
    [query.data],
  );

  return {
    userId: query.data?.user?.id,
    userDisplayPreferences: sanitizedUserPreferences,
    ...query,
  };
}

type UseUserTableViewPreferencesArguments = {
  tableId: string;
  defaultValues?: GenericViewType;
};

export default function useUserTableViewPreferences<
  ExtraParameterType extends Record<string, unknown>,
>(
  { tableId, defaultValues = {} }: UseUserTableViewPreferencesArguments,
  { queryOptions = {} }: QueryOptionsArgument = {},
): UseUserTableViewPreferencesReturnType<ExtraParameterType> {
  const { userId, userDisplayPreferences, ...query } =
    useUserDisplayPreferences({
      queryOptions,
    });

  const [mutation] = useMutation<GenericViewType>(UPDATE_TABLE_VIEW_MUTATION);

  const updateUserTableViewPreferences = useCallback(
    (input: GenericViewType = {}, options: MutationFunctionOptions = {}) => {
      return mutation({
        variables: {
          input: {
            tableId,
            ...input,
          },
        },
        ...options,
      });
    },
    [mutation, tableId],
  );

  const currentTableViewPreferences = useMemo(() => {
    return (
      _.findWhere(userDisplayPreferences?.tableViews || [], {
        tableId,
      }) || defaultValues
    );
  }, [userDisplayPreferences, defaultValues, tableId]);

  const currentExtraParameters = useMemo<ExtraParameterType>(() => {
    return _.reduce(
      currentTableViewPreferences?.extraParameters || [],
      (accumulator, { key, value }) => {
        let parsedValue;
        try {
          parsedValue = JSON.parse(value);
        } catch (e) {
          // Little risk that parent component will not understand the value
          // but should not really happen, just a precaution
          parsedValue = value;
        }

        return {
          ...accumulator,
          [key]: parsedValue,
        };
      },
      {} as ExtraParameterType,
    );
  }, [currentTableViewPreferences]);

  const updateExtraParameter = useCallback<
    UpdateExtraParameterFunction<ExtraParameterType>
  >(
    (userKey, userValue) => {
      const newExtraParameters: ExtraParameterType = {
        ...currentExtraParameters,
        [userKey]: userValue,
      };
      const extraParametersArray = _.map(newExtraParameters, (value, key) => ({
        key,
        value: JSON.stringify(value),
      }));

      return updateUserTableViewPreferences(
        {
          extraParameters: extraParametersArray,
        },
        {
          optimisticResponse: {
            updateTableViewPreferences: {
              id: userId,
              __typename: 'User',
              displayPreferences: {
                __typename: 'UserDisplayPreferences',
                tableViews: [
                  {
                    tableId,
                    __typename: 'UserDisplayTableViewPreferences',
                    extraParameters: [
                      ...extraParametersArray,
                      {
                        key: userKey,
                        value: JSON.stringify(userValue),
                        __typename: 'UserDisplayTableViewExtraParameters',
                      },
                    ],
                  },
                ],
              },
            },
          },
        },
      );
    },
    [currentExtraParameters, updateUserTableViewPreferences, userId, tableId],
  );

  if (query.loading) {
    return {
      ...query,
      updateUserTableViewPreferences,
      userTableViewPreferences: null,
      extraParameters: null,
      updateExtraParameter,
    };
  }

  return {
    ...query,
    updateUserTableViewPreferences,
    userTableViewPreferences: currentTableViewPreferences,
    extraParameters: currentExtraParameters,
    updateExtraParameter,
  };
}

type UseUserSelectorPreferencesReturnType = QueryResult & {
  updateUserSelectorPreferences: (
    variables?: GenericViewType,
    options?: ObjectLiteral,
  ) => Promise<FetchResult<GenericViewType>>;
  userSelectorPreferences: GenericViewType | null;
};

type UseUserSelectorPreferencesArguments = {
  selectorId: string;
  defaultValues?: GenericViewType;
};

const UPDATE_SELECTOR_MUTATION = gql`
  mutation updateSelectorsPreferences($input: UserDisplaySelectorInput!) {
    updateSelectorsPreferences(input: $input) {
      id
      displayPreferences {
        ...UserDisplayPreferences
      }
    }
  }
  ${UserDisplayPreferences}
`;

export function useUserSelectorPreferences(
  { selectorId, defaultValues = {} }: UseUserSelectorPreferencesArguments,
  { queryOptions = {}, mutationOptions = {} }: QueryOptionsArgument = {},
): UseUserSelectorPreferencesReturnType {
  const { userDisplayPreferences, ...query } = useUserDisplayPreferences({
    queryOptions,
  });

  const [mutation] = useMutation<GenericViewType>(
    UPDATE_SELECTOR_MUTATION,
    mutationOptions,
  );

  const updateUserSelectorPreferences = useCallback(
    (input: GenericViewType = {}, options: ObjectLiteral = {}) => {
      return mutation({
        variables: {
          input: {
            selectorId,
            ...input,
          },
        },
        ...options,
      });
    },
    [mutation, selectorId],
  );

  if (query.loading) {
    return {
      ...query,
      updateUserSelectorPreferences,
      userSelectorPreferences: null,
    };
  }

  const currentSelectorPreferences =
    _.findWhere(userDisplayPreferences?.selectors || [], {
      selectorId,
    }) || defaultValues;

  return {
    ...query,
    updateUserSelectorPreferences,
    userSelectorPreferences: currentSelectorPreferences,
  };
}

const isNil = (v: unknown) => v === null || v === undefined;

export const sortColumns = (columns: ColumnDisplay[]) => {
  return [...columns].sort((ca, cb) => {
    const aTargetOrderIndex = ca.targetOrderIndex;
    const bTargetOrderIndex = cb.targetOrderIndex;
    if (isNil(aTargetOrderIndex) && isNil(bTargetOrderIndex)) {
      return 0;
    }

    if (isNil(aTargetOrderIndex)) {
      return 1;
    }

    if (isNil(bTargetOrderIndex)) {
      return -1;
    }

    return aTargetOrderIndex - bTargetOrderIndex;
  });
};
