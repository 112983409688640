import React, { ComponentProps, FC } from 'react';

import styles from './GenericSlider.module.less';

type RangeInputProps = ComponentProps<'input'> & { type: 'range' };

type Props = Omit<RangeInputProps, 'type'> & {
  onValue: (value: number) => void;
  ValueDisplay?: FC<{ value: number }>;
};

function GenericSlider({
  onChange,
  onValue,
  min = 0,
  max = 100,
  ValueDisplay = DefaultValueDisplay,
  value,
  ...props
}: Props) {
  const valueRatio = value ? (+value - +min) / (+max - +min) : 0;
  return (
    <div className={styles.container}>
      <div className={styles.valueContainer}>
        <div
          className={styles.value}
          style={{
            left: `${valueRatio * 100}%`,
          }}
        >
          {value && <ValueDisplay value={+value} />}
        </div>
      </div>
      <div className={styles.slider}>
        <div
          className={styles.progress}
          style={{ width: `${valueRatio * 100}%` }}
        />
        <input
          className={styles.input}
          type='range'
          value={value}
          min={min}
          max={max}
          onChange={(e) => {
            onValue(+e.target.value);
            if (onChange) {
              onChange(e);
            }
          }}
          {...props}
        />
      </div>
    </div>
  );
}

function DefaultValueDisplay({ value }: { value: number }) {
  return `${value}`;
}

export default GenericSlider;
