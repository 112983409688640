import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import withUpdateCareerPage from '@/hocs/clients/withUpdateCareerPage';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import GenericButton from '@/components/Common/GenericButton';
import { sanitizeTypename } from '@/common/utils/apollo';
import withClient from '../../../hocs/clients/withClient';
import withClientCareerPages from '../../../hocs/clients/withClientCareerPages';
import withClientDepartments from '../../../hocs/clients/withClientDepartments';
import withClientOffers from '../../../hocs/offers/withClientOffers';
import { getCareerPageInput } from './helpers';
import CareerPage from './CareerPage';
import ApplicationForms from './ApplicationForms';
import JobPostings from './JobPostings';

import SettingsLayout from '../../SettingsLayout';
import RevealJobPostings from './RevealJobPostings';
import CareerPageDescriptionModal from './CareerPageDescription/DescriptionModal';

const CareerPagesContainer = ({
  clientId,
  client,
  clientCareerPages,
  clientCareerPagesLoading,
  offers,
  clientOffersLoading,
  loadingClientDepartments,
  clientDepartments,
  updateCareerPage,
  t,
}) => {
  const { applicationMode } = useNavApplicationMode();
  const [careerPageDescriptionModalOpen, setCareerPageDescriptionModalOpen] = useState(false);
  const { onShowNotification } = useNotificationSystem();

  const handleUpdate = async (careerPageState) => {
    try {
      await updateCareerPage({ input: sanitizeTypename(careerPageState) });
      onShowNotification({
        message: t('settings.careerPage.updateSuccess'),
        level: 'success',
      });
    } catch (e) {
      console.warn(e);
      onShowNotification({
        message: t('settings.careerPage.updateError'),
        level: 'error',
      });
    }
  };

  if (
    clientCareerPagesLoading ||
    clientOffersLoading ||
    loadingClientDepartments
  ) {
    return (
      <SettingsLayout
        currentPage='careerPage'
        clientId={clientId}
        className='settings-container'
      >
        <div className='settings'>
          <Loader active />
        </div>
      </SettingsLayout>
    );
  }

  const careerPage = (clientCareerPages || [])[0];

  return (
    <SettingsLayout
      currentPage='careerPage'
      clientId={clientId}
      className='settings-container'
    >
      <div className='settings'>
        <div className='settings-header'>
          <h1>{t('settings.careerPage.header')}</h1>
        </div>

        <h2>{t('settings.careerPage.configuration')}</h2>
        <CareerPage careerPage={careerPage} />
        <h2>{t('settings.careerPage.customization')}</h2>
        <div className='job-postings section visible'>
          <div className='input-container career-page-description'>
            <div className='input-label'>{t('settings.careerPage.description.title')}</div>
            {(_.size(careerPage?.description?.sections || []) <= 1 && !careerPage?.theme?.background && !careerPage?.theme?.font) ? (
              <>
                <div className='input-description'>{t('settings.careerPage.description.emptyState')}</div>
                <GenericButton onClick={() => setCareerPageDescriptionModalOpen(true)}>
                  {t('settings.careerPage.description.configureDescription')}
                </GenericButton>
              </>
            ) : (
              <div>
                <GenericButton onClick={() => setCareerPageDescriptionModalOpen(true)}>
                  {t('settings.careerPage.description.editDescription')}
                </GenericButton>
              </div>
            )}
          </div>
        </div>
        {applicationMode === 'classic' ? (
          careerPage && (
            <JobPostings
              clientId={clientId}
              careerPage={careerPage}
              jobs={offers}
              clientDepartments={clientDepartments}
            />
          )
        ) : (
          careerPage && (
            <RevealJobPostings
              clientId={clientId}
              careerPage={careerPage}
              clientDepartments={clientDepartments}
            />
          )
        )}
        {careerPage && (
          <div>
            <h2>{t('settings.careerPage.applicationForms.title')}</h2>
            <ApplicationForms client={client} t={t} />
          </div>
        )}
      </div>
      <CareerPageDescriptionModal
        open={careerPageDescriptionModalOpen}
        onClose={() => setCareerPageDescriptionModalOpen(false)}
        description={careerPage?.description}
        theme={careerPage?.theme}
        layout={careerPage?.layout}
        onUpdateCareerPage={({ description, theme, layout }) => {
          const input = {
            id: careerPage?.id,
            ...getCareerPageInput({
              careerPageState: {
                ...careerPage,
                description,
                theme,
                layout
              }
            }),
          };
          handleUpdate(input);
          setCareerPageDescriptionModalOpen(false);
        }}
      />
    </SettingsLayout>
  );
};

export default compose(
  withClient,
  withClientCareerPages,
  withClientDepartments,
  withClientOffers,
  withUpdateCareerPage,
  withTranslation('translations'),
)(CareerPagesContainer);
