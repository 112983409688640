import gql from 'graphql-tag';
import { QueryResult, QueryHookOptions, useLazyQuery } from '@apollo/client';

import { TrackedLinksDailyStatisticsDetails } from '@/types/statistics/trackedLinks';
import useClientId from '@/hooks/router/useClientId';
import { isTodayYyyyMmDd } from '@/common/utils/time';
import { GetTrackedLinksDailyEventsVariables } from './useTrackedLinksDailyStatistics';

export const GET_TRACKED_LINKS_DAILY_EVENTS_DETAILS = gql`
  query getTrackedLinksDailyEventsDetails(
    $clientId: ID!
    $startDate: Date
    $endDate: Date
    $sequencesFilter: GenericStatsFilterInput
    $urlsFilter: GenericStatsFilterInput
  ) {
    client(id: $clientId) {
      id
      statistics {
        trackedLinks(
          startDate: $startDate
          endDate: $endDate
          sequencesFilter: $sequencesFilter
          urlsFilter: $urlsFilter
        ) {
          dailyEvents {
            day
            linkStats {
              url
              sequenceId
              missionId
              eventType
              profileItems {
                profileId
              }
            }
          }
        }
      }
    }
  }
`;

interface GetTrackedLinksDailyEventsDetailsData {
  client: {
    id: string;
    statistics: {
      trackedLinks: {
        dailyEvents: TrackedLinksDailyStatisticsDetails[];
      };
    };
  };
}

type UseTrackedLinksDailyStatisticsDetailsResults = [
  (variables?: Omit<GetTrackedLinksDailyEventsVariables, 'clientId'>) => void,
  QueryResult<
    GetTrackedLinksDailyEventsDetailsData,
    GetTrackedLinksDailyEventsVariables
  > & {
    trackedLinksDailyStatisticsDetails: TrackedLinksDailyStatisticsDetails[];
  },
];

interface UseTrackedLinksDailyStatisticsDetailsInput {
  queryOptions?: QueryHookOptions<
    GetTrackedLinksDailyEventsDetailsData,
    GetTrackedLinksDailyEventsVariables
  >;
}

const useTrackedLinksDailyStatistics = ({
  queryOptions = {},
}: UseTrackedLinksDailyStatisticsDetailsInput = {}): UseTrackedLinksDailyStatisticsDetailsResults => {
  const clientId = useClientId();
  const [fetchDetails, query] = useLazyQuery<
    GetTrackedLinksDailyEventsDetailsData,
    GetTrackedLinksDailyEventsVariables
  >(GET_TRACKED_LINKS_DAILY_EVENTS_DETAILS, {
    ...queryOptions,
  });

  const trackedLinksDailyStatisticsDetails =
    query.data?.client.statistics.trackedLinks.dailyEvents || [];

  return [
    ({ startDate, endDate, sequencesFilter, urlsFilter } = {}) =>
      fetchDetails({
        variables: {
          clientId,
          startDate,
          endDate: isTodayYyyyMmDd(endDate) ? undefined : endDate,
          sequencesFilter,
          urlsFilter,
        },
      }),
    { ...query, trackedLinksDailyStatisticsDetails },
  ];
};

export default useTrackedLinksDailyStatistics;
