/* eslint-disable class-methods-use-this */
import _ from 'underscore';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import { NO_VARIABLE_FOUND_ERROR_MESSAGE } from '@/SweetEvaluator/utils/constants';
import { TextWithSnippetsVariable } from '../utils';

export default class ExpressionTextWithSnippetsEvaluator
  implements SweetEvaluatorTypes.EvaluatorInterface {
  evaluate({
    context,
    expression,
    getExpressionEvaluatorFromType,
    externalVariableEvaluator,
  }: {
    context: SweetEvaluatorTypes.Context;
    expression: SweetEvaluatorTypes.Variable;
    getExpressionEvaluatorFromType: SweetEvaluatorTypes.ExpressionEvaluatorFromType;
    externalVariableEvaluator: SweetEvaluatorTypes.ExternalVariableEvaluator;
  }): { value: string | undefined } {
    const typedExpression = (expression as unknown) as SweetEvaluatorTypes.BaseVariable &
      TextWithSnippetsVariable;
    const { text, snippets } = typedExpression;

    const evaluatuedSnippetValueFromId: Record<string, string | undefined> = {};

    _.each(snippets, (snippet) => {
      try {
        evaluatuedSnippetValueFromId[snippet.id] = externalVariableEvaluator({
          query: { idInParentDynamicVariable: snippet.id },
        }).value;
      } catch (error) {
        if ((error as any).message !== NO_VARIABLE_FOUND_ERROR_MESSAGE) {
          throw error;
        }
        evaluatuedSnippetValueFromId[
          snippet.id
        ] = getExpressionEvaluatorFromType({
          type: snippet.type,
        }).evaluate({
          context,
          expression: snippet as SweetEvaluatorTypes.Variable,
          getExpressionEvaluatorFromType,
          externalVariableEvaluator,
        }).value;
      }
    });

    const resultingText = text.replace(
      /\{{([^{}]*)}}/g,
      (match: string, key: string) => {
        const value = evaluatuedSnippetValueFromId[key];
        // The span should be only on frontend, not on the textWithSnippetsEvaluator in backend
        if (value) {
          return `<span class='merge-tags-deep-snippet-value'>${evaluatuedSnippetValueFromId[
            key
          ] || ''}</span>`;
        }
        return `<span class='merge-tags-deep-snippet-no-value'>${''}</span>`;
      },
    );
    return { value: resultingText };
  }
}
