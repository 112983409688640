import { ReactElement } from 'react';

export default function SelectableColumn({
  show = true,
  children = null,
}: {
  show?: boolean;
  children: null | ReactElement<any, any>;
  [key: string]: any;
}): null | ReactElement<any, any> {
  if (!show) {
    return null;
  }

  return children;
}
