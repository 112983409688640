import React, { useState, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, Loader, Dimmer, Segment } from 'semantic-ui-react';

import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import EmailContext from '../../../context/EmailContext';
import MailAccountConnect from '../../../components/MailAccountConnect';
import withUpdateSharedDiscussionMode from '../../../hocs/email/withUpdateSharedDiscussionMode';
import withDeleteEmailData from '../../../hocs/email/withDeleteEmailData';
import withHasEnabledSharedDiscussion from '../../../hocs/email/withHasEnabledSharedDiscussion';

import './AdvancedMessagingModal.css';

const SharedDiscussionSetting = ({
  t,
  updateSharedDiscussionMode,
  hasEnabledSharedDiscussion,
  token,
  type,
}) => {
  const {
    hasEnabledSharedDiscussion: isEnabled = true,
    loading = true,
    refetch,
  } = hasEnabledSharedDiscussion || {};
  const [mutationPending, setMutationPending] = useState(false);
  const toggleOn = async () => {
    setMutationPending(true);
    updateSharedDiscussionMode({
      token,
      enableSharedDiscussion: !isEnabled,
      type,
    })
      .then(() => {
        refetch();
      })
      .then(setMutationPending(false));
  };
  return (
    <Segment>
      <div className='shared-discussion-setting'>
        <div className='input-container'>
          <div className='input-label'>
            {t('settings.advancedMessaging.settingsModal.sharedDiscussion')}
          </div>
          <div className='input-description'>
            {t(
              'settings.advancedMessaging.settingsModal.sharedDiscussionPopup',
            )}
          </div>
          {!loading && (
            <div className='input-element'>
              <LabeledCheckbox
                label={t(
                  'settings.advancedMessaging.settingsModal.sharedDiscussionLabel',
                )}
                checked={isEnabled}
                onClick={mutationPending ? undefined : toggleOn}
              />
            </div>
          )}
        </div>
      </div>
    </Segment>
  );
};

const SharedDiscussionSettingWithData = withHasEnabledSharedDiscussion(
  withUpdateSharedDiscussionMode(SharedDiscussionSetting),
);

const DeleteDataSetting = ({
  t,
  deleteEmailData,
  token,
  type,
  onTriggerSignOut,
  onClose,
}) => {
  const [checked, setChecked] = useState(false);
  const [pending, setPending] = useState(false);
  const toggle = () => {
    setChecked(!checked);
  };
  const onClick = () => {
    if (checked) {
      setPending(true);
      // the Apollo cache is not invalidated
      deleteEmailData({ token, type })
        .then(() => {
          setPending(false);
          setChecked(false);
          onClose();
          onTriggerSignOut(); // we could .then this but it requires user interaction for Outlook
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  };
  return (
    <Dimmer.Dimmable as={Segment} dimmed={pending}>
      <div className='delete-data-setting'>
        <div className='input-container'>
          <div className='input-label'>
            {t('settings.advancedMessaging.settingsModal.deleteData')}
          </div>
          <div className='input-description'>
            {t('settings.advancedMessaging.settingsModal.deleteDataText')}
          </div>
          <div className='delete-data-setting-form'>
            <LabeledCheckbox
              label={t(
                'settings.advancedMessaging.settingsModal.deleteDataLabel',
              )}
              checked={checked}
              onClick={toggle}
            />
            <div className='spacer' />
            <GenericButton
              color='red'
              disabled={!checked || pending}
              onClick={onClick}
            >
              {t('settings.advancedMessaging.settingsModal.deleteDataButton')}
            </GenericButton>
          </div>
        </div>
      </div>
      <Dimmer inverted active={pending}>
        <Loader />
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

const DeleteDataSettingWithMutation = withDeleteEmailData(DeleteDataSetting);

const AdvancedMessagingModal = ({ children, t }) => {
  const [isActive, setIsActive] = useState(false);
  const {
    currentAddress,
    onTriggerOfflineRegister,
    connectionInProgress,
    getIdToken,
    currentEmailType,
    onTriggerSignOut,
  } = useContext(EmailContext);

  const onActivate = () => {
    setIsActive(true);
  };
  const onClose = () => {
    setIsActive(false);
  };

  // case 1 : no one has clicked the button so render the children
  if (!isActive) {
    return children(onActivate);
  }

  // case 2 : the button has been clicked but no account is connected
  if (!currentAddress) {
    return (
      <>
        {children(onActivate)}
        {/* NB: we need closeOnDimmerClick=false because the close event is fired on clicking on MailAccountConnect
        This is probably a bug from semantic-ui-react. It could be nice to check this after updating semantic-ui-react */}
        <GenericModal
          open
          onClose={onClose}
          size='tiny'
          closeOnDimmerClick={false}
        >
          <Modal.Header>
            {t('settings.advancedMessaging.mailConnectionModal.header')}
          </Modal.Header>
          <Modal.Content>
            <h4>
              {t('settings.advancedMessaging.mailConnectionModal.description')}
            </h4>
            {!connectionInProgress ? (
              <>
                <MailAccountConnect
                  onClick={() => {
                    onTriggerOfflineRegister('gmail');
                  }}
                  imgSrc='/images/logos/gmail.svg'
                  imgAlt='gmail'
                  text={t('changeEmailAccountModal.gmailSwitch')}
                />
                <MailAccountConnect
                  onClick={() => {
                    onTriggerOfflineRegister('outlook', 'select_account');
                  }}
                  imgSrc='/images/logos/outlook.svg'
                  imgAlt='outlook'
                  text={t('changeEmailAccountModal.outlookSwitch')}
                />
              </>
            ) : (
              <Dimmer active inverted>
                <Loader />
              </Dimmer>
            )}
          </Modal.Content>
        </GenericModal>
      </>
    );
  }

  // case 3 : show the settings
  const currentEmailToken = getIdToken();
  return (
    <>
      {children(onActivate)}
      <GenericModal open onClose={onClose}>
        <Modal.Header>
          <div>
            {t('settings.advancedMessaging.settingsModal.messagingOptions')}
          </div>
          <div className='subheader'>{currentAddress}</div>
        </Modal.Header>
        <Modal.Content>
          <SharedDiscussionSettingWithData
            t={t}
            token={currentEmailToken}
            type={currentEmailType}
          />
          <DeleteDataSettingWithMutation
            t={t}
            token={currentEmailToken}
            type={currentEmailType}
            onTriggerSignOut={onTriggerSignOut}
            onClose={onClose}
          />
        </Modal.Content>
        <Modal.Actions className='modal-actions-buttons'>
          <GenericButton size='big' onClick={onClose}>
            {t('settings.advancedMessaging.settingsModal.cancel')}
          </GenericButton>
        </Modal.Actions>
      </GenericModal>
    </>
  );
};

export default withTranslation('translations')(AdvancedMessagingModal);
