import React from 'react';
import _ from 'underscore';
import { Icon, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { MarketplaceUnbiasedModeField } from '@/components/UnbiasedMode/UnbiasedModeField';
import { getRandomDefaultAvatarLink, getSecureLink } from '../../../common';
import TranslatableText from '../../../components/TranslatableText';
import WatchNewTag from '../../WatchCollectViewV2/WatchCollectRecommendations/JobProfileRecommendations/WatchNewTag';

const getDisplayedExperience = (experience) => {
  if (!experience) {
    return null;
  }
  const { title, companyName /* , startDate, endDate */ } = experience;
  // const dateDiff = getDateDiffInMs(startDate, endDate || new Date().toISOString());
  // const duration = getDurationInYearsAndMonths({ durationInMs: dateDiff, t });
  return {
    titleText: _.compact([title?.text, companyName]).join(' • '),
    // duration,
  };
};

const CandidateMiniCard = ({
  clientId,
  title,
  jobOfferId,
  profileId,
  creationTimestamp,
  firstname,
  lastname,
  mainExperience,
  educationName,
  photoLink,
  headline,
  jobWishes,
  firstLineOnlyReview,
}) => {
  const href = `/client/${clientId}/jobs/${jobOfferId}/profiles/${profileId}`;
  const experienceDetail = getDisplayedExperience(mainExperience)?.titleText;

  return (
    <Link className='candidate-mini-card' to={href}>
      <div className='mini-card-header'>
        <div className='mini-card-header-title' title={title}>
          {title}
        </div>
      </div>
      <div className='mini-card-profile'>
        <div className='mini-card-profile-header'>
          <div className='profile-avatar'>
            <div className='avatar'>
              <MarketplaceUnbiasedModeField
                name='hidePicture'
                fallback={
                  <Image
                    data-openreplay-masked
                    src={getRandomDefaultAvatarLink(firstname + lastname)}
                    circular
                  />
                }
              >
                <Image
                  // adding fallback to trigger error if photo link is missing
                  data-openreplay-masked
                  src={getSecureLink(photoLink || 'fake')}
                  circular
                  onError={(e) => {
                    e.target.src = getRandomDefaultAvatarLink(
                      firstname + lastname,
                    );
                  }}
                />
              </MarketplaceUnbiasedModeField>
            </div>
          </div>
          <div className='profile-info'>
            <div className='profile-name'>
              <MarketplaceUnbiasedModeField name='hideFullName'>
                <span className='full-name'>
                  {firstname} {lastname}
                </span>
              </MarketplaceUnbiasedModeField>
              <WatchNewTag creationTimestamp={creationTimestamp} />
            </div>
            <div className='profile-headline'>
              {!_.isEmpty(jobWishes) && (
                <span className='headline'>
                  <Icon name='search' />
                  {jobWishes}
                </span>
              )}
              {_.isEmpty(jobWishes) && headline?.content?.text && (
                <span className='headline'>{headline?.content?.text}</span>
              )}
            </div>
          </div>
        </div>

        {(educationName || experienceDetail) && (
          <div className='mini-card-profile-details'>
            {firstLineOnlyReview && (
              <div className='summary'>
                &laquo;&nbsp;
                <TranslatableText text={firstLineOnlyReview} />
                &nbsp;&raquo;
              </div>
            )}
            {!firstLineOnlyReview && experienceDetail && (
              <ProfileDetail iconName='ri-briefcase-4-line'>
                {experienceDetail}
              </ProfileDetail>
            )}
            {!firstLineOnlyReview && educationName && (
              <ProfileDetail iconName='ri-award-line'>
                {educationName}
              </ProfileDetail>
            )}
          </div>
        )}
      </div>
    </Link>
  );
};

const ProfileDetail = ({ children, iconName }) => (
  <div className='profile-detail'>
    <div className='profile-detail-icon'>
      <i className={iconName} />
    </div>
    <div className='profile-detail-content' title={children}>
      {children}
    </div>
  </div>
);

export default CandidateMiniCard;
