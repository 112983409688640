import { gql } from '@apollo/client';

const RevealSavedSearchFragment = gql`
  fragment RevealSavedSearch on RevealSavedSearch {
    id
    title
    author {
      firstname
      lastname
      email
    }
    creationDate
    lastEditionDate
    sharingSettings {
      ... on SearchOnlyAuthorSharingSettings {
        type
      }
      ... on SearchAllUsersSharingSettings {
        type
      }
    }
    scope {
      ... on SearchGlobalScope {
        type
      }
      ... on SearchAssignedToProjectScope {
        type
        assignedProjectId
      }
    }
    criteria {
      free
    }
  }
`;

export default RevealSavedSearchFragment;
