import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SmallIntegration from '../../graphql/fragments/SmallIntegration';
import getOfferWorkflows from './getOfferWorkflows';

export const mutation = gql`
  mutation editOfferPinpointWorkflows(
    $clientId: ID!
    $jobOfferId: ID!
    $integrationId: ID!
    $workflows: [PinpointWorkflowInput]
  ) {
    editOfferPinpointWorkflows(
      clientId: $clientId
      jobOfferId: $jobOfferId
      integrationId: $integrationId
      workflows: $workflows
    ) {
      ...SmallIntegration
    }
  }
  ${SmallIntegration}
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    editOfferPinpointWorkflows: ({
      clientId,
      jobOfferId,
      integrationId,
      workflows,
    }) =>
      mutate({
        variables: {
          clientId,
          jobOfferId,
          integrationId,
          workflows,
        },
        refetchQueries: [
          {
            query: getOfferWorkflows,
            variables: {
              id: jobOfferId,
            },
          },
        ],
      }),
  }),
});
