import React from 'react';
import _, { compose } from 'underscore';
import {
  parseDate,
  getDurationInYearsAndMonths,
  getDateDiffInMs,
} from './date-utils';

import withActionLogger from '../../../../hocs/withActionLogger';
import BackgroundItemCard from './BackgroundItemCard';

import './Experiences.css';

export class EducationCard extends React.Component {
  logExpand = (expanded) => {
    if (expanded && this.props.logAction) {
      this.props.logAction({
        type: 'expand-profile-education',
        profileId: this.props.profileId,
        info: { education: this.props.educationItem.schoolName },
      });
    }
  };

  render() {
    const {
      educationItem,
      t,
      profileId,
      applicationMode,
      resumeData,
    } = this.props;
    const startDate =
      educationItem.startDate && parseDate(educationItem.startDate);
    const endDate = educationItem.endDate && parseDate(educationItem.endDate);

    const capitalizeText = (str) => {
      if (_.isString(str) && str.length > 0) {
        str = str.charAt(0).toUpperCase() + str.substr(1);
      }
      return str;
    };

    const formattedStartDate = capitalizeText(
      (startDate && startDate.format('YYYY')) || '',
    );
    const formattedEndDate = capitalizeText(
      (endDate && endDate.format('YYYY')) || t('profile.resume.presentShort'),
    );

    const startDateStr = educationItem?.startDate;
    const endDateStr = educationItem?.endDate || new Date().toISOString();
    const dateDiff = getDateDiffInMs(startDateStr, endDateStr);
    const duration =
      startDate &&
      endDate &&
      getDurationInYearsAndMonths({ durationInMs: dateDiff, t });
    const formattedDuration = duration ? `(${duration})` : '';

    const subtitle = formattedStartDate
      ? formattedStartDate +
        (formattedEndDate ? ` – ${formattedEndDate}` : '') +
        (formattedEndDate && formattedDuration ? `  ${formattedDuration}` : '')
      : '';

    return (
      <BackgroundItemCard
        applicationMode={applicationMode}
        icon={educationItem.photoLink || '/images/icons/defaultDegree.svg'}
        title=''
        titleSecondPart={{
          text: educationItem.schoolName,
          link: educationItem.schoolWebsite,
        }}
        subtitle={subtitle}
        profileId={profileId}
        resumeData={resumeData}
        content={educationItem.degree}
        stack={educationItem.stack}
        onExpand={(expanded) => this.logExpand(expanded)}
        structureType='education'
      />
    );
  }
}

class Education extends React.PureComponent {
  componentWillReceiveProps(nextProps) {
    if (nextProps.profileId !== this.props.profileId) {
      this.setState({ isOpened: false });
    }
  }

  componentWillMount() {
    this.setState((state) => ({ isOpened: state?.isOpened || false }));
  }

  handleToggleEducation = () => {
    this.setState(({ isOpened }) => ({ isOpened: !isOpened }));
  };

  render() {
    const displayToggle = (this.props.education || []).length > 3;
    const { isOpened } = this.state;
    const {
      education,
      t,
      profileId,
      onLogAction,
      applicationMode,
      resumeData,
    } = this.props;
    return (
      <div>
        {_.map(education, (educationItem, index) =>
          !displayToggle ||
          (displayToggle && !isOpened && index < 3) ||
          (displayToggle && isOpened) ? (
            <EducationCard
              applicationMode={applicationMode}
              key={index}
              educationItem={educationItem}
              t={t}
              logAction={onLogAction}
              profileId={profileId}
              resumeData={resumeData}
            />
          ) : null,
        )}
        {displayToggle ? (
          isOpened === false ? (
            <div
              className='background-toggle down body-medium'
              onClick={() => this.handleToggleEducation()}
            >
              {t('profile.contact.timeline.seeMore')}
            </div>
          ) : (
            <div
              className='background-toggle up body-medium'
              onClick={() => this.handleToggleEducation()}
            >
              {t('profile.contact.timeline.seeLess')}
            </div>
          )
        ) : null}
      </div>
    );
  }
}

export default compose(withActionLogger)(Education);
