import gql from 'graphql-tag';
import { QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import { TranslatableText } from '@/types/text';
import { MetaTaskFormat } from '@/revealComponents/ExplicitTasks/MetaTaskForm/types';
import ContactFlowActionFormat from '@/graphql/fragments/ContactFlowActionFormat';

export const GET_CLIENT_CUSTOM_ACTIVITIES = gql`
  query getClientCustomActivities($clientId: ID!) {
    client(id: $clientId) {
      id
      clientCustomActivities {
        id
        type
        title {
          default
          fr
          en
        }
        icon {
          type
          id
          url
          emojiText
        }
        format {
          ...ContactFlowActionFormatFragment
        }
      }
    }
  }
  ${ContactFlowActionFormat}
`;

interface Data {
  client: {
    id: string;
    clientCustomActivities?: ClientCustomActivity[];
  };
}

export interface ClientCustomActivity {
  id: string;
  type: string;
  title: TranslatableText;
  icon: {
    type: string;
    id: string;
    url: string;
    emojiText: string;
  };
  format: MetaTaskFormat;
}

interface Variables {
  clientId: string;
}

interface Input extends Variables {
  queryOptions?: QueryHookOptions<Data, Variables>;
}

const useClientCustomActivities = ({ clientId, queryOptions = {} }: Input) => {
  const query = useQuery<Data, Variables>(GET_CLIENT_CUSTOM_ACTIVITIES, {
    ...queryOptions,
    variables: {
      clientId,
    },
  });
  const clientCustomActivities =
    query.data?.client?.clientCustomActivities || [];
  return { ...query, clientCustomActivities };
};

const UPDATE_CLIENT_CUSTOM_ACTIVITY = gql`
  mutation UpdateClientCustomActivity(
    $clientId: ID!
    $input: ClientCustomActivityInput!
  ) {
    updateClientCustomActivity(clientId: $clientId, input: $input) {
      id
      clientCustomActivities {
        id
        type
        icon {
          type
          id
          url
          emojiText
        }
        title {
          default
          fr
          en
        }
        format {
          form {
            id
            fields {
              id
              type
              subtype
              options {
                id
                selected
              }
              isMultiselect
              value
              metadata {
                key
                value
              }
              onEvents {
                rule {
                  type
                }
                effect {
                  type
                  key
                }
              }
              condition {
                type
                key
                targetValues
                effect {
                  type
                }
              }
              required
              title {
                default
                fr
                en
              }
            }
          }
          helpText {
            default
            fr
            en
          }
        }
      }
    }
  }
`;

export function useUpdateClientCustomActivity() {
  return useMutation(UPDATE_CLIENT_CUSTOM_ACTIVITY);
}

const REMOVE_CLIENT_CUSTOM_ACTIVITY = gql`
  mutation RemoveClientCustomActivity($clientId: ID!, $activityId: ID!) {
    removeClientCustomActivity(clientId: $clientId, activityId: $activityId) {
      clientCustomActivities {
        id
        type
        icon {
          type
          id
          url
          emojiText
        }
        title {
          default
          fr
          en
        }
        format {
          form {
            id
            fields {
              id
              type
              subtype
              options {
                id
                selected
              }
              isMultiselect
              value
              metadata {
                key
                value
              }
              onEvents {
                rule {
                  type
                }
                effect {
                  type
                  key
                }
              }
              condition {
                type
                key
                targetValues
                effect {
                  type
                }
              }
              required
            }
          }
          helpText {
            default
            fr
            en
          }
        }
      }
    }
  }
`;

export function useRemoveClientCustomActivity() {
  return useMutation(REMOVE_CLIENT_CUSTOM_ACTIVITY);
}

export default useClientCustomActivities;
