import React from 'react';
import {
  useJobApplicantProfiles,
  useConfirmRecommendedProfileMutation,
  useJobRecommendedProfiles,
  useSkipRecommmendedProfileMutation,
  useRemoveRecommendedProfileMutation,
  useConfirmApplicationProfileMutation,
  useSkipApplicationProfileMutation,
  useRemoveApplicationProfileMutation,
} from '@/graphql/searchPoolJobProfiles';
import { useGenerateRecommendedProfilesMutation } from '@/graphql/recommendedMissions';
import RecommendedProfilesTab from './RecommendedProfilesTab';

interface RecommendedOrApplicantsTabWrapperProps {
  jobId: string;
  clientId: string;
  searchPoolId: string;
}

export const RecommendedTabWrapper: React.FC<RecommendedOrApplicantsTabWrapperProps> = ({
  jobId,
  clientId,
  searchPoolId,
}) => {
  const [currentTab, setCurrentTab] = React.useState('active');

  const { profiles, loading, profilesStatesCount } = useJobRecommendedProfiles(
    searchPoolId,
    jobId,
    currentTab,
  );

  const [skipProfileMutate] = useSkipRecommmendedProfileMutation(
    searchPoolId,
    jobId,
  );

  const [confirmProfileMutate] = useConfirmRecommendedProfileMutation(
    searchPoolId,
    jobId,
  );

  const [removeRecommendedProfile] = useRemoveRecommendedProfileMutation(
    searchPoolId,
  );

  const [
    generateRecommendedProfiles,
    generateRecommendationState,
  ] = useGenerateRecommendedProfilesMutation(searchPoolId);

  if (loading) {
    return null;
  }

  return (
    <RecommendedProfilesTab
      jobId={jobId}
      clientId={clientId}
      searchPoolId={searchPoolId}
      profiles={profiles}
      profilesLoading={loading}
      profilesStatesCount={profilesStatesCount}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      onSkip={skipProfileMutate}
      onConfirm={confirmProfileMutate}
      onRemove={removeRecommendedProfile}
      onGenerateNewRecommendedProfiles={generateRecommendedProfiles}
      generationLoading={generateRecommendationState?.loading}
    />
  );
};

export const ApplicantsTabWrapper: React.FC<RecommendedOrApplicantsTabWrapperProps> = ({
  jobId,
  clientId,
  searchPoolId,
}) => {
  const [currentTab, setCurrentTab] = React.useState('active');

  const { profiles, loading, profilesStatesCount } = useJobApplicantProfiles(
    searchPoolId,
    jobId,
    currentTab,
  );

  const [skipProfileMutate] = useSkipApplicationProfileMutation(
    searchPoolId,
    jobId,
  );

  const [confirmProfileMutate] = useConfirmApplicationProfileMutation(
    searchPoolId,
    jobId,
  );

  const [removeRecommendedProfile] = useRemoveApplicationProfileMutation(
    searchPoolId,
  );

  return (
    <RecommendedProfilesTab
      jobId={jobId}
      clientId={clientId}
      searchPoolId={searchPoolId}
      profiles={profiles}
      profilesLoading={loading}
      profilesStatesCount={profilesStatesCount}
      isApplicantsTab
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      onSkip={skipProfileMutate}
      onConfirm={confirmProfileMutate}
      onRemove={removeRecommendedProfile}
    />
  );
};
