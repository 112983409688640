import React, { useState } from 'react';
import _ from 'underscore';
import { Loader } from 'semantic-ui-react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { dateTickFormatter, ticksByFrequency } from '../../utils';
import * as colors from '../../../../../less/colors';

import './CohortsGraph.css';

const colorMap = {
  nbSent: colors.PrimaryCobalt,
  nbAnswered: colors.SecondarySkyBlue120,
  nbPositiveAnswered: colors.SecondarySkyBlue60,
  nbInProcess: colors.PositiveColor80,
  nbHired: colors.PositiveColor120,
  nbSkipped: colors.SecondaryRed,
};

export const CohortsGraphStub = () => (
  <div className='cohorts-graph'>
    <div className='cohorts-graph-empty'>
      <Loader active inline='centered' size='large' />
    </div>
  </div>
);

const CohortsGraph = ({ cohortsData, isLoading, frequency, t }) => {
  const [visibleLines, setVisibleLines] = useState({
    nbSent: true,
    nbAnswered: true,
    nbPositiveAnswered: true,
    nbInProcess: true,
    nbHired: true,
    nbSkipped: true,
  });

  const toggleVisibility = (lineKey) => {
    setVisibleLines({
      ...visibleLines,
      [lineKey]: !visibleLines[lineKey],
    });
  };

  // 45px is the perfect fit for 4 digits (e.g >1000 sends).
  const Y_AXIS_WIDTH = 45;

  if (isLoading) {
    return <CohortsGraphStub />;
  }

  if (_.isEmpty(cohortsData)) {
    return (
      <div className='cohorts-graph'>
        <div className='cohorts-graph-empty'>
          <div className='cohorts-graph-empty-text'>{t('analytics.noDataPlaceholder')}</div>
        </div>
      </div>
    );
  }

  const ticks = ticksByFrequency({ startDate: (cohortsData[0] || {}).timestamp, frequency });

  // NOTE: order of Axes / Lines is important for "z-index" of components
  return (
    <div className='cohorts-graph'>
      <ResponsiveContainer width='100%' height={300}>
        <LineChart data={cohortsData} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey='timestamp'
            type='number'
            domain={['dataMin', null]}
            ticks={ticks}
            tickFormatter={dateTickFormatter(frequency, t)}
          />
          <YAxis tickLine={false} axisLine={false} width={Y_AXIS_WIDTH} />
          <Tooltip labelFormatter={dateTickFormatter(frequency, t)} />
          {_.map(Object.keys(visibleLines), (lineKey) => {
            if (!visibleLines[lineKey]) {
              return null;
            }
            return (
              <Line
                key={`line-${lineKey}`}
                type='monotone'
                stroke={colorMap[lineKey]}
                dot={false}
                animationDuration={300}
                dataKey={lineKey}
                name={t(`analytics.labels.${lineKey}`)}
                strokeWidth='2'
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
      <div className='cohorts-legend'>
        {
          _.map(Object.keys(visibleLines), (lineKey) => {
            const lineColor = colorMap[lineKey];
            const checkboxStyle = visibleLines[lineKey]
              ? { border: '1px solid', borderColor: lineColor, backgroundColor: lineColor }
              : {};

            return (
              <div
                key={`legend-${lineKey}`}
                className='cohorts-legend-item'
                onClick={() => toggleVisibility(lineKey)}
              >
                <div className='cohorts-legend-checkbox-box'>
                  <div className='cohorts-legend-checkbox' style={checkboxStyle}/>
                </div>
                <div className='cohorts-legend-text'>
                  {t(`analytics.labels.${lineKey}`)}
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default CohortsGraph;
