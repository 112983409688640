import { useQuery, QueryHookOptions, ApolloError } from '@apollo/client';
import { OVERVIEW_MINI_PROFILE } from '@/graphql/OverviewMiniProfiles';
import { ResumeData } from '@/types/searchPoolProfile';

interface UseOverviewPoolMiniProfileByIdParams {
  searchPoolId: string;
  profileId: string;
}

type OverviewMiniProfile = {
  id: string;
  resumeData?: ResumeData;
};

type OverviewMiniProfileResult = {
  searchPool: {
    id: string;
    profile: OverviewMiniProfile;
  };
};

const useOverviewPoolMiniProfileById = (
  { searchPoolId, profileId }: UseOverviewPoolMiniProfileByIdParams,
  options?: Omit<
    QueryHookOptions<
      OverviewMiniProfileResult,
      UseOverviewPoolMiniProfileByIdParams
    >,
    'variables'
  >,
): {
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
  profile: OverviewMiniProfile | undefined;
} => {
  const { data, loading, error, refetch } = useQuery<
    OverviewMiniProfileResult,
    {
      searchPoolId: string;
      profileId: string;
    }
  >(OVERVIEW_MINI_PROFILE, {
    variables: {
      searchPoolId,
      profileId,
    },
    ...options,
  });
  return {
    loading,
    error,
    refetch,
    profile: data?.searchPool?.profile,
  };
};

export default useOverviewPoolMiniProfileById;
