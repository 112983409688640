import React from 'react';

import 'url-search-params-polyfill'; // for URLSearchParams

export default (WrappedComponent) => {
  return class withTokenFromUrl extends React.Component {
    render() {
      const getTokenFromUrl = () => {
        const search = new URLSearchParams(window.location.search);
        return search.get('token');
      };
      const token = getTokenFromUrl();
      return <WrappedComponent token={token} {...this.props} />;
    }
  };
};
