import _ from 'underscore';

import { LocationAndRemote } from '@/types/locationAndRemote';
import { remotePolicies, RemotePolicy } from '@/types/remotePolicy';

export const remotePoliciesAvailable = (
  wishes: LocationAndRemote[],
  location: string,
): RemotePolicy[] =>
  _.reject(remotePolicies, (policy) =>
    _.some(
      wishes,
      ({ locationId, remotePolicy }) =>
        locationId === location && remotePolicy === policy,
    ),
  );

export const addWish = (
  wishes: LocationAndRemote[],
  locationId: string,
  remotePolicy?: RemotePolicy,
): LocationAndRemote[] => {
  const availablePolicies = remotePoliciesAvailable(wishes, locationId);
  if (_.isEmpty(availablePolicies)) {
    return wishes;
  }
  if (remotePolicy && !_.contains(availablePolicies, remotePolicy)) {
    return wishes;
  }
  if (remotePolicy) {
    return [
      ...wishes,
      { locationId, favorite: _.isEmpty(wishes), remotePolicy },
    ];
  }
  const defaultPolicy = _.last(wishes)?.remotePolicy || 'hybrid-remote';
  const fallbackPolicy = _.contains(availablePolicies, defaultPolicy)
    ? defaultPolicy
    : availablePolicies[0];
  return [
    ...wishes,
    { locationId, favorite: _.isEmpty(wishes), remotePolicy: fallbackPolicy },
  ];
};

const setWishFields = (
  wishes: LocationAndRemote[],
  index: number,
  fields: Partial<LocationAndRemote>,
): LocationAndRemote[] => {
  if (index < 0 || index >= _.size(wishes)) {
    return wishes;
  }
  const oldWish = wishes[index];
  const newWish = { ...oldWish, ...fields };
  const previousWishes = wishes.slice(0, index);
  const followingWishes = wishes.slice(index + 1);
  return [...previousWishes, newWish, ...followingWishes];
};

export const setRemoteWish = (
  wishes: LocationAndRemote[],
  index: number,
  remotePolicy: RemotePolicy,
): LocationAndRemote[] => setWishFields(wishes, index, { remotePolicy });

export const setFavorite = (
  wishes: LocationAndRemote[],
  index: number,
  favorite: boolean,
): LocationAndRemote[] => setWishFields(wishes, index, { favorite });

export const suggestedPolicy = (
  wishes: LocationAndRemote[],
  index: number,
): RemotePolicy | null => {
  const wish = wishes[index];
  if (!wish) {
    return null;
  }
  const { locationId, favorite, remotePolicy } = wish;
  if (!favorite) {
    return null;
  }
  const policyIndex = _.indexOf(remotePolicies, remotePolicy);
  if (policyIndex === _.size(remotePolicies) - 1) {
    return null;
  }
  if (
    _.some(
      wishes,
      ({ locationId: otherLocationId, remotePolicy: otherRemotePolicy }) =>
        locationId === otherLocationId &&
        _.indexOf(remotePolicies, otherRemotePolicy) > policyIndex,
    )
  ) {
    return null;
  }
  return remotePolicies[policyIndex + 1];
};
