import React from 'react';
import { Input } from 'semantic-ui-react';
import { AcceptConditionWithTargetText } from '@/common/mergeTags/utils';
import { useConditionsChainingContext } from '../useConditionsChainingContext';
import styles from '../ConditionsChaining.module.less';

const InputTarget: React.FC<{
  value: string;
  nodeId: string;
  node: AcceptConditionWithTargetText;
}> = ({ value, nodeId, node }) => {
  const {
    getStateTreeAfterReplacement,
    conditionsChainingState,
    setConditionsChainingState,
  } = useConditionsChainingContext();

  const handleChange = ({ newValue }: { newValue: string }) => {
    const newState = getStateTreeAfterReplacement<
      AcceptConditionWithTargetText
    >({
      node: conditionsChainingState,
      id: nodeId,
      newValue: {
        ...node,
        targetText: newValue,
      },
    });
    setConditionsChainingState(newState);
  };

  return (
    <Input
      className={styles.input}
      fluid
      value={value}
      placeholder='Your value'
      onChange={(event, { value: newValue }) => handleChange({ newValue })}
    />
  );
};

export default InputTarget;
