import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './Tag.module.less';

export interface TagProps {
  className?: string;
  onClick?: () => void;
  size?: 'small' | 'mini' | 'normal';
  color?: 'green' | 'red' | 'yellow' | 'grey' | 'orange' | 'blue';
}

const Tag: FC<TagProps> = ({
  className,
  size = 'normal',
  color = 'blue',
  children,
  onClick,
}) => {
  return (
    <span
      onClick={onClick}
      className={classNames(
        className,
        styles.tagMessage,
        styles[color],
        styles[size],
      )}
    >
      {children}
    </span>
  );
};

export default Tag;
