import _ from 'underscore';
import { Attachment, SourceData } from '../reveal/SourceData.type';
import { getOneATSDataItemAttachments } from '../reveal/SourceData';

export type ProfileWithEmailInfo = {
  id: string;
  email: string;
  contactData: {
    threads?: {
      bouncingEmails?: string[];
    }[];
  };
};

const flattenBouncingEmails = ({
  contactData: { threads },
}: ProfileWithEmailInfo): string[] =>
  _.chain(threads)
    .map(({ bouncingEmails }) => bouncingEmails || [])
    .flatten()
    .value();

export const hasProfileBounced = (profile: ProfileWithEmailInfo): boolean =>
  _.contains(flattenBouncingEmails(profile), profile.email);

type ClientProfile = {
  profileId: string;
  jobOfferId: string;
  jobTitle?: string;
  creationTimestamp?: string;
  isUnclassified?: boolean;
};

export function getCurrentClientProfile(
  clientProfiles: ClientProfile[],
): ClientProfile | null {
  if (_.isEmpty(clientProfiles)) {
    return null;
  }
  const sortedClientProfiles = _.sortBy(clientProfiles, 'isUnclassified');
  return sortedClientProfiles[0];
}

type ProfileWithAttachments = {
  resumeData?: {
    sourceData?: SourceData;
  };
  attachments?: Attachment[];
};

export function getAllAttachments(profile: ProfileWithAttachments) {
  const { resumeData } = profile;
  const sourceData = resumeData?.sourceData;
  return [
    ...getOneATSDataItemAttachments(sourceData),
    ..._.map(profile.attachments || [], (attachment) => ({
      ...attachment,
      from: 'hiresweet',
    })),
  ];
}
