import classNames from 'classnames';
import React, { CSSProperties, FC } from 'react';
import _ from 'underscore';

import styles from './HeaderRow.module.less';

interface HeaderRowProps {
  columns: {
    id: string;
    title: string;
    className?: string;
    style?: CSSProperties;
  }[];
}

const HeaderRow: FC<HeaderRowProps> = ({ columns }) => (
  <div className={styles.header}>
    {_.map(columns, ({ title, className, style, id }) => (
      <div
        key={id}
        className={classNames(styles.column, className)}
        style={style}
      >
        {title}
      </div>
    ))}
  </div>
);

export default HeaderRow;
