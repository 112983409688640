import _ from 'underscore';
import React, { FC, useState, useMemo, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { lowerCaseAndUnaccent } from '@/common';
import DropdownContainer from '@/components/Reveal/Dropdown/DropdownContainer/DropdownContainer';
import DropdownTrigger from '@/components/Reveal/Dropdown/DropdownTrigger/DropdownTrigger';
import MissionDropdownEmptyState from '@/revealComponents/ProfileProjectsTab/NewProfileMissionsManagement/EmptyState/MissionDropdownEmptyState';
import SequenceDropdownNoResultsState, {
  DropdownGenericEmptyState,
} from '@/components/Reveal/Sequences/SequenceDropdown/NoResultsState/SequenceDropdownNoResultsState';
import useUserFromJWToken from '@/graphql/hooks/users/useUserFromJWToken';
import useClientId from '@/hooks/router/useClientId';
import DropdownControlsContext from '@/context/DropdownControlsContext';
import {
  FilterKeys,
  Project,
  PROJECT_FILTER_FUNCTIONS,
  PROJECT_SORT_FUNCTIONS,
  SortKeys,
  useProjectDropdownPreferences,
  GroupKeys,
} from './sortFilter';
import {
  ItemGroupType,
  BasicItemType,
} from '../SelectItemDropdown/SelectItemList';
import SelectItemDropdown from '../SelectItemDropdown/SelectItemDropdown';

import styles from './ProjectDropdown.module.less';

interface ProjectDropdownTriggerProps {
  currentMission?: Project;
  placeholder?: string;
  multiSelect?: boolean;
  selectedItems: BasicItemType[];
  totalItemCount: number;
  clearable?: boolean;
  onClear?: () => void;
}

type FilterSortOrGroupItems<AllowedKeys> = {
  key: AllowedKeys;
  label: string;
}[];

export const ProjectDropdownTrigger: React.FC<ProjectDropdownTriggerProps> = ({
  currentMission,
  placeholder,
  multiSelect,
  selectedItems = [],
  totalItemCount,
  clearable,
  onClear,
}) => {
  return (
    <div>
      <DropdownContainer>
        <DropdownTrigger clearable={clearable} onClear={onClear}>
          <div className={styles.triggerContent}>
            {currentMission && selectedItems.length < totalItemCount && (
              <div className={styles.triggerText}>
                {currentMission.data?.title}
              </div>
            )}
            {multiSelect &&
              selectedItems.length > 1 &&
              selectedItems.length < totalItemCount && (
                <div className='pill-message blue small equal-padding'>
                  +{selectedItems.length - 1}
                </div>
              )}
            {multiSelect &&
              !_.isEmpty(selectedItems) &&
              selectedItems.length === totalItemCount && <>{placeholder}</>}
            {!currentMission && _.isEmpty(selectedItems) && (
              <div className={styles.triggerPlaceholder}>{placeholder}</div>
            )}
          </div>
        </DropdownTrigger>
      </DropdownContainer>
    </div>
  );
};

interface ProjectDropdownProps {
  projects: Project[];
  disabledProjectIds?: string[];
  currentMission?: Project;
  onProjectSelected: (project: Project, value?: boolean) => void;
  selectedProjectIds?: string[];
  placeholder?: string;
  clearable?: boolean;
  onClear?: () => void;
  initialOpen?: boolean;
}

const ProjectDropdown: FC<ProjectDropdownProps &
  Partial<ComponentProps<typeof SelectItemDropdown>>> = ({
  projects,
  disabledProjectIds = [],
  currentMission,
  onProjectSelected,
  placeholder,
  selectedProjectIds,
  style,
  trigger,
  className,
  clearable,
  onClear,
  initialOpen = false,
  ...dropdownProps
}) => {
  const clientId = useClientId();
  const { data: user } = useUserFromJWToken();
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const {
    sortKey,
    filterKey,
    groupKey,
    updateSortKey,
    updateFilterKey,
    updateGroupKey,
  } = useProjectDropdownPreferences();

  const projectMapById = useMemo(() => _.indexBy(projects, 'id'), [projects]);

  const searchedProjects = useMemo(() => {
    if (!searchQuery) {
      return projects;
    }

    return _.filter(projects, (project) => {
      if (!searchQuery) {
        // otherwise typescript is not happy
        return true;
      }
      const unaccentedTitle = lowerCaseAndUnaccent(project.data?.title || '');
      const unaccentedSearchString = lowerCaseAndUnaccent(searchQuery);
      return unaccentedTitle.indexOf(unaccentedSearchString) >= 0;
    });
  }, [projects, searchQuery]);

  const filteredProjects = useMemo(() => {
    if (!PROJECT_FILTER_FUNCTIONS[filterKey]) {
      return searchedProjects;
    }
    return PROJECT_FILTER_FUNCTIONS[filterKey]([...(searchedProjects || [])], {
      currentUser: user,
    });
  }, [searchedProjects, filterKey, user]);

  const sortedProjects = useMemo(() => {
    if (!PROJECT_SORT_FUNCTIONS[sortKey]) {
      return filteredProjects;
    }

    return PROJECT_SORT_FUNCTIONS[sortKey]([...filteredProjects], {
      currentUser: user,
    });
  }, [filteredProjects, sortKey, user]);

  const items: BasicItemType[] = useMemo(
    () =>
      _.map(sortedProjects, (project) => {
        return {
          content: project.data?.title || '',
          disabled: disabledProjectIds.includes(project.id),
          id: project.id,
          searchableText: project.data?.title || '',
        };
      }),
    [sortedProjects, disabledProjectIds],
  );

  const SORT_BY_ITEMS: FilterSortOrGroupItems<SortKeys> = useMemo(
    () => [
      {
        key: 'name',
        label: t('common.sorting.alphabetically'),
      },
      {
        key: 'author',
        label: t('profile.contact.sorting.byAuthor'),
      },
      {
        key: 'creation-date',
        label: t('common.sorting.creationDate'),
      },
      {
        key: 'last-use-by-me',
        label: t('common.sorting.lastUseByMe'),
      },
    ],
    [t],
  );

  const FILTER_BY_ITEMS: FilterSortOrGroupItems<FilterKeys> = useMemo(
    () => [
      {
        key: 'all-projects',
        label: t('profile.contact.filtering.allProjects'),
      },
      {
        key: 'created-by-me',
        label: t('profile.contact.filtering.projectsCreatedByMe'),
      },
    ],
    [t],
  );

  const GROUP_BY_ITEMS: FilterSortOrGroupItems<GroupKeys> = useMemo(
    () => [
      {
        key: 'departments',
        label: 'By departments',
      },
    ],
    [],
  );

  // Add "all group"
  const itemGroup: ItemGroupType = useMemo(() => {
    return {
      items,
      groupId: 'all-projects-group',
      groupLabel:
        filterKey === 'created-by-me'
          ? t('profile.contact.filtering.projectsCreatedByMe')
          : t('profile.contact.filtering.allProjects'),
      currentFilterKey: filterKey,
      currentSortKey: sortKey,
      currentGroupKey: groupKey,
      sortBy: SORT_BY_ITEMS,
      filterBy: FILTER_BY_ITEMS,
      groupBy: GROUP_BY_ITEMS,
      filteredEmptyState: (
        <DropdownGenericEmptyState
          title={t('profile.contact.filtering.missions.noResultsTitle')}
          description={t(
            'profile.contact.filtering.missions.noResultsDescription',
          )}
        />
      ),
    };
  }, [
    t,
    items,
    filterKey,
    sortKey,
    SORT_BY_ITEMS,
    FILTER_BY_ITEMS,
    GROUP_BY_ITEMS,
    groupKey,
  ]);

  const selectedItems = _.filter(items, (item) =>
    _.contains(selectedProjectIds || [], item.id),
  );

  return (
    <SelectItemDropdown
      initialOpen={initialOpen}
      totalItemCount={projects?.length || 0}
      groups={[itemGroup]}
      onItemSelected={({ id }) => onProjectSelected(projectMapById[id])}
      onSearchChanged={setSearchQuery}
      searchValue={searchQuery}
      searchPlaceholder={t('missions.search.placeholder')}
      trigger={
        trigger || (
          <DropdownControlsContext.Consumer>
            {({ toggleDropdown }) => (
              <button
                type='button'
                onClick={toggleDropdown}
                className={styles.trigger}
              >
                <ProjectDropdownTrigger
                  currentMission={currentMission}
                  placeholder={placeholder}
                  clearable={clearable}
                  onClear={onClear}
                  selectedItems={selectedItems || []}
                  totalItemCount={projects?.length || 0}
                />
              </button>
            )}
          </DropdownControlsContext.Consumer>
        )
      }
      emptyState={<MissionDropdownEmptyState clientId={clientId} />}
      emptyStateWithSearch={
        <SequenceDropdownNoResultsState
          search={searchQuery || ''}
          setSearchFilter={setSearchQuery}
        />
      }
      onGroupSorted={(_groupId, groupSortKey) =>
        updateSortKey((groupSortKey || 'name') as SortKeys)
      }
      onGroupFiltered={(_groupId, groupFilterKey) =>
        updateFilterKey((groupFilterKey || 'all-projects') as FilterKeys)
      }
      onGroupGrouped={(_groupId, groupGroupKey) =>
        updateGroupKey(groupGroupKey)
      }
      style={{
        ...style,
        ...(!style?.minWidth && {
          minWidth: '250px',
        }),
      }}
      selectedItemIds={_.pluck(selectedItems, 'id')}
      className={classnames(styles.dropdown, className)}
      {...dropdownProps}
    />
  );
};

export default ProjectDropdown;
