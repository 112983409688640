import React, { SyntheticEvent, useState } from 'react';
import { DropdownProps, Modal, Select } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import _ from 'underscore';

import { getUsersOptions } from '@/revealComponents/Modals/AddToATSJobModal';
import { GET_USER_SETTINGS, UPDATE_ATS_USER } from '@/graphql/users';
import { useAtsJobsAndUsers } from '@/components/NewRevealJobModal';
import { ATStype } from '@/common/reveal';
import { getATSName } from '@/common/reveal/utils';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import { lowerCaseAndUnaccent } from '../../../../../common/index';

import styles from './LinkedUserSelectionModal.module.less';

interface LinkedUserSelectionModalProps {
  open: boolean;
  atsType: ATStype;
  setOpen: (value: boolean) => void;
  clientId: string;
  atsUserId: string;
}

const LinkedUserSelectionModal: React.FC<LinkedUserSelectionModalProps> = ({
  open,
  atsType,
  setOpen,
  clientId,
  atsUserId,
}) => {
  const [ATSUserInput, setATSUserInput] = useState({});
  const { data: atsFilterOptionsData } = useAtsJobsAndUsers(clientId);

  const atsUsersOptions = getUsersOptions({
    atsFilterOptionsData,
  });
  const { t } = useTranslation();

  const [updateATSUser] = useMutation(UPDATE_ATS_USER);

  const handleATSUserChange = (
    _e: SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
  ) => {
    const selectedUserId = data.value;
    const user = _.find(
      atsUsersOptions,
      (atsUserOption) => atsUserOption.value === selectedUserId,
    );
    setATSUserInput({
      atsType,
      userName: user?.text,
      userId: user?.value,
    });
  };

  const handleConfirmATSUser = () => {
    updateATSUser({
      variables: {
        input: ATSUserInput,
      },
      refetchQueries: [
        {
          query: GET_USER_SETTINGS,
          variables: {
            searchPoolId: 'reveal',
          },
        },
      ],
    });
  };

  return (
    <GenericModal open={open} onClose={() => setOpen(false)} size='mini'>
      <Modal.Header>
        {t('settings.linkedATSUser.modal.title', {
          ATS: getATSName(atsType),
        })}
      </Modal.Header>
      <Modal.Content>
        <p className={styles.subtitle}>
          {t('settings.linkedATSUser.modal.subtitle', {
            ATS: getATSName(atsType),
          })}
        </p>
        <Select
          fluid
          search={(options, query) => {
            return _.filter(
              options,
              (option) =>
                lowerCaseAndUnaccent(option.text as string).indexOf(
                  lowerCaseAndUnaccent(query),
                ) >= 0,
            );
          }}
          options={atsUsersOptions || []}
          onChange={handleATSUserChange}
          defaultValue={atsUserId}
          placeholder={t('settings.linkedATSUser.modal.placeholder', {
            ATS: getATSName(atsType),
          })}
        />
      </Modal.Content>
      <Modal.Actions>
        <div className={styles.modalActions}>
          <GenericButton
            size='big'
            primacy='secondary'
            onClick={() => setOpen(false)}
          >
            {t('common.cancel')}
          </GenericButton>
          <GenericButton
            size='big'
            onClick={() => {
              handleConfirmATSUser();
              setOpen(false);
            }}
          >
            {t('common.save')}
          </GenericButton>
        </div>
      </Modal.Actions>
    </GenericModal>
  );
};

export default LinkedUserSelectionModal;
