import React from 'react';
import ProfileDimmer from '@/components/Dimmer/ProfileDimmer';
import ProfilePreview from '../ProfilePreview';
import ClientProfileLoader from './ClientProfileLoader';

const ClientProfileModal = ({
  clientId,
  user,
  // client profile
  clientProfileId,
  onMarkHidden,
  onMarkUnhidden,
  setProfilesInJobIds,
  searches,
  onClose,
}) => {
  return (
    <ClientProfileWrapper onClose={onClose}>
      <ProfilePreview
        clientId={clientId}
        user={user}
        profileId={clientProfileId}
        // profileSearchPoolState={profileSearchPoolState} // TODO: fix
        // searchPoolId={searchPoolId} // TODO: fix
        onMarkHidden={onMarkHidden}
        onMarkUnhidden={onMarkUnhidden}
        setProfilesInJobIds={setProfilesInJobIds}
        searches={searches}
      />
    </ClientProfileWrapper>
  );
};

const ClientProfileWrapper = ({ children, onClose }) => (
  <div className='profile-preview-container marketplace-profile'>
    <ProfileDimmer onClose={onClose} />
    {children}
  </div>
);

const ClientProfileModalContainer = (props) => {
  const {
    searches,
    searchPoolProfileId,
    searchPoolId,
    onClose,
    clientProfileId,
    clientId,
  } = props;
  if (clientProfileId) {
    return <ClientProfileModal {...props} />;
  }
  if (searchPoolProfileId && searchPoolId) {
    return (
      <ClientProfileWrapper onClose={onClose}>
        <ClientProfileLoader
          clientId={clientId}
          searches={searches}
          searchPoolId={searchPoolId}
          searchPoolProfileId={searchPoolProfileId}
        />
      </ClientProfileWrapper>
    );
  }
  return null;
};

export default ClientProfileModalContainer;
