import _, { compose } from 'underscore';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useApolloClient } from '@apollo/client';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import GenericButton from '@/components/Common/GenericButton';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import useScheduleEmail from '@/graphql/hooks/searchPoolProfile/useScheduleEmail';
import { sanitizeTypename } from '@/common/utils/apollo';
import { getUserClientMailAccount } from '@/hocs/email/sendEmailQuery';
import { SNIPPET_TYPES } from '@/common/mergeTags/utils';
import { hasProfileBounced } from '@/common/helpers/profile';
import WarningModal from '../../../../../components/modals/WarningModal';
import withUserSettings from '../../../../../hocs/users/withUserSettings';
import withSendRevealEmail from '../../../../../hocs/email/withSendRevealEmail';
import { withLastProfileActionsContextConsumer } from '../../../../../context/LastProfileActionsContext';

import ConfirmationModal from './ConfirmationModal';
import ForceSendModal from './ForceSendModal';
import UnlockProfileModal from './UnlockProfileModal';

import { sentryCaptureException, textToId } from '../../../../../common';
import { getNamesInFirstLine, getThreadId } from './sendEmailHelpers';
import { getOSFromPlatform } from './ConfirmationModal/helpers';
import {
  cleanString,
  detectSuspiciousEmailContent,
} from '../../../../../common/detectSuspiciousEmailContent';
import withClient from '../../../../../hocs/clients/withClient';
import { invalidEmailFormat } from '../../../../../common/validators';

const BUTTON_LOCK_DURATION_IN_MS = 5000;
const WRONG_NAME_IN_EMAIL = 'wrong_name_in_email';
const SUSPICIOUS_CONTENT = 'suspicious_content';
const HAS_XX = 'has_xx';
const SHORT_BODY_WARNING = 'short-body-email-warning';
const SHORT_SUBJECT_WARNING = 'short-subject-email-warning';
const OS_CODE = getOSFromPlatform();
const MOD_KEY = OS_CODE === 'mac' ? '⌘' : 'Ctrl';

const performSendEmail = async ({
  sendEmail,
  notification,
  addProfileAction,
  refreshProfile,
  t,
  clientId,
  profileId,
  sequenceId,
  actionId,
  mailData,
  client,
  displayHotKeyTip,
  task,
  assignedSender,
  searchPoolId,
}) => {
  try {
    await sendEmail({
      ...mailData,
      profileId,
      sequenceId,
      actionId,
      client,
      task,
      assignedSender,
      searchPoolId,
    });
  } catch (e) {
    console.error(e);
    sentryCaptureException({ error: e, tags: { feature: 'mail' } });
    notification.error(t('profile.notifications.emailSentError'));
    // refreshProfile({ profileId });
    return;
  }

  // refreshProfile({ profileId });

  const message = displayHotKeyTip ? (
    <>
      <span>{t('profile.notifications.emailSentWithSuccess')}</span>
      <br />
      <span>
        {t('profile.notifications.emailSentWithSuccessTip.youCanAlsoHit')}
      </span>
      <span className='code-text'>{MOD_KEY}</span>
      <span> + </span>
      <span className='code-text'>{t('common.keyEnter')}</span>
      <span>{t('profile.notifications.emailSentWithSuccessTip.toSend')}</span>
    </>
  ) : (
    t('profile.notifications.emailSentWithSuccess')
  );

  notification.success(message);

  try {
    const lastProfileAction = {
      clientId,
      profileId,
      type: 'send',
    };
    addProfileAction(lastProfileAction);
  } catch (e) {
    console.error(e);
  }
};

const SendEmailButton = ({
  clientId,
  client,
  sequenceId,
  actionId,
  profileId,
  profile,
  onSend,
  mailData,
  emailApi,
  addProfileAction,
  refreshProfile,
  task,
  assignedSender,
  searchPoolId,
  targetThread,
  shouldUseThread,
  allowSchedule,
  // These props come from withSendRevealEmail
  sendEmail,
  getEmailSenderAndTracking,
}) => {
  const notification = useNotificationSystem();
  const { t } = useTranslation();
  const { currentSequenceWithDeepActionsSnippets } = useCandidateViewContext();
  const isPlugin = useIsPlugin();
  const apolloClient = useApolloClient();
  const [sending, setSending] = useState(false);

  const hasBounced = useMemo(() => hasProfileBounced(profile), [profile]);

  const scheduleEmail = useScheduleEmail({
    onCompleted: () => {
      // Toast success
      notification.success(t('profile.contact.drafts.scheduleModal.success'));
    },
    onError: (error) => {
      // toast error
      console.error(error);
      notification.success(t('profile.contact.drafts.scheduleModal.error'));
    },
  });

  const [state, setState] = useState({
    open: false,
    openWithHotKey: false,
    forceSendModal: null,
    warningMessage: '',
  });
  const lockProfileUntil = {};

  const handleOpenModal = ({ sendWithHotKey }) => {
    setState((prev) => ({
      ...prev,
      open: true,
      openWithHotKey: sendWithHotKey,
    }));
  };

  const handleCancel = () => {
    setState((prev) => ({
      ...prev,
      open: false,
      openWithHotKey: null,
    }));
  };

  const handleOpenForceSendModal = ({
    title,
    content,
    question,
    options = {},
  }) => {
    setState((prev) => ({
      ...prev,
      forceSendModal: {
        title,
        content,
        question,
        options,
      },
    }));
  };

  const handleCancelForceSendModal = () => {
    setState((prev) => ({
      ...prev,
      forceSendModal: null,
    }));
  };

  const raiseWarningModal = (warningMessage) => {
    setState((prev) => ({
      ...prev,
      warningMessage,
    }));
  };

  const closeWarningModal = () => {
    setState((prev) => ({
      ...prev,
      warningMessage: null,
    }));
  };

  const checkEmail = ({ subject, body, firstname }) => {
    if (!_.isString(body) || body?.length < 10) {
      throw Error(SHORT_BODY_WARNING);
    }
    if (!_.isString(subject) || subject?.length < 8) {
      throw Error(SHORT_SUBJECT_WARNING);
    }

    const namesInFirstLine = getNamesInFirstLine(body, firstname);
    if (namesInFirstLine.length > 0) {
      throw Error(WRONG_NAME_IN_EMAIL);
    }
    const suspiciousContent = detectSuspiciousEmailContent(cleanString(body));
    if (_.contains(suspiciousContent, 'hellofirstname')) {
      throw Error(SUSPICIOUS_CONTENT);
    }
    const hasXX = /XX/.test(body);
    if (hasXX) {
      throw Error(HAS_XX);
    }
  };

  const handleSubmit = async (
    force,
    { sendWithHotKey = false, date, timezone } = {},
  ) => {
    if (!profileId) {
      return;
    }

    const timestamp = Date.now();
    if (
      lockProfileUntil[profileId] &&
      lockProfileUntil[profileId] > timestamp &&
      !force
    ) {
      return;
    }
    lockProfileUntil[profileId] = timestamp + BUTTON_LOCK_DURATION_IN_MS;

    setState((prev) => ({
      ...prev,
      open: false,
      openWithHotKey: null,
      forceSendModal: null,
    }));

    const defaultThreadId = getThreadId({
      shouldUseThread,
      targetThread,
      currentAddress: emailApi.currentAddress,
      profile,
    });

    const mailDataWithThread = {
      ...mailData,
      ...(defaultThreadId && { defaultThreadId }),
    };

    // Check integrity in email (especially the profile name)
    if (!force) {
      try {
        checkEmail({
          subject: mailDataWithThread.subject,
          body: mailDataWithThread.body,
          firstname: mailDataWithThread.firstname,
        });
      } catch (e) {
        if (
          !_.contains(
            [
              WRONG_NAME_IN_EMAIL,
              SUSPICIOUS_CONTENT,
              HAS_XX,
              SHORT_BODY_WARNING,
              SHORT_SUBJECT_WARNING,
            ],
            e?.message,
          )
        ) {
          sentryCaptureException({ error: e, tags: { feature: 'mail' } });
        }
        if (e.message === WRONG_NAME_IN_EMAIL && !force) {
          handleOpenForceSendModal({
            title: t('profile.contact.drafts.wrongNameModal.title'),
            content: t('profile.contact.drafts.wrongNameModal.content'),
            question: t('profile.contact.drafts.wrongNameModal.question'),
            options: { sendWithHotKey, date, timezone },
          });
          return;
        }
        if (e.message === SUSPICIOUS_CONTENT) {
          handleOpenForceSendModal({
            title: t('profile.contact.drafts.suspiciousContent.title'),
            content: t('profile.contact.drafts.suspiciousContent.content'),
            question: t('profile.contact.drafts.suspiciousContent.question'),
            options: { sendWithHotKey, date, timezone },
          });
          return;
        }
        if (e.message === HAS_XX) {
          handleOpenForceSendModal({
            title: t('profile.contact.drafts.hasXX.title'),
            content: t('profile.contact.drafts.hasXX.content'),
            question: t('profile.contact.drafts.hasXX.question'),
            options: { sendWithHotKey, date, timezone },
          });
          return;
        }
        if (e.message === SHORT_BODY_WARNING) {
          handleOpenForceSendModal({
            title: t('profile.contact.drafts.shortBodyWarning.title'),
            content: t('profile.contact.drafts.shortBodyWarning.content'),
            question: t('profile.contact.drafts.shortBodyWarning.question'),
            options: { sendWithHotKey, date, timezone },
          });
          return;
        }
        if (e.message === SHORT_SUBJECT_WARNING) {
          handleOpenForceSendModal({
            title: t('profile.contact.drafts.shortSubjectWarning.title'),
            content: t('profile.contact.drafts.shortSubjectWarning.content'),
            question: t('profile.contact.drafts.shortSubjectWarning.question'),
            options: { sendWithHotKey, date, timezone },
          });
          return;
        }
        return;
      }
    }

    const displayHotKeyTip = emailApi?.isFirstSessionSend && !sendWithHotKey;

    setSending(true);

    if (date) {
      const getPluginUser = async () => {
        if (isPlugin && !assignedSender?.id) {
          return apolloClient.query({
            query: getUserClientMailAccount,
          });
        }
        return null;
      };

      const getPluginSender = async () => {
        const pluginUser = await getPluginUser();

        const senderEmailAddress = pluginUser?.data?.user?.mailAccount?.address;
        const alias = pluginUser?.data?.user?.alias || '';
        const targetSignature = pluginUser?.data?.user?.signature || '';

        return {
          id: textToId(senderEmailAddress),
          alias,
          signature: targetSignature || '',
          senderAddress: senderEmailAddress,
          mailAccount: {
            idToken: textToId(senderEmailAddress),
            type: pluginUser?.data?.user?.mailAccount?.type,
          },
        };
      };

      // users, mailAccount & owner seem to not be formatted correctly
      let sender = assignedSender
        ? {
            ..._.omit(assignedSender, ['users', 'owner', 'mailAccount']),
            mailAccount: {
              type: assignedSender.mailAccount?.type,
              idToken: textToId(assignedSender.mailAccount?.address),
            },
          }
        : isPlugin
        ? await getPluginSender()
        : null;

      const {
        senderInfo,
        trackingInfo: { trackingMessageId, trackOnEmailOpen, trackOnLinkClick },
        // This method comes from HOC withSendRevealEmail
      } = await getEmailSenderAndTracking({
        profileId,
        assignedSender,
        hiresweetClient: null,
        originalBody: mailDataWithThread.body,
        signature: mailDataWithThread.signature || null,
      });

      if (!sender && !isPlugin) {
        const {
          firstname,
          lastname,
          signature: emailApiSignature,
        } = emailApi.getEmailAddressDetails();

        const { senderEmailAddress, alias, targetSignature } = senderInfo;

        sender = {
          id: textToId(senderEmailAddress),
          firstname,
          lastname,
          alias,
          signature: targetSignature || emailApiSignature || '',
          senderAddress: senderEmailAddress,
          mailAccount: {
            idToken: textToId(senderEmailAddress),
            type: emailApi.currentEmailType,
          },
        };
      }

      // TODO: check if thread is necessary
      await scheduleEmail({
        variables: {
          input: {
            sender: sanitizeTypename(sender),
            actionId,
            timezone,
            sendDate: date,
            profileId,
            trackingMessageId,
            trackOnEmailOpen,
            trackOnLinkClick,
          },
        },
      });
    } else {
      await performSendEmail({
        searchPoolId,
        sendEmail,
        notification,
        addProfileAction,
        refreshProfile,
        t,
        mailData: mailDataWithThread,
        clientId,
        client,
        profileId,
        sequenceId,
        actionId,
        displayHotKeyTip,
        task,
        assignedSender,
      });
    }

    setSending(false);

    setState((prev) => ({
      ...prev,
      open: false,
      forceSendModal: null,
    }));

    emailApi.setIsFirstSessionSend(false);

    if (onSend) {
      onSend({ profileId });
    }
  };

  const handleAskSend = async ({ sendWithHotKey, date, timezone }) => {
    // TODO: refacto subject test with other email checks
    if (
      (!mailData ||
        !_.isString(mailData.body) ||
        mailData?.body?.length < 10) &&
      (mailData?.body?.length || 0) <= 7
    ) {
      raiseWarningModal(t('profile.contact.drafts.bodyEmptyError'));
    } else if (
      (!mailData ||
        !_.isString(mailData.subject) ||
        mailData?.subject?.length < 8) &&
      (mailData?.subject?.length || 0) <= 0
    ) {
      raiseWarningModal(t('profile.contact.drafts.subjectEmptyError'));
    } else if (
      // disabled feature
      false
      // emailApi?.sendConfirmationModeActive &&
      // user?.showConfirmationModal
    ) {
      handleOpenModal({ sendWithHotKey });
    } else {
      handleSubmit(undefined, { sendWithHotKey, date, timezone });
    }
  };

  const handleTrySend = async ({ sendWithHotKey, date, timezone }) => {
    handleAskSend({ sendWithHotKey, date, timezone });
  };

  const hasDynamicVariablesNullValues = useMemo(() => {
    return _.some(currentSequenceWithDeepActionsSnippets.actions, (action) => {
      if (action.completion?.isCompleted) {
        return false;
      }
      return _.some(
        action.snippets,
        (snippet) =>
          snippet.type !== SNIPPET_TYPES.CONDITIONS_CHAINING &&
          !snippet.state.value &&
          !snippet.fallbackValue?.text,
      );
    });
  }, [currentSequenceWithDeepActionsSnippets]);

  const { open, warningMessage, openWithHotKey, forceSendModal } = state;
  if (!profile) return <div />;
  if (!open && profile?.locked) {
    return (
      <div className='send-button-with-lock'>
        <GenericButton disabled>
          {t('profile.contact.drafts.sending')}
        </GenericButton>
        <UnlockProfileModal
          clientId={clientId}
          profileId={profileId}
          profile={profile}
          addProfileAction={addProfileAction}
        />
      </div>
    );
  }
  if (isPlugin && sending) {
    return (
      <div className='send-button-with-lock'>
        <GenericButton disabled>
          {t('profile.contact.drafts.sending')}
        </GenericButton>
      </div>
    );
  }
  return (
    <>
      <WarningModal
        open={!!warningMessage}
        message={warningMessage}
        onCancel={closeWarningModal}
      />
      <ConfirmationModal
        sendDisabled={
          invalidEmailFormat({ email: mailData?.dest }) ||
          hasDynamicVariablesNullValues ||
          hasBounced
        }
        disabledReason={
          hasDynamicVariablesNullValues
            ? 'missing-dynamic-variables'
            : hasBounced
            ? 'email-bounced'
            : 'invalid-email-format'
        }
        open={open}
        openWithHotKey={openWithHotKey}
        onTrigger={handleTrySend}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        emailApi={emailApi}
        clientId={clientId}
        assignedSender={assignedSender}
        allowSchedule={allowSchedule}
      />
      <ForceSendModal
        open={!!forceSendModal}
        onCancel={handleCancelForceSendModal}
        onSubmit={() => handleSubmit(true, forceSendModal.options || {})}
        title={(forceSendModal || {}).title || ''}
        content={(forceSendModal || {}).content || ''}
        question={(forceSendModal || {}).question || ''}
      />
    </>
  );
};

export default compose(
  withUserSettings,
  withSendRevealEmail,
  withLastProfileActionsContextConsumer,
  withClient,
)(SendEmailButton);
