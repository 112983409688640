import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const query = gql`
  query getClientUsers($id: ID!) {
    client(id: $id) {
      id
      users {
        id
        firstname
        lastname
        email
        status
      }
    }
  }
`;

export default graphql(query, {
  options: ({ clientId }) => ({
    variables: { id: clientId },
  }),
  props: ({ data: { client, loading } }) => ({
    users: client && client.users,
    usersLoading: loading,
  }),
});
