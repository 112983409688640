import React, { useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';

import { withTranslation } from 'react-i18next';
import { compose } from 'underscore';

import withUpdateUserSettings from '../../hocs/users/withUpdateUserSettings';
import withClient from '../../hocs/clients/withClient';
import usePluginStatuses from '../../hooks/common/usePluginStatuses';

import { ChromeStoreLink } from '../../components/Links';

import './TrialModal.css';

const TrialModal = ({ open, client, updateUser, t }) => {
  const [isOpen, setIsOpen] = useState(open);

  const crmMode = client?.customizations?.mainMode === 'crm';

  const closeModal = () => {
    updateUser({ firstLogin: false }).then(() => {
      setIsOpen(false);
    });
  };

  if (!client) {
    return null;
  }
  if (!crmMode) {
    return (
      <Modal open={isOpen} size='tiny' className='trial-modal'>
        <div style={{ height: '100%' }}>
          <div className='trial-modal-close' onClick={closeModal}>
            <div className='image-box'>
              <img
                alt='close'
                src='/images/icons/close2.png'
                height='12'
                width='12'
              />
            </div>
          </div>
          <div className='trial-modal-content'>
            <div className='trial-image-container'>
              <img
                alt='logo'
                src='/images/logos/new-logo_without_text_blue.svg'
                height='50'
              />
            </div>
            <h1 className='modal-title'>{t('trial.onboarding.header')}</h1>
            <div className='modal-content'>{t('trial.onboarding.content')}</div>
            <div className='next-step-button' onClick={closeModal}>
              <Button primary size='big'>
                {t('trial.onboarding.launchOffer')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
  return (
    <CRMModeModal
      isOpen={isOpen}
      closeModal={closeModal}
      clientId={client?.id}
      t={t}
    />
  );
};

const CRMModeModal = ({ clientId, isOpen, closeModal, t }) => {
  const { pluginDownloaded } = usePluginStatuses(clientId);
  const [skipPluginDownload, setSkipPluginDownload] = useState(false);
  const [pluginButtonClicked, setPluginButtonClicked] = useState(false);

  return (
    <Modal open={isOpen} size='tiny' dimmer='blurring' className='trial-modal'>
      <div style={{ height: '100%' }}>
        <div className='trial-modal-close' onClick={closeModal}>
          <div className='image-box'>
            <img
              alt='close'
              src='/images/icons/close2.png'
              height='12'
              width='12'
            />
          </div>
        </div>
        <div className='trial-modal-content'>
          {pluginDownloaded || skipPluginDownload ? (
            <CRMModalCreateJobContent closeModal={closeModal} t={t} />
          ) : (
            <CRMModalDownloadPluginContent
              setSkipPluginDownload={setSkipPluginDownload}
              pluginButtonClicked={pluginButtonClicked}
              setPluginButtonClicked={setPluginButtonClicked}
              t={t}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

const CRMModalCreateJobContent = ({ closeModal, t }) => (
  <>
    <div className='trial-image-container'>
      <img
        alt='logo'
        src='/images/logos/new-logo_without_text_blue.svg'
        height='50'
      />
    </div>
    <h1 className='modal-title'>{t('crm.onboarding.createJobModal.title')}</h1>
    <div className='modal-content'>
      {t('crm.onboarding.createJobModal.content')}
    </div>
    <div className='next-step-button' onClick={closeModal}>
      <Button primary size='big'>
        {t('crm.onboarding.createJobModal.cta')}
      </Button>
    </div>
  </>
);

const CRMModalDownloadPluginContent = ({
  setSkipPluginDownload,
  pluginButtonClicked,
  setPluginButtonClicked,
  t,
}) => (
  <>
    <div className='trial-image-container'>
      <img
        alt='logo'
        src='/images/logos/new-logo_without_text_blue.svg'
        height='50'
      />
    </div>
    <h1 className='modal-title'>
      {t('crm.onboarding.downloadPluginModal.title')}
    </h1>
    <div className='modal-content'>
      {t('crm.onboarding.downloadPluginModal.content')}
    </div>

    {!pluginButtonClicked ? (
      <ChromeStoreLink>
        <div className='next-step-button'>
          <Button
            primary
            size='big'
            onClick={() => setPluginButtonClicked(true)}
          >
            {t('pluginSettings.downloadButton')}
          </Button>
        </div>
      </ChromeStoreLink>
    ) : (
      <div className='next-step-button'>
        <Button
          primary
          size='big'
          onClick={() => {
            window.location.reload();
          }}
        >
          {t('crm.onboarding.downloadPluginModal.done')}
        </Button>
      </div>
    )}

    <div className='skip-step-container'>
      <Button className='link-button default' onClick={setSkipPluginDownload}>
        {t('crm.onboarding.downloadPluginModal.skipStep')}
      </Button>
    </div>
  </>
);

export default compose(
  withClient,
  withUpdateUserSettings,
  withTranslation('translations'),
)(TrialModal);
