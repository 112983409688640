import commonEmailProviders from 'email-providers';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { passwordIsStrong } from '../../common';
import { invalidEmailFormat } from '../../common/validators';

export const isBannedProvider = ({ email }) => {
  if (!email) {
    return true;
  }
  const provider = email.split('@')[1];
  if (!provider) {
    return true;
  }
  if (provider === 'gmail.com') {
    return false;
  }
  return commonEmailProviders.includes(provider);
};

export const validateEmail = ({ email, t }) => {
  if (!email || email.trim() === '') {
    return undefined;
  }
  if (invalidEmailFormat({ email })) {
    return {
      type: 'emailError',
      content: t('trial.signup.emailInvalidFormat'),
      pointing: 'below',
    };
  }
  // TODO: enforce work email
  // if (isBannedProvider({ email })) {
  //   return {
  //     type: 'email',
  //     content: t('trial.signup.emailBannedProvider'),
  //     pointing: 'below',
  //   };
  // }
  return undefined;
};

export const validatePassword = ({ password, t }) => {
  if (!password || password.trim() === '') {
    return undefined;
  }
  if (passwordIsStrong(password)) {
    return undefined;
  }
  return {
    type: 'password',
    content: t('trial.signup.passwordIsWeak'),
    pointing: 'below',
  };
};

export const validatePhoneNumber = ({ phoneNumber, t }) => {
  if (!phoneNumber || phoneNumber.trim() === '') {
    return undefined;
  }
  const phoneObject = parsePhoneNumberFromString(phoneNumber);
  if (phoneObject && phoneObject.isValid()) {
    return undefined;
  }
  return {
    type: 'phoneNumber',
    content: t('trial.signup.invalidPhoneNumber'),
    pointing: 'below',
  };
};
