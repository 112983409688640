import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Dropdown, Modal, Button, Segment } from 'semantic-ui-react';

import { getActionType } from '@/common/constants/taskTypes';
import { TYPES_WITH_SUBJECT } from '@/revealComponents/FullContactFlowEditor/FullContactFlowActionEditor/helpers';
import GenericButton from '@/components/Common/GenericButton';
import contextToProps from '../../../../../hocs/contextToProps';

import ActionTriggerEditor from '../../../../../components/contactFlow/ActionTriggerEditor';
import ReadOnlyTemplate from '../../../Offers/components/ReadOnlyTemplate';
import EditTemplate from '../../../Templates/modals/editTemplate';

import './ContactFlowActionEditor.css';

const ContactFlowActionEditor = ({
  clientId,
  index,
  action,
  actions,
  signature,
  permissionsAndNudges,
  onUpdate,
  onDelete,
  onDeleteMultiple,
  templates,
  templateOptions,
  defaultTemplatesOnSeveralOffers,
  onShowNotification,
  onQuitContactFlowEditor,
  t,
}) => {
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [detachModalOpen, setDetachModalOpen] = useState(false);

  const onUpdateActionTemplate = ({ templateId }) => {
    const { message } = action || {};
    const newAction = {
      ...(action || {}),
      message: {
        ...message,
        templateId,
      },
    };
    onUpdate({ action: newAction });
  };

  const handleDelete = async () => {
    if (index + 1 < actions.length) {
      setDetachModalOpen(true);
    } else {
      await onDelete();
      onShowNotification({
        message: t('offers.notifications.detachEmail.success', { count: 1 }),
        level: 'success',
      });
    }
  };

  const { message } = action || {};
  const { templateId } = message || {};
  const messageTemplate = _.findWhere(templates, { id: templateId });
  const { subject, body, isArchived, title } = messageTemplate || {};

  const isFollowup = index > 0;
  const shouldDisplaySubject =
    !isFollowup &&
    _.contains(TYPES_WITH_SUBJECT, getActionType(action)) &&
    subject?.length;

  const templateOptionsWithCurrent = [...templateOptions];
  if (isArchived) {
    templateOptionsWithCurrent.push({
      value: templateId,
      text: `${title} (${t('offers.defaultEmails.archived')})`,
    });
  }
  return (
    <Segment className='contact-flow-action-editor'>
      <div className='action-editor-header'>
        <h3 className='email-header'>
          {`${t('offers.defaultEmails.emailHeader')} ${index + 1}`}
        </h3>

        <GenericButton size='small' primacy='secondary' onClick={handleDelete}>
          <i className='ri-link-unlink-m' />
          {t('offers.defaultEmails.detach')}
        </GenericButton>

        <DetachConfirmationModal
          open={detachModalOpen}
          header={t('offers.defaultEmails.confirmationModal.header')}
          content={
            index === actions.length - 2
              ? t('offers.defaultEmails.confirmationModal.content', {
                  count: 1,
                })
              : t('offers.defaultEmails.confirmationModal.content', {
                  count: 0,
                })
          }
          cancel={t('offers.defaultEmails.confirmationModal.cancel')}
          submit={t('offers.defaultEmails.confirmationModal.submit')}
          onCancel={() => setDetachModalOpen(false)}
          onSubmit={onDeleteMultiple}
        />
      </div>

      <div className='action-editor-config'>
        <div className='action-trigger-container'>
          <ActionTriggerEditor
            permissionsAndNudges={permissionsAndNudges}
            action={action}
            onUpdate={onUpdate}
            extraDescription={
              action?.trigger?.type !== 'manual-trigger' && (
                <div className='trigger-extra-description'>
                  {t('contactFlow.triggers.ifNoAnswer')}
                </div>
              )
            }
          />
        </div>

        <div className='action-template-editor'>
          <div className='template-selector'>
            <h5 className='template-header'>
              {t('offers.defaultEmails.template')}
            </h5>
            <Dropdown
              className='template-dropdown'
              selection
              scrolling
              placeholder={t('offers.defaultEmails.chooseTemplate')}
              options={templateOptionsWithCurrent}
              value={templateId}
              onChange={(e, { value }) =>
                onUpdateActionTemplate({ templateId: value })
              }
            />
          </div>

          <div
            className='edit-template-button'
            role='button'
            onClick={() => setUpdateModalOpen(true)}
          >
            <i className='ri-edit-2-line' />
            <span>{t('offers.defaultEmails.edit')}</span>
          </div>

          <EditTemplate
            clientId={clientId}
            template={messageTemplate}
            templateId={action?.message?.templateId}
            open={updateModalOpen}
            onClose={() => setUpdateModalOpen(false)}
            defaultTemplatesOnSeveralOffers={defaultTemplatesOnSeveralOffers}
            onQuitContactFlowEditor={onQuitContactFlowEditor}
          />
        </div>
      </div>

      <div className='action-editor-template'>
        {messageTemplate && (
          <ReadOnlyTemplate
            clientId={clientId}
            subject={subject}
            body={body}
            signature={signature}
            shouldDisplaySubject={shouldDisplaySubject}
          />
        )}
      </div>
    </Segment>
  );
};

const DetachConfirmationModal = (props) => {
  const { open, header, content, cancel, submit, onCancel, onSubmit } = props;
  return (
    <Modal open={open} onClose={onCancel} closeIcon size='tiny'>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>
        <div className='align-left'>
          <Button className='dismiss' type='button' onClick={onCancel}>
            {cancel}
          </Button>
        </div>
        <Button secondary size='big' type='button' onClick={onSubmit}>
          {submit}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default compose(
  withTranslation('translations'),
  contextToProps,
)(ContactFlowActionEditor);
