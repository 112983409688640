import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  HiresweetLibProvider,
  ProjectApplicantsOverview,
  ProjectsSetContainer,
} from '@hiresweet/hiresweet-lib';

import useClientId from '@/hooks/router/useClientId';

const PlaygroundT2 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  const history = useHistory();
  return (
    <div style={{ padding: 30 }}>
      <h1>P-T-2 - Project applicants overview</h1>
      <HiresweetLibProvider
        theme={theme}
        clientId={clientId}
        actions={{
          openProjectPage: (projectId) =>
            history.push(
              `/client/${clientId}/reveal/projects/recruiting/${projectId}`,
            ),
        }}
      >
        <ProjectsSetContainer
          filter={{
            ownerEmail: 'ismael@hiresweet.com',
          }}
        >
          <ProjectApplicantsOverview />
        </ProjectsSetContainer>
      </HiresweetLibProvider>
    </div>
  );
};

export default PlaygroundT2;
