import _ from 'underscore';
import { isEmailAction } from '@/common/constants/taskTypes';
import { Thread } from '@/types/contactData';
import { ContactFlow } from '@/types/contactFlow';
import { ContactFlowSequenceActions } from '@/types/searchPoolProfile';

export const findLastThread = ({
  threads,
}: {
  threads: Thread[];
}): Thread | undefined => {
  if (_.isEmpty(threads)) {
    return undefined;
  }

  if (_.any(threads, ({ lastMessageTimestamp }) => !lastMessageTimestamp)) {
    return _.last(threads);
  }

  let lastThread = threads[0];
  _.forEach(threads, (thread) => {
    if (!thread.lastMessageTimestamp) {
      return;
    }
    if (
      !lastThread.lastMessageTimestamp ||
      thread.lastMessageTimestamp > lastThread.lastMessageTimestamp
    ) {
      lastThread = thread;
    }
  });

  return lastThread;
};

export const findLastSendEmailCompletedAction = ({
  contactFlow,
}: {
  contactFlow?: ContactFlow;
}): ContactFlowSequenceActions | undefined => {
  const sequences = contactFlow?.sequences || [];
  const actions = _.flatten(_.pluck(sequences, 'actions'));
  const emailCompletedActions = _.filter(
    actions,
    (action) => isEmailAction(action) && !!action?.completion?.isCompleted,
  );
  if (_.isEmpty(emailCompletedActions)) {
    return undefined;
  }
  let lastEmail = emailCompletedActions[0];
  _.forEach(emailCompletedActions, (action) => {
    if (!action?.completion?.completionDate) {
      return;
    }
    if (
      !lastEmail?.completion?.completionDate ||
      action?.completion?.completionDate > lastEmail?.completion?.completionDate
    ) {
      lastEmail = action;
    }
  });
  return lastEmail;
};
