import classNames from 'classnames';
import _ from 'underscore';
import moment from 'moment';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Dropdown, DropdownProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { TimeSpan } from '../RevealAnalyticsHeader/RevealAnalyticsHeader';

import './GraphWrapper.css';
import { ChartType, Frequency, Mode } from './types';

const FREQUENCY: Frequency[] = ['day', 'week', 'month', 'quarter'];
const CHART_TYPES: { value: ChartType; icon: string }[] = [
  {
    value: 'bar',
    icon: 'ri-bar-chart-fill',
  },
  {
    value: 'line',
    icon: 'ri-line-chart-line',
  },
];

interface GraphWrapperProps {
  timeSpan: TimeSpan;
  lockChartType?: boolean;
  lockMode?: boolean;
  lockFrequency?: boolean;
  title?: ReactNode;
  extraHeaderContent?: ReactNode;
  renderChildren: (
    frequency: Frequency,
    mode: Mode,
    type: ChartType,
  ) => ReactNode;
}

const GraphWrapper: FC<GraphWrapperProps> = ({
  timeSpan,
  lockChartType,
  lockFrequency,
  lockMode,
  title,
  extraHeaderContent = null,
  renderChildren,
}) => {
  const { t } = useTranslation();

  const [frequency, setFrequency] = useState<Frequency>('quarter'); // overridden by useEffects
  const [mode, setMode] = useState<Mode>('number');
  const [chartType, setChartType] = useState<ChartType>('bar');

  const onChangeFrequency = (
    _e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
  ) => {
    setFrequency(data.value as Frequency);
  };

  // NOTE: second useEffect for init value below
  // Reset frequency when timeSpan is updated
  useEffect(() => {
    const duration = moment
      .duration(timeSpan.endDate.diff(timeSpan.startDate))
      .asDays();

    switch (timeSpan.id) {
      case '7days':
      case 'week':
      case '30days':
      case 'month':
        setFrequency('day');
        return;
      case '60days':
        setFrequency('week');
        return;
      case '180days':
      case 'quarter':
      case '365days':
      case 'year':
        setFrequency('month');
        return;
      case 'allTime':
        setFrequency('quarter');
        return;
      case 'custom':
        if (duration < 30) {
          setFrequency('day');
          return;
        }

        if (duration < 60) {
          setFrequency('week');
          return;
        }

        if (duration < 250) {
          setFrequency('month');
          return;
        }

        setFrequency('quarter');
        break;
      default:
    }
  }, [timeSpan]);

  // Actual default initial value
  useEffect(() => {
    setFrequency('week');
  }, []);

  const onChangeMode = (newMode: Mode) => () => {
    if (newMode !== mode) {
      setMode(newMode);
    }
  };

  const onChangeChartType = (
    _e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
  ) => {
    setChartType(data.value as ChartType);
  };

  const frequencyOptions = () => {
    const formatValue = (value: Frequency) => ({
      key: value,
      value,
      text: t(`analytics.frequency.${value}`),
    });
    return _.map(FREQUENCY, formatValue);
  };

  const chartTypeOptions = () => {
    const formatValue = (option: { value: ChartType; icon: string }) => {
      const { value, icon } = option;
      return {
        key: value,
        value,
        text: (
          <span className='dropdown-item'>
            <i className={icon} />
            {t(`analytics.chartType.${value}`)}
          </span>
        ),
      };
    };
    return _.map(CHART_TYPES, formatValue);
  };

  return (
    <div className='graph-wrapper'>
      <div className='header'>
        {title && <div className='title'>{title}</div>}
        <div className='controls'>
          {extraHeaderContent}
          {!lockFrequency && (
            <Dropdown
              className='dropdown hiresweet-rounded'
              selection
              onChange={onChangeFrequency}
              value={frequency}
              options={frequencyOptions()}
            />
          )}
          {!lockChartType && (
            <Dropdown
              className='dropdown hiresweet-rounded'
              selection
              onChange={onChangeChartType}
              value={chartType}
              options={chartTypeOptions()}
            />
          )}
          {!lockMode && (
            <div className='buttons'>
              <Button
                onClick={onChangeMode('number')}
                className={classNames('type', {
                  isActive: mode === 'number',
                })}
              >
                <i className='ri-hashtag' />
                {t('reveal.analyticsView.tasks.chart.filters.number')}
              </Button>
              <Button
                onClick={onChangeMode('percentage')}
                className={classNames('type', {
                  isActive: mode === 'percentage',
                })}
              >
                <i className='ri-percent-line' />
                {t('reveal.analyticsView.tasks.chart.filters.percentage')}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className='content'>
        {renderChildren(frequency, mode, chartType)}
      </div>
    </div>
  );
};

export default GraphWrapper;
