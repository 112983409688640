import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import ActionItemContainer from '@/revealComponents/ProfileContactFlow/Sequences/RevealPendingActions/ActionItemContainer';
import ActionItemHeader from '@/revealComponents/ProfileContactFlow/Sequences/RevealPendingActions/ActionItemHeader';
import EmailActionItem from '@/revealComponents/ProfileContactFlow/Sequences/RevealPendingActions/EmailActionItem';
import { getTranslatedText } from '@/common';
import MarkTaskAsCompletedButton from '@/revealComponents/ProfileContactFlow/Sequences/RevealPendingActions/MarkTaskAsCompletedButton';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import useClientId from '@/hooks/router/useClientId';
import { useTaskCanSnooze } from '@/hooks/common/useTaskCanSnooze';
import { useClientSnoozeReasons } from '@/graphql/hooks/clients/useClientSnoozeReasons';
import _ from 'underscore';
import { useClientConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import SnoozeTask from '../ProfileContactFlow/Sequences/RevealPendingActions/SnoozeTask';
import MetaTaskForm from './MetaTaskForm';
import { useHelpTextCTA } from '../ProfileContactFlow/Sequences/RevealPendingActions/useHelpTextCTA';

interface ExplicitDueTaskProps {
  task: any;
  onEdit: () => void;
  onDelete: () => void;
  onSnooze?: () => void;
}

const ExplicitTaskDue: React.FC<ExplicitDueTaskProps> = ({
  task,
  onEdit,
  onDelete,
  onSnooze,
}) => {
  const { isMinimized } = useMinimizedView();
  const { t } = useTranslation();
  const {
    profile,
    currentTask,
    snoozeExplicitTask,
  } = useCandidateViewContext();
  const [snoozeModalOpen, setSnoozeModalOpen] = useState(false);
  const { configurationParams } = useClientConfigurationParams();

  const innerOnSnooze = ({
    snoozeDate,
    snoozeReasonId,
    snoozeReasonTitle,
    snoozeComment,
  }: {
    snoozeDate: Date;
    snoozeReasonId?: string;
    snoozeReasonTitle?: string;
    snoozeComment?: string;
  }) => {
    snoozeExplicitTask({
      ...task,
      dueDate: snoozeDate,
      ...(!_.isEmpty(snoozeReasonId) && { snoozeReasonId, snoozeReasonTitle }),
      ...(snoozeComment && { snoozeComment }),
    });
    if (onSnooze) {
      onSnooze();
    }
  };

  const hasContent = !!useMemo(() => {
    return (
      task.message?.body?.length ||
      task.message?.subject?.length ||
      task.description?.default
    );
  }, [task]);

  const clientId = useClientId();
  const { snoozeReasons } = useClientSnoozeReasons(clientId);

  const actionHelpTextCTA = useHelpTextCTA({
    profile,
    action: task,
  });

  const { canSnooze } = useTaskCanSnooze(task);

  return (
    <ActionItemContainer
      action={task}
      isLast={false}
      isBox
      withIcon={false}
      withBorder={currentTask?.id === task?.id}
    >
      <ActionItemHeader action={task} task={task} withIcon />
      <MetaTaskForm helpTextCTA={actionHelpTextCTA} />
      {hasContent && (
        <div className={`action-content ${isMinimized ? 'minimized' : ''}`}>
          {task.message && (
            <EmailActionItem message={task.message} showSubject />
          )}

          {!!getTranslatedText(task?.description) && (
            <span className='action-description'>
              {getTranslatedText(task?.description)}
            </span>
          )}
        </div>
      )}

      <div
        className={`pending-action-buttons ${isMinimized ? 'minimized' : ''}`}
      >
        <div className='mark-completed-button'>
          <MarkTaskAsCompletedButton task={task} />
        </div>
        {canSnooze && (
          <div className='snooze-button'>
            <Button
              className='pending-action-button'
              basic
              onClick={() => setSnoozeModalOpen(true)}
            >
              <i className='ri-rest-time-line' />
              {!isMinimized && (
                <>
                  {t('reveal.candidatesView.timeline.snooze.label')}
                  <i className='ri-arrow-drop-down-fill' />
                </>
              )}
            </Button>
            {!isMinimized && snoozeModalOpen && (
              <SnoozeTask
                snoozeReasons={snoozeReasons}
                setSnoozeModalOpen={setSnoozeModalOpen}
                onConfirm={innerOnSnooze}
                maxSnoozeDate={task?.snoozeConfiguration?.maxSnoozeDate}
              />
            )}
          </div>
        )}
        {configurationParams?.shouldHideDeleteTask !== 'true' && (
          <div className='remove-button'>
            <Button className='pending-action-button' basic onClick={onDelete}>
              <i className='ri-close-fill' />
              {!isMinimized && t('common.delete')}
            </Button>
          </div>
        )}

        {configurationParams?.shouldHideEditTask !== 'true' && (
          <div className='edit-button'>
            <Button className='pending-action-button' basic onClick={onEdit}>
              <i className='ri-pencil-line' />
              {!isMinimized && t('common.edit')}
            </Button>
          </div>
        )}
      </div>
      {isMinimized && snoozeModalOpen && (
        <SnoozeTask
          snoozeReasons={snoozeReasons}
          setSnoozeModalOpen={setSnoozeModalOpen}
          onConfirm={innerOnSnooze}
          maxSnoozeDate={task?.snoozeConfiguration?.maxSnoozeDate}
        />
      )}
    </ActionItemContainer>
  );
};

export default ExplicitTaskDue;
