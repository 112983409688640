import React from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';
import './RelevantActivities.css';
import sanitizeTimeLineItem from '../../../../common/sanitizeTimeLineItem';

const RelevantActivities = ({ relevantActivities, t }) => (
  <div className='relevant-activities'>
    <h3>{t('profile.resume.relevantActivities')}</h3>
    {_.map(relevantActivities, (relevantActivity, index) => (
      <div key={index} className='content' dangerouslySetInnerHTML={{ __html: `${index + 1  }. ${  sanitizeTimeLineItem(relevantActivity)}` }} />
    ))}
  </div>
);

export default withTranslation('translations')(RelevantActivities);
