import _ from 'underscore';
import React from 'react';
import {
  HiresweetLibProvider,
  ProfileContainer,
  ProfileFirstname,
  createProfileTextCustomField,
  createProfileFloatCustomField,
  createProfileIntegerCustomField,
  createProfileDayCustomField,
  createProfileExplicitEnumCustomField,
} from '@hiresweet/hiresweet-lib';
import useClientId from '@/hooks/router/useClientId';
import { useLocation } from 'react-router-dom';

const ProfileComment = createProfileTextCustomField({
  clientCustomFieldId: 'comment-pr',
});
const ProfileGrade = createProfileFloatCustomField({
  clientCustomFieldId: 'grade',
});
const ProfileNbInterviews = createProfileIntegerCustomField({
  clientCustomFieldId: 'nbinterviews',
});
const ProfileDateCall = createProfileDayCustomField({
  clientCustomFieldId: 'datecall',
});
const ProfileCertifs = createProfileExplicitEnumCustomField({
  clientCustomFieldId: 'certifs',
  titleFromId: { 'flic-flac': 'Flic Flac', 'caces-12': 'Caces 12' },
});

const PlaygroundProfileCustomField = ({
  theme,
}: {
  theme: 'hiresweet' | 'slash';
}) => {
  const clientId = useClientId();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const profileId = searchParams.get('testProfileId') || 'jean-bombeur-45T0ZI';

  return (
    <div style={{ padding: 30 }}>
      <h1>Profile Custom Field</h1>
      <div>
        <HiresweetLibProvider theme={theme} clientId={clientId}>
          <ProfileContainer profileId={profileId}>
            Firstname: <ProfileFirstname />
            <br />
            Comment: <ProfileComment />
            <br />
            Grade: <ProfileGrade />
            <br />
            Interviews: <ProfileNbInterviews />
            <br />
            DueDay: <ProfileDateCall />
            <br />
            cerifs: <ProfileCertifs />
            <br />
          </ProfileContainer>
        </HiresweetLibProvider>
      </div>
    </div>
  );
};

export default PlaygroundProfileCustomField;
