import React from 'react';
import _ from 'underscore';
import { withTranslation } from 'react-i18next';
import skillOptions from '../../common/options/skillOptions.json';
import ResizableLabelList from '../ResizableLabelList';

// import './SkillsLabels.css';

// TODO: use useResizableItemList
const SkillsLabels = ({ skills, condensedMode = false, t }) => {
  const validSkills = _.compact(_.map(skills, (skill) => {
    const label = getOptionText({ value: skill?.skillId });
    if (!label) {
      return null;
    }
    return { ...skill, label };
  }));

  return (
    <ResizableLabelList
      labelsWithRelevance={validSkills}
      condensedMode={condensedMode}
      t={t}
    />
  );
};

export const getSortedSkillsWishes = ({ hiresweetData }) => {
  const { target, okWith } = hiresweetData?.wishes?.skills || {};
  const mergedWishes = [...target || [], ...okWith || []];
  // NOTE: preserves initial order for same relevance
  return _.sortBy(mergedWishes, ({ relevance }) => -relevance);
};

const getOptionText = ({ value }) => {
  return _.findWhere(skillOptions, { value })?.text;
};

export default withTranslation('translations')(SkillsLabels);
