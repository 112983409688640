import _, { compose } from 'underscore';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect, withRouter } from 'react-router-dom';
import { Button, Form, Image, Loader } from 'semantic-ui-react';

import withClientCriteriaOptions from '@/hocs/clients/withClientCriteriaOptions';
import routerParamsToProps from '../../../hocs/routerParamsToProps';
import withUserFromJWToken from '../../../hocs/users/withUserFromJWToken';
import withUserSettings from '../../../hocs/users/withUserSettings';
import withAddOffer from '../../../hocs/offers/withAddOffer';
import withClient from '../../../hocs/clients/withClient';
import withClientBilling from '../../../hocs/clients/withClientBilling';
import { NewOfferCriteria } from '../OfferCriteria';
import TrialModal from '../../Onboarding/TrialModal';
import {
  getGenericTitle,
  getOptions,
  validateOfferInput,
} from '../OfferCriteria/helpers';

import './clientNewOffer.css';

class ClientNewOffer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      redirectToProfileView: {},
      submitted: undefined,
      submitting: false,
      criteria: undefined,
      criteriaFormLoaded: undefined,
      error: undefined,
      errorFields: undefined,
      requirementMeet: false,
    };
  }

  handleSubmit = async () => {
    this.setState({ submitting: true });

    const { clientId, addOffer, t, criteriaOptions } = this.props; // { client, pricingPlanConfiguration }
    const { criteria, title } = this.state;
    const simplifiedMode = true; // !_.isEmpty(pricingPlanConfiguration);
    const crmMode = true; // client?.customizations?.mainMode === 'crm';

    const { error, missingFieldsNames } = validateOfferInput({
      title,
      criteria,
      simplifiedMode,
      crmMode,
      t,
    });

    // ex: "afin de valider, remplissez le titre, le type d'offre et l'exp souhaitée"
    if (!_.isEmpty(missingFieldsNames)) {
      this.setState({
        errorFields: missingFieldsNames,
      });
    }

    if (error) {
      this.setState({ error, submitting: false });
      setTimeout(() => this.setState({ error: null }), 5000);
      return;
    }

    let newOffer;
    const options = getOptions(criteriaOptions);
    try {
      const { data } = await addOffer({
        clientId,
        input: {
          title: simplifiedMode
            ? getGenericTitle({ options, criteria })
            : title || '_',
          criteria: criteria || {},
        },
      });
      newOffer = data?.addOffer;
    } catch (e) {
      console.error(e);
    }

    if (newOffer && (newOffer.isInstantFlow || crmMode)) {
      this.setState({ redirectToProfileView: { offerId: newOffer.id } });
    }
    this.setState({ submitted: true, submitting: false });

    window.scrollTo(0, 0);
  };

  handleChangeCriteria = ({ newCriteria }) => {
    const { criteria } = this.state;
    const updated = {
      ...criteria,
      ...newCriteria,
      ...{
        skills: {
          required:
            ((newCriteria || {}).skills || {}).required ||
            ((criteria || {}).skills || {}).required,
          important:
            ((newCriteria || {}).skills || {}).important ||
            ((criteria || {}).skills || {}).important,
          bonus:
            ((newCriteria || {}).skills || {}).bonus ||
            ((criteria || {}).skills || {}).bonus,
        },
      },
    };
    this.setState((prevstate) => ({
      criteria: updated,
      errorFields: prevstate.errorFields
        ? _.without(prevstate.errorFields, ...Object.keys(newCriteria))
        : null,
    }));
  };

  setRequirementsMeet = (value) => {
    this.setState({ requirementMeet: value });
  };

  handleCriteriaFormLoaded = () => {
    this.setState({ criteriaFormLoaded: true });
  };

  goToOffersPage = () => {
    const { history, clientId } = this.props;
    history.push(`/client/${clientId}/jobs`);
  };

  renderOfferCreation() {
    const { clientId, user, location, t } = this.props; // { pricingPlanConfiguration }
    const { criteria } = this.state;
    const isTrial = location.search.includes('trial');
    const simplifiedMode = true; // !_.isEmpty(pricingPlanConfiguration);
    const {
      error,
      criteriaFormLoaded,
      title,
      errorFields,
      submitting,
      requirementMeet,
    } = this.state;

    return (
      <div className='offer-creation'>
        <div className='offer-edition'>
          {criteriaFormLoaded && !simplifiedMode && (
            <Form.Field
              className={`title-field title${
                _.indexOf(errorFields, 'title') >= 0 ? ' is-error' : ''
              }`}
              required
            >
              <input
                autoFocus
                maxLength={80}
                placeholder={t('newOffer.titlePlaceholder')}
                name='title'
                value={title}
                onChange={this.handleChangeTitle}
                className='inplace-input'
                tabIndex={-10}
              />
            </Form.Field>
          )}
          {criteriaFormLoaded && simplifiedMode && (
            <div className='header-container'>
              <h1>{t('newOffer.header')}</h1>
            </div>
          )}
          <NewOfferCriteria
            mode='new-offer'
            clientId={clientId}
            offer={{}}
            handleChangeCriteria={this.handleChangeCriteria}
            handleLoaded={this.handleCriteriaFormLoaded}
            handleSubmit={this.handleSubmit}
            submitting={submitting}
            errorFields={errorFields}
            simplifiedMode={simplifiedMode}
            setRequirementsMeet={this.setRequirementsMeet}
            criteria={criteria}
          />
        </div>
        {error && (
          <div className='error-container'>
            <span>{error}</span>
          </div>
        )}
        {criteriaFormLoaded && (
          <div className='actions-container'>
            <Button
              className='launch-job-button  primary-cta'
              onClick={this.handleSubmit}
              disabled={submitting || !requirementMeet}
            >
              <Image src='/images/icons/figma/new-cross-white.svg' />
              {t('newOffer.launchOffer')}
            </Button>
          </div>
        )}
        {user && user.firstLogin && (
          <TrialModal open={isTrial} clientId={clientId} />
        )}
      </div>
    );
  }

  renderOfferCreated() {
    const { clientId, t } = this.props;
    const { redirectToProfileView } = this.state;

    if (!_.isEmpty(redirectToProfileView)) {
      return (
        <Redirect
          to={`/client/${clientId}/jobs/${redirectToProfileView.offerId}/profiles`}
          push
        />
      );
    }

    return (
      <center className='offer-created'>
        <div className='offer-created-message'>
          <center>
            <span>{t('newOffer.confirmed.header')}</span>
          </center>
          <center>
            <span>{t('newOffer.confirmed.text')}</span>
          </center>
        </div>
        <div className='offer-created-back-button-container'>
          <Button onClick={this.goToOffersPage}>
            {t('newOffer.backToHomepage')}
          </Button>
        </div>
      </center>
    );
  }

  render() {
    const { loading, error } = this.props;
    const { submitted } = this.state;

    if (loading) {
      return (
        <div className='new-offer-page'>
          <div className='criteria-view criteria-view-placeholder'>
            <Loader active inline='centered' size='large' />
          </div>
        </div>
      );
    }
    if (error) return <p />;

    return (
      <div className='new-offer-page'>
        {submitted ? this.renderOfferCreated() : this.renderOfferCreation()}
      </div>
    );
  }
}

export default compose(
  routerParamsToProps,
  withUserSettings,
  withUserFromJWToken,
  withClient,
  withRouter,
  withClientBilling,
  withAddOffer,
  withClientCriteriaOptions,
  withTranslation('translations'),
)(ClientNewOffer);
