import React, { Component } from 'react';
import _ from 'underscore';
import GenericRangeSlider from '@/components/Common/GenericRangeSlider/GenericRangeSlider';

class CustomRange extends Component {
  constructor(props) {
    super(props);
    const { currentValue } = this.props;
    this.state = {
      value: currentValue,
    };
  }

  throttledOnChange = _.throttle((value) => {
    const { onChange, label } = this.props;
    onChange({ label, value: { value } });
  }, 200);

  handleChange = (values) => {
    this.setState({
      value: {
        min: values[0],
        max: values[1],
      },
    });
    this.throttledOnChange({
      min: values[0],
      max: values[1],
    });
  };

  render() {
    const { max, min, step, formatLabel } = this.props;
    const { value } = this.state;

    const formatLabelFunction = formatLabel || ((v) => `${v}`);

    return (
      <div className='custom-range'>
        <GenericRangeSlider
          min={min || 0}
          max={max || 10}
          step={step}
          values={[value?.min || min || 0, value?.max || max || 10]}
          onChange={this.handleChange}
          formatLabel={formatLabelFunction}
        />
      </div>
    );
  }
}

export default CustomRange;
