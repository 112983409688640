import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getPercentage, percentageToString } from '@/common/utils/number';

import styles from './RateCellContent.module.less';

interface RateCellContentProps {
  value: number;
  referenceValue: number;
  rate: number;
}

const RateCellContent: FC<RateCellContentProps> = ({
  value,
  referenceValue,
  rate,
}) => {
  const { t } = useTranslation();
  const detail = referenceValue ? `(${value}/${referenceValue})` : '';
  return (
    <>
      {referenceValue
        ? percentageToString(t)(getPercentage(Math.min(rate, 1), 1, 1))
        : '-'}
      {detail && <span className={styles.details}>{detail}</span>}
    </>
  );
};

export default RateCellContent;
