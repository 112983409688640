import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SearchPoolJob from '@/graphql/fragments/SearchPoolJob';
import JobPosting from '../../graphql/fragments/JobPosting';

export const updateJobPostingMutation = gql`
  mutation updateJobPosting($input: UpdateJobPostingInput!) {
    updateJobPosting(input: $input) {
      jobPosting {
        ...JobPosting
      }
    }
  }
  ${JobPosting}
`;

export const updateRevealJobPostingMutation = gql`
  mutation updateRevealJobPosting($searchPoolId: ID!, $input: UpdateJobPostingInput!) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      updateRevealJobPosting(input: $input) {
        jobPosting {
          ...JobPosting
        }
        job {
          ...SearchPoolJob
        }
      }
    }
  }
  ${JobPosting}
  ${SearchPoolJob}
`;

export default graphql(updateJobPostingMutation, {
  props: ({ mutate }) => ({
    updateJobPosting: ({ input }) => mutate({ variables: { input } }),
  }),
});
