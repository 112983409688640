import React from 'react';

import CollapsibleEnrichedText from '../../../../components/CollapsibleEnrichedText';
import withActionLogger from '../../../../hocs/withActionLogger';
import { getEnrichedText } from '../../../../common';

export class SummaryBase extends React.Component {
  logExpand = (collapsed) => {
    if (!collapsed && this.props.onLogAction) {
      this.props.onLogAction({
        type: 'expand-profile-summary',
        profileId: this.props.profileId,
      });
    }
  };

  render() {
    const { content } = this.props;
    return (
      <div className='profile-summary'>
        <CollapsibleEnrichedText
          enrichedText={getEnrichedText(content.text)}
          charCost={1}
          lineCost={90}
          targetMaxCost={400}
          toleranceFactor={0.3}
          onToggleCollapse={this.logExpand}
        />
      </div>
    );
  }
}

export default withActionLogger(SummaryBase);
