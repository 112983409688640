import React from 'react';
import _ from 'underscore';
import { Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ATS_NAMES_BY_TYPE } from '@/common/reveal';
import { useClientRevealConnector } from '@/graphql/hooks/clients/useClientRevealProjects';
import {
  SynchronizationCheckedIcon,
  SynchronizationCrossIcon,
} from '@/assets/icons';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import { Profile } from '@/types/profile';
import { TimelineItem } from '@/types/contactData';
import { getSynchronizedATSNames } from './utils';

interface IntegrationsSynchronizationStatusProps {
  timelineItem: TimelineItem;
  clientId: string;
  profile: SearchPoolProfile | Profile;
}

const IntegrationsSynchronizationStatus: React.FC<IntegrationsSynchronizationStatusProps> = ({
  timelineItem,
  clientId,
  profile,
}) => {
  const { t } = useTranslation();
  const defaultConnector = useClientRevealConnector(clientId);
  const { atsSynchronizations, ats } = timelineItem || {};
  const atsSynchronized = !_.isEmpty(atsSynchronizations);

  if (!defaultConnector) {
    return null;
  }

  if (ats && !atsSynchronized) {
    // This means that the item comes directly from the ATS ie. was not created in HireSweet
    return null;
  }

  const profileInATS = !!profile?.resumeData?.sourceData?.[
    defaultConnector?.type
  ]?.sourceCandidateId;
  if (!profileInATS) {
    return null;
  }

  const someSynchronizationIsActive = _.some(
    _.values(_.omit(defaultConnector?.synchronizationSettings, '__typename')),
  );

  if (atsSynchronized) {
    const synchronizedAtsNames = getSynchronizedATSNames({
      atsSynchronizations,
    });
    return (
      <Popup
        trigger={
          <span>
            {' '}
            <SynchronizationCheckedIcon className='ats-synchronization' />
          </span>
        }
        content={`${t(
          'profile.contact.timeline.synchronizedWith',
        )} ${synchronizedAtsNames}`}
        basic
        size='mini'
      />
    );
  }

  if (!someSynchronizationIsActive) {
    return null;
  }

  const atsName = ATS_NAMES_BY_TYPE[defaultConnector.type];
  return (
    <Popup
      trigger={
        <span>
          {' '}
          <SynchronizationCrossIcon className='ats-synchronization' />
        </span>
      }
      content={`${t(
        'profile.contact.timeline.notSynchronizedWith',
      )} ${atsName}`}
      basic
      size='mini'
    />
  );
};

export default IntegrationsSynchronizationStatus;
