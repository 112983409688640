import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import Sequences from '@/containers/Parameters/Sequences';
import Templates from '@/containers/Parameters/Templates';
import useClientId from '@/hooks/router/useClientId';
import Campaigns from '@/containers/Parameters/Campaigns';
import SubHeader from './SubHeader/SubHeader';
import { AutomationTab } from './types';

import './AutomationsView.css';

interface AutomationsViewProps {
  tab: AutomationTab;
}

const AutomationsView: React.FC<AutomationsViewProps> = ({ tab }) => {
  const clientId = useClientId();
  const { isExact } = useRouteMatch();

  return (
    <>
      {isExact && <SubHeader tab={tab} />}
      {tab === 'sequences' && <Sequences />}
      {tab === 'templates' && (
        <div className='menu-templates templates-list'>
          <Templates clientId={clientId} />
        </div>
      )}

      {tab === 'campaigns' && <Campaigns />}
    </>
  );
};

export default AutomationsView;
