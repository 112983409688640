import React, { useState } from 'react';
import { compose } from 'underscore';
import { Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { getJobPostingInput, isInvalidJobPosting } from './helpers';

import withUpdateJobPosting from '../../../hocs/offers/withUpdateJobPosting';
import withDeleteJobPosting from '../../../hocs/offers/withDeleteJobPosting';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';

import JobPostingDisplay from './JobPostingDisplay';
import JobPostingFormFields from './JobPostingFormFields';

import './JobPosting.css';
import contextToProps from '../../../hocs/contextToProps';

const JobPosting = ({
  careerPageId,
  jobPosting,
  jobFoldering,
  updateJobPosting,
  deleteJobPosting,
  onShowNotification,
  t,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [jobPostingState, setJobPostingState] = useState(jobPosting);

  const handleCancel = () => {
    setEditMode(false);
    setJobPostingState(jobPosting);
  };

  const handleSave = async () => {
    const input = getJobPostingInput({ jobPostingState });

    setLoading(true);
    try {
      await updateJobPosting({ input });
      onShowNotification({
        message: t('offers.jobPostings.updateSuccess'),
        level: 'success',
      });
    } catch (e) {
      console.warn(e);
      onShowNotification({
        message: t('offers.jobPostings.updateError'),
        level: 'error',
      });
    }
    setLoading(false);
    setEditMode(false);
  };

  const handleDeleteJobPosting = async () => {
    await deleteJobPosting({ input: { id: jobPosting.id } });
  };

  if (editMode) {
    return (
      <div className='job-posting'>
        <JobPostingFormFields
          formState={jobPostingState}
          setFormState={setJobPostingState}
        />

        <div className='buttons-container'>
          <Button secondary onClick={handleCancel}>
            {t('common.cancel')}
          </Button>
          <Button
            primary
            loading={loading}
            onClick={handleSave}
            disabled={isInvalidJobPosting({ jobPosting })}
          >
            {t('common.save')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className='job-posting'>
      <JobPostingDisplay
        careerPageId={careerPageId}
        jobPosting={jobPosting}
        jobFoldering={jobFoldering}
        onClickEdit={() => setEditMode(true)}
      />

      {(jobPosting?.description?.sections || []).length >= 3 && (
        <div className='buttons-container'>
          <Button primary onClick={() => setEditMode(true)}>
            {t('common.edit')}
          </Button>
        </div>
      )}

      <ConfirmationModal
        open={confirmationModalOpen}
        header={t('offers.jobPostings.deleteConfirmation')}
        content=''
        submit={t('common.delete')}
        onSubmit={handleDeleteJobPosting}
        onCancel={() => setConfirmationModalOpen(false)}
      />
    </div>
  );
};

export default compose(
  withUpdateJobPosting,
  withDeleteJobPosting,
  contextToProps,
  withTranslation('translations'),
)(JobPosting);
