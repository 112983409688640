import React, { FC, useCallback, useMemo, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Dimmer, Dropdown, Loader, Modal } from 'semantic-ui-react';

import usePipelineActionsStatistics from '@/graphql/hooks/clients/usePipelineActionsStatistics';
import useClientId from '@/hooks/router/useClientId';
import {
  ArchivedState,
  PipelineState,
} from '@/components/PipelineSegmentation/pipelineSegmentation';
import useArchiveReasons from '@/graphql/useArchiveReasons';
import InfoTooltip from '@/components/Common/InfoTooltip';
import usePipelineActionsStatisticsDetails from '@/graphql/hooks/clients/usePipelineActionsStatisticsDetails';
import { getFullname } from '@/common/helpers/person';
import GenericModal from '@/components/Common/GenericModal';
import HorizontalStackedBarChart from '../../../components/HorizontalStackedBarChart';
import {
  buildStackFromDailyEvents,
  convertPipelineDailyEvents,
  getCategoryLabels,
} from './helpers';
import { DisplayMode, StatsFilters } from './types';
import { TimeSpan } from '../../../components/RevealAnalyticsHeader/RevealAnalyticsHeader';
import { ArchiveDetail } from '../../../components/StatsProfileAndArchiveActionTable/types';
import StatsProfileAndArchiveActionTable from '../../../components/StatsProfileAndArchiveActionTable';
import DetailsModalHeader from '../../../components/DetailsModalHeader';

import styles from './ReasonCategories.module.less';

interface ReasonCategoriesProps {
  missionIds: string[];
  timeSpan: TimeSpan;
}

const ReasonCategories: FC<ReasonCategoriesProps> = ({
  missionIds,
  timeSpan,
}) => {
  const clientId = useClientId();
  const { t } = useTranslation();
  const [displayMode, setDisplayMode] = useState<DisplayMode>('extended');
  const [filters, setFilters] = useState<StatsFilters | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState(false);

  const { archiveReasons } = useArchiveReasons({
    clientId,
    includeArchived: true,
  });

  const displayModeSwitchOptions = useMemo(
    () => [
      {
        key: 'collapsed',
        text: t(
          'reveal.reports.archiveReasons.categoryBreakdown.displayMode.collapsed',
        ),
        value: 'collapsed',
      },
      {
        key: 'extended',
        text: t(
          'reveal.reports.archiveReasons.categoryBreakdown.displayMode.extended',
        ),
        value: 'extended',
      },
    ],
    [t],
  );

  const onChangeDisplayMode = (value: DisplayMode) => {
    setDisplayMode(value);
  };

  const reasonLabels = useMemo(() => {
    const result: Record<string, string> = getCategoryLabels(t);
    _.each(archiveReasons || [], ({ id, title }) => {
      result[id] = title;
    });
    return result;
  }, [archiveReasons, t]);

  const { pipelineActionsByUser, loading } = usePipelineActionsStatistics({
    clientId,
    missionsFilter: !_.isEmpty(missionIds) ? { in: missionIds } : undefined,
    stagesFilter: { in: [ArchivedState.ARCHIVED] },
    startDate: timeSpan.startDate.format('YYYY-MM-DD'),
    endDate: timeSpan.endDate.format('YYYY-MM-DD'),
  });

  const [
    fetchDetails,
    {
      pipelineActionsByUser: pipelineActionsByUserDetails,
      loading: detailsLoading,
    },
  ] = usePipelineActionsStatisticsDetails({
    queryOptions: { fetchPolicy: 'cache-first' },
  });

  const applyFilter = useCallback(
    (newFilter: StatsFilters) => {
      if (!fetchDetails) {
        return;
      }
      setFilters(newFilter);
      fetchDetails({
        clientId,
        missionsFilter: !_.isEmpty(missionIds) ? { in: missionIds } : undefined,
        stagesFilter: { in: [ArchivedState.ARCHIVED] },
        startDate: timeSpan.startDate.format('YYYY-MM-DD'),
        endDate: timeSpan.endDate.format('YYYY-MM-DD'),
      });
      setModalOpen(true);
    },
    [fetchDetails, clientId, timeSpan, missionIds],
  );

  const allStats = useMemo(
    () =>
      convertPipelineDailyEvents(
        displayMode,
        reasonLabels,
        pipelineActionsByUser || [],
        applyFilter,
      ),
    [displayMode, reasonLabels, pipelineActionsByUser, applyFilter],
  );

  const stack = useMemo(
    () =>
      buildStackFromDailyEvents(
        displayMode,
        reasonLabels,
        pipelineActionsByUser || [],
      ),
    [displayMode, reasonLabels, pipelineActionsByUser],
  );

  const profileDetails = useMemo(() => {
    if (filters === undefined) {
      return [];
    }
    const { customArchiveReasonId, category } = filters;

    const result = [] as ArchiveDetail[];

    _.each(pipelineActionsByUserDetails || [], ({ day, userStats }) => {
      _.each(userStats, ({ stats, author }) => {
        _.each(stats, ({ type, archivedDetails, profileItems, missionId }) => {
          if (type !== 'nbMoveToArchived' || !archivedDetails) {
            return;
          }
          const {
            customArchiveReasonId: detailId,
            category: detailCategory,
            fromStage,
          } = archivedDetails;
          if (customArchiveReasonId && detailId === customArchiveReasonId) {
            _.each(profileItems, ({ profileId }) => {
              result.push({
                profileId,
                day,
                fromStage:
                  fromStage === 'null' ? PipelineState.PENDING : fromStage,
                missionId,
                authorFullname: getFullname(author),
              });
            });
          }
          if (
            !customArchiveReasonId &&
            detailCategory === category &&
            !detailId
          ) {
            _.each(profileItems, ({ profileId }) => {
              result.push({
                profileId,
                day,
                fromStage:
                  fromStage === 'null' ? PipelineState.PENDING : fromStage,
                missionId,
                authorFullname: getFullname(author),
              });
            });
          }
        });
      });
    });

    return result;
  }, [pipelineActionsByUserDetails, filters]);

  const modalSubtitle = useMemo(() => {
    if (filters?.customArchiveReasonId) {
      return reasonLabels[filters.customArchiveReasonId];
    }
    return reasonLabels[filters?.category || 'no-reason'];
  }, [filters, reasonLabels]);

  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <h3 className={styles.title}>
          {t('reveal.reports.archiveReasons.categoryBreakdown.title')}
          <InfoTooltip rich hoverable position='right center'>
            <h1>
              {t(
                'reveal.reports.tooltips.archiveReasons.categoryBreakdown.title',
              )}
            </h1>
            <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
            <p>
              {t(
                `reveal.reports.tooltips.archiveReasons.categoryBreakdown.${displayMode}.paragraph`,
              )}
            </p>
            <h2>{t('reveal.reports.tooltips.common.surprised')}</h2>
            <p>
              {t(
                `reveal.reports.tooltips.archiveReasons.categoryBreakdown.multiMoveParagraph`,
              )}
            </p>
            <h2>{t('reveal.reports.tooltips.common.details')}</h2>
            <p>{t(`reveal.reports.tooltips.common.barDetails`)}</p>
          </InfoTooltip>
        </h3>
        <div>
          <Dropdown
            selection
            selectOnBlur={false}
            options={displayModeSwitchOptions}
            value={displayMode}
            onChange={(_e, { value }) =>
              onChangeDisplayMode(value as DisplayMode)
            }
            className='hiresweet-rounded'
          />
        </div>
      </div>
      <div className={styles.content}>
        {!loading && (
          <HorizontalStackedBarChart allStats={allStats} stack={stack} />
        )}
        {loading && (
          <Loader
            className={styles.loader}
            active
            inline='centered'
            size='large'
          />
        )}
      </div>
      {detailsLoading ? (
        <Dimmer active>
          <Loader size='large' />
        </Dimmer>
      ) : (
        modalOpen && (
          <GenericModal open={modalOpen} onClose={() => setModalOpen(false)}>
            <Modal.Content>
              <DetailsModalHeader>
                <h1>
                  {t(
                    'reveal.reports.archiveReasons.categoryBreakdown.detailsModal.title',
                  )}
                </h1>
                {modalSubtitle && <h2>{modalSubtitle}</h2>}
              </DetailsModalHeader>
              <StatsProfileAndArchiveActionTable
                archiveDetails={profileDetails}
              />
            </Modal.Content>
          </GenericModal>
        )
      )}
    </div>
  );
};

export default ReasonCategories;
