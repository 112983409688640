import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useEmailApi from '@/hooks/common/useEmailApi';
import Check from '@/components/Common/Icons/Check';

import { useSetFinishedOnboardingOnUnmount } from './hooks';

const Step3: React.FC = () => {
  const { t } = useTranslation();
  useSetFinishedOnboardingOnUnmount();

  const { onTriggerOfflineRegister, currentEmailType } = useEmailApi();

  return (
    <div className='step3'>
      <h1 className='step-title'>{t('reveal.onboardingModal.step3.title')}</h1>
      <p className='step-paragraph'>
        {t('reveal.onboardingModal.step3.emailText')}
      </p>
      <button
        type='button'
        className='step3-mail-connector'
        onClick={() => {
          onTriggerOfflineRegister('gmail');
        }}
        disabled={currentEmailType === 'gmail'}
      >
        <div className='step3-mail-logo-container'>
          <img
            className='step3-mail-logo'
            src='/images/logos/gmail.svg'
            alt='gmail'
          />
        </div>
        <span
          className={classNames('step3-mail-cta', {
            connected: currentEmailType === 'gmail',
          })}
        >
          {t(
            `changeEmailAccountModal.${
              currentEmailType === 'gmail' ? 'gmailConnected' : 'gmailSwitch'
            }`,
          )}
        </span>
        {currentEmailType === 'gmail' && (
          <span className='step3-mail-check'>
            <Check />
          </span>
        )}
      </button>
      <button
        type='button'
        className='step3-mail-connector'
        onClick={() => {
          onTriggerOfflineRegister('outlook', 'select_account');
        }}
        disabled={currentEmailType === 'outlook'}
      >
        <div className='step3-mail-logo-container'>
          <img
            className='step3-mail-logo'
            src='/images/logos/outlook.svg'
            alt='outlook'
          />
        </div>
        <span
          className={classNames('step3-mail-cta', {
            connected: currentEmailType === 'outlook',
          })}
        >
          {t(
            `changeEmailAccountModal.${
              currentEmailType === 'outlook'
                ? 'outlookConnected'
                : 'outlookSwitch'
            }`,
          )}
        </span>
        {currentEmailType === 'outlook' && (
          <span className='step3-mail-check'>
            <Check />
          </span>
        )}
      </button>
    </div>
  );
};

export default Step3;
