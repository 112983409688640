import React, { FC, useMemo, useContext, ReactNode } from 'react';
import _ from 'underscore';

import useDailyCountReportStreamAggregate from '@/graphql/hooks/clients/useDailyCountReportStreamAggregate';
import { NumberRecord } from '@/types/statistics/stats';
import { StackItem } from '@/types/statistics/stackItem';
import { useUserAllowedDepartments } from '@/graphql/hooks/users/useUserAllowedDepartments';
import useMissionsWithFoldering from '@/graphql/hooks/searchPoolJobs/useMissionsWithFoldering';

import StatisticsParametersContext from '../../../StatisticsParametersContext';
import GraphWrapper from '../../../components/GraphWrapper/GraphWrapper';
import DailyEventsDataContainer from '../../../components/DailyEventsDataContainer/DailyEventsDataContainer';

import styles from './DailyCountStream.module.less';

type DailyCountStreamProps = {
  title: ReactNode;
  reportStreamId: string;
  stack: StackItem[];
};

const DailyCountStream: FC<DailyCountStreamProps> = ({
  title,
  reportStreamId,
  stack,
}) => {
  const [{ timeSpan, aggregatePeriod, missionIds }] = useContext(
    StatisticsParametersContext,
  );

  const { subsections } = useUserAllowedDepartments();
  const { missionsWithFoldering } = useMissionsWithFoldering();

  const subsectionIdMap = useMemo(() => {
    const result = {} as Record<string, boolean>;
    _.each(subsections, ({ id }) => {
      result[id] = true;
    });
    return result;
  }, [subsections]);

  const availableMissionIds = useMemo(() => {
    const result = [] as string[];
    _.each(missionsWithFoldering, ({ id, foldering }) => {
      if (
        foldering?.subsection?.id &&
        subsectionIdMap[foldering.subsection.id]
      ) {
        result.push(id);
      }
    });
    return result;
  }, [missionsWithFoldering, subsectionIdMap]);

  const sentMissionIds = useMemo(
    () => (_.isEmpty(missionIds) ? availableMissionIds : missionIds),
    [missionIds, availableMissionIds],
  );

  const {
    dailyCountReportStreamAggregate,
  } = useDailyCountReportStreamAggregate({
    reportStreamId,
    startDate: timeSpan.startDate.format('YYYY-MM-DD'),
    endDate: timeSpan.endDate.format('YYYY-MM-DD'),
    aggregatePeriod,
    scope: [{ key: 'missionId', value: { in: sentMissionIds } }],
  });

  const dailyEvents = useMemo(
    () =>
      _.map(
        _.sortBy(dailyCountReportStreamAggregate, 'day'),
        ({ day, counts }) => {
          const values = {} as NumberRecord;
          _.each(counts, ({ value, key }) => {
            values[key] = value;
          });
          return {
            day,
            values,
          };
        },
      ),

    [dailyCountReportStreamAggregate],
  );

  return (
    <div className={styles.card}>
      <GraphWrapper
        lockChartType
        lockFrequency
        timeSpan={timeSpan}
        title={<h3 className={styles.title}>{title}</h3>}
        renderChildren={(_f, mode) => (
          <DailyEventsDataContainer
            frequency={aggregatePeriod}
            dateRange={timeSpan}
            stack={stack}
            mode={mode}
            type='bar'
            dailyEvents={dailyEvents}
          />
        )}
      />
    </div>
  );
};

export default DailyCountStream;
