import { withTranslation } from 'react-i18next';
import { compose } from 'underscore';
import { TFunction } from 'i18next';
import React from 'react';
import { Radio } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { DelayInput } from '../components/DateRangePicker';
import { DateOperator } from './types';

import { OPERATORS_OPTION } from './operatorsOptions';
import './CustomCriteriaFilters.css';
import 'react-datepicker/dist/react-datepicker.css';

const CustomCriteriaDate = (props: {
  selectedOperator: DateOperator | undefined;
  onSelectedOperatorChange: (operator: DateOperator) => void;
  value: string;
  onValueChange: (value: string) => void;
  onClose: () => void;
  t: TFunction;
}) => {
  const {
    selectedOperator,
    onSelectedOperatorChange,
    value,
    onValueChange,
    onClose,
    t,
  } = props;

  const onDatePick = (date: Date) => {
    onValueChange(date.toISOString());
  };

  const onUpdate = (delayObject: { delay: number }) => {
    const newDate = new Date();
    const newValue = new Date(newDate.getTime() - delayObject.delay);

    onValueChange(newValue.toISOString());
  };

  const getDelay = () => {
    const today = new Date();
    const daySelected = new Date(value);
    return today.getTime() - daySelected.getTime();
  };

  return (
    <div className='property-details-wrapper'>
      <div className='label-divider'>
        {t('reveal.searchView.search.relative')}
      </div>
      {OPERATORS_OPTION.day.slice(0, 2).map((opt) => (
        <div className='radio-wrapper' key={opt.operator}>
          <div
            className='radio-row'
            onClick={() => {
              onSelectedOperatorChange(opt.operator);
            }}
          >
            <Radio
              className='radio'
              checked={selectedOperator === opt.operator}
            />
            <div className='label'>{t(opt.translation)}</div>
          </div>

          {opt.withInput && selectedOperator === opt.operator && (
            <div className='property-details-selector'>
              <DelayInput
                delayInMs={getDelay()}
                onUpdate={onUpdate}
                onReset={undefined}
                placeholder='N'
              />
            </div>
          )}
        </div>
      ))}
      <div className='label-divider'>
        {t('reveal.searchView.search.absolute')}
      </div>
      {OPERATORS_OPTION.day.slice(2).map((opt) => (
        <div className='radio-wrapper' key={opt.operator}>
          <div className='radio-row'>
            <Radio
              className='radio'
              checked={selectedOperator === opt.operator}
              onClick={() => {
                onSelectedOperatorChange(opt.operator);
              }}
            />
            <div className='label'>{t(opt.translation)}</div>
          </div>
          {opt.withInput && selectedOperator === opt.operator && (
            <div className='property-details-selector'>
              <DatePicker
                selected={
                  value && new Date(value) ? new Date(value) : undefined
                }
                onChange={onDatePick}
                dateFormat='MMMM d, yyyy'
                className='datepicker'
                placeholderText={t('reveal.searchView.search.pickADate')}
              />
            </div>
          )}
        </div>
      ))}
      <div className='save-button' onClick={onClose}>
        {t('reveal.searchView.search.save')}
      </div>
    </div>
  );
};

export default compose(withTranslation('translations'))(CustomCriteriaDate);
