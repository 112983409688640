import React, { FC } from 'react';

import AllCandidatesMockup from './AllCandidatesMockup';

import styles from './Illustration.module.less';

const Illustration: FC = () => {
  return (
    <div className={styles.visual}>
      <AllCandidatesMockup />
    </div>
  );
};

export default Illustration;
