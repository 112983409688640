import React from 'react';
import _, { compose } from 'underscore';
import qs from 'qs';

import { Redirect } from 'react-router-dom';
import GlobalSigninForm from '../containers/Auth/GlobalSigninForm';
import routerParamsToProps from '../hocs/routerParamsToProps';
import { decodeToken } from '../common';

class GlobalSigninView extends React.PureComponent {
  render() {
    const { location } = this.props || {};
    const token = localStorage.getItem('token');
    const decodedJWT = token ? decodeToken(token) : token;
    const { clientId, clients } = decodedJWT || {};

    // Already logged in
    if (clientId) {
      const queryString =
        (_.isString(location.search) && location.search.replace('?', '')) || '';
      const queryObject = qs.parse(queryString);

      const redirectionPath = decodeURIComponent(queryObject?.redirect || '');
      if (redirectionPath) {
        return <Redirect to={`/client/${clientId}${redirectionPath}`} />;
      }
      return <Redirect to={`/client/${clientId}/dashboard`} />;
    }

    return (
      <GlobalSigninForm
        location={location}
        {...(clients && { clients })}
        history={this.props.history}
      />
    );
  }
}

export default compose(routerParamsToProps)(GlobalSigninView);
