import { FetchResult, MutationResult, useMutation } from '@apollo/client';
import {
  UPDATE_USER_APPLICATION_MODE,
  UpdateUserApplicationResult,
  UpdateUserApplicationVariables,
} from '../../users';

type AvailableApplicationMode = 'classic' | 'reveal';

type UseUpdateUserApplicationModeResult = [
  (
    newApplicationMode: AvailableApplicationMode,
  ) => Promise<FetchResult<UpdateUserApplicationResult>> | void,
  MutationResult<UpdateUserApplicationResult>,
];
const useUpdateUserApplicationMode = (
  clientId: string,
  userId?: string,
): UseUpdateUserApplicationModeResult => {
  const [update, result] = useMutation<
    UpdateUserApplicationResult,
    UpdateUserApplicationVariables
  >(UPDATE_USER_APPLICATION_MODE, {});
  const updateUserApplicationMode = (
    newApplicationMode: AvailableApplicationMode,
  ) => {
    if (!userId || !clientId) {
      return;
    }
    update({
      variables: { clientId, input: { applicationMode: newApplicationMode } },
      optimisticResponse: {
        updateUser: {
          id: userId,
          applicationMode: newApplicationMode,
          __typename: 'User',
        },
      },
    });
  };
  return [updateUserApplicationMode, result];
};

export default useUpdateUserApplicationMode;
