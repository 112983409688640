import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const deleteClientCalendlyAccount = gql`
  mutation deleteClientCalendlyAccount($id: ID!) {
    deleteClientCalendlyAccount(id: $id) {
      id
      calendlyIntegration {
        active
        accounts {
          id
          slug
          email
          status
        }
      }
    }
  }
`;

export default graphql(deleteClientCalendlyAccount, {
  props: ({ mutate }) => ({
    deleteClientCalendlyAccount: ({ id }) => {
      const variables = { id };
      return mutate({ variables });
    },
  }),
});
