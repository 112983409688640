import _ from 'underscore';
import {
  AND_CONDITION_TYPE,
  LINKEDIN_INVITATION_ACCEPTED,
  OR_CONDITION_TYPE,
  TASK_TYPES,
} from '../../../../common/constants/taskTypes';

export const getTriggerText = ({ actionIndex, action, t }) => {
  if (actionIndex === 0) {
    return t('contactFlow.triggers.createdImmediately');
  }

  if (action?.trigger?.type === 'manual-trigger') {
    if (!action?.trigger?.delay) {
      return t('contactFlow.triggers.createdImmediately');
    }
    return getAutomaticTriggerText({
      type: action?.type,
      trigger: action?.trigger,
      t,
    });
  }

  if (
    action?.trigger?.type === OR_CONDITION_TYPE ||
    action?.trigger?.type === AND_CONDITION_TYPE
  ) {
    return _.map(action.trigger?.conditions, (condition) =>
      getTriggerText({
        actionIndex,
        action: { ...action, trigger: condition },
        t,
      }),
    ).join(
      action?.trigger?.type === OR_CONDITION_TYPE
        ? ` ${t('common.OR')} `
        : ` ${t('common.AND')} `,
    );
  }

  if (action?.trigger?.type === LINKEDIN_INVITATION_ACCEPTED) {
    return t('contactFlow.triggers.linkedinInvitationAccepted');
  }

  return getAutomaticTriggerText({
    type: action?.type,
    trigger: action?.trigger,
    t,
  });
};

const getAutomaticTriggerText = ({ type, trigger, t }) => {
  const { value, unit } = trigger?.delay || {};
  const created =
    type === TASK_TYPES.SEND_EMAIL
      ? t('contactFlow.triggers.triggeredAutomatically')
      : t('contactFlow.triggers.createdAutomatically');

  const unitText = t(
    `contactFlow.triggers.units.${
      unit === 'working-day' ? 'workingDay' : unit
    }`,
    { count: value },
  );
  return `${created} ${value} ${unitText}`;
};

export default getTriggerText;
