import React, { ReactNode, useMemo, useState } from 'react';
import _ from 'underscore';
import GenericSegmentView from '../GenericSegmentView/GenericSegmentView';
import { SegmentDefinition } from '../types';
import styles from './GenericKanban.module.less';
import { GenericSegmentationItem } from './GenericKanbanColumn/index';
import { GenericKanbanContext } from './GenericKanbanContext';

interface GenericKanbanProps {
  segmentDefinitions: SegmentDefinition[];
  renderItem: (
    item: GenericSegmentationItem,
    itemLoading: boolean,
  ) => ReactNode;
  dataLoading: boolean;
}

const GenericKanban: React.FC<GenericKanbanProps> = ({
  segmentDefinitions,
  renderItem,
  dataLoading,
}) => {
  const [isCardGrabbed, setIsCardGrabbed] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const context = useMemo(
    () => ({
      isCardGrabbed,
      isMouseDown,
      setIsCardGrabbed,
      setIsMouseDown,
    }),
    [isCardGrabbed, isMouseDown],
  );

  return (
    <GenericKanbanContext.Provider value={context}>
      <div className={styles.genericKanban}>
        {_.map(segmentDefinitions, (segmentDefinition) => (
          <GenericSegmentView
            key={segmentDefinition.segmentId}
            segmentDefinition={segmentDefinition}
            type='kanban'
            renderItem={renderItem}
            dataLoading={dataLoading}
          />
        ))}
      </div>
    </GenericKanbanContext.Provider>
  );
};

export default GenericKanban;
