/**
 * Defines a subset of the profiles inside a mission
 */
export type Segment = {
  id: SegmentId | string;
  name?: string;
  translationKey?: string;
  type?: string;
  subtype?: string;
  nextAvailabilityFilter?: {
    lessThan?: string;
    greaterThan?: string;
    isUnknown?: boolean;
  };
  isDisabled?: boolean;
};

/**
 * Set of segments
 */
export type Segmentation = {
  id: string;
  translationKey: string;
  type: 'pipeline' | 'free-segmentation' | 'hs-next-availability-date';
  subtype?: string;
  /**
   * Segment IDs
   */
  segments: Segment[];
  showDisplayTypeSelector?: boolean;
  displayType?: 'kanban' | 'table';
  needsShowKanbanFeatureFlag?: boolean;
  disableDragAndDrop?: boolean;
  featureFlag?: string;
  showExtraOptions?: {
    condensedInterested?: boolean;
  };
};

export enum PipelineState {
  PENDING = 'pending',
  IN_PROGRESS = 'in-progress',
  REPLIED = 'replied',
  INTERESTED = 'interested',
  HIRED = 'hired',
}

export enum RecencySegment {
  RECENT_WEEK = 'recent-week',
  RECENT_MONTH = 'recent-month',
  ALL = 'all',
}

export enum ArchivedState {
  ARCHIVED = 'archived',
  NOT_ARCHIVED = 'not-archived',
}

export type SegmentId = PipelineState | RecencySegment | ArchivedState | string;
