import * as Sentry from '@sentry/browser';
import _, { compose } from 'underscore';
import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { Accordion, Input, Modal, Dimmer, Segment } from 'semantic-ui-react';

import useClientId from '@/hooks/router/useClientId';
import { CLIENT_SEQUENCES } from '@/containers/Parameters/Sequences/queries';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import useImportCandidateRefetch from '@/graphql/hooks/candidates/useImportCandidateRefetch';
import CustomFileInput from '@/components/CustomFileInput';
import useParseResume from '@/graphql/parseResume';
import GenericButton from '@/components/Common/GenericButton';
import { SequenceDropdownForJob } from '@/components/Reveal/Sequences/SequenceDropdown/SequenceDropdown';
import ProjectDropdown from '@/components/Common/ProjectDropdown/ProjectDropdown';
import GenericModal from '@/components/Common/GenericModal';
import { AddProfileToSequenceContext } from '@/context/AddProfileToSequenceContext';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import useHumanContactCategorySubtypes from '@/graphql/hooks/clients/useHumanCategorySubtypes';
import GenericSelect from '@/components/Common/GenericSelect';
import { getTranslatedText } from '@/common';
import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import routerParamsToProps from '@/hocs/routerParamsToProps';
import contextToProps from '@/hocs/contextToProps';
import {
  ADD_PROFILE_TO_SEARCH_POOL,
  useAddProfilesToMissionAndSequenceMutation,
} from '@/graphql/searchPoolProfiles';
import {
  invalidEmailFormat,
  isValidGithubProfileUrl,
  isValidLinkedinProfileUrl,
} from '@/common/validators';
import { DownIcon } from '@/assets/icons';
import NewCandidateCustomFields from './NewCandidateCustomFields';

import './NewCandidateModal.css';

const NewCandidateModal = ({
  open,
  onClose,
  onShowNotification,
  jobId,
  contactCategory = 'human',
}) => {
  if (!open) {
    return null;
  }
  return (
    <NewCandidateModaInner
      open
      onClose={onClose}
      onShowNotification={onShowNotification}
      jobId={jobId}
      contactCategory={contactCategory}
    />
  );
};

const NewCandidateModaInner = ({
  open,
  onClose,
  onShowNotification,
  jobId,
  contactCategory = 'human',
}) => {
  const { t } = useTranslation();
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [candidateAttachment, setCandidateAttachment] = useState();
  const [candidateData, setCandidateData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phoneNumber: '',
    website: '',
    linkedin: '',
    github: '',
    name: '',
    contactCategorySubtype: '',
  });
  const [errors, setErrors] = useState({
    emailError: '',
    linkedinError: '',
    githubError: '',
  });
  const {
    firstname,
    lastname,
    email,
    name,
    phoneNumber,
    linkedin,
    github,
    contactCategorySubtype,
    website,
  } = candidateData;
  const { emailError, linkedinError, githubError } = errors;

  const addProfileToSequenceContext = useContext(AddProfileToSequenceContext);

  const [addProfileToSearchPool] = useMutation(ADD_PROFILE_TO_SEARCH_POOL, {
    refetchQueries: ['getRevealSearchPoolResults'],
  });
  const [addProfilesToMissionAndSequence] =
    useAddProfilesToMissionAndSequenceMutation();
  const parseResume = useParseResume();
  const [parsingResume, setParsingResume] = useState(false);

  const clientId = useClientId();
  const { humanContactCategorySubtypes } = useHumanContactCategorySubtypes();
  const permissions = useClientPermissions(clientId).data?.client?.permissions;
  const clientSequences = useQuery(CLIENT_SEQUENCES, {
    variables: { clientId, filters: { activeOnly: true } },
    fetchPolicy: 'network-only',
  });
  const clientSequencesList = _.filter(
    clientSequences?.data?.client?.sequences,
    ({ isArchived }) => !isArchived,
  );

  const [selectedJobId, setSelectedJobId] = useState(jobId || '');
  const [selectedSequenceId, setSelectedSequenceId] = useState('');

  const { profileCustomFields } = useClientProfileCustomFields(clientId);

  const filteredCustomFields = useMemo(() => {
    return _.filter(profileCustomFields, (field) =>
      field.contactCategory?.type
        ? field.contactCategory?.type === contactCategory
        : contactCategory === 'human',
    );
  }, [profileCustomFields, contactCategory]);

  const [filledCustomFields, setFilledCustomFields] = useState([]);

  const { jobOptions: jobs } = useSearchPoolJobOptions('reveal', {
    activeOnly: true,
  });

  const { refetchQueries } = useImportCandidateRefetch();

  const areMandatoryFieldsFilled = useMemo(() => {
    const filledCustomFieldsById = _.indexBy(
      filledCustomFields,
      'clientCustomFieldId',
    );
    const requiredCustomFields = _.filter(
      filteredCustomFields,
      ({ isMandatoryAtCreation }) => !!isMandatoryAtCreation,
    );
    // eslint-disable-next-line
    for (const field of requiredCustomFields) {
      if (!filledCustomFieldsById[field.id]) {
        return false;
      }
    }

    return true;
  }, [filledCustomFields, filteredCustomFields]);

  const hasErrors = useCallback(() => {
    let formHasErrors = false;
    if (!areMandatoryFieldsFilled) {
      formHasErrors = true;
    }
    if (email && invalidEmailFormat({ email })) {
      setErrors((prev) => ({ ...prev, emailError: true }));
      formHasErrors = true;
    }

    // if (phoneNumber && !isValidPhoneNumber({ phoneNumber })) {
    //   setErrors((prev) => ({ ...prev, phoneNumberError: true }));
    //   formHasErrors = true;
    // }

    if (linkedin && !isValidLinkedinProfileUrl({ linkedin })) {
      setErrors((prev) => ({ ...prev, linkedinError: true }));
      setAccordionOpen(true);
      formHasErrors = true;
    }

    if (github && !isValidGithubProfileUrl({ github })) {
      setErrors((prev) => ({ ...prev, githubError: true }));
      setAccordionOpen(true);
      formHasErrors = true;
    }
    return formHasErrors;
  }, [areMandatoryFieldsFilled, email, github, linkedin]);

  const onInputChange = (fieldName, value) => {
    setCandidateData((prev) => ({ ...prev, [fieldName]: value }));
  };

  const onChangeJob = ({ id }) => {
    setSelectedJobId(id);
  };

  const onChangeFile = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        if (!reader.result) {
          return;
        }
        const attachment = {
          type: 'resume',
          ...(file.name && { name: file.name }),
          ...(reader.result && { file: { content: reader.result } }),
        };
        setCandidateAttachment(attachment);
        setParsingResume(true);
        const { data } = await parseResume(attachment);
        setParsingResume(false);
        if (!data) {
          return;
        }
        const parsedLinkedin = data?.sources?.linkedin;
        const parsedGithub = data?.sources?.github;
        const parseWebsite = data?.sources?.website;
        const parsedPhoneNumber = data?.phoneNumber;
        if (parsedLinkedin || parsedGithub || parsedPhoneNumber) {
          setAccordionOpen(true);
        }
        setCandidateData({
          firstname: firstname || data?.firstname,
          lastname: lastname || data?.lastname,
          email: email || data?.email,
          phoneNumber: phoneNumber || parsedPhoneNumber,
          website: website || parseWebsite,
          linkedin: linkedin || parsedLinkedin,
          github: github || parsedGithub,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  // reset inputs on open/close;
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setAccordionOpen(false);
      setCandidateAttachment(undefined);
      setCandidateData({
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        linkedin: '',
        github: '',
      });
      setErrors({
        emailError: '',
        linkedinError: '',
        githubError: '',
      });
    }
    return () => {
      mounted = false;
    };
  }, [open]);

  const disableSubmit =
    (contactCategory === 'human' && (!firstname || !lastname)) ||
    (contactCategory === 'company' && !name) ||
    !areMandatoryFieldsFilled;

  const handleSubmit = async () => {
    setSubmitting(true);
    if (hasErrors()) {
      return;
    }

    try {
      const profile = {
        data: {
          ...(firstname && { firstname }),
          ...(lastname && { lastname }),
          ...(email && { email }),
          ...(phoneNumber && { phoneNumber }),
          ...((linkedin || github || website) && {
            sources: {
              ...(website && { website }),
              ...(linkedin && { linkedin }),
              ...(github && { github }),
            },
          }),
          ...(name && {
            companyData: {
              ...(name && { name }),
            },
          }),
          contactCategory: {
            type: contactCategory,
            ...(contactCategorySubtype && {
              subtypes: contactCategorySubtype,
            }),
          },
          ...(filledCustomFields?.length > 0 && {
            customFields: filledCustomFields,
          }),
        },
        ...(candidateAttachment && { attachments: [candidateAttachment] }),
      };

      const variables = {
        searchPoolId: 'reveal',
        input: {
          profile,
          ...(selectedJobId && { missionId: selectedJobId }),
          sourceType: 'reveal-app-import',
        },
      };

      const { data } = await addProfileToSearchPool({ variables });
      const { error, profile: profileAdded } = data?.searchPool?.addProfile;

      if (error) {
        throw new Error(error?.type);
      }

      const withMissionId = selectedJobId.length > 0;
      const withSequenceId = selectedSequenceId.length > 0;

      if (withMissionId || withSequenceId) {
        const { data: addToMissionAndSequenceData } =
          await addProfilesToMissionAndSequence({
            variables: {
              searchPoolId: 'reveal',
              input: {
                ...(withMissionId && { missionId: selectedJobId }),
                ...(withSequenceId > 0 && { sequenceId: selectedSequenceId }),
                profileIds: [profileAdded.id],
              },
            },
            ...(withSequenceId > 0 && {
              sequence: _.findWhere(clientSequencesList, {
                id: selectedSequenceId,
              }),
            }),
          });

        const results =
          addToMissionAndSequenceData?.searchPool
            ?.addProfilesToMissionAndSequence?.results || [];
        const addToMissionAndSequenceError = _.filter(
          results,
          ({ success }) => !success,
        );

        if (!_.isEmpty(addToMissionAndSequenceError)) {
          onShowNotification({
            message: t('reveal.searchView.header.addToMissionError'),
            level: 'error',
          });
        }
      }

      refetchQueries({ withMissionId, withSequenceId });
      onShowNotification({
        message: t('newCandidateModal.success'),
        level: 'success',
      });
    } catch (e) {
      Sentry.captureException(e);

      if (e?.message === 'duplicate-profiles-error') {
        onShowNotification({
          message: t('newCandidateModal.errors.duplicatesFound'),
          level: 'error',
        });
      } else {
        onShowNotification({
          message: t('newCandidateModal.error'),
          level: 'error',
        });
      }
    }
    setErrors((prev) => ({ ...prev, emailError: false }));
    setSubmitting(false);
    onClose();
  };

  const handleSequenceChange = (id) => {
    const { checkIfProfileCanBeAddedToSequence, setModalOpen } =
      addProfileToSequenceContext;
    if (
      !checkIfProfileCanBeAddedToSequence(
        _.findWhere(clientSequencesList, { id }),
      )
    ) {
      setModalOpen(true);
    } else {
      setSelectedSequenceId(id);
    }
  };

  const contactCategoryOptions = _.map(
    humanContactCategorySubtypes,
    (subtype) => ({
      value: subtype.id,
      label: getTranslatedText(subtype.title),
    }),
  );

  return (
    <GenericModal
      className='new-candidate-modal'
      open={open}
      onClose={() => {
        setCandidateData({
          firstname: '',
          lastname: '',
          email: '',
          phoneNumber: '',
          linkedin: '',
          github: '',
        });
        setCandidateAttachment(undefined);
        onClose();
      }}
      size='tiny'
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        {t(`newCandidateModal.header.${contactCategory}`)}
      </Modal.Header>
      <Modal.Content>
        {permissions?.canEditHumanContactSubtypes &&
          !_.isEmpty(humanContactCategorySubtypes) &&
          contactCategory === 'human' && (
            <div className='input-container'>
              <div className='input-label'>
                {t('newCandidateModal.subtype')}
              </div>
              <div className='input-element'>
                <GenericSelect
                  isMulti
                  options={contactCategoryOptions}
                  placeholder={t('newCandidateModal.subtype')}
                  onChange={(selectedValues) =>
                    onInputChange(
                      'contactCategorySubtype',
                      _.map(selectedValues || [], ({ value }) => ({
                        id: value,
                      })),
                    )
                  }
                  className='human-contact-subtype'
                />
              </div>
            </div>
          )}
        <Dimmer.Dimmable
          as={Segment}
          loading={parsingResume}
          dimmed={parsingResume}
        >
          {contactCategory === 'human' && (
            <>
              <div className='input-container'>
                {candidateAttachment ? (
                  <div className='input-label'>{candidateAttachment.name}</div>
                ) : (
                  <CustomFileInput
                    onChange={(files) => onChangeFile(files?.[0])}
                    fileTypes='application/pdf'
                    t={t}
                  />
                )}
              </div>
              <div className='input-container'>
                <div className='input-label'>
                  {t('newCandidateModal.firstname')}*
                </div>
                <div className='input-element'>
                  <Input
                    className='form-row-input'
                    fluid
                    placeholder={t('newCandidateModal.firstname')}
                    value={firstname}
                    onChange={(_e, { value }) =>
                      onInputChange('firstname', value)
                    }
                    input={{
                      autoComplete: 'chrome-off',
                    }}
                    disabled={parsingResume}
                  />
                </div>
              </div>

              <div className='input-container'>
                <div className='input-label'>
                  {t('newCandidateModal.lastname')}*
                </div>
                <div className='input-element'>
                  <Input
                    className='form-row-input'
                    fluid
                    placeholder={t('newCandidateModal.lastname')}
                    value={lastname}
                    onChange={(_e, { value }) =>
                      onInputChange('lastname', value)
                    }
                    input={{
                      autoComplete: 'chrome-off',
                    }}
                    disabled={parsingResume}
                  />
                </div>
              </div>
            </>
          )}

          {contactCategory === 'company' && (
            <div className='input-container'>
              <div className='input-label'>{t('newCandidateModal.name')}*</div>
              <div className='input-element'>
                <Input
                  className='form-row-input'
                  fluid
                  placeholder={t('newCandidateModal.name')}
                  value={name}
                  onChange={(_e, { value }) => onInputChange('name', value)}
                  input={{
                    autoComplete: 'chrome-off',
                  }}
                />
              </div>
            </div>
          )}

          <div className='input-container'>
            <div className='input-label'>{t('newCandidateModal.email')}</div>
            <div className='input-element'>
              <Input
                className='form-row-input'
                fluid
                placeholder={t('newCandidateModal.email')}
                value={email}
                onChange={(_e, { value }) => {
                  onInputChange('email', value);
                  setErrors((prev) => ({ ...prev, emailError: false }));
                }}
                input={{
                  autoComplete: 'chrome-off',
                }}
                error={!!emailError}
                disabled={parsingResume}
              />
            </div>
            {emailError && (
              <div className='input-error'>
                {t('newCandidateModal.errors.email')}
              </div>
            )}
          </div>

          {contactCategory === 'company' && (
            <>
              <div className='input-container'>
                <div className='input-label'>
                  {t('newCandidateModal.phoneNumber')}
                </div>
                <div className='input-element'>
                  <Input
                    fluid
                    className='form-row-input'
                    placeholder={t('newCandidateModal.phoneNumber')}
                    value={phoneNumber}
                    onChange={(_e, { value }) => {
                      onInputChange('phoneNumber', value);
                    }}
                    input={{
                      autoComplete: 'chrome-off',
                    }}
                    disabled={parsingResume}
                  />
                </div>
              </div>
              <div className='input-container'>
                <div className='input-label'>
                  {t('newCandidateModal.website')}
                </div>
                <div className='input-element'>
                  <Input
                    fluid
                    className='form-row-input'
                    placeholder={t('newCandidateModal.website')}
                    value={website}
                    onChange={(_e, { value }) => {
                      onInputChange('website', value);
                    }}
                    input={{
                      autoComplete: 'chrome-off',
                    }}
                    disabled={parsingResume}
                  />
                </div>
              </div>
            </>
          )}

          {contactCategory === 'human' && (
            <>
              <div className='input-container'>
                <div className='input-label'>
                  {t('newCandidateModal.mission')}
                </div>
                <ProjectDropdown
                  projects={jobs}
                  currentMission={_.findWhere(jobs, {
                    id: selectedJobId,
                  })}
                  disabledProjectIds={_.filter(jobs, (job) => job.isArchived)}
                  onProjectSelected={(project) => {
                    onChangeJob(project);
                  }}
                  name='missions'
                  placeholder={t(
                    'reveal.modals.addToMission.projectPlaceholder',
                  )}
                  size='small'
                />
              </div>

              <div className='input-container'>
                <div className='input-label'>
                  {t('newCandidateModal.sequence')}
                </div>
                <div className='input-element'>
                  <div id='new-profile-sequence-dropdown'>
                    <SequenceDropdownForJob
                      jobId={selectedJobId}
                      clientId={clientId}
                      clientSequencesList={clientSequencesList}
                      currentSequence={{
                        sequenceId: selectedSequenceId,
                      }}
                      onSequenceSelected={(id) => handleSequenceChange(id)}
                      placeholder={t('newCandidateModal.sequencePlaceholder')}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <NewCandidateCustomFields
            customFields={filledCustomFields}
            profileCustomFields={filteredCustomFields}
            onChange={setFilledCustomFields}
            readOnlyFields={[]}
          />

          {contactCategory === 'human' && (
            <Accordion styled className='hs-accordion'>
              <Accordion.Title
                active={accordionOpen}
                icon={<DownIcon className='dropdown icon' />}
                content={t('newCandidateModal.addMore')}
                onClick={() => setAccordionOpen((state) => !state)}
              />

              <Accordion.Content active={accordionOpen}>
                <div className='input-container'>
                  <div className='input-label'>
                    {t('newCandidateModal.phoneNumber')}
                  </div>
                  <div className='input-element'>
                    <Input
                      className='form-row-input'
                      fluid
                      placeholder={t('newCandidateModal.phoneNumber')}
                      value={phoneNumber}
                      onChange={(_e, { value }) => {
                        onInputChange('phoneNumber', value);
                        // setErrors((prev) => ({ ...prev, phoneNumberError: false }));
                      }}
                      input={{
                        autoComplete: 'chrome-off',
                      }}
                      // error={!!phoneNumberError}
                      disabled={parsingResume}
                    />
                  </div>
                  {/* {phoneNumberError && (
                  <div className='input-error'>
                    {t('newCandidateModal.errors.phoneNumber')}
                  </div>
                )} */}
                </div>

                <div className='input-container'>
                  <div className='input-label'>
                    {t('newCandidateModal.linkedin')}
                  </div>
                  <div className='input-element'>
                    <Input
                      className='form-row-input'
                      fluid
                      placeholder={t('newCandidateModal.linkedinPlaceholder')}
                      value={linkedin}
                      onChange={(_e, { value }) => {
                        onInputChange('linkedin', value);
                        setSubmitting(false);
                        setErrors((prev) => ({
                          ...prev,
                          linkedinError: false,
                        }));
                      }}
                      input={{ autoComplete: 'chrome-off' }}
                      error={linkedinError}
                      disabled={parsingResume}
                    />
                  </div>
                  {linkedinError && (
                    <div className='input-error'>
                      {t('newCandidateModal.errors.linkedin')}
                    </div>
                  )}
                </div>

                <div className='input-container'>
                  <div className='input-label'>
                    {t('newCandidateModal.github')}
                  </div>
                  <div className='input-element'>
                    <Input
                      className='form-row-input'
                      fluid
                      placeholder={t('newCandidateModal.githubPlaceholder')}
                      value={github}
                      onChange={(_e, { value }) => {
                        onInputChange('github', value);
                        setSubmitting(false);
                        setErrors((prev) => ({
                          ...prev,
                          githubError: false,
                        }));
                      }}
                      input={{ autoComplete: 'chrome-off' }}
                      error={githubError}
                      disabled={parsingResume}
                    />
                  </div>
                  {githubError && (
                    <div className='input-error'>
                      {t('newCandidateModal.errors.github')}
                    </div>
                  )}
                </div>
              </Accordion.Content>
            </Accordion>
          )}
        </Dimmer.Dimmable>
      </Modal.Content>

      <Modal.Actions>
        <div className='actions'>
          <GenericButton size='big' primacy='secondary' onClick={onClose}>
            {t('newCandidateModal.cancel')}
          </GenericButton>
          <GenericButton
            size='big'
            onClick={handleSubmit}
            disabled={disableSubmit || submitting}
          >
            {t('newCandidateModal.confirm')}
          </GenericButton>
        </div>
      </Modal.Actions>
    </GenericModal>
  );
};

export default compose(contextToProps, routerParamsToProps)(NewCandidateModal);
