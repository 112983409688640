import gql from 'graphql-tag';
import Author from './Author';

export default gql`
  fragment Attachment on Attachment {
    id
    name
    creationDate
    type
    author {
      ...Author
    }
  }
  ${Author}
`;
