import { TFunction } from 'i18next';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'underscore';

import { DiversityOperator } from './types';

import './CustomCriteriaFilters.css';

const CriteriaDiversity = (props: {
  selectedOperator: DiversityOperator[];
  value: Record<DiversityOperator, string[]>;
  onValueChange: (
    value: Record<DiversityOperator, string[]>,
    operator?: DiversityOperator[],
  ) => void;
  onClose: () => void;
  t: TFunction;
}) => {
  const { selectedOperator, value, onValueChange, onClose, t } = props;

  const onChange = (key: DiversityOperator) => (
    ev: any,
    data: DropdownProps,
  ) => {
    const newValue = { ...value };
    const valueDropdown = data.value as string[];
    newValue[key] = valueDropdown;
    let newOperator = [...selectedOperator];

    // set or reset operator on selectino
    if (!selectedOperator.includes(key)) {
      newOperator = [...selectedOperator, key];
    }

    if (valueDropdown?.length === 0 && selectedOperator.includes(key)) {
      newOperator = newOperator.filter((op) => op !== key);
    }
    onValueChange(newValue, newOperator);
  };

  return (
    <div className='property-details-wrapper'>
      <div className='radio-wrapper'>
        <div className='radio-row'>
          <div className='label'>
            {t('reveal.searchView.search.firstnameIsLikely')}
          </div>
        </div>
        <Dropdown
          selection
          options={getOptions(t)}
          placeholder={t('reveal.searchView.search.diversityPlaceholder')}
          onChange={onChange('firstname_is_likely')}
          value={value.firstname_is_likely ?? ''}
          className='dropdown-contact-diversity'
          upward
        />
      </div>
      <div className='save-button' onClick={onClose}>
        {t('reveal.searchView.search.save')}
      </div>
    </div>
  );
};

const getOptions = (t: TFunction) => [
  {
    text: t('reveal.searchView.search.male'),
    value: 'male',
  },
  {
    text: t('reveal.searchView.search.female'),
    value: 'female',
  },
];

export default compose(withTranslation('translations'))(CriteriaDiversity);
