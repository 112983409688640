import React, { useMemo } from 'react';
import { mergeStyles, type GroupBase } from 'react-select';
import AsyncSelect, { type AsyncProps } from 'react-select/async';
import { useTranslation } from 'react-i18next';

import baseStyles from './styles';

const GenericAsyncSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  isClearable,
  noOptionsMessage,
  placeholder,
  styles,
  ...props
}: AsyncProps<Option, IsMulti, Group>): JSX.Element => {
  const { t } = useTranslation();

  const actualNoOptionsMessage = useMemo(
    () => noOptionsMessage || (() => t('common.noOptions')),
    [noOptionsMessage, t],
  );

  const actualPlaceholder = useMemo(
    () => placeholder || `${t('common.select')}...`,
    [placeholder, t],
  );

  return (
    <AsyncSelect
      {...props}
      isClearable={isClearable || false}
      styles={mergeStyles(baseStyles, styles)}
      noOptionsMessage={actualNoOptionsMessage}
      placeholder={actualPlaceholder}
    />
  );
};

export default GenericAsyncSelect;
