import React, { FC, useContext } from 'react';
import NotificationsContext from '@/context/NotificationSystem';

type OnShowNotifArg = {
  message: string;
  level: 'success' | 'error';
  type?: 'networkStatus';
};

export interface ContextToProps {
  onShowNotification: (arg: OnShowNotifArg) => void;
  onClearNotifications: () => void;
}

export default function contextToProps<
  T extends ContextToProps = ContextToProps
>(WrappedComponent: React.ComponentType<T>): FC<Omit<T, keyof ContextToProps>> {
  return (props) => {
    const { onShowNotification, onClearNotifications } = useContext(
      NotificationsContext,
    );

    return (
      <WrappedComponent
        {...(props as T)}
        onShowNotification={onShowNotification}
        onClearNotifications={onClearNotifications}
      />
    );
  };
}
