import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import useClientTemplates from '@/graphql/hooks/templates/useClientTemplates';
import EditTemplate from '@/containers/Parameters/Templates/modals/editTemplate';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import { convertNewlinesToLinebreaks } from '@/revealComponents/ProfileContactFlow/helpers';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import { getSweetEvaluator, SweetEvaluatorTypes } from '@/SweetEvaluator';
import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import MergeTagsContactFlowEditor from '@/containers/Editor/MergeTagsContactFlowEditor';
import { MergeTagsVariable } from '@/common/mergeTags/utils';
import getExternalExpressionEvaluatorFromType from '@/common/mergeTags/externalEvaluators';
import useMergeTagsProfileContext from '@/common/mergeTags/useMergeTagsProfileContext';
import { TASK_TYPES, isEmailAction } from '@/common/constants/taskTypes';
import { instantiateMessage } from '../../Actions/EmailAction';

export interface ActionMessage {
  senderId?: string | null;
  templateId?: string;
  body?: string;
  cc?: string[];
  bcc?: string[];
  subject?: string;
  snippets?: MergeTagsVariable[];
}

interface ActionEmailEditorProps {
  clientId: string;
  messageState: ActionMessage;
  setMessageState: Dispatch<SetStateAction<ActionMessage>>;
  hideMenu?: boolean;
  action?: any;
  onUpdateAction?: any;
  isFirstEmail?: boolean;
  isInlineEdition?: boolean;
}
const ActionEmailEditor: React.FC<ActionEmailEditorProps> = ({
  clientId,
  setMessageState,
  messageState,
  hideMenu,
  action,
  onUpdateAction,
  isFirstEmail = true,
  isInlineEdition = false,
}) => {
  const { t } = useTranslation();

  const {
    profile,
    currentSequence,
    needMergeTagEditorRefresh,
  } = useCandidateViewContext();

  const needInitialRefresh = useRef(true);
  const isPlugin = useIsPlugin();
  const [version, setVersion] = useState(0);
  const [editTemplateMode, setEditTemplateMode] = useState<any>({
    isActive: false,
    templateToEditId: null,
  });

  const clientTemplates = useClientTemplates(clientId);

  const templates = useMemo(
    () => clientTemplates?.data?.client?.templates ?? [],
    [clientTemplates],
  );

  const { body, subject, templateId } = messageState;
  const context = useMemo(() => profile?.resumeData || {}, [profile]);

  const mergeTagsProfileContext = useMergeTagsProfileContext({
    clientId,
    profile,
  });

  useEffect(() => {
    // On templates change
    if (!_.isEmpty(templates)) {
      setMessageState((previousMessageState) => {
        return instantiateMessage({
          message: previousMessageState,
          context,
          templates,
          defaultSubject: '',
        });
      });
      setVersion((previousVersion) => previousVersion + 1);
    }
  }, [templates, context, setMessageState]);

  useEffect(() => {
    // IMPORTANT: prevents cursor from switching to beginning of text,
    // after selecting a template from the dropdown and clicking inside the editor
    if (!templateId) {
      return;
    }
    // Hack for reveal contactflow merge tags
    if (!_.isEmpty(messageState.snippets)) {
      return;
    }
    // On templateId change (e.g, when adding a Discover profile to a job with default templates and profile CF follows job CF)
    setMessageState(
      instantiateMessage({
        message: messageState,
        context,
        templates,
        defaultSubject: '',
      }),
    );
    setVersion((previousVersion) => previousVersion + 1);
    // eslint-disable-next-line
  }, [templateId, context]);

  const onChangeBody = (updatedBody: string) => {
    const updatedMessage = {
      ..._.omit(messageState, 'templateId'),
      body: updatedBody,
    };
    if (onUpdateAction) {
      const updatedAction = {
        ...action,
        message: updatedMessage,
      };
      onUpdateAction({ updatedAction });
    }
    setMessageState(updatedMessage);
  };

  const onChangeSubject = (updatedSubject: string) => {
    const updatedMessage = {
      ..._.omit(messageState, 'templateId'),
      subject: updatedSubject,
    };
    if (onUpdateAction) {
      const updatedAction = {
        ...action,
        message: updatedMessage,
      };
      onUpdateAction({ updatedAction });
    }
    setMessageState(updatedMessage);
  };

  const onSelectTemplate = ({ templateId }: { templateId: string }) => {
    const template = _.findWhere(templates, { id: templateId });
    if (!template) {
      console.error(`Template not found : ${templateId}`);
      return;
    }

    const { subject: templateSubject, body: templateBody, snippets } = template;
    const sweetEvaluator = getSweetEvaluator({
      getExternalExpressionEvaluatorFromType,
    });

    const {
      subject: contactFlowSubject,
      body: contactFlowBody,
      snippets: contactFlowSnippets,
    } = MergeTagsService.replaceAllIds({
      subject: templateSubject || '',
      body: templateBody || '',
      subId: 'contactFlow',
      snippets,
      t,
    });

    const instantiatedSnippets = sweetEvaluator.getUpdatedVariables({
      variables: contactFlowSnippets as SweetEvaluatorTypes.Variable[],
      context: mergeTagsProfileContext,
    });

    const updatedBody = MergeTagsService.getSnippetIdOrContextValueFromText({
      context: mergeTagsProfileContext,
      text: contactFlowBody || '',
      instantiatedSnippets,
    });

    const updatedSubject =
      MergeTagsService.getSnippetIdOrContextValueFromText({
        context: mergeTagsProfileContext,
        text: contactFlowSubject || '',
        instantiatedSnippets,
      }) || '';

    const updatedMessage = {
      ...messageState,
      body: updatedBody,
      subject: updatedSubject,
      templateId,
    };

    setMessageState({
      ...updatedMessage,
      body: updatedBody,
      subject: updatedSubject,
      snippets: instantiatedSnippets,
    });

    setVersion(version + 1);
    if (onUpdateAction) {
      const updatedAction = {
        ...action,
        message: updatedMessage,
      };
      onUpdateAction({ updatedAction });
    }
  };

  const onSnippetAdded = ({
    newSnippet,
    updatedBody,
    updatedSubject,
  }: {
    newSnippet: SweetEvaluatorTypes.Variable;
    updatedBody: string;
    updatedSubject: string;
  }) => {
    if (!newSnippet) {
      return;
    }

    const sweetEvaluator = getSweetEvaluator({
      getExternalExpressionEvaluatorFromType,
    });

    const instantiatedSnippets = sweetEvaluator.getUpdatedVariables({
      variables: [
        ...(messageState.snippets || []),
        newSnippet,
      ] as SweetEvaluatorTypes.Variable[],
      context: mergeTagsProfileContext,
    });

    if (action?.message && onUpdateAction) {
      const updatedAction = {
        ...action,
        message: {
          ...action.message,
          snippets: instantiatedSnippets,
          body: updatedBody,
          subject: updatedSubject,
        },
        snippets: instantiatedSnippets,
      };
      onUpdateAction({ updatedAction });
    }
  };

  const onSnippetUpdated = ({
    updatedSnippet,
  }: {
    updatedSnippet: SweetEvaluatorTypes.Variable;
  }) => {
    if (isInlineEdition && onUpdateAction) {
      const updatedSnippets = _.map(messageState.snippets || [], (snippet) => {
        if (snippet.id === updatedSnippet.id) {
          return updatedSnippet;
        }
        return snippet;
      });

      onUpdateAction({
        updatedAction: {
          ...action,
          message: { ...action.message, snippets: updatedSnippets },
        },
      });
    }
    setVersion((prevState) => prevState + 1);
  };

  const onSnippetFragmentInserted = ({
    newSnippets,
    field,
    newContent,
  }: any) => {
    const updatedSnippets = [...(messageState.snippets || [])];
    let updatedSubject = messageState.subject;
    let updatedBody = messageState.body;

    if (!_.isEmpty(newSnippets)) {
      updatedSnippets.push(...newSnippets);
    }
    if (field === 'subject') {
      updatedSubject = newContent;
    }
    if (field === 'body') {
      updatedBody = newContent;
    }

    setMessageState({
      ...messageState,
      body: updatedBody,
      subject: updatedSubject,
      snippets: updatedSnippets,
    });

    if (onUpdateAction) {
      const updatedAction = {
        ...action,
        message: {
          ...action.message,
          snippets: updatedSnippets,
          body: updatedBody,
          subject: updatedSubject,
        },
        snippets: updatedSnippets,
      };
      onUpdateAction({ updatedAction });
    }

    setVersion((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (!currentSequence) {
      return;
    }
    if (needInitialRefresh.current) {
      setVersion((prevState) => prevState + 1);
      needInitialRefresh.current = false;
    }
  }, [currentSequence]);

  useEffect(() => {
    if (needMergeTagEditorRefresh) {
      setVersion((prevState) => prevState + 1);
    }
  }, [needMergeTagEditorRefresh]);

  return (
    <>
      <MergeTagsContactFlowEditor
        version={version}
        key={action?.actionId}
        clientId={clientId}
        fields={
          isFirstEmail && action?.type !== TASK_TYPES.CUSTOM
            ? 'double'
            : 'simple'
        }
        snippets={messageState.snippets}
        mergeTagsSubId='contactFlow'
        onChange={onChangeBody}
        onChangeSubject={onChangeSubject}
        onSnippetAdded={onSnippetAdded}
        onSnippetUpdated={onSnippetUpdated}
        onSnippetFragmentInserted={onSnippetFragmentInserted}
        defaultValue={convertNewlinesToLinebreaks(body)}
        defaultValueSubject={subject}
        alwaysShowMenu={!hideMenu}
        hideMenu={hideMenu}
        placeholder={t('editor.placeholder.emailBody')}
        placeholderSubject={t('editor.placeholder.emailSubject')}
        onSelectTemplate={onSelectTemplate}
        isPlugin={isPlugin}
        displayImageInsertion={isEmailAction(action)}
        editTemplate={
          templateId
            ? () =>
                setEditTemplateMode({
                  isActive: true,
                  templateToEditId: templateId,
                })
            : null
        }
      />
      <EditTemplate
        clientId={clientId}
        template={_.findWhere(templates, {
          id: editTemplateMode.templateToEditId,
        })}
        templateId={editTemplateMode.templateToEditId}
        open={editTemplateMode.isActive}
        onClose={() => setEditTemplateMode({ isActive: false })}
        submitCallback={null}
      />
    </>
  );
};

export default ActionEmailEditor;
