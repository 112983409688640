import { QueryHookOptions } from '@apollo/client';
import {
  SequenceGenerationInput,
  GeneratedSequence,
} from '@/types/generatedSequence';
import useClientId from '@/hooks/router/useClientId';
import useGenerateSequence, {
  GenerateSequenceData,
  GenerateSequenceVariables,
} from '../clients/useGenerateSequence';
import useGenerateDemoSequence, {
  GenerateDemoSequenceData,
  GenerateDemoSequenceVariables,
} from './useGenerateDemoSequence';

interface ClientTypes {
  data: GenerateSequenceData;
  variables: GenerateSequenceVariables;
}

interface DemoTypes {
  data: GenerateDemoSequenceData;
  variables: GenerateDemoSequenceVariables;
}

interface UseGenerateMaybeDemoSequenceInput<T extends ClientTypes | DemoTypes> {
  queryOptions?: QueryHookOptions<T['data'], T['variables']>;
  onGeneratedSequence?: (sequence: GeneratedSequence) => void;
  onData?: (data: GenerateDemoSequenceData | GenerateSequenceData) => void;
  onError?: (err: Error) => void;
}

type UseGenerateMaybeDemoSequenceResult = [
  (input: SequenceGenerationInput) => void,
  {
    loading: boolean;
    generatedSequence?: GeneratedSequence;
  },
];

const useGenerateMaybeDemoSequence = <T extends ClientTypes | DemoTypes>({
  queryOptions = {},
  onGeneratedSequence,
  onData,
  onError,
}: UseGenerateMaybeDemoSequenceInput<
  T
>): UseGenerateMaybeDemoSequenceResult => {
  const clientId = useClientId();
  const clientTuple = useGenerateSequence({
    queryOptions: {
      ...(queryOptions as QueryHookOptions<
        GenerateSequenceData,
        GenerateSequenceVariables
      >),
      onCompleted: (data) => {
        if (onGeneratedSequence) {
          onGeneratedSequence(data.client.generateSequence.sequence);
        }

        if (onData) {
          onData(data);
        }
      },
      onError: (error) => {
        if (onError) {
          onError(error);
        }
      },
    },
  });
  const demoTuple = useGenerateDemoSequence({
    queryOptions: {
      ...(queryOptions as QueryHookOptions<
        GenerateDemoSequenceData,
        GenerateDemoSequenceVariables
      >),
      onCompleted: (data) => {
        if (onGeneratedSequence) {
          onGeneratedSequence(data.generateSequence.sequence);
        }
        if (onData) {
          onData(data);
        }
      },
      onError: (error) => {
        if (onError) {
          onError(error);
        }
      },
    },
  });

  if (clientId === 'ai-seq-demo') {
    return demoTuple;
  }
  return clientTuple;
};

export default useGenerateMaybeDemoSequence;
