import React from 'react';
import _ from 'underscore';
import { Dropdown, Form } from 'semantic-ui-react';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import {
  TYPES_WITH_DESCRIPTION,
  TYPES_WITH_MESSAGE,
} from '@/revealComponents/FullContactFlowEditor/FullContactFlowActionEditor/helpers';
import { CustomTextArea } from '@/containers/Parameters/OfferCriteria/CustomFormItems';
import { getTranslatedText } from '@/common';
import NewTemplate from '@/containers/Parameters/Templates/modals/NewTemplate';
import EditTemplate from '@/containers/Parameters/Templates/modals/editTemplate';

import './CreateTaskForm.css';

const CreateTasksForm = ({
  clientId,
  formState,
  formOptions,
  compact = false,
  children = null,
}) => {
  const { t } = useTranslation();
  const selectedOption = _.findWhere(formOptions?.taskTypes, {
    type: formState?.taskType,
  });

  const dueNowField = (
    <Form.Field>
      {!compact && <label>{t('reveal.createManualTasks.dueDate')}</label>}
      <Dropdown
        upward
        className={classNames('due-when-dropdown', compact && 'small-field')}
        selection
        value={formState.dueWhen}
        options={[
          {
            value: 'due-now',
            text: t('reveal.tasks.dueNow'),
          },
          {
            value: 'due-later',
            text: t('reveal.tasks.dueLater'),
          },
        ]}
        onChange={(e, { value }) => formState.setDueWhen(value)}
      />
    </Form.Field>
  );

  const dueLaterField = (
    <Form.Field className={compact && 'mt-O'}>
      {formState.dueWhen === 'due-later' && (
        <DatePicker
          popperPlacement='top'
          showTimeSelect
          selected={formState.dueDate}
          onChange={formState.setDueDate}
          dateFormat='MMMM d, yyyy'
        />
      )}
    </Form.Field>
  );

  const descriptionField = _.contains(
    TYPES_WITH_DESCRIPTION,
    formState.taskType,
  ) && (
    <Form.Field>
      {!compact && <label>{t('reveal.createManualTasks.description')}</label>}
      <CustomTextArea
        key={formState?.taskType} // TODO: hack to reload state on type change
        label='description'
        currentValue={
          typeof formState?.taskDescription === 'string'
            ? formState?.taskDescription
            : getTranslatedText(formState?.taskDescription)
        }
        onChange={({ value }) => formState.setTaskDescription(value?.value)}
        placeholderText='Description (optional)'
      />
    </Form.Field>
  );

  return (
    <Form
      id='create-tasks-form'
      className='create-manual-tasks-form'
      onSubmit={formState?.onSubmit}
      loading={formOptions?.loading}
    >
      <Form.Dropdown
        className={classNames(
          'task-type-dropdown',
          'hs-dropdown-menu-ellipsis',
          'hs-dropdown-text-with-image',
          formState.disabled && 'hs-dropdown-disabled',
        )}
        label={!compact && t('reveal.createManualTasks.taskTitle')}
        selection
        search
        fluid
        value={formState?.taskType}
        onChange={(e, { value }) => formState.setTaskType(value)}
        options={formOptions?.taskTypes?.map((option) => ({
          ...option,
          text: t(`sequences.tasks.${option.type}`),
        }))}
        placeholder='Select or type task title'
        text={
          selectedOption ? (
            <div className='text-with-image'>
              <img alt='text' src={selectedOption?.image?.src} />
              <span>{t(`sequences.tasks.${selectedOption?.type}`)}</span>
            </div>
          ) : null
        }
      />

      <Form.Dropdown
        className={classNames(
          'owner-dropdown',
          formState.disabled && 'hs-dropdown-disabled',
        )}
        search
        selection
        fluid
        label={!compact && t('reveal.createManualTasks.assignedTo')}
        placeholder='Pick an owner'
        value={formState?.selectedOwnerEmail}
        onChange={(e, { value }) => formState.setSelectedOwnerEmail(value)}
        options={formOptions?.users}
      />

      {compact ? (
        <>{descriptionField}</>
      ) : (
        <>
          <div className={classNames('d-flex-align-end', 'margin-top')}>
            {dueNowField}
            {dueLaterField}
          </div>
          {descriptionField}
        </>
      )}

      {_.contains(TYPES_WITH_MESSAGE, formState.taskType) && (
        <Form.Dropdown
          selection
          fluid
          label={!compact && 'Message template'}
          placeholder='Select a template'
          value={formState?.selectedTemplateId}
          onChange={(e, { value }) => formState.setSelectedTemplateId(value)}
          options={formOptions?.templates}
          disabled={formState.disabled}
        />
      )}

      {formState?.newTemplateOpen && (
        <NewTemplate
          open
          clientId={clientId}
          onClose={() => formState.setNewTemplateOpen(false)}
          onPostSubmit={() => formState.setNewTemplateOpen(false)}
        />
      )}

      <EditTemplate
        clientId={clientId}
        template={formState?.templateToEdit}
        templateId={formState?.templateToEdit?.id}
        open={!!formState?.templateToEdit}
        onClose={() => formState.setTemplateToEdit(null)}
      />

      <div
        className={classNames(
          compact && 'compact-actions',
          compact && 'margin-top',
        )}
      >
        {compact && (
          <>
            {dueNowField}
            {dueLaterField}
          </>
        )}
        {children && children}
      </div>
    </Form>
  );
};

export default CreateTasksForm;
