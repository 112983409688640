import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  GET_REVEAL_SEARCH_POOL_RESULTS,
  REVEAL_CATEGORIES,
  REVEAL_MAX_NB_RESULTS,
} from '@/hocs/searchPool/withRevealSearchPoolResults';
import { GET_SEQUENCE } from '@/graphql/sequences';
import { CLIENT_SEQUENCES } from '@/containers/Parameters/Sequences/queries';
import useClientId from '../../../hooks/router/useClientId';
import useSequenceId from '../../../hooks/router/useSequenceId';

const useImportCandidateRefetch = () => {
  const clientId = useClientId();
  const sequenceId = useSequenceId();
  const matchCandidatePage = useRouteMatch('/client/:clientId/reveal/search');
  const matchSequencesPage = useRouteMatch({
    path: '/client/:clientId/reveal/sequences',
    exact: true,
  });
  const matchSequencePage = useRouteMatch({
    path: '/client/:clientId/reveal/sequences/:sequenceId',
    exact: true,
  });

  // Use this 4 queries to refetch the cache after candidate added on the modal
  // skip queries here, but call them after candidate added on the handleSubmit method
  const { refetch: refetchRevealSearchPoolResults } = useQuery(
    GET_REVEAL_SEARCH_POOL_RESULTS,
    {
      skip: true,
      fetchPolicy: 'network-only',
      variables: {
        searchPoolId: 'reveal',
        searchesCriteria: [{ free: '{}' }],
        searchText: '',
        categories: REVEAL_CATEGORIES,
        maxNbResults: REVEAL_MAX_NB_RESULTS,
      },
    },
  );

  const { refetch: refetchClientSequences } = useQuery(CLIENT_SEQUENCES, {
    skip: true,
    fetchPolicy: 'network-only',
    variables: { clientId },
  });

  const { refetch: refetchSequence } = useQuery(GET_SEQUENCE, {
    skip: true,
    fetchPolicy: 'network-only',
    variables: { sequenceId },
  });

  const refetchQueries = ({ withSequenceId }) => {
    if (matchCandidatePage) {
      refetchRevealSearchPoolResults();
    }

    if (withSequenceId && matchSequencesPage) {
      refetchClientSequences();
    }
    if (withSequenceId && matchSequencePage) {
      refetchSequence();
    }
  };

  return {
    refetchQueries,
  };
};

export default useImportCandidateRefetch;
