import React from 'react';

import './TwoColumnLayout.css';

const TwoColumnLayout = ({ leftColumn, rightColumn }) => {
  return (
    <div className='two-column-layout'>
      <div className='column-1'>
        {leftColumn}
      </div>
      <div className='column-2'>
        {rightColumn}
      </div>
    </div>
  );
};

export default TwoColumnLayout;
