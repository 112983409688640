/* eslint-disable import/prefer-default-export */
import _ from 'underscore';
import moment from 'moment';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { generateId } from '@/components/CustomFields/CustomFieldForm';
import type { CSVLines, CSVLine, ColumnsMapping } from './helpers';

function getEnumInitial(
  column: CSVLine,
): {
  type: 'enum';
} | null {
  const count = column.length;
  const uniqueValues = new Set(column);

  const uniqueCount = uniqueValues.size;
  if (uniqueCount >= 20 || uniqueCount / count > 0.6) return null;
  return {
    type: 'enum',
  };
}

function getInitialText(): Partial<CustomFieldDefinition> {
  return { type: 'text' };
}

function getUniqueValues(column: CSVLine): { title: string }[] {
  const uniqueValues = new Set(column);
  return Array.from(uniqueValues.values()).map((x) => ({ title: x }));
}

export function getDateFormat(column: CSVLine): string | null {
  const dateFormats = [
    'MM-DD-YYYY',
    'DD-MM-YYYY',
    'YYYY-MM-DD',
    'MM/DD/YYYY',
    'DD/MM/YYYY',
    'YYYY/MM/DD',
    'YYYY-MM-DDTHH:mm:ss.SSSZ'
  ];

  const aggregator = Object.fromEntries(dateFormats.map((x) => [x, 0]));
  column.forEach((value) => {
    const trimmedValue = (_.isString(value) && value.trim()) || '';
    dateFormats.forEach((dateFormat) => {
      const parsed = moment(trimmedValue, dateFormat, true);
      if (parsed.isValid()) {
        aggregator[dateFormat] += 1;
      }
    })
  });

  const values = Array.from(Object.entries(aggregator))
  const maxDateFormat = _.max(values, (x) => x[1]) as unknown as [string, number];

  return maxDateFormat[0];
}

/**
 * Analyse column to determine new custom field settings
 */
export function getInitialForColumn(
  rows: CSVLines,
  { columnIndex }: { columnIndex?: number } = {},
): {
  initial: Partial<CustomFieldDefinition>;
  initialEnumOptions: { title: string }[];
} | null {
  if (typeof columnIndex !== 'number') return null;
  if (!rows?.length) return null;
  if (columnIndex >= rows[0].length) return null;
  const column = rows.map((x) => x[columnIndex]);

  const initialEnum = getEnumInitial(column);

  return {
    initial: initialEnum || getInitialText(),
    initialEnumOptions: getUniqueValues(column),
  };
}

export type DetectedNewEnumOptions = {
  customField: CustomFieldDefinition;
  newEnumOptions: { title: string }[];
}[];
/**
 * Analyse column and determine if new enum options should be created
 *
 * Enum options are matched by id:
 * - for enum options in the custom field, id property is used
 * - for enum options taken from the csv column, an id is generated using the
 * value (same as generated from the CustomFieldForm)
 */
export function detectNewEnumOptions(
  rows: CSVLines,
  {
    columnsMapping,
    customFields,
  }: { columnsMapping: ColumnsMapping; customFields: CustomFieldDefinition[] },
): DetectedNewEnumOptions {
  if (!rows?.length) return [];
  if (columnsMapping.length !== rows[0].length) {
    console.warn('columnsMapping and rows length mismatch');
  }
  return _.compact(
    columnsMapping.map((mapping, columnIndex) => {
      if (!mapping || mapping.type !== 'custom-field') return null;

      const customFieldId = mapping.id;
      const customField = customFields.find((x) => x.id === customFieldId);
      if (customField?.type !== 'enum') return null;

      const column = rows.map((x) => x[columnIndex]);

      const newEnumOptions: { title: string }[] = [];
      const existingEnumOptions = new Set(customField.options.map((x) => x.id));

      getUniqueValues(column).forEach((value) => {
        const id = generateId(value.title);
        if (existingEnumOptions.has(id)) return;
        if (_.isEmpty(value.title)) return;
        newEnumOptions.push({ title: value.title });
      });

      if (!newEnumOptions.length) return null;
      return {
        customField,
        newEnumOptions,
      };
    }),
  );
}

export function getFloatValue(value: string): number | null {
  if (!value) return null;
  const floatValue = parseFloat(value.replace(/\s+/g, '').replace(',', '.'));
  if (Number.isNaN(floatValue)) return null;
  return floatValue;
}

export function getIntegerValue(value: string): number | null {
  if (!value) return null;
  const intValue = parseInt(value.replace(/\s+/g, ''), 10);
  if (Number.isNaN(intValue)) return null;
  return intValue;
}

export function getDateValue(value: string, format: string | null): string | null {
  if (!value) return null;
  const args = format ? [format] : [];
  const dateValue = moment(value, ...args);
  if (!dateValue.isValid()) return null;
  return dateValue.format('YYYY-MM-DD');
}
