import React from 'react';

import { removeEmptyKeyValues } from '@/common';
import GreenhouseFilters from '../GreenhouseFilters';
import LeverFilters from '../LeverFilters';
import SmartrecruitersFilters from '../SmartrecruitersFilters';
import TeamtailorFilters from '../TeamtailorFilters';
import RecruiteeFilters from '../RecruiteeFilters';
import WelcomekitFilters from '../WelcomekitFilters';
import WorkableFilters from '../WorkableFilters';
import BoondmanagerFilters from '../BoondmanagerFilters';
import AshbyFilters from '../AshbyFilters';
import RecruitcrmFilters from '../RecruitcrmFilters';
import AdventureFilters from '../Adventure';
import SuccessfactorsFilters from '../SuccessfactorsFilters';
import PinpointFilters from '../PinpointFilters';

const AtsFilters = ({
  projectId,
  clientId,
  searchParams,
  onUpdateSearchParams,
}) => {
  const handleUpdateATSFilter = ({
    greenhouseFilters,
    leverFilters,
    smartrecruitersFilters,
    teamtailorFilters,
    welcomekitFilters,
    workableFilters,
    recruiteeFilters,
    recruitcrmFilters,
    boondmanagerFilters,
    ashbyFilters,
    pinpointFilters,
    adventureFilters,
    successfactorsFilters,
  }) => {
    onUpdateSearchParams({
      newSearchParams: {
        ...searchParams,
        ...(greenhouseFilters && {
          greenhouseFilters: removeEmptyKeyValues(greenhouseFilters),
        }),
        ...(adventureFilters && {
          adventureFilters: removeEmptyKeyValues(adventureFilters),
        }),
        ...(ashbyFilters && {
          ashbyFilters: removeEmptyKeyValues(ashbyFilters),
        }),
        ...(pinpointFilters && {
          pinpointFilters: removeEmptyKeyValues(pinpointFilters),
        }),
        ...(leverFilters && {
          leverFilters: removeEmptyKeyValues(leverFilters),
        }),
        ...(smartrecruitersFilters && {
          smartrecruitersFilters: removeEmptyKeyValues(smartrecruitersFilters),
        }),
        ...(teamtailorFilters && {
          teamtailorFilters: removeEmptyKeyValues(teamtailorFilters),
        }),
        ...(recruiteeFilters && {
          recruiteeFilters: removeEmptyKeyValues(recruiteeFilters),
        }),
        ...(recruitcrmFilters && {
          recruitcrmFilters: removeEmptyKeyValues(recruitcrmFilters),
        }),
        ...(welcomekitFilters && {
          welcomekitFilters: removeEmptyKeyValues(welcomekitFilters),
        }),
        ...(workableFilters && {
          workableFilters: removeEmptyKeyValues(workableFilters),
        }),
        ...(boondmanagerFilters && {
          boondmanagerFilters: removeEmptyKeyValues(boondmanagerFilters),
        }),
        ...(successfactorsFilters && {
          successfactorsFilters: removeEmptyKeyValues(successfactorsFilters),
        }),
        ...(pinpointFilters && {
          pinpointFilters: removeEmptyKeyValues(pinpointFilters),
        }),
      },
    });
  };

  return (
    <>
      <GreenhouseFilters
        projectId={projectId}
        clientId={clientId}
        filters={searchParams?.greenhouseFilters}
        onUpdate={handleUpdateATSFilter}
      />

      <LeverFilters
        projectId={projectId}
        clientId={clientId}
        filters={searchParams?.leverFilters}
        onUpdate={handleUpdateATSFilter}
      />

      <SuccessfactorsFilters
        projectId={projectId}
        clientId={clientId}
        filters={searchParams?.successfactorsFilters}
        onUpdate={handleUpdateATSFilter}
      />

      <SmartrecruitersFilters
        projectId={projectId}
        clientId={clientId}
        filters={searchParams?.smartrecruitersFilters}
        onUpdate={handleUpdateATSFilter}
      />

      <TeamtailorFilters
        projectId={projectId}
        clientId={clientId}
        filters={searchParams?.teamtailorFilters}
        onUpdate={handleUpdateATSFilter}
      />

      <RecruiteeFilters
        projectId={projectId}
        clientId={clientId}
        filters={searchParams?.recruiteeFilters}
        onUpdate={handleUpdateATSFilter}
      />

      <RecruitcrmFilters
        projectId={projectId}
        clientId={clientId}
        filters={searchParams?.recruitcrmFilters}
        onUpdate={handleUpdateATSFilter}
      />

      <WelcomekitFilters
        projectId={projectId}
        clientId={clientId}
        filters={searchParams?.welcomekitFilters}
        onUpdate={handleUpdateATSFilter}
      />

      <WorkableFilters
        projectId={projectId}
        clientId={clientId}
        filters={searchParams?.workableFilters}
        onUpdate={handleUpdateATSFilter}
      />

      <BoondmanagerFilters
        projectId={projectId}
        clientId={clientId}
        filters={searchParams?.boondmanagerFilters}
        onUpdate={handleUpdateATSFilter}
      />

      <AshbyFilters
        projectId={projectId}
        clientId={clientId}
        filters={searchParams?.ashbyFilters}
        onUpdate={handleUpdateATSFilter}
      />

      <AdventureFilters
        projectId={projectId}
        clientId={clientId}
        filters={searchParams?.adventureFilters}
        onUpdate={handleUpdateATSFilter}
      />

      <PinpointFilters
        projectId={projectId}
        clientId={clientId}
        filters={searchParams?.pinpointFilters}
        onUpdate={handleUpdateATSFilter}
      />
    </>
  );
};

export default AtsFilters;
