import React from 'react';
import { useTranslation } from 'react-i18next';

import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { getShortLanguage } from '@/common/utils/i18n';
import useClientId from '@/hooks/router/useClientId';
import type { SearchPoolProfile } from '@/types/searchPoolProfile';
import type { ProfileOverviewCardItemContent } from '@/common/helpers/profileOverviewCard';

import { contentToStrings } from './helpers';

import styles from './OverviewCardItemContent.module.less';

type Props = {
  profile: SearchPoolProfile;
  content: ProfileOverviewCardItemContent;
};

function OverviewCardItemContent({ profile, content }: Props) {
  const clientId = useClientId();
  const { i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const { profileCustomFields: customFieldDefinitions } =
    useClientProfileCustomFields(clientId);
  const strings = contentToStrings({ profile, lang, customFieldDefinitions })(
    content,
  );
  if (strings.length === 0) {
    return '-';
  }
  if (strings.length === 1) {
    return strings[0];
  }
  return (
    <span className={styles.content}>
      {strings.map((string) => (
        <span key={string}>{string}</span>
      ))}
    </span>
  );
}

export default OverviewCardItemContent;
