/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Button, Checkbox, Modal } from 'semantic-ui-react';
import { compose } from 'underscore';
import withUpdateUserSettings from '@/hocs/users/withUpdateUserSettings';
import logAction from '@/common/logAction';
import styles from './PricingValidationModal.module.css';

interface PricingValidationModalProps {
  clientId: string;
  updateUser: any;
}

const PricingValidationModal: React.FC<PricingValidationModalProps> = ({
  clientId,
  updateUser,
}) => {
  const [open, setOpen] = useState(true);
  const [cgvAccepted, setCgvAccepted] = useState(false);
  const [, setPrivacyAccepted] = useState(false);

  logAction({
    type: 'new-pricing-validation-showed',
    info: {
      clientId,
    },
  });

  const acceptConditions = () => {
    updateUser({
      newPricingValidated: true,
    })
      .then(() => {
        setOpen(false);
      })
      .catch((e: any) => {
        console.error(e);
      });
  };

  return (
    <Modal open={open} size='large' className={styles.pricingValidationModal}>
      <Modal.Header>
        <h3 className={styles.title}>
          Modification des Conditions Générales de Vente - HireSweet Marketplace
        </h3>
      </Modal.Header>
      <div className={styles.subTitle}>
        Nos conditions générales de vente ont changé le 1er mai 2024 et 
        restent disponibles{' '}
        <a
          href='https://content.hiresweet.com/clients-hiresweet/hiresweet-marketplace-fr/hiresweet-marketplace-cgv'
          target='_blank'
          rel='noopener noreferrer'
        >
          ici
        </a>
        .
      </div>
      <div className={styles.content}>
        Voici les points principaux :
        <ul>
          <li>
            Ces changements sont applicables aux recrutements de tous les candidats 
            contactés à partir du 1er mai 2024. 
          </li>
          <li>
            Les recrutements sont facturés 15% de la rémunération annuelle (paiement en une fois), 
            ou 2% par mois pendant 12 mois maximum tant que la personne reste en poste (paiement mensualisé)
          </li>
          <li>
            En cas de départ du candidat (quelle qu’en soit la raison) avant la fin du 4ème mois, un avoir valable 12 mois sera édité.
          </li>
         
          {false && <li>
            Vous vous inscrivez sur la marketplace pour une durée indéterminée,
            et pouvez à tout moment vous désinscrire (directement depuis votre
            compte).
          </li>}
          {false && <li>
            Nous prenons fortement en considération votre vie privée au regard
            du RGPD. Voici notre{' '}
            <a
              href='https://content.hiresweet.com/clients-hiresweet/hiresweet-marketplace-fr/hiresweet-marketplace-politique-de-confidentialite'
              target='_blank'
              rel='noopener noreferrer'
            >
              politique de confidentialité
            </a>
          </li>}
        </ul>
        <div className={styles.conditions}>
          <div className={styles.checkboxContainer}>
            <Checkbox
              label={
                <label>
                  J’ai lu et j’accepte les{' '}
                  <a
                    href='https://content.hiresweet.com/clients-hiresweet/hiresweet-marketplace-fr/hiresweet-marketplace-cgv'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    conditions générales de vente
                  </a>{' '}
                  de HireSweet
                </label>
              }
              onChange={(_e, data) => setCgvAccepted(data?.checked || false)}
            />
          </div>
          {false && <div className={styles.checkboxContainer}>
            <Checkbox
              label={
                <label>
                  J’ai lu et j’accepte les{' '}
                  <a
                    href='https://content.hiresweet.com/clients-hiresweet/hiresweet-marketplace-fr/hiresweet-marketplace-politique-de-confidentialite'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    politiques de confidentialité
                  </a>{' '}
                  de HireSweet
                </label>
              }
              onChange={(_e, data) =>
                setPrivacyAccepted(data?.checked || false)
              }
            />
          </div>}
        </div>
      </div>
      <Modal.Actions>
        <Button
          content='Confirmer'
          disabled={!cgvAccepted}
          primary
          onClick={acceptConditions}
          size='big'
        />
      </Modal.Actions>
    </Modal>
  );
};

export default compose(withUpdateUserSettings)(PricingValidationModal);