import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

const useSelectedProfile = (): [
  string | qs.ParsedQs | string[] | qs.ParsedQs[] | undefined,
  (profileId: string | null | undefined) => void,
] => {
  const history = useHistory();
  const location = useLocation();
  const selectedProfileId = qs.parse(location.search, { ignoreQueryPrefix: true })?.profileId;
  const setSelectedProfileId = (profileId: string | null | undefined) => {
    history.push({
      ...location,
      search: profileId ? `?profileId=${profileId}` : '',
    });
  };
  return [selectedProfileId, setSelectedProfileId];
};

export default useSelectedProfile;
