import gql from 'graphql-tag';

export default gql`
  fragment PipeStepStat on PipeStepStat {
    stepId
    count
    pipeSubStepStats {
      subStepId
      count
    }
  }
`;
