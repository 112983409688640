import _, { compose } from 'underscore';
import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { Image, Loader } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import { getFullname } from '@/common/helpers/person';
import { sentryCaptureException } from '@/common';
import useUnHoldOffer from '@/graphql/hooks/offers/useUnHoldOffer';
import useClientId from '@/hooks/router/useClientId';
import GenericButton from '@/components/Common/GenericButton';
import useRemovePendingAndPrependingProfilesFromOffer from '@/graphql/hooks/offers/useRemovePendingAndPrependingProfilesFromOffer';
import logAction from '@/common/logAction';

import contextToProps from '../../../hocs/contextToProps';
import withOfferCriteria from '../../../hocs/offers/withOfferCriteria';
import routerParamsToProps from '../../../hocs/routerParamsToProps';
import withUpdateOfferCriteria from '../../../hocs/offers/withUpdateOfferCriteria';
import withOfferCriteriaOptions from '../../../hocs/offers/withOfferCriteriaOptions';
import withClientCriteriaOptions from '../../../hocs/clients/withClientCriteriaOptions';

import SidePanelInformations from './SidePanelInformations';
import {
  getInitialValues,
  getLabelToValue,
  getNewField,
  getOptions,
  removeUnwantedFields,
} from './helpers';
import PureCriteriaForm from './PureCriteriaForm';

import './criteria.css';

class OfferCriteria extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: null,
      labelToValue: null,
      editing: {},
      version: 0,
      requiredCriterias: {
        jobPositions: '',
        locationsAndRemote: [],
        requiredSkills: [],
      },
    };
  }

  maxEditingTimestamp = {};

  componentWillMount() {
    const { mode } = this.props;
    this.canDisplayOnboardingMessage = mode !== 'new-offer';
  }

  componentDidMount() {
    const { criteriaOptions } = this.props;
    if (criteriaOptions) {
      this.updateOptionsInState(removeUnwantedFields(criteriaOptions));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      criteriaOptions,
      loading,
      offer = {},
      onShowNotification,
      t,
    } = this.props;
    if (prevProps.criteriaOptions !== criteriaOptions) {
      this.updateOptionsInState(removeUnwantedFields(criteriaOptions));
    }
    if (!loading && this.canDisplayOnboardingMessage) {
      this.canDisplayOnboardingMessage = false;
      if (!offer.criteria) {
        onShowNotification({
          message: t('criteria.onboardingMessage'),
          level: 'success',
        });
      }
    }
  }

  updateOptionsInState = (criteriaOptions) => {
    const { handleLoaded } = this.props;
    const options = getOptions(criteriaOptions);
    const labelToValue = getLabelToValue(criteriaOptions);
    this.setState({ options, labelToValue });
    if (handleLoaded) {
      handleLoaded();
    }
  };

  handleNotifyModification = ({ label, error }) => {
    const { editing } = this.state;
    if (error) {
      this.setState({
        editing: {
          ...editing,
          [label]: 'error',
        },
      });
      return;
    }
    if (editing[label] !== 'loading') {
      this.setState({
        editing: {
          ...editing,
          [label]: 'loading',
        },
      });
    }
    this.maxEditingTimestamp[label] = Date.now();
    setTimeout(() => {
      if (this.maxEditingTimestamp[label] > Date.now() - 250) {
        return;
      }
      this.setState(
        {
          editing: {
            ...editing,
            [label]: 'finished',
          },
        },
        () => {
          setTimeout(() => {
            if (editing[label] === 'finished') {
              this.setState({
                editing: {
                  ...editing,
                  [label]: null,
                },
              });
            }
          }, 1000);
        },
      );
    }, 500);
  };

  handleChange = ({ label, value, error }) => {
    const { mode, offer, handleChangeCriteria, updateCriteria } = this.props;
    if (mode !== 'new-offer') {
      this.handleNotifyModification({ label, error });
    }
    const { requiredCriterias } = this.state;
    if (
      label === 'jobPositions' ||
      label === 'locationsAndRemote' ||
      label === 'requiredSkills'
    ) {
      requiredCriterias[label] = label === 'jobPositions' ? value.value : value;
    }
    const realValue = label === 'jobPositions' ? [value] : value;
    const { labelToValue } = this.state;
    const offerCriteriaToSave = ((offer || {}).criteria || {})
      .collaboratorsInCharge
      ? {
          ...(offer || {}).criteria,
          collaboratorsInCharge: _.pluck(
            ((offer || {}).criteria || {}).collaboratorsInCharge,
            'email',
          ),
        }
      : (offer || {}).criteria;
    const newField = getNewField({
      label,
      value: realValue,
      labelToValue,
      currentCriteria: offerCriteriaToSave,
    });
    if (newField) {
      const newCriteria = removeUnwantedFields({
        ...offerCriteriaToSave,
        ...newField,
      });
      if (mode === 'new-offer') {
        handleChangeCriteria({ newCriteria });
      } else if (!error) {
        updateCriteria({ id: offer.id, input: newCriteria });
      }
    }
  };

  isRequiredCriteriasEmpty = () => {
    const {
      requiredCriterias: { jobPositions, locationsAndRemote, requiredSkills },
    } = this.state;
    return (
      _.isEmpty(jobPositions) &&
      _.isEmpty(locationsAndRemote) &&
      _.isEmpty(requiredSkills)
    );
  };

  render() {
    const {
      loading,
      loadingCriteriaOptions,
      error,
      errorCriteriaOptions,
      errorFields,
      offer,
      mode,
      simplifiedMode,
      handleSubmit,
      submitting,
      setRequirementsMeet,
      criteria = {},
      t,
    } = this.props;
    const { options, editing } = this.state;

    const notificationByLabel = {
      finished: (
        <span className='saved-notification'>{t('criteria.saved')}</span>
      ),
      loading: <Loader size='tiny' active inline />,
      error: (
        <span className='saved-notification error'>
          {t('criteria.notSaved')}
        </span>
      ),
    };

    const modifyingNotification = (label) =>
      mode !== 'new-offer' ? (
        <div className='modifying-notification'>
          {notificationByLabel[editing[label]] || null}
        </div>
      ) : (
        ''
      );

    if (error || errorCriteriaOptions) {
      return <p />;
    }

    if (loading || loadingCriteriaOptions || !offer || !options) {
      return (
        <div className='criteria-view criteria-view-placeholder'>
          <Loader active inline='centered' size='large' />
        </div>
      );
    }

    const initialValues = !_.isEmpty(offer)
      ? getInitialValues({ offer })
      : getInitialValues({ offer: { criteria } });

    if (!_.isEmpty(initialValues) && this.isRequiredCriteriasEmpty()) {
      this.setState({
        requiredCriterias: {
          jobPositions: initialValues?.jobPosition?.value,
          locationsAndRemote: initialValues?.locationsAndRemote || [],
          requiredSkills: initialValues?.requiredSkills || [],
        },
      });
    }

    const { requiredCriterias, version } = this.state;

    const hasRequiredSkills = [
      'full-stack',
      'web-backend',
      'web-frontend',
      'software-engineering',
      'lead-dev',
      'mobile-developer',
      'web-developer',
    ].includes(requiredCriterias?.jobPositions);
    const canProceed =
      !_.isEmpty(requiredCriterias?.locationsAndRemote) &&
      !_.isEmpty(requiredCriterias?.jobPositions) &&
      (!hasRequiredSkills ||
        (hasRequiredSkills && !_.isEmpty(requiredCriterias?.requiredSkills)));
    if (setRequirementsMeet) {
      setRequirementsMeet(canProceed);
    }

    return (
      <div className='criteria-view'>
        <div
          className={`criteria-page ${mode === 'new-offer' ? 'new-offer' : ''}`}
          key={`${(offer || {}).id}_${version}`}
        >
          <PureCriteriaForm
            t={t}
            options={options}
            handleChange={this.handleChange}
            modifyingNotification={modifyingNotification}
            errorFields={errorFields}
            simplifiedMode={simplifiedMode}
            debounceTextFields={mode !== 'new-offer'}
            current={initialValues}
            saveButtons={mode !== 'new-offer'}
            requiredCriterias={requiredCriterias}
          />
        </div>
        <div className='side-content'>
          <div className='side-button-container'>
            <div className='side-button'>
              {mode === 'new-offer' ? (
                <GenericButton
                  className='launch-job-button'
                  size='big'
                  onClick={handleSubmit}
                  disabled={submitting || !canProceed}
                >
                  <Image src='/images/icons/figma/new-cross-white.svg' />
                  {t('newOffer.launchOffer')}
                </GenericButton>
              ) : (
                <SaveAndUnHoldButton
                  isHold={offer.isHold}
                  disabled={!canProceed}
                  offerId={offer.id}
                />
              )}
            </div>
            <div className='required-criterias'>
              <div className='required-criteria'>
                {!_.isEmpty(requiredCriterias?.jobPositions) ? (
                  <i className='ri-checkbox-circle-line' />
                ) : (
                  <i className='ri-close-circle-line' />
                )}
                <span
                  className={`${
                    !_.isEmpty(requiredCriterias?.jobPositions) ? 'valid' : ''
                  }`}
                >
                  {t('criteria.sideContent.conditions.jobPosition')}
                </span>
              </div>
              <div className='required-criteria'>
                {!_.isEmpty(requiredCriterias?.locationsAndRemote) ? (
                  <i className='ri-checkbox-circle-line' />
                ) : (
                  <i className='ri-close-circle-line' />
                )}
                <span
                  className={`${
                    !_.isEmpty(requiredCriterias?.locationsAndRemote)
                      ? 'valid'
                      : ''
                  }`}
                >
                  {t('criteria.sideContent.conditions.location')}
                </span>
              </div>
              {hasRequiredSkills && (
                <div className='required-criteria'>
                  {!_.isEmpty(requiredCriterias?.requiredSkills) ? (
                    <i className='ri-checkbox-circle-line' />
                  ) : (
                    <i className='ri-close-circle-line' />
                  )}
                  <span
                    className={`${
                      !_.isEmpty(requiredCriterias?.requiredSkills)
                        ? 'valid'
                        : ''
                    }`}
                  >
                    {t('criteria.sideContent.conditions.skills')}
                  </span>
                </div>
              )}
            </div>
          </div>
          <SidePanelInformations />
        </div>
      </div>
    );
  }
}

const SaveAndUnHoldButton = ({ isHold, disabled, offerId }) => {
  const clientId = useClientId();
  const { t } = useTranslation();
  const [unHoldOffer, { loading: unHoldingOffer }] = useUnHoldOffer();
  const [
    removePendingProfiles,
    { loading: removingProfiles },
  ] = useRemovePendingAndPrependingProfilesFromOffer();
  const history = useHistory();
  const { user } = useCurrentUser();

  const clickHandler = async () => {
    await removePendingProfiles(offerId);
    unHoldOffer(offerId);
    if (isHold) {
      logAction({
        type: 'reactivate-offer-sourcing',
        info: {
          clientId,
          jobOfferId: offerId,
          ...(user?.firstname &&
            user?.lastname && {
              author: getFullname(user),
            }),
        },
      }).catch((e) => sentryCaptureException({ error: e }));
      history.push(`/client/${clientId}/jobs/${offerId}/profiles/step_pending`);
    }
  };

  return (
    <GenericButton
      size='big'
      onClick={clickHandler}
      disabled={disabled || removingProfiles || unHoldingOffer}
    >
      {t(isHold ? 'criteria.saveAndUnHold' : 'criteria.save')}
    </GenericButton>
  );
};

const DefaultComponent = compose(
  routerParamsToProps,
  contextToProps,
  withTranslation('translations'),
  withOfferCriteria,
  withOfferCriteriaOptions,
  withUpdateOfferCriteria,
)(OfferCriteria);

export const NewOfferCriteria = compose(
  routerParamsToProps,
  contextToProps,
  withTranslation('translations'),
  withClientCriteriaOptions,
)(OfferCriteria);

export default DefaultComponent;
