import React, { useCallback, useMemo, useState } from 'react';
import _ from 'underscore';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';

import EnrichedRevealProfileModal from '@/revealComponents/EnrichedRevealProfileModal';
import useClientId from '@/hooks/router/useClientId';
import ProfileIdHandler from '@/common/ProfileIdHandler';
import ArchiveReasonsModal from '@/components/modals/ArchivedReasons/ArchiveReasonsModal';
import {
  ArchivedState,
  PipelineState,
  Segmentation,
  Segment,
} from '@/components/PipelineSegmentation/pipelineSegmentation';
import CandidateReplyModal from '@/components/modals/CandidateReplyModal/CandidateReplyModal';
import useUpdateProfilesPipelineStage from '@/graphql/hooks/searchPoolJob/useUpdateProfilesMissionPipelineStage';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';

import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import GenericSegmentationView from './GenericSegmentationView/GenericSegmentationView';
import { DragContextType } from './GenericSegmentationView/GenericSegmentationViewContext';
import { GenericSegmentationItem } from './GenericKanban/GenericKanbanColumn';
import KanbanProfileCard from './GenericKanban/GenericKanbanColumn/GenericKanbanCard/KanbanProfileCard/index';
import { SegmentDefinition } from './types';

import styles from './GenericSegmentationViewContainer.module.less';

interface MissionProfilesGenericViewProps {
  segmentation: Segmentation;
  jobId: string;
  disableDragAndDrop?: boolean;
  weekDiffInMS?: number;
  disableProfilesInteraction?: boolean;
}

export const SEGMENT_COLORS: Record<string, string> = {
  pending: '#FB5607',
  'in-progress': '#473198',
  replied: '#28AFB0',
  interested: '#F4D35E',
  hired: '#ED217C',
  archived: '#9A8F97',
};

const MissionProfilesGenericView: React.FC<MissionProfilesGenericViewProps> = ({
  segmentation,
  jobId,
  disableDragAndDrop = false,
  weekDiffInMS = 0,
  disableProfilesInteraction = false,
}) => {
  const [displayType] = useState<'kanban' | 'table'>('kanban');
  const [columnItems, setColumnItems] = useState<GenericSegmentationItem[]>([]);
  const [segmentItemIds, setSegmentItemIds] = useState<string[]>([]);
  const [segmentToMoveProfileTo, setSegmentToMoveProfileTo] = useState('');
  const [segmentToMoveProfileFrom, setSegmentToMoveProfileFrom] = useState('');
  const [showMoveToArchivedModal, setShowMoveToArchivedModal] = useState(false);
  const [showMoveToRepliedModal, setShowMoveToRepliedModal] = useState(false);
  const [reply, setReply] = useState('');
  const [archivedEngagement, setArchivedEngagement] = useState('');
  const [archivedReason, setArchivedReason] = useState('');
  const [clientArchiveReasonId, setClientArchiveReasonId] = useState('');
  const location = useLocation();
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const profileIdFromURL = queryParams.get('item');
  const [selectedItem, setSelectedItem] = useState<GenericSegmentationItem>({
    id: profileIdFromURL || '',
  });
  const notifications = useNotificationSystem();
  const history = useHistory();
  const { t } = useTranslation();
  const [updateProfilesPipelineStage] = useUpdateProfilesPipelineStage();
  const clientId = useClientId();
  const client = useApolloClient();

  const { profileCustomFields: customFields } =
    useClientProfileCustomFields(clientId);

  const updateCacheAfterMutation = useCallback(
    ({
      newData,
      prevSegment,
      newSegment,
    }: {
      newData: any;
      prevSegment: string;
      newSegment: string;
    }) => {
      if (prevSegment === newSegment) {
        return;
      }

      const { id } = newData.searchPoolJob?.updateProfilesPipelineStage?.[0];
      client.cache.modify({
        id: client.cache.identify({
          __typename: 'SearchPool',
          id: 'reveal',
        }),
        fields: {
          segmentProfileResults: (segmentProfileResults) => {
            const oldDataPrevSegment = _.findWhere(segmentProfileResults, {
              segmentId: prevSegment,
            });

            const profileRef = client.cache.identify({
              __typename: 'MiniProfileWithSegmentation',
              id,
              categoryId: jobId,
            });

            const profileToMove = _.find(
              oldDataPrevSegment.profiles,
              (profile) => profile.__ref === profileRef,
            );
            const newSegmentProfileResults = _.map(
              segmentProfileResults,
              (segment) => {
                if (segment.segmentId === prevSegment) {
                  return {
                    ...segment,
                    profiles: _.filter(
                      segment.profiles,
                      (profile) => profile.__ref !== profileRef,
                    ),
                    count: segment.count - 1,
                  };
                }

                if (segment.segmentId === newSegment) {
                  return {
                    ...segment,
                    profiles: [profileToMove, ...segment.profiles],
                    count: segment.count + 1,
                  };
                }

                return segment;
              },
            );
            return newSegmentProfileResults;
          },
        },
      });
    },
    [client.cache, jobId],
  );

  const updateProfiles = useCallback(
    async ({
      newSegment,
      items,
      labels,
      selectedClientArchiveReasonId,
      prevSegment,
    }: {
      newSegment: string;
      items: GenericSegmentationItem[];
      labels?: string[];
      selectedClientArchiveReasonId?: string;
      prevSegment: string;
    }) => {
      const fullNewSegment = _.findWhere(segmentation.segments, {
        id: newSegment,
      });
      try {
        const { data } = await updateProfilesPipelineStage({
          missionId: jobId,
          segmentationId: segmentation.id,
          profileIds: _.pluck(items || [], 'id'),
          stage: isInterestedSubstep(fullNewSegment)
            ? 'interested'
            : newSegment || '',
          interestedStepId: isInterestedSubstep(fullNewSegment)
            ? newSegment
            : undefined,
          labels,
          clientArchiveReasonId: selectedClientArchiveReasonId,
        });
        notifications.success(
          t('reveal.pipelineSegmentations.profilesStageUpdated'),
        );
        updateCacheAfterMutation({
          newData: data,
          prevSegment,
          newSegment,
        });
      } catch (e) {
        console.error('e :', e);
        notifications.error(t('Error occurred'));
        // Sentry.captureException(e);
      }
    },
    [
      updateCacheAfterMutation,
      updateProfilesPipelineStage,
      t,
      jobId,
      segmentation,
      notifications,
    ],
  );

  const profileIdHandler = ProfileIdHandler({
    profileIds: segmentItemIds,
    selectedProfileId: selectedItem.id,
    onSelectProfileId: (itemId: string) => {
      setSelectedItem({ id: itemId });
      const params = new URLSearchParams(location.search);
      params.set('item', itemId);
      const urlSearch = params.toString();
      history.replace({
        pathname: 'profiles',
        search: urlSearch,
      });
    },
  });

  const onChangeSegment = useCallback(
    ({ prevSegment, newSegment, items }: DragContextType) => {
      if (prevSegment === newSegment) {
        return;
      }
      setSegmentToMoveProfileTo(newSegment);
      setSegmentToMoveProfileFrom(prevSegment);
      setColumnItems(items);
      if (newSegment === 'archived') {
        setShowMoveToArchivedModal(true);
      } else if (newSegment === 'replied') {
        setShowMoveToRepliedModal(true);
      } else {
        updateProfiles({ newSegment, items, prevSegment });
      }
    },
    [updateProfiles],
  );

  const confirmMove = async ({ moveTo }: { moveTo?: string } = {}) => {
    let labels;

    // The callback can force a new target segment in order
    // to allow the user/modals to change the target if necessary
    // ex: moving to replied + positive => interested
    const newSegment = moveTo || segmentToMoveProfileTo;

    if (newSegment === PipelineState.REPLIED) {
      labels = [reply === 'neutral' ? 'medium' : reply];
    }
    if (newSegment === ArchivedState.ARCHIVED) {
      labels = [archivedEngagement, archivedReason];
    }
    updateProfiles({
      newSegment,
      items: columnItems,
      labels,
      selectedClientArchiveReasonId: clientArchiveReasonId,
      prevSegment: segmentToMoveProfileFrom,
    });
    setReply('');
    resetArchivedModalData();
  };

  const allowDrop = useCallback(
    ({ prevSegment, newSegment }: DragContextType) => {
      if (prevSegment === newSegment || disableDragAndDrop) {
        return false;
      }
      return true;
    },
    [disableDragAndDrop],
  );

  const segmentDefinitionWithItems: SegmentDefinition[] = useMemo(() => {
    return _.map(
      segmentation.segments,
      ({
        id,
        name,
        type,
        nextAvailabilityFilter,
        translationKey,
        subtype,
        isDisabled,
        emptyState,
      }) => ({
        segmentId: id,
        missionsFilter: {
          in: [jobId],
        },
        ...(type && { type }),
        ...(nextAvailabilityFilter && { nextAvailabilityFilter }),
        ...(name && { title: name }),
        ...(subtype && { subtype }),
        isDisabled,
        emptyState,
        translationKey,
        color: SEGMENT_COLORS[id],
      }),
    );
  }, [segmentation, jobId]);

  const resetArchivedModalData = () => {
    setArchivedEngagement('');
    setArchivedReason('');
    setClientArchiveReasonId('');
  };

  return (
    <div className={styles.genericSegmentationViewContainer}>
      <GenericSegmentationView
        displayType={displayType}
        selectedItem={selectedItem}
        allowDrop={allowDrop}
        onItemSelected={({ item, segmentItems }) => {
          setSelectedItem(item);
          setSegmentItemIds(segmentItems);
        }}
        segmentItemIds={segmentItemIds}
        onSegmentItemIds={(value) => {
          setSegmentItemIds(value);
        }}
        onChangeSegment={onChangeSegment}
        // allowDrop={allowDrop}
        segmentDefinitions={segmentDefinitionWithItems}
        disableDragAndDrop={disableDragAndDrop}
        renderItem={(item: GenericSegmentationItem, itemLoading: boolean) => {
          if (displayType === 'kanban') {
            return (
              <KanbanProfileCard
                profileId={item.id}
                itemLoading={itemLoading}
                customFields={customFields}
              />
            );
          }
          return null;
        }}
        dataLoading={false}
        weekDiffInMS={weekDiffInMS}
        disableProfilesInteraction={disableProfilesInteraction}
      />
      <EnrichedRevealProfileModal
        clientId={clientId}
        searches={undefined}
        open={!_.isEmpty(selectedItem?.id)}
        onClose={() => {
          const params = new URLSearchParams(location.search);
          params.delete('item');
          const searchUrl = params.toString();
          history.replace({
            pathname: 'profiles',
            search: searchUrl,
          });
          setSelectedItem({ id: '' });
        }}
        profileIdHandler={profileIdHandler}
        onChangeStage={updateCacheAfterMutation}
      />
      {showMoveToArchivedModal && (
        <ArchiveReasonsModal
          confirm={confirmMove}
          archivedEngagement={archivedEngagement}
          setArchivedEngagement={setArchivedEngagement}
          archivedReason={archivedReason}
          setArchivedReason={setArchivedReason}
          clientArchiveReasonId={clientArchiveReasonId}
          setClientArchiveReasonId={setClientArchiveReasonId}
          clientId={clientId}
          profiles={columnItems}
          onCancel={resetArchivedModalData}
          onClose={() => setShowMoveToArchivedModal(false)}
        />
      )}
      {showMoveToRepliedModal && (
        <CandidateReplyModal
          confirm={confirmMove}
          reply={reply}
          onReply={setReply}
          onForceStage={(stage) => {
            confirmMove({
              moveTo: stage,
            });
          }}
          candidatesLength={columnItems?.length}
          onCancel={() => setReply('')}
          onClose={() => setShowMoveToRepliedModal(false)}
        />
      )}
    </div>
  );
};

function isInterestedSubstep(segment: Segment | undefined): boolean {
  if (segment === undefined) {
    return false;
  }
  if (segment.type === 'hs-pipeline-interested-substep') {
    return true;
  }
  if (segment.type === 'hs-pipeline-interested-default-substep') {
    return true;
  }
  return false;
}

export default MissionProfilesGenericView;
