import React from 'react';
import i18n from 'i18next';

export default (WrappedComponent) => ({ client, user, ...rest }) => {
  if (client) {
    const { resolvedLanguage } = i18n;
    const languageWithoutCountry = resolvedLanguage
      ? resolvedLanguage.split('-')[0]
      : null;
    const clientLanguage = client && client.language ? client.language : null;
    const languageSettings =
      user && user.language ? user.language : clientLanguage;
    if (
      client &&
      languageSettings &&
      languageSettings !== languageWithoutCountry
    ) {
      i18n.changeLanguage(languageSettings);
    }
  }
  return <WrappedComponent client={client} user={user} {...rest} />;
};
