import React from 'react';
import MetaTaskForm from '@/revealComponents/ExplicitTasks/MetaTaskForm';
import { useTranslation } from 'react-i18next';
import { getTranslatedText } from '@/common';
import { CustomIcon } from '@/components/Common/GenericIconSelector/IconDropdown/IconDropdown';
import { ClientCustomAction } from '@/types/customAction';
import GenericButton from '@/components/Common/GenericButton';
import { ClientCustomActivity } from '@/graphql/hooks/clients/useClientCustomActivities';
import { CustomIconDescriptor } from '@/components/Common/GenericIconSelector/types';
import styles from './CustomTaskPreviewContainer.module.less';

interface CustomTaskPreviewContainerProps {
  task: ClientCustomAction | ClientCustomActivity;
  type: 'tasks' | 'activities';
  withPreviewTitle?: boolean;
}

const CustomTaskPreviewContainer: React.FC<CustomTaskPreviewContainerProps> = ({
  task,
  type,
  withPreviewTitle = true,
}) => {
  const { t } = useTranslation();

  const translationKey =
    type === 'tasks' ? 'customTasksSettings' : 'customActivitySettings';

  return (
    <div className={styles.customTaskPreviewContainer}>
      {withPreviewTitle && (
        <h3 className={styles.previewTitle}>
          {t('reveal.modals.metaTaskModal.preview')}
        </h3>
      )}
      <div className={styles.customTaskPreview}>
        <div className={styles.customTaskPreviewHeader}>
          <CustomIcon icon={task.icon as CustomIconDescriptor} />
          <h5>
            {getTranslatedText(task.title) ||
              t(`reveal.parameters.${translationKey}.customTask`)}
          </h5>
        </div>

        {task?.format && <MetaTaskForm format={task?.format} />}
        {type === 'tasks' &&
          getTranslatedText(
            (task as ClientCustomAction)?.completionButtonDisplay?.title,
          ) && (
            <div className='mark-completed-button'>
              <GenericButton className={styles.fakeCompletionButton}>
                <i className='ri-check-line' />
                {getTranslatedText(
                  (task as ClientCustomAction)?.completionButtonDisplay?.title,
                )}
              </GenericButton>
            </div>
          )}
      </div>
    </div>
  );
};

export default CustomTaskPreviewContainer;
