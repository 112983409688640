import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import JobPosting from '../../graphql/fragments/JobPosting';

export const updateJobPostingApplicationForm = gql`
  mutation updateJobPostingApplicationForm(
    $input: UpdateJobPostingApplicationFormInput!
  ) {
    updateJobPostingApplicationForm(input: $input) {
      jobPosting {
        ...JobPosting
      }
    }
  }
  ${JobPosting}
`;

export default graphql(updateJobPostingApplicationForm, {
  props: ({ mutate }) => ({
    updateJobPostingApplicationForm: ({ input }) =>
      mutate({ variables: { input } }),
  }),
});
