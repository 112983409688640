import React from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { TableHeader } from '@/containers/Parameters/Campaigns/CampaignsList';
import styles from './ConnectPagesTable.module.less';

interface ConnectPagesTableHeaderProps {
  withEdit: boolean;
  sortKey: string;
  sortOrder: number;
  handleToggleSort: ({ key }: { key: string }) => void;
}

const ConnectPagesTableHeader: React.FC<ConnectPagesTableHeaderProps> = ({
  withEdit,
  sortKey,
  sortOrder,
  handleToggleSort,
}) => {
  const { t } = useTranslation();
  return (
    <Table.Header className={styles.connectPagesTableHeaders}>
      <Table.Row>
        <Table.HeaderCell>
          <TableHeader
            rowKey='title'
            name={t(
              'settings.connectSettings.connectPages.table.header.internalTitle',
            )}
            sortKey={sortKey}
            sortOrder={sortOrder}
            handleToggleSort={handleToggleSort}
          />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <TableHeader
            rowKey='author'
            name={t(
              'settings.connectSettings.connectPages.table.header.author',
            )}
            sortKey={sortKey}
            sortOrder={sortOrder}
            handleToggleSort={handleToggleSort}
          />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <TableHeader
            rowKey='title'
            name={t('settings.connectSettings.connectPages.table.header.title')}
            sortKey={sortKey}
            sortOrder={sortOrder}
            handleToggleSort={handleToggleSort}
          />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <TableHeader
            rowKey='form'
            name={t('settings.connectSettings.connectPages.table.header.form')}
            sortKey={sortKey}
            sortOrder={sortOrder}
            handleToggleSort={handleToggleSort}
          />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <TableHeader
            rowKey='published'
            name={t(
              'settings.connectSettings.connectPages.table.header.published',
            )}
            sortKey={sortKey}
            sortOrder={sortOrder}
            handleToggleSort={handleToggleSort}
          />
        </Table.HeaderCell>
        {withEdit && <Table.HeaderCell />}
      </Table.Row>
    </Table.Header>
  );
};

export default ConnectPagesTableHeader;
