import gql from 'graphql-tag';
import PipeStepStat from '@/graphql/fragments/PipeStepStat';
import {
  MutationHookOptions,
  FetchResult,
  MutationResult,
  useMutation,
} from '@apollo/client';

const removePendingAndPrependingProfilesFromOffer = gql`
  mutation removePendingAndPrependingProfilesFromOffer($offerId: ID!) {
    removePendingAndPrependingProfilesFromOffer(id: $offerId) {
      id
      pipeStepStats {
        ...PipeStepStat
      }
      profiles(search: "", step: "") {
        id
        lastStepUpdateTimestamp
      }
    }
  }
  ${PipeStepStat}
`;

type Data = {
  removePendingAndPrependingProfilesFromOffer: {
    id: string;
    pipeStepStats: [];
    profiles: [];
  };
};

type Variables = {
  offerId: string;
};

type Input = {
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

type Result = [
  (offerId: string) => Promise<FetchResult<Data>>,
  MutationResult<Data>,
];

function useRemovePendingAndPrependingProfilesFromOffer({
  mutationOptions = {},
}: Input = {}): Result {
  const [mutation, result] = useMutation<Data, Variables>(
    removePendingAndPrependingProfilesFromOffer,
    mutationOptions,
  );

  return [(offerId) => mutation({ variables: { offerId } }), result];
}

export default useRemovePendingAndPrependingProfilesFromOffer;
