import _ from 'underscore';
import React, { ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DAY_IN_MS, dateFromDayAndWeekDiffFromStartDate } from '@/common/dates';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

import { SegmentDefinition } from '../types';
import GenericKanbanColumn, {
  GenericSegmentationItem,
} from '../GenericKanban/GenericKanbanColumn';
import useDataLoaderSegmentProfiles from '../useDataLoaderSegmentProfiles';
import { GenericSegmentationViewContext } from '../GenericSegmentationView/GenericSegmentationViewContext';

import styles from './GenericSegmentView.module.less';

interface GenericSegmentViewProps {
  type: string;
  segmentDefinition: SegmentDefinition;
  renderItem: (
    item: GenericSegmentationItem,
    itemLoading: boolean,
  ) => ReactNode;
  dataLoading: boolean;
}

const GenericSegmentView: React.FC<GenericSegmentViewProps> = ({
  type,
  segmentDefinition,
  renderItem,
  dataLoading,
}) => {
  const { t } = useTranslation();

  const { data, dataCount, fetch } =
    useDataLoaderSegmentProfiles(segmentDefinition);

  const configurationParams = useMergedConfigurationParams();
  const { weekDiffInMS } = useContext(GenericSegmentationViewContext);
  const day =
    segmentDefinition?.subtype === 'date'
      ? segmentDefinition?.translationKey?.split('+')?.[1]
      : null;
  const firstDay = parseInt(
    (configurationParams?.weeklyKanbanStartDay as string) || '1',
    10,
  );
  const startDate = new Date();
  const diffDay = startDate.getDay() - firstDay;
  const startTimestamp = startDate.getTime() - diffDay * DAY_IN_MS;
  const date = day
    ? dateFromDayAndWeekDiffFromStartDate(
        startTimestamp,
        +day,
        weekDiffInMS || 0,
      )
    : null;

  if (type === 'kanban') {
    return (
      <div className={styles.genericKanbanColumnContainer}>
        <GenericKanbanColumn
          itemCount={
            data?.profiles?.length > dataCount?.count
              ? data?.profiles?.length
              : dataCount?.count || 0
          }
          segment={
            date !== null
              ? t('common.weekDayMonth', { date })
              : segmentDefinition?.title
                ? segmentDefinition.title
                : t(
                    `reveal.pipelineSegmentations.availabilityPipeline.${segmentDefinition?.translationKey}`,
                  )
          }
          items={data?.profiles || []}
          color={segmentDefinition.color}
          dataLoading={dataLoading}
          segmentId={segmentDefinition.segmentId}
          renderItem={renderItem}
          onAskMoreItems={(offset) => {
            fetch(segmentDefinition, { offset });
          }}
          disabled={segmentDefinition.isDisabled}
          emptyState={segmentDefinition.emptyState}
          canDrop={segmentDefinition.canDrop}
        />
      </div>
    );
  }

  return (
    <table>
      <tbody>
        {_.map(segmentDefinition?.items || [], (item) => (
          <tr key={`${item.id}-${item.categoryId}`}>
            <td>{item.id}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default GenericSegmentView;
