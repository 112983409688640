import React from 'react';
import classnames from 'classnames';

const Separator = ({ children, className, style }) => (
  <span className={classnames('signin-separator', className)} style={style}>
    <span>{children}</span>
  </span>
);

export default Separator;
