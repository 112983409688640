import _, { compose } from 'underscore';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal, Grid, Form, Checkbox } from 'semantic-ui-react';

import * as Sentry from '@sentry/browser';

import { withRouter } from 'react-router-dom';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import withProfileResume from '../../../../hocs/profiles/withProfileResume';
import withDisqualifyProfile from '../../../../hocs/profiles/withDisqualifyProfile';
import withActionLogger from '../../../../hocs/withActionLogger';
import { withLastProfileActionsContextConsumer } from '../../../../context/LastProfileActionsContext';

import '../newSkipModal/skipModal.css';

export const disqualifyReasons = {
  'customer-refusal': [
    'technical-level',
    'human-fit',
    'remuneration-expectations',
    'availability',
  ],
  'profile-refusal': [
    'currently-not-looking',
    'not-interested',
    'inappropriate-technical-stack',
    'remuneration-too-low',
  ],
  'profile-refusal-2': [
    'responsibilities-too-low',
    'responsibilities-too-high',
    'location-mismatch',
    'contract',
  ],
};

class DisqualifyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: null,
      reminderDuration: '6months',
    };
  }

  close = () => {
    this.props.onClose();
  };

  handleChange = (event, { name, value, checked }) => {
    this.setState({
      [name]: {
        value,
        checked,
      },
    });
  };

  // handleChangeReminderDuration = (e, { value }) => {
  //   this.setState({
  //     'schedule-reminder': { checked: true },
  //     reminderDuration: value,
  //   });
  // };

  getReasonText = () => {
    const { state } = this;
    const reasons = _.flatten(_.values(disqualifyReasons));
    const suffix =
      ((state['schedule-reminder'] || {}).checked
        ? `!schedule-reminder-${this.state.reminderDuration}`
        : '') + (state.reason ? `#${state.reason.value}` : '');
    const result =
      _.reduce(
        reasons,
        (memo, item) =>
          memo +
          (state[item] && state[item].checked
            ? (memo.length > 0 ? ';' : '') + item
            : ''),
        '',
      ) + suffix;
    return result;
  };

  renderCheckbox(reasonId, side) {
    const { t } = this.props;
    const name =
      (t('profile.steps.newDisqualify.reasons') || {})[reasonId] || reasonId;
    const checked = !!(this.state[reasonId] || {}).checked;
    return (
      <div
        className={`checkbox-container ${side}${checked ? ' selected' : ''}`}
      >
        <div className={`checkbox-subcontainer${checked ? ' selected' : ''}`}>
          <Form.Field key={reasonId}>
            <Checkbox
              name={reasonId}
              label={name}
              checked={checked}
              onChange={this.handleChange}
            />
          </Form.Field>
        </div>
      </div>
    );
  }

  renderCheckboxGrid(reasonIds) {
    const nbRows = Math.ceil(reasonIds.length / 2);
    return (
      <Grid style={{ padding: 0, margin: 0 }}>
        {_.map(_.range(nbRows), (iRow) => (
          <Grid.Row key={iRow} columns={2} style={{ padding: 0, margin: 0 }}>
            <Grid.Column style={{ padding: 0, margin: 0 }}>
              {this.renderCheckbox(reasonIds[2 * iRow], 'left')}
            </Grid.Column>
            <Grid.Column style={{ padding: 0, margin: 0 }}>
              {2 * iRow + 1 < reasonIds.length &&
                this.renderCheckbox(reasonIds[2 * iRow + 1], 'right')}
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
    );
  }

  render() {
    if (!this.props.open) {
      return null;
    }
    const onSubmit = async () => {
      const text = this.getReasonText();
      const {
        profileId,
        offerId,
        oldStep,
        disqualifyProfile,
        stepId,
        // optional props
        getNextProfileId,
        getProfilesInMove,
        onChangeProfile,
        onChangeStep,
        onSkip,
        handleMarkProfileAsInMove,
        handleUnmarkProfileAsInMove,
      } = this.props;

      // TODO: pass one handler instead of these optional props and logic
      if (handleMarkProfileAsInMove) {
        handleMarkProfileAsInMove({ profileId });
      }

      let nextProfileId;
      if (getNextProfileId && getProfilesInMove) {
        nextProfileId = getNextProfileId({
          forbiddenProfileIds: getProfilesInMove(),
        });
      }

      if (nextProfileId && onChangeProfile) {
        await onChangeProfile(nextProfileId);
      }

      if (!nextProfileId && onChangeStep) {
        const forbiddenProfileId = profileId;
        await onChangeStep({
          newStepId: stepId,
          forceRefresh: true,
          forbiddenProfileId,
          originalProfileId: profileId,
        });
      }

      new Promise((resolve) => {
        setTimeout(resolve, 100);
      })
        .then(() => {
          try {
            this.props.addProfileAction({
              type: 'disqualify-profile',
              jobOfferId: offerId,
              profileId,
            });
          } catch (e) {
            console.error(e);
          }

          return disqualifyProfile({
            id: profileId,
            disqualifyReason: text,
            oldStep,
            offerId,
          });
        })
        .then(() => {
          if (handleUnmarkProfileAsInMove) {
            handleUnmarkProfileAsInMove({ profileId });
          }
          if (onSkip) {
            onSkip({ profileId, jobOfferId: offerId });
          }
          this.close();
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    };
    const { t, profile, open } = this.props;
    const reasonValue = this.state.reason ? this.state.reason.value : '';
    const firstname = ((profile || {}).resumeData || {}).firstname || '???';

    const header = (t('profile.steps.newDisqualify.header') || '')
      .replace('firstname', firstname)
      .replace("s's", "s'");
    const subHeader = t('profile.steps.newDisqualify.subheader');

    return (
      <div>
        <GenericModal
          className='new-skip-profile-modal'
          open={open}
          onClose={this.close}
        >
          <Modal.Header>
            <div>{header}</div>
            <div className='subheader description'>{subHeader}</div>
          </Modal.Header>
          <Modal.Content>
            <Form className='skip-profile-form'>
              <div className='inputs-row'>
                <div className='input-container'>
                  <div className='input-label'>
                    <i className='ri-emotion-normal-line  basic-icon' />
                    <span>
                      {t('profile.steps.newDisqualify.customerRefusalHeader')}
                    </span>
                  </div>
                  {_.map(disqualifyReasons['customer-refusal'], (reasonId) => (
                    <ReasonCheckbox
                      reasonId={reasonId}
                      t={t}
                      checked={!!(this.state[reasonId] || {}).checked}
                      handleChange={this.handleChange}
                    />
                  ))}
                </div>
                <div className='input-container'>
                  <div className='input-label'>
                    <i className='ri-emotion-sad-line basic-icon' />
                    <span>
                      {t('profile.steps.newDisqualify.profileRefusalHeader')}
                    </span>
                  </div>
                  {_.map(disqualifyReasons['profile-refusal'], (reasonId) => (
                    <ReasonCheckbox
                      reasonId={reasonId}
                      t={t}
                      checked={!!(this.state[reasonId] || {}).checked}
                      handleChange={this.handleChange}
                    />
                  ))}
                </div>
                <div className='input-container'>
                  <div className='input-label'>&nbsp;</div> {/* Spacer */}
                  {_.map(disqualifyReasons['profile-refusal-2'], (reasonId) => (
                    <ReasonCheckbox
                      reasonId={reasonId}
                      t={t}
                      checked={!!(this.state[reasonId] || {}).checked}
                      handleChange={this.handleChange}
                    />
                  ))}
                </div>
              </div>
              <div className='input-container'>
                <div className='input-label'>
                  <i className='ri-message-3-line basic-icon' />
                  <span>
                    {t('profile.steps.newDisqualify.furtherReasonsHeader')}
                  </span>
                </div>
                <Form.Field>
                  <div style={{ textAlign: 'center' }}>
                    <Form.Input
                      className='profile-skip-comment'
                      name='reason'
                      rows={2}
                      value={reasonValue}
                      onChange={this.handleChange}
                      placeholder={t('profile.steps.newDisqualify.tellUsWhy')}
                    />
                  </div>
                </Form.Field>
              </div>
              <div className='input-container'>
                {/* <div className='input-label'> */}
                {/* <i className='ri-alarm-line basic-icon' /> */}
                {/* <span>{t('profile.steps.newDisqualify.reminder.scheduleReminder')}</span> */}
                {/* </div> */}
                {/* <div className={`schedule-reminder ${(state['schedule-reminder'] || {}).checked ? 'selected' : ''}`}> */}
                {/* <Form.Field> */}
                {/* <Checkbox */}
                {/* name='schedule-reminder' */}
                {/* checked={(state['schedule-reminder'] || {}).checked} */}
                {/* label={t('profile.steps.newDisqualify.reminder.reproposeProfile')} */}
                {/* onChange={this.handleChange} */}
                {/* /> */}
                {/* <Dropdown */}
                {/* className='reminder-duration-select' */}
                {/* inline */}
                {/* upward */}
                {/* direction='left' */}
                {/* options={[ */}
                {/* { */}
                {/* key: '3months', */}
                {/* value: '3months', */}
                {/* text: t('profile.steps.newDisqualify.reminder.3months'), */}
                {/* }, */}
                {/* { */}
                {/* key: '6months', */}
                {/* value: '6months', */}
                {/* text: t('profile.steps.newDisqualify.reminder.6months'), */}
                {/* }, */}
                {/* { */}
                {/* key: '12months', */}
                {/* value: '12months', */}
                {/* text: t('profile.steps.newDisqualify.reminder.12months'), */}
                {/* }, */}
                {/* ]} */}
                {/* value={this.state.reminderDuration} */}
                {/* onChange={this.handleChangeReminderDuration} */}
                {/* /> */}
                {/* </Form.Field> */}
                {/* </div> */}
              </div>
            </Form>
          </Modal.Content>
          <Modal.Actions className='modal-actions-buttons'>
            <div className='flex-spaced-between'>
              <GenericButton
                primacy='secondary'
                size='big'
                type='submit'
                onClick={() => this.close()}
              >
                {t('profile.steps.newDisqualify.cancel')}
              </GenericButton>
              <GenericButton
                color='red'
                size='big'
                type='submit'
                onClick={() => onSubmit()}
              >
                {t('profile.steps.newDisqualify.submit')}
              </GenericButton>
            </div>
          </Modal.Actions>
        </GenericModal>
      </div>
    );
  }
}

const ReasonCheckbox = ({ t, reasonId, checked, handleChange }) => {
  const name =
    (t('profile.steps.newDisqualify.reasons') || {})[reasonId] || reasonId;
  return (
    <Form.Field key={reasonId}>
      <Checkbox
        name={reasonId}
        label={name}
        checked={checked}
        onChange={handleChange}
      />
    </Form.Field>
  );
};

export default compose(
  withProfileResume,
  withDisqualifyProfile,
  withActionLogger,
  withRouter,
  withLastProfileActionsContextConsumer,
  withTranslation('translations'),
)(DisqualifyModal);
