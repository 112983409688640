import _, { compose } from 'underscore';
import React from 'react';
import { Icon, Dropdown, Image } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getSecureLink, getRandomDefaultAvatarLink } from '../../../common';
import withClient from '../../../hocs/clients/withClient';
import withNotifications from '../../../hocs/clients/withNotifications';
import withActionLogger from '../../../hocs/withActionLogger';

import './Notifications.css';

const NotificationCard = ({ notification }) => {
  const seenClass = notification.seen ? 'seen' : 'not-seen';
  const { photoLink, firstname, lastname } = notification.profile?.resumeData;

  return (
    <div className={`notification-card ${seenClass}`}>
      <div className='notification-bubble'>
        <Image
          circular
          src={getSecureLink(photoLink)}
          onError={(e) => {
            e.currentTarget.src = getRandomDefaultAvatarLink(
              firstname + lastname,
            );
          }}
        />
      </div>
      <div className='content'>
        <div className='name'>
          <span>{`${firstname} ${lastname}`}</span>
          {(notification.offer.title || '')
            .toLowerCase()
            .indexOf('unclassified') < 0 ? (
            <Icon name='caret right' />
          ) : (
            ''
          )}
          {(notification.offer.title || '')
            .toLowerCase()
            .indexOf('unclassified') < 0 ? (
            <span>{notification.offer.title}</span>
          ) : (
            ''
          )}
        </div>
        <div className='description'>
          {notification.date &&
            parseInt(notification.date, 10) &&
            moment(parseInt(notification.date, 10)).fromNow()}
        </div>
      </div>
    </div>
  );
};

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shown: 5,
    };
  }

  clickNotification = (notification) => {
    const { clientId, history } = this.props;
    const offerId = notification.offer.id;
    const profileId = notification.profile.id;
    history.push(`/client/${clientId}/jobs/${offerId}/profiles/${profileId}`);

    this.props.onLogAction({
      type: 'click-notification',
      profileId,
      info: { offerId, notificationType: notification.type },
    });
  };

  showMore = (e) => {
    e.stopPropagation();
    this.setState(({ shown }) => ({ shown: shown + 5 }));
  };

  renderNotificationList(notifications) {
    return (
      <TransitionGroup className='notifications-dropdown-container'>
        {_.map(notifications, (notification) => (
          <CSSTransition
            key={`${notification.offer.id}-${notification.profile.id}`}
            classNames='animate'
            timeout={250}
          >
            <Dropdown.Item onClick={() => this.clickNotification(notification)}>
              <NotificationCard notification={notification} />
            </Dropdown.Item>
          </CSSTransition>
        ))}
      </TransitionGroup>
    );
  }

  renderNotificationsGroup(notifications, count, numberToDisplay, kind) {
    if (!numberToDisplay) {
      return null;
    }
    const { t } = this.props;
    const { icon, name } = {
      seen: { name: t('notifications.headerRead', { count }), icon: 'wait' },
      notSeen: { name: t('notifications.headerNew', { count }), icon: 'alarm' },
    }[kind];
    return (
      <div className='menu-group'>
        <Dropdown.Header
          content={`${name} (${count})`}
          icon={icon}
          size='huge'
        />
        {this.renderNotificationList(notifications.slice(0, numberToDisplay))}
      </div>
    );
  }

  render() {
    const { notifications, t } = this.props;
    const { countSeen, countNotSeen, seen, notSeen } = notifications;
    const nbNotifications = countSeen + countNotSeen;

    const { shown } = this.state;
    const numberNotSeenShown = Math.min(countNotSeen, shown);
    const numberSeenShown = Math.min(shown - numberNotSeenShown, countSeen);
    const allNotificationsAreDisplayed = shown >= nbNotifications;
    // <Icon name="mail outline" inverted size="big" />
    return (
      <Dropdown
        direction='left'
        className='notifications-dropdown'
        closeOnBlur
        closeOnChange={false}
        scrolling
        icon={null}
        trigger={
          <div className='notifications-dropdown-trigger'>
            <i className='ri-notification-4-line' />
            {countNotSeen > 0 && (
              <div className='notifications-count-bubble'>
                {countNotSeen < 10 ? countNotSeen : '9+'}
              </div>
            )}
          </div>
        }
      >
        {nbNotifications > 0 ? (
          <Dropdown.Menu style={{ marginTop: 10 }}>
            {this.renderNotificationsGroup(
              notSeen,
              countNotSeen,
              numberNotSeenShown,
              'notSeen',
            )}
            {this.renderNotificationsGroup(
              seen,
              countSeen,
              numberSeenShown,
              'seen',
            )}
            {!allNotificationsAreDisplayed ? (
              <div className='menu-group'>
                <Dropdown.Item
                  className='show-more'
                  onClick={(e) => this.showMore(e)}
                >
                  Show more
                </Dropdown.Item>
              </div>
            ) : null}
          </Dropdown.Menu>
        ) : (
          <Dropdown.Menu style={{ marginTop: 10 }}>
            <div className='notifications-dropdown-container no-items-container'>
              {/* <img alt='notifications check' src='/images/icons/custom/illustration-check.svg'/> */}
              <img
                src='/images/placeholders/notifications.svg'
                height='155'
                alt='notifications-placeholder'
              />
              <div className='no-items'>{t('notifications.empty')}</div>
            </div>
          </Dropdown.Menu>
        )}
      </Dropdown>
    );
  }
}

export default compose(
  withRouter,
  withClient,
  withNotifications,
  withActionLogger,
  withTranslation('translations'),
)(Notifications);
