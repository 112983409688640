import React from 'react';

import { TimeSpan } from '../components/RevealAnalyticsHeader/RevealAnalyticsHeader';
import ReasonCategories from '../tabs/MissionsTab/ReasonCategories';
import ContactsStatistics from './ContactsStatistics';
import PipelineActionsStatistics from './PipelineActionsStatistics';
import PipelineStatisticsByProject from './PipelineStatisticsByProject';

interface RevealAnalyticsMissionsProps {
  timeSpan: TimeSpan;
  missionIds: string[];
}

const RevealAnalyticsMissions: React.FC<RevealAnalyticsMissionsProps> = ({
  timeSpan,
  missionIds,
}) => {
  return (
    <div>
      <PipelineStatisticsByProject
        missionIds={missionIds}
        timeSpan={timeSpan}
      />
      <PipelineActionsStatistics timeSpan={timeSpan} missionIds={missionIds} />
      <ReasonCategories missionIds={missionIds} timeSpan={timeSpan} />
      <ContactsStatistics />
    </div>
  );
};

export default RevealAnalyticsMissions;
