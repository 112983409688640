import React from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import ContextMenu from '@/components/Tables/ContextMenu/ContextMenu';
import { ConnectPage } from '@/types/connectPage';
import ExternalLink from '@/components/Reveal/Icons/ExternalLink';
import styles from './ConnectPagesTable.module.less';

interface ConnectPagesTableRowProps {
  connectPage: ConnectPage;
  withEdit: boolean;
  onEditConnectPage: ({ connectPageId }: { connectPageId: string }) => void;
  onDeleteConnectPage: ({ connectPageId }: { connectPageId: string }) => void;
  onShowQRCode: ({ connectPageId }: { connectPageId: string }) => void;
}

export const ConnectPagesTableRow: React.FC<ConnectPagesTableRowProps> = ({
  connectPage,
  withEdit,
  onEditConnectPage,
  onDeleteConnectPage,
  onShowQRCode,
}) => {
  const { t } = useTranslation();
  const { id, title, author, connectForm, internalTitle, published } =
    connectPage || {};

  const onEdit = () => {
    onEditConnectPage({ connectPageId: id });
  };

  const onDelete = () => {
    onDeleteConnectPage({ connectPageId: id });
  };

  const handleShowQRCode = () => {
    onShowQRCode({ connectPageId: id });
  };

  return (
    <Table.Row className={styles.tableRow}>
      <Table.Cell className={styles.tableCell}>{internalTitle}</Table.Cell>
      <Table.Cell className={styles.tableCell}>{`${author.firstname ||
        ''} ${author.lastname || ''}`}</Table.Cell>
      <Table.Cell className={styles.tableCell}>{title?.default}</Table.Cell>
      <Table.Cell className={styles.tableCell}>
        {connectForm?.title?.default}
      </Table.Cell>
      <Table.Cell className={styles.tableCell}>
        {published ? t('common.yes') : t('common.no')}
      </Table.Cell>
      {withEdit && (
        <Table.Cell className={styles.actionTableCell} collapsing>
          <ContextMenu
            actions={[
              {
                icon: 'ri-edit-line',
                label: t('settings.senders.editButton'),
                onClick: () => onEdit(),
              },
              {
                icon: 'ri-delete-bin-line',
                label: t('settings.senders.deleteButton'),
                onClick: () => onDelete(),
              },
            ]}
          />
          <div
            className={styles.showQRCodeButton}
            onClick={handleShowQRCode}
            onKeyPress={handleShowQRCode}
            role='button'
          >
            <i className='ri-qr-code-line ri-lg' />
          </div>
          <div>
            <a
              href={connectPage.url}
              className={styles.externalLink}
              target='_blank'
              rel='noreferrer noopener'
            >
              <ExternalLink />
            </a>
          </div>
        </Table.Cell>
      )}
    </Table.Row>
  );
};

export default ConnectPagesTableRow;
