import React, { FC } from 'react';

import styles from './svg.module.less';

const Company: FC = () => {
  return (
    <svg
      className={styles.svgIcon}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 2C0.895431 2 0 2.89543 0 4V12C0 13.1046 0.89543 14 2 14H6H10H14C15.1046 14 16 13.1046 16 12V7C16 5.89543 15.1046 5 14 5H10H8V4C8 2.89543 7.10457 2 6 2H2ZM6 5V4.5C6 4.22386 5.77614 4 5.5 4H2.5C2.22386 4 2 4.22386 2 4.5V5.5C2 5.77614 2.22386 6 2.5 6H5.5C5.77614 6 6 5.77614 6 5.5V5ZM6 7.5C6 7.22386 5.77614 7 5.5 7H2.5C2.22386 7 2 7.22386 2 7.5V8.5C2 8.77614 2.22386 9 2.5 9H5.5C5.77614 9 6 8.77614 6 8.5V7.5ZM6 10.5C6 10.2239 5.77614 10 5.5 10H2.5C2.22386 10 2 10.2239 2 10.5V11.5C2 11.7761 2.22386 12 2.5 12H5.5C5.77614 12 6 11.7761 6 11.5V10.5ZM9 7.5V8.5C9 8.77614 9.22386 9 9.5 9H13.5C13.7761 9 14 8.77614 14 8.5V7.5C14 7.22386 13.7761 7 13.5 7H9.5C9.22386 7 9 7.22386 9 7.5ZM9 10.5V11.5C9 11.7761 9.22386 12 9.5 12H13.5C13.7761 12 14 11.7761 14 11.5V10.5C14 10.2239 13.7761 10 13.5 10H9.5C9.22386 10 9 10.2239 9 10.5Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Company;
