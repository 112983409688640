import _ from 'underscore';
import { OPERATORS_OPTION } from './operatorsOptions';
import {
  ContactOperator,
  DiversityOperator,
  KeywordsOperator,
  PropertyFilter,
} from './types';
import {
  HAS_ANY_KEYWORDS,
  HAS_KEYWORDS,
  HAS_NONE_OF_KEYWORDS,
} from './constants';

export const propertyFilterHasError = (filter: PropertyFilter) => {
  const { type, operator, value } = filter;
  if (
    (type === 'contact' || type === 'diversity' || type === 'keywords') &&
    Array.isArray(operator) &&
    typeof value === 'object' &&
    !Array.isArray(value)
  ) {
    return !_.some(
      operator,
      (key: ContactOperator | DiversityOperator | KeywordsOperator) => {
        if (type === 'contact') {
          if (
            key !== 'all-filled' &&
            key !== 'all-missing' &&
            key !== 'any-missing' &&
            key !== 'any-filled'
          ) {
            throw new Error('Not suitable operator for contact filter');
          }
          return (
            ((value as Partial<Record<ContactOperator, string[]>>)?.[key]
              ?.length ?? 0) > 0
          );
        }

        if (type === 'diversity') {
          if (key !== 'firstname_is_likely') {
            throw new Error('Not suitable operator for contact filter');
          }
          return (
            ((value as Partial<Record<DiversityOperator, string[]>>)?.[key]
              ?.length ?? 0) > 0
          );
        }

        if (
          key !== HAS_ANY_KEYWORDS &&
          key !== HAS_KEYWORDS &&
          key !== HAS_NONE_OF_KEYWORDS
        ) {
          throw new Error('Not suitable operator for keywords filter');
        }
        return (
          ((value as Partial<Record<KeywordsOperator, string[]>>)?.[key]
            ?.length ?? 0) > 0
        );
      },
    );
  }

  const operatorsOptions: {
    operator: string;
    withInput?: boolean;
  }[] = OPERATORS_OPTION[type];
  const operatorOption = operatorsOptions?.find(
    (op) => op.operator === operator,
  );

  if (!operatorOption) return true;
  if (operatorOption.withInput === false) return false;
  if (!value) return true;
  if ((type === 'float' || type === 'integer') && typeof value === 'string') {
    return Number.isNaN(Number.parseFloat(value));
  }
  if (type === 'inline-text' || type === 'text') return value === '';
  if (type === 'day' && typeof value === 'string') {
    return Number.isNaN(Date.parse(value));
  }
  if (type === 'enum' && Array.isArray(value)) return value.length === 0;

  return false;
};

export default propertyFilterHasError;
