import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import CareerPageAndJobPostings from '../../graphql/fragments/CareerPageAndJobPostings';

export const createCareerPageMutation = gql`
  mutation createCareerPage($input: CreateCareerPageInput!) {
    createCareerPage(input: $input) {
      client {
        id
        careerPages {
          ...CareerPageAndJobPostings
        }
      }
    }
  }
  ${CareerPageAndJobPostings}
`;

export default graphql(createCareerPageMutation, {
  props: ({ mutate }) => ({
    createCareerPage: ({ input }) => mutate({ variables: { input } }),
  }),
});
