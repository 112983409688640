import _, { compose } from 'underscore';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Table, Icon, Popup, Button } from 'semantic-ui-react';
import './Templates.css';
import GenericButton from '@/components/Common/GenericButton';
import ContextMenu from '@/components/Tables/ContextMenu/ContextMenu';
import withUpdateRecruiterConfiguration from '@/hocs/templates/withUpdateRecruiterConfiguration';
import withUserFromJWToken from '@/hocs/users/withUserFromJWToken';
import AddReplyIcon from './AddReply';
import RemoveReplyIcon from './RemoveReply';
import EditTemplate from './modals/editTemplate';
import withNavApplicationMode from '../../TopBar/Header/withNavApplicationMode';

class TemplatesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // necessary to prevent re-renders
    this.handleSortChange = this.handleSortChange.bind(this);
  }

  handleSortChange({ key }) {
    const { sortKey, sortOrder, onSort } = this.props;
    if (sortKey === key) {
      return onSort({
        key,
        order: sortOrder * -1,
      });
    }

    return onSort({
      key,
      order: 1,
    });
  }

  render() {
    const {
      clientId,
      templates,
      handleDeleteTemplate,
      handleAfterEditTemplate,
      updateTemplateRecruiterConfiguration,
      duplicateTemplate,
      restoreTemplate,
      readOnly,
      navApplicationMode,
      user,
      sortKey,
      sortOrder,
      t,
    } = this.props;

    const withAssociatedOffers =
      !navApplicationMode.loading &&
      navApplicationMode.applicationMode === 'classic';

    return (
      <Table basic className={`templates-table${readOnly ? ' readOnly' : ''}`}>
        <Table.Header className='template-table-headers'>
          <Table.Row>
            <Table.HeaderCell>
              <TableHeader
                rowKey='name'
                name={t('templates.table.name')}
                sortKey={sortKey}
                sortOrder={sortOrder}
                handleToggleSort={this.handleSortChange}
              />
            </Table.HeaderCell>

            {withAssociatedOffers && (
              <Table.HeaderCell>
                <TableHeader
                  rowKey='offers'
                  name={t('templates.table.associatedOffers')}
                  sortKey={sortKey}
                  sortOrder={sortOrder}
                  handleToggleSort={this.handleSortChange}
                />
              </Table.HeaderCell>
            )}

            <Table.HeaderCell>
              <TableHeader
                rowKey='author'
                name={t('templates.table.author')}
                sortKey={sortKey}
                sortOrder={sortOrder}
                handleToggleSort={this.handleSortChange}
              />
            </Table.HeaderCell>

            <Table.HeaderCell>
              <TableHeader
                rowKey='last-edition-date'
                name={t('templates.table.lastEditionDate')}
                sortKey={sortKey}
                sortOrder={sortOrder}
                handleToggleSort={this.handleSortChange}
              />
            </Table.HeaderCell>

            {!readOnly && <Table.HeaderCell collapsing />}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(templates, (template) => (
            <Table.Row
              className='template-row'
              key={template.id}
              onClick={() => {
                this.setState({ openedTemplateId: template.id });
              }}
            >
              <Table.Cell
                textAlign='left'
                className='template-header-cell'
                onClick={() => {
                  this.setState({ openedTemplateId: template.id });
                }}
              >
                <div className='header-cell-container'>
                  {!readOnly && (
                    <img
                      className='template-icon'
                      src='/images/icons/figma/template-grey.svg'
                      alt='template-grey'
                    />
                  )}
                  <span className='template-title'>{template.title}</span>
                  <EditTemplate
                    clientId={clientId}
                    template={template}
                    templateId={template.id}
                    open={template.id === this.state.openedTemplateId}
                    onClose={() => this.setState({ openedTemplateId: null })}
                    submitCallback={handleAfterEditTemplate}
                  />
                  <span className='table-cell-icons'>
                    {_.findWhere(template.recruiterConfigurations, {
                      recruiterEmail: user.email,
                    })?.isDefaultReply && (
                      <i className='ri-question-answer-line' />
                    )}
                  </span>
                </div>
              </Table.Cell>

              {withAssociatedOffers && (
                <Table.Cell>
                  <TemplateLinkedOffers offers={template.offers} />
                </Table.Cell>
              )}

              <Table.Cell textAlign='left'>
                <div>{getTemplateAuthor(template.author)}</div>
              </Table.Cell>

              <Table.Cell style={{ paddingLeft: 20 }}>
                {template?.lastEditionDate
                  ? t('common.simpleDate', {
                      date: new Date(template?.lastEditionDate),
                    })
                  : ''}
              </Table.Cell>

              <Table.Cell>
                {!readOnly &&
                  (templates[0].isArchived === false ? (
                    <div
                      className='template-actions'
                      onClick={(event) => event.stopPropagation()}
                    >
                      {navApplicationMode?.applicationMode === 'classic' ? (
                        <>
                          <Button
                            className='hs-tertiary-button'
                            onClick={() => duplicateTemplate(template)}
                          >
                            <i className='ri-file-copy-2-line' />
                            <span>{t('templates.buttons.duplicate')}</span>
                          </Button>
                          <Button
                            className='hs-tertiary-button'
                            onClick={() => handleDeleteTemplate(template)}
                          >
                            <i className='ri-archive-line' />
                            <span>{t('templates.buttons.archive')}</span>
                          </Button>
                        </>
                      ) : (
                        <ContextMenu
                          popupContainerStyle={{ maxWidth: 'fit-content' }}
                          actions={[
                            {
                              icon: 'ri-file-copy-2-line',
                              label: t('templates.buttons.duplicate'),
                              onClick: () => duplicateTemplate(template),
                            },
                            {
                              icon: 'ri-archive-line',
                              label: t('templates.buttons.archive'),
                              onClick: () => handleDeleteTemplate(template),
                            },
                            ...(!template.recruiterConfigurations?.[0]
                              ?.isDefaultReply
                              ? [
                                  {
                                    icon: (
                                      <AddReplyIcon
                                        style={{
                                          width: '18px',
                                          marginLeft: '1px',
                                        }}
                                      />
                                    ),
                                    label: t(
                                      'templates.buttons.addToDefaultReplies',
                                    ),
                                    onClick: () => {
                                      updateTemplateRecruiterConfiguration({
                                        templateId: template.id,
                                        isDefaultReply: true,
                                      });
                                    },
                                  },
                                ]
                              : []),
                            ...(template.recruiterConfigurations?.[0]
                              ?.isDefaultReply
                              ? [
                                  {
                                    icon: (
                                      <RemoveReplyIcon
                                        style={{
                                          width: '18px',
                                          marginLeft: '1px',
                                        }}
                                      />
                                    ),
                                    label: t(
                                      'templates.buttons.removeFromDefaultReplies',
                                    ),
                                    onClick: () => {
                                      updateTemplateRecruiterConfiguration({
                                        templateId: template.id,
                                        isDefaultReply: false,
                                      });
                                    },
                                  },
                                ]
                              : []),
                          ]}
                        />
                      )}
                    </div>
                  ) : (
                    <div
                      className='template-actions'
                      onClick={(event) => event.stopPropagation()}
                    >
                      {navApplicationMode?.applicationMode === 'classic' ? (
                        <Button
                          className='hs-tertiary-button'
                          onClick={() => restoreTemplate(template)}
                        >
                          <i className='ri-inbox-unarchive-line' />
                          {t('templates.buttons.restore')}
                        </Button>
                      ) : (
                        <GenericButton
                          primacy='secondary'
                          onClick={() => restoreTemplate(template)}
                        >
                          <i className='ri-inbox-unarchive-line' />
                          {t('templates.buttons.restore')}
                        </GenericButton>
                      )}
                    </div>
                  ))}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

const TableHeader = ({
  rowKey,
  name,
  sortKey,
  sortOrder,
  handleToggleSort,
}) => (
  <div
    className={`button-header${
      sortKey === rowKey ? orderClass(sortOrder) : ''
    }`}
    onClick={() => handleToggleSort({ key: rowKey })}
  >
    <span className='header-name'>{name}</span>{' '}
    {sortKey === rowKey ? orderIcon(sortOrder) : ''}
  </div>
);

const TemplateLinkedOffers = ({ offers }) => {
  const nbCharPerLine = 30;

  const displayedOffers = _.reject(
    offers,
    ({ isUnclassified }) => !!isUnclassified,
  );
  const sortedOffers = _.sortBy(
    displayedOffers,
    (offer) => (offer.title || '').length,
  );

  const {
    offersTitleString,
    offersNumberNotDisplayed,
    fulltitle,
    lastTitleIsCut,
  } = _.reduce(
    sortedOffers,
    (memo, offer) => {
      const title =
        memo.fulltitle === ''
          ? offer.title
          : `${memo.fulltitle}, ${offer.title}`;
      if (memo.offersTitleString.length > 0.8 * nbCharPerLine) {
        return {
          ...memo,
          fulltitle: title,
          offersNumberNotDisplayed: memo.offersNumberNotDisplayed + 1,
        };
      }
      const smoothCutText = (text, targetNbChars, maxNbChars) => {
        if (text.length < maxNbChars) {
          return { partialText: text, cut: false };
        }
        return { partialText: text.slice(0, targetNbChars).trim(), cut: true };
      };
      const maxNbChars = 8 + nbCharPerLine - memo.offersTitleString.length;
      const targetNbChars = Math.max(
        nbCharPerLine - memo.offersTitleString.length,
        5,
      );
      const { partialText, cut } = smoothCutText(
        offer.title,
        targetNbChars,
        maxNbChars,
      );
      return {
        ...memo,
        fulltitle: title,
        offersTitleString:
          memo.offersTitleString === ''
            ? partialText
            : `${memo.offersTitleString}, ${partialText}`,
        ...(cut && { lastTitleIsCut: true }),
      };
    },
    { offersTitleString: '', offersNumberNotDisplayed: 0, fulltitle: '' },
  );
  return sortedOffers ? (
    <div className='middle-cell-content'>
      <div>
        {offersTitleString}
        {(offersNumberNotDisplayed > 0 || lastTitleIsCut) && '... '}
      </div>
      {offersNumberNotDisplayed > 0 && (
        <Popup
          trigger={
            <div className='offers-not-displayed-label'>{`+${offersNumberNotDisplayed}`}</div>
          }
          content={fulltitle}
          inverted
          style={{
            background: '#112e48',
            borderRadius: '3px',
            fontFamily: 'Graphik',
            fontSize: '14px',
            color: '#b4bdc5',
            letterSpacing: '0',
            textAlign: 'center',
          }}
        />
      )}
    </div>
  ) : null;
};

const getTemplateAuthor = (author) => {
  if (!author) {
    return '';
  }

  return `${author.firstname || ''} ${author.lastname || ''}`;
};

const orderClass = (val) => (val > 0 ? ' sort increasing' : ' sort decreasing');

const orderIcon = (val) => (
  <div className='icon-container'>
    {val > 0 ? <Icon name='triangle up' /> : <Icon name='triangle down' />}
  </div>
);

export default compose(
  withNavApplicationMode,
  withUserFromJWToken,
  withTranslation('translations'),
  withUpdateRecruiterConfiguration,
)(TemplatesTable);
