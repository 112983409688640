import _, { compose } from 'underscore';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';

import { getMilestoneStageOptions } from '@/routes/WatchCollectViewV2/WatchCollectFollowup/FollowupKanban/components/FollowupKanbanCard';
import withUpdateProfileStage from '@/hocs/profiles/withUpdateProfileStage';
import withRegisterProfileView from '@/hocs/profiles/withRegisterProfileView';
import { getSortedSkillsWishes } from '../../../../components/Profile/SkillsLabels';
import withActionLogger from '../../../../hocs/withActionLogger';
import LoadingComponent from '../../../../components/LoadingComponent';
import RelevantTags from './RelevantTags';
import ProfileHeader from './ProfileHeader';
import SmartSummary from './SmartSummary';
import SummaryAndStack from './SummaryAndStack';
import Attachments from '../Attachments/Attachments';
import StepModificationView from './StepModificationView.component';
import CandidateClientInterestStates from '../CandidateClientInterestStates/CandidateClientInterestStates';

import './Card.css';
import CandidatePowerHours from '../CandidatePowerHours/CandidatePowerHours';

class ProfileResumeCard extends React.PureComponent {
  onSelectAttachment = ({ id }) => {
    const attachmentElement = document.getElementById(id);
    if (attachmentElement) {
      const { top, left } = attachmentElement.getBoundingClientRect();
      window.scrollTo(left, top);
    }
  };

  render() {
    const {
      profileId,
      profile,
      resumeData,
      t,
      onLogAction,
      attachments,
      updateProfileStage,
      hidePersonnalData,
      registerView,
      offerId,
      clientId,
      stepId,
      subStepId,
    } = this.props;

    const isWatchProfile =
      ((resumeData || {}).prequalification || {}).type === 'hiresweet-watch';

    const currentStage = profile?.processStep?.milestone?.id || 'milestone-1';

    const handleStageChange = (e, { value }) => {
      updateProfileStage({ profileId, value });
    };

    registerView({ profileId });

    return (
      <div className='resume-data-card v4'>
        <div className='profile-header-container'>
          <ProfileHeader {...this.props} />
          {!hidePersonnalData && (
            <div className='profile-header-actions'>
              <StepModificationView {...this.props} />
              {resumeData?.step === 'in-process' && (
                <div className='profile-stage-selector'>
                  <Dropdown
                    className='change-profile-stage'
                    placeholder={t(
                      'watchCollect.followupView.kanban.card.selectStage',
                    )}
                    selection
                    floating
                    upward={false}
                    onChange={handleStageChange}
                    options={getMilestoneStageOptions(t)}
                    defaultValue={currentStage}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {stepId === 'pending' && subStepId === 'pending-extended' && (
          <div className='pending-extended-warning'>
            <i className='ri-information-line ri-lg' />
            <span>{t('profile.resume.pendingExtendedWarning')}</span>
          </div>
        )}
        <Attachments
          attachments={attachments}
          profileId={profileId}
          onSelectAttachment={this.onSelectAttachment}
        />
        <CandidatePowerHours
          profile={profile}
          offerId={offerId}
          clientId={clientId}
        />
        <CandidateClientInterestStates
          profile={profile}
          offerId={offerId}
          clientId={clientId}
        />
        <SmartSummary
          isWatchProfile={isWatchProfile}
          onLogAction={onLogAction}
          profileId={profileId}
          argumentation={resumeData?.smartSummary?.arguments}
          skills={getSortedSkillsWishes({
            hiresweetData: resumeData?.sourceData?.hiresweet,
          })}
          talentStrategist={profile?.talentStrategist}
        />
        <SummaryAndStack
          onLogAction={onLogAction}
          isWatchProfile={isWatchProfile}
          profileId={profileId}
          summary={(resumeData || {}).summary}
        />
        <LoadingComponent
          as='div'
          loading={_.isEmpty(resumeData)}
          length={60}
          margin={1}
        >
          {resumeData && !_.isEmpty(resumeData.relevantTags) && (
            <RelevantTags relevantTags={resumeData.relevantTags} />
          )}
        </LoadingComponent>
      </div>
    );
  }
}

export default compose(
  withActionLogger,
  withUpdateProfileStage,
  withRegisterProfileView,
  withTranslation('translations'),
)(ProfileResumeCard);
