import gql from 'graphql-tag';

export default gql`
  fragment TextWithEnrichmentsAndStack on TextWithEnrichmentsAndStack {
    content {
      text
    }
    stack {
      name {
        default
        fr
        en
      }
      icon
    }
  }
`;

export type TextWithEnrichmentsAndStack = {
  content: {
    text: string;
  };
  stack: {
    name: {
      default: string;
      fr?: string;
      en?: string;
    };
    icon?: string;
  };
};
