import { createContext } from 'react';

export type GenericKanbanContextType = {
  isCardGrabbed: boolean;
  isMouseDown: boolean;
  setIsCardGrabbed: (grabbed: boolean) => void;
  setIsMouseDown: (mouseDown: boolean) => void;
};

export const GenericKanbanContext = createContext<GenericKanbanContextType>(
  {} as GenericKanbanContextType,
);
