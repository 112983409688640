import _ from 'underscore';

export const areEqualOrBothEmpty = (item1, item2) => {
  // both empty values (null or undefined or '')
  if (isEmptyObjectOrValue(item1) && isEmptyObjectOrValue(item2)) {
    return true;
  }
  // works for objects and booleans
  // if (!_.isEqual(item1, item2)) {
    // console.log('item1', item1, 'item2', item2);
  // }
  // object equality ["a"] isEqual ["a"], {} isEqual {}
  return _.isEqual(item1, item2);
};

export const isEmptyObjectOrValue = (item) => {
  return (
    item === null
    || item === ''
    || item === undefined
    || _.isEqual(item, {})
    || _.isEqual(item, [])
  );
};
