import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import classNames from 'classnames';

import UnifiedViewContext from '@/containers/Parameters/Offers/UnifiedViewContext';
import withClientOffers from '../../../hocs/offers/withClientOffers';
import UnifiedViewStepNav from './UnifiedViewStepNav/UnifiedViewStepNav';

import './StepNav.css';

const StepNav = ({
  offers,
  offerId,
  stepId,
  subStepId,
  onSelectStep,
  shouldSplitPending,
  t,
}) => {
  const { unifiedView } = useContext(UnifiedViewContext);

  if (!offers) {
    return null;
  }

  const offer = _.findWhere(offers, { id: offerId });
  if (!offer) {
    return null;
  }

  if (unifiedView) {
    return (
      <UnifiedViewStepNav
        offer={offer}
        activeStepId={stepId}
        activeSubStepId={subStepId}
        onSelectStep={onSelectStep}
        shouldSplitPending={shouldSplitPending}
      />
    );
  }

  const pipe = offer.pipeStepStats || [];

  const getPendingStepFromSubStep = (subStep) => ({
    stepId: 'pending',
    ...subStep,
  });

  const filteredSteps = _.filter(pipe, (step) => step.stepId !== 'pre-pending');

  const cleanedPipe = _.flatten(
    _.map(filteredSteps, (step) =>
      step.stepId === 'pending' && shouldSplitPending
        ? _.map(step.pipeSubStepStats, getPendingStepFromSubStep)
        : step,
    ),
  );

  return (
    <nav className='offer-step-nav'>
      {_.map(cleanedPipe, (step, index) => (
        <button
          type='button'
          key={index}
          className={classNames('body-medium', 'step', {
            active:
              step.stepId === stepId &&
              (!shouldSplitPending ||
                stepId !== 'pending' ||
                !_.contains(
                  ['pending-main', 'pending-extended'],
                  step.subStepId,
                ) ||
                step.subStepId === subStepId),
          })}
          onClick={() =>
            onSelectStep({
              newStepId: step.stepId,
              newSubStepId: step.subStepId,
            })
          }
        >
          {step.subStepId
            ? t(`profile.steps.pipe.${step.subStepId}`, step.subStepId)
            : t(`profile.steps.pipe.${step.stepId}`, step.stepId)}{' '}
          {step.stepId !== 'skipped' ? `(${step.count})` : ''}
        </button>
      ))}
    </nav>
  );
};

export default compose(
  withClientOffers,
  withTranslation('translations'),
)(StepNav);
