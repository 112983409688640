import { RevealProject } from '@/common/reveal';
import getFilterOptions, { FilterOptions } from '../Filters/options';

const APPLICATION_STATUS_OPTIONS = [
  {
    id: 'active',
    name: 'Active',
  },
  {
    id: 'rejected',
    name: 'Rejected',
  },
  {
    id: 'hired',
    name: 'Hired',
  },
];

const getWelcomekitOptions = ({
  projectId,
  projectsWithFilterOptions,
}: {
  projectId: string;
  projectsWithFilterOptions: RevealProject[];
}): FilterOptions =>
  getFilterOptions({
    connectorType: 'welcomekit',
    projectId,
    projectsWithFilterOptions,
    getApplicationStatusOptions: () => APPLICATION_STATUS_OPTIONS,
  });

export default getWelcomekitOptions;
