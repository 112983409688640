import React, { FC, useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import { Task } from '@/types/task';
import { useUserAllowedDepartments } from '@/graphql/hooks/users/useUserAllowedDepartments';

import TaskTable from './TaskTable';
import { useTaskViewFilterDisplayPreferences } from './hooks';

type TaskAgencyTableProps = {
  tasks: Task[];
  onSelect: (id: string, selection?: Task[]) => void;
};

const TaskAgencyTable: FC<TaskAgencyTableProps> = ({ tasks, onSelect }) => {
  const { t } = useTranslation();
  const { departments } = useUserAllowedDepartments();
  const { filters } = useTaskViewFilterDisplayPreferences();
  const { departmentIdFilter = [] } = filters;

  const agencyNamesById = useMemo(() => {
    const result = {
      no_agency: t('reveal.tasks.no_agency'),
    } as Record<string, string>;

    _.forEach(departments, (department) => {
      _.forEach(department.sections || [], (section) => {
        _.forEach(section.subsections || [], ({ id, title }) => {
          result[id] = title;
        });
      });
    });

    return result;
  }, [departments, t]);

  const uncompletedTasks = _.filter(
    tasks,
    (task) => task?.state !== 'completed',
  );

  const groupedTasks = getTasksGroupedByAgency(
    uncompletedTasks,
    departmentIdFilter as string[],
  );
  const sortedTasks = sortTasksByAgencyName(groupedTasks, agencyNamesById);

  return (
    <>
      {_.map(sortedTasks, ({ tableTasks, agencyId, agencyName }) => {
        return (
          <TaskTable
            key={agencyId}
            title={agencyName}
            iconName=''
            tasks={tableTasks}
            onFocusTask={({ taskId }: { taskId: string }) => {
              onSelect(taskId, tableTasks);
            }}
          />
        );
      })}
    </>
  );
};

function getTasksGroupedByAgency(
  tasks: Task[],
  departmentIds?: string[],
): Record<string, Task[]> {
  const result = {} as Record<string, Task[]>;

  function addTask(agencyId: string, task: Task) {
    if (!result[agencyId]) {
      result[agencyId] = [];
    }
    result[agencyId].push(task);
  }

  _.each(tasks, (task) => {
    const searchPoolProfileId = task.target?.id;
    const linkedProfileReferences =
      task.target?.linkedProfilesGroup?.linkedProfileReferences || [];
    if (_.isEmpty(linkedProfileReferences)) {
      addTask('no_agency', task);
      return;
    }
    _.each(linkedProfileReferences, ({ profileId, metadata }) => {
      if (profileId !== searchPoolProfileId) {
        return;
      }
      const agencyMetadata = _.findWhere(metadata || [], { key: 'agencyId' });
      if (
        departmentIds &&
        !_.isEmpty(departmentIds) &&
        !_.contains(departmentIds, agencyMetadata?.value)
      ) {
        return;
      }
      addTask(agencyMetadata?.value || 'no_agency', task);
    });
  });

  return result;
}

function sortTasksByAgencyName(
  taskMap: Record<string, Task[]>,
  names: Record<string, string>,
) {
  return _.chain(taskMap)
    .map((tableTasks, agencyId) => ({
      agencyId,
      agencyName: names[agencyId] || agencyId,
      tableTasks,
    }))
    .sortBy('agencyName')
    .value();
}

export default TaskAgencyTable;
