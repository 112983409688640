import _ from 'underscore';
import { ConnectorRejectionReason, RevealProject } from '@/common/reveal';
import getFilterOptions, { FilterOptions } from '../Filters/options';

const REJECTED_BY_COMPANY = 'rejected-by-company';
const REJECTED_BY_COMPANY_SOURCE_ID = 'RejectedByOrg';
const REJECTED_BY_CANDIDATE = 'rejected-by-candidate';
const REJECTED_BY_CANDIDATE_SOURCE_ID = 'RejectedByCandidate';
const REJECTED_OTHER = 'none-given';
const REJECTED_OTHER_SOURCE_ID = 'Other';

const getApplicationStatusOptions = (
  rejectionReasons?: ConnectorRejectionReason[],
) => {
  return [
    {
      id: 'active',
      name: 'Active',
    },
    {
      id: 'hired',
      name: 'Hired',
    },
    {
      id: 'rejection__we-rejected-them',
      sourceId: REJECTED_BY_COMPANY_SOURCE_ID,
      name: 'We rejected them',
      subOptions: _.map(
        _.filter(
          rejectionReasons || [],
          ({ rejectionType }) => rejectionType?.id === REJECTED_BY_COMPANY,
        ),
        ({ id, name }) => ({ id, name }),
      ),
    },
    {
      id: 'rejection__they-rejected-us',
      sourceId: REJECTED_BY_CANDIDATE_SOURCE_ID,
      name: 'They rejected us',
      subOptions: _.map(
        _.filter(
          rejectionReasons || [],
          ({ rejectionType }) => rejectionType?.id === REJECTED_BY_CANDIDATE,
        ),
        ({ id, name }) => ({ id, name }),
      ),
    },
    {
      id: 'rejection__other',
      sourceId: REJECTED_OTHER_SOURCE_ID,
      name: 'Other',
      subOptions: _.map(
        _.filter(
          rejectionReasons || [],
          ({ rejectionType }) => rejectionType?.id === REJECTED_OTHER,
        ),
        ({ id, name }) => ({ id, name }),
      ),
    },
  ];
};

const getAshbyOptions = ({
  projectId,
  projectsWithFilterOptions,
}: {
  projectId: string;
  projectsWithFilterOptions: RevealProject[];
}): FilterOptions => {
  return getFilterOptions({
    connectorType: 'ashby',
    projectId,
    projectsWithFilterOptions,
    getApplicationStatusOptions,
  });
};

export default getAshbyOptions;
