import _ from 'underscore';

import { TrackedLinksDailyStatistics } from '@/types/statistics/trackedLinks';

interface StatsForLink {
  url: string;
  count: number;
  lastDay: string;
}

export const getStatsForLinks = (
  dailyStats: TrackedLinksDailyStatistics[],
): StatsForLink[] => {
  const statsByURLMap = {} as Record<string, StatsForLink>;

  _.each(dailyStats, ({ day, linkStats }) => {
    _.each(linkStats, ({ count, eventType, url }) => {
      if (eventType !== 'click') return;

      if (!statsByURLMap[url]) {
        statsByURLMap[url] = {
          url,
          count,
          lastDay: day,
        };
      } else {
        const current = statsByURLMap[url];
        current.count += count;
        if (day > current.lastDay) {
          current.lastDay = day;
        }
      }
    });
  });

  return _.values(statsByURLMap);
};

export default {};
