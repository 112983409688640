import React, { useState } from 'react';
import _ from 'underscore';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table, Button } from 'semantic-ui-react';
import TranslatableText from '../../../components/TranslatableText';

import './JobPostings.css';

const careerPageURL = 'https://join.hiresweet.com';

const JobPostings = ({ clientId, careerPage, jobs, clientDepartments, t }) => {
  const jobPostings = getExistingAndPossibleJobPostings({
    clientId,
    jobs,
    careerPage,
    clientDepartments,
    applicationMode: 'classic',
  });
  const sortingFunction = getSortingFunction(clientDepartments);
  const publishedJobPostings = _.filter(
    jobPostings,
    ({ published }) => published,
  ).sort(sortingFunction);
  const unpublishedJobPostings = _.filter(
    jobPostings,
    ({ published }) => !published,
  ).sort(sortingFunction);
  const [showNotPublishedPostings, setShowNotPublishedPostings] = useState(
    _.isEmpty(publishedJobPostings),
  );

  return (
    <>
      <h2>{t('settings.careerPage.jobPostings.jobPostings')}</h2>
      <div className='job-postings section visible'>
        <JobPostingsTable
          jobPostings={publishedJobPostings}
          clientDepartments={clientDepartments}
          t={t}
        />
        {!_.isEmpty(unpublishedJobPostings) && (
          <div className='button-container'>
            <Button
              className='link-button'
              onClick={() =>
                setShowNotPublishedPostings(!showNotPublishedPostings)
              }
            >
              {showNotPublishedPostings
                ? t('settings.careerPage.jobPostings.hideNotPublishedPostings')
                : t('settings.careerPage.jobPostings.showNotPublishedPostings')}
            </Button>
          </div>
        )}
        {showNotPublishedPostings && (
          <JobPostingsTable
            jobPostings={unpublishedJobPostings}
            clientDepartments={clientDepartments}
            t={t}
            unpublished
          />
        )}
      </div>
    </>
  );
};

export const getExistingAndPossibleJobPostings = ({
  clientId,
  jobs,
  careerPage,
  clientDepartments,
  applicationMode,
}) => {
  return _.compact(
    _.flatten(
      _.map(
        jobs,
        ({
          id,
          title,
          foldering,
          jobPostings,
          isArchived,
          isSuggestedForCreation,
          isUnclassified,
        }) => {
          if (isArchived || isSuggestedForCreation || isUnclassified) {
            return null;
          }
          if (_.isEmpty(jobPostings)) {
            return [
              {
                id,
                title: {
                  default: title,
                },
                published: false,
                departmentAndSections: getDepartmentAndSections({
                  foldering,
                  clientDepartments,
                }),
                link:
                  applicationMode === 'classic'
                    ? `/client/${clientId}/jobs/${id}/jobPostings`
                    : `/client/${clientId}/reveal/missions/${id}/job-posting`,
                externalLink: null,
                notCreatedYet: true,
              },
            ];
          }
          return _.map(jobPostings, (jobPosting) => {
            if (!_.findWhere(careerPage.jobPostings, { id: jobPosting.id })) {
              return null;
            }
            return {
              id: jobPosting?.id,
              title: jobPosting.title,
              published: jobPosting.published,
              departmentAndSections: getDepartmentAndSections({
                foldering,
                clientDepartments,
              }),
              link:
                applicationMode === 'classic'
                  ? `/client/${clientId}/jobs/${id}/jobPostings`
                  : `/client/${clientId}/reveal/missions/${id}/job-posting`,
              externalLink: `${careerPageURL}/${careerPage.id}/jobs/${jobPosting?.id}`,
            };
          });
        },
      ),
    ),
  );
};

export const JobPostingsTable = ({
  jobPostings,
  clientDepartments,
  unpublished,
  t,
}) => {
  return (
    <Table className='senders-settings-table' textAlign='center' basic>
      <JobPostingsTableHeader
        t={t}
        clientDepartments={clientDepartments}
        unpublished={unpublished}
      />
      <JobPostingsTableBody
        jobPostings={jobPostings}
        clientDepartments={clientDepartments}
        t={t}
      />
    </Table>
  );
};

export const JobPostingsTableHeader = ({
  clientDepartments,
  unpublished,
  t,
}) => {
  return (
    <Table.Header className='senders-table-header'>
      <Table.Row>
        <Table.HeaderCell>
          {unpublished
            ? t('settings.careerPage.jobPostings.notPublishedJobPostings')
            : t('settings.careerPage.jobPostings.jobPostings')}
        </Table.HeaderCell>
        {clientDepartments && (
          <Table.HeaderCell>
            {t('settings.careerPage.jobPostings.departmentAndSections')}
          </Table.HeaderCell>
        )}
        <Table.HeaderCell>
          {t('settings.careerPage.jobPostings.actions')}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export const JobPostingsTableBody = ({ jobPostings, clientDepartments, t }) => {
  return (
    <Table.Body>
      {_.map(jobPostings, (jobPosting) => (
        <JobPostingTableRow
          key={jobPosting.id}
          jobPosting={jobPosting}
          clientDepartments={clientDepartments}
          t={t}
        />
      ))}
    </Table.Body>
  );
};

export const JobPostingTableRow = ({ jobPosting, clientDepartments, t }) => {
  const {
    title,
    link,
    notCreatedYet,
    published,
    externalLink,
    departmentAndSections,
  } = jobPosting || {};
  return (
    <Table.Row>
      <Table.Cell>
        <TranslatableText text={title} />
      </Table.Cell>
      {clientDepartments && (
        <Table.Cell className='departments'>{departmentAndSections}</Table.Cell>
      )}
      <Table.Cell>
        <div className='edit-cell'>
          {published && externalLink && (
            <a
              className='ui button tertiary'
              href={externalLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('settings.careerPage.jobPostings.view')}
            </a>
          )}
          <Button className='tertiary' as={Link} to={link}>
            {notCreatedYet
              ? t('settings.careerPage.jobPostings.create')
              : t('common.edit')}
          </Button>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export const getDepartmentAndSections = ({ foldering, clientDepartments }) => {
  if (!foldering?.department?.id) {
    return '';
  }

  const department = _.findWhere(clientDepartments, {
    id: foldering.department.id,
  });
  if (!department?.title) {
    return '';
  }
  if (!foldering?.section?.id) {
    return department.title;
  }
  const section = _.findWhere(department.sections, {
    id: foldering.section.id,
  });
  if (!section?.title) {
    return department.title;
  }
  return `${department.title}/${section.title}`;
};

export const getSortingFunction = (clientDepartments) => (a, b) => {
  if (!_.isEmpty(clientDepartments)) {
    if (!a?.departmentAndSections && b?.departmentAndSections) {
      return 1;
    }
    if (a?.departmentAndSections && !b?.departmentAndSections) {
      return -1;
    }
    if (
      (a?.departmentAndSections || '').toLowerCase() <
      (b?.departmentAndSections || '').toLowerCase()
    ) {
      return -1;
    }
    if (
      (a?.departmentAndSections || '').toLowerCase() >
      (b?.departmentAndSections || '').toLowerCase()
    ) {
      return 1;
    }
  }
  if (
    (a?.title?.default || '').toLowerCase() <
    (b?.title?.default || '').toLowerCase()
  ) {
    return -1;
  }
  if (
    (a?.title?.default || '').toLowerCase() >
    (b?.title?.default || '').toLowerCase()
  ) {
    return 1;
  }
  return 0;
};

export default withTranslation('translations')(JobPostings);
