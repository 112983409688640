import gql from 'graphql-tag';

export const UPDATE_PROFILE_PROCESS_STEP = gql`
  mutation UpdateProfileProcessStep(
    $profileId: ID!
    $input: UpdateProfileProcessStepInput!
  ) {
    updateProfileProcessStep(profileId: $profileId, input: $input) {
      processStep {
        milestone {
          id
        }
      }
      id
    }
  }
`;

export const UPDATE_PROFILE_ANSWER = gql`
  mutation UpdateProfileAnswer(
    $profileId: ID!
    $input: UpdateProfileAnswerInput
  ) {
    updateProfileAnswer(profileId: $profileId, input: $input) {
      id
      answer {
        label
      }
    }
  }
`;

export const MARK_PROFILE_AS_GLOBAL_FAVORITE = gql`
  mutation MarkProfileAsGlobalFavorite($profileId: ID!) {
    markProfileAsGlobalFavorite(profileId: $profileId) {
      id
      annotation {
        globalFavorite {
          value
          lastEditionDate
          author {
            firstname
            lastname
            email
          }
        }
      }
    }
  }
`;

export const UNMARK_PROFILE_AS_GLOBAL_FAVORITE = gql`
  mutation UnmarkProfileAsGlobalFavorite($profileId: ID!) {
    unmarkProfileAsGlobalFavorite(profileId: $profileId) {
      id
      annotation {
        globalFavorite {
          value
          lastEditionDate
          author {
            firstname
            lastname
            email
          }
        }
      }
    }
  }
`;

export const UPDATE_GLOBAL_COMMENT = gql`
  mutation UpdateGlobalComment($profileId: ID!, $input: String!) {
    updateGlobalComment(profileId: $profileId, input: $input) {
      id
      annotation {
        globalComment {
          value
          lastEditionDate
          author {
            firstname
            lastname
            email
          }
        }
      }
    }
  }
`;
