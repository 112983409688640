import React, { useState } from 'react';
import { Image, Placeholder } from 'semantic-ui-react';

import classNames from 'classnames';
import { getRandomDefaultAvatarLink, getSecureLink } from '@/common';
import { ProfileColumn } from '../../types';

import styles from './AvatarColumn.module.less';

const AvatarColumn: ProfileColumn = ({
  profileLoading,
  profile,
  className,
  style,
}) => {
  const { firstname, lastname, photoLink, sources } = profile?.resumeData || {};
  const avatarImageUrl =
    (profile?.contactCategory?.type === 'company' && sources?.website && (!photoLink || photoLink.includes('default-avatar'))) ? (
      `https://logo.clearbit.com/${sources?.website}`
    ) : (
      photoLink || getRandomDefaultAvatarLink(`${firstname}${lastname}`)
    );


  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      className={classNames(styles.avatarContainer, className)}
      style={style}
      data-openreplay-masked
    >
      {profileLoading ? (
        <Placeholder className={styles.placeholder}>
          <Placeholder.Image />
        </Placeholder>
      ) : (
        <Image
          data-openreplay-masked
          src={getSecureLink(avatarImageUrl)}
          style={{ visibility: isVisible ? 'visible' : 'hidden' }}
          className={styles.avatar}
          circular
          onLoad={() => setIsVisible(true)}
          onError={(e: Event) => {
            (e.target as HTMLImageElement).src = getRandomDefaultAvatarLink(
              `${firstname}${lastname}`,
            );
          }}
        />
      )}
    </div>
  );
};

export default AvatarColumn;
