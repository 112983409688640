import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import styles from './ListButton.module.less';

export function ListButton({
  as = 'div',
  link = false,
  children,
  className,
  ...rest
}: PropsWithChildren<{
  as: string | React.FC<{ className: string }>;
  link?: boolean;
  className?: string;
  [x: string]: unknown;
}>) {
  const Component = as;
  return (
    <Component className={classNames(className, styles.container)} {...rest}>
      <div className={styles.listButton}>
        <div>{children}</div>
        {link && (
          <div className={styles.linkIcon}>
            <i className='ri-arrow-right-s-line' />
          </div>
        )}
      </div>
    </Component>
  );
}
