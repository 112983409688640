import React from 'react';

import SpeechBubble from './SpeechBubble';
import SendMailMockup from './SendMailMockup';

import styles from './Visual3.module.less';
import LogoCircle from './LogoCircle';

const Visual3 = () => {
  return (
    <div className='visual3'>
      <SpeechBubble className={styles.bubble} />
      <div className={styles.logos}>
        <LogoCircle src='/images/logos/gmail.svg' alt='gmail' />
        <LogoCircle src='/images/logos/outlook.svg' alt='outlook' />
      </div>
      <SendMailMockup />
    </div>
  );
};

export default Visual3;
