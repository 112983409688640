import { gql, useMutation } from '@apollo/client';

const UPDATE_CLIENT_TEXTING_SENDERS = gql`
mutation updateTextingSenders($input: TextingSendersInput!) {
  updateTextingSenders(input: $input) {
    id
    textingSenders {
      firstname
      id
      lastname
      owner {
        email
      }
      senderNumber
      users {
        email
      }
    }
  }
}
`;

export function useUpdateClientTextingSenders() {
  return useMutation(UPDATE_CLIENT_TEXTING_SENDERS);
}