import { useMutation } from '@apollo/client';
import { EDIT_SNIPPET } from '@/graphql/snippets';

const useEditSnippet = () => {
  return useMutation<any, any>(EDIT_SNIPPET, {
    refetchQueries: ['GetClientSnippets', 'getAssistantDescriptors'],
  });
};

export default useEditSnippet;
