import gql from 'graphql-tag';
import Author from './Author';

export default gql`
  fragment Campaign on Campaign {
    id
    title
    subject
    description
    author {
      ...Author
    }
    creationDate
    lastEditionDate
    isArchived
    profilesCount
    profiles {
      id
    }
  }
  ${Author}
`;
