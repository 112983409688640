import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Dropdown, Form, Image, Input, Popup } from 'semantic-ui-react';
import classNames from 'classnames';

import useClientId from '@/hooks/router/useClientId';
import RichEditor from '@/components/RichEditor';
import GenericButton from '@/components/Common/GenericButton';
import useClientMarketplaceDescriptor, {
  useClientMarketplaceDescriptorMutation,
} from '@/graphql/hooks/clients/useClientMarketplaceDescriptor';
import { getTranslatedText } from '@/common';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import {
  ClientDescriptorIndustry,
  ClientDescriptorLocation,
  ClientDescriptorStack,
} from '@/types/client';
import { useLockHistory } from '@/context/LockHistoryContext';
import skillOptions from '@/common/options/skillOptions.json';
import industryOptions from '@/common/options/industryOptions.json';
import locationOptions from '@/common/options/locationOptions.json';

import { sanitizeTypename } from '@/common/utils/apollo';
import SettingsLayout from '../../SettingsLayout';
import {
  CompanyDescriptor,
  CompanyLogoUpload,
  SourceType,
  sourceTypeUrlCheckers,
} from './types';

import styles from './CompanySettings.module.less';

const CompanySettingsPage: React.FC = () => {
  const clientId = useClientId();
  const { t } = useTranslation();
  const notification = useNotificationSystem();
  const { lockHistory, unlockHistory } = useLockHistory();

  const [
    updateClientMarketplaceDescriptor,
  ] = useClientMarketplaceDescriptorMutation();

  const {
    marketplaceDescriptor,
    loading,
    refetch,
  } = useClientMarketplaceDescriptor(clientId);

  const description = getTranslatedText(
    marketplaceDescriptor?.description?.sections?.[0]?.content || {
      default: '',
    },
  );

  const [formValues, setFormValues] = React.useState<CompanyDescriptor>(
    {} as CompanyDescriptor,
  );

  const [
    companyLogo,
    setCompanyLogo,
  ] = React.useState<CompanyLogoUpload | null>(null);
  const updatedFields = React.useRef<Record<string, boolean>>({});
  const logoInputRef = React.useRef<HTMLInputElement | null>(null);
  const errors = React.useRef<Partial<Record<SourceType, boolean>>>({});
  const [formIsValid, setFormIsValid] = React.useState(true);

  const getDefaultValues = React.useCallback(
    (): CompanyDescriptor => ({
      name: marketplaceDescriptor?.name ?? '',
      logoURL: marketplaceDescriptor?.logoURL || '',
      website: marketplaceDescriptor?.website,
      linkedin: marketplaceDescriptor?.linkedin,
      crunchbase: marketplaceDescriptor?.crunchbase,
      description,
      industries:
        (marketplaceDescriptor?.industries as ClientDescriptorIndustry[]) || [],
      locations:
        (marketplaceDescriptor?.locations as ClientDescriptorLocation[]) || [],
      stack: (marketplaceDescriptor?.stack as ClientDescriptorStack[]) || [],
      staffRange: marketplaceDescriptor?.staffRange,
    }),
    [marketplaceDescriptor, description],
  );

  React.useEffect(() => {
    if (!loading) {
      setFormValues(getDefaultValues());
    }
    // eslint-disable-next-line
  }, [loading]);

  const lockHistoryAndTrackUpdatedField = (name: string) => {
    updatedFields.current = {
      ...updatedFields.current,
      [name]: true,
    };
    lockHistory(t('settings.companySettings.lockHistory'));
  };

  const handleClickChangeLogo = () => {
    if (!logoInputRef.current) {
      return;
    }
    logoInputRef.current.click();
  };

  const handleChangeLogo = (files: FileList | null) => {
    const file = files?.[0];
    if (!file) {
      return;
    }
    lockHistoryAndTrackUpdatedField('logoURL');

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        setCompanyLogo({
          name: file.name,
          file: {
            content: reader.result,
          },
        });
      }
    };
    reader.readAsDataURL(file);
  };

  const handleChangeInput = (
    name: string,
    value: unknown,
    isLinkInput = false,
  ) => {
    lockHistoryAndTrackUpdatedField(name);
    setFormValues(
      (prevValues) => ({ ...prevValues, [name]: value } as CompanyDescriptor),
    );
    if (isLinkInput && errors.current) {
      const sourceType = name as SourceType;
      if (value === '' || sourceTypeUrlCheckers[sourceType] === undefined) {
        errors.current[sourceType] = false;
      } else {
        errors.current[sourceType] = !sourceTypeUrlCheckers[sourceType]?.(
          value as string,
        );
      }
    }
  };

  const handleDropdownChange = ({
    name,
    values,
    options,
  }: {
    name: string;
    values: string[];
    options: any;
  }) => {
    lockHistoryAndTrackUpdatedField(name);
    setFormValues((previousValues) => ({
      ...previousValues,
      [name]: _.map(values, (id) => {
        const option = _.findWhere(options, { value: id });
        if (name === 'locations') {
          return {
            identifier: option?.value || '',
            label: {
              default: option?.text || '',
            },
          };
        }
        return {
          id: option?.value || '',
          name: {
            default: option?.text || '',
          },
        };
      }),
    }));
  };

  React.useEffect(() => {
    setFormIsValid(
      _.isEmpty(_.values(errors.current)) ||
        _.every(_.values(errors.current), (value) => !value),
    );
  }, [formValues.website, formValues.linkedin, formValues.crunchbase]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formIsValid) {
      return;
    }
    unlockHistory();
    const payload = _.pick(
      formValues,
      (value, key) => !!updatedFields.current[key as keyof CompanyDescriptor],
    );
    const finalPayload: any = _.omit(
      sanitizeTypename({
        ...payload,
        ...(payload.logoURL !== undefined &&
          companyLogo && {
            logo: companyLogo,
          }),
      }),
      'logoURL',
    );
    await updateClientMarketplaceDescriptor({
      variables: {
        input: finalPayload,
      },
    });
    updatedFields.current = {};
    notification.success(t('settings.notifications.savedWithSuccess'));
    refetch();
  };

  if (loading) {
    return <></>;
  }

  return (
    <SettingsLayout
      currentPage='company-settings'
      clientId={clientId}
      className='settings-container'
    >
      <Form className={styles.companySettings} onSubmit={handleSubmit}>
        <h1 className={styles.title}>{t('settings.companySettings.title')}</h1>

        <div className={styles.section}>
          <h2>{t('settings.companySettings.generalInformation.title')}</h2>
          <div className={styles.card}>
            <div className={styles.nameAndLogo}>
              <FormInput
                name='name'
                label={t('settings.companySettings.generalInformation.name')}
                handleChange={handleChangeInput}
                value={formValues.name || ''}
              />

              <div className={styles.logoWrapper}>
                {companyLogo?.file?.content || formValues.logoURL ? (
                  <Image
                    src={companyLogo?.file?.content || formValues.logoURL}
                  />
                ) : (
                  <span className={styles.spanIcon}>{_.first(clientId)}</span>
                )}
                <div>
                  <GenericButton
                    primacy='secondary'
                    onClick={handleClickChangeLogo}
                  >
                    {t(
                      'settings.companySettings.generalInformation.changeLogo',
                    )}
                  </GenericButton>
                  <span className={styles.hint}>
                    {t('settings.companySettings.generalInformation.logoHint')}
                  </span>
                </div>
                <input
                  type='file'
                  ref={logoInputRef}
                  onChange={(e) => handleChangeLogo(e.target.files)}
                  accept='image/*'
                  style={{ display: 'none' }}
                  autoComplete='off'
                />
              </div>
            </div>
            <Form.Field className={styles.formField}>
              <label>
                {t('settings.companySettings.generalInformation.description')}
              </label>
              <RichEditor
                initialHtml={description}
                onChangeAsHtml={(html: string) =>
                  handleChangeInput('description', html)
                }
              />
            </Form.Field>
          </div>
        </div>

        <div className={styles.section}>
          <h2>{t('settings.companySettings.links.title')}</h2>
          <div className={styles.card}>
            <span className={styles.hint}>
              {t('settings.companySettings.links.hint')}
            </span>
            <FormInput
              name='website'
              label={t('settings.companySettings.links.website')}
              handleChange={handleChangeInput}
              value={formValues.website || ''}
              className={styles.linkFormField}
              placeholder={t('settings.companySettings.links.placeholder')}
              inline
              isLinkInput
              isInvalid={errors.current.website}
            />
            <FormInput
              name='linkedin'
              label='LinkedIn'
              handleChange={handleChangeInput}
              value={formValues.linkedin || ''}
              className={styles.linkFormField}
              placeholder={t('settings.companySettings.links.placeholder')}
              inline
              isLinkInput
              isInvalid={errors.current.linkedin}
            />
            <FormInput
              name='crunchbase'
              label='Crunchbase'
              handleChange={handleChangeInput}
              value={formValues.crunchbase || ''}
              className={styles.linkFormField}
              placeholder={t('settings.companySettings.links.placeholder')}
              inline
              isLinkInput
              isInvalid={errors.current.crunchbase}
            />
          </div>
        </div>

        <div className={styles.section}>
          <h2>{t('settings.companySettings.company.title')}</h2>
          <div className={styles.card}>
            <div className={styles.flexBetween}>
              <Form.Field className={styles.formField}>
                <label>
                  {t('settings.companySettings.company.industries.title')}
                </label>
                <Dropdown
                  search
                  selection
                  fluid
                  multiple
                  selectOnBlur={false}
                  placeholder={t(
                    'settings.companySettings.company.industries.placeholder',
                  )}
                  options={industryOptions}
                  value={_.map(
                    formValues.industries || [],
                    (industry) => industry.id,
                  )}
                  onChange={(event, { value }) => {
                    handleDropdownChange({
                      name: 'industries',
                      values: value as string[],
                      options: industryOptions,
                    });
                  }}
                />
              </Form.Field>

              <Form.Field className={styles.formField}>
                <label>
                  {t('settings.companySettings.company.locations.title')}
                </label>
                <Dropdown
                  search
                  selection
                  fluid
                  multiple
                  selectOnBlur={false}
                  placeholder={t(
                    'settings.companySettings.company.locations.placeholder',
                  )}
                  options={locationOptions}
                  value={_.map(
                    formValues.locations || [],
                    (location) => location.identifier,
                  )}
                  onChange={(event, { value }) => {
                    handleDropdownChange({
                      name: 'locations',
                      values: value as string[],
                      options: locationOptions,
                    });
                  }}
                />
              </Form.Field>
            </div>
            <Form.Field className={styles.formField}>
              <label>{t('settings.companySettings.company.stack.title')}</label>
              <Dropdown
                search
                selection
                fluid
                multiple
                selectOnBlur={false}
                placeholder={t(
                  'settings.companySettings.company.stack.placeholder',
                )}
                options={skillOptions}
                value={_.map(formValues.stack || [], (skill) => skill.id)}
                onChange={(event, { value }) => {
                  handleDropdownChange({
                    name: 'stack',
                    values: value as string[],
                    options: skillOptions,
                  });
                }}
              />
            </Form.Field>
            <Form.Field className={styles.formField}>
              <label>
                {t('settings.companySettings.company.staffRange.title')}
              </label>
              <div className={styles.flexBetween}>
                <Form.Select
                  className={styles.formField}
                  placeholder={t(
                    'settings.companySettings.company.staffRange.placeholder',
                  )}
                  options={[
                    { value: '1-10', text: '1-10' },
                    { value: '11-25', text: '11-25' },
                    { value: '26-50', text: '26-50' },
                    { value: '51-100', text: '51-100' },
                    { value: '101-200', text: '101-200' },
                    { value: '201-500', text: '201-500' },
                    { value: '501-1000', text: '501-1000' },
                    { value: '1001-2000', text: '1001-2000' },
                    { value: '2001-4999', text: '2001-4999' },
                    {
                      value: '5000',
                      text: '5000+',
                    },
                  ]}
                  onChange={(e, { value }) => {
                    if (!value || typeof value !== 'string') {
                      return;
                    }
                    const [min, max] = value.split('-');
                    lockHistoryAndTrackUpdatedField('staffRange');
                    setFormValues((previousState) => ({
                      ...previousState,
                      staffRange: {
                        min: +min,
                        ...(max && { max: +max }),
                      },
                    }));
                  }}
                  value={`${formValues.staffRange?.min}${
                    formValues.staffRange?.max
                      ? `-${formValues.staffRange?.max}`
                      : ''
                  }`}
                />
              </div>
            </Form.Field>
          </div>
        </div>

        <div className={styles.actions}>
          {!formIsValid ? (
            <Popup
              inverted
              position='top center'
              content={t('settings.companySettings.invalidForm')}
              trigger={
                <GenericButton type='submit' size='big' disabled>
                  {t('settings.saveButton')}
                </GenericButton>
              }
            />
          ) : (
            <GenericButton type='submit' size='big'>
              {t('settings.saveButton')}
            </GenericButton>
          )}
        </div>
      </Form>
    </SettingsLayout>
  );
};

interface FormInputProps {
  label?: string;
  name: string;
  placeholder?: string;
  inline?: boolean;
  value: string;
  className?: string;
  handleChange: (name: string, value: string, isLinkInput?: boolean) => void;
  isLinkInput?: boolean;
  isInvalid?: boolean;
}
const FormInput: React.FC<FormInputProps> = ({
  label,
  name,
  inline = false,
  handleChange,
  value,
  placeholder,
  className,
  isLinkInput,
  isInvalid,
}) => {
  return (
    <Form.Field
      className={classNames(styles.formField, className)}
      inline={inline}
    >
      {label && <label>{label}</label>}

      <Input
        className={classNames(styles.input, isInvalid && styles.invalidInput)}
        onChange={(event, data) => handleChange(name, data.value, isLinkInput)}
        value={value}
        placeholder={placeholder || ''}
        autoComplete='off'
      />
    </Form.Field>
  );
};

export default CompanySettingsPage;
