import gql from 'graphql-tag';

export const GET_DO_NOT_CONTACT_REASONS = gql`
  query getClientDoNotContactReasons($clientId: ID!) {
    client(id: $clientId) {
      id
      customDoNotContactReasons {
        id
        title {
          default
        }
      }
    }
  }
`;

export type DoNotContactReason = {
  id: string;
  title: { default?: string; fr?: string; en?: string; };
};
