export /**
 *
 *
 * @param {string} str - the string to clean
 * @returns {string} - the previous string without html elements, accents, spaces, and lowercased
 */
const cleanString = (str) =>
  str
    .replace(/(<.*?>| )/g, '')
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

const hellofirstnameSubstrings = /(bonjourfirstname|bonjourprenom|salutfirstname|salutprenom|hellofirstname|helloprenom|hifirstname|hiprenom|dearprenom|dearfirstname)/;
/**
 *
 * @param {string} str - the string to examine
 * @returns {boolean} - true if the string is suspicious
 */
export const detectSuspiciousEmailContent = (str) => {
  const detected = []
  if (hellofirstnameSubstrings.test(str)) {
    detected.push('hellofirstname')
  }
  return detected;
};
