import gql from 'graphql-tag';
import TranslatableText from './TranslatableText';

export default gql`
    fragment RelevantTag on RelevantTag {
        label {
            ...TranslatableText
        }
        content {
            ...TranslatableText
        }
        popupContent {
            ...TranslatableText
        }
        category
    }
    ${TranslatableText}
`;
