import _ from 'underscore';

const geometricMean = (vals) =>
  _.reduce(vals, (a, b) => a * b) ** (1 / vals.length);

const wordPrefixEditDistance = (word, pref) => {
  const W = word.length;
  const P = pref.length;
  if (W * P === 0) {
    return Math.max(W, P);
  }
  const dyn = new Array(W + 1);
  for (let i = 0; i < dyn.length; i++) {
    dyn[i] = new Array(P + 1);
  }
  for (let j = 0; j <= P; j++) {
    dyn[W][j] = P - j;
  }
  for (let i = 0; i <= W; i++) {
    dyn[i][P] = 0;
  }
  for (let i = W - 1; i >= 0; i--) {
    for (let j = P - 1; j >= 0; j--) {
      if (word[i] === pref[j]) {
        dyn[i][j] = dyn[i + 1][j + 1];
      } else {
        dyn[i][j] = Math.min(
          1 + dyn[i + 1][j + 1],
          1 + dyn[i + 1][j],
          1 + dyn[i][j + 1],
        );
      }
    }
  }
  return dyn[0][0];
};

// Remove accents
// @see https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
function removeDiacritics(str) {
  return (
    str &&
    _.isString(str) &&
    str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  );
}

// SearchEngine({
//   items: [...],
//   keyMapper: function(item) {...},
//   fieldsWithWeights : [{field:'firstName', weight: 1.}, {field:'lastName', weight: 0.8.}, ...]
// })
//
// In the function getResults, a score :
//       >= 0.8    means "quite confident",
//     around 0.5  means "really not sure"
//       <= 0.3    means "seems improbable"
// NB: The result are sorted by decreasing scores
class FuzzySearch {
  constructor({ items, keyMapper, fieldsWithWeights }) {
    this.items = items;
    this.keyMapper = keyMapper;
    this.fieldsWithWeights = fieldsWithWeights;
    this.getScoreOfItem = this.getScoreOfItem.bind(this);
    this.getResults = this.getResults.bind(this);
  }

  // same notation conventions as getResults
  getSimilarity(word, pref) {
    const minLength = Math.min(word.length, pref.length);
    if (minLength === 0) {
      return 0;
    }
    const editDist = wordPrefixEditDistance(word, pref);
    if (editDist > 3) {
      return 0;
    }
    if (10 * editDist >= 4 * minLength) {
      // err >= 40%
      return 0;
    }
    if (minLength >= 7 && 3 * editDist >= minLength) {
      // err >= 33%, L >= 7
      return 0;
    }
    const score = Math.max(
      0,
      1 - (editDist * editDist) / (minLength * minLength),
    );
    if (minLength <= 1) {
      return score * 0.8;
    }
    if (minLength <= 2) {
      return score * 0.9;
    }
    return score;
  }

  // assumes that each queryWord is normalized
  getScoreOfItem(queryWords, item) {
    const scores = _.map(queryWords, (queryWord) =>
      _.max(
        _.map(this.fieldsWithWeights, (fieldWithWeight) => {
          const fieldValue = removeDiacritics(item[fieldWithWeight.field]);
          const targetWord = _.isString(fieldValue)
            ? fieldValue.toLowerCase()
            : '';
          const similarity = this.getSimilarity(targetWord, queryWord);
          return similarity > 0.4 && targetWord !== ''
            ? similarity * fieldWithWeight.weight
            : 0;
        }),
      ),
    )
      .sort()
      .reverse();
    // if we have >= 2 queryWords, we can drop a word but get a loss of 20%
    let score = geometricMean(scores);
    let coef = 1;
    while (scores.length >= 2) {
      scores.pop();
      coef *= 0.8;
      score = Math.max(score, coef * geometricMean(scores));
    }
    return score;
  }

  // returns [{key,score},..]
  getResults(queryStr) {
    const tokens = _.compact(queryStr.split(/,?\s+/));
    const queryWords = _.map(tokens, (token) => token.toLowerCase());
    return _.sortBy(
      _.map(this.items, (item) => ({
        key: this.keyMapper(item),
        score: this.getScoreOfItem(queryWords, item),
      })),
      (keyAndScore) => -keyAndScore.score,
    );
  }
}

export default FuzzySearch;
