import _ from 'underscore';
import { TFunction } from 'i18next';

import {
  ArchivedState,
  PipelineState,
} from '@/components/PipelineSegmentation/pipelineSegmentation';
import { sumNumberRecords } from '@/common/helpers/stats';
import {
  CountsByArchivedState,
  CountsByPipelineState,
} from '@/types/statistics/pipeline';
import {
  PipelineCountDataPointForCategory,
  SeparatedCountsByPipelineState,
  SeparatedCountsByPipelineStateByCategory,
} from '../types';
import { segments, states } from './segments';

const getInitialSeparatedCountsByPipelineState = (): SeparatedCountsByPipelineState => ({
  nonArchived: {
    [PipelineState.PENDING]: 0,
    [PipelineState.IN_PROGRESS]: 0,
    [PipelineState.REPLIED]: 0,
    [PipelineState.INTERESTED]: 0,
    [PipelineState.HIRED]: 0,
  },
  archived: {
    [PipelineState.PENDING]: 0,
    [PipelineState.IN_PROGRESS]: 0,
    [PipelineState.REPLIED]: 0,
    [PipelineState.INTERESTED]: 0,
    [PipelineState.HIRED]: 0,
  },
});

const sumSeparatedCountsByPipelineState = (
  a: SeparatedCountsByPipelineState,
  b: SeparatedCountsByPipelineState,
): SeparatedCountsByPipelineState => ({
  nonArchived: sumNumberRecords(a.nonArchived, b.nonArchived),
  archived: sumNumberRecords(a.archived, b.archived),
});

export const formatPipelineCountDataPoints = (
  datapoints: PipelineCountDataPointForCategory[],
): SeparatedCountsByPipelineStateByCategory =>
  _.reduce(
    datapoints,
    (result, { categoryId, data: datapoint }) => {
      const counts = getInitialSeparatedCountsByPipelineState();
      _.each(datapoint, ({ state, archived, count }) => {
        if (archived) {
          counts.archived[state] = (counts.archived[state] || 0) + count;
        } else {
          counts.nonArchived[state] = (counts.nonArchived[state] || 0) + count;
        }
      });
      return {
        ...result,
        [categoryId]: counts,
      };
    },
    {} as SeparatedCountsByPipelineStateByCategory,
  );

export const getConversionRates = (t: TFunction) => (
  stats: CountsByPipelineState,
): Partial<Record<PipelineState, string>> =>
  _.chain([
    (stats[PipelineState.IN_PROGRESS] || 0) /
      (stats[PipelineState.PENDING] || 1),
    (stats[PipelineState.REPLIED] || 0) /
      (stats[PipelineState.IN_PROGRESS] || 1),
    (stats[PipelineState.INTERESTED] || 0) /
      (stats[PipelineState.REPLIED] || 1),
    (stats[PipelineState.HIRED] || 0) / (stats[PipelineState.INTERESTED] || 1),
  ])
    .map((rate) => Math.round(100 * rate))
    .map((rate) => `${rate}${t('reveal.reports.percentageSuffix')}`)
    .map((rate, index) => [segments[index], rate])
    .object()
    .value();

export const flattenSeparatedCountsByPipelineState = (
  data: SeparatedCountsByPipelineStateByCategory,
): SeparatedCountsByPipelineState =>
  _.reduce(
    data,
    (result, counts) => sumSeparatedCountsByPipelineState(result, counts),
    getInitialSeparatedCountsByPipelineState(),
  );

export const getEverCountsBySegment = (
  stats: SeparatedCountsByPipelineState,
): CountsByPipelineState & CountsByArchivedState => {
  const result = {
    [PipelineState.PENDING]: 0,
    [PipelineState.IN_PROGRESS]: 0,
    [PipelineState.REPLIED]: 0,
    [PipelineState.INTERESTED]: 0,
    [PipelineState.HIRED]: 0,
    [ArchivedState.ARCHIVED]: 0,
  };
  const { archived, nonArchived } = stats;

  _.each(states, (segmentId, index) => {
    for (let i = index; i < states.length; i += 1) {
      result[segmentId] += nonArchived[states[i]];
      result[segmentId] += archived[states[i]];
    }
  });

  _.each(_.values(archived), (count) => {
    result[ArchivedState.ARCHIVED] += count;
  });

  return result;
};

export const getCurrentCountsBySegment = (
  stats: SeparatedCountsByPipelineState,
): CountsByPipelineState & CountsByArchivedState => {
  return {
    ...stats.nonArchived,
    [ArchivedState.ARCHIVED]: _.reduce(
      stats.archived,
      (total, stat) => total + stat,
      0,
    ),
  };
};
