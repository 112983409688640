import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import { ApolloTranslatableText } from '@/types/text';
import { getTranslatedText } from '@/common/helpers/translatableText';
import { getShortLanguage } from '@/common/utils/i18n';

export const GET_INTERESTED_SUB_PIPELINE = gql`
  query GetInterestedSubPipeline($missionId: ID!) {
    searchPool(id: "reveal") {
      job(id: $missionId) {
        id
        ... on RevealJob {
          interestedSubPipeline {
            id
            title {
              default
              en
              fr
            }
            steps {
              id
              title {
                default
                en
                fr
              }
              previousStepId
              isDisabled
            }
          }
        }
      }
    }
  }
`;

interface GetInterestedSubPipelineData {
  searchPool: {
    __typename: 'SearchPool';
    job: {
      __typename: 'RevealSearchPoolJob';
      id: string;
      interestedSubPipeline?: {
        __typename: 'SubPipeline';
        id: string;
        title: ApolloTranslatableText;
        steps: {
          __typename: 'SubPipelineStep';
          id: string;
          title: ApolloTranslatableText;
          previousStepId?: string | null;
          isDisabled?: boolean | undefined;
        }[];
      };
    };
  };
}

interface GetInterestedSubPipelineVariables {
  missionId: string;
}

interface UseInterestedSubPipelineInput
  extends GetInterestedSubPipelineVariables {
  queryOptions?: QueryHookOptions<
    GetInterestedSubPipelineData,
    GetInterestedSubPipelineVariables
  >;
}

interface InterestedSubPipeline {
  id: string;
  title: string;
  steps: { id: string; title: string; previousStepId?: string | null, isDisabled?: boolean | null }[];
}

interface UseInterestedSubPipelineResult
  extends QueryResult<
    GetInterestedSubPipelineData,
    GetInterestedSubPipelineVariables
  > {
  interestedSubPipeline?: InterestedSubPipeline;
}

const useInterestedSubPipeline = ({
  missionId,
  queryOptions = {},
}: UseInterestedSubPipelineInput): UseInterestedSubPipelineResult => {
  const { i18n } = useTranslation();
  const query = useQuery<
    GetInterestedSubPipelineData,
    GetInterestedSubPipelineVariables
  >(GET_INTERESTED_SUB_PIPELINE, { ...queryOptions, variables: { missionId } });

  const translatableInterestedSubPipeline =
    query.data?.searchPool.job.interestedSubPipeline;

  const lang = useMemo(() => {
    const { resolvedLanguage } = i18n;
    return getShortLanguage(resolvedLanguage);
  }, [i18n]);

  const interestedSubPipeline = useMemo(() => {
    if (!translatableInterestedSubPipeline) {
      return undefined;
    }
    const { id, title, steps } = translatableInterestedSubPipeline;
    return {
      id,
      title: getTranslatedText(lang, title),
      steps: _.map(steps, (step) => ({
        id: step.id,
        title: getTranslatedText(lang, step.title),
        ...(step.previousStepId && { previousStepId: step.previousStepId }),
        ...('isDisabled' in step && { isDisabled: step.isDisabled }),
      })),
    };
  }, [translatableInterestedSubPipeline, lang]);

  return { ...query, interestedSubPipeline };
};

export default useInterestedSubPipeline;
