import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  ModalHeader as Header,
  ModalContent as Content,
  ModalActions as Actions,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import withUpdateOffer from '../../../../hocs/offers/withUpdateOffer';
import withClient from '../../../../hocs/clients/withClient';
import withClientCollaborators from '../../../../hocs/users/withClientCollaborators';

const OrganizeJobModal = ({
  client,
  offer,
  collaborators,
  onClose,
  updateOffer,
  t,
}) => {
  const [selectedOwner, setOwner] = useState(offer?.owner?.email || '');
  const [selectedHiringManager, setHiringManager] = useState(
    offer?.hiringManager?.email || '',
  );
  const [selectedFollowers, selectFollowers] = useState(
    _.pluck(offer?.followers, 'email'),
  );
  const [selectedDepartmentId, setDepartmentId] = useState(
    offer?.foldering?.department?.id || '',
  );
  const [selectedSectionId, setSectionId] = useState(
    offer?.foldering?.section?.id || '',
  );

  const departmentOptions = !_.isEmpty(client?.departments)
    ? [
        ..._.map(client.departments, ({ title, id }) => ({
          text: title,
          value: id,
        })),
        { text: 'Other departments', value: 'other', type: 'department' },
      ]
    : [];

  const selectedDepartment =
    selectedDepartmentId &&
    _.findWhere(client?.departments, {
      id: selectedDepartmentId,
    });

  const sectionOptions = _.map(selectedDepartment?.sections, (section) => ({
    text: section.title,
    value: section.id,
  }));

  const handleSubmit = async () => {
    try {
      const variables = {
        id: offer?.id,
        input: {
          owner: selectedOwner ? { email: selectedOwner } : null,
          hiringManager: selectedHiringManager
            ? { email: selectedHiringManager }
            : null,
          followers: _.map(selectedFollowers, (email) => ({ email })),
          foldering: {
            department: selectedDepartmentId
              ? { id: selectedDepartmentId }
              : null,
            section: selectedSectionId ? { id: selectedSectionId } : null,
          },
        },
      };
      await updateOffer(variables);
      onClose();
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  if (!collaborators) {
    return <div />;
  }

  const collaboratorOptions = [
    ..._.map(
      _.where(collaborators, { status: 'active' }),
      ({ email, firstname, lastname }) => ({
        value: email,
        text:
          (firstname ? `${firstname} ` : '') +
          (lastname || '') +
          (!firstname && !lastname ? email : ''),
      }),
    ),
  ];

  return (
    <GenericModal size='tiny' onClose={onClose} open>
      <Header>{t('offers.organizeModal.header')}</Header>
      <Content>
        <div className='input-container'>
          <div className='input-label'>{t('offers.organizeModal.owner')}</div>
          <div className='input-element'>
            <Dropdown
              fluid
              selection
              clearable={!!selectedOwner}
              options={collaboratorOptions}
              onChange={(e, { value }) => setOwner(value)}
              value={
                _.findWhere(collaboratorOptions, { value: selectedOwner })
                  ? selectedOwner
                  : ''
              }
            />
          </div>
        </div>

        <div className='input-container'>
          <div className='input-label'>
            {t('offers.organizeModal.hiringManager')}
          </div>
          <div className='input-element'>
            <Dropdown
              fluid
              selection
              clearable={!!selectedHiringManager}
              options={collaboratorOptions}
              onChange={(e, { value }) => setHiringManager(value)}
              value={
                _.findWhere(collaboratorOptions, {
                  value: selectedHiringManager,
                })
                  ? selectedHiringManager
                  : ''
              }
            />
          </div>
        </div>

        <div className='input-container'>
          <div className='input-label'>
            {t('offers.organizeModal.followers')}
          </div>
          <div className='input-element'>
            <Dropdown
              fluid
              multiple
              selection
              options={collaboratorOptions}
              value={_.filter(
                selectedFollowers,
                (email) => !!_.findWhere(collaboratorOptions, { value: email }),
              )}
              onChange={(e, { value }) => selectFollowers(value)}
              placeholder='Followers...'
            />
          </div>
        </div>

        <div className='input-container'>
          <div className='input-label'>
            {t('offers.organizeModal.department')}
          </div>
          {_.isEmpty(departmentOptions) ? (
            <div className='input-description'>
              {t('offers.organizeModal.addDepartments.goTo')}
              <Link to={`/client/${client?.id}/departments`}>
                {t('offers.organizeModal.addDepartments.settings')}
              </Link>
            </div>
          ) : (
            <div className='input-element'>
              <Dropdown
                fluid
                selection
                clearable={!!selectedDepartmentId}
                options={departmentOptions}
                onChange={(e, { value }) => {
                  setSectionId('');
                  setDepartmentId(value);
                }}
                value={selectedDepartmentId}
              />
            </div>
          )}
        </div>
        {!_.isEmpty(sectionOptions) && (
          <div className='input-container'>
            <div className='input-label'>
              {t('offers.organizeModal.section')}
            </div>
            <div className='input-element'>
              <Dropdown
                className='organize-job-form-dropdown'
                fluid
                selection
                clearable={!!selectedSectionId}
                options={sectionOptions}
                onChange={(e, { value }) => {
                  setSectionId(value);
                }}
                value={selectedSectionId}
              />
            </div>
          </div>
        )}
      </Content>
      <Actions className='modal-actions-buttons'>
        <div className='flex-spaced-between'>
          <GenericButton primacy='secondary' size='big' onClick={onClose}>
            {t('offers.organizeModal.cancel')}
          </GenericButton>
          <GenericButton size='big' onClick={handleSubmit}>
            {t('offers.organizeModal.confirm')}
          </GenericButton>
        </div>
      </Actions>
    </GenericModal>
  );
};

export default compose(
  withClient,
  withClientCollaborators,
  withUpdateOffer,
  withTranslation('translations'),
)(OrganizeJobModal);
