import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

const getDistinctFilterOptions = gql`
  query getDistinctFilterOptions(
    $searchPoolId: ID!
    $missionId: ID!
    $filterId: String!
  ) {
    searchPool(id: $searchPoolId) {
      id
      job(id: $missionId) {
        id
        distinctFilterOptions(filterId: $filterId) {
          optionId
        }
      }
    }
  }
`;

type GetDistinctFilterOptionsData = {
  searchPool: {
    __typename: string;
    id: string;
    job: {
      __typename: string;
      id: string;
      distinctFilterOptions: {
        optionId: string;
      }[];
    };
  };
};

type GetDistinctFilterOptionsVariables = {
  searchPoolId: string;
  missionId: string;
  filterId: string;
};

type UseDistinctFilterOptionsInput = {
  searchPoolId?: string;
  missionId: string;
  filterId: string;
  queryOptions?: QueryHookOptions<
    GetDistinctFilterOptionsData,
    GetDistinctFilterOptionsVariables
  >;
};

interface UseDistinctFilterOptionsResult
  extends QueryResult<
    GetDistinctFilterOptionsData,
    GetDistinctFilterOptionsVariables
  > {
  distinctFilterOptions: {
    optionId: string;
  }[];
}

function useDistinctFilterOptions({
  filterId,
  missionId,
  searchPoolId = 'reveal',
  queryOptions = {},
}: UseDistinctFilterOptionsInput): UseDistinctFilterOptionsResult {
  const query = useQuery<
    GetDistinctFilterOptionsData,
    GetDistinctFilterOptionsVariables
  >(getDistinctFilterOptions, {
    ...queryOptions,
    variables: {
      searchPoolId,
      missionId,
      filterId,
    },
  });

  const { distinctFilterOptions = [] } = query.data?.searchPool.job || {};

  return { ...query, distinctFilterOptions };
}

export default useDistinctFilterOptions;
