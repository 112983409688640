import React, { FC } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import GenericFormLabel from '@/components/Common/GenericFormLabel';
import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';
import useCuratedAutoSend from '@/graphql/hooks/offers/useCuratedAutoSend';
import useUpdateCuratedAutoSend from '@/graphql/hooks/offers/useUpdateCuratedAutoSend';
import useClientUsers from '@/graphql/hooks/clients/useClientUsers';
import useClientId from '@/hooks/router/useClientId';
import GenericSelect from '@/components/Common/GenericSelect';

import styles from './CuratedAutoSendControls.module.less';

type CuratedAutoSendControlsProps = {
  offerId: string;
};

const CuratedAutoSendControls: FC<CuratedAutoSendControlsProps> = ({
  offerId,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { curatedAutoSend } = useCuratedAutoSend({ offerId });
  const [updateCuratedAutoSend] = useUpdateCuratedAutoSend({
    mutationOptions: {
      optimisticResponse: ({ offerId: id, input }) => ({
        offer: {
          id,
          curatedAutoSend: input,
        },
      }),
    },
  });
  const { isEnabled, senderEmail } = curatedAutoSend;
  const { users } = useClientUsers(clientId);
  const activeUsers = _.where(users, { status: 'active' });

  const userOptions = _.map(activeUsers, ({ email }) => ({
    value: email,
    label: email,
  }));

  const selectedUser = _.findWhere(userOptions, {
    value: senderEmail ?? undefined,
  });

  const toggleCuratedAutoSend = () =>
    updateCuratedAutoSend(offerId, {
      isEnabled: !isEnabled,
      senderEmail,
    });

  const updateSenderEmail = (email: string) =>
    updateCuratedAutoSend(offerId, {
      isEnabled,
      senderEmail: email,
    });

  return (
    <>
      <h3 className={styles.title}>{t('offers.curatedAutoSend.title')}</h3>
      <LabeledCheckbox
        label={t('offers.curatedAutoSend.enable')}
        checked={isEnabled}
        onClick={toggleCuratedAutoSend}
        className={styles.checkbox}
      />
      <GenericFormLabel>
        {t('offers.curatedAutoSend.senderEmail')}
      </GenericFormLabel>
      <GenericSelect
        options={userOptions}
        value={selectedUser}
        className={styles.input}
        isDisabled={!isEnabled}
        onChange={(value) => {
          if (
            value &&
            typeof value === 'object' &&
            'value' in value &&
            typeof value.value === 'string'
          ) {
            updateSenderEmail(value.value);
          }
        }}
      />
    </>
  );
};

export default CuratedAutoSendControls;
