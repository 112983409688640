/* eslint-disable max-len */

import React, { FC } from 'react';

import styles from './svg.module.less';

const TableIcon: FC = () => (
  <svg
    className={styles.svgIcon}
    height='1em'
    width='1em'
    viewBox='0 0 16 16'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.5 8V5H14.5V8H1.5ZM1.5 9.5V13C1.5 13.2761 1.72386 13.5 2 13.5H14C14.2761 13.5 14.5 13.2761 14.5 13V9.5H1.5ZM2 1C0.895431 1 0 1.89543 0 3V13C0 14.1046 0.895431 15 2 15H14C15.1046 15 16 14.1046 16 13V3C16 1.89543 15.1046 1 14 1H2Z'
      fill='currentColor'
    />
  </svg>
);

export default TableIcon;
