import React, { useState } from 'react';

interface ContextInterface {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  tabActive: REVEAL_ACTIVITY_TABS;
  setTabActive: (tab: REVEAL_ACTIVITY_TABS) => void;
  clientId: string;
  missionId: string;
}

interface ProviderProps {
  clientId: string;
  missionId: string;
}

export enum REVEAL_ACTIVITY_TABS {
  ALL_ACTIVITIES,
  CURRENT_USER_ACTIVITIES,
  SHARED_ACTIVITIES,
}

const RevealActivityContext = React.createContext<ContextInterface | null>(
  null,
);

const RevealActivityProvider: React.FC<ProviderProps> = ({
  clientId,
  missionId,
  children,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabActive, setTabActive] = useState(
    REVEAL_ACTIVITY_TABS.ALL_ACTIVITIES,
  );
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <RevealActivityContext.Provider
      value={{
        isModalOpen,
        openModal,
        closeModal,
        tabActive,
        setTabActive,
        clientId,
        missionId,
      }}
    >
      {children}
    </RevealActivityContext.Provider>
  );
};

function useRevealActivityContext() {
  const context = React.useContext(RevealActivityContext);
  if (!context) {
    throw new Error(
      'useRevealActivityContext must be used within a RevealActivityProvider',
    );
  }
  return context;
}

export { RevealActivityProvider, useRevealActivityContext };
