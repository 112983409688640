import GenericButton from '@/components/Common/GenericButton';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimmer, Dropdown, Form, Loader, TextArea } from 'semantic-ui-react';
import classnames from 'classnames';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import ENRICHED_SEARCH_POOL_PROFILE from '@/graphql/enrichedSearchPoolProfile';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useSharedMessagingAccounts from '@/graphql/hooks/clients/useSharedServiceAccount';
import _ from 'underscore';
import {
  GET_MESSAGE_ACCOUNT_CONTACT_RELATION,
  GET_UNIPILE_RECONNECTION_URL,
} from '@/graphql/unipile';
import SimpleDropdown from '@/components/SimpleDropdown';
import Alert from '@/components/Common/Alert/Alert';
import { SERVICE_FROM_SUBTYPE } from '@/containers/Profile/Contact/TimeLineItems/UnipileMessageItem/UnipileMessageItem';
import UnipileConnexionModal from '@/containers/Parameters/AutomationIntegration/UnipileAutomation/UnipileConnexionModal';
import GenericCheckbox from '@/components/Common/GenericCheckbox';
import styles from './UnipileNewMessage.module.less';

export const sendMessageUsingMessagingServiceMutation = gql`
  mutation sendMessageUsingMessagingService(
    $input: SendMessageUsingMessagingServiceInput!
    $searchPoolId: ID!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      sendMessageUsingMessagingService(input: $input) {
        id
        success
        error
      }
    }
  }
`;

interface UnipileNewMessageProps {
  messageType: string;
  profileId: string;
  unipileChatId?: string;
  unipileSenderId?: string;
  onClose?: () => void;
  profileName: string;
  profilePhoneNumber: string;
}

const UnipileNewMessage: React.FC<UnipileNewMessageProps> = ({
  messageType,
  profileId,
  unipileChatId,
  unipileSenderId,
  onClose,
  profileName,
  profilePhoneNumber,
}) => {
  const { t } = useTranslation();
  const notifications = useNotificationSystem();
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [pollingRate, setPollingRate] = useState(0);
  const [hasValidated, setHasValidated] = useState(false);
  const [reconnectModalOpen, setReconnectModalOpen] = useState(false);
  const [sendMessageUsingMessagingService, { loading }] = useMutation(
    sendMessageUsingMessagingServiceMutation,
  );
  const [isSending, setIsSending] = useState(false);
  const [explicitAccountId, setExplicitAccountId] = useState('');
  const [sendAsInMail, setSendAsInMail] = useState(false);
  const [getUnipileReconnectionURL] = useMutation(GET_UNIPILE_RECONNECTION_URL);

  const { sharedMessagingAccounts } = useSharedMessagingAccounts({
    queryOptions: {
      pollInterval: pollingRate,
    },
  });

  const { data: isConnectedData, loading: isConnectedLoading } = useQuery(
    GET_MESSAGE_ACCOUNT_CONTACT_RELATION,
    {
      variables: {
        input: {
          sharedMessagingSenderId: explicitAccountId || unipileSenderId,
          messagingType: messageType,
          profileId,
        },
      },
      skip: _.isEmpty(explicitAccountId || unipileSenderId),
      fetchPolicy: 'cache-first',
    },
  );

  const isConnected =
    isConnectedData?.user?.getMessageAccountContactRelation?.status ===
    'connected';
  const isInvitationPending =
    isConnectedData?.user?.getMessageAccountContactRelation?.status ===
    'pending';

  const handleSendMessage = useCallback(async () => {
    const res = await sendMessageUsingMessagingService({
      variables: {
        input: {
          messagingAccountType: 'unipile',
          messagingSenderId: explicitAccountId || unipileSenderId,
          messagingChatId: unipileChatId,
          messageType,
          profileId,
          body: message,
          subject,
          isConnected,
          ...(sendAsInMail && {
            isInmail: sendAsInMail,
          }),
        },
        searchPoolId: 'reveal',
        skip: !profilePhoneNumber,
      },
      refetchQueries: [ENRICHED_SEARCH_POOL_PROFILE],
    });
    if (
      res?.data?.searchPoolProfile?.sendMessageUsingMessagingService?.success
    ) {
      setIsSending(false);
      if (onClose) {
        onClose();
      }
      notifications.success(
        t(`profile.contact.timeline.newMessage.${messageType}.sendSuccess`),
      );
    } else {
      setIsSending(false);
      notifications.error(
        t(`profile.contact.timeline.newMessage.${messageType}.sendError`),
      );
    }
  }, [
    explicitAccountId,
    message,
    messageType,
    onClose,
    profileId,
    sendMessageUsingMessagingService,
    unipileSenderId,
    unipileChatId,
    notifications,
    t,
    isConnected,
    profilePhoneNumber,
    sendAsInMail,
    subject,
  ]);

  const sharedAccountsOptions = _.map(
    _.filter(
      sharedMessagingAccounts,
      ({ type }) => type === messageType || messageType.includes(type),
    ),
    (account) => ({
      value: account.id,
      label: (
        <div className={styles.dropdownOption}>
          {account.name ||
            (account.owner.firstname
              ? `${account.owner.firstname} ${account.owner.lastname}`
              : account.owner.email)}
        </div>
      ),
    }),
  );

  const linkedinSharedAccounts = useMemo(
    () =>
      _.filter(
        sharedMessagingAccounts,
        (account) => account.type === 'unipile-linkedin',
      ),
    [sharedMessagingAccounts],
  );
  const whatsappSharedAccounts = useMemo(
    () =>
      _.filter(
        sharedMessagingAccounts,
        (account) => account.type === 'unipile-whatsapp',
      ),
    [sharedMessagingAccounts],
  );
  const possibleSharedAccounts = useMemo(
    () =>
      messageType === 'unipile-whatsapp'
        ? whatsappSharedAccounts
        : linkedinSharedAccounts,
    [messageType, linkedinSharedAccounts, whatsappSharedAccounts],
  );

  const onSelectSender = useCallback((id: string) => {
    setExplicitAccountId(id);
  }, []);

  useEffect(() => {
    if ((possibleSharedAccounts || []).length === 1) {
      setExplicitAccountId(possibleSharedAccounts[0].id);
    }
  }, [possibleSharedAccounts, messageType]);

  const selectedSender = useMemo(
    () =>
      _.findWhere(sharedMessagingAccounts, {
        id: explicitAccountId || unipileSenderId,
      }),
    [unipileSenderId, explicitAccountId, sharedMessagingAccounts],
  );

  useEffect(() => {
    if (!selectedSender || !hasValidated) {
      return;
    }

    if (selectedSender.status === 'valid') {
      setHasValidated(true);
      setPollingRate(0);
    }
  }, [hasValidated, selectedSender, sharedMessagingAccounts]);

  const isMessagingAccountValid = useMemo(
    () => selectedSender?.status === 'valid',
    [selectedSender],
  );

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.headerTitleContainer}>
          {messageType === 'unipile-whatsapp' && (
            <i
              className={classnames(styles.whatsapp, 'ri-whatsapp-line ri-lg')}
            />
          )}
          {messageType === 'unipile-linkedin-message' && (
            <i
              className={classnames(styles.linkedin, 'ri-linkedin-fill ri-lg')}
            />
          )}
          <h4>
            {t(
              `profile.contact.timeline.newMessage.${messageType}.header.title`,
            )}
          </h4>
        </div>
        <GenericButton
          disabled={
            !(explicitAccountId || unipileSenderId) ||
            (sendAsInMail && !subject) ||
            loading
          }
          onClick={() => {
            setIsSending(true);
            handleSendMessage();
          }}
        >
          {t('profile.contact.drafts.confirmationModal.send')}
        </GenericButton>
      </div>
      {isConnectedLoading || isSending ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : (
        <>
          {messageType === 'unipile-linkedin-message' && (
            <div>
              <span
                className={styles.checkboxContainer}
                onClick={() => {
                  setSendAsInMail(!sendAsInMail);
                  setSubject('');
                }}
              >
                <GenericCheckbox checked={sendAsInMail} />
                <label className={styles.checkboxLabel}>
                  {t('settings.automations.unipile.sendAsInMail')}
                </label>
              </span>
            </div>
          )}
          {(sharedAccountsOptions || []).length > 1 && (
            <SimpleDropdown
              text={
                selectedSender?.label ||
                selectedSender?.name ||
                t('reveal.sobo.assignSender')
              }
            >
              <Dropdown.Menu>
                {_.map(sharedAccountsOptions, (sender) => (
                  <Dropdown.Item
                    className='sobo-dropdown-item'
                    key={sender.value}
                    text={sender.label}
                    onClick={() => onSelectSender(sender.value)}
                  />
                ))}
              </Dropdown.Menu>
            </SimpleDropdown>
          )}
          {message === 'unipile-whatsapp' && !profilePhoneNumber && (
            <Alert
              title={t('common.warning')}
              description={t(`settings.automations.unipile.emptyPhoneNumber`, {
                contact: profileName,
              })}
            />
          )}
          {selectedSender &&
            !isConnectedLoading &&
            !isConnected &&
            !isInvitationPending &&
            isMessagingAccountValid &&
            messageType === 'unipile-whatsapp' && (
              <Alert
                title={t('common.warning')}
                description={t(
                  `settings.automations.unipile.contactNotConnected.${messageType}`,
                  {
                    contact: profileName,
                  },
                )}
              />
            )}
          {selectedSender &&
            !isConnectedLoading &&
            !isConnected &&
            isInvitationPending &&
            messageType === 'unipile-linkedin-message' && (
              <Alert
                title={t('common.warning')}
                description={t(
                  `settings.automations.unipile.unipileWarnings.invitation-pending`,
                  {
                    contact: profileName,
                  },
                )}
              />
            )}
          {selectedSender && !isMessagingAccountValid && (
            <Alert
              title={t('common.warning')}
              description={t(
                `settings.automations.unipile.unipileWarnings.accountDisconnected`,
                {
                  accountType: SERVICE_FROM_SUBTYPE[selectedSender?.type || ''],
                },
              )}
              level='info'
              hasCta
            >
              <GenericButton
                onClick={() => {
                  getUnipileReconnectionURL({
                    variables: {
                      accountId: selectedSender?.serviceAccountId,
                    },
                  }).then((response) => {
                    const { url, expectedCode: _code } =
                      response?.data?.getUnipileReconnectionLink || {};
                    window.open(url, '_blank');
                    setPollingRate(2000);
                  });
                  setReconnectModalOpen(true);
                }}
                size='small'
              >
                {t('settings.automations.unipile.reconnect')}
              </GenericButton>
            </Alert>
          )}
          <Form className={styles.formContainer}>
            {sendAsInMail && (
              <Form.Input
                // label={t('settings.automations.unipile.subject')}
                placeholder={t(
                  'settings.automations.unipile.subjectPlaceholder',
                )}
                value={subject}
                onChange={(_e, { value }) => setSubject(value as string)}
              />
            )}
            <TextArea
              className={styles.textArea}
              rows={8}
              fluid
              value={message}
              onChange={(_e, { value }) => setMessage(value as string)}
            />
          </Form>
        </>
      )}
      <UnipileConnexionModal
        open={reconnectModalOpen}
        onClose={() => {
          setPollingRate(0);
          setReconnectModalOpen(false);
        }}
        onCancel={() => {
          setReconnectModalOpen(false);
          setPollingRate(0);
          setReconnectModalOpen(false);
        }}
        onConfirm={() => {
          setReconnectModalOpen(false);
          setReconnectModalOpen(false);
        }}
        hasValidated={hasValidated}
        hasErrors={false}
      />
    </div>
  );
};

export default UnipileNewMessage;
