import React from 'react';
import { withApollo } from '@apollo/client/react/hoc';

const withClientSafe = (Component) => {
  const WrappedComponent = (props) => {
    const { client, ...otherProps } = props || {};
    return <Component safeClient={client} {...otherProps} />;
  };
  return WrappedComponent;
};

const withApolloClientSafe = (Component) => {
  const WrappedComponent = (props) => {
    const { client, safeClient, ...otherProps } = props || {};
    return (
      <Component apolloClient={client} client={safeClient} {...otherProps} />
    );
  };
  return withClientSafe(withApollo(WrappedComponent));
};

export default withApolloClientSafe;
