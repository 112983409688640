import React from 'react';
import Switch from 'react-toggle-switch';

import './MailAccountConnect.css';

const MailAccountConnect = ({ onClick, imgSrc, imgAlt, text }) => {
  return (
    <div className='mail-account-connect' onClick={onClick}>
      <img alt={imgAlt} className='mail-account-image' src={imgSrc} height='40' />
      {text}
      <div className='spacer' />
      <Switch onClick={() => null} on={false} className='mail-account-switch' />
    </div>
  );
};

export default MailAccountConnect;
