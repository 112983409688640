import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import { getUserClientMailAccount } from '@/hocs/email/sendEmailQuery';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import GenericButton from '@/components/Common/GenericButton';
import SendEmailButton from './SendEmailButton';

import './MailButton.css';

const MailButton = ({
  profileId,
  searchPoolId,
  clientId,
  actionId,
  sequenceId,
  profile,
  onSend,
  mailData,
  emailApi,
  task,
  assignedSender,
  setClassicSender,
  targetThread,
  shouldUseThread,
  allowSchedule = true,
  userCanSend,
}) => {
  const { t } = useTranslation();
  const isPlugin = useIsPlugin();
  const { isMinimized } = useMinimizedView();
  const { loading: pluginLoading, data: pluginData } = useQuery(
    getUserClientMailAccount,
    {},
  );
  const resolvedUserCanSend =
    userCanSend ||
    (emailApi.currentAddress &&
      emailApi.hasOfflineGrant[emailApi.currentAddress] &&
      !emailApi.connectionInProgress) ||
    assignedSender;

  if (
    resolvedUserCanSend ||
    (isPlugin && (pluginData?.user?.mailAccount ?? false))
  ) {
    return (
      <div className='mail-button ready'>
        <SendEmailButton
          clientId={clientId}
          actionId={actionId}
          sequenceId={sequenceId}
          searchPoolId={searchPoolId}
          profileId={profileId}
          profile={profile}
          onSend={onSend}
          emailApi={emailApi}
          mailData={mailData}
          task={task}
          assignedSender={assignedSender}
          targetThread={targetThread}
          shouldUseThread={shouldUseThread}
          allowSchedule={allowSchedule}
        />
      </div>
    );
  }

  if (
    (emailApi.connectionInProgress && !isPlugin) ||
    (isPlugin && pluginLoading)
  ) {
    return (
      <GenericButton disabled>
        <Loader inline active inverted size='mini' />
        <span>{t('profile.contact.drafts.connectionInProgress')}</span>
      </GenericButton>
    );
  }

  if (isPlugin) {
    return (
      <div className='mail-button'>
        <a
          href={`https://app.hiresweet.com/client/${clientId}/settings`}
          target='blank'
        >
          <GenericButton
            className={`${isMinimized ? 'mini' : 'small'}`}
            onClick={() => {}}
          >
            <div>
              {isMinimized ? (
                <i className='ri-mail-settings-line ri-lg' />
              ) : (
                t('profile.contact.drafts.configure')
              )}
            </div>
          </GenericButton>
        </a>
      </div>
    );
  }

  return (
    <div className='mail-button'>
      <GenericButton
        className={`${isMinimized ? 'mini' : 'small'}`}
        onClick={() => emailApi.onTriggerOfflineRegister('gmail')}
      >
        <div>{t('profile.contact.drafts.gmailConnectShort')}</div>
      </GenericButton>
      <GenericButton
        className={`${isMinimized ? 'mini' : 'small'}`}
        onClick={() => {
          emailApi.onTriggerOfflineRegister('outlook', 'select_account');
          setClassicSender();
        }}
      >
        <div>{t('profile.contact.drafts.outlookConnectShort')}</div>
      </GenericButton>
    </div>
  );
};

export default MailButton;
