import React from 'react';
import classNames from 'classnames';

import './OAuthSigninButtons.css';

const OAuthSigninButton = ({ className, title, text, logoSrc, ...props }) => (
  <div
    className={classNames('oauth-signin-button', className)}
    title={title}
    {...props}
  >
    <img className='provider-logo' src={logoSrc} alt={title}/>
    <span className='button-text'>{text}</span>
  </div>
);

export default OAuthSigninButton;
