import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import {
  isEmptyRawLocationAndRemoteCriteria,
  locationsAndRemoteFromRaw,
} from '@/common/helpers/locationAndRemote';
import schoolTypeOptions from '../../../common/options/schoolTypeOptions.json';
import skillOptions from '../../../common/options/skillOptions.json';
import jobPositionOptions from '../../../common/options/jobPositionOptions.json';
import contractOptions from '../../../common/options/contractOptions.json';
import { XP_YEAR_MAX } from '../SearchTopBar/ExperienceYearsCriterionPopup';
import {
  filterHasNoEffect,
  RESPONSIBILITIES,
} from '../WatchCollectFiltersModal';
import { getLocationsAndRemoteOptions } from '../helpers';

import './SearchFilters.css';

const formatExperienceLabel = ({
  name,
  t,
  min,
  max,
  defaultMin,
  defaultMax,
}) => {
  const minimum = min || defaultMin;
  const maximum = max || defaultMax;
  const options = { minimum, maximum };

  // This case should not happen but still.
  if (minimum === defaultMin && maximum === defaultMax) {
    return t(`watchCollect.filters.${name}.label`, options);
  }
  if (minimum === defaultMin) {
    return t(`watchCollect.filters.${name}.labelInf`, options);
  }
  if (maximum === defaultMax) {
    return t(`watchCollect.filters.${name}.labelSup`, options);
  }
  return t(`watchCollect.filters.${name}.label`, options);
};

const SearchFilters = ({ filtersState, onClickLabel, onClickResetAll }) => {
  const { t } = useTranslation();
  const {
    jobPositions,
    salaryMin,
    salaryMax,
    experienceYearsMin,
    experienceYearsMax,
    skills,
    locationsAndRemote,
    schoolTypes,
    contracts,
    jobPositionsOperator,
    skillsOperator,
    remoteOnly,
    responsibilities,
    excludeHidden,
    shownInterestOnly,
    selectedCategory,
  } = filtersState?.values || {};

  const { pathname, search } = useLocation();
  const queryObject = qs.parse(search.replace('?', ''));
  const shownInterestOnlyParam = JSON.parse(
    queryObject.shownInterestOnly || 'false',
  );

  const locationsAndRemoteValues = _.map(
    locationsAndRemoteFromRaw(locationsAndRemote),
    ({ locationId, remotePolicy }) => `${locationId}__${remotePolicy}`,
  );

  const locationAndRemoteOptions = getLocationsAndRemoteOptions(t);

  const removeShownInterestOnlyParamOr = (callback) => () => {
    if (!shownInterestOnlyParam) {
      if (callback) {
        callback();
      }
      return;
    }
    const newSearch = qs.stringify({
      ...queryObject,
      shownInterestOnly: undefined,
    });
    window.location.href = newSearch ? `${pathname}?${newSearch}` : pathname;
  };

  const filtersStateEmpty = areFiltersEmpty({ filters: filtersState?.values });
  return (
    <div className='search-filters'>
      {selectedCategory === 'new-next-week' && (
        <FilterLabel
          text="Next week's new"
          onClose={() => filtersState.setSelectedCategory('all')}
          onClick={() => onClickLabel('selectedCategory')}
        />
      )}

      <ItemsFilterLabel
        items={jobPositions}
        setItems={filtersState.setJobPositions}
        options={jobPositionOptions}
        onClickLabel={() => onClickLabel('jobPositions')}
        operator={t(`common.${jobPositionsOperator}`)}
        otherText={t('common.other', { count: 1 })}
      />

      <RangeFilterLabel
        name='salary'
        minValue={salaryMin}
        maxValue={salaryMax}
        defaultMinValue={30}
        defaultMaxValue={150}
        setMinValue={filtersState.setSalaryMin}
        setMaxValue={filtersState.setSalaryMax}
        onClickLabel={() => onClickLabel('salary')}
        t={t}
      />

      {!filterHasNoEffect({ responsibilities }) && (
        <FilterLabel
          text={getResponsibilitiesLabel({ responsibilities, t })}
          onClose={() => filtersState.setResponsibilities(RESPONSIBILITIES)}
          onClick={() => onClickLabel('responsibilities')}
        />
      )}

      <RangeFilterLabel
        name='experienceYears'
        minValue={experienceYearsMin}
        maxValue={experienceYearsMax}
        defaultMinValue={0}
        defaultMaxValue={XP_YEAR_MAX}
        setMinValue={filtersState.setExperienceYearsMin}
        setMaxValue={filtersState.setExperienceYearsMax}
        formatLabel={formatExperienceLabel}
        onClickLabel={() => onClickLabel('experienceYears')}
        t={t}
      />

      <ItemsFilterLabel
        items={skills}
        setItems={filtersState?.setSkills}
        options={skillOptions}
        onClickLabel={() => onClickLabel('skills')}
        operator={t(`common.${skillsOperator}`)}
        otherText={t('common.other', { count: 1 })}
      />

      <ItemsFilterLabel
        items={locationsAndRemoteValues}
        setItems={filtersState?.setLocationsAndRemote}
        options={locationAndRemoteOptions}
        onClickLabel={() => onClickLabel('locations')}
        otherText={t('common.other', { count: 1 })}
      />

      <ItemsFilterLabel
        items={schoolTypes}
        setItems={filtersState?.setSchoolTypes}
        options={_.map(schoolTypeOptions, (schoolTypeOption) => ({
          ...schoolTypeOption,
          text: t(
            `watchCollect.filters.schoolTypes.options.${schoolTypeOption.value}`,
          ),
        }))}
        onClickLabel={() => onClickLabel('schoolTypes')}
        otherText={t('common.other', { count: 1 })}
      />

      <ItemsFilterLabel
        items={contracts}
        setItems={filtersState?.setContracts}
        options={contractOptions}
        onClickLabel={() => onClickLabel('contracts')}
        otherText={t('common.other', { count: 1 })}
      />

      {excludeHidden === false && (
        <FilterLabel
          text={t('watchCollect.filters.hiddenProfiles.included')}
          onClose={() => filtersState.setExcludeHidden(true)}
          onClick={() => onClickLabel('excludeProfiles')}
        />
      )}

      {shownInterestOnly === true && (
        <FilterLabel
          text={t('watchCollect.filters.shownInterest.included')}
          onClose={removeShownInterestOnlyParamOr(() => {
            filtersState.setShownInterestOnly(false);
          })}
          onClick={() => onClickLabel('shownInterestOnly')}
        />
      )}

      {remoteOnly === true && (
        <FilterLabel
          text={t('watchCollect.filters.remoteOnly.label')}
          onClose={() => filtersState.setRemoteOnly(false)}
          onClick={() => onClickLabel('locations')}
        />
      )}

      {!filtersStateEmpty && (
        <Button
          className='reset-all-button headline-5'
          content={t('watchCollect.filters.resetAll')}
          onClick={removeShownInterestOnlyParamOr(onClickResetAll)}
        />
      )}
    </div>
  );
};

const getResponsibilitiesLabel = ({ responsibilities, t }) => {
  const sortedResponsibilities = _.sortBy(responsibilities, (level) => {
    return _.findIndex(RESPONSIBILITIES, level);
  });

  if (_.isEqual(sortedResponsibilities, ['lead', 'normal'])) {
    return t('watchCollect.filters.responsibilities.labels.normalLead');
  }
  if (_.isEqual(sortedResponsibilities, ['director', 'normal'])) {
    return t('watchCollect.filters.responsibilities.labels.normalDirector');
  }
  if (_.isEqual(sortedResponsibilities, ['normal'])) {
    return t('watchCollect.filters.responsibilities.labels.normal');
  }
  if (_.isEqual(sortedResponsibilities, ['lead', 'director'])) {
    return t('watchCollect.filters.responsibilities.labels.leadDirector');
  }
  if (_.isEqual(sortedResponsibilities, ['lead'])) {
    return t('watchCollect.filters.responsibilities.labels.lead');
  }
  if (_.isEqual(sortedResponsibilities, ['director'])) {
    return t('watchCollect.filters.responsibilities.labels.director');
  }
  return null;
};

export const areFiltersEmpty = ({ filters }) => {
  const {
    jobPositions,
    salaryMin,
    salaryMax,
    experienceYearsMin,
    experienceYearsMax,
    skills,
    locations,
    locationsAndRemote,
    schoolTypes,
    contracts,
    responsibilities,
    remoteOnly,
    shownInterestOnly,
  } = filters || {};

  return (
    _.isEmpty(jobPositions) &&
    _.isEmpty(skills) &&
    _.isEmpty(locations) &&
    isEmptyRawLocationAndRemoteCriteria(locationsAndRemote) &&
    _.isEmpty(schoolTypes) &&
    _.isEmpty(contracts) &&
    filterHasNoEffect({ responsibilities }) &&
    !salaryMin &&
    !salaryMax &&
    !experienceYearsMin &&
    !experienceYearsMax &&
    !shownInterestOnly &&
    remoteOnly !== true
  );
};

const ItemsFilterLabel = ({
  items,
  setItems,
  options,
  onClickLabel,
  operator,
  otherText,
}) => {
  const getItemText = (item) => _.findWhere(options, { value: item })?.text;
  const itemsTexts = _.compact(_.map(items, getItemText));
  const text = getItemsListLabel({ itemsTexts, operator, otherText });
  return (
    <FilterLabel
      text={text}
      onClose={() => setItems([])}
      onClick={onClickLabel}
    />
  );
};

const RangeFilterLabel = ({
  name,
  minValue,
  defaultMinValue,
  setMinValue,
  maxValue,
  defaultMaxValue,
  setMaxValue,
  onClickLabel,
  formatLabel,
  t,
}) => {
  const isApplied = _.isNumber(minValue) || _.isNumber(maxValue);

  if (!isApplied) {
    return null;
  }

  const reset = () => {
    setMinValue('');
    setMaxValue('');
  };

  const label =
    formatLabel?.({
      name,
      t,
      min: minValue,
      max: maxValue,
      defaultMin: defaultMinValue,
      defaultMax: defaultMaxValue,
    }) ||
    t(`watchCollect.filters.${name}.label`, {
      minimum: Math.floor(minValue || defaultMinValue),
      maximum: Math.floor(maxValue || defaultMaxValue),
    });

  return <FilterLabel text={label} onClose={reset} onClick={onClickLabel} />;
};

const getItemsListLabel = ({ itemsTexts, operator = '', otherText }) => {
  if (_.isEmpty(itemsTexts)) {
    return null;
  }
  const firstTwoItems = _.first(itemsTexts, 2);
  if (itemsTexts.length <= 2) {
    return firstTwoItems.join(operator ? `, ${operator} ` : ', ');
  }
  return `${firstTwoItems.join(', ')}, ${operator} ${itemsTexts.length -
    2} ${otherText}${itemsTexts.length - 2 !== 1 ? 's' : ''}`;
};

const FilterLabel = ({ text, onClick, onClose }) => {
  if (!text) {
    return null;
  }

  const onClickClose = (e) => {
    if (e) {
      e.stopPropagation();
    }
    onClose();
  };

  return (
    <Button className='filter-label' onClick={onClick}>
      <span className='text'>{text}</span>
      <i className='ri-close-line' onClick={onClickClose} role='button' />
    </Button>
  );
};

export default SearchFilters;
