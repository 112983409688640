import React, { useState, useEffect } from 'react';
import _ from 'underscore';
import { Dropdown, Form, Input, TextArea } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
// import { useQuery } from '@apollo/client';
import {
  canHaveHtmlMessage,
  getActionType,
  isEmailAction,
  LINKEDIN_MESSAGE_SUBTYPES,
  TASK_TITLE_BY_TYPE,
  TASK_TYPES,
  getActionFieldsForType,
} from '@/common/constants/taskTypes';
import {
  EMAIL_TYPE_OPTIONS,
  useClientTaskOptions,
} from '@/revealComponents/FullContactFlowEditor/FullContactFlowActionEditor/helpers';
import createActionInputFromAction from '@/common/contactFlow/createActionInputFromAction';
import useUpdateContactFlowAction from '@/graphql/hooks/searchPoolProfile/useUpdateContactFlowAction';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { TranslatableText as TranslatableTextType } from '@/types/text';
import { cleanString } from '@/common/detectSuspiciousEmailContent';
import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
// import { getUserClientMailAccount } from '@/hocs/email/sendEmailQuery';
import GenericButton from '@/components/Common/GenericButton';
import TriggerEditor, {
  getCanonicalTrigger,
} from '@/revealComponents/FullContactFlowEditor/FullContactFlowActionEditor/TriggerEditor';
import { sanitizeTypename } from '@/common/utils/apollo';
import useRevealEmailAction, {
  RevealSenderType,
} from '../../Actions/EmailAction/useRevealEmailAction';
// import SenderAndRecipient from '../../Actions/EmailAction/SenderAndRecipient';
import { formatTaskOrActionMessage } from '../../helpers';
import ActionEmailEditor, { ActionMessage } from './ActionEmailEditor';
import getPermissionsAndNudgesAtIndex from '../../../../../../common/contactFlow/getPermissionsAndNudgesAtIndex';
import ActionItemContainer from './ActionItemContainer';

interface ActionItemEditorProps {
  isLast: boolean;
  action: any;
  actions: any;
  index: number;
  onClose: () => void;
  canChangeType: boolean;
  profileId: string;
  sequenceId: string;
  clientId: string;
  profile: SearchPoolProfile;
  nextEmailActionIds: any[];
  onBulkApplyCcBccToActions: ({
    actionIds,
    cc,
    bcc,
  }: {
    actionIds: string[];
    cc: string[];
    bcc: [];
  }) => void;
}

const ActionItemEditor: React.FC<ActionItemEditorProps> = ({
  action,
  actions,
  profileId,
  sequenceId,
  clientId,
  index,
  isLast,
  onClose,
  canChangeType,
  profile,
  nextEmailActionIds,
  onBulkApplyCcBccToActions,
}) => {
  const { t } = useTranslation();
  const { profileCustomFields: customFields } =
    useClientProfileCustomFields(clientId);
  const [selectedType, setSelectedType] = useState<string>(
    getActionType(action),
  );
  const [messageSubtype, setMessageSubtype] = useState<string>('');
  const originalTrigger = action.trigger;
  const [triggerState, setTriggerState] = useState(action.trigger);
  const [messageState, setMessageState] = useState<ActionMessage>({
    subject: '',
    body: '',
    cc: action?.message?.cc || [],
    bcc: action?.message?.bcc || [],
    snippets: [],
  });

  const [cc, setCc] = useState(action?.message?.cc || []);
  const [bcc, setBcc] = useState(action?.message?.bcc || []);
  // const { data: pluginUserData } = useQuery(getUserClientMailAccount, {});
  const {
    // emitter,
    assignedSender,
    // canChooseEmitter,
    // setClassicSender,
    // setSoboSender,
    // senders,
    // revealSenderType,
  } = useRevealEmailAction(profile, {
    ...(action?.message?.senderId && {
      initialRevealSender: {
        senderId: action.message.senderId,
        type: RevealSenderType.Sobo,
      },
    }),
  });

  const { options: availableActionOptions } = useClientTaskOptions({
    currentAction: action,
  });

  useEffect(() => {
    if (!action) {
      return;
    }

    setMessageState({
      subject: action.message?.subject || '',
      body: action.message?.body || '',
      cc: cc || [],
      bcc: bcc || [],
      snippets: action.snippets || [],
    });
  }, [action, customFields, cc, bcc]);

  // watch external cc change (GraphQl external update)
  const ccStringFromActionProp = (action?.message?.cc || []).join(';');
  useEffect(() => {
    setCc(action?.message?.cc || []);
    // eslint-disable-next-line
  }, [ccStringFromActionProp]);

  // watch external cc change (GraphQl external update)
  const bccStringFromActionProp = (action?.message?.bcc || []).join(';');
  useEffect(() => {
    setBcc(action?.message?.bcc || []);
    // eslint-disable-next-line
  }, [bccStringFromActionProp]);

  const [descriptionState, setDescriptionState] =
    useState<TranslatableTextType>(action.description);
  const [titleState, setTitleState] = useState<TranslatableTextType>(
    action.title,
  );
  const { onShowNotification } = useNotificationSystem();

  const [updateContactFlowAction, { loading: updateLoading }] =
    useUpdateContactFlowAction();

  const futureAction = {
    ...action,
    type: selectedType,
    trigger: triggerState,
  };

  const isMail = selectedType?.indexOf('send-email-') >= 0;

  const actionFields = getActionFieldsForType(selectedType) as Record<
    string,
    boolean
  >;

  const wouldBeFirstEmail =
    _.findIndex(actions, (act) => isEmailAction(act)) >= index;
  const isFirstEmail = isEmailAction(futureAction) && wouldBeFirstEmail;

  const showSubject =
    actionFields?.subject && (!isEmailAction(action) || isFirstEmail);

  const taskTitle: string =
    TASK_TITLE_BY_TYPE[selectedType as 'send-email-manual'];

  const onSave = async () => {
    try {
      const message = formatTaskOrActionMessage(
        messageState,
        selectedType,
        assignedSender?.id,
      );

      const newAction = {
        ..._.omit(action, 'originalTrigger'),
        trigger: triggerState,
        type: selectedType,
        ...(messageSubtype && { subtype: messageSubtype }),
        description: descriptionState,
        title: titleState,
        message: {
          ...message,
          ...((selectedType || '').indexOf('email') >= 0 && {
            senderId: action.senderId,
          }),
        },
        snippets: messageState.snippets,
      };
      const contactFlowActionInput = createActionInputFromAction({
        action: _.omit(newAction, 'nbManualSnoozes', 'snoozeConfiguration'),
      });
      const input = {
        id: profileId,
        sequenceId,
        actionId: action.actionId,
        actionInput: contactFlowActionInput,
        ...(!_.isEmpty(newAction.snippets) && {
          snippets: MergeTagsService.createSnippetsInput({
            subject: messageState.subject || '',
            body: messageState.body || '',
            snippets: newAction.snippets,
          }),
        }),
      };
      await updateContactFlowAction({
        variables: { searchPoolId: 'reveal', input: sanitizeTypename(input) },
      });
      onShowNotification({
        level: 'success',
        message: t('reveal.candidatesView.timeline.editSuccess'),
      });
      onClose();
    } catch (e) {
      console.error(e);
      onShowNotification({
        level: 'error',
        message: t('reveal.candidatesView.timeline.editError'),
      });
    }
  };

  const handleActionTypeChange = (value: string) => {
    setSelectedType(
      value === 'send-email-action' && wouldBeFirstEmail
        ? 'send-email-manual'
        : (value as string),
    );
    if (value === TASK_TYPES.LINKEDIN_RECRUITER_INMAIL) {
      setMessageSubtype(LINKEDIN_MESSAGE_SUBTYPES.RECRUITER);
    }
    if (value === TASK_TYPES.LINKEDIN_SALESNAVIGATOR_INMAIL) {
      setMessageSubtype(LINKEDIN_MESSAGE_SUBTYPES.SALESNAVIGATOR);
    }
    if (value === TASK_TYPES.LINKEDIN_PREMIUM_INMAIL) {
      setMessageSubtype(LINKEDIN_MESSAGE_SUBTYPES.PREMIUM);
    }
    if (value === TASK_TYPES.LINKEDIN_SEND_MESSAGE) {
      setMessageSubtype(LINKEDIN_MESSAGE_SUBTYPES.CLASSIC);
    }
    const canonicalTrigger = getCanonicalTrigger({
      trigger: triggerState,
      actionType: value,
    });
    if (!_.isEqual(canonicalTrigger, triggerState)) {
      setTriggerState(canonicalTrigger);
    }
  };

  const emailHasBody = isEmailAction({ type: selectedType })
    ? !!messageState.body && !!cleanString(messageState.body)
    : true;

  const areSameEmailsLists = (emailsList1: string[], emailsList2: string[]) =>
    (emailsList1 || []).join(';') === (emailsList2 || []).join(';');

  const hasFollowingEmailActionsWithDifferentCcBcc = _.some(
    (actions || []).slice(index + 1),
    (nextAction) =>
      isEmailAction(nextAction) &&
      (!areSameEmailsLists(
        [...(nextAction.message?.cc || [])].sort(),
        [...cc].sort(),
      ) ||
        !areSameEmailsLists(
          [...(nextAction.message?.bcc || [])].sort(),
          [...bcc].sort(),
        )),
  );

  return (
    <ActionItemContainer
      isBox
      action={{ ...action, type: selectedType }}
      isLast={isLast}
      withIcon={false}
    >
      <h4 className='action-item-editor-title'>
        {t('reveal.candidatesView.timeline.editNextActivity')}
      </h4>
      <Form className='action-item-editor'>
        <Form.Field className='action-type-editor'>
          <Dropdown
            selection
            text={taskTitle}
            onChange={(e, { value }) => handleActionTypeChange(value as string)}
            options={availableActionOptions.map((option) => ({
              ...option,
              text: t(`sequences.tasks.${option.type}`, option.text),
            }))}
            disabled={!canChangeType || updateLoading}
          />
          {isEmailAction(futureAction) && (
            <Dropdown
              className='email-type-dropdown'
              selection
              value={selectedType}
              onChange={(e, { value }) => {
                setSelectedType(value as string);
              }}
              options={EMAIL_TYPE_OPTIONS(t)}
              disabled={isFirstEmail || updateLoading}
            />
          )}
          {!canChangeType && (
            <div className='action-type-editor-info'>
              {t('reveal.candidatesView.timeline.blockEmailTypeChange')}
            </div>
          )}
        </Form.Field>
        <Form.Field>
          <TriggerEditor
            permissionsAndNudges={getPermissionsAndNudgesAtIndex({
              actions: _.map(actions, (actionAtIndexI, i) =>
                i === index ? futureAction : actionAtIndexI,
              ),
              index,
              editType: 'update',
              t,
            })}
            action={{ ...action, originalTrigger, trigger: triggerState }}
            onUpdate={({ action: newAction }: { action: any }) => {
              setTriggerState(newAction.trigger);
            }}
            disabled={updateLoading}
          />
        </Form.Field>
        {isMail && (
          <Form.Field>
            {/*
            <SenderAndRecipient
              clientId={clientId}
              revealSenderType={revealSenderType}
              from={emitter}
              canChooseEmitter={canChooseEmitter}
              senders={senders}
              profile={profile}
              pluginUserData={pluginUserData}
              setClassicSender={setClassicSender}
              setSoboSender={setSoboSender}
              bcc={bcc}
              setBcc={setBcc}
              cc={cc}
              setCc={setCc}
              t={t}
              allowSelectSharedSender
            />
            */}
            {hasFollowingEmailActionsWithDifferentCcBcc &&
              (!_.isEmpty(cc) || !_.isEmpty(bcc)) && (
                <span
                  className='apply-to-next-mails'
                  onClick={() => {
                    onBulkApplyCcBccToActions({
                      actionIds: nextEmailActionIds,
                      cc,
                      bcc,
                    });
                  }}
                  onKeyPress={() => {
                    onBulkApplyCcBccToActions({
                      actionIds: nextEmailActionIds,
                      cc,
                      bcc,
                    });
                  }}
                  role='button'
                >
                  {t('reveal.candidatesView.timeline.applyToFollowingMails')}
                </span>
              )}
          </Form.Field>
        )}
        {actionFields.title && (
          <Form.Field>
            <Input
              value={titleState?.default || ''}
              onChange={(e, { value }) =>
                setTitleState({ default: value as string })
              }
              placeholder={t('reveal.candidatesView.timeline.title')}
            />
          </Form.Field>
        )}

        {actionFields.description && (
          <Form.Field>
            <TextArea
              style={{ minHeight: 200 }}
              value={descriptionState?.default || ''}
              onChange={(e, { value }) =>
                setDescriptionState({ default: value as string })
              }
              placeholder={t('reveal.candidatesView.timeline.description')}
            />
          </Form.Field>
        )}

        {actionFields?.message && (
          <Form.Field className='position-relative'>
            <ActionEmailEditor
              clientId={clientId}
              messageState={messageState}
              setMessageState={setMessageState}
              hideMenu={!canHaveHtmlMessage({ type: selectedType })}
              isFirstEmail={!!showSubject}
            />
          </Form.Field>
        )}
        <div className='action-item-editor-buttons'>
          <GenericButton
            primacy='secondary'
            onClick={onClose}
            disabled={updateLoading}
          >
            {t('common.cancel')}
          </GenericButton>
          <GenericButton
            onClick={onSave}
            disabled={updateLoading || !emailHasBody}
          >
            {t('common.save')}
          </GenericButton>
        </div>
      </Form>
    </ActionItemContainer>
  );
};

export default ActionItemEditor;
