import React, { CSSProperties, FC } from 'react';
import _ from 'underscore';

import { ProfileColumn } from '@/components/ProfileRow/types';
import useSearchPoolMiniProfileById from '@/graphql/hooks/profiles/useSearchPoolMiniProfileById';

import styles from './ProfileRow.module.less';

interface ProfileRowProps {
  profileId: string;
  columns: {
    id: string;
    component: ProfileColumn;
    className?: string;
    style?: CSSProperties;
  }[];
}

const ProfileRow: FC<ProfileRowProps> = ({ profileId, columns }) => {
  const { profile, loading } = useSearchPoolMiniProfileById({
    searchPoolId: 'reveal',
    profileId,
  });

  return (
    <div className={styles.row}>
      {_.map(columns, ({ style, className, component: Component, id }) => (
        <Component
          key={id}
          profile={profile}
          profileLoading={loading}
          className={className}
          style={style}
        />
      ))}
    </div>
  );
};

export default ProfileRow;
