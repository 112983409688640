import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

const generateRecommendedProfiles = gql`
  mutation generateRecommendedMissions($searchPoolId: ID!, $missionId: ID!) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      generateRecommendedMissions(missionId: $missionId) {
        id
      }
    }
  }
`;

export function useGenerateRecommendedProfilesMutation(
  searchPoolId = 'reveal',
) {
  const [mutate, state] = useMutation(generateRecommendedProfiles, {
    refetchQueries: ['getRecommendedProfiles'],
  });
  return [
    (missionId: string) =>
      mutate({
        variables: { searchPoolId, missionId },
      }),
    state,
  ] as const;
}

const addToMissionAsRecommended = gql`
  mutation addToMissionAsRecommended(
    $searchPoolId: ID!
    $missionId: ID!
    $profileId: ID!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      addToMissionAsRecommended(profileId: $profileId, missionId: $missionId) {
        id
      }
    }
  }
`;

export function useAddToMissionAsRecommendedMutation(searchPoolId = 'reveal') {
  const [mutate, state] = useMutation(addToMissionAsRecommended, {
    refetchQueries: ['getRecommendedProfiles'],
  });
  return [
    (profileId: string, missionId: string) =>
      mutate({
        variables: { searchPoolId, missionId, profileId },
      }),
    state,
  ] as const;
}
