import React from 'react';

import NewTemplate from '@/containers/Parameters/Templates/modals/NewTemplate';
import NewProfileModal from '@/containers/Profile/modals/newProfileModal';
import NewSequenceModal from '@/containers/Parameters/Sequences/NewSequenceModal';
import AddUserModal from '@/components/modals/AddUserModal';
import NewJobModal from '@/components/NewJobModal';

export default function QuickActionsModals({ modals, clientId }) {
  return (
    <>
      <NewProfileModal
        clientId={clientId}
        open={modals.newProfileOpen}
        onClose={() => modals.setNewProfileOpen(false)}
      />
      <NewSequenceModal
        clientId={clientId}
        open={modals.newSequenceOpen}
        onClose={() => modals.setNewSequenceOpen(false)}
        editionMode={false}
      />
      <NewTemplate
        clientId={clientId}
        open={modals.newTemplateOpen}
        onClose={() => modals.setNewTemplateOpen(false)}
        onPostSubmit={() => modals.setNewTemplateOpen(false)}
      />
      <AddUserModal
        clientId={clientId}
        newCollaboratorOpen={modals.newCollaboratorOpen}
        setNewCollaboratorOpen={modals.setNewCollaboratorOpen}
      />
      <NewJobModal
        open={modals.newJobOpen}
        setNewJobModalOpen={modals.setNewJobOpen}
        searchPoolId='reveal'
        clientId={clientId}
      />
    </>
  );
}
