import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const query = gql`
  query getOfferParams($id: ID!) {
    offer(id: $id) {
      id
      manualStepsAllowed
      criteriaEditionAllowed
      unlimitedFlowBatchSize
      isHiresweetWatch
      isUnclassified
      isAutopilot
      pushFlowPolicy {
        nbPerDay
      }
      pipeStepStats {
        stepId
        count
        pipeSubStepStats {
          subStepId
          count
        }
      }
    }
  }
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.offerId,
    },
  }),
  props: ({ data: { loading, error, offer } }) => ({
    loading,
    error,
    offer,
  }),
});
