import {
  FetchResult,
  gql,
  MutationHookOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import { SharingSettings, Scope, SavedSearch } from '@/types/savedSearch';
import RevealSavedSearchFragment from '@/graphql/fragments/RevealSavedSearch';

const UPDATE_SAVED_SEARCH = gql`
  mutation updateSavedSearch($input: UpdateRevealSavedSearchInput!) {
    revealSavedSearch {
      update(input: $input) {
        revealSavedSearch {
          ...RevealSavedSearch
        }
      }
    }
  }
  ${RevealSavedSearchFragment}
`;

interface UpdateSavedSearchData {
  revealSavedSearch: {
    update: {
      revealSavedSearch: SavedSearch;
    };
  };
}

interface UpdateSavedSearchVariables {
  input: {
    id: string;
    title?: string;
    sharingSettings?: SharingSettings;
    scope?: Scope;
    criteria?: {
      free?: string;
    };
  };
}

interface UseUpdateSavedSearchInput {
  mutationOptions?: MutationHookOptions<
    UpdateSavedSearchData,
    UpdateSavedSearchVariables
  >;
}

type UseUpdateSavedSearchResult = [
  (
    input: UpdateSavedSearchVariables['input'],
  ) => Promise<FetchResult<UpdateSavedSearchData>>,
  MutationResult<UpdateSavedSearchData>,
];

const useUpdateSavedSearch = ({
  mutationOptions = {},
}: UseUpdateSavedSearchInput = {}): UseUpdateSavedSearchResult => {
  const [mutation, result] = useMutation<
    UpdateSavedSearchData,
    UpdateSavedSearchVariables
  >(UPDATE_SAVED_SEARCH, {
    ...mutationOptions,
  });

  return [(input) => mutation({ variables: { input } }), result];
};

export default useUpdateSavedSearch;
