import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { Department } from '../../graphql/fragments/Department';

export const updateClientDepartment = gql`
  mutation updateClientDepartment(
    $department: ClientDepartmentInput!
    $departmentId: ID!
  ) {
    updateClientDepartment(
      department: $department
      departmentId: $departmentId
    ) {
      id
      departments {
        ...Department
      }
    }
  }
  ${Department}
`;

export default graphql(updateClientDepartment, {
  props: ({ mutate }) => ({
    updateClientDepartment: ({ department, departmentId }) =>
      mutate({
        variables: {
          department,
          departmentId,
        },
      }),
  }),
});
