import React, { useState } from 'react';
import { compose } from 'underscore';
import { Form, Input } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

import GenericButton from '@/components/Common/GenericButton';
import { isEmail } from '../../../common';
import contextToProps from '../../../hocs/contextToProps';

import './CollaboratorInvitationForm.css';

const CollaboratorInvitationForm = ({ addUser, onShowNotification, t }) => {
  const [email, setEmail] = useState('');
  const [sendingInvite, setSendingInvite] = useState(false);

  const sendInvite = () => {
    setSendingInvite(true);
    const lowerCaseEmail = email.toLowerCase();
    if (isEmail(lowerCaseEmail)) {
      addUser({
        email: lowerCaseEmail,
      }).then(() => {
        onShowNotification({
          message: t('collaborators.notifications.inviteSend'),
          level: 'success',
        });
        setSendingInvite(false);
        setEmail('');
      });
    } else {
      setSendingInvite(false);
    }
  };

  return (
    <div className='send-invite-form-2'>
      <Form className='settings-row'>
        <Input
          type='email'
          placeholder={t('collaborators.pendingSection.email')}
          name='email'
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
          autoFocus
        />

        <GenericButton
          type='submit'
          onClick={sendInvite}
          disabled={sendingInvite}
        >
          {t('collaborators.pendingSection.submit')}
        </GenericButton>
      </Form>
    </div>
  );
};

export default compose(
  contextToProps,
  withTranslation('translations'),
)(CollaboratorInvitationForm);
