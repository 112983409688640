import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import RevealProject from '../../graphql/fragments/RevealProject';

export const createGreenhouseConnectorMutation = gql`
  mutation createGreenhouseConnector($input: CreateGreenhouseConnectorInput) {
    createGreenhouseConnector(input: $input) {
      client {
        id
        revealProjects {
          ...RevealProject
        }
      }
    }
  }
  ${RevealProject}
`;

export default graphql(createGreenhouseConnectorMutation, {
  props: ({ mutate }) => ({
    createGreenhouseConnector: ({ input }) => {
      const variables = { input };
      return mutate({ variables });
    },
  }),
});
