import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';

import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';

interface Props {
  open: boolean;
  onCancel: () => void;
  message?: string;
  translationId?: string;
}

const WarningModal: React.FC<Props> = ({
  open,
  message,
  translationId,
  onCancel,
}) => {
  const { t } = useTranslation();
  return (
    <GenericModal
      className='warning-modal'
      open={open}
      onClose={onCancel}
      size='mini'
    >
      <Modal.Header>{t('common.warning')}</Modal.Header>
      <Modal.Content>
        <div className='message'>
          <span>{translationId ? t(translationId) : message}</span>
        </div>
      </Modal.Content>
      <Modal.Actions className='align-center'>
        <GenericButton size='big' onClick={() => onCancel()}>
          OK
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default WarningModal;
