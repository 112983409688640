import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SearchPoolProfileState from '../../graphql/fragments/SearchPoolProfileState';

export const markHiddenMultiple = gql`
  mutation markHiddenMultiple($searchPoolId: ID!, $profileIds: [ID!]!) {
    markHiddenMultiple(searchPoolId: $searchPoolId, profileIds: $profileIds) {
      ...SearchPoolProfileState
    }
  }
  ${SearchPoolProfileState}
`;

export default graphql(markHiddenMultiple, {
  props: ({ mutate }) => ({
    markHiddenMultiple: ({ searchPoolId, profileIds }) =>
      mutate({
        variables: { searchPoolId, profileIds },
      }),
  }),
});
