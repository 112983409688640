import React from 'react';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import GenericModal from '@/components/Common/GenericModal';

import GenericButton from '@/components/Common/GenericButton';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import styles from './UnsyncFragmentModal.module.less';

const UnsyncFragmentModal = ({ open, onClose, handleUnsyncFragment }) => {
  const { t } = useTranslation();
  const isPlugin = useIsPlugin();

  const handleSubmit = () => {
    handleUnsyncFragment();
  };

  return (
    <GenericModal
      size='tiny'
      open={open}
      onClose={onClose}
      className={styles.unlinkFragmentModal}
    >
      <Modal.Header>{t('editor.unsyncFragment.modal.title')}</Modal.Header>
      <Modal.Content>
        <div
          className={classNames(
            styles.wrapper,
            isPlugin && styles.pluginWrapper,
          )}
        >
          <p className={styles.content}>
            {t('editor.unsyncFragment.modal.content')}
          </p>
          <p className={styles.warning}>
            {t('editor.unsyncFragment.modal.warning')}
          </p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <GenericButton type='button' primacy='secondary' onClick={onClose}>
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          type='submit'
          onClick={handleSubmit}
          className={styles.unlinkBtn}
        >
          {t('editor.unsyncFragment.unsync')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default UnsyncFragmentModal;
