import React from 'react';
import { Detector } from 'react-detect-offline';

const withNetworkStatus = (WrappedComponent) => (props) => (
  <Detector
    render={({ online }) => (
      <WrappedComponent {...props} isOnlineNetworkStatus={online} />
    )}
  />
);

export default withNetworkStatus;
