import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import authProvider from '../../context/msAuthProvider';
import outlookApi from '../../context/outlookAPI';
import { sentryCaptureException } from '../../common';
import OAuthSigninButton from './OAuthSigninButton';

const OAuthOutlookSignin = ({
  label,
  onSignin,
  loading: externalLoading,
  onError,
  onBeforeSignin,
  mode = 'signin',
  t,
}) => {
  const [loading, setLoading] = useState(false);
  const onClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (typeof onBeforeSignin === 'function') {
        onBeforeSignin();
      }
      const authResponse = await authProvider.loginPopup({
        prompt: 'select_account',
      });
      setLoading(false);
      const token = authResponse.idToken.rawIdToken;
      const { email } = authResponse.account.idToken;
      const { name } = authResponse.account;

      let firstname;
      let lastname;
      // avoid request
      if (mode === 'signin' && name) {
        const nameSplit = name.split(' ');
        // this is an approximation
        [firstname] = nameSplit;
        lastname = nameSplit.slice(1).join(' ');
      }
      if (!name || mode === 'signup') {
        const currentUser = await outlookApi.getCurrentUser();
        const { givenName, surname } = currentUser || {};
        firstname = givenName;
        lastname = surname;
      }

      return onSignin({
        id_token: token,
        type: 'outlook',
        email,
        firstname,
        lastname,
      });
    } catch (error) {
      setLoading(false);
      const message = error?.message;
      if (
        !message.includes(
          'The user has denied access to the scope requested by the client application.',
        ) &&
        !message.includes('User cancelled the flow')
      ) {
        console.error(message);
        sentryCaptureException({ error });
        if (typeof onError === 'function') {
          onError({ error, provider: 'microsoft' });
        }
      }
      return undefined;
    }
  };
  return (
    <OAuthSigninButton
      title={label || t('signin.signinWithMicrosoft')}
      text={label || t('signin.signinWithMicrosoft')}
      logoSrc='/images/logos/microsoft-logo.svg'
      disabled={loading || externalLoading}
      onClick={onClick}
    />
  );
};

export default withTranslation('translations')(OAuthOutlookSignin);
