import { literalTypeChecker } from '@/common/utils/types';
import { Author } from './author';

interface ExternalAutomation {
  id: string;
  title?: string;
}

const linkedInPhantomBusterAutomationTypes = [
  'linkedin-message-sender',
  'linkedin-auto-connect',
] as const;

type LinkedInPhantomBusterAutomationType = typeof linkedInPhantomBusterAutomationTypes[number];

export const isLinkedInPhantomBusterAutomation = literalTypeChecker(
  linkedInPhantomBusterAutomationTypes,
);

type PhantomBusterAutomationType =
  | LinkedInPhantomBusterAutomationType
  | 'unknown';

interface PhantomBusterAutomation extends ExternalAutomation {
  id: string;
  type: PhantomBusterAutomationType;
  title?: string;
  description?: string;
}

type PhantomBusterAutomationService = {
  id: string;
  type: 'phantombuster';
  title?: string;
  creationDate?: string;
  author?: Author;

  automations: PhantomBusterAutomation[];
};

export type ExternalAutomationService = PhantomBusterAutomationService;
