import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback } from 'react';

const changeProfileStep = gql`
  mutation changeProfileStep($id: ID!, $step: String!, $subStep: String) {
    changeProfileStep(id: $id, step: $step, subStep: $subStep) {
      id
      resumeData {
        step
        subStep
      }
      lastStepUpdateTimestamp
    }
  }
`;

type Data = {
  changeProfileStep: {
    id: string;
    resumeData: {
      step: string;
      subStep: string;
    };
    lastStepUpdateTimestamp: number;
  } | null;
};

type Variables = {
  id: string;
  step: string;
  subStep?: string;
};

type Input = {
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

function useChangeProfileStep({ mutationOptions = {} }: Input = {}) {
  const [mutation, result] = useMutation<Data, Variables>(
    changeProfileStep,
    mutationOptions,
  );
  const update = useCallback(
    (variables: Variables) => mutation({ variables }),
    [mutation],
  );
  return [update, result] as const;
}

export default useChangeProfileStep;
