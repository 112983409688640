import React from 'react';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import GenericButton from '@/components/Common/GenericButton';
import withMarkUnhidden from '../../../hocs/searchPoolProfileState/withMarkUnhidden';

const UnskipProfileButton = ({
  searchPoolId,
  searchPoolProfileId,
  onUnskip,
  markUnhidden,
  t,
}) => {
  const handleHide = async (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    try {
      await markUnhidden({ searchPoolId, profileId: searchPoolProfileId });
      onUnskip({ searchPoolProfileId });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <GenericButton primacy='secondary' color='grey' onClick={handleHide}>
      <i className='ri-close-line' />
      {t('watchCollect.results.profileRow.unskip')}
    </GenericButton>
  );
};

export default compose(
  withMarkUnhidden,
  withTranslation('translations'),
)(UnskipProfileButton);
