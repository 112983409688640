import _ from 'underscore';

import { removeAccents } from '@/common/utils/string';

import { SearchItem, SearchPreferences, SearchAcceptance } from './types';
import { defaultPreferences } from './preferences';

export function getAcceptance(
  searchText: string,
  { labels }: SearchItem,
  { mainLanguage }: SearchPreferences = defaultPreferences,
): SearchAcceptance {
  const languageScores = _.map(labels, (label, language) => {
    const match = matchText(searchText, label);
    const rawScore = scoreMatch(match);
    return language === mainLanguage ? rawScore : rawScore / 2;
  });

  return acceptScore(_.max(languageScores));
}

function acceptScore(score: number): SearchAcceptance {
  return { score, category: score === 0 ? 'rejected' : 'main' };
}

function scoreMatch(match: StringMatch): number {
  if (match === 'none') {
    return 0;
  }
  if (match === 'random') {
    return 0.6;
  }
  return 1;
}

type StringMatch = 'start' | 'startOfWord' | 'random' | 'none';

function matchText(searchText: string, referenceText: string): StringMatch {
  const index = removeAccents(referenceText.toLocaleLowerCase()).indexOf(
    removeAccents(searchText.toLocaleLowerCase()),
  );

  if (index < 0) {
    return 'none';
  }
  if (index === 0) {
    return 'start';
  }
  if (referenceText.at(index - 1) === ' ') {
    return 'startOfWord';
  }
  return 'random';
}
