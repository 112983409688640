export const INIT_CRITERIA = {
  locationsAndRemote: {},
  jobPositions: [],
  experienceYearsMin: '',
  experienceYearsMax: '',
  skills: [],
  salaryMin: undefined,
  salaryMax: undefined,
  contracts: [],
};

export const SHOWN_INTEREST_CRITERIA = {
  shownInterestOnly: true,
};

export default {};
