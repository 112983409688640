import React from 'react';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { RowDescription, SegmentDescription } from './types';
import Funnel from '../GenericFunnelSummary/Funnel';

import styles from './GenericFunnelRow.module.less';

interface GenericFunnelRowProps {
  row?: RowDescription;
  segments: SegmentDescription[];
}

const GenericFunnelRow: React.FC<GenericFunnelRowProps> = ({ row, segments }) =>
  !row ? null : (
    <div className={styles.tableRow}>
      {row.link ? (
        <Link
          className={classNames(styles.name, styles.nameLink)}
          title={row.title}
          target='_blank'
          to={row.link}
        >
          {row.title}
        </Link>
      ) : (
        <span className={styles.name} title={row.title}>
          {row.title}
        </span>
      )}

      {_.map(
        segments,
        ({
          key,
          count,
          clickListener,
          classNames: segmentClassNames,
          conversionRate,
        }) => (
          <div
            key={key}
            className={classNames(
              styles.stage,
              conversionRate && styles.wider,
              segmentClassNames?.global,
            )}
          >
            <button
              type='button'
              className={classNames(
                styles.stageCount,
                segmentClassNames?.count,
              )}
              onClick={clickListener}
              disabled={count === 0 || !clickListener}
            >
              {count || '-'}
            </button>
            {conversionRate && (
              <div className={styles.funnel}>
                <Funnel>{conversionRate}</Funnel>
              </div>
            )}
          </div>
        ),
      )}
    </div>
  );

export default GenericFunnelRow;
