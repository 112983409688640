import { createContext } from 'react';

interface FreeTrialUpgradeModalContext {
  openFreeTrialUpgradeModal?: () => void;
}

const FreeTrialUpgradeModalContext = createContext<
  FreeTrialUpgradeModalContext
>({
  openFreeTrialUpgradeModal: undefined,
});

export default FreeTrialUpgradeModalContext;
