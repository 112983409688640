import gql from 'graphql-tag';
import { useMutation, MutationHookOptions } from '@apollo/client';
import { useCallback } from 'react';
import _ from 'underscore';
import { getDraftSharedTimelineItems } from './useDraftSharedTimelineItems';

const updateSharedActivityDraft = gql`
  mutation updateSharedActivityDraft(
    $input: UpdateSharedActivityDraftInput!
    $searchPoolId: ID!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      updateSharedActivityDraft(input: $input) {
        errors
      }
    }
  }
`;

type Data = {
  searchPoolProfile: {
    updateSharedActivityDraft: {
      errors: string[];
    };
  };
};

type Variables = {
  searchPoolId: string;
  input: {
    profileId: string;
    draftId: string;
    referenceId?: string;
    content: {
      type: string;
      date?: string;
      subtype?: string;
      comment?: {
        text: string;
      };
    };
    targets: {
      type: string;
      targetId: string;
      isMain?: boolean;
    }[];
  };
};

type Input = {
  searchPoolId?: 'reveal';
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

function useUpdateSharedActivityDraft({
  searchPoolId = 'reveal',
  mutationOptions = {},
}: Input = {}) {
  const [mutate, result] = useMutation<Data, Variables>(
    updateSharedActivityDraft,
    mutationOptions,
  );
  const apply = useCallback(
    (input: Variables['input']) =>
      mutate({
        variables: { searchPoolId, input },
        refetchQueries: [
          {
            query: getDraftSharedTimelineItems,
            variables: {
              searchPoolId: 'reveal',
              profileId: input.profileId,
            },
          },
        ],
      }),
    [mutate, searchPoolId],
  );
  return [apply, result] as const;
}

export default useUpdateSharedActivityDraft;
