import React from 'react';
import { Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { MarketplaceUnbiasedModeField } from '@/components/UnbiasedMode/UnbiasedModeField';
import { BlurParagraph } from '@/components/UnbiasedMode/BlurLine';
import useClientId from '@/hooks/router/useClientId';
import useClient from '@/graphql/hooks/clients/useClient';
import { getSecureLink, getEnrichedText, getTranslatedText } from '@/common';
import { Stack } from '@/components/Stack';
import CollapsibleEnrichedText from '@/components/CollapsibleEnrichedText';

import WorkplacePopup from './WorkplacePopup';

import './BackgroundItemCard.css';

const BackgroundItemContent = ({
  allowUnbiasedMode,
  content,
  logExpand,
  resumeData,
}) => {
  const clientId = useClientId();
  const { data } = useClient(clientId);
  const { hasLimitedAccessToProfiles } = data?.client || {};

  if (!content) {
    return null;
  }

  if (hasLimitedAccessToProfiles && content.text) {
    return <BlurParagraph lines={4} />;
  }

  return (
    <div className='card-description body-regular'>
      <MarketplaceUnbiasedModeField
        enabled={allowUnbiasedMode && resumeData?.step === 'pending'}
        name='hideEducationDescriptions'
        fallback={<BlurParagraph lines={4} />}
      >
        <CollapsibleEnrichedText
          enrichedText={getEnrichedText(content.text)}
          charCost={1}
          lineCost={90}
          targetMaxCost={400}
          toleranceFactor={0.3}
          onToggleCollapse={logExpand}
        />
      </MarketplaceUnbiasedModeField>
    </div>
  );
};

const BackgroundItemTitlePopup = ({
  titleSecondPart,
  hoverPopupsDisabled,
  workplace,
  profileId,
  structureType = 'company',
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { data } = useClient(clientId);
  const { hasLimitedAccessToProfiles } = data?.client || {};
  if (hasLimitedAccessToProfiles) {
    return (
      <span>
        {getTranslatedText(workplace?.data?.types?.[0]?.name) ||
          t(`trial.placeholders.${structureType}`)}
      </span>
    );
  }
  if (!hoverPopupsDisabled && workplace?.data?.name) {
    return (
      <WorkplacePopup
        workplace={workplace}
        titleSecondPart={titleSecondPart}
        profileId={profileId}
      />
    );
  }

  if (titleSecondPart.link) {
    return (
      <a
        href={titleSecondPart.link}
        target='_blank'
        rel='noopener noreferrer'
        className='card-link'
      >
        {titleSecondPart.text}
      </a>
    );
  }

  return titleSecondPart.text;
};

const BackgroundItemTitle = ({
  title,
  titleSecondPart,
  workplace,
  profileId,
  hoverPopupsDisabled,
  allowUnbiasedMode,
  resumeData,
  structureType,
}) => {
  return (
    <div className='card-title'>
      <MarketplaceUnbiasedModeField
        enabled={allowUnbiasedMode && resumeData?.step === 'pending'}
        name='hideEducationSchoolNames'
      >
        {title}
        {titleSecondPart && titleSecondPart.text && (
          <span>
            {title && <span> • </span>}
            <BackgroundItemTitlePopup
              titleSecondPart={titleSecondPart}
              hoverPopupsDisabled={hoverPopupsDisabled}
              workplace={workplace}
              profileId={profileId}
              structureType={structureType}
            />
          </span>
        )}
      </MarketplaceUnbiasedModeField>
    </div>
  );
};

const BackgroundItemPicture = ({ icon }) => {
  return (
    <Image
      className='card-company-image'
      src={getSecureLink(icon) || '/images/icons/defaultCompany.svg'}
      onError={(e) => {
        e.target.src = '/images/icons/defaultCompany.svg';
      }}
    />
  );
};

const BackgroundItemCard = ({
  icon,
  stack,
  subtitle,
  profileId,
  children,
  applicationMode,
  title,
  titleSecondPart,
  workplace,
  hoverPopupsDisabled,
  content,
  logExpand,
  allowUnbiasedMode = true,
  resumeData,
  structureType,
}) => {
  return (
    <div
      className={`background-item-card ${applicationMode}`}
      key={profileId || Math.floor(Math.random() * 10000)}
    >
      <div className='background-item-row'>
        <div className='background-item-picture'>
          <BackgroundItemPicture icon={icon} />
        </div>
        <div className='main'>
          <div className='card-header'>
            <div className='show-more'>
              <Stack
                stack={stack}
                displaySoftSkills
                profileId={profileId}
                context='experience'
              />
            </div>
            <BackgroundItemTitle
              title={title}
              allowUnbiasedMode={allowUnbiasedMode}
              titleSecondPart={titleSecondPart}
              workplace={workplace}
              profileId={profileId}
              hoverPopupsDisabled={hoverPopupsDisabled}
              resumeData={resumeData}
              structureType={structureType}
            />
            <div className='card-subtitle'>
              <MarketplaceUnbiasedModeField
                enabled={allowUnbiasedMode && resumeData?.step === 'pending'}
                name='hideEducationDates'
              >
                {subtitle}
              </MarketplaceUnbiasedModeField>
            </div>
          </div>
          <div className='card-content'>
            <BackgroundItemContent
              allowUnbiasedMode={allowUnbiasedMode}
              content={content}
              logExpand={logExpand}
              resumeData={resumeData}
            />
          </div>
        </div>
      </div>
      <div className='card-children'>{children}</div>
    </div>
  );
};

export default BackgroundItemCard;
