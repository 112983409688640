import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getShortLanguage } from '@/common/utils/i18n';

import EN from './EN';
import FR from './FR';

type RepliesMockupProps = {
  className?: string;
};

const RepliesMockup: FC<RepliesMockupProps> = ({ className }) => {
  const { i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);

  switch (lang) {
    case 'en':
      return <EN className={className} />;
    case 'fr':
      return <FR className={className} />;
    default:
      return null;
  }
};

export default RepliesMockup;
