import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import { OfferCriteria } from '@/types/offerCriteria';
import { sanitizeTypename } from '@/common/utils/apollo';
import { useMemo } from 'react';

const getSalaryBenchmark = gql`
  query getSalaryBenchmark($criteria: CriteriaInput!) {
    salaryBenchmark(criteria: $criteria) {
      __typename
      ... on SalaryBenchmarkError {
        missingFields
        emptyResult
      }
      ... on SalaryBenchmarkOK {
        value {
          countryCode
          min {
            level
            salary
          }
          mean {
            level
            salary
          }
          max {
            level
            salary
          }
        }
      }
    }
  }
`;

type SalaryBoundary = {
  level: string;
  salary: number;
};

export type SalaryBenchmark = {
  countryCode: string;
  min: SalaryBoundary;
  mean: SalaryBoundary;
  max: SalaryBoundary;
};

type GetSalaryBenchmarkData = {
  salaryBenchmark:
    | {
        __typename: 'SalaryBenchmarkOK';
        value: SalaryBenchmark;
      }
    | {
        __typename: 'SalaryBenchmarkError';
        missingFields?: string[];
        emptyResult?: boolean;
      };
};

type GetSalaryBenchmarkVariables = {
  criteria: OfferCriteria;
};

type UseSalaryBenchmarkInput = {
  criteria: OfferCriteria;
  queryOptions?: QueryHookOptions<
    GetSalaryBenchmarkData,
    GetSalaryBenchmarkVariables
  >;
};

interface UseSalaryBenchmarkResult
  extends QueryResult<GetSalaryBenchmarkData, GetSalaryBenchmarkVariables> {
  salaryBenchmark?: SalaryBenchmark;
  missingFields?: string[];
  emptyResult?: boolean;
}

function useSalaryBenchmark({
  criteria,
  queryOptions = {},
}: UseSalaryBenchmarkInput): UseSalaryBenchmarkResult {
  const query = useQuery<GetSalaryBenchmarkData, GetSalaryBenchmarkVariables>(
    getSalaryBenchmark,
    { ...queryOptions, variables: { criteria } },
  );

  const { salaryBenchmark, missingFields, emptyResult } = useMemo(() => {
    if (!query.data) {
      return {};
    }
    const result = query.data.salaryBenchmark;
    if (result.__typename === 'SalaryBenchmarkOK') {
      return {
        salaryBenchmark: sanitizeTypename(result.value),
        missingFields: undefined,
        emptyResult: undefined,
      };
    }
    return {
      missingFields: result.missingFields,
      emptyResult: result.emptyResult,
      salaryBenchmark: undefined,
    };
  }, [query.data]);

  return { ...query, salaryBenchmark, missingFields, emptyResult };
}

export default useSalaryBenchmark;
