import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

interface ISequence {
  id: string;
  title: string;
}

interface SequenceOptionsResult {
  client: {
    id: string;
    sequences?: ISequence[];
  };
}

interface SequenceOptionsVariables {
  clientId: string;
}

const SEQUENCE_OPTIONS = gql`
  query getSequenceOptions($clientId: ID!) {
    client(id: $clientId) {
      id
      sequences {
        id
        title
      }
    }
  }
`;

const useSequenceOptions = (clientId: string) =>
  useQuery<SequenceOptionsResult, SequenceOptionsVariables>(SEQUENCE_OPTIONS, {
    variables: { clientId },
  });

export default useSequenceOptions;
