import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { ConditionResult, IfStatement } from '@/common/mergeTags/utils';
import { useConditionsChainingContext } from '../useConditionsChainingContext';
import styles from '../ConditionsChaining.module.less';

const IfStatementValueSimpleInput: React.FC<{
  ifStatement: IfStatement;
}> = ({ ifStatement }) => {
  const {
    getStateTreeAfterReplacement,
    conditionsChainingState,
    setConditionsChainingState,
  } = useConditionsChainingContext();

  const handleChange = (newValue: string) => {
    const newState = getStateTreeAfterReplacement<ConditionResult>({
      node: conditionsChainingState,
      id: ifStatement.result.id,
      newValue: {
        ...ifStatement.result,
        text: newValue,
      },
    });
    setConditionsChainingState(newState);
  };

  return (
    <Form.Field className={styles.simplifiedStatementInput}>
      <Input
        value={ifStatement.result.text}
        onChange={(e, { value }) => handleChange(value as string)}
        className={styles.input}
      />
    </Form.Field>
  );
};

export default IfStatementValueSimpleInput;
