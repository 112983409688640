import { isRecord } from 'ts-is-record';

/**
 * Create a message of type notification.
 * 
 * Internally we use JSON RPC 2 protocol for formatting.
 */
export const createNotificationMessage = (methodName: string, params: Record<string, unknown> | undefined) => ({
  jsonrpc: '2.0',
  method: methodName,
  params,
});

type NotificationMessage<TParams extends Record<string, unknown> | undefined = Record<string, unknown> | undefined> = {
  method: string;
  params: TParams;
}

/**
 * Returns true if the given message of a notification message.
 */
export function isNotificationMessage(message: unknown): message is NotificationMessage {
  if (!isRecord(message)) return false;
  // in JSON RPC, notifications do not have any id.
  return typeof message.method === 'string' && message.id === undefined;
}
