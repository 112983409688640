import React, { useCallback, useState } from 'react';
import InfoBox from '@/components/Common/InfoBox';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import GenericSelect from '@/components/Common/GenericSelect';
import _ from 'underscore';
import {
  useClientDepartmentNomenclature,
  useUpdateClientDepartmentNomenclature,
} from '@/graphql/hooks/clients/useClientDepartmentNomenclature';
import ArrowRight from '@/components/Reveal/Icons/ArrowRight';
import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import styles from './DepartmentEditNaming.module.less';
import { DEPARTMENT } from './Departements';

const DepartmentEditNaming: React.FC = () => {
  const { applicationMode } = useNavApplicationMode();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState({
    department: 'department',
    subDepartment: 'sub-department',
    section: 'section',
  });

  useClientDepartmentNomenclature({
    queryOptions: {
      onCompleted: (data) => {
        const { departmentNomenclature } = data?.client;
        setSelectedValues({
          department: departmentNomenclature?.department?.id || 'department',
          subDepartment:
            departmentNomenclature?.section?.id || 'sub-department',
          section: departmentNomenclature?.subsection?.id || 'section',
        });
      },
    },
  });

  const [
    updateDepartmentNomenclature,
  ] = useUpdateClientDepartmentNomenclature();

  const { t } = useTranslation();

  const handleSubmit = useCallback(() => {
    const newNomenclature = {
      department: {
        id: selectedValues.department,
        type: 'built-in',
      },
      section: {
        id: selectedValues.subDepartment,
        type: 'built-in',
      },
      subsection: {
        id: selectedValues.section,
        type: 'built-in',
      },
    };
    updateDepartmentNomenclature({
      variables: {
        input: {
          ...newNomenclature,
        },
      },
    });
    setModalOpen(false);
  }, [selectedValues, updateDepartmentNomenclature]);

  if (!applicationMode || applicationMode !== 'reveal') {
    return null;
  }

  const options = _.sortBy(
    _.map(Object.values(DEPARTMENT), (departmentId) => ({
      value: departmentId,
      label: t(`settings.departments.nomenclature.options.${departmentId}`, {
        count: 1,
      }),
    })),
    'label',
  );

  const handleSelectName = (key: string, value: string | undefined) => {
    if (!value) {
      return;
    }
    setSelectedValues({
      ...selectedValues,
      [key]: value,
    });
  };

  return (
    <>
      <InfoBox className={styles.renameStepsContainer}>
        <div className={styles.description}>
          <span className={styles.mainText}>
            {' '}
            {t(`settings.departments.nomenclature.infoBox.title`)}
          </span>
          <span className={styles.secondaryText}>
            {t(`settings.departments.nomenclature.infoBox.description`)}
          </span>
        </div>

        <GenericButton primacy='secondary' onClick={() => setModalOpen(true)}>
          {t(`settings.departments.nomenclature.infoBox.cta`)}
        </GenericButton>
      </InfoBox>
      <GenericModal
        size='small'
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Modal.Header>
          {t(`settings.departments.nomenclature.modal.title`)}
        </Modal.Header>
        <Modal.Content>
          <div className={styles.renameStepsModalContainer}>
            <div className={styles.header}>
              <div className={styles.sectionName}>
                {t('settings.departments.nomenclature.modal.original')}
              </div>
              <div className={styles.sectionName}>
                {t('settings.departments.nomenclature.modal.new')}
              </div>
            </div>
            <div className={styles.stepContainer}>
              <div className={styles.originalStep}>
                {t('settings.departments.nomenclature.options.department', {
                  count: 1,
                })}
              </div>
              <div className={styles.arrow}>
                <ArrowRight />
              </div>
              <div className={styles.newStepContainer}>
                <GenericSelect
                  className={styles.scopeSelector}
                  onChange={(e) => handleSelectName('department', e?.value)}
                  value={_.findWhere(options, {
                    value: selectedValues.department as DEPARTMENT,
                  })}
                  options={_.filter(
                    options,
                    (option) =>
                      !_.contains(_.values(selectedValues), option.value),
                  )}
                />
              </div>
            </div>
            <div className={styles.stepContainer}>
              <div className={styles.originalStep}>
                {t('settings.departments.nomenclature.options.sub-department', {
                  count: 1,
                })}
              </div>
              <div className={styles.arrow}>
                <ArrowRight />
              </div>
              <div className={styles.newStepContainer}>
                <GenericSelect
                  className={styles.scopeSelector}
                  onChange={(e) => handleSelectName('subDepartment', e?.value)}
                  value={_.findWhere(options, {
                    value: selectedValues.subDepartment as DEPARTMENT,
                  })}
                  options={_.filter(
                    options,
                    (option) =>
                      !_.contains(_.values(selectedValues), option.value),
                  )}
                />
              </div>
            </div>
            <div className={styles.stepContainer}>
              <div className={styles.originalStep}>
                {t('settings.departments.nomenclature.options.section', {
                  count: 1,
                })}
              </div>
              <div className={styles.arrow}>
                <ArrowRight />
              </div>
              <div className={styles.newStepContainer}>
                <GenericSelect
                  className={styles.scopeSelector}
                  onChange={(e) => handleSelectName('section', e?.value)}
                  value={_.findWhere(options, {
                    value: selectedValues.section as DEPARTMENT,
                  })}
                  options={_.filter(
                    options,
                    (option) =>
                      !_.contains(_.values(selectedValues), option.value) ||
                      option.value === selectedValues?.section,
                  )}
                />
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div className='department-edit-modal-actions'>
            <GenericButton
              size='big'
              primacy='secondary'
              onClick={() => setModalOpen(false)}
            >
              {t('common.cancel')}
            </GenericButton>
            <GenericButton size='big' onClick={handleSubmit} disabled={false}>
              {t(`common.save`)}
            </GenericButton>
          </div>
        </Modal.Actions>
      </GenericModal>
    </>
  );
};

export default DepartmentEditNaming;
