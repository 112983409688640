import React from 'react';
import { useTranslation } from 'react-i18next';
import SettingsLayout from '@/containers/SettingsLayout';
import { LegalEntity } from '@/types/client';
import useClientPrivacySettings, {
  PrivacySettingsInput,
  useClientPrivacySettingsMutation,
} from '@/graphql/hooks/clients/useClientPrivacySettings';
import styles from './RevealCompliance.module.less';
import ComplianceLegalEntity from './components/ComplianceLegalEntity';

interface RevealComplianceProps {
  clientId: string;
}

const RevealCompliance: React.FC<RevealComplianceProps> = ({ clientId }) => {
  const { t } = useTranslation();
  const [updatePrivacySettingsMutate] = useClientPrivacySettingsMutation();
  const { privacySettings } = useClientPrivacySettings(clientId);

  const handleSubmit = async (data: PrivacySettingsInput) => {
    await updatePrivacySettingsMutate({
      variables: {
        input: { ...data },
      },
    });
  };

  const handleDPOSubmit = async (data: LegalEntity) => {
    await handleSubmit({ dpo: { ...data } });
  };

  const handleEuRepresentativeSubmit = async (data: LegalEntity) => {
    await handleSubmit({ euRepresentative: { ...data } });
  };

  return (
    <div className='settings'>
      <SettingsLayout
        currentPage='compliance'
        clientId={clientId}
        className='settings-container'
      >
        <div className='settings compliance-page'>
          <h1 className={styles.mainTitle}>
            {t('settings.complianceSettings.header')}
          </h1>
          <ComplianceLegalEntity
            entity={privacySettings.dpo}
            onEntityChange={handleDPOSubmit}
            entityName='dpo'
          />
          <ComplianceLegalEntity
            entity={privacySettings.euRepresentative}
            onEntityChange={handleEuRepresentativeSubmit}
            entityName='euRepresentative'
          />
        </div>
      </SettingsLayout>
    </div>
  );
};

export default RevealCompliance;
