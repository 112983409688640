import React, { useEffect } from 'react';
import _ from 'underscore';
import useResizableItemList from '@/hooks/common/useResizableItemList';
import TopHeaderDropdown from '../TopHeaderDropdown';
import TopHeaderLink from '../TopHeaderLink';
import { DropdownMenuItem, MenuItem } from './MenuItem';

import style from './Menu.module.less';

interface MenuProps {
  clientId: string;
  items: MenuItem[];
}

const ELLIPSIS_OFFSET = 52;

const Menu: React.FC<MenuProps> = ({ clientId, items }) => {
  const {
    containerRef,
    listRef,
    displayedItemsNumber,
    finalWidth,
    refresh,
  } = useResizableItemList<HTMLDivElement>(ELLIPSIS_OFFSET);

  useEffect(refresh, [items, refresh]);

  const hiddenItems = _.rest(items, displayedItemsNumber);

  return (
    <div className={style.menu} ref={containerRef}>
      <div
        className={style.visibleMenu}
        style={{ width: finalWidth }}
        ref={listRef}
      >
        {_.map(items, (item, index) => (
          <div
            key={item.key}
            className={`${style.menuItem} ${
              index >= displayedItemsNumber ? style.hidden : ''
            }`}
          >
            {item.isDropdown ? (
              <TopHeaderDropdown
                clientId={clientId}
                active={item.active}
                items={(item as DropdownMenuItem).items}
                icon={item.icon}
              >
                {item?.text}
              </TopHeaderDropdown>
            ) : (
              <TopHeaderLink
                clientId={clientId}
                to={item.to}
                pageId={item.pageId}
                active={item.active}
              >
                {item.icon ? (
                  <div className='navigation-item with-icon'>
                    <span className='icon'>{item.icon}</span>
                    <span className='title'>{item?.text}</span>
                  </div>
                ) : (
                  <div className='navigation-item'>{item?.text}</div>
                )}
              </TopHeaderLink>
            )}
          </div>
        ))}
      </div>

      <div
        className={`${style.ellipsis} ${
          hiddenItems.length === 0 ? style.hidden : ''
        }`}
      >
        <TopHeaderDropdown
          key='ellipsis'
          clientId={clientId}
          active={!!_.findWhere(hiddenItems, { active: true })}
          items={hiddenItems}
          icon={null}
        >
          <i className='ri-more-fill' style={{ verticalAlign: 'top' }} />
        </TopHeaderDropdown>
      </div>
    </div>
  );
};

export default Menu;
