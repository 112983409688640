import _ from 'underscore';
import React, { useMemo, useState, useContext } from 'react';
import { Button, Image, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetTasksCount } from '@/graphql/tasks';
import useAtsFilterOptions from '@/graphql/hooks/clients/useClientAtsFiltersOptions';
import Tag from '@/components/Common/Tag';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import GenericTooltip from '@/components/Common/GenericTooltip';
import AccountPin from '@/components/Reveal/Icons/AccountPin';
import {
  getRandomDefaultAvatarLink,
  getSecureLink,
  getTranslatedText,
} from '@/common';
import useOverviewPoolMiniProfileById from '@/graphql/hooks/profiles/useOverviewMiniProfilesById';
import ProfilesViewSettingsContextProvider from '@/context/ProfilesViewSettingsContext/ProfilesViewSettingsContextProvider';
import useSearchPoolJob from '@/graphql/hooks/searchPoolJobs/useSearchPoolJob';

import JobBreadCrumb from '../components/JobBreadCrumb';
import JobContactKPIs from '../components/JobContactKPIs';
import { TabId } from '../../revealComponents/ProjectTypesTabs';
import ApplicationReviewsTab from './ApplicationReviewsTab';
import ImportedCandidatesTab from './ImportedCandidatesTab';
import ProjectProfilesTab from './ProjectProfilesTab';
import { computeJobStats } from './helpers';
import AllSettingsTab from './AllSettingsTab';
import MoreContactsTab from './MoreContactsTab/MoreContactsTab';
import TasksView from '../../TasksView';
import { AtsPill } from '../../ProfileRow/ProfileAtsApplicationsRow/ProfileAtsApplicationsRow';

import styles from './JobView.module.less';

interface JobViewProps {
  clientId: string;
  jobId: string;
  searchPoolId: string;
  revealProject: any;
  activeTab: TabId;
}

const JobView: React.FC<JobViewProps> = ({
  clientId,
  searchPoolId,
  jobId,
  revealProject,
  activeTab,
}) => {
  const { t } = useTranslation();
  const { permissions } = useClientPermissions(clientId);
  const configurationParams = useMergedConfigurationParams();
  const tasksCount = useGetTasksCount({
    clientId,
    filters: { missionIds: [jobId] },
    skip: configurationParams?.shouldHideProjectTasks === 'true',
  });
  const { data: jobData, loading: jobLoading } = useSearchPoolJob(
    searchPoolId,
    jobId,
  );
  const missionCategoryType = useContext(MissionCategoryContext);

  const isArchived = jobData?.searchPool?.job?.isArchived || false;
  // used as a key to refresh the data within the tabs
  const [jobViewVersion] = useState(0);

  const dailyAddedProfiles =
    jobData?.searchPool?.job?.stats?.dailyAddedProfiles;

  const {
    weeklyProfilesAdded,
    nbProfilesAddedToday,
    nbProfilesAddedWeek,
    nbProfilesAdded3Months,
  } = useMemo(
    () => computeJobStats(dailyAddedProfiles || []),
    [dailyAddedProfiles],
  );

  const { data: atsFilterOptionsData } = useAtsFilterOptions(clientId);

  const job = jobData?.searchPool?.job;
  const linkedCompany = job?.targets?.companies?.[0];

  const { profile: companyData } = useOverviewPoolMiniProfileById(
    {
      searchPoolId: 'reveal',
      profileId: linkedCompany?.id,
    },
    { skip: !linkedCompany },
  );

  if (jobLoading) {
    return (
      <div>
        <Loader active />
      </div>
    );
  }
  if (!job) {
    return null;
  }
  const { id, stats } = job;
  const authorName =
    job.owner?.firstname || job.owner?.lastname
      ? (job.owner.firstname || '') +
        (job.owner.firstname && job.owner.lastname ? ' ' : '') +
        (job.owner.lastname || '')
      : '';

  const atsConnector =
    atsFilterOptionsData?.client?.revealProjects[0]?.connectors[0];
  const atsJobs = atsConnector?.filterOptions?.jobs || [];

  const linkedAtsJob = _.findWhere(atsJobs, {
    id: job.linkedATSJobId,
  });

  const positionDescription = getTranslatedText(
    job?.positionDescription?.description?.sections?.[0]?.content,
  );

  const companyAvatar = companyData?.resumeData?.photoLink;
  const secureCompanyAvatar = companyAvatar
    ? getSecureLink(companyAvatar)
    : companyData?.resumeData?.sources?.website
      ? `https://logo.clearbit.com/${companyData.resumeData.sources.website}`
      : getRandomDefaultAvatarLink(linkedCompany?.id);
  return (
    <>
      {configurationParams?.shouldHideProjectSubheader !== 'true' && (
        <div className={styles.jobHeader}>
          <div className={styles.breadcrumbs}>
            <JobBreadCrumb
              clientId={clientId}
              jobId={id}
              searchPoolId={searchPoolId}
              activeTab={activeTab}
              shouldHideNewProjectButton={
                configurationParams?.shouldHideAllNewProjectButtons === 'true'
              }
            />
            {linkedCompany && (
              <Link
                to={`/client/${clientId}/reveal/companies?profileId=${linkedCompany?.id}`}
                className={styles.linkedCompanyContainer}
              >
                <Image
                  src={secureCompanyAvatar}
                  circular
                  className={styles.companyLogo}
                />
                <div className={styles.linkedCompany}>{linkedCompany.name}</div>
              </Link>
            )}
            {authorName ? (
              configurationParams?.shouldHideProjectParameters !== 'true' ? (
                <Link
                  to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${id}/settings`}
                >
                  <Tag color='green'>
                    <i className='ri-user-line ri-lg' />
                    {authorName}
                  </Tag>
                </Link>
              ) : (
                <Tag color='green'>
                  <i className='ri-user-line ri-lg' />
                  {authorName}
                </Tag>
              )
            ) : null}
            {linkedAtsJob && (
              <AtsPill atsId={atsConnector?.type}>
                <span className={styles.linkedAtsJobName}>
                  {linkedAtsJob.name || '??'}
                </span>
              </AtsPill>
            )}
            {positionDescription && (
              <div className={styles.positionDescriptionContainer}>
                <GenericTooltip
                  rich
                  inverted
                  position='bottom center'
                  trigger={
                    <div className={styles.positionDescriptionTrigger}>
                      <AccountPin />
                    </div>
                  }
                >
                  <div className={styles.tooltipContent}>
                    <div
                      className={styles.tooltipDescription}
                      dangerouslySetInnerHTML={{
                        __html: positionDescription || 'blablablbal',
                      }}
                    />
                  </div>
                </GenericTooltip>
              </div>
            )}
          </div>
          {(configurationParams?.shouldHideProjectSearch !== 'true' ||
            configurationParams?.shouldHideProjectTasks !== 'true' ||
            configurationParams?.shouldHideProjectParameters !== 'true') && (
            <div className={styles.navigation}>
              <div className={styles.buttonsContainer}>
                {configurationParams?.shouldHideProjectSearch !== 'true' && (
                  <Button
                    className={`default bulk-action-button${
                      activeTab === 'search' || activeTab === 'recommendations'
                        ? ' active'
                        : ''
                    }`}
                    as={Link}
                    to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${id}/search`}
                  >
                    <i className='ri-search-line icon' />
                    {t('reveal.missions.mission.searchContacts')}
                  </Button>
                )}
                {missionCategoryType === 'recruiting' &&
                  permissions?.careerPage && (
                    <Button
                      className={`default bulk-action-button${
                        activeTab === 'applicants' ? ' active' : ''
                      }`}
                      as={Link}
                      to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${id}/applicants`}
                    >
                      {t('reveal.missions.mission.applicants')}
                    </Button>
                  )}
                <Button
                  className={`default bulk-action-button${
                    activeTab === 'profiles' ? ' active' : ''
                  }`}
                  as={Link}
                  to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${id}/profiles`}
                >
                  {t('reveal.missions.mission.pipeline')}
                </Button>
                {configurationParams?.shouldHideProjectTasks !== 'true' && (
                  <Button
                    className={`default bulk-action-button${
                      activeTab === 'mission-tasks' ? ' active' : ''
                    }`}
                    as={Link}
                    to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${id}/mission-tasks`}
                  >
                    {t('reveal.missions.mission.tasks')}
                    <span className='ui label'>{tasksCount || 0}</span>
                  </Button>
                )}
                {configurationParams?.shouldHideProjectParameters !==
                  'true' && (
                  <Button
                    className={`default bulk-action-button${
                      activeTab === 'settings' ||
                      activeTab === 'calibration' ||
                      activeTab === 'sequences' ||
                      activeTab === 'workflows' ||
                      activeTab === 'dynamic-imports' ||
                      activeTab === 'position-description' ||
                      activeTab === 'pipeline'
                        ? ' active'
                        : ''
                    }`}
                    as={Link}
                    to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${id}/settings`}
                  >
                    {t('reveal.missions.mission.settings')}
                  </Button>
                )}
              </div>
            </div>
          )}

          <div className={styles.kpis}>
            <JobContactKPIs
              countToday={nbProfilesAddedToday}
              countWeek={nbProfilesAddedWeek}
              countTrimester={nbProfilesAdded3Months}
              heatMapValues={weeklyProfilesAdded}
              clientId={clientId}
              jobId={job.id}
            />
          </div>
        </div>
      )}

      <CurrentTab
        key={`${jobId}_${jobViewVersion}`}
        activeTab={activeTab}
        jobId={jobId}
        clientId={clientId}
        revealProject={revealProject}
        stats={stats}
        configurationParams={configurationParams}
        isArchived={isArchived}
      />
    </>
  );
};

interface CurrentTabProps {
  activeTab: TabId;
  jobId: string;
  clientId: string;
  revealProject: any;
  stats: any;
  configurationParams: any;
  isArchived: boolean;
}

const CurrentTab: React.FC<CurrentTabProps> = ({
  activeTab,
  jobId,
  clientId,
  revealProject,
  configurationParams,
  stats,
  isArchived,
}) => {
  if (activeTab === TabId.PROFILES) {
    return (
      <ProfilesViewSettingsContextProvider>
        <ProjectProfilesTab
          clientId={clientId}
          jobId={jobId}
          shouldHideBulkActions={
            configurationParams?.shouldHideProjectProfilesBulkActions === 'true'
          }
          disableProfilesInteraction={isArchived}
        />
      </ProfilesViewSettingsContextProvider>
    );
  }
  if (activeTab === TabId.APPLICATIONS) {
    return <ApplicationReviewsTab jobId={jobId} clientId={clientId} />;
  }
  if (activeTab === TabId.APPLICANTS) {
    return (
      <MoreContactsTab
        clientId={clientId}
        revealProject={revealProject}
        mode='job'
        jobId={jobId}
        stats={stats}
        activeTab={activeTab}
      />
    );
  }

  if (activeTab === TabId.IMPORTED) {
    return <ImportedCandidatesTab jobId={jobId} clientId={clientId} />;
  }

  if (activeTab === TabId.SEARCH || activeTab === TabId.RECOMMENDATIONS) {
    return (
      <MoreContactsTab
        clientId={clientId}
        revealProject={revealProject}
        mode='job'
        jobId={jobId}
        stats={stats}
        activeTab={activeTab}
      />
    );
  }

  if (activeTab === TabId.TASKS) {
    return <TasksView clientId={clientId} />;
  }

  if (
    activeTab === TabId.CALIBRATION ||
    activeTab === TabId.SETTINGS ||
    activeTab === TabId.WORKFLOWS ||
    activeTab === TabId.SEQUENCES ||
    activeTab === TabId.PIPELINE ||
    activeTab === TabId.DYNAMIC_IMPORTS ||
    activeTab === TabId.JOB_POSTING ||
    activeTab === TabId.POSITION_DESCRIPTION ||
    activeTab === TabId.DOCUMENTS
  ) {
    return (
      <AllSettingsTab clientId={clientId} jobId={jobId} activeTab={activeTab} />
    );
  }

  if (activeTab === TabId.OTHER_PROJECTS) {
    return null;
  }

  return null;
};

export default JobView;
