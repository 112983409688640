import gql from 'graphql-tag';

export const Department = gql`
  fragment Department on Department {
    id
    title
    sections {
      id
      title
      subsections {
        id
        title
      }
    }
  }
`;
