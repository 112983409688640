import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import ContactFlow from '../../graphql/fragments/ContactFlow';

export const addSequence = gql`
  mutation addSequence(
    $jobOfferId: ID
    $contactFlowId: ID!
    $sequenceInput: ContactFlowSequenceInput!
  ) {
    addSequence(
      jobOfferId: $jobOfferId
      contactFlowId: $contactFlowId
      sequenceInput: $sequenceInput
    ) {
      ...ContactFlow
    }
  }
  ${ContactFlow}
`;

export default graphql(addSequence, {
  props: ({ ownProps, mutate }) => ({
    addSequence: ({ contactFlowId, sequenceInput }) =>
      mutate({
        variables: {
          jobOfferId: ownProps.offerId, // TODO: component must receive offerId prop
          contactFlowId,
          sequenceInput,
        },
      }),
  }),
});
