import React, { useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';

import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import GenericModal from '@/components/Common/GenericModal';
import useEditPhotoLink from '@/graphql/hooks/searchPoolProfile/useEditPhotoLink';
import GenericTextInput from '@/components/Common/GenericTextInput';
import GenericButton from '@/components/Common/GenericButton';

interface EditPictureLinkModalProps {
  profileId: string;
  profilePictureLink: string;
  onClose: () => void;
}

const EditPictureLinkModal: React.FC<EditPictureLinkModalProps> = ({
  profileId,
  profilePictureLink,
  onClose,
}) => {
  const { t } = useTranslation();
  const notifications = useNotificationSystem();
  const [newPictureLink, setNewPictureLink] = useState<string | undefined>(
    profilePictureLink,
  );

  const [editPhotoLink] = useEditPhotoLink();

  return (
    <GenericModal open onClose={onClose} size='tiny'>
      <Modal.Header>{t('reveal.profileModal.editPhotoLink')}</Modal.Header>
      <Modal.Content>
        <label>{t('reveal.profileModal.photoLinkUrl')}</label>
        <GenericTextInput
          fluid
          value={newPictureLink}
          placeholder='https://example.com/picture.jpg'
          onChange={(e) => setNewPictureLink(e.target.value)}
        />
      </Modal.Content>

      <Modal.Actions>
        <GenericButton
          size='big'
          primacy='secondary'
          onClick={onClose || (() => {})}
        >
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          size='big'
          primacy='primary'
          onClick={async () => {
            try {
              await editPhotoLink({
                variables: {
                  searchPoolId: 'reveal',
                  profileId,
                  photoLink: newPictureLink,
                },
              });
              notifications.success(t('common.genericSuccess'));
              onClose();
            } catch (e) {
              console.error(e);
              notifications.error('common.genericError');
            }
          }}
        >
          {t('common.save')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default EditPictureLinkModal;
