import { QueryResult, useLazyQuery, QueryHookOptions } from '@apollo/client';
import gql from 'graphql-tag';
import {
  GeneratedSequence,
  SequenceGenerationInput,
} from '@/types/generatedSequence';

const GENERATE_DEMO_SEQUENCE = gql`
  query generateDemoSequence($input: SequenceGenerationInput!) {
    generateSequence(input: $input) {
      streamId
      streamEnded
      sequence {
        actions {
          body
          subject
        }
      }
    }
  }
`;

export interface GenerateDemoSequenceVariables {
  input: SequenceGenerationInput;
}

export interface GenerateDemoSequenceData {
  generateSequence: {
    streamId: string;
    streamEnded?: boolean;
    sequence: GeneratedSequence;
  };
}

interface UseGenerateDemoSequenceInput {
  queryOptions?: QueryHookOptions<
    GenerateDemoSequenceData,
    GenerateDemoSequenceVariables
  >;
}

type UseGenerateDemoSequenceResult = [
  (input: SequenceGenerationInput) => void,
  QueryResult<GenerateDemoSequenceData, GenerateDemoSequenceVariables> & {
    generatedSequence?: GeneratedSequence;
  },
];

const useGenerateDemoSequence = ({
  queryOptions,
}: UseGenerateDemoSequenceInput): UseGenerateDemoSequenceResult => {
  const [fetch, result] = useLazyQuery<
    GenerateDemoSequenceData,
    GenerateDemoSequenceVariables
  >(GENERATE_DEMO_SEQUENCE, {
    // use network-only as a default, if
    // defined in options it will be overridden
    fetchPolicy: 'network-only',
    ...queryOptions,
  });

  const generatedSequence = result?.data?.generateSequence.sequence;

  return [
    (input) => fetch({ variables: { input } }),
    {
      ...result,
      generatedSequence,
    },
  ];
};

export default useGenerateDemoSequence;
