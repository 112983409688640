import _ from 'underscore';
import React from 'react';
import { Modal } from 'semantic-ui-react';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import { useTranslation } from 'react-i18next';

import './CompaniesStatesEditModal.css';
import GenericTextInput from '@/components/Common/GenericTextInput';
import GenericSelect from '@/components/Common/GenericSelect';
import { getRandomString, getTranslatedText, textToId } from '../../../common';
import { CompanyStateInitialValue, CompanyStateType } from './CompaniesStates';

interface CompanyStateEditModalProps {
  initialValue?: CompanyStateInitialValue;
  onSubmit: (data: any) => void;
  mode: string;
  onClose: () => void;
}

type StateType = {
  value: CompanyStateType;
  initialValue?: CompanyStateInitialValue;
};

const CompanyStateEditModal: React.FC<CompanyStateEditModalProps> = ({
  initialValue,
  onSubmit,
  mode,
  onClose,
}) => {
  const { t } = useTranslation();
  const [state, setState] = React.useState<StateType>({
    ...(initialValue
      ? {
          initialValue,
          value: { title: initialValue.title, type: initialValue.type },
        }
      : {
          value: {
            title: {
              default: '',
            },
            type: 'prospect',
          },
        }),
  });

  const handleSubmit = () => {
    const { value } = state;
    const rawCompanyState = {
      title: value.title || { default: '' },
      type: value.type,
    };

    const getIdFromStr = (str: string) =>
      `${textToId(str)}-${getRandomString(6)}`;

    const getCompanyStateWithReadableId = () => {
      return {
        ...rawCompanyState,
        id: initialValue?.id || getIdFromStr(rawCompanyState.title.default),
      };
    };

    const companyState = getCompanyStateWithReadableId();

    onSubmit({ companyState });
  };

  const handleChangeTitle = ({ newTitle }: { newTitle: string }) => {
    const { value } = state;
    setState({
      value: {
        ...value,
        title: {
          default: newTitle,
          fr: '',
          en: '',
        },
      },
    });
  };

  const disableSubmit = !state?.value?.title;

  const options = [
    {
      value: 'prospect',
      label: getTranslatedText({
        default: 'Prospect',
        fr: 'Prospect',
        en: 'Prospect',
      }),
    },
    {
      value: 'client',
      label: getTranslatedText({
        default: 'Client',
        fr: 'Client',
        en: 'Client',
      }),
    },
    {
      value: 'archived',
      label: getTranslatedText({
        default: 'Archived',
        fr: 'Archivé',
        en: 'Archived',
      }),
    },
  ] as const;

  return (
    <GenericModal
      className='company-state-edit-modal'
      size='small'
      open
      onClose={onClose}
    >
      <Modal.Header>
        {t(`settings.companiesStates.${mode}ModalTitle`)}
      </Modal.Header>
      <Modal.Content>
        <div>
          <div className='input-container'>
            <div className='input-label'>
              {t('settings.companiesStates.form.title')}
            </div>
            <div className='input-element'>
              <GenericTextInput
                fluid
                value={getTranslatedText(state?.value?.title) || ''}
                onValue={(newTitle) => handleChangeTitle({ newTitle })}
                placeholder='Title'
                autoFocus
              />
            </div>
          </div>
          <div className='input-container'>
            <div className='input-label'>
              {t('settings.companiesStates.form.type')}
            </div>
            <div className='input-element'>
              <GenericSelect
                options={options}
                value={_.findWhere(options, { value: state.value.type })}
                onChange={(option) => {
                  setState({
                    ...state,
                    value: {
                      ...state.value,
                      ...(option?.value && {
                        type: option?.value,
                      }),
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className='company-state-edit-modal-actions'>
          <GenericButton size='big' primacy='secondary' onClick={onClose}>
            {t('common.cancel')}
          </GenericButton>
          <GenericButton
            size='big'
            onClick={handleSubmit}
            disabled={disableSubmit}
          >
            {t(`common.${mode}`)}
          </GenericButton>
        </div>
      </Modal.Actions>
    </GenericModal>
  );
};

export default CompanyStateEditModal;
