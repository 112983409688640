import React, { FC } from 'react';
import classNames from 'classnames';

import LabelDetails from '@/components/LabelDetails';
import OperatorToggle from './OperatorToggle';
import { Operator } from './types';

interface WatchCollectFilterProps {
  className: string;
  title: string;
  operator?: Operator;
  setOperator?: (value: Operator) => void;
  operatorHelp?: string;
}

const WatchCollectFilter: FC<WatchCollectFilterProps> = ({
  className,
  title,
  operator,
  setOperator,
  operatorHelp,
  children,
}) => (
  <div className={classNames(className, 'watch-collect-filter')}>
    <div className='filter-header'>
      <h3 className='filter-title'>{title}</h3>
      {setOperator && operator && (
        <div className='filter-operator'>
          <OperatorToggle operator={operator} setOperator={setOperator} />
          <LabelDetails
            className='filters-help'
            text={operatorHelp}
            position='bottom right'
            inverted={false}
            basic
          />
        </div>
      )}
    </div>
    <div className='filter-content'>{children}</div>
  </div>
);

export default WatchCollectFilter;
