import React from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import { Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { fullName } from '../../Settings/Senders/helpers';


import './OfferRecruiterConfiguration.css';

const OfferRecruiterConfiguration = ({ assignedSender, user, setModalOpen, t }) => {
  if (!assignedSender) {
    return (
      <div className='offer-recruiter-configuration'>
        <Segment className='offer-card-box'>
          <div className='offer-card-sender-text'>
            {t('offers.recruiterConfiguration.noSender')}
          </div>
          <span className='configure-text' onClick={() => setModalOpen(true)}>
            {t('offers.recruiterConfiguration.edit')}
          </span>
        </Segment>
      </div>
    );
  }

  const userHasSenderRights = assignedSender?.owner?.email === user?.email
    || _.findWhere(assignedSender.users, { email: user?.email });

  const senderWasRevoked = assignedSender && !userHasSenderRights;
  const senderMailAccountRemoved = assignedSender && _.isEmpty(assignedSender?.mailAccount);

  const errorClass = (senderWasRevoked || senderMailAccountRemoved) && 'error';
  return (
    <div className='offer-recruiter-configuration'>
      <h3 className='sender-title'>
        {t('offers.recruiterConfiguration.assignedSender')}
      </h3>

      <Segment className={classNames('offer-card-box', errorClass)}>
        <div className={classNames('offer-card-sender-text', errorClass)}>
          <div className='full-name'>
            {fullName(assignedSender)}
          </div>
          <div className='email'>
            {assignedSender?.senderAddress || ''}
          </div>
        </div>
        {senderMailAccountRemoved && (
          <div className='offer-card-error-text'>
            {t('offers.recruiterConfiguration.noMailAccount')}
          </div>
        )}
        {senderWasRevoked && !senderMailAccountRemoved && (
          <div className='offer-card-error-text'>
            {t('offers.recruiterConfiguration.revokedSender')}
          </div>
        )}
        <span className='configure-text' onClick={() => setModalOpen(true)}>
          {t('offers.recruiterConfiguration.edit')}
        </span>
      </Segment>
    </div>
  );
};

export default withTranslation('translations')(OfferRecruiterConfiguration);
