import gql from 'graphql-tag';
import TranslatableText from './fragments/TranslatableText';

export const GET_CONNECT_PAGES = gql`
  query getClientConnectPages($clientId: ID!) {
    client(id: $clientId) {
      id
      connectPages {
        id
        internalTitle
        title {
          ...TranslatableText
        }
        author {
          firstname
          lastname
          email
        }
        creationDate
        lastEditionDate
        published
        url
        targetMissionId
        description {
          type
          ... on MultiSectionsClientDescription {
            sections {
              type
              ... on SimpleClientDescriptionSection {
                title {
                  ...TranslatableText
                }
                content {
                  ...TranslatableText
                }
              }
              ... on VideoClientDescriptionSection {
                url
              }
              ... on ImageClientDescriptionSection {
                file {
                  url
                }
              }
            }
          }
        }
        connectForm {
          id
          title {
            ...TranslatableText
          }
        }
      }
    }
  }
  ${TranslatableText}
`;

export const CREATE_CONNECT_PAGE = gql`
  mutation CreateconnectPage($input: CreateConnectPageInput!) {
    connectPage {
      create(input: $input) {
        connectPage {
          id
          internalTitle
          title {
            ...TranslatableText
          }
          author {
            firstname
            lastname
            email
          }
          creationDate
          lastEditionDate
          published
          url
          targetMissionId
          description {
            type
            ... on MultiSectionsClientDescription {
              sections {
                type
                ... on SimpleClientDescriptionSection {
                  title {
                    ...TranslatableText
                  }
                  content {
                    ...TranslatableText
                  }
                }
                ... on VideoClientDescriptionSection {
                  url
                }
                ... on ImageClientDescriptionSection {
                  file {
                    url
                  }
                }
              }
            }
          }
          connectForm {
            id
            title {
              ...TranslatableText
            }
          }
        }
      }
    }
  }
  ${TranslatableText}
`;

export const DELETE_CONNECT_PAGE = gql`
  mutation DeleteConnectPage($input: DeleteConnectPageInput!) {
    connectPage {
      delete(input: $input) {
        id
      }
    }
  }
`;

export const UPDATE_CONNECT_PAGE = gql`
  mutation UpdateConnectPage($input: UpdateConnectPageInput!) {
    connectPage {
      update(input: $input) {
        internalTitle
        published
        title {
          ...TranslatableText
        }
        url
        targetMissionId
        description {
          type
          ... on MultiSectionsClientDescription {
            sections {
              type
              ... on SimpleClientDescriptionSection {
                title {
                  ...TranslatableText
                }
                content {
                  ...TranslatableText
                }
              }
              ... on VideoClientDescriptionSection {
                url
              }
              ... on ImageClientDescriptionSection {
                file {
                  url
                }
              }
            }
          }
        }
        connectForm {
          id
          title {
            ...TranslatableText
          }
        }
      }
    }
  }
  ${TranslatableText}
`;

export const PAGE_URL_EXISTS = gql`
  query isPageUrlAlreadyExisting(
    $url: String!
    $pageId: String!
    $clientId: ID!
  ) {
    client(id: $clientId) {
      id
      isPageUrlAlreadyExisting(url: $url, pageId: $pageId)
    }
  }
`;
