import React, { FC, useState, useMemo } from 'react';
import { Loader } from 'semantic-ui-react';
import _ from 'underscore';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useOffers from '@/graphql/hooks/offers/useOffers';
import useClientId from '@/hooks/router/useClientId';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import { useSearchPoolAllCandidatesCount } from '@/graphql/hooks/searchPool/useSearchPoolCounts';
import logAction from '@/common/logAction';
import DepartmentSelector from '@/components/DepartmentSelector';
import useClient from '@/graphql/hooks/clients/useClient';
import EmptyState from '@/revealComponents/EmptyState/EmptyState';
import Plus from '@/components/Reveal/Icons/Plus';
import GenericButton from '@/components/Common/GenericButton';
import Counter from './Counter';
import HideOrShowArchived from './HideOrShowArchived';
import { isOfferAttachedToUser, sortOffersForUserView } from './helpers';
import OfferTable from './OfferTable';
import NewJobIllustration from './NewJobIllustration';
import AllCandidatesIllustration from './AllCandidatesIllustration';
import NotificationsDashboard from './NotificationsDashboard';
import PowerHoursBanner from './PowerHoursBanner';
import AccountStrategistContact from './AccountStrategistContact';

import styles from './Offers.module.less';

const Offers: FC = () => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const {
    data: { client = [] },
  } = useClient(clientId);
  const {
    permissions: { watchCollect },
    departments = [],
  } = client;
  const history = useHistory();
  const { user, loading: userLoading } = useCurrentUser();
  const { offers, loading: offersLoading } = useOffers({ clientId });
  const [selectedDepartmentId, setSelectedDepartmentId] = useState('all');
  const [showArchived, setShowArchived] = useState(false);
  const { allCandidatesCount } = useSearchPoolAllCandidatesCount();

  const departmentOffers = useMemo(
    () =>
      _.filter(offers, (offer) => {
        if (selectedDepartmentId) {
          if (selectedDepartmentId === 'all') {
            return true;
          }
          const departmentId = ((offer.foldering || {}).department || {}).id;

          if (selectedDepartmentId === 'other') {
            return (
              !departmentId ||
              departmentId === 'other' ||
              departmentId === 'none'
            );
          }
          return departmentId === selectedDepartmentId;
        }
        return true;
      }),
    [offers, selectedDepartmentId],
  );

  const sortedOffers = useMemo(
    () => sortOffersForUserView(user, departmentOffers),
    [user, departmentOffers],
  );

  const archivedSortedOffers = _.where(sortedOffers, { isArchived: true });

  const userSortedOffers = _.filter(sortedOffers, (offer) =>
    isOfferAttachedToUser({ offer, user }),
  );

  const unarchivedUserSortedOffers = _.where(userSortedOffers, {
    isArchived: false,
    isSuggestedForCreation: false,
  });

  const otherSortedOffers = _.filter(
    sortedOffers,
    (offer) => !isOfferAttachedToUser({ offer, user }),
  );

  const unarchivedOtherSortedOffers = _.where(otherSortedOffers, {
    isArchived: false,
    isSuggestedForCreation: false,
  });

  if (userLoading || offersLoading) {
    return (
      <div className='menu-offers'>
        <div className='header'>{}</div>
        <div className='offers-list-placeholder'>
          <Loader active inline='centered' size='large' />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.offersPage}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t('header.parameters.sourcing')}</h1>
        {!_.isEmpty(departments) && (
          <div className={styles.departmentSelector}>
            <DepartmentSelector
              value={selectedDepartmentId}
              onChange={({ id }: { id: string }) => setSelectedDepartmentId(id)}
              departments={departments}
              withOtherOption
              withAllOption
              placeholder=''
            />
          </div>
        )}
      </div>
      <div className={styles.main}>
        <PowerHoursBanner />
        {_.isEmpty(unarchivedUserSortedOffers) &&
          _.isEmpty(unarchivedOtherSortedOffers) && (
            <div className={styles.emptyState}>
              <EmptyState
                title={t('offers.emptyState.title')}
                illustrationPath='/images/placeholders/projectsEmptyState.svg'
                innerContent={t('offers.emptyState.innerContent')}
                cta={
                  <>
                    <GenericButton
                      primacy='secondary'
                      onClick={() =>
                        history.push(`/client/${clientId}/discover/candidates`)
                      }
                      className={styles.cta}
                    >
                      {t('offers.emptyState.exploreCta')}
                    </GenericButton>
                    <GenericButton
                      primacy='primary'
                      onClick={() => {
                        logAction({
                          type: 'click-new-offer-link',
                          info: {
                            clientId,
                            from: 'offers',
                          },
                        });
                        history.push(`/client/${clientId}/new-job`);
                      }}
                      className={styles.cta}
                    >
                      <Plus />
                      {t('offers.emptyState.newJobCta')}
                    </GenericButton>
                  </>
                }
              />
            </div>
          )}
        {!_.isEmpty(unarchivedUserSortedOffers) && (
          <OfferTable
            title={t('offers.myJobs')}
            offers={unarchivedUserSortedOffers}
          />
        )}
        {!_.isEmpty(unarchivedOtherSortedOffers) && (
          <OfferTable
            title={
              _.isEmpty(unarchivedUserSortedOffers)
                ? t('offers.offers')
                : t('offers.otherJobs')
            }
            offers={unarchivedOtherSortedOffers}
          />
        )}
        {!_.isEmpty(archivedSortedOffers) && (
          <>
            <HideOrShowArchived
              showArchived={showArchived}
              setShowArchived={setShowArchived}
            />
            {showArchived && (
              <OfferTable
                title={t('offers.archivedJobs')}
                offers={archivedSortedOffers}
              />
            )}
          </>
        )}
      </div>
      <div className={styles.aside}>
        {watchCollect && (
          <section className={styles.section}>
            <AllCandidatesIllustration className={styles.illustration} />
            <div className={styles.content}>
              <h2 className={styles.title}>
                {t('offers.explore.title')}
                <Counter count={allCandidatesCount} />
              </h2>
              <p className={styles.description}>
                {t('offers.explore.description')}
              </p>
              <GenericButton
                primacy='secondary'
                onClick={() =>
                  history.push(`/client/${clientId}/discover/candidates`)
                }
              >
                {t('offers.explore.cta')}
              </GenericButton>
            </div>
          </section>
        )}
        <section className={styles.section}>
          <NewJobIllustration className={styles.illustration} />
          <div className={styles.content}>
            <h2 className={styles.title}>{t('offers.newJob.title')}</h2>
            <p className={styles.description}>
              {t('offers.newJob.description')}
            </p>
            <GenericButton
              primacy='secondary'
              onClick={() => {
                logAction({
                  type: 'click-new-offer-link',
                  info: {
                    clientId,
                    from: 'offers',
                  },
                });
                history.push(`/client/${clientId}/new-job`);
              }}
            >
              {t('offers.newJob.cta')}
            </GenericButton>
          </div>
        </section>
        <section className={styles.section}>
          <div className={styles.wide}>
            <NotificationsDashboard />
          </div>
        </section>
        <section className={styles.section}>
          <div className={styles.wide}>
            <AccountStrategistContact />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Offers;
