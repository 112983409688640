import React from 'react';
import classNames from 'classnames';
import useClientId from '@/hooks/router/useClientId';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import styles from './Placeholder.module.less';

const FragmentPopupContent = ({ clientFragment, onUnsyncFragment, t }) => {
  const clientId = useClientId();
  const isPlugin = useIsPlugin();
  if (!clientFragment) {
    return null;
  }
  return (
    <div
      className={classNames(
        styles.fragmentPopupContent,
        isPlugin && styles.fragmentPopupContentPlugin,
      )}
    >
      <span className={styles.fragmentName}>{clientFragment.name}</span>
      <span className={styles.sync}>
        {t('editor.unsyncFragment.syncedWith')}{' '}
        <a
          href={`/client/${clientId}/snippets`}
          target='_blank'
          rel='noopener noreferrer'
          className={styles.link}
        >
          {t('editor.unsyncFragment.settings')}
        </a>
      </span>
      <span
        className={styles.unlink}
        onClick={onUnsyncFragment}
        aria-hidden='true'
      >
        <i className='ri-link-unlink' />
        {t('editor.unsyncFragment.unsync')}
      </span>
    </div>
  );
};

export default FragmentPopupContent;
