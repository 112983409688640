import React, { useState /* , { useState } */ } from 'react';
import {
  HiresweetLibProvider,
  ProjectEditionForm,
  ProjectEditionSubmitButton,
  ProjectTitleEditionInput,
  createProjectTextCustomFieldEditionInput,
  createProjectIntegerCustomFieldEditionInput,
  createProjectFloatCustomFieldEditionInput,
  createProjectExplicitEnumCustomFieldEditionInput,
  createProjectDayCustomFieldEditionInput,
  ProjectContainer,
  /*
  createProjectExplicitEnumCustomFieldEditionInput,  ,
  */
} from '@hiresweet/hiresweet-lib';

import useClientId from '@/hooks/router/useClientId';

const ProjectLocalisationEditor = createProjectTextCustomFieldEditionInput({
  label: 'Localisation',
  clientCustomFieldId: 'localisation',
  isInlineText: true,
});

const ProjectImportanceEditor = createProjectIntegerCustomFieldEditionInput({
  label: 'Importance',
  clientCustomFieldId: 'importance',
});

const ProjectRatioEditor = createProjectFloatCustomFieldEditionInput({
  label: 'Ratio',
  clientCustomFieldId: 'ratio',
});

const qualificationTitleFromId = {
  a: 'A!',
  b: 'B!',
  c: 'C!',
};

const ProjectQualificationEditor =
  createProjectExplicitEnumCustomFieldEditionInput({
    label: 'Qualification',
    clientCustomFieldId: 'qualification',
    titleFromId: qualificationTitleFromId,
    sort: true,
  });

const ProjectDueDayEditor = createProjectDayCustomFieldEditionInput({
  label: 'Due Date',
  clientCustomFieldId: 'dueday',
});

const ProjectEditor = () => {
  return (
    <ProjectEditionForm mode='update'>
      <h1>Distant editor</h1>
      <ProjectTitleEditionInput label='Titre' />
      <br />
      <ProjectLocalisationEditor />
      <br />
      <ProjectImportanceEditor />
      <br />
      <ProjectRatioEditor />
      <br />
      <ProjectQualificationEditor />
      <br />
      <ProjectDueDayEditor />
      <br />
      <ProjectEditionSubmitButton label='Créer le projet' />
    </ProjectEditionForm>
  );
};

const PlaygroundB4 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  const [isEdition, setIsEdition] = useState(false);
  return (
    <div style={{ padding: 30, background: 'white' }}>
      <h1>B2 - Project Forms 2</h1>
      <HiresweetLibProvider theme={theme} clientId={clientId}>
        <h4>Update Form</h4>
        {!isEdition ? (
          <button
            type='button'
            onClick={() => {
              setIsEdition(true);
            }}
          >
            Edit
          </button>
        ) : (
          ''
        )}
        <ProjectContainer projectId='ri7-demo-testimport2-UMODD2'>
          {isEdition ? <ProjectEditor /> : ''}
        </ProjectContainer>
      </HiresweetLibProvider>
    </div>
  );
};

export default PlaygroundB4;
