import gql from 'graphql-tag';
import _ from 'underscore';
import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

const useUpdateCacheMissionLastUse = () => {
  const apolloClient = useApolloClient();

  // Plugin uses filters, so we hack this to work seamlessly
  // in both environments
  // In reality we should ask the user to pass the entire object or query, but
  // this would be really unpractical, and since it's not an essential business feature
  // we can allow some mishaps
  const readCache = useCallback(
    (jobId) => {
      try {
        const searchPoolJob = apolloClient.readQuery({
          query: gql`
            query ReadJobFromCache($searchPoolId: ID!, $jobId: ID!) {
              searchPool(id: $searchPoolId) {
                job(id: $jobId) {
                  id
                  lastUseByAuthor {
                    authorEmail
                    timestamp
                  }
                }
              }
            }
          `,
          variables: {
            jobId,
            searchPoolId: 'reveal',
          },
        });

        return searchPoolJob?.searchPool.job;
      } catch (e) {
        const searchPoolData = apolloClient.readQuery({
          query: gql`
            query ReadJobFromCache(
              $searchPoolId: ID!
              $filters: SearchPoolJobFiltersInput
            ) {
              searchPool(id: $searchPoolId) {
                jobs(filters: $filters) {
                  id
                  lastUseByAuthor {
                    authorEmail
                    timestamp
                  }
                }
              }
            }
          `,
          variables: {
            filters: {
              activeOnly: true,
            },
            searchPoolId: 'reveal',
          },
        });

        return searchPoolData?.searchPool?.jobs.find(
          ({ id }: { id: string }) => id === jobId,
        );
      }
    },
    [apolloClient],
  );

  const updateCache = useCallback(
    ({ jobId, authorEmail: currentAuthorEmail }) => {
      if (!jobId) {
        return;
      }

      const missionCachedData = readCache(jobId);
      if (!missionCachedData) {
        return;
      }

      const lastUsedWithoutCurrentAuthor = _.filter(
        missionCachedData.lastUseByAuthor,
        ({ authorEmail }) => {
          return authorEmail !== currentAuthorEmail;
        },
      );

      apolloClient.writeQuery({
        query: gql`
          query WriteLastUsedInCache($searchPoolId: ID!) {
            searchPool(id: $searchPoolId) {
              id
              job(id: $jobId) {
                id
                lastUseByAuthor {
                  authorEmail
                  timestamp
                }
              }
            }
          }
        `,
        data: {
          searchPool: {
            __typename: 'SearchPool',
            id: 'reveal',
            job: {
              __typename: missionCachedData.__typename || 'RevealJob',
              id: jobId,
              lastUseByAuthor: [
                ...lastUsedWithoutCurrentAuthor,
                {
                  authorEmail: currentAuthorEmail,
                  timestamp: new Date().getTime(),
                  __typename: 'LastUseByAuthor',
                },
              ],
            },
          },
        },
        variables: {
          jobId,
          searchPoolId: 'reveal',
        },
      });
    },
    [apolloClient, readCache],
  );

  return useCallback(
    ({ jobId, authorEmail }) => {
      try {
        updateCache({ jobId, authorEmail });
      } catch (e) {
        // pass
        console.error(e);
      }
    },
    [updateCache],
  );
};

export default useUpdateCacheMissionLastUse;
