import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { User } from '@/types/user';
import useClientId from '@/hooks/router/useClientId';
import logAction from '@/common/logAction';
import Website from '@/components/Common/Icons/Website';

import styles from './PodcastLink.module.less';

interface PodcastLinkProps {
  url: string;
  title: string;
  author: string;
  offerId: string;
  user: User;
}

const PodcastLink: FC<PodcastLinkProps> = ({
  url,
  title,
  author,
  offerId,
  user,
}) => {
  const clientId = useClientId();
  const { t } = useTranslation();
  const onClick = () => {
    logAction({
      type: 'click-article-link',
      info: {
        clientId,
        jobOfferId: offerId,
        author: `${user.firstname} ${user.lastname}`,
        url,
      },
    });
  };

  return (
    <a
      href={url}
      target='_blank'
      onClick={onClick}
      rel='noopener noreferrer'
      className={styles.link}
    >
      <div className={styles.icon}>
        <Website />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{`${t(
        'dashboard.podcast.by',
      )} ${author}`}</div>
    </a>
  );
};

export default PodcastLink;
