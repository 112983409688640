import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import withClientActivity from '../../../hocs/clients/withClientActivity';
import withUserFromJWToken from '../../../hocs/users/withUserFromJWToken';
import withActionLogger from '../../../hocs/withActionLogger';
import ActivityTimeline from '../../../components/ActivityTimeline';

const ActivityDashboard = ({
  clientId,
  clientActivity,
  clientActivityLoading,
  userFromJWTokenLoading,
  user,
  onLogAction,
  t,
}) => {
  const logAction = ({ type }) => {
    onLogAction({
      type,
      info: {
        clientId,
        from: 'activity-dashboard',
      },
    });
  };

  if (userFromJWTokenLoading || clientActivityLoading) {
    return (
      <div className='activity dashboard-section'>
        <div className='header'>
          <h1>{t('dashboard.activity.header')}</h1>
        </div>
        <div className='activity-table-placeholder'>
          <Loader active inline='centered' size='large' />
        </div>
      </div>
    );
  }

  if (_.isEmpty(clientActivity?.events)) {
    return (
      <div className='activity dashboard-section'>
        <div className='header'>
          <h1>{t('dashboard.activity.header')}</h1>
        </div>
        <div className='no-items-container'>
          <img
            src='/images/placeholders/activity.svg'
            height='155'
            alt='activity-placeholder'
          />
          <div className='no-items'>{t('dashboard.activity.empty')}</div>
        </div>
      </div>
    );
  }

  // TODO: See more
  return (
    <div className='activity dashboard-section'>
      <div className='header'>
        <h1>{t('dashboard.activity.header')}</h1>
      </div>
      <ActivityTimeline
        activityEvents={clientActivity?.events}
        clientId={clientId}
        user={user}
        logAction={logAction}
      />
    </div>
  );
};

export default compose(
  withUserFromJWToken,
  withClientActivity,
  withActionLogger,
  withTranslation('translations'),
)(ActivityDashboard);
