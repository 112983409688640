import classNames from 'classnames';
import React, { FC, useState, useContext } from 'react';
import _ from 'underscore';
import { Dropdown } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { textEllipsis } from '@/common';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import { ISearchPoolJobOption } from '@/graphql/fragments/SearchPoolJobOption';
import NewRevealJobModal from '@/components/NewRevealJobModal';
import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';
import useQueryParams from '@/hooks/router/useQueryParams';
import useCreateSearchPoolJob from '@/graphql/hooks/searchPoolJobs/useCreateSearchPoolJob';
import { ADVENTURE, ATStype } from '@/common/reveal';
import { useClientCurrentAtsId } from '@/graphql/hooks/clients/useClientRevealProjects';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import useMiniMission from '@/graphql/hooks/searchPoolJob/useMiniMission';

import styles from '../../components/JobBreadCrumb.module.less';
import './JobSelector.css';

const MAX_N_CHARACTERS = 30;

interface GenericDropdownProps {
  clientId: string;
  jobId: string;
  jobs: ISearchPoolJobOption[];
  targetPage: string;
  searchPoolId: string;
  shouldHideNewProjectButton?: boolean;
}

function getDropdownItemJobTitle({
  job,
  atsId,
}: {
  job: ISearchPoolJobOption;
  atsId: ATStype;
}) {
  const defaultTitle = textEllipsis(job.data?.title || '', MAX_N_CHARACTERS);
  if ([ADVENTURE].includes(atsId)) {
    const agencyCode =
      _.findWhere(job.metadata || [], { key: 'agencyCode' })?.value ?? null;
    if (!agencyCode) {
      return defaultTitle;
    }
    return `${agencyCode}-${defaultTitle}`;
  }

  return defaultTitle;
}

const GenericDropdown: FC<GenericDropdownProps> = ({
  clientId,
  jobId,
  jobs = [],
  targetPage,
  searchPoolId,
  shouldHideNewProjectButton,
}) => {
  const { t } = useTranslation();
  const atsId = useClientCurrentAtsId();
  const history = useHistory();
  const queryParams = useQueryParams<{
    segmentId?: string;
    segmentationId?: string;
  }>();
  const [newJobModalOpen, setNewJobModalOpen] = useState(false);
  const { sortProjectsAlphabeticallyInBreadcrumbs } =
    useMergedConfigurationParams();
  const missionCategory = useContext(MissionCategoryContext);

  const [
    createNewJobMutation,
    { loading: creatingNewJob, data: createJobData },
  ] = useCreateSearchPoolJob();

  const displayedJobs = _.filter(jobs, ({ isArchived }) => !isArchived);

  if (_.isEmpty(displayedJobs)) {
    return null;
  }

  const sortedJobs =
    sortProjectsAlphabeticallyInBreadcrumbs === 'true'
      ? _.sortBy(displayedJobs, (job) =>
          getDropdownItemJobTitle({ job, atsId }),
        )
      : displayedJobs;

  const onClickAllJobs = () => {
    history.push(`/client/${clientId}/reveal/projects/${missionCategory}`);
  };

  const onClickItem = (targetJob: ISearchPoolJobOption) => {
    history.push(
      `/client/${clientId}/reveal/projects/${missionCategory}/${
        targetJob.id
      }/${targetPage}${
        targetPage === 'profiles' &&
        queryParams?.segmentationId &&
        queryParams?.segmentId
          ? `?segmentationId=${queryParams.segmentationId}&segmentId=${queryParams?.segmentId}`
          : ''
      }`,
    );
  };

  return (
    <>
      <div className={classNames('generic-dropdown', styles.breadCrumbSection)}>
        <Dropdown
          className='hs-dropdown'
          icon={<i className='ri-arrow-drop-down-fill' />}
          onClick={(e) => e.preventDefault()}
        >
          <Dropdown.Menu className='job-selector-menu'>
            {_.map(sortedJobs, (targetJob, index) => (
              <Dropdown.Item
                key={index}
                className={classNames(
                  'large-item',
                  jobId && targetJob.id === jobId && 'current',
                )}
                onClick={(e) => {
                  e.preventDefault();
                  onClickItem(targetJob);
                }}
              >
                <span>
                  {getDropdownItemJobTitle({ job: targetJob, atsId })}
                </span>
              </Dropdown.Item>
            ))}
            <Dropdown.Item
              className='large-item'
              onClick={(e) => {
                e.preventDefault();
                onClickAllJobs();
              }}
            >
              {t('missions.header.allMissions')}
            </Dropdown.Item>
            {!shouldHideNewProjectButton && (
              <Dropdown.Item className='large-item item-new-job'>
                <div className='new-job-button-container'>
                  <GenericButton
                    onClick={(e) => {
                      e.preventDefault();
                      setNewJobModalOpen(true);
                    }}
                  >
                    <Plus />
                    {t('missions.header.newMission')}
                  </GenericButton>
                </div>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <NewRevealJobModal
        open={newJobModalOpen}
        onClose={() => setNewJobModalOpen(false)}
        searchPoolId={searchPoolId}
        clientId={clientId}
        onSubmit={createNewJobMutation}
        loading={creatingNewJob}
        queryResponse={createJobData}
      />
    </>
  );
};

interface JobSelectorProps {
  clientId: string;
  jobId?: string;
  targetPage: string;
  searchPoolId: string;
  shouldHideNewProjectButton?: boolean;
}

const JobSelector: FC<JobSelectorProps> = ({
  clientId,
  jobId,
  targetPage,
  searchPoolId,
  shouldHideNewProjectButton,
}) => {
  const missionCategoryType = useContext(MissionCategoryContext);
  const { jobOptions } = useSearchPoolJobOptions(searchPoolId, {
    missionCategory: { type: missionCategoryType },
  });
  const { miniMission: currentJob } = useMiniMission(jobId ?? '', {
    queryOptions: {
      skip: !jobId,
    },
  });
  const queryParams = useQueryParams<{
    segmentId?: string;
    segmentationId?: string;
  }>();

  if (!jobId) {
    return null;
  }

  const currentJobTitle = currentJob?.data?.title || '';

  const cleanJobs = _.filter(jobOptions, (jobOption) => !jobOption.isArchived);

  return (
    <>
      <Link
        to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${jobId}${
          queryParams?.segmentationId && queryParams?.segmentId
            ? `/profiles?segmentationId=${queryParams.segmentationId}&segmentId=${queryParams?.segmentId}`
            : ''
        }`}
        className={classNames(styles.breadCrumbSection, styles.collapsible)}
      >
        {currentJobTitle}
      </Link>
      <GenericDropdown
        clientId={clientId}
        jobId={jobId}
        jobs={cleanJobs}
        targetPage={targetPage}
        searchPoolId={searchPoolId}
        shouldHideNewProjectButton={shouldHideNewProjectButton}
      />
    </>
  );
};

export default JobSelector;
