import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './GenericButtonGroup.module.less';

// This button group performs some magic to auto-add necessary classes to buttons
// Without forcing us to modify the button component or add specific group-css to the button definition
const GenericButtonGroup: FC = React.memo(({ children }) => {
  const classNamedButtons = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    // Clone child to add classname without bothering developer
    // or linkeing button to groups
    return React.cloneElement(child, {
      ...child.props,
      className: classNames(child.props?.className, styles.buttonInGroup),
    });
  });

  return <div className={styles.buttonGroup}>{classNamedButtons}</div>;
});

export default GenericButtonGroup;
