import { gql, useMutation } from '@apollo/client';

const UPDATE_SHARED_MESSAGING_ACCOUNT = gql`
  mutation UpdateUnipileConnection($input: updateUnipileConnectionInput!) {
    updateUnipileConnection(input: $input) {
      id
      name
      users {
        email
      }
    }
  }
`;

export function useUpdateSharedMessagingAccount() {
  return useMutation(UPDATE_SHARED_MESSAGING_ACCOUNT);
}
