import React from 'react';
import { compose } from 'underscore';
import { useTranslation } from 'react-i18next';
import { PositiveColor } from '@/less/colors';
import { getFullname } from '@/common/helpers/person';
import Thumbnail from '../../../../../components/Thumbnail';
import withOffer from '../../../../../hocs/offers/withOffer';
import { formatDate } from '../../../../../common/dates';
import AuthorAndDate from '../AuthorAndDate';

const ClassicApplicationItem = ({
  item,
  unbiasedModeEnabled,
  profile,
  offer,
}) => {
  const { t } = useTranslation();
  const { date } = item || {};
  const { firstname, lastname, photoLink, email } = profile?.resumeData || {};

  let profileName = `${firstname || ''} ${lastname || ''}`.trim();
  profileName = unbiasedModeEnabled ? '???' : profileName;

  return (
    <div
      className='timeline-card-mail-wrapper classic-item'
      style={{ bottom: 0 }}
    >
      <div className='content'>
        <div className='header'>
          <Thumbnail
            link={photoLink}
            firstname={firstname}
            lastname={lastname}
            email={email}
          />
          <div className='author'>
            <div className='date'>{date && formatDate(date, t)}</div>
            {t('profile.contact.timeline.application.description', {
              profileName,
              jobTitle: offer?.title,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const RevealApplicationItem = ({
  item,
  unbiasedModeEnabled,
  profile,
  offer,
}) => {
  const { t } = useTranslation();

  let profileName = getFullname(profile?.resumeData);
  profileName = unbiasedModeEnabled ? '???' : profileName;

  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div style={{ backgroundColor: PositiveColor }} className='icon'>
              <i className='ri-add-circle-line ri-2x' />
            </div>
          </div>
          <div className='author'>
            <div
              dangerouslySetInnerHTML={{
                __html: t('profile.contact.timeline.application.description', {
                  profileName,
                  jobTitle: offer?.title,
                }),
              }}
            />
            <AuthorAndDate timelineItem={item} />
          </div>
        </div>
      </div>
    </div>
  );
};

const ApplicationItem = ({
  item,
  unbiasedModeEnabled,
  profile,
  offer,
  applicationMode,
}) => {
  if (applicationMode === 'classic') {
    return (
      <ClassicApplicationItem
        item={item}
        profile={profile}
        unbiasedModeEnabled={unbiasedModeEnabled}
        offer={offer}
      />
    );
  }
  return (
    <RevealApplicationItem
      profile={profile}
      item={item}
      unbiasedModeEnabled={unbiasedModeEnabled}
      offer={offer}
    />
  );
};

export default compose(withOffer)(ApplicationItem);
