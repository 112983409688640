import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import Search from '@/components/Reveal/Icons/Search';
import GenericTextInput from '@/components/Common/GenericTextInput';
import Counter from '@/components/Common/Counter/Counter';
import SavedSearchSelector from '@/components/Reveal/SavedSearch/SavedSearchSelector';
import useSavedSearchParams from '@/graphql/hooks/searchPool/useSavedSearchParams';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';

import { cleanSearchParams } from './utils';
import RevealSearchPane from './SearchPane';
import CandidatesListPane from './CandidatesListPane';
import SubHeader from './SubHeader';
import AICriteriaGenerator from './AICriteriaGenerator';

import './SearchView.css';

const SearchView = ({ clientId, revealProject, mode, jobId }) => {
  const { t } = useTranslation();
  const { savedSearchParams, loadingSavedSearchParams, setSavedSearchParams } =
    useSavedSearchParams({ mode, jobId });
  const [searchParams, setSearchParams] = useState(savedSearchParams || {});
  const [searchText, setSearchText] = useState('');
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const hasLoadedSavedSearchParams = useRef(false);
  const [resultCount, setResultCount] = useState(0);
  const [hasFilters, setHasFilters] = useState(false);
  const { data } = useClientPermissions(clientId);

  const { canHandleCompanyContacts, canEditHumanContactSubtypes } =
    data?.client.permissions || {};

  const handleUpdateSearchParams = ({ newSearchParams }) => {
    setSearchParams(newSearchParams);
    setSavedSearchParams(newSearchParams);
  };

  useEffect(() => {
    if (!loadingSavedSearchParams && !hasLoadedSavedSearchParams.current) {
      hasLoadedSavedSearchParams.current = true;
      setSearchParams(savedSearchParams);
    }

    // eslint-disable-next-line
  }, [savedSearchParams]);

  useEffect(() => {
    if (_.isEmpty(searchParams?.criteria)) {
      setHasFilters(false);
    } else {
      setHasFilters(true);
    }
  }, [searchParams]);

  // Add filter for human contacts (as opposed to companies)
  const searchParamsWithHumanFilter = useMemo(
    () =>
      !canHandleCompanyContacts
        ? searchParams
        : {
            ...searchParams,
            criteria: {
              ...searchParams?.criteria,
              categoryFilters: searchParams?.criteria?.categoryFilters || [
                {
                  filterType: 'in',
                  categories: [
                    {
                      type: 'human',
                      subtypes: canEditHumanContactSubtypes
                        ? [{ id: 'hiring' }, { id: null }]
                        : undefined,
                    },
                  ],
                },
              ],
            },
          },
    [searchParams, canHandleCompanyContacts, canEditHumanContactSubtypes],
  );

  // NOTE: normalize searchParams with removeEmptyKeyValues
  // (an empty key can cause duplicate Query for the same result,
  // because the variables wouldn't exactly match in another component)
  const searches = [{ free: cleanSearchParams(searchParamsWithHumanFilter) }];

  const overwriteCriteria = useCallback(
    ({ free }) => setSearchParams(free ? JSON.parse(free) : {}),
    [],
  );

  return (
    <>
      {(canHandleCompanyContacts || canEditHumanContactSubtypes) && (
        <SubHeader
          initialValue={[
            searchParams?.criteria?.categoryFilter?.categories?.[0]
              ?.subtypes?.[0]?.id,
          ]}
          onValue={(subtypes) =>
            handleUpdateSearchParams({
              newSearchParams: {
                ...searchParams,
                criteria: {
                  ...searchParams.criteria,
                  categoryFilters: [
                    {
                      filterType: 'in',
                      categories: [
                        {
                          type: 'human',
                          subtypes: subtypes.map((id) => ({ id })),
                        },
                      ],
                    },
                  ],
                },
              },
            })
          }
        />
      )}
      <div className='search-view'>
        <div className='left-pane'>
          {canHandleCompanyContacts ? (
            <h3 className='search-view-search'>
              {t('common.search')}
              <Counter value={resultCount} active={resultCount > 0} />
            </h3>
          ) : (
            <h2 className='search-view-title'>
              {t('reveal.searchView.title')}
              <Counter value={resultCount} active={resultCount > 0} />
            </h2>
          )}
          <GenericTextInput
            value={searchText}
            onValue={setSearchText}
            placeholder={t('header.search.searchProfiles')}
            icon={<Search />}
            className='contact-filter-by-text'
          />
          <SavedSearchSelector
            currentCriteria={searches?.[0]}
            overwriteCriteria={overwriteCriteria}
          />
          <AICriteriaGenerator overwriteCriteria={overwriteCriteria} />
          <RevealSearchPane
            clientId={clientId}
            projectId={revealProject.id}
            searchParams={searchParams}
            onUpdateSearchParams={handleUpdateSearchParams}
            searchModalOpen={searchModalOpen}
            setSearchModalOpen={setSearchModalOpen}
          />
        </div>
        <CandidatesListPane
          clientId={clientId}
          projectId={revealProject.id}
          jobId={jobId}
          searchPoolId='reveal'
          searches={searches}
          searchText={searchText}
          setSearchText={setSearchText}
          setResultCount={setResultCount}
          hasFilters={hasFilters}
          showActiveSequence
        />
      </div>
    </>
  );
};

export default SearchView;
