import gql from 'graphql-tag';
import TranslatableText from './fragments/TranslatableText';

export const UPDATE_MISSION_INTERESTED_SUB_STEPS = gql`
  mutation updateMissionInterestedSubSteps(
    $input: SubPipelineStepsInput!
    $searchPoolId: ID!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      updateMissionPipeline(input: $input) {
        job {
          ... on RevealJob {
            interestedSubPipeline {
              id
              steps {
                id
                title {
                  ...TranslatableText
                }
                previousStepId
                isDisabled
              }
              title {
                ...TranslatableText
              }
            }
          }
        }
      }
    }
  }
  ${TranslatableText}
`;
