import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SmallIntegration from '../../graphql/fragments/SmallIntegration';
import getOfferWorkflows from './getOfferWorkflows';

export const mutation = gql`
  mutation addWorkableWorkflow(
    $clientId: ID!
    $jobOfferId: ID!
    $rule: WorkflowRuleInput!
    $action: WorkableWorkflowActionInput!
  ) {
    addWorkableWorkflow(
      clientId: $clientId
      jobOfferId: $jobOfferId
      rule: $rule
      action: $action
    ) {
      ...SmallIntegration
    }
  }
  ${SmallIntegration}
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    addWorkableWorkflow: ({ clientId, jobOfferId, rule, action }) =>
      mutate({
        variables: {
          clientId,
          jobOfferId,
          rule,
          action,
        },
        refetchQueries: [
          {
            query: getOfferWorkflows,
            variables: {
              id: jobOfferId,
            },
          },
        ],
      }),
  }),
});
