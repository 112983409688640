import React, { CSSProperties, FC } from 'react';
import { Placeholder } from 'semantic-ui-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useMiniMission from '@/graphql/hooks/searchPoolJob/useMiniMission';
import { Segment } from '../PipelineStatisticsByCategory/helpers/segments';

import styles from './ActionColumn.module.less';

interface ActionColumnProps {
  missionId: string;
  segment: Segment;
  day: string;
  profileLoading?: boolean;
  className?: string;
  style?: CSSProperties;
}

const ActionColumn: FC<ActionColumnProps> = ({
  missionId,
  segment,
  day,
  profileLoading,
  className,
  style,
}) => {
  const { t, i18n } = useTranslation();

  const { loading, miniMission } = useMiniMission(missionId);

  const segmentName = t(`reveal.pipelineSegmentations.${segment}`);

  const actionDescription = t(
    'reveal.reports.missions.moveTo.detailsModal.action',
    {
      segment: segmentName,
      date: new Date(day).toLocaleString(i18n.resolvedLanguage, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
    },
  );

  return (
    <div className={classNames(styles.action, className)} style={style}>
      {loading || profileLoading ? (
        <Placeholder>
          <Placeholder.Line length='short' />
        </Placeholder>
      ) : (
        <>
          <div className={styles.missionName}>
            {miniMission?.data?.title || '???'}
          </div>
          <div className={styles.description}>{actionDescription}</div>
        </>
      )}
    </div>
  );
};

export default ActionColumn;
