import _ from 'underscore';

import { ClientMissionDailyEvent } from '@/types/statistics/clientStatistics';
import { DailyEvent } from '@/types/statistics/dailyEvent';
import { StackItem } from '@/types/statistics/stackItem';
import { DataVizColors } from '@/less/colors';

export function formatDailyEvent({
  day,
  details,
}: ClientMissionDailyEvent): DailyEvent {
  const values = {} as Record<string, number>;

  _.each(details, (detail) => {
    if (detail.type === 'nbSourced') {
      return;
    }
    const { count } = detail;
    const removalReason = coerceRemovalReason(detail.removalReason);
    values[removalReason] = (values[removalReason] || 0) + count;
  });

  return {
    day,
    values,
  };
}

export function coerceRemovalReason(removalReason?: string): string {
  if (!removalReason || removalReason === 'null') {
    return 'Inconnue';
  }
  return removalReason;
}

export function buildStack(dailyEvents: DailyEvent[]): StackItem[] {
  const stackItemMap = {} as Record<string, StackItem>;
  let itemCount = 0;

  _.each(dailyEvents, ({ values }) => {
    _.each(values, (_value, key) => {
      if (stackItemMap[key]) {
        return;
      }
      stackItemMap[key] = {
        datakey: key,
        displayedText: key,
        color: DataVizColors[itemCount % DataVizColors.length],
      };
      itemCount += 1;
    });
  });

  return _.values(stackItemMap);
}
