import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { MergeTagsBaseExample } from '@/common/mergeTags/utils';

import styles from './SmartVariableWithPreviewModal.module.less';

type Props<T> = {
  examples: T[];
  calculationFunction: (
    example: T,
  ) => { calculatedValue: string; rawValue: unknown };
  examplePreviewTranslationKey: string;
};

const ExamplesPreview = <T extends MergeTagsBaseExample>(props: Props<T>) => {
  const { examples, calculationFunction, examplePreviewTranslationKey } = props;
  const { t } = useTranslation();

  return (
    <>
      {_.map(examples, (example) => {
        const { id, text } = example;
        const { calculatedValue, rawValue } = calculationFunction(example);

        const displayedText = text.replace(/\{#([^{}]*)#}/g, () => {
          if (!calculatedValue || _.isEmpty(calculatedValue)) {
            return `<span class='merge-tags-preview-red-text'>No Value</span>`;
          }
          return `<span class='merge-tags-preview-blue-text'>${calculatedValue}</span>`;
        });

        return (
          <div key={id} className={styles.example}>
            <span className={styles.subtitle}>
              {!rawValue || (Array.isArray(rawValue) && _.isEmpty(rawValue))
                ? t('editor.kLastCompaniesVariables.examples.empty')
                : t(examplePreviewTranslationKey, {
                    rawValue,
                  })}
            </span>
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{ __html: displayedText }}
            />
          </div>
        );
      })}
    </>
  );
};

export default ExamplesPreview;
