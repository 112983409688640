import { QueryResult, useQuery } from '@apollo/client';
import { GET_AGGREGATED_CONTACTS } from '@/graphql/statistics';

interface AggregatedContactsResult {
  client: {
    id: string;
    statistics: {
      aggregatedContacts: {
        categories: {
          categorizationId: string;
          categoryId: string;
        }[];
        count: number;
      }[];
    };
  };
}

interface AggregatedContactsVariables {
  clientId?: string;
  categorizations: string[];
}

const useAggregatedContacts = (
  clientId: string,
  categorizations: string[],
): QueryResult<AggregatedContactsResult, AggregatedContactsVariables> =>
  useQuery(GET_AGGREGATED_CONTACTS, {
    variables: { clientId, categorizations } as AggregatedContactsVariables,
    fetchPolicy: 'network-only',
  });

export default useAggregatedContacts;
