import gql from 'graphql-tag';
import TranslatableItem from './TranslatableItem';

export default gql`
  fragment GradualSignificance on GradualSignificance {
      required {
        ...TranslatableItem
      }
      important {
        ...TranslatableItem
      }
      bonus {
        ...TranslatableItem
      }
  }
  ${TranslatableItem}
`;
