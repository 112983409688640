import gql from 'graphql-tag';
import GradualSignificance from './GradualSignificance';
import TranslatableItem from './TranslatableItem';

export default gql`
  fragment OfferSearchCriteria on Criteria {
    jobPositions {
      ...GradualSignificance
    }
    locationsAndRemote {
      target {
        location {
          id
        }
        remoteWish
      }
      okWith {
        location {
          id
        }
        remoteWish
      }
    }
    experience {
      years {
        min
        max
      }
    }
    salary {
      min
      max
      currency
    }
    skills {
      ...GradualSignificance
    }
  }
  ${TranslatableItem}
  ${GradualSignificance}
`;
