import React from 'react';

import './Layout.css';

const Layout = ({ children, title, subtitle, details }) => {
  return (
    <div className='landing-layout'>
      <img alt='Hiresweet' className='hiresweet-logo' src='/images/logos/new-logo.svg'/>
      <div className='layout-content-container'>
        <div className='layout-content'>
          <div className='layout-title-container'>
            <div className='layout-title'>
              <h2 className='title'>{title}</h2>
              {subtitle && (<div className='subtitle'>{subtitle}</div>)}
              {details && (<div className='details'>{details}</div>)}
            </div>
          </div>
          <div className='layout-form'>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
