import gql from 'graphql-tag';
import BaseMailTemplate, { BaseMailTemplateFragment } from '@/graphql/fragments/BaseMailTemplate';

export interface GetClientTemplatesResult {
  client: {
    id: string;
    templates: Array<BaseMailTemplateFragment>
  }
}
export interface GetClientTemplatesVariables {
  clientId: string;
}
export const GET_CLIENT_TEMPLATES = gql`
  query getClientTemplates($clientId: ID!) {
    client(id: $clientId) {
      id
      templates {
        ...BaseMailTemplate
      }
    }
  }
  ${BaseMailTemplate}
`;
