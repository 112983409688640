import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import SnoozeReasonsByDate from '../AdventureDashboard1/SnoozeReasonsByDate';
import SnoozeReasonsOverview from '../AdventureDashboard1/SnoozeReasonsOverview';

import TasksProcessing from './TasksProcessing';

import styles from './AdventureDashboard.module.less';

const AdventureDashboard2: FC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <TasksProcessing />
      <SnoozeReasonsByDate />
      <div className={styles.card}>
        <div className={styles.header}>
          <h3 className={styles.title}>
            {t('reveal.reports.adventure.snoozeReasonsOverview.title')}
          </h3>
        </div>
        <div className={styles.content}>
          <SnoozeReasonsOverview />
        </div>
      </div>
    </div>
  );
};

export default AdventureDashboard2;
