import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Form, Button } from 'semantic-ui-react';
import sanitizeTimeLineItem from '../../../../common/sanitizeTimeLineItem';
import createActionInputFromAction from '../../../../common/contactFlow/createActionInputFromAction';
import withDeleteProfileContactFlowAction from '../../../../hocs/profiles/withDeleteProfileContactFlowAction';
import withUpdateProfileContactFlowAction from '../../../../hocs/profiles/withUpdateProfileContactFlowAction';
import { getTranslatedDateOrDuration } from '../ProfileContactFlow/helpers';
import getPermissionsAndNudgesAtIndex from '../../../../common/contactFlow/getPermissionsAndNudgesAtIndex';

import EmailEditor from '../../../Editor';
import Thumbnail from '../../../../components/Thumbnail';
import ActionTriggerEditor from '../../../../components/contactFlow/ActionTriggerEditor';

const PendingAction = ({
  profile,
  action,
  sequenceId,
  clientId,
  actions,
  deleteProfileContactFlowAction,
  updateProfileContactFlowAction,
  t,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [triggerState, setTriggerState] = useState(action.trigger);
  if (!action) {
    return null;
  }
  if (action?.type !== 'send-email-action') {
    return null;
  }
  const { message, plannedExecutionDate, trigger, completion } = action || {};
  if (completion?.isCompleted) {
    return null;
  }
  if (!message) {
    return null;
  }
  const { sender, body } = message || {};
  const onDeleteContactFlowAction = async () => {
    await deleteProfileContactFlowAction({
      id: profile.id,
      sequenceId,
      actionId: action.actionId,
    });
  };

  const onUpdatePendingAction = async ({ body: newBody }) => {
    const newAction = {
      ...action,
      trigger: triggerState,
      message: {
        ...action.message,
        body: newBody,
      },
    };
    const contactFlowActionInput = createActionInputFromAction({
      action: newAction,
    });
    await updateProfileContactFlowAction({
      id: profile?.id,
      sequenceId,
      actionId: action.actionId,
      contactFlowActionInput,
    });
    setEditMode(false);
  };

  const index = _.findIndex(actions, (a) => a.actionId === action.actionId);

  return (
    <div className='timeline-card-mail-wrapper classic-item'>
      <div className='content'>
        <div className='header'>
          <Thumbnail
            firstname={sender?.firstname}
            lastname={sender?.lastname}
            email={sender?.senderAddress}
          />

          <div className='author'>
            <div className='date'>
              {editMode ? (
                <ActionTriggerEditor
                  permissionsAndNudges={getPermissionsAndNudgesAtIndex({
                    actions,
                    index,
                    editType: 'update',
                    t,
                  })}
                  action={{ ...action, trigger: triggerState }}
                  onUpdate={({ action: newAction }) =>
                    setTriggerState(newAction.trigger)
                  }
                />
              ) : (
                getTranslatedDateOrDuration({
                  plannedExecutionDate,
                  trigger,
                  t,
                })
              )}
            </div>
            <span>
              {sender?.senderAddress &&
                `${t('profile.contact.timeline.from')} ${
                  sender?.senderAddress
                } `}
              {`${t('profile.contact.timeline.to')} ${profile?.email || ''}`}
            </span>
          </div>
          <div className='buttons-container'>
            {editMode ? (
              <button
                className='modify-button'
                type='button'
                onClick={() => {
                  setEditMode(false);
                  setTriggerState(action.trigger);
                }}
              >
                {t('profile.contact.timeline.cancel')}
              </button>
            ) : (
              <button
                className='modify-button'
                type='button'
                onClick={() => setEditMode(true)}
              >
                {t('profile.contact.timeline.edit')}
              </button>
            )}

            <button
              className='trash-button'
              type='button'
              onClick={onDeleteContactFlowAction}
            >
              <img className='trash-svg' src='/images/icons/trash.svg' alt='' />
            </button>
          </div>
        </div>
        <div className={`card-content comment${editMode ? ' edit-mode' : ''}`}>
          {editMode ? (
            <PendingActionEditor
              clientId={clientId}
              profileId={profile?.id}
              body={body}
              onUpdatePendingAction={onUpdatePendingAction}
              t={t}
            />
          ) : (
            <EmailBody content={body} />
          )}
        </div>
      </div>
    </div>
  );
};

const EmailBody = React.memo(({ content }) => {
  return (
    <div
      className='body'
      dangerouslySetInnerHTML={{ __html: sanitizeTimeLineItem(content) }}
    />
  );
});

const PendingActionEditor = ({
  clientId,
  profileId,
  body,
  onUpdatePendingAction,
  t,
}) => {
  const [newBody, setNewBody] = useState(body);
  return (
    <div className='comment-content'>
      <Form>
        <EmailEditor
          clientId={clientId}
          profileId={profileId}
          defaultValue={body}
          mode='comment'
          placeholder={t('editor.placeholder.emailBody')}
          onChange={setNewBody}
        />
        <Button
          onMouseDown={() => onUpdatePendingAction({ body: newBody })}
          className='primary-cta save-comment-button'
        >
          {t('profile.contact.timeline.save')}
        </Button>
      </Form>
    </div>
  );
};

export default compose(
  withUpdateProfileContactFlowAction,
  withDeleteProfileContactFlowAction,
  withTranslation('translations'),
)(PendingAction);
