import classnames from 'classnames';
import React from 'react';
import styles from './ColorPicker.module.less';

interface ColorPickerProps {
  selectedColor: string;
  setSelectedColor: (color: string) => void;
  className?: any;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  selectedColor,
  setSelectedColor,
  className,
}) => {
  return (
    <div
      className={classnames(className, styles.container)}
      style={{
        background: selectedColor,
      }}
    >
      <input
        type='color'
        value={selectedColor}
        onChange={(e) => setSelectedColor(e.target.value)}
      />
    </div>
  );
};

export default ColorPicker;
