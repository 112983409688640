import i18n from 'i18next';
import * as Sentry from '@sentry/browser';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import { enUS, fr } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';

require('moment/locale/fr');
require('moment/locale/es');

registerLocale('fr', fr);
registerLocale('en', enUS);

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    load: 'unspecific',
    debug: false,
    saveMissing: true,
    supportedLngs: ['en', 'fr'],

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      // (value, formatting, lng)
      format: (value, formatting) => {
        if (value instanceof Date) {
          if (formatting === 'relative') {
            return moment(value).fromNow();
          }
          return moment(value).format(formatting);
        }
        return value.toString();
      },
    },
    returnObjects: true,

    react: {
      useSuspense: true,
      defaultTransParent: 'span',
    },
  });

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

// i18n.changeLanguage('en');

i18n.on('missingKey', (lang, namespace, key) => {
  const error = new Error(`Missing translation key in ${lang}: ${key}`);
  if (process.env.NODE_ENV !== 'production') {
    console.error(error); // eslint-disable-line no-console
  }

  // Sentry is enabled on production only
  Sentry.captureException(error);
});

export default i18n;
