import classnames from 'classnames';
import React from 'react';
import styles from './GenericTitle.module.less';

interface GenericTitleProps {
  children: React.ReactNode;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  color?: 'cobalt' | 'blue';
  font?: 'Graphik' | 'Gilroy';
  style?: any;
}

const GenericTitle: React.FC<GenericTitleProps> = ({
  children,
  as = 'h2',
  color = 'cobalt',
  font = 'Graphik',
  style,
}) => {
  const As = as;
  return (
    <As
      className={classnames(styles.genericTitle, styles[color], styles[font])}
      style={style}
    >
      {children}
    </As>
  );
};

export default GenericTitle;
