import moment from 'moment';

export const parseDate = (date) => {
  if (date?.year) {
    return moment([date.year, date.month || 0]);
  }

  if (date?.raw && date?.raw.match(/\d{4}/)) {
    return moment([+date.raw]);
  }

  const parsed = date?.raw && moment(date?.raw);

  return parsed && parsed.isValid() ? parsed : null;
};

/**
 * To be used in conjunction with getDurationInYearsAndMonths for experience durations
 */
export const getDateDiffInMs = (startDateStr, endDateStr) => {
  let start;
  try {
    // TODO: approximation at start of month
    start = moment(startDateStr).startOf('month');
  } catch (e) {
    console.warn('Invalid start date:', startDateStr, 'of type', typeof startDateStr);
    return 0;
  }
  let end;
  try {
    // TODO: approximation at end of month (to match LinkedIn estimation)
    end = moment(endDateStr).endOf('month');
  } catch (e) {
    console.warn('Invalid end date:', endDateStr, 'of type', typeof endDateStr);
    return 0;
  }
  return end.diff(start);
};


export const getMomentDiffInMs = (startMoment, endMoment) => {
  let start;
  try {
    // TODO: approximation at start of month
    start = startMoment.startOf('month');
  } catch (e) {
    console.warn('Invalid start moment:', startMoment, 'of type', typeof endMoment);
    return 0;
  }
  let end;
  try {
    // TODO: approximation at end of month (to match LinkedIn estimation)
    end = endMoment.endOf('month');
  } catch (e) {
    console.warn('Invalid end moment:', endMoment, 'of type', typeof endMoment);
    return 0;
  }
  return end.diff(start);
};

export const getDurationInYearsAndMonths = ({ durationInMs, t }) => {
  const momentDuration = moment.duration(durationInMs);
  const years = momentDuration.years();
  const yearsPart = years
    ? `${years} ${t('common.duration.yearsShort', { count: years })}`
    : '';

  const months = momentDuration.months();
  const monthsParts = months
    ? `${months} ${t('common.duration.monthsShort', { count: months })}`
    : '';
  return `${yearsPart} ${monthsParts}`.trim();
};
