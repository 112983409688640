import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './UnsubscribedPill.module.less';

interface UnsubscribedPillProps {
  showLabel?: boolean;
}

const UnsubscribedPill: React.FC<UnsubscribedPillProps> = ({
  showLabel = true,
}) => {
  const { t } = useTranslation();

  return (
    <div className={style.pill}>
      <i className='ri-chat-off-line' />
      {showLabel && t('reveal.profileModal.privacy.unsubscribed')}
    </div>
  );
};

export default UnsubscribedPill;
