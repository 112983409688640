import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { EvaluationsSet } from '../../graphql/fragments/Evaluations';

export const query = gql`
  query getProfileEvaluationsSets($id: ID!) {
    profile(id: $id) {
      id
      evaluationsSets {
        ...EvaluationsSet
      }
    }
  }
  ${EvaluationsSet}
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.profileId,
    },
  }),
  props: ({ data: { loading, profile, error } }) => ({
    loading,
    loadingProfileEvaluationsSets: loading,
    profileEvaluationsSets: profile?.evaluationsSets,
    error,
  }),
});
