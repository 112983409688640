import _ from 'underscore';

const formatOfferOptions = (offers) => {
  const options = _.map(offers, (offer) => {
    if (offer?.isUnclassified) {
      return null;
    }
    return {
      key: offer.id,
      value: offer.id,
      text: offer.title,
    };
  });
  // NOTE: _.sortBy(options, 'text') does not take caps into account ('a' > 'B')
  return _.compact(options).sort((a, b) => {
    return a.text.localeCompare(b.text);
  });
};

export default formatOfferOptions;
