import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import useHasOverflow from '../hooks/common/useHasOverflow';

import './ExpandableContainer.css';

const ExpandableContainer = ({ className, render, alwaysExpanded }) => {
  const { containerRef, contentRef, containerHasOverflow } = useHasOverflow();
  const [showMore, setShowMore] = useState(false);
  const [hasShowMore, setHasShowMore] = useState(!alwaysExpanded); // force overflow on init so mask CSS can be applied once

  useEffect(() => {
    if (alwaysExpanded) {
      return;
    }

    if (containerHasOverflow) {
      setHasShowMore(true);
    } else {
      setHasShowMore(false);
    }
  }, [alwaysExpanded, containerHasOverflow]);

  return (
    <div className={classNames(className, 'expandable-container')}>
      <div
        className={classNames(
          'expandable-container-content',
          showMore && 'show-more',
          hasShowMore && 'has-show-more',
        )}
        ref={containerRef}
      >
        {render({ contentRef })}
      </div>

      {hasShowMore &&
        (showMore ? (
          <div className='show-less-button' onClick={() => setShowMore(false)}>
            Show less
          </div>
        ) : (
          <div className='show-more-button' onClick={() => setShowMore(true)}>
            Show more
          </div>
        ))}
    </div>
  );
};

export default ExpandableContainer;
