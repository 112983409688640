import gql from 'graphql-tag';
import Snippet from '@/graphql/fragments/Snippet';

export const GET_CLIENT_SNIPPETS = gql`
  query GetClientSnippets {
    snippets {
      ...Snippet
    }
  }
  ${Snippet}
`;

export const CREATE_SNIPPET = gql`
  mutation CreateSnippet($input: CreateSnippetInput) {
    snippets {
      createSnippet(input: $input) {
        id
        type
        name
        ... on SnippetFragment {
          text
          snippets {
            ...Snippet
          }
        }
        ... on SnippetSelect {
          options {
            id
            title {
              default
            }
          }
          state {
            value
            isLocked
          }
          fallbackValue {
            type
            text
          }
        }
        ... on SnippetAIToken {
          text
          state {
            value
            isLocked
          }
          snippets {
            ...Snippet
          }
        }
      }
    }
  }
  ${Snippet}
`;

export const EDIT_SNIPPET = gql`
  mutation EditSnippet($input: EditSnippetInput) {
    snippets {
      editSnippet(input: $input) {
        id
        type
        name
      }
    }
  }
`;

export const DELETE_SNIPPET = gql`
  mutation DeleteSnippet($snippetId: String!) {
    snippets {
      deleteSnippet(id: $snippetId) {
        id
      }
    }
  }
`;
