import React from 'react';
import _ from 'underscore';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { getTranslatedText } from '@/common';
import industryOptions from '@/common/options/industryOptions.json';
import { removeAccents } from '@/common/utils/string';

const IndustryFields = ({ industries, setIndustries }) => {
  const { t } = useTranslation();
  const currentValue = _.pluck(industries?.target, 'industryId');
  const onChange = (e, { value }) => {
    setIndustries({
      target: _.map(value, (industryId) => ({ industryId })),
    });
  };

  const translatedSourceTypeOptions = _.chain(industryOptions)
    .map(({ value, text, fr }) => ({
      key: value,
      value,
      text: getTranslatedText({ en: text, fr }),
    }))
    .sortBy(({ text }) => removeAccents(text))
    .value();

  return (
    <div className='industry-fields section'>
      <Form.Dropdown
        search
        selection
        multiple
        fluid
        placeholder={t('common.select')}
        options={translatedSourceTypeOptions || []}
        value={currentValue}
        onChange={onChange}
        closeOnChange
      />
    </div>
  );
};

export default React.memo(IndustryFields);
