import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { sanitizeTypename } from '@/common/utils/apollo';
import withClientStatistics from '../../../../../hocs/clients/withClientStatistics';
import PipelineTable from './PipelineTable';
import {
  addRates,
  aggregateEvents,
  dateRangeDates,
  filterEventsByDateRange,
} from '../../utils';

const pipelineStats = ({ events, dateRange, totalSent }) => {
  const { startDate, endDate } = dateRangeDates({ dateRange });
  const dateRangeEvents = filterEventsByDateRange({
    events,
    startDate,
    endDate,
  });
  const aggregatedEvent = aggregateEvents({ events: dateRangeEvents });
  return addRates({ events: [aggregatedEvent], totalSent })[0];
};

const formatEmailEvents = ({ totalSent, dateRange, t }) => {
  return (emailEvents, key) => {
    if (!key.startsWith('index')) {
      return null;
    }
    const emailType = `${t('analytics.labels.email')} ${+key.slice(-1) + 1}`;

    const pipelineDataForEmail = pipelineStats({
      events: emailEvents,
      dateRange,
      totalSent,
    });
    // show at least first row
    if (key !== 'index0' && _.isEmpty(pipelineDataForEmail)) {
      return null;
    }
    return { ...pipelineDataForEmail, emailType };
  };
};

const emailStats = ({ eventsByEmail, dateRange, t }) => {
  // compute global total of sends
  const allEvents = _.flatten(Object.values(eventsByEmail || {}));
  const totalSent = pipelineStats({ events: allEvents, dateRange }).nbSent;
  const statsByEmail = _.mapObject(
    eventsByEmail,
    formatEmailEvents({ totalSent, dateRange, t }),
  );

  let statsByEmailArray = _.sortBy(
    _.compact(Object.values(statsByEmail)),
    (stats) => stats?.emailType,
  );
  statsByEmailArray = addNbReceivedAtLeastNEmails({
    statsByEmailArray,
    totalSent,
  });

  return statsByEmailArray;
};

// NOTE: this is equivalent to reverse cumsum
const addNbReceivedAtLeastNEmails = ({ statsByEmailArray, totalSent }) => {
  return _.reduce(
    statsByEmailArray,
    (memoEmailStats, emailStats, index) => {
      if (!emailStats) {
        return memoEmailStats;
      }
      let newEmailStats;
      if (index === 0) {
        newEmailStats = {
          ...emailStats,
          nbReceivedAtLeastNEmails: totalSent,
        };
      } else {
        const prevStats = memoEmailStats[index - 1];
        newEmailStats = {
          ...emailStats,
          nbReceivedAtLeastNEmails:
            prevStats?.nbReceivedAtLeastNEmails - prevStats?.nbSent || 0,
        };
      }
      return [...memoEmailStats, newEmailStats];
    },
    [],
  );
};

const PipelineDataContainer = ({
  statistics,
  statisticsLoading,
  dateRange,
  t,
}) => {
  // const { dailyCohorts, firstContactSequenceCohorts } = (fullStatistics.data.client.statistics || {}).offers || {};
  const { dailyCohorts, firstContactSequenceCohorts } =
    (statistics || {}).offers || {};
  const pipelineData = pipelineStats({ events: dailyCohorts, dateRange });
  let eventsByEmail = sanitizeTypename(firstContactSequenceCohorts);
  if (_.isEmpty(eventsByEmail)) {
    eventsByEmail = { index0: [] };
  }
  const tableData = emailStats({ eventsByEmail, dateRange, t });
  return (
    <PipelineTable
      pipelineData={pipelineData}
      tableData={tableData}
      isLoading={statisticsLoading}
    />
  );
};

export default compose(
  withClientStatistics,
  withTranslation('translations'),
)(PipelineDataContainer);
