import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Input, Table, Loader } from 'semantic-ui-react';

import GenericButton from '@/components/Common/GenericButton';
import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';
import withClientCalendlyIntegration from '../../../hocs/clients/withClientCalendlyIntegration';
import withUpdateClientCalendlyTrackingSetting from '../../../hocs/clients/withUpdateClientCalendlyTrackingSetting';
import withAddClientCalendlyAccount from '../../../hocs/clients/withAddClientCalendlyAccount';
import withDeleteClientCalendlyAccount from '../../../hocs/clients/withDeleteClientCalendlyAccount';
import SettingsLayout from '../../SettingsLayout';

import './CalendarIntegrations.css';

const CalendarIntegrations = ({
  t,
  clientId,
  clientCalendlyIntegration,
  clientCalendlyIntegrationLoading,
  error,
  addClientCalendlyAccount,
  deleteClientCalendlyAccount,
  updateClientCalendlyTrackingSetting,
}) => {
  const [apiKey, setApiKey] = useState('');
  const [addingAccount, setAddingAccount] = useState(false);
  const [addAccountError, setError] = useState(false);

  const onAddAccount = async () => {
    setAddingAccount(true);
    try {
      await addClientCalendlyAccount({ token: apiKey });
      setApiKey('');
      setError(false);
    } catch (e) {
      setError(true);
      console.error(e);
    }
    setAddingAccount(false);
  };

  if (clientCalendlyIntegrationLoading || error) {
    return (
      <SettingsLayout
        currentPage='calendar'
        clientId={clientId}
        className='settings-container'
      >
        <div className='calendar-integrations settings'>
          <div className='settings-header'>
            <h1>{t('settings.calendly.header')}</h1>
          </div>
        </div>
      </SettingsLayout>
    );
  }

  return (
    <SettingsLayout
      currentPage='calendar'
      clientId={clientId}
      className='settings-container'
    >
      <div className='calendar-integrations settings'>
        <div className='settings-header'>
          <h1>{t('settings.calendly.header')}</h1>
        </div>
        <h2>{t('settings.configuration')}</h2>
        <div className='section visible'>
          <div className='toggle-tracking input-container'>
            <div className='input-label'>{t('settings.calendly.tracking')}</div>
            <div className='input-element'>
              <LabeledCheckbox
                checked={!!clientCalendlyIntegration?.active}
                onClick={() =>
                  updateClientCalendlyTrackingSetting({
                    active: !clientCalendlyIntegration?.active,
                  })
                }
                label={t('settings.calendly.replyTracking')}
              />
            </div>
            <div className='input-description'>
              {t('settings.calendly.replyTrackingTooltip')}
            </div>
          </div>

          <div className='api-key input-container'>
            <div className='input-label'>{t('settings.calendly.accounts')}</div>
            <div className='input-description'>
              <div>
                {t('settings.calendly.addAccounts')}{' '}
                <a
                  href='https://calendly.com/integrations'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('settings.calendly.apiKey')}
                </a>
                .
              </div>
              <div>{t('settings.calendly.needPremiumAccount')}</div>
            </div>

            <div className='api-key-input settings-row'>
              <Input
                placeholder={t('settings.calendly.apiKey')}
                value={apiKey}
                onChange={(e, { value }) => setApiKey(value)}
              />
              <GenericButton disabled={addingAccount} onClick={onAddAccount}>
                {t('settings.calendly.saveApiKey')}
              </GenericButton>
            </div>
            {addAccountError && (
              <div className='input-error'>{t('common.genericError')}</div>
            )}
          </div>

          <div className='input-container'>
            {_.isEmpty(clientCalendlyIntegration?.accounts) ? (
              <div className='input-description'>
                {t('settings.calendly.noAccounts')}
              </div>
            ) : (
              <Table
                className='senders-settings-table'
                textAlign='center'
                basic
              >
                <CalendlyIntegrationTableHeader t={t} />
                <CalendlyIntegrationTableBody
                  accounts={clientCalendlyIntegration?.accounts}
                  deleteClientCalendlyAccount={deleteClientCalendlyAccount}
                  t={t}
                />
              </Table>
            )}
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
};

const CalendlyIntegrationTableHeader = ({ t }) => {
  return (
    <Table.Header className='senders-table-header'>
      <Table.Row>
        <Table.HeaderCell width={2}>
          {t('settings.calendly.slug')}
        </Table.HeaderCell>
        <Table.HeaderCell width={4}>
          {t('settings.calendly.email')}
        </Table.HeaderCell>
        <Table.HeaderCell width={3}>
          {t('settings.calendly.status')}
        </Table.HeaderCell>
        <Table.HeaderCell width={3}>
          {t('settings.calendly.delete')}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

const CalendlyIntegrationTableBody = ({
  accounts,
  deleteClientCalendlyAccount,
  t,
}) => {
  return (
    <Table.Body>
      {_.map(accounts, (account) => (
        <CalendlyIntegrationTableRow
          key={account.id}
          account={account}
          deleteClientCalendlyAccount={deleteClientCalendlyAccount}
          t={t}
        />
      ))}
    </Table.Body>
  );
};

const CalendlyIntegrationTableRow = ({
  account,
  deleteClientCalendlyAccount,
  t,
}) => {
  const [deletingAccount, setDeletingAccount] = useState(false);
  const { id, slug, email, status } = account || {};

  const onDelete = async () => {
    setDeletingAccount(true);
    try {
      await deleteClientCalendlyAccount({ id });
    } catch (e) {
      console.error(e);
      setDeletingAccount(false);
    }
  };

  return (
    <Table.Row>
      <Table.Cell>{slug}</Table.Cell>
      <Table.Cell>{email}</Table.Cell>
      <Table.Cell>{status}</Table.Cell>
      <Table.Cell collapsing>
        <div className='edit-cell'>
          {deletingAccount ? (
            <Loader active inline />
          ) : (
            <GenericButton primacy='secondary' onClick={onDelete}>
              <i className='ri-delete-bin-line' />
              <span>{t('settings.calendly.delete')}</span>
            </GenericButton>
          )}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default compose(
  withTranslation('translations'),
  withClientCalendlyIntegration,
  withUpdateClientCalendlyTrackingSetting,
  withAddClientCalendlyAccount,
  withDeleteClientCalendlyAccount,
)(CalendarIntegrations);
