import gql from 'graphql-tag';

export default gql`
  fragment TalentStrategist on TalentStrategist {
    userId
    firstname
    lastname
    email
    photoLink
    phoneNumber
  }
`;
