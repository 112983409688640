import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Message } from 'semantic-ui-react';
import {
  MissionWorkflow,
  WORKFLOW_ACTION_COMMANDS,
  WORKFLOW_RULE_TYPES,
} from '@/types/searchPoolJob';
import useClickOutside from '@/hooks/common/useClickOutside';
import { getTranslatedText } from '@/common';
import GenericButton from '@/components/Common/GenericButton';
import useAtsConfigurationPermission from '@/graphql/hooks/clients/useAtsConfigurationPermission';
import {
  ActionCommandDropdown,
  MoveToAtsStageDropdown,
  MoveToRevealStageDropdown,
  TriggerRuleDropdown,
} from './WorkflowComponents';
import {
  useMissionWorkflowsContext,
  WORKFLOWS_CONTEXT_MODE,
} from '../context/useMissionWorkflowsContext';

interface Props {
  workflow: MissionWorkflow;
}

const Workflow: React.FC<Props> = ({ workflow }) => {
  const { t } = useTranslation();
  const {
    mode,
    integrationTitle,
    styles,
    updateWorkflowState,
    deleteWorkflow,
    saveWorkflow,
  } = useMissionWorkflowsContext();
  const ref = useRef<HTMLDivElement>(null);
  const [editMode, setEditMode] = useState(false);
  const [ruleType, setRuleType] = useState(workflow.rule.type);
  const [actionCommand, setActionCommand] = useState(workflow.action.command);

  const canEditAtsConfiguration = useAtsConfigurationPermission();

  useEffect(() => {
    if (workflow.isDraft) {
      setEditMode(true);
    }
    // eslint-disable-next-line
  }, []);

  useClickOutside(ref, async (event: any) => {
    const classList: string[] = Array.from(event?.target?.classList);
    const isInside = classList.some(
      (className) =>
        className.startsWith('divider') ||
        className.startsWith('GenericButton_button'),
    );
    if (editMode && !workflow.isDraft && !isInside) {
      setEditMode(false);
    }
    if (workflow.id.startsWith('draft') && !isInside) {
      deleteWorkflow(workflow.id);
    }
  });

  const onSubmit = async () => {
    setEditMode(false);
    await saveWorkflow(workflow);
  };

  return (
    <div className={styles.workflowContainer} ref={ref}>
      {workflow?.warnings?.[0]?.message && (
        <>
          <Message warning size='tiny'>
            {getTranslatedText(workflow?.warnings?.[0]?.message)}
          </Message>
        </>
      )}
      <div className={styles.workflow}>
        <div className={styles.trigger}>
          <div
            className={classNames(
              styles.triggerRule,
              mode === WORKFLOWS_CONTEXT_MODE.CLIENT_DEFAULT_WORKFLOWS
                ? styles.flexGrow
                : '',
            )}
          >
            {editMode ? (
              <TriggerRuleDropdown
                value={ruleType}
                placeholder={t('reveal.workflows.chooseCriteria')}
                onChange={(event: any, data: any) => {
                  setRuleType(data.value);
                  updateWorkflowState({
                    workflow,
                    objectToUpdate: 'rule',
                    keyToUpdate: 'type',
                    value: data.value,
                  });
                }}
                atsName={integrationTitle}
              />
            ) : (
              <span>
                {t(`reveal.workflows.ruleTypes.${ruleType}`, {
                  ats: integrationTitle,
                })}
              </span>
            )}
          </div>
          {DisplayTriggerComponent({ workflow, editMode, ruleType })}
        </div>

        <i className={classNames('ri-arrow-right-line', styles.arrow)} />

        <div className={styles.actionWrapper}>
          <div className={styles.action}>
            {editMode ? (
              <ActionCommandDropdown
                value={actionCommand}
                placeholder={t('reveal.workflows.chooseCriteria')}
                onChange={(event: any, data: any) => {
                  setActionCommand(data.value);
                  updateWorkflowState({
                    workflow,
                    objectToUpdate: 'action',
                    keyToUpdate: 'command',
                    value: data.value,
                  });
                }}
                ats={integrationTitle}
              />
            ) : (
              <span>
                {t(`reveal.workflows.actionCommands.${actionCommand}`, {
                  ats: integrationTitle,
                })}
              </span>
            )}
            {DisplayActionComponent({
              workflow,
              editMode,
              actionCommand,
            })}
          </div>
        </div>
      </div>

      <div className={styles.workflowActions}>
        {!editMode && canEditAtsConfiguration && (
          <>
            <GenericButton
              primacy='secondary'
              onClick={() => setEditMode(true)}
            >
              {t('common.edit')}
            </GenericButton>
            <i
              className={classNames('ri-delete-bin-fill', styles.deleteIcon)}
              style={{ cursor: 'pointer' }}
              onClick={() => deleteWorkflow(workflow.id)}
            />
          </>
        )}
        {editMode && (
          <GenericButton primacy='secondary' onClick={onSubmit}>
            {t('common.save')}
          </GenericButton>
        )}
      </div>
    </div>
  );
};

const DisplayTriggerComponent = ({
  workflow,
  editMode,
  ruleType,
}: {
  workflow: MissionWorkflow;
  ruleType: string;
  editMode: boolean;
}) => {
  const { updateWorkflowState } = useMissionWorkflowsContext();
  switch (ruleType) {
    case WORKFLOW_RULE_TYPES.REVEAL_MISSION_UPDATE_PROFILE_STAGE:
      return (
        <MoveToRevealStageDropdown
          disabled={!editMode}
          defaultValue={workflow.rule.condition?.revealStageId}
          onChange={(event: any, data: any) =>
            updateWorkflowState({
              workflow,
              objectToUpdate: 'rule',
              keyToUpdate: 'revealStageId',
              value: data.value,
            })
          }
        />
      );

    /*     case WORKFLOW_RULE_TYPES.ATS_UPDATE_PROFILE_STAGE:
      return (
        <MoveToAtsStageDropdown
          disabled={!editMode}
          defaultValue={workflow.rule.condition?.atsStageId}
          onChange={(event: any, data: any) =>
            updateWorkflowState({
              workflow,
              objectToUpdate: 'rule',
              keyToUpdate: 'atsStageId',
              value: data.value,
            })
          }
        />
      ); */
    default:
      return <></>;
  }
};

const DisplayActionComponent = ({
  workflow,
  editMode,
  actionCommand,
}: {
  workflow: MissionWorkflow;
  editMode: boolean;
  actionCommand: string;
}) => {
  const { updateWorkflowState } = useMissionWorkflowsContext();
  switch (actionCommand) {
    /*     case WORKFLOW_ACTION_COMMANDS.MOVE_TO_MISSION_STAGE:
      return (
        <MoveToRevealStageDropdown
          disabled={!editMode}
          defaultValue={workflow.action.target?.revealStageId}
          onChange={(event: any, data: any) =>
            updateWorkflowState({
              workflow,
              objectToUpdate: 'action',
              keyToUpdate: 'revealStageId',
              value: data.value,
            })
          }
        />
      ); */

    case WORKFLOW_ACTION_COMMANDS.MOVE_TO_ATS_STAGE:
      return (
        <MoveToAtsStageDropdown
          disabled={!editMode}
          defaultValue={workflow.action.target?.atsStageId}
          onChange={(event: any, data: any) =>
            updateWorkflowState({
              workflow,
              objectToUpdate: 'action',
              keyToUpdate: 'atsStageId',
              value: data.value,
            })
          }
        />
      );
    /* case 'notify':
      return <OwnerDropdown workflow={workflow} disabled={!editMode} />; */
    default:
      return <></>;
  }
};

export default Workflow;
