import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './Hiresweet.module.less';
import svgStyles from './svg.module.less';

interface HiresweetProps {
  classNames?: {
    global?: string;
    main?: string;
    corner?: string;
  };
}

const Hiresweet: FC<HiresweetProps> = ({
  classNames: { global, main, corner } = {},
}) => (
  <svg
    className={classNames(svgStyles.svgIcon, global)}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      className={classNames(styles.main, main)}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 0L0.000692497 32H31.9999V16.4199H25.2398V25.2264H6.76033L6.76154 6.77374H15.5512V0H0Z'
      fill='currentColor'
    />
    <path
      className={classNames(styles.corner, corner)}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.6667 0V6.85059H25.1813V13.3333H32V0H18.6667Z'
      fill='currentColor'
    />
  </svg>
);

export default Hiresweet;
