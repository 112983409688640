import React, { FC, Fragment } from 'react';
import _ from 'underscore';

import classNames from 'classnames';
import InfoTooltip from '@/components/Common/InfoTooltip';
import { SegmentDescription } from './types';
import Funnel from './Funnel';

import styles from './GenericFunnelSummary.module.less';

interface GenericFunnelSummaryProps {
  segments: SegmentDescription[];
}

const GenericFunnelSummary: FC<GenericFunnelSummaryProps> = ({ segments }) => (
  <div className={styles.funnelSummary}>
    {_.map(
      segments,
      ({
        key,
        count,
        name,
        classNames: segmentClassNames,
        clickListener,
        conversionRate,
        tooltip,
      }) => (
        <Fragment key={key}>
          <div className={classNames(styles.stage, segmentClassNames?.global)}>
            <div className={styles.name}>
              {name}
              {tooltip && (
                <InfoTooltip rich hoverable position='bottom left'>
                  {tooltip}
                </InfoTooltip>
              )}
            </div>
            <button
              type='button'
              className={classNames(styles.count, segmentClassNames?.count)}
              onClick={clickListener}
              disabled={count === 0 || !clickListener}
            >
              {count}
            </button>
          </div>
          {conversionRate && <Funnel>{conversionRate}</Funnel>}
        </Fragment>
      ),
    )}
  </div>
);

export default GenericFunnelSummary;
