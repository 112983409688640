import { TFunction } from 'i18next';
import moment, { Moment, unitOfTime } from 'moment';
import { Frequency, Mode } from './components/GraphWrapper/types';

const nDaysAgo = (n: number): Moment => {
  // include beginning of the day (midnight onwards)
  return moment()
    .subtract(n, 'days')
    .startOf('day');
};

interface DateRangeDatesInput {
  dateRange: string;
  firstDate?: Date | string;
}

interface DateRangeDatesOutput {
  startDate: Moment;
  endDate: Moment;
  id: string;
}

export const dateRangeDates = ({
  dateRange,
  firstDate,
}: DateRangeDatesInput): DateRangeDatesOutput => {
  const endDate = moment();
  let startDate;
  switch (dateRange) {
    case '7days': {
      startDate = nDaysAgo(6);
      break;
    }
    case '30days': {
      startDate = nDaysAgo(29);
      break;
    }
    case '60days': {
      startDate = nDaysAgo(59);
      break;
    }
    case '180days': {
      startDate = nDaysAgo(179);
      break;
    }
    case '365days': {
      startDate = nDaysAgo(364);
      break;
    }
    case 'week': {
      startDate = moment()
        .startOf('isoWeek')
        .startOf('day');
      break;
    }
    case 'month': {
      startDate = moment().startOf('month');
      break;
    }
    case 'quarter': {
      startDate = moment().startOf('quarter');
      break;
    }
    case 'year': {
      startDate = moment().startOf('year');
      break;
    }
    case 'allTime': {
      startDate = moment(firstDate || new Date(0));
      break;
    }
    default:
      startDate = moment(firstDate || new Date(0));
      break;
  }
  return { startDate, endDate, id: dateRange };
};

export const safeDivide = (a: number, b: number): number | null => {
  if (!b) {
    return null;
  }
  return (a || 0) / b;
};

interface TicksByFrequencyInput {
  firstDataDate?: number;
  startDate: Moment;
  endDate: Moment;
  frequency: Frequency;
  minTicks?: number;
}

export const ticksByFrequency = ({
  firstDataDate,
  startDate,
  endDate,
  frequency,
  minTicks = 30,
}: TicksByFrequencyInput): number[] => {
  const periodByFrequency: Partial<Record<Frequency, unitOfTime.StartOf>> = {
    week: 'isoWeek',
    day: 'day',
  };
  const period = periodByFrequency[frequency] || 'month';

  const startMoment = moment(firstDataDate)
    .startOf(period)
    .startOf('day');
  const endMoment = endDate.endOf('day');

  let currentMoment = startMoment;
  const ticks = [];

  while (currentMoment <= endMoment) {
    ticks.push(currentMoment.valueOf());
    currentMoment = currentMoment.add(1, frequency);
  }

  const freqUnit = `${frequency}s` as unitOfTime.Diff;
  // min length for ticks to get good looking charts
  while (
    ticks.length <= Math.min(endDate.diff(startDate, freqUnit), minTicks)
  ) {
    ticks.unshift(
      moment(ticks[0])
        .subtract(1, frequency)
        .valueOf(),
    );
  }
  return ticks;
};

export const dateTickFormatter = (frequency: Frequency, t: TFunction) => {
  return (timestamp: string): string => {
    if (!timestamp) {
      return '';
    }
    const date = new Date(timestamp);
    if (frequency === 'quarter') {
      return t('analytics.dateFormats.quarter', { date });
    }
    if (frequency === 'month') {
      return t('analytics.dateFormats.month', { date });
    }
    if (frequency === 'week') {
      return t('analytics.dateFormats.week', { date });
    }
    return t('analytics.dateFormats.day', { date });
  };
};

export const tooltipValueFormatter = (mode: Mode, t: TFunction) => (
  value: number,
  name: string,
): string[] => [
  t(`reveal.analyticsView.graphLabels.${mode}`, { value, name }),
  '',
];
