import React, { FC, useMemo, useContext } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import useSnoozeReasonsOverview from '@/graphql/hooks/clients/statistics/useSnoozeReasonsOverview';
import { SecondaryBlue } from '@/less/colors';
import { useUserAllowedDepartments } from '@/graphql/hooks/users/useUserAllowedDepartments';
import useMissionsWithFoldering from '@/graphql/hooks/searchPoolJobs/useMissionsWithFoldering';

import HorizontalStackedBarChart from '../../../components/HorizontalStackedBarChart';
import StatisticsParametersContext from '../../../StatisticsParametersContext';

const SnoozeReasonsOverview: FC = () => {
  const { t } = useTranslation();
  const [{ missionIds, timeSpan }] = useContext(StatisticsParametersContext);
  const { subsections } = useUserAllowedDepartments();
  const { missionsWithFoldering } = useMissionsWithFoldering();

  const subsectionIdMap = useMemo(() => {
    const result = {} as Record<string, boolean>;
    _.each(subsections, ({ id }) => {
      result[id] = true;
    });
    return result;
  }, [subsections]);

  const availableMissionIds = useMemo(() => {
    const result = [] as string[];
    _.each(missionsWithFoldering, ({ id, foldering }) => {
      if (
        foldering?.subsection?.id &&
        subsectionIdMap[foldering.subsection.id]
      ) {
        result.push(id);
      }
    });
    return result;
  }, [missionsWithFoldering, subsectionIdMap]);

  const sentMissionIds = useMemo(
    () => (_.isEmpty(missionIds) ? availableMissionIds : missionIds),
    [missionIds, availableMissionIds],
  );

  const { snoozeReasonsOverview } = useSnoozeReasonsOverview({
    missionsFilter: { in: sentMissionIds },
    startDate: timeSpan.startDate.format('YYYY-MM-DD'),
    endDate: timeSpan.endDate.format('YYYY-MM-DD'),
  });

  const allStats = useMemo(
    () =>
      _.map(
        snoozeReasonsOverview,
        ({ snoozeReasonId, snoozeReasonTitle, count }) => {
          const name =
            snoozeReasonTitle ||
            (snoozeReasonId
              ? '???'
              : t(
                  'reveal.reports.adventure.snoozeReasonsOverview.noSnoozeReason',
                )) ||
            '';
          return {
            name,
            values: { [snoozeReasonId || '']: count },
          };
        },
      ),
    [snoozeReasonsOverview, t],
  );

  const stack = useMemo(
    () =>
      _.map(snoozeReasonsOverview, ({ snoozeReasonId, snoozeReasonTitle }) => {
        const name =
          snoozeReasonTitle ||
          (snoozeReasonId
            ? '???'
            : t(
                'reveal.reports.adventure.snoozeReasonsOverview.noSnoozeReason',
              )) ||
          '';
        return {
          datakey: snoozeReasonId || '',
          color: SecondaryBlue,
          hideFromLegend: true,
          displayedText: name,
        };
      }),
    [snoozeReasonsOverview, t],
  );

  return <HorizontalStackedBarChart stack={stack} allStats={allStats} />;
};

export default SnoozeReasonsOverview;
