import React, { ReactNode } from 'react';

import GenericButton, {
  GenericButtonColor,
  GenericButtonPrimacy,
  GenericButtonSize,
} from '../Common/GenericButton';
import ButtonDropdownMenu from '../Common/ButtonDropdownMenu';
import { DropdownMenuOption } from '../Common/DropdownMenuPanel';

import styles from './ButtonWithDropdown.module.less';

type Props = {
  onClick: () => void;
  onSelect: (id: string) => void;
  label: ReactNode;
  options: DropdownMenuOption[];
  primacy?: GenericButtonPrimacy;
  color?: GenericButtonColor;
  size?: GenericButtonSize;
};

const ButtonWithDropdown = ({
  onClick,
  onSelect,
  label,
  options,
  primacy,
  color,
  size,
}: Props) => {
  return (
    <div className={styles.buttonWithDropdown}>
      <GenericButton
        className={styles.button}
        primacy={primacy}
        onClick={onClick}
        color={color}
        size={size}
      >
        {label}
      </GenericButton>
      <ButtonDropdownMenu
        isIcon
        position='right'
        className={styles.dropdown}
        onSelect={onSelect}
        options={options}
        primacy={primacy}
        color={color}
        size={size}
      />
    </div>
  );
};

export default ButtonWithDropdown;
