import React, { useEffect, useState } from 'react';
import { Input, Modal, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import GenericModal from '@/components/Common/GenericModal';
import GenericButton from '@/components/Common/GenericButton';
import styles from './PlaceholderVariableModal.module.less';

const PlaceholderVariableModal = ({ open, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');

  useEffect(() => {
    setName('');
  }, [open]);

  const handleSubmit = () => {
    onSubmit(name);
    onClose();
  };

  return (
    <GenericModal size='mini' open={open} onClose={onClose}>
      <Modal.Header>{t('editor.placeholderVariableModal.title')}</Modal.Header>
      <Modal.Content>
        <Form>
          <Input
            value={name}
            fluid
            onChange={(e, { value }) => setName(value)}
          />
          <div className={styles.actions}>
            <GenericButton
              type='button'
              primacy='secondary'
              className='dismiss'
              onClick={onClose}
            >
              {t('customFieldsSettings.cancel')}
            </GenericButton>
            <GenericButton
              type='submit'
              primary
              onClick={handleSubmit}
              disabled={!name.length}
            >
              {t('common.save')}
            </GenericButton>
          </div>
        </Form>
      </Modal.Content>
    </GenericModal>
  );
};

export default PlaceholderVariableModal;
