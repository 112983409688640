import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { ApplicationFormTemplate } from '../../graphql/fragments/Applications';

export const updateApplicationFormTemplate = gql`
  mutation updateApplicationFormTemplate(
    $applicationFormTemplateId: ID!
    $applicationFormTemplate: ApplicationFormTemplateInput!
  ) {
    updateApplicationFormTemplate(
      applicationFormTemplateId: $applicationFormTemplateId
      applicationFormTemplate: $applicationFormTemplate
    ) {
      id
      applicationFormTemplates {
        ...ApplicationFormTemplate
      }
    }
  }
  ${ApplicationFormTemplate}
`;

export default graphql(updateApplicationFormTemplate, {
  props: ({ mutate }) => ({
    updateApplicationFormTemplate: ({
      applicationFormTemplateId,
      applicationFormTemplate,
    }) =>
      mutate({
        variables: {
          applicationFormTemplateId,
          applicationFormTemplate,
        },
      }),
  }),
});
