import React, { useState } from 'react';
import { compose } from 'underscore';
import { Button } from 'semantic-ui-react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import ButtonAndDropdown from '@/components/Common/ButtonAndDropdown';
import Plus from '@/components/Reveal/Icons/Plus';
import useAddProfileToOffer from '@/graphql/hooks/offers/useAddProfileToOffer';
import GenericButton from '@/components/Common/GenericButton';
import { SvgCheckMark } from '../../../assets/icons';
import logAction from '../../../common/logAction';

import AddToJobModal from '../AddToJobModal';
import AddToNewJobModal from '../AddToNewJobModal';
import RemoveFromJobModal from '../RemoveFromJobModal';

import withMarkHidden from '../../../hocs/searchPoolProfileState/withMarkHidden';
import withMarkUnhidden from '../../../hocs/searchPoolProfileState/withMarkUnhidden';
import withMarkBlacklisted from '../../../hocs/searchPoolProfileState/withMarkBlacklisted';
import withMarkUnblacklisted from '../../../hocs/searchPoolProfileState/withMarkUnblacklisted';
import withProfileSearchPoolState from '../../../hocs/profiles/withProfileSearchPoolState';

import './SearchPoolProfileActions.css';

// TODO: refactor props
// pass all handlers down to avoid having sortBy, excludeHidden, excludeProfilesInJob, onMarkHidden, etc. as props from 2 levels up.
const SearchPoolProfileActions = React.memo(
  ({
    clientId,
    clientProfile,
    // searchPoolState is passed as props or else it is loaded with HOC
    searchPoolState,
    loadingSearchPoolState,
    // data for addToJobModal
    resumeData,
    // actions
    markBlacklisted,
    markUnblacklisted,
    markHidden,
    markUnhidden,
    // TODO: check these
    onMarkHidden,
    onMarkUnhidden,
    onAddToJob,
    setProfilesInJobIds,
    searches,
    // end
    isUnclassified,
    jobTitle,
    user,
    t,
  }) => {
    const [openAddToJobModal, setOpenAddToJobModal] = useState(false);
    const [openAddToNewJobModal, setOpenAddToNewJobModal] = useState(false);
    const [openRemoveFromJob, setOpenRemoveFromJob] = useState(false);

    const { hidden, blacklisted, searchPoolId, searchPoolProfileId } =
      searchPoolState || {};

    if (!searchPoolState || loadingSearchPoolState) {
      return null;
    }

    const handleBlacklist = async (e) => {
      handleClickWithNoPropagation(e);
      try {
        await markBlacklisted({ searchPoolId, profileId: searchPoolProfileId });
      } catch (err) {
        console.error(err);
      }
    };

    const handleUnblacklist = async (e) => {
      handleClickWithNoPropagation(e);
      try {
        await markUnblacklisted({
          searchPoolId,
          profileId: searchPoolProfileId,
        });
      } catch (err) {
        console.error(err);
      }
    };

    const handleHide = async (e) => {
      handleClickWithNoPropagation(e);
      try {
        await markHidden({ searchPoolId, profileId: searchPoolProfileId });
        if (onMarkHidden) {
          onMarkHidden({
            profileId: clientProfile?.profileId,
            searchPoolProfileId,
            jobOfferId: clientProfile?.jobOfferId,
          });
        }
      } catch (err) {
        console.error(err);
      }
    };

    const handleUnhide = async (e) => {
      handleClickWithNoPropagation(e);
      try {
        await markUnhidden({ searchPoolId, profileId: searchPoolProfileId });
        if (onMarkUnhidden) {
          onMarkUnhidden({ searchPoolProfileId });
        }
      } catch (err) {
        console.error(err);
      }
    };

    const onOpenAddToJob = (e) => {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }
      setOpenAddToJobModal(true);
      logAction({
        type: 'discover-open-add-to-job-modal',
        searchPoolProfileId,
        info: {
          clientId,
          author: `${user?.firstname} ${user?.lastname}`,
        },
      });
    };

    const onCloseAddToJob = (e) => {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }
      setOpenAddToJobModal(false);
    };

    const onOpenAddToNewJob = () => {
      setOpenAddToNewJobModal(true);
    };

    const onCloseAddToNewJob = (e) => {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }
      setOpenAddToNewJobModal(false);
    };

    const onOpenRemoveFromJob = (e) => {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }
      setOpenRemoveFromJob(true);
      logAction({
        type: 'discover-open-remove-from-job-modal',
        searchPoolProfileId,
        info: {
          clientId,
          author: `${user?.firstname} ${user?.lastname}`,
        },
      });
    };

    const onCloseRemoveFromJob = () => {
      setOpenRemoveFromJob(false);
    };

    const onAddProfileToJob = ({ jobOfferId }) => {
      // TODO: refactor as onAddToJob handler
      if (setProfilesInJobIds) {
        setProfilesInJobIds((oldIds) => [
          ...(oldIds || []),
          searchPoolProfileId,
        ]);
      }

      if (onAddToJob) {
        onAddToJob({
          profileId: clientProfile?.profileId,
          searchPoolProfileId,
          jobOfferId,
        });
      }
    };

    return (
      <div className='search-pool-profile-actions actions-container'>
        {user?.isAdmin && (
          <GenericButton
            primacy='secondary'
            color='red'
            onClick={blacklisted ? handleUnblacklist : handleBlacklist}
          >
            {blacklisted ? 'Removed' : 'Remove'}
          </GenericButton>
        )}

        <GenericButton
          primacy='secondary'
          color='red'
          onClick={hidden ? handleUnhide : handleHide}
        >
          {hidden ? (
            <i className='ri-arrow-go-back-line' />
          ) : (
            <i className='ri-close-line' />
          )}
          {hidden
            ? t('watchCollect.results.profileCard.header.show')
            : t('watchCollect.results.profileCard.header.hide')}
        </GenericButton>

        {isUnclassified ? (
          <AddToJobButton
            onOpenAddToJob={onOpenAddToJob}
            onAddToJob={onAddProfileToJob}
            profileId={searchPoolProfileId}
          />
        ) : (
          <RemoveFromJobButton
            onOpenRemoveFromJob={onOpenRemoveFromJob}
            t={t}
            jobTitle={jobTitle}
          />
        )}

        {openAddToJobModal && (
          <AddToJobModal
            clientId={clientId}
            profileId={searchPoolProfileId}
            resumeData={resumeData}
            open={openAddToJobModal}
            onClose={onCloseAddToJob}
            onAddToJob={onAddProfileToJob}
            onAddToNewJob={onOpenAddToNewJob}
            onClick={handleClickWithNoPropagation}
          />
        )}
        {openAddToNewJobModal && (
          <AddToNewJobModal
            clientId={clientId}
            profileId={searchPoolProfileId}
            searches={searches}
            open={openAddToNewJobModal}
            onAddToNewJob={onAddProfileToJob}
            onClose={onCloseAddToNewJob}
            onClick={handleClickWithNoPropagation}
          />
        )}
        {openRemoveFromJob && (
          <RemoveFromJobModal
            searchPoolId={searchPoolId}
            clientId={clientId}
            profileId={searchPoolProfileId}
            clientProfileId={clientProfile?.profileId}
            jobOfferId={clientProfile?.jobOfferId}
            open={openRemoveFromJob}
            onClose={onCloseRemoveFromJob}
          />
        )}
      </div>
    );
  },
);

const handleClickWithNoPropagation = (e) => {
  if (e && e.stopPropagation) {
    e.stopPropagation();
  }
};

const AddToJobButton = ({ onOpenAddToJob, onAddToJob, profileId }) => {
  const { t } = useTranslation();
  const { offerId } = useParams();
  const { success, error } = useNotificationSystem();
  const [addProfileToOffer] = useAddProfileToOffer();

  const handleAddToJob = async () => {
    try {
      await addProfileToOffer(profileId, offerId);
      success(t('watchCollect.addToNewJobModal.success', { count: 1 }));
      if (onAddToJob) {
        onAddToJob({
          searchPoolProfileId: profileId,
          jobOfferId: offerId,
        });
      }
    } catch (e) {
      error(t('watchCollect.addToNewJobModal.error', { count: 1 }));
    }
  };

  return offerId ? (
    <ButtonAndDropdown
      title={
        <>
          <Plus />
          {t('watchCollect.results.profileCard.header.addToJobModal.thisJob')}
        </>
      }
      options={[
        {
          id: 'openModal',
          label: t(
            'watchCollect.results.profileCard.header.addToJobModal.otherJob',
          ),
        },
      ]}
      onClick={handleAddToJob}
      onSelect={(id) => {
        if (id === 'openModal') {
          onOpenAddToJob();
        }
      }}
      position='right'
    />
  ) : (
    <GenericButton onClick={onOpenAddToJob}>
      <i className='ri-add-line' />
      {t('watchCollect.results.profileCard.header.addToJobModal.anyJob')}
    </GenericButton>
  );
};

const RemoveFromJobButton = ({ onOpenRemoveFromJob, t, jobTitle }) => (
  <GenericButton primacy='tertiary' onClick={onOpenRemoveFromJob}>
    <SvgCheckMark className='button-icon' />
    {jobTitle ||
      t(
        'watchCollect.results.profileCard.header.addToJobModal.alreadyAddedToAJob',
      )}
  </GenericButton>
);

export default compose(
  withProfileSearchPoolState,
  withMarkHidden,
  withMarkUnhidden,
  withMarkBlacklisted,
  withMarkUnblacklisted,
  withTranslation('translations'),
)(SearchPoolProfileActions);
