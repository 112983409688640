import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const updateClientCalendlyTrackingSetting = gql`
  mutation updateClientCalendlyTrackingSetting($active: Boolean!) {
    updateClientCalendlyTrackingSetting(active: $active) {
      id
      calendlyIntegration {
        active
        accounts {
          id
          slug
          email
          status
        }
      }
    }
  }
`;

export default graphql(updateClientCalendlyTrackingSetting, {
  props: ({ mutate }) => ({
    updateClientCalendlyTrackingSetting: ({ active }) => {
      const variables = { active };
      return mutate({ variables });
    },
  }),
});
