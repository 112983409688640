import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SmallResumeData from '../../graphql/fragments/SmallResumeData';
import PipeStepStat from '../../graphql/fragments/PipeStepStat';

export const refetchQuery = gql`
  query updateRemoveProfileCache(
    $offerId: ID!
    $step: String!
    $search: String!
  ) {
    offer(id: $offerId) {
      id
      pipeStepStats {
        ...PipeStepStat
      }
      profiles(step: $step, search: $search) {
        id
        resumeData {
          ...SmallResumeData
        }
        lastStepUpdateTimestamp
      }
    }
  }
  ${SmallResumeData}
  ${PipeStepStat}
`;

export const mutation = gql`
  mutation removeProfile($id: ID!) {
    removeProfile(id: $id)
  }
`;

export default graphql(mutation, {
  props: ({ ownProps, mutate }) => ({
    removeProfile: async ({ id, offerId }) =>
      mutate({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: refetchQuery,
            variables: {
              offerId,
              step: ownProps.stepId,
              search: ownProps.searchText,
            },
            fetchPolicy: 'network-only',
          },
          {
            query: refetchQuery,
            variables: { offerId, step: ownProps.stepId, search: '' },
            fetchPolicy: 'network-only',
          },
          {
            query: refetchQuery,
            variables: { offerId, step: '', search: '' },
            fetchPolicy: 'network-only',
          },
        ],
      }),
  }),
});
