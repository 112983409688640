import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Segment } from 'semantic-ui-react';
import TaskIcon from '@/revealComponents/TaskIcon';
import { getTaskTitle } from '@/common/constants/taskTypes';
import SimpleDropdown from '@/components/SimpleDropdown';
import { getTranslatedText } from '@/common';
import { capitalizeFirstLetter } from '@/common/utils/string';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';

interface ExplicitTaskProps {
  task: any;
  onEdit: () => void;
  onDelete: () => void;
}

const ExplicitTask: React.FC<ExplicitTaskProps> = ({
  task,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();
  const { dueDate } = task;
  const { advanceExplicitTask } = useCandidateViewContext();

  return (
    <Segment className='task-link'>
      <TaskIcon action={task} />
      <div className='task-link-contact'>
        <div className='task-link-action'>
          <div className='sequence-info'>
            <span className='strong'>{getTaskTitle({ task })}</span>
          </div>

          <div className='hs-flex-spacer' />
          <SimpleDropdown
            text={t('reveal.candidatesView.timeline.edit')}
            direction='left'
          >
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={onEdit}
                text={t('reveal.candidatesView.timeline.edit')}
              />

              <Dropdown.Item
                text={t('reveal.candidatesView.timeline.remove')}
                onClick={onDelete}
              />
            </Dropdown.Menu>
          </SimpleDropdown>
        </div>

        <span className='task-link-date'>
          {capitalizeFirstLetter(moment(dueDate).fromNow())}
          <span onClick={() => advanceExplicitTask(task)}>
            {t('reveal.candidatesView.timeline.changeToNow')}
          </span>
        </span>

        <div className='task-link-description'>
          <span>{getTranslatedText(task?.description)}</span>
        </div>
      </div>
    </Segment>
  );
};

export default ExplicitTask;
