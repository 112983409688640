import React from 'react';
import classNames from 'classnames';
import _ from 'underscore';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import MergeTagsAutomationEditor from '@/containers/Editor/MergeTagsAutomationEditor';
import useClientId from '@/hooks/router/useClientId';
import { MergeTagsVariable, SNIPPET_TYPES } from '@/common/mergeTags/utils';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import styles from './SnippetsSettingsTable.module.less';

interface Props {
  snippets: MergeTagsVariable[] | undefined;
  onEdit: (snippet: MergeTagsVariable) => void;
  onDelete: (snippetId: string) => void;
}

const SnippetsSettingsTable: React.FC<Props> = ({
  snippets,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();

  if (_.isEmpty(snippets) || !snippets?.length) {
    return <></>;
  }

  const getSnippetOptions = (options: SweetEvaluatorTypes.SelectOption[]) => {
    return _.map(options, (option) => option.title.default).join(',');
  };

  return (
    <Table basic className={styles.table}>
      <Table.Header className={styles.header}>
        <Table.Row className={styles.headerRow}>
          <Table.HeaderCell className={styles.th}>
            <span>{t('reveal.parameters.snippetSettings.table.name')}</span>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.th}>
            <span>{t('reveal.parameters.snippetSettings.table.type')}</span>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.th}>
            <span>{t('reveal.parameters.snippetSettings.table.content')}</span>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.th}>
            <span>{t('reveal.parameters.snippetSettings.table.author')}</span>
          </Table.HeaderCell>
          <Table.HeaderCell collapsing className={styles.th} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(snippets, (snippet) => (
          <Table.Row key={snippet.id} className={styles.row}>
            <Table.Cell textAlign='left' className={styles.name}>
              <span>{snippet.name}</span>
            </Table.Cell>
            <Table.Cell textAlign='left' className={styles.subtype}>
              <span>
                {t(`reveal.parameters.snippetSettings.type.${snippet.type}`)}
              </span>
            </Table.Cell>

            <Table.Cell textAlign='left'>
              {(snippet.type === SNIPPET_TYPES.FRAGMENT ||
                snippet.type === SNIPPET_TYPES.AI_TOKEN) && (
                <MergeTagsAutomationEditor
                  clientId={clientId}
                  defaultValue={snippet.text}
                  snippets={snippet.snippets}
                  mode='template'
                  fields='simple'
                  readOnly
                  withMinHeight={false}
                />
              )}
              {snippet.type === SNIPPET_TYPES.SELECT && (
                <span>{getSnippetOptions(snippet.options)}</span>
              )}
            </Table.Cell>

            <Table.Cell textAlign='left'>
              <span>
                {snippet.author?.firstname} {snippet.author?.lastname}
              </span>
            </Table.Cell>

            <Table.Cell className={styles.actions}>
              <i
                className={classNames('ri-pencil-line ri-lg', styles.icon)}
                onClick={() => onEdit(snippet)}
              />
              <i
                className={classNames('ri-delete-bin-line ri-lg', styles.icon)}
                onClick={() => onDelete(snippet.id)}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default SnippetsSettingsTable;
