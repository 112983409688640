import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SlackIntegration from '../../graphql/fragments/SlackIntegration';

export const mutation = gql`
  mutation addClientSlackIntegration($token: String) {
    addClientSlackIntegration(token: $token) {
      id
      slackIntegration {
        ...SlackIntegration
      }
    }
  }
  ${SlackIntegration}
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    addClientSlackIntegration: ({ token }) =>
      mutate({
        variables: {
          token,
        },
      }),
  }),
});
