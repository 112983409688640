import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  FormInputSelect,
  FormInputText,
  HiresweetLibProvider,
} from '@hiresweet/hiresweet-lib';

import useClientId from '@/hooks/router/useClientId';

const PlaygroundT3 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  const history = useHistory();
  const [text, setText] = useState('');
  const [number, setNumber] = useState<number | undefined>();
  return (
    <div style={{ padding: 30 }}>
      <h1>P-T-3 - Form inputs</h1>
      <HiresweetLibProvider
        theme={theme}
        clientId={clientId}
        actions={{
          openProjectPage: (projectId: string) =>
            history.push(
              `/client/${clientId}/reveal/projects/recruiting/${projectId}`,
            ),
        }}
      >
        <div style={{ padding: '30px', background: 'white' }}>
          <FormInputText
            label='Field title'
            value={text}
            onChange={setText}
            required
          />
        </div>
        <div style={{ padding: '30px', background: 'white' }}>
          <FormInputSelect
            label='Field title'
            value={`${number}`}
            options={[1, 2, 3].map(optionFromNumber)}
            required
            onChange={(value) => {
              setNumber(+value);
            }}
          />
        </div>
      </HiresweetLibProvider>
    </div>
  );
};

const optionFromNumber = (x: number) => ({
  value: `${x}`,
  label: `Option ${x}`,
});

export default PlaygroundT3;
