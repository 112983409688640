import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { Button, Dropdown, Modal, Image, Icon } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

import getJobWishes from '@/common/candidates';
import { MarketplaceUnbiasedModeField } from '@/components/UnbiasedMode/UnbiasedModeField';
import GenericButton from '@/components/Common/GenericButton';
import { getSecureLink, getRandomDefaultAvatarLink } from '../../../common';
import contextToProps from '../../../hocs/contextToProps';
import withClientActiveOffers from '../../../hocs/offers/withClientActiveOffers';
import withAddSearchProfileToJob from '../../../hocs/searchPool/withAddSearchProfileToJob';

import './AddToJobModal.css';
import '../WatchProfileHeader.css';

const AddToJobModal = React.memo(
  ({
    resumeData,
    profileId,
    open,
    onClose,
    activeOffers,
    addSearchProfileToJob,
    onShowNotification,
    onAddToJob,
    onAddToNewJob,
    onClick,
    t,
  }) => {
    const [selectedJobId, setSelectedJobId] = useState(null);

    const handleAddToJob = async () => {
      try {
        await addSearchProfileToJob({
          id: profileId,
          jobOfferId: selectedJobId,
        });
        onShowNotification({
          message: t('watchCollect.addToNewJobModal.success', { count: 1 }),
          level: 'success',
        });
        if (onAddToJob) {
          onAddToJob({
            searchPoolProfileId: profileId,
            jobOfferId: selectedJobId,
          });
        }
      } catch (e) {
        onShowNotification({
          message: t('watchCollect.addToNewJobModal.error', { count: 1 }),
          level: 'error',
        });
      }
      onClose();
    };

    const handleAddToNewJob = () => {
      onAddToNewJob();
      onClose();
    };

    const dropdownOptions = _.map(activeOffers, ({ id, title }) => ({
      value: id,
      text: title,
    }));
    dropdownOptions.push({
      value: 'new-job',
      text: t('watchCollect.addToNewJobModal.trigger'),
      content: (
        <Button
          className='add-to-new-job-button primary-cta'
          onClick={handleAddToNewJob}
        >
          <Image src='/images/icons/figma/new-cross-white.svg' />
          {t('watchCollect.addToNewJobModal.trigger')}
        </Button>
      ),
    });

    const hiresweetData = resumeData?.sourceData?.hiresweet;
    const { firstname, lastname, photoLink } = resumeData || {};
    const avatarImageUrl =
      photoLink || getRandomDefaultAvatarLink(firstname + lastname);
    const jobWishes = getJobWishes({ hiresweetData });

    return (
      <Modal
        open={open}
        onClose={onClose}
        size='small'
        className='add-to-job-modal'
        onClick={onClick}
        closeIcon
      >
        <Modal.Header>
          {t('watchCollect.results.profileCard.header.addToJobModal.title')}
        </Modal.Header>
        <Modal.Content>
          <div className='watch-profile-header'>
            <div className='avatar-container'>
              <MarketplaceUnbiasedModeField
                name='hidePicture'
                fallback={
                  <Image
                    src={getRandomDefaultAvatarLink(firstname + lastname)}
                    circular
                  />
                }
              >
                <Image
                  src={getSecureLink(avatarImageUrl)}
                  circular
                  onError={(e) => {
                    e.target.src = getRandomDefaultAvatarLink(
                      firstname + lastname,
                    );
                  }}
                />
              </MarketplaceUnbiasedModeField>
              {/* <SourceIndicator
                // profileCreationTimestamp={creationTimestamp}
                t={t}
              /> */}
            </div>

            <div className='profile-data-extract'>
              <div>
                <span className='fullname'>
                  <MarketplaceUnbiasedModeField name='hideFullName'>
                    {firstname} {lastname}
                  </MarketplaceUnbiasedModeField>
                </span>
              </div>
              <div>
                <span className='headline'>
                  <Icon name='search' />
                  {jobWishes}
                </span>
              </div>
            </div>
          </div>

          {_.isEmpty(dropdownOptions) ? (
            <div>
              {t(
                'watchCollect.results.profileCard.header.addToJobModal.noActiveOffers',
              )}
            </div>
          ) : (
            <div className='inputs-row'>
              <div className='input-container half-width'>
                <div className='input-label'>
                  {t(
                    'watchCollect.results.profileCard.header.addToJobModal.thisJob',
                  )}
                </div>
                <div className='input-element'>
                  <Dropdown
                    className='job-dropdown'
                    selection
                    placeholder={t(
                      'watchCollect.results.profileCard.header.addToJobModal.jobDropdownPlaceholder',
                    )}
                    options={dropdownOptions}
                    value={selectedJobId}
                    onChange={(e, { value }) => setSelectedJobId(value)}
                    fluid
                  />
                </div>
              </div>
              {/* <div className='input-container half-width' /> */}
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <div className='confirmation-modal-actions'>
            <GenericButton primacy='secondary' onClick={onClose} size='big'>
              {t('common.cancel')}
            </GenericButton>
            <GenericButton
              onClick={handleAddToJob}
              disabled={!selectedJobId}
              size='big'
            >
              {t(
                'watchCollect.results.profileCard.header.addToJobModal.confirm',
              )}
            </GenericButton>
          </div>
        </Modal.Actions>
      </Modal>
    );
  },
);

export default compose(
  withClientActiveOffers,
  withAddSearchProfileToJob,
  contextToProps,
  withTranslation('translations'),
)(AddToJobModal);
