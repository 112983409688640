import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { query as ClientOffersQuery } from './withClientOffers';

export const mutation = gql`
  mutation hideOffer($id: ID!) {
    hideOffer(id: $id) {
      id
    }
  }
`;

export default graphql(mutation, {
  props: ({ ownProps, mutate }) => ({
    hideOffer: ({ id }) =>
      mutate({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: ClientOffersQuery,
            variables: { clientId: ownProps.clientId },
          },
        ],
      }),
  }),
});
