import React from 'react';
import {
  ApolloClient,
  useQuery,
  useApolloClient,
  QueryResult,
} from '@apollo/client';
import useDataUpdateSubscription from '@/graphql/dataUpdateSubscription/useDataUpdateSubscription';
import ENRICHED_SEARCH_POOL_PROFILE, {
  ENRICHED_SEARCH_POOL_PROFILE_WITHOUT_RESUME_DATA,
  ENRICHED_SEARCH_POOL_PROFILE_ENRICHMENT_RESULTS,
  ENRICHED_SEARCH_POOL_PROFILE_SOURCES,
} from '@/graphql/enrichedSearchPoolProfile';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

type UseCandidateViewProfileOptions = {
  searchPoolId: string;
  profileId: string;
  relevantCriteria: any;
  skip?: boolean;
  needNetworkCall?: boolean;
};

type EnrichedSearchPoolProfileVariables = {
  searchPoolId: string;
  id: string;
  relevantCriteria?: unknown;
  explicitTasksFilter?: { withDueLater?: boolean };
};

function getEnrichedCandidateViewProfileVariables({
  searchPoolId,
  profileId,
  relevantCriteria,
}: Omit<
  UseCandidateViewProfileOptions,
  'skip'
>): EnrichedSearchPoolProfileVariables {
  return {
    searchPoolId,
    id: profileId,
    relevantCriteria,
    explicitTasksFilter: { withDueLater: true },
  };
}

/**
 * Get the full seatch pool profile.
 *
 * Will subscribe to plugin's data-update so that the result is up to to date.
 */
export default function useEnrichedCandidateViewProfile({
  skip,
  ...baseVariables
}: UseCandidateViewProfileOptions): QueryResult<
  any,
  EnrichedSearchPoolProfileVariables
> {
  const apolloClient = useApolloClient();
  const configurationParams = useMergedConfigurationParams()
  const variables = getEnrichedCandidateViewProfileVariables(baseVariables);
  const { profileId } = baseVariables;

  const result = useQuery(ENRICHED_SEARCH_POOL_PROFILE, {
    variables,
    skip,
    fetchPolicy: baseVariables.needNetworkCall || configurationParams?.shouldReloadProfileOnEveryVisit === 'true' ? 'network-only' : 'cache-first', // refresh tasks cache in particular
  });

  useDataUpdateSubscription({
    type: 'onProfileResumeDataUpdate',
    match: { id: profileId },
    skip,
    onData: () => result.refetch(),
  });

  // Some events may not need that we reload `resumeData` that is quite
  // expensive.
  // For these, we'll make a very similar query to `ENRICHED_SEARCH_POOL_PROFILE`
  // but wihout `resumeData` so that Apollo cache is updated.
  const refetchWithoutResumeData = React.useCallback(async () => {
    await apolloClient.query({
      query: ENRICHED_SEARCH_POOL_PROFILE_WITHOUT_RESUME_DATA,
      variables,
      fetchPolicy: 'network-only',
    });
  }, [apolloClient, variables]);

  const refetchEnrichmentResultsOnly = React.useCallback(async () => {
    await apolloClient.query({
      query: ENRICHED_SEARCH_POOL_PROFILE_ENRICHMENT_RESULTS,
      variables,
      fetchPolicy: 'network-only',
    });
  }, [apolloClient, variables]);

  const refetchSources = React.useCallback(async () => {
    await apolloClient.query({
      query: ENRICHED_SEARCH_POOL_PROFILE_SOURCES,
      variables,
      fetchPolicy: 'network-only',
    });
  }, [apolloClient, variables]);

  useDataUpdateSubscription({
    type: 'onProfileTimelineUpdate',
    match: { id: profileId },
    skip,
    onData: refetchWithoutResumeData,
  });
  useDataUpdateSubscription({
    type: 'onExplicitTasksUpdated',
    match: { id: profileId },
    skip,
    onData: refetchWithoutResumeData,
  });
  useDataUpdateSubscription({
    type: 'onProfileAskedEnrichmentResults',
    match: { id: profileId },
    skip,
    onData: refetchEnrichmentResultsOnly,
  });
  useDataUpdateSubscription({
    type: 'onSourceAddedToProfile',
    match: { id: profileId },
    skip,
    onData: refetchSources,
  });
  useDataUpdateSubscription({
    type: 'onProfilesRemovedFromSequence',
    match: ({ profileIds }) => profileIds.includes(profileId),
    skip,
    onData: refetchWithoutResumeData,
  });
  useDataUpdateSubscription({
    type: 'onProfileAddedToSequence',
    match: ({ profileIds }) => profileIds.includes(profileId),
    skip,
    onData: refetchWithoutResumeData,
  });
  useDataUpdateSubscription({
    type: 'onProfileLockCurrentSequenceVariable',
    match: ({ profileId: profileIdParam }) => profileIdParam === profileId,
    skip,
    onData: refetchWithoutResumeData,
  });
  useDataUpdateSubscription({
    type: 'onProfilesAddedToMission',
    match: ({ profileIds }) => profileIds.includes(profileId),
    skip,
    onData: refetchWithoutResumeData,
  });
  useDataUpdateSubscription({
    type: 'onProfilesRemovedFromMission',
    match: ({ profileIds }) => profileIds.includes(profileId),
    skip,
    onData: refetchWithoutResumeData,
  });

  useDataUpdateSubscription({
    type: 'onProfilesChangedMissionStage',
    match: ({ profileIds }) => profileIds.includes(profileId),
    skip,
    onData: refetchWithoutResumeData,
  });

  useDataUpdateSubscription({
    type: 'onTaskUpdated',
    match: { profileId },
    skip,
    onData: refetchWithoutResumeData,
  });

  return result;
}

function prefetchQuery(
  {
    profileId,
    relevantCriteria,
  }: { profileId: string; relevantCriteria: unknown },
  { client }: { client: ApolloClient<unknown> },
) {
  client.query({
    query: ENRICHED_SEARCH_POOL_PROFILE,
    variables: getEnrichedCandidateViewProfileVariables({
      searchPoolId: 'reveal',
      profileId,
      relevantCriteria,
    }),
    fetchPolicy: 'cache-first',
  });
}

export function usePrefetchProfiles(
  profileIds: string[],
  { relevantCriteria }: { relevantCriteria?: any },
  delay = 1000,
): void {
  const client = useApolloClient();

  const isPlugin = useIsPlugin();

  React.useEffect(() => {
    if (isPlugin) return undefined;
    if (!profileIds?.length) return undefined;

    const timeout = setTimeout(() => {
      profileIds.forEach((profileId: string) => {
        prefetchQuery({ profileId, relevantCriteria }, { client });
      });
    }, delay);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [profileIds]);
}
