import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const query = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      id
      title
    }
  }
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.clientId,
    },
  }),
  props: ({ data: { loading, client, error } }) => ({
    loading,
    loadingClient: loading,
    client,
    error,
  }),
});
