import React from 'react';
import { isRecord } from 'ts-is-record';
import * as Sentry from '@sentry/browser';
import useAppPluginPubSub from '@/common/appPluginMessaging/useAppPluginPubSub';
import {
  DataUpdateNotificationMethodName,
  DataUpdateNotificationParams,
} from './constants';
import { SubscriptionsData } from './types';

type SubscriptionTypeData<
  TSubscriptionType extends keyof SubscriptionsData
> = SubscriptionsData[TSubscriptionType];
type SubscriptionTypeMatch<TSubscriptionType extends keyof SubscriptionsData> =
  | Partial<SubscriptionsData[TSubscriptionType]>
  | MatchFn<SubscriptionsData[TSubscriptionType]>;
type MatchFn<TData> = (data: TData) => boolean;

type UseDataUpdateSubscriptionOptions<
  TSubscriptionType extends keyof SubscriptionsData = keyof SubscriptionsData
> = {
  type?: TSubscriptionType | TSubscriptionType[];
  match?: SubscriptionTypeMatch<TSubscriptionType>;
  onData?: (
    data: SubscriptionTypeData<TSubscriptionType>,
    type: TSubscriptionType,
  ) => void;
  /**
   * If true, there will be no subscription made.
   */
  skip?: boolean;
};

/**
 * Subscribes to data update subscription with the given `subscriptionName`
 * and for which args matches the given filter. When a matching event is received,
 * `onEvent` will be called.
 *
 * This will leverage app/plugin communication PubSub.
 */
function useDataUpdateSubscription<
  TSubscriptionType extends keyof SubscriptionsData
>({
  type,
  match,
  onData,
  skip,
}: UseDataUpdateSubscriptionOptions<TSubscriptionType>): void {
  const pubSub = useAppPluginPubSub();

  return React.useEffect(() => {
    if (!pubSub) return;
    if (skip) return;

    const unSubscribe = pubSub.subscribe<DataUpdateNotificationParams>(
      DataUpdateNotificationMethodName,
      (params) => {
        try {
          if (type !== undefined) {
            if (Array.isArray(type)) {
              if (!(type as string[]).includes(params.type)) return;
            }
            if (type !== params.type) return;
          }
          const data = params.data as SubscriptionTypeData<TSubscriptionType>;
          if (match !== undefined) {
            const matchFn =
              typeof match === 'function'
                ? match
                : (newData: SubscriptionsData[TSubscriptionType]) =>
                    dataMatch(newData, match);
            if (!matchFn(data)) return;
          }

          if (onData) {
            onData(data, params.type as TSubscriptionType);
          }
        } catch (e) {
          console.error(e);
          Sentry.captureException(e);
        }
      },
    );

    // eslint-disable-next-line consistent-return
    return unSubscribe;
  }, [pubSub, type, match, onData, skip]);
}

/**
 * Returns true if the given args matches filter.
 */
const dataMatch = (data: unknown, match: unknown) => {
  if (data === match) return true;
  if (!isRecord(data) || !isRecord(match)) return false;
  return Object.keys(match).every(
    (filteringKey) => match[filteringKey] === data[filteringKey],
  );
};

export default useDataUpdateSubscription;
