import React, { CSSProperties, FC } from 'react';
import { Placeholder } from 'semantic-ui-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useMiniMission from '@/graphql/hooks/searchPoolJob/useMiniMission';

import styles from './MissionRemovedColumn.module.less';

interface MissionRemovedColumnProps {
  missionId: string;
  profileLoading?: boolean;
  day: string;
  className?: string;
  style?: CSSProperties;
}

const MissionRemovedColumn: FC<MissionRemovedColumnProps> = ({
  profileLoading,
  missionId,
  day,
  className,
  style,
}) => {
  const { t, i18n } = useTranslation();

  const { loading, miniMission } = useMiniMission(missionId);

  const date = t('reveal.reports.missions.removed.detailsModal.removed', {
    date: new Date(day).toLocaleString(i18n.resolvedLanguage, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }),
  });

  return (
    <div className={classNames(styles.mission, className)} style={style}>
      {loading || profileLoading ? (
        <Placeholder>
          <Placeholder.Line length='short' />
        </Placeholder>
      ) : (
        <>
          <div className={styles.missionName}>
            {miniMission?.data?.title || '???'}
          </div>
          <div className={styles.date}>{date}</div>
        </>
      )}
    </div>
  );
};

export default MissionRemovedColumn;
