import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import OfferRecipients from '../../graphql/fragments/OfferRecipients';
import UserNotificationsSettings from '../../graphql/fragments/UserNotificationsSettings';

export const query = gql`
  query getUserNotificationsSettings($clientId: ID!) {
    user {
      ...UserNotificationsSettings
    }
    client(id: $clientId) {
      id
      offers(
        activeOnly: true
        includeSuggestedOffers: false
        includeUnclassified: false
      ) {
        ...OfferRecipients
      }
    }
  }
  ${UserNotificationsSettings}
  ${OfferRecipients}
`;

export default graphql(query, {
  options: ({ clientId }) => ({
    variables: { clientId },
    fetchPolicy: 'network-only',
  }),
  props: ({ data: { loading, user, client, error } }) => ({
    loading,
    loadingUserSettings: loading,
    user,
    client,
    error,
  }),
});
