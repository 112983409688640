import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { removeUnwantedFields } from '@/containers/Parameters/OfferCriteria/helpers';
import SavedSearch from '../../graphql/fragments/SavedSearch';

export const getSavedSearches = gql`
  query getSavedSearches($id: ID!, $searchPoolId: ID!) {
    client(id: $id) {
      id
      savedSearches(searchPoolId: $searchPoolId) {
        ...SavedSearch
      }
    }
  }
  ${SavedSearch}
`;

export default graphql(getSavedSearches, {
  options: ({ clientId, searchPoolId }) => ({
    variables: { id: clientId, searchPoolId },
  }),
  props: ({ data: { loading, client, error } }) => ({
    loadingSavedSearches: loading,
    errorSavedSearches: error,
    savedSearches: client && removeUnwantedFields(client?.savedSearches),
  }),
});
