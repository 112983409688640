import React, { useContext, useEffect, useState } from 'react';
import {
  Loader,
  ModalActions as Actions,
  ModalContent as Content,
  ModalHeader as Header,
} from 'semantic-ui-react';
import GenericButton from '@/components/Common/GenericButton';
import EmailContext from '../../../../../context/EmailContext';

import './SenderModalStep1.css';

const SenderModalStep1 = ({ closeModal, toNextStep, t }) => {
  const {
    connectionInProgress,
    offlineError,
    onTriggerOfflineRegister,
    currentAddress,
  } = useContext(EmailContext);

  const [completedRegistration, setCompletedRegistration] = useState(false);
  const [registrationFailed, setRegistrationFailed] = useState(false);

  useEffect(() => {
    if (!registrationFailed && offlineError) {
      setRegistrationFailed(true);
    }
  }, [offlineError, registrationFailed]);

  useEffect(() => {
    if (!completedRegistration) {
      return;
    }

    if (offlineError) {
      setCompletedRegistration(false);
      return;
    }

    toNextStep();
  }, [completedRegistration, offlineError, toNextStep]);

  const onConnectGmail = async () => {
    try {
      await onTriggerOfflineRegister('gmail', 'select_account');
    } catch (e) {
      console.error(e);
    }
    setCompletedRegistration(true);
  };

  const onConnectOutlook = async () => {
    try {
      await onTriggerOfflineRegister('outlook', 'select_account');
    } catch (e) {
      console.error(e);
    }
    setCompletedRegistration(true);
  };

  return (
    <>
      <Header>{t('settings.senders.modalStep1.header')}</Header>
      <Content>
        <div className='description'>
          {t('settings.senders.modalStep1.description')}
        </div>
        {connectionInProgress ? (
          <div className='signin-buttons'>
            <div className='email-signin-button loader-button'>
              <Loader inline active size='medium' className='on-dimmer' />
              <span>{t('profile.onboarding.connectionInProgress')}</span>
            </div>
          </div>
        ) : (
          <div className='signin-buttons'>
            <button
              type='button'
              className='email-signin-button'
              onClick={onConnectGmail}
              disabled={connectionInProgress}
              title={t('settings.senders.connectGmail')}
            >
              <img alt='Gmail' src='/images/logos/gmail.svg' width='36px' />
              <span>{t('settings.senders.modalStep1.connectGmail')}</span>
            </button>
            <button
              type='button'
              className='email-signin-button'
              onClick={onConnectOutlook}
              disabled={connectionInProgress}
              title={t('settings.senders.connectOutlook')}
            >
              <img alt='Outlook' src='/images/logos/outlook.svg' width='36px' />
              <span>{t('settings.senders.modalStep1.connectOutlook')}</span>
            </button>
            <div className='confidentiality-text'>
              {t('settings.senders.modalStep1.confidentiality')}
            </div>
          </div>
        )}
      </Content>
      <Actions>
        <div className='modal-actions'>
          <GenericButton onClick={closeModal} size='big' primacy='secondary'>
            {t('common.cancel')}
          </GenericButton>
          <GenericButton
            type='submit'
            onClick={toNextStep}
            size='big'
            disabled={!currentAddress}
          >
            {t('settings.senders.modalNext')}
          </GenericButton>
        </div>
      </Actions>
    </>
  );
};

export default SenderModalStep1;
