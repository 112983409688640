import React, { useCallback, useMemo, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

// import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
// import styles from './TextingSettings.module.less';
import SettingsLayout from '@/containers/SettingsLayout';
import useTextingSenders, {
  TextingSender,
} from '@/graphql/hooks/clients/useTextingSenders';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import { useUpdateClientTextingSenders } from '@/graphql/hooks/clients/useUpdateTextingSenders';
import TextingSendersTable from './TextingSendersTable';
import EditTextingSenderModal from './EditTextingSenderModal';
import DeleteTextingSenderModal from './DeleteTextingSenderModal';
import GrantedTextingSendersTable from './GrantedTextingSendersTable';
// import { getRandomString } from '@/common';

interface TextingSettingsProps {
  clientId: string;
}

const TextingSettings: React.FC<TextingSettingsProps> = ({ clientId }) => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const { textingSenders } = useTextingSenders();
  const [selectedSenderId, setSelectedSenderId] = useState('');
  // const [addModalOpen, setAddModalOpen] = useState(false);
  const [updateTextingSenders] = useUpdateClientTextingSenders();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { email } = user || {};

  const handleUpdateTextingSender = useCallback(
    (textingSender) => {
      updateTextingSenders({
        variables: {
          input: {
            textingSenderId: textingSender.id,
            users: textingSender.users,
          },
        },
      });
      setEditModalOpen(false);
    },
    [updateTextingSenders],
  );

  const onEditSender = ({ senderId }: { senderId: string }) => {
    setSelectedSenderId(senderId);
    setEditModalOpen(true);
  };

  const onDeleteSender = ({ senderId }: { senderId: string }) => {
    setSelectedSenderId(senderId);
    setDeleteModalOpen(true);
  };

  const ownerSenders: TextingSender[] = _.filter(
    textingSenders,
    (sender) => sender.owner?.email === email,
  );
  const grantedSenders: TextingSender[] = _.filter(
    textingSenders,
    (sender) => sender.owner?.email !== email,
  );

  const selectedSender = useMemo(
    () => _.findWhere(textingSenders, { id: selectedSenderId }),
    [selectedSenderId, textingSenders],
  );

  return (
    <SettingsLayout
      currentPage='phoneSettings'
      clientId={clientId}
      className='settings-container'
    >
      <div className='settings'>
        <div className='senders-section'>
          <div className='settings-header'>
            <h1>{t('settings.texting.title')}</h1>
          </div>
          <h2>{t('settings.configuration')}</h2>
          <div className='section visible'>
            <div className='input-container'>
              <div className='input-label'>
                {t('settings.texting.textingSenders')}
              </div>
              <div className='input-description'>
                {t('settings.texting.details')}
              </div>
              <TextingSendersTable
                senders={ownerSenders}
                userEmail={email}
                onEditSender={onEditSender}
                onDeleteSender={onDeleteSender}
              />
              {selectedSender && (
                <>
                  <EditTextingSenderModal
                    textingSender={selectedSender as TextingSender}
                    open={editModalOpen}
                    closeModal={() => setEditModalOpen(false)}
                    onSave={handleUpdateTextingSender}
                  />
                  <DeleteTextingSenderModal
                    textingSender={selectedSender as TextingSender}
                    closeModal={() => setDeleteModalOpen(false)}
                    open={deleteModalOpen}
                    onDelete={() => {}}
                  />
                </>
              )}
              {!_.isEmpty(grantedSenders) && (
                <div className='senders-settings-granted input-container'>
                  <div className='input-label'>
                    {t('settings.senders.accountsSharedWithYou')}
                  </div>
                  <div className='input-description'>
                    {t('settings.senders.grantedDetails')}
                  </div>

                  <GrantedTextingSendersTable senders={grantedSenders} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
};

export default TextingSettings;
