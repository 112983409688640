import _ from 'underscore';
import React, { useMemo } from 'react';
import { Checkbox } from 'semantic-ui-react';
import withSetJobRecipient from '../../../hocs/offers/withSetJobRecipient';

const ToggleOfferCheckBox = ({ offer, setJobRecipient }) => {
  const onChange = () => {
    setJobRecipient({ offerId: offer.id, isRecipient: !offer.isRecipient });
  };
  return (
    <div className='input-element'>
      <Checkbox checked={offer.isRecipient} onChange={onChange} label={offer.title} />
    </div>
  );
};

const ToggleOfferCheckBoxWithMutation = withSetJobRecipient(ToggleOfferCheckBox);

const isFollowing = (email, offer) => {
  return _.findIndex(offer?.followers, { email }) > -1;
};

const JobOfferNotifications = ({ email, offers }) => {
  const [followedOffers, otherOffers] = useMemo(() => {
    const followedJobOffers = [];
    const otherJobOffers = [];
    _.forEach(offers, (offer) => {
      if (isFollowing(email, offer)) {
        followedJobOffers.unshift(offer);
      } else {
        otherJobOffers.unshift(offer);
      }
    });
    return [followedJobOffers, otherJobOffers];
  }, [email, offers]);

  return (
    <div>
      {followedOffers.map((offer) => {
        return <ToggleOfferCheckBoxWithMutation key={offer.id} offer={offer} />;
      })}
      {otherOffers.map((offer) => {
        return <ToggleOfferCheckBoxWithMutation key={offer.id} offer={offer} />;
      })}
    </div>
  );
};

export default JobOfferNotifications;
