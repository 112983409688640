import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import _ from 'underscore';

import useIsPlugin from '@/hooks/common/useIsPlugin';
import { getShortLanguage } from '@/common/utils/i18n';

import GenericSelect from '../GenericSelect';

import styles from './NowOrOtherDatePicker.module.less';

export type DateValue = 'now' | Date;
type Props = {
  value: DateValue;
  onChange: (value: DateValue) => void;
};

const NowOrOtherDatePicker = ({ value, onChange }: Props) => {
  const { t, i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const isPlugin = useIsPlugin();
  const initialSelectValue = useMemo(
    () => (value === 'now' ? ('now' as const) : ('other' as const)),
    [value],
  );
  const [selectValue, setSelectValue] = useState(initialSelectValue);
  const [date, setDate] = useState(value === 'now' ? new Date() : value);

  const selectOptions = [
    {
      value: 'now',
      label: t('reveal.timeline.activityLog.dateOptions.now'),
    },
    {
      value: 'other',
      label: t('reveal.timeline.activityLog.dateOptions.other'),
    },
  ] as const;
  const selectedOption = _.findWhere(selectOptions, { value: selectValue });

  const dateFormat = useMemo(
    () => (lang === 'en' ? 'MM/dd/yyyy hh:mm a' : 'dd/MM/yyyy HH:mm'),
    [lang],
  );
  const content = (
    <>
      <GenericSelect
        menuPlacement='top'
        className={styles.select}
        value={selectedOption}
        options={selectOptions}
        onChange={(newValue) => {
          const newSelectValue = newValue?.value || 'now';
          setSelectValue(newValue?.value || 'now');
          if (newSelectValue === 'now') {
            setDate(new Date());
            onChange('now');
          }
        }}
      />
      {selectValue === 'other' && (
        <ReactDatePicker
          popperPlacement='top'
          showTimeSelect
          className={styles.picker}
          maxDate={new Date()}
          selected={date}
          onChange={(d) => {
            if (d) {
              setDate(d);
              onChange(d);
            }
          }}
          dateFormat={dateFormat}
        />
      )}
    </>
  );

  if (isPlugin) {
    return <div>{content}</div>;
  }

  return content;
};

export default NowOrOtherDatePicker;
