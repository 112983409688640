import gql from 'graphql-tag';
import { AccountStrategist } from '@/types/accountStrategist';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import useClientId from '@/hooks/router/useClientId';

const getAccountStrategist = gql`
  query getAccountStrategist($clientId: ID!) {
    client(id: $clientId) {
      id
      accountStrategist {
        userId
        firstname
        lastname
        email
        calendlyUrl
        photoLink
        phoneNumber
      }
    }
  }
`;

type GetAccountStrategistData = {
  client: {
    id: string;
    accountStrategist?: AccountStrategist;
  };
};

type GetAccountStrategistVariables = {
  clientId: string;
};

type UseAccountStrategistInput = {
  queryOptions?: QueryHookOptions<
    GetAccountStrategistData,
    GetAccountStrategistVariables
  >;
};

interface UseAccountStrategistResult
  extends QueryResult<GetAccountStrategistData, GetAccountStrategistVariables> {
  accountStrategist?: AccountStrategist;
}

function useAccountStrategist({
  queryOptions = {},
}: UseAccountStrategistInput = {}): UseAccountStrategistResult {
  const clientId = useClientId();

  const query = useQuery<
    GetAccountStrategistData,
    GetAccountStrategistVariables
  >(getAccountStrategist, {
    ...queryOptions,
    variables: { clientId },
  });

  const { accountStrategist } = query.data?.client || {};

  return { ...query, accountStrategist };
}

export default useAccountStrategist;
