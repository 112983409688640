import React from 'react';
import { 
  HiresweetLibProvider, 
  ProjectContainer, 
  ProjectEditionForm, 
  ProjectEditionSubmitButton, 
  ProjectTitleEditionInput, 
  createProjectTextCustomFieldEditionInput,
  createProjectTextCustomField,
  ProjectTitle  
} from '@hiresweet/hiresweet-lib';

import useClientId from '@/hooks/router/useClientId';

const ProjectLocalisation = createProjectTextCustomField({
  clientCustomFieldId: 'localisation'
});

const LocalisationEditor = createProjectTextCustomFieldEditionInput({
  label: 'Localisation',
  clientCustomFieldId: 'localisation',
  isInlineText: true,
});

const PlaygroundT3 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30, background: 'white' }}>
      <h1>B1 - Project Forms</h1>
      <HiresweetLibProvider
        theme={theme}
        clientId={clientId}
      >
        <ProjectContainer projectId="ri7-demo-testimport2-UMODD2" >

          <h4>Display</h4>
          <ProjectTitle /><br/>
          <ProjectLocalisation />
          <br/>
          
          <h4>Form</h4>
          <ProjectEditionForm>
            <ProjectTitleEditionInput label='Titre' />
            <br/>
            <LocalisationEditor />
            <br/><br/>
            <ProjectEditionSubmitButton label='Sauvegarder' />
          </ProjectEditionForm>
        </ProjectContainer>
    
      </HiresweetLibProvider>
    </div>
  );
};

export default PlaygroundT3;
