import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'underscore';
import { Popup, Checkbox, Accordion, Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import contextStyles from '@/components/Tables/ContextMenu/ContextMenu.module.less';
import GenericButton from '@/components/Common/GenericButton';
import styles from './TableColumnSelector.module.less';
import {
  ColumnDisplay,
  ColumnGroup,
} from './JobView/ProjectProfilesTab/columns/useProfilesTableColumns';

interface TableColumnSelectorProps {
  onUpdateColumnsToDisplay: (columnsDisplay: ColumnDisplay[]) => void;
  currentColumnValues: ColumnDisplay[];
  columnGroups: ColumnGroup[];
}

export const ColumnToggles: React.FC<{
  columns: ColumnDisplay[];
  currentColumnValuesById: Record<string, ColumnDisplay>;
  onColumnChange: (column: ColumnDisplay) => void;
}> = ({ columns, currentColumnValuesById, onColumnChange }) => {
  // If we don't use the fragment Typescript is not happy because we return an array...
  const availableColumns = _.filter(
    columns,
    (column) =>
      column.id in currentColumnValuesById &&
      currentColumnValuesById[column.id].available,
  );
  return (
    <>
      {_.map(availableColumns, (column) => (
        <div className='column-selector' key={column.id}>
          <Checkbox
            className='column-selector-switch'
            checked={!currentColumnValuesById[column.id].hidden}
            onChange={() => onColumnChange(column)}
            toggle
          />
          <span className='column-name'>
            {currentColumnValuesById[column.id].name}
          </span>
        </div>
      ))}
    </>
  );
};

export const ColumnToggleGroup: React.FC<{
  group: ColumnGroup;
  currentColumnValuesById: Record<string, ColumnDisplay>;
  onColumnChange: (column: ColumnDisplay) => void;
}> = ({ group, currentColumnValuesById, onColumnChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div>
      {group.isExpandable ? (
        <Accordion>
          <Accordion.Title
            className={styles.accordionGroup}
            active={isExpanded}
            index={0}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <span className={styles.accordionTitle}>{group.title}</span>
            {group.columns.length > 0 && (
              <span className='pill-message blue mini'>
                {group.columns.length}
              </span>
            )}
            <div className='hs-spacer' />
            <Icon className='dropdown' />
          </Accordion.Title>

          <Accordion.Content
            className='accordion-filter-content'
            active={isExpanded}
          >
            <ColumnToggles
              columns={group.columns}
              onColumnChange={onColumnChange}
              currentColumnValuesById={currentColumnValuesById}
            />
          </Accordion.Content>
        </Accordion>
      ) : (
        <>
          <h5 className={styles.columnGroupTitle}>{group.title}</h5>
          <ColumnToggles
            columns={group.columns}
            onColumnChange={onColumnChange}
            currentColumnValuesById={currentColumnValuesById}
          />
        </>
      )}
    </div>
  );
};

interface ToggleColumnSelectorContentProps {
  defaultColumns: ColumnDisplay[];
  otherColumns: ColumnGroup[];
  currentColumnValuesById: Record<string, ColumnDisplay>;
  handleCheckboxChange: (column: ColumnDisplay) => void;
}

export const ToggleColumnSelectorContent: React.FC<
  ToggleColumnSelectorContentProps
> = ({
  defaultColumns,
  otherColumns,
  currentColumnValuesById,
  handleCheckboxChange,
}) => {
  return (
    <div className={styles.columnsSelectorContainer}>
      <ColumnToggles
        columns={defaultColumns || []}
        currentColumnValuesById={currentColumnValuesById}
        onColumnChange={handleCheckboxChange}
      />

      {_.map(otherColumns || [], (group) => {
        return (
          <ColumnToggleGroup
            key={group.groupId}
            currentColumnValuesById={currentColumnValuesById}
            onColumnChange={handleCheckboxChange}
            group={group}
          />
        );
      })}
    </div>
  );
};

const TableColumnSelector: React.FC<TableColumnSelectorProps> = ({
  onUpdateColumnsToDisplay,
  currentColumnValues,
  columnGroups,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [columnsDisplay, setColumnsDisplay] =
    useState<ColumnDisplay[]>(currentColumnValues);

  useEffect(() => {
    setColumnsDisplay(currentColumnValues || []);
  }, [currentColumnValues]);

  const handleCheckboxChange = useCallback(
    (updatedColumn) => {
      const newColumnsDisplay = _.map(columnsDisplay || [], (column) =>
        column?.id === updatedColumn.id
          ? { ...column, hidden: !column.hidden }
          : column,
      );
      setColumnsDisplay(newColumnsDisplay);
    },
    [columnsDisplay],
  );

  const handleSave = useCallback(() => {
    onUpdateColumnsToDisplay(columnsDisplay);
    setOpen(false);
  }, [onUpdateColumnsToDisplay, columnsDisplay]);

  const { defaultGroup, otherColumnGroups } = useMemo(() => {
    const defaultGroup = _.findWhere(columnGroups, {
      groupId: 'default',
    });

    const otherColumnGroups = _.filter(
      columnGroups,
      ({ groupId, columns }) => groupId !== 'default' && !_.isEmpty(columns),
    );

    return { defaultGroup, otherColumnGroups };
  }, [columnGroups]);

  const currentColumnValuesById = useMemo(() => {
    return _.reduce(
      columnsDisplay,
      (agg, column) => {
        // eslint-disable-next-line
        agg[column.id] = column;
        return agg;
      },
      {} as Record<string, ColumnDisplay>,
    );
  }, [columnsDisplay]);

  return (
    <Popup
      basic
      on='click'
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      position='bottom right'
      className='column-selector-popup'
      trigger={
        <div className={contextStyles.contextBtnContainer}>
          <i
            className={classNames(
              contextStyles.contextBtn,
              styles.contextBtn,
              'ri-more-fill ri-lg',
            )}
            role='button'
          />
        </div>
      }
      content={
        <>
          <h4>{t('reveal.missions.columnSelector')}</h4>
          <ToggleColumnSelectorContent
            defaultColumns={defaultGroup?.columns || []}
            otherColumns={otherColumnGroups}
            currentColumnValuesById={currentColumnValuesById}
            handleCheckboxChange={handleCheckboxChange}
          />
          <GenericButton
            className={styles.button}
            onClick={(_e) => handleSave()}
          >
            {t('common.save')}
          </GenericButton>
        </>
      }
    />
  );
};

export default TableColumnSelector;
