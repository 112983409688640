import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Checkbox, Dropdown, Input } from 'semantic-ui-react';
import { getTranslatedText } from '../../../common';
import withOfferCriteria from '../../../hocs/offers/withOfferCriteria';
import withOfferCriteriaOptions from '../../../hocs/offers/withOfferCriteriaOptions';
import withUpdateJobPosting from '../../../hocs/offers/withUpdateJobPosting';
import withDeleteJobPosting from '../../../hocs/offers/withDeleteJobPosting';
import routerParamsToProps from '../../../hocs/routerParamsToProps';

import JobPostingDescription from './components/JobPostingDescription';

import './JobPostingFormFields.css';

const JobPostingFormFields = ({
  formState,
  setFormState,
  loadingCriteriaOptions,
  criteriaOptions,
  t,
}) => {
  const { description, locations, published } = formState;

  const handleChangeTitle = (e, { value }) => {
    setFormState((oldFormState) => ({
      ...oldFormState,
      title: {
        ...oldFormState?.title, // TODO: translations ?
        default: value,
      },
    }));
  };

  const setDescriptionState = (newDescription) => {
    setFormState((oldFormState) => ({
      ...oldFormState,
      description: newDescription,
    }));
  };

  const locationsOptions = getDropdownOptions({
    backendOptions: criteriaOptions?.locations,
  });

  const handleChangeLocations = (e, { value }) => {
    setFormState((oldFormState) => ({
      ...oldFormState,
      locations: getBackendOptionsFromListValue({
        value,
        backendOptions: criteriaOptions?.locations,
      }),
    }));
  };

  const handleChangePublicationStatus = (e, { checked }) => {
    setFormState((oldFormState) => ({
      ...oldFormState,
      published: checked,
    }));
  };

  return (
    <div className='job-posting-form-fields'>
      <div className='job-posting-form-header'>
        <Input
          className='job-posting-title-input'
          fluid
          value={formState?.title?.default}
          onChange={handleChangeTitle}
          placeholder={t('offers.jobPostings.form.titlePlaceholder')}
        />

        <Checkbox
          className='publication-status'
          checked={!!published}
          label={t('offers.jobPostings.form.published')}
          onChange={handleChangePublicationStatus}
        />
      </div>

      <div className='form-main-fields'>
        <div className='input-container'>
          <div className='input-label'>
            {t('offers.jobPostings.form.locations')}
          </div>
          <div className='input-element'>
            <Dropdown
              selection
              multiple
              fluid
              label='locations'
              value={getListValue({ backendOptionsList: locations })}
              options={locationsOptions}
              onChange={handleChangeLocations}
              placeholder={t('offers.jobPostings.form.locationsPlaceholder')}
              loading={loadingCriteriaOptions}
            />
          </div>
        </div>
      </div>

      <JobPostingDescription
        description={description}
        setDescriptionState={setDescriptionState}
        editMode
      />
    </div>
  );
};

const getDropdownOptionFromBackendOption = (option) => ({
  text: getTranslatedText(option?.label),
  value: option?.identifier,
});

const getDropdownOptions = ({ backendOptions }) => {
  return _.map(backendOptions, getDropdownOptionFromBackendOption);
};

const getBackendOptionsFromListValue = ({ value, backendOptions }) => {
  return _.map(value, (identifier) =>
    _.findWhere(backendOptions, { identifier }),
  );
};

const getListValue = ({ backendOptionsList }) => {
  return _.map(backendOptionsList, (location) => location?.identifier);
};

export default compose(
  withRouter,
  routerParamsToProps,
  withUpdateJobPosting,
  withDeleteJobPosting,
  withTranslation('translations'),
  withOfferCriteria,
  withOfferCriteriaOptions,
)(JobPostingFormFields);
