import gql from 'graphql-tag';
import MatchingDetails from './MatchingDetails';

export default gql`
  fragment SearchResults on SearchResults {
    categories {
      # DEPRECATED because of cache issues
      # id
      categoryId
      count
      results {
        searchPoolProfileId
        scores
        matchingDetails {
          ...MatchingDetails
        }
      }
    }
  }
  ${MatchingDetails}
`;
