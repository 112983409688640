import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'underscore';
import { useTranslation } from 'react-i18next';
import usePowerHours from '@/graphql/hooks/powerHours/usePowerHours';
import { getSoonestPowerHour } from '@/common/helpers/powerHours';
import GenericButton from '@/components/Common/GenericButton';
import { TelescopeIcon } from '../../../../assets/icons';
import withClientPermissions from '../../../../hocs/clients/withClientPermissions';
import withSearchPoolTotalCount from '../../../../hocs/searchPool/withSearchPoolTotalCount';
import PowerHoursLegacyBanner from './PowerHoursLegacyBanner';

import './JobsWatchCollectBanner.css';

const JobsWatchCollectBanner = ({
  clientId,
  permissions,
  searchPoolTotalCount,
  searchPoolTotalCountLoading,
}) => {
  const { t } = useTranslation();
  const { powerHours } = usePowerHours();
  const soonestPowerHour = getSoonestPowerHour(powerHours);

  if (soonestPowerHour) {
    return <PowerHoursLegacyBanner powerHour={soonestPowerHour} />;
  }

  if (!permissions?.watchCollect) {
    return null;
  }

  if (searchPoolTotalCountLoading || !searchPoolTotalCount) {
    return null;
  }

  return (
    <Link
      to={`/client/${clientId}/discover/candidates`}
      className='jobs-watch-collect-banner'
    >
      <div className='banner-icon'>
        <TelescopeIcon />
      </div>

      <div className='banner-content'>
        <div className='banner-main-text body-regular'>
          <span className='total-count'>{searchPoolTotalCount}</span>
          {t('offers.jobsWatchCollectBanner.text', {
            count: searchPoolTotalCount,
          })}
        </div>
      </div>

      <GenericButton>
        {t('offers.jobsWatchCollectBanner.button', {
          count: searchPoolTotalCount,
        })}
      </GenericButton>
    </Link>
  );
};

export default compose(
  withClientPermissions,
  withSearchPoolTotalCount,
)(JobsWatchCollectBanner);
