import _ from 'underscore';
import gql from 'graphql-tag';

import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import useClientId from '@/hooks/router/useClientId';

import { DepartmentScopeSet } from '@/graphql/users';
import {
  Client,
  Department as TDepartment,
  Section,
  Section as TSection,
  Subsection,
  Subsection as TSubsection,
} from '@/types/client';
import { Department } from '@/graphql/fragments/Department';

import useUserFromJWToken from './useUserFromJWToken';
import useClientPermissions from '../clients/useClientPermissions';

const GET_CLIENT_DEPARTMENTS = gql`
  query getClientDepartments($id: ID!) {
    client(id: $id) {
      id
      departments {
        ...Department
      }
    }
  }
  ${Department}
`;

const mapById = (arr: string[]) =>
  arr.reduce((agg, id) => {
    agg[id] = true;
    return agg;
  }, {} as Record<string, true>);

function computeDepartmentsFromScopes({
  allowedDepartmentIds,
  allowedSectionIds,
  allowedSubsectionIds,
  departments,
}: {
  allowedDepartmentIds: string[];
  allowedSectionIds: string[];
  allowedSubsectionIds: string[];
  departments: TDepartment[];
}) {
  const allowedDepartments = mapById(allowedDepartmentIds);
  const allowedSections = mapById(allowedSectionIds);
  const allowedSubsections = mapById(allowedSubsectionIds);

  const scopedDepartments: TDepartment[] = [];
  const scopedSections: TSection[] = [];
  const scopedSubsections: TSubsection[] = [];

  // Traverse the tree naively
  // but early exit if we can
  _.each(departments, (department) => {
    if (allowedDepartments[department.id]) {
      scopedDepartments.push(department);
      return;
    }

    const limitedDepartment: Required<TDepartment> = {
      ...department,
      sections: [],
    };

    _.each(department.sections || [], (section) => {
      if (allowedSections[section.id]) {
        limitedDepartment.sections?.push(section);
        scopedSections.push(section);
        return;
      }

      const limitedSection: Required<TSection> = {
        ...section,
        subsections: [],
      };

      _.each(section.subsections || [], (subsection) => {
        if (allowedSubsections[subsection.id]) {
          limitedSection.subsections?.push(subsection);
          scopedSubsections.push(subsection);
        }
      });

      if (limitedSection.subsections.length) {
        limitedDepartment.sections.push(limitedSection);
        scopedSections.push(limitedSection);
      }
    });

    if (limitedDepartment.sections.length) {
      scopedDepartments.push(limitedDepartment);
    }
  });

  return { scopedDepartments, scopedSections, scopedSubsections };
}

export function useUserAllowedDepartments() {
  const clientId = useClientId();
  const { permissions } = useClientPermissions(clientId);

  const { data: clientData, loading } = useQuery<{
    client: Pick<Client, 'departments' | 'id'>;
  }>(GET_CLIENT_DEPARTMENTS, {
    variables: {
      id: clientId,
    },
  });

  const { data } = useUserFromJWToken();
  const user = data?.user;

  const allowedDepartments = useMemo(() => {
    if (!user || !clientData) {
      return {
        scopedDepartments: [],
        scopedSections: [],
        scopedSubsections: [],
      };
    }
    const { client } = clientData;
    const { departments = [] } = client;

    if (!permissions?.limitedAccessToDepartments) {
      return computeDepartmentsFromScopes({
        departments,
        allowedDepartmentIds: _.pluck(departments, 'id'),
        allowedSectionIds: [],
        allowedSubsectionIds: [],
      });
    }

    const { userCurrentClientSettings } = user;
    if (!userCurrentClientSettings) {
      return {
        scopedDepartments: [],
        scopedSections: [],
        scopedSubsections: [],
      };
    }

    const {
      departmentScopes = [] as DepartmentScopeSet[],
    } = userCurrentClientSettings;

    const allowedSubsectionIds =
      _.findWhere(departmentScopes, {
        type: 'subsections',
      })?.subsectionIds ?? [];
    const allowedSectionIds =
      _.findWhere(departmentScopes, {
        type: 'sections',
      })?.sectionIds ?? [];

    let allowedDepartmentIds;
    const hasAllDepartments = _.findWhere(departmentScopes, {
      type: 'all-departments',
    });

    if (hasAllDepartments) {
      allowedDepartmentIds = _.pluck(departments, 'id');
    } else {
      allowedDepartmentIds =
        _.findWhere(departmentScopes, {
          type: 'departments',
        })?.departmentIds ?? [];
    }

    const result = computeDepartmentsFromScopes({
      allowedDepartmentIds,
      allowedSectionIds,
      allowedSubsectionIds,
      departments,
    });
    return result;
  }, [clientData, user, permissions?.limitedAccessToDepartments]);

  const fullPermissions = useMemo(() => {
    if (!allowedDepartments) {
      return {
        sections: [],
        departments: [],
        subsections: [],
      };
    }
    const { scopedDepartments } = allowedDepartments;
    // Reconstruct tree from scopedDepartments
    const sections: Section[] = [];
    const subsections: Subsection[] = [];
    _.forEach(scopedDepartments, (department) => {
      _.forEach(department.sections || [], (section) => {
        sections.push(section);

        _.forEach(section.subsections || [], (subsection) => {
          subsections.push(subsection);
        });
      });
    });

    return {
      departments: scopedDepartments,
      sections,
      subsections,
    };
  }, [allowedDepartments]);

  return {
    ...allowedDepartments,
    ...fullPermissions,
    loading,
  };
}
