import _ from 'underscore';
import { Link } from 'react-router-dom';
import React, { useMemo, useContext } from 'react';

import useClientId from '@/hooks/router/useClientId';

import { SearchPoolProfile } from '@/types/searchPoolProfile';
import { ListButton } from '@/components/Common/ListButton';
import useSearchPoolJobs from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobs';
import { ISearchPoolJob } from '@/graphql/searchPoolJobs';
import { getTranslatedText } from '@/common';
import MissionCategoryContext from '@/context/MissionCategoryContext';

import styles from './ProfileMIssionList.module.less';

export function ProfileMission({ id, title }: { id: string; title: string }) {
  const missionCategory = useContext(MissionCategoryContext);
  const clientId = useClientId();
  const urlTo = useMemo(() => {
    return `/client/${clientId}/reveal/projects/${missionCategory}/${id}`;
  }, [id, clientId, missionCategory]);

  return (
    <ListButton as={Link} link to={urlTo}>
      <span>{title}</span>
    </ListButton>
  );
}

export function ProfileMissionList({
  profile,
}: {
  profile: SearchPoolProfile;
}) {
  return _.map(profile.missionsInfo || [], ({ missionId, mission }) => (
    <ProfileMission
      key={missionId}
      id={missionId}
      title={mission.data!.title!}
    />
  ));
}

export function ProfileMissionsListInline({
  profile,
}: {
  profile: SearchPoolProfile;
}) {
  const { data, loading } = useSearchPoolJobs(
    'reveal',
    {
      includeAll: true,
    },
    'cache-first',
  );

  const jobsById = useMemo(() => {
    if (!data) {
      return {};
    }

    const { jobs } = data?.searchPool;
    return _.reduce(
      jobs || [],
      (agg, job) => {
        agg[job.id] = job;
        return agg;
      },
      {} as Record<string, ISearchPoolJob>,
    );
  }, [data]);

  if (loading) {
    return ('-' as unknown) as JSX.Element;
  }

  const linkedSelf = _.findWhere(
    profile.linkedProfilesGroup?.linkedProfileReferences || [],
    {
      profileId: profile.id,
    },
  );

  // const missionTitlesFromMetadata = {};

  const linkedSelfMissionsMetadata = _.findWhere(linkedSelf?.metadata, {
    key: 'missionIds',
  });
  // const linkedSelfMissionsTitlesMetadata = _.findWhere(linkedSelf?.metadata, {
  //   key: 'missionTitle',
  // });

  // const allMetadataIds = linkedSelfMissionsMetadata?.value.split(';');
  // const allMetadataTitles = linkedSelfMissionsTitlesMetadata?.value.split(
  //   ';;;',
  // );

  // const otherMetadataIds = [];
  // const otherMetadataTitles = [];

  const selfMissions = _.compact(
    _.map(linkedSelfMissionsMetadata?.value?.split(';'), (id) => {
      const job = jobsById[id];
      if (job?.isArchived) {
        return null;
      }

      return job;
    }),
  );

  let otherMissions = _.map(
    profile.linkedProfilesGroup?.linkedProfileReferences || [],
    ({ metadata, title }) => {
      const value = _.findWhere(metadata, {
        key: 'missionIds',
      })?.value;

      // const titles = _.findWhere(metadata, {
      //   key: 'missionTitle',
      // })?.value;

      const missionIds = value?.split(';');
      // otherMetadataIds.push(...missionIds);
      // const missionTitles = titles?.split(';;;');
      // otherMetadataTitles.push(...missionTitles);

      const missions = _.map(missionIds, (id) => {
        const mission = jobsById[id];
        if (!mission) {
          return null;
        }

        if (_.findWhere(selfMissions, { id })) {
          return null;
        }

        // Do not display archived missions
        if (mission.isArchived) {
          return null;
        }

        return {
          ...mission,
          data: {
            ...mission.data,
            title: `${mission.data?.title} (${getTranslatedText(
              title,
            )})`.trim(),
          },
        };
      });

      return missions;
    },
  );

  otherMissions = _.compact(_.flatten(otherMissions));

  // const metadataTitlesById = {} as Record<string, string>;
  // for (let i = 0; i < allMetadataIds.length; i++) {
  //   const id = allMetadataIds[i];
  //   const title = allMetadataTitles[i];
  //   metadataTitlesById[id] = title;
  // }


  const selfTitles = _.map(selfMissions, (mission) => (
    <div key={mission.id}>{mission.data.title}</div>
  ));
  const otherTitles = _.map(otherMissions, (mission) => (
    <div key={mission.id}>{mission.data.title}</div>
  ));

  return (
    <div>
      <b className={styles.bold}>{selfTitles}</b>
      {otherTitles && <span className={styles.normal}>{otherTitles}</span>}
    </div>
  );
}
