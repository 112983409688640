import React from 'react';
import _ from 'underscore';
import { Dropdown, Loader, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import GenericButton from '@/components/Common/GenericButton';
import { FormField } from '@/components/FormFields';
import useSharedMessagingAccounts from '@/graphql/hooks/clients/useSharedServiceAccount';
import useSearchPoolJob from '@/graphql/hooks/searchPoolJobs/useSearchPoolJob';
import GenericTextInput from '@/components/Common/GenericTextInput';
import { useMutation } from '@apollo/client';
import {
  CREATE_DYNAMIC_IMPORTS,
  DELETE_DYNAMIC_IMPORTS,
  UPDATE_DYNAMIC_IMPORTS,
} from '@/graphql/DynamicImports';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import InfoTooltip from '@/components/InfoTooltip';
import styles from './DynamicImports.module.less';

interface DynamicImportsProps {
  jobId: string;
}

const DynamicImports: React.FC<DynamicImportsProps> = ({ jobId }) => {
  const [selectedMessagingAccount, setSelectedMessagingAccount] =
    React.useState<string>();
  const [selectedJobId, setSelectedJobId] = React.useState<string>();
  const [selectedAccountType, setSelectedAccountType] =
    React.useState<string>();
  const [isCreating, setIsCreating] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const { sharedMessagingAccounts, loading } = useSharedMessagingAccounts();
  const notification = useNotificationSystem();

  const [updateImport] = useMutation(UPDATE_DYNAMIC_IMPORTS);
  const [createImport] = useMutation(CREATE_DYNAMIC_IMPORTS);
  const [deleteImport] = useMutation(DELETE_DYNAMIC_IMPORTS);

  const { data: jobData, loading: jobLoading } = useSearchPoolJob(
    'reveal',
    jobId,
  );

  const { job } = jobData?.searchPool || {};

  const sharedAccountsOptions = _.map(
    _.filter(
      sharedMessagingAccounts,
      (account) => account.type === 'unipile-linkedin',
    ),
    (account) => ({
      key: account.id,
      text: account.name,
      value: account.id,
    }),
  );

  const accountTypeOptions = [
    {
      key: 'unipile-linkedin-recruiter-project',
      text: t('reveal.missions.mission.settingsTab.imports.recruiterProject'),
      value: 'unipile-linkedin-recruiter-project',
    },
    {
      key: 'unipile-salesnavigator-list',
      text: t('reveal.missions.mission.settingsTab.imports.salesNavigatorList'),
      value: 'unipile-salesnavigator-list',
    },
  ];

  const onAccountChange = React.useCallback(
    ({ accountId }: { accountId: string }) => {
      setSelectedMessagingAccount(accountId);
    },
    [],
  );

  const onSourceJobIdChange = React.useCallback(({ sourceJobId }) => {
    setSelectedJobId(sourceJobId);
  }, []);

  if (jobLoading || loading) {
    return <Loader inline='centered' active />;
  }

  return (
    <Segment className='pure-criteria-form'>
      <div className={styles.dynamicImportsSection}>
        <div className={styles.header}>
          <h2>{t(`reveal.missions.mission.settingsTab.imports.title`)}</h2>
          {!_.isEmpty(job?.dynamicImports) ? (
            <div className={styles.actions}>
              <GenericButton
                primacy='secondary'
                color='red'
                onClick={async () =>
                  deleteImport({
                    variables: {
                      searchPoolId: 'reveal',
                      input: { jobId, id: job.dynamicImports[0].id },
                    },
                  })
                }
              >
                {t(`reveal.missions.mission.settingsTab.imports.removeImport`)}
              </GenericButton>
              <GenericButton
                primacy='primary'
                onClick={async () => {
                  const { data } = await updateImport({
                    variables: {
                      searchPoolId: 'reveal',
                      input: {
                        jobId,
                        id: job.dynamicImports[0].id,
                        unipileSharedAccountId:
                          selectedMessagingAccount ||
                          job.dynamicImports[0].descriptor
                            ?.unipileSharedAccountId,
                        type:
                          selectedAccountType ||
                          job.dynamicImports[0]?.type ||
                          'unipile-linkedin-recruiter-project',
                        sourceJobId:
                          selectedJobId ||
                          job.dynamicImports[0].descriptor.sourceJobId,
                      },
                    },
                  });
                  if (!data) {
                    notification.error(t('common.genericError'));
                  } else {
                    notification.success(t('common.genericSuccess'));
                  }
                }}
              >
                {t('common.save')}
              </GenericButton>
            </div>
          ) : isCreating ? (
            <GenericButton
              primacy='primary'
              onClick={async () => {
                const { data } = await createImport({
                  variables: {
                    searchPoolId: 'reveal',
                    input: {
                      jobId,
                      dynamicImportId: job?.dynamicImports?.[0]?.id,
                      unipileSharedAccountId:
                        selectedMessagingAccount ||
                        job?.dynamicImports?.[0]?.descriptor
                          ?.unipileSharedAccountId,
                      type:
                        selectedAccountType ||
                        job?.dynamicImports?.[0]?.type ||
                        'unipile-linkedin-recruiter-project',
                      sourceJobId:
                        selectedJobId ||
                        job?.dynamicImports?.[0]?.descriptor?.sourceJobId,
                    },
                  },
                });
                if (!data) {
                  notification.error(t('common.genericError'));
                } else {
                  notification.success(t('common.genericSuccess'));
                }
                setIsCreating(false);
              }}
            >
              {t('common.save')}
            </GenericButton>
          ) : null}
        </div>
        {/* <div className={styles.hint}>
          <i className='ri-lightbulb-flash-line' />
          <span>{t(`reveal.missions.mission.settingsTab.imports.hint`)}</span>
        </div> */}
        <div className={styles.content}>
          {_.isEmpty(job?.dynamicImports) && !isCreating ? (
            <div>
              <GenericButton
                primacy='primary'
                onClick={async () => {
                  setIsCreating(true);
                }}
              >
                {t(`reveal.missions.mission.settingsTab.imports.newImport`)}
              </GenericButton>
            </div>
          ) : (
            <div className={styles.inputsContainer}>
              <FormField>
                <label>
                  {t(`reveal.missions.mission.settingsTab.imports.account`)}
                </label>
                <Dropdown
                  id='type'
                  fluid
                  search
                  selections
                  selectOnBlur={false}
                  placeholder={t(
                    'reveal.missions.mission.settingsTab.imports.accountPlaceholder',
                  )}
                  selection
                  className='hiresweet-rounded'
                  defaultValue={
                    job?.dynamicImports?.[0]?.descriptor
                      ?.unipileSharedAccountId || ''
                  }
                  onChange={(e, { value }) => {
                    onAccountChange({ accountId: value as string });
                  }}
                  options={sharedAccountsOptions}
                  value={selectedMessagingAccount}
                />
              </FormField>
              <FormField>
                <label>
                  {t(`reveal.missions.mission.settingsTab.imports.accountType`)}
                </label>
                <Dropdown
                  id='account-type'
                  fluid
                  search
                  selections
                  selectOnBlur={false}
                  selection
                  className='hiresweet-rounded'
                  defaultValue={
                    job?.dynamicImports?.[0]?.type ||
                    'unipile-linkedin-recruiter-project'
                  }
                  onChange={(e, { value }) => {
                    setSelectedAccountType(value as string);
                  }}
                  options={accountTypeOptions}
                  value={selectedAccountType}
                />
              </FormField>
              <FormField>
                <label>
                  {t(`reveal.missions.mission.settingsTab.imports.jobId`)}
                  <InfoTooltip
                    text={t(
                      'reveal.missions.mission.settingsTab.imports.identifierTooltip',
                    )}
                  />
                </label>
                <GenericTextInput
                  fluid
                  placeholder='123456789'
                  value={
                    selectedJobId ||
                    job?.dynamicImports?.[0]?.descriptor?.sourceJobId ||
                    ''
                  }
                  className={styles.input}
                  onChange={(e) =>
                    onSourceJobIdChange({ sourceJobId: e.target.value })
                  }
                />
              </FormField>
            </div>
          )}
        </div>
      </div>
    </Segment>
  );
};

export default DynamicImports;
