import _, { compose } from 'underscore';
import React, { useEffect, useState } from 'react';
import { Redirect, useLocation, useParams, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import classNames from 'classnames';
import qs from 'qs';

import { useSessionSearch } from '@/context/SessionSearchContext';
import { defaultBoolean } from '../../common';
import routerParamsToProps from '../../hocs/routerParamsToProps';
import withClientPermissions from '../../hocs/clients/withClientPermissions';
import withUserFromJWToken from '../../hocs/users/withUserFromJWToken';
import withSavedSearches from '../../hocs/clients/withSavedSearches';
import Header from '../../containers/TopBar/Header';
import { areFiltersEmpty } from './SearchFilters';
import WatchCollectContainer from './WatchCollectContainer';
import {
  RECENCY_ORDER,
  RELEVANCY_ORDER,
} from './WatchCollectContainer/SortByDropdown';
import { OR_OPERATOR } from './WatchCollectFiltersModal/OperatorToggle';
import { INIT_CRITERIA, SHOWN_INTEREST_CRITERIA } from './constants';
import { formatForSearchQuery } from './helpers';

import './WatchCollectAllCandidates.css';

const EXCLUDE_HIDDEN_DEFAULT = true;
const INCLUDE_POFILES_IN_JOB_DEFAULT = 'pending-only';
const SHOWN_INTEREST_ONLY_DEFAULT = false;
const DEFAULT_RESPONSIBILITIES = [];

export const WatchCollectAllCandidates = compose(
  withClientPermissions,
  withUserFromJWToken,
  withTranslation('translations'),
  withSavedSearches,
)(
  ({
    clientId,
    permissions,
    permissionsLoading,
    user,
    savedSearches,
    cardMode = 'large',
    setCardMode,
    offerCriteria,
    t,
  }) => {
    const { offerId } = useParams();
    const { search: querySearch } = useLocation();
    const queryObject = qs.parse(querySearch.replace('?', ''));
    const shownInterestOnlyParam = JSON.parse(
      queryObject.shownInterestOnly || 'false',
    );

    const { sessionSearch, setSessionSearch } = useSessionSearch(
      offerCriteria || INIT_CRITERIA,
    );

    let initCriteria = offerCriteria || INIT_CRITERIA;
    if (sessionSearch?.expirationTimestamp > Date.now()) {
      initCriteria = sessionSearch?.criteria;
    }

    if (shownInterestOnlyParam) {
      initCriteria = SHOWN_INTEREST_CRITERIA;
    }

    const DEFAULT_SORT_BY = areFiltersEmpty({ filters: initCriteria })
      ? RECENCY_ORDER
      : RELEVANCY_ORDER;

    // NOTE: default values are necessary for incomplete stored sessionSearch
    const [locationsAndRemote, setLocationsAndRemote] = useState(
      initCriteria?.locationsAndRemote || {},
    );

    const [schoolTypes, setSchoolTypes] = useState(
      initCriteria.schoolTypes || [],
    );
    const [jobPositions, setJobPositions] = useState(
      initCriteria.jobPositions || [],
    );
    const [jobPositionsOperator, setJobPositionsOperator] = useState(
      initCriteria.jobPositionsOperator || OR_OPERATOR,
    );
    const [skills, setSkills] = useState(initCriteria.skills || []);
    const [skillsOperator, setSkillsOperator] = useState(
      initCriteria.skillsOperator || OR_OPERATOR,
    );
    const [experienceYearsMin, setExperienceYearsMin] = useState(
      initCriteria.experienceYearsMin || '',
    );
    const [experienceYearsMax, setExperienceYearsMax] = useState(
      initCriteria.experienceYearsMax || '',
    );
    const [salaryMin, setSalaryMin] = useState(initCriteria.salaryMin);
    const [salaryMax, setSalaryMax] = useState(initCriteria.salaryMax);
    const [contracts, setContracts] = useState(initCriteria.contracts || []);
    const [responsibilities, setResponsibilities] = useState(
      initCriteria.responsibilities || DEFAULT_RESPONSIBILITIES,
    );

    const [sortBy, setSortBy] = useState(DEFAULT_SORT_BY);

    // 'all', 'new', 'new-next-week', 'saved' or 'contacted'
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [excludeHidden, setExcludeHidden] = useState(
      defaultBoolean(initCriteria.excludeHidden, EXCLUDE_HIDDEN_DEFAULT),
    );
    const [includeProfilesInJob, setIncludeProfilesInJob] = useState(
      initCriteria.includeProfilesInJob || 'pending-only',
    );
    const [shownInterestOnly, setShownInterestOnly] = useState(
      defaultBoolean(
        initCriteria.shownInterestOnly,
        SHOWN_INTEREST_ONLY_DEFAULT,
      ),
    );

    const [selectedSearchIds] = useState([]);

    const resetSearchInputs = ({ criteria: newCriteria }) => {
      const criteria = _.omit(newCriteria || {}, '__typename');
      setLocationsAndRemote(criteria?.locationsAndRemote || {});
      setSchoolTypes(criteria?.schoolTypes || []);
      setJobPositions(criteria.jobPositions || []);
      setJobPositionsOperator(criteria.jobPositionsOperator || OR_OPERATOR);
      setSkills(criteria.skills || []);
      setSkillsOperator(criteria.skillsOperator || OR_OPERATOR);
      setContracts(criteria.contracts || []);
      setSalaryMin(criteria.salaryMin || '');
      setSalaryMax(criteria.salaryMax || '');
      setExperienceYearsMin(criteria.experienceYearsMin || '');
      setExperienceYearsMax(criteria.experienceYearsMax || '');
      setResponsibilities(
        criteria?.responsibilities || DEFAULT_RESPONSIBILITIES,
      );

      setSelectedCategory(criteria?.selectedCategory || 'all');
      setExcludeHidden(
        defaultBoolean(criteria.excludeHidden, EXCLUDE_HIDDEN_DEFAULT),
      );
      setIncludeProfilesInJob(
        criteria?.includeProfilesInJob || INCLUDE_POFILES_IN_JOB_DEFAULT,
      );
      setShownInterestOnly(
        defaultBoolean(criteria.shownInterestOnly, SHOWN_INTEREST_ONLY_DEFAULT),
      );

      if (!areFiltersEmpty({ filters: newCriteria })) {
        setSortBy(RELEVANCY_ORDER);
      }
    };

    // TODO: redux-like hook + context ?
    const filtersState = {
      values: {
        locationsAndRemote,
        schoolTypes,
        jobPositions,
        jobPositionsOperator,
        skills,
        skillsOperator,
        contracts,
        salaryMin,
        salaryMax,
        experienceYearsMin,
        experienceYearsMax,
        excludeHidden,
        includeProfilesInJob,
        shownInterestOnly,
        responsibilities,
        selectedCategory,
        sortBy,
      },
      setLocationsAndRemote,
      setSchoolTypes,
      setJobPositions,
      setJobPositionsOperator,
      setSkills,
      setSkillsOperator,
      setContracts,
      setSalaryMin,
      setSalaryMax,
      setExperienceYearsMin,
      setExperienceYearsMax,
      setExcludeHidden,
      setIncludeProfilesInJob,
      setShownInterestOnly,
      setSelectedCategory,
      setResponsibilities,
      setSortBy,
      resetSearchInputs,
    };

    useEffect(() => {
      if ((selectedSearchIds || []).length === 1) {
        const selectedSearchId = selectedSearchIds[0];
        const selectedSearch = _.findWhere(savedSearches, {
          id: selectedSearchId,
        });
        if (selectedSearch) {
          resetSearchInputs({ criteria: selectedSearch.criteria });
        } else {
          resetSearchInputs({});
        }
      } else if ((selectedSearchIds || []).length > 1) {
        resetSearchInputs({});
      }
    }, [savedSearches, selectedSearchIds]);

    useEffect(() => {
      /**
       * The search view has got a kind of "interest filter mode" when the URL
       * has a "shownInterestOnly" parameter.
       *
       * This mode activates one filter only and disables all the others, but
       * it shouldn't affect the application outside of this mode. For instance
       * parameters kept in localStorage shouldn't be changed, so that the user
       * can resume their search in "normal mode".
       */
      if (shownInterestOnlyParam) {
        return;
      }
      const currentSessionSearch = {
        expirationTimestamp: Date.now() + 2 * 3600 * 1000,
        criteria: {
          locationsAndRemote,
          schoolTypes,
          jobPositions,
          jobPositionsOperator,
          experienceYearsMin,
          experienceYearsMax,
          skills,
          skillsOperator,
          salaryMin,
          salaryMax,
          contracts,
          responsibilities,
          excludeHidden,
          includeProfilesInJob,
          shownInterestOnly,
        },
      };
      setSessionSearch(currentSessionSearch);
    }, [
      shownInterestOnlyParam,
      locationsAndRemote,
      schoolTypes,
      jobPositions,
      jobPositionsOperator,
      experienceYearsMin,
      experienceYearsMax,
      skills,
      skillsOperator,
      salaryMin,
      salaryMax,
      contracts,
      responsibilities,
      excludeHidden,
      includeProfilesInJob,
      shownInterestOnly,
      setSessionSearch,
    ]);

    // TODO merge search and filtersState
    const search = {
      criteria: formatForSearchQuery({
        locationsAndRemote,
        schoolTypes,
        jobPositions,
        jobPositionsOperator,
        experienceYearsMin,
        experienceYearsMax,
        skills,
        skillsOperator,
        salaryMin,
        salaryMax,
        contracts,
        responsibilities,
        excludeHidden,
        includeProfilesInJob,
        shownInterestOnly,
      }),
      category: selectedCategory,
    };

    if (permissionsLoading) {
      return (
        <div className='watch-collect-all-candidates-loading'>
          <Loader active inline='centered' size='large' />
        </div>
      );
    }

    if (!permissionsLoading && !permissions?.watchCollect) {
      return <Redirect to={`/client/${clientId}/dashboard`} />;
    }

    let searches;
    if ((selectedSearchIds || []).length <= 1) {
      searches = [search?.criteria || {}];
    } else {
      searches = _.compact(
        _.map(selectedSearchIds, (selectedSearchId) => {
          const savedSelectedSearch = _.findWhere(savedSearches, {
            id: selectedSearchId,
          });
          return _.omit(savedSelectedSearch?.criteria, '__typename');
        }),
      );
    }

    const onClickResetAll = () => {
      setSortBy(RECENCY_ORDER);
      resetSearchInputs({});
    };

    return (
      <div
        className={classNames('watch-collect-all-candidates', {
          'watch-collect-all-candidates--embedded': offerId !== undefined,
        })}
      >
        <WatchCollectContainer
          searchPoolId='watch'
          searches={searches}
          selectedCategory={selectedCategory}
          clientId={clientId}
          user={user}
          selectedSearchIds={selectedSearchIds}
          search={search}
          excludeHidden={excludeHidden}
          includeProfilesInJob={includeProfilesInJob}
          shownInterestOnly={shownInterestOnly}
          filtersState={filtersState}
          onClickResetAll={onClickResetAll}
          cardMode={cardMode}
          setCardMode={setCardMode}
          sortBy={sortBy}
          setSortBy={setSortBy}
          onApplySearch={resetSearchInputs}
          t={t}
        />
      </div>
    );
  },
);

const WatchCollectViewContainer = ({ clientId }) => {
  return (
    <div className='parametersview watch-collect'>
      <div className='header-placeholder' />
      <div className='stickable-header'>
        <div className='top-header'>
          <Header clientId={clientId} pageId='collect' />
        </div>
      </div>
      <WatchCollectAllCandidates clientId={clientId} searchPoolId='watch' />
    </div>
  );
};

export default compose(
  routerParamsToProps,
  withRouter,
)(WatchCollectViewContainer);
