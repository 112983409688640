import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import JobFoldering from '../../graphql/fragments/JobFoldering';
import OfferStatuses from '../../graphql/fragments/OfferStatuses';
import RecommendedProfile from '../../graphql/fragments/RecommendedProfile';

export const getRecommendedProfiles = gql`
  query getRecommendedProfiles($clientId: ID!, $limit: Int, $sortBy: String) {
    client(id: $clientId) {
      id
      offers(activeOnly: true) {
        id
        title
        owner {
          email
          firstname
          lastname
        }
        foldering {
          ...JobFoldering
        }
        ...OfferStatuses
        recommendedProfilesFeed(limit: $limit, sortBy: $sortBy) {
          profiles {
            ...RecommendedProfile
          }
          total
        }
      }
    }
  }
  ${RecommendedProfile}
  ${OfferStatuses}
  ${JobFoldering}
`;

export default graphql(getRecommendedProfiles, {
  options: ({ clientId, sortBy, limit }) => {
    const variables = { clientId, sortBy, limit };
    return {
      variables,
      fetchPolicy: 'network-only', // TODO: good ? Always refetch when tab is selected
    };
  },
  props: ({ data: { client, loading } }) => {
    return {
      jobsWithRecommendedProfiles: client && client.offers,
      jobsWithRecommendedProfilesLoading: loading,
    };
  },
});
