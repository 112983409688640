import gql from 'graphql-tag';

import Author from './Author';

const EvaluationFormField = gql`
  fragment EvaluationFormField on EvaluationFormField {
    id
    type
    title
    commentType
    coefficient
    value {
      grade
      comment
    }
  }
`;

const EvaluationForm = gql`
  fragment EvaluationForm on EvaluationForm {
    fields {
      ...EvaluationFormField
      subfields {
        ...EvaluationFormField
      }
    }
  }
  ${EvaluationFormField}
`;

export const EvaluationsSet = gql`
  fragment EvaluationsSet on EvaluationsSet {
    id
    title
    owner {
      ...Author
    }
    form {
      ...EvaluationForm
    }
    evaluations {
      id
      date
      author {
        ...Author
      }
      form {
        ...EvaluationForm
      }
    }
  }
  ${EvaluationForm}
  ${Author}
`;

export const EvaluationFormTemplate = gql`
  fragment EvaluationFormTemplate on EvaluationFormTemplate {
    id
    title
    author {
      ...Author
    }
    form {
      ...EvaluationForm
    }
  }
  ${EvaluationForm}
  ${Author}
`;
