import React from 'react';
import _ from 'underscore';
import { useTranslation, withTranslation } from 'react-i18next';
import Thumbnail from '../../../../../components/Thumbnail';
import {
  skipReasons,
  disqualifyReasons,
  newSkipReasons,
  newDisqualifyReasons,
} from '../../../../../common';
import TASK_ICONS from '../TaskIcons';
import AuthorAndDate from '../AuthorAndDate';

const getTextReasons = (d, t) => {
  const inSkipped = _.findWhere(skipReasons, { name: d });
  const inDisqualified = _.findWhere(disqualifyReasons, { name: d });
  const inNewSkipped = _.findWhere(newSkipReasons, { name: d });
  const inNewDisqualified = _.findWhere(newDisqualifyReasons, { name: d });
  if (inSkipped) {
    return t(`profile.steps.skip.reasons.${d}`);
  }
  if (inDisqualified) {
    return t(`profile.steps.disqualify.reasons.${d}`);
  }
  if (inNewSkipped) {
    return t(`profile.steps.newSkip.reasons.${d}`);
  }
  if (inNewDisqualified) {
    return t(`profile.steps.newDisqualify.reasons.${d}`);
  }
  return d;
};

const getCardBody = ({ details }, t) => {
  const detailsFormatted = (details || '').replace(
    /!schedule-reminder-[\d]{1,2}months/g,
    '',
  );
  return detailsFormatted ? (
    <div className='card-content comment'>
      <div className='body'>
        {t('profile.contact.timeline.reasons')}
        <ul>
          {_.map(_.compact(detailsFormatted.split(/;|#/)), (d) => (
            <li key={d}>{getTextReasons(d, t)}</li>
          ))}
        </ul>
      </div>
    </div>
  ) : null;
};

class ClassicStepTimelineItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { profile, timelineItem, t } = this.props;
    const author = timelineItem.author ? timelineItem.author : {};

    return (
      <div className='timeline-card-mail-wrapper classic-item'>
        {/* style={{ bottom: -40 }} */}
        <div className='content'>
          <div className='header'>
            <Thumbnail
              link={author.photoLink}
              firstname={author.firstname}
              lastname={author.lastname}
            />
            <div className='author'>
              <div className='date'>
                {+timelineItem.date
                  ? t('common.formattedDate', {
                      date: new Date(+timelineItem.date),
                    })
                  : t('common.defaultDuration')}
              </div>
              {(((timelineItem || {}).action || {}).step || {}).stepId ? (
                timelineItem.action.step.stepId === 'skipped' ||
                timelineItem.action.step.stepId === 'disqualified' ? (
                  <span>
                    {`${author.firstname} ${author.lastname} ${t(
                      'profile.contact.timeline.skipped',
                    )} ${profile?.resumeData.firstname} ${
                      profile?.resumeData.lastname
                    }`}
                  </span>
                ) : (
                  <span>
                    {`${author.firstname} ${author.lastname} ${t(
                      'profile.contact.timeline.moved',
                    )} ${profile?.resumeData.firstname} ${
                      profile?.resumeData.lastname
                    } ${t('profile.contact.timeline.inCategory')} ${t(
                      `profile.steps.pipe.${timelineItem.action.step.stepId}`,
                      timelineItem.action.step.stepId,
                    )}`}
                  </span>
                )
              ) : null}
            </div>
          </div>
          {(((timelineItem || {}).action || {}).step || {}).stepId &&
          (timelineItem.action.step.stepId === 'skipped' ||
            timelineItem.action.step.stepId === 'disqualified') &&
          timelineItem.action.step.details
            ? getCardBody(timelineItem.action.step, t)
            : null}
        </div>
      </div>
    );
  }
}

const getCardDescription = ({
  stepId,
  stepIsSkipOrDisqualify,
  firstname,
  lastname,
  author,
  t,
}) => {
  if (!stepId) {
    return null;
  }
  if (stepIsSkipOrDisqualify) {
    return `${author.firstname} ${author.lastname} ${t(
      'profile.contact.timeline.skipped',
    )} ${firstname} ${lastname}`;
  }
  return `${author.firstname} ${author.lastname} ${t(
    'profile.contact.timeline.moved',
  )} ${firstname} ${lastname} ${t('profile.contact.timeline.inCategory')} ${t(
    `profile.steps.pipe.${stepId}`,
    stepId,
  )}`;
};

export const RevealStepTimelineItem = ({ profile, timelineItem }) => {
  const { t } = useTranslation();
  const author = timelineItem?.author || {};
  const stepId = timelineItem?.action?.step?.stepId;
  const stepIsSkipOrDisqualify =
    stepId === 'skipped' || stepId === 'disqualified';
  const { firstname, lastname } = profile?.resumeData || {};
  const description = getCardDescription({
    stepId,
    stepIsSkipOrDisqualify,
    firstname,
    lastname,
    author,
    t,
  });

  return (
    <div className='timeline-card-mail-wrapper'>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{ backgroundColor: TASK_ICONS.step.color }}
              className='icon'
            >
              <i className={`${TASK_ICONS.step.icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            <div
              dangerouslySetInnerHTML={{
                __html: description ? `<span>${description}</span>` : '',
              }}
            />
            <AuthorAndDate timelineItem={timelineItem} />
          </div>
        </div>
        {stepId && stepIsSkipOrDisqualify && timelineItem.action.step.details
          ? getCardBody(timelineItem.action.step, t)
          : null}
      </div>
    </div>
  );
};

const StepTimelineItem = ({ profile, timelineItem, applicationMode }) => {
  const { t } = useTranslation();
  if (applicationMode === 'classic') {
    return (
      <ClassicStepTimelineItem
        profile={profile}
        timelineItem={timelineItem}
        t={t}
      />
    );
  }
  return (
    <RevealStepTimelineItem profile={profile} timelineItem={timelineItem} />
  );
};

export default StepTimelineItem;
