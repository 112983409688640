import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import AllCandidatesIllustration from '@/containers/Parameters/Offers/AllCandidatesIllustration';
import useClientId from '@/hooks/router/useClientId';
import NewJobIllustration from '@/containers/Parameters/Offers/NewJobIllustration';
import GenericButton from '@/components/Common/GenericButton';
import logAction from '@/common/logAction';
import { useSearchPoolAllCandidatesCount } from '@/graphql/hooks/searchPool/useSearchPoolCounts';

import styles from './Content.module.less';

const Content: FC<{ close: () => void }> = ({ close }) => {
  const clientId = useClientId();
  const { t } = useTranslation();
  const history = useHistory();
  const { allCandidatesCount } = useSearchPoolAllCandidatesCount();

  return (
    <>
      <h1>{t('onboarding.start.title')}</h1>
      <p>{t('onboarding.start.subtitle')}</p>
      <section className={styles.section}>
        <NewJobIllustration className={styles.illustration} />
        <div className={styles.content}>
          <h2 className={styles.title}>
            {t('onboarding.start.createJob.title')}
          </h2>
          <div className={styles.description}>
            {t('onboarding.start.createJob.description')}
          </div>
        </div>
        <GenericButton
          onClick={() => {
            logAction({
              type: 'click-new-offer-link',
              info: {
                clientId,
                from: 'offers',
              },
            });
            close();
            history.push(`/client/${clientId}/new-job`);
          }}
        >
          {t('onboarding.start.createJob.cta')}
        </GenericButton>
      </section>
      <section className={styles.section}>
        <AllCandidatesIllustration className={styles.illustration} />
        <div className={styles.content}>
          <h2 className={styles.title}>
            {t('onboarding.start.explore.title')}
          </h2>
          <div className={styles.description}>
            {t('onboarding.start.explore.description', {
              count: allCandidatesCount,
            })}
          </div>
        </div>
        <GenericButton
          onClick={() => {
            close();
            history.push(`/client/${clientId}/discover/candidates`);
          }}
        >
          {t('onboarding.start.explore.cta')}
        </GenericButton>
      </section>
    </>
  );
};

export default Content;
