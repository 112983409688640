import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';
import gql from 'graphql-tag';
import React from 'react';

export type User = {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  status: string;
};

export const query = gql`
  query getClientUsers($id: ID!) {
    client(id: $id) {
      id
      users {
        id
        firstname
        lastname
        email
        status
      }
    }
  }
`;

type UsersResult = { client: { users: User[] } };
const useClientUsers = (
  clientId: string,
  options: QueryHookOptions = {},
): QueryResult<UsersResult> & { users: User[] } => {
  const result = useQuery<UsersResult>(query, {
    variables: { id: clientId },
    // use network-only as a default, if
    // defined in options it will be overridden
    fetchPolicy: 'network-only',
    ...options,
  });

  const users = React.useMemo(() => {
    return result?.data?.client?.users || [];
    // eslint-disable-next-line
  }, [result?.data]);

  return {
    ...result,
    users,
  };
};

export default useClientUsers;
