import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Integration from '../../graphql/fragments/Integration';

export const query = gql`
  query getClientIntegrations($clientId: ID!) {
    client(id: $clientId) {
      id
      integrations {
        ...Integration
      }
    }
  }
  ${Integration}
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      clientId: ownProps.clientId,
    },
  }),
  props: ({
    data: { client, loading: integrationsLoading, error: integrationsError },
  }) => {
    return {
      integrations: client && client.integrations,
      integrationsLoading,
      integrationsError,
    };
  },
});
