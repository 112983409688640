import { TFunction } from 'i18next';
import React, { Dispatch, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import _ from 'underscore';
import classNames from 'classnames';
import {
  GetSequenceListResults,
  GetSequenceListVariables,
  GET_SEQUENCE_LIST,
} from '@/graphql/sequences';

import styles from './RevealAnalyticsHeader/RevealAnalyticsHeader.module.less';

const MAX_RESULT_DISPLAY = 4;
interface SequenceSelectorProps {
  clientId: string;
  selectedSequence: string[];
  setSelectedSequence: Dispatch<string[]>;
  t: TFunction;
}

const SequenceSelector: React.FC<SequenceSelectorProps> = ({
  clientId,
  selectedSequence,
  setSelectedSequence,
  t,
}) => {
  // fetch the list of sequences
  const { data, loading } = useQuery<
    GetSequenceListResults,
    GetSequenceListVariables
  >(GET_SEQUENCE_LIST, {
    variables: { clientId, activeOnly: true },
  });

  // dropdown options
  const anySequenceTitle = t('reveal.analyticsView.allSequences');
  const options = useMemo(() => {
    const opts = data?.client?.sequences
      ? _.map(data?.client?.sequences, ({ id, title }) => ({
          text: title,
          value: id,
        }))
      : [];
    return opts;
  }, [data]);

  const renderLabel = (item: DropdownItemProps, index: number) => {
    if (index < MAX_RESULT_DISPLAY) {
      return {
        content: item.text,
      };
    }
    if (index === MAX_RESULT_DISPLAY) {
      return {
        content: (
          <div
            onClick={() =>
              setSelectedSequence(
                selectedSequence?.slice(0, MAX_RESULT_DISPLAY) ?? [],
              )
            }
          >
            {t('reveal.reports.header.countSequences', {
              nbr:
                (selectedSequence?.length ?? MAX_RESULT_DISPLAY) -
                MAX_RESULT_DISPLAY,
            })}
          </div>
        ),
      };
    }
    return null;
  };

  return (
    <Dropdown
      selection
      search
      multiple
      loading={loading}
      options={options}
      value={selectedSequence || []}
      onChange={(e, { value }) => {
        setSelectedSequence(value as string[]);
      }}
      placeholder={anySequenceTitle}
      renderLabel={renderLabel}
      className={classNames(
        styles.multiDropdown,
        styles.sequencesDropdown,
        'hiresweet-rounded',
      )}
    />
  );
};

export default SequenceSelector;
