import { gql } from "@apollo/client";
import SearchPoolMiniProfile from "./fragments/SearchPoolMiniProfile";

const SEARCH_POOL_MINI_PROFILE_BY_EMAIL_RESULTS = gql`
  query searchPoolProfilesByEmail($searchPoolId: ID!, $input: SearchPoolProfilesByEmailInput) {
    searchPool(id: $searchPoolId) {
      profilesByEmail(input: $input) {
        profiles {
          ...SearchPoolMiniProfile
        }
      }
    }
  }
  ${SearchPoolMiniProfile}
`;

export default SEARCH_POOL_MINI_PROFILE_BY_EMAIL_RESULTS;