import React from 'react';

import useClientId from '@/hooks/router/useClientId';

import {
  HiresweetLibProvider,
  HiresweetProfiles,
} from '@hiresweet/hiresweet-lib';

const PlaygroundJ2 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30 }}>
      <h1>PJ2 - Profiles</h1>
      <HiresweetLibProvider theme={theme} clientId={clientId}>
        <HiresweetProfiles />
      </HiresweetLibProvider>
    </div>
  );
};

export default PlaygroundJ2;
