import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import Check from '@/components/Common/Icons/Check';
import Cross from '@/components/Common/Icons/Cross';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, Modal } from 'semantic-ui-react';
import styles from './UnipileConnexionModal.module.less';

interface UnipileConnexionModalProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  hasValidated: boolean;
  hasErrors: boolean;
  createdAutomation?: any;
}

const UnipileConnexionModal: React.FC<UnipileConnexionModalProps> = ({
  open,
  onClose,
  onCancel,
  onConfirm,
  hasValidated,
  hasErrors,
  createdAutomation,
}) => {
  const { t } = useTranslation();

  return (
    <GenericModal
      size='tiny'
      open={open}
      closeOnDimmerClick
      closeOnEscape
      onClose={onClose}
    >
      <Modal.Header>
        {t('settings.automations.unipile.creationModal.title')}
      </Modal.Header>
      <Modal.Content>
        <div className={styles.modalContent}>
          {!hasValidated && !hasErrors && (
            <Loader
              className={styles.loader}
              indeterminate
              active
              size='massive'
            />
          )}
          {hasErrors && <Cross className={styles.cross} />}
          {hasValidated && (
            <div className={styles.modalSuccess}>
              <Check className={styles.checkmark} />
              <p>
                {createdAutomation?.type === 'unipile-linkedin'
                  ? t('settings.automations.unipile.creationSuccessLinkedin', {
                      account: createdAutomation?.name,
                    })
                  : t('settings.automations.unipile.creationSuccessWhatsapp', {
                      account: createdAutomation?.name,
                    })}
              </p>
            </div>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions className={classnames(styles.confirmationModalActions)}>
        {!hasValidated && (
          <GenericButton
            primacy='primary'
            className={styles.confirmationButton}
            size='big'
            onClick={onCancel}
          >
            {t('common.cancel')}
          </GenericButton>
        )}
        {hasValidated && (
          <GenericButton
            primacy='primary'
            className={styles.confirmationButton}
            size='big'
            onClick={onConfirm}
          >
            {t('common.close')}
          </GenericButton>
        )}
      </Modal.Actions>
    </GenericModal>
  );
};

export default UnipileConnexionModal;
