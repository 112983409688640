import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import _, { compose } from 'underscore';

import { Button, Loader } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import GenericButton from '@/components/Common/GenericButton';
import logAction from '../../../common/logAction';

import withSearchEnrichedProfiles from '../../../hocs/searchPool/withSearchEnrichedProfiles';
import withMarkHiddenMultiple from '../../../hocs/searchPoolProfileState/withMarkHiddenMultiple';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import AddToNewJobMultipleModal from '../AddToNewJobMultipleModal';
import SearchPoolProfileModal from '../SearchPoolProfileModal';
import AddToJobMultipleModal from '../AddToJobMultipleModal';
import ClientProfileModal from '../ClientProfileModal';
import ProfileRow from '../../WatchCollectViewV2/WatchCollectRecommendations/JobProfileRecommendations/ProfileRow';
import ReviewProfileButton from './ReviewProfileButton';
import UnskipProfileButton from './UnskipProfileButton';
import SkipProfileButton from './SkipProfileButton';
import MiniCard from './MiniCard';
import { useEnrichedProfilesRouting } from './EnrichedProfiles.hooks';
import AddProfilesToJobButton from '../components/AddProfilesToJobButton';

import './EnrichedProfiles.css';

const EnrichedProfiles = ({
  searches,
  excludeHidden,
  excludeProfilesInJob,
  sortBy,
  searchPoolId,
  clientId,
  user,
  searchResultItems,
  enrichedProfiles,
  profileIds,
  getNextProfiles,
  hiddenProfileIds,
  setHiddenProfileIds,
  profilesInJobIds,
  setProfilesInJobIds,
  markHiddenMultiple,
  loadingEnrichedProfiles,
  cardMode,
  selectedProfileId,
  onSelectProfileId,
  t,
}) => {
  const DEFAULT_VISIBLE_PROFILES = cardMode === 'large' ? 10 : 20;

  const {
    clientProfileId,
    searchPoolId: querySearchPoolId,
    searchPoolProfileId,
    showClientProfileModal,
    onCloseModal,
  } = useEnrichedProfilesRouting({ selectedProfileId, onSelectProfileId });

  const [batchProfileIds, setBatchProfileIds] = useState();
  const [openSkipAllModal, setOpenSkipAllModal] = useState(false);
  const [openAddSelectionToJobModal, setOpenAddSelectionToJobModal] = useState(
    false,
  );
  const [
    openAddSelectionToNewJobModal,
    setOpenAddSelectionToNewJobModal,
  ] = useState(false);

  const addProfileToBatch = ({ profileId }) => {
    setBatchProfileIds((oldBatch) => [...(oldBatch || []), profileId]);
  };

  const removeProfileFromBatch = ({ profileId }) => {
    setBatchProfileIds((oldBatch) => _.without(oldBatch, profileId));
  };

  const isSelectedInBatch = ({ profileId }) => {
    return _.contains(batchProfileIds, profileId);
  };

  const handleToggleSelectAll = async () => {
    setBatchProfileIds((oldProfileIds) => {
      if (!_.isEmpty(oldProfileIds)) {
        return [];
      }
      let selectionIds = profileIds;
      if (excludeHidden) {
        selectionIds = _.difference(selectionIds, hiddenProfileIds);
      }
      if (excludeProfilesInJob) {
        selectionIds = _.difference(selectionIds, profilesInJobIds);
      }
      return selectionIds;
    });
  };

  useEffect(() => {
    setBatchProfileIds([]);
  }, [cardMode]);

  const profileIdToIndex = {};
  _.each(profileIds, (id, index) => {
    profileIdToIndex[id] = index;
  });

  const hasMore = profileIds && enrichedProfiles?.length < profileIds?.length;

  let visibleProfiles = enrichedProfiles;
  if (excludeHidden) {
    visibleProfiles = _.reject(visibleProfiles, ({ id }) =>
      _.contains(hiddenProfileIds, id),
    );
  }
  if (excludeProfilesInJob) {
    visibleProfiles = _.reject(visibleProfiles, ({ id }) =>
      _.contains(profilesInJobIds, id),
    );
  }
  const sortedVisibleProfiles = _.sortBy(
    visibleProfiles,
    (profile) => profileIdToIndex[profile?.id],
  );

  const loadMoreProfiles = async () => {
    if (loadingEnrichedProfiles) {
      return;
    }
    // must be enrichedProfiles' length so as to not request same ids twice
    const profilesCount = enrichedProfiles?.length || 0;
    try {
      await getNextProfiles({ searchResultItems, searches, profilesCount });
    } catch (e) {
      console.error(e);
    }
  };

  // reload some profiles if too many of them are hidden
  const visibleProfilesLength = sortedVisibleProfiles.length;
  useEffect(() => {
    // - visibleProfilesLength > 0 is a proxy to check that first loading completed
    // (using loadingEnrichedProfiles does not work because loadMoreProfiles changes it's value)
    //
    // -when visibleProfilesLength < 5, InfiniteScroll will not trigger loading as
    // component height progressively decreases so scrolling condition disappears
    // TODO: this is a bit hacky because it would not work on very tall screens
    if (
      visibleProfilesLength > 0 &&
      visibleProfilesLength < DEFAULT_VISIBLE_PROFILES &&
      hasMore
    ) {
      loadMoreProfiles();
    }

    // eslint-disable-next-line
  }, [visibleProfilesLength, hasMore, loadMoreProfiles]);

  const loader = (
    <div className='loading-placeholder'>
      <Loader active inline='centered' />
    </div>
  );

  const onMarkHidden = ({ searchPoolProfileId }) => {
    setHiddenProfileIds([...hiddenProfileIds, searchPoolProfileId]);
  };

  const onMarkHiddenMultiple = ({ searchPoolProfileIds }) => {
    setHiddenProfileIds([...hiddenProfileIds, ...(searchPoolProfileIds || [])]);
  };

  const onMarkUnhidden = ({ searchPoolProfileId }) => {
    setHiddenProfileIds(_.without(hiddenProfileIds, searchPoolProfileId));
  };

  const onClickMiniCard = ({ e, profileId }) => {
    // Don't open profile if click on modal
    const parentNodeClassName = e.target.parentNode?.className || '';
    if (
      _.isString(parentNodeClassName) &&
      parentNodeClassName.indexOf('modal') !== -1
    ) {
      return;
    }
    const grandParentNodeClassName =
      e.target.parentNode?.parentNode?.className || '';
    if (
      _.isString(grandParentNodeClassName) &&
      grandParentNodeClassName.indexOf('modal') !== -1
    ) {
      return;
    }
    const className = e.target.className || '';
    // Don't open profile if click on source-link
    if (
      _.isString(className) &&
      className.indexOf('source-link-inner') !== -1
    ) {
      return;
    }
    onSelectProfileId(profileId);
    logAction({
      type: 'discover-click-profile-card',
      info: {
        clientId,
        author: `${user?.firstname} ${user?.lastname}`,
        searchPoolProfileId: profileId,
      },
    });
  };

  const onClickProfileReviewButton = ({ e, profileId }) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    onSelectProfileId(profileId);
    logAction({
      type: 'discover-click-profile-row-review-button',
      info: {
        clientId,
        author: `${user?.firstname} ${user?.lastname}`,
      },
    });
  };

  const onClickReviewAllButton = () => {
    if (!_.isEmpty(batchProfileIds)) {
      onSelectProfileId(batchProfileIds[0]);
    } else {
      onSelectProfileId(profileIds[0]);
    }
    logAction({
      type: 'discover-click-review-all-button',
      info: {
        clientId,
        author: `${user?.firstname} ${user?.lastname}`,
      },
    });
  };

  const onClickProfileRowCheckbox = ({ e, profileId }) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    if (isSelectedInBatch({ profileId })) {
      removeProfileFromBatch({ profileId });
    } else {
      addProfileToBatch({ profileId });
    }
  };

  const onClickProfileRow = ({ profileId }) => {
    onSelectProfileId(profileId);
    logAction({
      type: 'discover-click-profile-row',
      info: {
        clientId,
        author: `${user?.firstname} ${user?.lastname}`,
      },
    });
  };

  const handleSkipAll = async () => {
    // setSkipping(true);
    try {
      await markHiddenMultiple({ searchPoolId, profileIds: batchProfileIds });
      onMarkHiddenMultiple({ searchPoolProfileIds: batchProfileIds });
      // TODO: after skip ? or not at all ?
      // await loadMoreProfiles();
      // skip currently displayed (which will remove them from view)
      // await skipOfferProfiles({ profileIds: batchProfileIds });
      setBatchProfileIds([]);
    } catch (e) {
      console.error(e);
    }
    // setSkipping(false);
  };

  const handleOpenSkipAllModal = async () => {
    if (_.isEmpty(batchProfileIds)) {
      return;
    }
    if (batchProfileIds.length === 1) {
      handleSkipAll();
      return;
    }
    setOpenSkipAllModal(true);
    logAction({
      type: 'discover-click-skip-all-button',
      info: {
        clientId,
        author: `${user?.firstname} ${user?.lastname}`,
      },
    });
  };

  const handleOpenAddSelectionToJobModal = async () => {
    if (_.isEmpty(batchProfileIds)) {
      return;
    }
    setOpenAddSelectionToJobModal(true);

    logAction({
      type: 'discover-click-add-selection-to-job-button',
      info: {
        clientId,
        author: `${user?.firstname} ${user?.lastname}`,
      },
    });
  };

  const handleConfirmSkipAll = async () => {
    handleSkipAll();
    setOpenSkipAllModal(false);
  };

  const onAddSelectionToJob = async ({ profileIds }) => {
    setProfilesInJobIds((oldIds) => _.union(oldIds, profileIds));
    setOpenAddSelectionToJobModal(false);
  };

  const onClickAddToNewJobButton = () => {
    setOpenAddSelectionToNewJobModal(true);
    setOpenAddSelectionToJobModal(false);
  };

  const onAddSelectionToNewJob = ({ profileIds }) => {
    setProfilesInJobIds((oldIds) => _.union(oldIds, profileIds));
  };

  const profileIdsToReview = _.isEmpty(batchProfileIds)
    ? profileIds
    : batchProfileIds;

  if (loadingEnrichedProfiles) {
    return loader;
  }

  if (!loadingEnrichedProfiles && _.isEmpty(sortedVisibleProfiles)) {
    return (
      <div className='enriched-profiles'>
        <div className='no-results'>
          <img
            src='/images/placeholders/jobs.svg'
            alt='recommendations-placeholder'
          />
          {/* <h3>{t('watchCollect.results.empty')}</h3> */}
        </div>
      </div>
    );
  }

  return (
    <div className='enriched-profiles'>
      {cardMode === 'small' && !_.isEmpty(sortedVisibleProfiles) && (
        <div className='batch-actions'>
          <GenericButton
            primacy='secondary'
            color='grey'
            onClick={handleToggleSelectAll}
          >
            {_.isEmpty(batchProfileIds)
              ? t('watchCollect.results.batchActions.selectAll')
              : t('watchCollect.results.batchActions.deselectAll')}
          </GenericButton>

          <GenericButton
            primacy='secondary'
            color='red'
            onClick={handleOpenSkipAllModal}
            disabled={_.isEmpty(batchProfileIds)}
          >
            <i className='ri-close-line' />
            {t('watchCollect.results.batchActions.skipSelection')}
          </GenericButton>

          <AddProfilesToJobButton
            profileIds={batchProfileIds}
            onAddProfiles={() =>
              onAddSelectionToJob({ profileIds: batchProfileIds })
            }
            openModal={handleOpenAddSelectionToJobModal}
          />

          <GenericButton onClick={onClickReviewAllButton}>
            {_.isEmpty(batchProfileIds)
              ? t('watchCollect.results.batchActions.review')
              : t('watchCollect.results.batchActions.reviewSelection')}
            <i className='ri-send-plane-2-fill' />
          </GenericButton>
        </div>
      )}
      <div
        id='infinite-scroll-container'
        className={classNames(
          'enriched-profiles-scroll-container',
          cardMode === 'small' && 'small-card-mode',
        )}
      >
        <InfiniteScroll
          className='infinite-scroll'
          dataLength={sortedVisibleProfiles?.length || 0}
          next={loadMoreProfiles}
          hasMore={hasMore}
          loader={loader}
          scrollThreshold='4000px'
          scrollableTarget='watch-collect-content-container'
        >
          {_.map(sortedVisibleProfiles, (enrichedProfile) => {
            const profileId = enrichedProfile?.id;
            const { searchPoolState } = enrichedProfile || {};

            const reviewButton = (
              <ReviewProfileButton
                onReview={(e) => onClickProfileReviewButton({ e, profileId })}
              />
            );

            const skipButton = searchPoolState?.hidden ? (
              <UnskipProfileButton
                searchPoolId={searchPoolId}
                searchPoolProfileId={profileId}
                onUnskip={onMarkUnhidden}
              />
            ) : (
              <SkipProfileButton
                searchPoolId={searchPoolId}
                searchPoolProfileId={profileId}
                onSkip={onMarkHidden}
              />
            );

            return cardMode === 'large' ? (
              <MiniCard
                key={enrichedProfile?.id}
                clientId={clientId}
                user={user}
                searches={searches}
                searchPoolId={searchPoolId}
                profile={enrichedProfile}
                onSelectProfileId={onSelectProfileId}
                onMarkHidden={onMarkHidden}
                onMarkUnhidden={onMarkUnhidden}
                setProfilesInJobIds={setProfilesInJobIds}
                onClick={(e) => onClickMiniCard({ e, profileId })}
              />
            ) : (
              <ProfileRow
                key={`profile-row-${profileId}`}
                profile={enrichedProfile}
                clientId={clientId}
                onCheck={(e) => onClickProfileRowCheckbox({ e, profileId })}
                onClick={() => onClickProfileRow({ profileId })}
                isSelectedInBatch={isSelectedInBatch({ profileId })}
                reviewButton={reviewButton}
                skipButton={skipButton}
              />
            );
          })}
          {selectedProfileId && (
            <SearchPoolProfileModal
              clientId={clientId}
              user={user}
              // searchPool profile
              selectedProfileId={selectedProfileId}
              searchPoolId={searchPoolId}
              onSelectProfileId={onSelectProfileId}
              profileIds={profileIdsToReview}
              onMarkHidden={onMarkHidden}
              onMarkUnhidden={onMarkUnhidden}
              setProfilesInJobIds={setProfilesInJobIds}
              searches={searches}
              onClose={onCloseModal}
              canApplyUnbiasedMode
            />
          )}
          {showClientProfileModal && (
            <ClientProfileModal
              clientId={clientId}
              user={user}
              // search pool
              searchPoolId={querySearchPoolId}
              searchPoolProfileId={searchPoolProfileId}
              // client profile
              clientProfileId={clientProfileId}
              onMarkHidden={onMarkHidden}
              onMarkUnhidden={onMarkUnhidden}
              setHiddenProfileIds={setHiddenProfileIds}
              setProfilesInJobIds={setProfilesInJobIds}
              searches={searches}
              sortBy={sortBy}
              excludeHidden={excludeHidden}
              excludeProfilesInJob={excludeProfilesInJob}
              onClose={onCloseModal}
            />
          )}
        </InfiniteScroll>
      </div>

      {openAddSelectionToJobModal && (
        <AddToJobMultipleModal
          clientId={clientId}
          profileIds={batchProfileIds}
          onAddSelectionToJob={() =>
            onAddSelectionToJob({ profileIds: batchProfileIds })
          }
          onClickAddToNewJobButton={onClickAddToNewJobButton}
          onClose={() => setOpenAddSelectionToJobModal(false)}
          open
        />
      )}
      {openAddSelectionToNewJobModal && (
        <AddToNewJobMultipleModal
          clientId={clientId}
          profileIds={batchProfileIds}
          searches={searches}
          onAddSelectionToNewJob={() =>
            onAddSelectionToNewJob({ profileIds: batchProfileIds })
          }
          onClose={() => setOpenAddSelectionToNewJobModal(false)}
          open
        />
      )}
      <ConfirmationModal
        open={openSkipAllModal}
        onCancel={() => setOpenSkipAllModal(false)}
        onSubmit={handleConfirmSkipAll}
        header={t('watchCollect.recommendationsView.skipAllModal.header')}
        content={t('watchCollect.recommendationsView.skipAllModal.content')}
        submit={t('watchCollect.recommendationsView.skipAllModal.confirm', {
          count: batchProfileIds?.length || 0,
        })}
      />
    </div>
  );
};

export default compose(
  withSearchEnrichedProfiles,
  withMarkHiddenMultiple,
  withTranslation('translations'),
)(EnrichedProfiles);
