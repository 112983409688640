import React from 'react';
import { Segment } from 'semantic-ui-react';
import { getActionTitle } from '../../../../../../common/constants/taskTypes';

import './FallbackAction.css';

const FallbackAction = ({ action }) => {
  return (
    <Segment className='fallback-action'>
      <div className='action-header'>{getActionTitle({ action })}</div>
    </Segment>
  );
};

export default FallbackAction;
