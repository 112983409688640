import React, {
  MouseEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
  FC,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { TooltipContext } from '../TooltipBoundary/TooltipBoundary';

import styles from './TooltipContainer.module.less';

interface TooltipContainerProps {
  tooltipContent: ReactNode | null;
}

const TooltipContainer: FC<TooltipContainerProps> = ({
  children,
  tooltipContent,
}) => {
  const [xPos, setXPos] = useState(0);
  const [yPos, setYPos] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const { boundaryRef } = useContext(TooltipContext);
  const [display, setDisplay] = useState(false);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      const tooltipWidth =
        tooltipRef.current?.getBoundingClientRect().width || 0;
      const tooltipHeight =
        tooltipRef.current?.getBoundingClientRect().height || 0;
      const boundaryRight =
        boundaryRef?.current?.getBoundingClientRect().right ||
        window.innerWidth;
      setXPos(
        event.pageX + 10 + tooltipWidth < boundaryRight
          ? event.pageX + 10
          : event.pageX - tooltipWidth - 10,
      );
      setYPos(
        event.pageY + 10 + tooltipHeight < document.body.clientHeight
          ? event.pageY + 10
          : event.pageY - tooltipHeight - 10,
      );
    },
    [boundaryRef],
  );

  const style = useMemo(() => ({ left: xPos, top: yPos }), [xPos, yPos]);

  useEffect(() => {
    const eventListener = () => setDisplay(false);
    document.body.addEventListener('wheel', eventListener);

    return () => document.body.removeEventListener('wheel', eventListener);
  }, []);

  return (
    <>
      <span
        ref={containerRef}
        className={styles.tooltipContainer}
        onMouseMove={handleMouseMove}
        onMouseEnter={() => setDisplay(!!tooltipContent)}
        onMouseLeave={() => setDisplay(false)}
      >
        {children}
      </span>
      {createPortal(
        <div
          className={classNames(styles.tooltip, { [styles.display]: display })}
          style={style}
          ref={tooltipRef}
        >
          {tooltipContent}
        </div>,
        document.body,
      )}
    </>
  );
};

export default TooltipContainer;
