import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'semantic-ui-react';
import _ from 'underscore';
import { MetaTaskFormatFormField } from '@/revealComponents/ExplicitTasks/MetaTaskForm/types';
import GenericSelect from '@/components/Common/GenericSelect';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { getTranslatedText } from '@/common';
import GenericDropdown from '@/components/Common/GenericDropdown';
import DropdownControlsContext from '@/context/DropdownControlsContext';
import Dots from '@/components/Common/Icons/Dots';
import DropdownPanel from '@/components/Common/DropdownPanel';
import DropdownMenuItem from '@/components/Common/DropdownMenuItem';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import ArrowUp from '@/components/Reveal/Icons/ArrowUp';
import Delete from '@/components/Reveal/Icons/Delete';
import styles from './CreateMetaTaskForm.module.less';

export interface MetaTaskCustomFieldFormProps {
  field: MetaTaskFormatFormField;
  onUpdateField: ({
    fieldId,
    newField,
  }: {
    fieldId: string;
    newField: MetaTaskFormatFormField;
  }) => void;
  onRemoveField?: ({ fieldId }: { fieldId: string }) => void;
  onMoveDownward?: ({ fieldId }: { fieldId: string }) => void;
  onMoveUpward?: ({ fieldId }: { fieldId: string }) => void;
  index: number;
  isLast?: boolean;
  customFields?: CustomFieldDefinition[];
}

const MetaTaskCustomFieldForm: React.FC<MetaTaskCustomFieldFormProps> = ({
  field,
  onUpdateField,
  onRemoveField,
  onMoveDownward,
  onMoveUpward,
  index,
  isLast,
  customFields,
}) => {
  const { t } = useTranslation();

  const customFieldsOptions = _.map(customFields || [], (customField) => ({
    value: customField?.id,
    label: getTranslatedText(customField?.title),
  }));

  return (
    <div className={styles.formFieldContainer}>
      <Form.Field className={styles.modalFormField}>
        <div className={styles.fieldTitle}>
          <div className={styles.fieldTitleInfo}>
            {t('reveal.modals.metaTaskModal.field')} {index}
            <span className='pill-message blue mini mini small-radius'>
              {t(
                `reveal.modals.metaTaskModal.customField.${
                  field.isMultiselect ? 'multi-enum' : field.subtype
                }`,
              )}
            </span>
          </div>
          <div className={styles.fieldTitleActions}>
            <GenericDropdown
              position='right'
              trigger={
                <DropdownControlsContext.Consumer>
                  {({ toggleDropdown }) => (
                    <div style={{cursor: 'pointer'}} onClick={toggleDropdown}>
                      <Dots />
                    </div>
                  )}
                </DropdownControlsContext.Consumer>
              }
            >
              <DropdownPanel className='dropdown-panel'>
                {!isLast ? (
                  <DropdownMenuItem
                    onClick={() => onMoveDownward &&
                      onMoveDownward({
                        fieldId: field.id,
                      })
                    }
                  >
                    <span className='dropdown-menu-item-icon'>
                      <ArrowDown />
                    </span>
                    {t('settings.departments.moveDownward')}
                  </DropdownMenuItem>
                ) : (
                  ''
                )}
                {index > 1 ? (
                  <DropdownMenuItem
                    onClick={() => onMoveUpward &&
                      onMoveUpward({
                        fieldId: field.id,
                      })
                    }
                  >
                    <span className='dropdown-menu-item-icon'>
                      <ArrowUp />
                    </span>
                    {t('settings.departments.moveUpward')}
                  </DropdownMenuItem>
                ) : (
                  ''
                )}
                <DropdownMenuItem
                  onClick={() => onRemoveField &&
                    onRemoveField({
                      fieldId: field.id,
                    })
                  }
                >
                  <span className='dropdown-menu-item-icon'>
                    <Delete />
                  </span>
                  {t('settings.departments.delete')}
                </DropdownMenuItem>
              </DropdownPanel>
            </GenericDropdown>
          </div>
        </div>
        <div className={styles.selectFieldQuestionContainer}>
          <GenericSelect
            className={styles.customFieldSelector}
            defaultValue={_.findWhere(customFieldsOptions || [], {
              value: field?.id || '',
            })}
            options={customFieldsOptions}
            onChange={(e) => {
              const originalField = _.findWhere(customFields || [], {
                id: e?.value,
              });
              onUpdateField({
                fieldId: field.id,
                newField: {
                  title: originalField?.title || { default: '' },
                  type: 'custom-field',
                  subtype: originalField?.type,
                  id: originalField?.id || '',
                  ...(originalField?.type === 'enum' && {
                    options: _.map(originalField?.options, (option) => ({
                      id: option.id,
                      title: option.title,
                    })),
                    isMultiselect: originalField.isMultiselect,
                  }),
                },
              });
            }}
          />
        </div>
      </Form.Field>
      {field.subtype === 'enum' && (
        <Form.Field className={styles.modalFormField}>
          <div className={styles.optionsSelector}>
            {_.map(field?.options || [], (option) => (
              <div className={styles.fieldOptionContainer} key={option.id}>
                <div className={styles.fieldOption}>
                  <Input
                    size='small'
                    className={styles.modalFormInput}
                    value={option.title.default}
                    placeholder='Option'
                    disabled
                  />
                </div>
              </div>
            ))}
          </div>
        </Form.Field>
      )}
    </div>
  );
};

export default MetaTaskCustomFieldForm;
