import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Project from '../../fragments/Project';

type ProjectType = 'applications' | 'recommendations' | 'imported-profiles';

const SEARCH_POOL_JOB_APPLICATION_REVIEW_PROJECT = gql`
  query getSearchPoolJobApplicationReviewProject(
    $searchPoolId: ID!
    $jobId: ID!
  ) {
    searchPool(id: $searchPoolId) {
      id
      job(id: $jobId) {
        id
        applicationReviewProject {
          ...Project
        }
      }
    }
  }
  ${Project}
`;

const SEARCH_POOL_JOB_RECOMMENDATION_PROJECT = gql`
  query getSearchPoolJobRecommendationProject($searchPoolId: ID!, $jobId: ID!) {
    searchPool(id: $searchPoolId) {
      id
      job(id: $jobId) {
        id
        recommendationsProject {
          ...Project
        }
      }
    }
  }
  ${Project}
`;

const SEARCH_POOL_JOB_IMPORTED_PROFILES_PROJECT = gql`
  query getSearchPoolJobImportedProfilesProject(
    $searchPoolId: ID!
    $jobId: ID!
  ) {
    searchPool(id: $searchPoolId) {
      id
      job(id: $jobId) {
        id
        importedProfilesProject {
          ...Project
        }
      }
    }
  }
  ${Project}
`;

const SEARCH_POOL_JOB_PROJECTS = gql`
  query getSearchPoolJobProjects($searchPoolId: ID!, $jobId: ID!) {
    searchPool(id: $searchPoolId) {
      id
      job(id: $jobId) {
        id
        projects {
          ...Project
        }
      }
    }
  }
  ${Project}
`;

const useJobProject = (
  searchPoolId: string,
  jobId: string,
  type?: ProjectType,
) => {
  const {
    data: applicationReviewData,
    ...restFromApplicationReview
  } = useQuery(SEARCH_POOL_JOB_APPLICATION_REVIEW_PROJECT, {
    variables: { searchPoolId, jobId },
    skip: type !== 'applications',
    fetchPolicy: 'network-only',
  });

  const { data: recommendationData, ...restFromRecommendations } = useQuery(
    SEARCH_POOL_JOB_RECOMMENDATION_PROJECT,
    {
      variables: { searchPoolId, jobId },
      skip: type !== 'recommendations',
      fetchPolicy: 'network-only',
    },
  );

  const { data: importedProfilesData, ...restFromImportedProfiles } = useQuery(
    SEARCH_POOL_JOB_IMPORTED_PROFILES_PROJECT,
    {
      variables: { searchPoolId, jobId },
      skip: type !== 'imported-profiles',
      fetchPolicy: 'network-only',
    },
  );

  const { data: projectsData, ...restFromProjects } = useQuery(
    SEARCH_POOL_JOB_PROJECTS,
    {
      variables: { searchPoolId, jobId },
      skip: !!type,
      fetchPolicy: 'network-only',
    },
  );

  if (type === 'applications') {
    return {
      data: applicationReviewData?.searchPool?.job?.applicationReviewProject,
      ...restFromApplicationReview,
    };
  }

  if (type === 'recommendations') {
    return {
      data: recommendationData?.searchPool?.job?.recommendationsProject,
      ...restFromRecommendations,
    };
  }

  if (type === 'imported-profiles') {
    return {
      data: importedProfilesData?.searchPool?.job?.importedProfilesProject,
      ...restFromImportedProfiles,
    };
  }

  return {
    data: projectsData?.searchPool?.job?.projects,
    ...restFromProjects,
  };
};

export default useJobProject;
