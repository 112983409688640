import gql from 'graphql-tag';
import { CustomFieldDefinition } from '../hooks/clients/useClientProfileCustomFields';
import CustomFieldsValues from './CustomFieldsValues';

export default gql`
  fragment SearchPoolJobOption on SearchPoolJob {
    id
    hide
    isArchived
    creationDate
    lastUseByAuthor {
      authorEmail
      timestamp
    }
    metadata {
      key
      value
    }
    owner {
      email
      firstname
      lastname
    }
    data {
      title
      status
    }
    customFields {
      ...CustomFieldsValues
    }
    foldering {
      department {
        id
      }
      section {
        id
      }
      subsection {
        id
      }
    }
  }
  ${CustomFieldsValues}
`;

export interface ISearchPoolJobOption {
  id: string;
  hide?: boolean;
  isArchived?: boolean;
  creationDate: string;
  customFields?: CustomFieldDefinition[];
  owner: {
    email: string;
    firstname: string;
    lastname: string;
  };
  lastUseByAuthor: {
    authorEmail: string;
    timestamp: number;
  }[];
  data?: {
    title: string;
    status: string;
  };
}
