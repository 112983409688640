import React, { useRef, CSSProperties, FC } from 'react';
import classNames from 'classnames';

import styles from './BlurLine.module.less';

export const BlurLine: FC<{
  inverted?: boolean;
  index?: number;
  style?: CSSProperties;
}> = ({ inverted = false, index = 0, style = {}, ...rest }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      {...rest}
      ref={ref}
      className={classNames(styles.blurLine, {
        [styles.inverted]: inverted,
      })}
      style={{ ...style, '--index-percent': `${10 * index}%` } as CSSProperties}
    />
  );
};

export const BlurParagraph: FC<{ lines?: number }> = ({ lines = 2 }) => {
  const renderedLines = [];
  for (let i = 0; i < lines; i += 1) {
    renderedLines.push(<BlurLine inverted={i % 2 !== 0} index={i} key={i} />);
  }

  return <div className={styles.blurParagraph}>{renderedLines}</div>;
};
