import { literalTypeChecker } from '@/common/utils/types';

export const campaignEvents = [
  'nbEnrolled',
  'nbContacted',
  'nbOpened',
  'nbClicked',
  'nbUnenrolled',
  'nbUnsubscribe',
] as const;

export type CampaignEventType = typeof campaignEvents[number];

export interface CampaignDailyCohort {
  day: string;
  details: {
    campaignId: string;
    type: CampaignEventType;
    count: number;
  }[];
}

export interface CampaignDailyCohortDetails {
  day: string;
  details: {
    campaignId: string;
    type: CampaignEventType;
    profileItems: { profileId: string }[];
  }[];
}

export const statsCampaignEvents = [
  'nbEnrolled',
  'nbContacted',
  'nbOpened',
  'nbClicked',
  'nbUnsubscribe',
] as const;

export type StatsCampaignEventType = typeof statsCampaignEvents[number];

export const isStatsCampaignEventType = literalTypeChecker(statsCampaignEvents);

export const convertibleCampaignEvents = [
  'nbEnrolled',
  'nbContacted',
  'nbOpened',
] as const;

export type ConvertibleCampaignEventType = typeof convertibleCampaignEvents[number];

export const isConvertibleCampaignEventType = literalTypeChecker(
  convertibleCampaignEvents,
);
