import React from 'react';
import logAction from '../common/logAction';

export default (WrappedComponent) => {
  return class withActionLogger extends React.PureComponent {
    render() {
      return <WrappedComponent onLogAction={logAction} {...this.props} />;
    }
  };
};
