import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import { ICON_FROM_SERVICE } from '@/containers/Profile/Contact/TimeLineItems/UnipileMessageItem/UnipileMessageItem';
import { replacePhotoLink } from '@/containers/Profile/Resume/Card/RevealProfileHeader/RevealProfileHeader';
import { getRandomDefaultAvatarLink, getSecureLink } from '@/common';
import { ProfileWithLastMessageInfo } from '@/graphql/hooks/searchPoolProfile/useProfilesWithLastMessageInfo';
import { getFullname } from '@/common/helpers/person';

import styles from './InboxView.module.less';

const timeDifference = (current: number, previous: number) => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;
  const elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return {
      value: Math.round(elapsed / 1000),
      unit: 'second',
    };
  }
  if (elapsed < msPerHour) {
    return {
      value: Math.round(elapsed / msPerMinute),
      unit: 'minute',
    };
  }
  if (elapsed < msPerDay) {
    return {
      value: Math.round(elapsed / msPerHour),
      unit: 'hour',
    };
  }
  if (elapsed < msPerMonth) {
    return {
      value: Math.round(elapsed / msPerDay),
      unit: 'day',
    };
  }
  if (elapsed < msPerYear) {
    return {
      value: Math.round(elapsed / msPerMonth),
      unit: 'month',
    };
  }
  return {
    value: Math.round(elapsed / msPerYear),
    unit: 'year',
  };
};

interface ThreadItemProps {
  profile: ProfileWithLastMessageInfo;
}

const ThreadItem: React.FC<ThreadItemProps> = ({ profile }) => {
  const { t } = useTranslation();

  const dateDiff = timeDifference(
    Date.now(),
    profile.lastMessageInfo?.timestamp || Date.now(),
  );

  const date = useMemo(
    () => new Date(profile.lastMessageInfo?.timestamp || Date.now()),
    [profile.lastMessageInfo?.timestamp],
  );

  const [avatarImageUrl, setAvatarImageUrl] = useState(
    profile.resumeData?.photoLink
      ? replacePhotoLink(profile.resumeData.photoLink)
      : getRandomDefaultAvatarLink(
          `${profile.resumeData?.firstname}${profile.resumeData?.lastname}`,
        ),
  );

  const dateText = useMemo(() => {
    if (_.includes(['second', 'minute', 'hour', 'day'], dateDiff.unit)) {
      return `${dateDiff.value} ${t(
        `contactFlow.triggers.delays.${dateDiff.unit}`,
        {
          count: dateDiff.value,
        },
      )}`;
    }
    return t('common.shortDate', { date });
  }, [date, dateDiff, t]);

  const icon = useMemo(() => {
    const type = profile.lastMessageInfo?.type;
    if (type && type in ICON_FROM_SERVICE) {
      return ICON_FROM_SERVICE[type as keyof typeof ICON_FROM_SERVICE];
    }
    return ICON_FROM_SERVICE.default;
  }, [profile.lastMessageInfo?.type]);

  return (
    <>
      <div className={styles.profileInfo}>
        <img
          data-openreplay-masked
          alt={getFullname(profile?.resumeData)}
          className={styles.avatar}
          src={getSecureLink(avatarImageUrl || '')}
          onError={() =>
            setAvatarImageUrl(
              getRandomDefaultAvatarLink(
                `${profile?.resumeData?.firstname}${profile?.resumeData?.lastname}`,
              ),
            )
          }
        />
        <div className={styles.profileHeader}>
          <div className={styles.nameAndTimestamp}>
            <h4
              className={styles.profileName}
            >{`${profile.resumeData?.firstname} ${profile.resumeData?.lastname}`}</h4>
            <span className={styles.lastMessageDate}>{dateText}</span>
          </div>
          <span className={styles.headline}>
            {profile.resumeData?.headline?.content?.text}
          </span>
        </div>
      </div>
      <div className={styles.lastMessagePreviewContainer}>
        <div
          style={{ backgroundColor: icon.background }}
          className={styles.messageIcon}
        >
          <i style={{ color: icon.color }} className={`${icon.icon}`} />
        </div>
      </div>
    </>
  );
};

export default ThreadItem;
