import React from 'react';
import { 
  HiresweetLibProvider, 
  ProfileContainer, 
  ProfileFirstname,
  ProfileCurrentSequenceWithActions,
} from '@hiresweet/hiresweet-lib';

import useClientId from '@/hooks/router/useClientId';

const PlaygroundB6 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30, background: 'white' }}>
      <h1>B6 - Cur sequence with actions</h1>
      <HiresweetLibProvider
        theme={theme}
        clientId={clientId}
      >
        <ProfileContainer profileId='julien-deoux-1BI1TI' >

          <h4>Display</h4>
          <ProfileFirstname /><br/>
          <br/>
          <br/>
          <br/>
          <ProfileCurrentSequenceWithActions />
        </ProfileContainer>
    
      </HiresweetLibProvider>
    </div>
  );
};

export default PlaygroundB6;
