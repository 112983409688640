/* eslint-disable class-methods-use-this */
import _ from 'underscore';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import {
  getMergeTagsKLastCompaniesValue,
  KLastCompaniesVariable,
} from '@/common/mergeTags/utils';

export default class ExpressionKLastCompaniesEvaluator
  implements SweetEvaluatorTypes.EvaluatorInterface {
  evaluate({
    context,
    expression,
  }: {
    context: SweetEvaluatorTypes.Context;
    expression: SweetEvaluatorTypes.Variable;
  }): { value: string | undefined } {
    const variable = (expression as unknown) as SweetEvaluatorTypes.BaseVariable &
      KLastCompaniesVariable;

    const anyContext = context as any;
    const profileExperiences =
      anyContext.experiences || anyContext.data?.experiences || null;

    if (_.isEmpty(profileExperiences)) {
      return { value: undefined };
    }

    const { number, locale } = variable;

    return {
      value:
        getMergeTagsKLastCompaniesValue({
          locale,
          nbLastCompanies: number,
          experiences: profileExperiences,
        })?.calculatedValue || undefined,
    };
  }
}
