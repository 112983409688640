import React from 'react';
import _, { compose } from 'underscore';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import ENRICHED_SEARCH_POOL_PROFILES from '../../../../graphql/enrichedSearchPoolProfiles';

import SimilarProfileCard from './SimilarProfileCard';

import withRevealSearchPoolResults from '../../../../hocs/searchPool/withRevealSearchPoolResults';

import './ProfileSimilarProfiles.css';
import EmptyState from '../EmptyState/EmptyState';

export const MAX_SIMILAR_PROFILES = 6;

// NOTE: has index penalty to distinguish profiles
const getScore = ({ searchPoolProfileId, results, index }) => {
  const scores = _.findWhere(results, { searchPoolProfileId })?.scores || [];
  return 0.92 * Math.max((scores[0] || 0) - 0.01 * index, 0);
};

const ProfileSimilarProfiles = ({
  searches,
  searchPoolId,
  profileId,
  loadingSearchResultsCategories,
  searchResultsCategories,
}) => {
  const { t } = useTranslation();
  const { profile } = useCandidateViewContext();

  const category = _.findWhere(searchResultsCategories, { categoryId: 'all' });
  const { results } = category || {};

  const profileIds = _.pluck(results, 'searchPoolProfileId');
  const { loading: loadingProfiles, data } = useQuery(
    ENRICHED_SEARCH_POOL_PROFILES,
    {
      variables: { searchPoolId, ids: profileIds, criteria: searches },
      skip: _.isEmpty(profileIds),
    },
  );
  const enrichedProfiles = _.reject(
    data?.searchPool?.enrichedProfiles,
    (enrichedProfile) => enrichedProfile?.id === profileId,
  );

  if (loadingSearchResultsCategories || loadingProfiles) {
    return (
      <div className='profile-similar-profiles'>
        <Loader active />
      </div>
    );
  }

  const displayedProfiles = enrichedProfiles.slice(0, MAX_SIMILAR_PROFILES);

  if (_.isEmpty(displayedProfiles)) {
    return (
      <EmptyState
        title={t('reveal.profileModal.emptyStates.similarProfiles.title', {
          firstname: profile.resumeData?.firstname,
        })}
        innerContent={t(
          'reveal.profileModal.emptyStates.similarProfiles.innerContent',
          {
            firstname: profile.resumeData?.firstname,
          },
        )}
        illustrationPath='/images/placeholders/contactsEmptyState.svg'
      />
    );
  }

  return (
    <div className='profile-similar-profiles'>
      {_.map(displayedProfiles, (matchingProfile, index) => (
        <SimilarProfileCard
          key={matchingProfile?.id}
          profile={matchingProfile}
          score={getScore({
            searchPoolProfileId: matchingProfile?.id,
            results,
            index,
          })}
        />
      ))}
    </div>
  );
};

export default compose(withRevealSearchPoolResults)(ProfileSimilarProfiles);
