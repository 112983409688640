import gql from 'graphql-tag';
import {
  FetchResult,
  MutationResult,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';

const unlockProfilesByAcceptingToS = gql`
  mutation unlockProfilesByAcceptingToS {
    unlockProfilesByAcceptingToS {
      id
      hasLimitedAccessToProfiles
    }
  }
`;

type UnlockProfilesByAcceptingToSVariables = Record<string, never>;

type UnlockProfilesByAcceptingToSData = {
  unlockProfilesByAcceptingToS: {
    id: string;
    hasLimitedAccessToProfiles: boolean;
  };
};

type UseUnlockProfilesByAcceptingToSInput = {
  mutationOptions?: MutationHookOptions<
    UnlockProfilesByAcceptingToSData,
    UnlockProfilesByAcceptingToSVariables
  >;
};

type UseUnlockProfilesByAcceptingToSResult = [
  () => Promise<FetchResult<UnlockProfilesByAcceptingToSData>>,
  MutationResult<UnlockProfilesByAcceptingToSData>,
];

function useUnlockProfilesByAcceptingToS({
  mutationOptions = {},
}: UseUnlockProfilesByAcceptingToSInput = {}): UseUnlockProfilesByAcceptingToSResult {
  const [mutation, result] = useMutation<
    UnlockProfilesByAcceptingToSData,
    UnlockProfilesByAcceptingToSVariables
  >(unlockProfilesByAcceptingToS, mutationOptions);

  return [() => mutation(), result];
}

export default useUnlockProfilesByAcceptingToS;
