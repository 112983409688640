import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from '@apollo/client';

import { MetaTaskFormatForm } from '@/revealComponents/ExplicitTasks/MetaTaskForm/types';
import { Author } from '@/types/author';
import ContactFlowActionFormatFragment from '@/graphql/fragments/ContactFlowActionFormat';
import TranslatableTextFragment from '@/graphql/fragments/TranslatableText';
import { TranslatableText } from '@/types/text';

const getMissionSharedActivities = gql`
  query getMissionSharedActivities($searchPoolId: ID!, $missionId: ID!) {
    searchPool(id: $searchPoolId) {
      id
      job(id: $missionId) {
        id
        sharedActivities {
          id
          author {
            firstname
            lastname
            email
            photoLink
          }
          content {
            date
            type
            ... on LoggedActivityContent {
              subtype
              customActivityId
              customActivityTitle {
                ...TranslatableText
              }
              comment {
                text
              }
              format {
                ...ContactFlowActionFormatFragment
              }
            }
          }
          targets {
            type
            targetId
            isMain
            name
          }
        }
      }
    }
  }
  ${TranslatableTextFragment}
  ${ContactFlowActionFormatFragment}
`;

type LoggedActivityContent = {
  type: 'logged-activity';
  date: string;
  subtype: string;
  comment: { text: string } | null;
  customActivityId: string | null;
  customActivityTitle: TranslatableText | null;
  format: {
    form: MetaTaskFormatForm | null;
  } | null;
};

type SharedActivityContent = LoggedActivityContent;

type SharedActivity = {
  id: string;
  author: Author;
  content: SharedActivityContent;
  targets: {
    type: 'mission' | 'profile' | 'company';
    targetId: string;
    isMain: boolean | null;
    name: string;
  }[];
};

type Data = {
  searchPool: {
    id: string;
    job: {
      id: string;
      sharedActivities: SharedActivity[];
    };
  };
};

type Variables = {
  searchPoolId: string;
  missionId: string;
};

type Input = {
  missionId: string;
  searchPoolId?: string;
  queryOptions?: QueryHookOptions<Data, Variables>;
};

function useMissionSharedActivities({
  missionId,
  searchPoolId = 'reveal',
  queryOptions = {},
}: Input) {
  const query = useQuery<Data, Variables>(getMissionSharedActivities, {
    ...queryOptions,
    variables: {
      searchPoolId,
      missionId,
    },
  });
  const sharedActivities = query.data?.searchPool?.job?.sharedActivities || [];
  return { ...query, sharedActivities };
}

export default useMissionSharedActivities;
