import React from 'react';
import _ from 'underscore';
import * as Sentry from '@sentry/browser';
import { validateSequence } from '@/containers/Parameters/Sequences/validateContactFlow';
import {
  canHaveHtmlMessage,
  isEmailAction,
} from '@/common/constants/taskTypes';
import sanitizeTimeLineItem from '../../../../common/sanitizeTimeLineItem';
import { getHtmlStringAsText } from '../../SearchView/utils';

export const getTranslatedDateOrDuration = ({
  configurationParameters,
  plannedExecutionDate,
  trigger,
  type,
  t,
}) => {
  try {
    if (trigger?.type === 'manual-trigger') {
      return t('contactFlow.triggers.createdImmediately');
    }

    if (plannedExecutionDate) {
      const plannedOn = t('profile.contact.drafts.plannedOn');

      const dateStr =
        configurationParameters?.shouldHideFutureTaskHour === 'true'
          ? t('common.formattedDateWithoutHours', {
              date: new Date(plannedExecutionDate),
            })
          : t('common.formattedDate', {
              date: new Date(plannedExecutionDate),
            });

      return `${plannedOn}${dateStr}`;
    }

    if (_.isEmpty(trigger)) {
      console.error('No plannedExecutionDate nor trigger provided');
      return null;
    }

    let translatedDelay;
    if (trigger.type === 'delay-after-action') {
      const { delay } = trigger;
      const { value, unit } = delay;
      const specification =
        type === 'send-email-action'
          ? '.automatic'
          : type === 'send-email-manual'
            ? '.manual'
            : '';
      if (unit === 'hour') {
        translatedDelay = t(
          `contactFlow.triggers.delays${specification}.nbHoursLater`,
          {
            count: value,
          },
        );
      } else if (unit === 'day') {
        translatedDelay = t(
          `contactFlow.triggers.delays${specification}.nbDaysLater`,
          { count: value },
        );
      } else if (unit === 'week') {
        translatedDelay = t(
          `contactFlow.triggers.delays${specification}.nbWeeksLater`,
          { count: value },
        );
      } else if (unit === 'working-day') {
        translatedDelay = t(
          `contactFlow.triggers.delays${specification}.nbWorkingDaysLater`,
          { count: value },
        );
      } else if (unit === 'ms') {
        translatedDelay = delayInMsToReadableString(value, t);
      } else {
        throw new Error('Unsupported Delay Unit');
      }
    }

    if (trigger.type === 'after-date') {
      return configurationParameters?.shouldHideFutureTaskHour === 'true'
        ? t('contactFlow.triggers.afterDateWithoutHours', {
            date: new Date(trigger?.date),
          })
        : t('contactFlow.triggers.afterDate', {
            date: new Date(trigger?.date),
          });
    }

    return translatedDelay;
  } catch (e) {
    Sentry.captureException(e);
    return '';
  }
};

const delayInMsToReadableString = (ms, t) => {
  if (ms === 0) {
    return t('contactFlow.triggers.delays.immediatelyAfter');
  }
  if (!ms) {
    throw Error('No value provided to delayInMsToReadableString');
  }
  const numberOfDays = Math.floor(ms / (24 * 3600 * 1000));
  const numberOfHours = Math.floor(
    (ms - numberOfDays * 24 * 3600 * 1000) / (3600 * 1000),
  );
  const numberOfMinutes = Math.floor(
    (ms - numberOfDays * 24 * 3600 * 1000 - numberOfHours * 3600 * 1000) /
      (60 * 1000),
  );
  const result = [];
  if (numberOfDays >= 1) {
    result.push(
      ` ${numberOfDays} ${t('contactFlow.triggers.delays.day', {
        count: numberOfDays,
      })}`,
    );
  }
  if (numberOfHours >= 1) {
    result.push(
      ` ${numberOfHours} ${t('contactFlow.triggers.delays.hour', {
        count: numberOfHours,
      })}`,
    );
  }
  if (numberOfMinutes >= 1) {
    result.push(
      ` ${numberOfMinutes} ${t('contactFlow.triggers.delays.minute', {
        count: numberOfMinutes,
      })}`,
    );
  }
  if (result.length === 0) {
    return t('contactFlow.triggers.delays.immediatelyAfter');
  }
  if (result.length > 1) {
    return `${t('contactFlow.triggers.delays.after')} ${_.first(
      result,
      result.length - 1,
    ).join(',')} and${_.last(result)}`;
  }
  return `${t('contactFlow.triggers.delays.after')} ${result[0]}`;
};

export const MessageBody = React.memo(({ content }) => {
  return (
    <div
      className='body-content'
      dangerouslySetInnerHTML={{ __html: sanitizeTimeLineItem(content) }}
    />
  );
});

export const isTaskForAction = ({ task, action }) =>
  typeof task?.id === 'string' &&
  typeof action?.actionId === 'string' &&
  task?.id.endsWith(action?.actionId);

export const canDeleteAction = ({ actions, actionId }) => {
  if (!isEmailAction(actions?.[0])) {
    if (actionId === actions?.[0]?.actionId) {
      if (!actions?.[1]?.shouldPerformAutomatically) {
        return true;
      }
    }
    if (actionId !== actions?.[0]?.actionId) {
      return true;
    }
  }
  const sequenceWithoutAction = { actions: _.reject(actions, { actionId }) };
  return _.isEmpty(_.flatten(validateSequence(sequenceWithoutAction, true)));
};

export const formatTaskOrActionMessage = (
  message,
  taskOrActionType,
  senderId,
) => {
  let formatedMessage = message;
  if (!_.isEmpty(message) && !canHaveHtmlMessage({ type: taskOrActionType })) {
    // NOTE: (both cases are usually mutually exclusive ?)
    // - if body is an HTML string, we add extra line breaks after divs
    // let inputBody = addLinebreakAfterDivs(message?.body);
    // console.log('formatTaskOrActionMessage 2', inputBody);
    // - if body contains newline characters ('\n'), we convert them to line breaks so they don't get lost
    const inputBody = convertNewlinesToLinebreaks(message?.body);
    const inputBodyWithLinks = (inputBody || '').replace(
      /<a[^<]*href="([^<]*)">([^<]*)<\/a>/g,
      '$2 ($1)',
    );

    formatedMessage = {
      ...message,
      subject: getHtmlStringAsText(message?.subject),
      body: getHtmlStringAsText(inputBodyWithLinks || ''),
    };
  }

  if (senderId) {
    formatedMessage = {
      ...formatedMessage,
      sender: {
        senderId,
      },
      senderId,
    };
  }

  return formatedMessage;
};

export const addLinebreakAfterDivs = (str) => {
  if (!str) {
    return str;
  }
  return str.replaceAll('</div>', '</div></br>');
};

export const convertNewlinesToLinebreaks = (str) => {
  if (!str) {
    return str;
  }
  return str.replace(/(?:\r\n|\r|\n)/g, '<br/>');
};
