import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Sender from '../../graphql/fragments/Sender';

export const mutation = gql`
  mutation updateSender($senderInput: UpdateSenderInput!) {
    updateSender(senderInput: $senderInput) {
      ...Sender
    }
  }
  ${Sender}
`;

export default graphql(mutation, {
  props: ({ mutate }) => {
    return {
      updateSender: ({ senderInput }) =>
        mutate({
          variables: { senderInput },
        }),
    };
  },
});
