import React from 'react';
import SettingsLayout from '@/containers/SettingsLayout';
import DisplayPreferences from '../DisplayPreferences';

interface MarketplacePrivacyProps {
  clientId: string;
}

const MarketplacePrivacy: React.FC<MarketplacePrivacyProps> = ({
  clientId,
}) => {
  return (
    <div className='settings'>
      <SettingsLayout
        currentPage='privacy'
        clientId={clientId}
        className='settings-container'
      >
        <DisplayPreferences />
      </SettingsLayout>
    </div>
  );
};

export default MarketplacePrivacy;
