import _ from 'underscore';

import FuzzySearch from './FuzzySearch';

class ProfileFuzzySearch {
  constructor(profiles, mode) {
    this.profiles = profiles;

    const keyMapper = (p) => p._id || p.id;

    this.profileFromKey = {};
    _.each(profiles, (p) => {
      this.profileFromKey[keyMapper(p)] = p;
    });

    const items = _.map(profiles, (profile) => ({
      ...profile,
      ...(profile && profile.resumeData),
      ...(((profile || {}).resumeData || {}).lastname || '').indexOf(' ') >= 0 && {
        secondLastname: profile.resumeData.lastname.split(' ')[1] || '_____'
      },
      ...(profile?.resumeData?.headline?.content?.text && {
        headlineText: profile?.resumeData?.headline?.content?.text,
      }),
    }));

    this.searchEngine = new FuzzySearch({
      items,
      keyMapper,
      fieldsWithWeights: [
        { field: 'firstname', weight: 1 },
        { field: 'lastname', weight: 0.95 },
        { field: 'email', weight: 0.95 },
        { field: 'secondLastname', weight: 0.95 },
        ...((mode !== 'simple') ? [{ field: 'headlineText', weight: 0.9 }] : []),
      ],
    });
  }

  search(str) {
    if (str.trim() === 0) {
      return this.profiles;
    }

    const keysAndScores = this.searchEngine.getResults(str);
    const minErr = _.isEmpty(keysAndScores) ? 1 : 1 - keysAndScores[0].score;
    const maxNbResults = 7;
    return _.compact(
      _.map(keysAndScores, ({ key, score }) => {
        const err = 1 - score;
        return err < Math.max(2 * minErr, 0.15) && err < 0.3
          ? this.profileFromKey[key]
          : null;
      }),
    ).slice(0, maxNbResults);
  }
}

export default ProfileFuzzySearch;
