import React from 'react';
import _ from 'underscore';
import { Popup, Dropdown } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { getTranslatedDateOrDuration } from '../../containers/Profile/Contact/ProfileContactFlow/helpers';
import { TASK_TYPES } from '../../common/constants/taskTypes';

import './ActionTriggerEditor.css';

const SUPPORTED_DELAY_UNITS = ['day', 'working-day', 'week'];

class ActionTriggerEditor extends React.Component {
  handleChangeFromManualToAutomatic() {
    const { action, onUpdate } = this.props;
    onUpdate({
      action: {
        ...action,
        trigger: {
          type: 'delay-after-action',
          delay: {
            value: 7,
            unit: 'day',
          },
        },
      },
    });
  }

  handleChangeFromAutomaticToManual() {
    const { action, onUpdate } = this.props;
    onUpdate({
      action: {
        ...action,
        trigger: {
          type: 'manual-trigger',
        },
      },
    });
  }

  handleChangeDelayValue({ value }) {
    const { action, onUpdate } = this.props;

    onUpdate({
      action: {
        ...action,
        trigger: {
          type: 'delay-after-action',
          delay: {
            unit: action?.trigger?.delay?.unit || 'day',
            value,
          },
        },
      },
    });
  }

  handleChangeDelayUnit({ unit }) {
    const { action, onUpdate } = this.props;

    onUpdate({
      action: {
        ...action,
        trigger: {
          type: 'delay-after-action',
          delay: {
            unit,
            value: action?.trigger?.delay?.value || 7,
          },
        },
      },
    });
  }

  handleChangeDate({ date }) {
    const { action, onUpdate } = this.props;

    onUpdate({
      action: {
        ...action,
        trigger: {
          type: 'after-date',
          date,
        },
      },
    });
  }

  renderManualVsAutomaticDropdown() {
    const { action, t, disabled } = this.props;
    const { trigger } = action;
    const value = trigger.type === 'manual-trigger' ? 'manual' : 'automatic';

    const handleChange = (event, { value: newValue }) => {
      if (newValue === value) {
        return;
      }
      if (newValue === 'automatic') {
        this.handleChangeFromManualToAutomatic();
      } else {
        this.handleChangeFromAutomaticToManual();
      }
    };

    let options = [
      { value: 'manual', text: t('contactFlow.triggers.createdImmediately') },
      {
        value: 'automatic',
        text: `${t('contactFlow.triggers.createdAutomatically')} `,
      },
    ];

    if (action?.type === TASK_TYPES.SEND_EMAIL) {
      options = [
        { value: 'manual', text: t('contactFlow.triggers.createdImmediately') },
        {
          value: 'automatic',
          text: `${t('contactFlow.triggers.triggeredAutomatically')} `,
        },
      ];
    }
    return (
      <Dropdown
        className='trigger-type-label hs-inline-dropdown'
        value={value}
        onChange={handleChange}
        options={options}
        disabled={disabled}
      />
    );
  }

  renderManualVsAutomaticSelector() {
    const { action, permissionsAndNudges, t } = this.props;
    const { originalTrigger, trigger } = action;
    if ((originalTrigger?.type || trigger.type) === 'manual-trigger') {
      if (!permissionsAndNudges.nonManualTrigger.isAllowed) {
        return null;
      }
      return this.renderManualVsAutomaticDropdown();
    }
    return (
      <>
        {!permissionsAndNudges.manualTrigger.isAllowed ? (
          <Popup
            trigger={
              <span className='trigger-type-label'>
                {action?.type === TASK_TYPES.SEND_EMAIL
                  ? t('contactFlow.triggers.triggeredAutomatically')
                  : t('contactFlow.triggers.createdAutomatically')}
              </span>
            }
            content={permissionsAndNudges.manualTrigger.reason}
          />
        ) : (
          this.renderManualVsAutomaticDropdown()
        )}
      </>
    );
  }

  renderAutomaticSelector() {
    const { action, t, disabled } = this.props;
    const { trigger } = action;
    if (trigger.type === 'manual-trigger') {
      return null;
    }

    if (trigger.type === 'after-date') {
      return (
        <div className='automatic-selector'>
          <DatePicker
            selected={new Date(trigger.date)}
            minDate={new Date(trigger.date)}
            onChange={(date) => this.handleChangeDate({ date })}
            className='datepicker'
            dateFormat='MMMM d, yyyy'
          />
        </div>
      );
    }
    const delayValue = trigger?.delay?.value;
    const delayUnit = trigger?.delay?.unit;

    const delayValueOptionsEmptyArray = Array(30);
    const delayValueOptions = _.map(
      delayValueOptionsEmptyArray,
      (option, index) => {
        return { value: index + 1, text: `${index + 1}` };
      },
    );

    const delayUnitOptions = [
      {
        value: 'working-day',
        text: t('contactFlow.triggers.units.workingDay', { count: delayValue }),
      },
      {
        value: 'day',
        text: t('contactFlow.triggers.units.day', { count: delayValue }),
      },
      {
        value: 'week',
        text: t('contactFlow.triggers.units.week', { count: delayValue }),
      },
    ];

    const handleChangeDelayValue = (event, { value }) => {
      this.handleChangeDelayValue({
        value,
      });
    };

    const handleChangeDelayUnit = (event, { value }) => {
      this.handleChangeDelayUnit({
        unit: value,
      });
    };

    if (
      !delayValue ||
      !delayUnit ||
      !_.contains(SUPPORTED_DELAY_UNITS, delayUnit)
    ) {
      return (
        <div className='automatic-selector'>
          {getTranslatedDateOrDuration({ trigger, t })}
        </div>
      );
    }

    return (
      <span className='automatic-selector'>
        <Dropdown
          className='automatic-selector-number'
          value={delayValue}
          onChange={handleChangeDelayValue}
          options={delayValueOptions}
          selection
          scrolling
          disabled={disabled}
        />
        <Dropdown
          value={delayUnit}
          onChange={handleChangeDelayUnit}
          options={delayUnitOptions}
          selection
          disabled={disabled}
        />
      </span>
    );
  }

  render() {
    const { extraDescription } = this.props;
    return (
      <div className='action-trigger-editor'>
        {this.renderManualVsAutomaticSelector()}
        {this.renderAutomaticSelector()}
        {extraDescription}
      </div>
    );
  }
}

export default withTranslation('translations')(ActionTriggerEditor);
