import React from 'react';
import _ from 'underscore';

import OfferMiniCard from './OfferMiniCard';

const OtherOffersToSee = ({ onLogAction, t, targetOffers, clientId, user }) => {
  return (
    <center>
      <h3>{t('smartRightPane.otherOffersToSee')}</h3>
      <div className='offer-mini-cards'>
        {_.map(targetOffers.slice(0, 10), ({ id, title, nbPending }) => (
          <OfferMiniCard
            key={id}
            t={t}
            onLogAction={onLogAction}
            user={user}
            clientId={clientId}
            offerId={id}
            title={title}
            nbPending={nbPending}
          />
        ))}
      </div>
    </center>
  );
};

export default OtherOffersToSee;
