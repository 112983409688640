import {
  PositiveColor,
  AppleGreen,
  NegativeColor,
  DataVizColors,
} from '@/less/colors';
import { StackItem } from '@/types/statistics/stackItem';

export const dailyCountReportStacks: Record<string, StackItem[]> = {
  'adequat-status-H6YRKS': [
    {
      color: PositiveColor,
      i18nKey: 'reveal.reports.adventure.availability.posted',
      datakey: 'posted',
    },
    {
      color: AppleGreen,
      i18nKey: 'reveal.reports.adventure.availability.not-posted-available',
      datakey: 'not-posted-available',
    },
    {
      color: NegativeColor,
      i18nKey: 'reveal.reports.adventure.availability.not-posted-not-available',
      datakey: 'not-posted-not-available',
    },
  ],
  'adequat-anteriority-R2ALUB': [
    {
      color: DataVizColors[3],
      i18nKey: 'reveal.reports.adventure.anteriority.0_1_months',
      datakey: '0_1_months',
    },
    {
      color: DataVizColors[4],
      i18nKey: 'reveal.reports.adventure.anteriority.1_3_months',
      datakey: '1_3_months',
    },
    {
      color: DataVizColors[5],
      i18nKey: 'reveal.reports.adventure.anteriority.3_6_months',
      datakey: '3_6_months',
    },
    {
      color: DataVizColors[2],
      i18nKey: 'reveal.reports.adventure.anteriority.6_inf_months',
      datakey: '6_inf_months',
    },
  ],
  'adequat-tasks-procesing-L3EKSV': [
    {
      color: DataVizColors[3],
      i18nKey: 'reveal.reports.adventure.taskProcessing.task_left',
      datakey: 'task_left',
    },
    {
      color: DataVizColors[4],
      i18nKey: 'reveal.reports.adventure.taskProcessing.task_done',
      datakey: 'task_done',
    },
    {
      color: DataVizColors[5],
      i18nKey: 'reveal.reports.adventure.taskProcessing.task_shifted',
      datakey: 'task_shifted',
    },
  ],
};
