import React from 'react';
import _ from 'underscore';

import Website from '@/components/Common/Icons/Website';

import SOURCES from '../../../common/constants/sources';

import './ProfileSources.css';

const ProfileSources = ({ sources, onClickSource }) => {
  return (
    <div className='profile-sources'>
      {_.map(SOURCES, (sourceId) => {
        if (!sources || !sources[sourceId]) {
          return null;
        }
        return (
          <ProfileSource
            key={sourceId}
            source={{ sourceId, url: sources[sourceId] }}
            onClick={(e) => onClickSource({ e, sourceId })}
          />
        );
      })}
    </div>
  );
};

const ProfileSource = ({ source, onClick }) => {
  const { sourceId, url } = source;
  return (
    <div className='source-link'>
      <a
        href={cleanLink(url)}
        target='_blank'
        rel='noreferrer noopener'
        onClick={onClick}
      >
        <SourceContent sourceId={sourceId} />
      </a>
    </div>
  );
};

const SourceContent = ({ sourceId }) => {
  if (
    sourceId === 'website' ||
    sourceId === 'githubIo' ||
    sourceId === 'aboutMe'
  ) {
    return <Website className='source-link-inner' />;
  }
  return (
    <img
      className={`source-link-inner ${sourceId}`}
      src={`/images/contact_logos/logolink_${sourceId}.svg`}
      alt={sourceId}
    />
  );
};

const cleanLink = (url) =>
  url.match(/^https?:\/\//i) ? url : `https://${url}`;

export default ProfileSources;
