import {
  SecondarySkyBlue,
  SecondaryRed,
  PositiveColor,
  PrimaryCobalt40,
  PrimaryCobalt60,
  SecondaryBlue,
} from '@/less/colors';

const TASK_ICONS: any = {
  'new-contact': {
    icon: 'ri-add-circle-line ri-2x',
    color: PositiveColor,
  },
  email: {
    icon: 'ri-mail-line',
    color: '#B26FD2',
  },
  'email-from-profile': {
    icon: 'ri-mail-line',
    color: PositiveColor,
  },
  'email-to-profile': {
    icon: 'ri-mail-line',
    color: '#B26FD2',
  },
  'send-email-manual': {
    icon: 'ri-mail-line',
    color: '#B26FD2',
  },
  'linkedin-invitation-to-profile': {
    icon: 'ri-linkedin-fill',
    color: '#4680D7',
  },
  'linkedin-message-to-profile': {
    icon: 'ri-linkedin-fill',
    color: '#4680D7',
  },
  'linkedin-check-request': {
    icon: 'ri-linkedin-fill',
    color: '#4680D7',
  },
  'linkedin-send-inmail': {
    icon: 'ri-linkedin-fill',
    color: '#4680D7',
  },
  'linkedin-message-from-profile': {
    icon: 'ri-linkedin-fill',
    color: '#4680D7',
  },
  'linkedin-invitation-accepted-from-profile': {
    icon: 'ri-linkedin-fill',
    color: '#4680D7',
  },
  'linkedin-send-message': {
    icon: 'ri-linkedin-fill',
    color: '#4680D7',
  },
  'linkedin-recruiter-inmail': {
    icon: 'ri-linkedin-fill',
    color: '#4680D7',
  },
  'linkedin-premium-inmail': {
    icon: 'ri-linkedin-fill',
    color: '#4680D7',
  },
  'linkedin-classic-message': {
    icon: 'ri-linkedin-fill',
    color: '#4680D7',
  },
  'linkedin-salesnavigator-inmail': {
    icon: 'ri-linkedin-fill',
    color: '#4680D7',
  },
  'linkedin-send-request': {
    icon: 'ri-linkedin-fill',
    color: '#4680D7',
  },
  'make-phone-call': {
    icon: 'ri-phone-fill',
    color: '#5A5270',
  },
  'call-task': {
    icon: 'ri-phone-fill',
    color: '#5A5270',
  },
  'call-to-profile': {
    icon: 'ri-phone-fill',
    color: '#5A5270',
  },
  'call-from-profile': {
    icon: 'ri-phone-fill',
    color: '#5A5270',
  },
  call: {
    icon: 'ri-phone-fill',
    color: '#5A5270',
  },
  "texting-message-to-profile": {
    icon: 'custom-icon-sms-task',
    color: '#E76E67',
  },
  "texting-message-from-profile": {
    icon: 'custom-icon-sms-task',
    color: '#E76E67',
  },
  "vocal-message-to-profile": {
    icon: 'ri-chat-voice-fill',
    color: '#5A5270',
  },
  "vocal-message-from-profile" : {
    icon: 'ri-chat-voice-fill',
    color: '#5A5270',
  },
  "inspection-tour": {
    icon: 'ri-group-fill',
    color: '#4680D7',
  },
  addedToMission: {
    icon: 'ri-briefcase-4-line',
    color: SecondarySkyBlue,
  },
  removedFromMission: {
    icon: 'ri-briefcase-4-line',
    color: SecondaryRed,
  },
  enrolledInCampaign: {
    color: SecondarySkyBlue,
  },
  unenrolledFromCampaign: {
    icon: 'ri-briefcase-4-line',
    color: SecondaryRed,
  },
  missionStageUpdated: {
    icon: 'ri-briefcase-4-line',
    color: SecondarySkyBlue,
  },
  acceptedFromMissionRecommendations: {
    color: SecondarySkyBlue,
  },
  'skipped-from-mission-recommendations': {
    color: SecondaryRed,
  },
  sourced: {
    icon: 'ri-add-circle-line',
    color: PositiveColor,
  },
  enrolledInSequence: {
    icon: 'ri-stack-line',
    color: SecondarySkyBlue,
  },
  unenrolledInSequence: {
    icon: 'ri-stack-line',
    color: SecondaryRed,
  },
  taskCompleted: {
    icon: 'ri-todo-line',
    color: SecondarySkyBlue,
  },
  taskSnoozed: {
    icon: 'ri-todo-line',
    color: SecondarySkyBlue,
  },
  comment: {
    icon: 'ri-sticky-note-line',
    color: '#FFD142',
  },
  log: {
    icon: 'ri-file-text-line',
    color: SecondarySkyBlue,
  },
  step: {
    icon: 'ri-todo-line',
    color: SecondarySkyBlue,
  },
  messageUnavailable: {
    icon: 'ri-question-mark',
    color: PrimaryCobalt40,
  },
  meeting: {
    icon: 'custom-icon-manual-task',
    color: 'rgb(101, 85, 198)',
  },
  genericATS: {
    icon: 'ri-price-tag-3-line',
    color: PrimaryCobalt60,
  },
  doNotContact: {
    icon: 'ri-chat-off-line',
    color: SecondaryRed,
  },
  manual: {
    icon: 'custom-icon-manual-task',
    color: 'rgb(101, 85, 198)',
  },
  'send-text-message': {
    icon: 'custom-icon-sms-task',
    color: '#E76E67',
  },
  'ats-move': {
    icon: 'ri-exchange-line',
    color: SecondarySkyBlue,
  },
  'ats-changed': {
    icon: 'ri-price-tag-3-line',
    color: SecondarySkyBlue,
  },
  evaluatedYes: {
    icon: 'ri-thumb-up-line',
    color: PositiveColor,
  },
  evaluatedNo: {
    icon: 'ri-thumb-down-line',
    color: SecondaryRed,
  },
  updated: {
    icon: 'ri-refresh-line',
    color: SecondaryBlue,
  },
  uploaded: {
    icon: 'ri-upload-line',
    color: 'rgb(101, 85, 198)',
  },
  addedFollower: {
    icon: 'ri-user-follow-line',
    color: PositiveColor,
  },
  calendarEvent: {
    icon: 'ri-calendar-check-line',
    color: SecondarySkyBlue,
  },
  applied: {
    icon: 'ri-upload-line',
    color: PositiveColor,
  },
  profileView: {
    icon: 'ri-eye-line',
    color: SecondaryBlue,
  },
  'whatsapp-send-message': {
    icon: 'ri-whatsapp-line',
    color: 'rgb(91, 180, 81)',
  },
};

export default TASK_ICONS;
