import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { InfoBlock, InfoBlockItem } from '@hiresweet/hiresweet-lib';

import { getTranslatedText } from '@/common/helpers/translatableText';
import { getShortLanguage } from '@/common/utils/i18n';
import { getItemId } from '@/common/helpers/profileOverviewCard';
import type { SearchPoolProfile } from '@/types/searchPoolProfile';

import useUserProfileViewDisplayPreferences from './useUserProfileViewDisplayPreferences';
import OverviewCardItemContent from './OverviewCardItemContent';

import styles from './OverviewCard.module.less';

type Props = {
  profile: SearchPoolProfile;
};

function OverviewCard({ profile }: Props) {
  const { i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const { profileViews } = useUserProfileViewDisplayPreferences();
  const profileViewPreferences = _.findWhere(profileViews, {
    profileViewId:
      profile.contactCategory?.type === 'company'
        ? 'default-company-view'
        : 'default-human-view',
  });
  if (!profileViewPreferences?.overviewCard) {
    return null;
  }
  const title = getTranslatedText(
    lang,
    profileViewPreferences.overviewCard.title,
  );
  return (
    <InfoBlock title={title} className={styles.card}>
      {profileViewPreferences.overviewCard.items.map((item) => (
        <InfoBlockItem
          key={getItemId(item.content)}
          label={getTranslatedText(lang, item.label)}
        >
          <OverviewCardItemContent profile={profile} content={item.content} />
        </InfoBlockItem>
      ))}
    </InfoBlock>
  );
}

export default OverviewCard;
