import { useMemo } from 'react';

const useBrowserType = () =>
  useMemo(() => {
    const { userAgent } = window.navigator;
    return identifyBrowser(userAgent);
  }, []);

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#browser_name
 * @param {string} userAgent the userAgent of the browser
 * @returns the name of the browser
 */
const identifyBrowser = (userAgent) => {
  if (userAgent.match(/Chromium\//)) {
    return 'chromium';
  }
  if (userAgent.match(/Chrome\//)) {
    return 'chrome';
  }
  if (userAgent.match(/Safari\//)) {
    return 'safari';
  }
  if (userAgent.match(/Seamonkey\//)) {
    return 'seamonkey';
  }
  if (userAgent.match(/Firefox\//)) {
    return 'firefox';
  }
  return null;
};

export default useBrowserType;
