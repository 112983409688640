import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'underscore';
import {
  getExperienceDetail,
  getMainEducation,
  getProfileBlurb,
} from '../../components/Profile/helpers';
import contextToProps from '../../hocs/contextToProps';
import withOffer from '../../hocs/offers/withOffer';
import routerParamsToProps from '../../hocs/routerParamsToProps';
import withUserFromJWToken from '../../hocs/users/withUserFromJWToken';
import { getOSFromPlatform } from '../Profile/Contact/ProfileContactFlow/Email/ConfirmationModal/helpers';

const OS_CODE = getOSFromPlatform();

const SaveProfileInfoToClipboard = ({
  clientId,
  children,
  profile,
  offer,
  user,
  onShowNotification,
  asIcon = false,
  t,
}) => {
  if (!user?.isAdmin) {
    if (children) {
      return <>{children}</>;
    }
    return null;
  }

  const saveProfileInfoToClipBoard = async (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    if (!profile || !offer) {
      onShowNotification({
        message: t('reveal.candidatesView.contactInfoCopiedError'),
        level: 'error',
      });
      console.error('Cmd+Maj+S failed because of missing offer or profile', {
        profile,
        offer,
      });
      return;
    }

    const pid = encodeURIComponent(btoa(profile?.id));
    const profileLink = `${window.origin}/client/${clientId}/jobs/${offer?.id}/profiles?pid=${pid}`;
    const blurbPlaceholder = `${getExperienceDetail({
      profile,
    })} - ${getMainEducation({ profile })}`;
    const blurb = getProfileBlurb({ profile }) || blurbPlaceholder || '';
    const offerTitle = offer?.isUnclassified ? 'Discover' : offer?.title;

    const text = `${offerTitle} - ${blurb}\n\n${profileLink}`;
    await navigator.clipboard.writeText(text);

    onShowNotification({
      message: t('reveal.candidatesView.contactInfoCopied'),
      level: 'success',
    });
  };

  if (asIcon) {
    return (
      <i className='ri-clipboard-line' onClick={saveProfileInfoToClipBoard} />
    );
  }

  const keyMap = {
    saveProfileInfoToClipBoard:
      OS_CODE === 'mac' ? 'Command+Shift+s' : 'Control+Shift+s',
  };

  const handlers = {
    saveProfileInfoToClipBoard,
  };

  return (
    <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
      {children}
    </GlobalHotKeys>
  );
};

export default compose(
  contextToProps,
  withTranslation('translations'),
  withUserFromJWToken,
  withRouter,
  routerParamsToProps,
  withOffer,
)(SaveProfileInfoToClipboard);
