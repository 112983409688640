import _ from 'underscore';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

const useUpdateCacheSequenceLastUse = () => {
  const apolloClient = useApolloClient();

  // Plugin uses filters, and apollo cannot read from its cache if the input is not the same
  const readSequences = useCallback(
    (clientId) => {
      try {
        return apolloClient.readQuery({
          query: gql`
            query getCachedClientSequences($clientId: ID!) {
              client(id: $clientId) {
                sequences {
                  id
                  lastUseByAuthor {
                    authorEmail
                    date
                    timestamp
                  }
                }
              }
            }
          `,
          variables: {
            clientId,
          },
        });
      } catch (e) {
        return apolloClient.readQuery({
          query: gql`
            query getCachedClientSequences(
              $clientId: ID!
              $filters: ClientSequencesFiltersInput
            ) {
              client(id: $clientId) {
                sequences(filters: $filters) {
                  id
                  lastUseByAuthor {
                    authorEmail
                    date
                    timestamp
                  }
                }
              }
            }
          `,
          variables: {
            clientId,
            filters: {
              activeOnly: true,
            },
          },
        });
      }
    },
    [apolloClient],
  );

  const updateCache = useCallback(
    ({ clientId, sequenceId, authorEmail }) => {
      if (!sequenceId || !clientId) {
        return;
      }

      // TODO: update lastUseByProject as well, but it is not used for now
      const cachedSequences = readSequences(clientId);

      const cachedSequenceData = cachedSequences?.client.sequences.find(
        ({ id }: { id: string }) => id === sequenceId,
      );

      if (!cachedSequenceData) {
        return;
      }

      const lastUsedByAuthorWithoutCurrentAuthor = _.filter(
        cachedSequenceData?.lastUseByAuthor,
        ({ authorEmail: email }) => {
          return email !== authorEmail;
        },
      );

      const now = new Date();

      apolloClient.writeQuery({
        query: gql`
          query WriteCachedSequence($sequenceId: ID!) {
            sequence(id: $sequenceId) {
              id
              lastUseByAuthor {
                authorEmail
                date
                timestamp
              }
            }
          }
        `,
        data: {
          sequence: {
            id: sequenceId,
            __typename: 'Sequence',
            lastUseByAuthor: [
              ...lastUsedByAuthorWithoutCurrentAuthor,
              {
                authorEmail,
                timestamp: now.getTime(),
                date: now.toISOString(),
                __typename: 'LastUseByAuthor',
              },
            ],
          },
        },
        variables: {
          sequenceId,
        },
      });
    },
    [apolloClient, readSequences],
  );

  return useCallback(
    ({ clientId, sequenceId, authorEmail }) => {
      try {
        updateCache({ clientId, sequenceId, authorEmail });
      } catch (e) {
        // pass
        console.error(e);
      }
    },
    [updateCache],
  );
};

export default useUpdateCacheSequenceLastUse;
