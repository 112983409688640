import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import { ATS_NAMES_BY_TYPE } from '@/common/reveal';
import { connectorCanBeSynchronized } from '@/common/reveal/utils';
import {
  IRevealConnector,
  SynchronizationSettingsKeys,
} from '@/graphql/fragments/RevealConnector';
import useUpdateConnectorSynchronizationSettings from '@/graphql/hooks/clients/integrations/updateConnectorSynchronizationSettings';
import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';
import useAtsConfigurationPermission from '@/graphql/hooks/clients/useAtsConfigurationPermission';
import GenericTooltip from '@/components/Common/GenericTooltip';

interface IntegrationSynchronizationSettingsProps {
  connector: IRevealConnector;
}

const IntegrationSynchronizationSettings: React.FC<IntegrationSynchronizationSettingsProps> = ({
  connector,
}) => {
  const [
    updateConnectorSynchronizationSettings,
    { loading },
  ] = useUpdateConnectorSynchronizationSettings();
  const { t } = useTranslation();

  const settings = connector?.synchronizationSettings || {};

  const canEditAtsConfiguration = useAtsConfigurationPermission();

  if (!connectorCanBeSynchronized(connector?.type)) {
    return null;
  }

  const updateHandler = (settingKeys: SynchronizationSettingsKeys[]) => () => {
    const newSettings = _.object(
      settingKeys,
      _.map(settingKeys, (key) => !settings[key]),
    );
    updateConnectorSynchronizationSettings({
      variables: {
        input: {
          id: connector.id,
          type: connector.type,
          settings: {
            ..._.mapObject(_.omit(settings, '__typename'), (value) => !!value),
            ...newSettings,
          },
        },
      },
    });
  };

  return (
    <div>
      <div className='integration-header'>
        <div className='integration-header-text'>
          {t('integrations.synchronizationSettings.title')}
        </div>
      </div>
      <div className='segment'>
        {t('integrations.synchronizationSettings.description')}{' '}
        {ATS_NAMES_BY_TYPE[connector?.type]}.
      </div>
      <div className='inputs-row'>
        <div className='input-container half-width'>
          <div className='input-element'>
            <SynchronizationSettingsCheckbox
              checkbox={
                <LabeledCheckbox
                  disabled={loading || !canEditAtsConfiguration}
                  checked={
                    settings?.synchronizeNotes &&
                    settings?.synchronizeCalls &&
                    settings?.synchronizeEmails &&
                    settings?.synchronizeLinkedinMessages
                  }
                  label={t(
                    'integrations.synchronizationSettings.activities.synchronize',
                  )}
                  onClick={updateHandler([
                    'synchronizeNotes',
                    'synchronizeCalls',
                    'synchronizeEmails',
                    'synchronizeLinkedinMessages',
                  ])}
                />
              }
            />
          </div>
          <div className='input-description'>
            {t('integrations.synchronizationSettings.activities.description')}
          </div>
        </div>
        <div className='input-container half-width'>
          <div className='input-element'>
            <SynchronizationSettingsCheckbox
              checkbox={
                <LabeledCheckbox
                  disabled={loading || !canEditAtsConfiguration}
                  checked={settings?.synchronizeCompletedTasks}
                  label={t(
                    'integrations.synchronizationSettings.completedTasks.synchronize',
                  )}
                  onClick={updateHandler(['synchronizeCompletedTasks'])}
                />
              }
            />
          </div>
          <div className='input-description'>
            {t(
              'integrations.synchronizationSettings.completedTasks.description',
            )}
          </div>
        </div>
      </div>
      <div className='inputs-row'>
        <div className='input-container half-width'>
          <div className='input-element'>
            <SynchronizationSettingsCheckbox
              checkbox={
                <LabeledCheckbox
                  disabled={loading || !canEditAtsConfiguration}
                  checked={settings?.synchronizeMissionActivities}
                  label={t(
                    'integrations.synchronizationSettings.missionActivities.synchronize',
                  )}
                  onClick={updateHandler(['synchronizeMissionActivities'])}
                />
              }
            />
          </div>
          <div className='input-description'>
            {t(
              'integrations.synchronizationSettings.missionActivities.description',
            )}
          </div>
        </div>
        <div className='input-container half-width'>
          <div className='input-element'>
            <SynchronizationSettingsCheckbox
              checkbox={
                <LabeledCheckbox
                  disabled={loading || !canEditAtsConfiguration}
                  checked={settings?.synchronizeSequenceActivities}
                  label={t(
                    'integrations.synchronizationSettings.sequenceActivities.synchronize',
                  )}
                  onClick={updateHandler(['synchronizeSequenceActivities'])}
                />
              }
            />
          </div>
          <div className='input-description'>
            {t(
              'integrations.synchronizationSettings.sequenceActivities.description',
            )}
          </div>
        </div>
      </div>
      <div /> {/* HACK to get the right margin */}
    </div>
  );
};

const SynchronizationSettingsCheckbox = ({
  checkbox,
}: {
  checkbox: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const canEditAtsConfiguration = useAtsConfigurationPermission();

  if (!canEditAtsConfiguration) {
    return (
      <GenericTooltip trigger={<div>{checkbox}</div>}>
        {t('common.permissions.disabled')}
      </GenericTooltip>
    );
  }
  return <>{checkbox}</>;
};

export default IntegrationSynchronizationSettings;
