import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Form as SemanticForm } from 'semantic-ui-react';

interface Props {
  defaultValues: { [x: string]: any } | undefined;
  resolver?: any;
  onSubmit: any;
  [x: string]: any;
}

const RegisteredForm: React.FC<Props> = ({
  defaultValues,
  resolver,
  onSubmit,
  children,
  ...otherProps
}) => {
  const form = useForm({
    defaultValues,
    resolver,
  });

  const onSubmitWrapped = (...args: any) => {
    onSubmit(...args);
    form.reset(defaultValues);
  };

  return (
    <FormProvider {...form}>
      <SemanticForm
        {...otherProps}
        onSubmit={form.handleSubmit(onSubmitWrapped)}
      >
        {children}
      </SemanticForm>
    </FormProvider>
  );
};

export default RegisteredForm;
