import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { getATSDataItems } from '@/common/reveal/SourceData';
import { INTERACTION_TYPES } from '@/common/constants/taskTypes';
import { getSourceNameFromSource } from '../ProfileDetails';
import './profileOverview.css';
import TaskIcon from '../TaskIcon';

interface ProfileOverviewProps {
  profile: any;
}

const ProfileOverview: React.FC<ProfileOverviewProps> = ({ profile }) => {
  const { t } = useTranslation();
  const sourceData = profile?.resumeData?.sourceData || {};
  const atsDataItems = getATSDataItems(sourceData);
  const timeline = profile?.contactData?.timeline;
  const interactions = _.filter(timeline, (timelineItem) =>
    _.contains(INTERACTION_TYPES, timelineItem.action.subtype),
  );
  const lastInteraction = interactions[0];
  const lastSeen = profile?.views?.timeline?.[0] || null;

  return (
    <div className='overview-container'>
      <h4 className='background-section-title'>Overview</h4>
      <div className='overview'>
        <div className='overview-section'>
          <div className='icon-container'>
            <i className='ri-calendar-event-line ri-lg' />
          </div>
          <div className='section-details'>
            <h4 className='section-title'>
              {t('reveal.profileModal.detailsContent.creationDate')}
            </h4>
            <div className='section-value'>
              {t('common.shortDate', {
                date: new Date(+profile?.creationTimestamp),
              })}
            </div>
          </div>
        </div>
        <div className='overview-section'>
          <div className='icon-container'>
            <i className='ri-database-2-line ri-lg' />
          </div>
          <div className='section-details'>
            <h4 className='section-title'>
              {t('reveal.profileModal.detailsContent.source')}
            </h4>
            <div className='section-value'>
              {getSourceNameFromSource(t, atsDataItems, profile?.source)}
            </div>
          </div>
        </div>
        <div className='overview-section'>
          <div className='icon-container'>
            {lastInteraction ? (
              <TaskIcon action={{ type: lastInteraction.action?.subtype }} />
            ) : (
              <i className='ri-calendar-event-line ri-lg' />
            )}
          </div>
          <div className='section-details'>
            <h4 className='section-title'>
              {t('reveal.profileModal.detailsContent.lastInteraction')}
            </h4>
            <div className='section-value'>
              {lastInteraction
                ? t('common.shortDate', {
                    date: new Date(+lastInteraction.date),
                  })
                : '/'}
            </div>
          </div>
        </div>
        <div className='overview-section'>
          <div className='icon-container'>
            <i className='ri-eye-line ri-lg' />
          </div>
          <div className='section-details'>
            <h4 className='section-title'>
              {t('reveal.profileModal.detailsContent.lastSeen', {
                author: lastSeen?.author?.firstname,
              })}
            </h4>
            <div className='section-value'>
              {lastSeen
                ? t('common.shortDate', {
                    date: new Date(lastSeen.date),
                  })
                : '/'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileOverview;
