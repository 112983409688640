import { useRef, useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { getOfferProfiles } from '@/hocs/offers/withOfferProfiles';

import useInstantFlowStatus, {
  InstantFlowStatus,
} from './useInstantFlowStatus';

const DEFAULT_MAX_REFETCH_ATTEMPTS_NUMBER = 120;
const DEFAULT_REFETCH_DELAY_IN_MS = 1000;

interface UsePollInstantFlowStatusOptions {
  maxRefetchAttemptsNumber?: number;
  refetchDelayInMs?: number;
  onSuccess?: () => void;
  onError?: () => void;
}

function usePollInstantFlowStatus(
  id: string,
  options: UsePollInstantFlowStatusOptions = {},
): InstantFlowStatus | undefined {
  const apolloClient = useApolloClient();
  const {
    maxRefetchAttemptsNumber = DEFAULT_MAX_REFETCH_ATTEMPTS_NUMBER,
    refetchDelayInMs = DEFAULT_REFETCH_DELAY_IN_MS,
    onSuccess,
    onError,
  } = options;
  const [remainingRefetches, setRemainingRefetches] = useState(
    maxRefetchAttemptsNumber,
  );
  const { instantFlowStatus, refetch, loading } = useInstantFlowStatus({ id });

  const refetchStatusTimeout = useRef<ReturnType<typeof setTimeout> | null>(
    null,
  );

  useEffect(() => {
    return () => {
      if (refetchStatusTimeout.current) {
        clearTimeout(refetchStatusTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (instantFlowStatus !== 'pending') {
      setRemainingRefetches(maxRefetchAttemptsNumber);
    }
  }, [instantFlowStatus, maxRefetchAttemptsNumber]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (remainingRefetches === 0) {
      return;
    }
    if (instantFlowStatus && instantFlowStatus !== 'pending') {
      return;
    }
    refetchStatusTimeout.current = setTimeout(() => {
      refetch();
      setRemainingRefetches((current) => current - 1);
    }, refetchDelayInMs);
  }, [
    instantFlowStatus,
    refetch,
    refetchDelayInMs,
    remainingRefetches,
    loading,
  ]);

  useEffect(() => {
    if (remainingRefetches === maxRefetchAttemptsNumber) {
      return;
    }
    if (instantFlowStatus === 'success') {
      apolloClient.refetchQueries({ include: [getOfferProfiles] });
      if (onSuccess) {
        onSuccess();
      }
    }
    if (instantFlowStatus === 'error') {
      apolloClient.refetchQueries({ include: [getOfferProfiles] });
      if (onError) {
        onError();
      }
    }
  }, [
    remainingRefetches,
    maxRefetchAttemptsNumber,
    instantFlowStatus,
    onSuccess,
    onError,
    apolloClient,
  ]);

  return instantFlowStatus;
}

export default usePollInstantFlowStatus;
