import React, { FC } from 'react';

interface CornerProps {
  className?: string;
}

const Corner: FC<CornerProps> = ({ className }) => (
  <svg
    className={className}
    height='1em'
    width='1em'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40 40V19.4482H20.4562V-2.98023e-07H-2.98023e-07V40H40Z'
      fill='currentColor'
    />
  </svg>
);

export default Corner;
