import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import SearchPoolJobWorkflow from '@/graphql/fragments/SearchPoolJobWorkflow';

export const CreateMissionWorkflow = gql`
  mutation createMissionWorkflow(
    $searchPoolId: ID!
    $input: createMissionWorkflowInput!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      createMissionWorkflow(input: $input) {
        job {
          id
          workflows {
            ...SearchPoolJobWorkflow
          }
        }
      }
    }
  }
  ${SearchPoolJobWorkflow}
`;

export default function useCreateMissionWorkflow() {
  return useMutation(CreateMissionWorkflow);
}
