import gql from 'graphql-tag';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import Snippet from './Snippet';

export interface BaseMailTemplateFragment {
  id: string;
  title?: string;
  body?: string;
  isArchived?: boolean;
  subject?: string;
  creationDate: string;
  lastEditionDate?: string;
  snippets: SweetEvaluatorTypes.Variable[];
}

export default gql`
  fragment BaseMailTemplate on MailTemplate {
    id
    title
    body
    isArchived
    subject
    creationDate
    lastEditionDate
    snippets {
      ...Snippet
    }
  }
  ${Snippet}
`;
