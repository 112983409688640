import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import OfferRecruiterConfiguration from '../../graphql/fragments/OfferRecruiterConfiguration';

export const updateRecruiterConfiguration = gql`
  mutation updateRecruiterConfiguration(
    $id: ID!
    $configId: ID!
    $configInput: RecruiterConfigurationInput!
  ) {
    updateRecruiterConfiguration(
      id: $id
      configId: $configId
      configInput: $configInput
    ) {
      id
      recruiterConfigurations {
        ...OfferRecruiterConfiguration
      }
    }
  }
  ${OfferRecruiterConfiguration}
`;

export default graphql(updateRecruiterConfiguration, {
  props: ({ mutate }) => ({
    updateRecruiterConfiguration: ({ id, configId, configInput }) => {
      return mutate({ variables: { id, configId, configInput } });
    },
  }),
});
