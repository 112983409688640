import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import {
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from '@apollo/client';

import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { UserEnrichmentIntegration } from '@/graphql/hooks/users/useUserEnrichmentIntegrations';
import { UserEnrichmentIntegration as UserIntegrationFragment } from '@/graphql/fragments/EnrichmentIntegration';

interface UserWithEnrichmentIntegrations {
  id: string;
  email: string;
  clients: {
    id: string;
    enrichmentIntegrations: UserEnrichmentIntegration;
  };
}

const UserEnrichmentIntegrationsFragment = gql`
  fragment UserEnrichmentIntegrations on User {
    id
    email
    userCurrentClientSettings {
      id
      enrichmentIntegrations {
        ...UserEnrichmentIntegration
      }
    }
  }
  ${UserIntegrationFragment}
`;

const ENABLE_SYSTEM_INTEGRATION_CREDITS = gql`
  mutation enableSystemIntegrationCredits(
    $input: EnableUserInheritedDataProviderInput!
  ) {
    enableClientEnrichmentProvider(input: $input) {
      ...UserEnrichmentIntegrations
    }
  }

  ${UserEnrichmentIntegrationsFragment}
`;

interface EnableSystemEnrichmentIntegrationVariables {
  clientIntegrationId: string;
  // userIntegrationId only necessary if we are updating the integration
  userIntegrationId?: string;
  hasAcceptedTerms: boolean;
  carryOver?: boolean;
  monthlyQuota?: number;
  userEmail?: string;
  type: string;
}

export const useEnableSystemEnrichmentIntegration = (
  variables: Partial<EnableSystemEnrichmentIntegrationVariables>,
  mutationOptions: MutationHookOptions = {},
): MutationTuple<
  UserWithEnrichmentIntegrations,
  | Partial<EnableSystemEnrichmentIntegrationVariables>
  | {
      input: Partial<EnableSystemEnrichmentIntegrationVariables>;
    }
> => {
  const { t } = useTranslation();
  const notifications = useNotificationSystem();
  return useMutation(ENABLE_SYSTEM_INTEGRATION_CREDITS, {
    variables: {
      input: variables,
    },
    onCompleted: () => {
      notifications.success(
        t(`providers.systemIntegrations.enabledSuccessfully`),
      );
    },
    onError: (error) => {
      console.error(error);
      notifications.error(t(`providers.systemIntegrations.errorEnabling`));
    },
    ...mutationOptions,
  });
};

const DISABLE_SYSTEM_INTEGRATION_CREDITS = gql`
  mutation disableSystemIntegrationCredits(
    $input: DisableUserInheritedDataProviderInput!
  ) {
    disableClientEnrichmentProvider(input: $input) {
      ...UserEnrichmentIntegrations
    }
  }

  ${UserEnrichmentIntegrationsFragment}
`;

interface DisableSystemEnrichmentIntegrationVariables {
  userIntegrationId?: string;
  userEmail?: string;
}

export const useDisableSystemEnrichmentIntegration = (
  variables: DisableSystemEnrichmentIntegrationVariables = {},
  mutationOptions: MutationHookOptions = {},
) => {
  const { t } = useTranslation();
  const notifications = useNotificationSystem();

  const mutationResult = useMutation(DISABLE_SYSTEM_INTEGRATION_CREDITS, {
    variables: {
      input: variables,
    },
    onCompleted: () => {
      notifications.success(
        t(`providers.systemIntegrations.disabledSuccessfully`),
      );
    },
    onError: (error) => {
      console.error(error);
      notifications.error(t(`providers.systemIntegrations.errorDisabling`));
    },
    ...mutationOptions,
  });

  if (!variables.userIntegrationId) {
    return [null, null];
  }

  return mutationResult;
};
