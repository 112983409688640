import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

import WorkflowForm from './WorkflowForm';

import contextToProps from '../../../../hocs/contextToProps';

import './OfferMappingCreator.css';

const initialState = {
  hiresweetOfferId: '',
  integrationOfferId: '',
  trigger: '',
  recruiterId: '',
  mutationLoading: false,
  stageStep: null,
  stageName: null,
};

class OfferMappingCreator extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  onAddOfferMapping = () => {
    const {
      needsStageSelection,
      needsRecruiterSelection,
      clientId,
      integration,
      addWorkflow,
      integrationOffers,
      recruiters,
      onShowNotification,
      t,
    } = this.props;

    const {
      hiresweetOfferId: jobOfferId,
      integrationOfferId: jobId,
      trigger,
      recruiterId,
      stageStep,
      stageName,
    } = this.state;
    const integrationId = (integration || {}).id;

    // get jobTitle and recruiterName
    const { name: recruiterName } =
      _.find(recruiters, { id: recruiterId }) || {};
    const { name: jobTitle } = _.find(integrationOffers, { id: jobId }) || {};

    if (!integrationId || !jobOfferId || !jobId || !jobTitle) {
      // todo : error handling
      console.error('Missing Id for offer mapping.');
      onShowNotification({
        message: t('common.genericError'),
        level: 'error',
      });
      return;
    }

    if (needsRecruiterSelection && (!recruiterId || !recruiterName)) {
      // todo : error handling
      console.error('Missing recruiter.');
      onShowNotification({
        message: t('common.genericError'),
        level: 'error',
      });
      return;
    }

    const rule = { type: trigger };
    const action = {
      command: 'upload-profile',
      integrationId,
      target: {
        jobId,
        jobTitle,
        ...(needsStageSelection && {
          stageStep,
          stageName,
        }),
        ...(needsRecruiterSelection && {
          recruiterId,
          recruiterName,
        }),
      },
    };
    this.setState(
      {
        mutationLoading: true,
      },
      () => {
        addWorkflow({
          clientId,
          jobOfferId,
          rule,
          action,
        })
          .then((res) => {
            this.setState(initialState);
            onShowNotification({
              message: t('common.genericSuccess'),
              level: 'success',
            });
          })
          .catch((reason) => {
            onShowNotification({
              message: t('common.genericError'),
              level: 'error',
            });
          });
      },
    );
  };

  handleHiresweetOfferChange = (e, { value }) => {
    this.setState({ hiresweetOfferId: value });
  };

  handleIntegrationOfferChange = (e, { value }) => {
    this.setState({ integrationOfferId: value });
  };

  handleIntegrationTriggerChange = (e, { value }) => {
    this.setState({ trigger: value });
  };

  handleRecruiterChange = (e, { value }) => {
    this.setState({ recruiterId: value });
  };

  handleStageChange = ({ stageStep, stageName }) => {
    this.setState({
      stageStep,
      stageName,
    });
  };

  render() {
    const {
      t,
      clientId,
      needsRecruiterSelection,
      needsStageSelection,
      recruiters,
      offers,
      integrationOffers,
      integration,
    } = this.props;

    const {
      hiresweetOfferId,
      integrationOfferId,
      recruiterId,
      mutationLoading,
      stageStep,
      trigger,
    } = this.state;

    const disableAddButton =
      mutationLoading ||
      !hiresweetOfferId ||
      !integrationOfferId ||
      !trigger ||
      (needsStageSelection && !stageStep) ||
      (needsRecruiterSelection && !recruiterId);

    return (
      <div className='offerMappingCreator'>
        <WorkflowForm
          offers={offers}
          integration={integration}
          clientId={clientId}
          hiresweetOfferId={hiresweetOfferId}
          handleHiresweetOfferChange={this.handleHiresweetOfferChange}
          trigger={trigger}
          handleIntegrationTriggerChange={this.handleIntegrationTriggerChange}
          integrationOffers={integrationOffers}
          integrationOfferId={integrationOfferId}
          handleIntegrationOfferChange={this.handleIntegrationOfferChange}
          needsStageSelection={needsStageSelection}
          stageStep={stageStep}
          handleStageChange={this.handleStageChange}
          needsRecruiterSelection={needsRecruiterSelection}
          recruiters={recruiters}
          recruiterId={recruiterId}
          handleRecruiterChange={this.handleRecruiterChange}
          offerSpecificMode={false}
          t={t}
        />
        <div className='offerMappingCreator-element'>
          <Button
            type='submit'
            primary
            onClick={disableAddButton ? () => {} : this.onAddOfferMapping}
            disabled={disableAddButton}
          >
            {t('integrations.addButton')}
          </Button>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation('translations'),
  contextToProps,
)(OfferMappingCreator);
