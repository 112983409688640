import React, { FC } from 'react';
import _ from 'underscore';

import Avatar from '@/components/Common/Avatar/Avatar';

import { ProfileWithAvatar } from '@/types/profile';
import styles from './AvatarGroup.module.less';

interface AvatarGroupProps {
  profiles: ProfileWithAvatar[];
  remainder: number;
}

const AvatarGroup: FC<AvatarGroupProps> = ({ profiles, remainder }) => (
  <span className={styles.avatars}>
    {_.map(profiles, ({ id, resumeData }) => (
      <Avatar key={id} className={styles.avatar} person={resumeData} />
    ))}
    {remainder !== 0 && <span className={styles.avatar}>+{remainder}</span>}
  </span>
);

export default AvatarGroup;
