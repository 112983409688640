import gql from 'graphql-tag';
import TranslatableItem from './TranslatableItem';
import TranslatableText from './TranslatableText';

export default gql`
  fragment JobPosting on JobPosting {
    id
    published
    url
    title {
      ...TranslatableText
    }
    foldering {
      department {
        id
        title
      }
      section {
        id
        title
      }
    }
    salary {
      min
      max
      currency
      type
      period
      freeText
    }
    remote {
      ...TranslatableItem
    }
    locations {
      ...TranslatableItem
    }
    contractType {
      ...TranslatableItem
    }
    description {
      type
      ... on MultiSectionsJobPostingDescription {
        sections {
          type
          ... on SimpleJobPostingDescriptionSection {
            title {
              ...TranslatableText
            }
            content {
              ...TranslatableText
            }
          }
          ... on VideoJobPostingDescriptionSection {
            url
          }
          ... on ImageJobPostingDescriptionSection {
            name
            file {
              url
            }
          }
        }
      }
    }
    application {
      form {
        type
        ... on ContactAndQuestionsJobPostingApplicationForm {
          questionsForm {
            type
            templateId
          }
        }
      }
    }
  }
  ${TranslatableText}
  ${TranslatableItem}
`;
