import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const mutation = gql`
  mutation createSuggestedOffer($id: ID!, $seekingCandidatesOnly: Boolean!) {
    createSuggestedOffer(
      id: $id
      seekingCandidatesOnly: $seekingCandidatesOnly
    ) {
      id
      title
      isSuggestedForCreation
      isWaitingForValidation
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    createSuggestedOffer: ({ id, seekingCandidatesOnly }) =>
      mutate({
        variables: {
          id,
          seekingCandidatesOnly,
        },
      }),
  }),
});
