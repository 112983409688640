import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import _ from 'underscore';
import { useCallback } from 'react';

const SAVED_SEARCH_PARAMS = gql`
  query getSavedSearchParams($searchPoolId: ID!, $jobId: ID!) {
    searchPool(id: $searchPoolId) {
      id
      job(id: $jobId) {
        id
        lastCriteriaForSearchView {
          free
        }
      }
    }
  }
`;

const UPDATE_SAVED_SEARCH_PARAMS = gql`
  mutation updateSavedSearchParams(
    $searchPoolId: ID!
    $input: UpdateSearchParamsInput!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      updateSearchParams(input: $input) {
        job {
          id
          lastCriteriaForSearchView {
            free
          }
        }
      }
    }
  }
`;

const useSavedSearchParams = ({
  mode,
  jobId,
}: {
  mode?: 'general' | 'job';
  jobId?: string;
}) => {
  const { data, loading } = useQuery(SAVED_SEARCH_PARAMS, {
    variables: {
      searchPoolId: 'reveal',
      jobId,
    },
    skip: !jobId || mode !== 'job',
  });
  const [updateSavedSearchParams] = useMutation(UPDATE_SAVED_SEARCH_PARAMS);

  const mutationSetSavedSearchParams = useCallback(
    async (newSearchParams: any) => {
      await updateSavedSearchParams({
        variables: {
          searchPoolId: 'reveal',
          input: {
            id: jobId,
            searchCriteria: { free: JSON.stringify({ ...newSearchParams }) },
          },
        },
      });
    },
    [jobId, updateSavedSearchParams],
  );

  if (mode === 'job' && jobId) {
    let parsedCriteria: any = {};
    try {
      parsedCriteria = JSON.parse(
        data?.searchPool?.job?.lastCriteriaForSearchView?.free || null,
      );
      const projectFilters = getProjectFilters({
        savedProjectFilters: parsedCriteria?.criteria?.projectFilters || [],
        jobId,
      });
      parsedCriteria = {
        ...parsedCriteria,
        criteria: {
          ...parsedCriteria?.criteria,
          projectFilters,
        },
      };
    } catch (e) {
      console.error(e);
    }
    return {
      savedSearchParams: parsedCriteria,
      loadingSavedSearchParams: loading,
      setSavedSearchParams: mutationSetSavedSearchParams,
    };
  }
  return {
    savedSearchParams: JSON.parse(
      localStorage.getItem('reveal-search-params') || '{}',
    ),
    loadingSavedSearchParams: false,
    setSavedSearchParams: (newSearchParams: any) => {
      return localStorage.setItem(
        'reveal-search-params',
        JSON.stringify(newSearchParams),
      );
    },
  };
};

const getProjectFilters = ({
  savedProjectFilters,
  jobId,
}: {
  savedProjectFilters: any[];
  jobId: string;
}) => {
  if (!_.findWhere(savedProjectFilters, { filterType: 'currently-not-in' })) {
    return [
      {
        filterType: 'currently-not-in',
        projectIds: [jobId],
      },
    ];
  }

  return _.map(savedProjectFilters, (filter) => {
    if (filter.filterType === 'currently-not-in') {
      if (_.contains(filter.projectIds, jobId)) {
        return filter;
      }
      return {
        ...filter,
        projectIds: [...(filter.projectIds || []), jobId],
      };
    }
    return filter;
  });
};

export default useSavedSearchParams;
