import gql from 'graphql-tag';
import TranslatableText from './TranslatableText';

export default gql`
  fragment ClientCrmDescriptor on ClientCrmDescriptor {
    name
    website
    logoURL
    linkedin
    crunchbase
    description {
      type
      ... on MultiSectionsClientDescription {
        sections {
          type
          ... on SimpleClientDescriptionSection {
            title {
              ...TranslatableText
            }
            content {
              ...TranslatableText
            }
          }
          ... on VideoClientDescriptionSection {
            url
          }
          ... on ImageClientDescriptionSection {
            file {
              url
            }
          }
        }
      }
    }
    locations {
      identifier
      label {
        ...TranslatableText
      }
    }
    industries {
      id
      name {
        ...TranslatableText
      }
    }
    stack {
      id
      icon
      name {
        ...TranslatableText
      }
    }
    staffRange {
      min
      max
    }
  }
  ${TranslatableText}
`;
