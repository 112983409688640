import React, { FC, ReactNode } from 'react';
import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';

import MarketplaceGeneralCriteriaForm from './MarketplaceGeneralCriteriaForm/MarketplaceGeneralCriteriaForm';
import RevealGeneralCriteriaForm from './RevealGeneralCriteriaForm/RevealGeneralCriteriaForm';
import { FormOptions, FormValue, HandleChangeArguments } from './types';

interface GeneralCriteriaFormProps {
  current: FormValue;
  options: FormOptions;
  handleChange: <T extends keyof FormValue>(
    args: HandleChangeArguments<T>,
  ) => void;
  errorFields: string[];
  modifyingNotification: (label: string) => ReactNode;
}

const GeneralCriteriaForm: FC<GeneralCriteriaFormProps> = ({ ...props }) => {
  const { applicationMode } = useNavApplicationMode();

  const FormComponent =
    applicationMode === 'reveal'
      ? RevealGeneralCriteriaForm
      : MarketplaceGeneralCriteriaForm;

  return <FormComponent {...props} />;
};

export default GeneralCriteriaForm;
