import gql from 'graphql-tag';
import SweetDate from './SweetDate';
import TextWithEnrichmentsAndStack from './TextWithEnrichmentsAndStack';
import TranslatableText from './TranslatableText';

export default gql`
  fragment RecommendedProfile on Profile {
    id
    creationTimestamp
    resumeData {
      step
      firstname
      lastname
      photoLink
      headline {
        ...TextWithEnrichmentsAndStack
      }
      location {
        name {
          ...TranslatableText
        }
      }
      mainEducation {
        schoolName
      }
      experiences {
        title {
          text
        }
        startDate {
          ...SweetDate
        }
        endDate {
          ...SweetDate
        }
        companyName
      }
      smartSummary {
        arguments {
          type
          ... on ReviewArgument {
            content {
              ...TranslatableText
            }
          }
        }
      }
      sourceData {
        hiresweet {
          wishes {
            locations {
              remoteWish
              target {
                location {
                  ...TranslatableText
                }
                remoteWish
              }
            }
            jobs {
              target {
                ...TranslatableText
              }
            }
            salary {
              target
              okWith
            }
            skills {
              target {
                skillId
                relevance
              }
              okWith {
                skillId
                relevance
              }
            }
          }
        }
        linkedin {
          skills {
            label {
              ...TranslatableText
            }
            relevance
          }
        }
      }
    }
  }
  ${SweetDate}
  ${TextWithEnrichmentsAndStack}
  ${TranslatableText}
`;
