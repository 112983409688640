import React, { FC, useMemo } from 'react';
import _ from 'underscore';
import { AuthorDailyEvent } from '@/types/statistics/authorStatistics';
import {
  convertAuthorDailyEvent,
  getAuthorStack,
  getFullnameMap,
} from './helpers';
import { ChartType, Frequency, Mode } from '../GraphWrapper/types';
import { TimeSpan } from '../RevealAnalyticsHeader/RevealAnalyticsHeader';
import DailyEventsDataContainer from '../DailyEventsDataContainer/DailyEventsDataContainer';

interface AuthorDataContainerProps {
  frequency: Frequency;
  dateRange: TimeSpan;
  type: ChartType;
  mode: Mode;
  authorDailyEvents: AuthorDailyEvent[];
  fetchDetails?: (startDay: string, endDay: string, datakey: string) => void;
}

const AuthorDataContainer: FC<AuthorDataContainerProps> = ({
  frequency,
  dateRange,
  type,
  mode,
  authorDailyEvents,
  fetchDetails,
}) => {
  const dailyEvents = useMemo(
    () => _.map(authorDailyEvents, convertAuthorDailyEvent),
    [authorDailyEvents],
  );

  const authorMap = useMemo(() => getFullnameMap(authorDailyEvents), [
    authorDailyEvents,
  ]);

  const stack = useMemo(() => getAuthorStack(authorMap), [authorMap]);

  return (
    <DailyEventsDataContainer
      frequency={frequency}
      dateRange={dateRange}
      type={type}
      mode={mode}
      dailyEvents={dailyEvents}
      stack={stack}
      fetchDetails={fetchDetails}
    />
  );
};

export default AuthorDataContainer;
