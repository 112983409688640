import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import Author from '@/graphql/fragments/Author';
import Campaign from '../../../graphql/fragments/Campaign';

export const CLIENT_CAMPAIGNS = gql`
  query getClientCampaigns($clientId: ID!, $filters: ClientCampaignsInput) {
    client(id: $clientId) {
      id
      campaigns(filters: $filters) {
        ...Campaign
      }
    }
  }
  ${Campaign}
`;

export const CLIENT_MINI_CAMPAIGNS = gql`
  query getClientMiniCampaigns($clientId: ID!, $filters: ClientCampaignsInput) {
    client(id: $clientId) {
      id
      campaigns(filters: $filters) {
        id
        title
        subject
        isArchived
        author {
          ...Author
        }
      }
    }
  }
  ${Author}
`;

export const CLIENT_CAMPAIGN = gql`
  query getClientCampaign($campaignId: ID!) {
    campaign(id: $campaignId) {
      ...Campaign
    }
  }
  ${Campaign}
`;

export const ARCHIVE_CAMPAIGN = gql`
  mutation archiveCampaign($input: ArchiveCampaignInput!) {
    campaign {
      archive(input: $input) {
        campaign {
          ...Campaign
        }
      }
    }
  }
  ${Campaign}
`;

export const RESTORE_CAMPAIGN = gql`
  mutation restoreCampaign($input: RestoreCampaignInput!) {
    campaign {
      restore(input: $input) {
        campaign {
          ...Campaign
        }
      }
    }
  }
  ${Campaign}
`;

export const CREATE_CAMPAIGN = gql`
  mutation createCampaign($input: CreateCampaignInput!) {
    campaign {
      create(input: $input) {
        campaign {
          ...Campaign
        }
      }
    }
  }
  ${Campaign}
`;

export const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign($input: UpdateCampaignInput!) {
    campaign {
      update(input: $input) {
        campaign {
          ...Campaign
        }
      }
    }
  }
  ${Campaign}
`;

export const DUPLICATE_CAMPAIGN = gql`
  mutation duplicateCampaign($input: DuplicateCampaignInput!) {
    campaign {
      duplicate(input: $input) {
        campaign {
          ...Campaign
        }
      }
    }
  }
  ${Campaign}
`;

export const CLIENT_CAMPAIGN_TEMPLATES = gql`
  query getClientCampaignTemplates($clientId: ID!) {
    client(id: $clientId) {
      id
      campaignTemplates {
        defaultCampaignTemplates {
          title
          id
          description
          descriptionHTML
          subject
        }
      }
    }
  }
`;

export const useClientCampaignTemplates = ({ clientId, ...options }) => {
  const { data, loading } = useQuery(CLIENT_CAMPAIGN_TEMPLATES, {
    variables: { clientId },
    ...options,
  });
  return {
    loading,
    clientCampaignTemplates:
      data?.client?.campaignTemplates.defaultCampaignTemplates,
  };
};
