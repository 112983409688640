import _ from 'underscore';
import { getTranslatedText, removeEmptyKeyValues } from '../../../../../common';

export const getCriteriaForSearch = ({
  experience,
  locations,
  industries,
  backgroundSolidity,
  schoolPrestige,
  startupnessImportance,
  skills,
  jobPosition,
  advancedBackgroundDefinition,
  managementLevels,
  projectFilters,
  sequenceFilters,
  freelanceStates,
  companyStates,
  schoolStates,
  customFilters,
  importFilters,
}) => {
  const newCriteria = {
    ...(backgroundSolidity && {
      backgroundSolidity: {
        target: { min: 4 },
        disable: !backgroundSolidity,
        disabled: !backgroundSolidity,
      },
    }),
    ...(schoolPrestige && {
      schoolPrestige: {
        target: [{ id: 'top' }, { id: 'very-top' }],
        disable: !schoolPrestige,
        disabled: !schoolPrestige,
      },
    }),
    ...(startupnessImportance && { startupnessImportance }),
    ...(!experienceCriteriaIsEmpty(experience) && { experience }),
    ...(!_.isEmpty(locations?.target) && { locations }),
    ...(!_.isEmpty(industries?.target) && { industries }),
    ...(!_.isEmpty(managementLevels?.target) && { managementLevels }),
    ...(!_.isEmpty(freelanceStates?.target) && { freelanceStates }),
    ...(!_.isEmpty(companyStates) && { companyStates }),
    ...(!_.isEmpty(schoolStates) && { schoolStates }),
    ...(_.isObject(jobPosition) && !_.isEmpty(jobPosition) && { jobPosition }),
    ...(countSkills({ skills }) && { skills }),
    ...(!_.isEmpty(customFilters) && { customFilters }),
    ...(!_.isEmpty(advancedBackgroundDefinition) &&
      getExtraCriteriaFromAdvancedBackgroundDefinition({
        advancedBackgroundDefinition,
      })),
    ...(!_.isEmpty(projectFilters) && { projectFilters }),
    ...(!_.isEmpty(sequenceFilters) && { sequenceFilters }),
    ...(!_.isEmpty(importFilters) && { importFilters }),
  };

  newCriteria.importanceWeights = getImportanceWeights(newCriteria);

  return removeEmptyKeyValues(newCriteria);
};

export const getExtraCriteriaFromAdvancedBackgroundDefinition = ({
  advancedBackgroundDefinition,
}) => {
  return {
    extraCriteria: _.map(advancedBackgroundDefinition, (item) => ({
      id: item?.id, // keep for filters state
      importance: item?.importance,
      criterion: {
        id: 'advancedBackground',
        params: item?.advancedBackground,
      },
    })),
  };
};

const getImportanceWeights = ({
  jobPosition,
  skills,
  backgroundSolidity,
  schoolPrestige,
}) => {
  if (
    _.isEmpty(jobPosition) &&
    _.isEmpty(skills) &&
    !backgroundSolidity &&
    !schoolPrestige
  ) {
    return null;
  }

  const importanceWeights = {
    jobPosition: !_.isEmpty(jobPosition) ? 2 : 0,
    skills: !_.isEmpty(skills) ? 2 : 0,
    background: backgroundSolidity || schoolPrestige ? 1 : 0,
  };

  while (sumValues(importanceWeights) < 5) {
    _.each(['jobPosition', 'skills', 'background'], (criteria) => {
      if (importanceWeights[criteria] > 0 && sumValues(importanceWeights) < 5) {
        importanceWeights[criteria] += 1;
      }
    });
  }

  return importanceWeights;
};

const sumValues = (array) => {
  return _.reduce(_.values(array), (memo, value) => memo + value, 0);
};

const experienceCriteriaIsEmpty = (experience) => {
  if (_.isEmpty(experience)) {
    return true;
  }
  if (
    _.isNumber(experience?.target?.min) ||
    _.isNumber(experience?.target?.max)
  ) {
    return false;
  }
  if (
    _.isNumber(experience?.okWith?.min) ||
    _.isNumber(experience?.okWith?.max)
  ) {
    return false;
  }
  return true;
};

// TODO: apply getCriteriaForSearch only when submitting, to avoid 2-way formatting (?)
// Reverse "getCriteriaForSearch"
export const getCriteriaForFilters = ({ criteria }) => {
  const { extraCriteria } = criteria || {};
  const advancedBackgroundDefinition = getAdvancedBackgroundDefinitionFromExtraCriteria(
    { extraCriteria },
  );
  return {
    ..._.omit(criteria, 'extraCriteria'),
    ...(advancedBackgroundDefinition && { advancedBackgroundDefinition }),
  };
};

const getAdvancedBackgroundDefinitionFromExtraCriteria = ({
  extraCriteria,
}) => {
  if (_.isEmpty(extraCriteria)) {
    return null;
  }
  return _.map(extraCriteria, (item) => {
    return {
      id: item?.id,
      importance: item?.importance,
      advancedBackground: item?.criterion?.params,
    };
  });
};

// see: getOptions used in Parameters/OfferCriteria
export const formatOptions = (criteriaOptions) => ({
  jobPositions: _.map((criteriaOptions || {}).jobPositions, (jp) => ({
    value: jp.identifier,
    text: getTranslatedText(jp.label),
  })),
  locations: _.map((criteriaOptions || {}).locations, (loc) => ({
    value: loc.identifier,
    text: getTranslatedText(loc.label),
  })),
  languages: _.map((criteriaOptions || {}).languages, (language) => ({
    value: language.identifier,
    text: getTranslatedText(language.label),
  })),
  bonusSkills: _.map(((criteriaOptions || {}).skills || {}).bonus, (sk) => ({
    value: sk.identifier,
    text: getTranslatedText(sk.label),
  })),
  importantSkills: _.map(
    ((criteriaOptions || {}).skills || {}).important,
    (sk) => ({
      value: sk.identifier,
      text: getTranslatedText(sk.label),
    }),
  ),
  requiredSkills: _.map(
    ((criteriaOptions || {}).skills || {}).required,
    (sk) => ({
      value: sk.identifier,
      text: getTranslatedText(sk.label),
    }),
  ),
  remote: _.map((criteriaOptions || {}).remote, (option) => ({
    value: option.identifier,
    text: getTranslatedText(option.label),
  })),
  freelance: _.map((criteriaOptions || {}).freelance, (option) => ({
    value: option.identifier,
    text: getTranslatedText(option.label),
  })),
  collaboratorsInCharge: _.map(
    (criteriaOptions || {}).collaboratorsInCharge,
    ({ firstname, lastname, email }) => ({
      value: email,
      text: `${firstname || ''} ${lastname || ''}`,
    }),
  ),
  education: _.map((criteriaOptions || {}).education, (option) => ({
    value: option.identifier,
    text: getTranslatedText(option.label),
  })),
});

export const countJobs = ({ jobPosition }) => {
  const { major, minors } = jobPosition || {};
  return (major?.id ? 1 : 0) + (minors || []).length || 0;
};

export const countSkills = ({ skills }) => {
  const { main, important, bonus } = skills || {};
  const countCategory = (category) => (category || [])[0]?.skills?.length || 0;
  return countCategory(main) + countCategory(important) + countCategory(bonus);
};

export const countLocations = ({ locations }) => {
  return locations?.target?.length || 0;
};

export const countIndustries = ({ industries }) => {
  return industries?.target?.length || 0;
};

export const countFreelanceStates = ({ freelanceStates }) => {
  return freelanceStates?.target?.length || 0;
};

export const countCompanyStates = ({ companyStates }) => {
  return _.reduce(
    companyStates,
    (sum, values) => sum + (values.length > 0 ? 1 : 0),
    0,
  );
};

export const countSchoolStates = ({ schoolStates }) => {
  return _.reduce(
    schoolStates,
    (sum, values) => sum + (values.length > 0 ? 1 : 0),
    0,
  );
};

export const countManagementLevels = ({ managementLevels }) => {
  return managementLevels?.target?.length || 0;
};

export const countExperience = ({ experience }) => {
  return _.isNumber(experience?.target?.min) ||
    _.isNumber(experience?.target?.max)
    ? 1
    : 0;
};

export const countBackground = ({ backgroundSolidity, schoolPrestige }) => {
  return (backgroundSolidity ? 1 : 0) + (schoolPrestige ? 1 : 0);
};

export const countStartupness = ({ startupnessImportance }) => {
  return startupnessImportance ? 1 : 0;
};

export const countAdvancedBackground = ({ advancedBackgroundDefinition }) => {
  return (advancedBackgroundDefinition || []).length;
};

const TYPOLOGY_ID_TO_PARAMS_KEY = {
  school: ['schoolTypes'],
  jobPosition: ['jobPositions'],
  management: ['targetHierarchicalRanks', 'targetManagementChunks'],
  industry: ['industryIds'],
  managementLevels: ['managementLevels'],
  responsibility: ['responsibilities'],
  modality: ['modalities'],
  company: ['companyTypes', 'companyGroups'],
  educationField: ['educationFields'],
  studyLevel: ['studyLevels'],
};

export const validateAdvancedBackgroundDefinition = ({
  advancedBackgroundDefinition,
}) => {
  if (_.isEmpty(advancedBackgroundDefinition)) {
    return null;
  }
  const errors = _.map(
    advancedBackgroundDefinition,
    ({ advancedBackground, importance }, index) => {
      if (!importance) {
        return { errorMessage: 'Importance is required', index };
      }
      if (!advancedBackground?.mode) {
        return { errorMessage: 'Mode is required', index };
      }
      if (
        advancedBackground?.mode === 'work' &&
        !advancedBackground?.aggregation?.id
      ) {
        return { errorMessage: 'Aggregation Mode is required', index };
      }
      const typologyId = advancedBackground?.typology?.id;
      if (!typologyId) {
        return { errorMessage: 'Type of criteria is required', index };
      }
      if (_.has(TYPOLOGY_ID_TO_PARAMS_KEY, typologyId)) {
        const typologyErrors = _.map(
          TYPOLOGY_ID_TO_PARAMS_KEY[typologyId],
          (parameterKey) => {
            if (
              _.isEmpty(
                (advancedBackground?.typology?.params || {})[parameterKey],
              )
            ) {
              return {
                errorMessage: `${parameterKey} parameter is required`,
                index,
              };
            }
            return undefined;
          },
        );
        return typologyErrors[0] || null;
      }
      if (_.isEmpty((advancedBackground?.typology?.params || {})[typologyId])) {
        return { errorMessage: `${typologyId} parameter is required`, index };
      }
      return null;
    },
  );
  return _.compact(errors);
};
