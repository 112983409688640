import React, { useState, useEffect } from 'react';
import _, { compose } from 'underscore';
import * as Sentry from '@sentry/browser';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  Modal,
  Dropdown,
  Input,
  Button,
  Accordion,
  Loader,
} from 'semantic-ui-react';

import { DownIcon } from '../../../../assets/icons';
import {
  invalidEmailFormat,
  isValidGithubProfileUrl,
  isValidLinkedinProfileUrl,
} from '../../../../common/validators';
import formatOfferOptions from '../../../../common/formatOfferOptions';
import withClientBaseOffers from '../../../../hocs/offers/withClientBaseOffers';
import withAddProfileToJob from '../../../../hocs/profiles/withAddProfileToJob';
import contextToProps from '../../../../hocs/contextToProps';
import routerParamsToProps from '../../../../hocs/routerParamsToProps';

import './NewProfileModal.css';

const NewProfileModal = ({
  clientId,
  open,
  offers,
  offersLoading,
  offerId,
  onClose,
  addProfileToJob,
  onShowNotification,
  t,
}) => {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [jobOfferId, setJobOfferId] = useState(offerId || ''); // default offerId from router params
  const [linkedin, setLinkedin] = useState('');
  const [github, setGithub] = useState('');

  const [accordionOpen, setAccordionOpen] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [linkedinError, setLinkedinError] = useState(false);
  const [githubError, setGithubError] = useState(false);

  // reset inputs on open/close;
  useEffect(() => {
    setFirstname('');
    setLastname('');
    setEmail('');
    setLinkedin('');
    setGithub('');
    setJobOfferId(offerId || '');
    setAccordionOpen(false);
    setEmailError(false);
    setLinkedinError(false);
    setGithubError(false);
    // eslint-disable-next-line
  }, [open]);

  if (offersLoading) {
    return (
      <Modal
        className='new-profile-modal-DELETE'
        open={open}
        onClose={onClose}
        size='tiny'
        closeIcon
      >
        <Modal.Header>{t('newProfileModal.header')}</Modal.Header>
        <Modal.Content>
          <Loader
            className='on-dimmer'
            active
            inverted
            inline='centered'
            size='large'
          />
        </Modal.Content>
      </Modal>
    );
  }

  if (!offersLoading && _.isEmpty(offers)) {
    return (
      <Modal
        className='new-profile-modal-DELETE'
        open={open}
        onClose={onClose}
        size='tiny'
        closeIcon
      >
        <Modal.Header>{t('newProfileModal.header')}</Modal.Header>
        <Modal.Content>
          {t('newProfileModal.noOffers.inOrderToAdd')}
          <Link to={`/client/${clientId}/new-job`} onClick={onClose}>
            {t('newProfileModal.noOffers.createAJob')}
          </Link>
          {t('newProfileModal.noOffers.first')}
        </Modal.Content>
        <Modal.Actions className='modal-actions-buttons'>
          <Button
            className='dismiss-button primary'
            type='button'
            onClick={onClose}
          >
            {t('newProfileModal.noOffers.cancel')}
          </Button>
          <Link to={`/client/${clientId}/new-job`} onClick={onClose}>
            <Button className='confirm-button primary' type='button'>
              {t('newProfileModal.noOffers.confirm')}
            </Button>
          </Link>
        </Modal.Actions>
      </Modal>
    );
  }

  const disableSubmit = !firstname || !email || !jobOfferId;

  const handleSubmit = async () => {
    let formHasErrors = false;
    if (invalidEmailFormat({ email })) {
      setEmailError(true);
      formHasErrors = true;
    }

    if (linkedin && !isValidLinkedinProfileUrl({ linkedin })) {
      setLinkedinError(true);
      setAccordionOpen(true);
      formHasErrors = true;
    }

    if (github && !isValidGithubProfileUrl({ github })) {
      setGithubError(true);
      setAccordionOpen(true);
      formHasErrors = true;
    }

    if (formHasErrors) {
      return;
    }

    try {
      const profileInput = {
        data: {
          firstname,
          lastname,
          email,
          sources: {
            ...(linkedin && { linkedin }),
            ...(github && { github }),
          },
        },
      };

      await addProfileToJob({ jobOfferId, profileInput });

      onShowNotification({
        message: t('newProfileModal.success'),
        level: 'success',
      });
    } catch (e) {
      Sentry.captureException(e);
      onShowNotification({
        message: t('newProfileModal.error'),
        level: 'error',
      });
    }
    setEmailError(false);
    onClose();
  };

  return (
    <Modal
      className='new-profile-modal-DELETE'
      open={open}
      onClose={onClose}
      size='tiny'
      closeIcon
    >
      <Modal.Header>{t('newProfileModal.header')}</Modal.Header>
      <Modal.Content>
        <div className='input-container'>
          <div className='input-label'>{t('newProfileModal.firstname')}</div>
          <div className='input-element'>
            <Input
              className='form-row-input'
              fluid
              placeholder={t('newProfileModal.firstname')}
              value={firstname}
              onChange={(e, { value }) => setFirstname(value)}
              input={{
                autoComplete: 'chrome-off',
              }}
            />
          </div>
        </div>

        <div className='input-container'>
          <div className='input-label'>{t('newProfileModal.lastname')}</div>
          <div className='input-element'>
            <Input
              className='form-row-input'
              fluid
              placeholder={t('newProfileModal.lastname')}
              value={lastname}
              onChange={(e, { value }) => setLastname(value)}
              input={{
                autoComplete: 'chrome-off',
              }}
            />
          </div>
        </div>

        <div className='input-container'>
          <div className='input-label'>{t('newProfileModal.email')}</div>
          <div className='input-element'>
            <Input
              className='form-row-input'
              fluid
              placeholder={t('newProfileModal.email')}
              value={email}
              onChange={(e, { value }) => {
                setEmail(value);
                setEmailError(false);
              }}
              input={{
                autoComplete: 'chrome-off',
              }}
              error={emailError}
            />
          </div>
          {emailError && (
            <div className='input-error'>
              {t('newProfileModal.errors.email')}
            </div>
          )}
        </div>

        <div className='input-container'>
          <div className='input-label'>{t('newProfileModal.job')}</div>
          <div className='input-element'>
            <Dropdown
              className='form-row-dropdown'
              fluid
              search
              selection
              placeholder={t('newProfileModal.jobPlaceholder')}
              options={formatOfferOptions(offers)}
              value={jobOfferId}
              onChange={(e, { value }) => setJobOfferId(value)}
            />
          </div>
        </div>

        <Accordion styled className='hs-accordion'>
          <Accordion.Title
            active={accordionOpen}
            icon={<DownIcon className='dropdown icon' />}
            content={t('newProfileModal.addMore')}
            onClick={() => setAccordionOpen((accordionOpen) => !accordionOpen)}
          />

          <Accordion.Content active={accordionOpen}>
            <div className='input-container'>
              <div className='input-label'>{t('newProfileModal.linkedin')}</div>
              <div className='input-element'>
                <Input
                  className='form-row-input'
                  fluid
                  placeholder={t('newProfileModal.linkedinPlaceholder')}
                  value={linkedin}
                  onChange={(e, { value }) => {
                    setLinkedin(value);
                    setLinkedinError(false);
                  }}
                  input={{ autoComplete: 'chrome-off' }}
                  error={linkedinError}
                />
              </div>
              {linkedinError && (
                <div className='input-error'>
                  {t('newProfileModal.errors.linkedin')}
                </div>
              )}
            </div>

            <div className='input-container'>
              <div className='input-label'>{t('newProfileModal.github')}</div>
              <div className='input-element'>
                <Input
                  className='form-row-input'
                  fluid
                  placeholder={t('newProfileModal.githubPlaceholder')}
                  value={github}
                  onChange={(e, { value }) => {
                    setGithub(value);
                    setGithubError(false);
                  }}
                  input={{ autoComplete: 'chrome-off' }}
                  error={githubError}
                />
              </div>
              {githubError && (
                <div className='input-error'>
                  {t('newProfileModal.errors.github')}
                </div>
              )}
            </div>
          </Accordion.Content>
        </Accordion>
      </Modal.Content>

      <Modal.Actions>
        <div className='align-left'>
          <Button className='dismiss' type='button' onClick={onClose}>
            {t('newProfileModal.cancel')}
          </Button>
        </div>
        <Button
          primary
          size='big'
          type='button'
          onClick={handleSubmit}
          disabled={disableSubmit}
        >
          {t('newProfileModal.confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default compose(
  withRouter,
  contextToProps,
  routerParamsToProps,
  withTranslation('translations'),
  withClientBaseOffers,
  withAddProfileToJob,
)(NewProfileModal);
