import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './svg.module.less';

interface FirstAidKitProps {
  className?: string;
}

const FirstAidKit: FC<FirstAidKitProps> = ({ className }) => (
  <svg
    className={classNames(styles.svgIcon, className)}
    viewBox='1 1 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M16 1C16.5523 1 17 1.44772 17 2V5H21C21.5523 5 22 5.44772 22 6V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V6C2 5.44772 2.44772 5 3 5H7V2C7 1.44772 7.44772 1 8 1H16ZM20 7H4V19H20V7ZM13 9V12H16V14H12.999L13 17H11L10.999 14H8V12H11V9H13ZM15 3H9V5H15V3Z' />
  </svg>
);

export default FirstAidKit;
