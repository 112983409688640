import gql from 'graphql-tag';
import TranslatableItem from './fragments/TranslatableItem';

const CLIENT_CRITERIA_OPTIONS = gql`
  query getClientCriteriaOptions($id: ID!) {
    client(id: $id) {
      id
      criteriaOptions {
        jobPositions {
          ...TranslatableItem
        }
        locations {
          ...TranslatableItem
        }
        remote {
          ...TranslatableItem
        }
        freelance {
          ...TranslatableItem
        }
        education {
          ...TranslatableItem
        }
        skills {
          required {
            ...TranslatableItem
          }
          important {
            ...TranslatableItem
          }
          bonus {
            ...TranslatableItem
          }
        }
        languages {
          ...TranslatableItem
        }
        collaboratorsInCharge {
          email
          firstname
          lastname
          position
        }
      }
    }
  }
  ${TranslatableItem}
`;

export default CLIENT_CRITERIA_OPTIONS;
