import { createContext, Dispatch, SetStateAction } from 'react';

interface ProjectSearchParams {
  includeSkipped: boolean;
}

type ProjectSearchParamsState = [
  ProjectSearchParams,
  Dispatch<SetStateAction<ProjectSearchParams>>,
];

const ProjectSearchParamsContext = createContext<
  ProjectSearchParamsState | undefined
>(undefined);

export default ProjectSearchParamsContext;
