import gql from 'graphql-tag';

export default gql`
  fragment ConnectData on ConnectData {
    sourceContactId
    firstname
    lastname
    emails {
      type
      value
    }
    phoneNumbers {
      type
      value
    }
    interestForms {
      creationDate
      formId
      fields {
        type
        title {
          default
        }
        value {
          text
        }
      }
    }
  }
`;
