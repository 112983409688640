import React from 'react';
import getPermissionsAndNudgesAtIndex from '../../../../../common/contactFlow/getPermissionsAndNudgesAtIndex';
import FullContactFlowActionEditor from '../FullContactFlowActionEditor';

const ContactFlowAction = ({
  sequence,
  offerId,
  sequenceId,
  onInsertAction,
  action,
  actions,
  removeNewFollowupCard,
  index,
  t,
  updateAfterTemplateCreation,
  ...rest
}) => {
  return (
    <FullContactFlowActionEditor
      sequenceId={sequenceId}
      action={action}
      actions={actions}
      index={index}
      permissionsAndNudges={getPermissionsAndNudgesAtIndex({
        actions,
        index,
        editType: 'update',
        t,
      })}
      updateAfterTemplateCreation={updateAfterTemplateCreation}
      {...rest}
    />
  );
};

export default ContactFlowAction;
