import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const mutation = gql`
  mutation updateSharedDiscussionMode(
    $token: String!
    $type: String!
    $enableSharedDiscussion: Boolean!
  ) {
    updateSharedDiscussionMode(
      token: $token
      type: $type
      enableSharedDiscussion: $enableSharedDiscussion
    ) {
      type
      address
      enableSharedDiscussion
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    updateSharedDiscussionMode: ({ token, type, enableSharedDiscussion }) =>
      mutate({
        variables: {
          token,
          type,
          enableSharedDiscussion,
        },
      }),
  }),
});
