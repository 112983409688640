import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const query = gql`
  query getProfileDrafts($id: ID!) {
    profile(id: $id) {
      id
      email
      contacted
      locked
      lockedSince
      lastStepUpdateTimestamp
      sent
      isImported
      jobOfferId
      contactData {
        defaultThreadId
        defaultSubject
        threads {
          id
          type
          mailAccountId
          mailAccountType
        }
        draftComment
        assignedSender {
          email
          alias
        }
      }
      resumeData {
        firstname
        lastname
        email
        step
        sourceName
        prequalification {
          type
        }
      }
      talentStrategist {
        email
      }
      powerHourFlags {
        powerHourId
        isFlagged
      }
      firstContactDate
    }
  }
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.profileId,
    },
    // ...(!lastActionTimestamp || Date.now() - lastActionTimestamp < 3 * 60 * 60 * 1000) && { pollInterval: 30000 },
  }),
  props: ({ data: { loading, profile, error } }) => ({
    loading,
    loadingProfileDrafts: loading,
    profile,
    error,
  }),
  skip: (ownProps) => !ownProps.profileId,
});

// tester query recursive https://github.com/facebook/graphql/issues/91
