import React from 'react';
import { 
  HiresweetLibProvider, 
  ProfileEditionForm, 
  ProfileEditionSubmitButton, 
  ProfileFirstnameEditionInput, 
  ProfileLastnameEditionInput,
  ProfileHeadlineEditionInput,
  ProfileEmailEditionInput,
  ProfilePhoneNumberEditionInput,
  ProfileLocationEditionInput,
} from '@hiresweet/hiresweet-lib';

import useClientId from '@/hooks/router/useClientId';



const PlaygroundB7 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30, background: 'white' }}>
      <h1>B7 - Profile Forms - creation</h1>
      <HiresweetLibProvider
        theme={theme}
        clientId={clientId}
      >
        <h4>Creation Form</h4>
        <ProfileEditionForm mode='creation'>
          <ProfileFirstnameEditionInput label='Firstname' />
          <br />
          <ProfileLastnameEditionInput label='Lastname' />
          <br />
          <ProfileHeadlineEditionInput label='Headline' />
          <br />
          <ProfileEmailEditionInput label='Email' />
          <br />
          <ProfilePhoneNumberEditionInput label='Phone' />
          <br />
          <ProfileLocationEditionInput label='Location' />
          <br />
          <ProfileEditionSubmitButton label='Sauvegarder' />
        </ProfileEditionForm>

      </HiresweetLibProvider>
    </div>
  );
};

export default PlaygroundB7;
