import React from 'react';
import _ from 'underscore';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import managementLevelsOptions from '../../../../../common/options/managementLevelsOptions.json';

const ManagementLevelFields = React.memo(
    ({ managementLevels, setManagementLevels }) => {
        const currentValue = _.pluck(managementLevels?.target, 'managementLevel');
        const onChange = (e, { value }) => {
            setManagementLevels({
                target: _.map(value, (managementLevel) => ({ managementLevel })),
            });
        };
        const { t } = useTranslation();
        return (
            <div className='industry-fields section'>
                <Form.Dropdown
                    search
                    selection
                    multiple
                    fluid
                    placeholder={t('common.select')}
                    options={managementLevelsOptions || []}
                    value={currentValue}
                    onChange={onChange}
                    closeOnChange
                />
            </div>
        );
    },
);

export default ManagementLevelFields;
