import React, { useState, useContext } from 'react';
import * as Sentry from '@sentry/browser';
import { Modal, Button, Form } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useCreateSearchPoolJob from '@/graphql/hooks/searchPoolJobs/useCreateSearchPoolJob';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import GenericTextInput from '../Common/GenericTextInput';

interface NewJobModalProps {
  open: boolean;
  setNewJobModalOpen: any;
  searchPoolId: string;
  clientId: string;
}

const NewJobModal: React.FC<NewJobModalProps> = ({
  open,
  setNewJobModalOpen,
  searchPoolId,
  clientId,
}) => {
  const { t } = useTranslation();
  const missionCategory = useContext(MissionCategoryContext);
  const notification = useNotificationSystem();
  const [jobTitle, setJobTitle] = useState('');
  const [
    createNewJobMutation,
    { loading: creatingNewJob, data: createJobData },
  ] = useCreateSearchPoolJob();

  const createNewJob = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await createNewJobMutation({
        searchPoolId,
        input: { title: jobTitle },
      });
      setNewJobModalOpen(false);
      notification.success(t('reveal.missions.newMissionModal.success'));
    } catch (e) {
      Sentry.captureException(e);
      notification.error(t('reveal.missions.newMissionModal.error'));
    }
  };

  const createdJobId = createJobData?.searchPoolJob?.create?.job?.id;
  if (createdJobId) {
    return (
      <Redirect
        to={`/client/${clientId}/reveal/projects/${missionCategory}/${createdJobId}`}
      />
    );
  }

  return (
    <Modal
      onSubmit={createNewJob}
      open={open}
      as={Form}
      onClose={() => setNewJobModalOpen(false)}
      size='tiny'
      onClick={(event: any) => event.stopPropagation()}
    >
      <Modal.Header>{t('reveal.missions.newMissionModal.title')}</Modal.Header>
      <Modal.Content>
        <div className='input-container'>
          <div className='input-label'>
            {t('reveal.missions.newMissionModal.name')}
          </div>
          <div className='input-element'>
            <GenericTextInput fluid value={jobTitle} onValue={setJobTitle} />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className='align-left'>
          <Button
            type='button'
            className='dismiss'
            onClick={() => setNewJobModalOpen(false)}
          >
            {t('common.cancel')}
          </Button>
        </div>
        <Button
          type='submit'
          primary
          size='big'
          disabled={creatingNewJob}
          loading={creatingNewJob}
        >
          {t('reveal.missions.newMissionModal.create')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default NewJobModal;
