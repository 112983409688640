import { compose } from 'underscore';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { MiniProfilesSearchProvider } from '@/context/Profiles/useMiniProfilesSearch';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import { AddIcon } from '../../../assets/icons';
import { sentryCaptureException } from '../../../common';

import withClient from '../../../hocs/clients/withClient';
import withClientBaseOffers from '../../../hocs/offers/withClientBaseOffers';
import withOffer from '../../../hocs/offers/withOffer';
import withActionLogger from '../../../hocs/withActionLogger';
import NewCandidateModal from '../../../routes/RevealView/SearchView/CandidatesListPane/ImportCandidates/NewCandidateModal';

import NewTemplate from '../../Parameters/Templates/modals/NewTemplate';
import NewProfileModal from '../../Profile/modals/newProfileModal';

import AnimatedLogo from './AnimatedLogo';
import ApplicationModeAutoSwitcher from './ApplicationModeAutoSwitcher';
import ClientProfilesSearchBar from './ClientProfilesSearchBar';
import NavConditional from './NavConditional';
import NavSwitch from './NavSwitch';
import { useNavApplicationMode } from './NavSwitch.hook';
import Notifications from './Notifications';
import SettingsDropdown from './SettingsDropdown';
import GlobalSearchBar from './GlobalSearchBar';
import NewProjectButton from './NewProjectButton';

import styles from './Header.module.less';

const Header = React.memo(
  ({ clientId, client, pageId, tabId, t, onLogAction }) => {
    const [newProfileOpen, setNewProfileOpen] = useState(false);
    const [newCandidateModalOpen, setNewCandidateModalOpen] = useState(false);
    const [newCompanyModalOpen, setNewCompanyModalOpen] = useState(false);
    const [newTemplateOpen, setNewTemplateOpen] = useState(false);
    const configurationParams = useMergedConfigurationParams();
    const {
      applicationMode,
      loading,
      error: navApplicationModeError,
    } = useNavApplicationMode();

    const logTopHeaderClick = ({ name }) => {
      try {
        onLogAction({
          type: `click-${name}`,
          info: {
            from: 'top-header',
            clientId,
          },
        });
      } catch (error) {
        sentryCaptureException({ error });
      }
    };

    return (
      <>
        <div className={styles.header}>
          <ApplicationModeAutoSwitcher clientId={clientId} />

          <div className={styles.left}>
            <AnimatedLogo pageId={pageId} clientId={clientId} />

            <NavConditional
              pageId={pageId}
              clientId={clientId}
              tabId={tabId}
              permissions={client?.permissions}
              t={t}
            />
          </div>

          <div className={styles.right}>
            <div className={styles.shortcutIcons}>
              {!loading &&
                !navApplicationModeError &&
                applicationMode === 'classic' && (
                  <div className={styles.clientProfilesSearchBarContainer}>
                    <ClientProfilesSearchBar clientId={clientId} />
                  </div>
                )}

              {!loading &&
                !navApplicationModeError &&
                applicationMode === 'reveal' && (
                  <MiniProfilesSearchProvider>
                    <GlobalSearchBar clientId={clientId} styles={styles} />
                  </MiniProfilesSearchProvider>
                )}

              <div className={styles.iconNavigationItem}>
                <Dropdown
                  className='hs-contextual-menu'
                  trigger={<AddIcon className={styles.addIcon} />}
                  icon={null}
                >
                  <Dropdown.Menu style={{ marginTop: 13 }} direction='left'>
                    {applicationMode === 'classic' && (
                      <Dropdown.Item
                        as={Link}
                        to={`/client/${clientId}/new-job`}
                        onClick={() => {
                          logTopHeaderClick({ name: 'new-offer' });
                        }}
                      >
                        <i className='menu-icon ri-add-line' />
                        <span>{t('header.new.job')}</span>
                      </Dropdown.Item>
                    )}
                    {client?.permissions?.csvImportAndExport &&
                      applicationMode === 'classic' && (
                        <Dropdown.Item
                          onClick={() => {
                            setNewProfileOpen(true);
                            logTopHeaderClick({ name: 'new-profile' });
                          }}
                        >
                          <i className='menu-icon ri-user-received-2-line' />
                          <span>{t('header.new.profile')}</span>
                        </Dropdown.Item>
                      )}
                    {applicationMode === 'reveal' && (
                      <>
                        {configurationParams?.shouldHideAllNewProjectButtons !==
                          'true' && (
                          <NewProjectButton
                            onClick={(name) => logTopHeaderClick({ name })}
                          />
                        )}
                        <Dropdown.Item
                          as={Link}
                          to={`/client/${clientId}/reveal/sequences/new-sequence-create`}
                          onClick={() => {
                            logTopHeaderClick({ name: 'new-sequence' });
                          }}
                        >
                          <i className='menu-icon ri-stack-line' />
                          <span>{t('header.new.sequence')}</span>
                        </Dropdown.Item>
                      </>
                    )}
                    <Dropdown.Item
                      onClick={() => {
                        setNewTemplateOpen(true);
                        logTopHeaderClick({ name: 'new-template' });
                      }}
                    >
                      <i className='menu-icon ri-file-list-2-line' />
                      <span>{t('header.new.template')}</span>
                    </Dropdown.Item>
                    {applicationMode === 'reveal' && (
                      <>
                        <Dropdown.Item
                          onClick={() => {
                            setNewCandidateModalOpen(true);
                            logTopHeaderClick({ name: 'new-reveal-profile' });
                          }}
                        >
                          <i className='menu-icon ri-user-received-2-line' />
                          <span>{t('header.new.profile')}</span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setNewCompanyModalOpen(true);
                            logTopHeaderClick({ name: 'new-reveal-company' });
                          }}
                        >
                          <i className='menu-icon ri-user-received-2-line' />
                          <span>{t('header.new.company')}</span>
                        </Dropdown.Item>
                      </>
                    )}
                    <Dropdown.Item
                      as={Link}
                      to={`/client/${clientId}/users`}
                      onClick={() => {
                        logTopHeaderClick({ name: 'new-collaborator' });
                      }}
                    >
                      <i className='menu-icon ri-team-line' />
                      <span>{t('header.new.user')}</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {client && (
                <div className={styles.notificationsOuterContainer}>
                  <Notifications client={client} clientId={clientId} />
                </div>
              )}
            </div>

            {client && <NavSwitch clientId={clientId} t={t} />}

            {clientId && (
              <SettingsDropdown
                clientId={clientId}
                clientPermissions={client?.permissions}
              />
            )}
          </div>
        </div>
        <NewProfileModal
          clientId={clientId}
          open={newProfileOpen}
          onClose={() => setNewProfileOpen(false)}
        />
        <NewTemplate
          clientId={clientId}
          open={newTemplateOpen}
          onClose={() => setNewTemplateOpen(false)}
          onPostSubmit={() => setNewTemplateOpen(false)}
        />
        <NewCandidateModal
          onClose={() => setNewCandidateModalOpen(false)}
          open={newCandidateModalOpen}
          contactCategory='human'
        />
        <NewCandidateModal
          onClose={() => setNewCompanyModalOpen(false)}
          open={newCompanyModalOpen}
          contactCategory='company'
        />
      </>
    );
  },
);

export default compose(
  withClient,
  withOffer,
  withClientBaseOffers,
  withActionLogger,
  withTranslation('translations'),
)(Header);
