/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo } from 'react';
import _ from 'underscore';
import { Dimmer, Loader } from 'semantic-ui-react';

import useSaveSharedActivity from '@/graphql/hooks/searchPoolProfile/useSaveSharedActivity';
import useUpdateSharedActivityDraft from '@/graphql/hooks/searchPoolProfile/useUpdateSharedActivityDraft';
import useDraftSharedTimelineItems from '@/graphql/hooks/searchPoolProfile/useDraftSharedTimelineItems';
import { getRandomString } from '@/common';
import { MetaTaskFormat } from '@/revealComponents/ExplicitTasks/MetaTaskForm/types';
import { coerceSubtype } from '../subtypes';
import SharedNoteForm, { FormValue } from './SharedNoteForm';
import { getInput } from '../SharedNoteModal';

interface EditSharedNoteProps {
  profileId: string;
  sharedActivityId: string;
  format?: MetaTaskFormat;
  targets: {
    type: string;
    targetId: string;
    isMain?: boolean;
  }[];
  text: string;
  subtype: string;
  customActivityId?: string;
  date: Date;
  onSubmit?: () => void;
}

const EditSharedNote: React.FC<EditSharedNoteProps> = ({
  profileId,
  sharedActivityId,
  format,
  targets,
  text,
  subtype,
  customActivityId,
  date,
  onSubmit,
}) => {
 
  
  const [saveSharedActivity, { loading: isSaving }] = useSaveSharedActivity();
  const { draftSharedTimelineItems, loading: draftsLoading } =
    useDraftSharedTimelineItems({
      profileId,
    });

  const mainTarget = useMemo(
    () => _.findWhere(targets, { isMain: true }),
    [targets],
  );

  const sharedNoteInitialValues = useMemo(() => {
    const companyTarget = _.findWhere(targets, {
      type: 'company',
    });
    const contactTargets = _.filter(
      targets,
      (target) => target.type === 'profile' && !target.isMain,
    );
    const missionTargets = _.filter(
      targets,
      (target) => target.type === 'mission',
    );
    return {
      text,
      companyId: companyTarget?.targetId,
      format: format || undefined,
      contactIds: _.pluck(contactTargets, 'targetId'),
      missionIds: _.pluck(missionTargets, 'targetId'),
      subtype: coerceSubtype(subtype),
      customActivityId,
      date,
    };
  }, [text, subtype, targets, customActivityId, date, format]);
 
  
  const { draftId, initialValue } = useMemo(() => {
    if (draftsLoading) {
      return {
        draftId: '',
        initialValue: {
          text: '',
          subtype: 'comment' as const,
          missionIds: [],
          companyId: undefined,
          contactIds: [],
          format: undefined,
          date: 'now' as const,
        },
      };
    }
    const editionDraft = _.find(
      draftSharedTimelineItems,
      (item) =>
        item.draftStatus === 'edition' &&
        item.draftReferenceSharedActivityId === sharedActivityId,
    );
    if (editionDraft) {
      const draftTargets = editionDraft?.targets || [];
      const companyTarget = _.findWhere(draftTargets, { type: 'company' });
      const contactTargets = _.filter(
        draftTargets,
        ({ isMain, type: targetType }) => targetType === 'profile' && !isMain,
      );
      const missionTargets = _.where(draftTargets, { type: 'mission' });

      return {
        draftId: editionDraft.id || getRandomString(12),
        initialValue: {
          text: editionDraft.action?.comment?.text || '',
          subtype: coerceSubtype(editionDraft.action?.subtype),
          customActivityId: editionDraft?.action?.customActivityId || undefined,
          format: editionDraft?.action?.format || undefined,
          companyId: companyTarget?.targetId || undefined,
          missionIds: _.pluck(missionTargets, 'targetId'),
          contactIds: _.pluck(contactTargets, 'targetId'),
          date: editionDraft.date === 'now' ? ('now' as const) : (
            editionDraft.date ? new Date(+editionDraft.date) : ('now' as const)
          ),
        },
      };
    }
    return {
      draftId: getRandomString(12),
      initialValue: sharedNoteInitialValues,
    };
  }, [
    draftSharedTimelineItems,
    draftsLoading,
    sharedNoteInitialValues,
    sharedActivityId,
  ]);

  const [updateSharedActivityDraft] = useUpdateSharedActivityDraft();
  const handleValue = useMemo(
    () =>
      _.debounce((value: FormValue) => {
        updateSharedActivityDraft(
          getInput({
            value,
            profileId: mainTarget?.targetId || profileId,
            draftId,
            sharedActivityId,
          }),
        );
      }, 2000),
    [
      updateSharedActivityDraft,
      draftId,
      profileId,
      mainTarget,
      sharedActivityId,
    ],
  );


  const handleSave = useCallback(
    async (value: FormValue) => {
      handleValue.cancel();
      await saveSharedActivity({
        variables: {
          searchPoolId: 'reveal',
          input: getInput({
            draftId,
            value,
            profileId: mainTarget?.targetId || profileId,
            sharedActivityId,
          }),
        },
      });
      if (onSubmit) {
        onSubmit();
      }
    },
    [
      profileId,
      draftId,
      saveSharedActivity,
      sharedActivityId,
      onSubmit,
      handleValue,
      mainTarget,
    ],
  );

  return draftsLoading ? (
    <Dimmer active>
      <Loader />
    </Dimmer>
  ) : (
    <SharedNoteForm
      profileId={profileId}
      initialValue={initialValue}
      onValue={handleValue}
      onSave={handleSave}
      isSaving={isSaving}
    />
  );
  
};

export default EditSharedNote;
