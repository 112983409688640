import classNames from 'classnames';
import React, { FC, MouseEvent, ReactNode } from 'react';

import GenericCheckbox from '../GenericCheckbox';

import styles from './LabeledCheckbox.module.less';

interface LabeledCheckboxProps {
  checked?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  label: ReactNode;
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
}

const LabeledCheckbox: FC<LabeledCheckboxProps> = ({
  checked,
  onClick,
  label,
  className,
  labelClassName,
  disabled,
}) => {
  return (
    <button
      type='button'
      onClick={onClick}
      className={classNames(styles.container, className)}
      disabled={disabled || !onClick}
    >
      <GenericCheckbox checked={checked} />
      <span className={classNames(styles.label, labelClassName)}>{label}</span>
    </button>
  );
};

export default LabeledCheckbox;
