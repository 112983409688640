import { graphql } from '@apollo/client/react/hoc';
import GET_CLIENT_ATS_FILTERS_OPTIONS from '@/graphql/clientAtsFilterOptions';

export default graphql(GET_CLIENT_ATS_FILTERS_OPTIONS, {
  options: (ownProps) => ({
    variables: {
      clientId: ownProps.clientId,
    },
  }),
  props: ({
    data: { client, loading: optionsLoading, error: optionsError },
  }) => {
    return {
      projectsWithFilterOptions: client?.revealProjects,
      optionsLoading,
      optionsError,
    };
  },
});
