import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import PipeStepStat from '../../graphql/fragments/PipeStepStat';

export const query = gql`
  query getOfferPipe($id: ID!) {
    offer(id: $id) {
      id
      pipeStepStats {
        ...PipeStepStat
      }
    }
  }
  ${PipeStepStat}
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.offerId,
    },
  }),
  props: ({ data: { loading, offer, error, refetch } }) => ({
    loading,
    pipe: offer && offer.pipeStepStats,
    refetchPipe: refetch,
    error,
  }),
});
