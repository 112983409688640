import React, { useCallback, useMemo, useState } from 'react';
import SettingsLayout from '@/containers/SettingsLayout';
import { useTranslation } from 'react-i18next';
import useClientId from '@/hooks/router/useClientId';
import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';
import GenericModal from '@/components/Common/GenericModal';
import { Modal } from 'semantic-ui-react';
import GenericTextInput from '@/components/Common/GenericTextInput';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import { useMutation } from '@apollo/client';
import useClientExternalAutomationServices, {
  CREATE_CLIENT_EXTERNAL_AUTOMATION_SERVICES,
  DELETE_CLIENT_EXTERNAL_AUTOMATION_SERVICES,
  UPDATE_CLIENT_EXTERNAL_AUTOMATION_SERVICES,
} from '@/graphql/hooks/clients/useClientExternalAutomationServices';
import _ from 'underscore';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import AutomationIntegrationsTable from './AutomationIntegrationsTable';
import styles from './AutomationIntegration.module.less';
import UnipileAutomation from './UnipileAutomation';

const AutomationIntegration: React.FC = () => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { permissions } = useClientPermissions(clientId);

  return (
    <SettingsLayout
      currentPage='automations'
      clientId={clientId}
      className='settings-container'
    >
      <div className='settings'>
        <div className='settings-header'>
          <h1>{t('settings.automations.title')}</h1>
        </div>
        <PhantomBusterAutomation clientId={clientId} t={t} />
        {permissions?.unipile && <UnipileAutomation clientId={clientId} />}
      </div>
    </SettingsLayout>
  );
};

export interface AutomationServiceProps {
  clientId: string;
  t: any;
}

const PhantomBusterAutomation: React.FC<AutomationServiceProps> = ({
  clientId,
  t,
}) => {
  const notifications = useNotificationSystem();
  const [newAutomationModalOpen, setNewAutomationModalOpen] = useState(false);
  const { user } = useCurrentUser();
  const [newAutomationName, setNewAutomationName] = useState('');
  const [newAutomationKey, setNewAutomationKey] = useState('');
  const [selectedAutomationId, setSelectedAutomationId] = useState<
    string | null
  >(null);
  const [deletionModalOpen, setDeletionModalOpen] = useState(false);
  const [editApiKey, setEditApiKey] = useState(false);

  const { externalAutomationServices } = useClientExternalAutomationServices({
    queryOptions: { variables: { clientId } },
  });

  const [createAutomation] = useMutation(
    CREATE_CLIENT_EXTERNAL_AUTOMATION_SERVICES,
  );
  const [updateAutomation] = useMutation(
    UPDATE_CLIENT_EXTERNAL_AUTOMATION_SERVICES,
  );
  const [deleteAutomation] = useMutation(
    DELETE_CLIENT_EXTERNAL_AUTOMATION_SERVICES,
  );

  const handleChangeTitle = (newTitle: string) => {
    setNewAutomationName(newTitle);
  };

  const handleChangeKey = (newKey: string) => {
    setNewAutomationKey(newKey);
  };

  const resetForm = useCallback(() => {
    setSelectedAutomationId(null);
    setNewAutomationKey('');
    setNewAutomationName('');
    setEditApiKey(false);
  }, []);

  const handleUpdateAutomation = () => {
    if (!selectedAutomationId) {
      return;
    }
    updateAutomation({
      variables: {
        input: {
          id: selectedAutomationId,
          title: newAutomationName || selectedAutomation?.title,
          type: selectedAutomation?.type || 'phantombuster',
          ...(newAutomationKey && { apiKey: newAutomationKey }),
        },
      },
      onError: (error) => {
        notifications.error(error.message);
      },
    });
    setNewAutomationModalOpen(false);
    resetForm();
  };

  const handleCreateAutomation = () => {
    createAutomation({
      variables: {
        input: {
          title:
            newAutomationName ||
            `PhantomBuster - ${user?.firstname} ${user?.lastname}`,
          apiKey: newAutomationKey,
          type: 'phantombuster',
        },
      },
      onError: (error) => {
        notifications.error(error.message);
      },
    });
    setNewAutomationModalOpen(false);
    resetForm();
  };

  const handleDeleteAutomation = () => {
    if (!selectedAutomationId) {
      return;
    }
    deleteAutomation({
      variables: {
        input: {
          id: selectedAutomationId,
        },
      },
    });

    setSelectedAutomationId(null);
  };

  const selectedAutomation = useMemo(
    () =>
      _.findWhere(externalAutomationServices, {
        id: selectedAutomationId || '',
      }),
    [externalAutomationServices, selectedAutomationId],
  );

  return (
    <>
      <div className='section visible'>
        <h2>{t('settings.automations.phantomBuster.title')}</h2>
        <AutomationIntegrationsTable
          service='phantomBuster'
          automations={externalAutomationServices}
          onDelete={({ automationId }: { automationId: string }) => {
            setSelectedAutomationId(automationId);
            setDeletionModalOpen(true);
          }}
          onEdit={({ automationId }: { automationId: string }) => {
            setSelectedAutomationId(automationId);
            setNewAutomationModalOpen(true);
          }}
        />
        <div className='input-container'>
          <GenericButton
            onClick={() => {
              setSelectedAutomationId(null);
              setNewAutomationModalOpen(true);
            }}
          >
            <Plus />
            {t('settings.automations.phantomBuster.create')}
          </GenericButton>
        </div>
      </div>
      <GenericModal
        size='tiny'
        open={newAutomationModalOpen}
        closeOnDimmerClick
        closeOnEscape
        onClose={() => {
          setSelectedAutomationId(null);
          setNewAutomationKey('');
          setNewAutomationName('');
          setEditApiKey(false);
        }}
      >
        <Modal.Header>
          {t('settings.automations.phantomBuster.creationModal.title')}
        </Modal.Header>
        <Modal.Content>
          <div className={styles.modalContent}>
            <div>
              <div className={styles.inputLabel}>
                {t(
                  'settings.automations.phantomBuster.creationModal.form.title',
                )}
              </div>
              <div className='input-element'>
                <GenericTextInput
                  fluid
                  value={
                    newAutomationName ||
                    selectedAutomation?.title ||
                    `PhantomBuster - ${user?.firstname} ${user?.lastname}`
                  }
                  onValue={(newTitle) => handleChangeTitle(newTitle)}
                  placeholder={t(
                    'settings.automations.phantomBuster.creationModal.form.titlePlaceholder',
                  )}
                  autoFocus
                />
              </div>
            </div>

            {selectedAutomationId && !editApiKey ? (
              <span
                className={styles.editApiKey}
                onClick={() => setEditApiKey(true)}
              >
                {t(
                  'settings.automations.phantomBuster.creationModal.form.editApiKey',
                )}
              </span>
            ) : (
              <div>
                <div className={styles.inputLabel}>
                  {t(
                    'settings.automations.phantomBuster.creationModal.form.APIKey',
                  )}
                </div>
                <div className='input-element'>
                  <GenericTextInput
                    fluid
                    value={newAutomationKey}
                    onValue={(newKey) => handleChangeKey(newKey)}
                    placeholder={t(
                      'settings.automations.phantomBuster.creationModal.form.APIKeyPlaceholder',
                    )}
                    autoFocus
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <GenericButton
            primacy='secondary'
            size='big'
            onClick={() => {
              setNewAutomationModalOpen(false);
              resetForm();
            }}
          >
            {t('common.cancel')}
          </GenericButton>
          <GenericButton
            primacy='primary'
            color='blue'
            onClick={() =>
              selectedAutomationId
                ? handleUpdateAutomation()
                : handleCreateAutomation()
            }
            size='big'
            disabled={false}
          >
            {t(
              'settings.automations.phantomBuster.creationModal.actions.connect',
            )}
          </GenericButton>
        </Modal.Actions>
      </GenericModal>
      <Modal
        open={deletionModalOpen}
        onClose={() => {
          resetForm();
        }}
        closeOnEscape
        closeOnDimmerClick
        closeIcon
        size='tiny'
      >
        <Modal.Header>
          {t('settings.automations.phantomBuster.deletionModal.title')}
        </Modal.Header>
        <Modal.Actions>
          <div className={styles.modalActions}>
            <GenericButton
              primacy='secondary'
              size='big'
              onClick={() => {
                setSelectedAutomationId(null);
                setDeletionModalOpen(false);
              }}
            >
              {t('common.cancel')}
            </GenericButton>
            <GenericButton
              size='big'
              onClick={() => {
                handleDeleteAutomation();
                setDeletionModalOpen(false);
              }}
            >
              {t('common.delete')}
            </GenericButton>
          </div>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default AutomationIntegration;
