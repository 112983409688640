import moment from 'moment';
import _ from 'underscore';
import { DailyEvent } from '@/types/statistics/dailyEvent';
import { NumberRecord } from '@/types/statistics/stats';
import { Frequency } from './components/GraphWrapper/types';
import { TimeSpan } from './components/RevealAnalyticsHeader/RevealAnalyticsHeader';
import { dateRangeDates } from './utils';

export const initialDateRange = dateRangeDates({
  dateRange: 'allTime',
}) as TimeSpan;

interface DatedEvent {
  day: string;
}

const isEventInDateRange = (dateRange: TimeSpan) => <T extends DatedEvent>(
  event: T,
): boolean => {
  if (!event) {
    return false;
  }
  const { startDate, endDate } = dateRange;
  const eventDate = moment(event.day);
  return startDate <= eventDate && eventDate <= endDate;
};

export const filterEventsByDateRange = <T extends DatedEvent>(
  dateRange: TimeSpan,
  events: T[],
): T[] => {
  return _.filter(events, isEventInDateRange(dateRange));
};

const startOfPeriod = (
  frequency: Frequency,
  dayMoment: moment.Moment,
): moment.Moment => {
  switch (frequency) {
    case 'day': {
      return dayMoment.startOf('day');
    }
    case 'week': {
      return dayMoment.startOf('isoWeek');
    }
    case 'month': {
      return dayMoment.startOf('month');
    }
    case 'quarter': {
      return dayMoment.startOf('quarter');
    }
    case 'year': {
      return dayMoment.startOf('year');
    }
    default:
      throw new Error(`Invalid frequency: ${frequency}`);
  }
};

type GroupedDatedEvents<T extends DatedEvent> = Record<string, T[]>;

const groupEventsByFrequency = <T extends DatedEvent>(
  frequency: Frequency,
  events: T[],
): GroupedDatedEvents<T> => {
  const groupedEvents: GroupedDatedEvents<T> = {};
  _.each(events, (event) => {
    const dayMoment = moment(event.day);
    const startDayOfPeriod = startOfPeriod(frequency, dayMoment).format(
      'YYYY-MM-DD',
    );
    groupedEvents[startDayOfPeriod] = groupedEvents[startDayOfPeriod] || [];
    groupedEvents[startDayOfPeriod].push(event);
  });
  return groupedEvents;
};

const aggregateEvents = (events: DailyEvent[]): NumberRecord =>
  _.reduce(
    events,
    (aggregator, current) => {
      const result = { ...aggregator };
      _.each(_.keys(current.values), (key) => {
        result[key] = (result[key] || 0) + current.values[key];
      });
      return result;
    },
    {} as NumberRecord,
  );

const getTimestamp = (day: string): number => moment(day).valueOf();

interface TimestampedEvent {
  timestamp: number;
  values: NumberRecord;
}

const aggregateEventsByFrequency = (
  frequency: Frequency,
  events: DailyEvent[],
): TimestampedEvent[] => {
  const groupedEvents = groupEventsByFrequency(frequency, events);
  const aggregatedMap = _.mapObject(groupedEvents, aggregateEvents);
  const aggregatedArray = _.map(_.keys(aggregatedMap), (day) => ({
    values: aggregatedMap[day],
    timestamp: getTimestamp(day),
  }));
  return aggregatedArray;
};

const flattenEventForRecharts = (event: TimestampedEvent): unknown => ({
  ...event.values,
  timestamp: event.timestamp,
});

export const getRechartsData = (
  dateRange: TimeSpan,
  frequency: Frequency,
  events: DailyEvent[],
): unknown[] => {
  const filteredEvents = filterEventsByDateRange(dateRange, events);
  const aggregatedEvents = aggregateEventsByFrequency(
    frequency,
    filteredEvents,
  );
  const rechartsEvents = _.map(aggregatedEvents, flattenEventForRecharts);
  return rechartsEvents;
};
