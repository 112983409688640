/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  ContentBlock,
  ContentState,
  EditorState,
  RichUtils,
  Modifier,
  SelectionState,
  genKey,
  getDefaultKeyBinding,
  KeyBindingUtil,
  AtomicBlockUtils,
} from 'draft-js';

import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createImagePlugin from '@draft-js-plugins/image';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createFocusPlugin from '@draft-js-plugins/focus';
import { List } from 'immutable';
import { withRouter, NavLink } from 'react-router-dom';
import _, { compose } from 'underscore';
import { Icon, Menu } from 'semantic-ui-react';
import clsx from 'classnames';

import CharacterCount from '@/components/CharacterCount';
import {
  getMergeTagsSimplifiedConditionVariable,
  getOldMergeTagsKeys,
  SNIPPET_TYPES,
} from '@/common/mergeTags/utils';
import withClientProfileCustomFields from '@/hocs/clients/withClientProfileCustomFields';
import withClientPermissions from '@/hocs/clients/withClientPermissions';
import { getRandomString } from '@/common';
import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import PlaceholderVariableModal from '@/containers/Editor/PlaceholderVariableModal/PlaceholderVariableModal';
import withSnippets from '@/hocs/snippets/withSnippets';
import GenericTooltip from '@/components/Common/GenericTooltip';
import { getActionType } from '@/common/constants/taskTypes';

import withClientMissionCustomFields from '@/hocs/clients/withClientMissionCustomFields';
import Signature from './Signature';
import Link from './Link';
import AutomationPlaceholder from './placeholders/AutomationPlaceholder';
import NewTemplate from '../Parameters/Templates/modals/NewTemplate';
import {
  findPlaceholdersEntities,
  findLinkEntities,
  isURL,
  draftJSContentToHTML,
  getHTMLToDraftJSContentConverter,
} from './helpers';
import EditorToolsMenu from './EditorToolsMenu';
import { FONT_SIZE_STYLES, STYLE_MAP } from './constants';
import MergeTagsSelector from './MergeTagsSelector/MergeTagsSelector';
import InsertImageModal from './InsertImageModal/InsertImageModal';
import SmartVariableWithPreviewModal, {
  SMART_VARIABLES,
} from './VariableExamplesPreviewModal/SmartVariableWithPreviewModal';
import UnsyncFragmentModal from './UnsyncFragmentModal/UnsyncFragmentModal';

import './editor.css';
import '@draft-js-plugins/focus/lib/plugin.css';

const getFullUrl = (url) =>
  url.match(/^https?:\/\//i) ? url : `https://${url}`;

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();

const imagePlugin = createImagePlugin({
  decorator: composeDecorators(
    resizeablePlugin.decorator,
    focusPlugin.decorator,
  ),
});
const plugins = [focusPlugin, resizeablePlugin, imagePlugin];

const NEWLINE_REGEX = /\n/g;
const MAX_LENGTH = 200;

class MergeTagsAutomationEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    const initialEditorState = this.initEditor({ props, field: 'body' });
    const initialSubjectEditorState = this.initEditor({
      props,
      field: 'subject',
    });

    this.state = {
      needUpdate: false,
      editorVersion: 0,
      editorState: initialEditorState,
      editorStateSubject: initialSubjectEditorState,
      decorators: this.initDecorators({ props: this.props }),
      showURLInput: false,
      urlValue: '',
      activeField: 'body',
      hasFocus: false,
      newTemplateModalOpen: false,
      isReadOnly: props.readOnly || false,
      placeholderVariableModal: {
        open: false,
        id: '',
        name: '',
      },
      variableExamplesPreviewModal: {
        open: false,
        variable: null,
      },
      editSmartVariableModal: {
        open: false,
        variable: null,
      },
      insertImageModalOpen: false,
      mergeTagsSelectorOpen: false,
      unsyncFragmentModal: {
        open: false,
        clientFragment: null,
      },
    };
  }

  setIsReadOnly = (isReadOnly) => {
    this.setState({ isReadOnly });
  };

  onChange = (editorState, cb) => {
    this.setState({ editorState, activeField: 'body' }, () => {
      this.handleChange();
      cb && cb();
    });
  };

  onChangeSubject = (editorState, cb) => {
    const editorStateInline = this.getInlineEditorState(editorState);
    this.setState({ editorStateSubject: editorStateInline }, () => {
      this.handleChangeSubject();
      cb && cb();
    });
  };

  onSnippetAdded = (newSnippet, field) => {
    if (!newSnippet) {
      return;
    }
    let newContent = '';
    if (field) {
      newContent = this.handleExportTemplate({ field });
    }
    this.props.onSnippetAdded({ newSnippet, field, newContent });
  };

  getEntityKeyInSelection = (type) => {
    if (this.state.activeField === 'body') {
      const { editorState } = this.state;
      const selection = editorState.getSelection();
      const contentState = editorState.getCurrentContent();
      const startKey = selection.getStartKey();
      const startOffset = selection.getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const entityKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      if (!entityKey) return null;

      const entity = contentState.getEntity(entityKey);
      if (entity.getType() !== type) return null;
      return entityKey;
    }
    return null;
  };

  getInlineEditorState = (editorState) => {
    const blocks = editorState.getCurrentContent().getBlocksAsArray();

    // If we have more than one block, compress them
    if (blocks.length > 1) {
      editorState = this.condenseBlocks(editorState, blocks, {});
    } else {
      // We only have one content block
      let contentBlock = blocks[0];
      let text = contentBlock.getText();
      const characterList = contentBlock.getCharacterList();
      // let hasEntitiesToStrip = options.stripEntities && characterListhasEntities(characterList)

      if (NEWLINE_REGEX.test(text)) {
        // || hasEntitiesToStrip
        // Replace the text stripped of its newlines. Note that we replace
        // one '\n' with one ' ' so we don't need to modify the characterList
        text = this.replaceNewlines(text);

        // Create a new content block based on the old one
        contentBlock = new ContentBlock({
          key: genKey(),
          text,
          type: 'unstyled',
          characterList,
          depth: 0,
        });

        // Update the editor state with the compressed version
        // const selection = editorState.getSelection()
        const newContentState = ContentState.createFromBlockArray([
          contentBlock,
        ]);

        // Create the new state as an undoable action
        editorState = EditorState.push(
          editorState,
          newContentState,
          'insert-characters',
        );
      }
    }
    return editorState;
  };

  initEditor = ({ props, field }) => {
    const subjectDefaultValue = props.defaultValueSubject
      ? props.defaultValueSubject
      : null;
    const bodyDefaultValue = props.defaultValue ? props.defaultValue : null;
    const value = field === 'subject' ? subjectDefaultValue : bodyDefaultValue;

    const contentState = value
      ? getHTMLToDraftJSContentConverter({
          snippets: props.snippets,
          clientSnippets: props.clientSnippets,
          clientCustomFields: props.clientCustomFields,
          clientMissionCustomFields: props.clientMissionCustomFields,
          t: props.t,
        })(value)
      : null;

    return contentState
      ? EditorState.createWithContent(contentState)
      : EditorState.createEmpty();
  };

  initDecorators = ({ props }) => {
    return [
      {
        strategy: findPlaceholdersEntities,
        component: (componentProps) => (
          <AutomationPlaceholder
            {...componentProps}
            snippets={props.snippets}
            clientSnippets={this.props.clientSnippets}
            clientCustomFields={this.props.clientCustomFields}
            clientMissionCustomFields={this.props.clientMissionCustomFields}
            setIsReadOnly={this.setIsReadOnly}
            onEditSmartVariable={(snippet) =>
              this.setState({
                editSmartVariableModal: {
                  open: true,
                  variable: snippet,
                },
              })
            }
            onUpdate={props.onSnippetUpdated}
            readOnly={props.readOnly}
            handleUnsyncFragment={(clientFragment) =>
              this.setState({
                unsyncFragmentModal: { open: true, clientFragment },
              })
            }
          />
        ),
        props: {
          highlight: true,
        },
      },
      {
        strategy: findLinkEntities,
        component: Link,
      },
    ];
  };

  componentWillReceiveProps(nextProps) {
    const needUpdate =
      this.props.version !== nextProps.version ||
      this.state.needUpdate ||
      this.props.readOnly ||
      (this.props.mergeTagsSubId !== 'sequence' &&
        this.props.snippets?.length !== nextProps.snippets?.length) ||
      nextProps?.clientSnippets?.length > this.props?.clientSnippets?.length;

    if (needUpdate) {
      this.setState((previousState) => ({
        needUpdate: false,
        isReadOnly: !!this.props.readOnly,
        editorState: this.initEditor({ props: nextProps, field: 'body' }),
        editorStateSubject: this.initEditor({
          props: nextProps,
          field: 'subject',
        }),
        decorators: this.initDecorators({ props: nextProps }),
        editorVersion: previousState.editorVersion + 1,
      }));
    }

    if (nextProps.focusId !== this.state.focusId) {
      this.setState({ focusId: nextProps.focusId }, () => {
        this.focus();
      });
    }

    if (
      nextProps.onChangeTriggerSubjectFocus !==
      this.props.onChangeTriggerSubjectFocus
    ) {
      this.focusSubject();
    }
  }

  condenseBlocks = (editorState, blocks) => {
    // , options
    blocks = blocks || editorState.getCurrentContent().getBlocksAsArray();
    let text = List();
    let characterList = List();

    // Gather all the text/characterList and concat them
    blocks.forEach((block) => {
      // Atomic blocks should be ignored (stripped)
      if (block.getType() !== 'atomic') {
        text = text.push(this.replaceNewlines(block.getText()));
        characterList = characterList.concat(block.getCharacterList());
      }
    });

    // Create a new content block
    const contentBlock = new ContentBlock({
      key: genKey(),
      text: text.join(''),
      type: 'unstyled',
      characterList,
      depth: 0,
    });

    // Update the editor state with the compressed version
    const newContentState = ContentState.createFromBlockArray([contentBlock]);
    // Create the new state as an undoable action
    editorState = EditorState.push(
      editorState,
      newContentState,
      'remove-range',
    );
    // Move the selection to the end
    return EditorState.moveFocusToEnd(editorState);
  };

  replaceNewlines = (str, replacement = ' ') =>
    str.replace(NEWLINE_REGEX, replacement);

  handleChange = () => {
    const html = this.handleExportTemplate({ field: 'body' });
    this.props.onChange && this.props.onChange(html);
  };

  handleChangeSubject = () => {
    const html = this.handleExportTemplate({ field: 'subject' });
    this.props.onChangeSubject && this.props.onChangeSubject(html);
  };

  handleEditorRef = (editorRef) => {
    this.setState({ editorRef });
  };

  handleEditorRefSubject = (editorRef) => {
    this.setState({ editorRefSubject: editorRef });
  };

  handleUrlRef = (urlRef) => this.setState({ urlRef });

  mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 75 && KeyBindingUtil.hasCommandModifier(e)) {
      // cmd + K on mac or ctrl + K on win / linux
      return 'hyperlink';
    }
    return getDefaultKeyBinding(e);
  };

  handleKeyCommand = (command, editorState) => {
    if (command === 'hyperlink') {
      this.handleAddLink();
      return 'handled';
    }
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState && command !== 'code') {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  inlineStyleHandler = (style) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.state.activeField === 'body') {
      let newEditorState = this.state.editorState;
      const currentStyle = this.state.editorState.getCurrentInlineStyle();
      if (style.includes('FONT_SIZE_')) {
        _.forEach(FONT_SIZE_STYLES, (fontSizeStyle) => {
          if (style === fontSizeStyle) {
            return;
          }
          if (currentStyle.has(fontSizeStyle)) {
            newEditorState = RichUtils.toggleInlineStyle(
              this.state.editorState,
              fontSizeStyle,
            );
          }
        });
      }
      this.onChange(
        RichUtils.toggleInlineStyle(newEditorState, style),
        this.focus,
      );
    }
  };

  blockTypeHandler = (blockType) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.state.activeField === 'body') {
      this.onChange(
        RichUtils.toggleBlockType(this.state.editorState, blockType),
        this.focus,
      );
    }
  };

  handleAddLink = (e) => {
    e?.stopPropagation(); // eslint-disable-line no-unused-expressions
    e?.preventDefault(); // eslint-disable-line no-unused-expressions
    if (this.state.activeField === 'body') {
      const { editorState } = this.state;
      const contentState = editorState.getCurrentContent();
      const linkEntityKey = this.getEntityKeyInSelection('LINK');
      let url = '';
      if (linkEntityKey) {
        url = contentState.getEntity(linkEntityKey).getData().url;
      } else {
        const selection = editorState.getSelection();
        const startKey = selection.getStartKey();
        const startOffset = selection.getStartOffset();
        const endOffset = selection.getEndOffset();
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
        const selectedText = blockWithLinkAtBeginning
          .getText()
          .slice(startOffset, endOffset);
        if (isURL(selectedText)) url = selectedText;
      }
      this.setState(
        {
          showURLInput: true,
          urlValue: url,
        },
        () => {
          setTimeout(() => this.state.urlRef && this.state.urlRef.focus(), 0);
        },
      );
    }
  };

  handleLinkKeyPress = (ev) =>
    ev.charCode === 13 || ev.key === 'Enter' ? this.insertLink() : null;

  handleLinkChange = (ev, { value }) => this.setState({ urlValue: value });

  getOptionKeyAndTextAndSnippet = (option) => {
    const { key, label, type } = option;
    const {
      profile,
      mergeTagsSubId,
      t,
      clientCustomFields,
      clientMissionCustomFields,
      snippets,
      clientSnippets,
    } = this.props;

    const isOldMergeTag = _.findWhere(getOldMergeTagsKeys(t), { key });

    if (isOldMergeTag) {
      return {
        key,
        text: profile?.resumeData?.[key] ?? label,
        snippet: null,
        isOldMergeTag,
      };
    }

    const uniqueKey = `${key}_${mergeTagsSubId}_${getRandomString(10)}`;
    // try to find snippets in base snippets, custom fields, and client fragment snippets
    const snippetsFromText = MergeTagsService.getSnippetsFromText({
      text: `{{${uniqueKey}}}`,
      type,
      customFields: clientCustomFields,
      missionCustomFields: clientMissionCustomFields,
      clientSnippets,
      snippetsToSearch: snippets,
      t,
    });

    if (!_.isEmpty(snippetsFromText)) {
      const firstSnippet = _.first(snippetsFromText);
      return {
        key: uniqueKey,
        text:
          firstSnippet.type === SNIPPET_TYPES.PLACEHOLDER
            ? label
            : firstSnippet.name,
        snippet: firstSnippet,
        isOldMergeTag,
      };
    }
    return {
      key: uniqueKey,
      text: label,
      snippet: null,
      isOldMergeTag,
    };
  };

  handleVariableInsertion = ({
    key,
    label,
    type,
    isClientSnippet,
    simplifiedConditionVariable,
  }) => {
    const { clientSnippets, mergeTagsSubId } = this.props;

    if (simplifiedConditionVariable && simplifiedConditionVariable.key) {
      const id = `${key}_${mergeTagsSubId}_${getRandomString(10)}`;
      this.setState({
        variableExamplesPreviewModal: {
          open: true,
          variable: getMergeTagsSimplifiedConditionVariable({
            id,
            fieldId: simplifiedConditionVariable.key,
            name: label,
          }),
        },
      });
      return;
    }

    let snippetToAdd = null;

    const {
      key: finalKey,
      text,
      snippet: snippetFind,
      isOldMergeTag,
    } = this.getOptionKeyAndTextAndSnippet({ key, label, type });

    snippetToAdd = snippetFind || null;

    if (_.includes(SMART_VARIABLES, snippetToAdd?.type) && !isClientSnippet) {
      this.setState({
        variableExamplesPreviewModal: {
          open: true,
          variable:
            snippetToAdd.type === SNIPPET_TYPES.CONDITIONS_CHAINING
              ? {
                  ...snippetToAdd,
                  ifStatements: [],
                }
              : snippetToAdd,
        },
      });
      return;
    }

    if (snippetToAdd?.type === SNIPPET_TYPES.PLACEHOLDER) {
      if (label === 'Placeholder') {
        this.setState({
          placeholderVariableModal: {
            open: true,
            id: key,
            name: 'Placeholder',
          },
        });
        return;
      }
      snippetToAdd = { ...snippetToAdd, name: label };
    }

    if (
      snippetToAdd?.type === SNIPPET_TYPES.FRAGMENT ||
      snippetToAdd?.type === SNIPPET_TYPES.SELECT ||
      snippetToAdd?.type === SNIPPET_TYPES.CONDITIONS_CHAINING ||
      snippetToAdd?.type === SNIPPET_TYPES.AI_TOKEN
    ) {
      const clientSnippet = _.find(
        clientSnippets,
        (cf) => cf.id === snippetToAdd.id.split('_')[0],
      );
      if (clientSnippet) {
        snippetToAdd = { ...clientSnippet, id: finalKey };
        if (snippetToAdd.type === SNIPPET_TYPES.FRAGMENT) {
          const syncedFragmentVariable = {
            id: finalKey,
            type: SNIPPET_TYPES.SYNCED_FRAGMENT,
            clientDynamicVariableId: clientSnippet.id,
            name: clientSnippet.name,
            ...(clientSnippet.author && { author: clientSnippet.author }),
          };
          return this.insertVariable({
            variable: syncedFragmentVariable,
            key: finalKey,
            text,
            isOldMergeTag: false,
          });
        }
      }
    }

    return this.insertVariable({
      variable: snippetToAdd,
      key: finalKey,
      text,
      isOldMergeTag,
    });
  };

  insertVariable = ({ variable, key, text, isOldMergeTag }) => {
    const { mergeTagsSubId } = this.props;
    const editorState =
      this.state.activeField === 'body'
        ? this.state.editorState
        : this.state.editorStateSubject;
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const contentStateWithEntity = contentState.createEntity(
      'PLACEHOLDER',
      'IMMUTABLE',
      { key },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const textWithEntity = Modifier.replaceText(
      contentStateWithEntity,
      selectionState,
      text,
      null,
      entityKey,
    );
    const editorStateWithEntity = EditorState.push(
      editorState,
      textWithEntity,
      'insert-characters',
    );
    const newEditorState = EditorState.forceSelection(
      editorStateWithEntity,
      textWithEntity.getSelectionAfter(),
    );
    const editorStateField =
      this.state.activeField === 'body' ? 'editorState' : 'editorStateSubject';
    this.setState(
      {
        [editorStateField]: newEditorState,
        needUpdate: mergeTagsSubId === 'template' || isOldMergeTag,
        isReadOnly: true,
      },
      () =>
        this.state.activeField === 'body'
          ? this.onChange(newEditorState, () =>
              this.onSnippetAdded(variable, 'body'),
            )
          : this.onChangeSubject(
              newEditorState,
              _.debounce(() => this.onSnippetAdded(variable, 'subject'), 400),
            ),
    );
  };

  handleUnsyncFragment = () => {
    const {
      unsyncFragmentModal: { clientFragment },
    } = this.state;
    const { text, snippets: fragmentSnippets } = clientFragment;
    const {
      onChange,
      onChangeSubject,
      t,
      onSnippetFragmentInserted,
    } = this.props;

    const { subject, body, snippets } = MergeTagsService.replaceAllIds({
      subject: '',
      body: text,
      subId: 'sequence',
      snippets: fragmentSnippets,
      t,
    });
    const currentHtml = this.handleExportTemplate({
      field: this.state.activeField,
    });
    // Remove the synced fragment id in text
    // Also replace the variable surrounded div, to not create extra lines breaks
    const replacedValue = currentHtml.replace(
      /<div>({{[^{}]+}})<\/div>|({{[^{}]+}})/gm,
      (match) => {
        let syncedFragmentKey = '';
        match.replace(/\{{([^{}]*)}}/g, (subMatch, subKey) => {
          syncedFragmentKey = subKey;
        });
        if (syncedFragmentKey.split('_')[0] === clientFragment.id) {
          const fragmentText =
            this.state.activeField === 'body' ? body : subject;
          return fragmentText;
        }
        return match;
      },
    );

    this.setState(
      {
        unsyncFragmentModal: {
          open: false,
          clientFragment: null,
        },
        needUpdate: true,
      },
      () => {
        this.state.activeField === 'body'
          ? onChange(replacedValue)
          : onChangeSubject(replacedValue);

        if (onSnippetFragmentInserted) {
          onSnippetFragmentInserted({
            newSnippets: snippets,
            field: this.state.activeField,
            newContent: replacedValue,
          });
        }
      },
    );
  };

  insertMergeTagsExamplePreviewVariable = (variable) => {
    const { id, name, type } = variable;

    let variableName = name;
    if (type === SNIPPET_TYPES.K_LAST_COMPANIES) {
      variableName = variableName.replace('K', variable.number);
    }

    let key = id;
    if (type === SNIPPET_TYPES.CONDITIONS_CHAINING) {
      key = `${getRandomString(6)}_${id}`;
    }

    return this.insertVariable({
      variable: { ...variable, name: variableName, id: key },
      key,
      text: name,
      isOldMergeTag: false,
    });
  };

  insertImage = async ({ url }) => {
    const key = `image-${getRandomString(10)}`;
    const image = {
      key,
      src: url,
      alignment: 'default',
      width: 40,
    };
    const { editorState } = this.state;

    const contentStateWithEntity = editorState
      .getCurrentContent()
      .createEntity('IMAGE', 'IMMUTABLE', {
        ...image,
      });

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      ' ',
    );

    this.setState(
      {
        editorState: newEditorState,
      },
      () => {
        this.onChange(newEditorState);
      },
    );
  };

  insertLink = () => {
    if (this.state.activeField === 'body') {
      const { editorState, urlValue } = this.state;
      let entityKey = this.getEntityKeyInSelection('LINK');
      const contentState = editorState.getCurrentContent();

      let newEditorState = null;
      const newUrlValue = getFullUrl(urlValue);
      if (entityKey) {
        newEditorState = contentState.replaceEntityData(entityKey, {
          url: newUrlValue,
        });
        return this.setState({ showURLInput: false, urlValue: '' });
      }
      const contentStateWithEntity = contentState.createEntity(
        'LINK',
        'MUTABLE',
        { url: newUrlValue },
      );
      entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      });

      this.setState(
        {
          editorState: RichUtils.toggleLink(
            newEditorState,
            newEditorState.getSelection(),
            entityKey,
          ),
          showURLInput: false,
          urlValue: '',
        },
        this.focus,
      );
    }
  };

  handleExportTemplate = ({ field }) => {
    if (field === 'body') {
      return draftJSContentToHTML(this.state.editorState.getCurrentContent());
    }
    let editorState = this.state.editorStateSubject;
    const contentState = this.state.editorStateSubject.getCurrentContent();
    contentState.getBlockMap().forEach((contentBlock) => {
      const blockKey = contentBlock.getKey();
      const entities = [];

      // Find entities keys in current block
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        if (entityKey === null) return false;
        if (contentState.getEntity(entityKey).getType() === 'PLACEHOLDER') {
          entities.push(entityKey);
        }
      });

      // For each entity, find the position in the updated editor state
      entities.forEach((entityKey) => {
        const newContentBlock = editorState
          .getCurrentContent()
          .getBlockForKey(blockKey);
        newContentBlock.findEntityRanges(
          (character) => character.getEntity() === entityKey,
          (start, end) => {
            const selectionState = new SelectionState({
              anchorKey: blockKey,
              anchorOffset: start,
              focusKey: blockKey,
              focusOffset: end,
            });
            const entity = contentState.getEntity(entityKey);
            const textWithEntity = Modifier.replaceText(
              editorState.getCurrentContent(),
              selectionState,
              `{{${entity.data.key}}}`,
              null,
              entityKey,
            );
            editorState = EditorState.push(
              editorState,
              textWithEntity,
              'insert-characters',
            );
          },
        );
      });
    });

    // Export the update editor state
    return editorState.getCurrentContent().getPlainText();
  };

  handleBeforeInput = () => {
    const currentContent = this.state.editorStateSubject.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;

    if (currentContentLength > MAX_LENGTH - 1) {
      return 'handled';
    }
  };

  handlePastedText = (pastedText) => {
    const currentContent = this.state.editorStateSubject.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;

    if (currentContentLength + pastedText.length > MAX_LENGTH) {
      return 'handled';
    }
  };

  focus = () => {
    this.state.editorRef.focus();
    this.setState({ showURLInput: false, urlValue: '', activeField: 'body' });
  };

  focusSubject = () => {
    this.state.editorRefSubject.focus();
    this.setState({
      showURLInput: false,
      urlValue: '',
      activeField: 'subject',
    });
  };

  onBlur = (e) => {
    const { currentTarget } = e;
    setTimeout(() => {
      if (!currentTarget?.contains(document.activeElement)) {
        this.setState({ hasFocus: false });
      }
    }, 0);
  };

  onFocus = (e) => {
    const { currentTarget } = e;
    setTimeout(() => {
      if (currentTarget.contains(document.activeElement)) {
        this.setState({ hasFocus: true });
      }
    }, 0);
  };

  onChangeTemplate = ({ value }) => {
    if (this.props.onSelectTemplate) {
      this.props.onSelectTemplate({ templateId: value });
    }
  };

  onSubmitPlaceholderVariableModal = (updatedName) => {
    const { id } = this.state.placeholderVariableModal;
    this.handleVariableInsertion({
      label: updatedName,
      key: id,
      type: SNIPPET_TYPES.PLACEHOLDER,
    });
  };

  renderSecondaryMenu() {
    const {
      applyTemplateDropdown,
      displayImageInsertion,
      withoutEditorToolsMenu,
      withClientFragmentSnippets,
    } = this.props;

    const selection = this.state.editorState.getSelection();
    const currentStyle = this.state.editorState.getCurrentInlineStyle();
    const blockType = this.state.editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return (
      <Menu
        secondary
        style={{ margin: 0, flexWrap: 'wrap', justifyContent: 'space-between' }}
      >
        {!withoutEditorToolsMenu && (
          <EditorToolsMenu
            showURLInput={this.state.showURLInput}
            urlValue={this.state.urlValue}
            handleUrlRef={this.handleUrlRef}
            handleLinkChange={this.handleLinkChange}
            handleLinkKeyPress={this.handleLinkKeyPress}
            currentStyle={currentStyle}
            insertLink={this.insertLink}
            inlineStyleHandler={this.inlineStyleHandler}
            currentBlockType={blockType}
            blockTypeHandler={this.blockTypeHandler}
            getEntityKeyInSelection={this.getEntityKeyInSelection}
            handleAddLink={this.handleAddLink}
            displayImageInsertion={displayImageInsertion}
            handleClickImage={() =>
              this.setState({ insertImageModalOpen: true })
            }
          />
        )}

        <Menu.Menu>
          <div className='merge-tags-selector-trigger'>
            {this.state.mergeTagsSelectorOpen && (
              <MergeTagsSelector
                clientId={this.props.clientId}
                onVariableSelected={this.handleVariableInsertion}
                currentSequenceVariables={this.props.snippets}
                withConditionsChainingVariable={
                  this.props.withConditionsChainingVariable
                }
                onClose={() => {
                  this.setState({ mergeTagsSelectorOpen: false });
                }}
                withClientFragmentSnippets={withClientFragmentSnippets}
              />
            )}
            <span
              className={clsx('choose-template-trigger', 'trigger')}
              onClick={() => this.setState({ mergeTagsSelectorOpen: true })}
              aria-hidden='true'
            >
              {this.props.t('editor.useVariable')}
              <Icon
                className='merge-tags-trigger-icon'
                size='small'
                name='caret down'
              />
            </span>
          </div>
        </Menu.Menu>
        <Menu.Menu>{applyTemplateDropdown}</Menu.Menu>
        <CharacterCount
          text={this.state.editorState.getCurrentContent().getPlainText('')}
          type={getActionType(this.props.action)}
        />
      </Menu>
    );
  }

  render() {
    const {
      mergeTagsSubId,
      withMinHeight,
      editorWrapperClassName,
      withBorderRadius,
    } = this.props;
    const {
      editorState,
      hasFocus,
      isReadOnly,
      placeholderVariableModal,
      variableExamplesPreviewModal,
      editSmartVariableModal,
      decorators,
      insertImageModalOpen,
      unsyncFragmentModal,
    } = this.state;
    let className = 'RichEditor-editor';
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (
        contentState.getBlockMap() &&
        contentState.getBlockMap().first() &&
        contentState
          .getBlockMap()
          .first()
          .getType() !== 'unstyled'
      ) {
        className += ' RichEditor-hidePlaceholder';
      }
    }
    return (
      <div
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        className={clsx(editorWrapperClassName && editorWrapperClassName)}
      >
        <div
          className={clsx(
            'editor-container',
            withBorderRadius && 'radius',
            hasFocus && 'focus',
          )}
        >
          {this.props.fields === 'double' ? (
            <div
              className={`email-subject ${className}`}
              onClick={this.focusSubject}
              style={{
                paddingBottom: 8,
                marginBottom: 15,
                borderBottom: '1px solid whitesmoke',
              }}
            >
              <Editor
                key={this.state.editorVersion}
                ref={this.handleEditorRefSubject}
                editorState={this.state.editorStateSubject}
                onChange={this.onChangeSubject}
                placeholder={this.props.placeholderSubject}
                handleBeforeInput={this.handleBeforeInput}
                handlePastedText={this.handlePastedText}
                readOnly={isReadOnly}
                decorators={decorators}
                spellCheck
              />
            </div>
          ) : null}
          <div
            className={clsx(
              'body-and-signature position-relative',
              withMinHeight === false ? 'body-and-signature-small' : '',
              mergeTagsSubId === 'settings' ? 'editor-fragment' : '',
            )}
          >
            <div className={`main-body ${className}`}>
              <Editor
                key={this.state.editorVersion}
                ref={this.handleEditorRef}
                decorators={decorators}
                plugins={plugins}
                editorState={this.state.editorState}
                keyBindingFn={this.mapKeyToEditorCommand}
                handleKeyCommand={this.handleKeyCommand}
                onChange={this.onChange}
                placeholder={this.props.placeholder}
                readOnly={isReadOnly}
                customStyleMap={STYLE_MAP}
                spellCheck
              />
            </div>
            {this.props.signature ? (
              <GenericTooltip
                trigger={
                  <div className='static-signature'>
                    <Signature content={this.props.signature} />
                  </div>
                }
                hoverable
              >
                {this.props.t('editor.signatureInstructions')}&nbsp;
                <NavLink
                  style={{
                    color: 'white',
                    textDecoration: 'underline',
                    padding: '0px 3px',
                  }}
                  to={`/client/${this.props.clientId}/email`}
                  onClick={this.props.onNavigate}
                >
                  {this.props.t('editor.settings')}
                </NavLink>
              </GenericTooltip>
            ) : null}
          </div>
          {!this.props.readOnly && (hasFocus || this.props.alwaysShowMenu) ? (
            this.renderSecondaryMenu()
          ) : (
            <div className='empty-menu' />
          )}
        </div>
        <NewTemplate
          open={this.state.newTemplateModalOpen}
          onClose={this.closeNewTemplateModal}
          clientId={this.props.clientId}
          offerId={this.props.offerId}
          isFromOffer
          messagePosition={this.props.messagePosition}
          onPostSubmit={this.onPostSubmit}
          awaitRefetchTemplates
        />
        <PlaceholderVariableModal
          open={placeholderVariableModal.open}
          onSubmit={(name) => this.onSubmitPlaceholderVariableModal(name)}
          onClose={() => {
            this.setState({
              placeholderVariableModal: {
                open: false,
                id: '',
                name: '',
              },
            });
          }}
        />
        <SmartVariableWithPreviewModal
          open={variableExamplesPreviewModal.open}
          onSubmit={this.insertMergeTagsExamplePreviewVariable}
          exampleVariable={variableExamplesPreviewModal.variable}
          onClose={() => {
            this.setState({
              variableExamplesPreviewModal: {
                open: false,
                variable: null,
              },
            });
          }}
        />
        <SmartVariableWithPreviewModal
          open={editSmartVariableModal.open}
          onSubmit={(variable) => {
            if (variable.type === SNIPPET_TYPES.K_LAST_COMPANIES) {
              this.props.onSnippetUpdated({
                updatedSnippet: {
                  ...variable,
                  name: variable.name.replace(/\d{1}/, () => {
                    return variable.number;
                  }),
                },
              });
            } else {
              this.props.onSnippetUpdated({ updatedSnippet: variable });
            }
          }}
          exampleVariable={editSmartVariableModal.variable}
          onClose={() => {
            this.setState({
              editSmartVariableModal: {
                open: false,
                variable: null,
              },
            });
          }}
          isEditMode
        />
        <InsertImageModal
          open={insertImageModalOpen}
          handleImageInsertion={(url) => this.insertImage({ url })}
          onClose={() => this.setState({ insertImageModalOpen: false })}
        />
        <UnsyncFragmentModal
          open={unsyncFragmentModal.open}
          handleUnsyncFragment={() => this.handleUnsyncFragment()}
          onClose={() =>
            this.setState({
              unsyncFragmentModal: { open: false, clientFragment: null },
            })
          }
        />
      </div>
    );
  }

  onPostSubmit = ({ templateId }) => {
    if (templateId) {
      this.onChangeTemplate({ value: templateId });
    }
  };

  closeNewTemplateModal = () => {
    this.setState({ newTemplateModalOpen: false });
  };
}

export default compose(
  withRouter,
  withTranslation('translations'),
  withClientProfileCustomFields,
  withClientMissionCustomFields,
  withClientPermissions,
  withSnippets,
)(MergeTagsAutomationEditor);
