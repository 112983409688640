import { useCallback, useMemo, useState } from 'react';
import _ from 'underscore';
import {
  ArchivedState,
  PipelineState,
} from '@/components/PipelineSegmentation/pipelineSegmentation';
import {
  formatPipelineDetailDataPoints,
  getCurrentProfileItemsBySegment,
  getEverProfileItemsBySegment,
} from '../helpers/profileItems';
import { Segment, states } from '../helpers/segments';
import { DisplayType, PipelineDetailDataPointForCategory } from '../types';
import { ProfileDetail } from '../../StatsProfileAndMissionTable/types';

interface Filter {
  categoryId?: string;
  segment?: Segment;
}

export interface PipelineDetails {
  filter: Filter | undefined;
  applyFilter: (filter: Filter) => void;
  profileDetails: ProfileDetail[];
}

const usePipelineDetails = (
  data: PipelineDetailDataPointForCategory[] = [],
  displayType: DisplayType,
  fetchDetails?: (filters: {
    categoryIds?: string[];
    stages?: PipelineState[];
    archivedStates?: ArchivedState[];
  }) => void,
): PipelineDetails => {
  const [filter, setFilter] = useState<Filter | undefined>(undefined);

  const fetchWithFilter = useCallback(
    (newFilter: Filter) => {
      if (!fetchDetails) {
        return;
      }
      setFilter(newFilter);
      const { categoryId, segment } = newFilter;
      if (!segment) {
        return;
      }

      if (segment === ArchivedState.ARCHIVED) {
        fetchDetails({
          categoryIds: categoryId ? [categoryId] : undefined,
          archivedStates: [ArchivedState.ARCHIVED],
        });
        return;
      }

      const stages =
        displayType === 'current'
          ? [segment]
          : _.rest(states, _.indexOf(states, segment));

      fetchDetails({
        stages,
        categoryIds: categoryId ? [categoryId] : undefined,
      });
    },
    [fetchDetails, displayType],
  );

  const detailsByCategory = formatPipelineDetailDataPoints(data);

  const conversionFunction =
    displayType === 'current'
      ? getCurrentProfileItemsBySegment
      : getEverProfileItemsBySegment;

  const profileDetails = useMemo(() => {
    if (!filter?.segment) {
      return [];
    }

    const results = [] as ProfileDetail[];
    _.each(detailsByCategory, (separatedDetails, key) => {
      const [missionId, categoryId] = key.split('__');
      if (filter?.categoryId && categoryId !== filter.categoryId) {
        return;
      }
      const details = conversionFunction(separatedDetails);
      _.each(details, (profileItems, segment) => {
        if (segment !== filter.segment) {
          return;
        }

        _.each(profileItems, ({ profileId }) => {
          results.push({
            profileId,
            missionId,
          });
        });
      });
    });

    return results;
  }, [detailsByCategory, filter, conversionFunction]);

  return {
    filter,
    applyFilter: fetchWithFilter,
    profileDetails,
  };
};

export default usePipelineDetails;
