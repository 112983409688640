import _, { compose } from 'underscore';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Segment, Image, Dropdown } from 'semantic-ui-react';

import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';
import { sanitizeTypename } from '@/common/utils/apollo';
import { getRandomString } from '../../../common';
import withAddClientApplicationFormTemplate from '../../../hocs/clients/withAddClientApplicationFormTemplate';
import withUpdateClientApplicationFormTemplate from '../../../hocs/clients/withUpdateClientApplicationFormTemplate';
import withDeleteClientApplicationFormTemplate from '../../../hocs/clients/withDeleteClientApplicationFormTemplate';
import withMoveClientApplicationFormTemplateUpward from '../../../hocs/clients/withMoveClientApplicationFormTemplateUpward';
import withMoveClientApplicationFormTemplateDownward from '../../../hocs/clients/withMoveClientApplicationFormTemplateDownward';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import ApplicationFormEditModal from './ApplicationFormEditModal';

import './ApplicationForms.css';

const cleanApplicationTemplateInput = (input) => {
  return _.pick(sanitizeTypename(input), 'title', 'form');
};

const ApplicationFormTemplate = ({
  applicationFormTemplate,
  index,
  nbApplicationFormTemplates,
  onAskEdit,
  onAskRemove,
  onMoveUpward,
  onMoveDownward,
  t,
}) => (
  <Segment className='application-form-template'>
    <h3>{applicationFormTemplate.title}</h3>

    <div className='settings-dropdown-container'>
      <Dropdown
        direction='left'
        icon={null}
        trigger={<Image src='/images/icons/figma/ellipsis.svg' />}
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => onAskEdit()}>
            {t('settings.careerPage.applicationForms.edit')}
          </Dropdown.Item>
          {index > 0 && (
            <Dropdown.Item onClick={() => onMoveUpward()}>
              {t('settings.careerPage.applicationForms.moveUpward')}
            </Dropdown.Item>
          )}
          {index + 1 < nbApplicationFormTemplates && (
            <Dropdown.Item onClick={() => onMoveDownward()}>
              {t('settings.careerPage.applicationForms.moveDownward')}
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={() => onAskRemove()}>
            {t('settings.careerPage.applicationForms.delete')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  </Segment>
);

class ApplicationForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCloseModal = () => {
    this.setState({ modal: null });
  };

  handleAskAddApplicationForm = () => {
    const { t } = this.props;
    this.setState({
      modal: {
        type: 'add-application-form',
        initialValue: {
          form: {
            fields: [
              {
                id: getRandomString(10),
                type: 'text',
                title: t(
                  'settings.careerPage.applicationForms.defaultQuestion',
                ),
              },
            ],
          },
        },
      },
    });
  };

  handleAddApplicationForm = ({ value }) => {
    const { addApplicationFormTemplate } = this.props;

    addApplicationFormTemplate({
      applicationFormTemplate: cleanApplicationTemplateInput(value),
    });

    this.handleCloseModal();
  };

  handleAskEditApplicationForm = ({ applicationFormTemplateId }) => {
    const { client } = this.props;

    const applicationFormTemplates = client?.applicationFormTemplates || [];
    const applicationFormTemplate = _.findWhere(applicationFormTemplates, {
      id: applicationFormTemplateId,
    });

    if (applicationFormTemplate) {
      this.setState({
        modal: {
          type: 'edit-application-form',
          applicationFormTemplateId,
          initialValue: applicationFormTemplate,
        },
      });
    }
  };

  handleEditApplicationForm = ({ applicationFormTemplateId, newValue }) => {
    const { updateApplicationFormTemplate } = this.props;

    updateApplicationFormTemplate({
      applicationFormTemplateId,
      applicationFormTemplate: cleanApplicationTemplateInput(newValue),
    });

    this.handleCloseModal();
  };

  handleAskDeleteApplicationForm = ({ applicationFormTemplateId }) => {
    this.setState({
      modal: {
        type: 'delete-application-form',
        applicationFormTemplateId,
      },
    });
  };

  handleDeleteApplicationForm = ({ applicationFormTemplateId }) => {
    const { deleteApplicationFormTemplate } = this.props;
    deleteApplicationFormTemplate({ applicationFormTemplateId });
    this.handleCloseModal();
  };

  handleMoveApplicationFormUpward = ({ applicationFormTemplateId }) => {
    const { moveApplicationFormTemplateUpward } = this.props;
    moveApplicationFormTemplateUpward({ applicationFormTemplateId });
    this.handleCloseModal();
  };

  handleMoveApplicationFormDownward = ({ applicationFormTemplateId }) => {
    const { moveApplicationFormTemplateDownward } = this.props;
    moveApplicationFormTemplateDownward({ applicationFormTemplateId });
    this.handleCloseModal();
  };

  renderForm() {
    const { client, t } = this.props;

    const applicationFormTemplates =
      (client || {}).applicationFormTemplates || [];

    return (
      <div className='applicationFormTemplates-editor'>
        <div className='applicationFormTemplates-list-container'>
          {_.map(applicationFormTemplates, (applicationFormTemplate, index) => (
            <div
              className='applicationFormTemplate-container'
              key={applicationFormTemplate.id}
            >
              <ApplicationFormTemplate
                index={index}
                nbApplicationFormTemplates={applicationFormTemplates.length}
                applicationFormTemplate={applicationFormTemplate}
                onAskEdit={() => {
                  this.handleAskEditApplicationForm({
                    applicationFormTemplateId: applicationFormTemplate.id,
                  });
                }}
                onMoveUpward={() => {
                  this.handleMoveApplicationFormUpward({
                    applicationFormTemplateId: applicationFormTemplate.id,
                  });
                }}
                onMoveDownward={() => {
                  this.handleMoveApplicationFormDownward({
                    applicationFormTemplateId: applicationFormTemplate.id,
                  });
                }}
                onAskRemove={() => {
                  this.handleAskDeleteApplicationForm({
                    applicationFormTemplateId: applicationFormTemplate.id,
                  });
                }}
                t={t}
              />
            </div>
          ))}
        </div>

        <GenericButton onClick={() => this.handleAskAddApplicationForm()}>
          <Plus />
          {t('settings.careerPage.applicationForms.add')}
        </GenericButton>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const { modal } = this.state;
    return (
      <div className='section visible application-forms-container'>
        <div className='description'>
          {t('settings.careerPage.applicationForms.description')}
          {this.renderForm()}
        </div>

        {modal?.type === 'delete-application-form' ? (
          <ConfirmationModal
            header={t(
              'settings.careerPage.applicationForms.deleteConfirmationModal.title',
            )}
            submit={t(
              'settings.careerPage.applicationForms.deleteConfirmationModal.delete',
            )}
            onSubmit={() => {
              this.handleDeleteApplicationForm({
                applicationFormTemplateId: modal.applicationFormTemplateId,
              });
            }}
            open
            onCancel={() => this.handleCloseModal()}
          />
        ) : (
          ''
        )}

        {modal?.type === 'edit-application-form' ? (
          <ApplicationFormEditModal
            mode='edit'
            initialValue={modal.initialValue}
            onClose={() => this.handleCloseModal()}
            onSubmit={({ newValue }) => {
              this.handleEditApplicationForm({
                applicationFormTemplateId: modal.applicationFormTemplateId,
                newValue,
              });
            }}
            t={t}
          />
        ) : (
          ''
        )}

        {modal?.type === 'add-application-form' ? (
          <ApplicationFormEditModal
            mode='create'
            initialValue={modal.initialValue}
            onClose={() => this.handleCloseModal()}
            onSubmit={({ newValue }) => {
              this.handleAddApplicationForm({
                value: newValue,
              });
            }}
            t={t}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default compose(
  withAddClientApplicationFormTemplate,
  withUpdateClientApplicationFormTemplate,
  withDeleteClientApplicationFormTemplate,
  withMoveClientApplicationFormTemplateUpward,
  withMoveClientApplicationFormTemplateDownward,
  withTranslation('translations'),
)(ApplicationForms);
