import React, { useEffect, useState } from 'react';
import _, { compose } from 'underscore';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';

import {
  Button,
  Dropdown,
  Checkbox,
  ModalHeader as Header,
  ModalContent as Content,
  ModalActions as Actions,
} from 'semantic-ui-react';

import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import { formatUser } from '../../Settings/Senders/helpers';
import withUserSenders from '../../../../hocs/users/withUserSenders';
import routerParamsToProps from '../../../../hocs/routerParamsToProps';
import withOfferRecruiterConfiguration from '../../../../hocs/offers/withOfferRecruiterConfiguration';
import withAddOfferRecruiterConfiguration from '../../../../hocs/offers/withAddOfferRecruiterConfiguration';
import withUpdateOfferRecruiterConfiguration from '../../../../hocs/offers/withUpdateOfferRecruiterConfiguration';
import withDeleteOfferRecruiterConfiguration from '../../../../hocs/offers/withDeleteOfferRecruiterConfiguration';

import './ConfigureSenderModal.css';

const ConfigureSenderModal = (props) => {
  const {
    clientId,
    offerId,
    open,
    onClose,
    user,
    recruiterConfiguration,
    addRecruiterConfiguration,
    updateRecruiterConfiguration,
    deleteRecruiterConfiguration,
    t,
  } = props;

  const senders = _.filter(user?.senders, (sender) => !!sender.mailAccount);
  const assignedSender = recruiterConfiguration?.assignedSender;

  const [selectedSenderId, setSelectedSenderId] = useState();
  const [checked, setChecked] = useState(!!assignedSender);

  const senderOptions = formatSenderOptions({ senders });

  // Update default dropdown value based on user selection and config
  useEffect(() => {
    // if there is no config or user has made a selection: do nothing
    if (_.isEmpty(recruiterConfiguration) || selectedSenderId) {
      return;
    }

    // if assigned sender was revoked or deleted: do nothing
    const senderIdOptions = _.map(senderOptions, (opt) => opt.value);
    const assignedSenderInOptions = _.find(
      senderIdOptions,
      (id) => id === assignedSender?.id,
    );
    if (assignedSender?.id && !assignedSenderInOptions) {
      return;
    }

    // else, auto-select sender from config
    setSelectedSenderId(assignedSender?.id);
  }, [assignedSender, recruiterConfiguration, selectedSenderId, senderOptions]);

  const onSelectSender = (e, { value }) => {
    setSelectedSenderId(value);
  };

  const addConfig = async ({ configInput }) => {
    try {
      await addRecruiterConfiguration({ id: offerId, configInput });
    } catch (e) {
      console.error(e);
    }
  };

  const updateConfig = async ({ configInput }) => {
    try {
      const configId = (recruiterConfiguration || {}).id;
      updateRecruiterConfiguration({ id: offerId, configId, configInput });
    } catch (e) {
      console.error(e);
    }
  };

  const deleteConfig = async () => {
    try {
      const configId = (recruiterConfiguration || {}).id;
      await deleteRecruiterConfiguration({ id: offerId, configId });
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Adds, Updates or Deletes recruiterConfiguration depending on current state
   */
  const onSubmitConfig = async () => {
    if (!selectedSenderId || !checked) {
      if (recruiterConfiguration) {
        await deleteConfig();
      }
      return onClose();
    }

    const selectedSender = _.findWhere(senders, { id: selectedSenderId }) || {};
    const configInput = {
      recruiterEmail: user?.email,
      assignedSender: {
        senderId: selectedSender?.id,
      },
    };

    if (_.isEmpty(recruiterConfiguration)) {
      await addConfig({ configInput });
      return onClose();
    }

    await updateConfig({ configInput });
    return onClose();
  };

  return (
    <GenericModal
      className='configure-sender-modal'
      size='small'
      open={open}
      onClose={onClose}
    >
      <Header>{t('offers.configureSenderModal.header')}</Header>
      <Content>
        <div className='modal-content'>
          <div className='sender-modal-description'>
            {t('offers.configureSenderModal.subHeader')}
          </div>

          <div className='input-container'>
            <div className='input-element'>
              <Checkbox
                label={t('offers.configureSenderModal.checkbox')}
                onChange={() => setChecked(!checked)}
                checked={checked}
              />
            </div>
          </div>

          <div
            className={classNames('dropdown-container', !checked && 'hidden')}
          >
            {!_.isEmpty(senderOptions) ? (
              <Dropdown
                selection
                fluid
                options={senderOptions}
                value={selectedSenderId}
                onChange={onSelectSender}
                placeholder={t('offers.configureSenderModal.placeholder')}
                disabled={_.isEmpty(senderOptions)}
              />
            ) : (
              <div className='no-senders'>
                {t('offers.configureSenderModal.emptyDropdown.noSenders')}
                <br />
                {t('offers.configureSenderModal.emptyDropdown.goTo')}
                <Link to={`/client/${clientId}/senders`}>
                  {t('offers.configureSenderModal.emptyDropdown.settings')}
                </Link>
              </div>
            )}
          </div>
        </div>
      </Content>
      <Actions className='modal-actions-buttons'>
        <div className='flex-spaced-between'>
          <GenericButton primacy='secondary' size='big' onClick={onClose}>
            {t('offers.configureSenderModal.cancel')}
          </GenericButton>
          <GenericButton size='big' onClick={onSubmitConfig}>
            {t('offers.configureSenderModal.confirm')}
          </GenericButton>
        </div>
      </Actions>
    </GenericModal>
  );
};

const formatSenderOptions = ({ senders }) => {
  const formattedSenders = _.map(senders, (sender) => {
    const { id, senderAddress, firstname, lastname } = sender;
    return {
      key: id,
      value: id,
      text: formatUser({
        email: senderAddress,
        firstname,
        lastname,
      }),
    };
  });
  return formattedSenders;
};

export default compose(
  withTranslation('translations'),
  withUserSenders,
  withOfferRecruiterConfiguration,
  withAddOfferRecruiterConfiguration,
  withUpdateOfferRecruiterConfiguration,
  withDeleteOfferRecruiterConfiguration,
  withRouter,
  routerParamsToProps,
)(ConfigureSenderModal);
