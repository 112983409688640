import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

export const UpdateMissionATS = gql`
  mutation updateMissionATS($searchPoolId: ID!, $input: updateLinkedATSInput!) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      updateLinkedATS(input: $input) {
        job {
          id
          linkedATSJobId
          linkedATSUserId
        }
      }
    }
  }
`;

export default function useUpdateMissionATS() {
  return useMutation(UpdateMissionATS);
}
