import _ from 'underscore';
import React, { useEffect } from 'react';
import { Accordion, Segment } from 'semantic-ui-react';

import InfoTooltip from '@/components/Common/InfoTooltip';
import GenericAccordion from '@/components/Common/GenericAccordion';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import useClientId from '@/hooks/router/useClientId';

import { CustomSelect, CustomInput, CustomTextArea } from './CustomFormItems';
import GeneralCriteriaForm from './GeneralCriteriaForm';
import HardFiltersForm from './HardFiltersForm';

import './PureCriteriaForm.css';

export const DEBOUNCE_INPUT_MS = 700;

const PureCriteriaForm = ({
  t,
  options,
  handleChange,
  current,
  modifyingNotification,
  errorFields,
  // simplifiedMode,
  debounceTextFields,
  onInit,
  requiredCriterias,
}) => {
  useEffect(() => {
    if (onInit) {
      onInit();
    }

    // eslint-disable-next-line
  }, []);

  const clientId = useClientId();
  const { permissions } = useClientPermissions(clientId);

  return (
    <Accordion className='hs-accordion pure-criteria-form'>
      <Segment>
        <div className='form-section-title'>{t('criteria.positionType')}</div>
        <GeneralCriteriaForm
          current={current}
          options={options}
          handleChange={handleChange}
          t={t}
          errorFields={errorFields}
          modifyingNotification={modifyingNotification}
        />
      </Segment>
      <Segment>
        <div className='form-section-title'>
          {t('criteria.skill', { count: 0 })}
        </div>
        <SkillsCriteriaForm
          current={current}
          options={options}
          handleChange={handleChange}
          t={t}
          errorFields={errorFields}
          modifyingNotification={modifyingNotification}
          requiredCriterias={requiredCriterias}
        />
      </Segment>
      <Segment>
        <div className='form-section-title'>{t('criteria.extra')}</div>
        <OtherInformationCriteriaForm
          current={current}
          options={options}
          handleChange={handleChange}
          t={t}
          debounceTextFields={debounceTextFields}
          modifyingNotification={modifyingNotification}
        />
      </Segment>
      {permissions?.hardFilters && (
        <Segment>
          <GenericAccordion
            title={t('criteria.hardFilters.title')}
            triggerClassName='form-section-accordion-trigger'
          >
            <HardFiltersForm current={current} handleChange={handleChange} />
          </GenericAccordion>
        </Segment>
      )}
    </Accordion>
  );
};

export const SkillsCriteriaForm = ({
  current,
  options,
  handleChange,
  t,
  errorFields,
  modifyingNotification,
  requiredCriterias = {},
}) => (
  <>
    <div className='form-box'>
      <div
        className={`form-field-label${
          _.indexOf(errorFields, 'skills') >= 0 ? ' is-error' : ''
        }`}
      >
        {t('criteria.required')}
        <InfoTooltip>{t('criteria.requiredDetails')}</InfoTooltip>
        {modifyingNotification('requiredSkills')}
      </div>
      <div className='form-field-input'>
        <CustomSelect
          label='requiredSkills'
          multi
          value={current?.requiredSkills}
          options={
            !requiredCriterias?.requiredSkills ||
            requiredCriterias?.requiredSkills?.length < 3
              ? (options || {}).requiredSkills
              : []
          }
          onChange={handleChange}
          showBlockTags
          placeholderText={t('criteria.requiredPlaceholder')}
        />
      </div>
    </div>
    <div className='form-box'>
      <div className='form-field-label'>
        {t('criteria.important')}
        <InfoTooltip>{t('criteria.importantDetails')}</InfoTooltip>
        {modifyingNotification('importantSkills')}
      </div>
      <div className='form-field-input'>
        <CustomSelect
          label='importantSkills'
          multi
          value={current.importantSkills}
          options={(options || {}).importantSkills}
          onChange={handleChange}
          showBlockTags
          placeholderText={t('criteria.importantPlaceholder')}
        />
      </div>
    </div>
    <div className='form-box'>
      <div className='form-field-label'>
        {t('criteria.bonus')}
        <InfoTooltip>{t('criteria.bonusDetails')}</InfoTooltip>
        {modifyingNotification('bonusSkills')}
      </div>
      <div className='form-field-input'>
        <CustomSelect
          label='bonusSkills'
          multi
          value={current.bonusSkills}
          options={(options || {}).bonusSkills}
          onChange={handleChange}
          showBlockTags
          placeholderText={t('criteria.bonusPlaceholder')}
        />
      </div>
    </div>
  </>
);

export const OtherInformationCriteriaForm = ({
  current,
  options,
  handleChange,
  t,
  debounceTextFields,
  modifyingNotification,
  simplifiedMode,
}) => (
  <>
    <div className='form-row'>
      <div className='form-box'>
        <div className='form-field-label'>
          {t('criteria.languages')}
          {modifyingNotification('languages')}
        </div>
        <div className='form-field-input'>
          <CustomSelect
            label='languages'
            multi
            value={current.languages}
            options={(options || {}).languages}
            onChange={handleChange}
            showBlockTags
            placeholderText={t('criteria.languagesPlaceholder')}
          />
        </div>
      </div>
      <div className='form-box'>
        <div className='form-field-label'>
          {t('criteria.keyWords')}
          <InfoTooltip>{t('criteria.keyWordsDetails')}</InfoTooltip>
          {modifyingNotification('extraKeyWords')}
        </div>
        <div className='form-field-input'>
          <CustomInput
            label='extraKeyWords'
            currentValue={current.extraKeyWords}
            onChange={
              debounceTextFields
                ? _.debounce(handleChange, DEBOUNCE_INPUT_MS)
                : handleChange
            }
            placeholderText={t('criteria.keyWordsPlaceholder')}
          />
        </div>
      </div>
    </div>
    <div className='form-row'>
      <div className='form-box'>
        <div className='form-field-label'>
          {t('criteria.collaboratorsInCharge')}
          <InfoTooltip>
            {t('criteria.collaboratorsInChargeDetails')}
          </InfoTooltip>
          {modifyingNotification('collaboratorsInCharge')}
        </div>
        <div className='form-field-input'>
          <CustomSelect
            label='collaboratorsInCharge'
            multi
            value={current.collaboratorsInCharge}
            options={(options || {}).collaboratorsInCharge}
            onChange={handleChange}
            showBlockTags
            placeholderText={t('criteria.collaboratorsInChargePlaceholder')}
          />
        </div>
      </div>
      <div className='form-box'>
        <div className='form-field-label'>
          {t('criteria.onlineLinks')}
          <InfoTooltip>{t('criteria.onlineLinksDetails')}</InfoTooltip>
          {modifyingNotification('onlineLinks')}
        </div>
        <div className='form-field-input'>
          <CustomInput
            label='onlineLinks'
            currentValue={current.onlineLinks}
            onChange={_.debounce(handleChange, DEBOUNCE_INPUT_MS)}
            placeholderText={t('criteria.onlineLinksPlaceholder')}
          />
        </div>
      </div>
    </div>
    <div className='form-row'>
      <div className='form-box'>
        <div className='form-field-label'>
          {t('criteria.relevantProfiles')}
          <InfoTooltip>{t('criteria.relevantProfilesDetails')}</InfoTooltip>
          {modifyingNotification('exampleProfiles')}
        </div>
        <div className='form-field-input'>
          <CustomInput
            label='exampleProfiles'
            currentValue={current.exampleProfiles}
            onChange={
              debounceTextFields
                ? _.debounce(handleChange, DEBOUNCE_INPUT_MS)
                : handleChange
            }
            placeholderText={t('criteria.relevantProfilesPlaceholder')}
          />
        </div>
      </div>
      <div className='form-box'>
        <div className='form-field-label'>
          {t('criteria.targetEducation')}
          <InfoTooltip>{t('criteria.targetEducationDetails')}</InfoTooltip>
          {modifyingNotification('education')}
        </div>
        <div className='form-field-input'>
          <CustomSelect
            label='education'
            multi
            value={current.targetEducation}
            options={(options || {}).education}
            allowCreate
            onChange={handleChange}
            showBlockTags
            placeholderText={t('criteria.targetEducationPlaceholder')}
          />
        </div>
      </div>
    </div>
    <div className='form-row'>
      <div className='form-box'>
        <div className='form-field-label'>
          {t('criteria.relevantCompanies')}
          <InfoTooltip>{t('criteria.relevantCompaniesDetails')}</InfoTooltip>
          {modifyingNotification('relevantCompanies')}
        </div>
        <div className='form-field-input'>
          <CustomInput
            label='relevantCompanies'
            currentValue={current.relevantCompanies}
            onChange={
              debounceTextFields
                ? _.debounce(handleChange, DEBOUNCE_INPUT_MS)
                : handleChange
            }
            placeholderText={t('criteria.relevantCompaniesPlaceholder')}
          />
        </div>
      </div>
      <div className='form-box'>
        <div className='form-field-label'>
          {t('criteria.blacklist')}
          <InfoTooltip>{t('criteria.blacklistDetails')}</InfoTooltip>
          {modifyingNotification('blacklistedCompanies')}
        </div>
        <div className='form-field-input'>
          <CustomInput
            label='blacklistedCompanies'
            currentValue={current.blacklist}
            onChange={
              debounceTextFields
                ? _.debounce(handleChange, DEBOUNCE_INPUT_MS)
                : handleChange
            }
            placeholderText={t('criteria.blacklistPlaceholder')}
          />
        </div>
      </div>
    </div>
    {!simplifiedMode && (
      <div className='form-box form-box-top'>
        <div className='form-field-label'>
          {t('criteria.moreInfo')}
          {modifyingNotification('moreInfo')}
        </div>
        <div className='form-field-input'>
          <CustomTextArea
            label='moreInfo'
            currentValue={current.moreInfo}
            onChange={
              debounceTextFields
                ? _.debounce(handleChange, DEBOUNCE_INPUT_MS)
                : handleChange
            }
            placeholderText={t('criteria.moreInfoPlaceholder')}
          />
        </div>
      </div>
    )}
  </>
);

export default PureCriteriaForm;
