import _, { compose } from 'underscore';
import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import * as Sentry from '@sentry/browser';

import withClientTemplatesAndOffers from '../../../hocs/templates/withClientTemplatesAndOffers';
import withDuplicateTemplate from '../../../hocs/templates/withDuplicateTemplate';
import withDeleteTemplate from '../../../hocs/templates/withDeleteTemplate';
import withRestoreTemplate from '../../../hocs/templates/withRestoreTemplate';
import routerParamsToProps from '../../../hocs/routerParamsToProps';
import contextToProps from '../../../hocs/contextToProps';
import withActionLogger from '../../../hocs/withActionLogger';

import TemplatesTable from '../../../containers/Parameters/Templates/TemplatesTable';

import './TemplatesDashboard.css';

class TemplatesDashboard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      seeAll: false,
    };
  }

  logAction = ({ type }) => {
    const { clientId, onLogAction } = this.props;
    onLogAction({
      type,
      info: {
        clientId,
        from: 'templates-dashboard',
      },
    });
  };

  handleDeleteTemplate = (template) => {
    this.props
      .deleteTemplate(template)
      .then(() => {
        this.props.onShowNotification({
          message: this.props.t('templates.notifications.deletedWithSuccess'),
          level: 'success',
        });
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  };

  render() {
    const {
      error,
      templates,
      templatesAndOffersLoading,
      duplicateTemplate,
      clientId,
      t,
    } = this.props;
    const { seeAll } = this.state;

    if (templatesAndOffersLoading) {
      return (
        <div className='templates dashboard-section'>
          <div className='header'>
            <h1 style={{ display: 'inline' }}>{t('templates.header')}</h1>
          </div>
          <div className='templates-table-placeholder'>
            <Loader active inline='centered' size='large' />
          </div>
        </div>
      );
    }
    if (error) return <p />;
    if (!templates) return <div />;

    const N_DEFAULT_DISPLAYED_TEMPLATES = 10;
    const getEditionDate = (template) => {
      const { lastEditionDate, creationDate } = template || {};
      return lastEditionDate || creationDate || '';
    };

    const unarchivedTemplates = _.where(templates, { isArchived: false });
    const sortedTemplates = _.sortBy(
      unarchivedTemplates,
      getEditionDate,
    ).reverse();
    const displayedTemplates = _.first(
      sortedTemplates,
      seeAll ? sortedTemplates.length : N_DEFAULT_DISPLAYED_TEMPLATES,
    );
    const displayedAndSortedByTitle = _.sortBy(
      displayedTemplates,
      ({ title }) => title.toLowerCase(),
    );

    if (_.isEmpty(displayedAndSortedByTitle)) {
      return (
        <div className='templates dashboard-section'>
          <div className='header'>
            <h1 style={{ display: 'inline' }}>
              <Link to={`/client/${clientId}/templates`}>
                {t('templates.header')}
              </Link>
            </h1>
          </div>
          <div className='no-items-container'>
            <img
              src='/images/placeholders/templates.svg'
              height='155'
              alt='templates-placeholder'
            />
            <div className='no-items'>
              {t('dashboard.templates.noTemplates')}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='templates dashboard-section'>
        <div className='header'>
          <h1 style={{ display: 'inline' }}>
            <Link to={`/client/${clientId}/templates`}>
              {t('templates.header')}
            </Link>
          </h1>
        </div>

        <TemplatesTable
          templates={displayedAndSortedByTitle}
          clientId={clientId}
          duplicateTemplate={duplicateTemplate}
          handleDeleteTemplate={this.handleDeleteTemplate}
          readOnly
        />

        {sortedTemplates.length > N_DEFAULT_DISPLAYED_TEMPLATES && !seeAll && (
          <div
            className='see-all-link-container'
            onClick={() => this.setState({ seeAll: true })}
          >
            {t('dashboard.templates.seeAll')}
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  routerParamsToProps,
  withActionLogger,
  withClientTemplatesAndOffers,
  withDuplicateTemplate,
  withDeleteTemplate,
  withRestoreTemplate,
  contextToProps,
  withTranslation('translations'),
)(TemplatesDashboard);
