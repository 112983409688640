import _, { compose } from 'underscore';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Dropdown, Container } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

import { getRandomString } from '../../../common';
import withClient from '../../../hocs/clients/withClient';
import withProfileEvaluationsSets from '../../../hocs/profiles/withProfileEvaluationsSets';
import withAddProfileEvaluation from '../../../hocs/profiles/withAddProfileEvaluation';
import withDeleteProfileEvaluation from '../../../hocs/profiles/withDeleteProfileEvaluation';
import withUpdateProfileEvaluation from '../../../hocs/profiles/withUpdateProfileEvaluation';
import withAddProfileEvaluationsSet from '../../../hocs/profiles/withAddProfileEvaluationsSet';
import withUpdateProfileEvaluationsSet from '../../../hocs/profiles/withUpdateProfileEvaluationsSet';
import withDeleteProfileEvaluationsSet from '../../../hocs/profiles/withDeleteProfileEvaluationsSet';
import withAddClientEvaluationFormTemplate from '../../../hocs/clients/withAddClientEvaluationFormTemplate';

import EvaluationsSet from './EvaluationsSet';
import EvaluationEditModal from './EvaluationsSet/EvaluationEditModal';
import EvaluationFormEditModal from './EvaluationFormEditModal';

import './evaluationsSets.css';

const AddEvaluationsSetDropdown = ({
  evaluationFormTemplates,
  onAddEvaluationsSet,
  onAskCreateEvaluationsSet,
  onAskFormsManagement,
  isFirst,
  t,
}) => {
  // console.log('evaluationFormTemplates', evaluationFormTemplates);
  return (
    <div className='add-evaluation-form'>
      <Dropdown
        direction='left'
        trigger={
          <span className='choose-evaluation-form-template'>
            {isFirst
              ? t('profile.evaluations.addAnEvaluation')
              : t('profile.evaluations.addAnotherEvaluation')}
          </span>
        }
      >
        <Dropdown.Menu>
          {_.map(evaluationFormTemplates, ({ id, title }) => {
            const formTemplate = _.findWhere(evaluationFormTemplates, { id });
            return (
              <Dropdown.Item
                key={id}
                data-key={id}
                onClick={() => {
                  onAddEvaluationsSet({
                    evaluationForm: formTemplate?.form,
                    title: formTemplate?.title,
                  });
                }}
              >
                {title}
              </Dropdown.Item>
            );
          })}
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() => {
              onAskFormsManagement({});
            }}
          >
            {t('profile.evaluations.manageEvaluationForms')}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              onAskCreateEvaluationsSet({});
            }}
          >
            {t('profile.evaluations.createNewForm')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const getDefaultFormTemplate = ({ t }) => ({
  id: 'default',
  title: t('profile.evaluations.defaultForm.title'),
  author: {
    email: 'support@hiresweet.com',
    name: '?? ??',
  },
  form: {
    fields: [
      {
        type: 'grade-and-comment',
        title: t('profile.evaluations.defaultForm.overallImpression'),
        coefficient: 1,
        commentType: 'inline',
      },
      {
        type: 'grade-and-comment',
        title: t('profile.evaluations.defaultForm.suitabilityForTheJob'),
        coefficient: 1,
        commentType: 'inline',
      },
      {
        type: 'grade',
        title: t('profile.evaluations.defaultForm.motivation'),
        coefficient: 1,
      },
      {
        type: 'scorecard',
        title: t('profile.evaluations.defaultForm.fit'),
        subfields: [
          {
            type: 'grade',
            title: t('profile.evaluations.defaultForm.communication'),
            coefficient: 1,
          },
          {
            type: 'grade',
            title: t('profile.evaluations.defaultForm.empathy'),
            coefficient: 1,
          },
          {
            type: 'grade',
            title: t('profile.evaluations.defaultForm.care'),
            coefficient: 1,
          },
        ],
      },
      {
        type: 'scorecard',
        title: t('profile.evaluations.defaultForm.tech'),
        subfields: [
          {
            type: 'grade',
            title: t('profile.evaluations.defaultForm.velocity'),
            coefficient: 1,
          },
          {
            type: 'grade',
            title: t('profile.evaluations.defaultForm.accuracy'),
            coefficient: 1,
          },
          {
            type: 'grade',
            title: t('profile.evaluations.defaultForm.knowledge'),
            coefficient: 1,
          },
        ],
      },
      {
        type: 'comment',
        title: t('profile.evaluations.defaultForm.additionalNote'),
        commentType: 'inline',
      },
    ],
  },
});

class EvaluationsSets extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleAskAddEvaluationsSet = () => {
    const { t } = this.props;
    this.setState({
      modal: {
        type: 'new-evaluations-set',
        initialValue: {
          form: {
            fields: [
              {
                id: getRandomString(10),
                type: 'grade',
                title: t('settings.evaluations.form.overallImpression'),
                coefficient: 1,
              },
            ],
          },
        },
      },
    });
  };

  handleUpdateEvaluationsSet = async ({ evaluationsSetId, title }) => {
    const { updateProfileEvaluationsSet, profileId } = this.props;
    this.setState({ modal: null });
    await updateProfileEvaluationsSet({
      id: profileId,
      evaluationsSetId,
      evaluationsSet: { title },
    });
  };

  handleAddEvaluationsSet = async ({
    evaluationForm,
    saveAsTemplate,
    title,
  }) => {
    const {
      addProfileEvaluationsSet,
      addEvaluationFormTemplate,
      profileId,
      profileEvaluationsSets: evaluationsSets,
      t,
    } = this.props;
    const index = (evaluationsSets || []).length;

    this.setState({ modal: null });
    await addProfileEvaluationsSet({
      id: profileId,
      evaluationsSet: {
        title: title || `${t('profile.evaluations.evaluation')} ${index + 1}`,
        form: cleanForm(evaluationForm),
      },
    });

    if (saveAsTemplate) {
      await addEvaluationFormTemplate({
        evaluationFormTemplate: {
          title: title || `${t('profile.evaluations.evaluation')} ${index + 1}`,
          form: cleanForm(evaluationForm),
        },
      });
    }
  };

  handleAskRemoveEvaluationsSet = async ({ evaluationsSetId }) => {
    const { deleteProfileEvaluationsSet, profileId } = this.props;

    await deleteProfileEvaluationsSet({
      id: profileId,
      evaluationsSetId,
    });
  };

  handleOpenFormsManagement = () => {
    const { clientId, history } = this.props;
    if (history && clientId) {
      const link = `/client/${clientId}/evaluationForms`;
      history.push(link);
    }
  };

  handleAskAddFeedback = ({ evaluationsSetId }) => {
    const { profileEvaluationsSets: evaluationsSets } = this.props;
    const evaluationsSet = _.findWhere(evaluationsSets, {
      id: evaluationsSetId,
    });
    if (!evaluationsSet) {
      return;
    }

    const evaluation = {
      form: cleanForm(evaluationsSet.form),
    };

    this.setState({
      modal: {
        type: 'new-evaluation',
        evaluationsSetId,
        initialValue: evaluation,
      },
    });
  };

  handleAddFeedback = async ({ evaluationsSetId, evaluation }) => {
    this.setState({ modal: null });
    const {
      profileId,
      profileEvaluationsSets: evaluationsSets,
      addProfileEvaluation,
    } = this.props;

    const evaluationsSet = _.findWhere(evaluationsSets, {
      id: evaluationsSetId,
    });

    if (!evaluationsSet) {
      return;
    }

    await addProfileEvaluation({
      id: profileId,
      evaluationsSetId,
      evaluation: {
        ...evaluation,
      },
    });
  };

  handleCloseModal = () => {
    this.setState({ modal: null });
  };

  renderFormModal() {
    const { t } = this.props;
    const { modal } = this.state;

    return (
      <div>
        {modal && modal.type === 'new-evaluations-set' ? (
          <EvaluationFormEditModal
            mode='online-creation'
            editMode='create'
            initialValue={modal.initialValue}
            onClose={this.handleCloseModal}
            onSubmit={({ evaluationForm, saveAsTemplate, title }) => {
              this.handleAddEvaluationsSet({
                evaluationForm,
                saveAsTemplate,
                title,
              });
            }}
            t={t}
          />
        ) : (
          ''
        )}
      </div>
    );
  }

  render() {
    const {
      client,
      deleteProfileEvaluation,
      updateProfileEvaluation,
      profileId,
      t,
    } = this.props;
    const { evaluationFormTemplates: clientEvaluationFormTemplates } =
      client || {};
    const evaluationFormTemplates = [
      getDefaultFormTemplate({ t }),
      ...(clientEvaluationFormTemplates || []),
    ];

    const { profileEvaluationsSets: evaluationsSets } = this.props;
    const { modal } = this.state;
    if (_.isEmpty(evaluationsSets)) {
      return (
        <div className='evaluations-sets-container'>
          <div className='evaluations-sets-header'>
            <Grid style={{ margin: 0, padding: 0 }}>
              <Grid.Row columns={2} style={{ margin: 0, padding: 0 }}>
                <Grid.Column style={{ margin: 0, padding: 0 }}>
                  <h3>{t('profile.evaluations.evaluations')}</h3>
                  <div className='no-evaluations'>
                    <span>{t('profile.evaluations.noEvaluations')}</span>
                  </div>
                </Grid.Column>
                <Grid.Column
                  textAlign='right'
                  verticalAlign='middle'
                  style={{ margin: 0, padding: 0 }}
                >
                  <AddEvaluationsSetDropdown
                    evaluationFormTemplates={evaluationFormTemplates}
                    onAddEvaluationsSet={this.handleAddEvaluationsSet}
                    onAskCreateEvaluationsSet={this.handleAskAddEvaluationsSet}
                    onAskFormsManagement={this.handleOpenFormsManagement}
                    isFirst
                    t={t}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          {this.renderFormModal()}
        </div>
      );
    }

    return (
      <div className='evaluations-sets-container'>
        <div className='evaluations-sets-header'>
          <h3 className='header'>{t('profile.evaluations.evaluations')}</h3>
        </div>
        <div className='evaluations-sets-list'>
          {_.map(evaluationsSets, (evaluationsSet) => (
            <EvaluationsSet
              key={evaluationsSet.id}
              evaluationsSet={evaluationsSet}
              onUpdate={({ title }) =>
                this.handleUpdateEvaluationsSet({
                  evaluationsSetId: evaluationsSet.id,
                  title,
                })
              }
              onRemove={() =>
                this.handleAskRemoveEvaluationsSet({
                  evaluationsSetId: evaluationsSet.id,
                })
              }
              onAskAddFeedback={() =>
                this.handleAskAddFeedback({
                  evaluationsSetId: evaluationsSet.id,
                })
              }
              deleteProfileEvaluation={({ evaluationsSetId, evaluationId }) =>
                deleteProfileEvaluation({
                  id: profileId,
                  evaluationsSetId,
                  evaluationId,
                })
              }
              updateEvaluation={({
                evaluationsSetId,
                evaluationId,
                evaluation,
              }) =>
                updateProfileEvaluation({
                  id: profileId,
                  evaluationsSetId,
                  evaluationId,
                  evaluation: {
                    form: cleanForm(evaluation.form, 'update'),
                  },
                })
              }
            />
          ))}
        </div>
        <div className='evaluations-sets-add-more'>
          <Container fluid textAlign='right'>
            <AddEvaluationsSetDropdown
              evaluationFormTemplates={evaluationFormTemplates}
              onAddEvaluationsSet={this.handleAddEvaluationsSet}
              onAskCreateEvaluationsSet={this.handleAskAddEvaluationsSet}
              onAskFormsManagement={this.handleOpenFormsManagement}
              t={t}
            />
          </Container>
        </div>

        {modal && modal.type === 'new-evaluation' ? (
          <EvaluationEditModal
            initialValue={modal.initialValue}
            onClose={this.handleCloseModal}
            onSubmit={({ evaluation }) =>
              this.handleAddFeedback({
                evaluationsSetId: modal.evaluationsSetId,
                evaluation,
              })
            }
          />
        ) : (
          ''
        )}

        {this.renderFormModal()}
      </div>
    );
  }
}

const cleanForm = (form, mode) => {
  if (!form) {
    return form;
  }
  return {
    ..._.omit(form, '__typename', 'id'),
    ...(form.fields && { fields: cleanFields(form.fields, mode) }),
  };
};

const cleanFields = (fields, mode) => {
  if (!fields) {
    return fields;
  }
  const fieldsToOmit =
    mode === 'update' ? ['__typename'] : ['__typename', 'id'];
  return _.map(fields, (field) => ({
    ..._.omit(field, fieldsToOmit),
    ...(field.value && { value: _.omit(field.value, '__typename') }),
    ...(field.subfields && { subfields: cleanFields(field.subfields, mode) }),
  }));
};

export default compose(
  withClient,
  withAddClientEvaluationFormTemplate,
  withProfileEvaluationsSets,
  withAddProfileEvaluationsSet,
  withUpdateProfileEvaluationsSet,
  withAddProfileEvaluation,
  withDeleteProfileEvaluationsSet,
  withDeleteProfileEvaluation,
  withUpdateProfileEvaluation,
  withRouter,
  withTranslation('translations'),
)(EvaluationsSets);
