import _ from 'underscore';

const ProfileIdHandler = ({
  profileIds,
  selectedProfileId,
  onSelectProfileId,
}) => {
  let currentProfileIndex;
  let previousProfileId;
  let nextProfileId;

  if (!_.contains(profileIds, selectedProfileId)) {
    currentProfileIndex = 0;
    previousProfileId = null;
    [nextProfileId] = profileIds;
  } else {
    currentProfileIndex = (profileIds || []).indexOf(selectedProfileId);
    previousProfileId =
      currentProfileIndex > 0 && profileIds[currentProfileIndex - 1];
    nextProfileId =
      currentProfileIndex !== -1 && profileIds[currentProfileIndex + 1];
  }

  return {
    current: selectedProfileId,
    prev: previousProfileId,
    next: nextProfileId,
    onSelectProfileId,
    profileIds,
  };
};

export default ProfileIdHandler;
