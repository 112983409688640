import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import { SnoozeReasonStats } from '@/types/statistics/snoozeReasonsOverview';
import useClientId from '@/hooks/router/useClientId';

import { GenericStatsFilterInput } from '../common';

const getSnoozeReasonsOverview = gql`
  query getSnoozeReasonsOverview(
    $clientId: ID!
    $missionsFilter: GenericStatsFilterInput
    $authorEmailsFilter: GenericStatsFilterInput
    $startDate: Date
    $endDate: Date
  ) {
    client(id: $clientId) {
      id
      statistics {
        snoozeReasonsOverview(
          missionsFilter: $missionsFilter
          authorEmailsFilter: $authorEmailsFilter
          startDate: $startDate
          endDate: $endDate
        ) {
          snoozeReasonId
          snoozeReasonTitle
          count
        }
      }
    }
  }
`;

type GetSnoozeReasonsOverviewData = {
  client: {
    id: string;
    statistics: {
      snoozeReasonsOverview: SnoozeReasonStats[];
    };
  };
};

type GetSnoozeReasonsOverviewVariables = {
  clientId: string;
  missionsFilter?: GenericStatsFilterInput;
  authorEmailsFilter?: GenericStatsFilterInput;
  startDate?: string; // YYYY-MM-DD
  endDate?: string; // YYYY-MM-DD
};

type UseSnoozeReasonsOverviewInput = {
  missionsFilter?: GenericStatsFilterInput;
  authorEmailsFilter?: GenericStatsFilterInput;
  startDate?: string;
  endDate?: string;
  queryOptions?: QueryHookOptions<
    GetSnoozeReasonsOverviewData,
    GetSnoozeReasonsOverviewVariables
  >;
};

interface UseSnoozeReasonsOverviewResult
  extends QueryResult<
    GetSnoozeReasonsOverviewData,
    GetSnoozeReasonsOverviewVariables
  > {
  snoozeReasonsOverview: SnoozeReasonStats[];
}

function useSnoozeReasonsOverview({
  missionsFilter,
  authorEmailsFilter,
  startDate,
  endDate,
  queryOptions = {},
}: UseSnoozeReasonsOverviewInput = {}): UseSnoozeReasonsOverviewResult {
  const clientId = useClientId();
  const query = useQuery<
    GetSnoozeReasonsOverviewData,
    GetSnoozeReasonsOverviewVariables
  >(getSnoozeReasonsOverview, {
    ...queryOptions,
    variables: {
      clientId,
      missionsFilter,
      authorEmailsFilter,
      startDate,
      endDate,
    },
  });

  const snoozeReasonsOverview =
    query.data?.client.statistics.snoozeReasonsOverview || [];

  return { ...query, snoozeReasonsOverview };
}

export default useSnoozeReasonsOverview;
