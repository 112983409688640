import React from 'react';
import qs from 'qs';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import withClientFullStatistics from '../../../hocs/clients/withClientFullStatistics';
import Pipeline from './components/Pipeline';
import SkipRatio from './components/SkipRatio';
import Cohorts from './components/Cohorts';
import Activity from './components/Activity';
import Achievements from './components/Achievements';

import './index.css';

const Analytics = ({ clientId, location, fullStatisticsLoading, t }) => {
  const queryString =
    (location.search && location.search.replace('?', '')) || '';
  const queryObject = qs.parse(queryString);
  const offerId = queryObject && queryObject.offerId;
  return (
    <div className='menu-analytics'>
      <h1 className='analytics-title'>{t('header.parameters.analytics')}</h1>
      <Pipeline
        clientId={clientId}
        offerId={offerId}
        fullStatisticsLoading={fullStatisticsLoading}
      />
      <Cohorts
        clientId={clientId}
        offerId={offerId}
        fullStatisticsLoading={fullStatisticsLoading}
      />
      <SkipRatio
        clientId={clientId}
        offerId={offerId}
        fullStatisticsLoading={fullStatisticsLoading}
      />
      <Activity
        clientId={clientId}
        fullStatisticsLoading={fullStatisticsLoading}
      />
      {fullStatisticsLoading ? null : <Achievements clientId={clientId} />}
    </div>
  );
};

export default compose(
  withRouter,
  withClientFullStatistics,
  withTranslation('translations'),
)(Analytics);
