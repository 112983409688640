import React from 'react';
import _ from 'underscore';
import { Grid } from 'semantic-ui-react';
import GradeButtonSelector from './GradeButtonSelector';


const EvaluationScorecardEditor = ({ scorecard, index, onUpdateField }) => {
  const onSelectSubfieldGrade = (subfield) => {
    return ({ grade }) => {
      onUpdateField({
        newValue: {
          ...scorecard,
          subfields: _.map(scorecard.subfields, (iteratedSubfield) => (iteratedSubfield.id === subfield.id
            ? {
              ...subfield,
              value: {
                ...subfield.value,
                grade,
              },
            }
            : iteratedSubfield),
          ),
        },
      });
    };
  };

  return (
    <div className='evaluation-edit-modal-field'>
      <h4>
        {index + 1}. {scorecard.title}
      </h4>
      <Grid style={{ margin: 0, padding: 0 }}>
        {_.map(scorecard.subfields, (subfield, subfieldIndex) => (
          <Grid.Row className='score-card-row' key={subfieldIndex} style={{ padding: 0 }}>
            <Grid.Column width={8} verticalAlign='middle' style={{ margin: 0, padding: 0 }}>
              <h5>{subfield.title}</h5>
            </Grid.Column>
            <Grid.Column width={8} verticalAlign='middle' textAlign='right' style={{ margin: 0, padding: 0 }}>
              <GradeButtonSelector
                grade={(subfield.value || {}).grade}
                onSelectGrade={onSelectSubfieldGrade(subfield)}
              />
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
    </div>
  );
};

export default EvaluationScorecardEditor;
