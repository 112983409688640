import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import { SvgCheckMark } from '../../assets/icons';
import withClientOffers from '../../hocs/offers/withClientOffers';
import withClientTemplates from '../../hocs/templates/withClientTemplates';
import withUserFromJWToken from '../../hocs/users/withUserFromJWToken';
import withClientCollaborators from '../../hocs/users/withClientCollaborators';

export const BaseGetStartedDashboard = withTranslation('translations')(
  ({ completionStatus, clientId, quickActions, mode, t }) => {
    const completedSteps = _.reduce(
      Object.values(completionStatus),
      (sum, completed) => sum + !!completed,
    );

    const offerProps =
      mode === 'classic'
        ? { onClick: quickActions.openNewJob }
        : { link: `/client/${clientId}/new-job` };

    return (
      <div className='get-started dashboard-section '>
        <div className='header'>
          <h1>{t('dashboard.getStarted.header')}</h1>
          <div className='completed-steps-progress'>
            <CircularProgress completedSteps={completedSteps} />
          </div>
        </div>
        <div className='get-started-item-list'>
          <GetStartedItem
            text={t('dashboard.getStarted.connectMailAccount')}
            durationText='1 mn'
            completed={completionStatus.connectMailAccount}
          />
          <GetStartedItem
            text={t('dashboard.getStarted.createJob')}
            durationText='5 mn'
            completed={completionStatus.createJob}
            {...offerProps}
          />
          <GetStartedItem
            text={t('dashboard.getStarted.createTemplate')}
            durationText='5 mn'
            completed={completionStatus.createTemplate}
            onClick={quickActions.openNewTemplate}
          />
          <GetStartedItem
            text={t('dashboard.getStarted.contactProfile')}
            durationText='3 mn'
            completed={completionStatus.contactProfile}
            link={`/client/${clientId}/discover/candidates`}
          />
          <GetStartedItem
            text={t('dashboard.getStarted.inviteCollaborator')}
            durationText='1 mn'
            completed={completionStatus.inviteCollaborator}
            onClick={quickActions.openNewCollaborator}
            contactProfile
          />
        </div>
      </div>
    );
  },
);

const GetStartedDashboard = ({
  clientId,
  offers,
  clientOffersLoading,
  user,
  collaborators,
  templates,
  quickActions,
  mode,
}) => {
  const contactedProfilesCount = countContactedProfiles({ offers });
  if (clientOffersLoading || contactedProfilesCount > 10) {
    return null;
  }

  const completionStatus = {
    connectMailAccount: !!user?.mailAccount?.address,
    createJob:
      _.reject(offers, ({ isUnclassified }) => isUnclassified).length > 0,
    createTemplate: templates?.length > 0,
    contactProfile: contactedProfilesCount > 0,
    inviteCollaborator: collaborators?.length > 1,
  };

  return (
    <BaseGetStartedDashboard
      clientId={clientId}
      mode={mode}
      quickActions={quickActions}
      completionStatus={completionStatus}
    />
  );
};

const GetStartedItem = ({ text, completed, durationText, link, onClick }) => {
  const completedClass = completed ? 'completed' : '';
  const Tag = completed || !link ? 'div' : Link;
  const tagProps = completed || !link ? {} : { to: link };
  const clickableProps = onClick ? { role: 'button', tabIndex: 0 } : {};
  const history = useHistory();
  const onKeyPress = (ev) => {
    if (!onClick && !link) return;
    if (['Space', 'Enter'].includes(ev.nativeEvent.code)) {
      if (onClick) onClick();
      if (link) history.push(link);
      ev.preventDefault();
    }
  };
  return (
    <Tag
      {...tagProps}
      {...clickableProps}
      onClick={onClick}
      onKeyPress={onKeyPress}
      className={classNames('get-started-item', completedClass, {
        'get-started-item--clickable': onClick,
      })}
    >
      <SvgCheckMark className={classNames('check-mark-icon', completedClass)} />
      <div className={classNames('get-started-item-text', completedClass)}>
        {text}
      </div>
      <div className='spacer' />
      <div className='get-started-item-duration'>{durationText}</div>
    </Tag>
  );
};

const countContactedProfiles = ({ offers }) => {
  return _.reduce(
    offers,
    (total, offer) => {
      const offerTotal = countContacted(offer?.pipeStepStats);
      return total + offerTotal;
    },
    0,
  );
};

const countContacted = (pipeStepStats) => {
  return _.reduce(
    pipeStepStats,
    (total, step) => {
      const { stepId, count, pipeSubStepStats } = step;
      if (stepId === 'pending') {
        return total;
      }
      if (stepId === 'skipped') {
        const sentAndSkipped = _.findWhere(pipeSubStepStats, {
          subStepId: 'sent',
        });
        return total + ((sentAndSkipped && sentAndSkipped.count) || 0);
      }
      return total + count;
    },
    0,
  );
};

const CircularProgress = ({ completedSteps = 0 }) => {
  const maxValue = 5;
  const styles = buildStyles({
    strokeLinecap: 'butt',
    pathColor: '#2ECC71',
  });
  return (
    <CircularProgressbarWithChildren
      value={completedSteps}
      maxValue={maxValue}
      styles={styles}
      strokeWidth={10}
    >
      <div className='ratio-container'>
        <span className='colorized'>{completedSteps * 20}%</span>
      </div>
    </CircularProgressbarWithChildren>
  );
};

export default compose(
  withUserFromJWToken,
  withClientOffers,
  withClientTemplates,
  withClientCollaborators,
)(GetStartedDashboard);
