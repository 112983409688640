import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const addClientCalendlyAccount = gql`
  mutation addClientCalendlyAccount($token: String!) {
    addClientCalendlyAccount(token: $token) {
      id
      calendlyIntegration {
        active
        accounts {
          id
          slug
          email
          status
        }
      }
    }
  }
`;

export default graphql(addClientCalendlyAccount, {
  props: ({ mutate }) => ({
    addClientCalendlyAccount: ({ token }) => {
      const variables = { token };
      return mutate({ variables });
    },
  }),
});
