import React, { FC, useMemo, MouseEvent } from 'react';
import _ from 'underscore';

import { getFirstProfilesAndRemainder } from '@/containers/Profile/EmptyState/helpers';
import { StepProfile } from '@/graphql/hooks/offers/useOfferStepProfiles';

import OfferProfileColumn from '../OfferProfileColumn';
import OfferHoldColumn from './OfferHoldColumn';

interface OfferPendingColumnsProps {
  offerId: string;
  pendingProfiles: StepProfile[];
  isHold?: boolean;
  handlerForStep: (stepId: string) => (event: MouseEvent) => void;
}

const OfferPendingColumns: FC<OfferPendingColumnsProps> = ({
  offerId,
  pendingProfiles,
  isHold,
  handlerForStep,
}) => {
  const pendingCount = _.size(pendingProfiles);
  const pendingMainProfiles = useMemo(
    () =>
      _.filter(
        pendingProfiles,
        ({ resumeData }) => resumeData.subStep === 'pending-main',
      ),
    [pendingProfiles],
  );
  const pendingMainCount = _.size(pendingMainProfiles);
  const {
    firstProfiles: firstPendingMainProfiles,
    remainder: pendingMainRemainder,
  } = useMemo(() => getFirstProfilesAndRemainder(pendingMainProfiles, 2), [
    pendingMainProfiles,
  ]);

  const pendingExtendedProfiles = useMemo(
    () =>
      _.filter(
        pendingProfiles,
        ({ resumeData }) => resumeData.subStep === 'pending-extended',
      ),
    [pendingProfiles],
  );
  const pendingExtendedCount = _.size(pendingExtendedProfiles);
  const {
    firstProfiles: firstPendingExtendedProfiles,
    remainder: pendingExtendedRemainder,
  } = useMemo(() => getFirstProfilesAndRemainder(pendingExtendedProfiles, 2), [
    pendingExtendedProfiles,
  ]);

  if (isHold && pendingCount === 0) {
    return <OfferHoldColumn offerId={offerId} double />;
  }

  return (
    <>
      <OfferProfileColumn
        count={pendingMainCount}
        profiles={firstPendingMainProfiles}
        remainder={pendingMainRemainder}
        onClick={handlerForStep('pending-main')}
      />
      <OfferProfileColumn
        count={pendingExtendedCount}
        profiles={firstPendingExtendedProfiles}
        remainder={pendingExtendedRemainder}
        onClick={handlerForStep('pending-extended')}
      />
    </>
  );
};

export default OfferPendingColumns;
