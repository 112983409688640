import useClientId from '@/hooks/router/useClientId';
import {
  HiresweetLibProvider,
  ProfileContainer,
  ProfileFooter,
} from '@hiresweet/hiresweet-lib';
import React from 'react';

const PlaygroundT4 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <HiresweetLibProvider theme={theme} clientId={clientId}>
      <h1>P-T-4 - Profile Footer</h1>
      <ProfileContainer profileId='jean-bombeur-45T0ZI'>
        <ProfileFooter />
      </ProfileContainer>
    </HiresweetLibProvider>
  );
};

export default PlaygroundT4;
