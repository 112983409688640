import gql from 'graphql-tag';
import SequenceWithProfiles, {
  sequenceWithMiniProfile,
} from './fragments/SequenceWithProfiles';

export const GET_SEQUENCE = gql`
  query getSequence($sequenceId: ID!) {
    sequence(id: $sequenceId) {
      ...SequenceWithProfiles
    }
  }
  ${SequenceWithProfiles}
`;

export const GET_SEQUENCE_WITH_MINI_PROFILES = gql`
  query getSequence($sequenceId: ID!) {
    sequence(id: $sequenceId) {
      ...SequenceWithMiniProfiles
    }
  }
  ${sequenceWithMiniProfile}
`;

export interface GetSequenceListResults {
  client: {
    id: string;
    sequences: Array<{
      id: string;
      title: string | null;
      isArchived: string | null;
    }> | null;
  };
}

export interface GetSequenceListVariables {
  clientId: string;
  activeOnly?: boolean;
}

export const GET_SEQUENCE_LIST = gql`
  query getSequenceList($clientId: ID!, $activeOnly: Boolean) {
    client(id: $clientId) {
      id
      sequences(filters: { activeOnly: $activeOnly }) {
        id
        title
        isArchived
      }
    }
  }
`;
