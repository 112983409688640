import { QueryHookOptions, gql, useQuery } from '@apollo/client';

import { MissionWithCategory } from '@/types/mission';

const getMissionWithCategory = gql`
  query getMissionWithCategory($searchPoolId: ID!, $missionId: ID!) {
    searchPool(id: $searchPoolId) {
      id
      job(id: $missionId) {
        id
        isArchived
        data {
          title
        }
        ... on RevealJob {
          missionCategory {
            type
          }
        }
      }
    }
  }
`;

type Data = {
  searchPool: {
    id: string;
    job: MissionWithCategory;
  };
};

type Variables = {
  searchPoolId: string;
  missionId: string;
};

type Input = {
  missionId: string;
  searchPoolId?: string;
  queryOptions?: QueryHookOptions<Data, Variables>;
};

function useMissionWithCategory({
  missionId,
  searchPoolId = 'reveal',
  queryOptions = {},
}: Input) {
  const query = useQuery<Data, Variables>(getMissionWithCategory, {
    ...queryOptions,
    variables: {
      missionId,
      searchPoolId,
    },
  });
  const missionWithCategory = query.data?.searchPool?.job;
  return { ...query, missionWithCategory };
}

export default useMissionWithCategory;
