import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import {
  DailyCountReportStreamEvent,
  DailyCountReportStreamInput,
} from '@/types/statistics/reportStream';
import useClientId from '@/hooks/router/useClientId';

const getDailyCountAggregate = gql`
  query getDailyCountAggregate(
    $clientId: ID!
    $input: DailyCountReportStreamInput!
  ) {
    client(id: $clientId) {
      id
      dailyCountReportStreamAggregate(input: $input) {
        aggregatePeriod
        day
        counts {
          key
          value
        }
      }
    }
  }
`;

type GetDailyCountAggregateData = {
  client: {
    id: string;
    dailyCountReportStreamAggregate: DailyCountReportStreamEvent[];
  };
};

type GetDailyCountAggregateVariables = {
  clientId: string;
  input: DailyCountReportStreamInput;
};

interface UseDailyCountReportStreamAggregateInput
  extends DailyCountReportStreamInput {
  queryOptions?: QueryHookOptions<
    GetDailyCountAggregateData,
    GetDailyCountAggregateVariables
  >;
}

interface UseDailyCountReportStreamAggregateResult
  extends QueryResult<
    GetDailyCountAggregateData,
    GetDailyCountAggregateVariables
  > {
  dailyCountReportStreamAggregate: DailyCountReportStreamEvent[];
}

function useDailyCountReportStreamAggregate({
  reportStreamId,
  aggregatePeriod,
  startDate,
  endDate,
  scope,
  missionsFilter,
  queryOptions = {},
}: UseDailyCountReportStreamAggregateInput): UseDailyCountReportStreamAggregateResult {
  const clientId = useClientId();
  const query = useQuery<
    GetDailyCountAggregateData,
    GetDailyCountAggregateVariables
  >(getDailyCountAggregate, {
    ...queryOptions,
    variables: {
      clientId,
      input: {
        reportStreamId,
        aggregatePeriod,
        startDate,
        endDate,
        scope,
        missionsFilter,
      },
    },
  });

  const dailyCountReportStreamAggregate =
    query.data?.client.dailyCountReportStreamAggregate || [];

  return { ...query, dailyCountReportStreamAggregate };
}

export default useDailyCountReportStreamAggregate;
