import React from 'react';
import { Loader, Segment } from 'semantic-ui-react';

const ProfilePreviewLoading = () => (
  <div className='profile-preview'>
    <div className='resume-section-container resume-section-container-loader'>
      <Segment className='resume-data-card-placeholder'>
        <Loader active inline='centered' />
      </Segment>
    </div>
  </div>
);

export default ProfilePreviewLoading;
