import gql from 'graphql-tag';
import TranslatableText from './TranslatableText';

export default gql`
  fragment ContactFlowActionFormatFragment on ContactFlowActionFormat {
    helpText {
      ...TranslatableText
    }
    form {
      fields {
        id
        type
        subtype
        isMultiselect
        options {
          id
          title {
            ...TranslatableText
          }
          selected
        }
        title {
          ...TranslatableText
        }
        metadata {
          key
          value
        }
        value
        onEvents {
          rule {
            type
          }
          effect {
            type
            key
          }
        }
        condition {
          type
          key
          targetValues
          effect {
            type
          }
        }
        required
      }
    }
  }
  ${TranslatableText}
`;
