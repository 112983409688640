import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Popup } from 'semantic-ui-react';
import classNames from 'classnames';
import { SearchPoolMiniProfile } from '@/graphql/searchPoolMiniProfileById';
import { Sequence } from '@/types/sequence';

import GenericToggle from '@/components/Common/Toggle/Toggle'; 
import SequencePreview from '@/revealComponents/SequencePreview';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import ProfileRowSequence from '../../../ProfileRow/ProfileRowSequence';

import styles from './ReapplySequenceModal.module.less';

interface UpdatePreviewProps {
  titleText?: string;
  toggleMode?: boolean;
  onSwitchToggle?: () => void;
  isEnabled?: boolean;
  profiles: SearchPoolMiniProfile[];
  sequence: Sequence;
  numberOfProfileActionsToKeep: number;
  sequenceSuffixIndex: number;
  clientId: string;
}

const UpdatePreview: React.FC<UpdatePreviewProps> = ({
  toggleMode,
  onSwitchToggle,
  isEnabled,
  titleText,
  profiles,
  sequence,
  numberOfProfileActionsToKeep,
  sequenceSuffixIndex,
  clientId,
}) => {
  const isPlugin = useIsPlugin();
  const { t } = useTranslation('translations');
  const profilesUpdatePreview = getProfilesUpdatePreview({
    profiles,
    sequence,
    numberOfProfileActionsToKeep,
    sequenceSuffixIndex,
  });

  return (
    <div className={styles.previewContainer}>
      <div className={styles.previewHeader}>
        <h4 className={styles.previewHeaderTitle}>
          {toggleMode && (
            <GenericToggle
              className={styles.previewToggle}
              checked={isEnabled}
              onChange={() => onSwitchToggle && onSwitchToggle()}
              toggle
            />
          )}
          {titleText || t('contactFlow.reapplySequence.preview')}
        </h4>
      </div>
      <div className={styles.preview}>
        {_.map(profilesUpdatePreview, (profile) => {
          const profileCurrentSequence = _.findWhere(
            profile?.contactFlow?.sequences || [],
            { isCurrent: true },
          );
          return (
            <React.Fragment key={profile.id}>
              <div
                className={classNames(
                  styles.cell,
                  'name-and-headline-container',
                )}
              >
                <h5 className='full-name'>
                  {profile?.resumeData?.firstname || ''}{' '}
                  {profile?.resumeData?.lastname || ''}
                </h5>
              </div>

              <div
                className={classNames(styles.cell, styles.sequenceCellContainer)}
              >
                <Popup
                  className={`sequence-preview-popup${
                    isPlugin ? '-plugin' : ''
                  }`}
                  basic
                  on='hover'
                  hoverable
                  position='left center'
                  trigger={
                    <div>
                      <ProfileRowSequence
                        hideTitle
                        currentSequenceInfo={profile.currentSequenceInfo}
                        sequences={profile?.contactFlow?.sequences}
                        nextInteraction={null}
                        loading={false}
                        isStandalone={false}
                        mini
                        styles={styles.sequenceRow}
                      />
                    </div>
                  }
                  content={
                    <div>
                      <SequencePreview
                        clientId={clientId}
                        sequence={{
                          contactFlow: { sequences: [profileCurrentSequence] },
                        }}
                      />
                    </div>
                  }
                  popperModifiers={[
                    {
                      // Fix a positioning problem
                      name: 'preventOverflow',
                      options: {
                        boundariesElement: 'viewport',
                      },
                    }
                  ]}
                  size='large'
                />
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

const getProfilesUpdatePreview = ({
  profiles,
  sequence,
  numberOfProfileActionsToKeep,
  sequenceSuffixIndex,
}: {
  profiles: SearchPoolMiniProfile[];
  sequence: Sequence;
  numberOfProfileActionsToKeep: number;
  sequenceSuffixIndex: number;
}): SearchPoolMiniProfile[] => {
  return _.map(profiles || [], (profile) => {
    const newProfileSequences = getProfileSequencesUpdatePreview({
      profile,
      sequence,
      numberOfProfileActionsToKeep,
      sequenceSuffixIndex,
    });
    return {
      ...profile,
      contactFlow: {
        ...profile?.contactFlow,
        sequences: newProfileSequences,
      },
    };
  });
};

const getProfileSequencesUpdatePreview = ({
  profile,
  sequence,
  numberOfProfileActionsToKeep,
  sequenceSuffixIndex,
}: {
  profile: SearchPoolMiniProfile;
  sequence: Sequence;
  numberOfProfileActionsToKeep: number;
  sequenceSuffixIndex: number;
}) => {
  return _.map(profile?.contactFlow?.sequences || [], (profileSequence) => {
    if (!profileSequence?.isCurrent) {
      return profileSequence;
    }
    const profileActionsToKeep = _.first(
      profileSequence.actions,
      numberOfProfileActionsToKeep,
    );
    const sequenceActions =
      sequence?.contactFlow?.sequences?.[0]?.actions || [];
    const sequenceActionsToAppend = sequenceActions.slice(sequenceSuffixIndex);
    return {
      ...profileSequence,
      actions: profileActionsToKeep.concat(sequenceActionsToAppend),
    };
  });
};

export default UpdatePreview;
