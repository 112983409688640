import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import JobPosting from '../../graphql/fragments/JobPosting';

export const unPublishJobPosting = gql`
  mutation unPublishJobPosting($input: UnPublishJobPostingInput!) {
    unPublishJobPosting(input: $input) {
      jobPosting {
        ...JobPosting
      }
    }
  }
  ${JobPosting}
`;

export default graphql(unPublishJobPosting, {
  props: ({ mutate }) => ({
    unPublishJobPosting: ({ input }) => mutate({ variables: { input } }),
  }),
});
