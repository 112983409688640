import React from 'react';
import { useTranslation } from 'react-i18next';

const Visual1 = () => {
  const { t } = useTranslation();
  return (
    <div className='visual1'>
      <div className='loom-embed'>
        <iframe
          title='loom-embed'
          src='https://www.loom.com/embed/0e2fd1f4d812453cbb738e6bb6a9fc05?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true'
          frameBorder='0'
          allowFullScreen
        />
      </div>
      <h3 className='embed-title'>
        {t('reveal.onboardingModal.visual1.caption')}
      </h3>
    </div>
  );
};

export default Visual1;
