import _ from 'underscore';

export const FONT_SIZE_SMALL = 'FONT_SIZE_SMALL';
export const FONT_SIZE_NORMAL = 'FONT_SIZE_NORMAL';
export const FONT_SIZE_BIG = 'FONT_SIZE_BIG';
export const FONT_SIZE_HUGE = 'FONT_SIZE_HUGE';
export const FONT_SIZE_STYLES = [
  FONT_SIZE_SMALL,
  FONT_SIZE_NORMAL,
  FONT_SIZE_BIG,
  FONT_SIZE_HUGE,
];
export const STYLE_MAP: Record<
  string,
  { fontWeight?: number; fontSize?: string }
> = {
  BOLD: {
    fontWeight: 500,
  },
  [FONT_SIZE_SMALL]: {
    fontSize: '10px',
  },
  [FONT_SIZE_BIG]: {
    fontSize: '18px',
  },
  [FONT_SIZE_HUGE]: {
    fontSize: '32px',
  },
};

const getReverseStyleMap = () => {
  const reverseStyleMap: Record<string, string> = {};
  _.mapObject(STYLE_MAP, (value, key) => {
    if (value?.fontSize) {
      reverseStyleMap[value?.fontSize] = key;
    }
  });
  return reverseStyleMap;
};
export const REVERSE_FONT_SIZE_STYLE_MAP = getReverseStyleMap();
