import React from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';
import PendingAction from './PendingAction';

import './PendingActions.css';

const PendingActions = ({ profile, contactFlow, clientId, t }) => {
  if (_.isEmpty(contactFlow)) {
    return null;
  }
  const currentSequence = _.findWhere(contactFlow?.sequences, { isCurrent: true });
  if (_.isEmpty(currentSequence)) {
    return null;
  }
  const currentSequenceIsCompleted = currentSequence?.completion?.isCompleted;
  if (currentSequenceIsCompleted) {
    return null;
  }
  const firstAction = (currentSequence?.actions || [])[0];
  const currentSequenceHasStarted = firstAction?.completion?.isCompleted;
  if (!currentSequenceHasStarted) {
    return null;
  }
  const pendingActions = _.filter(currentSequence?.actions, (action) => !action?.completion?.isCompleted);
  if (_.isEmpty(pendingActions)) {
    return null;
  }
  return (
    <div className='pending-actions'>
      <div style={{ textAlign: 'center', paddingTop: 10 }} />
      <h3 className='section-title'>{t('profile.contact.timeline.toCome')}</h3>
      {_.map([...pendingActions].reverse(), (action) => (
        <PendingAction
          key={action.actionId}
          profile={profile}
          action={action}
          sequenceId={currentSequence?.id}
          clientId={clientId}
          actions={currentSequence?.actions || []}
        />
      ))}
    </div>
  );
};

export default withTranslation('translations')(PendingActions);
