import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { SessionSearchContextProvider } from '@/context/SessionSearchContext';
import MainLayout from '../../containers/MainLayout';
import routerParamsToProps from '../../hocs/routerParamsToProps';
import { WatchCollectAllCandidates } from '../WatchCollectAllCandidates';
import WatchCollectFollowup from './WatchCollectFollowup';
import './WatchCollectViewV2.css';

const WatchCollectViewV2 = ({ clientId, tabId }) => {
  const [cardMode, setCardMode] = useState(
    localStorage.getItem('sessionCardMode') || 'large',
  ); // ['small', 'large']

  useEffect(() => {
    localStorage.setItem('sessionCardMode', cardMode);
  }, [cardMode]);

  return (
    <MainLayout pageId='collect' clientId={clientId} tabId={tabId} hideFooter>
      <div
        className='watch-collect-content-container'
        id='watch-collect-content-container'
      >
        <Switch>
          <Route
            exact
            path='/client/:clientId/discover/recommendations'
            render={({ location }) => {
              if (location?.search) {
                return (
                  <Redirect
                    to={`/client/${clientId}/discover/candidates${location?.search}`}
                    replace
                  />
                );
              }
              return <Redirect to={`/client/${clientId}/jobs`} replace />;
            }}
          />
          <Route exact path='/client/:clientId/discover/candidates'>
            <SessionSearchContextProvider sessionSearchKey='sessionSearch'>
              <WatchCollectAllCandidates
                clientId={clientId}
                cardMode={cardMode}
                setCardMode={setCardMode}
                searchPoolId='watch'
                version='v2'
              />
            </SessionSearchContextProvider>
          </Route>
          <Route exact path='/client/:clientId/discover/followup/:offerId?'>
            <WatchCollectFollowup clientId={clientId} includeUnclassified />
          </Route>
        </Switch>
      </div>
    </MainLayout>
  );
};

export default routerParamsToProps(WatchCollectViewV2);
