import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const mutation = gql`
  mutation enableAutomationMode {
    enableClientMode(mode: "automation") {
      id
      permissions {
        projectDynamicImports
        unipile
        whatsappSequenceActions
        canEditAutomationIntegrations
      }
    }
  }
`;

type Data = {
  enableClientMode: {
    id: string;
    permissions: {
      projectDynamicImports: boolean;
      unipile: boolean;
      whatsappSequenceActions: boolean;
    };
  };
};

type Variables = Record<string, never>;

type Input = {
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

const useEnableAutomationMode = ({ mutationOptions = {} }: Input = {}) =>
  useMutation<Data, Variables>(mutation, mutationOptions);

export default useEnableAutomationMode;
