/* eslint-disable max-len */
import * as Sentry from '@sentry/browser';
import _ from 'underscore';

import baseSkillOptions from '@/common/options/skillOptions.json';
import revealSkillOptions from '@/common/options/revealSkillExtraOptions.json';

import { getTranslatedText } from '../../../../../common';
import { isValidDate } from '../../../../../common/dates';
import industryOptions from '../../../../../common/options/industryOptions.json';
import jobPositionOptions from '../../../../../common/options/revealJobPositionExtraOptions.json';
import locationOptions from '../../../../../common/options/revealLocationOptions.json';
import { getApplicationStageAndStatusText } from '../../../../../containers/Profile/Resume/Card/GreenhouseData';

const skillOptions = [...baseSkillOptions, ...revealSkillOptions];

export const formatSectionsData = ({ criteria }) => {
  try {
    const {
      experience,
      skills,
      jobPosition,
      locations,
      industries,
      backgroundSolidity,
      schoolPrestige,
      startupnessImportance,
      managementLevels,
    } = criteria || {};

    // skills
    const { main, important, bonus } = skills || {};
    const getSkillsList = (category) =>
      _.isEmpty((category || [])[0]?.skills) ? null : category[0]?.skills;
    const mainSkills = getSkillsList(main);
    const importantSkills = getSkillsList(important);
    const bonusSkills = getSkillsList(bonus);

    // jobPosition
    const majorJobs = _.isEmpty(jobPosition?.major)
      ? null
      : [jobPosition?.major];
    const minorJobs = _.isEmpty(jobPosition?.minors)
      ? null
      : _.sortBy(jobPosition?.minors, ({ score }) => -score);

    // locations
    const locationsTarget = _.isEmpty(locations?.target)
      ? null
      : locations?.target;

    // industries
    const industriesTarget = _.isEmpty(industries?.target)
      ? null
      : industries?.target;

    // managementLevels
    const managementLevelsTarget = _.isEmpty(managementLevels?.target)
      ? null
      : managementLevels?.target;

    return {
      ...(experience && { experience: formatExperienceData({ experience }) }),
      ...(mainSkills && {
        mainSkills: formatSkillsData({ skills: mainSkills, value: 'Main' }),
      }),
      ...(importantSkills && {
        importantSkills: formatSkillsData({
          skills: importantSkills,
          value: 'Important',
        }),
      }),
      ...(bonusSkills && {
        bonusSkills: formatSkillsData({ skills: bonusSkills, value: 'Bonus' }),
      }),
      ...(majorJobs && {
        majorJobPosition: formatJobPositionData({
          jobs: majorJobs,
          value: 'Major',
        }),
      }),
      ...(minorJobs && {
        minorJobPositions: formatJobPositionData({
          jobs: minorJobs,
          value: 'Minors',
        }),
      }),
      ...(locationsTarget && {
        locationsTarget: formatLocationsData({ locations: locationsTarget }),
      }),
      ...(industriesTarget && {
        industriesTarget: formatIndustriesData({
          industries: industriesTarget,
        }),
      }),
      ...(managementLevelsTarget && {
        managementLevelsTarget: formatManagementLevelsData({
          managementLevels: managementLevelsTarget,
        }),
      }),
      ...(backgroundSolidity && {
        backgroundSolidity: formatBackgroundData({ backgroundSolidity }),
      }),
      ...(schoolPrestige && {
        schoolPrestige: formatSchoolPrestigeData({ schoolPrestige }),
      }),
      ...(startupnessImportance && {
        startupnessImportance: formatStartupnessData({ startupnessImportance }),
      }),
    };
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
};

const formatExperienceData = ({ experience }) => {
  const { score, value } = experience || {};
  const nbYearsStr = getNbYearsStr({ value });
  if (!nbYearsStr) {
    return null;
  }
  return {
    title: 'Experience',
    value: nbYearsStr,
    score,
  };
};

const getNbYearsStr = ({ value }) => {
  if (!_.isNumber(value)) {
    return null;
  }
  if (value < 1) {
    return '<1 year';
  }
  if (Math.floor(value) === 1) {
    return '1 year';
  }
  return `${Math.floor(value)} years`;
};

const formatSkillsData = ({ skills, value }) => {
  return {
    title: 'Skills',
    value,
    score: skills?.score,
    labels: getLabelsFromItems({
      items: skills,
      options: skillOptions,
      key: 'skillId',
    }),
  };
};

const formatJobPositionData = ({ jobs, value }) => {
  return {
    title: 'Job position',
    value,
    // score
    labels: getLabelsFromItems({
      items: jobs,
      options: jobPositionOptions,
      key: 'jobId',
    }),
  };
};

export const getRoundedScore = ({ score }) => {
  let cleanScore = score || 0;
  if (cleanScore > 1) {
    cleanScore = 1;
  }
  return Math.floor(cleanScore * 100);
};

export const getClassName = (score) => {
  if (score >= 0.9) {
    return 'near-perfect';
  }
  if (score >= 0.7) {
    return 'high';
  }
  if (score >= 0.5) {
    return 'medium';
  }
  if (score >= 0) {
    return 'low';
  }
  if (score < 0) {
    return 'negative';
  }
  return 'normal';
};

export const getLabelsFromItems = ({ items, options, key }) => {
  const result = _.map(items, (item) => ({
    label: _.findWhere(options, { value: item[key] })?.text,
    // TODO: TEMP HACK when validated is available but not score
    score: item?.score || (item?.validated ? 1 : 0),
  }));
  // TODO: TEMP HACK to remove labels with no score (should mean not validated / no match)
  return _.reject(result, ({ score }) => !score);
};

/* prettier-ignore */
/* eslint-disable max-len */
export const formatAtsSectionsData = ({ atsExplanation, t }) => {
  try {
    const {
      recruiter,
      coordinator,
      creationDate,
      lastActivityDate,
      applications,
      tags,
    } = atsExplanation || {};
    return {
      ...(tags && { tags: formatTagsData({ tags, title: 'Tags' }) }),
      ...(recruiter && { recruiter: formatUserData({ user: recruiter, title: 'Recruiter' }) }),
      ...(coordinator && { coordinator: formatUserData({ user: coordinator, title: 'Coordinator' }) }),
      ...(creationDate && { creationDate: formatDateData({ dateStr: creationDate, title: 'Creation date', t }) }),
      ...(lastActivityDate &&
        { lastActivityDate: formatDateData({ dateStr: lastActivityDate, title: 'Last action date', t }) }),
      ...(!_.isEmpty(applications) &&
        { applications: _.map(applications, (application) => formatApplicationData({ application })) }),
    };
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
};

const formatTagsData = ({ tags, title }) => {
  return {
    title,
    labels: getTagsLabels({ tags }),
  };
};

export const getTagsLabels = ({ tags }) => {
  return _.map(tags, ({ /* sourceTagId, */ name }) => ({
    label: getTranslatedText(name),
    score: 1,
  }));
};

const formatUserData = ({ user, title }) => {
  const userLabel = getUserLabel({ user });
  return {
    title,
    ...(userLabel && { labels: [userLabel] }),
  };
};

export const getUserLabel = ({ user }) => {
  if (_.isEmpty(user)) {
    return null;
  }
  return {
    label: getFullname(user),
    score: 0.9,
  };
};

const formatDateData = ({ dateStr, title, t }) => {
  const date = new Date(dateStr);
  if (!isValidDate(date)) {
    return null;
  }
  return {
    title,
    value: t('common.simpleDate', { date }),
  };
};

export const getDateLabel = ({ prefix, dateStr, t }) => {
  if (!dateStr || !_.isString(dateStr)) {
    return null;
  }
  const date = new Date(dateStr);
  if (!isValidDate(date)) {
    return null;
  }
  return {
    label: `${prefix}${t('common.simpleDate', { date })}`,
    score: 0.9,
  };
};

const getFullname = (user) => {
  const { firstname, lastname } = user || {};
  return `${firstname} ${lastname}`.trim();
};

export const getApplicationLabels = (application) => {
  const statusText = getApplicationStageAndStatusText(application);
  if (!statusText) {
    return null;
  }
  return [
    {
      label: statusText,
      score: 0.8,
    },
  ];
};

const formatApplicationData = ({ application }) => {
  const job = (application?.jobs || [])[0];
  const labels = getApplicationLabels(application);
  return {
    key: job?.sourceJobId,
    title: 'Application',
    value: job?.title || '',
    ...(labels && { labels }),
  };
};

const formatLocationsData = ({ locations }) => {
  const labels = getLabelsFromItems({
    items: locations,
    options: locationOptions,
    key: 'locationId',
  });
  return {
    title: 'Locations',
    labels: _.sortBy(labels, ({ score }) => -score),
  };
};

const formatIndustriesData = ({ industries }) => {
  const labels = getLabelsFromItems({
    items: industries,
    options: industryOptions,
    key: 'industryId',
  });
  return {
    title: 'Industries',
    labels: _.sortBy(labels, ({ score }) => -score),
  };
};

const formatManagementLevelsData = ({ managementLevels }) => {
  const labels = getLabelsFromItems({
    items: managementLevels,
    options: industryOptions,
    key: 'industryId',
  });
  return {
    title: 'ManagementLevels',
    labels: _.sortBy(labels, ({ score }) => -score),
  };
};

const formatBackgroundData = ({ backgroundSolidity }) => {
  return {
    title: 'Background',
    value: 'Background strength',
    labels: getBackgroundSolidityLabels({ backgroundSolidity }),
  };
};

export const getBackgroundSolidityLabels = ({ backgroundSolidity }) => {
  if (_.isEmpty(backgroundSolidity)) {
    return null;
  }

  // TODO: improve and adapt interpretation
  const getLabelText = ({ value }) => {
    if (value > 4) {
      return 'Very strong background';
    }
    if (value > 3) {
      return 'Strong background';
    }
    if (value > 2) {
      return 'Average background';
    }
    if (value > 1) {
      return 'Weak background';
    }
    return 'Very weak background';
  };

  return [
    {
      label: getLabelText({ value: backgroundSolidity?.value }),
      score: backgroundSolidity?.target?.score,
    },
  ];
};

const formatSchoolPrestigeData = ({ schoolPrestige }) => {
  return {
    title: 'Background',
    value: 'School prestige',
    labels: getSchoolPrestigeLabels({ schoolPrestige }),
  };
};

export const getSchoolPrestigeLabels = ({ schoolPrestige }) => {
  if (_.isEmpty(schoolPrestige)) {
    return null;
  }
  // TODO: improve
  const getLabelText = ({ value }) => {
    if (value === 'very-top') {
      return 'Very prestigious school';
    }
    if (value === 'top') {
      return 'Prestigious school';
    }
    if (value === 'medium') {
      return 'Average school prestige';
    }
    if (value === 'low') {
      // return 'Low school prestige';
      return null; // show nothing in this case ?
    }
    if (value === 'bootcamp') {
      return 'Bootcamp';
    }
    return null;
  };

  const label = getLabelText({ value: schoolPrestige?.value });
  if (!label) {
    return null;
  }
  return [{ label, score: schoolPrestige?.score }];
};

const formatStartupnessData = ({ startupnessImportance }) => {
  return {
    title: 'Background',
    value: 'Startupness importance',
    labels: getStartupnessLabels({ startupnessImportance }),
  };
};

export const getStartupnessLabels = ({ startupnessImportance }) => {
  if (_.isEmpty(startupnessImportance)) {
    return null;
  }
  // TODO: improve
  const getLabelText = ({ value }) => {
    if (value === 'high') {
      return 'High startupness';
    }
    if (value === 'medium') {
      return 'Medium startupness';
    }
    return 'Low startupness';
  };

  return [
    {
      label: getLabelText({ value: startupnessImportance?.value }),
      score: startupnessImportance?.score,
    },
  ];
};
