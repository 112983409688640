import React, { useMemo } from 'react';
import _ from 'underscore';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Dots from '@/components/Common/Icons/Dots';
import GenericButton from '@/components/Common/GenericButton';
import DropdownPanel from '@/components/Common/DropdownPanel';
import DropdownMenuItem from '@/components/Common/DropdownMenuItem';
import Edit from '@/components/Reveal/Icons/Edit';
import Delete from '@/components/Reveal/Icons/Delete';
import { SharedMessagingAccount } from '@/graphql/hooks/clients/useSharedServiceAccount';
import {
  ICON_FROM_SERVICE,
  SERVICE_FROM_SUBTYPE,
} from '@/containers/Profile/Contact/TimeLineItems/UnipileMessageItem/UnipileMessageItem';
import classNames from 'classnames';
import Retry from '@/components/Reveal/Icons/Retry';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import AbsoluteDropdown from '@/components/Common/AbsoluteDropdown';
import DropdownControlsContext from '@/context/DropdownControlsContext';
import styles from '../AutomationIntegrationsTable/AutomationIntegrationsTable.module.less';

interface UnipileAutomationTableCommonProps {
  onEditAccount: ({ accountId }: { accountId: string }) => void;
  onDeleteAccount: ({ accountId }: { accountId: string }) => void;
  onReconnectAccount: ({ accountId }: { accountId: string }) => void;
}

interface UnipileAutomationTableProps {
  accounts: SharedMessagingAccount[];
}

const UnipileAutomationTable: React.FC<
  UnipileAutomationTableProps & UnipileAutomationTableCommonProps
> = ({ accounts, onEditAccount, onDeleteAccount, onReconnectAccount }) => {
  const { user } = useCurrentUser();
  if (_.isEmpty(accounts)) {
    return null;
  }
  return (
    <Table basic className={styles.table}>
      <UnipileAutomationHeader />
      <UnipileAutomationBody
        accounts={accounts}
        currentUser={user}
        onEditAccount={onEditAccount}
        onDeleteAccount={onDeleteAccount}
        onReconnectAccount={onReconnectAccount}
      />
    </Table>
  );
};

export const UnipileAutomationHeader = () => {
  const { t } = useTranslation();
  return (
    <Table.Header className={styles.header}>
      <Table.Row className={styles.headerRow}>
        <Table.HeaderCell collapsing className={styles.th} />
        <Table.HeaderCell className={styles.th}>
          <span>{t('settings.automations.unipile.table.name')}</span>
        </Table.HeaderCell>
        <Table.HeaderCell className={styles.th}>
          <span>{t('settings.automations.unipile.table.status')}</span>
        </Table.HeaderCell>
        <Table.HeaderCell className={styles.th}>
          <span>{t('settings.automations.unipile.table.scope')}</span>
        </Table.HeaderCell>
        <Table.HeaderCell className={styles.th}>
          <span>{t('settings.automations.unipile.table.sharedWith')}</span>
        </Table.HeaderCell>
        <Table.HeaderCell collapsing className={styles.th} />
      </Table.Row>
    </Table.Header>
  );
};

export const UnipileAutomationBody: React.FC<
  UnipileAutomationTableProps &
    UnipileAutomationTableCommonProps & { currentUser: { email: string } }
> = ({
  accounts,
  currentUser,
  onEditAccount,
  onDeleteAccount,
  onReconnectAccount,
}) => {
  return (
    <Table.Body>
      {_.map(accounts, (account) => (
        <UnipileAutomationRow
          key={account.id}
          account={account}
          currentUser={currentUser}
          onEditAccount={onEditAccount}
          onDeleteAccount={onDeleteAccount}
          onReconnectAccount={onReconnectAccount}
        />
      ))}
    </Table.Body>
  );
};

const ACCOUNT_SCOPE_TYPES: Record<string, string> = {
  salesnavigator: 'Sales Navigator',
  recruiter: 'Recruiter',
  premium: 'Premium',
} as const;

interface UnipileAutomationRowProps {
  account: SharedMessagingAccount;
}

export const UnipileAutomationRow: React.FC<
  UnipileAutomationRowProps &
    UnipileAutomationTableCommonProps & {
      currentUser: { email: string; isAdmin: boolean };
    }
> = ({
  account,
  currentUser,
  onEditAccount,
  onDeleteAccount,
  onReconnectAccount,
}) => {
  const { t } = useTranslation();
  const { id, name, serviceAccountId, type, status, users, scopes } =
    account || {};

  const onEdit = () => {
    onEditAccount({ accountId: serviceAccountId });
  };

  const onDelete = () => {
    onDeleteAccount({ accountId: serviceAccountId });
  };

  const onReconnect = () => {
    onReconnectAccount({ accountId: serviceAccountId });
  };

  const displayedScope = useMemo(
    () =>
      ACCOUNT_SCOPE_TYPES[
        _.findWhere(scopes || [], { type: 'salesnavigator' })
          ? 'salesnavigator'
          : _.findWhere(scopes || [], { type: 'recruiter' })
            ? 'recruiter'
            : _.findWhere(scopes || [], { type: 'premium' })
              ? 'premium'
              : ''
      ],
    [scopes],
  );

  const isOwner =
    currentUser?.email && currentUser?.email === account.owner?.email;

  return (
    <Table.Row key={id} className={styles.row}>
      <Table.Cell textAlign='left' className={styles.unipileIcon}>
        <div
          style={{
            backgroundColor: ICON_FROM_SERVICE[type].background,
          }}
          className={styles.icon}
        >
          <i
            style={{
              color: ICON_FROM_SERVICE[type].color,
            }}
            className={`${ICON_FROM_SERVICE[type].icon}`}
          />
        </div>
      </Table.Cell>
      <Table.Cell textAlign='left' className={styles.name}>
        <span>{name}</span>
      </Table.Cell>

      <Table.Cell textAlign='left' className={styles.statusContainer}>
        {status === 'valid' ? (
          <span className={classNames(styles.status, 'pill-message green')}>
            <i className='ri-checkbox-circle-fill' />
            <span>{t('settings.automations.unipile.connected')}</span>
          </span>
        ) : (
          <span className={classNames(styles.status, 'pill-message red')}>
            <i className='ri-checkbox-circle-fill' />
            <span>{t('settings.automations.unipile.disconnected')}</span>
          </span>
        )}
      </Table.Cell>

      <Table.Cell textAlign='left' className={styles.statusContainer}>
        <span className={styles.type}>
          {SERVICE_FROM_SUBTYPE[type]}{' '}
          {SERVICE_FROM_SUBTYPE[type] === 'LinkedIn' && displayedScope
            ? `(${displayedScope})`
            : ''}
        </span>
      </Table.Cell>

      <Table.Cell textAlign='left'>
        <div className={styles.sharedWithContainer}>
          {_.isEmpty(users) ? (
            <span className={styles.status}>-</span>
          ) : (
            _.map(users, (user) => <span key={user.email}>{user.email}</span>)
          )}
        </div>
      </Table.Cell>

      <Table.Cell className={styles.actions}>
        {isOwner || currentUser.isAdmin ? (
          <AbsoluteDropdown
            position='bottom right'
            trigger={
              <DropdownControlsContext.Consumer>
                {({ toggleDropdown }) => (
                  <GenericButton primacy='tertiary' onClick={toggleDropdown}>
                    <Dots />
                  </GenericButton>
                )}
              </DropdownControlsContext.Consumer>
            }
          >
            <DropdownPanel className={styles.actionPanel}>
              {status === 'invalid' && isOwner && (
                <DropdownMenuItem
                  className={styles.actionPanelItem}
                  onClick={() => {
                    onReconnect();
                  }}
                >
                  <span>
                    <Retry />
                  </span>
                  {t('settings.automations.unipile.reconnect')}
                </DropdownMenuItem>
              )}
              {isOwner && (
                <DropdownMenuItem
                  className={styles.actionPanelItem}
                  onClick={() => {
                    onEdit();
                  }}
                >
                  <span>
                    <Edit />
                  </span>
                  {t('common.edit')}
                </DropdownMenuItem>
              )}
              <DropdownMenuItem
                className={styles.actionPanelItem}
                onClick={() => {
                  onDelete();
                }}
              >
                <span>
                  <Delete />
                </span>
                {t('common.delete')}
              </DropdownMenuItem>
            </DropdownPanel>
          </AbsoluteDropdown>
        ) : null}
      </Table.Cell>
    </Table.Row>
  );
};

export default UnipileAutomationTable;
