import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import {
  ACCEPT_CONDITIONS_WITH_TARGET_TEXT,
  ACCEPT_CONDITIONS_WITH_TARGET_TEXTS,
} from '@/common/mergeTags/utils';
import { useConditionsChainingContext } from '../useConditionsChainingContext';
import styles from '../ConditionsChaining.module.less';
import { getStringAcceptConditionOptions } from '../utils';

const ConditionsChainingDropdownAccept: React.FC<{
  value: string;
  nodeId: string;
  fieldType: string;
}> = ({ value, nodeId, fieldType }) => {
  const {
    getStateTreeAfterReplacement,
    conditionsChainingState,
    setConditionsChainingState,
  } = useConditionsChainingContext();
  const { t } = useTranslation();

  const handleChangeAcceptCondition = ({ newValue }: { newValue: string }) => {
    const newState = getStateTreeAfterReplacement<any>({
      node: conditionsChainingState,
      id: nodeId,
      newValue: {
        id: nodeId,
        type: newValue as any,
        ...(ACCEPT_CONDITIONS_WITH_TARGET_TEXT.includes(newValue) && {
          targetText: '',
        }),
        ...(ACCEPT_CONDITIONS_WITH_TARGET_TEXTS.includes(newValue) && {
          targetTexts: [],
        }),
      },
    });
    setConditionsChainingState(newState);
  };

  return (
    <Dropdown
      className={styles.input}
      value={value}
      fluid
      selection
      options={getStringAcceptConditionOptions({ t, fieldType })}
      onChange={(e, { value: newValue }) =>
        handleChangeAcceptCondition({ newValue: newValue as string })
      }
    />
  );
};

export default ConditionsChainingDropdownAccept;
