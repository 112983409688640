import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRevealActivityContext } from '@/context/JobView/useRevealActivityProvider';
import styles from './ActivityFeed.module.less';


const ActivityModalTrigger: React.FC = () => {
  const { t } = useTranslation();
  const { openModal } = useRevealActivityContext();
  return (
    <span onClick={openModal} aria-hidden='true' className={styles.activityFeedTrigger}>
      {t('reveal.dashboardView.activity.trigger')}
    </span>
  );
};

export default ActivityModalTrigger;
