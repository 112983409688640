import React, { Dispatch, SetStateAction } from 'react';
import _ from 'underscore';
import classnames from 'classnames';
import { Input, Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { FormField } from '@/components/FormFields';
import useClientUsers from '@/graphql/hooks/clients/useClientUsers';
import useClientId from '@/hooks/router/useClientId';
import { getUserOptions } from '@/routes/RevealView/optionsHelpers';
import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';
import { User } from './helpers';

import styles from './CSVPreview.module.less';

type CSVOptionsFormProps = {
  fileName: string;
  setFileName: (fileName: string) => void;
  recruiter: User;
  setRecruiter: (recruiter: User) => void;
  firstLineIsHeader: boolean;
  setFirstLineIsHeader: Dispatch<SetStateAction<boolean>>;
};

export default function CSVOptionsForm({
  fileName,
  setFileName,
  recruiter,
  setRecruiter,
  firstLineIsHeader,
  setFirstLineIsHeader,
}: CSVOptionsFormProps): JSX.Element {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { users } = useClientUsers(clientId);
  const onRecruiterChange = (
    email: string | number | boolean | undefined | unknown[],
  ) => {
    if (!_.isString(email) || Array.isArray(email)) return;
    const selectedRecruiter = _.findWhere(users, { email });
    if (!selectedRecruiter) return;
    setRecruiter({
      email,
      firstname: selectedRecruiter.firstname,
      lastname: selectedRecruiter.lastname,
    });
  };
  return (
    <div className='csv-preview__header'>
      <div className='csv-preview__header-item'>
        <FormField>
          <FormField.Label htmlFor='field-importName'>
            {t('CSVImport.importNameLabel')}
          </FormField.Label>
          <FormField.Wrapper>
            <Input
              fluid
              className={classnames('form-row-input', styles.inputFilename)}
              id='field-importName'
              value={fileName}
              error={!fileName.length}
              onChange={(event) => {
                if (setFileName) {
                  setFileName(event.target.value);
                }
              }}
              input={{ 'data-form-type': 'other' }}
            />
          </FormField.Wrapper>
        </FormField>
      </div>
      <div className='csv-preview__header-item'>
        <FormField>
          <FormField.Label htmlFor='field-recruiter'>
            {t('CSVImport.ownerLabel')}
          </FormField.Label>
          <FormField.Wrapper>
            <Dropdown
              id='field-recruiter'
              search
              fluid
              selection
              defaultValue={recruiter.email}
              options={getUserOptions(users)}
              onChange={(e, { value }) => onRecruiterChange(value)}
              size='medium'
              searchInput={{ 'data-form-type': 'other' }}
            />
          </FormField.Wrapper>
        </FormField>
      </div>
      <FormField className='checkbox-field'>
        <FormField.Label>
          {t('CSVImport.firstLineIsHeaderLabel')}
        </FormField.Label>

        <FormField.Wrapper>
          <LabeledCheckbox
            checked={firstLineIsHeader}
            label={t('CSVImport.fistLineIsHeader')}
            onClick={() => {
              setFirstLineIsHeader((previousValue) => !previousValue);
            }}
          />
        </FormField.Wrapper>
      </FormField>
    </div>
  );
}
