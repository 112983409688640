import React from 'react';
import classNames from 'classnames';
import { Button } from 'semantic-ui-react';


import './CardModeToggle.css';

// TODO: use ButtonToggle instead
const CardModeToggle = ({ className, cardMode, setCardMode }) => {
  return (
    <Button.Group className={classNames(className, 'toggle-buttons')}>
      <Button
        className={classNames(cardMode === 'large' && 'active')}
        onClick={() => setCardMode('large')}
      >
        <i className='ri-layout-grid-fill'/>
      </Button>
      <Button
        className={classNames(cardMode === 'small' && 'active')}
        onClick={() => setCardMode('small')}
      >
        <i className='ri-menu-fill'/>
      </Button>
    </Button.Group>
  );
};

export default CardModeToggle;
