import _ from 'underscore';
import React from 'react';
import TaskIcon from '../TaskIcon';

type Props = {
  sequence: any;
};

const SequenceActionsIcons: React.FC<Props> = ({ sequence }) => {
  const contactFlowSequence = (sequence?.contactFlow?.sequences || [])[0];

  if (
    !sequence ||
    !contactFlowSequence ||
    _.isEmpty(contactFlowSequence?.actions)
  ) {
    return null;
  }

  return (
    <div className='sequence-template-icons'>
      {contactFlowSequence.actions.map((action: any) => (
        <div className='icon-wrapper' key={action.actionId}>
          <TaskIcon action={action} />
        </div>
      ))}
    </div>
  );
};

export default SequenceActionsIcons;
