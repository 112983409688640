import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { CuratedAutoSendConfig } from '@/types/curatedAutoSend';

const getCuratedAutoSend = gql`
  query getCuratedAutoSend($offerId: ID!) {
    offer(id: $offerId) {
      id
      curatedAutoSend {
        isEnabled
        senderEmail
      }
    }
  }
`;

type Data = {
  offer: {
    id: string;
    curatedAutoSend?: CuratedAutoSendConfig;
  };
};

type Variables = {
  offerId: string;
};

type Input = {
  offerId: string;
  queryOptions?: QueryHookOptions<Data, Variables>;
};

type Result = QueryResult<Data, Variables> & {
  curatedAutoSend: CuratedAutoSendConfig;
};

function useCuratedAutoSend({ offerId, queryOptions = {} }: Input): Result {
  const query = useQuery<Data, Variables>(getCuratedAutoSend, {
    ...queryOptions,
    variables: { offerId },
  });

  const curatedAutoSend = query.data?.offer.curatedAutoSend || {
    isEnabled: false,
    senderEmail: null,
  };

  return { ...query, curatedAutoSend };
}

export default useCuratedAutoSend;
