import htmlToText from 'html-to-text';

export const getHtmlStringAsText = (htmlString) => {
  if (typeof htmlString !== 'string') {
    return '';
  }

  if (htmlString.indexOf('<div>') < 0) {
    return htmlString;
  }
  return htmlToText
    .fromString((htmlString || '').replace(/<\/div>/g, '</div><br/>'), {
      ignoreHref: true,
      ignoreImage: true,
      unorderedListItemPrefix: '• ',
      wordwrap: null,
    })
    .trim();
};
