import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SmallResumeData from '../../graphql/fragments/SmallResumeData';

export const query = gql`
  query getClientProfilesByNumber($clientId: ID!, $searchNumber: String) {
    client(id: $clientId) {
      id
      profilesByNumber(searchNumber: $searchNumber) {
        id
        jobOfferId
        resumeData {
          ...SmallResumeData
        }
        powerHourFlags {
          powerHourId
          isFlagged
        }
        firstContactDate
      }
    }
  }
  ${SmallResumeData}
`;

export default graphql(query, {
  options: (ownProps) => {
    return {
      variables: {
        clientId: ownProps.clientId,
      },
    };
  },
  props: ({ data: { loading, client, error } }) => ({
    loading,
    clientProfiles: client && client.profiles ? client.profiles : [],
    error,
  }),
});
