import React, { useCallback } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import useMinimizedView from '@/hooks/ui/useMinimizedView';

import styles from './PluginProfileFooter.module.less';

interface PluginProfileFooterProps {
  onMinimize?: () => void;
  messagingClient?: any;
}

const PluginProfileFooter: React.FC<PluginProfileFooterProps> = ({
  onMinimize,
  messagingClient,
}) => {
  const { isMinimized, minimize } = useMinimizedView();
  const { t, i18n } = useTranslation();

  const minimizeWithCallback = useCallback(() => {
    minimize();
    if (onMinimize) {
      onMinimize();
    }
  }, [minimize, onMinimize]);

  const isCalendarPlugin = window.location.href.includes(
    'hiresweet-for-calendar.html',
  );

  const handleLanguageChange = (value: string) => {
    i18n.changeLanguage(value);
    chrome.storage.local.set({
      i18nextLng: value,
    });
    if (messagingClient) {
      messagingClient.postMessageToContentScript({
        type: 'changeExtensionLocale',
        data: { locale: value },
      });
    }
  };

  return isMinimized || isCalendarPlugin ? null : (
    <div className={styles.footer}>
      <Dropdown
        className={styles.languageDropdown}
        value={i18n.resolvedLanguage}
        selection
        options={[
          { value: 'fr', text: t('langs.french'), key: 'fr' },
          { value: 'en', text: t('langs.english'), key: 'en' },
        ]}
        onChange={(e, { value }) => handleLanguageChange(value as string)}
      />
      <Button
        onClick={minimizeWithCallback}
        basic
        title='[Minimize]'
        style={{
          padding: '6px',
          paddingBottom: '5px',
        }}
        content={<i className='ri-fullscreen-exit-fill ri-lg' />}
        size='tiny'
      />
    </div>
  );
};

export default PluginProfileFooter;
