import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Sequence as ISequence } from '@/types/sequence';
import Sequence from '@/graphql/fragments/Sequence';

interface GetSequenceData {
  sequence: ISequence;
}

interface GetSequenceVariables {
  sequenceId: string;
}

interface UseSequenceResult
  extends QueryResult<GetSequenceData, GetSequenceVariables> {
  sequence: ISequence | undefined;
}

const GET_SEQUENCE = gql`
  query getSequence($sequenceId: ID!) {
    sequence(id: $sequenceId) {
      ...Sequence
    }
  }
  ${Sequence}
`;

const useSequence = ({
  sequenceId,
  queryOptions = {},
}: {
  sequenceId: string;
  queryOptions?: Omit<
    QueryHookOptions<GetSequenceData, GetSequenceVariables>,
    'variables'
  >;
}): UseSequenceResult => {
  const queryResults = useQuery<GetSequenceData, GetSequenceVariables>(
    GET_SEQUENCE,
    {
      ...queryOptions,
      variables: {
        sequenceId,
      },
    },
  );

  return {
    ...queryResults,
    sequence: queryResults?.data?.sequence,
  };
};

export default useSequence;
