import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const mutation = gql`
  mutation updatePushFlowPolicy($id: ID!) {
    updatePushFlowPolicy(id: $id) {
      id
      pushFlowPolicy {
        nbPerDay
      }
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    updatePushFlowPolicy: ({ id }) =>
      mutate({
        variables: {
          id,
        },
      }),
  }),
});
