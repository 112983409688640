import React, { FC, useState } from 'react';

import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import {
  PrefixStatisticsNode,
  PrefixTaskType,
} from '@/types/statistics/clientStatistics';
import InfoTooltip from '@/components/Common/InfoTooltip';
import { PrefixFilter } from '../../helpers/filters';
import { isLinear } from '../helpers';
import PathStatistics from '../PathStatistics';
import StepStatistics from '../StepStatistics';

import styles from './PrefixStatistics.module.less';

interface PrefixStatisticsProps {
  totalContacted: number;
  stats: PrefixStatisticsNode;
  fetchDetails?: (taskType: PrefixTaskType, prefix?: PrefixFilter) => void;
}

const displayModes = ['step', 'path'] as const;

const PrefixStatistics: FC<PrefixStatisticsProps> = ({
  totalContacted,
  stats,
  fetchDetails,
}) => {
  const { t } = useTranslation();
  const [stepMode, setStepMode] = useState(true);

  const displayModeDropdownValues: DropdownItemProps[] = _.map(
    displayModes,
    (value) => ({
      value,
      key: value,
      text: t(`reveal.reports.sequences.prefixDisplayMode.${value}`),
    }),
  );

  return (
    <div>
      {!isLinear(stats) && (
        <div className={styles.modeSelector}>
          <span className={styles.label}>
            {t('reveal.reports.sequences.prefixDisplayMode.label')}
          </span>
          <Dropdown
            options={displayModeDropdownValues}
            className={styles.dropdown}
            selection
            value={stepMode ? 'step' : 'path'}
            onChange={(_e, { value }) => setStepMode(value === 'step')}
          />
          <InfoTooltip rich hoverable position='right center'>
            <h1>
              {t('reveal.reports.tooltips.sequences.prefixDisplayMode.title')}
            </h1>
            <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
            <p>
              {t(
                'reveal.reports.tooltips.sequences.prefixDisplayMode.explanationParagraph',
              )}
            </p>
            <h2>
              {t('reveal.reports.tooltips.sequences.prefixDisplayMode.step')}
            </h2>
            <p>
              {t(
                'reveal.reports.tooltips.sequences.prefixDisplayMode.stepParagraph',
              )}
            </p>
            <h2>
              {t('reveal.reports.tooltips.sequences.prefixDisplayMode.path')}
            </h2>
            <p>
              {t(
                'reveal.reports.tooltips.sequences.prefixDisplayMode.pathParagraph',
              )}
            </p>
          </InfoTooltip>
        </div>
      )}
      {stepMode ? (
        <StepStatistics
          totalContacted={totalContacted}
          stats={stats}
          fetchDetails={fetchDetails}
        />
      ) : (
        <PathStatistics
          totalContacted={totalContacted}
          stats={stats}
          fetchDetails={fetchDetails}
          isLinear={isLinear(stats)}
        />
      )}
    </div>
  );
};

export default PrefixStatistics;
