import React from 'react';
import classNames from 'classnames';
import TaskIcon from '@/revealComponents/TaskIcon';
import useMinimizedView from '@/hooks/ui/useMinimizedView';

interface ActionItemContainerProps {
  isBox?: boolean;
  isLast: boolean;
  action: any;
  withIcon?: boolean;
  withBorder?: boolean;
  className?: string;
}

const ActionItemContainer: React.FC<ActionItemContainerProps> = ({
  isBox,
  isLast,
  action,
  withIcon = true,
  withBorder = false,
  children,
  className,
}) => {
  const { isMinimized } = useMinimizedView();

  return (
    <div
      className={classNames(
        classNames('action-item-container', {
          'ui segment box-mode': !!isBox,
        }),
        { 'last-action': isLast },
        { 'with-border': withBorder },
        isMinimized ? 'minimized' : '',
        className,
      )}
    >
      {withIcon && (
        <div className='action-item-icon-wrapper'>
          <TaskIcon action={action} />
        </div>
      )}
      <div className='action-item-content'>{children}</div>
    </div>
  );
};

export default ActionItemContainer;
