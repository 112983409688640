import { useMutation, type MutationHookOptions } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback } from 'react';

const updateClientAccountState = gql`
  mutation ChangeAccountState($input: ChangAccountStateInput) {
    changeAccountState(input: $input) {
      id
      accountState {
        deactivationDate
        deactivationReason {
          type
          message
        }
        status
      }
    }
  }
`;

type Data = {
  client: {
    accountState?: {
      status: string;
      deactivationReason?: {
        type: string;
        message: string;
      };
      deactivationDate?: string; // ISOString
    };
  };
};

type AccountState = {
  status: string;
  deactivationReason?: {
    type: string;
    message: string;
  };
  deactivationDate?: string;
};

type Variables = {
  input: AccountState;
};

type Input = {
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

function useUpdateClientAccountState({ mutationOptions = {} }: Input = {}) {
  const [mutate, result] = useMutation<Data, Variables>(
    updateClientAccountState,
    mutationOptions,
  );
  const update = useCallback(
    (accountStatus: AccountState) =>
      mutate({ variables: { input: accountStatus } }),
    [mutate],
  );
  return [update, result] as const;
}

export default useUpdateClientAccountState;
