import gql from 'graphql-tag';

import SmallResumeData from '../../graphql/fragments/SmallResumeData';
import PipeStepStat from '../../graphql/fragments/PipeStepStat';
import Author from '../../graphql/fragments/Author';
import ContactFlow from '../../graphql/fragments/ContactFlow';
import TimelineItem from '../../graphql/fragments/TimelineItem';
import ProfileTimeline from '../../graphql/fragments/ProfileTimeLine';

export const updateCacheAfterSendEmail = gql`
  query updateCacheAfterSendEmail($profileId: ID!, $step: String!) {
    profile(id: $profileId) {
      ...ProfileTimeline
      offer {
        id
        pipeStepStats {
          ...PipeStepStat
        }
        profiles(step: $step, search: "") {
          id
          resumeData {
            ...SmallResumeData
          }
          powerHourFlags {
            powerHourId
            isFlagged
          }
          firstContactDate
          lastStepUpdateTimestamp
        }
      }
    }
  }
  ${SmallResumeData}
  ${PipeStepStat}
  ${ProfileTimeline}
`;

export const lockProfileMutation = gql`
  mutation lockProfile($profileId: ID!, $email: String!) {
    lockProfile(id: $profileId, email: $email) {
      id
      locked
      lockedSince
      contacted
      sent
      resumeData {
        step
      }
    }
  }
`;

export const unlockProfileMutation = gql`
  mutation unlockProfile($profileId: ID!, $error: String) {
    unlockProfile(id: $profileId, error: $error) {
      id
      locked
      lockedSince
      contacted
      sent
      resumeData {
        step
      }
    }
  }
`;

export const backendSendMutation = gql`
  mutation backendSendEmail(
    $profileId: ID!
    $subject: String!
    $body: String!
  ) {
    backendSendEmail(id: $profileId, subject: $subject, body: $body) {
      id
      type
      threadId
      labelIds
    }
  }
`;

export const sendSearchPoolEmail = gql`
  mutation sendSearchPoolEmail(
    $input: BackendSendSearchPoolEmailInput!
    $searchPoolId: ID!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      backendSendSearchPoolEmail(input: $input) {
        id
        type
        threadId
        labelIds
      }
    }
  }
`;

export const sendOnBehalfOfMutation = gql`
  mutation sendEmailOnBehalfOf(
    $profileId: ID!
    $subject: String!
    $body: String!
    $senderId: ID!
    $bcc: [String!]
    $cc: [String!]
  ) {
    sendEmailOnBehalfOf(
      id: $profileId
      subject: $subject
      body: $body
      senderId: $senderId
      bcc: $bcc
      cc: $cc
    ) {
      id
      type
      threadId
      labelIds
    }
  }
`;

export const sentProfileEmailMutation = gql`
  mutation sentProfileEmail(
    $profileId: ID!
    $threadId: String
    $from: String
    $to: String
    $bcc: [String!]
    $cc: [String!]
    $body: String
    $subject: String
    $alias: String
    $signature: String
    $type: String
    $sender: SenderInput
    $trackingMessageId: String
    $trackOnEmailOpen: Boolean
    $trackOnLinkClick: Boolean
    $sequenceId: ID
    $actionId: ID
  ) {
    sentProfileEmail(
      id: $profileId
      threadId: $threadId
      from: $from
      to: $to
      bcc: $bcc
      cc: $cc
      body: $body
      subject: $subject
      alias: $alias
      signature: $signature
      sender: $sender
      type: $type
      trackingMessageId: $trackingMessageId
      trackOnEmailOpen: $trackOnEmailOpen
      trackOnLinkClick: $trackOnLinkClick
      sequenceId: $sequenceId
      actionId: $actionId
    ) {
      id
      locked
      lockedSince
      contacted
      sent
      lastStepUpdateTimestamp
      contactData {
        defaultThreadId
        defaultSubject
        timeline {
          ...TimelineItem
        }
      }
      resumeData {
        step
      }
      contactFlow {
        ...ContactFlow
      }
    }
  }
  ${Author}
  ${ContactFlow}
  ${TimelineItem}
`;
