import gql from 'graphql-tag';
import GradualSignificance from './fragments/GradualSignificance';

export interface ProfileMatch {
  job: {
    id?: string;
    title?: string;
    criteria?: {
      skills?: {
        required?: any[];
        important?: any[];
        bonus?: any[];
      };
    };
    sourceData?: {
      greenhouse?: {
        sourceJobId?: string;
      };
    };
  };
  score: {
    value?: number;
  };
}

export interface GetProfilesMatchesResult {
  searchPool: {
    id: string;
    profileMatches?: {
      matches?: ProfileMatch[];
    };
  };
}

export interface GetProfilesMatchesVariables {
  id: string;
  input: {
    id?: string;
    resume?: string;
  };
}

export const GET_PROFILE_MATCHES = gql`
  query getProfileMatches($id: ID!, $input: ProfileMatchesInput!) {
    searchPool(id: $id) {
      id
      profileMatches(input: $input) {
        matches {
          job {
            id
            title
            criteria {
              skills {
                ...GradualSignificance
              }
            }
            sourceData {
              greenhouse {
                sourceJobId
              }
            }
          }
          score {
            value
          }
        }
      }
    }
  }
  ${GradualSignificance}
`;
