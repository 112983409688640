import React from 'react';
import {
  Route,
  Redirect,
  useRouteMatch,
  generatePath,
  useLocation,
} from 'react-router-dom';
import TaskDimmer from '@/components/Dimmer/TaskDimmer';
import './LaserFocusModal.css';
import { CandidateViewProvider } from '@/context/CandidateView/useCandidateViewContext';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import CandidateView from '../SearchView/CandidatesListPane/CandidateView';

const LaserFocusModal = ({ clientId, tasksIds, tasks }) => {
  const isPlugin = useIsPlugin();
  return (
    <div className='enriched reveal-profile-modal profile-preview-container'>
      <CandidateViewProvider
        tasks={tasks}
        tasksIds={tasksIds}
        clientId={clientId}
        needNetworkCall={isPlugin}
      >
        <TaskDimmer />
        <CandidateView
          searchPoolId='reveal'
          clientId={clientId}
          withSimilarProfiles
        />
      </CandidateViewProvider>
    </div>
  );
};

/**
 * handle routing declaratively for laser focus
 * @param {*} props
 * @returns
 */
const LaserFocusModalContainer = (props) => {
  const { path, params } = useRouteMatch();
  const location = useLocation();
  const { tasksIds } = props;
  const firstTaskId = tasksIds?.[0];

  // if no selectedTaskId, redirect to first task id, if no first task id, exit laser focus
  // need to redirect using location or we loose the querystring
  const redirectUrl = firstTaskId
    ? generatePath(`${path}/focus/:selectedTaskId`, {
      ...params,
      selectedTaskId: firstTaskId,
    })
    : generatePath(path, { ...params });

  return (
    <>
      <Route exact path={`${path}/focus`}>
        <Redirect to={{ ...location, pathname: redirectUrl }} />
      </Route>
      <Route path={`${path}/focus/:selectedTaskId`}>
        <LaserFocusModal {...props} />
      </Route>
    </>
  );
};

export default LaserFocusModalContainer;
