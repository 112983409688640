import React from 'react';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import Pagination from '@/components/Pagination';

import Actions from './Actions';
import { nbSteps } from './constants';
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Visual0 from './Visual0';
import Visual1 from './Visual1';
import Visual2 from './Visual2';
import Visual3 from './Visual3';
import { useOnboardingModalController } from './hooks';

import './OnboardingModal.css';

const steps = [Step0, Step1, Step2, Step3];
const visuals = [Visual0, Visual1, Visual2, Visual3];

const OnboardingModal: React.FC = () => {
  const { t } = useTranslation();
  const {
    open,
    step,
    toNextStep,
    toPrevStep,
    onClose,
  } = useOnboardingModalController();
  const CurrentStep = steps[step] || Null;
  const CurrentVisual = visuals[step] || Null;

  return (
    <div>
      <Modal
        className='reveal-onboarding-modal'
        dimmer={{ id: 'reveal-onboarding-dimmer' }}
        size='large'
        open={open}
      >
        <div className='layout'>
          <CurrentVisual />
          <div className='layout-right'>
            <div>
              <div className='blue-pill'>{t('trial.onboarding.pill')}</div>
            </div>
            <CurrentStep />
            <div className='actions'>
              <Pagination step={step} nbSteps={nbSteps} />
              <Actions
                nbSteps={nbSteps}
                step={step}
                toNextStep={toNextStep}
                toPreviousStep={toPrevStep}
                onClose={onClose}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const Null = () => null;

export default OnboardingModal;
