import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from '@apollo/client';
import GreenhouseData from './fragments/GreenhouseData';
import Project from './fragments/Project';
import Task, { TaskWithFullProfileContact } from './fragments/Task';
import useDataUpdateSubscription from './dataUpdateSubscription/useDataUpdateSubscription';

export interface CompleteTaskVariables {
  input: {
    task: {
      id: string;
      type: string;
    };
    projectId?: string;
    queueId?: string;
    sequenceId?: string;
    missionId?: string;
    profileId: string;
  };
}

export interface CompleteTaskResult {
  project: {
    completeTask: {
      task: any;
    };
  };
}

export const COMPLETE_TASK = gql`
  mutation completeTask($input: CompleteTaskInput!) {
    project {
      completeTask(input: $input) {
        task {
          ...Task
        }
      }
    }
  }
  ${Task}
`;

export const COMPLETE_TASK_FULL_PROFILE = gql`
  mutation completeTaskFullProfile($input: CompleteTaskInput!) {
    project {
      completeTask(input: $input) {
        task {
          ...TaskWithFullProfileContact
        }
      }
    }
  }
  ${TaskWithFullProfileContact}
`;

export const BULK_COMPLETE_TASKS_FULL_PROFILE = gql`
  mutation bulkCompleteTasksFullProfile($input: [CompleteTaskInput!]!) {
    project {
      bulkCompleteTasks(input: $input) {
        task {
          ...TaskWithFullProfileContact
        }
      }
    }
  }
  ${TaskWithFullProfileContact}
`;

export const SNOOZE_TASK_FULL_PROFILE = gql`
  mutation snoozeTaskFullProfile($input: SnoozeExplicitTaskInput!) {
    project {
      snoozeExplicitTask(input: $input) {
        task {
          ...TaskWithFullProfileContact
        }
      }
    }
  }
  ${TaskWithFullProfileContact}
`;

export const ADVANCE_TASK_FULL_PROFILE = gql`
  mutation advanceTaskFullProfile($input: AdvanceExplicitTaskInput!) {
    project {
      advanceExplicitTask(input: $input) {
        task {
          ...TaskWithFullProfileContact
        }
      }
    }
  }
  ${TaskWithFullProfileContact}
`;

export const ADVANCE_APPLICATION = gql`
  mutation advanceApplication($input: AdvanceApplicationInput!) {
    project {
      advanceApplication(input: $input) {
        task {
          id
          state
          target {
            ... on RevealProfile {
              id
              resumeData {
                sourceData {
                  greenhouse {
                    ...GreenhouseData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${GreenhouseData}
`;

export const REJECT_APPLICATION = gql`
  mutation rejectApplication($input: RejectApplicationInput!) {
    project {
      rejectApplication(input: $input) {
        task {
          id
          state
          target {
            ... on RevealProfile {
              id
              resumeData {
                sourceData {
                  greenhouse {
                    ...GreenhouseData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${GreenhouseData}
`;

export const GET_TASKS_BY_PROJECT = gql`
  query getTasksByProject($id: ID!, $filter: ClientProjectsFilter) {
    client(id: $id) {
      id
      projects(filter: $filter) {
        ...Project
      }
    }
  }
  ${Project}
`;

const GET_CLIENT_TASKS = gql`
  query getClientTasks($clientId: ID!, $filters: TasksFiltersInput) {
    client(id: $clientId) {
      id
      contactFlowTasks(filters: $filters) {
        ...Task
      }
      explicitTasks(filters: $filters) {
        ...Task
      }
    }
  }
  ${Task}
`;

export const useGetClientTasks = ({
  clientId,
  filters,
  queryOptions,
}: {
  clientId: string;
  filters?: any;
  queryOptions?: QueryHookOptions;
}) => {
  const { loading, data, refetch } = useQuery(GET_CLIENT_TASKS, {
    variables: { clientId, filters },
    fetchPolicy: 'network-only',
    ...queryOptions,
  });

  useDataUpdateSubscription({
    type: 'onTaskUpdated',
    onData: () => refetch(),
  });

  return { loading, data };
};

// NOTE: GET_TASKS_COUNT aka 'getTasksCount' is used in refetchQueries /!\
const GET_TASKS_COUNT = gql`
  query getTasksCount($clientId: ID!, $filters: TasksFiltersInput) {
    client(id: $clientId) {
      id
      tasksCount(filters: $filters)
    }
  }
`;

export const useGetTasksCount = ({ clientId, filters, ...options }: any) => {
  const variables = { clientId, filters };
  const { data } = useQuery(GET_TASKS_COUNT, { variables, ...options });
  return data?.client?.tasksCount;
};

export const GET_REVEAL_TASKS_STATISTICS = gql`
  query getRevealTasksStatistics($id: ID!, $type: [String!]!) {
    client(id: $id) {
      id
      statistics {
        tasks(type: $type) {
          taskType
          dailyEvents {
            taskType
            day
            completed
            inProgress
            created
          }
        }
      }
    }
  }
`;

export const DELETE_TASKS = gql`
  mutation deleteTasks($input: DeleteTasksInput!) {
    project {
      deleteTasks(input: $input) {
        tasks {
          id
          state
        }
      }
    }
  }
`;

export const DELETE_TASK_FULL = gql`
  mutation deleteTasks(
    $input: DeleteTasksInput!
    $explicitTasksFilter: ExplicitTasksFilter
  ) {
    project {
      deleteTasks(input: $input) {
        tasks {
          id
          state
          target {
            ... on RevealProfile {
              id
              explicitTasks(filter: $explicitTasksFilter) {
                ...Task
              }
            }
          }
        }
      }
    }
  }
  ${Task}
`;
