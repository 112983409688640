import gql from 'graphql-tag';
import ContactFlowTrigger from './ContactFlowTrigger';
import ContactFlowMessage from './ContactFlowMessage';
import TranslatableText from './TranslatableText';
import Snippet from './Snippet';
import CustomActionExecutionFragment from './CustomActionExecutionFragment';
import ContactFlowActionFormatFragment from './ContactFlowActionFormat';

export default gql`
  fragment ContactFlowAction on ContactFlowAction {
    actionId
    type
    plannedExecutionDate
    referenceDueDate
    trigger {
      ...ContactFlowTrigger
    }
    completion {
      isCompleted
      completionDate
      # author {
      #   email
      #   firstname
      #   lastname
      # }
    }
    nbManualSnoozes

    ... on ManualAction {
      title {
        ...TranslatableText
      }
      description {
        ...TranslatableText
      }
      execution {
        ...CustomActionExecutionFragment
      }
      format {
        ...ContactFlowActionFormatFragment
      }
    }

    ... on MakePhoneCallAction {
      description {
        ...TranslatableText
      }
    }

    ... on SendTextMessageAction {
      description {
        ...TranslatableText
      }
      message {
        ...ContactFlowMessage
      }
      snippets {
        ...Snippet
      }
    }

    ... on SendWhatsappMessageAction {
      message {
        ...ContactFlowMessage
      }
      snippets {
        ...Snippet
      }
    }

    ... on SendEmailAction {
      message {
        ...ContactFlowMessage
      }
      snippets {
        ...Snippet
      }
    }

    ... on SendEmailManualAction {
      message {
        ...ContactFlowMessage
      }
      snippets {
        ...Snippet
      }
    }

    ... on LinkedinSendRequestAction {
      message {
        ...ContactFlowMessage
      }
      snippets {
        ...Snippet
      }
    }

    ... on LinkedinSendMessageAction {
      subtype
      message {
        ...ContactFlowMessage
      }
      snippets {
        ...Snippet
      }
    }

    ... on CustomAction {
      countAs
      format {
        ...ContactFlowActionFormatFragment
      }
      completionButtonDisplay {
        title {
          ...TranslatableText
        }
      }
      customActionId
      execution {
        ...CustomActionExecutionFragment
      }
      icon {
        url
        emojiText
        id
        type
      }
      title {
        ...TranslatableText
      }
      message {
        body
        labels
      }
      snippets {
        ...Snippet
      }
    }

    ... on LinkedinCheckRequestAction {
      senderId
      metadata {
        key
        value
      }
      format {
        ...ContactFlowActionFormatFragment
      }
    }

    snoozeConfiguration {
      isSnoozable
      isAdvanceable
      maxSnoozeDate
    }
    shouldPerformAutomatically
  }
  ${ContactFlowActionFormatFragment}
  ${ContactFlowTrigger}
  ${ContactFlowMessage}
  ${TranslatableText}
  ${Snippet}
  ${CustomActionExecutionFragment}
`;
