import React, { useRef, useState, ReactNode } from 'react';
import { Popup } from 'semantic-ui-react';

import './HoverPopup.css';

// to stop events from bubbling out of the popup
const stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
};

interface HoverPopupProps {
  content: ReactNode;
  delay?: number;
  useMaxWidth?: boolean;
}

const HoverPopup: React.FC<HoverPopupProps> = ({
  children,
  content,
  delay,
  useMaxWidth,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const PopupTrigger = (
    <div style={{ width: useMaxWidth ? '100%' : 'fit-content' }}>
      <span>
        {children}
        <span ref={ref} />
      </span>
    </div>
  );

  return (
    <div>
      <Popup
        className='hover-popup'
        basic
        hoverable
        position='right center'
        flowing
        context={ref}
        on='hover'
        open={!!(open && content)}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        mouseEnterDelay={delay || 200}
        mouseLeaveDelay={delay || 200}
        trigger={PopupTrigger}
        content={
          <div className='hover-popup-content' onClick={stopPropagation}>
            {content}
          </div>
        }
        popperModifiers={[
          {
            // Fix a positioning problem
            name: 'preventOverflow',
            options: {
              boundariesElement: 'offsetParent',
            },
          }
        ]}
        style={{
          marginLeft: '60px',
          zIndex: 11,
        }}
      />
    </div>
  );
};

export default HoverPopup;
