import _ from 'underscore';
import React, { useEffect, useMemo, useState } from 'react';
import { Loader, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import {
  ContactSearch,
  SearchResultItem,
} from '@/routes/RevealView/SearchView/types';
import PageSelector from '@/components/Common/Pagination/PageSelector';
import EmptyState from '@/revealComponents/EmptyState/EmptyState';
import ImportCandidatesDropdown from '@/revealComponents/Buttons/ImportCandidatesDropdown';
import { PartitioningSelector } from '@/components/Common/Pagination';
import useClientId from '@/hooks/router/useClientId';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';

import { useProfilesTableColumnsWithCustomFields } from '../../JobsView/JobView/ProjectProfilesTab/columns/useProfilesTableColumns';
import ProfileRowHeader from '../../ProfileRow/ProfileRowHeader';

import useCandidateSelection from './useCandidateSelection';
import SearchResultsPage from './SearchResultsPage';
import BulkActionsControls from './BulkActionsControls';
import IncludeSkippedCheckbox from './IncludeSkippedCheckbox';

import './CandidatesSearchResults.css';

const candidatePerPageOptions = [10, 15, 20, 30, 50];

type CandidatesSearchResultsProps = {
  jobId?: string;
  searches: ContactSearch[];
  searchPoolId: string;
  searchResultItems: SearchResultItem[];
  refetchSearchResults: () => void;
  loadingResults?: boolean;
  hasFilters?: boolean;
  showActiveSequence?: boolean;
  shouldHideBulkActions?: boolean;
  tableId?: string;
  contactCategory?: 'human' | 'company';
};

const CandidatesSearchResults = ({
  jobId,
  searches,
  searchPoolId,
  searchResultItems,
  refetchSearchResults,
  loadingResults = false,
  hasFilters = false,
  showActiveSequence = false,
  shouldHideBulkActions = false,
  tableId = 'search-profiles-table-view',
  contactCategory = 'human',
}: CandidatesSearchResultsProps): JSX.Element => {
  const clientId = useClientId();
  const { t } = useTranslation();
  const [candidatePerPage, setCandidatePerPage] = useState(
    +localStorage.MissionPaginationProfilesPerPage
      ? +localStorage.MissionPaginationProfilesPerPage
      : 10,
  );
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const { permissions } = useClientPermissions(clientId);

  const candidateSelection = useCandidateSelection();
  const allCandidates = candidateSelection.getAll();
  const selection = candidateSelection.get();
  const nbPages = Math.ceil(searchResultItems?.length / candidatePerPage);
  const pageProfileIds = React.useMemo(() => {
    if (!searchResultItems?.length) return [];
    return searchResultItems.slice(
      currentPageIndex * candidatePerPage,
      (currentPageIndex + 1) * candidatePerPage,
    );
  }, [currentPageIndex, candidatePerPage, searchResultItems]);

  const isHeaderChecked = useMemo(() => {
    if (!selection.length || !allCandidates.length) {
      return false;
    }
    const pageIds = _.pluck(pageProfileIds, 'searchPoolProfileId');
    return candidateSelection.areSelected({ profilesIds: pageIds });
    // eslint-disable-next-line
  }, [currentPageIndex, selection.length, allCandidates?.length]);

  const allCandidateAcrossAllPages =
    searchResultItems?.length > 1000
      ? searchResultItems.slice(0, 1000)
      : searchResultItems;

  const handleCandidatePerPageChange = (value: number) => {
    setCandidatePerPage(value);
    localStorage.setItem('MissionPaginationProfilesPerPage', String(value));
  };

  const onToggleCheckbox = ({ checked }: { checked: boolean }) => {
    candidateSelection.onSelectAllInCurrentPageToggle(
      checked,
      currentPageIndex,
      candidatePerPage,
      pageProfileIds.map((profile) => ({ id: profile.searchPoolProfileId })),
    );
  };

  const {
    columnGroups,
    displayColumn,
    displayedColumns,
    updateColumnsDisplay,
    profileCustomFields,
  } = useProfilesTableColumnsWithCustomFields({
    tableId,
    defaultColumns: [
      { id: 'project-profiles-view-contact', available: true, hidden: false },
      { id: 'project-profiles-view-email', available: true, hidden: true },
      {
        id: 'project-profiles-view-relevance',
        available: true,
        hidden: !hasFilters,
      },
      { id: 'project-profiles-view-missions', available: true, hidden: false },
      {
        id: 'project-profiles-view-sequence',
        available: true,
        hidden: !showActiveSequence,
      },
      {
        id: 'project-profiles-view-last-interaction',
        available: true,
        hidden: hasFilters,
      },
      {
        id: 'project-profiles-view-next-interaction',
        available: true,
        hidden: true,
      },
      ...(permissions?.companiesDealStates
        ? [{ id: 'company-custom-deal-state', available: true, hidden: false }]
        : []),
    ],
    contactCategory,
  });

  useEffect(() => {
    candidateSelection.reset();
    setCurrentPageIndex(0);
    // eslint-disable-next-line
  }, [searchResultItems?.length]);

  const selectedProfilesCount = new Intl.NumberFormat(t('common.lang')).format(
    candidateSelection.get().length,
  );

  return (
    <div className='candidates-search-results-container'>
      <Segment className='candidates-search-results'>
        <div className='search-bar-container'>
          <div className='button-container'>
            {!shouldHideBulkActions && (
              <>
                <BulkActionsControls
                  clientId={clientId}
                  candidates={candidateSelection.get()}
                  jobId={jobId}
                  refetchSearchResults={refetchSearchResults}
                  onSuccess={candidateSelection.reset}
                  basicBtns
                />
                <div className='selected-profiles'>
                  {!_.isEmpty(candidateSelection?.get()) && (
                    <>
                      {selectedProfilesCount +
                        t('common.selectedProfilesCount', {
                          count: +selectedProfilesCount,
                        })}
                      {!candidateSelection.isAllCandidatesSelected ? (
                        <span
                          aria-hidden
                          className='select-all-profiles'
                          onClick={() =>
                            candidateSelection.selectAllAcrossAllPages()
                          }
                        >
                          {searchResultItems?.length >= 1000
                            ? t('reveal.selection.selectMax')
                            : t('reveal.selection.selectAll')}
                        </span>
                      ) : (
                        <span
                          aria-hidden
                          className='select-all-profiles'
                          onClick={() => candidateSelection.reset()}
                        >
                          {t('reveal.selection.unselectAll')}
                        </span>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>

          <IncludeSkippedCheckbox />
        </div>
        {!_.isEmpty(pageProfileIds) ? (
          <>
            <ProfileRowHeader
              onToggleCheckbox={onToggleCheckbox}
              isChecked={isHeaderChecked}
              showActiveSequence={showActiveSequence}
              shouldHideCheckbox={shouldHideBulkActions}
              showLastInteraction={displayColumn(
                'project-profiles-view-last-interaction',
              )}
              showNextInteraction={displayColumn(
                'project-profiles-view-next-interaction',
              )}
              showMissions={displayColumn('project-profiles-view-missions')}
              showEmail={displayColumn('project-profiles-view-email')}
              showSequence={displayColumn('project-profiles-view-sequence')}
              showRelevance={displayColumn('project-profiles-view-relevance')}
              showCampaignState={displayColumn(
                'project-profiles-view-campaign-state',
              )}
              showCompanyDealState={displayColumn('company-custom-deal-state')}
              showAtsApplications={false}
              columnGroups={columnGroups}
              displayColumn={displayColumn}
              displayedColumns={displayedColumns}
              onUpdateColumnDisplay={updateColumnsDisplay}
              customFields={profileCustomFields}
            />

            <div id='candidates-scroll-container'>
              <SearchResultsPage
                clientId={clientId}
                searchPoolId={searchPoolId}
                searchResultItems={pageProfileIds}
                searches={searches}
                candidateSelection={candidateSelection}
                allCandidateAcrossAllPages={allCandidateAcrossAllPages}
                loadingResults={loadingResults}
                projectId={jobId}
                shouldHideCheckboxes={shouldHideBulkActions}
                displayColumn={displayColumn}
                customFields={profileCustomFields}
              />
            </div>

            <div className='table-footer'>
              <PartitioningSelector
                onChange={handleCandidatePerPageChange}
                options={candidatePerPageOptions}
                value={candidatePerPage}
              />
              {nbPages > 1 && (
                <PageSelector
                  currentPageIndex={currentPageIndex}
                  numberOfPages={nbPages}
                  onChange={setCurrentPageIndex}
                />
              )}
            </div>
          </>
        ) : (
          <div className='candidates-empty-state-container'>
            {loadingResults ? (
              <div className='loader-container'>
                <Loader active inline='centered' size='large' />
              </div>
            ) : (
              <>
                {_.isEmpty(allCandidates) ? (
                  <EmptyState
                    title={t('reveal.searchView.results.emptyState.title')}
                    innerContent={t(
                      'reveal.searchView.results.emptyState.innerContent',
                    )}
                    illustrationPath='/images/placeholders/contactsEmptyState.svg'
                    cta={
                      <ImportCandidatesDropdown
                        clientId={clientId}
                        jobId={jobId}
                        onSuccess={refetchSearchResults}
                      />
                    }
                  />
                ) : (
                  <EmptyState
                    title={t('reveal.searchView.results.noResults.title')}
                    innerContent={t(
                      'reveal.searchView.results.noResults.innerContent',
                    )}
                    illustrationPath='/images/placeholders/contactsEmptyState.svg'
                  />
                )}
              </>
            )}
          </div>
        )}
      </Segment>
    </div>
  );
};

export default CandidatesSearchResults;
