import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import GenericTextInput from '@/components/Common/GenericTextInput';
import { MissionInterestedSubStep } from '@/graphql/hooks/clients/useClientMissionInterestedSubSteps';
import MissionSubStepSetting from '@/routes/RevealView/JobsView/JobPipelineView/MissionSubStepSetting';
import { t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, Modal, DropdownProps } from 'semantic-ui-react';
import _ from 'underscore';
import { Department } from '@/types/client';
import GenericCheckbox from '@/components/Common/GenericCheckbox';
import styles from '../SuperPipelineSettings.module.less';
import { ClientPipeline } from '../../../../graphql/clients';
import { getTranslatedText } from '../../../../common/index';

export interface DepartmentWithType extends Department {
  type: string;
}

interface CreateAndEditPipelineModalProps {
  title: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  isEdition?: boolean;
  clientSubsteps: MissionInterestedSubStep[];
  pipeline?: Partial<ClientPipeline>;
  updatePipeline: (pipeline: Partial<ClientPipeline>) => void;
  onCheckName?: (name: string, id: string) => boolean;
  departments: DepartmentWithType[];
  usedDepartments: string[];
}

const CreateAndEditPipelineModal: React.FC<CreateAndEditPipelineModalProps> = ({
  title,
  open,
  setOpen,
  isEdition = false,
  clientSubsteps,
  pipeline,
  updatePipeline,
  onCheckName,
  departments,
  usedDepartments,
}) => {
  const [pipelineState, setPipelineState] = useState(pipeline);
  const [showDepartmentsDropdown, setShowDepartmentsDropdown] = useState(false);
  const [isDepartmentAlreadyUsed, setIsDepartmentAlreadyUsed] = useState(false);

  useEffect(() => {
    setPipelineState(pipeline);
  }, [pipeline]);

  const handleStepToggle = ({
    key,
    value,
  }: {
    key: string | number;
    value: boolean;
  }) => {
    if (value) {
      const newSteps = [
        ...(pipelineState?.steps as MissionInterestedSubStep[]),
        _.findWhere(clientSubsteps, {
          id: key as string,
        }) as MissionInterestedSubStep,
      ];
      setPipelineState({
        ...pipelineState,
        steps: newSteps,
      });
    } else {
      const newSteps = [
        ..._.filter(
          pipelineState?.steps || [],
          (subStep) => subStep.id !== key,
        ),
      ];
      setPipelineState({
        ...pipelineState,
        steps: newSteps,
      });
    }
  };

  const handleScopeSelect = ({ value }: { value: string }) => {
    const type = _.findWhere(departments, { id: value })?.type || 'global';
    if (_.contains(usedDepartments, value)) {
      setIsDepartmentAlreadyUsed(true);
    } else {
      setIsDepartmentAlreadyUsed(false);
    }
    setPipelineState({
      ...pipelineState,
      scopeDescriptor: {
        type,
        ...(type !== 'global' && {
          [`${type}Id`]: value,
        }),
      },
    });
  };

  const canValidate = useMemo(
    () =>
      onCheckName &&
      !onCheckName(
        pipelineState?.title?.default || '',
        pipelineState?.id || '',
      ),
    [pipelineState, onCheckName],
  );

  const handleClose = () => {
    setIsDepartmentAlreadyUsed(false);
    setPipelineState({});
    setShowDepartmentsDropdown(false);
    setOpen(false);
  };

  // flatten departments with sub-departments and sub-sub-departments to create options for select input
  const departmentOptions = _.map(departments, (department) => ({
    key: department.id,
    text: department.title,
    value: department.id,
  }));

  return (
    <GenericModal size='tiny' open={open} onClose={handleClose}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <p className={styles.newSubstepNameLabel}>
          {t('superPipelineSettings.pipelineName')}
        </p>
        <GenericTextInput
          small
          value={getTranslatedText(pipelineState?.title || { default: '' })}
          onValue={(value) => {
            setPipelineState({
              ...pipelineState,
              title: {
                default: value,
              },
            });
          }}
          className={styles.newSubstepNameInput}
          autoFocus
        />
        {!_.isEmpty(clientSubsteps) && (
          <>
            <p className={styles.stepsLabel}>
              {t('superPipelineSettings.interestedSubsteps')}
            </p>
            {_.map(clientSubsteps, (subStep) => (
              <div
                className={styles.subStepEditionRawContainer}
                key={subStep.id}
              >
                <MissionSubStepSetting
                  isChecked={
                    !!_.findWhere(pipelineState?.steps || [], {
                      id: subStep.id,
                    })
                  }
                  subStep={subStep}
                  handleStepToggle={handleStepToggle}
                  subStepCount={0}
                />
              </div>
            ))}
          </>
        )}

        <div
          className={styles.departmentsCheckboxContainer}
          onClick={() => setShowDepartmentsDropdown(!showDepartmentsDropdown)}
        >
          <GenericCheckbox
            checked={
              showDepartmentsDropdown ||
              (!!pipeline?.scopeDescriptor?.type &&
                pipeline?.scopeDescriptor?.type !== 'global')
            }
          />{' '}
          <span>{t('superPipelineSettings.useAsDefaultFor')}</span>
        </div>
        {(showDepartmentsDropdown ||
          (pipeline?.scopeDescriptor?.type &&
            pipeline?.scopeDescriptor?.type !== 'global')) && (
          <Dropdown
            size='tiny'
            id='execution-type'
            fluid
            selection
            className={styles.scopeSelector}
            onChange={(_e: unknown, value: DropdownProps) => {
              if (
                typeof value === 'object' &&
                'value' in value &&
                typeof value.value === 'string'
              ) {
                handleScopeSelect({ value: value.value });
              }
            }}
            options={departmentOptions}
            value={
              pipelineState?.scopeDescriptor &&
              (pipelineState.scopeDescriptor as Record<string, string>)?.[
                `${pipelineState.scopeDescriptor.type}Id`
              ]
            }
          />
        )}
        {isDepartmentAlreadyUsed && (
          <div className={styles.warning}>
            {t('superPipelineSettings.departmentAlreadyUsed', {
              department: _.findWhere(departments, {
                id: ((pipelineState?.scopeDescriptor as Record<
                  string,
                  string
                >) || {})?.[`${pipelineState?.scopeDescriptor?.type}Id`],
              })?.title,
            })}
          </div>
        )}
      </Modal.Content>
      <Modal.Actions className={styles.actions}>
        <GenericButton
          size='big'
          primacy='secondary'
          onClick={() => setOpen(false)}
        >
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          size='big'
          primacy='primary'
          onClick={() =>
            updatePipeline({
              id: pipelineState?.id,
              steps: pipelineState?.steps,
              title: pipelineState?.title,
              scopeDescriptor: pipelineState?.scopeDescriptor,
              missionCategory: pipelineState?.missionCategory,
            })
          }
          disabled={
            _.isEmpty(pipelineState?.title?.default) ||
            !canValidate ||
            isDepartmentAlreadyUsed
          }
        >
          {isEdition ? t('common.save') : t('common.create')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default CreateAndEditPipelineModal;
