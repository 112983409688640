import React, { Dispatch, SetStateAction } from 'react';
import _ from 'underscore';

import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import { ResumeData } from '@/types/searchPoolProfile';
import {
  formatProfiles,
  CSVLines,
  ColumnsMapping,
  ProfileRowError,
  User,
  ProfileRow,
  CSVLine,
} from './helpers';
import {
  detectNewEnumOptions,
  DetectedNewEnumOptions,
} from './analysis.helpers';
import { useCustomFieldsEnumOptionsUpdateMutation } from './useCSVCreateColumnCustomField';
import useCSVLoader from './useCSVLoader';
import useCSVImportMapper, {
  CSVImportMapper,
  ParsedCSVLines,
} from './useCSVImportMapper';
import useCSVProfilesUploader from './useCSVProfilesUploader';

type CSVImport = {
  rows: CSVLines;
  parsedRows: ParsedCSVLines;
  headers: CSVLine | null;
  isImporting: boolean;
  firstLineIsHeader: boolean;
  setFirstLineIsHeader: Dispatch<SetStateAction<boolean>>;
  columnsMapping: ColumnsMapping;
  updateColumnMapping: CSVImportMapper['updateColumnMapping'];
  profilesToUpload: ProfileRow[] | null;
  profileErrors: ProfileRowError[] | null;
  isUploading: boolean;
  importProfilesResults: ImportProfilesResults;
  isUploadSuccess: boolean;
  onCancel: () => void;
  onDrop: (files: FileList) => void;
  onChange: (file: File) => void;
  onUpload: (force?: boolean) => void;
  canUpload: boolean;
  fileName: string;
  setFileName: Dispatch<SetStateAction<string>>;
  recruiter: User;
  setRecruiter: Dispatch<SetStateAction<User>>;
  newEnumOptions: DetectedNewEnumOptions;
};

type ImportProfilesResults = {
  duplicateProfiles: { id: string; resumeData: ResumeData }[];
  insertedProfilesCount: number;
};

/**
 * !TODO: needs refactoring
 *
 * Reason: single responsability principle is violated
 * - Handle import options
 * - handle CSV parsing
 * - Handle CSV data formatting
 * - Handle Import upload
 */
export default function useProfilesCSVImport({
  onSuccess,
  onDone,
  jobId,
  clientId,
}: {
  onSuccess: () => void;
  onDone: () => void;
  jobId: string;
  clientId: string;
}): CSVImport {
  const [fileName, setFileName] = React.useState('');
  const { user } = useCurrentUser();
  const defaultUser = {
    firstname: user?.firstname || '',
    lastname: user?.lastname || '',
    email: user?.email || '',
  };
  const [recruiter, setRecruiter] = React.useState<User>(defaultUser);
  const customFieldsQuery = useClientProfileCustomFields(clientId);
  const customFields = customFieldsQuery.profileCustomFields;

  const csvLoader = useCSVLoader();
  const csvImportMapper = useCSVImportMapper(csvLoader.rows, { customFields });
  const csvUploader = useCSVProfilesUploader();

  // Import parsing results
  const [profilesToUpload, setProfilesToUpload] = React.useState<
    ProfileRow[] | null
  >([]);
  const [profileErrors, setProfileErrors] = React.useState<
    ProfileRowError[] | null
  >([]);

  const onCancel = () => {
    csvLoader.reset();
    csvImportMapper.reset();
    csvUploader.reset();
    setRecruiter(defaultUser);
    setFileName('');

    setProfilesToUpload([]);
    setProfileErrors([]);

    if (onSuccess && csvUploader.importedProfiles) {
      onSuccess();
    }

    onDone();
  };

  const onDrop = (files: FileList) => {
    const file = (files || [])[0];
    if (file) {
      onChange(file);
    }
  };

  const onChange = (file: File) => {
    if (file) {
      csvUploader.reset();
      setFileName(file.name);
      csvLoader.loadCSVFile(file);

      setProfileErrors(null);
      setProfilesToUpload(null);
    }
  };

  const { headers, lines, columnsMapping } = csvImportMapper;
  const newEnumOptions = React.useMemo(() => {
    return detectNewEnumOptions(lines, { columnsMapping, customFields });
  }, [lines, columnsMapping, customFields]);
  const updateEnumOptions = useCustomFieldsEnumOptionsUpdateMutation({
    customFields,
  });

  const onUpload = async (force?: boolean) => {
    let currentCustomFields = customFields;
    // Update custom fields
    if (newEnumOptions.length > 0) {
      try {
        currentCustomFields = await updateEnumOptions(newEnumOptions);
      } catch (e) {
        console.error('Error while updating custom fields', e);
      }
    }

    const { profiles, errors } = formatProfiles(lines, {
      headers,
      columnsMapping,
      customFields: currentCustomFields,
    });
    setProfilesToUpload(profiles);
    setProfileErrors(errors);

    if (force || _.isEmpty(errors)) {
      await csvUploader.upload({
        profiles,
        recruiter,
        missionId: jobId,
        importName: fileName,
      });
    }
  };

  const hasField = (field: string) => {
    return columnsMapping.some((columnMapping) => columnMapping?.id === field);
  };

  const hasFirstname = hasField('firstname') || hasField('fullname');
  const canTryUpload =
    fileName.length &&
    ((hasFirstname && hasField('email')) ||
      (hasFirstname && hasField('linkedin')));

  return {
    headers,
    rows: lines,
    parsedRows: csvImportMapper.parsedRows,
    isImporting: !_.isEmpty(lines),
    firstLineIsHeader: csvImportMapper.firstLineIsHeader,
    setFirstLineIsHeader: csvImportMapper.setFirstLineIsHeader,
    columnsMapping: csvImportMapper.columnsMapping,
    updateColumnMapping: csvImportMapper.updateColumnMapping,
    profilesToUpload,
    profileErrors,
    isUploading: csvUploader.isUploading,
    importProfilesResults: csvUploader.importedProfiles as ImportProfilesResults,
    isUploadSuccess: Boolean(!_.isEmpty(lines) && csvUploader.importedProfiles),
    onCancel,
    onDrop,
    onChange,
    onUpload,
    canUpload: !!canTryUpload,
    fileName,
    setFileName,
    recruiter,
    setRecruiter,
    newEnumOptions,
  };
}
