import { QueryHookOptions, useQuery } from '@apollo/client';
import { IRevealConnector } from '@/graphql/fragments/RevealConnector';
import { ATStype } from '@/common/reveal';
import useClientId from '@/hooks/router/useClientId';
import GET_CLIENT_REVEAL_PROJECTS, {
  GetClientRevealProjectsResult,
  GetClientRevealProjectsVariables,
} from '../../clientRevealProjects';

type ClientRevealProjectsQuery = QueryHookOptions<
  GetClientRevealProjectsResult,
  GetClientRevealProjectsVariables
>;

const useClientRevealProjects = (
  clientId: string,
  queryOptions: Omit<ClientRevealProjectsQuery, 'variables'> = {},
) => {
  return useQuery<
    GetClientRevealProjectsResult,
    GetClientRevealProjectsVariables
  >(GET_CLIENT_REVEAL_PROJECTS, {
    variables: { clientId },
    ...queryOptions,
  });
};

export const useClientRevealConnector = (
  clientId: string,
  queryOptions: Omit<ClientRevealProjectsQuery, 'variables'> = {},
): IRevealConnector | undefined => {
  const { data } = useClientRevealProjects(clientId, queryOptions);
  const revealProjects = data?.client?.revealProjects;
  const revealProject = (revealProjects || [])[0];
  return revealProject?.connectors?.[0];
};

type ATSTypeCustom = ATStype | 'custom';

export const useClientCurrentAtsId = (): ATStype | undefined => {
  const clientId = useClientId();
  const connector = useClientRevealConnector(clientId);
  let atsId: ATSTypeCustom | undefined = connector?.type as ATSTypeCustom;
  if (atsId === 'custom') {
    atsId = undefined;
  }
  return atsId;
};

export default useClientRevealProjects;
