import React from 'react';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import classnames from 'classnames';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';

import { useClientSequenceTemplates } from '@/containers/Parameters/Sequences/queries';
import styles from './NewSequenceTypeModal.module.less';

interface NewSequenceTypeModalProps {
  open: boolean;
  clientId: string;
  onClose: () => void;
}

const NewSequenceTypeModal: React.FC<NewSequenceTypeModalProps> = ({
  open,
  clientId,
  onClose,
}) => {
  const { t } = useTranslation();
  const { sequenceTemplates } = useClientSequenceTemplates({
    clientId,
    fetchPolicy: 'network-only',
  });
  const { defaultSequenceTemplates } = sequenceTemplates || {};

  return (
    <GenericModal
      open={open}
      closeOnDimmerClick
      closeOnEscape
      onClose={onClose}
    >
      <Modal.Header>{t('sequences.newSequenceModal.title')}</Modal.Header>
      <Modal.Content>
        <p className={styles.modalDescription}>
          {t('sequences.newSequenceModal.description')}
        </p>
        <div className={styles.choicesContainer}>
          <NewSequenceTypeModalChoiceCard
            title={t('sequences.newSequenceModal.withAI.title')}
            illustrationPath='/images/illustrations/newSequenceFromAI.png'
            alt='Generate sequence with AI'
            url={`/client/${clientId}/reveal/sequences/new-sequence-create?withAIGeneration=true`}
            withAI
          />
          {!_.isEmpty(defaultSequenceTemplates) && (
            <NewSequenceTypeModalChoiceCard
              title={t('sequences.newSequenceModal.fromTemplate.title')}
              illustrationPath='/images/illustrations/newSequenceFromTemplate.png'
              alt='Generate sequence from template'
              url={`/client/${clientId}/reveal/sequences/new-sequence-preview`}
            />
          )}
          <NewSequenceTypeModalChoiceCard
            title={t('sequences.newSequenceModal.fromScratch.title')}
            illustrationPath='/images/illustrations/newSequenceFromScratch.png'
            alt='Generate sequence from scratch'
            url={`/client/${clientId}/reveal/sequences/new-sequence-create`}
          />
        </div>
      </Modal.Content>
    </GenericModal>
  );
};

interface NewSequenceTypeModalChoiceCardProps {
  title: string;
  illustrationPath: string;
  alt: string;
  url: string;
  withAI?: boolean;
}

const NewSequenceTypeModalChoiceCard: React.FC<NewSequenceTypeModalChoiceCardProps> = ({
  title,
  illustrationPath,
  alt,
  url,
  withAI = false,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.choiceCard}>
      <img src={illustrationPath} alt={alt} />
      <h3 className={styles.choiceTitle}>{title}</h3>
      <div className={styles.choiceCardAction}>
        <Link to={url}>
          <GenericButton
            className={classnames(
              styles.choiceButton,
              withAI ? styles.AIGeneration : null,
            )}
          >
            {t('sequences.newSequenceModal.choose')}
          </GenericButton>
          <div className={styles.gradientButtonBackground}>
            {t('sequences.newSequenceModal.choose')}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default NewSequenceTypeModal;
