import _ from 'underscore';
import createSnippetInputFromMessage from '@/common/contactFlow/createSnippetInputFromAction';
import {
  ACTION_KEY_BY_TYPE,
  TRIGGER_KEY_BY_TYPE,
  ACTION_FIELDS_BY_TYPE,
  OR_CONDITION_TYPE,
  AND_CONDITION_TYPE,
  TASK_TYPES,
  LINKEDIN_MESSAGE_SUBTYPES,
} from '../constants/taskTypes';
import { invalidEmailFormat } from '../validators';

const createMessageInputFromMessage = ({ message }) => {
  const validCc = _.filter(
    message.cc || [],
    (email) => !invalidEmailFormat({ email }),
  );
  const validBcc = _.filter(
    message.bcc || [],
    (email) => !invalidEmailFormat({ email }),
  );
  return {
    ...((message.senderId || message.sender?.id) && {
      senderId: message.senderId || message.sender.id,
    }),
    ...(message.templateId && { templateId: message.templateId }),
    ...(message.subject && { subject: message.subject }),
    ...(message.body && { body: message.body }),
    ...(message.cc && { cc: validCc }),
    ...(message.bcc && { bcc: validBcc }),
  };
};

const createTriggerInputFromTrigger = (trigger) => {
  const triggerKey = TRIGGER_KEY_BY_TYPE[trigger?.type];
  if (
    trigger?.type === OR_CONDITION_TYPE ||
    trigger?.type === AND_CONDITION_TYPE
  ) {
    return {
      [triggerKey]: {
        ..._.omit(trigger, 'conditions', '__typename'),
        conditions: _.map(trigger.conditions, createTriggerInputFromTrigger),
      },
    };
  }

  return {
    [triggerKey]: {
      ..._.omit(trigger, '__typename'),
      ...(trigger?.delay && {
        delay: _.omit(trigger?.delay, '__typename'),
      }),
    },
  };
};

const createActionInputFromAction = ({ action }) => {
  const {
    trigger,
    type,
    subtype,
    message,
    title,
    description,
    snippets,
    execution,
  } = action;

  const actionKey = ACTION_KEY_BY_TYPE[type] || 'customAction';
  const actionFields = ACTION_FIELDS_BY_TYPE[type];
  const keysOmitted = [
    'id',
    'actionId',
    'completion',
    'title',
    'description',
    'message',
    'plannedExecutionDate',
    'nbManualSnoozes',
    'snippets',
    'snoozeConfiguration',
    '__typename',
  ];

  if (
    ![
      TASK_TYPES.LINKEDIN_SEND_MESSAGE,
      TASK_TYPES.LINKEDIN_RECRUITER_INMAIL,
      TASK_TYPES.LINKEDIN_PREMIUM_INMAIL,
      TASK_TYPES.LINKEDIN_SALESNAVIGATOR_INMAIL,
    ].includes(type)
  ) {
    keysOmitted.push('subtype');
  }

  return {
    [actionKey]: {
      ..._.omit(action, keysOmitted),
      ...((type === TASK_TYPES.LINKEDIN_RECRUITER_INMAIL ||
        type === TASK_TYPES.LINKEDIN_SALESNAVIGATOR_INMAIL) && {
        type: TASK_TYPES.LINKEDIN_SEND_MESSAGE,
      }),
      ...(type === TASK_TYPES.LINKEDIN_SEND_MESSAGE &&
        !subtype && {
          subtype: LINKEDIN_MESSAGE_SUBTYPES.CLASSIC,
        }),
      trigger: createTriggerInputFromTrigger(trigger),
      ...(title &&
        (!!actionFields?.title || type === TASK_TYPES.CUSTOM) && {
          title: _.omit(title, '__typename'),
        }),
      ...(description &&
        !!actionFields?.description && {
          description: _.omit(description, '__typename'),
        }),
      ...(message &&
        !!actionFields?.message && {
          message: createMessageInputFromMessage({ message }),
        }),
      ...(execution && { execution }),
      ...(message &&
        !!actionFields?.message && {
          snippets: createSnippetInputFromMessage({
            snippets: message.snippets || snippets,
            message,
          }),
        }),
    },
  };
};

export default createActionInputFromAction;
