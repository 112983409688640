import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';

import { Button, Dropdown, Modal } from 'semantic-ui-react';

import withClientActiveOffers from '../../../../hocs/offers/withClientActiveOffers';
import withChangeProfileOffer from '../../../../hocs/profiles/withChangeProfileOffer';

import './moveModal.css';

const MoveModal = (props) => {
  const {
    profileId,
    offerId,
    changeProfileOffer,
    goToNextProfile,
    activeOffers,
    t,
  } = props;

  const [open, setOpen] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState('');

  const onSelectOffer = (e, { value }) => {
    setSelectedOfferId(value);
  };

  const onSubmit = async () => {
    if (selectedOfferId === offerId) {
      return;
    }
    try {
      await changeProfileOffer({ profileId, newOfferId: selectedOfferId });
      goToNextProfile();
      setOpen(false);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  };

  const offerOptions = formatOfferOptions(activeOffers, offerId);

  const trigger = offerOptions.length ? (
    <div className='move-profile-trigger'>
      <button
        className='move-button'
        onClick={() => setOpen(true)}
        type='button'
      >
        {t('profile.steps.move.button')}
      </button>
    </div>
  ) : null;

  return (
    <div>
      <Modal
        className='move-profile-modal'
        open={open}
        onClose={() => setOpen(false)}
        trigger={trigger}
        closeIcon
        size='small'
      >
        <Modal.Header>{t('profile.steps.move.header')}</Modal.Header>
        <Modal.Content>
          <div className='input-container'>
            <div className='input-label'>
              {t('profile.steps.move.subheader')}
            </div>
            <div className='input-element'>
              <Dropdown
                selection
                options={offerOptions}
                value={selectedOfferId}
                placeholder={t('profile.steps.move.placeholder')}
                onChange={onSelectOffer}
                fluid
              />
            </div>
          </div>
          {/* <div> */}
          {/* <span>Dans:</span> */}
          {/* <Dropdown */}
          {/* selection */}
          {/* options={stepOptions(t)} */}
          {/* value={selectedStep} */}
          {/* onChange={onSelectStep} */}
          {/* /> */}
          {/* </div> */}
        </Modal.Content>
        <Modal.Actions>
          <div className='align-left'>
            <Button
              className='dismiss'
              type='submit'
              onClick={() => setOpen(false)}
            >
              {t('common.cancel')}
            </Button>
          </div>
          <Button
            primary
            size='big'
            type='submit'
            onClick={onSubmit}
            disabled={!selectedOfferId}
          >
            {t('profile.steps.move.confirm')}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

const formatOfferOptions = (offers, currentOfferId) => {
  const options = _.map(offers, (offer) => {
    if (offer.id === currentOfferId) {
      return null;
    }
    return {
      key: offer.id,
      value: offer.id,
      text: offer.title,
    };
  });
  // NOTE: _.sortBy(options, 'text') does not take caps into account ('a' > 'B')
  return _.compact(options).sort((a, b) => {
    return a.text.localeCompare(b.text);
  });
};

// const steps = [
//   'pending',
//   'contacted',
//   'answered',
//   'in-process',
//   'hired',
//   'disqualified',
// ];
//
// const stepOptions = (t) => _.map(steps, (step) => ({
//   key: step,
//   value: step,
//   text: t(`profile.steps.${step}`),
// }));

export default compose(
  withClientActiveOffers,
  withChangeProfileOffer,
  withTranslation('translations'),
)(MoveModal);
