import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import useClientId from '@/hooks/router/useClientId';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import LegacyOffers from './LegacyOffers';
import Offers from './Offers';
import UnifiedViewContext from './UnifiedViewContext';

const OffersSwitch: FC = () => {
  const clientId = useClientId();
  const { data } = useClientPermissions(clientId);
  const history = useHistory();

  const { marketplaceUnifiedView } = data?.client?.permissions || {};

  if (!marketplaceUnifiedView) {
    return <LegacyOffers clientId={clientId} history={history} />;
  }

  return (
    <UnifiedViewContext.Provider value={{ unifiedView: true }}>
      <Offers />
    </UnifiedViewContext.Provider>
  );
};

export default OffersSwitch;
