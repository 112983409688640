import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { OfferStatistics } from '../../graphql/fragments/Analytics';

export const query = gql`
  query getClientStatistics($clientId: ID!, $offerIds: [String!]) {
    client(id: $clientId) {
      id
      statistics {
        offers(offerIds: $offerIds) {
          ...OfferStatistics
        }
      }
    }
  }
  ${OfferStatistics}
`;

export default graphql(query, {
  options: ({ clientId, offerIds }) => ({
    variables: {
      clientId,
      offerIds: offerIds || [],
    },
  }),
  props: ({ data: { loading, client, error } }) => ({
    statistics: (client || {}).statistics,
    statisticsLoading: loading,
    statisticsError: error,
  }),
});
