import { graphql } from '@apollo/client/react/hoc';
import { GET_RECOMMENDED_PROFILES_COUNT } from '../../graphql/clients';

export default graphql(GET_RECOMMENDED_PROFILES_COUNT, {
  options: ({ clientId, sortBy }) => {
    const variables = { clientId, sortBy };
    return {
      variables,
      fetchPolicy: 'network-only', // TODO: good ? Always refetch when tab is selected
    };
  },
  props: ({ data: { client, loading } }) => {
    return {
      jobsWithRecommendedProfilesCount: client && client.offers,
      jobsWithRecommendedProfilesCountLoading: loading,
    };
  },
});
