import { TranslatableText } from '@/types/text';
import { gql, useMutation } from '@apollo/client';

const DELETE_CUSTOM_CLIENT_COMPANY_STATE = gql`
  mutation DeleteClientCompanyState($customCompanyDealStateId: ID!) {
    deleteClientCompanyState(
      customCompanyDealStateId: $customCompanyDealStateId
    ) {
      id
      customCompanyDealStates {
        customCompanyDealStateId
        title {
          default
          fr
          en
        }
        type
      }
    }
  }
`;

interface DeleteClientCustomCompanyDealStateVariables {
  customCompanyDealStateId: string;
}

interface ClientCustomCompanyDealStateResults {
  client: {
    customCompanyDealStates: {
      customCompanyDealStateId: string;
      title: TranslatableText;
      type: string;
    }[];
  };
}

const useDeleteCustomClientCompanyState = () => {
  return useMutation<
    ClientCustomCompanyDealStateResults,
    DeleteClientCustomCompanyDealStateVariables
  >(DELETE_CUSTOM_CLIENT_COMPANY_STATE);
};

export default useDeleteCustomClientCompanyState;
