import gql from 'graphql-tag';

export default gql`
  fragment NotificationsSet on Client {
    notifications {
      countSeen
      countNotSeen
      seen {
        ...NotificationComplete
      }
      notSeen {
        ...NotificationComplete
      }
    }
  }

  fragment NotificationComplete on Notification {
    type
    date
    seen
    ... on ProfileAnswerNotification {
      offer {
        id
        title
      }
      profile {
        id
        resumeData {
          email
          firstname
          lastname
          photoLink
          headline {
            content {
              text
            }
          }
        }
      }
    }
  }
`;
