import _ from 'underscore';
import moment from 'moment';

export const countAllFilters = (filters) =>
  countRecruiters(filters) +
  countCoordinators(filters) +
  countCreationDate(filters) +
  countLastActionDate(filters) +
  countStages(filters) +
  countApplicationStatuses(filters) +
  countTags(filters) +
  countInATSFilter(filters);

const countRecruiters = (filters) => filters?.recruiterIds?.length || 0;

const countCoordinators = (filters) => filters?.coordinatorIds?.length || 0;

const countCreationDate = (filters) =>
  filters?.minCreationTimestamp ||
  filters?.maxCreationTimestamp ||
  filters?.minCreationDelayInMs ||
  filters?.maxCreationDelayInMs
    ? 1
    : 0;

const countLastActionDate = (filters) =>
  filters?.minLastActionTimestamp ||
  filters?.maxLastActionTimestamp ||
  filters?.minLastActionDelayInMs ||
  filters?.maxLastActionDelayInMs
    ? 1
    : 0;

const countStages = (filters) => filters?.stageIds?.length || 0;

const countApplicationStatuses = (filters) =>
  filters?.applicationStatuses?.length || 0;

const countTags = (filters) => filters?.tagIds?.length || 0;

const countInATSFilter = (filters) => {
  const { profileInATS } = filters || {};
  if (profileInATS?.yes && profileInATS?.no) {
    return 0;
  }
  if (profileInATS?.yes || profileInATS?.no) {
    return 1;
  }
  return 0;
};

export const getDateFromTimestamp = (timestamp) => {
  if (_.isNumber(timestamp)) {
    return new Date(timestamp);
  }
  return null;
};

export const getTimestampFromDate = (date) => {
  if (date) {
    return new Date(date).getTime();
  }
  return null;
};

const dateFormat = 'MM/DD/YYYY';
export const getCreationDynamicDescription = (minDelay, maxDelay) => {
  if (!minDelay && !maxDelay) {
    return null;
  }
  if (minDelay && !maxDelay) {
    const minDate = moment(Date.now() - minDelay).format(dateFormat);
    return `Contacts shown were created after the ${minDate}. (Rolling period)`;
  }
  if (maxDelay && !minDelay) {
    const maxDate = moment(Date.now() - maxDelay).format(dateFormat);
    return `Contacts shown were created before the ${maxDate}. (Rolling period)`;
  }
  const minDate = moment(Date.now() - minDelay).format(dateFormat);
  const maxDate = moment(Date.now() - maxDelay).format(dateFormat);
  return `Contacts shown were created before the ${maxDate} and after the ${minDate}. (Rolling period)`;
};

export const getLastActionDynamicDescription = (minDelay, maxDelay) => {
  if (!minDelay && !maxDelay) {
    return null;
  }
  if (minDelay && !maxDelay) {
    const minDate = moment(Date.now() - minDelay).format(dateFormat);
    return `Contacts shown have last been updated after the ${minDate}. (Rolling period)`;
  }
  if (maxDelay && !minDelay) {
    const maxDate = moment(Date.now() - maxDelay).format(dateFormat);
    return `Contacts shown have last been updated before the ${maxDate}. (Rolling period)`;
  }
  const minDate = moment(Date.now() - minDelay).format(dateFormat);
  const maxDate = moment(Date.now() - maxDelay).format(dateFormat);
  return `Contacts shown have last been updated before the ${maxDate} and after the ${minDate}. (Rolling period)`;
};
