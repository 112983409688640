import React from 'react';
import _ from 'underscore';
import { TranslatableText as TranslatableTextType } from '@/types/text';
import { getTranslatedText } from '../common';

// TODO: move to common and convert to TS
const extractLink = (text: string) => {
  const markdownLinkRegexp = /\[(.*?)\]\((.*?)\)/;
  const match = text.match(markdownLinkRegexp);
  if (match) {
    const textBeforeMatch = text.slice(0, match.index);
    const textAfterMatch = text.slice(match.index! + match[0].length);
    const link = (
      <a
        key={match[1]}
        href={match[2]}
        target='_blank'
        rel='noopener noreferrer'
      >
        {match[1]}
      </a>
    );
    return [textBeforeMatch, link, textAfterMatch];
  }
  return [text, ''];
};

/**
 * Turn text String into an Array of strings and parsed <a> tags
 * Links are recognised according to markdown format: [link](https://url.com)
 *
 * Example:
 * input: "Click [here](https://url.com) !"
 * output: ["Click ", <a href='https://url.com'>here</>, " !"] (simplified)
 */
const extractLinksAsTags = (text: string) => {
  let textsAndLinks = extractLink(text);
  let textToProcess = textsAndLinks[textsAndLinks.length - 1];
  while (textToProcess !== '') {
    // remove old textToProcess from end of array
    textsAndLinks = textsAndLinks.slice(0, -1);
    // concat new array
    textsAndLinks = textsAndLinks.concat(extractLink(textToProcess as string));
    // compute remaining text
    textToProcess = textsAndLinks[textsAndLinks.length - 1];
  }
  return _.compact(textsAndLinks);
};

type TranslatableTextProps = {
  text: TranslatableTextType;
  className?: string;
};

const TranslatableText = ({ text, className }: TranslatableTextProps) => {
  const textsAndLinks = extractLinksAsTags(getTranslatedText(text));
  return <span className={className}>{[...textsAndLinks]}</span>;
};

export default TranslatableText;
