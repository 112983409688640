import { useTranslation } from 'react-i18next';
import React from 'react';

const SidePanelInformations = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className='side-block-tips'>
        <h4>{t('criteria.sideContent.remoteAndLocation.title')}</h4>
        <p>{t('criteria.sideContent.remoteAndLocation.subtitle')}</p>
        <div className='tip-line'>
          <i className='ri-star-line' />
          {t('criteria.sideContent.remoteAndLocation.tip1')}
        </div>
        <div className='tip-line'>
          <i className='ri-map-pin-line' />
          {t('criteria.sideContent.remoteAndLocation.tip2')}
        </div>
        <div className='tip-line'>
          <i className='ri-home-4-line' />
          {t('criteria.sideContent.remoteAndLocation.tip3')}
        </div>
        <div className='tip-line'>
          <i className='ri-building-2-line' />
          {t('criteria.sideContent.remoteAndLocation.tip4')}
        </div>
      </div>
      <div className='side-block-tips'>
        <h4>{t('criteria.sideContent.skills.title')}</h4>
        <p>{t('criteria.sideContent.skills.subtitle')}</p>
        <div className='tip-line'>
          <i className='ri-scan-2-line' />
          {t('criteria.sideContent.skills.tip1')}
        </div>
        <div className='tip-line'>
          <i className='ri-bar-chart-2-line' />
          {t('criteria.sideContent.skills.tip2')}
        </div>
        <div className='tip-line'>
          <i className='ri-focus-3-line' />
          {t('criteria.sideContent.skills.tip3')}
        </div>
      </div>
      <div className='side-block-tips'>
        <h4>{t('criteria.sideContent.salary.title')}</h4>
        <p>{t('criteria.sideContent.salary.subtitle')}</p>
        <div className='tip-line'>
          <i className='ri-focus-3-line' />
          {t('criteria.sideContent.salary.tip1')}
        </div>
        <div className='tip-line'>
          <i className='ri-user-star-line' />
          {t('criteria.sideContent.salary.tip2')}
        </div>
        <div className='tip-line'>
          <i className='ri-chat-private-line' />
          {t('criteria.sideContent.salary.tip3')}
        </div>
      </div>
      <div className='side-block-tips'>
        <h4>{t('criteria.sideContent.search.title')}</h4>
        <p>{t('criteria.sideContent.search.subtitle')}</p>
        <div className='tip-line'>
          <i className='ri-number-1' />
          {t('criteria.sideContent.search.tip1')}
        </div>
        <div className='tip-line'>
          <i className='ri-number-2' />
          {t('criteria.sideContent.search.tip2')}
        </div>
        <div className='tip-line'>
          <i className='ri-number-3' />
          {t('criteria.sideContent.search.tip3')}
        </div>
      </div>
    </>
  );
};

export default SidePanelInformations;
