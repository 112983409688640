import React from 'react';

import { Dropdown, Icon } from 'semantic-ui-react';
import { compose } from 'underscore';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import withLogout from '../../../hocs/users/withLogout';
import withActionLogger from '../../../hocs/withActionLogger';
import withEmailContextConsumer from '../../../hocs/email/withEmailContextConsumer';
import UserInitialThumbnail from '../../../components/UserInitialThumbnail';

import styles from './SettingsDropdown.module.less';

const SettingsDropdown = ({ clientId, onLogout, onLogAction, emailApi, t }) => {
  const logAction = ({ type }) => {
    onLogAction({
      type,
      info: {
        clientId,
        from: 'settings-dropdown',
      },
    });
  };
  // const showBilling = !_.isEmpty(pricingPlanConfiguration);

  const trigger = (
    <div className={styles.dropdownTrigger}>
      <UserInitialThumbnail clientId={clientId} />
      <Icon className={styles.dropdownCaret} name='dropdown' />
    </div>
  );

  return (
    <div className={styles.settingsDropdownContainer}>
      <Dropdown direction='left' trigger={trigger} icon={null}>
        <Dropdown.Menu
          className={`hs-dropdown-menu ${styles.settingsDropdownMenu}`}
        >
          <Dropdown.Item
            as={Link}
            to={`/client/${clientId}/settings`}
            className='dropdown-item first last'
            onClick={() => logAction({ type: 'click-personal-settings' })}
          >
            {t('header.accountDropdownMenu.settings')}
          </Dropdown.Item>

          <Dropdown.Divider />

          {emailApi &&
            emailApi.onTriggerChangeAccount &&
            (emailApi.currentEmailType === 'outlook' ? (
              <Dropdown.Item
                className='dropdown-item first last'
                onClick={() => {
                  emailApi.onTriggerChangeAccount();
                  logAction({ type: 'click-email-change-account' });
                }}
              >
                {t('header.accountDropdownMenu.switchEmailAccounts')}
              </Dropdown.Item>
            ) : (
              <>
                <Dropdown.Item
                  className='dropdown-item first'
                  onClick={() => {
                    emailApi.onTriggerChangeAccount('gmail');
                    logAction({ type: 'click-gmail-change-account' });
                  }}
                >
                  {emailApi.currentEmailType === 'gmail'
                    ? t('header.accountDropdownMenu.switchGmailAccount')
                    : t('header.accountDropdownMenu.useGmailAccount')}
                </Dropdown.Item>
                <Dropdown.Item
                  className='dropdown-item last'
                  onClick={() => {
                    emailApi.onTriggerChangeAccount('outlook');
                    logAction({ type: 'click-outlook-change-account' });
                  }}
                >
                  {emailApi.currentEmailType === 'outlook'
                    ? t('header.accountDropdownMenu.switchOutlookAccount')
                    : t('header.accountDropdownMenu.useOutlookAccount')}
                </Dropdown.Item>
              </>
            ))}

          <Dropdown.Divider />

          <Dropdown.Item
            className='dropdown-item first last'
            onClick={onLogout}
          >
            {t('header.accountDropdownMenu.logout')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default compose(
  withActionLogger,
  withLogout,
  // withClientBilling,
  withEmailContextConsumer,
  withTranslation('translations'),
)(SettingsDropdown);
