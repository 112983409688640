import gql from 'graphql-tag';
import RevealProject, { IRevealProject } from './fragments/RevealProject';

export interface GetClientRevealProjectsVariables {
  clientId: string;
}

export interface GetClientRevealProjectsResult {
  client: {
    id: string;
    revealProjects: IRevealProject[];
  };
}

const GET_CLIENT_REVEAL_PROJECTS = gql`
  query getClientRevealProjects($clientId: ID!) {
    client(id: $clientId) {
      id
      revealProjects {
        ...RevealProject
      }
    }
  }
  ${RevealProject}
`;

export default GET_CLIENT_REVEAL_PROJECTS;
