import React from 'react';
import { TFunction } from 'i18next';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import { withTranslation } from 'react-i18next';

const ModalDeletion = ({
  open,
  setOpen,
  onConfirm,
  profileName,
  t,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
  profileName: string;
  t: TFunction;
}) => {
  return (
    <ConfirmationModal
      open={open}
      onCancel={() => setOpen(false)}
      onSubmit={onConfirm}
      header={t('reveal.profileDeletion.modal.header', { profileName })}
      submit={t('reveal.profileDeletion.modal.confirm')}
      cancel={t('reveal.profileDeletion.modal.cancel')}
      size='tiny'
    />
  );
};

export default withTranslation('translations')(ModalDeletion as any);
