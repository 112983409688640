/* eslint-disable max-len */

import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './svg.module.less';

interface EditProps {
  className?: string;
}

const Edit: FC<EditProps> = ({ className }) => (
  <svg
    className={classNames(styles.svgIcon, className)}
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.5224 4.24834L11.7517 2.47765C11.1148 1.84078 10.0822 1.84078 9.44537 2.47765L4.36363 7.55939C4.20442 7.7186 4.20442 7.97674 4.36363 8.13596L7.86404 11.6364C8.02326 11.7956 8.2814 11.7956 8.44061 11.6364L13.5224 6.55463C14.1592 5.91776 14.1592 4.88521 13.5224 4.24834Z'
      fill='currentColor'
    />
    <path
      d='M6.84699 12.2501L3.7499 9.15301C3.53009 8.9332 3.155 9.0304 3.0696 9.3293L2.03237 12.9596C1.85663 13.5747 2.42531 14.1434 3.0404 13.9676L6.6707 12.9304C6.96961 12.845 7.0668 12.4699 6.84699 12.2501Z'
      fill='currentColor'
    />
  </svg>
);

export default Edit;
