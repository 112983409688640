import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

/**
 * Unlock a profile manually after send email attempt failed
 * (error may have occurred before or after the send)
 */
export const unlockProfile = gql`
  mutation unlockProfile($profileId: ID!, $error: String) {
    unlockProfile(id: $profileId, error: $error) {
      id
      locked
      lockedSince
      contacted
      sent
      resumeData {
        step
      }
      # # refetch timeline in case email was sent but not displayed
      # (edit: does not work if first email fails and no thread is saved ?)
      # contactData {
      #   creationTimestamp
      #   defaultThreadId
      #   timeline {
      #     ...TimelineItem
      #   }
      # }
    }
  }
`;

export default graphql(unlockProfile, {
  props: ({ mutate }) => ({
    unlockProfile: ({ profileId, error }) => {
      const variables = { profileId, error };
      return mutate({ variables });
    },
  }),
});
