/* global gapi */
import React, { useContext } from 'react';

const googleClientId = process.env.REACT_APP_GOOGLE_APP_CLIENT_ID;
const googleApiKey = process.env.REACT_APP_GOOGLE_APP_API_KEY;

const GoogleContext = React.createContext();

const auth2 = new Promise((resolve, reject) => {
  gapi.load('client:auth2', () => {
    gapi.client.setApiKey(googleApiKey);
    gapi.auth2.init({ client_id: googleClientId }).then(
      () => {
        resolve(gapi.auth2.getAuthInstance());
      },
      (reason) => {
        reject(reason);
      },
    );
  });
});

const GoogleAuth2 = ({ children }) => {
  return <GoogleContext.Provider value={auth2}>{children}</GoogleContext.Provider>;
};

export const useGoogleAuth2 = () => useContext(GoogleContext);

export const withGoogleAuth2 = (WrappedComponent) => (props) => {
  const auth2Promise = useGoogleAuth2();
  return <WrappedComponent auth2Promise={auth2Promise} {...props} />;
};

export default GoogleAuth2;
