import classNames from 'classnames';
import React, {
  CSSProperties,
  FC,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
  ReactNode,
  PropsWithChildren,
} from 'react';
import _ from 'underscore';

import DropdownControlsContext from '@/context/DropdownControlsContext';
import useEagerClientRect from '@/hooks/common/useEagerClientRect';
import { GenericDropdownPosition } from './types';

import styles from './GenericDropdown.module.less';

export interface TriggerProps {
  onClick: () => void;
  disabled?: boolean;
}

export interface GenericDropdownProps {
  position?: GenericDropdownPosition;
  disabled?: boolean;
  upward?: boolean;
  /** @deprecated use lowercase trigger and DropdownControlsContext instead */
  Trigger?: FC<TriggerProps>;
  trigger?: ReactNode;
  onDisplayStatus?: (displayStatus: boolean) => void;
  className?: string;
  onClick?: (e: any) => void;
  contentClassName?: string;
  style?: CSSProperties;
  center?: boolean;
  initialOpen?: boolean;
}

const GenericDropdown = ({
  position = 'left',
  disabled,
  upward,
  children,
  Trigger,
  trigger,
  className,
  onClick,
  onDisplayStatus,
  contentClassName,
  style,
  center = false,
  initialOpen = false,
}: PropsWithChildren<GenericDropdownProps>) => {
  const { whitelistElement: whitelistElementForParent } = useContext(
    DropdownControlsContext,
  );
  const [isContentDisplayed, setIsContentDisplayed] = useState(initialOpen);
  const [whitelistedOutsideElements, setWhitelistedOutsideElements] = useState(
    [] as HTMLElement[],
  );

  const [containerElement, setContainerElement] = useState<HTMLDivElement>();

  const { ref: containerRefObs, rect: containerRect } = useEagerClientRect();
  const { ref: contentRefObs, rect: contentRect } = useEagerClientRect();

  const mountContainerRef = useCallback(
    (ref: HTMLDivElement | null) => {
      containerRefObs(ref);
      setContainerElement(ref || undefined);
    },
    [containerRefObs],
  );

  const toggleDisplayed = useCallback(
    () => setIsContentDisplayed((value) => !value),
    [],
  );

  const whitelistElement = useMemo(
    () => (element: HTMLElement) => {
      setWhitelistedOutsideElements((current) => [...current, element]);
      whitelistElementForParent(element);
    },
    [whitelistElementForParent],
  );

  const shouldBeTop = useMemo(() => {
    if (upward) {
      return true;
    }
    if (!containerRect || !contentRect || !isContentDisplayed) {
      return false;
    }

    const contentHeight = contentRect.height || 0;
    const containerBottom = containerRect.bottom || 0;
    const containerTop = containerRect.top || 0;

    if (containerTop < contentHeight + 8) {
      return false;
    }

    if (containerBottom + contentHeight + 8 >= document.body.clientHeight) {
      return true;
    }

    return false;
  }, [containerRect, contentRect, isContentDisplayed, upward]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      let shouldHide = true;
      if (containerElement && containerElement.contains(e.target as Node)) {
        shouldHide = false;
      }
      _.each(whitelistedOutsideElements, (element) => {
        if (!shouldHide) return;
        if (element.contains(e.target as Node)) {
          shouldHide = false;
        }
      });
      if (shouldHide) setIsContentDisplayed(false);
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerElement, whitelistedOutsideElements]);

  useEffect(() => {
    if (onDisplayStatus) {
      onDisplayStatus(isContentDisplayed);
    }
  }, [onDisplayStatus, isContentDisplayed]);

  return (
    <DropdownControlsContext.Provider
      value={{
        toggleDropdown: toggleDisplayed,
        closeDropdown: () => setIsContentDisplayed(false),
        whitelistElement,
      }}
    >
      <div
        className={classNames(styles.dropdownContainer, className)}
        ref={mountContainerRef}
        onClick={onClick}
      >
        {trigger ||
          (Trigger && (
            <Trigger disabled={disabled} onClick={toggleDisplayed} />
          ))}
        <div
          className={classNames(
            styles.dropdownContent,
            styles[position],
            isContentDisplayed && styles.open,
            shouldBeTop ? styles.top : styles.bottom,
            center && styles.center,
            contentClassName,
          )}
          style={style}
          ref={contentRefObs}
        >
          {children}
        </div>
      </div>
    </DropdownControlsContext.Provider>
  );
};

export default GenericDropdown;
