import classNames from 'classnames';
import React from 'react';
import _ from 'underscore';

import './MatchLevelGauge.css';

const MatchLevelGauge = ({ score, positive }) => {
  let cleanScore = score || 0;
  if (cleanScore > 1) {
    cleanScore = 1;
  }
  const numberOfFullBars = Math.floor(cleanScore * 10);
  const rest = cleanScore - Math.floor(cleanScore);
  const numberOfBackgroundBars = Math.floor(10 - cleanScore * 10);

  return (
    <div className={classNames('match-level-gauge', positive && 'positive')}>
      {_.times(numberOfFullBars, (index) => (
        <div key={`full_${index}`} className='fill-bar' />
      ))}
      <div className='none-bar'>
        <div className='fill-bar' style={{ width: `${rest * 100}%` }} />
        <div className='back-bar' style={{ width: `${(1 - rest) * 100}%` }} />
      </div>
      {_.times(numberOfBackgroundBars, (index) => (
        <div key={`background_${index}`} className='back-bar' />
      ))}
    </div>
  );
};

export default MatchLevelGauge;
