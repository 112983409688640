import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'underscore';
import { GlobalHotKeys } from 'react-hotkeys';
import { Button, Modal, Form, Checkbox, Popup } from 'semantic-ui-react';

import GenericButton from '@/components/Common/GenericButton';
import Send from '@/components/Common/Icons/Send';
import withActionLogger from '../../../../../../hocs/withActionLogger';
import withStopConfirmationModal from '../../../../../../hocs/users/withStopConfirmationModal';
import { getOSFromPlatform } from './helpers';

import './confirmationModal.css';

const SESSION = 'session';
const FOREVER = 'forever';

const OS_CODE = getOSFromPlatform();
const MOD_KEY = OS_CODE === 'mac' ? '⌘' : 'Ctrl';

class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stopConfirmation: '',
    };
  }

  logAction = ({ type }) => {
    const { clientId, onLogAction } = this.props;
    onLogAction({
      type,
      info: {
        clientId,
        from: 'send-confirmation-modal',
      },
    });
  };

  handleSend = () => {
    const { stopConfirmation } = this.state;
    const {
      emailApi,
      stopConfirmationModal,
      onSubmit,
      clientId,
      openWithHotKey,
    } = this.props;

    if (stopConfirmation === SESSION || stopConfirmation === FOREVER) {
      emailApi.setSendConfirmationMode(false);
    }

    onSubmit(undefined, undefined, openWithHotKey).then(() => {
      if (stopConfirmation === FOREVER) {
        stopConfirmationModal({ clientId });
      }
    });
  };

  renderButton = () => {
    const {
      assignedSender,
      offerAssignedSender,
      onTrigger,
      sendDisabled,
      t,
    } = this.props;

    let trigger;
    if (assignedSender) {
      trigger = (
        <Button
          color='orange'
          style={{ fontSize: 10 }}
          onClick={onTrigger}
          disabled={sendDisabled}
        >
          Send as {assignedSender.alias}({assignedSender.email})
        </Button>
      );
    } else if (offerAssignedSender) {
      trigger = (
        <GenericButton
          className='button-sobo'
          onClick={onTrigger}
          disabled={sendDisabled}
        >
          <Send />
          {t('profile.contact.drafts.confirmationModal.sendOnBehalfOf')}
        </GenericButton>
      );
    } else {
      trigger = (
        <GenericButton
          id='button-ask-send'
          onClick={onTrigger}
          disabled={sendDisabled}
          primacy='primary'
        >
          <Send />
          {t('profile.contact.drafts.confirmationModal.trigger')}
        </GenericButton>
      );
    }

    const keyMap = {
      SEND: OS_CODE === 'mac' ? 'Command+Enter' : 'Control+Enter',
    };

    const handlers = {
      SEND: () => {
        if (sendDisabled) {
          return;
        }
        onTrigger({ sendWithHotKey: true });
        this.logAction({ type: 'send-with-hot-key' });
      },
    };

    return (
      <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
        <Popup
          trigger={trigger}
          size='tiny'
          position='bottom center'
          inverted
          content={<SendButtonTooltip t={t} />}
        />
      </GlobalHotKeys>
    );
  };

  render() {
    const { open, openWithHotKey, t } = this.props;
    return (
      <div>
        <Modal
          className='stop-confirmation-modal'
          open={open}
          onClose={this.props.onCancel}
          trigger={this.renderButton()}
          closeIcon
          size='small'
        >
          <Modal.Header>
            {t('profile.contact.drafts.confirmationModal.header')}
          </Modal.Header>
          <Modal.Content>
            <div className='input-container'>
              <div className='input-label'>
                {t('profile.contact.drafts.confirmationModal.removeWarning')}
              </div>
              <Form>
                <Form.Field className='input-element'>
                  <Checkbox
                    name={SESSION}
                    checked={this.state.stopConfirmation === SESSION}
                    label={t(
                      'profile.contact.drafts.confirmationModal.sessionOnly',
                    )}
                    onChange={(event, { checked }) =>
                      this.setState({
                        stopConfirmation: checked ? SESSION : '',
                      })
                    }
                  />
                </Form.Field>
                <Form.Field className='input-element'>
                  <Checkbox
                    name={FOREVER}
                    checked={this.state.stopConfirmation === FOREVER}
                    label={t(
                      'profile.contact.drafts.confirmationModal.permanently',
                    )}
                    onChange={(event, { checked }) =>
                      this.setState({
                        stopConfirmation: checked ? FOREVER : '',
                      })
                    }
                  />
                </Form.Field>
              </Form>
            </div>
            {!openWithHotKey && (
              <div className='hot-key-tip'>
                <span>
                  {t(
                    'profile.contact.drafts.confirmationModal.proTip.youCanAlsoHit',
                  )}
                </span>
                <span className='code-text'>{MOD_KEY}</span>
                <span> + </span>
                <span className='code-text'>{t('common.keyEnter')}</span>
                <span>
                  {t('profile.contact.drafts.confirmationModal.proTip.toSend')}
                </span>
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <div className='align-left'>
              <Button
                className='dismiss'
                type='submit'
                onClick={() => this.props.onCancel()}
              >
                {t('profile.contact.drafts.confirmationModal.cancel')}
              </Button>
            </div>
            <Button
              primary
              size='big'
              type='submit'
              onClick={() => this.handleSend()}
            >
              {t('profile.contact.drafts.confirmationModal.send')}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const SendButtonTooltip = ({ t }) => {
  return (
    <div>
      <span>
        {t('profile.contact.drafts.confirmationModal.triggerTooltip.hit')}
      </span>
      <span className='code-text'>{MOD_KEY}</span>
      <span> + </span>
      <span className='code-text'>{t('common.keyEnter')}</span>
      <br />
      <span>
        {t('profile.contact.drafts.confirmationModal.triggerTooltip.toSend')}
      </span>
    </div>
  );
};

export default compose(
  withActionLogger,
  withStopConfirmationModal,
  withTranslation('translations'),
)(ConfirmationModal);
