import gql from 'graphql-tag';
import {
  MutationHookOptions,
  useMutation,
  MutationResult,
  FetchResult,
} from '@apollo/client';

const ASK_DEMO = gql`
  mutation askDemo {
    askDemo {
      id
    }
  }
`;

type AskDemoVariables = Record<string, never>;

type AskDemoData = {
  askDemo: {
    id: string;
  };
};

type UseAskDemoInput = {
  mutationOptions?: MutationHookOptions<AskDemoData, AskDemoVariables>;
};

type UseAskDemoResult = [
  () => Promise<FetchResult<AskDemoData>>,
  MutationResult<AskDemoData>,
];

const useAskDemo = ({
  mutationOptions,
}: UseAskDemoInput = {}): UseAskDemoResult =>
  useMutation<AskDemoData, AskDemoVariables>(ASK_DEMO, mutationOptions);

export default useAskDemo;
