import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Offer from '../../graphql/fragments/Offer';

export const ProfilesQuery = gql`
  query getUpdateOfferProfiles(
    $offerId: ID!
    $step: String!
    $search: String!
  ) {
    offer(id: $offerId) {
      id
      profiles(step: $step, search: $search) {
        id
        contactData {
          draftComment
        }
        lastStepUpdateTimestamp
      }
    }
  }
`;

export const mutation = gql`
  mutation updateOffer($id: ID!, $input: OfferInput!) {
    updateOffer(id: $id, input: $input) {
      ...Offer
    }
  }
  ${Offer}
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    updateOffer: ({ id, input }) =>
      mutate({
        variables: {
          id,
          input,
        },
        refetchQueries: [
          {
            query: ProfilesQuery,
            variables: { offerId: id, search: '', step: 'pending' },
          },
        ],
      }),
  }),
});
