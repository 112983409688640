import React from 'react';
import { withTranslation } from 'react-i18next';
import './styles/PendingInviteCard.css';

const PendingInviteCard = ({ invite, onClick, t }) => (
  <div className="txt-box-container">
    <div className="box-title">
      <div className="box-email">
        <div className="box-email-header">{invite.email}</div>
        <div className="box-email-body">
          {t('collaborators.pendingSection.sent')}{' '}
          {+invite.date
            ? t('common.relativeDate', { date: new Date(+invite.date) })
            : t('common.defaultDuration')}
        </div>
      </div>
      <div className="box-action" onClick={onClick}>
        {t('collaborators.pendingSection.delete')}
      </div>
    </div>
  </div>
);

export default withTranslation('translations')(PendingInviteCard);
