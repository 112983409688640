import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import ContentEditable from 'react-contenteditable';
import {
  Button,
  Dropdown,
  Form,
  Input,
  Modal,
  ModalActions as Actions,
  ModalContent as Content,
  ModalHeader as Header,
} from 'semantic-ui-react';
import { useEditSenderState } from './hooks';
import { recruitersToOptions, validEditSenderFormData } from '../helpers';
import GmailAliasPreview from '../GmailAliasPreview';

import withClientUsers from '../../../../../hocs/clients/withClientUsers';
import withUpdateSender from '../../../../../hocs/senders/withUpdateSender';

import './EditSenderModal.css';

const EditSenderModal = ({
  users,
  ownerEmail,
  sender,
  open,
  closeModal,
  updateSender,
  t,
}) => {
  const editSenderState = useEditSenderState(sender);
  const {
    alias,
    signature,
    senderUsers,
    setAlias,
    setSignature,
    setSenderUsers,
  } = editSenderState;

  const onChange = (name) => {
    const setField = {
      alias: setAlias,
      signature: setSignature,
    };
    if (name === 'senderUsers') {
      return (e, { value }) => setSenderUsers(value);
    }
    return ({ target }) => {
      const { value } = target;
      setField[name](value);
    };
  };

  const activeUsers = _.reject(users, { status: 'pending' });
  const recruiterOptions = recruitersToOptions({
    users: activeUsers,
    ownerEmail,
  });

  const onSave = async () => {
    const senderInput = {
      id: sender?.id,
      alias,
      signature,
      users: _.map(senderUsers, (user) => ({ email: user })),
    };
    await updateSender({ senderInput });
    closeModal();
  };

  const { senderAddress, mailAccount } = sender || {};
  const emailType = mailAccount?.type;

  return (
    <Modal
      className='edit-sender-modal'
      open={open}
      onClose={closeModal}
      size='small'
      closeIcon
    >
      <Header>
        <div>{t('settings.senders.editModal.header')}</div>
        {sender?.senderAddress && (
          <div className='subheader'>{sender?.senderAddress}</div>
        )}
      </Header>
      <Content>
        <div className='modal-main-description'>
          {t('settings.senders.editModal.description')}
        </div>
        <Form className='edit-sender-details'>
          <Form.Field className='input-container'>
            <div className='input-label'>
              {t('settings.senders.editModal.emailAccount')}
            </div>
            <div className='input-element'>
              <Input
                type='text'
                style={{ opacity: 0.8 }}
                disabled
                value={sender?.senderAddress}
              />
            </div>
          </Form.Field>

          <Form.Field className='input-container signature-field'>
            <div className='input-label'>
              {t('settings.senders.editModal.signature')}
            </div>
            <div className='input-element signature-content'>
              <ContentEditable
                name='signature'
                className='content-edition-field'
                html={signature || ''}
                onChange={onChange('signature')}
                placeholder={t('settings.emailingSection.signaturePlaceholder')}
              />
            </div>
          </Form.Field>

          {sender?.mailAccount?.type === 'gmail' && (
            <div className='inputs-row'>
              <Form.Field className='input-container half-width'>
                <div className='input-label'>
                  {t('settings.senders.editModal.alias')}
                </div>

                <input
                  type='text'
                  name='alias'
                  value={alias || ''}
                  onChange={onChange('alias')}
                  placeholder={t('settings.senders.editModal.aliasPlaceholder')}
                />
              </Form.Field>

              <Form.Field className='input-container half-width alias-field-preview'>
                <div className='input-label preview-label'>
                  {t('settings.senders.editModal.aliasPreview')}
                </div>
                <GmailAliasPreview alias={alias} />
              </Form.Field>
            </div>
          )}

          <div className='inputs-row'>
            <div className='input-container half-width'>
              <div className='input-label'>
                {t('settings.senders.editModal.sharedWith')}
              </div>
              <div className='input-element'>
                <Dropdown
                  multiple
                  selection
                  fluid
                  options={recruiterOptions}
                  value={senderUsers}
                  onChange={onChange('senderUsers')}
                  placeholder={t('settings.senders.editModal.usersPlaceholder')}
                />
              </div>
            </div>
            {/* Spacer */}
            <div className='input-container half-width' />
          </div>
        </Form>
      </Content>
      <Actions>
        <div className='align-left'>
          <Button className='dismiss' type='button' onClick={closeModal}>
            {t('settings.senders.editModal.cancel')}
          </Button>
        </div>
        <Button
          primary
          size='big'
          type='button'
          onClick={onSave}
          disabled={
            !validEditSenderFormData({
              alias,
              signature,
              senderAddress,
              emailType,
            })
          }
        >
          {t('settings.senders.editModal.confirm')}
        </Button>
      </Actions>
    </Modal>
  );
};

export default compose(
  withTranslation('translations'),
  withClientUsers,
  withUpdateSender,
)(EditSenderModal);
