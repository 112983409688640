import React, { FC, useState, useCallback } from 'react';
import { Modal } from 'semantic-ui-react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import GenericCheckbox from '@/components/Common/GenericCheckbox';
import ButtonLink from '@/components/Common/GenericButton/ButtonLink';
import useUnlockProfilesByAcceptingToS from '@/graphql/hooks/clients/useUnlockProfilesByAcceptingToS';
import logAction from '@/common/logAction';

import Term from './Term';

import styles from './TrialSidePanel.module.less';

const TrialSidePanel: FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [gtcChecked, setGtcChecked] = useState(false);
  const [acceptToS, { loading }] = useUnlockProfilesByAcceptingToS({
    mutationOptions: {
      refetchQueries: [
        'getSearchPoolResults',
        'getSearchEnrichedProfiles',
        'withProfileFromSearchPoolProfile',
        'getProfile',
        'getOfferProfiles',
      ],
    },
  });
  const { t } = useTranslation();

  const handleClick = useCallback(async () => {
    logAction({ type: 'trial-accept-gts' });
    await acceptToS();
    setModalOpen(false);
  }, [acceptToS]);

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.title}>{t('trial.sidePanel.title')}</div>
        <div className={styles.paragraph}>{t('trial.sidePanel.paragraph')}</div>
        <div className={styles.ctas}>
          <ButtonLink
            size='big'
            className={styles.cta}
            target='_blank'
            to={{
              pathname:
                'https://knowledge.hiresweet.com/meetings/victor-romain',
            }}
            onClick={() =>
              logAction({ type: 'click-trial-calendar-account-strategist' })
            }
          >
            {t('trial.sidePanel.bookCall')}
          </ButtonLink>
          <GenericButton
            size='big'
            primacy='secondary'
            className={styles.cta}
            onClick={() => {
              logAction({ type: 'click-trial-gts-modal' });
              setModalOpen(true);
            }}
          >
            {t('trial.sidePanel.finish')}
          </GenericButton>
          <GenericModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            className={styles.gtcModal}
          >
            <Modal.Header>{t('trial.sidePanel.modal.title')}</Modal.Header>
            <div className={styles.modalContent}>
              <div className={styles.modalParagraph}>
                {t('trial.sidePanel.modal.paragraph')}
              </div>
              <ul className={styles.terms}>
                <Term>
                  <Trans
                    components={{ b: <b /> }}
                    i18nKey={t('trial.sidePanel.modal.successFee')}
                  />
                </Term>
                <Term>{t('trial.sidePanel.modal.credit')}</Term>
                <Term>{t('trial.sidePanel.modal.chill')}</Term>
              </ul>
              <div className={styles.gtcCheckbox}>
                <GenericCheckbox
                  checked={gtcChecked}
                  onClick={() => setGtcChecked((current) => !current)}
                />
                <Trans
                  i18nKey='trial.sidePanel.modal.checkbox'
                  components={{
                    a: (
                      <Link
                        className={styles.gtcLink}
                        target='_blank'
                        to={{ pathname: t('termsOfService.url') }}
                        onClick={() =>
                          logAction({ type: 'click-trial-gts-link' })
                        }
                      />
                    ),
                  }}
                />
              </div>
            </div>
            <div className={styles.modalActions}>
              <GenericButton
                size='big'
                primacy='secondary'
                onClick={() => setModalOpen(false)}
              >
                {t('common.cancel')}
              </GenericButton>
              <GenericButton
                size='big'
                disabled={!gtcChecked || loading}
                onClick={handleClick}
              >
                {t('trial.sidePanel.modal.submit')}
              </GenericButton>
            </div>
          </GenericModal>
        </div>
      </div>
    </div>
  );
};

export default TrialSidePanel;
