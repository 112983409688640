import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import { MiniCampaign } from '@/types/campaign';

export const GET_MINI_CAMPAIGN = gql`
  query getMiniCampaign($campaignId: ID!) {
    campaign(id: $campaignId) {
      id
      title
      subject
      isArchived
    }
  }
`;

interface GetMiniCampaignData {
  campaign: MiniCampaign;
}

interface GetMiniCampaignVariables {
  campaignId: string;
}

interface UseMiniCampaignResult
  extends QueryResult<GetMiniCampaignData, GetMiniCampaignVariables> {
  miniCampaign?: MiniCampaign;
}

interface UseMiniCampaignInput extends GetMiniCampaignVariables {
  queryOptions?: QueryHookOptions<
    GetMiniCampaignData,
    GetMiniCampaignVariables
  >;
}

const useMiniCampaign = ({
  campaignId,
  queryOptions = {},
}: UseMiniCampaignInput): UseMiniCampaignResult => {
  const query = useQuery<GetMiniCampaignData, GetMiniCampaignVariables>(
    GET_MINI_CAMPAIGN,
    {
      ...queryOptions,
      variables: {
        campaignId,
      },
    },
  );

  const { campaign: miniCampaign } = query.data || {};
  return { ...query, miniCampaign };
};

export default useMiniCampaign;
