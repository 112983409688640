import _ from 'underscore';
import React, { FC } from 'react';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import { useClientCurrentAtsId } from '@/graphql/hooks/clients/useClientRevealProjects';

import { Application } from '@/common/reveal/SourceData.type';
import { SearchPoolMiniProfile } from '@/graphql/searchPoolMiniProfileById';
import { ATStype, ATS_TIMELINE_LOGOS_BY_TYPE } from '@/common/reveal';

import Tag from '@/components/Common/Tag';
import { TagProps } from '@/components/Common/Tag/Tag';
import GenericTooltip from '@/components/Common/GenericTooltip';

import styles from './ProfileAtsApplicationsRow.module.less';

interface AtsPillProps {
  atsId: ATStype;
  rejected?: boolean;
}

export const AtsPill: FC<AtsPillProps & TagProps> = ({
  atsId,
  children,
  rejected = false,
  className,
  ...rest
}) => {
  return (
    <Tag className={classNames(className, styles.pill)} {...rest}>
      <img
        className={classNames(styles.pillLogo, {
          [styles.rejectedAtsPill]: rejected,
        })}
        alt={atsId}
        src={ATS_TIMELINE_LOGOS_BY_TYPE[atsId]}
      />
      {children && <span>{children}</span>}
    </Tag>
  );
};

interface ApplicationPillProps {
  atsId: ATStype;
  application: Application;
}

const getJobAndStageFromApplication = (application: Application) => {
  const { jobs, status } = application;
  const isRejected = status?.sourceStatusId === 'rejected';
  const rejectionName = status?.rejection?.reason?.sourceRejectionReasonName;
  const jobName = jobs?.[0]?.title;
  const stageName =
    status?.rejection?.lastStage?.sourceStageName ||
    status?.stage?.sourceStageName;

  return { jobName, stageName, isRejected, rejectionName };
};

const ApplicationPill: FC<ApplicationPillProps> = ({ atsId, application }) => {
  const { t } = useTranslation();

  if (!application) {
    return null;
  }

  const {
    jobName,
    stageName,
    isRejected,
    rejectionName,
  } = getJobAndStageFromApplication(application);

  const resolvedStageName = isRejected
    ? t('reveal.missions.mission.profiles.archivedInAts', {
        rejectionName,
        stageName,
      })
    : stageName;

  const resolvedJobName = isRejected
    ? t('reveal.missions.mission.profiles.archivedInAtsWithJob', {
        rejectionName,
        stageName: stageName || '??',
        jobName,
      })
    : jobName;

  if (!jobName) {
    return (
      <AtsPill rejected={isRejected} atsId={atsId}>
        {resolvedStageName}
      </AtsPill>
    );
  }

  return (
    <GenericTooltip
      position='bottom'
      trigger={
        <div>
          <AtsPill atsId={atsId}>{resolvedStageName}</AtsPill>
        </div>
      }
    >
      {resolvedJobName}
    </GenericTooltip>
  );
};

interface MoreApplicationsProps {
  atsId: ATStype;
  applications: Application[];
}

const MoreApplications: FC<MoreApplicationsProps> = ({
  atsId,
  applications,
}) => {
  const { t } = useTranslation();

  if (!applications || !applications.length) {
    return null;
  }

  const jobNamesAndStatuses = _.map(applications, (application) => {
    const {
      jobName,
      stageName,
      isRejected,
      rejectionName,
    } = getJobAndStageFromApplication(application);

    const resolvedJobNameWithStage = stageName
      ? `${jobName} - ${stageName}`
      : jobName;

    const resolvedJobName = isRejected
      ? t('reveal.missions.mission.profiles.archivedInAtsWithJob', {
          rejectionName,
          stageName: stageName || '??',
          jobName,
        })
      : resolvedJobNameWithStage;

    return <div>{resolvedJobName}</div>;
  });

  return (
    <GenericTooltip
      position='bottom'
      trigger={
        // style is hard-coded because of the special div to trigger tooltip
        <div style={{ marginLeft: '4px' }}>
          <AtsPill atsId={atsId}>+{applications.length}</AtsPill>
        </div>
      }
    >
      {jobNamesAndStatuses}
    </GenericTooltip>
  );
};

interface ProfileAtsApplicationsRowProps {
  profile?: SearchPoolMiniProfile;
  job?: {
    linkedATSJobId: string;
  };
}

const ProfileAtsApplicationsRow: FC<ProfileAtsApplicationsRowProps> = ({
  profile,
  job,
}) => {
  const { t } = useTranslation();
  const atsId = useClientCurrentAtsId();

  if (!profile || !atsId) {
    return null;
  }

  const profileAtsData = profile.resumeData?.sourceData?.[atsId];
  if (!profileAtsData) {
    return (
      <div className={styles.container}>
        <AtsPill atsId={atsId} color='grey'>
          {t('reveal.missions.mission.profiles.notLinkedToAts')}
        </AtsPill>
      </div>
    );
  }

  const applications = profileAtsData?.applications;
  if (!applications?.length) {
    return (
      <div className={styles.container}>
        <AtsPill className={styles.notInAJobPill} rejected atsId={atsId}>
          {t('reveal.missions.mission.profiles.noAtsApplications')}
        </AtsPill>
      </div>
    );
  }

  const priorizedApplicationIndex = _.findIndex(applications, (application) => {
    return application?.jobs?.[0]?.sourceJobId === job?.linkedATSJobId;
  });

  let sortedApplications = [...applications];
  if (priorizedApplicationIndex > -1) {
    sortedApplications = _.sortBy(applications, (element, index) => {
      return index === priorizedApplicationIndex ? 0 : 1;
    });
  }

  const priorizedApplication = sortedApplications[0];
  const otherApplications = sortedApplications.slice(1);

  return (
    <div className={styles.container}>
      <ApplicationPill atsId={atsId} application={priorizedApplication} />
      <MoreApplications atsId={atsId} applications={otherApplications} />
    </div>
  );
};

export default ProfileAtsApplicationsRow;
