import React from 'react';
import { Loader } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import useClientRevealProjects from '@/graphql/hooks/clients/useClientRevealProjects';
import MainLayout from '@/containers/MainLayout';
import FreeTrialGuard from '@/components/FreeTrial/FreeTrialGuard';
import AccountStateGuard from '@/components/ClientAccountState/AccountStateGuard';

import RevealProject from './RevealProject';

import './RevealView.css';

const RevealView = () => {
  const { clientId, tabId } = useParams();
  const {
    data: clientRevealProjectsData,
    loading: revealProjectsLoading,
    startPolling: startPollingRevealProjects,
    stopPolling: stopPollingRevealProjects,
  } = useClientRevealProjects(clientId);
  const revealProjects = clientRevealProjectsData?.client?.revealProjects;
  const revealProject = (revealProjects || [])[0];

  if (revealProjectsLoading) {
    return (
      <MainLayout tabId={tabId} pageId='reveal' clientId={clientId} hideFooter>
        <div className='reveal-view loading'>
          <Loader active inline='centered' size='large' />
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout tabId={tabId} pageId='reveal' clientId={clientId} hideFooter>
      <AccountStateGuard>
        <FreeTrialGuard>
          <RevealProject
            clientId={clientId}
            revealProject={revealProject}
            startPollingRevealProjects={startPollingRevealProjects}
            stopPollingRevealProjects={stopPollingRevealProjects}
          />
        </FreeTrialGuard>
      </AccountStateGuard>
    </MainLayout>
  );
};

export default RevealView;
