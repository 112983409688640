import GenericButton from '@/components/Common/GenericButton';
import { MetaTaskFormat } from '@/revealComponents/ExplicitTasks/MetaTaskForm/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import _ from 'underscore';
import GenericModal from '@/components/Common/GenericModal';
import CustomTaskPreviewContainer from '@/containers/Parameters/CustomTasks/CustomTasksForm/CustomTaskPreviewContainer';
import { Action } from '@/types/action';
import { ClientCustomAction } from '@/types/customAction';
import styles from './CreateMetaTaskFormModal.module.less';
import CreateMetaTaskForm from './CreateMetaTaskForm';

interface CreateMetaTaskFormModalProps {
  task?: Action | ClientCustomAction;
  open: boolean;
  setOpen: (value: boolean) => void;
  metaTaskFormat?: MetaTaskFormat;
  onSave: (format: MetaTaskFormat) => void;
}

const CreateMetaTaskFormModal: React.FC<CreateMetaTaskFormModalProps> = ({
  task,
  open,
  setOpen,
  metaTaskFormat,
  onSave,
}) => {
  const { t } = useTranslation();

  const [formatState, setFormatState] = useState<MetaTaskFormat | undefined>(
    metaTaskFormat,
  );

  return (
    <GenericModal
      open={open}
      onClose={() => setOpen(false)}
      className={styles.createMetaTaskFormModal}
      closeOnDimmerClick
      size='large'
    >
      <Modal.Header>{t('reveal.modals.metaTaskModal.title')}</Modal.Header>
      <Modal.Content style={{ padding: '0px' }}>
        <div className={styles.createMetaTaskAndPreviewFormContainer}>
          <div className={styles.createMetaTaskFormContainer}>
            <CreateMetaTaskForm
              metaTaskFormat={formatState}
              onUpdate={setFormatState}
            />
          </div>
          {formatState && (
            <CustomTaskPreviewContainer
              task={{
                ...(task as ClientCustomAction),
                format: {
                  ...(task as ClientCustomAction)?.format,
                  ...formatState,
                },
              }}
            />
          )}
          {/* <div className={styles.previewContainer}>
            <h3 className={styles.previewTitle}>Preview</h3>
            {formatState && (
              <MetaTaskForm
                format={{
                  form: formatState,
                }}
                disabled
              />
            )}
          </div> */}
        </div>
      </Modal.Content>
      <Modal.Actions className={styles.modalFooter}>
        <GenericButton
          size='big'
          primacy='secondary'
          onClick={() => setOpen(false)}
        >
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          size='big'
          primacy='primary'
          onClick={() => {
            if (formatState) {
              onSave(formatState);
            }
            setOpen(false);
          }}
        >
          {t('reveal.modals.metaTaskModal.save')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default CreateMetaTaskFormModal;
