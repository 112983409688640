import React from 'react';

// TODO: insert specific link for each feature
export const FAQLink = ({ children }) => {
  return (
    <a
      href='https://hiresweet.zendesk.com/hc/en-us/sections/360003556000-HireSweet-Plugin-Chrome-Extension-'
      target='_blank'
      rel='noopener noreferrer'
    >
      {children}
    </a>
  );
};

export const chromeStoreUrl =
  'https://chrome.google.com/webstore/detail/hiresweet-extension/gncdeciaplnakdjjkcbmlbbbnlpochik';

export const ChromeStoreLink = ({ children }) => {
  return (
    <a href={chromeStoreUrl} target='_blank' rel='noopener noreferrer'>
      {children}
    </a>
  );
};
