import React, { useEffect, useState, useMemo } from 'react';
import _ from 'underscore';
import { Input, Loader } from 'semantic-ui-react';

import { SearchIcon } from '@/assets/icons';

import './DebouncedSearchBar.css';

const DebouncedSearchBar = ({
  text,
  setText,
  onBlur = null,
  autoFocus = false,
  placeholder,
}) => {
  const [localText, setLocalText] = useState(text);
  const loading = text !== localText;

  const debouncedSetText = useMemo(() => _.debounce(setText, 1000), [setText]);

  useEffect(() => {
    debouncedSetText(localText);
  }, [localText, debouncedSetText]);

  return (
    <Input
      autoFocus={autoFocus}
      className='debounced-search-bar rounded-input'
      fluid
      value={localText}
      onChange={(e, { value }) => setLocalText(value)}
      placeholder={placeholder}
      iconPosition='left'
      onBlur={onBlur}
      icon={
        <i className='icon custom-dropdown-icon'>
          {loading ? (
            <Loader active size='tiny' />
          ) : (
            <SearchIcon className='profiles-search-icon' />
          )}
        </i>
      }
    />
  );
};

export default DebouncedSearchBar;
