import _, { compose } from 'underscore';
import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Icon, Segment, Dropdown } from 'semantic-ui-react';

import ProfilePreviewLoading from '@/routes/WatchCollectAllCandidates/components/ProfilePreviewLoading';
import { UPDATE_PROFILE_PROCESS_STEP } from '@/graphql/profile';
import { REGISTER_PROFILE_VIEW } from '@/graphql/searchPoolProfiles';
import CandidatePowerHours from '@/containers/Profile/Resume/CandidatePowerHours/CandidatePowerHours';
import useClient from '@/graphql/hooks/clients/useClient';
import { getSortedSkillsWishes } from '@/components/Profile/SkillsLabels';
import PendingActions from '@/containers/Profile/Contact/PendingActions';
import ProfileContactFlow from '@/containers/Profile/Contact/ProfileContactFlow';
import TimeLineItems from '@/containers/Profile/Contact/TimeLineItems';
import Background from '@/containers/Profile/Resume/Background';
import ProfileHeader from '@/containers/Profile/Resume/Card/ProfileHeader';
import SmartSummary from '@/containers/Profile/Resume/Card/SmartSummary';
import { withLastProfileActionsContextConsumer } from '@/context/LastProfileActionsContext';
import withOffer from '@/hocs/offers/withOffer';
import withProfile, { query } from '@/hocs/profiles/withProfile';
import routerParamsToProps from '@/hocs/routerParamsToProps';
import ProfileStepButton from '@/containers/Profile/Resume/ProfileStepButton';
import TrialSidePanel from '@/routes/WatchCollectAllCandidates/TrialSidePanel';

import ProfileJobState from '../../../WatchCollectAllCandidates/components/ProfileJobState';
import { getMilestoneStageOptions } from '../../WatchCollectFollowup/FollowupKanban/components/FollowupKanbanCard';

import './ReviewProfile.css';

const ReviewProfile = (props) => {
  const {
    clientId,
    profileId,
    profile,
    profileError,
    loadingProfile,
    lastProfileActions,
    onSend,
    profileActions,
    onChangeProfileStep,
    // markSeen,
    t,
  } = props;
  const { data } = useClient(clientId);
  const [stageChangeMutation] = useMutation(UPDATE_PROFILE_PROCESS_STEP);
  const { resumeData, offer } = profile || {};

  const [updateProfileViews] = useMutation(REGISTER_PROFILE_VIEW, {
    refetchQueries: [
      {
        query,
        variables: {
          searchPoolId: 'watch',
          id: profileId,
        },
      },
    ],
  });

  useEffect(() => {
    if (profileId) {
      updateProfileViews({
        variables: {
          searchPoolId: 'watch',
          profileId,
          origin: {
            type: 'app',
            URL: window.location.href,
          },
        },
      });
    }
    // eslint-disable-next-line
  }, [profileId]);

  const currentStage = profile?.processStep?.milestone?.id || 'milestone-1';

  const handleStepChange = (_oldStep, _x, _forbiden, profileId, step) => {
    onChangeProfileStep({ profileId, value: step });
  };

  const handleStageChange = (e, { value }) => {
    stageChangeMutation({
      variables: {
        profileId,
        input: {
          milestoneId: value,
        },
      },
    });
  };

  if (loadingProfile) {
    return <ProfilePreviewLoading />;
  }

  if (profileError || !resumeData) {
    return (
      <div className='profile-preview'>
        <div className='resume-section-container'>
          <Segment className='resume-data-card-placeholder' />
        </div>
      </div>
    );
  }
  const lastProfileAction = _.last(lastProfileActions);

  return (
    <div className='profile-preview'>
      <div className='resume-section-container'>
        <Segment className='hiresweet-segment resume-data-card'>
          <div className='modal-profile-header'>
            <ProfileHeader
              profile={profile}
              profileId={profileId}
              firstname={resumeData?.firstname}
              lastname={resumeData?.lastname}
              resumeData={resumeData}
              clientId={clientId}
              watchCollectViewMode
            />
            <div className='profile-header-actions'>
              {profileActions && profileActions}
              <ProfileStepButton
                clientId={clientId}
                step={resumeData?.step}
                offerId={offer?.id}
                profileId={profileId}
                getNextProfileId={() => {}}
                getProfilesInMove={() => {}}
                onChangeProfile={() => {}}
                onChangeStep={handleStepChange}
                handleMarkProfileAsInMove={() => {}}
                handleUnmarkProfileAsInMove={() => {}}
                stepId={resumeData?.step}
                searchText=''
              />
              {resumeData?.step === 'in-process' && (
                <div className='profile-stage-selector'>
                  <Dropdown
                    className='change-profile-stage'
                    placeholder={t(
                      'watchCollect.followupView.kanban.card.selectStage',
                    )}
                    selection
                    floating
                    upward={false}
                    onChange={handleStageChange}
                    options={getMilestoneStageOptions(t)}
                    defaultValue={currentStage}
                  />
                </div>
              )}
            </div>
          </div>
          <CandidatePowerHours profile={profile} />
          <SmartSummary
            isWatchProfile
            onLogAction={() => {}}
            profileId={profileId}
            argumentation={resumeData?.smartSummary?.arguments}
            skills={getSortedSkillsWishes({
              hiresweetData: resumeData?.sourceData?.hiresweet,
            })}
            neutralMode
            talentStrategist={profile?.talentStrategist}
          />
          <Background
            clientId={clientId}
            resumeData={resumeData}
            profileId={profileId}
          />
        </Segment>
      </div>

      {data?.client.hasLimitedAccessToProfiles ? (
        <div className='trial-side-panel'>
          <TrialSidePanel />
        </div>
      ) : (
        <div className='contact-section-container'>
          <div className='contact-section'>
            <div className='profile-job-state-container'>
              <ProfileJobState
                profile={profile}
                offerTitle={profile?.offer?.title}
                t={t}
              />
            </div>
            <Segment className='segment-custom-padding'>
              {profile?.resumeData?.step === 'contacted' &&
              lastProfileAction?.profileId === profile?.id &&
              lastProfileAction.type === 'send' ? (
                <div className='success'>
                  <h3>
                    {t(
                      'watchCollect.results.profileCard.contact.emailSuccessfullySent',
                    )}
                  </h3>
                  <div className='icon-container'>
                    <Icon name='paper plane' />
                  </div>
                </div>
              ) : (
                <ProfileContactFlowWithOffer
                  contactFlow={profile?.contactFlow}
                  profile={profile}
                  profileId={profile.id}
                  clientId={clientId}
                  offer={offer} // added by withOffer HOC
                  offerId={offer?.id}
                  stepId={profile?.resumeData?.step}
                  searchText=''
                  getNextProfileId={() => {}}
                  onSend={onSend}
                  onChangeStep={() => {}}
                  onChangeProfile={() => {}}
                  handleMarkProfileAsInMove={() => {}}
                  handleUnmarkProfileAsInMove={() => {}}
                  getProfilesInMove={() => {}}
                  isSearchResultProfile
                />
              )}
            </Segment>
            <PendingActions
              profile={profile}
              contactFlow={profile?.contactFlow}
              clientId={clientId}
            />
            <TimeLineItems
              clientId={clientId}
              profileId={profileId}
              isWatchProfile
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default compose(
  withRouter,
  routerParamsToProps,
  // withMarkSeen,
  withProfile,
  withLastProfileActionsContextConsumer,
  withTranslation('translations'),
)(ReviewProfile);

// TODO: cleanup / explicit
const ProfileContactFlowWithOffer = withOffer(ProfileContactFlow);
