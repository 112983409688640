import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import { Modal } from 'semantic-ui-react';
import classNames from 'classnames';

import useIsPlugin from '@/hooks/common/useIsPlugin';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';

import './confirmationModal.css';

interface Props {
  open: boolean;
  header: string;
  content?: React.ReactNode;
  trigger?: React.ReactNode;
  onCancel: (event: React.MouseEvent) => void;
  onSubmit: (event: React.MouseEvent) => void;
  validationType?: 'positive' | 'neutral' | 'negative';
  submit?: string;
  submitDisabled?: boolean;
  cancel?: string;
  size?:
    | 'small'
    | 'mini'
    | 'tiny'
    | 'small'
    | 'large'
    | 'fullscreen'
    | undefined;
  closeIcon?: boolean;
}

const ConfirmationModal: FC<Props> = ({
  open,
  header,
  content,
  trigger,
  cancel,
  submit,
  onCancel,
  onSubmit,
  submitDisabled = false,
  validationType = 'positive',
  size = 'small',
  closeIcon,
}) => {
  const { t } = useTranslation();
  const isPlugin = useIsPlugin();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event: React.MouseEvent) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const handleSubmitPromise = new Promise((resolve, reject) => {
      try {
        resolve(onSubmit(event));
      } catch (error) {
        Sentry.captureException(error);
        reject();
      }
    });
    await handleSubmitPromise;
    setIsSubmitting(false);
  };

  const handleClose = (event: React.MouseEvent) => {
    if (!isSubmitting) {
      onCancel(event);
    }
  };

  return (
    <div>
      <GenericModal
        className={classNames('confirmation-modal', isPlugin && 'plugin-modal')}
        size={size}
        open={open}
        onClose={handleClose}
        trigger={trigger}
        closeIcon={closeIcon || false}
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>{content}</Modal.Content>
        <Modal.Actions>
          <div className='confirmation-modal-actions'>
            <GenericButton primacy='secondary' size='big' onClick={handleClose}>
              {cancel || t('common.cancel')}
            </GenericButton>
            <GenericButton
              primacy={validationType === 'neutral' ? 'secondary' : 'primary'}
              color={validationType === 'negative' ? 'red' : 'blue'}
              onClick={handleSubmit}
              size='big'
              disabled={submitDisabled || isSubmitting}
            >
              {submit || t('common.confirm')}
            </GenericButton>
          </div>
        </Modal.Actions>
      </GenericModal>
    </div>
  );
};

export default ConfirmationModal;
