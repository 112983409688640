import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { query as ClientCollaboratorsQuery } from './withClientCollaborators';

export const mutation = gql`
  mutation addUser($email: String!, $clientId: ID!) {
    addUser(email: $email, clientId: $clientId) {
      id
    }
  }
`;

export default graphql(mutation, {
  props: ({ ownProps, mutate }) => ({
    addUser: ({ email }) =>
      mutate({
        variables: {
          email,
          clientId: ownProps.clientId,
        },
        refetchQueries: [
          {
            query: ClientCollaboratorsQuery,
            variables: { clientId: ownProps.clientId },
          },
        ],
      }),
  }),
});
