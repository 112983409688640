import _ from 'underscore';
import { useMemo } from 'react';
import { getTranslatedText } from '@/common';
import { useClientMissionCustomFields } from '@/graphql/hooks/clients/useClientMissionCustomFields';
import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';

const useConditionsChainingCustomFields = ({
  clientId,
}: {
  clientId: string;
}) => {
  const { profileCustomFields } = useClientProfileCustomFields(clientId);
  const { missionCustomFields } = useClientMissionCustomFields(clientId);

  const displayedProfileCustomFields = useMemo(() => {
    return profileCustomFields.filter(
      (cf) =>
        cf.type === 'text' ||
        cf.type === 'inline-text' ||
        (cf.type === 'enum' && !cf.isMultiselect),
    );
  }, [profileCustomFields]);

  const displayedMissionCustomFields = useMemo(() => {
    return missionCustomFields.filter(
      (cf) =>
        cf.type === 'text' ||
        cf.type === 'inline-text' ||
        (cf.type === 'enum' && !cf.isMultiselect),
    );
  }, [missionCustomFields]);

  const profileCustomFieldsMap = useMemo(() => {
    const map: Record<
      string,
      { type: string; title: string; value: string }
    > = {};
    _.each(displayedProfileCustomFields, (customField) => {
      map[customField.id] = {
        type: customField.type,
        title: getTranslatedText(customField.title),
        value: customField.display || '',
      };
    });
    return map;
  }, [displayedProfileCustomFields]);

  const missionCustomFieldsMap = useMemo(() => {
    const map: Record<
      string,
      { type: string; title: string; value: string }
    > = {};
    _.each(displayedMissionCustomFields, (customField) => {
      map[customField.id] = {
        type: customField.type,
        title: getTranslatedText(customField.title),
        value: customField.display || '',
      };
    });
    return map;
  }, [displayedMissionCustomFields]);

  return {
    profileCustomFields: displayedProfileCustomFields,
    missionCustomFields: displayedMissionCustomFields,
    profileCustomFieldsMap,
    missionCustomFieldsMap,
  };
};

export default useConditionsChainingCustomFields;
