import {
  useQuery,
  QueryHookOptions,
  WatchQueryFetchPolicy,
} from '@apollo/client';
import SEARCH_POOL_JOBS, {
  SearchPoolJobQueryResult,
  SearchPoolJobQueryVariables,
  SEARCH_POOL_JOBS_WITH_CUSTOM_FIELDS,
} from '../../searchPoolJobs';

const useSearchPoolJobs = (
  searchPoolId: string,
  filters?: SearchPoolJobQueryVariables['filters'],
  fetchPolicy?: WatchQueryFetchPolicy | undefined,
  options: QueryHookOptions<any, SearchPoolJobQueryVariables> = {},
  { includeCustomFields = false }: { includeCustomFields?: boolean } = {},
) => {
  return useQuery<SearchPoolJobQueryResult, SearchPoolJobQueryVariables>(
    includeCustomFields
      ? SEARCH_POOL_JOBS_WITH_CUSTOM_FIELDS
      : SEARCH_POOL_JOBS,
    {
      variables: { searchPoolId, filters },
      fetchPolicy: fetchPolicy || 'network-only',
      ...options,
    },
  );
};

export default useSearchPoolJobs;
