import _ from 'underscore';
import { SUPPORTED_ATS_TYPES } from '.';
import { SourceData, AtsData, Attachment } from './SourceData.type';

export const getOneATSDataItem = (
  sourceData?: SourceData,
): AtsData | undefined => {
  const {
    ashby,
    smartrecruiters,
    greenhouse,
    lever,
    teamtailor,
    workable,
    recruitee,
    welcomekit,
    boondmanager,
    pinpoint,
  } = sourceData || {};
  const atsData =
    ashby ||
    smartrecruiters ||
    greenhouse ||
    lever ||
    teamtailor ||
    workable ||
    recruitee ||
    boondmanager ||
    welcomekit ||
    pinpoint;
  return atsData;
};

export const getOneATSDataItemAttachments = (
  sourceData?: SourceData,
): Attachment[] => {
  if (!sourceData) {
    return [];
  }
  const atsType = _.findKey(
    _.pick(sourceData || {}, ...SUPPORTED_ATS_TYPES),
    (atsData) => !_.isEmpty(atsData?.attachments),
  );
  if (!atsType) {
    return [];
  }
  return _.map(sourceData?.[atsType]?.attachments || [], (attachment) => ({
    from: atsType,
    ...attachment,
  }));
};

export const getATSDataItems = (sourceData?: SourceData): AtsData[] => {
  if (!sourceData) {
    return [];
  }

  return _.compact(
    _.map(SUPPORTED_ATS_TYPES, (atsType) => sourceData[atsType]),
  );
};
