export const toTriggerInput = ({ trigger }) => {
  if (!trigger) {
    return null;
  }
  const TRIGGER_KEYS_BY_TYPE = {
    'delay-after-sequence': 'triggerDelayAfterSequence',
  };
  const triggerKey = TRIGGER_KEYS_BY_TYPE[trigger.type];
  return { [triggerKey]: trigger };
};

export const createSequenceInputFromSequence = ({ sequence }) => {
  throw new Error('Not implemented yet');
  // const TRIGGER_KEYS_BY_TYPE = {
  //   'delay-after-sequence': 'triggerDelayAfterSequence',
  // };
  // const { title, position, trigger, actions } = sequence;
  // const triggerKey = TRIGGER_KEYS_BY_TYPE[trigger.type];
  // return {
  //   title,
  //   position,
  //   ...(!_.isEmpty(trigger) && { trigger }),
  //   ...(!_.isEmpty(actions) && { actions }),
  // }
};
