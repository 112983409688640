import classNames from 'classnames';
import React, { FC } from 'react';
import { Modal, ModalProps } from 'semantic-ui-react';
import useIsPlugin from '@/hooks/common/useIsPlugin';

import styles from './GenericModal.module.less';

const GenericModal: FC<ModalProps> = ({ className, children, ...props }) => {
  const isPlugin = useIsPlugin();
  return (
    <Modal
      className={classNames(
        className,
        styles.modal,
        isPlugin && 'plugin-modal',
      )}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default GenericModal;
