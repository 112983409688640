import _ from 'underscore';
import React from 'react';
import { Container, Label } from 'semantic-ui-react';

import './LinkedinExtract.css';

const LinkedInExtract = React.memo(({ hideEndorsements, skills, t }) => {
  return (
    <div className="linkedin-extract">
      <Container className="skill-tags-container" fluid>
        <h4 className="linkedin-extract-title">
          {!hideEndorsements && (<img alt='linkedin-logo' src='/images/logos/logo-linkedin.svg'/>)}
          {!hideEndorsements ? t('profile.resume.endorsements') : t('profile.resume.skills')}
        </h4>
        <Label.Group className="skill-tags">
          {_.map(skills, (skill, index) => (
            <SkillTag
              key={index}
              label={skill.label}
              endorsementsCount={hideEndorsements ? null : skill.endorsementsCount}
              relevance={skill.relevance}
            />
          ))}
        </Label.Group>
      </Container>
    </div>
  );
});

const SkillTag = ({ label, endorsementsCount, relevance }) => {
  // const opacity = (relevance + 28) / 40;
  const className = `skill-tag ${relevance > 0 ? 'important' : 'normal'}`;
  return (
    <Label
      className={className}
    >
      <span>{label.default}</span>
      {_.isNumber(endorsementsCount) && endorsementsCount > 0 && (
        <span className='endorsement-count'>
          <span>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
          <span>
            {endorsementsCount}
          </span>
        </span>
      )}
    </Label>
  );
};

export default LinkedInExtract;
