import classNames from 'classnames';
import React, { FC, MouseEvent, ReactNode, useState } from 'react';
import _ from 'underscore';

import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import {
  GenericButtonColor,
  GenericButtonPrimacy,
  GenericButtonSize,
} from '../GenericButton';
import { GenericDropdownPosition } from '../GenericDropdown';

import styles from './ButtonAndDropdown.module.less';

interface ButtonAndDropdownProps {
  title: ReactNode;
  options: {
    label: ReactNode;
    id: string;
    disabled?: boolean;
  }[];
  onClick: () => void;
  onSelect: (id: string) => void;
  primacy?: GenericButtonPrimacy;
  color?: GenericButtonColor;
  size?: GenericButtonSize;
  position?: GenericDropdownPosition;
  buttonDisabled?: boolean;
  dropdownDisabled?: boolean;
  className?: string;
}

const ButtonAndDropdown: FC<ButtonAndDropdownProps> = ({
  title,
  options,
  onClick,
  onSelect,
  primacy = 'primary',
  color = 'blue',
  size = 'normal',
  position = 'left',
  buttonDisabled,
  dropdownDisabled,
  className,
}) => {
  const [isContentDisplayed, setIsContentDisplayed] = useState(false);

  return (
    <span
      className={classNames(
        styles.container,
        styles[primacy],
        styles[color],
        styles[size],
        className,
      )}
    >
      <button
        className={styles.button}
        type='button'
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          onClick();
        }}
        disabled={buttonDisabled}
      >
        {title}
      </button>
      <span
        className={classNames(styles.separator, {
          [styles.disabled]: buttonDisabled && dropdownDisabled,
        })}
      />
      <button
        className={styles.dropdownButton}
        type='button'
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          if (!dropdownDisabled) {
            setIsContentDisplayed(!isContentDisplayed);
          }
        }}
        onBlur={() => {
          setIsContentDisplayed(false);
        }}
        disabled={dropdownDisabled}
      >
        <ArrowDown />
      </button>
      <div
        className={classNames(styles.dropdownContent, styles[position], {
          [styles.open]: isContentDisplayed,
        })}
        onMouseDown={(e: MouseEvent) => {
          e.preventDefault(); // Avoids blurring the button when clicking inside the dropdown
        }}
        role='tooltip'
      >
        <div className={styles.menu}>
          {_.map(options, ({ label, id, disabled: optionDisabled }) => (
            <button
              type='button'
              key={id}
              className={styles.option}
              onClick={(e: MouseEvent) => {
                e.stopPropagation();
                onSelect(id);
              }}
              disabled={optionDisabled}
            >
              {label}
            </button>
          ))}
        </div>
      </div>
    </span>
  );
};

export default ButtonAndDropdown;
