import gql from 'graphql-tag';
import SearchPoolProfile from './SearchPoolProfile';
import Sequence from './Sequence';
import SequenceStatistics from './SequenceStatistics';
import Task from './Task';

export default gql`
  fragment SequenceWithProfiles on Sequence {
    ...Sequence
    profiles {
      ...SearchPoolProfile
      explicitTasks {
        ...Task
      }
    }
    statistics {
      ...SequenceStatistics
    }
  }
  ${Sequence}
  ${SearchPoolProfile}
  ${SequenceStatistics}
  ${Task}
`;

export const sequenceWithMiniProfile = gql`
  fragment SequenceWithMiniProfiles on Sequence {
    ...Sequence
    profiles {
      id
      currentSequenceInfo {
        sequenceId
      }
    }
    statistics {
      ...SequenceStatistics
    }
  }
  ${Sequence}
  ${SequenceStatistics}
`;
