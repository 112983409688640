import _, { compose } from 'underscore';
import memoize from 'memoize-one';
import React from 'react';

import withOffer from '../../../hocs/offers/withOffer';
import withClient from '../../../hocs/clients/withClient';
import withActionLogger from '../../../hocs/withActionLogger';
import ProfileFuzzySearch from '../../../common/ProfileFuzzySearch';

import SearchBar from './SearchBar.component';

import ProfileSelector from './ProfileSelector.component';

import './style.css';

const LOG_SEARCH_DEBOUNCE_DURATION = 2000;

// filter only profiles belonging to the current step, and sort them with the following order:
// 1. Hiresweet Watch
// 2. Last step update
// the server is already sorting profiles by updateTimestamp and this order is kept here so it should stay this way
const getOptimizedSortedProfilesForPending = ({ profiles, stepId }) => {
  const current = Date.now();
  const preSortedProfiles = _.sortBy(
    profiles?.filter((p) => p?.resumeData?.step === stepId),
    ({ lastStepUpdateTimestamp, resumeData, source }) => {
      if (source?.type === 'career-page' || source?.createdFromDiscover) {
        return -4 * current - lastStepUpdateTimestamp;
      }
      if (resumeData?.prequalification?.type === 'hiresweet-watch') {
        if (source?.matchingScore !== undefined) {
          return -3 * current - source.matchingScore;
        }
        return -2 * current - lastStepUpdateTimestamp;
      }

      return -lastStepUpdateTimestamp;
    },
  );
  return _.sortBy(
    preSortedProfiles,
    (profile) =>
      (!profile?.annotation?.globalFavorite?.value ? 2 : 0) +
      (_.isEmpty(
        profile?.resumeData?.sourceData?.hiresweet?.clientInterestStates,
      )
        ? 1
        : 0),
  );
};

const getOptimizedSortedProfilesForOtherStep = ({ profiles, stepId }) => {
  const current = Date.now();
  const preSortedProfiles = _.sortBy(
    profiles?.filter((p) => p?.resumeData?.step === stepId),
    ({ lastStepUpdateTimestamp, resumeData, source }) => {
      if (source?.type === 'career-page') {
        return -4 * current - lastStepUpdateTimestamp;
      }
      if (resumeData?.prequalification?.type === 'hiresweet-watch') {
        return -2 * current - lastStepUpdateTimestamp;
      }
      return -lastStepUpdateTimestamp;
    },
  );
  return _.sortBy(preSortedProfiles, (profile) =>
    !profile?.annotation?.globalFavorite?.value ? 1 : 0,
  );
};

export const getOptimizedSortedProfiles = ({ profiles, stepId }) => {
  if (stepId === 'pending') {
    return getOptimizedSortedProfilesForPending({ profiles, stepId });
  }
  return getOptimizedSortedProfilesForOtherStep({ profiles, stepId });
};
class ProfileNavigation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      hovered: false,
      selectedProfile: null,
      isLoading: false,
    };
  }

  logSearch = _.debounce((searchText) => {
    if (searchText && searchText.length !== 0) {
      const { onLogAction, profileId } = this.props;
      onLogAction({
        type: 'search-profile-by-text',
        profileId,
        info: { searchText },
      });
    }
  }, LOG_SEARCH_DEBOUNCE_DURATION);

  componentWillReceiveProps(nextProps) {
    const { profileId, profiles } = nextProps;
    const { selectedProfile } = this.state;

    if (!profileId || !profiles) {
      return;
    }
    if (!selectedProfile || selectedProfile.id !== profileId) {
      const newSelected = profiles.find((p) => p.id === profileId);
      if (newSelected) {
        this.setState({ selectedProfile: newSelected });
      }
    }
  }

  // Use memoization to avoid recomputation see
  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
  getFuse = memoize((profiles) => {
    return new ProfileFuzzySearch(profiles);
  });

  computeShowedProfiles = memoize((profiles, stepId, subStepId, searchText) => {
    const hasManyProfiles = profiles && profiles.length > 300;
    if (
      (searchText && searchText.length > 0 && !hasManyProfiles) ||
      (hasManyProfiles && searchText && searchText.length > 1)
    ) {
      const fuse = this.getFuse(profiles);
      return fuse.search(searchText);
    }

    if (!stepId) {
      return [];
    }

    const sortedProfiles = getOptimizedSortedProfiles({ profiles, stepId });

    if (
      this.props.shouldSplitPending &&
      stepId === 'pending' &&
      _.contains(['pending-main', 'pending-extended'], subStepId)
    ) {
      return _.filter(
        sortedProfiles,
        (p) => p.resumeData?.subStep === subStepId,
      );
    }
    return sortedProfiles;
  });

  onSelectProfile = (profile) => {
    this.setState({ selectedProfile: profile });
    const { onSelectProfile } = this.props;
    if (onSelectProfile) {
      onSelectProfile(profile.id);
    }
  };

  enter = () => {
    this.setState({ hovered: true });
  };

  leave = () => {
    this.setState({ hovered: false });
  };

  handleSearch = (searchText) => {
    this.logSearch(searchText);
    this.setState({ searchText });
  };

  render() {
    const {
      profiles,
      stepId,
      subStepId,
      currentlyInMoveProfileIds,
      shouldSplitPending,
    } = this.props;
    const { hovered, searchText, selectedProfile, isLoading } = this.state;

    const isInMove = _.object(
      currentlyInMoveProfileIds,
      _.map(currentlyInMoveProfileIds, () => 1),
    );

    const notInMoveProfiles = _.filter(
      profiles,
      (p) => (p || {}).id && !isInMove[p.id],
    );

    const showedProfiles = this.computeShowedProfiles(
      notInMoveProfiles,
      stepId,
      subStepId,
      searchText,
    );
    return (
      <div
        onMouseEnter={this.enter}
        onMouseLeave={this.leave}
        className='sticky-profile-navigation'
      >
        <div className={`profile-navigation ${hovered ? 'open' : ''}`}>
          <div className='search-bar-row'>
            <SearchBar
              full={hovered}
              onChange={this.handleSearch}
              searchText={searchText}
            />
            {isLoading ? <div className='loader' /> : null}
          </div>
          <div className='profile-nav-scroll-container'>
            <ProfileSelector
              full={hovered || true}
              profiles={showedProfiles}
              stepId={stepId}
              searchText={searchText}
              selected={selectedProfile}
              onSelectProfile={this.onSelectProfile}
              shouldSplitPending={shouldSplitPending}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withClient,
  withOffer,
  withActionLogger,
)(ProfileNavigation);
