import _, { compose } from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import UnifiedViewContext from '@/containers/Parameters/Offers/UnifiedViewContext';
import { getSearchCriteria } from '@/containers/Parameters/OfferCriteria/helpers';
import withOfferCriteria from '@/hocs/offers/withOfferCriteria';
import { SessionSearchContextProvider } from '@/context/SessionSearchContext';
import withClient from '../hocs/clients/withClient';
import withClientOffers from '../hocs/offers/withClientOffers';
import routerParamsToProps from '../hocs/routerParamsToProps';
import OfferCriteria from '../containers/Parameters/OfferCriteria';
import OfferContactFlow from '../containers/Parameters/OfferContactFlow';
import OfferAnalytics from '../containers/Parameters/OfferAnalytics';
import JobPostings from '../containers/Parameters/OfferJobPostings';
import Header from '../containers/TopBar/Header';
import { getJobTemplatesId } from '../common/jobs';
import SubHeader from '../containers/TopBar/SubHeader';
import { WatchCollectAllCandidates } from './WatchCollectAllCandidates';

import './pagesview.css';

class OfferViewRoute extends React.PureComponent {
  static propTypes = {
    clientId: PropTypes.string.isRequired,
    pageId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      redirectUrl: null,
      cardMode: localStorage.getItem('sessionCardMode') || 'large', // ['small', 'large']
    };
  }

  redirectToStep = ({ newStepId, newSubStepId }) => {
    const { clientId, offerId } = this.props;
    const redirectUrl = `/client/${clientId}/jobs/${offerId}/profiles/step_${newSubStepId ||
      newStepId}`;
    this.setState({ redirectUrl });
  };

  setCardMode(cardMode) {
    localStorage.setItem('sessionCardMode', cardMode);
    this.setState({ cardMode });
  }

  render() {
    const {
      clientId,
      client,
      clientOffersLoading,
      pageId,
      offers,
      offerId,
      offerCriteria,
      toggleMenuVisibility,
    } = this.props;
    const { redirectUrl, cardMode } = this.state;
    // sequences is deprecated at the job level
    const pagesList = [
      'criteria',
      'sequences',
      'sequence',
      'analytics',
      'reports',
      'jobPostings',
      'explore',
    ];

    if (_.indexOf(pagesList, pageId) === -1) {
      console.error(`unknown page ${pageId}`);
      return <Redirect to={`/client/${clientId}/dashboard`} />;
    }

    const displayableOffers = _.where(offers, {
      isSuggestedForCreation: false,
    });
    if (
      offerId &&
      displayableOffers &&
      displayableOffers.length > 0 &&
      _.indexOf(_.pluck(displayableOffers, 'id'), offerId) === -1
    ) {
      return <Redirect to={`/client/${clientId}/jobs`} />;
    }

    const defaultTemplatesOnSeveralOffers = getDefaultTemplatesOnSeveralOffers({
      offers,
    });

    if (redirectUrl) {
      return <Redirect to={redirectUrl} />;
    }

    const { shouldSplitPending, unifiedView } = getClientDisplayConfig({
      client,
    });

    const searchCriteria = offerCriteria
      ? getSearchCriteria({ offerCriteria })
      : undefined;

    return (
      <SessionSearchContextProvider
        sessionSearchKey={`sessionSearch_${offerId}`}
      >
        <div className='parametersview'>
          <UnifiedViewContext.Provider value={{ unifiedView }}>
            <div className='header-placeholder' />
            <div className='stickable-header'>
              <div className='top-header'>
                <Header
                  clientId={clientId}
                  pageId='jobs'
                  toggleMenuVisibility={toggleMenuVisibility}
                  from='offer-parameters'
                />
              </div>
              <div className='navigation'>
                <SubHeader
                  clientId={clientId}
                  offerId={offerId}
                  pageId={pageId || 'criteria'}
                  stepId={null}
                  searchText={null}
                  onChangeStep={this.redirectToStep}
                  shouldSplitPending={shouldSplitPending}
                />
              </div>
            </div>

            <div
              className='parameters-pages-content with-subheader'
              id='watch-collect-content-container'
              /*
               * The infinite scrolling profile list in the explore tab
               * watches this specific ID to detect when we reach the bottom
               * of the page.
               */
            >
              {!clientOffersLoading && (
                <div className='parameters-offers'>
                  <div className='parameters-pages-page'>
                    <Switch>
                      <Route
                        exact
                        path='/client/:clientId/jobs/:offerId/criteria'
                        component={OfferCriteria}
                      />
                      <Route
                        exact
                        path='/client/:clientId/jobs/:offerId/sequences'
                      >
                        <Redirect
                          to={`/client/${clientId}/jobs/${offerId}/sequence`}
                          replace
                        />
                      </Route>
                      <Route
                        exact
                        path='/client/:clientId/jobs/:offerId/sequence'
                      >
                        <OfferContactFlow
                          clientId={clientId}
                          offer={_.findWhere(offers, { id: offerId })}
                          offerId={offerId}
                          defaultTemplatesOnSeveralOffers={
                            defaultTemplatesOnSeveralOffers
                          }
                        />
                      </Route>
                      <Route
                        exact
                        path='/client/:clientId/jobs/:offerId/reports'
                      >
                        <OfferAnalytics clientId={clientId} offerId={offerId} />
                      </Route>
                      {client?.permissions?.careerPage && (
                        <Route
                          exact
                          path='/client/:clientId/jobs/:offerId/jobPostings'
                        >
                          <JobPostings clientId={clientId} offerId={offerId} />
                        </Route>
                      )}
                      <Route
                        exact
                        path='/client/:clientId/jobs/:offerId/explore'
                      >
                        {searchCriteria ? (
                          <WatchCollectAllCandidates
                            searchPoolId='watch'
                            clientId={clientId}
                            cardMode={cardMode}
                            setCardMode={(newCardMode) =>
                              this.setCardMode(newCardMode)
                            }
                            offerCriteria={searchCriteria}
                          />
                        ) : (
                          <Loader inline='centered' />
                        )}
                      </Route>
                    </Switch>
                  </div>
                </div>
              )}
            </div>
          </UnifiedViewContext.Provider>
        </div>
      </SessionSearchContextProvider>
    );
  }
}

const getClientDisplayConfig = ({ client }) => {
  return {
    shouldSplitPending: client?.permissions?.marketplaceSplitPending,
    unifiedView: client?.permissions?.marketplaceUnifiedView,
  };
};

const getDefaultTemplatesOnSeveralOffers = ({ offers }) => {
  const templatesId = _.flatten(_.map(offers, (o) => getJobTemplatesId(o)));
  const templatesIdCount = _.countBy(templatesId, (t) => t);
  return _.compact(
    _.map(templatesIdCount, (num, key) => (num > 1 ? key : null)),
  );
};

export default compose(
  routerParamsToProps,
  withClient,
  withClientOffers,
  withOfferCriteria,
)(OfferViewRoute);
