import React from 'react';
import { CustomIcon } from '@/components/Common/GenericIconSelector/IconDropdown/IconDropdown';
import { useClientTaskOptions } from '../FullContactFlowEditor/FullContactFlowActionEditor/helpers';

interface TaskIconProps {
  action: {
    type: string;
  };
}

const TaskIcon: React.FC<TaskIconProps> = ({ action }) => {
  const { selectedOption } = useClientTaskOptions({
    currentAction: action,
  });

  return <CustomIcon icon={selectedOption?.optionIcon} />;
};

export default TaskIcon;
