import { useQuery, QueryHookOptions } from '@apollo/client';
import gql from 'graphql-tag';

const getMiniProfile = gql`
  query getMiniProfile($searchPoolId: ID!, $profileId: ID!) {
    searchPool(id: $searchPoolId) {
      id
      profile(id: $profileId) {
        id
        name
        resumeData {
          firstname
          lastname
        }
      }
    }
  }
`;

type Data = {
  searchPool: {
    id: string;
    profile?: {
      id: string;
      name?: string;
      resumeData: {
        firstname?: string;
        lastname?: string;
      };
    };
  };
};

type Variables = {
  searchPoolId: string;
  profileId: string;
};

type Input = {
  profileId: string;
  searchPoolId?: string;
  queryOptions?: QueryHookOptions<Data, Variables>;
};

function useMiniProfile({
  profileId,
  searchPoolId = 'reveal',
  queryOptions = {},
}: Input) {
  const query = useQuery<Data, Variables>(getMiniProfile, {
    ...queryOptions,
    variables: {
      searchPoolId,
      profileId,
    },
  });
  const miniProfile = query.data?.searchPool.profile;
  return { ...query, miniProfile };
}

export default useMiniProfile;
