export const subtypes = ['comment', 'call', 'meeting'] as const;
export type Subtype = typeof subtypes[number];

function isSubtype(subtype: unknown): subtype is Subtype {
  return subtypes.includes(subtype as Subtype);
}

export function coerceSubtype(subtype: unknown): Subtype {
  if (isSubtype(subtype)) {
    return subtype;
  }
  return 'comment';
}
