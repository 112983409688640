import classNames from 'classnames';
import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import { removeEmptyKeyValues } from '@/common';
import GenericButton from '@/components/Common/GenericButton';
import Cross from '@/components/Common/Icons/Cross';
import OmegaCriteriaFilters from './OmegaCriteriaFilters';
import SearchModal from './SearchModal';
import { getModifiableParams } from '../utils';

import './SearchPane.css';

const RevealSearchPane = ({
  clientId,
  projectId,
  searchParams,
  onUpdateSearchParams,
  searchModalOpen,
  setSearchModalOpen,
}) => {
  const { t } = useTranslation();

  const handleUpdateCriteria = ({ criteria }) => {
    onUpdateSearchParams({
      newSearchParams: {
        ...searchParams,
        ...(criteria && { criteria }),
      },
    });
  };

  const onClickReset = () => {
    onUpdateSearchParams({ newSearchParams: {} });
  };

  const cleanParams = removeEmptyKeyValues(getModifiableParams(searchParams));

  return (
    <div className='reveal-search-pane'>
      <GenericButton
        primacy='tertiary'
        className={classNames(
          'reset-all-button',
          _.isEmpty(cleanParams) && 'hidden',
        )}
        onClick={onClickReset}
      >
        <span>{t('reveal.searchView.header.filters.clear')}</span>
        <Cross />
      </GenericButton>
      <OmegaCriteriaFilters
        clientId={clientId}
        criteria={searchParams?.criteria}
        onUpdateCriteria={handleUpdateCriteria}
        onOpenSearchModal={() => setSearchModalOpen(true)}
        projectId={projectId}
        searchParams={searchParams}
        onUpdateSearchParams={onUpdateSearchParams}
      />
      {searchModalOpen && (
        <SearchModal
          open={searchModalOpen}
          onClose={() => setSearchModalOpen(false)}
          clientId={clientId}
          projectId={projectId}
          searchParams={searchParams}
          onUpdateSearchParams={onUpdateSearchParams}
        />
      )}
    </div>
  );
};

export default RevealSearchPane;
