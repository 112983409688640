import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import styles from './Callout.module.less';

type CalloutProps = PropsWithChildren<{
  primary?: boolean;
  borderless?: boolean;
  title?: string | null;
}>;

export function CalloutInfoLine({
  icon,
  title,
  children,
  className,
}: PropsWithChildren<{
  icon: string | JSX.Element;
  title: string;
  className?: string;
}>) {
  return (
    <div className={classNames(className, styles.calloutInfoLine)}>
      <span className={styles.calloutInfoLineIcon}>{icon}</span>
      <span className={styles.calloutInfoLineTitle}>{title}</span>
      <div className={styles.calloutInfoLineInfo}>{children}</div>
    </div>
  );
}

export function Callout({
  primary = false,
  borderless = false,
  title = null,
  children,
}: CalloutProps) {
  return (
    <div
      className={classNames(styles.callout, {
        [styles.borderless]: borderless,
        [styles.primary]: primary,
      })}
    >
      {title && <h4 className={styles.title}>{title}</h4>}
      {children}
    </div>
  );
}
