import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { Container } from 'semantic-ui-react';

import { getRandomString } from '@/common';
import { isEmailAction, TASK_TYPES } from '@/common/constants/taskTypes';
import useSequences from '@/hooks/common/useSequences';
import SequenceLayout from '../SequenceLayout';
import FullContactFlowEditor from '../../../../routes/RevealView/revealComponents/FullContactFlowEditor';
import validateContactFlow from '../validateContactFlow';
import './SequencePage.css';

const DEFAULT_SEQUENCES = [
  {
    id: `contactflow-sequence-${getRandomString(6)}`,
    position: 'first-contact',
    actions: [
      {
        actionId: `contact-flow-action-${getRandomString(6)}`,
        type: TASK_TYPES.SEND_EMAIL_MANUAL,
        trigger: { type: 'manual-trigger' },
      },
    ],
  },
];

const CreateSequenceStandalonePage = () => {
  const { t } = useTranslation();

  const showAIGenerationPanel = true;

  const [sequences, setSequences] = useState(DEFAULT_SEQUENCES);

  const contactFlow = { sequences };

  // FORM ERRORS
  const [errorLevel, setErrorLevel] = useState('major');
  const formErrors = validateContactFlow({ contactFlow });
  const displayedErrors =
    errorLevel === 'major'
      ? _.filter(formErrors, ({ level }) => level === 'major')
      : formErrors;

  const {
    handleInsertAction,
    handleUpdateAction,
    handleRemoveAction,
    handleMoveUpwardAction,
    handleMoveDownwardAction,
    updateAfterTemplateCreation,
  } = useSequences({
    setSequences: (sequencesMutation) => {
      setSequences(sequencesMutation);
    },
    formErrors,
    setErrorLevel,
  });

  // SUBMIT
  const onSubmit = async () => {};

  const applyCcBccToNextMails = ({ cc, bcc, index }) => {
    const sequence = _.first(contactFlow.sequences);
    if (!sequence) {
      return;
    }
    const newActions = _.map(sequence.actions || [], (action, actionIndex) => {
      if (!isEmailAction(action) || actionIndex <= index) {
        return action;
      }
      return {
        ...action,
        message: {
          ...action.message,
          ...{
            cc,
            bcc,
          },
        },
      };
    });
    const newSequence = [
      {
        ...sequence,
        actions: newActions,
      },
    ];
    setSequences(newSequence);
  };

  const updateGeneratedActions = (newActions) => {
    const sequence = _.first(contactFlow.sequences);
    if (!sequence) {
      return;
    }
    const newSequence = [
      {
        ...sequence,
        actions: newActions,
      },
    ];
    setSequences(newSequence);
  };

  return (
    <SequenceLayout
      withAiGenerationPanel={showAIGenerationPanel}
      updateGeneratedActions={updateGeneratedActions}
      hasSidePanel
      onLoading={() => {
        /* Do nothing */
      }}
      isStandalone
      pollInterval={500}
    >
      <Container className='new-sequence-edit'>
        <FullContactFlowEditor
          clientId='ai-seq-demo'
          contactFlow={contactFlow}
          t={t}
          onInsertAction={handleInsertAction}
          onUpdateAction={handleUpdateAction}
          onRemoveAction={handleRemoveAction}
          onMoveUpward={handleMoveUpwardAction}
          onMoveDownward={handleMoveDownwardAction}
          onQuitContactFlowEditor={onSubmit}
          updateAfterTemplateCreation={updateAfterTemplateCreation}
          formErrors={displayedErrors}
          applyCcBccToNextMails={applyCcBccToNextMails}
        />
      </Container>
    </SequenceLayout>
  );
};

export default CreateSequenceStandalonePage;
