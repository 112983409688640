import React from 'react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';
import ProfileDimmer from '@/components/Dimmer/ProfileDimmer';
import { CandidateViewProvider } from '@/context/CandidateView/useCandidateViewContext';
import ProfileIdHandler from '@/common/ProfileIdHandler';

import CandidateView from '../SearchView/CandidatesListPane/CandidateView';

import styles from './EnrichedRevealProfileModal.module.less';

type EnrichedRevealProfileModalProps = {
  clientId: string;
  searches: unknown;
  open: boolean;
  onClose: () => void;
  profileIdHandler: ReturnType<typeof ProfileIdHandler>;
  needNetworkCall?: boolean;
  onChangeStage?: (data: any) => void;
  profileId?: string;
};

const EnrichedRevealProfileModal = ({
  clientId,
  searches,
  open,
  onClose,
  profileIdHandler,
  needNetworkCall,
  onChangeStage,
  profileId,
}: EnrichedRevealProfileModalProps): JSX.Element | null => {
  if (!open) {
    return null;
  }

  return createPortal(
    <div
      className={classNames(
        'enriched',
        'reveal-profile-modal',
        'profile-preview-container',
        styles.modal,
      )}
    >
      <ProfileDimmer
        previousProfileId={profileIdHandler.prev}
        nextProfileId={profileIdHandler.next}
        onSelectProfileId={profileIdHandler.onSelectProfileId}
        onClose={onClose}
      />
      <CandidateViewProvider
        clientId={clientId}
        profileId={profileId || profileIdHandler.current}
        nextProfileId={profileIdHandler.next}
        previousProfileId={profileIdHandler.prev}
        searches={searches}
        needNetworkCall={needNetworkCall || false}
      >
        <CandidateView
          searchPoolId='reveal'
          clientId={clientId}
          onClose={onClose}
          withSimilarProfiles
          onChangeStage={onChangeStage}
        />
      </CandidateViewProvider>
    </div>,
    document.body,
  );
};

export default EnrichedRevealProfileModal;
