import React, { FC } from 'react';

import styles from './PieChartSection.module.less';

interface PieChartSectionProps {
  color: string;
  value: number;
  offset: number;
}

const PieChartSection: FC<PieChartSectionProps> = ({
  color,
  value,
  offset,
}) => {
  const start = `${offset}turn`;
  const end = `${offset + value}turn`;

  return (
    <>
      <div
        className={styles.separator}
        style={{
          transform: `rotate(${start}) translateY(-51%)`,
        }}
      />
      <div
        className={styles.pieChartSection}
        style={{
          background: `conic-gradient(transparent 0 ${start}, ${color} ${start} ${end}, transparent ${end} 1turn)`,
        }}
      />
    </>
  );
};

export default PieChartSection;
