import classNames from 'classnames';
import _ from 'underscore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Segment } from 'semantic-ui-react';

import { Link } from 'react-router-dom';
import LoadingComponent from '@/components/LoadingComponent';
import SequenceActionsIcons from '@/revealComponents/SequencePreview/SequenceActionsIcons';
import GenericButton from '@/components/Common/GenericButton';
import SequencePreview from '../../../../routes/RevealView/revealComponents/SequencePreview';
import { useClientSequenceTemplates } from '../queries';

import SequenceLayout from '../SequenceLayout';
import SequencesSubHeader from '../SequencesSubHeader/SequencesSubHeader';

import './NewSequencePreview.css';

const NewSequencePreview = ({ clientId }) => {
  const { t } = useTranslation();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const { sequenceTemplates, loading } = useClientSequenceTemplates({
    clientId,
    fetchPolicy: 'network-only',
  });
  const { defaultSequenceTemplates } = sequenceTemplates || {};

  useEffect(() => {
    if (!loading) {
      setSelectedTemplate(defaultSequenceTemplates[0]);
    }
  }, [loading, defaultSequenceTemplates]);

  if (loading || !selectedTemplate) {
    return <></>;
  }

  return (
    <SequenceLayout
      subHeader={
        <SequencesSubHeader
          breadcrumbs={[
            {
              text: t('sequences.breadcrumbs.sequences'),
              link: `/client/${clientId}/reveal/sequences`,
            },
            {
              text: t('sequences.breadcrumbs.newSequence'),
              link: `/client/${clientId}/reveal/sequences/new-sequence-preview`,
            },
          ]}
        >
          <Link
            to={`/client/${clientId}/reveal/sequences/new-sequence-create?sequenceTemplateId=${selectedTemplate.id}`}
          >
            <GenericButton size='big'>
              {t('sequences.buttons.startFromTemplate')}
            </GenericButton>
          </Link>
          <span className='span-delimiter'> {t('common.or')} </span>
          <Link to={`/client/${clientId}/reveal/sequences/new-sequence-create`}>
            <GenericButton size='big'>
              {t('sequences.buttons.startFromScratch')}
            </GenericButton>
          </Link>
        </SequencesSubHeader>
      }
    >
      <div className='new-sequence-preview'>
        <div className='sequence-template-selector'>
          <h4 className='templates-list-section-title'>
            {t('sequences.preview.choose')}
          </h4>
          <div className='sequence-templates-list'>
            {loading ? (
              <LoadingComponent />
            ) : (
              _.map(defaultSequenceTemplates, (sequenceTemplate) => (
                <Segment
                  key={sequenceTemplate.id}
                  onClick={() => setSelectedTemplate(sequenceTemplate)}
                  className={classNames(
                    'sequence-template',
                    selectedTemplate?.id === sequenceTemplate.id &&
                    'selected-sequence-template',
                  )}
                >
                  <h4 className='sequence-template-title'>
                    {sequenceTemplate.title}
                  </h4>
                  <p className='sequence-template-description'>
                    {sequenceTemplate.description}
                  </p>
                  <SequenceActionsIcons sequence={sequenceTemplate} />
                </Segment>
              ))
            )}
          </div>
        </div>
        <div className='new-sequence-preview-display'>
          <div className='preview-display-header'>
            <h2 className='header-2'>
              {t('sequences.preview.header')} {selectedTemplate.title}
            </h2>
            <p>{t('sequences.preview.subHeader')}.</p>
          </div>
          <SequencePreview
            clientId={clientId}
            sequence={selectedTemplate}
            alwaysExpanded
          />
        </div>
      </div>
    </SequenceLayout>
  );
};

export default NewSequencePreview;
