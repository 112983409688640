/* eslint-disable max-len */

import React from 'react';

const EnrolledInCampaignIcon: React.FC = () => (
  <svg
    width='17'
    height='17'
    viewBox='0 0 17 17'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_2_10)'>
      <path
        d='M2.18225 3.82133L12.9517 9.7445C12.9975 9.76968 13.0356 9.80667 13.0622 9.85161C13.0887 9.89655 13.1027 9.9478 13.1027 10C13.1027 10.0522 13.0887 10.1035 13.0622 10.1484C13.0356 10.1933 12.9975 10.2303 12.9517 10.2555L2.18225 16.1787C2.13784 16.2031 2.08784 16.2155 2.03716 16.2147C1.98648 16.214 1.93688 16.2 1.89325 16.1742C1.84962 16.1484 1.81346 16.1117 1.78834 16.0677C1.76322 16.0237 1.75001 15.9739 1.75 15.9232V4.07683C1.75001 4.02615 1.76322 3.97634 1.78834 3.93232C1.81346 3.8883 1.84962 3.85159 1.89325 3.8258C1.93688 3.80001 1.98648 3.78604 2.03716 3.78525C2.08784 3.78447 2.13784 3.7969 2.18225 3.82133V3.82133ZM2.91667 10.5833V14.4433L10.9958 10L2.91667 5.55675V9.41667H5.83333V10.5833H2.91667Z'
        fill='currentColor'
      />
    </g>
    <rect x='13.0333' width='0.933333' height='7' fill='currentColor' />
    <rect
      x='16.9999'
      y='2.97345'
      width='0.932189'
      height='6.99994'
      transform='rotate(89.998 16.9999 2.97345)'
      fill='currentColor'
    />
    <defs>
      <clipPath id='clip0_2_10'>
        <rect
          width='14'
          height='14'
          fill='currentColor'
          transform='translate(0 3)'
        />
      </clipPath>
    </defs>
  </svg>
);

export default EnrolledInCampaignIcon;
