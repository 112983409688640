import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Segment, Dropdown, Checkbox } from 'semantic-ui-react';

import './JobsSelector.css';

// TODO: see ItemsSelector for more generic Component
const JobsSelector = ({ jobsWithCount, setShowDisabled, setJobId, jobId }) => {
  const { t } = useTranslation();
  const sortedJobs = _.sortBy(
    jobsWithCount,
    ({ isUnclassified, profilesCount }) => {
      let score = 0;
      if (isUnclassified) {
        score -= 1;
      }
      if (profilesCount === 0) {
        score += 10;
      }
      return score;
    },
  );

  const sortedJobsWithoutUnclassified = _.filter(
    sortedJobs,
    (job) => !job?.isUnclassified,
  );

  const jobOptions = [
    {
      key: 'all-jobs',
      text: t('watchCollect.followupView.jobsSelector.allJobs'),
      value: 'all-jobs',
    },
    ..._.map(sortedJobsWithoutUnclassified, (job) => {
      return {
        key: job?.id,
        text: job?.title,
        value: job?.id,
      };
    }),
  ];

  const handleJobChange = (e, { value }) => {
    if (value === 'all-jobs') {
      setJobId(undefined);
    } else {
      setJobId(value);
    }
  };

  const handleActiveProfileCheckboxChange = (e, { checked }) => {
    setShowDisabled(checked);
  };

  return (
    <Segment className='jobs-selector'>
      <h2 className='title'>{t('header.parameters.offersFollowup')}</h2>
      <div className='jobs-list'>
        <Dropdown
          value={jobId || 'all-jobs'}
          fluid
          selection
          floating
          onChange={handleJobChange}
          options={jobOptions || []}
        />
        <Checkbox
          label={t(
            'watchCollect.followupView.jobsSelector.activeProfileCheckbox',
          )}
          onChange={handleActiveProfileCheckboxChange}
        />
      </div>
    </Segment>
  );
};

export default JobsSelector;
