// import _ from 'underscore';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
// import * as Sentry from '@sentry/browser';

// import { query as NotificationsQuery } from '../clients/withNotifications';

export const mutation = gql`
  mutation markProfileAsJustSeen($id: ID!) {
    markProfileAsSeen(id: $id) {
      id
      lastSeenTimestamp
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    markProfileAsJustSeen({ profileId, clientId }) {
      mutate({
        variables: { id: profileId },
        // update the local cache to remove the notification if needed
        update: (store, { data }) => {
          /* Deprecated
          let previousQuery;
          try {
            // read the cache to get the notifs object
            previousQuery = store.readQuery({ query: NotificationsQuery, variables: { clientId } });
          } catch (error) {
            // do nothing here, this might throw because the query has not returned yet.
          }
          try {
            // find if there is an unseen notification for this profile
            const previousNotSeenNotifications =
              (((previousQuery || {}).client || {}).notifications || {}).notSeen || [];
            const previousSeenNotifications = (((previousQuery || {}).client || {}).notifications || {}).seen || [];
            const matchingProfileNotifications = _.filter(
              previousNotSeenNotifications,
              (notif) => ((notif || {}).profile || {}).id === profileId,
            );

            // if there is one unseen, pass it to seen
            if (!_.isEmpty(matchingProfileNotifications)) {
              const notifications = ((previousQuery || {}).client || {}).notifications;
              const newSeenNotifications = [...matchingProfileNotifications, ...previousSeenNotifications];
              const newNotSeenNotifications =
                _.filter(previousNotSeenNotifications, (notif) => ((notif || {}).profile || {}).id !== profileId) || [];
              const newQuery = {
                ...previousQuery,
                client: {
                  ...(previousQuery || {}).client,
                  notifications: {
                    ...notifications,
                    countSeen: newSeenNotifications.length,
                    countNotSeen: newNotSeenNotifications.length,
                    seen: newSeenNotifications,
                    notSeen: newNotSeenNotifications,
                  },
                },
              };
              store.writeQuery({ query: NotificationsQuery, variables: { clientId }, data: newQuery });
            }
          } catch (error) {
            Sentry.captureException(error);
          }
          */
        },
      });
    },
  }),
});
