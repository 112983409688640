import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { ApplicationFormTemplate } from '../../graphql/fragments/Applications';

export const moveApplicationFormTemplateDownward = gql`
  mutation moveApplicationFormTemplateDownward(
    $applicationFormTemplateId: ID!
  ) {
    moveApplicationFormTemplateDownward(
      applicationFormTemplateId: $applicationFormTemplateId
    ) {
      id
      applicationFormTemplates {
        ...ApplicationFormTemplate
      }
    }
  }
  ${ApplicationFormTemplate}
`;

export default graphql(moveApplicationFormTemplateDownward, {
  props: ({ mutate }) => ({
    moveApplicationFormTemplateDownward: ({ applicationFormTemplateId }) =>
      mutate({
        variables: {
          applicationFormTemplateId,
        },
      }),
  }),
});
