import React, { FC, useState } from 'react';
import { Clock, CrossIcon } from '@/assets/icons';
import TimeSelectorModal from '../TimeSelectorModal';
import { TimeSelectorModalProps } from '../TimeSelectorModal/TimeSelectorModal';
import GenericButton from '../GenericButton';

import { Timezone } from '../TimezoneSelect/timezones';

import styles from './InlineTimeSelector.module.less';

interface InlineTimeSelectorProps {
  time: string | null;
  timezone: Timezone | null;
  onClear: () => void;
}

const InlineTimeSelector: FC<InlineTimeSelectorProps &
  TimeSelectorModalProps> = ({
  time,
  timezone,
  onClear,
  onChange,
  ...modalProps
}) => {
  const [editing, setEditing] = useState(false);
  return (
    <>
      <GenericButton
        primacy='secondary'
        size='small'
        onClick={() => setEditing(true)}
        className={styles.timeSelector}
      >
        <Clock />
        {time && (
          <span className={styles.currentTime}>
            {time}

            <button
              type='button'
              className={styles.clearTime}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (onClear) {
                  onClear();
                }
              }}
            >
              <CrossIcon />
            </button>
          </span>
        )}
      </GenericButton>

      {editing && (
        <TimeSelectorModal
          open={editing}
          defaultTime={time}
          defaultTimezone={timezone}
          onClose={() => setEditing(false)}
          onChange={(...args) => {
            setEditing(false);
            onChange(...args);
          }}
          {...modalProps}
        />
      )}
    </>
  );
};

export default InlineTimeSelector;
