import React from 'react';
import { useTranslation } from 'react-i18next';
import usePluginStatuses from '@/hooks/common/usePluginStatuses';
import { chromeStoreUrl } from '@/components/Links';
import ButtonLink from '@/components/Common/GenericButton/ButtonLink';
import Check from '@/components/Common/Icons/Check';

const Step2: React.FC = () => {
  const { t } = useTranslation();
  const { pluginDownloaded } = usePluginStatuses();

  return (
    <div className='step2'>
      <h1 className='step-title'>{t('reveal.onboardingModal.step2.title')}</h1>
      <p className='step-paragraph'>
        {t('reveal.onboardingModal.step2.extensionText')}
      </p>
      {pluginDownloaded ? (
        <div className='step2-button-container'>
          <ButtonLink size='big' to={{ pathname: chromeStoreUrl }} disabled>
            <Check />
            {t('reveal.onboardingModal.step2.installed')}
          </ButtonLink>
          <span className='step2-synchronized'>
            {t('reveal.onboardingModal.step2.synchronized')}
          </span>
        </div>
      ) : (
        <ButtonLink
          size='big'
          to={{ pathname: chromeStoreUrl }}
          target='_blank'
        >
          {t('reveal.onboardingModal.step2.install')}
        </ButtonLink>
      )}
    </div>
  );
};

export default Step2;
