import React, { FC } from 'react';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import useIsPlugin from '@/hooks/common/useIsPlugin';

import { UserEnrichmentIntegration } from '@/graphql/hooks/users/useUserEnrichmentIntegrations';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import styles from './enrichmentProvider.module.less';

export function getMinCreditsRemaining(integration: UserEnrichmentIntegration) {
  // If user has no quota we set it as if he had an inifnity of credits
  const { totalCreditsCount = Infinity, usedCreditsCount = 0 } =
    integration.quota?.currentPeriod || {};

  const userRemainingCredits = Math.max(
    totalCreditsCount - usedCreditsCount,
    0,
  );

  if (!integration.clientIntegration || !integration.clientIntegration.quota) {
    return {
      remainingCredits: userRemainingCredits,
      totalCredits: totalCreditsCount,
      usedCredits: usedCreditsCount,
    };
  }

  const {
    totalCreditsCount: clientTotalCredits,
    usedCreditsCount: clientUsedCredits,
  } = integration.clientIntegration.quota.currentPeriod;

  const clientRemainingCredits = Math.max(
    clientTotalCredits - clientUsedCredits,
    0,
  );

  return {
    remainingCredits: Math.min(userRemainingCredits, clientRemainingCredits),
    totalCredits: Math.min(totalCreditsCount, clientTotalCredits),
    usedCredits: Math.max(usedCreditsCount, clientUsedCredits),
  };
}

interface EnrichmentQuotaProps {
  integration: UserEnrichmentIntegration;
  isOwn?: boolean;
}

const EnrichmentQuota: FC<EnrichmentQuotaProps> = ({ integration, isOwn }) => {
  const { t } = useTranslation();
  const isPlugin = useIsPlugin();
  const { isMinimized } = useMinimizedView();

  if (!integration || integration.status !== 'active') {
    return null;
  }

  if (isOwn) {
    return (
      <span
        className={classNames(styles.quota, {
          [styles.quotaPlugin]: isPlugin,
        })}
      >
        {t('reveal.contactout.own')}
      </span>
    );
  }

  const { remainingCredits, totalCredits, usedCredits } =
    getMinCreditsRemaining(integration);

  if (remainingCredits === null) {
    return null;
  }

  return (
    <span
      className={classNames(styles.quota, {
        [styles.quotaPlugin]: isPlugin,
      })}
    >
      {t(`reveal.contactout.quota${isMinimized ? 'Minimized' : ''}`, {
        count: remainingCredits,
        maxCredits: totalCredits,
        usedCredits,
      })}
    </span>
  );
};

export default EnrichmentQuota;
