import classNames from 'classnames';
import React from 'react';
import _, { compose } from 'underscore';
import { Image } from 'semantic-ui-react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';

import ActionButton from '@/components/ActionButton';
import ProfileViews from '@/components/Profile/ProfileViews';
import { MarketplaceUnbiasedModeField } from '@/components/UnbiasedMode/UnbiasedModeField';
import { getTaskType } from '@/common/constants/taskTypes';
import { CANDIDATE_INTEREST_ITEM_TYPE } from '@/common/reveal/SourceData.type';
import WatchNewTag from '@/routes/WatchCollectViewV2/WatchCollectRecommendations/JobProfileRecommendations/WatchNewTag';
import Interest from '@/components/Common/Icons/Interest';
import useClientId from '@/hooks/router/useClientId';
import useClient from '@/graphql/hooks/clients/useClient';
import { BlurLine } from '@/components/UnbiasedMode/BlurLine';
import withClient from '@/hocs/clients/withClient';

import ProfilePowerHourFlag from '../PowerHourState';
import {
  getSecureLink,
  getTranslatedText,
  getRandomDefaultAvatarLink,
  removeEmptyKeyValues,
} from '../../../../common';
import { isFemaleName } from '../../../../common/firstnames';
import logAction from '../../../../common/logAction';
import withProfileCurrentTask from '../../../../hocs/searchPoolProfile/withProfileCurrentTask';
import withActionLogger from '../../../../hocs/withActionLogger';
import withUserFromJWToken from '../../../../hocs/users/withUserFromJWToken';
import withMarkProfileAsShared from '../../../../hocs/profiles/withMarkProfileAsShared';
import LoadingComponent from '../../../../components/LoadingComponent';
import SaveProfileInfoToClipboard from '../../../hotkeys/SaveProfileInfoToClipboard';
import ProfileSources from '../../ProfileSources';
import UploadFileModal from '../../../../components/modals/UploadFileModal';
import ProfileGlobalComment from './ProfileGlobalComment';

import {
  ATTACHMENT_TYPE_COVER_LETTER,
  ATTACHMENT_TYPE_OTHER,
  ATTACHMENT_TYPE_RESUME,
} from '../../../../common/constants/attachments';
import ProfileFavoriteButton from './ProfileFavoriteButton';
import DownloadResumeButton from './RevealProfileHeader/DownloadResumeButton';
import ProfilePowerHourIcon from './ProfilePowerHourIcon';
import CuratedAutoSendButton from './CuratedAutoSendButton';

import styles from './ProfileHeader.module.less';

const replacePhotoLink = (link) =>
  (link || '').replace('shrinknp_400_400', 'shrinknp_75_75');

const sweetDateToMomentDate = (date) => {
  const { year, month } = date || {};
  const currentYear = new Date().getFullYear();
  // const currentMonth = (new Date()).getMonth();
  const defaultEducationMonth = 6;
  if (!year || currentYear <= year) {
    return null;
  }
  return moment([year, _.isNumber(month) ? month : defaultEducationMonth]);
};

const getLocationCanBeDisplayed = (locationName) => {
  if (/\d/.test(locationName)) {
    // No digits in locationName
    return false;
  }
  return true;
};

const setPluginTaskState = ({ task }) => {
  let message;
  if (task) {
    message = {
      type: 'set-task-state',
      data: { task, activeTaskListId: getTaskType(task) },
    };
  } else {
    message = {
      type: 'clear-task-state',
    };
  }
  window.postMessage(message, '*');
};

const getBeautifiedPhoneNumber = ({ phoneNumber }) => {
  if (!phoneNumber) {
    return phoneNumber;
  }
  if (phoneNumber.indexOf('.') >= 0) {
    return phoneNumber;
  }
  const withoutSpace = phoneNumber.replace(/\s/g, '');

  if (withoutSpace.indexOf('+336') === 0) {
    return `+336 ${getBeautifiedPhoneNumber({
      phoneNumber: withoutSpace.slice(4),
    })}`;
  }
  if (withoutSpace.indexOf('+337') === 0) {
    return `+337 ${getBeautifiedPhoneNumber({
      phoneNumber: withoutSpace.slice(4),
    })}`;
  }

  if (/^[0-9]*$/.test(withoutSpace)) {
    if (withoutSpace.length === 8 || withoutSpace.length === 10) {
      let result = '';
      _.each([...withoutSpace], (c) => {
        if (
          result.length > 1 &&
          result[result.length - 1] !== ' ' &&
          result[result.length - 2] !== ' '
        ) {
          result += ' ';
        }
        result += c;
      });
      return result;
    }
  }
  return phoneNumber;
};

function isProfileDeletable(profile) {
  const ATS_KEYS = [
    'smartrecruiters',
    'lever',
    'greenhouse',
    'teamtailor',
    'workable',
    'recruitee',
  ];
  return _.isEmpty(
    removeEmptyKeyValues(_.pick(profile?.resumeData?.sourceData, ATS_KEYS)),
  );
}

const ProfileEducationSummary = ({
  schoolName,
  studiedAt,
  endDate,
  stepId,
}) => {
  const clientId = useClientId();
  const { data } = useClient(clientId);
  const { hasLimitedAccessToProfiles } = data?.client || {};

  if (!schoolName) {
    return null;
  }

  if (hasLimitedAccessToProfiles) {
    return <BlurLine style={{ display: 'inline-block', width: 100 }} />;
  }

  return (
    <MarketplaceUnbiasedModeField
      name='hideEducationName'
      enabled={stepId === 'pending'}
    >
      {studiedAt} {schoolName}
      <MarketplaceUnbiasedModeField
        name='hideEducationDates'
        enabled={stepId === 'pending'}
      >
        {endDate ? ` (${endDate.fromNow()})` : ''}
      </MarketplaceUnbiasedModeField>
    </MarketplaceUnbiasedModeField>
  );
};

class ProfileHeader extends React.PureComponent {
  static defaultProps = {
    editable: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      uploadFileModalIsOpen: false,
      linkCopiedToClipboard: false,
    };
  }

  onOpenUploadResumeFileModal = () => {
    this.setState({
      uploadFileModalIsOpen: true,
      fileType: ATTACHMENT_TYPE_RESUME,
    });
  };

  onOpenUploadCoverLetterFileModal = () => {
    this.setState({
      uploadFileModalIsOpen: true,
      fileType: ATTACHMENT_TYPE_COVER_LETTER,
    });
  };

  onOpenUploadOtherFileModal = () => {
    this.setState({
      uploadFileModalIsOpen: true,
      fileType: ATTACHMENT_TYPE_OTHER,
    });
  };

  onCloseUploadFileModal = () => {
    this.setState({ uploadFileModalIsOpen: false });
  };

  shareProfile = async () => {
    const {
      markProfileAsShared,
      clientId,
      profileId,
      offerId,
      profile,
    } = this.props;
    if (!clientId || !profileId || !(offerId || profile?.offer?.id)) {
      Sentry.captureException('Missing props');
      return;
    }
    await markProfileAsShared({ profileId });
    await navigator.clipboard.writeText(
      `${window.origin}/client/${clientId}/jobs/${offerId ||
        profile?.offer?.id}/profiles?pid=${encodeURIComponent(
        btoa(profileId),
      )}`,
    );
    this.setState({ linkCopiedToClipboard: true });
  };

  copyEmail = async () => {
    const { profile } = this.props;
    if (profile?.resumeData?.email) {
      await navigator.clipboard.writeText(profile?.resumeData?.email);
    }
  };

  hasCompanyGlobalInterestItem = () => {
    const { resumeData, clientId } = this.props;
    const clientInterestStates = _.findWhere(
      resumeData?.sourceData?.hiresweet?.clientInterestStates || [],
      { clientId },
    );
    return _.findWhere(clientInterestStates?.interestItems || [], {
      type: CANDIDATE_INTEREST_ITEM_TYPE.COMPANY_GLOBAL,
    });
  };

  render() {
    const {
      t,
      profileId,
      stepId,
      resumeData,
      firstname,
      lastname,
      user,
      currentTask,
      attachments,
      client,
      clientId,
      profile,
      offerId,
      // customFields,
      showEmailInHeader,
      // hidePersonnalData,
      withBottomMargin,
      applicationReviewMode,
      editable,
      toggleEditMode,
      deleteProfile,
      searchPoolId,
      canApplyUnbiasedMode = true,
    } = this.props;

    const {
      uploadFileModalIsOpen,
      fileType,
      linkCopiedToClipboard,
    } = this.state;

    const unbiasedModeEnabled =
      (user || {}).unbiasedModeEnabled &&
      (stepId === 'pending' || applicationReviewMode);
    const { mainEducation, photoLink, headline, location, phoneNumber } =
      resumeData || {};
    const headlineText = ((headline || {}).content || {}).text;
    const locationName = getTranslatedText((location || {}).name || '');
    const locationCanBeDisplayed = getLocationCanBeDisplayed(locationName);
    const { schoolName } = mainEducation || {};

    const endDate = sweetDateToMomentDate(
      mainEducation && mainEducation.endDate,
    );

    const studiedAt =
      resumeData?.firstname && isFemaleName(resumeData?.firstname)
        ? t('profile.resume.femaleStudiedAt')
        : t('profile.resume.studiedAt');

    const avatarImageUrl =
      photoLink && !unbiasedModeEnabled
        ? replacePhotoLink(photoLink)
        : getRandomDefaultAvatarLink(firstname + lastname);

    const hasSummary =
      resumeData?.summary &&
      !_.isEmpty(resumeData?.summary?.content) &&
      !_.isEmpty(resumeData?.summary?.content?.text);
    const isWatchProfile =
      (resumeData?.prequalification || {}).type === 'hiresweet-watch';

    const needBottomMargin =
      withBottomMargin ||
      !_.isEmpty(resumeData?.relevantTags) ||
      hasSummary ||
      isWatchProfile;
    const isDeletable = isProfileDeletable(profile);

    const onClickSource = ({ e, sourceId }) => {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }

      logAction({
        searchPoolProfileId: profile?.id,
        type: 'click-profile-source',
        profileId,
        info: {
          sourceId,
          from: 'profile-header',
        },
      });

      if (sourceId === 'linkedin') {
        setPluginTaskState({ task: currentTask });
      }
    };

    return (
      <SaveProfileInfoToClipboard
        profile={profile}
        offerId={offerId || profile?.offer?.id}
      >
        <div
          className={classNames(
            'profile-header',
            needBottomMargin && 'with-bottom-margin',
            isWatchProfile && 'is-watch-profile',
          )}
        >
          <div className='avatar-and-infos'>
            <div className='avatar' data-openreplay-masked>
              <MarketplaceUnbiasedModeField
                enabled={stepId === 'pending' || canApplyUnbiasedMode}
                name='hidePicture'
                fallback={
                  <Image
                    circular
                    data-openreplay-masked
                    className='card-company-image'
                    src={getRandomDefaultAvatarLink(firstname + lastname)}
                  />
                }
              >
                <Image
                  data-openreplay-masked
                  className='card-company-image'
                  src={getSecureLink(avatarImageUrl)}
                  circular
                  onError={(e) => {
                    e.target.src = getRandomDefaultAvatarLink(
                      firstname + lastname,
                    );
                  }}
                />
              </MarketplaceUnbiasedModeField>
              {isWatchProfile && (
                <div className='header-annotations'>
                  <ProfileFavoriteButton profile={profile} />
                </div>
              )}
              {this.hasCompanyGlobalInterestItem() && (
                <div className={styles.companyGlobalInterestIcon}>
                  <Interest />
                </div>
              )}
              <ProfilePowerHourFlag profile={profile}>
                <div className={styles.profilePowerHourIcon}>
                  <ProfilePowerHourIcon />
                </div>
              </ProfilePowerHourFlag>
              {user?.isAdmin &&
                user?.permissions?.canEnableAutoSend &&
                profile && (
                  <div className={styles.curatedAutoSendButton}>
                    <CuratedAutoSendButton profile={profile} />
                  </div>
                )}
            </div>
            <div className='infos'>
              <LoadingComponent
                as='div'
                loading={_.isEmpty(resumeData)}
                length={30}
                margin={0.6}
              >
                <div>
                  <div className={styles.heading1}>
                    <div
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        width: '100%',
                      }}
                    >
                      <h1 className='fullname' data-openreplay-masked>
                        {client?.hasLimitedAccessToProfiles ? (
                          <BlurLine
                            style={{ display: 'inline-block', width: 100 }}
                          />
                        ) : (
                          <MarketplaceUnbiasedModeField
                            enabled={
                              stepId === 'pending' || canApplyUnbiasedMode
                            }
                            name='hideFullName'
                          >
                            {resumeData?.firstname} {resumeData?.lastname}
                          </MarketplaceUnbiasedModeField>
                        )}
                        <ProfileViews profile={profile} />
                        {user?.isAdmin &&
                          clientId &&
                          profileId &&
                          (offerId || profile?.offer?.id) && (
                            <i
                              className={classNames('ri-external-link-line', {
                                copied: linkCopiedToClipboard,
                              })}
                              onClick={() => this.shareProfile()}
                            />
                          )}
                        {resumeData?.email && showEmailInHeader && (
                          <i
                            className='ri-mail-line'
                            title={resumeData?.email}
                            onClick={() => this.copyEmail()}
                          />
                        )}
                        {isWatchProfile && (
                          <SaveProfileInfoToClipboard
                            profile={profile}
                            offerId={offerId || profile?.offer?.id}
                            asIcon
                          />
                        )}
                        {isWatchProfile && (
                          <WatchNewTag
                            creationTimestamp={profile?.creationTimestamp}
                          />
                        )}
                      </h1>
                    </div>
                    {editable && (
                      <div className={styles.headingActions}>
                        <ActionButton
                          icon='edit-2-fill'
                          onClick={toggleEditMode}
                        >
                          {t('profile.actions.edit')}
                        </ActionButton>
                        {isDeletable && (
                          <ActionButton
                            icon='delete-bin-fill'
                            onClick={deleteProfile}
                          >
                            {t('profile.actions.delete')}
                          </ActionButton>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </LoadingComponent>
              <LoadingComponent
                as='div'
                loading={_.isEmpty(resumeData)}
                length={50}
                margin={0.6}
              >
                <h2
                  className={classNames(
                    'headline',
                    isWatchProfile && 'watch-profile',
                  )}
                >
                  <span className='headline'>{headlineText}</span>
                  {headlineText && locationCanBeDisplayed && locationName ? (
                    <span> • </span>
                  ) : null}
                  <span className='location'>
                    {locationCanBeDisplayed && locationName}
                  </span>
                </h2>
              </LoadingComponent>
              <LoadingComponent
                as='div'
                loading={_.isEmpty(resumeData)}
                length={50}
                margin={0.6}
              >
                {!isWatchProfile && phoneNumber && (
                  <h3
                    className='phone-number reveal-profile'
                    data-openreplay-masked
                  >
                    <i className='ri-phone-fill' />{' '}
                    {getBeautifiedPhoneNumber({ phoneNumber })}
                  </h3>
                )}
                <div className='sources-container'>
                  <ProfileSources
                    sources={resumeData?.sources}
                    onClickSource={onClickSource}
                  />
                  {/* <Dropdown
                      direction='left'
                      className='add-attachment-dropdown'
                      trigger={
                        <button type='button' className='primary-link-button'>
                          {t('fileUpload.uploadAttachment')}
                        </button>
                      }
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={this.onOpenUploadResumeFileModal}>
                          {t('fileUpload.typeOptions.resume')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={this.onOpenUploadCoverLetterFileModal}
                        >
                          {t('fileUpload.typeOptions.coverLetter')}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={this.onOpenUploadOtherFileModal}>
                          {t('fileUpload.typeOptions.other')}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                  <DownloadResumeButton
                    profileId={profileId}
                    searchPoolId={searchPoolId}
                  />
                  {isWatchProfile &&
                    (phoneNumber ? (
                      <h3 className='phone-number' data-openreplay-masked>
                        <i className='ri-phone-fill' />{' '}
                        {getBeautifiedPhoneNumber({ phoneNumber })}
                      </h3>
                    ) : (
                      <MarketplaceUnbiasedModeField
                        enabled={stepId === 'pending' || canApplyUnbiasedMode}
                        name='hideEducationSchoolNames'
                      >
                        <h3 className='main-education'>
                          <ProfileEducationSummary
                            schoolName={schoolName}
                            studiedAt={studiedAt}
                            endDate={endDate}
                            stepId={stepId}
                          />
                        </h3>
                      </MarketplaceUnbiasedModeField>
                    ))}
                </div>
              </LoadingComponent>
              {isWatchProfile && <ProfileGlobalComment profile={profile} />}
            </div>
          </div>
        </div>
        {/*  <ProfileHeaderCustomFields
          customFields={customFields}
          resumeData={resumeData}
        /> */}
        <UploadFileModal
          open={uploadFileModalIsOpen}
          attachments={attachments}
          onClose={this.onCloseUploadFileModal}
          profileId={profileId}
          fileType={fileType}
        />
      </SaveProfileInfoToClipboard>
    );
  }
}

export default compose(
  withActionLogger,
  withUserFromJWToken,
  withMarkProfileAsShared,
  withTranslation('translations'),
  withProfileCurrentTask,
  withClient,
)(ProfileHeader);
