import { useState } from 'react';
import _ from 'underscore';

export const useEditSenderState = (sender) => {
  const initialValues = sender || {};
  const flattenedUsers = _.map(initialValues.users, (user) => user.email);
  const [senderUsers, setSenderUsers] = useState(flattenedUsers);
  const [alias, setAlias] = useState(initialValues.alias);
  const [signature, setSignature] = useState(initialValues.signature);
  return {
    alias,
    signature,
    senderUsers,
    setAlias,
    setSignature,
    setSenderUsers,
  };
};
