import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/browser';
import _, { compose } from 'underscore';
import classNames from 'classnames';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import AutosizeInput from 'react-18-input-autosize';
import { withTranslation } from 'react-i18next';

import SimpleDropdown from '@/components/SimpleDropdown';
import './SenderAndRecipient.css';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import {
  RevealCcBccContextProvider,
  RevealCcBccInputs,
  RevealCcBccSelector,
} from '@/components/Sequences/RevealCcBcc/RevealCcBcc';
import Alert from '@/components/Common/Alert/Alert';
import GenericButton from '@/components/Common/GenericButton';
import { textToId } from '@/common';
import AddReplyIcon from '@/containers/Parameters/Templates/AddReply';
import { RevealSenderType } from './useRevealEmailAction';
import withUpdateEmail from '../../../../../../hocs/searchPoolProfile/withUpdateEmail';
import { invalidEmailFormat } from '../../../../../../common/validators';
import onLogAction from '../../../../../../common/logAction';

const SenderAndRecipient = ({
  clientId,
  revealSenderType,
  from,
  canChooseEmitter,
  setClassicSender,
  setSoboSender,
  senders,
  sobo,
  bcc,
  setBcc,
  cc,
  setCc,
  sendInTargetThread,
  setSendInTargetThread,
  sendInTargetThreadPossible,
  targetThread,
  profile,
  updateEmail,
  pluginUserData,
  templateWithRecruiterConfigurations,
  onSelectTemplate,
  canShowDefaultTemplateReplies,
  suggestedTemplateForDefaultReply,
  handleAddTemplateToDefault,
  allowSelectSharedSender = false,
  t,
}) => {
  const [recipient, setRecipient] = useState(profile?.email || '');
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(false);
  const [inputRef, setInputFocus] = useFocus();
  const isPlugin = useIsPlugin();
  const { isMinimized } = useMinimizedView();

  const onCopyText = () =>
    onLogAction({
      profileId: profile.id,
      type: 'copy-email',
      info: {
        from,
        to: profile?.email,
      },
    });

  useEffect(() => {
    if (editMode) {
      setInputFocus();
    }
  }, [editMode, setInputFocus]);

  useEffect(() => {
    setRecipient(profile?.email);
    // eslint-disable-next-line
  }, [profile?.email]);

  const onEdit = () => {
    setEditMode(true);
  };

  const onSubmit = async () => {
    if (!recipient) {
      setEditMode(false);
      return;
    }

    if (invalidEmailFormat({ email: recipient })) {
      setError(true);
      setEditMode(false);
      return;
    }

    try {
      const input = { id: profile?.id, email: recipient };
      await updateEmail({ searchPoolId: 'reveal', input });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }

    setEditMode(false);
  };

  const onChange = (e) => {
    setRecipient(e?.target?.value || '');
    setError(false);
  };

  const emitterDisplayed =
    from || (isPlugin && pluginUserData?.user?.mailAccount?.address) || null;

  const recipientIsEditable =
    !profile?.sent && (!profile?.email || profile?.isImported);

  const targetThreadSender = targetThread
    ? _.find(
        senders,
        ({ senderAddress }) =>
          textToId(senderAddress) === targetThread?.mailAccountId,
      )
    : undefined;

  const showDefaultReplyTemplates =
    targetThread &&
    sendInTargetThreadPossible &&
    sendInTargetThread &&
    canShowDefaultTemplateReplies;

  return (
    <div className='email-info-container'>
      {targetThread && !sendInTargetThreadPossible && from && (
        <Alert
          title={t('common.warning')}
          description={t('profile.contact.targetThread.cannotSend', {
            senderAddress:
              targetThreadSender?.senderAddress ||
              t('profile.contact.targetThread.senderAddressFallback'),
          })}
        >
          {targetThreadSender && (
            <GenericButton
              primacy='secondary'
              size='small'
              onClick={() => {
                setSoboSender(targetThreadSender?.id);
                setSendInTargetThread(true);
              }}
            >
              {t('profile.contact.targetThread.switchAccount')}
            </GenericButton>
          )}
        </Alert>
      )}

      <RevealCcBccContextProvider cc={cc} bcc={bcc}>
        <div className='sender-container'>
          <div
            onCopy={onCopyText}
            className={`sender-and-recipient ${isMinimized ? 'minimized' : ''}`}
          >
            {Boolean(allowSelectSharedSender && !from && canChooseEmitter) && (
              <SimpleDropdown text={t('reveal.sobo.assignSender')}>
                <Dropdown.Menu>
                  {_.map(senders, (sender) => (
                    <Dropdown.Item
                      className='sobo-dropdown-item'
                      key={sender.id}
                      text={sender.senderAddress}
                      onClick={() => setSoboSender(sender.id)}
                    />
                  ))}
                </Dropdown.Menu>
              </SimpleDropdown>
            )}
            {Boolean(emitterDisplayed) && (
              <div className='sender'>
                <span className='intro-word'>
                  {t('profile.contact.timeline.from')}
                </span>
                {canChooseEmitter ? (
                  <span>
                    <SimpleDropdown text={emitterDisplayed}>
                      <Dropdown.Menu>
                        {revealSenderType !== RevealSenderType.Classic && (
                          <>
                            <Dropdown.Item
                              className='sobo-dropdown-item'
                              text={t('reveal.sobo.classicEmail')}
                              onClick={() => setClassicSender()}
                            />
                            <Dropdown.Divider className='sobo-dropdown-divider' />
                          </>
                        )}
                        {_.map(senders, (sender) => (
                          <Dropdown.Item
                            className='sobo-dropdown-item'
                            key={sender.id}
                            text={sender.senderAddress}
                            onClick={() => setSoboSender(sender.id)}
                          />
                        ))}
                      </Dropdown.Menu>
                    </SimpleDropdown>
                  </span>
                ) : (
                  <span>{emitterDisplayed}</span>
                )}
              </div>
            )}
            <div className='recipient'>
              <span className='intro-word'>
                {t('profile.contact.timeline.to')}
              </span>
              {recipientIsEditable ? (
                <span onClick={onEdit}>
                  <AutosizeInput
                    ref={inputRef}
                    className={classNames('recipient-input', error && 'error')}
                    value={recipient}
                    onChange={onChange}
                    placeholder={t(
                      'profile.contact.timeline.emailInputPlaceholder',
                    )}
                    disabled={!editMode}
                    onBlur={onSubmit}
                  />
                  {!editMode && <i className='recipient-icon ri-edit-line' />}
                </span>
              ) : (
                <span>{profile?.resumeData?.email}</span>
              )}
            </div>
          </div>
          <RevealCcBccSelector />
        </div>
        <RevealCcBccInputs onCc={setCc} cc={cc} onBcc={setBcc} bcc={bcc} />
      </RevealCcBccContextProvider>

      {targetThread && (
        <Checkbox
          label={t('profile.contact.targetThread.sameThread')}
          checked={sendInTargetThread && sendInTargetThreadPossible}
          onChange={(e, { checked }) => {
            setSendInTargetThread(checked);
          }}
          disabled={!sendInTargetThreadPossible}
          toggle
          className='hiresweet-small-toggle'
        />
      )}

      {showDefaultReplyTemplates &&
        !_.isEmpty(templateWithRecruiterConfigurations) && (
          <div className='default-template-replies-container'>
            <span>{t('profile.contact.targetThread.useReplyTemplate')}</span>
            <div className='default-template-replies'>
              {_.map(
                templateWithRecruiterConfigurations,
                (templateWithRecruiterConfiguration) => (
                  <span
                    key={templateWithRecruiterConfiguration.id}
                    className='pill-message grey mini'
                    onClick={() =>
                      onSelectTemplate({
                        templateId: templateWithRecruiterConfiguration.id,
                      })
                    }
                    role='button'
                    tabIndex={0}
                    onKeyDown={(event) =>
                      event.key === 'Enter' &&
                      onSelectTemplate({
                        templateId: templateWithRecruiterConfiguration.id,
                      })
                    }
                  >
                    {templateWithRecruiterConfiguration.title}
                  </span>
                ),
              )}
            </div>
            {suggestedTemplateForDefaultReply ? (
              <div
                className='add-to-default-template-replies'
                onClick={handleAddTemplateToDefault}
              >
                <span>
                  <AddReplyIcon
                    style={{
                      width: '18px',
                      height: '18px',
                      marginLeft: '1px',
                      flexShrink: 0,
                    }}
                  />
                  {t('profile.contact.targetThread.addToDefaultReplies.prefix')}
                  <span className='template-title'>
                    {suggestedTemplateForDefaultReply.title}
                  </span>
                  {t('profile.contact.targetThread.addToDefaultReplies.suffix')}
                </span>
              </div>
            ) : null}
          </div>
        )}
    </div>
  );
};

const useFocus = () => {
  const ref = useRef(null);
  const setFocus = () => {
    if (ref.current) {
      ref.current.focus();
    }
  };
  return [ref, setFocus];
};

export default compose(
  withTranslation('translations'),
  withUpdateEmail,
)(SenderAndRecipient);
