import _ from 'underscore';
import { TFunction } from 'i18next';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { getSweetEvaluator, SweetEvaluatorTypes } from '@/SweetEvaluator';
import { ContactFlowSequenceActions } from '@/types/searchPoolProfile';
import {
  getRandomString,
  getTranslatedText,
  recursivelyRemoveEmptyKeyValues,
} from '@/common';
import getExternalExpressionEvaluatorFromType from '@/common/mergeTags/externalEvaluators';
import { getHtmlStringAsText } from '@/routes/RevealView/SearchView/utils';
import {
  ConditionsChainingVariable,
  getMergeTagsSnippets,
  getOldMergeTagsKeys,
  IfStatement,
  MergeTagsVariable,
  SNIPPET_INPUT_BY_TYPE,
  SNIPPET_TYPES,
} from './utils';
import { sanitizeTypename } from '../utils/apollo';

const SNIPPETS_TYPES_WITH_SUB_SNIPPETS = [SNIPPET_TYPES.SYNCED_FRAGMENT];

/**
 * Interpret dynamic variables ({{}}) in text
 * Return snippetId if the key matched is a snippet id, or the context value
 * @param text
 * @param instantiatedSnippets
 * @param context
 */
const getSnippetIdOrContextValueFromText = ({
  text,
  instantiatedSnippets,
  context,
}: {
  text: string;
  instantiatedSnippets: MergeTagsVariable[];
  context: Record<string, unknown>;
}): string => {
  return text.replace(
    /\{{([^{}]*)}}/g,
    (match: string, key: string): string => {
      const snippet = _.find(instantiatedSnippets, (instantiatedSnippet) => {
        return instantiatedSnippet.id === key;
      });
      if (snippet) {
        return `{{${snippet.id}}}`;
      }
      return context[key] ? (context[key] as string) : '';
    },
  );
};

/**
 * Interpret dynamic variables ({{}}) in text
 * Find snippets that matches the variables
 * Return snippets
 * @param text
 * @param subId
 * @param customFields
 * @param snippetsToSearch
 */
const getSnippetsFromText = ({
  text,
  type,
  customFields,
  missionCustomFields,
  snippetsToSearch,
  t,
  clientSnippets,
}: {
  text: string;
  type: string;
  customFields?: CustomFieldDefinition[];
  missionCustomFields?: CustomFieldDefinition[];
  snippetsToSearch?: MergeTagsVariable[];
  clientSnippets?: MergeTagsVariable[];
  t: TFunction;
}): MergeTagsVariable[] => {
  const snippets: MergeTagsVariable[] = [];
  text.replace(/\{{([^{}]*)}}/g, (match, key) => {
    const keyToFind = key.split('_')[0];

    const snippet =
      (snippetsToSearch &&
        _.find(snippetsToSearch, (sn) => sn.id.split('_')[0] === keyToFind)) ||
      _.findWhere(
        getMergeTagsSnippets({
          t,
          customFields,
          clientSnippets,
          missionCustomFields,
        }),
        {
          id: keyToFind,
          ...(type && {
            type: type as any,
          }),
        },
      );
    if (snippet) {
      snippets.push({
        ...snippet,
        id: key,
      });
      return '';
    }
    return '';
  });
  return snippets;
};

/**
 * Instantiate dynamic variables in text
 * Replace variable by snippet value or by context value
 * @param text
 * @param instantiatedSnippets
 * @param context
 */
const getInstantiatedText = ({
  text,
  instantiatedSnippets,
  context,
  t,
  withNoValueText = true,
  isEmailAction = false,
}: {
  text: string;
  instantiatedSnippets: MergeTagsVariable[];
  context: Record<string, unknown>;
  t: TFunction;
  withNoValueText?: boolean;
  isEmailAction?: boolean;
}) => {
  return text?.replace(
    /\{{([^{}]*)}}/g,
    (match: string, key: string): string => {
      const snippet = _.find(instantiatedSnippets, (sn) => sn.id === key);
      if (snippet) {
        return getSnippetDisplayedValue({
          snippet,
          t,
          withNoValueText,
          isEmailAction,
        });
      }
      return context[key] ? (context[key] as string) : '';
    },
  );
};

/**
 * Instantiate message (body and subject)
 * Replace variables by snippets value or context
 * @param message
 * @param instantiatedSnippets
 * @param context
 * @param templates
 * @param defaultSubject
 */
const getInstantiatedMessage = ({
  message,
  instantiatedSnippets,
  context,
  templates,
  defaultSubject,
  t,
  isEmailAction,
}: {
  message: any;
  instantiatedSnippets: MergeTagsVariable[];
  context: Record<string, unknown>;
  templates: any;
  defaultSubject?: string;
  isEmailAction?: boolean;
  t: TFunction;
}) => {
  const instantiatedMessage = {
    ...message,
    ...(message.body && {
      body: getInstantiatedText({
        text: message.body,
        instantiatedSnippets,
        context,
        t,
        isEmailAction,
      }),
    }),
    ...(message.subject && {
      subject: getInstantiatedText({
        text: message.subject,
        instantiatedSnippets,
        context,
        t,
        isEmailAction,
      }),
    }),
    ...(!message.subject && defaultSubject && { subject: defaultSubject }),
  };
  if (!message?.templateId) {
    return instantiatedMessage;
  }
  const currentTemplate = _.findWhere(templates, { id: message?.templateId });
  if (!currentTemplate) {
    return instantiatedMessage;
  }
  const { subject: templateSubject, body: templateBody } = currentTemplate;
  return {
    ...message,
    body: getInstantiatedText({
      context,
      text: templateBody,
      instantiatedSnippets,
      t,
      isEmailAction,
    }),
    subject:
      getInstantiatedText({
        context,
        text: templateSubject,
        instantiatedSnippets,
        isEmailAction,
        t,
      }) ||
      defaultSubject ||
      '',
  };
};

/**
 * Remove all __typename in a Snippet GraphQL object
 * @param snippet
 */
const sanitizeSnippet = ({
  snippet,
}: {
  snippet: any;
}): Record<string, unknown> => {
  const sanitized: any = _.omit(
    {
      ...sanitizeTypename(snippet),
    },
    ['__typename', 'author', 'isVirtual', 'actionId'],
  );
  return _.pick(sanitized, _.identity);
};

/**
 * Create input for Snippets
 * Return object with specific input values for each type of snippets
 * @param snippets
 */
const createSnippetsInput = ({
  snippets,
  subject,
  body,
}: {
  snippets: MergeTagsVariable[];
  subject: string;
  body: string;
}): any => {
  const cleanSnippets = _.filter(snippets || [], (snippet) => {
    if (snippet.idInParentDynamicVariable) {
      return true;
    }
    return subject?.includes(snippet.id) || body?.includes(snippet.id);
  });
  return _.compact(
    _.map(cleanSnippets, (snippet) => {
      const { type } = snippet;
      const snippetKey = SNIPPET_INPUT_BY_TYPE[type];
      if (!snippetKey) {
        return null;
      }
      if (type === SNIPPET_TYPES.CONDITIONS_CHAINING) {
        return createConditionsChainingSnippetsInput(snippet as any);
      }
      return {
        [snippetKey]: {
          ...sanitizeSnippet({ snippet }),
        },
      };
    }),
  );
};

const createConditionsChainingSnippetsInput = (
  snippet: ConditionsChainingVariable & { id?: string; name: string },
) => {
  const cleanSnippet = sanitizeTypename(snippet);

  const {
    ifStatements,
    elseStatement,
    id,
    name,
    type,
    subtype,
    switchField,
  } = cleanSnippet;
  const cleanElseSnippets = _.filter(
    elseStatement?.snippets || [],
    (subSnippet) => {
      return elseStatement.text.includes(subSnippet.id);
    },
  );
  const snippetKey = SNIPPET_INPUT_BY_TYPE[SNIPPET_TYPES.CONDITIONS_CHAINING];
  const finalObject = {
    [snippetKey]: {
      ...(id && { id }),
      ...(subtype && { subtype }),
      ...(switchField && { switchField }),
      name,
      type,
      ifStatements: _.map(ifStatements, (ifStatement: IfStatement) => {
        const cleanSnippets = _.filter(
          ifStatement.result.snippets || [],
          (subSnippet) => {
            return ifStatement.result.text.includes(subSnippet.id);
          },
        );
        return {
          ...ifStatement,
          result: {
            ...ifStatement.result,
            ...(!_.isEmpty(ifStatement.result.snippets) && {
              snippets: MergeTagsService.createSnippetsInput({
                snippets: cleanSnippets,
                subject: '',
                body: ifStatement.result.text,
              }),
            }),
          },
        };
      }),
      ...(!_.isEmpty(elseStatement) && {
        elseStatement: {
          ...elseStatement,
          ...(!_.isEmpty(elseStatement.snippets) && {
            snippets: MergeTagsService.createSnippetsInput({
              snippets: cleanElseSnippets,
              subject: '',
              body: elseStatement.text,
            }),
          }),
        },
      }),
    },
  };
  return recursivelyRemoveEmptyKeyValues(finalObject);
};

const getActionDeepSnippets = ({
  action,
  evaluatorContext,
}: {
  action: ContactFlowSequenceActions;
  evaluatorContext: Record<string, any>;
}) => {
  return _.flatten(
    _.map(action.snippets as MergeTagsVariable[], (actionSnippet) => {
      if (!_.contains(SNIPPETS_TYPES_WITH_SUB_SNIPPETS, actionSnippet.type)) {
        return actionSnippet;
      }

      const getDeepSnippets = () => {
        if (actionSnippet.type === SNIPPET_TYPES.SYNCED_FRAGMENT) {
          const clientFragment =
            evaluatorContext?.externalSnippets?.[
              actionSnippet.clientDynamicVariableId
            ];
          return _.filter(
            clientFragment?.snippets as MergeTagsVariable[],
            (clientFragmentSnippet) => {
              return !_.find(
                action.snippets as MergeTagsVariable[],
                (snippet) => {
                  return (
                    clientFragmentSnippet.type === snippet.type &&
                    clientFragmentSnippet.id ===
                      snippet.idInParentDynamicVariable
                  );
                },
              );
            },
          );
        }
        return [];
      };

      const deepSnippets = getDeepSnippets();

      return [
        actionSnippet,
        ..._.map(deepSnippets, (deepSnippet) => ({
          ...deepSnippet,
          id: `${deepSnippet.id.split('_')[0]}_contactFlow_${getRandomString(
            10,
          ).toUpperCase()}`,
          isVirtual: true,
          idInParentDynamicVariable: deepSnippet.id,
          actionId: action.actionId,
        })),
      ];
    }),
  );
};

const preInstantiateContactFlowAction = ({
  action,
  evaluatorContext,
  withDeepSnippets = false,
}: {
  action: ContactFlowSequenceActions;
  evaluatorContext: Record<string, any>;
  withDeepSnippets?: boolean;
}): ContactFlowSequenceActions => {
  const sweetEvaluator = getSweetEvaluator({
    getExternalExpressionEvaluatorFromType,
  });

  const actionSnippets = withDeepSnippets
    ? getActionDeepSnippets({ action, evaluatorContext })
    : action.snippets;

  const instantiatedSnippets = sweetEvaluator.getUpdatedVariables({
    variables: actionSnippets as SweetEvaluatorTypes.Variable[],
    context: evaluatorContext,
  });

  let finalInstantiatedSnippets;
  if (withDeepSnippets) {
    // Add evaluated statement snippets
    const conditionsChainingEvaluatedSnippets = _.compact(
      _.flatten(
        _.map(instantiatedSnippets as MergeTagsVariable[], (snippet) => {
          if (snippet.type !== SNIPPET_TYPES.CONDITIONS_CHAINING) {
            return null;
          }
          const { evaluatedStatementId } = snippet.state as any;
          const statement = _.findWhere(snippet.ifStatements, {
            id: evaluatedStatementId,
          });
          if (!statement?.result.snippets) {
            return null;
          }
          return _.map(statement.result.snippets, (statementSnippet) => {
            const alreadyExistInAction = _.findWhere(
              action.snippets as MergeTagsVariable[],
              { idInParentDynamicVariable: statementSnippet.id },
            );
            if (alreadyExistInAction) {
              return null;
            }
            const idSnippet = statementSnippet.id.split('_');
            return {
              ...statementSnippet,
              id: `${idSnippet[0]}_${idSnippet[1]}_${getRandomString(10)}`,
              isVirtual: true,
              idInParentDynamicVariable: statementSnippet.id,
              actionId: action.actionId,
            };
          });
        }),
      ),
    );
    finalInstantiatedSnippets = sweetEvaluator.getUpdatedVariables({
      variables: [
        ...instantiatedSnippets,
        ...conditionsChainingEvaluatedSnippets,
      ] as SweetEvaluatorTypes.Variable[],
      context: evaluatorContext,
    });
  } else {
    finalInstantiatedSnippets = instantiatedSnippets;
  }

  return {
    ...action,
    snippets: finalInstantiatedSnippets,
  };
};

const replaceAllIds = ({
  subject,
  body,
  subId,
  snippets,
  t,
}: {
  subject: string;
  body: string;
  subId: string;
  snippets: MergeTagsVariable[];
  t: TFunction;
}) => {
  const updatedSnippets: MergeTagsVariable[] = [];
  const updatedSubject = subject?.replaceAll(
    /\{{([^{}]*)}}/g,
    (match: string, key: string): string => {
      const isOldMergeTag = _.findWhere(getOldMergeTagsKeys(t), { key });
      if (isOldMergeTag) {
        return `{{${key}}}`;
      }
      const matchedSnippet = _.findWhere(snippets, { id: key });
      if (!matchedSnippet) {
        throw new Error();
      }
      const keySplit = key.split('_');
      const newId = `${keySplit[0]}_${subId}_${getRandomString(10)}`;
      updatedSnippets.push({
        ...matchedSnippet,
        id: newId,
      });
      return `{{${newId}}}`;
    },
  );
  const updatedBody = body?.replaceAll(
    /\{{([^{}]*)}}/g,
    (match: string, key: string): string => {
      const isOldMergeTag = _.findWhere(getOldMergeTagsKeys(t), { key });
      if (isOldMergeTag) {
        return `{{${key}}}`;
      }
      const matchedSnippet = _.findWhere(snippets, { id: key });
      if (!matchedSnippet) {
        throw new Error('Missing matching snippet');
      }
      const keySplit = key.split('_');
      const newId = `${keySplit[0]}_${subId}_${getRandomString(10)}`;
      updatedSnippets.push({
        ...matchedSnippet,
        id: newId,
      });
      return `{{${newId}}}`;
    },
  );
  return {
    subject: updatedSubject,
    body: updatedBody,
    snippets: updatedSnippets,
  };
};

const instantiateMessage = ({
  evaluatorContext,
  snippets,
  subject,
  body,
  t,
}: {
  evaluatorContext: any;
  snippets: MergeTagsVariable[];
  subject: string;
  body: string;
  t: TFunction;
}): {
  instantiatedSubject: string;
  instantiatedBody: string;
  instantiatedSnippets: MergeTagsVariable[];
} => {
  const sweetEvaluator = getSweetEvaluator({
    getExternalExpressionEvaluatorFromType,
  });
  const finalSnippets = snippets?.map((snippet) => {
    if (snippet.type === SNIPPET_TYPES.SELECT) {
      if (!snippet.state?.value) {
        return snippet;
      }
      const selectedOptionsIds = snippet.state.value.split(',');
      return {
        ...snippet,
        state: {
          ...snippet.state,
          value: selectedOptionsIds
            .map((selectedOptionId) => {
              const selectedOption = _.find(
                snippet.options,
                (option) => option.id === selectedOptionId,
              );
              return selectedOption?.title.default || selectedOption?.id;
            })
            .join(', '),
        },
      };
    }
    return snippet;
  });
  const instantiatedSnippets = sweetEvaluator.getUpdatedVariables({
    variables: finalSnippets as SweetEvaluatorTypes.Variable[],
    context: evaluatorContext,
  });
  const {
    body: instantiatedBody,
    subject: instantiatedSubject,
  } = getInstantiatedMessage({
    message: { body, subject },
    instantiatedSnippets,
    context: evaluatorContext,
    templates: [],
    t,
  });
  return {
    instantiatedSubject,
    instantiatedBody,
    instantiatedSnippets,
  };
};

const getSnippetDisplayedValue = ({
  snippet,
  t,
  withNoValueText = true,
  isEmailAction = false,
}: {
  snippet: MergeTagsVariable;
  t?: TFunction;
  withNoValueText?: boolean;
  isEmailAction?: boolean;
}) => {
  if (!snippet) {
    return '';
  }
  if (snippet.type === SNIPPET_TYPES.SELECT && snippet.state?.value) {
    const values = snippet.state.value.split(',');
    return _.map(values, (value) =>
      getTranslatedText(
        _.findWhere(snippet.options, { id: value })?.title || {
          default: '',
        },
      ),
    ).join(', ');
  }

  if (
    _.contains(SNIPPETS_TYPES_WITH_SUB_SNIPPETS, snippet.type) &&
    !isEmailAction
  ) {
    return getHtmlStringAsText(snippet?.state?.value || '');
  }

  return (
    snippet.state?.value ||
    snippet.fallbackValue?.text ||
    (withNoValueText && snippet.type !== SNIPPET_TYPES.CONDITIONS_CHAINING && t
      ? t('reveal.mergeTags.noValue')
      : '') ||
    ''
  );
};

const MergeTagsService = {
  getSnippetIdOrContextValueFromText,
  getSnippetsFromText,
  getInstantiatedText,
  getInstantiatedMessage,
  preInstantiateContactFlowAction,
  sanitizeSnippet,
  createSnippetsInput,
  replaceAllIds,
  instantiateMessage,
  getSnippetDisplayedValue,
  createConditionsChainingSnippetsInput,
  getActionDeepSnippets,
};

export default MergeTagsService;
