import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useLazyQuery } from '@apollo/client';
import { PipelineDetailDataPoint } from '@/types/statistics/pipeline';
import { CategorizationType } from '@/types/statistics/category';
import { isTodayYyyyMmDd } from '@/common/utils/time';
import { GenericStatsFilterInput } from './common';

const GET_PIPELINE_STATISTICS_BY_CATEGORY_DETAILS = gql`
  query getPipelineStatisticsByCategoryDetails(
    $clientId: ID!
    $categorization: CategorizationInput!
    $categoriesFilter: GenericStatsFilterInput
    $missionsFilter: GenericStatsFilterInput
    $stagesFilter: GenericStatsFilterInput
    $archivedStatusesFilter: GenericStatsFilterInput
    $startDate: Date
    $endDate: Date
  ) {
    client(id: $clientId) {
      id
      statistics {
        volumeByPipelineSplitByCategory(
          categorization: $categorization
          categoriesFilter: $categoriesFilter
          missionsFilter: $missionsFilter
          stagesFilter: $stagesFilter
          archivedStatusesFilter: $archivedStatusesFilter
          startDate: $startDate
          endDate: $endDate
        ) {
          missionId
          categoryId
          data {
            archived
            state
            profileItems {
              profileId
            }
          }
        }
      }
    }
  }
`;

interface VolumeByPipelineForCategoryAndMission {
  missionId: string;
  categoryId: string;
  data: PipelineDetailDataPoint[];
}

interface GetPipelineStatisticsByCategoryDetailsData {
  client: {
    id: string;
    statistics: {
      volumeByPipelineSplitByCategory: VolumeByPipelineForCategoryAndMission[];
    };
  };
}

interface GetPipelineStatisticsByCategoryDetailsVariables {
  clientId: string;
  categorization: {
    type: CategorizationType;
  };
  categoriesFilter?: GenericStatsFilterInput;
  missionsFilter?: GenericStatsFilterInput;
  stagesFilter?: GenericStatsFilterInput;
  archivedStatusesFilter?: GenericStatsFilterInput;
  startDate?: string;
  endDate?: string;
}

interface UsePipelineStatisticsByCategoryDetailsInput {
  queryOptions?: QueryHookOptions<
    GetPipelineStatisticsByCategoryDetailsData,
    GetPipelineStatisticsByCategoryDetailsVariables
  >;
}

type UsePipelineStatisticsByCategoryDetailsResult = [
  (variables: GetPipelineStatisticsByCategoryDetailsVariables) => void,
  QueryResult<
    GetPipelineStatisticsByCategoryDetailsData,
    GetPipelineStatisticsByCategoryDetailsVariables
  > & {
    volumeByPipelineSplitByCategory:
      | VolumeByPipelineForCategoryAndMission[]
      | undefined;
  },
];

const usePipelineStatisticsByCategoryDetails = (
  input?: UsePipelineStatisticsByCategoryDetailsInput,
): UsePipelineStatisticsByCategoryDetailsResult => {
  const { queryOptions } = input || {};

  const [fetchDetails, query] = useLazyQuery<
    GetPipelineStatisticsByCategoryDetailsData,
    GetPipelineStatisticsByCategoryDetailsVariables
  >(GET_PIPELINE_STATISTICS_BY_CATEGORY_DETAILS, {
    ...(queryOptions || {}),
  });

  return [
    ({ endDate, ...otherVariables }) =>
      fetchDetails({
        variables: {
          ...otherVariables,
          endDate: isTodayYyyyMmDd(endDate) ? undefined : endDate,
        },
      }),
    {
      ...query,
      volumeByPipelineSplitByCategory:
        query?.data?.client?.statistics?.volumeByPipelineSplitByCategory,
    },
  ];
};

export default usePipelineStatisticsByCategoryDetails;
