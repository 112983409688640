import gql from 'graphql-tag';

export default gql`
  fragment TranslatableItem on TranslatableItem {
    identifier
    label {
      default
      fr
      en
    }
  }
`;
