import gql from 'graphql-tag';
import _ from 'underscore';
import { graphql } from '@apollo/client/react/hoc';
import SmallResumeData from '../../graphql/fragments/SmallResumeData';
import PipeStepStat from '../../graphql/fragments/PipeStepStat';

export const getOfferProfiles = gql`
  query getOfferProfiles($offerId: ID!, $step: String!, $search: String!) {
    offer(id: $offerId) {
      id
      pipeStepStats {
        ...PipeStepStat
      }
      profiles(step: $step, search: $search) {
        id
        email
        jobOfferId
        resumeData {
          ...SmallResumeData
        }
        powerHourFlags {
          powerHourId
          isFlagged
        }
        firstContactDate
        source {
          type
          matchingScore
          createdFromDiscover
        }
        lastStepUpdateTimestamp
        annotation {
          globalFavorite {
            value
          }
          globalComment {
            value
          }
        }
        creationTimestamp
      }
    }
  }
  ${SmallResumeData}
  ${PipeStepStat}
`;

export const withOfferPendingProfiles = graphql(getOfferProfiles, {
  options: (ownProps) => {
    return {
      variables: {
        offerId: ownProps.offerId,
        step: 'pending',
        search: '',
      },
    };
  },
  props: ({ data: { loading, offer, error } }) => ({
    loading,
    profiles: offer && offer.profiles ? offer.profiles : [],
    error,
  }),
});

export default graphql(getOfferProfiles, {
  options: (ownProps) => {
    return {
      variables: {
        offerId: ownProps.offerId,
        step: '',
        search: '',
      },
      pollInterval: 10 * 60 * 1000,
    };
  },
  props: ({ data: { loading, offer, error, refetch } }) => ({
    loading,
    offerProfilesLoading: loading,
    refetchOfferProfiles: refetch,
    profiles: offer && offer.profiles ? offer.profiles : [],
    offerProfilesFirstnames: _.uniq(
      _.compact(
        _.map(
          offer && offer.profiles ? offer.profiles : [],
          (profile) => ((profile || {}).resumeData || {}).firstname,
        ),
      ),
    ),
    error,
  }),
});
