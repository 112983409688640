import React from 'react';
import { useMutation } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import { TimelineItem } from '@/types/contactData';

import { REMOVE_TIMELINE_ITEM } from '@/graphql/searchPoolProfile';
import DoNotContactPill from '@/components/Reveal/Profile/privacy/DoNotContactPill/DoNotContactPill';
import TASK_ICONS from '../TaskIcons';
import TimelineItemActions from '../TimelineItemActions';
import AuthorAndDate from '../AuthorAndDate';

interface DoNotContactItemProps {
  profile: SearchPoolProfile;
  timelineItem: TimelineItem;
  clientId: string;
}

const DoNotContactItem: React.FC<DoNotContactItemProps> = ({
  profile,
  timelineItem,
  clientId,
}) => {
  const { t } = useTranslation();
  const { action } = timelineItem;
  const { type } = action;
  const [removeTimelineItemMutation] = useMutation(REMOVE_TIMELINE_ITEM);

  const actionType =
    type === 'marked-as-do-not-contact' ? 'marked' : 'unmarked';

  const removeTimelineItem = async () => {
    await removeTimelineItemMutation({
      variables: {
        searchPoolId: 'reveal',
        profileId: profile?.id,
        type: timelineItem.action?.type,
        date: timelineItem.date,
      },
    });
  };

  const doNotContactPillText = `${t('reveal.profileModal.privacy.doNotContact.label')}${action?.reason ? ` (${action?.reason})` : ''}`;

  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{ backgroundColor: TASK_ICONS.doNotContact.color }}
              className='icon'
            >
              <i className={`${TASK_ICONS.doNotContact.icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            <Trans
              i18nKey={`profile.contact.timeline.${actionType}AsDoNotContact.description`}
              values={{
                firstname: profile.resumeData?.firstname,
                lastname: profile.resumeData?.lastname,
              }}
              components={{
                b: <b />,
                doNotContact: <DoNotContactPill text={doNotContactPillText} />,
              }}
            />
            <AuthorAndDate timelineItem={timelineItem} />
          </div>
          <div className='timeline-item-actions'>
            <TimelineItemActions
              onRemove={removeTimelineItem}
              profile={profile}
              clientId={clientId}
              timelineItem={timelineItem}
              withAtsSynchronization={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoNotContactItem;
