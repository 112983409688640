import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { JobSuspendedIcon } from '@/assets/icons';
import ReactivateOfferModal from '@/components/modals/ReactivateOfferModal';
import GenericButton from '@/components/Common/GenericButton';

import styles from './HoldNudge.module.less';

interface HoldNudgeProps {
  offerId: string;
  className?: string;
}

const HoldNudge: FC<HoldNudgeProps> = ({ offerId, className }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className={classNames(styles.holdNudge, className)}>
      <span className={styles.left}>
        <JobSuspendedIcon className={styles.suspendedIcon} />
        <span className={styles.title}>{t('offers.isHoldNudge.title')}</span>
        <span>{t('offers.isHoldNudge.explanation')}</span>
      </span>
      <span>
        <GenericButton onClick={() => setModalOpen(true)}>
          {t('offers.isHoldNudge.cta')}
        </GenericButton>
        <ReactivateOfferModal
          offerId={offerId}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      </span>
    </div>
  );
};

export default HoldNudge;
