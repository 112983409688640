import _ from 'underscore';
import React from 'react';
import { Modal, Dropdown, Input, Image } from 'semantic-ui-react';

import GenericButton from '@/components/Common/GenericButton';
import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';
import GenericModal from '@/components/Common/GenericModal';

import './DepartmentEditModal.css';
import GenericTextInput from '@/components/Common/GenericTextInput';
import GenericText from '@/components/Common/Text';
import Plus from '@/components/Reveal/Icons/Plus';
import GenericDropdown from '@/components/Common/GenericDropdown';
import DropdownMenuItem from '@/components/Common/DropdownMenuItem';
import DropdownPanel from '@/components/Common/DropdownPanel';
import Dots from '@/components/Common/Icons/Dots';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import ArrowUp from '@/components/Reveal/Icons/ArrowUp';
import Delete from '@/components/Reveal/Icons/Delete';
import { getRandomString, textToId } from '../../../common';

class DepartmentEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (props.initialValue) {
      this.state.initialValue = props.initialValue;
      this.state.value = _.omit(props.initialValue, 'id');
      this.state.useSections = !_.isEmpty(props.initialValue.sections);
    } else {
      this.state.value = {
        title: '',
      };
    }
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { initialValue, value } = this.state;
    const rawDepartment = {
      title: value.title || '',
      ...(!_.isEmpty(value.sections) && {
        sections: value.sections || [],
      }),
    };

    const getIdFromStr = (str) => `${textToId(str)}-${getRandomString(6)}`;

    const getDepartmentWithReadableId = () => {
      const initialSectionId = {};
      _.each(initialValue?.sections, (section) => {
        initialSectionId[section.id] = true;
      });

      return {
        ...rawDepartment,
        id: initialValue?.id || getIdFromStr(rawDepartment.title),
        ...(rawDepartment.sections && {
          sections: _.map(rawDepartment.sections, (section) => ({
            ...section,
            id: initialSectionId[section.id]
              ? section.id
              : getIdFromStr(section.title),
          })),
        }),
      };
    };

    const department = getDepartmentWithReadableId();

    onSubmit({ department });
  };

  handleChangeTitle = ({ newTitle }) => {
    const { value } = this.state;
    this.setState({
      value: {
        ...value,
        title: newTitle,
      },
    });
  };

  handleUpdateSectionTitle = ({ sectionId, newValue }) => {
    const { value } = this.state;
    this.setState({
      value: {
        ...value,
        sections: _.map(value.sections, (section) =>
          section.id === sectionId
            ? {
                ...section,
                title: newValue,
              }
            : section,
        ),
      },
    });
  };

  handleUpdateSubsectionTitle = ({ sectionId, subSectionId, newValue }) => {
    const { value } = this.state;
    this.setState({
      value: {
        ...value,
        sections: _.map(value.sections, (section) =>
          section.id === sectionId
            ? {
                ...section,
                subsections: _.map(section.subsections, (subSection) =>
                  subSection.id === subSectionId
                    ? {
                        ...subSection,
                        title: newValue,
                      }
                    : subSection,
                ),
              }
            : section,
        ),
      },
    });
  };

  handleMoveSectionDownward = ({ sectionId }) => {
    const { value } = this.state;
    const index = _.findIndex(value.sections, ({ id }) => id === sectionId);
    if (index + 1 < value.sections.length) {
      this.setState({
        value: {
          ...value,
          sections: [
            ...value.sections.slice(0, index),
            value.sections[index + 1],
            value.sections[index],
            ...value.sections.slice(index + 2),
          ],
        },
      });
    }
  };

  handleMoveSectionUpward = ({ sectionId }) => {
    const { value } = this.state;
    const index = _.findIndex(value.sections, ({ id }) => id === sectionId);
    if (index > 0) {
      this.setState({
        value: {
          ...value,
          sections: [
            ...value.sections.slice(0, index - 1),
            value.sections[index],
            value.sections[index - 1],
            ...value.sections.slice(index + 1),
          ],
        },
      });
    }
  };

  handleRemoveSection = ({ sectionId }) => {
    const { value } = this.state;
    this.setState({
      value: {
        ...value,
        sections: _.filter(value.sections, ({ id }) => id !== sectionId),
      },
    });
  };

  handleAddSection = () => {
    const { value } = this.state;
    this.setState({
      useSections: true, // to be sure
      value: {
        ...value,
        sections: [
          ...(value.sections || []),
          {
            id: getRandomString(10),
            title: '',
          },
        ],
      },
    });
  };

  handleToggleUseSections = () => {
    const { useSections, value } = this.state;
    if (useSections) {
      this.setState({ useSections: false });
    } else if (!_.isEmpty(value?.sections)) {
      this.setState({ useSections: true });
    } else {
      this.setState({
        useSections: true,
        value: {
          ...value,
          sections: [
            {
              id: getRandomString(10),
              title: '',
            },
          ],
        },
      });
    }
  };

  handleAddSubSection = ({ sectionId, newValue }) => {
    const { value } = this.state;
    this.setState({
      value: {
        ...value,
        sections: _.map(value.sections, (section) =>
          section.id === sectionId
            ? {
                ...section,
                subsections: [
                  ...(section?.subsections || []),
                  {
                    id: getRandomString(10),
                    title: newValue,
                  },
                ],
              }
            : section,
        ),
      },
    });
  };

  handleMoveSubsectionDownward = ({ sectionId, index }) => {
    const { value } = this.state;
    this.setState({
      value: {
        ...value,
        sections: _.map(value.sections, (section) => ({
          ...section,
          ...(sectionId === section.id && {
            subsections: [
              ...section.subsections.slice(0, index),
              section.subsections[index + 1],
              section.subsections[index],
              ...section.subsections.slice(index + 2),
            ],
          }),
        })),
      },
    });
  };

  handleMoveSubsectionUpward = ({ sectionId, index }) => {
    const { value } = this.state;
    if (index > 0) {
      this.setState({
        value: {
          ...value,
          sections: _.map(value.sections, (section) => ({
            ...section,
            ...(sectionId === section.id && {
              subsections: [
                ...section.subsections.slice(0, index - 1),
                section.subsections[index],
                section.subsections[index - 1],
                ...section.subsections.slice(index + 1),
              ],
            }),
          })),
        },
      });
    }
  };

  handleRemoveSubsection = ({ sectionId }) => {
    const { value } = this.state;
    this.setState({
      value: {
        ...value,
        sections: _.filter(value.sections, ({ id }) => id !== sectionId),
      },
    });
  };

  renderFormEditor() {
    const { t } = this.props;
    const { useSections, value = {} } = this.state;
    return (
      <div>
        <div className='input-container'>
          <div className='input-label'>
            {t('settings.departments.form.title')}
          </div>
          <div className='input-element'>
            <GenericTextInput
              fluid
              value={value.title || ''}
              onValue={(newTitle) => this.handleChangeTitle({ newTitle })}
              placeholder='Title'
              autoFocus
            />
          </div>
        </div>

        <div className='input-container'>
          <div className='input-label'>
            {t('settings.departments.form.sections')}
          </div>

          {_.isEmpty(value?.sections) ? (
            <div className='empty-sections-container'>
              <p className='section-empty-state-description'>
                {t('settings.departments.form.useSections')}
              </p>
              <GenericButton
                primacy='secondary'
                onClick={() =>
                  this.setState({
                    value: {
                      ...value,
                      sections: [
                        {
                          id: getRandomString(10),
                          title: '',
                        },
                      ],
                    },
                  })
                }
              >
                <Plus />
                {t('settings.departments.form.addSection')}
              </GenericButton>
            </div>
          ) : (
            <div className='sections-container'>
              {_.map(value?.sections, (section, index) => (
                <div
                  className='section-and-sub-section-container'
                  key={section.id}
                >
                  <div className='section-container'>
                    <GenericTextInput
                      fluid
                      value={section?.title || ''}
                      onValue={(newValue) =>
                        this.handleUpdateSectionTitle({
                          sectionId: section?.id,
                          newValue,
                        })
                      }
                      placeholder='Title'
                      autoFocus={!section?.title}
                    />
                    <GenericDropdown
                      position='right'
                      Trigger={({ onClick }) => (
                        <GenericButton primacy='tertiary' onClick={onClick}>
                          <Dots />
                        </GenericButton>
                      )}
                    >
                      <DropdownPanel className='dropdown-panel'>
                        {_.isEmpty(section.subsections) && (
                          <DropdownMenuItem
                            onClick={() => {
                              this.handleAddSubSection({
                                sectionId: section.id,
                                newValue: '',
                              });
                            }}
                          >
                            <span className='dropdown-menu-item-icon'>
                              <Plus />
                            </span>
                            {t('settings.departments.addSubSection')}
                          </DropdownMenuItem>
                        )}
                        {index + 1 < value.sections.length ? (
                          <DropdownMenuItem
                            onClick={() => {
                              this.handleMoveSectionDownward({
                                sectionId: section.id,
                              });
                            }}
                          >
                            <span className='dropdown-menu-item-icon'>
                              <ArrowDown />
                            </span>
                            {t('settings.departments.moveDownward')}
                          </DropdownMenuItem>
                        ) : (
                          ''
                        )}
                        {index > 0 ? (
                          <DropdownMenuItem
                            onClick={() => {
                              this.handleMoveSectionUpward({
                                sectionId: section.id,
                              });
                            }}
                          >
                            <span className='dropdown-menu-item-icon'>
                              <ArrowUp />
                            </span>
                            {t('settings.departments.moveUpward')}
                          </DropdownMenuItem>
                        ) : (
                          ''
                        )}
                        <DropdownMenuItem
                          onClick={() => {
                            this.handleRemoveSection({
                              sectionId: section.id,
                            });
                          }}
                        >
                          <span className='dropdown-menu-item-icon'>
                            <Delete />
                          </span>
                          {t('settings.departments.delete')}
                        </DropdownMenuItem>
                      </DropdownPanel>
                    </GenericDropdown>
                  </div>
                  <div className='sub-sections-container'>
                    {_.map(
                      section.subsections,
                      (subSection, subSectionIndex) => (
                        <div
                          className='sub-section-container'
                          key={subSection.id}
                        >
                          <div className='sub-section-carret' />
                          <GenericTextInput
                            fluid
                            value={subSection?.title || ''}
                            onValue={(newValue) =>
                              this.handleUpdateSubsectionTitle({
                                sectionId: section.id,
                                subSectionId: subSection.id,
                                newValue,
                              })
                            }
                            placeholder='Title'
                            autoFocus={!subSection?.title}
                          />
                          <GenericDropdown
                            position='right'
                            Trigger={({ onClick }) => (
                              <GenericButton
                                primacy='tertiary'
                                onClick={onClick}
                              >
                                <Dots />
                              </GenericButton>
                            )}
                          >
                            <DropdownPanel className='dropdown-panel'>
                              {subSectionIndex + 1 <
                              section.subsections?.length ? (
                                <DropdownMenuItem
                                  onClick={() => {
                                    this.handleMoveSubsectionDownward({
                                      sectionId: section.id,
                                      index: subSectionIndex,
                                    });
                                  }}
                                >
                                  <span className='dropdown-menu-item-icon'>
                                    <ArrowDown />
                                  </span>
                                  {t('settings.departments.moveDownward')}
                                </DropdownMenuItem>
                              ) : (
                                ''
                              )}
                              {subSectionIndex > 0 ? (
                                <DropdownMenuItem
                                  onClick={() => {
                                    this.handleMoveSubsectionUpward({
                                      sectionId: section.id,
                                      index: subSectionIndex,
                                    });
                                  }}
                                >
                                  <span className='dropdown-menu-item-icon'>
                                    <ArrowUp />
                                  </span>
                                  {t('settings.departments.moveUpward')}
                                </DropdownMenuItem>
                              ) : (
                                ''
                              )}
                              <DropdownMenuItem
                                onClick={() => {
                                  this.handleRemoveSubsection({
                                    sectionId: section.id,
                                    subSectionId: subSection.id,
                                  });
                                }}
                              >
                                <span className='dropdown-menu-item-icon'>
                                  <Delete />
                                </span>
                                {t('settings.departments.delete')}
                              </DropdownMenuItem>
                            </DropdownPanel>
                          </GenericDropdown>
                        </div>
                      ),
                    )}
                    {!_.isEmpty(section.subsections) && (
                      <span
                        className='new-sub-section'
                        onClick={() => {
                          this.handleAddSubSection({
                            sectionId: section.id,
                            newValue: '',
                          });
                        }}
                      >
                        <Plus />
                        {t('settings.departments.addSubSection')}
                      </span>
                    )}
                  </div>
                </div>
              ))}
              <GenericButton
                primacy='secondary'
                className='add-section-cutton'
                onClick={() =>
                  this.setState({
                    value: {
                      ...value,
                      sections: [
                        ...value?.sections,
                        {
                          id: getRandomString(10),
                          title: '',
                        },
                      ],
                    },
                  })
                }
              >
                <Plus />
                {t('settings.departments.form.addSection')}
              </GenericButton>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { onClose, t, mode = 'edit' } = this.props;
    const { value } = this.state;

    const disableSubmit =
      !value?.title ||
      !_.isEmpty(_.some(value?.sections, ({ title }) => !title));

    return (
      <GenericModal
        className='department-edit-modal'
        size='small'
        open
        onClose={onClose}
      >
        <Modal.Header>
          {t(`settings.departments.${mode}ModalTitle`)}
        </Modal.Header>
        <Modal.Content>{this.renderFormEditor()}</Modal.Content>
        <Modal.Actions>
          <div className='department-edit-modal-actions'>
            <GenericButton size='big' primacy='secondary' onClick={onClose}>
              {t('common.cancel')}
            </GenericButton>
            <GenericButton
              size='big'
              onClick={this.handleSubmit}
              disabled={disableSubmit}
            >
              {t(`common.${mode}`)}
            </GenericButton>
          </div>
        </Modal.Actions>
      </GenericModal>
    );
  }
}

export default DepartmentEditModal;
