import { createContext } from 'react';

interface SequenceDropdownControls {
  toggleSequenceDropdown: (() => void) | null;
}

const SequenceDropdownControlsContext = createContext<SequenceDropdownControls>(
  {
    toggleSequenceDropdown: null,
  },
);

export default SequenceDropdownControlsContext;
