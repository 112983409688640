import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

interface PinAndUnpinSharedActivityVariables {
  input: {
    profileId: string;
    sharedActivityId: string;
  };
}

interface PinSharedActivityResult {
  pinSharedActivity?: {
    errors: string[];
  };
}

interface UnpinSharedActivityResult {
  pinSharedActivity?: {
    errors: string[];
  };
}

const pinSharedActivity = gql`
  mutation PinSharedActivity($input: PinSharedActivityInput!) {
    pinSharedActivity(input: $input) {
      errors
    }
  }
`;

const unpinSharedActivity = gql`
  mutation UnpinSharedActivity($input: UnpinSharedActivityInput!) {
    unpinSharedActivity(input: $input) {
      errors
    }
  }
`;

const usePinAndUnpinSharedActivity = () => {
  const [pin] = useMutation<
    PinSharedActivityResult,
    PinAndUnpinSharedActivityVariables
  >(pinSharedActivity, {
    refetchQueries: ['getEnrichedSearchPoolProfile'],
  });
  const [unpin] = useMutation<
    UnpinSharedActivityResult,
    PinAndUnpinSharedActivityVariables
  >(unpinSharedActivity, {
    refetchQueries: ['getEnrichedSearchPoolProfile'],
  });

  return { pinSharedActivity: pin, unpinSharedActivity: unpin };
};

export default usePinAndUnpinSharedActivity;
