import _ from 'underscore';
import moment from 'moment';
import { HeatMapValue } from '@/components/LinearHeatMap';
import { sameDayNMonthsAgo } from '@/containers/Parameters/Analytics/utils';

const HIRESWEET_DATE = 'YY-MM-DD';

/**
 * from graphql schema
 */
interface DailyMissionStatistics {
  /**
   * format: YY-MM-DD
   */
  day: string;
  nbAdded: number;
}

export const computeJobStats = (
  dailyAddedProfiles: DailyMissionStatistics[],
): {
  /**
   * aggregated values by week
   */
  weeklyProfilesAdded: HeatMapValue[];
  nbProfilesAddedToday: number;
  /**
   * sum since start of current week
   */
  nbProfilesAddedWeek: number;
  /**
   * sum since start of current quarter
   */
  nbProfilesAdded3Months: number;
} => {
  const today = moment();
  const todayKey = today.format(HIRESWEET_DATE);
  const startOfWeekKey = today.startOf('week').format(HIRESWEET_DATE);
  const threeMonthsAgo = sameDayNMonthsAgo(3);

  let nbProfilesAddedToday = 0;
  let nbProfilesAddedWeek = 0;
  let nbProfilesAdded3Months = 0;

  const weeklyStatsMap: Record<string, number> = {};

  // init the weeklyStatsMap with the last 12 weeks
  const weekMoment = moment();
  for (let i = 0; i < 12; i++) {
    const weekKey = weekMoment.startOf('week').format(HIRESWEET_DATE);
    weeklyStatsMap[weekKey] = 0;
    weekMoment.subtract(1, 'week');
  }

  _.each(dailyAddedProfiles, ({ day, nbAdded }) => {
    if (day === todayKey) {
      nbProfilesAddedToday = nbAdded;
    }

    const dayMoment = moment(day, HIRESWEET_DATE);

    if (dayMoment.isSameOrAfter(threeMonthsAgo)) {
      nbProfilesAdded3Months += nbAdded;
    }

    const weekDay = dayMoment.startOf('week').format(HIRESWEET_DATE);
    sumInMap(weekDay, nbAdded, weeklyStatsMap);
  });

  nbProfilesAddedWeek = weeklyStatsMap[startOfWeekKey] || 0;

  const weeklyProfilesAdded: HeatMapValue[] = _.sortBy(
    _.map(
      weeklyStatsMap,
      (nbAdded, day): HeatMapValue => {
        const dayMoment = moment(day, HIRESWEET_DATE);
        return {
          value: nbAdded,
          dateRange: {
            startDate: dayMoment.startOf('week').toDate(),
            endDate: dayMoment.endOf('week').toDate(),
          },
        };
      },
    ),
    'dateRange.startDate',
  ).reverse();

  return {
    nbProfilesAddedToday,
    nbProfilesAddedWeek,
    nbProfilesAdded3Months,
    weeklyProfilesAdded,
  };
};

// the map must be initialized with the keys here
const sumInMap = (key: string, value: number, map: Record<string, number>) => {
  if (_.isNumber(map[key])) {
    map[key] += value;
  }
  return map;
};
