import React, { useState, useEffect, useContext } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useSearchPoolJob from '@/graphql/hooks/searchPoolJobs/useSearchPoolJob';
import { CalibrationAndRecommendationIcon } from '@/assets/icons';
import GenericButton from '@/components/Common/GenericButton';
import MissionCategoryContext from '@/context/MissionCategoryContext';

import './RecommendationPane.css';

interface RevealRecommendationPaneProps {
  searchPoolId: string;
  clientId: string;
  jobId: string;
}

const RevealRecommendationPane: React.FC<RevealRecommendationPaneProps> = ({
  searchPoolId,
  clientId,
  jobId,
}) => {
  const { t } = useTranslation();
  const { data: jobData, loading } = useSearchPoolJob(searchPoolId, jobId);
  const missionCategory = useContext(MissionCategoryContext);
  const [criteria, setCriteria] = useState({
    blackListedCompanies: '',
    collaboratorsInCharge: [],
    education: {
      bonus: null,
      important: [],
      required: null,
    },
    exampleProfiles: '',
    experience: {
      years: {
        min: -1,
        max: -1,
      },
    },
    extraKeyWords: '',
    freelance: null,
    jobPositions: {
      bonus: [],
      important: [],
      required: [],
    },
    languages: {
      bonus: [],
      important: [],
      required: [],
    },
    locations: [],
    moreInfo: null,
    onlineLinks: null,
    relevantCompanies: '',
    remote: {
      identifier: '',
      label: {
        default: '',
      },
    },
    salary: {
      currency: '',
      min: -1,
      max: -1,
    },
    skills: {
      bonus: [],
      important: [],
      required: [],
    },
  });

  const isCalibrationEmpty = (): boolean => {
    if (
      !_.isEmpty(criteria.jobPositions?.required) ||
      !_.isEmpty(criteria.skills?.required) ||
      !_.isEmpty(criteria.skills?.bonus) ||
      !_.isEmpty(criteria.locations) ||
      criteria.experience?.years?.min > 0 ||
      criteria.experience?.years?.max > 0 ||
      !_.isEmpty(criteria.remote?.identifier) ||
      !_.isEmpty(criteria.languages?.important) ||
      !_.isEmpty(criteria?.relevantCompanies)
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!loading) {
      setCriteria(jobData?.searchPool?.job?.criteria);
    }
    // eslint-disable-next-line
  }, [loading]);

  if (loading || !criteria) {
    return <></>;
  }

  return (
    <div className='recommendation-criterias'>
      <div className='title'>
        <CalibrationAndRecommendationIcon className='icon' />
        {t('reveal.missions.mission.recommendationCriterias.recommendations')}
      </div>
      <div className='settings'>
        <div className='settings-title'>
          {isCalibrationEmpty()
            ? t('reveal.missions.mission.recommendationCriterias.noSettings')
            : t(
                'reveal.missions.mission.recommendationCriterias.currentSettings',
              )}
        </div>
        <div className='tags'>
          {_.map(criteria.jobPositions?.required, (position: any) => (
            <div className='calibration-label'>{position?.label?.default}</div>
          ))}
          {_.map(criteria.skills?.required, (skill: any) => (
            <div className='calibration-label'>{skill?.label?.default}</div>
          ))}
          {_.map(criteria.skills?.important, (skill: any) => (
            <div className='calibration-label'>{skill?.label?.default}</div>
          ))}
          {_.map(criteria.skills?.bonus, (skill: any) => (
            <div className='calibration-label'>{skill?.label?.default}</div>
          ))}
          {_.map(criteria.locations, (location: any) => (
            <div className='calibration-label'>{location?.label?.default}</div>
          ))}
          {criteria.experience?.years?.min > 0 &&
          criteria.experience?.years?.max < 20 ? (
            <div className='calibration-label'>
              {criteria.experience.years.min}-{criteria.experience.years.max} xp
            </div>
          ) : criteria.experience?.years?.min > 0 ? (
            <div className='calibration-label'>
              {'>'} {criteria.experience.years.min} xp
            </div>
          ) : criteria.experience?.years?.max < 20 &&
            criteria.experience?.years?.max >= 0 ? (
            <div className='calibration-label'>
              {'<'} {criteria.experience.years.max} xp
            </div>
          ) : null}
          {!_.isEmpty(criteria.remote?.identifier) && (
            <div className='calibration-label'>
              {criteria.remote.label?.default}
            </div>
          )}
          {_.map(criteria.languages?.important, (language: any) => (
            <div className='calibration-label'>{language?.label?.default}</div>
          ))}
          {!_.isEmpty(criteria?.relevantCompanies) && (
            <div className='calibration-label'>
              {criteria.relevantCompanies}
            </div>
          )}
        </div>
        <Link
          to={`/client/${clientId}/reveal/projects/${missionCategory}/${jobId}/calibration`}
        >
          <GenericButton>
            {t('reveal.missions.mission.recommendationCriterias.calibrate')}
          </GenericButton>
        </Link>
      </div>
    </div>
  );
};

export default RevealRecommendationPane;
