import React from 'react';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';

import GenericButton from '@/components/Common/GenericButton';
import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';
import withSetPluginCalendarPermission from '../../../hocs/users/withSetPluginCalendarPermission';
import withSetPluginGmailPermission from '../../../hocs/users/withSetPluginGmailPermission';
import withSetPluginLinkedinPermission from '../../../hocs/users/withSetPluginLinkedinPermission';
import withUserFromJWToken from '../../../hocs/users/withUserFromJWToken';
import routerParamsToProps from '../../../hocs/routerParamsToProps';
import SettingsLayout from '../../SettingsLayout';

import usePluginStatuses from '../../../hooks/common/usePluginStatuses';
import { FAQLink, ChromeStoreLink } from '../../../components/Links';

import './PluginSettings.css';

const PluginSettings = ({
  clientId,
  user,
  userFromJWTokenLoading,
  setPluginGmailPermission,
  setPluginCalendarPermission,
  setPluginLinkedinPermission,
  t,
}) => {
  const { pluginDownloaded, pluginSynchronized } = usePluginStatuses(clientId);

  return (
    <SettingsLayout
      currentPage='plugin'
      clientId={clientId}
      className='settings-container plugin-settings-container'
    >
      <div className='settings plugin-settings'>
        <div className='settings-header'>
          <h1>{t('header.accountDropdownMenu.pluginSettings')}</h1>
        </div>
        <h2>{t('pluginSettings.configuration')}</h2>
        <div className='section visible'>
          <div className='download input-container'>
            <div className='input-label'>
              {t('pluginSettings.downloadTitle')}
            </div>
            {pluginDownloaded ? (
              <div className='input-description'>
                <span>{t('pluginSettings.alreadyDownloaded')}</span>
                <ChromeStoreLink>
                  {t('pluginSettings.linkHere')}
                </ChromeStoreLink>
              </div>
            ) : (
              <div>
                <ChromeStoreLink>
                  <GenericButton>
                    {t('pluginSettings.downloadButton')}
                  </GenericButton>
                </ChromeStoreLink>
              </div>
            )}
          </div>
          <div className='input-container'>
            <div className='input-label'>
              {t('pluginSettings.synchronizeTitle')}
            </div>
            {!pluginDownloaded && (
              <div className='input-description'>
                {t('pluginSettings.downloadFirst')}
              </div>
            )}
            {pluginDownloaded && pluginSynchronized && (
              <div className='input-description'>
                {t('pluginSettings.alreadySynchronized')}
              </div>
            )}
            {pluginDownloaded && !pluginSynchronized && (
              <div className='input-description'>
                <GenericButton onClick={() => window.location.reload()}>
                  {t('pluginSettings.synchronizeButton')}
                </GenericButton>
              </div>
            )}
          </div>
        </div>
        {userFromJWTokenLoading ? (
          <>
            <h2>{t('pluginSettings.pluginSettings')}</h2>
            <div className='section visible'>
              <div className='settings-placeholder'>
                <Loader inline active size='mini' />
              </div>
            </div>
          </>
        ) : (
          <SettingsSection
            permissions={user?.permissions}
            setPluginGmailPermission={setPluginGmailPermission}
            setPluginCalendarPermission={setPluginCalendarPermission}
            setPluginLinkedinPermission={setPluginLinkedinPermission}
            t={t}
          />
        )}
      </div>
    </SettingsLayout>
  );
};

const SettingsSection = ({
  permissions,
  setPluginGmailPermission,
  setPluginCalendarPermission,
  setPluginLinkedinPermission,
  t,
}) => {
  const { pluginGmail, pluginCalendar, pluginLinkedin } = permissions || {};

  return (
    <>
      <h2>{t('pluginSettings.pluginSettings')}</h2>
      <div className='section visible settings-section'>
        <div className='input-container'>
          <div className='input-label'>{t('pluginSettings.gmail.header')}</div>
          <div className='input-description'>
            <span>{t('pluginSettings.gmail.tooltip')}</span>
            <FAQLink>{t('pluginSettings.gmail.FAQLink')}</FAQLink>
          </div>
          <div className='checkbox input-element'>
            <LabeledCheckbox
              checked={!!pluginGmail}
              onClick={() => setPluginGmailPermission({ bool: !pluginGmail })}
              label={t('pluginSettings.gmail.enable')}
            />
          </div>
        </div>
        <div className='input-container'>
          <div className='input-label'>
            {t('pluginSettings.calendar.header')}
          </div>
          <div className='input-description'>
            <span>{t('pluginSettings.calendar.tooltip')}</span>
            <FAQLink>{t('pluginSettings.calendar.FAQLink')}</FAQLink>
          </div>
          <div className='checkbox input-element'>
            <LabeledCheckbox
              checked={!!pluginCalendar}
              onClick={() =>
                setPluginCalendarPermission({ bool: !pluginCalendar })
              }
              label={t('pluginSettings.calendar.enable')}
            />
          </div>
        </div>
        <div className='input-container'>
          <div className='input-label'>
            {t('pluginSettings.linkedin.header')}
          </div>
          <div className='input-description'>
            <span>{t('pluginSettings.linkedin.tooltip')}</span>
            <FAQLink>{t('pluginSettings.linkedin.FAQLink')}</FAQLink>
          </div>
          <div className='checkbox input-element'>
            <LabeledCheckbox
              checked={!!pluginLinkedin}
              onClick={() =>
                setPluginLinkedinPermission({ bool: !pluginLinkedin })
              }
              label={t('pluginSettings.linkedin.enable')}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default compose(
  routerParamsToProps,
  withTranslation('translations'),
  withUserFromJWToken,
  withSetPluginGmailPermission,
  withSetPluginCalendarPermission,
  withSetPluginLinkedinPermission,
)(PluginSettings);
