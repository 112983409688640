import React from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import useClientEnrichmentIntegrations, {
  ClientEnrichmentIntegration,
} from '@/graphql/hooks/clients/useClientEnrichmentIntegrations';

import useUserEnrichmentIntegrations, {
  UserEnrichmentIntegration,
} from '@/graphql/hooks/users/useUserEnrichmentIntegrations';
import IntegrationLink from '../Integrations/components/IntegrationLink';
import SettingsLayout from '../../SettingsLayout';

import RevealProvider from './RevealDataProviders';
import { contactOutLogo, kasprLogo, lushaLogo, rocketReachLogo } from './logos';
import PeriodQuotaSettings from './QuotaSettings/PeriodQuotaSettings';

import styles from './DataProviders.module.less';

interface ClientDataProvidersProps {
  clientId: string;
}

const ClientDataProviders: React.FC<ClientDataProvidersProps> = ({
  clientId,
}) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  // const providers: any[] = [];
  const {
    enrichmentIntegrations: clientEnrichmentIntegrations,
  } = useClientEnrichmentIntegrations();

  const { enrichmentIntegrations } = useUserEnrichmentIntegrations();

  const { applicationMode, updateApplicationMode } = useNavApplicationMode();
  const { permissions: clientPermissions } = useClientPermissions(clientId);

  if (applicationMode !== 'reveal') {
    updateApplicationMode('reveal');
  }

  const clientProviderFromType: Record<
    string,
    ClientEnrichmentIntegration
  > = _.indexBy(clientEnrichmentIntegrations || [], 'type');

  const providerFromType: Record<string, UserEnrichmentIntegration> = _.indexBy(
    _.filter(
      enrichmentIntegrations,
      ({ useClientIntegration }) => !useClientIntegration,
    ),
    'type',
  );

  return (
    <SettingsLayout
      currentPage='providers'
      clientId={clientId}
      className='providers-view settings-container'
    >
      <div
        className={classNames(
          'menu-providers',
          'settings',
          styles.providersSettings,
        )}
      >
        <Switch>
          <Route exact path='/client/:clientId/providers'>
            <>
              <div className='settings-header'>
                <h1>{t('providers.header')}</h1>
              </div>
              {clientPermissions?.canAskEmailEnrichmentCredits && (
                <>
                  <h2>{t('providers.freeCreditsTitle')}</h2>
                  <PeriodQuotaSettings
                    clientIntegration={clientProviderFromType.contactout}
                    userIntegration={_.findWhere(
                      enrichmentIntegrations as any,
                      {
                        useClientIntegration: true,
                        integrationId: clientProviderFromType.contactout?.id,
                        type: 'contactout',
                      },
                    )}
                    providerTitle='ContactOut'
                    providerType='contactout'
                    providerLogo={contactOutLogo}
                  />
                </>
              )}
              <div className={styles.section}>
                <h2>{t('providers.ownAccountsTitle')}</h2>
                <div className={styles.providersGrid}>
                  <IntegrationLink
                    title='ContactOut'
                    logo={contactOutLogo}
                    integration={providerFromType.contactout}
                    redirectLink={`${match.url}/contactout`}
                  />

                  <IntegrationLink
                    title='Kaspr'
                    logo={kasprLogo}
                    integration={providerFromType.kaspr}
                    redirectLink={`${match.url}/kaspr`}
                  />

                  <IntegrationLink
                    title='Lusha'
                    logo={lushaLogo}
                    integration={providerFromType.lusha}
                    redirectLink={`${match.url}/lusha`}
                  />

                  <IntegrationLink
                    title='RocketReach'
                    logo={rocketReachLogo}
                    integration={providerFromType.rocketreach}
                    redirectLink={`${match.url}/rocketreach`}
                  />
                </div>
              </div>
            </>
          </Route>

          <Route
            path={`${match.path}/contactout`}
            render={() => (
              <RevealProvider
                clientId={clientId}
                provider={providerFromType.contactout}
                type='contactout'
                mode='token'
              />
            )}
          />

          <Route
            path={`${match.path}/kaspr`}
            render={() => (
              <RevealProvider
                clientId={clientId}
                provider={providerFromType.kaspr}
                type='kaspr'
                mode='token'
              />
            )}
          />

          <Route
            path={`${match.path}/rocketreach`}
            render={() => (
              <RevealProvider
                clientId={clientId}
                provider={providerFromType.rocketreach}
                type='rocketreach'
                mode='token'
              />
            )}
          />

          <Route
            path={`${match.path}/lusha`}
            render={() => (
              <RevealProvider
                clientId={clientId}
                provider={providerFromType.lusha}
                type='lusha'
                mode='token'
              />
            )}
          />
        </Switch>
      </div>
    </SettingsLayout>
  );
};

export default ClientDataProviders;
