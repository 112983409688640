import React, { FC } from 'react';

import { KPIItem } from '../../helpers/kpis';

import styles from './KPICell.module.less';
import RateCellContent from './RateCellContent/RateCellContent';

interface KPICellProps {
  kpiItem: KPIItem;
}

const KPICell: FC<KPICellProps> = ({
  kpiItem: { isRate, value, rate, referenceValue, onClick },
}) => (
  <button
    type='button'
    onClick={onClick}
    className={styles.cell}
    disabled={!value || !onClick}
  >
    {isRate ? (
      <RateCellContent
        value={value}
        rate={rate || 0}
        referenceValue={referenceValue || 0}
      />
    ) : (
      value || '-'
    )}
  </button>
);

export default KPICell;
