import _ from 'underscore';
import { getOneATSDataItem } from '@/common/reveal/SourceData';

const getAtsFiltersExplanation = ({ atsFilters, profile }) => {
  const sourceData = profile?.resumeData?.sourceData || {};

  const atsData = getOneATSDataItem(sourceData);


  if (_.isEmpty(atsData) || _.isEmpty(atsFilters)) {
    return null;
  }

  const explanation = {
    // recruiter,
    // coordinator,
    // creationDate,
    // lastActivityDate,
    //
  };

  if (!_.isEmpty(atsFilters?.recruiterIds) && !_.isEmpty(atsData?.recruiter)) {
    explanation.recruiter = atsData?.recruiter;
  }

  if (!_.isEmpty(atsFilters?.coordinatorIds) && !_.isEmpty(atsData?.coordinator)) {
    explanation.coordinator = atsData?.coordinator;
  }

  if (
    atsFilters?.minCreationTimestamp ||
    atsFilters?.maxCreationTimestamp ||
    // TODO: format date differently for these (?) (e.g "Created 9 days ago")
    atsFilters?.minCreationDelayInMs ||
    atsFilters?.maxCreationDelayInMs
  ) {
    explanation.creationDate = atsData?.creationDate;
  }

  if (
    atsFilters?.minLastActionTimestamp ||
    atsFilters?.maxLastActionTimestamp ||
    // TODO: format date differently for these (?) (e.g "Last action 9 days ago")
    atsFilters?.minLastActionDelayInMs ||
    atsFilters?.maxLastActionDelayInMs
  ) {
    explanation.lastActivityDate = atsData?.lastActivityDate;
  }

  if (atsFilters?.tagIds) {
    const tagMatchesFilters = ({ sourceTagId }) => _.contains(atsFilters?.tagIds, sourceTagId);
    const matchingTags = _.filter(atsData?.tags, tagMatchesFilters);
    if (!_.isEmpty(matchingTags)) {
      explanation.tags = matchingTags;
    }
  }

  /*
  "applicationStatuses": [
    {
      "status": "active"
    },
    {
      "status": "rejected",
      "rejectionReasonTypeId": 2,
      "rejectionReasonIds": [
        11,
        15228
      ]
    },
    {
      "status": "rejected",
      "rejectionReasonTypeId": 1
    }
  ]
  */

  if (!_.isEmpty(atsFilters?.stageIds) || !_.isEmpty(atsFilters?.applicationStatuses)) {
    const matchingApplications = filterRelevantApplications({
      applications: atsData?.applications,
      stageIds: atsFilters?.stageIds,
      statuses: _.pluck(atsFilters?.applicationStatuses, 'status'),
    });

    if (!_.isEmpty(matchingApplications)) {
      explanation.applications = matchingApplications;
    }
  }

  return explanation;
};

const filterRelevantApplications = ({ applications, stageIds, statuses }) => {
  return _.filter(applications, applicationMatchesStatusOrStage({ stageIds, statuses }));
};

const applicationMatchesStatusOrStage = ({ stageIds, statuses }) => {
  return ({ status }) => {
    if (_.contains(statuses, status?.sourceStatusId)) {
      return true;
    }

    if (_.contains(stageIds, status?.stage?.sourceStageId)) {
      return true;
    }

    return false;
  };
};

export default getAtsFiltersExplanation;
