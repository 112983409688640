import React from 'react';
import { Button } from 'semantic-ui-react';

import './RadioButtonSelector.css';

export type RadioOption = {
  value: string;
  label: string;
};

export interface RadioButtonSelectorProps {
  onChange: (value: RadioOption) => void;
  options: ReadonlyArray<RadioOption>;
  defaultValue?: string;
}

export function RadioButtonSelector({
  onChange,
  options,
  defaultValue,
}: RadioButtonSelectorProps) {
  const [selected, setSelected] = React.useState(
    defaultValue || options[0].value,
  );
  const onSelect = (value: RadioOption['value']) => {
    setSelected(value);
    onChange(options.find((o) => o.value === value)!);
  };
  return (
    <Button.Group className='radio-button-selector' role='radiogroup'>
      {options.map(({ value, label }) => (
        <Button
          key={value}
          role='radio'
          size='tiny'
          onClick={() => onSelect(value)}
          className={
            selected === value ? 'rds-button-selected' : 'rds-button-default'
          }
          basic={selected === value}
          type='button'
          aria-checked={selected === value}
        >
          {label}
        </Button>
      ))}
    </Button.Group>
  );
}

export default RadioButtonSelector;
