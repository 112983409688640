import classNames from 'classnames';
import React, { FC } from 'react';
import _ from 'underscore';

import ChevronLeft from '@/components/Reveal/Icons/ChevronLeft';
import ChevronRight from '@/components/Reveal/Icons/ChevronRight';
import { getPaginationBounds } from './helpers';

import styles from './PageSelector.module.less';

interface PageSelectorProps {
  currentPageIndex: number;
  numberOfPages: number;
  onChange: (pageIndex: number) => void;
}

const PageSelector: FC<PageSelectorProps> = ({
  currentPageIndex,
  numberOfPages,
  onChange,
}) => {
  const { pageMinIndex, pageMaxIndex } = getPaginationBounds(
    currentPageIndex,
    numberOfPages,
    3,
  );

  const isFirstPage = currentPageIndex === 0;
  const isLastPage = currentPageIndex === numberOfPages - 1;

  return (
    <div className={styles.pagination}>
      <button
        type='button'
        className={classNames(styles.paginationButton, styles.previous)}
        disabled={isFirstPage}
        onClick={() => onChange(currentPageIndex - 1)}
      >
        <ChevronLeft />
      </button>
      {_.map(_.range(pageMinIndex, pageMaxIndex + 1), (pageIndex) => (
        <button
          type='button'
          className={classNames(
            styles.paginationButton,
            pageIndex === currentPageIndex && styles.active,
          )}
          key={pageIndex}
          onClick={() => onChange(pageIndex)}
        >
          {pageIndex + 1}
        </button>
      ))}
      <button
        type='button'
        className={classNames(styles.paginationButton, styles.next)}
        disabled={isLastPage}
        onClick={() => onChange(currentPageIndex + 1)}
      >
        <ChevronRight />
      </button>
    </div>
  );
};

export default PageSelector;
