import classNames from 'classnames';
import React, {
  FC,
  useLayoutEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import _ from 'underscore';
import { Input } from 'semantic-ui-react';
import SelectItemList, {
  ItemGroupType,
  SelectItemListProps,
} from './SelectItemList/SelectItemList';

import styles from './SelectItemDropdown.module.less';
import GenericDropdown, {
  GenericDropdownProps,
} from '../GenericDropdown/GenericDropdown';
import DropdownPanel from '../DropdownPanel';

export interface SelectItemDropdownProps extends SelectItemListProps {
  searchable?: boolean;
  className?: string;
  autoFocus?: boolean;
  searchPlaceholder?: string;
  emptyState: React.ReactNode;
  emptyStateWithSearch?: React.ReactNode;
  groups?: ItemGroupType[];
  totalItemCount: number;
  multiSelect?: boolean;
  selectedItemIds?: string[];
  setSelectedItemIds?: (itemIds: string[]) => void;
  onGroupGrouped?: (groupId: string, key: string | null) => void;
  onSearchChanged?: (value: string) => void;
  searchValue?: string;
  initialOpen?: boolean;
}

export type SelectItemDropdownType = SelectItemDropdownProps &
  GenericDropdownProps;

const SelectItemDropdown: FC<SelectItemDropdownType> = ({
  items = [],
  groups = [],
  onItemSelected,
  autoFocus = true,
  searchable = true,
  onGroupFiltered,
  onGroupSorted,
  onGroupGrouped,
  className,
  contentClassName,
  searchPlaceholder,
  emptyState,
  emptyStateWithSearch,
  multiSelect,
  selectedItemIds,
  onSelectAll,
  onUnselectAll,
  // We use a count to know if the empty state means "you need to create an object"
  // or if it means that the objects have been filtered
  totalItemCount,
  onDisplayStatus,
  onSearchChanged,
  searchValue,
  initialOpen = false,
  ...popupProps
}) => {
  const [open, setOpen] = useState(initialOpen);

  const inputRef = useRef<Input>(null);
  useLayoutEffect(() => {
    if (inputRef.current && open) {
      inputRef.current.focus();
    }
  }, [autoFocus, open, inputRef]);

  const updateSelectedItem = useCallback(
    (item) => {
      if (_.includes(selectedItemIds || [], item.id)) {
        onItemSelected(item, false);
      } else {
        onItemSelected(item, true);
      }
      if (!multiSelect) {
        setOpen(false);
      }
    },
    [onItemSelected, multiSelect, selectedItemIds],
  );

  const itemsWithoutGroups = useMemo(() => {
    const itemsWithoutParent = [...items];
    _.each(groups, ({ items: groupItems }) => {
      itemsWithoutParent.push(...groupItems);
    });

    return itemsWithoutParent;
  }, [items, groups]);

  return (
    <GenericDropdown
      className={classNames(styles.selectItemDropdown, className)}
      contentClassName={classNames(
        styles.selectItemDropdownContent,
        contentClassName,
      )}
      onDisplayStatus={(value) => {
        setOpen(value);
        if (onDisplayStatus) onDisplayStatus(value);
        if (value === false && onSearchChanged && searchValue !== '') {
          onSearchChanged('');
        }
      }}
      initialOpen={initialOpen}
      {...popupProps}
    >
      <DropdownPanel className={styles.panel}>
        {searchable && (
          <div className={styles.searchBar}>
            <i className='ri-search-line' />
            <Input
              fluid
              value={searchValue}
              ref={inputRef}
              placeholder={searchPlaceholder}
              onChange={(_e, { value }) => {
                if (onSearchChanged) {
                  onSearchChanged(value);
                }
              }}
            />
          </div>
        )}
        <div style={{ minHeight: '0px', position: 'relative' }}>
          {totalItemCount === 0 && emptyState}
          {totalItemCount > 0 &&
            (_.isEmpty(itemsWithoutGroups) && !_.isEmpty(searchValue) ? (
              emptyStateWithSearch
            ) : (
              <SelectItemList
                groups={groups}
                items={items}
                onItemSelected={updateSelectedItem}
                onGroupFiltered={onGroupFiltered}
                onGroupSorted={onGroupSorted}
                onGroupGrouped={onGroupGrouped}
                multiSelect={multiSelect}
                selectedItemIds={selectedItemIds}
                onSelectAll={onSelectAll}
                onUnselectAll={onUnselectAll}
                searchValue={searchValue}
              />
            ))}
        </div>
      </DropdownPanel>
    </GenericDropdown>
  );
};

export default SelectItemDropdown;
