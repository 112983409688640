import React from 'react';

const LinkEntity = (props) => {
  const {url} = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a target='_blank' rel='noopener noreferrer' href={url}>
      {props.children}
    </a>
  );
};

export default LinkEntity;
