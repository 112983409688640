/* eslint-disable max-len */

import React, { FC, useMemo, useState } from 'react';
import _ from 'underscore';

import useSearchPoolMiniResults from '@/graphql/hooks/searchPool/useSearchPoolMiniResults';
import { getFirstProfilesAndRemainder } from '@/containers/Profile/EmptyState/helpers';
import useSearchProfilesWithAvatar from '@/graphql/hooks/searchPool/useSearchProfilesWithAvatar';
import useUserMarketplaceProfileDisplayPreferences from '@/graphql/hooks/users/UserProfileDisplayPreferences';
import { sanitizeTypename } from '@/common/utils/apollo';

interface AllCandidatesIllustrationProps {
  className?: string;
}

const AllCandidatesIllustration: FC<AllCandidatesIllustrationProps> = ({
  className,
}) => {
  const { searchPoolMiniResults } = useSearchPoolMiniResults({
    searchPoolId: 'watch',
  });
  const profileIds = useMemo(() => searchPoolMiniResults[0]?.results || [], [
    searchPoolMiniResults,
  ]);
  const firstResults = useMemo(() => sanitizeTypename(_.first(profileIds, 5)), [
    profileIds,
  ]);
  const { searchProfilesWithAvatar } = useSearchProfilesWithAvatar({
    searchPoolId: 'watch',
    searchResultItemsInput: firstResults,
  });
  const { firstProfiles } = getFirstProfilesAndRemainder(
    searchProfilesWithAvatar,
    3,
  );
  const {
    userMarketplaceProfileDisplayPreferences,
    loading,
  } = useUserMarketplaceProfileDisplayPreferences();
  const { hidePicture } = userMarketplaceProfileDisplayPreferences;
  const [failedFetch, setFailedFetch] = useState<Record<number, boolean>>({
    0: false,
    1: false,
    2: false,
  });

  const avatarUrls = useMemo(() => {
    const result = [
      '/images/defaultAvatars/default-avatar-1.svg',
      '/images/defaultAvatars/default-avatar-2.svg',
      '/images/defaultAvatars/default-avatar-3.svg',
    ];

    if (loading || hidePicture) {
      return result;
    }

    _.each(firstProfiles, ({ resumeData: { photoLink } }, index) => {
      if (!photoLink || failedFetch[index]) {
        return;
      }
      result[index] = photoLink;
    });

    return result;
  }, [failedFetch, firstProfiles, loading, hidePicture]);

  return (
    <>
      <svg
        className={className}
        viewBox='0 0 84 84'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <rect width='84' height='84' rx='8' fill='#F1F6FF' />
        <rect x='40' width='44' height='84' fill='url(#paint0_linear_9_1541)' />
        <g filter='url(#filter0_d_9_1541)'>
          <rect
            x='10'
            y='10'
            width='30'
            height='30'
            rx='15'
            fill='url(#aci_pattern0)'
          />
          <rect
            x='11'
            y='11'
            width='28'
            height='28'
            rx='14'
            stroke='white'
            strokeWidth='2'
            fill='none'
          />
        </g>
        <g filter='url(#filter1_d_9_1541)'>
          <rect
            x='54'
            y='17'
            width='20'
            height='20'
            rx='10'
            fill='url(#aci_pattern1)'
          />
          <rect
            x='55'
            y='18'
            width='18'
            height='18'
            rx='9'
            stroke='white'
            strokeWidth='2'
            fill='none'
          />
        </g>
        <g filter='url(#filter2_d_9_1541)'>
          <rect
            x='32'
            y='39'
            width='36'
            height='36'
            rx='18'
            fill='url(#aci_pattern2)'
          />
          <rect
            x='33'
            y='40'
            width='34'
            height='34'
            rx='17'
            stroke='white'
            strokeWidth='2'
            fill='none'
          />
        </g>
        <circle
          cx='17.375'
          cy='55.375'
          r='5'
          stroke='#4864C9'
          strokeWidth='1.25'
          fill='none'
        />
        <path
          d='M21.125 59.125L23.625 61.625'
          stroke='#4864C9'
          strokeWidth='1.25'
          strokeLinecap='round'
        />
        <defs>
          <filter
            id='filter0_d_9_1541'
            x='6'
            y='8'
            width='38'
            height='38'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='2' />
            <feGaussianBlur stdDeviation='2' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.121569 0 0 0 0 0.180392 0 0 0 0 0.466667 0 0 0 0.06 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_9_1541'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_9_1541'
              result='shape'
            />
          </filter>
          <pattern
            id='aci_pattern0'
            patternContentUnits='objectBoundingBox'
            width='1'
            height='1'
          >
            <use href='#avatar_1' transform='scale(0.005)' />
          </pattern>
          <filter
            id='filter1_d_9_1541'
            x='50'
            y='15'
            width='28'
            height='28'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='2' />
            <feGaussianBlur stdDeviation='2' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.121569 0 0 0 0 0.180392 0 0 0 0 0.466667 0 0 0 0.06 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_9_1541'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_9_1541'
              result='shape'
            />
          </filter>
          <pattern
            id='aci_pattern1'
            patternContentUnits='objectBoundingBox'
            width='1'
            height='1'
          >
            <use href='#avatar_2' transform='scale(0.005)' />
          </pattern>
          <filter
            id='filter2_d_9_1541'
            x='28'
            y='37'
            width='44'
            height='44'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='2' />
            <feGaussianBlur stdDeviation='2' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.121569 0 0 0 0 0.180392 0 0 0 0 0.466667 0 0 0 0.06 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_9_1541'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_9_1541'
              result='shape'
            />
          </filter>
          <pattern
            id='aci_pattern2'
            patternContentUnits='objectBoundingBox'
            width='1'
            height='1'
          >
            <use href='#avatar_0' transform='scale(0.005)' />
          </pattern>
          <linearGradient
            id='paint0_linear_9_1541'
            x1='84'
            y1='35.5'
            x2='40.0042'
            y2='36.2468'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='white' />
            <stop offset='1' stopColor='white' stopOpacity='0' />
          </linearGradient>
          <image id='avatar_0' width='200' height='200' href={avatarUrls[0]} />
          <image id='avatar_1' width='200' height='200' href={avatarUrls[1]} />
          <image id='avatar_2' width='200' height='200' href={avatarUrls[2]} />
        </defs>
      </svg>
      {_.map(avatarUrls, (avatarUrl, index) => (
        <img
          key={`avatar${index}`}
          style={{ display: 'none' }}
          alt='Hidden avatar'
          src={avatarUrl}
          onError={() =>
            setFailedFetch((current) => ({
              ...current,
              [index]: true,
            }))
          }
        />
      ))}
    </>
  );
};

export default AllCandidatesIllustration;
