/* eslint-disable max-len */

import React, { FC } from 'react';

import styles from './svg.module.less';

const BoardIcon: FC = () => (
  <svg
    className={styles.svgIcon}
    width='1em'
    height='1em'
    viewBox='0 0 16 16'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect y='1' width='7' height='14' rx='2' fill='currentColor' />
    <rect x='9' y='1' width='7' height='9' rx='2' fill='currentColor' />
  </svg>
);

export default BoardIcon;
