import * as Sentry from '@sentry/browser';
import React from 'react';
import { compose } from 'underscore';

import ProfileStepButton from '../ProfileStepButton';
import RestoreModal from '../../modals/restoreModal';
import { withLastProfileActionsContextConsumer } from '../../../../context/LastProfileActionsContext';
import withSkipProfile from '../../../../hocs/profiles/withSkipProfile';
import NewProfileSkipButton from '../NewProfileSkipButton';

const StepModificationView = ({
  resumeData,
  offerId,
  offer,
  profileId,
  getNextProfileId,
  onChangeProfile,
  onChangeStep,
  getProfilesInMove,
  handleMarkProfileAsInMove,
  handleUnmarkProfileAsInMove,
  stepId,
  searchText,
  clientId,
  skipProfile,
  addProfileAction,
}) => {
  const skipProfileHelper = async ({ skipReasonText, onSkip }) => {
    handleMarkProfileAsInMove({ profileId });

    const nextProfileId = getNextProfileId({
      forbiddenProfileIds: getProfilesInMove(),
    });

    if (nextProfileId) {
      await onChangeProfile(nextProfileId);
    }

    if (!nextProfileId) {
      // console.log(`after successful skip, change step ${stepId}`);
      const forbiddenProfileId = profileId;
      await onChangeStep({
        newStepId: 'pending',
        forceRefresh: true,
        forbiddenProfileId,
        originalProfileId: profileId,
      });
      // console.log('changed');
    }

    try {
      await skipProfile({
        id: profileId,
        ...(skipReasonText && { skipReason: skipReasonText }),
        oldStep: resumeData.step,
        offerId,
      });

      const lastProfileAction = {
        clientId,
        jobOfferId: offerId,
        profileId,
        stepId,
        type: 'skip-profile',
      };

      addProfileAction(lastProfileAction);
      handleUnmarkProfileAsInMove({ profileId });

      if (onSkip) {
        onSkip();
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  if (!resumeData || !onChangeStep) {
    return null;
  }

  const StepButton = () => (
    <ProfileStepButton
      clientId={clientId}
      step={resumeData.step}
      offerId={offerId}
      profileId={profileId}
      getNextProfileId={getNextProfileId}
      getProfilesInMove={getProfilesInMove}
      onChangeProfile={onChangeProfile}
      onChangeStep={onChangeStep}
      handleMarkProfileAsInMove={handleMarkProfileAsInMove}
      handleUnmarkProfileAsInMove={handleUnmarkProfileAsInMove}
      stepId={stepId}
      searchText={searchText}
    />
  );

  return (
    <div className='step-modification-view'>
      {resumeData.step === 'pending' ? (
        <>
          <NewProfileSkipButton skipProfileHelper={skipProfileHelper} />
          {offer && offer.manualStepsAllowed && <StepButton />}
        </>
      ) : resumeData.step !== 'skipped' ? (
        <StepButton />
      ) : (
        <RestoreModal
          clientId={clientId}
          offerId={offerId}
          profileId={profileId}
          getNextProfileId={getNextProfileId}
          getProfilesInMove={getProfilesInMove}
          onChangeProfile={onChangeProfile}
          onChangeStep={onChangeStep}
          handleMarkProfileAsInMove={handleMarkProfileAsInMove}
          handleUnmarkProfileAsInMove={handleUnmarkProfileAsInMove}
          stepId={stepId}
          searchText={searchText}
        />
      )}
    </div>
  );
};

export default compose(
  withSkipProfile,
  withLastProfileActionsContextConsumer,
)(StepModificationView);
