import _ from 'underscore';
import { TFunction } from 'i18next';
import { NumberRecord, Stats } from '@/types/statistics/stats';
import { StackItem } from '@/types/statistics/stackItem';
import { getPercentage, percentageToString } from '../utils/number';
import { getRecordTotal } from '../utils/record';

export const getTotalCount = (stats: Stats): number =>
  getRecordTotal(stats.values);

export const getMaxTotalCount = (allStats: Stats[]): number =>
  _.chain(allStats)
    .map(getTotalCount)
    .concat([0])
    .max()
    .value();

export const getMaxCount = (stats: Stats): number => _.max(stats.values);

export const getGlobalMaxCount = (allStats: Stats[]): number =>
  _.chain(allStats)
    .map(getMaxCount)
    .max()
    .value();

const DIVIDERS = [4, 20, 100];

const getDivider = (maxCount: number): number =>
  _.reduce(
    DIVIDERS,
    (previous, current) => (maxCount >= 2 * current ? current : previous),
    1,
  );

const getUpperBound = (maxCount: number): number => {
  const divider = getDivider(maxCount);
  return divider * (Math.floor(maxCount / divider) + 1);
};

export const getTicks = (maxCount: number, divider = 2): number[] => {
  const upperBound = getUpperBound(maxCount);
  return _.map(_.range(divider + 1), (i) => (i * upperBound) / divider);
};

export const getDisplayedValue = (t: TFunction, isPercentage?: boolean) => (
  stats: Stats,
  datakey: string,
): string => {
  const value = stats.values[datakey] || 0;
  if (!isPercentage) {
    return `${value}`;
  }
  return percentageToString(t)(getPercentage(value, getTotalCount(stats), 1));
};

export const getStackName = (t: TFunction, item: StackItem): string => {
  const { datakey, i18nKey, displayedText } = item;
  return i18nKey ? t(i18nKey) : displayedText || datakey;
};

export const keyInStack = (stack: StackItem[], key: string): boolean =>
  _.some(stack, (stackItem) => key === stackItem.datakey);

export const filterStack = (
  stack: StackItem[],
  allStats: Stats[],
): StackItem[] =>
  _.filter(stack, (stackItem) =>
    _.some(
      allStats,
      (stats) =>
        _.has(stats?.values, stackItem.datakey) &&
        stats.values[stackItem.datakey] !== 0,
    ),
  );

export const sumNumberRecords = <T extends NumberRecord>(a: T, b: T): T =>
  _.reduce(
    b,
    (result, value, key) => ({
      ...result,
      [key]: (result[key] || 0) + value,
    }),
    a,
  );
