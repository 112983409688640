import React from 'react';
import ProfileDimmer from '@/components/Dimmer/ProfileDimmer';
import ReviewProfile from '../ReviewProfile';

import './ReviewModal.css';

const ReviewModal = ({
  open,
  onClose,
  onSend,
  profileActions,
  profileIdHandler,
  onChangeProfileStep,
}) => {
  if (!open) {
    return null;
  }

  return (
    <div className='overflow-modal'>
      <div className='profile-preview-container marketplace-profile'>
        <ProfileDimmer
          previousProfileId={profileIdHandler.prev}
          nextProfileId={profileIdHandler.next}
          onSelectProfileId={profileIdHandler.onSelectProfileId}
          onClose={onClose}
        />
        <ReviewProfile
          profileId={profileIdHandler.current}
          profileActions={profileActions}
          onSend={onSend}
          onChangeProfileStep={onChangeProfileStep}
        />
      </div>
    </div>
  );
};

export default ReviewModal;
