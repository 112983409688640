import React, { useEffect, useState } from 'react';
import _, { compose } from 'underscore';
import { Button, Checkbox, Input, Modal } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

import { locationsAndRemoteFromRaw } from '@/common/helpers/locationAndRemote';
import logAction from '../../../common/logAction';
import skillOptions from '../../../common/options/skillOptions.json';
import contractOptions from '../../../common/options/contractOptions.json';
import jobPositionOptions from '../../../common/options/jobPositionOptions.json';

import { SaveIcon } from '../../../assets/icons';

import withClientCriteriaOptions from '../../../hocs/clients/withClientCriteriaOptions';
import withSaveSearch from '../../../hocs/clients/withSaveSearch';
import contextToProps from '../../../hocs/contextToProps';
import withAddOffer from '../../../hocs/offers/withAddOffer';

import {
  getLabelToValue,
  getNewOfferCriteria,
  getOfferCriteria,
  getOptions,
  removeUnwantedFields,
  MIN_XP,
  MAX_XP,
} from '../../../containers/Parameters/OfferCriteria/helpers';
import { AND_OPERATOR } from '../WatchCollectFiltersModal/OperatorToggle';
import { getLocationsAndRemoteOptions } from '../helpers';

import './SaveSearchModal.css';

const SaveSearchModal = ({
  clientId,
  search,
  searchPoolId,
  saveSearch,
  selectedSearchIds,
  onShowNotification,
  criteriaOptions,
  addOffer,
  user,
  t,
}) => {
  const [open, setModalOpen] = useState(false);
  const [searchName, setSearchName] = useState(
    defaultSearchName({ search, t }),
  );
  const [checked, setChecked] = useState(false);

  const {
    locationsAndRemote,
    schoolTypes,
    jobPositions,
    jobPositionsOperator,
    skills,
    skillsOperator,
    salaryMin,
    salaryMax,
    experienceYearsMin,
    experienceYearsMax,
    contracts,
  } = search?.criteria || {};

  const locationsAndRemoteValues = _.map(
    locationsAndRemoteFromRaw(locationsAndRemote),
    ({ locationId, remotePolicy }) => `${locationId}__${remotePolicy}`,
  );
  const locationAndRemoteOptions = getLocationsAndRemoteOptions(t);

  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    setSearchName(defaultSearchName({ search, t }));
  }, [search, t]);

  const onSaveSearch = async () => {
    try {
      await saveSearch({ searchPoolId, name: searchName, search });
    } catch (e) {
      console.error('onSaveSearch failed', e);
    }
  };

  const onSaveJob = async () => {
    try {
      const offerCriteria = getOfferCriteriaFromSearch({
        search,
        criteriaOptions,
      });
      const input = { title: searchName, criteria: offerCriteria };
      await addOffer({ clientId, input });

      onShowNotification({
        message: t(
          'watchCollect.results.saveSearchModal.notifications.jobCreated',
        ),
        level: 'success',
      });
    } catch (e) {
      console.error('onSaveJob failed', e);

      onShowNotification({
        message: t(
          'watchCollect.results.saveSearchModal.notifications.jobCreationFailed',
        ),
        level: 'error',
      });
    }
  };

  const onSubmit = async () => {
    if (checked) {
      onSaveJob();
    } else {
      onSaveSearch();
    }
    closeModal();
  };

  const modalTrigger = (
    <Button
      className='save-search-modal-trigger'
      primary
      type='button'
      disabled={(selectedSearchIds || []).length > 1}
      onClick={() => {
        setModalOpen(true);
        logAction({
          type: 'discover-open-save-search-modal',
          info: {
            clientId,
            author: `${user?.firstname} ${user?.lastname}`,
          },
        });
      }}
    >
      <SaveIcon className='icon save-icon' />
      {t('watchCollect.results.saveSearchModal.trigger')}
    </Button>
  );

  const formattedJobPositions = formatJobPositions({ jobPositions });
  const formattedLocations = formatLocations(
    locationAndRemoteOptions,
    locationsAndRemoteValues,
  );
  const formattedSchoolTypes = formatSchoolTypes({ schoolTypes, t });
  const formattedSkills = formatSkills({ skills });
  const formattedContracts = formatContracts({ contracts, t });

  return (
    <Modal
      className='save-search-modal'
      open={open}
      onClose={closeModal}
      trigger={modalTrigger}
      closeIcon
    >
      <Modal.Header>
        {t('watchCollect.results.saveSearchModal.title')}
      </Modal.Header>
      <Modal.Content>
        <div className='input-container'>
          <div className='criteria-section'>
            <div className='input-label'>
              {t('watchCollect.results.saveSearchModal.criteria.header')}
            </div>

            <div className='search-criteria'>
              <div className='criteria-label'>
                {t(
                  'watchCollect.results.saveSearchModal.criteria.jobPosition',
                  { count: (jobPositions || []).length },
                )}
              </div>
              <span>
                {formattedJobPositions.join(
                  getOperatorStr(jobPositionsOperator),
                ) || '-'}
              </span>
            </div>

            <div className='search-criteria'>
              <div className='criteria-label'>
                {t(
                  'watchCollect.results.saveSearchModal.criteria.experienceYears',
                )}
              </div>
              <span>
                {experienceYearsMin || experienceYearsMax
                  ? t(
                      'watchCollect.results.saveSearchModal.criteria.experienceYearsRange',
                      {
                        minimum: Math.floor(experienceYearsMin) || MIN_XP,
                        maximum: Math.floor(experienceYearsMax) || MAX_XP,
                      },
                    )
                  : '-'}
              </span>
            </div>

            <div className='search-criteria'>
              <div className='criteria-label'>
                {t('watchCollect.results.saveSearchModal.criteria.skill', {
                  count: (skills || []).length,
                })}
              </div>
              <span>
                {formattedSkills.join(getOperatorStr(skillsOperator)) || '-'}
              </span>
            </div>
          </div>

          <div className='search-criteria'>
            <div className='criteria-label'>
              {t('watchCollect.results.saveSearchModal.criteria.location', {
                count: (locationsAndRemoteValues || []).length,
              })}
            </div>
            <span>{formattedLocations.join(', ') || '-'}</span>
          </div>

          <div className='search-criteria'>
            <div className='criteria-label'>
              {t('watchCollect.results.saveSearchModal.criteria.schoolType', {
                count: (schoolTypes || []).length,
              })}
            </div>
            <span>{formattedSchoolTypes.join(', ') || '-'}</span>
          </div>

          <div className='search-criteria'>
            <div className='criteria-label'>
              {t('watchCollect.results.saveSearchModal.criteria.salary')}
            </div>
            <span>
              {_.isNumber(salaryMin) || _.isNumber(salaryMax)
                ? t(
                    'watchCollect.results.saveSearchModal.criteria.salaryRange',
                    {
                      minimum: Math.floor(salaryMin || 30),
                      maximum: Math.floor(salaryMax || 150),
                    },
                  )
                : '-'}
            </span>
          </div>

          <div className='search-criteria'>
            <div className='criteria-label'>
              {t('watchCollect.results.saveSearchModal.criteria.contracts', {
                count: (contracts || []).length,
              })}
            </div>
            <span>{formattedContracts.join(', ') || '-'}</span>
          </div>
        </div>

        <div className='input-container'>
          <div className='input-label'>
            {t('watchCollect.results.saveSearchModal.searchName')}
          </div>
          <div className='input-element'>
            <Input
              className='name-input'
              type='text'
              name='Search name'
              value={searchName}
              onChange={(e, { value }) => setSearchName(value)}
              fluid
            />
          </div>
        </div>

        <div className='input-container'>
          <div className='input-element'>
            <Checkbox
              className='create-associated-job-input'
              checked={checked}
              onChange={() => setChecked((oldChecked) => !oldChecked)}
              label={t('watchCollect.results.saveSearchModal.checkboxLabel')}
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className='align-left'>
          <Button
            type='button'
            className='dismiss'
            content={t('watchCollect.results.saveSearchModal.cancel')}
            onClick={closeModal}
          />
        </div>
        <Button
          type='button'
          primary
          size='big'
          content={t('watchCollect.results.saveSearchModal.save')}
          onClick={onSubmit}
          disabled={!searchName}
        />
      </Modal.Actions>
    </Modal>
  );
};

const getOptionText = ({ value, options }) => {
  return _.findWhere(options, { value })?.text || '';
};

const translateOptions = ({ key, options, t }) => {
  const getText = (option) => {
    return t(
      `watchCollect.filters.${key}.options.${option?.value}` || option?.text,
    );
  };
  return _.map(options, (option) => ({ ...option, text: getText(option) }));
};

const formatJobPositions = ({ jobPositions }) => {
  return _.map(jobPositions, (job) =>
    getOptionText({ value: job, options: jobPositionOptions }),
  );
};

const formatLocations = (options, locations) => {
  return _.map(locations, (value) => getOptionText({ value, options }));
};

const formatSchoolTypes = ({ schoolTypes, t }) => {
  const options = _.map(schoolTypes, (schoolType) => ({
    value: schoolType,
    text: t(`watchCollect.filters.schoolTypes.options.${schoolType}`),
  }));
  return _.map(schoolTypes, (schoolType) =>
    getOptionText({
      value: schoolType,
      options,
    }),
  );
};

const formatSkills = ({ skills }) => {
  return _.map(skills, (skill) =>
    getOptionText({ value: skill, options: skillOptions }),
  );
};

const formatContracts = ({ contracts, t }) => {
  const translatedOptions = translateOptions({
    key: 'contracts',
    options: contractOptions,
    t,
  });
  return _.map(contracts, (contract) =>
    getOptionText({ value: contract, options: translatedOptions }),
  );
};

const defaultSearchName = ({ search, t }) => {
  const {
    locationsAndRemote,
    schoolTypes,
    jobPositions,
    jobPositionsOperator,
    skills,
    skillsOperator,
    experienceYearsMin,
    experienceYearsMax,
  } = search?.criteria || {};
  const locationsAndRemoteValues = _.map(
    locationsAndRemoteFromRaw(locationsAndRemote),
    ({ locationId, remotePolicy }) => `${locationId}__${remotePolicy}`,
  );
  const locationAndRemoteOptions = getLocationsAndRemoteOptions(t);
  const formattedJobPositions = formatJobPositions({ jobPositions });
  const formattedLocations = formatLocations(
    locationAndRemoteOptions,
    locationsAndRemoteValues,
  );
  const formattedSchoolTypes = formatSchoolTypes({ schoolTypes, t });
  const formattedSkills = formatSkills({ skills });

  const jobOperatorStr = getOperatorStr(jobPositionsOperator);
  const jobPart = formattedJobPositions.slice(0, 2).join(jobOperatorStr);
  const locationPart = formattedLocations[0];
  const schoolTypePart = formattedSchoolTypes[0];
  const experiencePart =
    experienceYearsMin || experienceYearsMax
      ? [experienceYearsMin || MIN_XP, experienceYearsMax || MAX_XP].join('-')
      : null;

  const skillsOperatorStr = getOperatorStr(skillsOperator);
  const skillsPart = formattedSkills.slice(0, 3).join(skillsOperatorStr);
  // `{{jobPosition1}} & {{jobPosition2}} - {{Location}} - {{minXp}}-{{maxXp}} - {{skill1}} & {{skill2}} & {{skill3}}`;
  const parts = _.compact([jobPart, locationPart, experiencePart, skillsPart]);
  if (parts.length <= 1 && schoolTypePart) {
    parts.push(schoolTypePart);
  }
  return parts.join(' - ');
};

const getOfferCriteriaFromSearch = ({ search, criteriaOptions }) => {
  const cleanCriteriaOptions = removeUnwantedFields(criteriaOptions) || {};
  const labelToValue = getLabelToValue(removeUnwantedFields(criteriaOptions));
  const options = getOptions(cleanCriteriaOptions);
  // legacy step introduced because of PureCriteriaForm initialization, see CreateJobFromSearchModal
  const initialValues = getOfferCriteria({
    searchCriteria: search?.criteria,
    options,
  });
  return getNewOfferCriteria({ initialValues, labelToValue });
};

// implicit: default is OR
const getOperatorStr = (operator) => (operator === AND_OPERATOR ? ' & ' : ', ');

export default compose(
  withTranslation('translations'),
  contextToProps,
  withSaveSearch,
  withAddOffer,
  withClientCriteriaOptions,
)(SaveSearchModal);
