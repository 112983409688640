import React from 'react';
import _ from 'underscore';

import classnames from 'classnames';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import GenericPopup from '@/components/Common/GenericPopup';
import Help from '@/components/Reveal/Icons/Help';
import styles from './HiresweetDetails.module.less';

interface ProfileHiresweetDetailsProps {
  details: any;
}

// const pascalCaseToNormalString = (str: string) => {
//   const words = [];
//   const splittedStr = str.split('');
//   let word = '';
//   for (let i = 0; i < splittedStr.length; i++) {
//     if (i !== 0 && splittedStr[i] === splittedStr[i].toUpperCase()) {
//       words.push(word);
//       word = '';
//     }
//     word += splittedStr[i];
//     if (i === splittedStr.length - 1) {
//       words.push(word);
//       return words.join(' ');
//     }
//   }
//   return words.join(' ');
// };

const ProfileHiresweetDetails: React.FC<ProfileHiresweetDetailsProps> = ({
  details,
}) => {
  const isPlugin = useIsPlugin();

  if (!details) {
    return null;
  }

  const {
    smartHunt,
    matchingOffers,
    modelScores,
    tags,
    marketplaceHistory,
  } = details;

  const smartHuntColumns = _.chunk(
    _.filter(
      smartHunt?.items || [],
      (item) =>
        !['null', 'unknown', 'undefined', '', null, undefined].includes(
          item.value,
        ),
    ),
    5,
  );

  const modelScoresColumns = _.chunk(
    _.filter(
      modelScores?.items || [],
      (item) =>
        !['null', 'unknown', 'undefined', '', null, undefined].includes(
          item.value,
        ),
    ),
    5,
  );

  const marketplaceHistoryColumns = _.chunk(
    _.filter(
      marketplaceHistory?.items || [],
      (item) =>
        !['null', 'unknown', 'undefined', '', null, undefined].includes(
          item.value,
        ),
    ),
    5,
  );

  const sortedOffers = [...(matchingOffers?.items || [])].sort(
    (a: any, b: any) => {
      const ascore = parseFloat(a.score[0].value);
      const bscore = parseFloat(b.score[0].value);
      return bscore - ascore;
    },
  );

  return (
    <div
      className={classnames(
        styles.hiresweetDetailsContainer,
        isPlugin ? styles.plugin : '',
      )}
    >
      <ProfileHiresweetDetailsKeyValueList
        category='Marketplace History'
        columnsList={marketplaceHistoryColumns}
        isPlugin={isPlugin}
      />
      <ProfileHiresweetDetailsMatchingOffers
        offers={sortedOffers}
        isPlugin={isPlugin}
      />
      <ProfileHiresweetDetailsKeyValueList
        category='SmartHunt'
        columnsList={smartHuntColumns}
        isPlugin={isPlugin}
      />
      <ProfileHiresweetDetailsKeyValueList
        category='Model scores'
        columnsList={modelScoresColumns}
        isPlugin={isPlugin}
      />

      <div className={styles.detailsCategory}>
        {_.map(tags || [], (tag) => {
          <p>{tag.name.default}</p>;
        })}
      </div>
    </div>
  );
};

interface ProfileHiresweetDetailsKeyValueListProps {
  category: string;
  columnsList: any[];
  isPlugin: boolean;
}

const ProfileHiresweetDetailsKeyValueList: React.FC<ProfileHiresweetDetailsKeyValueListProps> = ({
  category,
  columnsList,
  isPlugin,
}) => {
  return (
    <div className={styles.detailsCategory}>
      <h4>{category}</h4>
      <div
        className={classnames(
          styles.categoryColumns,
          isPlugin ? styles.plugin : '',
        )}
      >
        {_.map(columnsList, (column, index) => (
          <div className={styles.categoryItems} key={index}>
            {_.map(column || [], (item) => (
              <div
                className={styles.categoryItem}
                key={`${category.replace(' ', '_')}-${item.key}`}
              >
                <span className={styles.itemName}>{item.key}</span>
                <span
                  className={classnames(
                    styles.itemValue,
                    'pill-message mini blue',
                  )}
                >
                  {item.value}
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

interface ProfileHiresweetDetailsMatchingOffersProps {
  offers: any[];
  isPlugin: boolean;
}

const ProfileHiresweetDetailsMatchingOffers: React.FC<ProfileHiresweetDetailsMatchingOffersProps> = ({
  offers,
  isPlugin,
}) => {
  return (
    <div className={styles.detailsCategory}>
      <h4>Matching Offers</h4>
      {isPlugin ? (
        <ProfileHiresweetDetailsMatchingOffersPlugin offers={offers} />
      ) : (
        <ProfileHiresweetDetailsMatchingOffersWeb offers={offers} />
      )}
    </div>
  );
};

const ProfileHiresweetDetailsMatchingOffersWeb: React.FC<Partial<
  ProfileHiresweetDetailsMatchingOffersProps
>> = ({ offers }) => {
  return (
    <table>
      <thead>
        <tr>
          <th className={styles.tableHeader}>Offer</th>
          <th className={styles.tableHeader}>Criteria</th>
          <th className={styles.tableHeader}>Score</th>
          <th className={styles.tableHeader}>Activity</th>
        </tr>
      </thead>
      <tbody>
        {_.map(offers || [], (offer) => (
          <tr key={offer.offerId}>
            <td className={styles.tableCell}>
              <a target='_blank' rel='noreferrer' href={offer.url}>{offer.title}</a>
            </td>
            <td className={styles.tableCell}>
              {getTextFromOfferCriteria(offer.criteria)}
            </td>
            <td className={styles.tableCell}>
              {offer?.score?.[0]?.key}: {offer?.score?.[0]?.value}
            </td>
            <td className={styles.tableCell}>
              {_.map(
                offer.activity,
                (activity) => activity.key === 'ActiveProfilesInCat' ? '' : `${activity.key}: ${activity.value}`,
              ).join(' ')}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const jobTitleById = {
  "full-stack": "Fullstack",
  "web-backend": "Back-End",
  "web-frontend": "Front-End",
  "lead-dev" : "Lead Dev",
  "cto": "CTO / VP Engineering",
  "devops": "DevOps",
  "product-management": "Product Manager",
  "data-engineering": "Data Engineer",
  "data-science" : "Data Scientist",
  "product-design": "Product Designer",
  "mobile-developer": "Mobile Developer",
  "data-analysis": "Data Analyst",
  "head-of-product" : "Head of Product",
  "product-owner" : "Product Owner",
  "cloud-developer": "Cloud Developer",
  "embedded-systems": "Embedded Systems Engineer",
  "solutions-engineer": "Solutions Engineer",
  "site-reliability-engineering": "Site Reliability Engineering",
  "head-of-data": "Head of Data",
  "software-architect": "Software Architect",
  "video-games": "Video Games Developer",
  "user-interface" : "UI"
  };

const getTextFromOfferCriteria = (offerCriteria: any[]) => {
  if (!offerCriteria) {
    return '';
  }

  let jobPos = _.findWhere(offerCriteria, { type: 'job-position' })?.value;
  const xp = _.findWhere(offerCriteria, { type: 'experience' })?.value;
  let mainSkills = _.findWhere(offerCriteria, { type: 'skills-main' })?.value;
  let importantSkils = _.findWhere(offerCriteria, { type: 'skills-important' })?.value;
  let locations = _.findWhere(offerCriteria, { type: 'locations' })?.value;

  jobPos = jobTitleById[jobPos] || jobPos;

  if (mainSkills) {
    mainSkills = _.map(mainSkills.split(', '), toTitleCase).join(', ');
  }
  if (importantSkils) {
    importantSkils = _.map(importantSkils.split(', '), toTitleCase).join(', ');
  }

  if (locations) {
    if (locations.endsWith(', ')) {
      locations = locations.slice(0,-2)
    }  
    locations = locations.replace('-region', '');
    locations = _.map(locations.split(', '), toTitleCase).join(', ')
  }
 
  const otherFields = _.filter(
    offerCriteria, 
    ({ type }) => !['job-position', 'experience', 'skills-main', 'skills-important', 'locations'].includes(type)
  );

  const text = `${jobPos || 'unknown'  } ${mainSkills} (${(xp || '').replace('-20', '+')} xp)
    ${importantSkils ? `(${importantSkils}) ` : ''} ${locations ? `[${locations}] ` : ''}
    ${!_.isEmpty(otherFields) ? `(${_.pluck(otherFields, 'value').join(' ; ')})`: ''}
    `;

  return text;
}

const ProfileHiresweetDetailsMatchingOffersPlugin: React.FC<Partial<
  ProfileHiresweetDetailsMatchingOffersProps
>> = ({ offers }) => {
  return (
    <>
      {_.map(offers || [], (offer) => (
        <div className={styles.pluginOffer} key={offer.offerId}>
          <span>{offer.title}</span>
          <div className={styles.pluginOfferValue}>
            <span className='pill-message mini blue'>
              {offer?.score?.[0]?.key}: {offer?.score?.[0]?.value}
            </span>
            <GenericPopup
              basic
              on='hover'
              position='bottom right'
              trigger={
                <div>
                  <Help />
                </div>
              }
              style={styles}
            >
              <ProfileHiresweetDetailsMatchingOffersWeb offers={[offer]} />
            </GenericPopup>
          </div>
        </div>
      ))}
    </>
  );
};

const toTitleCase = (str: string) => {                                                                                                                                                                              
  const title = _.reduce(                                                                                                                                                                                          
    str,                                                                                                                                                                                                           
    (memo, letter) => ({                                                                                                                                                                                           
      previousChar: letter,                                                                                                                                                                                        
      res: /[\s-]/.test(memo.previousChar) ? memo.res + letter.toUpperCase() : memo.res + letter.toLowerCase(),                                                                                                    
    }),                                                                                                                                                                                                            
    {                                                                                                                                                                                                              
      res: '',                                                                                                                                                                                                     
      previousChar: ' ',                                                                                                                                                                                           
    },                                                                                                                                                                                                             
  );                                                                                                                                                                                                               
  return title.res;                                                                                                                                                                                                
}; 

export default ProfileHiresweetDetails;
