import React from 'react';
import { 
  HiresweetLibProvider,
  ProfileGenericSourceCandidateId,
  createSweetProfileComponent, 
} from '@hiresweet/hiresweet-lib';

import useClientId from '@/hooks/router/useClientId';

type XProps = {
  text: string 
}

const MyCompoImpl : React.FC<XProps> = ({ text }) => {
  return <h1>{text}</h1>
}

const MyCompo = createSweetProfileComponent(
  MyCompoImpl, 
  [ProfileGenericSourceCandidateId]
)


const PlaygroundB4 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  // profileId='jean-bombeur-45T0ZI'
  return (
    <div style={{ padding: 30, background: 'white' }}>
      <h1>B2 - Project Forms 2</h1>
      <HiresweetLibProvider
        theme={theme}
        clientId={clientId}
      >     
        <h4>Sweet compo props</h4>
        <MyCompo text='Coucou hoho'/>
    
      </HiresweetLibProvider>
    </div>
  );
};

export default PlaygroundB4;
