import _ from 'underscore';

// returning null tells sentry to drop the error and not send it
export default function isErrorIgnored(error?: Error): boolean {
  const IGNORED_ERRORS = [
    // Caused by chrome removing the extension for various reasons (update, inactivity...)
    // And loops on itself, causing a huge amount of errors
    /Extension context invalidated/g,

    // Triggered by navigator depending on different factors. Since it's a "navigator observer"
    // it is bubbled up to window.onerror and I believe cannot be caught on the observer implementation
    // itself. Ignoring it should not be an issue since it's invisible for users
    /ResizeObserver loop limit exceeded/g,
  ];

  return _.some(IGNORED_ERRORS, (ignoredError) =>
    ignoredError.test(error?.message || error?.toString() || ''),
  );
}
