import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'semantic-ui-react';
import _ from 'underscore';
import { PrimaryCobalt80, NegativeColor120 } from '@/less/colors';
import { TEMPLATE_MAX_LENGTH } from '@/common/templateLength';

const CharacterCount = ({
  text,
  type,
  endPlacement,
}: {
  text: string;
  type: string;
  endPlacement?: boolean;
}) => {
  const { t, i18n } = useTranslation();

  const limit = TEMPLATE_MAX_LENGTH[type] ?? 0;
  const formattedLimit =
    i18n.resolvedLanguage === 'en'
      ? new Intl.NumberFormat('en').format(limit)
      : limit;

  const styles = {
    counterStyle: {
      alignSelf: 'center',
      textAlign: !endPlacement ? ('right' as const) : ('end' as const),
      margin: '0px 10px 0px 10px',
      width: limit ? '140px' : !endPlacement ? '90px' : '100%',
      fontSize: '12px',
      fontFamily: 'Graphik, sans-serif',
      color: limit && text?.length > limit ? NegativeColor120 : PrimaryCobalt80,
      whiteSpace: 'nowrap' as const,
    },
  };
  return limit ? (
    <Popup
      trigger={
        <span className='character-count' style={styles.counterStyle}>
          {text?.length}
          <span>
            {` / ${t('editor.characterCount.characters', {
              num: formattedLimit,
              count: limit,
            })}`}
          </span>
        </span>
      }
      content={t(`editor.characterCount.${type}Limit`)}
      inverted
    />
  ) : (
    <span className='character-count' style={styles.counterStyle}>
      {_.isEmpty(text)
        ? t('editor.characterCount.empty')
        : t('editor.characterCount.characters', {
            count: text?.length,
            num: text?.length,
          })}
    </span>
  );
};

export default CharacterCount;
