import GenericIconSelector from '@/components/Common/GenericIconSelector/GenericIconSelector';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import { ClientCustomAction } from '@/types/customAction';

// TODO: change inputs etc to use our own
import { getTranslatedText } from '@/common';
import GenericTextInput from '@/components/Common/GenericTextInput';
import { ClientCustomActivity } from '@/graphql/hooks/clients/useClientCustomActivities';
import GenericSelect from '@/components/Common/GenericSelect';
import _ from 'underscore';
import { subtypes } from '@/routes/RevealView/SearchView/CandidatesListPane/CandidateView/SharedNotes/subtypes';
import { CustomIconDescriptor } from '@/components/Common/GenericIconSelector/types';
import { DEFAULT_CUSTOM_TASK } from '../..';
import styles from '../../../CustomTasksSettings.module.less';

interface CustomTaskFormBasicConfigProps {
  taskForm: ClientCustomAction | ClientCustomActivity;
  type: 'tasks' | 'activities';
  onUpdate: (newValues: Partial<ClientCustomAction>) => void;
}

export function CustomTaskFormBasicConfig({
  taskForm,
  type,
  onUpdate,
}: CustomTaskFormBasicConfigProps) {
  const { t } = useTranslation();

  const translationKey =
    type === 'tasks' ? 'customTasksSettings' : 'customActivitySettings';

  const subtypeOptions = useMemo(() => {
    return [
      ..._.map(subtypes, (st) => ({
        value: st,
        label: t(`reveal.timeline.sharedNote.subtypes.${st}`),
      })),
    ];
  }, [t]);

  return (
    <>
      <Form.Field className={styles.modalFormField}>
        <label htmlFor='name'>
          {t(`reveal.parameters.${translationKey}.name`)}
        </label>
        <GenericTextInput
          id='name'
          fluid
          name='name'
          value={getTranslatedText(taskForm.title)}
          onChange={(e) =>
            onUpdate({
              title: { default: e.target?.value || '' },
            })
          }
        />
      </Form.Field>
      {type === 'activities' && (
        <Form.Field className={styles.modalFormField}>
          <label htmlFor='icon-type'>
            {t(`reveal.parameters.${translationKey}.typeLabel`)}
          </label>
          <GenericSelect
            value={_.findWhere(subtypeOptions, {
              value: _.includes(subtypes, taskForm.type)
                ? taskForm.type
                : 'comment',
            })}
            options={subtypeOptions}
            onChange={(newValue) => {
              if (!newValue) {
                return;
              }
              onUpdate({
                ...taskForm,
                type: newValue.value,
              });
            }}
          />
        </Form.Field>
      )}
      <Form.Field className={styles.modalFormField}>
        <label htmlFor='icon-type'>
          {t(`reveal.parameters.${translationKey}.iconLabel`)}
        </label>
        <GenericIconSelector
          icon={taskForm.icon as CustomIconDescriptor}
          onIconSelected={(icon) => onUpdate({ icon })}
        />
      </Form.Field>
      {type === 'tasks' && (
        <Form.Field className={styles.modalFormField}>
          <label htmlFor='name'>
            {t(`reveal.parameters.${translationKey}.cta`)}
          </label>
          <GenericTextInput
            id='name'
            fluid
            name='name'
            value={getTranslatedText(
              (taskForm as ClientCustomAction).completionButtonDisplay?.title ||
                DEFAULT_CUSTOM_TASK.completionButtonDisplay.title,
            )}
            onChange={(e) =>
              onUpdate({
                completionButtonDisplay: {
                  title: { default: e.target?.value || '' },
                },
              })
            }
          />
        </Form.Field>
      )}
    </>
  );
}
