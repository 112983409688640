import React, { createContext, FC, useContext } from 'react';
import { StorageSearchCriteria } from '@/types/search';
import useLocalStorage from '@/hooks/storage/useLocalStorage';

interface SessionSearch {
  expirationTimestamp: number;
  criteria: StorageSearchCriteria;
}

interface SessionSearchContextType {
  sessionSearch?: SessionSearch;
  setSessionSearch: (newSearch: SessionSearch) => void;
}

const SessionSearchContext = createContext<SessionSearchContextType>({
  setSessionSearch: () => {
    /* Do nothing */
  },
});

interface SessionSearchContextProviderProps {
  sessionSearchKey: string;
}

export const SessionSearchContextProvider: FC<SessionSearchContextProviderProps> = ({
  sessionSearchKey,
  children,
}) => {
  const [sessionSearch, setSessionSearch] = useLocalStorage<
    SessionSearch | undefined
  >(sessionSearchKey, undefined);

  return (
    <SessionSearchContext.Provider value={{ sessionSearch, setSessionSearch }}>
      {children}
    </SessionSearchContext.Provider>
  );
};

export const useSessionSearch = (
  defaultCriteria: StorageSearchCriteria,
): Required<SessionSearchContextType> => {
  const { sessionSearch, setSessionSearch } = useContext(SessionSearchContext);

  return {
    sessionSearch: sessionSearch || {
      expirationTimestamp: Date.now(),
      criteria: defaultCriteria,
    },
    setSessionSearch,
  };
};
