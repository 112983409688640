import React, { useEffect, useState, useMemo, useContext } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ProfileRowHeader from '@/routes/RevealView/ProfileRow/ProfileRowHeader';
import useSelectedProfile from '@/hooks/router/useSelectedProfile';
import EmptyState from '@/revealComponents/EmptyState/EmptyState';
import GenericButton from '@/components/Common/GenericButton';
import {
  PageSelector,
  PartitioningSelector,
} from '@/components/Common/Pagination';
import MissionCategoryContext from '@/context/MissionCategoryContext';

import ProfileIdHandler from '../../../../common/ProfileIdHandler';
import { REVEAL_MAX_NB_RESULTS } from '../../../../hocs/searchPool/withRevealSearchPoolResults';
import ActionsDropdown from '../../../../routes/RevealView/revealComponents/Buttons/ActionsDropdown';
import EnrichedRevealProfileModal from '../../../../routes/RevealView/revealComponents/EnrichedRevealProfileModal';
import useCandidateSelection from '../../../../routes/RevealView/SearchView/CandidatesListPane/useCandidateSelection';
import SequenceProfilesPage from './SequenceProfilesPage';

import './SequenceProfilesList.css';

const candidatePerPageOptions = [10, 15, 20, 30, 50];

const SequenceProfilesList = ({
  clientId,
  sequenceId,
  searchPoolId,
  profilesWithCurrentSequenceId,
  loading,
}) => {
  const { t } = useTranslation();
  const missionCategory = useContext(MissionCategoryContext);
  // exclude profiles that may have been remove from sequence since query loaded
  const isInSequence = ({ currentSequenceInfo }) =>
    currentSequenceInfo?.sequenceId === sequenceId;
  const filteredProfilesId = _.filter(
    profilesWithCurrentSequenceId,
    isInSequence,
  );
  const [candidatePerPage, setCandidatePerPage] = useState(
    +localStorage.SequencePaginationProfilesPerPage
      ? +localStorage.SequencePaginationProfilesPerPage
      : 10,
  );
  const [currentPage, setCurrentPage] = useState(0);
  const pageProfiles = (filteredProfilesId || []).slice(
    currentPage * candidatePerPage,
    (currentPage + 1) * candidatePerPage,
  );
  const totalPages = Math.min(
    Math.ceil((filteredProfilesId?.length || 0) / candidatePerPage),
    REVEAL_MAX_NB_RESULTS / candidatePerPage,
  );

  const [selectedProfileId, setSelectedProfileId] = useSelectedProfile();
  const candidateSelection = useCandidateSelection();
  const allCandidates = candidateSelection.getAll();
  const selection = candidateSelection.get();

  const isHeaderChecked = useMemo(() => {
    if (_.isEmpty(selection) || _.isEmpty(allCandidates)) {
      return false;
    }
    const pageIds = _.pluck(pageProfiles, 'id');
    return candidateSelection.areSelected({ profilesIds: pageIds });
    // eslint-disable-next-line
  }, [
    currentPage,
    pageProfiles.length,
    selection.length,
    allCandidates.length,
  ]);

  const onToggleCheckbox = ({ checked }) =>
    candidateSelection.onSelectAllInCurrentPageToggle(
      checked,
      currentPage,
      candidatePerPage,
      pageProfiles,
    );

  const handleCandidatePerPageChange = (value) => {
    setCandidatePerPage(value);
    localStorage.setItem('SequencePaginationProfilesPerPage', `${value}`);
  };

  useEffect(() => {
    candidateSelection.updateAllCandidates({ candidates: filteredProfilesId });
    candidateSelection.reset();
    setCurrentPage(0);
    // eslint-disable-next-line
  }, [_.pluck(filteredProfilesId, 'id').join(';')]);

  const profileIdHandler = ProfileIdHandler({
    profileIds: _.pluck(filteredProfilesId, 'id'),
    selectedProfileId,
    onSelectProfileId: (profileId) => setSelectedProfileId(profileId),
  });

  return (
    <div className='sequence-profiles-list'>
      <div className='button-container'>
        <ActionsDropdown
          clientId={clientId}
          sequenceId={sequenceId}
          candidates={selection}
          t={t}
        />
      </div>

      <ProfileRowHeader
        onToggleCheckbox={onToggleCheckbox}
        isChecked={isHeaderChecked}
        showMissions
        showLastInteraction
        showNextInteraction
      />

      {!loading && _.isEmpty(profilesWithCurrentSequenceId) ? (
        <EmptyState
          title={t('sequences.table.emptyState.title')}
          innerContent={t('sequences.table.emptyState.innerContent')}
          illustrationPath='/images/placeholders/contactsEmptyState.svg'
          cta={
            <Link to={`/client/${clientId}/reveal/projects/${missionCategory}`}>
              <GenericButton size='big'>
                {t('sequences.table.emptyState.cta')}
              </GenericButton>
            </Link>
          }
        />
      ) : (
        <>
          <SequenceProfilesPage
            clientId={clientId}
            searchPoolId={searchPoolId}
            candidateSelection={candidateSelection}
            profiles={pageProfiles}
            loading={loading}
            onClickRow={({ profileId }) => setSelectedProfileId(profileId)}
          />

          <div className='table-footer'>
            <PartitioningSelector
              options={candidatePerPageOptions}
              value={candidatePerPage}
              onChange={(value) => handleCandidatePerPageChange(value)}
            />
            {totalPages > 1 && (
              <PageSelector
                currentPageIndex={currentPage}
                numberOfPages={totalPages}
                onChange={setCurrentPage}
              />
            )}
          </div>
        </>
      )}

      {/* Enriched profile query must end after non-enriched profiles have
      loaded so we get the full data in the modal */}
      {!loading && (
        <EnrichedRevealProfileModal
          clientId={clientId}
          searchPoolId={searchPoolId}
          open={!!selectedProfileId}
          onClose={() => setSelectedProfileId(null)}
          profileIdHandler={profileIdHandler}
          searches={{}} // TODO: hacky but useful
        />
      )}
    </div>
  );
};

export default SequenceProfilesList;
