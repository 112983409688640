import gql from 'graphql-tag';
import TranslatableTextFragment from '@/graphql/fragments/TranslatableText';
import Author from '@/graphql/fragments/Author';
import { ClientPipeline } from '@/graphql/clients';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import useClientId from '@/hooks/router/useClientId';

const getMissionInterestedSubPipelines = gql`
  query getMissionInterestedSubPipelines($clientId: ID!) {
    client(id: $clientId) {
      id
      missionInterestedSubPipelines {
        id
        title {
          ...TranslatableText
        }
        scopeDescriptor {
          type
          departmentId
          sectionId
          subsectionId
        }
        missionCategory {
          type
        }
        steps {
          id
          title {
            ...TranslatableText
          }
        }
        author {
          ...Author
        }
      }
    }
  }
  ${TranslatableTextFragment}
  ${Author}
`;

type GetMissionInterestedSubPipelinesData = {
  client: {
    id: string;
    missionInterestedSubPipelines?: ClientPipeline[];
  };
};

type GetMissionInterestedSubPipelinesVariables = {
  clientId: string;
};

type UseMissionInterestedSubPipelinesInput = {
  queryOptions?: QueryHookOptions<
    GetMissionInterestedSubPipelinesData,
    GetMissionInterestedSubPipelinesVariables
  >;
};

type UseMissionInterestedSubPipelinesResult = QueryResult<
  GetMissionInterestedSubPipelinesData,
  GetMissionInterestedSubPipelinesVariables
> & {
  missionInterestedSubPipelines: ClientPipeline[];
};

function useMissionInterestedSubPipelines({
  queryOptions = {},
}: UseMissionInterestedSubPipelinesInput = {}): UseMissionInterestedSubPipelinesResult {
  const clientId = useClientId();
  const query = useQuery<
    GetMissionInterestedSubPipelinesData,
    GetMissionInterestedSubPipelinesVariables
  >(getMissionInterestedSubPipelines, {
    ...queryOptions,
    variables: { clientId },
  });
  const missionInterestedSubPipelines =
    query.data?.client.missionInterestedSubPipelines || [];
  return { ...query, missionInterestedSubPipelines };
}

export default useMissionInterestedSubPipelines;
