import _ from 'underscore';
import React from 'react';
import {
  HiresweetLibProvider,
  HiresweetProfile,
  ProfileContainer,
  HiresweetCreateSlashProfileButton,
} from '@hiresweet/hiresweet-lib';
import useClientId from '@/hooks/router/useClientId';

const Playground13 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  const profileId = 'kevin-goueffon-7XJEOP';
  return (
    <div style={{ padding: 30 }}>
      <h1>P13 - Slash HiresweetProfile</h1>
      <div>
        <HiresweetLibProvider theme={theme} clientId={clientId}>
          <ProfileContainer profileId={profileId}>
            <HiresweetCreateSlashProfileButton
              onSlashProfileCreated={(hiresweetProfileId, slashProfileId) => {
                console.info('youhou', hiresweetProfileId, slashProfileId);
              }}
            />
            <br />
            <br />
            <br />
            <HiresweetProfile
              profileId={profileId}
              extraFields={[
                { key: 'social-security-number', value: 'NumSec' },
                { key: 'nationality', value: 'Nationalité' },
                { key: 'civility', value: 'Civilité' },
                { key: 'birth-country', value: '' },
                { key: 'birth-department', value: 'Département de naissance' },
                { key: 'family-situation', value: 'Situation familiale' },
                { key: 'children-number', value: "Nombre d'enfants" },
              ]}
            />
          </ProfileContainer>
        </HiresweetLibProvider>
      </div>
    </div>
  );
};

export default Playground13;
