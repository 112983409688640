import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import './IntegrationLink.css';
import GenericButton from '@/components/Common/GenericButton';

const IntegrationLink = ({ logo, title, integration, redirectLink }) => {
  const { t } = useTranslation();
  const { initializationStarted } = integration?.synchronizationStatus || {};

  const isValidEnrichmentIntegration =
    !integration?.useClientIntegration && integration?.status === 'active';

  return (
    <Link className='integration-link' to={redirectLink}>
      <div className='integration-link-container'>
        <div className='integration-link-logo'>{logo}</div>
        {initializationStarted || isValidEnrichmentIntegration ? (
          <>
            <div className='integration-link-title'>
              <div>{title}</div>
              <span className='integration-link-label pill-message green'>
                <i className='ri-checkbox-circle-fill' />
                <span>{t('integrations.connected')}</span>
              </span>
            </div>
            <div className='integration-link-arrow'>
              <Icon
                className='integration-link-icon'
                name='chevron right'
                size='small'
              />
            </div>
          </>
        ) : (
          <>
            <div className='integration-link-title'>{title}</div>
            <div className='integration-link-button'>
              <GenericButton primacy='secondary' size='big'>
                {t('integrations.connectButton')}
              </GenericButton>
            </div>
          </>
        )}
      </div>
    </Link>
  );
};

export default IntegrationLink;
