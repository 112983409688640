import React, { useState } from 'react';

import useClientId from '@/hooks/router/useClientId';

import {
  HiresweetLibProvider,
  Button,
  PanelWithPagination,
} from '@hiresweet/hiresweet-lib';

const PlaygroundJ3 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  const [openPage, setOpenPage] = useState<number | undefined>(undefined);
  return (
    <div style={{ padding: 30 }}>
      <h1>PJ3 - Panel with pagination</h1>
      <HiresweetLibProvider theme={theme} clientId={clientId}>
        <Button onClick={() => setOpenPage(0)}>Open panel</Button>
        <PanelWithPagination
          numberOfPages={3}
          currentPage={openPage ?? 0}
          onPage={setOpenPage}
          open={openPage !== undefined}
          onClose={() => setOpenPage(undefined)}
        >
          {openPage}
        </PanelWithPagination>
      </HiresweetLibProvider>
    </div>
  );
};

export default PlaygroundJ3;
