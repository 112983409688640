import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link, withRouter } from 'react-router-dom';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';
import _, { compose } from 'underscore';
import { useTranslation } from 'react-i18next';
import useDataUpdateSubscription from '@/graphql/dataUpdateSubscription/useDataUpdateSubscription';
import GenericButton from '@/components/Common/GenericButton';
import useCampaignsDailyCohorts from '@/graphql/hooks/campaigns/useCampaignsDailyCohorts';
import { getCampaignStats } from '@/routes/RevealView/RevealAnalytics/tabs/MarketingTab/CampaignFunnels/helpers';
import CampaignFunnelSummary from '@/routes/RevealView/RevealAnalytics/tabs/MarketingTab/CampaignFunnels/CampaignFunnelSummary';
import useCampaignsDailyCohortsDetails from '@/graphql/hooks/campaigns/useCampaignsDailyCohortsDetails';
import { statsCampaignEvents } from '@/types/statistics/campaigns';
import GenericModal from '@/components/Common/GenericModal';
import StatsProfileAndCampaignTable from '@/routes/RevealView/RevealAnalytics/components/StatsProfileAndCampaignTable';
import useFunnelDetails from '@/routes/RevealView/RevealAnalytics/tabs/MarketingTab/CampaignFunnels/hooks/useFunnelDetails';
import DetailsModalHeader from '@/routes/RevealView/RevealAnalytics/components/DetailsModalHeader';
import CampaignSettingsDropdown from './CampaignSettingsDropdown';
import CampaignProfilesList from './CampaignProfilesList';
import routerParamsToProps from '../../../../hocs/routerParamsToProps';
import { GET_CAMPAIGN_WITH_MINI_PROFILES } from '../../../../graphql/campaigns';

import './CampaignView.css';

const CampaignView = ({ clientId, campaignId, match }) => {
  const { t } = useTranslation();
  const variables = { campaignId };
  const { data, loading, refetch } = useQuery(GET_CAMPAIGN_WITH_MINI_PROFILES, {
    variables,
    fetchPolicy: 'network-only',
  });
  useDataUpdateSubscription({
    type: 'onProfilesRemovedFromCampaign',
    match: { campaignId },
    onData: () => refetch(),
  });
  useDataUpdateSubscription({
    type: 'onProfileAddedToCampaign',
    match: { campaignId },
    onData: () => refetch(),
  });
  const { campaign } = data || {};

  const { dailyCohorts } = useCampaignsDailyCohorts({ clientId });
  const campaignStats = getCampaignStats(dailyCohorts);

  const [
    fetchDetails,
    { dailyCohorts: dailyCohortsDetails = [], loading: detailsLoading },
  ] = useCampaignsDailyCohortsDetails();
  const [modalOpen, setModalOpen] = useState(false);

  const {
    filter,
    applyFilter,
    profileDetails,
  } = useFunnelDetails(dailyCohortsDetails, ({ campaignIds }) =>
    fetchDetails({ clientId, campaignIds }),
  );

  const globalClickListeners = useMemo(() => {
    if (!fetchDetails) {
      return undefined;
    }

    return _.reduce(
      statsCampaignEvents,
      (listeners, segment) => ({
        ...listeners,
        [segment]: () => {
          setModalOpen(true);
          applyFilter({ segment, campaignId: campaign?.id });
        },
      }),
      {},
    );
  }, [fetchDetails, applyFilter, campaign]);

  const modalTitle = useMemo(() => {
    const titleParts = [campaign?.title];
    if (filter?.segment) {
      titleParts.push(t(`reveal.reports.marketing.state.${filter.segment}`));
    }
    return titleParts.join(' - ');
  }, [filter, t, campaign]);

  if (loading) {
    return (
      <div className='campaign-view'>
        <Loader active size='large' />
      </div>
    );
  }

  return (
    <div className='campaign-view'>
      <div className='campaigns-header'>
        <div className='campaign-view-breadcrumb'>
          <Link
            className='campaign-view-breadcrumb-title'
            to={`/client/${clientId}/reveal/campaigns`}
          >
            {t('campaigns.header')}
          </Link>
          <div className='campaign-view-breadcrumb-divider'>
            <i className='ri-arrow-right-s-line' />
          </div>
          <div className='campaign-view-breadcrumb-title collapsible'>
            {campaign?.title}
          </div>
          <CampaignSettingsDropdown campaign={campaign} />
        </div>
        <Link to={`${match.url}/edit`}>
          <GenericButton size='big'>
            {t('campaigns.editCampaign')}
          </GenericButton>
        </Link>
      </div>

      <div className='campaigns-content'>
        <div className='campaign-card'>
          {campaignStats[campaignId] && (
            <CampaignFunnelSummary
              data={campaignStats[campaignId]}
              clickListeners={globalClickListeners}
            />
          )}
          <CampaignProfilesList
            clientId={clientId}
            campaignId={campaignId}
            searchPoolId='reveal'
            profilesWithCurrentCampaignId={campaign?.profiles}
            loading={loading}
          />
        </div>
      </div>
      {detailsLoading ? (
        <Dimmer active>
          <Loader size='large' />
        </Dimmer>
      ) : (
        <GenericModal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Content>
            <DetailsModalHeader>
              <h1>{modalTitle}</h1>
            </DetailsModalHeader>
            <StatsProfileAndCampaignTable profileDetails={profileDetails} />
          </Modal.Content>
        </GenericModal>
      )}
    </div>
  );
};

export default compose(withRouter, routerParamsToProps)(CampaignView);
