import * as Sentry from '@sentry/browser';
import { openPopup, sleep } from '../helpers';

const SMARTRECRUITERS_CLIENT_ID =
  process.env.REACT_APP_SMARTRECRUITERS_CLIENT_ID;
const REDIRECT_URI = `${window.location.origin}/smartrecruiters-callback.html`;
const SMARTRECRUITERS_POPUP = { width: 440, height: 440 };

const BASE_URL = 'https://www.smartrecruiters.com/identity/oauth/allow?';
const scopes = [
  'candidates_read',
  'candidates_create',
  'candidates_manage', // required for patch updates
  'jobs_read',
  'users_read',
  // Folowing are used by reveal
  'configuration_read',
  'messages_read',
];
const params = [
  `client_id=${SMARTRECRUITERS_CLIENT_ID}`,
  `redirect_uri=${REDIRECT_URI}`,
  `scope=${scopes.join('%20')}`,
];

const AUTHORIZATION_URL = BASE_URL + params.join('&');

let detectedAuthorizationCode = '';

const receiveAuthorizationCode = (event) => {
  const originCheck = /localhost:8070$|hiresweet\.com$/g;
  if (
    typeof event.data === 'string' &&
    event.data.startsWith('ats-authorization-code-') &&
    originCheck.test(event.origin)
  ) {
    const popupUrl = new URL(event.data.replace('ats-authorization-code-', ''));
    detectedAuthorizationCode = popupUrl.searchParams.get('code');
  }
};

window.addEventListener('message', receiveAuthorizationCode);

const pollDetectAuthorizationCode = async (nbTrials, popup) => {
  if (nbTrials > 0) {
    if (detectedAuthorizationCode) {
      const result = { token: detectedAuthorizationCode };
      // reset code
      detectedAuthorizationCode = '';
      return result;
    }
    if (popup && popup.closed) {
      return undefined;
    }
    await sleep(250);
    return pollDetectAuthorizationCode(nbTrials - 1, popup);
  }
  return undefined;
};

/**
 * Constructor which returns a function that will execute an OAuth flow and run onAuthorizedCallback
 * when the authorization token is successfully retrieved.
 */
const authorizeWithOAuth = ({ onAuthorizedCallback }) => {
  /**
   * opens a popup to ask the user to allow an OAuth2 integration
   * @returns Promise <Object: {success: boolean, error: object}>
   */
  return async () => {
    try {
      // reset code
      detectedAuthorizationCode = '';
      // open child window that will use window.opener.postMessage
      // to send event back to current 'opener' window
      const offlineGrantPopup = openPopup(
        AUTHORIZATION_URL,
        '',
        SMARTRECRUITERS_POPUP.height,
        SMARTRECRUITERS_POPUP.width,
      );
      // start polling for validation (during 5 minutes max.)
      const pollResult = await pollDetectAuthorizationCode(
        (5 * 60 * 1000) / 250,
        offlineGrantPopup,
      );
      // close pop-up
      offlineGrantPopup.close();

      const { token } = pollResult || {};
      if (!token) {
        return {
          success: false,
          error: Error('user closed popup manually or popup timed out'),
        };
      }

      await onAuthorizedCallback({ token });

      return {
        success: true,
      };
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setTags({ feature: 'smartrecruiters-integration' });
        Sentry.captureException(e);
      });
      return {
        success: false,
        error: e,
      };
    }
  };
};

const smartrecruiters = {
  authorizeWithOAuth,
};

export default smartrecruiters;
