import React from 'react';
import _, { compose } from 'underscore';
import { Table } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { fullName } from '../helpers';

const GrantedSendersTable = ({ senders, t }) => {
  return (
    <Table className='senders-settings-table' textAlign='center' basic>
      <SendersTableHeader t={t} />
      <SendersTableBody senders={senders} />
    </Table>
  );
};

export const SendersTableHeader = ({ t }) => {
  return (
    <Table.Header className='senders-table-header'>
      <Table.Row>
        <Table.HeaderCell width={4}>
          {t('settings.senders.senderAddress')}
        </Table.HeaderCell>
        <Table.HeaderCell width={3}>
          {t('settings.senders.owner')}
        </Table.HeaderCell>
        <Table.HeaderCell width={2} />
      </Table.Row>
    </Table.Header>
  );
};

export const SendersTableBody = ({ senders }) => {
  return (
    <Table.Body>
      {_.map(senders, (sender) => (
        <SendersTableRow key={sender.id} sender={sender} />
      ))}
    </Table.Body>
  );
};

export const SendersTableRow = ({ sender }) => {
  const { senderAddress, owner } = sender || {};

  return (
    <Table.Row>
      <Table.Cell>{senderAddress}</Table.Cell>
      <Table.Cell>{fullName(owner)}</Table.Cell>
      <Table.Cell />
    </Table.Row>
  );
};

export default compose(withTranslation('translations'))(GrantedSendersTable);
