import React from 'react';
import _ from 'underscore';
import { Button } from 'semantic-ui-react';


import './ContactFlowPlaceholderSequence.css';

const ContactFlowPlaceholderSequence = ({ sequence, onAddSequence }) => {
  const ctaString = {
    'first-contact': 'Configure your first sequence',
    'positive-answer': 'Configure a canned response for positive answer',
    'non-positive-answer': 'Configure a canned response for negative answer',
    recontact: 'Configure a recontact sequence',
  }[sequence.position] || `Create "${sequence.title}"`;
  return (
    <div className='contact-flow-sequence-placeholder'>
      <Button className='button-default' onClick={() => onAddSequence({ sequence: _.omit(sequence, 'isPlaceholder', '__typename') })}>
        {ctaString}
      </Button>
    </div>
  );
};

export default ContactFlowPlaceholderSequence;
