import gql from 'graphql-tag';
import TranslatableText from './fragments/TranslatableText';

export const GET_CONNECT_FORMS = gql`
  query getClientConnectForms($clientId: ID!) {
    client(id: $clientId) {
      id
      connectForms {
        id
        title {
          ...TranslatableText
        }
        author {
          email
          lastname
          firstname
        }
        creationDate
        lastEditionDate
        fields {
          id
          title {
            ...TranslatableText
          }
          type
          customFieldId
          customFieldType
        }
      }
    }
  }
  ${TranslatableText}
`;

export const GET_CONNECT_FORM = gql`
  query getClientConnectForm($connectFormId: ID!, $clientId: ID!) {
    client(id: $clientId) {
      id
      connectForm(connectFormId: $connectFormId) {
        id
        title {
          ...TranslatableText
        }
        author {
          email
          lastname
          firstname
        }
        creationDate
        lastEditionDate
        fields {
          id
          title {
            ...TranslatableText
          }
          type
          customFieldId
          customFieldType
        }
      }
    }
  }
  ${TranslatableText}
`;

export const CREATE_CONNECT_FORM = gql`
  mutation CreateconnectForm($input: CreateConnectFormInput!) {
    connectForm {
      create(input: $input) {
        connectForm {
          id
          title {
            ...TranslatableText
          }
          fields {
            type
            title {
              ...TranslatableText
            }
            customFieldId
            customFieldType
          }
        }
      }
    }
  }
  ${TranslatableText}
`;

export const DELETE_CONNECT_FORM = gql`
  mutation DeleteConnectForm($input: DeleteConnectFormInput!) {
    connectForm {
      delete(input: $input) {
        id
      }
    }
  }
`;

export const UPDATE_CONNECT_FORM = gql`
  mutation UpdateConnectForm($input: UpdateConnectFormInput!) {
    connectForm {
      update(input: $input) {
        id
        title {
          ...TranslatableText
        }
        fields {
          type
          title {
            ...TranslatableText
          }
          customFieldId
          customFieldType
        }
      }
    }
  }
  ${TranslatableText}
`;
