import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import SmallResumeData from '../../graphql/fragments/SmallResumeData';
import ContactFlow from '../../graphql/fragments/ContactFlow';

export const getClientProfilesToFollowup = gql`
  query getClientProfilesToFollowup($clientId: ID!) {
    client(id: $clientId) {
      id
      profilesToFollowup {
        id
        jobOfferId
        creationTimestamp
        lastStepUpdateTimestamp
        resumeData {
          step
          firstname
          lastname
          headline {
            content {
              text
            }
          }
          photoLink
        }
        powerHourFlags {
          powerHourId
          isFlagged
        }
        firstContactDate
        
        # TODO: only useful for the "hide"/skip action of unclassified candidates
        searchPoolState {
          id
          searchPoolProfileId
        }
        answer {
          date
          label
        }
        processStep {
          milestone {
            id
            lastUpdateDate
            lastUpdateTimestamp
          }
        }
        isActive
        firstContactDate
        annotation {
          globalFavorite {
            value
          }
          globalComment {
            value
          }
        }
      }
    }
  }
`;

export default graphql(getClientProfilesToFollowup, {
  options: ({ clientId }) => {
    const variables = { clientId };
    // NOTE: fetchPolicy
    return { variables, fetchPolicy: 'network-only' };
  },
  props: ({ data: { client, loading, error } }) => {
    return {
      profilesToFollowup: {
        data: client && client.profilesToFollowup,
        loading,
        error,
      },
    };
  },
});
