import { createContext } from 'react';

type TimelineItemDiplaySettings = {
  showAgencies: boolean;
  currentLinkedProfileTitle: string;
};

const TimelineItemDiplaySettingsContext = createContext<
  TimelineItemDiplaySettings
>({ showAgencies: false, currentLinkedProfileTitle: '' });

export default TimelineItemDiplaySettingsContext;
