import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const query = gql`
  query withJobStages($clientId: ID!, $integrationId: ID!, $jobId: ID!) {
    client(id: $clientId) {
      id
      integration(id: $integrationId) {
        id
        ... on WorkableIntegration {
          job(id: $jobId) {
            id
            name
            stages {
              step
              name
              position
            }
          }
        }
        ... on GreenhouseIntegration {
          job(id: $jobId) {
            id
            name
            stages {
              step
              name
              position
            }
          }
        }
        ... on AshbyIntegration {
          job(id: $jobId) {
            id
            name
            stages {
              step
              name
              position
            }
          }
        }
        ... on PinpointIntegration {
          job(id: $jobId) {
            id
            name
            stages {
              step
              name
              position
            }
          }
        }
        ... on RecruiteeIntegration {
          job(id: $jobId) {
            id
            name
            stages {
              step
              name
              position
            }
          }
        }
        ... on TeamtailorIntegration {
          job(id: $jobId) {
            id
            name
            stages {
              step
              name
              position
            }
          }
        }
        ... on SmartrecruitersIntegration {
          job(id: $jobId) {
            id
            name
            stages {
              step
              name
              position
            }
          }
        }
        ... on WelcomekitIntegration {
          job(id: $jobId) {
            id
            name
            stages {
              step
              name
              position
            }
          }
        }
        ... on LeverIntegration {
          job(id: $jobId) {
            id
            name
            stages {
              step
              name
              position
            }
          }
        }
      }
    }
  }
`;

export default graphql(query, {
  options: ({ clientId, integrationId, jobId }) => ({
    variables: {
      clientId,
      integrationId,
      jobId,
    },
  }),
  props: ({ data: { loading, client, error } }) => ({
    loading,
    stages: client && client.integration?.job?.stages,
    error,
  }),
});
