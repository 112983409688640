import React from 'react';
import _ from 'underscore';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import freelanceStatesOptions from '../../../../../common/options/freelanceStatesOptions.json';

const FreelanceFields = React.memo(
    ({ freelanceStates, setFreelanceStates }) => {
        const currentValue = _.pluck(freelanceStates?.target, 'freelanceState');
        const onChange = (e, { value }) => {
            setFreelanceStates({
                target: _.map(value, (freelanceState) => ({ freelanceState })),
            });
        };
        const { t } = useTranslation();
        return (
            <div className='industry-fields section'>
                <Form.Dropdown
                    search
                    selection
                    multiple
                    fluid
                    placeholder={t('common.select')}
                    options={freelanceStatesOptions || []}
                    value={currentValue}
                    onChange={onChange}
                    closeOnChange
                />
            </div>
        );
    },
);

export default FreelanceFields;
