import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CrossIcon } from '@/assets/icons';
import styles from './SequenceDropdownNoResultsState.module.less';

interface DropdownGenericEmptyStateProps {
  title: string;
  description: string;
}
export const DropdownGenericEmptyState: FC<DropdownGenericEmptyStateProps> = ({
  title,
  description,
  children,
}) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      <p className={styles.description}>{description}</p>
      {children}
    </div>
  );
};

interface SequenceDropdownNoResultsStateProps {
  search: string;
  setSearchFilter: (searchFilter: string) => void;
}

const SequenceDropdownNoResultsState: FC<SequenceDropdownNoResultsStateProps> = ({
  search,
  setSearchFilter,
}) => {
  const { t } = useTranslation();
  return (
    <DropdownGenericEmptyState
      title={t('sequences.noResultsState.title', { search })}
      description={t('sequences.noResultsState.description')}
    >
      <div
        className={styles.ctaContainer}
        onClick={() => setSearchFilter('')}
        onKeyPress={() => setSearchFilter('')}
        role='button'
      >
        <CrossIcon />
        {t('sequences.noResultsState.cta')}
      </div>
    </DropdownGenericEmptyState>
  );
};

export default SequenceDropdownNoResultsState;
