import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import * as Sentry from '@sentry/browser';
import { Loader } from 'semantic-ui-react';

import EmptyState from '@/revealComponents/EmptyState/EmptyState';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import { useAddProfilesToMissionAndSequenceMutation } from '@/graphql/searchPoolProfiles';
import useSearchPoolProfileMatches from '@/graphql/hooks/searchPoolProfile/useSearchPoolProfileMatches';
import ProjectDropdown from '@/components/Common/ProjectDropdown/ProjectDropdown';
import GenericButton from '@/components/Common/GenericButton';
import DropdownControlsContext from '@/context/DropdownControlsContext';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

import RelevantJobs from './RelevantJobs';
import ProfileMissionsManagement from './ProfileMissionsManagement';

import styles from './ProfileProjectsTab.module.less';

interface ProfileProjectsTabProps {
  clientId: string;
  profileId: string;
  missions: any;
  missionsInfo: any;
}

const ProfileProjectsTab: React.FC<ProfileProjectsTabProps> = ({
  clientId,
  profileId,
  missions,
  missionsInfo,
}) => {
  const { t } = useTranslation();
  const { profile } = useCandidateViewContext();
  const projectOptions = useSearchPoolJobOptions('reveal', {
    activeOnly: true,
  }).jobOptions;
  const [fetched, setFetched] = useState(false);
  const [addProfilesToMissionAndSequence] =
    useAddProfilesToMissionAndSequenceMutation();
  const [fetchMatches, { data: relevantProjectsData, loading }] =
    useSearchPoolProfileMatches('reveal', {
      id: profileId,
    });
  const { shouldHideRelevantProjects } = useMergedConfigurationParams();

  const relevantProjects =
    relevantProjectsData?.searchPool?.profileMatches?.matches || [];

  const addToProject = async ({ id }: any) => {
    try {
      await addProfilesToMissionAndSequence({
        variables: {
          searchPoolId: 'reveal',
          input: {
            missionId: id,
            profileIds: [profileId],
          },
        },
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className={styles.profileProjectsTab}>
      {_.isEmpty(missions) ? (
        <EmptyState
          title={t('reveal.profileModal.emptyStates.projects.title', {
            firstname: profile.resumeData?.firstname,
          })}
          innerContent={t(
            'reveal.profileModal.emptyStates.projects.innerContent',
          )}
          illustrationPath='/images/placeholders/projectsEmptyState.svg'
          cta={
            <ProjectDropdown
              projects={projectOptions}
              onProjectSelected={addToProject}
              trigger={
                <DropdownControlsContext.Consumer>
                  {({ toggleDropdown }) => (
                    <GenericButton
                      size='big'
                      primacy='primary'
                      onClick={toggleDropdown}
                    >
                      {t('reveal.profileModal.emptyStates.projects.cta')}
                    </GenericButton>
                  )}
                </DropdownControlsContext.Consumer>
              }
            />
          }
        />
      ) : (
        <ProfileMissionsManagement
          profileId={profileId}
          missions={missions}
          missionsInfo={missionsInfo}
          clientId={clientId}
        />
      )}

      {shouldHideRelevantProjects !== 'true' && (
        <>
          <h3>{t('reveal.profileModal.relevantMissions.title')}</h3>
          {fetched &&
            (loading ? (
              <Loader inline='centered' active />
            ) : (
              <RelevantJobs
                profileId={profileId}
                matches={relevantProjects}
                missions={missions}
              />
            ))}
          {!fetched && (
            <GenericButton
              primacy='secondary'
              color='grey'
              onClick={() => {
                fetchMatches();
                setFetched(true);
              }}
            >
              {t('reveal.profileModal.relevantMissions.fetch')}
            </GenericButton>
          )}
        </>
      )}
    </div>
  );
};

export default ProfileProjectsTab;
