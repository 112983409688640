import gql from 'graphql-tag';

export const EnrichmentIntegrationQuotaPeriod = gql`
  fragment EnrichmentIntegrationQuotaPeriod on EnrichmentIntegrationQuotaPeriod {
    beginDate
    endDate
    totalCreditsCount
    usedCreditsCount
  }
`;

export const EnrichmentIntegrationQuota = gql`
  fragment EnrichmentIntegrationQuota on EnrichmentIntegrationQuota {
    type

    currentPeriod {
      ...EnrichmentIntegrationQuotaPeriod
    }

    periodPolicy {
      type
      totalCreditsCount
      carryOver
    }

    history {
      ...EnrichmentIntegrationQuotaPeriod
    }
  }

  ${EnrichmentIntegrationQuotaPeriod}
`;

export const UserEnrichmentIntegration = gql`
  fragment UserEnrichmentIntegration on UserEnrichmentIntegration {
    id
    type
    status
    useClientIntegration
    integrationId
    hasAcceptedTerms
    quota {
      ...EnrichmentIntegrationQuota
    }

    clientIntegration {
      id
      type
      status
      useSystemIntegration
      quota {
        ...EnrichmentIntegrationQuota
      }
    }
  }

  ${EnrichmentIntegrationQuota}
`;
