import gql from 'graphql-tag';
import SearchPoolProfile from './fragments/SearchPoolProfile';

const ENRICHED_SEARCH_POOL_PROFILES = gql`
  query getEnrichedSearchPoolProfiles($searchPoolId: ID!, $ids: [ID]!, $criteria: SearchCriteriaInput) {
    searchPool(id: $searchPoolId) {
      id
      enrichedProfiles(ids: $ids, criteria: $criteria) {
        ...SearchPoolProfile
      }
    }
  }
  ${SearchPoolProfile}
`;

export default ENRICHED_SEARCH_POOL_PROFILES;
