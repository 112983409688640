import React, { FC, ReactNode } from 'react';

import EmptyState from '@/revealComponents/EmptyState';

import styles from './CardEmptyState.module.less';

interface CardEmptyStateProps {
  illustrationPath?: string;
  title: string;
  cta?: ReactNode;
}

const CardEmptyState: FC<CardEmptyStateProps> = ({
  illustrationPath,
  title,
  cta,
  children,
}) => (
  <EmptyState
    title={title}
    cta={cta}
    illustrationPath={illustrationPath}
    innerContent={children}
    className={styles.emptyState}
  />
);

export default CardEmptyState;
