import gql from 'graphql-tag';
import { MiniMission } from '@/types/mission';

export interface GetMiniMissionData {
  searchPool: {
    id: string;
    job: MiniMission;
  };
}

export interface GetMiniMissionVariables {
  missionId: string;
}

const GET_MINI_MISSION = gql`
  query getMiniMission($missionId: ID!) {
    searchPool(id: "reveal") {
      id
      job(id: $missionId) {
        id
        isArchived
        data {
          title
        }
      }
    }
  }
`;

export default GET_MINI_MISSION;
