import * as Sentry from '@sentry/browser';
import _, { compose } from 'underscore';
import React from 'react';
import { useMutation } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Button, Form } from 'semantic-ui-react';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import InputControl from '@/components/InputControl';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import {
  REGEXP_GITHUB,
  REGEXP_LINKEDIN,
  REGEXP_STACKOVERFLOW,
} from '@/common/validators';
import { UPDATE_RESUME_DATA_IN_SEARCH_POOL } from '@/graphql/searchPoolProfile';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { createCustomFieldsPayload } from '@/common/customFields';
import contextToProps, { ContextToProps } from '@/hocs/contextToProps';
import CustomField from '@/revealComponents/CustomField';

import styles from './PluginProfileEditForm.module.less';

const isRequiredIfOtherEmpty = {
  is: _.isEmpty,
  then: yup.string().required(),
  otherwise: yup.string(),
};

const ProfileSchema = yup.object().shape(
  {
    firstname: yup.string().when('lastname', isRequiredIfOtherEmpty),
    lastname: yup.string().when('firstname', isRequiredIfOtherEmpty),
    headline: yup.string(),
    email: yup.string().email(),
    phoneNumber: yup.string(),
    linkedin: yup
      .string()
      .trim()
      .matches(REGEXP_LINKEDIN, { excludeEmptyString: true }),
    github: yup
      .string()
      .trim()
      .matches(REGEXP_GITHUB, { excludeEmptyString: true }),
    stackoverflow: yup
      .string()
      .trim()
      .matches(REGEXP_STACKOVERFLOW, { excludeEmptyString: true }),
  },
  [['firstname', 'lastname']],
);

type ProfileData = {
  firstname: string;
  lastname: string;
  headline: string;
  email: string;
  phoneNumber: string;
  linkedin: string;
  github: string;
  stackoverflow: string;
  customFields: Record<string, string | number>;
};

export type PluginProfileEditFormProps = ContextToProps & {
  setEditMode: (editMode: boolean) => void;
  initialValues: Partial<ProfileData>;
  customFields?: CustomFieldDefinition[];
  profileId: string;
  t: TFunction;
};

const PluginProfileEditForm = ({
  setEditMode,
  initialValues,
  customFields = [],
  profileId,
  t,
  onShowNotification,
}: PluginProfileEditFormProps) => {
  const [updateResumeDataInSearchPool] = useMutation(
    UPDATE_RESUME_DATA_IN_SEARCH_POOL,
  );

  const getDefaultValues = () => ({
    firstname: initialValues.firstname || '',
    lastname: initialValues.lastname || '',
    headline: initialValues.headline || '',
    email: initialValues.email || '',
    phoneNumber: initialValues.phoneNumber || '',
    linkedin: initialValues.linkedin || '',
    stackoverflow: initialValues.stackoverflow || '',
    github: initialValues.github || '',
    customFields: initialValues.customFields || {},
  });
  const methods = useForm<ProfileData>({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(ProfileSchema),
  });

  React.useEffect(() => {
    methods.reset(getDefaultValues());
    // eslint-disable-next-line
  }, [profileId]);

  const publish = useDataUpdateSubscriptionPublish();

  const onSubmit = async (payload: ProfileData) => {
    const {
      headline,
      github,
      linkedin,
      stackoverflow,
      customFields: customFieldsData,
      ...rest
    } = payload;
    try {
      const customFieldsPayload = createCustomFieldsPayload(
        customFieldsData,
        customFields,
      );
      await updateResumeDataInSearchPool({
        variables: {
          searchPoolId: 'reveal',
          input: {
            id: profileId,
            data: {
              ...rest,
              customFields: customFieldsPayload,
              sources: { github, linkedin, stackoverflow },
              headline: { content: { text: headline } },
            },
          },
        },
      });

      publish('onProfileResumeDataUpdate', { id: profileId });
      setEditMode(false);
      if (onShowNotification) {
        onShowNotification({
          level: 'success',
          message: t('reveal.profileEdition.success'),
        });
      }
    } catch (e) {
      if (onShowNotification) {
        onShowNotification({
          level: 'error',
          message: t('reveal.profileEdition.error'),
        });
      }
      Sentry.captureException(e);
    }
  };

  const { handleSubmit } = methods;

  return (
    <div className={styles.root}>
      <FormProvider {...methods}>
        <Form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={styles.title}>{t('reveal.profileEdition.title')}</h2>
          <InputControl
            label={t('reveal.profileEdition.firstname')}
            errorMessage={t('reveal.profileEdition.errors.firstnameOrLastname')}
            name='firstname'
            horizontal
          />
          <InputControl
            label={t('reveal.profileEdition.lastname')}
            errorMessage={t('reveal.profileEdition.errors.firstnameOrLastname')}
            name='lastname'
            horizontal
          />
          <InputControl
            label={t('reveal.profileEdition.headline')}
            name='headline'
            horizontal
          />
          <InputControl
            label={t('reveal.profileEdition.email')}
            errorMessage={t('reveal.profileEdition.errors.email')}
            name='email'
            horizontal
          />
          <InputControl
            label={t('reveal.profileEdition.phoneNumber')}
            errorMessage={t('reveal.profileEdition.errors.phoneNumber')}
            name='phoneNumber'
            horizontal
          />
          <InputControl
            label={t('reveal.profileEdition.linkedin')}
            errorMessage={t('reveal.profileEdition.errors.linkedin')}
            name='linkedin'
            horizontal
          />
          <InputControl
            label={t('reveal.profileEdition.github')}
            errorMessage={t('reveal.profileEdition.errors.github')}
            name='github'
            horizontal
          />
          <InputControl
            label={t('reveal.profileEdition.stackoverflow')}
            errorMessage={t('reveal.profileEdition.errors.stackoverflow')}
            name='stackoverflow'
            horizontal
          />
          {(customFields || []).map((customField) => (
            <CustomField
              key={customField.id}
              name={`customFields.${customField.id}`}
              definition={customField}
            />
          ))}
          <div className={styles.actions}>
            <Button
              type='button'
              className={styles.cancel}
              size='small'
              onClick={() => setEditMode(false)}
            >
              {t('reveal.profileEdition.cancel')}
            </Button>
            <Button
              primary
              type='submit'
              size='small'
              className={styles.submitButton}
            >
              {t('reveal.profileEdition.save')}
            </Button>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default compose(
  contextToProps,
  withTranslation('translations'),
)(PluginProfileEditForm);
