import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import ContactFlow from '../../graphql/fragments/ContactFlow';

export const updateSequence = gql`
  mutation updateSequenceTrigger(
    $jobOfferId: ID
    $contactFlowId: ID!
    $sequenceId: ID!
    $triggerInput: ContactFlowTriggerInput!
  ) {
    updateSequenceTrigger(
      jobOfferId: $jobOfferId
      contactFlowId: $contactFlowId
      sequenceId: $sequenceId
      triggerInput: $triggerInput
    ) {
      ...ContactFlow
    }
  }
  ${ContactFlow}
`;

export default graphql(updateSequence, {
  props: ({ ownProps, mutate }) => ({
    updateSequenceTrigger: ({ contactFlowId, sequenceId, triggerInput }) =>
      mutate({
        variables: {
          jobOfferId: ownProps.offerId,
          contactFlowId,
          sequenceId,
          triggerInput,
        },
      }),
  }),
});
