import React, { CSSProperties, FC } from 'react';
import { Placeholder } from 'semantic-ui-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useMiniSequence from '@/graphql/hooks/sequences/useMiniSequence';

import styles from './SequenceColumn.module.less';

interface SequenceColumnProps {
  sequenceId: string;
  day: string;
  profileLoading?: boolean;
  className?: string;
  style?: CSSProperties;
}

const SequenceColumn: FC<SequenceColumnProps> = ({
  sequenceId,
  day,
  profileLoading,
  className,
  style,
}) => {
  const { t, i18n } = useTranslation();

  const { loading, miniSequence } = useMiniSequence({ sequenceId });

  const sequenceDescription = t(
    'reveal.reports.sequences.dailyCohorts.detailsModal.enrolled',
    {
      date: new Date(day).toLocaleString(i18n.resolvedLanguage, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
    },
  );

  return (
    <div className={classNames(styles.sequence, className)} style={style}>
      {loading || profileLoading ? (
        <Placeholder>
          <Placeholder.Line length='short' />
        </Placeholder>
      ) : (
        <>
          <div className={styles.sequenceName}>
            {miniSequence?.title || '???'}
          </div>
          <div className={styles.description}>{sequenceDescription}</div>
        </>
      )}
    </div>
  );
};

export default SequenceColumn;
