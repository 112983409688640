import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'semantic-ui-react';
import DisplayPreferencesStyles from '../DisplayPreferences.module.less';
import ComplianceStyles from '../../RevealCompliance/RevealCompliance.module.less';

const RevealDisplayPreferences: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={ComplianceStyles.privacySection}>
      <h2 className={ComplianceStyles.title}>
        {t('settings.privacySettings.crmTitle')}
      </h2>
      <div className={ComplianceStyles.background}>
        <p className={ComplianceStyles.paragraph}>
          {t('settings.privacySettings.crmDescription')}
        </p>
        <div className={DisplayPreferencesStyles.content}>
          <div className={DisplayPreferencesStyles.leftPan}>
            <p className={DisplayPreferencesStyles.panTitle}>
              {t('settings.privacySettings.leftPanTitle')}
            </p>
            <p className={DisplayPreferencesStyles.subTitle}>
              {t('settings.privacySettings.personalInformation')}
            </p>
            <div className={DisplayPreferencesStyles.toggleContainer}>
              <Checkbox
                toggle
                label={t('settings.privacySettings.profilePicture')}
              />
            </div>
            <div className={DisplayPreferencesStyles.toggleContainer}>
              <Checkbox
                toggle
                label={t('settings.privacySettings.firstAndLastName')}
              />
            </div>
            <div className={DisplayPreferencesStyles.toggleContainer}>
              <Checkbox
                toggle
                label={t('settings.privacySettings.reveal.resume')}
              />
            </div>
            <p className={DisplayPreferencesStyles.subTitle}>
              {t('settings.privacySettings.educationInformation')}
            </p>
            <div className={DisplayPreferencesStyles.toggleContainer}>
              <Checkbox
                toggle
                label={t('settings.privacySettings.educationNames')}
              />
            </div>
            <div className={DisplayPreferencesStyles.toggleContainer}>
              <Checkbox
                toggle
                label={t('settings.privacySettings.educationDates')}
              />
            </div>
            <div className={DisplayPreferencesStyles.toggleContainer}>
              <Checkbox
                toggle
                label={t('settings.privacySettings.educationDescriptions')}
              />
            </div>
          </div>
          <div className={DisplayPreferencesStyles.rightPan} />
        </div>
      </div>
    </div>
  );
};

export default RevealDisplayPreferences;
