import React from 'react';
import { formatDates } from '../utils/date';
import {
  EnrichedField,
  fallbackForEnrichedText,
} from '../utils/enrichmentFallbacks';
import './PluginBackgroundTimeline.css';

type PluginBackgroundTimelineProps = {
  title: EnrichedField;
  startDate: any;
  endDate: any;
  workplace: string;
};

const PluginBackgroundTimeline: React.FC<PluginBackgroundTimelineProps> = ({
  title,
  workplace,
  startDate,
  endDate,
}) => {
  const dates = formatDates({ startDate, endDate });

  const text = fallbackForEnrichedText({
    field: title,
    fallback: '',
  });

  return (
    <div className='background-timeline-experience'>
      <div className='background-timeline-experienceBullet'>
        <div className='hsBulletPoint' />
      </div>
      <div className='background-timeline-experienceDetails'>
        <div className='background-timeline-experienceTitle'>
          {text && `${text} • `} {workplace}
        </div>
        <div className='background-timeline-experienceDates'>
          {dates && (
            <span>
              {dates.start} - {dates.end}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PluginBackgroundTimeline;
