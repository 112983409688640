import { useEffect, useState } from 'react';

type UseLocalStorageResult<T> = readonly [
  T,
  (value: T | ((val: T) => T)) => void,
];

/**
 * useState with persistence in localstorage
 * @param key key set inside localStorage
 * @param initialValue initial value if there is nothing in localstorage
 * @returns same a useState
 * @source https://usehooks.com/useLocalStorage/
 */
const useLocalStorage = <T>(
  key: string,
  initialValue: T,
): UseLocalStorageResult<T> => {
  const [value, setValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (_e) {
      return initialValue;
    }
  });

  useEffect(() => {
    const storageEventListener = ({
      key: eventKey,
      newValue,
    }: StorageEvent) => {
      if (key === eventKey) {
        try {
          setValue(JSON.parse(newValue || 'null') ?? initialValue);
        } catch (_e) {
          setValue(initialValue);
        }
      }
    };
    window.addEventListener('storage', storageEventListener);
    return () => window.removeEventListener('storage', storageEventListener);
  }, [key, initialValue]);

  useEffect(() => {
    try {
      const newValue = JSON.stringify(value);
      window.localStorage.setItem(key, newValue);
    } catch (_e) {
      /* Do nothing */
    }
  }, [value, key]);

  return [value, setValue] as const;
};

export default useLocalStorage;
