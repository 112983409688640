import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import TranslatableTextFragment from '@/graphql/fragments/TranslatableText';
import useClientId from '@/hooks/router/useClientId';
import { PipelineSubStepsDefinition } from '@/types/pipelineSubSteps';

const getPipelineSubSteps = gql`
  query getPipelineSubSteps($clientId: ID!) {
    client(id: $clientId) {
      id
      pipelineSubSteps {
        missionCategory {
          type
        }
        stepId
        subSteps {
          id
          title {
            ...TranslatableText
          }
        }
      }
    }
  }
  ${TranslatableTextFragment}
`;

type GetPipelineSubStepsData = {
  client: {
    id: string;
    pipelineSubSteps: PipelineSubStepsDefinition[];
  };
};

type GetPipelineSubStepsVariables = {
  clientId: string;
};

type UsePipelineSubStepsInput = {
  queryOptions?: QueryHookOptions<
    GetPipelineSubStepsData,
    GetPipelineSubStepsVariables
  >;
};

type UsePipelineSubStepsResult = QueryResult<
  GetPipelineSubStepsData,
  GetPipelineSubStepsVariables
> & {
  pipelineSubSteps: PipelineSubStepsDefinition[];
};

function usePipelineSubSteps({
  queryOptions = {},
}: UsePipelineSubStepsInput = {}): UsePipelineSubStepsResult {
  const clientId = useClientId();
  const query = useQuery<GetPipelineSubStepsData, GetPipelineSubStepsVariables>(
    getPipelineSubSteps,
    {
      ...queryOptions,
      variables: { clientId },
    },
  );
  const pipelineSubSteps = query.data?.client.pipelineSubSteps || [];
  return { ...query, pipelineSubSteps };
}

export default usePipelineSubSteps;
