import React, { useCallback, useEffect, useState } from 'react';
import _ from 'underscore';

import { Loader } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';

import { useTranslation } from 'react-i18next';
import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { combineDataWithCustomFields } from '@/common/customFields';
import NewProfileMissionsManagement from '@/revealComponents/ProfileProjectsTab/NewProfileMissionsManagement/NewProfileMissionsManagement';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import ProfileDetails from '@/revealComponents/ProfileDetails';
import withNotifications from '@/hocs/withNotifications';
import { REGISTER_REVEAL_PROFILE_VIEW } from '@/graphql/searchPoolProfiles';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import ProfileHiresweetDetails from '@/routes/RevealView/SearchView/CandidatesListPane/CandidateView/HiresweetDetails';
import PluginProfileHeader from './PluginProfileHeader';
import PluginProfileTabsMenu from './PluginProfileTabsMenu';
import PluginProfileOverview from './PluginProfileOverview';
import PluginProfileActivity from './PluginProfileActivity';
import PluginProfileEditForm from './components/PluginProfileEditForm';
import Notes from './components/Notes';
import PluginExperiences from './components/PluginExperiences';
import PluginEducations from './components/PluginEducations';
import JobAdmin from './components/JobAdmin';
import PluginProfileFooter from './PluginProfileFooter/PluginProfileFooter';

import styles from './PluginProfileView.module.less';

const PluginProfileView = ({
  clientId,
  extra,
  messagingClient,
  activeProjectState,
  profile: profileProps,
  shiftSidebar,
}: any) => {
  const { t } = useTranslation();
  const [activeTabId, setActiveTabId] = useState('overview');
  const [editMode, setEditMode] = useState(false);
  const { profileCustomFields: customFields } = useClientProfileCustomFields(
    clientId,
  );

  const { profile: searchPoolProfile } = useCandidateViewContext();

  const profile = profileProps || searchPoolProfile;

  const { isMinimized } = useMinimizedView();

  const refetchTasksForSideEffect = useCallback(() => {
    if (activeProjectState) {
      // Using if because linter does not like xxx?.method()
      activeProjectState.refetchTasks();
    }
  }, [activeProjectState]);

  useEffect(() => {
    const root = document.getElementById('root');
    if (!root) {
      return;
    }
    if (isMinimized) {
      root.classList.add('mini');
    } else {
      root.classList.remove('mini');
    }
  }, [isMinimized]);

  const [updateProfileViews] = useMutation(REGISTER_REVEAL_PROFILE_VIEW);

  useEffect(() => {
    if (!profile?.id) {
      return;
    }
    updateProfileViews({
      variables: {
        searchPoolId,
        profileId: profile.id,
        origin: {
          type: 'plugin',
          URL: window.location.href,
        },
      },
    });
    // eslint-disable-next-line
  }, []);

  const { education, experiences } = profile?.resumeData ?? {};

  const resumeData = profile?.resumeData || {};
  const customDetails = combineDataWithCustomFields(customFields, resumeData);
  const customFieldsInitialValues = _.object(
    customDetails.map((o) => [o.clientCustomFieldId, o.rawValue]),
  );

  const searchPoolId =
    (profile as any)?.__typename === 'RevealProfile' ? 'reveal' : 'watch';

  if (!profile) {
    return (
      <div className={styles.loaderContainer}>
        <Loader active inline='centered' />
      </div>
    );
  }

  if (editMode) {
    return (
      <PluginProfileEditForm
        customFields={customFields}
        setEditMode={setEditMode}
        profileId={profile?.id}
        initialValues={{
          firstname: resumeData?.firstname,
          lastname: resumeData?.lastname,
          headline: resumeData?.headline?.content?.text,
          email: resumeData?.email,
          phoneNumber: resumeData?.phoneNumber,
          linkedin: resumeData?.sources?.linkedin,
          github: resumeData?.sources?.github,
          stackoverflow: resumeData?.sources?.stackoverflow,
          customFields: customFieldsInitialValues,
        }}
      />
    );
  }

  return (
    <div className={styles.pluginLayout}>
      <div className={styles.pluginMain}>
        <div
          className={`${styles.header} ${isMinimized ? styles.minimized : ''}`}
        >
          <PluginProfileHeader
            toggleEditMode={setEditMode}
            clientId={clientId}
            watchProfile={profile}
          />
          {searchPoolId === 'reveal' && (
            <div className='mission-sequence-container'>
              <NewProfileMissionsManagement
                clientId={clientId}
                missionsInfo={profile?.missionsInfo}
                missions={_.pluck(profile?.missionsInfo || [], 'mission')}
                messagingClient={messagingClient}
                shiftSidebar={shiftSidebar}
                onChangeStage={refetchTasksForSideEffect}
                onSequenceEdited={refetchTasksForSideEffect}
              />
            </div>
          )}
          <PluginProfileTabsMenu
            tabOptions={[
              { id: 'overview', text: t('plugin.tabs.overview') },
              { id: 'activity', text: t('plugin.tabs.activity') },
              { id: 'information', text: t('plugin.tabs.information') },
              ...(profile?.hiresweetDetails && clientId === 'quentin-qa-demo'
                ? [
                    {
                      id: 'hs-data',
                      text: t('plugin.tabs.hsData'),
                    },
                  ]
                : []),
            ]}
            activeTabId={activeTabId}
            setActiveTabId={setActiveTabId}
          />
        </div>

        {activeTabId === 'overview' && searchPoolId === 'reveal' && (
          <PluginProfileOverview
            clientId={clientId}
            searchPoolId={searchPoolId}
            activeProjectState={activeProjectState}
            onSnoozeAction={(id) => {
              activeProjectState.markTaskAsCompleted({ taskId: id });
            }}
            onDeleteAction={(id) => {
              activeProjectState.markTaskAsCompleted({ taskId: id });
            }}
          />
        )}
        {activeTabId === 'overview' && searchPoolId === 'watch' && (
          <div className={styles.root}>
            <div className={styles.block}>
              <JobAdmin profileData={profile} profileUrl={extra.profileUrl} />
            </div>
            {experiences?.length > 0 && (
              <div className={styles.block}>
                <PluginExperiences experiences={experiences} dense />
              </div>
            )}
            {education?.length > 0 && (
              <div className={styles.block}>
                <PluginEducations educations={education} dense />
              </div>
            )}
            <div className={styles.block} id='hsSidebar-notes-block'>
              <Notes profile={profile} />
            </div>
          </div>
        )}
        {activeTabId === 'activity' && (
          <PluginProfileActivity
            clientId={clientId}
            profile={profile}
            searchPoolId={searchPoolId}
          />
        )}

        {activeTabId === 'information' && (
          <ProfileDetails
            customFields={customFields}
            profileId={profile.id}
            watchProfile={profile}
            isWatchProfile={searchPoolId === 'watch'}
            isPlugin
          />
        )}

        {activeTabId === 'hs-data' && (
          <ProfileHiresweetDetails details={profile?.hiresweetDetails || {}} />
        )}
      </div>
      {searchPoolId === 'reveal' && (
        <div className={styles.pluginFooter}>
          <PluginProfileFooter
            onMinimize={() => setActiveTabId('overview')}
            messagingClient={messagingClient}
          />
        </div>
      )}
    </div>
  );
};

export default withNotifications(PluginProfileView);
