import React, { ReactNode } from 'react';
import classnames from 'classnames';
import styles from './InfoBox.module.less';

interface InfoBoxPros {
  title?: string;
  description?: string;
  children?: ReactNode;
  className?: any;
}

const InfoBox: React.FC<InfoBoxPros> = ({
  title,
  description,
  children,
  className,
}) => {
  return (
    <div className={classnames(styles.infoBox, className)}>
      {title && <p className={styles.title}>{title}</p>}
      {description && <p className={styles.description}>{description}</p>}
      {children && [children]}
    </div>
  );
};

export default InfoBox;
