import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import { getRandomString } from '@/common';
import {
  Condition,
  CONDITION_SELECT_FIELDS,
  CONDITION_STRING_FIELDS,
  ConditionStringFields,
  ConditionSelectFields,
  SelectFieldCondition,
  StringFieldCondition,
  CustomFieldCondition,
  LinkedMissionCustomFieldCondition,
} from '@/common/mergeTags/utils';
import { useConditionsChainingContext } from '../useConditionsChainingContext';
import styles from '../ConditionsChaining.module.less';
import { getStatementConstructionOptions } from '../utils';
import useConditionsChainingCustomFields from '../useConditionsChainingCustomFields';

const ConditionsChainingDropdownStatement: React.FC<{
  value: string;
  nodeId: string;
  withConditionalOptions: boolean;
}> = ({ value, nodeId, withConditionalOptions }) => {
  const { t } = useTranslation();
  const {
    getStateTreeAfterReplacement,
    conditionsChainingState,
    setConditionsChainingState,
    clientId,
  } = useConditionsChainingContext();
  const {
    profileCustomFields,
    missionCustomFields,
  } = useConditionsChainingCustomFields({ clientId });

  const handleChangeConditionType = ({ newValue }: { newValue: string }) => {
    const isProfileCustomField = newValue.startsWith('profile-custom-field');
    if (isProfileCustomField) {
      const textTypeAndId = newValue.split('/');
      const newState = getStateTreeAfterReplacement<CustomFieldCondition>({
        node: conditionsChainingState,
        id: nodeId,
        newValue: {
          id: nodeId,
          type: 'field-condition',
          fieldCategory: 'custom-field',
          customFieldId: textTypeAndId[2],
          fieldType: textTypeAndId[1],
          accept: {
            id: getRandomString(12),
            ...(textTypeAndId[1] === 'select'
              ? {
                  type: 'string-equals',
                  targetText: '',
                }
              : {
                  type: 'is-known',
                }),
          },
        },
      });
      setConditionsChainingState(newState);
      return;
    }
    const isMissionCustomField = newValue.startsWith('mission-custom-field');
    if (isMissionCustomField) {
      const textTypeAndId = newValue.split('/');
      const newState = getStateTreeAfterReplacement<
        LinkedMissionCustomFieldCondition
      >({
        node: conditionsChainingState,
        id: nodeId,
        newValue: {
          id: nodeId,
          type: 'field-condition',
          fieldCategory: 'linked-mission-custom-field',
          customFieldId: textTypeAndId[2],
          fieldType: textTypeAndId[1],
          accept: {
            id: getRandomString(12),
            ...(textTypeAndId[1] === 'select'
              ? {
                  type: 'string-equals',
                  targetText: '',
                }
              : {
                  type: 'is-known',
                }),
          },
        },
      });
      setConditionsChainingState(newState);
      return;
    }
    const isFieldCondition = _.includes(CONDITION_STRING_FIELDS, newValue);
    if (isFieldCondition) {
      const newState = getStateTreeAfterReplacement<StringFieldCondition>({
        node: conditionsChainingState,
        id: nodeId,
        newValue: {
          id: nodeId,
          type: 'field-condition',
          fieldCategory: 'native',
          fieldId: newValue as ConditionStringFields,
          fieldType: 'string',
          accept: {
            id: getRandomString(12),
            type: 'is-known',
          },
        },
      });
      setConditionsChainingState(newState);
      return;
    }
    const isSelectCondition = _.includes(CONDITION_SELECT_FIELDS, newValue);
    if (isSelectCondition) {
      const newState = getStateTreeAfterReplacement<SelectFieldCondition>({
        node: conditionsChainingState,
        id: nodeId,
        newValue: {
          id: nodeId,
          type: 'field-condition',
          fieldCategory: 'native',
          fieldId: newValue as ConditionSelectFields,
          fieldType: 'select',
          accept: {
            id: getRandomString(12),
            type: 'string-equals',
            targetText: '',
          },
        },
      });
      setConditionsChainingState(newState);
      return;
    }
    if (newValue === 'and' || newValue === 'or') {
      const newState = getStateTreeAfterReplacement<Condition>({
        node: conditionsChainingState,
        id: nodeId,
        newValue: {
          id: nodeId,
          type: newValue,
          conditions: [
            {
              id: getRandomString(12),
              type: 'field-condition',
              fieldCategory: 'native',
              fieldId: 'firstname',
              fieldType: 'string',
              accept: {
                id: getRandomString(12),
                type: 'is-known',
              },
            },
          ],
        },
      });
      setConditionsChainingState(newState);
    }
  };

  return (
    <Dropdown
      className={styles.input}
      value={value}
      fluid
      selection
      options={getStatementConstructionOptions({
        t,
        withConditionalOptions,
        profileCustomFields,
        missionCustomFields,
      })}
      onChange={(e, { value: newValue }) =>
        handleChangeConditionType({ newValue: newValue as string })
      }
    />
  );
};

export default ConditionsChainingDropdownStatement;
