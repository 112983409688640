import gql from 'graphql-tag';
import {
  MutationHookOptions,
  useMutation,
  MutationResult,
  FetchResult,
} from '@apollo/client';
import { useCallback } from 'react';

import ContactFlow from '@/graphql/fragments/ContactFlow';
import { ContactFlowSequence } from '@/types/searchPoolProfile';

const generateAIVariable = gql`
  mutation generateAIVariable(
    $searchPoolId: ID!
    $input: GenerateAIVariableInput!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      generateAIVariable(input: $input) {
        profile {
          id
          contactFlow {
            ...ContactFlow
          }
        }
      }
    }
  }
  ${ContactFlow}
`;

type Data = {
  searchPoolProfile: {
    generateAIVariable: {
      profile: {
        id: string;
        contactFlow: {
          id: string;
          sequences: ContactFlowSequence[];
        };
      };
    };
  };
};

type GenerateAIVariableInput = {
  profileId: string;
  variableId: string;
  variableValue: string;
};

type Variables = {
  searchPoolId: string;
  input: GenerateAIVariableInput;
};

type Input = {
  searchPoolId?: string;
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

type Result = [
  (input: GenerateAIVariableInput) => Promise<FetchResult<Data>>,
  MutationResult<Data>,
];

function useGenerateAIVariable({
  searchPoolId = 'reveal',
  mutationOptions,
}: Input = {}): Result {
  const [mutation, result] = useMutation<Data, Variables>(
    generateAIVariable,
    mutationOptions,
  );

  const generate = useCallback(
    (input: GenerateAIVariableInput) =>
      mutation({ variables: { searchPoolId, input } }),
    [mutation, searchPoolId],
  );

  return [generate, result];
}

export default useGenerateAIVariable;
