import _ from 'underscore';

export type NonEmpty<T> = T extends (infer U)[] ? [U, ...U[]] : never;

export const pure = <T>(element: T): NonEmpty<T[]> => [element];

export const safelyRemoveItem = <T>(array: T[], index: number): T[] =>
  _.reject(array, (__, i) => i === index);

export default {};
