import React, { Dispatch, SetStateAction } from 'react';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { RepliedStageContent } from '@/components/PipelineSegmentation/MoveToStageAction';
import GenericModal from '@/components/Common/GenericModal';
import GenericButton from '@/components/Common/GenericButton';

interface CandidateReplyModalProps {
  reply: string;
  onReply: Dispatch<SetStateAction<string>>;
  candidatesLength: number;
  onClose: () => void;
  confirm: () => void;
  onCancel: () => void;
  onForceStage: (stage: string) => void;
}

const CandidateReplyModal: React.FC<CandidateReplyModalProps> = ({
  reply,
  onReply,
  candidatesLength,
  onClose,
  confirm,
  onCancel,
  onForceStage,
}) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    onCancel();
    onClose();
  };

  const handleConfirm = () => {
    confirm();
    onClose();
  };

  return (
    <GenericModal
      open
      onClose={() => onClose()}
      closeOnEscape
      closeOnDimmerClick={false}
      size='mini'
    >
      <Modal.Header>{t('reveal.modals.replyType.title')}</Modal.Header>
      <Modal.Content>
        <RepliedStageContent
          repliedAnswer={reply}
          setRepliedAnswer={onReply}
          moveStage={(stage) => {
            onForceStage(stage);
            onClose();
          }}
          candidatesLength={candidatesLength}
        />
      </Modal.Content>
      <Modal.Actions>
        <GenericButton primacy='secondary' onClick={handleCancel}>
          {t('common.cancel')}
        </GenericButton>
        <GenericButton primacy='primary' onClick={handleConfirm}>
          {t('common.confirm')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default CandidateReplyModal;
