import React from 'react';
import _ from 'underscore';
import { Segment, Loader, Message, Button } from 'semantic-ui-react';
import { Document as DocumentPDF, Page as PagePDF, pdfjs } from 'react-pdf';
import classNames from 'classnames';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import withAttachment from '../../../../hocs/attachments/withAttachment';

import './AttachmentViewer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class AttachmentViewerRawNoErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pdfWidth: null,
      numberOfPages: null,
      currentPage: 1,
    };
    this.containerElement = React.createRef();
  }

  setPdfWidth = (width) => {
    this.setState({ pdfWidth: width });
  };

  throttledSetPDFWidth = _.throttle(() => {
    const width = this.containerElement?.current?.clientWidth || 400;
    this.setPdfWidth(width - 48);
  }, 5 * 1000);

  componentDidMount() {
    this.throttledSetPDFWidth();
    // window.addEventListener('resize', this.throttledSetPDFWidth);
  }

  componentWillUnmount() {
    // window.removeEventListener('resize', this.throttledSetPDFWidth);
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numberOfPages: numPages });
  };

  render() {
    const { attachment, attachmentLoading, t, className } = this.props;
    const { pdfWidth, currentPage, numberOfPages } = this.state;

    if (attachment?.file?.url) {
      const fileUrl = new URL(attachment?.file?.url);
      const filePath = fileUrl.pathname;
      if (!filePath.match(/.pdf$/)) {
        if (filePath.match(/.txt$/)) {
          return (
            <div
              className={classNames('attachment-viewer', className)}
              ref={this.containerElement}
            >
              <object
                data={attachment?.file?.url}
                width='100%'
                height='250px'
                type='text/plain'
              >
                <Message error>
                  {t('profile.resume.attachments.loadingError')}
                </Message>
              </object>
            </div>
          );
        }
        return null;
      }
    }
    return (
      <div
        className={classNames('attachment-viewer', className)}
        ref={this.containerElement}
      >
        {attachmentLoading && (
          <Segment style={{ textAlign: 'center' }}>
            <Loader inline active />
          </Segment>
        )}
        {!attachmentLoading &&
          !attachment?.file?.content &&
          !attachment?.file?.url && (
            <Message error>
              {t('profile.resume.attachments.loadingError')}
            </Message>
          )}
        {!attachmentLoading &&
          (attachment?.file?.content || attachment?.file?.url) && (
            <Segment>
              <DocumentPDF
                file={attachment?.file?.content || attachment?.file?.url}
                onLoadSuccess={this.onDocumentLoadSuccess}
                error={t('profile.resume.attachments.loadingError')}
                loading={t('profile.resume.attachments.loading')}
              >
                <PagePDF pageNumber={currentPage} width={pdfWidth} />
              </DocumentPDF>
              {numberOfPages > 1 && (
                <div className='footer'>
                  <Button
                    disabled={currentPage <= 1}
                    onClick={() =>
                      this.setState({ currentPage: currentPage - 1 })
                    }
                  >
                    <i className='ri-arrow-left-s-line' />
                  </Button>
                  {t('profile.resume.attachments.page', {
                    currentPage,
                    numberOfPages,
                  })}
                  <Button
                    disabled={currentPage >= numberOfPages}
                    onClick={() =>
                      this.setState({ currentPage: currentPage + 1 })
                    }
                  >
                    <i className='ri-arrow-right-s-line' />
                  </Button>
                </div>
              )}
            </Segment>
          )}
      </div>
    );
  }
}

class AttachmentErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return <div>Something went wrong.</div>;
    }

    return children;
  }
}

export const AttachmentViewerRaw = (props) => {
  return (
    <AttachmentErrorBoundary>
      <AttachmentViewerRawNoErrorBoundary {...props} />
    </AttachmentErrorBoundary>
  );
};

export default withAttachment(AttachmentViewerRaw);
