import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalHeader as Header,
  ModalContent as Content,
  ModalActions as Actions,
  Input,
} from 'semantic-ui-react';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import withUpdateOffer from '../../../../hocs/offers/withUpdateOffer';

const EditJobTitleModal = ({ offer, onClose, updateOffer, t }) => {
  const [jobTitleInput, setJobTitleInput] = useState(offer?.title || '');

  const disableSubmit = !offer?.id || !jobTitleInput;

  const handleSubmit = async () => {
    try {
      const variables = {
        id: offer?.id,
        input: { title: jobTitleInput },
      };
      await updateOffer(variables);
      onClose();
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <GenericModal onClose={onClose} open size='tiny'>
      <Header>{t('offers.editTitleModal.header')}</Header>
      <Content>
        <div className='input-container'>
          <div className='input-label'>
            {t('offers.editTitleModal.titlePlaceholder')}
          </div>
          <div className='input-element'>
            <Input
              fluid
              value={jobTitleInput}
              onChange={(e, { value }) => setJobTitleInput(value)}
              placeholder={t('offers.editTitleModal.titlePlaceholder')}
            />
          </div>
        </div>
      </Content>
      <Actions className='modal-actions-buttons'>
        <div className='flex-spaced-between'>
          <GenericButton primacy='secondary' size='big' onClick={onClose}>
            {t('offers.editTitleModal.cancel')}
          </GenericButton>
          <GenericButton
            size='big'
            onClick={handleSubmit}
            disabled={disableSubmit}
          >
            {t('offers.editTitleModal.confirm')}
          </GenericButton>
        </div>
      </Actions>
    </GenericModal>
  );
};

export default compose(
  withTranslation('translations'),
  withUpdateOffer,
)(EditJobTitleModal);
