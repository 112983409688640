import _ from 'underscore';
import * as Sentry from '@sentry/browser';

const getFromFromMail = (mail, addr) => {
  let from;
  if (mail.aliases) {
    const { aliases } = mail;
    for (let i = 0; i < aliases.length; i += 1) {
      if (aliases[i][0] === addr) {
        from = `=?UTF-8?B?${btoa(unescape(encodeURIComponent(aliases[i][1])))}?= <${aliases[i][0]}>`;
        break;
      }
    }
  }
  if (mail.alias) {
    from = `=?UTF-8?B?${btoa(unescape(encodeURIComponent(mail.alias)))}?= <${addr}>`;
  }
  return from;
};

const getBodyFromMail = (mail) => mail.body + (mail.signature ? mail.signature : '');

// returns the (augmented) result of the sent mail
// (this result contains in particular the threadId)

let gmailClientPromise;

const sendMessage = ({ gapi, headersObj, senderAddress, message, defaultThreadId }) => {
  let email = '';
  let threadFrom;
  const keys = _.keys(headersObj);
  for (let i = 0; i < keys.length; i += 1) {
    if (headersObj[keys[i]] !== undefined) {
      let val = headersObj[keys[i]];
      if (keys[i] === 'From') {
        threadFrom = val;
      }
      if (keys[i] === 'Subject') {
        val = `=?UTF-8?B?${btoa(unescape(encodeURIComponent(val)))}?=`;
      }
      email += `${keys[i]}: ${val}\r\n`;
    }
  }

  const messageFormatted =
    `--94eb2c06031033e01305344a1eef
Content-Type: text/plain; charset=UTF-8


--94eb2c06031033e01305344a1eef
Content-Type: text/html; charset=UTF-8

${ 
    message 
    }\r\n` +
    `--94eb2c06031033e01305344a1eef--`;

  email += `\r\n${  messageFormatted}`;

  const emailFormatted = btoa(unescape(encodeURIComponent(email)))
    .replace(/\//g, '_')
    .replace(/\+/g, '-');

  return new Promise((resolve, reject) => {
    if (!gmailClientPromise) {
      gmailClientPromise = new Promise((resolve) => {
        gapi.client.load('gmail', 'v1', () => {
          resolve(gapi.client.gmail);
        });
      });
    }
    gmailClientPromise
      .then((gmailClient) => {
        const sendRequest = gmailClient.users.messages.send({
          userId: 'me',
          resource: {
            raw: emailFormatted,
            ...(defaultThreadId && { threadId: defaultThreadId }),
          },
        });
        return sendRequest.execute((res) => {
          const sentResult = {
            threadData: {
              from: senderAddress,
              to: headersObj.To,
              bcc: headersObj.Bcc,
              cc: headersObj.Cc,
              threadId: res ? res.threadId : '',
              subject: headersObj.Subject,
              body: message,
            },
            gmailResult: res,
            senderAddress,
          };
          if (threadFrom) {
            sentResult.threadFrom = threadFrom;
          }
          resolve(sentResult);
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const sendEmail = async ({
  gapi,
  auth2,
  currentGmailAddress,
  mail,
  bccAddresses,
  ccAddresses,
}) => {
  if (!auth2 || !auth2.isSignedIn.get()) {
    throw Error('Erreur de connexion, rechargez la page et recommencez !');
  }

  try {
    // check validity of access_token
    const currentGoogleUser = auth2.currentUser.get();
    const currentAuthResponse = currentGoogleUser.getAuthResponse();
    // if necesary, refresh the access_token
    if (currentAuthResponse && currentAuthResponse.expires_at && currentAuthResponse.expires_at < Date.now() + 30000) {
      await currentGoogleUser.reloadAuthResponse();
    }
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTags({ feature: 'gmail' });
      Sentry.captureException(error);
    });
  }

  const from = getFromFromMail(mail, currentGmailAddress);
  const body = getBodyFromMail(mail);
  return sendMessage({
    gapi,
    headersObj: {
      From: from,
      To: mail.dest,
      ...(!_.isEmpty(bccAddresses) && { Bcc: bccAddresses.join(',') }),
      ...(!_.isEmpty(ccAddresses) && { Cc: ccAddresses.join(',') }),
      Subject: mail.subject,
      'MIME-Version': '1.0',
      'Content-Type': 'multipart/alternative; boundary=94eb2c06031033e01305344a1eef',
    },
    senderAddress: currentGmailAddress,
    message: body,
    defaultThreadId: mail.defaultThreadId,
  });
};

const registerOffline = async ({ auth2, prompt = 'consent' }) => {
  const scopes = 'https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.readonly ';
  // 'https://www.googleapis.com/auth/userinfo.email '
  // + 'https://www.googleapis.com/auth/userinfo.profile '
  // + 'https://mail.google.com/ '
  // + 'https://www.google.com/m8/feeds '
  // + 'https://www.googleapis.com/auth/calendar';

  try {
    // grantofflineaccess is .thenAble but not .catchAble, hence the weird code here.
    // see https://developers.google.com/identity/sign-in/web/reference#googleauthgrantofflineaccessoptions
    const { code } = await auth2.currentUser
      .get()
      .grantOfflineAccess({
        scope: scopes,
        redirect_uri: 'postmessage',
        prompt,
      })
      .then(
        (res) => res,
        (error) => {
          throw error;
        },
      );
    return code;
  } catch (e) {
    if (e?.error !== 'popup_closed_by_user' && e?.error !== 'access_denied') {
      Sentry.withScope((scope) => {
        scope.setTags({ feature: 'gmail' });
        Sentry.captureException(e);
      });
      throw e;
    } else {
      throw Error(e.error);
    }
  }
};

const gmailApi = {
  registerOffline,
  sendEmail,
};

export default gmailApi;
