import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useLazyQuery } from '@apollo/client';
import { PipelineDailyEventDetails } from '@/types/statistics/pipelineActions';
import { isTodayYyyyMmDd } from '@/common/utils/time';
import { GetPipelineActionsStatisticsVariables } from './usePipelineActionsStatistics';

const GET_PIPELINE_ACTIONS_STATISTICS_DETAILS = gql`
  query getPipelineActionsStatisticsDetails(
    $clientId: ID!
    $missionsFilter: GenericStatsFilterInput
    $stagesFilter: GenericStatsFilterInput
    $authorEmailsFilter: GenericStatsFilterInput
    $startDate: Date
    $endDate: Date
  ) {
    client(id: $clientId) {
      id
      statistics {
        pipelineActionsByUser(
          missionsFilter: $missionsFilter
          stagesFilter: $stagesFilter
          authorEmailsFilter: $authorEmailsFilter
          startDate: $startDate
          endDate: $endDate
        ) {
          day
          userStats {
            author {
              email
              firstname
              lastname
            }
            stats {
              type
              missionId
              profileItems {
                profileId
              }
              archivedDetails {
                fromStage
                customArchiveReasonId
                category
              }
            }
          }
        }
      }
    }
  }
`;

interface GetPipelineActionsStatisticsDetailsData {
  client: {
    id: string;
    statistics: {
      pipelineActionsByUser: PipelineDailyEventDetails[];
    };
  };
}

interface UsePipelineActionsStatisticsDetailsInput {
  queryOptions?: QueryHookOptions<
    GetPipelineActionsStatisticsDetailsData,
    GetPipelineActionsStatisticsVariables
  >;
}

type UsePipelineActionsStatisticsDetailsResult = [
  (variables: GetPipelineActionsStatisticsVariables) => void,
  QueryResult<
    GetPipelineActionsStatisticsDetailsData,
    GetPipelineActionsStatisticsVariables
  > & {
    pipelineActionsByUser?: PipelineDailyEventDetails[];
  },
];

const usePipelineActionsStatisticsDetails = ({
  queryOptions = {},
}: UsePipelineActionsStatisticsDetailsInput = {}): UsePipelineActionsStatisticsDetailsResult => {
  const [fetchDetails, query] = useLazyQuery<
    GetPipelineActionsStatisticsDetailsData,
    GetPipelineActionsStatisticsVariables
  >(GET_PIPELINE_ACTIONS_STATISTICS_DETAILS, {
    ...queryOptions,
  });

  return [
    ({ endDate, ...otherVariables }) =>
      fetchDetails({
        variables: {
          ...otherVariables,
          endDate: isTodayYyyyMmDd(endDate) ? undefined : endDate,
        },
      }),
    {
      ...query,
      pipelineActionsByUser:
        query.data?.client.statistics.pipelineActionsByUser,
    },
  ];
};

export default usePipelineActionsStatisticsDetails;
