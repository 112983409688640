import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import _ from 'underscore';

import { MiniSequence } from '@/types/sequence';

export interface GetMiniSequencesData {
  client: {
    id: string;
    sequences: MiniSequence[];
  };
}

type GetMiniSequencesVariables = {
  clientId: string;
  filters?: {
    activeOnly?: boolean;
    sequenceIds?: string[];
  };
};

const GET_MINI_SEQUENCES = gql`
  query getMiniSequences(
    $clientId: ID!
    $filters: ClientSequencesFiltersInput
  ) {
    client(id: $clientId) {
      id
      sequences(filters: $filters) {
        id
        title
      }
    }
  }
`;

export interface UseMiniSequencesResult
  extends QueryResult<GetMiniSequencesData, GetMiniSequencesVariables> {
  miniSequences: MiniSequence[] | undefined;
}

interface useMiniSequencesInput {
  clientId: string;
  filters?: {
    activeOnly?: boolean;
    sequenceIds?: string[];
  };
  queryOptions?: QueryHookOptions<
    GetMiniSequencesData,
    GetMiniSequencesVariables
  >;
}

const useMiniSequences = ({
  clientId,
  filters = {},
  queryOptions = {},
}: useMiniSequencesInput): UseMiniSequencesResult => {
  const { activeOnly = false, sequenceIds = [] } = filters;

  const query = useQuery<GetMiniSequencesData, GetMiniSequencesVariables>(
    GET_MINI_SEQUENCES,
    {
      fetchPolicy: 'cache-first',
      ...(queryOptions || {}),
      variables: {
        clientId,
        filters: {
          activeOnly,
          ...(!_.isEmpty(sequenceIds) && { sequenceIds }),
        },
      },
    },
  );

  const miniSequences = query.data?.client.sequences;

  return { ...query, miniSequences };
};

export default useMiniSequences;
