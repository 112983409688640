import classNames from 'classnames';
import React, { ReactNode } from 'react';

import styles from './EmptyState.module.less';

interface EmptyStateProps {
  illustrationPath?: string;
  fallbackIllustrationPath?: string;
  title: string;
  innerContent?: ReactNode;
  cta?: ReactNode;
  className?: string;
  size?: 'medium' | 'small';
}

const EmptyState: React.FC<EmptyStateProps> = ({
  illustrationPath,
  fallbackIllustrationPath = '',
  title,
  innerContent,
  cta,
  className,
  size = 'medium',
}) => {
  return (
    <div className={classNames(styles.emptyState, className)}>
      {illustrationPath && (
        <div>
          <img
            className={classNames(size === 'small' && styles.smallImg)}
            src={illustrationPath}
            alt={title}
            onError={(e) => {
              if (fallbackIllustrationPath) {
                e.target.src = fallbackIllustrationPath;
              }
            }}
          />
        </div>
      )}
      <h1
        className={classNames(
          styles.title,
          size === 'small' && styles.smallTitle,
        )}
      >
        {title}
      </h1>
      {innerContent && (
        <div
          className={classNames(
            styles.innerContent,
            size === 'small' && styles.smallInnerContent,
          )}
        >
          {innerContent}
        </div>
      )}
      {cta && <div>{cta}</div>}
    </div>
  );
};

export default EmptyState;
