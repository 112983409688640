import {
  ATStype,
  GREENHOUSE,
  LEVER,
  RECRUITEE,
  SMARTRECRUITERS,
  TEAMTAILOR,
  SUCCESSFACTORS,
} from '@/common/reveal';

import { Application } from '@/common/reveal/SourceData.type';
import { getApplicationStatusText as getGreenhouseApplicationStatusText } from '../GreenhouseData';
import {
  getGenericApplicationStatusText,
  getLeverApplicationStatusText,
  getSmartrecruitersApplicationStatusText,
  getSuccessFactorsApplicationStatusText,
} from '../ATSDataUtils';

// TODO: use actual translation
export const getApplicationStatusText = ({
  sourceStatusId,
  stageName,
  lastStageName,
}: any): string => {
  if (sourceStatusId !== 'rejected') {
    return stageName || lastStageName || '??';
  }
  return `Archived${lastStageName ? ` (${lastStageName})` : ''}`;
};

export const getApplicationStageAndStatusText = (
  application: Application,
  atsType: ATStype,
): string => {
  const { status } = application || {};
  const { sourceStatusId, stage, rejection } = status || {};
  const stageName = stage?.sourceStageName;
  const lastStageName = rejection?.lastStage?.sourceStageName;
  const rejectionReason = rejection?.reason?.sourceRejectionReasonName;
  if (atsType === LEVER) {
    return getLeverApplicationStatusText({
      sourceStatusId,
      stageName,
      lastStageName,
      rejectionReason,
    });
  }
  if (atsType === TEAMTAILOR || atsType === RECRUITEE) {
    return getGenericApplicationStatusText({
      sourceStatusId,
      stageName,
      lastStageName,
      rejectionReason,
    });
  }
  if (atsType === SMARTRECRUITERS) {
    return getSmartrecruitersApplicationStatusText({
      sourceStatusId,
      stageName,
      rejectionReason,
    });
  }
  if (atsType === GREENHOUSE) {
    return getGreenhouseApplicationStatusText({
      sourceStatusId,
      stageName,
      rejectionReason,
    });
  }

  if (atsType === SUCCESSFACTORS) {
    return getSuccessFactorsApplicationStatusText({
      sourceStatusId,
      stageName,
      rejectionReason,
    });
  }

  return getApplicationStatusText({ sourceStatusId, stageName, lastStageName });
};
