import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import UnifiedViewContext from '@/containers/Parameters/Offers/UnifiedViewContext';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import FreeTrialBanner from '@/components/FreeTrial/FreeTrialBanner';
import { getOSFromPlatform } from '../Profile/Contact/ProfileContactFlow/Email/ConfirmationModal/helpers';
import Header from '../TopBar/Header';
import MainFooter from '../../components/MainFooter';
import { useNavApplicationMode } from '../TopBar/Header/NavSwitch.hook';

import styles from './MainLayout.module.less';
import TranslationOverride from '../TranslationOverride';

interface MainLayoutProps {
  clientId: string;
  pageId: string;
  tabId: string;
  hideFooter?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  clientId,
  pageId,
  tabId,
  children,
  hideFooter,
}) => {
  const { pathname } = useLocation();
  const OSCode = getOSFromPlatform();
  const { applicationMode } = useNavApplicationMode();
  const contentContainer = useRef<HTMLDivElement>(null);
  const { data, loading: permissionsLoading } = useClientPermissions(clientId);

  // scroll back to top on page change
  useEffect(() => {
    const mainLayoutElement = contentContainer.current;
    if (mainLayoutElement) {
      mainLayoutElement.scrollTo(0, 0);
    }
  }, [pathname]);

  const shouldHideFooter =
    hideFooter || (applicationMode === 'reveal' && pageId !== 'settings');

  if (permissionsLoading) {
    return null;
  }

  const unifiedView = !!data?.client.permissions?.marketplaceUnifiedView;

  return (
    <div className={classNames(styles.sweetappContainer, OSCode)}>
      <UnifiedViewContext.Provider value={{ unifiedView }}>
        <TranslationOverride>
          <div className={styles.headerContainer}>
            <FreeTrialBanner />
            <div
              className={classNames(styles.topHeader, {
                [styles.classic]: applicationMode === 'classic',
              })}
            >
              <Header clientId={clientId} pageId={pageId} tabId={tabId} />
            </div>
          </div>
          <div className={styles.contentContainer} ref={contentContainer}>
            {children}
            {!shouldHideFooter && <MainFooter clientId={clientId} />}
          </div>
        </TranslationOverride>
      </UnifiedViewContext.Provider>
    </div>
  );
};

export default MainLayout;
