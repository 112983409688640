import React from 'react';
import classNames from 'classnames';
import {
  ModalActions as Actions,
  ModalContent as Content,
  ModalHeader as Header,
} from 'semantic-ui-react';

import { useTranslation } from 'react-i18next';
import GenericModal from '@/components/Common/GenericModal';

import './TargetAccountModal.less';
import GenericButton from '@/components/Common/GenericButton';

interface WrongEmailModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  addressToConnect: string;
  currentAddress: string;
  onClose: () => void;
}

const WrongEmailModal: React.FC<WrongEmailModalProps> = ({
  open,
  setOpen,
  addressToConnect,
  currentAddress,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <GenericModal
      open={open}
      onClose={() => {
        onClose();
        setOpen(false);
      }}
      className={classNames('target-account-modal', 'add-account')}
      size='tiny'
    >
      <Header>{t('settings.targetAccount.wrongEmail.title')}</Header>
      <Content>
        {t('settings.targetAccount.wrongEmail.message', {
          currentAddress,
          addressToConnect,
        })}
      </Content>
      <Actions>
        <div className='modal-actions'>
          <GenericButton
            type='submit'
            onClick={() => setOpen(false)}
            size='big'
            disabled={!currentAddress}
          >
            {t('settings.targetAccount.wrongEmail.button')}
          </GenericButton>
        </div>
      </Actions>
    </GenericModal>
  );
};

export default WrongEmailModal;
