import CustomActionExecutionFragment from '@/graphql/fragments/CustomActionExecutionFragment';
import useClientId from '@/hooks/router/useClientId';
import { gql, useQuery } from '@apollo/client';
import ContactFlowActionFormatFragment from '@/graphql/fragments/ContactFlowActionFormat';
import { ClientCustomAction } from '@/types/customAction';

export const GET_CLIENT_CUSTOM_ACTIONS = gql`
  query getClientCustomActions($clientId: ID!) {
    client(id: $clientId) {
      id
      customActions {
        id
        type
        completionButtonDisplay {
          title {
            default
          }
        }
        execution {
          ...CustomActionExecutionFragment
        }
        icon {
          id
          url
          type
          emojiText
        }
        title {
          default
        }
        format {
          ...ContactFlowActionFormatFragment
        }
      }
    }
  }
  ${ContactFlowActionFormatFragment}
  ${CustomActionExecutionFragment}
`;

export function useClientCustomActions() {
  const clientId = useClientId();
  const { data, ...rest } = useQuery(GET_CLIENT_CUSTOM_ACTIONS, {
    variables: {
      clientId,
    },
  });

  return {
    // for demo purposes client does not exist, so we return an empty array
    clientCustomActions: (data?.client?.customActions ||
      []) as ClientCustomAction[],
    data,
    ...rest,
  };
}
