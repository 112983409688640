import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { Image, Segment, Divider, Dropdown, Popup } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { UPDATE_PROFILE_PROCESS_STEP } from '@/graphql/profile';
import ProfileFavoriteButton from '@/containers/Profile/Resume/Card/ProfileFavoriteButton';
import withAddSearchProfileToJob from '@/hocs/searchPool/withAddSearchProfileToJob';
import ProfileGlobalComment from '@/containers/Profile/Resume/Card/ProfileGlobalComment';
import { isProfileActive } from '@/common/helpers/followup';
import { getFullname } from '@/common/helpers/person';
import DisqualifyModal from '@/containers/Profile/modals/DisqualifyModal';
import {
  getRandomDefaultAvatarLink,
  getSecureLink,
  getTranslatedText,
} from '@/common';

import KanbanCardChangeAnswer from './KanbanCardChangeAnswer';

import './FollowupKanbanCard.css';

const replacePhotoLink = (link) => {
  return (link || '').replace('shrinknp_400_400', 'shrinknp_75_75');
};

const getAnswerIcon = (replyType) => {
  if (replyType === 'positive') {
    return 'ri-user-smile-line';
  }
  if (replyType === 'negative') {
    return 'ri-emotion-sad-line';
  }
  return 'ri-user-smile-line grey';
};

const getAnswerStatus = (replyType) => {
  if (replyType === 'positive') {
    return 'watchCollect.followupView.kanban.card.positiveReply';
  }
  if (replyType === 'negative') {
    return 'watchCollect.followupView.kanban.card.negativeReply';
  }
  return 'watchCollect.followupView.kanban.card.replyProcessing';
};

const getAnswerContent = (replyType) => {
  if (replyType === 'positive') {
    return 'watchCollect.followupView.kanban.card.positiveReplyPopup';
  }
  if (replyType === 'negative') {
    return 'watchCollect.followupView.kanban.card.negativeReplyPopup';
  }
  return 'watchCollect.followupView.kanban.card.replyProcessingPopup';
};

export const getMilestoneStageOptions = (t) => [
  {
    key: 'step1',
    value: 'milestone-1',
    text: t('watchCollect.followupView.kanban.card.step', { step: 1 }),
    selected: true,
  },
  {
    key: 'step2',
    value: 'milestone-2',
    text: t('watchCollect.followupView.kanban.card.step', { step: 2 }),
  },
  {
    key: 'step3',
    value: 'milestone-3',
    text: t('watchCollect.followupView.kanban.card.step', { step: 3 }),
  },
  {
    key: 'offer',
    value: 'milestone-4',
    text: t('watchCollect.followupView.kanban.card.offer'),
  },
];

const FollowupKanbanCard = ({
  profile,
  jobOffers,
  onClick,
  onSkip,
  clientId,
  addSearchProfileToJob,
}) => {
  const { t } = useTranslation();
  const [openSkipModal, setOpenSkipModal] = useState(false);
  const [changeAnswerModalOpen, setChangeAnswerModalOpen] = useState(false);
  const [stepChangeMutation] = useMutation(UPDATE_PROFILE_PROCESS_STEP);

  const {
    id: profileId,
    resumeData,
    jobOfferId,
    firstContactDate,
    // processStep,
    answer,
    // lastStepUpdateTimestamp,
  } = profile || {};

  const {
    firstname,
    lastname,
    photoLink,
    prequalification,
    headline,
    location,
    step,
  } = resumeData || {};

  const isWatchProfile = prequalification?.type === 'hiresweet-watch';
  const defaultAvatarLink = getRandomDefaultAvatarLink(firstname + lastname);
  const avatarImageUrl = photoLink
    ? replacePhotoLink(photoLink)
    : defaultAvatarLink;
  const locationName = getTranslatedText((location || {}).name || '');
  const currentStage = profile?.processStep?.milestone?.id || 'milestone-1';

  const job = _.findWhere(jobOffers, { id: jobOfferId });

  const isActive = isProfileActive(profile);

  const replyType = step === 'answered' ? profile?.answer?.label : '';

  const onHandleSkip = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    setOpenSkipModal(true);
  };

  const dropdownOptions = _.map(jobOffers, (jobOffer) => ({
    text: jobOffer?.title,
    value: jobOffer.id,
    selected: false,
  }));

  const SkipModal = () => (
    <DisqualifyModal
      offerId={jobOfferId}
      profileId={profileId}
      stepId={resumeData?.step}
      searchText=''
      open={openSkipModal}
      onClose={() => setOpenSkipModal(false)}
      onSkip={onSkip}
    />
  );

  const handleStageChange = (e, { value }) => {
    stepChangeMutation({
      variables: {
        profileId,
        input: {
          milestoneId: value,
        },
      },
    });
  };

  const onJobLinkClick = (event) => {
    // Necessary to prevent profile modal open when click on job link
    event.preventDefault();
    event.stopPropagation();
    window.open(`/client/${clientId}/jobs/${job?.id}/profiles`, '_blank');
  };

  const handleJobChange = async (e, { value }) => {
    if (!value || _.isEmpty(value)) {
      return;
    }
    try {
      // @ts-ignore no properly typed because types wrongly guessed by TS from a JS file
      await addSearchProfileToJob({ id: profileId, jobOfferId: value });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <Segment
        className={`followup-kanban-card resume-data-card ${
          step === 'hired' ? 'hired-card' : ''
        } ${replyType} ${!isActive ? 'disabled' : ''}`}
      >
        <div className='skip-profile-button-container'>
          <div
            className='skip-profile-button'
            onClick={onHandleSkip}
            aria-hidden
          >
            <i className='ri-close-line ri-lg' />
          </div>
          <SkipModal />
        </div>
        <div className='card-body' onClick={onClick}>
          <div className='card-avatar'>
            <Image
              className='avatar-image'
              src={getSecureLink(avatarImageUrl)}
              circular
              onError={(e) => {
                e.target.src = defaultAvatarLink;
              }}
            />
            {step === 'hired' && (
              <div className='hired-avatar-icon'>
                <i className='ri-check-line ri-sm' />
              </div>
            )}
            {isWatchProfile && (
              <div className='header-annotations'>
                <ProfileFavoriteButton profile={profile} />
              </div>
            )}
          </div>

          <div className='card-info'>
            <h4 className='full-name'>
              {getFullname({ firstname, lastname })}
              {!isActive ? (
                <span className='profile-state'>{t('common.disabled')}</span>
              ) : (
                ''
              )}
            </h4>
            {step === 'replied' && (
              <div className='skip-icon-container' onClick={onHandleSkip}>
                <i className='ri-more-fill' />
              </div>
            )}
            <div className='headline'>
              <span className='headline-text'>
                {headline?.content?.text}
                {headline?.content?.text && locationName && <span> • </span>}
                {locationName}
              </span>
            </div>
            {job && (
              <Link
                onClick={onJobLinkClick}
                className='job'
                to={`/client/${clientId}/jobs/${job?.id}/profiles`}
              >
                {job?.title}
              </Link>
            )}
            {!job && (
              <Dropdown
                className='job-dropdown'
                placeholder={
                  <>
                    &#43; {t('watchCollect.followupView.kanban.card.addToJob')}
                  </>
                }
                fluid
                selection
                floating
                selectOnBlur={false}
                onChange={handleJobChange}
                options={dropdownOptions}
              />
            )}
            {/* <div className='sources-container'>
              {_.map(SOURCES, (sourceId) => {
                if (!sources || !sources[sourceId]) {
                  return null;
                }
                return (
                  <Source
                    key={sourceId}
                    source={{ sourceId, url: sources[sourceId] }}
                    onLogAction={logAction}
                    profileId={profile?.id}
                    type='profile'
                    t={t}
                  />
                );
              })}
            </div> */}
            <ProfileGlobalComment profile={profile} readOnly />
          </div>
        </div>
        {step === 'contacted' && (
          <>
            <Divider />
            <div className='card-footer'>
              <img
                className='task-icon'
                src='/images/icons/tasks/email-task.svg'
                alt='task icon'
              />
              <span className='reply-status'>
                {t('watchCollect.followupView.kanban.card.contacted')}
              </span>
              {firstContactDate && (
                <span className='reply-date'>
                  {t('watchCollect.followupView.kanban.card.on')}{' '}
                  {t('common.shortDate', {
                    date: new Date(firstContactDate),
                  })}
                </span>
              )}
            </div>
          </>
        )}
        {step === 'answered' && (
          <>
            <Divider />
            <div className='card-footer'>
              <Popup
                inverted
                trigger={
                  <span className='popup-trigger flex-grow'>
                    <i className={`${getAnswerIcon(replyType)} ri-lg`} />
                    <span className='reply-status'>
                      {t(`${getAnswerStatus(replyType)}`)}
                    </span>
                    {answer?.date && (
                      <span className='reply-date'>
                        {t('watchCollect.followupView.kanban.card.on')}{' '}
                        {t('common.shortDate', {
                          date: new Date(answer.date),
                        })}
                      </span>
                    )}
                  </span>
                }
                content={t(`${getAnswerContent(replyType)}`)}
              />

              <i
                className='ri-more-fill'
                aria-hidden
                id='change-answer-icon'
                onClick={() => setChangeAnswerModalOpen(true)}
              />

              {changeAnswerModalOpen && (
                <KanbanCardChangeAnswer
                  profileId={profileId}
                  setOpen={setChangeAnswerModalOpen}
                />
              )}
            </div>
          </>
        )}
        {step === 'in-process' && (
          <>
            <Divider />
            <div className='card-footer'>
              <span className='in-process-label'>
                {t('watchCollect.followupView.kanban.card.stage')}
              </span>
              <span className='in-process-stage'>
                <Dropdown
                  placeholder={t(
                    'watchCollect.followupView.kanban.card.selectStage',
                  )}
                  fluid
                  selection
                  floating
                  upward={false}
                  onChange={handleStageChange}
                  options={getMilestoneStageOptions(t)}
                  defaultValue={currentStage}
                />
              </span>
            </div>
          </>
        )}
      </Segment>
    </>
  );
};

export default _.compose(withAddSearchProfileToJob)(FollowupKanbanCard);
