import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import classNames from 'classnames';
import { StackItem } from '@/types/statistics/stackItem';
import { Stats } from '@/types/statistics/stats';
import TooltipContainer from '@/components/Common/TooltipContainer';
import { getPercentage, percentageToString } from '@/common/utils/number';
import { getTotalCount, getStackName } from '@/common/helpers/stats';

import styles from './ParallelBars.module.less';

interface ParallelBarsProps {
  stack: StackItem[];
  stats: Stats;
  topBoundary: number;
  isVertical?: boolean;
  showPercentage?: boolean;
}

const ParallelBars: FC<ParallelBarsProps> = ({
  stack,
  stats,
  topBoundary,
  isVertical,
  showPercentage,
}) => {
  const { t } = useTranslation();

  const tooltipContent = useMemo(
    () => (
      <div className={styles.tooltip}>
        <div className={styles.tooltipHeader}>
          <span>{stats.name}</span>
          <span>{getTotalCount(stats)}</span>
        </div>
        <div>
          {_.map(stack, (stackItem) => (
            <div className={styles.tooltipItem} key={stackItem.datakey}>
              <span className={styles.type}>
                <i
                  className={styles.box}
                  style={{ backgroundColor: stackItem.color }}
                />
                <span className={styles.label}>
                  {getStackName(t, stackItem)}
                </span>
              </span>
              <span>{stats.values[stackItem.datakey] || 0}</span>
            </div>
          ))}
        </div>
      </div>
    ),
    [stack, stats, t],
  );

  return (
    <TooltipContainer tooltipContent={tooltipContent}>
      <div className={styles.bars}>
        {_.map(stack, (stackItem) => (
          <button
            type='button'
            className={classNames(
              styles.bar,
              isVertical ? styles.vertical : styles.horizontal,
              stats.clickListeners?.[stackItem.datakey] && styles.clickable,
            )}
            key={stackItem.datakey}
            onClick={
              stats.clickListeners?.[stackItem.datakey] ||
              (() => {
                /* Do nothing */
              })
            }
          >
            <div
              className={styles.section}
              style={{
                backgroundColor: stackItem.color,
                flex: stats.values[stackItem.datakey],
              }}
            />
            <div
              className={styles.background}
              style={{
                flex: topBoundary - (stats.values[stackItem.datakey] || 0),
              }}
            >
              <span className={styles.totalCount}>
                {stats.values[stackItem.datakey] || 0}{' '}
                {showPercentage
                  ? `(${percentageToString(t)(
                      getPercentage(
                        stats.values[stackItem.datakey],
                        getTotalCount(stats),
                      ),
                    )})`
                  : ''}
              </span>
            </div>
          </button>
        ))}
      </div>
    </TooltipContainer>
  );
};

export default ParallelBars;
