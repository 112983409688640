import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './svg.module.less';

const Paper: FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    className={classNames(styles.svgIcon, className)}
    height='1.167em'
    viewBox='0 0 12 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.66667 3.00001V1.00001C2.66667 0.823199 2.7369 0.65363 2.86193 0.528606C2.98695 0.403581 3.15652 0.333344 3.33333 0.333344H11.3333C11.5101 0.333344 11.6797 0.403581 11.8047 0.528606C11.9298 0.65363 12 0.823199 12 1.00001V10.3333C12 10.5102 11.9298 10.6797 11.8047 10.8047C11.6797 10.9298 11.5101 11 11.3333 11H9.33333V13C9.33333 13.368 9.03333 13.6667 8.662 13.6667H0.671333C0.583418 13.6672 0.496265 13.6504 0.414882 13.6171C0.333498 13.5838 0.259489 13.5348 0.197106 13.4729C0.134723 13.4109 0.0851953 13.3373 0.0513695 13.2561C0.0175437 13.175 8.62495e-05 13.0879 0 13L0.00200001 3.66668C0.00200001 3.29868 0.302 3.00001 0.672667 3.00001H2.66667ZM1.33467 4.33334L1.33333 12.3333H8V4.33334H1.33467ZM4 3.00001H9.33333V9.66668H10.6667V1.66668H4V3.00001ZM2.66667 6.33334H6.66667V7.66668H2.66667V6.33334ZM2.66667 9.00001H6.66667V10.3333H2.66667V9.00001Z'
      fill='currentColor'
    />
  </svg>
);

export default Paper;
