import React from 'react';

export default (WrappedComponent) =>
  class withSignin extends React.Component {
    handlePartialSignin = async ({ email, password }) => (
      fetch(`${process.env.REACT_APP_SERVER_URL}/auth/global-signin/identifier`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          email: email ? email.toLowerCase() : null,
          password,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
          const token = res.headers.get('Authorization');
          localStorage.setItem('token', token);
          return res.json();
        })
        .then(async (response) => {
          return (response || {}).clients || [];
        })
    )

    handleClientSignin = async ({ clientId }) => {
      const token = localStorage.getItem('token');
      return fetch(`${process.env.REACT_APP_SERVER_URL}/auth/global-signin/client`, {
        headers: {
          'Content-Type': 'application/json',
          ...token && { 'Authorization': token }
        },
        method: 'POST',
        body: JSON.stringify({
          clientId,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
          const token = res.headers.get('Authorization');
          localStorage.setItem('token', token);
          return res.json();
        })
        .then(async (response) => {
          return response;
        })
    }

    render() {
      return <WrappedComponent
        onGlobalPartialSignin={this.handlePartialSignin}
        onGlobalClientSignin={this.handleClientSignin}
        {...this.props}
      />;
    }
  };
