import React, { useEffect, useState } from 'react';
import { Table, TableCell } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import GenericButton from '@/components/Common/GenericButton';
import Edit from '@/components/Reveal/Icons/Edit';
import Segmentations from '@/components/PipelineSegmentation/data';
import styles from './JobPipelineTable.module.less';
import useMissionSegmentations from '../JobView/ProjectProfilesTab/useMissionSegmentations';

interface JobPipelineTableProps {
  jobId: string;
  onSaveSteps: () => void;
  onOpen: (value: boolean) => void;
}

const DEFAULT_STEP_IDS = _.pluck(Segmentations[0]?.segments, 'id');

const JobPipelineTable: React.FC<JobPipelineTableProps> = ({
  jobId,
  onSaveSteps,
  onOpen,
}) => {
  const { t } = useTranslation();
  const [shouldSave, setShouldSave] = useState(false);
  const missionSegmentations = useMissionSegmentations(jobId, true);

  useEffect(() => {
    if (shouldSave) {
      onSaveSteps();
      setShouldSave(false);
    }
  }, [shouldSave, setShouldSave, onSaveSteps]);

  const defaultSegmentation = _.findWhere(missionSegmentations, {
    translationKey: 'default',
  });
  const segmentWithSubSteps = _.map(
    defaultSegmentation?.segments || [],
    (segment) => {
      const missionSegmentation = _.findWhere(missionSegmentations, {
        translationKey: segment.id,
      });
      return {
        ...segment,
        subSteps: missionSegmentation?.segments || [],
      };
    },
  );

  return (
    <Table basic className={styles.table}>
      <Table.Header className={styles.header}>
        <Table.Row>
          <Table.HeaderCell className={styles.th} collapsing />
          <Table.HeaderCell className={styles.th}>
            <span>
              {t('reveal.missions.mission.settingsTab.pipeline.table.step')}
            </span>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.th}>
            <span>
              {t('reveal.missions.mission.settingsTab.pipeline.table.substep')}
            </span>
          </Table.HeaderCell>
          <Table.HeaderCell collapsing />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(segmentWithSubSteps, (segment, index) => (
          <Table.Row key={segment.id} className={styles.row}>
            <Table.Cell textAlign='center' className={styles.number}>
              {index + 1}
            </Table.Cell>
            <Table.Cell textAlign='left' className={styles.name}>
              <span>
                {_.includes(DEFAULT_STEP_IDS, segment.id)
                  ? t(`reveal.pipelineSegmentations.${segment.id}`)
                  : segment.name}
              </span>
            </Table.Cell>
            <Table.Cell textAlign='left'>
              <div className={styles.subtypeContainer}>
                {_.map(
                  _.filter(segment.subSteps, (substep) => !substep.isDisabled),
                  (substep) => (
                    <span className={styles.subtype} key={substep.id}>
                      {substep.name}
                    </span>
                  ),
                )}
              </div>
            </Table.Cell>
            {segment.id === 'interested' ? (
              <Table.Cell>
                <GenericButton onClick={() => onOpen(true)}>
                  <Edit />
                  {t('common.edit')}
                </GenericButton>
              </Table.Cell>
            ) : (
              <TableCell />
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default JobPipelineTable;
