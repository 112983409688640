import _ from 'underscore';
import React, { useContext } from 'react';
import * as Sentry from '@sentry/browser';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAddProfilesToMissionAndSequenceMutation } from '@/graphql/searchPoolProfiles';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import { ProfileMatch } from '@/graphql/searchPoolProfileMatches';
import useClientId from '@/hooks/router/useClientId';
import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';
import MissionCategoryContext from '@/context/MissionCategoryContext';

import { getSkillsTagsFromCriteria } from '../../revealHelpers/tags';
import { CriteriaTags } from '../CriteriaTags';
import MatchLevelGauge from '../RevealListRow/MatchingDetails/MatchLevelGauge';
import { getGreenhouseJobURL } from '../../../../common/reveal/greenhouseURL';
import EmptyState from '../EmptyState/EmptyState';

import './RelevantJobs.css';

interface RelevantJobsProps {
  profileId: string;
  matches: ProfileMatch[];
  missions?: { id: string; data: { title: string } }[];
}

const RelevantJobs: React.FC<RelevantJobsProps> = ({
  profileId,
  matches,
  missions,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { profile } = useCandidateViewContext();
  const [
    addProfilesToMissionAndSequence,
  ] = useAddProfilesToMissionAndSequenceMutation();
  const missionCategory = useContext(MissionCategoryContext);

  const missionIds = _.pluck(missions || [], 'id');
  const filteredMatches = _.reject(matches, ({ job }) =>
    _.contains(missionIds, job?.id),
  );

  if (_.isEmpty(filteredMatches)) {
    return (
      <EmptyState
        title={t('reveal.profileModal.emptyStates.relevantProjects.title', {
          firstname: profile.resumeData?.firstname,
        })}
        innerContent={t(
          'reveal.profileModal.emptyStates.relevantProjects.innerContent',
          { firstname: profile.resumeData?.firstname },
        )}
        illustrationPath='/images/placeholders/projectsEmptyState.svg'
      />
    );
  }

  const onAddToMission = async ({ missionId }: { missionId?: string }) => {
    try {
      await addProfilesToMissionAndSequence({
        variables: {
          searchPoolId: 'reveal',
          input: {
            missionId,
            profileIds: [profileId],
          },
        },
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className='profile-relevant-jobs'>
      {_.map(matches, ({ job, score }, index) => {
        const greenhouseJobId = job?.sourceData?.greenhouse?.sourceJobId;
        const greenhouseURI = getGreenhouseJobURL(greenhouseJobId || '');
        const { skills } = job?.criteria || {};
        const sanitizedScoreValue = Math.max(Math.min(score?.value || 0, 1), 0);
        const skillTags = getSkillsTagsFromCriteria({ skills });
        return (
          <div key={index} className='job'>
            <div className='job-title'>
              <Link
                to={`/client/${clientId}/reveal/projects/${missionCategory}/${job?.id}`}
              >
                {job?.title}
              </Link>
              {greenhouseURI && (
                <a
                  href={greenhouseURI}
                  target='__blank'
                  rel='noopener noreferrer'
                >
                  <i className='ri-share-box-line' />
                </a>
              )}

              <div className='hs-spacer' />

              <GenericButton
                onClick={() => onAddToMission({ missionId: job?.id })}
              >
                <Plus />
                {t('reveal.profileModal.addToMission')}
              </GenericButton>
            </div>

            <div className='criteria-and-score'>
              {sanitizedScoreValue && (
                <div className='job-score'>
                  <MatchLevelGauge score={sanitizedScoreValue} positive />
                </div>
              )}
              <CriteriaTags
                header={t('reveal.profileModal.matchingSkills')}
                tags={skillTags}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RelevantJobs;
