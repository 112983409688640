import { getTranslatedText } from '@/common';
import DropdownMenuItem from '@/components/Common/DropdownMenuItem';
import DropdownPanel from '@/components/Common/DropdownPanel';
import GenericButton from '@/components/Common/GenericButton';
import GenericDropdown from '@/components/Common/GenericDropdown';
import Dots from '@/components/Common/Icons/Dots';
import Delete from '@/components/Reveal/Icons/Delete';
import Edit from '@/components/Reveal/Icons/Edit';
import Plus from '@/components/Reveal/Icons/Plus';
import { TranslatableText } from '@/types/text';
import classnames from 'classnames';
import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import styles from '../SuperPipelineSettings.module.less';

interface SuperPipelineSegmentRowSubstepsProps {
  substeps: {
    id: string;
    title: TranslatableText;
  }[];
  onHoverSubstep: (id: string) => void;
  substepIdsWithVisibleSubstepAddition: string[];
  onOpenNewSubstepModal: (open: boolean) => void;
  onNewSubstepIndex: (index: number) => void;
  onDeleteSubstep: ({ substepId }: { substepId: string }) => void;
  onSelectedSubstep: (substepId: string) => void;
  hoveredSubstepIndex: number;
  onOpenSubstepNameEditionModal: (open: boolean) => void;
  onChangeSubstepName: (name: string) => void;
  substepEditMode: boolean;
  onSubstepEditMode: (value: boolean) => void;
}

const SuperPipelineSegmentRowSubsteps: React.FC<SuperPipelineSegmentRowSubstepsProps> = ({
  substeps,
  onHoverSubstep,
  substepIdsWithVisibleSubstepAddition,
  onOpenNewSubstepModal,
  onNewSubstepIndex,
  onDeleteSubstep,
  onSelectedSubstep,
  hoveredSubstepIndex,
  onOpenSubstepNameEditionModal,
  onChangeSubstepName,
  substepEditMode,
  onSubstepEditMode,
}) => {
  const { t } = useTranslation();

  if (_.isEmpty(substeps)) {
    return null;
  }

  return (
    <div className={styles.stepExtension}>
      {_.map(substeps, (substep, substepIndex) => (
        <div
          onMouseOver={() => onHoverSubstep(substep.id)}
          onMouseLeave={() => onHoverSubstep('')}
          onFocus={() => {}}
          key={substep.id}
        >
          {substepEditMode && (
            <div
              className={classnames(
                styles.newSubstepContainer,
                _.contains(substepIdsWithVisibleSubstepAddition, substep.id) &&
                  styles.newSubstepVisible,
              )}
              onClick={() => {
                onOpenNewSubstepModal(true);
                onNewSubstepIndex(substepIndex as number);
              }}
            >
              <Plus />
              &nbsp;
              {t('superPipelineSettings.addSubstep')}
            </div>
          )}
          <div className={styles.substepContainer} key={substep.id}>
            <div className={styles.substepNumber}>{substepIndex + 1}</div>
            <div className={styles.substep}>
              {getTranslatedText(substep.title)}
            </div>
            <GenericDropdown
              position='right'
              Trigger={({ onClick }) => (
                <GenericButton
                  primacy='tertiary'
                  onClick={onClick}
                  className={styles.actionButton}
                >
                  <Dots />
                </GenericButton>
              )}
            >
              <DropdownPanel className={styles.panel}>
                <DropdownMenuItem
                  className={styles.panelOption}
                  key='interested-edit-substep'
                  onClick={() => {
                    onSelectedSubstep(substep.id);
                    onChangeSubstepName(getTranslatedText(substep.title));
                    onOpenSubstepNameEditionModal(true);
                  }}
                >
                  <Edit className={styles.panelOptionIcon} />
                  <span>{t('common.edit')}</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className={styles.panelOption}
                  key='interested-delete-substep'
                  onClick={() =>
                    onDeleteSubstep({
                      substepId: substep.id,
                    })
                  }
                >
                  <Delete className={styles.panelOptionIcon} />
                  <span>{t('common.delete')}</span>
                </DropdownMenuItem>
              </DropdownPanel>
            </GenericDropdown>
          </div>
        </div>
      ))}
      {substepEditMode && (
        <div
          onMouseOver={() => onHoverSubstep(substeps[_.size(substeps) - 1].id)}
          onMouseLeave={() => onHoverSubstep('')}
          onFocus={() => {}}
        >
          <div
            className={classnames(
              styles.newSubstepContainer,
              hoveredSubstepIndex === substeps.length - 1 &&
                styles.newSubstepVisible,
            )}
            onClick={() => {
              onOpenNewSubstepModal(true);
              onNewSubstepIndex(substeps.length);
            }}
          >
            <Plus />
            &nbsp;
            {t('superPipelineSettings.addSubstep')}
          </div>
        </div>
      )}
      {substepEditMode ? (
        <GenericButton
          className={styles.substepActions}
          primacy='secondary'
          onClick={() => onSubstepEditMode(false)}
        >
          {t('common.cancel')}
        </GenericButton>
      ) : (
        <GenericButton
          className={styles.substepActions}
          primacy='secondary'
          onClick={() => onSubstepEditMode(true)}
        >
          {t('superPipelineSettings.addSubstep')}
        </GenericButton>
      )}
    </div>
  );
};

export default SuperPipelineSegmentRowSubsteps;
