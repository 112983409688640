import _, { compose } from 'underscore';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import { User } from '@/types/user';
import { MailTemplate } from '@/types/mailTemplate';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import { SEQUENCE_STATES } from '@/common/constants/taskTypes';
import Alert from '@/components/Common/Alert/Alert';
import withEmailContextConsumer from '../../../../../../../hocs/email/withEmailContextConsumer';
import withClientTemplates from '../../../../../../../hocs/templates/withClientTemplates';
import withUserSettings from '../../../../../../../hocs/users/withUserSettings';
import DisplayedSequence from '../../../../../revealComponents/ProfileContactFlow/Sequences/DisplayedSequence';

import styles from './MessageTab.module.less';
import UnipileNewMessage from '../UnipileNewMessage';

interface MessageTabProps {
  clientId: string;
  profile: SearchPoolProfile;
  user: User;
  emailApi: any;
  templates: MailTemplate[];
  canShowDefaultTemplateReplies?: boolean;
  messageType?: string;
  metadata?: {
    key: string;
    value: string;
  }[];
  onClose?: () => void;
  profileName?: string;
  profilePhoneNumber?: string;
}

const MessageTab: React.FC<MessageTabProps> = ({
  clientId,
  profile,
  user,
  emailApi,
  templates,
  canShowDefaultTemplateReplies,
  messageType,
  metadata,
  onClose,
  profileName,
  profilePhoneNumber,
}) => {
  const { t } = useTranslation();
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();

  const currentSequence = _.findWhere(profile?.contactFlow?.sequences || [], {
    isCurrent: true,
  });

  let displayedSequence = currentSequence;
  const currentSequenceFirstAction = (currentSequence?.actions || [])[0];
  if (
    currentSequenceFirstAction?.completion?.isCompleted ||
    currentSequence?.completion?.isCompleted
  ) {
    displayedSequence = _.findWhere(profile?.contactFlow?.sequences || [], {
      isDraft: true,
    });
  }

  if (profile.privacyState?.markedAsDoNotContact) {
    return (
      <CannotBeContacted
        title={t('profile.actionButtons.cannotBeContacted.doNoContact.title')}
        description={t(
          'profile.actionButtons.cannotBeContacted.doNoContact.description',
        )}
      />
    );
  }

  if (
    !_.isEmpty(profile?.currentSequenceInfo?.sequence) &&
    profile?.currentSequenceInfo?.state !== SEQUENCE_STATES.COMPLETED &&
    !currentSequence?.completion?.isCompleted
  ) {
    return (
      <CannotBeContacted
        title={t('profile.actionButtons.cannotBeContacted.enrolled.title')}
        description={t(
          'profile.actionButtons.cannotBeContacted.enrolled.description',
        )}
      />
    );
  }

  const onActivitySend = () => {
    publishSubscriptionEvent('onProfileTimelineUpdate', {
      id: profile?.id,
    });
  };

  if (
    messageType === 'unipile-whatsapp' ||
    messageType === 'unipile-linkedin-message'
  ) {
    if (messageType === 'unipile-whatsapp') {
      if (!profilePhoneNumber) {
        return (
          <CannotBeContacted
            title={t(
              'profile.actionButtons.cannotBeContacted.whatsappNoPhoneNumber.title',
            )}
            description={t(
              'profile.actionButtons.cannotBeContacted.whatsappNoPhoneNumber.description',
            )}
          />
        );
      }
    }
    if (messageType.includes('unipile-linkedin')) {
      if (!profile?.resumeData?.sources?.linkedin) {
        return (
          <CannotBeContacted
            title={t(
              'profile.actionButtons.cannotBeContacted.missingLinkedin.title',
            )}
            description={t(
              'profile.actionButtons.cannotBeContacted.missingLinkedin.description',
            )}
          />
        );
      }
    }

    return (
      <div className='actions-tab email-tab timeline-default-card-wrapper'>
        {!_.findWhere(metadata || [], {
          key: 'canSend',
        })?.value && (
          <Alert title={t('common.warning')}>
            {t('profile.actionButtons.noRecruiterAccess')}
          </Alert>
        )}
        <UnipileNewMessage
          messageType={messageType}
          profileId={profile?.id}
          unipileChatId={
            _.findWhere(metadata || [], { key: 'unipileChatId' })?.value
          }
          unipileSenderId={
            _.findWhere(metadata || [], {
              key: 'unipileSenderId',
            })?.value
          }
          onClose={onClose}
          profileName={profileName || ''}
          profilePhoneNumber={profilePhoneNumber || ''}
        />
      </div>
    );
  }

  return (
    <div className='actions-tab email-tab timeline-default-card-wrapper'>
      <DisplayedSequence
        clientId={clientId}
        sequence={displayedSequence}
        profileId={profile?.id}
        searchPoolId='reveal'
        profile={profile}
        emailApi={emailApi}
        user={user}
        templates={templates}
        t={t}
        onSend={onActivitySend}
        canShowDefaultTemplateReplies={canShowDefaultTemplateReplies}
      />
    </div>
  );
};

export default compose(
  withUserSettings,
  withClientTemplates,
  withEmailContextConsumer,
)(MessageTab);

interface CannotBeContactedProps {
  title: string;
  description: string;
}

const CannotBeContacted: React.FC<CannotBeContactedProps> = ({
  title,
  description,
}) => {
  return (
    <div className={styles.cannotBeContacted}>
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  );
};
