import React from 'react';
import { Loader, Segment } from 'semantic-ui-react';
import { compose } from 'underscore';

import TimeLineItems from './TimeLineItems';
import PendingActions from './PendingActions';
import SmartNoProfileRightPane from './SmartNoProfileRightPane';
import ProfileContactFlow from './ProfileContactFlow';

import withOffer from '../../../hocs/offers/withOffer';
import withProfileContactFlow from '../../../hocs/profiles/withProfileContactFlow';
import withNextProfileId from '../../../hocs/profiles/withNextProfileId';
import withProfileDrafts from '../../../hocs/profiles/withProfileDrafts';

class Contact extends React.PureComponent {
  render() {
    const {
      shouldDisplaySmartNoProfilePane,
      offerId,
      stepId,
      searchText,
      profileId,
      getNextProfileId,
      isSearchResultProfile,
      onChangeStep,
      onChangeProfile,
      profile,
      profileContactFlow,
      clientId,
      handleMarkProfileAsInMove,
      handleUnmarkProfileAsInMove,
      getProfilesInMove,
      offer,
      loadingProfileContactFlow,
      loadingProfileDrafts,
    } = this.props;

    if (shouldDisplaySmartNoProfilePane && offerId) {
      return (
        <SmartNoProfileRightPane
          key={offerId}
          clientId={clientId}
          offerId={offerId}
        />
      );
    }

    if (loadingProfileContactFlow || loadingProfileDrafts) {
      return (
        <div className='profile-contact-container'>
          <Segment className='profile-contact-flow-placeholder'>
            <Loader active inline='centered' size='large' />
          </Segment>
        </div>
      );
    }

    return (
      <div className='profile-contact-container'>
        <div key={profileId}>
          {profile && profileContactFlow && (
            <Segment className='profile-contact-flow'>
              <ProfileContactFlow
                contactFlow={profileContactFlow}
                profile={profile}
                profileId={profile.id}
                clientId={clientId}
                offer={offer}
                offerId={offerId}
                stepId={stepId}
                searchText={searchText}
                getNextProfileId={getNextProfileId}
                isSearchResultProfile={isSearchResultProfile}
                onChangeStep={onChangeStep}
                onChangeProfile={onChangeProfile}
                handleMarkProfileAsInMove={handleMarkProfileAsInMove}
                handleUnmarkProfileAsInMove={handleUnmarkProfileAsInMove}
                getProfilesInMove={getProfilesInMove}
              />
            </Segment>
          )}
          <PendingActions
            profile={profile}
            contactFlow={profileContactFlow}
            clientId={clientId}
          />
          <TimeLineItems clientId={clientId} profileId={profileId} />
        </div>
      </div>
    );
  }
}

export default compose(
  withOffer,
  withNextProfileId,
  withProfileDrafts,
  withProfileContactFlow,
)(Contact);
