/* eslint-disable class-methods-use-this */
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import { CustomFieldVariable } from '@/common/mergeTags/utils';

export default class ExpressionCustomFieldEvaluator
  implements SweetEvaluatorTypes.EvaluatorInterface {
  evaluate({
    context,
    expression,
  }: {
    context: SweetEvaluatorTypes.Context;
    expression: SweetEvaluatorTypes.Variable;
  }): { value: string | undefined } {
    const expressionType = (expression as unknown) as SweetEvaluatorTypes.BaseVariable &
      CustomFieldVariable;
    if (!context.customFields) {
      return { value: undefined };
    }
    const contextCustomFields = context.customFields as Record<
      string,
      { type: string; value: string }
    >;
    return {
      value:
        contextCustomFields[expressionType.clientProfileCustomFieldId]?.value ||
        undefined,
    };
  }
}
