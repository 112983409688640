import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Placeholder, Popup, Image } from 'semantic-ui-react';
import { getSecureLink, getRandomDefaultAvatarLink } from '@/common';
import useClientProfileCustomFields, {
  CustomFieldDefinition,
} from '@/graphql/hooks/clients/useClientProfileCustomFields';
import _ from 'underscore';
import useResizableItemList from '@/hooks/common/useResizableItemList';
import { combineDataWithCustomFields } from '@/common/customFields';
import classnames from 'classnames';
import TranslatableText from '@/components/TranslatableText';
import useClientId from '@/hooks/router/useClientId';
import GenericPopup from '@/components/Common/GenericPopup';
import { getAnteriorityFromCustomField } from '@/routes/RevealView/ProfileRow';
import { useTranslation } from 'react-i18next';
import useOverviewPoolMiniProfileById from '@/graphql/hooks/profiles/useOverviewMiniProfilesById';
import styles from './KanbanProfileCard.module.less';

interface KanbanProfileCardProps {
  profileId: string;
  itemLoading: boolean;
  missionTitle?: string;
  customFields?: CustomFieldDefinition[];
  pageName?: string;
}

const KanbanProfileCard: React.FC<KanbanProfileCardProps> = ({
  profileId,
  itemLoading,
  missionTitle = '',
  customFields,
  pageName = 'profiles',
}) => {
  const clientId = useClientId();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const {
    containerRef,
    listRef,
    displayedItemsNumber,
    finalWidth,
  } = useResizableItemList<HTMLDivElement>(0);

  const { profile, loading } = useOverviewPoolMiniProfileById({
    searchPoolId: 'reveal',
    profileId,
  });

  const { profileCustomFields } = useClientProfileCustomFields(clientId);

  const topFields = (customFields || profileCustomFields || []).filter(
    (cf: any) => cf.shouldDisplayInCard,
  );

  const { resumeData } = profile || {};
  const { firstname, lastname, photoLink } = resumeData || {};
  const avatarImageUrl =
    photoLink || getRandomDefaultAvatarLink(firstname || `${lastname}` || '');

  const fields = combineDataWithCustomFields(topFields, resumeData);
  const fieldsWithValue = _.filter(fields, (field: any) => field.readableValue);

  const profileStatusCustomField = _.findWhere(
    profile?.resumeData?.customFields || [],
    {
      clientCustomFieldId: 'best-kaoutar',
    },
  );

  const anteriorityCustomField = _.findWhere(
    profile?.resumeData?.customFields || [],
    {
      clientCustomFieldId: 'anteriority',
    },
  );

  const anteriority = getAnteriorityFromCustomField(
    anteriorityCustomField,
    profileStatusCustomField,
  );

  return (
    <div
      onClick={() => {
        const params = new URLSearchParams(location.search);
        params.set('item', profileId);
        const searchUrl = params.toString();
        history.replace({
          pathname: pageName,
          search: searchUrl,
        });
      }}
    >
      <div className={styles.cardHeader}>
        {itemLoading || loading ? (
          <Placeholder className={styles.placeholder}>
            <Placeholder.Header>
              <Placeholder.Line length='long' />
            </Placeholder.Header>
          </Placeholder>
        ) : (
          <>
            <div className={styles.avatarContainer}>
              <Image
                className={styles.avatar}
                src={getSecureLink(avatarImageUrl)}
                circular
                onError={(e: Event) => {
                  (e.target as HTMLImageElement).src = getRandomDefaultAvatarLink(
                    `${firstname}${lastname}`,
                  );
                }}
              />
            </div>
            <div className={styles.profileInfoContainer}>
              {resumeData?.firstname}&nbsp;{resumeData?.lastname}
              <Popup
                content={missionTitle}
                basic
                inverted
                wide='very'
                trigger={
                  <div className={styles.missionTitle}>{missionTitle}</div>
                }
              />
              {anteriority && (
                <span className='pill-message blue mini equal-padding no-margin'>
                  {t('reveal.tasks.anteriority', { anteriority })}
                </span>
              )}
            </div>
          </>
        )}
      </div>
      {(resumeData?.headline?.content?.text || !_.isEmpty(fieldsWithValue)) && (
        <div className={styles.cardBody}>
          {itemLoading || loading ? (
            <Placeholder className={styles.placeholder}>
              <Placeholder.Header>
                <Placeholder.Line length='full' />
              </Placeholder.Header>
            </Placeholder>
          ) : (
            <>
              <div className={styles.cardHeadline}>
                {resumeData?.headline?.content?.text || ''}
              </div>
              {!_.isEmpty(profileId) && (
                <div
                  ref={containerRef}
                  className={styles.cardCustomFieldsContainer}
                >
                  <div
                    ref={listRef}
                    className={styles.cardCustomFieldsList}
                    style={{ width: finalWidth, minWidth: '32px' }}
                  >
                    {_.map(fieldsWithValue, (field, index) => (
                      <div
                        key={field.id}
                        className={classnames(
                          styles.cardCustomField,
                          index >= displayedItemsNumber
                            ? styles.customFieldHidden
                            : '',
                        )}
                      >
                        <div className={styles.customFieldTitle}>
                          <TranslatableText text={field.title} />
                        </div>
                        <div className={styles.customFieldValue}>
                          {field.readableValue}
                        </div>
                      </div>
                    ))}
                  </div>
                  {fieldsWithValue.length - displayedItemsNumber > 0 && (
                    <GenericPopup
                      basic
                      on='hover'
                      position='bottom right'
                      style={{
                        padding: '8px',
                      }}
                      trigger={
                        <div className={styles.remainingCustomFields}>
                          +{fieldsWithValue.length - displayedItemsNumber}
                        </div>
                      }
                    >
                      <div className={styles.customFieldsPopup}>
                        {_.map(
                          fieldsWithValue.slice(displayedItemsNumber),
                          (field) => (
                            <div
                              key={field.id}
                              className={classnames(styles.cardCustomField)}
                            >
                              <div className={styles.customFieldTitle}>
                                <TranslatableText text={field.title} />
                              </div>
                              <div className={styles.customFieldValue}>
                                {field.readableValue}
                              </div>
                            </div>
                          ),
                        )}
                      </div>
                    </GenericPopup>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default KanbanProfileCard;
