import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Profile from '../../graphql/fragments/Profile';
import { getOfferProfiles } from '../offers/withOfferProfiles';

export const addProfileToJob = gql`
  mutation addProfileToJob($jobOfferId: ID!, $profileInput: ProfileInput!) {
    addProfileToJob(jobOfferId: $jobOfferId, profileInput: $profileInput) {
      ...Profile
    }
  }
  ${Profile}
`;

export default graphql(addProfileToJob, {
  props: ({ mutate }) => ({
    addProfileToJob: ({ jobOfferId, profileInput }) => {
      const variables = { jobOfferId, profileInput };
      return mutate({
        variables,
        refetchQueries: [
          {
            query: getOfferProfiles,
            variables: {
              offerId: jobOfferId,
              step: '', // refresh left side bar list
              search: '',
            },
          },
          {
            query: getOfferProfiles,
            variables: {
              offerId: jobOfferId,
              step: 'pending', // refresh step
              search: '',
            },
          },
        ],
      });
    },
  }),
});
