import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Offer from '../../graphql/fragments/Offer';

export const query = gql`
  query getOffer($id: ID!) {
    offer(id: $id) {
      ...Offer
    }
  }
  ${Offer}
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.offerId,
    },
  }),
  props: ({ data: { loading, error, offer } }) => ({
    loading,
    offerLoading: loading,
    error,
    offer,
  }),
  skip: (ownProps) => !ownProps.offerId,
});
