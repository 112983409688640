import _ from 'underscore';
import { TFunction } from 'i18next';
import {
  archiveReasonCategories,
  ArchiveReasonCategory,
  PipelineDailyEvent,
} from '@/types/statistics/pipelineActions';
import { Stats } from '@/types/statistics/stats';
import { StackItem } from '@/types/statistics/stackItem';
import { DataVizColors, SecondaryBlue } from '@/less/colors';
import { DisplayMode, StatsFilters } from './types';

export const getCategoryLabels = (
  t: TFunction,
): Record<ArchiveReasonCategory, string> =>
  _.reduce(
    archiveReasonCategories,
    (accumulator, category) => ({
      ...accumulator,
      [category]: t(`settings.archiveReasonsSettings.categories.${category}`),
    }),
    {} as Record<ArchiveReasonCategory, string>,
  );

export const convertPipelineDailyEvents = (
  mode: DisplayMode,
  reasonLabels: Record<string, string>,
  dailyEvents: PipelineDailyEvent[],
  applyFilter?: (newFilter: StatsFilters) => void,
): Stats[] => {
  const statsByArchiveReason: Record<string, Stats> = {};

  _.each(dailyEvents, ({ userStats }) => {
    _.each(userStats, ({ stats }) => {
      _.each(stats, ({ type, archivedDetails, count }) => {
        if (type !== 'nbMoveToArchived') {
          return;
        }
        const { customArchiveReasonId, category } = archivedDetails || {};
        const categoryKey =
          mode === 'extended'
            ? customArchiveReasonId || category || 'no-reason'
            : category || 'no-reason';
        const reasonKey =
          mode === 'extended'
            ? categoryKey
            : customArchiveReasonId || 'non-custom-reason';
        statsByArchiveReason[categoryKey] = statsByArchiveReason[
          categoryKey
        ] || {
          values: {},
          name: reasonLabels[categoryKey],
          clickListeners: applyFilter ? {} : undefined,
        };
        const { values, clickListeners } = statsByArchiveReason[categoryKey];
        values[reasonKey] = (values[reasonKey] || 0) + count;
        if (clickListeners !== undefined && applyFilter) {
          clickListeners[reasonKey] = () =>
            applyFilter({
              customArchiveReasonId,
              category: customArchiveReasonId ? undefined : category,
            });
        }
      });
    });
  });

  return _.values(statsByArchiveReason);
};

export const buildStackFromDailyEvents = (
  mode: DisplayMode,
  reasonLabels: Record<string, string>,
  dailyEvents: PipelineDailyEvent[],
): StackItem[] => {
  const stackMap: Record<string, StackItem> = {};
  let colorRoulette = 3;

  _.each(dailyEvents, ({ userStats }) => {
    _.each(userStats, ({ stats }) => {
      _.each(stats, ({ type, archivedDetails }) => {
        if (type !== 'nbMoveToArchived') {
          return;
        }
        const { customArchiveReasonId, category } = archivedDetails || {};
        const reasonKey =
          mode === 'extended'
            ? customArchiveReasonId || category || 'no-reason'
            : customArchiveReasonId || 'non-custom-reason';
        if (!stackMap[reasonKey]) {
          stackMap[reasonKey] = {
            datakey: reasonKey,
            color:
              mode === 'collapsed'
                ? DataVizColors[colorRoulette % DataVizColors.length]
                : SecondaryBlue,
            hideFromLegend: true,
            displayedText: reasonLabels[reasonKey],
          };
          colorRoulette += 1;
        }
      });
    });
  });

  return _.values(stackMap);
};
