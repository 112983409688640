/* eslint-disable max-len */

import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './svg.module.less';

interface DeleteProps {
  className?: string;
}

const Delete: FC<DeleteProps> = ({ className }) => (
  <svg
    className={classNames(styles.svgIcon, className)}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 2C5 0.895431 5.89543 0 7 0H9C10.1046 0 11 0.895431 11 2C11 2.55228 11.4477 3 12 3H14C14.5523 3 15 3.44772 15 4C15 4.55228 14.5523 5 14 5H2C1.44772 5 1 4.55228 1 4C1 3.44772 1.44772 3 2 3H4C4.55228 3 5 2.55228 5 2ZM4.04376 6C3.03078 6 2.30905 6.98336 2.61277 7.94974L4.70275 14.5997C4.96466 15.433 5.73717 16 6.61073 16H9.38926C10.2628 16 11.0353 15.433 11.2972 14.5997L13.3872 7.94974C13.6909 6.98336 12.9692 6 11.9562 6H4.04376Z'
      fill='currentColor'
    />
  </svg>
);

export default Delete;
