import React from 'react';
import _ from 'underscore';
import { Loader } from 'semantic-ui-react';
import { CartesianGrid, Legend, Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { dateTickFormatter, ticksByFrequency } from '../../utils';
import * as colors from '../../../../../less/colors';


import './SkipRatioGraph.css';

export const SkipRatioGraphStub = () => (
  <div className='skip-ratio-graph'>
    <div className='skip-ratio-graph-empty'>
      <Loader active inline='centered' size='large' />
    </div>
  </div>
);

const SkipRatioGraph = ({ skipRatioData, isLoading, frequency, t }) => {
  if (isLoading) {
    return <SkipRatioGraphStub />;
  }
  if (_.isEmpty(skipRatioData)) {
    return (
      <div className='skip-ratio-graph'>
        <div className='skip-ratio-graph-empty'>
          <div className='skip-ratio-graph-empty-text'>{t('analytics.noDataPlaceholder')}</div>
        </div>
      </div>
    );
  }

  const domain = [(dataMin) => dataMin, (dataMax) => dataMax];
  const ticks = ticksByFrequency({ startDate: (skipRatioData[0] || {}).timestamp, frequency });

  const dataIndexedByTimestamp = _.indexBy(skipRatioData, 'timestamp');

  const completedSkipRatioData = _.map(ticks, (tick, index) => (
    dataIndexedByTimestamp[tick] ? ({
      ...dataIndexedByTimestamp[tick],
      index,
    }) : {
      frequency,
      nbSent: 0,
      nbSkipped: 0,
      timestamp: tick,
      index,
    }
  ));

  const MAX_BAR_SIZE = 100;
  const barSize = 5 + 1500 / (completedSkipRatioData.length || MAX_BAR_SIZE); // heuristic
  const AXIS_PADDING = barSize / 2 + 5;
  const Y_AXIS_WIDTH = 45;

  // NOTE: order of Axes / Bars is important for "z-index" of components
  return (
    <div className='skip-ratio-graph'>
      <ResponsiveContainer width='100%' height={300}>
        <BarChart data={completedSkipRatioData} barCategoryGap={10} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey='timestamp'
            type='number'
            domain={domain}
            ticks={ticks}
            tickFormatter={dateTickFormatter(frequency, t)}
        // leave some room for first an last bars
            padding={{ left: AXIS_PADDING, right: AXIS_PADDING }}
          />
          <Tooltip labelFormatter={dateTickFormatter(frequency, t)} />
          <Legend />
          <Bar
            barSize={MAX_BAR_SIZE}
            fill={colors.PrimaryCobalt}
            opacity={0.9}
            animationDuration={300}
            stackId='stack'
            dataKey='nbSent'
            name={t('analytics.labels.nbContacted')}
          />
          <Bar
            barSize={MAX_BAR_SIZE}
            fill={colors.SecondaryRed}
            opacity={0.9}
            animationDuration={300}
            stackId='stack'
            dataKey='nbSkipped'
            name={t('analytics.labels.nbSkipped')}
          />
          <YAxis tickLine={false} axisLine={false} width={Y_AXIS_WIDTH} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SkipRatioGraph;
