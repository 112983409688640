import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import classNames from 'classnames';

import { remotePolicies, RemotePolicy } from '@/types/remotePolicy';
import Cross from '../Common/Icons/Cross';
import RemotePolicyPill from './RemotePolicyPill';
import Star from '../Common/Icons/Star';

import styles from './RemoteSelector.module.less';

interface RemoteSelectorProps {
  locationName: string;
  remotePolicy: RemotePolicy;
  availableRemotePolicies?: RemotePolicy[];
  starred: boolean;
  onRemotePolicy: (policy: RemotePolicy) => void;
  onStar?: () => void;
  onRemove: () => void;
  nudge?: {
    prompt: string;
    onClick?: () => void;
  };
}

const RemoteSelector: FC<RemoteSelectorProps> = ({
  locationName,
  remotePolicy,
  availableRemotePolicies,
  starred,
  onRemotePolicy,
  onStar,
  onRemove,
  nudge,
}) => {
  const { t } = useTranslation();

  const isAvailablePolicy = useCallback(
    (policy: RemotePolicy) =>
      availableRemotePolicies === undefined ||
      _.contains(availableRemotePolicies, policy),
    [availableRemotePolicies],
  );

  return (
    <>
      <div className={styles.remoteSelector}>
        <button
          type='button'
          className={classNames(styles.star, starred && styles.active)}
          onClick={onStar}
          disabled={onStar === undefined}
        >
          <Star />
        </button>
        <div className={styles.location}>{locationName}</div>
        <div className={styles.remote}>
          <span className={styles.label}>{t('criteria.remote.label')}</span>
          <div className={styles.pills}>
            {_.map(remotePolicies, (policy) => (
              <RemotePolicyPill
                key={policy}
                remotePolicy={policy}
                selected={policy === remotePolicy}
                onClick={
                  isAvailablePolicy(policy)
                    ? () => onRemotePolicy(policy)
                    : undefined
                }
              />
            ))}
          </div>
        </div>
        <button type='button' className={styles.remove} onClick={onRemove}>
          <Cross />
        </button>
      </div>
      {nudge && (
        <div className={styles.nudge}>
          <button
            type='button'
            className={styles.nudgeButton}
            onClick={nudge.onClick}
            disabled={nudge.onClick === undefined}
          >
            {nudge.prompt}
          </button>
        </div>
      )}
    </>
  );
};

export default RemoteSelector;
