import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { EvaluationFormTemplate } from '../../graphql/fragments/Evaluations';

export const moveEvaluationFormTemplateUpward = gql`
  mutation moveEvaluationFormTemplateUpward($evaluationFormTemplateId: ID!) {
    moveEvaluationFormTemplateUpward(
      evaluationFormTemplateId: $evaluationFormTemplateId
    ) {
      id
      evaluationFormTemplates {
        ...EvaluationFormTemplate
      }
    }
  }
  ${EvaluationFormTemplate}
`;

export default graphql(moveEvaluationFormTemplateUpward, {
  props: ({ mutate }) => ({
    moveEvaluationFormTemplateUpward: ({ evaluationFormTemplateId }) =>
      mutate({
        variables: {
          evaluationFormTemplateId,
        },
      }),
  }),
});
