import React from 'react';
import { HiresweetLibProvider, SharedSpace } from "@hiresweet/hiresweet-lib";
import { useRouteMatch } from 'react-router-dom';

const SLASH_APP_IDS = ['slhp'];

const SharedSpaceView = () => {
  const { params } = useRouteMatch();
  const { standaloneReferrer, appId } = params as { standaloneReferrer: string; appId: string; };
  const decodedReferrer = atob(standaloneReferrer);
  const parts = decodedReferrer.split(':');
  const [clientId] = parts;

  if (!clientId) {
    return null;
  }

  return (
    <HiresweetLibProvider 
      clientId={clientId} 
      theme={SLASH_APP_IDS.includes(appId) ? 'slash' : 'hiresweet'}
    >
      <SharedSpace 
        standaloneReferrerToken={standaloneReferrer}
        appId={appId}
        parameters={[]}
      />
    </HiresweetLibProvider>
  );
}

export default SharedSpaceView;