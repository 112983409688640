import React, { FC, Fragment, useMemo } from 'react';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { StackItem } from '@/types/statistics/stackItem';
import { Stats } from '@/types/statistics/stats';
import {
  getMaxTotalCount,
  getTotalCount,
  filterStack,
  getTicks,
} from '@/common/helpers/stats';
import Legend from '../Legend';
import StackedBar from '../StackedBar';
import GraphEmptyState from '../GraphEmptyState';

import styles from './HorizontalStackedBarChart.module.less';

interface HorizontalStackedBarChartProps {
  stack: StackItem[];
  allStats: Stats[];
  isPercentage?: boolean;
  exhaustive?: boolean;
}

const HorizontalStackedBarChart: FC<HorizontalStackedBarChartProps> = ({
  stack,
  allStats,
  isPercentage,
  exhaustive,
}) => {
  const maxCount = useMemo(
    () => (isPercentage ? 100 : getMaxTotalCount(allStats)),
    [allStats, isPercentage],
  );
  const filteredStack = useMemo(
    () => (exhaustive ? stack : filterStack(stack, allStats)),
    [stack, allStats, exhaustive],
  );
  const ticks = useMemo(
    () => (isPercentage ? [0, 50, 100] : getTicks(maxCount)),
    [maxCount, isPercentage],
  );

  const sortedStats = useMemo(
    () =>
      _.chain(allStats)
        .sortBy(getTotalCount)
        .reverse()
        .value(),
    [allStats],
  );

  if (_.isEmpty(sortedStats)) {
    return <GraphEmptyState />;
  }

  return (
    <>
      <Legend stack={filteredStack} />
      <div className={styles.graph}>
        {_.map(sortedStats, (stats, index) => (
          <Fragment key={index}>
            {stats.link ? (
              <Link
                className={classNames(styles.label, styles.labelLink)}
                target='_blank'
                to={stats.link}
                title={stats.name}
              >
                {stats.name}
              </Link>
            ) : (
              <div className={styles.label} title={stats.name}>
                {stats.name}
              </div>
            )}
            <StackedBar
              stack={exhaustive ? stack : filterStack(stack, [stats])}
              stats={stats}
              topBoundary={_.last(ticks) || maxCount}
              isPercentage={isPercentage}
              exhaustive={exhaustive}
            />
          </Fragment>
        ))}
        <div className={styles.scale}>
          {_.map(ticks, (tick, index) => (
            <span className={styles.tick} key={`${index}_${tick}`}>
              {tick || 0}
            </span>
          ))}
        </div>
      </div>
    </>
  );
};

export default HorizontalStackedBarChart;
