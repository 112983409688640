import gql from 'graphql-tag';

interface GreenhouseUser {
  sourceUserId?: string;
  firstname?: string;
  lastname?: string;
}

interface GreenhouseInterview {
  title?: string;
  step?: { name?: string };
}

export interface GreenhouseScorecardAttribute {
  name?: string;
  type?: string;
  note?: string;
  rating?: string;
}

export interface GreenhouseScorecardQuestion {
  question?: string;
  answer?: string;
}

export interface Scorecard {
  sourceScorecardId: string;
  creationDate: string;
  lastUpdateDate: string;
  interviewDate?: string;
  submissionDate?: string;
  submittedBy?: GreenhouseUser;
  interviewer?: GreenhouseUser;
  interview?: GreenhouseInterview;
  overallRecommendation?: string;
  attributes?: Array<GreenhouseScorecardAttribute>;
  questions?: Array<GreenhouseScorecardQuestion>;
}

export default gql`
  fragment Scorecard on GreenhouseScorecard {
    sourceScorecardId
    creationDate
    lastUpdateDate
    interviewDate
    submissionDate
    interview {
      title
      step {
        name
      }
    }
    submittedBy {
      sourceUserId
      firstname
      lastname
    }
    interviewer {
      sourceUserId
      firstname
      lastname
    }
    overallRecommendation
    attributes {
      name
      type
      note
      rating
    }
    questions {
      question
      answer
    }
  }
`;
