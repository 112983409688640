import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const query = gql`
  query getSearchPoolTotalCount(
    $searchPoolId: ID!
    $searchesCriteria: [SearchCriteriaInput]
    $categories: [SearchCategoryInput]
    $excludeHidden: Boolean
    $excludeProfilesInJob: Boolean
  ) {
    searchPool(id: $searchPoolId) {
      id
      searchResults(
        searchesCriteria: $searchesCriteria
        categories: $categories
        excludeHidden: $excludeHidden
        excludeProfilesInJob: $excludeProfilesInJob
      ) {
        categories {
          id
          categoryId
          count
        }
      }
    }
  }
`;

export default graphql(query, {
  options: ({ searchPoolId }) => {
    return {
      variables: {
        searchPoolId,
        searchesCriteria: [{}],
        categories: [
          {
            categoryId: 'all',
            withCount: true,
            withResults: false,
          },
        ],
        excludeHidden: true,
        excludeProfilesInJob: true,
      },
      fetchPolicy: 'network-only',
    };
  },
  props: ({ data: { loading, error, searchPool } }) => {
    const allCategory = (searchPool?.searchResults?.categories || [])[0];
    return {
      searchPoolTotalCountLoading: loading,
      searchPoolTotalCountError: error,
      searchPoolTotalCount: allCategory?.count,
    };
  },
});
