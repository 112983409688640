import _, { compose } from 'underscore';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';

import { removeSkippedFromPipeSteps } from '@/routes/DashboardView/OffersDashboard';
import withClient from '../../../hocs/clients/withClient';
import withClientOffers from '../../../hocs/offers/withClientOffers';
import withUserSettings from '../../../hocs/users/withUserSettings';

import NewOfferButton from '../../../components/NewOfferButton';
import DepartmentSelector from '../../../components/DepartmentSelector';
import JobsWatchCollectBanner from './components/JobsWatchCollectBanner';
import OfferCard from './OfferCard';
import { isOfferAttachedToUser, sortOffersForUserView } from './helpers';

import './Offers.css';

class ClientOffers extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedDepartmentId: 'all',
    };
  }

  handleSelectDepartmentId = ({ departmentId }) => {
    this.setState({
      selectedDepartmentId: departmentId,
      selectedSectionId: null,
    });
  };

  handleSelectSectionId = ({ sectionId }) => {
    this.setState({
      selectedDepartmentId: null,
      selectedSectionId: sectionId,
    });
  };

  handleSelectItem = ({ type, id }) => {
    if (type === 'department') {
      this.handleSelectDepartmentId({ departmentId: id });
    }
    if (type === 'section') {
      this.handleSelectSectionId({ sectionId: id });
    }
  };

  render() {
    const {
      loading,
      clientTemplatesAndAuthorsLoading,
      loadingUserSettings,
      clientOffersLoading,
      error,
      offers: allOffers,
      history,
      clientId,
      client,
      user,
      t,
    } = this.props;

    const {
      selectedDepartmentId,
      displayArchivedOffers,
      selectedSectionId,
    } = this.state;

    if (
      loading ||
      clientTemplatesAndAuthorsLoading ||
      loadingUserSettings ||
      clientOffersLoading ||
      !client
    ) {
      return (
        <div className='menu-offers'>
          <div className='header'>{/* <h1>{t('offers.offers')}</h1> */}</div>
          <div className='offers-list-placeholder'>
            <Loader active inline='centered' size='large' />
          </div>
        </div>
      );
    }

    const offers = _.filter(allOffers, (jobOffer) => {
      if (selectedDepartmentId) {
        if (selectedDepartmentId === 'all') {
          return true;
        }
        const departmentId = ((jobOffer.foldering || {}).department || {}).id;

        if (selectedDepartmentId === 'other') {
          return (
            !departmentId || departmentId === 'other' || departmentId === 'none'
          );
        }
        return departmentId === selectedDepartmentId;
      }
      if (selectedSectionId) {
        const sectionId = ((jobOffer.foldering || {}).section || {}).id;
        return sectionId === selectedSectionId;
      }
      return true;
    });

    if (error) return <p />;
    if (!offers) return <div />;

    const fullPipeStepStats = _.max(
      offers,
      (job) => _.keys((job || {}).pipeStepStats).length,
    )?.pipeStepStats;
    const clientPipeStepIds = _.pluck(fullPipeStepStats, 'stepId');

    const sortedOffers = sortOffersForUserView(user, offers);

    const userSortedOffers = _.filter(sortedOffers, (offer) =>
      isOfferAttachedToUser({ offer, user }),
    );
    const unarchivedUserSortedOffers = _.where(userSortedOffers, {
      isArchived: false,
      isSuggestedForCreation: false,
    });

    const otherSortedOffers = _.filter(
      sortedOffers,
      (offer) => !isOfferAttachedToUser({ offer, user }),
    );
    const unarchivedOtherSortedOffers = _.where(otherSortedOffers, {
      isArchived: false,
      isSuggestedForCreation: false,
    });

    const hasBothCategory =
      !_.isEmpty(unarchivedUserSortedOffers) &&
      !_.isEmpty(unarchivedOtherSortedOffers);

    const { departments } = client;

    const dropdownValue = selectedDepartmentId || selectedSectionId;

    if (_.isEmpty(sortedOffers)) {
      return (
        <div className='menu-offers'>
          <div className='menu-offers-content-empty'>
            <div className='new-offer-and-department-container'>
              <JobsWatchCollectBanner
                clientId={clientId}
                searchPoolId='watch'
              />

              <div className='hs-spacer banner-margin' />

              <DepartmentSelector
                value={dropdownValue}
                onChange={this.handleSelectItem}
                departments={departments}
                withOtherOption
                withAllOption
              />
              <div className='new-offer-button-container'>
                <NewOfferButton clientId={clientId} from='offers' />
              </div>
            </div>

            <div className='no-items-container no-jobs'>
              <img
                src='/images/placeholders/jobs.svg'
                width='500'
                alt='jobs-placeholder'
              />
              <div className='no-items'>{t('dashboard.offers.noOffers')}</div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='menu-offers'>
        <div className='menu-offers-content'>
          <div className='new-offer-and-department-container'>
            <JobsWatchCollectBanner clientId={clientId} searchPoolId='watch' />

            <div className='hs-spacer banner-margin' />

            <DepartmentSelector
              value={dropdownValue}
              onChange={this.handleSelectItem}
              departments={departments}
              withOtherOption
              withAllOption
            />
            <div className='new-offer-button-container'>
              <NewOfferButton clientId={clientId} from='offers' />
            </div>
          </div>

          {!_.isEmpty(sortedOffers) && (
            <div className='offers-list-container'>
              <div className='pipe-steps-row'>
                <h1>
                  {hasBothCategory || !_.isEmpty(unarchivedUserSortedOffers)
                    ? t('offers.myJobs')
                    : t('offers.offers')}
                </h1>
                {!_.isEmpty(clientPipeStepIds) && (
                  <div className='pipe-steps-container'>
                    {_.map(
                      removeSkippedFromPipeSteps(clientPipeStepIds),
                      (stepId) => (
                        <div key={stepId} className='job-pipe-step-name'>
                          {t(`profile.steps.pipe.${stepId}`, stepId)}
                        </div>
                      ),
                    )}
                  </div>
                )}
              </div>

              {_.map(unarchivedUserSortedOffers, (offer, index) => (
                <div key={`user_${index}`} className='offer-card'>
                  <OfferCard
                    history={history}
                    clientId={clientId}
                    offerId={offer?.id}
                  />
                </div>
              ))}

              {hasBothCategory && (
                <div className='pipe-steps-row'>
                  <h1>{t('offers.otherJobs')}</h1>
                  {!_.isEmpty(clientPipeStepIds) && (
                    <div className='pipe-steps-container'>
                      {_.map(
                        removeSkippedFromPipeSteps(clientPipeStepIds),
                        (stepId) => (
                          <div key={stepId} className='job-pipe-step-name'>
                            {t(`profile.steps.pipe.${stepId}`, stepId)}
                          </div>
                        ),
                      )}
                    </div>
                  )}
                </div>
              )}
              {_.map(unarchivedOtherSortedOffers, (offer, index) => (
                <div key={`other_${index}`} className='offer-card'>
                  <OfferCard
                    history={history}
                    clientId={clientId}
                    offerId={offer?.id}
                  />
                </div>
              ))}

              {_.isEmpty(
                _.where(sortedOffers, {
                  isArchived: false,
                  isSuggestedForCreation: false,
                  isUnclassified: false,
                }),
              ) &&
                !displayArchivedOffers && (
                  <div className='no-items-container no-jobs'>
                    <img
                      src='/images/placeholders/jobs.svg'
                      width='500'
                      alt='jobs-placeholder'
                    />
                    <div className='no-items'>
                      {t('dashboard.offers.noOffers')}
                    </div>
                  </div>
                )}

              {!_.isEmpty(_.where(sortedOffers, { isArchived: true })) &&
                (displayArchivedOffers ? (
                  <div className='archived-offers-link-box'>
                    <div
                      className='archived-offers-link'
                      onClick={() =>
                        this.setState({ displayArchivedOffers: false })
                      }
                    >
                      {t('offers.hideArchivedOffers')}
                    </div>
                  </div>
                ) : (
                  <div className='archived-offers-link-box'>
                    <div
                      className='archived-offers-link'
                      onClick={() =>
                        this.setState({ displayArchivedOffers: true })
                      }
                    >
                      {t('offers.showArchivedOffers')}
                    </div>
                  </div>
                ))}

              {!_.isEmpty(_.where(sortedOffers, { isArchived: true })) &&
                displayArchivedOffers && (
                  <div>
                    {_.map(
                      _.where(sortedOffers, { isArchived: true }),
                      (offer, index) => (
                        <div key={index} className='offer-card'>
                          <OfferCard
                            history={history}
                            clientId={clientId}
                            offerId={offer?.id}
                          />
                        </div>
                      ),
                    )}
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  withClient,
  withClientOffers,
  withUserSettings,
  withTranslation('translations'),
)(ClientOffers);
