import Tracker from '@openreplay/tracker';
import { useEffect } from 'react';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';

const useOpenReplay = (): void => {
  const { user } = useCurrentUser();
  const { email, isAdmin } = user || {};

  useEffect(() => {
    const shouldUseOpenReplay =
      email &&
      !email.endsWith('@hiresweet.com') &&
      !isAdmin &&
      !window.origin.includes('internal-beta') &&
      process.env.NODE_ENV === 'production';

    if (!shouldUseOpenReplay) {
      return undefined;
    }

    try {
      const tracker = new Tracker({
        // Should be in env
        projectKey: 'Eo3YaDYl5omdOlhgd9Qt',
        obscureInputEmails: true,
        obscureInputNumbers: true,
        obscureTextEmails: true,
        obscureTextNumbers: true,
      });

      tracker.start();
      tracker.setUserID(email);
      return () => {
        tracker.stop();
      };
    } catch (e) {
      console.error('OpenReplay could not be started', e);
    }

    return undefined;
  }, [email, isAdmin]);
};

export default useOpenReplay;
