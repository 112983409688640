import _ from 'underscore';

import { SnoozeReasonStatsWithDateDetails } from '@/types/statistics/snoozeReasonsOverview';
import { DailyEvent } from '@/types/statistics/dailyEvent';
import { StackItem } from '@/types/statistics/stackItem';
import { DataVizColors } from '@/less/colors';

export function getDailyEventsFromDetails(
  stats: SnoozeReasonStatsWithDateDetails[],
): DailyEvent[] {
  const dailyEventByDay = {} as Record<string, DailyEvent>;

  _.each(stats, ({ snoozeReasonId = '', details }) => {
    _.each(details, ({ date }) => {
      if (!dailyEventByDay[date]) {
        dailyEventByDay[date] = {
          day: date,
          values: {} as Record<string, number>,
        };
      }
      const { values } = dailyEventByDay[date];
      values[snoozeReasonId] = (values[snoozeReasonId] || 0) + 1;
    });
  });

  return _.values(dailyEventByDay);
}

export function buildStack(
  stats: SnoozeReasonStatsWithDateDetails[],
): StackItem[] {
  const stackItemMap = {} as Record<string, StackItem>;
  let itemCount = 0;

  _.each(stats, ({ snoozeReasonId, snoozeReasonTitle }) => {
    const key = snoozeReasonId || '';
    if (stackItemMap[key]) {
      return;
    }
    const displayedText =
      snoozeReasonTitle || (snoozeReasonId ? '???' : 'No snooze reason');
    stackItemMap[key] = {
      datakey: key,
      displayedText,
      color: DataVizColors[itemCount % DataVizColors.length],
    };
    itemCount += 1;
  });

  return _.values(stackItemMap);
}
