import { getRandomString } from '@/common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'semantic-ui-react';
import _ from 'underscore';
import GenericButton from '@/components/Common/GenericButton';
import GenericDropdown from '@/components/Common/GenericDropdown';
import DropdownControlsContext from '@/context/DropdownControlsContext';
import Dots from '@/components/Common/Icons/Dots';
import DropdownPanel from '@/components/Common/DropdownPanel';
import DropdownMenuItem from '@/components/Common/DropdownMenuItem';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import ArrowUp from '@/components/Reveal/Icons/ArrowUp';
import Delete from '@/components/Reveal/Icons/Delete';
import styles from './CreateMetaTaskForm.module.less';
import { MetaTaskFieldFormProps } from './MetaTaskInlineTextFieldForm';

const MetaTaskSelectFieldForm: React.FC<MetaTaskFieldFormProps> = ({
  field,
  onUpdateField,
  onRemoveField,
  onMoveDownward,
  onMoveUpward,
  index,
  isLast,
}) => {
  const { t } = useTranslation();

  const addOption = () => {
    onUpdateField({
      fieldId: field.id,
      newField: {
        ...field,
        options: [
          ...(field.options || []),
          {
            id: getRandomString(6),
            title: { default: `Option ${(field?.options || []).length + 1}` },
          },
        ],
      },
    });
  };

  const handleOptionEdition = ({
    optionId,
    newValue,
  }: {
    optionId: string;
    newValue: string;
  }) => {
    onUpdateField({
      fieldId: field.id,
      newField: {
        ...field,
        options: _.map(field.options || [], (option) => ({
          ...option,
          ...(optionId === option.id && {
            id: option?.id || getRandomString(6),
            title: { default: newValue },
          }),
        })),
      },
    });
  };

  return (
    <div className={styles.formFieldContainer}>
      <Form.Field className={styles.modalFormField}>
        <div className={styles.fieldTitle}>
          <div className={styles.fieldTitleInfo}>
            {t('reveal.modals.metaTaskModal.field')} {index}
            <span className='pill-message blue mini mini small-radius'>
              {t(
                `reveal.modals.metaTaskModal.${
                  field.isMultiselect ? 'multi-select' : 'select'
                }`,
              )}
            </span>
          </div>
          <div className={styles.fieldTitleActions}>
            <GenericDropdown
              position='right'
              trigger={
                <DropdownControlsContext.Consumer>
                  {({ toggleDropdown }) => (
                    <div style={{cursor: 'pointer'}} onClick={toggleDropdown}>
                      <Dots />
                    </div>
                  )}
                </DropdownControlsContext.Consumer>
              }
            >
              <DropdownPanel className='dropdown-panel'>
                {!isLast ? (
                  <DropdownMenuItem
                    onClick={() => onMoveDownward &&
                      onMoveDownward({
                        fieldId: field.id,
                      })
                    }
                  >
                    <span className='dropdown-menu-item-icon'>
                      <ArrowDown />
                    </span>
                    {t('settings.departments.moveDownward')}
                  </DropdownMenuItem>
                ) : (
                  ''
                )}
                {index > 1 ? (
                  <DropdownMenuItem
                    onClick={() => onMoveUpward &&
                      onMoveUpward({
                        fieldId: field.id,
                      })
                    }
                  >
                    <span className='dropdown-menu-item-icon'>
                      <ArrowUp />
                    </span>
                    {t('settings.departments.moveUpward')}
                  </DropdownMenuItem>
                ) : (
                  ''
                )}
                <DropdownMenuItem
                  onClick={() => onRemoveField &&
                    onRemoveField({
                      fieldId: field.id,
                    })
                  }
                >
                  <span className='dropdown-menu-item-icon'>
                    <Delete />
                  </span>
                  {t('settings.departments.delete')}
                </DropdownMenuItem>
              </DropdownPanel>
            </GenericDropdown>
          </div>
        </div>
        <div className={styles.selectFieldQuestionContainer}>
          <Input
            size='small'
            className={styles.modalFormInput}
            value={field?.title?.default || ''}
            placeholder={t('reveal.modals.metaTaskModal.formPlaceholder2')}
            onChange={(e) =>
              onUpdateField({
                fieldId: field.id,
                newField: {
                  ...field,
                  title: { default: e.target.value },
                },
              })
            }
          />

          <GenericButton
            className={styles.addOptionButton}
            primacy='secondary'
            onClick={addOption}
          >
            {t('reveal.modals.metaTaskModal.addOption')}
          </GenericButton>
        </div>
      </Form.Field>
      <Form.Field className={styles.modalFormField}>
        <div className={styles.optionsSelector}>
          {_.map(field.options || [], (option) => (
            <div className={styles.fieldOptionContainer} key={option.id}>
              <div className={styles.fieldOption}>
                <Input
                  size='small'
                  className={styles.modalFormInput}
                  value={option.title.default}
                  placeholder='Option'
                  onChange={(e, data) =>
                    handleOptionEdition({
                      optionId: option.id,
                      newValue: data.value,
                    })
                  }
                />
              </div>
              <div
                className={styles.removeOptionButton}
                onClick={() =>
                  onUpdateField({
                    fieldId: field.id,
                    newField: {
                      ...field,
                      options: _.filter(
                        field.options || [],
                        (opt) => opt.id !== option.id,
                      ),
                    },
                  })
                }
              >
                <i className='ri-close-line' />
              </div>
            </div>
          ))}
        </div>
      </Form.Field>
    </div>
  );
};

export default MetaTaskSelectFieldForm;
