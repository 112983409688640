import React, { useState } from 'react';
import _ from 'underscore';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ConnectForm } from '@/types/connectForm';

import {
  getComparisonFunction,
  TableHeader,
} from '@/containers/Parameters/Campaigns/CampaignsList';
import styles from './ConnectFormsTable.module.less';
import ConnectFormsTableRow from './ConnectFormsTableRow';

interface ConnectFormsTableProps {
  connectForms: ConnectForm[];
  withEdit: boolean;
  onEditConnectForm: ({ connectFormId }: { connectFormId: string }) => void;
  onDeleteConnectForm: ({ connectFormId }: { connectFormId: string }) => void;
}
const ConnectFormsTable: React.FC<ConnectFormsTableProps> = ({
  connectForms,
  withEdit,
  onEditConnectForm,
  onDeleteConnectForm,
}) => {
  const { t } = useTranslation();
  const [sort, setSort] = useState({ key: 'none', order: 1 });

  const { key: sortKey, order: sortOrder } = sort;

  if (_.isEmpty(connectForms)) {
    return null;
  }

  const handleToggleSort = ({ key }: { key: string }) => {
    if (sortKey === key) {
      setSort({ key, order: -sortOrder });
    } else {
      setSort({ key, order: 1 });
    }
  };

  const increasingSortedForms = _.sortBy(
    connectForms,
    getComparisonFunction({ sortKey }),
  );
  const sortedForms =
    sortOrder < 0 ? increasingSortedForms.reverse() : increasingSortedForms;

  return (
    <div className={styles.connectFormsList}>
      <Table basic className={styles.connectFormsSettingsTable}>
        <Table.Header className={styles.connectFormsTableHeaders}>
          <Table.Row>
            <Table.HeaderCell>
              <TableHeader
                rowKey='title'
                name={t(
                  'settings.connectSettings.connectForms.table.header.title',
                )}
                sortKey={sortKey}
                sortOrder={sortOrder}
                handleToggleSort={handleToggleSort}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <TableHeader
                rowKey='author'
                name={t(
                  'settings.connectSettings.connectForms.table.header.author',
                )}
                sortKey={sortKey}
                sortOrder={sortOrder}
                handleToggleSort={handleToggleSort}
              />
            </Table.HeaderCell>
            <Table.HeaderCell collapsing />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(sortedForms, (connectForm) => (
            <ConnectFormsTableRow
              key={connectForm.id}
              connectForm={connectForm}
              withEdit={withEdit}
              onEditConnectForm={onEditConnectForm}
              onDeleteConnectForm={onDeleteConnectForm}
            />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default ConnectFormsTable;
