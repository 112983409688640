import _ from 'underscore';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Callout, CalloutInfoLine } from '@/components/Common/Callout';
import Tag from '@/components/Common/Tag';
import {
  AdventureATSData,
  AdventureQualification,
} from '@/graphql/fragments/AdventureData';
import { TranslatableText } from '@/types/text';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import Message from '@/components/Common/Icons/Message';
import Phone from '@/components/Common/Icons/Phone';
import Car from '@/components/Common/Icons/Car';
import CalendarOutline from '@/components/Common/Icons/CalendarOutline';
import CheckmarkOutline from '@/components/Common/Icons/CheckmarkOutline';
import Location from '@/components/Common/Icons/Location';
import Paper from '@/components/Common/Icons/Paper';
import TagIcon from '@/components/Common/Icons/Tag';
import Tools from '@/components/Common/Icons/Tools';
import FirstAidKit from '@/components/Common/Icons/FirstAidKit';
import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { getShortLanguage } from '@/common/utils/i18n';
import useClientId from '@/hooks/router/useClientId';
import { getTranslatedText } from '@/common/helpers/translatableText';
import People from '@/components/Common/Icons/People';
import HardHat from '@/components/Common/Icons/HardHat';

import { ProfileMissionsListInline } from './ProfileMissionsList/ProfileMissionList';
import {
  AdventureContractsSeriesHistory,
  ContractHistory,
} from './ContractHistory';

import styles from './adventure.module.less';

// function TranslatableInfo({
//   info,
// }: PropsWithChildren<{ info?: TranslatableText }>): JSX.Element {
//   if (!info) {
//     return ('-' as unknown) as JSX.Element;
//   }

//   return (getTranslatedText(info) as unknown) as JSX.Element;
// }

const getChunksFromWorkslotsByDay = ({
  workslotTagIds,
}: {
  workslotTagIds: string[];
}) => {
  const workslotsWeek = [
    { day: 'monday', slots: [] },
    { day: 'tuesday', slots: [] },
    { day: 'wednesday', slots: [] },
    { day: 'thursday', slots: [] },
    { day: 'friday', slots: [] },
    { day: 'saturday', slots: [] },
    { day: 'sunday', slots: [] },
  ];

  const sortedTagIds = [...workslotTagIds].sort();

  for (let i = 0; i < (sortedTagIds || []).length; i++) {
    const parts = sortedTagIds[i].replace('workslot__', '').split('-');
    const day = parts[0];
    const slot = parts[1];
    const iDay = {
      monday: 0,
      tuesday: 1,
      wednesday: 2,
      thursday: 3,
      friday: 4,
      saturday: 5,
      sunday: 6,
    }[day];
    workslotsWeek[iDay].slots.push(slot);
  }

  const chunks = [];
  for (let i = 0; i < (workslotsWeek || []).length; i++) {
    const sortedSlots = _.sortBy(
      workslotsWeek[i].slots || [],
      (slot) => periodMap[slot],
    );
    const key = (sortedSlots || []).join();
    if (chunks.length > 0 && key === chunks[chunks.length - 1].key) {
      chunks[chunks.length - 1].days.push(workslotsWeek[i].day);
    } else {
      chunks.push({
        key,
        slots: sortedSlots,
        days: [workslotsWeek[i].day],
      });
    }
  }
  return chunks.filter(({ key }) => !!key);
};

function TranslatableInfoList({
  infoList,
}: PropsWithChildren<{ infoList: TranslatableText[] }>): JSX.Element {
  const { i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const data = _.map(infoList, (t) => getTranslatedText(lang, t));
  return data.join(', ') as unknown as JSX.Element;
}

function QualificationsList({
  qualifications,
}: {
  qualifications: AdventureATSData['qualifications'];
}) {
  const { i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  if (!qualifications?.length) {
    return '-' as unknown as JSX.Element;
  }

  let primaryQualification: AdventureQualification | undefined;
  const otherQualifications: AdventureATSData['qualifications'] = [];
  _.forEach(qualifications, (qualification) => {
    if (qualification.isPrimary && !primaryQualification) {
      primaryQualification = qualification;
      return;
    }

    otherQualifications.push(qualification);
  });

  return (
    <div className={styles.qualifications}>
      {primaryQualification && (
        <span className={styles.primaryQualification}>
          {getTranslatedText(lang, primaryQualification.name)}
        </span>
      )}
      {_.map(otherQualifications, ({ name, id }, index) => (
        <span key={id}>
          {primaryQualification || index > 0 ? ',' : ''}{' '}
          {getTranslatedText(lang, name)}
        </span>
      ))}
    </div>
  );
}

const periodMap = {
  morning: 0,
  afternoon: 1,
  day: 2,
  night: 3,
};

export function AdventureData({
  atsData,
  profile,
}: PropsWithChildren<{
  atsData: AdventureATSData;
  profile: SearchPoolProfile;
}>) {
  const clientId = useClientId();
  const { i18n, t } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const [nbShowClicks, setNbShowClicks] = useState(0);
  const { profileCustomFields } = useClientProfileCustomFields(clientId);

  const myAdequatStatusOptionTitles = useMemo(() => {
    const result = {} as Record<string, string>;
    const myAdequatClientCustomField = _.findWhere(profileCustomFields, {
      id: 'myadequat-status',
    });
    if (myAdequatClientCustomField?.type !== 'enum') {
      return result;
    }
    myAdequatClientCustomField.options.forEach((option) => {
      result[option.id] = getTranslatedText(lang, option.title);
    });
    return result;
  }, [profileCustomFields, lang]);

  const myAdequatStatusTitle = useMemo(() => {
    const myAdequatStatusCustomField = _.findWhere(
      profile.resumeData?.customFields ?? [],
      { clientCustomFieldId: 'myadequat-status' },
    );
    const myAdequatStatus =
      myAdequatStatusCustomField?.type === 'enum'
        ? myAdequatStatusCustomField.selected[0]
        : undefined;
    if (!myAdequatStatus) {
      return '-';
    }
    return myAdequatStatusOptionTitles[myAdequatStatus] ?? '-';
  }, [myAdequatStatusOptionTitles, profile]);

  const {
    addresses,
    availabilityInfoSlots,
    transportationTypes,
    sectors,
    qualifications,
    certifications,
    skills,
    tags,
    emails,
    phoneNumbers,
    contracts = [],
  } = atsData;

  const [availabilityDate] = availabilityInfoSlots || [];
  const [address] = addresses || [];
  const transports = _.map(transportationTypes, (transportationType) =>
    getTranslatedText(lang, transportationType.name),
  );

  const [principalEmail] = emails || [];
  const [principalPhoneNumber] = phoneNumbers || [];

  const availabilityFormattedDate = useMemo(() => {
    if (!availabilityDate?.startDate) {
      return '-';
    }

    const formatter = new Intl.DateTimeFormat(i18n.resolvedLanguage, {
      dateStyle: 'long',
    });

    return formatter.format(new Date(availabilityDate.startDate));
  }, [availabilityDate, i18n]);

  const agencyTypeCustomField = _.findWhere(profile.resumeData?.customFields ?? [], { 
    clientCustomFieldId: 'agency-type'
  });

  const agencyType = agencyTypeCustomField?.selected?.[0] ?? 'city-agency' as const;

  const medicalExamFormattedDate = useMemo(() => {
    const timestampString =
      profile.availability?.medicalExaminationExpirationDate;
    if (!timestampString) {
      return '-';
    }
    const formatter = new Intl.DateTimeFormat(i18n.resolvedLanguage, {
      dateStyle: 'long',
    });
    return formatter.format(new Date(+timestampString));
  }, [profile.availability?.medicalExaminationExpirationDate, i18n]);

  const constructionCardFormattedDate = useMemo(() => {
    const timestampString =
      profile.availability?.constructionCardExpirationDate;
    if (!timestampString) {
      return '-';
    }
    const formatter = new Intl.DateTimeFormat(i18n.resolvedLanguage, {
      dateStyle: 'long',
    });
    return formatter.format(new Date(+timestampString));
  }, [profile.availability?.constructionCardExpirationDate, i18n]);

  const workslotTags = _.filter(
    tags || [],
    (tag) => tag.sourceTagId.indexOf('workslot') >= 0,
  );

  const workslotTagsChunks = getChunksFromWorkslotsByDay({
    workslotTagIds: _.pluck(workslotTags, 'sourceTagId'),
  });

  return (
    <>
      <Callout
        primary
        borderless
        title={t('profile.resume.ATSData.mainInformation')}
      >
        <CalloutInfoLine
          icon={<Message />}
          title={t('newCandidateModal.email')}
        >
          {principalEmail?.value || '-'}
        </CalloutInfoLine>

        <CalloutInfoLine
          icon={<Phone />}
          title={t('profile.resume.ATSData.phone')}
        >
          {principalPhoneNumber?.value || '-'}
        </CalloutInfoLine>

        <CalloutInfoLine icon={<Location />} title={t('common.address')}>
          {address?.value || '-'}
        </CalloutInfoLine>

        <CalloutInfoLine
          icon={<CalendarOutline />}
          title={t('profile.resume.ATSData.availability')}
        >
          {availabilityFormattedDate}
        </CalloutInfoLine>

        <CalloutInfoLine
          icon={<FirstAidKit />}
          title={t('profile.resume.ATSData.medicalExamination')}
        >
          {medicalExamFormattedDate}
        </CalloutInfoLine>

        <CalloutInfoLine
          icon={<Car />}
          title={t('profile.resume.ATSData.transportation')}
        >
          {transports.join(', ')}
        </CalloutInfoLine>

        {!_.isEmpty(workslotTags) && (
          <CalloutInfoLine
            icon={<i className='ri-time-line' />}
            title={t('profile.resume.ATSData.workingHours')}
          >
            {_.map(workslotTagsChunks, (chunk) => (
              <div key={`${chunk.key}-${chunk.days.join('-')}`}>
                {t(
                  `reveal.searchView.filters.ats.adventure.workingHours.${chunk.days[0]}`,
                )}
                {/* eslint-disable-next-line */}
                {Boolean(chunk.days.length > 1)
                  ? `${' '}${t('reveal.dashboardView.activity.to')} ${t(
                      `reveal.searchView.filters.ats.adventure.workingHours.${
                        chunk.days[chunk.days.length - 1]
                      }`,
                    )}`
                  : ''}
                &nbsp;(
                {_.map(chunk.slots, (slot) =>
                  t(
                    `reveal.searchView.filters.ats.adventure.workingHours.${slot}`,
                  ),
                )?.join(', ')}
                )
              </div>
            ))}
          </CalloutInfoLine>
        )}

        <CalloutInfoLine
          icon={<HardHat />}
          title={t('profile.resume.ATSData.constructionCard')}
        >
          {constructionCardFormattedDate}
        </CalloutInfoLine>

        <CalloutInfoLine
          icon={<People />}
          title={t('profile.resume.ATSData.myAdequatStatus')}
        >
          {myAdequatStatusTitle}
        </CalloutInfoLine>
      </Callout>

      <hr />

      <Callout borderless title={t('profile.resume.ATSData.job')}>
        <CalloutInfoLine
          icon={<TagIcon />}
          title={t('profile.resume.ATSData.sector')}
        >
          <TranslatableInfoList infoList={_.map(sectors, (s) => s.name)} />
        </CalloutInfoLine>

        <CalloutInfoLine
          icon={<Paper />}
          title={t('profile.resume.ATSData.qualifications')}
        >
          <QualificationsList qualifications={qualifications} />
        </CalloutInfoLine>

        <CalloutInfoLine
          icon={<CheckmarkOutline />}
          title={t('profile.resume.ATSData.certifications')}
        >
          {_.map(certifications, (clearance) => (
            <Tag color='grey' key={clearance.id}>
              {getTranslatedText(lang, clearance.name)}
            </Tag>
          ))}
        </CalloutInfoLine>

        <CalloutInfoLine
          icon={
            <i
              className={classnames('ri-briefcase-4-line', styles.missionsIcon)}
            />
          }
          title={t('common.projects')}
        >
          <ProfileMissionsListInline profile={profile} />
        </CalloutInfoLine>

        {skills?.length && (
          <CalloutInfoLine
            icon={<Tools />}
            title={t('profile.resume.ATSData.skills')}
          >
            {_.map(skills, (skill) => getTranslatedText(lang, skill.name)).join(
              ', ',
            )}
          </CalloutInfoLine>
        )}
      </Callout>

      <hr />

      <Callout borderless title={t('profile.resume.ATSData.contractHistory')}>
        <AdventureContractsSeriesHistory
          agencyType={agencyType}
          contracts={contracts}
        />
      </Callout>

      {nbShowClicks >= 3 ? (
        <>
          <br />
          <hr />
          <br />
          <Callout
            borderless
            title={`${t('profile.resume.ATSData.contractHistory')} - DEBUG`}
          >
            <ContractHistory atsId='adventure' contracts={contracts} />
          </Callout>
        </>
      ) : (
        <div
          style={{ width: '100%', height: 100, cursor: 'pointer' }}
          onClick={() => {
            setNbShowClicks((v) => v + 1);
          }}
        />
      )}
    </>
  );
}
