import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useClientId from '@/hooks/router/useClientId';
import ButtonLink from '@/components/Common/GenericButton/ButtonLink';

import CardEmptyState from '../CardEmptyState';

interface FollowupEmptyStateProps {
  count: number;
  isPrimary: boolean;
  offerId: string;
}

const FollowupEmptyState: FC<FollowupEmptyStateProps> = ({
  count,
  isPrimary,
  offerId,
}) => {
  const clientId = useClientId();
  const { t } = useTranslation();

  return (
    <CardEmptyState
      illustrationPath='/images/placeholders/followupIllustration.svg'
      title={t('offers.profileEmptyStates.followup.title', { count })}
      cta={
        <ButtonLink
          to={`/client/${clientId}/discover/followup/${offerId}`}
          primacy={isPrimary ? 'primary' : 'secondary'}
        >
          {t('offers.profileEmptyStates.followup.cta')}
        </ButtonLink>
      }
    >
      {t('offers.profileEmptyStates.followup.innerContent', {
        count,
      })}
    </CardEmptyState>
  );
};

export default FollowupEmptyState;
