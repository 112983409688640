import _ from 'underscore';
import { ContactFlowSequence } from '@/types/searchPoolProfile';
import { UNIPILE_TASK_TYPES } from '@/common/constants/taskTypes';

const validateSequenceSendersAndSubjects = (sequence: ContactFlowSequence) => {
  const seenSenders: Record<string, boolean> = {};

  _.each(sequence.actions, (action) => {
    if (!action.message || _.includes(UNIPILE_TASK_TYPES, action.type)) {
      return;
    }

    const { senderId, subject } = action.message;

    if (!senderId) {
      // no need to check
      return;
    }

    // The first action of the sequence with this sender has no subject
    if (!subject && !seenSenders[senderId]) {
      throw new Error(
        `Sender ${senderId} has new action, message needs a subject`,
      );
    }

    // In case we manage to set a new subject on a later action
    if (seenSenders[senderId] && subject) {
      throw new Error(
        `Sender ${senderId} already used, message must not specify a new subject`,
      );
    }

    seenSenders[senderId] = true;
  });
};

export default validateSequenceSendersAndSubjects;
