import _ from 'underscore';
import React from 'react';
import {
  HiresweetLibProvider,
  HiresweetProject,
} from '@hiresweet/hiresweet-lib';
import useClientId from '@/hooks/router/useClientId';
import { useLocation } from 'react-router-dom';

const Playground14 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const projectId = searchParams.get('testProjectId') || "ri7-demo-testimport2-UMODD2";

  return (
    <div style={{ padding: 30 }}>
      <h1>P15 - Slash HiresweetProjects</h1>
      <div>
        <HiresweetLibProvider theme={theme} clientId={clientId}>
          <HiresweetProject projectId={projectId} />
        </HiresweetLibProvider>
      </div>
    </div>
  );
};

export default Playground14;
