import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

export const getSharedMessagingAccounts = gql`
  query getSharedMessagingAccounts {
    user {
      id
      email
      sharedMessagingAccounts {
        type
        id
        owner {
          firstname
          lastname
          email
        }
        users {
          email
        }
        serviceAccountId
        name
        expectedCode
        status
        scopes {
          type
        }
      }
    }
  }
`;

export type UnipileAccount = {
  id: string;
  firstname?: string;
  lastname?: string;
  senderNumber?: string;
  expectedCode?: string;
  name?: string;
  unipileAccount?: {
    id: string;
    username: string;
    type: string;
  };
  owner?: {
    email: string;
  };
  users?: {
    email: string;
  }[];
  scopes?: {
    type: string;
  }[];
};

export type SharedMessagingAccount = {
  type: string;
  id: string;
  owner: {
    firstname: string;
    lastname: string;
    email: string;
  };
  users: {
    email: string;
  }[];
  status: string;
  serviceAccountId: string;
  expectedCode?: string;
  name?: string;
  scopes?: {
    type: string;
  }[];
};

type GetSharedMessagingAccountsData = {
  user: {
    __typename: 'User';
    id: string;
    sharedMessagingAccounts: ({
      __typename: 'SharedMessagingAccount';
    } & SharedMessagingAccount)[];
  };
};

type GetSharedMessagingAccountsVariables = Record<string, never>;

type UseSharedMessagingAccountsInput = {
  skip?: boolean;
  queryOptions?: QueryHookOptions<
    GetSharedMessagingAccountsData,
    GetSharedMessagingAccountsVariables
  >;
};

interface UseSharedMessagingAccountsResult
  extends QueryResult<
    GetSharedMessagingAccountsData,
    GetSharedMessagingAccountsVariables
  > {
  sharedMessagingAccounts: SharedMessagingAccount[];
}

function useSharedMessagingAccounts({
  skip = false,
  queryOptions = {},
}: UseSharedMessagingAccountsInput = {}): UseSharedMessagingAccountsResult {
  const query = useQuery<
    GetSharedMessagingAccountsData,
    GetSharedMessagingAccountsVariables
  >(getSharedMessagingAccounts, { ...queryOptions, skip });

  const { sharedMessagingAccounts = [] } = query.data?.user || {};

  return { ...query, sharedMessagingAccounts };
}

export default useSharedMessagingAccounts;
