import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { PipelineDailyEvent } from '@/types/statistics/pipelineActions';
import { isTodayYyyyMmDd } from '@/common/utils/time';
import { GenericStatsFilterInput } from './common';

const GET_PIPELINE_ACTIONS_STATISTICS = gql`
  query getPipelineActionsStatistics(
    $clientId: ID!
    $missionsFilter: GenericStatsFilterInput
    $stagesFilter: GenericStatsFilterInput
    $authorEmailsFilter: GenericStatsFilterInput
    $startDate: Date
    $endDate: Date
  ) {
    client(id: $clientId) {
      id
      statistics {
        pipelineActionsByUser(
          missionsFilter: $missionsFilter
          stagesFilter: $stagesFilter
          authorEmailsFilter: $authorEmailsFilter
          startDate: $startDate
          endDate: $endDate
        ) {
          day
          userStats {
            author {
              email
              firstname
              lastname
            }
            stats {
              type
              missionId
              count
              archivedDetails {
                fromStage
                customArchiveReasonId
                category
              }
            }
          }
        }
      }
    }
  }
`;

interface GetPipelineActionsStatisticsData {
  client: {
    id: string;
    statistics: {
      pipelineActionsByUser: PipelineDailyEvent[];
    };
  };
}

export interface GetPipelineActionsStatisticsVariables {
  clientId: string;
  missionsFilter?: GenericStatsFilterInput;
  stagesFilter?: GenericStatsFilterInput;
  authorEmailsFilter?: GenericStatsFilterInput;
  startDate?: string;
  endDate?: string;
}

interface UsePipelineActionsStatisticsInput
  extends GetPipelineActionsStatisticsVariables {
  queryOptions?: QueryHookOptions<
    GetPipelineActionsStatisticsData,
    GetPipelineActionsStatisticsVariables
  >;
}

interface UsePipelineActionsStatisticsResult
  extends QueryResult<
    GetPipelineActionsStatisticsData,
    GetPipelineActionsStatisticsVariables
  > {
  pipelineActionsByUser?: PipelineDailyEvent[];
}

const usePipelineActionsStatistics = ({
  clientId,
  missionsFilter,
  stagesFilter,
  authorEmailsFilter,
  startDate,
  endDate,
  queryOptions = {},
}: UsePipelineActionsStatisticsInput): UsePipelineActionsStatisticsResult => {
  const query = useQuery<
    GetPipelineActionsStatisticsData,
    GetPipelineActionsStatisticsVariables
  >(GET_PIPELINE_ACTIONS_STATISTICS, {
    ...queryOptions,
    variables: {
      clientId,
      missionsFilter,
      stagesFilter,
      authorEmailsFilter,
      startDate,
      endDate: isTodayYyyyMmDd(endDate) ? undefined : endDate,
    },
  });

  const { pipelineActionsByUser } = query.data?.client.statistics || {};
  return { ...query, pipelineActionsByUser };
};

export default usePipelineActionsStatistics;
