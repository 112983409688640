import React, { useState } from 'react';
import _, { compose } from 'underscore';
import classNames from 'classnames';
import { Link, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Loader, Table } from 'semantic-ui-react';
import withClientOffers from '../../hocs/offers/withClientOffers';
import withActionLogger from '../../hocs/withActionLogger';

export const removeSkippedFromPipeSteps = (pipeSteps) => {
  return _.filter(
    pipeSteps,
    (pipeStep) => pipeStep !== 'skipped' && pipeStep.stepId !== 'skipped',
  );
};

const OfferRow = ({
  offerTitle,
  pipeStepStats,
  offerId,
  clientId,
  logAction,
  setRedirectPath,
}) => {
  const onClickStep = ({ stepId }) => {
    logAction({ type: 'click-offer-step-link' });
    setRedirectPath(
      `/client/${clientId}/jobs/${offerId}/profiles/step_${stepId}`,
    );
  };

  const onClickTitle = () => {
    logAction({ type: 'click-offer-link' });
    setRedirectPath(`/client/${clientId}/jobs/${offerId}/profiles`);
  };

  return (
    <Table.Row key={offerId} className='offers-table-row'>
      <Table.Cell onClick={onClickTitle}>
        <span>{offerTitle}</span>
      </Table.Cell>
      {_.map(pipeStepStats, ({ count, stepId }) => (
        <Table.Cell
          key={stepId}
          className={classNames(
            'offers-table-cell',
            stepId,
            !count && 'not-available',
          )}
          onClick={() => onClickStep({ stepId })}
        >
          <span>{count || '-'}</span>
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

const OffersDashboard = ({
  clientId,
  offers,
  clientOffersLoading,
  onLogAction,
  t,
}) => {
  const [redirectPath, setRedirectPath] = useState();
  const [seeAll, setSeeAll] = useState(false);

  const logAction = ({ type }) => {
    onLogAction({
      type,
      info: {
        clientId,
        from: 'offers-dashboard',
      },
    });
  };

  if (redirectPath) {
    return <Redirect push to={redirectPath} />;
  }

  if (clientOffersLoading) {
    return (
      <div className='offers dashboard-section'>
        <div className='header'>
          <h1>{t('offers.offers')}</h1>
        </div>
        <div className='offers-table-placeholder'>
          <Loader active inline='centered' size='large' />
        </div>
      </div>
    );
  }

  const N_DEFAULT_DISPLAYED_OFFERS = 10;

  const filteredOffers = _.where(offers, {
    isArchived: false,
    isSuggestedForCreation: false,
    isUnclassified: false,
  });
  const sortedOffers = _.sortBy(filteredOffers, ({ title }) =>
    (title || '').toLowerCase(),
  );
  const displayedOffers = _.first(
    sortedOffers,
    seeAll ? sortedOffers.length : N_DEFAULT_DISPLAYED_OFFERS,
  );

  if (_.isEmpty(displayedOffers)) {
    return (
      <div className='offers dashboard-section'>
        <OffersDashboardHeader clientId={clientId} t={t} />
        <div className='no-items-container'>
          <img
            src='/images/placeholders/jobs.svg'
            height='155'
            alt='jobs-placeholder'
          />
          <div className='no-items'>{t('dashboard.offers.noOffers')}</div>
        </div>
      </div>
    );
  }
  return (
    <div className='offers dashboard-section'>
      <OffersDashboardHeader clientId={clientId} t={t} />
      <Table basic style={{ border: 'none' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {/* <span>{t('dashboard.offers.name')}</span> */}
            </Table.HeaderCell>
            {_.map(
              removeSkippedFromPipeSteps(displayedOffers[0].pipeStepStats),
              ({ stepId }) => (
                <Table.HeaderCell key={stepId}>
                  <span>{t(`profile.steps.pipe.${stepId}`, stepId)}</span>
                </Table.HeaderCell>
              ),
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(displayedOffers, (offer) => (
            <OfferRow
              key={offer.id}
              pipeStepStats={removeSkippedFromPipeSteps(offer.pipeStepStats)}
              offerId={offer.id}
              offerTitle={offer.title}
              clientId={clientId}
              logAction={logAction}
              setRedirectPath={setRedirectPath}
            />
          ))}
        </Table.Body>
      </Table>
      {sortedOffers.length > N_DEFAULT_DISPLAYED_OFFERS && !seeAll && (
        <div className='see-all-link-container' onClick={() => setSeeAll(true)}>
          {t('dashboard.offers.seeAll')}
        </div>
      )}
    </div>
  );
};

const OffersDashboardHeader = ({ clientId, t }) => (
  <div className='header'>
    <h1>
      <Link to={`/client/${clientId}/jobs`}>{t('offers.offers')}</Link>
    </h1>
  </div>
);

export default compose(
  withActionLogger,
  withClientOffers,
  withTranslation('translations'),
)(OffersDashboard);
