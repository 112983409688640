import React, { useMemo, useRef } from 'react';
import _ from 'underscore';
import { Form, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { generateId } from '@/components/CustomFields/CustomFieldForm';
import { getRandomString } from '@/common';
import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import styles from '../../SnippetsSettings.module.less';

type Props = {
  options: SweetEvaluatorTypes.SelectOption[] | undefined;
  setOptions: (options: SweetEvaluatorTypes.SelectOption[]) => void;
};

const SnippetSelectOptions: React.FC<Props> = ({ options, setOptions }) => {
  const { t } = useTranslation();
  const newOptionId = useRef<string>('');
  const addOptionBtnRef = useRef<HTMLButtonElement | null>(null);

  const inputRefsById = useMemo(() => {
    const refs: Record<string, React.RefObject<Input>> = {};
    _.each(options || [], (option) => {
      refs[option.id] = React.createRef<Input>();
    });
    return refs;
  }, [options]);

  const handleAddOption = () => {
    const newId = getRandomString(5);
    const newOptions = [
      ...(options || []),
      { id: newId, title: { default: '' } },
    ];
    setOptions(newOptions);
    newOptionId.current = newId;
  };

  React.useEffect(() => {
    const currentInputRef = inputRefsById[newOptionId?.current];
    if (currentInputRef?.current) {
      currentInputRef.current.focus();
    }
    // eslint-disable-next-line
  }, [newOptionId.current]);

  const handleChangeOption = (optionId: string, newValue: string) => {
    const newOptions = _.map(options || [], (option) => {
      if (option.id === optionId) {
        return {
          id: generateId(newValue, {
            exclude: _.map(options || [], (op) => op.id),
          }),
          title: { default: newValue },
        };
      }
      return option;
    });
    setOptions(newOptions);
  };

  const handleRemove = (optionId: string) => {
    const newOptions = _.filter(
      options || [],
      (option) => option.id !== optionId,
    );
    setOptions(newOptions);
  };

  React.useEffect(() => {
    const handleKeypress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && addOptionBtnRef.current) {
        addOptionBtnRef.current.click();
      }
    };
    document.addEventListener('keypress', handleKeypress);
    return () => document.removeEventListener('keypress', handleKeypress);
  }, []);

  return (
    <Form.Field className={styles.modalFormField}>
      <label>{t('reveal.parameters.snippetSettings.options')}</label>
      {_.map(options || [], (option) => (
        <div className={styles.optionWrapper}>
          <Input
            className={styles.optionInput}
            ref={inputRefsById[option.id]}
            fluid
            value={option.title.default}
            onChange={(e, { value }) => {
              handleChangeOption(option.id, value);
            }}
          />
          <i
            className='ri-close-line'
            style={{ cursor: 'pointer' }}
            onClick={() => handleRemove(option.id)}
          />
        </div>
      ))}
      <GenericButton
        ref={addOptionBtnRef}
        primacy='secondary'
        onClick={handleAddOption}
      >
        <Plus />
        {t('reveal.parameters.snippetSettings.addOption')}
      </GenericButton>
    </Form.Field>
  );
};

export default SnippetSelectOptions;
