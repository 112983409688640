import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

const mutation = gql`
  mutation registerOfflineGrant($type: String!, $code: String!) {
    registerEmailAccount(type: $type, code: $code)
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    registerOfflineGrant: (type, code) => mutate({ variables: { type, code } }),
  }),
});
