import React, { FC } from 'react';
import classNames from 'classnames';

import InfoTooltip from '@/components/Common/InfoTooltip';
import { getPercentage } from '@/common/utils/number';
import { KPIItem } from '../../tabs/SequencesTab/helpers/kpis';

import './FunnelKPI.css';

interface FunnelKPIProps {
  kpiItems: KPIItem[];
}

const FunnelKPI: FC<FunnelKPIProps> = ({ kpiItems }) => {
  return (
    <div className='funnel-kpi'>
      {kpiItems.map((kpiItem) => {
        return kpiItem?.rate !== undefined ? (
          <RateKPIItem key={kpiItem?.id} kpiItem={kpiItem} />
        ) : (
          <FunnelKPIItem key={kpiItem?.id} kpiItem={kpiItem} />
        );
      })}
    </div>
  );
};

interface FunnelKPIItemProps {
  kpiItem: KPIItem;
}

const FunnelKPIItem: FC<FunnelKPIItemProps> = ({ kpiItem }) => {
  const { text, tooltipContent, value, color, onClick } = kpiItem || {};
  return (
    <div className='kpi-item'>
      <div className='kpi-title'>
        {text}
        {tooltipContent && <InfoTooltip rich>{tooltipContent}</InfoTooltip>}
      </div>
      <div className={classNames('score', color)}>
        <button
          type='button'
          onClick={value ? onClick : undefined}
          className={classNames('absolute', onClick && value && 'clickable')}
        >
          {value}
        </button>
      </div>
    </div>
  );
};

interface RateKPIItemProps {
  kpiItem: KPIItem;
}

const RateKPIItem: FC<RateKPIItemProps> = ({ kpiItem }) => {
  const { text, tooltipContent, value, referenceValue, rate, color, onClick } =
    kpiItem || {};
  return (
    <div className='kpi-item rate'>
      <div className='kpi-title'>
        {text}
        {tooltipContent && (
          <InfoTooltip rich hoverable>
            {tooltipContent}
          </InfoTooltip>
        )}
      </div>
      <div className={classNames('score', color)}>
        <button
          type='button'
          onClick={rate ? onClick : undefined}
          className={classNames('absolute', onClick && rate && 'clickable')}
        >
          {referenceValue ? (
            <span>
              {getPercentage(Math.min(rate || 0, 1), 1, 1)}
              <span className='symbol'>%</span>
            </span>
          ) : (
            '-'
          )}
        </button>
        {!!referenceValue && (
          <div className='percent'>{`(${value}/${referenceValue})`}</div>
        )}
      </div>
    </div>
  );
};

export default FunnelKPI;
