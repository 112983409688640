import GenericButton from '@/components/Common/GenericButton';
import { Department } from '@/types/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import _ from 'underscore';
import GenericDropdown from '@/components/Common/GenericDropdown';
import Dots from '@/components/Common/Icons/Dots';
import DropdownPanel from '@/components/Common/DropdownPanel';
import DropdownMenuItem from '@/components/Common/DropdownMenuItem';
import Edit from '@/components/Reveal/Icons/Edit';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import Delete from '@/components/Reveal/Icons/Delete';
import ArrowUp from '@/components/Reveal/Icons/ArrowUp';
import styles from './DepartmentTable.module.less';

interface DepartmentTableProps {
  departments: Department[];
  onRemove: ({ departmentId }: { departmentId: string }) => void;
  onEdit: ({ departmentId }: { departmentId: string }) => void;
  onMoveUpward: ({ departmentId }: { departmentId: string }) => void;
  onMoveDownward: ({ departmentId }: { departmentId: string }) => void;
}

const DepartmentTable: React.FC<DepartmentTableProps> = ({
  departments,
  onRemove,
  onEdit,
  onMoveUpward,
  onMoveDownward,
}) => {
  const { t } = useTranslation();

  if (_.isEmpty(departments)) {
    return null;
  }

  return (
    <Table basic className={styles.table}>
      <Table.Header className={styles.header}>
        <Table.Row className={styles.headerRow}>
          <Table.HeaderCell className={styles.th}>
            <span>{t('settings.departments.table.name')}</span>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.th}>
            <span>{t('settings.departments.table.section')}</span>
          </Table.HeaderCell>
          <Table.HeaderCell collapsing className={styles.th} />
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {_.map(departments || [], (department, index) => (
          <Table.Row key={department.id} className={styles.row}>
            <Table.Cell textAlign='left' className={styles.name}>
              <span>{department.title}</span>
            </Table.Cell>
            <Table.Cell textAlign='left' className={styles.subtype}>
              <div className={styles.sectionsContainer}>
                {_.map(department?.sections || [], (section) => (
                  <div className={styles.sectionContainer} key={section.id}>
                    <span className='pill-message grey mini'>
                      {section.title}
                    </span>
                    <div className={styles.subSectionsContainer}>
                      {_.map(section.subsections || [], (subSection) => (
                        <span key={subSection.id}>{subSection.title}</span>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </Table.Cell>

            <Table.Cell className={styles.actions}>
              <GenericDropdown
                position='right'
                Trigger={({ onClick }) => (
                  <GenericButton primacy='tertiary' onClick={onClick}>
                    <Dots />
                  </GenericButton>
                )}
              >
                <DropdownPanel className={styles.actionPanel}>
                  <DropdownMenuItem
                    className={styles.actionPanelItem}
                    onClick={() => {
                      onEdit({ departmentId: department.id });
                    }}
                  >
                    <span>
                      <Edit />
                    </span>
                    {t('common.edit')}
                  </DropdownMenuItem>
                  {index > 0 ? (
                    <DropdownMenuItem
                      className={styles.actionPanelItem}
                      onClick={() => {
                        onMoveUpward({ departmentId: department.id });
                      }}
                    >
                      <span>
                        <ArrowUp />
                      </span>
                      {t('settings.departments.moveUpward')}
                    </DropdownMenuItem>
                  ) : (
                    ''
                  )}
                  {index + 1 < (department?.sections || []).length - 1 ? (
                    <DropdownMenuItem
                      className={styles.actionPanelItem}
                      onClick={() => {
                        onMoveDownward({ departmentId: department.id });
                      }}
                    >
                      <span>
                        <ArrowDown />
                      </span>
                      {t('settings.departments.moveDownward')}
                    </DropdownMenuItem>
                  ) : (
                    ''
                  )}
                  <DropdownMenuItem
                    className={styles.actionPanelItem}
                    onClick={() => {
                      onRemove({ departmentId: department.id });
                    }}
                  >
                    <span>
                      <Delete />
                    </span>
                    {t('settings.departments.delete')}
                  </DropdownMenuItem>
                </DropdownPanel>
              </GenericDropdown>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default DepartmentTable;
