import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonToggle from '../../../components/ButtonToggle';
import { Operator } from './types';

export const OR_OPERATOR = 'OR';
export const AND_OPERATOR = 'AND';

interface OperatorToggleProps {
  operator: Operator;
  setOperator: (value: Operator) => void;
}

const OperatorToggle: FC<OperatorToggleProps> = ({ operator, setOperator }) => {
  const { t } = useTranslation();
  const operatorOptions = [
    { value: OR_OPERATOR, label: t('watchCollect.filters.operators.OR') },
    { value: AND_OPERATOR, label: t('watchCollect.filters.operators.AND') },
  ];
  return (
    <ButtonToggle
      className='operator-toggle'
      currentValue={operator}
      setValue={setOperator}
      options={operatorOptions}
    />
  );
};

export default OperatorToggle;
