import classNames from 'classnames';
import React from 'react';

import styles from './Pagination.module.less';

interface PaginationProps {
  step: number;
  nbSteps: number;
}
const Pagination: React.FC<PaginationProps> = ({ nbSteps, step }) => {
  return (
    <div className={styles.wrapper}>
      {Array(nbSteps)
        .fill(null)
        .map((v, index) => (
          <div
            className={classNames(styles.item, {
              [styles.active]: index === step,
            })}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        ))}
    </div>
  );
};

export default Pagination;
