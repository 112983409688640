import React, { FC, useState } from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Offer } from '@/types/offer';
import Dots from '@/components/Common/Icons/Dots';
import useClientId from '@/hooks/router/useClientId';
import { useSearchPoolOfferCandidatesCount } from '@/graphql/hooks/searchPool/useSearchPoolCounts';
import Counter from '@/components/Common/Counter/Counter';

import styles from './UnifiedViewStepNav.module.less';

const unifiedViewLeftSteps = ['pending'] as const;
const unifiedViewRightSteps = ['answered', 'in-process', 'hired'] as const;

interface UnifiedViewStepNavProps {
  offer: Offer;
  activeStepId: string;
  activeSubStepId: string;
  onSelectStep: (stepInfo: {
    newStepId: string;
    newSubStepId?: string;
  }) => void;
  shouldSplitPending: boolean;
}

const UnifiedViewStepNav: FC<UnifiedViewStepNavProps> = ({
  offer,
  activeStepId,
  activeSubStepId,
  onSelectStep,
  shouldSplitPending,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { pageId } = useParams<{ pageId: string }>();

  const { offerCandidatesCount } = useSearchPoolOfferCandidatesCount(offer.id);

  const [showRemainder, setShowRemainder] = useState(false);

  const { pipeStepStats = [] } = offer;

  const getPendingStepFromSubStep = (subStep: {
    subStepId: string;
    count: number;
  }) => ({
    stepId: 'pending',
    ...subStep,
  });

  const isActiveStep = (stepId: string, subStepId?: string) =>
    stepId === activeStepId &&
    (!shouldSplitPending ||
      stepId !== 'pending' ||
      !_.contains(['pending-main', 'pending-extended'], subStepId) ||
      subStepId === activeSubStepId);

  const leftStepStats = _.filter(pipeStepStats, (step) =>
    _.contains(unifiedViewLeftSteps, step.stepId),
  );

  const rightStepStats = _.filter(
    pipeStepStats,
    (step) =>
      _.contains(unifiedViewRightSteps, step.stepId) &&
      (step.stepId !== 'hired' || step.count !== 0),
  );

  const cleanedLeftPipe: {
    stepId: string;
    count: number;
    subStepId?: string;
  }[] = _.flatten(
    _.map(leftStepStats, (step) =>
      step.stepId === 'pending' && shouldSplitPending
        ? _.map(step.pipeSubStepStats || [], getPendingStepFromSubStep)
        : step,
    ),
  );

  return (
    <nav className={styles.navigation}>
      {_.map(cleanedLeftPipe, ({ stepId, count, subStepId }, index) => (
        <button
          type='button'
          key={index}
          className={classNames(styles.tab, {
            [styles.active]: isActiveStep(stepId, subStepId),
          })}
          onClick={() =>
            onSelectStep({
              newStepId: stepId,
              newSubStepId: subStepId,
            })
          }
        >
          <span className={styles.label}>
            {subStepId
              ? t(`profile.steps.pipe.${subStepId}`)
              : t(`profile.steps.pipe.${stepId}`)}
          </span>
          <Counter
            value={count}
            active={isActiveStep(stepId, subStepId)}
            className={styles.counter}
          />
        </button>
      ))}
      <Link
        type='button'
        className={classNames(styles.tab, {
          [styles.active]: pageId === 'explore',
        })}
        to={`/client/${clientId}/jobs/${offer.id}/explore`}
      >
        <span className={styles.label}>{t('profile.steps.pipe.explore')}</span>
        <Counter
          className={styles.counter}
          value={offerCandidatesCount}
          active={pageId === 'explore'}
        />
      </Link>
      <div className={styles.separator} />
      {_.map(rightStepStats, ({ stepId, count }, index) => (
        <button
          type='button'
          key={index}
          className={classNames(styles.tab, {
            [styles.active]: isActiveStep(stepId),
          })}
          onClick={() =>
            onSelectStep({
              newStepId: stepId,
            })
          }
        >
          <span className={styles.label}>
            {t(`profile.steps.pipe.${stepId}`)}
          </span>
          <Counter
            value={count}
            active={isActiveStep(stepId)}
            className={styles.counter}
          />
        </button>
      ))}
      <div className={styles.remainder}>
        <button
          type='button'
          className={classNames(styles.trigger, {
            [styles.active]: isActiveStep('skipped'),
          })}
          onClick={() => setShowRemainder(!showRemainder)}
          onBlur={() => setShowRemainder(false)}
        >
          <Dots />
        </button>
        <div
          className={classNames(styles.dropdown, {
            [styles.open]: showRemainder,
          })}
        >
          <button
            type='button'
            className={classNames(styles.option, {
              [styles.active]: isActiveStep('skipped'),
            })}
            onClick={() => {
              onSelectStep({
                newStepId: 'skipped',
              });
              setShowRemainder(false);
            }}
            onMouseDown={(e) => e.preventDefault()}
          >
            {t('profile.steps.pipe.skipped')}
          </button>
        </div>
      </div>
    </nav>
  );
};

export default UnifiedViewStepNav;
