import { createContext } from 'react';

export interface DropdownControls {
  toggleDropdown: () => void;
  closeDropdown: () => void;
  whitelistElement: (element: HTMLElement) => void;
}

const DropdownControlsContext = createContext<DropdownControls>({
  toggleDropdown: () => {},
  closeDropdown: () => {},
  whitelistElement: () => {},
});

export default DropdownControlsContext;
