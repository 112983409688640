import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import useSearchPoolJobs from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobs';
import { CalibrationAndRecommendationIcon } from '@/assets/icons';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import usePipelineSubSteps from '@/graphql/hooks/clients/usePipelineSubSteps';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import { useMutation } from '@apollo/client';
import { REMOVE_TIMELINE_ITEM } from '@/graphql/searchPoolProfile';
import { getTranslatedText } from '@/common/helpers/translatableText';
import { getShortLanguage } from '@/common/utils/i18n';
import useClientId from '@/hooks/router/useClientId';

import Thumbnail from '../../../../../components/Thumbnail';
import { formatDate } from '../../../../../common/dates';
import TASK_ICONS from '../TaskIcons';
import IntegrationsSynchronizationStatus from '../IntegrationsSynchronizationStatus';
import TimelineItemActions, {
  TimelineItemSynchronizationActions,
} from '../TimelineItemActions';
import AuthorAndDate from '../AuthorAndDate';

const RevealMissionItem = ({
  item,
  unbiasedModeEnabled,
  profile,
  missionAction,
  clientId,
}) => {
  const { t, i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const { pipelineSubSteps } = usePipelineSubSteps();
  const missionCategoryType = useContext(MissionCategoryContext);
  const subStepsDefinition = useMemo(
    () =>
      _.find(
        pipelineSubSteps,
        ({ missionCategory, stepId }) =>
          missionCategory.type === missionCategoryType &&
          stepId === 'interested',
      ),
    [pipelineSubSteps, missionCategoryType],
  );
  const missionInterestedSubSteps = useMemo(
    () => subStepsDefinition?.subSteps || [],
    [subStepsDefinition],
  );
  const interestedSubStepsTitlesById = useMemo(() => {
    const result = {};
    _.each(missionInterestedSubSteps, ({ id, title }) => {
      result[id] = getTranslatedText(lang, title);
    });
    return result;
  }, [lang, missionInterestedSubSteps]);

  const { action, author } = item || {};
  const { firstname, lastname } = profile?.resumeData || {};
  let profileName = `${firstname || ''} ${lastname || ''}`.trim();
  profileName = unbiasedModeEnabled ? '???' : profileName;

  const { data, loading } = useSearchPoolJobs(
    'reveal',
    {
      includeAll: true,
    },
    'cache-first',
  );

  const job = useMemo(() => {
    if (loading) {
      return null;
    }

    return _.findWhere(data?.searchPool?.jobs || [], { id: action.missionId });
  }, [data, action, loading]);

  if (loading) {
    return null;
  }

  const { removalReason } = item;

  const getItemDescription = () => {
    let title = t(`profile.contact.timeline.${missionAction}.description`, {
      authorName: `${author?.firstname || ''} ${author?.lastname || ''}`,
      profileName,
      missionName: job?.data?.title,
      ...(missionAction === 'missionStageUpdated' && {
        stage:
          action.stage === 'interested' && action.subStage
            ? interestedSubStepsTitlesById[action.subStage] ??
              t(`reveal.pipelineSegmentations.${action.stage}`)
            : t(`reveal.pipelineSegmentations.${action.stage}`),
      }),
    });
    if (action.customArchiveReasonTitle) {
      title = `${title} (${action.customArchiveReasonTitle})`;
    } else if (action.label) {
      const labelSplit = action.label.split(';');
      _.each(labelSplit, (label) => {
        title = `${title} ${t(
          `profile.contact.timeline.missionStageUpdated.label.${label}`,
        )}`;
      });
    }
    return title;
  };

  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{ backgroundColor: TASK_ICONS[missionAction]?.color }}
              className='icon'
            >
              {missionAction === 'acceptedFromMissionRecommendations' ||
              missionAction === 'skipped-from-mission-recommendations' ? (
                <CalibrationAndRecommendationIcon className='icon-center' />
              ) : (
                <i className={`${TASK_ICONS[missionAction]?.icon} ri-2x`} />
              )}
            </div>
          </div>
          <div className='author'>
            <div
              dangerouslySetInnerHTML={{
                __html: getItemDescription(),
              }}
            />
            {removalReason && `(${removalReason})`}
            <AuthorAndDate timelineItem={item}>
              <IntegrationsSynchronizationStatus
                timelineItem={item}
                clientId={clientId}
                profile={profile}
              />
            </AuthorAndDate>
          </div>
          <div className='timeline-item-actions'>
            <TimelineItemSynchronizationActions
              clientId={clientId}
              profile={profile}
              timelineItem={item}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ClassicMissionItem = ({
  item,
  unbiasedModeEnabled,
  profile,
  missionAction,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const [removeTimelineItemMutation] = useMutation(REMOVE_TIMELINE_ITEM);

  const [job, setJob] = useState(null);
  const { date, action, author } = item || {};
  const { firstname, lastname } = profile?.resumeData || {};
  let profileName = `${firstname || ''} ${lastname || ''}`.trim();
  profileName = unbiasedModeEnabled ? '???' : profileName;

  const recruiterName = `${author?.firstname || ''} ${
    author?.lastname || ''
  }`.trim();

  const { jobOptions, jobOptionsLoading: loading } =
    useSearchPoolJobOptions('reveal');

  const jobId = job?.id;
  useEffect(() => {
    if (!loading && jobId !== action.missionId) {
      setJob(
        _.findWhere(jobOptions, {
          id: action.missionId,
        }),
      );
    }
  }, [loading, jobOptions, action.missionId, jobId]);

  if (loading) {
    return null;
  }

  const removeTimelineItem = async () => {
    await removeTimelineItemMutation({
      variables: {
        searchPoolId: 'reveal',
        profileId: profile?.id,
        type: action?.type,
        date,
      },
    });
  };

  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='header'>
          <Thumbnail
            link={author?.photoLink}
            firstname={author?.firstname}
            lastname={author?.lastname}
            email={author?.email}
          />
          <div className='author'>
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  `profile.contact.timeline.${missionAction}.description`,
                  {
                    authorName: `${author?.firstname || ''} ${
                      author?.lastname || ''
                    }`,
                    profileName,
                    missionName: job?.data?.title,
                    ...(missionAction === 'missionStageUpdated' && {
                      stage: t(`reveal.pipelineSegmentations.${action?.stage}`),
                    }),
                  },
                ),
              }}
            />
            {(recruiterName || date) && (
              <div className='date'>
                {recruiterName && (
                  <b>{`${t('common.by')} ${recruiterName} `}</b>
                )}
                {date && formatDate(date, t)}
              </div>
            )}
          </div>
          <div className='timeline-item-actions'>
            <TimelineItemActions
              onRemove={removeTimelineItem}
              profile={profile}
              clientId={clientId}
              timelineItem={item}
              withAtsSynchronization={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MissionItem = ({
  timelineItem,
  unbiasedModeEnabled,
  profile,
  missionAction,
  applicationMode,
  clientId,
}) => {
  if (applicationMode === 'classic') {
    return (
      <ClassicMissionItem
        item={timelineItem}
        unbiasedModeEnabled={unbiasedModeEnabled}
        profile={profile}
        missionAction={missionAction}
      />
    );
  }
  return (
    <RevealMissionItem
      item={timelineItem}
      unbiasedModeEnabled={unbiasedModeEnabled}
      profile={profile}
      missionAction={missionAction}
      clientId={clientId}
    />
  );
};

export default MissionItem;
