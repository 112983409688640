import _ from 'underscore';

import { SweetEvaluatorTypes } from '@/SweetEvaluator';

export default class ExpressionLastCompanyEvaluator
  implements SweetEvaluatorTypes.EvaluatorInterface {
  evaluate({
    context,
  }: {
    context: SweetEvaluatorTypes.Context;
  }): { value: string | undefined } {
    const anyContext = context as any;
    const profileExperiences =
      anyContext.experiences || anyContext.data?.experiences || null;

    if (_.isEmpty(profileExperiences)) {
      return { value: undefined };
    }

    return {
      value: profileExperiences[0]?.companyName || undefined,
    };
  }
}
