import React from 'react';
import {
  HiresweetLibProvider,
  ProfileContainer,
  ProfileExperiences,
} from '@hiresweet/hiresweet-lib';

import useClientId from '@/hooks/router/useClientId';

const PlaygroundT1 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <div style={{ padding: 30 }}>
      <h1>P-T-1 - Profile experiences</h1>
      <HiresweetLibProvider theme={theme} clientId={clientId}>
        <ProfileContainer profileId='jean-bombeur-45T0ZI'>
          <ProfileExperiences />
        </ProfileContainer>
      </HiresweetLibProvider>
    </div>
  );
};

export default PlaygroundT1;
