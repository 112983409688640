import React from 'react';
import _, { compose } from 'underscore';
import { Link, withRouter } from 'react-router-dom';
import { Form, Loader, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import ProjectDropdown from '@/components/Common/ProjectDropdown/ProjectDropdown';
import GenericButton from '@/components/Common/GenericButton';
import { SequenceDropdownForJob } from '@/components/Reveal/Sequences/SequenceDropdown/SequenceDropdown';
import SidePanel from '../../../../../components/SidePanel';
import withClientUsers from '../../../../../hocs/clients/withClientUsers';
import contextToProps from '../../../../../hocs/contextToProps';
import withUserFromJWToken from '../../../../../hocs/users/withUserFromJWToken';
import SequencePreview from '../../../revealComponents/SequencePreview';
import useAddToProjectFormState from './hooks/useAddToProjectFormState';
import './AddToSequenceSidebar.css';

const AddToProjectSidebar = ({
  clientId,
  jobId,
  candidates,
  onClose,
  onShowNotification,
}) => {
  const { t } = useTranslation();
  const { formState, formOptions } = useAddToProjectFormState({
    clientId,
    jobId,
    candidates,
    onShowNotification,
    onClose,
  });

  const translationModalSelector = `reveal.modals.${
    jobId ? 'addToTheMission' : 'addToMission'
  }`;

  const footerContent = (
    <GenericButton
      size='big'
      disabled={formState?.submissionDisabled || formState?.submitting}
      onClick={formState?.onSubmit}
    >
      {t(`${translationModalSelector}.addToProject`)}
    </GenericButton>
  );

  if (_.isEmpty(formOptions?.jobs)) {
    return (
      <SidePanel
        className='add-to-project-sidebar'
        title={t(`${translationModalSelector}.header`, {
          count: candidates?.length,
        })}
        footerContent={footerContent}
        onClose={onClose}
      >
        {formState?.loading ? (
          <div>
            <br />
            <br />
            <br />
            <Loader active inline />
            <br />
            <br />
            <br />
          </div>
        ) : (
          <div className='no-projects-content'>
            <span>{t('reveal.modals.addToMission.noProject')}</span>
            <br />
            <span>{t('reveal.modals.addToMission.goTo')}</span>{' '}
            <Link to={`/client/${clientId}/projects`}>
              {t('reveal.modals.addToMission.projectPage')}
            </Link>
            <span> {t('reveal.modals.addToMission.toCreateOne')}</span>
          </div>
        )}
      </SidePanel>
    );
  }

  const handleClose = () => {
    if (!formState.submitting) {
      onClose();
    }
  };

  return (
    <SidePanel
      className='add-to-project-sidebar'
      title={t(`${translationModalSelector}.header`, {
        count: candidates?.length,
      })}
      footerContent={footerContent}
      onClose={handleClose}
    >
      <AddToProjectForm
        clientId={clientId}
        jobId={formState?.selectedJobId}
        candidates={candidates}
        formState={formState}
        formOptions={formOptions}
      />
    </SidePanel>
  );
};

const AddToProjectForm = ({
  clientId,
  jobId,
  candidates,
  formState,
  formOptions,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Form className='tasks-form'>
        <div className='job-dropdown'>
          <span>{t('reveal.modals.addToMission.title')}</span>
          <ProjectDropdown
            clearable
            onClear={formState.onClear}
            currentMission={_.findWhere(formOptions?.jobs, {
              id: formState.selectedJobId,
            })}
            projects={formOptions.jobs}
            disabledProjectIds={_.filter(
              formOptions?.jobs,
              (job) => job.isArchived,
            )}
            onProjectSelected={(project) => {
              formState.onChangeJob(project);
            }}
            name='missions'
            placeholder={t('reveal.modals.addToMission.projectPlaceholder')}
            size='small'
          />
        </div>

        <Form.Checkbox
          className='sequence-checkbox'
          checked={formState?.addToSequence}
          onChange={formState?.toggleAddToSequence}
          label={t('reveal.modals.addToMission.alsoAddToSequence', {
            count: candidates?.length,
          })}
        />
        {formState?.addToSequence && (
          <>
            <div id='sequence-dropdown'>
              <span>{t('reveal.sequenceSideBar.sequence')}</span>
              <SequenceDropdownForJob
                jobId={jobId}
                clearable={!_.isEmpty(formState?.selectedSequenceId)}
                onClear={formState.onClearSequence}
                clientId={clientId}
                currentSequence={{
                  sequenceId: formState?.selectedSequenceId,
                }}
                placeholder={t(
                  'reveal.modals.addToMission.sequencePlaceholder',
                )}
                onSequenceSelected={(id) => {
                  if (formState) {
                    formState.setSelectedSequenceId(id);
                  }
                }}
                className='sidebar-sequence-popup'
              />
            </div>
          </>
        )}
      </Form>

      {formState?.addToSequence && formState?.selectedSequence && (
        <Segment className='sequence-preview-container'>
          <SequencePreview
            clientId={clientId}
            sequence={formState?.selectedSequence}
            alwaysExpanded
          />
        </Segment>
      )}
    </>
  );
};

export default compose(
  withRouter,
  withUserFromJWToken,
  withClientUsers,
  contextToProps,
)(AddToProjectSidebar);
