import _ from 'underscore';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import GenericRangeSlider from '@/components/Common/GenericRangeSlider';
import logAction from '../../../common/logAction';

import styles from './MarkeplaceRange.module.less';

const SalaryCriterionPopup = ({
  salaryMin,
  salaryMax,
  setSalaryMin,
  setSalaryMax,
  resetSelectedSearch,
  clientId,
  user,
  t,
}) => {
  const [salaryMinState, setSalaryMinState] = useState(salaryMin);
  const [salaryMaxState, setSalaryMaxState] = useState(salaryMax);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSalaryMinState(salaryMin);
    setSalaryMaxState(salaryMax);
  }, [salaryMin, salaryMax]);

  const apply = () => {
    if (salaryMin !== salaryMinState) {
      setSalaryMin(salaryMinState);
      resetSelectedSearch();
    }
    if (salaryMax !== salaryMaxState) {
      setSalaryMax(salaryMaxState);
      resetSelectedSearch();
    }
    setOpen(false);
    logAction({
      type: 'discover-change-filter-salary',
      info: {
        clientId,
        author: `${user?.firstname} ${user?.lastname}`,
      },
    });
  };

  const reset = () => {
    setSalaryMinState('');
    setSalaryMaxState('');
  };

  const isActive = _.isNumber(salaryMin) || _.isNumber(salaryMax);
  return (
    <Popup
      open={open}
      trigger={
        <div
          className={classNames('criterion-button', isActive && 'active')}
          onClick={() => setOpen(!open)}
        >
          {isActive
            ? t('watchCollect.filters.salary.label', {
                minimum: Math.floor(salaryMin || 30),
                maximum: Math.floor(salaryMax || 200),
              })
            : t('watchCollect.filters.salary.defaultLabel')}
        </div>
      }
      onClose={() => {
        apply();
        setOpen(false);
      }}
      on='click'
      basic
      position='bottom center'
      className='criterion-popup'
    >
      <SalaryCriterion
        salaryMin={salaryMinState}
        salaryMax={salaryMaxState}
        setSalaryMin={setSalaryMinState}
        setSalaryMax={setSalaryMaxState}
        t={t}
      />
      <div className='actions-container'>
        <Button className='reset-button' onClick={reset}>
          {t('watchCollect.filters.reset')}
        </Button>
        <Button className='primary-cta' onClick={apply}>
          {t('watchCollect.filters.apply')}
        </Button>
      </div>
    </Popup>
  );
};

const SALARY_MIN_VALUE = 30;
const SALARY_MAX_VALUE = 200;

const formatSalaryLabel = (value) => {
  return value ? `${value}k` : value;
};

export const SalaryCriterion = ({
  salaryMin,
  salaryMax,
  setSalaryMin,
  setSalaryMax,
  t,
}) => {
  const onChange = ([minValue, maxValue]) => {
    setSalaryMin(minValue === SALARY_MIN_VALUE ? '' : minValue);
    setSalaryMax(maxValue === SALARY_MAX_VALUE ? '' : maxValue);
  };

  return (
    <div className='salary-criterion'>
      <div className='input-range-container'>
        <GenericRangeSlider
          min={30}
          max={200}
          step={1}
          values={[
            salaryMin || SALARY_MIN_VALUE,
            salaryMax || SALARY_MAX_VALUE,
          ]}
          onChange={onChange}
          formatLabel={(value) => formatSalaryLabel(value)}
          labelClassName={styles.marketplaceRangeLabel}
        />
      </div>
      <div className='min-max-labels'>
        <div>{t('watchCollect.filters.salary.minimum')}</div>

        <div>{t('watchCollect.filters.salary.maximum')}</div>
      </div>
    </div>
  );
};

export default withTranslation('translations')(SalaryCriterionPopup);
