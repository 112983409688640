import React, { FC, useCallback } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import { getShortLanguage } from '@/common/utils/i18n';
import { SearchItem, search } from '@/common/search';

import GenericAsyncSelect from '../GenericSelect/GenericAsyncSelect';

type MultilingualSelectProps = {
  options: SearchItem[];
  value: string;
  onValue: (value: string | undefined) => void;
};

const MultilingualSelect: FC<MultilingualSelectProps> = ({
  options,
  value,
  onValue,
}) => {
  const { i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);

  const loadOptions = useCallback(
    async (searchText: string) =>
      search(searchText, options, { mainLanguage: lang }),
    [lang, options],
  );

  return (
    <GenericAsyncSelect
      isClearable
      isMulti={false}
      defaultOptions={options}
      loadOptions={loadOptions}
      getOptionValue={({ id }) => id}
      getOptionLabel={({ id, labels }) => labels[lang] || labels.en || id}
      value={_.findWhere(options, { id: value })}
      onChange={(newValue) => {
        if (!newValue) {
          onValue(undefined);
          return;
        }
        onValue(newValue.id);
      }}
    />
  );
};

export default MultilingualSelect;
