import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import BasicCredentialsForm from '@/containers/Parameters/Integrations/CredentialsForms/BasicCredentialsForm';
import TokenAndAccountIdCredentialsForm from '@/containers/Parameters/Integrations/CredentialsForms/TokenAndAccountIdCredentialsForm';
import { IRevealConnector } from '@/graphql/fragments/RevealConnector';
import { ATStype, ATS_NAMES_BY_TYPE } from '@/common/reveal';
import { PrimaryCobalt } from '@/less/colors';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import { showNavIntegrationSwitch } from '@/common/constants/sharedIntegrations';
import {
  MissionWorkflowsProvider,
  WORKFLOWS_CONTEXT_MODE,
} from '@/components/Reveal/MissionWorkflows/context/useMissionWorkflowsContext';
import MissionWorkflows from '@/components/Reveal/MissionWorkflows/MissionWorkflows';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import _ from 'underscore';
import RevealIntegrationLinkedUser from './LinkedUser/RevealIntegrationLinkedUser';
import IntegrationSynchronizationSettings from './IntegrationSynchronizationSettings';
import IntegrationInstructions from './IntegrationInstructions';

import styles from './RevealIntegrations.module.less';

interface RevealIntegrationProps {
  clientId: string;
  connector?: IRevealConnector;
  type: ATStype;
  mode: 'oauth' | 'token';
}

const RevealIntegration: React.FC<RevealIntegrationProps> = ({
  clientId,
  connector,
  type,
  mode,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const permissions = useClientPermissions(clientId).data?.client?.permissions;
  const showSwitch = showNavIntegrationSwitch(type);

  return (
    <>
      <div className='welcomekit-integration'>
        <div className='settings-header'>
          <Breadcrumb
            icon='right angle'
            size='massive'
            style={{
              color: PrimaryCobalt,
              fontWeight: 700,
              fontFamily: 'Gilroy',
              fontSize: '26px',
            }}
          >
            <Breadcrumb.Section
              as={Link}
              to={`/client/${clientId}/crm-integrations`}
              style={{
                color: PrimaryCobalt,
                fontFamily: 'Gilroy',
                marginBottom: '0px',
              }}
            >
              <h1>
                {t('integrations.header')}{' '}
                {permissions?.reveal && permissions?.watchCollect && '(CRM)'}
              </h1>
            </Breadcrumb.Section>
            <Breadcrumb.Divider
              icon={
                <i
                  className={classnames(
                    styles.breadcrumbDividerIcon,
                    'ri-arrow-right-s-line',
                  )}
                />
              }
            />
            <Breadcrumb.Section
              active
              style={{ fontFamilly: 'Gilroy', marginBottom: '0px' }}
            >
              <h1>{ATS_NAMES_BY_TYPE[type]}</h1>
            </Breadcrumb.Section>
          </Breadcrumb>
        </div>
        {permissions?.reveal && permissions?.watchCollect && showSwitch && (
          <>
            <p className={styles.subHeader}>
              {t('integrations.subheader', { client: 'Marketplace' })}
              <Link to={`/client/${clientId}/integrations/${type}`}>
                {' '}
                {t('integrations.clickHere')}
              </Link>
              .
            </p>
          </>
        )}
        <div className='section visible settings-section'>
          <div className='integration-header'>
            <div className='integration-header-text'>
              {connector
                ? t('integrations.ATSConnected')
                : t('integrations.connectATS')}
            </div>
          </div>
          {!connector ? (
            <>
              <CredentialForm type={type} mode={mode} />
              {(type === 'lever' ||
                type === 'greenhouse' ||
                type === 'smartrecruiters' ||
                type === 'teamtailor' ||
                type === 'recruitee' ||
                type === 'ashby') && (
                <div className={styles.integrationHelper}>
                  <div>
                    <h4>{t('integrations.helper.header')}</h4>
                    <p>
                      {t('integrations.helper.description', {
                        ATS: ATS_NAMES_BY_TYPE[type],
                      })}
                    </p>
                  </div>
                  <div className={styles.integrationHelperInstructions}>
                    <GenericModal
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}
                      open={open}
                      trigger={
                        <GenericButton>
                          {t('integrations.helper.instructionsBtn')}
                        </GenericButton>
                      }
                    >
                      <Modal.Header>
                        {t('integrations.helper.modal.header', {
                          ATS: ATS_NAMES_BY_TYPE[type],
                        })}
                      </Modal.Header>
                      <Modal.Content>
                        <IntegrationInstructions type={type} />
                      </Modal.Content>
                      <Modal.Actions>
                        <GenericButton
                          size='big'
                          onClick={() => setOpen(false)}
                        >
                          {t('common.close')}
                        </GenericButton>
                      </Modal.Actions>
                    </GenericModal>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className='input-container'>
                <div className='input-label'>
                  {t('integrations.ATSConnectedDescription')}
                </div>
              </div>
              <IntegrationSynchronizationSettings connector={connector} />
            </>
          )}
        </div>
        {!!connector && (
          <RevealIntegrationLinkedUser type={type} clientId={clientId} />
        )}
      </div>
      {!!connector && (
        <MissionWorkflowsProvider
          mode={WORKFLOWS_CONTEXT_MODE.CLIENT_DEFAULT_WORKFLOWS}
        >
          <MissionWorkflows />
        </MissionWorkflowsProvider>
      )}
    </>
  );
};

interface CredentialFormProps {
  type: string;
  mode: 'oauth' | 'token';
}

const CredentialForm: React.FC<CredentialFormProps> = ({ type, mode }) => {
  if (_.includes(['workable', 'recruitee'], type)) {
    return <TokenAndAccountIdCredentialsForm reveal type={type} />;
  }
  // if (type === 'workable') {
  //   return <BasicCredentialsForm type={type} mode='token' reveal />;
  // }

  return <BasicCredentialsForm type={type} mode={mode} reveal />;
};
export default RevealIntegration;
