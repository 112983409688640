import classnames from 'classnames';
import React from 'react';

import styles from './GenericText.module.less';

interface GenericTextProps {
  text: string;
  size?: 'small' | 'medium' | 'large';
  color?: 'cobalt' | 'blue' | 'cobalt60';
}

const GenericText: React.FC<GenericTextProps> = ({
  text,
  size = 'medium',
  color = 'cobalt60',
}) => {
  switch (size) {
    case 'medium':
      return (
        <p
          className={classnames(
            styles.genericText,
            styles[color],
            styles.medium,
          )}
        >
          {text}
        </p>
      );
    default:
      return (
        <p
          className={classnames(
            styles.genericText,
            styles[color],
            styles.small,
          )}
        >
          {text}
        </p>
      );
  }
};

export default GenericText;
