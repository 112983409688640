import React from 'react';

import { useMergeTagsPlayground } from '../context/MergeTagsPlaygroundProvider';
import MergeTagsJsonContent from './MergeTagsJsonContent';

const MergeTagsContext: React.FC = () => {
  const { context, setContext } = useMergeTagsPlayground();

  return (
    <MergeTagsJsonContent
      content={context}
      onChange={(newContent) => setContext(newContent)}
    />
  );
};

export default MergeTagsContext;
