import React from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Label, Popup } from 'semantic-ui-react';

import { getEnrichedText, getTranslatedTextWithEnrichments } from '@/common';
import withActionLogger from '@/hocs/withActionLogger';
import TranslatableText from '@/components/TranslatableText';
import CollapsibleEnrichedText from '@/components/CollapsibleEnrichedText';
import { getFormattedStack, Stack } from '@/components/Stack';
import Source from '@/components/Sources';

import './WorkplacePopup.css';

const WorkplacePopup = (props) => {
  const { workplace, titleSecondPart, t, profileId, onLogAction } = props;
  if (!workplace || !workplace.data || !workplace.data.name) {
    return '';
  }
  const workplaceData = workplace.data || {};
  let workplaceType = '';
  if (workplaceData.types && workplaceData.types.length > 0) {
    workplaceType = workplaceData.types[0].name; // TODO Choose a type or display all ?
  }
  const { min: minEmployees, max: maxEmployees } =
    workplaceData.staffCount || {};
  const mainLocation = (workplaceData.mainLocation || {}).name;
  const formattedMainLocation = mainLocation
    ? capitalizeFirstLetter(mainLocation)
    : '';

  const formattedStack = getFormattedStack(workplaceData.stack);
  const description = getEnrichedText(
    getTranslatedTextWithEnrichments(workplaceData.description),
  );
  const website =
    (titleSecondPart || {}).link ||
    (_.findWhere((workplaceData || {}).sources, { sourceId: 'website' }) || {})
      .url;

  const sourcesOrder = {
    crunchbase: 1,
    linkedin: 2,
    wttj: 3,
    website: 4,
  };
  const workplaceSources =
    _.sortBy(
      _.filter(workplaceData.sources, (src) =>
        _.contains(
          ['linkedin', 'wttj', 'website', 'crunchbase'],
          (src || {}).sourceId,
        ),
      ),
      (src) => sourcesOrder[src.sourceId],
    ) || [];

  // if the website is in titleSecondPart and not in sources, add it back in sources
  if (
    website &&
    !(_.findWhere(workplaceSources, { sourceId: 'website' }) || {}).url
  ) {
    workplaceSources.push({ sourceId: 'website', url: website });
  }
  const logExpand = (collapsed) => {
    if (!collapsed && onLogAction) {
      onLogAction({
        type: 'expand-workplace-description',
        profileId,
        info: {
          workplaceId: workplace.id,
        },
      });
    }
  };
  const logClinkWorkplaceLink = () => {
    onLogAction({
      type: 'click-workplace-link',
      profileId,
      info: {
        workplaceId: workplace.id,
      },
    });
  };
  const logWorkplacePopupAction = (type) => {
    onLogAction({
      type,
      profileId,
      info: {
        workplaceId: workplace.id,
      },
    });
  };

  return (
    <Popup
      className='workplace-popup'
      basic
      hoverable
      position='bottom center'
      wide
      onOpen={() => logWorkplacePopupAction('open-workplace-popup')}
      onClose={() => logWorkplacePopupAction('close-workplace-popup')}
      trigger={
        <span className='workplace-popup-trigger'>
          {website ? (
            <a
              href={website}
              target='_blank'
              rel='noopener noreferrer'
              className='card-link'
              onClick={logClinkWorkplaceLink}
            >
              {workplaceData.name}
            </a>
          ) : (
            workplaceData.name
          )}
        </span>
      }
    >
      <div className='popup-first-line'>
        <div className='workplace-name'>{workplaceData.name}</div>
        {workplaceType && (
          <div className='workplace-type'>
            • <TranslatableText text={workplaceType} />
          </div>
        )}
        <div className='popup-first-line-spacer' />
        {workplaceSources && (
          <div className='sources-container'>
            {_.map(workplaceSources, (source) => (
              <Source
                key={source.sourceId}
                source={source}
                onLogAction={onLogAction}
                profileId={profileId}
                t={t}
                type='workplace'
              />
            ))}
          </div>
        )}
      </div>

      <div className='popup-subtitle body-regular'>
        <span>
          {workplaceData.foundedYear && (
            <>
              {t('profile.resume.workplace.foundedIn')}{' '}
              {workplaceData.foundedYear}{' '}
            </>
          )}
          {workplaceData.foundedYear && formattedMainLocation && <> • </>}
          {formattedMainLocation && (
            <TranslatableText text={formattedMainLocation} />
          )}
        </span>
        {_.isNumber(minEmployees) && _.isNumber(maxEmployees) && (
          <div className='workplace-staff-count'>
            {minEmployees !== maxEmployees
              ? t('profile.resume.workplace.employeesCountMinMax', {
                  min: minEmployees,
                  max: maxEmployees,
                })
              : t('profile.resume.workplace.employeesCount', {
                  employeesCount: minEmployees,
                })}
          </div>
        )}
      </div>
      <div className='workplace-industries'>
        {_.map(
          workplaceData.industries,
          (industry) =>
            industry &&
            industry.name &&
            industry.name.default && (
              <Label key={industry.name.default} className='industry-label'>
                <TranslatableText text={industry.name} />
              </Label>
            ),
        )}
      </div>
      {formattedStack && formattedStack.length > 0 && (
        <div className='workplace-stack'>
          <span className='caption-medium'>Stack:</span>{' '}
          <Stack
            stack={workplaceData.stack}
            displaySoftSkills={false}
            profileId={profileId}
            context='workplace'
          />
        </div>
      )}
      <p className='workplace-description body-regular'>
        <CollapsibleEnrichedText
          t={t}
          charCost={1}
          lineCost={90}
          targetMaxCost={200}
          toleranceFactor={0.3}
          enrichedText={description}
          expandOnly
          onToggleCollapse={logExpand}
        />
      </p>
    </Popup>
  );
};

const capitalizeFirstLetter = (translatableText) => {
  const capitalizedTranslateableText = {};
  _.each(translatableText, (value, key) => {
    capitalizedTranslateableText[key] =
      value.charAt(0).toUpperCase() + value.slice(1);
  });
  return capitalizedTranslateableText;
};

export default compose(
  withActionLogger,
  withTranslation('translations'),
)(WorkplacePopup);
