import _ from 'underscore';
import React, { ReactNode } from 'react';
import { TFunction } from 'i18next';

import { MiniSequence } from '@/types/sequence';
import {
  ClientDailyCohort,
  DisplayedTaskType,
  displayedTaskTypes,
  isPrefixTaskType,
  isSequenceTaskType,
  PrefixTaskType,
  SequenceStatistic,
  SequenceTaskType,
} from '@/types/statistics/clientStatistics';
import { sumNumberRecords } from '@/common/helpers/stats';
import { TimeSpan } from '../../../components/RevealAnalyticsHeader/RevealAnalyticsHeader';
import { filterEventsByDateRange } from '../../../stats.helpers';
import { safeDivide } from '../../../utils';

export const getEmptySequenceStats = (): Record<SequenceTaskType, number> => ({
  nbEnrolled: 0,
  nbContacted: 0,
  nbSentWithEmailOpenTracking: 0,
  nbSentWithLinkClickTracking: 0,
  nbOpened: 0,
  nbClicked: 0,
  nbAnswered: 0,
  nbPositiveAnswered: 0,
});

export const getEmptyPrefixStats = (): Record<PrefixTaskType, number> => ({
  nbContacted: 0,
  nbAnswered: 0,
  nbPositiveAnswered: 0,
});

export const getSequencesStats = (
  timeSpan: TimeSpan,
  dailyCohorts?: ClientDailyCohort[],
  miniSequences?: MiniSequence[],
): SequenceStatistic[] => {
  const cohortsInRange = filterEventsByDateRange(timeSpan, dailyCohorts || []);

  const sequencesStatsMap: Record<string, SequenceStatistic> = {};

  _.each(miniSequences || [], ({ id, title }) => {
    sequencesStatsMap[id] = {
      id,
      title,
      statistics: getEmptySequenceStats(),
      prefixStatistics: {
        code: '/',
        counts: getEmptyPrefixStats(),
        children: [],
      },
    };
  });

  _.each(cohortsInRange, ({ details }) => {
    _.each(details, ({ type, count, sequenceId }) => {
      const sequencesStat = sequencesStatsMap[sequenceId];
      if (!sequencesStat) {
        return;
      }
      if (isSequenceTaskType(type)) {
        sequencesStat.statistics[type] += count;
        return;
      }
      const [realType, ...prefixElements] = type.split('_');
      if (isPrefixTaskType(realType)) {
        let { prefixStatistics } = sequencesStat;
        _.each(prefixElements, (code) => {
          let child = _.findWhere(prefixStatistics.children, { code });
          if (!child) {
            child = {
              code,
              counts: getEmptyPrefixStats(),
              children: [],
            };
            prefixStatistics.children.push(child);
          }
          prefixStatistics = child;
        });
        prefixStatistics.counts[realType] += count;
      }
    });
  });

  return _.values(sequencesStatsMap);
};

export const aggregateSequenceStats = (
  sequencesStats: SequenceStatistic[],
): Record<SequenceTaskType, number> => {
  return _.reduce(
    sequencesStats,
    (memo, { statistics }) => sumNumberRecords(memo, statistics),
    getEmptySequenceStats(),
  );
};

export type KPIItem = {
  id: string;
  text: string;
  value: number;
  referenceValue?: number;
  rate?: number;
  isRate?: boolean;
  tooltipContent?: ReactNode;
  color?: string;
  onClick?: () => void;
};

export const getKPIItems = ({
  stats,
  t,
  clickListeners,
}: {
  stats: Record<SequenceTaskType, number>;
  t: TFunction;
  clickListeners?: Record<DisplayedTaskType, () => void>;
}): KPIItem[] => {
  const {
    nbEnrolled,
    nbContacted,
    nbSentWithEmailOpenTracking,
    nbSentWithLinkClickTracking,
    nbOpened,
    nbClicked,
    nbAnswered,
    nbPositiveAnswered,
  } = stats || {};

  return [
    {
      id: 'nbEnrolled',
      text: t('reveal.analyticsView.sequences.enrolled'),
      value: nbEnrolled,
      color: 'grey',
      tooltipContent: (
        <>
          <h1>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.enrolled.title',
            )}
          </h1>
          <h2>{t('reveal.reports.tooltips.common.surprised')}</h2>
          <p>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.enrolled.surprisedParagraph',
            )}
          </p>
        </>
      ),
      onClick: clickListeners?.nbEnrolled,
    },
    {
      id: 'nbContacted',
      text: t('reveal.analyticsView.sequences.contacted'),
      value: nbContacted,
      color: 'cobalt',
      tooltipContent: (
        <>
          <h1>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.contacted.title',
            )}
          </h1>
          <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
          <p>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.contacted.explanationsParagraph',
            )}
          </p>
        </>
      ),
      onClick: clickListeners?.nbContacted,
    },
    {
      id: 'nbOpened',
      text: t('reveal.analyticsView.sequences.opened'),
      value: nbOpened,
      referenceValue: nbSentWithEmailOpenTracking,
      rate: safeDivide(nbOpened, nbSentWithEmailOpenTracking) || 0,
      isRate: true,
      color: 'skyblue',
      tooltipContent: (
        <>
          <h1>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.opened.title',
            )}
          </h1>
          <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
          <p>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.opened.countParagraph',
            )}
          </p>
          <p>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.opened.percentageParagraph',
            )}
          </p>
          <h2>{t('reveal.reports.tooltips.common.surprised')}</h2>
          <p>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.opened.blockersParagraph',
            )}
          </p>
          <p>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.opened.settingsParagraph',
            )}
          </p>
        </>
      ),
      onClick: clickListeners?.nbOpened,
    },
    {
      id: 'nbClicked',
      text: t('reveal.analyticsView.sequences.clicked'),
      value: nbClicked,
      referenceValue: nbSentWithLinkClickTracking,
      rate: safeDivide(nbClicked, nbSentWithLinkClickTracking) || 0,
      isRate: true,
      color: 'blue',
      tooltipContent: (
        <>
          <h1>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.clicked.title',
            )}
          </h1>
          <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
          <p>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.clicked.countParagraph',
            )}
          </p>
          <p>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.clicked.percentageParagraph',
            )}
          </p>
          <h2>{t('reveal.reports.tooltips.common.surprised')}</h2>
          <p>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.clicked.blockersParagraph',
            )}
          </p>
          <p>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.clicked.settingsParagraph',
            )}
          </p>
        </>
      ),
      onClick: clickListeners?.nbClicked,
    },
    {
      id: 'nbAnswered',
      text: t('reveal.analyticsView.sequences.answered'),
      value: nbAnswered,
      referenceValue: nbContacted,
      rate: safeDivide(nbAnswered, nbContacted) || 0,
      isRate: true,
      color: 'lightgreen',
      tooltipContent: (
        <>
          <h1>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.replied.title',
            )}
          </h1>
          <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
          <p>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.replied.sourceParagraph',
            )}
          </p>
          <p>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.replied.stagesParagraph',
            )}
          </p>
          <p>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.replied.percentageParagraph',
            )}
          </p>
        </>
      ),
      onClick: clickListeners?.nbAnswered,
    },
    {
      id: 'nbPositiveAnswered',
      text: t('reveal.analyticsView.sequences.positiveAnswered'),
      value: nbPositiveAnswered,
      referenceValue: nbContacted,
      rate: safeDivide(nbPositiveAnswered, nbContacted) || 0,
      isRate: true,
      color: 'green',
      tooltipContent: (
        <>
          <h1>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.interested.title',
            )}
          </h1>
          <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
          <p>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.interested.sourceParagraph',
            )}
          </p>
          <p>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.interested.stagesParagraph',
            )}
          </p>
          <p>
            {t(
              'reveal.reports.tooltips.sequences.kpis.categories.interested.percentageParagraph',
            )}
          </p>
        </>
      ),
      onClick: clickListeners?.nbPositiveAnswered,
    },
  ];
};

export const getKpisToDisplay = (
  t: TFunction,
  sequencesStats: SequenceStatistic[],
  onKpiClick: (taskType: DisplayedTaskType) => void,
): KPIItem[] => {
  const clickListeners = _.reduce(
    displayedTaskTypes,
    (aggregator, taskType) => ({
      ...aggregator,
      [taskType]: () => onKpiClick(taskType),
    }),
    {} as Record<DisplayedTaskType, () => void>,
  );

  const aggregatedStats = aggregateSequenceStats(sequencesStats);
  return getKPIItems({ stats: aggregatedStats, t, clickListeners });
};

export default {};
