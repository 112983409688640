export const MS_GRAPH_ENDPOINTS = {
  ME: 'https://graph.microsoft.com/v1.0/me',
  MAIL: 'https://graph.microsoft.com/v1.0/me/messages',
};

export const MS_SCOPES = [
  'user.read',
  'mail.readwrite',
  'mail.send',
  'openid',
  'profile',
  'offline_access',
];
