import React, { FC, ReactNode } from 'react';
import _ from 'underscore';
import classNames from 'classnames';

import DropdownMenuItem from '../DropdownMenuItem';
import DropdownPanel from '../DropdownPanel';

import styles from './DropdownMenuPanel.module.less';

export interface DropdownMenuOption {
  label: ReactNode;
  id: string;
  disabled?: boolean;
}

interface DropdownMenuPanelProps {
  options: DropdownMenuOption[];
  onSelect: (id: string) => void;
  className?: string;
}

const DropdownMenuPanel: FC<DropdownMenuPanelProps> = ({
  options,
  onSelect,
  className,
}) => (
  <DropdownPanel className={classNames(styles.menu, className)}>
    {_.map(options, ({ label, id, disabled }) => (
      <DropdownMenuItem
        key={id}
        onClick={() => onSelect(id)}
        disabled={disabled}
      >
        {label}
      </DropdownMenuItem>
    ))}
  </DropdownPanel>
);

export default DropdownMenuPanel;
