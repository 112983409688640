import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const UPDATE_EMAIL = gql`
  mutation updateEmail(
    $searchPoolId: ID!
    $input: UpdateSearchPoolProfileEmailInput!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      updateEmail(input: $input) {
        profile {
          id
          email
          resumeData {
            email
          }
        }
      }
    }
  }
`;

export default graphql(UPDATE_EMAIL, {
  props: ({ mutate }) => ({
    updateEmail: ({ searchPoolId, input }) =>
      mutate({
        variables: { searchPoolId, input },
      }),
  }),
});
