import { QueryHookOptions, QueryResult, useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

import useClientId from '@/hooks/router/useClientId';

const GET_CLIENT_MISSION_DAILY_EVENTS_DETAILS = gql`
  query getClientMissionsStatistics($clientId: ID!, $missionIds: [ID!]) {
    client(id: $clientId) {
      id
      statistics {
        missions(missionIds: $missionIds) {
          dailyEvents {
            day
            details {
              missionId
              type
              profileItems {
                profileId
              }
              removalReason
            }
          }
        }
      }
    }
  }
`;

interface MissionsDailyEventDetails {
  day: string;
  details: DailyEventDetail[];
}

type DailyEventDetail =
  | {
      type: 'nbSourced';
      missionId: string;
      profileItems: { profileId: string }[];
    }
  | {
      type: 'nbRemoved';
      missionId: string;
      profileItems: { profileId: string }[];
      removalReason?: string;
    };

interface GetClientMissionsDailyEventsDetailsData {
  client: {
    id: string;
    statistics: {
      missions: {
        dailyEvents: MissionsDailyEventDetails[];
      };
    };
  };
}

interface GetClientMissionsDailyEventsDetailsVariables {
  clientId: string;
  missionIds?: string[];
}

interface UseClientSequencesMissionsDailyEventsDetailsInput {
  queryOptions?: QueryHookOptions<
    GetClientMissionsDailyEventsDetailsData,
    GetClientMissionsDailyEventsDetailsVariables
  >;
}

type UsePipelineStatisticsByCategoryDetailsResult = [
  (variables?: { missionIds?: string[] }) => void,
  QueryResult<
    GetClientMissionsDailyEventsDetailsData,
    GetClientMissionsDailyEventsDetailsVariables
  > & {
    dailyEvents?: MissionsDailyEventDetails[];
  },
];

const useClientMissionsDailyEventsDetails = (
  input?: UseClientSequencesMissionsDailyEventsDetailsInput,
): UsePipelineStatisticsByCategoryDetailsResult => {
  const clientId = useClientId();
  const { queryOptions } = input || {};

  const [fetchDetails, query] = useLazyQuery<
    GetClientMissionsDailyEventsDetailsData,
    GetClientMissionsDailyEventsDetailsVariables
  >(GET_CLIENT_MISSION_DAILY_EVENTS_DETAILS, {
    ...(queryOptions || {}),
  });

  return [
    ({ missionIds } = {}) =>
      fetchDetails({ variables: { clientId, missionIds } }),
    {
      ...query,
      dailyEvents: query?.data?.client?.statistics?.missions?.dailyEvents,
    },
  ];
};

export default useClientMissionsDailyEventsDetails;
