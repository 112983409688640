import React from 'react';
import { useTranslation } from 'react-i18next';

const Step1: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className='step'>
      <h1 className='step-title'>{t('reveal.onboardingModal.step1.title')}</h1>
      <div className='list'>
        <ListItem
          index='1'
          title={t('reveal.onboardingModal.step1.list.missions.title')}
          description={t('reveal.onboardingModal.step1.list.missions.text')}
        />
        <ListItem
          index='2'
          title={t('reveal.onboardingModal.step1.list.contacts.title')}
          description={t('reveal.onboardingModal.step1.list.contacts.text')}
        />
        <ListItem
          index='3'
          title={t('reveal.onboardingModal.step1.list.sequences.title')}
          description={t('reveal.onboardingModal.step1.list.sequences.text')}
        />
      </div>
    </div>
  );
};

interface ListItemProps {
  index: string;
  title: string;
  description: string;
}
const ListItem = ({ index, title, description }: ListItemProps) => (
  <div className='list-item'>
    <div className='number'>{index}</div>
    <div className='content'>
      <div className='title'>{title}</div>
      <div className='description'>{description}</div>
    </div>
  </div>
);

export default Step1;
