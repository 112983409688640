import React, { FC, Fragment, useMemo } from 'react';
import _ from 'underscore';

import { StackItem } from '@/types/statistics/stackItem';
import { Stats } from '@/types/statistics/stats';
import {
  filterStack,
  getGlobalMaxCount,
  getMaxCount,
  getTicks,
} from '@/common/helpers/stats';
import TooltipBoundary from '@/components/Common/TooltipBoundary';
import Legend from '../Legend';
import ParallelBars from '../ParallelBars';
import GraphEmptyState from '../GraphEmptyState';

import styles from './HorizontalParallelBarChart.module.less';

interface HorizontalParallelBarChartProps {
  stack: StackItem[];
  allStats: Stats[];
  showPercentage?: boolean;
  exhaustive?: boolean;
}

const HorizontalParallelBarChart: FC<HorizontalParallelBarChartProps> = ({
  stack,
  allStats,
  showPercentage,
  exhaustive,
}) => {
  const maxCount = useMemo(() => getGlobalMaxCount(allStats), [allStats]);
  const filteredStack = useMemo(
    () => (exhaustive ? stack : filterStack(stack, allStats)),
    [stack, allStats, exhaustive],
  );
  const ticks = useMemo(() => getTicks(maxCount), [maxCount]);
  const sortedStats = useMemo(
    () =>
      _.chain(allStats)
        .sortBy(getMaxCount)
        .reverse()
        .value(),
    [allStats],
  );

  if (_.isEmpty(sortedStats)) {
    return <GraphEmptyState />;
  }

  return (
    <>
      <Legend stack={filteredStack} />
      <TooltipBoundary>
        <div className={styles.graph}>
          {_.map(sortedStats, (stats, index) => (
            <Fragment key={index}>
              <div className={styles.label}>{stats.name}</div>
              <ParallelBars
                stack={filteredStack}
                stats={stats}
                topBoundary={_.last(ticks) || maxCount}
                showPercentage={showPercentage}
              />
            </Fragment>
          ))}
          <div className={styles.scale}>
            {_.map(ticks, (tick) => (
              <span className={styles.tick} key={tick}>
                {tick}
              </span>
            ))}
          </div>
        </div>
      </TooltipBoundary>
    </>
  );
};

export default HorizontalParallelBarChart;
