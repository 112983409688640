import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { Button, Dropdown, Modal, Image } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import contextToProps from '../../../hocs/contextToProps';

import withClientActiveOffers from '../../../hocs/offers/withClientActiveOffers';
import withAddMultipleSearchProfilesToJob from '../../../hocs/searchPool/withAddMultipleSearchProfilesToJob';
import withAddSearchProfileToJob from '../../../hocs/searchPool/withAddSearchProfileToJob';

import './AddToJobMultipleModal.css';

const AddToJobMultipleModal = ({
  profileIds,
  open,
  onClose,
  activeOffers,
  onAddSelectionToJob,
  onClickAddToNewJobButton,
  onShowNotification,
  addMultipleSearchProfilesToJob,
  t,
}) => {
  const [selectedJobId, setSelectedJobId] = useState(null);

  const dropdownOptions = _.map(activeOffers, ({ id, title }) => ({
    value: id,
    text: title,
  }));
  dropdownOptions.push({
    value: 'new-job',
    text: t('watchCollect.addToNewJobModal.trigger'),
    content: (
      <Button
        className='add-to-new-job-button primary-cta'
        onClick={onClickAddToNewJobButton}
      >
        <Image src='/images/icons/figma/new-cross-white.svg' />
        {t('watchCollect.addToNewJobModal.trigger')}
      </Button>
    ),
  });

  const handleAddSelectionToJob = async () => {
    onAddSelectionToJob();
    try {
      const { data } = await addMultipleSearchProfilesToJob({
        ids: profileIds,
        jobOfferId: selectedJobId,
      });
      const results = data?.addMultiplePoolProfilesToOffer;

      const errorsCount = results?.length - _.compact(results).length;
      if (errorsCount > 0) {
        onShowNotification({
          message: t('watchCollect.results.addSelectionToJobModal.warning', {
            count: errorsCount,
          }),
          level: 'error', // TODO: add warning level
        });
      } else {
        onShowNotification({
          message: t('watchCollect.results.addSelectionToJobModal.success'),
          level: 'success',
        });
      }
    } catch (e) {
      console.error(e);
      onShowNotification({
        message: t('watchCollect.results.addSelectionToJobModal.error'),
        level: 'error',
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      size='small'
      className='add-to-job-multiple-modal'
      closeIcon
    >
      <Modal.Header>
        {t('watchCollect.results.addSelectionToJobModal.title', {
          count: profileIds.length,
        })}
      </Modal.Header>
      <Modal.Content>
        {_.isEmpty(dropdownOptions) ? (
          <div>
            {t('watchCollect.results.addSelectionToJobModal.noActiveOffers')}
          </div>
        ) : (
          <>
            <div className='modal-content'>
              {t('watchCollect.results.addSelectionToJobModal.content')}
            </div>
            <div className='inputs-row'>
              <div className='input-container half-width'>
                <div className='input-label'>
                  {t(
                    'watchCollect.results.addSelectionToJobModal.jobDropdownLabel',
                  )}
                </div>
                <div className='input-element'>
                  <Dropdown
                    className='job-dropdown'
                    selection
                    placeholder={t(
                      'watchCollect.results.addSelectionToJobModal.jobDropdownPlaceholder',
                    )}
                    options={dropdownOptions}
                    value={selectedJobId}
                    onChange={(e, { value }) => setSelectedJobId(value)}
                    fluid
                  />
                </div>
              </div>
              {/* <div className='input-container half-width' /> */}
            </div>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <div className='align-left'>
          <Button
            content={t('common.cancel')}
            onClick={onClose}
            className='dismiss'
          />
        </div>
        <Button
          content={t('watchCollect.results.addSelectionToJobModal.confirm')}
          onClick={handleAddSelectionToJob}
          disabled={!selectedJobId}
          primary
          size='big'
        />
      </Modal.Actions>
    </Modal>
  );
};

export default compose(
  contextToProps,
  withAddMultipleSearchProfilesToJob,
  withClientActiveOffers,
  withAddSearchProfileToJob,
  withTranslation('translations'),
)(AddToJobMultipleModal);
