import React from 'react';

import './GmailAliasPreview.css';

const GmailAliasPreview = ({ alias, placeholder }) => {
  return (
    <div className='gmail-alias-preview'>
      <i className='square-outline'/>
      <i className='star-outline'/>
      <i className='important-arrow'/>
      {alias && alias.trim() ? (
        <span className='alias'>{alias}</span>
      ) : (
        <span className='alias placeholder'>{placeholder}</span>
      )}
    </div>
  );
};

export default GmailAliasPreview;
