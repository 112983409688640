import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import useClientId from '@/hooks/router/useClientId';
import ConnectPagesSettings from '@/containers/Parameters/ConnectFormsAndPages/ConnectPages';
import ConnectFormsSettings from '@/containers/Parameters/ConnectFormsAndPages/ConnectForms';
import SubHeader from './SubHeader/SubHeader';
import { MarketingTab } from './types';

interface CampaignsViewProps {
  tab: MarketingTab;
}

const CampaignsView: React.FC<CampaignsViewProps> = ({ tab }) => {
  const clientId = useClientId();
  const [newFormModalOpen, setNewFormModalOpen] = useState(false);
  const [newPageModalOpen, setNewPageModalOpen] = useState(false);
  const { isExact } = useRouteMatch();

  return (
    <>
      {isExact && (
        <SubHeader
          tab={tab}
          clientId={clientId}
          setNewFormModalOpen={setNewFormModalOpen}
          setNewPageModalOpen={setNewPageModalOpen}
        />
      )}
      {tab === 'pages' && (
        <ConnectPagesSettings
          clientId={clientId}
          setNewPageModalOpen={setNewPageModalOpen}
          newPageModalOpen={newPageModalOpen}
        />
      )}
      {tab === 'forms' && (
        <ConnectFormsSettings
          clientId={clientId}
          newFormModalOpen={newFormModalOpen}
          setNewFormModalOpen={setNewFormModalOpen}
        />
      )}
    </>
  );
};

export default CampaignsView;
