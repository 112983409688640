import React, { useState } from 'react';
import _ from 'underscore';
import { Table } from 'semantic-ui-react';
import { ConnectPage } from '@/types/connectPage';

import { getComparisonFunction } from '@/containers/Parameters/Campaigns/CampaignsList';
import styles from './ConnectPagesTable.module.less';
import ConnectPagesTableHeader from './ConnectPagesTableHeader';
import ConnectPagesTableRow from './ConnectPagesTableRow';

interface ConnectPagesTableProps {
  connectPages: ConnectPage[];
  withEdit: boolean;
  onEditConnectPage: ({ connectPageId }: { connectPageId: string }) => void;
  onDeleteConnectPage: ({ connectPageId }: { connectPageId: string }) => void;
  onShowQRCode: ({ connectPageId }: { connectPageId: string }) => void;
}
const ConnectPagesTable: React.FC<ConnectPagesTableProps> = ({
  connectPages,
  withEdit,
  onEditConnectPage,
  onDeleteConnectPage,
  onShowQRCode,
}) => {
  const [sort, setSort] = useState({ key: 'none', order: 1 });

  const { key: sortKey, order: sortOrder } = sort;

  if (_.isEmpty(connectPages)) {
    return null;
  }

  const handleToggleSort = ({ key }: { key: string }) => {
    if (sortKey === key) {
      setSort({ key, order: -sortOrder });
    } else {
      setSort({ key, order: 1 });
    }
  };

  const increasingSortedForms = _.sortBy(
    connectPages,
    getComparisonFunction({ sortKey }),
  );
  const sortedPages =
    sortOrder < 0 ? increasingSortedForms.reverse() : increasingSortedForms;

  if (_.isEmpty(connectPages)) {
    return null;
  }

  return (
    <div className={styles.connectPagesList}>
      <Table basic className={styles.connectPagesSettingsTable}>
        <ConnectPagesTableHeader
          withEdit={withEdit}
          sortKey={sortKey}
          handleToggleSort={handleToggleSort}
          sortOrder={sortOrder}
        />
        <Table.Body>
          {_.map(sortedPages, (connectPage) => (
            <ConnectPagesTableRow
              key={connectPage.id}
              connectPage={connectPage}
              withEdit={withEdit}
              onEditConnectPage={onEditConnectPage}
              onDeleteConnectPage={onDeleteConnectPage}
              onShowQRCode={onShowQRCode}
            />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default ConnectPagesTable;
