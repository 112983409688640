import {
  useQuery,
  QueryResult,
  useMutation,
  MutationTuple,
} from '@apollo/client';
import gql from 'graphql-tag';
import { GET_CLIENT_PRIVACY_SETTINGS } from '@/graphql/clients';
import { PrivacySettings, LegalEntity } from '@/types/client';

export type ResultProfilePrivacySettings = {
  client: {
    privacySettings: PrivacySettings;
  };
};

export function useClientPrivacySettings(
  clientId: string,
): QueryResult<ResultProfilePrivacySettings, { clientId: string }> & {
  privacySettings: PrivacySettings;
} {
  const query = useQuery<ResultProfilePrivacySettings, { clientId: string }>(
    GET_CLIENT_PRIVACY_SETTINGS,
    { variables: { clientId } },
  );

  const { privacySettings } = query.data?.client || {};

  return {
    ...query,
    privacySettings: {
      dpo: {
        email: privacySettings?.dpo?.email || '',
        fullname: privacySettings?.dpo?.fullname || '',
        address: privacySettings?.dpo?.address || '',
        phoneNumber: privacySettings?.dpo?.phoneNumber || '',
      },
      euRepresentative: {
        email: privacySettings?.euRepresentative?.email || '',
        fullname: privacySettings?.euRepresentative?.fullname || '',
        address: privacySettings?.euRepresentative?.address || '',
        phoneNumber: privacySettings?.euRepresentative?.phoneNumber || '',
      },
    },
  };
}

export default useClientPrivacySettings;

const updateClientPrivacySettings = gql`
  mutation Mutation($input: UpdateClientPrivacySettingsInput!) {
    updateClientPrivacySettings(input: $input) {
      privacySettings {
        dpo {
          email
          fullname
          address
          phoneNumber
        }
        euRepresentative {
          email
          fullname
          address
          phoneNumber
        }
      }
    }
  }
`;

export interface PrivacySettingsInput {
  dpo?: LegalEntity;
  euRepresentative?: LegalEntity;
}

export function useClientPrivacySettingsMutation(): MutationTuple<
  {
    updateClientPrivacySettings: {
      privacySettings: PrivacySettings;
    };
  },
  { input: PrivacySettingsInput }
> {
  return useMutation(updateClientPrivacySettings, {
    refetchQueries: ['getClientPrivacySettings'],
  });
}
