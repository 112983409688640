import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

const GET_OFFER_STEP_PROFILES = gql`
  query getOfferStepProfiles($offerId: ID!, $step: String!) {
    offer(id: $offerId) {
      id
      title
      profiles(step: $step) {
        id
        email
        isActive
        resumeData {
          step
          subStep
          firstname
          lastname
          photoLink
          sourceData {
            hiresweet {
              clientInterestStates {
                clientId
              }
            }
          }
          prequalification {
            type
          }
        }
        annotation {
          globalFavorite {
            value
          }
        }
        source {
          type
          createdFromDiscover
          matchingScore
        }
      }
    }
  }
`;

export interface StepProfile {
  id: string;
  email: string;
  isActive: boolean;
  resumeData: {
    step: string;
    subStep?: string;
    firstname?: string;
    lastname?: string;
    photoLink?: string;
    sourceData?: {
      hiresweet?: {
        clientInterestStates?: {
          clientId: string;
        }[];
      };
    };
    prequalification?: {
      type?: string;
    };
  };
  source?: {
    type?: string;
    createdFromDiscover?: boolean;
    matchingScore?: number;
  };
  annotation?: {
    globalFavorite?: {
      value: boolean;
    };
  };
}

interface GetOfferStepProfilesData {
  offer: {
    id: string;
    title: string;
    profiles: StepProfile[];
  };
}

interface GetOfferStepProfilesVariables {
  offerId: string;
  step?: string;
}

interface UseOfferStepProfilesResult
  extends QueryResult<GetOfferStepProfilesData, GetOfferStepProfilesVariables> {
  stepProfiles: StepProfile[];
}

interface UseOfferStepProfilesInput extends GetOfferStepProfilesVariables {
  queryOptions?: QueryHookOptions<
    GetOfferStepProfilesData,
    GetOfferStepProfilesVariables
  >;
}

const useOfferStepProfiles = ({
  offerId,
  step = '',
  queryOptions = {},
}: UseOfferStepProfilesInput): UseOfferStepProfilesResult => {
  const query = useQuery<
    GetOfferStepProfilesData,
    GetOfferStepProfilesVariables
  >(GET_OFFER_STEP_PROFILES, {
    ...queryOptions,
    variables: {
      offerId,
      step,
    },
  });

  const stepProfiles = query.data?.offer.profiles || [];

  return { ...query, stepProfiles };
};

export default useOfferStepProfiles;
