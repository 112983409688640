import { BallChain } from '@hiresweet/hiresweet-lib';
import React from 'react';

const PlaygroundT5 = () => {
  return (
    <div style={{ padding: '30px', background: 'white' }}>
      <BallChain
        nodes={[
          { id: '1', label: 'A examiner', value: '16' },
          { id: '2', label: 'Interessés', value: '5' },
          { id: '3', label: 'Proposés', value: '0' },
          { id: '4', label: 'Retenus', value: '4' },
          {
            id: '5',
            label: 'Placéfesfsefsefsfsefsefsfesfsfsfsefs',
            value: '0',
          },
        ]}
        isMuted={({ value }: { value: string }) => value === '0'}
      />
    </div>
  );
};

export default PlaygroundT5;
