import _ from 'underscore';
import React from 'react';

import { Profile } from '@/types/profile';
import { getRelevantPowerHourForProfile } from '@/common/helpers/powerHours';
import usePowerHours from '@/graphql/hooks/powerHours/usePowerHours';

const ProfilePowerHourFlag = ({
  profile,
  children,
}: {
  profile: Profile | undefined;
  children: React.ReactNode;
}) => {
  const { powerHours } = usePowerHours();
  const relevantPowerHour = getRelevantPowerHourForProfile(powerHours, profile);

  if (!relevantPowerHour) {
    return null;
  }

  return children;
};

export default ProfilePowerHourFlag;
