import { graphql } from '@apollo/client/react/hoc';
import { GET_CLIENT_TEMPLATES } from '@/graphql/clientTemplates';

export default graphql(GET_CLIENT_TEMPLATES, {
  options: (ownProps) => ({
    variables: {
      clientId: ownProps.clientId,
    },
  }),
  props: ({ data: { loading, client, error }, ownProps }) => ({
    loading: loading || ownProps?.loading,
    templates: client && client.templates ? client.templates : [],
    error,
  }),
});
