import React, { FC } from 'react';

import Check from '@/components/Common/Icons/Check';

import styles from './Term.module.less';

const Term: FC = ({ children }) => (
  <li className={styles.term}>
    <span className={styles.check}>
      <Check />
    </span>
    <span className={styles.text}>{children}</span>
  </li>
);

export default Term;
