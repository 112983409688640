import React, { FC } from 'react';

import styles from './Area.module.less';

interface AreaProps {
  color: string;
  leftValue: number;
  rightValue: number;
  leftOffset: number;
  rightOffset: number;
}

const SEPARATOR_WIDTH = 1;

const Area: FC<AreaProps> = ({
  color,
  leftValue,
  leftOffset,
  rightOffset,
  rightValue,
}) => {
  const topStart = `${100 - (leftOffset + leftValue)}%`;
  const topEnd = `${100 - (rightOffset + rightValue)}%`;
  const bottomStart = `${100 - leftOffset}%`;
  const bottomEnd = `${100 - rightOffset}%`;

  const topLeft = `0 ${topStart}`;
  const topRight = `100% ${topEnd}`;
  const bottomLeft = `0 ${bottomStart}`;
  const bottomRight = `100% ${bottomEnd}`;

  const seperatorTopLeft = `0 calc(${topStart} - ${SEPARATOR_WIDTH / 2}px)`;
  const seperatorTopRight = `100% calc(${topEnd} - ${SEPARATOR_WIDTH / 2}px)`;
  const seperatorBottomLeft = `0 calc(${topStart} + ${SEPARATOR_WIDTH / 2}px)`;
  const seperatorBottomRight = `100% calc(${topEnd} + ${SEPARATOR_WIDTH /
    2}px)`;

  return (
    <>
      <div
        className={styles.separator}
        style={{
          clipPath: `polygon(${seperatorTopLeft}, ${seperatorTopRight}, ${seperatorBottomRight}, ${seperatorBottomLeft})`,
        }}
      />
      <div
        className={styles.area}
        style={{
          backgroundColor: color,
          clipPath: `polygon(${topLeft}, ${topRight}, ${bottomRight}, ${bottomLeft})`,
        }}
      />
    </>
  );
};

export default Area;
