import gql from 'graphql-tag';

export const UserClientPermissionsFragment = gql`
  fragment UserClientPermissionsFragment on UserClientSettings {
    id
    departmentScopes {
      type
      ... on ExplicitDepartmentScope {
        departmentIds
      }
      ... on ExplicitSectionScope {
        sectionIds
      }
      ... on ExplicitSubsectionScope {
        subsectionIds
      }
    }
  }
`;
