import React from 'react';
import _ from 'underscore';
import { Input, Select, TextArea } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import { ConnectPageForPreview } from '@/types/connectPage';
import { GET_CONNECT_FORMS } from '@/graphql/connectForms';
import { ConnectForm } from '@/types/connectForm';
import styles from './ConnectPagePreview.module.less';

interface ConnectPagePreviewProps {
  clientId: string;
  connectPage: ConnectPageForPreview;
}

const ConnectPagePreview: React.FC<ConnectPagePreviewProps> = ({
  connectPage,
  clientId,
}) => {
  const { data: connectFormsData } = useQuery(GET_CONNECT_FORMS, {
    variables: {
      clientId,
    },
  });

  const forms: ConnectForm[] = connectFormsData?.client?.connectForms;
  const connectForm = _.findWhere(forms, {
    id: connectPage.connectForm?.id,
  });

  return (
    <div className={styles.connectPagePreview}>
      <div className={styles.previewHeader}>
        <div className={styles.previewText}>Preview</div>
        <div className={styles.title}>{connectPage?.title?.default}</div>
      </div>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: connectPage?.description || '',
        }}
      />
      <div className={styles.previewForm}>
        {_.map(connectForm?.fields || [], (field) => (
          <div className={styles.question} key={field.id}>
            <p className={styles.questionTitle}>{field.title.default}</p>
            {(!field.customFieldType ||
              field.customFieldType === 'inline-text' ||
              field.customFieldType === 'integer') && (
              <Input
                className={styles.fakeInput}
                disabled
                fluid
                key={`question-${field.id}`}
                value='Answer...'
              />
            )}
            {field.customFieldType === 'text' && (
              <TextArea
                className={styles.fakeInput}
                disabled
                rows={1}
                key={`question-${field.id}`}
                value='Answer...'
              />
            )}
            {field.customFieldType === 'enum' && (
              <Select
                className={styles.fakeInput}
                fluid
                disabled
                key={`question-${field.id}`}
                value={field.title.default}
                placeholder='Select a value...'
                options={[]}
              />
            )}
            {field.customFieldType === 'day' && (
              <Input
                className={styles.fakeInput}
                fluid
                disabled
                key={`question-${field.id}`}
                value={new Date().toLocaleDateString()}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConnectPagePreview;
