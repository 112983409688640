import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import _ from 'underscore';

import styles from './PartitioningSelector.module.less';

interface PartitioningSelectorProps {
  onChange: (partitioning: number) => void;
  options: number[];
  value: number;
  placeholder?: string;
}

const PartitioningSelector: FC<PartitioningSelectorProps> = ({
  onChange,
  options,
  placeholder,
  value,
}) => {
  const { t } = useTranslation();

  const dropdownOptions = _.map(options, (option, index) => ({
    key: index,
    text: `${option}`,
    value: option,
  }));

  return (
    <div className={styles.partitioningSelector}>
      <Dropdown
        className={styles.partitioningDropdown}
        onChange={(_e, { value: newValue }) => {
          if (!_.isNumber(newValue)) {
            return;
          }
          onChange(newValue);
        }}
        options={dropdownOptions}
        placeholder={placeholder}
        compact
        selection
        value={value}
      />
      <span className={styles.label}>
        {t('common.pagination.resultsPerPage')}
      </span>
    </div>
  );
};

export default PartitioningSelector;
