import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import { EvaluationsSet } from '../../graphql/fragments/Evaluations';

export const deleteProfileEvaluation = gql`
  mutation deleteProfileEvaluation(
    $id: ID!
    $evaluationsSetId: ID!
    $evaluationId: ID!
  ) {
    deleteProfileEvaluation(
      id: $id
      evaluationsSetId: $evaluationsSetId
      evaluationId: $evaluationId
    ) {
      id
      evaluationsSets {
        ...EvaluationsSet
      }
    }
  }
  ${EvaluationsSet}
`;

export default graphql(deleteProfileEvaluation, {
  props: ({ mutate }) => ({
    deleteProfileEvaluation: ({ id, evaluationsSetId, evaluationId }) => {
      const variables = { id, evaluationsSetId, evaluationId };
      return mutate({ variables });
    },
  }),
});
