/* eslint-disable max-len */

import React, { FC } from 'react';

import styles from './svg.module.less';

const ArrowLeft: FC = () => (
  <svg
    className={styles.svgIcon}
    style={{
      transform: 'rotateZ(180deg)',
    }}
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.70709 13L13 8.70712C13.1953 8.51186 13.2929 8.25595 13.2929 8.00003M8.70706 3L13 7.29291C13.1953 7.48818 13.2929 7.7441 13.2929 8.00003M13.2929 8.00003L2.5 8.00003'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default ArrowLeft;
