import React, { useEffect, useState } from 'react';
import { Loader, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import useGenerateSequence from '@/graphql/hooks/clients/useGenerateSequence';
import ReadOnlyTemplate from '@/containers/Parameters/Offers/components/ReadOnlyTemplate';
import ExpandableContainer from '@/components/ExpandableContainer';
import EmptyState from '@/revealComponents/EmptyState';
import {
  getMergeTagsSnippets,
  getOldMergeTagsKeys,
  MergeTagsVariable,
  SNIPPET_TYPES,
  MERGE_TAGS_VARIABLES_BASE_IDS,
} from '@/common/mergeTags/utils';
import useClientMarketplaceDescriptor from '@/graphql/hooks/clients/useClientMarketplaceDescriptor';
import { getHtmlStringAsText } from '@/routes/RevealView/SearchView/utils';

import { Action } from '@/types/action';
import styles from './GenerateSequence.module.less';
import {
  getRandomString,
  getTranslatedText,
} from '../../../../../common/index';
import { TYPES_WITH_SUBJECT } from './helpers';
import GenerateSequenceForm, {
  SequenceGenerationFormState,
} from './GenerateSequenceForm';

const SNIPPET_MAP: Record<string, string> = {
  currentCompany: MERGE_TAGS_VARIABLES_BASE_IDS.CURRENT_COMPANY,
  currentJobtitle: MERGE_TAGS_VARIABLES_BASE_IDS.CURRENT_JOB_TITLE,
  currentExperienceDuration:
    MERGE_TAGS_VARIABLES_BASE_IDS.CURRENT_EXPERIENCE_DURATION,
};

interface GenerateSequenceProps {
  clientId: string;
  action: Action;
  actions: Action[];
  sequenceId: string;
  onSubmit: ({
    newSubject,
    newBody,
    newSnippets,
  }: {
    newSubject?: string;
    newBody: string;
    newSnippets: MergeTagsVariable[];
  }) => void;
  open: boolean;
  onChangeOpen: (open: boolean) => void;
}

const ACTION_TYPES_TO_EXCLUDE = [
  'manual',
  'linkedin-check-request',
  'make-phone-call',
  'send-text-message',
];

export const handleSnippetInGeneratedText = (text: string, t: TFunction) => {
  const snippetsInText: any[] = [];
  const baseSnippets = getMergeTagsSnippets({ t });
  const outputWithSnippets = text.replace(
    /\{{([^{}]*)}}/g,
    (match: string, key: string) => {
      const isOldMergeTag = _.findWhere(getOldMergeTagsKeys(t), { key });
      if (isOldMergeTag) {
        return `{{${key}}}`;
      }
      const snippetBaseId = SNIPPET_MAP[key];
      if (!snippetBaseId) {
        const snippetId = `${
          MERGE_TAGS_VARIABLES_BASE_IDS.PLACEHOLDER
        }_sequence_${getRandomString(6)}`;
        snippetsInText.push({
          id: snippetId,
          type: SNIPPET_TYPES.PLACEHOLDER,
          name: key,
        });
        return `{{${snippetId}}}`;
      }
      const snippet = _.findWhere(baseSnippets, { id: snippetBaseId });
      const snippetId = `${snippetBaseId}_sequence_${getRandomString(6)}`;
      snippetsInText.push({
        ...snippet,
        id: snippetId,
      });
      // ajoute à l'id _sequence_randomString(10)
      return `{{${snippetId}}}`;
    },
  );
  return { snippetsInText, outputWithSnippets };
};

const GenerateSequence: React.FC<GenerateSequenceProps> = ({
  clientId,
  action,
  actions,
  sequenceId,
  onSubmit,
  open,
  onChangeOpen,
}) => {
  const { t } = useTranslation();
  const [hasGenerated, setHasGenerated] = useState(false);
  const [body, setBody] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [snippets, setSnippets] = useState<MergeTagsVariable[]>([]);
  const [formState, setFormState] = useState<SequenceGenerationFormState>({
    basePrompts: [
      "Use 5 to 10 sentence to write an outreach email to a candidate I'd like to hire\nYou should mention the current candidate's company.\n\nCandidate's firstname: {{firstname}}\nCandidate's company: {{currentCompany}}",
    ],
    context: {
      language: 'english',
      tone: 'Casual',
      companyDescription: {
        description: '',
      },
      jobDescription: {
        description: '',
      },
      jobPosition:
        clientId === 'ai-seq-demo'
          ? t('sequences.generation.modal.recruiter')
          : '',
    },
    computePolicy: 'bulk',
  });
  const showPrompt = localStorage.getItem('showPrompt') === 'true';

  const actionIndex = _.findIndex(
    actions,
    ({ actionId }) => !!actionId && actionId === action?.actionId,
  );

  const previousActions =
    actionIndex >= 0 ? (actions || []).slice(0, actionIndex) : [];

  const nbMessagesBefore = _.filter(
    previousActions,
    ({ type }) => !_.contains(ACTION_TYPES_TO_EXCLUDE, type),
  ).length;

  useEffect(() => {
    const prompt = `Use ${
      action?.type === 'linkedin-send-request'
        ? '2 to 4 sentences'
        : '5 to 10 sentences'
    } to write ${
      nbMessagesBefore === 0 ? 'an outreach ' : 'a follow-up outreach '
    }${
      action?.type?.indexOf('email') >= 0 ? 'email' : 'message'
    } to a candidate I'd like to hire. ${
      nbMessagesBefore === 0
        ? "\nYou should mention the current candidate's company."
        : ''
    }\n\nCandidate's firstname: {{firstname}}\nCandidate's company: {{currentCompany}}`;
    let outputFormat = '\n\n###\nOutput format\n';
    outputFormat += 'Tone of voice used:[insert]\n';
    outputFormat += 'Language used:[insert]\n';
    outputFormat += 'Subject Message 1:[insert]\nMessage 1:[insert]\n';
    setFormState((f) => ({
      ...f,
      basePrompts: [prompt + outputFormat],
    }));
  }, [action?.type, nbMessagesBefore]);

  const localStorageJobDescription =
    localStorage.getItem(`jobDescription_${sequenceId}`) || '';

  const { marketplaceDescriptor } = useClientMarketplaceDescriptor(clientId);

  const settingsCompanyDescription = getTranslatedText(
    marketplaceDescriptor?.description?.sections?.[0]?.content || {
      default: '',
    },
  );

  useEffect(() => {
    setFormState((f) => ({
      ...f,
      context: {
        ...f.context,
        companyDescription: {
          description: getHtmlStringAsText(settingsCompanyDescription),
        },
      },
    }));
  }, [settingsCompanyDescription]);

  useEffect(() => {
    setFormState((f) => ({
      ...f,
      context: {
        ...f.context,
        jobDescription: {
          description: localStorageJobDescription,
        },
      },
    }));
  }, [localStorageJobDescription]);

  const [fetch, { loading }] = useGenerateSequence({
    queryOptions: {
      fetchPolicy: 'network-only',
      skip: true,
      onCompleted: (data: any) => {
        const generatedSequenceData = data?.client?.generateSequence;
        setSubject(
          handleText(
            generatedSequenceData?.sequence?.actions?.[0].subject || '',
          ),
        );
        setBody(
          handleText(generatedSequenceData.sequence?.actions?.[0].body || ''),
        );
      },
    },
  });

  const handleText = (text: string) => {
    const { snippetsInText, outputWithSnippets } = handleSnippetInGeneratedText(
      text,
      t,
    );
    setSnippets([...snippets, ...snippetsInText]);
    return outputWithSnippets;
  };

  const saveJobDescriptionInLocalStorage = () => {
    if (sequenceId) {
      localStorage.setItem(
        `jobDescription_${sequenceId}`,
        formState.context.jobDescription.description,
      );
    }
  };

  const handleSubmit = () => {
    onSubmit({
      ...(_.contains(TYPES_WITH_SUBJECT, action.type) && {
        newSubject: `<div>${subject}</div>`,
      }),
      newBody: `<div>${body}</div>`,
      newSnippets: snippets,
    });
    onChangeOpen(false);
  };

  return (
    <GenericModal open={open} size='fullscreen'>
      <Modal.Header>{t('sequences.generation.modal.title')}</Modal.Header>
      <Modal.Content>
        <div className={styles.formWithPreview}>
          <GenerateSequenceForm
            clientId={clientId}
            formState={formState}
            setFormState={setFormState}
            showPrompt={showPrompt}
            inModal
          />

          <div className={styles.generatedContent}>
            <h4>{t('sequences.generation.modal.preview')}</h4>
            {_.isEmpty(body) ? (
              <EmptyState
                illustrationPath='/images/placeholders/profileEmptyState.svg'
                title=''
                innerContent={t('sequences.generation.modal.emptyState')}
                className='profileEmptyState' // For resume creator parsing
              />
            ) : (
              <ExpandableContainer
                className='message-template'
                alwaysExpanded
                render={(props: { contentRef: any }) => (
                  <ReadOnlyTemplate
                    contentRef={props.contentRef}
                    clientId={clientId}
                    snippets={snippets}
                    subject={
                      _.contains(TYPES_WITH_SUBJECT, action.type) ? subject : ''
                    }
                    body={body}
                    shouldDisplaySubject={_.contains(
                      TYPES_WITH_SUBJECT,
                      action.type,
                    )}
                    signature=''
                  />
                )}
              />
            )}
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <GenericButton
          primacy='secondary'
          onClick={() => onChangeOpen(false)}
          disabled={loading}
          size='big'
        >
          {t('common.cancel')}
        </GenericButton>
        <div className={styles.generateAndConfirm}>
          <GenericButton
            primacy={_.isEmpty(body) && !hasGenerated ? 'primary' : 'secondary'}
            onClick={() => {
              saveJobDescriptionInLocalStorage();
              fetch(formState);
              setHasGenerated(true);
            }}
            disabled={loading}
            size='big'
          >
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='currentColor'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M11.516 1.89224L3.39669 10.9709C2.50334 11.9699 3.21679 13.5489 4.56146 13.5489H8.88318C9.74386 13.5489 10.4416 14.2424 10.4416 15.0979V21.336C10.4416 22.4042 11.77 22.9061 12.484 22.1078L20.6033 13.0291C21.4967 12.0301 20.7832 10.4511 19.4385 10.4511H15.1168C14.2561 10.4511 13.5584 9.75759 13.5584 8.90213V2.66404C13.5584 1.59583 12.23 1.09386 11.516 1.89224Z'
                fill='currentColor'
              />
            </svg>
            {_.isEmpty(body) && !hasGenerated
              ? t('sequences.generation.modal.generate')
              : t('sequences.generation.modal.regenerate')}
          </GenericButton>

          {loading ? (
            <GenericButton primacy='primary' disabled size='big'>
              <div className={styles.loaderButton}>
                <Loader active size='small' />
              </div>
            </GenericButton>
          ) : !_.isEmpty(body) ? (
            <GenericButton
              primacy='primary'
              onClick={() => handleSubmit()}
              size='big'
            >
              {t('common.confirm')}
            </GenericButton>
          ) : null}
        </div>
      </Modal.Actions>
    </GenericModal>
  );
};

export default GenerateSequence;
