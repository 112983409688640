import React from 'react';
import _ from 'underscore';
import { Dropdown } from 'semantic-ui-react';

import withJobStages from '../../../../hocs/integrations/withJobStages';

const stageOptions = (stages) => {
  const jobStages = _.map(stages, (stage) => ({
    value: stage.step,
    text: stage.name,
    position: stage.position,
  }));
  return _.sortBy(jobStages, 'position');
};

const offerOptions = (offers) => {
  const displayedOffers = _.where(offers, { isSuggestedForCreation: false, isUnclassified: false });
  return _.map(displayedOffers, (offer) => ({
    value: offer.id,
    text: offer.title,
  }));
};

const integrationOfferOptions = (integrationOffers) => {
  return _.map(integrationOffers, (offer) => ({
    value: offer.id,
    text: offer.name,
  }));
};

const recruiterOptions = (recruiters) => {
  return _.map(recruiters, (recruiter) => ({
    value: recruiter.id,
    text: recruiter.name,
  }));
};

export const triggerTexts = {
  'profile-uploaded': 'integrations.triggers.upload',
  'profile-sent': 'integrations.triggers.send',
  'profile-answered': 'integrations.triggers.answered',
  'profile-first-moved-to-process': 'integrations.triggers.move-to-process',
};

const triggerOptions = (t) => [
  /*
  {
    value: 'profile-uploaded',
    text: t(triggerTexts['profile-uploaded']),
  },
  */
  {
    value: 'profile-sent',
    text: t(triggerTexts['profile-sent']),
  },
  {
    value: 'profile-first-moved-to-process',
    text: t(triggerTexts['profile-first-moved-to-process']),
  },
  {
    value: 'profile-answered',
    text: t(triggerTexts['profile-answered']),
  },
];

class DropdownWithStages extends React.PureComponent {
  onChangeStage = (e, { value }) => {
    const { handleStageChange, stages } = this.props;
    const { step: stageStep, name: stageName } = _.findWhere(stages, { step: value });
    handleStageChange({
      stageStep,
      stageName,
    });
  };

  render() {
    const { jobId, value, stages, loading, t } = this.props;
    if (!jobId) {
      return (
        <Dropdown
          className='offerMappingCreator-dropdown'
          fluid
          selection
          placeholder={t('integrations.placeholders.in')}
          options={null}
          loading={loading}
          disabled
        />
      );
    }
    return (
      <Dropdown
        className='offerMappingCreator-dropdown'
        fluid
        search
        selection
        placeholder={t('integrations.placeholders.in')}
        value={value}
        options={stageOptions(stages)}
        loading={loading}
        onChange={this.onChangeStage}
      />
    );
  }
}

const JobStagesDropdown = (props) => {
  const { jobId } = props;
  if (!jobId) {
    return <DropdownWithStages {...props} />;
  }
  const Component = withJobStages(DropdownWithStages);
  return <Component {...props} />;
};

const WorkflowForm = ({
  offers,
  integration,
  clientId,
  hiresweetOfferId,
  handleHiresweetOfferChange,
  trigger,
  handleIntegrationTriggerChange,
  integrationOffers,
  integrationOfferId,
  handleIntegrationOfferChange,
  needsStageSelection,
  stageStep,
  handleStageChange,
  needsRecruiterSelection,
  recruiters,
  recruiterId,
  handleRecruiterChange,
  offerSpecificMode,
  t,
}) => {
  const offerDropdownOptions = offerOptions(offers);
  return (
    <>
      <div className='inputs-row'>
        <div className='input-container half-width'>
          <div className='input-label'>{t('integrations.configuration.from')}</div>
          {(offerDropdownOptions || []).length === 1 && offerSpecificMode ? (
            <span className='offerMappingCreator-default-element-value'>{offerDropdownOptions[0].text}</span>
          ) : (
            <Dropdown
              className='offerMappingCreator-dropdown'
              fluid
              search
              selection
              placeholder={t('integrations.placeholders.from')}
              value={hiresweetOfferId}
              options={offerDropdownOptions}
              onChange={handleHiresweetOfferChange}
            />
          )}
        </div>

        <div className='input-container half-width'>
          <div className='input-label'>{t('integrations.configuration.to')}</div>
          <Dropdown
            className='offerMappingCreator-dropdown'
            fluid
            search
            selection
            placeholder={t('integrations.placeholders.to')}
            value={integrationOfferId}
            options={integrationOfferOptions(integrationOffers)}
            onChange={handleIntegrationOfferChange}
          />
        </div>
      </div>
      <div className='inputs-row'>
        <div className='input-container half-width'>
          <div className='input-label'>{t('integrations.configuration.on')}</div>
          <Dropdown
            className='offerMappingCreator-dropdown'
            fluid
            search
            selection
            placeholder={t('integrations.placeholders.on')}
            options={triggerOptions(t)}
            value={trigger}
            onChange={handleIntegrationTriggerChange}
          />
        </div>
        {needsStageSelection && (
          <div className='input-container half-width'>
            <div className='input-label'>{t('integrations.configuration.in')}</div>
            <JobStagesDropdown
              clientId={clientId}
              integrationId={(integration || {}).id}
              jobId={integrationOfferId}
              value={stageStep}
              handleStageChange={handleStageChange}
              t={t}
            />
          </div>
        )}
      </div>
      {needsRecruiterSelection && (
        <div className='inputs-row align-right'>
          <div className='input-container half-width'>
            <div className='input-label'>{t('integrations.configuration.as')}</div>
            <Dropdown
              className='offerMappingCreator-dropdown'
              fluid
              search
              selection
              placeholder={t('integrations.placeholders.as')}
              value={recruiterId}
              options={recruiterOptions(recruiters)}
              onChange={handleRecruiterChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WorkflowForm;
