import classNames from 'classnames';
import React, { forwardRef, HTMLAttributes, useCallback } from 'react';

import styles from './DropdownTrigger.module.less';

interface DropdownTriggerProps {
  clearable?: boolean;
  onClear?: () => void;
}

const DropdownTrigger = forwardRef<
  HTMLDivElement,
  DropdownTriggerProps & HTMLAttributes<HTMLDivElement>
>(({ clearable = false, onClear, children, className }, ref) => {
  const handleClear = useCallback(
    (event) => {
      // Prevents opening/closing the dropdown
      event.stopPropagation();
      if (onClear) {
        onClear();
      }
    },
    [onClear],
  );

  return (
    <div ref={ref} className={classNames(styles.dropdownTrigger, className)}>
      <div className={styles.trigger}>{children}</div>
      <div className={styles.actions}>
        <i
          aria-hidden='true'
          className={classNames('dropdown icon', styles.dropdownIcon)}
        />
        {clearable && (
          <i
            aria-hidden='true'
            className='ri-close-fill ri-lg'
            onClick={handleClear}
          />
        )}
      </div>
    </div>
  );
});

export default DropdownTrigger;
