import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import _ from 'underscore';
import { useQuery } from '@apollo/client';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import styles from './ConnectBox.module.less';
import { GET_CONNECT_FORMS } from '../../../../../graphql/connectForms';

interface ConnectBoxProps {
  clientId: string;
  profile: SearchPoolProfile;
}

const ConnectBox: React.FC<ConnectBoxProps> = ({ clientId, profile }) => {
  const { t } = useTranslation();
  const connectDataForm =
    profile.resumeData?.sourceData?.connect?.interestForms?.[0];
  const { data: connectFormsData } = useQuery(GET_CONNECT_FORMS, {
    variables: {
      clientId,
    },
  });
  const currentConnectForm = _.findWhere(
    connectFormsData?.client?.connectForms || [],
    {
      id: connectDataForm?.formId,
    },
  );
  return (
    <div className={styles.connectBox}>
      <span className={styles.title}>
        {t('reveal.profileModal.connectBox.title')}
        <span className={styles.formName}>
          {currentConnectForm?.title?.default}
        </span>
      </span>
      <div className={styles.fieldsContainer}>
        {_.map(connectDataForm?.fields || [], (field) => (
          <div className={styles.field} key={field.id}>
            <div className={styles.question}>{field.title.default}</div>
            <div className={styles.answer}>{field.value?.text || ''}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withTranslation('translations')(ConnectBox);
