import React, { FC, useMemo } from 'react';
import _ from 'underscore';
import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';

import { OfferCriteria } from '@/types/offerCriteria';
import useSalaryBenchmark from '@/graphql/hooks/salaryBenchmark/useSalaryBenchmark';
import { getShortLanguage } from '@/common/utils/i18n';
import InfoTooltip from '@/components/Common/InfoTooltip';

import { getFlexValues } from './helpers';

import styles from './SalaryBenchmark.module.less';

type SalaryBenchmarkProps = {
  criteria: OfferCriteria;
};

const managerLevels = ['TEAM_LEAD', 'HEAD_OF', 'VP', 'C_LEVEL'] as const;

const SalaryBenchmark: FC<SalaryBenchmarkProps> = ({ criteria }) => {
  const { t, i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const { salaryBenchmark, emptyResult, missingFields } = useSalaryBenchmark({
    criteria,
  });

  const currencyFormat = useMemo(
    () =>
      new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }),
    [lang],
  );

  if (emptyResult) {
    return (
      <div className={styles.benchmark}>
        <div className={styles.header}>
          <div className={styles.title}>
            {t('criteria.salaryBenchmark.defaultTitle')}
          </div>
        </div>
        <div className={styles.emptyState}>
          {t('criteria.salaryBenchmark.noData')}
        </div>
      </div>
    );
  }

  if (!_.isEmpty(missingFields)) {
    return (
      <div className={styles.benchmark}>
        <div className={styles.header}>
          <div className={styles.title}>
            {t('criteria.salaryBenchmark.defaultTitle')}
          </div>
        </div>
        <div className={styles.emptyState}>
          {t('criteria.salaryBenchmark.missingFields', {
            fields: _.map(missingFields || [], (field) =>
              t(`criteria.${field}`, { count: 0 }),
            ).join(', '),
          })}
        </div>
      </div>
    );
  }

  if (!salaryBenchmark) {
    return null;
  }

  const [
    firstQuarter,
    secondQuarter,
    thirdQuarter,
    fourthQuarter,
  ] = getFlexValues(salaryBenchmark);

  return (
    <div className={styles.benchmark}>
      <div className={styles.header}>
        <div className={styles.title}>
          {t('criteria.salaryBenchmark.title', {
            position: criteria.jobPositions?.required[0].label.default,
            location: t(
              `criteria.salaryBenchmark.location.${salaryBenchmark?.countryCode}`,
            ),
          })}
        </div>
        <div className={styles.partnership}>
          <img
            src='/images/logos/figures.png'
            className={styles.logo}
            alt='Figures'
          />
          <Trans
            i18nKey='criteria.salaryBenchmark.partnership.figures'
            components={{
              a: (
                <a
                  className={styles.link}
                  target='_blank'
                  href={t('criteria.salaryBenchmark.partnership.figuresUrl')}
                  rel='noreferrer'
                >
                  Figures
                </a>
              ),
            }}
          />
        </div>
      </div>
      <div className={styles.legend}>
        <div className={styles.left}>{t('criteria.salaryBenchmark.low')}</div>
        <div className={styles.right}>{t('criteria.salaryBenchmark.high')}</div>
      </div>
      <div className={styles.graph}>
        <div
          className={classNames(styles.bar, styles.first)}
          style={{ flex: firstQuarter }}
        />
        <div className={classNames(styles.handle, styles.first)}>
          <div className={styles.label}>
            {currencyFormat.format(
              Math.floor(salaryBenchmark.min.salary / 100),
            )}
            <InfoTooltip iconClassName={styles.tooltip}>
              {t(
                `criteria.salaryBenchmark.tooltip.${
                  _.contains(managerLevels, salaryBenchmark.min.level)
                    ? 'managerMin'
                    : 'min'
                }`,
                {
                  position: criteria.jobPositions?.required[0].label.default,
                  location: t(
                    `criteria.salaryBenchmark.location.${salaryBenchmark?.countryCode}`,
                  ),
                  level: t(
                    `criteria.salaryBenchmark.level.${salaryBenchmark.min.level}`,
                  ),
                },
              )}
            </InfoTooltip>
          </div>
        </div>
        <div
          className={classNames(styles.bar, styles.second)}
          style={{ flex: secondQuarter }}
        />
        <div className={classNames(styles.handle, styles.second)}>
          <div className={styles.upperLabel}>
            {t('criteria.salaryBenchmark.benchmark')}
          </div>
          <div className={styles.label}>
            {currencyFormat.format(
              Math.floor(salaryBenchmark.mean.salary / 100),
            )}
            <InfoTooltip iconClassName={styles.tooltip}>
              {t(
                `criteria.salaryBenchmark.tooltip.${
                  _.contains(managerLevels, salaryBenchmark.mean.level)
                    ? 'managerMean'
                    : 'mean'
                }`,
                {
                  position: criteria.jobPositions?.required[0].label.default,
                  location: t(
                    `criteria.salaryBenchmark.location.${salaryBenchmark?.countryCode}`,
                  ),
                  level: t(
                    `criteria.salaryBenchmark.level.${salaryBenchmark.mean.level}`,
                  ),
                },
              )}
            </InfoTooltip>
          </div>
        </div>
        <div
          className={classNames(styles.bar, styles.third)}
          style={{ flex: thirdQuarter }}
        />
        <div className={classNames(styles.handle, styles.third)}>
          <div className={styles.label}>
            {currencyFormat.format(
              Math.floor(salaryBenchmark.max.salary / 100),
            )}
            <InfoTooltip iconClassName={styles.tooltip}>
              {t(
                `criteria.salaryBenchmark.tooltip.${
                  _.contains(managerLevels, salaryBenchmark.max.level)
                    ? 'managerMax'
                    : 'max'
                }`,
                {
                  position: criteria.jobPositions?.required[0].label.default,
                  location: t(
                    `criteria.salaryBenchmark.location.${salaryBenchmark?.countryCode}`,
                  ),
                  level: t(
                    `criteria.salaryBenchmark.level.${salaryBenchmark.max.level}`,
                  ),
                },
              )}
            </InfoTooltip>
          </div>
        </div>
        <div
          className={classNames(styles.bar, styles.fourth)}
          style={{ flex: fourthQuarter }}
        />
      </div>
    </div>
  );
};

export default SalaryBenchmark;
