import gql from 'graphql-tag';

export default gql`
  fragment SlackIntegration on SlackIntegration {
    id
    channel {
      name
    }
    team {
      name
    }
    notificationsSettings {
      watchCollectNotification {
        active
      }
      newCandidatesNotification {
        active
      }
      positiveAnswersNotification {
        active
      }
      statisticsNotification {
        active
      }
    }
  }
`;
