import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import Thumbnail from '../../../../../components/Thumbnail';
import TASK_ICONS from '../TaskIcons';
import AuthorAndDate from '../AuthorAndDate';

const ClassicMeeting = ({ actionType, timelineItem, author }) => {
  const { t } = useTranslation();
  const text =
    actionType === 'plannedMeetingAction'
      ? t('profile.contact.timeline.scheduledMeeting', {
          date: new Date(timelineItem.action.startDate),
        })
      : t('profile.contact.timeline.cancelledMeeting', {
          date: new Date(timelineItem.action.startDate),
        });
  return (
    <div className='timeline-card-mail-wrapper classic-item'>
      <div className='content'>
        <div className='header'>
          <Thumbnail
            link={author.photoLink}
            firstname={author.firstname}
            lastname={author.lastname}
          />
          <div className='author'>
            <div className='date'>
              {+timelineItem.date
                ? t('common.formattedDate', {
                    date: new Date(+timelineItem.date),
                  })
                : t('common.defaultDuration')}
            </div>
            <span>
              {author.firstname} {author.lastname} {text}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RevealMeeting = ({ actionType, timelineItem, author }) => {
  const { t } = useTranslation();
  const text =
    actionType === 'plannedMeetingAction'
      ? t('profile.contact.timeline.scheduledMeeting', {
          date: new Date(timelineItem.action.startDate),
        })
      : t('profile.contact.timeline.cancelledMeeting', {
          date: new Date(timelineItem.action.startDate),
        });
  return (
    <div className='timeline-card-mail-wrapper'>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{ backgroundColor: TASK_ICONS.meeting.color }}
              className='icon'
            >
              <i className={`${TASK_ICONS.meeting.icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            <span>
              {author.firstname} {author.lastname} {text}
            </span>
            <AuthorAndDate author={author} timelineItem={timelineItem} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Meeting = ({ actionType, timelineItem, applicationMode }) => {
  const author = timelineItem.author ? timelineItem.author : {};

  if (applicationMode === 'classic') {
    return (
      <ClassicMeeting
        actionType={actionType}
        timelineItem={timelineItem}
        author={author}
      />
    );
  }
  return (
    <RevealMeeting
      actionType={actionType}
      timelineItem={timelineItem}
      author={author}
    />
  );
};

export default Meeting;
