import _ from 'underscore';
import useClientId from '@/hooks/router/useClientId';
import {
  HiresweetLibProvider,
  TasksSetContainer,
  TasksTable,
  TaskTargetFirstname,
  TaskTargetLastname,
  createSweetTaskComponent,
  TaskTargetEmail,
  useTaskData,
} from '@hiresweet/hiresweet-lib';
import React from 'react';


const Fullname = createSweetTaskComponent(() => {
  return (
    <div>
      <TaskTargetFirstname /> <TaskTargetLastname />
    </div>
  );
}, [TaskTargetFirstname, TaskTargetLastname]);

const HiddenEmail = createSweetTaskComponent(() => {
  const { loading, data: email } = useTaskData(TaskTargetEmail);
  
  if (loading) {
    return null;
  }

  const hiddenEmail = (email ?? '').replace(/^.*@/, '***@');

  return <span>{hiddenEmail}</span>;
}, [TaskTargetEmail]);

const PlaygroundTaskTable2 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  return (
    <HiresweetLibProvider theme={theme} clientId={clientId}>
      <h1>Tasks table 2 (sweetTaskCompo + useTaskData)</h1>
      <TasksSetContainer filter={{}}>
        <TasksTable pageSize={10}>
          <TasksTable.Header className=''>
            <TasksTable.HeaderCell className=''>Fullname (composed)</TasksTable.HeaderCell>
            <TasksTable.HeaderCell className=''>HiddenEmail (constructed)</TasksTable.HeaderCell>
          </TasksTable.Header>
          <TasksTable.Body>
            <TasksTable.RowMap>
              <TasksTable.Cell className=''><Fullname /></TasksTable.Cell>
              <TasksTable.Cell className=''><HiddenEmail /></TasksTable.Cell>
            </TasksTable.RowMap>
          </TasksTable.Body>
        </TasksTable>
      </TasksSetContainer>
    </HiresweetLibProvider>
  );
};


export default PlaygroundTaskTable2;
