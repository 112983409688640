import _ from 'underscore';
import React, { useMemo, useState } from 'react';
import {
  Button,
  HiresweetJobPostingProjects,
  HiresweetLibProvider,
  HiresweetProfile,
  HiresweetProject,
} from '@hiresweet/hiresweet-lib';
import useClientId from '@/hooks/router/useClientId';

type Location =
  | {
      type: 'projects';
    }
  | {
      type: 'project';
      projectId: string;
    }
  | {
      type: 'profile';
      profileId: string;
    };



const Playground12 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  const [location, setLocation] = useState<Location>({ type: 'projects' });
  const content = useMemo(() => {
    if (location.type === 'project') {
      return <HiresweetProject projectId={location.projectId} />;
    }
    if (location.type === 'profile') {
      return <HiresweetProfile profileId={location.profileId} />;
    }
    return <HiresweetJobPostingProjects />;
  }, [location]);
  return (
    <div style={{ padding: 30 }}>
      <h1>P12 - Slash JobPostings</h1>

      <div>
        <HiresweetLibProvider
          theme={theme}
          clientId={clientId}
          actions={{
            openProjectPage: (projectId) =>
              setLocation({ type: 'project', projectId }),
            openProfilePage: (profileId) =>
              setLocation({ type: 'profile', profileId }),
          }}
        >
          <Button
            type='button'
            primacy='secondary'
            disabled={location.type === 'projects'}
            onClick={() => setLocation({ type: 'projects' })}
          >
            Back to projects
          </Button>
          {content}
        </HiresweetLibProvider>
      </div>
    </div>
  );
};

export default Playground12;
