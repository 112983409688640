/* eslint-disable class-methods-use-this */
import _ from 'underscore';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import { SyncedFragmentVariable } from '@/common/mergeTags/utils';
import { NO_VARIABLE_FOUND_ERROR_MESSAGE } from '@/SweetEvaluator/utils/constants';

export default class ExpressionSyncedFragmentEvaluator
  implements SweetEvaluatorTypes.EvaluatorInterface
{
  evaluate({
    context,
    expression,
    getExpressionEvaluatorFromType,
    externalVariableEvaluator,
  }: {
    context: SweetEvaluatorTypes.Context;
    expression: SweetEvaluatorTypes.Variable;
    getExpressionEvaluatorFromType: SweetEvaluatorTypes.ExpressionEvaluatorFromType;
    externalVariableEvaluator: SweetEvaluatorTypes.ExternalVariableEvaluator;
  }): { value: string | undefined } {
    const currentExpression =
      expression as unknown as SweetEvaluatorTypes.BaseVariable &
        SyncedFragmentVariable;
    if (!context.externalSnippets) {
      return { value: undefined };
    }
    const contextExternalSnippets = context.externalSnippets;
    const clientSyncedFragment =
      contextExternalSnippets[currentExpression.clientDynamicVariableId];
    if (!clientSyncedFragment) {
      return { value: undefined };
    }
    if (
      !('text' in clientSyncedFragment) ||
      typeof clientSyncedFragment.text !== 'string'
    ) {
      return { value: undefined };
    }
    if (
      !('snippets' in clientSyncedFragment) ||
      !Array.isArray(clientSyncedFragment.snippets)
    ) {
      return { value: undefined };
    }
    const { text, snippets } = clientSyncedFragment;
    const evaluatuedSnippetValueFromId: Record<string, string | undefined> = {};

    _.each(snippets, (snippet) => {
      try {
        evaluatuedSnippetValueFromId[snippet.id] = externalVariableEvaluator({
          query: { idInParentDynamicVariable: snippet.id },
        }).value;
      } catch (error) {
        if ((error as any).message !== NO_VARIABLE_FOUND_ERROR_MESSAGE) {
          throw error;
        }
        evaluatuedSnippetValueFromId[snippet.id] =
          getExpressionEvaluatorFromType({
            type: snippet.type,
          }).evaluate({
            context,
            expression: snippet as SweetEvaluatorTypes.Variable,
            getExpressionEvaluatorFromType,
            externalVariableEvaluator,
          }).value;
      }
    });

    const resultingText = text.replace(
      /\{{([^{}]*)}}/g,
      (match: string, key: string) => {
        // The span should be only on frontend, not on the syncedFragmentEvaluator in backend
        const value = evaluatuedSnippetValueFromId[key];
        if (value) {
          return `<span class='merge-tags-deep-snippet-value'>${
            evaluatuedSnippetValueFromId[key] || ''
          }</span>`;
        }
        return `<span class='merge-tags-deep-snippet-no-value'>${''}</span>`;
      },
    );
    return {
      value: resultingText,
    };
  }
}
