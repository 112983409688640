import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import { EvaluationsSet } from '../../graphql/fragments/Evaluations';

export const updateProfileEvaluationsSet = gql`
  mutation updateProfileEvaluationsSet(
    $id: ID!
    $evaluationsSetId: ID!
    $evaluationsSet: EvaluationsSetInput
  ) {
    updateProfileEvaluationsSet(
      id: $id
      evaluationsSetId: $evaluationsSetId
      evaluationsSet: $evaluationsSet
    ) {
      id
      evaluationsSets {
        ...EvaluationsSet
      }
    }
  }
  ${EvaluationsSet}
`;

export default graphql(updateProfileEvaluationsSet, {
  props: ({ mutate }) => ({
    updateProfileEvaluationsSet: ({ id, evaluationsSetId, evaluationsSet }) => {
      const variables = { id, evaluationsSetId, evaluationsSet };
      return mutate({ variables });
    },
  }),
});
