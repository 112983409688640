import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Profile from '../../graphql/fragments/Profile';

export const query = gql`
  query getProfile($id: ID!) {
    profile(id: $id) {
      ...Profile
    }
  }
  ${Profile}
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.profileId,
    },
  }),
  props: ({ data: { loading, profile, error } }) => ({
    loading,
    loadingProfile: loading,
    profile,
    profileError: error,
  }),
});
