import React from 'react';
import logAction from '../../../common/logAction';
import { getSSOUrl } from './utils';

interface SSOLinkProps {
  clientId: string;
  redirectTo: Location;
}

const SSOLink: React.FC<SSOLinkProps> = ({ clientId, redirectTo, children }) => {
  const ssoUrl = getSSOUrl(clientId, redirectTo);
  const onClickSSO = (e: React.MouseEvent) => {
    e.preventDefault();
    logAction({
      type: 'click-signin-sso',
      info: {
        clientId,
        from: 'signin-form',
        redirection: ssoUrl,
      },
    });
    window.location.href = ssoUrl;
  };

  return (
    <a href={ssoUrl} onClick={onClickSSO}>
      {children}
    </a>
  );
};

export default SSOLink;
