import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import JobPosting from '../../graphql/fragments/JobPosting';

export const publishJobPosting = gql`
  mutation publishJobPosting($input: PublishJobPostingInput!) {
    publishJobPosting(input: $input) {
      jobPosting {
        ...JobPosting
      }
    }
  }
  ${JobPosting}
`;

export default graphql(publishJobPosting, {
  props: ({ mutate }) => ({
    publishJobPosting: ({ input }) => mutate({ variables: { input } }),
  }),
});
