import gql from 'graphql-tag';
import CandidateClientInterestStates from './CandidateClientInterestStates';
import Sources from './Sources';

export default gql`
  fragment SmallResumeData on ProfileResumeData {
    step
    subStep
    firstname
    lastname
    headline {
      content {
        text
      }
    }
    photoLink
    prequalification {
      type
    }
    sources {
      ...Sources
    }
    sourceData {
      hiresweet {
        ...CandidateClientInterestStates
      }
    }
  }
  ${Sources}
  ${CandidateClientInterestStates}
`;
