import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { mainEventsFields } from '../../graphql/fragments/Analytics';

export const query = gql`
  query getClientAchievements($clientId: ID!) {
    client(id: $clientId) {
      id
      statistics {
        mainEvents {
          ...mainEventsFields
        }
      }
    }
  }
  ${mainEventsFields}
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      clientId: ownProps.clientId,
    },
  }),
  props: ({ data: { error, loading, client } }) => {
    return {
      mainEvents: client?.statistics?.mainEvents,
      mainEventsLoading: loading,
      mainEventsError: error,
    };
  },
});
