import _ from 'underscore';

export type LocationTarget = {
  target: {
    location?: Location;
    remoteWish: string;
  }[];
};

export type Location =
  | ExactLocation
  | RadiusFromPointLocation
  | RadiusFromIdLocation;

type ExactLocation = {
  type?: 'exact-id';
  id: string;
  label?: string;
};

type RadiusFromPointLocation = {
  type: 'radius-from-point';
  pointLatitude: number;
  pointLongitude: number;
  radiusInKm: number;
  label?: string;
};

export type RadiusFromIdLocation = {
  type: 'radius-from-id';
  id: string;
  radiusInKm: number;
  label?: string;
};

export function getRadiusLocations(
  { target }: LocationTarget = { target: [] },
) {
  const result = [] as (RadiusFromIdLocation | RadiusFromPointLocation)[];
  target.forEach(({ location }) => {
    if (location && 'id' in location) {
      result.push({
        type: 'radius-from-id',
        id: location.id,
        radiusInKm: 'radiusInKm' in location ? location.radiusInKm : 0,
        label: location.label,
      });
      return;
    }
    if (
      location &&
      'pointLatitude' in location &&
      'pointLongitude' in location
    ) {
      result.push({
        type: 'radius-from-point',
        label: location.label,
        pointLongitude: location.pointLongitude,
        pointLatitude: location.pointLatitude,
        radiusInKm: 'radiusInKm' in location ? location.radiusInKm : 0,
      });
    }
  });
  return result;
}
