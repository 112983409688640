import _ from 'underscore';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import BaseOffer from '../../graphql/fragments/BaseOffer';

export const getClientBaseOffers = gql`
  query getClientBaseOffers($clientId: ID!) {
    client(id: $clientId) {
      id
      offers(includeSuggestedOffers: true, includeUnclassified: true) {
        ...BaseOffer
      }
    }
  }
  ${BaseOffer}
`;

// without memoization, all components which subscribe to the offers prop
// will have a different array each time. This can cause superfluous renders.
const sortWatchBeforeOthers = _.memoize(
  (clientOffers) =>
    _.sortBy(clientOffers, ({ isHiresweetWatch }) => !isHiresweetWatch),
  JSON.stringify,
);

export default graphql(getClientBaseOffers, {
  options: (ownProps) => ({
    variables: {
      clientId: ownProps.clientId,
    },
  }),
  props: ({ data: { error, loading, client } }) => {
    return {
      offers: sortWatchBeforeOthers(
        client && client.offers ? client.offers : [],
      ),
      offersLoading: loading,
      loading,
      error,
    };
  },
});
