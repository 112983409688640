import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Input } from 'semantic-ui-react';

const RegisteredInput = ({ name, ...props }) => {
  const { register, watch, setValue } = useFormContext();

  const watchedValue = watch(name);

  return (
    <Input
      {...register(name)}
      {...props}
      defaultValue={watchedValue}
      onChange={(event) => setValue(name, event.target.value)}
    />
  );
};

export default RegisteredInput;
