import gql from 'graphql-tag';

export default gql`
  fragment SequenceStatistics on RevealSequenceStatistics {
    nbEnrolled
    nbContacted
    nbSentWithEmailOpenTracking
    nbSentWithLinkClickTracking
    nbOpened
    nbClicked
    nbAnswered
    nbPositiveAnswered
  }
`;
