import React from 'react';
import {
  MutationFunctionOptions,
  MutationTuple,
  useMutation,
} from '@apollo/client';
import { UPDATE_CONTACT_FLOW_ACTION } from '@/graphql/searchPoolProfile';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';

interface ContactFlowActionInput {
  id: string;
  sequenceId: string;
  actionId: string;
  actionInput: any; // FIXME
}

interface UpdateContactFlowActionInput {
  searchPoolId: string;
  input: ContactFlowActionInput;
}

export const useUpdateContactFlowAction = (): MutationTuple<
  any,
  UpdateContactFlowActionInput
> => {
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();
  const [mutation, res] = useMutation<any, UpdateContactFlowActionInput>(
    UPDATE_CONTACT_FLOW_ACTION,
  );

  const wrappedMutation = React.useCallback(
    async (
      options?: MutationFunctionOptions<any, UpdateContactFlowActionInput>,
    ) => {
      if (!options || !options.variables) {
        throw new Error('Missing variables');
      }
      const { id, actionId, sequenceId } = options?.variables?.input || {};

      const execResult = await mutation(options);
      publishSubscriptionEvent('onTaskUpdated', {
        profileId: id,
        taskId: `task-for-${actionId}`,
        sequenceId,
      });

      return execResult;
    },
    [mutation, publishSubscriptionEvent],
  );

  return [wrappedMutation, res];
};

export default useUpdateContactFlowAction;
