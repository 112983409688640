const parser = new DOMParser();

/**
 * Removes leading and trailing line breaks if html only contains one line.
 * See unit tests for examples
 */
export function trimLineBreaks(html: string): string {
  if (!html) {
    return html;
  }

  const tree = parser.parseFromString(html, 'text/xml');

  /**
   * There is a bit of magic happening here.
   * Multiline inputs are going to look something like this :
   * <div>first line</div><div>second line</div>
   * With no enclosing tag, which makes it invalid html since it's not a tree.
   *
   * We take advantage of this by detecting the resulting parsing failure and
   * returning the input as-is since we don't want to transform multiline
   * inputs.
   */
  const errorNode = tree.querySelector('parsererror');
  if (errorNode) {
    return html;
  }

  return tree.documentElement.innerHTML;
}
