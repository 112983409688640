import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const getClientActiveOffers = gql`
  query getClientActiveOffers($clientId: ID!, $includeUnclassified: Boolean) {
    client(id: $clientId) {
      id
      offers(activeOnly: true, includeUnclassified: $includeUnclassified) {
        id
        title
        isUnclassified
      }
    }
  }
`;

export default graphql(getClientActiveOffers, {
  options: ({ clientId, includeUnclassified = false }) => {
    const variables = { clientId, includeUnclassified };
    // TODO: fetchPolicy could be optimised
    // (for now, addOffer does not refetch this query and this query is only used in modals)
    return { variables, fetchPolicy: 'network-only' };
  },
  props: ({ data: { client }, loading }) => {
    return {
      activeOffers: client && client.offers,
      activeOffersLoading: loading,
    };
  },
});
