import React, { FC } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Offer } from '@/types/offer';

import OfferRow from './OfferRow';
import Counter from '../Counter';

import styles from './OfferTable.module.less';

interface OfferTableProps {
  offers: Offer[];
  title: string;
}

const OfferTable: FC<OfferTableProps> = ({ offers, title }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.offers}>
      <div className={styles.header}>
        <div className={styles.title}>
          {title}
          <Counter count={offers.length} />
        </div>
        <div className={classNames(styles.column, styles.main)}>
          {t('profile.steps.pipe.pending-main')}
        </div>
        <div className={classNames(styles.column, styles.main)}>
          {t('profile.steps.pipe.pending-extended')}
        </div>
        <div className={classNames(styles.column, styles.extra)}>
          {t('profile.steps.pipe.answered')}
        </div>
        <div className={classNames(styles.column, styles.extra)}>
          {t('profile.steps.pipe.in-process')}
        </div>
        <div className={styles.menu} />
      </div>
      {_.map(offers, (offer) => (
        <OfferRow key={offer.id} offer={offer} />
      ))}
    </div>
  );
};

export default OfferTable;
