import React, { FC } from 'react';

import AddToProjectAction from './AddToProjectAction';
import HideFromSearchAction from './HideFromSearchAction';

import styles from './ProfileActions.module.less';

interface ProfileActionsProps {
  projectId: string;
  profileId: string;
  setLockHover?: (lock: boolean) => void;
}

const ProfileActions: FC<ProfileActionsProps> = ({
  projectId,
  profileId,
  setLockHover,
}) => (
  <div className={styles.actions}>
    <AddToProjectAction
      profileId={profileId}
      projectId={projectId}
      setLockHover={setLockHover}
    />
    <HideFromSearchAction profileId={profileId} projectId={projectId} />
  </div>
);

export default ProfileActions;
