import React from 'react';

export const emptyEmailContext = {
  currentEmailType: '', // the type of email api, either outlook or gmail
  onTriggerSendMail: () => {}, // send an email, with or without a threadId
  onTriggerOfflineRegister: () => {},
  onTriggerChangeAccount: () => {},
  onTriggerSignIn: () => {},
  onTriggerSignOut: () => {},
  onSendError: () => {},
  getIdToken: () => {},
  getEmailAddressDetails: () => {},
  currentAddress: '',
  sendConfirmationModeActive: true,
  setSendConfirmationMode: () => {},
  isFirstSessionSend: true,
  setIsFirstSessionSend: () => {},
  hasOfflineGrant: {},
  connectionInProgress: true,
  onOpenProviderChoiceModal: () => {},
};

const EmailContext = React.createContext(emptyEmailContext);

export default EmailContext;
