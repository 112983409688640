import { ATStype } from '@/common/reveal';
import { useClientCurrentAtsId } from '@/graphql/hooks/clients/useClientRevealProjects';
import { ColumnDisplay } from '@/graphql/hooks/users/useUserDisplayPreferences';
import { ISearchPoolJob } from '@/graphql/searchPoolJobs';

import { ATSDefinitionGetter, ColumnRenderer } from './types';

import { AdventureColumns } from './adventure/columns';
import { ProjectStats } from '../components/Projects/Row/ProjectRow/types';

export interface UseMissionATSMetadataColumnsResult {
  columns: ColumnDisplay[];
  valueRenderers: Record<string, ColumnRenderer>;
  headerRenderers: Record<string, ColumnRenderer>;
}

type PartialAtsGetter = {
  [x in ATStype]?: ATSDefinitionGetter;
};
const COLUMNS_BY_ATS: PartialAtsGetter = {
  adventure: AdventureColumns,
};

export function useMissionATSMetadataColumns({
  job,
  stats,
}: {
  job: null | ISearchPoolJob;
  stats: null | ProjectStats;
}): UseMissionATSMetadataColumnsResult {
  const atsId = useClientCurrentAtsId();

  if (!atsId || !COLUMNS_BY_ATS[atsId]) {
    return {
      columns: [],
      valueRenderers: {},
      headerRenderers: {},
    };
  }

  const {
    getColumnDefinitions,
    getHeaderRenderers,
    getValueRenderers,
    // Disable because we tested just before
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = COLUMNS_BY_ATS[atsId]!;

  return {
    columns: getColumnDefinitions({ job, stats }),
    valueRenderers: getValueRenderers({ job, stats }),
    headerRenderers: getHeaderRenderers({ job, stats }),
  };
}
