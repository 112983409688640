import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const mutation = gql`
  mutation addSelectionFeedback(
    $offerId: ID!
    $input: SelectionFeedbackInput!
  ) {
    addSelectionFeedback(id: $offerId, input: $input) {
      id
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    addSelectionFeedback: ({ offerId, rating, reason }) =>
      mutate({
        variables: {
          offerId,
          input: { rating, reason },
        },
      }),
  }),
});
