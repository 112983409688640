import React, { useContext, FC } from 'react';
import { useTranslation } from 'react-i18next';

import useMinimizedView from '@/hooks/ui/useMinimizedView';
import SequenceDropdownControlsContext from '../context/SequenceDropdownControlsContext';

const AddToSequence: FC = () => {
  const { t } = useTranslation();
  const { isMinimized } = useMinimizedView();
  const { toggleSequenceDropdown } = useContext(
    SequenceDropdownControlsContext,
  );

  return (
    <button
      type='button'
      onClick={toggleSequenceDropdown || undefined}
      className='new-mission-icon'
      disabled={toggleSequenceDropdown === null}
    >
      {!isMinimized && (
        <span style={{ whiteSpace: 'nowrap' }}>
          {t('profile.missionsAndSequences.addToSequence')}
        </span>
      )}
      <i className='ri-add-circle-line ri-lg' />
    </button>
  );
};

export default AddToSequence;
