import React, { useState } from 'react';
import _ from 'underscore';
import * as Sentry from '@sentry/browser';
import { Loader } from 'semantic-ui-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';

import useSelectedProfile from '@/hooks/router/useSelectedProfile';
import ProfileIdHandler from '@/common/ProfileIdHandler';
import { Sequence } from '@/types/sequence';
import useClientSequences from '@/graphql/hooks/sequences/useClientSequences';
import {
  PageSelector,
  PartitioningSelector,
} from '@/components/Common/Pagination';
import GenericButton from '@/components/Common/GenericButton';
import { Profile } from '@/types/profile';
// import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import RecommendedProfileRow from './RecommendedProfileRow';
import RecommendedProfileModal from './RecommendedProfileModal';

import styles from './RecommendedProfilesTab.module.less';

interface RecommendedProfilesTabProps {
  jobId: string;
  clientId: string;
  searchPoolId: string;
  profiles: Profile[];
  profilesLoading: boolean;
  profilesStatesCount: {
    active: number;
    skipped: number;
    accepted: number;
  };
  isApplicantsTab?: boolean;
  currentTab: string;
  setCurrentTab: (tab: string) => void;
  onSkip: (profileId: string, reason: string | undefined) => void;
  onConfirm: (profileId: string, sequenceId: string | null) => void;
  onRemove: () => void;
  onGenerateNewRecommendedProfiles?: (jobId: string) => void;
  generationLoading?: boolean;
}

const RecommendedProfilesTab: React.FC<RecommendedProfilesTabProps> = ({
  jobId,
  clientId,
  searchPoolId,
  profiles,
  profilesLoading,
  profilesStatesCount,
  isApplicantsTab = false,
  currentTab,
  setCurrentTab,
  onSkip,
  onConfirm,
  onRemove,
  onGenerateNewRecommendedProfiles = () => {},
  generationLoading = false,
}) => {
  const { t } = useTranslation();
  const notifications = useNotificationSystem();
  // const { user } = useCurrentUser();
  const [selectedProfileId, setSelectedProfileId] = useSelectedProfile();
  const [candidatePerPage, setCandidatePerPage] = useState(
    +localStorage.MissionPaginationProfilesPerPage || 10,
  );
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const clientSequences = useClientSequences({ clientId });
  const clientSequencesList: Sequence[] = _.filter(
    clientSequences?.data?.client?.sequences,
    ({ isArchived }) => !isArchived,
  );
  const profileCategoryTranslationKey = isApplicantsTab
    ? 'applicantProfiles'
    : 'recommendedProfiles';

  const profileIds = _.pluck(profiles, 'id');

  const nbPages = Math.ceil(profileIds?.length / candidatePerPage);
  const currentPageProfilesId = profiles.slice(
    currentPageIndex * candidatePerPage,
    (currentPageIndex + 1) * candidatePerPage,
  );

  const candidatePerPageOptions = [10, 15, 20, 30, 50];

  const handleCandidatePerPageChange = (value: number) => {
    setCandidatePerPage(value);
    localStorage.setItem('MissionPaginationProfilesPerPage', `${value}`);
  };

  const profileIdHandler = ProfileIdHandler({
    profileIds,
    selectedProfileId,
    onSelectProfileId: (profileId: string) => setSelectedProfileId(profileId),
  });

  const skipProfile = async (profileId: string, reason?: string) => {
    try {
      await onSkip(profileId, reason);
      if (selectedProfileId === profileId) {
        profileIdHandler.onSelectProfileId(profileIdHandler.next);
      }
      notifications.success(
        t(`reveal.${profileCategoryTranslationKey}.skip.success`),
      );
    } catch (e) {
      Sentry.captureException(e);
      notifications.error(
        t(`reveal.${profileCategoryTranslationKey}.skip.error`),
      );
    }
  };
  const confirmProfile = async (
    profileId: string,
    sequenceId: string | null,
  ) => {
    try {
      await onConfirm(profileId, sequenceId);
      notifications.success(
        t(`reveal.${profileCategoryTranslationKey}.confirm.success`),
      );
    } catch (e) {
      Sentry.captureException(e);
      notifications.error(
        t(`reveal.${profileCategoryTranslationKey}.confirm.error`),
      );
    }
  };

  return (
    <div className='project-profiles-tab'>
      <div className='project-profiles-table'>
        <div className='button-container'>
          <button
            type='button'
            className={classNames('tab-button', {
              active: currentTab === 'active',
            })}
            onClick={() => setCurrentTab('active')}
          >
            {t(`reveal.${profileCategoryTranslationKey}.tabs.active`)} •{' '}
            {profilesStatesCount?.active || 0}
          </button>
          <button
            type='button'
            className={classNames('tab-button', {
              active: currentTab === 'accepted',
            })}
            onClick={() => setCurrentTab('accepted')}
          >
            {t(`reveal.${profileCategoryTranslationKey}.tabs.accepted`)} •{' '}
            {profilesStatesCount?.accepted || 0}
          </button>
          <button
            type='button'
            className={classNames('tab-button', {
              active: currentTab === 'skipped',
            })}
            onClick={() => setCurrentTab('skipped')}
          >
            {t(`reveal.${profileCategoryTranslationKey}.tabs.skipped`)} •{' '}
            {profilesStatesCount?.skipped || 0}
          </button>
        </div>

        <div className='profiles-page-container'>
          {profilesLoading ? (
            <div className='loading-placeholder'>
              <Loader
                active
                inline='centered'
                size='large'
                className={styles.loader}
              />
            </div>
          ) : (
            _.map(currentPageProfilesId, (profile) => (
              <RecommendedProfileRow
                jobId={jobId}
                key={profile?.id}
                profile={profile}
                setSelectedProfileId={setSelectedProfileId}
                clientId={clientId}
                currentTab={currentTab}
                searchPoolId={searchPoolId}
                skipProfile={skipProfile}
                confirmProfile={confirmProfile}
                removeRecommendedProfile={onRemove}
                isApplicantRow={isApplicantsTab}
              />
            ))
          )}

          {!profilesLoading && _.isEmpty(profiles) && (
            <div className='empty-profiles-page'>
              {/* There are no contacts in this mission */}
              {t(`reveal.${profileCategoryTranslationKey}.emptyTable`)}
              <img
                src='/images/placeholders/sequenceContacts.svg'
                className='image-placeholder'
                alt='empty-state'
              />
              {!isApplicantsTab && (
                <GenericButton
                  size='big'
                  disabled={generationLoading}
                  onClick={() => onGenerateNewRecommendedProfiles(jobId)}
                >
                  {t(
                    'reveal.missions.mission.recommandations.moreRecommandationsBtn',
                  )}
                </GenericButton>
              )}
            </div>
          )}
        </div>

        <div className={styles.pagination}>
          <PartitioningSelector
            onChange={handleCandidatePerPageChange}
            options={candidatePerPageOptions}
            value={candidatePerPage}
          />
          {nbPages > 1 && (
            <PageSelector
              currentPageIndex={currentPageIndex}
              numberOfPages={nbPages}
              onChange={setCurrentPageIndex}
            />
          )}
        </div>
      </div>

      <RecommendedProfileModal
        clientId={clientId}
        searchPoolId={searchPoolId}
        open={!!selectedProfileId}
        jobId={jobId}
        onClose={() => setSelectedProfileId(null)}
        profileIdHandler={profileIdHandler}
        skipProfile={skipProfile}
        confirmProfile={confirmProfile}
        clientSequencesList={clientSequencesList}
        isApplicantProfileModal={isApplicantsTab}
      />
    </div>
  );
};

export default RecommendedProfilesTab;
