import _ from 'underscore';
import React from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import useAtsFilterOptions from '@/graphql/hooks/clients/useClientAtsFiltersOptions';
import useClientId from '@/hooks/router/useClientId';
import { getDateFromTimestamp } from '@/common/dates';
import DateRangePicker from '../components/DateRangePicker';
import AccordionFilter from '../components/AccordionFilter';
import { getCreationDynamicDescription, getTimestampFromDate } from './utils';

import styles from './ImportFilters.module.less';

const SOURCE_TYPE_OPTIONS = [
  'reveal-csv-import',
  'reveal-plugin-import',
  'reveal-app-import',
];

interface ImportFiltersProps {
  importFilters: any; // TODO
  onChange: (value: any) => void;
  withoutAccordion: boolean | null;
}

export const ImportFilters: React.FC<ImportFiltersProps> = ({
  importFilters,
  onChange,
  withoutAccordion,
}) => {
  const { t } = useTranslation('translations');
  const clientId = useClientId();
  const { data: filterOptionsData } = useAtsFilterOptions(clientId);
  const { users, bulkImports } = filterOptionsData?.client || {};

  const userOptions = _.map(users, (user) => ({
    key: user.email,
    value: user.email,
    text: `${user.firstname} ${user.lastname}`,
  }));

  const bulkImportOptions = _.map(bulkImports, (bulkImport) => ({
    key: bulkImport.id,
    value: bulkImport.id,
    text: `${bulkImport.name}`,
  }));

  const getDropdownOnChangeHandler = (type: string) => (
    ev: any,
    data: DropdownProps,
  ) => {
    onChange((previousImportFilters: any) => {
      if (_.isEmpty(data.value)) {
        return _.omit(previousImportFilters, type);
      }
      return {
        ...previousImportFilters,
        [type]: data.value,
      };
    });
  };

  const creationDateOnChangeHandler = (dateFilters?: DateFilters) => {
    onChange((previousImportFilters: any) => {
      if (_.isEmpty(dateFilters)) {
        return _.omit(previousImportFilters, 'creationDateFilter');
      }
      return {
        ...previousImportFilters,
        creationDateFilter: dateFilters,
      };
    });
  };

  const translatedSourceTypeOptions = _.map(SOURCE_TYPE_OPTIONS, (type) => ({
    key: type,
    value: type,
    text: t(`reveal.searchView.search.import.${type}`),
  }));

  const count = _.keys(importFilters).length;
  const content = (
    <>
      <div className={styles.subFilter}>
        <div className='subtitle'>
          {t('reveal.searchView.search.import.creationDate')}
        </div>
        <ImportDateFilter
          filters={importFilters?.creationDateFilter}
          onChange={creationDateOnChangeHandler}
        />
      </div>
      <div className={styles.subFilter}>
        <div className='subtitle'>
          {t('reveal.searchView.search.import.source')}
        </div>
        <ImportFilterDropdown
          onChange={getDropdownOnChangeHandler('sourceTypes')}
          value={importFilters?.sourceTypes || []}
          options={translatedSourceTypeOptions}
          placeholder={t('reveal.searchView.search.import.sourcePlaceholder')}
        />
      </div>
      <div className={styles.subFilter}>
        <div className='subtitle'>
          {t('reveal.searchView.search.import.name')}
        </div>
        <ImportFilterDropdown
          onChange={getDropdownOnChangeHandler('sourceImportIds')}
          value={importFilters?.sourceImportIds || []}
          options={bulkImportOptions}
          placeholder={t('reveal.searchView.search.import.namePlaceholder')}
        />
      </div>
      <div className={styles.subFilter}>
        <div className='subtitle'>
          {t('reveal.searchView.search.import.importedBy')}
        </div>
        <ImportFilterDropdown
          onChange={getDropdownOnChangeHandler('sourceRecruiterEmails')}
          value={importFilters?.sourceRecruiterEmails || []}
          options={userOptions}
          placeholder={t(
            'reveal.searchView.search.import.importedByPlaceholder',
          )}
        />
      </div>
    </>
  );

  if (withoutAccordion) {
    return <div className='custom-criteria-filters'>{content}</div>;
  }
  return (
    <div className='custom-criteria-filters'>
      <AccordionFilter
        title={t('reveal.searchView.search.import.label')}
        count={count}
      >
        {content}
      </AccordionFilter>
    </div>
  );
};

interface ImportFilterDropdownProps {
  onChange: (ev: any, data: DropdownProps) => void;
  value: string[];
  options: { text: string; value: string; key: string }[];
  placeholder: string;
}
const ImportFilterDropdown: React.FC<ImportFilterDropdownProps> = ({
  onChange,
  value,
  options,
  placeholder,
}) => {
  return (
    <>
      <Dropdown
        placeholder={placeholder}
        // search on label and value
        search={(dropdownOptions, query) => {
          const re = new RegExp(query, 'i');
          return dropdownOptions.filter(
            (option) =>
              (typeof option.text === 'string' && re.test(option.text)) ||
              (typeof option.value === 'string' && re.test(option.value)),
          );
        }}
        fluid
        selection
        multiple
        options={options}
        value={value}
        onChange={onChange}
      />
    </>
  );
};

type DateFilters = {
  minTimestamp?: number;
  maxTimestamp?: number;
  minDelayInMs?: number;
  maxDelayInMs?: number;
};

interface ImportDateFilterProps {
  onChange: (dateFilters: DateFilters) => void;
  filters?: DateFilters;
}
const ImportDateFilter: React.FC<ImportDateFilterProps> = ({
  filters,
  onChange,
}) => {
  const handleUpdateMinTimestamp = ({
    timestamp,
  }: {
    timestamp: number | null;
  }): void => {
    if (!timestamp) {
      return onChange(_.omit(filters || {}, 'minTimestamp'));
    }
    return onChange({
      ...filters,
      minTimestamp: timestamp,
    });
  };

  const handleUpdateMinDelay = ({ delay }: { delay: number | null }): void => {
    if (!delay) {
      return onChange(_.omit(filters || {}, 'minDelayInMs'));
    }
    return onChange({
      ...filters,
      minDelayInMs: delay,
    });
  };

  const handleUpdateMaxTimestamp = ({
    timestamp,
  }: {
    timestamp: number | null;
  }): void => {
    if (!timestamp) {
      return onChange(_.omit(filters || {}, 'maxTimestamp'));
    }
    return onChange({
      ...filters,
      maxTimestamp: timestamp,
    });
  };

  const handleUpdateMaxDelay = ({ delay }: { delay: number | null }): void => {
    if (!delay) {
      return onChange(_.omit(filters || {}, 'maxDelayInMs'));
    }
    return onChange({
      ...filters,
      maxDelayInMs: delay,
    });
  };

  const handleResetMin = () => {
    return onChange(_.omit(filters || {}, 'minDelayInMs', 'minTimestamp'));
  };

  const handleResetMax = () => {
    return onChange(_.omit(filters || {}, 'maxDelayInMs', 'maxTimestamp'));
  };

  const handleResetAll = () => {
    return onChange({});
  };

  return (
    <DateRangePicker
      minDate={getDateFromTimestamp(filters?.minTimestamp)}
      maxDate={getDateFromTimestamp(filters?.maxTimestamp)}
      onUpdateMinDate={({ date }: { date: Date | null }) =>
        handleUpdateMinTimestamp({
          timestamp: getTimestampFromDate(date),
        })
      }
      onUpdateMaxDate={({ date }: { date: Date | null }) =>
        handleUpdateMaxTimestamp({
          timestamp: getTimestampFromDate(date),
        })
      }
      minDelay={filters?.minDelayInMs}
      maxDelay={filters?.maxDelayInMs}
      onUpdateMinDelay={handleUpdateMinDelay}
      onUpdateMaxDelay={handleUpdateMaxDelay}
      dynamicDescription={getCreationDynamicDescription(
        filters?.minDelayInMs,
        filters?.maxDelayInMs,
      )}
      onResetMin={handleResetMin}
      onResetMax={handleResetMax}
      onResetAll={handleResetAll}
    />
  );
};

export default ImportFilters;
