import React, { useState } from 'react';

import useClientId from '@/hooks/router/useClientId';

import {
  Button,
  HiresweetLibProvider,
  SidePanel,
} from '@hiresweet/hiresweet-lib';

const PlaygroundJ1 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  const [open, setOpen] = useState(false);
  return (
    <div style={{ padding: 30 }}>
      <h1>P1 - Profile tabs</h1>
      <HiresweetLibProvider theme={theme} clientId={clientId}>
        <Button onClick={() => setOpen(true)}>Open panel</Button>
        <SidePanel open={open} onClose={() => setOpen(false)}>
          Hello, World!
        </SidePanel>
      </HiresweetLibProvider>
    </div>
  );
};

export default PlaygroundJ1;
