import gql from 'graphql-tag';
import {
  FetchResult,
  MutationHookOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

const UNFOLLOW_JOB = gql`
  mutation unfollowJob($id: ID!) {
    unfollowJob(id: $id) {
      id
      owner {
        email
      }
      hiringManager {
        email
      }
      followers {
        email
      }
    }
  }
`;

interface UnfollowJobData {
  id: string;
  owner?: {
    email: string;
  };
  hiringManager?: {
    email: string;
  };
  followers?: {
    email: string;
  }[];
}

interface UnfollowJobVariables {
  id: string;
}

interface UseUnfollowJobInput {
  mutationOptions?: MutationHookOptions<UnfollowJobData, UnfollowJobVariables>;
}

type UseUnfollowJobResult = [
  (id: string) => Promise<FetchResult<UnfollowJobData>>,
  MutationResult<UnfollowJobData>,
];

const useUnfollowJob = ({
  mutationOptions = {},
}: UseUnfollowJobInput = {}): UseUnfollowJobResult => {
  const [mutation, result] = useMutation<UnfollowJobData, UnfollowJobVariables>(
    UNFOLLOW_JOB,
    {
      ...mutationOptions,
    },
  );

  return [(id) => mutation({ variables: { id } }), result];
};

export default useUnfollowJob;
