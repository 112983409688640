import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { removeUnwantedFields } from '@/containers/Parameters/OfferCriteria/helpers';
import OfferSearchCriteria from '../../graphql/fragments/OfferSearchCriteria';

export const getClientActiveOffersSearchCriteria = gql`
  query getClientActiveOffersSearchCriteria($clientId: ID!) {
    client(id: $clientId) {
      id
      offers(activeOnly: true) {
        id
        title
        criteria {
          ...OfferSearchCriteria
        }
      }
    }
  }
  ${OfferSearchCriteria}
`;

export default graphql(getClientActiveOffersSearchCriteria, {
  options: ({ clientId }) => {
    const variables = { clientId };
    // TODO: fetchPolicy could be optimised
    return { variables, fetchPolicy: 'network-only' };
  },
  props: ({ data: { client } }) => {
    return {
      offersSearchCriteria: client && removeUnwantedFields(client.offers),
    };
  },
});
