import React, { useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import ActionTriggerEditor from '../../../../../../components/contactFlow/ActionTriggerEditor';
import { getTranslatedDateOrDuration } from '../../helpers';


const TriggerEditor = ({ action, permissionsAndNudges, onUpdateAction, onDeleteAction, deletionIsAllowed, t }) => {
  const [triggerEditionMode, setTriggerEditionMode] = useState(false);
  const [triggerState, setTriggerState] = useState(action?.trigger);

  const { trigger, plannedExecutionDate } = action;
  const translatedDateOrDuration = getTranslatedDateOrDuration({ plannedExecutionDate, trigger, t });

  if (trigger?.type === 'manual-trigger') {
    return null;
  }

  return (
    <div className='trigger-editor'>
      <div className='trigger-editor-toggle'>
        <div className='trigger-editor-header'>
          <Checkbox checked disabled={!deletionIsAllowed} onClick={onDeleteAction}/>
          <i className='ri-ghost-smile-line'/>
          {translatedDateOrDuration}
        </div>
        {
          triggerEditionMode ? (
            <>
              <i
                className='icon-button ri-close-line'
                onClick={() => {
                  setTriggerState(action.trigger);
                  setTriggerEditionMode(false);
                }}
              />
              <i
                className='icon-button ri-check-line'
                onClick={() => {
                  onUpdateAction({ updatedAction: { ...action, trigger: triggerState } });
                  setTriggerEditionMode(false);
                }}
              />
            </>
          ) : (
            <i className='icon-button ri-edit-line' onClick={() => setTriggerEditionMode(true)}/>
          )
        }
      </div>
      {triggerEditionMode && (
        <div className='trigger-editor-container'>
          <ActionTriggerEditor
            action={{ ...action, trigger: triggerState }}
            onUpdate={({ action: updatedAction }) => setTriggerState(updatedAction.trigger)}
            permissionsAndNudges={permissionsAndNudges}
          />
        </div>
      )}
    </div>
  );
};

export default TriggerEditor;
