import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import { MiniSequence } from '@/types/sequence';

export interface GetMiniSequenceData {
  sequence: MiniSequence;
}

export interface GetMiniSequenceVariables {
  sequenceId: string;
}

const GET_MINI_SEQUENCE = gql`
  query getMiniSequence($sequenceId: ID!) {
    sequence(id: $sequenceId) {
      id
      title
    }
  }
`;

export interface UseMiniSequenceResult
  extends QueryResult<GetMiniSequenceData, GetMiniSequenceVariables> {
  miniSequence: MiniSequence | undefined;
}

interface useMiniSequenceInput {
  sequenceId: string;
  queryOptions?: QueryHookOptions<
    GetMiniSequenceData,
    GetMiniSequenceVariables
  >;
}

const useMiniSequence = ({
  sequenceId,
  queryOptions = {},
}: useMiniSequenceInput): UseMiniSequenceResult => {
  const query = useQuery<GetMiniSequenceData, GetMiniSequenceVariables>(
    GET_MINI_SEQUENCE,
    {
      fetchPolicy: 'cache-first',
      ...(queryOptions || {}),
      variables: { sequenceId },
    },
  );

  const miniSequence = query.data?.sequence;

  return { ...query, miniSequence };
};

export default useMiniSequence;
