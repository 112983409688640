import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import _ from 'underscore';

import GenericToggle from '@/components/Common/GenericToggle';
import { ClientCustomAction } from '@/types/customAction';
import GenericSelect from '@/components/Common/GenericSelect';
import GenericFormLabel from '@/components/Common/GenericFormLabel';
import GenericTextInput from '@/components/Common/GenericTextInput';

import PhantomBusterTaskConfig from './PhantomBusterTaskConfig';

// TODO: change inputs etc to use our own
import styles from '../../../CustomTasksSettings.module.less';

interface CustomTaskFormExecutionConfigProps {
  taskForm: ClientCustomAction;
  onUpdate: (newValues: Partial<ClientCustomAction>) => void;
}

const taskExecutionTypes = ['http-call', 'phantombuster'] as const;

export function CustomTaskFormExecutionConfig({
  taskForm,
  onUpdate,
}: CustomTaskFormExecutionConfigProps) {
  const { t } = useTranslation();
  const [showExecution, setShowExecution] = useState(
    taskForm.execution.type !== '',
  );

  const taskExecutionTypeOptions = useMemo(
    () =>
      _.map(taskExecutionTypes, (value) => ({
        label: t(
          `reveal.parameters.customTasksSettings.executionTypes.${value}`,
        ),
        value,
      })),
    [t],
  );

  const selectedTaskExecutionOption = useMemo(
    () =>
      _.findWhere(taskExecutionTypeOptions, {
        value: taskForm.execution.type || undefined,
      }),
    [taskForm.execution.type, taskExecutionTypeOptions],
  );

  const handleShowExecution = useCallback(
    ({ value }: { value: boolean }) => {
      if (!value) {
        onUpdate({
          execution: {
            type: '',
          },
        });
      }
      setShowExecution(value);
    },
    [onUpdate],
  );

  const onUrl = useCallback(
    (url: string) => {
      if (taskForm.execution.type !== 'http-call') {
        return;
      }
      onUpdate({ execution: { ...taskForm.execution, url } });
    },
    [taskForm, onUpdate],
  );

  return (
    <>
      <div className={styles.fieldHeader}>
        <GenericToggle
          className={styles.toggle}
          isChecked={showExecution || false}
          name='showOrder'
          onChange={handleShowExecution}
        />
        <span>
          {t(
            'reveal.parameters.customTasksSettings.creationModal.addExecution',
          )}
        </span>
      </div>
      {showExecution && (
        <>
          <Form.Field className={styles.modalFormField}>
            <GenericFormLabel>
              {t('reveal.parameters.customTasksSettings.executionType')}
            </GenericFormLabel>
            <GenericSelect
              value={selectedTaskExecutionOption}
              onChange={(value) => {
                if (!value) {
                  return;
                }
                onUpdate({
                  execution: {
                    type: value.value,
                  },
                });
              }}
              options={taskExecutionTypeOptions}
            />
          </Form.Field>
          {taskForm.execution.type === 'http-call' && (
            <Form.Field className={styles.modalFormField}>
              <GenericFormLabel>
                {t('reveal.parameters.customTasksSettings.url')}
              </GenericFormLabel>
              <GenericTextInput
                fluid
                value={taskForm.execution.url || ''}
                onValue={onUrl}
                data-form-type='url'
              />
            </Form.Field>
          )}
          {taskForm.execution.type === 'phantombuster' && (
            <PhantomBusterTaskConfig
              phantomServiceId={taskForm.execution.phantomServiceId}
              phantomAgentId={taskForm.execution.phantomAgentId}
              onPhantomServiceId={(phantomServiceId) => {
                if (taskForm.execution.type !== 'phantombuster') {
                  return;
                }
                onUpdate({
                  execution: {
                    ...taskForm.execution,
                    phantomServiceId,
                    phantomAgentId: undefined,
                    phantomAgentTitle: undefined,
                    phantomAgentType: undefined,
                  },
                });
              }}
              onPhantomAgentInfo={(info) => {
                if (taskForm.execution.type !== 'phantombuster') {
                  return;
                }
                const { phantomAgentId, phantomAgentTitle, phantomAgentType } =
                  info || {};
                onUpdate({
                  execution: {
                    ...taskForm.execution,
                    phantomAgentId,
                    phantomAgentTitle,
                    phantomAgentType,
                  },
                });
              }}
            />
          )}
        </>
      )}
    </>
  );
}
