import gql from 'graphql-tag';
import RevealConnector, { IRevealConnector } from './RevealConnector';

export interface IRevealProject {
  id: string;
  connectors: IRevealConnector[];
}

export default gql`
  fragment RevealProject on RevealProject {
    id
    connectors {
      ...RevealConnector
    }
  }
  ${RevealConnector}
`;
