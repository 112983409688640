import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import ContactFlow from '../../graphql/fragments/ContactFlow';

export const removeSequence = gql`
  mutation removeSequence(
    $jobOfferId: ID
    $contactFlowId: ID!
    $sequenceId: ID!
  ) {
    removeSequence(
      jobOfferId: $jobOfferId
      contactFlowId: $contactFlowId
      sequenceId: $sequenceId
    ) {
      ...ContactFlow
    }
  }
  ${ContactFlow}
`;

export default graphql(removeSequence, {
  props: ({ ownProps, mutate }) => ({
    deleteSequence: ({ contactFlowId, sequenceId }) =>
      mutate({
        variables: {
          jobOfferId: ownProps.offerId,
          contactFlowId,
          sequenceId,
        },
      }),
  }),
});
