import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import _, { Dictionary } from 'underscore';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import {
  Project,
  PROJECT_SORT_CARDINALS,
  useProjectDropdownPreferences,
} from '@/components/Common/ProjectDropdown/sortFilter';
import { useClientDepartmentsQuery } from '@/hocs/clients/withClientDepartments';
import useClientId from '@/hooks/router/useClientId';
import { Department } from '@/types/client';
import { Author } from '@/types/author';
import { getFullname } from '@/common/helpers/person';
import { ISearchPoolJobOption } from '@/graphql/fragments/SearchPoolJobOption';
import useClientUsers from '@/graphql/hooks/clients/useClientUsers';
import {
  TreeItemType,
  generateSelectedItemsFromTopLevelSelection,
  generateSelectedItemsFromTree,
} from './TreeDropdown';

interface ProjectTreeDropdownContextValue {
  loading: boolean;
  selectedNodeIds: string[];
  selectedItemIds: string[];
  tree: TreeItemType;
  selectedSortKey: null | string;
  selectedFilterKey: null | string;
  selectedGroupKey: null | string;
  items: Project[];
  totalItemCount: number;

  onUpdateTree: (newTree: TreeItemType) => void;
  onGroupChange: (key: string) => void;
  onSortChange: (key: string) => void;
  onFilterChange: (key: string) => void;
}

const ProjectTreeDropdownContext = React.createContext<null | ProjectTreeDropdownContextValue>(
  null,
);

export const useProjectTreeDropdownContext = () => {
  const context = useContext(ProjectTreeDropdownContext);
  if (!context) {
    throw new Error('Did you forget to use ProjectTreeDropdownProvider');
  }
  return context;
};

// TODO: add context to make last-used-by-me work
const compareFunctionMap: Record<string, (a: Project, b: Project) => void> = {
  ...PROJECT_SORT_CARDINALS,
  'last-use-by-me': (a: Project, b: Project) => {
    if (
      (a.lastUseByAuthor?.[0].timestamp || '') <
      (b.lastUseByAuthor?.[0].timestamp || '')
    ) {
      return -1;
    }
    if (
      (a.lastUseByAuthor?.[0].timestamp || '') >
      (b.lastUseByAuthor?.[0].timestamp || '')
    ) {
      return 1;
    }
    return 0;
  },
  'no-sort': (_a: Project, _b: Project) => 0,
};

const getByDepartmentCategorizer = (departments: Department[]) => ({
  getPathFromItem: ({ item }: { item: Project }) =>
    _.compact([
      item.foldering?.department?.id,
      item.foldering?.section?.id,
      item.foldering?.subsection?.id,
    ]),
  getFolderingTree: () =>
    _.object(
      _.map(departments, (department) => [
        department.id,
        {
          title: department.title,
          childrenById: _.object(
            _.map(department.sections || [], (section) => [
              section.id,
              {
                title: section.title,
                childrenById: _.object(
                  _.map(section.subsections || [], (subsection) => [
                    subsection.id,
                    {
                      title: subsection.title,
                    },
                  ]),
                ),
              },
            ]),
          ),
        },
      ]),
    ),
});

const getByAuthorCategorizer = (authors: Author[]) => ({
  getPathFromItem: ({ item }: { item: Project }) => [item.owner?.email],
  getFolderingTree: () =>
    _.object(
      _.map(authors, (author) => [
        author?.email,
        { title: getFullname(author) },
      ]),
    ),
});

const getFlatCategorizer = () => ({
  getPathFromItem: ({ item: _item }: { item: Project }) => [],
  getFolderingTree: () => ({}),
});

type ProjectTreeDropdownItem = {
  id: string;
  items: ProjectTreeDropdownItem[];
  title: string;
  selected: boolean;
};

const getCategorizedItem = (
  items: Project[],
  categorizer: {
    getPathFromItem: ({ item }: { item: Project }) => string[];
    getFolderingTree: () => ProjectTreeDropdownItem;
  },
  compareItems: (a: any, b: any) => number,
  selectedNodeIds: string[], // TODO : transform to map
  forceSelection = false,
) => {
  const itemsByPath = _.groupBy(items, (item) =>
    categorizer.getPathFromItem({ item }).join(';'),
  );
  const foldering = categorizer.getFolderingTree();
  const tree = {
    id: 'root',
    items: [],
    title: '',
    selected: forceSelection || items.length === selectedNodeIds?.length,
  };
  const fillNode = (
    folderingNode,
    treeNode: TreeItemType,
    currentPath: string,
  ) => {
    const childrenItems = itemsByPath[currentPath] || [];

    const folderingNodeItems: [TreeItemType, Project][] = [];
    _.each(folderingNode, ({ title, childrenById }, key) => {
      const path = `${currentPath && `${currentPath};`}${key}`;
      const newItem = {
        id: key,
        items: [],
        content: title || key,
        searchableText: title || key,
        selected: treeNode.selected || _.includes(selectedNodeIds, key),
      };
      const { treeNode: newTreeNode, sortValue } = fillNode(
        childrenById,
        newItem,
        path,
      );
      folderingNodeItems.push([newTreeNode, sortValue]);
    });

    const sortedFolderingNodes = folderingNodeItems.sort(
      ([_itemA, sortValueA], [_itemB, sortValueB]) => {
        return compareItems
          ? compareItems(
              {
                ..._.omit(sortValueA, 'title'),
                data: { title: sortValueA?.searchableText || '' },
              },
              {
                ..._.omit(sortValueB, 'title'),
                data: { title: sortValueB?.searchableText || '' },
              },
            )
          : 0;
      },
    );

    const sortedFolderingItems = _.map(sortedFolderingNodes, ([item]) => item);

    // because of for..of
    // eslint-disable-next-line
    for (const item of sortedFolderingItems) {
      treeNode.items.push(item);
    }

    const sortedChildren = compareItems
      ? childrenItems.sort(compareItems)
      : childrenItems;

    const minValues = [sortedChildren[0], sortedFolderingNodes[0]?.[1]].sort(
      compareItems,
    );
    // because of for..of
    // eslint-disable-next-line
    for (const childItem of sortedChildren) {
      treeNode.items.push({
        id: childItem.id,
        items: [],
        content: childItem.data?.title || childItem.id,
        searchableText: childItem.data?.title || childItem.id,
        selected:
          treeNode.selected || _.includes(selectedNodeIds, childItem.id),
      });
    }

    return { treeNode, sortValue: minValues[0] };
  };
  return fillNode(foldering, tree, '');
};

const ProjectTreeDropdownProvider = ({ children, onSelectedItemsChange }) => {
  const clientId = useClientId();
  const { user } = useCurrentUser();
  const displayPreferences = useProjectDropdownPreferences({
    selectorId: 'overview-project-selector',
    defaultValues: {
      groupingOptions: {
        groupBy: [
          {
            key: 'author',
            group: 'author',
          },
        ],
      },
      selectedItemIds: [user.email],
    },
  });
  const {
    sortKey: selectedSortKey,
    filterKey: selectedFilterKey,
    groupKey: selectedGroupKey,
    updateSortKey: setSelectedSortKey,
    updateGroupKey: setSelectedGroupKey,
    updateFilterKey: setSelectedFilterKey,
    selectedItemIds: selectedNodeIdsFromPreferences,
    updateSelectedItems,
    loading: displayPreferencesLoading,
  } = displayPreferences;
  const { data: usersData, loading: usersLoading } = useClientUsers(clientId);

  const users = useMemo(
    () =>
      _.map(
        usersData?.client?.users || [],
        ({ firstname, lastname, email }) => ({
          firstname,
          lastname,
          email,
        }),
      ),
    [usersData],
  );

  const [tree, setTree] = useState<TreeItemType>({
    id: 'root',
    title: '',
    items: [],
    selected: true,
  });
  const [init, setInit] = useState<boolean>(false);

  const { jobOptions, jobOptionsLoading } = useSearchPoolJobOptions('reveal');
  const {
    data: departments,
    loading: departmentsLoading,
  } = useClientDepartmentsQuery(clientId);

  // const nameFilteredJobOptions: ISearchPoolJobOption[] = useMemo(() => {
  //   if (!_.isEmpty(searchText)) {
  //     return _.filter(
  //       jobOptions,
  //       (jobOption) => (jobOption.data?.title || '').indexOf(searchText) >= 0,
  //     );
  //   }
  //   return jobOptions;
  // }, [jobOptions, searchText]);

  const filteredJobs: ISearchPoolJobOption[] = useMemo(() => {
    if (!user) {
      return [];
    }
    if (selectedFilterKey === 'created-by-me') {
      return _.filter(
        jobOptions,
        (jobOption) => jobOption.owner?.email === user.email,
      );
    }
    return jobOptions;
  }, [jobOptions, user, selectedFilterKey]);

  const leafsMap: Dictionary<string | boolean> = useMemo(
    () => _.object(_.map(filteredJobs, (job) => [job.id, true])),
    [filteredJobs],
  );

  const { selectedItemIds } = useMemo(() => {
    const treeData = generateSelectedItemsFromTree(
      tree,
      false, // _.isEmpty(selectedNodeIdsFromPreferences),
      leafsMap,
      false,
    );
    onSelectedItemsChange(treeData.selectedItemIds);
    return treeData;
    // TODO: Fix dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tree, leafsMap, onSelectedItemsChange]);

  const selectedNodeIds = useMemo(() => {
    return generateSelectedItemsFromTopLevelSelection(
      tree,
      false,
      selectedNodeIdsFromPreferences,
    );
  }, [tree, selectedNodeIdsFromPreferences]);

  const handleTreeUpdate = useCallback(
    (newTree) => {
      setTree(newTree);
      const {
        selectedNodeIds: newSelectedNodeIds,
      } = generateSelectedItemsFromTree(newTree, false, leafsMap, true);
      updateSelectedItems(newSelectedNodeIds);
    },
    [leafsMap, updateSelectedItems],
  );

  const onGroupOrSortChange = useCallback(
    ({
      groupId,
      sortKey,
      currentSelectedNodeIds = selectedNodeIds,
      forceSelection = false,
    }: {
      groupId: string;
      sortKey: string;
      currentSelectedNodeIds?: string[];
      forceSelection?: boolean;
    }) => {
      const compareFunction = compareFunctionMap[sortKey];
      if (groupId === 'departments') {
        if (departmentsLoading || _.isEmpty(departments)) {
          return;
        }
        setTree(
          getCategorizedItem(
            filteredJobs,
            getByDepartmentCategorizer(departments),
            compareFunction,
            currentSelectedNodeIds,
            forceSelection,
          ).treeNode,
        );
        return;
      }
      if (groupId === 'author') {
        if (usersLoading || _.isEmpty(users)) {
          return;
        }
        setTree(
          getCategorizedItem(
            filteredJobs,
            getByAuthorCategorizer(users),
            compareFunction,
            currentSelectedNodeIds,
            forceSelection,
          ).treeNode,
        );
        return;
      }
      if (groupId === 'not-grouped') {
        setTree(
          getCategorizedItem(
            filteredJobs,
            getFlatCategorizer(),
            compareFunction,
            currentSelectedNodeIds,
            forceSelection,
          ).treeNode,
        );
      }
    },
    [
      departments,
      departmentsLoading,
      filteredJobs,
      selectedNodeIds,
      users,
      usersLoading,
    ],
  );

  useEffect(() => {
    if (init) {
      return;
    }
    if (
      departmentsLoading ||
      jobOptionsLoading ||
      usersLoading ||
      displayPreferencesLoading ||
      _.isEmpty(filteredJobs)
    ) {
      // maybe also wait for displayPrefs to be loaded
      return;
    }
    setInit(true);
    onGroupOrSortChange({
      groupId: selectedGroupKey,
      sortKey: selectedSortKey,
      currentSelectedNodeIds: selectedNodeIdsFromPreferences,
      forceSelection: _.isEmpty(selectedNodeIdsFromPreferences),
    });
    // Careful with dependencies! We disabled it to be able to use onGroupOrSortChange.
    // onGroupOrSortChange not in dependency array to prevent infinite re-render
    // eslint-disable-next-line
  }, [
    jobOptionsLoading,
    departmentsLoading,
    filteredJobs,
    selectedGroupKey,
    selectedSortKey,
    selectedNodeIdsFromPreferences,
    usersLoading,
    displayPreferencesLoading,
  ]);

  useEffect(() => {
    if (!init) {
      return;
    }
    onGroupOrSortChange({
      groupId: selectedGroupKey,
      sortKey: selectedSortKey,
      currentSelectedNodeIds: selectedNodeIds,
      forceSelection: false, // _.isEmpty(selectedNodeIdsFromPreferences),
    });
    // eslint-disable-next-line
  }, [filteredJobs]);

  const onGroupChange = useCallback(
    (groupKey) => {
      setSelectedGroupKey(groupKey);
      onGroupOrSortChange({
        groupId: groupKey,
        sortKey: selectedSortKey,
      });
    },
    [onGroupOrSortChange, selectedSortKey, setSelectedGroupKey],
  );

  const onSortChange = useCallback(
    (sortKey) => {
      setSelectedSortKey(sortKey);
      onGroupOrSortChange({
        groupId: selectedGroupKey,
        sortKey,
      });
    },
    [onGroupOrSortChange, selectedGroupKey, setSelectedSortKey],
  );

  const onFilterChange = useCallback(
    (filterKey) => {
      setSelectedFilterKey(filterKey);
    },
    [setSelectedFilterKey],
  );

  return (
    <ProjectTreeDropdownContext.Provider
      value={{
        loading:
          jobOptionsLoading ||
          departmentsLoading ||
          usersLoading ||
          _.isEmpty(selectedNodeIds),
        selectedNodeIds,
        selectedItemIds,
        onUpdateTree: handleTreeUpdate,
        tree,
        onGroupChange,
        onSortChange,
        onFilterChange,
        selectedSortKey,
        selectedFilterKey,
        selectedGroupKey,
        items: filteredJobs,
        totalItemCount: jobOptions?.length || 0,
      }}
    >
      {children}
    </ProjectTreeDropdownContext.Provider>
  );
};

export default ProjectTreeDropdownProvider;
