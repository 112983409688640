import { QueryHookOptions, useQuery } from '@apollo/client';
import { CLIENT_SEQUENCES } from '@/containers/Parameters/Sequences/queries';
import { Sequence } from '@/types/sequence';

export interface ClientSequencesData {
  client: {
    id: string;
    sequences: Sequence[];
  };
}

const useClientSequences = ({
  clientId,
  queryOptions = {},
}: {
  clientId: string;
  queryOptions?: QueryHookOptions;
}) =>
  useQuery(CLIENT_SEQUENCES, {
    variables: {
      clientId,
      filters: { activeOnly: true },
    },
    ...queryOptions,
  });

export default useClientSequences;
