import React, { FC } from 'react';
import { Loader } from 'semantic-ui-react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import useAggregatedContacts from '@/graphql/hooks/clients/useAggregatedContacts';
import useClientId from '@/hooks/router/useClientId';
import InfoTooltip from '@/components/Common/InfoTooltip';
import { isDefinedGender } from '@/common/helpers/gender';
import { GenderRecord, GENDER_STACK, getInitialGenderRecord } from '../helpers';
import PieChart from '../../../components/PieChart';

import styles from './GenderRepartition.module.less';

const GenderRepartition: FC = () => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { loading, data: clientData } = useAggregatedContacts(clientId, [
    'gender',
  ]);

  const { aggregatedContacts } = clientData?.client.statistics || {};

  const numbers: GenderRecord = _.reduce(
    aggregatedContacts || [],
    (accumulator, { categories, count }) => {
      const gender = categories[0].categoryId;
      if (isDefinedGender(gender)) {
        return {
          ...accumulator,
          [gender]: accumulator[gender] + count,
        };
      }
      return { ...accumulator, unknown: accumulator.unknown + count };
    },
    getInitialGenderRecord(),
  );

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <h3>
          {t('reveal.reports.diversity.genderRepartition')}
          <InfoTooltip rich hoverable position='right center'>
            <h1>
              {t('reveal.reports.tooltips.diversity.genderRepartition.title')}
            </h1>
            <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
            <p>{t('reveal.reports.tooltips.common.hiresweetCount')}</p>
            <h2>
              {t('reveal.reports.tooltips.diversity.common.genderLabeling')}
            </h2>
            <p>
              {t(
                'reveal.reports.tooltips.diversity.common.genderLabelingParagraph',
              )}
            </p>
          </InfoTooltip>
        </h3>
      </div>
      <div className={styles.cardBody}>
        {loading ? (
          <div className={styles.loader}>
            <Loader active inline='centered' size='large' />
          </div>
        ) : (
          <PieChart
            stack={GENDER_STACK}
            stats={{ name: 'gender', values: numbers }}
            exhaustive
          />
        )}
      </div>
    </div>
  );
};

export default GenderRepartition;
