import _ from 'underscore';
import sanitizeHtml from 'sanitize-html';

const allowedTags = [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol', 'nl', 'li', 'b', 'i',
  'strong', 'em', 'strike', 'u', 'code', 'hr', 'br', 'div', 'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td',
  'colgroup', 'pre', 'img', 'font', 'span', 'col' ];
const baseAttributes = ['dir', 'class', 'data-smartmail', 'style'];
const allowedAttributes = {
  ..._.object(_.map(allowedTags, (tag) => [tag, baseAttributes])),
  a: sanitizeHtml.defaults.allowedAttributes.a.concat(baseAttributes),
  img: ['src', 'width', 'height', 'alt', 'style'],
  font: ['face', 'size', 'color', 'style'],
  table: baseAttributes.concat(['align', 'bgcolor', 'border','cellpadding', 'cellspacing', 'width', 'valign']),
  td: baseAttributes.concat(['bgcolor', 'colspan', 'rowspan', 'width', 'align', 'valign']),
  col: baseAttributes.concat(['bgcolor', 'colspan', 'rowspan', 'width', 'align', 'valign'])
};
const options = {
  allowedAttributes,
  allowedTags,
  allowedSchemes: ['http', 'https', 'mailto'],
};

export default (signature) => {
  return sanitizeHtml(signature, options);
};
