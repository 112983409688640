import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Attachment from '../../graphql/fragments/Attachment';

const uploadProfileAttachment = gql`
  mutation uploadProfileAttachment($id: ID!, $attachment: AttachmentInput!) {
    uploadProfileAttachment(id: $id, attachment: $attachment) {
      id
      attachments {
        ...Attachment
      }
    }
  }
  ${Attachment}
`;

export default graphql(uploadProfileAttachment, {
  props: ({ mutate }) => ({
    uploadProfileAttachment: ({ profileId, attachment }) =>
      mutate({ variables: { attachment, id: profileId } }),
  }),
});
