import gql from 'graphql-tag';
import {
  MutationHookOptions,
  useMutation,
  MutationResult,
  FetchResult,
} from '@apollo/client';

const ASK_REACTIVATION = gql`
  mutation askReactivation {
    askReactivation {
      id
    }
  }
`;

type AskReactivationVariables = Record<string, never>;

type AskReactivationData = {
  askReactivation: {
    id: string;
  };
};

type UseAskReactivationInput = {
  mutationOptions?: MutationHookOptions<
    AskReactivationData,
    AskReactivationVariables
  >;
};

type UseAskReactivationResult = [
  () => Promise<FetchResult<AskReactivationData>>,
  MutationResult<AskReactivationData>,
];

const useAskReactivation = ({
  mutationOptions,
}: UseAskReactivationInput = {}): UseAskReactivationResult =>
  useMutation<AskReactivationData, AskReactivationVariables>(
    ASK_REACTIVATION,
    mutationOptions,
  );

export default useAskReactivation;
