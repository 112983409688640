import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { EvaluationFormTemplate } from '../../graphql/fragments/Evaluations';

export const updateEvaluationFormTemplate = gql`
  mutation updateEvaluationFormTemplate(
    $evaluationFormTemplateId: ID!
    $evaluationFormTemplate: EvaluationFormTemplateInput!
  ) {
    updateEvaluationFormTemplate(
      evaluationFormTemplateId: $evaluationFormTemplateId
      evaluationFormTemplate: $evaluationFormTemplate
    ) {
      id
      evaluationFormTemplates {
        ...EvaluationFormTemplate
      }
    }
  }
  ${EvaluationFormTemplate}
`;

export default graphql(updateEvaluationFormTemplate, {
  props: ({ mutate }) => ({
    updateEvaluationFormTemplate: ({
      evaluationFormTemplateId,
      evaluationFormTemplate,
    }) =>
      mutate({
        variables: {
          evaluationFormTemplateId,
          evaluationFormTemplate,
        },
      }),
  }),
});
