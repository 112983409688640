import React from 'react';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import AchievementBadge from './AchievementBadge';

const AchievementRow = ({ clientId, mainEvent, t }) => {
  if (_.isEmpty(mainEvent)) {
    return null;
  }

  const { type, subtype, count } = mainEvent || {};

  const { title, id } = mainEvent?.offer || {};

  const jobValue = title ? (
    <Link to={`/client/${clientId}/jobs/${id}/profiles`}>{title}</Link>
  ) : (
    t('analytics.nanValue')
  );

  return (
    <Table.Row>
      <Table.Cell collapsing>{t('analytics.dateFormats.day', { date: new Date(mainEvent.date) })}</Table.Cell>
      <Table.Cell>
        {t(`analytics.achievements.${type}.${subtype}`, { count })}
        {type === 'offer-achievement' && jobValue}
      </Table.Cell>
      <Table.Cell collapsing>
        <AchievementBadge {...mainEvent} />
      </Table.Cell>
    </Table.Row>
  );
};

export default AchievementRow;
