import React from 'react';
import { compose } from 'underscore';
import { Redirect } from 'react-router-dom';

import routerParamsToProps from '../hocs/routerParamsToProps';
import { decodeToken } from '../common';

import ResetPasswordForm from '../containers/Auth/ResetPasswordForm';

class ResetPasswordView extends React.PureComponent {
  render() {
    const { clientId } = this.props;

    const token = localStorage.getItem('token');
    const decodedJWT = token ? decodeToken(token) : token;

    if (decodedJWT && decodedJWT.clientId === clientId) {
      return <Redirect to={`/client/${clientId}/dashboard`} />;
    }

    return (
      <div>
        <ResetPasswordForm
          clientId={clientId}
          redirectTo={`/client/${clientId}/dashboard`}
        />
      </div>
    );
  }
}

export default compose(routerParamsToProps)(ResetPasswordView);
