import { AbsoluteDropdownYPosition, AbsoluteDropdownXPosition } from './types';

export const getYPosition = (
  preferedYPosition: AbsoluteDropdownYPosition,
  containerRect?: ClientRect,
  contentRect?: ClientRect,
): AbsoluteDropdownYPosition => {
  if (!containerRect || !contentRect) return preferedYPosition;

  const contentHeight = contentRect.height;
  const containerBottom = containerRect.bottom;
  const containerTop = containerRect.top;

  const tooHigh = containerTop < contentHeight + 8;
  const tooLow =
    containerBottom + contentHeight + 8 >= document.body.clientHeight;

  if ((tooHigh && tooLow) || (!tooHigh && !tooLow)) {
    return preferedYPosition;
  }

  return tooHigh ? 'bottom' : 'top';
};

export const getXPosition = (
  preferedXPosition: AbsoluteDropdownXPosition,
  containerRect?: ClientRect,
  contentRect?: ClientRect,
): AbsoluteDropdownXPosition => {
  if (!containerRect || !contentRect) return preferedXPosition;

  const contentWidth = contentRect.width;
  const containerLeft = containerRect.left;
  const containerRight = containerRect.right;

  const tooLeft = containerRight < contentWidth;
  const tooRight = containerLeft + contentWidth >= document.body.clientWidth;

  if ((tooLeft && tooRight) || (!tooLeft && !tooRight)) {
    return preferedXPosition;
  }

  return tooLeft ? 'left' : 'right';
};

export const getTop = (
  yPosition: AbsoluteDropdownYPosition,
  containerRect?: ClientRect,
  contentRect?: ClientRect,
): number =>
  yPosition === 'top'
    ? window.scrollY +
      (containerRect?.top || 0) -
      (contentRect?.height || 0) -
      8
    : window.scrollY +
      (containerRect?.top || 0) +
      (containerRect?.height || 0) +
      8;

export const getLeft = (
  xPosition: AbsoluteDropdownXPosition,
  containerRect?: ClientRect,
  contentRect?: ClientRect,
): number =>
  xPosition === 'left'
    ? window.scrollX + (containerRect?.left || 0)
    : window.scrollX + (containerRect?.right || 0) - (contentRect?.width || 0);
