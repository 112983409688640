import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { AttachmentInput } from '@/types/attachment';

interface ParseResumeFunction {
  (resume: AttachmentInput): Promise<{
    data: {
      firstname?: string;
      lastname?: string;
      email?: string;
      sources?: {
        linkedin?: string;
        github?: string;
      };
    } | null;
  }>;
}

const PARSE_RESUME_QUERY = gql`
  query parseResume($resume: AttachmentInput!) {
    parseResume(resume: $resume) {
      firstname
      lastname
      email
      phoneNumber
      sources {
        linkedin
        github
      }
    }
  }
`;

const useParseResume = (): ParseResumeFunction => {
  const { refetch: parseResume } = useQuery(PARSE_RESUME_QUERY, {
    skip: true,
    fetchPolicy: 'network-only',
  });

  return async (resume) => {
    try {
      const { data } = await parseResume({ resume });
      return { data: data?.parseResume };
    } catch (e) {
      console.error('Parse Resume failed');
      console.error(e);
    }
    return { data: null };
  };
};

export default useParseResume;
