import { QueryResult, useQuery } from '@apollo/client';
import { CategorizationType } from '@/types/statistics/category';
import { GET_CATEGORY_COHORTS } from '@/graphql/statistics';

interface CategoryCohortsResult {
  client: {
    id: string;
    statistics: {
      categoryCohorts: {
        day: string;
        values: {
          categoryId: string;
          count: number;
        }[];
      }[];
    };
  };
}

interface CategoryCohortsVariables {
  clientId?: string;
  categorization: {
    type: CategorizationType;
  };
}

const useCategoryCohorts = (
  clientId: string,
  categorizationType: CategorizationType,
): QueryResult<CategoryCohortsResult, CategoryCohortsVariables> =>
  useQuery(GET_CATEGORY_COHORTS, {
    variables: {
      clientId,
      categorization: { type: categorizationType },
    } as CategoryCohortsVariables,
    fetchPolicy: 'network-only',
  });

export default useCategoryCohorts;
