import _ from 'underscore';
import React from 'react';
import { Divider } from 'semantic-ui-react';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { ATS_NAMES_BY_TYPE } from '@/common/reveal';
import { getATSDataItems } from '@/common/reveal/SourceData';

import './ProfileTimeline.css';

import {
  SourceData,
  AtsData,
  Application,
  ScorecardInActivityFeed,
  ActivityFeedItem,
} from '@/common/reveal/SourceData.type';
import InfoTooltip from '@/components/Common/InfoTooltip';
import { capitalizeText } from '../../../../common';
import {
  GreenhouseScorecardAttribute,
  GreenhouseScorecardQuestion,
} from '../../../../graphql/fragments/Scorecard';

interface ProfileTimelineProps {
  sourceData?: SourceData;
  t: TFunction;
}

const ProfileTimeline: React.FC<ProfileTimelineProps> = ({ sourceData, t }) => {
  const atsDataItems = getATSDataItems(sourceData);

  const areAllActivityFeedsEmpty = _.isEmpty(
    _.flatten(
      _.map(atsDataItems, (atsDataItem) => atsDataItem.activityFeed || []),
    ),
  );

  if (areAllActivityFeedsEmpty) {
    return (
      <div className='profile-timeline'>
        <img
          className='empty-activity'
          src='/images/placeholders/jobs.svg'
          width='80%'
          alt='jobs-placeholder'
        />
      </div>
    );
  }
  return (
    <div className='generic-ats-timelines-container'>
      {_.map(atsDataItems, (atsDataItem, index) => (
        <div key={index} className='generic-ats-timeline-section'>
          {atsDataItems.length > 1 && (
            <div>
              <br />
              {index > 0 && <Divider />}
              <h2>{ATS_NAMES_BY_TYPE[atsDataItem.type] || atsDataItem.type}</h2>
            </div>
          )}
          <div className='profile-timeline'>
            {_.map(
              getActivityFeedWithScoreCards({ atsDataItem }),
              (activityFeedItem) => {
                if (activityFeedItem?.type === 'scorecard') {
                  return (
                    <ScorecardActivity
                      key={activityFeedItem?.sourceScorecardId}
                      scorecard={activityFeedItem}
                      t={t}
                    />
                  );
                }
                if (activityFeedItem?.type === 'email') {
                  return (
                    <EmailActivity
                      key={activityFeedItem?.sourceActivityId}
                      activity={activityFeedItem}
                      t={t}
                    />
                  );
                }
                if (activityFeedItem?.type === 'activity') {
                  return (
                    <BasicActivity
                      key={activityFeedItem?.sourceActivityId}
                      activity={activityFeedItem}
                      t={t}
                    />
                  );
                }
                if (activityFeedItem?.type === 'note') {
                  return (
                    <NoteActivity
                      key={activityFeedItem?.sourceActivityId}
                      activity={activityFeedItem}
                      t={t}
                    />
                  );
                }
                if (activityFeedItem?.type === 'linkedin-note') {
                  return (
                    <LinkedinNoteActivity
                      key={activityFeedItem?.sourceActivityId}
                      activity={activityFeedItem}
                      t={t}
                    />
                  );
                }
                if (activityFeedItem?.type === 'linkedin-inmail') {
                  return (
                    <LinkedinInMailActivity
                      key={activityFeedItem?.sourceActivityId}
                      activity={activityFeedItem}
                      t={t}
                    />
                  );
                }
                return null;
              },
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export const getActivityFeedWithScoreCards = ({
  atsDataItem,
}: {
  atsDataItem: AtsData;
}) => {
  const allScorecards = getAllScorecards({
    applications: atsDataItem?.applications,
  });
  return _.sortBy(
    [
      ..._.map(atsDataItem?.activityFeed || [], (item) => {
        return { ...item, ats: atsDataItem?.type };
      }),
      ...(allScorecards || []),
    ],
    'creationDate',
  ).reverse();
};

const getAllScorecards = ({
  applications,
}: {
  applications: Array<Application> | undefined;
}): Array<ScorecardInActivityFeed> => {
  if (_.isUndefined(applications) || _.isEmpty(applications)) {
    return [];
  }
  return _.map(
    _.compact(_.flatten(_.pluck(applications, 'scorecards'))),
    (scorecard): ScorecardInActivityFeed => ({
      ...scorecard,
      type: 'scorecard',
    }),
  );
};

interface ScorecardProps {
  scorecard: ScorecardInActivityFeed;
  t: TFunction;
}

export const ScorecardActivity: React.FC<ScorecardProps> = ({
  scorecard,
  t,
}) => {
  if (!scorecard) {
    return null;
  }
  return (
    <div className='activity scorecard-activity'>
      <div className='first-line'>
        <div>
          <span className='label'>Scorecard</span>
          {(scorecard.submittedBy?.firstname ||
            scorecard.submittedBy?.lastname) && (
            <span className='value'>
              {' '}
              submitted by{' '}
              {`${scorecard.submittedBy?.firstname} ${scorecard.submittedBy?.lastname}`}
            </span>
          )}
        </div>
        <div className='date'>
          {t('common.shortDateAndTime', {
            date: new Date(scorecard.creationDate),
          })}
        </div>
      </div>
      <OverallRecommendation
        overallRecommendation={scorecard?.overallRecommendation}
        t={t}
      />
      <ScorecardQuestions questions={scorecard?.questions} />
      <ScorecardSummary attributes={scorecard?.attributes} />
      <div />
    </div>
  );
};

interface OverallRecommendationProps {
  overallRecommendation?: string;
  t: TFunction;
}

export const OverallRecommendation: React.FC<OverallRecommendationProps> = ({
  overallRecommendation,
  t,
}) => {
  if (!overallRecommendation || overallRecommendation === 'no-decision') {
    return (
      <div className='overall-recommendation'>
        <div className='label'>Overall recommendation</div>
        <div className='overall-recommendation-content'>
          {t('profile.scorecard.ratings.no-decision')}
        </div>
      </div>
    );
  }

  return (
    <div className='overall-recommendation'>
      <div className='label'>Overall recommendation</div>
      <div className='overall-recommendation-content'>
        <RatingIndicator rating={overallRecommendation} />
        {t(`profile.scorecard.ratings.${overallRecommendation}`)}
      </div>
    </div>
  );
};

interface ScorecardQuestionsProps {
  questions?: Array<GreenhouseScorecardQuestion>;
}

export const ScorecardQuestions: React.FC<ScorecardQuestionsProps> = ({
  questions,
}) => {
  if (!questions || _.isEmpty(questions)) {
    return null;
  }

  return (
    <div className='questions'>
      {_.map(questions, (question, index) => {
        if (!question?.answer || !question?.question) {
          return null;
        }
        return (
          <div key={index} className='question'>
            <div className='label'>{question?.question}</div>
            <div className='value'>{question?.answer}</div>
          </div>
        );
      })}
    </div>
  );
};

interface ScorecardSummaryProps {
  attributes?: Array<GreenhouseScorecardAttribute>;
}

export const ScorecardSummary: React.FC<ScorecardSummaryProps> = ({
  attributes,
}) => {
  if (_.isUndefined(attributes) || _.isEmpty(attributes)) {
    return null;
  }

  const attributesByRating = getAttributesByRating({ attributes });

  return (
    <div className='scorecard-summary'>
      <div className='label'>Scorecard summary</div>
      {_.map(attributesByRating, (ratingAttributes, rating) => {
        if (_.isEmpty(ratingAttributes)) {
          return null;
        }
        return (
          <div key={rating} className='scorecard-attributes'>
            <RatingIndicator rating={rating} />
            <div className='value'>
              {_.map(ratingAttributes, (ratingAttribute, index) => (
                <Attribute
                  key={index}
                  attribute={ratingAttribute}
                  isLast={index === ratingAttributes.length - 1}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

interface AttributeProps {
  attribute?: GreenhouseScorecardAttribute;
  isLast: boolean;
}

export const Attribute: React.FC<AttributeProps> = ({ attribute, isLast }) => {
  if (!attribute) {
    return null;
  }
  return (
    <span>
      {capitalizeText(attribute?.name)}{' '}
      {attribute?.note && <InfoTooltip>{attribute.note}</InfoTooltip>}
      {!isLast && ', '}
    </span>
  );
};

interface RatingIndicatorProps {
  rating: string;
}

export const RatingIndicator: React.FC<RatingIndicatorProps> = ({ rating }) => {
  if (rating === 'strong-yes') {
    return (
      <div className={`rating-indicator ${rating}`}>
        <i className='ri-star-fill' />
      </div>
    );
  }
  if (rating === 'yes') {
    return (
      <div className={`rating-indicator ${rating}`}>
        <i className='ri-thumb-up-line' />
      </div>
    );
  }
  if (rating === 'mixed') {
    return (
      <div className={`rating-indicator ${rating}`}>
        <i className='ri-indeterminate-circle-line' />
      </div>
    );
  }
  if (rating === 'no') {
    return (
      <div className={`rating-indicator ${rating}`}>
        <i className='ri-thumb-down-line' />
      </div>
    );
  }
  if (rating === 'definitely-not') {
    return (
      <div className={`rating-indicator ${rating}`}>
        <i className='ri-spam-2-fill' />
      </div>
    );
  }
  if (rating === 'no-decision') {
    return (
      <div className={`rating-indicator ${rating}`}>
        <i className='ri-question-fill' />
      </div>
    );
  }
  return <div>{rating}</div>;
};

const getAttributesByRating = ({
  attributes,
}: {
  attributes: Array<GreenhouseScorecardAttribute>;
}) => {
  const attributesByRating = _.reduce(
    attributes,
    (
      memo: { [key: string]: Array<GreenhouseScorecardAttribute> },
      attribute,
    ) => {
      if (_.isUndefined(attribute?.rating)) {
        return memo;
      }
      return {
        ...memo,
        [attribute?.rating]: [...(memo[attribute?.rating] || []), attribute],
      };
    },
    {
      'strong-yes': [],
      yes: [],
      mixed: [],
      no: [],
      'definitely-not': [],
      'no-decision': [],
    },
  );
  return attributesByRating;
};
interface EmailActivityProps {
  activity: ActivityFeedItem;
  t: TFunction;
}

export const EmailActivity: React.FC<EmailActivityProps> = ({
  activity,
  t,
}) => {
  return (
    <div className='activity email-activity'>
      <div className='first-line'>
        <div>
          <span className='label'>Email</span>
        </div>
        <div className='date'>
          {t('common.shortDateAndTime', {
            date: new Date(activity.creationDate),
          })}
        </div>
      </div>
      {activity?.from && (
        <div>
          <span className='label'>From:</span>
          <span className='value'> {activity?.from}</span>
        </div>
      )}
      {activity?.to && (
        <div>
          <span className='label'>To:</span>
          <span className='value'> {activity?.to}</span>
        </div>
      )}
      {activity?.subject && (
        <div>
          <span className='label'>Subject:</span>
          <span className='value'> {activity?.subject}</span>
        </div>
      )}
      <div className='body'>{activity?.body}</div>
    </div>
  );
};

interface BasicActivityProps {
  activity: ActivityFeedItem;
  t: TFunction;
}

export const BasicActivity: React.FC<BasicActivityProps> = ({
  activity,
  t,
}) => {
  return (
    <div className='activity basic-activity'>
      <div className='first-line'>
        <div>
          <span className='label'>Activity</span>
        </div>
        <div className='date'>
          {t('common.shortDateAndTime', {
            date: new Date(activity.creationDate),
          })}
        </div>
      </div>
      <div className='body'>{activity?.body}</div>
    </div>
  );
};

interface NoteActivityProps {
  activity: ActivityFeedItem;
  t: TFunction;
}

export const NoteActivity: React.FC<NoteActivityProps> = ({ activity, t }) => {
  const user = activity?.user;
  const displayableUser =
    (user?.firstname || user?.lastname) &&
    `${user?.firstname} ${user?.lastname}`;
  return (
    <div className='activity note-activity'>
      <div className='first-line'>
        <div>
          <span className='label'>Note</span>
          {displayableUser && (
            <span className='value'> from {displayableUser}</span>
          )}
        </div>
        <div className='date'>
          {t('common.shortDateAndTime', {
            date: new Date(activity.creationDate),
          })}
        </div>
      </div>
      <div className='body'>{activity?.body}</div>
    </div>
  );
};

interface LinkedinNoteActivityProps {
  activity: ActivityFeedItem;
  t: TFunction;
}

export const LinkedinNoteActivity: React.FC<LinkedinNoteActivityProps> = ({
  activity,
  t,
}) => {
  return (
    <div className='activity linkedin-note-activity'>
      <div className='first-line'>
        <div>
          <span className='label'>Linkedin Note</span>
          {activity.from && (
            <span className='value'> from {activity.from}</span>
          )}
        </div>
        <div className='date'>
          {t('common.shortDateAndTime', {
            date: new Date(activity.creationDate),
          })}
        </div>
      </div>
      <div className='body'>{activity?.body}</div>
    </div>
  );
};

interface LinkedinInMailActivityProps {
  activity: ActivityFeedItem;
  t: TFunction;
}

export const LinkedinInMailActivity: React.FC<LinkedinInMailActivityProps> = ({
  activity,
  t,
}) => {
  return (
    <div className='activity linkedin-inmail-activity'>
      <div className='first-line'>
        <div>
          <span className='label'>Linkedin InMail</span>
          {activity.from && (
            <span className='value'> from {activity.from}</span>
          )}
          {!activity.from && activity.to && (
            <span className='value'> to {activity.to}</span>
          )}
        </div>
        <div className='date'>
          {t('common.shortDateAndTime', {
            date: new Date(activity.creationDate),
          })}
        </div>
      </div>
      <div className='body'>{activity?.body}</div>
    </div>
  );
};

export default withTranslation('translations')(ProfileTimeline);
