import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { CategorizationType } from '@/types/statistics/category';
import { PipelineCountDataPoint } from '@/types/statistics/pipeline';
import { isTodayYyyyMmDd } from '@/common/utils/time';
import { GenericStatsFilterInput } from './common';

const GET_PIPELINE_STATISTICS_BY_CATEGORY = gql`
  query getPipelineStatisticsByCategory(
    $clientId: ID!
    $categorization: CategorizationInput!
    $categoriesFilter: GenericStatsFilterInput
    $missionsFilter: GenericStatsFilterInput
    $stagesFilter: GenericStatsFilterInput
    $archivedStatusesFilter: GenericStatsFilterInput
    $startDate: Date
    $endDate: Date
  ) {
    client(id: $clientId) {
      id
      statistics {
        volumeByPipelineSplitByCategory(
          categorization: $categorization
          categoriesFilter: $categoriesFilter
          missionsFilter: $missionsFilter
          stagesFilter: $stagesFilter
          archivedStatusesFilter: $archivedStatusesFilter
          startDate: $startDate
          endDate: $endDate
        ) {
          missionId
          categoryId
          categoryTitle {
            default
            fr
            en
          }
          data {
            archived
            state
            count
          }
        }
      }
    }
  }
`;

interface VolumeByPipelineForCategoryAndMission {
  missionId: string;
  categoryId: string;
  categoryTitle?: {
    default: string;
    fr: string;
    en: string;
  };
  data: PipelineCountDataPoint[];
}

interface GetPipelineStatisticsByCategoryData {
  client: {
    id: string;
    statistics: {
      volumeByPipelineSplitByCategory: VolumeByPipelineForCategoryAndMission[];
    };
  };
}

interface GetPipelineStatisticsByCategoryVariables {
  clientId: string;
  categorization: {
    type: CategorizationType;
  };
  categoriesFilter?: GenericStatsFilterInput;
  missionsFilter?: GenericStatsFilterInput;
  stagesFilter?: GenericStatsFilterInput;
  archivedStatusesFilter?: GenericStatsFilterInput;
  startDate?: string;
  endDate?: string;
}

interface UsePipelineStatisticsByCategoryInput
  extends GetPipelineStatisticsByCategoryVariables {
  queryOptions?: QueryHookOptions<
    GetPipelineStatisticsByCategoryData,
    GetPipelineStatisticsByCategoryVariables
  >;
}

interface UsePipelineStatisticsByCategoryResult
  extends QueryResult<
    GetPipelineStatisticsByCategoryData,
    GetPipelineStatisticsByCategoryVariables
  > {
  volumeByPipelineSplitByCategory?: VolumeByPipelineForCategoryAndMission[];
}

const usePipelineStatisticsByCategory = ({
  clientId,
  categorization,
  categoriesFilter,
  missionsFilter,
  stagesFilter,
  archivedStatusesFilter,
  startDate,
  endDate,
  queryOptions = {},
}: UsePipelineStatisticsByCategoryInput): UsePipelineStatisticsByCategoryResult => {
  const query = useQuery<
    GetPipelineStatisticsByCategoryData,
    GetPipelineStatisticsByCategoryVariables
  >(GET_PIPELINE_STATISTICS_BY_CATEGORY, {
    ...queryOptions,
    variables: {
      clientId,
      categorization,
      categoriesFilter,
      missionsFilter,
      stagesFilter,
      archivedStatusesFilter,
      startDate,
      endDate: isTodayYyyyMmDd(endDate) ? undefined : endDate,
    },
  });

  const { volumeByPipelineSplitByCategory } =
    query.data?.client.statistics || {};

  return { ...query, volumeByPipelineSplitByCategory };
};

export default usePipelineStatisticsByCategory;
