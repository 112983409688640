import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import { PipeStepStatItem } from '@/types/pipeStepStatItem';
import PipeStepStat from '@/graphql/fragments/PipeStepStat';

const GET_OFFER_PIPE_STEP_STATS = gql`
  query getOfferPipeStepStats($offerId: ID!) {
    offer(id: $offerId) {
      id
      pipeStepStats {
        ...PipeStepStat
      }
    }
  }
  ${PipeStepStat}
`;

interface GetOfferPipeStepStatsData {
  offer: {
    id: string;
    pipeStepStats: PipeStepStatItem[];
  };
}

interface GetOfferPipeStepStatsVariables {
  offerId: string;
}

interface UseOfferPipeStepStatsResult
  extends QueryResult<
    GetOfferPipeStepStatsData,
    GetOfferPipeStepStatsVariables
  > {
  pipeStepStats: PipeStepStatItem[];
}

interface UseOfferPipeStepStatsInput extends GetOfferPipeStepStatsVariables {
  queryOptions?: QueryHookOptions<
    GetOfferPipeStepStatsData,
    GetOfferPipeStepStatsVariables
  >;
}

const useOfferPipeStepStats = ({
  offerId,
  queryOptions = {},
}: UseOfferPipeStepStatsInput): UseOfferPipeStepStatsResult => {
  const query = useQuery<
    GetOfferPipeStepStatsData,
    GetOfferPipeStepStatsVariables
  >(GET_OFFER_PIPE_STEP_STATS, {
    ...queryOptions,
    variables: {
      offerId,
    },
  });

  const pipeStepStats = query.data?.offer.pipeStepStats || [];

  return { ...query, pipeStepStats };
};

export default useOfferPipeStepStats;
