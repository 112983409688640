import gql from 'graphql-tag';
import {
  FetchResult,
  MutationHookOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

const FOLLOW_JOB = gql`
  mutation followJob($id: ID!) {
    followJob(id: $id) {
      id
      owner {
        email
      }
      hiringManager {
        email
      }
      followers {
        email
      }
    }
  }
`;

interface FollowJobData {
  id: string;
  owner?: {
    email: string;
  };
  hiringManager?: {
    email: string;
  };
  followers?: {
    email: string;
  }[];
}

interface FollowJobVariables {
  id: string;
}

interface UseFollowJobInput {
  mutationOptions?: MutationHookOptions<FollowJobData, FollowJobVariables>;
}

type UseFollowJobResult = [
  (id: string) => Promise<FetchResult<FollowJobData>>,
  MutationResult<FollowJobData>,
];

const useFollowJob = ({
  mutationOptions = {},
}: UseFollowJobInput = {}): UseFollowJobResult => {
  const [mutation, result] = useMutation<FollowJobData, FollowJobVariables>(
    FOLLOW_JOB,
    {
      ...mutationOptions,
    },
  );

  return [(id) => mutation({ variables: { id } }), result];
};

export default useFollowJob;
