import React from 'react';

import './CampaignLayout.css';

const CampaignLayout = ({ subHeader, children }) => {
  return (
    <div className='campaign-layout'>
      <div className='campaign-layout_header'>{subHeader}</div>
      <div className='campaign-layout_frame'>
        <div className='campaign-layout_content'>{children}</div>
      </div>
    </div>
  );
};

export default CampaignLayout;
