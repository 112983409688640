import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';
import { Button, Modal, Input } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { TASK_TYPES } from '@/common/constants/taskTypes';
import { getRandomString } from '@/common';
import useSequences from '@/hooks/common/useSequences';
import getContactFlowInput from '../../../common/contactFlow/getContactFlowInput';
import FullContactFlowEditor from '../../../routes/RevealView/revealComponents/FullContactFlowEditor';
import { CLIENT_SEQUENCES, CREATE_SEQUENCE, UPDATE_SEQUENCE } from './queries';
import validateContactFlow from './validateContactFlow';
import SequenceErrorMessages from './SequenceErrorMessages';

import './NewSequenceModal.css';

const DEFAULT_SEQUENCES = [
  {
    id: `contactflow-sequence-${getRandomString(6)}`,
    position: 'first-contact',
    actions: [
      {
        type: TASK_TYPES.SEND_EMAIL_MANUAL,
        trigger: { type: 'manual-trigger' },
        // message: { templateId: value },
      },
    ],
  },
];

const NewSequenceModal = ({
  clientId,
  open,
  onClose,
  t,
  sequence,
  editionMode,
}) => {
  const [
    createSequence,
    { loading: creatingSequence, error: sequenceCreationError },
  ] = useMutation(CREATE_SEQUENCE, {
    update: (cache, { data: { sequence: sequenceMutationData } }) => {
      const data = cache.readQuery({
        query: CLIENT_SEQUENCES,
        variables: { clientId },
      });
      const newData = {
        client: {
          ...data.client,
          sequences: [
            ...(data?.client?.sequences || []),
            sequenceMutationData?.create?.sequence,
          ],
        },
      };
      cache.writeQuery({
        query: CLIENT_SEQUENCES,
        data: newData,
      });
    },
  });

  const [
    updateSequence,
    { loading: updatingSequence, error: sequenceEditionError },
  ] = useMutation(UPDATE_SEQUENCE);
  const [sequenceTitle, setSequenceTitle] = useState(
    sequence?.title || t('sequences.titlePlaceholder'),
  );
  const [sequences, setSequences] = useState(
    sequence?.contactFlow?.sequences || DEFAULT_SEQUENCES,
  );
  const [errorLevel, setErrorLevel] = useState('major');

  const contactFlow = { sequences };
  const formErrors = validateContactFlow({ contactFlow });
  const displayedErrors =
    errorLevel === 'major'
      ? _.filter(formErrors, ({ level }) => level === 'major')
      : formErrors;

  // reset sequences and errorLevel on close
  useEffect(() => {
    if (!open) {
      setSequences(sequence?.contactFlow?.sequences || DEFAULT_SEQUENCES);
      setErrorLevel('major');
    }
    // eslint-disable-next-line
  }, [open]);

  // console.log('SEQUENCE', sequences[0]);

  const [closeOnDimmerClick, setCloseOnDimmerClick] = useState(true);
  const handleUpdateSequenceTrigger = () => {};

  const {
    handleInsertAction,
    handleUpdateAction,
    handleRemoveAction,
    handleRemoveActions,
    handleMoveUpwardAction,
    handleMoveDownwardAction,
    updateAfterTemplateCreation,
  } = useSequences({
    setSequences,
    formErrors,
    setErrorLevel,
    setCloseOnDimmerClick,
    clientId,
  });

  const onSubmit = async () => {
    if (!_.isEmpty(formErrors)) {
      setErrorLevel('minor');
      return;
    }

    const contactFlowInput = getContactFlowInput({
      contactFlow,
    });
    if (editionMode) {
      await updateSequence({
        variables: {
          input: {
            id: sequence.id,
            title: sequenceTitle,
            contactFlow: contactFlowInput,
          },
        },
      });
    } else {
      await createSequence({
        variables: {
          input: { title: sequenceTitle, contactFlow: contactFlowInput },
        },
      });
    }
    if (!sequenceCreationError && !sequenceEditionError) {
      setCloseOnDimmerClick(true);
      onClose();
    }
  };

  return (
    <Modal
      className='new-sequence-modal'
      open={open}
      onClose={onClose}
      closeOnDimmerClick={closeOnDimmerClick}
      closeOnEscape={closeOnDimmerClick}
    >
      <Modal.Header>
        {editionMode
          ? t('sequences.updateSequenceHeader')
          : t('sequences.createSequenceHeader')}
      </Modal.Header>
      <Modal.Content scrolling>
        <h3 className='input-container'>
          <Input
            className='sequence-title-input'
            fluid
            placeholder={t('sequences.title')}
            value={sequenceTitle}
            onChange={(ev) => {
              setSequenceTitle(ev.target.value);
              setCloseOnDimmerClick(false);
            }}
          />
        </h3>

        <FullContactFlowEditor
          clientId={clientId}
          t={t}
          // offerId={offer.id} Might be a problem
          // onAddSequence={this.handleAddSequence}
          onUpdateSequenceTrigger={handleUpdateSequenceTrigger}
          // onRemoveSequence={handleRemoveSequence}
          onInsertAction={handleInsertAction}
          onUpdateAction={handleUpdateAction}
          onRemoveAction={handleRemoveAction}
          onRemoveActions={handleRemoveActions}
          onMoveUpward={handleMoveUpwardAction}
          onMoveDownward={handleMoveDownwardAction}
          contactFlow={contactFlow}
          updateAfterTemplateCreation={updateAfterTemplateCreation}
          // TODO (useful to warn the user when editing a template)
          // defaultTemplatesOnSeveralOffers={defaultTemplatesOnSeveralOffers}
          onQuitContactFlowEditor={onSubmit}
          formErrors={displayedErrors}
        />
      </Modal.Content>
      <Modal.Actions>
        <div className='align-left'>
          <Button className='dismiss' type='button' onClick={onClose}>
            {t('common.cancel')}
          </Button>
        </div>

        <div className='submit-action'>
          <SequenceErrorMessages errors={displayedErrors} />
          <Button
            size='big'
            primary
            disabled={!_.isEmpty(displayedErrors)}
            type='button'
            onClick={onSubmit}
            loading={creatingSequence || updatingSequence}
          >
            {editionMode
              ? t('sequences.buttons.save')
              : t('sequences.buttons.create')}
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default withTranslation('translations')(NewSequenceModal);
