export const openPopup = (url, title, popupHeight, popupWidth) => {
  const winLeft = window.screenLeft ? window.screenLeft : window.screenX;
  const winTop = window.screenTop ? window.screenTop : window.screenY;
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const left = width / 2 - popupWidth / 2 + winLeft;
  const top = height / 2 - popupHeight / 2 + winTop;
  const popup = window.open(url, title, `width=${popupWidth}, height=${popupHeight}, top=${top}, left=${left}`);
  if (popup && popup.focus) popup.focus();
  return popup;
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getHeaders = (accessToken) => {
  return {
    headers: { Authorization: `bearer ${  accessToken}` },
  };
};
