import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { UrlPreview } from '@/types/urlPreview';

const GET_URL_PREVIEW = gql`
  query getUrlPreview($url: String!) {
    urlPreview(url: $url) {
      title
      favicon
    }
  }
`;

interface GetUrlPreviewData {
  urlPreview: UrlPreview;
}

interface GetUrlPreviewVariables {
  url: string;
}

interface UseUrlPreviewInput extends GetUrlPreviewVariables {
  queryOptions?: QueryHookOptions<GetUrlPreviewData, GetUrlPreviewVariables>;
}

interface UseUrlPreviewResult
  extends QueryResult<GetUrlPreviewData, GetUrlPreviewVariables> {
  urlPreview: UrlPreview;
}

const useUrlPreview = ({
  url,
  queryOptions = {},
}: UseUrlPreviewInput): UseUrlPreviewResult => {
  const query = useQuery<GetUrlPreviewData, GetUrlPreviewVariables>(
    GET_URL_PREVIEW,
    {
      ...queryOptions,
      variables: { url },
    },
  );

  const urlPreview = query.data?.urlPreview || {};

  return { ...query, urlPreview };
};

export default useUrlPreview;
