import React, { useMemo } from 'react';
import Select, { mergeStyles, type GroupBase, type Props } from 'react-select';
import { useTranslation } from 'react-i18next';

import baseStyles from './styles';

const GenericSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  isClearable,
  noOptionsMessage,
  placeholder,
  styles,
  ...props
}: Props<Option, IsMulti, Group>) => {
  const { t } = useTranslation();

  const actualNoOptionsMessage = useMemo(
    () => noOptionsMessage || (() => t('common.noOptions')),
    [noOptionsMessage, t],
  );

  const actualPlaceholder = useMemo(
    () => placeholder || `${t('common.select')}...`,
    [placeholder, t],
  );

  return (
    <Select
      {...props}
      isClearable={isClearable || false}
      styles={mergeStyles(baseStyles, styles)}
      noOptionsMessage={actualNoOptionsMessage}
      placeholder={actualPlaceholder}
    />
  );
};

export default GenericSelect;
