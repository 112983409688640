import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { Checkbox } from 'semantic-ui-react';
import styles from './GenericToggle.module.less';

interface GenericToggleProps<AllowedOptions> {
  isChecked?: boolean;
  defaultValue?: boolean;
  label?: string;
  tooltip?: string | ReactElement;
  name: keyof AllowedOptions;
  onChange: ({
    key,
    value,
  }: {
    key: keyof AllowedOptions | string;
    value: boolean;
  }) => void;
  className?: any;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  id?: string;
}

function GenericToggle<Type>({
  isChecked,
  defaultValue,
  name,
  label,
  tooltip,
  onChange,
  className,
  disabled,
  size = 'medium',
  id,
}: GenericToggleProps<Type>): ReactElement<any, any> {
  return (
    <div
      className={classnames(styles.toggleContainer, className, styles[size])}
    >
      <Checkbox
        disabled={!!disabled}
        className={classnames(styles.toggle, styles[size])}
        toggle
        checked={isChecked}
        defaultChecked={defaultValue}
        label={label}
        onChange={(e, data) =>
          onChange({
            key: id || name,
            value: data.checked || false,
          })
        }
      />
      {tooltip}
    </div>
  );
}

export default GenericToggle;
