import { useLocation } from 'react-router-dom';
import useClientId from '@/hooks/router/useClientId';
import useClientPermissions from './useClientPermissions';
import useUserFromJWToken from '../users/useUserFromJWToken';

const useAtsConfigurationPermission = (): boolean => {
  const clientId = useClientId();
  const permissions = useClientPermissions(clientId).data?.client?.permissions;
  const { data: userData } = useUserFromJWToken();
  const location = useLocation();

  if (!location.pathname.includes('crm-integrations')) {
    return true;
  }

  const userPermission =
    userData?.user?.permissions?.canEditRevealAtsConfiguration;
  return userPermission || !!permissions?.allUsersCanEditRevealAtsConfiguration;
};

export default useAtsConfigurationPermission;
