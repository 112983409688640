import gql from 'graphql-tag';

export default gql`
  fragment CustomFieldsValues on CustomFieldValue {
    id
    type
    clientCustomFieldId
    ... on CustomFieldValueInteger {
      integer
    }
    ... on CustomFieldValueFloat {
      float
    }
    ... on CustomFieldValueText {
      text
    }
    ... on CustomFieldValueEnum {
      selected
    }
    ... on CustomFieldValueDay {
      date
    }
  }
`;
