import React from 'react';
import { useTranslation } from 'react-i18next';
import DebouncedSearchBar from '@/routes/RevealView/SearchView/CandidatesListPane/DebouncedSearchBar';
import { useMiniProfilesSearchContext } from '@/context/Profiles/useMiniProfilesSearch';

interface MiniProfileSearchBarProps {
  onBlur: any;
}

const MiniProfileSearchBar: React.FC<MiniProfileSearchBarProps> = ({
  onBlur,
}) => {
  const { t } = useTranslation();

  const { searchText, setSearchText } = useMiniProfilesSearchContext();

  return (
    <>
      <div className='mini-profile-search-bar'>
        <DebouncedSearchBar
          text={searchText}
          setText={setSearchText}
          onBlur={onBlur}
          autoFocus
          placeholder={t('header.search.searchProfiles')}
        />
      </div>
    </>
  );
};

export default MiniProfileSearchBar;
