import _ from 'underscore';
import { isEmptyRawLocationAndRemoteCriteria } from '@/common/helpers/locationAndRemote';
import { getTranslatedText } from '../../../common';
import reverseLocationsMapping from './reverseLocationsMapping.json';
import skillsByStrongImplications from './skillsByStrongImplications.json';

export const MIN_XP = 0;
export const MAX_XP = 20;

const locationsMapping = _.invert(reverseLocationsMapping);

export const getInitialValues = ({ offer }) => {
  const criteria = offer?.criteria ?? {};

  const firstJobPosition = (criteria.jobPositions?.required || [null])[0];
  const currentJobPosition = firstJobPosition && {
    value: firstJobPosition.identifier,
    label: getTranslatedText(firstJobPosition.label),
  };
  const currentLocations = _.map(criteria.locations || [], (loc) => ({
    value: loc.identifier,
    label: getTranslatedText(loc.label),
  }));
  const currentLanguages = _.map(criteria.languages?.important || [], (lg) => ({
    value: lg.identifier,
    label: getTranslatedText(lg.label),
  }));
  const currentBonusSkills = _.map(criteria.skills?.bonus || [], (sk) => ({
    value: sk.identifier,
    label: getTranslatedText(sk.label),
  }));
  const currentImportantSkills = _.map(
    criteria.skills?.important || [],
    (sk) => ({
      value: sk.identifier,
      label: getTranslatedText(sk.label),
    }),
  );
  const currentRequiredSkills = _.map(
    criteria.skills?.required || [],
    (sk) => ({
      value: sk.identifier,
      label: getTranslatedText(sk.label),
    }),
  );
  const currentRemote = criteria.remote && {
    value: criteria.remote.identifier,
    label: getTranslatedText(criteria.remote.label),
  };
  const currentFreelance = criteria.freelance && {
    value: criteria.freelance.identifier,
    label: getTranslatedText(criteria.freelance.label),
  };
  const currentOnlineLinks = criteria.onlineLinks || '';
  const currentCollaboratorsInCharge = _.map(
    criteria.collaboratorsInCharge || [],
    ({ firstname, lastname, email }) => ({
      value: email,
      label: `${firstname || ''} ${lastname || ''}`,
    }),
  );
  const currentTargetEducation = _.map(
    criteria.education?.important || [],
    ({ label, identifier }) => ({
      label: getTranslatedText(label),
      value: identifier,
    }),
  );

  const currentLocationsAndRemote = criteria.locationsAndRemote || {
    target: [],
    okWith: [],
  };
  const currentFilteringParameter = {
    minExperience: criteria.filteringParameter?.minExperience || 'disabled',
    maxExperience: criteria.filteringParameter?.maxExperience || 'disabled',
    minSalary: criteria.filteringParameter?.minSalary || 'disabled',
    maxSalary: criteria.filteringParameter?.maxSalary || 'disabled',
    locationAndRemote:
      criteria.filteringParameter?.locationAndRemote || 'disabled',
  };

  const currentBlacklist = criteria.blacklistedCompanies || '';
  const currentRelevantCompanies = criteria.relevantCompanies || '';
  const currentExperienceYears = criteria.experience?.years;
  const currentSalary = criteria.salary;
  const currentExampleProfiles = criteria.exampleProfiles || '';
  const currentExtraKeyWords = criteria.extraKeyWords || '';
  const currentMoreInfo = criteria.moreInfo || '';
  return {
    jobPosition: currentJobPosition,
    locations: currentLocations,
    experienceYears: currentExperienceYears,
    remote: currentRemote,
    freelance: currentFreelance,
    salary: currentSalary,
    requiredSkills: currentRequiredSkills,
    importantSkills: currentImportantSkills,
    bonusSkills: currentBonusSkills,
    languages: currentLanguages,
    extraKeyWords: currentExtraKeyWords,
    collaboratorsInCharge: currentCollaboratorsInCharge,
    onlineLinks: currentOnlineLinks,
    exampleProfiles: currentExampleProfiles,
    targetEducation: currentTargetEducation,
    relevantCompanies: currentRelevantCompanies,
    blacklist: currentBlacklist,
    moreInfo: currentMoreInfo,
    locationsAndRemote: currentLocationsAndRemote,
    filteringParameter: currentFilteringParameter,
  };
};

export const getLabelToValue = (criteriaOptions) => ({
  jobPositions: _.object(
    _.pluck((criteriaOptions || {}).jobPositions, 'identifier'),
    (criteriaOptions || {}).jobPositions,
  ),
  locations: _.object(
    _.pluck((criteriaOptions || {}).locations, 'identifier'),
    (criteriaOptions || {}).locations,
  ),
  languages: _.object(
    _.pluck((criteriaOptions || {}).languages, 'identifier'),
    (criteriaOptions || {}).languages,
  ),
  bonusSkills: _.object(
    _.pluck(((criteriaOptions || {}).skills || {}).bonus, 'identifier'),
    ((criteriaOptions || {}).skills || {}).bonus,
  ),
  importantSkills: _.object(
    _.pluck(((criteriaOptions || {}).skills || {}).important, 'identifier'),
    ((criteriaOptions || {}).skills || {}).important,
  ),
  requiredSkills: _.object(
    _.pluck(((criteriaOptions || {}).skills || {}).required, 'identifier'),
    ((criteriaOptions || {}).skills || {}).required,
  ),
  remote: _.object(
    _.pluck((criteriaOptions || {}).remote, 'identifier'),
    (criteriaOptions || {}).remote,
  ),
  freelance: _.object(
    _.pluck((criteriaOptions || {}).freelance, 'identifier'),
    (criteriaOptions || {}).freelance,
  ),
  collaboratorsInCharge: _.object(
    _.pluck((criteriaOptions || {}).collaboratorsInCharge, 'email'),
    (criteriaOptions || {}).collaboratorsInCharge,
  ),
  education: _.object(
    _.pluck((criteriaOptions || {}).education, 'identifier'),
    (criteriaOptions || {}).education,
  ),
});

export const getOptions = (criteriaOptions) => ({
  jobPositions: _.map((criteriaOptions || {}).jobPositions, (jp) => ({
    value: jp.identifier,
    label: getTranslatedText(jp.label),
  })),
  locations: _.map((criteriaOptions || {}).locations, (loc) => ({
    value: loc.identifier,
    label: getTranslatedText(loc.label),
  })),
  languages: _.map((criteriaOptions || {}).languages, (language) => ({
    value: language.identifier,
    label: getTranslatedText(language.label),
  })),
  bonusSkills: _.map(((criteriaOptions || {}).skills || {}).bonus, (sk) => ({
    value: sk.identifier,
    label: getTranslatedText(sk.label),
  })),
  importantSkills: _.map(
    ((criteriaOptions || {}).skills || {}).important,
    (sk) => ({
      value: sk.identifier,
      label: getTranslatedText(sk.label),
    }),
  ),
  requiredSkills: _.map(
    ((criteriaOptions || {}).skills || {}).required,
    (sk) => ({
      value: sk.identifier,
      label: getTranslatedText(sk.label),
    }),
  ),
  remote: _.map((criteriaOptions || {}).remote, (option) => ({
    value: option.identifier,
    label: getTranslatedText(option.label),
  })),
  freelance: _.map((criteriaOptions || {}).freelance, (option) => ({
    value: option.identifier,
    label: getTranslatedText(option.label),
  })),
  collaboratorsInCharge: _.map(
    (criteriaOptions || {}).collaboratorsInCharge,
    ({ firstname, lastname, email }) => ({
      value: email,
      label: `${firstname || ''} ${lastname || ''}`,
    }),
  ),
  education: _.map((criteriaOptions || {}).education, (option) => ({
    value: option.identifier,
    label: getTranslatedText(option.label),
  })),
});

export const getNewField = ({
  label,
  value,
  labelToValue,
  currentCriteria,
}) => {
  if (label === 'filteringParameter') {
    return { [label]: value };
  }
  if (label === 'locationsAndRemote') {
    return { [label]: value };
  }
  if (
    !label ||
    (_.isArray(value) &&
      _.pluck(value, 'value').length !==
        _.compact(_.pluck(value, 'value')).length)
  ) {
    return null;
  }
  if (_.contains(_.keys(labelToValue), label)) {
    if (_.contains(['collaboratorsInCharge'], label)) {
      return { [label]: _.pluck(value, 'value') };
    }
    if (_.contains(['remote', 'freelance'], label)) {
      return {
        [label]: ((labelToValue || {})[label] || {})[(value || {}).value],
      };
    }
    if (_.contains(['jobPositions'], label)) {
      return {
        [label]: {
          required: _.map(
            value,
            (v) => ((labelToValue || {})[label] || {})[(v || {}).value],
          ),
        },
      };
    }
    if (_.contains(['languages', 'education'], label)) {
      return {
        [label]: {
          important: _.map(
            value,
            (v) => ((labelToValue || {})[label] || {})[(v || {}).value],
          ),
        },
      };
    }
    if (
      _.contains(['requiredSkills', 'importantSkills', 'bonusSkills'], label)
    ) {
      const keyByLabel = {
        requiredSkills: 'required',
        importantSkills: 'important',
      };
      const key = keyByLabel[label] || 'bonus';
      return {
        skills: {
          ...(currentCriteria || {}).skills,
          [key]: _.map(
            value,
            (v) => ((labelToValue || {})[label] || {})[(v || {}).value],
          ),
        },
      };
    }
    return {
      [label]: _.map(
        value,
        (v) => ((labelToValue || {})[label] || {})[(v || {}).value],
      ),
    };
  }
  if (label === 'experience') {
    return { [label]: { years: (value || {}).value } };
  }
  if (_.isArray(value)) {
    return { [label]: _.pluck(value, 'value') };
  }
  return { [label]: (value || {}).value };
};

export const removeUnwantedFields = (myVar) => {
  if (_.isArray(myVar)) {
    return _.compact(_.map(myVar, (v) => removeUnwantedFields(v)));
  }
  if (_.isObject(myVar)) {
    return _.mapObject(
      _.omit(myVar, (value, key) => key === '__typename' || value === null),
      (values) => removeUnwantedFields(values),
    );
  }
  return myVar;
};

const asOption = (options, value) => {
  return _.findWhere(options, { value });
};

const asOptions = (options, criteria) => {
  return _.compact(
    _.map(criteria, (criterion) => asOption(options, criterion)),
  );
};

const searchLocationToOfferLocation = ({ location: { id }, remoteWish }) => ({
  location: {
    id: reverseLocationsMapping[id],
  },
  remoteWish,
});

const getOfferLocationsAndRemoteCriteria = ({ target, okWith } = {}) => ({
  target: _.map(target || [], searchLocationToOfferLocation),
  okWith: _.map(okWith || [], searchLocationToOfferLocation),
});

export const getOfferCriteria = ({ searchCriteria, options }) => {
  const {
    jobPositions,
    locationsAndRemote,
    experienceYearsMin,
    experienceYearsMax,
    salaryMin,
    salaryMax,
    skills,
  } = searchCriteria || {};
  const searchJobPosition = (jobPositions || [])[0];
  return {
    jobPosition: asOption(options?.jobPositions, searchJobPosition),
    locationsAndRemote: getOfferLocationsAndRemoteCriteria(locationsAndRemote),
    experienceYears: {
      min: experienceYearsMin || MIN_XP,
      max: experienceYearsMax || MAX_XP,
    },
    salary: {
      min: salaryMin && salaryMin * 1000,
      max: salaryMax && salaryMax * 1000,
      currency: 'EUR', // Discover is only in FR for now
    },
    requiredSkills: asOptions(
      options?.requiredSkills,
      (skills || []).slice(0, 2),
    ),
    importantSkills: asOptions(
      options?.importantSkills,
      (skills || []).slice(2, 6),
    ),
    bonusSkills: asOptions(options?.bonusSkills, (skills || []).slice(6)),
  };
};

const getSkillsForSearch = ({ criteria }) => {
  const { required, important } = criteria || {};
  const requiredSkills = _.compact(_.pluck(required, 'identifier'));
  const importantSkills = _.compact(_.pluck(important, 'identifier'));
  if (_.isEmpty(requiredSkills) && _.isEmpty(importantSkills)) {
    return [];
  }
  if (_.isEmpty(requiredSkills) && !_.isEmpty(importantSkills)) {
    return importantSkills;
  }
  if (!_.isEmpty(requiredSkills) && _.isEmpty(importantSkills)) {
    return requiredSkills;
  }
  const requiredStrongDependencies = _.flatten(
    _.map(requiredSkills, (s) => skillsByStrongImplications[s]),
  );
  const importantImplyingRequired = _.intersection(
    importantSkills,
    requiredStrongDependencies,
  );

  return _.union(requiredSkills, importantImplyingRequired);
};

const flattenLeveledCriteria = ({ criteria }) => {
  const { required, important, bonus } = criteria || {};
  const flattenCategory = (category) =>
    _.map(category, ({ identifier }) => identifier);
  return _.union(
    flattenCategory(required),
    flattenCategory(important),
    flattenCategory(bonus),
  );
};

const offerLocationToSearchLocation = ({ location: { id }, remoteWish }) => ({
  location: {
    id: locationsMapping[id],
  },
  remoteWish,
});

const getSearchLocationsAndRemoteCriteria = ({
  locationsAndRemote: { target, okWith } = {},
} = {}) => ({
  target: _.map(target || [], offerLocationToSearchLocation),
  okWith: _.map(okWith || [], offerLocationToSearchLocation),
});

export const getSearchCriteria = ({ offerCriteria }) => {
  const { experience, salary } = offerCriteria || {};

  const jobPositions = flattenLeveledCriteria({
    criteria: offerCriteria?.jobPositions,
  });
  const skills = getSkillsForSearch({ criteria: offerCriteria?.skills });
  const locationsAndRemote = getSearchLocationsAndRemoteCriteria(
    offerCriteria || {},
  );

  return {
    ...(!_.isEmpty(jobPositions) && { jobPositions }),
    ...(!isEmptyRawLocationAndRemoteCriteria(locationsAndRemote) && {
      locationsAndRemote,
    }),
    ...(!_.isEmpty(skills) && { skills }),
    ...(_.isNumber(experience?.years?.min) && {
      experienceYearsMin: experience?.years?.min,
    }),
    ...(_.isNumber(experience?.years?.max) && {
      experienceYearsMax: experience?.years?.max,
    }),
    ...(_.isNumber(salary?.min) && { salaryMin: salary?.min / 1000 }),
    ...(_.isNumber(salary?.max) && { salaryMax: salary?.max / 1000 }),
  };
};

export const getNewOfferCriteria = ({ initialValues, labelToValue }) => {
  const {
    jobPosition,
    locationsAndRemote,
    experienceYears,
    salary,
    requiredSkills,
    importantSkills,
    bonusSkills,
  } = initialValues;

  const skills = {
    ...getNewField({
      label: 'requiredSkills',
      value: requiredSkills,
      labelToValue,
    })?.skills,
    ...getNewField({
      label: 'importantSkills',
      value: importantSkills,
      labelToValue,
    })?.skills,
    ...getNewField({ label: 'bonusSkills', value: bonusSkills, labelToValue })
      ?.skills,
  };

  return {
    ...getNewField({
      label: 'jobPositions',
      value: [jobPosition],
      labelToValue,
    }),
    locationsAndRemote,
    ...getNewField({
      label: 'experience',
      value: { value: experienceYears },
      labelToValue,
    }),
    ...getNewField({ label: 'salary', value: { value: salary }, labelToValue }),
    skills,
  };
};

export const validateOfferInput = ({
  title,
  criteria,
  simplifiedMode,
  crmMode,
  t,
}) => {
  const titleLength = (title || '').length;
  const experienceSet = !!((criteria || {}).experience || {}).years;
  const nbJobPositions = (((criteria || {}).jobPositions || {}).required || [])
    .length;
  const { locationsAndRemote: { target = [], okWith = [] } = {} } =
    criteria || {};
  const nbLocations = target.length + okWith.length;
  const skills = (criteria || {}).skills || {};
  const nbSkills = [
    ...(skills.required || []),
    ...(skills.important || []),
    ...(skills.bonus || []),
  ].length;

  const salary = (criteria || {}).salary || {};
  const salaryNumberError =
    (salary.min && !_.isNumber(salary.min)) ||
    (salary.max && !_.isNumber(salary.max));
  const salaryMinMaxError =
    _.isNumber(salary.min) && _.isNumber(salary.max) && salary.max < salary.min;
  const salaryNegativeError = salary.min < 0 || salary.max < 0;

  let error = '';
  const missingFields = [];
  const missingFieldsNames = [];
  const otherErrors = [];
  if (!simplifiedMode && titleLength === 0) {
    missingFields.push(t('newOffer.errors.missingTitle'));
    missingFieldsNames.push('title');
  } else if (!simplifiedMode && titleLength < 6) {
    missingFields.push(t('newOffer.errors.titleTooShort'));
    missingFieldsNames.push('title');
  }
  if (nbJobPositions < 1 && !crmMode) {
    missingFields.push(t('newOffer.errors.missingJobPosition'));
    missingFieldsNames.push('jobPositions');
  }
  if (nbLocations < 1 && !crmMode) {
    missingFields.push(t('newOffer.errors.missingLocation'));
    missingFieldsNames.push('locationsAndRemote');
  }
  if (nbSkills < 1 && !crmMode) {
    missingFields.push(t('newOffer.errors.missingSkill'));
    missingFieldsNames.push('skills');
  }
  if (!experienceSet && !crmMode) {
    missingFields.push(t('newOffer.errors.missingExperienceLevel'));
    missingFieldsNames.push('experience');
  }
  if (salaryNumberError) {
    otherErrors.push(t('newOffer.errors.salaryNumberError'));
  }
  if (salaryMinMaxError) {
    otherErrors.push(t('newOffer.errors.salaryMinMaxError'));
  }
  if (salaryNegativeError) {
    otherErrors.push(t('newOffer.errors.salaryNegativeError'));
  }

  if (missingFields.length === 1) {
    error = t('newOffer.errors.missingFields') + missingFields[0];
  } else if (missingFields.length > 1) {
    const lastMissingField = missingFields.pop();
    error =
      t('newOffer.errors.missingFields') +
      missingFields.join(', ') +
      t('newOffer.errors.and') +
      lastMissingField;
  }

  if (otherErrors.length >= 1) {
    error = otherErrors.join(' ') + error;
  }

  return {
    error,
    missingFieldsNames,
  };
};

export const getLocationName = (locationOptions, locationId) =>
  _.find(
    locationOptions || [],
    ({ value: optionValue }) => optionValue === locationId,
  )?.label;

export const getGenericTitle = ({ options, criteria }) => {
  const jobPosition = (
    ((((criteria || {}).jobPositions || {}).required || [])[0] || {}).label ||
    {}
  ).default;
  const min = (((criteria || {}).experience || {}).years || {}).min || MIN_XP;
  const max = (((criteria || {}).experience || {}).years || {}).max || MAX_XP;
  const locationId =
    (criteria || {}).locationsAndRemote?.target?.[0]?.location.id ||
    (criteria || {}).locationsAndRemote?.okWith?.[0]?.location.id;
  const locationString =
    getLocationName(options.locations, locationId) ||
    ((((criteria || {}).locations || [])[0] || {}).label || {}).default ||
    '';

  const experience = min !== MIN_XP && max !== MAX_XP && ` (${min}-${max})`;
  const location = locationString && ` - ${locationString}`;
  if (!jobPosition && !experience && !location) {
    return 'My Job';
  }
  return `${jobPosition || ''}${location || ''}${experience || ''}`;
};
