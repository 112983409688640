import _ from 'underscore';
import { PowerHour } from '@/types/powerHours';

export function getSoonestPowerHour(
  sortedPowerHours: PowerHour[],
): PowerHour | undefined {
  let result: PowerHour | undefined;
  const now = new Date();

  _.each(sortedPowerHours, (powerHours) => {
    if (result) {
      return;
    }
    if (now > powerHours.endDate) {
      return;
    }
    result = powerHours;
  });

  return result;
}

interface PowerHourProfile {
  powerHourFlags?: {
    powerHourId: string;
    isFlagged?: boolean;
  }[];
  firstContactDate?: string;
}

export function getRelevantPowerHourForProfile(
  sortedPowerHours: PowerHour[],
  profile: PowerHourProfile | undefined,
): PowerHour | undefined {
  if (!profile) {
    return undefined;
  }

  const { firstContactDate, powerHourFlags } = profile;

  const powerHourFlagMap = _.indexBy(powerHourFlags || [], 'powerHourId');

  if (!firstContactDate) {
    const soonestPowerHour = getSoonestPowerHour(sortedPowerHours);

    if (!soonestPowerHour) {
      return undefined;
    }

    const { id } = soonestPowerHour;

    return powerHourFlagMap[id]?.isFlagged ? soonestPowerHour : undefined;
  }

  return _.find(sortedPowerHours, (powerHour) => {
    const { id, startDate, endDate } = powerHour;
    if (!powerHourFlagMap[id]?.isFlagged) {
      return false;
    }
    const firstContact = new Date(firstContactDate);
    if (firstContact < startDate) {
      return false;
    }
    if (firstContact > endDate) {
      return false;
    }
    return true;
  });
}

export default {};
