import _ from 'underscore';

export const recruitersToOptions = ({ users, ownerEmail }) => {
  const otherRecruiters = _.reject(users, (user) => user?.email === ownerEmail);
  return _.map(otherRecruiters, (user) => {
    const { firstname, lastname, email } = user || {};
    return {
      key: email,
      value: email,
      text: `${firstname} ${lastname}`,
    };
  });
};

export const randomId = () => {
  return Math.random().toString(36).substring(7);
};

export const formatUsers = (users) => {
  const userStrings = _.compact(_.map(users, (user) => formatUser(user)));
  return userStrings.join(',\n');
};

export const formatUser = (user) => {
  const { email, firstname, lastname } = user || {};
  const nameString = `${firstname || ''} ${lastname || ''}`.trim();
  if (!nameString) {
    return email || '';
  }
  const emailString = email ? ` (${email})` : '';
  return `${nameString}${emailString}`.trim();
};

export const fullName = (user) => {
  const { firstname, lastname } = user || {};
  return `${firstname || ''} ${lastname || ''}`.trim();
};

export const validNewSenderFormData = ({ alias, signature, firstname, lastname, senderAddress, emailType }) => {
  if (!emailType) {
    return false;
  }

  if (emailType === 'gmail' && !alias) {
    return false;
  }
  return signature && firstname && lastname && senderAddress;
};

export const validEditSenderFormData = ({ alias, signature, senderAddress, emailType }) => {
  if (!emailType) {
    return false;
  }

  if (emailType === 'gmail' && !alias) {
    return false;
  }
  return signature && senderAddress;
};
