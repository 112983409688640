import React from 'react';
import { Placeholder } from 'semantic-ui-react';

import LastInteraction from '@/revealComponents/RevealListRow/LastInteraction';
import { ProfileColumn } from '../../types';

const LastInteractionColumn: ProfileColumn = ({
  profile,
  profileLoading,
  className,
  style,
}) => {
  const { currentSequenceInfo, explicitLastInteraction } = profile || {};
  const { author } = currentSequenceInfo || {};
  const lastInteraction = currentSequenceInfo?.lastActionDate
    ? {
        author,
        date: currentSequenceInfo?.lastActionDate
          ? Date.parse(currentSequenceInfo?.lastActionDate).toString()
          : '0000000000',
      }
    : explicitLastInteraction
    ? {
        ...explicitLastInteraction,
        date: explicitLastInteraction?.date
          ? Date.parse(explicitLastInteraction?.date).toString()
          : '0000000000',
      }
    : null;

  return (
    <div className={className} style={style}>
      {profileLoading && (
        <Placeholder>
          <Placeholder.Line length='medium' />
          <Placeholder.Line length='short' />
        </Placeholder>
      )}
      {!profileLoading && lastInteraction && (
        <LastInteraction lastInteraction={lastInteraction} />
      )}
      {!profileLoading && !lastInteraction && <div> - </div>}
    </div>
  );
};

export default LastInteractionColumn;
