import React, { Component } from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Icon, Button, Image } from 'semantic-ui-react';
import * as Sentry from '@sentry/browser';

import { MarketplaceUnbiasedModeField } from '@/components/UnbiasedMode/UnbiasedModeField';
import useClientId from '@/hooks/router/useClientId';
import { CANDIDATE_INTEREST_ITEM_TYPE } from '@/common/reveal/SourceData.type';
import WatchNewTag from '@/routes/WatchCollectViewV2/WatchCollectRecommendations/JobProfileRecommendations/WatchNewTag';
import Interest from '@/components/Common/Icons/Interest';
import { BlurLine } from '@/components/UnbiasedMode/BlurLine';
import useClient from '@/graphql/hooks/clients/useClient';

import withUserFromJWToken from '../../../hocs/users/withUserFromJWToken';
import {
  getSecureLink,
  textEllipsis,
  getRandomDefaultAvatarLink,
} from '../../../common';
import ProfilePowerHourIcon from '../Resume/Card/ProfilePowerHourIcon';
import ProfilePowerHourFlag from '../Resume/PowerHourState';

import './ProfileSelector.css';

function ProfileCard(props) {
  const {
    picture,
    firstname,
    lastname,
    profile,
    headline,
    selected,
    full,
    onClick,
    unbiasedModeEnabled,
    annotation,
    stepId,
    sourceData,
    creationTimestamp,
  } = props;
  const selectedClass = selected ? 'selected' : 'not-selected';
  const visibilityClass = full ? 'full' : 'reduced';
  const avatarLink = getRandomDefaultAvatarLink(firstname + lastname);
  const isStarred = annotation?.globalFavorite?.value;
  const clientId = useClientId();
  const { data } = useClient(clientId);
  const { hasLimitedAccessToProfiles } = data?.client || {};

  const hasCompanyGlobalInterestItem = React.useMemo(() => {
    const clientInterestStates = _.findWhere(
      sourceData?.hiresweet?.clientInterestStates || [],
      { clientId },
    );
    return _.findWhere(clientInterestStates?.interestItems || [], {
      type: CANDIDATE_INTEREST_ITEM_TYPE.COMPANY_GLOBAL,
    });
  }, [sourceData, clientId]);

  return (
    <div
      className={`profile-card-compact ${selectedClass} ${visibilityClass}`}
      onClick={onClick}
      role='button'
    >
      <div className='profile-bubble'>
        <MarketplaceUnbiasedModeField
          name='hidePicture'
          enabled={stepId === 'pending'}
          fallback={<Image circular src={avatarLink} />}
        >
          <Image
            circular
            src={(!unbiasedModeEnabled && getSecureLink(picture)) || avatarLink}
            onError={(e) => {
              e.target.src = avatarLink;
            }}
          />
        </MarketplaceUnbiasedModeField>
        {isStarred && <i className='ri-star-fill bubble-star' />}
        {hasCompanyGlobalInterestItem && (
          <div className='companyGlobalInterestIcon'>
            <Interest />
          </div>
        )}
        <ProfilePowerHourFlag profile={profile}>
          <div className='profileSelectorPowerHoursIcon'>
            <ProfilePowerHourIcon profile={profile} />
          </div>
        </ProfilePowerHourFlag>

        {/* {isHiresweetWatch ? (
          <div className='profile-source-indicator hiresweet-watch'>
            <Icon name='check' />
          </div>
        ) : null}
        {!isHiresweetWatch && sourceType === 'career-page' ? (
          <div className='profile-source-indicator career-page'>
            <Icon className='ri-flashlight-fill' />
          </div>
        ) : null} */}
      </div>
      <div className='content'>
        <div className='name'>
          <span className='inner-name'>
            {hasLimitedAccessToProfiles ? (
              <BlurLine style={{ display: 'inline-block', width: 100 }} />
            ) : (
              <MarketplaceUnbiasedModeField
                enabled={stepId === 'pending'}
                name='hideFullName'
                fallback={<BlurLine style={{ width: 100 }} />}
              >
                {firstname} {lastname}
              </MarketplaceUnbiasedModeField>
            )}
          </span>
          <WatchNewTag creationTimestamp={creationTimestamp} />
        </div>
        <div className='description'>{textEllipsis(headline, 52)}</div>
      </div>
    </div>
  );
}

class ProfileSelector extends Component {
  static defaultProps = {
    full: false,
    profiles: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      maxSize: 30,
    };
  }

  handleAugmentSize = () => {
    const { maxSize } = this.state;
    this.setState({ maxSize: 2 * maxSize });
  };

  componentWillReceiveProps(nextProps) {
    const { selected } = this.state;
    if (nextProps.selected !== selected) {
      this.setState({ selected: nextProps.selected });
    }
  }

  onSelect = (profile) => {
    this.setState({ selected: profile });
    const { onSelectProfile } = this.props;
    if (onSelectProfile) {
      onSelectProfile(profile);
    }
  };

  static renderEmpty(count) {
    return (
      <div key={count} className={`profile-card-compact empty empty-${count}`}>
        <div className='profile-bubble' />
        <div className='content'>
          <div className='name' />
          <div className='description' />
        </div>
      </div>
    );
  }

  renderProfile = (profile) => {
    const { user, stepId, searchText, full } = this.props;
    const unbiasedModeEnabled =
      (user || {}).unbiasedModeEnabled && stepId === 'pending' && !searchText;

    if (!profile) {
      Sentry.captureException('Warning: profile not provided');
      return null;
    }

    const { resumeData, source, creationTimestamp, id, annotation } = profile;

    const {
      photoLink,
      firstname,
      lastname,
      headline,
      prequalification,
      sourceData,
    } = resumeData;
    const headlineText = ((headline || {}).content || {}).text;
    const isHiresweetWatch =
      (prequalification || {}).type === 'hiresweet-watch';
    const { type: sourceType } = source || {};
    const { selected } = this.state;
    return (
      <ProfileCard
        key={id}
        onClick={() => this.onSelect(profile)}
        selected={selected && selected.id === id}
        profile={profile}
        firstname={firstname}
        lastname={lastname}
        headline={headlineText}
        picture={photoLink}
        isHiresweetWatch={isHiresweetWatch}
        sourceType={sourceType}
        full={full}
        unbiasedModeEnabled={unbiasedModeEnabled}
        annotation={annotation}
        stepId={stepId}
        sourceData={sourceData}
        creationTimestamp={creationTimestamp}
      />
    );
  };

  renderStepProfiles = (stepId, profiles) => {
    const { t } = this.props;
    const stepName = t(`profile.steps.pipe.${stepId}`, stepId);
    return (
      <div key={stepId} className='step-results'>
        <div className='category-header'>
          {stepName} <span className='count'>({profiles.length})</span>
        </div>
        <div className='profile-list'>
          {_.map(profiles, this.renderProfile)}
        </div>
      </div>
    );
  };

  renderProfilesByStep = (profiles) => {
    const { shouldSplitPending } = this.props;

    const categories = _.groupBy(
      profiles,
      ({ resumeData }) =>
        (shouldSplitPending && resumeData?.subStep) || resumeData?.step,
    );
    const buckets = [
      'pending',
      'pending-main',
      'pending-extended',
      'contacted',
      'answered',
      'in-process',
      'hired',
      'skipped',
    ];
    return _.filter(
      _.map(buckets, (stepId) => {
        const stepProfiles = categories[stepId];
        return !stepProfiles
          ? null
          : this.renderStepProfiles(stepId, stepProfiles);
      }),
      (x) => !!x,
    );
  };

  renderItems() {
    const { profiles, searchText } = this.props;
    const { maxSize } = this.state;
    if (!profiles) {
      return _.map([1, 2, 3, 4], this.renderEmpty);
    }

    if (searchText && searchText !== '') {
      return this.renderProfilesByStep(profiles);
    }

    return _.map((profiles || []).slice(0, maxSize), this.renderProfile);
  }

  render() {
    const { profiles, full } = this.props;
    const { maxSize } = this.state;
    const variantClass = full ? 'full' : 'reduced';
    const items = this.renderItems();

    return (
      <div className={`profile-selector ${variantClass}`}>
        <div className='profile-list'>{items}</div>
        {full && (profiles || []).length > maxSize && (
          <div className='see-more-profiles-section-container'>
            <div className='see-more-profiles-section'>
              <Button
                className='see-more-profiles-button'
                onClick={this.handleAugmentSize}
              >
                <Icon size='large' name='chevron circle down' />
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withUserFromJWToken,
  withTranslation('translations'),
)(ProfileSelector);
