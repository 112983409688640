import _ from 'underscore';

const sortedMarketplaceLocations = [
  'paris',
  '198254379',
  'lyon',
  'marseille',
  'lille',
  'toulouse',
  'bordeaux',
  'rennes',
  'nantes',
  'montpellier',
  'strasbourg',
  'nice',
  'grenoble',
  'france',
  'belgium',
  'swiss',
  'europe',
  'san-francisco',
  'new-york',
  'chicago',
  'washington-dc',
  'houston',
  'philadelphia',
  'boston',
  'dallas',
  'los-angeles',
  'palo-alto',
  'redwood-city',
  'mountain-view',
  'cupertino',
  'san-diego',
  'charlotte-north-carolina',
  'detroit',
  'miami',
  'phoenix',
  'seattle',
  'indianapolis',
  'portland',
  'memphis',
  'atlanta',
  'austin',
  'tampa',
  'london',
  'birmingham',
  'manchester',
  'glasgow',
  'leeds',
  'liverpool',
  'newcastle',
  'sheffield',
  'berlin',
  'hamburg',
  'munich',
  'cologne',
  'frankfurt',
  'stuttgart',
  'dusseldorf',
  'dortmund',
  'barcelona',
  'madrid',
  'valencia',
  'seville',
  'bilbao',
  'brussels',
  'antwerp',
  'ghent',
  'charleroi',
  'liege',
  'lisbon',
  'porto',
  'zurich',
  'lausanne',
  'geneva',
  'bern',
  'basel',
  'montreal',
  'toronto',
  'vancouver',
  'calgary',
  'edmonton',
  'ottawa',
  'quebec-city',
  'mexico-city',
  'usa',
  'uk',
  'germany',
  'spain',
  'portugal',
  'canada',
  'mexico',
  'romania',
  'poland',
  'hungary',
  'bulgary',
  'czech-republic',
  'slovenia',
  'slovakia',
  'lithuania',
  'estonia',
  'latvia',
  'croatia',
  'ukraine',
  '198306739',
  '198326930',
  '198010785',
  '198627786',
  '198210698',
];

const marketplaceLocationRanks = _.reduce(
  sortedMarketplaceLocations,
  (acc, location, rank) => ({
    ...acc,
    [location]: rank,
  }),
  {} as Record<string, number>,
);

export function sortLocationOptions<T extends { value: string }>(
  options: T[],
): T[] {
  return _.sortBy(
    options,
    ({ value }) => marketplaceLocationRanks[value] ?? -1,
  );
}
