/* eslint-disable no-nested-ternary */
import _ from 'underscore';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';

import TaskIcon from '@/revealComponents/TaskIcon';
import {
  ActionFuture,
  Ellipsis,
  SvgCheckMark,
  SequenceStopIcon,
} from '@/assets/icons';
import useResizableItemList from '@/hooks/common/useResizableItemList';
import GenericTooltip from '@/components/Common/GenericTooltip';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

import './ProfileRowSequence.css';

interface ProfileRowSequenceInterface {
  currentSequenceInfo: any;
  sequences: any;
  nextInteraction: any;
  loading: boolean;
  isStandalone?: boolean;
  mini: boolean;
  hideTitle?: boolean;
  hideTooltip?: boolean;
  styles?: any;
  inlineTitle?: boolean;
  showStateIcons?: boolean;
}

const ProfileRowSequence: React.FC<ProfileRowSequenceInterface> = ({
  currentSequenceInfo,
  sequences,
  nextInteraction,
  loading,
  isStandalone = false,
  mini,
  hideTitle,
  hideTooltip,
  styles,
  inlineTitle = false,
  showStateIcons = true,
}) => {
  const { t } = useTranslation();
  const { displayedActionsLimit } = useMergedConfigurationParams();

  let currentSequence: any = null;
  for (let iSequence = 0; iSequence < sequences?.length; iSequence += 1) {
    if (
      (sequences[iSequence].isCurrent &&
        sequences[iSequence]?.trigger?.type !== 'manual-trigger') ||
      sequences[iSequence]?.id ===
        currentSequenceInfo?.contactFlow?.sequences?.[0]?.id
    ) {
      currentSequence = sequences[iSequence];
    }
  }
  const isCurrentSequenceCompleted = currentSequence?.completion?.isCompleted;

  const {
    containerRef,
    listRef,
    displayedItemsNumber,
    refresh,
    finalWidth,
  } = useResizableItemList<HTMLDivElement>(20);

  useEffect(() => refresh(), [refresh, sequences]);

  if (!nextInteraction) {
    _.each(sequences || [], (sequence) => {
      if (
        !sequence?.isCurrent ||
        sequence?.completion?.isCompleted ||
        sequence?.trigger?.type === 'manual-trigger'
      ) {
        return;
      }
      let lastInterIndex = -1;
      for (let iAction = 0; iAction < sequence?.actions?.length; iAction++) {
        if (sequence?.actions?.[iAction]?.completion?.isCompleted) {
          lastInterIndex = iAction;
        }
      }
      const nextInteractionIndex = lastInterIndex + 1;
      if (nextInteractionIndex < sequence?.actions?.length) {
        const nextInter = sequence.actions[nextInteractionIndex];
        if (nextInter?.type && !nextInter?.completion?.completionDate) {
          nextInteraction = nextInter;
        }
      }
    });
  }

  const getEllapsedTime = (timeDiff: number) => {
    const dueDate = {
      date: 0,
      unit: '',
    };
    // H: 3600000
    // D: 86400000
    // W: 604800000
    if (timeDiff > -86400000 && timeDiff < 0) {
      dueDate.date = Math.round(timeDiff / 3600000);
      dueDate.unit = ` ${t('contactFlow.triggers.delays.hour', { count: 1 })}`;
      if (dueDate.date < -1) dueDate.unit += 's';
    } else if (timeDiff < -86400000) {
      dueDate.date = Math.round(timeDiff / 86400000);
      dueDate.unit = ` ${t('contactFlow.triggers.delays.day', { count: 1 })}`;
      if (dueDate.date < -1) dueDate.unit += 's';
    }
    return dueDate;
  };

  const getToolTipDate = (dateStr: string) => {
    if (dateStr) {
      const date = new Date(dateStr);
      return moment(date).format('LL');
    }
    return undefined;
  };

  const getCompletedToolTipContent = (action: any) => {
    if (!action?.completion?.completionDate) {
      return t('reveal.missions.mission.sequences.completed');
    }
    const completionDateDiff =
      Date.parse(action?.completion?.completionDate) - Date.now();
    const { isCompleted } = action.completion;
    if (completionDateDiff < -604800000) {
      const date = new Date(action.completion.completionDate);
      return `${t('reveal.missions.mission.sequences.completed')} ${moment(
        date,
      ).format('LL')}`;
    }
    if (completionDateDiff > 0 && isCompleted) {
      return t('sequences.statuses.pending');
    }
    if (completionDateDiff < 0 && !isCompleted) {
      return t('sequences.statuses.failed');
    }
    const dueDate = getEllapsedTime(completionDateDiff);
    return `${t(
      'reveal.missions.mission.sequences.completedBefore',
    )} ${dueDate.date * -1}${dueDate.unit} ${t(
      'reveal.missions.mission.sequences.completedBeforeDelay',
    )} ${t('reveal.missions.mission.sequences.ago')}`;
  };

  const getToolTipContent = (action: any, state: string) => {
    if (state === 'completed') {
      return `${getCompletedToolTipContent(action)} - ${getToolTipAuthor()}`;
    }
    if (state === 'due') {
      const date = getToolTipDate(action?.plannedExecutionDate);
      return t('reveal.missions.mission.sequences.due', {
        date: date ? ` - ${date}` : '',
        author: getToolTipAuthor(),
      });
    }
    if (state === 'future') {
      const date = getToolTipDate(action?.plannedExecutionDate);
      if (!date) {
        if (action?.trigger?.delay) {
          return t('reveal.missions.mission.sequences.plannedAfterDelay', {
            value: action?.trigger?.delay?.value,
            unit: t(
              `contactFlow.triggers.units.${action?.trigger?.delay?.unit}`,
              { count: action?.trigger?.delay?.value },
            ),
            author: getToolTipAuthor(),
          });
        }
        return t('reveal.missions.mission.sequences.plannedImmediatelyAfter', {
          author: getToolTipAuthor(),
        });
      }
      return t('reveal.missions.mission.sequences.planned', {
        date: getToolTipDate(action?.plannedExecutionDate),
        author: getToolTipAuthor(),
      });
    }
    return undefined;
  };

  const getToolTipAuthor = () => {
    if (
      !currentSequenceInfo?.author?.lastname &&
      !currentSequenceInfo?.author?.firstname
    ) {
      return '';
    }
    if (
      !currentSequenceInfo?.author?.lastname ||
      !currentSequenceInfo?.author?.firstname
    ) {
      return (
        currentSequenceInfo?.author?.firstname ||
        currentSequenceInfo?.author?.lastname ||
        ''
      );
    }
    return `${currentSequenceInfo?.author?.firstname} ${currentSequenceInfo?.author?.lastname}`;
  };

  const isDue = (action: any) => {
    return (
      action.actionId === nextInteraction?.actionId &&
      !action?.completion?.isCompleted &&
      (!action.plannedExecutionDate ||
        new Date(action.plannedExecutionDate).getTime() <= Date.now())
    );
  };

  const isPlanned = (action: any) => {
    return !action.completion?.isCompleted;
  };

  const displayedActions = displayedActionsLimit
    ? (currentSequence?.actions || []).slice(
        0,
        +(displayedActionsLimit as string),
      )
    : currentSequence?.actions || [];

  if (isStandalone) {
    return (
      <div className={classNames(styles, 'sequence-container')}>
        {!loading && isStandalone && currentSequence ? (
          <div className='row-sequence-container' ref={containerRef}>
            <div
              className='row-icon-container'
              ref={listRef}
              style={{ width: finalWidth }}
            >
              {_.map(displayedActions, (action, index) => (
                <div
                  className={`icon-container${mini ? ' mini' : ''} ${
                    index >= displayedItemsNumber ? 'hidden' : ''
                  }`}
                  key={index}
                >
                  <div className='icon'>
                    <TaskIcon action={action} />
                  </div>
                  {index !== displayedActions.length - 1 && (
                    <div className='caret-divider' />
                  )}
                </div>
              ))}
            </div>
            <div
              className={`icon-container${mini ? ' mini' : ''} more-actions ${
                displayedItemsNumber > 0 &&
                displayedItemsNumber < displayedActions.length
                  ? ''
                  : 'hidden'
              }`}
            >
              <div className='icon'>
                +{displayedActions.length - displayedItemsNumber}
              </div>
            </div>
          </div>
        ) : (
          '-'
        )}
      </div>
    );
  }

  return (
    <div className={classNames(styles, 'sequence-container')}>
      {!loading && currentSequence ? (
        <div className={`sequence-with-title  ${inlineTitle ? 'inline' : ''}`}>
          {!hideTitle && (
            <p className='sequence-title'>
              {currentSequenceInfo?.sequence?.title || '--'}
            </p>
          )}
          <div
            className={`row-sequence-container ${inlineTitle ? 'inline' : ''}`}
            key={currentSequence?.id}
            ref={containerRef}
          >
            <div
              className='row-icon-container'
              ref={listRef}
              style={{ width: finalWidth }}
            >
              {_.map(displayedActions, (action: any, index) => (
                <div
                  className={`icon-container${mini ? ' mini' : ''} ${
                    index >= displayedItemsNumber ? 'hidden' : ''
                  }`}
                  key={index}
                >
                  {showStateIcons ? (
                    action?.completion?.isCompleted ? (
                      <ActionComplete
                        action={action}
                        getToolTipContent={getToolTipContent}
                        hideTooltip={hideTooltip}
                      />
                    ) : isCurrentSequenceCompleted ? (
                      <ActionStopped
                        action={action}
                        hideTooltip={hideTooltip}
                      />
                    ) : isDue(action) ? (
                      <ActionDue
                        action={action}
                        getToolTipContent={getToolTipContent}
                        hideTooltip={hideTooltip}
                      />
                    ) : isPlanned(action) ? (
                      <ActionPlanned
                        action={action}
                        getToolTipContent={getToolTipContent}
                        hideTooltip={hideTooltip}
                      />
                    ) : null
                  ) : (
                    <div className='icon'>
                      <TaskIcon action={action} />
                    </div>
                  )}
                  {index !== displayedActions.length - 1 && (
                    <div className='caret-divider' />
                  )}
                </div>
              ))}
            </div>
            <div
              className={`icon-container${mini ? ' mini' : ''} more-actions ${
                displayedItemsNumber > 0 &&
                displayedItemsNumber < displayedActions.length
                  ? ''
                  : 'hidden'
              }`}
            >
              <div className='icon'>
                +{displayedActions.length - displayedItemsNumber}
              </div>
            </div>
          </div>
        </div>
      ) : (
        '-'
      )}
    </div>
  );
};

interface ActionProps {
  action: any;
  getToolTipContent: (action: any, state: string) => string | undefined;
  hideTooltip?: boolean;
}

const ActionComplete: React.FC<ActionProps> = ({
  action,
  getToolTipContent,
  hideTooltip,
}) => {
  if (hideTooltip) {
    return (
      <div className='icon icon-grey'>
        <SvgCheckMark className='state complete' />
        <TaskIcon action={action} />
      </div>
    );
  }

  return (
    <GenericTooltip
      trigger={
        <div className='icon icon-grey'>
          <SvgCheckMark className='state complete' />
          <TaskIcon action={action} />
        </div>
      }
      content={getToolTipContent(action, 'completed')}
    />
  );
};

type ActionStoppedProps = Omit<ActionProps, 'getToolTipContent'>;

const ActionStopped: React.FC<ActionStoppedProps> = ({
  action,
  hideTooltip,
}) => {
  const { t } = useTranslation();
  if (hideTooltip) {
    return (
      <div className='icon icon-grey'>
        <SequenceStopIcon className='state stopped' />
        <TaskIcon action={action} />
      </div>
    );
  }

  return (
    <GenericTooltip
      trigger={
        <div className='icon icon-grey'>
          <SequenceStopIcon className='state stopped' />
          <TaskIcon action={action} />
        </div>
      }
      content={t('reveal.missions.mission.sequences.stopped')}
    />
  );
};

const ActionDue: React.FC<ActionProps> = ({
  action,
  getToolTipContent,
  hideTooltip,
}) => {
  if (hideTooltip) {
    return (
      <div className='icon'>
        <Ellipsis className='state due' />
        <TaskIcon action={action} />
      </div>
    );
  }

  return (
    <GenericTooltip
      trigger={
        <div className='icon'>
          <Ellipsis className='state due' />
          <TaskIcon action={action} />
        </div>
      }
      content={getToolTipContent(action, 'due')}
    />
  );
};

const ActionPlanned: React.FC<ActionProps> = ({
  action,
  getToolTipContent,
  hideTooltip,
}) => {
  if (hideTooltip) {
    return (
      <div className='icon'>
        <ActionFuture className='state future' />
        <TaskIcon action={action} />
      </div>
    );
  }

  return (
    <GenericTooltip
      trigger={
        <div className='icon'>
          <ActionFuture className='state future' />
          <TaskIcon action={action} />
        </div>
      }
      content={getToolTipContent(action, 'future')}
    />
  );
};

export default ProfileRowSequence;
