import React from 'react';
import _, { compose } from 'underscore';
import { useTranslation } from 'react-i18next';
import { isLinkedinTaskType } from '@/common/constants/taskTypes';

import SidePanel from '@/components/SidePanel';

import withClientUsers from '@/hocs/clients/withClientUsers';
import withUserFromJWToken from '@/hocs/users/withUserFromJWToken';

import CreateTasksForm from '@/routes/RevealView/SearchView/CandidatesListPane/SideBars/CreateTasksForm';
import GenericButton from '@/components/Common/GenericButton';
import CandidatesWithoutLinkedinWarning from './CandidatesWithoutLinkedinWarning';
import useCreateManualTasksFormState from './hooks/useCreateManualTasksFormState';

import './CreateManualTasksSidebar.css';
import 'react-datepicker/dist/react-datepicker.css';

const CreateManualTasksSidebar = ({
  clientId,
  jobId,
  candidates,
  user,
  users,
  usersLoading,
  onClose,
}) => {
  const { t } = useTranslation();
  const { formState, formOptions } = useCreateManualTasksFormState({
    clientId,
    jobId,
    user,
    users,
    usersLoading,
    candidates,
    onClose,
  });

  let candidatesWithLinkedin;
  let candidatesWithoutLinkedin;

  if (isLinkedinTaskType(formState.taskType)) {
    candidatesWithLinkedin = _.filter(candidates, (candidate) => {
      const { linkedin } = candidate?.resumeData?.sources || {};
      return !!linkedin && !linkedin.includes('/in/None');
    });

    candidatesWithoutLinkedin = _.filter(candidates, (candidate) => {
      const { linkedin } = candidate?.resumeData?.sources || {};
      return !linkedin || linkedin.includes('/in/None');
    });

    // if (candidatesWithLinkedin.length === 0) {
    //   (formState || {}).disabled = true;
    // }
  }

  const footerContent = (
    <>
      {isLinkedinTaskType(formState.taskType) && (
        <CandidatesWithoutLinkedinWarning
          candidatesWithLinkedin={candidatesWithLinkedin}
          candidatesWithoutLinkedin={candidatesWithoutLinkedin}
        />
      )}

      <GenericButton
        className='submit-button'
        form='create-tasks-form'
        type='submit'
        size='big'
        disabled={formState.submissionDisabled || formState?.submitting}
      >
        {t('reveal.createManualTasks.createTasks')}
      </GenericButton>
    </>
  );

  const handleClose = () => {
    if (!formState.submitting) {
      onClose();
    }
  };

  return (
    <SidePanel
      className='create-tasks-sidebar'
      title={t('reveal.createManualTasks.createTasks')}
      footerContent={footerContent}
      onClose={handleClose}
    >
      <CreateTasksForm
        clientId={clientId}
        formState={formState}
        formOptions={formOptions}
      />
    </SidePanel>
  );
};

export default compose(
  withUserFromJWToken,
  withClientUsers,
)(CreateManualTasksSidebar);
