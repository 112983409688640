export const getRoot = (urlString: string): string => {
  try {
    const url = new URL(urlString);
    return url.origin;
  } catch (_e) {
    return urlString;
  }
};

export default {};
