import { graphql } from '@apollo/client/react/hoc';
import { UPDATE_PROFILE_PROCESS_STEP } from '@/graphql/profile';

export default graphql(UPDATE_PROFILE_PROCESS_STEP, {
  props: ({ mutate }) => ({
    updateProfileStage: ({ profileId, value }) => {
      return mutate({
        variables: {
          profileId,
          input: {
            milestoneId: value,
          },
        },
      });
    },
  }),
});
