import _ from 'underscore';

import unformattedOptions from '@/common/options/revealJobPositionExtraOptions.json';
import { SearchItem } from '@/common/search';

export const jobOptions: SearchItem[] = _.map(
  unformattedOptions,
  ({ value, fr, text }) => ({
    id: value,
    labels: {
      en: text,
      fr,
    },
  }),
);
