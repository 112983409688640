import React from 'react';
import { useNavApplicationMode } from './NavSwitch.hook';

const withNavApplicationMode = (Component: any) => {
  return (props: any) => {
    const navApplicationMode = useNavApplicationMode();
    return <Component navApplicationMode={navApplicationMode} {...props} />;
  };
};

export default withNavApplicationMode;
