import qs from 'qs';
import _ from 'underscore';
import { useCallback } from 'react';
import * as Sentry from '@sentry/browser';
import { useHistory, useLocation } from 'react-router-dom';

import useUserTableViewPreferences from '@/graphql/hooks/users/useUserDisplayPreferences';

import { TASK_PROFILES_TABLE_ID } from './TaskTable/TaskTable';

const arrayify = <T>(value: T | Array<T>): Array<T> =>
  Array.isArray(value) ? value : [value];

const arrayifyWithNullValues = <T>(value: T | Array<T>): Array<T | null> => {
  if (Array.isArray(value)) {
    return _.map(value, (item) => item || null);
  }
  return [value || null];
};

const getTodoProjectIdsSearch = (
  newTodoProjectIds: string[],
  previousSearch: string,
): string => {
  let queryString = '';
  const searchParams =
    qs.parse(previousSearch, { ignoreQueryPrefix: true, arrayLimit: 1000 }) ||
    {};
  const { todoProjectId: _tpi, ...restSearch } = searchParams;

  if (_.isEmpty(newTodoProjectIds)) {
    queryString = qs.stringify(restSearch);
  } else {
    queryString = qs.stringify({
      ...restSearch,
      todoProjectId: newTodoProjectIds,
    });
  }

  return queryString;
};

const getJobIdsSearch = (
  newJobIds: (string | null)[],
  previousSearch: string,
): string => {
  let queryString = '';
  const searchParams =
    qs.parse(previousSearch, { ignoreQueryPrefix: true, arrayLimit: 1000 }) ||
    {};
  const { jobId: _ji, ...restSearch } = searchParams;

  if (_.isEmpty(newJobIds)) {
    queryString = qs.stringify(restSearch);
  } else {
    queryString = qs.stringify({ ...restSearch, jobId: newJobIds });
  }

  return queryString;
};

/**
 * like a useState but inside querystring
 */
export const useTodoProjectIds = (): [
  Array<string>,
  (newTodoProjectIds: string[]) => void,
] => {
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const searchParams =
    qs.parse(search, { ignoreQueryPrefix: true, arrayLimit: 1000 }) || {};
  const { todoProjectId = [] } = searchParams;
  const todoProjectIds = arrayify(todoProjectId as string | string[]);
  const setTodoProjectIds = (
    newTodoProjectIdsOrFunction:
      | string[]
      | ((todoProjectIds: string[]) => string[]),
  ): void => {
    let newTodoProjectIds: string[];
    if (_.isFunction(newTodoProjectIdsOrFunction)) {
      newTodoProjectIds = newTodoProjectIdsOrFunction(todoProjectIds);
    } else {
      newTodoProjectIds = newTodoProjectIdsOrFunction;
    }
    const queryString = getTodoProjectIdsSearch(newTodoProjectIds, search);
    history.push({
      ...location,
      search: queryString,
    });
  };
  return [todoProjectIds, setTodoProjectIds];
};

export const useJobIds = (): [
  Array<string | null>,
  (newJobIds: (string | null)[]) => void,
] => {
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const searchParams =
    qs.parse(search, { ignoreQueryPrefix: true, arrayLimit: 1000 }) || {};
  const { jobId = [] } = searchParams;
  const jobIds = arrayifyWithNullValues(
    jobId as (string | null) | (string | null)[],
  );
  const setTodoProjectIds = (
    newJobIdsOrFunction:
      | (string | null)[]
      | ((jobIds: (string | null)[]) => string[]),
  ): void => {
    let newJobIds: (string | null)[];
    if (_.isFunction(newJobIdsOrFunction)) {
      newJobIds = newJobIdsOrFunction(jobIds);
    } else {
      newJobIds = newJobIdsOrFunction;
    }
    const queryString = getJobIdsSearch(newJobIds, search);
    history.push({
      ...location,
      search: queryString,
    });
  };
  return [jobIds, setTodoProjectIds];
};

export type AllowedTaskViewFilters =
  | 'adventure-status'
  | 'adventure-cdii'
  | 'adventure-profil-vivier'
  | 'mission-owner'
  | 'mission-id'
  | 'sequence-id'
  | 'department-id'
  | 'subtype'
  | 'task-owner';

export type FilterEqualityParam = {
  [k in AllowedTaskViewFilters]: string | null;
};

export function useTaskViewFilterDisplayPreferences() {
  const {
    userTableViewPreferences,
    loading,
    updateUserTableViewPreferences,
  } = useUserTableViewPreferences({
    tableId: TASK_PROFILES_TABLE_ID,
    defaultValues: {},
  });

  const adventureStatusFilter = _.findWhere(
    userTableViewPreferences?.filteringOptions?.filters || [],
    { key: 'adventure-status' },
  )?.filter.eq;

  const adventureCDIIFilter = _.findWhere(
    userTableViewPreferences?.filteringOptions?.filters || [],
    { key: 'adventure-cdii' },
  )?.filter.eq;

  const adventureProfilVivierFilter = _.findWhere(
    userTableViewPreferences?.filteringOptions?.filters || [],
    { key: 'adventure-profil-vivier' },
  )?.filter.eq;

  const missionOwnerFilter = _.findWhere(
    userTableViewPreferences?.filteringOptions?.filters || [],
    { key: 'mission-owner' },
  )?.filter.eq;

  const missionIdFilter = _.findWhere(
    userTableViewPreferences?.filteringOptions?.filters || [],
    { key: 'mission-id' },
  )?.filter.eq;

  const sequenceIdFilter = _.findWhere(
    userTableViewPreferences?.filteringOptions?.filters || [],
    { key: 'sequence-id' },
  )?.filter.eq;

  const departmentIdFilter = _.findWhere(
    userTableViewPreferences?.filteringOptions?.filters || [],
    { key: 'department-id' },
  )?.filter.in;

  const subtypeFilter = _.findWhere(
    userTableViewPreferences?.filteringOptions?.filters || [],
    { key: 'subtype' },
  )?.filter.in;

  const taskOwner = _.findWhere(
    userTableViewPreferences?.filteringOptions?.filters || [],
    { key: 'task-owner' },
  )?.filter.eq;

  const updateEqualityFilters = useCallback(
    (newFilters: Partial<FilterEqualityParam>) => {
      const newFilterKeys = new Set(Object.keys(newFilters));
      const { __typename, ...displayPrefs } = userTableViewPreferences || {};
      updateUserTableViewPreferences({
        ...displayPrefs,
        filteringOptions: {
          filters: [
            ..._.filter(
              displayPrefs.filteringOptions?.filters || [],
              (filterOption) => !newFilterKeys.has(filterOption.key),
            ),
            ..._.map(newFilters, (value, key) => {
              return {
                key,
                filter: {
                  eq: Array.isArray(value) ? null : value,
                  in: Array.isArray(value) ? value : null,
                },
              };
            }),
          ],
        },
      }).catch((error) => {
        // info: It does not matter much if this query fails since it has little business value.
        // info: The user will be able to set their filters/views again
        Sentry.captureException(error);
      });
    },
    [userTableViewPreferences, updateUserTableViewPreferences],
  );

  return {
    loading,
    userTableViewPreferences,
    updateUserTableViewPreferences,
    updateEqualityFilters,
    filters: {
      adventureStatusFilter,
      adventureCDIIFilter,
      adventureProfilVivierFilter,
      missionOwnerFilter,
      missionIdFilter,
      sequenceIdFilter,
      departmentIdFilter,
      subtypeFilter,
      taskOwner,
    },
  };
}
