import { useMemo } from 'react';
import { MergeTagsVariable, SNIPPET_TYPES } from '@/common/mergeTags/utils';

const LIGHT_BLUE = '#E2EBFF';
const DARK_BLUE = '#4864C9';
const STRONG_DARK_BLUE = '#1F2E77';
const LIGHT_YELLOW = '#FFF2D5';
const DARK_YELLOW = '#EBA91A';
const LIGHT_RED = '#FFF1F3';
const DARK_RED = '#F66F81';
const LIGHT_GREY = '#7982AD';

const useMergeTagsVariablesColors = (mergeTagsVariable: MergeTagsVariable) => {
  const backgroundColor = useMemo(() => {
    if (
      !mergeTagsVariable ||
      mergeTagsVariable.type === SNIPPET_TYPES.CONDITIONS_CHAINING
    ) {
      return LIGHT_BLUE;
    }
    if (mergeTagsVariable.state?.value) {
      return LIGHT_BLUE;
    }
    if (mergeTagsVariable.fallbackValue?.text) {
      return LIGHT_YELLOW;
    }
    return LIGHT_RED;
  }, [mergeTagsVariable]);

  const variableNameColor = useMemo(() => {
    if (
      !mergeTagsVariable ||
      mergeTagsVariable.type === SNIPPET_TYPES.CONDITIONS_CHAINING
    ) {
      return DARK_BLUE;
    }
    if (mergeTagsVariable.state?.value) {
      return DARK_BLUE;
    }
    if (mergeTagsVariable.fallbackValue?.text) {
      return DARK_YELLOW;
    }
    return DARK_RED;
  }, [mergeTagsVariable]);

  const variableValueColor = useMemo(() => {
    if (
      !mergeTagsVariable ||
      mergeTagsVariable.type === SNIPPET_TYPES.CONDITIONS_CHAINING
    ) {
      return STRONG_DARK_BLUE;
    }
    if (
      mergeTagsVariable.state?.value ||
      mergeTagsVariable.fallbackValue?.text
    ) {
      return STRONG_DARK_BLUE;
    }
    return LIGHT_GREY;
  }, [mergeTagsVariable]);

  return {
    backgroundColor,
    variableNameColor,
    variableValueColor,
  };
};

export default useMergeTagsVariablesColors;
