import _ from 'underscore';
import React, { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import DatePicker from 'react-datepicker';
import TimeSelectorModal from '@/components/Common/TimeSelectorModal';

// Types
import {
  CustomTimeSelectionComponent,
  TimeSelectorModalProps,
} from '@/components/Common/TimeSelectorModal/TimeSelectorModal';

import timeSelectorStyles from '@/components/Common/TimeSelectorModal/TimeSelectorModal.module.less';
import GenericDateTimeFormat from '@/components/Common/GenericDateTimeFormat';
import style from './ScheduleEmail.module.less';

const DAYS = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};

const DAY_TIMES = {
  MORNING: 9, // morning / maitn
  NOON: 14, // après-midi / after lunch
  EVENING: 18, // fin d'après midi / evening
};

const DAY_TIME_TRANSLATIONS = {
  [DAY_TIMES.MORNING]: 'common.timeOfDay.morning',
  [DAY_TIMES.NOON]: 'common.timeOfDay.noon',
  [DAY_TIMES.EVENING]: 'common.timeOfDay.evening',
};

interface ScheduleEmailProps {
  onSubmit: (args: { date: string; timezone: string }) => void;
}

// Get default time options for a given day offset from today
const getDefaultOptionsForDay = ({ dayOffset = 0 } = {}) => {
  const today = new Date();
  const desiredDay = new Date(today.setDate(today.getDate() + dayOffset));
  // morning, noon, evening
  // hours, minutes, seconds, millis
  return _.map(Object.values(DAY_TIMES), (hour) =>
    new Date(desiredDay.setHours(hour, 0, 0, 0)).toISOString(),
  );
};

// Now is passed as an argument to be able to test the function
// without mocking the Date object
const computeSchedulingOptionsFromNow = (now = new Date()) => {
  // Business logic for scheduling options
  // We always display next monday (except if we are on a monday)
  // We display a window of 3 possibilities of every day
  // morning, noon, evening
  // and we slide on the window on each day, ex:
  // we are tuesday noon, so we display:
  // tuesday evening, wed morning, wed noon, next monday morning
  // Lastly we display a custom block with a calendar

  // Gives us the number of days we need to advance to get to next monday
  const nextMondayOffset = 8 - now.getDay();
  const mondayOptions = getDefaultOptionsForDay({
    dayOffset: nextMondayOffset,
  });

  // On saturday and sunday we only display monday options
  if ([DAYS.SATURDAY, DAYS.SUNDAY].includes(now.getDay())) {
    return mondayOptions;
  }

  // Compute options for today and the following day
  let dayOffset = 1;
  if (now.getDay() === DAYS.FRIDAY) {
    // Special case for friday, we compute both friday and next monday
    dayOffset = 3;
  }

  const allTodayOptions = getDefaultOptionsForDay();
  const allTomorrowOptions = getDefaultOptionsForDay({ dayOffset });

  // Get the number of options today allows
  let allowedTodayOptions = 3;

  // TODO: check minutes? maybe sneding an email 3 mins before is not very nic
  if (now.getHours() >= DAY_TIMES.MORNING) {
    // allow noon and evening
    allowedTodayOptions = 2;
  }

  if (now.getHours() >= DAY_TIMES.NOON) {
    // allow evening
    allowedTodayOptions = 1;
  }

  if (now.getHours() >= DAY_TIMES.EVENING) {
    // too late, only show tomorrow and monday if needed
    allowedTodayOptions = 0;
  }

  const [nextMondayMorning] = mondayOptions;

  const todayOptions = allowedTodayOptions
    ? allTodayOptions.slice(-allowedTodayOptions)
    : [];
  const tomorrowOptions = allTomorrowOptions.slice(0, 3 - allowedTodayOptions);

  const result = [...todayOptions, ...tomorrowOptions];
  // If this is not a monday we can show "next monday morning" otpion
  // except for Fridays since all options are already on monday
  if (now.getDay() > 1 && now.getDay() < 5) {
    return [...result, nextMondayMorning];
  }

  return result;
};

const RelativeDateTranslation = ({ date }: { date: string }): string => {
  const { t } = useTranslation();

  const dateTranslation = useMemo(() => {
    const dateObject = new Date(date);

    const todayDate = new Date().getDate();
    if (dateObject.getDate() === todayDate) {
      return t('common.today');
    }

    const tomorrowDate = new Date();
    // Automatically goes to 1st if needed
    tomorrowDate.setDate(todayDate + 1);
    if (dateObject.getDate() === tomorrowDate.getDate()) {
      return t('common.tomorrow');
    }

    return t('common.nextMonday');
  }, [date, t]);

  const timeOfDay = useMemo(() => {
    const dateObject = new Date(date);
    const hours = dateObject.getHours();
    return DAY_TIME_TRANSLATIONS[hours] ? t(DAY_TIME_TRANSLATIONS[hours]) : '';
  }, [date, t]);

  return t('profile.contact.drafts.scheduleModal.relativeDate', {
    date: dateTranslation,
    timeOfDay,
  });
};

const ScheduleEmailCalendar: CustomTimeSelectionComponent = ({
  currentTime,
  onChange,
}) => {
  const now = new Date();

  return (
    <div className={style.datePickerContainer}>
      <DatePicker
        inline
        showTimeSelect
        timeIntervals={15}
        filterTime={(selectedDate) => now.getTime() < selectedDate.getTime()}
        selected={currentTime ? new Date(currentTime) : new Date()}
        minDate={now}
        className='datepicker'
        dateFormat='MMMM d, yyyy'
        onChange={(date: Date) => {
          return onChange(date.toISOString());
        }}
      />
    </div>
  );
};

const ScheduleEmailCalendarValue: CustomTimeSelectionComponent = ({
  currentTime,
}) => {
  if (!currentTime) {
    return null;
  }
  return (
    <span className={timeSelectorStyles.time}>
      <GenericDateTimeFormat date={new Date(currentTime)} />
    </span>
  );
};

const ScheduleEmail: FC<ScheduleEmailProps & TimeSelectorModalProps> = ({
  onChange: _onChange,
  onSubmit,
  ...props
}) => {
  const { t } = useTranslation();
  const schedulingOptions = useMemo(() => {
    const options = computeSchedulingOptionsFromNow();
    return _.map(options, (option) => {
      return {
        // Hacks because TS does not like strings and elements together
        label: ((
          // @ts-ignore
          <RelativeDateTranslation date={option} />
        ) as unknown) as string,
        time: option,
        formatter: (date: string) =>
          ((
            <GenericDateTimeFormat date={new Date(date)} />
          ) as unknown) as string,
      };
    });
  }, []);

  return (
    <TimeSelectorModal
      title={t('profile.contact.drafts.scheduleModal.title')}
      label={t('profile.contact.drafts.scheduleModal.label')}
      submitLabel={t('profile.contact.drafts.scheduleModal.submit')}
      defaultTime={schedulingOptions[0].time}
      defaultOptions={schedulingOptions}
      customSelectionComponent={ScheduleEmailCalendar}
      customSelectionValue={ScheduleEmailCalendarValue}
      onChange={(date, timezone) => {
        if (!date) {
          return;
        }
        onSubmit({ date, timezone });
      }}
      {...props}
    />
  );
};

export default ScheduleEmail;
