import { createContext } from 'react';

interface MissionDropdownControls {
  toggleMissionDropdown: (() => void) | null;
}

const MissionDropdownControlsContext = createContext<MissionDropdownControls>({
  toggleMissionDropdown: null,
});

export default MissionDropdownControlsContext;
