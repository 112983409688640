import { useQuery, QueryHookOptions, QueryResult } from '@apollo/client';
import ENRICHED_SEARCH_POOL_PROFILE from '@/graphql/enrichedSearchPoolProfile';

interface UseEnrichedSearchPoolProfileVariables {
  searchPoolId: string;
  id: string;
}

const useEnrichedSearchPoolProfile = (
  { searchPoolId, id }: UseEnrichedSearchPoolProfileVariables,
  options?: QueryHookOptions,
): QueryResult =>
  useQuery(ENRICHED_SEARCH_POOL_PROFILE, {
    ...options,
    variables: { searchPoolId, id },
  });

export default useEnrichedSearchPoolProfile;
