import _ from 'underscore';
import { useCallback, useContext, useEffect } from 'react';
import {
  BundleAndQueryArgs,
  DataLoaderSegmentProfilesContext,
} from './DataLoaderSegmentProfilesContext';
import { SegmentDefinition } from './types';

const useDataLoaderSegmentProfiles = (segmentDefinition: SegmentDefinition) => {
  const context = useContext(DataLoaderSegmentProfilesContext);
  const { bundleAndQuery, data, dataCount } = context || {};

  const fetch = useCallback(
    (...args: BundleAndQueryArgs) => {
      // stocke & lance la query plus tard
      if (bundleAndQuery) {
        bundleAndQuery(...args);
      }
    },
    [bundleAndQuery],
  );

  useEffect(() => {
    if (fetch) {
      fetch(segmentDefinition, {
        queryOptions: {
          // We use network-only here to prevent apollo from reading this value from cache
          // We have a very specific cache issue with this resolver and I'm not sure
          // how we're gonna be able to solve it easily, but essentially since this is paginated
          // and it's an array of arrays, we told apollo not to use input as cache keys (because offset)
          // and then we handled the `merge` function manually as per the docs. However if you
          // unmount/remount this component you want it to perform the query again, hence the network-only.
          // @see DataLoaderSegmentProfilesContext for more info
          fetchPolicy: 'network-only',
        },
      });
    }
  }, [fetch, segmentDefinition]);

  return {
    fetch,
    dataCount: _.findWhere(dataCount?.searchPool?.segmentProfileResults, {
      segmentId: segmentDefinition?.segmentId,
    }),
    data: _.findWhere(data?.searchPool?.segmentProfileResults, {
      segmentId: segmentDefinition?.segmentId,
    }),
  };
};

export default useDataLoaderSegmentProfiles;
