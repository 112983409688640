import React from 'react';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import routerParamsToProps from '@/hocs/routerParamsToProps';
import MainLayout from '@/containers/MainLayout';
import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import useQuickActions from '@/hooks/common/useQuickActions';
import usePowerHours from '@/graphql/hooks/powerHours/usePowerHours';
import { getSoonestPowerHour } from '@/common/helpers/powerHours';
import PowerHoursLegacyBanner from '@/containers/Parameters/Offers/components/PowerHoursLegacyBanner';

import OffersDashboard from './OffersDashboard';
import UsersDashboard from './UsersDashboard';
import TemplatesDashboard from './TemplatesDashboard';
import NotificationsDashboard from './NotificationsDashboard';
import ActivityDashboard from './ActivityDashboard';
import GetStartedDashboard from './GetStartedDashboard';
import DashboardQuickActions from './DashboardQuickActions';
import WatchCollectBanner from './WatchCollectBanner';
import RecommendedCandidatesDashboard from './RecommendedCandidatesDashboard';
import { NB_PROFILES_ON_CAROUSEL } from './RecommendedCandidatesDashboard/constants';
import QuickActionsModals from './QuickActionsModals';

import './Dashboard.css';

const Dashboard = ({ clientId, toggleMenuVisibility, t }) => {
  const { applicationMode, loading } = useNavApplicationMode();
  const { modals, quickActions } = useQuickActions({
    clientId,
    mode: 'classic',
  });

  const { powerHours } = usePowerHours();
  const soonestPowerHour = getSoonestPowerHour(powerHours);

  if (loading) {
    return (
      <MainLayout pageId='dashboard' clientId={clientId}>
        <div className='dashboard'>
          <Loader active />
        </div>
      </MainLayout>
    );
  }

  if (applicationMode === 'reveal') {
    return <Redirect to={`/client/${clientId}/reveal/projects`} />;
  }

  return (
    <MainLayout
      pageId='dashboard'
      clientId={clientId}
      toggleMenuVisibility={toggleMenuVisibility}
    >
      <div className='dashboard'>
        <h1>{t('dashboard.header')}</h1>
        <div className='dashboard-columns'>
          <div className='column-1'>
            {soonestPowerHour ? (
              <PowerHoursLegacyBanner
                powerHour={soonestPowerHour}
                className='dashboard-power-hour-banner'
              />
            ) : (
              <WatchCollectBanner clientId={clientId} />
            )}
            <RecommendedCandidatesDashboard
              clientId={clientId}
              limit={NB_PROFILES_ON_CAROUSEL}
            />
            <OffersDashboard clientId={clientId} />
            <UsersDashboard clientId={clientId} />
            <TemplatesDashboard clientId={clientId} />
          </div>
          <div className='column-2'>
            <DashboardQuickActions
              clientId={clientId}
              mode='classic'
              quickActions={quickActions}
            />
            <GetStartedDashboard
              clientId={clientId}
              quickActions={quickActions}
            />
            <NotificationsDashboard clientId={clientId} />
            <ActivityDashboard clientId={clientId} />
          </div>
        </div>
      </div>
      <QuickActionsModals modals={modals} clientId={clientId} mode='classic' />
    </MainLayout>
  );
};

export default compose(
  withTranslation('translations'),
  routerParamsToProps,
)(Dashboard);
