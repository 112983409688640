import { GET_USER_SENDERS } from '@/graphql/users';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Sender from '../../graphql/fragments/Sender';

export const mutation = gql`
  mutation addSender($senderInput: NewSenderInput!) {
    addSender(senderInput: $senderInput) {
      ...Sender
    }
  }
  ${Sender}
`;

export default graphql(mutation, {
  props: ({ mutate }) => {
    return {
      addSender: (senderInput) =>
        mutate({
          variables: { senderInput },
          refetchQueries: [
            {
              query: GET_USER_SENDERS,
              variables: { owned: true, granted: true },
            },
          ],
        }),
    };
  },
});
