import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import styles from './Content.module.less';

const Content: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <h1>{t(`onboarding.bullets.title`)}</h1>
      <div className={styles.bullets}>
        {_.map(_.range(3), (index) => (
          <div className={styles.bullet} key={index}>
            <div className={styles.index}>{index + 1}</div>
            <div className={styles.title}>
              {t(`onboarding.bullets.points.${index}.title`)}
            </div>
            <div className={styles.description}>
              {t(`onboarding.bullets.points.${index}.description`)}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Content;
