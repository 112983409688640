import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'semantic-ui-react';

import styles from './ShowMore.module.less';

export interface ShowMoreRowProps {
  items: unknown[];
  displayLimit: number;
  onShowMore: () => void;
  onReset: () => void;
  initialLimit?: number;
  limitStep?: number;
}

// Simple component to display a show-more button
export const ShowMoreRow: FC<ShowMoreRowProps> = ({
  items,
  displayLimit,
  initialLimit = 20,
  limitStep = 10,
  onShowMore,
  onReset,
}) => {
  const { t } = useTranslation();
  const remainingItemsLength = items.length - displayLimit;

  if (items.length <= initialLimit) {
    return null;
  }

  return (
    <div className={styles.showMoreTasks}>
      <div className={styles.showMoreActions}>
        {displayLimit < items.length && (
          <div onClick={onShowMore}>
            <i className='ri-arrow-drop-down-line ri-2x' />
            <p>{t('common.seeMore')}</p>
            <Label>
              {remainingItemsLength >= limitStep
                ? limitStep
                : remainingItemsLength}
            </Label>
          </div>
        )}
        {displayLimit >= items.length && displayLimit > initialLimit && (
          <div onClick={onReset}>
            <i className='ri-arrow-drop-up-line ri-2x' />
            <p>{t('common.seeLess')}</p>
          </div>
        )}
      </div>
      <p>
        {t('common.pagination.currentPageResults', {
          current: Math.min(displayLimit, items.length),
          total: items.length,
        })}
      </p>
    </div>
  );
};
