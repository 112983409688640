import { useMutation } from '@apollo/client';
import {
  updateClientSSOSettings,
  UpdateClientSSOSettingsResult,
  UpdateClientSSOSettingsVariables,
} from '../../../clients/clientSSOSettings';

const useUpdateClientSSOSettings = () => {
  return useMutation<
    UpdateClientSSOSettingsResult,
    UpdateClientSSOSettingsVariables
  >(updateClientSSOSettings);
};

export default useUpdateClientSSOSettings;
