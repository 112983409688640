import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const mutation = gql`
  mutation stopConfirmationModal($clientId: ID!) {
    updateUser(clientId: $clientId, input: { showConfirmationModal: false }) {
      id
      showConfirmationModal
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    stopConfirmationModal: ({ clientId }) =>
      mutate({ variables: { clientId } }),
  }),
});
