import gql from 'graphql-tag';

export default gql`
  fragment CandidateClientInterestStates on HiresweetData {
    clientInterestStates {
      clientId
      interestItems {
        id
        type
        state
        message {
          text
        }
        creationDate
        lastEditionDate
        ... on CandidateInterestItemJobOffer {
          jobOfferId
        }
        ... on CandidateInterestItemCompanyDepartment {
          departmentId
        }
      }
    }
  }
`;
