import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import OfferRecruiterConfiguration from '../../graphql/fragments/OfferRecruiterConfiguration';

export const deleteRecruiterConfiguration = gql`
  mutation deleteRecruiterConfiguration($id: ID!, $configId: ID!) {
    deleteRecruiterConfiguration(id: $id, configId: $configId) {
      id
      recruiterConfigurations {
        ...OfferRecruiterConfiguration
      }
    }
  }
  ${OfferRecruiterConfiguration}
`;

export default graphql(deleteRecruiterConfiguration, {
  props: ({ mutate }) => ({
    deleteRecruiterConfiguration: ({ id, configId }) => {
      return mutate({ variables: { id, configId } });
    },
  }),
});
