import React from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'qs';

const RedirectView = ({ match, location }) => {
  const encodedTarget = qs.parse(location.search, { ignoreQueryPrefix: true })?.target;
  const target = decodeURIComponent(encodedTarget || '');

  const trailingPath = match.params[0] || '';
  const search = location.search || '';

  const redirection = trailingPath + search || target;
  const redirectionQS = redirection ? `?redirect=${encodeURIComponent(redirection)}` : '';

  return <Redirect to={{ pathname: '/signin', search: redirectionQS }} replace />;
};

export default RedirectView;
