import _ from 'underscore';

interface Message {
  templateId: string;
}

interface Action {
  messages: Array<Message>
  type: string
}

interface Rule {
  id: string;
}

interface SuggestedAction {
  action: Action;
  rule: Rule;
}

interface ContactFlowAction {
  message: Message
}

interface ContactFlowSequence {
  actions: Array<ContactFlowAction>
  position: string
}
interface ContactFlow {
  sequences: Array<ContactFlowSequence>;
}

interface Job {
  defaultActionSuggestions: Array<SuggestedAction>
  contactFlow: ContactFlow
}

export const getJobTemplatesId = (job: Job): Array<string> => {
  const sequences = job?.contactFlow?.sequences;
  const sequence = (sequences || [])[0];
  if (sequence && sequence.actions) {
    return _.compact(_.map(sequence.actions, ({ message }) => message?.templateId));
  }

  const suggestedActions = job && job.defaultActionSuggestions ? job.defaultActionSuggestions : [];
  const firstContactSuggestedAction = _.find(
    suggestedActions,
    (suggestedAction) => suggestedAction.rule && suggestedAction.rule.id === 'firstContact',
  );
  const action = firstContactSuggestedAction ? firstContactSuggestedAction.action : undefined;
  const messages = action && action.messages ? action.messages : [];
  return _.map(messages, (message) => message.templateId);
};