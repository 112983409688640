import React from 'react';

import LogoCircle from '@/components/LogoCircle';

import SpeechBubble from './SpeechBubble';
import SendMailMockup from './SendMailMockup';

import styles from './Illustration.module.less';

const Illustration = () => {
  return (
    <div className={styles.visual}>
      <SpeechBubble className={styles.bubble} />
      <SendMailMockup className={styles.mockup} />
      <div className={styles.logos}>
        <LogoCircle src='/images/logos/gmail.svg' alt='gmail' />
        <LogoCircle src='/images/logos/outlook.svg' alt='outlook' />
      </div>
    </div>
  );
};

export default Illustration;
