import React from 'react';
import _, { compose } from 'underscore';
import * as Sentry from '@sentry/browser';
import { withTranslation } from 'react-i18next';
import { Input, Accordion, Form } from 'semantic-ui-react';
import GenericButton from '@/components/Common/GenericButton';
import withClientPermissions from '@/hocs/clients/withClientPermissions';
import { passwordIsStrong } from '../../../common';
import routerParamsToProps from '../../../hocs/routerParamsToProps';
import withUserSettings from '../../../hocs/users/withUserSettings';
import withUpdateUserSettings from '../../../hocs/users/withUpdateUserSettings';
import withEmailContextConsumer from '../../../hocs/email/withEmailContextConsumer';
import withAddClientIntegration from '../../../hocs/integrations/withAddClientIntegration';

import sanitizeSignature from '../../../common/sanitizeSignature';

import SettingsLayout from '../../SettingsLayout';
import { DownIcon } from '../../../assets/icons';

import './Settings.css';

const getErrorMessage = ({ error, t }) => {
  const errorInvalidEmail =
    error === 'new password invalid'
      ? t('settings.notifications.invalidPassword')
      : error;
  return error === 'GraphQL error: Wrong credentials'
    ? t('settings.notifications.wrongPassword')
    : errorInvalidEmail;
};

class ClientParameters extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorPassword: false,
      errorNewPassword: false,
      input: {
        oldPassword: '',
        newPassword: '',
      },
      disableSubmit: false,
      passwordAccordionActive: false,
    };
    this.onChange = this.onChange.bind(this);
  }

  languageOptions = [
    { value: 'fr', text: 'Français', key: 'fr' },
    { value: 'en', text: 'English', key: 'en' },
  ];

  handleKeyDown = (e) => {
    // 13 is the Enter key code
    /*
    if (e.keyCode === 13) {
      this.onSubmit();
    }
    */
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);

    const { user } = this.props;
    if (user) {
      const {
        alias,
        signature,
        description,
        language,
        showConfirmationModal,
        unbiasedModeEnabled,
      } = user;
      // if the user has a selected language in the base, then use this. If not, use the one from i18next
      const UsedLanguage = language || this.props.i18n.resolvedLanguage;
      this.setState(({ input }) => ({
        input: {
          ...input,
          alias,
          signature,
          description,
          language: UsedLanguage,
          showConfirmationModal,
          unbiasedModeEnabled,
        },
      }));
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      const {
        alias,
        signature,
        language,
        description,
        showConfirmationModal,
        unbiasedModeEnabled,
      } = nextProps.user;
      const UsedLanguage = language || nextProps.i18n.resolvedLanguage || '';
      this.setState(({ input }) => ({
        input: {
          ...input,
          alias: alias || '',
          signature: sanitizeSignature(signature || ''),
          description: description ?? '',
          language: UsedLanguage,
          showConfirmationModal,
          unbiasedModeEnabled,
        },
      }));
    }
  }

  onChange = (ev) => {
    const { name, value } = ev.target;
    this.setState(({ input }) => ({
      input: {
        ...input,
        [name]: value,
      },
      errorNewPassword: false,
      errorPassword: false,
    }));
  };

  onChangeLanguage = (ev, { value }) => {
    this.setState(({ input }) => ({
      input: {
        ...input,
        language: value,
      },
    }));
  };

  onChangeDescription = (ev, { value }) => {
    this.setState(({ input }) => ({
      input: {
        ...input,
        description: value,
      },
    }));
  };

  onChangeUnbiasedModeEnabled = (ev, { checked }) => {
    this.setState((prevState) => ({
      input: {
        ...prevState.input,
        unbiasedModeEnabled: checked,
      },
    }));
  };

  onSubmit = () => {
    this.setState({ error: false });
    const { input, passwordAccordionActive } = this.state;
    window.postMessage(
      {
        type: 'set-locale',
        data: {
          locale: input.language,
        },
      },
      '*',
    );
    const { emailApi, updateUser, t } = this.props;
    if (
      passwordAccordionActive &&
      input.oldPassword !== '' &&
      !passwordIsStrong(input.newPassword)
    ) {
      this.setState({
        error: 'new password invalid',
        errorNewPassword: true,
      });
      setTimeout(() => this.setState({ error: null }), 6000);
    } else if (
      passwordAccordionActive &&
      input.oldPassword === '' &&
      input.newPassword !== ''
    ) {
      this.setState({
        error: t('settings.notifications.wrongPassword'),
        errorPassword: true,
      });
      setTimeout(() => this.setState({ error: null }), 6000);
    } else {
      this.setState({ disableSubmit: true });
      const userInput = !passwordAccordionActive
        ? _.omit(input, 'oldPassword', 'newPassword')
        : input;
      updateUser(userInput)
        .then(() => {
          const oldInput = input;

          emailApi.setSendConfirmationMode(input.showConfirmationModal);

          this.setState({
            disableSubmit: false,
            error: false,
            justSaved: true,
            errorPassword: false,
            errorNewPassword: false,
            input: {
              ...oldInput,
              oldPassword: '',
              newPassword: '',
            },
          });

          setTimeout(() => this.setState({ justSaved: false }), 1000);
        })
        .catch((e) => {
          Sentry.captureException(e);
          this.setState({
            disableSubmit: false,
            error: e.message,
            errorNewPassword: false,
            errorPassword: e.message === 'GraphQL error: Wrong credentials',
          });
          setTimeout(() => this.setState({ error: null }), 3000);
        });
    }
  };

  render() {
    const { clientId, user, permissions, t } = this.props;
    const {
      error,
      errorPassword,
      errorNewPassword,
      input,
      justSaved,
      disableSubmit,
      passwordAccordionActive,
    } = this.state;

    if (!user) {
      return <div />;
    }

    return (
      <SettingsLayout
        currentPage='settings'
        clientId={clientId}
        className='settings-container'
      >
        <Form className='settings' onSubmit={this.onSubmit}>
          <div className='settings-header'>
            <h1>{t('settings.personalSettings.title')}</h1>
            <div className='settings-submit'>
              <div>
                <GenericButton type='submit' disabled={disableSubmit}>
                  {t('settings.saveButton')}
                </GenericButton>
              </div>
              <div className='settings-submit-message'>
                {error ? (
                  <span className='error-message'>
                    {getErrorMessage({ error, t })}
                  </span>
                ) : null}
              </div>
              <div className='settings-submit-message'>
                {justSaved ? (
                  <span className='just-saved'>
                    {t('settings.notifications.savedWithSuccess')}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <h2>{t('settings.configuration')}</h2>
          <div className='section visible'>
            <div className='input-container'>
              <div className='input-label'>
                {t('settings.languageSection.language')}
              </div>
              <div className='input-element half-width'>
                <div className='language-field'>
                  <Form.Dropdown
                    fluid
                    selection
                    options={this.languageOptions}
                    onChange={this.onChangeLanguage}
                    value={input.language}
                  />
                </div>
              </div>
              <br/>
              {permissions?.canEditUserDescription ? (
                <>
                  <div className='input-label'>
                    Description
                  </div>
                  <div className='input-element'>
                    <div className='description-field'>
                      <Form.TextArea 
                        style={{ resize: 'none' }}
                        value={input.description} 
                        onChange={this.onChangeDescription}
                      />
                    </div>
                  </div>
                </>
              ) : ''}
            </div>

            

            <div className='input-container'>
              <Accordion className='hs-accordion'>
                <Accordion.Title
                  active={passwordAccordionActive}
                  icon={
                    <DownIcon className='dropdown icon password-accordion-title' />
                  }
                  content={
                    <span className='password-accordion-title'>
                      {t('settings.passwordSection.header')}
                    </span>
                  }
                  onClick={() =>
                    this.setState(
                      ({ passwordAccordionActive: accordionState }) => ({
                        passwordAccordionActive: !accordionState,
                      }),
                    )
                  }
                />

                <Accordion.Content
                  active={passwordAccordionActive}
                  className='password-accordion-content'
                >
                  <div className='inputs-row'>
                    <div className='input-container half-width'>
                      <div className='input-label'>
                        {t('settings.passwordSection.oldPassword')}
                      </div>
                      <div className='input-element'>
                        <div className='old-password-field'>
                          {errorPassword ? (
                            <Input
                              type='password'
                              name='oldPassword'
                              value={input.oldPassword}
                              onChange={this.onChange}
                              placeholder={t(
                                'settings.passwordSection.password',
                              )}
                              input={{ autoComplete: 'chrome-off' }}
                              className='input-error'
                              fluid
                            />
                          ) : (
                            <Input
                              type='password'
                              name='oldPassword'
                              value={input.oldPassword}
                              onChange={this.onChange}
                              placeholder={t(
                                'settings.passwordSection.password',
                              )}
                              input={{ autoComplete: 'chrome-off' }}
                              fluid
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='input-container half-width'>
                      <div className='input-label'>
                        {t('settings.passwordSection.newPassword')}
                      </div>
                      <div className='input-element'>
                        <div className='new-password-field'>
                          {errorNewPassword ? (
                            <Input
                              type='password'
                              name='newPassword'
                              value={input.newPassword}
                              onChange={this.onChange}
                              placeholder={t(
                                'settings.passwordSection.password',
                              )}
                              input={{ autoComplete: 'chrome-off' }}
                              className='input-error'
                              fluid
                            />
                          ) : (
                            <Input
                              type='password'
                              name='newPassword'
                              value={input.newPassword}
                              onChange={this.onChange}
                              placeholder={t(
                                'settings.passwordSection.password',
                              )}
                              input={{ autoComplete: 'chrome-off' }}
                              fluid
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Content>
              </Accordion>
            </div>
          </div>
          {/*
          <h2>{t('settings.additionalOptions.header')}</h2>
          <div className='section visible'>
            <div className='input-container'>
              <div className='input-label'>{t('settings.additionalOptions.unbiasedMode')}</div>
              <div className='input-element'>
                <div className='input-element'>
                  <Checkbox
                    label={t('settings.additionalOptions.unbiasedModeEnable')}
                    checked={!!input?.unbiasedModeEnabled}
                    name='unbiased-mode'
                    onChange={this.onChangeUnbiasedModeEnabled}
                  />
                </div>

                <div className='input-description'>{t('settings.additionalOptions.unbiasedModeToolTip')}</div>
              </div>
            </div>
          </div>
          */}
        </Form>
      </SettingsLayout>
    );
  }
}

export default compose(
  routerParamsToProps,
  withUserSettings,
  withClientPermissions,
  withUpdateUserSettings,
  withTranslation('translations'),
  withEmailContextConsumer,
  withAddClientIntegration,
)(ClientParameters);
