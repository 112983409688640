import _ from 'underscore';
import { isLinkedInPhantomBusterAutomation } from '@/types/externalAutomation';
import { isEmailAction, TASK_TYPES } from '../constants/taskTypes';

// editType can be "insert" or "update"
const getPermissionsAndNudgesAtIndex = ({ actions, index, editType, t }) => {
  const isBlocking = ({ action }) => action?.trigger?.type === 'manual-trigger';

  // The following two code blocks do not handle emails as they are already handled by the pre-existing code
  // TO-DO: Rewrite this part of the contactFlow (part of the bigger contactFlow rewrite)
  /*
  const previousManualActionOfSameTypeIndex = _.findIndex(
    actions,
    (action, actionIndex) => {
      return (
        actionIndex < index &&
        action.type === actions[index]?.type &&
        isBlocking({ action: actions[index] }) &&
        _.includes([TASK_TYPES.LINKEDIN_SEND_MESSAGE], actions[index?.type])
      );
    },
  );
  
  const followupActionOfSameTypeIndex = _.findIndex(
    actions,
    (action, actionIndex) => {
      return (
        actionIndex > index &&
        action.type === actions[index]?.type &&
        action.trigger.type === 'delay-after-action' &&
        _.includes([TASK_TYPES.LINKEDIN_SEND_MESSAGE], actions[index?.type])
      );
    },
  );
  */

  const firstNonBlockingActionIndex = _.findIndex(
    actions,
    (action) => !isBlocking({ action })/* && isEmailAction(action) */,
  );
  const firstSendIndex = _.findIndex(actions, (action) =>
    isEmailAction(action),
  );
  const secondSendIndex = _.findIndex(
    actions,
    (action, actionIndex) =>
      firstSendIndex >= 0 &&
      actionIndex !== firstSendIndex &&
      isEmailAction(action),
  );

  const getDeletionPermissionAndNudge = () => {
    if (editType !== 'update') {
      return { isAllowed: true };
    }
    if (
      (index === firstSendIndex && secondSendIndex >= 0)/* ||
      (isBlocking({ action: actions[index] }) &&
        followupActionOfSameTypeIndex > index) */
    ) {
      return {
        isAllowed: false,
        reason: t('contactFlow.triggers.permissions.removeActionWithFollowups'),
      };
    }
    if (
      index === 0 &&
      actions.length >= 2 &&
      actions[1]?.trigger?.type !== 'manual-trigger'
    ) {
      return {
        isAllowed: false,
        reason: t(
          'contactFlow.triggers.permissions.removeActionsWithNotManualNextAction',
        ),
      };
    }
    return { isAllowed: true };
  };

  const getManualTriggerPermissionAndNudge = () => {
    if (editType === 'insert') {
      return { isAllowed: true };
    }
    const action = actions[index];
    // do not use isEmailAction here, only 'send-email-action'
    if (action.type === 'send-email-action' && index > firstSendIndex) {
      return {
        isAllowed: false, // TODO: review this policy, do we still want to enforce it for reveal ?
        reason: t('contactFlow.triggers.permissions.automaticFollowups'),
      };
    }
    return { isAllowed: true };
  };

  const getNonManualTriggerPermissionAndNudge = () => {
    if (editType === 'insert') {
      return index === 0
        ? {
            isAllowed: false,
            reason: t('contactFlow.triggers.permissions.manualFirstAction'),
          }
        : {
            isAllowed: true,
            isNudged:
              index >= firstNonBlockingActionIndex ||
              (firstSendIndex >= 0 && index > firstSendIndex),
          };
    }

    // editType === 'update'
    const action = actions[index];
    if (action.type === 'send-email-action' && index === firstSendIndex) {
      return {
        isAllowed: false,
        reason: t('contactFlow.triggers.permissions.manualFirstContactAction'),
      };
    }
    /*
    if (
      previousManualActionOfSameTypeIndex === -1 &&
      action.type === 'linkedin-send-message'
    ) {
      return {
        isAllowed: false,
        reason: t('contactFlow.triggers.permissions.manualFirstContactAction'),
      };
    }
    */
    if (index === 0) {
      return {
        isAllowed: false,
        reason: t('contactFlow.triggers.permissions.manualFirstAction'),
      };
    }

    return { isAllowed: true };
  };

  const getWaitingForCompletionPermissionAndNudge = () => {
    if (editType === 'insert') {
      return {
        isAllowed: true,
        isNudged: firstSendIndex < 0 || index <= firstSendIndex,
      };
    }
    return { isAllowed: true };
  };

  const getLinkedinInvitationAcceptedTriggerPermissionAndNudge = () => {
    if (index === 0) {
      return { isAllowed: false };
    }
    const linkedinActions = [
      TASK_TYPES.LINKEDIN_SEND_INMAIL,
      TASK_TYPES.LINKEDIN_SEND_MESSAGE,
      TASK_TYPES.LINKEDIN_CHECK_REQUEST,
      TASK_TYPES.CUSTOM,
    ];
    const action = actions[index];

    if (!_.contains(linkedinActions, action?.type)) {
      return { isAllowed: false };
    }

    if (
      action?.type === TASK_TYPES.CUSTOM &&
      !isLinkedInPhantomBusterAutomation(action?.execution?.phantomAgentType)
    ) {
      return { isAllowed: false };
    }

    const invitationActionIndex = _.findIndex(actions, isLinkedInInviteAction);
    if (invitationActionIndex === -1) {
      return { isAllowed: false };
    }
    return { isAllowed: invitationActionIndex < index };
  };

  return {
    deletion: getDeletionPermissionAndNudge(),
    manualTrigger: getManualTriggerPermissionAndNudge(),
    nonManualTrigger: getNonManualTriggerPermissionAndNudge(),
    waitingForCompletion: getWaitingForCompletionPermissionAndNudge(),
    linkedinInvitationAcceptedTrigger: getLinkedinInvitationAcceptedTriggerPermissionAndNudge(),
  };
};

export function isLinkedInInviteAction({ type, execution }) {
  if (type === TASK_TYPES.LINKEDIN_SEND_REQUEST) {
    return true;
  }
  if (
    type === TASK_TYPES.CUSTOM &&
    execution?.phantomAgentType === 'linkedin-auto-connect'
  ) {
    return true;
  }
  return false;
}

export default getPermissionsAndNudgesAtIndex;
