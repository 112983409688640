import { Dispatch, SetStateAction, createContext } from 'react';

interface CandidateViewControls {
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
}

const CandidateViewControlsContext = createContext<CandidateViewControls>({
  editMode: false,
  setEditMode: () => {},
});

export default CandidateViewControlsContext;
