import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import TranslatableItem from '../../graphql/fragments/TranslatableItem';

export const query = gql`
  query getOfferCriteriaOptions($id: ID!) {
    offer(id: $id) {
      id
      criteriaOptions {
        jobPositions {
          ...TranslatableItem
        }
        locations {
          ...TranslatableItem
        }
        remote {
          ...TranslatableItem
        }
        freelance {
          ...TranslatableItem
        }
        education {
          ...TranslatableItem
        }
        skills {
          required {
            ...TranslatableItem
          }
          important {
            ...TranslatableItem
          }
          bonus {
            ...TranslatableItem
          }
        }
        languages {
          ...TranslatableItem
        }
        collaboratorsInCharge {
          email
          firstname
          lastname
          position
        }
      }
    }
  }
  ${TranslatableItem}
`;

export default graphql(query, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.offerId,
    },
  }),
  props: ({ data: { loading, error, offer } }) => ({
    loadingCriteriaOptions: loading,
    errorCriteriaOptions: error,
    criteriaOptions: (offer || {}).criteriaOptions,
  }),
  skip: (ownProps) => !ownProps.offerId,
});
