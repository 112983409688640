import React, { FC } from 'react';
import _ from 'underscore';
import { Trans, useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { Profile } from '@/types/profile';

import Zap from '@/components/Common/Icons/Zap';
import usePowerHours from '@/graphql/hooks/powerHours/usePowerHours';
import { getRelevantPowerHourForProfile } from '@/common/helpers/powerHours';
import styles from './CandidatePowerHours.module.less';

interface Props {
  profile: Profile;
}

const CandidatePowerHours: FC<Props> = ({ profile }) => {
  const { t } = useTranslation();
  const { powerHours } = usePowerHours();
  const relevantPowerHour = getRelevantPowerHourForProfile(powerHours, profile);

  if (!relevantPowerHour) {
    return null;
  }

  const firstname = profile?.resumeData?.firstname;

  const profileStatusKey = profile.firstContactDate
    ? 'wasContacted'
    : 'isEligible';

  return (
    <div className={styles.wrapper}>
      <div className={styles.application}>
        <div className={styles.icon}>
          <Zap />
        </div>
        <div className='content'>
          <span className={styles.bold}>
            <Trans
              i18nKey={`profile.powerHours.${profileStatusKey}`}
              values={{
                firstname: firstname || t('profile.powerHours.candidate'),
                title: relevantPowerHour.title,
                date: new Date(profile.firstContactDate || ''),
              }}
              components={{ i: <i /> }}
            />
          </span>
        </div>
      </div>
      <div className={styles.explanation}>
        <Trans
          i18nKey='profile.powerHours.explanation'
          components={{
            a: (
              <Link
                className={styles.link}
                to={{ pathname: relevantPowerHour.link }}
                target='_blank'
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

export default CandidatePowerHours;
