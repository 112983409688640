import React, { useState } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import useSequence from '@/graphql/hooks/sequences/useSequence';
import useSearchPoolProfiles from '@/graphql/hooks/searchPoolProfile/useSearchPoolProfiles';

import GenericButton from '@/components/Common/GenericButton';
import { SearchPoolMiniProfile } from '@/graphql/searchPoolMiniProfileById';
import { getReapplySequenceTerms } from './utils';
import ModalComponent from './ModalComponent';
import ReapplySequenceModalContent from './ReapplySequenceModalContent';
import ErrorModal from './ErrorModal';

interface CandidateSelection {
  id: string;
  profileId?: string;
  isSelected?: boolean;
  sequenceId?: string;
  state?: string;
}
interface ReapplySequenceModalProps {
  candidates: CandidateSelection[] | SearchPoolMiniProfile[];
  onCancel: () => void;
  clientId: string;
}

const ReapplySequenceModal: React.FC<ReapplySequenceModalProps> = ({
  candidates,
  onCancel,
  clientId,
}) => {
  const { t } = useTranslation('translations');
  const candidateIds = _.pluck(candidates, 'id');
  const { profiles, loading: profilesLoading } = useSearchPoolProfiles({
    profileIds: candidateIds,
    queryOptions: { skip: _.isEmpty(candidateIds) },
  });

  const candidatesSequenceIds = getCurrentSequenceIds(candidates);
  const searchPoolProfileSequenceIds = getCurrentSequenceIds(profiles || []);
  const candidateSequenceIds = [
    ...candidatesSequenceIds,
    ...searchPoolProfileSequenceIds,
  ];
  const sequenceId = candidateSequenceIds[0];
  const { sequence, loading: sequenceLoading } = useSequence({
    sequenceId,
    queryOptions: {
      skip: !sequenceId,
    },
  });
  const [profileErrors, setProfileErrors] = useState<
    { profileId: string; error: string }[] | undefined
  >(undefined);

  const cancelButton = (
    <GenericButton
      primacy='secondary' 
      onClick={onCancel}
      size='big'
    >
      {t('common.cancel')}
    </GenericButton>
  );

  if (sequenceLoading || profilesLoading) {
    return (
      <ModalComponent cancelButton={cancelButton}>
        <Loader active inline='centered' className='on-dimmer' />
      </ModalComponent>
    );
  }

  const reapplySequenceTerms = getReapplySequenceTerms({
    profiles,
    sequence,
  });

  const {
    possible,
    reason,
    lastCompletedActionIndex,
    shortestProfileSequenceLength,
  } = reapplySequenceTerms;

  if (
    !possible ||
    !_.isNumber(lastCompletedActionIndex) ||
    !_.isNumber(shortestProfileSequenceLength) ||
    shortestProfileSequenceLength === 0 ||
    !profiles ||
    !sequence
  ) {
    return (
      <ModalComponent cancelButton={cancelButton}>
        <Message error>
          {t(
            `contactFlow.reapplySequence.errorReasons.${reason}`,
            t('common.genericError'),
          )}
        </Message>
      </ModalComponent>
    );
  }

  if (profileErrors) {
    return (
      <ErrorModal
        profileErrors={profileErrors}
        profiles={profiles}
        onCancel={onCancel}
      />
    );
  }

  return (
    <ReapplySequenceModalContent
      profiles={profiles}
      sequence={sequence}
      reapplySequenceTerms={reapplySequenceTerms}
      onSuccess={onCancel}
      cancelButton={cancelButton}
      onError={onCancel}
      clientId={clientId}
      setProfileErrors={setProfileErrors}
    />
  );
};

const getCurrentSequenceIds = (
  candidates: SearchPoolMiniProfile[] | CandidateSelection[],
) =>
  _.uniq(
    _.compact(
      _.map(candidates, (candidate) => {
        if ('currentSequenceInfo' in candidate) {
          return candidate?.currentSequenceInfo?.sequenceId;
        }
        if ('sequenceId' in candidate) {
          return candidate?.sequenceId;
        }
        return null;
      }),
    ),
  );

export default ReapplySequenceModal;
