import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Attachment from '../../graphql/fragments/Attachment';

const deleteProfileAttachment = gql`
  mutation deleteProfileAttachment($id: ID!, $attachmentId: ID!) {
    deleteProfileAttachment(id: $id, attachmentId: $attachmentId) {
      id
      attachments {
        ...Attachment
      }
    }
  }
  ${Attachment}
`;

export default graphql(deleteProfileAttachment, {
  props: ({ mutate }) => ({
    deleteProfileAttachment: ({ profileId, attachmentId }) =>
      mutate({ variables: { attachmentId, id: profileId } }),
  }),
});
