import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';

import useUrlPreview from '@/graphql/hooks/useUrlPreview';
import Website from '@/components/Common/Icons/Website';
import { getRoot } from '@/common/utils/url';

import styles from './LinkPreview.module.less';

interface LinkPreviewProps {
  url: string;
  classNames?: {
    global?: string;
    title?: string;
    url?: string;
  };
}

const LinkPreview: FC<LinkPreviewProps> = ({
  url,
  classNames: classNamesProp = {},
}) => {
  const { urlPreview } = useUrlPreview({ url });
  const { title, favicon } = urlPreview;
  const [useFavicon, setUseFavicon] = useState(false);
  const normalizedUrl = getRoot(url);

  const displayUrl = title || normalizedUrl !== url;

  useEffect(() => {
    if (favicon) {
      setUseFavicon(true);
    }
  }, [favicon]);

  return (
    <span className={classNames(styles.preview, classNamesProp.global)}>
      {useFavicon ? (
        <img
          className={classNames(styles.image, classNamesProp.title, {
            [styles.extended]: displayUrl,
          })}
          src={favicon}
          onError={() => setUseFavicon(false)}
          alt='favicon'
        />
      ) : (
        <Website
          className={classNames(styles.image, classNamesProp.title, {
            [styles.extended]: displayUrl,
          })}
        />
      )}
      <span className={classNames(styles.title, classNamesProp.title)}>
        {title || normalizedUrl}
      </span>
      {displayUrl && (
        <span className={classNames(styles.url, classNamesProp.url)}>
          {url}
        </span>
      )}
    </span>
  );
};

export default LinkPreview;
