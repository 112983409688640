import React from 'react';

import useClientId from '@/hooks/router/useClientId';

import {
  HiresweetLibProvider,
  Button,
  ProjectContainer,
  ProjectTitle,
  Tabs,
  ProjectJobPostings,
  SideNav,
} from '@hiresweet/hiresweet-lib';
import Dots from '@/components/Common/Icons/Dots';
import _ from 'underscore';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import Company from '@/components/Common/Icons/Company';

const settingsTabs = [
  {
    id: 'tab-1',
    label: 'Tab 1',
    icon: <Dots />,
  },
  {
    id: 'tab-2',
    label: 'Tab 2',
    icon: <ArrowDown />,
  },
  {
    id: 'tab-3',
    label: 'Tab 3',
    icon: <Company />,
  },
];

const projectTabs = [
  {
    id: 'job-postings',
    label: 'Annonces',
  },
  {
    id: 'settings',
    label: 'Paramètres',
  },
];

const Playground4 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  const [selectedProjectTab, setSelectedProjectTab] =
    React.useState('job-postings');
  const [selectedSettingsTab, setSelectedSettingsTab] = React.useState('tab-1');

  return (
    <div style={{ padding: 30 }}>
      <h1>P4 - Playground 4</h1>
      <div>
        <HiresweetLibProvider theme={theme} clientId={clientId}>
          <br />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '8px',
            }}
          >
            <Button>Default</Button>

            <Button primacy='primary'>Primary</Button>
            <Button primacy='primary' color='blue'>
              Primary blue
            </Button>
            <Button primacy='primary' color='red'>
              Primary red
            </Button>

            <Button primacy='secondary'>Secondary</Button>
            <Button primacy='secondary' color='blue'>
              Secondary blue
            </Button>
            <Button primacy='secondary' color='red'>
              Secondary red
            </Button>
            <Button primacy='secondary' color='grey'>
              Secondary grey
            </Button>

            <Button primacy='tertiary'>Tertiary</Button>
            <Button primacy='tertiary' color='blue'>
              Tertiary blue
            </Button>
            <Button primacy='tertiary' color='red'>
              Tertiary red
            </Button>
            <Button primacy='tertiary' color='grey'>
              Tertiary grey
            </Button>

            <Button size='small'>Small</Button>
            <Button size='normal'>Normal</Button>
            <Button size='big'>Big</Button>

            <Button isIcon>
              <Dots />
            </Button>
          </div>

          <ProjectContainer projectId='acme-demo-lite-LLXX9I'>
            {/* Side nav */}
            <h1>
              <ProjectTitle />
            </h1>
            <Tabs
              tabs={projectTabs}
              selectedTab={selectedProjectTab}
              onSelectTab={setSelectedProjectTab}
            />
            {selectedProjectTab === 'job-postings' && <ProjectJobPostings />}
            {selectedProjectTab === 'settings' && (
              <div style={{ display: 'flex', gap: '48px' }}>
                <div style={{ width: '300px' }}>
                  <SideNav
                    tabs={settingsTabs}
                    selectedTab={selectedSettingsTab}
                    onSelectTab={setSelectedSettingsTab}
                  />
                </div>
                <h1>
                  {
                    _.findWhere(settingsTabs, { id: selectedSettingsTab })
                      ?.label
                  }
                </h1>
              </div>
            )}
          </ProjectContainer>
        </HiresweetLibProvider>
      </div>
    </div>
  );
};

export default Playground4;
