import React, { Fragment } from 'react';
import _ from 'underscore';
import SuperPipelineSegmentRow, {
  SegmentWithSubsteps,
} from './SuperPipelineSegmentRow';
import styles from '../SuperPipelineSettings.module.less';
import SuperPipelineSegmentRowSubsteps from './SuperPipelineSegmentRowSubsteps';

interface SuperPipelineSegmentTableProps {
  segmentsWithSubsteps: SegmentWithSubsteps[];
  onHoverSubstep: (id: string) => void;
  substepIdsWithVisibleSubstepAddition: string[];
  onOpenNewSubstepModal: (open: boolean) => void;
  onNewSubstepIndex: (index: number) => void;
  onDeleteSubstep: ({ substepId }: { substepId: string }) => void;
  onSelectedStep: (stepId: string) => void;
  hoveredSubstepIndex: number;
  onOpenSubstepNameEditionModal: (open: boolean) => void;
  onOpenStepNameEditionModal: (open: boolean) => void;
  onChangeSubstepName: (name: string) => void;
  substepEditMode: boolean;
  onSubstepEditMode: (value: boolean) => void;
}

const SuperPipelineSegmentTable: React.FC<SuperPipelineSegmentTableProps> = ({
  segmentsWithSubsteps,
  onHoverSubstep,
  substepIdsWithVisibleSubstepAddition,
  onOpenNewSubstepModal,
  onNewSubstepIndex,
  onDeleteSubstep,
  onSelectedStep,
  hoveredSubstepIndex,
  onOpenSubstepNameEditionModal,
  onOpenStepNameEditionModal,
  onChangeSubstepName,
  substepEditMode,
  onSubstepEditMode,
}) => {
  return (
    <div className={styles.stepsTable} onMouseLeave={() => onHoverSubstep('')}>
      {_.map(segmentsWithSubsteps, (segment, index) => (
        <Fragment key={segment.id}>
          <SuperPipelineSegmentRow
            segment={segment}
            index={index}
            onSelectedStep={onSelectedStep}
            onOpenStepNameEditionModal={onOpenStepNameEditionModal}
            onOpenNewSubstepModal={onOpenNewSubstepModal}
            onNewSubstepIndex={onNewSubstepIndex}
          />
          {!_.isEmpty(segment.substeps) && (
            <SuperPipelineSegmentRowSubsteps
              substeps={segment.substeps || []}
              onHoverSubstep={onHoverSubstep}
              substepIdsWithVisibleSubstepAddition={
                substepIdsWithVisibleSubstepAddition
              }
              onOpenNewSubstepModal={onOpenNewSubstepModal}
              onNewSubstepIndex={onNewSubstepIndex}
              onDeleteSubstep={onDeleteSubstep}
              onSelectedSubstep={onSelectedStep}
              hoveredSubstepIndex={hoveredSubstepIndex}
              onOpenSubstepNameEditionModal={onOpenSubstepNameEditionModal}
              onChangeSubstepName={onChangeSubstepName}
              substepEditMode={substepEditMode}
              onSubstepEditMode={onSubstepEditMode}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default SuperPipelineSegmentTable;
