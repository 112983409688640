import React from 'react';

import PluginMockup from './PluginMockup';
import Corner from './Corner';

import styles from './Visual2.module.less';
import LogoCircle from './LogoCircle';

const Visual2 = () => {
  return (
    <div className='visual2'>
      <div className={styles.logos}>
        <LogoCircle
          className={styles.logo}
          src='/images/logos/logo-linkedin.svg'
          alt='linkedin'
        />
        <LogoCircle
          className={styles.logo}
          src='/images/logos/gmail.svg'
          alt='gmail'
        />
        <LogoCircle
          className={styles.logo}
          src='/images/logos/google-calendar.svg'
          alt='google calendar'
        />
      </div>
      <Corner className={styles.corner} />
      <PluginMockup />
      <div className={styles.shadow} />
    </div>
  );
};

export default Visual2;
