import React, { Dispatch, SetStateAction } from 'react';
import _ from 'underscore';
import { Table } from 'semantic-ui-react';
import { LinkedInAccount } from '@/types/LinkedInAccount';
import { User } from '@/types/user';
import LinkedinAccountsTableRow from './LinkedinAccountsTableRow';

interface LinkedinAccountsTableBodyProps {
  linkedinAccounts: LinkedInAccount[];
  withEdit: boolean;
  user: User;
  onEditLinkedinAccount: ({
    linkedinAccountId,
  }: {
    linkedinAccountId: string;
  }) => void;
  onDeleteLinkedinAccount: ({
    linkedinAccountId,
  }: {
    linkedinAccountId: string;
  }) => void;
  onShowConnectModal: Dispatch<SetStateAction<boolean>>;
  setAddressToConnect: Dispatch<SetStateAction<string>>;
}

const LinkedinAccountsTableBody: React.FC<LinkedinAccountsTableBodyProps> = ({
  linkedinAccounts,
  withEdit,
  onEditLinkedinAccount,
  onDeleteLinkedinAccount,
  user,
  onShowConnectModal,
  setAddressToConnect,
}) => {
  return (
    <Table.Body>
      {_.map(linkedinAccounts, (linkedinAccount) => (
        <LinkedinAccountsTableRow
          key={linkedinAccount.id}
          linkedinAccount={linkedinAccount}
          withEdit={withEdit}
          onEditLinkedinAccount={onEditLinkedinAccount}
          onDeleteLinkedinAccount={onDeleteLinkedinAccount}
          user={user}
          onShowConnectModal={onShowConnectModal}
          setAddressToConnect={setAddressToConnect}
        />
      ))}
    </Table.Body>
  );
};

export default LinkedinAccountsTableBody;
