import _, { compose } from 'underscore';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'qs';
import { Trans, withTranslation } from 'react-i18next';

import Counter from '@/containers/Parameters/Offers/Counter';
import UnifiedViewContext from '@/containers/Parameters/Offers/UnifiedViewContext';
import ProfileEmptyState from '@/containers/Profile/EmptyState';
import { SessionSearchContextProvider } from '@/context/SessionSearchContext';
import GenericModal from '@/components/Common/GenericModal';
import routerParamsToProps from '../hocs/routerParamsToProps';
import withProfileQueries from '../hocs/profiles/withProfileQueries';
import withOfferProfiles from '../hocs/offers/withOfferProfiles';
import withActionLogger from '../hocs/withActionLogger';
import withClient from '../hocs/clients/withClient';
import withOffer from '../hocs/offers/withOffer';
import withClientOffers from '../hocs/offers/withClientOffers';
import withNetworkStatus from '../hocs/withNetworkStatus';
import withMarkProfileAsSeen from '../hocs/profiles/withMarkProfileAsSeen';
import withAddSelectionFeedback from '../hocs/offers/withAddSelectionFeedback';
import contextToProps from '../hocs/contextToProps';
import withUserSettings from '../hocs/users/withUserSettings';
import SubHeader from '../containers/TopBar/SubHeader';
import Header from '../containers/TopBar/Header';
import Resume from '../containers/Profile/Resume';
import Contact from '../containers/Profile/Contact';
import EvaluationsSets from '../containers/Profile/EvaluationsSets';
import ProfileNavigation, {
  getOptimizedSortedProfiles,
} from '../containers/Profile/ProfileNavigation';
import NudgeArrow from './NudgeArrow';
import InstantFlowStatusGuard from './InstantFlowStatusGuard';
import HoldEmptyState from './HoldEmptyState';
import HoldNudge from './HoldNudge';
import TrialSidePanel from './WatchCollectAllCandidates/TrialSidePanel';
import InstantFlowSuccessMessage from './InstantFlowSuccessMessage';

import './pagesview.css';

const REFRESH_LOCK_DURATION_IN_MS = 5000;

class ProfileViewRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepId: '',
      subStepId: '',
      searchText: '',
      searchResultProfileId: null,
      profileId: '',
      loading: true,
      currentlyInMoveProfileIds: [],
    };
  }

  refreshLockedUntil = null;

  componentWillMount() {
    const {
      profileId,
      isOnlineNetworkStatus,
      onShowNotification,
      onClearNotifications,
    } = this.props;

    if (profileId) {
      if (profileId.indexOf('step_') === 0) {
        const { stepId, subStepId } = getStepIdAndSubStepId(profileId);
        this.handleChangeStep({ stepId, subStepId, forceRefresh: true });
      } else {
        this.handleChangeProfile({ profileId });
      }
    } else if (
      getProfileIdFromQueryString({ windowLocation: window.location })
    ) {
      this.handleChangeProfile({
        profileId: atob(
          getProfileIdFromQueryString({ windowLocation: window.location }),
        ),
      });
    } else {
      this.handleChangeStep({ stepId: 'pending', subStepId: 'pending-main' });
    }
    if (isOnlineNetworkStatus === false) {
      onShowNotification({
        type: 'networkStatus',
      });
    } else if (isOnlineNetworkStatus) {
      onClearNotifications();
    }
  }

  componentDidMount() {
    const { clientId, profileId, profiles, markProfileAsJustSeen } = this.props;
    if (profileId) {
      markProfileAsJustSeen({ profileId, clientId });
    }
    this.updateOffersSeenWithPendingProfiles(profiles);
  }

  componentWillReceiveProps(nextProps) {
    const {
      client,
      profileId: currentProfileId,
      offerId: currentOfferId,
      offer,
      profiles,
      markProfileAsJustSeen,
      isOnlineNetworkStatus,
      onShowNotification,
      onClearNotifications,
    } = this.props;
    const { shouldSplitPending } = getClientDisplayConfig({ client });
    const { loading, stepId, subStepId, searchResultProfileId } = this.state;

    if (nextProps.profileId !== currentProfileId && nextProps.profileId) {
      const { clientId, profileId } = nextProps;
      markProfileAsJustSeen({ profileId, clientId });
    }

    if (isOnlineNetworkStatus !== nextProps.isOnlineNetworkStatus) {
      if (nextProps.isOnlineNetworkStatus === false) {
        onShowNotification({
          type: 'networkStatus',
        });
      } else if (nextProps.isOnlineNetworkStatus) {
        onClearNotifications();
      }
    }

    if (!loading) {
      const { offerId, profileId } = nextProps;
      if (offerId !== currentOfferId) {
        this.handleChangeOffer({ offerId });
      } else if (
        nextProps.profileId &&
        nextProps.profileId.indexOf('step_') === 0
      ) {
        const {
          stepId: newStepId,
          subStepId: newSubStepId,
        } = getStepIdAndSubStepId(nextProps.profileId);
        this.handleChangeStep({
          stepId: newStepId,
          subStepId: newSubStepId,
          forceRefresh: true,
        });
      } else if (
        offer?.isInstantFlow &&
        _.isEmpty(profiles) &&
        !_.isEmpty(nextProps.profiles) &&
        !currentProfileId &&
        !nextProps.profileId
      ) {
        const offerProfiles = nextProps.profiles;
        const firstProfile = getOptimizedSortedProfiles({
          profiles: offerProfiles || [],
          stepId,
        })[0];
        const firstProfileId = firstProfile?.id;
        this.handleChangeProfile({ profileId: firstProfileId });
      } else {
        let performAction = false;
        if (profileId !== currentProfileId) {
          if (profileId) {
            if (profileId !== searchResultProfileId) {
              performAction = true;
              this.handleChangeProfile({ profileId });
            }
          } else {
            performAction = true;
            this.handleChangeStep({ stepId, subStepId, forceRefresh: false });
          }
        }
        if (
          !performAction &&
          currentProfileId &&
          !_.isEmpty(nextProps.profiles)
        ) {
          const newProfileVersion = _.findWhere(nextProps.profiles, {
            id: currentProfileId,
          });
          if (
            !newProfileVersion ||
            newProfileVersion.resumeData.step !== stepId ||
            (shouldSplitPending &&
              stepId === 'pending' &&
              _.contains(['pending-main', 'pending-extended'], subStepId) &&
              newProfileVersion.resumeData.subStep !== subStepId)
          ) {
            const timestamp = Date.now();
            if (
              !this.refreshLockedUntil ||
              this.refreshLockedUntil < timestamp
            ) {
              this.refreshLockedUntil = timestamp + REFRESH_LOCK_DURATION_IN_MS;
              this.handleChangeProfile({ profileId, forceRefresh: true });
            }
          }
        }
      }
    }
    this.updateOffersSeenWithPendingProfiles(nextProps.profiles);
  }

  componentWillUnmount() {
    clearInterval(this.adjustSelectorSizeIntervalFunction);
  }

  updateOffersSeenWithPendingProfiles(profiles) {
    const { offerSeenWithPendingProfiles } = this.state;
    // to know when to display rating view
    const nbPending = _.filter(
      profiles,
      (p) => ((p || {}).resumeData || {}).step === 'pending',
    ).length;
    if (nbPending > 0) {
      const { jobOfferId } = profiles[0] || {};
      if (jobOfferId && !(offerSeenWithPendingProfiles || {})[jobOfferId]) {
        this.setState({
          offerSeenWithPendingProfiles: {
            ...offerSeenWithPendingProfiles,
            [jobOfferId]: true,
          },
        });
      }
    }
  }

  changeStepAndProfile = async ({
    stepId,
    subStepId,
    profileId,
    forcedOfferId,
  }) => {
    return new Promise((resolve) => {
      const { clientId, history, client, offerId: propsOfferId } = this.props;
      const { shouldSplitPending } = getClientDisplayConfig({ client });
      if (!history) {
        console.warn('No available history');
        return;
      }
      const offerId = forcedOfferId || propsOfferId;
      this.setState(
        {
          stepId,
          ...(shouldSplitPending && {
            subStepId:
              stepId === 'pending' &&
              _.contains(['pending-main', 'pending-extended'], subStepId)
                ? subStepId
                : '',
          }),
          profileId,
          searchText: '',
          searchResultProfileId: null,
          loading: false,
        },
        () => {
          const currentPath = history?.location?.pathname || '';
          // trailing slash in case of empty id causes extra history entry for nothing
          const nextPath = `/client/${clientId}/jobs/${offerId}/profiles${
            profileId ? `/${profileId}` : ''
          }`;

          if (currentPath === nextPath) {
            resolve();
            return;
          }
          if (currentPath.includes('step_')) {
            history.replace(nextPath);
          } else {
            history.push(nextPath);
          }

          resolve();
        },
      );
    });
  };

  handleChangeSearchResultProfile = async ({ searchResultProfileId }) => {
    return new Promise((resolve) => {
      const { clientId, offerId, history } = this.props;
      const { searchText } = this.state;
      if (searchText === '' || !searchResultProfileId) {
        return;
      }
      this.setState(
        {
          searchResultProfileId,
        },
        () => {
          // TODO: push only if necessary
          history.push(
            `/client/${clientId}/jobs/${offerId}/profiles/${searchResultProfileId}`,
          );
          resolve();
        },
      );
    });
  };

  handleChangeOffer = async ({ offerId }) => {
    const { findOneProfile, client } = this.props;
    const { shouldSplitPending } = getClientDisplayConfig({ client });
    const stepId = 'pending';
    const subStepId = 'pending-main';
    this.setState({ findingOneProfile: true }, async () => {
      const profile = await findOneProfile({
        offerId,
        stepId,
        subStepId,
        forceRefresh: true,
        shouldSplitPending,
      });
      const profileId = profile ? profile.id : null;
      await this.changeStepAndProfile({
        stepId,
        subStepId,
        profileId,
        forcedOfferId: offerId,
      });
      this.setState({ findingOneProfile: false });
    });
  };

  handleChangeStep = async ({
    stepId,
    subStepId,
    forceRefresh,
    forbiddenProfileId,
    originalProfileId,
  }) => {
    const { client } = this.props;
    const {
      profileId: currentProfileId,
      stepId: currentStepId,
      subStepId: currentSubStepId,
      searchText,
    } = this.state;
    const { shouldSplitPending } = getClientDisplayConfig({
      client,
    });

    if (originalProfileId && currentProfileId !== originalProfileId) {
      return;
    }
    if (
      stepId === currentStepId &&
      (!shouldSplitPending ||
        stepId !== 'pending' ||
        !_.contains(['pending-main', 'pending-extended', ''], subStepId) ||
        subStepId === currentSubStepId) &&
      searchText === '' &&
      !forceRefresh
    ) {
      return;
    }
    const { offerId, findOneProfile } = this.props;
    this.setState({ findingOneProfile: true }, async () => {
      const profile = await findOneProfile({
        offerId,
        stepId,
        subStepId,
        forceRefresh,
        shouldSplitPending,
      });
      const profileId =
        profile?.id && profile.id !== forbiddenProfileId ? profile.id : null;
      await this.changeStepAndProfile({ stepId, subStepId, profileId });
      this.setState({ findingOneProfile: false });
    });
  };

  handleChangeProfile = async ({ profileId, forceRefresh = false }) => {
    const { client } = this.props;
    const { shouldSplitPending } = getClientDisplayConfig({
      client,
    });
    const {
      profileId: currentProfileId,
      searchText,
      stepId: currentStepId,
      subStepId: currentSubStepId,
    } = this.state;
    if (forceRefresh || profileId !== currentProfileId || searchText !== '') {
      const { offerId, getProfileOfferAndStep, onLogAction } = this.props;
      onLogAction({ profileId, type: 'select-profile' });
      const profile = await getProfileOfferAndStep(profileId);
      if (profile && profile.offer && profile.offer.id === offerId) {
        const stepId = profile.resumeData.step;
        const subStepId = profile.resumeData.subStep;
        this.changeStepAndProfile({ profileId, stepId, subStepId });
        return;
      }
      const stepId = currentStepId || 'pending';
      let subStepId = '';
      if (shouldSplitPending && stepId === 'pending') {
        subStepId = _.contains(
          ['pending-main', 'pending-extended'],
          currentSubStepId,
        )
          ? currentSubStepId
          : 'pending-main';
      }
      this.handleChangeStep({ stepId, subStepId });
    }
  };

  handleMarkProfileAsInMove = ({ profileId }) => {
    const { currentlyInMoveProfileIds } = this.state;
    if (currentlyInMoveProfileIds.indexOf(profileId) < 0) {
      this.setState({
        currentlyInMoveProfileIds: [...currentlyInMoveProfileIds, profileId],
      });
    }
  };

  handleUnmarkProfileAsInMove = ({ profileId }) => {
    const { currentlyInMoveProfileIds } = this.state;
    this.setState({
      currentlyInMoveProfileIds: _.filter(
        currentlyInMoveProfileIds,
        (id) => id !== profileId,
      ),
    });
  };

  getProfilesInMove = () => {
    const { currentlyInMoveProfileIds } = this.state;
    return currentlyInMoveProfileIds;
  };

  handleSubmitSelectionFeedback = ({ offerId, rating, reason }) => {
    const { hasSubmittedSelectionFeedback } = this.state;
    const { addSelectionFeedback } = this.props;
    this.setState({
      hasSubmittedSelectionFeedback: {
        ...hasSubmittedSelectionFeedback,
        [offerId]: true,
      },
    });
    addSelectionFeedback({ offerId, rating, reason });
  };

  onChangeStep = async ({
    newStepId,
    newSubStepId,
    forceRefresh = true,
    forbiddenProfileId,
    originalProfileId,
  }) => {
    return this.handleChangeStep({
      stepId: newStepId,
      subStepId: newSubStepId,
      forceRefresh,
      forbiddenProfileId,
      originalProfileId,
    });
  };

  onRefresh = async () => {
    const { stepId, subStepId } = this.state;
    return this.handleChangeStep({ stepId, subStepId, forceRefresh: true });
  };

  onInstantFlowSuccess = () => {
    this.setState({ instantFlowSuccessModalOpen: true });
    this.onRefresh();
  };

  onChangeProfile = async (newProfileId, forceRefresh = false) => {
    return this.handleChangeProfile({ profileId: newProfileId, forceRefresh });
  };

  onSubmitSelectionFeedback = async ({ offerId, rating, reason }) => {
    this.handleSubmitSelectionFeedback({ offerId, rating, reason });
  };

  render() {
    const {
      offerId,
      clientId,
      msClient,
      msApi,
      msAuth,
      toggleMenuVisibility,
      client,
      offers,
      offer,
      history,
      profiles,
      offerProfilesLoading,
      error,
      loading,
      refetchOfferProfiles,
    } = this.props;

    const { hasLimitedAccessToProfiles } = client || {};

    const {
      profileId,
      searchResultProfileId,
      stepId,
      subStepId,
      searchText,
      hasSubmittedSelectionFeedback,
      findingOneProfile,
      offerSeenWithPendingProfiles,
      currentlyInMoveProfileIds,
      instantFlowSuccessModalOpen,
    } = this.state;

    const { shouldSplitPending, unifiedView } = getClientDisplayConfig({
      client,
    });

    const { getProfilesInMove } = this;

    const isSearchResultProfile = !!searchResultProfileId;
    const targetProfileId = searchResultProfileId || profileId;
    if (!client || !offers || offers.length === 0) {
      return null;
    }

    if (
      _.findWhere(offers, { isUnclassified: true, id: offerId }) &&
      targetProfileId
    ) {
      const currentPath = history.location.pathname;
      const nextPath = `/client/${clientId}/discover/candidates?profileId=${targetProfileId}`;
      if (currentPath !== nextPath) {
        history.replace(nextPath);
        return null;
      }
    }

    const displayableOffers = _.where(offers, {
      isSuggestedForCreation: false,
    });
    if (
      displayableOffers &&
      displayableOffers.length > 0 &&
      _.indexOf(_.pluck(displayableOffers, 'id'), offerId) === -1
    ) {
      return <Redirect to={`/client/${clientId}/jobs`} />;
    }

    const nbPending = _.filter(
      profiles,
      (p) => ((p || {}).resumeData || {}).step === 'pending',
    ).length;

    const nbPrePending = _.filter(
      profiles,
      (p) => ((p || {}).resumeData || {}).step === 'pre-pending',
    ).length;

    const { pipeStepStats = [] } = offer || {};

    const pendingStats =
      _.findWhere(pipeStepStats, { stepId: 'pending' }) || {};

    const pendingMainStats =
      _.findWhere(pendingStats.pipeSubStepStats || [], {
        subStepId: 'pending-main',
      }) || {};

    const nbPendingMain = pendingMainStats.count || 0;

    const hasTreatedPendingProfiles = (offerSeenWithPendingProfiles || {})[
      offerId
    ];

    const shouldDisplaySmartNoProfilePanes =
      stepId === 'pending' &&
      !_.isEmpty(profiles) &&
      nbPending === 0 &&
      (hasTreatedPendingProfiles ||
        (!(offer || {}).isWaitingForValidation &&
          !(offer || {}).isHold &&
          !(offer || {}).isArchived));

    const { profileEvaluation, canEvaluatePendingProfiles } =
      client?.permissions || {};
    const showEvaluationsSets =
      profileEvaluation &&
      profileId &&
      (stepId !== 'pending' || canEvaluatePendingProfiles);

    return (
      <SessionSearchContextProvider
        sessionSearchKey={`sessionSearch_${offerId}`}
      >
        <UnifiedViewContext.Provider value={{ unifiedView }}>
          <div className='profileview v4'>
            <div className='stickable-header'>
              <div className='top-header'>
                <Header
                  clientId={clientId}
                  offerId={offerId}
                  toggleMenuVisibility={toggleMenuVisibility}
                  history={history}
                  pageId='jobs'
                />
              </div>
              <div className='navigation'>
                <SubHeader
                  clientId={clientId}
                  offerId={offerId}
                  stepId={stepId}
                  subStepId={subStepId}
                  pageId='profiles'
                  searchText={searchText}
                  shouldSplitPending={shouldSplitPending}
                  onChangeStep={this.onChangeStep}
                  unifiedView={unifiedView}
                />
              </div>
            </div>

            <div className='profile-and-sidebar-container'>
              <ProfileNavigation
                clientId={clientId}
                offerId={offerId}
                stepId={stepId}
                subStepId={subStepId}
                searchText={searchText}
                profileId={targetProfileId}
                onSelectProfile={this.onChangeProfile}
                profiles={profiles}
                currentlyInMoveProfileIds={currentlyInMoveProfileIds || []}
                shouldSplitPending={shouldSplitPending}
                loading={loading}
                error={error}
              />

              <div className='profile'>
                {offer?.isHold ? (
                  <HoldNudge offerId={offerId} className='nudge' />
                ) : (
                  stepId === 'pending' &&
                  subStepId === 'pending-extended' &&
                  nbPendingMain !== 0 && (
                    <Trans
                      className='nudge pending-main-nudge'
                      i18nKey='offers.pendingNudge'
                      count={nbPendingMain}
                      components={{
                        arrow: <NudgeArrow />,
                        counter: <Counter count={nbPendingMain} />,
                      }}
                    />
                  )
                )}
                {offer?.isHold && stepId === 'pending' && !targetProfileId ? (
                  <div className='profile-empty-state'>
                    <HoldEmptyState offerId={offerId} />
                  </div>
                ) : (
                  <InstantFlowStatusGuard
                    offerId={offerId}
                    onSuccess={this.onInstantFlowSuccess}
                    onError={this.onRefresh}
                    bypass={stepId !== 'pending'}
                  >
                    {loading ||
                    shouldDisplaySmartNoProfilePanes ||
                    targetProfileId ? (
                      <>
                        <div className='profile-resume'>
                          <Resume
                            clientId={clientId}
                            offerId={offerId}
                            stepId={stepId} // WARNING: useful for withNextProfileId
                            subStepId={subStepId} // WARNING: useful for withNextProfileId
                            shouldSplitPending={shouldSplitPending} // WARNING: useful for withNextProfileId
                            shouldDisplaySmartNoProfilePane={
                              shouldDisplaySmartNoProfilePanes
                            }
                            isHiresweetWatch={(offer || {}).isHiresweetWatch}
                            hasTreatedPendingProfiles={
                              hasTreatedPendingProfiles
                            }
                            hasSubmittedSelectionFeedback={
                              !!(hasSubmittedSelectionFeedback || {})[offerId]
                            }
                            onSubmitSelectionFeedback={
                              this.onSubmitSelectionFeedback
                            }
                            profileId={targetProfileId}
                            searchText={searchText}
                            isSearchResultProfile={isSearchResultProfile}
                            onChangeStep={this.onChangeStep}
                            onChangeProfile={this.onChangeProfile}
                            getProfilesInMove={getProfilesInMove}
                            handleMarkProfileAsInMove={
                              this.handleMarkProfileAsInMove
                            }
                            handleUnmarkProfileAsInMove={
                              this.handleUnmarkProfileAsInMove
                            }
                            nbPrePending={nbPrePending}
                            profiles={profiles} // WARNING: useful for withNextProfileId
                            offerProfilesLoading={offerProfilesLoading}
                            findingOneProfile={findingOneProfile}
                            loading={loading}
                            error={error}
                            refetchOfferProfiles={refetchOfferProfiles}
                          />
                        </div>
                        {hasLimitedAccessToProfiles ? (
                          <div className='trial-side-panel'>
                            <TrialSidePanel />
                          </div>
                        ) : (
                          <div className='profile-contact'>
                            {showEvaluationsSets && (
                              <EvaluationsSets
                                clientId={clientId}
                                profileId={profileId}
                              />
                            )}

                            <Contact
                              clientId={clientId}
                              offerId={offerId}
                              stepId={stepId}
                              subStepId={subStepId} // WARNING: usefull for withNextProfileId
                              shouldSplitPending={shouldSplitPending} // WARNING: usefull for withNextProfileId
                              shouldDisplaySmartNoProfilePane={
                                shouldDisplaySmartNoProfilePanes
                              }
                              isHiresweetWatch={offer?.isHiresweetWatch}
                              profileId={targetProfileId}
                              searchText={searchText}
                              isSearchResultProfile={isSearchResultProfile}
                              onChangeStep={this.onChangeStep}
                              onChangeProfile={this.onChangeProfile}
                              msApi={msApi}
                              msClient={msClient}
                              msAuth={msAuth}
                              handleMarkProfileAsInMove={
                                this.handleMarkProfileAsInMove
                              }
                              handleUnmarkProfileAsInMove={
                                this.handleUnmarkProfileAsInMove
                              }
                              getProfilesInMove={getProfilesInMove}
                              profiles={profiles} // WARNING: usefull for withNextProfileId
                              loading={loading}
                              error={error}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <div className='profile-empty-state'>
                        <ProfileEmptyState
                          offerId={offerId}
                          onChangeStep={this.onChangeStep}
                        />
                      </div>
                    )}
                  </InstantFlowStatusGuard>
                )}
              </div>
            </div>
            <GenericModal
              className='instantflow-success-modal'
              open={instantFlowSuccessModalOpen && nbPending !== 0}
              onClose={() =>
                this.setState({ instantFlowSuccessModalOpen: false })
              }
            >
              <InstantFlowSuccessMessage
                offerId={offerId}
                onClose={() =>
                  this.setState({ instantFlowSuccessModalOpen: false })
                }
              />
            </GenericModal>
          </div>
        </UnifiedViewContext.Provider>
      </SessionSearchContextProvider>
    );
  }
}

const withOfferIdSplitter = (NestedComponent) => ({ offerId, ...rest }) => (
  <NestedComponent key={offerId || '_'} offerId={offerId} {...rest} />
);

const getProfileIdFromQueryString = ({ windowLocation }) => {
  const queryString =
    (windowLocation.search && windowLocation.search.replace('?', '')) || '';
  const queryObject = qs.parse(queryString);
  return queryObject && queryObject.pid;
};

const getStepIdAndSubStepId = (stepString) => {
  const stepPart = stepString.substr(5);
  if (_.contains(['pending-main', 'pending-extended'], stepPart)) {
    return { stepId: 'pending', subStepId: stepPart };
  }
  if (stepPart === 'pending') {
    return { stepId: 'pending', subStepId: 'pending-main' };
  }
  return { stepId: stepPart, subStepId: '' };
};

export const getClientDisplayConfig = ({ client }) => {
  return {
    shouldSplitPending: client?.permissions?.marketplaceSplitPending,
    unifiedView: client?.permissions?.marketplaceUnifiedView,
  };
};

export default compose(
  routerParamsToProps,
  withUserSettings,
  withProfileQueries,
  withOfferProfiles,
  withActionLogger,
  withNetworkStatus,
  withClient,
  withClientOffers,
  withOffer,
  withMarkProfileAsSeen,
  withAddSelectionFeedback,
  contextToProps,
  withOfferIdSplitter,
  withTranslation('translations'),
)(ProfileViewRoute);
