import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContextProvider } from '@/context/AppContext/AppContext';
import CreateSequenceStandalonePage from '@/containers/Parameters/Sequences/CreateSequencePage/standalone';
import Hiresweet from '@/components/Common/Icons/Hiresweet';
import GenericButton from '@/components/Common/GenericButton';
import FakeClientContext from '@/context/FakeClientContext';
import ButtonLink from '@/components/Common/GenericButton/ButtonLink';

import GenericModal from '@/components/Common/GenericModal';
import Cross from '@/components/Common/Icons/Cross';
import styles from './SequenceGenerationDemo.module.less';

const SequenceGenerationDemo: FC = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <FakeClientContext.Provider value={{ clientId: 'ai-seq-demo' }}>
      <AppContextProvider clientId='ai-seq-demo'>
        <div className={styles.viewport}>
          <div className={styles.header}>
            <Hiresweet
              classNames={{ global: styles.logo, main: styles.logoMain }}
            />
            <div className={styles.headline}>
              {t('sequences.generation.demo.headline')}
            </div>
            <div className={styles.ctas}>
              <GenericButton
                primacy='secondary'
                size='big'
                onClick={() => setModalOpen(true)}
              >
                {t('sequences.generation.demo.watch')}
              </GenericButton>
              <ButtonLink
                primacy='primary'
                size='big'
                to={{ pathname: 'https://www.hiresweet.com/contact' }}
                target='_blank'
              >
                {t('sequences.generation.demo.try')}
              </ButtonLink>
            </div>
          </div>
          <div className={styles.main}>
            <CreateSequenceStandalonePage />
          </div>
        </div>
        <GenericModal open={modalOpen}>
          <div className={styles.demoModal}>
            <div
              style={{
                position: 'relative',
                paddingBottom: '61.855670103092784%',
                height: 0,
                borderRadius: 8,
              }}
            >
              <iframe
                src='https://www.loom.com/embed/0dc56fb69f74450f8df559385463b17c'
                frameBorder='0'
                allowFullScreen
                title='loom-demo'
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: 8,
                }}
              />
            </div>
            <div className={styles.modalControls}>
              <GenericButton
                primacy='tertiary'
                size='big'
                onClick={() => setModalOpen(false)}
              >
                <Cross />
                {t('common.close')}
              </GenericButton>
            </div>
          </div>
        </GenericModal>
      </AppContextProvider>
    </FakeClientContext.Provider>
  );
};

export default SequenceGenerationDemo;
