import gql from 'graphql-tag';
import { useMemo } from 'react';
import { QueryResult, useQuery } from '@apollo/client';

import { sanitizeTypename } from '@/common/utils/apollo';
import { Author } from '@/types/author';

export type RevealSnoozeReason = {
  id: string;
  atsId?: string;
  title: string;
  linkedATSSnoozeReasonId?: string;
  author?: Author;
  creationDate: string;
  lastEditionDate: string;
};

export type ResultSnoozeReasons = {
  client: {
    snoozeReasons: RevealSnoozeReason[];
  };
};

export const GET_CLIENT_SNOOZE_REASONS = gql`
  query getClientSnoozeReasons($clientId: ID!) {
    client(id: $clientId) {
      id
      snoozeReasons {
        id
        title
      }
    }
  }
`;

export function useClientSnoozeReasons(
  clientId: string,
  onCompleted?: (data?: ResultSnoozeReasons) => void,
): QueryResult<ResultSnoozeReasons, { clientId: string }> & {
  snoozeReasons: RevealSnoozeReason[];
} {
  const query = useQuery<ResultSnoozeReasons, { clientId: string }>(
    GET_CLIENT_SNOOZE_REASONS,
    { variables: { clientId }, onCompleted },
  );
  const { snoozeReasons } = query.data?.client || {};
  const fields = useMemo(() => sanitizeTypename(snoozeReasons || []), [
    snoozeReasons,
  ]);
  return {
    ...query,
    snoozeReasons: fields,
  };
}
