import React from 'react';
import { Segment } from 'semantic-ui-react';
import { getTranslatedText } from '../../../../../../common';

import './ManualAction.css';

const ManualAction = ({ action }) => {
  return (
    <Segment className='manual-action'>
      <div className='action-header'>{getTranslatedText(action?.title) || 'My task'}</div>
      <div className='action-description'>{getTranslatedText(action?.description) || ''}</div>
    </Segment>
  );
};

export default ManualAction;
