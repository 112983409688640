import React, { FC, useContext, useMemo } from 'react';
import _ from 'underscore';
import { Trans, useTranslation } from 'react-i18next';

import Alert from '@/components/Common/Alert/Alert';
import CandidateViewControlsContext from '@/routes/RevealView/SearchView/CandidatesListPane/CandidateView/CandidateViewControlsContext';
import Edit from '@/components/Common/Icons/Edit';
import useRemoveProfilesFromSequence from '@/graphql/hooks/searchPool/useRemoveProfilesFromSequence';
import Cross from '@/components/Common/Icons/Cross';
import {
  hasProfileBounced,
  ProfileWithEmailInfo,
} from '@/common/helpers/profile';

import styles from './BouncedEmailAlert.module.less';

interface BouncedEmailAlertProps {
  profile: ProfileWithEmailInfo;
}

const BouncedEmailAlert: FC<BouncedEmailAlertProps> = ({ profile }) => {
  const { t } = useTranslation();
  const { setEditMode } = useContext(CandidateViewControlsContext);
  const [
    removeProfilesFromSequence,
    { loading: removeLoading },
  ] = useRemoveProfilesFromSequence();

  const hasBounced = useMemo(() => hasProfileBounced(profile), [profile]);

  if (!hasBounced) {
    return null;
  }

  return (
    <Alert title={t('reveal.profileModal.warnings.bouncedEmail.title')}>
      <div className={styles.message}>
        <Trans
          i18nKey='reveal.profileModal.warnings.bouncedEmail.description'
          components={{ b: <span className={styles.address} /> }}
          values={{ email: profile.email }}
        />
      </div>
      <div className={styles.actions}>
        <button
          type='button'
          className={styles.action}
          onClick={() => setEditMode(true)}
        >
          <Edit className={styles.icon} />
          {t('reveal.profileModal.warnings.bouncedEmail.changeEmail')}
        </button>
        <button
          type='button'
          className={styles.action}
          onClick={() =>
            removeProfilesFromSequence({ profileIds: [profile.id] })
          }
          disabled={removeLoading}
        >
          <Cross className={styles.icon} />
          {t('reveal.profileModal.warnings.bouncedEmail.unenroll')}
        </button>
      </div>
    </Alert>
  );
};

export default BouncedEmailAlert;
