import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MissionCategoryContext from '@/context/MissionCategoryContext';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';

import JobSelector from '../JobView/JobSelector/JobSelector';

import styles from './JobBreadCrumb.module.less';

interface JobBreadCrumbProps {
  clientId: string;
  jobId?: string;
  activeTab: string;
  searchPoolId: string;
  shouldHideNewProjectButton?: boolean;
}

const JobBreadCrumb: React.FC<JobBreadCrumbProps> = ({
  clientId,
  jobId,
  activeTab,
  searchPoolId,
  shouldHideNewProjectButton,
}) => {
  const { t } = useTranslation();
  const missionCategoryType = useContext(MissionCategoryContext);
  const { permissions } = useClientPermissions(clientId);
  const { salesProjects } = permissions || {};

  const pageTitleKey = useMemo(() => {
    if (!salesProjects) {
      return 'header.parameters.projects';
    }
    if (missionCategoryType === 'sales') {
      return 'header.parameters.salesProjects';
    }
    return 'header.parameters.hiringProjects';
  }, [salesProjects, missionCategoryType]);

  return (
    <div className={styles.jobBreadcrumb}>
      <Link
        className={styles.breadCrumbSection}
        to={`/client/${clientId}/reveal/projects/${missionCategoryType}`}
      >
        {t(pageTitleKey)}
      </Link>
      <div className={styles.breadcrumbDivider}>
        <i className='ri-arrow-right-s-line' />
      </div>
      <JobSelector
        clientId={clientId}
        jobId={jobId}
        targetPage={activeTab}
        searchPoolId={searchPoolId}
        shouldHideNewProjectButton={shouldHideNewProjectButton}
      />
    </div>
  );
};

export default JobBreadCrumb;
