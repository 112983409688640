import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import SearchPoolJobWorkflow from '@/graphql/fragments/SearchPoolJobWorkflow';

export const UpdateMissionWorkflow = gql`
  mutation updateMissionWorkflow(
    $searchPoolId: ID!
    $input: updateMissionWorkflowInput!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      updateMissionWorkflow(input: $input) {
        job {
          id
          workflows {
            ...SearchPoolJobWorkflow
          }
        }
      }
    }
  }
  ${SearchPoolJobWorkflow}
`;

export default function useUpdateMissionWorkflow() {
  return useMutation(UpdateMissionWorkflow);
}
