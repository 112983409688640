import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { getOfferProfiles } from '../offers/withOfferProfiles';
import Profile from '../../graphql/fragments/Profile';

// TODO: response could be made lighter by requesting only id and  searchPoolState ?
// cf. withAddMultipleSearchProfilesToJob
export const addSearchProfileToJobQuery = gql`
  mutation addSearchProfileToJob($id: ID!, $jobOfferId: ID!) {
    addSearchPoolProfileToJobOffer(id: $id, jobOfferId: $jobOfferId) {
      ...Profile
    }
  }
  ${Profile}
`;

export default graphql(addSearchProfileToJobQuery, {
  props: ({ mutate }) => {
    return {
      addSearchProfileToJob: ({ id, jobOfferId }) =>
        mutate({
          variables: { id, jobOfferId },
          refetchQueries: [
            {
              query: getOfferProfiles,
              variables: {
                offerId: jobOfferId,
                step: '',
                search: '',
              },
            },
            {
              query: getOfferProfiles,
              variables: {
                offerId: jobOfferId,
                step: 'pending',
                search: '',
              },
            },
          ],
        }),
    };
  },
});
