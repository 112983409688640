import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import logAction from '../../../common/logAction';
import { useNavApplicationMode } from './NavSwitch.hook';

import styles from './AnimatedLogo.module.less';

const useAnimatedLogo = (
  pageId: string,
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const animationState = useState<boolean>(pageId === 'dashboard');
  useEffect(() => {
    const [displayAnimation, setDisplayAnimation] = animationState;
    if (displayAnimation) {
      setTimeout(() => {
        setDisplayAnimation(false);
        // TODO: adjust to animation duration
        // total duration = 4280ms;
      }, 2800);
    }
    // eslint-disable-next-line
  }, [animationState]);

  return animationState;
};

interface AnimatedLogoProps {
  pageId: string;
  clientId: string;
}
const AnimatedLogo: React.FC<AnimatedLogoProps> = ({ pageId, clientId }) => {
  const [displayAnimation, setDisplayAnimation] = useAnimatedLogo(pageId);
  const { applicationMode } = useNavApplicationMode();
  const modePath = applicationMode === 'reveal' ? '/reveal' : '';
  const handleLogoClick = () => {
    setDisplayAnimation(true);
    logAction({
      type: 'click-dashboard',
      info: {
        from: 'top-header',
        clientId,
      },
    });
  };
  return (
    <Link
      className={classNames(styles.logoContainer, {
        [styles.classic]: applicationMode === 'classic',
      })}
      to={`/client/${clientId}${modePath}/dashboard`}
      onClick={handleLogoClick}
    >
      {displayAnimation ? (
        <img
          width={40}
          src='/images/logos/animated_logo_300.gif'
          alt='logo hiresweet'
          className={styles.animatedLogo}
        />
      ) : (
        <img
          width={32}
          src='/images/logos/new-logo_without_text_blue.svg'
          alt='logo hiresweet'
        />
      )}
    </Link>
  );
};

export default AnimatedLogo;
