import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ProfileTableParamsContext from '@/context/ProfileTableParamsContext';

import ActionsDropdown from '../../revealComponents/Buttons/ActionsDropdown';
import ImportCandidatesDropdown from '../../revealComponents/Buttons/ImportCandidatesDropdown';

import AddToProjectButton from './AddToProjectButton';

import './BulkActionsControls.css';

const BulkActionsControls = ({
  clientId,
  candidates,
  jobId,
  onSuccess,
  refetchSearchResults,
  basicBtns,
}) => {
  const { t } = useTranslation();
  const { disabledActions } = useContext(ProfileTableParamsContext);
  return (
    <div className='bulk-action-controls'>
      <div className='left-controls' />
      <div className='right-controls'>
        {!disabledActions['add-to-project'] && (
          <AddToProjectButton
            clientId={clientId}
            jobId={jobId}
            candidates={candidates}
            basicBtn={basicBtns}
          />
        )}
        <ActionsDropdown
          clientId={clientId}
          jobId={jobId}
          candidates={candidates}
          t={t}
          onSuccess={onSuccess}
        />
        {!disabledActions['import-candidates'] && (
          <ImportCandidatesDropdown
            clientId={clientId}
            jobId={jobId}
            onSuccess={refetchSearchResults}
          />
        )}
      </div>
    </div>
  );
};

export default BulkActionsControls;
