import { QueryHookOptions, QueryResult, useLazyQuery } from '@apollo/client';

import { GET_CLIENT_DAILY_COHORTS_DETAILS } from '@/graphql/statistics';

export interface DailyCohortDetails {
  day: string;
  details: {
    type: string;
    sequenceId: string;
    profileItems: {
      profileId: string;
    }[];
  }[];
}

interface GetClientDailyCohortDetailsData {
  client: {
    id: string;
    statistics: {
      sequences: {
        dailyCohorts: DailyCohortDetails[];
      };
    };
  };
}

interface GetClientDailyCohortDetailsVariables {
  clientId: string;
  sequenceIds?: string[];
  userEmails?: string[];
}

interface UseClientSequencesDailyCohortDetailsInput {
  queryOptions?: QueryHookOptions<
    GetClientDailyCohortDetailsData,
    GetClientDailyCohortDetailsVariables
  >;
}

type UsePipelineStatisticsByCategoryDetailsResult = [
  (variables: GetClientDailyCohortDetailsVariables) => void,
  QueryResult<
    GetClientDailyCohortDetailsData,
    GetClientDailyCohortDetailsVariables
  > & {
    dailyCohorts?: DailyCohortDetails[];
  },
];

const useClientDailyCohortsDetails = (
  input?: UseClientSequencesDailyCohortDetailsInput,
): UsePipelineStatisticsByCategoryDetailsResult => {
  const { queryOptions } = input || {};

  const [fetchDetails, query] = useLazyQuery<
    GetClientDailyCohortDetailsData,
    GetClientDailyCohortDetailsVariables
  >(GET_CLIENT_DAILY_COHORTS_DETAILS, {
    ...(queryOptions || {}),
  });

  return [
    (variables) => fetchDetails({ variables }),
    {
      ...query,
      dailyCohorts: query?.data?.client?.statistics?.sequences?.dailyCohorts,
    },
  ];
};

export default useClientDailyCohortsDetails;
