import gql from 'graphql-tag';
import TranslatableText from './TranslatableText';

export default gql`
  fragment SearchPoolJobWorkflow on MissionWorkflow {
    id
    rule {
      type
      condition {
        revealStageId
      }
    }
    action {
      integrationId
      service
      type
      command
      target {
        atsStageId
        linkedATSJobId
        linkedATSUserId
        revealStageId
      }
    }
    warnings {
      message {
        ...TranslatableText
      }
    }
  }
  ${TranslatableText}
`;
