import gql from 'graphql-tag';
import MinimalTask from './MinimalTask';

export default gql`
  fragment ProfileSequenceInfoWithSequence on ProfileSequenceInfo {
    sequenceId
    state
    insertionDate
    reapplicationDate
    lastActionDate
    nextActionDate
    author {
      email
      firstname
      lastname
    }
    sequence {
      id
      title
      lastEditionDate
    }
    currentTask {
      ...MinimalTask
    }
  }
  ${MinimalTask}
`;
