import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import HeaderRow from '@/components/ProfileRow/HeaderRow/HeaderRow';
import AvatarColumn from '@/components/ProfileRow/columns/AvatarColumn';
import NameAndHeadlineColumn from '@/components/ProfileRow/columns/NameAndHeadlineColumn';
import usePagination from '@/hooks/ui/usePagination';

import ProfileRow from '../ProfileRow';
import MissionRemovedColumn from './MissionRemovedColumn';

import { MissionRemovedDetail } from './types';

import styles from './StatsProfileAndMissionRemovedTable.module.less';

interface StatsProfileAndMissionSourcedTableProps {
  missionRemovedDetails: MissionRemovedDetail[];
}

const StatsProfileAndMissionRemovedTable: FC<StatsProfileAndMissionSourcedTableProps> = ({
  missionRemovedDetails,
}) => {
  const { t } = useTranslation();

  const { pageElements, numberOfPages, PageSelector } = usePagination(
    missionRemovedDetails,
  );

  return (
    <div className={styles.table}>
      <HeaderRow
        columns={[
          { id: 'avatar', title: '', className: styles.avatar },
          {
            id: 'contact',
            title: t('reveal.missions.mission.tableHeader.contact'),
            className: styles.nameAndHeadline,
          },
          {
            id: 'mission',
            title: t('reveal.missions.mission.tableHeader.mission'),
            className: styles.mission,
          },
        ]}
      />
      <div>
        {_.map(pageElements, ({ profileId, missionId, day }, index) => (
          <ProfileRow
            key={`${missionId}_${profileId}_${day}_${index}`}
            profileId={profileId}
            columns={[
              {
                id: 'avatar',
                component: AvatarColumn,
                className: styles.avatar,
              },
              {
                id: 'contact',
                component: NameAndHeadlineColumn,
                className: styles.nameAndHeadline,
              },
              {
                id: 'mission',
                component: ({ profileLoading }) => (
                  <MissionRemovedColumn
                    profileLoading={profileLoading}
                    day={day}
                    missionId={missionId}
                    className={styles.mission}
                  />
                ),
              },
            ]}
          />
        ))}
      </div>
      {numberOfPages > 1 && (
        <div className={styles.pagination}>
          <PageSelector />
        </div>
      )}
    </div>
  );
};

export default StatsProfileAndMissionRemovedTable;
