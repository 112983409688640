import React from 'react';
import _ from 'underscore';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Popup, Icon } from 'semantic-ui-react';

const OpenTrackingInfo = ({ openEvents, trackOnEmailOpen, t }) => {
  if (!trackOnEmailOpen) {
    return (
      <div className='no-events'>
        <Icon name='envelope open outline' />
        {t('profile.contact.timeline.emailTracking.noOpenTracking')}
      </div>
    );
  }
  if (_.isEmpty(openEvents)) {
    return (
      <div className='no-events'>
        <Icon name='envelope open outline' />
        {t('profile.contact.timeline.emailTracking.notOpened')}
      </div>
    );
  }
  const lastEvent = _.last(_.sortBy(openEvents, ({ timestamp }) => timestamp));
  return (
    <div>
      <Icon name='envelope open outline' />
      {t('profile.contact.timeline.emailTracking.lastOpened', { date: new Date(lastEvent.timestamp) })}
    </div>
  );
};

const ClickTrackingInfo = ({ clickEvents, trackOnLinkClick, t }) => {
  if (!trackOnLinkClick) {
    return (
      <div className='no-events'>
        <Icon name='mouse pointer' />
        {t('profile.contact.timeline.emailTracking.noClickTracking')}
      </div>
    );
  }
  if (_.isEmpty(clickEvents)) {
    return (
      <div className='no-events'>
        <Icon name='mouse pointer' />
        {t('profile.contact.timeline.emailTracking.notClicked')}
      </div>
    );
  }
  const lastEvent = _.last(_.sortBy(clickEvents, ({ timestamp }) => timestamp));
  return (
    <div>
      <Icon name='mouse pointer' />
      {t('profile.contact.timeline.emailTracking.lastClicked', { date: new Date(lastEvent.timestamp) })}
    </div>
  );
};

const UnsubscribeClickTrackingInfo = ({ unsubsribeClickEvents, trackOnEmailOpen, t }) => {
  if (_.isEmpty(unsubsribeClickEvents)) {
    return null;
  }
  const firstEvent = unsubsribeClickEvents[0];
  return (
    <div>
      <Icon name='unlink' />
      {t('profile.contact.timeline.emailTracking.unsubscribed', { date: new Date(firstEvent.timestamp) })}
    </div>
  );
};

const EmailTrackingPopupContent = ({ events, trackOnEmailOpen, trackOnLinkClick, t }) => {
  return (
    <div>
      <OpenTrackingInfo
        openEvents={_.filter(events, ({ type }) => type === 'open-email')}
        trackOnEmailOpen={trackOnEmailOpen}
        t={t}
      />
      <ClickTrackingInfo
        clickEvents={_.filter(events, ({ type }) => type === 'click-link')}
        trackOnLinkClick={trackOnLinkClick}
        t={t}
      />
      <UnsubscribeClickTrackingInfo
        unsubsribeClickEvents={_.filter(events, ({ type }) => type === 'click-unsubscribe-link')}
        t={t}
      />
    </div>
  );
};

const EmailTrackingPopup = ({ action, t }) => {
  if (!action) {
    return null;
  }
  const { events, trackOnEmailOpen, trackOnLinkClick } = action;
  if (_.isEmpty(events) && !trackOnEmailOpen && !trackOnLinkClick) {
    return null;
  }
  return (
    <Popup
      trigger={
        <div className={classNames('tracking', !_.isEmpty(events) && 'active')}>
          <Icon name='envelope outline' size='large' />
        </div>
      }
      position='top right'
      content={
        <div className='email-tracking-popup'>
          <EmailTrackingPopupContent
            events={events}
            trackOnEmailOpen={trackOnEmailOpen}
            trackOnLinkClick={trackOnLinkClick}
            t={t}
          />
        </div>
      }
    />
  );
};

export default withTranslation('translations')(EmailTrackingPopup);
