import _ from 'underscore';
import React, { FC, useMemo } from 'react';
import { StackItem } from '@/types/statistics/stackItem';
import { DailyEvent } from '@/types/statistics/dailyEvent';
import { NumberRecord } from '@/types/statistics/stats';
import { getRechartsData } from '../../stats.helpers';
import { ChartType, Frequency, Mode } from '../GraphWrapper/types';
import RevealAnalyticsGraph from '../RevealAnalyticsGraph/RevealAnalyticsGraph';
import { TimeSpan } from '../RevealAnalyticsHeader/RevealAnalyticsHeader';

interface DailyEventsDataContainerProps {
  frequency: Frequency;
  dateRange: TimeSpan;
  type: ChartType;
  mode: Mode;
  dailyEvents: DailyEvent[];
  stack: StackItem[];
  initialValues?: NumberRecord;
  fetchDetails?: (startDay: string, endDay: string, datakey: string) => void;
  exhaustive?: boolean;
}

const DailyEventsDataContainer: FC<DailyEventsDataContainerProps> = ({
  frequency,
  dateRange,
  type,
  mode,
  dailyEvents,
  stack,
  initialValues,
  fetchDetails,
  exhaustive,
}) => {
  const rechartsData = useMemo(
    () => getRechartsData(dateRange, frequency, dailyEvents),
    [dailyEvents, frequency, dateRange],
  );

  const emptyValue = useMemo(
    () =>
      _.reduce(
        dailyEvents,
        (result, event) => ({
          ...result,
          ..._.mapObject(event.values, () => 0),
        }),
        {} as Record<string, number>,
      ),
    [dailyEvents],
  );

  return (
    <RevealAnalyticsGraph
      data={rechartsData}
      frequency={frequency}
      timeSpan={dateRange}
      mode={mode}
      stack={stack}
      type={type}
      emptyValue={emptyValue}
      initialValues={initialValues}
      fetchDetails={fetchDetails}
      exhaustive={exhaustive}
    />
  );
};

export default DailyEventsDataContainer;
