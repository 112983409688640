import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { Department } from '../../graphql/fragments/Department';

export const deleteClientDepartment = gql`
  mutation deleteClientDepartment($departmentId: ID!) {
    deleteClientDepartment(departmentId: $departmentId) {
      id
      departments {
        ...Department
      }
    }
  }
  ${Department}
`;

export default graphql(deleteClientDepartment, {
  props: ({ mutate }) => ({
    deleteClientDepartment: ({ departmentId, queryOptions }) =>
      mutate({
        variables: {
          departmentId,
        },
        ...queryOptions,
      }),
  }),
});
