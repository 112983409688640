import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import { EvaluationsSet } from '../../graphql/fragments/Evaluations';

export const addProfileEvaluationsSet = gql`
  mutation addProfileEvaluationsSet(
    $id: ID!
    $evaluationsSet: EvaluationsSetInput
  ) {
    addProfileEvaluationsSet(id: $id, evaluationsSet: $evaluationsSet) {
      id
      evaluationsSets {
        ...EvaluationsSet
      }
    }
  }
  ${EvaluationsSet}
`;

export default graphql(addProfileEvaluationsSet, {
  props: ({ mutate }) => ({
    addProfileEvaluationsSet: ({ id, evaluationsSet }) => {
      const variables = { id, evaluationsSet };
      return mutate({ variables });
    },
  }),
});
