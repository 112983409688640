import React from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import { Grid } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

const OfferPipe = ({ pipeStepStats, goToStep }) => {
  const onClickStep = ({ e, stepId }) => {
    e.stopPropagation();
    goToStep({ stepId });
  };
  return (
    <div className='offer-pipe'>
      <Grid style={{ margin: '0px' }}>
        <Grid.Row columns={pipeStepStats.length}>
          {_.map(pipeStepStats, ({ count, stepId }, index) => (
            <Grid.Column
              key={index}
              className={`offer-pipe-step-column ${index === 0 ? 'first' : ''}`}
              textAlign='center'
              onClick={(e) => onClickStep({ stepId, e })}
            >
              <div
                className={classNames(
                  'offer-pipe-step',
                  stepId,
                  !count && 'not-available',
                )}
              >
                <div className='offer-pipe-step-count'>{count || '-'}</div>
              </div>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default withTranslation('translations')(OfferPipe);
