import React from 'react';
// import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import './RevealCandidateViewActionsButtons.css';
import TaskTab from '@/revealComponents/TaskTab';
import useIsPlugin from '@/hooks/common/useIsPlugin';

interface RevealCandidateViewTasksProps {
  clientId: string;
  setIsOpen: any;
}

const RevealCandidateViewTasks: React.FC<RevealCandidateViewTasksProps> = ({
  clientId,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const isPlugin = useIsPlugin();
  return (
    <div className={`new-activity-modal ${isPlugin ? 'plugin' : ''}`}>
      <h4>{t('profile.actionButtons.createTask')}</h4>
      <TaskTab clientId={clientId} setIsOpen={setIsOpen} />
    </div>
  );
};

export default RevealCandidateViewTasks;
