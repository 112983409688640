import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import parseHtml from 'html-react-parser';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import _ from 'underscore';
import classNames from 'classnames';
import { smoothCutText } from '@/components/CollapsibleEnrichedText';
import { capitalizeFirstLetter, filterHtmlText } from '@/common/utils/string';
import { useRevealActivityContext } from '@/context/JobView/useRevealActivityProvider';
import { MissionCategoryType } from '@/types/missionCategory';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import useClientId from '@/hooks/router/useClientId';
import { getTranslatedText } from '@/common/helpers/translatableText';
import { getShortLanguage } from '@/common/utils/i18n';
import styles from './RevealActivityTimeline.module.less';
import { getTaskTypeDescription, getTaskTypeIcon } from './helpers';

interface TimelineProps {
  events: any;
  showSeeMore: boolean;
  seeMore: () => void;
  missionId?: string;
}

interface EventProps {
  event: any;
  isLast?: boolean;
}

const truncateName = (str: string) => {
  if (!str || !str.length) {
    return '';
  }
  return smoothCutText(str, 5, 20);
};

const replaceNewStageName = (newStage: string): string =>
  capitalizeFirstLetter(newStage.replace('-', ' '));

const profileLink = (
  clientId: string,
  missionId: string,
  profileId: string,
  missionCategory: MissionCategoryType = 'recruiting',
): string => {
  if (missionId) {
    return `/client/${clientId}/reveal/projects/${missionCategory}/${missionId}/profiles?selectedProfileId=${profileId}`;
  }
  return `/client/${clientId}/reveal/search?profileId=${profileId}`;
};

const RevealActivityTimeline: React.FC<TimelineProps> = ({
  events,
  showSeeMore,
  seeMore,
  missionId,
}) => {
  return (
    <>
      {_.map(events, (dict, iEvent) => (
        <React.Fragment key={iEvent}>
          <h3
            className={classNames(
              styles.date,
              iEvent !== 0 ? styles.marginTop : '',
            )}
          >
            {dict.date}
          </h3>
          {_.map(dict.events, (event, index) => (
            <ActivityTimelineItem
              key={index}
              event={event}
              isLast={dict.events && index === dict.events.length - 1}
              missionId={missionId}
            />
          ))}
        </React.Fragment>
      ))}
      {showSeeMore && <ExpandReduceItem seeMore={seeMore} />}
    </>
  );
};

interface ActivityTimelineItemProps extends EventProps {
  missionId?: string;
}

const ActivityTimelineItem: React.FC<ActivityTimelineItemProps> = ({
  event,
  isLast,
  missionId,
}) => {
  switch (event.__typename) {
    case 'TasksActivityEvent':
      return <TasksActivity event={event} isLast={isLast} />;
    case 'SingleTaskActivityEvent':
      return <SingleTaskActivity event={event} isLast={isLast} />;
    case 'RevealProfileActivityEvent':
      return <RevealProfileActivityEvent event={event} isLast={isLast} />;
    case 'RevealMultipleProfilesActivityEvent':
      return (
        <RevealProfileActivityEvent event={event} isLast={isLast} multiple />
      );
    case 'RevealMissionActivityEvent':
      return <RevealMissionActivityEvent event={event} isLast={isLast} />;
    case 'LoggedActivityContent':
      return (
        <RevealMissionActivityEvent
          event={event}
          isLast={isLast}
          missionId={missionId}
        />
      );
    default:
      return null;
  }
};

const ExpandReduceItem = ({ seeMore }: { seeMore: () => void }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.seeMore}>
      <span aria-hidden onClick={seeMore}>
        {t('dashboard.activity.seeMore')}
      </span>
    </div>
  );
};

const SingleTaskActivity: React.FC<EventProps> = ({ event, isLast }) => {
  const missionCategory = useContext(MissionCategoryContext);
  const { clientId, missionId } = useRevealActivityContext();
  const { t } = useTranslation();
  return (
    <GenericActivity event={event} isLast={isLast}>
      <>
        <Link
          to={profileLink(
            clientId,
            missionId,
            event.task?.target?.id,
            missionCategory,
          )}
          className={styles.target}
        >
          {truncateName(event.task?.target?.resumeData?.firstname)}{' '}
          {truncateName(event.task?.target?.resumeData?.lastname)}{' '}
        </Link>
        <span className={styles.taskType}>
          {t(
            `reveal.dashboardView.activity.${getTaskTypeDescription(
              event.taskType,
            )}`,
            { count: 1 },
          )}
        </span>
      </>
    </GenericActivity>
  );
};

const TasksActivity: React.FC<EventProps> = ({ event, isLast }) => {
  const { t } = useTranslation();
  return (
    <GenericActivity event={event} isLast={isLast}>
      <span className={styles.taskType}>
        {event.totalTasksCount}{' '}
        {t(
          `reveal.dashboardView.activity.${getTaskTypeDescription(
            event.taskType,
          )}`,
          { count: 2 },
        )}
      </span>
    </GenericActivity>
  );
};

interface RevealProfileActivityEventProps extends EventProps {
  multiple?: boolean;
}

const RevealProfileActivityEvent: React.FC<RevealProfileActivityEventProps> = ({
  event,
  multiple,
  isLast,
}) => {
  const { t } = useTranslation();
  const missionCategory = useContext(MissionCategoryContext);
  const { clientId, missionId } = useRevealActivityContext();

  return (
    <GenericActivity event={event} isLast={isLast}>
      {!multiple && (
        <Link
          to={profileLink(
            clientId,
            missionId,
            event.searchPoolProfileId,
            missionCategory,
          )}
          className={styles.target}
        >
          {truncateName(event.searchPoolProfileData?.firstname)}{' '}
          {truncateName(event.searchPoolProfileData?.lastname)}{' '}
        </Link>
      )}
      <span className={styles.taskType}>
        {multiple && event.totalsearchPoolProfilesCount}{' '}
        {t(
          `reveal.dashboardView.activity.${getTaskTypeDescription(
            event.subtype,
          )}`,
          { count: multiple ? 2 : 1 },
        )}{' '}
        {event.newStage && replaceNewStageName(event.newStage)}
      </span>
    </GenericActivity>
  );
};

interface RevealMissionActivityEventProps extends EventProps {
  missionId?: string;
}

const RevealMissionActivityEvent: React.FC<RevealMissionActivityEventProps> = ({
  event,
  isLast,
  missionId,
}) => {
  const { t, i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const clientId = useClientId();

  const mainTarget = _.findWhere(event.targets, { isMain: true });

  return (
    <GenericActivity event={event} isLast={isLast}>
      {['call', 'meeting', 'comment'].includes(event.subtype) ? (
        <span className={styles.taskType}>
          <Trans
            i18nKey={`reveal.dashboardView.activity.${getTaskTypeDescription(
              event.subtype,
              !!event.customActivityTitle,
            )}`}
            components={{
              b: <b />,
              a: (
                <Link
                  to={`/client/${clientId}/reveal/projects/recruiting/${missionId}/profiles?selectedProfileId=${mainTarget?.targetId}`}
                />
              ),
            }}
            values={{
              profileName: mainTarget?.name,
              customActivityTitle: event.customActivityTitle
                ? getTranslatedText(lang, event.customActivityTitle)
                : t('common.unknown'),
            }}
          />
        </span>
      ) : (
        <span className={styles.taskType}>
          {t(
            `reveal.dashboardView.activity.${getTaskTypeDescription(
              event.subtype || event.content.type,
            )}`,
          )}
        </span>
      )}
    </GenericActivity>
  );
};

const GenericActivity: React.FC<EventProps> = ({ event, isLast, children }) => {
  const { author, comment } = event;
  const rawText = filterHtmlText(comment?.text || '');
  return (
    <div className={styles.timelineItem}>
      <div className={styles.header}>
        <i
          className={classNames(
            `${getTaskTypeIcon(event.taskType || event.subtype)} ${
              styles.icon
            }`,
            !isLast ? styles.iconAfter : '',
          )}
        />
        <div className={styles.description}>
          <span>{moment(event.date).format('HH:mm')} - </span>
          {children}
        </div>
        <span className={styles.author}>
          {author?.firstname} {author?.lastname}
        </span>
      </div>
      {rawText && (
        <div className={styles.content}> {parseHtml(comment.text)} </div>
      )}
    </div>
  );
};

export default RevealActivityTimeline;
