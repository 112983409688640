import React from 'react';
import { Form, TextArea } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { ContactFlowSequenceActions } from '@/types/searchPoolProfile';
import {
  isEmailAction,
  isLinkedinTaskType,
  TASK_TYPES,
} from '@/common/constants/taskTypes';
import ActionEmailEditor, { ActionMessage } from './ActionEmailEditor';

interface Props {
  action: ContactFlowSequenceActions;
  messageState: ActionMessage;
  setMessageState: React.Dispatch<React.SetStateAction<ActionMessage>>;
  isFirstEmail?: boolean;
  clientId: string;
  onUpdate: (actionUpdate: { actionId: string; updatedAction: any }) => void;
  onQuitInlineEdition: () => void;
}

const ActionInlineEdition: React.FC<Props> = ({
  action,
  messageState,
  setMessageState,
  isFirstEmail,
  clientId,
  onUpdate,
  onQuitInlineEdition,
}) => {
  const { t } = useTranslation();
  const textAreaRef = React.useRef<TextArea | null>(null);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (!textAreaRef.current) {
      return;
    }
    textAreaRef.current.focus();
  }, []);

  const checkClassNames = (classList: string[]) => {
    const classNames = Array.from(classList);
    if (_.isEmpty(classNames)) {
      return false;
    }
    return classNames.some(
      (className) =>
        className === 'item' ||
        className === 'icon' ||
        className === 'menu' ||
        className === 'search' ||
        className.includes('search') ||
        className === 'editor-container' ||
        className.includes('SequenceDynamicVariables') ||
        className.includes('ConditionsChaining') ||
        /action-item.*/.test(className),
    );
  };

  const handleClick = React.useCallback(
    (event: MouseEvent) => {
      if (!wrapperRef.current) {
        return;
      }
      const { clientX, clientY } = event;
      const target = event.target as any;

      // TODO: Refacto this code and change the behavior to close the inline edition
      // The current solution is not easily extensible
      if (
        target &&
        (checkClassNames(target.classList) ||
          target.localName === 'textarea' ||
          target.localName === 'label')
      ) {
        return;
      }
      const {
        top,
        bottom,
        right,
        left,
      } = wrapperRef.current.getBoundingClientRect();
      if (
        clientY < top ||
        clientY > bottom ||
        clientX > right ||
        clientX < left
      ) {
        onQuitInlineEdition();
      }
    },
    [onQuitInlineEdition],
  );

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [handleClick]);

  return (
    <div className='action-inline-edition' ref={wrapperRef}>
      {isEmailAction(action) ||
      isLinkedinTaskType(action.type) ||
      action.type === TASK_TYPES.CUSTOM ? (
        <Form.Field className='email-editor-container'>
          <ActionEmailEditor
            messageState={messageState}
            setMessageState={setMessageState}
            action={action}
            isFirstEmail={isFirstEmail}
            clientId={clientId}
            onUpdateAction={({ updatedAction }: any) =>
              onUpdate({
                actionId: action.actionId,
                updatedAction: {
                  ...action,
                  message: {
                    ...action.message,
                    ...updatedAction.message,
                  }
                },
              })
            }
            hideMenu={false}
            isInlineEdition
          />
        </Form.Field>
      ) : (
        <Form>
          <Form.Field>
            <TextArea
              ref={textAreaRef}
              rows='8'
              value={messageState.body}
              onChange={(e, { value }) => {
                setMessageState((prev) => ({ ...prev, body: value as string }));
                onUpdate({
                  actionId: action.actionId,
                  updatedAction: {
                    ...action,
                    description: { default: value },
                  },
                });
              }}
              placeholder={t('reveal.candidatesView.timeline.description')}
            />
          </Form.Field>
        </Form>
      )}
    </div>
  );
};

export default ActionInlineEdition;
