import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'underscore';
import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';
import CustomCriteriaFiltersTemplate from './CustomCriteriaFilterTemplate';
import { PropertyFilter } from './types';

import './CustomCriteriaFilters.css';

const NewCustomCriteriaFilters = (props: {
  clientId: string;
  mode: string;
  onSave: (filter: PropertyFilter) => void;
  t: TFunction;
}) => {
  const { clientId, mode, t, onSave } = props;

  const [
    selectedPropertyFilter,
    setSelectedPropertyFilter,
  ] = useState<PropertyFilter | null>(null);

  const closePopup = () => {
    if (selectedPropertyFilter && selectedPropertyFilter.operator) {
      onSave(selectedPropertyFilter);
    }
    setSelectedPropertyFilter(null);
  };

  return (
    <CustomCriteriaFiltersTemplate
      mode={mode}
      onClose={closePopup}
      setSelectedPropertyFilter={setSelectedPropertyFilter}
      selectedPropertyFilter={selectedPropertyFilter}
      triggerButton={
        <GenericButton primacy='secondary'>
          <Plus />
          {t('reveal.searchView.search.addFilter')}
        </GenericButton>
      }
      clientId={clientId}
    />
  );
};

export default compose(withTranslation('translations'))(
  NewCustomCriteriaFilters,
);
