import React from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import FilterDropdown from '../components/FilterDropdown';
import ApplicationStatusesFilter from '../GreenhouseFilters/ApplicationStatusesFilter';
import getGreenhouseOptions from '../GreenhouseFilters/getGreenhouseOptions';
import {
  CreationDateFilter,
  LastActionDateFilter,
} from '../Filters/DateFilters';

// import './GreenhouseSectionContent.css';

// TODO: refactor with useReducer
const GreenhouseSectionContent = ({
  onUpdate,
  filters,
  projectId,
  projectsWithFilterOptions,
}) => {
  const { t } = useTranslation();
  const handleUpdateRecruiters = ({ recruiterIds }) => {
    onUpdate({
      greenhouseFilters: {
        ...filters,
        recruiterIds: _.isEmpty(recruiterIds) ? null : recruiterIds,
      },
    });
  };

  const handleUpdateCoordinators = ({ coordinatorIds }) => {
    onUpdate({
      greenhouseFilters: {
        ...filters,
        coordinatorIds: _.isEmpty(coordinatorIds) ? null : coordinatorIds,
      },
    });
  };

  const handleUpdateStages = ({ stageIds }) => {
    onUpdate({
      greenhouseFilters: {
        ...filters,
        stageIds: _.isEmpty(stageIds) ? null : stageIds,
      },
    });
  };

  const handleUpdateApplicationStatuses = ({ applicationStatuses }) => {
    onUpdate({
      greenhouseFilters: {
        ...filters,
        applicationStatuses: _.isEmpty(applicationStatuses)
          ? null
          : applicationStatuses,
      },
    });
  };

  const handleUpdateTags = ({ tagIds }) => {
    onUpdate({
      greenhouseFilters: {
        ...filters,
        tagIds: _.isEmpty(tagIds) ? null : tagIds,
      },
    });
  };

  const {
    userOptions,
    stageOptions,
    applicationStatusOptions,
    tagOptions,
  } = getGreenhouseOptions({
    projectId,
    projectsWithFilterOptions,
  });

  return (
    <div className='greenhouse-filters'>
      <FiltersSubSection
        title={t('reveal.searchView.filters.ats.recruiters')}
        count={filters?.recruiterIds?.length}
      >
        <FilterDropdown
          options={userOptions}
          onChange={(e, { value }) =>
            handleUpdateRecruiters({ recruiterIds: value })
          }
          value={filters?.recruiterIds || []}
        />
      </FiltersSubSection>

      <FiltersSubSection
        title={t('reveal.searchView.filters.ats.coordinator')}
        count={filters?.coordinatorIds?.length}
      >
        <FilterDropdown
          options={userOptions}
          onChange={(e, { value }) =>
            handleUpdateCoordinators({ coordinatorIds: value })
          }
          value={filters?.coordinatorIds || []}
        />
      </FiltersSubSection>

      <FiltersSubSection
        title={t('reveal.searchView.filters.ats.creationDate')}
        count={
          filters?.minCreationTimestamp ||
          filters?.maxCreationTimestamp ||
          filters?.minCreationDelayInMs ||
          filters?.maxCreationDelayInMs
            ? 1
            : 0
        }
      >
        <CreationDateFilter
          onUpdate={onUpdate}
          filters={filters}
          atsType='greenhouse'
        />
      </FiltersSubSection>

      <FiltersSubSection
        title={t('reveal.searchView.filters.ats.lastActionDate')}
        count={
          filters?.minLastActionTimestamp ||
          filters?.maxLastActionTimestamp ||
          filters?.minLastActionDelayInMs ||
          filters?.maxLastActionDelayInMs
            ? 1
            : 0
        }
      >
        <LastActionDateFilter
          onUpdate={onUpdate}
          filters={filters}
          atsType='greenhouse'
        />
      </FiltersSubSection>

      <FiltersSubSection
        title={t('reveal.searchView.filters.ats.stages')}
        count={filters?.stageIds?.length}
      >
        <FilterDropdown
          options={stageOptions}
          onChange={(e, { value }) => handleUpdateStages({ stageIds: value })}
          value={filters?.stageIds || []}
        />
      </FiltersSubSection>

      <FiltersSubSection
        title={t('reveal.searchView.filters.ats.applicationStatus')}
        count={filters?.applicationStatuses?.length}
      >
        <ApplicationStatusesFilter
          applicationStatuses={filters?.applicationStatuses}
          onUpdate={handleUpdateApplicationStatuses}
          applicationStatusOptions={applicationStatusOptions}
        />
      </FiltersSubSection>

      <FiltersSubSection title='Tags' count={filters?.tagIds?.length}>
        <FilterDropdown
          options={tagOptions}
          onChange={(e, { value }) => handleUpdateTags({ tagIds: value })}
          value={filters?.tagIds || []}
        />
      </FiltersSubSection>
    </div>
  );
};

const FiltersSubSection = ({ title, children }) => {
  return (
    <div className='sub-section'>
      <div className='label'>{title}</div>
      {children}
    </div>
  );
};

export default GreenhouseSectionContent;
