import { SalaryBenchmark } from '@/graphql/hooks/salaryBenchmark/useSalaryBenchmark';

const referenceLength = 0.38;
const referenceEmptyLength = 0.5 - referenceLength;

export function getFlexValues(
  salaryBenchmark: SalaryBenchmark,
): [number, number, number, number] {
  const minSalary = salaryBenchmark.min.salary;
  const maxSalary = salaryBenchmark.max.salary;
  const meanSalary = salaryBenchmark.mean.salary;
  const bottomDiff = meanSalary - minSalary;
  const topDiff = maxSalary - meanSalary;
  if (bottomDiff > topDiff) {
    const remainingBar = (referenceLength * topDiff) / bottomDiff;
    return [
      referenceEmptyLength,
      referenceLength,
      remainingBar,
      0.5 - remainingBar,
    ];
  }
  const remainingBar = (referenceLength * bottomDiff) / topDiff;
  return [
    0.5 - remainingBar,
    remainingBar,
    referenceLength,
    referenceEmptyLength,
  ];
}
