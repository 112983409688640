import React from 'react';
import _ from 'underscore';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import GenericDropdown from '@/components/Common/GenericDropdown';
import Dots from '@/components/Common/Icons/Dots';
import GenericButton from '@/components/Common/GenericButton';
import DropdownPanel from '@/components/Common/DropdownPanel';
import DropdownMenuItem from '@/components/Common/DropdownMenuItem';
import Edit from '@/components/Reveal/Icons/Edit';
import Delete from '@/components/Reveal/Icons/Delete';
import { TextingSender } from '@/graphql/hooks/clients/useTextingSenders';
import styles from './TextingSendersTable.module.less';

interface TextingSendersTableCommonProps {
  userEmail: string;
  onEditSender: ({ senderId }: { senderId: string }) => void;
  onDeleteSender: ({ senderId }: { senderId: string }) => void;
}

interface TextingSendersTableProps {
  senders: TextingSender[];
}

const TextingSendersTable: React.FC<TextingSendersTableProps &
  TextingSendersTableCommonProps> = ({
  senders,
  userEmail,
  onEditSender,
  onDeleteSender,
}) => {
  if (_.isEmpty(senders)) {
    return null;
  }
  return (
    <Table basic className={styles.table}>
      <TextingSendersHeader />
      <TextingSendersBody
        senders={senders}
        userEmail={userEmail}
        onEditSender={onEditSender}
        onDeleteSender={onDeleteSender}
      />
    </Table>
  );
};

export const TextingSendersHeader = () => {
  const { t } = useTranslation();
  return (
    <Table.Header className={styles.header}>
      <Table.Row className={styles.headerRow}>
        <Table.HeaderCell className={styles.th}>
          <span>{t('settings.texting.table.senderNumber')}</span>
        </Table.HeaderCell>
        <Table.HeaderCell className={styles.th}>
          <span>{t('settings.texting.table.users')}</span>
        </Table.HeaderCell>
        <Table.HeaderCell collapsing className={styles.th} />
      </Table.Row>
    </Table.Header>
  );
};

export const TextingSendersBody: React.FC<TextingSendersTableProps &
  TextingSendersTableCommonProps> = ({
  senders,
  userEmail,
  onEditSender,
  onDeleteSender,
}) => {
  return (
    <Table.Body>
      {_.map(senders, (sender) => (
        <TextingSendersRow
          key={sender.id}
          sender={sender}
          onEditSender={onEditSender}
          onDeleteSender={onDeleteSender}
          userEmail={userEmail}
        />
      ))}
    </Table.Body>
  );
};

interface TextingSendersRowProps {
  sender: TextingSender;
}

export const TextingSendersRow: React.FC<TextingSendersRowProps &
  TextingSendersTableCommonProps> = ({
  sender,
  userEmail,
  onEditSender,
  onDeleteSender,
}) => {
  const { t } = useTranslation();
  const { id, senderNumber, users } = sender || {};

  const onEdit = () => {
    onEditSender({ senderId: id });
  };

  const onDelete = () => {
    onDeleteSender({ senderId: id });
  };

  const filteredUsers = _.filter(
    users || [],
    (user) => user.email !== userEmail,
  );

  return (
    <Table.Row key={id} className={styles.row}>
      <Table.Cell textAlign='left' className={styles.name}>
        <span>{senderNumber}</span>
      </Table.Cell>
      <Table.Cell textAlign='left' className={styles.subtype}>
        {_.pluck(filteredUsers || [], 'email')?.join(', ')}
      </Table.Cell>

      <Table.Cell className={styles.actions}>
        <GenericDropdown
          position='right'
          Trigger={({ onClick }) => (
            <GenericButton primacy='tertiary' onClick={onClick}>
              <Dots />
            </GenericButton>
          )}
        >
          <DropdownPanel className={styles.actionPanel}>
            <DropdownMenuItem
              className={styles.actionPanelItem}
              onClick={() => {
                onEdit();
              }}
            >
              <span>
                <Edit />
              </span>
              {t('common.edit')}
            </DropdownMenuItem>
            <DropdownMenuItem
              className={styles.actionPanelItem}
              onClick={() => {
                onDelete();
              }}
            >
              <span>
                <Delete />
              </span>
              {t('common.delete')}
            </DropdownMenuItem>
          </DropdownPanel>
        </GenericDropdown>
      </Table.Cell>
    </Table.Row>
  );
};

export default TextingSendersTable;
