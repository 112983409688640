import React from 'react';
import { useTranslation } from 'react-i18next';
import Thumbnail from './Thumbnail';


import './styles/PendingInviteCard.css';

const ActiveCollaboratorsCard = ({ collaborator, onClick, user }) => {
  const { t } = useTranslation();
  return (
    <div className="txt-box-container">
      <div className="box-title">
        <div className="box-identity">
          <div className="box-image">
            <Thumbnail
              link={collaborator.photoLink}
              firstname={collaborator.firstname}
              lastname={collaborator.lastname}
            />
          </div>
          <div className="box-email">
            <div className="box-email-header">
              {`${collaborator.firstname || '-'} ${collaborator.lastname || '-'}`}
            </div>
            <div className="box-email-body">{collaborator.email}</div>
          </div>
        </div>
        {user && user.email !== collaborator.email ? (
          <div className="box-action" onClick={onClick}>
            {t('collaborators.activeSection.delete')}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ActiveCollaboratorsCard;
