import React, { useState } from 'react';

import useClientId from '@/hooks/router/useClientId';

import { HiresweetLibProvider, DatePicker } from '@hiresweet/hiresweet-lib';

const PlaygroundJ2 = ({ theme }: { theme: 'hiresweet' | 'slash' }) => {
  const clientId = useClientId();
  const [date, setDate] = useState(new Date());
  return (
    <div style={{ padding: 30 }}>
      <h1>PJ4 - Date picker</h1>
      <HiresweetLibProvider theme={theme} clientId={clientId}>
        Selected:{' '}
        {date.toLocaleDateString('fr-FR', {
          weekday: 'long',
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })}
        <DatePicker date={date} onDate={setDate} />
      </HiresweetLibProvider>
    </div>
  );
};

export default PlaygroundJ2;
