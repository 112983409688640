import _, { reduce } from 'underscore';
import { TFunction } from 'i18next';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { getTranslatedText } from '@/common';
import {
  APIFilter,
  ContactPropertyFilter,
  DiversityPropertyFilter,
  KeywordAPIFilter,
  KeywordsPropertyFilter,
  PropertiesType,
  PropertyFilter,
} from './types';
import {
  HAS_ANY_KEYWORDS,
  HAS_KEYWORDS,
  HAS_NONE_OF_KEYWORDS,
  KEYWORDS_PARAM_IDS,
  KEYWORD_EMPLACEMENT_ANYWERE,
} from './constants';

const CONTACT = 'contact';
const DIVERSITY = 'diversity';
const KEYWORDS = 'keywords';
const FIRST_NAME_IS_LIKELY = 'firstnameIsLikely';
const HAS_ANY_SOURCES = 'hasAnySources';
const HAS_SOURCES = 'hasSources';
const HAS_ANY_MISSING_SOURCES = 'hasAnyMissingSources';
const HAS_NONE_OF_SOURCES = 'hasNoneOfSources';
const CONTACT_PARAM_IDS = [
  HAS_ANY_SOURCES,
  HAS_SOURCES,
  HAS_ANY_MISSING_SOURCES,
  HAS_NONE_OF_SOURCES,
];
const DIVERSITY_PARAM_IDS = [FIRST_NAME_IS_LIKELY];

/* eslint-disable no-param-reassign */
export const jsonParamsToState = (
  jsonParams: APIFilter[],
  fields: CustomFieldDefinition[],
  t: TFunction,
): PropertyFilter[] => {
  return reduce(
    jsonParams,
    (filters: PropertyFilter[], jsonParam) => {
      if (_.contains(CONTACT_PARAM_IDS, jsonParam.id)) {
        return getFiltersForContactParam(filters, jsonParam, t);
      }
      if (_.contains(DIVERSITY_PARAM_IDS, jsonParam.id)) {
        return getFiltersForDiversityParam(filters, jsonParam, t);
      }

      if (_.contains(KEYWORDS_PARAM_IDS, jsonParam.id)) {
        return getFiltersForKeywordsParam(filters, jsonParam, t);
      }

      const isANotParams = jsonParam.id === '#not';
      const params: any =
        jsonParam.id === '#not'
          ? jsonParam.params.son.params
          : jsonParam.params;
      const field = fields?.find((f) => f.id === params.clientFilterKey);
      const name = field?.title ? getTranslatedText(field?.title) : '';

      const newFilter: any =
        params.type === 'enum' || params.type === CONTACT
          ? {
              id: params.clientFilterKey,
              name,
              type: 'enum',
              operator: 'is_in',
              value: Array.isArray(params.value) ? params.value : [],
              options: field?.type === 'enum' ? field?.options ?? [] : [],
            }
          : {
              id: params.clientFilterKey,
              name,
              type: params.type,
              operator: 'is',
              value: params.value && getValue(params.value, params.type),
            };

      switch (params.policy) {
        case 'equal':
          newFilter.operator = isANotParams ? 'is_not' : 'is';
          break;
        case 'contains':
          if (
            typeof params.value === 'string' &&
            params.value.substring(0, 1) === '^'
          ) {
            newFilter.operator = 'start_with';
            break;
          }

          if (
            typeof params.value === 'string' &&
            params.value.substring(-1) === '$'
          ) {
            newFilter.operator = 'end_with';
            break;
          }

          newFilter.operator = isANotParams ? 'not_include' : 'include';
          break;
        case 'exists':
          newFilter.operator = isANotParams ? 'not_has_value' : 'has_value';
          break;

        case 'intersect':
          newFilter.operator = isANotParams ? 'is_not_in' : 'is_in';
          break;
        default:
          break;
      }

      // HACK
      if (params.clientFilterKey === 'last-interaction') {
        newFilter.name = t(
          'reveal.searchView.search.pastActivity.last-interaction',
        );
      }
      if (params.clientFilterKey === 'last-email-interaction') {
        newFilter.name = t(
          'reveal.searchView.search.pastActivity.last-email-interaction',
        );
      }
      if (params.clientFilterKey === 'last-ats-interaction') {
        newFilter.name = t(
          'reveal.searchView.search.pastActivity.last-ats-interaction',
        );
      }

      if (params.min) {
        newFilter.operator = 'greater';
        newFilter.value = params.min;
      }

      if (params.max) {
        newFilter.operator = 'lesser';
        newFilter.value = params.max;
      }

      filters.push(newFilter);
      return filters;
    },
    [],
  );
};

const getValue = (value: string | string[], type: PropertiesType) => {
  switch (type) {
    case 'enum':
    case 'text':
    case 'inline-text':
    case 'integer':
    case 'float':
      return value.toString();
    case 'day':
      if (value && !Array.isArray(value)) {
        const date = new Date(value);
        return date.toISOString();
      }
      return value.toString();
    default:
      return value.toString();
  }
};

const isIncompleteContactPropertyFilter = (
  filter: PropertyFilter,
  jsonParam: APIFilter,
) => {
  if (!isContactPropertyFilter(filter)) {
    return false;
  }
  const subfilterLists = filter.value || {};
  if (
    jsonParam.id === HAS_ANY_SOURCES &&
    !_.isEmpty(subfilterLists['any-filled'])
  ) {
    return false;
  }
  if (
    jsonParam.id === HAS_SOURCES &&
    !_.isEmpty(subfilterLists['all-filled'])
  ) {
    return false;
  }
  if (
    jsonParam.id === HAS_ANY_MISSING_SOURCES &&
    !_.isEmpty(subfilterLists['any-missing'])
  ) {
    return false;
  }
  if (
    jsonParam.id === HAS_NONE_OF_SOURCES &&
    !_.isEmpty(subfilterLists['all-missing'])
  ) {
    return false;
  }
  return true;
};

const isContactPropertyFilter = (
  filter: PropertyFilter,
): filter is ContactPropertyFilter => {
  return (filter as ContactPropertyFilter).type === CONTACT;
};

const getFiltersForContactParam = (
  filters: PropertyFilter[],
  jsonParam: APIFilter,
  t: TFunction,
) => {
  const inCompleteFilterIndex = filters.findIndex((filter) =>
    isIncompleteContactPropertyFilter(filter, jsonParam),
  );
  const incompleteContactFilter: any =
    inCompleteFilterIndex !== -1 && filters[inCompleteFilterIndex];

  const newFilter: ContactPropertyFilter = !incompleteContactFilter
    ? {
        id: CONTACT,
        name: t('reveal.searchView.search.contactInformation'),
        type: CONTACT,
        operator: [],
        value: {
          'any-filled': [],
          'all-filled': [],
          'any-missing': [],
          'all-missing': [],
        },
      }
    : incompleteContactFilter;

  if (!newFilter.operator) {
    newFilter.operator = [];
  }
  if (!newFilter.value) {
    newFilter.value = {
      'any-filled': [],
      'all-filled': [],
      'any-missing': [],
      'all-missing': [],
    };
  }

  if (jsonParam.id === HAS_ANY_SOURCES) {
    newFilter.operator.push('any-filled');
    newFilter.value['any-filled'] = jsonParam.params.sourceTypes;
  }

  if (jsonParam.id === HAS_SOURCES) {
    newFilter.operator.push('all-filled');
    newFilter.value['all-filled'] = jsonParam.params.sourceTypes;
  }

  if (jsonParam.id === HAS_ANY_MISSING_SOURCES) {
    newFilter.operator.push('any-missing');
    newFilter.value['any-missing'] = jsonParam.params.sourceTypes;
  }

  if (jsonParam.id === HAS_NONE_OF_SOURCES) {
    newFilter.operator.push('all-missing');
    newFilter.value['all-missing'] = jsonParam.params.sourceTypes;
  }

  if (!incompleteContactFilter) {
    return [...filters, newFilter];
  }

  const newFilters = [...filters];
  newFilters[inCompleteFilterIndex] = newFilter;
  return newFilters;
};

const getFiltersForDiversityParam = (
  filters: PropertyFilter[],
  jsonParam: APIFilter,
  t: TFunction,
) => {
  const newFilter: DiversityPropertyFilter = {
    id: DIVERSITY,
    name: t('reveal.searchView.search.diversityInformation'),
    type: DIVERSITY,
    operator: [],
    value: {
      firstname_is_likely: [],
    },
  };

  if (!newFilter.operator) {
    newFilter.operator = [];
  }
  if (!newFilter.value) {
    newFilter.value = {
      firstname_is_likely: [],
    };
  }

  if (jsonParam.id === FIRST_NAME_IS_LIKELY) {
    newFilter.operator.push('firstname_is_likely');
    newFilter.value.firstname_is_likely = jsonParam.params.value;
  }

  return [...filters, newFilter];
};

const isKeywordsPropertyFilter = (
  filter: PropertyFilter,
): filter is KeywordsPropertyFilter => {
  return (filter as KeywordsPropertyFilter).type === KEYWORDS;
};

const isKeywordsAPIFilter = (
  jsonParam: APIFilter,
): jsonParam is KeywordAPIFilter => {
  return _.contains(KEYWORDS_PARAM_IDS, jsonParam.id);
};

const isIncompleteKeywordsPropertyFilter = (
  filter: PropertyFilter,
  jsonParam: KeywordAPIFilter,
) => {
  if (!isKeywordsPropertyFilter(filter)) {
    return false;
  }
  const subfilterLists = filter.value || {};

  if (!_.isEmpty(subfilterLists[jsonParam.id])) {
    return false;
  }

  return true;
};

const getFiltersForKeywordsParam = (
  filters: PropertyFilter[],
  jsonParam: APIFilter,
  t: TFunction,
) => {
  const emptyDefaultValue = {
    [HAS_ANY_KEYWORDS]: [],
    [HAS_KEYWORDS]: [],
    [HAS_NONE_OF_KEYWORDS]: [],
  };
  if (!isKeywordsAPIFilter(jsonParam)) {
    throw new Error('Wrong json params id for keywords filters');
  }
  const inCompleteFilterIndex = filters.findIndex((filter) =>
    isIncompleteKeywordsPropertyFilter(filter, jsonParam),
  );
  const incompleteKeywordsFilter: any =
    inCompleteFilterIndex !== -1 && filters[inCompleteFilterIndex];

  const newFilter: KeywordsPropertyFilter = !incompleteKeywordsFilter
    ? {
        id: KEYWORDS,
        name: t('reveal.searchView.search.keywords.descriptions.label'),
        type: KEYWORDS,
        operator: [],
        value: emptyDefaultValue,
        emplacements: [],
      }
    : incompleteKeywordsFilter;

  if (!newFilter.operator) {
    newFilter.operator = [];
  }
  if (!newFilter.value) {
    newFilter.value = emptyDefaultValue;
  }
  if (_.isEmpty(newFilter.emplacements)) {
    newFilter.emplacements = [KEYWORD_EMPLACEMENT_ANYWERE];
  }

  if (
    jsonParam.id === HAS_ANY_KEYWORDS ||
    jsonParam.id === HAS_KEYWORDS ||
    jsonParam.id === HAS_NONE_OF_KEYWORDS
  ) {
    newFilter.operator.push(jsonParam.id);
    newFilter.value[jsonParam.id] = jsonParam.params.keywords;
    newFilter.emplacements = jsonParam.params.emplacements;
  }

  if (!incompleteKeywordsFilter) {
    return [...filters, newFilter];
  }
  const newFilters = [...filters];
  newFilters[inCompleteFilterIndex] = newFilter;
  return newFilters;
};

export default jsonParamsToState;
