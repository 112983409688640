import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import GET_MINI_MISSION, {
  GetMiniMissionData,
  GetMiniMissionVariables,
} from '@/graphql/getMiniMission';
import { MiniMission } from '@/types/mission';

export interface UseMiniMissionResult
  extends QueryResult<GetMiniMissionData, GetMiniMissionVariables> {
  miniMission: MiniMission | undefined;
}

interface useMiniMissionInput {
  queryOptions?: QueryHookOptions<GetMiniMissionData, GetMiniMissionVariables>;
}

const useMiniMission = (
  missionId: string,
  input?: useMiniMissionInput,
): UseMiniMissionResult => {
  const { queryOptions } = input || {};

  const query = useQuery<GetMiniMissionData, GetMiniMissionVariables>(
    GET_MINI_MISSION,
    {
      fetchPolicy: 'cache-first',
      ...(queryOptions || {}),
      variables: { missionId },
    },
  );

  const miniMission = query.data?.searchPool?.job;

  return { ...query, miniMission };
};

export default useMiniMission;
