import _ from 'underscore';
import { useMemo } from 'react';

import {
  Segmentation,
  PipelineState,
  Segment,
  RecencySegment,
} from '@/components/PipelineSegmentation/pipelineSegmentation';
import staticSegmentations from '@/components/PipelineSegmentation/data';
import useInterestedSubPipeline from '@/graphql/hooks/searchPoolJob/useInterestedSubPipeline';

const pipelineSegmentationId = 'D2KD9DW3';

// TODO: find a way to make this dynamic
// but for now it is not needed
// @see src/components/PipelineSegmentation/data.ts
export type ConfigurationParamDefaultSegmentationId =
  | 'all'
  | 'planning'
  | 'default';
const CONFIG_PARAMS_SEGMENTATION_ID_MAP: Record<
  ConfigurationParamDefaultSegmentationId,
  {
    segmentationId: string;
    segmentId: string;
  }
> = {
  all: {
    segmentationId: 'FESH3IQ7',
    segmentId: RecencySegment.ALL,
  },
  planning: {
    segmentationId: 'SEK4NDS2',
    segmentId: 'KHFHT6',
  },
  default: {
    segmentationId: pipelineSegmentationId,
    segmentId: PipelineState.PENDING,
  },
};

export const getSegmentationIdFromConfigurationParam = (
  configurationParam: ConfigurationParamDefaultSegmentationId = 'default',
) => {
  return (
    CONFIG_PARAMS_SEGMENTATION_ID_MAP[configurationParam] ||
    CONFIG_PARAMS_SEGMENTATION_ID_MAP.default
  );
};

export function useMissionSegmentations(
  missionId: string,
  condensedInterested = false,
  includeDisabledSubsteps = false,
): Segmentation[] {
  const { interestedSubPipeline } = useInterestedSubPipeline({ missionId });
  const segmentationsWithEnrichedPipeline = useMemo(
    () =>
      _.map(staticSegmentations, (segmentation) => {
        if (interestedSubPipeline === undefined) {
          return segmentation;
        }
        if (segmentation.id !== pipelineSegmentationId) {
          return segmentation;
        }

        const { segments } = segmentation;
        const interestedIndex = _.findIndex(
          segments,
          ({ id }) => id === PipelineState.INTERESTED,
        );
        const interestedSegments = getSegmentsFromSubSteps(
          interestedSubPipeline.steps,
          includeDisabledSubsteps,
        );
        return {
          ...segmentation,
          segments:
            condensedInterested || _.isEmpty(interestedSegments)
              ? segments
              : [
                  ..._.first(segments, interestedIndex),
                  ...interestedSegments,
                  ..._.rest(segments, interestedIndex + 1),
                ],
          showExtraOptions: {
            condensedInterested: true,
          },
        };
      }),
    [interestedSubPipeline, condensedInterested, includeDisabledSubsteps],
  );

  const allSegmentations = useMemo(() => {
    if (interestedSubPipeline === undefined) {
      return segmentationsWithEnrichedPipeline;
    }
    const pipelineSegmentationIndex = _.findIndex(
      segmentationsWithEnrichedPipeline,
      ({ id }) => id === pipelineSegmentationId,
    );
    const interestedSegmentation = {
      id: interestedSubPipeline.id,
      segments: getSegmentsFromSubSteps(
        interestedSubPipeline.steps,
        includeDisabledSubsteps,
      ),
      type: 'pipeline' as const,
      translationKey: 'interested',
      showDisplayTypeSelector: true,
    };
    return [
      ..._.first(
        segmentationsWithEnrichedPipeline,
        pipelineSegmentationIndex + 1,
      ),
      interestedSegmentation,
      ..._.rest(
        segmentationsWithEnrichedPipeline,
        pipelineSegmentationIndex + 1,
      ),
    ];
  }, [
    segmentationsWithEnrichedPipeline,
    interestedSubPipeline,
    includeDisabledSubsteps,
  ]);

  return allSegmentations;
}

function getSegmentsFromSubSteps(
  substeps: {
    id: string;
    title: string;
    isDisabled?: boolean | undefined | null;
  }[],
  includeDisabledSubsteps: boolean,
): Segment[] {
  const segments = _.map(substeps, ({ id, title, isDisabled }, index) => ({
    id,
    name: title,
    type:
      index === 0
        ? 'hs-pipeline-interested-default-substep'
        : 'hs-pipeline-interested-substep',
    ...(isDisabled && { isDisabled }),
  }));
  return includeDisabledSubsteps
    ? segments
    : _.filter(segments, (segment) => !segment.isDisabled);
}

export default useMissionSegmentations;
