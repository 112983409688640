import { createContext } from 'react';

export type ContactCategory =
  | {
      type: 'human';
    }
  | {
      type: 'company';
    };

export type ContactCategoryType = ContactCategory['type'];

const ContactCategoryContext = createContext<ContactCategoryType>('human');

export default ContactCategoryContext;
