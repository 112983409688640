import gql from 'graphql-tag';
import { FetchResult, MutationResult, useMutation } from '@apollo/client';
import { GET_CAMPAIGN, GET_CAMPAIGN_WITH_MINI_PROFILES } from './campaigns';

export const ADD_PROFILES_TO_CAMPAIGN = gql`
  mutation addProfilesToCampaign(
    $searchPoolId: ID!
    $input: AddProfilesToCampaignInput!
  ) {
    searchPool(id: $searchPoolId) {
      addProfilesToCampaign(input: $input) {
        results {
          profileId
          success
          error
          profile {
            id
            campaignEnrollments {
              id
            }
          }
        }
      }
    }
  }
`;

interface AddProfilesToCampaignInput {
  campaignId?: string;
  profileIds: string[];
  scheduleDate: Date;
  assignedSender: {
    type: string;
    senderId: string;
  };
}

type AddProfilesToCampaignHook = [
  (options: {
    variables: {
      searchPoolId: string;
      input: AddProfilesToCampaignInput;
    };
  }) => Promise<FetchResult<any>>,
  MutationResult<any>,
];

export function useAddProfilesToCampaignMutation(): AddProfilesToCampaignHook {
  const [mutation, mutationResult] = useMutation(ADD_PROFILES_TO_CAMPAIGN);
  return [mutation, mutationResult]; // as const
}

export const useRemoveProfilesFromCampaign = (
  campaignId: string,
): [(options: any) => Promise<FetchResult<any>>, MutationResult<any>] => {
  const [mutation, mutationResult] = useMutation(
    REMOVE_PROFILES_FROM_CAMPAIGN,
    {
      refetchQueries: [
        {
          query: GET_CAMPAIGN,
          variables: { campaignId },
        },
      ],
    },
  );
  return [mutation, mutationResult]; // as const
};

export const REMOVE_PROFILES_FROM_CAMPAIGN = gql`
  mutation removeProfilesFromCampaign(
    $searchPoolId: ID!
    $input: RemoveProfilesFromCampaignInput!
  ) {
    searchPool(id: $searchPoolId) {
      removeProfilesFromCampaign(input: $input) {
        results {
          profileId
          success
          error
          profile {
            id
            campaignEnrollments {
              id
            }
          }
        }
      }
    }
  }
`;

export const SET_CAMPAIGN_PROFILES_TO_SCHEDULED = gql`
  mutation SetCampaignProfilesToScheduled(
    $searchPoolId: ID!
    $input: ScheduledProfilesInCampaignInput
  ) {
    searchPool(id: $searchPoolId) {
      setCampaignProfilesToScheduled(input: $input) {
        results {
          profileId
          profile {
            id
            campaignEnrollments {
              id
              state {
                archived
                hold
                nextActionDate
                status
                warning {
                  type
                }
              }
              campaignId
            }
          }
        }
      }
    }
  }
`;

export const useSetCampaignProfilesToScheduled = (
  campaignId: string,
): [(options: any) => Promise<FetchResult<any>>, MutationResult<any>] => {
  const [mutation, mutationResult] = useMutation(
    SET_CAMPAIGN_PROFILES_TO_SCHEDULED,
    {
      refetchQueries: [
        {
          query: GET_CAMPAIGN_WITH_MINI_PROFILES,
          variables: { campaignId },
        },
      ],
    },
  );
  return [mutation, mutationResult]; // as const
};

export const SET_CAMPAIGN_PROFILES_TO_HOLD = gql`
  mutation setCampaignProfilesToHold(
    $searchPoolId: ID!
    $input: HoldProfilesInCampaignInput
  ) {
    searchPool(id: $searchPoolId) {
      holdProfilesInCampaign(input: $input) {
        results {
          profileId
          profile {
            id
            campaignEnrollments {
              id
              campaignId
              state {
                archived
                hold
                nextActionDate
                status
                warning {
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useSetCampaignProfilesToHold = (
  campaignId: string,
): [(options: any) => Promise<FetchResult<any>>, MutationResult<any>] => {
  const [mutation, mutationResult] = useMutation(
    SET_CAMPAIGN_PROFILES_TO_HOLD,
    {
      refetchQueries: [
        {
          query: GET_CAMPAIGN_WITH_MINI_PROFILES,
          variables: { campaignId },
        },
      ],
    },
  );
  return [mutation, mutationResult]; // as const
};
