import _ from 'underscore';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import GenericDropdown from '@/components/Common/GenericDropdown';
import GenericButton from '@/components/Common/GenericButton';
import DropdownMenuPanel from '@/components/Common/DropdownMenuPanel';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import TranslatableText from '@/components/TranslatableText';
import { useUserAllowedDepartments } from '@/graphql/hooks/users/useUserAllowedDepartments';
import { getTranslatedText } from '@/common';

import styles from './RevealLinkedProfilesSwitch.module.less';

const RevealLinkedProfilesSwitch = ({ profile }) => {
  const history = useHistory();
  const { subsections } = useUserAllowedDepartments();

  const linkedReferences =
    profile?.linkedProfilesGroup?.linkedProfileReferences || [];

  const allowedLinkedProfiles = useMemo(() => {
    const subsectionMap = _.reduce(
      subsections,
      (agg, subsection) => {
        agg[subsection.id] = true;
        return agg;
      },
      {},
    );

    return _.filter(
      profile?.linkedProfilesGroup?.linkedProfileReferences,
      (ref) => {
        const { metadata = [] } = ref;
        const agencyId =
          _.findWhere(metadata, { key: 'agencyId' })?.value || null;
        if (!agencyId) {
          return true;
        }

        return Boolean(subsectionMap[agencyId]);
      },
    );
  }, [profile, subsections]);

  if (allowedLinkedProfiles.length <= 1) {
    return null;
  }

  return (
    <GenericDropdown
      Trigger={({ onClick }) => {
        return (
          <GenericButton
            className={styles.linkedProfileSwitchButton}
            onClick={onClick}
          >
            <TranslatableText
              text={
                _.findWhere(
                  profile?.linkedProfilesGroup?.linkedProfileReferences,
                  { profileId: profile.id },
                )?.title || { default: '' }
              }
            />
            <ArrowDown />
          </GenericButton>
        );
      }}
    >
      <DropdownMenuPanel
        options={_.compact(
          _.map(allowedLinkedProfiles, (ref) =>
            ref.profileId !== profile.id
              ? {
                  id: ref.profileId,
                  label: getTranslatedText(ref.title),
                }
              : null,
          ),
        )}
        onSelect={(optionId) => {
          const params = {};
          const searchString = history.location.search.slice(1);

          let hasChanged = false;
          _.each(searchString.split('&'), (part) => {
            if (!part) {
              return;
            }
            const [key, value] = part.split('=');
            params[key] = value;
            if (
              [
                'profileId',
                'searchPoolProfileId',
                'selectedProfileId',
              ].includes(key)
            ) {
              params[key] = optionId;
            }
            hasChanged = true;
          });

          if (hasChanged) {
            history.push({
              search: `?${_.map(params, (value, key) => `${key}=${value}`).join(
                '&',
              )}`,
            });
          }
        }}
      />
    </GenericDropdown>
  );
};

export default RevealLinkedProfilesSwitch;
