import React from 'react';

const SimplePlaceholder = (props) => {
  const { offsetKey, highlight, children } = props;
  return (
    <span
      spellCheck={false}
      data-offset-key={offsetKey}
      style={
        highlight && {
          backgroundColor: '#FFF2D5',
          padding: '0 0.5em',
          borderRadius: '1em',
        }
      }
    >
      {children}
    </span>
  );
};

export default SimplePlaceholder;
