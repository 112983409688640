import React from 'react';
import MergeTagsAutomationEditor from '@/containers/Editor/MergeTagsAutomationEditor';

const ReadOnlyTemplate = ({
  contentRef,
  clientId,
  subject,
  body,
  snippets,
  signature,
  shouldDisplaySubject = true,
}) => {
  if (!body?.length || body === '<br/>') {
    return <></>;
  }
  return (
    <div style={{ width: '100%' }} ref={contentRef}>
      <MergeTagsAutomationEditor
        clientId={clientId}
        defaultValue={body}
        defaultValueSubject={subject}
        snippets={snippets}
        mode='template'
        fields={shouldDisplaySubject ? 'double' : 'simple'}
        signature={signature || ''}
        readOnly
      />
    </div>
  );
};

export default ReadOnlyTemplate;
