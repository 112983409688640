import React, { useState } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import useSequence from '@/graphql/hooks/sequences/useSequence';
import useSearchPoolProfiles from '@/graphql/hooks/searchPoolProfile/useSearchPoolProfiles';

import GenericModal from '@/components/Common/GenericModal';
import GenericButton from '@/components/Common/GenericButton';
import {
  SearchPoolMiniProfile,
  Action,
} from '@/graphql/searchPoolMiniProfileById';
import {
  getProfileSequencePrefixLength,
  getReapplySequenceTerms,
} from './utils';

import ModalComponent from './ModalComponent';
import ReapplySequenceModalContent from './ReapplySequenceModalContent';
import ErrorModal from './ErrorModal';

interface CandidateSelection {
  id: string;
  profileId?: string;
  isSelected?: boolean;
  sequenceId?: string;
  state?: string;
}
interface ReapplySequenceStepByStepModalProps {
  sequenceId: string;
  candidates: CandidateSelection[] | SearchPoolMiniProfile[];
  lastModifiedActionIndex?: number;
  onCancel: () => void;
  clientId: string;
}

const ReapplySequenceStepByStepModal: React.FC<
  ReapplySequenceStepByStepModalProps
> = ({
  sequenceId,
  candidates,
  lastModifiedActionIndex,
  onCancel,
  clientId,
}) => {
  const { t } = useTranslation('translations');
  const [stepIndex, setStepIndex] = useState(0);
  const [isToggleEnabled, setIsToggleEnabled] = useState(true);
  const candidateIds = _.pluck(candidates, 'id');
  const { profiles, loading: profilesLoading } = useSearchPoolProfiles({
    profileIds: candidateIds,
    queryOptions: { skip: _.isEmpty(candidateIds) },
  });

  const activeProfiles = filterActiveProfiles(
    _.compact(profiles || []),
    sequenceId,
  );

  const { sequence, loading: sequenceLoading } = useSequence({
    sequenceId,
    queryOptions: {
      skip: !sequenceId,
    },
  });
  const [profileErrors, setProfileErrors] = useState<
    { profileId: string; error: string }[]
  >([]);
  const [displayMode, setDisplayMode] = useState('profiles');

  const cancelButton = (
    <GenericButton onClick={onCancel} primacy='secondary' size='big'>
      {t('common.cancel')}
    </GenericButton>
  );

  if (sequenceLoading || profilesLoading) {
    return (
      <GenericModal open size='small'>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Loader active inline='centered' className='on-dimmer' />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </GenericModal>
    );
  }

  const sequenceActions: Action[] =
    sequence?.contactFlow?.sequences?.[0]?.actions || [];

  const steps = getSteps({
    profiles: activeProfiles,
    sequenceActions,
    lastModifiedActionIndex,
  });

  if (_.isEmpty(steps)) {
    // We realized there were no profiles to modify
    return (
      <ModalComponent
        title={t('contactFlow.reapplySequence.confirmationOfSequenceEdition')}
        submitButton={
          <GenericButton
            primacy='primary'
            type='submit'
            onClick={onCancel}
            size='big'
          >
            {t('common.ok')}
          </GenericButton>
        }
        cancelButton={<div />}
      >
        {t('contactFlow.reapplySequence.noProfilesToUpdate')}
      </ModalComponent>
    );
  }

  const goToNextStep = () => {
    if (stepIndex + 1 >= steps.length) {
      if (displayMode !== 'profile-errors' && !_.isEmpty(profileErrors)) {
        return setDisplayMode('profile-errors');
      }
      return onCancel();
    }
    setIsToggleEnabled(true);
    return setStepIndex(stepIndex + 1);
  };

  if (displayMode === 'profile-errors' && profileErrors && activeProfiles) {
    return (
      <ErrorModal
        profileErrors={profileErrors}
        profiles={activeProfiles}
        onCancel={onCancel}
      />
    );
  }

  const filteredProfiles = steps[stepIndex]?.profiles || [];

  const reapplySequenceTerms = getReapplySequenceTerms({
    profiles: filteredProfiles,
    sequence,
  });

  const {
    possible,
    reason,
    lastCompletedActionIndex,
    shortestProfileSequenceLength,
  } = reapplySequenceTerms;

  if (
    !possible ||
    !_.isNumber(lastCompletedActionIndex) ||
    !_.isNumber(shortestProfileSequenceLength) ||
    shortestProfileSequenceLength === 0 ||
    !filteredProfiles ||
    !sequence
  ) {
    return (
      <ModalComponent cancelButton={cancelButton}>
        <Message error>
          {t(
            `contactFlow.reapplySequence.errorReasons.${reason}`,
            t('common.genericError'),
          )}
        </Message>
      </ModalComponent>
    );
  }

  return (
    <ReapplySequenceModalContent
      title={`${t('contactFlow.reapplySequence.updateEnrolledContactsTitle')} ${
        steps.length > 1 ? ` - ${stepIndex + 1}/${steps.length}` : ''
      }`}
      description={
        <div>
          <div>
            <h4 style={{ marginBottom: 4 }}>
              {t('contactFlow.reapplySequence.confirmationOfSequenceEdition')}
            </h4>
            {t('contactFlow.reapplySequence.nudgeUpdateEnrolledContacts')}{' '}
            {t('contactFlow.reapplySequence.previewDescription')}
          </div>
          <br />
        </div>
      }
      afterSequenceEdition
      toggleMode
      isToggleEnabled={isToggleEnabled}
      onSwitchToggle={() => setIsToggleEnabled(!isToggleEnabled)}
      profiles={filteredProfiles}
      sequence={sequence}
      reapplySequenceTerms={reapplySequenceTerms}
      onSuccess={goToNextStep}
      onError={() => {
        console.error('Something wrong here...');
      }}
      clientId={clientId}
      setProfileErrors={(newErrors) => {
        if (newErrors) {
          const allErrors = profileErrors.concat(newErrors);
          setProfileErrors(allErrors);
        }
        goToNextStep();
      }}
      cancelButton={cancelButton}
    />
  );
};

const filterActiveProfiles = (
  profiles: SearchPoolMiniProfile[],
  sequenceId: string,
) => {
  return _.filter(profiles, (profile) => {
    const currentSequence = _.findWhere(profile?.contactFlow?.sequences || [], {
      isCurrent: true,
    });

    if (profile?.currentSequenceInfo?.sequenceId !== sequenceId) {
      return false;
    }

    if (currentSequence?.completion?.isCompleted) {
      return false;
    }

    return true;
  });
};

const getSteps = ({
  profiles,
  sequenceActions,
  lastModifiedActionIndex,
}: {
  profiles: SearchPoolMiniProfile[];
  sequenceActions: Action[];
  lastModifiedActionIndex?: number;
}) => {
  const steps = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const profile of profiles) {
    const currentSequence = _.findWhere(profile?.contactFlow?.sequences || [], {
      isCurrent: true,
    });

    const completedActionsCount =
      _.findLastIndex(
        currentSequence?.actions || [],
        (action) => !!action?.completion?.isCompleted,
      ) + 1;

    const prefixLength = getProfileSequencePrefixLength({
      profileActions: currentSequence?.actions,
      sequenceActions,
    });
    if (prefixLength < completedActionsCount) {
      const step = _.findWhere(steps, { complexProfiles: true });
      if (!step) {
        steps.push({ complexProfiles: true, profiles: [profile] });
      } else {
        step.profiles.push(profile);
      }
    } else {
      const step = _.findWhere(steps, { completedActionsCount });
      if (!step) {
        steps.push({ completedActionsCount, profiles: [profile] });
      } else {
        step.profiles.push(profile);
      }
    }
  }
  const impactedSteps = _.isNumber(lastModifiedActionIndex)
    ? _.filter(steps, (step) => {
        if (!step.completedActionsCount) {
          return true;
        }
        // All modified actions are already completed: an update would have no effect
        if (step.completedActionsCount > lastModifiedActionIndex) {
          return false;
        }
        return true;
      })
    : steps;
  return impactedSteps.sort((stepA, stepB) => {
    if (!_.isNumber(stepA?.completedActionsCount)) {
      return 1;
    }
    if (!_.isNumber(stepB?.completedActionsCount)) {
      return -1;
    }
    return stepA?.completedActionsCount - stepB?.completedActionsCount;
  });
};
export default ReapplySequenceStepByStepModal;
