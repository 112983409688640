import { MiniMission } from '@/types/mission';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const getCompanyTargetJobs = gql`
  query getCompanyTargetJobs($searchPoolId: ID!, $profileId: ID!) {
    searchPool(id: $searchPoolId) {
      id
      profile(id: $profileId) {
        id
        companyTargetJobs {
          id
          isArchived
          data {
            title
          }
        }
      }
    }
  }
`;

type Data = {
  searchPool: {
    id: string;
    profile?: {
      id: string;
      companyTargetJobs: MiniMission[];
    };
  };
};

type Variables = {
  searchPoolId: string;
  profileId: string;
};

type Input = {
  profileId: string;
  searchPoolId?: string;
  queryOptions?: QueryHookOptions<Data, Variables>;
};

type Result = QueryResult<Data, Variables> & {
  companyTargetJobs: MiniMission[];
};

function useCompanyTargetJobs({
  profileId,
  searchPoolId = 'reveal',
  queryOptions = {},
}: Input): Result {
  const query = useQuery<Data, Variables>(getCompanyTargetJobs, {
    ...queryOptions,
    variables: {
      profileId,
      searchPoolId,
    },
  });

  const companyTargetJobs =
    query.data?.searchPool.profile?.companyTargetJobs || [];

  return { ...query, companyTargetJobs };
}

export default useCompanyTargetJobs;
