import React from 'react';
import { withTranslation } from 'react-i18next';
import './PrivacyBox.css';

const PrivacyBox = ({ profile, t }) => (
  profile && profile.resumeData && profile.resumeData.hidePersonnalData ? (
    <div className="privacy-box">
      <div>{`${t('profile.resume.privacy')} `}<a href='mailto:privacy@hiresweet.com'>privacy@hiresweet.com</a>.</div>
    </div>
  ) : null
)

export default withTranslation('translations')(PrivacyBox);