import classNames from 'classnames';
import React, { forwardRef, HTMLAttributes } from 'react';

import styles from './DropdownPanel.module.less';

const DropdownPanel = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div className={classNames(styles.panel, className)} ref={ref} {...props}>
    {children}
  </div>
));

export default DropdownPanel;
