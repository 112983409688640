import gql from 'graphql-tag';
import TranslatableText from './TranslatableText';

export default gql`
  fragment CareerPage on CareerPage {
    id
    title {
      ...TranslatableText
    }
    enabled
    url
    organizationWebsite
    organizationLogoURL
  }
  ${TranslatableText}
`;
