import gql from 'graphql-tag';
import SearchPoolMiniProfile from './fragments/SearchPoolMiniProfile';

const SEARCH_POOL_MINI_PROFILE_BY_NUMBER_RESULTS = gql`
  query getMiniProfileFromNumber(
    $searchPoolId: ID!
    $input: SearchProfilesByNumberInput
  ) {
    searchPool(id: $searchPoolId) {
      id
      searchProfilesByNumber(input: $input) {
        ...SearchPoolMiniProfile
      }
    }
  }
  ${SearchPoolMiniProfile}
`;

export default SEARCH_POOL_MINI_PROFILE_BY_NUMBER_RESULTS;
