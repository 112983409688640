import gql from 'graphql-tag';
import Workflow from './Workflow';

export default gql`
  fragment SmallIntegration on IIntegration {
    id
    type
    status
    workflows {
      ...Workflow
    }
  }
  ${Workflow}
`;
