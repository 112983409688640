import React, { MouseEvent, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input, Modal } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';

import {
  CREATE_SEQUENCES_FOLDERS,
  GET_SEQUENCES_FOLDERS,
} from '@/graphql/sequencesFolders';
import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';
import GenericModal from '@/components/Common/GenericModal';

import styles from './NewSequencesFolderButton.module.less';

interface NewSequencesFolderButtonParams {
  clientId: string;
}

const NewSequencesFolderButton: React.FC = () => {
  const { t } = useTranslation();
  const { params } = useRouteMatch<NewSequencesFolderButtonParams>();
  const { clientId } = params;
  const [isCreateSequenceFolderOpen, setIsCreateSequenceFolderOpen] = useState(
    false,
  );
  const [newFolderName, setNewFolderName] = useState('');

  const [
    createSequencesFolder,
    { loading: creatingSequenceFolder },
  ] = useMutation(CREATE_SEQUENCES_FOLDERS, {
    refetchQueries: [
      {
        query: GET_SEQUENCES_FOLDERS,
        variables: {
          clientId,
        },
      },
    ],
  });

  const onCreateFolder = async () => {
    const sequenceFolderInput = {
      title: newFolderName,
    };
    await createSequencesFolder({
      variables: {
        input: sequenceFolderInput,
      },
    });
    setIsCreateSequenceFolderOpen(false);
  };

  return (
    <>
      <GenericButton
        size='big'
        primacy='secondary'
        onClick={() => setIsCreateSequenceFolderOpen(true)}
      >
        <Plus />
        {t('sequences.folderActions.new')}
      </GenericButton>
      <GenericModal
        onSubmit={onCreateFolder}
        open={isCreateSequenceFolderOpen}
        as={Form}
        size='tiny'
        onClick={(event: MouseEvent) => event.stopPropagation()}
      >
        <Modal.Header>{t('sequences.newFolderModal.title')}</Modal.Header>
        <Modal.Content>
          <div className='input-container'>
            <div className='input-label'>
              {t('sequences.newFolderModal.name')}
            </div>
            <div className='input-element'>
              <Input
                fluid
                onChange={(_e, { value }) => setNewFolderName(value)}
                input={{ 'data-form-type': 'other' }}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div className={styles.actions}>
            <GenericButton
              primacy='secondary'
              size='big'
              onClick={() => setIsCreateSequenceFolderOpen(false)}
            >
              {t('sequences.newFolderModal.cancel')}
            </GenericButton>
            <GenericButton
              size='big'
              disabled={creatingSequenceFolder}
              type='submit'
            >
              {t('sequences.newFolderModal.create')}
            </GenericButton>
          </div>
        </Modal.Actions>
      </GenericModal>
    </>
  );
};

export default NewSequencesFolderButton;
