import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import { FollowupProfile } from '@/types/profile';

const GET_PROFILES_TO_FOLLOWUP = gql`
  query getProfilesToFollowup($clientId: ID!) {
    client(id: $clientId) {
      id
      profilesToFollowup {
        id
        email
        isActive
        jobOfferId
        resumeData {
          firstname
          lastname
          step
        }
        processStep {
          milestone {
            id
            lastUpdateDate
            lastUpdateTimestamp
          }
        }
        lastStepUpdateTimestamp
        firstContactDate
      }
    }
  }
`;

interface GetProfilesToFollowupData {
  client: {
    id: string;
    profilesToFollowup: FollowupProfile[];
  };
}

interface GetProfilesToFollowupVariables {
  clientId: string;
}

interface UseProfilesToFollowupResult
  extends QueryResult<
    GetProfilesToFollowupData,
    GetProfilesToFollowupVariables
  > {
  profilesToFollowup: FollowupProfile[];
}

interface UseProfilesToFollowupInput extends GetProfilesToFollowupVariables {
  queryOptions?: QueryHookOptions<
    GetProfilesToFollowupData,
    GetProfilesToFollowupVariables
  >;
}

const useProfilesToFollowup = ({
  clientId,
  queryOptions = {},
}: UseProfilesToFollowupInput): UseProfilesToFollowupResult => {
  const query = useQuery<
    GetProfilesToFollowupData,
    GetProfilesToFollowupVariables
  >(GET_PROFILES_TO_FOLLOWUP, {
    ...queryOptions,
    variables: { clientId },
  });

  const profilesToFollowup = query.data?.client.profilesToFollowup || [];

  return { ...query, profilesToFollowup };
};

export default useProfilesToFollowup;
