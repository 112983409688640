import React, { FC } from 'react';

import Asterisk from './Asterisk';
import RepliesMockup from './RepliesMockup';

import styles from './Illustration.module.less';

const Illustration: FC = () => (
  <div className={styles.visual}>
    <Asterisk className={styles.asterisk} />
    <RepliesMockup className={styles.mockup} />
  </div>
);

export default Illustration;
