import React, { useEffect, useMemo } from 'react';
import _ from 'underscore';
import styles from '@/containers/Parameters/SnippetsSettings/SnippetsSettings.module.less';
import ConditionsChainingForm from '@/containers/Editor/VariableExamplesPreviewModal/ConditionsChaining/ConditionsChainingForm';
import { useConditionsChainingContext } from '@/containers/Editor/VariableExamplesPreviewModal/ConditionsChaining/useConditionsChainingContext';
import ConditionsChainingPreview from '@/containers/Editor/VariableExamplesPreviewModal/ConditionsChaining/ConditionsChainingPreview';
import { MergeTagsVariable } from '@/common/mergeTags/utils';

type Props = {
  setFormValues: React.Dispatch<React.SetStateAction<MergeTagsVariable>>;
};

const SnippetConditionsChaining: React.FC<Props> = ({ setFormValues }) => {
  const { conditionsChainingState } = useConditionsChainingContext();

  const debouncedSetFormValues = useMemo(() => _.debounce(setFormValues, 500), [
    setFormValues,
  ]);

  useEffect(() => {
    debouncedSetFormValues((previousValue) => ({
      ...previousValue,
      ifStatements: conditionsChainingState.ifStatements,
      elseStatement: conditionsChainingState.elseStatement,
      subtype: conditionsChainingState.subtype,
      switchField: conditionsChainingState.switchField,
    }));
    // eslint-disable-next-line
  }, [conditionsChainingState]);

  return (
    <div className={styles.conditionsChainingForm}>
      <div className={styles.left}>
        <ConditionsChainingForm />
      </div>
      <div className={styles.right}>
        <div className={styles.padding}>
          <ConditionsChainingPreview />
        </div>
      </div>
    </div>
  );
};

export default SnippetConditionsChaining;
