import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import useClientCustomCompanyDealStates from '@/graphql/hooks/clients/useClientCustomCompanyDealStates';
import GenericButton from '@/components/Common/GenericButton';
import SettingsLayout from '@/containers/SettingsLayout';
import useClientId from '@/hooks/router/useClientId';
import Plus from '@/components/Reveal/Icons/Plus';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useAddCustomClientCompanyState from '@/graphql/hooks/clients/useAddCustomClientCompanyState';
import useDeleteCustomClientCompanyState from '@/graphql/hooks/clients/useDeleteCustomClientCompanyState';
import useMoveCustomClientCompanyStateDownward from '@/graphql/hooks/clients/UseMoveCustomClientCompanyStateDownward';
import useUpdateCustomClientCompanyState from '@/graphql/hooks/clients/useUpdateCustomClientCompanyState';
import { sanitizeTypename } from '@/common/utils/apollo';
import CompanyStateEditModal from './CompaniesStatesEditModal';
import { CompanyStateType } from './CompaniesStates';
import CompaniesStatesTable from './CompaniesStatesTable';
import useMoveCustomClientCompanyStateUpward from '../../../graphql/hooks/clients/UseMoveCustomClientCompanyStateUpward';

import './CompaniesStatesSettings.css';

type ModalState = {
  type?: 'edit-company-state' | 'add-company-state';
  customCompanyDealStateId?: string;
  initialValue?: any;
};

const CustomCompaniesStatesSettings = () => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const [modal, setModal] = React.useState<null | ModalState>(null);
  const { customCompanyDealStates } = useClientCustomCompanyDealStates();
  const notifications = useNotificationSystem();

  const [addCustomClientCompanyState] = useAddCustomClientCompanyState();
  const [deleteCustomClientCompanyState] = useDeleteCustomClientCompanyState();
  const [moveCustomClientCompanyStateUpward] =
    useMoveCustomClientCompanyStateUpward();
  const [moveCustomClientCompanyStateDownward] =
    useMoveCustomClientCompanyStateDownward();
  const [updateCustomClientCompanyState] = useUpdateCustomClientCompanyState();

  const handleCloseModal = () => {
    setModal(null);
  };

  const handleEditCompanyState = async ({
    id,
    newValue,
  }: {
    id: string;
    newValue: CompanyStateType;
  }) => {
    await updateCustomClientCompanyState({
      variables: {
        ...sanitizeTypename({
          customCompanyDealStateId: id,
          companyState: {
            title: newValue.title,
            type: newValue.type,
          },
        }),
      },
    });
    handleCloseModal();
  };

  const handleAskEditCompanyState = ({
    companyStateId,
  }: {
    companyStateId: string;
  }) => {
    const companyState = _.findWhere(customCompanyDealStates, {
      customCompanyDealStateId: companyStateId,
    });
    setModal({
      type: 'edit-company-state',
      customCompanyDealStateId: companyStateId,
      initialValue: companyState,
    });
  };

  const handleAskAddCompanyState = () => {
    setModal({
      type: 'add-company-state',
    });
  };

  const handleAddCompanyState = async ({
    companyState,
  }: {
    companyState: CompanyStateType;
  }) => {
    await addCustomClientCompanyState({
      variables: {
        companyState: {
          title: companyState.title,
          type: companyState.type as 'prospect' | 'client' | 'archived',
        },
      },
    });
    handleCloseModal();
  };

  const handleRemoveCompanyState = async ({
    companyStateId,
  }: {
    companyStateId: string;
  }) => {
    const queryOptions = {
      onError: (error: any) => notifications.error(error.message),
    };
    await deleteCustomClientCompanyState({
      variables: { customCompanyDealStateId: companyStateId },
      ...queryOptions,
    });
  };

  const handleMoveCompanyStateUpward = async ({
    companyStateId,
  }: {
    companyStateId: string;
  }) => {
    const index = _.findIndex(customCompanyDealStates, {
      customCompanyDealStateId: companyStateId,
    });
    if (index < 1) {
      return;
    }
    const variables = {
      customCompanyDealStateId: companyStateId,
    };
    await moveCustomClientCompanyStateUpward({ variables });
  };

  const handleMoveCompanyStateDownward = async ({
    companyStateId,
  }: {
    companyStateId: string;
  }) => {
    const index = _.findIndex(customCompanyDealStates, {
      customCompanyDealStateId: companyStateId,
    });
    if (index < 0 || index + 1 >= customCompanyDealStates.length) {
      return;
    }
    await moveCustomClientCompanyStateDownward({
      variables: { customCompanyDealStateId: companyStateId },
    });
  };

  return (
    <SettingsLayout
      clientId={clientId}
      currentPage='companies-states'
      className='settings-container'
    >
      <div className='settings companyStates-page'>
        <div className='settings-header'>
          <h1>{t('settings.companiesStates.header')}</h1>
        </div>
        <h2>{t('settings.configuration')}</h2>
        <div className='section visible'>
          <div className='description'>
            {t('settings.companiesStates.description')}
          </div>
          <div className='companyStates-form'>
            <CompaniesStatesTable
              companyStates={customCompanyDealStates}
              onEdit={handleAskEditCompanyState}
              onRemove={handleRemoveCompanyState}
              onMoveDownward={handleMoveCompanyStateDownward}
              onMoveUpward={handleMoveCompanyStateUpward}
            />
            <div className='add-company-state-container'>
              <GenericButton onClick={() => handleAskAddCompanyState()}>
                <Plus />
                {t('settings.companiesStates.add')}
              </GenericButton>
            </div>
          </div>
        </div>
      </div>

      {modal?.type === 'edit-company-state' ? (
        <CompanyStateEditModal
          mode='edit'
          initialValue={modal.initialValue}
          onClose={handleCloseModal}
          onSubmit={({ companyState }) => {
            handleEditCompanyState({
              id: modal?.customCompanyDealStateId || '',
              newValue: companyState,
            });
          }}
        />
      ) : (
        ''
      )}
      {modal?.type === 'add-company-state' ? (
        <CompanyStateEditModal
          mode='create'
          onClose={handleCloseModal}
          onSubmit={({ companyState }) => {
            handleAddCompanyState({ companyState });
          }}
        />
      ) : (
        ''
      )}
    </SettingsLayout>
  );
};

export default CustomCompaniesStatesSettings;
