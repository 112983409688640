import React, { FC, useState } from 'react';

import classNames from 'classnames';
import { getFullname } from '@/common/helpers/person';
import { getRandomDefaultAvatarLink, getSecureLink } from '@/common';
import useUserMarketplaceProfileDisplayPreferences from '@/graphql/hooks/users/UserProfileDisplayPreferences';

import styles from './Avatar.module.less';

interface AvatarProps {
  person: {
    firstname?: string;
    lastname?: string;
    photoLink?: string;
  };
  className?: string;
}

const Avatar: FC<AvatarProps> = ({ person, className }) => {
  const { photoLink, firstname, lastname } = person;
  const randomLink = getRandomDefaultAvatarLink(`${firstname}${lastname}`);
  const avatarImageUrl = photoLink || randomLink;
  const [src, setSrc] = useState(getSecureLink(avatarImageUrl));
  const fullname = getFullname(person);

  const {
    userMarketplaceProfileDisplayPreferences,
    loading,
  } = useUserMarketplaceProfileDisplayPreferences();
  const {
    hideFullName,
    hidePicture,
  } = userMarketplaceProfileDisplayPreferences;

  return (
    <img
      alt={loading || hideFullName ? 'avatar' : fullname}
      data-openreplay-masked
      src={loading || hidePicture ? randomLink : src}
      className={classNames(styles.avatar, className)}
      onError={() => setSrc(randomLink)}
    />
  );
};

export default Avatar;
