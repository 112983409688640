import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const mutation = gql`
  mutation withSendForgottenPasswordToken($email: String!, $clientId: ID) {
    withSendForgottenPasswordToken(email: $email, clientId: $clientId) {
      id
    }
  }
`;

export default graphql(mutation, {
  props: ({ ownProps, mutate }) => ({
    sendForgottenPasswordToken: ({ email }) =>
      mutate({
        variables: {
          email,
          ...(ownProps.clientId && { clientId: ownProps.clientId }),
        },
      }),
  }),
});
