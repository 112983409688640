import * as Sentry from '@sentry/browser';
import { PUB_SUB_PORT_NAME } from './constants';
import { createNotificationMessage, isNotificationMessage } from './messages';

type UnsubscribeFn = () => void;
type DefaultNotificationParams = Record<string, unknown> | undefined;

export interface IAppPluginPubSub {
  notify: <TParams extends DefaultNotificationParams>(
    methodName: string,
    params: TParams,
  ) => void;
  subscribe: <TParams extends DefaultNotificationParams>(
    methodName: string,
    callback: (params: TParams) => void,
  ) => UnsubscribeFn;
}

export class AppPluginPubSub implements IAppPluginPubSub {
  private port: chrome.runtime.Port;

  constructor(port: chrome.runtime.Port) {
    this.port = port;
  }

  notify<TParams extends DefaultNotificationParams>(
    methodName: string,
    params: TParams,
  ) {
    try {
      this.port.postMessage(createNotificationMessage(methodName, params));
    } catch (e) {
      console.info(
        '🚀 ~ file: AppPluginPubSub.tsx ~ line 33 ~ AppPluginPubSub ~ e',
        e,
      );
      console.error(e);
      Sentry.captureException(e);
    }
  }

  subscribe<TParams extends DefaultNotificationParams>(
    methodName: string,
    callback: (params: TParams) => void,
  ) {
    try {
      const listener = (message: unknown) => {
        if (isNotificationMessage(message) && message.method === methodName) {
          callback(message.params as TParams);
        }
      };

      this.port.onMessage.addListener(listener);

      return () => this.port.onMessage.removeListener(listener);
    } catch (e) {
      console.info(
        '🚀 ~ file: AppPluginPubSub.tsx ~ line 53 ~ AppPluginPubSub ~ e',
        e,
      );
      console.error(e);
      Sentry.captureException(e);
      return () => {};
    }
  }
}

/**
 * Will attempt to create an `AppPluginPubSub` (from the front app) and return it.
 */
export const maybeCreateAppPluginPubSubFromApp = (): IAppPluginPubSub | null => {
  // checking the env variable for connection is present
  if (typeof process.env.REACT_APP_PLUGIN_EXTENSION_ID !== 'string') {
    const error = new Error(
      'Missing env variable `REACT_APP_PLUGIN_EXTENSION_ID`',
    );

    // it should not happen in production => we throw
    if (process.env.NODE_ENV === 'production') {
      throw error;
    }

    console.error(error);
    return null;
  }

  if (!window.chrome) {
    console.info('Ignoring AppPluginPubSub: not on Chrome');
    return null;
  }

  if (!window.chrome.runtime) {
    // console.log('Ignoring AppPluginPubSub: extension not installed');
    return null;
  }

  const port = window.chrome.runtime.connect(
    process.env.REACT_APP_PLUGIN_EXTENSION_ID,
    { name: PUB_SUB_PORT_NAME },
  );

  return new AppPluginPubSub(port);
};
