import _ from 'underscore';
import gql from 'graphql-tag';
import { withApollo } from '@apollo/client/react/hoc';
import React from 'react';
import * as Sentry from '@sentry/browser';
import TalentStrategist from '@/graphql/fragments/TalentStrategist';
import { getOptimizedSortedProfiles } from '@/containers/Profile/ProfileNavigation';
import SmallResumeData from '../../graphql/fragments/SmallResumeData';
import FullResumeData from '../../graphql/fragments/FullResumeData';
import PipeStepStat from '../../graphql/fragments/PipeStepStat';
import ContactData from '../../graphql/fragments/ContactData';
import Attachment from '../../graphql/fragments/Attachment';
import { EvaluationsSet } from '../../graphql/fragments/Evaluations';

const profileFromIdQuery = gql`
  query getProfileFromId($id: ID!) {
    profile(id: $id) {
      id
      email
      creationTimestamp
      lastStepUpdateTimestamp
      locked
      lockedSince
      contacted
      sent
      isImported
      jobOfferId
      offer {
        id
      }
      source {
        type
      }
      resumeData {
        ...FullResumeData
      }
      attachments {
        ...Attachment
      }
      contactData {
        ...ContactData
      }
      evaluationsSets {
        ...EvaluationsSet
      }
      talentStrategist {
        ...TalentStrategist
      }
      powerHourFlags {
        powerHourId
        isFlagged
      }
      firstContactDate
    }
  }
  ${FullResumeData}
  ${ContactData}
  ${EvaluationsSet}
  ${Attachment}
  ${TalentStrategist}
`;

const ProfileOfferAndStepQuery = gql`
  query getProfileOfferAndStepFromId($id: ID!) {
    profile(id: $id) {
      id
      offer {
        id
      }
      resumeData {
        step
        subStep
      }
    }
  }
`;

const profilesFromOfferAndStepIdQuery = gql`
  query getProfilesFromOfferAndStep($offerId: ID!, $stepId: String!) {
    offer(id: $offerId) {
      id
      profiles(step: $stepId, search: "") {
        id
        lastStepUpdateTimestamp
        resumeData {
          ...SmallResumeData
        }
        powerHourFlags {
          powerHourId
          isFlagged
        }
        firstContactDate
        annotation {
          globalFavorite {
            value
          }
        }
        source {
          type
          matchingScore
          createdFromDiscover
        }
      }
      pipeStepStats {
        ...PipeStepStat
      }
    }
  }
  ${SmallResumeData}
  ${PipeStepStat}
`;

export default (WrappedComponent) =>
  withApollo(({ client, ...rest }) => {
    const childProps = {
      findOneProfile: async ({
        offerId,
        stepId,
        subStepId,
        forceRefresh,
        shouldSplitPending,
      }) => {
        const param = {
          query: profilesFromOfferAndStepIdQuery,
          variables: {
            offerId,
            stepId: stepId || '',
          },
          ...(forceRefresh && { fetchPolicy: 'network-only' }),
        };
        let offerProfiles = [];
        try {
          const { data } = await client.query(param);
          offerProfiles = ((data || {}).offer || {}).profiles || [];
        } catch (e) {
          Sentry.captureException(e);
        }

        const shouldFilterOnSubStep =
          shouldSplitPending &&
          stepId === 'pending' &&
          _.contains(['pending-main', 'pending-extended'], subStepId);

        const filteredProfiles = shouldFilterOnSubStep
          ? _.filter(offerProfiles, (p) => p.resumeData?.subStep === subStepId)
          : offerProfiles;

        if (_.isEmpty(filteredProfiles)) {
          return null;
        }

        return getOptimizedSortedProfiles({
          profiles: filteredProfiles,
          stepId,
        })[0];
      },
      findProfileById: async (profileId) => {
        let profile;
        try {
          const { data } = await client.query({
            query: profileFromIdQuery,
            variables: { id: profileId },
          });
          profile = data && data.profile;
        } catch (e) {
          Sentry.captureException(e);
        }
        return profile;
      },
      getProfileOfferAndStep: async (profileId) => {
        try {
          const { data } = await client.query({
            query: ProfileOfferAndStepQuery,
            variables: { id: profileId },
          });
          return data?.profile;
        } catch (e) {
          Sentry.configureScope((scope) => {
            scope.setExtra('profileId', profileId);
          });
          Sentry.captureException(e);
        }
        return undefined;
      },
    };
    return <WrappedComponent client={client} {...rest} {...childProps} />;
  });
