import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../common/dates';
import TASK_ICONS from '../TaskIcons';
import AuthorAndDate from '../AuthorAndDate';

const ClassicMessageUnavailableTimelineItem = ({ profile }) => {
  const { t } = useTranslation();

  return (
    <div
      className='timeline-card-mail-wrapper classic-item'
      style={{ bottom: 0 }}
    >
      <div className='content'>
        <div className='header'>
          <div className='author'>
            <div className='date'>
              {profile &&
                profile.contactData &&
                formatDate(profile.contactData.creationTimestamp, t)}
            </div>
            {t('profile.contact.timeline.messageUnavailable')}
          </div>
        </div>
      </div>
    </div>
  );
};

const RevealMessageUnavailableTimelineItem = ({ profile, timelineItem }) => {
  const { t } = useTranslation();
  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{
                backgroundColor: TASK_ICONS.messageUnavailable.color,
              }}
              className='icon'
            >
              <i className={`${TASK_ICONS.messageUnavailable.icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            {t('profile.contact.timeline.messageUnavailable')}
            <AuthorAndDate
              timelineItem={timelineItem}
              date={profile?.contactData?.creationTimestamp}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MessageUnavailableTimelineItem = ({
  profile,
  timelineItem,
  applicationMode,
}) => {
  if (applicationMode === 'classic') {
    return <ClassicMessageUnavailableTimelineItem profile={profile} />;
  }
  return (
    <RevealMessageUnavailableTimelineItem
      profile={profile}
      timelineItem={timelineItem}
    />
  );
};

export default MessageUnavailableTimelineItem;
