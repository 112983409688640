import { graphql } from '@apollo/client/react/hoc';
import CLIENT_CRITERIA_OPTIONS from '../../graphql/clientCriteriaOptions';

export default graphql(CLIENT_CRITERIA_OPTIONS, {
  options: (ownProps) => ({
    variables: {
      id: ownProps.clientId,
    },
  }),
  props: ({ data: { loading, error, client } }) => ({
    loadingCriteriaOptions: loading,
    errorCriteriaOptions: error,
    criteriaOptions: (client || {}).criteriaOptions,
  }),
  skip: (ownProps) => !ownProps.clientId,
});
