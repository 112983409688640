import React from 'react';
import _ from 'underscore';
import Layout from './Layout';


import './GlobalSigninForm.css';

const TeamChoice = ({ clients, t, onChooseClient }) => (
  <Layout
    title={t('globalSignin.clientsSelectorHeader')}
    // details={t('signin.details')}
  >
    <div className='global-signin-form'>
      {_.map(clients, (client, index) => (
        <div key={index} className='client-box-container' onClick={() => onChooseClient(client.id)}>
          <div className='client-box'>
            <div>
              <div className='client-box-header'>{client.name || client.title || ''}</div>
              <div className='client-box-body'>{`hiresweet.com/client/${client.id}`}</div>
            </div>
            <div className='client-box-action'>
              <img alt='arrow' className='client-box-arrow' src='/images/icons/arrow-right.svg'/>
            </div>
          </div>
        </div>
      ))}
    </div>
  </Layout>
);

export default TeamChoice;
