import gql from 'graphql-tag';

export default gql`
  fragment SearchPoolProfileState on SearchPoolProfileState {
    id
    searchPoolId
    clientId
    searchPoolProfileId
    saved
    seen
    hidden
    new
    blacklisted
    contacted
    globalFavorite
    clientProfiles {
      profileId
      jobOfferId
      jobTitle
      isUnclassified
      creationTimestamp
    }
    profiles {
      id
      jobOfferId
      creationTimestamp
      lastStepUpdateTimestamp
      sent
      answerDetected
      resumeData {
        step
        subStep
      }
      views {
        timeline {
          author {
            lastname
            firstname
            email
            photoLink
          }
          timestamp
          date
          origin {
            type
            URL
          }
        }
        statsByRecruiter {
          author {
            lastname
            firstname
            email
            photoLink
          }
          firstViewTimestamp
          lastViewTimestamp
          count
        }
      }
    }
  }
`;
