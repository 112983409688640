import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import TranslatableTextFragment from '@/graphql/fragments/TranslatableText';

import { ClientCompanyDealState } from './useClientCustomCompanyDealStates';

const mutation = gql`
  mutation enableCompanyCustomDealStates {
    enableClientMode(mode: "companiesDealStates") {
      id
      permissions {
        companiesDealStates
        companiesOverview
      }
      customCompanyDealStates {
        customCompanyDealStateId
        title {
          ...TranslatableText
        }
        type
      }
    }
  }
  ${TranslatableTextFragment}
`;

type Data = {
  enableCompanyCustomDealStates: {
    id: string;
    permissions: {
      companiesOverview: boolean;
      companiesDealStates: boolean;
    };
    customCompanyDealStates?: ClientCompanyDealState[];
  };
};

type Variables = Record<string, never>;

type Input = {
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

const useEnableCompanyCustomDealStates = ({
  mutationOptions = {},
}: Input = {}) => useMutation<Data, Variables>(mutation, mutationOptions);

export default useEnableCompanyCustomDealStates;
