import _ from 'underscore';

const NB_MS_IN_A_DAY = 24*3600*1000;

export const getDiffDatesInDays = (date1, date2) => {
  const d1 = new Date(new Date(date1).toISOString().slice(0,10));
  const d2 = new Date(new Date(date2).toISOString().slice(0,10));
  return Math.round((d2.getTime() - d1.getTime()) / NB_MS_IN_A_DAY);
}


export const getDayAfterOffset = (date, nbDays) => {
  const d = new Date(new Date(date).toISOString().slice(0,10));
  return new Date(d.getTime() + nbDays*NB_MS_IN_A_DAY).toISOString().slice(0,10);
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));


export const getFirstBusinessDayEqualOrAfter = (date) => {
  let d = new Date(date);
  while (d.getDay() === 6 || d.getDay() === 0) {
    d = new Date(d.getTime() + NB_MS_IN_A_DAY)
  }
  return d.toISOString().slice(0,10);
}

export const getLastBusinessDayEqualOrBefore = (date) => {
  let d = new Date(date);
  while (d.getDay() === 6 || d.getDay() === 0) {
    d = new Date(d.getTime() - NB_MS_IN_A_DAY);
  }
  return d.toISOString().slice(0,10);
}

export const getDayAfterBusinessOffset = (date, nbWorkingDays) => {
  if (nbWorkingDays === 0) {
    return new Date(date).toISOString().slice(0,10);
  }
  const unitShift = nbWorkingDays > 0 ? 1 : -1;
  let nbWorkingDaysToSee = nbWorkingDays > 0 ? nbWorkingDays : -nbWorkingDays; 
  let d = new Date(date);
  while (nbWorkingDaysToSee > 0) {
    d = new Date(d.getTime() + unitShift*NB_MS_IN_A_DAY);
    if (d.getDay() !== 6 && d.getDay() !== 0) {
      nbWorkingDaysToSee--;
    }
  }
  return d.toISOString().slice(0,10);
}

export const getMondayOfPreviousWeek = (date) => (
  getDayAfterOffset(
    date, 
    -(new Date(date).getDay())-6
  )
)

export const getDiffInBusinessDays = (date1, date2) => {

  if (date2 < date1) {
    return getDiffInBusinessDays(date2, date1);
  }

  const firstBusinessDay1 = getFirstBusinessDayEqualOrAfter(date1);
  const firstBusinessDay2 = getFirstBusinessDayEqualOrAfter(date2);

  const d1 = new Date(firstBusinessDay1);
  const d2 = new Date(firstBusinessDay2);

  const nbDays = Math.round((d2.getTime() - d1.getTime()) / NB_MS_IN_A_DAY);

  const sunday1 = getDayAfterOffset(d1, -(new Date(d1).getDay()));
  const sunday2 = getDayAfterOffset(d2, -(new Date(d2).getDay()));
  
  const nbWeekends = Math.floor(
    (new Date(sunday2) - new Date(sunday1)) / 
    (7*NB_MS_IN_A_DAY)
  );
  return nbDays - 2*nbWeekends;
}


export const getDayAfterTransformPolicy = (date, policy) => {

  // eslint-disable-next-line no-nested-ternary
  const refValue = policy.valueIfReferenceIsNotBusinessDay !== undefined ? (
    [6,0].includes(new Date(date).getDay()) ? (
      policy.valueIfReferenceIsNotBusinessDay
    ) : policy.value
  ) : policy.value;

  if (policy?.type === 'idle') {
    return date;
  }
  if (policy?.type === 'days-offset') {
    return getDayAfterOffset(date, refValue)
  }
  if (policy?.type === 'business-days-offset') {
    return getDayAfterBusinessOffset(date, refValue);
  }
  if (policy?.type === 'last-business-day-equal-or-before') {
    return getLastBusinessDayEqualOrBefore(date);
  }
  if (policy?.type === 'first-business-day-equal-or-after') {
    return getFirstBusinessDayEqualOrAfter(date);
  }
  throw Error('unknown policy type for day transformation');
} 