import { BaseQueryOptions, useQuery } from '@apollo/client';

import { GET_CLIENT_DAILY_COHORTS } from '@/graphql/statistics';
import { ClientDailyCohort } from '@/types/statistics/clientStatistics';

interface GetClientDailyCohortsData {
  client: {
    id: string;
    statistics: {
      sequences: {
        dailyCohorts: ClientDailyCohort[];
      };
    };
  };
}

interface GetClientDailyCohortsVariables {
  clientId: string;
  sequenceIds?: string[];
  userEmails?: string[];
}

interface UseClientDailyCohortsResult {
  dailyCohorts?: ClientDailyCohort[];
  loading: boolean;
}

interface UseClientDailyCohortInput extends GetClientDailyCohortsVariables {
  queryOptions?: BaseQueryOptions<GetClientDailyCohortsVariables>;
}

const useClientDailyCohorts = ({
  clientId,
  sequenceIds,
  userEmails,
  queryOptions = {},
}: UseClientDailyCohortInput): UseClientDailyCohortsResult => {
  const { data, loading } = useQuery<
    GetClientDailyCohortsData,
    GetClientDailyCohortsVariables
  >(GET_CLIENT_DAILY_COHORTS, {
    ...queryOptions,
    variables: { clientId, sequenceIds, userEmails },
  });

  const { dailyCohorts } = data?.client?.statistics?.sequences || {};
  return { dailyCohorts, loading };
};

export default useClientDailyCohorts;
