import gql from 'graphql-tag';

export default gql`
  fragment ContactFlowMessageSender on Sender {
    firstname
    lastname
    signature
    alias
    senderAddress
    mailAccount {
      type
      address
    }
  }
`;
