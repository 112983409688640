import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { Button, Image } from 'semantic-ui-react';
import {
  getRandomDefaultAvatarLink,
  getSecureLink,
  getTranslatedText,
} from '../../../common';
import useUserFromJWToken from '../../../graphql/hooks/users/useUserFromJWToken';
import { COMPLETE_TASK } from '../../../graphql/tasks';

const TaskCard = ({ task, t }) => {
  const location = useLocation();
  const { data: userData } = useUserFromJWToken();
  const { unbiasedModeEnabled } = userData?.user || {};
  const [completeTask] = useMutation(COMPLETE_TASK);
  const [stateButtonHovered, setStateButtonHovered] = useState(false);
  const { target, owner, projectTitle, state, title } = task || {};
  const taskTitle = getTranslatedText(title) || '';

  if (target?.__typename !== 'RevealProfile') {
    return null;
  }

  const {
    firstname,
    lastname,
    photoLink: avatarImageUrl,
    mainEducation,
    headline,
  } = target?.resumeData || {};
  const fullName = `${firstname || ''} ${lastname || ''}`.trim();

  const onMarkAsCompleted = async (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
      e.preventDefault();
    }

    try {
      await completeTask({
        variables: {
          input: {
            projectId: task?.projectId,
            queueId: task?.queueId,
            sequenceId: task?.sequenceId,
            missionId: task?.missionId,
            profileId: target?.id,
            task: {
              id: task?.id,
              type: task?.type,
            },
          },
        },
      });
    } catch (err) {
      console.error(err.message);
    }
  };

  const taskPath = `${location.pathname}/focus/${task.id}`;
  const taskLocation = {
    ...location,
    pathname: taskPath,
  };

  return (
    <Link className='task-card' to={taskLocation}>
      <div className='header'>
        <div className='avatar-and-info-container'>
          <div className='avatar-container'>
            <Image
              src={
                avatarImageUrl
                  ? getSecureLink(avatarImageUrl)
                  : getRandomDefaultAvatarLink(firstname + lastname)
              }
              circular
              onError={(e) => {
                e.target.src = getRandomDefaultAvatarLink(firstname + lastname);
              }}
            />
          </div>

          <div className='profile-info-container'>
            <div className='first-line'>
              <span
                className={`fullname${
                  unbiasedModeEnabled && task?.type === 'application-review'
                    ? ' unbiased'
                    : ''
                }`}
              >
                {fullName}
              </span>
            </div>
            {headline?.content?.text && (
              <div className='headline'>{headline?.content?.text}</div>
            )}
            {mainEducation?.schoolName && (
              <div className='headline'>
                Studied at {mainEducation?.schoolName}
              </div>
            )}
          </div>
        </div>

        <div className='buttons-container'>
          <Button className='tertiary'>View profile</Button>
        </div>
      </div>
      <div className='task-card-content'>
        <div>
          <div className='line'>
            <i className='ri-calendar-todo-fill' />
            <span>{getTranslatedText(projectTitle)}</span>
            {taskTitle && <span>&nbsp;- {taskTitle}</span>}
          </div>

          <span className='line'>
            <i className='ri-user-fill' />
            <span>
              {owner?.firstname} {owner?.lastname}
            </span>
          </span>
        </div>

        {state === 'completed' && (
          <Button className='task-status-button completed' type='button'>
            <i className='ri-check-line' />
            <span className='body-medium'>
              {t('reveal.todosView.states.completed')}
            </span>
          </Button>
        )}

        {task?.type !== 'application-review' && state === 'in-progress' && (
          <Button
            className={classNames(
              'task-status-button',
              stateButtonHovered ? 'hovered' : state,
            )}
            onMouseEnter={() => setStateButtonHovered(true)}
            onMouseLeave={() => setStateButtonHovered(false)}
            type='button'
            onClick={onMarkAsCompleted}
          >
            {stateButtonHovered ? (
              <>
                <i className='ri-check-line' />
                <span className='body-medium'>Interrupt this task?</span>
              </>
            ) : (
              <>
                <i className='ri-loader-3-line' />
                <span className='body-medium'>
                  {t(`reveal.todosView.states.${state}`)}
                </span>
              </>
            )}
          </Button>
        )}

        {task?.type !== 'application-review' &&
          state !== 'completed' &&
          state !== 'in-progress' && (
            <Button
              className={classNames(
                'task-status-button',
                stateButtonHovered ? 'hovered' : state,
              )}
              onMouseEnter={() => setStateButtonHovered(true)}
              onMouseLeave={() => setStateButtonHovered(false)}
              type='button'
              onClick={onMarkAsCompleted}
            >
              {stateButtonHovered ? (
                <>
                  <i className='ri-check-line' />
                  <span className='body-medium'>Mark as completed?</span>
                </>
              ) : (
                <>
                  <i className='ri-loader-3-line' />
                  <span className='body-medium'>
                    {t(`reveal.todosView.states.${state}`)}
                  </span>
                </>
              )}
            </Button>
          )}
      </div>
    </Link>
  );
};

export default withTranslation('translations')(TaskCard);
