import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import GenericButton from '@/components/Common/GenericButton';

import styles from './Actions.module.less';

interface ActionsProps {
  step: number;
  nbSteps: number;
  next: () => void;
  previous: () => void;
  close: () => void;
  submitCta?: ReactNode;
}
const Actions: React.FC<ActionsProps> = ({
  step,
  nbSteps,
  next,
  previous,
  close,
  submitCta,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.actions}>
      {step > 0 && (
        <GenericButton primacy='secondary' size='big' onClick={previous}>
          {t('common.previous')}
        </GenericButton>
      )}
      {step < nbSteps - 1 ? (
        <GenericButton size='big' onClick={next}>
          {t('common.next')}
        </GenericButton>
      ) : (
        <GenericButton size='big' onClick={close}>
          {submitCta || t('reveal.onboardingModal.finish')}
        </GenericButton>
      )}
    </div>
  );
};

export default Actions;
