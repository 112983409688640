import React from 'react';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import downloadPDF from '@/common/downloadPDF';
import withAttachment from '../../../../hocs/attachments/withAttachment';

import './DownloadButton.css';

const DownloadButton = ({ attachmentLoading, attachment, icon, t }) => {
  const className = icon ? 'download-button' : 'tertiary';
  if (attachment?.file?.content && attachment?.name) {
    return (
      <Button
        className={className}
        onClick={() =>
          downloadPDF({
            filename: attachment.name,
            objectURL: attachment.file.content,
          })
        }
      >
        <i className='ri-file-download-line' />
        {!icon && t('profile.resume.attachments.download')}
      </Button>
    );
  }
  if (attachmentLoading) {
    return (
      <Button disabled className={className}>
        <i className='ri-file-download-line' />
        {!icon && t('profile.resume.attachments.download')}
      </Button>
    );
  }
  return null;
};

export default compose(
  withAttachment,
  withTranslation('translations'),
)(DownloadButton);
