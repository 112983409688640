import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import ConfirmationModal from '@/components/modals/ConfirmationModal';
import { Sequence } from '@/types/sequence';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useClientId from '@/hooks/router/useClientId';
import useArchiveSequence from '@/graphql/hooks/sequences/useArchiveSequence';
import useRestoreSequence from '@/graphql/hooks/sequences/useRestoreSequence';
import { DUPLICATE_SEQUENCE } from '../queries';

import styles from './SequenceSettingsDropdown.module.less';

type Props = {
  sequence: Sequence;
};

const SequenceSettingsDropdown: React.FC<Props> = ({ sequence }) => {
  const [openModal, setOpenModal] = useState<string | null>(null);
  const [redirectTo, setRedirectTo] = useState<string | undefined>();
  const [archiveSequence] = useArchiveSequence();
  const [restoreSequence] = useRestoreSequence();
  const [duplicateSequence] = useMutation(DUPLICATE_SEQUENCE);
  const notification = useNotificationSystem();
  const { t } = useTranslation();
  const clientId = useClientId();

  const Icon = <i className={`ellipsis ri-more-fill ${styles.icon}`} />;

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  const closeModal = () => setOpenModal(null);

  const handleArchiveSequence = async () => {
    await archiveSequence(sequence.id);
    closeModal();
    notification.success(t('sequences.archive.success'));
  };
  const handleRestoreSequence = async () => {
    await restoreSequence(sequence.id);
    closeModal();
    notification.success(t('sequences.unarchive.success'));
  };
  const handleDuplicateSequence = async () => {
    try {
      const result = await duplicateSequence({
        variables: { input: { id: sequence.id } },
      });
      const newSequenceId = result.data.sequence.duplicate.sequence.id;
      setRedirectTo(`/client/${clientId}/reveal/sequences/${newSequenceId}`);
      notification.success(t('sequences.duplicate.success'));
    } catch (error) {
      notification.error(t('sequences.duplicate.error'));
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <div className={styles.settingsDropdown}>
      <Dropdown className={styles.dropdown} direction='right' item icon={Icon}>
        <Dropdown.Menu style={{ marginTop: 3 }}>
          {sequence?.isArchived ? (
            <Dropdown.Item onClick={(): void => setOpenModal('unarchive')}>
              {t('sequences.unarchive.label')}
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onClick={(): void => setOpenModal('archive')}>
              {t('sequences.archive.label')}
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={() => handleDuplicateSequence()}>
            {t('sequences.duplicate.label')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {openModal === 'archive' && (
        <ConfirmationModal
          open
          size='tiny'
          header={t('sequences.archive.warning')}
          onSubmit={() => handleArchiveSequence()}
          onCancel={closeModal}
        />
      )}

      {openModal === 'unarchive' && (
        <ConfirmationModal
          open
          size='tiny'
          header={t('sequences.unarchive.warning')}
          onSubmit={() => handleRestoreSequence()}
          onCancel={closeModal}
        />
      )}
    </div>
  );
};

export default SequenceSettingsDropdown;
