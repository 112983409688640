import { graphql } from '@apollo/client/react/hoc';
import { GET_USER_FROM_JWTOKEN } from '@/graphql/users';

export default graphql(GET_USER_FROM_JWTOKEN, {
  options: () => ({
    variables: {},
  }),
  props: ({ data: { loading, user, error, refetch } }) => ({
    loading,
    user,
    userFromJWTokenLoading: loading,
    refetchUser: refetch,
    error,
  }),
});
