import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { getOfferProfiles } from '../offers/withOfferProfiles';

export const importProfilesCSV = gql`
  mutation importProfilesCSV($jobOfferId: ID!, $CSVInput: [CSVProfileInput!]!) {
    importProfilesCSV(jobOfferId: $jobOfferId, CSVInput: $CSVInput) {
      mergedProfiles {
        resumeData {
          firstname
          lastname
        }
      }
      duplicateProfiles {
        id
        jobOfferId
        resumeData {
          firstname
          lastname
          email
        }
      }
      insertedProfilesCount
    }
  }
`;

export default graphql(importProfilesCSV, {
  props: ({ mutate }) => ({
    importProfilesCSV: ({ jobOfferId, CSVInput }) => {
      const variables = { jobOfferId, CSVInput };
      return mutate({
        variables,
        refetchQueries: [
          {
            query: getOfferProfiles,
            variables: {
              offerId: jobOfferId,
              step: '', // refresh left side bar list
              search: '',
            },
          },
          {
            query: getOfferProfiles,
            variables: {
              offerId: jobOfferId,
              step: 'pending', // refresh step
              search: '',
            },
          },
        ],
      });
    },
  }),
});
