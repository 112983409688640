import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import _ from 'underscore';
import * as yup from 'yup';
import { Form as SemanticForm } from 'semantic-ui-react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DatePicker from 'react-datepicker';
import {
  ACTION_TYPE_OPTIONS,
  TYPES_WITH_DESCRIPTION,
  TYPES_WITH_MESSAGE,
} from '@/revealComponents/FullContactFlowEditor/FullContactFlowActionEditor/helpers';
import NewTemplate from '@/containers/Parameters/Templates/modals/NewTemplate';
import EditTemplate from '@/containers/Parameters/Templates/modals/editTemplate';
import { canHaveHtmlMessage, TASK_TYPES } from '@/common/constants/taskTypes';
import {
  getTemplatesOptions,
  getUserOptions,
} from '@/routes/RevealView/optionsHelpers';
import useClientTemplates from '@/graphql/hooks/templates/useClientTemplates';
import RegisteredDropdown from '@/components/RegisteredForm/RegisteredDropdown';
import RegisteredCustomTextArea from '@/components/RegisteredForm/RegisteredCustomTextArea';
import ActionEmailEditor from '@/revealComponents/ProfileContactFlow/Sequences/RevealPendingActions/ActionEmailEditor';
import { useClientRevealConnector } from '@/graphql/hooks/clients/useClientRevealProjects';
import {
  connectorCanBeSynchronized,
  profileIsInATS,
  getATSName,
} from '@/common/reveal/utils';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import GenericButton from '@/components/Common/GenericButton';
import RegisteredCheckbox from '@/components/RegisteredForm/RegisteredCheckbox';

import './CreateTaskForm.css';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';

export const TASK_TYPE_OPTIONS = _.reject(
  ACTION_TYPE_OPTIONS,
  ({ type }) => type === TASK_TYPES.SEND_EMAIL,
);

export const CreateUpdateTaskFormSchema = yup.object().shape({
  selectedOwnerEmail: yup.string().email(),
  taskType: yup.string(),
  taskDescription: yup.string(),
  dueWhen: yup.string(),
  dueDate: yup.date(),
  synchronize: yup.bool(),
});

const CreateUpdateTaskForm = ({
  clientId,
  users,
  usersLoading,
  submitting,
  compact = false,
  submitLabel,
  withCancel = false,
  onClose = null,
  messageState,
  setMessageState,
}) => {
  const { t } = useTranslation();
  const isPlugin = useIsPlugin();
  const { permissions } = useClientPermissions(clientId) || {};
  const { watch, setValue, register } = useFormContext();
  const { profile } = useCandidateViewContext();
  const [newTemplateOpen, setNewTemplateOpen] = useState(false);
  const [templateToEdit, setTemplateToEdit] = useState();
  const [disabled] = useState(false);

  const clientTemplates = useClientTemplates(clientId);
  const cachedTemplates = clientTemplates?.data?.client?.templates;
  const templates = _.filter(cachedTemplates, ({ isArchived }) => !isArchived);
  const defaultConnector = useClientRevealConnector(clientId);
  const connectorType = defaultConnector?.type;

  const dueWhen = watch('dueWhen');
  const dueDate = watch('dueDate');
  const taskType = watch('taskType');
  const selectedOwnerEmail = watch('selectedOwnerEmail');
  const isTimeSelected = watch('isTimeSelected');

  useEffect(() => {
    if (dueWhen === 'due-now') {
      setValue('dueDate', new Date());
    }
    // eslint-disable-next-line
  }, [dueWhen]);

  useEffect(() => {
    register('isTimeSelected');
    // eslint-disable-next-line
  }, []);

  const formOptions = {
    taskTypes: _.filter(
      TASK_TYPE_OPTIONS,
      (actionOption) => permissions.whatsappSequenceActions || permissions.unipile || !actionOption.value.includes('whatsapp')
    ),
    users: getUserOptions(users),
    templates: getTemplatesOptions({
      templates,
      onEditTemplate: ({ template }) => setTemplateToEdit(template),
      onCreateNewTemplate: () => setNewTemplateOpen(true),
    }),
    loading: usersLoading || clientTemplates?.loading,
  };
  const [formOptionsState, setFormOptionsState] = useState(formOptions);

  useEffect(() => {
    if (!formOptions.loading) {
      setFormOptionsState(formOptions);
    }
    // eslint-disable-next-line
  }, [formOptions?.loading]);

  useEffect(() => {
    setFormOptionsState(formOptions);
    // using templates causes infinite loop because the array changes on every _.filter call
    // eslint-disable-next-line
  }, [cachedTemplates]);

  const dueNowField = (
    <SemanticForm.Field>
      <RegisteredDropdown
        upward
        name='dueWhen'
        className={classNames(
          'due-when-dropdown',
          compact && 'small-field',
          'hiresweet-rounded',
        )}
        selection
        options={[
          {
            value: 'due-now',
            text: t('reveal.tasks.dueNow'),
          },
          {
            value: 'due-later',
            text: t('reveal.tasks.dueLater'),
          },
        ]}
      />
    </SemanticForm.Field>
  );

  const dueLaterField = (
    <SemanticForm.Field className={compact && 'mt-O'}>
      {dueWhen === 'due-later' && (
        <DatePicker
          {...register('dueDate')}
          popperPlacement='top-start'
          showTimeSelect
          dateFormat={isTimeSelected ? 'MMMM d, yyyy h:mm aa' : 'MMMM d, yyyy'}
          minDate={new Date()}
          filterTime={(selectedDate) =>
            new Date().getTime() < selectedDate.getTime()
          }
          selected={dueDate}
          onChange={(value, event) => {
            setValue('dueDate', value);
            // no event = user click on time column
            setValue('isTimeSelected', !event);
          }}
        />
      )}
    </SemanticForm.Field>
  );

  const descriptionField = _.contains(TYPES_WITH_DESCRIPTION, taskType) && (
    <SemanticForm.Field>
      {!compact && <label>Description</label>}
      <RegisteredCustomTextArea
        name='taskDescription'
        key={taskType} // TODO: hack to reload state on type change
        label='description'
        placeholderText='Description (optional)'
      />
    </SemanticForm.Field>
  );

  const selectedOption = _.findWhere(formOptionsState?.taskTypes, {
    type: taskType,
  });

  const submissionDisabled = !taskType || submitting || !selectedOwnerEmail;

  return (
    <>
      <SemanticForm.Field
        className={classNames(
          'task-type-dropdown',
          'hs-dropdown-menu-ellipsis',
          'hs-dropdown-text-with-image',
          disabled && 'hs-dropdown-disabled',
        )}
      >
        <RegisteredDropdown
          name='taskType'
          label={!compact && 'Task title'}
          selection
          search
          fluid
          options={formOptionsState?.taskTypes.map((option) => ({
            ...option,
            text: t(`sequences.tasks.${option.type}`),
          }))}
          placeholder='Select or type task title'
          text={
            selectedOption ? (
              <div className='text-with-image'>
                <img alt='selected-option' src={selectedOption?.image?.src} />
                <span>{t(`sequences.tasks.${selectedOption?.type}`)}</span>
              </div>
            ) : null
          }
        />
      </SemanticForm.Field>

      <SemanticForm.Field
        className={classNames(
          'owner-dropdown',
          disabled && 'hs-dropdown-disabled',
        )}
      >
        <RegisteredDropdown
          name='selectedOwnerEmail'
          search
          selection
          fluid
          label={!compact && 'Assigned to'}
          placeholder='Pick an owner'
          options={formOptionsState?.users}
        />
      </SemanticForm.Field>

      {compact ? (
        <>{descriptionField}</>
      ) : (
        <>
          <div className={classNames('d-flex-align-end', 'margin-top')}>
            {dueNowField}
            {dueLaterField}
          </div>
          {descriptionField}
        </>
      )}

      {_.contains(TYPES_WITH_MESSAGE, taskType) && (
        <SemanticForm.Field>
          <ActionEmailEditor
            clientId={clientId}
            messageState={messageState}
            setMessageState={setMessageState}
            hideMenu={!canHaveHtmlMessage({ type: taskType })}
          />
        </SemanticForm.Field>
      )}

      {newTemplateOpen && (
        <NewTemplate
          open
          clientId={clientId}
          onClose={() => setNewTemplateOpen(false)}
          onPostSubmit={() => setNewTemplateOpen(false)}
        />
      )}

      <EditTemplate
        clientId={clientId}
        template={templateToEdit}
        templateId={templateToEdit?.id}
        open={!!templateToEdit}
        onClose={() => setTemplateToEdit(null)}
      />

      {connectorType &&
        connectorCanBeSynchronized(connectorType) &&
        profileIsInATS(profile, connectorType) && (
          <SemanticForm.Field>
            <RegisteredCheckbox
              name='synchronize'
              label={`${t(
                'reveal.timeline.activityLog.synchronize',
              )} ${getATSName(connectorType)}`}
            />
          </SemanticForm.Field>
        )}
      <div
        className={classNames(
          compact && 'compact-actions',
          compact && 'margin-top',
        )}
      >
        {compact && isPlugin && (
          <div>
            {dueNowField}
            {dueLaterField}
          </div>
        )}
        {compact && !isPlugin && (
          <>
            {dueNowField}
            {dueLaterField}
          </>
        )}
        <div>
          {withCancel && (
            <GenericButton size='big' primacy='secondary' onClick={onClose}>
              {t('common.cancel')}
            </GenericButton>
          )}
          <GenericButton
            type='submit'
            size='big'
            disabled={submissionDisabled || submitting}
          >
            {submitLabel}
          </GenericButton>
        </div>
      </div>
    </>
  );
};

export default CreateUpdateTaskForm;
