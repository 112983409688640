import useClientId from '@/hooks/router/useClientId';
import { QueryResult, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const getAssistantDescriptors = gql`
  query getAssistantDescriptors($clientId: ID!) {
    client(id: $clientId) {
      id
      assistantDescriptors {
        text
        options
        id
      }
    }
  }
`;

type AssistantDescriptor = {
  text: string;
  options: string[];
  id: string;
};

type ResultAssistantDescriptors = {
  client: {
    assistantDescriptors: AssistantDescriptor[];
  };
};

export function useClientAssistantDescriptors(): QueryResult<
  ResultAssistantDescriptors,
  { clientId: string }
> & {
  assistantDescriptors: AssistantDescriptor[];
} {
  const clientId = useClientId();
  const query = useQuery<ResultAssistantDescriptors, { clientId: string }>(
    getAssistantDescriptors,
    { variables: { clientId } },
  );
  const { assistantDescriptors } = query.data?.client || {};
  return {
    ...query,
    assistantDescriptors: assistantDescriptors || [],
  };
}

const getAssistantDescriptorById = gql`
  query getAssistantDescriptorById(
    $assistantDescriptorId: String!
    $clientId: ID!
  ) {
    client(id: $clientId) {
      id
      assistantDescriptor(id: $assistantDescriptorId) {
        text
        options
        id
      }
    }
  }
`;

type ResultAssistantDescriptorById = {
  client: {
    assistantDescriptor: AssistantDescriptor;
  };
};

export function useClientAssistantDescriptorById(
  id: string,
): QueryResult<
  ResultAssistantDescriptorById,
  { clientId: string; assistantDescriptorId: string }
> & {
  assistantDescriptor: AssistantDescriptor | null;
} {
  const clientId = useClientId();
  const query = useQuery<
    ResultAssistantDescriptorById,
    { clientId: string; assistantDescriptorId: string }
  >(getAssistantDescriptorById, {
    variables: { clientId, assistantDescriptorId: id },
  });
  const { assistantDescriptor = null } = query.data?.client || {};
  return {
    ...query,
    assistantDescriptor,
  };
}
