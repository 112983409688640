import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { Department } from '../../graphql/fragments/Department';

export const moveClientDepartmentDownward = gql`
  mutation moveClientDepartmentDownward($departmentId: ID!) {
    moveClientDepartmentDownward(departmentId: $departmentId) {
      id
      departments {
        ...Department
      }
    }
  }
  ${Department}
`;

export default graphql(moveClientDepartmentDownward, {
  props: ({ mutate }) => ({
    moveClientDepartmentDownward: ({ departmentId }) =>
      mutate({
        variables: {
          departmentId,
        },
      }),
  }),
});
