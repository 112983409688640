import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

export const mutation = gql`
  mutation disableAutopilot($id: ID!) {
    disableAutopilot(id: $id) {
      id
      isAutopilot
    }
  }
`;

export default graphql(mutation, {
  props: ({ mutate }) => ({
    disableAutopilot: ({ id }) =>
      mutate({
        variables: {
          id,
        },
      }),
  }),
});
