import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'underscore';
import { Button, Modal } from 'semantic-ui-react';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import withUnlockSearchPoolProfile from '../../../../../../hocs/searchPoolProfile/withUnlockSearchPoolProfile';

import withActionLogger from '../../../../../../hocs/withActionLogger';

const LOCK_DURATION = 3000;

const UnlockProfileModal = ({ profile, unlockSearchPoolProfile, t }) => {
  const lockExpired =
    parseFloat(profile?.lockedSince) + LOCK_DURATION < Date.now();
  const [open, setOpen] = useState(false);
  const [showTrigger, setShowTrigger] = useState(lockExpired);
  const onClose = () => setOpen(false);
  const notification = useNotificationSystem();

  useEffect(() => {
    setTimeout(() => setShowTrigger(true), LOCK_DURATION);
  }, []);

  if (!showTrigger) {
    return null;
  }

  const trigger = (
    <div className='unlock-send-trigger' onClick={() => setOpen(true)}>
      {t('profile.contact.drafts.unlock')}
    </div>
  );

  const unlockWithoutChangingStep = async () => {
    try {
      await unlockSearchPoolProfile({
        searchPoolId: 'reveal',
        input: {
          id: profile?.id,
        },
      });

      notification.success(
        t('profile.contact.drafts.unlockProfileModal.success'),
      );
    } catch (e) {
      notification.error(t('profile.contact.drafts.unlockProfileModal.error'));
    }
  };

  return (
    <div>
      <Modal
        className='unlock-profile-modal'
        open={open}
        onClose={onClose}
        trigger={trigger}
        closeIcon
      >
        <Modal.Header>
          {t('profile.contact.drafts.unlockProfileModal.header')}
        </Modal.Header>
        <Modal.Content>
          <span className='modal-content'>
            {t('profile.contact.drafts.unlockProfileModal.contentOtherStep')}
          </span>
        </Modal.Content>
        <Modal.Actions className='modal-actions-buttons'>
          <div className='align-left'>
            <Button className='dismiss' type='submit' onClick={onClose}>
              {t('profile.contact.drafts.unlockProfileModal.cancel')}
            </Button>
          </div>

          <Button
            secondary
            size='big'
            type='submit'
            onClick={unlockWithoutChangingStep}
          >
            {t('profile.contact.drafts.unlockProfileModal.confirmAnyway')}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default compose(
  withActionLogger,
  withUnlockSearchPoolProfile,
  withTranslation('translations'),
)(UnlockProfileModal);
