import React, { useState, useEffect } from 'react';
import _ from 'underscore';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { CLIENT_MINI_CAMPAIGNS } from '@/containers/Parameters/Campaigns/queries';
import DoNotContactPill from '@/components/Reveal/Profile/privacy/DoNotContactPill/DoNotContactPill';
import TASK_ICONS from '../TaskIcons';
import IntegrationsSynchronizationStatus from '../IntegrationsSynchronizationStatus';
import { TimelineItemSynchronizationActions } from '../TimelineItemActions';
import AuthorAndDate from '../AuthorAndDate';

const UnenrolledFromCampaignItem = ({
  item,
  unbiasedModeEnabled,
  profile,
  clientId,
}) => {
  const { t } = useTranslation();
  const [campaign, setCampaign] = useState('');

  const {  action, author } = item || {};
  const { firstname, lastname } = profile?.resumeData || {};
  let profileName = `${firstname || ''} ${lastname || ''}`.trim();
  profileName = unbiasedModeEnabled ? '???' : profileName;
  const { loading, data } = useQuery(CLIENT_MINI_CAMPAIGNS, {
    variables: { clientId },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (!loading) {
      setCampaign(
        _.findWhere(data?.client?.campaigns, {
          id: action.campaignId,
        }),
      );
    }
    // eslint-disable-next-line
  }, [loading]);

  if (loading) {
    return <></>;
  }
  return (
    <div className='timeline-card-mail-wrapper' style={{ bottom: 0 }}>
      <div className='content'>
        <div className='timeline-line' />
        <div className='header'>
          <div className='icon-container'>
            <div
              style={{
                backgroundColor:
                  TASK_ICONS.unenrolledInCampaign?.color || 'grey',
              }}
              className='icon'
            >
              <i className={`${TASK_ICONS.unenrolledInCampaign?.icon} ri-2x`} />
            </div>
          </div>
          <div className='author'>
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  `profile.contact.timeline.unenrolledFromCampaign.description`,
                  {
                    authorName: `${author?.firstname ||
                      ''} ${author?.lastname || ''}`,
                    profileName,
                    campaignName: campaign?.title,
                  },
                ),
              }}
            />
            <AuthorAndDate timelineItem={item} >
              <IntegrationsSynchronizationStatus
                timelineItem={item}
                clientId={clientId}
                profile={profile}
              />
            </AuthorAndDate>
          </div>
          <div className='timeline-item-actions'>
            <TimelineItemSynchronizationActions
              clientId={clientId}
              profile={profile}
              timelineItem={item}
            />
          </div>
        </div>
        <div className='card-content comment'>
          <div className='body'>
            {t('profile.contact.timeline.reasons')}
            <ul>
              {_.map(
                _.compact(action?.interruptionReason?.split(/;|#/)),
                (reason) => (
                  <li key={reason}>
                    <Trans
                      i18nKey={`reveal.candidatesView.actionsBox.campaignReasons.${reason}`}
                      components={{ doNotContact: <DoNotContactPill /> }}
                    />
                  </li>
                ),
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnenrolledFromCampaignItem;
