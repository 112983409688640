import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import ClientDefaultCrmWorkflows from '@/graphql/fragments/ClientDefaultCrmWorkflows';

const CREATE_CLIENT_DEFAULT_WORKFLOW = gql`
  mutation createClientDefaultWorkflow(
    $input: CreateClientDefaultWorkflowInput!
  ) {
    createClientDefaultWorkflow(input: $input) {
      id
      defaultCrmWorkflows {
        ...ClientDefaultCrmWorkflows
      }
    }
  }
  ${ClientDefaultCrmWorkflows}
`;

const useCreateClientDefaultWorkflow = () => {
  return useMutation(CREATE_CLIENT_DEFAULT_WORKFLOW);
};

export default useCreateClientDefaultWorkflow;
