import React, { useCallback, useContext } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CalibrationAndRecommendationIcon } from '@/assets/icons';
import GenericButton from '@/components/Common/GenericButton';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import GenericToggle from '@/components/Common/GenericToggle';
import { revealJobWithJobPostings } from '@/hocs/offers/withOfferJobPostings';
import { useMutation, useQuery } from '@apollo/client';
import { getJobPostingInput } from '@/containers/Parameters/OfferJobPostings/helpers';
import { updateRevealJobPostingMutation } from '@/hocs/offers/withUpdateJobPosting';

import { getTranslatedText } from '../../../../common/index';

import styles from './ApplicantsPane.module.less';
import '../RecommendationPane/RecommendationPane.css';

interface RevealApplicantsPaneProps {
  clientId: string;
  jobId: string;
}

const RevealApplicantsPane: React.FC<RevealApplicantsPaneProps> = ({
  clientId,
  jobId,
}) => {
  const { t } = useTranslation();
  const { data: jobData } = useQuery(revealJobWithJobPostings, {
    variables: { searchPoolId: 'reveal', jobId },
  });
  const [updateJobPosting] = useMutation(updateRevealJobPostingMutation);
  const missionCategory = useContext(MissionCategoryContext);

  const job = jobData?.searchPool?.job;
  const jobPosting = job?.jobPostings?.[0];

  const handleTogglePublish = useCallback(async () => {
    const input = getJobPostingInput({
      jobPostingState: {
        ...jobPosting,
        published: !jobPosting.published,
      },
    });
    await updateJobPosting({
      variables: {
        input,
        searchPoolId: 'reveal',
      },
    });
  }, [jobPosting, updateJobPosting]);

  return (
    <div className={styles.applicationsCriterias}>
      <div className={styles.header}>
        <div className={styles.title}>
          <CalibrationAndRecommendationIcon className={styles.icon} />
          {t('reveal.missions.mission.settingsTab.jobPosting.title')}
        </div>
        <div className={styles.publishToggleContainer}>
          {t('offers.jobPostings.display.published')}
          <GenericToggle
            isChecked={jobPosting?.published}
            onChange={handleTogglePublish}
            name={t('offers.jobPostings.display.published') as string}
          />
        </div>
      </div>
      <div className={styles.settings}>
        <div className={styles.jobPostingTitle}>
          {getTranslatedText(jobPosting?.title)}
          <a
            className={styles.link}
            href={`https://join.hiresweet.com/${clientId}/jobs/${jobPosting?.id}`}
            target='_blank'
            rel='noreferrer'
          >
            <i className='ri-external-link-line ri-lg' />
          </a>
        </div>
        <Link
          to={`/client/${clientId}/reveal/projects/${missionCategory}/${jobId}/job-posting`}
        >
          <GenericButton>
            {t('reveal.missions.mission.settingsTab.jobPosting.title')}
          </GenericButton>
        </Link>
      </div>
    </div>
  );
};

export default RevealApplicantsPane;
