import _ from 'underscore';
import React from 'react';
import { Modal, Dropdown, Input, Divider, Image } from 'semantic-ui-react';

import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import { getRandomString, textToId } from '../../../common';

import './ApplicationFormEditModal.css';

class FieldEditor extends React.Component {
  renderSettings() {
    const {
      index,
      nbFields,
      onMoveFieldUpward,
      onMoveFieldDownward,
      onDuplicateField,
      onRemoveField,
      t,
    } = this.props;

    return (
      <div className='application-form-editor-field-settings'>
        <div className='field-settings-dropdown-container'>
          <Dropdown
            direction='left'
            trigger={<Image src='/images/icons/figma/ellipsis.svg' />}
            icon={null}
          >
            <Dropdown.Menu>
              {index > 0 && (
                <Dropdown.Item onClick={() => onMoveFieldUpward()}>
                  {t('settings.careerPage.applicationForms.moveUpward')}
                </Dropdown.Item>
              )}
              {index < nbFields - 1 && (
                <Dropdown.Item onClick={() => onMoveFieldDownward()}>
                  {t('settings.careerPage.applicationForms.moveDownward')}
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={() => onDuplicateField()}>
                {t('settings.careerPage.applicationForms.duplicate')}
              </Dropdown.Item>
              {nbFields > 1 && (
                <Dropdown.Item onClick={() => onRemoveField()}>
                  {t('settings.careerPage.applicationForms.delete')}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  }

  render() {
    const { field, index, onUpdateField, t } = this.props;

    return (
      <div className='application-form-editor-field'>
        <div className='application-form-editor-field-header'>
          <h4>
            {t('settings.careerPage.applicationForms.editModal.form.question')}{' '}
            {index + 1}
          </h4>

          {this.renderSettings({ fieldId: field.id })}
        </div>
        <div>
          <div className='application-form-editor-field-edition'>
            <div className='field-edition-element'>
              <Input
                className='field-edition-input'
                placeholder={t(
                  'settings.careerPage.applicationForms.editModal.form.fieldTitle',
                )}
                value={field.title}
                onChange={(e, { value }) => {
                  const newValue = {
                    ...field,
                    title: value,
                  };
                  onUpdateField({ newValue });
                }}
                fluid
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const getFieldCopy = ({ field, withCopyTitleExtention, t }) => {
  return {
    ...field,
    id: getRandomString(10),
    title:
      field.title +
      (withCopyTitleExtention
        ? ` - ${
            t
              ? t(
                  'settings.careerPage.applicationForms.editModal.form.fieldCopy',
                )
              : 'Copy'
          }`
        : ''),
    ...(field.subfields && {
      subfields: _.map(field.subfields, (subfield) => ({
        ...subfield,
        id: getRandomString(10),
      })),
    }),
  };
};

class ApplicationFormEditModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    const addIdsToForm = (form) => ({
      ...form,
      fields: _.map(form.fields, (field) =>
        getFieldCopy({ field, t: props.t }),
      ),
    });

    this.state.initialValue = props.initialValue;

    this.state.form = addIdsToForm({
      fields: [],
      ...props.initialValue?.form,
    });

    this.state.title =
      props.initialValue?.title ||
      (props.t &&
        props.t(
          'settings.careerPage.applicationForms.editModal.form.newForm',
        )) ||
      'New form';
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { title, form, initialValue } = this.state;

    const getIdFromStr = (str) => `${textToId(str)}-${getRandomString(6)}`;

    onSubmit({
      newValue: {
        ...initialValue,
        title,
        form,
        id: initialValue?.id || getIdFromStr(title),
      },
    });
  };

  handleFinalizeSubmit = ({ withSave, title }) => {
    const { onSubmit } = this.props;
    const { form } = this.state;
    onSubmit({ applicationForm: form, saveAsTemplate: !!withSave, title });
  };

  handleUpdateFormTitle = ({ newValue }) => {
    this.setState({ title: newValue });
  };

  handleUpdateFields = ({ newFields }) => {
    const { form: currentForm } = this.state;
    this.setState({
      form: {
        ...currentForm,
        fields: newFields,
      },
    });
  };

  handleMoveFieldUpward = ({ fieldId }) => {
    const { form: currentForm } = this.state;
    const index = _.findIndex(currentForm.fields, { id: fieldId });

    if (index <= 0) {
      return;
    }

    const newFields = [
      ...currentForm.fields.slice(0, index - 1),
      currentForm.fields[index],
      currentForm.fields[index - 1],
      ...currentForm.fields.slice(index + 1),
    ];

    this.handleUpdateFields({ newFields });
  };

  handleMoveFieldDownward = ({ fieldId }) => {
    const { form: currentForm } = this.state;
    const index = _.findIndex(currentForm.fields, { id: fieldId });

    if (index < 0 || index === currentForm.fields.length - 1) {
      return;
    }

    const newFields = [
      ...currentForm.fields.slice(0, index),
      currentForm.fields[index + 1],
      currentForm.fields[index],
      ...currentForm.fields.slice(index + 2),
    ];

    this.handleUpdateFields({ newFields });
  };

  handleDuplicateField = ({ fieldId }) => {
    const { t } = this.props;
    const { form: currentForm } = this.state;
    const index = _.findIndex(currentForm.fields, { id: fieldId });

    if (index < 0) {
      return;
    }

    const field = currentForm.fields[index];

    const newFields = [
      ...currentForm.fields.slice(0, index),
      field,
      getFieldCopy({ field, withCopyTitleExtention: true, t }),
      ...currentForm.fields.slice(index + 1),
    ];

    this.handleUpdateFields({ newFields });
  };

  handleRemoveField = ({ fieldId }) => {
    const { form: currentForm } = this.state;
    const newFields = _.filter(currentForm.fields, ({ id }) => id !== fieldId);
    this.handleUpdateFields({ newFields });
  };

  handleUpdateField = ({ fieldId, newValue }) => {
    const { form: currentForm } = this.state;
    const newFields = _.map(currentForm.fields, (field) =>
      field.id !== fieldId
        ? field
        : {
            ...newValue,
            id: fieldId, // to be sure
          },
    );
    this.handleUpdateFields({ newFields });
  };

  handleAddField = ({ index }) => {
    const { t } = this.props;
    const { form: currentForm } = this.state;
    const newFields = [
      ...currentForm.fields.slice(0, index),
      {
        id: getRandomString(10),
        type: 'text',
        title: t(
          'settings.careerPage.applicationForms.editModal.form.defaultQuestion',
        ),
      },
      ...currentForm.fields.slice(index),
    ];
    this.handleUpdateFields({ newFields });
  };

  renderFormEditor() {
    const { t } = this.props;
    const { form, title } = this.state;

    return (
      <div className='application-form-editor-fields-container'>
        <div className='input-container'>
          <div className='input-label'>
            {t('settings.careerPage.applicationForms.editModal.form.title')}
          </div>
          <div className='input-element'>
            <Input
              fluid
              className='field-edition-input'
              placeholder={t(
                'settings.careerPage.applicationForms.editModal.form.title',
              )}
              value={title}
              onChange={(e, { value }) => {
                this.handleUpdateFormTitle({ newValue: value });
              }}
            />
          </div>
        </div>
        {_.map(form.fields, (field, index) => (
          <div key={field.id}>
            {index > 0 ? <Divider /> : ''}
            <FieldEditor
              field={field}
              index={index}
              nbFields={form.fields.length}
              onUpdateField={({ newValue }) => {
                this.handleUpdateField({ fieldId: field.id, newValue });
              }}
              onMoveFieldUpward={() =>
                this.handleMoveFieldUpward({ fieldId: field.id })
              }
              onMoveFieldDownward={() =>
                this.handleMoveFieldDownward({ fieldId: field.id })
              }
              onDuplicateField={() =>
                this.handleDuplicateField({ fieldId: field.id })
              }
              onRemoveField={() =>
                this.handleRemoveField({ fieldId: field.id })
              }
              t={t}
            />
          </div>
        ))}
        <div className='add-field-container'>
          <span
            onClick={() => this.handleAddField({ index: form.fields.length })}
          >
            {t('settings.careerPage.applicationForms.editModal.form.addField')}
          </span>
        </div>
      </div>
    );
  }

  render() {
    const { onClose, t, mode = 'edit' } = this.props;

    const disableSubmit = false;

    return (
      <GenericModal
        className='application-form-edit-modal'
        open
        onClose={onClose}
        size='small'
      >
        <Modal.Header>
          {t(`settings.careerPage.applicationForms.${mode}Modal.header`)}
        </Modal.Header>
        <Modal.Content>{this.renderFormEditor()}</Modal.Content>
        <Modal.Actions>
          <div className='application-form-edit-modal__actions'>
            <GenericButton primacy='secondary' size='big' onClick={onClose}>
              {t('common.cancel')}
            </GenericButton>
            <GenericButton
              size='big'
              onClick={this.handleSubmit}
              disabled={disableSubmit}
            >
              {t(`common.${mode}`)}
            </GenericButton>
          </div>
        </Modal.Actions>
      </GenericModal>
    );
  }
}

export default ApplicationFormEditModal;
