import gql from 'graphql-tag';
import Attachment from './Attachment';
import TextWithEnrichmentsAndStack from './TextWithEnrichmentsAndStack';
import TranslatableText from './TranslatableText';

export default gql`
  fragment WelcomekitData on WelcomekitData {
    type
    sourceCandidateId
    firstname
    lastname
    headline {
      ...TextWithEnrichmentsAndStack
    }
    # currentCompany {
    #   name
    # }
    creationDate
    lastUpdateDate
    lastActivityDate
    emails {
      type
      value
    }
    phoneNumbers {
      type
      value
    }
    addresses {
      type
      value
    }
    attachments {
      ...Attachment
      file {
        url
      }
    }
    applications {
      sourceApplicationId
      applicationDate
      lastActivityDate
      jobs {
        sourceJobId
        title
      }
      status {
        sourceStatusId
        stage {
          sourceStageId
          sourceStageName
        }
        rejection {
          category {
            type
            name
          }
          reason {
            sourceRejectionReasonId
            sourceRejectionReasonName
          }
          lastStage {
            sourceStageId
            sourceStageName
          }
        }
      }
    }
    activityFeed {
      sourceActivityId
      type
      creationDate
      from
      to
      subject
      body
      user {
        sourceUserId
        firstname
        lastname
      }
      isPrivate
      visibility
    }
    tags {
      name {
        ...TranslatableText
      }
    }
  }
  ${Attachment}
  ${TextWithEnrichmentsAndStack}
  ${TranslatableText}
`;
