import React from 'react';

export default (props) => (
  <span
    spellCheck={false}
    data-offset-key={props.offsetKey}
    style={
      props.highlight && {
        backgroundColor: '#ffc860',
        padding: '0 0.5em',
        borderRadius: '1em',
      }
    }
  >
    {props.children}
  </span>
);
